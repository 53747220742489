import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const DocumentVersionSVG: FunctionComponent<Props> = ({
  width = 28,
  height = 28,
  color,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6058_120022)">
        <path
          d="M29.9894 0H10.5074C9.94876 0 9.49707 0.469343 9.49707 1.04985V26.9502C9.49707 27.5307 9.94876 28 10.5074 28H29.9894C30.5481 28 30.9998 27.5307 30.9998 26.9502V1.04985C30.9998 0.469343 30.5481 0 29.9894 0ZM26.1144 22.4049H13.8238C13.4909 22.4049 13.2294 22.1332 13.2294 21.7874C13.2294 21.4416 13.4909 21.1698 13.8238 21.1698H26.1144C26.4472 21.1698 26.7087 21.4539 26.7087 21.7874C26.7087 22.1209 26.4472 22.4049 26.1144 22.4049ZM26.1144 17.3286H13.8238C13.4909 17.3286 13.2294 17.0569 13.2294 16.7111C13.2294 16.3652 13.4909 16.0935 13.8238 16.0935H26.1144C26.4472 16.0935 26.7087 16.3776 26.7087 16.7111C26.7087 17.0446 26.4472 17.3286 26.1144 17.3286ZM26.1144 12.5117H13.8238C13.4909 12.5117 13.2294 12.24 13.2294 11.8941C13.2294 11.5483 13.4909 11.2766 13.8238 11.2766H26.1144C26.4472 11.2766 26.7087 11.5607 26.7087 11.8941C26.7087 12.2276 26.4472 12.5117 26.1144 12.5117ZM26.1144 7.58359H13.8238C13.4909 7.58359 13.2294 7.31187 13.2294 6.96603C13.2294 6.6202 13.4909 6.34848 13.8238 6.34848H26.1144C26.4472 6.34848 26.7087 6.63255 26.7087 6.96603C26.7087 7.29951 26.4472 7.58359 26.1144 7.58359Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M5.68175 3.40894H8.30867V2.17383H5.68175C4.46933 2.17383 3.48275 3.19897 3.48275 4.45879V6.44732H2.199C0.98658 6.44732 0 7.47246 0 8.73228V19.2678C0 20.5276 0.98658 21.5527 2.199 21.5527H3.48275V23.5413C3.48275 24.8011 4.46933 25.8262 5.68175 25.8262H8.30867V24.5911H5.68175C5.12308 24.5911 4.6714 24.1218 4.6714 23.5413V4.45879C4.6714 3.87828 5.12308 3.40894 5.68175 3.40894ZM3.48275 20.3176H2.199C1.64034 20.3176 1.18865 19.8483 1.18865 19.2678V8.73228C1.18865 8.15177 1.64034 7.68243 2.199 7.68243H3.48275V20.3176Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M9.49711 6.34863V7.58375H7.66659C7.34566 7.58375 7.07227 7.31202 7.07227 6.96619C7.07227 6.62036 7.34566 6.34863 7.66659 6.34863H9.49711Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M9.49711 11.2764V12.5115H7.66659C7.34566 12.5115 7.07227 12.2398 7.07227 11.8939C7.07227 11.5481 7.34566 11.2764 7.66659 11.2764H9.49711Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M9.49711 16.0938V17.3289H7.66659C7.34566 17.3289 7.07227 17.0571 7.07227 16.7113C7.07227 16.3655 7.34566 16.0938 7.66659 16.0938H9.49711Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M9.49711 21.1699V22.405H7.66659C7.34566 22.405 7.07227 22.1333 7.07227 21.7875C7.07227 21.4416 7.34566 21.1699 7.66659 21.1699H9.49711Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M3.48261 9.91797V11.1531H2.47225C2.13943 11.1531 1.87793 10.869 1.87793 10.5355C1.87793 10.202 2.13943 9.91797 2.47225 9.91797H3.48261Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M3.48261 13.2773V14.5125H2.47225C2.13943 14.5125 1.87793 14.2407 1.87793 13.8949C1.87793 13.5491 2.13943 13.2773 2.47225 13.2773H3.48261Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M3.48261 16.5752V17.8103H2.47225C2.13943 17.8103 1.87793 17.5262 1.87793 17.1928C1.87793 16.8593 2.13943 16.5752 2.47225 16.5752H3.48261Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6058_120022">
          <rect width="31" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
