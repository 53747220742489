import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
  onClick?: () => void;
};

export const AllTasksSvgIcon: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 40,
  height = 40,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13364_135312)">
        <path
          d="M19.5342 21.6525C19.9178 21.8421 20.1917 21.7609 20.5342 21.5984C24.6575 19.54 28.7808 17.4817 32.9041 15.4234C33.5616 15.0984 34.2191 14.7598 34.9452 14.3807C34.7534 14.2723 34.6438 14.2046 34.5205 14.1369C29.8219 11.8077 25.1095 9.46504 20.4109 7.10879C20.0958 6.95983 19.8493 6.95983 19.5479 7.10879C15.0958 9.34316 10.6301 11.564 6.16433 13.7848C5.80817 13.9609 5.452 14.1505 5.02734 14.3807C5.20543 14.489 5.27392 14.5296 5.35611 14.5702C10.0821 16.9265 14.8219 19.2827 19.5479 21.6525H19.5342ZM13.5479 12.5796C15.5616 11.5775 17.5616 10.5755 19.589 9.58691C19.8082 9.47858 20.1643 9.47858 20.3835 9.58691C23.4931 11.1036 26.5753 12.6473 29.6712 14.1911C29.7397 14.2317 29.8082 14.2723 29.9588 14.3671C29.7945 14.4619 29.6986 14.5432 29.5753 14.6109C26.5068 16.1546 23.4246 17.6848 20.3561 19.215C20.178 19.2963 19.9041 19.3369 19.7397 19.2557C16.5616 17.6984 13.3972 16.114 10.2328 14.5296C10.178 14.5025 10.1369 14.4484 9.99995 14.3536C11.2328 13.7307 12.3835 13.1619 13.5479 12.5796Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M31.5753 24.3607C27.8903 26.2023 24.1917 28.0305 20.5205 29.8857C20.1506 30.0752 19.8629 30.0752 19.4794 29.8857C15.7807 28.0305 12.0821 26.1888 8.36978 24.3607C8.1917 24.2659 7.91773 24.1982 7.75334 24.2794C6.86293 24.6857 5.98622 25.1461 5.04102 25.62C5.2054 25.7284 5.28759 25.7961 5.38348 25.8367C10.0958 28.1794 14.7944 30.5221 19.5068 32.8784C19.8629 33.0544 20.1506 33.0544 20.5068 32.8784C24.9314 30.6575 29.3698 28.4638 33.8081 26.2565C34.178 26.0669 34.5479 25.8638 34.9862 25.6336C34.0821 25.1867 33.2739 24.794 32.4794 24.3742C32.1506 24.1982 31.904 24.2117 31.5753 24.3742V24.3607Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M31.5617 18.7274C28.0411 20.5014 24.4932 22.2212 20.9863 24.0222C20.2603 24.4014 19.7124 24.4014 18.9726 24.0222C15.4658 22.2212 11.9178 20.4878 8.38359 18.7274C8.19181 18.6326 7.90414 18.5514 7.73975 18.6326C6.83564 19.0524 5.94523 19.5128 4.98633 19.9868C5.17811 20.0951 5.274 20.1493 5.38359 20.2035C10.0959 22.5597 14.8219 24.9024 19.5343 27.2587C19.8904 27.4347 20.1644 27.3941 20.4932 27.2316C24.4658 25.241 28.4384 23.2774 32.411 21.3003C33.2466 20.8806 34.0959 20.4608 35.0137 20.0003C34.1096 19.5535 33.274 19.1608 32.4658 18.7139C32.137 18.5378 31.8904 18.5649 31.5754 18.7139L31.5617 18.7274Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_13364_135312">
          <rect width="30" height="26" fill="white" transform="translate(5 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};
