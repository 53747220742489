import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import { organizationLinkTemplates } from "../link-templates";
import { useServices } from "../hooks/useServices";

type Props = {
  to: string;
  replace?: boolean;
  state?: unknown;
};

export const OrganizationNavigate: FunctionComponent<Props> = ({ to, replace, state }) => {
  const { user } = useServices();

  if (!user) {
    return <Navigate to={organizationLinkTemplates.loginAndReturnTo(to)} replace={true} />;
  }

  return <Navigate to={`/${user.organizationId}${to}`} replace={replace} state={state} />;
};
