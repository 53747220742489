import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const ErrorsFoundSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g transform="translate(0)">
        <rect width="50" height="50" rx="25" fill="#ffc8cb" />
      </g>
      <path
        d="M16.27,2.979a2,2,0,0,1,3.459,0L34.256,28a2,2,0,0,1-1.73,3H3.474a2,2,0,0,1-1.73-3Z"
        transform="translate(7 7)"
        fill="#ee3636"
      />
      <path
        d="M0-3.375A1.118,1.118,0,0,0,1.125-4.468V-13.5A1.125,1.125,0,0,0,0-14.625,1.125,1.125,0,0,0-1.125-13.5v8.968A1.131,1.131,0,0,0,0-3.375ZM0-1.687A1.406,1.406,0,0,0-1.406-.281,1.38,1.38,0,0,0,0,1.093,1.4,1.4,0,0,0,1.406-.281,1.427,1.427,0,0,0,0-1.687Z"
        transform="translate(25 32.295)"
        fill="#ffc8cb"
      />
    </svg>
  );
};
