import { Box, Text } from "@prodoctivity/design-system";

import { Activity } from "../../../components/RecentActivities/Activity";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useCollectionActivitiesPage } from "../../activities/hooks";

type Props = {
  instanceId: string;
};

export const CollectionsInstanceLogs: FunctionComponent<Props> = ({ instanceId }) => {
  const { collectionLogs } = useCollectionActivitiesPage(instanceId);
  const { resources } = useAppTranslation();

  if (collectionLogs?.entries?.length === 0) {
    return (
      <Box padding={4}>
        <Text>{resources.noResultsFound}</Text>
      </Box>
    );
  }

  return (
    <Box overflow="auto">
      {collectionLogs &&
        collectionLogs.entries.map((entry) => {
          if (entry.entryType === "document-collection-assigned") {
            return (
              <Box key={entry.id} padding={2}>
                <Activity entry={entry} fullScreen={false} fullWidth={true} />
              </Box>
            );
          } else {
            return (
              <Text key={entry.id} weight="bold" size="300">
                {resources.activities.noActivitiesFound}
              </Text>
            );
          }
        })}
    </Box>
  );
};
