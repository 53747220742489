import {
  Box,
  ColorConfig,
  TapArea,
  Text,
  calculateColor,
  useColors,
} from "@prodoctivity/design-system";

import { ReactNode } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {};

type Props = {
  children?: ReactNode;
  width?: string | number;
  height?: string | number;
  color?: ColorConfig;
  bgColor?: ColorConfig;
  onClick?: VoidFunction;
  border?: ColorConfig | boolean;
  disabled?: boolean;
};

export function DependencyButton({
  children,
  color,
  bgColor,
  width,
  height = 40,
  border,
  onClick,
  disabled,
}: Props) {
  const { colors } = useColors();
  return (
    <TapArea disabled={disabled} onTap={disabled ? emptyFunc : onClick}>
      <Box
        dangerouslySetInlineStyle={{
          __style: { borderColor: typeof border === "object" ? calculateColor(border) : undefined },
        }}
        borderStyle={border ? "sm" : undefined}
        rounding={1}
        width={width}
        height={height}
        color={disabled ? colors.subtle : bgColor}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Text align="center" color={disabled ? colors.white : color}>
          {children}
        </Text>
      </Box>
    </TapArea>
  );
}
