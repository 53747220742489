import {
  Box,
  PubSubEventManager,
  PubSubEventManagerCallback,
  useDesignBreakpoint,
  usePubSub,
  useSubscribe,
} from "@prodoctivity/design-system";
import {
  FunctionComponent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import type { FromServerWsMessage } from "@prodoctivity/types";
import { Outlet } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { Header } from "../../components/Header";
import { useSubscribeWS } from "../../hooks/useSubscribeWS";

type Props = Record<string, never>;

export const PageWrapper: FunctionComponent<{ hasHeader?: boolean; children: JSX.Element }> = ({
  hasHeader,
  children,
}) => {
  const { breakpoint } = useDesignBreakpoint();
  const subHandler = useCallback((msg: FromServerWsMessage) => {
    if (
      typeof window.Notification !== "undefined" &&
      window.Notification.permission !== "granted"
    ) {
      return;
    }
    msg.notifications.map((n) => new window.Notification(n.title, { body: n.body }));
  }, []);

  useEffect(() => {
    if (typeof window.Notification !== "undefined") {
      window.Notification.requestPermission().then((res) => {
        if (res === "granted") {
          console.log(Notification);
          // new Notification("Welcome to ProDoctivity");
        }
      });
    }
  }, []);

  useSubscribeWS("send-notifications-to-user", subHandler);

  const [hasFixedFullHeight, setHasFixedFullHeight] = useState<boolean>(false);

  const { eventManager } = usePubSub<DashboardPageEvents>();

  const { height: windowHeight } = useWindowSize();

  const eventManagerCallback: PubSubEventManagerCallback<DashboardPageEvents> = useCallback(
    (ev) => {
      switch (ev.type) {
        case "set-height": {
          setHasFixedFullHeight(!!ev.value.fullHeight);
          break;
        }
        default:
          throw new Error();
      }
    },
    []
  );

  useSubscribe(eventManager, eventManagerCallback);

  const contextValue = useMemo(() => {
    return {
      eventManager,
    };
  }, [eventManager]);

  return (
    <DashboardPageContext.Provider value={contextValue}>
      <Box
        direction="column"
        display="flex"
        height={hasFixedFullHeight ? windowHeight : undefined}
        minHeight={windowHeight}
      >
        {hasHeader ? <Header /> : null}
        {hasHeader ? <Box margin={breakpoint === "small" ? 3 : 7} /> : null}
        <Box direction="column" display="flex" width="100%" flex="grow">
          {children}
        </Box>
      </Box>
    </DashboardPageContext.Provider>
  );
};

function DashboardPageWrapper(_: Props) {
  return (
    <PageWrapper hasHeader={true}>
      <Outlet />
    </PageWrapper>
  );
}

export type DashboardPageEvents = {
  type: "set-height";
  value: {
    fullHeight: true | undefined;
  };
};

export const DashboardPageContext = createContext<{
  eventManager: undefined | PubSubEventManager<DashboardPageEvents>;
}>({
  eventManager: undefined,
});

export function usePageHeight(fullHeight: boolean) {
  const context = useContext(DashboardPageContext);

  useEffect(() => {
    if (context.eventManager) {
      context.eventManager.publish({
        type: "set-height",
        value: {
          fullHeight: fullHeight ? true : undefined,
        },
      });
    }
  }, [context, fullHeight]);
}

export default DashboardPageWrapper;
