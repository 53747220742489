import type {
  TemplateContextDefinition,
  TemplateVersionContextMapping,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import type { HttpExecuteDataLinkRequest, HttpExecuteDataLinkResponse } from "@prodoctivity/types";
import { FC, useCallback, useMemo, useState } from "react";

import { DatePickerProps } from "@prodoctivity/design-system";
import type momentType from "moment";
import { FormDataCapture } from "../Wizard";
import { IFormValues } from "../Wizard/_types";
import { contextToFormDefinition } from "../_lib/context-to-FormDefinition";
import { FormConfiguration } from "../_lib/types";
import { wizardDefinitionToLayout } from "../_lib/wizardDefinition-to-layout";

type IndexingFormProps = {
  readonly?: boolean;
  contextDefinition: TemplateContextDefinition;
  wizardDefinition: TemplateWizardDefinition;
  dataLinkMappings: TemplateVersionContextMapping["datalinks"];
  i18n: (key: string) => string;
  onFormValuesChange?: (formData: IFormValues) => void;
  executeDataLink: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  onNoDataFound?: (message: string) => void;
  moment: typeof momentType;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
};

type State = {
  hasErrors: boolean;
};

export const IndexingForm: FC<IndexingFormProps> = ({
  readonly,
  contextDefinition,
  wizardDefinition,
  dataLinkMappings,
  i18n,
  onFormValuesChange,
  executeDataLink,
  onNoDataFound,
  moment,
  resources,
}) => {
  const [state, setState] = useState<State>({
    hasErrors: false,
  });

  const onFormValuesUpdated = useCallback(
    (data: IFormValues) => {
      if (onFormValuesChange) {
        onFormValuesChange(data);
      }

      const hasErrors = validateFormValues(data);

      setState((prev) => ({ ...prev, hasErrors }));
    },
    [onFormValuesChange]
  );

  const onFormErrors = (_hasErrors: boolean) => {
    const { hasErrors } = state;

    if (hasErrors !== _hasErrors) {
      setState({ ...state, hasErrors: _hasErrors });
    }
  };

  const validateFormValues = (data: IFormValues): boolean => {
    return (
      data.formErrors !== undefined &&
      Object.keys(data.formErrors).some((key) => {
        return (
          data.formErrors && data.formErrors[key] !== undefined && data.formErrors[key].length > 0
        );
      })
    );
  };

  let prevFormConfiguration: FormConfiguration;
  const onDependencyLayoutUpdate = (layout: any) => {
    //JSON stringify and parse used to create a new copy  of formConfiguration without a reference
    //Object.assign create a copy with the reference
    prevFormConfiguration = JSON.parse(JSON.stringify(formConfiguration));
    if (prevFormConfiguration) prevFormConfiguration.formLayout.layout = layout;
  };

  //TODO: @eburgos Remove this
  const { formDefinition, formConfiguration } = useMemo(() => {
    const formDefinition = contextToFormDefinition(contextDefinition);
    const formLayout = wizardDefinitionToLayout(wizardDefinition, contextDefinition, undefined);

    const formConfiguration: FormConfiguration = {
      formLayout,
      formConnectors: [],
    };

    return { formDefinition, formLayout, formConfiguration };
  }, [contextDefinition, wizardDefinition]);

  return (
    <FormDataCapture
      formConfiguration={formConfiguration}
      formDefinition={formDefinition}
      connectors={[]}
      dataLinks={[]}
      connectorDataLinks={[]}
      dataLinkMappings={dataLinkMappings}
      executeDataLink={executeDataLink}
      onUpdateValues={onFormValuesUpdated}
      paginate={false}
      onFireConnector={() =>
        new Promise((resolve) =>
          resolve({
            formValues: null,
            formErrors: null,
            groupValues: null,
          })
        )
      }
      readOnly={readonly}
      i18n={i18n}
      //navigate={navigateTo}
      //onNavigate={onNavigate}
      //initialPagesHeight={initialPagesHeight}
      onFormErrorsUpdate={onFormErrors}
      onDependencyLayoutUpdate={onDependencyLayoutUpdate}
      onNoDataFound={onNoDataFound}
      moment={moment}
      resources={resources}
    />
  );
};
