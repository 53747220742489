import { Box, Svg, Text, useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import { CSSProperties, FunctionComponent, PropsWithChildren } from "react";
import { Link, To } from "react-router-dom";

import logoUrl from "../assets/logo.png";
import { useAppTranslation } from "../hooks/useAppTranslation";
import { useSettings } from "../hooks/useSettings";

const linkStyle: CSSProperties = { textDecoration: "none" };
const HeaderLink: FunctionComponent<PropsWithChildren<{ to: To }>> = ({ children, to }) => {
  const { colors } = useColors();
  return (
    <Box paddingX={5} display="flex">
      <Link style={linkStyle} to={to}>
        <Text align="center" size="300" color={colors.white} weight="bold">
          {children}
        </Text>
      </Link>
    </Box>
  );
};

export function MenuLink() {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const { disableSignUp } = useSettings();
  const breakPointRes = breakpoint === "small" || breakpoint === "medium";
  const breakPointResLarge = breakpoint === "large";
  const color = breakPointRes ? colors.black600 : colors.white;
  const direction = breakPointRes ? "column" : "row";
  const gap = breakPointRes ? 6 : 2;
  const size = breakPointRes ? "400" : breakPointResLarge ? "200" : "300";
  const alignItems = breakPointRes ? undefined : "center";
  const disable = breakPointRes ? "flex" : "none";
  const reverseDisable = breakPointRes ? "none" : "flex";
  return (
    <>
      {breakPointRes && (
        <Box paddingY={8}>
          <Box alignItems="center" display="flex">
            <HeaderLink to="/">
              <Svg src={logoUrl} alt="logo" width={240} />
            </HeaderLink>
          </Box>
        </Box>
      )}
      <Box display="flex" gap={gap} alignItems={alignItems} direction={direction}>
        <HeaderLink to="/product">
          <Text weight={"bold"} size={size} color={color}>
            {resources.product}
          </Text>
        </HeaderLink>
        {!disableSignUp && (
          <>
            <Box borderRadius={4} borderStyle="lg" display={disable} />
            <HeaderLink to="/pricing">
              <Text weight={"bold"} size={size} color={color}>
                {resources.pricing}
              </Text>
            </HeaderLink>
          </>
        )}
        <Box borderRadius={4} borderStyle="lg" display={disable} />
        <HeaderLink to="/login">
          <Text weight={"bold"} size={size} color={color}>
            {resources.signIn}
          </Text>
        </HeaderLink>
        <Box borderRadius={4} borderStyle="lg" display={disable} />
        {!disableSignUp && (
          <Box
            color={colors.primary}
            minWidth={100}
            rounding={2}
            padding={3}
            display={reverseDisable}
          >
            <HeaderLink to="/sign-up">
              <Text weight={"bold"} color={colors.white}>
                {resources.signUp}
              </Text>
            </HeaderLink>
          </Box>
        )}
        <Box
          display={disable}
          color={colors.primary}
          rounding={2}
          padding={3}
          width="40%"
          alignItems="center"
          justifyContent="center"
        >
          <HeaderLink to="/sign-up">
            <Text weight={"bold"} color={colors.white}>
              {resources.signUp}
            </Text>
          </HeaderLink>
        </Box>
      </Box>
    </>
  );
}
