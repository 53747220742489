import {
  Badge,
  Box,
  ColorConfig,
  ContextStateUpdater,
  FormControllerProvider,
  Grid,
  Heading,
  Switch,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { ColoredTitle, PricingTable } from "./PricingTable";

import { Svg } from "@prodoctivity/design-system";
import { FormDataCapture } from "@prodoctivity/prodoctivity-form-v5/src/components/Wizard";
import { formatCurrency } from "@prodoctivity/shared";
import type {
  ParametersObject,
  TemplateContextDefinition,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import { useCallback, useMemo, useState, type FunctionComponent } from "react";
import Cloud3D from "../../assets/3d-inset-cloud.png";
import BusyCity from "../../assets/busyCity.png";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { noop } from "../../utils";
import { VisitorsFooter } from "../LandingPage/VisitorsFooter";
import { VisitorsHeader } from "../LandingPage/VisitorsHeader";
import { useLandingPageLogic } from "../LandingPage/hooks";
import { PricingCard } from "./PricingCard";

export const prices = {
  storage: {
    annually: 4,
    monthly: 0.4,
  },
  api: {
    annually: 25,
    monthly: 2.5,
  },
  composition: {
    annually: 100,
    monthly: 10,
  },
  integrations: {
    annually: 16,
    monthly: 1.6,
  },
  collection: {
    annually: 100,
    monthly: 10,
  },
  professional: {
    value: 19.99,
    annually: 199.99,
    rawAnnually: 239.88,
    storage: 10,
    collectionPerUserPerMonth: 3000,
  },
  business: {
    value: 34.99,
    annually: 349.99,
    rawAnnually: 419.88,
    storage: 15,
    collectionPerUserPerMonth: 3000,
  },
  enterprise: {
    value: 49.99,
    annually: 499.99,
    rawAnnually: 599.88,
    storage: 20,
    collectionPerUserPerMonth: 3000,
    compositionsPerUserPerMonth: 2000,
    operationsPerUserPerMonth: 3000,
    integrationsPerUserPerMonth: 1500,
  },
};

type PricingCardProps = {
  title: string;
  monthlyPrice: number;
  annualPrice: number;
  rawAnnualPrice: number;
  features: string[];
  url?: string;
  borderColor?: ColorConfig;
};

type FeaturesData = Array<Array<React.ReactNode | boolean>>;

const PricingPage: FunctionComponent = () => {
  const { breakpoint, breakPointSmallDuo, resources } = useLandingPageLogic();
  const { colors } = useColors();
  const layout = breakpoint !== "small" ? [1, 1, 1] : [1];
  const smallLetter = breakpoint === "small" ? "400" : "600";

  const [isAnnually, setAnnually] = useState(true);

  const togglePaymentPeriod = useCallback(() => {
    setAnnually((prev) => !prev);
  }, []);

  const pricingCards: PricingCardProps[] = useMemo(() => {
    return [
      {
        title: resources.pricingPage.professionalPlan,
        monthlyPrice: prices.professional.value,
        annualPrice: prices.professional.annually,
        rawAnnualPrice: prices.professional.rawAnnually,
        url: "/sign-up",
        features: [
          resources.pricingPage.pricingCards.professional.feature1,
          resources.pricingPage.pricingCards.professional.feature2,
          resources.pricingPage.pricingCards.professional.feature3,
          resources.pricingPage.pricingCards.professional.feature4,
          resources.pricingPage.pricingCards.professional.feature5,
          resources.pricingPage.pricingCards.professional.feature6(
            prices.enterprise.collectionPerUserPerMonth
          ),
        ],
      },
      {
        title: resources.pricingPage.businessPlan,
        monthlyPrice: prices.business.value,
        annualPrice: prices.business.annually,
        rawAnnualPrice: prices.business.rawAnnually,
        url: "/sign-up",
        features: [
          resources.pricingPage.pricingCards.business.feature1,
          resources.pricingPage.pricingCards.business.feature2,
          resources.pricingPage.pricingCards.business.feature3,
          resources.pricingPage.pricingCards.business.feature4,
          resources.pricingPage.pricingCards.business.feature5,
          resources.pricingPage.pricingCards.business.feature6,
          resources.pricingPage.pricingCards.business.feature7,
          resources.pricingPage.pricingCards.business.feature8,
          resources.pricingPage.pricingCards.business.feature9,
          resources.pricingPage.pricingCards.business.feature10,
          resources.pricingPage.pricingCards.business.feature11,
        ],
        borderColor: colors.primary,
      },
      {
        title: resources.pricingPage.enterprisePlan,
        monthlyPrice: prices.enterprise.value,
        annualPrice: prices.enterprise.annually,
        rawAnnualPrice: prices.enterprise.rawAnnually,
        url: "/sign-up",
        features: [
          resources.pricingPage.pricingCards.enterprise.feature1,
          resources.pricingPage.pricingCards.enterprise.feature2,
          resources.pricingPage.pricingCards.enterprise.feature3,
          resources.pricingPage.pricingCards.enterprise.feature4,
          resources.pricingPage.pricingCards.enterprise.feature5,
          resources.pricingPage.pricingCards.enterprise.feature6(
            prices.enterprise.compositionsPerUserPerMonth
          ),
          resources.pricingPage.pricingCards.enterprise.feature7(
            prices.enterprise.operationsPerUserPerMonth
          ),
          resources.pricingPage.pricingCards.enterprise.feature8(
            prices.enterprise.integrationsPerUserPerMonth
          ),
          resources.pricingPage.pricingCards.enterprise.feature9,
        ],
      },
    ];
  }, [colors.primary, resources]);

  const featuresData: FeaturesData = useMemo(() => {
    return [
      [
        <Svg src={Cloud3D} alt="ProDoctivity Logo" />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature1} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature2} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature3} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature4} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature5} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature6} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature7} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature8} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature9} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature10} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature11} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature12} />,
      ],
      [
        <ColoredTitle text={resources.pricingPage.professionalPlan} />,
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      [
        <ColoredTitle text={resources.pricingPage.businessPlan} />,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        false,
      ],
      [
        <ColoredTitle text={resources.pricingPage.enterprisePlan} />,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
    ];
  }, [resources]);

  const enterFeaturesData: FeaturesData = useMemo(() => {
    return [
      [
        <Svg src={Cloud3D} alt="ProDoctivity Logo" />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature1} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature2} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature3} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature4} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature5} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature6} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature7} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature8} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature9} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature10} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature11} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature12} />,
      ],
      [
        <ColoredTitle text={resources.pricingPage.enterprisePlan} />,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
    ];
  }, [resources]);

  const proFeaturesData: FeaturesData = useMemo(() => {
    return [
      [
        <Svg src={Cloud3D} alt="ProDoctivity Logo" />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature1} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature2} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature3} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature4} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature5} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature6} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature7} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature8} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature9} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature10} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature11} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature12} />,
      ],
      [
        <ColoredTitle text={resources.pricingPage.professionalPlan} />,
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    ];
  }, [resources]);

  const businessFeaturesData: FeaturesData = useMemo(() => {
    return [
      [
        <Svg src={Cloud3D} alt="ProDoctivity Logo" />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature1} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature2} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature3} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature4} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature5} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature6} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature7} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature8} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature9} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature10} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature11} />,
        <Text weight="bold" children={resources.pricingPage.pricingTable.feature12} />,
      ],
      [
        <ColoredTitle text={resources.pricingPage.businessPlan} />,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        false,
      ],
    ];
  }, [resources]);

  return (
    <Box color={colors.white} overflow="hidden">
      {/* {HEADER} */}
      <VisitorsHeader />
      {/* {SLOGAN & TEXT} */}
      <Box display="flex" justifyContent="center" direction="row">
        <Box direction="column">
          <Box
            display={"flex"}
            gap={2}
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
          >
            <Text size={smallLetter} weight="bold" color={colors.primary700} align="center">
              {resources.choose}
            </Text>
            <Text size={smallLetter} weight="bold" color={colors.primary} align="center">
              {resources.pricingPage.theBestPlan}
            </Text>
            <Text size={smallLetter} weight="bold" color={colors.secondary} align="center">
              {resources.pricingPage.team}
            </Text>
          </Box>
          <Box
            marginTop={2}
            marginBottom={4}
            display="flex"
            width={"100%"}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Box display="flex" direction="row" gap={3}>
              <Text weight={isAnnually ? undefined : "bold"}>
                {resources.pricingPage.payMonthly}
              </Text>
              <Switch id="monthly_annually" onChange={togglePaymentPeriod} switched={isAnnually} />
              <Text weight={isAnnually ? "bold" : undefined}>
                {resources.pricingPage.payAnnually}
              </Text>
              <Badge text={resources.pricingPage.twoMonthsOff} color={colors.other200} />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* {PRICING CARDS} */}
      <Box
        display="flex"
        justifyContent="center"
        direction="row"
        paddingX={breakPointSmallDuo || breakpoint === "large" ? 4 : undefined}
      >
        <Grid gridTemplateColumns={layout} gap={3}>
          {pricingCards.map((card, index) => (
            <PricingCard
              key={card.title}
              index={index}
              title={card.title}
              isAnnually={isAnnually}
              monthlyPrice={card.monthlyPrice}
              annualPrice={card.annualPrice}
              rawAnnualPrice={card.rawAnnualPrice}
              features={card.features}
              borderColor={card.borderColor}
              url={card.url}
              togglePaymentPeriod={togglePaymentPeriod}
            />
          ))}
        </Grid>
      </Box>
      {/* { PRICING TABLE } */}
      {breakpoint === "small" ? (
        <Box
          width={"100%"}
          display="flex"
          direction="column"
          flex="shrink"
          maxWidth={1280}
          padding={4}
          gap={4}
        >
          <PricingTable headerColor={colors.primary} data={proFeaturesData} />
          <PricingTable headerColor={colors.primary} data={businessFeaturesData} />
          <PricingTable headerColor={colors.primary} data={enterFeaturesData} />
        </Box>
      ) : (
        <Box
          direction="column"
          flex="shrink"
          width={"100%"}
          display="flex"
          maxWidth={1400}
          margin={"auto"}
          borderRadius={12}
        >
          <PricingTable headerColor={colors.primary} data={featuresData} />
        </Box>
      )}

      <Box width={breakPointSmallDuo ? "90%" : "74%"} margin={"auto"}>
        <Box display="flex" direction="column">
          <PriceCalculator />
        </Box>
      </Box>

      <Box marginTop={12} justifyContent="center" direction="row" width={"100vw"}>
        <Box display="flex" direction="row" margin={"auto"} justifyContent="center">
          <Svg
            src={BusyCity}
            alt="Busy city with people"
            width={breakpoint === "small" ? "256px" : "auto"}
          />
        </Box>
      </Box>
      {/* {Footer} */}
      <Box display="flex" justifyContent="center" direction="row">
        <VisitorsFooter breakPointSmallDuo={breakPointSmallDuo} breakpoints={breakpoint} />
      </Box>
    </Box>
  );
};

// type Props = {};

export const PriceCalculator: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  const i18n = useCallback(
    (arg: string) => {
      const obj: any = resources;
      if (obj[arg] !== undefined) {
        return obj[arg];
      }

      return arg;
    },
    [resources]
  );

  const [calculatorContext, setCalculatorContext] = useState<{
    plan: "professional" | "business" | "enterprise";
    billingPeriod: "monthly" | "annual";
    userCount: number;
    storage: number;
    composeWithAPI: boolean;
    documentCompositionUsage: number;
    operateWithAPI: boolean;
    documentOperationUsage: number;
    integrationsUsage: number;
    collectionUsage: number;
  }>({
    plan: "business",
    userCount: 5,
    storage: 75,
    composeWithAPI: false,
    documentCompositionUsage: 10000,
    operateWithAPI: false,
    documentOperationUsage: 10000,
    billingPeriod: "annual",
    integrationsUsage: 1000,

    collectionUsage: 3000,
  });

  const { total, includedStorage } = useMemo(() => {
    const users =
      calculatorContext.userCount === undefined
        ? 1
        : Number.isNaN(calculatorContext.userCount)
        ? 1
        : calculatorContext.userCount || 1;
    const storage =
      calculatorContext.storage === undefined
        ? 1
        : Number.isNaN(calculatorContext.storage)
        ? 1
        : calculatorContext.storage || 1;

    let documentCompositionUsage =
      calculatorContext.documentCompositionUsage === undefined
        ? 0
        : Number.isNaN(calculatorContext.documentCompositionUsage)
        ? 0
        : Math.max(
            0,
            (calculatorContext.plan === "enterprise"
              ? calculatorContext.userCount * -1 * prices.enterprise.compositionsPerUserPerMonth
              : 0) + calculatorContext.documentCompositionUsage
          ) / 1000;
    if (documentCompositionUsage > 0) {
      documentCompositionUsage = Math.ceil(documentCompositionUsage);
    }

    let documentOperationUsage =
      calculatorContext.documentOperationUsage === undefined
        ? 0
        : Number.isNaN(calculatorContext.documentOperationUsage)
        ? 0
        : Math.max(
            0,
            (calculatorContext.plan === "enterprise"
              ? calculatorContext.userCount * -1 * prices.enterprise.operationsPerUserPerMonth
              : 0) + calculatorContext.documentOperationUsage
          ) / 1000;

    if (documentOperationUsage > 0) {
      documentOperationUsage = Math.ceil(documentOperationUsage);
    }

    let integrationsUsage =
      calculatorContext.integrationsUsage === undefined
        ? 0
        : Number.isNaN(calculatorContext.integrationsUsage)
        ? 0
        : Math.max(
            0,
            (calculatorContext.plan === "enterprise"
              ? calculatorContext.userCount * -1 * prices.enterprise.integrationsPerUserPerMonth
              : 0) + calculatorContext.integrationsUsage
          ) / 1000;

    if (integrationsUsage > 0) {
      integrationsUsage = Math.ceil(integrationsUsage);
    }

    let collectionUsage =
      calculatorContext.collectionUsage === undefined
        ? 0
        : Number.isNaN(calculatorContext.collectionUsage)
        ? 0
        : Math.max(
            0,
            (calculatorContext.plan === "professional" || "business" || "enterprise"
              ? calculatorContext.userCount * -1 * prices.enterprise.collectionPerUserPerMonth
              : 0) + calculatorContext.collectionUsage
          ) / 1000;

    if (collectionUsage > 0) {
      collectionUsage = Math.ceil(collectionUsage);
    }

    switch (calculatorContext.billingPeriod) {
      case "annual": {
        switch (calculatorContext.plan) {
          case "professional": {
            return {
              total:
                prices.professional.annually * users +
                prices.storage.annually *
                  Math.max(0, storage - users * prices.professional.storage) +
                prices.collection.annually * collectionUsage +
                prices.integrations.annually * integrationsUsage,
              includedStorage: prices.professional.storage,
            };
          }
          case "business": {
            return {
              total:
                prices.business.annually * users +
                prices.storage.annually * Math.max(0, storage - users * prices.business.storage) +
                (calculatorContext.composeWithAPI
                  ? prices.composition.annually * documentCompositionUsage
                  : 0) +
                (calculatorContext.operateWithAPI
                  ? prices.api.annually * documentOperationUsage
                  : 0) +
                (calculatorContext.collectionUsage
                  ? prices.collection.annually * collectionUsage
                  : 0) +
                prices.integrations.annually * integrationsUsage,
              includedStorage: prices.business.storage,
            };
          }
          case "enterprise": {
            return {
              total:
                prices.enterprise.annually * users +
                prices.storage.annually * Math.max(0, storage - users * prices.enterprise.storage) +
                (calculatorContext.composeWithAPI
                  ? prices.composition.annually * documentCompositionUsage
                  : 0) +
                (calculatorContext.operateWithAPI
                  ? prices.api.annually * documentOperationUsage
                  : 0) +
                (calculatorContext.collectionUsage
                  ? prices.collection.annually * collectionUsage
                  : 0) +
                prices.integrations.annually * integrationsUsage,
              includedStorage: prices.enterprise.storage,
            };
          }
        }
        break;
      }

      case "monthly":
      default: {
        switch (calculatorContext.plan) {
          case "professional": {
            return {
              total:
                prices.professional.value * users +
                prices.storage.monthly *
                  Math.max(0, storage - users * prices.professional.storage) +
                prices.collection.monthly * collectionUsage +
                prices.integrations.monthly * integrationsUsage,
              includedStorage: prices.professional.storage,
            };
          }
          case "business": {
            return {
              total:
                prices.business.value * users +
                prices.storage.monthly * Math.max(0, storage - users * prices.business.storage) +
                (calculatorContext.composeWithAPI
                  ? prices.composition.monthly * documentCompositionUsage
                  : 0) +
                (calculatorContext.operateWithAPI
                  ? prices.api.monthly * documentOperationUsage
                  : 0) +
                prices.collection.monthly * collectionUsage +
                prices.integrations.monthly * integrationsUsage,
              includedStorage: prices.business.storage,
            };
          }
          case "enterprise": {
            return {
              total:
                prices.enterprise.value * users +
                prices.storage.monthly * Math.max(0, storage - users * prices.enterprise.storage) +
                (calculatorContext.composeWithAPI
                  ? prices.composition.monthly * documentCompositionUsage
                  : 0) +
                (calculatorContext.operateWithAPI
                  ? prices.api.monthly * documentOperationUsage
                  : 0) +
                prices.collection.monthly * collectionUsage +
                prices.integrations.monthly * integrationsUsage,
              includedStorage: prices.enterprise.storage,
            };
          }
        }
      }
    }
  }, [calculatorContext]);

  const onChange = useCallback(
    (args: { data: ParametersObject }) => {
      const newContext = { ...args.data } as typeof calculatorContext;

      if (newContext.storage < includedStorage) {
        newContext.storage = includedStorage;
      }

      setCalculatorContext(newContext);
    },
    [includedStorage]
  );

  const { contextDefinition, wizardDefinition } = useMemo(() => {
    const contextDefinition: TemplateContextDefinition = {
      fields: [
        {
          name: "plan",
          fullPath: "plan",
          humanName: "Plan",
          properties: {
            sequenceId: undefined,
            dataType: "Alphanumeric",
            description: "",
            label: resources.pricingPage.pricingCalculator.choose,
            humanName: "Plan",
            inputType: "Radio",
            instructions: "",
            valueList: [
              {
                value: "professional",
                label: resources.pricingPage.professionalPlan,
              },
              {
                value: "business",
                label: resources.pricingPage.businessPlan,
              },
              {
                value: "enterprise",
                label: resources.pricingPage.enterprisePlan,
              },
            ],
            defaultValue: ["business"],
            minOccurs: 1,
            maxOccurs: 1,
          },
        },
        {
          name: "billingPeriod",
          fullPath: "billingPeriod",
          humanName: "Billing Period",
          properties: {
            sequenceId: undefined,
            dataType: "Alphanumeric",
            description: "",
            label: resources.billing,
            humanName: "billingPeriod",
            inputType: "Radio",
            instructions: "",
            valueList: [
              {
                value: "annual",
                label: resources.upgradePage.yearly,
              },
              {
                value: "monthly",
                label: resources.upgradePage.monthly,
              },
            ],
            defaultValue: ["annual"],
            minOccurs: 1,
            maxOccurs: 1,
          },
        },
        {
          name: "userCount",
          fullPath: "userCount",
          humanName: "User Count",
          properties: {
            sequenceId: undefined,
            dataType: "Numeric",
            description: resources.pricingPage.pricingCalculator.userCount,
            label: resources.pricingPage.pricingCalculator.organizationUser,
            humanName: "User Count",
            inputType: "TextBox",
            placeholder: "Number of users",
            instructions: "",
            minValue: 1,
            defaultValue: [5],
            minOccurs: 1,
            maxOccurs: 1,
          },
        },
        {
          name: "storage",
          fullPath: "storage",
          humanName: "Storage",
          properties: {
            sequenceId: undefined,
            dataType: "Numeric",
            description: `${resources.pricingPage.pricingCalculator.gigabytes}`,
            label: resources.pricingPage.pricingCalculator.storage,
            humanName: "Storage",
            inputType: "TextBox",
            placeholder: "Enter initial storage requirement",
            instructions: "",
            minValue: 0, // (calculatorContext.userCount || 1) * includedStorage,
            maxValue: 15000,
            defaultValue: [(calculatorContext.userCount || 1) * includedStorage],
            minOccurs: 1,
            maxOccurs: 1,
          },
        },
        {
          name: "composeWithAPI",
          fullPath: "composeWithAPI",
          humanName: "Compose With API",
          properties: {
            sequenceId: undefined,
            dataType: "Logical",
            description: "",
            label: resources.pricingPage.pricingCalculator.apiCompose,
            humanName: "Compose With API",
            inputType: "Checkbox",
            instructions: "",
            minOccurs: 1,
            maxOccurs: 1,
          },
        },
        {
          name: "documentCompositionUsage",
          fullPath: "documentCompositionUsage",
          humanName: "Document Composition Usage",
          properties: {
            sequenceId: undefined,
            dataType: "Numeric",
            description: resources.pricingPage.pricingCalculator.composeDescription,
            label: resources.pricingPage.pricingCalculator.howManyCompose,
            humanName: "Document Composition Usage",
            placeholder: "Number of documents per month",
            inputType: "TextBox",
            instructions: "",
            defaultValue: [10000],
            minOccurs: 1,
            maxOccurs: 1,
            minValue: 0,
            maxValue: 100000000,
          },
        },
        {
          name: "operateWithAPI",
          fullPath: "operateWithAPI",
          humanName: "Operate With API",
          properties: {
            sequenceId: undefined,
            dataType: "Logical",
            description: "",
            label: resources.pricingPage.pricingCalculator.apiOperate,
            humanName: "Operate With API",
            inputType: "Checkbox",
            instructions: "",
            minOccurs: 1,
            maxOccurs: 1,
          },
        },
        {
          name: "documentOperationUsage",
          fullPath: "documentOperationUsage",
          humanName: "Document Operation Usage",
          properties: {
            sequenceId: undefined,
            dataType: "Numeric",
            description: resources.pricingPage.pricingCalculator.operationDescription,
            label: resources.pricingPage.pricingCalculator.howManyOperation,
            humanName: "Document Operation Usage",
            placeholder: "Number of operations per month",
            inputType: "TextBox",
            instructions: "",
            defaultValue: [10000],
            minOccurs: 1,
            maxOccurs: 1,
            minValue: 0,
            maxValue: 100000000,
          },
        },
        {
          name: "integrationsUsage",
          fullPath: "integrationsUsage",
          humanName: "Integrations Usage",
          properties: {
            sequenceId: undefined,
            dataType: "Numeric",
            description: resources.pricingPage.pricingCalculator.integrationDescription,
            label: resources.pricingPage.pricingCalculator.integrationUse,
            humanName: "Integrations Usage",
            placeholder: "Number of integrations per month",
            inputType: "TextBox",
            instructions: "",
            defaultValue: [1000],
            minOccurs: 1,
            maxOccurs: 1,
            minValue: 0,
            maxValue: 100000000,
          },
        },

        {
          name: "collectionUsage",
          fullPath: "collectionUsage",
          humanName: "Collection Usage",
          properties: {
            sequenceId: undefined,
            dataType: "Numeric",
            description: resources.pricingPage.pricingCalculator.collectionDescription,
            label: resources.pricingPage.pricingCalculator.collectionUse,
            humanName: "Collection Usage",
            placeholder: "Number of Document Collection Instances",
            inputType: "TextBox",
            instructions: "",
            defaultValue: [3000],
            minOccurs: 1,
            maxOccurs: 1,
            minValue: 0,
            maxValue: 100000000,
          },
        },
      ],
      records: [],
    };

    const wizardDefinition: TemplateWizardDefinition = {
      defaultPageName: "page1",
      defaultSectionName: "Organization Information",
      dependencies: [
        {
          name: "API Document Composition dependency",
          expression: {
            operatorType: "equals",
            source: "composeWithAPI",
            isNegative: true,
            targetFixedValue: true,
          },
          items: [
            {
              key: "documentCompositionUsage",
              type: "field",
              action: "hide",
            },
          ],
        },
        {
          name: "API Document Operation dependency",
          expression: {
            operatorType: "equals",
            source: "operateWithAPI",
            isNegative: true,
            targetFixedValue: true,
          },
          items: [
            {
              key: "documentOperationUsage",
              type: "field",
              action: "hide",
            },
          ],
        },
        {
          name: "Hide API questions",
          expression: {
            operatorType: "equals",
            source: "plan",
            isNegative: false,
            targetFixedValue: "professional",
          },
          items: [
            {
              key: "composeWithAPI",
              type: "field",
              action: "hide",
            },
            {
              key: "documentCompositionUsage",
              type: "field",
              action: "hide",
            },
            {
              key: "operateWithAPI",
              type: "field",
              action: "hide",
            },
            {
              key: "documentOperationUsage",
              type: "field",
              action: "hide",
            },
          ],
        },
      ],
      inferredDependencies: [],
      pages: [
        {
          key: "page1",
          description: "",
          label: "page1",
          properties: {},
          sections: [
            {
              key: "section1",
              description: "",
              label: resources.pricingPage.pricingCalculator.organizationDetails,
              properties: {},
              fields: [
                {
                  isRecord: false,
                  key: "plan",
                  label: "Choose your plan",
                  properties: {
                    controlSize: "Large",
                  },
                },
                {
                  isRecord: false,
                  key: "billingPeriod",
                  label: "",
                  properties: {
                    controlSize: "Large",
                  },
                },
                {
                  isRecord: false,
                  key: "userCount",
                  label: "How many users does your organization have?",
                  properties: {
                    controlSize: "Large",
                  },
                },
                {
                  isRecord: false,
                  key: "storage",
                  label: "How much storage do you need right now?",
                  properties: {
                    controlSize: "Large",
                  },
                },
                {
                  isRecord: false,
                  key: "composeWithAPI",
                  label: "Will you compose documents via our API?",
                  properties: {
                    controlSize: "Large",
                  },
                },
                {
                  isRecord: false,
                  key: "documentCompositionUsage",
                  label: "How many documents will you compose a month?",
                  properties: {
                    controlSize: "Large",
                  },
                },
                {
                  isRecord: false,
                  key: "operateWithAPI",
                  label: "",
                  properties: {
                    controlSize: "Large",
                  },
                },
                {
                  isRecord: false,
                  key: "documentOperationUsage",
                  label: "",
                  properties: {
                    controlSize: "Large",
                  },
                },
                {
                  isRecord: false,
                  key: "integrationsUsage",
                  label: "",
                  properties: {
                    controlSize: "Large",
                  },
                },

                {
                  isRecord: false,
                  key: "collectionUsage",
                  label: "",
                  properties: {
                    controlSize: "Large",
                  },
                },
              ],
            },
          ],
        },
      ],
    };

    return { contextDefinition, wizardDefinition };
  }, [resources, calculatorContext.userCount, includedStorage]);

  const {
    formDefinition,
    formConfiguration,
    executeDataLink,
    onFireConnector,
    dataLinkMappings,
    connectors,
    dataLinks,
    connectorDataLinks,
  } = useMemo(() => {
    return {
      formDefinition: { allowedListValues: [], contextFields: [], groups: [] },
      formConfiguration: { formConnectors: [], formLayout: { layout: [], groupLayouts: {} } },
      executeDataLink: () =>
        Promise.resolve({
          contexts: [],
        }),
      onFireConnector: () => Promise.resolve({}),
      dataLinkMappings: [],
      connectors: [],
      dataLinks: [],
      connectorDataLinks: [],
    };
  }, []);

  return (
    <Box display="flex" direction="column">
      <Box margin={2} />
      <Box marginStart={4}>
        <Heading size="500" color={colors.secondary}>
          {resources.pricingPage.pricingCalculator.estimate}
        </Heading>
      </Box>

      <Box margin={2} />
      <Box>
        <FormControllerProvider
          contextDefinition={contextDefinition}
          wizardDefinition={wizardDefinition}
          dataLinkMappings={dataLinkMappings}
          initialContext={calculatorContext}
          executeDataLink={undefined}
          moment={moment}
        >
          <ContextStateUpdater onUpdate={onChange} />
          <FormDataCapture
            executeDataLink={executeDataLink}
            onFireConnector={onFireConnector}
            formConfiguration={formConfiguration}
            formDefinition={formDefinition}
            connectors={connectors}
            dataLinks={dataLinks}
            connectorDataLinks={connectorDataLinks}
            dataLinkMappings={dataLinkMappings}
            onUpdateValues={noop}
            readOnly={false}
            i18n={i18n}
            onFormErrorsUpdate={noop}
            onDependencyLayoutUpdate={noop}
            onNoDataFound={noop}
            purpose="real-time"
            moment={moment}
            resources={resources}
          />
        </FormControllerProvider>
      </Box>
      <Box>
        <Box marginStart={4} display="flex" direction="row" gap={4}>
          <Heading size="600" color={colors.black600}>
            {calculatorContext.billingPeriod === "monthly"
              ? resources.pricingPage.pricingCalculator.monthly
              : resources.pricingPage.pricingCalculator.twelveMonth}
          </Heading>
          <Text size="500" key={total}>
            USD {formatCurrency(total)}
          </Text>
        </Box>
        {/* <Box>{JSON.stringify(calculatorContext, null, 2)}</Box> */}
      </Box>
    </Box>
  );
};

export default PricingPage;
