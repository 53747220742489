import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const UserMenuIcon: FunctionComponent<Props> = ({ color }) => {
  const { colors } = useColors();
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.5962 29.0442C31.3488 29.4854 30.9883 29.8526 30.5516 30.1079C30.115 30.3632 29.6181 30.4974 29.1123 30.4965H10.8806C10.3746 30.4976 9.87756 30.3635 9.44073 30.1082C9.0039 29.8529 8.64314 29.4856 8.39573 29.0442C8.138 28.6111 8.00195 28.1164 8.00195 27.6124C8.00195 27.1084 8.138 26.6137 8.39573 26.1806L8.77447 25.5278C9.88901 23.5615 11.4905 21.9147 13.425 20.7459C14.1808 21.5214 15.0846 22.1373 16.0829 22.5569C17.0811 22.9766 18.1535 23.1914 19.2364 23.1887C20.4438 23.1921 21.637 22.9278 22.7301 22.4149C23.8231 21.902 24.7889 21.1532 25.5579 20.2224C27.943 21.3973 29.9279 23.2505 31.2636 25.5494L31.6218 26.1806C31.8757 26.616 32.0073 27.1119 32.0028 27.6159C31.9983 28.1199 31.8578 28.6134 31.5962 29.0442Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M25.2795 15.0423C25.2562 15.9272 25.0409 16.7964 24.6483 17.5898C24.5014 17.9131 24.3251 18.2224 24.1217 18.5135C23.8973 18.8316 23.6433 19.1279 23.3632 19.3983C22.2334 20.4864 20.7236 21.0907 19.1551 21.0826C17.7757 21.0844 16.4367 20.6175 15.3574 19.7585C15.0834 19.5061 14.8104 19.232 14.5568 18.958C14.3226 18.6854 14.1179 18.3888 13.9461 18.0732C13.4052 17.1546 13.1216 16.1073 13.125 15.0413C13.1457 13.4522 13.7915 11.9353 14.9225 10.819C16.0535 9.70261 17.5787 9.07666 19.1679 9.07666C20.757 9.07666 22.2822 9.70261 23.4133 10.819C24.5443 11.9353 25.19 13.4522 25.2107 15.0413L25.2795 15.0423Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
    </svg>
  );
};
