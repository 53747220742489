import { Box, Table, Text } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { DocumentTypesInitialState } from "./hooks";
import type { DocumentTypeSubscriptionType } from "@prodoctivity/types";
import { DocumentTypeInternalRows } from "./DocumentTypeInternalRows";

type Props = {
  documentTypeExtractUniqueElements: (
    subscriptions: DocumentTypesInitialState
  ) => DocumentTypesInitialState;
  currentDocumentTypeSubscriptions: DocumentTypesInitialState;
  handleDocTypeCheckBoxOnChange: (
    checkBoxCase: string,
    documentTypeId: string,
    subscriptionType: DocumentTypeSubscriptionType
  ) => void;
  documentTypeIsMutating: boolean;
};

export const DoctypeSubscriptionsTable: FunctionComponent<Props> = ({
  documentTypeExtractUniqueElements,
  currentDocumentTypeSubscriptions,
  handleDocTypeCheckBoxOnChange,
  documentTypeIsMutating,
}) => {
  const uniqueDocumentTypes = documentTypeExtractUniqueElements(currentDocumentTypeSubscriptions);
  return (
    <>
      {uniqueDocumentTypes.map((subscription) => {
        return (
          <Table.RowExpandable
            accessibilityExpandLabel="Expand"
            accessibilityCollapseLabel="Collapse"
            id="row2"
            expandedContents={
              <Box marginTop={-6} marginBottom={-6}>
                <DocumentTypeInternalRows
                  currentDocumentTypeSubscriptions={currentDocumentTypeSubscriptions}
                  documentTypeIsMutating={documentTypeIsMutating}
                  handleDocTypeCheckBoxOnChange={handleDocTypeCheckBoxOnChange}
                  uniqueDocumentTypeId={subscription.documentTypeId}
                />
              </Box>
            }
          >
            <Table.Cell colSpan={3}>
              <Box width={500}>
                <Text>{subscription.documentTypeName}</Text>
              </Box>
            </Table.Cell>
            <Table.Cell colSpan={3}>
              <Text></Text>
            </Table.Cell>
            <Table.Cell colSpan={3}>
              <Text></Text>
            </Table.Cell>
            <Table.Cell colSpan={3}>
              <Text></Text>
            </Table.Cell>
          </Table.RowExpandable>
        );
      })}
    </>
  );
};
