import { Box, IconButton, TapArea, Tooltip, useColors } from "@prodoctivity/design-system";
import { ChangeEvent, FunctionComponent, useCallback, useRef, useState } from "react";

import type { DictionaryList } from "@prodoctivity/shared/src/index-types";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { isValid } from "./utils";

type ImportListButtonProps = {
  updateList: (list: DictionaryList<string>) => void;
};

export const ImportListButton: FunctionComponent<ImportListButtonProps> = ({ updateList }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, []);
  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const listFile = event.target.files && event.target.files[0];
      if (!listFile) {
        return;
      }
      event.currentTarget.value = "";
      const fileReader = new FileReader();
      fileReader.readAsText(listFile);
      fileReader.onload = (e) => {
        const contents = e.target && e.target.result;
        if (contents && typeof contents === "string") {
          try {
            const list = JSON.parse(contents);
            if (!isValid(list)) {
              throw new Error("Invalid List");
            } else {
              updateList(list);
            }
          } catch (error) {
            setShowErrorToast(true);
            setTimeout(() => {
              setShowErrorToast(false);
            }, 3000);
          }
        }
      };
    },
    [updateList]
  );

  return (
    <Tooltip text={resources.dataDictionary.importList}>
      <input type={"file"} ref={inputRef} style={{ display: "none" }} onChange={handleFileChange} />
      <TapArea onTap={handleClick}>
        <Box display="flex" gap={1} direction="row">
          <IconButton
            color={colors.subtle}
            accessibilityLabel={resources.dataDictionary.importList}
            icon="down-to-bracket"
            size={5}
            onClick={handleClick}
          />
          {/* <Text>{resources.dataDictionary.importList}</Text> */}
        </Box>
      </TapArea>
      {showErrorToast ? (
        <Box width="100%" position="absolute" bottom>
          <NotificationMessage
            type={"error"}
            message={resources.dataDictionary.invalidFile}
            position="bottom-left"
            handleDismiss={() => setShowErrorToast(false)}
          />
        </Box>
      ) : null}
    </Tooltip>
  );
};
