import {
  Box,
  Checkbox,
  PasswordSetterField,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import { cleanPhone, validatePhoneNumber } from "@prodoctivity/shared";
import { FC, useCallback } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { SignUpFieldsGroup } from "../../utils";

type UIEvent = { event: React.SyntheticEvent<HTMLInputElement>; value: string };

type Props = {
  updateInput(input: SignUpFieldsGroup): void;
  currentInput: SignUpFieldsGroup;
  emailValidation: boolean;
  phoneValidation: boolean;
};

const AccountSignUp: FC<Props> = ({ updateInput, currentInput }) => {
  const { firstName, lastName, password, email, phone, isEnrolledForMarketing } =
    currentInput.account;

  const { colors } = useColors();

  const { resources } = useAppTranslation();

  // const [showPassword, setShowPassword] = useState(false);
  const updatePhone = useCallback(
    (e: UIEvent) => {
      const numericValue = e.value.replace(/\D/g, "");
      updateInput({
        ...currentInput,
        account: { ...currentInput.account, phone: numericValue },
      });
    },
    [currentInput, updateInput]
  );

  const updateEmail = useCallback(
    (e: UIEvent) => {
      updateInput({ ...currentInput, account: { ...currentInput.account, email: e.value } });
    },
    [currentInput, updateInput]
  );

  return (
    <Box display="flex" direction="column" justifyContent="center" gap={8}>
      <Box>
        <Box display="flex" direction="column" gap={2}>
          <Box>
            <Text weight="bold">{resources.name}</Text>
          </Box>
          <TextField
            id="firstName"
            value={firstName}
            onChange={(e) => {
              updateInput({
                ...currentInput,
                account: { ...currentInput.account, firstName: e.value },
              });
            }}
            placeholder={resources.name}
            label={resources.name}
            labelDisplay="hidden"
          />
        </Box>
      </Box>
      <Box>
        <Box display="flex" direction="column" gap={2}>
          <Box>
            <Text weight="bold">{resources.lastName}</Text>
          </Box>
          <TextField
            id="lastName"
            value={lastName}
            onChange={(e) => {
              updateInput({
                ...currentInput,
                account: { ...currentInput.account, lastName: e.value },
              });
            }}
            placeholder={resources.lastName}
            label={resources.lastName}
            labelDisplay="hidden"
          />
        </Box>
      </Box>
      <Box>
        <Box display="flex" direction="column" gap={2}>
          <Box display="flex" direction="row" justifyContent="between">
            <Text weight="bold">{resources.password}</Text>
            {/* <Link
              href=""
              onClick={(event) => {
                setShowPassword(!showPassword);
                event.event.preventDefault();
              }}
            >
              <Text color={colors.primary}>
                {showPassword ? resources.hide : resources.show}
              </Text>
            </Link> */}
          </Box>
          <PasswordSetterField
            id="newPasswordField"
            value={password}
            onChange={(value) => {
              updateInput({
                ...currentInput,
                account: { ...currentInput.account, password: value },
              });
            }}
            resources={resources}
            labelDisplay="hidden"
          />
        </Box>
      </Box>
      <Box display="flex" direction="column" gap={2}>
        <Box>
          <Text weight="bold">{resources.email}</Text>
        </Box>
        <TextField
          id="emailField"
          value={email}
          onChange={updateEmail}
          placeholder="name@example.com"
          label="Email"
          type="email"
          labelDisplay="hidden"
        />
      </Box>
      <Box display="flex" direction="column" gap={2}>
        <Box display="flex" direction="row" justifyContent="between">
          <Text weight="bold">{resources.phone}</Text>
          <Text color={colors.neutral500}>({resources.optional})</Text>
        </Box>
        <TextField
          id="phoneField"
          value={phone}
          onChange={updatePhone}
          placeholder="(555) 555-5555"
          label="phone"
          type="tel"
          labelDisplay="hidden"
          errorMessage={
            phone
              ? validatePhoneNumber(cleanPhone(phone), true)
                ? undefined
                : resources.validations.invalidPhoneNumber
              : undefined
          }
        />
      </Box>
      <Box>
        <Checkbox
          checked={isEnrolledForMarketing}
          onChange={({ checked }) =>
            updateInput({
              ...currentInput,
              account: { ...currentInput.account, isEnrolledForMarketing: checked },
            })
          }
          id="isEnrolledForMarketing"
          label={resources.iWantEmails}
        />
      </Box>
    </Box>
  );
};

export { AccountSignUp };
