import type { ReportHeaderData, RootAssignedGroupedDataSet } from "@prodoctivity/types";
import { useCallback, useMemo, useState } from "react";
import { useServices } from "../../../hooks/useServices";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { DocumentCollectionReportParameters } from "../../../components/Widgets/DocumentCollectionReport/documentCollectionReportParameters";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { Box, FormControllerProvider, useColors } from "@prodoctivity/design-system";
import moment from "moment";
import { WidgeReportForm } from "../../../components/Widgets/DocumentCollectionReport/WidgetReportForm";
import { ReportTableDataView } from "../components/wigets/table/TableDataView";

export type State = {
  widgetParams: DocumentCollectionReportParameters;
  widgetData: {
    filter: string;
    dataSet: Array<RootAssignedGroupedDataSet>;
    sortBy: "default" | "asc" | "desc";
    sortedCol: string;
  };
};

const defaultData: ReportHeaderData = {
  filters: [],
  initialData: {
    expired: 0,
    expiring: 0,
    issue: 0,
    total: 0,
    valid: 0,
  },
  assignments: [],
};

const useAssignment = (parameters: any) => {
  const { getDocumentCollectionFilters, getDocumentCollectionKeyNameList } = useServices();
  const { resources } = useAppTranslation();
  const [state, setState] = useState<State>({
    widgetData: {
      filter: "",
      dataSet: [],
      sortBy: "default",
      sortedCol: "",
    },
    widgetParams: {
      collectionId: parameters.params["collectionId"],
      editionMode: false,
      collectionName: parameters.params["additionalWidgetTitle"],
      elementParametersOnChange: parameters["setNewParams"],
      availableCollections: [],
    },
  });

  const changeSort = useCallback((sortBy: "default" | "asc" | "desc", sortedCol: string) => {
    setState((prev) => {
      return {
        ...prev,
        widgetData: {
          ...prev.widgetData,
          sortBy: sortBy,
          sortedCol: sortedCol,
        },
      };
    });
  }, []);

  const getConfiguration = useCallback(async () => {
    if (state.widgetParams.editionMode) return defaultData;
    if (!state.widgetParams.collectionId) return defaultData;
    if (!parameters) return defaultData;
    if (state.widgetParams.collectionId === "") return defaultData;
    return await getDocumentCollectionFilters(state.widgetParams.collectionId);
  }, [
    state.widgetParams.collectionId,
    getDocumentCollectionFilters,
    state.widgetParams.editionMode,
    ,
    parameters,
  ]);

  const saveConfiguration = useCallback(
    (contextValue?: any) => {
      const newObjectWithProps = { ...parameters.params };
      newObjectWithProps.additionalWidgetTitle = state.widgetParams.availableCollections.find(
        (x) => x.id === contextValue["CollectionName"]
      )?.name;
      newObjectWithProps.collectionId = contextValue["CollectionName"];
      state.widgetParams.elementParametersOnChange(
        newObjectWithProps,
        parameters["setOfIndexes"],
        undefined
      );
    },
    [parameters, state]
  );

  const getCollectionConfig = useCallback(async () => {
    return await getDocumentCollectionKeyNameList();
  }, [getDocumentCollectionKeyNameList]);

  useOrganizationQuery(`/collection-list/${state.widgetParams.editionMode}`, getCollectionConfig, {
    onSuccess(data) {
      setState((prev) => {
        const optionsList: Array<{ id: string; name: string }> = [];
        optionsList.push(...data.list.map((x) => ({ id: x.collectionId, name: x.collectionName })));
        return {
          ...prev,
          widgetParams: {
            ...prev.widgetParams,
            availableCollections: optionsList,
          },
        };
      });
    },
  });

  const {
    data: filtersConfig,
    isError,
    isLoading,
  } = useOrganizationQuery(
    `/collection-config/filters/${JSON.stringify(state.widgetParams)}`,
    getConfiguration,
    {
      onSuccess(data) {
        if (state.widgetParams.editionMode) {
          return;
        }

        const updateParams = parameters.params;
        if (data.initialData) {
          const regex = /\(\d+\)/;
          const appendContent = ` (${data.initialData.total})`;
          if (updateParams.additionalWidgetTitle) {
            updateParams.additionalWidgetTitle += regex.test(updateParams.additionalWidgetTitle)
              ? ""
              : appendContent;
            state.widgetParams.elementParametersOnChange(
              updateParams,
              parameters["setOfIndexes"],
              undefined
            );
          }
        }

        setState((prev) => {
          return {
            ...prev,
            widgetData: {
              ...prev.widgetData,
              dataSet: data.assignments,
            },
          };
        });
      },
    }
  );

  const formConfig = useMemo(() => {
    return {
      inputContextDefinition: {
        fields: [
          {
            name: "CollectionName",
            fullPath: "CollectionName",
            humanName: "Collection Name",
            properties: {
              dataType: "Alphanumeric",
              defaultValue: [state.widgetParams.collectionId],
              description: resources.selectACollection,
              humanName: "Collection Name",
              inputType: "Dropdown",
              instructions: resources.selectACollection,
              label: resources.collectionName,
              maxLength: 100,
              sampleValue: ["Customers"],
              minOccurs: 1,
              maxOccurs: 1,
              saveToECM: true,
              valueList: state.widgetParams.availableCollections.map((x) => ({
                value: x.id,
                label: x.name,
              })),
            },
          },
        ],
        records: [],
      },
      inputWizardDefinition: {
        defaultPageName: "",
        defaultSectionName: "",
        dependencies: [],
        inferredDependencies: [],
        pages: [
          {
            key: "bundleInformation",
            label: "Bundle information",
            description: "Bundle information",
            properties: {},
            sections: [
              {
                key: "bundleSection",
                label: resources.formRequirementInfo,
                description: resources.formRequirement,
                properties: {},
                fields: [
                  {
                    key: "CollectionName",
                    isRecord: false,
                    label: "Collection Name",
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  }, [
    resources.collectionName,
    resources.formRequirementInfo,
    resources.formRequirement,
    state.widgetParams.collectionId,
    state.widgetParams.availableCollections,
    resources.selectACollection,
  ]);

  const setEditMode = useCallback((state: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        widgetParams: {
          ...prev.widgetParams,
          editionMode: state,
        },
      };
    });
  }, []);

  const updateFilterValue = useCallback((value: string) => {
    setState((prev) => {
      return {
        ...prev,
        widgetData: {
          ...prev.widgetData,
          filter: value,
        },
      };
    });
  }, []);

  return {
    state,
    changeSort,
    isError,
    isLoading,
    filtersConfig,
    formConfig,
    saveConfiguration,
    setEditMode,
    updateFilterValue,
  };
};

export const AssignmentList = (parameters: any) => {
  const {
    setEditMode,
    saveConfiguration,
    formConfig,
    state,
    isLoading,
    changeSort,
    updateFilterValue,
  } = useAssignment(parameters);
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  useMemo(() => {
    setEditMode(parameters["allowParamEdition"] as boolean);
  }, [parameters, setEditMode]);

  const editModeContent = useCallback(() => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        direction="column"
        height={400}
        padding={4}
        position="relative"
      >
        <Box
          display="flex"
          flex="grow"
          position="absolute"
          color={colors.neutral600}
          width={"100%"}
          opacity={0.2}
        ></Box>

        <FormControllerProvider
          contextDefinition={formConfig.inputContextDefinition as any}
          wizardDefinition={formConfig.inputWizardDefinition as any}
          dataLinkMappings={[]}
          executeDataLink={undefined}
          moment={moment}
        >
          <WidgeReportForm
            saveConfiguration={saveConfiguration}
            resetConfiguration={() => console.log()}
          />
        </FormControllerProvider>
      </Box>
    );
  }, [
    colors.neutral600,
    formConfig.inputContextDefinition,
    formConfig.inputWizardDefinition,
    saveConfiguration,
  ]);

  const invalidConfigurationContent = useCallback(() => {
    return (
      <Box display="flex" direction="column" alignItems="center">
        <Box margin={4} padding={4}>
          {resources.noDataLoaded}
        </Box>
        <Box></Box>
      </Box>
    );
  }, [resources.noDataLoaded]);

  const renderContentDependEditionMode = useMemo(() => {
    const allowEdition = state.widgetParams.editionMode;
    const hasCollectionId = state.widgetParams.collectionId !== undefined;

    if (!allowEdition && !hasCollectionId) {
      return invalidConfigurationContent();
    }
    if (allowEdition) {
      return editModeContent();
    }

    if (!allowEdition && isLoading) {
      return (
        <Box display="flex" direction="column" alignItems="center">
          <Box margin={4} padding={4}>
            {resources.loading}
          </Box>
          <Box></Box>
        </Box>
      );
    }
    return (
      <ReportTableDataView
        dataSet={state.widgetData.dataSet}
        filter={state.widgetData.filter}
        onFilterChange={updateFilterValue}
        sortBy={state.widgetData.sortBy}
        sortedCol={state.widgetData.sortedCol}
        changeSort={changeSort}
      />
    );
  }, [
    state.widgetParams.editionMode,
    isLoading,
    state.widgetData,
    changeSort,
    updateFilterValue,
    editModeContent,
    resources.loading,
    invalidConfigurationContent,
    state.widgetParams.collectionId,
  ]);
  return renderContentDependEditionMode;
};
