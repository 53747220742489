import { ReactNode, useEffect, useRef, useState } from "react";

import { calculateColor } from "../../utils";
import { Box } from "../Box";
import { useColors } from "../ColorSchemeProvider";
import { Icon } from "../Icon";

type Props = {
  TitleComponent: ReactNode;
  children?: ReactNode;
  initialValue?: boolean;
  childIsActive?: boolean;
};
export function Accordion({ TitleComponent, initialValue, children, childIsActive }: Props) {
  const { colors } = useColors();
  const [open, setOpen] = useState(initialValue || false);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const { current: accordion } = btnRef;

    if (!accordion) {
      return;
    }

    const panel: HTMLElement | null = accordion.nextElementSibling as HTMLElement | null;

    if (!panel) {
      return;
    }
    if (!open) {
      panel.style.maxHeight = "";
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }, [open]);

  return (
    <Box>
      <Box hoverColor={colors.primaryHover0}>
        <button
          style={{
            backgroundColor: childIsActive ? calculateColor(colors.primaryHover0) : "transparent",
            padding: "8px 0",
            minHeight: "50px",
          }}
          ref={btnRef}
          className={"prodoctivity-accordion"}
          onClick={() => {
            setOpen((curr) => !curr);
          }}
        >
          {TitleComponent}
          {open ? (
            <Box marginEnd={5} alignSelf="center">
              <Icon size="xs" color={colors.black900} accessibilityLabel="show" icon="chevron-up" />
            </Box>
          ) : (
            <Box marginEnd={5} alignSelf="center">
              <Icon
                size="xs"
                color={colors.black900}
                accessibilityLabel="hide"
                icon="chevron-down"
              />
            </Box>
          )}
        </button>
      </Box>
      {open && children}
    </Box>
  );
}
