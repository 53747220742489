import { Box, useColors } from "@prodoctivity/design-system";
import { BreadCrumb } from "../../components/BreadCrumb";
import { organizationLinkTemplates } from "../../link-templates";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useRecentSearchLogic } from "./hooks";
import { LeftSidebar } from "./components/LeftSidebar";
import { MainContent } from "./components/MainContent";

const RecentSearchesPage = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const { showMobileLeftPanel, leftSidebarToggle, breakPointDuo } = useRecentSearchLogic();

  const leftBarIconOption = {
    options: [
      {
        name: resources.recentSearches,
        icon: undefined,
        svgIcon: undefined,
        color: undefined,
        onTap: () => undefined,
      },
    ],
    openState: {
      isOpen: false,
      setIsOpen: () => undefined,
    },
    customElement: undefined,
    title: undefined,
    setSelectedMenuItemCallback: () => undefined,
    resources: {
      open: "",
      close: "",
    },
  };

  return (
    <>
      <Box color={colors.white}>
        <BreadCrumb
          entries={[
            { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
            {
              type: "url",
              name: resources.recentSearches,
              url: organizationLinkTemplates.recentSearches(),
            },
          ]}
        />
      </Box>
      <Box display="flex" flex="grow">
        {!breakPointDuo && (
          <Box display="flex">
            <LeftSidebar
              options={leftBarIconOption.options}
              openState={{ isOpen: showMobileLeftPanel, setIsOpen: leftSidebarToggle }}
              resources={{ open: "Open", close: "Close" }}
              customElement={leftBarIconOption.customElement}
              title={leftBarIconOption.title}
              setSelectedMenuItemCallback={leftBarIconOption.setSelectedMenuItemCallback}
            />
          </Box>
        )}
        <MainContent />
      </Box>
    </>
  );
};

export default RecentSearchesPage;
