import {
  Box,
  SelectList,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import React, { useCallback } from "react";

import { formatNumber } from "@prodoctivity/shared";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { PaginationLeftIconSvg } from "../../../svg/Pagination/PaginationLeftIconSvg";
import { PaginationRightIconSvg } from "../../../svg/Pagination/PaginationRightIconSvg";

export type Props<T = number> = {
  id: string;
  /**
   * Zero based
   *
   * @type {number}
   */
  currentPage: number;
  pageLength: number;
  rowsPerPage?: T;
  isNextButtonDisabled?: boolean;
  isPreviousButtonDisabled?: boolean;
  pageLengthOptions?: T[];
  rowsLabel: string;
  totalRowCount?: number;
  setRowsPerPage?(l: T): void;
  firstPage?(): void;
  nextPage(): void;
  previousPage(): void;
  lastPage?(): void;
  extraComponent?: JSX.Element;
};

type PaginationType = <T>(props: Props<T>) => React.ReactElement;

export const Pagination: PaginationType = ({
  id,
  currentPage,
  nextPage,
  previousPage,
  firstPage,
  pageLength,
  rowsPerPage,
  isNextButtonDisabled,
  isPreviousButtonDisabled,
  rowsLabel: _rowsLabel,
  pageLengthOptions,
  totalRowCount,
  setRowsPerPage: setPageLength,
  extraComponent: children,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const isSmallDisplay = breakpoint === "small" || breakpoint === "medium";
  const rpp = parseInt(`${rowsPerPage}`, 10);
  const currentRow = currentPage * rpp + 1;
  const onRowsPerPageChange = useCallback(
    (args: { value: string }) => {
      if (rowsPerPage && setPageLength) {
        setPageLength(args.value as typeof rowsPerPage);
        if (firstPage) {
          firstPage();
        }
      }
    },
    [firstPage, setPageLength, rowsPerPage]
  );

  return (
    <Box
      display="flex"
      direction={isSmallDisplay ? "column" : "row"}
      flex="grow"
      alignItems={isSmallDisplay ? undefined : "center"}
      gap={2}
    >
      {children ? (
        <Box display="flex" flex="grow">
          {children}
        </Box>
      ) : null}

      <Box
        display="flex"
        direction="row"
        flex={isSmallDisplay ? "grow" : undefined}
        justifyContent="end"
        alignItems="center"
      >
        {rowsPerPage && pageLengthOptions && pageLengthOptions.length > 1 && (
          <Box display="flex" marginEnd={2} flex="shrink" direction="column" justifyContent="start">
            <SelectList
              id={`${id}_rowsPerPage`}
              options={pageLengthOptions.map((pl) => ({ label: `${pl}`, value: `${pl}` }))}
              value={`${rowsPerPage}`}
              onChange={onRowsPerPageChange}
            />
          </Box>
        )}
        {isSmallDisplay ? <Box display="flex" flex="grow" /> : undefined}
        <Box
          display="flex"
          flex="shrink"
          direction="column"
          justifyContent="center"
          maxWidth={isSmallDisplay ? 150 : undefined}
          paddingX={3}
        >
          <Text align="center">
            {/* {!children && `${rowsLabel} `}  */}
            {currentPage * rpp + pageLength === 0 ? 0 : currentRow} -{" "}
            {currentPage * rpp + pageLength}
            {totalRowCount !== undefined ? ` ${resources.of} ${formatNumber(totalRowCount)}` : null}
          </Text>
        </Box>
        <Box
          display="flex"
          flex="shrink"
          direction="column"
          justifyContent="center"
          marginTop={1}
          minWidth={"40px"}
          opacity={!isPreviousButtonDisabled ? 1 : 0.4}
        >
          <TapArea onTap={previousPage} disabled={isPreviousButtonDisabled}>
            <PaginationLeftIconSvg color={colors.black600} />
          </TapArea>
        </Box>
        <Box
          display="flex"
          flex="shrink"
          direction="column"
          justifyContent="center"
          marginTop={1}
          minWidth={"40px"}
          opacity={!isNextButtonDisabled ? 1 : 0.4}
        >
          <TapArea onTap={nextPage} disabled={isNextButtonDisabled}>
            <PaginationRightIconSvg color={colors.black600} />
          </TapArea>
        </Box>
      </Box>
    </Box>
  );
};
