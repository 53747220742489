import {
  Box,
  BoxWithRef,
  Button,
  Checkbox,
  ComboBox,
  Divider,
  Grid,
  Layer,
  NumberField,
  Popover,
  RadioGroup,
  SearchField,
  TapArea,
  Text,
  TextField,
  WhatsThis,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import type {
  ContextRecord,
  DataElement,
  TemplateContextDefinition,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  contextToRightItems,
  deepItemFinder,
  extractFieldsName,
  isDataElement,
  rightItemsToContext,
  sortByName,
} from "../utils";

import { ChevronSvgIcon } from "@prodoctivity/design-system";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import { sanitizeFieldName } from "@prodoctivity/shared";
import type { ExpirationBehavior } from "@prodoctivity/shared/src/index-types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { CalderndarTimerSvg } from "../../../../svg/CalderndarTimerSvg";
import { EditSvgIcon } from "../../../../svg/EditSvgIcon";
import { HorizontalEllipsisSvgIcon } from "../../../../svg/HorizontalEllipsisSvgIcon";
import { IdCardSVG } from "../../../../svg/IdCardSvg";
import { XIconSvg } from "../../../../svg/XIconSvg";
import { DataElementConfiguration } from "../../DataDictionary/DataElements/DataElementConfiguration";
import { DocumentTypeState } from "../hooks";

const startsWithNumber = (str: string): boolean => {
  const startsWithNumberRegex = /^\d/;
  return startsWithNumberRegex.test(str);
};

type Props = {
  dataElements: Array<DataElement>;
  contextDefinition: TemplateContextDefinition;
  onContextDefinitionChange: (contextDefinition: TemplateContextDefinition) => void;
  wizardDefinition?: TemplateWizardDefinition;
  error?: string;
  formState: DocumentTypeState;
  setFormState: Dispatch<SetStateAction<DocumentTypeState>>;
  disableContinueButton: undefined | ((v: boolean) => void);
};

export interface Item {
  id: string;
  label: string;
  dataElementInfo?: DataElement | ContextRecord;
  selected: boolean;
  items?: Array<Item>;
  fullPath: string;
}

export type State = {
  leftFilter: string;
  rightFilter: string;
  leftItems: Array<Item>;
  rightItems: Array<Item>;
  createRecord: boolean;
  newRecordName?: string;
  recordError?: string;
};

export const DocumentKeyStep: FunctionComponent<Props> = ({
  dataElements,
  contextDefinition,
  onContextDefinitionChange,
  wizardDefinition,
  formState,
  setFormState,
  error,
  disableContinueButton,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  // const { breakpoint } = useDesignBreakpoint();
  const [showAddDataElementModule, rawSetShowAddDataElementModule] = useState<boolean>(false);

  const setShowAddDataElementModule = useCallback(
    (v: boolean) => {
      if (disableContinueButton) {
        disableContinueButton(v);
      }
      rawSetShowAddDataElementModule(v);
    },
    [disableContinueButton]
  );

  const [editContextField, setEditContextField] = useState<boolean>(false);
  const [contextFieldAsProp, setContextFieldAsProp] = useState<
    { dataElement: DataElement; itemToReplace: Item } | undefined
  >();
  const [ellipsisState, setEllipsisState] = useState<{
    hoverItem: undefined;
    openKeys: [];
    ellipsisSelected: string | undefined;
  }>({
    hoverItem: undefined,
    openKeys: [],
    ellipsisSelected: undefined,
  });

  const [state, setState] = useState<State>({
    leftFilter: "",
    rightFilter: "",
    leftItems: [],
    rightItems: [],
    createRecord: false,
    newRecordName: undefined,
  });

  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<ComboBoxItemType[]>([]);
  const [dataElementComboBoxInputValue, setDataElementComboBoxInputValue] = useState("");
  const [expandedRecord, setExpandedRecord] = useState<string | undefined>(undefined);
  const anchorRefItems = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleToggleSelect = useCallback(
    (item: Item, isLeft: boolean) => {
      const updatedItems = isLeft
        ? state.leftItems.map((i) => (i.label === item.label ? { ...i, selected: !i.selected } : i))
        : state.rightItems.map((i) => {
            if (i.items) {
              let itemWithItems: Item = i;
              const arrayOfUpdatedItems: Item[] = i.items.map((subItem) =>
                subItem.label === item.label ? { ...subItem, selected: !subItem.selected } : subItem
              );
              return (itemWithItems = { ...itemWithItems, items: [...arrayOfUpdatedItems] });
            } else {
              return i.label === item.label ? { ...i, selected: !i.selected } : i;
            }
          });

      if (isLeft) {
        setState((prev) => ({ ...prev, leftItems: updatedItems }));
      } else {
        setState((prev) => ({ ...prev, rightItems: updatedItems }));
      }
    },
    [state.leftItems, state.rightItems]
  );

  const filteredRightItems = state.rightItems
    .reduce((acc: Item[], current: Item) => {
      const isDuplicate = acc.some((obj) => obj.fullPath === current.fullPath);
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    }, [])
    .filter((item) => item.label.toLowerCase().includes(state.rightFilter.toLowerCase()));

  const deleteItem = useCallback(
    (item: Item) => {
      const updatedSelectedDocumentTypes = selectedDocumentTypes.filter(
        (comboBoxItems) => comboBoxItems.value !== item.id
      );
      setSelectedDocumentTypes(updatedSelectedDocumentTypes);
      setDataElementComboBoxInputValue("");

      const updatedFromList = deepItemFinder(state.rightItems, item);
      const updatedToList = [...state.leftItems, item].map((i) => {
        i.selected = false;
        return i;
      });

      setState({
        ...state,
        leftItems: updatedToList,
        rightItems: [...updatedFromList.updatedList],
      });
      const context = rightItemsToContext(updatedFromList.updatedList, dataElements);

      onContextDefinitionChange(context);
      return updatedFromList;
    },
    [dataElements, onContextDefinitionChange, selectedDocumentTypes, state]
  );

  const handleEditContextField = useCallback(
    (item: Item) => {
      if (isDataElement(item.dataElementInfo)) {
        const dataElementInfo = item.dataElementInfo;
        setEditContextField(!editContextField);
        setContextFieldAsProp({ dataElement: dataElementInfo, itemToReplace: item });
        setShowAddDataElementModule(!showAddDataElementModule);
      } else {
        return;
      }
    },
    [editContextField, showAddDataElementModule, setShowAddDataElementModule]
  );

  const moveItemBetweenRecords = useCallback(
    (itemToMove: Item, destination: string): void => {
      if (destination === "root") {
        const listWithoutItem = deleteItem(itemToMove);
        listWithoutItem.updatedList.unshift(itemToMove);

        setState({
          ...state,
          rightItems: [...listWithoutItem.updatedList],
        });
        const context = rightItemsToContext(listWithoutItem.updatedList, dataElements);
        onContextDefinitionChange(context);
      } else {
        const destinationRecord = state.rightItems.filter((i) => i.id === destination);

        const listWithoutItem = deleteItem(itemToMove);
        const deepItemMover = (
          sourceItemList: Item[],
          itemToFind: Item,
          itemToMove: Item,
          isSubItem?: boolean
        ): { updatedList: Item[]; parentItem?: Item; isSubItem?: boolean } => {
          for (const i of sourceItemList) {
            if (i.label === itemToFind.label && !isSubItem) {
              const listCloned = sourceItemList;
              const positionForInsert = sourceItemList.findIndex(
                (item) => item.label === itemToFind.label
              );
              listCloned[positionForInsert].items?.push(itemToMove);
              return {
                updatedList: listCloned,
              };
            }
            if (i.items && i.items.length > 0) {
              const foundInSubItems = deepItemMover(i.items, itemToFind, itemToMove, true);
              if (foundInSubItems.isSubItem) {
                // Remove the item from i.items
                i.items = i.items.filter((subItem) => subItem.label !== itemToFind.label);
                return {
                  updatedList: sourceItemList.filter((itemInListWithNesting) => {
                    if (itemInListWithNesting.label === i.label) {
                      return i;
                    } else {
                      return itemInListWithNesting;
                    }
                  }),
                  parentItem: i,
                };
              }
            } else {
              if (i.label === itemToFind.label && isSubItem) {
                // Remove the item from sourceItemList and return its parent
                return {
                  updatedList: sourceItemList.filter((item) => item.label !== itemToFind.label),
                  isSubItem,
                };
              } else if (i.label === itemToFind.label && !isSubItem) {
                const tempList = sourceItemList.findIndex(
                  (item) => item.label === itemToFind.label
                );
                sourceItemList[tempList].items?.push(itemToMove);
                // Remove the item from sourceItemList
                return {
                  updatedList: sourceItemList,
                };
              }
            }
          }

          return { updatedList: sourceItemList };
        };

        const listWithItemMoved = deepItemMover(
          listWithoutItem.updatedList,
          destinationRecord[0],
          itemToMove
        );

        setState({
          ...state,
          rightItems: [...listWithItemMoved.updatedList],
        });
        const context = rightItemsToContext(listWithItemMoved.updatedList, dataElements);
        onContextDefinitionChange(context);
      }
    },
    [deleteItem, state, onContextDefinitionChange, dataElements]
  );

  const onEllipsisClick = useCallback(
    (key?: string) => {
      setEllipsisState({
        ...ellipsisState,
        ellipsisSelected: key === ellipsisState.ellipsisSelected ? undefined : key,
      });
    },
    [ellipsisState]
  );

  const itemContextList = useMemo(() => {
    const contextList: Item[] = [
      {
        id: "root",
        label: "root",
        dataElementInfo: undefined,
        selected: false,
        items: [],
        fullPath: "root",
      },
    ];

    state.rightItems.forEach((item) => {
      if (item.items) {
        contextList.push(item);
      } else {
        const rootIndex = contextList.findIndex((i) => i.id === "root");
        contextList[rootIndex].items?.push(item);
      }
    });
    return contextList;
  }, [state.rightItems]);

  const handleFormStateChange = useCallback(
    (element: ExpirationBehavior | undefined) => {
      setFormState((prev) => ({
        ...prev,
        expirationBehavior: element,
        expirationWarningDays: element ? prev.expirationWarningDays : undefined,
      }));
    },
    [setFormState]
  );
  const handleExpirationWarningChange = useCallback(
    (days: number | undefined) => {
      setFormState((prev) => ({ ...prev, expirationWarningDays: days }));
    },
    [setFormState]
  );

  const drawEllipsis = useCallback(
    (item: Item, parentItem?: Item) => {
      return (
        itemContextList.length > 1 && (
          <BoxWithRef
            ref={(ref) => (anchorRefItems.current[item.fullPath] = ref)}
            alignItems="center"
            width={40}
            height={40}
            marginStart={4}
            marginEnd={4}
          >
            <Box onClickCapture={() => onEllipsisClick(item.fullPath)} opacity={0.5}>
              <HorizontalEllipsisSvgIcon color={colors.neutral200} />
            </Box>
            {ellipsisState.ellipsisSelected === item.fullPath && (
              <Layer>
                <Popover
                  anchor={anchorRefItems.current[item.fullPath]}
                  onDismiss={() =>
                    setEllipsisState({
                      ...ellipsisState,
                      ellipsisSelected: undefined,
                    })
                  }
                  idealDirection="down"
                  positionRelativeToAnchor={false}
                  size={"flexible"}
                  color="white"
                  role="menu"
                >
                  <Box width={300} padding={2} color={colors.white}>
                    <ComboBox
                      id="moveTo"
                      placeholder={resources.moveTo}
                      label=""
                      accessibilityClearButtonLabel={resources.clear}
                      noResultText={resources.noResultsFound}
                      onSelect={(e) => {
                        moveItemBetweenRecords(item, e.item.value);
                        onEllipsisClick(undefined);
                      }}
                      size="lg"
                      options={(parentItem
                        ? itemContextList
                        : itemContextList.filter((i) => i.label !== "root")
                      )
                        .filter((i) => i.label !== parentItem?.label && i.items)
                        .map((item) => {
                          return { value: item.id, label: item.label };
                        })}
                    />
                  </Box>
                </Popover>
              </Layer>
            )}
          </BoxWithRef>
        )
      );
    },
    [
      colors.neutral200,
      colors.white,
      ellipsisState,
      itemContextList,
      moveItemBetweenRecords,
      onEllipsisClick,
      resources.clear,
      resources.moveTo,
      resources.noResultsFound,
    ]
  );

  const renderItem = useCallback(
    (item: Item, isLeft: boolean, inGroup?: boolean, parentItem?: Item) => {
      if (item.items === undefined) {
        return (
          <Box
            paddingX={inGroup ? 0 : 4}
            marginBottom={4}
            marginStart={inGroup ? 6 : 0}
            display="flex"
            alignItems="center"
            justifyContent="between"
            key={item.id}
          >
            <Checkbox
              id={`leftCheckbox${item.id}`}
              checked={item.selected}
              onChange={() => handleToggleSelect(item, isLeft)}
              label={item.label}
            />
            <Box display="flex" width={"fit-content"} alignItems="center">
              {!isLeft && (
                <>
                  {drawEllipsis(item, parentItem ? parentItem : undefined)}

                  <Box display="flex">
                    <TapArea
                      onTap={() => {
                        handleEditContextField(item);
                      }}
                      role="button"
                    >
                      <EditSvgIcon color={colors.neutral900} />
                    </TapArea>
                  </Box>

                  <Box
                    onClickCapture={() => deleteItem(item)}
                    width={40}
                    height={40}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    dangerouslySetInlineStyle={{
                      __style: {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <XIconSvg />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        );
      }

      return (
        <Box display="flex" direction="column" key={item.id}>
          <Divider color={colors.neutral700} />
          <Box
            height={40}
            color={colors.neutral200}
            paddingX={4}
            paddingY={2}
            display="flex"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClickCapture={() => {
                return expandedRecord === item.id
                  ? setExpandedRecord(undefined)
                  : setExpandedRecord(item.id);
              }}
            >
              <ChevronSvgIcon direction={expandedRecord === item.id ? "up" : "down"} />
            </Box>
            <Box marginStart={4}>
              <Text color={colors.neutral900} weight="bold">
                {item.label}
              </Text>
            </Box>
          </Box>
          {(state.rightItems.findIndex((i) => i.id === item.id) === state.rightItems.length - 1 ||
            expandedRecord === item.id) && <Divider color={colors.neutral700} />}
          {expandedRecord === item.id && (
            <Box marginBottom={item.items.length > 1 ? 4 : 0} marginTop={4} marginEnd={4}>
              {item.items.map((i) => renderItem(i, false, true, item))}
            </Box>
          )}
        </Box>
      );
    },
    [
      colors.neutral200,
      colors.neutral700,
      colors.neutral900,
      deleteItem,
      drawEllipsis,
      expandedRecord,
      handleEditContextField,
      handleToggleSelect,
      state.rightItems,
    ]
  );

  useEffect(() => {
    let dataElementsAlreadyUsed: Array<string> = contextDefinition.fields.map((f) => f.name);

    const recordsNames = extractFieldsName(
      dataElements,
      dataElementsAlreadyUsed,
      contextDefinition.records
    );
    dataElementsAlreadyUsed = [...recordsNames];

    const items: Array<Item> | [] = dataElements
      .filter((d) => !dataElementsAlreadyUsed.includes(d.name))
      .map((d) => {
        return {
          id: d.name,
          label: d.label,
          dataElementInfo: d,
          selected: false,
          fullPath: sanitizeFieldName(d.name, false),
        };
      });

    const rightItems = contextToRightItems(contextDefinition, wizardDefinition);
    setState((prevState) => ({ ...prevState, leftItems: items, rightItems: rightItems }));
  }, [
    contextDefinition,
    dataElements,
    wizardDefinition,
    resources.formDesigner.generalPage,
    resources.formDesigner.defaultSection,
  ]);

  const containsSpecialChars = (str: string): boolean => {
    const specialCharsRegex = /[^\w\s]/gi;
    return specialCharsRegex.test(str);
  };

  const onNewRecord = useCallback(() => {
    const items = state.rightItems.filter((i) => i.selected);
    if (!state.newRecordName) {
      setState({ ...state, recordError: resources.required });
      return;
    }
    if (containsSpecialChars(state.newRecordName)) {
      setState({ ...state, recordError: resources.specialCharsNotAllowed });
      return;
    }
    if (startsWithNumber(state.newRecordName)) {
      setState({ ...state, recordError: resources.nameCannotStartWithNumber });
      return;
    }
    if (items.length < 2) {
      setState({ ...state, recordError: resources.chooseTwoItems });
      return;
    }

    if (state.rightItems.find((i) => (i.items || []).length > 0 && i.id === state.newRecordName)) {
      setState({ ...state, recordError: resources.duplicated });
      return;
    }

    const newItem: Item = {
      id: state.newRecordName,
      label: state.newRecordName,
      selected: false,
      items: items.map((i) => {
        i.selected = false;
        return i;
      }),
      fullPath: sanitizeFieldName(state.newRecordName, true),
    };

    const rightItems = state.rightItems.filter((i) => !items.includes(i));
    rightItems.push(newItem);

    setState({
      ...state,
      createRecord: false,
      newRecordName: undefined,
      recordError: undefined,
      rightItems: rightItems,
    });

    const context = rightItemsToContext(rightItems, dataElements);
    onContextDefinitionChange(context);
  }, [
    dataElements,
    onContextDefinitionChange,
    resources.chooseTwoItems,
    resources.duplicated,
    resources.nameCannotStartWithNumber,
    resources.required,
    resources.specialCharsNotAllowed,
    state,
  ]);

  const onSave = useCallback(
    (dataElement: DataElement, oldItem: Item | undefined) => {
      const itemConverter = (d: DataElement): Item[] => {
        return [
          {
            id: d.name,
            label: d.label,
            dataElementInfo: d,
            selected: false,
            fullPath: sanitizeFieldName(d.name, false),
          },
        ];
      };

      const convertedDataElement = itemConverter(dataElement);
      const dataElementInArray = [dataElement];

      if (oldItem) {
        const wasFound = deepItemFinder(state.rightItems, oldItem);
        const individualTransfer = (
          fromList: Item[],
          toList: Item[],
          recordItem: Item | undefined
        ) => {
          let editedItem: Item[], cleanedList: Item[], updatedToList: Item[];
          if (recordItem) {
            editedItem = [fromList[0]];
            cleanedList = toList.filter((itemInListWithNesting) => {
              if (itemInListWithNesting.label === recordItem.label && recordItem.items) {
                recordItem.items.push(editedItem[0]);
                return recordItem;
              } else {
                return itemInListWithNesting;
              }
            });
            updatedToList = [...cleanedList].map((i) => {
              i.selected = false;
              return i;
            });
          } else {
            editedItem = [fromList[0]];
            updatedToList = [...toList, ...editedItem].map((i) => {
              i.selected = false;
              return i;
            });
          }

          setState((prevState) => ({
            ...prevState,
            rightItems: [...updatedToList],
          }));
          const context = rightItemsToContext(updatedToList, dataElementInArray);
          onContextDefinitionChange(context);
          return updatedToList;
        };

        individualTransfer(convertedDataElement, wasFound.updatedList, wasFound.parentItem);
      } else {
        const individualTransfer = (fromList: Item[], toList: Item[]) => {
          const createdItem = [fromList[0]];
          const updatedToList = [...toList, ...createdItem].map((i) => {
            i.selected = false;
            return i;
          });

          setState((prevState) => ({
            ...prevState,
            rightItems: [...prevState.rightItems, ...updatedToList],
          }));

          const context = rightItemsToContext(updatedToList, dataElementInArray);
          onContextDefinitionChange(context);
          return updatedToList;
        };

        individualTransfer(convertedDataElement, state.rightItems);
      }
      if (editContextField) {
        setEditContextField(!editContextField);
      }
      setShowAddDataElementModule(!showAddDataElementModule);
    },
    [
      onContextDefinitionChange,
      setShowAddDataElementModule,
      setEditContextField,
      editContextField,
      showAddDataElementModule,
      state,
    ]
  );

  const documentTypeItemList = useMemo(() => {
    const filteredItemList = state.leftItems
      .filter(
        (item) =>
          item.label.toLowerCase().includes(state.leftFilter.toLowerCase()) ||
          item.dataElementInfo?.humanName.toLowerCase().includes(state.leftFilter.toLowerCase())
      )
      .sort(sortByName);
    return filteredItemList.map((item) => ({
      label: item.label,
      value: item.id,
    }));
  }, [state.leftItems, state.leftFilter]);

  const onSelectDataElement = useCallback(
    ({
      item,
    }: {
      event: React.SyntheticEvent<HTMLInputElement, Event>;
      item: ComboBoxItemType;
    }) => {
      const isAlreadySelected = selectedDocumentTypes.some(
        (selectedItem) => selectedItem.value === item.value
      );

      if (!isAlreadySelected) {
        const newSelectedDocumentTypes = [...selectedDocumentTypes, item];
        setSelectedDocumentTypes(newSelectedDocumentTypes);
        setDataElementComboBoxInputValue("");

        const updatedFromList = state.leftItems.filter((i) => i.id !== item.value);
        const selectedItems = state.leftItems.filter((i) => i.id === item.value);
        const updatedToList = [...state.rightItems, ...selectedItems].map((i) => {
          i.selected = false;
          return i;
        });

        setState({
          ...state,
          leftItems: updatedFromList,
          rightItems: updatedToList,
          leftFilter: "",
        });
        const context = rightItemsToContext(updatedToList, dataElements);
        onContextDefinitionChange(context);
      }
    },
    [selectedDocumentTypes, dataElements, onContextDefinitionChange, state]
  );

  const handleDataElementComboBoxClear = useCallback(() => {
    setDataElementComboBoxInputValue("");
  }, []);

  const [expirationTypeInfo, setExpirationTypeInfo] = useState<
    ExpirationBehavior["type"] | undefined
  >("field");

  return showAddDataElementModule ? (
    <DataElementConfiguration
      isUpdating={editContextField}
      contextFieldItem={editContextField ? contextFieldAsProp : undefined}
      showAddDataElementModule={showAddDataElementModule}
      editContextField={editContextField}
      onSave={onSave}
      onCancel={() => {
        setShowAddDataElementModule(false);
        setEditContextField(false);
      }}
      rightItems={state.rightItems}
      isPanelHidden={false}
      contextFieldState={undefined}
    />
  ) : (
    <Box
      // width={"100%"}
      display="flex"
      padding={4}
      alignItems="center"
      flex="grow"
      direction="column"
    >
      <Box width={"100%"} display="flex" direction={"column"} flex="grow">
        <Box width={"100%"} justifyContent="center" flex="shrink">
          <Box width={"100%"} display="flex" direction="column">
            <Box width={"100%"} color={colors.white} borderRadius={6}>
              <Box
                width={"100%"}
                padding={3}
                borderRadius={6}
                borderColor={colors.neutral50}
                color={colors.neutral200}
              >
                <Text weight="bold" color={colors.neutral900} size="300">
                  {resources.dataDictionary.availableDataElements}
                </Text>
              </Box>
              <Divider color={colors.neutral900} />
              <Box padding={4} display="flex" flex="grow" gap={2}>
                <Box display="flex" flex="grow">
                  <Box width={"100%"}>
                    <ComboBox
                      id="data_element_id"
                      onClear={handleDataElementComboBoxClear}
                      label=""
                      inputValue={dataElementComboBoxInputValue}
                      onChange={(event) =>
                        setDataElementComboBoxInputValue(() => {
                          setState((prev) => ({ ...prev, leftFilter: event.value }));
                          return event.value;
                        })
                      }
                      accessibilityClearButtonLabel={resources.clear}
                      noResultText={resources.dataDictionary.noItems}
                      onSelect={onSelectDataElement}
                      options={documentTypeItemList}
                      placeholder={resources.dataDictionary.dataElementName}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" flex="shrink">
                  <Button
                    color={"transparent"}
                    onClick={() => {
                      setShowAddDataElementModule(!showAddDataElementModule);
                    }}
                    text={resources.add}
                    iconEnd="add-circle"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" direction="column" borderStyle="lg" borderRadius={6} flex="grow">
          <Box
            color={colors.neutral200}
            display="flex"
            paddingX={3}
            paddingY={2}
            alignItems="center"
            direction="row"
            gap={2}
          >
            <Box display="flex" flex="grow">
              <Text weight="bold" color={colors.neutral900} size="300">
                {resources.dataDictionary.assignedDataElements}
              </Text>
            </Box>
            <Box display="flex" alignItems="center" flex="shrink">
              <SearchField
                borderColor={colors.neutral700}
                accessibilityLabel={resources.dataDictionary.searchElementsPlaceholder}
                id="document_type_config_right_filter"
                onChange={(e) => {
                  setState({ ...state, rightFilter: e.value });
                }}
                value={state.rightFilter}
              />
            </Box>

            {state.createRecord ? null : (
              <Box display="flex" alignItems="center" flex="shrink">
                <Button
                  color={"transparent"}
                  onClick={() => setState({ ...state, createRecord: true })}
                  text={resources.documentTypes.createRecord}
                />
                <WhatsThis
                  color={colors.neutral900}
                  colorBundle={colors}
                  title={resources.documentTypes.createRecordActionMeaning}
                  size="xs"
                />
              </Box>
            )}
          </Box>
          {state.createRecord && (
            <>
              <Divider direction="horizontal" />
              <Box color={colors.neutral200} padding={3}>
                <Text weight="bold" color={colors.subtle}>
                  {resources.documentTypes.recordCreation}
                </Text>
                <Grid gridTemplateColumns={[7, 3, 2]}>
                  <Box display="flex" alignItems="center">
                    <Text color={colors.subtle}>
                      {resources.documentTypes.selectElementsRecord}
                    </Text>
                  </Box>
                  <Button
                    color={"transparent"}
                    onClick={() =>
                      setState({
                        ...state,
                        createRecord: false,
                        recordError: undefined,
                        newRecordName: undefined,
                      })
                    }
                    text={resources.cancel}
                  />

                  <Button color={"blue"} onClick={onNewRecord} text={resources.create} />
                </Grid>
                <Box marginTop={3} minHeight={65}>
                  <Box marginBottom={3} width="100%">
                    <Text weight="bold" color={colors.subtle}>
                      {resources.documentTypes.recordName}
                    </Text>
                  </Box>
                  <Box width="100%">
                    <TextField
                      id="recordName"
                      value={state.newRecordName}
                      onChange={(e) => {
                        setState({ ...state, newRecordName: e.value });
                      }}
                    />
                  </Box>
                  <Box>
                    {state.recordError && <Text color={colors.error}>{state.recordError}</Text>}
                  </Box>
                </Box>
              </Box>
            </>
          )}

          <Divider direction="horizontal" />
          <Box
            display="flex"
            direction="column"
            gap={2}
            flex="grow"
            color={colors.white}
            overflow="auto"
          >
            {filteredRightItems.map((item) => {
              return renderItem(item, false);
            })}
            {filteredRightItems.length === 0 && (
              <Text align="center" color={colors.subtle}>
                {resources.documentTypes.noDataElements}
              </Text>
            )}
          </Box>

          {error && (
            <Box>
              <Text color={colors.error}>{error}</Text>
            </Box>
          )}
        </Box>

        <Box
          padding={3}
          display="flex"
          color={colors.white}
          borderRadius={4}
          borderStyle="sm"
          direction="column"
          flex="shrink"
        >
          <Box display="flex" gap={3} direction="column" flex="shrink">
            <Box display="flex" gap={3} alignItems="center" flex="grow">
              <Box display="flex" flex="shrink">
                <Text>{resources.generationStatus.expirationDate}:</Text>
                <Box zIndex={modalZIndex} display="flex">
                  <WhatsThis
                    size="xs"
                    color={colors.neutral900}
                    colorBundle={colors}
                    title={resources.expirationDateWhatsThis.expirationWhatsThis}
                    clickContent={
                      <Box display="flex" direction="column">
                        {expirationTypeInfo === "field" && (
                          <Box>
                            <Box marginBottom={4}>
                              <Text align="center" size="600" weight="bold" color={colors.primary}>
                                {resources.expirationDateConfig.attachmentToField}
                              </Text>
                            </Box>
                            <Box paddingY={6}>
                              <IdCardSVG width={460} height={300} />
                            </Box>
                            <Box marginBottom={5}>
                              <Text
                                size="400"
                                weight="bold"
                                align="justify"
                                color={colors.neutral900}
                              >
                                {resources.expirationDateWhatsThis.indexedDocumentDescription}
                              </Text>
                            </Box>
                          </Box>
                        )}
                        {expirationTypeInfo === "days-after-creation" && (
                          <Box>
                            <Box marginBottom={4}>
                              <Text align="center" size="600" weight="bold" color={colors.primary}>
                                {resources.expirationDateConfig.daysAfterCreation}
                              </Text>
                            </Box>
                            <Box paddingY={6}>
                              <CalderndarTimerSvg width={460} height={300} />
                            </Box>
                            <Box marginBottom={5}>
                              <Text
                                size="400"
                                weight="bold"
                                align="justify"
                                color={colors.neutral900}
                              >
                                {resources.expirationDateWhatsThis.daysAfterCreationDescription}
                              </Text>
                            </Box>
                          </Box>
                        )}
                        <Box display="flex" direction="row" alignSelf="center">
                          <TapArea
                            onTap={() => {
                              setExpirationTypeInfo("field");
                              console.log(expirationTypeInfo);
                            }}
                          >
                            <Box
                              display="flex"
                              alignSelf="center"
                              color={
                                expirationTypeInfo === "field" ? colors.primary : colors.neutral900
                              }
                              width={20}
                              height={20}
                              borderRadius={48}
                              marginEnd={2}
                            />
                          </TapArea>
                          <TapArea onTap={() => setExpirationTypeInfo("days-after-creation")}>
                            <Box
                              display="flex"
                              alignSelf="center"
                              color={
                                expirationTypeInfo === "days-after-creation"
                                  ? colors.primary
                                  : colors.neutral900
                              }
                              width={20}
                              height={20}
                              borderRadius={48}
                            />
                          </TapArea>
                        </Box>
                      </Box>
                    }
                  />
                </Box>
              </Box>
              <RadioGroup
                id={"expiration-date-group"}
                legend={resources.expirationDateConfig.expirationDateConfiguration}
                direction="row"
                legendDisplay="hidden"
              >
                <RadioGroup.RadioButton
                  checked={formState.expirationBehavior?.type === "field"}
                  id="attachment-to-field-radio"
                  label={resources.expirationDateConfig.attachmentToField}
                  name={resources.expirationDateConfig.attachmentToField}
                  onChange={() => {
                    handleFormStateChange({ type: "field", value: "" });
                  }}
                  value={resources.expirationDateConfig.attachmentToField}
                />
                <RadioGroup.RadioButton
                  checked={formState.expirationBehavior?.type === "days-after-creation"}
                  id="days-after-creation-radio"
                  label={resources.expirationDateConfig.daysAfterCreation}
                  name={resources.expirationDateConfig.daysAfterCreation}
                  onChange={() => {
                    handleFormStateChange({ type: "days-after-creation", value: 1 });
                  }}
                  value={resources.expirationDateConfig.daysAfterCreation}
                />
                <RadioGroup.RadioButton
                  checked={formState.expirationBehavior === undefined}
                  id="no-expiration-date-radio"
                  label={resources.expirationDateConfig.noExpirationDate}
                  name={resources.expirationDateConfig.noExpirationDate}
                  onChange={() => {
                    handleFormStateChange(undefined);
                  }}
                  value={resources.expirationDateConfig.noExpirationDate}
                />
              </RadioGroup>

              <ExpirationTimeConfig
                expirationBehavior={formState.expirationBehavior}
                contextDefinition={formState.contextDefinition}
                onChange={handleFormStateChange}
              />
            </Box>
          </Box>
          {formState.expirationBehavior !== undefined && (
            <Box display="flex" width={"100%"} alignItems="center" paddingY={2} direction="row">
              <Text color={colors.black600}>{resources.expirationDaysOfNotice}</Text>
              <Box zIndex={modalZIndex}>
                <WhatsThis
                  colorBundle={colors}
                  color={colors.neutral900}
                  title={resources.expirationDaysOfNoticeDesc}
                  size="xs"
                />
              </Box>
              <Box width={"50%"}>
                <NumberField
                  id="maxLength"
                  onChange={(e) => {
                    handleExpirationWarningChange(e.value);
                  }}
                  min={1}
                  max={999}
                  value={formState.expirationWarningDays}
                  placeholder={resources.default + " 30"}
                  errorMessage={formState.errors?.["expirationWarningDays"]}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const ExpirationTimeConfig: FunctionComponent<{
  expirationBehavior: ExpirationBehavior | undefined;
  contextDefinition: TemplateContextDefinition;
  onChange(v: ExpirationBehavior | undefined): void;
}> = ({ expirationBehavior, contextDefinition, onChange }) => {
  const expirationDateOptions = useMemo(() => {
    return contextDefinition.fields
      .filter(
        (f) =>
          f.properties.dataType === "Date" ||
          f.properties.dataType === "DateTime" ||
          f.properties.dataType === "Time"
      )
      .map((f) => ({ label: f.properties.label, value: f.name }));
  }, [contextDefinition]);

  return (
    <Box>
      {expirationBehavior?.type === "field" && (
        <ComboBox
          id="expiration-date-comboBox"
          label=""
          options={expirationDateOptions}
          onSelect={({ item }) => onChange({ type: "field", value: item.value })}
          onClear={() => onChange(undefined)}
          selectedOption={expirationDateOptions.find((f) => f.value === expirationBehavior?.value)}
          inputValue={
            expirationDateOptions.find((f) => f.value === expirationBehavior?.value)?.label
          }
          onBlur={(item) => {
            if (item.value === "") {
              onChange(undefined);
            }
          }}
        />
      )}
      {expirationBehavior?.type === "days-after-creation" && (
        <NumberField
          id={"expiration-date-number-field"}
          onChange={(item) => {
            onChange({
              type: "days-after-creation",
              value: item.value || 0,
            });
          }}
          onBlur={(item) => {
            if (item.value === 0) {
              onChange(undefined);
            }
          }}
          min={1}
          value={expirationBehavior?.value}
        />
      )}
    </Box>
  );
};
