import { shouldNever } from "@prodoctivity/shared";
import type i18n from "i18next";
import type momentType from "moment";
import { ProDoctivityColorBundle } from "./components";

export type ColorConfig =
  | {
      type: "primary";
      scale: 0 | 100 | 200 | 300 | 400 | 500 | 600 | 700;
    }
  | {
      type: "secondary";
      scale: 300 | 400 | 500;
    }
  | {
      type: "neutral";
      scale: 50 | 100 | 200 | 250 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
    }
  | {
      type: "black";
      scale: 600 | 700 | 800 | 900;
    }
  | {
      type: "other";
      scale: 0 | 100 | 200 | 300 | 400 | 500 | 600;
    }
  | {
      type: "white";
      scale: 0;
    }
  | {
      type: "transparent";
      scale: 0;
    };

function colorPrefix(colorType: ColorConfig["type"]) {
  switch (colorType) {
    case "primary":
      return "--color-blue-skycicle-";
    case "secondary":
      return "--color-orange-firetini-";
    case "neutral":
      return "--color-gray-roboflow-";
    case "black":
      return "--color-black-cosmicore-";
    case "white":
      return "--color-white-mochimalist-";
    case "other":
      return "--color-other-other-";
    case "transparent":
      return "--color-transparent";
    default:
      shouldNever(colorType);
  }
}

export function calculateColor(config: ColorConfig) {
  const { type: colorType, scale: colorScale } = config;

  if (colorType === "transparent") {
    return `var(${colorPrefix(colorType)})`;
  }

  return `var(${colorPrefix(colorType)}${colorScale})`;
}

/**
 * Converts a CSS variable representing a color to an RGBA string.
 *
 * This function takes a `ColorConfig` object, calculates the corresponding CSS
 * variable, and retrieves its RGBA value. It creates a dummy HTML element,
 * applies the CSS variable as its color, appends the element to the document
 * body, and uses `getComputedStyle` to extract the computed RGBA value.
 *
 * @param {ColorConfig} config - The configuration object containing the color information.
 * @returns {string} The RGBA value of the color as a string in the format `rgba(r, g, b, a)`.
 *                   If the computed color cannot be determined, it defaults to `rgba(0, 0, 0, 0.85)`.
 */
export const getRGBAFromCSSVariable = (config: ColorConfig): string => {
  const variableName = calculateColor(config).replace("var(", "").replace(")", "");
  const dummyElement = document.createElement("div");
  dummyElement.style.color = `var(${variableName})`;
  document.body.appendChild(dummyElement);
  const rgbaMatch = window
    .getComputedStyle(dummyElement)
    .color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/i);
  dummyElement.remove();
  if (rgbaMatch) {
    const [, r, g, b, a] = rgbaMatch;
    return `rgba(${r}, ${g}, ${b}, ${a || "1"})`;
  }
  return "rgba(0, 0, 0, 0.85)";
};

export function changeLanguage(instance: typeof i18n, moment: typeof momentType) {
  return async (lang: string) => {
    let change = false;
    if (moment.locale() !== lang) {
      change = true;

      moment.locale(lang);
    }
    if (instance.language !== lang) {
      change = true;
    }
    if (change) {
      return instance.changeLanguage(lang);
    }
  };
}

export const popupZIndex = { index: () => 2000 };

export const overlayPanelZIndex = { index: () => 2500 };

export const isIconSelected = (
  colors: ProDoctivityColorBundle,
  wasClicked: boolean,
  useForHover: boolean
) => {
  if (wasClicked && useForHover) {
    return colors.primaryHover0;
  } else if (wasClicked && !useForHover) {
    return colors.primary;
  } else {
    return undefined;
  }
};

export const blobToBase64 = (blob: Blob) =>
  new Promise<string>((resolve, reject) => {
    // 1. Create a FileReader instance
    const reader = new FileReader();
    // 2. Add a handler for the 'onload' event
    reader.onload = () => {
      // 5. Get the result when the 'onload' event is triggered.
      const base64data = reader.result;
      if (typeof base64data !== "string") {
        reject(new Error("Wrong data type"));
        return;
      }
      resolve(base64data);
    };
    // 3. Add a handler for the 'onerror' event
    reader.onerror = (err) => {
      reject(err);
    };
    // 4. Call 'readAsDataURL' method
    reader.readAsDataURL(blob);
  });

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result;
      if (typeof result === "string") {
        resolve(result);
      } else {
        reject(new Error());
      }
    };
    reader.onerror = reject;
  });
