import { Box } from "./Box";
import { FunctionComponent } from "react";
import { TapArea } from "./TapArea";

type Props = {
  children: React.ReactNode;
  handleTap?: () => void;
};

export const StandardIconContainer: FunctionComponent<Props> = ({ children, handleTap }) => {
  return (
    <Box>
      <TapArea onTap={handleTap}>
        <Box height={40} width={40} display="flex" alignItems="center" justifyContent="center">
          {children}
        </Box>
      </TapArea>
    </Box>
  );
};
