import { Box, Text, useColors } from "@prodoctivity/design-system";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useMemo } from "react";

const HelpSection: React.FC = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const entries = useMemo(() => {
    return [
      {
        step: `${resources.step} 1`,
        content: resources.distributionManagementPage.step1Description,
      },
      {
        step: `${resources.step} 2`,
        content: resources.distributionManagementPage.step2Description,
      },
      {
        step: `${resources.step} 3`,
        content: resources.distributionManagementPage.step3Description,
      },
    ];
  }, [resources]);
  return (
    <Box display="flex" padding={4}>
      <Box>
        {entries.map((entry) => {
          return (
            <Box display="flex" key={entry.step} direction="row">
              <Box display="flex" direction="column" width={10}>
                <Box
                  marginTop={1}
                  color={colors.neutral500}
                  rounding="circle"
                  height={10}
                  width={10}
                  flex="shrink"
                />

                <Box marginStart={1} width={1} color={colors.neutral300} flex="grow" />
              </Box>
              <Box display="flex" direction="column">
                <Box marginStart={2}>
                  <Text color={colors.subtle} size="200">
                    {`${entry.step}`}
                  </Text>
                </Box>
                <Box padding={6}>{entry.content}</Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export { HelpSection };
