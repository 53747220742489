import { FC } from "react";
import { EmptyReportIcon } from "../../../../svg/reports/EmptyIconSvg";
import { Box, Text } from "@prodoctivity/design-system";

export const EmptyView: FC<{ mesage: string }> = ({ mesage }) => {
  return (
    <Box display="flex" direction="column" gap={1} justifyContent="center" alignItems="center">
      <Box alignItems="center" width={"100%"} height={"100%"}>
        <EmptyReportIcon width={200} height={200} />
      </Box>
      <Box alignItems="center">
        <Text size="400">{mesage} </Text>
      </Box>
    </Box>
  );
};
