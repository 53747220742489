import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CreditCardIconSvg: FunctionComponent<Props> = ({
  color,
  width = 25,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5701_96530)">
        <path
          d="M23.2803 2C24.2144 2.035 24.9682 2.86325 25 3.88982V18.1102C24.9682 19.1367 24.2144 19.965 23.2803 20H1.71975C1.26327 20 0.828025 19.8017 0.52017 19.4284C0.180467 19.0901 0 18.6118 0 18.1102V3.88982C0 3.3882 0.180467 2.90992 0.52017 2.57161C0.828025 2.19831 1.26327 2 1.71975 2H23.2803ZM1.71975 3.4932C1.58174 3.4932 1.50743 3.53986 1.47558 3.62152C1.40127 3.69151 1.35881 3.78483 1.35881 3.88982V6.52625H23.6412V3.88982C23.6412 3.78483 23.5881 3.69151 23.5244 3.62152C23.4926 3.52819 23.4183 3.4932 23.2803 3.4932H1.71975ZM23.2803 18.5068C23.4183 18.5068 23.4926 18.4601 23.5244 18.3785C23.5987 18.3085 23.6412 18.2152 23.6412 18.1102V10.9942H1.35881V18.0985C1.35881 18.2035 1.41189 18.2968 1.47558 18.3668C1.49682 18.4601 1.58174 18.4951 1.71975 18.4951H23.2803V18.5068ZM2.71762 17.0136V15.4738H5.47771V17.0136H2.71762ZM6.83652 17.0136V15.4738H10.913V17.0136H6.83652Z"
          fill={color || "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_5701_96530">
          <rect width="25" height="18" fill="white" transform="translate(0 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
