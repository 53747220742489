import LanguageDetector from "i18next-browser-languagedetector";
import enFile from "./locales/en.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { isProduction } from "./config";
import ptFile from "./locales/pt.json";
import spFile from "./locales/sp.json";
import nlFile from "./locales/nl.json";

//import Backend from "i18next-http-backend";
//import { getLocaleName } from "@prodoctivity/shared";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // backend: {
    //   loadPath: (lngs: string[]) => {
    //     const [lang] = lngs;
    //     const locale = getLocaleName(lang);
    //     return `/locales/${locale}.json`;
    //   },
    //   allowMultiLoading: false,
    // },
    resources: {
      en: {
        translation: enFile,
      },
      es: {
        translation: spFile,
      },
      pt: {
        translation: ptFile,
      },
      nl: {
        translation: nlFile,
      },
    },
    fallbackLng: "en",
    debug: !isProduction,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

if (!isProduction) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win: any = window;
  win.i18n = i18n;
}

export { i18n };
