import { FormConnector, FormDefinition, FormValues } from "../_lib/types";

import { makeCancelable } from "../_lib/utils";

interface OnFireConnector {
  (formConnector: { name?: string }, formValues: FormValues): Promise<FormValues>;
}

const getConnectorsToFire = (
  _formConnectors: FormConnector[],
  _connectors: any[],
  _formDefinition: FormDefinition,
  _dataElementFired?: string
): any[] => {
  return [];
  // const dataElementsList = formDefinition.dataElements.filter(
  //   (data) =>
  //     data.dataType === "List" &&
  //     data.dictionaryList?.feedFromDataConnector &&
  //     data.dictionaryList?.dataConnectorName
  // );

  // const listdataConnectorNames = dataElementsList.map(
  //   (dataElement) => dataElement.dictionaryList?.dataConnectorName
  // );

  // // Find connectors that should be fired
  // const connectorsToFire = connectors.filter(
  //   (connector) =>
  //     formConnectors.some((formConnector) => connector.name === formConnector.name) &&
  //     listdataConnectorNames.includes(connector.name) &&
  //     connector.inputMapping &&
  //     connector.inputMapping.some((d: any) => d.dataElement.name === dataElementFired)
  // );

  // return connectorsToFire;
};

let fireConnectorsPromise: any;

export const fireConnectorsList: any = async (
  formConnectors: FormConnector[],
  connectors: any[],
  onFireConnector: OnFireConnector,
  formDefinition: FormDefinition,
  formData: FormValues,
  onFormDefinitionChange: { (formDefinition: FormDefinition): void },
  dataElementFired?: string
) => {
  if (!onFireConnector || !connectors) {
    return;
  }

  const firedConnectors = getConnectorsToFire(
    formConnectors,
    connectors,
    formDefinition,
    dataElementFired
  );

  if (!firedConnectors || firedConnectors.length === 0) {
    return;
  }

  // Fire the connectors and capture their promises
  const promises = firedConnectors.map((fc) => onFireConnector(fc, formData));

  // Cancel the requests any previously fired connectors
  if (fireConnectorsPromise) {
    fireConnectorsPromise.cancel();
  }

  fireConnectorsPromise = makeCancelable(
    Promise.all(
      promises.map((p) =>
        p.then(
          (data) => ({ ok: true, data }),
          (error) => {
            //onConnectorFail(firedConnectors[i], error)
            return { ok: false, error };
          }
        )
      )
    )
  );

  // Await the responses
  let responses: [{ ok: any; data: any }];
  try {
    responses = await fireConnectorsPromise.promise;
  } catch (e: any) {
    if (e.isCanceled) {
      return;
    }
    throw e;
  }

  // Apply data response to each list of data element
  Array.from(responses).forEach((response /*, index*/) => {
    if (!response.ok || response.data == null || !Array.isArray(response.data)) {
      return;
    }

    // const formConnector: FormConnector = formConnectors.filter(
    //   (formConnector) => formConnector.name === firedConnectors[index].name
    // )[0];

    formDefinition.contextFields = formDefinition.contextFields.map((dataElement) => {
      // if (
      //   formConnector.output.some((output: { name: any }) => output.name === dataElement.name) &&
      //   dataElement.dataType === "List" &&
      //   dataElement.dictionaryList?.feedFromDataConnector &&
      //   dataElement.dictionaryList.dataConnectorName === firedConnectors[index].name
      // ) {
      //   dataElement.dictionaryList.elements = response.data.map((item: any[]) => {
      //     return {
      //       name: item[firedConnectors[index].valueExternalList],
      //       code: item[firedConnectors[index].keyExternalList],
      //       dictionaryListId: 1,
      //       children: [""],
      //       replacementInstances: [""],
      //     };
      //   });
      // }
      return dataElement;
    });
    onFormDefinitionChange(formDefinition);
  });
};
