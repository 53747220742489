import {
  Box,
  Button,
  Spinner,
  TapArea,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import { useCallback, useMemo, useState } from "react";

import { validateEmail } from "@prodoctivity/shared";
import type { HttpLoginResponse } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useSettings } from "../../hooks/useSettings";
import { GoogleProfileObj } from "../../utils";
import { GoogleButton } from "../Button/GoogleButton";

export type LoginInput = {
  email: string;
  password: string;
};

export type Props = {
  email: string;
  password: string;
  returnTo: string | undefined;
  setLoginInput: (currentInput: LoginInput) => void;
  authenticationHandler: (
    user: string,
    password: string,
    returnTo: string | undefined,
    withGoogle?: boolean
  ) => Promise<
    | { success: true; payload: HttpLoginResponse["payload"] }
    | { success: false; errorMessage: string }
  >;
};

const UserLogin: React.FC<Props> = ({
  email,
  password,
  returnTo,
  setLoginInput,
  authenticationHandler,
}) => {
  const { colors } = useColors();
  const navigate = useNavigate();
  const { googleClientId, disableSignUp } = useSettings();
  const { resources } = useAppTranslation();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");

  const emailValidation = useCallback(
    (value: string) => {
      if (!validateEmail(value)) {
        setUsernameErrorMessage(resources.invalidEmail);
      } else {
        setUsernameErrorMessage("");
      }
    },
    [resources.invalidEmail]
  );

  const mutationFunction = useCallback(
    (params: {
      user: string;
      password: string;
      returnTo: string | undefined;
      withGoogle?: boolean;
    }) => {
      return authenticationHandler(
        params.user,
        params.password,
        params.returnTo,
        params.withGoogle
      );
    },
    [authenticationHandler]
  );

  const mutationOptions = useMemo(
    () => ({
      onSuccess(successResponse: Awaited<ReturnType<typeof authenticationHandler>>) {
        if (successResponse.success) {
          if (localStorage) {
            localStorage.setItem("login.email", email);
          }
          navigate(returnTo || "/");
        } else {
          setPasswordErrorMessage(resources.invalidUserOrPassword);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError(error: any) {
        if (error?.response?.status === 403) {
          setPasswordErrorMessage(resources.invalidUserOrPassword);
        } else {
          setPasswordErrorMessage(resources.invalidLogin);
        }
      },
    }),
    [email, navigate, returnTo, resources]
  );

  const { mutate: authenticate, isLoading } = useMutation(mutationFunction, mutationOptions);

  const handlerEnterKeyPress = useCallback(
    ({ event: { code } }: { event: { code: string } }) => {
      if (code === "Enter" && !usernameErrorMessage && password) {
        authenticate({
          user: email,
          password: password,
          returnTo: returnTo,
          withGoogle: false,
        });
      }
    },
    [authenticate, usernameErrorMessage, password, email, returnTo]
  );

  const authenticateClick = useCallback(() => {
    authenticate({
      user: email,
      password: password,
      returnTo: returnTo,
      withGoogle: false,
    });
  }, [authenticate, returnTo, password, email]);

  const googleLoginCallback = useCallback(
    async (profileObj: GoogleProfileObj, token: string) => {
      authenticate({
        user: profileObj.email,
        password: token,
        returnTo: returnTo,
        withGoogle: true,
      });
    },
    [authenticate, returnTo]
  );

  const googleLoginComponent = useMemo(() => {
    if (googleClientId) {
      return (
        <Box marginBottom={4} alignItems="center">
          <GoogleButton
            width={400}
            type="signin_with"
            setGoogleProfile={googleLoginCallback}
            googleClientId={googleClientId}
          />
        </Box>
      );
    }

    return (
      <Box marginBottom={4} alignItems="center">
        <Spinner show={true} size="md" color="default" />
      </Box>
    );
  }, [googleClientId, googleLoginCallback]);

  return (
    <Box display="block" paddingX={8} paddingY={6} rounding="pill">
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Text weight="bold">{resources.email}</Text>
        </Box>
        <TextField
          id="emailField"
          type="email"
          autoComplete="email"
          name="email"
          value={email}
          onChange={(e) => {
            emailValidation(e.value);
            setLoginInput({ email: e.value, password });
          }}
          placeholder={resources.enterEmail}
          label={resources.email}
          labelDisplay="hidden"
          errorMessage={usernameErrorMessage}
          onKeyDown={handlerEnterKeyPress}
        />
      </Box>
      <Box marginBottom={4}>
        <Box display="flex" direction="row" justifyContent="between" marginBottom={2}>
          <Text weight="bold">{resources.password}</Text>
        </Box>
        <TextField
          id="passwordField"
          value={password}
          onChange={(e) => {
            setLoginInput({ email, password: e.value });
          }}
          onKeyDown={handlerEnterKeyPress}
          placeholder={resources.enterPassword}
          type={"password"}
          label={resources.password}
          labelDisplay="hidden"
          errorMessage={passwordErrorMessage}
        />
      </Box>
      <Box
        marginBottom={4}
        display="flex"
        direction="row"
        justifyContent="between"
        alignItems="center"
      >
        <Box marginEnd={12}></Box>
        <Box marginStart={12}>
          <TapArea onTap={() => navigate("/forgot-password")}>
            <Text color={colors.primary}>{resources.forgotPassword}</Text>
          </TapArea>
        </Box>
      </Box>
      <Box>
        <Button
          color="blue"
          fullWidth
          accessibilityLabel={resources.signIn}
          text={resources.signIn}
          disabled={isLoading || !!usernameErrorMessage || !password}
          onClick={authenticateClick}
        />
      </Box>
      {/* <Box margin={2} />
      <Box padding={1} width="100%" display="flex" justifyContent="center">
        <Box width="100%" borderStyle="sm">
          <Button
            color="transparent"
            fullWidth
            accessibilityLabel={resources.logInWithSso}
            text={resources.logInWithSso}
            onClick={() => navigate(organizationLinkTemplates.loginInWithSoo())}
          />
        </Box>
      </Box> */}
      <Box
        marginBottom={5}
        marginTop={5}
        display="flex"
        direction="row"
        justifyContent="between"
        alignItems="center"
      >
        <Box height={1} width={150} color={colors.neutral500} />
        <Box>
          <Text color={colors.neutral900}>{resources.or}</Text>
        </Box>
        <Box width={150} height={1} color={colors.neutral500} />
      </Box>
      {googleLoginComponent}

      {!disableSignUp && (
        <Box
          display="flex"
          width={400}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          direction="row"
          gap={4}
        >
          <Text color={colors.black900} size="300">
            {resources.dontHaveAccount}
          </Text>
          <TapArea fullWidth={false} onTap={() => navigate("/sign-up")}>
            <Text size="300" color={colors.primary} align="center">
              {resources.signUp}
            </Text>
          </TapArea>
        </Box>
      )}
    </Box>
  );
};

export { UserLogin };
