export type leyendLocation =
  | "leyend-left"
  | "leyend-right"
  | "leyend-bottom"
  | "leyend-top"
  | "none";

export const leyendPieConfig: Record<leyendLocation, any[]> = {
  none: [
    {
      anchor: "bottom",
      direction: "row",
      justify: false,
      translateX: 0,
      translateY: 56,
      itemsSpacing: 0,
      itemWidth: 100,
      itemHeight: 18,
      itemTextColor: "#999",
      itemDirection: "left-to-right",
      itemOpacity: 1,
      symbolSize: 18,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ],
  "leyend-left": [
    {
      anchor: "left",
      direction: "column",
      justify: false,
      translateX: 6,
      translateY: 15,
      itemsSpacing: 0,
      itemWidth: 50,
      itemHeight: 20,
      itemTextColor: "#999",
      itemDirection: "left-to-right",
      itemOpacity: 1,
      symbolSize: 15,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ],
  "leyend-right": [
    {
      anchor: "right",
      direction: "column",
      justify: false,
      translateX: 6,
      translateY: 15,
      itemsSpacing: 0,
      itemWidth: 106,
      itemHeight: 39,
      itemTextColor: "#999",
      itemDirection: "right-to-left",
      itemOpacity: 1,
      symbolSize: 25,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ],
  "leyend-bottom": [
    {
      anchor: "bottom",
      direction: "row",
      justify: false,
      translateX: -50,
      translateY: 47,
      itemsSpacing: 0,
      itemWidth: 106,
      itemHeight: 39,
      itemTextColor: "#999",
      itemDirection: "right-to-left",
      itemOpacity: 1,
      symbolSize: 25,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ],
  "leyend-top": [
    {
      anchor: "top",
      direction: "row",
      justify: false,
      translateX: -38,
      translateY: -44,
      itemsSpacing: 0,
      itemWidth: 104,
      itemHeight: 39,
      itemTextColor: "#999",
      itemDirection: "right-to-left",
      itemOpacity: 1,
      symbolSize: 25,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ],
};
