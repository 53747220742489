import { Box, Table, Text } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import type { TemplateSubscriptionType } from "@prodoctivity/shared/src/index-types";
import { TemplatesInitialState } from "./hooks";
import { TemplatesInternalRows } from "./TemplatesInternalRows";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type Props = {
  extractUniqueElements: (subscriptions: TemplatesInitialState) => TemplatesInitialState;
  currentTemplatesSubscriptions: TemplatesInitialState;
  handleCheckBoxOnChange: (
    checkBoxCase: string,
    templateId: string,
    subscriptionType: TemplateSubscriptionType
  ) => void;
  isMutating: boolean;
};

export const TemplateSubscriptionsTable: FunctionComponent<Props> = ({
  extractUniqueElements,
  currentTemplatesSubscriptions,
  handleCheckBoxOnChange,
  isMutating,
}) => {
  const { resources } = useAppTranslation();
  const uniqueTemplates = extractUniqueElements(currentTemplatesSubscriptions);
  return (
    <>
      {uniqueTemplates.map((subscription) => {
        return (
          <Table.RowExpandable
            key={`${subscription.templateId}_${subscription.action}`}
            accessibilityExpandLabel={resources.expand}
            accessibilityCollapseLabel={resources.collapse}
            id={`${subscription.templateId}_${subscription.action}`}
            expandedContents={
              <Box>
                <TemplatesInternalRows
                  currentTemplatesSubscriptions={currentTemplatesSubscriptions}
                  isMutating={isMutating}
                  handleCheckBoxOnChange={handleCheckBoxOnChange}
                  uniqueTemplateId={subscription.templateId}
                />
              </Box>
            }
          >
            <Table.Cell colSpan={3}>
              <Box width={500}>
                <Text>{subscription.templateName}</Text>
              </Box>
            </Table.Cell>
            <Table.Cell colSpan={3}>
              <Text></Text>
            </Table.Cell>
            <Table.Cell colSpan={3}>
              <Text></Text>
            </Table.Cell>
            <Table.Cell colSpan={3}>
              <Text></Text>
            </Table.Cell>
          </Table.RowExpandable>
        );
      })}
    </>
  );
};
