import { Box, Spinner, Text } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { useDocumentCollection } from "./hooks";
import { useAppTranslation } from "../hooks/useAppTranslation";

type Props = {
  configId: string;
  instanceId: string;
};

export const DocumentCollectionInstanceName: FunctionComponent<Props> = ({
  configId,
  instanceId,
}) => {
  const { documentCollection, instanceIdIsLoading } = useDocumentCollection(instanceId, configId);
  const { resources } = useAppTranslation();

  if (instanceIdIsLoading) {
    return (
      <Box>
        <Spinner show={true} accessibilityLabel={resources.loading} />;
      </Box>
    );
  }

  return (
    <Box display="flex">
      <Text>{documentCollection?.name}</Text>
    </Box>
  );
};
