import { DropdownProps, Dropdown as GestaltDropdown } from "gestalt";
import { Fragment, FunctionComponent, useMemo, useRef, useState } from "react";
import { dropDownZIndex } from "./layout";

import type { DropdownOption } from "gestalt";
import { Box } from "./Box";
import { ButtonWithRef } from "./Button/Button";
import { TapArea } from "./TapArea";
import { Text } from "./Text";

const Dropdown: FunctionComponent<DropdownProps> = (props: DropdownProps) => {
  return <GestaltDropdown {...props} />;
};

const DropdownItem = GestaltDropdown.Item;
const DropdownLink = GestaltDropdown.Link;
const DropdownSection = GestaltDropdown.Section;

DropdownItem.displayName = "Dropdown.Item";
DropdownLink.displayName = "Dropdown.Link";
DropdownSection.displayName = "Dropdown.Section";
Dropdown.displayName = "Dropdown ";
export { Dropdown, DropdownItem, DropdownLink, DropdownSection };
export type { DropdownOption };

export type DropdownWithIconsProps = {
  id: string;
  value: string | undefined;
  onChange(value: string | undefined): void;
  items: Array<{
    value: string;
    label: string;
    icon: JSX.Element;
  }>;
};

export const DropdownWithIcons: FunctionComponent<DropdownWithIconsProps> = ({
  id,
  value,
  onChange,
  items,
}) => {
  const [open, setOpen] = useState(false);

  const selected: { value: string; label: string } | undefined = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    const found = (items || []).find((item) => item.value === value);

    if (!found) {
      return undefined;
    }

    return {
      value: found.value,
      label: found.label,
    };
  }, [items, value]);

  const anchorRef = useRef(null);

  return (
    <Fragment>
      <Box display="flex" height="100%">
        <Box>
          <ButtonWithRef
            name="search_type_dropdown_button"
            accessibilityExpanded={open}
            accessibilityHaspopup
            iconEnd="arrow-down"
            onClick={() => setOpen((prevVal) => !prevVal)}
            ref={anchorRef}
            selected={open}
            size="sm"
            text={selected ? selected.label : "Display"}
          />
        </Box>
      </Box>

      {open && (
        <Dropdown
          id={id}
          onDismiss={() => setOpen(false)}
          zIndex={dropDownZIndex}
          anchor={anchorRef.current}
          idealDirection="down"
        >
          {items.map((item) => {
            return (
              <DropdownItem
                key={item.value}
                option={{
                  label: item.label,
                  value: item.value,
                }}
                onSelect={() => {
                  onChange(item.value);
                  setOpen(false);
                }}
              >
                <TapArea>
                  <Box display="flex" direction="row" gap={2} alignItems="center">
                    <Box display="flex" flex="shrink">
                      {item.icon}
                    </Box>
                    <Box display="flex" flex="grow" alignItems="center">
                      <Text>{item.label}</Text>
                    </Box>
                  </Box>
                </TapArea>
              </DropdownItem>
            );
          })}
        </Dropdown>
      )}
    </Fragment>
  );
};
