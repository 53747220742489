import {
  Box,
  Button,
  Divider,
  Layer,
  Modal,
  SearchField,
  Table,
  TapArea,
  Text,
  TrashSvgIcon,
  modalZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type {
  HttpGetOrganizationRolesRequest,
  HttpGetOrganizationRolesResponse,
} from "@prodoctivity/types";
import { FunctionComponent, useMemo, useState } from "react";

import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../components/hooks";
import { organizationLinkTemplates } from "../../../link-templates";
import { EditSvgIcon } from "../../../svg/EditSvgIcon";
import { useRoleManagementList } from "./hooks";

const PAGINATION_OPTIONS: HttpGetOrganizationRolesRequest["queryParameters"]["rowsPerPage"][] = [
  "15",
  "30",
  "50",
];

const RoleManagementListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const [deleteInfo, setDeleteInfo] = useState<{ roleId: string; roleName: string } | undefined>(
    undefined
  );
  const { breakpoint } = useDesignBreakpoint();
  const {
    paginatedData,
    refetch,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setFilter,
    setPageLength,
  } = usePaginatedDataEndpoint<
    { roles: HttpGetOrganizationRolesResponse["payload"]["roles"] },
    HttpGetOrganizationRolesRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.getOrganizationRoles(currentPage, rowsPerPage, filter);
    },
    "settings_role_list",
    { refetchInterval: 60 * 1000 }
  );

  const {
    resources,
    organizationNavigate,

    toastMessage,
    setToastMessage,

    mutateDeleteRole,
    isMutating,
  } = useRoleManagementList(refetch);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.security,
        url: organizationLinkTemplates.manageRoles(),
      },
      { type: "text", name: resources.rolesManagement.roles },
    ];
  }, [resources.home, resources.rolesManagement.roles, resources.settings, resources.security]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" flex="grow" padding={2} gap={2}>
        {deleteInfo && (
          <Layer zIndex={modalZIndex}>
            <Modal
              accessibilityModalLabel={resources.deleteRole}
              heading={
                <Box height={50}>
                  <Box margin={4}>
                    <Text weight="bold" size="400" color={colors.secondary}>
                      {resources.deleteRole}
                    </Text>
                  </Box>
                  <Divider />
                </Box>
              }
              size="sm"
              onDismiss={() => setDeleteInfo(undefined)}
              footer={
                <Box display="flex">
                  <Box>
                    <Button
                      color={"transparent"}
                      onClick={() => setDeleteInfo(undefined)}
                      text={resources.cancel}
                    />
                  </Box>
                  <Box marginStart="auto">
                    <Button
                      color={"red"}
                      onClick={() => {
                        mutateDeleteRole(deleteInfo.roleId);
                        setDeleteInfo(undefined);
                      }}
                      text={resources.yesDelete}
                    />
                  </Box>
                </Box>
              }
            >
              <Box margin={4} display="flex" justifyContent="center">
                <Text>
                  {resources.youAreAboutToDeleteThisRole.replace("{roleName}", deleteInfo.roleName)}
                </Text>
              </Box>
              <Divider />
            </Modal>
          </Layer>
        )}
        <SearchComponentWrapper
          componentHeaderText={resources.rolesManagement.roles}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="role-search"
          searchFieldLabel={resources.search}
          setFilter={setFilter}
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          disableAddButton={isMutating}
          buttonAction={() => organizationNavigate(organizationLinkTemplates.newRole())}
          omitSearch={true}
        />
        <Box padding={5} display="flex" flex="shrink" direction="column">
          <Pagination<typeof rowsPerPage>
            id="role_list_pagination_top"
            rowsLabel={`${resources.rolesManagement.roles}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.roles.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            extraComponent={
              <SearchField
                marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                marginEnd={2}
                accessibilityLabel={resources.search}
                id="roles_filter"
                onChange={({ value }) => {
                  setFilter(value);
                }}
              />
            }
          />
          <Divider direction="horizontal" />
        </Box>
        {toastMessage && (
          <NotificationMessage
            type={toastMessage.type}
            message={toastMessage.message}
            position="bottom-left"
            handleDismiss={() => setToastMessage(undefined)}
          />
        )}
        <Box
          marginTop={8}
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Table accessibilityLabel="Roles Table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Box paddingX={4}>
                    <Text size="200" weight="bold">
                      {resources.name.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                {breakpoint !== "small" && breakpoint !== "medium" && (
                  <>
                    <Table.HeaderCell>
                      <Box>
                        <Text size="200" weight="bold">
                          {resources.users.toUpperCase()}
                        </Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box>
                        <Text size="200" weight="bold">
                          {resources.documentTypes.documentTypes.toUpperCase()}
                        </Text>
                      </Box>
                    </Table.HeaderCell>
                  </>
                )}
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" weight="bold">
                      {resources.actions.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(paginatedData?.roles || []).map((tg) => (
                <Table.Row hoverStyle="gray" key={`${tg.id}`}>
                  <Table.Cell>
                    <Box marginStart={4} display={"flex"}>
                      <Box>
                        <Text
                          size="200"
                          weight="bold"
                          title={tg.name}
                          ellipsisLength={
                            breakpoint === "medium" || breakpoint === "small" ? 15 : 32
                          }
                        >
                          {tg.name}
                        </Text>
                      </Box>
                    </Box>
                  </Table.Cell>
                  {breakpoint !== "small" && breakpoint !== "medium" && (
                    <>
                      <Table.Cell>
                        <Text size="200">{tg.usersCount}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text size="200">{tg.documentTypesCount}</Text>
                      </Table.Cell>
                    </>
                  )}
                  <Table.Cell>
                    <Box display="flex" gap={3} alignItems="center">
                      <Box>
                        <TapArea
                          role="button"
                          disabled={isMutating}
                          accessibilityLabel={resources.edit}
                          onTap={() =>
                            organizationNavigate(
                              organizationLinkTemplates.editRole(tg.id, undefined)
                            )
                          }
                        >
                          <EditSvgIcon width={20} height={20} color={colors.primary} />
                        </TapArea>
                      </Box>
                      <Box>
                        <TapArea
                          role="button"
                          disabled={isMutating}
                          accessibilityLabel={resources.delete_}
                          onTap={() => setDeleteInfo({ roleId: tg.id, roleName: tg.name })}
                        >
                          <TrashSvgIcon width={20} height={20} color={colors.primary} />
                        </TapArea>
                      </Box>
                    </Box>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Box>
        <Box marginTop={2} padding={2} display="flex" direction="row">
          <Box display="flex" flex="grow" />
          <Box display="flex" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="role_list_pagination_bottom"
              rowsLabel={`${resources.rolesManagement.roles}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.roles.length : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={PAGINATION_OPTIONS}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default RoleManagementListPage;
