import {
  Box,
  Checkbox,
  Text,
  Table,
  TapArea,
  useDesignBreakpoint,
  Skeleton,
  FancyDateTime,
} from "@prodoctivity/design-system";
import type { GetRecentSearchListResponse } from "@prodoctivity/types/model/recent-searches";
import { FunctionComponent, SyntheticEvent } from "react";
import { useRecentSearchLogic } from "../hooks";
import Each from "./Each";
import { RenderDocumentTypeIconSvg } from "./MainContent";
import { useAppTranslation } from "../../../hooks/useAppTranslation";

interface RecentSearchListProps {
  recentSearchList: GetRecentSearchListResponse[];
  isToggleSelect: boolean;
  isSelectAll: boolean;
  onCheckboxChange: (event: SyntheticEvent<HTMLInputElement, Event>) => void;
  isChecked: {
    [key: string]: boolean;
  };
}

export const RecentSearchList: FunctionComponent<RecentSearchListProps> = ({
  recentSearchList,
  isSelectAll,
  onCheckboxChange,
  isChecked,
}) => {
  const { buildSearchUrl, searchTypeText } = useRecentSearchLogic();
  const { resources, moment } = useAppTranslation();

  return (
    <>
      <Each
        of={recentSearchList}
        render={(searchItem, index) => (
          <Table.Row key={index} hoverStyle="gray">
            <Table.Cell>
              <Box display="flex" direction="row" alignItems="center">
                <Checkbox
                  id={searchItem._id}
                  name={searchItem._id}
                  size="sm"
                  checked={isSelectAll ? isSelectAll : !!isChecked[searchItem._id]}
                  onChange={(e) => onCheckboxChange(e.event)}
                />
                <TapArea
                  onTap={() =>
                    buildSearchUrl(
                      searchItem.searchQuery,
                      searchItem.category,
                      searchItem.searchType,
                      searchItem.documentId
                    )
                  }
                >
                  <Box display="flex" alignItems="center" paddingX={2}>
                    <Box paddingX={2}>
                      <RenderDocumentTypeIconSvg iconType={searchItem.category} size={undefined} />
                    </Box>
                    {searchItem.searchQuery ? (
                      <Text size="300" title={searchItem.searchQuery} ellipsisLength={60}>
                        {searchItem.searchQuery}
                      </Text>
                    ) : (
                      <Text size="300">...</Text>
                    )}
                  </Box>
                </TapArea>
              </Box>
            </Table.Cell>
            <Table.Cell>
              <Box width={"1%"} borderStyle={"sm"} borderRadius={4} height={25} />
            </Table.Cell>

            <Table.Cell>
              <TapArea
                onTap={() =>
                  buildSearchUrl(
                    searchItem.searchQuery,
                    searchItem.category,
                    searchItem.searchType,
                    searchItem.documentId
                  )
                }
              >
                <Text
                  size="300"
                  title={searchTypeText[searchItem.category].type}
                  ellipsisLength={65}
                >
                  {searchTypeText[searchItem.category].type}
                </Text>
              </TapArea>
            </Table.Cell>
            <Table.Cell>
              <Box width={"1%"} borderStyle={"sm"} borderRadius={4} height={25} />
            </Table.Cell>

            <Table.Cell>
              <FancyDateTime moment={moment} resources={resources} value={searchItem.createdAt} />
            </Table.Cell>
          </Table.Row>
        )}
      />
    </>
  );
};

export const RecentSearchCard: FunctionComponent<RecentSearchListProps> = ({
  recentSearchList,
  isSelectAll,
  onCheckboxChange,
  isChecked,
}) => {
  const { breakpoint } = useDesignBreakpoint();
  const { searchTypeText, isLoading, buildSearchUrl } = useRecentSearchLogic();
  const { resources, moment } = useAppTranslation();

  return (
    <Box display="flex" gap={4} justifyContent="center" wrap>
      {isLoading && <RecentSearchCardSkeleton />}
      <Each
        of={recentSearchList || []}
        render={(data, index) => (
          <Box
            borderRadius={12}
            borderStyle="sm"
            padding={4}
            maxWidth={320}
            height={108}
            width={breakpoint === "small" ? "90%" : undefined}
            key={index}
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                id={data._id}
                name={data._id}
                size="sm"
                checked={isSelectAll ? isSelectAll : !!isChecked[data._id]}
                onChange={(e) => onCheckboxChange(e.event)}
              />
              <Box marginStart={2}>
                <RenderDocumentTypeIconSvg iconType={data.category} size={50} />
              </Box>
              <TapArea
                onTap={() =>
                  buildSearchUrl(data.searchQuery, data.category, data.searchType, data.documentId)
                }
              >
                <Box marginStart={4}>
                  <Text size="300" weight="bold">
                    {searchTypeText[data.category].type}
                  </Text>
                  <Text title={data.searchQuery} ellipsisLength={25}>
                    {data.searchQuery}
                  </Text>
                  <FancyDateTime
                    moment={moment}
                    resources={resources}
                    value={data.createdAt}
                    size="200"
                  />
                </Box>
              </TapArea>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export const RecentSearchCardSkeleton = () => {
  const { breakpoint } = useDesignBreakpoint();
  return (
    <>
      <Box minWidth={320} width={breakpoint === "small" ? "90%" : undefined}>
        <Skeleton height={75} width={"100%"} />
      </Box>
      <Box minWidth={320} width={breakpoint === "small" ? "90%" : undefined}>
        <Skeleton height={75} width={"100%"} />
      </Box>
      <Box minWidth={320} width={breakpoint === "small" ? "90%" : undefined}>
        <Skeleton height={75} width={"100%"} />
      </Box>
      <Box minWidth={320} width={breakpoint === "small" ? "90%" : undefined}>
        <Skeleton height={75} width={"100%"} />
      </Box>
    </>
  );
};
