import { Box, BoxWithRef, SearchField, Text, useColors } from "@prodoctivity/design-system";
import { FC, useCallback, useMemo, useState } from "react";
import { CheckboxLine } from "./CheckboxLine";
import { CircleCloseSvgIcon } from "../../../svg/CircleCloseSVGIcon";
import { useAppTranslation } from "../../../hooks/useAppTranslation";

export const FilterReportContentBox: FC<{
  title: string;
  filters: Array<{ label: string; checked: boolean; type?: "default" | "value" }>;
  selectedFilter: Array<string>;
  onFilterChange: (category: string, filterValue: { value: string; checkState: boolean }) => void;
  onCheckAll: (category: string) => void;
  defaults: Array<string>;
}> = ({ title, filters, selectedFilter, defaults, onFilterChange, onCheckAll }) => {
  const { colors } = useColors();

  const [searchValue, setSearchValue] = useState<string>("");
  const { resources } = useAppTranslation();
  const [searchOpen, setOpen] = useState<boolean>(false);

  const handlerBoxFilterValues = useCallback(
    (values: { value: string; checkState: boolean }) => {
      onFilterChange(title, { value: values.value, checkState: values.checkState });
    },
    [onFilterChange, title]
  );

  const handlerCheckAll = useCallback(() => {
    onCheckAll(title);
  }, [onCheckAll, title]);

  const checkableOptionsList = useMemo(() => {
    if (searchValue === "") {
      return filters;
    }
    return filters.filter((x) => x.label.includes(searchValue));
  }, [searchValue, filters]);

  if (!filters) {
    return <>Loading</>;
  }

  const emptyCriteriaSearchResult = () => {
    return (
      <Box display="flex" direction="column">
        <Box alignContent="center" alignItems="center">
          <Text align="center">{resources.listIsEmpty}</Text>
        </Box>
      </Box>
    );
  };

  return (
    <BoxWithRef
      ref={(node) => {
        if (node) {
          node.classList.add("report-content-box");
        }
      }}
      borderRadius={6}
    >
      <Box display="flex" direction="column">
        <Box
          color={colors.white}
          display="flex"
          direction="row"
          paddingY={3}
          paddingX={4}
          alignItems="center"
          alignContent="center"
          borderRadius={6}
          gap={1}
        >
          {!searchOpen && (
            <>
              <Box width={"95%"}>
                <Text color={colors.primary600} weight="bold">
                  {title}
                </Text>
              </Box>
              <Box width={"5%"} onClickCapture={() => setOpen(true)}>
                <SearchField
                  borderColor={colors.white}
                  accessibilityLabel={""}
                  id={`filter-for-${title}-dummy`}
                  value={""}
                  onChange={(e) => setSearchValue(e.value)}
                />
              </Box>
            </>
          )}
          {searchOpen && (
            <Box width={"100%"} display="flex" direction="row" gap={2}>
              <Box width={"95%"}>
                <SearchField
                  borderColor={colors.neutral700}
                  accessibilityLabel={`${resources.searchFor} ${title}`}
                  id={`filter-for-${title}`}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.value)}
                />
              </Box>
              <Box display="flex" width={"5%"} onClickCapture={() => setOpen(false)}>
                <Box alignContent="center" alignItems="center">
                  <CircleCloseSvgIcon width={15} height={15} />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        color={colors.white}
        paddingY={3}
        paddingX={4}
        overflow="scrollY"
        marginEnd={-12}
        paddingRight={12}
        width={"100%"}
      >
        <Box height={12} />
        {checkableOptionsList.length === 0
          ? emptyCriteriaSearchResult()
          : checkableOptionsList.map((f) => (
              <CheckboxLine
                key={f.label}
                onClick={handlerBoxFilterValues}
                value={selectedFilter.includes(f.label)}
                defaults={defaults}
                label={f.label}
                type={f.type === "default" ? "default" : "value"}
                checkAll={handlerCheckAll}
              />
            ))}
      </Box>
    </BoxWithRef>
  );
};
