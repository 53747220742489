import { Box, Skeleton, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FunctionComponent, useMemo, useState } from "react";

import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";
import type { MimeType } from "@prodoctivity/shared/src/index-types";
import { useLocation } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { DocumentTypeInfo } from "../../components/Display/DocumentTypeInfo";
import { DocumentViewerSearchResultsNavigator } from "../../components/DocumentViewer/DocumentViewerSearchResultsNavigator";
import { DocumentViewerWrapper } from "../../components/DocumentViewer/DocumentViewerWrapper";
import { DocumentViewerSearchFilterState } from "../../components/DocumentViewer/hooks";
import { Page } from "../../components/Layout/Page";
import { organizationLinkTemplates } from "../../link-templates";
import { usePageHeight } from "../Dashboard/DashboardPageWrapper";
import { PageNotFound } from "./PageNotFound";
import { useDocumentViewerPage } from "./hooks";

type Props = {
  defaultPanel: DocumentViewerPanelKeys | undefined;
};

const DocumentViewerPage: FunctionComponent<Props> = ({ defaultPanel }) => {
  const {
    resources,
    documentTypeInfo,
    documentResponse,
    isLoading,
    isLoadingDocumentTypeInfo,
    isDocumentError,
    clearQueryCache,
  } = useDocumentViewerPage();
  const [selectedPanel, setSelectedPanel] = useState<DocumentViewerPanelKeys>(
    defaultPanel || "none"
  );
  const { state } = useLocation();
  const documentViewerSearchFilterState: DocumentViewerSearchFilterState | undefined =
    useMemo(() => {
      const results: DocumentViewerSearchFilterState | undefined =
        state && state.type === "document-viewer-state"
          ? (state as DocumentViewerSearchFilterState)
          : undefined;

      return results;
    }, [state]);

  const ecmDocument = documentResponse?.document;

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    const documentTypeId = ecmDocument?.documentTypeId;

    if (isDocumentError) {
      return [{ type: "url", name: resources.home, url: organizationLinkTemplates.home() }];
    }

    if (isLoading) {
      return [
        { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
        { type: "text", name: resources.loading },
        { type: "text", name: resources.loading },
      ];
    } else if (!ecmDocument) {
      return [{ type: "url", name: resources.home, url: organizationLinkTemplates.home() }];
    }

    if (isLoadingDocumentTypeInfo) {
      return [
        { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
        { type: "text", name: resources.loading },
        { type: "text", name: resources.loading },
      ];
    }

    if (!documentTypeId) {
      return [{ type: "url", name: resources.home, url: organizationLinkTemplates.home() }];
    }

    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "content",
        content: <DocumentTypeInfo size="300" documentTypeId={documentTypeId} skipLink={true} />,
      },
      { type: "text", name: ecmDocument.name },
    ];
  }, [
    ecmDocument,
    isLoading,
    isLoadingDocumentTypeInfo,
    isDocumentError,
    resources.home,
    resources.loading,
  ]);

  useDocumentTitle(`ProDoctivity - ${resources.document} - ${ecmDocument?.name || ""}`);

  usePageHeight(true);

  return (
    <Page
      fixedHeight={true}
      breadCrumbEntries={breadCrumbEntries}
      breadCrumbExtraComponent={
        documentViewerSearchFilterState !== undefined ? (
          <DocumentViewerSearchResultsNavigator
            searchFilter={documentViewerSearchFilterState.searchFilter}
            index={documentViewerSearchFilterState.index}
          />
        ) : undefined
      }
    >
      {isLoadingDocumentTypeInfo || isLoading || !documentResponse ? (
        <DocumentSkeleton />
      ) : !documentResponse.document ? (
        <PageNotFound message={resources.pageNotFound.documentNotFound} />
      ) : (
        <DocumentViewerWrapper
          evictCacheEntries={clearQueryCache}
          src={documentResponse?.document?.binaries || []}
          selectedPanel={selectedPanel}
          setSelectedPanel={setSelectedPanel}
          height="95vh"
          contentType={documentResponse.document.mimeType as MimeType}
          ecmDocument={ecmDocument}
          documentTypeInfo={documentTypeInfo?.documentType}
          goToLastVersion={undefined}
          isSignatureRequired={false}
          handleShowInstructionTask={undefined}
        />
      )}
    </Page>
  );
};

const DocumentSkeleton: FunctionComponent = () => {
  const { breakpoint } = useDesignBreakpoint();

  if (breakpoint === "small") {
    return (
      <Box display="flex" direction="column">
        <Skeleton height={"4%"} width="100%" />
        <Skeleton height={"96%"} width={"100%"} />
      </Box>
    );
  }

  return (
    <Box display="flex" direction="row">
      <Box display="flex" direction="column" width={"80%"}>
        <Skeleton height={"4%"} width="100%" />
        <Skeleton height={"96%"} width={"100%"} />
      </Box>
      <Skeleton height={"100%"} width={"20%"} />
    </Box>
  );
};

export default DocumentViewerPage;
