import {
  FilesSequence,
  MimeTypes,
  SequenceBody,
  SequenceData,
  mimeTypeToExtension,
} from "@prodoctivity/shared";
import type { RowSequence, SequenceActionBehavior } from "@prodoctivity/shared/src/index-types";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useCallback, useMemo, useReducer, useRef } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { BreadCrumbEntry } from "../../../../components/BreadCrumb";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../../../hooks/useOrganizationQuery";
import { useServices } from "../../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../../link-templates";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";

type SequenceType = "value-list" | "autogenerated";
type StepType = "increment" | "decrement";

export type SequenceMsg =
  | { type: "default"; value: SequenceBody }
  | {
      type: "alterName";
      value: string;
    }
  | {
      type: "alterDescription";
      value: string;
    }
  | {
      type: "isBlocking";
      value: boolean;
    }
  | {
      type: SequenceType;
    }
  | {
      type: "deleteSequenceFile";
      value: string;
    }
  | { type: "changeSequencesValues"; value: string[] }
  | {
      type: "changeSequenceFiles";
      value: FilesSequence[];
    }
  | {
      type: "changeStepType";
      value: StepType;
    }
  | {
      type: "changeStepValue";
      value: number | undefined;
    }
  | {
      type: "changeInitialValue";
      value: number | undefined;
    }
  | {
      type: "changePaddingLength";
      value: number | undefined;
    }
  | {
      type: "changePaddingValue";
      value: string | undefined;
    }
  | {
      type: "changePrefix";
      value: string | undefined;
    }
  | {
      type: "changeSuffix";
      value: string | undefined;
    }
  | {
      type: "changePaddingLength";
      value: number | undefined;
    }
  | {
      type: "changePaddingValue";
      value: string | undefined;
    }
  | {
      type: "isUsed";
      value: boolean;
    }
  | {
      type: "changeBehavior";
      value: SequenceActionBehavior;
    }
  | {
      type: "showDeleteFileWarningModal";
      value: boolean;
      message: string | undefined;
    };

export type FilesValidationMessages = {
  sequenceName: string | undefined;
  sequenceDescription: string | undefined;
  filesErrorMessages: string | undefined;
  initialValue: string | undefined;
  stepType: string | undefined;
  stepValue: string | undefined;
  paddingLength: string | undefined;
  paddingValue: string | undefined;
};

const sequenceReducer = (state: SequenceBody, action: SequenceMsg): SequenceBody => {
  const isValueList = state.type === "value-list";
  const isAutogenerated = state.type === "autogenerated";
  switch (action.type) {
    case "default":
      return { ...state, ...action.value };
    case "alterName":
      return { ...state, sequenceName: action.value };
    case "alterDescription":
      return { ...state, sequenceDescription: action.value };
    case "isBlocking":
      return { ...state, isBlocking: action.value };
    case "value-list":
      return {
        ...state,
        type: "value-list",
        files: undefined,
        behavior: undefined,
        behaviorModal: false,
        behaviorModalMessage: undefined,
      };
    case "autogenerated":
      return {
        ...state,
        type: "autogenerated",
        stepType: "increment",
        stepValue: undefined,
        paddingLength: undefined,
        paddingValue: undefined,
        prefix: undefined,
        suffix: undefined,
        initialValue: undefined,
      };
    case "deleteSequenceFile":
      return isValueList
        ? {
            ...state,
            files: state.files?.filter((file) => {
              return file.fileName !== action.value;
            }),
          }
        : state;
    case "changeSequenceFiles":
      return isValueList ? { ...state, files: action.value } : state;
    case "changeBehavior":
      return isValueList ? { ...state, behavior: action.value } : state;
    case "changeStepType":
      return isAutogenerated ? { ...state, stepType: action.value } : state;
    case "changeStepValue":
      return isAutogenerated ? { ...state, stepValue: action.value } : state;
    case "changeInitialValue":
      return isAutogenerated ? { ...state, initialValue: action.value } : state;
    case "changePaddingLength":
      return isAutogenerated ? { ...state, paddingLength: action.value } : state;
    case "changePaddingValue":
      return isAutogenerated ? { ...state, paddingValue: action.value } : state;
    case "changePrefix":
      return isAutogenerated ? { ...state, prefix: action.value } : state;
    case "changeSuffix":
      return isAutogenerated ? { ...state, suffix: action.value } : state;
    case "isUsed":
      return { ...state, isUsed: action.value };
    case "showDeleteFileWarningModal":
      return isValueList
        ? { ...state, behaviorModal: action.value, behaviorModalMessage: action.message }
        : state;

    default:
      return state;
  }
};

const hasShortValue = (value: string) => {
  return value.trim().length < 1;
};

const fileHasShortValue = (file: { valueList: string[] }) => {
  return file.valueList.some(hasShortValue);
};

export const useSequencesConfiguration = (
  sequenceName: string | undefined,
  onCancel: () => void
) => {
  const { resources } = useAppTranslation();
  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.dataDictionary.dictionary,
        url: organizationLinkTemplates.dataDictionary(),
      },
      { type: "text", name: resources.sequences, url: organizationLinkTemplates.dataSequences() },
      { type: "text", name: sequenceName || resources.new },
    ];
  }, [
    resources.dataDictionary.dictionary,
    resources.home,
    resources.sequences,
    resources.settings,
    resources.new,
    sequenceName,
  ]);
  const [success] = useSnackbar({ style: { backgroundColor: "#008753" } });
  const [fail] = useSnackbar({ style: { backgroundColor: "#CC0000" } });
  const { saveSequence, updateSequence, getSequence, appendValuesToSequence, rollBackSequence } =
    useServices();

  const organizationNavigate = useOrganizationNavigate();
  const goToSequenceList = useCallback(() => {
    organizationNavigate("/settings/data-dictionary/sequences");
  }, [organizationNavigate]);

  const getSequenceData = useCallback(async () => {
    if (sequenceName) {
      const sequenceDataResponse = await getSequence(sequenceName);
      const response: SequenceData = {
        sequence: sequenceDataResponse.sequenceConfig,
        filesSequence: sequenceDataResponse.filesSequence,
        isUsed: sequenceDataResponse.isUsed,
      };
      dispatch({ type: "default", value: mapSequenceRowToSequenceBody(response) });
      dispatch({ type: "isUsed", value: response.isUsed });
      return response;
    }
    return {
      sequence: undefined,
    };
  }, [getSequence, sequenceName]);

  const {
    data: sequenceDataResponse,
    isLoading: isLoadingSequence,
    refetch: refetchSequence,
  } = useOrganizationQuery(`/sequence/${sequenceName}`, getSequenceData, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const [sequenceState, dispatch] = useReducer(
    sequenceReducer,
    mapSequenceRowToSequenceBody(sequenceDataResponse)
  );

  const filesErrorMessages = useMemo(() => {
    if (
      sequenceState.type === "value-list" &&
      (!sequenceState.files?.length || sequenceState.files.some(fileHasShortValue))
    ) {
      return true;
    }
    return false;
  }, [sequenceState]);

  const { isSaveButtonDisable, validationMessages } = useMemo(() => {
    const messages: FilesValidationMessages = {
      sequenceName: undefined,
      sequenceDescription: undefined,
      filesErrorMessages: undefined,
      initialValue: undefined,
      stepType: undefined,
      stepValue: undefined,
      paddingLength: undefined,
      paddingValue: undefined,
    };
    let disableSaveButton = false;

    if (!sequenceState.sequenceName) {
      disableSaveButton = true;
      messages.sequenceName = resources.required;
    }
    if (!sequenceState.sequenceDescription) {
      disableSaveButton = true;
      messages.sequenceDescription = resources.required;
    }
    if (sequenceState.type === "autogenerated") {
      if (sequenceState.initialValue === undefined || sequenceState.initialValue < 0) {
        disableSaveButton = true;
        messages.initialValue = resources.required;
      }

      if (!sequenceState.stepType) {
        disableSaveButton = true;
        messages.stepType = resources.required;
      }

      if (!sequenceState.stepValue || sequenceState.stepValue < 0) {
        disableSaveButton = true;
        messages.stepValue = resources.required;
      }

      if (
        sequenceState.paddingValue &&
        (!sequenceState.paddingLength || sequenceState.paddingLength < 1)
      ) {
        disableSaveButton = true;
        messages.paddingLength = resources.required;
      }

      if (sequenceState.paddingLength && !sequenceState.paddingValue?.trim()) {
        disableSaveButton = true;
        messages.paddingValue = resources.required;
      }
    }

    return {
      isSaveButtonDisable: disableSaveButton,
      validationMessages: messages,
    };
  }, [sequenceState, resources.required]);

  // #region sequenceConfiguration

  const saveSequenceData = useCallback(async () => {
    const mappedSequence = mapSequenceBodyToSequenceRow(sequenceState);
    return await saveSequence(mappedSequence);
  }, [saveSequence, sequenceState]);

  const appendValues = useCallback(async () => {
    const isValueList = sequenceState.type === "value-list";
    const sequenceName = sequenceState.sequenceName;
    const sequenceFiles = isValueList && sequenceState.files;

    if (sequenceName && sequenceFiles) {
      return await appendValuesToSequence(sequenceName, sequenceFiles);
    }
  }, [appendValuesToSequence, sequenceState]);

  const { mutate: saveSequenceMutation } = useMutation(saveSequenceData, {
    onSuccess: async () => {
      success(resources.saved);
      appendValues();
      refetchSequence();
      onCancel();
    },
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      fail(error.response.data.errors[0].message);
    },
  });

  const updateSequenceData = useCallback(async () => {
    const mappedSequence = mapSequenceBodyToSequenceRow(sequenceState);
    const isValueList = sequenceState.type === "value-list";

    if (sequenceName && sequenceState.sequenceName) {
      return await updateSequence(
        mappedSequence,
        isValueList ? sequenceState.files : undefined,
        sequenceName
      );
    }
  }, [sequenceName, sequenceState, updateSequence]);

  const getOnlyNewFiles = useCallback(() => {
    const isValueList = sequenceState.type === "value-list";
    const sequenceStateFiles = isValueList ? sequenceState.files : undefined;
    if (sequenceDataResponse) {
      const sequenceData = mapSequenceRowToSequenceBody(sequenceDataResponse);
      const sequenceDataFiles = sequenceData.type === "value-list" ? sequenceData.files : undefined;
      if (sequenceDataFiles && sequenceStateFiles) {
        const newFiles = sequenceStateFiles.filter((file) => {
          return !sequenceDataFiles.some((dataFile) => dataFile.fileName === file.fileName);
        });
        return newFiles;
      }
    }
    return undefined;
  }, [sequenceDataResponse, sequenceState]);

  const { mutate: updateSequenceMutation } = useMutation(updateSequenceData, {
    onSuccess: () => {
      success(resources.saved);
      const newFiles = getOnlyNewFiles();
      if (newFiles) {
        dispatch({ type: "changeSequenceFiles", value: newFiles });
      }
      refetchSequence();
      goToSequenceList();
    },
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      fail(error.response.data.errors[0].message);
    },
  });

  // #endregion

  //#region sourceType
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const changeSequenceType = useCallback((sequenceType: SequenceType) => {
    dispatch({ type: sequenceType });
  }, []);

  const alterSequenceValue = useCallback((action: SequenceMsg) => dispatch(action), []);

  const acceptedFormats = {
    CsvText: mimeTypeToExtension(MimeTypes.CsvText),
    PlainFormat: ".txt",
  };

  const acceptedFormatsString = Object.values(acceptedFormats).join(",");

  const selectFromTable = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (
        event.target.files &&
        event.target.files.length > 0 &&
        sequenceState.type === "value-list"
      ) {
        const sequenceFiles: FilesSequence[] = sequenceState.files || [];
        const file = event.target.files[0];
        const fileName = file.name;

        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileType = file.type;
        if (
          !acceptedFormatsString.includes(fileType) &&
          !acceptedFormatsString.includes(fileExtension)
        ) {
          fail(resources.fileTypeNotSupported);
          return;
        }

        if (sequenceFiles.some((sequenceFile) => sequenceFile.fileName === fileName)) {
          fail(resources.fileWithThisNameAlreadyExists);
          return;
        }

        const sequenceFile: FilesSequence = { valueList: [], fileName: "" };
        sequenceFile.fileName = fileName;

        const reader = new FileReader();

        reader.onload = (e) => {
          const contents = e.target?.result;
          const elements =
            typeof contents === "string"
              ? contents
                  .split("\n")
                  .map((element) => element.trim())
                  .filter((element) => element !== "")
              : [];
          const valueList: string[] = elements;
          sequenceFile.valueList = valueList;

          const valueExists = sequenceFiles.some((sequenceFile) =>
            sequenceFile.valueList.some((value) => valueList.includes(value))
          );

          if (valueExists) {
            fail(resources.fileElementsCannotBeRepeatedBetweenFiles);
            return;
          }

          sequenceFiles.push(sequenceFile);
          dispatch({ type: "changeSequenceFiles", value: sequenceFiles });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        };

        reader.onerror = (e) => {
          console.error("Error reading file:", e);
        };
        reader.readAsText(file);
      }
    },
    [
      sequenceState,
      fail,
      resources.fileElementsCannotBeRepeatedBetweenFiles,
      resources.fileWithThisNameAlreadyExists,
      resources.fileTypeNotSupported,
      acceptedFormatsString,
    ]
  );

  //#endregion

  //#region generateNumber
  const stepTypeOptions = useMemo(
    () => [
      { value: "increment", label: "Increment" },
      { value: "decrement", label: "Decrement" },
    ],
    []
  );

  //#endregion

  //#region sequence overview

  const deleteFile = useCallback(
    (fileName: string) => {
      dispatch({ type: "deleteSequenceFile", value: fileName });
    },
    [dispatch]
  );

  const rollBackSequenceData = useCallback(async () => {
    const isValueList = sequenceState.type === "value-list";
    if (isValueList && sequenceState.behavior) {
      return await rollBackSequence(
        sequenceState.behavior.behaviorType,
        sequenceState.behavior.fileNameToClean,
        sequenceState.behavior.sequenceNameToClean
      );
    }
  }, [rollBackSequence, sequenceState]);
  const { mutate: rollBackSequenceMutation } = useMutation(rollBackSequenceData, {
    onSuccess: () => {
      success(resources.saved);
      refetchSequence();
    },
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      fail(error.response.data.errors[0].message);
    },
  });

  const isFileUpdatable = useCallback(
    (file: FilesSequence) => {
      if (sequenceName && sequenceDataResponse) {
        const sequence = mapSequenceRowToSequenceBody(sequenceDataResponse);
        const sequenceFiles = sequence.type === "value-list" ? sequence.files : undefined;
        if (sequenceFiles && JSON.stringify(sequenceFiles).includes(JSON.stringify(file))) {
          return true;
        }
      }
      return false;
    },
    [sequenceName, sequenceDataResponse]
  );

  //#endregion

  return {
    sequenceState,
    alterSequenceValue,
    changeSequenceType,
    selectFromTable,
    saveSequenceMutation,
    updateSequenceMutation,
    deleteFile,
    rollBackSequenceMutation,
    isLoadingSequence,
    fileInputRef,
    stepTypeOptions,
    breadCrumbEntries,
    acceptedFormatsString,
    isSaveButtonDisable,
    validationMessages,
    filesErrorMessages,
    isFileUpdatable,
  };
};

export const mapSequenceRowToSequenceBody = (sequenceResponse: SequenceData): SequenceBody => {
  const sequence = sequenceResponse && sequenceResponse.sequence;
  const isValueList = sequence && sequence.type === "value-list";
  const files = isValueList ? [...(sequenceResponse.filesSequence || [])] : undefined;
  const isAutogenerated = sequence && sequence.type === "autogenerated";
  if (isValueList) {
    return {
      sequenceName: sequence.name.trim(),
      sequenceDescription: sequence.description.trim(),
      isBlocking: sequence.isBlocking,
      type: "value-list",
      files: files,
      isUsed: false,
      behavior: undefined,
      behaviorModal: false,
      behaviorModalMessage: undefined,
    };
  } else if (isAutogenerated) {
    return {
      sequenceName: sequence.name.trim(),
      sequenceDescription: sequence.description.trim(),
      isBlocking: sequence.isBlocking,
      type: "autogenerated",
      stepType: sequence.stepType,
      stepValue: sequence.stepValue,
      paddingLength: sequence.paddingLength,
      paddingValue: sequence.paddingValue?.trim(),
      prefix: sequence.prefix,
      suffix: sequence.suffix,
      initialValue: sequence.initialValue,
      isUsed: false,
    };
  }
  return {
    sequenceName: "",
    sequenceDescription: "",
    isBlocking: false,
    type: "value-list",
    files: undefined,
    isUsed: false,
    behavior: undefined,
    behaviorModal: false,
    behaviorModalMessage: undefined,
  };
};

const mapSequenceBodyToSequenceRow = (sequence: SequenceBody): RowSequence => {
  if (sequence.type === "value-list") {
    return {
      name: sequence.sequenceName.trim(),
      description: sequence.sequenceDescription.trim(),
      isBlocking: sequence.isBlocking,
      type: "value-list",
    };
  } else if (sequence.type === "autogenerated") {
    return {
      name: sequence.sequenceName.trim(),
      description: sequence.sequenceDescription.trim(),
      isBlocking: sequence.isBlocking,
      type: "autogenerated",
      stepType: sequence.stepType,
      stepValue: sequence.stepValue ? sequence.stepValue : 0,
      paddingLength: sequence.paddingLength ? sequence.paddingLength : 0,
      paddingValue: sequence.paddingValue ? sequence.paddingValue.trim() : "",
      prefix: sequence.prefix,
      suffix: sequence.suffix,
      initialValue: sequence.initialValue ? sequence.initialValue : 0,
    };
  }
  return {
    name: "",
    description: "",
    isBlocking: false,
    type: "value-list",
  };
};
