import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const GenerationsSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g transform="translate(0)">
        <rect width="50" height="50" rx="25" fill="#cce7ff" />
      </g>
      <path
        d="M55.59,44.83c-.15-.44-.25-.77-.36-1.13h-3a4.63,4.63,0,0,1,.65,2.79,4.77,4.77,0,0,1-1.74,3.29h0a1.569,1.569,0,0,1-.21.17h0a4.67,4.67,0,0,1-2.37.87,4.47,4.47,0,0,1-1,0L47.7,54l1.9-.16L51.14,55l3-1.77-.3-1.92.36-.54.33-.5c.1-.15.21-.32.34-.5h0l1.89-.46.49-3.45Zm-12.24.89a4.75,4.75,0,0,1,8-3H55.1l.68-1.46-2.39-2.53-1.81.72-1.79-.68L49,37l-3.48.29-.56,1.86-1.64,1-1.91-.41c-.94,1.42-1,1.49-1.94,2.9l1.11,1.61c-.1.72-.16,1.16-.27,1.89l-1.5,1.23c.51,1.62.54,1.7,1.06,3.32l1.94.13,1.31,1.39v2l3.157,1.2.1.039.13-.14V50.58A4.75,4.75,0,0,1,43.35,45.72Z"
        transform="translate(-30.78 -29.83)"
        fill="#0779e7"
      />
      <path
        d="M49,37l-3.48.29-.56,1.86-1.64,1-1.91-.41c-.94,1.42-1,1.49-1.94,2.9l1.11,1.61c-.1.72-.16,1.16-.27,1.89l-1.5,1.23c.51,1.62.54,1.7,1.06,3.32l1.94.13,1.31,1.39v2l3.26,1.24.13-.14V50.58a4.721,4.721,0,1,1,4.84-7.86H55.1l.68-1.46-2.39-2.53-1.81.72-1.79-.68L49,37m3.23,6.7a4.63,4.63,0,0,1,.65,2.79,4.77,4.77,0,0,1-1.74,3.29,1.57,1.57,0,0,1-.21.17,4.67,4.67,0,0,1-2.37.87,4.471,4.471,0,0,1-1,0L47.7,54l1.9-.16L51.14,55l3-1.77-.3-1.92.36-.54.33-.5c.1-.15.21-.32.34-.5l1.89-.46.49-3.45-1.66-1.03c-.15-.44-.25-.77-.36-1.13h-3m-2.606-7.755.91,2.038,1.038.394,2.079-.827,3.322,3.517-.907,1.947.136.446c.071.233.14.458.222.711l1.907,1.184-.677,4.767-2.178.53-.054.081-.535.808.345,2.206L51.072,56.2,49.3,54.869l-1.793.151V55.7l-.856.922L42.12,54.9V52.607l-.769-.815-2.226-.149L38.918,51l-.332-1.031c-.237-.737-.356-1.106-.73-2.294l-.2-.645,1.725-1.414c.058-.4.1-.725.158-1.114l-1.274-1.848.376-.564c.411-.616.658-.982.875-1.306.287-.426.534-.793,1.063-1.591l.377-.569,2.187.469.973-.593.646-2.145Zm1.45,7.775h-.123l-.289-.275a3.727,3.727,0,1,0-3.82,6.191l.4.142.427.048a3.487,3.487,0,0,0,.776,0l.034,0a3.669,3.669,0,0,0,1.862-.684l.026-.018a.571.571,0,0,0,.076-.062l.03-.029L50.508,49a3.79,3.79,0,0,0,1.375-2.6,3.607,3.607,0,0,0-.51-2.187Z"
        transform="translate(-30.78 -29.83)"
        fill="#0779e7"
      />
      <path
        d="M65.781,46.592V65.034a2.918,2.918,0,0,1-2.923,2.912H50.413a2.918,2.918,0,0,1-2.923-2.912V46.592a2.918,2.918,0,0,1,2.923-2.912H62.877A2.918,2.918,0,0,1,65.781,46.592Z"
        transform="translate(-29.963 -28.687)"
        fill="#0779e7"
      />
      <path
        d="M62.362,42.7H50.271a3.781,3.781,0,0,0-3.781,3.781V64.443a3.781,3.781,0,0,0,3.781,3.781H62.362a3.781,3.781,0,0,0,3.781-3.781V46.481A3.781,3.781,0,0,0,62.362,42.7ZM65.2,64.443a2.836,2.836,0,0,1-2.836,2.836H50.271a2.836,2.836,0,0,1-2.836-2.836V46.481a2.836,2.836,0,0,1,2.836-2.836H62.362A2.836,2.836,0,0,1,65.2,46.481Z"
        transform="translate(-29.644 -28.354)"
        fill="#0779e7"
      />
      <path
        d="M60.629,56.615h-9.4a.482.482,0,0,1-.473-.473.473.473,0,0,1,.473-.473h9.4a.473.473,0,0,1,0,.945Z"
        transform="translate(-29.878 -29.063)"
        fill="#cce7ff"
      />
      <path
        d="M62.093,50.7h-10.6c-.4-.005-.724-.214-.732-.473s.328-.473.732-.473h10.6c.4,0,.732.212.732.473S62.5,50.7,62.093,50.7Z"
        transform="translate(-29.878 -28.74)"
        fill="#cce7ff"
      />
      <path
        d="M62.046,62.525H51.581c-.431,0-.781-.212-.781-.473s.353-.468.781-.473H62.046c.428.005.773.214.781.473S62.477,62.525,62.046,62.525Z"
        transform="translate(-29.88 -29.386)"
        fill="#cce7ff"
      />
    </svg>
  );
};
