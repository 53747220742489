import "./style.css";

import { FC } from "react";

type Props = {
  score: number;
};

const getStrengthClass = (score: number): string => {
  if (score === 0) {
    return "";
  }
  if (score >= 26 && score < 45) {
    return "not-enough";
  } else if (score >= 45 && score < 64) {
    return "more-or-less";
  } else if (score >= 65) {
    return "acceptable";
  }
  return "weak";
};

export const PasswordMetric: FC<Props> = (props) => {
  const strengthPasswordClass = getStrengthClass(props.score);

  return (
    <div className="strength-meter">
      <div className={"strength-meter-fill " + strengthPasswordClass}></div>
    </div>
  );
};
