import type { DocumentCollectionIcon } from "@prodoctivity/shared/src/index-types";
import {
  BankIconSvg,
  BarChartIconSvg,
  CalculatorIconSvg,
  CoinsStackIconSvg,
  ContractIconSvg,
  CreditCardIconSvg,
  DocSvgIcon,
  DocumentSignatureIconSvg,
  FinancialDocumentIconSvg,
  FolderSvgIcon,
  GavelIconSvg,
  HandcuffsIconSvg,
  LawBadgeIconSvg,
  LawBookIconSvg,
  LegalBriefcaseIconSvg,
  LegalScaleIconSvg,
  LoanIconSvg,
  PiggyBankIconSvg,
  PrisonCellIconSvg,
  SafeIconSvg,
  StockMarketIconSvg,
  WalletIconSvg,
  WitnessStandIconSvg,
} from "../../svg/DocumentCollection";

export const RenderDocumentCollectionIcon = ({
  iconKey,
  color,
  width,
  height,
}: {
  iconKey: DocumentCollectionIcon;
  color?: string;
  width?: number;
  height?: number;
}) => {
  switch (iconKey) {
    //#Financial Inductry
    case "bank":
      return <BankIconSvg color={color} width={width} height={height} />;
    case "calculator":
      return <CalculatorIconSvg color={color} width={width} height={height} />;
    case "bar-chart":
      return <BarChartIconSvg color={color} width={width} height={height} />;
    case "safe":
      return <SafeIconSvg color={color} width={width} height={height} />;
    case "credit-card":
      return <CreditCardIconSvg color={color} width={width} height={height} />;
    case "piggy-bank":
      return <PiggyBankIconSvg color={color} width={width} height={height} />;
    case "coins-stack":
      return <CoinsStackIconSvg color={color} width={width} height={height} />;
    case "financial-document":
      return <FinancialDocumentIconSvg color={color} width={width} height={height} />;
    case "stock-market":
      return <StockMarketIconSvg color={color} width={width} height={height} />;
    case "wallet":
      return <WalletIconSvg color={color} width={width} height={height} />;
    case "loan":
      return <LoanIconSvg color={color} width={width} height={height} />;
    //Law Firms
    case "gavel":
      return <GavelIconSvg color={color} width={width} height={height} />;
    case "legal-scale":
      return <LegalScaleIconSvg color={color} width={width} height={height} />;
    case "contract":
      return <ContractIconSvg color={color} width={width} height={height} />;
    case "law-book":
      return <LawBookIconSvg color={color} width={width} height={height} />;
    case "handcuffs":
      return <HandcuffsIconSvg color={color} width={width} height={height} />;
    case "law-badge":
      return <LawBadgeIconSvg color={color} width={width} height={height} />;
    case "legal-briefcase":
      return <LegalBriefcaseIconSvg color={color} width={width} height={height} />;
    case "witness-stand":
      return <WitnessStandIconSvg color={color} width={width} height={height} />;
    case "prison-cell":
      return <PrisonCellIconSvg color={color} width={width} height={height} />;
    case "document-signature":
      return <DocumentSignatureIconSvg color={color} width={width} height={height} />;
    //General
    case "document":
      return <DocSvgIcon color={color} width={width} height={height} />;
    case "folder":
    case "default":
    default:
      return <FolderSvgIcon color={color} width={width} height={height} />;
  }
};
