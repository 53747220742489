import { FunctionComponent, useCallback } from "react";

import { Box } from "../Box";
import { Icon } from "../Icon";
import { Text } from "../Text";
import type { StringTemplatePart, VariablePart } from "@prodoctivity/shared/src/index-types";
import { useColors } from "../ColorSchemeProvider";

type StringTemplateVariablePartProps = {
  part: VariablePart;
  lineIndex: number;
  partIndex: number;
  getFieldLabel(fldName: string): string;
  remove(): void;
  hoverPart(lineIndex: number, partIndex: number): void;
  onClick(lineIndex: number, partIndex: number, newPart: StringTemplatePart | undefined): void;
};
export const StringTemplateVariablePart: FunctionComponent<StringTemplateVariablePartProps> = ({
  part,
  lineIndex,
  partIndex,
  getFieldLabel,
  remove,
  hoverPart,
  onClick,
}) => {
  const { colors } = useColors();
  const onHoveringMe = useCallback(() => {
    hoverPart(lineIndex, partIndex);
  }, [hoverPart, lineIndex, partIndex]);

  const handleOnClick = useCallback(() => {
    onClick(lineIndex, partIndex, undefined);
  }, [onClick, lineIndex, partIndex]);
  return (
    <Box
      paddingX={2}
      paddingY={1}
      color={colors.primary100}
      display="flex"
      direction="row"
      alignItems="center"
      width={"auto"}
      borderStyle="lg"
      borderRadius={6}
      borderColor={colors.primary}
      wrap={false}
      onMouseOver={onHoveringMe}
    >
      <Box onClickCapture={handleOnClick}>
        <Text inline={true} size="200" color={colors.primary}>
          {getFieldLabel(part.name)}
        </Text>
      </Box>
      <Box margin={1} />

      <Icon
        size={3}
        accessibilityLabel={`Remove "${getFieldLabel(part.name)}" variable`}
        icon="close"
        color={colors.primary700}
        onClick={remove}
      />
    </Box>
  );
};
