import { Box, OverlayPanel, TapArea, dropDownZIndex, useColors } from "@prodoctivity/design-system";
import type {
  DistributionConfiguration,
  DistributionImplementationDefinition,
  PaginatedQueryResponsePart,
} from "@prodoctivity/types";

import { DistributionListSection } from "./DistributionListSection";
import { ExpandIconSvg } from "../../svg/Tasks/ExpandIconSvg";
import { useState } from "react";

interface Props {
  baseImplementations:
    | {
        implementations: DistributionImplementationDefinition[];
      }
    | undefined;
  userDefinedDistributions:
    | ({
        distributions: DistributionConfiguration[];
      } & PaginatedQueryResponsePart)
    | undefined;
}

export const DistributionOptionsLarge = ({
  userDefinedDistributions,
  baseImplementations,
}: Props) => {
  const { colors } = useColors();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Box
      color={colors.white}
      zIndex={showMobileMenu === false ? undefined : dropDownZIndex}
      marginTop={2}
    >
      <Box display="flex" alignItems="center" paddingX={2} margin={6}>
        <TapArea onTap={() => setShowMobileMenu(true)}>
          <ExpandIconSvg />
        </TapArea>
      </Box>
      {showMobileMenu && (
        <OverlayPanel
          accessibilityLabel={"Distribution options"}
          heading=""
          onDismiss={() => setShowMobileMenu(false)}
          size="lg"
        >
          <DistributionListSection
            baseImplementations={baseImplementations ? baseImplementations.implementations : []}
            userDefinedDistributions={
              userDefinedDistributions ? userDefinedDistributions.distributions : []
            }
          />
        </OverlayPanel>
      )}
    </Box>
  );
};
