import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  fillColor?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const EditFillSvgIcon: FunctionComponent<Props> = ({
  color,
  fillColor,
  customClassName,
  width = 18,
  height = 18,
  onClick,
}) => {
  return (
    <svg
      stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 18 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1807_66722)">
        <path
          d="M14.8411 7.73232L6.63454 15.9367L5.44077 14.7429L5.56465 14.6201H3.9418C3.79246 14.6201 3.64923 14.5608 3.54363 14.4552C3.43803 14.3496 3.3787 14.2064 3.3787 14.057V12.4342L3.25595 12.5569C3.09023 12.724 2.96837 12.9294 2.90119 13.155L2.09146 15.9085L4.84276 15.0988C5.0648 15.0233 5.26853 14.9021 5.44077 14.7429L6.63454 15.9367C6.26832 16.3036 5.81554 16.5724 5.31801 16.7182L1.08351 17.9638C0.935726 18.0098 0.777682 18.0112 0.629083 17.968C0.480484 17.9247 0.347905 17.8387 0.247875 17.7206C0.1392 17.6204 0.0614207 17.4913 0.0237077 17.3485C-0.0140054 17.2056 -0.010079 17.0549 0.0350241 16.9142L1.2806 12.6808C1.42673 12.1837 1.69505 11.7311 2.06105 11.3643L10.2654 3.15884L14.8411 7.73232ZM17.3435 2.04728C17.553 2.25647 17.7191 2.50488 17.8324 2.77832C17.9458 3.05175 18.0041 3.34485 18.0041 3.64085C18.0041 3.93685 17.9458 4.22994 17.8324 4.50338C17.7191 4.77682 17.553 5.02523 17.3435 5.23442L15.6362 6.93722L11.0616 2.36262L12.7644 0.655302C12.9736 0.445713 13.2221 0.279435 13.4957 0.165986C13.7692 0.0525358 14.0624 -0.00585938 14.3586 -0.00585938C14.6547 -0.00585938 14.9479 0.0525358 15.2215 0.165986C15.495 0.279435 15.7435 0.445713 15.9527 0.655302L17.3435 2.04728Z"
          fill={fillColor ? calculateColor(fillColor) : "#00185E"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1807_66722">
          <rect width="18" height="17.9977" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
