import type {
  EnvironmentSettings,
  HttpGetEnvironmentSettingsRequest,
  HttpGetEnvironmentSettingsResponse,
} from "@prodoctivity/types";
import { FunctionComponent, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Settings, SettingsContext } from "../context";
import { httpRequest, stableStringify } from "@prodoctivity/shared";

import { baseUrl } from "../config";
import { useQuery } from "@tanstack/react-query";

async function getEnvironmentSettings() {
  try {
    const { data } = await httpRequest<
      HttpGetEnvironmentSettingsRequest,
      HttpGetEnvironmentSettingsResponse
    >(baseUrl, {
      method: "GET",
      multiParts: {},
      queryParameters: {},
      route: "/environment",
      routeParams: {},
      payload: {},
      headers: {},
    });

    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

type SettingsProviderProps = PropsWithChildren;

export const SettingsProvider: FunctionComponent<SettingsProviderProps> = ({ children }) => {
  const [data, setData] = useState<
    | {
        settings: EnvironmentSettings;
      }
    | undefined
  >(undefined);

  const { data: queryData } = useQuery(["environment"], getEnvironmentSettings, {
    staleTime: 30 * 60 * 1000,
    // cacheTime: 30 * 60 * 1000,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,

    networkMode: "offlineFirst",
  });

  useEffect(() => {
    if (queryData && (!data || stableStringify(queryData) !== stableStringify(data))) {
      setData(queryData);
    }
  }, [data, queryData]);

  const wsGatewayApiURL = useMemo(() => {
    return data
      ? (data.settings.wsGatewayApiURL || "").replace("{host}", window.location.host)
      : undefined;
  }, [data]);

  const settings: Settings = useMemo(() => {
    return {
      wsGatewayApiURL,
      disableSignUp: data ? data.settings.disableSignUp : true,
      environmentIsLoading: !data,
      googleClientId: data ? data.settings.googleClientId : "",
      textControlUrl: data ? data.settings.textControlUrl : undefined,
      fluencyAuthorDownloadLink: data ? data.settings.fluencyAuthor.downloadLink : "",
      configuratorVersion: data ? data.settings.fluencyAuthor.configuratorVersion : "",
      configuratorDate: data
        ? new Date(data.settings.fluencyAuthor.configuratorDate).toLocaleDateString()
        : "",
      dynamsoftLicense: data ? data.settings?.dynamsoftLicense : "",
      contactInfo: data ? data.settings?.contactInfo : { FBPage: "", XPage: "", IGPage: "" },
      sizeCap: data ? data.settings?.sizeCap : "70",
    };
  }, [wsGatewayApiURL, data]);

  if (!data) {
    return <></>;
  }
  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
};
