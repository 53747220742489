import {
  Box,
  Divider,
  SearchField,
  Skeleton,
  Table,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { UserManagementListRow } from "./UserManagementListRow";
import { useUserManagementList } from "./hooks";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { noop } from "@prodoctivity/prodoctivity-form-v5";
import { organizationLinkTemplates } from "../../../link-templates";

const UserManagementListPage: FunctionComponent = () => {
  const {
    breadCrumbEntries,
    users,
    currentPage,
    organizationNavigate,
    nextPage,
    previousPage,
    pageLength: rowsPerPage,
    setPageLength,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    pageLengthOptions,
    resources,
    refetch,
    thisUsername,
    setFilter,
    totalRowCount,
    isLoading,
  } = useUserManagementList();
  const { breakpoint } = useDesignBreakpoint();
  const { colors } = useColors();

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" padding={2}>
        <Box padding={2}>
          <Box flex="grow" display="flex" direction="column">
            <SearchComponentWrapper
              componentHeaderText={resources.users}
              refetchFunction={refetch}
              refreshButtonLabel={resources.refresh}
              searchFieldId="user-search"
              searchFieldLabel={resources.search}
              setFilter={noop}
              addButtonLabel={resources.add}
              addButtonText={resources.add}
              buttonAction={() => organizationNavigate(organizationLinkTemplates.addUser())}
              omitSearch={true}
            />

            <Box display="flex" flex="shrink" direction="column">
              <Pagination<typeof rowsPerPage>
                id="user_management_list_pagination_top"
                rowsLabel={`${resources.users}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={users?.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
                totalRowCount={totalRowCount}
                extraComponent={
                  <SearchField
                    marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                    marginEnd={2}
                    accessibilityLabel={resources.search}
                    id="users_filter"
                    onChange={({ value }) => {
                      setFilter(value);
                    }}
                  />
                }
              />
              <Divider direction="horizontal" />
            </Box>

            <Box margin={3} />
          </Box>
          {isLoading ? (
            <Skeleton height={200} width="350" show={isLoading} />
          ) : (
            <Table accessibilityLabel={resources.users}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Text weight="bold" size="300">
                      {resources.name}
                    </Text>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Text weight="bold" size="300">
                      {resources.email}
                    </Text>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Text weight="bold" size="300">
                      {resources.position}
                    </Text>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Text weight="bold" size="300">
                      {resources.active}
                    </Text>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Text weight="bold" size="300">
                      {resources.actions}
                    </Text>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {users && users.length > 0 ? (
                  (users || []).map((user) => (
                    <UserManagementListRow
                      key={user.id}
                      user={user}
                      disabled={
                        thisUsername === user.id ||
                        !user.organizationInviteToken ||
                        user.hasDeactivationProtection
                      }
                      refetchUserList={refetch}
                    />
                  ))
                ) : (
                  <Table.Row key="no-users">
                    <Table.Cell colSpan={5}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral200}
                      >
                        <Text size="300">{resources.noResultsFound}</Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          )}
          <Divider />
          <Box display="flex">
            <Box display="flex" flex="grow" />

            <Skeleton show={isLoading}>
              <Box display="flex" flex="shrink">
                <Pagination<typeof rowsPerPage>
                  id="user_management_list_pagination_bottom"
                  rowsLabel={`${resources.users}:`}
                  currentPage={currentPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  pageLength={users?.length || 0}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setPageLength}
                  isNextButtonDisabled={isNextButtonDisabled}
                  isPreviousButtonDisabled={isPreviousButtonDisabled}
                  pageLengthOptions={pageLengthOptions}
                  totalRowCount={totalRowCount}
                />
              </Box>
            </Skeleton>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};
export default UserManagementListPage;
