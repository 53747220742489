import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const ListOutlineIconSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 28,
  height = 29,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 28 29`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4.66667 9.83333C5.311 9.83333 5.83333 9.311 5.83333 8.66667C5.83333 8.02233 5.311 7.5 4.66667 7.5C4.02233 7.5 3.5 8.02233 3.5 8.66667C3.5 9.311 4.02233 9.83333 4.66667 9.83333Z"
        fill={calculateColor(color)}
      />
      <path
        d="M4.66667 15.6663C5.311 15.6663 5.83333 15.144 5.83333 14.4997C5.83333 13.8553 5.311 13.333 4.66667 13.333C4.02233 13.333 3.5 13.8553 3.5 14.4997C3.5 15.144 4.02233 15.6663 4.66667 15.6663Z"
        fill={calculateColor(color)}
      />
      <path
        d="M4.66667 21.5003C5.311 21.5003 5.83333 20.978 5.83333 20.3337C5.83333 19.6893 5.311 19.167 4.66667 19.167C4.02233 19.167 3.5 19.6893 3.5 20.3337C3.5 20.978 4.02233 21.5003 4.66667 21.5003Z"
        fill={calculateColor(color)}
      />
      <path
        d="M23.4027 13.333H9.26268C8.65701 13.333 8.16602 13.824 8.16602 14.4297V14.5697C8.16602 15.1753 8.65701 15.6663 9.26268 15.6663H23.4027C24.0084 15.6663 24.4993 15.1753 24.4993 14.5697V14.4297C24.4993 13.824 24.0084 13.333 23.4027 13.333Z"
        fill={calculateColor(color)}
      />
      <path
        d="M23.4027 19.167H9.26268C8.65701 19.167 8.16602 19.658 8.16602 20.2637V20.4037C8.16602 21.0093 8.65701 21.5003 9.26268 21.5003H23.4027C24.0084 21.5003 24.4993 21.0093 24.4993 20.4037V20.2637C24.4993 19.658 24.0084 19.167 23.4027 19.167Z"
        fill={calculateColor(color)}
      />
      <path
        d="M23.4027 7.5H9.26268C8.65701 7.5 8.16602 7.99099 8.16602 8.59667V8.73667C8.16602 9.34234 8.65701 9.83333 9.26268 9.83333H23.4027C24.0084 9.83333 24.4993 9.34234 24.4993 8.73667V8.59667C24.4993 7.99099 24.0084 7.5 23.4027 7.5Z"
        fill={calculateColor(color)}
      />
    </svg>
  );
};
