import {
  Box,
  Divider,
  Layer,
  Popover,
  Skeleton,
  Table,
  Text,
  popupZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";

import { BoxWithRef } from "@prodoctivity/design-system/components/Box";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { GenerationMenuSearch } from "../../components/Generations/GenerationMenuSearch";
import { Page } from "../../components/Layout/Page";
import { Pagination } from "../../components/Layout/Pagination";
import { OrganizationNavLink } from "../../components/OrganizationLink";
import { PublicProfile } from "../../components/Profile/Public/PublicProfile";
import { StatusTableBadge } from "../../components/Status/StatusTableBadge";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { GenerationSearch } from "../../interfaces/generations/GenerationSearch";
import { organizationLinkTemplates } from "../../link-templates";
import { EyeSvg } from "../../svg/EyeSvg";
import { XIconSvg } from "../../svg/XIconSvg";

const pageLengthOptions: Array<GenerationSearch["rowsPerPage"]> = ["15", "50", "100"];

export const GenerationListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();
  const { getGenerations } = useServices();

  const [search, setSearch] = useState<GenerationSearch>({
    rowsPerPage: "15",
    pageNumber: 0,
    sort: "desc",
  });

  const setRowsPerPage = (rpp: GenerationSearch["rowsPerPage"]) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        rowsPerPage: rpp,
        pageNumber: 0,
      };
    });
  };

  const onChangeSearch = useCallback((newState: GenerationSearch) => {
    setSearch(newState);
  }, []);

  const onSortChange = useCallback(
    (newState: "asc" | "desc") => {
      const newEntry = newState;
      setSearch({ ...search, sort: newEntry, pageNumber: 0 });
    },
    [search]
  );

  const onRemoveDates = useCallback(() => {
    setSearch({ ...search, fromDate: undefined, toDate: undefined });
  }, [search]);

  const getGenerationEntries = useCallback(() => {
    return getGenerations(search);
  }, [getGenerations, search]);

  const { data, isLoading } = useOrganizationQuery(
    `generations/${JSON.stringify(search)}`,
    getGenerationEntries,
    {
      staleTime: 0,
    }
  );

  const entries = data ? data.generations : [];

  const isNextButtonDisabled = !data || data.requestedPageLength !== data.pageLength;
  const isPreviousButtonDisabled = !data || data.pageNumber < 1;

  const onLoadMore = useCallback(() => {
    setSearch({ ...search, pageNumber: search.pageNumber + 1 });
  }, [search]);

  const onLoadBack = useCallback(() => {
    setSearch({ ...search, pageNumber: search.pageNumber - 1 });
  }, [search]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "text", name: resources.generations },
    ];
  }, [resources.generations, resources.home]);

  const { breakpoint } = useDesignBreakpoint();
  return (
    <Page
      breadCrumbEntries={breadCrumbEntries}
      breadCrumbExtraComponent={
        <BreadCrumbExtraComponent sortDirection={search.sort} onSortChange={onSortChange} />
      }
      left={
        breakpoint !== "small" && breakpoint !== "medium" ? (
          <GenerationMenuSearch key={"search"} onChangeSearch={onChangeSearch} search={search} />
        ) : null
      }
    >
      <Box
        marginBottom={10}
        display={breakpoint === "small" || breakpoint === "medium" ? "flex" : undefined}
        direction={breakpoint === "small" || breakpoint === "medium" ? "column" : "row"}
      >
        {(breakpoint === "small" || breakpoint === "medium") && (
          <GenerationMenuSearch key={"search"} onChangeSearch={onChangeSearch} search={search} />
        )}
        <Box marginStart={12} marginTop={5} display="flex" direction="row">
          {!search.fromDate && !search.toDate ? null : (
            <Box padding={2}>
              <Text>{resources.filters}: </Text>
            </Box>
          )}

          {!search.fromDate && !search.toDate ? null : (
            <Box
              direction="row"
              marginEnd={8}
              padding={2}
              color={colors.white}
              dangerouslySetInlineStyle={{
                __style: {
                  border: "1px solid #C7C7C8",
                },
              }}
            >
              <Box display="flex" direction="row">
                <Box marginEnd={2}>
                  <Text color={colors.subtle} size="200">
                    {!search.fromDate
                      ? ""
                      : moment(search.fromDate).format(resources._dateFormatShort)}{" "}
                    -{" "}
                    {!search.toDate ? "" : moment(search.toDate).format(resources._dateFormatShort)}
                  </Text>
                </Box>
                <Box
                  onClickCapture={() => onRemoveDates()}
                  dangerouslySetInlineStyle={{
                    __style: {
                      cursor: "pointer",
                    },
                  }}
                >
                  <XIconSvg></XIconSvg>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box>
          <Skeleton show={isLoading} />
        </Box>
        <Box
          marginStart={breakpoint === "small" || breakpoint === "medium" ? 6 : 12}
          marginEnd={breakpoint === "small" || breakpoint === "medium" ? 6 : 12}
        >
          <Box color={colors.white} marginTop={2} padding={5} borderRadius={4} borderStyle="lg">
            <Box display="flex" flex="grow">
              <Box display="flex" flex="grow" />
              <Box display="flex" flex="shrink">
                <Pagination<typeof search.rowsPerPage>
                  id="generation_list_pagination_top"
                  rowsLabel={`${resources.generations}:`}
                  currentPage={search.pageNumber}
                  nextPage={onLoadMore}
                  previousPage={onLoadBack}
                  pageLength={entries?.length || 0}
                  rowsPerPage={search.rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  isNextButtonDisabled={isNextButtonDisabled}
                  isPreviousButtonDisabled={isPreviousButtonDisabled}
                  pageLengthOptions={pageLengthOptions}
                />
              </Box>
            </Box>

            {breakpoint === "small" || breakpoint === "medium" ? (
              (entries || []).map((tg, index) => (
                <DocumentGenerationsCard
                  key={`${index}_${tg.createdAt}`}
                  userName={tg.createdBy}
                  onlyImage={[true, false]}
                  generationToken={tg.generationToken}
                  status={tg.status}
                  createdAt={tg.createdAt}
                />
              ))
            ) : (
              <Table accessibilityLabel="Basic Table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text weight="bold" size="300">
                        {resources.name}
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold" size="300">
                        {resources.status}
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell> </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {(entries || []).map((tg) => (
                    <Table.Row key={tg.generationToken}>
                      <Table.Cell>
                        <Box display="flex" gap={2}>
                          <Box>
                            <PublicProfile username={tg.createdBy} onlyImage={true}></PublicProfile>
                          </Box>

                          <Box>
                            <PublicProfile
                              username={tg.createdBy}
                              size={"300"}
                              onlyImage={false}
                            ></PublicProfile>
                            <OrganizationNavLink to={`/generations/${tg.generationToken}`}>
                              <Text size="200">{tg.generationToken}</Text>
                            </OrganizationNavLink>
                            <Text size="100">{moment(tg.createdAt).fromNow()}</Text>
                          </Box>
                        </Box>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>
                          <StatusTableBadge status={tg.status} />
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Box marginStart={5}>
                          <OrganizationNavLink to={`/generations/${tg.generationToken}`}>
                            <EyeSvg />
                          </OrganizationNavLink>
                        </Box>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}

            <Divider />
            <Box display="flex" flex="grow">
              <Box display="flex" flex="grow" />
              <Box display="flex" flex="shrink">
                <Pagination<typeof search.rowsPerPage>
                  id="generation_list_pagination_bottom"
                  rowsLabel={`${resources.generations}:`}
                  currentPage={search.pageNumber}
                  nextPage={onLoadMore}
                  previousPage={onLoadBack}
                  pageLength={entries?.length || 0}
                  rowsPerPage={search.rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  isNextButtonDisabled={isNextButtonDisabled}
                  isPreviousButtonDisabled={isPreviousButtonDisabled}
                  pageLengthOptions={pageLengthOptions}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

type BreadCrumbExtraComponentProps = {
  sortDirection: "desc" | "asc" | undefined;
  onSortChange: (newState: "asc" | "desc") => void;
};

const BreadCrumbExtraComponent: FunctionComponent<BreadCrumbExtraComponentProps> = ({
  sortDirection,
  onSortChange,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const onSortClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Box direction="row">
      <BoxWithRef
        ref={anchorRef}
        onClickCapture={onSortClick}
        aria-label="My Organization"
        aria-roledescription="button"
        dangerouslySetInlineStyle={{
          __style: {
            cursor: "pointer",
          },
        }}
      >
        <Text>
          {resources.sortBy} {sortDirection === "desc" ? resources.mostRecent : resources.oldest}
        </Text>

        {open && (
          <Layer zIndex={popupZIndex}>
            <Popover
              anchor={anchorRef.current}
              onDismiss={() => setOpen(false)}
              idealDirection="down"
              positionRelativeToAnchor={true}
              size="xl"
              shouldFocus={true}
              color="white"
              role="menu"
            >
              <Box color={colors.white}>
                <Box margin={6}>
                  <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                    <Box width={266}>
                      <Box
                        onClickCapture={() => onSortChange("desc")}
                        dangerouslySetInlineStyle={{
                          __style: {
                            cursor: "pointer",
                          },
                        }}
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Box>
                          <Text
                            customClassName="text-bold-on-hover text-subtle-on-hover"
                            color={colors.subtle}
                          >
                            {resources.mostRecent}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        marginTop={5}
                        width="100%"
                        height={1}
                        color={colors.neutral500}
                        marginBottom={3}
                      />
                      <Box
                        onClickCapture={() => onSortChange("asc")}
                        dangerouslySetInlineStyle={{
                          __style: {
                            cursor: "pointer",
                          },
                        }}
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Box display="flex" direction="column">
                          <Text
                            customClassName="text-bold-on-hover text-subtle-on-hover"
                            color={colors.subtle}
                          >
                            {resources.oldest}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Popover>
          </Layer>
        )}
      </BoxWithRef>
    </Box>
  );
};

export default GenerationListPage;

interface DocumentGenerationCardProps {
  userName: string;
  onlyImage: boolean[];
  generationToken: string;
  status: "completed" | "error" | "pending" | "in-progress";
  createdAt: number;
}
const DocumentGenerationsCard: React.FC<DocumentGenerationCardProps> = ({
  userName,
  onlyImage,
  generationToken,
  status,
  createdAt,
}) => {
  const { resources, moment } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();

  return (
    <>
      <Box display="flex" direction="column" gap={2} marginBottom={1} padding={3} borderStyle="sm">
        <Box display="flex" direction="column" gap={1}>
          <Text weight="bold" size="300">
            {resources.name}
          </Text>
          <Box display="flex" direction="row" gap={2} marginTop={2}>
            <Box>
              <PublicProfile username={userName} onlyImage={onlyImage[0]}></PublicProfile>
            </Box>
            <Box>
              <PublicProfile
                username={userName}
                size={"300"}
                onlyImage={onlyImage[1]}
              ></PublicProfile>
              <OrganizationNavLink to={`/generations/${generationToken}`}>
                <Box display="flex" width={breakpoint === "small" ? "60%" : "100%"}>
                  <Text overflow="ellipsis" size="200">
                    {generationToken}
                  </Text>
                </Box>
              </OrganizationNavLink>

              <Text size="100">{moment(createdAt).fromNow()}</Text>
            </Box>
          </Box>
        </Box>
        <Box>
          <Text weight="bold" size="300">
            {resources.status}
          </Text>
          <Box display="flex" direction="row" justifyContent="between">
            <Text>
              <StatusTableBadge status={status} />
            </Text>
            <Box padding={2} display="flex" direction="row">
              <OrganizationNavLink to={`/generations/${generationToken}`}>
                <EyeSvg />
              </OrganizationNavLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
