import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const UserManagementMenuIcon: FunctionComponent<Props> = ({ color }) => {
  const { colors } = useColors();
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34 23.0332C34 23.7332 32.6 24.1998 30.9667 24.4332C29.9167 22.4498 27.8167 20.9332 25.3667 19.8832C25.6 19.5332 25.8333 19.2998 26.0667 18.9498H27C30.6167 18.8332 34 21.0498 34 23.0332ZM13.9333 18.8332H13C9.38333 18.8332 6 21.0498 6 23.0332C6 23.7332 7.4 24.1998 9.03333 24.4332C10.0833 22.4498 12.1833 20.9332 14.6333 19.8832L13.9333 18.8332ZM20 19.9998C22.5667 19.9998 24.6667 17.8998 24.6667 15.3332C24.6667 12.7665 22.5667 10.6665 20 10.6665C17.4333 10.6665 15.3333 12.7665 15.3333 15.3332C15.3333 17.8998 17.4333 19.9998 20 19.9998ZM20 21.1665C15.2167 21.1665 10.6667 24.1998 10.6667 26.9998C10.6667 29.3332 20 29.3332 20 29.3332C20 29.3332 29.3333 29.3332 29.3333 26.9998C29.3333 24.1998 24.7833 21.1665 20 21.1665ZM26.65 17.6665H27C28.9833 17.6665 30.5 16.1498 30.5 14.1665C30.5 12.1832 28.9833 10.6665 27 10.6665C26.4167 10.6665 25.95 10.7832 25.4833 11.0165C26.4167 12.1832 27 13.6998 27 15.3332C27 16.1498 26.8833 16.9665 26.65 17.6665ZM13 17.6665H13.35C13.1167 16.9665 13 16.1498 13 15.3332C13 13.6998 13.5833 12.1832 14.5167 11.0165C14.05 10.7832 13.5833 10.6665 13 10.6665C11.0167 10.6665 9.5 12.1832 9.5 14.1665C9.5 16.1498 11.0167 17.6665 13 17.6665Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
    </svg>
  );
};
