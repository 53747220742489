import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const HamburgerIcon: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 40,
  height = 40,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M12 14.25C12 13.5859 12.5469 13 13.25 13H28.25C28.9141 13 29.5 13.5859 29.5 14.25C29.5 14.9531 28.9141 15.5 28.25 15.5H13.25C12.5469 15.5 12 14.9531 12 14.25ZM12 20.5C12 19.8359 12.5469 19.25 13.25 19.25H28.25C28.9141 19.25 29.5 19.8359 29.5 20.5C29.5 21.2031 28.9141 21.75 28.25 21.75H13.25C12.5469 21.75 12 21.2031 12 20.5ZM28.25 28H13.25C12.5469 28 12 27.4531 12 26.75C12 26.0859 12.5469 25.5 13.25 25.5H28.25C28.9141 25.5 29.5 26.0859 29.5 26.75C29.5 27.4531 28.9141 28 28.25 28Z"
        fill={color ? calculateColor(color) : "#91979E"}
        fillOpacity="0.9"
      />
    </svg>
  );
};
