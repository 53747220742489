import {
  Box,
  BoxWithRef,
  ChevronSvgIcon,
  Layer,
  Popover,
  SegmentedControl,
  Spinner,
  TapArea,
  Text,
  popupZIndex,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { DocumentSearchFilter, organizationLinkTemplates } from "../../link-templates";
import { BreadCrumb, BreadCrumbEntry } from "../BreadCrumb";

import { GridLargeIcon } from "../../svg/GridLargeIcon";
import { GridMediumIcon } from "../../svg/GridMediumIcon";
import { GridSmallIcon } from "../../svg/GridSmallIcon";
import { ListOutlineIconSvg } from "../../svg/ListOutlineIconSvg";
import { MobileResultsPageSortIcon } from "../../svg/MobileResultsPageSortIcon";
import { Pagination } from "../Layout/Pagination";
import { ResultsPageRefreshButton } from "../ResultsPageRefreshButton";
import { SmallRefreshButton } from "../SmallRefreshButton";
import { SearchResultsList } from "./SearchResultsList";
// import { UserSvg } from "../../svg/userSvg";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { XIconSvg } from "../../svg/XIconSvg";
import { useOpenSearch } from "../OpenSearch/hooks";
import { useResultOfFilteredFields } from "./hooks";

const documentsDisplaySettingKeyName = "documents_display_setting";

function documentsDisplaySettingInitialState() {
  if (window.localStorage) {
    const raw = window.localStorage.getItem(documentsDisplaySettingKeyName);
    if (raw !== undefined && raw !== null) {
      const val = Number.parseInt(raw, 10);
      if (!Number.isNaN(val) && val >= 0 && val < 4) {
        return val;
      }
    }
  }

  return 0;
}

type Props = {
  filter: DocumentSearchFilter;
  params: URLSearchParams;
};

export const ResultOfFilteredFields: FunctionComponent<Props> = ({ filter, params }: Props) => {
  const { resources, moment } = useAppTranslation();

  const breadcrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "text",
        name: resources.search,
      },
    ];
  }, [resources.home, resources.search]);
  const {
    changeSort,
    sortDirection,
    setPageLength,
    previousPage,
    nextPage,
    pageLengthOptions,
    rowsPerPage,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    document,
    onSortClick,
    resultFiltered,
    setOpen,
    open,
    organizationUsersData,
    cleanInitialDate,
    cleanFinalDate,
    buildSearchUrl,
    queryParameters,
    refetch,
    isLoading,
    isRefetching,
    breakpoint,
    humanFilterName,
  } = useResultOfFilteredFields(params);
  const { colors } = useColors();
  const { buildSearchUrl: buildSearchUrlForSearch } = useOpenSearch();
  const [rawItemIndex, rawSetItemIndex] = useState(documentsDisplaySettingInitialState);

  const itemIndex = useMemo(() => {
    if (breakpoint === "small") {
      return 0;
    } else if (breakpoint === "medium") {
      return 1;
    }
    return rawItemIndex;
  }, [breakpoint, rawItemIndex]);

  const setItemIndex = useCallback((arg: number) => {
    if (window.localStorage) {
      window.localStorage.setItem(documentsDisplaySettingKeyName, `${arg}`);
    }
    rawSetItemIndex(arg);
  }, []);

  const displayDocumentsAsCards = useMemo(() => {
    return itemIndex < 3;
  }, [itemIndex]);

  const displayItemsOptions = useMemo(() => {
    let sizeItems = [];
    if (breakpoint === "medium") {
      sizeItems = [
        <Box paddingY={2} display="flex" alignItems="center">
          <GridSmallIcon color={itemIndex === 0 ? colors.primary : undefined} />
        </Box>,
        <Box paddingY={2} display="flex" alignItems="center">
          <GridMediumIcon color={itemIndex === 1 ? colors.primary : undefined} />
        </Box>,
      ];
    } else {
      sizeItems = [
        <Box paddingY={2} display="flex" alignItems="center">
          <GridSmallIcon
            color={displayDocumentsAsCards && itemIndex === 0 ? colors.primary : undefined}
          />
        </Box>,
        <Box paddingY={2} display="flex" alignItems="center">
          <GridMediumIcon
            color={displayDocumentsAsCards && itemIndex === 1 ? colors.primary : undefined}
          />
        </Box>,
        <Box paddingY={2} display="flex" alignItems="center">
          <GridLargeIcon
            color={displayDocumentsAsCards && itemIndex === 2 ? colors.primary : undefined}
          />
        </Box>,
        <Box paddingY={2} display="flex" alignItems="center">
          <ListOutlineIconSvg color={displayDocumentsAsCards ? undefined : colors.primary} />
        </Box>,
      ];
    }
    return sizeItems;
  }, [breakpoint, colors.primary, displayDocumentsAsCards, itemIndex]);

  const labelOfOperatorUsed = (paramsOperator: string) => {
    const optionsDataTypes = [
      { label: " = " /* resources.equals */, value: "eq" },
      { label: resources.notEquals, value: "neq" },
      { label: resources.contains, value: "contains" },
      { label: resources.endsWith, value: "ends-with" },
      { label: resources.startsWith, value: "starts-with" },
      {
        label: " > ", //  resources.greaterThan,
        value: "gt",
      },
      {
        label: " < ", // resources.lessThan,
        value: "lt",
      },
      {
        label: " >= ", // resources.greaterThanOrEquals,
        value: "gte",
      },
      {
        label: " <= ", // resources.lessThanOrEquals,
        value: "lte",
      },
    ];

    const matchingLabel = optionsDataTypes.filter((op) => op.value.includes(paramsOperator));
    return matchingLabel[0].label;
  };

  const isMobile = breakpoint === "small" || breakpoint === "medium";
  const smallestBreakPoint = breakpoint === "small";

  const toDates = moment(queryParameters.toDate).format("MM/DD/YYYY");
  const fromDates = moment(queryParameters.fromDate).format("MM/DD/YYYY");
  const anchorRef = useRef<HTMLDivElement | null>(null);

  // const filteredUsers =
  //   useOrganization?.users.map((user) => ({
  //     label: `${user.firstName} ${user.lastName}`,
  //     value: user.firstName,
  //   })) ?? [];

  // const checkBoxList: checkListState[] = [
  //   { label: resources.activities.activities, id: "action-log", checked: false },
  //   { label: resources.documents, id: "document", checked: false },
  // ];

  // const GetTypeOfSearch = ({ type }: { type: checkListState }) => {
  //   const checked = queryParameters.entityType.includes(type.id);

  //   return (
  //     <Box
  //       padding={3}
  //       key={type.id}
  //       color={checked ? colors.primaryHover0 : undefined}
  //       hoverColor={colors.primaryHover0}
  //       onClickCapture={() => {
  //         buildSearchUrl({
  //           typeofSearch: {
  //             id: type.id,
  //             checked: !checked,
  //           },
  //         });
  //       }}
  //       dangerouslySetInlineStyle={{
  //         __style: {
  //           cursor: "pointer",
  //         },
  //       }}
  //     >
  //       <Checkbox
  //         label={type.label}
  //         id={type.id}
  //         key={type.id}
  //         checked={checked}
  //         onChange={() => {
  //           console.log("");
  //         }}
  //       />
  //     </Box>
  //   );
  // };
  // const dateShow = false;

  const sortByLabel = useCallback(() => {
    switch (sortDirection) {
      case "asc": {
        return resources.oldest;
      }
      case "desc": {
        return resources.mostRecent;
      }
      default: {
        return resources.withoutSorting;
      }
    }
  }, [resources.mostRecent, resources.oldest, resources.withoutSorting, sortDirection]);

  return (
    <Box width={"100%"} maxWidth={"100vw"} overflow={isMobile ? "scrollY" : undefined}>
      <Box direction="row">
        <Box paddingY={1} height={1} width={1}></Box>
        <BreadCrumb entries={breadcrumbEntries} />
      </Box>
      {/* <Box
        borderStyle="sm"
        borderRadius={4}
        height="100vh"
        padding={3}
        color={colors.white}
      >
        <Box paddingY={5}>
          <Text>{resources.activities.typeOfSearch}</Text>
        </Box>
        <Box display="flex" direction="column" gap={2}>
          <Accordion
            TitleComponent={
              <Box paddingX={2}>
                <Text color={colors.subtle}>{resources.checklist}</Text>
              </Box>
            }
          >
            {checkBoxList.map((item) => (
              <GetTypeOfSearch key={item.id} type={item} />
            ))}
          </Accordion>
          <Box borderStyle="lg" borderRadius={6}></Box>
          {dateShow && (
            <>
              <Text>{resources.activities.dateRange}</Text>
              <Box display="flex" gap={2}>
                <DatePicker
                  id="fromDate"
                  onChange={(e) => {
                    e.value !== null && buildSearchUrl({ newFromDate: e.value });
                  }}
                  placeholder={resources.from}
                  rangeSelector="start"
                />
                <DatePicker
                  id="toDate"
                  onChange={(e) => {
                    e.value !== null && buildSearchUrl({ newToDate: e.value });
                  }}
                  placeholder={resources.to}
                  rangeSelector="end"
                />
              </Box>
              <Box borderStyle="lg" borderRadius={6}></Box>
              <Text>{resources.createdBy}</Text>
              <Box display="flex" alignItems="center" borderStyle="lg" padding={2}>
                <UserSvg />
                <Box width="100%">
                  <ComboBox
                    options={filteredUsers}
                    id={""}
                    label=""
                    onSelect={(e) => buildSearchUrl({ createBy: e.item.label })}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box> */}
      <Box>
        <Box display="flex" alignItems="center" justifyContent="between" direction="column">
          <Box
            display="flex"
            justifyContent="between"
            alignItems="center"
            width="100%"
            color={colors.white}
            paddingX={!isMobile ? 10 : 4}
          >
            <Box display="flex" direction="row" justifyContent="between" alignItems="center">
              {!isMobile ? (
                <Box
                  display="flex"
                  direction="column"
                  marginEnd={2}
                  height={"100%"}
                  position="relative"
                >
                  <Text size="200" weight="bold">
                    {resources.searchResult}
                  </Text>
                </Box>
              ) : null}
              <Box height={"100%"} display="flex" alignItems="center">
                {!isMobile ? (
                  <SmallRefreshButton
                    size={20}
                    accessibilityLabel={resources.refresh}
                    refetch={refetch}
                  />
                ) : (
                  <ResultsPageRefreshButton
                    size={32}
                    accessibilityLabel={resources.refresh}
                    refetch={refetch}
                  />
                )}
              </Box>
            </Box>
            <Box paddingY={1} display="flex" alignItems="center">
              <Pagination
                id="document_list"
                rowsLabel={` `}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                pageLength={document?.length || 0}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
              />
              <Box marginStart={4} marginEnd={-2}>
                <Box display="flex" gap={6} alignItems="center">
                  {resources.sortType}:
                  <BoxWithRef
                    ref={anchorRef}
                    alignItems="center"
                    display="flex"
                    onClickCapture={onSortClick}
                    dangerouslySetInlineStyle={{
                      __style: {
                        cursor: "pointer",
                      },
                    }}
                  >
                    {isMobile ? (
                      <MobileResultsPageSortIcon />
                    ) : (
                      <Box display="flex" gap={2} alignItems="center">
                        <Text size="200" weight="bold">
                          {sortByLabel()}
                        </Text>

                        <ChevronSvgIcon direction="down" />
                      </Box>
                    )}
                  </BoxWithRef>
                </Box>
              </Box>
              {open && (
                <Layer zIndex={popupZIndex}>
                  <Popover
                    anchor={anchorRef.current}
                    onDismiss={() => setOpen(false)}
                    idealDirection="down"
                    positionRelativeToAnchor={true}
                    size="flexible"
                    shouldFocus={true}
                    color="white"
                    role="menu"
                  >
                    <Box color={colors.white}>
                      <Box
                        display="flex"
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Box width={160}>
                          <Box
                            onClickCapture={() => changeSort("Date", "desc")}
                            dangerouslySetInlineStyle={{
                              __style: {
                                cursor: "pointer",
                              },
                            }}
                            color={sortDirection === "desc" ? colors.primaryHover0 : undefined}
                            hoverColor={colors.primaryHover0}
                            direction="row"
                            alignItems="center"
                            justifyContent="start"
                            padding={2}
                          >
                            <Box paddingX={2} marginTop={2} marginBottom={2}>
                              <Text
                                customClassName="text-bold-on-hover text-subtle-on-hover"
                                color={colors.subtle}
                              >
                                {resources.mostRecent}
                              </Text>
                            </Box>
                          </Box>
                          <Box width="100%" height={1} color={colors.neutral500} />
                          <Box
                            onClickCapture={() => changeSort("Date", "asc")}
                            color={sortDirection === "asc" ? colors.primaryHover0 : undefined}
                            hoverColor={colors.primaryHover0}
                            dangerouslySetInlineStyle={{
                              __style: {
                                cursor: "pointer",
                              },
                            }}
                            direction="row"
                            alignItems="center"
                            justifyContent="start"
                            padding={2}
                          >
                            <Box display="flex" direction="column">
                              <Box paddingX={2} marginTop={2} marginBottom={2}>
                                <Text
                                  customClassName="text-bold-on-hover text-subtle-on-hover"
                                  color={colors.subtle}
                                >
                                  {resources.oldest}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Popover>
                </Layer>
              )}
            </Box>
          </Box>
          <Box borderStyle="lg" borderRadius={6} width="100%"></Box>
        </Box>

        <Box padding={isMobile ? 4 : 10} overflow="scrollY" height={"82vh"}>
          <Box display="flex" alignItems="center" justifyContent="between">
            <Box display="flex" gap={5} alignItems="center">
              <Text>{resources.filters}:</Text>
              {/* {queryParameters.entityType.map((item) => {
              const obj = { formattedLabel: "" };

              switch (item) {
                case "document":
                  obj.formattedLabel = resources.document;
                  break;
                case "action-log":
                  obj.formattedLabel = resources.activities.activities;
                  break;
              }
              if (!obj.formattedLabel.endsWith("s")) {
                obj.formattedLabel += "s";
              }
              return (
                <Box
                  key={item}
                  display="flex"
                  alignItems="center"
                  justifyContent="between"
                  borderStyle="sm"
                  color={colors.white}
                  width="10%"
                  height="5vh"
                  padding={2}
                  borderRadius={4}
                  dangerouslySetInlineStyle={{
                    __style: {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Text size="300">{obj.formattedLabel}</Text>
                  <XIconSvg
                    onClick={() => buildSearchUrl({ typeofSearch: { id: item, checked: false } })}
                  />
                </Box>
              );
            })} */}
              {queryParameters.fromDate ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="between"
                  borderStyle="sm"
                  color={colors.white}
                  width="10%"
                  height="5vh"
                  padding={2}
                  borderRadius={4}
                  dangerouslySetInlineStyle={{
                    __style: {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Text size="300">{fromDates}</Text>
                  <XIconSvg onClick={cleanInitialDate} />
                </Box>
              ) : null}
              {queryParameters.toDate ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="between"
                  borderStyle="sm"
                  color={colors.white}
                  width="10%"
                  height="5vh"
                  padding={2}
                  borderRadius={4}
                  dangerouslySetInlineStyle={{
                    __style: {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Text size="300">{toDates}</Text>
                  <XIconSvg onClick={cleanFinalDate} />
                </Box>
              ) : null}

              <Box display="flex" wrap={true}>
                {filter.query !== "" ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    height={"auto"}
                    width={"fit-content"}
                    paddingX={isMobile ? 2 : 4}
                    paddingY={isMobile ? 1 : 2}
                    margin={1}
                    color={colors.white}
                    borderRadius={4}
                  >
                    <Text
                      color={colors.neutral900}
                    >{`${resources.hasTheWord} = ${filter.query}`}</Text>

                    <Box
                      smMarginStart={isMobile ? 1 : 3}
                      onClickCapture={() => buildSearchUrlForSearch(filter, undefined, "query")}
                      dangerouslySetInlineStyle={{
                        __style: {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <XIconSvg />
                    </Box>
                  </Box>
                ) : null}
                {filter.fields.map((field, index) => {
                  const dateValue =
                    field.dataType === "Date"
                      ? moment(parseInt(field.val)).format("DD-MM-YYYY")
                      : undefined;
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      height={"auto"}
                      width={"fit-content"}
                      key={index}
                      paddingX={isMobile ? 2 : 4}
                      paddingY={isMobile ? 1 : 2}
                      margin={1}
                      color={colors.white}
                      borderRadius={4}
                    >
                      <Text color={colors.neutral900}>{`${humanFilterName(
                        field.fld
                      )} ${labelOfOperatorUsed(field.op)} ${
                        dateValue ? dateValue : field.val
                      }`}</Text>

                      <Box
                        smMarginStart={isMobile ? 1 : 3}
                        onClickCapture={() => buildSearchUrlForSearch(filter, index)}
                        dangerouslySetInlineStyle={{
                          __style: {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <XIconSvg />
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box
                onClickCapture={() => {
                  buildSearchUrl({});
                }}
              >
                <TapArea
                  onTap={() =>
                    buildSearchUrlForSearch({
                      documentTypesSelected: filter.documentTypesSelected,
                      fields: [],
                      query: "",
                      searchCriteria: "0",
                      docTypesSelected: "0",
                      pageNumber: currentPage.toString(),
                      rowsPerPage: rowsPerPage.toString(),
                    })
                  }
                >
                  <Text lineClamp={5} color={colors.primary300}>
                    {resources.clearAll}
                    <Box borderStyle="sm" borderRadius={6} color={colors.primary300}></Box>
                  </Text>
                </TapArea>
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              <TapArea>
                {!smallestBreakPoint && (
                  <Box display="flex" gap={4} alignItems="center">
                    <Box width={200}>
                      <SegmentedControl
                        items={displayItemsOptions}
                        selectedItemIndex={itemIndex}
                        onChange={({ activeIndex }) => setItemIndex(activeIndex)}
                        size="sm"
                      ></SegmentedControl>
                    </Box>
                  </Box>
                )}
              </TapArea>
            </Box>
          </Box>
          {isLoading || isRefetching ? (
            <Spinner show={true} accessibilityLabel={resources.loading} />
          ) : (
            <SearchResultsList
              resultFiltered={resultFiltered}
              organizationUsersData={organizationUsersData}
              searchFilter={queryParameters}
              displayDocumentsAsCards={displayDocumentsAsCards}
              breakpoint={breakpoint}
              itemIndex={itemIndex}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
