import { isValueListContextField } from "@prodoctivity/shared";
import type {
  DataElement,
  DictionaryList,
  DictionaryListItem,
} from "@prodoctivity/shared/src/index-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DictionaryListPath } from "./DictionaryListControl";

export function useDictionaryListControl(
  setLists: (n: boolean) => void,
  element: DataElement,
  dictionaryLists: DictionaryList<string>[] | undefined,
  onChange: (dataElement: DataElement) => void,
  setShowPopoverComboBox: (n: boolean) => void,
  setDictionaryListName: (n: string) => void
) {
  const elementOptionInputType =
    element.dataType === "Alphanumeric" &&
    (element.inputType === "Checkbox" ||
      element.inputType === "Radio" ||
      element.inputType === "Dropdown" ||
      element.inputType === "CheckBoxList" ||
      element.inputType === "DropDownList" ||
      element.inputType === "RadioButtonList");

  const [synchronizeLists, setSynchronizeValueLists] = useState<boolean>(false);
  const dictionaryList = (dictionaryLists || []).find(
    (dl) => dl.listSchema.name === element.dictionaryListName
  );
  const getRouteValues = useCallback((items: DictionaryListItem[], paths: string[] | undefined) => {
    const values: {
      value: string;
      label: string;
      parentValue?: string | undefined;
    }[] = [];

    let deepestPath = "";
    if (paths) {
      deepestPath = paths
        .reduce((a, b) => (a.split("/").length > b.split("/").length ? a : b))
        .split("/")
        .slice(1, 3)
        .join("/");
    }

    const pathSet = new Set(deepestPath.split("/"));

    const searchItems = (items: DictionaryListItem[], currentPath = "", parentValue = "") => {
      for (const item of items) {
        const newPath = currentPath ? `${currentPath}/${item.label}` : item.label;
        if (newPath.startsWith(deepestPath) || pathSet.has(item.label)) {
          const newItem = {
            label: item.label,
            value: item.key,
            parentValue: parentValue.length > 0 ? parentValue : undefined,
          };
          values.push(newItem);
        }

        if (item.children && newPath.startsWith(deepestPath)) {
          setSynchronizeValueLists(newPath.startsWith(deepestPath));
          for (const childrenArray of Object.values(item.children)) {
            childrenArray.forEach((child) => {
              if (child.label.startsWith(deepestPath)) {
                const siblingItem = {
                  label: child.label,
                  value: child.key,
                  parentValue: item.label,
                };
                values.push(siblingItem);
              }
            });
          }
        }
        if (item.children) {
          for (const childrenArray of Object.values(item.children)) {
            searchItems(childrenArray, newPath, item.label);
          }
        }
      }
    };

    searchItems(items);
    return values;
  }, []);

  const synchronizeValueLists = useCallback(() => {
    if (!isValueListContextField(element)) {
      return;
    }
    if (dictionaryList?.items) {
      const valueList = getRouteValues(dictionaryList.items, element.dictionaryListPath);
      if (elementOptionInputType && valueList !== element.valueList) {
        onChange({
          ...element,
          valueList: valueList,
        });
        setShowPopoverComboBox(true);
        setLists(false);
      }
    }
  }, [
    dictionaryList,
    element,
    elementOptionInputType,
    getRouteValues,
    onChange,
    setShowPopoverComboBox,
    setLists,
  ]);

  useEffect(() => {
    if (elementOptionInputType && dictionaryList?.items) {
      const valueList = getRouteValues(dictionaryList.items, element.dictionaryListPath) || [];
      const elementValueList = element.valueList || [];
      setSynchronizeValueLists(
        elementValueList !== undefined ? valueList.length !== elementValueList.length : false
      );
      setSynchronizeValueLists(
        !element.dictionaryListPath ? false : valueList.length !== elementValueList.length
      );
    }
  }, [
    dictionaryList,
    dictionaryLists,
    element,
    elementOptionInputType,
    getRouteValues,
    setShowPopoverComboBox,
  ]);

  const updateDictionaryList = useCallback(
    (value: string) => {
      const dictionaryList = (dictionaryLists || []).find((dl) => dl.listSchema.name === value);

      if (!dictionaryList) return;

      setDictionaryListName(value);
      setShowPopoverComboBox(true);
      setLists(false);

      const valueList = (dictionaryList?.items || []).map((item) => {
        return {
          value: item.key,
          label: item.label,
        };
      });

      if (
        element.dataType === "Alphanumeric" &&
        element.inputType !== "Default" &&
        element.inputType !== "TextArea" &&
        element.inputType !== "TextBox"
      ) {
        onChange({
          ...element,
          dataType: "Alphanumeric",
          inputType: element.inputType,
          dictionaryListName: value,
          dictionaryListId: dictionaryList?._id,
          valueList: valueList,
        });
      }
    },
    [dictionaryLists, element, onChange, setDictionaryListName, setLists, setShowPopoverComboBox]
  );

  return {
    synchronizeLists,
    synchronizeValueLists,
    updateDictionaryList,
  };
}

export function useDataListItems(
  element: DataElement,
  dictionaryLists: DictionaryList<string>[] | undefined,
  dictionaryListName: string,
  onChange: (dataElement: DataElement) => void,
  setShowValueLists: (n: boolean) => void
) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [dictionaryPathName, setDictionaryPathName] = useState<Record<number, string>>({});
  const [search, setSearch] = useState("");
  const [showListItems, setShowListItems] = useState(false);
  const [listValue, setListValue] = useState<Record<number, string>>({});
  const [listLabel, setListLabel] = useState<Record<number, string>>({});

  const dictionaryList = (dictionaryLists || []).find(
    (dl) => dl.listSchema.name === dictionaryListName
  );

  const elementOptionInputType =
    element.dataType === "Alphanumeric" &&
    (element.inputType === "Checkbox" ||
      element.inputType === "Radio" ||
      element.inputType === "Dropdown" ||
      element.inputType === "CheckBoxList" ||
      element.inputType === "DropDownList" ||
      element.inputType === "RadioButtonList");

  const findInChildren = useCallback(
    (items: DictionaryListItem[], parentPath = "") => {
      const results: DictionaryListPath[] = [];
      let currentPath: string;
      items.forEach((item) => {
        currentPath = `${parentPath}/${item.label}`;
        const itemLabel = item.label.toLowerCase();
        const searchQuery = search.toLowerCase();
        let found = itemLabel.includes(searchQuery);

        let children: DictionaryListPath[] = [];

        if (item.children) {
          Object.values(item.children).forEach((child) => {
            const childResults = findInChildren(child, currentPath);
            if (childResults.length > 0) {
              children = [...children, ...childResults];
              found = true;
            }
          });
        }

        if (found) {
          results.push({
            key: item.key,
            label: item.label,
            found,
            path: currentPath,
            ...(children.length > 0 && { children }),
          });
        }
      });

      if (results.some((r) => (r.children || []).some((c) => c.children === undefined))) {
        const keys = results.map((i) => i.label);
        for (const i of items.filter((i) => !keys.includes(i.label))) {
          currentPath = parentPath + "/" + i.label;
          results.push({
            key: i.key,
            label: i.label,
            found: true,
            path: currentPath,
          });
        }
      }

      return results;
    },
    [search]
  );

  const itemsDictionary = useMemo(() => {
    if (elementOptionInputType) {
      const found = (dictionaryLists || []).find(
        (itemsDictionary) =>
          itemsDictionary._id === element.dictionaryListId ||
          itemsDictionary.listSchema.name === element.dictionaryListName
      );

      if (found) {
        const item = findInChildren(found.items);
        if (item) return item;
      }
    }
  }, [dictionaryLists, element, elementOptionInputType, findInChildren]);

  const dictionaryItemPaths = useMemo(() => {
    const paths: string[] = [];

    const getPaths = (items: DictionaryListPath[]) => {
      items.forEach((i) => {
        if (i.path) {
          paths.push(i.path);
        }
        if (i.children) {
          getPaths(i.children);
        }
      });
    };

    if (itemsDictionary) {
      getPaths(itemsDictionary);
    }

    if (!isValueListContextField(element)) {
      return;
    }

    if (!element.dictionaryListPath) {
      onChange({
        ...element,
        dictionaryListPath: paths,
      });
    }

    return paths;
  }, [element, itemsDictionary, onChange]);

  const getNullValues = useCallback(
    (items: DictionaryListItem[] | undefined, key: string) => {
      let valueList: {
        value: string;
        label: string;
        parentValue?: string | undefined;
      }[] = [];

      let keyExist = false;

      const mappingSonsElement = (item: DictionaryListItem) => {
        if (item.children && item.children[key]) {
          keyExist = true;
          const newValues = item.children[key].map((e) => ({
            label: e.label,
            value: e.key,
            parentValue: item.label,
          }));
          valueList = [...valueList, ...newValues];
        }
        for (const key in item.children) {
          if (Array.isArray(item.children[key])) {
            item.children[key].forEach((e) => mappingSonsElement(e));
          }
        }
      };
      if (items) {
        items.forEach((item) => mappingSonsElement(item));

        if (!keyExist)
          items.forEach((i) => {
            if (key !== i.key) {
              const newValueList = {
                label: i.label,
                value: i.key,
                parentValue: undefined,
              };
              valueList.push(newValueList);
            }
          });
      }

      if (!isValueListContextField(element)) {
        return;
      }

      onChange({
        ...element,
        listHasLabels: true,
        dictionaryListId: dictionaryList?._id,
        dictionaryListPath: dictionaryItemPaths,
        valueList: valueList,
      });
      setShowValueLists(true);
    },
    [dictionaryItemPaths, dictionaryList?._id, element, onChange, setShowValueLists]
  );

  const updateElementWithValueList = useCallback(() => {
    const valueList =
      itemsDictionary?.flatMap((i) => {
        return getValues(i);
      }) || [];

    function getValues(item: DictionaryListPath) {
      const values: {
        value: string;
        label: string;
        parentValue?: string | undefined;
      }[] = [];
      if (item.children) {
        const childrenArrays = Object.values(item.children);
        for (const childrenArray of childrenArrays) {
          if (childrenArray) {
            const childItem = {
              label: childrenArray.label,
              value: childrenArray.key,
              parentValue: item.key,
            };
            values.push(childItem);
            if (childrenArray.children) {
              values.push(...getValues(childrenArray));
            }
          }
        }
      } else {
        const childItem = {
          label: item.label,
          value: item.key,
        };
        values.push(childItem);
      }
      return values;
    }
    if (elementOptionInputType) {
      onChange({
        ...element,
        listHasLabels: true,
        dictionaryListId: dictionaryList?._id,
        dictionaryListPath: dictionaryItemPaths,
        valueList: valueList,
      });
    }

    setShowValueLists(true);
  }, [
    dictionaryItemPaths,
    dictionaryList?._id,
    element,
    elementOptionInputType,
    itemsDictionary,
    onChange,
    setShowValueLists,
  ]);

  const generateUniqueValues = useCallback(
    (k: { value: string; label: string; parentValue?: string | undefined }) => {
      if (!elementOptionInputType) {
        return [];
      }
      return [...element.valueList.flatMap((f) => f.parentValue)].reduce(
        (arr: { value: string; label: string }[], parentValue) => {
          if (!arr.find((i) => i.value === parentValue) && parentValue) {
            arr.push({
              value: parentValue,
              label: parentValue,
            });
            element.valueList.forEach((item) => {
              if (
                item.value !== k.value &&
                !arr.some((p) => p.value === item.value || p.value === item.parentValue)
              ) {
                arr.push({
                  value: item.value,
                  label: item.label,
                });
              }
            });
          }

          return arr;
        },
        []
      );
    },
    [element, elementOptionInputType]
  );

  return {
    inputRef,
    dictionaryItemPaths,
    dictionaryPathName,
    setDictionaryPathName,
    search,
    setSearch,
    showListItems,
    setShowListItems,
    itemsDictionary,
    listValue,
    setListValue,
    listLabel,
    setListLabel,
    getNullValues,
    dictionaryList,
    elementOptionInputType,
    updateElementWithValueList,
    generateUniqueValues,
  };
}
