import { Box, Icon, useColors, Text, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode } from "react";
import { BreadCrumb, BreadCrumbEntry } from "../BreadCrumb";

import { useSettingsPage } from "../../hooks/useSettingsPage";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type Props = {
  fixedHeight?: boolean;
  breadCrumbEntries?: BreadCrumbEntry[];
  breadCrumbExtraComponent?: JSX.Element;
  children?: ReactNode;
  isPanelOpen?: boolean;
  mobileHeading?: {
    title: string;
    backArrowFn: (() => void) | undefined;
    rightButtonIcon: ReactNode | undefined;
  };
  left?: ReactNode;
  right?: ReactNode;
  hideBackButton?: boolean;
};

export const Page: FunctionComponent<Props> = ({
  fixedHeight,
  breadCrumbEntries,
  breadCrumbExtraComponent,
  isPanelOpen,
  mobileHeading,
  left,
  right,
  hideBackButton,
  children,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const { breakpoint } = useDesignBreakpoint();
  const pageContext = useSettingsPage();

  const isOpen = isPanelOpen !== undefined ? isPanelOpen : pageContext.isOpen;
  const leftSide = left ? left : pageContext.leftSideBar ? pageContext.leftSideBar : null;
  const content = pageContext.topComponent ? (
    <Box display="flex" direction="column" alignItems="center" flex="grow">
      {pageContext.topComponent}
      <Box display="flex" direction="column" justifyContent="center" flex="grow">
        {children}
      </Box>
    </Box>
  ) : (
    children
  );

  return (
    <Box
      display="flex"
      direction="column"
      flex="grow"
      width={"100%"}
      minHeight={"100%"}
      height={fixedHeight ? "100%" : undefined}
      maxHeight={fixedHeight ? "100%" : undefined}
    >
      {breadCrumbEntries && (
        <Box color={colors.white} display="flex" direction="column" flex="shrink">
          <BreadCrumb
            entries={breakpoint === "small" ? [] : breadCrumbEntries}
            extraComponent={breadCrumbExtraComponent}
            hideBackButton={hideBackButton}
          />
        </Box>
      )}
      {mobileHeading && (
        <Box color={colors.white} display="flex" direction="column" flex="shrink">
          <Box
            display="flex"
            paddingY={2}
            paddingX={4}
            height={62}
            direction="row"
            flex="grow"
            fit={true}
            alignItems="center"
            justifyContent="between"
            color={colors.white}
          >
            <Box width={40} height={40} display="flex" justifyContent="center" alignItems="center">
              {mobileHeading.backArrowFn ? (
                <Icon
                  icon="arrow-left"
                  accessibilityLabel={resources.goBack}
                  color={colors.neutral900}
                  onClick={mobileHeading.backArrowFn}
                ></Icon>
              ) : (
                <Box></Box>
              )}
            </Box>
            <Text overflow="ellipsis">{mobileHeading.title}</Text>
            <Box width={40} height={40} display="flex" justifyContent="center" alignItems="center">
              {mobileHeading.rightButtonIcon || <Box></Box>}
            </Box>
          </Box>
          <Box height={1} color={colors.neutral500} />
        </Box>
      )}
      <Box display="flex" direction="column" flex="grow">
        {leftSide ? (
          <Box display="flex" direction="row" flex="grow">
            {breakpoint !== "small" ? (
              <Box
                display="flex"
                direction="column"
                flex="grow"
                width={isOpen ? "22%" : "auto"}
                minWidth={isOpen ? 300 : 40}
                overflow={fixedHeight ? "auto" : undefined}
                minHeight={"100%"}
                maxHeight={fixedHeight ? "100%" : undefined}
              >
                {leftSide}
              </Box>
            ) : undefined}
            <Box
              display="flex"
              direction="column"
              flex="grow"
              width={isOpen ? "78%" : "100%"}
              overflow={fixedHeight ? "auto" : undefined}
              minHeight={"100%"}
              maxHeight={fixedHeight ? "100%" : undefined}
            >
              {content}
            </Box>
          </Box>
        ) : (
          content
        )}
        {right ? right : null}
      </Box>
    </Box>
  );
};
