import type { TemplateVersionContextMapping } from "@prodoctivity/shared/src/index-types";
import type { HttpExecuteDataLinkRequest, HttpExecuteDataLinkResponse } from "@prodoctivity/types";
import type {
  ConnectorDataLink,
  DataElementValue,
  DataLink,
  FormConnector,
  FormDefinition,
  FormErrors,
  FormValues,
  Group,
  GroupLayouts,
  GroupValues,
  ProDoctivityFormLayout,
  ProDoctivityFormLayoutItem,
  SequenceBehavior,
  WebServiceConnector,
} from "../_lib/types";

import {
  DatePickerProps,
  DesignBreakpointType,
  type DataElementInputProps,
} from "@prodoctivity/design-system";
import type { ContextField } from "@prodoctivity/shared/src/index-types";
import type momentType from "moment";
import { Component } from "react";
import { ErrorBoundary } from "../ErrorBoundary";
import { noop } from "../_lib/utils";
import { FormPage } from "./FormPage";

type Props = {
  formDefinition: FormDefinition;
  /** The layout for the current form */
  formLayout: {
    layout: ProDoctivityFormLayout;
    groupLayouts: GroupLayouts;
  };
  connectors: WebServiceConnector[];
  /** The list of connectors that were configured for this form or document type. */
  formConnectors: FormConnector[];
  paginate: boolean;
  /** The list of data links that were configured for this form */
  dataLinks: DataLink[];
  connectorDataLinks: ConnectorDataLink[];
  /** Will disable every input when set */
  readOnly: boolean;
  summaryMode: boolean;
  /** Object that contains the values that will be used to prepulate the inputs of the form. */
  initialFormValues: FormValues;
  /** Object that contains the values that will be used to prepulate the groups of the form. */
  initialGroupValues: GroupValues;
  /** Executes with { name: string, value: DataElementValue } on every input change */
  onChangeFormValue: (params: { name: string; value: DataElementValue }) => void;
  /** Executes with the entire input data of the form on every input change */
  onUpdateValues: (params: {
    formValues: FormValues;
    groupValues: GroupValues;
    formErrors?: FormErrors;
    pinnedElements: string[];
  }) => void;
  /** Callback function that executes when a connector is triggered. */
  onFireConnector: (formConnector: FormConnector, formValues: FormValues) => Promise<FormValues>;
  /** Callback function that executes when `onFireConnector` errors. */
  onConnectorFail: (formConnector: FormConnector, error: any) => void;
  /** Callback function that executes when no data is found for a given connector. */
  onNoDataFound?: (message: string) => void;
  dataLinkMappings: TemplateVersionContextMapping["datalinks"];
  executeDataLink: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  /** Whether or not the pinning feature is active */
  showPins: boolean;
  /** Names of the data elements that should be pinned when the form initially loads */
  initialPinnedElements: string[];

  /** Indicate the data elements sequence behavior.
   * If [Lock] the data elemenet input will be disabled.
   * If [AllowEdit] the data element will allow entry value
   * Default: [Lock]
   */
  sequenceBehaviour: SequenceBehavior;
  i18n: (key: string) => string;
  navigate: string;
  onNavigate: (to: string) => void;
  initialPagesHeight: number;
  onFormErrorsUpdate: (formHasErrors: boolean) => void;
  onDependencyLayoutUpdate: (layout: ProDoctivityFormLayout) => void;
  setPinAllCallback?: (pinAllCallback: any) => void;
  onnPageDefinitionChange?: (pages: ProDoctivityFormLayoutItem[]) => void;
  onSummary?: () => void;
  defaultColumns?: string;
  componentBreakpoint: DesignBreakpointType;
  handlerNextStep?: (callback: (isNext: boolean) => boolean) => void;
  moment: typeof momentType;
  cancel?: () => void;
  cancelLabel?: string;
  finish?: () => void;
  finishButtonLabel?: string;
  back?: () => void;
  backButtonLabel?: string;
  isLoading?: boolean;
  purpose?: DataElementInputProps["purpose"];
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
};

type State = {
  layout: ProDoctivityFormLayout;
  rePaginate?: boolean;
  isLayoutSorted?: boolean;
};

export class ProDoctivityForm extends Component<Props, State> {
  static displayName = "ProDoctivityForm";

  static defaultProps = {
    formLayout: {},
    onChangeFormValue: noop,
    readOnly: false,
    summaryMode: false,
    initialFormValues: {},
    initialGroupValues: {},
    initialPinnedElements: [],
    dataLinks: [],
    connectorDataLinks: [],
  };

  state = {
    layout: this.props.formLayout.layout,
  };

  // These are not going to be used in render,
  // so there's no need to store them in state.
  formValues: FormValues = this.props.initialFormValues;
  groupValues: GroupValues = this.props.initialGroupValues;
  formErrors: FormErrors = {};
  pinnedElements: string[] = [];

  componentDidMount = () => {
    this.updateLayout(() => ({ layout: this.state.layout }));
  };

  componentDidUpdate = (prevProps: Props, prevState: any) => {
    if (prevState.layout !== this.state.layout) {
      this.updateLayout(() => ({ layout: this.state.layout }));
    }
    if (prevProps.initialFormValues !== this.props.initialFormValues) {
      this.onUpdateFormValues(this.props.initialFormValues, this.formErrors, this.pinnedElements);
    }
  };

  onLayoutSort = (layout: any) => {
    this.updateLayout(() => layout);
    this.setState({ isLayoutSorted: true });
  };

  onUpdateFormValues = (
    formValues: FormValues,
    formErrors: FormErrors,
    pinnedElements: string[]
  ) => {
    this.props.onUpdateValues({
      formValues,
      groupValues: this.groupValues,
      formErrors,
      pinnedElements,
    });
    this.formValues = formValues;
    this.formErrors = formErrors;
    this.pinnedElements = pinnedElements;
  };

  onUpdateGroupValues = (groupValues: GroupValues) => {
    this.props.onUpdateValues({
      formValues: this.formValues,
      groupValues,
      formErrors: this.formErrors,
      pinnedElements: this.pinnedElements,
    });
    this.groupValues = groupValues;
  };

  updateLayout = (
    callback: (params: { layout: ProDoctivityFormLayout }) => {
      layout: ProDoctivityFormLayout;
    }
  ) => {
    if (this.props.componentBreakpoint === "small") {
      const { layout } = callback({ layout: this.state.layout });

      const { items, alreadySorted } = [...layout].reduce(
        (acc: { order: number; alreadySorted: boolean; items: typeof layout }, next) => {
          let dirty = false;
          if (next.x !== 0) {
            next.x = 0;
            dirty = true;
          }
          if (next.y !== acc.order) {
            next.y = acc.order;
            dirty = true;
          }

          acc.items.push(next);

          return {
            order: acc.order + 1,
            items: acc.items,
            alreadySorted: acc.alreadySorted && !dirty,
          };
        },
        {
          order: 0,
          items: [],
          alreadySorted: true,
        }
      );

      if (!alreadySorted) {
        this.setState(() => ({ layout: items }));
      }
    } else {
      this.setState(callback);
    }
  };

  sortLayoutByAscending = (layout: ProDoctivityFormLayout) =>
    layout.sort((a, b) => a.x - b.x).sort((a, b) => a.y - b.y);

  RecoverDEValues = (formValues: FormValues, fields: Array<ContextField>) => {
    //This method is implement for recover the data element's value that are sanatize for save the process context as XML.
    //Example: Data element named "Hora de Inicio" as xml tag is "<HoradeInicio>", when is recoverd come "HoradeInicio" and don't match with original name.
    const newFormValues: FormValues = JSON.parse(JSON.stringify(formValues));

    fields.forEach((element) => {
      if (element.name && newFormValues[element.name] === undefined) {
        const xmlTagname = element.name.replace(/\s/g, "");

        if (formValues[xmlTagname] !== undefined) {
          newFormValues[element.name] = formValues[xmlTagname];
        }
      }
    });

    return newFormValues;
  };

  RecoverGroupValue = (groupValues: GroupValues, groups: Array<Group>) => {
    const newGroupValues: GroupValues = JSON.parse(JSON.stringify(groupValues));

    groups.forEach((group) => {
      if (group.groupName && newGroupValues[group.groupName] === undefined) {
        const xmlTagname = group.groupName.replace(/\s/g, "");

        if (groupValues[xmlTagname] !== undefined) {
          newGroupValues[group.groupName] = groupValues[xmlTagname];
        }
      }

      const arr = newGroupValues[group.groupName];

      if (Array.isArray(arr)) {
        arr.forEach((formValue, i) => {
          if (typeof formValue === "object") {
            const fValues = this.RecoverDEValues(formValue, group.fields);
            arr[i] = fValues;
          }
        });
      }
    });

    return newGroupValues;
  };

  render() {
    const {
      formLayout,
      formDefinition,
      formConnectors,
      dataLinks,
      connectorDataLinks,
      readOnly,
      summaryMode,
      initialFormValues,
      // initialGroupValues,
      onFireConnector,
      onConnectorFail,
      onNoDataFound,
      showPins,
      initialPinnedElements,
      sequenceBehaviour,
      initialPagesHeight,
      i18n,
      onFormErrorsUpdate,
      connectors,
      navigate,
      onNavigate,
      onChangeFormValue,
      onDependencyLayoutUpdate,
      setPinAllCallback,
      dataLinkMappings,
      executeDataLink,
      defaultColumns,
      componentBreakpoint,
      moment,
      handlerNextStep,
      isLoading,
      purpose,
      resources,
    } = this.props;
    const { groupLayouts } = formLayout;
    const { layout } = this.state;

    const newInitialFormValues = this.RecoverDEValues(
      initialFormValues,
      formDefinition.contextFields
    );
    const newInitialGroupValues = this.RecoverGroupValue(
      initialFormValues as any,
      formDefinition.groups
    );

    return (
      <ErrorBoundary>
        <FormPage
          isDesignMode={false}
          readOnly={readOnly}
          summaryMode={summaryMode}
          formDefinition={formDefinition}
          connectors={connectors}
          formConnectors={formConnectors}
          dataLinks={dataLinks}
          connectorDataLinks={connectorDataLinks}
          dataLinkMappings={dataLinkMappings}
          executeDataLink={executeDataLink}
          layout={this.sortLayoutByAscending(layout)}
          groupLayouts={groupLayouts}
          fullLayout={this.sortLayoutByAscending(layout)}
          fullGroupLayouts={groupLayouts}
          onChangeFormValue={onChangeFormValue}
          onUpdateFormValues={this.onUpdateFormValues}
          onUpdateGroupValues={this.onUpdateGroupValues}
          setPinAllCallback={setPinAllCallback}
          initialFormValues={newInitialFormValues}
          initialGroupValues={newInitialGroupValues}
          updateLayout={this.updateLayout}
          onFireConnector={onFireConnector}
          onConnectorFail={onConnectorFail}
          onNoDataFound={onNoDataFound}
          showPins={showPins}
          initialPinnedElements={initialPinnedElements}
          initialPagesHeight={initialPagesHeight}
          sequenceBehaviour={sequenceBehaviour}
          i18n={i18n}
          onFormErrorsUpdate={onFormErrorsUpdate}
          navigate={navigate}
          onNavigate={onNavigate}
          onDependencyLayoutUpdate={onDependencyLayoutUpdate}
          onnPageDefinitionChange={this.props.onnPageDefinitionChange}
          onSummary={this.props.onSummary}
          defaultColumns={defaultColumns}
          componentBreakpoint={componentBreakpoint}
          moment={moment}
          handlerNextStep={handlerNextStep}
          paginate={this.props.paginate}
          cancel={this.props.cancel}
          cancelLabel={this.props.cancelLabel}
          finish={this.props.finish}
          finishButtonLabel={this.props.finishButtonLabel}
          back={this.props.back}
          backButtonLabel={this.props.backButtonLabel}
          isLoading={isLoading}
          resources={resources}
          purpose={purpose}
        />
      </ErrorBoundary>
    );
  }
}
