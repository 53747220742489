import {
  Box,
  Button,
  IconButton,
  SelectList,
  Table,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import type { DictionaryListSchema, ListType } from "@prodoctivity/shared/src/index-types";

import { getListTypes } from "@prodoctivity/shared";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";

type DependentListProps = {
  currentSchema: DictionaryListSchema;
  onChange: (updatedValue: DictionaryListSchema, parentListName: string) => void;
  onAdd: (newValue: DictionaryListSchema, parentListName: string) => void;
  onRemove: (listName: string) => void;
  parentListName: string;
};
export const DependentLists: FunctionComponent<DependentListProps> = ({
  currentSchema,
  onChange,
  onAdd,
  onRemove,
  parentListName,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  return (
    <>
      <Box marginStart={4} marginEnd={4} display="flex" direction="column">
        {currentSchema.children && currentSchema.children.length > 0 ? (
          <>
            <Box
              borderRadius={4}
              borderStyle="sm"
              height={48}
              display="flex"
              alignItems="center"
              paddingX={6}
              color={colors.neutral200}
            >
              <Text weight="bold">{`${resources.dataDictionary.dependentListsFor} ${currentSchema.name}`}</Text>
            </Box>
            <Box borderStyle="lg">
              <Table accessibilityLabel="data list">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell> </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box paddingX={4}>
                        <Text size="200">{resources.name.toUpperCase()}</Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box paddingX={4}>
                        <Text size="200">{resources.description.toUpperCase()}</Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box paddingX={4}>
                        <Text size="200">{resources.dataDictionary.listType.toUpperCase()}</Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell> </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {currentSchema.children.map((schema, index) => {
                    return (
                      <Table.RowExpandable
                        key={`${currentSchema}-row-${index}`}
                        id={`${schema.name}-${index}`}
                        expandedContents={
                          <DependentLists
                            key={`${schema.name}-${index}`}
                            currentSchema={schema}
                            onChange={onChange}
                            onAdd={onAdd}
                            onRemove={onRemove}
                            parentListName={schema.name}
                          />
                        }
                        accessibilityCollapseLabel="Collapse"
                        accessibilityExpandLabel="Expand"
                      >
                        <Table.Cell>
                          <Box marginTop={5}>
                            <TextField
                              id={`${schema.name}-${index}-name`}
                              onChange={({ value }) => {
                                const newValue = { ...schema, newName: value };
                                onChange(newValue, parentListName);
                              }}
                              placeholder={resources.dataDictionary.dependentListName}
                              value={schema.name}
                              maxLength={{
                                characterCount: 100,
                                errorAccessibilityLabel: "Max Length: 100 Characters",
                              }}
                            />
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box marginTop={5}>
                            <TextField
                              id={`${schema.name}-${index}-description`}
                              onChange={({ value }) => {
                                const newValue = { ...schema, description: value };
                                onChange(newValue, parentListName);
                              }}
                              value={schema.description}
                              maxLength={{
                                characterCount: 300,
                                errorAccessibilityLabel: "Max Length: 300 Characters",
                              }}
                            />
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <SelectList
                            id={`${schema.name}-${index}-listType`}
                            onChange={({ value }) => {
                              const newValue = { ...schema, listType: value as ListType };
                              onChange(newValue, parentListName);
                            }}
                            placeholder={resources.chooseOne}
                            options={getListTypes().map((t) => {
                              return { label: t, value: t };
                            })}
                            value={schema.listType}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <IconButton
                            icon="trash"
                            accessibilityLabel={resources.delete_}
                            onClick={() => {
                              onRemove(schema.name);
                            }}
                          />
                        </Table.Cell>
                      </Table.RowExpandable>
                    );
                  })}
                </Table.Body>
              </Table>
            </Box>
          </>
        ) : (
          <Box
            borderRadius={4}
            borderStyle="sm"
            height={56}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color={colors.neutral200}
          >
            <Text title={parentListName} size="300" ellipsisLength={100}>
              {`${resources.dataDictionary.noDependentLists} ${parentListName}`}
            </Text>
          </Box>
        )}
      </Box>
      <Box margin={4} width={150} height={48}>
        <Button
          onClick={() => {
            onAdd(
              {
                children: [],
                description: "",
                listType: "None",
                name: ``,
              },
              currentSchema.name
            );
          }}
          iconEnd="add-circle"
          color="gray"
          text={resources.add}
        />
      </Box>
    </>
  );
};
