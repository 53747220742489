import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const ViewerAdjustWidthIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.9613L8.0663 11.5138V8H11.3149C14.4972 8 14.5635 8 13.5028 9.32597C12.442 10.5856 12.5083 10.7845 14.5635 12.8398C16.2873 14.5635 16.5525 15.0939 15.8895 15.8232C15.1602 16.4862 14.6298 16.221 10.7182 12.3094L8 14.9613Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <path
        d="M27.6243 10.7182L26.2983 9.32597L24.9724 8H31.9337L32 14.9613L29.2818 12.3094L27.1602 14.4972C25.4365 16.221 24.8398 16.4862 24.1768 15.8232C23.5138 15.1602 23.779 14.5635 27.6243 10.7182Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <path
        d="M10.7845 27.558L12.8398 25.4365C14.4972 23.7127 15.0939 23.4475 15.8232 24.1105C16.6188 24.7735 16.4199 25.3039 12.3757 29.2155L15.0276 31.9337L8.0663 31.8674V24.9061L10.7845 27.558Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <path
        d="M25.5028 27.0939C23.779 25.3702 23.5138 24.7735 24.1105 24.1105C24.7735 23.4475 25.3702 23.7127 29.2818 27.558L32 24.9061L31.9337 31.8674L24.9724 31.9337L27.6243 29.2155L25.5028 27.0939Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
    </svg>
  );
};
