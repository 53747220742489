import { FunctionComponent, useCallback, useState } from "react";
import {
  Layer,
  Modal,
  Box,
  Text,
  Divider,
  Button,
  modalZIndex,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import { useAppTranslation } from "../../hooks/useAppTranslation";

export const DeleteDistributionModal: FunctionComponent<{
  show: boolean;
  onDismiss(): void;
  deleteDistribution: () => void;
  permanentDelete?: boolean;
}> = ({ show, onDismiss, deleteDistribution }) => {
  const { resources } = useAppTranslation();
  const [deleteTextBoxValue, setDeleteTextBoxValue] = useState<string>();
  const { colors } = useColors();
  const handleDeleteTextBoxChange = useCallback(
    (newValue: string) => {
      setDeleteTextBoxValue(newValue);
    },
    [setDeleteTextBoxValue]
  );

  return (
    show && (
      <Layer zIndex={modalZIndex}>
        <Modal
          accessibilityModalLabel={resources.delete_}
          heading={
            <Box>
              <Box marginBottom={3}>
                <Box marginBottom={4}>
                  <Text color={colors.error}>
                    <strong>{resources.warning}</strong>
                  </Text>
                </Box>
              </Box>
              <Divider />
            </Box>
          }
          size="md"
          onDismiss={onDismiss}
          footer={
            <>
              <Divider />
              <Box marginTop={3} display="flex" justifyContent="end">
                <Box borderRadius={6} borderStyle="sm">
                  <Button color={"gray"} onClick={onDismiss} text={resources.cancel} />
                </Box>
                <Box marginStart={3} borderRadius={6} borderStyle="sm">
                  <Button
                    color={"red"}
                    onClick={deleteDistribution}
                    text={resources.yesDelete}
                    disabled={
                      deleteTextBoxValue?.toLowerCase() !==
                      resources.distributionManagementPage.permanentlyDelete
                    }
                  />
                </Box>
              </Box>
            </>
          }
        >
          <Box marginBottom={3}>
            <Text inline>{resources.distributionManagementPage.permanentlyDeleteBefore}</Text>
            <Text inline>
              {" "}
              <strong>{resources.distributionManagementPage.permanentlyDelete}</strong>{" "}
              {resources.distributionManagementPage.permanentlyDeleteAfter}
            </Text>
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              id="permanentlyDelete"
              value={deleteTextBoxValue ?? ""}
              onChange={({ value }) => handleDeleteTextBoxChange(value)}
              placeholder={resources.distributionManagementPage.permanentlyDelete}
            ></TextField>
          </Box>
        </Modal>
      </Layer>
    )
  );
};
