import {
  Box,
  Divider,
  Grid,
  Image,
  SelectList,
  TapArea,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";

import { Page } from "../../components/Layout/Page";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { CardIconSvg } from "../../svg/CardIconSvg";
import { noop } from "../../utils";
import { usePaymentPage } from "./hooks";

function PaymentPage() {
  const { resources } = useAppTranslation();
  const {
    selectedSubscription,
    isSelectedSubscription,
    handlePlanChange,
    breakpoint,
    backToTheSubscriptionsPage,
    amountOfSubscriptions,
    subscriptionDetails,
    subscription,
    breadCrumbEntries,
  } = usePaymentPage();

  const { colors } = useColors();

  const breakpointTrio =
    breakpoint === "small" || breakpoint === "medium" || breakpoint === "large";
  const breakpointDuo = breakpoint === "small" || breakpoint === "medium";

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <Box
          paddingX={breakpointDuo ? (breakpointTrio ? 6 : undefined) : 4}
          paddingY={breakpointTrio ? 6 : undefined}
          marginTop={breakpointTrio ? undefined : 4}
        >
          <Grid
            gridTemplateColumns={breakpointTrio ? (breakpointTrio ? [1] : [2, 1]) : [3, 1]}
            width={"100%"}
            gap={breakpointTrio ? 4 : 2}
          >
            <Box padding={breakpointDuo ? 4 : 6} color={colors.white}>
              <Box
                display="flex"
                width={breakpointTrio ? (breakpointTrio ? "100%" : "90%") : "65%"}
                gap={2}
                direction="column"
              >
                <Text color={colors.black900} weight="bold" size="400">
                  {resources.paymentPage.paymentDetails}
                </Text>
                <Box display="flex" gap={2} direction="column">
                  <Box display="flex" gap={2} direction="column">
                    <Text color={colors.black900} weight="bold" size="300">
                      {resources.paymentPage.fullName}
                    </Text>
                    <Box minWidth={breakpoint === "small" ? "30%" : undefined}>
                      <TextField
                        id={resources.upgradePage.currentField}
                        onChange={noop}
                        placeholder={resources.upgradePage.currentField}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" gap={2} direction="column">
                    <Text color={colors.black900} weight="bold" size="300">
                      {resources.paymentPage.paymentMethod}
                    </Text>
                    <TapArea>
                      <Box
                        borderStyle="lg"
                        borderRadius={12}
                        borderColor={colors.neutral700}
                        height={60}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          display="flex"
                          width={"100%"}
                          gap={2}
                          alignContent="center"
                          justifyContent="center"
                        >
                          <CardIconSvg />
                          <Text>{resources.paymentPage.creditOrDebit}</Text>
                        </Box>
                      </Box>
                    </TapArea>

                    <Box height={25} width={100} paddingX={2}>
                      <Image
                        color="transparent"
                        alt="transparent"
                        naturalHeight={100}
                        naturalWidth={100}
                        src={"/img/cardsIcon.png"}
                        role="presentation"
                        fit="cover"
                      />
                    </Box>
                  </Box>
                </Box>
                <Grid gridTemplateColumns={[1]} gap={2}>
                  <Box minWidth={breakpoint === "small" ? "30%" : undefined}>
                    <TextField
                      placeholder={resources.paymentPage.cardNumber}
                      id={resources.paymentPage.cardNumber}
                      onChange={noop}
                    />
                  </Box>
                  <Grid gridTemplateColumns={breakpointDuo ? [1] : [1, 1]} gap={2}>
                    <Box
                      display="flex"
                      gap={2}
                      direction="column"
                      minWidth={breakpoint === "small" ? "30%" : undefined}
                    >
                      <TextField
                        placeholder={resources.paymentPage.mmOrYy}
                        id={resources.paymentPage.mmOrYy}
                        onChange={noop}
                      />
                      <TextField placeholder={resources.name} id={resources.name} onChange={noop} />
                      <TextField
                        placeholder={resources.paymentPage.postalCode}
                        id={resources.paymentPage.postalCode}
                        onChange={noop}
                      />
                    </Box>
                    <Box
                      display="flex"
                      gap={2}
                      direction="column"
                      minWidth={breakpoint === "small" ? "30%" : undefined}
                    >
                      <TextField
                        placeholder={resources.paymentPage.cardVerificationCode}
                        id={resources.paymentPage.cardVerificationCode}
                        onChange={noop}
                      />
                      <TextField
                        placeholder={resources.paymentPage.fullName}
                        id={resources.paymentPage.fullName}
                        onChange={noop}
                      />
                      <TextField
                        placeholder={resources.paymentPage.countryOrRegion}
                        id={resources.paymentPage.countryOrRegion}
                        onChange={noop}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box marginTop={6}>
                  <Box display="flex" direction="column" gap={2}>
                    <Box display="flex" gap={1}>
                      <Text color={colors.primary}>{resources.paymentPage.contactUs}</Text>
                      <Text>{resources.paymentPage.ifYoureTaxExcempt}</Text>
                    </Box>
                    <Text color={colors.neutral700}>
                      {" "}
                      {resources.paymentPage.byUpgrading} {resources.paymentPage.amountDisplayed}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              borderStyle="shadow"
              color={colors.white}
              display="flex"
              direction="column"
              alignItems="center"
            >
              <Box display="flex" width={"100%"}>
                <Box height={"2vh"} width={"60%"} color={colors.primary} />
                <Box height={"2vh"} width={"100%"} color={colors.secondary} />
                <Box height={"2vh"} width={"60%"} color={colors.primary} />
              </Box>
              <Box display="flex" direction="column" alignItems="center">
                <Box display="flex" direction="column" alignItems="center" marginTop={12}>
                  <Text title="" color={colors.black600} weight="bold" size="600">
                    {amountOfSubscriptions}
                  </Text>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Text title="" size="500" weight="bold" color={colors.black600}>
                      ${subscriptionDetails.plan}
                    </Text>
                    <Text title="" size="200" weight="bold" color={colors.primary}>
                      / {subscriptionDetails.formOfPayment}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" width={"80%"} direction="column" gap={4}>
                <Box marginTop={12}>
                  <Text title="" weight="bold" color={colors.neutral900}>
                    {resources.paymentPage.subscription}
                  </Text>
                </Box>
                <Box width={"100%"} marginBottom={4}>
                  <SelectList
                    id={`${subscription}`}
                    onChange={handlePlanChange}
                    options={[
                      {
                        label: resources.paymentPage.paidMonthly,
                        value: resources.upgradePage.monthly,
                      },
                      {
                        label: resources.paymentPage.paidYearly,
                        value: resources.upgradePage.yearly,
                      },
                    ]}
                    value={isSelectedSubscription}
                  />
                </Box>
                <Divider />
                <Box marginTop={2}>
                  <Box display="flex" direction="column" gap={4}>
                    {selectedSubscription().map((title, index) => {
                      return (
                        <Box display="flex" key={index} alignItems="center" gap={4}>
                          <Text title={title} size="300" ellipsisLength={38}>
                            {title}
                          </Text>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Box marginTop={8} marginBottom={breakpointTrio ? 5 : undefined}>
                  <Box display="flex" gap={4} direction="column">
                    <Box
                      display="flex"
                      padding={2}
                      rounding={2}
                      borderStyle="lg"
                      fit={true}
                      width={"100%"}
                      color={colors.primary}
                      borderRadius={12}
                    >
                      <TapArea>
                        <Box display="flex" justifyContent="center" margin={1}>
                          <Text size="300" color={colors.white}>
                            {resources.upgradePlan}
                          </Text>
                        </Box>
                      </TapArea>
                    </Box>
                    <Box
                      display="flex"
                      padding={2}
                      rounding={2}
                      fit={true}
                      width={"100%"}
                      color={colors.white}
                      borderColor={colors.primary}
                      borderRadius={12}
                      onClickCapture={backToTheSubscriptionsPage}
                    >
                      <TapArea>
                        <Box display="flex" justifyContent="center" margin={1}>
                          <Text size="300" color={colors.primary}>
                            {resources.cancel}
                          </Text>
                        </Box>
                      </TapArea>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Box>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
}
export default PaymentPage;
