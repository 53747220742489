import { ColorConfig, calculateColor } from "../utils";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  direction: "right" | "left" | "down" | "up";
};

export const ChevronSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
  direction = "right",
}) => {
  const getRotateAngle = () => {
    switch (direction) {
      case "up":
        return "270deg";
      case "left":
        return "180deg";
      case "down":
        return "90deg";
      case "right":
      default:
        return "0deg";
    }
  };
  return (
    <svg
      //stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${getRotateAngle()})`,
      }}
    >
      <path
        d="M18 25L23 20L18 15"
        stroke={color ? calculateColor(color) : "#333333"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
