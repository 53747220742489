import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import DistributionManagementPage from "./DistributionManagement";

type Props = {
  isUpdating: boolean;
};
const DistributionManagementWrapper: FunctionComponent<Props> = ({ isUpdating }) => {
  const { distributionId, implementationId } = useParams();
  return (
    <DistributionManagementPage
      isUpdating={isUpdating}
      key={distributionId ? distributionId : implementationId}
    />
  );
};

export default DistributionManagementWrapper;
