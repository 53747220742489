import { Box, FancyDateTime, Skeleton, Text, useColors } from "@prodoctivity/design-system";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";
import { OrganizationLink } from "../OrganizationLink";
import { useDocumentCollection } from "../hooks";

type Props = {
  instanceId: string;
  configId: string;
  documentId?: string;
};

export function ViewerDocumentCollection({ instanceId, configId, documentId }: Props) {
  const { documentCollection, instanceIdIsLoading, configIdData, configIdIsLoading } =
    useDocumentCollection(instanceId, configId);
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  const noData =
    instanceIdIsLoading ||
    configIdIsLoading ||
    documentCollection === undefined ||
    configIdData === undefined;

  if (noData) {
    return <Skeleton />;
  }

  return (
    <Box padding={5}>
      <Box rounding={2} color={colors.white} borderStyle="sm" overflow="scrollY" maxHeight={600}>
        <Box padding={5} color={colors.white} hoverColor={colors.neutral600}>
          <Box display="flex" direction="column" flex="grow">
            <Box display="flex" flex="shrink">
              <Text>{configIdData.name}</Text>
            </Box>

            <Box display="flex" flex="grow">
              <Box display="flex" flex="grow">
                <OrganizationLink
                  to={organizationLinkTemplates.documentCollection(instanceId, documentId)}
                >
                  <Text inline={true} weight="bold">
                    {documentCollection.name}
                  </Text>
                </OrganizationLink>
              </Box>
            </Box>
            <Box display="flex" flex="shrink">
              <FancyDateTime
                moment={moment}
                resources={resources}
                value={documentCollection.updatedAt}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
