const donutStyle = {
  display: "inline-block",
  border: "4px solid rgba(0, 0, 0, 0.1)",
  borderLeftColor: "#7983ff",
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  animation: `1.2s linear 0s infinite normal none running animation-d2btug`,
};

const SpinnerContainer = ({ children, style }: { children: any; style: any }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...style,
    }}
  >
    {children}
  </div>
);

const Donut = () => <div style={donutStyle}></div>;

export const Spinner = ({ style }: { style: any }) => {
  return (
    <SpinnerContainer style={style}>
      <Donut />
    </SpinnerContainer>
  );
};

/*
const Placeholder = ({ result, style, children }) => {
  return result ? children(result) : <Spinner style={style} />
}

type Props = {
  fetchData: () => Promise<any>,
  children: any
}
export default class Loader extends Component<Props> {
  static propTypes = {
    fetchData: func.isRequired,
  }

  state = { result: null }

  async componentDidMount() {
    const result = await this.props.fetchData()
    this.setState({ result })
  }

  render() {
    const { children } = this.props
    const { result } = this.state

    return children(({ children, style }) => (
      <Placeholder result={result} style={style}>
        {children}
      </Placeholder>
    ))
  }
}*/
