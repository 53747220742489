import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ContractIconSvg: FunctionComponent<Props> = ({
  color,
  width = 19,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5718_96444)">
        <path
          d="M14.6626 3.44038C14.3506 3.38285 14.0588 3.33682 13.7468 3.27929V1.07008H5.54502C5.54502 1.51883 5.54502 1.95607 5.54502 2.40481C5.54502 3.4749 5.54502 4.54498 5.54502 5.61506C5.54502 6.09833 5.37394 6.30544 4.95127 6.30544C3.72352 6.30544 2.4857 6.30544 1.25795 6.30544C1.15731 6.30544 1.06674 6.30544 0.945975 6.30544V20.9529H13.7468V13.8881C14.0689 13.7385 14.3607 13.5889 14.6626 13.4508C14.6626 13.4508 14.6626 13.4969 14.6626 13.5314C14.6626 16.1433 14.6626 18.7437 14.6626 21.3556C14.6626 21.8159 14.5016 22.0115 14.099 22.0115C9.58051 22.0115 5.07203 22.0115 0.553496 22.0115C0.181144 22.0115 0 21.8044 0 21.3672C0 18.1684 0 14.9812 0 11.7824C0 9.84937 0 7.90481 0 5.97176C0 5.6841 0.0805085 5.45397 0.261653 5.24686C1.68061 3.62448 3.09958 2.0136 4.50847 0.379707C4.73994 0.126569 4.9714 0 5.29343 0C8.22193 0 11.1605 0 14.089 0C14.5016 0 14.6626 0.1841 14.6626 0.655858C14.6626 1.57636 14.6626 2.49686 14.6626 3.41736V3.44038Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M17.2888 8.16928C17.3894 8.27284 17.48 8.37639 17.6008 8.50296C17.7215 8.35338 17.8423 8.2038 17.9631 8.05422C18.1643 7.8356 18.3958 7.82409 18.5669 8.00819C18.7279 8.19229 18.7178 8.45694 18.5266 8.67556C17.7215 9.59606 16.9164 10.5166 16.1114 11.4371C15.9101 11.6557 15.6786 11.6672 15.5176 11.4716C15.3566 11.276 15.3767 11.0228 15.578 10.7812C16.1516 10.1253 16.7252 9.46949 17.3089 8.80213C17.2083 8.69857 17.1177 8.60652 16.997 8.49146C16.6749 8.85966 16.3428 9.25087 16.0107 9.63058C14.5113 11.345 13.0118 13.071 11.5022 14.7854C11.4117 14.8889 11.2809 14.981 11.15 15.027C10.2946 15.3607 9.43922 15.6829 8.59388 16.0051C8.4228 16.0741 8.25172 16.1086 8.11083 15.9475C7.95988 15.7749 7.99007 15.5793 8.05045 15.3722C8.34229 14.3942 8.62407 13.4161 8.91591 12.4496C8.95617 12.3115 9.02661 12.162 9.11719 12.0584C11.2809 9.57305 13.4546 7.0992 15.6182 4.61384C15.7793 4.42974 15.9503 4.38372 16.1617 4.44125C16.685 4.57932 17.0976 4.92451 17.4699 5.36175C17.802 5.75296 18.0738 6.1902 18.1744 6.7425C18.2247 6.99564 18.1744 7.19125 18.0134 7.37535C17.7819 7.62849 17.5605 7.89313 17.319 8.16928H17.2888Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M18.1649 5.31603C17.9033 5.01686 17.6617 4.72921 17.4102 4.43004C17.6215 4.19992 17.9033 3.85473 18.2052 3.54406C18.2555 3.49803 18.3863 3.48653 18.4467 3.52105C18.6882 3.63611 18.8694 3.84322 18.97 4.11937C19.0103 4.23444 19.0203 4.33799 18.9298 4.44155C18.648 4.76373 18.3763 5.07439 18.1649 5.31603Z"
          fill={color || "#5A5A5A"}
        />
        <path d="M7.61787 15.8438H3.47168V16.9138H7.61787V15.8438Z" fill={color || "#5A5A5A"} />
        <path d="M7.61787 12.4844H3.47168V13.5545H7.61787V12.4844Z" fill={color || "#5A5A5A"} />
        <path d="M9.03683 8.72168H3.47168V9.79176H9.03683V8.72168Z" fill={color || "#5A5A5A"} />
      </g>
      <defs>
        <clipPath id="clip0_5718_96444">
          <rect width="19" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
