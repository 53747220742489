import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const SocialMediaIconFacebook: FunctionComponent<Props> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g transform="translate(-0.125)">
        <circle cx="15" cy="15" r="15" transform="translate(0.125)" fill="#fff" />
        <path
          d="M8.764,8.041l.4-2.587H6.679V3.775a1.294,1.294,0,0,1,1.459-1.4H9.266V.175a13.763,13.763,0,0,0-2-.175A3.159,3.159,0,0,0,3.882,3.482V5.454H1.609V8.041H3.882V14.3h2.8V8.041Z"
          transform="translate(9.245 7.696)"
          fill="#1e394e"
        />
      </g>
    </svg>
  );
};
