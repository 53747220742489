import {
  Box,
  ChevronSvgIcon,
  Divider,
  FieldSvgIcon,
  PreviewDocSvg,
  SectionSvgIcon,
  SummarySvg,
  Tabs,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import type {
  ParametersObject,
  TemplatePage,
  TemplateSection,
  TemplateWizardField,
} from "@prodoctivity/shared/src/index-types";
import { Fragment, FunctionComponent, useMemo, useState } from "react";

import { WizardStep } from "./_types";

export const STEPS: WizardStep[] = [
  WizardStep.Form,
  WizardStep.Summary,
  WizardStep.Preview,
  WizardStep.DocumentGenerated,
];

type Props = {
  allSections: Array<{
    page: TemplatePage;
    section: TemplateSection;
  }>;
  isConfigured: boolean;
  currentStep: number;
  currentSectionKey: string;
  isOpen: (type: "page" | "section" | "group", key: string) => boolean;
  setOpenKey: (type: "page" | "section" | "group", key: string) => void;
  i18n(k: string): string;
  getTemplateContextSample(): Promise<ParametersObject>;
};

const expandableMode = false;

export const GenerationWizardSidePanel: FunctionComponent<Props> = ({
  allSections,
  isConfigured,
  currentStep,
  currentSectionKey,
  isOpen,
  setOpenKey,
  i18n,
}) => {
  const { colors } = useColors();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = useMemo(() => {
    return [
      { href: "#", text: i18n("formSteps") },
      // { href: "#", text: i18n("taskDetails") },
    ];
  }, [i18n]);

  return (
    <Box color={colors.white} width={"100%"}>
      <Tabs
        activeTabIndex={activeTabIndex}
        onChange={({ activeTabIndex: index }) => setActiveTabIndex(index)}
        tabs={tabs}
        wrap={true}
      />

      {activeTabIndex === 0 && (
        <Fragment>
          {allSections.map(({ page, section }) => {
            return (
              <GenerationWizardSidePanelSection
                key={`${page.key}_${section.key}`}
                section={section}
                isCurrentSection={currentSectionKey === section.key}
                isOpen={isOpen}
                setOpenKey={setOpenKey}
                allSections={allSections}
                expandableMode={expandableMode}
              />
            );
          })}

          <Box display="flex" direction="column">
            <Box display="flex" direction="row" flex="shrink" alignItems="center" gap={2}>
              <Box margin={expandableMode ? 4 : 1} />
              <SummarySvg />
              <Text weight={STEPS[currentStep] === WizardStep.Summary ? "bold" : "normal"}>
                {i18n("summary")}{" "}
              </Text>
            </Box>
          </Box>
          {!isConfigured && (
            <>
              <Box display="flex" direction="column">
                <Box display="flex" direction="row" flex="shrink" alignItems="center" gap={2}>
                  <Box margin={expandableMode ? 4 : 1} />
                  <PreviewDocSvg />
                  <Text weight={STEPS[currentStep] === WizardStep.Preview ? "bold" : "normal"}>
                    {i18n("preview")}
                  </Text>
                </Box>
              </Box>
            </>
          )}
        </Fragment>
      )}
    </Box>
  );
};

const GenerationWizardSidePanelSection: FunctionComponent<{
  section: TemplateSection;
  allSections: Array<{
    page: TemplatePage;
    section: TemplateSection;
  }>;
  isCurrentSection: boolean;
  isOpen: (type: "page" | "section" | "group", key: string) => boolean;
  setOpenKey: (type: "page" | "section" | "group", key: string) => void;
  expandableMode: boolean;
}> = ({ section, allSections, isCurrentSection, isOpen, setOpenKey, expandableMode }) => {
  const { colors } = useColors();
  return (
    <Fragment>
      <Box hoverColor={colors.primary100} paddingX={1}>
        <TapArea key={section.key} role="button" fullWidth={true}>
          <Box
            display="flex"
            alignItems="center"
            height={50}
            paddingY={2}
            onClickCapture={() => setOpenKey("section", section.key)}
          >
            <Box display="flex" direction="row">
              <Box margin={1} />
              <Box height={35} display="flex" direction="column" marginTop={2}>
                <Box height={25} width={3} color={isCurrentSection ? colors.primary : undefined} />
              </Box>
              {expandableMode &&
                (isOpen("section", section.key) ? (
                  <Box width={20} alignSelf="center">
                    <ChevronSvgIcon direction="down" />
                  </Box>
                ) : (
                  <Box width={20} alignSelf="center">
                    <ChevronSvgIcon direction="right" />
                  </Box>
                ))}
            </Box>
            <Box width={24} height={24} marginStart={4} marginEnd={4}>
              <SectionSvgIcon />
            </Box>
            <Text overflow="ellipsis" inline={true} title={section.label}>
              {section.label}
            </Text>
          </Box>
        </TapArea>
      </Box>
      <Box marginStart={5}>
        {expandableMode &&
          isOpen("section", section.key) &&
          (section.fields || []).map((f) => (
            <GenerationWizardSidePanelField
              key={f.key}
              field={f}
              sectionParentKey={section.key}
              allSections={allSections}
              isOpen={isOpen}
              setOpenKey={setOpenKey}
              expandableMode={expandableMode}
            />
          ))}
        {expandableMode && isOpen("section", section.key) && <Divider />}
      </Box>
    </Fragment>
  );
};

const GenerationWizardSidePanelField: FunctionComponent<{
  field: TemplateWizardField;
  sectionParentKey: string;
  allSections: Array<{
    page: TemplatePage;
    section: TemplateSection;
  }>;
  isOpen: (type: "page" | "section" | "group", key: string) => boolean;
  setOpenKey: (type: "page" | "section" | "group", key: string) => void;
  expandableMode: boolean;
}> = ({ field, sectionParentKey, allSections, isOpen, setOpenKey, expandableMode }) => {
  const { colors } = useColors();
  if (!field.isRecord) {
    return (
      <Box
        key={`${sectionParentKey}_${field.key}`}
        justifyContent="between"
        display="flex"
        height={50}
        paddingY={2}
        marginStart={3}
        hoverColor={colors.primary100}
        paddingX={1}
      >
        <TapArea key={field.key} role="button">
          <Box display="flex" alignItems="center">
            <Box width={40} height={16} marginStart={4} marginEnd={4}>
              <FieldSvgIcon />
            </Box>
            <Text
              overflow="ellipsis"
              title={field.label.split("/")[field.label.split("/").length - 1]}
            >
              {field.label.split("/")[field.label.split("/").length - 1]}
            </Text>
          </Box>
        </TapArea>
      </Box>
    );
  }

  return (
    <Box hoverColor={colors.primary100} paddingX={1}>
      <TapArea key={field.key} role="button">
        <Box display="flex" justifyContent="between" height={50} paddingY={2} marginStart={4}>
          <Box
            display="flex"
            alignItems="center"
            onClickCapture={() => setOpenKey("group", field.key)}
            width={"100%"}
          >
            <Box marginStart={1} marginEnd={9}>
              {isOpen("group", field.key) ? (
                <Box width={20} alignSelf="center">
                  <ChevronSvgIcon direction="down" />
                </Box>
              ) : (
                <Box width={20} alignSelf="center">
                  <ChevronSvgIcon direction="right" />
                </Box>
              )}
            </Box>
            <Text weight={"bold"} color={colors.black900}>
              {field.label}
            </Text>
          </Box>
        </Box>
      </TapArea>

      {expandableMode && (
        <Box marginStart={10}>
          {isOpen("group", field.key) &&
            (field.fields || []).map((f) => (
              <GenerationWizardSidePanelField
                field={f}
                sectionParentKey={sectionParentKey}
                allSections={allSections}
                isOpen={isOpen}
                setOpenKey={setOpenKey}
                expandableMode={expandableMode}
              />
            ))}
          {isOpen("group", field.key) && <Divider />}
        </Box>
      )}
    </Box>
  );
};
