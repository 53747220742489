import { AccordionExpandable, Box, Skeleton, Text, useColors } from "@prodoctivity/design-system";

import type { DataLinkParameterTemplate } from "@prodoctivity/types";
import { FC } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useDatalinkVersionConfig } from "../../hooks";

type Props = {
  datalinkId: string;
  datalinkVersionId: string;
};

const stringifyParameterTemplate = (template: DataLinkParameterTemplate): string => {
  const templateString = template.values.reduce<string>((previous, current) => {
    let result = "";
    if (current.source === "fixed") {
      result = current.value;
    } else {
      result = `[${current.value}:${current.source}]`;
    }
    return `${previous}${result}`;
  }, "");
  if (templateString === "") {
    return "[NONE]";
  }
  return templateString;
};

const DatalinkCard: FC<Props> = (DatalinkCardProps: Props) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { isLoading, data } = useDatalinkVersionConfig(
    DatalinkCardProps.datalinkId,
    DatalinkCardProps.datalinkVersionId
  );
  if (isLoading || !data) {
    return (
      <Box padding={2} margin={1}>
        <Skeleton width={400} height={160} key={DatalinkCardProps.datalinkVersionId} />
      </Box>
    );
  } else {
    return (
      <Box padding={2} margin={1}>
        <AccordionExpandable
          id={data.datalink.datalinkId}
          accessibilityCollapseLabel="Hide Details"
          accessibilityExpandLabel="Show Details"
          items={[
            {
              title: `${data.datalink.name.toLocaleLowerCase()}`,
              children: (
                <Box>
                  <Text size="200" color={colors.primary200}>
                    {data.datalink.datalinkId}
                  </Text>
                  {data.datalink.versions.length > 0 &&
                    data.datalink.versions[0].inputParameters.map((ip) => {
                      return (
                        <Box key={ip.key}>
                          <Text size="200" color={colors.black600}>
                            ({ip.key}:{ip.dataType})
                          </Text>
                        </Box>
                      );
                    })}

                  {data.datalink.versions.length > 0 && (
                    <Box marginTop={2}>
                      <Text size="200" color={colors.black600}>
                        {resources.dataLinkRequestTemplateParameters.baseURL}
                      </Text>
                      <Text size="100" color={colors.secondary}>
                        {stringifyParameterTemplate(
                          data.datalink.versions[0].requestTemplate.baseUrl
                        )}
                      </Text>
                      <Text size="200" color={colors.black600}>
                        {resources.dataLinkRequestTemplateParameters.headers}
                      </Text>
                      <Text size="100" color={colors.secondary}>
                        {stringifyParameterTemplate(
                          data.datalink.versions[0].requestTemplate.headers
                        )}
                      </Text>
                      <Text size="200" color={colors.black600}>
                        {resources.dataLinkRequestTemplateParameters.path}
                      </Text>
                      <Text size="100" color={colors.secondary}>
                        {stringifyParameterTemplate(data.datalink.versions[0].requestTemplate.path)}
                      </Text>
                      <Text size="200" color={colors.black600}>
                        {resources.dataLinkRequestTemplateParameters.payload}
                      </Text>
                      <Text size="100" color={colors.secondary}>
                        {stringifyParameterTemplate(
                          data.datalink.versions[0].requestTemplate.payload
                        )}
                      </Text>
                      <Text size="200" color={colors.black600}>
                        {resources.dataLinkRequestTemplateParameters.queryString}
                      </Text>
                      <Text size="100" color={colors.secondary}>
                        {stringifyParameterTemplate(
                          data.datalink.versions[0].requestTemplate.queryString
                        )}
                      </Text>
                      <Text size="200" color={colors.black600}>
                        {resources.dataLinkRequestTemplateParameters.responseType}
                      </Text>
                      <Text size="100" color={colors.secondary}>
                        {data.datalink.versions[0].requestTemplate.responseType}
                      </Text>
                      <Text size="200" color={colors.black600}>
                        {resources.dataLinkRequestTemplateParameters.verb}
                      </Text>
                      <Text size="100" color={colors.secondary}>
                        {data.datalink.versions[0].requestTemplate.verb}
                      </Text>
                    </Box>
                  )}
                </Box>
              ),
            },
          ]}
        />
      </Box>
    );
  }
};

export { DatalinkCard };
