import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const AddDocumentButtonSvg: FunctionComponent<Props> = ({
  color,
  width = 29,
  height = 27,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="29"
      height="27"
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7866_105779)">
        <path
          d="M3.83985 7.99637V10.8879H11.227L11.1173 11.2092C11.0441 11.4948 10.971 11.8161 10.9344 12.1017C10.8613 12.6014 10.8247 13.0655 10.8247 13.5653V13.8866C10.8613 14.3863 10.8979 14.8504 11.0076 15.3145L3.83985 15.3502V18.2774H12.0681C12.3241 18.7415 12.6532 19.2056 13.0189 19.6339C13.1652 19.8481 13.348 20.0266 13.5309 20.2051L13.7137 20.3836C14.4817 21.169 15.396 21.7758 16.3468 22.2399C17.6999 22.8825 19.2358 23.2395 20.7718 23.2395H21.0643V24.2033C21.0643 25.3099 20.1501 26.2381 18.9798 26.2381H2.08449C0.95082 26.2381 0 25.3099 0 24.2033V2.07049C0 0.92815 0.95082 0 2.08449 0H18.9798C20.1501 0 21.0643 0.92815 21.0643 2.07049V3.92679H19.6747L19.4187 3.96249H19.3455C18.285 4.14098 17.261 4.42656 16.4565 4.85494C15.5788 5.24762 14.7743 5.78309 13.9697 6.49705L13.7137 6.74694C13.348 7.13962 13.0555 7.4609 12.7995 7.78218C12.7629 7.85358 12.6898 7.92498 12.6532 7.99637H3.83985Z"
          fill={color ? calculateColor(color) : "#767676"}
        />
        <path
          d="M20.7717 5.35449C16.237 5.35449 12.5435 8.9243 12.5435 13.3866C12.5435 17.8488 16.237 21.4186 20.7717 21.4186C25.3064 21.4186 29 17.8131 29 13.3866C29 8.96 25.3064 5.35449 20.7717 5.35449ZM25.8184 15.0287H22.3808V18.4914H19.1261V15.0287H15.6885V11.7445H19.1261V8.24604H22.3808V11.7445H25.8184V15.0287Z"
          fill={color ? calculateColor(color) : "#767676"}
        />
      </g>
      <defs>
        <clipPath id="clip0_7866_105779">
          <rect width="29" height="26.2381" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
