import { Box, ComboBox, Text, useColors } from "@prodoctivity/design-system";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useMemo, useState } from "react";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import { SelectedItemTag } from "../Users/Actions/RolesTab";

type Props = {
  isLoading: boolean;
  availableLabel?: string;
  placeholder?: string;
  selectedLabel?: string;
  options: Array<ComboBoxItemType>;
  selectedOptions: Array<ComboBoxItemType>;
  onSelect: (value: string) => void;
  onRemove: (value: string) => void;
};

export const ComboBoxWithSelectedTag = ({
  isLoading,
  availableLabel,
  selectedLabel,
  placeholder,
  options,
  selectedOptions,
  onSelect,
  onRemove,
}: Props) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [filter, setFilter] = useState<string | undefined>();

  const filteredOptions = useMemo(() => {
    return options.filter(
      (u) =>
        selectedOptions.findIndex((opt) => opt.value === u.value) < 0 &&
        u.label.toLocaleLowerCase().includes((filter || "").toLocaleLowerCase())
    );
  }, [options, selectedOptions, filter]);

  return (
    <Box display="flex" direction="column" color={colors.white}>
      {/* Form Start */}
      <Box display="flex" direction="column" flex="grow" width={"100%"} paddingX={4} gap={4}>
        <Box display="flex" direction="column" flex="shrink" gap={1}>
          <Box minWidth={170} display="flex">
            <Text color={colors.subtle}>{availableLabel || resources.availableOptions}</Text>
          </Box>
          <Box flex="grow">
            <ComboBox
              id={"availableItems"}
              label=""
              disabled={isLoading}
              inputValue={filter || ""}
              placeholder={placeholder || resources.chooseOne}
              onClear={() => setFilter(undefined)}
              onChange={(e) => setFilter(e.value)}
              onSelect={(e) => {
                onSelect(e.item.value);
                setFilter(undefined);
              }}
              options={filteredOptions}
            />
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="shrink" gap={1}>
          <Box minWidth={170} width={150} display="flex">
            <Text color={colors.subtle}>{selectedLabel || resources.selectedOptions}</Text>
          </Box>
          <Box display="flex" flex="grow">
            <Box
              width={"100%"}
              height={"fit-content"}
              minHeight={120}
              display="flex"
              paddingX={2}
              borderRadius={4}
              alignContent="start"
              wrap={true}
            >
              {selectedOptions.map((option, index) => (
                <SelectedItemTag
                  key={`${option.value}_${index}`}
                  label={option.label}
                  value={option.value}
                  onRemove={() => {
                    if (!isLoading) {
                      onRemove(option.value);
                      setFilter(undefined);
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Form End */}
    </Box>
  );
};
