import {
  ColumnDef,
  ExpandedState,
  TableMeta,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import React from "react";

export type DataGridProps<T> = {
  columns: ColumnDef<T>[];
  meta?: TableMeta<T>;
  data: T[];
  getSubRows?: (originalRow: T, index: number) => T[] | undefined;
  tableAttributes: React.TableHTMLAttributes<HTMLTableElement> | undefined;
};

export function DataGrid<T>({
  data,
  columns,
  getSubRows,
  tableAttributes,
  meta,
}: DataGridProps<T>) {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    meta,
    getSubRows,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className={"prodoctivity-container"}>
      <table {...tableAttributes}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
