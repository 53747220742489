import {
  Box,
  BoxWithRef,
  Divider,
  Layer,
  SearchField,
  Spinner,
  Text,
  overlayPanelZIndex,
} from "@prodoctivity/design-system";
import { LeftSidebar } from "@prodoctivity/design-system/components/layout/LeftSidebar";
import { FunctionComponent } from "react";
import { Page } from "../Layout/Page";
import { DateRangeFilter } from "./DateRangeFilter";
import { useInboxPage } from "./hooks";
import { ProcessAccordion } from "./ProcessAccordion";
import { TaskDetailsPanel } from "./TaskDetailsPanel";

export const InboxPage: FunctionComponent = () => {
  const {
    resources,
    colors,
    // breakpoint,
    isOpen,
    handleSetIsOpen,
    taskDetailsPanel,
    setTaskDetailsPanel,
    breadCrumbEntries,
    columnHeaders,
    options,
    isLoading,
    setProcessFilter,
    dateFilter,
    handleChangeDate,
    handleChangeDateTo,
    selectedMenuItem,
    setSelectedMenuItemCallback,
    workflowTypeList,
    processFilter,
    listType,
    filteredResourceIndex,
    tableHeadersRef,
    headersWidth,
    openAccordions,
    isAnyAccordionOpen,
    onAnyAccordionOpen,
    resetOpenAccordions,
  } = useInboxPage();
  return (
    <Page
      breadCrumbEntries={breadCrumbEntries}
      isPanelOpen={isOpen}
      left={
        <LeftSidebar
          options={options}
          openState={{ isOpen, setIsOpen: handleSetIsOpen }}
          title={resources.homePage.myTasks}
          setSelectedMenuItemCallback={setSelectedMenuItemCallback}
          index={filteredResourceIndex}
          customElement={
            <DateRangeFilter
              filterLabel={resources.workflow.taskCreationDate.toUpperCase()}
              fromDate={dateFilter.fromDate}
              toDate={dateFilter.toDate}
              handleChangeDate={handleChangeDate}
              handleChangeDateTo={handleChangeDateTo}
            />
          }
          resources={resources}
        />
      }
    >
      <Box display="flex" direction="column" maxHeight={60} height={"100%"}>
        <Box
          display="flex"
          justifyContent="between"
          alignItems="center"
          paddingX={8}
          paddingY={3}
          flex="grow"
        >
          <Text weight="bold">{selectedMenuItem}</Text>
          <Box display="flex" flex="grow" maxWidth={300} marginStart={6}>
            <SearchField
              accessibilityLabel={resources.dataDictionary.searchElementsPlaceholder}
              id={"inbox-process-search"}
              onChange={({ value }) => {
                setProcessFilter(value);
                resetOpenAccordions();
              }}
            />
          </Box>
        </Box>
        <Divider direction="horizontal"></Divider>
      </Box>
      {isLoading ? (
        <Box paddingX={8} paddingY={4}>
          <Spinner show={true} />
        </Box>
      ) : (
        <Box paddingX={8} paddingY={4}>
          {isAnyAccordionOpen && (
            <BoxWithRef
              ref={tableHeadersRef}
              display="flex"
              flex="grow"
              alignItems="center"
              maxHeight={46}
              paddingY={2}
              paddingX={6}
              height={"100%"}
            >
              <Box column={5} paddingRight={3}></Box>
              {columnHeaders.map((col, idx) => {
                return (
                  <Box
                    key={`${idx}-${col.name}_${col.span}`}
                    display="flex"
                    justifyContent="start"
                    column={
                      col.name === resources.expires && headersWidth && headersWidth < 950
                        ? 3
                        : col.span
                    }
                    paddingRight={3}
                    paddingLeft={idx === columnHeaders.length - 1 ? 3 : undefined}
                  >
                    <Text
                      size="200"
                      color={colors.neutral900}
                      overflow="ellipsis"
                      title={
                        (col.name === resources.workflow.priority ||
                          col.name === resources.actions ||
                          col.name === resources.status) &&
                        headersWidth &&
                        headersWidth < 950
                          ? ""
                          : col.name.toUpperCase()
                      }
                    >
                      {(col.name === resources.workflow.priority ||
                        col.name === resources.actions ||
                        col.name === resources.status) &&
                      headersWidth &&
                      headersWidth < 950
                        ? ""
                        : col.name.toUpperCase()}
                    </Text>
                  </Box>
                );
              })}
            </BoxWithRef>
          )}
          <Box display="flex" direction="column" gap={4}>
            <ProcessAccordion
              cIndex={0}
              category={{
                workflowTypeId: "document-collection-task",
                workflowTypeName: resources.rolesManagement.documentCollectionTask,
                states: [],
                engineType: "basic",
              }}
              fromDate={dateFilter.fromDate?.getTime()}
              toDate={dateFilter.toDate?.getTime()}
              processFilter={processFilter}
              listType={listType}
              setProcessDetailsPanel={setTaskDetailsPanel}
              taskType={"document-collection-task"}
              isWidget={false}
              isOpenAccordion={openAccordions.has("document-collection-task")}
              onAnyAccordionOpen={(isOpen) =>
                onAnyAccordionOpen("document-collection-task", isOpen)
              }
            />
            <ProcessAccordion
              cIndex={0}
              category={{
                workflowTypeId: "document-approval-task",
                workflowTypeName: resources.approvalTasks,
                states: [],
                engineType: "basic",
              }}
              fromDate={dateFilter.fromDate?.getTime()}
              toDate={dateFilter.toDate?.getTime()}
              processFilter={processFilter}
              listType={listType}
              setProcessDetailsPanel={setTaskDetailsPanel}
              taskType={"document-approval-task"}
              isWidget={false}
              isOpenAccordion={openAccordions.has("document-approval-task")}
              onAnyAccordionOpen={(isOpen) => onAnyAccordionOpen("document-approval-task", isOpen)}
            />
            <ProcessAccordion
              cIndex={0}
              category={{
                workflowTypeId: "template-context-state",
                workflowTypeName: resources.generationTasks,
                states: [],
                engineType: "basic",
              }}
              fromDate={dateFilter.fromDate?.getTime()}
              toDate={dateFilter.toDate?.getTime()}
              processFilter={processFilter}
              listType={listType}
              setProcessDetailsPanel={setTaskDetailsPanel}
              taskType={"template-context-state"}
              isWidget={false}
              isOpenAccordion={openAccordions.has("template-context-state")}
              onAnyAccordionOpen={(isOpen) => onAnyAccordionOpen("template-context-state", isOpen)}
            />
            {!isLoading &&
              workflowTypeList &&
              workflowTypeList.workflowTypes.map((category, cIndex) => {
                return (
                  <ProcessAccordion
                    key={`${cIndex}-${category.workflowTypeId}_${category.workflowTypeName}`}
                    cIndex={cIndex}
                    category={category}
                    fromDate={dateFilter.fromDate?.getTime()}
                    toDate={dateFilter.toDate?.getTime()}
                    processFilter={processFilter}
                    listType={listType}
                    setProcessDetailsPanel={setTaskDetailsPanel}
                    taskType={undefined}
                    isWidget={false}
                    isOpenAccordion={openAccordions.has(category.workflowTypeId)}
                    onAnyAccordionOpen={(isOpen) =>
                      onAnyAccordionOpen(category.workflowTypeId, isOpen)
                    }
                  />
                );
              })}
          </Box>
          {taskDetailsPanel && (
            <Layer zIndex={overlayPanelZIndex}>
              <TaskDetailsPanel
                taskSelected={taskDetailsPanel}
                onCloseSheet={() => setTaskDetailsPanel(undefined)}
              ></TaskDetailsPanel>
            </Layer>
          )}
        </Box>
      )}
    </Page>
  );
};
