import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const WarningExclamationIconSvg: FunctionComponent<Props> = ({
  color,
  width = 35,
  height = 32,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8536 24.2216L27.7544 13.5802L21.6553 2.92194C19.8156 -0.307313 15.1915 -0.307313 13.3353 2.92194L7.2527 13.5802L1.15355 24.2216C-0.702712 27.4676 1.61762 31.5 5.31357 31.5H29.677C33.3895 31.5 35.6933 27.4676 33.8536 24.2216Z"
        fill={color ? calculateColor(color) : "#E97025"}
      />
      <path
        d="M17.5045 25.1525C18.2643 25.1525 18.8802 24.5307 18.8802 23.7637C18.8802 22.9968 18.2643 22.375 17.5045 22.375C16.7448 22.375 16.1289 22.9968 16.1289 23.7637C16.1289 24.5307 16.7448 25.1525 17.5045 25.1525Z"
        fill="white"
      />
      <path d="M18.9113 10.7227L18.4969 20.31H16.4915L16.0938 10.7227H18.9113Z" fill="white" />
    </svg>
  );
};
