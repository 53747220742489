import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
  type: "exclamation" | "exclamation-inverted";
};

export const CircleInfoSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 50,
  height = 50,
  onClick,
  type = "exclamation",
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      //stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={50}
      height={50}
      viewBox={`0 0 50 50`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{
        transform: `rotate(${type === "exclamation" ? "180deg" : "0deg"})`,
        ...svgStyle,
      }}
    >
      <path
        d="M25 13C22.6266 13 20.3066 13.7038 18.3332 15.0224C16.3598 16.3409 14.8217 18.2151 13.9135 20.4078C13.0052 22.6005 12.7676 25.0133 13.2306 27.3411C13.6936 29.6689 14.8365 31.8071 16.5147 33.4853C18.193 35.1635 20.3311 36.3064 22.6589 36.7694C24.9867 37.2324 27.3995 36.9948 29.5922 36.0866C31.7849 35.1783 33.6591 33.6402 34.9776 31.6668C36.2962 29.6935 37 27.3734 37 25C37 23.4241 36.6896 21.8637 36.0866 20.4078C35.4835 18.9519 34.5996 17.629 33.4853 16.5147C32.371 15.4004 31.0481 14.5165 29.5922 13.9134C28.1363 13.3104 26.5759 13 25 13ZM26.2 29.8C26.2 30.1183 26.0736 30.4235 25.8485 30.6485C25.6235 30.8736 25.3183 31 25 31C24.6817 31 24.3765 30.8736 24.1515 30.6485C23.9264 30.4235 23.8 30.1183 23.8 29.8V23.8C23.8 23.4817 23.9264 23.1765 24.1515 22.9515C24.3765 22.7264 24.6817 22.6 25 22.6C25.3183 22.6 25.6235 22.7264 25.8485 22.9515C26.0736 23.1765 26.2 23.4817 26.2 23.8V29.8ZM25 21.4C24.7627 21.4 24.5307 21.3296 24.3333 21.1978C24.136 21.0659 23.9822 20.8785 23.8913 20.6592C23.8005 20.4399 23.7768 20.1987 23.8231 19.9659C23.8694 19.7331 23.9837 19.5193 24.1515 19.3515C24.3193 19.1836 24.5331 19.0694 24.7659 19.0231C24.9987 18.9768 25.24 19.0005 25.4592 19.0913C25.6785 19.1822 25.8659 19.336 25.9978 19.5333C26.1296 19.7307 26.2 19.9627 26.2 20.2C26.2 20.5183 26.0736 20.8235 25.8485 21.0485C25.6235 21.2736 25.3183 21.4 25 21.4Z"
        fill={color ? calculateColor(color) : "#3366CC"}
      />
    </svg>
  );
};
