import { es } from "date-fns/locale/es";
import { nl } from "date-fns/locale/nl";
import { pt } from "date-fns/locale/pt";
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import "../external/gestalt-datepicker.css";

import { Ref, useMemo } from "react";

registerLocale("sp", es);
registerLocale("pt", pt);
registerLocale("nl", nl);

// eslint-disable-next-line @typescript-eslint/no-empty-function
function localNoop() {}

export type DatePickerResources = {
  _locale: string;
  _dateFormatShort: string;
  _dateTimeFormat: string;
  _dateTimeFormatShort: string;
  _timeFormat: string;
};

export type DatePickerProps = Omit<ReactDatePickerProps<false, false>, "value" | "onChange"> & {
  ref?: Ref<HTMLInputElement | null>;
  placeholder: string;
  resources: DatePickerResources;
  value?: Date | undefined;
  onChange(date: { value: Date | null | undefined }): void;
  rangeStartDate?: Date | undefined;
  rangeEndDate?: Date | undefined;
  label?: string | undefined;
  rangeSelector?: "start" | "end";
  nextRef?: unknown;
  dataType: "Date" | "DateTime" | "Time";
};

export function DatePicker(props: DatePickerProps) {
  const {
    ref: _ref,
    nextRef: _nextRef,
    rangeSelector: _rangeSelector,
    placeholder,
    value,
    onChange,
    rangeStartDate,
    rangeEndDate,
    label,
    resources,
    dataType: _dataType,
    ...rest
  } = props;

  const stardardizedOnChange = useMemo(() => {
    if (onChange === undefined) {
      return localNoop;
    }
    const r: ReactDatePickerProps["onChange"] = (args) => {
      onChange({ value: args });
    };
    return r;
  }, [onChange]);

  return (
    <ReactDatePicker
      {...rest}
      locale={resources._locale}
      placeholderText={placeholder || label}
      value={undefined}
      onChange={stardardizedOnChange}
      startDate={rangeStartDate}
      endDate={rangeEndDate}
      dateFormat={"MM/dd/yyyy"}
      selected={!value || isNaN(value.getTime()) ? undefined : value}
      minDate={rangeStartDate}
      maxDate={rangeEndDate}
      isClearable={true}
    />
  );
}
