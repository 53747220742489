import { SelectListProps, useDesignBreakpoint } from "@prodoctivity/design-system";
import type {
  TemplateSubscription,
  TemplateSubscriptionType,
} from "@prodoctivity/shared/src/index-types";
import type {
  DocumentTypeSubscription,
  DocumentTypeSubscriptionType,
  HttpGetUserDocumentTypeSubscriptionsRequest,
  HttpGetUserTemplatesSubscriptionsRequest,
} from "@prodoctivity/types";
import { useCallback, useMemo, useState } from "react";
import {
  DocumentTypesInitialState,
  TemplatesInitialState,
} from "../../components/Subscriptions/hooks";

import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { usePagination } from "../../components/Layout/Pagination/hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { usePlanSetting } from "../../hooks/usePlanSetting";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { prices } from "../../pages/Pricing/Pricing";

/********************************************************************************************
 *********************************** CODE SECTION: Types *************************************
 *********************************************************************************************/

/* ---------------------------- #TEMPLATES region ---------------------------- */

export type FormattedTemplateSubscription = {
  templateId: string;
  templateName: string;
  subscriptionType: TemplateSubscriptionType;
  action: "subscribe" | "unsubscribe";
  sendToWeb: boolean;
  sendToMobile: boolean;
};

export type UpdatedTemplateSubscriptionList = {
  templateId: string;
  subscriptionType: TemplateSubscriptionType;
  action: "subscribe" | "unsubscribe";
  sendToWeb: boolean;
  sendToMobile: boolean;
};

/* ---------------------------- #DOCUMENT TYPES region ---------------------------- */

export type FormattedDocumentTypeSubscription = {
  documentTypeId: string;
  documentTypeName: string;
  subscriptionType: DocumentTypeSubscriptionType;
  action: "subscribe" | "unsubscribe";
  sendToWeb: boolean;
  sendToMobile: boolean;
};

export type UpdatedDocumentTypeSubscriptionList = {
  documentTypeId: string;
  subscriptionType: DocumentTypeSubscriptionType;
  action: "subscribe" | "unsubscribe";
  sendToWeb: boolean;
  sendToMobile: boolean;
};

/**************************************************************************************************************************************************  ************************************************************* HOOK ********************************************************************************/

export function useUserSubscriptions() {
  const { resources } = useAppTranslation();
  const {
    getMyUserProfile,
    getUserTemplatesSubscriptions,
    getUserDocumentTypeSubscriptions,
    updateUserProfile,
    updateTemplateSubscription,
    updateDocumentTypeSubscriptions,
    user,
  } = useServices();
  const organizationNavigate = useOrganizationNavigate();
  const [currentTemplatesSubscriptions, setCurrentTemplatesSubscriptions] =
    useState<TemplatesInitialState>([]);
  const [staticInitialSubscription, setStaticInitialSubscription] = useState<TemplatesInitialState>(
    []
  );
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorsFoundOnSave, setErrorsFoundOnSave] = useState<string[]>([]);
  const [toastMessage, setToastMessage] = useState("");
  const [currentUserEmailPreferences, setCurrentUserEmailPreferences] = useState<boolean>(false);
  const [initialUserEmailPreferences, setInitialUserEmailPreferences] = useState<boolean>(false);
  const [currentDocumentTypeSubscriptions, setCurrentDocumentTypeSubscriptions] =
    useState<DocumentTypesInitialState>([]);
  const [staticDocTypeInitialSubscription, setStaticDocTypeInitialSubscription] =
    useState<DocumentTypesInitialState>([]);

  /********************************************************************************************
   ******************************** CODE SECTION: Breadcrumb ***********************************
   *********************************************************************************************/

  const breadcrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.account() },
      {
        type: "url",
        name: resources.subscriptions.subscriptionsLabel,
        url: organizationLinkTemplates.userSubscriptions(),
      },
    ];
  }, [resources.home, resources.subscriptions, resources.settings]);

  /********************************************************************************************
   ******************************** CODE SECTION: Pagination ***********************************
   *********************************************************************************************/

  /* ---------------------------- #TEMPLATES region ---------------------------- */

  const templateSubscriptionsPageLengthOptions: Array<
    HttpGetUserTemplatesSubscriptionsRequest["queryParameters"]["rowsPerPage"]
  > = ["15", "30", "100", "500"];

  const {
    currentPage,
    rowsPerPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    setPageData,
    paginationDispatch,
  } = usePagination<HttpGetUserTemplatesSubscriptionsRequest["queryParameters"]["rowsPerPage"]>({
    currentPage: 0,
    rowsPerPage: "15",
  });

  /* ---------------------------- #DOCUMENT TYPES region ---------------------------- */

  const documentTypeSubscriptionsPageLengthOptions: Array<
    HttpGetUserDocumentTypeSubscriptionsRequest["queryParameters"]["rowsPerPage"]
  > = ["15", "30", "100", "500"];

  const {
    currentPage: docTypeCurrentPage,
    rowsPerPage: docTypeRowsPerPage,
    isNextButtonDisabled: docTypeIsNextButtonDisabled,
    isPreviousButtonDisabled: docTypeIsPreviousButtonDisabled,
    setPageData: docTypeSetPageData,
    paginationDispatch: docTypePaginationDispatch,
  } = usePagination<HttpGetUserDocumentTypeSubscriptionsRequest["queryParameters"]["rowsPerPage"]>({
    currentPage: 0,
    rowsPerPage: "15",
  });

  /********************************************************************************************
   *********************** CODE SECTION: GET methods implementation *****************************
   *********************************************************************************************/

  /* ---------------------------- #EMAIL PREFERENCES region ---------------------------- */

  const getUserEmailPreferencesDetails = useCallback(async () => {
    const myUserProfileResponse = await getMyUserProfile();
    setCurrentUserEmailPreferences(
      myUserProfileResponse.isEnrolledForMarketing !== undefined &&
        myUserProfileResponse.isEnrolledForMarketing
    );
    setInitialUserEmailPreferences(
      myUserProfileResponse.isEnrolledForMarketing !== undefined &&
        myUserProfileResponse.isEnrolledForMarketing
    );
    return myUserProfileResponse;
  }, [getMyUserProfile]);

  const {
    data: userEmailPreferences,
    isLoading: getMyProfileIsLoading,
    isError: getMyProfileIsError,
  } = useOrganizationQuery(
    `/account/user/${user}/subscriptions/email-preferences/`,
    getUserEmailPreferencesDetails,
    {
      refetchOnMount: "always",
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  /* ---------------------------- #TEMPLATES region ---------------------------- */

  const getUserTemplatesSubscriptionsDetails = useCallback(async () => {
    const subscriptionTemplatesResponse = await getUserTemplatesSubscriptions(
      currentPage,
      rowsPerPage
    );
    const staticInitialSubscriptionList: TemplatesInitialState = [];

    subscriptionTemplatesResponse.subscriptionTemplates.forEach((template) => {
      template.mySubscriptions.forEach((subscription: TemplateSubscription) => {
        const completeResult: FormattedTemplateSubscription = {
          templateId: template.templateId,
          templateName: template.templateName,
          subscriptionType: subscription.subscriptionType,
          action: "subscribe",
          sendToWeb: subscription.sendToWeb,
          sendToMobile: subscription.sendToMobile,
        };
        staticInitialSubscriptionList.push(completeResult);
      });
    });
    setCurrentTemplatesSubscriptions([...staticInitialSubscriptionList]);
    setStaticInitialSubscription([...staticInitialSubscription, ...staticInitialSubscriptionList]);
    return subscriptionTemplatesResponse;
  }, [getUserTemplatesSubscriptions, staticInitialSubscription, currentPage, rowsPerPage]);

  const {
    data: subscriptionTemplates,
    isLoading,
    isError,
    refetch,
  } = useOrganizationQuery(
    `/subscriptions/templates/${user}/page_${currentPage}_${rowsPerPage}`,
    getUserTemplatesSubscriptionsDetails,
    {
      refetchOnMount: "always",
      staleTime: 0,
      cacheTime: 0,
      onSuccess(data) {
        setPageData(data.requestedPageLength, data.pageLength, undefined);
      },
      refetchOnWindowFocus: true,
    }
  );

  /* ---------------------------- #DOCUMENT TYPES region ---------------------------- */

  const getUserDocumentTypeSubscriptionsDetails = useCallback(async () => {
    const subscriptionDocumentTypeResponse = await getUserDocumentTypeSubscriptions(
      docTypeCurrentPage,
      docTypeRowsPerPage
    );
    const staticInitialSubscriptionList: DocumentTypesInitialState = [];

    subscriptionDocumentTypeResponse.subscriptionDocumentTypes.forEach((documentType) => {
      documentType.mySubscriptions.forEach((subscription: DocumentTypeSubscription) => {
        const completeResult: FormattedDocumentTypeSubscription = {
          documentTypeId: documentType.documentTypeId,
          documentTypeName: documentType.documentTypeName,
          subscriptionType: subscription.subscriptionType,
          action: "subscribe",
          sendToWeb: subscription.sendToWeb,
          sendToMobile: subscription.sendToMobile,
        };
        staticInitialSubscriptionList.push(completeResult);
      });
    });
    setCurrentDocumentTypeSubscriptions([...staticInitialSubscriptionList]);
    setStaticDocTypeInitialSubscription([
      ...staticDocTypeInitialSubscription,
      ...staticInitialSubscriptionList,
    ]);
    return subscriptionDocumentTypeResponse;
  }, [
    getUserDocumentTypeSubscriptions,
    staticDocTypeInitialSubscription,
    docTypeCurrentPage,
    docTypeRowsPerPage,
  ]);

  const {
    data: subscriptionDocumentType,
    isLoading: documentTypeIsLoading,
    isError: documentTypeIsError,
    refetch: documentTypeRefetch,
  } = useOrganizationQuery(
    `/subscriptions/document-types/${user}/page_${docTypeCurrentPage}_${docTypeRowsPerPage}`,
    getUserDocumentTypeSubscriptionsDetails,
    {
      refetchOnMount: "always",
      staleTime: 0,
      cacheTime: 0,
      onSuccess(data) {
        docTypeSetPageData(data.requestedPageLength, data.pageLength, undefined);
      },
      refetchOnWindowFocus: true,
    }
  );

  /********************************************************************************************
   ********************** CODE SECTION: UPDATE methods implementation ***************************
   *********************************************************************************************/

  /* ---------------------------- #EMAIL PREFERENCES region ---------------------------- */

  const { mutate: saveCurrentEmailPreferences, isLoading: emailPreferencesIsMutating } =
    useMutation(updateUserProfile, {
      onError() {
        setErrorsFoundOnSave([resources.subscriptions.emailPreferenceError]);
      },
    });

  /* ---------------------------- #TEMPLATES region ---------------------------- */

  const {
    mutate: saveCurrentTemplatesSubscriptions,
    isLoading: isMutating,
    reset,
  } = useMutation(updateTemplateSubscription, {
    onError() {
      setErrorsFoundOnSave([...errorsFoundOnSave, resources.subscriptions.itemToUpdateNotFound]);
    },
    onSettled() {
      reset();
    },
  });

  /* ---------------------------- #DOCUMENT TYPES region ---------------------------- */

  const { mutate: saveCurrentDocumentTypesSubscriptions, isLoading: documentTypeIsMutating } =
    useMutation(updateDocumentTypeSubscriptions, {
      onSuccess() {
        if (
          errorsFoundOnSave.length > 0 &&
          errorsFoundOnSave[0] === resources.subscriptions.emailPreferenceError
        ) {
          setToastMessage(resources.subscriptions.emailPreferenceError);
          setShowErrorToast(true);
          setTimeout(() => {
            setShowErrorToast(false);
          }, 4500);
        } else if (
          errorsFoundOnSave.length > 0 &&
          errorsFoundOnSave[0] === resources.subscriptions.itemToUpdateNotFound
        ) {
          setToastMessage(resources.subscriptions.itemToUpdateNotFound);
          setShowErrorToast(true);
          setTimeout(() => {
            setShowErrorToast(false);
          }, 4500);
        } else {
          setToastMessage(resources.subscriptions.changesSaved);
          setShowSuccessToast(true);
          setTimeout(() => {
            refetch();
            documentTypeRefetch();
          }, 100);
          setTimeout(() => {
            setShowSuccessToast(false);
          }, 4500);
        }
      },
      onError() {
        if (
          errorsFoundOnSave.length > 0 &&
          errorsFoundOnSave[0] === resources.subscriptions.emailPreferenceError
        ) {
          setToastMessage(resources.subscriptions.docTypeAndEmailPreferenceErrorsOnSave);
          setShowErrorToast(true);
          setTimeout(() => {
            setShowErrorToast(false);
          }, 4500);
        } else if (
          errorsFoundOnSave.length > 0 &&
          errorsFoundOnSave[0] === resources.subscriptions.itemToUpdateNotFound
        ) {
          setToastMessage(resources.subscriptions.docTypeAndTemplateTableErrorsOnSave);
          setShowErrorToast(true);
          setTimeout(() => {
            setShowErrorToast(false);
          }, 4500);
        } else if (errorsFoundOnSave.length > 1) {
          setToastMessage(resources.subscriptions.errorOnAllSaveMethods);
          setShowErrorToast(true);
          setTimeout(() => {
            setShowErrorToast(false);
          }, 4500);
        } else {
          setToastMessage(resources.subscriptions.documentTypeSaveError);
          setShowErrorToast(true);
          setTimeout(() => {
            setShowErrorToast(false);
          }, 4500);
        }
      },
      onSettled() {
        reset();
      },
    });

  /********************************************************************************************
   **************************** CODE SECTION: Save changes button  *****************************
   *********************************************************************************************/

  const handleSaveClicked = useCallback(() => {
    /* ---------------------------- #EMAIL PREFERENCES region ---------------------------- */

    const prevEmailPreference = initialUserEmailPreferences;
    const currEmailPreference = currentUserEmailPreferences;

    /* ---------------------------- #TEMPLATES region ---------------------------- */

    const templateSubscriptionsChanged: UpdatedTemplateSubscriptionList[] = [];
    for (let i = 0; i < staticInitialSubscription.length; i++) {
      const prevTemplateSubscriptions = staticInitialSubscription[i];
      const currTemplateSubscriptions = currentTemplatesSubscriptions.find(
        (currSub) =>
          currSub.templateId === prevTemplateSubscriptions.templateId &&
          currSub.subscriptionType === prevTemplateSubscriptions.subscriptionType
      );
      let areEqual = true;
      if (currTemplateSubscriptions)
        if (
          prevTemplateSubscriptions.sendToWeb !== currTemplateSubscriptions.sendToWeb ||
          prevTemplateSubscriptions.sendToMobile !== currTemplateSubscriptions.sendToMobile
        ) {
          areEqual = false;
        }

      if (!areEqual && currTemplateSubscriptions) {
        if (
          currTemplateSubscriptions.sendToWeb === false &&
          currTemplateSubscriptions.sendToMobile === false
        ) {
          currTemplateSubscriptions.action = "unsubscribe";
        }
        templateSubscriptionsChanged.push(currTemplateSubscriptions);
      }
    }

    /* ---------------------------- #DOCUMENT TYPES region ---------------------------- */

    const documentTypeSubscriptionsChanged: UpdatedDocumentTypeSubscriptionList[] = [];
    for (let i = 0; i < staticDocTypeInitialSubscription.length; i++) {
      const prevDoctypeSubscriptions = staticDocTypeInitialSubscription[i];
      const currDocTypeSubscriptions = currentDocumentTypeSubscriptions.find(
        (currSub) =>
          currSub.documentTypeId === prevDoctypeSubscriptions.documentTypeId &&
          currSub.subscriptionType === prevDoctypeSubscriptions.subscriptionType
      );
      let areEqual = true;
      if (currDocTypeSubscriptions)
        if (
          prevDoctypeSubscriptions.sendToWeb !== currDocTypeSubscriptions.sendToWeb ||
          prevDoctypeSubscriptions.sendToMobile !== currDocTypeSubscriptions.sendToMobile
        ) {
          areEqual = false;
        }

      if (!areEqual && currDocTypeSubscriptions) {
        if (
          currDocTypeSubscriptions.sendToWeb === false &&
          currDocTypeSubscriptions.sendToMobile === false
        ) {
          currDocTypeSubscriptions.action = "unsubscribe";
        }
        documentTypeSubscriptionsChanged.push(currDocTypeSubscriptions);
      }
    }

    if (prevEmailPreference !== currEmailPreference) {
      saveCurrentEmailPreferences({ isEnrolledForMarketing: currEmailPreference });
    } else {
      saveCurrentEmailPreferences({ isEnrolledForMarketing: prevEmailPreference });
    }
    saveCurrentTemplatesSubscriptions({ updatedSubscriptionList: templateSubscriptionsChanged });
    saveCurrentDocumentTypesSubscriptions({
      updatedSubscriptionList: documentTypeSubscriptionsChanged,
    });
  }, [
    currentUserEmailPreferences,
    initialUserEmailPreferences,
    saveCurrentEmailPreferences,
    currentTemplatesSubscriptions,
    currentDocumentTypeSubscriptions,
    saveCurrentTemplatesSubscriptions,
    saveCurrentDocumentTypesSubscriptions,
    staticInitialSubscription,
    staticDocTypeInitialSubscription,
  ]);

  /********************************************************************************************
   **************************** CODE SECTION: Checkbox OnChange  *******************************
   *********************************************************************************************/

  /* ---------------------------- #EMAIL PREFERENCES region ---------------------------- */

  const handleEmailPreferenceCheckBoxOnChange = useCallback(() => {
    setCurrentUserEmailPreferences((prevState) => {
      return !prevState;
    });
  }, []);

  /* ---------------------------- #TEMPLATES region ---------------------------- */

  const handleCheckBoxOnChange = useCallback(
    (checkBoxCase: string, templateId: string, subscriptionType: TemplateSubscriptionType) => {
      setCurrentTemplatesSubscriptions((prevState) => {
        const newState = [...prevState];
        const index = newState.findIndex(
          (template) =>
            template.templateId === templateId && template.subscriptionType === subscriptionType
        );
        if (index >= 0) {
          if (checkBoxCase === "sendToWeb") {
            newState[index] = {
              ...newState[index],
              sendToWeb: !newState[index].sendToWeb,
            };
            return newState;
          } else if (checkBoxCase === "sendToMobile") {
            newState[index] = {
              ...newState[index],
              sendToMobile: !newState[index].sendToMobile,
            };
            return newState;
          }
        }
        return newState;
      });
    },
    []
  );

  /* ---------------------------- #DOCUMENT TYPES region ---------------------------- */

  const handleDocTypeCheckBoxOnChange = useCallback(
    (
      checkBoxCase: string,
      documentTypeId: string,
      subscriptionType: DocumentTypeSubscriptionType
    ) => {
      setCurrentDocumentTypeSubscriptions((prevState) => {
        const newState = [...prevState];
        const index = newState.findIndex(
          (docType) =>
            docType.documentTypeId === documentTypeId &&
            docType.subscriptionType === subscriptionType
        );
        if (index >= 0) {
          if (checkBoxCase === "sendToWeb") {
            newState[index] = {
              ...newState[index],
              sendToWeb: !newState[index].sendToWeb,
            };
            return newState;
          } else if (checkBoxCase === "sendToMobile") {
            newState[index] = {
              ...newState[index],
              sendToMobile: !newState[index].sendToMobile,
            };
            return newState;
          }
        }
        return newState;
      });
    },
    []
  );

  /********************************************************************************************
   **************************** CODE SECTION: Pagination Part 2  *******************************
   *********************************************************************************************/

  /* ---------------------------- #TEMPLATES region ---------------------------- */

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    paginationDispatch({
      type: "set-current-page",
      value: currentPage - 1,
    });
  }, [currentPage, isPreviousButtonDisabled, paginationDispatch]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    paginationDispatch({
      type: "set-current-page",
      value: currentPage + 1,
    });
  }, [currentPage, isNextButtonDisabled, paginationDispatch]);

  const setPageLength = useCallback(
    (value: HttpGetUserTemplatesSubscriptionsRequest["queryParameters"]["rowsPerPage"]) => {
      paginationDispatch({
        type: "set-rows-per-page",
        value,
      });
    },
    [paginationDispatch]
  );

  const templatesTotalRowCount = subscriptionTemplates
    ? subscriptionTemplates.totalRowCount
    : undefined;

  /* ---------------------------- #DOCUMENT TYPES region ---------------------------- */

  const documentTypePreviousPage = useCallback(() => {
    if (docTypeIsPreviousButtonDisabled) {
      return;
    }

    docTypePaginationDispatch({
      type: "set-current-page",
      value: docTypeCurrentPage - 1,
    });
  }, [docTypeCurrentPage, docTypeIsPreviousButtonDisabled, docTypePaginationDispatch]);

  const documentTypeNextPage = useCallback(() => {
    if (docTypeIsNextButtonDisabled) {
      return;
    }

    docTypePaginationDispatch({
      type: "set-current-page",
      value: docTypeCurrentPage + 1,
    });
  }, [docTypeCurrentPage, docTypeIsNextButtonDisabled, docTypePaginationDispatch]);

  const documentTypeSetPageLength = useCallback(
    (value: HttpGetUserDocumentTypeSubscriptionsRequest["queryParameters"]["rowsPerPage"]) => {
      docTypePaginationDispatch({
        type: "set-rows-per-page",
        value,
      });
    },
    [docTypePaginationDispatch]
  );

  const refetchAll = useCallback(() => {
    refetch();
    documentTypeRefetch();
  }, [documentTypeRefetch, refetch]);

  const documentTypesTotalRowCount = subscriptionDocumentType
    ? subscriptionDocumentType.totalRowCount
    : undefined;

  return {
    breadCrumbEntries: breadcrumbEntries,
    isLoading,
    isError,
    documentTypeIsLoading,
    documentTypeIsError,
    resources,
    organizationNavigate,
    subscriptionTemplates,
    subscriptionDocumentType,
    currentTemplatesSubscriptions,
    currentDocumentTypeSubscriptions,
    showSuccessToast,
    showErrorToast,
    toastMessage,
    handleSaveClicked,
    handleCheckBoxOnChange,
    handleDocTypeCheckBoxOnChange,
    isMutating,
    documentTypeIsMutating,
    previousPage,
    nextPage,
    setPageLength,
    templatesTotalRowCount,
    documentTypePreviousPage,
    documentTypeNextPage,
    documentTypeSetPageLength,
    documentTypesTotalRowCount,
    templateSubscriptionsPageLengthOptions,
    documentTypeSubscriptionsPageLengthOptions,
    currentPage,
    rowsPerPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    docTypeCurrentPage,
    docTypeRowsPerPage,
    docTypeIsNextButtonDisabled,
    docTypeIsPreviousButtonDisabled,
    userEmailPreferences,
    getMyProfileIsLoading,
    getMyProfileIsError,
    emailPreferencesIsMutating,
    currentUserEmailPreferences,
    handleEmailPreferenceCheckBoxOnChange,
    refetchAll,
  };
}

/**************************************************************************************************************************************************  ************************************************************* HOOK ********************************************************************************/

/* ---------------------------- #Upgrade Plan Page ---------------------------- */

export type AccountPlan = {
  title: string;
  priceMonth: number;
  priceYear: number;
  rawAnnualPrice: number;
  features: Array<string>;
  plan: "professional" | "business" | "enterprise";
  activePlan?: string;
};

export function useUpgradePlanPage() {
  const organizationNavigate = useOrganizationNavigate();
  const [showYearly, setShowYearly] = useState(false);
  const { breakpoint } = useDesignBreakpoint();
  const upgradePlanSetting = usePlanSetting();
  const { resources } = useAppTranslation();

  const breakPointSmallDuo = breakpoint === "small" || breakpoint === "medium";
  const breakpointFluid =
    breakpoint === "medium" || breakpoint === "small" || breakpoint === "large";
  const layout = breakpoint !== "small" ? [1, 1, 1] : [1];

  const toPayment = useCallback(
    (cardData: AccountPlan) => {
      const subscription = showYearly
        ? { method: "Yearly", amount: cardData.plan }
        : { method: "Monthly", amount: cardData.plan };

      organizationNavigate(
        organizationLinkTemplates.paymentDetails(subscription.amount, subscription.method)
      );
    },
    [organizationNavigate, showYearly]
  );

  const subscriptionData: AccountPlan[] = useMemo(() => {
    return [
      {
        title: resources.pricingPage.professionalPlan,
        priceMonth: prices.professional.value,
        priceYear: prices.professional.annually,
        rawAnnualPrice: prices.professional.rawAnnually,
        features: [
          resources.pricingPage.pricingCards.professional.feature1,
          resources.pricingPage.pricingCards.professional.feature2,
          resources.pricingPage.pricingCards.professional.feature3,
          resources.pricingPage.pricingCards.professional.feature4,
          resources.pricingPage.pricingCards.professional.feature5,
          resources.pricingPage.pricingCards.professional.feature6(
            prices.enterprise.collectionPerUserPerMonth
          ),
        ],
        plan: "professional",
      },
      {
        title: resources.pricingPage.businessPlan,
        priceMonth: prices.business.value,
        priceYear: prices.business.annually,
        rawAnnualPrice: prices.business.rawAnnually,
        features: [
          resources.pricingPage.pricingCards.business.feature1,
          resources.pricingPage.pricingCards.business.feature2,
          resources.pricingPage.pricingCards.business.feature3,
          resources.pricingPage.pricingCards.business.feature4,
          resources.pricingPage.pricingCards.business.feature5,
          resources.pricingPage.pricingCards.business.feature6,
          resources.pricingPage.pricingCards.business.feature7,
          resources.pricingPage.pricingCards.business.feature8,
          resources.pricingPage.pricingCards.business.feature9,
          resources.pricingPage.pricingCards.business.feature10,
          resources.pricingPage.pricingCards.business.feature11,
        ],
        plan: "business",
      },
      {
        title: resources.pricingPage.enterprisePlan,
        priceMonth: prices.enterprise.value,
        priceYear: prices.enterprise.annually,
        rawAnnualPrice: prices.enterprise.rawAnnually,
        features: [
          resources.pricingPage.pricingCards.enterprise.feature1,
          resources.pricingPage.pricingCards.enterprise.feature2,
          resources.pricingPage.pricingCards.enterprise.feature3,
          resources.pricingPage.pricingCards.enterprise.feature4,
          resources.pricingPage.pricingCards.enterprise.feature5,
          resources.pricingPage.pricingCards.enterprise.feature6(
            prices.enterprise.compositionsPerUserPerMonth
          ),
          resources.pricingPage.pricingCards.enterprise.feature7(
            prices.enterprise.operationsPerUserPerMonth
          ),
          resources.pricingPage.pricingCards.enterprise.feature8(
            prices.enterprise.integrationsPerUserPerMonth
          ),
          resources.pricingPage.pricingCards.enterprise.feature9,
        ],
        plan: "enterprise",
      },
    ];
  }, [
    resources.pricingPage.businessPlan,
    resources.pricingPage.enterprisePlan,
    resources.pricingPage.pricingCards.business.feature1,
    resources.pricingPage.pricingCards.business.feature10,
    resources.pricingPage.pricingCards.business.feature11,
    resources.pricingPage.pricingCards.business.feature2,
    resources.pricingPage.pricingCards.business.feature3,
    resources.pricingPage.pricingCards.business.feature4,
    resources.pricingPage.pricingCards.business.feature5,
    resources.pricingPage.pricingCards.business.feature6,
    resources.pricingPage.pricingCards.business.feature7,
    resources.pricingPage.pricingCards.business.feature8,
    resources.pricingPage.pricingCards.business.feature9,
    resources.pricingPage.pricingCards.enterprise,
    resources.pricingPage.pricingCards.professional,
    resources.pricingPage.professionalPlan,
  ]);

  const planSelection: SelectListProps["options"] = useMemo(() => {
    return [
      {
        label: `${resources.pricingPage.professionalPlan} / ${resources.upgradePage.monthly}`,
        value: "Professional / monthly",
      },
      {
        label: `${resources.pricingPage.businessPlan} / ${resources.upgradePage.monthly}`,
        value: "Business / monthly",
      },
      {
        label: `${resources.pricingPage.enterprisePlan} / ${resources.upgradePage.monthly}`,
        value: "Enterprise / monthly",
      },
      {
        label: `${resources.pricingPage.professionalPlan} / ${resources.upgradePage.yearly}`,
        value: "Professional / yearly",
      },
      {
        label: `${resources.pricingPage.businessPlan} / ${resources.upgradePage.yearly}`,
        value: "Business / yearly",
      },
      {
        label: `${resources.pricingPage.enterprisePlan} / ${resources.upgradePage.yearly}`,
        value: "Enterprise / yearly",
      },
    ];
  }, [
    resources.pricingPage.businessPlan,
    resources.pricingPage.enterprisePlan,
    resources.pricingPage.professionalPlan,
    resources.upgradePage.monthly,
    resources.upgradePage.yearly,
  ]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return breakpoint === "small"
      ? [
          {
            type: "url",
            name: resources.account,
            url: organizationLinkTemplates.account(),
          },
          { type: "text", name: resources.upgradePlan },
        ]
      : [
          { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
          {
            type: "url",
            name: resources.account,
            url: organizationLinkTemplates.account(),
          },
          { type: "text", name: resources.upgradePlan },
        ];
  }, [breakpoint, resources]);

  return {
    breakpoint,
    breakpointFluid,
    showYearly,
    setShowYearly,
    toPayment,
    subscriptionData,
    breadCrumbEntries,
    upgradePlanSetting,
    layout,
    planSelection,
    breakPointSmallDuo,
  };
}

/* ---------------------------- #Payment Page ---------------------------- */

export function usePaymentPage() {
  const { resources } = useAppTranslation();
  const { search } = useLocation();
  const { amountOfSubscriptions, subscriptionPaymentMethods } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      amountOfSubscriptions: params.get("amount"),
      subscriptionPaymentMethods: params.get("methodsofpayment"),
    };
  }, [search]);

  const [subscription, setSubscription] = useState(subscriptionPaymentMethods);
  const [isSelectedSubscription, setSelectedSubscription] = useState(
    resources.paymentPage.paidMonthly
  );
  const { breakpoint } = useDesignBreakpoint();
  const organizationNavigate = useOrganizationNavigate();

  const backToTheSubscriptionsPage = useCallback(() => {
    organizationNavigate(organizationLinkTemplates.upgradePlan());
  }, [organizationNavigate]);

  const selectedSubscription = useCallback(() => {
    const subscription: Record<string, string[]> = {
      professional: [
        resources.landingPageNew.cards.bulletPoints.integrationOnBase,
        resources.landingPageNew.cards.bulletPoints.barCodeIdentification,
        resources.landingPageNew.cards.bulletPoints.massiveBatchDigitization,
      ],
      business: [
        resources.landingPageNew.cards.bulletPoints.storageDatabases,
        resources.landingPageNew.cards.bulletPoints.scanOnlineAndIndex,
        resources.landingPageNew.cards.bulletPoints.authenticityProof,
      ],
      enterprise: [
        resources.landingPageNew.cards.bulletPoints.autoDocGeneration,
        resources.landingPageNew.cards.bulletPoints.emailNotificationAccountStatements,
        resources.landingPageNew.cards.bulletPoints.distributionToDigitalMedia,
      ],
    };

    if (amountOfSubscriptions) {
      return subscription[amountOfSubscriptions];
    } else {
      return [];
    }
  }, [amountOfSubscriptions, resources]);

  const subscriptionsPlan = useMemo(
    () => ({
      Professional: {
        monthly: resources.paymentPage.fifty,
        yearly: resources.paymentPage.fiveTenPerYear,
      },
      Business: {
        monthly: resources.paymentPage.hundredPerMonth,
        yearly: resources.paymentPage.tenTwentyPerYear,
      },
      Enterprise: {
        monthly: resources.paymentPage.threeHundredPerMonth,
        yearly: resources.paymentPage.thirtySixtyPerYear,
      },
    }),
    [resources]
  );

  const subscriptionDetails = useMemo(
    () => ({
      plan: "",
      formOfPayment: "",
    }),
    []
  );

  if (
    amountOfSubscriptions &&
    subscriptionsPlan.hasOwnProperty(amountOfSubscriptions as keyof typeof subscriptionsPlan)
  ) {
    subscriptionDetails.plan =
      subscription === resources.upgradePage.monthly
        ? subscriptionsPlan[amountOfSubscriptions as keyof typeof subscriptionsPlan].monthly
        : subscriptionsPlan[amountOfSubscriptions as keyof typeof subscriptionsPlan].yearly;
    subscriptionDetails.formOfPayment =
      subscription === resources.upgradePage.monthly
        ? resources.upgradePage.monthly
        : resources.upgradePage.yearly;
  }

  const handlePlanChange = useCallback((event: { value: string }) => {
    if (!(event.value === " ")) {
      setSubscription(event.value);
      setSelectedSubscription(event.value);
    }
  }, []);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return breakpoint === "small"
      ? [
          { type: "text", name: resources.upgradePlan },
          { type: "text", name: amountOfSubscriptions as string },
        ]
      : [
          { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
          {
            type: "url",
            name: resources.account,
            url: organizationLinkTemplates.account(),
          },
          { type: "text", name: resources.upgradePlan },
          { type: "text", name: amountOfSubscriptions as string },
        ];
  }, [amountOfSubscriptions, breakpoint, resources]);

  return {
    selectedSubscription,
    isSelectedSubscription,
    handlePlanChange,
    breakpoint,
    backToTheSubscriptionsPage,
    amountOfSubscriptions,
    subscriptionDetails,
    subscription,
    breadCrumbEntries,
  };
}
