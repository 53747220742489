import "./EditableItem.css";

import {
  Box,
  DataElementInput,
  DataElementInputProps,
  DesignBreakpointType,
  FormControllerContext,
  IconButton,
  Text,
  useColors,
  useEscapeKey,
  useFormController,
} from "@prodoctivity/design-system";
import type { ContextField, RecordContextStack } from "@prodoctivity/shared/src/index-types";

import { replaceActiveContent } from "@prodoctivity/shared";
import type momentType from "moment";
import { useCallback } from "react";

type Props = {
  instanceFullPath: string;
  typeValue: DataElementInputProps["typeValue"];
  contextField: ContextField;
  i18n: (key: string) => string;
  resources: DataElementInputProps["resources"];
  onChange: DataElementInputProps["onChange"];
  onBlur: DataElementInputProps["onBlur"];
  onExit: () => void;
  moment: typeof momentType;
  componentBreakpoint: DesignBreakpointType;
  getContextValueAsString: FormControllerContext["getContextValueAsString"];
  recordContextStack: RecordContextStack;
};

export function EditableItem({
  instanceFullPath,
  typeValue,
  contextField,
  i18n,
  resources,
  onChange,
  onBlur,
  onExit,
  moment,
  componentBreakpoint,
  getContextValueAsString,
  recordContextStack,
}: Props) {
  const { colors } = useColors();
  const { context } = useFormController();

  const localGetDynamicValue = useCallback(
    (text: string) => {
      return replaceActiveContent(context, recordContextStack, text);
    },
    [context, recordContextStack]
  );

  const localGetContextValueAsString = useCallback(
    (k: string) => {
      return getContextValueAsString(k, recordContextStack);
    },
    [getContextValueAsString, recordContextStack]
  );

  useEscapeKey(onExit);

  return (
    <Box
      display="flex"
      width={componentBreakpoint === "small" ? "100%" : "33%"}
      gap={2}
      borderColor={colors.primary}
      direction="column"
    >
      <Box display="flex">
        <Box display="flex" flex="grow">
          <Text weight="bold">{contextField.properties.label}: </Text>
        </Box>
        <Box display="flex" flex="shrink" color={colors.neutral400} padding={2} rounding={6}>
          <IconButton
            accessibilityLabel={i18n("close")}
            onClick={onExit}
            icon="x"
            color={colors.primary}
          />
        </Box>
      </Box>
      <Box display="block" fit={true}>
        <DataElementInput
          componentBreakpoint={componentBreakpoint}
          contextField={contextField}
          instanceFullPath={instanceFullPath}
          getContextValueAsString={localGetContextValueAsString}
          getDynamicValue={localGetDynamicValue}
          moment={moment}
          onChange={onChange}
          resources={resources}
          typeValue={typeValue}
          disabled={false}
          hideWrapper={true}
          mode="summary"
          purpose="real-time"
          onBlur={onBlur}
        />
      </Box>
    </Box>
  );
}
