import { Box, Text, useColors } from "@prodoctivity/design-system";
import type { DocumentGeneratedLogEntry, TemplateVersionId } from "@prodoctivity/types";

import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../../link-templates";
import { OrganizationNavLink } from "../../OrganizationLink";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  id: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  entry: DocumentGeneratedLogEntry;
  entryCount: number;
};

export const DocumentGeneratedEntry: FunctionComponent<Props> = ({
  id,
  entry,
  entryCount,
  fullWidth,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const documentCount = entryCount;

  const templateList = entry.entries.reduce(
    (
      list: Array<{ templateVersionId: TemplateVersionId; name: string; documentCount: number }>,
      next
    ) => {
      next.templates.forEach((template) => {
        const found = list.find((item) => item.templateVersionId === template.templateVersionId);
        if (found) {
          found.documentCount += template.documentCount;
        } else {
          list.push({
            templateVersionId: template.templateVersionId,
            name: template.name,
            documentCount: template.documentCount,
          });
        }
      });

      return list;
    },
    []
  );

  const hasSingleDocument = templateList.length === 1 && templateList[0].documentCount === 1;
  return (
    <Box display="flex" direction={fullWidth ? "row" : "column"} flex="grow">
      {fullWidth ? (
        <Box marginEnd={6}>
          <PublicProfile username={entry.username} onlyImage={true} sizeImage={48} />
        </Box>
      ) : null}

      <Box display="flex" direction="column">
        <Text inline={true} size="200">
          <Box display="flex" direction="column">
            <Text inline={true} size="200">
              <Box display="flex" direction="column">
                <PublicProfile username={entry.username} onlyImage={false} size="300" />{" "}
                <Text inline={true} size="200">
                  {" "}
                  {resources.activities.entryTypes.documentGenerated} {`(${documentCount})`}{" "}
                </Text>
                {hasSingleDocument && (
                  <Text inline={true} size="200" color={colors.primary}>
                    {resources.activities.entryTypes.documentGenerated + ": "}
                    <OrganizationNavLink
                      to={`/templates/versions/${templateList[0].templateVersionId}/details`}
                    >
                      <Text
                        inline={true}
                        weight="bold"
                        size="100"
                        color={colors.primary200}
                        title={templateList[0].name}
                      >
                        {templateList[0].name}
                      </Text>
                    </OrganizationNavLink>
                  </Text>
                )}
              </Box>
            </Text>
          </Box>
        </Text>
        {hasSingleDocument ? (
          <Box>
            <OrganizationNavLink
              to={organizationLinkTemplates.documentGenerationStatus(
                entry.entries[0].generationToken
              )}
            >
              <Text size="200" color={colors.primary}>
                {entry.entries[0].generationToken}
              </Text>
            </OrganizationNavLink>
          </Box>
        ) : fullWidth ? (
          <Box>
            {(entry.entries || []).map((entry) => {
              if (entry.templates && entry.templates.length > 0) {
                return (
                  <OrganizationNavLink
                    key={entry.generationToken}
                    to={organizationLinkTemplates.documentGenerationStatus(entry.generationToken)}
                  >
                    <Text size="200" color={colors.primary}>
                      {entry.templates[0].name}
                    </Text>
                  </OrganizationNavLink>
                );
              } else {
                return (
                  <Text size="200" color={colors.primary} key={entry.generationToken}>
                    {entry.generationToken}
                  </Text>
                );
              }
            })}
          </Box>
        ) : (
          <Box>
            <OrganizationNavLink to={`/activities/${id}`}>
              <Text size="200" color={colors.primary}>
                {resources.activities.viewEntry}
              </Text>
            </OrganizationNavLink>
          </Box>
        )}
      </Box>
    </Box>
  );
};
