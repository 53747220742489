import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const FolderSvgIcon: FunctionComponent<Props> = ({
  color,
  width = 25,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.3497 12.4053L21.3336 17.2609C20.8506 18.0265 19.9812 18.5 19.0259 18.5H2.37835C1.83095 18.5 1.38014 18.0769 1.38014 17.5631C1.38014 17.402 1.42308 17.2307 1.51968 17.0897L4.53578 12.2341C5.01878 11.4685 5.88819 10.995 6.84347 10.995H23.5018C24.0492 10.995 24.5 11.4181 24.5 11.9319C24.5 12.1031 24.4571 12.2643 24.3605 12.4053H24.3497ZM6.83274 9.74585H20.4964V7.87213C20.4964 6.83452 19.6055 5.9984 18.5 5.9984H11.8345L9.17263 3.50009H2.49642C1.39088 3.49002 0.5 4.32614 0.5 5.36375V16.2334L3.37657 11.6095C4.09571 10.4611 5.40519 9.74585 6.83274 9.74585Z"
        fill={color || "#5A5A5A"}
      />
    </svg>
  );
};
