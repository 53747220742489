import {
  ColorSchemeProviderProps,
  ColorSchemeProvider as GestaltColorSchemeProvider,
} from "gestalt";
import { FunctionComponent, createContext, useContext, useMemo } from "react";

import { ColorConfig } from "../utils";

export type ColorKeys =
  | "black600"
  | "black900"
  | "error"
  | "other"
  | "other100"
  | "other200"
  | "other300"
  | "other400"
  | "other500"
  | "other600"
  | "success"
  | "lightWash"
  | "neutral50"
  | "neutral100"
  | "neutral200"
  | "neutral250"
  | "neutral300"
  | "neutral400"
  | "neutral500"
  | "neutral600"
  | "neutral700"
  | "neutral800"
  | "neutral900"
  | "primary"
  | "primary100"
  | "primary200"
  | "primary300"
  | "primary400"
  | "primary600"
  | "primary700"
  | "primaryHover0"
  | "secondary"
  | "secondary300"
  | "secondary400"
  | "subtle"
  | "white";

export type ProDoctivityColorBundle = Record<ColorKeys, ColorConfig>;

const ProDoctivityLightColors: ProDoctivityColorBundle = {
  black600: {
    type: "black",
    scale: 600,
  },
  black900: {
    type: "black",
    scale: 900,
  },
  error: {
    type: "other",
    scale: 100,
  },
  other: {
    type: "other",
    scale: 0,
  },
  other100: {
    type: "other",
    scale: 100,
  },
  other200: {
    type: "other",
    scale: 200,
  },
  other300: {
    type: "other",
    scale: 300,
  },
  other400: {
    type: "other",
    scale: 400,
  },
  other500: {
    type: "other",
    scale: 500,
  },
  other600: {
    type: "other",
    scale: 600,
  },
  success: {
    type: "other",
    scale: 0,
  },
  lightWash: { type: "neutral", scale: 100 },
  neutral50: { type: "neutral", scale: 50 },
  neutral100: { type: "neutral", scale: 100 },
  neutral200: { type: "neutral", scale: 200 },
  neutral250: { type: "neutral", scale: 250 },
  neutral300: { type: "neutral", scale: 300 },
  neutral400: { type: "neutral", scale: 400 },
  neutral500: { type: "neutral", scale: 500 },
  neutral600: { type: "neutral", scale: 600 },
  neutral700: { type: "neutral", scale: 700 },
  neutral800: { type: "neutral", scale: 800 },
  neutral900: { type: "neutral", scale: 900 },

  primary100: {
    type: "primary",
    scale: 100,
  },
  primary200: {
    type: "primary",
    scale: 200,
  },
  primary300: {
    type: "primary",
    scale: 300,
  },
  primary400: {
    type: "primary",
    scale: 400,
  },
  primary600: {
    type: "primary",
    scale: 600,
  },
  primary: {
    type: "primary",
    scale: 500,
  },
  primary700: {
    type: "primary",
    scale: 700,
  },
  primaryHover0: { type: "primary", scale: 0 },
  secondary400: {
    type: "secondary",
    scale: 400,
  },
  secondary: {
    type: "secondary",
    scale: 500,
  },
  secondary300: {
    type: "secondary",
    scale: 500,
  },
  subtle: {
    type: "neutral",
    scale: 900,
  },
  white: {
    type: "white",
    scale: 0,
  },
};

const ProDoctivityDarkColors: ProDoctivityColorBundle = {
  black600: {
    type: "white",
    scale: 0,
  },
  black900: {
    type: "white",
    scale: 0,
  },
  error: {
    type: "other",
    scale: 100,
  },
  other: {
    type: "other",
    scale: 0,
  },
  other100: {
    type: "other",
    scale: 100,
  },
  other200: {
    type: "other",
    scale: 200,
  },
  other300: {
    type: "other",
    scale: 300,
  },
  other400: {
    type: "other",
    scale: 400,
  },
  other500: {
    type: "other",
    scale: 500,
  },
  other600: {
    type: "other",
    scale: 600,
  },
  success: {
    type: "other",
    scale: 0,
  },
  lightWash: { type: "neutral", scale: 100 },
  neutral50: { type: "neutral", scale: 50 },
  neutral100: { type: "neutral", scale: 100 },
  neutral200: { type: "transparent", scale: 0 },
  neutral250: { type: "neutral", scale: 250 },
  neutral300: { type: "transparent", scale: 0 },
  neutral400: { type: "transparent", scale: 0 },
  neutral500: { type: "transparent", scale: 0 },
  neutral600: { type: "transparent", scale: 0 },
  neutral700: { type: "transparent", scale: 0 },
  neutral800: { type: "transparent", scale: 0 },
  neutral900: { type: "transparent", scale: 0 },

  primary100: {
    type: "primary",
    scale: 100,
  },
  primary200: {
    type: "primary",
    scale: 200,
  },
  primary300: {
    type: "primary",
    scale: 300,
  },
  primary400: {
    type: "primary",
    scale: 400,
  },
  primary600: {
    scale: 600,
    type: "primary",
  },
  primary: {
    type: "primary",
    scale: 500,
  },
  primary700: {
    type: "primary",
    scale: 700,
  },
  primaryHover0: { type: "primary", scale: 0 },
  secondary400: {
    type: "secondary",
    scale: 400,
  },
  secondary: {
    type: "secondary",
    scale: 500,
  },
  secondary300: {
    type: "secondary",
    scale: 300,
  },
  subtle: {
    type: "neutral",
    scale: 900,
  },
  white: { type: "transparent", scale: 0 },
};

export type ColorSettings = {
  colors: ProDoctivityColorBundle;
  scheme: ColorSchemeProviderProps["colorScheme"];
};

export const ColorContext = createContext<ColorSettings>({
  colors: ProDoctivityLightColors,
  scheme: "light",
});

export type { ColorSchemeProviderProps };

export const ColorSchemeProvider: FunctionComponent<ColorSchemeProviderProps> = ({
  colorScheme,
  children,
}) => {
  const colorSettings: ColorSettings = useMemo(() => {
    if (colorScheme === "light" || colorScheme === "userPreference") {
      return {
        colors: ProDoctivityLightColors,
        scheme: colorScheme,
      };
    } else {
      return {
        colors: ProDoctivityDarkColors,
        scheme: colorScheme,
      };
    }
  }, [colorScheme]);

  return (
    <GestaltColorSchemeProvider colorScheme={colorScheme} id="color_scheme_provider">
      <ColorContext.Provider value={colorSettings}>{children}</ColorContext.Provider>
    </GestaltColorSchemeProvider>
  );
};

export function useColors(): ColorSettings {
  const colorSettings = useContext(ColorContext);

  return colorSettings;
}

/*

Dark mode defaults:

{
  name: "darkMode",
  colorRed0: "#e60023",
  colorRed100: "#ff5247",
  colorRed100Active: "#b8001b",
  colorRed100Hovered: "#cf001f",
  colorGray0: "#030303",
  colorGray0Active: "#1f1f1f",
  colorGray0Hovered: "#121212",
  colorGray50: "#212121",
  colorGray100: "#404040",
  colorGray100Active: "#666",
  colorGray100Hovered: "#535353",
  colorGray150: "#585858",
  colorGray150Hovered: "#535353",
  colorGray200: "#ababab",
  colorGray200Active: "#9b9b9b",
  colorGray200Hovered: "#919191",
  colorGray300: "#efefef",
  colorGray400: "#fff",
  colorTransparentDarkGray: "rgba(255, 255, 255, 0.8)",
  colorTransparentGray60: "rgba(250, 250, 250, 0.5)",
  colorTransparentGray100: "rgba(250, 250, 250, 0.6)",
  colorTransparentGray500: "rgba(0, 0, 0, 0.5)",
  colorTransparentWhite: "rgba(51, 51, 51, 0.8)",
  blueHovered: "#4a8ad4",
  blueActive: "#4a85c9",
  colorDataVisualization10: "#007A72",
  colorDataVisualization11: "#f76593",
  colorDataVisualization12: "#ffc58f",
  colorDataVisualizationPrimary: "#75bfff",
  colorDataVisualizationSuccessGraph: "#6bec8c",
  colorDataVisualizationSuccessText: "#39d377",
  colorDataVisualizationErrorGraph: "#f47171",
  colorDataVisualizationErrorText: "#eb4242",
  colorDataVisualization01: "#005fcb",
  colorDataVisualization02: "#75e4d5",
  colorDataVisualization03: "#B190FF",
  colorDataVisualization04: "#FDA600",
  colorDataVisualization05: "#75bfff",
  colorDataVisualization06: "#de2c62",
  colorDataVisualization07: "#a4f9ac",
  colorDataVisualization08: "#812ae7",
  colorDataVisualization09: "#ff5b45",
}


Light mode defaults:

{
  name: "lightMode",
  colorRed0: "#ff5247",
  colorRed100: "#e60023",
  colorRed100Active: "#a3081a",
  colorRed100Hovered: "#ad081b",
  colorGray0: "#fff",
  colorGray0Active: "#e0e0e0",
  colorGray0Hovered: "#f0f0f0",
  colorGray50: "#fff",
  colorGray100: "#efefef",
  colorGray100Active: "#dadada",
  colorGray100Hovered: "#e2e2e2",
  colorGray150: "#ddd",
  colorGray150Hovered: "#d0d0d0",
  colorGray200: "#767676",
  colorGray200Active: "#828282",
  colorGray200Hovered: "#878787",
  colorGray300: "#111",
  colorGray400: "#000",
  colorTransparentDarkGray: "rgba(51, 51, 51, 0.8)",
  colorTransparentGray60: "rgba(0, 0, 0, 0.06)",
  colorTransparentGray100: "rgba(0, 0, 0, 0.1)",
  colorTransparentGray500: "rgba(0, 0, 0, 0.1)",
  colorTransparentWhite: "rgba(255, 255, 255, 0.8)",
  blueHovered: "#4a8ad4",
  blueActive: "#4a85c9",
  colorDataVisualization10: "#005062",
  colorDataVisualization11: "#de2c62",
  colorDataVisualization12: "#660e00",
  colorDataVisualizationPrimary: "#0081fe",
  colorDataVisualizationSuccessGraph: "#008753",
  colorDataVisualizationSuccessText: "#005f3e",
  colorDataVisualizationErrorGraph: "#ed0000",
  colorDataVisualizationErrorText: "#cc0000",
  colorDataVisualization01: "#003c96",
  colorDataVisualization02: "#11a69c",
  colorDataVisualization03: "#924af7",
  colorDataVisualization04: "#d17711",
  colorDataVisualization05: "#0081fe",
  colorDataVisualization06: "#ff5383",
  colorDataVisualization07: "#00ab55",
  colorDataVisualization08: "#400387",
  colorDataVisualization09: "#f2681f",
}

*/
