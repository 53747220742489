import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const SocialMediaIconInstagram: FunctionComponent<Props> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g transform="translate(-78.124)">
        <g transform="translate(78.083)">
          <circle cx="15" cy="15" r="15" transform="translate(0.041)" fill="#fff" />
          <path
            d="M5.705,5.019A2.927,2.927,0,1,0,8.632,7.946,2.922,2.922,0,0,0,5.705,5.019Zm0,4.83a1.9,1.9,0,1,1,1.9-1.9A1.906,1.906,0,0,1,5.705,9.848ZM9.434,4.9a.683.683,0,1,1-.683-.683A.681.681,0,0,1,9.434,4.9Zm1.938.693A3.378,3.378,0,0,0,10.451,3.2a3.4,3.4,0,0,0-2.392-.922c-.943-.053-3.767-.053-4.71,0A3.4,3.4,0,0,0,.957,3.2,3.389,3.389,0,0,0,.035,5.589c-.053.943-.053,3.767,0,4.71a3.378,3.378,0,0,0,.922,2.392,3.4,3.4,0,0,0,2.392.922c.943.053,3.767.053,4.71,0a3.378,3.378,0,0,0,2.392-.922,3.4,3.4,0,0,0,.922-2.392c.053-.943.053-3.765,0-4.707Zm-1.218,5.719A1.926,1.926,0,0,1,9.07,12.4a12.581,12.581,0,0,1-3.365.229A12.679,12.679,0,0,1,2.34,12.4a1.926,1.926,0,0,1-1.085-1.085,12.581,12.581,0,0,1-.229-3.365,12.679,12.679,0,0,1,.229-3.365A1.926,1.926,0,0,1,2.34,3.5a12.581,12.581,0,0,1,3.365-.229A12.679,12.679,0,0,1,9.07,3.5a1.926,1.926,0,0,1,1.085,1.085,12.581,12.581,0,0,1,.229,3.365A12.574,12.574,0,0,1,10.155,11.311Z"
            transform="translate(8.901 6.898)"
            fill="#1e394e"
          />
        </g>
      </g>
    </svg>
  );
};
