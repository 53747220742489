import { getContextField, getContextRecord } from "@prodoctivity/shared";
import type {
  ContextField,
  ContextRecord,
  TemplateContextDefinition,
  TemplateContextRecordHolder,
  TemplateWizardDefinition,
  TemplateWizardField,
  TemplateWizardFieldIsRecord,
} from "@prodoctivity/shared/src/index-types";
import {
  createDataElementLayoutItem,
  createGroupLayoutItem,
  createTopicLayoutItem,
} from "../ProDoctivityFormDesigner/utils";
import {
  FormLayout,
  GroupLayouts,
  ProDoctivityFormLayout,
  ProDoctivityFormLayoutItem,
} from "./types";

const fieldToLayout = (
  field: TemplateWizardField,
  y: number,
  getContextField: (fullPath: string) => ContextField | undefined,

  parentInstanceFullPath: string | undefined
) => {
  // if (!!parentInstanceFullPath) {
  //   name = parentInstanceFullPath.split("/")[field.key.split("/").length - 1];
  // }

  const contextField = getContextField(field.key);
  if (!contextField) {
    return undefined;
  }

  const dataElementLayoutItem = createDataElementLayoutItem({
    parentInstanceFullPath,
    contextField,
    y: y,
    controlSize: field.isRecord ? "Normal" : field.properties?.controlSize ?? "Large",
  });
  return dataElementLayoutItem;
};

const pagesToLayout = (
  wizardDefinition: TemplateWizardDefinition,
  contextDefinition: TemplateContextDefinition,
  pageKey: string | undefined
): FormLayout => {
  const findContextField = (fp: string) => getContextField(contextDefinition, fp);
  const pages = wizardDefinition.pages.filter((p) => pageKey === undefined || p.key === pageKey);

  if (!pages.length) {
    return {
      layout: [],
      groupLayouts: {},
    };
  }

  const sections = pages.flatMap((p) => p.sections);

  const layout: ProDoctivityFormLayout = [];
  const groupLayouts: GroupLayouts = {};

  const yWrapper = { value: 0 };

  sections.forEach((section) => {
    const fields = section.fields;
    const layoutName = section.label;
    const layoutLabel = section.label;
    const parentFullPath = undefined;
    if (fields.length > 0) {
      const topic = createTopicLayoutItem({
        parentFullPath,
        name: layoutName,
        label: layoutLabel,
        y: yWrapper.value++,
      });

      layout.push(topic);
      const xWrapper = { value: 0 };

      fields.forEach((field) => {
        if (field.isRecord === true) {
          xWrapper.value = 0;

          const groupItemLayout = templateWizardFieldIsRecordToProDoctivityFormLayout(
            "",
            field,
            groupLayouts,
            getContextRecord,
            contextDefinition,
            xWrapper,
            yWrapper
          );
          if (groupItemLayout) {
            layout.push(groupItemLayout);
          }
        } else if (
          layout.findIndex((i) => i.type === "dataElement" && i.fullPath === field.key) < 0
        ) {
          const fieldLayout = fieldToLayout(field, yWrapper.value++, findContextField, undefined);
          if (fieldLayout) {
            fieldLayout.x = xWrapper.value;
            xWrapper.value = xWrapper.value === 0 ? 4 : 0;
            layout.push(fieldLayout);
          }
        }
      });
    }
  });

  return {
    layout,
    groupLayouts,
  };
};

function templateWizardFieldIsRecordToProDoctivityFormLayout(
  parentFullPath: string,
  wizardRecord: TemplateWizardFieldIsRecord,
  groupLayouts: GroupLayouts,
  getContextRecord: (
    holder: TemplateContextRecordHolder,
    fullPath: string
  ) => ContextRecord | undefined,
  contextDefinition: TemplateContextDefinition,
  xWrapper: { value: number },
  yWrapper: { value: number }
): ProDoctivityFormLayoutItem | undefined {
  xWrapper.value = 0;

  const findContextField = (fp: string) => getContextField(contextDefinition, fp);

  const contextRecord = getContextRecord(contextDefinition, wizardRecord.key);
  if (!contextRecord) {
    return undefined;
  }

  let xG = 0;
  let yG = 0;
  const groupLayout: ProDoctivityFormLayout = wizardRecord.fields
    .map((f) => {
      if (f.isRecord) {
        const childGroupLayoutItem = templateWizardFieldIsRecordToProDoctivityFormLayout(
          wizardRecord.key,
          f,
          groupLayouts,
          getContextRecord,
          contextDefinition,
          xWrapper,
          yWrapper
        );

        return childGroupLayoutItem;
      } else {
        const fieldG = fieldToLayout(f, yG++, findContextField, wizardRecord.key);
        if (fieldG) {
          fieldG.x = xG;
          xG = xG === 0 ? 4 : 0;
        }
        return fieldG;
      }
    })
    .reduce((acc: ProDoctivityFormLayout, next) => {
      if (next) {
        acc.push(next);
      }

      return acc;
    }, []);
  groupLayouts[wizardRecord.key] = groupLayout;

  const groupItemLayout = createGroupLayoutItem({
    parentFullPath,
    name: contextRecord.name,
    label: wizardRecord.label,
    y: yWrapper.value++,
  });

  return groupItemLayout;
}

export const wizardDefinitionToLayout = (
  wizardDefinition: TemplateWizardDefinition,
  contextDefinition: TemplateContextDefinition,
  pageKey: string | undefined
): FormLayout => {
  const formLayout: FormLayout = pagesToLayout(wizardDefinition, contextDefinition, pageKey);

  return formLayout;
};
