import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";

/**
 *  convert list of string in items for combo box
 *
 * @param devices
 * @returns  Array<ComboBoxItemType>
 */
export const itemOptions = (devices: Array<string>, defaultOption: string): ComboBoxItemType[] => {
  const items = devices;
  if (items.length === 0) {
    return [];
  }
  if (!items.includes(defaultOption)) {
    items.unshift(defaultOption);
  }
  return items.map((device) => ({ label: device, value: device }));
};
