import { SheetMobileProps } from "gestalt";
import { FunctionComponent, useState } from "react";
import { Box } from "../Box";
import { DeviceTypeProvider } from "../DeviceTypeProvider";
import { Layer, modalZIndex } from "./Layer";
import { SheetMobile } from "./SheetMobile";
import { Button } from "../Button/Button";

type Props = {
  resources: {
    show: string;
  };
  isMobile: boolean;
  headingText: string | undefined;
  showDismissButton: boolean;
  customFooter: React.ReactNode | undefined;
  externalOpenState: boolean;
  size: SheetMobileProps["size"] | undefined;
  externalOpenSetter: ((isOpen: boolean) => void) | undefined;
} & (
  | {
      displayButtonType: "button";
      displaySheetButtonText: string | undefined;
      disableSheetButton: boolean;
    }
  | { displayButtonType: "icon"; icon: React.ReactNode }
) &
  Omit<SheetMobileProps, "onDismiss" | "heading">;

export const ResponsiveContainer: FunctionComponent<Props> = (props) => {
  const [showComponent, setShowComponent] = useState(false);
  const showSheet = props.externalOpenState ? props.externalOpenState : showComponent;

  if (props.isMobile) {
    return (
      <DeviceTypeProvider deviceType="mobile">
        {showSheet ? (
          <Layer zIndex={modalZIndex}>
            <SheetMobile
              align={props.showDismissButton ? "start" : "center"}
              footer={props.customFooter ? props.customFooter : undefined}
              heading={props.headingText ? props.headingText : ""}
              onDismiss={() => {
                if (props.externalOpenSetter) {
                  props.externalOpenSetter(false);
                } else {
                  setShowComponent(false);
                }
              }}
              showDismissButton={props.showDismissButton ? props.showDismissButton : false}
              size={props.size ? props.size : "auto"}
            >
              {props.children}
            </SheetMobile>
          </Layer>
        ) : null}
        {props.displayButtonType === "icon" ? (
          <Box
            minWidth={40}
            minHeight={40}
            maxWidth={40}
            maxHeight={40}
            onClickCapture={() => {
              if (props.externalOpenSetter) {
                props.externalOpenSetter(true);
              } else {
                setShowComponent(true);
              }
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {props.icon}
          </Box>
        ) : (
          <Box padding={2}>
            <Button
              color={"blue"}
              onClick={() => {
                if (props.externalOpenSetter) {
                  props.externalOpenSetter(true);
                } else {
                  setShowComponent(true);
                }
              }}
              fullWidth
              text={
                props.displaySheetButtonText ? props.displaySheetButtonText : props.resources.show
              }
              disabled={props.disableSheetButton ? props.disableSheetButton : undefined}
            />
          </Box>
        )}
      </DeviceTypeProvider>
    );
  }

  return <>{props.children}</>;
};
