import {
  Box,
  Button,
  Divider,
  Layer,
  Modal,
  Text,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent, useMemo } from "react";

import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { Page } from "../../../components/Layout/Page";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { StepTabs } from "../../../components/StepTabs";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";
import { ConformationStep } from "./Steps/ConformationStep";
import { DisplaySettingsStep } from "./Steps/DisplaySettingsStep";
import { RelationshipsStep } from "./Steps/RelationshipsStep";
import { StructureStep } from "./Steps/StructureStep";
import { useDocumentCollectionManagement } from "./hooks";

const ManageDocumentCollectionsPage: FunctionComponent = () => {
  const organizationNavigate = useOrganizationNavigate();
  const {
    resources,
    id,
    formState,
    setFormState,
    masterDocumentType,
    masterSourceFields,
    allDocumentTypes,
    documentTypesOptions,
    selectedTabIndex,
    prevStep,
    nextStep,
    showConfirmationModal,
    setShowModal,
    affectedEntitiesResponse,
    saveDocumentCollection,
    nextIsDisable,
    undoAllChanges,
    isLoading,
    isMutating,
    isSuccess,
    STEPS,
    eventManager,
    visibleModal,
    setVisibleModal,
    showMainSaveButton,
    setShowMainSaveButton,
  } = useDocumentCollectionManagement();
  const { colors } = useColors();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.documentCollection.documentCollections,
        url: organizationLinkTemplates.documentCollectionList({
          pageNumber: 0,
          rowsPerPage: "15",
          assignedTo: "",
          filter: "",
        }),
      },
      { type: "text", name: formState.name || "" },
    ];
  }, [
    formState.name,
    resources.documentCollection.documentCollections,
    resources.home,
    resources.settings,
  ]);

  return (
    <>
      {visibleModal && (
        <Layer zIndex={modalZIndex}>
          <Modal
            accessibilityModalLabel={resources.confirmation}
            heading={
              <Box>
                <Box marginBottom={4}>
                  <Text weight="bold" size="400" color={colors.secondary}>
                    {resources.documentCollection.documentCollections}
                  </Text>
                </Box>
                <Divider />
              </Box>
            }
            size="md"
            onDismiss={() => setVisibleModal(false)}
            footer={
              <>
                <Divider />
                <Box marginTop={4} display="flex">
                  <Box>
                    <Button onClick={() => setVisibleModal(false)} text={resources.cancel} />
                  </Box>
                  <Box marginStart="auto">
                    <Button
                      onClick={() => {
                        setVisibleModal(false);
                        organizationNavigate(organizationLinkTemplates.manageDocumentCollections());
                      }}
                      text={resources.accept}
                    />
                  </Box>
                </Box>
              </>
            }
          >
            <Box display="flex" alignItems="center">
              <Text>{resources.documentCollection.goBackToDocumentCollection}</Text>
            </Box>
          </Modal>
        </Layer>
      )}
      <Page breadCrumbEntries={breadCrumbEntries}>
        <Box display="flex" direction="column" flex="grow">
          <Box display="flex" direction="column" flex="grow" width={"100%"}>
            <StepTabs
              selectedTabIndex={selectedTabIndex}
              extraComponent={
                <Box display="flex" flex="grow" alignItems="center" justifyContent="end">
                  {id && (
                    <Box>
                      <Button
                        text={resources.undoChanges}
                        onClick={undoAllChanges}
                        color="transparent"
                        iconEnd="replace"
                      />
                    </Box>
                  )}
                </Box>
              }
              tabs={[
                {
                  title: resources.documentCollection.conformation,
                  description: resources.documentCollection.conformationStepDesc,
                  content: (
                    <ConformationStep
                      resources={resources}
                      formState={formState}
                      isLoading={isLoading}
                      documentTypesOptions={documentTypesOptions}
                      setFormState={setFormState}
                    />
                  ),
                },
                {
                  title: resources.generalSettings,
                  description: resources.documentCollection.displaySettingsStepDesc,
                  content: masterDocumentType && (
                    <DisplaySettingsStep
                      masterDocumentType={masterDocumentType}
                      formState={formState}
                      isLoading={isLoading}
                      masterSourceFields={masterSourceFields}
                      setFormState={setFormState}
                      eventManager={eventManager}
                    />
                  ),
                },
                {
                  title: resources.documentCollection.documentsRelations,
                  description: resources.documentCollection.documentsRelationsStepDesc,
                  content: (
                    <RelationshipsStep
                      resources={resources}
                      formState={formState}
                      isLoading={isLoading}
                      masterSourceFields={masterSourceFields}
                      documentTypesOptions={documentTypesOptions}
                      setFormState={setFormState}
                    />
                  ),
                },
                {
                  title: resources.documentCollection.structure,
                  description: resources.documentCollection.structureStepDesc,
                  content: (
                    <StructureStep
                      formState={formState}
                      isLoading={isLoading}
                      documentTypes={allDocumentTypes}
                      documentTypesOptions={documentTypesOptions}
                      setFormState={setFormState}
                      setShowMainSaveButton={setShowMainSaveButton}
                    />
                  ),
                },
              ]}
            />
          </Box>
          {formState.toastMessage && (
            <NotificationMessage
              type={formState.toastMessage.type}
              message={formState.toastMessage.message}
              position="bottom-left"
              handleDismiss={() => setFormState({ ...formState, toastMessage: undefined })}
            />
          )}

          {affectedEntitiesResponse?.documentsAffected !== undefined && showConfirmationModal && (
            <Layer zIndex={modalZIndex}>
              <Modal
                accessibilityModalLabel={resources.documentCollection.documentCollections}
                role="alertdialog"
                heading={
                  <Box>
                    <Box marginBottom={4}>
                      <Text weight="bold" size="400" color={colors.secondary}>
                        {resources.documentCollection.documentCollections}
                      </Text>
                    </Box>
                    <Divider />
                  </Box>
                }
                size="md"
                onDismiss={() => setShowModal(false)}
                footer={
                  <>
                    <Divider />
                    <Box marginTop={4} display="flex">
                      <Box>
                        <Button onClick={() => setShowModal(false)} text={resources.cancel} />
                      </Box>
                      <Box marginStart="auto">
                        <Button
                          onClick={() => {
                            setShowModal(false);
                            saveDocumentCollection();
                          }}
                          text={resources.yesModify}
                        />
                      </Box>
                    </Box>
                  </>
                }
              >
                <Box display="flex" alignItems="center">
                  <Text>
                    {resources.documentCollection.youAreAboutToChangeTheCollection
                      .replace("{documentCollectionName}", formState.name || "")
                      .replace(
                        "{documentCollectionsCount}",
                        affectedEntitiesResponse.documentCollectionsAffected.toString()
                      )
                      .replace(
                        "{documentsCount}",
                        affectedEntitiesResponse.documentsAffected.toString()
                      )}
                  </Text>
                </Box>
              </Modal>
            </Layer>
          )}
          {showMainSaveButton && (
            <Box display="flex" direction="row" gap={2} padding={4}>
              <Box flex="grow" />
              <Box display="flex" flex="shrink" padding={1}>
                {selectedTabIndex > STEPS.Conformation && (
                  <Button
                    disabled={isLoading || isMutating || isSuccess}
                    color="gray"
                    onClick={prevStep}
                    text={resources.previousStep}
                  />
                )}
              </Box>
              <Box display="flex" flex="shrink" position="relative" padding={1}>
                <Button
                  color="blue"
                  onClick={nextStep}
                  disabled={nextIsDisable || isLoading || isMutating || isSuccess}
                  text={`${
                    selectedTabIndex === STEPS.Structure ? resources.save : resources.continue
                  }`}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Page>
    </>
  );
};

export default ManageDocumentCollectionsPage;
