import { FunctionComponent } from "react";
type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};
export const DocumentExpiringSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 50,
  height = 50,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      style={svgStyle}
      viewBox={`0 0 50 50`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect width="50" height="48" rx="24" fill="#FDF2DF" />
      <rect x="18.1084" y="11.6973" width="19.4595" height="25.4545" rx="2" fill="#FFEEBF" />
      <g clipPath="url(#clip0_11378_8347)">
        <path
          d="M34.3106 20.6519C34.3106 25.8838 34.3106 31.1012 34.3106 36.3331C34.3106 37.594 33.9247 37.9998 32.7395 37.9998C27.2683 37.9998 21.7832 37.9998 16.312 37.9998C15.1681 37.9998 14.7822 37.594 14.7822 36.3766C14.7822 28.9418 14.7822 21.507 14.7822 14.0577C14.7822 13.6954 14.7822 13.3331 14.9614 12.9998C15.4162 13.1882 15.7745 12.7969 16.2155 12.7969C19.5782 12.8404 22.9546 12.8259 26.3311 12.7969C26.841 12.7969 27.0064 12.9563 26.9926 13.4925C26.9651 15.1592 26.9788 16.8404 26.9926 18.5215C26.9926 19.5505 27.4061 19.9708 28.3983 19.9708C30.3415 19.9708 32.2709 19.9708 34.2141 19.9708C34.4346 20.1592 34.3244 20.4201 34.3244 20.6519H34.3106Z"
          fill="url(#paint0_linear_11378_8347)"
        />
        <path
          d="M34.1862 19.9708C32.243 19.9708 30.3136 19.9708 28.3704 19.9708C27.3919 19.9708 26.9785 19.5506 26.9785 18.5216C26.9785 16.8404 26.9509 15.1737 26.9785 13.4926C26.9785 12.9419 26.8269 12.7824 26.317 12.7969C22.9543 12.8259 19.5778 12.8259 16.2014 12.7969C15.7466 12.7969 15.4021 13.1882 14.9473 12.9998C15.1402 12.5506 15.4985 12.3477 15.9533 12.3477C19.5778 12.3477 23.2024 12.3621 26.8269 12.3766C26.8545 12.4781 26.8958 12.5216 26.9647 12.4056C28.1224 13.4056 29.1146 14.594 30.162 15.6955C31.5126 17.1158 32.8494 18.5506 34.1862 19.9853V19.9708Z"
          fill="#DEB744"
        />
        <g filter="url(#filter0_b_11378_8347)">
          <path
            d="M21.7143 16.1594C21.7143 12.7577 19.0919 10 15.8571 10C12.6223 10 10 12.7577 10 16.1594C10 19.5612 12.6223 22.3188 15.8571 22.3188C19.0919 22.3188 21.7143 19.5612 21.7143 16.1594Z"
            fill="url(#paint1_linear_11378_8347)"
          />
          <path
            d="M21.7143 16.1594C21.7143 12.7577 19.0919 10 15.8571 10C12.6223 10 10 12.7577 10 16.1594C10 19.5612 12.6223 22.3188 15.8571 22.3188C19.0919 22.3188 21.7143 19.5612 21.7143 16.1594Z"
            fill="#DEB744"
          />
        </g>
        <path
          d="M15.0153 15.7724V13.2773C15.0153 13.011 15.2447 12.8007 15.5352 12.8007H16.0399C16.3305 12.8007 16.5598 13.011 16.5598 13.2773V15.7724C16.5598 16.1649 16.2081 16.4873 15.7799 16.4873C15.3517 16.4873 15 16.1649 15 15.7724H15.0153Z"
          fill="white"
        />
        <path
          d="M15.5817 15.6179H17.6122C17.8289 15.6179 18 15.8924 18 16.2401V16.8441C18 17.1918 17.8289 17.4663 17.6122 17.4663H15.5817C15.2624 17.4663 15 17.0454 15 16.5329C15 16.0205 15.2624 15.5996 15.5817 15.5996V15.6179Z"
          fill="white"
        />
        <path d="M30.0525 23.7969H19.1514V25.565H30.0525V23.7969Z" fill="white" />
        <path d="M30.0525 27.8691H19.1514V29.6373H30.0525V27.8691Z" fill="white" />
        <path d="M29.9558 31.9424H19.0547V33.7105H29.9558V31.9424Z" fill="white" />
      </g>
      <g filter="url(#filter1_b_11378_8347)">
        <rect
          x="27.0273"
          y="26.1211"
          width="12.973"
          height="7.63636"
          rx="1"
          fill="url(#paint2_linear_11378_8347)"
        />
      </g>
      <g filter="url(#filter2_bf_11378_8347)">
        <path
          d="M15.8571 10.5C18.7924 10.5 21.2143 13.0098 21.2143 16.1594C21.2143 19.309 18.7924 21.8188 15.8571 21.8188C12.9219 21.8188 10.5 19.309 10.5 16.1594C10.5 13.0098 12.9219 10.5 15.8571 10.5Z"
          stroke="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_11378_8347"
          x="5"
          y="5"
          width="21.7139"
          height="22.3184"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11378_8347" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11378_8347"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_11378_8347"
          x="26.0273"
          y="25.1211"
          width="14.9727"
          height="9.63672"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11378_8347" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11378_8347"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_bf_11378_8347"
          x="5"
          y="5"
          width="21.7139"
          height="22.3184"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11378_8347" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11378_8347"
            result="shape"
          />
          <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_11378_8347" />
        </filter>
        <linearGradient
          id="paint0_linear_11378_8347"
          x1="24.5671"
          y1="12.7969"
          x2="23.709"
          y2="78.7258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0C548" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11378_8347"
          x1="10"
          y1="10"
          x2="18.5897"
          y2="21.0812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0C548" />
          <stop offset="1" stop-color="#FFEEBF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11378_8347"
          x1="20.9463"
          y1="19.7575"
          x2="74.8962"
          y2="52.6639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0C548" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_11378_8347">
          <rect width="24.3243" height="28" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  );
};
