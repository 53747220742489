import {
  Box,
  Checkbox,
  ComboBox,
  Flex,
  RadioGroup,
  Text,
  Tooltip,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { DocumentTypeState } from "../hooks";
import type { DocumentTypeApproveConfig } from "@prodoctivity/types";
import { XIconSvg } from "../../../../svg/XIconSvg";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import { ArrowSvg } from "../../../../svg/ArrowSvg";
import { CircleQuestionSvgIcon } from "../../../../svg/CircleQuestionSvgIcon";

type Props = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  isLoading: boolean;
  formState: DocumentTypeState;
  allApprovalTypes: ComboBoxItemType[];
  approvalTypeOptions: ComboBoxItemType[];
  addApprovalType: (id: string) => void;
  moveApproveItem: (index: number, direction: "up" | "down") => void;
  onChangeApprovePermission: (
    approvalPermissionType: DocumentTypeApproveConfig["approvalPermissionType"]
  ) => void;
  onChangeApproveExecOrder: (executeTaskInOrder: boolean) => void;
  dismissOnUpdateDocument: (id: string, dismissOnUpdateDocument: boolean) => void;
  onRemoveApprovalType: (id: string) => void;
};

export const ApprovalStep: FunctionComponent<Props> = ({
  formState,
  isLoading,
  resources,
  allApprovalTypes,
  approvalTypeOptions,
  addApprovalType,
  moveApproveItem,
  onChangeApprovePermission,
  onChangeApproveExecOrder,
  dismissOnUpdateDocument,
  onRemoveApprovalType,
}) => {
  const { colors } = useColors();

  return (
    <Box color={colors.white} margin={3} padding={12}>
      <Box marginBottom={8} width={"50%"} display="flex" justifyContent="start">
        <Text weight="bold" color={colors.black900} size="500">
          {resources.approvalType.configureDocumentTypeApprove}
        </Text>
      </Box>
      <Box>
        <RadioGroup
          id={"approval-permission-type"}
          legend={resources.approvalType.approvalTypes}
          direction="column"
          legendDisplay="hidden"
        >
          <RadioGroup.RadioButton
            checked={formState.approvalConfig?.approvalPermissionType === "any-user"}
            id="permission-to-any-radio"
            label={resources.approvalType.anyUserCanApprove}
            name={resources.approvalType.anyUserCanApprove}
            disabled={isLoading}
            onChange={() => {
              onChangeApprovePermission("any-user");
            }}
            value={resources.approvalType.anyUserCanApprove}
          />
          <RadioGroup.RadioButton
            checked={
              formState.approvalConfig?.approvalPermissionType === "only-users-with-permissions"
            }
            id="permission-to-specific-users-radio"
            label={resources.approvalType.onlyUserWithPermission}
            name={resources.approvalType.onlyUserWithPermission}
            disabled={isLoading}
            onChange={() => {
              onChangeApprovePermission("only-users-with-permissions");
            }}
            value={resources.approvalType.onlyUserWithPermission}
          />
        </RadioGroup>
      </Box>
      <Box marginTop={8} display="flex" gap={3} alignItems="center">
        <Checkbox
          checked={formState.approvalConfig?.executeTaskInOrder || false}
          id="executeTaskInOrder"
          label={resources.approvalType.executeTaskInOrder}
          onChange={({ checked }) => onChangeApproveExecOrder(checked)}
        />
        <Tooltip text={resources.approvalType.executeTaskOrderDescription}>
          <CircleQuestionSvgIcon width={18} height={18} />
        </Tooltip>
      </Box>
      <Box marginTop={8}>
        <Box marginBottom={3} display="flex" gap={3} alignItems="center">
          <Text weight="bold" color={colors.black900} size="400">
            {resources.approvalType.addApprovalType}
          </Text>
          <Box width={"350px"}>
            <ComboBox
              id="approvalTypes"
              label=""
              disabled={isLoading}
              size="lg"
              options={approvalTypeOptions}
              onSelect={(e) => addApprovalType(e.item.value)}
              onChange={(e) => addApprovalType(e.value)}
              placeholder={resources.approvalType.addApprovalType}
              inputValue={""}
              selectedOption={undefined}
            />
          </Box>
        </Box>

        <Box paddingY={3} display="flex" direction="column" gap={6}>
          {(formState.approvalConfig?.approvalTypes || []).map((approvalType, index) => {
            const allowUpBtn = index > 0;
            const allowDownBtn =
              index >= 0 && index < (formState.approvalConfig?.approvalTypes || []).length - 1;

            const approvalTypeInfo = allApprovalTypes.find((a) => a.value === approvalType.id);
            if (!approvalTypeInfo) {
              return <></>;
            }

            return (
              <Box
                key={`${approvalType.id}_${index}`}
                borderColor={colors.primary200}
                borderRadius={6}
                color={colors.primary100}
                padding={4}
                display="flex"
                alignItems="center"
                height={"86px"}
              >
                <Box display="flex" direction="column" gap={2} flex="grow">
                  <Text color={colors.primary}>
                    {formState.approvalConfig?.executeTaskInOrder ? index + 1 : ""}-{" "}
                    {approvalTypeInfo.label}
                  </Text>
                  <Flex gap={1} alignItems="center">
                    <Checkbox
                      checked={approvalType.dismissOnUpdateDocument}
                      id={`dismissOnUpdateDocument_${approvalType.id}_${index}`}
                      label={resources.approvalType.dismissPreviousApprovals}
                      onChange={({ checked }) => dismissOnUpdateDocument(approvalType.id, checked)}
                    />
                    <Tooltip text={resources.approvalType.dismissPreviousDescription}>
                      <CircleQuestionSvgIcon width={18} height={18} />
                    </Tooltip>
                  </Flex>
                </Box>
                <Box height={"100%"} alignItems="center" alignSelf="end" display="flex" gap={3}>
                  <Box
                    borderColor={allowUpBtn ? colors.primary200 : colors.subtle}
                    color={colors.primary100}
                    borderRadius={4}
                    paddingX={1}
                    paddingTop={1}
                  >
                    <ArrowSvg
                      direction="up"
                      color={allowUpBtn ? colors.primary200 : colors.subtle}
                      onClick={() => {
                        if (allowUpBtn) moveApproveItem(index, "up");
                      }}
                    />
                  </Box>
                  <Box
                    borderColor={allowDownBtn ? colors.primary200 : colors.subtle}
                    color={colors.primary100}
                    borderRadius={4}
                    paddingX={1}
                    paddingTop={1}
                  >
                    <ArrowSvg
                      direction="down"
                      color={allowDownBtn ? colors.primary200 : colors.subtle}
                      onClick={() => {
                        if (allowDownBtn) moveApproveItem(index, "down");
                      }}
                    />
                  </Box>
                  <XIconSvg
                    width={13}
                    height={13}
                    color={colors.primary200}
                    onClick={() => onRemoveApprovalType(approvalType.id)}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
