import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ViewLogSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 23,
  height = 16,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      className={customClassName}
      style={svgStyle}
      width={width}
      height={height}
      viewBox={`0 0 23 16`}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3968 8.00047C16.3968 10.5194 14.3548 12.5613 11.8359 12.5613C9.31704 12.5613 7.27508 10.5194 7.27508 8.00047C7.27508 5.48158 9.31704 3.43961 11.8359 3.43961C14.3548 3.43961 16.3968 5.48158 16.3968 8.00047ZM11.8359 14.8418C15.9815 14.8418 19.5528 11.1815 21.1721 9.21561C21.6146 8.67826 21.8359 8.40959 21.8359 8.00047C21.8359 7.59136 21.6146 7.32268 21.1721 6.78533C19.5528 4.81943 15.9815 1.15918 11.8359 1.15918C7.69042 1.15918 4.11903 4.81943 2.49982 6.78533C2.05723 7.32268 1.83594 7.59136 1.83594 8.00047C1.83594 8.40959 2.05723 8.67826 2.49982 9.21561C4.11903 11.1815 7.69042 14.8418 11.8359 14.8418Z"
        stroke="#7F7F7F"
        strokeWidth="2"
      />
    </svg>
  );
};
