import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const GridLargeIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 22,
  height = 22,
  color: rawColor,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7366 0.658388C20.6254 0.658388 21.3497 1.38262 21.3497 2.27144V18.7904C21.3497 19.6792 20.6254 20.4035 19.7366 20.4035H2.55269C1.66387 20.4035 0.939638 19.6792 0.939638 18.7904V2.27144C0.939638 1.38262 1.66387 0.658388 2.55269 0.658388H19.73M19.73 0H2.55269C1.30175 0 0.28125 1.0205 0.28125 2.27144V18.7904C0.28125 20.0413 1.30175 21.0618 2.55269 21.0618H19.73C20.981 21.0618 22.0015 20.0413 22.0015 18.7904V2.27144C22.0015 1.0205 20.981 0 19.73 0Z"
        fill={calculateColor(color)}
      />
      <path
        d="M18.2706 2.62109H3.95727C3.17186 2.62109 2.53516 3.2578 2.53516 4.04321V16.9476C2.53516 17.733 3.17186 18.3697 3.95727 18.3697H18.2706C19.056 18.3697 19.6928 17.733 19.6928 16.9476V4.04321C19.6928 3.2578 19.056 2.62109 18.2706 2.62109Z"
        fill={calculateColor(color)}
      />
    </svg>
  );
};
