import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const RestoreDocument: FunctionComponent<Props> = ({
  customClassName,
  width = 26,
  height = 26,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      className={customClassName}
      style={svgStyle}
      width={width}
      height={height}
      viewBox={`0 0 26 26`}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4984 19.8623C12.1023 19.8623 11.7671 19.5736 11.7367 19.1983L11.432 15.4842C11.4015 15.0897 11.7163 14.7433 12.1328 14.7048C12.5593 14.6759 12.9148 14.9742 12.9554 15.3687L13.2601 19.0829C13.2906 19.4774 12.9757 19.8238 12.5593 19.8623C12.539 19.8623 12.5187 19.8623 12.4984 19.8623Z"
        fill="#7F7F7F"
      />
      <path
        d="M15.3534 19.9392C14.9369 19.9392 14.5916 19.6121 14.6018 19.208L14.6424 15.3976C14.6424 15.0031 14.9674 14.647 15.4143 14.6855C15.8307 14.6855 16.176 15.0127 16.1659 15.4168L16.1252 19.2272C16.1252 19.6217 15.7799 19.9392 15.3635 19.9392H15.3534Z"
        fill="#7F7F7F"
      />
      <path
        d="M18.1745 19.9385C18.1745 19.9385 18.2253 19.9385 18.2456 19.9385C18.6315 19.9385 18.9667 19.6595 19.0073 19.2842L19.3831 15.5027C19.4237 15.1082 19.119 14.7522 18.6925 14.7137C18.276 14.6752 17.9003 14.9639 17.8596 15.368L17.4838 19.1495C17.4432 19.544 17.7479 19.9001 18.1745 19.9385Z"
        fill="#7F7F7F"
      />
      <path
        d="M24.5736 5.67877L21.4251 4.15847C21.5266 4.07187 21.618 3.96602 21.6891 3.84093C21.9837 3.29247 21.7501 2.61892 21.1712 2.33988L19.0992 1.33917C18.5203 1.06013 17.8094 1.28144 17.5148 1.8299C17.4437 1.95499 17.4133 2.0897 17.4031 2.21479L14.2546 0.704106C13.2796 0.23262 12.0812 0.607885 11.5835 1.52199L11.0452 2.51307C10.5475 3.4368 10.9436 4.57222 11.9187 5.0437L22.2376 10.0184C22.522 10.1531 22.8267 10.2204 23.1314 10.2204C23.3345 10.2204 23.5478 10.1916 23.7509 10.1242C24.2587 9.97026 24.665 9.63348 24.9087 9.19086L25.447 8.19978C25.9447 7.27605 25.5587 6.14063 24.5736 5.66915V5.67877ZM24.0861 7.55509L23.5478 8.54618C23.497 8.65202 23.3954 8.729 23.2837 8.76749C23.1618 8.80597 23.0399 8.79635 22.9282 8.73862L12.6093 3.76396C12.3858 3.65811 12.2944 3.38869 12.4062 3.177L12.9445 2.18592C13.0562 1.97423 13.3304 1.88763 13.564 1.99348L23.8829 6.96814C24.1064 7.07398 24.1978 7.3434 24.0861 7.55509Z"
        fill="#7F7F7F"
      />
      <path
        d="M8.67845 11.875C8.46517 11.875 8.26204 11.9616 8.11985 12.1059C7.97766 12.2503 7.90657 12.4523 7.92688 12.6544L9.02377 24.8361C9.05424 25.2113 9.3894 25.5 9.78551 25.5H21.0591C21.4552 25.5 21.7803 25.2113 21.8209 24.8361L22.9178 12.6544C22.9381 12.4523 22.867 12.2503 22.7248 12.1059C22.5826 11.9616 22.3795 11.875 22.1662 11.875H8.67845ZM20.338 24.0567H10.466L9.50113 13.3183H21.3029L20.338 24.0567Z"
        fill="#7F7F7F"
      />
      <path
        d="M14.6419 10.0186C14.6419 10.0186 14.5708 9.93196 14.4489 9.79725C11.6051 6.64118 6.95343 5.67896 2.94164 7.30511L4.05885 4.64939C4.2112 4.27412 4.02838 3.86037 3.63228 3.71604C3.24634 3.5717 2.79945 3.7449 2.64711 4.12017L0.717384 8.67145C0.646289 8.84465 0.646289 9.04672 0.717384 9.21992C0.798635 9.39312 0.950982 9.53745 1.1338 9.6048L5.9581 11.4234C6.34405 11.5677 6.79093 11.3945 6.94328 11.0193C7.09562 10.6536 6.91281 10.2302 6.51671 10.0859L3.1143 8.80616C3.1143 8.80616 3.1143 8.80616 3.10415 8.80616C6.56749 7.18002 10.7621 7.94017 13.2809 10.7402C13.3723 10.8364 13.4129 10.8942 13.4231 10.9038C13.5754 11.0866 13.7989 11.1828 14.0223 11.1828C14.1848 11.1828 14.3473 11.1347 14.4895 11.0385C14.8247 10.798 14.8856 10.3457 14.6317 10.0282L14.6419 10.0186Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
