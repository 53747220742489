import {
  Box,
  BoxWithAnimations,
  Button,
  ChevronSvgIcon,
  ColorConfig,
  CommentsLayout,
  Divider,
  Grid,
  Icon,
  Layer,
  Modal,
  modalZIndex,
  ResponsiveContainer,
  Spinner,
  Tabs,
  TapArea,
  Text,
  TextArea,
  TextField,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type {
  DocumentCollectionDocumentView,
  DocumentCollectionFolderSortType,
  DocumentCollectionFolderView,
  DocumentsDisplayProps,
  EvaluateValueMapFilter,
  ParametersObject,
} from "@prodoctivity/shared/src/index-types";
import type { DocumentCollectionView, WorkflowTask } from "@prodoctivity/types";
import { PreviewContainer } from "../../pages/DocumentCollectionInstance/DocumentCollectionInstance";
import { GenerationWizardWithPayload } from "../../pages/templates/GenerationWizard";
import { DocumentExpiredSvg } from "../../svg/DocumentExpiredSvg";
import { DocumentExpiringSvg } from "../../svg/DocumentExpiringSvg";
import { DocumentInstructionsSvg } from "../../svg/DocumentInstructionsSvg";
import { DocumentPendingSvg } from "../../svg/DocumentPendingSvg";
import { PrinterSvgIcon } from "../../svg/PrinterSvgIcon";
import { TaskInstructionsIconsSvg } from "../../svg/TaskInstructionsIconsSvg";
import { ViewLogSvg } from "../../svg/ViewLogSvg";
import { XIconSvg } from "../../svg/XIconSvg";
import { emptyArray } from "../../utils";
import { PublicAvatarImage } from "../Avatar/Avatar";
import { BreadCrumbEntry } from "../BreadCrumb";
import { useDocumentTypeInfoWithLatestVersion } from "../Display/hooks";
import { Page } from "../Layout/Page";
import { Pagination } from "../Layout/Pagination";
import { PublicProfile } from "../Profile/Public/PublicProfile";
import { SmallRefreshButton } from "../SmallRefreshButton";
import { useTaskCommentsManager, useTaskInstructions } from "./hook";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { organizationLinkTemplates } from "../../link-templates";
import { UseMutateFunction } from "@tanstack/react-query";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import IndexDocumentPage from "../../pages/Dashboard/IndexDocument";
import {
  FolderSummary,
  FolderTreeInstance,
} from "../../pages/DocumentCollectionInstance/components/StructureInstance";
import { DocumentNoResultsSVG } from "../../svg/DocumentNoResultsSVG";
import { ImportSvgDownArrowIcon } from "../../svg/ImportSvgDownArrowIcon";
import { GenerateDocumentGearIcon } from "../../svg/GenerateDocumentGearIcon";
import { RenderDocumentCollectionIcon } from "../../pages/DocumentCollectionInstance/RenderDocumentCollectionIcon";
import moment from "moment";
import { BackToTaskListSvg } from "../../svg/BackToTaskListSvg";
import {
  DocumentCheckSvgIcon,
  DocumentExpiredSvgIcon,
  DocumentMissingSvgIcon,
  DocumentToExpireSvgIcon,
} from "../../svg/DocumentCollection/DocumentStatus";
import { CircleXIcon } from "../../svg/Tasks/CircleXIcon";
import { NoData } from "../../svg/Tasks/noData";

export type CollectionInstanceEvents = {
  collapseAll: Record<string, unknown>;
};
export type EnhancedDocumentView = DocumentCollectionDocumentView & {
  valueMap: Record<string, EvaluateValueMapFilter>;
  folderName: string;
};

type DocumentStatusTypeProps = {
  name: string;
  icon: JSX.Element;
  count: number;
};

type DocumentStatusTypesProps = {
  documents: DocumentStatusTypeProps;
  toExpire: DocumentStatusTypeProps;
  missing: DocumentStatusTypeProps;
  expired: DocumentStatusTypeProps;
};

type DocumentCollectionExplorerProps = {
  documentCollection: DocumentCollectionView;
  globalDocumentsCount: {
    documentCount: number;
    issueCount: number;
    toExpireCount: number;
    expiredCount: number;
  };
  collectionTabIndex: number;
  setDocumentsDisplay: Dispatch<SetStateAction<DocumentsDisplayProps>>;
  documentsDisplay: DocumentsDisplayProps;
  setFilterTextFieldEnabled: Dispatch<SetStateAction<boolean>>;
  filterTextFieldEnabled: boolean;
  setFilterValue: Dispatch<SetStateAction<string>>;
  clearAllFilters: () => void;
  folderView: EnhancedDocumentView[];
  checkFolder: (
    folder: DocumentCollectionFolderView,
    fullPath: string[],
    filter: string,
    paths: Set<string>
  ) => boolean;
  setPaths: (n: string[]) => void;
  filterValue: string;
  eventEmitter: {
    on(event: "collapseAll", listener: (data: Record<string, unknown>) => void): void;
    off(event: string, listener: (data: Record<string, unknown>) => void): void;
    emit(event: string, data: Record<string, void>): void;
  };
  thisFolder: any;
  folderPath: string[];
  currDocument: DocumentCollectionDocumentView | undefined;
  setFolderPath: (n: string[]) => void;
  setCurrDocument: (docView: DocumentCollectionDocumentView | undefined) => void;
  paths: string[];
  sortSelectedOptions: {
    [pathKey: string]: DocumentCollectionFolderSortType;
  };
  SetSortSelectedOptions: Dispatch<
    SetStateAction<{
      [pathKey: string]: DocumentCollectionFolderSortType;
    }>
  >;
  documentCollectionResponseContext: ParametersObject;
  documentSelected: string;
  documentSelectedState: (value: string) => void;
  subscribe: (
    eventType: keyof CollectionInstanceEvents,
    handler: () => void
  ) => {
    unsubscribe(): void;
  };
  isLoading: boolean;
  createValueMapContextAndToggleVisibility: ParametersObject | undefined;
};

type ViewDocumentTaskProps = Pick<DocumentCollectionExplorerProps, "folderView"> & {
  handleDocumentAction: (isImport: boolean, isGenerate: boolean) => void;
  DocumentViewAction: (isSign: boolean) => void;
  setSelectedTypeId: (n: {
    documentTypeId: string;
    valueMap: Record<string, EvaluateValueMapFilter> | undefined;
  }) => void;
  setSelectedDocumentTypeId: (n: string) => void;
  workflowTaskResponse:
    | {
        workflowTask: WorkflowTask<number> & {
          id: string;
        };
      }
    | {
        workflowTask: undefined;
      }
    | undefined;
  saveTaskInstructions: UseMutateFunction<
    {
      newTaskId: string | undefined;
    },
    unknown,
    {
      state: "open" | "done";
      action: string;
      comment: string;
    },
    unknown
  >;
  popoverState: {
    actionName: string;
    isVisible: boolean;
  };
  setPopoverState: (n: { actionName: string; isVisible: boolean }) => void;
  taskId: string;
  validateDate: (date: number | undefined) => string | undefined;
  documentCollection: DocumentCollectionView | undefined;
  isPanelHidden: boolean;
  panelClicked: boolean;
  isMobileOrTablet: boolean;
};

type TaskModalProps = Pick<ViewDocumentTaskProps, "setPopoverState" | "saveTaskInstructions"> & {
  action: string;
  workflowTaskName: string | undefined;
  getActionButtonLabel: (actionName: string) => string;
};

type FolderComponentProps = Pick<
  ViewDocumentTaskProps,
  | "handleDocumentAction"
  | "setSelectedTypeId"
  | "setSelectedDocumentTypeId"
  | "validateDate"
  | "documentCollection"
> & {
  folder: EnhancedDocumentView[];
  DocumentViewAction: (isSign: boolean) => void;
  workflowTaskFolderName: string | undefined;
};

type TaskFolderViewProps = FolderComponentProps & ViewDocumentTaskProps;

type DocumentOptionsMenuProps = Pick<
  TaskFolderViewProps,
  | "handleDocumentAction"
  | "setSelectedTypeId"
  | "DocumentViewAction"
  | "setSelectedDocumentTypeId"
  | "documentCollection"
  | "workflowTaskFolderName"
> & {
  doc: DocumentCollectionDocumentView;
  index: number;
  displayedColor: (type: string, date: number | undefined) => ColorConfig | undefined;
  validateDate: (date: number | undefined) => string | undefined;
  valueMap: Record<string, EvaluateValueMapFilter> | undefined;
  folderName: string;
};

export const TaskInstructions = () => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const [panelClicked, setPanelClicked] = useState<boolean>(false);

  const {
    thisFolder,
    folderPath,
    setFolderPath,
    currDocument,
    filterValue,
    setFilterValue,
    setCurrDocument,
    isPanelHidden,
    sortSelectedOptions,
    SetSortSelectedOptions,
    setDocumentsDisplay,
    documentsDisplay,
    documentCollectionResponseContext,
    documentSelected,
    documentSelectedState,
    collectionTabIndex,
    setCollectionTabIndex,
    filterTextFieldEnabled,
    setFilterTextFieldEnabled,
    clearAllFilters,
    isLoadingDocument,
    globalDocumentsCount,
    hidePanel,
    showPanel,
    folderView,
    documentCollection,
    collectionConfig,
    refetch,
    eventEmitter,
    subscribe,
    paths,
    setPaths,
    tabs,
    checkFolder,
    ecmDocument,
    visibleInstructionsTask,
    setSelectedDocumentTypeId,
    handleDocumentAction,
    setSelectedTypeId,
    DocumentViewAction,
    documentTypeInfo,
    createValueMapContextAndToggleVisibility,
    workflowTaskResponse,
    saveTaskInstructions,
    popoverState,
    setPopoverState,
    taskId,
    loadingTaskDetails,
    isLoading,
    visiblePanelIndices,
    handleShowInstructionTask,
    resetAndShowSignatureInstructions,
    selectedDocumentTypeId,
    updateVisibleInstructions,
    isSignatureInstruction,
    validateDate,
    isMobileOrTablet,
  } = useTaskInstructions();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "url",
        name: resources.task,
        url: organizationLinkTemplates.tasks(),
      },
      { type: "text", name: workflowTaskResponse?.workflowTask?.workflowName || "" },
    ];
  }, [resources.home, resources.task, workflowTaskResponse?.workflowTask?.workflowName]);

  const resetTaskFilters = useCallback(() => {
    updateVisibleInstructions({
      isInstructionTaskShow: true,
    });
    setSelectedDocumentTypeId("");
    setSelectedTypeId({ documentTypeId: "", valueMap: undefined });
  }, [setSelectedDocumentTypeId, setSelectedTypeId, updateVisibleInstructions]);

  const sortDocumentsByStatus = useMemo(() => {
    const missingDocument: EnhancedDocumentView[] = [];
    const expiredDocument: EnhancedDocumentView[] = [];
    const expiringDocument: EnhancedDocumentView[] = [];
    const documentComplete: EnhancedDocumentView[] = [];

    folderView.forEach((items) => {
      if (items.type === "missing-document") {
        missingDocument.push(items);
      }
      if (items.type === "document") {
        if (validateDate(items.expirationDate) === "expired-document") {
          expiredDocument.push(items);
        } else if (validateDate(items.expirationDate) === "expiring-document") {
          expiringDocument.push(items);
        } else {
          documentComplete.push(items);
        }
      }
    });

    const document = documentComplete.sort((a, b) => {
      if (a.type === "document" && b.type === "document") {
        const dateA = moment(a.updatedAt);
        const dateB = moment(b.updatedAt);
        return dateB.diff(dateA);
      }
      return 0;
    });

    return [...missingDocument, ...expiringDocument, ...expiredDocument, ...document];
  }, [folderView, validateDate]);

  if (isLoading || loadingTaskDetails || isSignatureInstruction) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex="grow">
        <Spinner show={true} />
      </Box>
    );
  }

  return (
    <Box>
      {(sortDocumentsByStatus.length === 0 ||
        documentCollection?.rootFolder.counts.documentCount === 0) && (
        <Box
          display="flex"
          justifyContent="center"
          direction="column"
          flex="grow"
          alignItems="center"
          paddingTop={12}
          gap={4}
        >
          <DocumentNoResultsSVG />
          <Text size="400" weight="bold">
            {resources.taskInstructionNotFound}
          </Text>
        </Box>
      )}
      {sortDocumentsByStatus.length > 0 && (
        <Page
          breadCrumbEntries={breadCrumbEntries}
          breadCrumbExtraComponent={
            !visibleInstructionsTask.isInstructionTaskShow ? (
              <Box>
                <TapArea>
                  <ResetFiltersButton resetTaskFilters={resetTaskFilters} />
                </TapArea>
              </Box>
            ) : undefined
          }
        >
          {!loadingTaskDetails && !isLoading ? (
            <Grid
              gridTemplateColumns={isMobileOrTablet ? [1] : visiblePanelIndices}
              gridTemplateRows={isMobileOrTablet ? ["max-content"] : undefined}
              height="100vh"
            >
              <ResponsiveContainer
                resources={resources}
                isMobile={isMobileOrTablet}
                displaySheetButtonText={resources.documentTypes.documentCollection}
                displayButtonType="button"
                headingText={undefined}
                customFooter={undefined}
                disableSheetButton={false}
                externalOpenSetter={undefined}
                externalOpenState={false}
                showDismissButton={false}
                size={undefined}
              >
                <Box display="flex" direction="column" flex="grow" borderStyle="sm" height={"100%"}>
                  <Box display="flex" justifyContent="between" alignItems="center">
                    {!isPanelHidden && documentCollection && (
                      <Box
                        marginBottom={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="between"
                        flex="grow"
                        paddingX={2}
                      >
                        <Box>
                          <Text weight="bold">{collectionConfig?.name}</Text>
                        </Box>
                        <SmallRefreshButton
                          size={25}
                          refetch={refetch}
                          accessibilityLabel={resources.refresh}
                          marginStart={0}
                          marginTop={0}
                        />
                      </Box>
                    )}
                    <Box>
                      <TapArea
                        onTap={() => {
                          if (isMobileOrTablet) return;
                          if (isPanelHidden) {
                            showPanel();
                            setPanelClicked(!panelClicked);
                          } else {
                            hidePanel();
                          }
                        }}
                      >
                        <ChevronSvgIcon
                          color={colors.neutral700}
                          direction={isPanelHidden ? "right" : "left"}
                        />
                      </TapArea>
                    </Box>
                  </Box>
                  {!isPanelHidden && documentCollection && (
                    <Box display="flex" direction="column" flex="grow" gap={3}>
                      <Box paddingX={2}>
                        <Box display="flex" flex="shrink" alignItems="center" gap={2}>
                          <Box display="flex" direction="column" flex="grow">
                            <Box>
                              <Text>{documentCollection?.name}</Text>
                            </Box>
                            <Box>
                              <Text size="200">
                                {workflowTaskResponse?.workflowTask?.folderName}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="between"
                          alignItems="center"
                          flex="grow"
                        >
                          <Tabs
                            activeTabIndex={collectionTabIndex}
                            onChange={({ activeTabIndex: index }) => setCollectionTabIndex(index)}
                            tabs={tabs}
                            wrap={true}
                          />
                          <Box onClickCapture={resetTaskFilters}>
                            <TapArea>
                              <TaskInstructionsIconsSvg />
                            </TapArea>
                          </Box>
                        </Box>
                      </Box>

                      <Divider direction="horizontal" />
                      <DocumentCollectionExplorer
                        documentCollection={documentCollection}
                        globalDocumentsCount={globalDocumentsCount}
                        collectionTabIndex={collectionTabIndex}
                        setDocumentsDisplay={setDocumentsDisplay}
                        documentsDisplay={documentsDisplay}
                        setFilterTextFieldEnabled={setFilterTextFieldEnabled}
                        filterTextFieldEnabled={filterTextFieldEnabled}
                        setFilterValue={setFilterValue}
                        clearAllFilters={clearAllFilters}
                        folderView={folderView}
                        checkFolder={checkFolder}
                        setPaths={setPaths}
                        filterValue={filterValue}
                        eventEmitter={eventEmitter}
                        thisFolder={thisFolder}
                        folderPath={folderPath}
                        currDocument={currDocument}
                        setFolderPath={setFolderPath}
                        setCurrDocument={setCurrDocument}
                        paths={paths}
                        sortSelectedOptions={sortSelectedOptions}
                        SetSortSelectedOptions={SetSortSelectedOptions}
                        documentCollectionResponseContext={documentCollectionResponseContext}
                        documentSelected={documentSelected}
                        documentSelectedState={documentSelectedState}
                        subscribe={subscribe}
                        isLoading={isLoading}
                        createValueMapContextAndToggleVisibility={
                          createValueMapContextAndToggleVisibility
                        }
                      />
                    </Box>
                  )}
                </Box>
              </ResponsiveContainer>
              {visibleInstructionsTask.isInstructionTaskShow && (
                <ViewDocumentTask
                  folderView={sortDocumentsByStatus}
                  handleDocumentAction={handleDocumentAction}
                  setSelectedTypeId={setSelectedTypeId}
                  DocumentViewAction={DocumentViewAction}
                  setSelectedDocumentTypeId={setSelectedDocumentTypeId}
                  workflowTaskResponse={workflowTaskResponse}
                  saveTaskInstructions={saveTaskInstructions}
                  popoverState={popoverState}
                  setPopoverState={setPopoverState}
                  taskId={taskId || ""}
                  validateDate={validateDate}
                  documentCollection={documentCollection}
                  isPanelHidden={isPanelHidden}
                  panelClicked={panelClicked}
                  isMobileOrTablet={isMobileOrTablet}
                />
              )}
              {visibleInstructionsTask.isViewerShow && (
                <PreviewContainer
                  isLoadingDocument={isLoadingDocument}
                  ecmDocument={ecmDocument}
                  currDocument={currDocument}
                  onDocumentDelete={refetch}
                  initialPanel={"none"}
                  isSignatureRequired={false}
                  handleShowInstructionTask={handleShowInstructionTask}
                />
              )}
              {visibleInstructionsTask.isImport && (
                <IndexDocumentPage
                  mode={"import"}
                  documentTypeInfo={documentTypeInfo}
                  isInstructionsTask={visibleInstructionsTask.isImport}
                  handleShowInstructionTask={handleShowInstructionTask}
                  context={createValueMapContextAndToggleVisibility}
                  disableDocumentTypesFromTaskInstructions={true}
                />
              )}
              {visibleInstructionsTask.isScan && (
                <IndexDocumentPage
                  mode={"scan"}
                  documentTypeInfo={documentTypeInfo}
                  isInstructionsTask={visibleInstructionsTask.isScan}
                  handleShowInstructionTask={handleShowInstructionTask}
                  context={createValueMapContextAndToggleVisibility}
                  disableDocumentTypesFromTaskInstructions={true}
                />
              )}
              {visibleInstructionsTask.isGenerate && (
                <GenerationWizardWithPayload
                  templateVersionId={documentTypeInfo?.templateVersionId || ""}
                  stateId={""}
                  context={createValueMapContextAndToggleVisibility}
                  previousConfiguration={undefined}
                  isInstructionsTask={visibleInstructionsTask.isGenerate}
                  handleShowInstructionTask={handleShowInstructionTask}
                />
              )}
              {(visibleInstructionsTask.isSign ||
                (selectedDocumentTypeId.length > 0 && !visibleInstructionsTask.isViewerShow)) && (
                <PreviewContainer
                  isLoadingDocument={isLoadingDocument}
                  ecmDocument={ecmDocument}
                  currDocument={currDocument}
                  onDocumentDelete={refetch}
                  initialPanel={"sign"}
                  goToLastVersion={resetAndShowSignatureInstructions}
                  isSignatureRequired={true}
                  handleShowInstructionTask={handleShowInstructionTask}
                />
              )}
            </Grid>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" height={"100vh"}>
              <Spinner show={true} />
            </Box>
          )}
        </Page>
      )}
    </Box>
  );
};

const ResetFiltersButton = ({ resetTaskFilters }: { resetTaskFilters: () => void }) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="between"
      gap={2}
      onClickCapture={resetTaskFilters}
    >
      <Tooltip text={resources.backToInstructionList}>
        <Box display="flex" direction="row" gap={2} paddingTop={2}>
          <BackToTaskListSvg color={colors.primary} width={25} height={25} />
          <Text color={colors.primary}>{resources.backToInstructionList}</Text>
        </Box>
      </Tooltip>
    </Box>
  );
};

const DocumentCollectionExplorer = ({
  documentCollection,
  globalDocumentsCount,
  collectionTabIndex,
  setDocumentsDisplay,
  documentsDisplay,
  setFilterTextFieldEnabled,
  filterTextFieldEnabled,
  setFilterValue,
  clearAllFilters,
  checkFolder,
  setPaths,
  filterValue,
  eventEmitter,
  thisFolder,
  folderPath,
  currDocument,
  setFolderPath,
  setCurrDocument,
  paths,
  sortSelectedOptions,
  SetSortSelectedOptions,
  documentCollectionResponseContext,
  documentSelected,
  documentSelectedState,
  subscribe,
  isLoading,
  createValueMapContextAndToggleVisibility,
}: DocumentCollectionExplorerProps) => {
  const { resources } = useAppTranslation();

  return (
    <Box display="flex" direction="column" flex="grow">
      {collectionTabIndex === 0 && (
        <Box width={"100%"} display="flex" direction="column" flex="grow">
          <Box paddingY={2} display="flex" direction="column" paddingX={2}>
            <FolderSummary
              resources={resources}
              documentsCount={globalDocumentsCount}
              gapSize={2}
              setDocumentsDisplay={setDocumentsDisplay}
              documentsDisplay={documentsDisplay}
              setFilterTextFieldEnabled={setFilterTextFieldEnabled}
              filterTextFieldEnabled={filterTextFieldEnabled}
              setFilterValue={setFilterValue}
              clearAllFilters={clearAllFilters}
            />

            {filterTextFieldEnabled && (
              <Box paddingY={4} width={"100%"} display="flex" gap={2}>
                <Box display="block" width={"100%"}>
                  <TextField
                    placeholder={resources.documentCollection.collectionFilter.filterDocuments}
                    id={"document-collection-filter"}
                    onChange={(e) => {
                      const paths = new Set<string>([]);
                      if (documentCollection)
                        documentCollection.rootFolder.folders.map((f) =>
                          checkFolder(f, [documentCollection.name], e.value, paths)
                        );
                      setPaths(Array.from(paths));
                      setFilterValue(e.value);
                    }}
                    value={filterValue}
                  />
                </Box>
                <Box flex="shrink">
                  <Button
                    iconEnd="star"
                    text="Collapse"
                    color={"gray"}
                    onClick={() => {
                      eventEmitter.emit("collapseAll", {});
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Divider />
          {!isLoading && (
            <Box width={"100%"} flex="grow" overflow="auto">
              <FolderTreeInstance
                depth={0}
                folder={thisFolder}
                folderPath={emptyArray}
                resources={resources}
                selectedFolderPath={folderPath}
                selectedDocument={currDocument}
                onFolderSelect={setFolderPath}
                onSelectDocument={setCurrDocument}
                filterValue={filterValue}
                pathList={paths}
                sortSelectedOptions={sortSelectedOptions}
                setSortSelectedOptions={SetSortSelectedOptions}
                documentsDisplay={documentsDisplay}
                documentCollectionResponseContext={documentCollectionResponseContext}
                documentSelected={documentSelected}
                documentSelectedState={documentSelectedState}
                subscribe={subscribe}
                isHoverDisabledForSelectedId={true}
                context={createValueMapContextAndToggleVisibility}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const ViewDocumentTask = ({
  handleDocumentAction,
  setSelectedTypeId,
  DocumentViewAction,
  setSelectedDocumentTypeId,
  workflowTaskResponse,
  saveTaskInstructions,
  popoverState,
  setPopoverState,
  taskId,
  folderView,
  validateDate,
  documentCollection,
  isPanelHidden,
  panelClicked,
  isMobileOrTablet,
}: ViewDocumentTaskProps) => {
  const { resources, moment } = useAppTranslation();
  const { colors } = useColors();
  const taskPanelFadeInAnimation = {
    animate: {
      opacity: 1,
      display: panelClicked ? "block" : undefined,
    },
    initial: {
      opacity: 0,
      display: panelClicked ? "none" : undefined,
    },
    exit: {
      opacity: 0,
      display: panelClicked ? "none" : undefined,
    },
    transition: { delay: 0.5 },
  };

  const {
    addComment,
    comments,
    isLoadingComments,
    updateCommentList,
    user,
    memoizedProcessId,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    getActionButtonLabel,
    commentsTotal,
    refetchComments,
    pageLengthOptions,
    disableApprove,
    filteredDocuments,
    getFilteredDocumentsByStatus,
    filter,
    setFilter,
  } = useTaskCommentsManager({
    taskId,
    workflowTaskResponse,
    folderView,
    validateDate,
  });

  const completeTaskName = useMemo(() => {
    return (
      workflowTaskResponse?.workflowTask?.workflowTaskName +
      " - " +
      documentCollection?.name +
      " - " +
      workflowTaskResponse?.workflowTask?.folderName
    );
  }, [workflowTaskResponse, documentCollection]);

  return (
    <Box padding={4} color={colors.neutral300} display="flex" direction="column" gap={4}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="between"
        gap={8}
        direction={isMobileOrTablet ? "column" : "row"}
      >
        <Box display="flex" flex="grow" direction="column" gap={2}>
          <Box display="flex" flex="grow" gap={2} alignItems="center">
            <Box>
              <Text weight="bold">{completeTaskName}</Text>
            </Box>
          </Box>
          <Text>{workflowTaskResponse?.workflowTask?.instruction}</Text>
        </Box>
        {(isPanelHidden || isMobileOrTablet) && (
          <BoxWithAnimations
            animationSet={taskPanelFadeInAnimation}
            condition={true}
            animatePresence={true}
          >
            <DocumentStatusTypes
              folderView={filteredDocuments}
              validateDate={validateDate}
              setFilter={setFilter}
              filter={filter}
              isMobileOrTablet={isMobileOrTablet}
            />
          </BoxWithAnimations>
        )}
      </Box>

      <Box borderStyle="shadow" borderRadius={12} color={colors.white} overflow="hidden">
        <FolderComponent
          folder={getFilteredDocumentsByStatus}
          handleDocumentAction={handleDocumentAction}
          setSelectedTypeId={setSelectedTypeId}
          DocumentViewAction={DocumentViewAction}
          setSelectedDocumentTypeId={setSelectedDocumentTypeId}
          validateDate={validateDate}
          documentCollection={documentCollection}
          workflowTaskFolderName={workflowTaskResponse?.workflowTask?.folderName || ""}
        />
      </Box>
      <Box display="flex" justifyContent="end" gap={4}>
        {workflowTaskResponse &&
          workflowTaskResponse.workflowTask &&
          [...workflowTaskResponse.workflowTask.actions].reverse().map((action) => {
            const isWorkflowNotApproved =
              action.type === "workflow-keys" && action.key.toLowerCase() !== "approve";

            return (
              action.type === "workflow-keys" && (
                <Box display="flex" key={action.key}>
                  <Button
                    disabled={isWorkflowNotApproved ? false : disableApprove}
                    color={isWorkflowNotApproved ? "transparent" : "blue"}
                    text={getActionButtonLabel(action.key)}
                    onClick={() => {
                      setPopoverState({
                        actionName: action.key,
                        isVisible: true,
                      });
                    }}
                  />
                </Box>
              )
            );
          })}
      </Box>

      <Box color={colors.white} paddingY={4} borderRadius={12} borderStyle="sm">
        <CommentsLayout
          onAddComment={addComment}
          data={comments?.taskComments}
          commentsTotal={commentsTotal}
          isLoadingComments={isLoadingComments}
          onCommentUpdate={updateCommentList}
          taskSelected={{
            taskId: taskId,
            processId: memoizedProcessId || "",
            taskStageName: workflowTaskResponse?.workflowTask?.workflowTaskName,
          }}
          myPublicAvatarImageComponent={
            <PublicAvatarImage username={user?.email || ""} size={32}></PublicAvatarImage>
          }
          OtherUsersPublicAvatarImage={PublicAvatarImage}
          PublicProfileComponent={PublicProfile}
          resources={resources}
          moment={moment}
          refetchComments={refetchComments}
        />
        {comments?.taskComments && comments?.taskComments.length > 0 && (
          <Box paddingX={6} display="flex" justifyContent="between" alignItems="center" flex="grow">
            <Box />
            <Box>
              <Pagination<typeof rowsPerPage>
                id="task-details-pagination"
                rowsLabel={`${resources.comments}`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={comments?.taskComments.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
              />
            </Box>
          </Box>
        )}
      </Box>

      {popoverState.isVisible && (
        <TaskModal
          action={popoverState.actionName}
          setPopoverState={setPopoverState}
          saveTaskInstructions={saveTaskInstructions}
          workflowTaskName={workflowTaskResponse?.workflowTask?.workflowTaskName}
          getActionButtonLabel={getActionButtonLabel}
        />
      )}
    </Box>
  );
};

const DocumentStatusTypes = ({
  folderView,
  validateDate,
  setFilter,
  filter,
  isMobileOrTablet,
}: {
  folderView: EnhancedDocumentView[];
  validateDate: (date: number | undefined) => string | undefined;
  setFilter: (n: string) => void;
  filter: string;
  isMobileOrTablet: boolean;
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const documentStatusCounts = useMemo(() => {
    const statusCounts = {
      documents: 0,
      toExpire: 0,
      missing: 0,
      expired: 0,
    };

    folderView.forEach((doc) => {
      if (doc.type === "document") {
        if (validateDate(doc.expirationDate) === "expired-document") {
          statusCounts.expired = statusCounts.expired + 1;
        }
        if (validateDate(doc.expirationDate) === "expiring-document") {
          statusCounts.toExpire = statusCounts.toExpire + 1;
        }
      }

      if (doc.type === "missing-document") {
        statusCounts.missing = statusCounts.missing + 1;
      }
    });

    statusCounts.documents = folderView.length;

    return statusCounts;
  }, [folderView, validateDate]);

  const documentStatusTypes: DocumentStatusTypesProps = {
    documents: {
      name: resources.documents,
      icon: <DocumentCheckSvgIcon width={25} height={25} />,
      count: documentStatusCounts.documents,
    },
    toExpire: {
      name: resources.toExpire,
      icon: <DocumentToExpireSvgIcon width={25} height={25} />,
      count: documentStatusCounts.toExpire,
    },
    missing: {
      name: resources.missing,
      icon: <DocumentMissingSvgIcon width={25} height={25} />,
      count: documentStatusCounts.missing,
    },
    expired: {
      name: resources.expired,
      icon: <DocumentExpiredSvgIcon width={25} height={25} />,
      count: documentStatusCounts.expired,
    },
  };

  return (
    <Box display="flex" gap={2} padding={2}>
      {Object.keys(documentStatusTypes).map((key) => {
        const documentStatus = documentStatusTypes[key as keyof DocumentStatusTypesProps];
        return (
          <TapArea key={key} onTap={() => setFilter(documentStatus.name)}>
            <Box
              display="flex"
              direction="column"
              alignItems="center"
              justifyContent="center"
              borderStyle="lg"
              borderRadius={4}
              padding={isMobileOrTablet ? 2 : 3}
              borderColor={filter === documentStatus.name ? colors.primary : colors.neutral700}
              color={filter === documentStatus.name ? colors.primaryHover0 : colors.neutral200}
              position="relative"
              width={isMobileOrTablet ? 70 : 80}
            >
              {filter === documentStatus.name && filter !== resources.documents && (
                <Box
                  position="absolute"
                  top
                  right
                  marginTop={-3}
                  marginEnd={-3}
                  onClickCapture={() => setFilter(resources.documents)}
                >
                  <CircleXIcon width={24} height={25} color={colors.subtle} />
                </Box>
              )}
              <Box display="flex" alignItems="center" gap={2}>
                {documentStatus.icon}
                <Text size="100">{documentStatus.count}</Text>
              </Box>
              <Box display="flex" gap={2}>
                <Text size="100" overflow="ellipsis" title={documentStatus.name}>
                  {documentStatus.name}
                </Text>
              </Box>
            </Box>
          </TapArea>
        );
      })}
    </Box>
  );
};
const TaskModal = ({
  action,
  setPopoverState,
  saveTaskInstructions,
  workflowTaskName,
  getActionButtonLabel,
}: TaskModalProps) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const [comment, setComment] = useState<string>("");
  const [disableApprove, setIsButtonDisabled] = useState<boolean>(true);

  const resetPopoverState = useCallback(() => {
    setPopoverState({ actionName: "", isVisible: false });
  }, [setPopoverState]);

  const calculateTaskStatus = useMemo(() => {
    return action === "Approve" ? "done" : "open";
  }, [action]);

  return (
    <Layer zIndex={modalZIndex}>
      <Modal
        accessibilityModalLabel={action}
        heading={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="between"
            top
            right
            width={"100%"}
            padding={6}
            position="absolute"
          >
            <Text size="400" color={colors.black900} weight="bold">
              {workflowTaskName}
            </Text>
            <Box>
              <TapArea>
                <XIconSvg onClick={resetPopoverState} />
              </TapArea>
            </Box>
          </Box>
        }
        size="md"
        onDismiss={resetPopoverState}
        footer={
          <Box width={"100%"} display="flex" gap={2} justifyContent="end" alignItems="end">
            <Button
              text={getActionButtonLabel(action)}
              disabled={disableApprove}
              onClick={() =>
                saveTaskInstructions({
                  state: calculateTaskStatus,
                  action: action,
                  comment: comment,
                })
              }
            />
          </Box>
        }
      >
        <Box display="flex" direction="column" gap={2}>
          <Box>
            <Text>
              {resources.taskCompletionStatusMessage} {action}. {resources.decisionDetailsPrompt}
            </Text>
          </Box>
          <TextArea
            id={resources.comments}
            onChange={({ value }) => {
              setComment(value);
              setIsButtonDisabled(value.length === 0);
            }}
            placeholder={resources.decisionDetailsPrompt}
          />
        </Box>
      </Modal>
    </Layer>
  );
};

const FolderComponent = ({
  folder,
  handleDocumentAction,
  setSelectedTypeId,
  DocumentViewAction,
  setSelectedDocumentTypeId,
  validateDate,
  documentCollection,
  workflowTaskFolderName,
}: FolderComponentProps) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const displayedColor = useCallback(
    (type: string, date: number | undefined) => {
      switch (type) {
        case "missing-document":
          return colors.error;
        case "document":
          if (validateDate(date) === "expired-document") {
            return colors.other500;
          } else if (validateDate(date) === "expiring-document") {
            return colors.other400;
          } else {
            return undefined;
          }
        case "expired-document":
          return colors.other500;
        case "expiring-document":
          return colors.other400;
      }
    },
    [colors, validateDate]
  );

  return (
    <Box display="flex" direction="column" gap={3} flex="grow" margin={1} padding={6}>
      {folder.flatMap((doc, index) => (
        <DocumentOptionsMenu
          key={index}
          index={index}
          doc={doc}
          displayedColor={displayedColor}
          validateDate={validateDate}
          handleDocumentAction={handleDocumentAction}
          setSelectedTypeId={setSelectedTypeId}
          DocumentViewAction={DocumentViewAction}
          setSelectedDocumentTypeId={setSelectedDocumentTypeId}
          valueMap={doc.valueMap}
          folderName={doc.folderName}
          documentCollection={documentCollection}
          workflowTaskFolderName={workflowTaskFolderName}
        />
      ))}
      {folder.length === 0 && (
        <Box
          padding={4}
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <NoData />
          <Text weight="bold" color={colors.neutral700}>
            {resources.documentsNotFound}
          </Text>
        </Box>
      )}
    </Box>
  );
};

const DocumentOptionsMenu = ({
  doc,
  index,
  validateDate,
  displayedColor,
  handleDocumentAction,
  setSelectedTypeId,
  DocumentViewAction,
  setSelectedDocumentTypeId,
  valueMap,
  folderName,
  documentCollection,
  workflowTaskFolderName,
}: DocumentOptionsMenuProps) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const ACTION_DELAY = 100;

  const { data } = useDocumentTypeInfoWithLatestVersion(doc.documentTypeId, !!doc.documentTypeId);

  const generateDocumentOptions = useMemo(() => {
    const baseOptions = [];
    const documentActions = {
      generate: {
        label: resources.generate,
        icon: <GenerateDocumentGearIcon height={20} width={20} />,
        documentType: doc.type,
      },
      scan: {
        label: resources.scanADocument,
        icon: <PrinterSvgIcon height={20} width={20} />,
        documentType: doc.type,
      },
      view: {
        label: resources.view,
        icon: <ViewLogSvg height={20} width={20} />,
        documentType: doc.type,
      },
      signatures: {
        label: resources.signatures,
        icon: (
          <Icon
            icon={"pencil"}
            accessibilityLabel={resources.signatures}
            color={colors.neutral800}
          />
        ),
        documentType: doc.type,
      },
      importADocument: {
        label: resources.importADocument,
        icon: <ImportSvgDownArrowIcon width={20} height={20} />,
        documentType: doc.type,
      },
    };

    if (doc.type === "document") {
      baseOptions.push(documentActions.view);
    }

    if (doc.type === "document" && validateDate(doc.expirationDate) !== "expired-document") {
      baseOptions.push(documentActions.signatures);
    }

    if (doc.type === "document" && validateDate(doc.expirationDate) === "expired-document") {
      baseOptions.push(documentActions.importADocument);
    }

    if (doc.type === "missing-document") {
      if (data?.documentType?.templateId && data?.documentType?.templateId?.length > 0) {
        baseOptions.push(documentActions.generate);
      } else {
        baseOptions.push(documentActions.importADocument, documentActions.scan);
      }
    }

    return baseOptions;
  }, [colors, data, doc, resources, validateDate]);

  const executeActionBasedOnType = useCallback(
    (type: string, id: string) => {
      const isSetSelectedTypeIdRequired = [
        resources.importADocument,
        resources.scanADocument,
        resources.generate,
      ].includes(type);

      if (isSetSelectedTypeIdRequired) {
        setSelectedTypeId({
          documentTypeId: id,
          valueMap: valueMap,
        });
      }

      const performActionWithDelay = (action: () => void) => {
        setTimeout(action, ACTION_DELAY);
      };
      switch (type) {
        case resources.importADocument:
        case resources.scanADocument:
        case resources.generate:
          performActionWithDelay(() =>
            handleDocumentAction(type === resources.importADocument, type === resources.generate)
          );
          break;
        case resources.view:
        case resources.signatures:
          setSelectedDocumentTypeId(doc.documentTypeId);
          performActionWithDelay(() => {
            DocumentViewAction(type === resources.signatures);
          });
      }
    },
    [
      resources.importADocument,
      resources.scanADocument,
      resources.generate,
      resources.view,
      resources.signatures,
      setSelectedTypeId,
      valueMap,
      setSelectedDocumentTypeId,
      doc.documentTypeId,
      DocumentViewAction,
      handleDocumentAction,
    ]
  );

  const { breakpoint } = useDesignBreakpoint();

  return (
    <Box display="flex" flex="grow" borderStyle="lg" overflow="hidden" borderRadius={12}>
      <Box width={3}>
        <Divider
          direction="vertical"
          color={displayedColor(doc.type, doc.type === "document" ? doc.expirationDate : undefined)}
        />
      </Box>
      <TapArea>
        <Box display="flex" flex="grow" alignItems="center" gap={4} key={index} padding={2}>
          <Box minWidth={40} minHeight={40}>
            <DocumentInstructionIcon
              type={doc.type}
              date={doc.type === "document" ? doc.expirationDate : undefined}
              validateDate={validateDate}
            />
          </Box>

          <Box
            display="flex"
            alignItems={breakpoint === "small" ? "start" : "center"}
            justifyContent="between"
            flex="grow"
            direction={breakpoint === "small" ? "column" : "row"}
          >
            <DocumentTypeBox
              folderName={folderName}
              name={doc.name}
              documentCollection={documentCollection}
              workflowTaskFolderName={workflowTaskFolderName}
            />
            <Box display="flex" gap={1} alignSelf="end">
              {generateDocumentOptions.map((items, idx) => (
                <Tooltip text={items.label} key={idx}>
                  <Box
                    hoverColor={colors.neutral500}
                    borderRadius={6}
                    padding={2}
                    borderColor={colors.white}
                    onClickCapture={() =>
                      executeActionBasedOnType(items.label as string, doc.documentTypeId)
                    }
                  >
                    <Box>{items.icon}</Box>
                  </Box>
                </Tooltip>
              ))}
            </Box>
          </Box>
        </Box>
      </TapArea>
    </Box>
  );
};

const DocumentInstructionIcon = ({
  type,
  date,
  validateDate,
}: {
  type: string;
  date: number | undefined;
  validateDate: (n: number | undefined) => string | undefined;
}) => {
  switch (type) {
    case "missing-document":
      return <DocumentPendingSvg />;
    case "document":
      if (validateDate(date) === "expired-document") {
        return <DocumentExpiredSvg />;
      } else if (validateDate(date) === "expiring-document") {
        return <DocumentExpiringSvg />;
      } else {
        return <DocumentInstructionsSvg />;
      }
    default:
      return <DocumentInstructionsSvg />;
  }
};

const DocumentTypeBox = ({
  name,
  folderName,
  documentCollection,
  workflowTaskFolderName,
}: {
  name: string | undefined;
  folderName: string;
  documentCollection: DocumentCollectionView | undefined;
  workflowTaskFolderName: string | undefined;
}) => {
  const [showNameTask, setShowNameTask] = useState(false);
  const [showChevron, setShowChevron] = useState(false);
  const { colors } = useColors();

  const folderIcons = useMemo(() => {
    return {
      iconKey: documentCollection?.rootFolder.icon ? documentCollection?.rootFolder.icon : "folder",
      color: documentCollection?.rootFolder.color,
    };
  }, [documentCollection]);

  return (
    <Box display={"flex"} direction="row" flex="grow" justifyContent="between">
      <TapArea onMouseEnter={() => setShowChevron(true)} onMouseLeave={() => setShowChevron(false)}>
        <Box display="flex" alignItems="center">
          <Text weight="bold" title={folderName}>
            {name}
          </Text>
          {showChevron && workflowTaskFolderName !== folderName && (
            <Box marginBottom={3} height={15} onClickCapture={() => setShowNameTask(!showNameTask)}>
              <ChevronSvgIcon
                width={30}
                height={30}
                color={showNameTask ? colors.primary : colors.neutral700}
                direction={showNameTask ? "up" : "down"}
              />
            </Box>
          )}
        </Box>

        {showNameTask && workflowTaskFolderName !== folderName && (
          <Box display="flex" alignItems="center" gap={2}>
            <RenderDocumentCollectionIcon
              height={16}
              width={16}
              iconKey={folderIcons.iconKey}
              color={folderIcons.color}
            />
            <Text weight="bold" size="100">
              {folderName}
            </Text>
          </Box>
        )}
      </TapArea>
    </Box>
  );
};
