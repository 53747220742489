import {
  Box,
  BoxWithRef,
  Button,
  Layer,
  Popover,
  SearchField,
  TapArea,
  Text,
  Tooltip,
} from "@prodoctivity/design-system";
import React, { FunctionComponent, useState } from "react";
import { PaginationRightIconSvg } from "../../svg/Pagination/PaginationRightIconSvg";
import { useCascadeOptionsPopover } from "./hook";

export type CascadeOptionsPopoverItem = {
  childIcon: React.ReactNode | undefined;
  childLabel: string | undefined;
  childHoverLabel: string;
  treeItem: boolean;
  treeContent: React.ReactNode | undefined;
  key: string;
};

type Props = {
  icon: React.ReactNode | undefined;
  label: string | undefined;
  hoverLabel: string | undefined;
  items: CascadeOptionsPopoverItem[];
  onClick: ((key: string) => void) | undefined;
  searchFilter: boolean;
  childPopoverActionOnClose: (() => void) | undefined;
};

export const CascadeOptionsPopover: FunctionComponent<Props> = ({
  icon,
  label,
  items,
  onClick,
  searchFilter,
  hoverLabel,
  childPopoverActionOnClose,
}) => {
  const {
    anchorRef,
    newAnchorRefs,
    colors,
    filteredOptions,
    handlePopoverState,
    handleSearch,
    open,
    setOpen,
    resources,
    searchTerm,
    setSearchTerm,
  } = useCascadeOptionsPopover(items);
  const hover = hoverLabel ? hoverLabel : "";

  const [treeItemMenu, setTreeItemMenu] = useState(false);

  return (
    <BoxWithRef ref={anchorRef}>
      <TapArea onTap={handlePopoverState}>
        <Tooltip text={hover} doNotShowIf={() => !hover}>
          <Box display="flex" gap={2} direction="row">
            <Box display="flex">{icon}</Box>
            {label && <Text>{label}</Text>}
          </Box>
        </Tooltip>
      </TapArea>
      {open && (
        <Layer>
          <Popover
            accessibilityLabel={resources.icon}
            anchor={anchorRef.current}
            id="popover-icon"
            onDismiss={() => {
              if (!treeItemMenu) {
                setOpen(false);
              }
            }}
            showDismissButton={false}
            positionRelativeToAnchor={false}
            size={"flexible"}
            shouldFocus={true}
            role="menu"
          >
            <Box
              color={colors.white}
              width={"100%"}
              minWidth={200}
              display="flex"
              direction="column"
              justifyContent="between"
              alignItems="start"
              paddingX={2}
            >
              {searchFilter && (
                <Box display="flex" margin={2}>
                  <SearchField
                    value={searchTerm}
                    accessibilityLabel={resources.search}
                    id={"popover-icon-search"}
                    onChange={(item) => handleSearch(item.value)}
                  />
                </Box>
              )}
              <Box display="flex" direction="column" flex="shrink" width={"100%"}>
                {filteredOptions.map((option, index) => (
                  <Box key={option.key} display="flex" flex="grow" paddingY={2}>
                    <TapArea
                      onTap={() => {
                        if (onClick) {
                          onClick(option.key);
                        }
                      }}
                      fullWidth={true}
                    >
                      <Tooltip
                        text={option.childHoverLabel}
                        doNotShowIf={() => !option.childHoverLabel}
                      >
                        <BoxWithRef
                          ref={(ref) => {
                            newAnchorRefs.current[index] = ref;
                          }}
                          width={"100%"}
                          flex="grow"
                          borderRadius={6}
                          borderColor={colors.white}
                          hoverColor={colors.primaryHover0}
                          onMouseOver={() => {
                            option.treeItem ? setTreeItemMenu(true) : setTreeItemMenu(false);
                          }}
                          onMouseLeave={() => {
                            setTreeItemMenu(false);
                          }}
                          color={colors.white}
                          display="flex"
                          direction="row"
                        >
                          <Box
                            display="flex"
                            direction="row"
                            alignItems="center"
                            paddingX={2}
                            justifyContent="start"
                            gap={2}
                            height={40}
                            flex="grow"
                          >
                            <Box>{option.childIcon}</Box>

                            <Box display="flex" flex="grow">
                              <Text size="200">{option.childLabel}</Text>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center">
                            {option.treeContent && <PaginationRightIconSvg />}
                          </Box>
                        </BoxWithRef>
                      </Tooltip>
                    </TapArea>
                    <Box display="flex" flex="shrink">
                      {option.treeItem && treeItemMenu && (
                        <Layer>
                          <Popover
                            anchor={newAnchorRefs.current[index]}
                            onDismiss={() => {
                              setTreeItemMenu(false);
                              setOpen(false);
                              if (childPopoverActionOnClose) {
                                childPopoverActionOnClose();
                              }
                            }}
                            positionRelativeToAnchor={false}
                            shouldFocus={true}
                            showDismissButton={false}
                            role="menu"
                            size="flexible"
                            idealDirection={"right" || "left"}
                          >
                            <Box width={300}>{option.treeContent}</Box>
                          </Popover>
                        </Layer>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
              {searchFilter && (
                <Box>
                  <Button onClick={() => setSearchTerm("")} text={resources.clearFilter} />
                </Box>
              )}
            </Box>
          </Popover>
        </Layer>
      )}
    </BoxWithRef>
  );
};
