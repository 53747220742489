import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const GridMediumIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 23,
  height = 22,
  color: rawColor,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7679 0.658388C20.6567 0.658388 21.3809 1.38262 21.3809 2.27144V18.7904C21.3809 19.6792 20.6567 20.4035 19.7679 20.4035H2.58394C1.69512 20.4035 0.970888 19.6792 0.970888 18.7904V2.27144C0.970888 1.38262 1.69512 0.658388 2.58394 0.658388H19.7613M19.7613 0H2.58394C1.333 0 0.3125 1.0205 0.3125 2.27144V18.7904C0.3125 20.0413 1.333 21.0618 2.58394 21.0618H19.7613C21.0122 21.0618 22.0327 20.0413 22.0327 18.7904V2.27144C22.0327 1.0205 21.0122 0 19.7613 0Z"
        fill={calculateColor(color)}
      />
      <path
        d="M9.12884 2.71289H4.39503C3.72597 2.71289 3.18359 3.25527 3.18359 3.92432V8.65814C3.18359 9.32719 3.72597 9.86957 4.39503 9.86957H9.12884C9.7979 9.86957 10.3403 9.32719 10.3403 8.65814V3.92432C10.3403 3.25527 9.7979 2.71289 9.12884 2.71289Z"
        fill={calculateColor(color)}
      />
      <path
        d="M17.957 2.71289H13.2232C12.5541 2.71289 12.0117 3.25527 12.0117 3.92432V8.65814C12.0117 9.32719 12.5541 9.86957 13.2232 9.86957H17.957C18.626 9.86957 19.1684 9.32719 19.1684 8.65814V3.92432C19.1684 3.25527 18.626 2.71289 17.957 2.71289Z"
        fill={calculateColor(color)}
      />
      <path
        d="M9.12884 11.2051H4.39503C3.72597 11.2051 3.18359 11.7475 3.18359 12.4165V17.1503C3.18359 17.8194 3.72597 18.3618 4.39503 18.3618H9.12884C9.7979 18.3618 10.3403 17.8194 10.3403 17.1503V12.4165C10.3403 11.7475 9.7979 11.2051 9.12884 11.2051Z"
        fill={calculateColor(color)}
      />
      <path
        d="M17.957 11.2051H13.2232C12.5541 11.2051 12.0117 11.7475 12.0117 12.4165V17.1503C12.0117 17.8194 12.5541 18.3618 13.2232 18.3618H17.957C18.626 18.3618 19.1684 17.8194 19.1684 17.1503V12.4165C19.1684 11.7475 18.626 11.2051 17.957 11.2051Z"
        fill={calculateColor(color)}
      />
    </svg>
  );
};
