import { createUuid, shouldNever } from "@prodoctivity/shared";
import type { Dispatch, SetStateAction } from "react";

const promises: {
  [correlationId: string]: {
    promise: Promise<any>;
    reject(e: any): void;
    resolve(x: any): void;
  };
} = {};

let setFrameAsInitialized: Dispatch<SetStateAction<boolean>> | undefined = undefined;

export interface TextControlFrameConfig {
  /**
   * Eg: /Embed
   */
  textControlLocation: string;
  frameName: string;
  setIframeInitialized: Dispatch<SetStateAction<boolean>>;
}

export type TextControlFrame = {
  create(): Promise<CreateDocumentResponse>;
  load(templateVersionId: string): Promise<LoadDocumentResponse>;
  save(): Promise<SaveDocumentResponse>;
  updateToken(token: string): Promise<UpdateTokenResponse>;
};

enum MessageRequestType {
  CreateDocument = "CreateDocumentRequest",
  LoadDocument = "LoadDocumentRequest",
  SaveDocument = "SaveDocumentRequest",
  UpdateToken = "UpdateTokenRequest",
}

enum MessageResponseType {
  CreateDocument = "CreateDocumentResponse",
  LoadDocument = "LoadDocumentResponse",
  SaveDocument = "SaveDocumentResponse",
  UpdateToken = "UpdateTokenResponse",
}

enum FrameNotifications {
  TemplateSaved = "TemplateSaved",
  TextControlLoaded = "TextControlLoaded",
  TemplateCreationAborted = "TemplateCreationAborted",
}

type TemplateSavedNotification = { type: "TemplateSaved" };
type TextControlLoaded = { type: "TextControlLoaded" };
type TemplateCreationAborted = { type: "TemplateCreationAborted" };
interface MessageResponse {
  correlationId: string;
  type: MessageResponseType;
}

interface MessageRequest {
  correlationId: string;
  type: MessageRequestType;
}

interface CreateDocumentRequest extends MessageRequest {
  type: MessageRequestType.CreateDocument;
}

interface CreateDocumentResponse extends MessageResponse {
  type: MessageResponseType.CreateDocument;
}
interface LoadDocumentRequest extends MessageRequest {
  type: MessageRequestType.LoadDocument;
  templateVersionId: string;
}

interface LoadDocumentResponse extends MessageResponse {
  type: MessageResponseType.LoadDocument;
}

interface SaveDocumentRequest extends MessageRequest {
  type: MessageRequestType.SaveDocument;
}

interface SaveDocumentResponse extends MessageResponse {
  type: MessageResponseType.SaveDocument;
  data: string;
}

interface UpdateTokenRequest extends MessageRequest {
  type: MessageRequestType.UpdateToken;
  token: string;
}

interface UpdateTokenResponse extends MessageResponse {
  type: MessageResponseType.UpdateToken;
}

type TextControlFrameMessageRequest =
  | CreateDocumentRequest
  | LoadDocumentRequest
  | SaveDocumentRequest
  | UpdateTokenRequest;

type TextControlFrameMessageResponse =
  | CreateDocumentResponse
  | LoadDocumentResponse
  | SaveDocumentResponse
  | UpdateTokenResponse;

type TextControlFrameNotification =
  | TemplateSavedNotification
  | TextControlLoaded
  | TemplateCreationAborted;

function postMessage(targetWindow: Window, msg: TextControlFrameMessageRequest): Promise<any> {
  let promise: any = {};
  promise = new Promise<any>((resolve, reject) => {
    promises[msg.correlationId] = { resolve, reject, promise };
    targetWindow.postMessage(JSON.stringify(msg), "*");
  });
  return promise;
}

function isMessageResponse(data: any): data is TextControlFrameMessageResponse {
  return (
    data.correlationId &&
    Object.keys(MessageResponseType).some(
      (k) => MessageResponseType[k as keyof typeof MessageResponseType] === data.type
    )
  );
}

function isNotification(data: any): data is TextControlFrameNotification {
  return Object.values(FrameNotifications).some((k) => k === data.type);
}

function messageHandler(this: Window, ev: MessageEvent) {
  if (ev.data) {
    const jsonObj = typeof ev.data === "string" ? JSON.parse(ev.data) : ev.data;
    if (isNotification(jsonObj)) {
      switch (jsonObj.type) {
        case "TemplateSaved":
          // Go back to home
          window.location.assign("/");
          break;
        case "TemplateCreationAborted":
          // Go back to home
          window.location.assign("/");
          break;
        case "TextControlLoaded":
          if (setFrameAsInitialized) {
            setFrameAsInitialized(true);
          }
          break;
      }
    }
    if (isMessageResponse(jsonObj)) {
      const response: TextControlFrameMessageResponse = jsonObj;
      const responseType = response.type;
      switch (responseType) {
        case MessageResponseType.CreateDocument:
        case MessageResponseType.LoadDocument:
        case MessageResponseType.SaveDocument:
        case MessageResponseType.UpdateToken:
          const thisPromise = promises[response.correlationId];
          if (thisPromise) {
            thisPromise.resolve(response);
          }
          break;
        default:
          shouldNever(responseType);
      }
    }
  }
}

export function initTextControlFrame(
  mountPoint: HTMLElement,
  config: TextControlFrameConfig
): Promise<TextControlFrame> {
  const iframe: HTMLIFrameElement = document.createElement("iframe");
  iframe.style.width = "100vw";
  iframe.style.height = "100vh";
  iframe.name = config.frameName;
  iframe.src = config.textControlLocation;
  mountPoint.appendChild(iframe);
  setFrameAsInitialized = config.setIframeInitialized;
  window.addEventListener("message", messageHandler, false);

  return new Promise<TextControlFrame>((resolve, reject) => {
    iframe.addEventListener("load", () => {
      resolve({
        create() {
          const iframeElement: HTMLIFrameElement = document.getElementsByName(
            config.frameName
          )[0] as HTMLIFrameElement;
          if (iframeElement.contentWindow) {
            return postMessage(iframeElement.contentWindow, {
              correlationId: createUuid(),

              type: MessageRequestType.CreateDocument,
            });
          } else {
            return Promise.reject();
          }
        },
        load(templateVersionId: string) {
          const iframeElement: HTMLIFrameElement = document.getElementsByName(
            config.frameName
          )[0] as HTMLIFrameElement;
          if (iframeElement.contentWindow) {
            return postMessage(iframeElement.contentWindow, {
              correlationId: createUuid(),
              templateVersionId,
              type: MessageRequestType.LoadDocument,
            });
          } else {
            return Promise.reject();
          }
        },
        save() {
          const iframeElement: HTMLIFrameElement = document.getElementsByName(
            config.frameName
          )[0] as HTMLIFrameElement;
          if (iframeElement.contentWindow) {
            return postMessage(iframeElement.contentWindow, {
              correlationId: createUuid(),
              type: MessageRequestType.SaveDocument,
            });
          } else {
            return Promise.reject();
          }
        },
        updateToken(token: string) {
          const iframeElement: HTMLIFrameElement = document.getElementsByName(
            config.frameName
          )[0] as HTMLIFrameElement;
          if (iframeElement.contentWindow) {
            return postMessage(iframeElement.contentWindow, {
              correlationId: createUuid(),
              type: MessageRequestType.UpdateToken,
              token: token,
            });
          } else {
            return Promise.reject();
          }
        },
      });
    });
    iframe.addEventListener("error", reject);
  });
}
