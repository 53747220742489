import {
  Box,
  BoxWithRef,
  ComboBox,
  DatePicker,
  Grid,
  Icon,
  Table,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { range } from "@prodoctivity/shared";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { BrowseDocumentsFilter, organizationLinkTemplates } from "../../link-templates";
import { TemplateCardSkeleton } from "../../loading";
import { ArrowSvg } from "../../svg/ArrowSvg";
import { DocIconsSvg } from "../../svg/DocumentIconsSvg";
import { EllipseMenuSvg } from "../../svg/EllipseMenuSvg";
import { GridViewIconsSvg } from "../../svg/GridViewIconsSvg";
import { ListOutlineIconSvg } from "../../svg/ListOutlineIconSvg";
import { XIconSvg } from "../../svg/XIconSvg";
import { BreadCrumb } from "../BreadCrumb";
import { DocumentTypeInfo } from "../Display/DocumentTypeInfo";
import { OrganizationUserLookup } from "../Display/OrganizationUserLookup";
import { DocumentBookmarkTags } from "../DocumentBookmarkTags/DocumentBookmarkTags";
import { DocumentCard } from "../DocumentCard";
import { Pagination } from "../Layout/Pagination";
import { Topic } from "../Layout/Topic";

import { FancyDateTime } from "@prodoctivity/design-system";
import { MenuSvg } from "../../svg/MenuSvg";
import {
  CascadeOptionsPopover,
  CascadeOptionsPopoverItem,
} from "../CascadeOptionsPopover/CascadeOptionsPopover";
import { PublicProfile } from "../Profile/Public/PublicProfile";
import { SmallRefreshButton } from "../SmallRefreshButton";
import { useBrowseDocumentsList } from "./hooks";

const cardWidth = "244px";

type Props = {
  hideTitle?: boolean;
  filter: BrowseDocumentsFilter;
};

export const BrowseDocumentsList: FunctionComponent<Props> = ({ hideTitle, filter }: Props) => {
  const { colors } = useColors();

  const anchorRefItems = useRef<Array<HTMLDivElement | null>>([]);
  const { breakpoint } = useDesignBreakpoint();
  const isSmallOrMediumBreakpoint = breakpoint === "small" || breakpoint === "medium";
  const { resources, moment } = useAppTranslation();
  const templateCols = useMemo(() => {
    return [`repeat(auto-fill, ${cardWidth})`];
  }, []);

  const [documentId, setDocumentId] = useState<string>("");
  const CascadeOptionsPopoverList: CascadeOptionsPopoverItem[] = useMemo(() => {
    return [
      {
        childIcon: <MenuSvg width={40} height={20} />,
        childLabel: resources.viewDocument,
        childHoverLabel: resources.viewDocument,
        treeItem: false,
        treeContent: undefined,
        key: resources.viewDocument,
      },
      {
        childIcon: (
          <Icon
            icon={"copy"}
            accessibilityLabel={resources.browseDocument.copyDocumentId}
            color={colors.neutral900}
            size={"sm"}
          />
        ),
        childLabel: resources.browseDocument.copyDocumentId,
        childHoverLabel: resources.browseDocument.copyDocumentId,
        treeItem: false,
        treeContent: undefined,
        key: resources.browseDocument.copyDocumentId,
      },
    ];
  }, [colors.neutral900, resources.browseDocument.copyDocumentId, resources.viewDocument]);

  const {
    documentTypes,
    documentTypeIsLoading,
    documentsData,
    onSelectDocumentType,
    selectedDocumentTypes,
    currentPage,
    previousPage,
    nextPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    rowsPerPage,
    totalRowCount,
    pageLengthOptions,
    documentTypeHandleClick,
    documentTypeComboBoxInputValue,
    setDocumentTypeComboBoxInputValue,
    clearAll,
    handleMoveTo,
    handleFilterChange,
    setRowsPerPage,
    changeSort,
    refetch,
  } = useBrowseDocumentsList(filter);
  const handleCascadeOptionsPopoverClick = useCallback(
    (key: string) => {
      if (documentId) {
        switch (key) {
          case resources.viewDocument:
            handleMoveTo(documentId);
            break;
          case resources.browseDocument.copyDocumentId:
            navigator.clipboard.writeText(documentId);
            break;
          default:
            break;
        }
      }
    },
    [documentId, handleMoveTo, resources.viewDocument, resources.browseDocument.copyDocumentId]
  );

  const [displayDocumentsAsCards, setDisplayDocumentsAsCards] = useState(
    localStorage.getItem("cards") === "true"
  );

  const showOnCards = useCallback(() => {
    const newCardsState = !displayDocumentsAsCards;
    localStorage.setItem("cards", String(newCardsState));
    setDisplayDocumentsAsCards(newCardsState);
  }, [displayDocumentsAsCards]);

  const documentTypeList = useMemo(() => {
    const filteredDocumentTypes = documentTypes.filter(
      (type) =>
        !selectedDocumentTypes.some((item) => item.label === type.name) &&
        type.name.toLowerCase().includes(documentTypeComboBoxInputValue.toLowerCase())
    );
    return filteredDocumentTypes.map((type) => ({
      label: type.name,
      value: type.documentTypeId,
    }));
  }, [documentTypeComboBoxInputValue, documentTypes, selectedDocumentTypes]);

  const handleComboBoxClear = useCallback(() => {
    setDocumentTypeComboBoxInputValue("");
  }, [setDocumentTypeComboBoxInputValue]);

  if (documentTypeIsLoading) {
    return (
      <>
        {!hideTitle && (
          <>
            <Box paddingX={isSmallOrMediumBreakpoint ? undefined : 1}>
              <Topic>{resources.browseDocument.browseDocuments}</Topic>
            </Box>
            <Box margin={isSmallOrMediumBreakpoint ? undefined : 1} />
          </>
        )}

        <Box width={"100%"} display="flex" flex="grow" direction="column">
          <Grid width="100%" gridTemplateColumns={templateCols} gap={2}>
            {range(8).map((_, i) => (
              <TemplateCardSkeleton key={i} />
            ))}
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box color={colors.white}>
        <BreadCrumb
          entries={[
            { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
            { type: "text", name: resources.browseDocument.browseDocuments },
          ]}
        />
      </Box>
      <Box>
        <Box padding={6}>
          <Box display="flex" alignItems="center" justifyContent="between" width={"99.5%"}>
            <Box display="flex" direction="row" alignItems="center">
              <Text size="400" weight="bold" color={colors.black600}>
                {resources.browseDocument.browseDocuments}
              </Text>
              <Box>
                <SmallRefreshButton
                  accessibilityLabel={resources.refresh}
                  refetch={refetch}
                  marginTop={0}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" onClickCapture={showOnCards}>
              <TapArea>
                {displayDocumentsAsCards || breakpoint === "hd" ? (
                  <GridViewIconsSvg />
                ) : (
                  <ListOutlineIconSvg />
                )}
              </TapArea>
            </Box>
          </Box>
          <Box display="flex" flex="grow" direction="column">
            <Box
              paddingY={2}
              display="flex"
              direction={!isSmallOrMediumBreakpoint ? "row" : "column"}
              width={"100%"}
              justifyContent={!isSmallOrMediumBreakpoint ? "between" : "start"}
            >
              <Box width={!isSmallOrMediumBreakpoint ? "30%" : "100%"} padding={1}>
                <ComboBox
                  id="documentType"
                  onClear={handleComboBoxClear}
                  label=""
                  inputValue={documentTypeComboBoxInputValue}
                  onChange={(event) => setDocumentTypeComboBoxInputValue(event.value as string)}
                  accessibilityClearButtonLabel={resources.clear}
                  noResultText={resources.dataDictionary.noItems}
                  onSelect={onSelectDocumentType}
                  size="lg"
                  options={documentTypeList}
                  placeholder={resources.documentTypes.documentTypeName}
                />
              </Box>

              <Box padding={1} width={!isSmallOrMediumBreakpoint ? "25%" : "100%"}>
                <OrganizationUserLookup
                  id="browse_documents_author_lookup"
                  label={""}
                  accessibilityClearButtonLabel={resources.clear}
                  noResultText={resources.noUsers}
                  placeholder={resources.browseDocument.author}
                  size="lg"
                  onSelect={(element) => {
                    return handleFilterChange("updatedBy", {
                      value: element.item.value,
                    });
                  }}
                  onClear={() => {
                    handleFilterChange("updatedBy", {
                      value: undefined,
                    });
                  }}
                  selectedUsername={filter.updatedBy}
                />
              </Box>

              <Box display="flex" padding={1} gap={2} justifyContent="center" alignItems="center">
                <DatePicker
                  resources={resources}
                  id="startDate"
                  placeholder={resources.from}
                  value={filter.dateFrom ? new Date(filter.dateFrom) : undefined}
                  onChange={(value) => {
                    const dateValue = value.value;
                    const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;

                    handleFilterChange("dateFrom", { value: timestamp });
                  }}
                  dataType="Date"
                />

                <DatePicker
                  resources={resources}
                  id="dateEnd"
                  value={filter.dateTo ? new Date(filter.dateTo) : undefined}
                  placeholder={resources.to}
                  onChange={(value) => {
                    const dateValue = value.value;
                    const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;

                    handleFilterChange("dateTo", { value: timestamp });
                  }}
                  dataType="Date"
                />
              </Box>
              <Box display="flex" direction="column" flex="shrink" minWidth={230}>
                <Pagination<typeof rowsPerPage>
                  id="Browse"
                  rowsLabel={`${resources.documents}:`}
                  currentPage={currentPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  pageLength={documentsData?.length || 0}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  isNextButtonDisabled={isNextButtonDisabled}
                  isPreviousButtonDisabled={isPreviousButtonDisabled}
                  pageLengthOptions={pageLengthOptions}
                  totalRowCount={totalRowCount}
                />
              </Box>
              <Box display="flex" flex="shrink" alignItems="center" minWidth={60}>
                <TapArea onTap={clearAll} accessibilityLabel={resources.clearAll}>
                  <Text color={colors.primary}>{resources.clearAll}</Text>
                </TapArea>
              </Box>
            </Box>

            <Box display={"flex"} padding={2} justifyContent={"between"} alignItems={"center"}>
              {selectedDocumentTypes && selectedDocumentTypes.length > 0 && (
                <Box display="flex" flex="grow" direction="row" alignItems="center">
                  <Box display="flex" marginEnd={isSmallOrMediumBreakpoint ? 1 : 3}>
                    <Text>{resources.filters}:</Text>
                  </Box>
                  <Box display="flex" wrap={true}>
                    {selectedDocumentTypes.map((dt, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        height={"auto"}
                        width={"fit-content"}
                        key={index}
                        paddingX={isSmallOrMediumBreakpoint ? 2 : 4}
                        paddingY={isSmallOrMediumBreakpoint ? 1 : 2}
                        margin={1}
                        color={colors.white}
                        borderRadius={4}
                        onClickCapture={() => documentTypeHandleClick(index)}
                        dangerouslySetInlineStyle={{
                          __style: {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Text color={colors.neutral900}>{dt.label}</Text>

                        <Box smMarginStart={isSmallOrMediumBreakpoint ? 1 : 3}>
                          <XIconSvg />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            <Box color={displayDocumentsAsCards ? colors.white : undefined}>
              {displayDocumentsAsCards ? (
                <Box
                  borderStyle="lg"
                  marginBottom={12}
                  marginTop={2}
                  paddingY={3}
                  paddingX={isSmallOrMediumBreakpoint ? 2 : 4}
                  borderRadius={4}
                >
                  <Table accessibilityLabel="Basic Table">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan={2}>
                          <Box display="flex" direction="row" alignItems="center" marginBottom={2}>
                            <Box paddingX={isSmallOrMediumBreakpoint ? 2 : 4}>
                              <Icon
                                icon={"document"}
                                accessibilityLabel={resources.document}
                                color={colors.black600}
                                size={"sm"}
                              />
                            </Box>

                            <Text weight="bold" size="300">
                              {resources.documentTypes.documentType.toUpperCase()}
                            </Text>
                            <Box display="flex" gap={5} direction="row">
                              <Box margin={1} />
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "documentType" &&
                                    filter.sortDirection === "DESC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("documentType", "DESC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "documentType" &&
                                    filter.sortDirection === "DESC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg direction="down" />
                                </Box>
                              </TapArea>
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "documentType" &&
                                    filter.sortDirection === "ASC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("documentType", "ASC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "documentType" &&
                                    filter.sortDirection === "ASC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg />
                                </Box>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>
                          <Box display="flex" direction="row" marginBottom={2}>
                            <Text weight="bold" size="300">
                              {resources.name.toUpperCase()}
                            </Text>
                            <Box display="flex" gap={5} direction="row">
                              <Box margin={1} />
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "name" &&
                                    filter.sortDirection === "DESC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("name", "DESC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "name" && filter.sortDirection === "DESC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg direction="down" />
                                </Box>
                              </TapArea>
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "name" &&
                                    filter.sortDirection === "ASC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("name", "ASC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "name" && filter.sortDirection === "ASC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg />
                                </Box>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>
                          <Box display="flex" direction="row" marginBottom={2}>
                            <Text weight="bold" size="300">
                              {resources.browseDocument.author.toUpperCase()}
                            </Text>
                            <Box display="flex" gap={5} direction="row">
                              <Box margin={1} />
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "updatedBy" &&
                                    filter.sortDirection === "DESC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("updatedBy", "DESC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "updatedBy" &&
                                    filter.sortDirection === "DESC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg direction="down" />
                                </Box>
                              </TapArea>
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "updatedBy" &&
                                    filter.sortDirection === "ASC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("updatedBy", "ASC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "updatedBy" &&
                                    filter.sortDirection === "ASC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg />
                                </Box>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.HeaderCell>
                        {breakpoint === "hd" && (
                          <Table.HeaderCell>
                            <Box display="flex" direction="row" marginBottom={2}>
                              <Text weight="bold" size="300">
                                {resources.dataTypeValues.date.toUpperCase()}
                              </Text>
                              <Box display="flex" gap={5} direction="row">
                                <Box margin={1} />
                                <TapArea
                                  onTap={() => {
                                    if (
                                      filter.sortField === "updatedAt" &&
                                      filter.sortDirection === "DESC"
                                    ) {
                                      changeSort(undefined, undefined);
                                    } else {
                                      changeSort("updatedAt", "DESC");
                                    }
                                  }}
                                >
                                  <Box
                                    color={
                                      filter.sortField === "updatedAt" &&
                                      filter.sortDirection === "DESC"
                                        ? colors.neutral500
                                        : colors.neutral200
                                    }
                                    padding={1}
                                    borderRadius={4}
                                  >
                                    <ArrowSvg direction="down" />
                                  </Box>
                                </TapArea>
                                <TapArea
                                  onTap={() => {
                                    if (
                                      filter.sortField === "updatedAt" &&
                                      filter.sortDirection === "ASC"
                                    ) {
                                      changeSort(undefined, undefined);
                                    } else {
                                      changeSort("updatedAt", "ASC");
                                    }
                                  }}
                                >
                                  <Box
                                    color={
                                      filter.sortField === "updatedAt" &&
                                      filter.sortDirection === "ASC"
                                        ? colors.neutral500
                                        : colors.neutral200
                                    }
                                    padding={1}
                                    borderRadius={4}
                                  >
                                    <ArrowSvg />
                                  </Box>
                                </TapArea>
                              </Box>
                            </Box>
                          </Table.HeaderCell>
                        )}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {(documentsData || []).map((documentData, idx) => {
                        return (
                          <Table.Row key={idx} hoverStyle="gray">
                            <Table.Cell>
                              <TapArea>
                                <Box
                                  display="flex"
                                  direction="row"
                                  alignItems="center"
                                  onClickCapture={() => handleMoveTo(documentData.documentId)}
                                >
                                  <Box paddingX={2}>{<DocIconsSvg />}</Box>
                                  <DocumentTypeInfo
                                    size="300"
                                    documentTypeId={documentData.documentTypeId}
                                    skipLink={true}
                                  />
                                </Box>
                              </TapArea>
                            </Table.Cell>
                            <Table.Cell>
                              <Box width={"1%"} borderStyle={"sm"} borderRadius={4} height={25} />
                            </Table.Cell>
                            <Table.Cell>
                              <TapArea onTap={() => handleMoveTo(documentData.documentId)}>
                                <Text size="300" title={documentData.name} ellipsisLength={65}>
                                  {documentData.name}
                                </Text>
                              </TapArea>
                            </Table.Cell>
                            <Table.Cell>
                              <Box width={"1%"} borderStyle={"sm"} borderRadius={4} height={25} />
                            </Table.Cell>
                            <Table.Cell>
                              <TapArea onTap={() => handleMoveTo(documentData.documentId)}>
                                <PublicProfile username={documentData.updatedBy} />
                              </TapArea>
                            </Table.Cell>
                            <Table.Cell>
                              <Box width={"1%"} borderStyle={"sm"} borderRadius={4} height={25} />
                            </Table.Cell>
                            {breakpoint === "hd" && (
                              <Table.Cell>
                                <TapArea onTap={() => handleMoveTo(documentData.documentId)}>
                                  <FancyDateTime
                                    moment={moment}
                                    resources={resources}
                                    value={documentData.createdAt}
                                  />
                                </TapArea>
                              </Table.Cell>
                            )}
                            <Table.Cell>
                              <BoxWithRef ref={(ref) => (anchorRefItems.current[idx] = ref)}>
                                <TapArea
                                  onTap={() => {
                                    setDocumentId(documentData.documentId);
                                  }}
                                >
                                  <CascadeOptionsPopover
                                    icon={<EllipseMenuSvg />}
                                    hoverLabel={undefined}
                                    items={CascadeOptionsPopoverList}
                                    label={undefined}
                                    onClick={handleCascadeOptionsPopoverClick}
                                    searchFilter={false}
                                    childPopoverActionOnClose={undefined}
                                  />
                                </TapArea>
                              </BoxWithRef>
                            </Table.Cell>
                            <Table.Cell>
                              <BoxWithRef ref={(ref) => (anchorRefItems.current[idx] = ref)}>
                                <TapArea
                                  onTap={() => {
                                    setDocumentId(documentData.documentId);
                                  }}
                                >
                                  <Box>
                                    <DocumentBookmarkTags
                                      documentId={documentData.documentId}
                                      viewMode={{
                                        type: "countListPopoverMenu",
                                      }}
                                    />
                                  </Box>
                                </TapArea>
                              </BoxWithRef>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Box>
              ) : (
                <Grid
                  gridTemplateColumns={["repeat(auto-fill, 400px)"]}
                  gap={2}
                  justifyContent="center"
                >
                  {(documentsData || []).map((document, idx) => (
                    <Box color={colors.neutral200} key={idx}>
                      <DocumentCard document={document} onChange={handleMoveTo} />
                    </Box>
                  ))}
                </Grid>
              )}
              <Box display="flex" direction="row" flex="grow">
                <Box display="flex" direction="row" flex="grow" />
                <Box display="flex" direction="row" flex="shrink">
                  <Pagination<typeof rowsPerPage>
                    id="browse_documents_pagination_bottom"
                    rowsLabel={`${resources.documents}:`}
                    currentPage={currentPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageLength={documentsData?.length || 0}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    isNextButtonDisabled={isNextButtonDisabled}
                    isPreviousButtonDisabled={isPreviousButtonDisabled}
                    pageLengthOptions={pageLengthOptions}
                    totalRowCount={totalRowCount}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
