import {
  Box,
  Button,
  Divider,
  Layer,
  Modal,
  Text,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { WarningDeleteLabelLayerMode } from "./ProfileDocumentLabelsList/hook";
import { useWarningDeleteListLayer } from "./hook";

type Props = {
  deleteLabelWarning: {
    show: boolean;
    mode: WarningDeleteLabelLayerMode;
  };
  setDeleteLabelWarningState: (deleteLabelWarning: {
    show: boolean;
    mode: WarningDeleteLabelLayerMode;
  }) => void;

  handleDeleteMyProfileLabel: (label: string) => void;
  selectedLabel: string | undefined;
};

export const WarningDeleteLabelLayer: FunctionComponent<Props> = ({
  deleteLabelWarning,
  setDeleteLabelWarningState,
  handleDeleteMyProfileLabel,
  selectedLabel,
}) => {
  const { mutateDeleteAllDocuments } = useWarningDeleteListLayer(selectedLabel);
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const message =
    deleteLabelWarning.mode === "deleteAllDocuments"
      ? resources.youAreAboutToDeleteAllDocumentsFromThisList
      : resources.youAreAboutToDeleteThisList;

  return (
    <Box>
      {deleteLabelWarning.show && selectedLabel && (
        <Layer zIndex={modalZIndex}>
          <Modal
            accessibilityModalLabel={resources.deleteLabel}
            heading={
              <Box paddingY={2}>
                <Box>
                  <Text weight="bold" size="400" color={colors.secondary}>
                    {resources.deleteLabel}
                  </Text>
                </Box>
                <Divider />
              </Box>
            }
            size="sm"
            onDismiss={() =>
              setDeleteLabelWarningState({
                show: false,
                mode: "deleteLabel",
              })
            }
            footer={
              <Box display="flex">
                <Box>
                  <Button
                    color={"transparent"}
                    onClick={() =>
                      setDeleteLabelWarningState({
                        show: false,
                        mode: "deleteLabel",
                      })
                    }
                    text={resources.cancel}
                  />
                </Box>
                <Box marginStart="auto">
                  <Button
                    color={"red"}
                    onClick={() => {
                      deleteLabelWarning.mode === "deleteAllDocuments"
                        ? mutateDeleteAllDocuments()
                        : handleDeleteMyProfileLabel(selectedLabel);
                      setDeleteLabelWarningState({
                        show: false,
                        mode: "deleteLabel",
                      });
                    }}
                    text={resources.yesDelete}
                  />
                </Box>
              </Box>
            }
          >
            <Box margin={6} display="flex" justifyContent="center" marginBottom={12}>
              <Text>{message.replace("{label}", selectedLabel)}</Text>
            </Box>
            <Divider />
          </Modal>
        </Layer>
      )}
    </Box>
  );
};
