import {
  Box,
  ChevronSvgIcon,
  ColorConfig,
  ComboBox,
  Divider,
  FieldSvgIcon,
  Layer,
  Popover,
  SectionSvgIcon,
  TapArea,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent, MutableRefObject, useCallback, useMemo, useRef, useState } from "react";
import type {
  TemplatePage,
  TemplateSection,
  TemplateWizardField,
} from "@prodoctivity/shared/src/index-types";

import { BoxWithRef } from "@prodoctivity/design-system/components/Box";
import { HorizontalEllipsisSvgIcon } from "../../../../svg/HorizontalEllipsisSvgIcon";
import { PlusSvgIcon } from "../../../../svg/PlusSvgIcon";
import { XIconSvg } from "../../../../svg/XIconSvg";
import { createUuid } from "@prodoctivity/shared";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";

export type Option = { value: string; subtext?: string; label: string };
type Props = {
  pages: Array<TemplatePage>;
  moveFieldBetweenSections: (oldSectionKey: string, newSectionKey: string, itemKey: string) => void;
  addNewSection: (section: TemplateSection) => void;
};

type State = {
  hoveredItem?: string;
  openKeys: Array<string>;
  ellipsisSelected?: string;
  creatingSection: boolean;
  newSectionName?: string;
};

const newSectionKey = "new_section";

export const SidePanel: FunctionComponent<Props> = ({
  pages,
  moveFieldBetweenSections,
  addNewSection,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const allSections = useMemo(() => {
    return pages.flatMap((p) => p.sections);
  }, [pages]);

  const [state, setState] = useState<State>({
    hoveredItem: undefined,
    openKeys: [],
    ellipsisSelected: undefined,
    creatingSection: false,
    newSectionName: undefined,
  });
  const anchorRefItems = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const clearHover = useCallback(() => {
    setState((prev) => ({ ...prev, hoveredItem: undefined }));
  }, []);

  const setHoveredItem = useCallback((key: string) => {
    setState((prev) => ({ ...prev, hoveredItem: key }));
  }, []);

  const getBgHoverColor = useCallback(
    (key: string) => {
      return key === state.hoveredItem ? colors.primary100 : colors.white;
    },
    [colors.primary100, colors.white, state.hoveredItem]
  );
  const getTextHoverColor = useCallback(
    (key: string) => {
      return key === state.hoveredItem ? colors.primary : colors.subtle;
    },
    [colors.primary, colors.subtle, state.hoveredItem]
  );

  const getTextWeight = useCallback(
    (key: string): "bold" | "normal" => {
      return key === state.hoveredItem ? "bold" : "normal";
    },
    [state.hoveredItem]
  );

  const isItemOpen = useCallback(
    (type: "page" | "section" | "group", key: string) => {
      const completeKey = `${type}_${key}`;
      return state.openKeys.includes(completeKey);
    },
    [state.openKeys]
  );

  const setItemOpenKey = useCallback((type: "page" | "section" | "group", key: string) => {
    setState((prev) => {
      const completeKey = `${type}_${key}`;

      let newOpenKeys = prev.openKeys;
      if (prev.openKeys.includes(completeKey)) {
        newOpenKeys = newOpenKeys.filter((k) => k !== completeKey);
      } else {
        newOpenKeys.push(completeKey);
      }
      return { ...prev, openKeys: newOpenKeys };
    });
  }, []);

  const setEllipsisOpenItem = useCallback(
    (key?: string) => {
      setState((prev) => ({
        ...prev,
        ellipsisSelected: key === state.ellipsisSelected ? undefined : key,
      }));
    },
    [state.ellipsisSelected]
  );

  const cancelNewSection = useCallback(() => {
    setState((prev) => ({ ...prev, creatingSection: false, newSectionName: undefined }));
  }, []);

  const handleOnKeyDown = useCallback(
    ({
      event: { keyCode },
    }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event: { keyCode: number; currentTarget: any };
    }) => {
      if (keyCode === 13 /* Enter */ && state.newSectionName) {
        const section: TemplateSection = {
          key: createUuid(),
          label: state.newSectionName,
          description: state.newSectionName,
          fields: [],
          properties: {},
        };
        addNewSection(section);
        cancelNewSection();
      }
    },
    [addNewSection, cancelNewSection, state.newSectionName]
  );

  const sectionIsDuplicated = useCallback((): boolean => {
    if (state.creatingSection && state.newSectionName) {
      const index = allSections.findIndex((s) => s.label === state.newSectionName);

      return index >= 0;
    }
    return false;
  }, [allSections, state.creatingSection, state.newSectionName]);

  return (
    <Box color={colors.white}>
      <Box height={57} display="flex" alignItems="center" justifyContent="between" padding={3}>
        <Text weight="bold" color={colors.subtle}>
          {resources.formDesigner.sectionsList}
        </Text>
        <Box>
          <TapArea
            onTap={() => setState((prev) => ({ ...prev, creatingSection: !state.creatingSection }))}
          >
            <PlusSvgIcon color={colors.subtle} />
          </TapArea>
        </Box>
      </Box>
      <Box borderStyle="lg" borderRadius={6}></Box>
      <Box>
        {pages
          .flatMap((p) => p.sections.map((s) => ({ page: p, section: s })))
          .map(({ page, section }) => (
            <SidePanelSection
              key={`${page.key}_${section.key}`}
              section={section}
              allSections={allSections}
              anchorRefItems={anchorRefItems}
              clearHover={clearHover}
              getBgHoverColor={getBgHoverColor}
              getTextHoverColor={getTextHoverColor}
              getTextWeight={getTextWeight}
              hoveredItemKey={state.hoveredItem}
              isItemOpen={isItemOpen}
              moveFieldBetweenSections={moveFieldBetweenSections}
              selectedEllipsisKey={state.ellipsisSelected}
              setEllipsisOpenItem={setEllipsisOpenItem}
              setHoveredItem={setHoveredItem}
              setItemOpenKey={setItemOpenKey}
            />
          ))}

        {state.creatingSection && (
          <Box marginBottom={5}>
            <Box
              display="flex"
              alignItems="center"
              height={60}
              paddingY={2}
              color={getBgHoverColor(newSectionKey)}
            >
              <Box column={1} marginStart={1} marginEnd={3}>
                <Box width={20} alignSelf="center">
                  <ChevronSvgIcon direction="right" />
                </Box>
              </Box>
              <Box column={1} width={24} height={24} marginEnd={4}>
                <SectionSvgIcon />
              </Box>
              <Box column={10} display="flex" alignItems="center" justifyContent="between">
                <Box column={11}>
                  <TextField
                    placeholder={resources.name}
                    id="section_name"
                    value={state.newSectionName}
                    autoComplete="off"
                    onChange={(e) => {
                      setState({ ...state, newSectionName: e.value });
                    }}
                    onKeyDown={handleOnKeyDown}
                  />
                  {sectionIsDuplicated() && (
                    <Text size="100" color={colors.error}>
                      {resources.duplicated}
                    </Text>
                  )}
                </Box>
                <Box column={1} paddingX={2}>
                  <XIconSvg onClick={cancelNewSection} />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SidePanelSection: FunctionComponent<{
  section: TemplateSection;
  allSections: TemplateSection[];
  anchorRefItems: MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
  hoveredItemKey: string | undefined;
  selectedEllipsisKey: string | undefined;
  setHoveredItem: (key: string) => void;
  clearHover(): void;
  getBgHoverColor(key: string): ColorConfig;
  getTextHoverColor(key: string): ColorConfig;
  getTextWeight: (key: string) => "bold" | "normal";
  isItemOpen: (type: "page" | "section" | "group", key: string) => boolean;
  setItemOpenKey: (type: "page" | "section" | "group", key: string) => void;
  setEllipsisOpenItem: (key: string | undefined) => void;
  moveFieldBetweenSections: (oldSectionKey: string, newSectionKey: string, itemKey: string) => void;
}> = ({
  section,
  allSections,
  anchorRefItems,
  hoveredItemKey,
  selectedEllipsisKey,
  setHoveredItem,
  clearHover,
  getBgHoverColor,
  getTextHoverColor,
  getTextWeight,
  isItemOpen,
  setItemOpenKey,
  setEllipsisOpenItem,
  moveFieldBetweenSections,
}) => {
  const setHover = useCallback(() => {
    setHoveredItem(section.key);
  }, [section.key, setHoveredItem]);

  return (
    <Box key={section.key}>
      <TapArea key={section.key} onMouseEnter={setHover} onMouseLeave={clearHover} role="button">
        <Box
          display="flex"
          alignItems="center"
          height={50}
          paddingY={2}
          color={getBgHoverColor(section.key)}
          onClickCapture={() => setItemOpenKey("section", section.key)}
        >
          <Box marginStart={1} marginEnd={3}>
            {isItemOpen("section", section.key) ? (
              <Box width={20} alignSelf="center">
                <ChevronSvgIcon color={getTextHoverColor(section.key)} direction="down" />
              </Box>
            ) : (
              <Box width={20} alignSelf="center">
                <ChevronSvgIcon color={getTextHoverColor(section.key)} direction="right" />
              </Box>
            )}
          </Box>
          <Box width={24} height={24} marginStart={4} marginEnd={4}>
            <SectionSvgIcon color={getTextHoverColor(section.key)} />
          </Box>
          <Text weight={getTextWeight(section.key)} color={getTextHoverColor(section.key)}>
            {section.label}
          </Text>
        </Box>
      </TapArea>
      <Box marginStart={3}>
        {isItemOpen("section", section.key) &&
          (section.fields || []).map((f) => (
            <SidePanelField
              key={`${section.key}_${f.key}`}
              allSections={allSections}
              anchorRefItems={anchorRefItems}
              clearHover={clearHover}
              field={f}
              getBgHoverColor={getBgHoverColor}
              getTextHoverColor={getTextHoverColor}
              getTextWeight={getTextWeight}
              isItemOpen={isItemOpen}
              parentSectionKey={section.key}
              setHoveredItem={setHoveredItem}
              setItemOpenKey={setItemOpenKey}
              setEllipsisOpenItem={setEllipsisOpenItem}
              moveFieldBetweenSections={moveFieldBetweenSections}
              hoveredItemKey={hoveredItemKey}
              selectedEllipsisKey={selectedEllipsisKey}
            />
          ))}
        {isItemOpen("section", section.key) && <Divider />}
      </Box>
    </Box>
  );
};

const SidePanelFieldEllipsis: FunctionComponent<{
  allSections: TemplateSection[];
  anchorRefItems: MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
  fieldKey: string;
  parentSectionKey: string | undefined;
  hoveredItemKey: string | undefined;
  selectedEllipsisKey: string | undefined;
  onClick(key: string | undefined): void;
  moveFieldBetweenSections: (oldSectionKey: string, newSectionKey: string, itemKey: string) => void;
}> = ({
  anchorRefItems,
  allSections,
  fieldKey,
  parentSectionKey,
  hoveredItemKey,
  selectedEllipsisKey,
  onClick,
  moveFieldBetweenSections,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  if (!parentSectionKey) {
    return <></>;
  }
  return (
    <BoxWithRef
      key={fieldKey}
      alignItems="center"
      ref={(ref) => (anchorRefItems.current[fieldKey] = ref)}
      width={40}
      height={40}
      marginStart={4}
      marginEnd={4}
    >
      <Box
        display={hoveredItemKey === fieldKey ? undefined : "none"}
        onClickCapture={() => onClick(fieldKey)}
      >
        <HorizontalEllipsisSvgIcon />
      </Box>
      {selectedEllipsisKey === fieldKey && (
        <Layer>
          <Popover
            anchor={anchorRefItems.current[fieldKey]}
            onDismiss={console.log}
            idealDirection="down"
            positionRelativeToAnchor={false}
            size={"flexible"}
            color="white"
            role="menu"
          >
            <Box width={300} padding={2} color={colors.white}>
              <ComboBox
                id="moveTo"
                placeholder={resources.moveTo}
                label=""
                accessibilityClearButtonLabel={resources.clear}
                noResultText={resources.noResultsFound}
                onSelect={(e) => {
                  moveFieldBetweenSections(parentSectionKey, e.item.value, fieldKey);
                  onClick(undefined);
                }}
                size="lg"
                options={(allSections || [])
                  .filter((section) => section.key !== parentSectionKey)
                  .map((section) => {
                    return { value: section.key, label: section.label };
                  })}
              />
            </Box>
          </Popover>
        </Layer>
      )}
    </BoxWithRef>
  );
};

const SidePanelField: FunctionComponent<{
  field: TemplateWizardField;
  parentSectionKey: string;
  allSections: TemplateSection[];
  anchorRefItems: MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
  hoveredItemKey: string | undefined;
  selectedEllipsisKey: string | undefined;
  clearHover(): void;
  setHoveredItem(key: string): void;
  getBgHoverColor(key: string): ColorConfig;
  getTextHoverColor(key: string): ColorConfig;
  getTextWeight: (key: string) => "bold" | "normal";
  isItemOpen: (type: "page" | "section" | "group", key: string) => boolean;
  setItemOpenKey: (type: "page" | "section" | "group", key: string) => void;
  setEllipsisOpenItem: (key: string | undefined) => void;
  moveFieldBetweenSections: (oldSectionKey: string, newSectionKey: string, itemKey: string) => void;
}> = ({
  field,
  parentSectionKey,
  allSections,
  anchorRefItems,
  hoveredItemKey,
  selectedEllipsisKey,
  clearHover,
  setHoveredItem,
  getBgHoverColor,
  getTextHoverColor,
  getTextWeight,
  isItemOpen,
  setItemOpenKey,
  setEllipsisOpenItem,
  moveFieldBetweenSections,
}) => {
  const { colors } = useColors();
  const setHover = useCallback(() => {
    setHoveredItem(field.key);
  }, [field.key, setHoveredItem]);
  const setEllipsis = useCallback(() => {
    setEllipsisOpenItem(field.key);
  }, [field.key, setEllipsisOpenItem]);
  if (!field.isRecord) {
    return (
      <TapArea key={field.key} onMouseEnter={setHover} onMouseLeave={clearHover} role="button">
        <Box
          justifyContent="between"
          display="flex"
          height={50}
          paddingY={2}
          marginStart={4}
          color={getBgHoverColor(field.key)}
        >
          <Box display="flex" alignItems="center">
            <Box width={40} height={16} marginStart={4} marginEnd={4}>
              <FieldSvgIcon />
            </Box>
            <Text weight={getTextWeight(field.key)} color={getTextHoverColor(field.key)}>
              {field.label}
              {/*{field.label.split("/")[field.label.split("/").length - 1]}*/}
            </Text>
          </Box>
          <SidePanelFieldEllipsis
            anchorRefItems={anchorRefItems}
            fieldKey={field.key}
            onClick={setEllipsis}
            parentSectionKey={parentSectionKey}
            moveFieldBetweenSections={moveFieldBetweenSections}
            allSections={allSections}
            hoveredItemKey={hoveredItemKey}
            selectedEllipsisKey={selectedEllipsisKey}
          />
        </Box>
      </TapArea>
    );
  }

  return (
    <Box>
      <TapArea key={field.key} onMouseEnter={setHover} onMouseLeave={clearHover} role="button">
        <Box
          display="flex"
          justifyContent="between"
          height={50}
          paddingY={2}
          marginStart={7}
          color={getBgHoverColor(field.key)}
        >
          <Box
            display="flex"
            alignItems="center"
            onClickCapture={() => setItemOpenKey("group", field.key)}
            width={"100%"}
          >
            <Box marginStart={1} marginEnd={9}>
              {isItemOpen("group", field.key) ? (
                <Box width={20} alignSelf="center">
                  <ChevronSvgIcon direction="down" />
                </Box>
              ) : (
                <Box width={20} alignSelf="center">
                  <ChevronSvgIcon direction="right" />
                </Box>
              )}
            </Box>
            <Text weight={"bold"} color={colors.black900}>
              {field.label}
            </Text>
          </Box>
          <SidePanelFieldEllipsis
            anchorRefItems={anchorRefItems}
            fieldKey={field.key}
            onClick={setEllipsis}
            parentSectionKey={parentSectionKey}
            moveFieldBetweenSections={moveFieldBetweenSections}
            allSections={allSections}
            hoveredItemKey={hoveredItemKey}
            selectedEllipsisKey={selectedEllipsisKey}
          />
        </Box>
      </TapArea>

      <Box marginStart={10}>
        {isItemOpen("group", field.key) &&
          (field.fields || []).map((f) => (
            <SidePanelField
              key={`${parentSectionKey}_${f.key}`}
              anchorRefItems={anchorRefItems}
              allSections={allSections}
              field={f}
              parentSectionKey={parentSectionKey}
              clearHover={clearHover}
              setHoveredItem={setHoveredItem}
              getBgHoverColor={getBgHoverColor}
              getTextHoverColor={getTextHoverColor}
              isItemOpen={isItemOpen}
              setItemOpenKey={setItemOpenKey}
              getTextWeight={getTextWeight}
              setEllipsisOpenItem={setEllipsisOpenItem}
              moveFieldBetweenSections={moveFieldBetweenSections}
              hoveredItemKey={hoveredItemKey}
              selectedEllipsisKey={selectedEllipsisKey}
            />
          ))}
        {isItemOpen("group", field.key) && <Divider />}
      </Box>
    </Box>
  );
};
