import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const IntegrationsMenuIcon: FunctionComponent<Props> = ({ color }) => {
  const { colors } = useColors();
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.375 27.2211V12.7789C30.0312 12.5469 30.5 11.9211 30.5 11.1875C30.5 10.2547 29.7453 9.5 28.8125 9.5C28.0789 9.5 27.4531 9.96875 27.2211 10.625H12.7789C12.5469 9.96875 11.9211 9.5 11.1875 9.5C10.2547 9.5 9.5 10.2547 9.5 11.1875C9.5 11.9211 9.96875 12.5469 10.625 12.7789V27.2211C9.96875 27.4531 9.5 28.0789 9.5 28.8125C9.5 29.7453 10.2547 30.5 11.1875 30.5C11.9211 30.5 12.5469 30.0312 12.7789 29.375H27.2211C27.4531 30.0312 28.0789 30.5 28.8125 30.5C29.7453 30.5 30.5 29.7453 30.5 28.8125C30.5 28.0789 30.0312 27.4531 29.375 27.2211ZM28.8125 10.625C29.1242 10.625 29.375 10.8758 29.375 11.1875C29.375 11.4992 29.1242 11.75 28.8125 11.75C28.5008 11.75 28.25 11.4992 28.25 11.1875C28.25 10.8758 28.5008 10.625 28.8125 10.625ZM11.1875 29.375C10.8758 29.375 10.625 29.1242 10.625 28.8125C10.625 28.5008 10.8758 28.25 11.1875 28.25C11.4992 28.25 11.75 28.5008 11.75 28.8125C11.75 29.1242 11.4992 29.375 11.1875 29.375ZM11.1875 11.75C10.8758 11.75 10.625 11.4992 10.625 11.1875C10.625 10.8758 10.8758 10.625 11.1875 10.625C11.4992 10.625 11.75 10.8758 11.75 11.1875C11.75 11.4992 11.4992 11.75 11.1875 11.75ZM27.6875 27.6875H12.3125V12.3125H27.6875V27.6875ZM28.8125 29.375C28.5008 29.375 28.25 29.1242 28.25 28.8125C28.25 28.5008 28.5008 28.25 28.8125 28.25C29.1242 28.25 29.375 28.5008 29.375 28.8125C29.375 29.1242 29.1242 29.375 28.8125 29.375Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M14.75 19.1094H25.25C25.4563 19.1094 25.625 18.9406 25.625 18.7344V14.6094C25.625 14.4031 25.4563 14.2344 25.25 14.2344H14.75C14.5437 14.2344 14.375 14.4031 14.375 14.6094V18.7344C14.375 18.9406 14.5437 19.1094 14.75 19.1094ZM16.0625 15.9219H23.9375V17.4219H16.0625V15.9219ZM14.75 25.7656H25.25C25.4563 25.7656 25.625 25.5969 25.625 25.3906V21.2656C25.625 21.0594 25.4563 20.8906 25.25 20.8906H14.75C14.5437 20.8906 14.375 21.0594 14.375 21.2656V25.3906C14.375 25.5969 14.5437 25.7656 14.75 25.7656ZM16.0625 22.5781H23.9375V24.0781H16.0625V22.5781Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
    </svg>
  );
};
