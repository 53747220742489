"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RichExpression = exports.RichExpressionComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var shared_1 = require("@prodoctivity/shared");
var react_i18next_1 = require("react-i18next");
var utils_1 = require("../utils");
var react_1 = require("react");
var RichExpressionComponent = function (props) {
    var state = (0, react_1.useState)({ highlighted: false })[0] /* , setState*/;
    var expression = props.expression, level = props.level, t = props.t, contextDefinition = props.contextDefinition;
    var objExpression = (0, react_1.useMemo)(function () {
        return new shared_1.Expression(expression);
    }, [expression]);
    var valueWhenNoOperators = (0, react_1.useCallback)(function (self) {
        if (self.sourceSummary === null && !self.hasSource() && !self.hasTarget()) {
            if (self.isNegative) {
                return t("filterBuilder.alwaysFalse");
            }
            else {
                return t("filterBuilder.alwaysTrue");
            }
        }
        else {
            return t("filterBuilder.invalidExpression");
        }
    }, [t]);
    var fieldKeyLabelMap = (0, react_1.useMemo)(function () {
        var linear = (0, shared_1.linearizeRecordHolder)(contextDefinition);
        return (0, shared_1.arrayToMap)(linear, function (item) { return item.fullPath; }, function (item) { return item.humanName; });
    }, [contextDefinition]);
    var appendWhenSingleExpression = (0, react_1.useCallback)(function (self) {
        //getting left value
        var lval = self.sourceValueForDisplay(fieldKeyLabelMap);
        var lsummary;
        if (self.sourceSummary) {
            lsummary = t(utils_1.resourcesAdapters[self.sourceSummary]) || self.sourceSummary;
        }
        //getting right value
        var rval = self.targetValueForDisplay();
        var rsummary;
        if (self.targetSummary) {
            rsummary = t(utils_1.resourcesAdapters[self.targetSummary]) || self.targetSummary;
        }
        return ((0, jsx_runtime_1.jsxs)("span", { className: "single-expression", children: [lsummary ? ([
                    (0, jsx_runtime_1.jsx)("span", { children: lsummary }, 1),
                    (0, jsx_runtime_1.jsx)("span", { children: lval }, 2),
                    self.where ? ((0, jsx_runtime_1.jsxs)("span", { children: [(0, jsx_runtime_1.jsx)("span", { children: t("filterBuilder.whereCaps") }), (0, jsx_runtime_1.jsx)(exports.RichExpressionComponent, { t: t, expression: self.where, level: (level + 1) % 7, contextDefinition: contextDefinition })] }, 3)) : null,
                ]) : ((0, jsx_runtime_1.jsx)("span", { children: lval })), (0, jsx_runtime_1.jsx)("span", { children: t(utils_1.resourcesAdapters[self.operator]) || self.operator }), rsummary ? ([(0, jsx_runtime_1.jsx)("span", { children: rsummary }, 1), (0, jsx_runtime_1.jsx)("span", { children: rval }, 2)]) : ((0, jsx_runtime_1.jsx)("span", { children: rval }))] }));
    }, [t, level, contextDefinition]);
    if (!expression.operator) {
        return valueWhenNoOperators(objExpression);
    }
    var joiner;
    if ((0, shared_1.valueOrThrow)(objExpression.operatorList[expression.operator]).reductor) {
        joiner = " ".concat(t(utils_1.resourcesAdapters[expression.operator]) || expression.operator, " ");
    }
    var globalClassName = "expression-representation-level-".concat(level).concat(expression.isNegative ? " is-negative" : "").concat(state.highlighted ? " is-highlighted" : "");
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("span", { className: globalClassName, children: expression.isNegative ? ((0, jsx_runtime_1.jsx)("span", { className: "negative-expression", children: t("filterBuilder.not") })) : null }), joiner && expression.expressionList ? ((0, jsx_runtime_1.jsx)("span", { className: "multiple-expression", children: expression.expressionList.map(function (item, i) {
                    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [i > 0 ? ((0, jsx_runtime_1.jsx)("span", { className: "", children: t(utils_1.resourcesAdapters[expression.operator]) })) : undefined, (0, jsx_runtime_1.jsx)(exports.RichExpressionComponent, { t: t, expression: item, level: (level + 1) % 7, contextDefinition: contextDefinition })] }, i));
                }) })) : !objExpression.isValid() ? (appendWhenSingleExpression(objExpression) //i18n().invalidExpression
            ) : (appendWhenSingleExpression(objExpression))] }));
};
exports.RichExpressionComponent = RichExpressionComponent;
exports.RichExpression = (0, react_i18next_1.withTranslation)()(exports.RichExpressionComponent);
