import { Box, Skeleton, TapArea, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useServices } from "../../hooks/useServices";
import { DashboardItemContext } from "../../pages/Dashboard/DashboardConfigColumnElement";
import { EmptyTasksSvg } from "../../svg/Tasks/EmptyTasksSvg";
import { OrganizationNavLink } from "../OrganizationLink";
import { useHomeTasks } from "./hooks";
import { ProcessAccordion } from "./ProcessAccordion";

export const HomeTasks: FunctionComponent = () => {
  const { colors } = useColors();
  const { workflowTypeList, isLoading, refetch, setTaskDetailsPanel } = useHomeTasks();
  const { resources } = useAppTranslation();

  const dashboardItemContext = useContext(DashboardItemContext);
  const [openAccordions, setOpenAccordions] = useState<Set<string>>(new Set());

  const onAnyAccordionOpen = useCallback((id: string, isOpen: boolean) => {
    setOpenAccordions((prevOpenAccordions) => {
      const newOpenAccordions = new Set(prevOpenAccordions);
      if (isOpen) {
        newOpenAccordions.add(id);
      } else {
        newOpenAccordions.delete(id);
      }
      return newOpenAccordions;
    });
  }, []);

  useEffect(() => {
    const eventEmitter = dashboardItemContext.eventEmitter;
    if (eventEmitter) {
      const handler = () => {
        refetch();
      };
      eventEmitter.on("refresh", handler);
      return () => {
        eventEmitter.off("refresh", handler);
      };
    }
  }, [dashboardItemContext, refetch]);

  return (
    <Box display="flex" flex="grow">
      <Box display="flex" direction="column" flex="grow">
        {isLoading ? (
          <>
            <Skeleton width="60%" height={20} />
            <Skeleton width="50%" height={20} />
            <Skeleton width="80%" height={25} />
            <Skeleton width="100%" height={35} />
            <br />
            <Skeleton width="60%" height={20} />
            <Skeleton width="50%" height={20} />
            <Skeleton width="80%" height={25} />
            <Skeleton width="100%" height={35} />
            <br />
            <Skeleton width="60%" height={20} />
            <Skeleton width="50%" height={20} />
            <Skeleton width="80%" height={25} />
            <Skeleton width="100%" height={35} />
          </>
        ) : workflowTypeList?.workflowTypes && workflowTypeList?.workflowTypes.length > 0 ? (
          <Box display="flex" direction="column">
            <Box
              paddingX={dashboardItemContext.isSidebarComponent ? undefined : 8}
              paddingRight={dashboardItemContext.isSidebarComponent ? 4 : undefined}
              paddingTop={dashboardItemContext.isSidebarComponent ? undefined : 4}
            >
              <Box display="flex" flex="grow" alignItems="center" paddingY={2} paddingX={6}></Box>
              <Box display="flex" direction="column" gap={4}>
                <ProcessAccordion
                  cIndex={0}
                  category={{
                    workflowTypeId: "document-collection-task",
                    workflowTypeName: resources.rolesManagement.documentCollectionTask,
                    states: [],
                    engineType: "basic",
                  }}
                  fromDate={undefined}
                  toDate={undefined}
                  processFilter={""}
                  listType={"unclaimed"}
                  setProcessDetailsPanel={setTaskDetailsPanel}
                  taskType={"document-collection-task"}
                  isWidget={true}
                  isOpenAccordion={openAccordions.has("document-collection-task")}
                  onAnyAccordionOpen={(isOpen) =>
                    onAnyAccordionOpen("document-collection-task", isOpen)
                  }
                />
                <ProcessAccordion
                  cIndex={0}
                  category={{
                    workflowTypeId: "document-approval-task",
                    workflowTypeName: resources.approvalTasks,
                    states: [],
                    engineType: "basic",
                  }}
                  fromDate={undefined}
                  toDate={undefined}
                  processFilter={""}
                  listType={"unclaimed"}
                  setProcessDetailsPanel={setTaskDetailsPanel}
                  taskType={"document-approval-task"}
                  isWidget={true}
                  isOpenAccordion={openAccordions.has("document-approval-task")}
                  onAnyAccordionOpen={(isOpen) =>
                    onAnyAccordionOpen("document-approval-task", isOpen)
                  }
                />
                <ProcessAccordion
                  cIndex={0}
                  category={{
                    workflowTypeId: "template-context-state",
                    workflowTypeName: resources.generationTasks,
                    states: workflowTypeList.workflowTypes[0].states,
                    engineType: "basic",
                  }}
                  fromDate={undefined}
                  toDate={undefined}
                  processFilter={""}
                  listType={"unclaimed"}
                  setProcessDetailsPanel={setTaskDetailsPanel}
                  taskType={"template-context-state"}
                  isWidget={true}
                  isOpenAccordion={openAccordions.has("template-context-state")}
                  onAnyAccordionOpen={(isOpen) =>
                    onAnyAccordionOpen("template-context-state", isOpen)
                  }
                />
                {!isLoading &&
                  workflowTypeList &&
                  workflowTypeList.workflowTypes.slice(0, 5).map((category, cIndex) => {
                    return (
                      <ProcessAccordion
                        key={`${cIndex}-${category.workflowTypeId}_${category.workflowTypeName}`}
                        cIndex={cIndex}
                        category={category}
                        fromDate={undefined}
                        toDate={undefined}
                        processFilter={""}
                        listType={"unclaimed"}
                        setProcessDetailsPanel={setTaskDetailsPanel}
                        taskType={undefined}
                        isWidget={true}
                        isOpenAccordion={openAccordions.has(category.workflowTypeId)}
                        onAnyAccordionOpen={(isOpen) =>
                          onAnyAccordionOpen(category.workflowTypeId, isOpen)
                        }
                      />
                    );
                  })}
              </Box>
            </Box>
            <Box
              display="flex"
              flex="grow"
              alignItems="center"
              justifyContent={"center"}
              padding={6}
            >
              <OrganizationNavLink to="/tasks/list/unclaimed">
                <Text size="200" color={colors.primary}>
                  {resources.activities.viewAllEntries}
                </Text>
              </OrganizationNavLink>
            </Box>
          </Box>
        ) : (
          <TaskEmptyState />
        )}
      </Box>
    </Box>
  );
};
const TaskEmptyState = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();

  const handleNavigate = useCallback(() => {
    organizationNavigate("/tasks");
  }, [organizationNavigate]);

  const { user } = useServices();
  const isAdmin = user && user.permissions && user.permissions.indexOf("organization-admin") >= 0;

  return (
    <Box marginTop={4} display="flex" alignItems="center" direction="column">
      <EmptyTasksSvg />
      <Box margin={5} />
      <Text weight="bold">{resources.homePage.taskIsEmpty}</Text>
      <Box height={5} />
      <Text color={colors.black900} align="center">
        {resources.homePage.noActiveTask}
      </Text>
      {isAdmin && (
        <>
          <Text color={colors.black900} align="center">
            {resources.homePage.butOthersMightHaveTasksToDo}
          </Text>
          <TapArea onTap={handleNavigate}>
            <Text color={colors.primary} align="center">
              {resources.homePage.viewTaskHistory}
            </Text>
          </TapArea>
        </>
      )}
    </Box>
  );
};
