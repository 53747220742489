import {
  Box,
  ChevronSvgIcon,
  Skeleton,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import type {
  CollectionPermissionType,
  DocumentPermissionType,
  SearchByGroupsFilter,
  TemplatePermissionType,
} from "@prodoctivity/shared/src/index-types";
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo } from "react";
import { RoleManagementState, useDocumentPermissionTab, useRoleManagement } from "../hooks";
import { PermissionsTable, PermissionsTableProps } from "./PermissionsTable";

import type { DocumentGroup, OrganizationQueryBehavior } from "@prodoctivity/types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";

type PermissionsProps = {
  queryBehavior: OrganizationQueryBehavior | undefined;
  action: "allow" | "deny";
  formState: RoleManagementState;
  isLoading: boolean;
  setFormState: Dispatch<SetStateAction<RoleManagementState>>;
  documentTypesPaginated: ReturnType<typeof useRoleManagement>["documentTypesPaginated"];
  templatesPaginated: ReturnType<typeof useRoleManagement>["templatesPaginated"];
  onTemplatesFilterChanged: ({ value }: { value: string }) => void;
  templateFilteredValue: string;
  onDocumentTypesFilterChanged: ({ value }: { value: string }) => void;
  documentTypeFilteredValue: string;
  onCollectionFilterChanged: ({ value }: { value: string }) => void;
  collectionFilteredValue: string;
  collectionsPaginated: ReturnType<typeof useRoleManagement>["collectionsPaginated"];
  groupList: DocumentGroup[] | undefined;
  groupListFilter: SearchByGroupsFilter;
  onSetGroupListFilter: (value: SearchByGroupsFilter) => void;
};

export const DocumentAndTemplatePermissionsTab: FunctionComponent<PermissionsProps> = ({
  queryBehavior,
  action,
  formState,
  isLoading,
  documentTypesPaginated,
  templatesPaginated,
  setFormState,
  onDocumentTypesFilterChanged,
  onTemplatesFilterChanged,
  collectionsPaginated,
  onCollectionFilterChanged,
  documentTypeFilteredValue,
  templateFilteredValue,
  collectionFilteredValue,
  groupList,
  groupListFilter,
  onSetGroupListFilter,
}) => {
  const { resources } = useAppTranslation();
  const {
    expanedList,
    changeListExpanedState,
    documentTypePermissions,
    templatesPermissions,
    onDocTypePermissionCheck,
    onTemplatePermissionCheck,
    permissionsList,
    documentTypes,
    templates,
    collectionTarget,
    collectionsPermissions,
    collectionsPermissionsList,
    onCollectionCheck,
  } = useDocumentPermissionTab(
    action,
    formState,
    documentTypesPaginated,
    templatesPaginated,
    collectionsPaginated,
    setFormState
  );

  const { colors } = useColors();

  const docTypesPaginationData = useMemo((): PermissionsTableProps<
    "15" | "30" | "100",
    string
  >["paginationData"] => {
    return {
      rowsPerPage: documentTypesPaginated.rowsPerPage,
      currentPage: documentTypesPaginated.currentPage,
      pageLength: (documentTypesPaginated.paginatedData?.documentTypes || []).length,
      isNextButtonDisabled: documentTypesPaginated.isNextButtonDisabled,
      isPreviousButtonDisabled: documentTypesPaginated.isPreviousButtonDisabled,
      pageLengthOptions: ["15", "30", "100"],
      totalRowCount: documentTypesPaginated.totalRowCount,
      nextPage: documentTypesPaginated.nextPage,
      previousPage: documentTypesPaginated.previousPage,
      setPageLength: documentTypesPaginated.setPageLength,
    };
  }, [
    documentTypesPaginated.currentPage,
    documentTypesPaginated.isNextButtonDisabled,
    documentTypesPaginated.isPreviousButtonDisabled,
    documentTypesPaginated.nextPage,
    documentTypesPaginated.paginatedData?.documentTypes,
    documentTypesPaginated.previousPage,
    documentTypesPaginated.rowsPerPage,
    documentTypesPaginated.setPageLength,
    documentTypesPaginated.totalRowCount,
  ]);

  const templatePaginationData = useMemo((): PermissionsTableProps<
    "15" | "30" | "100",
    string
  >["paginationData"] => {
    return {
      rowsPerPage: templatesPaginated.rowsPerPage,
      currentPage: templatesPaginated.currentPage,
      pageLength: (templatesPaginated.paginatedData?.templates || []).length,
      isNextButtonDisabled: templatesPaginated.isNextButtonDisabled,
      isPreviousButtonDisabled: templatesPaginated.isPreviousButtonDisabled,
      pageLengthOptions: ["15", "30", "100"],
      totalRowCount: templatesPaginated.totalRowCount,
      nextPage: templatesPaginated.nextPage,
      previousPage: templatesPaginated.previousPage,
      setPageLength: templatesPaginated.setPageLength,
    };
  }, [
    templatesPaginated.currentPage,
    templatesPaginated.isNextButtonDisabled,
    templatesPaginated.isPreviousButtonDisabled,
    templatesPaginated.nextPage,
    templatesPaginated.paginatedData?.templates,
    templatesPaginated.previousPage,
    templatesPaginated.rowsPerPage,
    templatesPaginated.setPageLength,
    templatesPaginated.totalRowCount,
  ]);
  const collectionPaginationData = useMemo((): PermissionsTableProps<
    "15" | "30" | "50",
    string
  >["paginationData"] => {
    return {
      rowsPerPage: collectionsPaginated.rowsPerPage,
      currentPage: collectionsPaginated.currentPage,
      pageLength: (collectionsPaginated.paginatedData?.documentCollectionConfigList || []).length,
      isNextButtonDisabled: collectionsPaginated.isNextButtonDisabled,
      isPreviousButtonDisabled: collectionsPaginated.isPreviousButtonDisabled,
      pageLengthOptions: ["15", "30", "50"],
      totalRowCount: collectionsPaginated.totalRowCount,
      nextPage: collectionsPaginated.nextPage,
      previousPage: collectionsPaginated.previousPage,
      setPageLength: collectionsPaginated.setPageLength,
    };
  }, [
    collectionsPaginated.currentPage,
    collectionsPaginated.isNextButtonDisabled,
    collectionsPaginated.isPreviousButtonDisabled,
    collectionsPaginated.nextPage,
    collectionsPaginated.paginatedData?.documentCollectionConfigList,
    collectionsPaginated.previousPage,
    collectionsPaginated.rowsPerPage,
    collectionsPaginated.setPageLength,
    collectionsPaginated.totalRowCount,
  ]);

  const changeDocumentTypesGroupFilter = useCallback(
    (value: string | undefined) => {
      onSetGroupListFilter({
        ...groupListFilter,
        documentTypeGroup: value,
      });
    },
    [groupListFilter, onSetGroupListFilter]
  );

  const changeTemplatesGroupFilter = useCallback(
    (value: string | undefined) => {
      onSetGroupListFilter({
        ...groupListFilter,
        documentGroupId: value,
      });
    },
    [groupListFilter, onSetGroupListFilter]
  );

  return (
    <Skeleton height={400} show={isLoading}>
      <Box display="flex" direction="column" flex="grow">
        <Box display="flex" direction="column" flex="grow" minHeight={50}>
          <Box color={colors.neutral200} paddingY={1} paddingX={5} borderRadius={6}>
            <TapArea
              onTap={() =>
                changeListExpanedState({
                  ...expanedList,
                  docTypesExpanded: !expanedList.docTypesExpanded,
                })
              }
            >
              <Box display="flex" gap={2} alignItems="center">
                <ChevronSvgIcon
                  color={colors.subtle}
                  direction={expanedList.docTypesExpanded ? "down" : "right"}
                />
                <Text weight={"bold"} color={colors.subtle}>
                  {resources.documentTypes.documentTypes}
                </Text>
              </Box>
            </TapArea>
          </Box>

          {expanedList.docTypesExpanded && (
            <Box display="flex" direction="column" flex="grow">
              <PermissionsTable<typeof documentTypesPaginated.rowsPerPage, DocumentPermissionType>
                queryBehavior={queryBehavior}
                action={action}
                targets={documentTypes}
                targetLabel={resources.documentTypes.documentTypes}
                selectedPermissions={documentTypePermissions}
                permissionsList={permissionsList}
                onPermissionsChange={onDocTypePermissionCheck}
                paginationData={docTypesPaginationData}
                onFilterChanged={onDocumentTypesFilterChanged}
                groupList={groupList}
                filterValue={documentTypeFilteredValue}
                groupListFilter={groupListFilter.documentTypeGroup}
                onSetGroupListFilter={changeDocumentTypesGroupFilter}
              />
            </Box>
          )}
        </Box>
        <Box display="flex" direction="column" flex="grow">
          <Box color={colors.neutral200} borderRadius={6} paddingY={1} paddingX={5}>
            <TapArea
              onTap={() =>
                changeListExpanedState({
                  ...expanedList,
                  templatesExpanded: !expanedList.templatesExpanded,
                })
              }
            >
              <Box display="flex" gap={2} alignItems="center">
                <ChevronSvgIcon
                  color={colors.subtle}
                  direction={expanedList.templatesExpanded ? "down" : "right"}
                />
                <Text weight={"bold"} color={colors.subtle}>
                  {resources.templates}
                </Text>
              </Box>
            </TapArea>
          </Box>
          {expanedList.templatesExpanded && (
            <Box display="flex" direction="column" flex="grow">
              <PermissionsTable<typeof templatesPaginated.rowsPerPage, TemplatePermissionType>
                queryBehavior={queryBehavior}
                action={action}
                targets={templates}
                targetLabel={resources.templates}
                selectedPermissions={templatesPermissions}
                permissionsList={["view", "edit"]}
                onPermissionsChange={onTemplatePermissionCheck}
                paginationData={templatePaginationData}
                onFilterChanged={onTemplatesFilterChanged}
                groupList={groupList}
                filterValue={templateFilteredValue}
                groupListFilter={groupListFilter.documentGroupId}
                onSetGroupListFilter={changeTemplatesGroupFilter}
              />
            </Box>
          )}
        </Box>
        <Box display="flex" direction="column" flex="grow">
          <Box color={colors.neutral200} borderRadius={6} paddingY={1} paddingX={5}>
            <TapArea
              onTap={() =>
                changeListExpanedState({
                  ...expanedList,
                  collectionsTypesExpanded: !expanedList.collectionsTypesExpanded,
                })
              }
            >
              <Box display="flex" gap={2} alignItems="center">
                <ChevronSvgIcon
                  color={colors.subtle}
                  direction={expanedList.collectionsTypesExpanded ? "down" : "right"}
                />
                <Text weight={"bold"} color={colors.subtle}>
                  {resources.documentCollection.documentCollections}
                </Text>
              </Box>
            </TapArea>
          </Box>
          {expanedList.collectionsTypesExpanded && collectionTarget && (
            <Box display="flex" direction="column" flex="grow">
              <PermissionsTable<typeof collectionsPaginated.rowsPerPage, CollectionPermissionType>
                queryBehavior={queryBehavior}
                action={action}
                targets={collectionTarget}
                targetLabel={resources.collections}
                selectedPermissions={collectionsPermissions}
                permissionsList={collectionsPermissionsList}
                onPermissionsChange={onCollectionCheck}
                paginationData={collectionPaginationData}
                onFilterChanged={onCollectionFilterChanged}
                groupList={undefined}
                filterValue={collectionFilteredValue}
                groupListFilter={undefined}
                onSetGroupListFilter={undefined}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Skeleton>
  );
};
