import { formatNumber } from "@prodoctivity/shared";
import { FunctionComponent } from "react";

export type ProgressBarProps = {
  percentage: number;
  height?: number;
  backgroundColor?: string;
  progressColor?: string;
  labelColor?: string;
};

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  percentage,
  height = 20,
  backgroundColor = "#e0e0df",
  progressColor = "#76c7c0",
  labelColor = "black",
}) => {
  return (
    <div
      className="progress-bar-container"
      style={{
        height: `${height}px`,
        backgroundColor: backgroundColor,
        borderRadius: "50px",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.2);",
      }}
    >
      <div
        className="progress-bar-fill"
        style={{
          width: `${percentage}%`,
          backgroundColor: progressColor,
          height: "100%",
          transition: "width 0.3s ease-in-out",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          fontWeight: "bold",
          textAlign: "center",
        }}
      ></div>
      <span style={{ position: "absolute", left: "45%", top: "10%", color: labelColor }}>
        {formatNumber(Math.trunc(percentage))}%
      </span>
    </div>
  );
};
