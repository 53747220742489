import "./index.css";

import {
  Box,
  DatePickerProps,
  DocumentViewerEvents,
  DocumentViewerProps,
  EyeSvg,
  SearchSvgIcon,
  TapArea,
  Text,
  Tooltip,
  popupZIndex,
  useColors,
  useDesignBreakpoint,
  useFormController,
  usePubSub,
} from "@prodoctivity/design-system";
import type {
  ParametersObject,
  TemplateVersionContextMapping,
} from "@prodoctivity/shared/src/index-types";
import type { HttpExecuteDataLinkRequest, HttpExecuteDataLinkResponse } from "@prodoctivity/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormConfiguration, FormConnector, FormValues } from "../_lib/types";
import { GenerationWizardSidePanel, STEPS } from "./GenerationWizardSidePanel";
import { IFormValues, WizardStep } from "./_types";

import { VerticalEllipseMenuSvg } from "@prodoctivity/design-system";
import { MimeTypes, fixContextPayload } from "@prodoctivity/shared";
import type momentType from "moment";
import { Spinner } from "../Loader";
import { NavigationButtons } from "../ProDoctivityForm/FormPage/NavigationButtons";
import { contextToFormDefinition } from "../_lib/context-to-FormDefinition";
import { noop } from "../_lib/utils";
import { wizardDefinitionToLayout } from "../_lib/wizardDefinition-to-layout";
import { fireConnectorsList } from "./FireFormConnectorsList";
import { FormDataCapture } from "./steps/FormDataCapture";

export type Props = {
  documentBinary?: string | Blob;
  generating: boolean;
  generateOnFinish: boolean;
  DocumentViewerComponentImplementation({
    src,
    readOnly,
    mimeType,
    height,
    onFilesChange,
    ...rest
  }: DocumentViewerProps): JSX.Element;
  navigate: (to: string) => void;
  executeDataLink: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  generateDocument: (
    context: ParametersObject,
    isPreview: boolean
  ) => Promise<
    | {
        success: boolean;
        blob?: Blob;
        documentId: string | undefined;
        documentVersionId: string | undefined;
      }
    | { success: false; errors: any[] }
  >;
  templateVersionId?: string;
  generationToken?: string;
  dataLinkMappings: TemplateVersionContextMapping["datalinks"];
  connectors: any[];
  connectorDataLinks: any[];
  dataLinks: any[];
  initialPagesHeight?: number;
  i18n: (key: string) => string;
  onFormValuesChange?: (formData: IFormValues) => void;
  onNoDataFound?: (message: string) => void;
  onFireConnector: (formConnector: FormConnector, formValues: FormValues) => Promise<FormValues>;
  onFinish?(): void;
  onAfterPreview?(): void;
  isConfigured?: boolean;
  moment: typeof momentType;
  handlerNextStep?: (callback: (isNext: boolean) => boolean) => void;
  onCancel: () => void;
  cancelLabel: string | undefined;
  getTemplateContextSample(): Promise<ParametersObject>;
  resources: DatePickerProps["resources"] & {
    cancel: string;
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    continue: string;
    dataTypeValues: {
      none: string;
    };
    delete_: string;
    download: string;
    dragDropFile: string;
    expand: string;
    finish: string;
    print: string;
    signThisDocument: string;
    myLists: string;
    searchPanel: string;
    sendViaEmailPage: {
      sendViaEmail: string;
    };
    summary: string;
    yesDelete: string;
    youAreAboutToDeleteThisDocument: string;
  };
  onSearch: (n: boolean) => void;
};

interface IState {
  currentStep: number;
  loading: boolean;
  hasErrors: boolean;
  navigateTo: string | undefined;
}

export const GenerationWizard: React.FunctionComponent<Props> = (props) => {
  const {
    DocumentViewerComponentImplementation,
    onFormValuesChange,
    navigate,
    generateDocument,
    generateOnFinish,
    onCancel,
    onSearch,
  } = props;
  const { breakpoint } = useDesignBreakpoint();
  const [state, setState] = useState<IState>({
    currentStep: STEPS.indexOf(WizardStep.Form),
    loading: false,
    hasErrors: false,
    navigateTo: undefined,
  });

  const { isConfigured, onFinish, onAfterPreview } = props;
  const { currentStep, navigateTo } = state;
  const { wizardDefinition, contextDefinition, currentSection, context } = useFormController();

  //TODO: @eburgos Remove this
  const { formDefinition, formConfiguration } = useMemo(() => {
    const formDefinition = contextToFormDefinition(contextDefinition);
    const formLayout = wizardDefinitionToLayout(
      wizardDefinition,
      contextDefinition,
      currentSection
    );

    const formConfiguration: FormConfiguration = {
      formLayout,
      formConnectors: [],
    };

    return { formDefinition, formLayout, formConfiguration };
  }, [contextDefinition, currentSection, wizardDefinition]);

  // const attributeAssigner = (attribute: string) => {
  //   const button = document.querySelector("#btn-next");
  //   if (button !== null) {
  //     const attributeName = document.createAttribute("name");
  //     attributeName.value = attribute;
  //     button.attributes.setNamedItem(attributeName);
  //   }
  // };

  const prevFormConfigurationRef = useRef<FormConfiguration | undefined>();

  const onDependencyLayoutUpdate = useCallback(
    (layout: any) => {
      //JSON stringfy and parse used to create a new copy  of formConfiguration without a reference
      //Object.assign create a copy with the reference
      prevFormConfigurationRef.current = JSON.parse(JSON.stringify(formConfiguration));
      if (prevFormConfigurationRef.current) {
        prevFormConfigurationRef.current.formLayout.layout = layout;
      }
    },
    [formConfiguration]
  );

  const validateFormValues = useCallback((data: IFormValues): boolean => {
    return (
      data.formErrors !== undefined &&
      Object.keys(data.formErrors).some((key) => {
        return (
          data.formErrors && data.formErrors[key] !== undefined && data.formErrors[key].length > 0
        );
      })
    );
  }, []);

  const onFormValuesUpdated = useCallback(
    (data: IFormValues) => {
      if (onFormValuesChange) {
        onFormValuesChange(data);
      }

      const hasErrors = validateFormValues(data);

      setState((prev) => ({
        ...prev,
        hasErrors,
      }));
    },
    [onFormValuesChange, validateFormValues]
  );

  const deferStepMove = useCallback(
    (nextStepIndex: number) => {
      const nextStep = STEPS[nextStepIndex];

      switch (nextStep) {
        // case WizardStep.Summary:
        //   return this.props.generateDocument(data, true);
        case WizardStep.Preview:
          return generateDocument(context, true);
        case WizardStep.DocumentGenerated:
          if (generateOnFinish) {
            return generateDocument(context, false);
          } else {
            return Promise.resolve({
              success: true,
              documentId: undefined,
              documentVersionId: undefined,
            });
          }
        default:
          return Promise.resolve();
      }
    },
    [context, generateDocument, generateOnFinish]
  );

  const nextStepHandler = useCallback(() => {
    if (state.hasErrors) {
      return;
    }

    if (state.currentStep === STEPS.length - 2 && onAfterPreview) {
      onAfterPreview();
    } else if (state.currentStep === STEPS.length - 1) {
      if (onFinish) {
        onFinish();
      }
      navigate("/");
      return;
    }

    setState((prev) => ({
      ...prev,
      loading: true,
      navigateTo: undefined,
    }));

    const nextStepIndex = state.currentStep + 1;
    // tslint:disable-next-line: no-floating-promises
    deferStepMove(nextStepIndex).then((resp) => {
      if (resp && !resp.success) {
        setState((prev) => ({ ...prev, loading: false }));
        return;
      }
      setState((prev) => ({
        ...prev,
        currentStep: nextStepIndex,
        loading: false,
      }));
    });
  }, [deferStepMove, navigate, onAfterPreview, onFinish, state.currentStep, state.hasErrors]);

  const onNavigate = useCallback((to: string) => {
    const formStepIndex = STEPS.indexOf(WizardStep.Form);
    setState((prev) => ({
      ...prev,
      currentStep: formStepIndex,
      navigateTo: to,
    }));
  }, []);

  const onFormErrors = useCallback(
    (_hasErrors: boolean) => {
      if (state.hasErrors !== _hasErrors) {
        setState((prev) => ({
          ...prev,
          hasErrors: _hasErrors,
        }));
      }
    },
    [state.hasErrors]
  );

  useEffect(() => {
    fireConnectorsList(
      [], //this.props.formConfiguration.formConnectors,
      props.connectors,
      props.onFireConnector,
      formDefinition,
      context,
      noop //onFormDefinitionChange
    );
  }, [context, formDefinition, props.connectors, props.onFireConnector]);

  const {
    i18n,
    initialPagesHeight,
    connectorDataLinks,
    dataLinks,
    dataLinkMappings,
    executeDataLink,
    onFireConnector,
    onNoDataFound,
    moment,
    cancelLabel,
    handlerNextStep,
    resources,
    getTemplateContextSample,
  } = props;

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const allSections = useMemo(() => {
    return (wizardDefinition.pages || []).flatMap((page) =>
      page.sections.map((section) => {
        return {
          page,
          section,
        };
      })
    );
  }, [wizardDefinition.pages]);

  const isOpen = useCallback(
    (type: "page" | "section" | "group", key: string) => {
      const completeKey = `${type}_${key}`;
      return openKeys.includes(completeKey);
    },
    [openKeys]
  );
  const setOpenKey = useCallback(
    (type: "page" | "section" | "group", key: string) => {
      const completeKey = `${type}_${key}`;

      let newOpenKeys = [...openKeys];
      if (openKeys.includes(completeKey)) {
        newOpenKeys = newOpenKeys.filter((k) => k !== completeKey);
      } else {
        newOpenKeys.push(completeKey);
      }
      setOpenKeys(newOpenKeys);
    },
    [openKeys]
  );

  const onBackToForm = useCallback(() => {
    setState((prev) => ({
      ...prev,
      currentStep: STEPS.findIndex((step) => step === WizardStep.Form),
      loading: false,
    }));
  }, []);

  const onSummary = useCallback(() => {
    setState((prev) => ({
      ...prev,
      currentStep: STEPS.findIndex((step) => step === WizardStep.Summary),
      loading: false,
    }));
  }, []);

  const onPreview = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));

    const resp = await generateDocument(context, true);

    if (resp && !resp.success) {
      setState((prev) => ({ ...prev, loading: false }));
      return;
    }

    setState((prev) => ({
      ...prev,
      currentStep: STEPS.findIndex((step) => step === WizardStep.Preview),
      loading: false,
    }));
  }, [context, generateDocument]);

  const previewCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const finishDocument = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const previewBack = useCallback(() => {
    setState((prev) => ({
      ...prev,
      currentStep: STEPS.findIndex((step) => step === WizardStep.Summary),
      loading: false,
    }));
  }, []);

  const previewFinish = useCallback(() => {
    nextStepHandler();
  }, [nextStepHandler]);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [show, setShow] = useState(false);
  const [colorHovers, setColors] = useState(false);
  const { colors } = useColors();

  const actionItems = useMemo(() => {
    return [
      {
        title: i18n("copyFromDocument"),
        icon: <SearchSvgIcon color={colors.white} />,
      },
      {
        title: i18n("dataDictionary.sampleValue"),
        icon: <EyeSvg width={32} height={32} />,
      },
    ];
  }, [i18n, colors]);

  const formController = useFormController();

  const setSampleValues = useCallback(async () => {
    try {
      const sample = await getTemplateContextSample();

      //HACK: Sanitize movement. Please remove when done.
      formController.setContext((prev) => ({ ...prev, ...fixContextPayload(sample, false) }));
    } catch (err) {
      console.log(err);
    }
  }, [formController, getTemplateContextSample]);

  const { eventManager } = usePubSub<DocumentViewerEvents>();

  const isDeveloper = true;

  return (
    <Box display="flex" direction="column" flex="grow">
      {/* <Grid gridTemplateColumns={gridColumns} gap={1} height="100%"> */}
      <Box display="flex" direction="row" flex="grow">
        {breakpoint !== "small" && (
          <Box
            display="flex"
            direction="column"
            flex="grow"
            width={"22%"}
            maxWidth={"22%"}
            minWidth={"22%"}
            color={colors.white}
          >
            <GenerationWizardSidePanel
              allSections={allSections}
              currentStep={currentStep}
              currentSectionKey=""
              i18n={i18n}
              isConfigured={!!isConfigured}
              isOpen={isOpen}
              setOpenKey={setOpenKey}
              getTemplateContextSample={getTemplateContextSample}
            />
          </Box>
        )}

        <Box display="flex" flex="grow" direction="column" position="relative" color={colors.white}>
          {STEPS[currentStep] === WizardStep.Form && (
            <>
              <Box marginBottom={3} marginEnd={4}>
                <Box
                  color={colors.white}
                  position="absolute"
                  right={true}
                  top={true}
                  zIndex={popupZIndex}
                >
                  <Box>
                    <TapArea
                      onTap={() => {
                        setShow(!show);
                        setColors(!colorHovers);
                      }}
                    >
                      <Text title={i18n("open")}>
                        <VerticalEllipseMenuSvg
                          color={colorHovers ? colors.primary : colors.black600}
                        />
                      </Text>
                    </TapArea>
                  </Box>
                  {show && (
                    <Box
                      display="flex"
                      borderRadius={4}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {actionItems.map(
                        (item, idx) =>
                          isDeveloper && (
                            <Box
                              key={idx}
                              onClickCapture={() => {
                                item.title !== i18n("dataDictionary.sampleValue")
                                  ? onSearch(true)
                                  : setSampleValues();
                                setShow(false);
                                setColors(!colorHovers);
                              }}
                              hoverColor={colors.neutral700}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              paddingY={2}
                            >
                              <TapArea>
                                <Tooltip text={item.title}>{item.icon}</Tooltip>
                              </TapArea>
                            </Box>
                          )
                      )}
                    </Box>
                  )}
                </Box>
              </Box>

              <FormDataCapture
                formConfiguration={formConfiguration}
                connectors={props.connectors}
                dataLinks={dataLinks}
                connectorDataLinks={connectorDataLinks}
                dataLinkMappings={dataLinkMappings}
                executeDataLink={executeDataLink}
                formDefinition={formDefinition}
                onUpdateValues={onFormValuesUpdated}
                // initialValues={convertParametersObjectToIFormValues(contextDefinition, context)}
                onFireConnector={onFireConnector}
                readOnly={props.generating}
                i18n={i18n}
                navigate={navigateTo}
                onNavigate={onNavigate}
                initialPagesHeight={initialPagesHeight}
                onFormErrorsUpdate={onFormErrors}
                onDependencyLayoutUpdate={onDependencyLayoutUpdate}
                onNoDataFound={onNoDataFound}
                onSummary={nextStepHandler}
                moment={moment}
                handlerNextStep={handlerNextStep}
                cancelLabel={cancelLabel}
                cancel={onCancel}
                finish={onSummary}
                finishButtonLabel={i18n("summary")}
                isLoading={state.loading}
                resources={resources}
              />
            </>
          )}
          {STEPS[currentStep] === WizardStep.Summary &&
            (state.loading ? (
              <Box display="flex" flex="grow" justifyContent="center">
                <Spinner style={{}} />
              </Box>
            ) : (
              <FormDataCapture
                formConfiguration={
                  prevFormConfigurationRef.current
                    ? prevFormConfigurationRef.current
                    : formConfiguration
                }
                formDefinition={formDefinition}
                dataLinkMappings={dataLinkMappings}
                executeDataLink={executeDataLink}
                connectors={props.connectors}
                dataLinks={dataLinks}
                connectorDataLinks={connectorDataLinks}
                onUpdateValues={onFormValuesUpdated}
                // initialValues={formValues}
                onFireConnector={onFireConnector}
                onFormErrorsUpdate={onFormErrors}
                onDependencyLayoutUpdate={noop}
                readOnly={props.generating}
                i18n={i18n}
                summaryMode={true}
                onNavigate={onNavigate}
                onNoDataFound={onNoDataFound}
                moment={moment}
                handlerNextStep={handlerNextStep}
                cancel={onCancel}
                finish={onPreview}
                finishButtonLabel={i18n("preview")}
                back={onBackToForm}
                backButtonLabel={i18n("back")}
                isLoading={state.loading}
                resources={resources}
                paginate={false}
              />
            ))}
          {STEPS[currentStep] === WizardStep.Preview && (
            <Box display="flex" direction="column" flex="grow" gap={2}>
              {props.documentBinary && (
                <Box flex="grow">
                  <DocumentViewerComponentImplementation
                    height="100%"
                    src={[props.documentBinary]}
                    mimeType={MimeTypes.PdfDocument}
                    strategy={"normal"}
                    resources={resources}
                    isFullScreen={isFullScreen}
                    setIsFullScreen={setIsFullScreen}
                    eventManager={eventManager}
                    moment={moment}
                  />
                </Box>
              )}
              <Box paddingX={2} flex="shrink">
                <NavigationButtons
                  hasBackButton={false}
                  hasNextButton={false}
                  finish={previewFinish}
                  finishButtonLabel={resources.continue}
                  cancel={previewCancel}
                  i18n={i18n}
                  prev={noop}
                  next={noop}
                  summaryMode={false}
                  formHasErrors={false}
                  back={previewBack}
                  backButtonLabel={resources.summary}
                  isLoading={state.loading}
                />
              </Box>
            </Box>
          )}
          {STEPS[currentStep] === WizardStep.DocumentGenerated && (
            <Box display="flex" direction="column" flex="grow" gap={2}>
              {props.documentBinary && (
                <DocumentViewerComponentImplementation
                  height="100%"
                  src={[props.documentBinary]}
                  mimeType={MimeTypes.PdfDocument}
                  strategy={"normal"}
                  resources={resources}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  eventManager={eventManager}
                  moment={moment}
                />
              )}
              <Box paddingX={2}>
                <NavigationButtons
                  hasBackButton={false}
                  hasNextButton={false}
                  finish={finishDocument}
                  finishButtonLabel={resources.finish}
                  i18n={i18n}
                  prev={noop}
                  next={noop}
                  summaryMode={false}
                  formHasErrors={false}
                  isLoading={state.loading}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {/* </Grid> */}
      {/* <Box width={"100%"}>
        <Box display="flex" direction="row" justifyContent={"end"}>
          <Box display="flex" flex="grow" />
          <Box display="flex" gap={5} direction="row">
            {STEPS[currentStep] !== WizardStep.DocumentGenerated && (
              <Button
                accessibilityLabel="previous"
                type="button"
                disabled={STEPS[currentStep] === WizardStep.DocumentGenerated}
                onClick={previousStepHandler}
                text={currentStep > 0 ? i18n(STEPS[currentStep - 1]) : i18n("cancel")}
              ></Button>
            )}
            <Button
              accessibilityLabel="next"
              type="button"
              onClick={nextStepHandler}
              disabled={state.hasErrors}
              text={
                currentStep < STEPS.length - 1
                  ? `${i18n(STEPS[currentStep + 1])} `
                  : `${i18n("finish")} `
              }
            ></Button>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};
