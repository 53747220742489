import {
  Box,
  BoxWithAnimations,
  BoxWithRef,
  Divider,
  FancyDateTime,
  Skeleton,
  TapArea,
  Text,
  TextProps,
  VerticalEllipseMenuSvg,
  useColors,
} from "@prodoctivity/design-system";
import type { DocumentSummaryInformation } from "@prodoctivity/types";
import { useCallback, useMemo, useRef } from "react";
import NoThumbnail2 from "../../../assets/NoThumbnail2.jpeg";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";
import { MenuSvg } from "../../../svg/MenuSvg";
import {
  CascadeOptionsPopover,
  CascadeOptionsPopoverItem,
} from "../../CascadeOptionsPopover/CascadeOptionsPopover";
import { DocumentBookmarkTags } from "../../DocumentBookmarkTags/DocumentBookmarkTags";
import { useRecentDocumentSummary } from "./hooks";

export const templateAnimationSet = {
  initial: {
    display: "flex",
    opacity: 0,
    scale: 0.5,
    translate: "0 100px",
    width: "100%",
  },
  whileInView: { opacity: 1, scale: 1, translate: "0 0px" },
  transition: { duration: 0.7, animationFillMode: "both" },
  viewport: { once: true },
};

type Props = {
  ecmDocument: {
    updatedAt: number;
    documentTypeId: string;
    documentTypeVersionId: string;
    documentId: string;
    documentVersionId: string;
  };
  onChange: (id: string) => void;
  idx: number;
  itemIndex?: number;
};

export function RecentDocumentCard({
  ecmDocument: { documentId, updatedAt, documentVersionId },
  onChange,
  idx,
  itemIndex,
}: Props) {
  const { colors } = useColors();
  const { baseUrl, token } = useServices();
  const { resources, moment } = useAppTranslation();
  const anchorRefItems = useRef<Array<HTMLDivElement | null>>([]);
  const cardRef = useRef<HTMLDivElement | null>(null);
  // const { width } = useComponentBreakpoint(cardRef);
  // const cardWidthValue = width ? width : 230;
  // const itemNameEllipsis = cardWidthValue > 400 ? 100 : 70;
  // const documentTypeNameEllipsis = cardWidthValue < 380 ? 35 : 50;

  const cardOptionList: CascadeOptionsPopoverItem[] = useMemo(() => {
    return [
      {
        childIcon: <MenuSvg width={40} height={40} />,
        childLabel: resources.viewDocument,
        childHoverLabel: resources.viewDocument,
        treeItem: false,
        treeContent: undefined,
        key: "view",
      },
    ];
  }, [resources.viewDocument]);

  const handleCascadeOptionsPopoverClick = useCallback(
    (key: string) => {
      switch (key) {
        case "view":
          onChange(documentId);
          break;
        default:
          break;
      }
    },
    [documentId, onChange]
  );

  const { documentSummary } = useRecentDocumentSummary(documentId, documentVersionId);

  const viewMode = useMemo(() => {
    return { type: "countListPopoverMenu", documentVersionId } as const;
  }, [documentVersionId]);

  return (
    <BoxWithAnimations animationSet={templateAnimationSet}>
      <BoxWithRef
        ref={cardRef}
        display="flex"
        direction="column"
        alignItems="center"
        justifyContent="center"
        color={colors.white}
        position="relative"
        borderRadius={12}
        borderColor={colors.neutral600}
        overflow="hidden"
        maxWidth={itemIndex === 0 ? 500 : undefined}
        width={"100%"}
      >
        <TapArea onTap={() => onChange(documentId)}>
          <Box margin={"auto"} maxWidth={230}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="start"
              position="relative"
              width={"100%"}
              minWidth={230}
              height={itemIndex === 0 ? 230 : itemIndex === 1 ? 320 : 520}
            >
              <Box
                position="absolute"
                paddingX={3}
                width={itemIndex === 0 ? "120%" : itemIndex === 1 ? "210%" : "280%"}
                height={itemIndex === 0 ? 230 : itemIndex === 1 ? 320 : 520}
                overflow="hidden"
              >
                <Box width={"100%"} height={"100%"} overflow="hidden">
                  <img
                    id={`thumbnail-image-for-${documentId}`}
                    key={documentId}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "top",
                    }}
                    src={`${baseUrl}/app${organizationLinkTemplates.documentThumbnail(
                      documentId,
                      token || ""
                    )}`}
                    onError={() => {
                      const imageElement = document.getElementById(
                        `thumbnail-image-for-${documentId}`
                      );
                      if (imageElement && imageElement instanceof HTMLImageElement) {
                        imageElement.src = NoThumbnail2;
                      }
                    }}
                    alt={documentSummary?.name}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </TapArea>

        <Divider size={1} direction="horizontal" color={colors.neutral600}></Divider>
        <Box
          width={"100%"}
          display="flex"
          position="relative"
          alignItems="center"
          color={colors.neutral200}
          height={"100%"}
          maxHeight={170}
        >
          <Box
            width={"100%"}
            display="flex"
            direction="column"
            paddingY={3}
            paddingLeft={3}
            paddingRight={1}
            position="relative"
            height={"100%"}
          >
            <TapArea onTap={() => onChange(documentId)}>
              <Box display="flex" direction="column" justifyContent="between">
                <Box display="flex" direction="column" marginBottom={7} maxHeight={76}>
                  <RawDataDescriptionComponent
                    weight="bold"
                    size="200"
                    documentId={documentId}
                    documentVersionId={documentVersionId}
                    propertyName="documentTypeName"
                  />
                  <Box height={50}>
                    <RawDataDescriptionComponent
                      size="200"
                      documentId={documentId}
                      documentVersionId={documentVersionId}
                      propertyName="name"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  direction="column"
                  width={"100%"}
                  justifyContent="start"
                  alignItems="start"
                >
                  {itemIndex === 0 ? (
                    <>
                      <Box display="flex" gap={2} marginTop={1}>
                        <Box width={"fit-content"}>
                          <Text ellipsisLength={25} size="100" color={colors.neutral800}>
                            {resources.updatedAt}
                          </Text>

                          <Text ellipsisLength={25} size="100" color={colors.neutral700}>
                            <FancyDateTime
                              moment={moment}
                              resources={resources}
                              size="100"
                              value={updatedAt}
                            />
                          </Text>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        display="flex"
                        alignItems="end"
                        width={"100%"}
                        justifyContent="between"
                        gap={2}
                      >
                        <Text ellipsisLength={25} size="100" color={colors.neutral700}>
                          <FancyDateTime
                            moment={moment}
                            resources={resources}
                            size="100"
                            value={updatedAt}
                          />
                        </Text>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </TapArea>
          </Box>
          <BoxWithRef
            ref={(ref) => (anchorRefItems.current[idx] = ref)}
            display="flex"
            direction="column"
            height={"100%"}
          >
            <Box display="flex" direction="column" alignItems="start">
              <Box padding={2}>
                <DocumentBookmarkTags documentId={documentId} viewMode={viewMode} />
              </Box>
              <CascadeOptionsPopover
                icon={<VerticalEllipseMenuSvg />}
                label={undefined}
                hoverLabel={undefined}
                items={cardOptionList}
                searchFilter={false}
                onClick={handleCascadeOptionsPopoverClick}
                childPopoverActionOnClose={undefined}
              />
            </Box>
          </BoxWithRef>
        </Box>

        <Box marginEnd={5} />
      </BoxWithRef>
    </BoxWithAnimations>
  );
}

type DataDescription = TextProps & {
  documentId: string;
  propertyName: keyof DocumentSummaryInformation;
  documentVersionId?: string;
};

export const RawDataDescriptionComponent = ({
  documentId,
  documentVersionId,
  propertyName,
  ...rest
}: DataDescription) => {
  const { isLoading, documentSummary: summaryDocument } = useRecentDocumentSummary(
    documentId,
    documentVersionId
  );

  const txt = `${summaryDocument ? summaryDocument[propertyName] : ""}`;

  return (
    <Skeleton show={isLoading}>
      <Text {...rest} title={txt}>
        {txt}
      </Text>
    </Skeleton>
  );
};
