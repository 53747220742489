import {
  Box,
  DocumentViewer,
  DocumentViewerEvents,
  Heading,
  Icon,
  Mask,
  Spinner,
  Table,
  Text,
  Tooltip,
  useColors,
  useDesignBreakpoint,
  usePubSub,
} from "@prodoctivity/design-system";
import type {
  HttpDocumentGenerationStatusRequest,
  HttpDocumentGenerationStatusResponse,
} from "@prodoctivity/types";
import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from "react";

import { MimeTypes } from "@prodoctivity/shared";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { Pagination } from "../../components/Layout/Pagination";
import { PublicProfile } from "../../components/Profile/Public/PublicProfile";
import { TemplateLink } from "../../components/Templates/TemplateLink";
import { useDocumentWordToPdf, usePaginatedDataEndpoint } from "../../components/hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { AvailabilitySvgIcon } from "../../svg/GenerationStatus/AvailabilitySvgIcon";
import { ErrorsFoundSvgIcon } from "../../svg/GenerationStatus/ErrorsFoundSvgIcon";
import { GenerationsSvgIcon } from "../../svg/GenerationStatus/GenerationsSvgIcon";
import { StatusFailureSvgIcon } from "../../svg/GenerationStatus/StatusFailureSvgIcon";
import { StatusInProgressSvgIcon } from "../../svg/GenerationStatus/StatusInProgressSvgIcon";
import { StatusPendingSvgIcon } from "../../svg/GenerationStatus/StatusPendingSvgIcon";
import { StatusSuccessSvgIcon } from "../../svg/GenerationStatus/StatusSuccessSvgIcon";
import { PageNotFound } from "../StandAloneViewer/PageNotFound";

export function base64ToBlob(base64: string, contentType: string) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: contentType });
}

type HttpGenerationStatusDocument =
  HttpDocumentGenerationStatusResponse["payload"]["documentList"][0];

type ColumnKeys = keyof Omit<HttpGenerationStatusDocument, "items"> | "fileKey" | "contentType";

const PAGINATION_OPTIONS: HttpDocumentGenerationStatusRequest["queryParameters"]["rowsPerPage"][] =
  ["50", "100", "250"];

type HeaderProps = {
  generationStatusHeaderData: Omit<HttpDocumentGenerationStatusResponse["payload"], "documentList">;
  refetch: () => void;
};

const GenerationStatusHeaderComponent: React.FunctionComponent<HeaderProps> = (
  props: HeaderProps
) => {
  const { generationStatusHeaderData: generationStatus, refetch } = props;
  const { resources, moment } = useAppTranslation();
  const { colors } = useColors();

  type IconSize = {
    height: number;
    width: number;
  };
  const iconSizeObj: IconSize = {
    height: 40,
    width: 40,
  };

  const getStatusHeaderLabel = useCallback(
    (status: string) => {
      let icon: ReactNode;
      let label: string;
      switch (status) {
        case "in-progress":
          icon = <StatusInProgressSvgIcon height={40} width={40} />;
          label = resources.inProgress;
          break;
        case "completed":
          icon = <StatusSuccessSvgIcon height={40} width={40} />;
          label = resources.completed;
          break;
        case "error":
          icon = <StatusFailureSvgIcon height={40} width={40} />;
          label = resources.error;
          break;
        case "pending":
          icon = <StatusPendingSvgIcon height={40} width={40} />;
          label = resources.pending;
          break;
        default:
          return status;
      }
      return (
        <>
          <Mask rounding={"circle"}>
            <Box>{icon}</Box>
          </Mask>
          <Box>
            <Box display="flex" direction="column" gap={2}>
              <Box>
                <Text size="300" weight="bold">
                  {label}
                </Text>
              </Box>
              <Box>
                <Text color={colors.neutral900} size="200">
                  {resources.status}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box borderStyle="sm" height={50} width={1} />
        </>
      );
    },
    [resources, colors.neutral900]
  );
  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box>
      <Box padding={6}>
        <Heading color={colors.black600} size="400">
          {resources.generation} ID: {generationStatus.generationToken}
        </Heading>
        <Box>
          <Box
            color={colors.white}
            borderRadius={6}
            borderColor={colors.white}
            flex="grow"
            padding={4}
            display="flex"
            justifyContent="end"
          >
            <Box display="flex" justifyContent="end">
              {generationStatus.status !== "completed" ? (
                <Icon
                  accessibilityLabel="Refresh"
                  icon="arrows-rotate"
                  onClick={refetch}
                  size="sm"
                />
              ) : null}
            </Box>
            <Box display="flex" gap={8}>
              {/*START HERE Main container*/}

              <Box display="flex" direction={breakpoint === "small" ? "column" : "row"}>
                {/*First Sub-Container */}
                <Box display="flex" direction="row" gap={3}>
                  {/* Second Container Start */}

                  <Box display="flex" paddingY={5} alignItems="center">
                    <Box display="flex" gap={5}>
                      <Mask>
                        <Box>
                          <GenerationsSvgIcon {...iconSizeObj} />
                        </Box>
                      </Mask>
                      <Box>
                        <Box display="flex" direction="column" alignItems="center" gap={2}>
                          <Box>
                            <Text size="300" weight="bold">
                              {generationStatus.finishedCount + generationStatus.errorCount + " "}
                              {resources.of} {generationStatus.totalRowCount}
                            </Text>
                          </Box>
                          <Box>
                            <Text color={colors.neutral900} size="200">
                              {resources.generations}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                      <Box borderStyle="sm" height={50} width={1} />
                    </Box>
                  </Box>
                  {/* Second Container End */}
                  {/* Third Container Start */}
                  <Box display="flex" alignItems="center">
                    <Box display="flex" gap={5}>
                      <Mask>
                        <Box>
                          <ErrorsFoundSvgIcon {...iconSizeObj} />
                        </Box>
                      </Mask>
                      <Box>
                        <Box display="flex" direction="column" gap={2} alignItems="center">
                          <Box>
                            <Text size="300" weight="bold">
                              {generationStatus.errorCount + " "}
                            </Text>
                          </Box>
                          <Box>
                            <Text color={colors.neutral900} size="200">
                              {resources.generationStatus.errors}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                      {!(breakpoint === "small") && <Box borderStyle="sm" height={50} width={1} />}
                    </Box>
                  </Box>
                  {/* Third Container End */}
                </Box>
                {/**SECOND Sub-Container */}
                <Box display="flex" direction="row" gap={2}>
                  {/* Fourth Container Start */}
                  <Box display="flex" paddingY={5} alignItems="center">
                    <Box
                      display="flex"
                      gap={5}
                      paddingLeft={breakpoint === "small" ? undefined : 1}
                    >
                      {getStatusHeaderLabel(generationStatus.status)}
                    </Box>
                  </Box>
                  {/* Fourth Container End */}
                  {/* Fifth Container Start */}
                  <Box display="flex" alignItems="center">
                    <Box display="flex" gap={5}>
                      <Mask>
                        <Box>
                          <AvailabilitySvgIcon {...iconSizeObj} />
                        </Box>
                      </Mask>
                      <Box>
                        <Box display="flex" direction="column" gap={1}>
                          <Box>
                            <Text size="300" weight="bold">
                              {moment(generationStatus.expirationDate).fromNow()}
                            </Text>
                          </Box>
                          <Box>
                            <Text color={colors.neutral900} size="200">
                              {resources.generationStatus.expirationDate}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* Fifth Container End */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const GenerationStatusPage: React.FunctionComponent = () => {
  const { generationToken } = useParams();
  const { resources, moment } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();

  const [headerData, setHeaderData] = useState<
    HeaderProps["generationStatusHeaderData"] | undefined
  >(undefined);

  const {
    currentPage,
    isLoading: isGenerationLoading,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    paginatedData: generationStatus,
    previousPage,
    refetch,
    rowsPerPage,
    setPageLength,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    HttpDocumentGenerationStatusResponse["payload"],
    HttpDocumentGenerationStatusRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "50",
    "",
    (services, currentPage, rowsPerPage) => {
      return services.getGenerationStatus(generationToken || "", currentPage, rowsPerPage);
    },
    `get-generation-status-${generationToken}`
  );

  useEffect(() => {
    if (!isGenerationLoading && generationStatus && generationToken && !headerData) {
      setHeaderData({ ...generationStatus });
    }
  }, [generationStatus, generationToken, headerData, isGenerationLoading]);

  const [sortConfig, setSortConfig] = useState<{
    col: ColumnKeys;
    order: "asc" | "desc";
  }>({ col: "documentKey", order: "asc" });

  const onSortChange = useCallback(
    (col: ColumnKeys) => {
      if (sortConfig.col !== col) {
        setSortConfig({
          col,
          order: "desc",
        });
      } else {
        setSortConfig({
          col,
          order: sortConfig.order === "asc" ? "desc" : "asc",
        });
      }
    },
    [sortConfig.col, sortConfig.order]
  );

  const sortedDocumentList = useMemo(() => {
    if (!generationStatus) return [];
    return (generationStatus.documentList || [])
      .map((doc) => {
        const item = doc.items.find((i) => {
          return i.contentType === MimeTypes.MsWordDocument;
        });
        return {
          status: doc.status,
          updatedAt: doc.updatedAt,
          templateKey: doc.templateKey,
          documentKey: doc.documentKey,
          templateVersionId: doc.templateVersionId,
          contentType: item ? item.contentType : "",
          fileKey: item ? item.key : "",
          errorMessage: doc.errorMessage,
        };
      })
      .sort((a, b) => {
        const firstValue = a[sortConfig.col];
        const secondValue = b[sortConfig.col];

        if (!firstValue) return 1;
        if (!secondValue) return -1;
        if (!firstValue && !secondValue) return 0;
        return (
          firstValue.toString().localeCompare(secondValue.toString()) *
          (sortConfig.order === "asc" ? 1 : -1)
        );
      });
  }, [generationStatus, sortConfig.col, sortConfig.order]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "url",
        name: resources.generations,
        url: organizationLinkTemplates.documentGenerationStatusList(),
      },
      {
        type: "text",
        name: generationStatus ? `#${generationStatus.generationToken}` : resources.loading,
      },
    ];
  }, [resources, generationStatus]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      {headerData && (
        <Box>
          <GenerationStatusHeaderComponent
            generationStatusHeaderData={headerData}
            refetch={refetch}
            key={"generation-status-header"}
          />
        </Box>
      )}
      {isGenerationLoading && <Spinner show={true} size="md" />}
      {!isGenerationLoading &&
        (!generationStatus ? (
          <PageNotFound message={resources.noResultsFound} />
        ) : (
          <Box>
            <Box padding={6} display="flex" direction="column" gap={4}>
              <Box borderRadius={6}>
                <Box>
                  <Box paddingY={4}>
                    <Box paddingX={4} display="flex" direction="column">
                      <Box display="flex" direction="row" gap={2} paddingTop={2}>
                        <Text size="300" weight="bold">
                          {resources.generationStatus.totalDocuments}:
                        </Text>
                        <Text size="300">{generationStatus.totalRowCount}</Text>
                      </Box>
                      <Box display="flex" direction="row" gap={2} paddingTop={2}>
                        <Text size="300" weight="bold">
                          {resources.createdBy}:
                        </Text>
                        <PublicProfile size="300" username={generationStatus.createdBy} />
                      </Box>
                      <Box display="flex" direction="row" gap={2} paddingTop={2}>
                        <Text size="300" weight="bold">
                          {resources.runningTime}:
                        </Text>
                        <Text size="300">
                          {moment
                            .duration(generationStatus.updatedAt - generationStatus.createdAt)
                            .humanize()}
                        </Text>
                        <Tooltip
                          text={`${Math.floor(
                            moment
                              .duration(generationStatus.updatedAt - generationStatus.createdAt)
                              .asHours()
                          )
                            .toString()
                            .padStart(2, "0")} ${resources.hours},
                   ${moment
                     .utc(generationStatus.updatedAt - generationStatus.createdAt)
                     .format(
                       `mm [${resources.minutes}], ss [${resources.seconds}], SSS [${resources.milliseconds}]`
                     )}
                  `}
                        >
                          <Icon accessibilityLabel="tooltip" icon="circle-info" size="xs" />
                        </Tooltip>
                      </Box>
                      <Box display="flex" direction="row" gap={2} paddingTop={2}>
                        <Text size="300" weight="bold">
                          {resources.createdAt}:
                        </Text>
                        <Text size="300">
                          {moment(generationStatus.createdAt).format("DD MMMM YYYY HH:mm:ss")}
                        </Text>
                      </Box>
                      <Box display="flex" direction="row" gap={2} paddingTop={2}>
                        <Text size="300" weight="bold">
                          {resources.lastUpdated}:
                        </Text>
                        <Text size="300">
                          {moment(generationStatus.updatedAt).format("DD MMMM YYYY HH:mm:ss")}
                        </Text>
                      </Box>
                    </Box>
                  </Box>

                  <Box paddingX={4} paddingY={4}>
                    <Text size="300" color={colors.neutral900}>
                      {generationStatus.available
                        ? resources.generationStatus.documentsAvailable
                        : resources.generationStatus.documentsExpired}{" "}
                      {moment(generationStatus.expirationDate).format("LLLL")}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" direction={"row"}>
                <Box flex="grow" />
                <Box paddingX={2}>
                  <Pagination<typeof rowsPerPage>
                    id="document_history_list_pagination_top"
                    rowsLabel={`Generation Results`}
                    currentPage={currentPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageLength={generationStatus?.documentList?.length || 0}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setPageLength}
                    isNextButtonDisabled={isNextButtonDisabled}
                    isPreviousButtonDisabled={isPreviousButtonDisabled}
                    pageLengthOptions={PAGINATION_OPTIONS}
                    totalRowCount={totalRowCount}
                  />
                </Box>
              </Box>
              <Box
                padding={breakpoint === "small" ? 2 : undefined}
                overflow={breakpoint === "small" ? "scrollX" : undefined}
                borderStyle="raisedBottomShadow"
                color={colors.white}
                borderRadius={6}
                width={breakpoint === "small" ? "100%" : undefined}
              >
                {breakpoint === "small" ? (
                  sortedDocumentList.map((doc) => {
                    return (
                      <GenerationDocumentRowCard
                        key={`${doc.documentKey}-${doc.fileKey}`}
                        generationToken={generationStatus.generationToken}
                        documentKey={doc.documentKey}
                        status={doc.status}
                        templateVersionId={doc.templateVersionId}
                        fileKey={doc.fileKey}
                      />
                    );
                  })
                ) : (
                  <Table accessibilityLabel={resources.generationDocuments}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Box display="visuallyHidden">
                            <Text weight="bold">Open/Close row</Text>
                          </Box>
                        </Table.HeaderCell>
                        <Table.SortableHeaderCell
                          onSortChange={() => onSortChange("documentKey")}
                          sortOrder={sortConfig.order}
                          status={sortConfig.col === "documentKey" ? "active" : "inactive"}
                        >
                          <Text weight="bold" size="200">
                            {resources.generationStatus.documentKey}
                          </Text>
                        </Table.SortableHeaderCell>
                        <Table.SortableHeaderCell
                          onSortChange={() => onSortChange("templateKey")}
                          sortOrder={sortConfig.order}
                          status={sortConfig.col === "templateKey" ? "active" : "inactive"}
                        >
                          <Text weight="bold" size="200">
                            {resources.generationStatus.templateName}
                          </Text>
                        </Table.SortableHeaderCell>
                        <Table.SortableHeaderCell
                          onSortChange={() => onSortChange("status")}
                          sortOrder={sortConfig.order}
                          status={sortConfig.col === "status" ? "active" : "inactive"}
                        >
                          <Text weight="bold" size="200">
                            {resources.status}
                          </Text>
                        </Table.SortableHeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {sortedDocumentList.map((doc) => {
                        return (
                          <GenerationDocumentRow
                            key={`${doc.documentKey}-${doc.fileKey}`}
                            generationToken={generationStatus.generationToken}
                            documentKey={doc.documentKey}
                            fileKey={doc.fileKey}
                            status={doc.status}
                            templateVersionId={doc.templateVersionId}
                          />
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </Box>
            </Box>
          </Box>
        ))}
    </Page>
  );
};

const StatusTableBadge: FunctionComponent<{ status: string }> = ({ status }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  switch (status) {
    case "in-progress":
      return (
        <Mask rounding={3} width="fit-content">
          <Box color={colors.neutral200} paddingX={4} paddingY={2}>
            <Text weight="bold" color={colors.other200}>
              {resources.inProgress}
            </Text>
          </Box>
        </Mask>
      );
    case "completed":
      return (
        <Mask rounding={3} width="fit-content">
          <Box color={colors.neutral200} paddingX={4} paddingY={2}>
            <Text weight="bold" color={colors.other}>
              {resources.completed}
            </Text>
          </Box>
        </Mask>
      );
    case "error":
      return (
        <Mask rounding={3} width="fit-content">
          <Box color={colors.neutral200} paddingX={4} paddingY={2}>
            <Text weight="bold" color={colors.other100}>
              {resources.error}
            </Text>
          </Box>
        </Mask>
      );
    case "pending":
      return (
        <Mask rounding={3} width="fit-content">
          <Box color={colors.neutral200} paddingX={4} paddingY={2}>
            <Text weight="bold" color={colors.neutral800}>
              {resources.pending}
            </Text>
          </Box>
        </Mask>
      );
    default:
      return <Text>${status}</Text>;
  }
};

type GenerationDocumentRowProps = {
  documentKey: string;
  fileKey: string;
  generationToken: string;
  templateVersionId: string;
  status: string;
};

const GenerationDocumentRow: FunctionComponent<GenerationDocumentRowProps> = ({
  documentKey,
  fileKey,
  generationToken,
  templateVersionId,
  status,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { resources } = useAppTranslation();
  return (
    <Table.RowExpandable
      accessibilityExpandLabel={resources.expand}
      accessibilityCollapseLabel={resources.collapse}
      id={documentKey}
      onExpand={() => {
        setExpanded(!expanded);
      }}
      expandedContents={
        expanded && fileKey ? (
          <GenerationStatusDocumentViewer
            documentKey={documentKey}
            fileKey={fileKey}
            generationToken={generationToken}
          />
        ) : null
      }
    >
      <Table.Cell>
        <Text>{documentKey}</Text>
      </Table.Cell>
      <Table.Cell>
        <TemplateLink templateVersionId={templateVersionId} />
      </Table.Cell>
      <Table.Cell>
        <Text>
          <StatusTableBadge status={status} />
        </Text>
      </Table.Cell>
    </Table.RowExpandable>
  );
};

type GenerationStatusDocumentViewerProps = {
  generationToken: string;
  documentKey: string;
  fileKey: string;
};
const GenerationStatusDocumentViewer: FunctionComponent<GenerationStatusDocumentViewerProps> = ({
  generationToken,
  documentKey,
  fileKey,
}) => {
  const { getGenerationStatusDocument } = useServices();
  const { resources, moment } = useAppTranslation();

  const handleFetchTransientResource = useCallback(async () => {
    const resource = await getGenerationStatusDocument(generationToken, documentKey, fileKey);
    if (resource) {
      const blob = base64ToBlob(resource.contents, resource.contentType);
      return blob;
    }
  }, [getGenerationStatusDocument, generationToken, documentKey, fileKey]);

  const { data: resourceContents, isLoading: isResourceLoading } = useOrganizationQuery(
    `generation-status-contents/${generationToken}/${documentKey}/${fileKey}`,
    handleFetchTransientResource,
    {
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  const [isFullScreen, setIsFullScreen] = useState(false);

  const resourceArray = useMemo(() => {
    if (!resourceContents) {
      return [];
    }
    return [resourceContents];
  }, [resourceContents]);

  const wordToPdfHookRef = useDocumentWordToPdf(
    `generation-status-contents/${generationToken}/${documentKey}/${fileKey}`
  );

  const { eventManager } = usePubSub<DocumentViewerEvents>();
  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box>
      <Box paddingY={3}>
        {isResourceLoading ? (
          <Spinner accessibilityLabel="resource loading" show />
        ) : (
          <Box>
            {resourceContents ? (
              <Box
                height="50vh"
                borderStyle="shadow"
                margin={breakpoint === "small" ? undefined : "auto"}
              >
                <DocumentViewer
                  src={resourceArray}
                  height="50vw"
                  mimeType={MimeTypes.MsWordDocument}
                  strategy="normal"
                  resources={resources}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  wordToPdfHookRef={wordToPdfHookRef}
                  eventManager={eventManager}
                  moment={moment}
                />
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
      <Box display="flex" width="100%" padding={2} column={12} justifyContent="around"></Box>
    </Box>
  );
};

export default GenerationStatusPage;

interface GenerationDocumentRowCardProps {
  generationToken: string;
  documentKey: string;
  status: string;
  templateVersionId: string;
  fileKey: string;
}
const GenerationDocumentRowCard: React.FC<GenerationDocumentRowCardProps> = ({
  generationToken,
  documentKey,
  status,
  templateVersionId,
  fileKey,
}) => {
  const { resources } = useAppTranslation();
  const [expanded, setExpanded] = useState(false);
  const { colors } = useColors();
  return (
    <Box
      width={expanded ? "fit-content" : "100%"}
      color={colors.white}
      borderStyle="sm"
      padding={3}
      display="flex"
      direction="column"
      marginBottom={2}
    >
      <Box paddingBottom={2}>
        <Text weight="bold" size="200">
          {resources.generationStatus.documentKey}
        </Text>
        <Box>
          <Text overflow="ellipsis">{documentKey}</Text>
        </Box>
      </Box>
      <Box paddingBottom={2}>
        <Text weight="bold" size="200">
          {resources.generationStatus.templateName}
        </Text>
        <Box>
          <TemplateLink templateVersionId={templateVersionId} />
        </Box>
      </Box>

      <Box>
        <Table.RowExpandable
          accessibilityExpandLabel={resources.expand}
          accessibilityCollapseLabel={resources.collapse}
          id={documentKey}
          onExpand={() => {
            setExpanded(!expanded);
          }}
          expandedContents={
            expanded && fileKey ? (
              <GenerationStatusDocumentViewer
                documentKey={documentKey}
                fileKey={fileKey}
                generationToken={generationToken}
              />
            ) : null
          }
        >
          <Table.Cell>
            <Box display="flex" direction="column" paddingLeft={2}>
              <Text weight="bold" size="200">
                {resources.status}
              </Text>
              <StatusTableBadge status={status} />
            </Box>
          </Table.Cell>
        </Table.RowExpandable>
      </Box>
    </Box>
  );
};
