import { changeLanguage } from "@prodoctivity/design-system";
import type { MyUserProfile } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { ToastMessageType } from "../../NotificationMessage";
import type { OrganizationUserLanguage } from "@prodoctivity/shared/src/index-types";

export const useQuickActionsWidget = () => {
  const { i18n } = useTranslation();
  const { updateUserProfile, getMyUserProfile, user } = useServices();
  const { resources, moment } = useAppTranslation();
  const [toastMessage, setToastMessage] = useState<ToastMessageType | undefined>(undefined);
  const [profileData, setProfileData] = useState<MyUserProfile | undefined>();
  const changeLang = useMemo(() => {
    return changeLanguage(i18n, moment);
  }, [i18n, moment]);

  const updateLanguage = useCallback(async () => {
    const response = await getMyUserProfile();
    if (response) {
      setProfileData(response);
    }
    if (response && response.language && response.language !== i18n.language) {
      changeLang(response.language);
    }
    return response;
  }, [changeLang, getMyUserProfile, i18n.language]);

  const handleLanguageChange = ({ value }: { value: string }) => {
    if (profileData) {
      setProfileData((prev) => {
        const newProfileData = {
          ...(prev ? prev : profileData),
          language: value as OrganizationUserLanguage,
        };
        mutate({ language: newProfileData.language });
        return newProfileData;
      });
    }
  };

  const { data, refetch } = useOrganizationQuery(`${user?.username}/my-profile`, updateLanguage, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
  });

  const { mutate } = useMutation(updateUserProfile, {
    onSuccess: () => {
      setToastMessage({ message: resources.accountPage.successApplyingChange, type: "success" });
      refetch();
    },
    onError: () => {
      setToastMessage({ message: resources.unknownError, type: "error" });
      refetch();
    },
  });
  return {
    profileData,
    data,
    toastMessage,
    setToastMessage,
    handleLanguageChange,
  };
};
