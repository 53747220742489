import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import DocumentViewerPage from "./DocumentViewerPage";

type Props = {
  defaultPanel: DocumentViewerPanelKeys | undefined;
};

export const DocumentViewerPanelKeysSchema = z.union([
  z.literal("none"),
  z.literal("data"),
  z.literal("approvals"),
  z.literal("update"),
  z.literal("generations"),
  z.literal("versions"),
  z.literal("change"),
  z.literal("info"),
  z.literal("collections"),
  z.literal("exportViaEmail"),
  z.literal("share"),
  z.literal("history"),
  z.literal("myLists"),
]);

const documentViewer$Schema = z
  .object({
    defaultPanel: DocumentViewerPanelKeysSchema.optional(),
  })
  .strip();
function urlParamsToFilter(urlParams: Record<string, string | null>): Props {
  const filter = documentViewer$Schema.safeParse(urlParams);

  if (!filter.success) {
    return {
      defaultPanel: "none",
    };
  }

  const result: Props = {
    defaultPanel: filter.data.defaultPanel,
  };

  return result;
}

const DocumentViewerPageFilter: FunctionComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const filter = urlParamsToFilter({
    defaultPanel: params.get("defaultPanel"),
  });

  return <DocumentViewerPage defaultPanel={filter.defaultPanel} />;
};

export default DocumentViewerPageFilter;
