import React, { useCallback, useMemo } from "react";

import { Box } from "./Box";
import type {
  ContextFieldProperties,
  InputControlValueType,
} from "@prodoctivity/shared/src/index-types";
import { IconButton } from "./Icon";
import { NumberField } from "./NumberField";
import { NumberFieldProps } from "gestalt";
import type momentType from "moment";
import { useColors } from "./ColorSchemeProvider";

type TimeInputProps = {
  onChange: (dateTime: number | undefined) => void;
  onBlur: () => void;
  id: string | undefined;
  disabled: boolean | undefined;
  error: string | undefined;
  typeValue: InputControlValueType;
  moment: typeof momentType;
  contextFieldProperties: ContextFieldProperties;
  resources: {
    clear: string;
  };
};

export const TimerView: React.FC<TimeInputProps> = ({
  onChange,
  onBlur,
  id,
  disabled,
  error,
  typeValue,
  moment,
  contextFieldProperties,
  resources,
}) => {
  const { colors } = useColors();
  const hours = useMemo(() => {
    switch (typeValue.dataType) {
      case "Time":
      case "DateTime":
        if (typeValue.value === undefined) {
          return undefined;
        }
        const val = Array.isArray(typeValue.value) ? typeValue.value[0] : typeValue.value;
        const d = moment(val);
        if (!d.isValid()) {
          return undefined;
        }
        return d.toDate().getHours();
      default:
        return undefined;
    }
  }, [moment, typeValue.dataType, typeValue.value]);

  const setHours = useCallback(
    (v: number) => {
      switch (typeValue.dataType) {
        case "Time": {
          const date = typeValue.value === undefined ? new Date() : typeValue.value;

          const val = Array.isArray(date) ? date[0] : typeValue.value;
          const d = moment(val);
          if (!d.isValid()) {
            onChange(undefined);
          } else {
            d.hour(v);
            onChange(d.toDate().getTime());
          }

          break;
        }
        case "DateTime": {
          if (typeValue.value === undefined) {
            onChange(undefined);
          } else {
            const val = Array.isArray(typeValue.value) ? typeValue.value[0] : typeValue.value;
            const d = moment(val);
            if (!d.isValid()) {
              onChange(undefined);
            } else {
              d.hour(v);
              onChange(d.toDate().getTime());
            }
          }
          break;
        }
        default: {
          onChange(undefined);
          break;
        }
      }
    },
    [moment, onChange, typeValue.dataType, typeValue.value]
  );

  const minutes = useMemo(() => {
    switch (typeValue.dataType) {
      case "Time":
      case "DateTime":
        if (typeValue.value === undefined) {
          return undefined;
        }
        const val = Array.isArray(typeValue.value) ? typeValue.value[0] : typeValue.value;
        const d = moment(val);
        if (!d.isValid()) {
          return undefined;
        }
        return d.toDate().getMinutes();
      default:
        return undefined;
    }
  }, [moment, typeValue.dataType, typeValue.value]);

  const setMinutes = useCallback(
    (v: number) => {
      switch (typeValue.dataType) {
        case "Time": {
          const date = typeValue.value === undefined ? new Date() : typeValue.value;

          const val = Array.isArray(date) ? date[0] : typeValue.value;

          const d = moment(val);
          if (!d.isValid()) {
            onChange(undefined);
          } else {
            d.minute(v);
            onChange(d.toDate().getTime());
          }

          break;
        }
        case "DateTime": {
          if (typeValue.value === undefined) {
            onChange(undefined);
          } else {
            const val = Array.isArray(typeValue.value) ? typeValue.value[0] : typeValue.value;
            const d = moment(val);
            if (!d.isValid()) {
              onChange(undefined);
            } else {
              d.minute(v);
              onChange(d.toDate().getTime());
            }
          }
          break;
        }
        default: {
          onChange(undefined);
          break;
        }
      }
    },
    [moment, onChange, typeValue.dataType, typeValue.value]
  );

  const handleHoursChange = useCallback(
    (value: number | undefined) => {
      if (
        value !== undefined &&
        !isNaN(value) &&
        value.toString().length <= 2 &&
        value >= 0 &&
        value <= 23
      ) {
        setHours(value);
      }
    },
    [setHours]
  );

  const handleMinutesChange = useCallback(
    (value: number | undefined) => {
      if (value !== undefined && !isNaN(value) && value.toString().length <= 2) {
        setMinutes(value);
      }
    },
    [setMinutes]
  );

  const onHoursChanged: NumberFieldProps["onChange"] = useCallback(
    (e) => {
      if (e.value !== undefined) {
        if (e.value < 0 || e.value > 23) {
          e.event.preventDefault();
          e.event.bubbles = false;
          e.event.defaultPrevented = true;
          e.event.stopPropagation();
          return;
        }
      }

      handleHoursChange(e.value);
    },
    [handleHoursChange]
  );

  const onMinutesChanged: NumberFieldProps["onChange"] = useCallback(
    (e) => {
      if (e.value !== undefined) {
        if (e.value < 0 || e.value > 59) {
          return;
        }
      }

      handleMinutesChange(e.value);
    },
    [handleMinutesChange]
  );

  const clear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  return (
    <Box display="flex" alignItems="center">
      <Box width={80}>
        <NumberField
          id={id ?? ""}
          size="lg"
          placeholder="HH"
          value={hours || 0}
          disabled={disabled}
          onChange={onHoursChanged}
          onBlur={onBlur}
          errorMessage={typeValue.dataType === "Time" ? error : undefined}
          max={23}
          min={0}
        />
      </Box>
      <Box paddingX={1}>:</Box>
      <Box width={80}>
        <NumberField
          id={id ?? ""}
          size="lg"
          placeholder="MM"
          value={minutes || 0}
          disabled={disabled}
          onChange={onMinutesChanged}
          onBlur={onBlur}
          max={59}
          min={0}
        />
      </Box>
      {!contextFieldProperties.minOccurs && (
        <>
          <Box margin={2} />
          <Box
            color={colors.neutral900}
            hoverColor={colors.primary}
            padding={2}
            borderRadius={6}
            display="flex"
            justifyContent="center"
          >
            <IconButton
              icon="x"
              accessibilityLabel={resources.clear}
              color={colors.white}
              onClick={clear}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
