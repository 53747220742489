import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const TableSvgIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 20,
  height = 15,
  color,
  onClick,
}) => {
  const { colors } = useColors();
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 20 15`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4 3.75C4 3.55109 4.07902 3.36032 4.21967 3.21967C4.36032 3.07902 4.55109 3 4.75 3H13.75C13.9489 3 14.1397 3.07902 14.2803 3.21967C14.421 3.36032 14.5 3.55109 14.5 3.75C14.5001 3.84853 14.4808 3.94611 14.4432 4.03717C14.4055 4.12822 14.3503 4.21095 14.2806 4.28062C14.211 4.35029 14.1282 4.40553 14.0372 4.44318C13.9461 4.48082 13.8485 4.50013 13.75 4.5H4.75C4.55109 4.5 4.36032 4.42098 4.21967 4.28033C4.07902 4.13968 4 3.94891 4 3.75V3.75ZM5.5 7.5C5.5 7.30109 5.57902 7.11032 5.71967 6.96967C5.86032 6.82902 6.05109 6.75 6.25 6.75H15.25C15.3485 6.74987 15.4461 6.76918 15.5372 6.80682C15.6282 6.84447 15.711 6.89971 15.7806 6.96938C15.8503 7.03905 15.9055 7.12178 15.9432 7.21283C15.9808 7.30388 16.0001 7.40147 16 7.5C16.0001 7.59853 15.9808 7.69611 15.9432 7.78717C15.9055 7.87822 15.8503 7.96095 15.7806 8.03062C15.711 8.10029 15.6282 8.15553 15.5372 8.19318C15.4461 8.23082 15.3485 8.25013 15.25 8.25H6.25C6.05109 8.25 5.86032 8.17098 5.71967 8.03033C5.57902 7.88968 5.5 7.69891 5.5 7.5ZM13.75 12H4.75C4.55109 12 4.36032 11.921 4.21967 11.7803C4.07902 11.6397 4 11.4489 4 11.25C4 11.0511 4.07902 10.8603 4.21967 10.7197C4.36032 10.579 4.55109 10.5 4.75 10.5H13.75C13.8485 10.4999 13.9461 10.5192 14.0372 10.5568C14.1282 10.5945 14.211 10.6497 14.2806 10.7194C14.3503 10.789 14.4055 10.8718 14.4432 10.9628C14.4808 11.0539 14.5001 11.1515 14.5 11.25C14.5001 11.3485 14.4808 11.4461 14.4432 11.5372C14.4055 11.6282 14.3503 11.711 14.2806 11.7806C14.211 11.8503 14.1282 11.9055 14.0372 11.9432C13.9461 11.9808 13.8485 12.0001 13.75 12Z"
        fill={calculateColor(color || colors.neutral900)}
      />
    </svg>
  );
};
