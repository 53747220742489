import { ComboBox, Spinner } from "@prodoctivity/design-system";
import type { ComboBoxItemType, ComboBoxProps } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useState } from "react";

import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type Props = {
  id: string;
  label: string;
  selectedUsername: string | undefined;
  accessibilityClearButtonLabel: string;
  noResultText: string;
  placeholder?: string;
  size?: ComboBoxProps["size"];
  disabled?: boolean;
  onSelect?: ComboBoxProps["onSelect"];
  onClear?: () => void;
};

export const OrganizationUserLookup: FunctionComponent<Props> = ({
  id,
  label,
  selectedUsername,
  accessibilityClearButtonLabel,
  noResultText,
  placeholder,
  size,
  disabled,
  onSelect,
  onClear,
}) => {
  const { listAllOrganizationUsers } = useServices();
  const { resources } = useAppTranslation();

  const { data, isLoading } = useOrganizationQuery(
    `/organization-users`,
    listAllOrganizationUsers,
    {
      staleTime: 60 * 1000,
    }
  );

  const activeUsers = useMemo(() => {
    return data?.users.filter((user) => user.isActive) || [];
  }, [data]);

  const [input, setInput] = useState("");

  const onInputChange = useCallback((args: { value: string }) => {
    setInput(args.value);
  }, []);

  const { options, selectedOption } = useMemo(() => {
    let selectedOption: ComboBoxItemType | undefined = undefined;
    const options: ComboBoxItemType[] = [];
    const list = (activeUsers || []).filter(
      (item) =>
        item.username.startsWith(input) || `${item.firstName} ${item.lastName}`.startsWith(input)
    );

    for (const item of list) {
      const user: ComboBoxItemType = {
        value: item.username,
        label: `${item.firstName} ${item.lastName}`,
        subtext: item.positionRole,
      };
      if (selectedUsername && user.value === selectedUsername) {
        selectedOption = user;
      }
      options.push(user);
    }

    return { options, selectedOption };
  }, [activeUsers, selectedUsername, input]);

  const selectedLabel = selectedOption?.label || "";

  const onBlur = useCallback(() => {
    if (!selectedUsername) {
      setInput("");
    } else {
      setInput(selectedLabel);
    }
  }, [selectedUsername, selectedLabel]);

  const clear = useCallback(() => {
    if (onClear) {
      onClear();
    }
    setInput("");
  }, [onClear]);

  if (isLoading || !activeUsers) {
    return <Spinner show={true} accessibilityLabel={resources.loading} />;
  }

  return (
    <ComboBox
      id={id}
      label={label}
      accessibilityClearButtonLabel={accessibilityClearButtonLabel}
      noResultText={noResultText}
      onSelect={(args) => {
        setInput(args.item.label);
        if (onSelect) {
          onSelect(args);
        }
      }}
      size={size}
      options={options}
      placeholder={placeholder}
      onClear={clear}
      selectedOption={selectedOption}
      inputValue={input}
      disabled={disabled}
      onChange={onInputChange}
      onBlur={onBlur}
    />
  );
};
