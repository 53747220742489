import {
  Box,
  ChevronSvgIcon,
  TapArea,
  Text,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode, useMemo } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { BackSvg } from "../../svg/BackSvg";
import { OrganizationLink } from "../OrganizationLink";
import styles from "./BreadCrumb.module.css";

export type BreadCrumbEntry =
  | {
      type: "url";
      name: string;
      url: string;
    }
  | {
      type: "text";
      name: string;
      ellipsisLength?: number;
    }
  | {
      type: "content";
      content: ReactNode;
    };

type Props = {
  entries: BreadCrumbEntry[];
  extraComponent?: JSX.Element;
  hideBackButton?: boolean;
};

export const BreadCrumb: FunctionComponent<Props> = ({
  entries,
  extraComponent,
  hideBackButton,
}) => {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const values = useMemo(() => {
    let items = Array.isArray(entries) ? entries : [entries];
    if (items.length > 0 && breakpoint === "small") {
      items = items.slice(-1);
    }
    return items;
  }, [entries, breakpoint]);
  // const { documentId } = useParams();
  const { resources } = useAppTranslation();

  return (
    <Box display="flex" direction="column" flex="grow">
      <Box
        display="flex"
        paddingY={4}
        paddingX={6}
        height={62}
        direction="row"
        flex="grow"
        fit={true}
        justifyContent="between"
        color={colors.white}
      >
        <Box height={28} display="flex" alignItems="center" flex="grow">
          {!hideBackButton && window.history.length > 1 ? (
            <Box display="flex" direction="row">
              <Box display="flex" direction="column">
                <Box margin={1} />
                <TapArea onTap={() => window.history.back()}>
                  <Tooltip text={resources.goBack}>
                    <BackSvg width={20} height={17} />
                  </Tooltip>
                </TapArea>
              </Box>
              <Box margin={2} />
            </Box>
          ) : null}
          <Box display="flex" direction="row" flex="grow">
            <ul className={styles.breadcrumb}>
              {values.map((value, idx) => {
                if (value.type === "content") {
                  return (
                    <li key={`content_${idx}`}>
                      <Box display="flex" alignItems="center">
                        {idx > 0 && <ChevronSvgIcon color={colors.subtle} direction={"right"} />}
                        {value.content}
                      </Box>
                    </li>
                  );
                }
                if (value.type === "url") {
                  return (
                    <li key={`${value.url}_${idx}`}>
                      <Box display="flex" alignItems="center">
                        {idx > 0 && <ChevronSvgIcon color={colors.subtle} direction={"right"} />}
                        <OrganizationLink to={value.url}>
                          <Text color={colors.neutral900}>{value.name}</Text>
                        </OrganizationLink>
                        {/* {!documentId && (
                          <ChevronSvgIcon color={colors.subtle} direction={"right"} />
                        )} */}
                      </Box>
                    </li>
                  );
                }
                return (
                  <li key={`${value.name}_${idx}`}>
                    <Box display="flex" alignItems="center">
                      {idx > 0 && <ChevronSvgIcon color={colors.subtle} direction={"right"} />}
                      {/* {documentId && (
                        <ChevronSvgIcon color={colors.subtle} direction={"right"} />
                      )} */}
                      <Text color={colors.black600} ellipsisLength={value.ellipsisLength}>
                        {value.name}
                      </Text>
                    </Box>
                  </li>
                );
              })}
            </ul>
          </Box>
          <Box display="flex" flex="grow">
            <Box margin={1} />
          </Box>
          {extraComponent ? (
            <Box display="flex" flex="shrink" alignItems="end">
              {extraComponent}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box height={1} color={colors.neutral500} />
    </Box>
  );
};
