import { Box, TapArea, Text, useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode, useCallback } from "react";

import { ChevronDownIcon } from "../../../../svg/ChevronDownIcon";
import { ChevronRightIcon } from "../../../../svg/ChevronRightIcon";

export type Tab = {
  title: string;
  content: ReactNode;
};
type TabsProps = {
  tabs: Tab[];
  tabSummary: string[];
  openTabs: Set<"general" | "input" | "output">;
  setOpenTab(tab: "general" | "input" | "output", value: boolean): void;
};

export const DataDictionaryTabs: FunctionComponent<TabsProps> = ({
  tabs,
  tabSummary,
  openTabs,
  setOpenTab,
}) => {
  const { breakpoint } = useDesignBreakpoint();
  const { colors } = useColors();
  const handleTabClick = useCallback(
    (index: "general" | "input" | "output") => {
      setOpenTab(index, !openTabs.has(index));
    },
    [openTabs, setOpenTab]
  );
  return (
    <Box display="flex" direction="column" borderStyle="sm" borderRadius={4}>
      <Box display="flex" direction="column">
        {tabs.map((tab, index) => {
          const tabName = index === 0 ? "general" : index === 1 ? "input" : "output";
          return (
            <Box
              key={`${tab.title}_${index}`}
              color={openTabs.has(tabName) ? colors.white : colors.neutral100}
              display="flex"
              justifyContent="start"
              direction="column"
              alignItems="start"
              width={breakpoint === "small" ? 355 : "100%"}
            >
              <Box
                display="flex"
                justifyContent="start"
                height={"fit-content"}
                paddingX={6}
                paddingY={3}
                width={"100%"}
              >
                <TapArea
                  onTap={() => {
                    handleTabClick(tabName);
                  }}
                  role="button"
                >
                  <Box display="flex" width={"100%"} alignItems="center" justifyContent="between">
                    <Box display="flex" gap={4} alignItems="center" justifyContent="start">
                      {openTabs.has(tabName) ? <ChevronDownIcon /> : <ChevronRightIcon />}
                      <Text weight={openTabs.has(tabName) ? "bold" : "normal"}>{tab.title}</Text>
                    </Box>
                    <Text color={colors.neutral700} size="200" align="end">
                      {tabSummary[index]}
                    </Text>
                  </Box>
                </TapArea>
              </Box>
              <Box color={colors.white} width={"100%"}>
                {openTabs.has(tabName) ? tabs[index].content : null}
              </Box>

              <Box height={1} width={"100%"} color={colors.neutral400}></Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
