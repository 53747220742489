import {
  Box,
  Button,
  ChevronSvgIcon,
  DocumentViewer,
  DocumentViewerEvents,
  TapArea,
  Text,
  useColors,
  usePubSub,
} from "@prodoctivity/design-system";
import { FunctionComponent, useMemo, useState } from "react";

import { MimeTypes } from "@prodoctivity/shared";
import type { ListableTemplate } from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useTemplateRow } from "./hooks";

type Props = {
  templateId: string;
  templates: ListableTemplate[];
};

export const TemplateRow: FunctionComponent<Props> = ({ templateId, templates }) => {
  const { colors } = useColors();
  const { templateVersionUrl, currentTemplate, isActive, setActive, goToTemplateDetails } =
    useTemplateRow(templateId, templates);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { resources, moment } = useAppTranslation();

  const versionUrlSrc = useMemo(() => {
    if (!templateVersionUrl) {
      return [];
    }
    return [templateVersionUrl];
  }, [templateVersionUrl]);

  const { eventManager } = usePubSub<DocumentViewerEvents>();

  return (
    <Box flex="grow" borderRadius={6}>
      <Box
        flex="grow"
        display="flex"
        justifyContent="between"
        alignItems="center"
        paddingY={2}
        paddingX={5}
        color={isActive ? colors.primary700 : colors.neutral300}
      >
        <TapArea onTap={() => setActive((prev) => !prev)}>
          <Box display="flex" gap={3} alignItems="center">
            <ChevronSvgIcon
              color={isActive ? colors.white : undefined}
              direction={isActive ? "up" : "down"}
            />
            <Text weight={"normal"} color={isActive ? colors.white : colors.black600}>
              {currentTemplate?.name}
            </Text>
          </Box>
        </TapArea>
        <Box alignSelf="end">
          <Button color={"gray"} onClick={goToTemplateDetails} text={resources.details} />
        </Box>
      </Box>
      {isActive && templateVersionUrl && (
        <Box color={colors.white} height={800}>
          <DocumentViewer
            key={templateVersionUrl}
            src={versionUrlSrc}
            height="100%"
            mimeType={MimeTypes.PdfDocument}
            strategy="normal"
            resources={resources}
            templateDetailsExist={false}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            eventManager={eventManager}
            moment={moment}
          />
        </Box>
      )}
    </Box>
  );
};
