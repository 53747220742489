import { Box, TapArea, Text, useColors } from "@prodoctivity/design-system";
import React, { ReactNode, useEffect, useState } from "react";

import { useAppTranslation } from "../hooks/useAppTranslation";
import { CircleCheckSvgIcon } from "../svg/CircleCheckSvgIcon";
import { CircleInfoSvgIcon } from "../svg/CircleInfoSvgIcon";

type NotificationMessageProps = {
  type: "error" | "success" | "warn" | "info";
  position: "top-right" | "top-left" | "bottom-right" | "bottom-left";
  message: string | ReactNode;
  title?: string;
  handleDismiss: () => void;
  /** delay in milliseconds to auto-dismiss*/
  delay?: number;
};

export type ToastMessageType = {
  type: NotificationMessageProps["type"];
  message: NotificationMessageProps["message"];
};

export const NotificationMessage = ({
  title,
  type,
  position,
  message,
  handleDismiss,
  delay,
}: NotificationMessageProps) => {
  const { colors } = useColors();
  const [showNotification, setShowNotification] = useState(true);
  const { resources } = useAppTranslation();

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setShowNotification(false);
        handleDismiss();
      }, delay);
    }
  }, [delay, handleDismiss]);

  const onDismiss = () => {
    setShowNotification(false);
    handleDismiss();
  };

  const getColor = () => {
    switch (type) {
      case "error":
        return colors.error;
      case "success":
        return colors.success;
      case "warn":
        return colors.secondary;
      case "info":
      default:
        return colors.primary300;
    }
  };

  const getTitle = () => {
    switch (type) {
      case "error":
        return resources.unknownError;
      case "success":
        return resources.success;
      case "warn":
        return resources.warning;
      case "info":
      default:
        return resources.info;
    }
  };

  const getIcon = () => {
    switch (type) {
      case "error":
        return <CircleInfoSvgIcon type="exclamation-inverted" color={colors.error} />;
      case "success":
        return <CircleCheckSvgIcon />;
      case "warn":
        return <CircleInfoSvgIcon type="exclamation" color={colors.lightWash} />;
      case "info":
      default:
        return <CircleInfoSvgIcon type="exclamation-inverted" color={colors.error} />;
    }
  };

  return (
    <>
      {showNotification && (
        <Box
          position="fixed"
          top={position.includes("top")}
          bottom={position.includes("bottom")}
          right={position.includes("right")}
          left={position.includes("left")}
          marginBottom={12}
          marginStart={5}
        >
          <Box
            minWidth={369}
            minHeight={72}
            padding={3}
            borderRadius={6}
            borderStyle="shadow"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color={colors.white}
            marginBottom={10}
          >
            <Box marginEnd={3} width={4} minHeight={52} color={getColor()}></Box>
            <Box marginEnd={3}>{getIcon()}</Box>
            <Box column={10}>
              <Box marginBottom={1}>
                <Text color={colors.black900} weight="bold">
                  {title || getTitle()}
                </Text>
              </Box>
              {typeof message === "string" ? <Text color={colors.subtle}>{message}</Text> : message}
            </Box>
            <Box
              column={1}
              marginStart={3}
              padding={1}
              role="button"
              tabIndex={0}
              onClickCapture={onDismiss}
            >
              <TapArea>
                <Text color={colors.subtle}>X</Text>
              </TapArea>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
