import { Fragment, ReactNode } from "react";

type Props = {
  show?: boolean;
  rounded?: boolean;
  width?: string | number;
  height?: string | number;
  children?: ReactNode;
};
export function Skeleton({
  rounded,
  width = "100%",
  height = "100%",
  show = true,
  children,
}: Props) {
  return show ? (
    <div
      style={{ width, height, borderRadius: rounded ? "50%" : undefined }}
      className={"prodoctivity-skeleton"}
    />
  ) : (
    <Fragment>{children}</Fragment>
  );
}
