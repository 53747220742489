import { BrowseDeletedDocumentsFilter } from "../../link-templates";
import { DocumentRecoveryList } from "./DocumentRecoveryList";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { z } from "zod";

const browseDelectedDocumentsFilter$Schema = z
  .object({
    dateStart: z.preprocess((arg) => {
      if (typeof arg === "string") {
        if (arg.toLowerCase() === "true") {
          return true;
        } else if (arg.toLowerCase() === "false") {
          return false;
        }
      }
      return arg;
    }, z.coerce.date({}).optional().catch(undefined)),
    dateEnd: z.preprocess((arg) => {
      if (typeof arg === "string") {
        if (arg.toLowerCase() === "true") {
          return true;
        } else if (arg.toLowerCase() === "false") {
          return false;
        }
      }
      return arg;
    }, z.coerce.date({}).optional().catch(undefined)),
    documentTypeIdList: z
      .preprocess((arg) => {
        if (typeof arg === "string") {
          return arg.split(",");
        } else if (Array.isArray(arg)) {
          return arg;
        }
        return undefined;
      }, z.string().min(1).array())
      .optional()
      .catch([]),
    updatedBy: z.string().default("").optional().catch(undefined),
    pageNumber: z.coerce.number().nonnegative().catch(0),
    rowsPerPage: z.enum(["15", "30", "100", "500"]).catch("15"),
    sortField: z
      .enum(["documentType", "name", "deletedBy", "deletedAt"])
      .optional()
      .default("deletedAt")
      .catch("deletedAt"),
    sortDirection: z.enum(["ASC", "DESC"]).optional().default("DESC").catch("DESC"),
  })
  .strip();

function urlParamsToFilter(urlParams: Record<string, string | null>): BrowseDeletedDocumentsFilter {
  const filter = browseDelectedDocumentsFilter$Schema.safeParse(urlParams);

  if (!filter.success) {
    return {
      dateStart: undefined,
      dateEnd: undefined,
      documentTypeIdList: undefined,
      pageNumber: 0,
      rowsPerPage: "15",
      sortDirection: "ASC",
      sortField: undefined,
      updatedBy: undefined,
    };
  }

  const result: BrowseDeletedDocumentsFilter = {
    dateStart: filter.data.dateStart ? filter.data.dateStart.getTime() : undefined,
    dateEnd: filter.data.dateEnd ? filter.data.dateEnd.getTime() : undefined,
    documentTypeIdList: filter.data.documentTypeIdList,
    pageNumber: filter.data.pageNumber,
    rowsPerPage: filter.data.rowsPerPage,
    sortDirection: filter.data.sortDirection || "ASC",
    sortField: filter.data.sortField,
    updatedBy: filter.data.updatedBy,
  };

  return result;
}

export const DocumentRecoveryPage: FunctionComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const filter = urlParamsToFilter({
    dateStart: params.get("dateStart"),
    dateEnd: params.get("dateEnd"),
    documentTypeIdList: params.get("documentTypeIdList"),
    pageNumber: params.get("pageNumber"),
    rowsPerPage: params.get("rowsPerPage"),
    sortDirection: params.get("sortDirection"),
    sortField: params.get("sortField"),
    updatedBy: params.get("updatedBy"),
  });

  return <DocumentRecoveryList filter={filter} />;
};
