"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenderMultiple = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var design_system_1 = require("@prodoctivity/design-system");
var shared_1 = require("@prodoctivity/shared");
var react_1 = require("react");
var FilterBuilder_1 = require("./FilterBuilder");
var RenderMultiple = function (props) {
    var objExpression = props.objExpression, i18n = props.translateFn, state = props.state, parentProps = props.parentProps, colors = props.colors;
    var switchUuid = (0, shared_1.createUuid)();
    var highlightCheckUuid = (0, shared_1.createUuid)();
    var reductors = Object.keys(objExpression.operatorList).reduce(function (arr, k) {
        var op = objExpression.operatorList[k];
        if (!!op /* This removes the undefined possibility */ && !!op.reductor) {
            arr.push(op);
        }
        return arr;
    }, []);
    return (
    // <div
    //   className={`${parentProps.isChild ? "filterbuilder-multiple-row" : "filterbuilder-root"}${
    //     parentProps.expression.isNegative ? " filterbuilder-negative" : ""
    //   }${state.highlighted ? " highlighted" : ""}`}
    // >
    (0, jsx_runtime_1.jsx)(design_system_1.Box, { paddingY: 4, paddingLeft: 4, dangerouslySetInlineStyle: {
            __style: { borderBottom: "1px solid #cacbce" },
        }, children: (0, jsx_runtime_1.jsxs)(design_system_1.Box, { borderRadius: 6, borderColor: colors.neutral600, width: "fit-content", overflow: "hidden", children: [(0, jsx_runtime_1.jsxs)(design_system_1.Box, { paddingX: 6, display: "flex", alignItems: "center", justifyContent: "between", paddingY: 3, color: colors.neutral250, children: [(0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", gap: 4, children: [(0, jsx_runtime_1.jsx)(design_system_1.Text, { weight: "bold", children: parentProps.expression.operator.toString() === "and"
                                        ? i18n("dataTypeValues.and").toUpperCase()
                                        : i18n("or").toUpperCase() }), reductors.length === 2 ? ((0, jsx_runtime_1.jsx)(design_system_1.Box, { children: (0, jsx_runtime_1.jsx)(design_system_1.Switch, { id: switchUuid, switched: parentProps.expression.operator === "and", onChange: props.inputSwitchChanged }) })) : ((0, jsx_runtime_1.jsx)(design_system_1.Box, { children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { width: 300, children: (0, jsx_runtime_1.jsx)(design_system_1.SelectList, __assign({ id: "".concat(state.id, "-reductors") }, props.selectOperatorProps, { value: parentProps.expression.operator, onChange: props.inputSelectOperatorChanged, options: reductors.map(function (reductor) { return ({
                                                label: i18n(reductor.name),
                                                value: reductor.name,
                                            }); }) })) }) }))] }), (0, jsx_runtime_1.jsx)(design_system_1.Box, { children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", gap: 4, children: (0, jsx_runtime_1.jsx)(design_system_1.Tooltip, { text: "".concat(i18n("filterBuilder.turnYourExpression"), " - ").concat(parentProps.expression.isNegative
                                        ? i18n("filterBuilder.positiveF")
                                        : i18n("filterBuilder.negativeF")), children: (0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", gap: 4, alignItems: "center", children: [(0, jsx_runtime_1.jsx)(design_system_1.TapArea, { children: parentProps.helpMode ? ((0, jsx_runtime_1.jsx)(design_system_1.Text, { children: parentProps.expression.operator === "and"
                                                        ? parentProps.expression.isNegative
                                                            ? i18n("filterBuilder.clickHereToTurnToPositiveAnd")
                                                            : i18n("filterBuilder.clickHereToTurnToNegativeAnd")
                                                        : parentProps.expression.operator === "or"
                                                            ? parentProps.expression.isNegative
                                                                ? i18n("filterBuilder.clickHereToTurnToPositiveOr")
                                                                : i18n("filterBuilder.clickHereToTurnToNegativeOr")
                                                            : i18n("filterBuilder.negativeF") })) : ((0, jsx_runtime_1.jsxs)(design_system_1.Text, { children: [i18n("filterBuilder.negativeF"), " "] })) }), (0, jsx_runtime_1.jsx)(design_system_1.Checkbox, { id: highlightCheckUuid, onChange: function (e) { return props.inputMultipleNegativeChanged(e.checked); }, checked: parentProps.expression.isNegative })] }) }) }) })] }), (0, jsx_runtime_1.jsx)(design_system_1.Divider, { size: 1, direction: "horizontal" }), (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "row", justifyContent: "around", alignContent: "center", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", alignItems: "center", children: (0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", direction: "column", children: [(parentProps.expression.expressionList || []).map(function (expressionItem, i) {
                                    return ((0, jsx_runtime_1.jsx)(ExpressionListItem, { expressionItem: expressionItem, i18n: i18n, index: i, makeChangeHandler: props.makeChangeHandler, parentProps: parentProps }, "".concat(parentProps.level, "-").concat(i)));
                                }), (0, jsx_runtime_1.jsx)(design_system_1.Box, { padding: 4, children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { padding: 4, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: colors.primaryHover0, borderRadius: 6, borderColor: colors.primary, onClickCapture: props.addNewExpression, children: (0, jsx_runtime_1.jsx)(design_system_1.TapArea, { rounding: 4, children: (0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", alignItems: "center", justifyContent: "center", gap: 4, children: [(0, jsx_runtime_1.jsx)(design_system_1.Icon, { accessibilityLabel: i18n("filterBuilder.addAnExpressionHere"), icon: "plus", size: 4 }), (0, jsx_runtime_1.jsx)(design_system_1.Tooltip, { text: i18n("filterBuilder.addAnExpressionHere"), children: (0, jsx_runtime_1.jsx)(design_system_1.Text, { color: colors.primary, size: "200", children: i18n("addNewLine") }) })] }) }) }) })] }) }) })] }) }));
};
exports.RenderMultiple = RenderMultiple;
var ExpressionListItem = function (_a) {
    var index = _a.index, parentProps = _a.parentProps, expressionItem = _a.expressionItem, makeChangeHandler = _a.makeChangeHandler;
    var expressionDataInput = (0, react_1.useMemo)(function () {
        if (expressionItem instanceof shared_1.Expression) {
            return expressionItem.toDataInput();
        }
        else {
            return expressionItem;
        }
    }, [expressionItem]);
    return ((0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "row", alignItems: "center", children: (0, jsx_runtime_1.jsx)(FilterBuilder_1.FilterBuilder, __assign({}, parentProps, { level: (parentProps.level + 1) % 7, isChild: true, expression: expressionDataInput, expressionIndex: index, onExpressionChanged: makeChangeHandler, resources: parentProps.resources, parentExpressionOperator: parentProps.expression.operator })) }));
};
