import {
  Box,
  Button,
  Center,
  Divider,
  Link,
  Text,
  TextField,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";

import { EmailSent } from "./ForgotPassword";
import { FormEventHandler } from "react";
import { organizationLinkTemplates } from "../../link-templates";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { validateEmail } from "@prodoctivity/shared";

interface Props {
  emailSent: boolean;
  resetState: () => void;
  handleSubmit: FormEventHandler<unknown>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string | undefined;
  isError: boolean;
  isLoading: boolean;
}
export function ForgotPasswordMedium({
  isError,
  isLoading,
  errorMessage,
  setEmail,
  email,
  emailSent,
  resetState,
  handleSubmit,
}: Props) {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const breakPoint = breakpoint === "small";
  const sizeBreakPoint = breakPoint ? "400" : "500";

  return (
    <Box paddingX={8} paddingY={6} rounding="pill">
      {emailSent ? (
        <EmailSent resetState={resetState} />
      ) : (
        <Center direction="column">
          <Box>
            <Text size={sizeBreakPoint} weight="bold">
              {resources.forgotPasswordPage.forgotYourPassword}
            </Text>
          </Box>
          <Box margin={6}></Box>
          <Box maxWidth={500}>
            <Text overflow="breakWord" align="start" size="300">
              {resources.forgotPasswordPage.enterAssociatedEmail}
            </Text>
            <Box margin={4}></Box>
            <Divider />
            <Box margin={6}></Box>
            <form onSubmit={handleSubmit} method="POST">
              <Box display="flex" direction="column" gap={2}>
                <Box>
                  <Text weight="bold">{resources.email}</Text>
                </Box>
                <TextField
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.value)}
                  id="emailField"
                  placeholder="yourname@gmail.com"
                  autoComplete="email"
                  errorMessage={errorMessage}
                  maxLength={{
                    characterCount: 300,
                    errorAccessibilityLabel: resources.dataDictionary.limitReached,
                  }}
                />
                <Box margin={4}></Box>

                <Button
                  size="lg"
                  text={resources.forgotPasswordPage.resetPassword}
                  accessibilityLabel={resources.forgotPasswordPage.resetPassword}
                  fullWidth={true}
                  disabled={isLoading || isError || !validateEmail(email)}
                  type="button"
                />
              </Box>
            </form>
            <Box margin={4}></Box>
            <Box paddingX={10} alignContent="center" alignItems="center">
              <Link href={organizationLinkTemplates.login()}>
                <Text align="center" weight="bold" color={colors.primary}>
                  {resources.forgotPasswordPage.backToLogin}
                </Text>
              </Link>
            </Box>
          </Box>
        </Center>
      )}
    </Box>
  );
}
