import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  bgColor?: ColorConfig;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CircleQuestionSvgIcon: FunctionComponent<Props> = ({
  color,
  bgColor,
  width = 13,
  height = 13,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={svgStyle}
    >
      <g clipPath="url(#clip0_9526_119478)">
        <path
          d="M6.50336 0C2.91275 0 0 2.90604 0 6.49664C0 10.0872 2.91275 13 6.50336 13C10.094 13 13 10.0872 13 6.49664C13 2.90604 10.094 0 6.50336 0ZM6.24832 9.63758C5.93289 9.63758 5.71812 9.39597 5.71812 9.08054C5.71812 8.74497 5.9396 8.51007 6.26174 8.51007C6.58389 8.51007 6.79195 8.74497 6.79195 9.08054C6.79195 9.39597 6.58389 9.63758 6.24832 9.63758ZM7.12752 6.25503C6.72483 6.72483 6.58389 7.12081 6.61074 7.58389V7.81879H5.91275L5.89933 7.58389C5.84564 7.09396 6.00671 6.56376 6.45638 6.02013C6.86577 5.54362 7.08725 5.18792 7.08725 4.78523C7.08725 4.32215 6.79866 4.02013 6.2349 4.00671C5.90604 4.00671 5.54362 4.11409 5.32215 4.28859L5.10067 3.71812C5.40268 3.50336 5.91275 3.3557 6.39597 3.3557C7.42953 3.3557 7.89933 4 7.89933 4.68456C7.89933 5.30201 7.55705 5.73825 7.12752 6.25503Z"
          fill={bgColor ? calculateColor(bgColor) : "#2951A0"}
        />
        <path
          d="M6.79258 9.08219C6.79258 9.39762 6.58452 9.63924 6.24895 9.63924C5.93352 9.63924 5.71875 9.39762 5.71875 9.08219C5.71875 8.74662 5.94023 8.51172 6.26237 8.51172C6.58452 8.51172 6.79258 8.74662 6.79258 9.08219Z"
          fill={color ? calculateColor(color) : "white"}
        />
        <path
          d="M7.12841 6.2548C6.72572 6.7246 6.58478 7.12057 6.61163 7.58366V7.81856H5.91364L5.90022 7.58366C5.84653 7.09372 6.0076 6.56352 6.45727 6.0199C6.86666 5.54339 7.08814 5.18768 7.08814 4.785C7.08814 4.32191 6.79955 4.0199 6.23579 4.00648C5.90693 4.00648 5.54452 4.11386 5.32304 4.28835L5.10156 3.71788C5.40358 3.50312 5.91364 3.35547 6.39686 3.35547C7.43042 3.35547 7.90022 3.99976 7.90022 4.68433C7.90022 5.30178 7.55794 5.73802 7.12841 6.2548Z"
          fill={color ? calculateColor(color) : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_9526_119478">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
