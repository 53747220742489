import type { DataType } from "@prodoctivity/shared/src/index-types";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT = "YYYY-MM-DD hh:mm a";
export const DATETIME_24_FORMAT = "MMMM DD, YYYY - HH:mm";
export const TIME_FORMAT = "hh:mm a";
export const TIME_24_FORMAT = "HH:mm";

export function dateFormat(dataType: DataType, is12Hour: boolean) {
  if (dataType) {
    switch (dataType.toLowerCase()) {
      case "time":
        return is12Hour ? TIME_FORMAT : TIME_24_FORMAT;
      case "datetime":
        return is12Hour ? DATETIME_FORMAT : DATETIME_24_FORMAT;
      case "date":
        return DATE_FORMAT;
      default:
        return DATE_FORMAT;
    }
  }
  return DATE_FORMAT;
}
