import {
  Box,
  Image,
  Svg,
  TapArea,
  Text,
  backIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";

import DevicesSrc from "../assets/devices.png";
import Particles from "../assets/particles.svg";
import { AnimatedWaves } from "../components/AnimatedWaves/AnimatedWaves";
import { Page } from "../components/Layout/Page";
import { useAppTranslation } from "../hooks/useAppTranslation";
import { useSettings } from "../hooks/useSettings";

const DownloadFluencyAdvancedAuthorPage = () => {
  const { colors } = useColors();
  const { fluencyAuthorDownloadLink } = useSettings();
  const { resources } = useAppTranslation();

  const { breakpoint } = useDesignBreakpoint();
  return (
    <Page>
      <Box width={"100%"} height={"95vh"} overflow="hidden" position="relative">
        <Box position="absolute" width={"100%"} height={"100vh"} id="particles" zIndex={backIndex}>
          <Svg src={Particles} width="100%" height="100%" alt="particles" />
        </Box>

        <Box position="absolute" width={"100%"} bottom id="waves">
          <AnimatedWaves />
        </Box>
        <Box width="100%">
          <Box
            maxWidth="1280px"
            width="100%"
            display="flex"
            direction="row"
            justifyContent="evenly"
            alignContent="center"
            margin={"auto"}
          >
            {breakpoint === "hd" && (
              <Box direction="column" display="flex" justifyContent="center" alignContent="center">
                <Box width={512} right marginTop={12} marginEnd={12}>
                  <Box marginTop={12} />
                  <Image
                    src={DevicesSrc}
                    alt="phone"
                    naturalHeight={512}
                    naturalWidth={512}
                  ></Image>
                </Box>
              </Box>
            )}
            <Box
              direction="column"
              display="flex"
              justifyContent="center"
              alignContent="center"
              paddingX={6}
            >
              <Box marginBottom={2}>
                <Text color={colors.black900} size={"600"}>
                  {resources.configuratorPage.buildtemplatesMSWord}
                </Text>
              </Box>

              <Box marginBottom={4}>
                <Text color={colors.neutral900} size="300">
                  {resources.configuratorPage.featuresofOurSoftware}{" "}
                  {resources.configuratorPage.managingDocumentsEasy}
                </Text>
              </Box>

              <Box
                marginBottom={4}
                display="flex"
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
                <Box width={256} margin={"auto"}>
                  <TapArea
                    onTap={() => {
                      window.open(fluencyAuthorDownloadLink, "_blank");
                    }}
                  >
                    <Box color={colors.primary} flex="shrink" padding={4} rounding={"pill"}>
                      <Text color={colors.white} align="center" weight="bold">
                        {resources.configuratorPage.downloadAdvancedAuthor}{" "}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default DownloadFluencyAdvancedAuthorPage;
