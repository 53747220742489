import { getResources } from "../resources";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useAppTranslation = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const resources = useMemo(() => {
    return getResources(t);
  }, [t]);

  if (moment.locale() !== lang) {
    moment.locale(lang);
  }

  return { resources, moment };
};
