import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export const EyeSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 24,
  height = 24,
  color = "#767676",
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.47583 12.9375V12.942C4.45057 13.0373 4.40677 13.1266 4.34695 13.205C4.28713 13.2833 4.21246 13.3491 4.1272 13.3985C4.04194 13.448 3.94777 13.4801 3.85008 13.4932C3.75238 13.5062 3.65307 13.4999 3.55783 13.4745C2.91133 13.3035 3.02533 12.558 3.02533 12.558L3.05233 12.465C3.05233 12.465 3.09133 12.339 3.12283 12.2475C3.50776 11.1682 4.07443 10.1627 4.79833 9.2745C6.14383 7.6335 8.40733 6 11.9998 6C15.5923 6 17.8558 7.6335 19.2028 9.2745C19.9267 10.1627 20.4934 11.1682 20.8783 12.2475C20.9106 12.3403 20.9406 12.4338 20.9683 12.528L20.9728 12.5475V12.5535L20.9743 12.5565C21.0219 12.7474 20.9926 12.9493 20.8926 13.1188C20.7927 13.2883 20.6302 13.4117 20.4401 13.4625C20.25 13.5132 20.0476 13.4873 19.8765 13.3902C19.7054 13.2931 19.5793 13.1327 19.5253 12.9435L19.5238 12.9375L19.5118 12.9C19.4268 12.6345 19.3266 12.3741 19.2118 12.12C18.9061 11.4402 18.5128 10.8033 18.0418 10.2255C16.9288 8.868 15.0658 7.5 11.9998 7.5C8.93383 7.5 7.07233 8.868 5.95783 10.2255C5.34658 10.9762 4.86726 11.8253 4.54033 12.7365C4.5218 12.7907 4.50429 12.8452 4.48783 12.9L4.47583 12.9375ZM8.24983 14.25C8.24983 13.2554 8.64492 12.3016 9.34818 11.5983C10.0514 10.8951 11.0053 10.5 11.9998 10.5C12.9944 10.5 13.9482 10.8951 14.6515 11.5983C15.3547 12.3016 15.7498 13.2554 15.7498 14.25C15.7498 15.2446 15.3547 16.1984 14.6515 16.9017C13.9482 17.6049 12.9944 18 11.9998 18C11.0053 18 10.0514 17.6049 9.34818 16.9017C8.64492 16.1984 8.24983 15.2446 8.24983 14.25Z"
        fill={color}
      />
    </svg>
  );
};
