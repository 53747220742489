import { Box, Table, TapArea, Text, useColors } from "@prodoctivity/design-system";
import { Fragment, FunctionComponent } from "react";

import { useLocation } from "react-router-dom";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { IconsEssentialsTableSort } from "../../svg/IconsEssentialsTableSort";
import { IconsEssentialsTableSortAsc } from "../../svg/IconsEssentialsTableSortAsc";
import { IconsEssentialsTableSortDesc } from "../../svg/IconsEssentialsTableSortDesc";
import { IndicatorsDocumentTypeIconSvg } from "../../svg/IndicatorsDocumentTypeIconSvg";
import { useResultOfFilteredFields } from "./hooks";

type Props = {
  breakpoint: "small" | "medium" | "large" | "hd";
};

export const ResultsTableHeader: FunctionComponent<Props> = ({ breakpoint }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { changeSort, sortedColumn, sortDirection } = useResultOfFilteredFields(params);
  const tabletBreakpoint = breakpoint === "medium";

  function getSortIcon(headerColumn: string): JSX.Element | null {
    if (headerColumn === "") {
      return null;
    }
    let conditionalSortIcon;
    if (sortedColumn !== headerColumn) {
      conditionalSortIcon = (
        <IconsEssentialsTableSort
          color={sortedColumn === headerColumn ? colors.black600 : colors.neutral900}
        />
      );
    } else if (sortDirection === "asc" && sortedColumn === headerColumn) {
      conditionalSortIcon = (
        <IconsEssentialsTableSortAsc
          color={sortedColumn === headerColumn ? colors.black600 : colors.neutral900}
        />
      );
    } else if (sortDirection === "desc" && sortedColumn === headerColumn) {
      conditionalSortIcon = (
        <IconsEssentialsTableSortDesc
          color={sortedColumn === headerColumn ? colors.black600 : colors.neutral900}
        />
      );
    }

    return (
      <Box
        marginStart={2}
        width={40}
        height={40}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {conditionalSortIcon}
      </Box>
    );
  }

  const headerColumnTitles = [
    resources.documentTypes.documentType,
    resources.name,
    resources.createdBy,
    resources.date,
    "",
  ];

  return (
    <Table.Header>
      <Table.Row>
        {headerColumnTitles.map((title, idx) => {
          return (
            <Fragment key={title || `${idx}`}>
              {(!tabletBreakpoint || title !== resources.createdBy) && (
                <Table.HeaderCell>
                  <Box display="flex" alignItems="center">
                    {title === resources.type ? (
                      <Box display="flex" justifyContent="center" alignItems="center" marginEnd={1}>
                        <IndicatorsDocumentTypeIconSvg
                          color={sortedColumn === title ? colors.black600 : undefined}
                        />
                      </Box>
                    ) : null}

                    <Text
                      color={sortedColumn === title ? colors.black600 : colors.neutral900}
                      size="200"
                      weight={sortedColumn === title ? "bold" : undefined}
                    >
                      {title.toLocaleUpperCase()}
                    </Text>
                    <Box
                      width={40}
                      height={40}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <TapArea
                        onTap={() => {
                          changeSort(title, undefined);
                        }}
                      >
                        {getSortIcon(title)}
                      </TapArea>
                    </Box>
                  </Box>
                </Table.HeaderCell>
              )}
            </Fragment>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};
