import { Box } from "./Box";
import { ColorConfig } from "../utils";
import { Text } from "./Text";
import { useColors } from "./ColorSchemeProvider";

type BadgeProps = {
  text: string;
  color: ColorConfig;
  textColor?: ColorConfig;
};

export function Badge({ text, color, textColor }: BadgeProps) {
  const { colors } = useColors();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      color={color}
      borderRadius={6}
      paddingX={2}
      width={"fit-content"}
    >
      <Text weight="bold" size="200" color={textColor || colors.white}>
        {text}
      </Text>
    </Box>
  );
}
