import { Box, Text } from "@prodoctivity/design-system";

import type { UserCreatedLogEntry } from "@prodoctivity/types";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  id: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  entry: UserCreatedLogEntry;
};

export const UserCreatedEntry: FunctionComponent<Props> = ({ entry, fullWidth }) => {
  const { resources } = useAppTranslation();

  if (fullWidth) {
    return (
      <Box display="flex" direction={fullWidth ? "row" : "column"} flex="grow">
        {fullWidth ? (
          <Box marginEnd={6}>
            <PublicProfile username={entry.username} onlyImage={true} sizeImage={48} />
          </Box>
        ) : null}

        <Box display="flex" direction="column">
          <Text inline={true} size="200">
            <Box display="flex" direction="column">
              <PublicProfile username={entry.username} onlyImage={false} size="300" />{" "}
              <Text inline={true} size="200">
                {resources.activities.userWasCreated
                  .replace("{firstName}", entry.firstName)
                  .replace("{lastName}", entry.lastName)}
              </Text>
            </Box>
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" wrap={true} direction="row" gap={1}>
      <Text inline={true} size="200">
        {resources.activities.userWasCreated
          .replace("{firstName}", entry.firstName)
          .replace("{lastName}", entry.lastName)}
      </Text>
    </Box>
  );
};
