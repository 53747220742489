import { ServicesContext } from "../context";
import { useContext } from "react";

export function useServices() {
  const ctx = useContext(ServicesContext);
  if (ctx.user && ctx.user.expires * 1000 < Date.now()) {
    ctx.logout();
    return {
      ...ctx,
      user: undefined,
    };
  }

  return ctx;
}
