import type {
  HttpExecuteDataLinkRequest,
  ListableTemplate,
  TemplateVersionId,
} from "@prodoctivity/types";
import { useCallback, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { IFormValues } from "@prodoctivity/prodoctivity-form-v5/src/components/Wizard/_types";
import { RequestOrigin, createUuid } from "@prodoctivity/shared";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { isProduction } from "../../config";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";

export const useCombineGenerationWizard = () => {
  const { combinedTemplateId } = useParams();
  const { search } = useLocation();

  const { getCombinedTemplate } = useServices();
  const params = new URLSearchParams(search);

  const stateId = params.get("stateId");

  const fetchCombinedTemplate = useCallback(async () => {
    if (combinedTemplateId) {
      return getCombinedTemplate(combinedTemplateId);
    }
    return Promise.resolve({
      id: undefined,
      combinedTemplate: undefined,
    });
  }, [getCombinedTemplate, combinedTemplateId]);

  const { data: combinedTemplateResponse, isLoading } = useOrganizationQuery(
    `/combined-templates/${combinedTemplateId}`,
    fetchCombinedTemplate,
    {
      refetchOnMount: true,
    }
  );

  return {
    combinedTemplateId,
    combinedTemplateResponse,
    isLoading,
    stateId,
  };
};

export type GenerationWizardProps = {
  combinedTemplateId: TemplateVersionId;
  stateId: string;
  context: ParametersObject;
};

type State = {
  step: "form" | "summary" | "document-viewer";
  formValues?: IFormValues;
  errors?: Array<{ message: string }>;
};

export const useGenerationWizardWithPayload = ({
  combinedTemplateId,
  stateId,
  context,
}: GenerationWizardProps) => {
  const {
    getCombinedTemplateDefinition,
    generateDocumentsSync,
    executeDataLink,
    saveTemplateContextState,
    deleteTemplateContextState,
    getTemplateContextSampleRequest,
    getNextSequenceValue,
  } = useServices();
  const { t } = useTranslation();
  const { moment } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const updateState = useCallback(
    (data: ParametersObject) => {
      return saveTemplateContextState(stateId, data);
    },
    [saveTemplateContextState, stateId]
  );
  const { mutate: updateStateMutate } = useMutation({
    mutationFn: updateState,
  });

  const i18n = useCallback(
    (key: string) => {
      const value = t(key);
      if (!isProduction && typeof value === "undefined") {
        console.error(`Key "${key}" not defined in i18n resource files`);
        return key;
      }

      return value;
    },
    [t]
  );
  const [state, setState] = useState<State>({
    step: "form",
    formValues: {
      formValues: context as any,
      groupValues: {},
      pinnedElements: [],
    },
  });
  const fetchCombinedDefinitionInfo = useCallback(async () => {
    if (!combinedTemplateId) {
      organizationNavigate("/");
      return Promise.resolve(undefined);
    }
    return await getCombinedTemplateDefinition(combinedTemplateId);
  }, [combinedTemplateId, getCombinedTemplateDefinition, organizationNavigate]);

  const { data: combinedTemplateDefinition } = useOrganizationQuery(
    `/combined-template/${combinedTemplateId}/templates-merged-definition`,
    fetchCombinedDefinitionInfo
  );

  const dataToSaveRef = useRef<ParametersObject | undefined>();
  const saveTimerRef = useRef<number | undefined>();

  const onFormValuesChange = useCallback(
    (values: IFormValues) => {
      setState((curr) => ({ ...curr, formValues: values }));

      // If data changes, set it up to be saved
      dataToSaveRef.current = values.formValues as any;

      if (saveTimerRef.current) {
        clearTimeout(saveTimerRef.current);
      }

      saveTimerRef.current = window.setTimeout(() => {
        if (dataToSaveRef.current) {
          updateStateMutate(dataToSaveRef.current);
          dataToSaveRef.current = undefined; // Clear saved data after saving
        }
      }, 5000);

      // Cleanup on unmount
      return () => {
        if (saveTimerRef.current) {
          clearTimeout(saveTimerRef.current);
        }
      };
    },
    [updateStateMutate]
  );

  const execDataLink = useCallback(
    (
      dataLinkId: string,
      dataLinkConfigVersionId: string,
      inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
    ) => {
      return executeDataLink(dataLinkId, dataLinkConfigVersionId, inputParameters);
    },
    [executeDataLink]
  );

  const onFinish = useCallback(() => {
    return deleteTemplateContextState(stateId);
  }, [deleteTemplateContextState, stateId]);

  const {
    data,
    mutate: generateDocumentMutation,
    isLoading: isMutating,
  } = useMutation(generateDocumentsSync, {
    onSuccess: useCallback(() => {
      setState((curr) => ({ ...curr, step: "document-viewer" }));
    }, []),
    onError: useCallback((ex: { response: { data: { errors: Array<{ message: string }> } } }) => {
      if (ex.response.data.errors.length > 0) {
        setState((curr) => ({
          ...curr,
          step: "summary",
          errors: ex.response?.data?.errors,
        }));
      }
    }, []),
  });

  const documentKeys = useMemo(() => {
    if (!combinedTemplateDefinition) return [];

    return combinedTemplateDefinition.templateVersionList.map((template) => ({
      templateKey: template.templateVersionId,
      documentKey: createUuid(),
      distributions: [],
      plugins: [],
    }));
  }, [combinedTemplateDefinition]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const generateDocument = useCallback(
    async (
      formValues?: any,
      isPreview = false
    ): Promise<
      | {
          success: boolean;
          blob?: Blob;
          documentId: string | undefined;
          documentVersionId: string | undefined;
        }
      | { success: false; errors: any[] }
    > => {
      if (!combinedTemplateId || !formValues || !combinedTemplateDefinition) {
        return new Promise<{
          success: boolean;
          blob?: Blob;
          documentId: string | undefined;
          documentVersionId: string | undefined;
        }>((resolve) =>
          resolve({
            success: false,
            blob: new Blob(),
            documentId: undefined,
            documentVersionId: undefined,
          })
        );
      }
      if (combinedTemplateDefinition.mergedContextDefinition.fields && !isPreview) {
        for (const field of combinedTemplateDefinition.mergedContextDefinition.fields) {
          const element = field.properties;

          const isSequence =
            element.dataType === "Alphanumeric" &&
            (element.inputType === "Default" || element.inputType === "TextBox");

          if (isSequence && element.sequenceId) {
            const requestOrigin: RequestOrigin = {
              origin: "app",
            };
            const sequenceValue = await getNextSequenceValue(requestOrigin, element.sequenceId);

            if (sequenceValue) {
              formValues[field.name] = sequenceValue;
            }
          }
        }
      }
      setState((curr) => ({ ...curr, errors: [] }));
      generateDocumentMutation({
        isPreview: isPreview,
        distributions: [],
        templates: combinedTemplateDefinition.templateVersionList.map((template) => ({
          key: template.templateVersionId,
          templateVersionId: template.templateVersionId,
        })),
        contexts: [
          {
            key: combinedTemplateId,
            data: formValues,
            documents: documentKeys,
          },
        ],
      });
      return new Promise<{
        success: boolean;
        blob?: Blob;
        documentId: string | undefined;
        documentVersionId: string | undefined;
      }>((resolve) =>
        resolve({
          success: true,
          blob: new Blob(),
          documentId: undefined,
          documentVersionId: undefined,
        })
      );
    },
    [
      combinedTemplateDefinition,
      combinedTemplateId,
      documentKeys,
      generateDocumentMutation,
      getNextSequenceValue,
    ]
  );

  const handleDismiss = useCallback(
    (index: number) => {
      if (state.errors && state.errors[index] !== undefined) {
        const leftErrors = [...state.errors];
        setState({ ...state, errors: leftErrors.splice(index, 1) });
      }
    },
    [state]
  );

  const onCancel = useCallback(() => {
    organizationNavigate(organizationLinkTemplates.home());
  }, [organizationNavigate]);

  const getTemplateContextSample = useCallback(async () => {
    if (!combinedTemplateDefinition) {
      return {};
    }

    const templateVersionIdList = Array.from(
      new Set<string>(
        combinedTemplateDefinition.templateVersionList.map((version) => version.templateVersionId)
      )
    );

    const response = await getTemplateContextSampleRequest(templateVersionIdList);
    return response.requestSample.contexts[0].data;
  }, [combinedTemplateDefinition, getTemplateContextSampleRequest]);

  return {
    i18n,
    moment,
    documents: data ? data.docs : undefined,
    isMutating,
    combinedTemplateDefinition,
    state,
    organizationNavigate,
    updateState,
    execDataLink,
    onFinish,
    onCancel,
    onFormValuesChange,
    documentKeys,
    generateDocument,
    handleDismiss,
    getTemplateContextSample,
  };
};

export const useCombinedTemplateDetails = () => {
  const { combinedTemplateId } = useParams();

  const [showSample, setShowSample] = useState<boolean>(false);

  const {
    baseUrl,
    getCombinedTemplate,
    fetchTemplateListWithoutPagination,
    getTemplateContextSampleRequest,
  } = useServices();
  const organizationNavigate = useOrganizationNavigate();

  const fetchCombinedTemplate = useCallback(async () => {
    if (combinedTemplateId) {
      return getCombinedTemplate(combinedTemplateId);
    }
    return Promise.resolve({
      id: undefined,
      combinedTemplate: undefined,
    });
  }, [getCombinedTemplate, combinedTemplateId]);

  const { data: combinedTemplateResponse, isLoading } = useOrganizationQuery(
    `/combined-templates/${combinedTemplateId}`,
    fetchCombinedTemplate,
    {
      refetchOnMount: true,
    }
  );

  const fetchTemplateList = useCallback(() => {
    if (combinedTemplateResponse?.id !== undefined) {
      return fetchTemplateListWithoutPagination(
        combinedTemplateResponse.combinedTemplate.documentGroupId
      );
    }
    return {
      templates: [],
    };
  }, [fetchTemplateListWithoutPagination, combinedTemplateResponse]);

  const { data: templateListResponse } = useOrganizationQuery(
    `/templates/all?documentGroupId=${combinedTemplateResponse?.combinedTemplate?.documentGroupId}`,
    fetchTemplateList
  );

  const getSample = useCallback(async () => {
    if (combinedTemplateResponse?.combinedTemplate === undefined || !templateListResponse) {
      return {
        requestSample: undefined,
        contextDefinition: undefined,
      };
    }
    const templateVersionIds = templateListResponse.templates
      .filter((t) => combinedTemplateResponse.combinedTemplate.templates.includes(t.templateId))
      .map((t) => t.templateVersionId);
    return await getTemplateContextSampleRequest(templateVersionIds);
  }, [combinedTemplateResponse, getTemplateContextSampleRequest, templateListResponse]);

  const { data: sample, isLoading: sampleLoading } = useOrganizationQuery(
    `template-version-details/${(
      combinedTemplateResponse?.combinedTemplate?.templates || []
    ).join()}/sample-request`,
    getSample,
    {
      enabled: !(combinedTemplateResponse?.combinedTemplate === undefined || !templateListResponse),
    }
  );

  const handleButtonClickEdit = useCallback(() => {
    if (combinedTemplateId) {
      organizationNavigate(organizationLinkTemplates.editCombinedTemplates(combinedTemplateId));
    }
  }, [combinedTemplateId, organizationNavigate]);

  const handleButtonClickGenerate = useCallback(() => {
    if (combinedTemplateId) {
      organizationNavigate(
        organizationLinkTemplates.generateCombinedTemplate(combinedTemplateId, undefined)
      );
    }
  }, [combinedTemplateId, organizationNavigate]);

  return {
    baseUrl,
    combinedTemplateId,
    combinedTemplate: combinedTemplateResponse?.combinedTemplate,
    isLoading: isLoading || !combinedTemplateResponse,
    templates: templateListResponse?.templates || [],
    sample,
    sampleLoading,
    showSample,
    setShowSample,
    handleButtonClickEdit,
    handleButtonClickGenerate,
  };
};

export const useTemplateRow = (templateId: string, templates: ListableTemplate[]) => {
  const { user, baseUrl } = useServices();
  const organizationNavigate = useOrganizationNavigate();
  const [isActive, setActive] = useState<boolean>(false);

  const currentTemplate = useMemo(() => {
    return templates.find((t) => t.templateId === templateId);
  }, [templates, templateId]);

  const goToTemplateDetails = useCallback(() => {
    if (currentTemplate) {
      organizationNavigate(
        organizationLinkTemplates.templateVersionDetailPage(currentTemplate.templateVersionId)
      );
    }
  }, [currentTemplate, organizationNavigate]);

  const templateVersionUrl = useMemo(() => {
    if (!user || !user.token || !currentTemplate) {
      return undefined;
    }

    return `${baseUrl}/app/template-versions/${
      currentTemplate.templateVersionId
    }?returnAsPdf=true&token=${encodeURIComponent(user.token)}`;
  }, [user, baseUrl, currentTemplate]);

  return {
    currentTemplate,
    templateVersionUrl,
    isActive,
    setActive,
    goToTemplateDetails,
  };
};

type CombinedDocumentViewerProps = {
  combinedTemplateId?: string;
  documents?: Record<string, Blob>;
  templateVersionList: Array<{ name: string; templateId: string; templateVersionId: string }>;
  documentKeys: {
    templateKey: string;
    documentKey: string;
    distributions: never[];
    plugins: never[];
  }[];
};
export const useCombinedDocumentViewer = ({
  combinedTemplateId,
  documents,
  templateVersionList,
  documentKeys,
}: CombinedDocumentViewerProps) => {
  const { resources } = useAppTranslation();
  const [currentTemplate, setCurrent] = useState<string>(templateVersionList[0].templateVersionId);
  const [showSidePanel, setShowPanel] = useState<boolean>(true);

  const document = useMemo((): Array<Blob> => {
    const documentKeyInfo = documentKeys.find((d) => d.templateKey === currentTemplate);
    const documentKey = `${combinedTemplateId}-${documentKeyInfo?.documentKey}`;
    if (documents && !!documents[documentKey]) {
      return [documents[documentKey]];
    }
    return [];
  }, [combinedTemplateId, currentTemplate, documentKeys, documents]);

  return {
    resources,
    currentTemplate,
    setCurrent,
    showSidePanel,
    setShowPanel,
    document,
  };
};
