import { FlexProps, Flex as GFlex } from "gestalt";
import { ReactNode } from "react";
import { Box } from "../Box";

type CenterProps = {
  children?: ReactNode;
  direction?: FlexProps["direction"];
};
export function Center({ children, direction = "column" }: CenterProps) {
  return (
    <Box
      display="flex"
      direction={direction}
      children={children}
      alignItems="center"
      justifyContent="center"
      flex="grow"
    />
  );
}

type GapProps = {
  children?: ReactNode;
  direction?: FlexProps["direction"];
  gap?: FlexProps["gap"];
};

export function Gap({ children, gap = 3, direction = "column" }: GapProps) {
  return <GFlex gap={gap} children={children} direction={direction} />;
}

export const Flex = GFlex;
