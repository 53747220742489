import {
  Box,
  ComboBox,
  ContextStateUpdater,
  Divider,
  DocumentViewer,
  DocumentViewerEvents,
  FormControllerProvider,
  Heading,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
  usePubSub,
  ResponsiveContainer,
  CustomComboBox,
  modalZIndex,
} from "@prodoctivity/design-system";
import { AudioVideoPlayer } from "@prodoctivity/design-system/components/DocumentViewer/AudioVideoPlayer";
import { IndexingForm } from "@prodoctivity/prodoctivity-form-v5/src/components";
import type { MimeType, ParametersObject } from "@prodoctivity/shared/src/index-types";
import type {
  DocumentTypeInfo,
  DocumentTypeResume,
  HttpExecuteDataLinkRequest,
  HttpExecuteDataLinkResponse,
} from "@prodoctivity/types";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import {
  LookupDocument,
  LookupDocumentButton,
  StateProps,
} from "../../../components/LookupDocument/LookupDocument";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { TemplateCardSkeleton } from "../../../loading";
import { XIconSvg } from "../../../svg/XIconSvg";
import { usePageHeight } from "../DashboardPageWrapper";
import { useIndexingFormWrapper } from "./hooks";
type Props = {
  documentTypes: Array<DocumentTypeResume>;
  documentTypeSelected?: DocumentTypeInfo;
  files: Array<{
    file: File;
    type: MimeType | undefined;
  }>;
  mimeType: MimeType;
  disabled: boolean;
  loadingForm: boolean;
  onFormValuesChange: (context: ParametersObject) => void;
  onDocumentTypeSelect: (documentType: DocumentTypeResume) => void;
  executeDataLink: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  setDocumentWithSameIdentifier: (documentVersionId?: string) => void;
  context: ParametersObject;
  wordToPdfHookRef?: {
    pdfDataUri: string | undefined;
    setWordDataUri: (wordDataUri?: string | undefined) => void;
  };
  documentLoadingState: StateProps;
  extractedContext: ParametersObject | undefined;
  setDocumentSearchOpen: (n: boolean) => void;
  isDocumentSearchOpen: boolean;
  setDocumentLoadingState: (n: StateProps) => void;
  disableDocumentTypesFromTaskInstructions: boolean | undefined;
  onFilesChange: (files: Array<string | Blob>) => void;
};
export const Indexing: FunctionComponent<Props> = ({
  documentTypes: allDocumentTypes,
  documentTypeSelected,
  files,
  mimeType,
  disabled,
  loadingForm,
  onDocumentTypeSelect,
  executeDataLink,
  onFormValuesChange,
  setDocumentWithSameIdentifier,
  context,
  wordToPdfHookRef,
  documentLoadingState,
  setDocumentSearchOpen,
  isDocumentSearchOpen,
  setDocumentLoadingState,
  extractedContext,
  disableDocumentTypesFromTaskInstructions,
  onFilesChange,
}) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  const documentTypes = useMemo(() => {
    return allDocumentTypes.filter((documentType) =>
      documentType.acceptedMimeTypeList.includes(mimeType)
    );
  }, [allDocumentTypes, mimeType]);
  const { breakpoint } = useDesignBreakpoint();
  const isSmallOrMediumBreakpoint = useMemo(
    () => breakpoint === "medium" || breakpoint === "small",
    [breakpoint]
  );
  const isMobile = useMemo(() => breakpoint === "small", [breakpoint]);

  const fileBinaries = useMemo(() => files.map((f) => f.file), [files]);
  const onSelect = useCallback(
    (documentTypeVersionId: string) => {
      const index = documentTypes.findIndex(
        (d) => d.documentTypeVersionId === documentTypeVersionId
      );
      if (index >= 0) {
        onDocumentTypeSelect(documentTypes[index]);
      }
    },
    [documentTypes, onDocumentTypeSelect]
  );

  const [isFullScreen, setIsFullScreen] = useState(false);

  const isVideoFormat = useMemo(
    () => fileBinaries.map((f) => f.type).includes("video/mp4"),
    [fileBinaries]
  );
  const isAudioFormat = useMemo(
    () => fileBinaries.map((f) => f.type).includes("audio/mpeg"),
    [fileBinaries]
  );

  usePageHeight(true);

  const { eventManager } = usePubSub<DocumentViewerEvents>();

  const documentViewerNode = useMemo(() => {
    return (
      <DocumentViewer
        readOnly={false}
        height="100%"
        src={fileBinaries}
        mimeType={mimeType}
        strategy="normal"
        resources={resources}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        wordToPdfHookRef={wordToPdfHookRef}
        eventManager={eventManager}
        moment={moment}
        onFilesChange={onFilesChange}
      />
    );
  }, [
    eventManager,
    fileBinaries,
    isFullScreen,
    mimeType,
    moment,
    resources,
    wordToPdfHookRef,
    onFilesChange,
  ]);

  const audioFormat = isAudioFormat ? "audio/mpeg" : undefined;

  return (
    <Box display="flex" direction="column" flex="grow">
      <Box
        height={"100%"}
        display="flex"
        direction={isSmallOrMediumBreakpoint ? "column" : "row"}
        dangerouslySetInlineStyle={{
          __style: isMobile ? { flexDirection: "column-reverse" } : {},
        }}
        minWidth={360}
        flex="grow"
      >
        <Box
          width={isSmallOrMediumBreakpoint ? "99%" : 430}
          display="flex"
          direction="column"
          gap={2}
        >
          {!isMobile ? (
            <Box
              paddingX={3}
              marginTop={isMobile ? 4 : undefined}
              marginBottom={1}
              height={"fit-content"}
              display="flex"
              direction="column"
            >
              <Box display="flex">
                <Heading color={colors.black600} size="400">
                  {resources.documentTypes.documentType}:
                </Heading>
              </Box>
              <Box marginTop={1}>
                <ComboBox
                  id="documentTypeVersionId"
                  label=""
                  disabled={
                    documentTypes.length <= 0 ||
                    loadingForm ||
                    disabled ||
                    disableDocumentTypesFromTaskInstructions
                  }
                  onSelect={(e) => onSelect(e.item.value)}
                  size="md"
                  zIndex={modalZIndex}
                  placeholder={documentTypeSelected?.name}
                  selectedOption={
                    documentTypeSelected && documentTypeSelected.documentTypeVersionId
                      ? {
                          label: documentTypeSelected.name,
                          subtext: undefined,
                          value: documentTypeSelected.documentTypeVersionId,
                        }
                      : undefined
                  }
                  options={documentTypes.map((d) => {
                    return {
                      label: d.name,
                      subtext: undefined,
                      value: d.documentTypeVersionId || "",
                    };
                  })}
                  accessibilityClearButtonLabel=""
                  noResultText=""
                  errorMessage={!documentTypeSelected ? resources.required : undefined}
                />
              </Box>
            </Box>
          ) : null}
          <ResponsiveContainer
            resources={resources}
            isMobile={isMobile}
            displaySheetButtonText={resources.showForm}
            displayButtonType="button"
            headingText={resources.documentTypes.documentType}
            customFooter={undefined}
            disableSheetButton={false}
            externalOpenSetter={undefined}
            externalOpenState={false}
            showDismissButton={false}
            size={undefined}
          >
            {!isMobile ? (
              <>
                <Box margin={1} />
                <Divider direction="horizontal" />
              </>
            ) : (
              <Box marginBottom={4}>
                <CustomComboBox
                  resources={resources}
                  direction={"up"}
                  onClear={() => onSelect("")}
                  onSelect={onSelect}
                  placeholder={documentTypeSelected?.name}
                  options={documentTypes.map((d) => {
                    return {
                      label: d.name,
                      subtext: undefined,
                      value: d.documentTypeVersionId || "",
                    };
                  })}
                  selectedValue={
                    documentTypeSelected && documentTypeSelected.documentTypeVersionId
                      ? {
                          label: documentTypeSelected.name,
                          subtext: undefined,
                          value: documentTypeSelected.documentTypeVersionId,
                        }
                      : undefined
                  }
                ></CustomComboBox>
              </Box>
            )}
            <Box
              marginTop={2}
              overflow="auto"
              width={"100%"}
              position="relative"
              display="flex"
              direction="column"
              flex="grow"
            >
              {documentTypeSelected && <LookupDocumentButton onchange={setDocumentSearchOpen} />}
              {(loadingForm || documentLoadingState.isLoading) && <TemplateCardSkeleton />}
              {!loadingForm && documentTypeSelected && !documentLoadingState.isLoading && (
                <FormControllerProvider
                  contextDefinition={documentTypeSelected.contextDefinition}
                  wizardDefinition={documentTypeSelected.wizardDefinition}
                  dataLinkMappings={documentTypeSelected.dataLinkMappings}
                  moment={moment}
                  executeDataLink={executeDataLink}
                  initialContext={extractedContext || context}
                >
                  <IndexingFormWrapper
                    documentTypeSelected={documentTypeSelected}
                    disabled={disabled}
                    executeDataLink={executeDataLink}
                    setDocumentWithSameIdentifier={setDocumentWithSameIdentifier}
                    onFormValuesChange={onFormValuesChange}
                  />
                </FormControllerProvider>
              )}
            </Box>
          </ResponsiveContainer>
        </Box>
        <Box
          column={isSmallOrMediumBreakpoint ? 12 : 9}
          height={isSmallOrMediumBreakpoint ? "100%" : undefined}
        >
          <LookupDocument
            onChange={setDocumentLoadingState}
            isDocumentSearchOpen={isDocumentSearchOpen}
            setDocumentSearchOpen={setDocumentSearchOpen}
          />

          {isVideoFormat || isAudioFormat ? (
            <AudioVideoPlayer src={fileBinaries} format={audioFormat} strategy="normal" />
          ) : breakpoint === "small" ? (
            <Box width={"100%"} height={"100%"}>
              {documentViewerNode}
            </Box>
          ) : (
            documentViewerNode
          )}
        </Box>
      </Box>
    </Box>
  );
};

type IndexingFormProps = {
  documentTypeSelected: DocumentTypeInfo;
  disabled: boolean;
  setDocumentWithSameIdentifier: Props["setDocumentWithSameIdentifier"];
  executeDataLink: Props["executeDataLink"];
  onFormValuesChange(context: ParametersObject): void;
};
const IndexingFormWrapper: FunctionComponent<IndexingFormProps> = ({
  documentTypeSelected,
  disabled,
  setDocumentWithSameIdentifier,
  executeDataLink,
  onFormValuesChange,
}) => {
  const { colors } = useColors();

  const {
    i18n,
    resources,
    moment,
    isLoading,
    identifierConfig,
    showUseData,
    setShowUseData,
    onClickUseDocumentData,
  } = useIndexingFormWrapper({
    documentTypeSelected,
    setDocumentWithSameIdentifier,
  });

  return (
    <Box>
      {showUseData && identifierConfig && (
        <Box>
          <Box
            color={colors.neutral300}
            paddingY={2}
            paddingLeft={4}
            paddingRight={3}
            display="flex"
            justifyContent="between"
          >
            <TapArea onTap={onClickUseDocumentData}>
              <Box display="flex">
                <Text color={colors.secondary}>
                  {resources.formDesigner.useDocumentData.replace(
                    "{{identifierConfig}}",
                    identifierConfig
                  )}
                </Text>
              </Box>
            </TapArea>
            <Box height={15} width={15}>
              <TapArea onTap={() => setShowUseData(false)}>
                <XIconSvg height={15} width={15} />
              </TapArea>
            </Box>
          </Box>
          <Divider />
        </Box>
      )}
      <FormWatcher onChange={onFormValuesChange} />
      <IndexingForm
        readonly={disabled || isLoading}
        contextDefinition={documentTypeSelected.contextDefinition}
        wizardDefinition={documentTypeSelected.wizardDefinition}
        dataLinkMappings={documentTypeSelected.dataLinkMappings}
        executeDataLink={executeDataLink}
        i18n={i18n}
        moment={moment}
        resources={resources}
      />
    </Box>
  );
};

const FormWatcher: FunctionComponent<{
  onChange(context: ParametersObject): void;
}> = ({ onChange }) => {
  const handler = useCallback(
    (arg: { data: ParametersObject }) => {
      onChange(arg.data);
    },
    [onChange]
  );

  return <ContextStateUpdater onUpdate={handler} />;
};
