import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const ChevronRightIcon: FunctionComponent<Props> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 25L23 20L18 15"
        stroke="#00185E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
