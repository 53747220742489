import { Box, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useContext, useEffect } from "react";

import emptyActivitySvg from "../../assets/activity-empty.svg";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { DashboardItemContext } from "../../pages/Dashboard/DashboardConfigColumnElement";
import { OrganizationNavLink } from "../OrganizationLink";
import { Activity } from "./Activity";
import { RecentActivitiesSkeleton } from "./RecentActivitiesSkeleton";

const RecentActivities: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();
  const { getActionLogs } = useServices();
  const dashboardItemContext = useContext(DashboardItemContext);
  const getActions = useCallback(() => {
    return getActionLogs({
      pageNumber: 0,
      rowsPerPage: "15",
      listEntryTypes: [],
      entryTypes: [],
    });
  }, [getActionLogs]);
  const { data, isLoading, isRefetching, refetch, remove } = useOrganizationQuery(
    ["action-logs", "recent", `${dashboardItemContext.isSidebarComponent}`],
    getActions,
    {
      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const eventEmitter = dashboardItemContext.eventEmitter;
    if (eventEmitter) {
      const handler = () => {
        remove();
        refetch();
      };
      eventEmitter.on("refresh", handler);
      return () => {
        eventEmitter.off("refresh", handler);
      };
    }
  }, [dashboardItemContext, refetch, remove]);

  const entries = isLoading || isRefetching ? [] : data?.entries || [];
  const loading = isLoading || isRefetching;

  return (
    <Box display="flex" direction="column" flex="grow" width={"100%"} padding={4}>
      {loading && <RecentActivitiesSkeleton />}
      {!loading && (
        <Box
          display="flex"
          direction="column"
          flex="grow"
          paddingX={2}
          paddingY={2}
          marginStart={2}
        >
          {!entries.length ? (
            <ActivityEmptyState />
          ) : (
            entries.map((action, index) => {
              return (
                <Box
                  display="flex"
                  key={`${action.entryType}-${action.createdAt}-${action.id}`}
                  direction="row"
                >
                  <Box
                    key={`${action.entryType}-${action.createdAt}`}
                    display="flex"
                    direction="column"
                    width={10}
                  >
                    <Box
                      marginTop={1}
                      color={colors.neutral700}
                      rounding="circle"
                      height={10}
                      width={10}
                      flex="shrink"
                    />
                    {index !== entries.length - 1 && (
                      <Box marginStart={1} width={1} color={colors.neutral700} flex="grow" />
                    )}
                  </Box>
                  <Box display="flex" direction="column">
                    <Box marginStart={2}>
                      <Text color={colors.subtle} size="100">
                        {moment(action.createdAt).fromNow()}
                      </Text>
                    </Box>
                    <Box padding={4}>
                      <Activity entry={action} />
                    </Box>
                  </Box>
                </Box>
              );
            })
          )}
          <Box marginBottom={4}>
            <OrganizationNavLink to="/activities">
              <Text size="200" color={colors.primary}>
                {resources.activities.viewAllEntries}
              </Text>
            </OrganizationNavLink>
          </Box>
          <Box height={1} width={1}></Box>
        </Box>
      )}
    </Box>
  );
};

const ActivityEmptyState = () => {
  const { colors } = useColors();
  return (
    <Box marginTop={10} display="flex" alignItems="center" direction="column">
      <img width={180} height={120} alt="empty activity" src={emptyActivitySvg} />
      <Box margin={5} />
      <Text weight="bold">Things are quiet over here...</Text>
      <Box height={5} />
      <Text color={colors.neutral900} align="center">
        No activity has been started yet. Be the first one!
      </Text>
    </Box>
  );
};

export { RecentActivities };
