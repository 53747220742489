import { MimeTypes, createUuid } from "@prodoctivity/shared";

/**
 *  type of represents a file from scanner
 */
export type ScannerImage = {
  /**
   *  the image in blob
   */
  blob: Blob;
  /**
   *  mime type of file
   */
  mimeType: string;
  /**
   * name of capture image
   */
  name: string;
};

/**
 *  decode image from base64 string value
 *
 * @param base64Image
 * @returns
 */
export const decodeBase64Image = (base64Image: string): ScannerImage => {
  const decodedImage = atob(base64Image);
  const length = decodedImage.length;
  const arrayBuffer = new ArrayBuffer(length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < length; i++) {
    uintArray[i] = decodedImage.charCodeAt(i);
  }

  return {
    blob: new Blob([uintArray], { type: "image/jpeg" }),
    mimeType: MimeTypes.ImageJpeg,
    name: createUuid(),
  };
};
