import { FunctionComponent, useEffect } from "react";

import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import { useFormController } from "./input";

export const ContextStateUpdater: FunctionComponent<{
  onUpdate(args: { data: ParametersObject; progress: number }): void;
}> = ({ onUpdate }) => {
  const controller = useFormController();

  useEffect(() => {
    const t = controller.subscribe("onchange", onUpdate);

    return () => {
      t.unsubscribe();
    };
  }, [controller, onUpdate]);

  return null;
};
