import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CircleCheckSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
  onClick,
}) => {
  return (
    <svg
      //stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.37273 0 0 5.37273 0 12C0 18.6273 5.37273 24 12 24C18.6273 24 24 18.6273 24 12C24 5.37273 18.6273 0 12 0ZM17.2015 9.97091C17.2972 9.86143 17.3701 9.7339 17.4159 9.59582C17.4617 9.45774 17.4794 9.31191 17.4679 9.16689C17.4565 9.02188 17.4162 8.88062 17.3494 8.75141C17.2825 8.62221 17.1905 8.50768 17.0788 8.41455C16.967 8.32143 16.8378 8.2516 16.6987 8.20916C16.5595 8.16672 16.4133 8.15254 16.2686 8.16745C16.1239 8.18236 15.9836 8.22605 15.8561 8.29596C15.7285 8.36588 15.6162 8.46059 15.5258 8.57455L10.8349 14.2025L8.40764 11.7742C8.20189 11.5755 7.92632 11.4655 7.64029 11.468C7.35426 11.4705 7.08064 11.5852 6.87838 11.7875C6.67612 11.9897 6.56139 12.2633 6.5589 12.5494C6.55642 12.8354 6.66637 13.111 6.86509 13.3167L10.1378 16.5895C10.245 16.6966 10.3733 16.7802 10.5146 16.835C10.6559 16.8898 10.8071 16.9145 10.9585 16.9076C11.1099 16.9007 11.2582 16.8624 11.3939 16.795C11.5297 16.7277 11.6499 16.6328 11.7469 16.5164L17.2015 9.97091Z"
        fill={color ? calculateColor(color) : "#008753"}
      />
    </svg>
  );
};
