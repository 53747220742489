import { useCallback, useMemo, useState } from "react";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../../link-templates";
import { useServices } from "../../../hooks/useServices";
import { useMutation } from "@tanstack/react-query";
import type { BundleConfig, HttpExecuteDataLinkRequest } from "@prodoctivity/types";
import { useParams } from "react-router-dom";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { validateContextPayload } from "@prodoctivity/shared";
import { useDesignBreakpoint, useFormController } from "@prodoctivity/design-system";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import { isProduction } from "../../../config";
import { useTranslation } from "react-i18next";
import { useLanguages } from "../../../hooks/useLanguages";
import { useSnackbar } from "react-simple-snackbar";

export const useBundleConfigList = () => {
  const { resources, moment } = useAppTranslation();
  const { languages: languageOptions } = useLanguages();
  const { breakpoint } = useDesignBreakpoint();
  const [language, setLanguage] = useState<ComboBoxItemType>({
    value: "all",
    label: resources.all,
  });
  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      { type: "url", name: resources.settings, url: "/settings" },
      {
        type: "url",
        name: resources.bundle.bundles,
        url: organizationLinkTemplates.settingsBundle(),
      },
    ];
  }, [resources]);

  const onSelectLanguage = useCallback(
    ({
      item,
    }: {
      event: React.SyntheticEvent<HTMLInputElement, Event>;
      item: ComboBoxItemType;
    }) => {
      setLanguage(item);
    },
    []
  );

  return {
    resources,
    moment,
    breakpoint,
    breadCrumbEntries,
    language,
    languageOptions,
    setLanguage,
    onSelectLanguage,
  };
};

export type BundleConfigListState = {
  displayForm: boolean;
};

export const useBundleView = () => {
  const [state, setState] = useState<BundleConfigListState>({ displayForm: false });
  const { identifier } = useParams();
  const { getBundleConfig, postApplyBundle } = useServices();
  const { resources, moment } = useAppTranslation();
  const [success] = useSnackbar({ style: { backgroundColor: "#008753" } });
  const [failure] = useSnackbar({ style: { backgroundColor: "#CC0000" } });

  const fetchGetBundleConfig = useCallback(async () => {
    if (!identifier) {
      return {
        bundleConfig: undefined,
      };
    }
    return await getBundleConfig(identifier as BundleConfig["identifier"]);
  }, [identifier, getBundleConfig]);

  const { data, isLoading, refetch } = useOrganizationQuery(
    `bundle-config/${identifier}`,
    fetchGetBundleConfig,
    {
      refetchOnMount: true,
    }
  );

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      { type: "url", name: resources.settings, url: "/settings" },
      {
        type: "url",
        name: resources.bundle.bundles,
        url: organizationLinkTemplates.settingsBundle(),
      },
      {
        type: "text",
        name: data?.bundleConfig?.name || resources.loading,
        ellipsis: data?.bundleConfig?.name || resources.loading,
      },
    ];
  }, [resources, data]);

  const { mutate: applyBundleMutation, isLoading: isMutating } = useMutation(postApplyBundle, {
    onSuccess: useCallback(() => {
      refetch();
      setState((prev) => ({
        ...prev,
        displayForm: false,
      }));
      success(resources.bundle.bundleBeingApplied);
    }, [refetch, resources.bundle.bundleBeingApplied, success]),
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      failure(
        error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : resources.errorOccurred
      );
    },
  });

  const cancelDisplayForm = useCallback(() => {
    setState((prev) => ({ ...prev, displayForm: false }));
  }, []);

  const validateApplyBundle = useCallback(
    (contextData: ParametersObject) => {
      if (!data || !data.bundleConfig) return;

      if (
        (!data.bundleConfig.lastVersionApplied &&
          !data.bundleConfig.inputContextDefinition &&
          !data.bundleConfig.inputWizardDefinition) ||
        (!data.bundleConfig.inputContextDefinition && !data.bundleConfig.inputWizardDefinition)
      ) {
        applyBundleMutation({ identifier: data.bundleConfig.identifier });
        return;
      }

      const validationResult = validateContextPayload(
        data.bundleConfig.inputContextDefinition || {
          fields: [],
          records: [],
        },
        data.bundleConfig.inputWizardDefinition || {
          defaultPageName: "",
          defaultSectionName: "",
          dependencies: [],
          inferredDependencies: [],
          pages: [],
        },
        contextData
      );
      if (!validationResult.success) {
        setState((prev) => ({
          ...prev,
          displayForm: true,
          toastMessage: prev.displayForm
            ? {
                type: "error",
                message: validationResult.errors[0].message,
              }
            : undefined,
        }));
        return;
      }
      applyBundleMutation({ identifier: data.bundleConfig.identifier, inputData: contextData });
    },
    [data, applyBundleMutation]
  );

  const allChanges: Record<
    string,
    { i18n: string; changeList: BundleConfig["changesList"] } | undefined
  > = useMemo(() => {
    if (!data || !data.bundleConfig) return {};
    return {
      "data-elements": {
        i18n: resources.dataDictionary.dataElements,
        changeList: data.bundleConfig.changesList.filter((c) => c.type === "data-element"),
      },
      "document-groups": {
        i18n: resources.documentGroups,
        changeList: data.bundleConfig.changesList.filter((c) => c.type === "document-group"),
      },
      template: {
        i18n: resources.templates,
        changeList: data.bundleConfig.changesList.filter((c) => c.type === "template"),
      },
      "document-type": {
        i18n: resources.documentTypes.documentTypes,
        changeList: data.bundleConfig.changesList.filter((c) => c.type === "document-type"),
      },
      "document-collection": {
        i18n: resources.documentCollection.documentCollections,
        changeList: data.bundleConfig.changesList.filter((c) => c.type === "document-collection"),
      },
    };
  }, [data, resources]);

  return {
    breadCrumbEntries,
    resources,
    moment,
    bundleConfig: data?.bundleConfig,
    isLoading,
    allChanges,
    state,
    setState,
    validateApplyBundle,
    cancelDisplayForm,
    isMutating,
    refetch,
  };
};

export const useBundleForm = (onApply: (context: ParametersObject) => void) => {
  const { resources, moment } = useAppTranslation();
  const { t } = useTranslation();
  const { executeDataLink } = useServices();
  const { context, validationResult } = useFormController();

  const onApplyClick = useCallback(() => {
    onApply(context);
  }, [context, onApply]);

  const i18n = useCallback(
    (key: string) => {
      const value = t(key);
      if (!isProduction && typeof value === "undefined") {
        console.error(`Key "${key}" not defined in i18n resource files`);
        return key;
      }

      return value;
    },
    [t]
  );

  const execDataLink = useCallback(
    (
      dataLinkId: string,
      dataLinkConfigVersionId: string,
      inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
    ) => {
      return executeDataLink(dataLinkId, dataLinkConfigVersionId, inputParameters);
    },
    [executeDataLink]
  );

  return {
    resources,
    moment,
    i18n,
    validationResult,
    onApplyClick,
    execDataLink,
  };
};
