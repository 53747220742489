import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";

import { useMemo } from "react";
import { useServices } from "./useServices";

export function useOrganizationQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  queryKey: string | string[],
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, QueryKey>, "queryKey" | "queryFn">
): UseQueryResult<TData, TError> {
  const { user } = useServices();
  const newOptions: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, QueryKey>,
    "queryKey" | "queryFn"
  > = {
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError() {
      if (result) {
        result.remove();
      }
    },
    ...(options ? options : {}),
  };

  const qkey = useMemo(() => {
    const k: string[] = [];
    if (user) {
      k.push(user.organizationId);
      k.push(user.username);
    } else {
      k.push("<no-org>", "<no-user>");
    }

    if (Array.isArray(queryKey)) {
      k.push(...queryKey);
    } else {
      k.push(queryKey);
    }

    return k;
  }, [queryKey, user]);

  const result = useQuery(qkey, queryFn, newOptions);

  return result;
}
