import { FunctionComponent } from "react";
import {
  Layer,
  Modal,
  Box,
  Text,
  Divider,
  Button,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import { useAppTranslation } from "../../hooks/useAppTranslation";

export const ViewerErrorModal: FunctionComponent<{
  show: boolean;
  onDismiss(): void;
  errorMessage: string;
}> = ({ show, onDismiss, errorMessage }) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  return (
    show && (
      <Layer zIndex={modalZIndex}>
        <Modal
          accessibilityModalLabel={resources.anErrorHasOccurred}
          heading={
            <Box>
              <Box marginBottom={3}>
                <Box marginBottom={4}>
                  <Text color={colors.error}>
                    <strong>{resources.anErrorHasOccurred}</strong>
                  </Text>
                </Box>
              </Box>
              <Divider />
            </Box>
          }
          size="md"
          onDismiss={onDismiss}
          footer={
            <>
              <Divider />
              <Box marginTop={3} display="flex" justifyContent="end">
                <Box borderRadius={6} borderStyle="sm">
                  <Button color={"gray"} onClick={onDismiss} text={resources.close} />
                </Box>
              </Box>
            </>
          }
        >
          <Box marginBottom={3}>
            <Text>{errorMessage}</Text>
          </Box>
        </Modal>
      </Layer>
    )
  );
};
