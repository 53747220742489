import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { BrowseDocumentsList } from "../components/BrowseDocuments/BrowseDocumentsList";
import { BrowseDocumentsFilter } from "../link-templates";

const browseDocumentsFilter$Schema = z
  .object({
    dateFrom: z.coerce.date({}).optional().catch(undefined),
    dateTo: z.coerce.date({}).optional().catch(undefined),
    documentTypeIdList: z
      .preprocess((arg) => {
        if (typeof arg === "string") {
          return arg.split(",");
        } else if (Array.isArray(arg)) {
          return arg;
        }
        return undefined;
      }, z.string().min(1).array())
      .optional()
      .catch([]),
    updatedBy: z.string().default("").optional().catch(undefined),
    pageNumber: z.coerce.number().nonnegative().catch(0),
    rowsPerPage: z.enum(["15", "30", "100", "500"]).catch("15"),
    sortField: z
      .enum(["documentType", "name", "updatedBy", "updatedAt"])
      .optional()
      .default("updatedAt")
      .catch("updatedAt"),
    sortDirection: z.enum(["ASC", "DESC"]).optional().default("DESC").catch("DESC"),
  })
  .strip();

function urlParamsToFilter(urlParams: Record<string, string | null>): BrowseDocumentsFilter {
  const filter = browseDocumentsFilter$Schema.safeParse(urlParams);

  if (!filter.success) {
    return {
      dateFrom: undefined,
      dateTo: undefined,
      documentTypeIdList: undefined,
      pageNumber: 0,
      rowsPerPage: "15",
      sortDirection: "ASC",
      sortField: undefined,
      updatedBy: undefined,
    };
  }

  const result: BrowseDocumentsFilter = {
    dateFrom: filter.data.dateFrom ? filter.data.dateFrom.getTime() : undefined,
    dateTo: filter.data.dateTo ? filter.data.dateTo.getTime() : undefined,
    documentTypeIdList: filter.data.documentTypeIdList,
    pageNumber: filter.data.pageNumber,
    rowsPerPage: filter.data.rowsPerPage,
    sortDirection: filter.data.sortDirection || "ASC",
    sortField: filter.data.sortField,
    updatedBy: filter.data.updatedBy,
  };

  return result;
}

const BrowseDocumentsPage: FunctionComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const filter = urlParamsToFilter({
    dateFrom: params.get("dateStart"),
    dateTo: params.get("dateEnd"),
    documentTypeIdList: params.get("documentTypeIdList"),
    pageNumber: params.get("pageNumber"),
    rowsPerPage: params.get("rowsPerPage"),
    sortDirection: params.get("sortDirection"),
    sortField: params.get("sortField"),
    updatedBy: params.get("updatedBy"),
  });

  return <BrowseDocumentsList filter={filter} />;
};

export default BrowseDocumentsPage;
