import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const BackSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 34,
  color,
  onClick,
}) => {
  const { colors } = useColors();
  const rawColor = calculateColor(color || colors.primary);
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 34`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_6943_103258)">
        <path
          d="M39.9999 16.3353C39.9999 15.2606 39.1244 14.3813 38.0543 14.3813H23.5018L30.4279 6.29166C31.1283 5.47097 31.031 4.23994 30.2139 3.53649C29.3968 2.83304 28.1711 2.93074 27.4707 3.75143L19.2217 13.3848C17.6652 15.0848 17.6847 17.6836 19.2606 19.4422L27.6847 28.6261C28.0738 29.0365 28.5991 29.2514 29.1244 29.2514C29.5913 29.2514 30.0777 29.0756 30.4473 28.7434C31.245 28.0204 31.2839 26.7698 30.5641 25.9882L23.5018 18.2894H38.0738C39.1438 18.2894 40.0193 17.4101 40.0193 16.3353"
          fill={rawColor}
        />
        <path
          d="M24.8638 34H3.83268C1.71206 34 0 32.2805 0 30.1506V3.84943C0 1.71954 1.71206 0 3.83268 0H18.7549C19.8249 0 20.7004 0.87931 20.7004 1.95402C20.7004 3.02874 19.8249 3.90805 18.7549 3.90805H3.83268L3.89105 30.1506L24.8638 30.092C25.9339 30.092 26.8093 30.9713 26.8093 32.046C26.8093 33.1207 25.9339 34 24.8638 34Z"
          fill={rawColor}
        />
        <path
          d="M8.87152 18.3291C10.0535 18.3291 11.0116 17.3668 11.0116 16.1797C11.0116 14.9926 10.0535 14.0303 8.87152 14.0303C7.68959 14.0303 6.73145 14.9926 6.73145 16.1797C6.73145 17.3668 7.68959 18.3291 8.87152 18.3291Z"
          fill={rawColor}
        />
        <path
          d="M8.87152 10.8252C10.0535 10.8252 11.0116 9.86289 11.0116 8.67579C11.0116 7.4887 10.0535 6.52637 8.87152 6.52637C7.68959 6.52637 6.73145 7.4887 6.73145 8.67579C6.73145 9.86289 7.68959 10.8252 8.87152 10.8252Z"
          fill={rawColor}
        />
        <path
          d="M8.87152 25.8321C10.0535 25.8321 11.0116 24.8697 11.0116 23.6826C11.0116 22.4955 10.0535 21.5332 8.87152 21.5332C7.68959 21.5332 6.73145 22.4955 6.73145 23.6826C6.73145 24.8697 7.68959 25.8321 8.87152 25.8321Z"
          fill={rawColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_6943_103258">
          <rect width="40" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
