import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import { SettingsPageProvider } from "../../context/SettingsPageProvider";

const SettingsPage: FunctionComponent = () => {
  return (
    <SettingsPageProvider>
      <Outlet />
    </SettingsPageProvider>
  );
};

export default SettingsPage;
