import {
  Box,
  ComboBoxItemType,
  PopoverComboboxOption,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { CustomAccordion } from "@prodoctivity/prodoctivity-form-v5/src/components/Dependencies/CustomAccordion";
import type {
  ExpressionDataInput,
  LogicalExpression,
  TemplateContextRecordHolder,
} from "@prodoctivity/shared/src/index-types";
import type { DocumentCollectionConfigValidation } from "@prodoctivity/types";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import { TreeItem } from "../../hooks";
import { FolderValidationRow } from "./FolderValidationRow";

export type FolderValidationListProps = {
  validationListLength: number;
  deleteFolderValidation: (index: number) => () => void;

  currIndex: number;
  validation: DocumentCollectionConfigValidation;
  onSingleValidationFolderChange: (
    index: number
  ) => (_name: string, expression: ExpressionDataInput) => void;
  tI18n: (k: string) => string;
  mergedContextDefinition: TemplateContextRecordHolder;
  expressionDataInput: (expression: LogicalExpression) => ExpressionDataInput;
  getConditionText: (dependencyConditions: ExpressionDataInput) => string | undefined;
  onFilterChange: (newFilter: string) => void;
  filter: string;
  isLoading: boolean;
  iconOptions: PopoverComboboxOption[];
  folderDocumentTypesOptions: ComboBoxItemType[];
  setConfigItem: (
    value: SetStateAction<
      | {
          originalItem: TreeItem;
          updatedItem: TreeItem;
        }
      | undefined
    >
  ) => void;
  setIsFolderChangesButtonDisabled: Dispatch<SetStateAction<boolean>>;
};

export const FolderValidation = ({
  validation,
  onSingleValidationFolderChange,
  tI18n,
  mergedContextDefinition,
  expressionDataInput,
  getConditionText,
  setConfigItem,
  onFilterChange,
  filter,
  isLoading,
  iconOptions,
  folderDocumentTypesOptions,
  setIsFolderChangesButtonDisabled,
  deleteFolderValidation,
  currIndex,
  validationListLength,
}: FolderValidationListProps) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const labelCondition = useMemo(() => {
    if (validation.name.trim() === "") {
      return resources.dataDictionary.noName;
    }
    if (
      validation.invalidExpressionConfig &&
      validation.invalidExpressionConfig.name.trim() === ""
    ) {
      return resources.errorMessageEmpty;
    }
    return validation.name;
  }, [
    resources.dataDictionary.noName,
    resources.errorMessageEmpty,
    validation.invalidExpressionConfig,
    validation.name,
  ]);

  const accordionColorCondition = useMemo(() => {
    const condition =
      labelCondition === resources.errorMessageEmpty ||
      labelCondition === resources.dataDictionary.noName;
    return condition;
  }, [labelCondition, resources.dataDictionary.noName, resources.errorMessageEmpty]);

  return (
    <Box
      flex="grow"
      borderColor={colors.black600}
      borderRadius={6}
      marginBottom={validationListLength === currIndex + 1 ? 0 : 4}
    >
      <CustomAccordion
        removeLabel={"remove"}
        label={<Text>{labelCondition}</Text>}
        currIndex={currIndex}
        i18n={tI18n}
        deleteAccordionHandler={deleteFolderValidation(currIndex)}
        openedColor={accordionColorCondition ? colors.error : colors.neutral700}
        disableToggle={accordionColorCondition}
      >
        <Box padding={4} color={colors.neutral300}>
          <FolderValidationRow
            currIndex={currIndex}
            expressionDataInput={expressionDataInput}
            getConditionText={getConditionText}
            mergedContextDefinition={mergedContextDefinition}
            onSingleValidationFolderChange={onSingleValidationFolderChange}
            tI18n={tI18n}
            validation={validation}
            setConfigItem={setConfigItem}
            filter={filter}
            onFilterChange={onFilterChange}
            iconOptions={iconOptions}
            isLoading={isLoading}
            folderDocumentTypesOptions={folderDocumentTypesOptions}
            setIsFolderChangesButtonDisabled={setIsFolderChangesButtonDisabled}
          />
        </Box>
      </CustomAccordion>
    </Box>
  );
};
