import { CheckboxProps, Checkbox as GestaltCheckbox } from "gestalt";
import { FunctionComponent, useCallback, useEffect, useState } from "react";

import { Box } from "./Box";
import { Grid } from "./layout";

export type { CheckboxProps };

export function Checkbox(
  props: CheckboxProps & {
    mode?: "default" | "unstyled" | "summary";
  }
) {
  const { mode: _mode, ...rest } = props;
  return <GestaltCheckbox {...rest} />;
}

export const checkBoxGroupGridTemplateColumns = ["repeat(auto-fill, minmax(250px, 1fr))"];

type CheckboxGroupProps = {
  id: string;
  name: string;
  options: Array<{ label: string; value: string }>;
  disabled?: boolean;
  value: string[];
  size?: CheckboxProps["size"];
  onChange?(value: string[]): void;
};

export const CheckboxGroup: FunctionComponent<CheckboxGroupProps> = ({
  id,
  name,
  options,
  disabled,
  value,
  size,
  onChange,
}) => {
  const [values, setValues] = useState<Set<string>>(new Set<string>());
  useEffect(() => {
    setValues(new Set<string>(value));
  }, [value]);
  const checkBoxGroupChanged = useCallback(
    (selectedValue: string, checked: boolean) => {
      const newSet = new Set(values);
      if (checked) {
        newSet.add(selectedValue);
        setValues(newSet);
      } else {
        newSet.delete(selectedValue);
        setValues(newSet);
      }
      if (onChange) {
        onChange(Array.from(newSet));
      }
    },
    [onChange, values]
  );

  return (
    <Box display="flex" direction="column">
      <Grid gap={2} gridTemplateColumns={checkBoxGroupGridTemplateColumns}>
        {options.map((option) => {
          const valueChanged: CheckboxProps["onChange"] = (args) => {
            checkBoxGroupChanged(option.value, args.checked);
          };

          return (
            <Checkbox
              id={`${id}_${option.value.replace(/ /g, "")}`}
              key={option.value}
              disabled={disabled}
              checked={values.has(option.value)}
              onChange={valueChanged}
              label={option.label}
              name={name}
              size={size || "sm"}
            />
          );
        })}
      </Grid>
      <Box margin={2} />
    </Box>
  );
};
