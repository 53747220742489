import {
  ButtonLinkProps,
  ButtonProps,
  Button as GestaltButton,
  ButtonLink as GestaltButtonLink,
} from "gestalt";
import { FunctionComponent, Ref, forwardRef } from "react";

type Props = ButtonProps;

export const Button: FunctionComponent<Props> = (props: Props) => {
  return <GestaltButton color="blue" {...props} />;
};

export const ButtonLink: FunctionComponent<ButtonLinkProps> = (props: ButtonLinkProps) => {
  return <GestaltButtonLink {...props} />;
};

export const ButtonWithRef = forwardRef((props: Props, ref?: Ref<HTMLButtonElement>) => (
  <GestaltButton ref={ref} {...props} />
));
