import {
  Box,
  Center,
  Image,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FC, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { LogoSvg } from "../../svg/LogoSvg";
import { FooterLink } from "./FooterLink";
import { Props } from "./LoginLayout";

const LoginLayoutSmall: FC<Props> = ({ children }) => {
  const { colors } = useColors();
  const navigate = useNavigate();
  const { breakpoint } = useDesignBreakpoint();
  const widthLogo = breakpoint === "medium" || breakpoint !== "large" ? 300 : 400;

  return (
    <Box minHeight="100vh" width={"100vw"} overflow="hidden" color={colors.white}>
      <Box minHeight="80vh" display="flex" justifyContent="center">
        <Center direction="column">
          <Box width={widthLogo}>
            <TapArea onTap={() => navigate("/")}>
              <Box
                paddingX={9}
                dangerouslySetInlineStyle={{
                  __style: { padding: "25px" },
                }}
              >
                <Image
                  color="transparent"
                  alt=""
                  naturalHeight={50}
                  naturalWidth={400}
                  src={logo}
                ></Image>
              </Box>
            </TapArea>
          </Box>
          <Box>{children}</Box>
        </Center>
      </Box>
      <LoginLayoutFooterSmall />
    </Box>
  );
};

export function LoginLayoutFooterSmall() {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const navigate = useNavigate();

  const goHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Box width={"100%"} height="auto">
      <Box display="flex" direction="column" alignItems="center" wrap={false}>
        <Box display="flex" width={"85%"} height={1} color={colors.neutral700}></Box>
        <Box width={"95%"} paddingY={4} display="flex" justifyContent="center">
          <Box display="flex" gap={4} direction="column">
            <Box display="flex" justifyContent="between">
              <FooterLink />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Box display="flex" flex="shrink">
                <TapArea onTap={goHome}>
                  <LogoSvg width={47} />
                </TapArea>
              </Box>
              <Box display="flex" flex="shrink">
                <Text color={colors.neutral900} size="200">
                  © {new Date().getFullYear()} {resources.allRightsReserved}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export { LoginLayoutSmall };
