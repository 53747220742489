import {
  Box,
  DatePicker,
  Divider,
  Switch,
  SwitchProps,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode, useCallback, useRef } from "react";
import { Link, To } from "react-router-dom";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { ActivitySearch } from "../../interfaces/activities/ActivitySearch";
import { Topic } from "../Layout/Topic";
import { MenuSearch } from "./MenuSearch";

export type Props = {
  search: ActivitySearch;
  onChangeSearch: (search: ActivitySearch) => void;
};

export const ActivityMenuSearch: FunctionComponent<Props> = ({ search, onChangeSearch }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const handleChange: SwitchProps["onChange"] = useCallback(
    (d) => {
      if (search.entryTypes?.some((element) => element.code === d.event.currentTarget.id)) {
        const index = search.entryTypes?.findIndex(
          (element) => element.code === d.event.currentTarget.id
        );
        const newEntry = [...search.entryTypes];
        newEntry.splice(index, 1);
        onChangeSearch({ ...search, entryTypes: newEntry, pageNumber: 0 });
      } else {
        const newEntry = search.listEntryTypes?.find(
          (element) => element.code === d.event.currentTarget.id
        );
        if (newEntry) {
          onChangeSearch({
            ...search,
            entryTypes: [...search.entryTypes, newEntry],
            pageNumber: 0,
          });
        }
      }
    },
    [onChangeSearch, search]
  );

  const handleChangeDate = useCallback(
    (d: any) => {
      onChangeSearch({
        ...search,
        pageNumber: 0,
        fromDate: d.value == null ? undefined : new Date(d.value),
      });
    },
    [onChangeSearch, search]
  );
  const handleChangeDateTo = useCallback(
    (d: any) => {
      onChangeSearch({
        ...search,
        pageNumber: 0,
        toDate: d.value == null ? undefined : new Date(d.value),
      });
    },
    [onChangeSearch, search]
  );

  const endDateInput = useRef(null);
  const startDateInput = useRef(null);
  const { breakpoint } = useDesignBreakpoint();

  return (
    <>
      {breakpoint === "hd" && (
        <LeftSide>
          <Box paddingX={4} display="flex" direction="column" gap={2}>
            <Topic>{resources.activities.dateRange}</Topic>

            <Box display="flex" gap={2} direction="column">
              <DatePicker
                resources={resources}
                // rangeStartDate={search.fromDate}
                rangeEndDate={search.toDate}
                id="fromDate"
                label={resources.from}
                placeholder={resources.from}
                nextRef={endDateInput}
                onChange={handleChangeDate}
                rangeSelector="start"
                value={search.fromDate}
                ref={startDateInput}
                dataType="Date"
              />
              <DatePicker
                resources={resources}
                rangeStartDate={search.fromDate}
                // rangeEndDate={search.toDate}
                id="toDate"
                label={resources.to}
                placeholder={resources.to}
                nextRef={startDateInput}
                onChange={handleChangeDateTo}
                rangeSelector="end"
                value={search.toDate}
                ref={endDateInput}
                dataType="Date"
              />
            </Box>
            <Divider direction="horizontal" />

            <Box display="flex" direction="row" flex="shrink">
              <Topic>{resources.activities.typeOfActivity}</Topic>
            </Box>
            <Box display="flex" direction="column" flex="shrink" paddingX={1} gap={1}>
              {search.listEntryTypes.map((entry) => {
                return (
                  <Box key={entry.code} display="flex" flex="grow" alignItems="center" gap={2}>
                    <Switch
                      id={entry.code}
                      onChange={handleChange}
                      switched={search.entryTypes?.some((element) => element.code === entry.code)}
                    ></Switch>
                    <Text size="300">{entry.name}</Text>
                    {/* <Checkbox
                    label={entry.name}
                    id={entry.code}
                    size="sm"
                    onChange={handleChange}
                    checked={search.entryTypes?.some((element) => element.code === entry.code)}
                  ></Checkbox> */}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </LeftSide>
      )}
      {(breakpoint === "medium" || breakpoint === "small" || breakpoint === "large") && (
        <Box
          color={colors.white}
          height={"100%"}
          dangerouslySetInlineStyle={{
            __style: {
              borderRight: "1px solid #C7C7C8",
            },
          }}
          paddingY={breakpoint === "small" ? 9 : undefined}
        >
          <MenuSearch
            search={search}
            handleChange={handleChange}
            endDateInput={endDateInput}
            startDateInput={startDateInput}
            handleChangeDate={handleChangeDate}
            handleChangeDateTo={handleChangeDateTo}
          />
        </Box>
      )}
    </>
  );
};

export function FooterLink({ to, children }: { to: To; children?: ReactNode }) {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <p style={{ margin: 0, fontSize: "0.75rem", color: "#AAA9AD" }}>{children}</p>
    </Link>
  );
}
export function LeftSide({ children }: { children?: ReactNode }) {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const breakPoint = breakpoint !== "hd";
  const borderRightBreakPoint = breakPoint ? undefined : "1px solid #C7C7C8";

  return (
    <Box
      height="100%"
      display="flex"
      direction="column"
      color={colors.white}
      dangerouslySetInlineStyle={{
        __style: {
          borderRight: borderRightBreakPoint,
        },
      }}
    >
      <Box direction="column" flex="grow" display="flex" minWidth={320}>
        {children}
      </Box>
    </Box>
  );
}
