import { FunctionComponent } from "react";
import { calculateColor, type ColorConfig } from "../utils";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const SignDocumentSvg: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 50,
  height = 50,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 91.89 99.77"
      className={customClassName || undefined}
      width={width}
      height={height}
    >
      <defs>
        <style>{".cls-1{stroke-width:0"}</style>
      </defs>
      <g>
        <path
          d="M13 92.17H4.71C2.12 92.17 0 90.06 0 87.46V4.71C0 2.11 2.11 0 4.71 0h65.94c2.6 0 4.71 2.11 4.71 4.71V33.9h-6V6H6v80.17h7v6ZM70.65 92.17H45.11v-6h24.25V70.86h6v16.61c0 2.6-2.11 4.71-4.71 4.71ZM66.76 47.18 51.71 62.12a6.78 6.78 0 0 0-1.49 2.53l-1.81 6.55.29.29-.97 1.97c-.48.88-.16 1.33-.02 1.47.17.12.59.5 1.51-.03l1.94-.95.4.4 6.67-1.85c.98-.27 1.82-.78 2.51-1.5l9.61-9.54 5.35-5.33-8.96-8.96Z"
          className="cls-1"
          fill={color ? calculateColor(color) : "#5a5a5a"}
        />
        <path
          d="m88.26 45.06.67-.67c1.89-1.52 2.85-3.58 2.95-5.78.06-2.07-.7-4.11-2.19-5.51-1.4-1.4-3.44-2.13-5.59-2.02-2.21.11-4.2 1.13-5.63 2.8l-7.98 8.01-3.11 3.07 10.47 10.47 5.79-5.77.97.97-7.21 7.21.17.17a3.253 3.253 0 1 0 4.26 4.26l.17.17 7.56-7.56a6.036 6.036 0 0 0 0-8.51l-1.31-1.31ZM50.43 27.54H15.86c-1.66 0-3-1.34-3-3s1.34-3 3-3h34.58c1.66 0 3 1.34 3 3s-1.34 3-3 3ZM49.97 42.85H15.39c-1.66 0-3-1.34-3-3s1.34-3 3-3h34.58c1.66 0 3 1.34 3 3s-1.34 3-3 3Z"
          className="cls-1"
          fill={color ? calculateColor(color) : "#5a5a5a"}
        />
        <path
          d="M28.31 49.93C19.86 49.93 13 56.79 13 65.24c0 4.15 1.64 7.9 4.33 10.65 2.77 2.88 6.67 4.66 10.98 4.66s8.21-1.78 10.99-4.66c2.68-2.75 4.32-6.5 4.32-10.65 0-8.45-6.85-15.31-15.31-15.31Zm10.61 20.71c-1.96 3.86-5.98 6.51-10.61 6.51s-8.64-2.65-10.6-6.51c-.83-1.62-1.3-3.45-1.3-5.4 0-6.58 5.33-11.91 11.9-11.91s11.91 5.33 11.91 11.91c0 1.95-.47 3.78-1.3 5.4Z"
          style={{
            stroke: "#5a5a5a",
            strokeMiterlimit: 10,
          }}
          fill={color ? calculateColor(color) : "#5a5a5a"}
        />
        <path
          d="M35.1 65.24c0 1.71-.63 3.29-1.69 4.49-1.25 1.41-3.07 2.3-5.1 2.3a6.78 6.78 0 0 1-6.79-6.79 6.79 6.79 0 0 1 13.58 0ZM39.29 83.79V98.8c0 .77-.85 1.22-1.49.79l-9.19-6.18a.948.948 0 0 0-1.08.01l-8.7 6.11c-.63.45-1.51 0-1.51-.78V83.78c0-.73.78-1.19 1.42-.84 1.96 1.07 5.24 2.44 9.45 2.46 4.33.03 7.7-1.37 9.69-2.46a.95.95 0 0 1 1.41.84Z"
          className="cls-1"
          fill={color ? calculateColor(color) : "#5a5a5a"}
        />
      </g>
    </svg>
  );
};
