import {
  Box,
  Button,
  Divider,
  Heading,
  Link,
  PasswordSetterField,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useEffect, useState } from "react";

import { checkPasswordStrength } from "@prodoctivity/shared";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { PasswordStrength } from "../../utils";

type Props = {
  email: string | null;
  token: string | undefined;
};

export const InvitationPassword: FunctionComponent<Props> = ({ email, token }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength | undefined>(undefined);
  const [submit, setSubmit] = useState(false);
  const passwordNotMatch = password && password !== confirmedPassword;
  const { activateForgotPasswordOTP } = useServices();
  const [errorMessage, setErrorMessage] = useState("");

  const doChangePassword = useCallback(async () => {
    if (token && email) {
      await activateForgotPasswordOTP(email, token, password);
      return true;
    }
    return false;
  }, [activateForgotPasswordOTP, email, password, token]);
  const { data, mutate, isLoading } = useMutation(doChangePassword, {
    onError(error: any) {
      const message = error?.response?.data?.message;
      if (message === "expired") {
        setErrorMessage(resources.forgotPasswordPage.passwordResetAlreadyExpired);
        setTimeout(() => setErrorMessage(""), 5000);
      }
    },
  });
  const passwordChanged = !!data;
  useEffect(() => {
    let latestCheck = undefined;
    const comparison: boolean = password !== "";
    if (comparison) latestCheck = checkPasswordStrength(password);
    setPasswordStrength(latestCheck);
    confirmedPassword !== "" ? setSubmit(true) : setSubmit(false);
  }, [password, confirmedPassword, submit, navigate]);

  const changePasswordClick = useCallback(() => {
    if (password && !passwordNotMatch) {
      mutate();
    }

    if (submit) {
      navigate("/login");
    }
  }, [password, passwordNotMatch, submit, mutate, navigate]);
  return (
    <Box maxWidth={520} marginBottom="auto" alignSelf="center" right padding={12} rounding="pill">
      <Box paddingY={2}>
        <Heading size="400" color={colors.black600}>
          {resources.setPasswordActivateAccount}
        </Heading>
      </Box>
      <Box paddingY={4}>
        <Text inline={true} overflow="breakWord" align="start" color={colors.neutral900}>
          {resources.enterNewPassword}
          <Text inline={true} weight="bold">
            "{email}".
          </Text>
        </Text>
      </Box>
      <Divider />
      <Box paddingY={5}>
        <Box marginBottom={4} direction="column">
          <Box display="flex" gap={3} direction="column">
            <Box display="flex" direction="row" justifyContent="between" marginBottom={2}>
              <Text weight="bold">{resources.password}</Text>
              <Link
                href=""
                onClick={(event) => {
                  setShowPassword(!showPassword);
                  event.event.preventDefault();
                }}
              >
                <Text color={colors.primary}>{showPassword ? resources.hide : resources.show}</Text>
              </Link>
            </Box>
            <PasswordSetterField
              id="newPasswordField"
              value={password}
              onChange={setPassword}
              resources={resources}
              labelDisplay="hidden"
              showPassword={showPassword}
            />
          </Box>
        </Box>
        <Box marginBottom={4} direction="column">
          <Box display="flex" gap={3} direction="column">
            <Box display="flex" direction="row" justifyContent="between" marginBottom={2}>
              <Text weight="bold">{resources.adminChangePassword.confirmPassword}</Text>
              <Link
                href=""
                onClick={(event) => {
                  setShowConfirmPassword(!showConfirmPassword);
                  event.event.preventDefault();
                }}
              >
                <Text color={colors.primary}>
                  {showConfirmPassword ? resources.hide : resources.show}
                </Text>
              </Link>
            </Box>
            <TextField
              disabled={!passwordStrength?.success || passwordChanged}
              value={confirmedPassword}
              type={showConfirmPassword ? "text" : "password"}
              label={resources.adminChangePassword.confirmPassword}
              labelDisplay="hidden"
              onChange={(e) => setConfirmedPassword(e.value)}
              id="confirmedPasswordField"
              placeholder={resources.adminChangePassword.confirmPassword}
              errorMessage={
                passwordNotMatch ? resources.adminChangePassword.passwordNotMatch : null
              }
            ></TextField>
          </Box>
        </Box>
      </Box>
      {passwordChanged ? (
        <Button
          size="lg"
          text={resources.returnToLogin}
          accessibilityLabel="Return to Login"
          fullWidth={true}
          type="button"
          color="gray"
          onClick={() => navigate("/login")}
        />
      ) : (
        <>
          <Button
            disabled={!submit || isLoading}
            size="lg"
            text={resources.adminChangePassword.setNewPassword}
            accessibilityLabel="Set new password"
            fullWidth={true}
            color={"blue"}
            onClick={changePasswordClick}
          />
          {errorMessage && <Text color={colors.error}>{errorMessage} </Text>}
        </>
      )}
    </Box>
  );
};
