import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
};

export const ApproveTaskSvg: FunctionComponent<Props> = ({ width = 50, height = 50 }) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10437_119233)">
        <rect x="1.5" y="1.5" width="47" height="47" rx="3.5" fill="#ABEDD3" stroke="#008753" />
        <path
          d="M12.8047 14.8636L12.8047 37.4002C12.8047 39.4746 14.4863 41.1562 16.5608 41.1562H31.4583C33.5328 41.1562 35.2144 39.4746 35.2144 37.4002V14.8636C35.2144 12.7891 33.5328 11.1075 31.4583 11.1075H16.5608C14.4863 11.1075 12.8047 12.7891 12.8047 14.8636Z"
          fill="#ABEDD3"
        />
        <path
          d="M12.3174 38.1509L12.3174 14.1118C12.3184 13.1858 12.6866 12.298 13.3414 11.6432C13.9962 10.9884 14.884 10.6201 15.8101 10.6192H32.2101C33.1361 10.6201 34.0239 10.9884 34.6787 11.6432C35.3335 12.298 35.7018 13.1858 35.7027 14.1118V38.1509C35.7018 39.0769 35.3335 39.9647 34.6787 40.6195C34.0239 41.2743 33.1361 41.6426 32.2101 41.6436H15.8101C14.884 41.6426 13.9962 41.2743 13.3414 40.6195C12.6866 39.9647 12.3184 39.0769 12.3174 38.1509ZM34.7271 14.1118C34.7265 13.4445 34.4611 12.8046 33.9892 12.3327C33.5173 11.8608 32.8774 11.5954 32.2101 11.5948H15.8101C15.1427 11.5954 14.5028 11.8608 14.0309 12.3327C13.559 12.8046 13.2936 13.4445 13.293 14.1118L13.293 38.1509C13.2936 38.8182 13.559 39.4581 14.0309 39.93C14.5028 40.4019 15.1427 40.6673 15.8101 40.6679H32.2101C32.8774 40.6673 33.5173 40.4019 33.9892 39.93C34.4611 39.4581 34.7265 38.8182 34.7271 38.1509V14.1118Z"
          fill="#008753"
        />
        <path
          d="M24.0147 26.1221C26.7626 26.1221 28.9903 23.8945 28.9903 21.1465C28.9903 18.3986 26.7626 16.1709 24.0147 16.1709C21.2667 16.1709 19.0391 18.3986 19.0391 21.1465C19.0391 23.8945 21.2667 26.1221 24.0147 26.1221Z"
          fill="#ABEDD3"
        />
        <path
          d="M24.0142 15.6826C25.0948 15.6826 26.1511 16.003 27.0495 16.6034C27.948 17.2037 28.6482 18.057 29.0617 19.0553C29.4752 20.0536 29.5834 21.1521 29.3726 22.2119C29.1618 23.2717 28.6415 24.2452 27.8774 25.0092C27.1133 25.7733 26.1399 26.2937 25.0801 26.5045C24.0203 26.7153 22.9217 26.6071 21.9234 26.1936C20.9251 25.7801 20.0719 25.0798 19.4715 24.1813C18.8712 23.2829 18.5508 22.2266 18.5508 21.146C18.5508 19.697 19.1264 18.3074 20.151 17.2828C21.1756 16.2582 22.5652 15.6826 24.0142 15.6826ZM24.0142 25.6338C24.9018 25.6338 25.7695 25.3706 26.5075 24.8775C27.2455 24.3844 27.8207 23.6835 28.1604 22.8634C28.5001 22.0434 28.5889 21.1411 28.4158 20.2705C28.2426 19.4 27.8152 18.6003 27.1876 17.9727C26.5599 17.345 25.7603 16.9176 24.8897 16.7445C24.0192 16.5713 23.1168 16.6602 22.2968 16.9998C21.4767 17.3395 20.7758 17.9147 20.2827 18.6527C19.7896 19.3908 19.5264 20.2584 19.5264 21.146C19.5264 22.3363 19.9992 23.4778 20.8408 24.3194C21.6825 25.161 22.824 25.6338 24.0142 25.6338Z"
          fill="#008753"
        />
        <path
          d="M29.8386 30.8438H22.0044C21.875 30.8438 21.751 30.7924 21.6595 30.7009C21.568 30.6094 21.5166 30.4853 21.5166 30.356C21.5166 30.2266 21.568 30.1025 21.6595 30.011C21.751 29.9196 21.875 29.8682 22.0044 29.8682H29.8386C29.9026 29.8682 29.966 29.8808 30.0252 29.9053C30.0844 29.9298 30.1382 29.9657 30.1835 30.011C30.2288 30.0563 30.2647 30.1101 30.2892 30.1693C30.3137 30.2285 30.3264 30.2919 30.3264 30.356C30.3264 30.42 30.3137 30.4835 30.2892 30.5426C30.2647 30.6018 30.2288 30.6556 30.1835 30.7009C30.1382 30.7462 30.0844 30.7821 30.0252 30.8066C29.966 30.8312 29.9026 30.8438 29.8386 30.8438Z"
          fill="#008753"
        />
        <path
          d="M25.9166 34.083H22.0044C21.875 34.083 21.751 34.0316 21.6595 33.9402C21.568 33.8487 21.5166 33.7246 21.5166 33.5952C21.5166 33.4659 21.568 33.3418 21.6595 33.2503C21.751 33.1588 21.875 33.1074 22.0044 33.1074H25.9166C26.046 33.1074 26.1701 33.1588 26.2615 33.2503C26.353 33.3418 26.4044 33.4659 26.4044 33.5952C26.4044 33.7246 26.353 33.8487 26.2615 33.9402C26.1701 34.0316 26.046 34.083 25.9166 34.083Z"
          fill="#008753"
        />
        <path
          d="M18.4828 31.7898C19.3772 31.7898 20.1023 31.0647 20.1023 30.1703C20.1023 29.2759 19.3772 28.5508 18.4828 28.5508C17.5884 28.5508 16.8633 29.2759 16.8633 30.1703C16.8633 31.0647 17.5884 31.7898 18.4828 31.7898Z"
          fill="#ABEDD3"
        />
        <path
          d="M18.4823 28.0635C18.8991 28.0635 19.3065 28.1871 19.6531 28.4186C19.9996 28.6502 20.2697 28.9793 20.4292 29.3644C20.5887 29.7494 20.6305 30.1731 20.5491 30.5819C20.4678 30.9907 20.2671 31.3662 19.9724 31.6609C19.6777 31.9556 19.3022 32.1563 18.8934 32.2376C18.4847 32.3189 18.0609 32.2772 17.6759 32.1177C17.2908 31.9582 16.9617 31.6881 16.7301 31.3416C16.4986 30.995 16.375 30.5876 16.375 30.1708C16.375 29.6119 16.597 29.0759 16.9922 28.6807C17.3874 28.2855 17.9234 28.0635 18.4823 28.0635ZM18.4823 31.3025C18.7061 31.3025 18.925 31.2361 19.1111 31.1118C19.2972 30.9874 19.4422 30.8107 19.5279 30.6039C19.6135 30.3971 19.6359 30.1695 19.5923 29.95C19.5486 29.7305 19.4408 29.5288 19.2826 29.3706C19.1243 29.2123 18.9226 29.1045 18.7031 29.0608C18.4836 29.0172 18.256 29.0396 18.0492 29.1252C17.8424 29.2109 17.6657 29.3559 17.5413 29.5421C17.417 29.7282 17.3506 29.947 17.3506 30.1708C17.3506 30.4709 17.4698 30.7588 17.6821 30.971C17.8943 31.1833 18.1822 31.3025 18.4823 31.3025Z"
          fill="#008753"
        />
        <path d="M21.585 20.9215L23.1069 22.7361L26.4337 19.5557" fill="#ABEDD3" />
        <path
          d="M23.1067 23.2247H23.0799C23.0132 23.2212 22.9479 23.204 22.8881 23.1742C22.8283 23.1444 22.7753 23.1027 22.7323 23.0516L21.2103 21.2369C21.1283 21.1377 21.0889 21.01 21.1007 20.8818C21.1124 20.7536 21.1744 20.6352 21.273 20.5525C21.3717 20.4698 21.499 20.4294 21.6273 20.4402C21.7556 20.451 21.8745 20.5121 21.9579 20.6101L23.1457 22.026L26.0957 19.2052C26.1895 19.1177 26.314 19.0708 26.4423 19.0744C26.5705 19.0781 26.6921 19.1321 26.7808 19.2248C26.8695 19.3175 26.9181 19.4414 26.9161 19.5696C26.9141 19.6979 26.8616 19.8202 26.7701 19.9101L23.4481 23.0894C23.3563 23.1773 23.2338 23.2258 23.1067 23.2247Z"
          fill="#008753"
        />
        <path
          d="M14.5414 16.4932H12.0341C11.4414 16.4932 10.9609 16.9736 10.9609 17.5663V18.4834C10.9609 19.0761 11.4414 19.5566 12.0341 19.5566H14.5414C15.1341 19.5566 15.6146 19.0761 15.6146 18.4834V17.5663C15.6146 16.9736 15.1341 16.4932 14.5414 16.4932Z"
          fill="#ABEDD3"
        />
        <path
          d="M11.8141 16.0049H14.7556C15.1116 16.0055 15.453 16.1469 15.7052 16.3982C15.9574 16.6495 16.1 16.9903 16.1019 17.3463V18.6878C16.1 19.043 15.9581 19.3831 15.7069 19.6342C15.4557 19.8854 15.1156 20.0274 14.7605 20.0293H11.8141C11.4589 20.0274 11.1188 19.8854 10.8677 19.6342C10.6165 19.3831 10.4746 19.043 10.4727 18.6878V17.3463C10.4746 16.9912 10.6165 16.6511 10.8677 16.3999C11.1188 16.1488 11.4589 16.0068 11.8141 16.0049ZM14.7556 19.0683C14.8526 19.0683 14.9457 19.0298 15.0143 18.9611C15.0829 18.8925 15.1214 18.7995 15.1214 18.7024V17.3463C15.1214 17.2493 15.0829 17.1563 15.0143 17.0876C14.9457 17.019 14.8526 16.9805 14.7556 16.9805H11.8141C11.7171 16.9805 11.624 17.019 11.5554 17.0876C11.4868 17.1563 11.4483 17.2493 11.4483 17.3463V18.6878C11.4483 18.7848 11.4868 18.8779 11.5554 18.9465C11.624 19.0151 11.7171 19.0537 11.8141 19.0537L14.7556 19.0683Z"
          fill="#008753"
        />
        <path
          d="M14.5414 33.0098H12.0341C11.4414 33.0098 10.9609 33.4902 10.9609 34.0829V35C10.9609 35.5927 11.4414 36.0732 12.0341 36.0732H14.5414C15.1341 36.0732 15.6146 35.5927 15.6146 35V34.0829C15.6146 33.4902 15.1341 33.0098 14.5414 33.0098Z"
          fill="#ABEDD3"
        />
        <path
          d="M11.8141 32.5225H14.7556C15.1116 32.5231 15.453 32.6645 15.7052 32.9158C15.9574 33.1671 16.1 33.5079 16.1019 33.8639V35.2054C16.1 35.5606 15.9581 35.9007 15.7069 36.1518C15.4557 36.403 15.1156 36.5449 14.7605 36.5469H11.8141C11.4589 36.5449 11.1188 36.403 10.8677 36.1518C10.6165 35.9007 10.4746 35.5606 10.4727 35.2054V33.8639C10.4746 33.5087 10.6165 33.1686 10.8677 32.9175C11.1188 32.6663 11.4589 32.5244 11.8141 32.5225ZM14.7556 35.5859C14.8526 35.5859 14.9457 35.5473 15.0143 35.4787C15.0829 35.4101 15.1214 35.3171 15.1214 35.22V33.8639C15.1214 33.7669 15.0829 33.6738 15.0143 33.6052C14.9457 33.5366 14.8526 33.4981 14.7556 33.4981H11.8141C11.7171 33.4981 11.624 33.5366 11.5554 33.6052C11.4868 33.6738 11.4483 33.7669 11.4483 33.8639V35.2054C11.4483 35.3024 11.4868 35.3955 11.5554 35.4641C11.624 35.5327 11.7171 35.5712 11.8141 35.5712L14.7556 35.5859Z"
          fill="#008753"
        />
        <path d="M38.3856 16.1709H35.2148V21.927H38.3856V16.1709Z" fill="#ABEDD3" />
        <path
          d="M35.2144 15.6826H38.3851C38.5145 15.6826 38.6385 15.734 38.73 15.8255C38.8215 15.917 38.8729 16.041 38.8729 16.1704V21.9265C38.8729 22.0559 38.8215 22.18 38.73 22.2714C38.6385 22.3629 38.5145 22.4143 38.3851 22.4143H35.2144C35.085 22.4143 34.9609 22.3629 34.8694 22.2714C34.778 22.18 34.7266 22.0559 34.7266 21.9265V16.1704C34.7266 16.041 34.778 15.917 34.8694 15.8255C34.9609 15.734 35.085 15.6826 35.2144 15.6826ZM37.8973 16.6582H35.7022V21.4387H37.8973V16.6582Z"
          fill="#008753"
        />
        <path d="M38.3856 28.5508H35.2148V34.3069H38.3856V28.5508Z" fill="#ABEDD3" />
        <path
          d="M35.2144 28.0635H38.3851C38.5145 28.0635 38.6385 28.1149 38.73 28.2064C38.8215 28.2978 38.8729 28.4219 38.8729 28.5513V34.3074C38.8729 34.4368 38.8215 34.5608 38.73 34.6523C38.6385 34.7438 38.5145 34.7952 38.3851 34.7952H35.2144C35.085 34.7952 34.9609 34.7438 34.8694 34.6523C34.778 34.5608 34.7266 34.4368 34.7266 34.3074V28.5513C34.7266 28.4219 34.778 28.2978 34.8694 28.2064C34.9609 28.1149 35.085 28.0635 35.2144 28.0635ZM37.8973 29.0391H35.7022V33.8196H37.8973V29.0391Z"
          fill="#008753"
        />
        <g clipPath="url(#clip1_10437_119233)">
          <path
            d="M16.7301 31.3396C16.4901 30.9896 16.3701 30.5896 16.3701 30.1696C16.3701 29.6096 16.5901 29.0696 16.9901 28.6796C17.3801 28.2796 17.9201 28.0596 18.4801 28.0596C18.8901 28.0596 19.3001 28.1896 19.6501 28.4196C20.0001 28.6496 20.2701 28.9796 20.4301 29.3596C20.5801 29.7496 20.6301 30.1696 20.5401 30.5796C20.4601 30.9896 20.2601 31.3596 19.9701 31.6596C19.6701 31.9496 19.3001 32.1496 18.8901 32.2396C18.4801 32.3196 18.0601 32.2796 17.6701 32.1196C17.2901 31.9596 16.9601 31.6896 16.7301 31.3396Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M26.2598 33.2504C26.3498 33.3404 26.3998 33.4604 26.3998 33.5904C26.3998 33.7204 26.3498 33.8503 26.2598 33.9403C26.1698 34.0303 26.0398 34.0804 25.9098 34.0804H21.9998C21.8698 34.0804 21.7498 34.0303 21.6598 33.9403C21.5598 33.8503 21.5098 33.7204 21.5098 33.5904C21.5098 33.4604 21.5598 33.3404 21.6598 33.2504C21.7498 33.1604 21.8698 33.1104 21.9998 33.1104H25.9098C26.0398 33.1104 26.1698 33.1604 26.2598 33.2504Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M26.7798 19.2203C26.8698 19.3203 26.9098 19.4403 26.9098 19.5703C26.9098 19.7003 26.8598 19.8203 26.7698 19.9103L23.4398 23.0903C23.3498 23.1803 23.2298 23.2203 23.0998 23.2203H23.0798C23.0098 23.2203 22.9398 23.2003 22.8798 23.1703C22.8198 23.1403 22.7698 23.1003 22.7298 23.0503L21.2098 21.2403C21.1198 21.1403 21.0798 21.0103 21.0998 20.8803C21.1098 20.7503 21.1698 20.6303 21.2698 20.5503C21.3698 20.4703 21.4898 20.4303 21.6198 20.4403C21.7498 20.4503 21.8698 20.5103 21.9498 20.6103L22.0898 20.7803L23.1398 22.0203L25.4298 19.8303L26.0898 19.2003C26.1898 19.1203 26.3098 19.0703 26.4398 19.0703C26.5698 19.0803 26.6898 19.1303 26.7798 19.2203Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M29.0598 19.0497C28.6398 18.0597 27.9398 17.1997 27.0498 16.5997C26.1498 15.9997 25.0898 15.6797 24.0098 15.6797C22.5598 15.6797 21.1698 16.2597 20.1498 17.2797C19.1198 18.3097 18.5498 19.6997 18.5498 21.1397C18.5498 22.2297 18.8698 23.2797 19.4698 24.1797C20.0698 25.0797 20.9198 25.7797 21.9198 26.1897C22.9198 26.6097 24.0198 26.7097 25.0798 26.4997C26.1398 26.2897 27.1098 25.7697 27.8698 25.0097C28.6298 24.2497 29.1598 23.2697 29.3698 22.2097C29.5798 21.1497 29.4698 20.0497 29.0598 19.0497ZM28.1598 22.8597C27.8198 23.6797 27.2398 24.3797 26.4998 24.8797C25.7698 25.3697 24.8998 25.6297 24.0098 25.6297C22.8198 25.6297 21.6798 25.1597 20.8398 24.3197C19.9998 23.4797 19.5198 22.3297 19.5198 21.1397C19.5198 20.2597 19.7898 19.3897 20.2798 18.6497C20.7698 17.9097 21.4698 17.3397 22.2898 16.9997C23.1098 16.6597 24.0198 16.5697 24.8898 16.7397C25.7598 16.9197 26.5598 17.3397 27.1798 17.9697C27.8098 18.5997 28.2398 19.3997 28.4098 20.2697C28.5798 21.1397 28.4998 22.0397 28.1598 22.8597Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M21.9998 29.8701H29.8298C29.8998 29.8701 29.9598 29.8801 30.0198 29.9001C30.0798 29.9301 30.1298 29.9601 30.1798 30.0101C30.2198 30.0501 30.2598 30.1101 30.2898 30.1701C30.3098 30.2301 30.3198 30.2901 30.3198 30.3501C30.3198 30.4201 30.3098 30.4801 30.2898 30.5401C30.2598 30.6001 30.2198 30.6501 30.1798 30.7001C30.1298 30.7401 30.0798 30.7801 30.0198 30.8101C29.9598 30.8301 29.8998 30.8401 29.8298 30.8401H21.9998C21.8698 30.8401 21.7498 30.7901 21.6598 30.7001C21.5598 30.6101 21.5098 30.4801 21.5098 30.3501C21.5098 30.2301 21.5598 30.1001 21.6598 30.0101C21.7498 29.9201 21.8698 29.8701 21.9998 29.8701Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M45 1.5H5C3.06 1.5 1.5 3.07 1.5 5V45C1.5 46.93 3.06 48.5 5 48.5H45C46.93 48.5 48.5 46.93 48.5 45V5C48.5 3.07 46.93 1.5 45 1.5ZM13.29 36.55H14.76C15.11 36.55 15.45 36.4 15.7 36.15C15.95 35.9 16.1 35.56 16.1 35.2V33.86C16.1 33.51 15.95 33.17 15.7 32.91C15.45 32.66 15.11 32.52 14.75 32.52H13.29V20.03H14.76C15.11 20.03 15.45 19.88 15.7 19.63C15.95 19.38 16.1 19.04 16.1 18.69V17.34C16.1 16.99 15.95 16.65 15.7 16.4C15.45 16.15 15.11 16 14.75 16H13.29V14.11C13.29 13.44 13.55 12.8 14.03 12.33C14.5 11.86 15.14 11.59 15.81 11.59H32.21C32.87 11.59 33.51 11.86 33.99 12.33C34.46 12.8 34.72 13.44 34.72 14.11V38.15C34.72 38.82 34.46 39.46 33.99 39.93C33.51 40.4 32.87 40.67 32.21 40.67H15.81C15.14 40.67 14.5 40.4 14.03 39.93C13.55 39.46 13.29 38.82 13.29 38.15V36.55Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M20.4301 29.3596C20.5801 29.7496 20.6301 30.1696 20.5401 30.5796C20.4601 30.9896 20.2601 31.3596 19.9701 31.6596C19.6701 31.9496 19.3001 32.1496 18.8901 32.2396C18.4801 32.3196 18.0601 32.2796 17.6701 32.1196C17.2901 31.9596 16.9601 31.6896 16.7301 31.3396C16.4901 30.9896 16.3701 30.5896 16.3701 30.1696C16.3701 29.6096 16.5901 29.0696 16.9901 28.6796C17.3801 28.2796 17.9201 28.0596 18.4801 28.0596C18.8901 28.0596 19.3001 28.1896 19.6501 28.4196C20.0001 28.6496 20.2701 28.9796 20.4301 29.3596Z"
            fill="#ABEDD3"
          />
          <path
            d="M26.2598 33.2504C26.3498 33.3404 26.3998 33.4604 26.3998 33.5904C26.3998 33.7204 26.3498 33.8503 26.2598 33.9403C26.1698 34.0303 26.0398 34.0804 25.9098 34.0804H21.9998C21.8698 34.0804 21.7498 34.0303 21.6598 33.9403C21.5598 33.8503 21.5098 33.7204 21.5098 33.5904C21.5098 33.4604 21.5598 33.3404 21.6598 33.2504C21.7498 33.1604 21.8698 33.1104 21.9998 33.1104H25.9098C26.0398 33.1104 26.1698 33.1604 26.2598 33.2504Z"
            fill="#ABEDD3"
          />
          <path
            d="M26.7798 19.2203C26.8698 19.3203 26.9098 19.4403 26.9098 19.5703C26.9098 19.7003 26.8598 19.8203 26.7698 19.9103L23.4398 23.0903C23.3498 23.1803 23.2298 23.2203 23.0998 23.2203H23.0798C23.0098 23.2203 22.9398 23.2003 22.8798 23.1703C22.8198 23.1403 22.7698 23.1003 22.7298 23.0503L21.2098 21.2403C21.1198 21.1403 21.0798 21.0103 21.0998 20.8803C21.1098 20.7503 21.1698 20.6303 21.2698 20.5503C21.3698 20.4703 21.4898 20.4303 21.6198 20.4403C21.7498 20.4503 21.8698 20.5103 21.9498 20.6103L22.0898 20.7803L23.1398 22.0203L25.4298 19.8303L26.0898 19.2003C26.1898 19.1203 26.3098 19.0703 26.4398 19.0703C26.5698 19.0803 26.6898 19.1303 26.7798 19.2203Z"
            fill="#ABEDD3"
          />
          <path
            d="M29.0598 19.0497C28.6398 18.0597 27.9398 17.1997 27.0498 16.5997C26.1498 15.9997 25.0898 15.6797 24.0098 15.6797C22.5598 15.6797 21.1698 16.2597 20.1498 17.2797C19.1198 18.3097 18.5498 19.6997 18.5498 21.1397C18.5498 22.2297 18.8698 23.2797 19.4698 24.1797C20.0698 25.0797 20.9198 25.7797 21.9198 26.1897C22.9198 26.6097 24.0198 26.7097 25.0798 26.4997C26.1398 26.2897 27.1098 25.7697 27.8698 25.0097C28.6298 24.2497 29.1598 23.2697 29.3698 22.2097C29.5798 21.1497 29.4698 20.0497 29.0598 19.0497ZM28.1598 22.8597C27.8198 23.6797 27.2398 24.3797 26.4998 24.8797C25.7698 25.3697 24.8998 25.6297 24.0098 25.6297C22.8198 25.6297 21.6798 25.1597 20.8398 24.3197C19.9998 23.4797 19.5198 22.3297 19.5198 21.1397C19.5198 20.2597 19.7898 19.3897 20.2798 18.6497C20.7698 17.9097 21.4698 17.3397 22.2898 16.9997C23.1098 16.6597 24.0198 16.5697 24.8898 16.7397C25.7598 16.9197 26.5598 17.3397 27.1798 17.9697C27.8098 18.5997 28.2398 19.3997 28.4098 20.2697C28.5798 21.1397 28.4998 22.0397 28.1598 22.8597Z"
            fill="#ABEDD3"
          />
          <path
            d="M30.2898 30.1701C30.3098 30.2301 30.3198 30.2901 30.3198 30.3501C30.3198 30.4201 30.3098 30.4801 30.2898 30.5401C30.2598 30.6001 30.2198 30.6501 30.1798 30.7001C30.1298 30.7401 30.0798 30.7801 30.0198 30.8101C29.9598 30.8301 29.8998 30.8401 29.8298 30.8401H21.9998C21.8698 30.8401 21.7498 30.7901 21.6598 30.7001C21.5598 30.6101 21.5098 30.4801 21.5098 30.3501C21.5098 30.2301 21.5598 30.1001 21.6598 30.0101C21.7498 29.9201 21.8698 29.8701 21.9998 29.8701H29.8298C29.8998 29.8701 29.9598 29.8801 30.0198 29.9001C30.0798 29.9301 30.1298 29.9601 30.1798 30.0101C30.2198 30.0501 30.2598 30.1101 30.2898 30.1701Z"
            fill="#ABEDD3"
          />
          <path
            d="M31.4498 11.1104H16.5598C14.4798 11.1104 12.7998 12.7904 12.7998 14.8604V37.4004C12.7998 39.4704 14.4798 41.1504 16.5598 41.1504H31.4498C33.5298 41.1504 35.2098 39.4704 35.2098 37.4004V14.8604C35.2098 12.7904 33.5298 11.1104 31.4498 11.1104ZM34.7198 38.1504C34.7198 38.8204 34.4598 39.4604 33.9898 39.9304C33.5098 40.4004 32.8698 40.6703 32.2098 40.6703H15.8098C15.1398 40.6703 14.4998 40.4004 14.0298 39.9304C13.5498 39.4604 13.2898 38.8204 13.2898 38.1504V36.5504H14.7598C15.1098 36.5504 15.4498 36.4004 15.6998 36.1504C15.9498 35.9004 16.0998 35.5604 16.0998 35.2004V33.8604C16.0998 33.5104 15.9498 33.1704 15.6998 32.9104C15.4498 32.6604 15.1098 32.5204 14.7498 32.5204H13.2898V20.0304H14.7598C15.1098 20.0304 15.4498 19.8804 15.6998 19.6304C15.9498 19.3804 16.0998 19.0404 16.0998 18.6904V17.3404C16.0998 16.9904 15.9498 16.6504 15.6998 16.4004C15.4498 16.1504 15.1098 16.0004 14.7498 16.0004H13.2898V14.1104C13.2898 13.4404 13.5498 12.8004 14.0298 12.3304C14.4998 11.8604 15.1398 11.5904 15.8098 11.5904H32.2098C32.8698 11.5904 33.5098 11.8604 33.9898 12.3304C34.4598 12.8004 34.7198 13.4404 34.7198 14.1104V38.1504Z"
            fill="#ABEDD3"
          />
          <path
            d="M34.6696 11.6401C34.0196 10.9901 33.1296 10.6201 32.2096 10.6201H15.8096C14.8796 10.6201 13.9896 10.9901 13.3396 11.6401C12.6796 12.3001 12.3096 13.1801 12.3096 14.1101V38.1501C12.3096 39.0801 12.6796 39.9601 13.3396 40.6201C13.9896 41.2701 14.8796 41.6401 15.8096 41.6401H32.2096C33.1296 41.6401 34.0196 41.2701 34.6696 40.6201C35.3296 39.9601 35.6996 39.0801 35.6996 38.1501V14.1101C35.6996 13.1801 35.3296 12.3001 34.6696 11.6401ZM32.2096 40.6701H15.8096C15.1396 40.6701 14.4996 40.4001 14.0296 39.9301C13.5496 39.4601 13.2896 38.8201 13.2896 38.1501V14.1101C13.2896 13.4401 13.5496 12.8001 14.0296 12.3301C14.4996 11.8601 15.1396 11.5901 15.8096 11.5901H32.2096C32.8696 11.5901 33.5096 11.8601 33.9896 12.3301C34.4596 12.8001 34.7196 13.4401 34.7196 14.1101V38.1501C34.7196 38.8201 34.4596 39.4601 33.9896 39.9301C33.5096 40.4001 32.8696 40.6701 32.2096 40.6701Z"
            fill="#008753"
          />
          <path
            d="M26.7798 19.2203C26.8698 19.3203 26.9098 19.4403 26.9098 19.5703C26.9098 19.7003 26.8598 19.8203 26.7698 19.9103L23.4398 23.0903C23.3498 23.1803 23.2298 23.2203 23.0998 23.2203H23.0798C23.0098 23.2203 22.9398 23.2003 22.8798 23.1703C22.8198 23.1403 22.7698 23.1003 22.7298 23.0503L21.2098 21.2403C21.1198 21.1403 21.0798 21.0103 21.0998 20.8803C21.1098 20.7503 21.1698 20.6303 21.2698 20.5503C21.3698 20.4703 21.4898 20.4303 21.6198 20.4403C21.7498 20.4503 21.8698 20.5103 21.9498 20.6103L22.0898 20.7803L23.1398 22.0203L25.4298 19.8303L26.0898 19.2003C26.1898 19.1203 26.3098 19.0703 26.4398 19.0703C26.5698 19.0803 26.6898 19.1303 26.7798 19.2203Z"
            fill="#ABEDD3"
          />
          <path
            d="M24.0103 16.1699C21.2603 16.1699 19.0303 18.3999 19.0303 21.1399C19.0303 23.8799 21.2603 26.1199 24.0103 26.1199C26.7603 26.1199 28.9903 23.8899 28.9903 21.1399C28.9903 18.3899 26.7603 16.1699 24.0103 16.1699ZM26.5003 24.8799C25.7703 25.3699 24.9003 25.6299 24.0103 25.6299C22.8203 25.6299 21.6803 25.1599 20.8403 24.3199C20.0003 23.4799 19.5203 22.3299 19.5203 21.1399C19.5203 20.2599 19.7903 19.3899 20.2803 18.6499C20.7703 17.9099 21.4703 17.3399 22.2903 16.9999C23.1103 16.6599 24.0203 16.5699 24.8903 16.7399C25.7603 16.9199 26.5603 17.3399 27.1803 17.9699C27.8103 18.5999 28.2403 19.3999 28.4103 20.2699C28.5803 21.1399 28.5003 22.0399 28.1603 22.8599C27.8203 23.6799 27.2403 24.3799 26.5003 24.8799Z"
            fill="#ABEDD3"
          />
          <path
            d="M29.0598 19.0497C28.6398 18.0597 27.9398 17.1997 27.0498 16.5997C26.1498 15.9997 25.0898 15.6797 24.0098 15.6797C22.5598 15.6797 21.1698 16.2597 20.1498 17.2797C19.1198 18.3097 18.5498 19.6997 18.5498 21.1397C18.5498 22.2297 18.8698 23.2797 19.4698 24.1797C20.0698 25.0797 20.9198 25.7797 21.9198 26.1897C22.9198 26.6097 24.0198 26.7097 25.0798 26.4997C26.1398 26.2897 27.1098 25.7697 27.8698 25.0097C28.6298 24.2497 29.1598 23.2697 29.3698 22.2097C29.5798 21.1497 29.4698 20.0497 29.0598 19.0497ZM28.1598 22.8597C27.8198 23.6797 27.2398 24.3797 26.4998 24.8797C25.7698 25.3697 24.8998 25.6297 24.0098 25.6297C22.8198 25.6297 21.6798 25.1597 20.8398 24.3197C19.9998 23.4797 19.5198 22.3297 19.5198 21.1397C19.5198 20.2597 19.7898 19.3897 20.2798 18.6497C20.7698 17.9097 21.4698 17.3397 22.2898 16.9997C23.1098 16.6597 24.0198 16.5697 24.8898 16.7397C25.7598 16.9197 26.5598 17.3397 27.1798 17.9697C27.8098 18.5997 28.2398 19.3997 28.4098 20.2697C28.5798 21.1397 28.4998 22.0397 28.1598 22.8597Z"
            fill="#008753"
          />
          <path
            d="M18.4804 31.7898C19.3704 31.7898 20.1004 31.0598 20.1004 30.1698C20.1004 29.2798 19.3704 28.5498 18.4804 28.5498C17.5904 28.5498 16.8604 29.2798 16.8604 30.1698C16.8604 31.0598 17.5904 31.7898 18.4804 31.7898Z"
            fill="#ABEDD3"
          />
          <path
            d="M26.4301 19.5498L23.1001 22.7298L21.5801 20.9198L22.0901 20.7798L23.1401 22.0198L25.4301 19.8298L26.4301 19.5498Z"
            fill="#ABEDD3"
          />
          <path
            d="M34.72 14.1098V38.1498C34.72 38.8198 34.46 39.4598 33.99 39.9298C33.51 40.3998 32.87 40.6698 32.21 40.6698H15.81C15.14 40.6698 14.5 40.3998 14.03 39.9298C13.55 39.4598 13.29 38.8198 13.29 38.1498V36.5498H14.76C15.11 36.5498 15.45 36.3998 15.7 36.1498C15.95 35.8998 16.1 35.5598 16.1 35.1998V33.8598C16.1 33.5098 15.95 33.1698 15.7 32.9098C15.45 32.6598 15.11 32.5198 14.75 32.5198H13.29V20.0298H14.76C15.11 20.0298 15.45 19.8798 15.7 19.6298C15.95 19.3798 16.1 19.0398 16.1 18.6898V17.3398C16.1 16.9898 15.95 16.6498 15.7 16.3998C15.45 16.1498 15.11 15.9998 14.75 15.9998H13.29V14.1098C13.29 13.4398 13.55 12.7998 14.03 12.3298C14.5 11.8598 15.14 11.5898 15.81 11.5898H32.21C32.87 11.5898 33.51 11.8598 33.99 12.3298C34.46 12.7998 34.72 13.4398 34.72 14.1098Z"
            fill="#66D8A9"
          />
          <path
            d="M14.54 16.4902H12.03C11.44 16.4902 10.96 16.9702 10.96 17.5602V18.4802C10.96 19.0702 11.44 19.5502 12.03 19.5502H14.54C15.13 19.5502 15.61 19.0702 15.61 18.4802V17.5602C15.61 16.9702 15.13 16.4902 14.54 16.4902Z"
            fill="#ABEDD3"
          />
          <path
            d="M15.6997 16.4C15.4497 16.15 15.1097 16 14.7497 16H11.8097C11.4497 16.01 11.1097 16.15 10.8597 16.4C10.6097 16.65 10.4697 16.99 10.4697 17.34V18.69C10.4697 19.04 10.6097 19.38 10.8597 19.63C11.1097 19.88 11.4497 20.03 11.8097 20.03H14.7597C15.1097 20.03 15.4497 19.88 15.6997 19.63C15.9497 19.38 16.0997 19.04 16.0997 18.69V17.34C16.0997 16.99 15.9497 16.65 15.6997 16.4ZM15.1197 18.7C15.1197 18.8 15.0797 18.89 15.0097 18.96C14.9397 19.03 14.8497 19.07 14.7497 19.07H13.2897L12.7997 19.06H12.3097L11.8097 19.05C11.7097 19.05 11.6197 19.01 11.5497 18.94C11.4797 18.88 11.4397 18.78 11.4397 18.69V17.34C11.4397 17.25 11.4797 17.15 11.5497 17.09C11.6197 17.02 11.7097 16.98 11.8097 16.98H14.7497C14.8497 16.98 14.9397 17.02 15.0097 17.09C15.0797 17.15 15.1197 17.25 15.1197 17.34V18.7Z"
            fill="#008753"
          />
          <path
            d="M14.54 33.0098H12.03C11.44 33.0098 10.96 33.4898 10.96 34.0798V34.9998C10.96 35.5898 11.44 36.0698 12.03 36.0698H14.54C15.13 36.0698 15.61 35.5898 15.61 34.9998V34.0798C15.61 33.4898 15.13 33.0098 14.54 33.0098Z"
            fill="#ABEDD3"
          />
          <path
            d="M15.6997 32.9095C15.4497 32.6595 15.1097 32.5195 14.7497 32.5195H11.8097C11.4497 32.5195 11.1097 32.6595 10.8597 32.9195C10.6097 33.1695 10.4697 33.5095 10.4697 33.8595V35.1995C10.4697 35.5595 10.6097 35.8995 10.8597 36.1495C11.1097 36.3995 11.4497 36.5395 11.8097 36.5495H14.7597C15.1097 36.5495 15.4497 36.3995 15.6997 36.1495C15.9497 35.8995 16.0997 35.5595 16.0997 35.1995V33.8595C16.0997 33.5095 15.9497 33.1695 15.6997 32.9095ZM15.1197 35.2195C15.1197 35.3195 15.0797 35.4095 15.0097 35.4795C14.9397 35.5495 14.8497 35.5795 14.7497 35.5795H12.7997L11.8097 35.5695C11.7097 35.5695 11.6197 35.5295 11.5497 35.4595C11.4797 35.3895 11.4397 35.2995 11.4397 35.1995V33.8595C11.4397 33.7695 11.4797 33.6695 11.5497 33.5995C11.6197 33.5395 11.7097 33.4995 11.8097 33.4995H14.7497C14.8497 33.4995 14.9397 33.5395 15.0097 33.5995C15.0797 33.6695 15.1197 33.7695 15.1197 33.8595V35.2195Z"
            fill="#008753"
          />
          <path d="M38.38 16.1699H35.21V21.9299H38.38V16.1699Z" fill="#76D3AB" />
          <path
            d="M38.7297 15.8197C38.6297 15.7297 38.5097 15.6797 38.3797 15.6797H35.2097C35.0797 15.6797 34.9597 15.7297 34.8697 15.8197C34.7697 15.9197 34.7197 16.0397 34.7197 16.1697V21.9197C34.7197 22.0497 34.7697 22.1797 34.8697 22.2697C34.9597 22.3597 35.0797 22.4097 35.2097 22.4097H38.3797C38.5097 22.4097 38.6297 22.3597 38.7297 22.2697C38.8197 22.1797 38.8697 22.0497 38.8697 21.9197V16.1697C38.8697 16.0397 38.8197 15.9197 38.7297 15.8197ZM37.8897 21.4397H35.6997V16.6597H37.8897V21.4397Z"
            fill="#008753"
          />
          <path d="M38.38 28.5498H35.21V34.3098H38.38V28.5498Z" fill="#ABEDD3" />
          <path
            d="M38.7297 28.1996C38.6297 28.1096 38.5097 28.0596 38.3797 28.0596H35.2097C35.0797 28.0596 34.9597 28.1096 34.8697 28.1996C34.7697 28.2996 34.7197 28.4196 34.7197 28.5496V34.3096C34.7197 34.4396 34.7697 34.5596 34.8697 34.6496C34.9597 34.7396 35.0797 34.7896 35.2097 34.7896H38.3797C38.5097 34.7896 38.6297 34.7396 38.7297 34.6496C38.8197 34.5596 38.8697 34.4396 38.8697 34.3096V28.5496C38.8697 28.4196 38.8197 28.2996 38.7297 28.1996ZM37.8897 33.8196H35.6997V29.0396H37.8897V33.8196Z"
            fill="#008753"
          />
          <path d="M37.8902 29.04H35.7002V33.82H37.8902V29.04Z" fill="#76D3AB" />
          <path
            d="M28.9905 19.5399C28.5705 18.5499 27.8705 17.6899 26.9805 17.0899C26.0805 16.4899 25.0205 16.1699 23.9405 16.1699C22.4905 16.1699 21.1005 16.7499 20.0805 17.7699C19.0505 18.7999 18.4805 20.1899 18.4805 21.6299C18.4805 22.7199 18.8005 23.7699 19.4005 24.6699C20.0005 25.5699 20.8505 26.2699 21.8505 26.6799C22.8505 27.0999 23.9505 27.1999 25.0105 26.9899C26.0705 26.7799 27.0405 26.2599 27.8005 25.4999C28.5605 24.7399 29.0905 23.7599 29.3005 22.6999C29.5105 21.6399 29.4005 20.5399 28.9905 19.5399ZM28.0905 23.3499C27.7505 24.1699 27.1705 24.8699 26.4305 25.3699C25.7005 25.8599 24.8305 26.1199 23.9405 26.1199C22.7505 26.1199 21.6105 25.6499 20.7705 24.8099C19.9305 23.9699 19.4505 22.8199 19.4505 21.6299C19.4505 20.7499 19.7205 19.8799 20.2105 19.1399C20.7005 18.3999 21.4005 17.8299 22.2205 17.4899C23.0405 17.1499 23.9505 17.0599 24.8205 17.2299C25.6905 17.4099 26.4905 17.8299 27.1105 18.4599C27.7405 19.0899 28.1705 19.8899 28.3405 20.7599C28.5105 21.6299 28.4305 22.5299 28.0905 23.3499Z"
            fill="#008753"
          />
          <path
            d="M28.3402 20.7602C28.1702 19.8902 27.7402 19.0902 27.1102 18.4602C26.4902 17.8302 25.6902 17.4102 24.8202 17.2302C23.9502 17.0602 23.0402 17.1502 22.2202 17.4902C21.4002 17.8302 20.7002 18.4002 20.2102 19.1402C19.7202 19.8802 19.4502 20.7502 19.4502 21.6302C19.4502 22.8202 19.9302 23.9702 20.7702 24.8102C21.6102 25.6502 22.7502 26.1202 23.9402 26.1202C24.8302 26.1202 25.7002 25.8602 26.4302 25.3702C27.1702 24.8702 27.7502 24.1702 28.0902 23.3502C28.4302 22.5302 28.5102 21.6302 28.3402 20.7602ZM26.7002 20.4002L23.3702 23.5802C23.2802 23.6702 23.1602 23.7102 23.0302 23.7102H23.0102C22.9402 23.7102 22.8702 23.6902 22.8102 23.6602C22.7502 23.6302 22.7002 23.5902 22.6602 23.5402L21.1402 21.7302C21.0502 21.6302 21.0102 21.5002 21.0302 21.3702C21.0402 21.2402 21.1002 21.1202 21.2002 21.0402C21.3002 20.9602 21.4202 20.9202 21.5502 20.9302C21.6802 20.9402 21.8002 21.0002 21.8802 21.1002L22.0202 21.2702L23.0702 22.5102L25.3602 20.3202L26.0202 19.6902C26.1202 19.6102 26.2402 19.5602 26.3702 19.5602C26.5002 19.5702 26.6202 19.6202 26.7102 19.7102C26.8002 19.8102 26.8402 19.9302 26.8402 20.0602C26.8402 20.1902 26.7902 20.3102 26.7002 20.4002Z"
            fill="#008753"
          />
          <path
            d="M26.8404 20.0596C26.8404 20.1896 26.7904 20.3096 26.7004 20.3996L23.3704 23.5796C23.2804 23.6696 23.1604 23.7096 23.0304 23.7096H23.0104C22.9404 23.7096 22.8704 23.6896 22.8104 23.6596C22.7504 23.6296 22.7004 23.5896 22.6604 23.5396L21.1404 21.7296C21.0504 21.6296 21.0104 21.4996 21.0304 21.3696C21.0404 21.2396 21.1004 21.1196 21.2004 21.0396C21.3004 20.9596 21.4204 20.9196 21.5504 20.9296C21.6804 20.9396 21.8004 20.9996 21.8804 21.0996L22.0204 21.2696L23.0704 22.5096L25.3604 20.3196L26.0204 19.6896C26.1204 19.6096 26.2404 19.5596 26.3704 19.5596C26.5004 19.5696 26.6204 19.6196 26.7104 19.7096C26.8004 19.8096 26.8404 19.9296 26.8404 20.0596Z"
            fill="white"
          />
          <path
            d="M31.2006 30.5894C31.2006 30.6594 31.1906 30.7194 31.1706 30.7794C31.1406 30.8394 31.1006 30.8894 31.0606 30.9394C31.0106 30.9794 30.9606 31.0194 30.9006 31.0494C30.8406 31.0694 30.7806 31.0794 30.7106 31.0794H22.8806C22.7506 31.0794 22.6306 31.0294 22.5406 30.9394C22.4406 30.8494 22.3906 30.7194 22.3906 30.5894C22.3906 30.4694 22.4406 30.3394 22.5406 30.2494C22.6306 30.1594 22.7506 30.1094 22.8806 30.1094H30.7106C30.7806 30.1094 30.8406 30.1194 30.9006 30.1394C30.9606 30.1694 31.0106 30.1994 31.0606 30.2494C31.1006 30.2894 31.1406 30.3494 31.1706 30.4094C31.1906 30.4694 31.2006 30.5294 31.2006 30.5894Z"
            fill="#008753"
          />
          <path
            d="M28.4105 34.1196C28.4105 34.2496 28.3605 34.3797 28.2705 34.4697C28.1805 34.5597 28.0505 34.6096 27.9205 34.6096H24.0105C23.8805 34.6096 23.7605 34.5597 23.6705 34.4697C23.5705 34.3797 23.5205 34.2496 23.5205 34.1196C23.5205 33.9896 23.5705 33.8696 23.6705 33.7796C23.7605 33.6896 23.8805 33.6396 24.0105 33.6396H27.9205C28.0505 33.6396 28.1805 33.6896 28.2705 33.7796C28.3605 33.8696 28.4105 33.9896 28.4105 34.1196Z"
            fill="#008753"
          />
          <path
            d="M21.3403 30.2697C21.1803 29.8897 20.9103 29.5597 20.5603 29.3297C20.2103 29.0997 19.8003 28.9697 19.3903 28.9697C18.8303 28.9697 18.2903 29.1897 17.9003 29.5897C17.5003 29.9797 17.2803 30.5197 17.2803 31.0797C17.2803 31.4997 17.4003 31.8997 17.6403 32.2497C17.8703 32.5997 18.2003 32.8697 18.5803 33.0297C18.9703 33.1897 19.3903 33.2297 19.8003 33.1497C20.2103 33.0597 20.5803 32.8597 20.8803 32.5697C21.1703 32.2697 21.3703 31.8997 21.4503 31.4897C21.5403 31.0797 21.4903 30.6597 21.3403 30.2697ZM20.4303 31.5097C20.3503 31.7197 20.2003 31.8997 20.0203 32.0197C19.8303 32.1397 19.6103 32.2097 19.3903 32.2097C19.0903 32.2097 18.8003 32.0897 18.5903 31.8797C18.3803 31.6697 18.2603 31.3797 18.2603 31.0797C18.2603 30.8597 18.3203 30.6397 18.4503 30.4497C18.5703 30.2597 18.7503 30.1197 18.9603 30.0297C19.1603 29.9497 19.3903 29.9297 19.6103 29.9697C19.8303 30.0097 20.0303 30.1197 20.1903 30.2797C20.3503 30.4397 20.4503 30.6397 20.5003 30.8597C20.5403 31.0797 20.5203 31.3097 20.4303 31.5097Z"
            fill="#008753"
          />
        </g>
        <g clipPath="url(#clip2_10437_119233)">
          <path
            d="M16.7301 31.3396C16.4901 30.9896 16.3701 30.5896 16.3701 30.1696C16.3701 29.6096 16.5901 29.0696 16.9901 28.6796C17.3801 28.2796 17.9201 28.0596 18.4801 28.0596C18.8901 28.0596 19.3001 28.1896 19.6501 28.4196C20.0001 28.6496 20.2701 28.9796 20.4301 29.3596C20.5801 29.7496 20.6301 30.1696 20.5401 30.5796C20.4601 30.9896 20.2601 31.3596 19.9701 31.6596C19.6701 31.9496 19.3001 32.1496 18.8901 32.2396C18.4801 32.3196 18.0601 32.2796 17.6701 32.1196C17.2901 31.9596 16.9601 31.6896 16.7301 31.3396Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M26.2598 33.2504C26.3498 33.3404 26.3998 33.4604 26.3998 33.5904C26.3998 33.7204 26.3498 33.8503 26.2598 33.9403C26.1698 34.0303 26.0398 34.0804 25.9098 34.0804H21.9998C21.8698 34.0804 21.7498 34.0303 21.6598 33.9403C21.5598 33.8503 21.5098 33.7204 21.5098 33.5904C21.5098 33.4604 21.5598 33.3404 21.6598 33.2504C21.7498 33.1604 21.8698 33.1104 21.9998 33.1104H25.9098C26.0398 33.1104 26.1698 33.1604 26.2598 33.2504Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M26.7798 19.2203C26.8698 19.3203 26.9098 19.4403 26.9098 19.5703C26.9098 19.7003 26.8598 19.8203 26.7698 19.9103L23.4398 23.0903C23.3498 23.1803 23.2298 23.2203 23.0998 23.2203H23.0798C23.0098 23.2203 22.9398 23.2003 22.8798 23.1703C22.8198 23.1403 22.7698 23.1003 22.7298 23.0503L21.2098 21.2403C21.1198 21.1403 21.0798 21.0103 21.0998 20.8803C21.1098 20.7503 21.1698 20.6303 21.2698 20.5503C21.3698 20.4703 21.4898 20.4303 21.6198 20.4403C21.7498 20.4503 21.8698 20.5103 21.9498 20.6103L22.0898 20.7803L23.1398 22.0203L25.4298 19.8303L26.0898 19.2003C26.1898 19.1203 26.3098 19.0703 26.4398 19.0703C26.5698 19.0803 26.6898 19.1303 26.7798 19.2203Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M29.0598 19.0497C28.6398 18.0597 27.9398 17.1997 27.0498 16.5997C26.1498 15.9997 25.0898 15.6797 24.0098 15.6797C22.5598 15.6797 21.1698 16.2597 20.1498 17.2797C19.1198 18.3097 18.5498 19.6997 18.5498 21.1397C18.5498 22.2297 18.8698 23.2797 19.4698 24.1797C20.0698 25.0797 20.9198 25.7797 21.9198 26.1897C22.9198 26.6097 24.0198 26.7097 25.0798 26.4997C26.1398 26.2897 27.1098 25.7697 27.8698 25.0097C28.6298 24.2497 29.1598 23.2697 29.3698 22.2097C29.5798 21.1497 29.4698 20.0497 29.0598 19.0497ZM28.1598 22.8597C27.8198 23.6797 27.2398 24.3797 26.4998 24.8797C25.7698 25.3697 24.8998 25.6297 24.0098 25.6297C22.8198 25.6297 21.6798 25.1597 20.8398 24.3197C19.9998 23.4797 19.5198 22.3297 19.5198 21.1397C19.5198 20.2597 19.7898 19.3897 20.2798 18.6497C20.7698 17.9097 21.4698 17.3397 22.2898 16.9997C23.1098 16.6597 24.0198 16.5697 24.8898 16.7397C25.7598 16.9197 26.5598 17.3397 27.1798 17.9697C27.8098 18.5997 28.2398 19.3997 28.4098 20.2697C28.5798 21.1397 28.4998 22.0397 28.1598 22.8597Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M21.9998 29.8701H29.8298C29.8998 29.8701 29.9598 29.8801 30.0198 29.9001C30.0798 29.9301 30.1298 29.9601 30.1798 30.0101C30.2198 30.0501 30.2598 30.1101 30.2898 30.1701C30.3098 30.2301 30.3198 30.2901 30.3198 30.3501C30.3198 30.4201 30.3098 30.4801 30.2898 30.5401C30.2598 30.6001 30.2198 30.6501 30.1798 30.7001C30.1298 30.7401 30.0798 30.7801 30.0198 30.8101C29.9598 30.8301 29.8998 30.8401 29.8298 30.8401H21.9998C21.8698 30.8401 21.7498 30.7901 21.6598 30.7001C21.5598 30.6101 21.5098 30.4801 21.5098 30.3501C21.5098 30.2301 21.5598 30.1001 21.6598 30.0101C21.7498 29.9201 21.8698 29.8701 21.9998 29.8701Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M45 1.5H5C3.06 1.5 1.5 3.07 1.5 5V45C1.5 46.93 3.06 48.5 5 48.5H45C46.93 48.5 48.5 46.93 48.5 45V5C48.5 3.07 46.93 1.5 45 1.5ZM13.29 36.55H14.76C15.11 36.55 15.45 36.4 15.7 36.15C15.95 35.9 16.1 35.56 16.1 35.2V33.86C16.1 33.51 15.95 33.17 15.7 32.91C15.45 32.66 15.11 32.52 14.75 32.52H13.29V20.03H14.76C15.11 20.03 15.45 19.88 15.7 19.63C15.95 19.38 16.1 19.04 16.1 18.69V17.34C16.1 16.99 15.95 16.65 15.7 16.4C15.45 16.15 15.11 16 14.75 16H13.29V14.11C13.29 13.44 13.55 12.8 14.03 12.33C14.5 11.86 15.14 11.59 15.81 11.59H32.21C32.87 11.59 33.51 11.86 33.99 12.33C34.46 12.8 34.72 13.44 34.72 14.11V38.15C34.72 38.82 34.46 39.46 33.99 39.93C33.51 40.4 32.87 40.67 32.21 40.67H15.81C15.14 40.67 14.5 40.4 14.03 39.93C13.55 39.46 13.29 38.82 13.29 38.15V36.55Z"
            fill="#ABEDD3"
            stroke="#008753"
          />
          <path
            d="M20.4301 29.3596C20.5801 29.7496 20.6301 30.1696 20.5401 30.5796C20.4601 30.9896 20.2601 31.3596 19.9701 31.6596C19.6701 31.9496 19.3001 32.1496 18.8901 32.2396C18.4801 32.3196 18.0601 32.2796 17.6701 32.1196C17.2901 31.9596 16.9601 31.6896 16.7301 31.3396C16.4901 30.9896 16.3701 30.5896 16.3701 30.1696C16.3701 29.6096 16.5901 29.0696 16.9901 28.6796C17.3801 28.2796 17.9201 28.0596 18.4801 28.0596C18.8901 28.0596 19.3001 28.1896 19.6501 28.4196C20.0001 28.6496 20.2701 28.9796 20.4301 29.3596Z"
            fill="#ABEDD3"
          />
          <path
            d="M26.2598 33.2504C26.3498 33.3404 26.3998 33.4604 26.3998 33.5904C26.3998 33.7204 26.3498 33.8503 26.2598 33.9403C26.1698 34.0303 26.0398 34.0804 25.9098 34.0804H21.9998C21.8698 34.0804 21.7498 34.0303 21.6598 33.9403C21.5598 33.8503 21.5098 33.7204 21.5098 33.5904C21.5098 33.4604 21.5598 33.3404 21.6598 33.2504C21.7498 33.1604 21.8698 33.1104 21.9998 33.1104H25.9098C26.0398 33.1104 26.1698 33.1604 26.2598 33.2504Z"
            fill="#ABEDD3"
          />
          <path
            d="M26.7798 19.2203C26.8698 19.3203 26.9098 19.4403 26.9098 19.5703C26.9098 19.7003 26.8598 19.8203 26.7698 19.9103L23.4398 23.0903C23.3498 23.1803 23.2298 23.2203 23.0998 23.2203H23.0798C23.0098 23.2203 22.9398 23.2003 22.8798 23.1703C22.8198 23.1403 22.7698 23.1003 22.7298 23.0503L21.2098 21.2403C21.1198 21.1403 21.0798 21.0103 21.0998 20.8803C21.1098 20.7503 21.1698 20.6303 21.2698 20.5503C21.3698 20.4703 21.4898 20.4303 21.6198 20.4403C21.7498 20.4503 21.8698 20.5103 21.9498 20.6103L22.0898 20.7803L23.1398 22.0203L25.4298 19.8303L26.0898 19.2003C26.1898 19.1203 26.3098 19.0703 26.4398 19.0703C26.5698 19.0803 26.6898 19.1303 26.7798 19.2203Z"
            fill="#ABEDD3"
          />
          <path
            d="M29.0598 19.0497C28.6398 18.0597 27.9398 17.1997 27.0498 16.5997C26.1498 15.9997 25.0898 15.6797 24.0098 15.6797C22.5598 15.6797 21.1698 16.2597 20.1498 17.2797C19.1198 18.3097 18.5498 19.6997 18.5498 21.1397C18.5498 22.2297 18.8698 23.2797 19.4698 24.1797C20.0698 25.0797 20.9198 25.7797 21.9198 26.1897C22.9198 26.6097 24.0198 26.7097 25.0798 26.4997C26.1398 26.2897 27.1098 25.7697 27.8698 25.0097C28.6298 24.2497 29.1598 23.2697 29.3698 22.2097C29.5798 21.1497 29.4698 20.0497 29.0598 19.0497ZM28.1598 22.8597C27.8198 23.6797 27.2398 24.3797 26.4998 24.8797C25.7698 25.3697 24.8998 25.6297 24.0098 25.6297C22.8198 25.6297 21.6798 25.1597 20.8398 24.3197C19.9998 23.4797 19.5198 22.3297 19.5198 21.1397C19.5198 20.2597 19.7898 19.3897 20.2798 18.6497C20.7698 17.9097 21.4698 17.3397 22.2898 16.9997C23.1098 16.6597 24.0198 16.5697 24.8898 16.7397C25.7598 16.9197 26.5598 17.3397 27.1798 17.9697C27.8098 18.5997 28.2398 19.3997 28.4098 20.2697C28.5798 21.1397 28.4998 22.0397 28.1598 22.8597Z"
            fill="#ABEDD3"
          />
          <path
            d="M30.2898 30.1701C30.3098 30.2301 30.3198 30.2901 30.3198 30.3501C30.3198 30.4201 30.3098 30.4801 30.2898 30.5401C30.2598 30.6001 30.2198 30.6501 30.1798 30.7001C30.1298 30.7401 30.0798 30.7801 30.0198 30.8101C29.9598 30.8301 29.8998 30.8401 29.8298 30.8401H21.9998C21.8698 30.8401 21.7498 30.7901 21.6598 30.7001C21.5598 30.6101 21.5098 30.4801 21.5098 30.3501C21.5098 30.2301 21.5598 30.1001 21.6598 30.0101C21.7498 29.9201 21.8698 29.8701 21.9998 29.8701H29.8298C29.8998 29.8701 29.9598 29.8801 30.0198 29.9001C30.0798 29.9301 30.1298 29.9601 30.1798 30.0101C30.2198 30.0501 30.2598 30.1101 30.2898 30.1701Z"
            fill="#ABEDD3"
          />
          <path
            d="M31.4498 11.1104H16.5598C14.4798 11.1104 12.7998 12.7904 12.7998 14.8604V37.4004C12.7998 39.4704 14.4798 41.1504 16.5598 41.1504H31.4498C33.5298 41.1504 35.2098 39.4704 35.2098 37.4004V14.8604C35.2098 12.7904 33.5298 11.1104 31.4498 11.1104ZM34.7198 38.1504C34.7198 38.8204 34.4598 39.4604 33.9898 39.9304C33.5098 40.4004 32.8698 40.6703 32.2098 40.6703H15.8098C15.1398 40.6703 14.4998 40.4004 14.0298 39.9304C13.5498 39.4604 13.2898 38.8204 13.2898 38.1504V36.5504H14.7598C15.1098 36.5504 15.4498 36.4004 15.6998 36.1504C15.9498 35.9004 16.0998 35.5604 16.0998 35.2004V33.8604C16.0998 33.5104 15.9498 33.1704 15.6998 32.9104C15.4498 32.6604 15.1098 32.5204 14.7498 32.5204H13.2898V20.0304H14.7598C15.1098 20.0304 15.4498 19.8804 15.6998 19.6304C15.9498 19.3804 16.0998 19.0404 16.0998 18.6904V17.3404C16.0998 16.9904 15.9498 16.6504 15.6998 16.4004C15.4498 16.1504 15.1098 16.0004 14.7498 16.0004H13.2898V14.1104C13.2898 13.4404 13.5498 12.8004 14.0298 12.3304C14.4998 11.8604 15.1398 11.5904 15.8098 11.5904H32.2098C32.8698 11.5904 33.5098 11.8604 33.9898 12.3304C34.4598 12.8004 34.7198 13.4404 34.7198 14.1104V38.1504Z"
            fill="#ABEDD3"
          />
          <path
            d="M34.6696 11.6401C34.0196 10.9901 33.1296 10.6201 32.2096 10.6201H15.8096C14.8796 10.6201 13.9896 10.9901 13.3396 11.6401C12.6796 12.3001 12.3096 13.1801 12.3096 14.1101V38.1501C12.3096 39.0801 12.6796 39.9601 13.3396 40.6201C13.9896 41.2701 14.8796 41.6401 15.8096 41.6401H32.2096C33.1296 41.6401 34.0196 41.2701 34.6696 40.6201C35.3296 39.9601 35.6996 39.0801 35.6996 38.1501V14.1101C35.6996 13.1801 35.3296 12.3001 34.6696 11.6401ZM32.2096 40.6701H15.8096C15.1396 40.6701 14.4996 40.4001 14.0296 39.9301C13.5496 39.4601 13.2896 38.8201 13.2896 38.1501V14.1101C13.2896 13.4401 13.5496 12.8001 14.0296 12.3301C14.4996 11.8601 15.1396 11.5901 15.8096 11.5901H32.2096C32.8696 11.5901 33.5096 11.8601 33.9896 12.3301C34.4596 12.8001 34.7196 13.4401 34.7196 14.1101V38.1501C34.7196 38.8201 34.4596 39.4601 33.9896 39.9301C33.5096 40.4001 32.8696 40.6701 32.2096 40.6701Z"
            fill="#008753"
          />
          <path
            d="M26.7798 19.2203C26.8698 19.3203 26.9098 19.4403 26.9098 19.5703C26.9098 19.7003 26.8598 19.8203 26.7698 19.9103L23.4398 23.0903C23.3498 23.1803 23.2298 23.2203 23.0998 23.2203H23.0798C23.0098 23.2203 22.9398 23.2003 22.8798 23.1703C22.8198 23.1403 22.7698 23.1003 22.7298 23.0503L21.2098 21.2403C21.1198 21.1403 21.0798 21.0103 21.0998 20.8803C21.1098 20.7503 21.1698 20.6303 21.2698 20.5503C21.3698 20.4703 21.4898 20.4303 21.6198 20.4403C21.7498 20.4503 21.8698 20.5103 21.9498 20.6103L22.0898 20.7803L23.1398 22.0203L25.4298 19.8303L26.0898 19.2003C26.1898 19.1203 26.3098 19.0703 26.4398 19.0703C26.5698 19.0803 26.6898 19.1303 26.7798 19.2203Z"
            fill="#ABEDD3"
          />
          <path
            d="M24.0103 16.1699C21.2603 16.1699 19.0303 18.3999 19.0303 21.1399C19.0303 23.8799 21.2603 26.1199 24.0103 26.1199C26.7603 26.1199 28.9903 23.8899 28.9903 21.1399C28.9903 18.3899 26.7603 16.1699 24.0103 16.1699ZM26.5003 24.8799C25.7703 25.3699 24.9003 25.6299 24.0103 25.6299C22.8203 25.6299 21.6803 25.1599 20.8403 24.3199C20.0003 23.4799 19.5203 22.3299 19.5203 21.1399C19.5203 20.2599 19.7903 19.3899 20.2803 18.6499C20.7703 17.9099 21.4703 17.3399 22.2903 16.9999C23.1103 16.6599 24.0203 16.5699 24.8903 16.7399C25.7603 16.9199 26.5603 17.3399 27.1803 17.9699C27.8103 18.5999 28.2403 19.3999 28.4103 20.2699C28.5803 21.1399 28.5003 22.0399 28.1603 22.8599C27.8203 23.6799 27.2403 24.3799 26.5003 24.8799Z"
            fill="#ABEDD3"
          />
          <path
            d="M29.0598 19.0497C28.6398 18.0597 27.9398 17.1997 27.0498 16.5997C26.1498 15.9997 25.0898 15.6797 24.0098 15.6797C22.5598 15.6797 21.1698 16.2597 20.1498 17.2797C19.1198 18.3097 18.5498 19.6997 18.5498 21.1397C18.5498 22.2297 18.8698 23.2797 19.4698 24.1797C20.0698 25.0797 20.9198 25.7797 21.9198 26.1897C22.9198 26.6097 24.0198 26.7097 25.0798 26.4997C26.1398 26.2897 27.1098 25.7697 27.8698 25.0097C28.6298 24.2497 29.1598 23.2697 29.3698 22.2097C29.5798 21.1497 29.4698 20.0497 29.0598 19.0497ZM28.1598 22.8597C27.8198 23.6797 27.2398 24.3797 26.4998 24.8797C25.7698 25.3697 24.8998 25.6297 24.0098 25.6297C22.8198 25.6297 21.6798 25.1597 20.8398 24.3197C19.9998 23.4797 19.5198 22.3297 19.5198 21.1397C19.5198 20.2597 19.7898 19.3897 20.2798 18.6497C20.7698 17.9097 21.4698 17.3397 22.2898 16.9997C23.1098 16.6597 24.0198 16.5697 24.8898 16.7397C25.7598 16.9197 26.5598 17.3397 27.1798 17.9697C27.8098 18.5997 28.2398 19.3997 28.4098 20.2697C28.5798 21.1397 28.4998 22.0397 28.1598 22.8597Z"
            fill="#008753"
          />
          <path
            d="M18.4804 31.7898C19.3704 31.7898 20.1004 31.0598 20.1004 30.1698C20.1004 29.2798 19.3704 28.5498 18.4804 28.5498C17.5904 28.5498 16.8604 29.2798 16.8604 30.1698C16.8604 31.0598 17.5904 31.7898 18.4804 31.7898Z"
            fill="#ABEDD3"
          />
          <path
            d="M26.4301 19.5498L23.1001 22.7298L21.5801 20.9198L22.0901 20.7798L23.1401 22.0198L25.4301 19.8298L26.4301 19.5498Z"
            fill="#ABEDD3"
          />
          <path
            d="M34.72 14.1098V38.1498C34.72 38.8198 34.46 39.4598 33.99 39.9298C33.51 40.3998 32.87 40.6698 32.21 40.6698H15.81C15.14 40.6698 14.5 40.3998 14.03 39.9298C13.55 39.4598 13.29 38.8198 13.29 38.1498V36.5498H14.76C15.11 36.5498 15.45 36.3998 15.7 36.1498C15.95 35.8998 16.1 35.5598 16.1 35.1998V33.8598C16.1 33.5098 15.95 33.1698 15.7 32.9098C15.45 32.6598 15.11 32.5198 14.75 32.5198H13.29V20.0298H14.76C15.11 20.0298 15.45 19.8798 15.7 19.6298C15.95 19.3798 16.1 19.0398 16.1 18.6898V17.3398C16.1 16.9898 15.95 16.6498 15.7 16.3998C15.45 16.1498 15.11 15.9998 14.75 15.9998H13.29V14.1098C13.29 13.4398 13.55 12.7998 14.03 12.3298C14.5 11.8598 15.14 11.5898 15.81 11.5898H32.21C32.87 11.5898 33.51 11.8598 33.99 12.3298C34.46 12.7998 34.72 13.4398 34.72 14.1098Z"
            fill="#66D8A9"
          />
          <path
            d="M14.54 16.4902H12.03C11.44 16.4902 10.96 16.9702 10.96 17.5602V18.4802C10.96 19.0702 11.44 19.5502 12.03 19.5502H14.54C15.13 19.5502 15.61 19.0702 15.61 18.4802V17.5602C15.61 16.9702 15.13 16.4902 14.54 16.4902Z"
            fill="#ABEDD3"
          />
          <path
            d="M15.6997 16.4C15.4497 16.15 15.1097 16 14.7497 16H11.8097C11.4497 16.01 11.1097 16.15 10.8597 16.4C10.6097 16.65 10.4697 16.99 10.4697 17.34V18.69C10.4697 19.04 10.6097 19.38 10.8597 19.63C11.1097 19.88 11.4497 20.03 11.8097 20.03H14.7597C15.1097 20.03 15.4497 19.88 15.6997 19.63C15.9497 19.38 16.0997 19.04 16.0997 18.69V17.34C16.0997 16.99 15.9497 16.65 15.6997 16.4ZM15.1197 18.7C15.1197 18.8 15.0797 18.89 15.0097 18.96C14.9397 19.03 14.8497 19.07 14.7497 19.07H13.2897L12.7997 19.06H12.3097L11.8097 19.05C11.7097 19.05 11.6197 19.01 11.5497 18.94C11.4797 18.88 11.4397 18.78 11.4397 18.69V17.34C11.4397 17.25 11.4797 17.15 11.5497 17.09C11.6197 17.02 11.7097 16.98 11.8097 16.98H14.7497C14.8497 16.98 14.9397 17.02 15.0097 17.09C15.0797 17.15 15.1197 17.25 15.1197 17.34V18.7Z"
            fill="#008753"
          />
          <path
            d="M14.54 33.0098H12.03C11.44 33.0098 10.96 33.4898 10.96 34.0798V34.9998C10.96 35.5898 11.44 36.0698 12.03 36.0698H14.54C15.13 36.0698 15.61 35.5898 15.61 34.9998V34.0798C15.61 33.4898 15.13 33.0098 14.54 33.0098Z"
            fill="#ABEDD3"
          />
          <path
            d="M15.6997 32.9095C15.4497 32.6595 15.1097 32.5195 14.7497 32.5195H11.8097C11.4497 32.5195 11.1097 32.6595 10.8597 32.9195C10.6097 33.1695 10.4697 33.5095 10.4697 33.8595V35.1995C10.4697 35.5595 10.6097 35.8995 10.8597 36.1495C11.1097 36.3995 11.4497 36.5395 11.8097 36.5495H14.7597C15.1097 36.5495 15.4497 36.3995 15.6997 36.1495C15.9497 35.8995 16.0997 35.5595 16.0997 35.1995V33.8595C16.0997 33.5095 15.9497 33.1695 15.6997 32.9095ZM15.1197 35.2195C15.1197 35.3195 15.0797 35.4095 15.0097 35.4795C14.9397 35.5495 14.8497 35.5795 14.7497 35.5795H12.7997L11.8097 35.5695C11.7097 35.5695 11.6197 35.5295 11.5497 35.4595C11.4797 35.3895 11.4397 35.2995 11.4397 35.1995V33.8595C11.4397 33.7695 11.4797 33.6695 11.5497 33.5995C11.6197 33.5395 11.7097 33.4995 11.8097 33.4995H14.7497C14.8497 33.4995 14.9397 33.5395 15.0097 33.5995C15.0797 33.6695 15.1197 33.7695 15.1197 33.8595V35.2195Z"
            fill="#008753"
          />
          <path d="M38.38 16.1699H35.21V21.9299H38.38V16.1699Z" fill="#76D3AB" />
          <path
            d="M38.7297 15.8197C38.6297 15.7297 38.5097 15.6797 38.3797 15.6797H35.2097C35.0797 15.6797 34.9597 15.7297 34.8697 15.8197C34.7697 15.9197 34.7197 16.0397 34.7197 16.1697V21.9197C34.7197 22.0497 34.7697 22.1797 34.8697 22.2697C34.9597 22.3597 35.0797 22.4097 35.2097 22.4097H38.3797C38.5097 22.4097 38.6297 22.3597 38.7297 22.2697C38.8197 22.1797 38.8697 22.0497 38.8697 21.9197V16.1697C38.8697 16.0397 38.8197 15.9197 38.7297 15.8197ZM37.8897 21.4397H35.6997V16.6597H37.8897V21.4397Z"
            fill="#008753"
          />
          <path d="M38.38 28.5498H35.21V34.3098H38.38V28.5498Z" fill="#ABEDD3" />
          <path
            d="M38.7297 28.1996C38.6297 28.1096 38.5097 28.0596 38.3797 28.0596H35.2097C35.0797 28.0596 34.9597 28.1096 34.8697 28.1996C34.7697 28.2996 34.7197 28.4196 34.7197 28.5496V34.3096C34.7197 34.4396 34.7697 34.5596 34.8697 34.6496C34.9597 34.7396 35.0797 34.7896 35.2097 34.7896H38.3797C38.5097 34.7896 38.6297 34.7396 38.7297 34.6496C38.8197 34.5596 38.8697 34.4396 38.8697 34.3096V28.5496C38.8697 28.4196 38.8197 28.2996 38.7297 28.1996ZM37.8897 33.8196H35.6997V29.0396H37.8897V33.8196Z"
            fill="#008753"
          />
          <path d="M37.8902 29.04H35.7002V33.82H37.8902V29.04Z" fill="#76D3AB" />
          <path
            d="M28.9905 19.5399C28.5705 18.5499 27.8705 17.6899 26.9805 17.0899C26.0805 16.4899 25.0205 16.1699 23.9405 16.1699C22.4905 16.1699 21.1005 16.7499 20.0805 17.7699C19.0505 18.7999 18.4805 20.1899 18.4805 21.6299C18.4805 22.7199 18.8005 23.7699 19.4005 24.6699C20.0005 25.5699 20.8505 26.2699 21.8505 26.6799C22.8505 27.0999 23.9505 27.1999 25.0105 26.9899C26.0705 26.7799 27.0405 26.2599 27.8005 25.4999C28.5605 24.7399 29.0905 23.7599 29.3005 22.6999C29.5105 21.6399 29.4005 20.5399 28.9905 19.5399ZM28.0905 23.3499C27.7505 24.1699 27.1705 24.8699 26.4305 25.3699C25.7005 25.8599 24.8305 26.1199 23.9405 26.1199C22.7505 26.1199 21.6105 25.6499 20.7705 24.8099C19.9305 23.9699 19.4505 22.8199 19.4505 21.6299C19.4505 20.7499 19.7205 19.8799 20.2105 19.1399C20.7005 18.3999 21.4005 17.8299 22.2205 17.4899C23.0405 17.1499 23.9505 17.0599 24.8205 17.2299C25.6905 17.4099 26.4905 17.8299 27.1105 18.4599C27.7405 19.0899 28.1705 19.8899 28.3405 20.7599C28.5105 21.6299 28.4305 22.5299 28.0905 23.3499Z"
            fill="#008753"
          />
          <path
            d="M28.3402 20.7602C28.1702 19.8902 27.7402 19.0902 27.1102 18.4602C26.4902 17.8302 25.6902 17.4102 24.8202 17.2302C23.9502 17.0602 23.0402 17.1502 22.2202 17.4902C21.4002 17.8302 20.7002 18.4002 20.2102 19.1402C19.7202 19.8802 19.4502 20.7502 19.4502 21.6302C19.4502 22.8202 19.9302 23.9702 20.7702 24.8102C21.6102 25.6502 22.7502 26.1202 23.9402 26.1202C24.8302 26.1202 25.7002 25.8602 26.4302 25.3702C27.1702 24.8702 27.7502 24.1702 28.0902 23.3502C28.4302 22.5302 28.5102 21.6302 28.3402 20.7602ZM26.7002 20.4002L23.3702 23.5802C23.2802 23.6702 23.1602 23.7102 23.0302 23.7102H23.0102C22.9402 23.7102 22.8702 23.6902 22.8102 23.6602C22.7502 23.6302 22.7002 23.5902 22.6602 23.5402L21.1402 21.7302C21.0502 21.6302 21.0102 21.5002 21.0302 21.3702C21.0402 21.2402 21.1002 21.1202 21.2002 21.0402C21.3002 20.9602 21.4202 20.9202 21.5502 20.9302C21.6802 20.9402 21.8002 21.0002 21.8802 21.1002L22.0202 21.2702L23.0702 22.5102L25.3602 20.3202L26.0202 19.6902C26.1202 19.6102 26.2402 19.5602 26.3702 19.5602C26.5002 19.5702 26.6202 19.6202 26.7102 19.7102C26.8002 19.8102 26.8402 19.9302 26.8402 20.0602C26.8402 20.1902 26.7902 20.3102 26.7002 20.4002Z"
            fill="#008753"
          />
          <path
            d="M26.8404 20.0596C26.8404 20.1896 26.7904 20.3096 26.7004 20.3996L23.3704 23.5796C23.2804 23.6696 23.1604 23.7096 23.0304 23.7096H23.0104C22.9404 23.7096 22.8704 23.6896 22.8104 23.6596C22.7504 23.6296 22.7004 23.5896 22.6604 23.5396L21.1404 21.7296C21.0504 21.6296 21.0104 21.4996 21.0304 21.3696C21.0404 21.2396 21.1004 21.1196 21.2004 21.0396C21.3004 20.9596 21.4204 20.9196 21.5504 20.9296C21.6804 20.9396 21.8004 20.9996 21.8804 21.0996L22.0204 21.2696L23.0704 22.5096L25.3604 20.3196L26.0204 19.6896C26.1204 19.6096 26.2404 19.5596 26.3704 19.5596C26.5004 19.5696 26.6204 19.6196 26.7104 19.7096C26.8004 19.8096 26.8404 19.9296 26.8404 20.0596Z"
            fill="#66D8A9"
          />
          <path
            d="M31.2006 30.5894C31.2006 30.6594 31.1906 30.7194 31.1706 30.7794C31.1406 30.8394 31.1006 30.8894 31.0606 30.9394C31.0106 30.9794 30.9606 31.0194 30.9006 31.0494C30.8406 31.0694 30.7806 31.0794 30.7106 31.0794H22.8806C22.7506 31.0794 22.6306 31.0294 22.5406 30.9394C22.4406 30.8494 22.3906 30.7194 22.3906 30.5894C22.3906 30.4694 22.4406 30.3394 22.5406 30.2494C22.6306 30.1594 22.7506 30.1094 22.8806 30.1094H30.7106C30.7806 30.1094 30.8406 30.1194 30.9006 30.1394C30.9606 30.1694 31.0106 30.1994 31.0606 30.2494C31.1006 30.2894 31.1406 30.3494 31.1706 30.4094C31.1906 30.4694 31.2006 30.5294 31.2006 30.5894Z"
            fill="#008753"
          />
          <path
            d="M28.4105 34.1196C28.4105 34.2496 28.3605 34.3797 28.2705 34.4697C28.1805 34.5597 28.0505 34.6096 27.9205 34.6096H24.0105C23.8805 34.6096 23.7605 34.5597 23.6705 34.4697C23.5705 34.3797 23.5205 34.2496 23.5205 34.1196C23.5205 33.9896 23.5705 33.8696 23.6705 33.7796C23.7605 33.6896 23.8805 33.6396 24.0105 33.6396H27.9205C28.0505 33.6396 28.1805 33.6896 28.2705 33.7796C28.3605 33.8696 28.4105 33.9896 28.4105 34.1196Z"
            fill="#008753"
          />
          <path
            d="M21.3403 30.2697C21.1803 29.8897 20.9103 29.5597 20.5603 29.3297C20.2103 29.0997 19.8003 28.9697 19.3903 28.9697C18.8303 28.9697 18.2903 29.1897 17.9003 29.5897C17.5003 29.9797 17.2803 30.5197 17.2803 31.0797C17.2803 31.4997 17.4003 31.8997 17.6403 32.2497C17.8703 32.5997 18.2003 32.8697 18.5803 33.0297C18.9703 33.1897 19.3903 33.2297 19.8003 33.1497C20.2103 33.0597 20.5803 32.8597 20.8803 32.5697C21.1703 32.2697 21.3703 31.8997 21.4503 31.4897C21.5403 31.0797 21.4903 30.6597 21.3403 30.2697ZM20.4303 31.5097C20.3503 31.7197 20.2003 31.8997 20.0203 32.0197C19.8303 32.1397 19.6103 32.2097 19.3903 32.2097C19.0903 32.2097 18.8003 32.0897 18.5903 31.8797C18.3803 31.6697 18.2603 31.3797 18.2603 31.0797C18.2603 30.8597 18.3203 30.6397 18.4503 30.4497C18.5703 30.2597 18.7503 30.1197 18.9603 30.0297C19.1603 29.9497 19.3903 29.9297 19.6103 29.9697C19.8303 30.0097 20.0303 30.1197 20.1903 30.2797C20.3503 30.4397 20.4503 30.6397 20.5003 30.8597C20.5403 31.0797 20.5203 31.3097 20.4303 31.5097Z"
            fill="#008753"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10437_119233">
          <rect width="50" height="50" fill="white" />
        </clipPath>
        <clipPath id="clip1_10437_119233">
          <rect width="48" height="48" fill="white" transform="translate(1 1)" />
        </clipPath>
        <clipPath id="clip2_10437_119233">
          <rect width="48" height="48" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};
