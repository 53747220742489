import React, { FC, useMemo } from "react";
import { StatsContainer } from "./StatsContainer";
import {
  Box,
  Text,
  useColors,
  getRGBAFromCSSVariable,
  ColorConfig,
} from "@prodoctivity/design-system";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import type { CollectionResume } from "@prodoctivity/types";
import { useAppTranslation } from "../../../hooks/useAppTranslation";

export type ResumeProps = {
  initialData: CollectionResume | undefined;
};
export const DocumentCollectionStatusPanel: FC<ResumeProps> = ({ initialData }) => {
  const panelData = useMemo((): CollectionResume => {
    if (!initialData) {
      return {
        valid: 0,
        expired: 0,
        expiring: 0,
        issue: 0,
        total: 0,
      };
    } else {
      return initialData;
    }
  }, [initialData]);

  const { resources } = useAppTranslation();

  return (
    <Box id="box-for-progress-bar" display="flex" direction="row" gap={6} flex="grow">
      <Box display="flex">
        <ProgressDocumentCollection
          title={resources.totalCollectionProgressTitle}
          value={panelData.valid}
          total={panelData.total}
          color={"rgb(4, 118, 231)"}
        />
        <ProgressDocumentCollection
          title={resources.totalMissingCollectionProgressTitle}
          value={panelData.issue}
          total={panelData.total}
          color={"rgb(213, 38, 38)"}
        />
      </Box>
      <Box display="flex">
        <ProgressDocumentCollection
          title={resources.totalExpiredCollectionProgressTitle}
          value={panelData.expired}
          total={panelData.total}
          color={"rgb(127, 74, 223)"}
        />
        <ProgressDocumentCollection
          title={resources.totalExpiringCollectionProgressTitle}
          value={panelData.expiring}
          total={panelData.total}
          color={"rgb(255, 219, 111)"}
        />
      </Box>
    </Box>
  );
};

export type Props = {
  title: string;
  value: number | undefined;
  color?: ColorConfig | string;
  total: number | undefined;
};

export const ProgressDocumentCollection: React.FC<Props> = ({ title, value, color, total }) => {
  const { colors } = useColors();

  const valueInPercent = useMemo(() => {
    if (!value) {
      return 0;
    }
    if (!total) {
      return 0;
    }
    return (value / total) * 100;
  }, [value, total]);

  const valuePercentString = value === total ? "100" : valueInPercent.toPrecision(2);
  const convertToNumericString = useMemo(() => {
    if (!value) {
      return "0 %";
    }
    return value.toLocaleString("en-us", { minimumFractionDigits: 0 });
  }, [value]);

  const renderTitle = () => {
    return (
      <Box id="myProgressBox" display="flex" direction="column" gap={2} width={"100%"}>
        <Box id="myProgressBox-2" display="flex" direction="row">
          <Text weight="bold" color={colors.neutral600} size="200">
            {title}
          </Text>
        </Box>
        <Box display="flex" direction="row" alignContent="center" alignItems="center" gap={20}>
          <Box>
            <Text color={colors.black900} weight="bold" size="600">
              {convertToNumericString}
            </Text>
          </Box>
          <Box>
            <Box width={"60px"}>
              <CircularProgressbar
                value={valueInPercent}
                text={`${valuePercentString} %`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.75,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "25px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: !color
                    ? `rgba(92, 152, 255, ${60 / 100})`
                    : typeof color === "string"
                    ? color
                    : getRGBAFromCSSVariable(color), //`rgba(92, 152, 255, ${60 / 100})`,
                  textColor: "#000",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" flex="grow">
      <StatsContainer title={renderTitle()}></StatsContainer>
    </Box>
  );
};
