import type { MyUserProfile } from "@prodoctivity/types";
import { useOrganizationQuery } from "./useOrganizationQuery";
import { useServices } from "./useServices";

export function useUserProfile(args?: { onSuccess?: (data: MyUserProfile) => void }) {
  const { getMyUserProfile, user } = useServices();
  return useOrganizationQuery(`${user?.username}/my-profile`, getMyUserProfile, {
    onSuccess: args ? args.onSuccess : undefined,
    refetchOnWindowFocus: false,
  });
}
