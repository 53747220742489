"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenderIsNegative = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var design_system_1 = require("@prodoctivity/design-system");
var react_1 = require("react");
var RenderIsNegative = function (props) {
    var i18n = props.translateFn, expression = props.expression, id = props.id, inputSelectNegativeChanged = props.inputSelectNegativeChanged;
    var options = (0, react_1.useMemo)(function () {
        return [
            {
                label: i18n("filterBuilder.is"),
                value: "0",
            },
            {
                label: i18n("filterBuilder.isNot"),
                value: "1",
            },
        ];
    }, [i18n]);
    return expression.sourceSummary && expression.sourceField ? ((0, jsx_runtime_1.jsx)(design_system_1.Box, { minWidth: 150, children: (0, jsx_runtime_1.jsx)(design_system_1.SelectList, { id: "".concat(id, "-negative"), value: expression.isNegative ? "1" : "0", "data-name": "isNegative", onChange: inputSelectNegativeChanged, options: options }) })) : null;
};
exports.RenderIsNegative = RenderIsNegative;
