import {
  Box,
  Divider,
  Image,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FC, FunctionComponent, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { LogoSvg } from "../../svg/LogoSvg";
import { FooterLink } from "./FooterLink";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { LoginLayoutFooterLarge } from "./LoginLayoutFooterLarge";
import { LoginLayoutFooterSmall } from "./LoginLayoutFooterSmall";

export interface Props {
  children?: React.ReactNode;
  removeBorder?: boolean;
}

const LoginLayout: FC<Props> = ({ children, removeBorder }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const navigate = useNavigate();
  const { breakpoint } = useDesignBreakpoint();

  const isMobile = breakpoint === "small" || breakpoint === "medium";
  const isDesktop = !isMobile;

  return (
    <Box display="flex" direction="column" flex="grow">
      <Box
        display="flex"
        direction={"column"}
        alignItems="center"
        justifyContent="center"
        flex="shrink"
      >
        <Box width={isMobile ? 400 : 300}>
          <TapArea onTap={() => navigate("/")}>
            <Box paddingY={isDesktop ? 8 : undefined}>
              <Box paddingX={9} paddingY={12}>
                <Image
                  color="transparent"
                  alt={resources.prodoctivityLogo}
                  naturalHeight={50}
                  naturalWidth={400}
                  src={logo}
                ></Image>
              </Box>
            </Box>
          </TapArea>
        </Box>
        <Box color={colors.white} borderRadius={removeBorder ? undefined : 12}>
          {children}
        </Box>
      </Box>
      <Box display="flex" direction="column" flex="grow" />
      {breakpoint !== "small" && breakpoint !== "medium" ? (
        <LoginLayoutFooter />
      ) : (
        <LoginLayoutFooterSmall />
      )}
    </Box>
  );
};

const LoginLayoutFooter: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const navigate = useNavigate();
  const { breakpoint } = useDesignBreakpoint();

  const goHome = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <>
      {breakpoint === "hd" && (
        <Box
          // position="fixed"
          // bottom={true}
          display="flex"
          direction="column"
          flex="shrink"
          width={"100%"}
          padding={2}
        >
          <Box display="flex" direction="column" alignItems="center" width="100%">
            <Divider direction="horizontal" />
            <Box display="flex" flex="grow" width={"100%"} padding={2}>
              <Box
                display="flex"
                direction="row"
                gap={2}
                alignItems="center"
                flex="grow"
                justifyContent="start"
              >
                <Box display="flex" flex="shrink">
                  <TapArea onTap={goHome}>
                    <LogoSvg />
                  </TapArea>
                </Box>
                <Box marginStart={12} margin={1} />
                <Box display="flex" flex="shrink" marginEnd={12}>
                  <Text color={colors.neutral900} size="200">
                    © {new Date().getFullYear()} {resources.allRightsReserved}
                  </Text>
                </Box>
                <Box display="flex" flex="grow">
                  <Box
                    display="flex"
                    direction="row"
                    justifyContent="between"
                    alignItems="center"
                    flex="grow"
                  >
                    <FooterLink />
                    <LanguageSwitcher />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {breakpoint === "large" && <LoginLayoutFooterLarge />}
    </>
  );
};

export { LoginLayout };
