import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DocIconsSvg: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 38,
  height = 38,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 38 38`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_3328_44332)">
        <path
          d="M19 0.5C29.2173 0.5 37.5 8.78273 37.5 19C37.5 29.2173 29.2173 37.5 19 37.5C8.78273 37.5 0.5 29.2173 0.5 19C0.5 8.78273 8.78273 0.5 19 0.5Z"
          stroke="#DFE0E1"
          fill={color ? calculateColor(color) : "white"}
        />
        <path
          d="M19.217 21.9775C18.4971 21.9775 18.0771 22.6624 18.0771 23.5794C18.0771 24.5022 18.51 25.1544 19.224 25.1544C19.9438 25.1544 20.3568 24.4684 20.3568 23.5514C20.3568 22.7044 19.9508 21.9775 19.217 21.9775V21.9775Z"
          fill={color ? calculateColor(color) : "#00185E"}
        />
        <path
          d="M21.3337 7.33301H12.0003C11.3815 7.33301 10.788 7.57884 10.3504 8.01643C9.91282 8.45401 9.66699 9.0475 9.66699 9.66634V28.333C9.66699 28.9518 9.91282 29.5453 10.3504 29.9829C10.788 30.4205 11.3815 30.6663 12.0003 30.6663H26.0003C26.6192 30.6663 27.2127 30.4205 27.6502 29.9829C28.0878 29.5453 28.3337 28.9518 28.3337 28.333V14.333L21.3337 7.33301ZM15.6812 25.3627C15.1842 25.7757 14.4293 25.9705 13.5065 25.9705C12.9523 25.9705 12.5615 25.9355 12.2955 25.9005V21.2677C12.7726 21.199 13.2543 21.1666 13.7363 21.1708C14.6323 21.1708 15.2133 21.3318 15.6672 21.6748C16.1572 22.0388 16.4652 22.6198 16.4652 23.4505C16.4652 24.3547 16.1362 24.9777 15.6812 25.3627V25.3627ZM19.1695 25.9997C17.7695 25.9997 16.9517 24.9427 16.9517 23.5987C16.9517 22.1858 17.8535 21.13 19.2465 21.13C20.6943 21.13 21.4853 22.2138 21.4853 23.5158C21.4842 25.0617 20.5462 25.9997 19.1695 25.9997V25.9997ZM24.6003 25.1107C24.9212 25.1107 25.2782 25.0395 25.4893 24.9567L25.6503 25.7885C25.4543 25.8865 25.0133 25.9915 24.4405 25.9915C22.8107 25.9915 21.9707 24.9777 21.9707 23.6337C21.9707 22.0248 23.1175 21.13 24.5455 21.13C25.0985 21.13 25.5173 21.242 25.7063 21.34L25.4893 22.187C25.2053 22.0696 24.9007 22.0101 24.5933 22.012C23.7463 22.012 23.0883 22.523 23.0883 23.573C23.0883 24.5168 23.6483 25.1107 24.6003 25.1107V25.1107ZM21.3337 15.4997H20.167V9.66634L26.0003 15.4997H21.3337Z"
          fill={color ? calculateColor(color) : "#00185E"}
        />
        <path
          d="M13.848 21.9902C13.6112 21.9902 13.4572 22.0112 13.3662 22.0322V25.1181C13.4572 25.1391 13.6042 25.1391 13.736 25.1391C14.702 25.1461 15.3309 24.6152 15.3309 23.4882C15.3379 22.5082 14.765 21.9902 13.848 21.9902V21.9902Z"
          fill={color ? calculateColor(color) : "#00185E"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3328_44332">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
