import { useCallback } from "react";
import { useServices } from "../../../hooks/useServices";
import { useMutation } from "@tanstack/react-query";

export const useWarningDeleteListLayer = (list: string | undefined) => {
  const { deleteAllDocumentsLists } = useServices();

  const deleteAllDocuments = useCallback(async () => {
    if (!list) {
      return;
    }
    return await deleteAllDocumentsLists(list);
  }, [deleteAllDocumentsLists, list]);

  const { mutate: mutateDeleteAllDocuments } = useMutation(deleteAllDocuments, {});
  return {
    mutateDeleteAllDocuments,
  };
};
