import "antd/lib/steps/style/css";
import "./Page.css";

import {
  Box,
  DatePickerProps,
  useColors,
  useFormController,
  type DataElementInputProps,
} from "@prodoctivity/design-system";
import { contextFieldToDataElementSync, getContextField } from "@prodoctivity/shared";
import type {
  TemplateContextDefinition,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import { useCallback, useMemo } from "react";
import {
  FormConnector,
  FormDefinition,
  FormValues,
  GroupValues,
  ProDoctivityFormLayout,
  SequenceBehavior,
  ValidationErrors,
  WebServiceConnector,
} from "../../_lib/types";

import { DesignBreakpointType } from "@prodoctivity/design-system";

import type momentType from "moment";
import { ProDoctivityFormGridLayout } from "../../ProDoctivityFormGridLayout";
import { wizardDefinitionToLayout } from "../../_lib/wizardDefinition-to-layout";
import { NavigationButtons } from "./NavigationButtons";

type Props = {
  showSinglePage: boolean;
  pagination?: {
    total: number;
    sectionKey: string;
    formHasErrors: boolean;
    filterFormDefinitionByGroups?: () => FormDefinition;
  };
  summaryMode: boolean;
  isDesignMode: boolean;
  readOnly: boolean;
  formDefinition: FormDefinition;
  contextDefinition: TemplateContextDefinition;
  wizardDefinition: TemplateWizardDefinition;
  connectors: WebServiceConnector[];
  formConnectors: FormConnector[];
  layout: ProDoctivityFormLayout;
  formValues: FormValues;
  updateLayout: any;
  showPins: boolean;
  sequenceBehavior: SequenceBehavior;
  navigate: string;
  onLayoutChange: any;
  groupValues: GroupValues;
  pinnedElements: string[];
  disabledElements: string[];
  layoutTopics: Set<string>;
  editingTopic?: string | boolean;
  onChooseAlternativeQuestion: any;
  enableTopicEditing: any;
  onSubmitEditTopic: any;
  groupDataFromConnectors: any;
  onFormErrorOccurred: (name: string, errors: ValidationErrors) => void;
  removeTopic?: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
  onGroupLayoutChange?: (groupName: string, layout: ProDoctivityFormLayout) => void;
  onLayoutUpdated: () => void;
  onPin: (dataElementName: string) => void;
  fireConnectors: (dataElementName: string, groupName?: string | undefined) => void;
  onNavigate: (to: string) => void;
  i18n: (key: string) => string;
  onFireConnector: (formConnector: FormConnector, formValues: FormValues) => Promise<FormValues>;
  onConnectorFail: (formConnector: FormConnector, error: any) => void;
  onNoDataFound?: (message: string) => void;
  prev: () => void;
  next: () => void;
  cancel?: () => void;
  cancelLabel?: string;
  finish?: () => void;
  finishButtonLabel?: string;
  back?: () => void;
  backButtonLabel?: string;
  defaultColumns?: string;
  purpose?: DataElementInputProps["purpose"];
  componentBreakpoint: DesignBreakpointType;
  moment: typeof momentType;
  isLoading?: boolean;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
};

export const FormPageComponent: React.FunctionComponent<Props> = ({
  contextDefinition,
  wizardDefinition,
  formDefinition,
  showSinglePage,
  pagination,
  componentBreakpoint,
  connectors,
  disabledElements,
  fireConnectors,
  formValues,
  groupDataFromConnectors,
  groupValues,
  i18n,
  layoutTopics,
  moment,
  onChooseAlternativeQuestion,
  onFormErrorOccurred,
  onLayoutChange,
  onLayoutUpdated,
  onNavigate,
  onPin,
  pinnedElements,
  readOnly,
  showPins,
  summaryMode,
  updateLayout,
  defaultColumns,
  editingTopic,
  enableTopicEditing,
  isDesignMode,
  navigate,
  onGroupLayoutChange,
  onNoDataFound,
  onSubmitEditTopic,
  removeTopic,
  sequenceBehavior: sequenceBehaviour,
  cancel,
  cancelLabel,
  prev,
  next,
  finish,
  finishButtonLabel,
  back,
  backButtonLabel,
  purpose,
  isLoading,
  resources,
}) => {
  // const pages = useMemo(() => {
  //   return wizardDefinition.pages.map((p) => {
  //     return {
  //       name: p.label,
  //       content: wizardDefinitionToLayout(formDefinition, wizardDefinition, p.key).layout,
  //     };
  //   });
  // }, [formDefinition, wizardDefinition]);

  const { colors } = useColors();

  const formController = useFormController();

  const allSectionsLayout = useMemo(() => {
    return (wizardDefinition.pages || []).flatMap((page) =>
      page.sections.map((section) => {
        const temporaryWizardDefinition: TemplateWizardDefinition = {
          ...wizardDefinition,
          pages: [
            {
              ...page,
              sections: [section],
            },
          ],
        };

        const layout = wizardDefinitionToLayout(
          temporaryWizardDefinition,
          contextDefinition,
          page.key
        );

        return {
          key: section.key,
          name: section.label,
          description: section.description,
          content: layout.layout,
          groupLayouts: layout.groupLayouts,
          sectionWizardDefinition: temporaryWizardDefinition,
        };
      })
    );
  }, [wizardDefinition, contextDefinition]);

  //TODO: @eburgos Remove this
  const { groupLayouts: fullGroupLayouts } = useMemo(() => {
    const result = wizardDefinitionToLayout(wizardDefinition, contextDefinition, undefined);

    return {
      groupLayouts: result.groupLayouts,
    };
  }, [wizardDefinition, contextDefinition]);

  const fullLayout = useMemo(() => {
    return wizardDefinition.pages
      .map((p) => {
        return {
          name: p.label,
          content: wizardDefinitionToLayout(wizardDefinition, contextDefinition, p.key).layout,
        };
      })
      .reduce((acc: ProDoctivityFormLayout, next) => {
        if (!next) {
          return acc;
        }

        return [...acc, ...next.content];
      }, []);
  }, [wizardDefinition, contextDefinition]);

  const getDataElement = useCallback(
    (fullPath: string) => {
      const contextField = getContextField(contextDefinition, fullPath);
      if (!contextField) {
        return undefined;
      }

      const dataElement = contextFieldToDataElementSync("en-US", "", contextField);

      return { contextField, dataElement };
    },
    [contextDefinition]
  );

  if (pagination && !showSinglePage) {
    const { sectionKey /*, total */ } = pagination;
    const current = allSectionsLayout.findIndex((s) => s.key === sectionKey);
    const currentSection = allSectionsLayout[current];
    const hasNextButton = current < allSectionsLayout.length - 1;
    const hasBackButton = current > 0;

    return (
      <Box display="flex" flex="grow" direction="column">
        <Box display="flex" flex="grow" direction="column">
          {/* <Box display="flex" direction="row" flex="grow" fit={true} justifyContent="between">
            <Box>
              <Text weight="bold" size="400" color={colors.black900}>
                {currentPage ? currentPage.name : null}
              </Text>
            </Box>

            <Box>
              <Text weight="bold" size="400" color={colors.black900}>
                {current} of {pages.length}
              </Text>
            </Box>
          </Box> */}
          <Box display="flex" flex="grow" direction="column" overflow="auto">
            {currentSection && (
              <ProDoctivityFormGridLayout
                componentBreakpoint={componentBreakpoint}
                connectors={connectors}
                contextDefinition={contextDefinition}
                disabledElements={disabledElements}
                fireConnectors={fireConnectors}
                formDefinition={formDefinition}
                formValues={formValues}
                groupDataFromConnectors={groupDataFromConnectors}
                groupLayouts={currentSection.groupLayouts}
                groupValues={groupValues}
                i18n={i18n}
                layoutTopics={layoutTopics}
                moment={moment}
                onChooseAlternativeQuestion={onChooseAlternativeQuestion}
                onFormErrorOccurred={onFormErrorOccurred}
                onLayoutChange={onLayoutChange}
                onLayoutUpdated={onLayoutUpdated}
                onNavigate={onNavigate}
                onPin={onPin}
                pinnedElements={pinnedElements}
                readOnly={readOnly}
                showPins={showPins}
                summaryMode={summaryMode}
                updateLayout={updateLayout}
                wizardDefinition={currentSection.sectionWizardDefinition}
                defaultColumns={defaultColumns}
                editingTopic={editingTopic}
                enableTopicEditing={enableTopicEditing}
                isDesignMode={isDesignMode}
                navigate={navigate}
                onGroupLayoutChange={onGroupLayoutChange}
                onNoDataFound={onNoDataFound}
                onSubmitEditTopic={onSubmitEditTopic}
                removeTopic={removeTopic}
                sequenceBehaviour={sequenceBehaviour}
                layout={currentSection.content}
                paginated
                getDataElement={getDataElement}
                resources={resources}
                colors={colors}
                purpose={purpose}
              />
            )}
          </Box>
          <Box display="flex" flex="shrink" direction="column" paddingRight={2}>
            <NavigationButtons
              hasBackButton={hasBackButton}
              hasNextButton={hasNextButton}
              finish={hasNextButton ? undefined : finish}
              finishButtonLabel={finishButtonLabel}
              cancel={cancel}
              cancelLabel={cancelLabel}
              i18n={i18n}
              prev={prev}
              next={next}
              summaryMode={summaryMode}
              formHasErrors={!!formController.currentSectionErrors.length}
              back={back}
              backButtonLabel={backButtonLabel}
              isLoading={isLoading}
              isFinishButtonDisabled={false}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" direction="column" flex="grow" width={"100%"} padding={4}>
      <Box display="flex" direction="column" flex="grow">
        <ProDoctivityFormGridLayout
          componentBreakpoint={componentBreakpoint}
          connectors={connectors}
          contextDefinition={contextDefinition}
          disabledElements={disabledElements}
          fireConnectors={fireConnectors}
          formDefinition={formDefinition}
          formValues={formValues}
          groupDataFromConnectors={groupDataFromConnectors}
          groupLayouts={fullGroupLayouts}
          groupValues={groupValues}
          i18n={i18n}
          layoutTopics={layoutTopics}
          moment={moment}
          onChooseAlternativeQuestion={onChooseAlternativeQuestion}
          onFormErrorOccurred={onFormErrorOccurred}
          onLayoutChange={onLayoutChange}
          onLayoutUpdated={onLayoutUpdated}
          onNavigate={onNavigate}
          onPin={onPin}
          pinnedElements={pinnedElements}
          readOnly={readOnly}
          showPins={showPins}
          summaryMode={summaryMode}
          updateLayout={updateLayout}
          wizardDefinition={wizardDefinition}
          defaultColumns={defaultColumns}
          editingTopic={editingTopic}
          enableTopicEditing={enableTopicEditing}
          isDesignMode={isDesignMode}
          navigate={navigate}
          onGroupLayoutChange={onGroupLayoutChange}
          onNoDataFound={onNoDataFound}
          onSubmitEditTopic={onSubmitEditTopic}
          removeTopic={removeTopic}
          sequenceBehaviour={sequenceBehaviour}
          layout={fullLayout}
          getDataElement={getDataElement}
          resources={resources}
          colors={colors}
          purpose={purpose}
        />
      </Box>
      <Box display="flex" direction="column" flex="shrink">
        <NavigationButtons
          hasBackButton={false}
          hasNextButton={false}
          finish={finish}
          finishButtonLabel={finishButtonLabel}
          cancel={cancel}
          cancelLabel={cancelLabel}
          i18n={i18n}
          prev={prev}
          next={next}
          summaryMode={summaryMode}
          formHasErrors={false}
          back={back}
          backButtonLabel={backButtonLabel}
        />
      </Box>
    </Box>
  );
};
