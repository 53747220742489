import {
  Box,
  BoxWithRef,
  Divider,
  Image,
  Mask,
  Popover,
  Spinner,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";

import type { HttpGetOrganizationListFromUserResponse } from "@prodoctivity/types/model/api-gateway";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { Services } from "../../services";

type Props = {
  username: string;
  organizationName: string;
  organizationId: string;
  imageBase64?: string;
  forceOpen?: boolean;
  fetchOrganizations(): Promise<HttpGetOrganizationListFromUserResponse["payload"]>;
  switchOrganization: Services["switchOrganization"];
};

function OrganizationAvatar({
  username,
  organizationName,
  organizationId,
  imageBase64,
  forceOpen,
  fetchOrganizations,
  switchOrganization,
}: Props) {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const { resources } = useAppTranslation();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const { isLoading, data: organizationList } = useQuery(
    [`organizations/${username}`],
    fetchOrganizations,
    {
      staleTime: 60 * 1000,
      retry: 3,
    }
  );
  const navigate = useNavigate();

  const { mutate: mutateSwitchOrganization, reset } = useMutation(switchOrganization, {
    onSettled(data) {
      reset();
      navigate(`/${data?.organizationId}/`);
    },
  });

  const displayedImage = useMemo(() => {
    if (organizationList) {
      const myOrganization = organizationList.organizations.find(
        (item) => item.id === organizationId
      );
      if (myOrganization && myOrganization.pngImageBase64) {
        return myOrganization.pngImageBase64;
      }
    }
    return imageBase64;
  }, [imageBase64, organizationId, organizationList]);

  return (
    <BoxWithRef
      ref={anchorRef}
      onClickCapture={onClick}
      aria-label={resources.myOrganization}
      aria-roledescription="button"
      dangerouslySetInlineStyle={{
        __style: {
          cursor: "pointer",
        },
      }}
    >
      <OrganizationAvatarImage name={organizationName} imageBase64={displayedImage} />

      {(open || forceOpen) && (
        <Popover
          anchor={anchorRef.current}
          onDismiss={() => setOpen(false)}
          idealDirection="forceDown"
          positionRelativeToAnchor={true}
          size="xl"
          shouldFocus={true}
          role="menu"
        >
          <Box>
            <Box margin={4} height="auto" width="auto">
              <Box display="flex" direction="column">
                <Box display="flex" direction="column">
                  <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                    <Box margin={3} width="40px">
                      <OrganizationAvatarImage
                        name={organizationName}
                        imageBase64={displayedImage}
                      />
                    </Box>
                    <Box display="flex" direction="column">
                      <Text weight="bold">{organizationName}</Text>
                      <Text>{organizationId}</Text>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Spinner accessibilityLabel={resources.loading} show={isLoading} />
                {organizationList && (
                  <Box display="flex" direction="column" gap={4}>
                    {organizationList.organizations
                      .filter((item) => item.id !== organizationId)
                      .map((item) => {
                        return (
                          <Box
                            display="flex"
                            key={item.id}
                            direction="row"
                            alignItems="center"
                            justifyContent="start"
                            gap={6}
                            hoverColor={colors.primary100}
                            onClickCapture={() => {
                              mutateSwitchOrganization(item.id);
                            }}
                            aria-label={`Switch to ${item.name}`}
                            aria-roledescription="button"
                            dangerouslySetInlineStyle={{
                              __style: {
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Box margin={2} width={40}>
                              <OrganizationAvatarImage
                                name={item.name}
                                imageBase64={item.pngImageBase64}
                              />
                            </Box>
                            <Box direction="row" justifyContent="start">
                              <Box>
                                <Text>{item.name}</Text>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Popover>
      )}
    </BoxWithRef>
  );
}

type OrganizationAvatarImageProps = {
  imageBase64?: string;

  name: string;
};

const avatarImageHeight = 32;

export const OrganizationAvatarImage: FunctionComponent<OrganizationAvatarImageProps> = ({
  name,
  imageBase64,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [imgError, setImgError] = useState(false);

  return (
    <Mask rounding={"circle"} height={avatarImageHeight} width={avatarImageHeight}>
      {imageBase64 && !imgError ? (
        <Image
          alt={resources.avatar}
          color="blue"
          naturalHeight={avatarImageHeight}
          naturalWidth={avatarImageHeight}
          src={`data:image/png;base64,${imageBase64}`}
          onError={() => setImgError(true)}
        />
      ) : (
        <Box
          color={colors.primary}
          height={"100%"}
          minWidth={avatarImageHeight}
          maxWidth={avatarImageHeight}
          direction="column"
          display="flex"
          justifyContent="center"
        >
          <Box
            display="flex"
            flex="grow"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text size="200" weight="bold" inline={true} color={colors.secondary}>
              {name
                ?.split(" ")
                .filter((word) => word.trim())
                .map((word) => word.trim().charAt(0))
                .slice(0, 2)
                .join(" ")
                .toLocaleUpperCase()}
            </Text>
          </Box>
        </Box>
      )}
    </Mask>
  );
};

export { OrganizationAvatar };
