import { Box, ColorConfig, Divider, Svg, Text, useColors } from "@prodoctivity/design-system";

import GreenCheck from "../../assets/green-check.png";
import LogDrk from "../../assets/prodoctivity-noiso-dark.png";
import RedX from "../../assets/red-x.png";

type TableProps = {
  data: React.ReactNode[][];
  headerColor?: ColorConfig;
};

type RowProps = {
  index: number;
  cells: React.ReactNode[];
  color?: ColorConfig;
};

type CellProps = {
  content: React.ReactNode | boolean;
  index: number;
  isHeader: boolean;
  color?: ColorConfig;
};

const Row = ({ cells, color, index }: RowProps) => {
  return (
    <Box display="flex" direction="column" flex={index === 0 ? "grow" : "shrink"}>
      {cells.map((cell, index) =>
        index === 0 ? (
          <Cell key={index} color={color} content={cell} isHeader={true} index={index} />
        ) : (
          <Cell key={index} content={cell} isHeader={false} index={index} />
        )
      )}
    </Box>
  );
};

const Cell = ({ content, color, isHeader, index }: CellProps) => {
  const { colors } = useColors();

  return typeof content === "boolean" ? (
    <Box width="100%" position="relative">
      <Box
        color={color || (index % 2 === 1 ? colors.neutral200 : undefined)}
        // color={color}
        display="flex"
        height="60px"
        padding={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {content ? <Svg src={GreenCheck} alt="Green Check" /> : <Svg src={RedX} alt="Red Cross" />}
      </Box>

      {isHeader && index > 0 && (
        <Divider position="absolute" color={colors.neutral400} direction="vertical" />
      )}
    </Box>
  ) : (
    <Box width="100%" position="relative">
      <Box
        color={color || (index % 2 === 1 ? colors.neutral200 : undefined)}
        // color={color}
        display="flex"
        height={isHeader ? 80 : "60px"}
        padding={2}
        direction="column"
        alignItems={isHeader ? "center" : undefined}
        justifyContent={isHeader ? "center" : undefined}
      >
        {content}
      </Box>

      {isHeader && index > 0 && (
        <Divider position="absolute" color={colors.neutral400} direction="vertical" />
      )}
    </Box>
  );
};

export const ColoredTitle = ({ text }: { text: string }) => {
  const { colors } = useColors();

  return (
    <>
      <Svg src={LogDrk} alt="ProDoctivity Logo" />
      <Text color={colors.white} weight="bold">
        {text}
      </Text>
    </>
  );
};

export const PricingTable = ({ data, headerColor }: TableProps) => {
  return (
    <Box display="flex" direction="row" borderRadius={12} position="relative" width="100%">
      {data.map((cell, index) => (
        <Row key={index} cells={cell} color={headerColor} index={index} />
      ))}
    </Box>
  );
};
