import { Box, Grid } from "@prodoctivity/design-system";
import { ProDoctivityFormLayout, ProDoctivityFormLayoutItem } from "../_lib/types";

import { useMemo } from "react";

type ResponsiveReactGridLayoutProps = {
  cols: number;
  layout: ProDoctivityFormLayout;

  className: string;
  rowHeight: number;
  margin: [number, number];
  draggableHandle: string;
  onLayoutChange(newLayout?: ProDoctivityFormLayout): void;
  isDraggable: boolean | undefined;
  isResizable: boolean | undefined;
  children: React.ReactElement | React.ReactElement[];
};
export const ResponsiveReactGridLayout: React.FunctionComponent<ResponsiveReactGridLayoutProps> = ({
  cols,
  layout,
  children,
}) => {
  const maxCols = cols / 4;
  const rows = useMemo(() => {
    const mappedChildren = Array.isArray(children) ? children : [children];
    const result = layout.reduce(
      (
        acc: Array<
          Array<{
            value: ProDoctivityFormLayoutItem | undefined;
            item: React.ReactElement | undefined;
            colCount: number;
          }>
        >,
        next,
        idx
      ) => {
        let last = acc[acc.length - 1];
        const lastLength = last.reduce((s, i) => {
          return s + i.colCount;
        }, 0);
        const currentWidth = next.w / 4;
        if (lastLength + currentWidth > maxCols) {
          if (maxCols - lastLength > 0) {
            last.push({ value: undefined, item: undefined, colCount: 3 - lastLength });
          }

          last = [];
          acc.push(last);
        }

        last.push({ value: next, item: mappedChildren[idx], colCount: currentWidth });

        return acc;
      },
      [[]]
    );

    const last = result[result.length - 1];
    const colSum = last.reduce((acc: number, next) => {
      return acc + next.colCount;
    }, 0);

    if (colSum < maxCols) {
      last.push({
        value: undefined,
        item: undefined,
        colCount: maxCols - colSum,
      });
    }

    return result;
  }, [children, layout, maxCols]);

  return (
    <div className="react-grid-layout">
      <Box display="flex" direction="column">
        {rows.map((row, rowIndex) => {
          return (
            <Grid key={rowIndex} gridTemplateColumns={row.map((i) => `${i.colCount}fr`)} gap={5}>
              {row.map((i, idx) => (i.value !== undefined ? <Box key={idx}>{i.item}</Box> : null))}
            </Grid>
          );
        })}
      </Box>
    </div>
  );
};
