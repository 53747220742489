import {
  DataElementInputProps,
  DesignBreakpointType,
  FormControllerContext,
  getDisplayedError,
  useFormController,
} from "@prodoctivity/design-system";
import {
  formatCurrency,
  formatNumber,
  isValueListContextField,
  replaceActiveContent,
} from "@prodoctivity/shared";
import type {
  ContextField,
  ContextFieldProperties,
  DataElementSingleValue,
  RecordContextStack,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useMemo } from "react";
import { DataElementValue, WebServiceConnector } from "../../../_lib/types";

import type momentType from "moment";
import { dateFormat } from "../../../_lib/date-formats";
import { noop } from "../../../_lib/utils";
import { EditableItem } from "./EditableItem";
import { ReadOnlyItem } from "./ReadOnlyItem";

type Props = {
  instanceFullPath: string;
  contextField: ContextField;
  editable: boolean;
  connectors: WebServiceConnector[];
  typeValue: DataElementInputProps["typeValue"];
  editing: boolean;
  columns: any;
  i18n: (key: string) => string;
  onClick: (name: string) => void;
  onEdit: (name: string) => void;
  onChange: DataElementInputProps["onChange"];
  onBlur: DataElementInputProps["onBlur"];
  onErrors: (name: string, errors: any) => void;
  onSave: () => void;
  onEditExit: () => void;
  moment: typeof momentType;
  componentBreakpoint: DesignBreakpointType;
  getContextValue: FormControllerContext["getContextValueAsString"];
  resources: DataElementInputProps["resources"];
  recordContextStack: RecordContextStack;
};

export const ProDoctivityFormItem: FunctionComponent<Props> = ({
  instanceFullPath: instanceFullPathContainer,
  typeValue,
  editable,
  editing,
  columns,
  onEditExit,
  onChange,
  // onBlur,
  // onSave,
  contextField,
  i18n,
  moment,
  componentBreakpoint,
  getContextValue,
  recordContextStack,
  resources,

  onClick: propsOnClick,
}) => {
  const instanceFullPath = `${instanceFullPathContainer}[0]`;
  const { context, currentSectionErrors } = useFormController();

  const label = useMemo(() => {
    return replaceActiveContent(context, recordContextStack, contextField.properties.label);
  }, [context, contextField.properties.label, recordContextStack]);

  const onClick = useCallback(() => {
    propsOnClick(contextField.fullPath);
  }, [contextField.fullPath, propsOnClick]);

  const onHover = useCallback(noop, []);

  const onHoverOut = useCallback(noop, []);

  const error = useMemo(() => {
    return getDisplayedError(
      currentSectionErrors,
      instanceFullPath,
      resources.contextValidationErrors,
      undefined
    );
  }, [currentSectionErrors, instanceFullPath, resources.contextValidationErrors]);

  if (editing) {
    return (
      <EditableItem
        instanceFullPath={instanceFullPath}
        typeValue={typeValue}
        i18n={i18n}
        contextField={contextField}
        onChange={onChange}
        onExit={onEditExit}
        moment={moment}
        componentBreakpoint={componentBreakpoint}
        getContextValueAsString={getContextValue}
        onBlur={noop}
        recordContextStack={recordContextStack}
        resources={resources}
      />
    );
  }

  let width = "auto";

  if (componentBreakpoint !== "small") {
    if (columns === "2") width = "50%";
    if (columns === "3") width = "33.3%";
    if (columns === "4") width = "25%";
  }

  return (
    <ReadOnlyItem
      name={label}
      typeValue={typeValue}
      i18n={i18n}
      moment={moment}
      properties={contextField.properties}
      width={width}
      error={error}
      editable={editable}
      onClick={onClick}
      onHover={onHover}
      onHoverOut={onHoverOut}
    />
  );
};

function renderDateValue(
  moment: typeof momentType,
  date: DataElementSingleValue,
  inputMask: any | undefined,
  is12Hour: boolean | undefined
) {
  if (typeof date === "boolean") {
    return "";
  }
  if (!date) {
    return "";
  }
  const format = inputMask ? inputMask.pattern : dateFormat("Date", is12Hour || false);

  const m = moment.isMoment(date) ? date : moment(date);

  return m.isValid() ? m.format(format) : "";
}

function renderDateTimeValue(
  moment: typeof momentType,
  date: DataElementSingleValue,
  inputMask: any | undefined,
  is12Hour: boolean | undefined
) {
  if (typeof date === "boolean") {
    return "";
  }
  if (!date) {
    return "";
  }
  const format = inputMask ? inputMask.pattern : dateFormat("DateTime", is12Hour || false);

  const m = moment.isMoment(date) ? date : moment(date);

  return m.isValid() ? m.format(format) : "";
}

function renderTimeValue(
  moment: typeof momentType,
  date: DataElementSingleValue,
  inputMask: any | undefined,
  is12Hour: boolean | undefined
) {
  if (typeof date === "boolean") {
    return "";
  }
  if (!date) {
    return "";
  }
  const format = inputMask ? inputMask.pattern : dateFormat("Time", is12Hour || false);

  const m = moment.isMoment(date) ? date : moment(date);

  return m.isValid() ? m.format(format) : "";
}

export function formatValue(
  moment: typeof momentType,
  i18n: (key: string) => string,
  contextFieldProperties: ContextFieldProperties,
  value: DataElementValue
): string {
  if (Array.isArray(value)) {
    return value.map((v) => formatValue(moment, i18n, contextFieldProperties, v)).join(", ");
  }

  if (isValueListContextField(contextFieldProperties)) {
    const found = (contextFieldProperties.valueList || []).find((item) => item.value === value);
    if (found) {
      return found.label || found.value;
    }

    if (typeof value === undefined) {
      return "";
    }

    if (typeof value === "string") {
      return value;
    }

    return `${value}`;
  }

  if (value && contextFieldProperties.dataType === "Date") {
    if (Array.isArray(value)) {
      let dateStr = "";
      value.forEach(
        (date) =>
          (dateStr += (dateStr ? ", " : "") + renderDateValue(moment, date, undefined, false))
      );
      return dateStr;
    }

    if (
      typeof value === "number" ||
      typeof value === "string" ||
      value instanceof Date ||
      moment.isMoment(value)
    ) {
      return renderDateValue(moment, value, undefined, false);
    }
    return "";
  } else if (value && contextFieldProperties.dataType === "DateTime") {
    if (Array.isArray(value)) {
      let dateStr = "";
      value.map(
        (date) =>
          (dateStr += (dateStr ? ", " : "") + renderDateTimeValue(moment, date, undefined, false))
      );
      return dateStr;
    }

    if (
      typeof value === "number" ||
      typeof value === "string" ||
      value instanceof Date ||
      moment.isMoment(value)
    ) {
      return renderDateTimeValue(moment, value, undefined, false);
    }
    return "";
  } else if (value && contextFieldProperties.dataType === "Time") {
    if (Array.isArray(value)) {
      let dateStr = "";
      value.map(
        (date) =>
          (dateStr += (dateStr ? ", " : "") + renderTimeValue(moment, date, undefined, false))
      );
      return dateStr;
    }

    if (
      typeof value === "number" ||
      typeof value === "string" ||
      value instanceof Date ||
      moment.isMoment(value)
    ) {
      return renderTimeValue(moment, value, undefined, false);
    }
    return "";
  }

  if (contextFieldProperties.dataType === "Logical") {
    const parsedValue =
      typeof value === "string" ? JSON.parse((value || "false").toLowerCase()) : value;
    return parsedValue ? i18n("yes") : i18n("no");
  }

  if (contextFieldProperties.dataType === "Currency") {
    if (typeof value === "string") {
      const numberValue = parseFloat(value);
      return Number.isNaN(numberValue) ? "" : formatCurrency(numberValue);
    } else if (typeof value === "number") {
      return Number.isNaN(value) ? "" : formatCurrency(value);
    } else {
      return "";
    }
  }

  if (contextFieldProperties.dataType === "Numeric") {
    if (typeof value === "string") {
      const numberValue = parseInt(value, 10);
      return Number.isNaN(numberValue) ? "" : formatNumber(numberValue);
    } else if (typeof value === "number") {
      return Number.isNaN(value) ? "" : formatNumber(value);
    } else {
      return "";
    }
  }

  if (value === undefined || value === null) {
    return "";
  }

  return `${value}`;
}
