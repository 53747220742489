import { useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import { usePaginatedDataEndpoint } from "../../../components/hooks";
import type {
  ApprovalType,
  EmptyObject,
  HttpGetApprovalTypesRequest,
  HttpGetApprovalTypesResponse,
} from "@prodoctivity/types";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useCallback, useMemo, useState } from "react";
import { useServices } from "../../../hooks/useServices";
import { useMutation } from "@tanstack/react-query";
import { ToastMessageType } from "../../../components/NotificationMessage";
import { useParams } from "react-router-dom";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { organizationLinkTemplates } from "../../../link-templates";
import { useGetAllOrganizationRoles } from "../../../hooks";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";

export const useApprovalTypes = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const [deleteInfo, setDeleteInfo] = useState<{ id: string; label: string } | undefined>(
    undefined
  );
  const [toastMessage, setToastMessage] = useState<ToastMessageType | undefined>(undefined);
  const { removeApprovalType } = useServices();

  const {
    paginatedData,
    refetch,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setFilter,
    setPageLength,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    { approvalTypes: HttpGetApprovalTypesResponse["payload"]["approvalTypes"] },
    HttpGetApprovalTypesRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.getApprovalTypes(currentPage, rowsPerPage, filter);
    },
    "settings_approve_type_list",
    { refetchInterval: 60 * 1000 }
  );

  const { mutate: mutateDelete, isLoading: isMutating } = useMutation(removeApprovalType, {
    onSuccess: useCallback(
      (data: EmptyObject) => {
        if (data) {
          setToastMessage({ type: "success", message: resources.success });
          refetch();
        }
      },
      [refetch, resources.success]
    ),
    onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
      if (error.response.data.errors.length > 0) {
        setToastMessage({ type: "error", message: error.response.data.errors[0].message });
      }
    },
  });

  return {
    colors,
    resources,
    breakpoint,

    deleteInfo,
    setDeleteInfo,
    mutateDelete,
    isMutating,

    toastMessage,
    setToastMessage,

    paginatedData,
    refetch,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setFilter,
    setPageLength,
    totalRowCount,
  };
};

type ApprovalTypeState = {
  label?: string;
  roles: Array<string>;
  toastMessage: ToastMessageType | undefined;
};

export const useEditApprovalType = () => {
  const [formState, setFormState] = useState<ApprovalTypeState>({
    roles: [],
    toastMessage: undefined,
  });
  const { id } = useParams();
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const { getApprovalType, createApprovalType, updateApprovalType } = useServices();
  const organizationNavigate = useOrganizationNavigate();

  const fetchApprovalType = useCallback(async () => {
    if (!id) {
      return {
        approvalType: undefined,
      };
    }
    const response = await getApprovalType(id);
    setFormState((prev) => ({
      ...prev,
      label: response.approvalType?.label,
      roles: [...(response.approvalType?.roles || [])],
    }));
    return response;
  }, [id, getApprovalType]);

  const { isLoading } = useOrganizationQuery(`approval-type/${id}`, fetchApprovalType, {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
  });

  const { data: roles, isLoading: isLoadingRoles } = useGetAllOrganizationRoles();

  const rolesOptions: Array<ComboBoxItemType> = useMemo(() => {
    return (roles || []).map((role) => ({
      label: role.name,
      value: role.id,
    }));
  }, [roles]);

  const selectedRoles = useMemo(() => {
    return (roles || [])
      .filter((r) => formState.roles.includes(r.id))
      .map((role) => ({
        label: role.name,
        value: role.id,
      }));
  }, [roles, formState]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.approvalType.manageApprovalTypes,
        url: organizationLinkTemplates.manageApprovalTypes(),
      },
      { type: "text", name: `${id ? resources.edit : resources.addNew}` },
    ];
  }, [id, resources]);

  const fetchCreateOrUpdate = useCallback(async () => {
    if (!formState.label || !formState.roles.length) {
      return { id: undefined };
    }
    const config: ApprovalType<string> = {
      label: formState.label,
      roles: formState.roles,
    };
    if (!id) {
      return await createApprovalType(config);
    }
    return await updateApprovalType(id, config);
  }, [formState.label, formState.roles, id, createApprovalType, updateApprovalType]);

  const {
    mutate: mutateApprovalType,
    isLoading: isMutating,
    isSuccess,
  } = useMutation(fetchCreateOrUpdate, {
    onSuccess: (data) => {
      if (data.id) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: { type: "success", message: resources.success },
        }));
        setTimeout(() => {
          organizationNavigate(organizationLinkTemplates.manageApprovalTypes());
        }, 3000);
      }
    },
    onError: (error: {
      response: { data: { errors: Array<{ message: string; errorCode: string }> } };
    }) => {
      if (error.response.data.errors.length > 0) {
        setFormState((prev) => ({
          ...prev,
          toastMessage: { type: "error", message: error.response.data.errors[0].message },
        }));
      }
    },
  });

  const onSelectRole = useCallback(
    (value: string) => {
      setFormState((prev) => {
        const currentRoles = prev.roles;

        if (!currentRoles.includes(value)) {
          currentRoles.push(value);
        }

        return { ...prev, users: currentRoles };
      });
    },
    [setFormState]
  );

  const onRemoveRole = useCallback(
    (value: string) => {
      setFormState((prev) => {
        const currentRoles = prev.roles.filter((id) => id !== value);

        return { ...prev, roles: currentRoles };
      });
    },
    [setFormState]
  );

  const handleSubmit = useCallback(() => {
    if (isLoading || isMutating || isSuccess) {
      return;
    }

    if (!formState.label) {
      setFormState((prev) => ({
        ...prev,
        toastMessage: { type: "error", message: `${resources.missing} [${resources.label}]` },
      }));
      return;
    }

    if (!formState.roles.length) {
      setFormState((prev) => ({
        ...prev,
        toastMessage: {
          type: "error",
          message: `${resources.missing} [${resources.rolesManagement.roles}]: ${resources.chooseOneItem}`,
        },
      }));
      return;
    }

    mutateApprovalType();
  }, [
    mutateApprovalType,
    isLoading,
    isMutating,
    isSuccess,
    formState.label,
    formState.roles,
    resources.missing,
    resources.label,
    resources.rolesManagement.roles,
    resources.chooseOneItem,
  ]);

  return {
    id,
    colors,
    resources,
    breadCrumbEntries,
    organizationNavigate,

    isLoading: isLoading || isLoadingRoles,
    isSaving: isMutating || isSuccess,
    formState,
    setFormState,
    handleSubmit,
    rolesOptions,
    selectedRoles,
    onSelectRole,
    onRemoveRole,
  };
};
