"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDocumentCollectionFolderLogicalContextDefinition = exports.getDocumentMetadataFieldsContextDefinition = void 0;
const fluency_shared_1 = require("./fluency-shared");
const utils_1 = require("./utils");
function getDocumentMetadataFieldsContextDefinition() {
    const result = {
        records: [],
        fields: [
            {
                name: "$name",
                fullPath: "$name",
                humanName: "Document name",
                properties: {
                    dataType: "Alphanumeric",
                    description: "Auto-generated name for the document",
                    humanName: "Document name",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Document name",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$documentId",
                fullPath: "$documentId",
                humanName: "Document ID",
                properties: {
                    dataType: "Alphanumeric",
                    description: "Document's ID",
                    humanName: "Document ID",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Document ID",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$documentVersionId",
                fullPath: "$documentVersionId",
                humanName: "Document Version ID",
                properties: {
                    dataType: "Alphanumeric",
                    description: "Document's version",
                    humanName: "Document Version ID",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Document Version ID",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$createdAt",
                fullPath: "$createdAt",
                humanName: "Created At",
                properties: {
                    dataType: "DateTime",
                    description: "Document's creation date and time",
                    humanName: "Created At",
                    inputType: "DateTimePicker",
                    instructions: "",
                    label: "Created At",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$updatedAt",
                fullPath: "$updatedAt",
                humanName: "Updated At",
                properties: {
                    dataType: "DateTime",
                    description: "Document's last update date and time",
                    humanName: "Updated At",
                    inputType: "DateTimePicker",
                    instructions: "",
                    label: "Updated At",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$createdBy",
                fullPath: "$createdBy",
                humanName: "Created By",
                properties: {
                    dataType: "Alphanumeric",
                    description: "Username of the user who created this document",
                    humanName: "Created By",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Created By",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$updatedBy",
                fullPath: "$updatedBy",
                humanName: "Updated By",
                properties: {
                    dataType: "Alphanumeric",
                    description: "Username of the user who last updated this document",
                    humanName: "Updated By",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Updated By",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$documentDate",
                fullPath: "$documentDate",
                humanName: "Document Date",
                properties: {
                    dataType: "DateTime",
                    description: "Document's date and time",
                    humanName: "Document Date",
                    inputType: "DateTimePicker",
                    instructions: "",
                    label: "Document Date",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$expirationDate",
                fullPath: "$expirationDate",
                humanName: "Expiration Date",
                properties: {
                    dataType: "DateTime",
                    description: "Document's expiration date and time (if present)",
                    humanName: "Expiration Date",
                    inputType: "DateTimePicker",
                    instructions: "",
                    label: "Expiration Date",
                    minOccurs: 0,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$documentTypeName",
                fullPath: "$documentTypeName",
                humanName: "Document Type Name",
                properties: {
                    dataType: "Alphanumeric",
                    description: "Name of this document's type",
                    humanName: "Document Type Name",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Document Type Name",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$documentTypeId",
                fullPath: "$documentTypeId",
                humanName: "Document Type ID",
                properties: {
                    dataType: "Alphanumeric",
                    description: "ID of this document's type",
                    humanName: "Document Type ID",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Document Type ID",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$documentTypeVersionId",
                fullPath: "$documentTypeVersionId",
                humanName: "Document Type Version ID",
                properties: {
                    dataType: "Alphanumeric",
                    description: "ID of this document's type",
                    humanName: "Document Type ID",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Document Type ID",
                    minOccurs: 1,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$generationToken",
                fullPath: "$generationToken",
                humanName: "Generation Token",
                properties: {
                    dataType: "Alphanumeric",
                    description: "This document's generation token (if the document was generated)",
                    humanName: "Generation Token",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Generation Token",
                    minOccurs: 0,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$templateId",
                fullPath: "$templateId",
                humanName: "Template ID",
                properties: {
                    dataType: "Alphanumeric",
                    description: "ID of this document's template (if the document was generated)",
                    humanName: "Template ID",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Template ID",
                    minOccurs: 0,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
            {
                name: "$templateVersionId",
                fullPath: "$templateVersionId",
                humanName: "Template Version ID",
                properties: {
                    dataType: "Alphanumeric",
                    description: "ID of this document's template version (if the document was generated)",
                    humanName: "Template Version ID",
                    inputType: "TextBox",
                    instructions: "",
                    label: "Template Version ID",
                    minOccurs: 0,
                    maxOccurs: 1,
                    sequenceId: undefined,
                },
            },
        ],
    };
    return result;
}
exports.getDocumentMetadataFieldsContextDefinition = getDocumentMetadataFieldsContextDefinition;
function getDocumentCollectionFolderLogicalContextDefinition(masterDocumentTypeContextDefinition, documentTypeList) {
    const metadataContextDefinition = getDocumentMetadataFieldsContextDefinition();
    const mergedContextDefinition = Object.assign({}, metadataContextDefinition);
    const main = (0, fluency_shared_1.recursivelyMergeHolders)(mergedContextDefinition, masterDocumentTypeContextDefinition);
    if ((0, fluency_shared_1.isContextDefinitionMergeError)(main)) {
        return main;
    }
    const response = documentTypeList.reduce((acc, next) => {
        if ((0, fluency_shared_1.isContextDefinitionMergeError)(acc)) {
            return acc;
        }
        const targetDocumentTypeContextDefinition = (0, fluency_shared_1.recursivelyMergeHolders)(getDocumentMetadataFieldsContextDefinition(), (0, utils_1.deepCopy)(next.contextDefinition));
        if ((0, fluency_shared_1.isContextDefinitionMergeError)(targetDocumentTypeContextDefinition)) {
            return targetDocumentTypeContextDefinition;
        }
        const docTypeRecordName = `$${next.documentTypeName}`;
        const docTypeRecord = {
            name: docTypeRecordName,
            fullPath: "",
            humanName: next.documentTypeName,
            properties: {
                humanName: next.documentTypeName,
                label: next.documentTypeName,
            },
            fields: targetDocumentTypeContextDefinition.fields,
            records: targetDocumentTypeContextDefinition.records,
        };
        recursivelyUpdateFullPath(docTypeRecord, docTypeRecordName);
        acc.records.push(docTypeRecord);
        return acc;
    }, main);
    return response;
}
exports.getDocumentCollectionFolderLogicalContextDefinition = getDocumentCollectionFolderLogicalContextDefinition;
function recursivelyUpdateFullPath(docTypeRecord, docTypeRecordName) {
    docTypeRecord.fullPath = docTypeRecord.fullPath
        ? `${docTypeRecordName}/${docTypeRecord.fullPath}`
        : docTypeRecordName;
    for (const field of docTypeRecord.fields || []) {
        field.fullPath = `${docTypeRecordName}/${field.fullPath}`;
    }
    for (const record of docTypeRecord.records || []) {
        recursivelyUpdateFullPath(record, docTypeRecordName);
    }
}
