import { Box, ColorConfig, Grid, Skeleton, useColors } from "@prodoctivity/design-system";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import type { CollectionStat } from "@prodoctivity/types";
import { FunctionComponent, useCallback, useContext, useEffect, useMemo } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { DashboardItemContext } from "../../../pages/Dashboard/DashboardConfigColumnElement";
import { DashboardParametersTypeSet } from "../../../pages/Dashboard/hooks";
import { EmptyStateWidgetContent } from "../../../svg/EmptyStateWidgetContent";
import { NoResultsFoundBlock } from "../../NoResultsFoundBlock";
import { ReportCard } from "./ReportCard";

export type LineCardData = {
  label: string;
  value: string | number;
  color: ColorConfig | undefined;
};

export type ColorLineData = LineCardData & { percent: number };

export type DataCard = {
  title: string;
  resume: number;
  content: {
    documentCounter: ColorLineData;
    issueDocumentCounter: ColorLineData;
    expiredDocumentCounter: ColorLineData;
    pendingtoExpireDocumentCounter: ColorLineData;
  };
  colors?: { color: ColorConfig | undefined; value: number }[];
};

const getPercentVisualization = (value: number, total: number): number => {
  const factor = total / 100;
  return value / factor;
};

const buildColorBarData = (p: DataCard) => {
  const arr = [];
  arr.push({
    color: p.content.documentCounter.color,
    value: p.content.documentCounter.percent,
  });
  arr.push({
    color: p.content.issueDocumentCounter.color,
    value: p.content.issueDocumentCounter.percent,
  });
  arr.push({
    color: p.content.expiredDocumentCounter.color,
    value: p.content.expiredDocumentCounter.percent,
  });
  arr.push({
    color: p.content.pendingtoExpireDocumentCounter.color,
    value: p.content.pendingtoExpireDocumentCounter.percent,
  });
  return arr;
};

/* -------------------- PARAMETERS TYPING MODEL -------------------- */
type Props = DashboardParametersTypeSet;
/* -------------------- END -------------------- */

export const DocumentCollectionReport: FunctionComponent<Props> = (props) => {
  const { colors } = useColors();

  /* -------------------- PARAMETERS HANDLING MODEL -------------------- */
  const {
    // allowParamEdition,
    // params,
    setNewParams,
    setOfIndexes,
    sidebarItemIndex,
  } = props;

  // Extract parameters info > identify types > use as needed.
  // const { icon, jorgeProp } = params;
  // if (typeof icon === "string") {
  //   console.log("Params type defined: ", icon, params);
  // }

  const _handleParametersChange = useCallback(
    (modifiedParameters: ParametersObject) => {
      if (setOfIndexes) {
        setNewParams(
          {
            ...modifiedParameters,
          },
          setOfIndexes,
          undefined
        );
      }
      if (sidebarItemIndex) {
        setNewParams(
          {
            ...modifiedParameters,
          },
          undefined,
          sidebarItemIndex
        );
      }
    },
    [setNewParams, setOfIndexes, sidebarItemIndex]
  );

  /* -------------------- END -------------------- */

  // const colorArray = [colors.primary, colors.success, colors.secondary, colors.error]

  const { resources } = useAppTranslation();
  const { getOrganizationDocumentCollectionStats } = useServices();

  const convertRowToCardData = useCallback(
    (row: CollectionStat): DataCard => {
      const record: Record<string, string> = {
        documentCounter: resources.generationStatus.totalDocuments,
        issueDocumentCounter: resources.missing,
        expiredDocumentCounter: resources.expired,
        pendingtoExpireDocumentCounter: resources.toExpire,
      };

      const issue = getPercentVisualization(row.issueDocumentCounter, row.documentCounter);
      const expired = getPercentVisualization(row.expiredDocumentCounter, row.documentCounter);
      const toExpired = getPercentVisualization(
        row.pendingToExpireDocumentCounter,
        row.documentCounter
      );
      const res = 100 - (issue + expired + toExpired);

      const card: DataCard = {
        title: (row as any).name,
        resume: row.totalInstances,
        content: {
          documentCounter: {
            label: record["documentCounter"],
            value: row.documentCounter,
            color: colors.primary,
            percent: res,
          },
          issueDocumentCounter: {
            label: record["issueDocumentCounter"],
            value: row.issueDocumentCounter,
            color: colors.error,
            percent: issue,
          },
          expiredDocumentCounter: {
            label: record["expiredDocumentCounter"],
            value: row.expiredDocumentCounter,
            color: colors.other500,
            percent: expired,
          },
          pendingtoExpireDocumentCounter: {
            label: record["pendingtoExpireDocumentCounter"],
            value: row.pendingToExpireDocumentCounter,
            color: colors.other400,
            percent: toExpired,
          },
        },
      };
      card.colors = buildColorBarData(card);
      return card;
    },
    [
      colors.error,
      colors.primary,
      colors.other500,
      colors.other400,
      resources.generationStatus.totalDocuments,
      resources.missing,
      resources.expired,
      resources.toExpire,
    ]
  );

  const {
    data: dataSet,
    isLoading,
    refetch,
    remove,
  } = useOrganizationQuery([], getOrganizationDocumentCollectionStats);

  const cards = useMemo(() => {
    return dataSet?.statsDataSet.map(convertRowToCardData);
  }, [dataSet, convertRowToCardData]);

  const dashboardItemContext = useContext(DashboardItemContext);

  useEffect(() => {
    const eventEmitter = dashboardItemContext.eventEmitter;
    if (eventEmitter) {
      const handler = () => {
        remove();
        refetch();
      };
      eventEmitter.on("refresh", handler);
      return () => {
        eventEmitter.off("refresh", handler);
      };
    }
  }, [dashboardItemContext, refetch, remove]);

  return (
    <Box width={"100%"} height={"fit-content"} padding={4}>
      {/* -------------------- PARAMETERS CHANGING MODEL (Button simulation) -------------------- */}
      {/* UI Button of example to modify widget (only shown on edition mode) */}
      {/* Uncomment this to test changing its parameters */}
      {/* {allowParamEdition && (
        <Box>
          <TapArea
            onTap={() => {
              // decide what properties to add or edit, depending on widget location
              if (setOfIndexes) {
                handleParametersChange({
                  ...params,
                  modifyingMainContentElement: true,
                });
              }
              if (sidebarItemIndex) {
                handleParametersChange({
                  ...params,
                  modifyingSidebarElement: true,
                });
              }
            }}
          >
            <Box
              width={40}
              height={40}
              color={colors.primaryHover0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={6}
            >
              <Icon accessibilityLabel="" size={"sm"} icon={"check"} color={colors.neutral900} />
            </Box>
          </TapArea>
        </Box>
      )} */}
      {/* -------------------- END -------------------- */}
      <Grid
        justifyContent={"start"}
        gridTemplateColumns={["repeat(auto-fit, minmax(260px, 1fr))"]}
        gap={4}
        width="100%"
      >
        {isLoading ? (
          <>
            <Skeleton width={300} height={200} />
            <Skeleton width={300} height={200} />
            <Skeleton width={300} height={200} />
            <Skeleton width={300} height={200} />
          </>
        ) : cards && cards.length <= 0 ? (
          <NoResultsFoundBlock
            key={`${cards.length}_not-found`}
            messageTitle={resources.dashboard.noContentFound}
            messageDescription={""}
            illustration={<EmptyStateWidgetContent width={"100%"} height={"100%"} />}
          />
        ) : (
          <>
            {cards?.map((reportData, index) => (
              <ReportCard key={index} reportData={reportData}></ReportCard>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};
