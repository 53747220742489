import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const StatusInProgressSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 48.98"
    >
      <g transform="translate(0)">
        <rect width="50" height="48.98" rx="24.49" fill="#ffeebf" />
        <path
          d="M.857,10.719-.857,9.69,5.265-.514,6.98.515Z"
          transform="translate(11.735 18.878)"
          fill="#f0c548"
        />
        <path
          d="M.926,18.516-.926,17.76,6.472-.378,8.324.378Z"
          transform="translate(30.102 11.573)"
          fill="#f0c548"
        />
        <path
          d="M5.265,10.719-.857.515.857-.514,6.98,9.69Z"
          transform="translate(20.918 18.878)"
          fill="#f0c548"
        />
        <path
          d="M0,2.228,5.118,0l.715,5.277"
          transform="translate(33.008 11.002) rotate(-7)"
          fill="#f0c548"
        />
        <path
          d="M5.832,6.527A1.25,1.25,0,0,1,4.595,5.445L4.1,1.806.5,3.374a1.25,1.25,0,0,1-1.645-.647A1.25,1.25,0,0,1-.5,1.082L4.619-1.146a1.25,1.25,0,0,1,1.112.057,1.25,1.25,0,0,1,.626.921l.715,5.277a1.251,1.251,0,0,1-1.24,1.418Z"
          transform="translate(33.008 11.002) rotate(-7)"
          fill="#f0c548"
        />
        <circle
          cx="3.333"
          cy="3.333"
          r="3.333"
          transform="translate(7.143 28.571)"
          fill="#f0c548"
        />
        <path
          d="M3.333,1A2.333,2.333,0,1,0,5.667,3.333,2.336,2.336,0,0,0,3.333,1m0-1A3.333,3.333,0,1,1,0,3.333,3.333,3.333,0,0,1,3.333,0Z"
          transform="translate(7.143 28.571)"
          fill="#f0c548"
        />
        <circle
          cx="4.167"
          cy="4.167"
          r="4.167"
          transform="translate(15.816 13.265)"
          fill="#f0c548"
        />
        <path
          d="M4.167,1A3.167,3.167,0,1,0,7.333,4.167,3.17,3.17,0,0,0,4.167,1m0-1A4.167,4.167,0,1,1,0,4.167,4.167,4.167,0,0,1,4.167,0Z"
          transform="translate(15.816 13.265)"
          fill="#f0c548"
        />
        <circle cx="5" cy="5" r="5" transform="translate(24.49 27.551)" fill="#f0c548" />
        <path
          d="M5,1A4,4,0,1,0,9,5,4,4,0,0,0,5,1M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"
          transform="translate(24.49 27.551)"
          fill="#f0c548"
        />
      </g>
    </svg>
  );
};
