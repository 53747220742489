import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const WalletIconSvg: FunctionComponent<Props> = ({
  color,
  width = 25,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5701_96579)">
        <path
          d="M4.9772 5.50517C4.64792 5.69909 4.33131 5.9038 3.98936 6.09772C3.92604 6.13004 3.83739 6.13004 3.74873 6.13004C3.1155 6.13004 2.49493 6.13004 1.8617 6.13004C1.53242 6.13004 1.36778 6.23778 1.36778 6.45326C1.36778 6.66873 1.54509 6.78724 1.8617 6.78724C3.96403 6.78724 6.07903 6.78724 8.18136 6.78724C12.9686 6.78724 17.7432 6.78724 22.5304 6.78724C23.1763 6.78724 23.5942 7.132 23.5942 7.67069C23.5942 9.08205 23.5942 10.4934 23.5942 11.9048C23.5942 12.1849 23.5942 12.1849 23.2523 12.1957C22.5557 12.1957 21.8465 12.1849 21.15 12.228C19.9215 12.3034 18.845 13.0791 18.4904 14.0918C17.8571 15.848 19.3896 17.5933 21.5426 17.5825C22.2138 17.5825 22.8977 17.5825 23.6069 17.5825C23.6069 17.8303 23.6069 18.0781 23.6069 18.3152C23.6069 19.2202 23.6069 20.1251 23.6069 21.0301C23.6069 21.6442 23.2143 21.9675 22.4797 21.9675C17.4139 21.9675 12.3354 21.9675 7.2695 21.9675C5.2305 21.9675 3.19149 21.9675 1.15248 21.9675C0.835866 21.9675 0.54458 21.9244 0.303951 21.7304C0.113982 21.5796 0 21.4072 0 21.181C0 19.511 0 17.8519 0 16.182C0 14.372 0 12.562 0 10.7412C0 9.44836 0 8.15551 0.0126646 6.86266C0.0126646 6.63641 0.0633232 6.41016 0.126646 6.19469C0.253293 5.74219 0.620567 5.49439 1.19048 5.4513C1.55775 5.42975 1.91236 5.4082 2.27964 5.4082C3.16616 5.4082 4.06535 5.4082 4.95187 5.4082C4.95187 5.42975 4.96454 5.44052 4.9772 5.46207V5.50517Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M4.67285 6.45348C8.19362 4.28795 11.6764 2.15475 15.1972 0C16.1977 1.13124 17.1982 2.26249 18.2114 3.42605C17.7681 3.57689 17.3375 3.71694 16.9196 3.857C14.3106 4.70813 11.6891 5.57003 9.08015 6.42116C8.99149 6.45348 8.89018 6.47503 8.78886 6.47503C7.47174 6.47503 6.14195 6.47503 4.82483 6.47503C4.7995 6.47503 4.7615 6.47502 4.68552 6.45348H4.67285Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M24.9875 11.8408C24.9875 12.2394 24.9875 12.5842 24.9875 12.9182C24.9875 13.834 24.9875 14.7605 24.9875 15.6763C24.9875 15.8163 24.9368 15.9025 24.8102 15.9995C24.4176 16.2688 24.025 16.5597 23.6324 16.8398C23.5817 16.8829 23.5057 16.926 23.4424 16.926C22.6572 16.926 21.8593 16.9584 21.0741 16.8829C20.0229 16.7752 19.1871 15.9456 19.1364 15.0622C19.0858 14.0494 19.7697 13.1983 20.8588 12.9613C21.1754 12.8966 21.53 12.8859 21.8593 12.8751C22.3406 12.8643 22.8345 12.8751 23.3158 12.8751C23.4171 12.8751 23.5437 12.832 23.6197 12.7781C24.063 12.4872 24.4936 12.1748 24.9748 11.8408H24.9875ZM21.5047 15.9133C22.1759 15.9133 22.6952 15.4716 22.6952 14.9006C22.6952 14.3511 22.1506 13.8878 21.5174 13.8878C20.8461 13.8878 20.3142 14.3511 20.3269 14.9329C20.3269 15.4716 20.8715 15.9133 21.5047 15.9133Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M10.9805 6.42104C13.9187 5.51604 16.8695 4.62182 19.8457 3.70605C20.251 4.62182 20.6436 5.52682 21.0489 6.46414H10.9931C10.9931 6.46414 10.9931 6.44259 10.9931 6.43181L10.9805 6.42104Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M21.3652 5.46195C22.2011 5.46195 22.999 5.46195 23.8095 5.46195C24.3288 5.46195 24.9873 6.07605 24.9873 6.52855C24.9873 8.04765 24.9873 9.57752 24.9873 11.0966C24.9873 11.1505 24.9493 11.2367 24.886 11.269C24.6834 11.3767 24.4681 11.4522 24.2148 11.5707C24.2148 11.4306 24.2148 11.3121 24.2148 11.2044C24.2148 9.77145 24.2148 8.33854 24.2148 6.90563C24.2148 6.39926 23.8855 6.12992 23.2903 6.12992C22.809 6.12992 22.3277 6.12992 21.8592 6.12992C21.7072 6.12992 21.6312 6.08682 21.5932 5.96831C21.5299 5.80671 21.4539 5.6451 21.3779 5.45117L21.3652 5.46195Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M25.0001 16.6348C25.0001 17.0657 25.0001 17.4536 25.0001 17.8522C25.0001 18.6064 25.0001 19.3498 25.0001 20.1039C25.0001 20.481 24.6708 20.7611 24.2402 20.8042C24.2402 20.7288 24.2275 20.6534 24.2275 20.5887C24.2275 19.4575 24.2275 18.3262 24.2275 17.2058C24.2275 17.0442 24.2655 16.9472 24.4555 16.8826C24.6328 16.8287 24.7974 16.7425 25.0127 16.6455L25.0001 16.6348Z"
          fill={color || "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_5701_96579">
          <rect width="25" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
