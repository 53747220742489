import { ComboBoxProps, ComboBox as GestaltComboBox, Tag } from "gestalt";
import { useCallback, useMemo, useState } from "react";

type Option = { value: string; label: string };
interface Props extends Omit<ComboBoxProps, "selectedOption" | "onSelect"> {
  accessibilityClearButtonLabel: string;
  maxTags?: number;
  selected: Option[];
  onSelect: (items: Array<Option>, item?: Option) => void;
}
export const ComboBoxTags = (props: Props) => {
  const { onSelect, selected, maxTags, options } = props;
  const [searchTerm, setSearchTerm] = useState("");

  const suggestedOptions = useMemo(() => {
    return options.filter((opt) => {
      if (searchTerm) {
        if (
          !(
            opt.value.toLowerCase().includes(searchTerm.toLowerCase()) ||
            opt.label.toLowerCase().includes(searchTerm.toLowerCase())
          )
        ) {
          return false;
        }
      }

      if (!selected.some((dt) => dt.value === opt.value)) {
        return true;
      }

      return false;
    });
  }, [options, selected, searchTerm]);

  const handleOnSelect = useCallback(
    ({ item }: { item: Option }) => {
      if (!selected.includes(item) && selected.length < (maxTags || options.length)) {
        const newSelected = [...selected, item];
        onSelect(newSelected, item);

        setSearchTerm("");
      }
    },
    [selected, onSelect, maxTags, options]
  );

  const handleOnChange = useCallback(({ value }: { value: string }) => {
    setSearchTerm(value);
  }, []);

  const handleOnBlur = useCallback(() => setSearchTerm(""), []);

  const handleClear = useCallback(() => {
    onSelect([], undefined);
  }, [onSelect]);

  const handleOnKeyDown = useCallback(
    ({
      event: { keyCode, currentTarget },
    }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event: { keyCode: number; currentTarget: any };
    }) => {
      // Remove tag on backspace if the cursor is at the beginning of the field

      if (keyCode === 8 /* Backspace */ && currentTarget.selectionEnd === 0) {
        const newSelected = [...selected.slice(0, -1)];
        onSelect(newSelected, undefined);
      }
    },
    [onSelect, selected]
  );

  const handleRemoveTag = useCallback(
    (removedValue: string) => {
      const newSelected = selected.filter((tagValue) => tagValue.value !== removedValue);
      onSelect(newSelected, undefined);
    },
    [selected, onSelect]
  );

  const renderedTags = useMemo(() => {
    return selected.map((option) => (
      <Tag
        key={option.value}
        accessibilityRemoveIconLabel={`Remove ${option.label} tag`}
        onRemove={() => handleRemoveTag(option.value)}
        text={option.label}
      />
    ));
  }, [selected, handleRemoveTag]);

  return (
    <GestaltComboBox
      {...props}
      inputValue={searchTerm}
      options={suggestedOptions}
      onKeyDown={handleOnKeyDown}
      onChange={handleOnChange}
      onClear={handleClear}
      onBlur={handleOnBlur}
      onSelect={handleOnSelect}
      placeholder={selected.length > 0 ? "" : props.placeholder}
      tags={renderedTags}
    />
  );
};
