import {
  Box,
  Button,
  ComboBox,
  Divider,
  Icon,
  Layer,
  Modal,
  Popover,
  SearchField,
  Table,
  TapArea,
  Text,
  Tooltip,
  modalZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { BoxWithRef } from "@prodoctivity/design-system/components/Box";
import type { HttpGetDocumentGroupListRequest } from "@prodoctivity/types";
import type { DocumentGroup } from "@prodoctivity/types/model/ecm";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";
import { EditSvgIcon } from "../../../svg/EditSvgIcon";
import { ClipboardSvgIcon } from "../../../svg/ClipboardSvgIcon";
import { noop, useHasClipboard } from "../../../utils";
import { NotificationMessage, ToastMessageType } from "../../../components/NotificationMessage";
import { useCopyToClipboard } from "usehooks-ts";

const PAGINATION_OPTIONS: HttpGetDocumentGroupListRequest["queryParameters"]["rowsPerPage"][] = [
  "15",
  "30",
  "100",
];

const DocumentGroupListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const { removeDocumentGroup } = useServices();
  const anchorRefItems = useRef<Array<HTMLDivElement | null>>([]);
  const [openItemId, setOpenItemId] = useState<string>();
  const { breakpoint } = useDesignBreakpoint();
  const [toastMessage, setToastMessage] = useState<ToastMessageType | undefined>(undefined);
  const [_value, copyToClipboard] = useCopyToClipboard();
  const { data: hasClipboard } = useHasClipboard();

  const [deleteInfo, setDeleteInfo] = useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { documentGroup: DocumentGroup; newDocumentGroup: any } | undefined
  >(undefined);

  const handleCopyToClipBoard = useCallback(() => {
    setToastMessage({ message: resources.dataDictionary.copyDataElementId, type: "success" });
  }, [resources.dataDictionary.copyDataElementId]);

  const {
    paginatedData,
    refetch,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setFilter,
    setPageLength,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    { documentGroups: DocumentGroup[] },
    HttpGetDocumentGroupListRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.getDocumentGroupList(currentPage, rowsPerPage, filter);
    },
    "settings_document_group_list",
    { refetchInterval: 60 * 1000 }
  );

  const onClickItem = useCallback(
    (id?: string) => {
      setOpenItemId(id === openItemId ? undefined : id);
    },
    [openItemId]
  );

  useEffect(() => {
    if (anchorRefItems && anchorRefItems.current && paginatedData) {
      anchorRefItems.current = anchorRefItems.current.slice(0, paginatedData.documentGroups.length);
    }
  }, [paginatedData]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const remove = useCallback(() => {
    if (deleteInfo) {
      removeDocumentGroup(deleteInfo.documentGroup.id, deleteInfo.newDocumentGroup.value).then(
        (_res) => {
          refetch();
          onClickItem(undefined);
          setDeleteInfo(undefined);
        }
      );
    }
  }, [deleteInfo, removeDocumentGroup, refetch, onClickItem]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: `${resources.documentaryStructure}`,
        url: organizationLinkTemplates.manageDocumentTypes(),
      },
      { type: "text", name: resources.documentGroups },
    ];
  }, [
    resources.documentGroups,
    resources.documentaryStructure,
    resources.home,
    resources.settings,
  ]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        {deleteInfo && (
          <Layer zIndex={modalZIndex}>
            <Modal
              accessibilityModalLabel={resources.deleteDocumentGroup}
              heading={
                <Box height={50}>
                  <Box margin={4}>
                    <Text weight="bold" size="400" color={colors.secondary}>
                      {resources.deleteDocumentGroup}
                    </Text>
                  </Box>
                  <Divider />
                </Box>
              }
              size="sm"
              onDismiss={() => setDeleteInfo(undefined)}
              footer={
                <Box display="flex">
                  <Box>
                    <Button
                      color={"transparent"}
                      onClick={() => setDeleteInfo(undefined)}
                      text={resources.cancel}
                    />
                  </Box>
                  <Box marginStart="auto">
                    <Button color={"red"} onClick={remove} text={resources.yesDelete} />
                  </Box>
                </Box>
              }
            >
              <Box margin={6} display="flex" justifyContent="center">
                <Text>
                  {resources.youAreAboutToDelete.replace(
                    "{documentGroupName}",
                    deleteInfo.documentGroup.name
                  )}
                </Text>
              </Box>
              <Divider />
            </Modal>
          </Layer>
        )}

        <SearchComponentWrapper
          componentHeaderText={resources.documentGroups}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="document-group-search"
          searchFieldLabel={resources.search}
          setFilter={setFilter}
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          buttonAction={() => organizationNavigate("/settings/document-groups/new")}
          omitSearch={true}
        />
        <Box margin={5} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="document_group_list_pagination_top"
            rowsLabel={`${resources.documentGroups}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.documentGroups.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            totalRowCount={totalRowCount}
            extraComponent={
              <Box display="flex" direction="row" gap={2} flex="grow">
                <SearchField
                  marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                  marginEnd={2}
                  accessibilityLabel={resources.documentGroup}
                  id="document_group_name_filter"
                  onChange={({ value }) => {
                    setFilter(value);
                  }}
                />
              </Box>
            }
          />
          <Divider direction="horizontal" />
        </Box>

        <Box
          marginTop={8}
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Table accessibilityLabel="Document Group Table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Box paddingX={4}>
                    <Text size="200" weight="bold" textTransform="uppercase">
                      {resources.name}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" weight="bold" textTransform="uppercase">
                      {resources.description}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" align="end" weight="bold" textTransform="uppercase">
                      {resources.actions}
                    </Text>
                  </Box>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(paginatedData?.documentGroups || []).map((tg, index) => (
                <Table.Row hoverStyle="gray" key={`${tg.id}`}>
                  <Table.Cell>
                    <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                      <Box>
                        <Text ellipsisLength={50} size="200" weight="bold">
                          {tg.name}
                        </Text>
                      </Box>
                      <Box width={1} borderStyle={"sm"} borderRadius={4} />
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <Text overflow="ellipsis" wrap={true} size="200">
                      {tg.description}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <BoxWithRef
                      ref={(ref) => (anchorRefItems.current[index] = ref)}
                      display="flex"
                      justifyContent="end"
                    >
                      <Box marginEnd={3}>
                        <EditSvgIcon
                          color={colors.primary}
                          onClick={() =>
                            organizationNavigate(
                              organizationLinkTemplates.editDocumentGroups(tg.id)
                            )
                          }
                        />
                      </Box>
                      <Box>
                        <Icon
                          color={colors.primary}
                          icon="trash"
                          accessibilityLabel="Remove"
                          onClick={() => onClickItem(tg.id)}
                        />
                      </Box>

                      {hasClipboard && (
                        <Box marginStart={3}>
                          <TapArea onTap={() => copyToClipboard(tg.id)}>
                            <Tooltip text={resources.copyIdToClipboard}>
                              <ClipboardSvgIcon
                                color={colors.primary}
                                width={21}
                                height={21}
                                onClick={() => handleCopyToClipBoard()}
                              />
                              {toastMessage && (
                                <NotificationMessage
                                  type={toastMessage?.type}
                                  message={toastMessage.message}
                                  position="bottom-left"
                                  handleDismiss={() => setToastMessage(undefined)}
                                  delay={3000}
                                />
                              )}
                            </Tooltip>
                          </TapArea>
                        </Box>
                      )}

                      {openItemId === tg.id && (
                        <Layer>
                          <Popover
                            anchor={anchorRefItems.current[index]}
                            onDismiss={noop}
                            idealDirection="down"
                            positionRelativeToAnchor={false}
                            size={"flexible"}
                            color="white"
                            role="menu"
                          >
                            <Box width={300} padding={2} color={colors.white}>
                              <ComboBox
                                id="moveTo"
                                placeholder={resources.moveTo}
                                label=""
                                accessibilityClearButtonLabel={resources.clear}
                                noResultText="There's no more document group"
                                onSelect={(e) => {
                                  setDeleteInfo({ documentGroup: tg, newDocumentGroup: e.item });
                                }}
                                size="lg"
                                options={(paginatedData?.documentGroups || [])
                                  .filter((group) => group.id !== tg.id)
                                  .map((group) => {
                                    return { value: group.id, label: group.name };
                                  })}
                              />
                            </Box>
                          </Popover>
                        </Layer>
                      )}
                    </BoxWithRef>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Box>
        <Box marginTop={5} padding={2} display="flex" direction="row">
          <Box display="flex" flex="grow">
            <Box display="flex" flex="grow" />
            <Box display="flex" flex="shrink">
              <Pagination<typeof rowsPerPage>
                id="document_group_list_pagination_bottom"
                rowsLabel={`${resources.documentGroups}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={paginatedData ? paginatedData.documentGroups.length : 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={PAGINATION_OPTIONS}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default DocumentGroupListPage;
