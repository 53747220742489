import { useQuery, useQueryClient } from "@tanstack/react-query";

import { mimeTypeToExtension } from "@prodoctivity/shared";
import FileSaver from "file-saver";
import JSZip from "jszip";
import React, { useCallback, useEffect } from "react";
import { AccountUser } from "./context";

export type PasswordStrength = {
  success: boolean;
  score: number;
  errors?: Array<{
    key: string;
    data: Record<string, string>;
  }>;
};

export type SignUpUser = Omit<AccountUser, "token" | "positionRole" | "username"> & {
  password: string;
  phone: string;
  isEnrolledForMarketing: boolean;
  termsAndPolicyCheck: boolean;
};

export type SignUpFieldsGroup = {
  account: {
    firstName: SignUpUser["firstName"];
    lastName: SignUpUser["lastName"];
    password: SignUpUser["password"];
    phone: SignUpUser["phone"];
    email: SignUpUser["email"];
    isEnrolledForMarketing: SignUpUser["isEnrolledForMarketing"];
  };

  company: {
    organizationName: SignUpUser["organizationName"];
    organizationId: SignUpUser["organizationId"];
    termsAndPolicyCheck: SignUpUser["termsAndPolicyCheck"];
  };
};

export type GoogleProfileObj = {
  iss: string;
  nbf: string;
  aud: string;
  email: string;
  email_verified: boolean;
  azp: string;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  iat: number;
  exp: number;
  jti: string;
};

export const emptyArray = [];

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

export function sleep(ms: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function useHasClipboard() {
  return useQuery(["has-clipboard"], async () => {
    if (window.navigator.clipboard !== undefined) {
      return true;
    }
    return false;
  });
}

export function downloadZipFile(name: string, mimeType: string, binaries: string[]) {
  const zip = new JSZip();

  binaries.forEach((data, idx) => {
    zip.file(`page_${idx + 1}${mimeTypeToExtension(mimeType)}`, data, {
      base64: true,
    });
  });
  zip.generateAsync({ type: "blob" }).then(function (content) {
    FileSaver.saveAs(content, `${name}.zip`);
  });
}

export function useCacheManager() {
  const queryClient = useQueryClient();

  const clearQueryCache = useCallback(() => {
    queryClient.clear();
  }, [queryClient]);

  if ((window as any).clearQueryCache !== clearQueryCache) {
    (window as any).clearQueryCache = clearQueryCache;
  }

  return { clearQueryCache };
}

export function useOutsideClick(ref: React.RefObject<HTMLElement>, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export function useAutoFocus(ref: React.RefObject<HTMLInputElement>, condition: boolean) {
  useEffect(() => {
    if (condition && ref.current) {
      ref.current.focus();
    }
  }, [condition, ref]);
}
