"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HighlightColorValue = exports.GraphicsUnit = exports.FontStyle = void 0;
var FontStyle;
(function (FontStyle) {
    FontStyle[FontStyle["Regular"] = 0] = "Regular";
    FontStyle[FontStyle["Bold"] = 1] = "Bold";
    FontStyle[FontStyle["Italic"] = 2] = "Italic";
    FontStyle[FontStyle["Underline"] = 4] = "Underline";
    FontStyle[FontStyle["Strikeout"] = 8] = "Strikeout";
})(FontStyle || (exports.FontStyle = FontStyle = {}));
var GraphicsUnit;
(function (GraphicsUnit) {
    GraphicsUnit["World"] = "World";
    GraphicsUnit["Display"] = "Display";
    GraphicsUnit["Pixel"] = "Pixel";
    GraphicsUnit["Point"] = "Point";
    GraphicsUnit["Inch"] = "Inch";
    GraphicsUnit["Document"] = "Document";
    GraphicsUnit["Millimeter"] = "Millimeter";
})(GraphicsUnit || (exports.GraphicsUnit = GraphicsUnit = {}));
var HighlightColorValue;
(function (HighlightColorValue) {
    HighlightColorValue[HighlightColorValue["Black"] = 0] = "Black";
    HighlightColorValue[HighlightColorValue["Blue"] = 1] = "Blue";
    HighlightColorValue[HighlightColorValue["Cyan"] = 2] = "Cyan";
    HighlightColorValue[HighlightColorValue["Green"] = 3] = "Green";
    HighlightColorValue[HighlightColorValue["Magenta"] = 4] = "Magenta";
    HighlightColorValue[HighlightColorValue["Red"] = 5] = "Red";
    HighlightColorValue[HighlightColorValue["Yellow"] = 6] = "Yellow";
    HighlightColorValue[HighlightColorValue["White"] = 7] = "White";
    HighlightColorValue[HighlightColorValue["DarkBlue"] = 8] = "DarkBlue";
    HighlightColorValue[HighlightColorValue["DarkCyan"] = 9] = "DarkCyan";
    HighlightColorValue[HighlightColorValue["DarkGreen"] = 10] = "DarkGreen";
    HighlightColorValue[HighlightColorValue["DarkMagenta"] = 11] = "DarkMagenta";
    HighlightColorValue[HighlightColorValue["DarkRed"] = 12] = "DarkRed";
    HighlightColorValue[HighlightColorValue["DarkYellow"] = 13] = "DarkYellow";
    HighlightColorValue[HighlightColorValue["DarkGray"] = 14] = "DarkGray";
    HighlightColorValue[HighlightColorValue["LightGray"] = 15] = "LightGray";
    HighlightColorValue[HighlightColorValue["None"] = 16] = "None";
})(HighlightColorValue || (exports.HighlightColorValue = HighlightColorValue = {}));
