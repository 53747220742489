import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const LandingPageS7Icon2: FunctionComponent<Props> = ({
  customClassName,
  width = 46,
  height = 46,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 46 46`}
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g transform="translate(0.324 -0.23)">
        <ellipse
          cx="20"
          cy="20.5"
          rx="20"
          ry="20.5"
          transform="translate(2.676 2.23)"
          fill="rgba(233,112,37,0.3)"
          opacity="0.5"
        />
        <circle
          cx="23"
          cy="23"
          r="23"
          transform="translate(-0.324 0.23)"
          fill="rgba(30,57,78,0.15)"
          opacity="0.3"
        />
        <circle
          cx="17"
          cy="17"
          r="17"
          transform="translate(5.676 6.23)"
          fill="rgba(233,112,37,0.67)"
        />
        <path
          d="M13.626.679,10.754.016A.667.667,0,0,0,9.994.4L8.669,3.493a.661.661,0,0,0,.191.773l1.674,1.37A10.235,10.235,0,0,1,5.639,10.53L4.269,8.856A.662.662,0,0,0,3.5,8.666L.4,9.991a.671.671,0,0,0-.387.762l.663,2.872a.663.663,0,0,0,.646.514A12.813,12.813,0,0,0,14.139,1.325.662.662,0,0,0,13.626.679Z"
          transform="translate(15.629 16.016)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
