import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const IndicatorsDocumentTypeIconSvg: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12V16.5C20 16.8978 20.158 17.2794 20.4393 17.5607C20.7206 17.842 21.1022 18 21.5 18H26V26.5C26 26.8978 25.842 27.2794 25.5607 27.5607C25.2794 27.842 24.8978 28 24.5 28H15.5C15.1022 28 14.7206 27.842 14.4393 27.5607C14.158 27.2794 14 26.8978 14 26.5V13.5C14 13.1022 14.158 12.7206 14.4393 12.4393C14.7206 12.158 15.1022 12 15.5 12H20ZM21 12.25V16.5C21 16.6326 21.0527 16.7598 21.1464 16.8536C21.2402 16.9473 21.3674 17 21.5 17H25.75L21 12.25Z"
        fill={calculateColor(color || colors.neutral900)}
      />
    </svg>
  );
};
