import {
  Box,
  BoxWithRef,
  Button,
  PopoverMenu,
  Skeleton,
  StandardIconContainer,
  Text,
  useColors,
  useComponentBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useRef } from "react";
import { FancyDateTime } from "@prodoctivity/design-system/components/FancyDateTime";
import type { DocumentCollectionTask } from "@prodoctivity/types";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";
import { ReviewTaskSvg } from "../../../svg/Tasks/ReviewTaskSvg";
import { RoundIndicator } from "../../../svg/Tasks/RoundIndicator";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  item: DocumentCollectionTask<number> & {
    id: string;
    createdAt: number;
  };
  itemIdx: number;
  isWidget: boolean;
  accordionWidth: number | undefined;

  mainContentEllipsisOptions: string[];
};

export const DocumentCollectionIssueEntry: FunctionComponent<Props> = ({
  item,
  itemIdx,
  isWidget,
  accordionWidth,
  mainContentEllipsisOptions,
}) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { width: rowWidth } = useComponentBreakpoint(containerRef, "large");

  return (
    <BoxWithRef ref={containerRef} key={`${itemIdx}-${item.id}`}>
      <Box
        display="flex"
        flex="grow"
        alignItems="center"
        justifyContent="start"
        maxHeight={accordionWidth && accordionWidth < 700 ? undefined : 60}
        paddingY={3}
        color={itemIdx % 2 === 0 ? colors.primaryHover0 : undefined}
        borderRadius={6}
        borderColor={colors.white}
        direction={accordionWidth && accordionWidth < 700 ? "column" : "row"}
      >
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          gap={4}
          column={isWidget ? undefined : accordionWidth && accordionWidth > 950 ? 5 : 8}
          paddingLeft={2}
          paddingRight={isWidget ? 6 : 3}
          width={isWidget || (accordionWidth && accordionWidth < 700) ? "100%" : undefined}
        >
          <Box display="flex" justifyContent="end">
            <StandardIconContainer>
              <ReviewTaskSvg />
            </StandardIconContainer>
          </Box>
          <Box width={rowWidth && rowWidth < 400 ? 200 : 300}>
            <Text
              overflow="ellipsis"
              size="200"
              color={colors.black600}
              title={`${item.instanceName}`}
            >
              {!rowWidth ? <Skeleton height={16} width={"100%"} /> : `${item.instanceName}`}
            </Text>
            {!rowWidth ? <Box height={8}></Box> : null}
            <Text
              overflow="ellipsis"
              size="200"
              color={colors.neutral900}
              title={`${item.configName}`}
            >
              {!rowWidth ? (
                <Skeleton height={10} width={"100%"} />
              ) : (
                <Box display="flex" gap={1}>
                  {!item.username ? (
                    resources.unassigned
                  ) : (
                    <PublicProfile color={colors.neutral900} username={item.username} />
                  )}
                  {"-"}
                  <Text
                    size="200"
                    color={colors.neutral900}
                    overflow="ellipsis"
                  >{`${item.configName}`}</Text>
                </Box>
              )}
            </Text>
          </Box>
        </Box>
        {!isWidget && accordionWidth && accordionWidth > 950 && (
          <Box display="flex" justifyContent="start" column={3} paddingRight={3}>
            <Text overflow="ellipsis" size="200" color={colors.black600} title={resources.normal}>
              {resources.normal}
            </Text>
          </Box>
        )}
        {!isWidget && accordionWidth && accordionWidth > 700 && (
          <Box
            display="flex"
            justifyContent="start"
            column={accordionWidth && accordionWidth > 950 ? 1 : 2}
            paddingRight={3}
          >
            <Text
              overflow="ellipsis"
              size="200"
              color={colors.black600}
              title={new Date(item.expirationDate).toUTCString()}
            >
              <FancyDateTime
                moment={moment}
                resources={resources}
                color={colors.black600}
                size="200"
                value={item.expirationDate}
              />
            </Text>
          </Box>
        )}
        {!isWidget && accordionWidth && accordionWidth > 950 && (
          <Box display="flex" justifyContent="start" column={1}>
            <Text size="200" color={colors.black600}>
              {item.state === "open" && resources.pending}
            </Text>
          </Box>
        )}
        {!isWidget && accordionWidth && accordionWidth > 700 && (
          <Box column={accordionWidth && accordionWidth > 950 ? 1 : 2} paddingX={2}>
            <Box minWidth={accordionWidth && accordionWidth < 1100 ? 90 : undefined}>
              <Button
                text={resources.open}
                fullWidth
                onClick={() => {
                  organizationNavigate(organizationLinkTemplates.taskDetail(item.id, true));
                }}
              ></Button>
            </Box>
          </Box>
        )}

        {isWidget || (accordionWidth && accordionWidth < 700) ? (
          <Box
            onClickCapture={() => {
              organizationNavigate(organizationLinkTemplates.taskDetail(item.id, true));
            }}
            display="flex"
            alignItems={"center"}
            justifyContent="end"
            marginTop={1}
            gap={2}
            width={"100%"}
          >
            <Box display="flex" direction="column" paddingRight={2} minWidth={170}>
              {accordionWidth && accordionWidth > 700 && (
                <Box display="flex" gap={1}>
                  <Text size="200">{resources.workflow.priority}: </Text>
                  <Text size="200" color={colors.black600} weight={"normal"}>
                    {resources.normal}
                  </Text>
                </Box>
              )}
              <Box display="flex" alignItems="center" gap={2}>
                {item.expirationDate > Date.now() ? (
                  <RoundIndicator color="green" />
                ) : (
                  <RoundIndicator color="red" />
                )}
                <Text size="100" weight="normal" color={colors.subtle}>
                  {item.expirationDate > Date.now() ? resources.expires : resources.expired}{" "}
                  {moment(item.expirationDate).fromNow()}
                </Text>
              </Box>
            </Box>
            <Box display="flex" paddingRight={2}>
              <Button
                onClick={() => {
                  organizationNavigate(organizationLinkTemplates.taskDetail(item.id, true));
                }}
                text={resources.open}
                iconEnd="directional-arrow-right"
                color={"gray"}
                size="md"
              />
            </Box>
          </Box>
        ) : (
          <Box column={1} display="flex" justifyContent="end" alignItems="center">
            <PopoverMenu
              handleFunctionsBundle={[]}
              menuLabels={mainContentEllipsisOptions}
              popoverPanelWidth={180}
              collapsibleMenuIcon="verticalEllipsis"
              menuHasIcons={false}
              srcAreImages={undefined}
              bundleNeedsParameters={false}
            />
          </Box>
        )}
      </Box>
    </BoxWithRef>
  );
};
