import { Box, Heading, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { Page } from "../../components/Layout/Page";
import { Error404IconSvg } from "../../svg/404Error";

export function Svg() {
  return (
    <Box>
      <Error404IconSvg customClassName="icon icon-tabler icon-tabler-error-404" />
    </Box>
  );
}

export const PageNotFound: FunctionComponent<{ message: string }> = ({ message }) => {
  const { colors } = useColors();
  return (
    <Page>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        direction="column"
      >
        <Box>
          <Box height={"auto"} width={600} direction="column">
            <Error404IconSvg />
          </Box>
          <Box>
            <Box display="flex" justifyContent="center">
              <Heading size="600" color={colors.black900}>
                {message}
              </Heading>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};
