// @flow

import * as React from "react";

type Props = {
  children: React.ReactNode;
};

type State = {
  error: any;
  info: {
    componentStack: string;
  } | null;
};

export class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(
    error: any,
    info: {
      componentStack: string;
    }
  ) {
    this.setState({ error, info });
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h3 style={{ color: "red" }}>An error has occurred</h3>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}
