import {
  UnsignedUpTo12,
  useColors,
  useComponentBreakpoint,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { LeftSidebarProps } from "@prodoctivity/design-system/components/layout/LeftSidebar";
import type {
  HttpGetTaskCollectionListRequest,
  HttpGetTaskCollectionListResponse,
  HttpGetTaskCommentsRequest,
  HttpGetTaskCommentsResponse,
} from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useCopyToClipboard } from "usehooks-ts";
import { useUserPublicProfile } from "../../hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { AllTasksSvgIcon } from "../../svg/AllTasksSvgIcon";
import { FinishedTasksSvgIcon } from "../../svg/FinishedTasksSvgIcon";
import { MyTasksSvgIcon } from "../../svg/MyTasksSvgIcon";
import { PendingTasksSvgIcon } from "../../svg/PendingTasksSvgIcon";
import { useHasClipboard } from "../../utils";
import { BreadCrumbEntry } from "../BreadCrumb";
import { usePaginatedDataEndpoint } from "../hooks";
import { ToastMessageType } from "../NotificationMessage";

export type ColumnHeader = {
  name: string;
  span: UnsignedUpTo12;
};

export type ProcessListType = "all" | "priorities" | "my-tasks" | "finished" | "unclaimed";

export type DetailListLabels = {
  id: string;
  processId: string;
  workflowProcessId: string;
  workflowName: string;
  workflowTaskName: string;
  username: string | undefined;
  state: string;
  expirationDate: string;
  workflowTaskPriority: string;
  createdAt: string;
};

export type TaskArguments = {
  workflowId: string | undefined;
  priority: boolean | undefined;
  status: "open" | "done" | undefined;
  myTask: boolean | undefined;
  processFilter: string | undefined;
  workflowTaskName: boolean | undefined;
};

export function useHomeTasks() {
  const { getWorkflowTypeList } = useServices();
  const [taskDetailsPanel, setTaskDetailsPanel] = useState<
    | {
        taskId: string;
        processId: string;
        taskStageName: string | undefined;
      }
    | undefined
  >(undefined);

  const fetchWorkflowTypeList = useCallback(async () => {
    try {
      return await getWorkflowTypeList();
    } catch (error) {
      console.log(error);
    }
  }, [getWorkflowTypeList]);

  const {
    data: workflowTypeList,
    isLoading,
    refetch,
  } = useOrganizationQuery(`workflow/type-list`, fetchWorkflowTypeList, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return {
    workflowTypeList,
    isLoading,
    refetch,
    taskDetailsPanel,
    setTaskDetailsPanel,
  };
}

export function useInboxPage() {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const tableHeadersRef = useRef<HTMLDivElement | null>(null);
  const { width: headersWidth } = useComponentBreakpoint(tableHeadersRef, "large");
  const [isOpen, setIsOpen] = useState(true);
  const handleSetIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);
  const [openAccordions, setOpenAccordions] = useState<Set<string>>(new Set());
  const onAnyAccordionOpen = useCallback((id: string, isOpen: boolean) => {
    setOpenAccordions((prevOpenAccordions) => {
      const newOpenAccordions = new Set(prevOpenAccordions);
      if (isOpen) {
        newOpenAccordions.add(id);
      } else {
        newOpenAccordions.delete(id);
      }
      return newOpenAccordions;
    });
  }, []);
  const resetOpenAccordions = useCallback(() => {
    setOpenAccordions(new Set());
  }, []);

  const isAnyAccordionOpen = openAccordions.size > 0;

  const [taskDetailsPanel, setTaskDetailsPanel] = useState<
    | {
        taskId: string;
        processId: string;
        taskStageName: string | undefined;
      }
    | undefined
  >(undefined);
  const [processFilter, setProcessFilter] = useState<string>("");
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(resources.pending);
  const organizationNavigate = useOrganizationNavigate();

  const setSelectedMenuItemCallback = useCallback(
    (item: string) => {
      let param = "unclaimed";
      switch (item) {
        case resources.all:
          param = "all";
          break;
        case resources.workflow.priority:
          param = "priorities";
          break;
        case resources.homePage.myTasks:
          param = "my-tasks";
          break;
        case resources.workflow.finished:
          param = "finished";
          break;
        case resources.pending:
          param = "unclaimed";
          break;
        default:
          param = "unclaimed";
          break;
      }
      setSelectedMenuItem(item);
      organizationNavigate(organizationLinkTemplates.tasks(param));
      resetOpenAccordions();
    },
    [
      organizationNavigate,
      resetOpenAccordions,
      resources.all,
      resources.homePage.myTasks,
      resources.pending,
      resources.workflow.finished,
      resources.workflow.priority,
    ]
  );
  const { filter } = useParams();
  const [listType, setListType] = useState<ProcessListType>(filter as ProcessListType);
  const [dateFilter, setDateFilter] = useState<{
    fromDate: Date | undefined;
    toDate: Date | undefined;
  }>({
    fromDate: undefined,
    toDate: undefined,
  });

  const handleChangeDate = useCallback(
    (d: { value: Date | null | undefined }) => {
      setDateFilter({
        ...dateFilter,
        fromDate: d.value == null ? undefined : new Date(d.value),
      });
      resetOpenAccordions();
    },
    [dateFilter, resetOpenAccordions]
  );
  const handleChangeDateTo = useCallback(
    (d: { value: Date | null | undefined }) => {
      setDateFilter({
        ...dateFilter,
        toDate: d.value == null ? undefined : new Date(d.value),
      });
      resetOpenAccordions();
    },
    [dateFilter, resetOpenAccordions]
  );

  const { getWorkflowTypeList } = useServices();

  const fetchWorkflowTypeList = useCallback(async () => {
    try {
      return await getWorkflowTypeList();
    } catch (error) {
      console.log(error);
    }
  }, [getWorkflowTypeList]);

  const { data: workflowTypeList, isLoading } = useOrganizationQuery(
    `workflow/type-list`,
    fetchWorkflowTypeList,
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  // ---------------------------------------------------------------------------------------

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "text",
        name: resources.tasks,
      },
    ];
  }, [resources.home, resources.tasks]);

  const columnHeaders: ColumnHeader[] = useMemo(() => {
    return [
      {
        name: resources.workflow.priority,
        span: 3,
      },
      {
        name: resources.expires,
        span: 1,
      },
      {
        name: resources.status,
        span: 1,
      },
      {
        name: resources.actions,
        span: 1,
      },
    ];
  }, [resources.actions, resources.expires, resources.status, resources.workflow.priority]);

  const initialFilters = useMemo(() => {
    return {
      fromDate: undefined,
      toDate: undefined,
      processFilter: "",
      listType: "",
    };
  }, []);

  // Calls for counters in sidebar

  const { totalRowCount: totalCountAll } = usePaginatedDataEndpoint<
    HttpGetTaskCollectionListResponse["payload"],
    HttpGetTaskCollectionListRequest["queryParameters"]["rowsPerPage"],
    {
      fromDate: number | undefined;
      toDate: number | undefined;
      processFilter: string;
      listType: string;
    }
  >(
    "5",
    initialFilters,
    (services, currentPage, rowsPerPage) => {
      return services.getTaskCollectionList(
        undefined,
        currentPage,
        rowsPerPage,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
    },
    `workflow/task-list/all-${processFilter}-${listType}`
  );

  const { totalRowCount: totalCountPending } = usePaginatedDataEndpoint<
    HttpGetTaskCollectionListResponse["payload"],
    HttpGetTaskCollectionListRequest["queryParameters"]["rowsPerPage"],
    {
      fromDate: number | undefined;
      toDate: number | undefined;
      processFilter: string;
      listType: string;
    }
  >(
    "5",
    initialFilters,
    (services, currentPage, rowsPerPage) => {
      return services.getTaskCollectionList(
        undefined,
        currentPage,
        rowsPerPage,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        undefined
      );
    },
    `workflow/task-list/pending-${processFilter}-${listType}`
  );

  const { totalRowCount: totalCountMyTasks } = usePaginatedDataEndpoint<
    HttpGetTaskCollectionListResponse["payload"],
    HttpGetTaskCollectionListRequest["queryParameters"]["rowsPerPage"],
    {
      fromDate: number | undefined;
      toDate: number | undefined;
      processFilter: string;
      listType: string;
    }
  >(
    "5",
    initialFilters,
    (services, currentPage, rowsPerPage) => {
      return services.getTaskCollectionList(
        undefined,
        currentPage,
        rowsPerPage,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        "",
        undefined,
        undefined
      );
    },
    `workflow/task-list/my-tasks-${processFilter}-${listType}`
  );

  const { totalRowCount: totalCountFinished } = usePaginatedDataEndpoint<
    HttpGetTaskCollectionListResponse["payload"],
    HttpGetTaskCollectionListRequest["queryParameters"]["rowsPerPage"],
    {
      fromDate: number | undefined;
      toDate: number | undefined;
      processFilter: string;
      listType: string;
    }
  >(
    "5",
    initialFilters,
    (services, currentPage, rowsPerPage) => {
      return services.getTaskCollectionList(
        undefined,
        currentPage,
        rowsPerPage,
        undefined,
        undefined,
        undefined,
        "done",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
    },
    `workflow/task-list/finished-${processFilter}-${listType}`
  );

  const filteredResources = useMemo(() => {
    switch (listType) {
      case "all":
        return resources.all;
      case "priorities":
        return resources.workflow.priority;
      case "my-tasks":
        return resources.homePage.myTasks;
      case "finished":
        return resources.workflow.finished;
      case "unclaimed":
        return resources.pending;
      default:
        return resources.pending;
    }
  }, [
    listType,
    resources.all,
    resources.homePage.myTasks,
    resources.pending,
    resources.workflow.finished,
    resources.workflow.priority,
  ]);

  const options: LeftSidebarProps["options"] = useMemo(() => {
    return [
      {
        name: resources.pending,
        svgIcon: PendingTasksSvgIcon,
        onTap: () => setListType("unclaimed"),
        icon: undefined,
        color: undefined,
        totalCount: totalCountPending,
      },
      {
        name: resources.homePage.myTasks,
        svgIcon: MyTasksSvgIcon,
        onTap: () => setListType("my-tasks"),
        icon: undefined,
        color: undefined,
        totalCount: totalCountMyTasks,
      },
      {
        name: resources.workflow.finished,
        svgIcon: FinishedTasksSvgIcon,
        onTap: () => setListType("finished"),
        icon: undefined,
        color: undefined,
        totalCount: totalCountFinished,
      },
      {
        name: resources.all,
        svgIcon: AllTasksSvgIcon,
        onTap: () => setListType("all"),
        icon: undefined,
        color: undefined,
        totalCount: totalCountAll,
      },
    ];
  }, [
    resources.all,
    resources.homePage.myTasks,
    resources.pending,
    resources.workflow.finished,
    totalCountAll,
    totalCountFinished,
    totalCountMyTasks,
    totalCountPending,
  ]);

  const filteredResourceIndex = useMemo(() => {
    return options.findIndex((b) => b.name === filteredResources);
  }, [filteredResources, options]);

  return {
    resources,
    colors,
    breakpoint,
    isOpen,
    handleSetIsOpen,
    taskDetailsPanel,
    setTaskDetailsPanel,
    breadCrumbEntries,
    columnHeaders,
    options,
    isLoading,
    setProcessFilter,
    dateFilter,
    handleChangeDate,
    handleChangeDateTo,
    selectedMenuItem,
    setSelectedMenuItem,
    setSelectedMenuItemCallback,
    workflowTypeList,
    processFilter,
    listType,
    filteredResourceIndex,
    tableHeadersRef,
    headersWidth,
    openAccordions,
    isAnyAccordionOpen,
    onAnyAccordionOpen,
    resetOpenAccordions,
  };
}

export function useTaskDetailsPanel(taskSelected: { taskId: string; processId: string }) {
  const { colors } = useColors();
  const { updateTaskComment, insertTaskComment, user, getWorkflowTaskDetails } = useServices();
  const { resources, moment } = useAppTranslation();
  const [toastMessage, setToastMessage] = useState<ToastMessageType | undefined>(undefined);
  const [_value, copyToClipboard] = useCopyToClipboard();
  const { data: hasClipboard } = useHasClipboard();

  const handleCopyToClipBoard = useCallback(() => {
    setToastMessage({ message: resources.dataDictionary.copyDataElementId, type: "success" });
  }, [resources.dataDictionary.copyDataElementId]);

  const fetchTaskDetails = useCallback(async () => {
    try {
      return await getWorkflowTaskDetails(taskSelected.taskId);
    } catch (error) {
      console.log(error);
    }
  }, [getWorkflowTaskDetails, taskSelected]);

  const { data: taskDetails, isLoading } = useOrganizationQuery(
    `workflow/task-detail/${taskSelected.taskId}`,
    fetchTaskDetails,
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: userProfile } = useUserPublicProfile(
    taskDetails?.workflowTask.username || "",
    undefined
  );

  const {
    isLoading: isLoadingComments,
    paginatedData: comments,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    refetch: refetchComments,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount: commentsTotal,
  } = usePaginatedDataEndpoint<
    HttpGetTaskCommentsResponse["payload"],
    HttpGetTaskCommentsRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "5",
    "",
    (services, currentPage, rowsPerPage) => {
      return services.getTaskComments(
        undefined,
        taskSelected.processId,
        currentPage,
        rowsPerPage,
        undefined
      );
    },
    `workflow/task-comments/${taskSelected.processId}`
  );

  const { mutate: insertComment } = useMutation(insertTaskComment, {
    onSuccess: () => {
      setPageLength("5");
      refetchComments();
    },
    onError: () => {
      refetchComments();
    },
  });

  const addComment = useCallback(
    (arg: {
      taskId: string | undefined;
      processId: string | undefined;
      content: string;
      parentCommentId: string | undefined;
      commentSubtitle: string | undefined;
    }) => {
      return insertComment(arg);
    },
    [insertComment]
  );

  const { mutate: updateComment } = useMutation(updateTaskComment, {
    onSuccess: () => {
      refetchComments();
    },
    onError: () => {
      refetchComments();
    },
  });

  const updateCommentList = useCallback(
    (arg: { content: string; taskCommentId: string }) => {
      return updateComment(arg);
    },
    [updateComment]
  );

  const detailListLabels: DetailListLabels = useMemo(() => {
    return {
      id: resources.workflow.taskId,
      processId: resources.workflow.processId,
      workflowProcessId: resources.workflow.workflowProcessId,
      createdAt: resources.createdAt,
      username: resources.assignedTo,
      expirationDate: resources.workflow.dueDate,
      state: resources.status,
      workflowName: resources.workflow.workflowType,
      workflowTaskName: resources.phase,
      workflowTaskPriority: resources.workflow.priority,
    };
  }, [
    resources.assignedTo,
    resources.createdAt,
    resources.phase,
    resources.status,
    resources.workflow.dueDate,
    resources.workflow.priority,
    resources.workflow.processId,
    resources.workflow.taskId,
    resources.workflow.workflowProcessId,
    resources.workflow.workflowType,
  ]);

  return {
    user,
    userProfile,
    colors,
    resources,
    moment,
    taskDetails,
    detailListLabels,
    isLoading,
    updateCommentList,
    addComment,
    comments,
    isLoadingComments,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    pageLengthOptions: ["5", "15", "50", "100"] as Array<typeof rowsPerPage>,
    commentsTotal,
    refetchComments,
    toastMessage,
    copyToClipboard,
    hasClipboard,
    handleCopyToClipBoard,
    setToastMessage,
  };
}

export function useProcessAccordion(
  workflowId: string,
  fromDate: number | undefined,
  toDate: number | undefined,
  processFilter: string,
  listType: string,
  taskType:
    | "document-collection-task"
    | "document-approval-task"
    | "workflow-task"
    | "template-context-state"
    | undefined
) {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const [prevSelectedMenuItem, setPrevSelectedMenuItem] = useState<{
    fromDate: number | undefined;
    toDate: number | undefined;
    processFilter: string;
    listType: string;
  }>({
    fromDate,
    toDate,
    processFilter,
    listType,
  });
  const [success] = useSnackbar({ style: { backgroundColor: "#008753" } });
  const [fail] = useSnackbar({ style: { backgroundColor: "#CC0000" } });
  const { claimWorkflowTask } = useServices();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useComponentBreakpoint(containerRef, "large");

  const taskArguments = useMemo(() => {
    if (taskType === undefined || taskType === "workflow-task") {
      const workflowArguments: TaskArguments = {
        workflowId,
        priority: listType === "priorities",
        status: listType === "finished" ? "done" : undefined,
        myTask: listType === "my-tasks",
        processFilter,
        workflowTaskName: listType === "unclaimed",
      };
      return workflowArguments;
    } else {
      const prodoctivityTaskArguments: TaskArguments = {
        workflowId: undefined,
        priority: listType === "priorities",
        status: listType === "finished" ? "done" : undefined,
        myTask: listType === "my-tasks",
        processFilter: undefined,
        workflowTaskName: listType === "unclaimed",
      };
      return prodoctivityTaskArguments;
    }
  }, [listType, processFilter, taskType, workflowId]);

  const {
    currentPage,
    isLoading,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    paginatedData: workflowTaskList,
    previousPage,
    rowsPerPage,
    refetch,
    setPageLength,
    setFilter,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    HttpGetTaskCollectionListResponse["payload"],
    HttpGetTaskCollectionListRequest["queryParameters"]["rowsPerPage"],
    {
      fromDate: number | undefined;
      toDate: number | undefined;
      processFilter: string;
      listType: string;
    }
  >(
    "5",
    prevSelectedMenuItem,
    (services, currentPage, rowsPerPage) => {
      return services.getTaskCollectionList(
        taskArguments.workflowId,
        currentPage,
        rowsPerPage,
        fromDate,
        toDate,
        taskArguments.priority,
        taskArguments.status,
        undefined,
        taskArguments.myTask,
        undefined,
        taskArguments.processFilter,
        taskArguments.workflowTaskName,
        taskType
      );
    },
    `workflow/task-list/${workflowId}-${fromDate}-${toDate}-${processFilter}-${listType}`
  );

  useEffect(() => {
    if (prevSelectedMenuItem.fromDate !== fromDate) {
      setPrevSelectedMenuItem((prev) => {
        setFilter((prev) => {
          return {
            ...prev,
            fromDate,
          };
        });
        return {
          ...prev,
          fromDate,
        };
      });
    }
    if (prevSelectedMenuItem.toDate !== toDate) {
      setPrevSelectedMenuItem((prev) => {
        setFilter((prev) => {
          return {
            ...prev,
            toDate,
          };
        });
        return {
          ...prev,
          toDate,
        };
      });
    }
    if (prevSelectedMenuItem.processFilter !== processFilter) {
      setPrevSelectedMenuItem((prev) => {
        setFilter((prev) => {
          return {
            ...prev,
            processFilter,
          };
        });
        return {
          ...prev,
          processFilter,
        };
      });
    }
    if (prevSelectedMenuItem.listType !== listType) {
      setPrevSelectedMenuItem((prev) => {
        setFilter((prev) => {
          return {
            ...prev,
            listType,
          };
        });
        return {
          ...prev,
          listType,
        };
      });
    }
  }, [
    setPageLength,
    setFilter,
    fromDate,
    toDate,
    processFilter,
    listType,
    prevSelectedMenuItem.fromDate,
    prevSelectedMenuItem.toDate,
    prevSelectedMenuItem.processFilter,
    prevSelectedMenuItem.listType,
  ]);

  const updateAssignedTo = useCallback(
    (taskId: string) => {
      return claimWorkflowTask(taskId);
    },
    [claimWorkflowTask]
  );

  const { mutate: claimTask } = useMutation(updateAssignedTo, {
    onSuccess: () => {
      success(resources.accountPage.successApplyingChange);
      refetch();
    },
    onError: () => {
      fail(resources.workflow.errorModifyingTask);
      refetch();
    },
  });

  const mainContentEllipsisOptions = useMemo(() => {
    return [
      resources.activities.viewDetail,
      resources.assign,
      resources.reassign,
      resources.suspend,
      resources.cancel,
      resources.reactivate,
    ];
  }, [
    resources.activities.viewDetail,
    resources.assign,
    resources.cancel,
    resources.reactivate,
    resources.reassign,
    resources.suspend,
  ]);

  return {
    resources,
    colors,
    workflowTaskList,
    isLoading,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount,
    pageLengthOptions: ["5", "15", "50", "100"] as Array<typeof rowsPerPage>,
    claimTask,
    containerRef,
    accordionWidth: width,
    mainContentEllipsisOptions,
    refetch,
  };
}
