import { Box, MarkdownViewer, Table, Text, useColors } from "@prodoctivity/design-system";
import { Link } from "react-router-dom";
import { TermsAndConditionsPolicySvg } from "../../svg/TermsAndConditionsPolicySvg";
import { VisitorsFooter } from "../LandingPage/VisitorsFooter";
import { VisitorsHeader } from "../LandingPage/VisitorsHeader";
import { useLandingPageLogic } from "../LandingPage/hooks";

export function PrivacyPolicy() {
  const { breakpoint, breakPointSmallDuo, resources } = useLandingPageLogic();
  const { colors } = useColors();
  return (
    <Box color={colors.neutral300}>
      <VisitorsHeader />
      <Box display="flex" justifyContent="center">
        <Box width={"75%"} display="flex" gap={2} direction="column" marginBottom={8}>
          <Box display="flex" justifyContent="center" marginTop={8}>
            <TermsAndConditionsPolicySvg width={600} height={300} />
          </Box>
          <Box display="flex" gap={5} justifyContent="center" marginTop={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.privacyPolicy}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.privacyPolicyPage.privacyPolicyDescription}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.personalInformationWeCollect}
            </Text>
          </Box>

          <Text size="400">{resources.privacyPolicyPage.informationYouProvide}</Text>

          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.identificationInformation}{" "}
              </Text>
              {resources.privacyPolicyPage.personalInformationDetails}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.communications}{" "}
              </Text>
              {resources.privacyPolicyPage.communicationDescription}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.paymentAndTransactionalInformation}{" "}
              </Text>
              {resources.privacyPolicyPage.orderProcessingInformation}
            </Text>
          </Box>
          <Box paddingLeft={5}>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                - Plaid:
              </Text>{" "}
              {resources.privacyPolicyPage.plaidApiInformation}{" "}
              <Link to="/privacy-policy">{resources.privacyPolicyPage.plaidPrivacyPolicy}</Link>
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.marketingInformation}{" "}
              </Text>
              {resources.privacyPolicyPage.marketingPreferences}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.thirdPartyAccountInformation}{" "}
              </Text>
              {resources.privacyPolicyPage.thirdPartyAccountConnection}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.locationInformation}{" "}
              </Text>
              {resources.privacyPolicyPage.collectedInformation}
            </Text>
          </Box>

          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.derivedInformation}{" "}
              </Text>
              {resources.privacyPolicyPage.inferredPreferences}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.thirdPartySources}{" "}
              </Text>
              {resources.privacyPolicyPage.combinedPersonalInformation}
            </Text>
          </Box>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer markdownText={resources.privacyPolicyPage.otherSources} size="xs" />
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.automaticDataCollection}{" "}
              </Text>
              {resources.privacyPolicyPage.automaticLoggingInformation}
            </Text>
          </Box>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer
                markdownText={resources.privacyPolicyPage.deviceAndOnlineActivityInformation}
                size="xs"
              />
              Click
              <Link color="blue" to="https://support.google.com/analytics/answer/12159447?hl=en">
                {" "}
                {resources.termsOfServicePrivacyPage.here}{" "}
              </Link>
              {resources.privacyPolicyPage.googleAnalyticsInfo}
            </Text>
          </Box>

          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer markdownText={resources.privacyPolicyPage.chatMonitoring} size="xs" />
            </Text>
          </Box>
          <Text size="400"> {resources.privacyPolicyPage.automatedDataCollectionTechnologies}</Text>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer
                markdownText={resources.privacyPolicyPage.dataCollectionTechnologiesDescription}
                size="xs"
              />
            </Text>
          </Box>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.howWeUseYourPersonalInformation}
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.useOfPersonalInformation}</Text>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.serviceDelivery}{" "}
              </Text>
              {resources.privacyPolicyPage.includingTo}
            </Text>
          </Box>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer
                markdownText={resources.privacyPolicyPage.personalInformationUsage}
                size="xs"
              />
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.researchAndDevelopment}{" "}
              </Text>
              {resources.privacyPolicyPage.deIdentifiedInformationUsage}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.connectionsAndInvitations}{" "}
              </Text>
              {resources.privacyPolicyPage.contactsInformationUsage}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.complianceAndProtection}{" "}
              </Text>
              {resources.privacyPolicyPage.weMayUseYourPersonalInformationTo}
            </Text>
          </Box>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer
                markdownText={resources.privacyPolicyPage.legalComplianceAndProtection}
                size="xs"
              />
            </Text>
          </Box>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.howWeDiscloseYourPersonalInformation}
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.disclosureOfPersonalInformation}</Text>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.vendors}{" "}
              </Text>
              {resources.privacyPolicyPage.serviceProvidersDescription}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.professionalAdvisors}{" "}
              </Text>
              {resources.privacyPolicyPage.professionalAdvisorsDescription}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.authoritiesAndOthers}{" "}
              </Text>
              {resources.privacyPolicyPage.lawEnforcementDescription}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.businessTransferees}{" "}
              </Text>
              {resources.privacyPolicyPage.businessTransactionParticipantsDescription}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.consent}{" "}
              </Text>
              {resources.privacyPolicyPage.consentDisclosureDescription}
            </Text>
          </Box>
          <Box display="flex" gap={5} justifyContent="center" marginTop={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.yourChoices}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.updateOrCorrectYourInformation}{" "}
              </Text>
              {resources.privacyPolicyPage.updateInformationInstructions}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.optOutOfMarketingCommunications}{" "}
              </Text>
              {resources.privacyPolicyPage.optOutInstructions}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.onlineTrackingOptOut}{" "}
              </Text>
              {resources.privacyPolicyPage.optOutWaysDescription}
            </Text>
          </Box>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer
                markdownText={resources.privacyPolicyPage.optOutMethodsDescription}
                size="xs"
              />
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.optOutNote}</Text>
          <MarkdownViewer
            markdownText={resources.privacyPolicyPage.doNotTrackDisclaimer}
            size="md"
          />
          <Text size="400">{resources.privacyPolicyPage.declineToProvideInfo}</Text>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.otherSitesAndServices}
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.thirdPartyLinksDisclaimer}</Text>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.security}
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.securitySafeguardsDescription}</Text>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.processingOfDataInTheUS}
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.headquartersLocationDescription}</Text>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.applicantInformation}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.privacyPolicyPage.jobApplicationInformation}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.children}
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.childrenUnder13Policy}</Text>
          <Box display="flex" gap={5} justifyContent="center" marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.noticeToCaliforniaUsers}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.privacyPolicyPage.informationCategories}
              size="xs"
            />
          </Text>
          <Box>
            <Table accessibilityLabel="Main example table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Text size="400" weight="bold">
                      {resources.privacyPolicyPage.categoryOfPersonalInformation}
                    </Text>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Text size="400" weight="bold">
                      {resources.privacyPolicyPage.categoriesOfRecipients}
                    </Text>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {[
                  {
                    id: "row1",
                    category: resources.privacyPolicyPage.identifiers,
                    categoryOfRecipients: resources.privacyPolicyPage.disclosedParties,
                  },
                  {
                    id: "row2",
                    category: resources.privacyPolicyPage.commercialInformation,
                    categoryOfRecipients: resources.privacyPolicyPage.disclosedParties,
                  },
                  {
                    id: "row3",
                    category: resources.privacyPolicyPage.approximateGeolocationData,
                    categoryOfRecipients: resources.privacyPolicyPage.relatedCompanies,
                  },
                  {
                    id: "row4",
                    category: resources.privacyPolicyPage.internetActivityInformation,
                    categoryOfRecipients: resources.privacyPolicyPage.relatedCompanies,
                  },
                  {
                    id: "row5",
                    category: resources.privacyPolicyPage.inferences,
                    categoryOfRecipients: resources.privacyPolicyPage.disclosedParties,
                  },
                ].map(({ id, category, categoryOfRecipients }) => (
                  <Table.Row key={id}>
                    <Table.Cell>
                      <Text size="400">{category}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text size="400">{categoryOfRecipients}</Text>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.advertisingPartnersSharing}</Text>

          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.privacyRights}{" "}
              </Text>
              {resources.privacyPolicyPage.californiaRightsDescription}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.access}{" "}
              </Text>
              {resources.privacyPolicyPage.accessPersonalInformationRequest}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.correction}{" "}
              </Text>
              {resources.privacyPolicyPage.correctPersonalInformationRequest}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.deletion}{" "}
              </Text>
              {resources.privacyPolicyPage.deletePersonalInformationRequest}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.optOutOfSaleOfYourPersonalInformation}{" "}
              </Text>
              {resources.privacyPolicyPage.optOutInstructionsKey}
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.exerciseRightsContactInstructions}</Text>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.authorizedAgents}{" "}
              </Text>
              {resources.privacyPolicyPage.authorizedAgentInstructions}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              <Text size="400" inline weight="bold">
                {resources.privacyPolicyPage.dataRetention}{" "}
              </Text>
              {resources.privacyPolicyPage.retentionPolicy}
            </Text>
          </Box>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.privacyPolicyPage.howToContactUs}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {resources.privacyPolicyPage.contactEmail}{" "}
              <Link to="#">concierge@ProDoctivity.com</Link>{" "}
              {resources.privacyPolicyPage.orByWritingTo}
            </Text>
          </Box>
          <Text size="400">{resources.privacyPolicyPage.attentionPrivacy}</Text>
          <Text size="400">
            Calle Pedro A. Bobea no. 1, Santo Domingo, Dominican Republic 10112
          </Text>
        </Box>
      </Box>
      <VisitorsFooter breakPointSmallDuo={breakPointSmallDuo} breakpoints={breakpoint} />
    </Box>
  );
}
