import { Box, Button, Checkbox, Skeleton, Text, TextField } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { Page } from "../../../../components/Layout/Page";
import { NotificationMessage } from "../../../../components/NotificationMessage";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../../link-templates";
import { GraphicMarkConfigWidget } from "./GraphicMark";
import { useGraphicMarkBuilder } from "./hooks";

type EditOrganizationGraphicMarkProps = {
  isUpdating?: boolean;
};

const EditGraphicMarkPage: FunctionComponent<EditOrganizationGraphicMarkProps> = ({
  isUpdating,
}) => {
  const organizationNavigate = useOrganizationNavigate();
  const {
    resources,
    breakpoint,
    formState,
    setFormState,
    handleSaveChanges,
    handlePicChange,
    breadcrumbEntries,
    isMutating,
    isLoading,
    clearPicture,
  } = useGraphicMarkBuilder(!!isUpdating);
  const disable = isMutating || isLoading || formState.name?.length < 2;
  return (
    <Page breadCrumbEntries={breadcrumbEntries}>
      {isLoading && formState ? (
        <Box display="flex" flex="grow" justifyContent="center">
          <Skeleton show={isLoading} />
        </Box>
      ) : (
        <Box borderRadius={6} padding={6} margin={4} display="flex" direction="column" flex="grow">
          <Box display="flex" direction="column" flex="shrink">
            <Text size="400" weight="bold">
              {resources.graphicMarkTemplate}
            </Text>
            <Text size="200">{resources.graphicMarkTemplateDesc}</Text>
            <Box marginBottom={4} paddingTop={4}>
              <Text
                weight="bold"
                size="300"
                title={formState.name ?? ""}
                ellipsisLength={breakpoint === "small" || breakpoint === "medium" ? 32 : 50}
              >
                {`${resources.graphicMark}${formState.name ? `: ${formState.name}` : ""}`}
              </Text>
            </Box>
            <Box display="flex" direction="column" flex="grow" gap={2}>
              <Box display="flex" direction="column" flexValue={1} gap={2}>
                <Text weight="bold">{resources.name}</Text>
                <TextField
                  id="name"
                  name="name"
                  onChange={(e) => setFormState({ ...formState, name: e.value })}
                  label={resources.name}
                  labelDisplay="hidden"
                  placeholder={resources.enterName}
                  value={formState.name}
                />
              </Box>
              <Box display="flex" direction="column" flexValue={1} gap={2}>
                <Text weight="bold">{resources.customMessage}</Text>
                <TextField
                  id="customMessage"
                  name="customMessage"
                  onChange={(e) => setFormState({ ...formState, customMessage: e.value })}
                  label={resources.customMessage}
                  labelDisplay="hidden"
                  placeholder={resources.customMessage}
                  value={formState.customMessage}
                />
              </Box>
              <Box display="flex" direction="column" flexValue={1} gap={2}>
                <Text weight="bold">{resources.default}</Text>
                <Checkbox
                  id={`default${formState.isDefault}`}
                  name={resources.default}
                  checked={formState.isDefault}
                  onChange={({ checked }) => {
                    setFormState({ ...formState, isDefault: checked });
                  }}
                />
              </Box>
            </Box>
            <Box padding={4} />
            <Box display="flex" direction="row" flex="grow">
              <Box display="flex" direction="column" flex="shrink" flexValue={3} gap={2}>
                <Box display="flex" direction="row" flex="shrink">
                  <Box direction="row" gap={2} display="flex" flexValue={1}>
                    <Checkbox
                      id={`dn${formState.distinguishedName}`}
                      name={resources.email}
                      checked={formState.distinguishedName}
                      onChange={({ checked }) => {
                        setFormState({ ...formState, distinguishedName: checked });
                      }}
                    />
                    <Text>{resources.distinguishedName}</Text>
                  </Box>
                  <Box direction="row" gap={2} display="flex" flexValue={1}>
                    <Checkbox
                      id={`email`}
                      name={resources.email}
                      checked={formState.email}
                      onChange={({ checked }) => {
                        setFormState((prev) => ({ ...prev, email: checked }));
                      }}
                    />
                    <Text>{resources.email}</Text>
                  </Box>
                </Box>
                <Box display="flex" direction="row" flex="shrink">
                  <Box direction="row" gap={2} display="flex" flexValue={1}>
                    <Checkbox
                      id={`date${formState.url}`}
                      name={"link"}
                      onChange={({ checked }) => {
                        setFormState({ ...formState, url: checked });
                      }}
                      checked={formState.url}
                    />
                    <Text>{"URL"} </Text>
                  </Box>
                  {/* <Box direction="row" gap={2} display="flex" flexValue={1}>
                  <Checkbox
                    id={`dn${formState.waterMark}`}
                    name={"QR"}
                    checked={formState.waterMark}
                    onChange={({ checked }) => {
                      setFormState({ ...formState, waterMark: checked });
                    }}
                  />
                  <Text>{resources.waterMark}</Text>
                </Box> */}
                  <Box direction="row" gap={2} display="flex" flexValue={1}>
                    <Checkbox
                      id={`date${formState.logo}`}
                      name={"logo"}
                      onChange={({ checked }) => {
                        setFormState({ ...formState, logo: checked });
                      }}
                      checked={formState.logo}
                    />
                    <Text>{"Logo"} </Text>
                  </Box>
                </Box>
                <Box display="flex" direction="row" flex="shrink">
                  <Box direction="row" gap={2} display="flex" flexValue={1}>
                    <Checkbox
                      id={`date${formState.date}`}
                      name={resources.date}
                      checked={formState.date}
                      onChange={({ checked }) => {
                        setFormState({ ...formState, date: checked });
                      }}
                    />
                    <Text>{resources.date}</Text>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" direction="row" flex="grow" minHeight={250} flexValue={7}>
                <GraphicMarkConfigWidget
                  index={0}
                  setFormState={setFormState}
                  handlePicChange={handlePicChange}
                  graphicMark={formState}
                  building={true}
                  clearPicture={clearPicture}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" direction="column" flex="grow" />

          <Box display="flex" direction="row" flex="grow">
            <Box display="flex" direction="row" flex="grow" />
            <Box display="flex" direction="row" flex="shrink" gap={4}>
              <Box>
                <Button
                  color="white"
                  text={resources.cancel}
                  onClick={() => organizationNavigate(organizationLinkTemplates.graphicMarkList())}
                  size="sm"
                  accessibilityLabel={resources.cancel}
                />
              </Box>
              <Box>
                <Button
                  color="blue"
                  text={resources.save}
                  size="sm"
                  accessibilityLabel={resources.accept}
                  onClick={handleSaveChanges}
                  disabled={disable}
                />
              </Box>
            </Box>
          </Box>
          {formState.toastMessage && (
            <NotificationMessage
              type={formState.toastMessage.type}
              delay={3000}
              message={formState.toastMessage.message}
              position="bottom-left"
              handleDismiss={() => setFormState((prev) => ({ ...prev, toastMessage: undefined }))}
            />
          )}
        </Box>
      )}
    </Page>
  );
};

export default EditGraphicMarkPage;
