import {
  Box,
  Divider,
  SearchField,
  Table,
  TapArea,
  Text,
  Tooltip,
  TrashSvgIcon,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";

import type { CombinedTemplate } from "@prodoctivity/types";
import { FunctionComponent } from "react";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../components/hooks";
import { organizationLinkTemplates } from "../../../link-templates";
import { EditFillSvgIcon } from "../../../svg/EditFillSvgIcon";
import { useCombinedTemplateList } from "./hooks";

export const CombinedTemplateListPage: FunctionComponent = () => {
  const {
    paginatedData,
    setFilter,
    currentPage,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    refetch,
  } = usePaginatedDataEndpoint<
    {
      combinedList: Array<
        {
          id: string;
        } & CombinedTemplate<string>
      >;
    },
    "15" | "30" | "100",
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.getCombinedTemplateList(currentPage, rowsPerPage, filter);
    },
    "settings_combined_template_list"
  );

  const { colors } = useColors();

  const { breakpoint } = useDesignBreakpoint();

  const { resources, breadCrumbEntries, isMutating, organizationNavigate, deleteMutation } =
    useCombinedTemplateList(refetch);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" flex="grow">
        <SearchComponentWrapper
          componentHeaderText={resources.combinedTemplates.combinedTemplates}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="combined-template-list-search"
          searchFieldLabel={resources.search}
          setFilter={setFilter}
          buttonAction={() =>
            organizationNavigate(organizationLinkTemplates.newCombinedTemplates())
          }
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          omitSearch={true}
        />

        <Box margin={5} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="combined_template_list_pagination_top"
            rowsLabel={`${resources.combinedTemplates.combinedTemplates}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.combinedList.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={[rowsPerPage]}
            totalRowCount={totalRowCount}
            extraComponent={
              <SearchField
                marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                marginEnd={2}
                accessibilityLabel={resources.search}
                id="combined_lists_filter"
                onChange={({ value }) => {
                  setFilter(value);
                }}
              />
            }
          />
          <Divider direction="horizontal" />
        </Box>
        <Box
          marginTop={8}
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Box>
            <Table accessibilityLabel={resources.combinedTemplates.combinedTemplates}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Box paddingX={4}>
                      <Text size="200" weight="bold">
                        {resources.name.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.description.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.documentGroup.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.actions.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {paginatedData ? (
                  paginatedData.combinedList.map((combinedTemplate) => {
                    return (
                      <Table.Row key={combinedTemplate.id}>
                        <Table.Cell>
                          <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                            <Box>
                              <Text
                                ellipsisLength={32}
                                weight="bold"
                              >{`${combinedTemplate.name}`}</Text>
                            </Box>
                            <Box width={1} borderStyle={"sm"} borderRadius={4} />
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box>
                            <Text>{`${combinedTemplate.description}`}</Text>
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box>
                            <Text>{`${combinedTemplate.documentGroupName}`}</Text>
                          </Box>
                        </Table.Cell>

                        <Table.Cell>
                          <Box display="flex">
                            <Box display="flex" gap={2}>
                              <TapArea
                                disabled={isMutating}
                                onTap={() =>
                                  organizationNavigate(
                                    organizationLinkTemplates.editCombinedTemplates(
                                      combinedTemplate.id
                                    )
                                  )
                                }
                              >
                                <Tooltip text={resources.edit}>
                                  <EditFillSvgIcon fillColor={colors.primary} />
                                </Tooltip>
                              </TapArea>
                              <TapArea
                                disabled={isMutating}
                                onTap={() => deleteMutation(combinedTemplate.id)}
                              >
                                <Tooltip text={resources.delete_}>
                                  <TrashSvgIcon color={colors.primary} />
                                </Tooltip>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral200}
                      >
                        <Text size="300">{resources.noResultsFound}</Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Box>
        </Box>
        <Box margin={5} display="flex" direction="row">
          <Box display="flex" flex="grow" />
          <Box display="flex" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="combined_template_list_pagination_bottom"
              rowsLabel={`${resources.combinedTemplates.combinedTemplates}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.combinedList.length : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={[rowsPerPage]}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default CombinedTemplateListPage;
