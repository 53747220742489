import {
  Box,
  ComboBox,
  DatePicker,
  IconButton,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import { FunctionComponent, SyntheticEvent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { BrowseDocumentLabelsFilter } from "../../../link-templates";
import { XIconSvg } from "../../../svg/XIconSvg";
import { ListSvg } from "@prodoctivity/design-system/svg/ListSvg";
import { GridViewIconsSvg } from "../../../svg/GridViewIconsSvg";
import { ListOutlineIconSvg } from "../../../svg/ListOutlineIconSvg";
import { Pagination } from "../../../components/Layout/Pagination";
import type { DocumentSummary, HttpGetDocumentsWithListsRequest } from "@prodoctivity/types";

export type DocumentLabelsFiltersProps = {
  filter: BrowseDocumentLabelsFilter;
  refetch(): void;
  onSelectDocumentType: ({
    item,
  }: {
    event: SyntheticEvent<HTMLInputElement, Event>;
    item: ComboBoxItemType;
  }) => void;
  documentTypeHandleClick: (index: number) => void;
  clearAll: () => void;
  handleFilterChange: (
    filterType: keyof BrowseDocumentLabelsFilter,
    value: {
      value?: string | number | undefined;
    }
  ) => void;
  handleComboBoxClear: () => void;
  handleSetDocumentTypeComboBoxInputValue: (value: string) => void;
  documentTypeComboBoxInputValue: string;
  documentTypeList: {
    label: string;
    value: string;
  }[];
  selectedDocumentTypes: ComboBoxItemType[];
  isBreakpointHd: boolean;
  isSmallOrMediumBreakpoint: boolean;
  showOnCards: () => void;
  displayDocumentsAsCards: boolean;
  alterShowComponentState: (value: boolean) => void;
  paginationProps: {
    rowsPerPage: "15" | "100" | "30";
    currentPage: number;
    nextPage: () => void;
    previousPage: () => void;
    documentsData: DocumentSummary[];
    setRowsPerPage: (
      rpp: HttpGetDocumentsWithListsRequest["queryParameters"]["rowsPerPage"]
    ) => void;
    isNextButtonDisabled: boolean;
    isPreviousButtonDisabled: boolean;
    pageLengthOptions: ("15" | "100" | "30")[];
    totalRowCount: number | undefined;
  };
};

export const DocumentLabelsFilters: FunctionComponent<DocumentLabelsFiltersProps> = ({
  filter,
  refetch,
  onSelectDocumentType,
  documentTypeHandleClick,
  clearAll,
  handleFilterChange,
  handleComboBoxClear,
  handleSetDocumentTypeComboBoxInputValue,
  documentTypeComboBoxInputValue,
  selectedDocumentTypes,
  documentTypeList,
  isSmallOrMediumBreakpoint,
  showOnCards,
  displayDocumentsAsCards,
  alterShowComponentState,
  paginationProps,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box>
      <Box
        paddingY={5}
        display="flex"
        direction={isSmallOrMediumBreakpoint ? "column" : "row"}
        alignItems="center"
        gap={4}
      >
        <Box display="flex" paddingX={2} direction="column">
          {breakpoint !== "hd" && (
            <Box display="flex" direction="row" flex="grow" justifyContent="center">
              <Box minWidth={80}>
                <Text align="center" weight="bold">
                  {resources.myLists}
                </Text>
              </Box>
            </Box>
          )}
          {breakpoint === "hd" ? (
            <Box display="flex" alignItems="center" onClickCapture={showOnCards}>
              <TapArea>
                {displayDocumentsAsCards ? <GridViewIconsSvg /> : <ListOutlineIconSvg />}
              </TapArea>
            </Box>
          ) : (
            <Box
              display="flex"
              flex="shrink"
              alignItems="center"
              justifyContent="center"
              onClickCapture={() => alterShowComponentState(true)}
              paddingX={4}
            >
              <ListSvg width={30} height={30} />
            </Box>
          )}
        </Box>
        <Box width={breakpoint === "small" ? "100%" : "80%"}>
          <ComboBox
            id="documentType"
            onClear={handleComboBoxClear}
            label=""
            inputValue={documentTypeComboBoxInputValue}
            onChange={(event) => handleSetDocumentTypeComboBoxInputValue(event.value as string)}
            accessibilityClearButtonLabel={resources.clear}
            noResultText={resources.dataDictionary.noItems}
            onSelect={onSelectDocumentType}
            size="lg"
            options={documentTypeList}
            placeholder={resources.documentTypes.documentTypeName}
          />
        </Box>

        <Box display="flex" gap={2} direction="row" flex="shrink">
          <Box minWidth={150}>
            <DatePicker
              resources={resources}
              id="startDate"
              placeholder={resources.from}
              value={filter.dateStart ? new Date(filter.dateStart) : undefined}
              onChange={(value) => {
                const dateValue = value.value;
                const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;
                handleFilterChange("dateStart", { value: timestamp });
              }}
              dataType="Date"
            />
          </Box>
          <Box minWidth={150}>
            <DatePicker
              resources={resources}
              id="dateEnd"
              value={filter.dateEnd ? new Date(filter.dateEnd) : undefined}
              placeholder={resources.to}
              onChange={(value) => {
                const dateValue = value.value;
                const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;

                handleFilterChange("dateEnd", { value: timestamp });
              }}
              dataType="Date"
            />
          </Box>
        </Box>

        <Box display="flex" direction="column" justifyContent="center" minWidth={250}>
          <Box display="flex" direction="row" gap={4} justifyContent="around">
            <Box display="flex" alignItems="center">
              <TapArea onTap={clearAll} accessibilityLabel={resources.clearAll}>
                <Box>
                  <Text color={colors.primary} align="center">
                    {resources.clearAll}
                  </Text>
                </Box>
              </TapArea>
            </Box>
            <Box display="flex">
              <IconButton
                accessibilityLabel={resources.refresh}
                onClick={refetch}
                icon="arrows-rotate"
              />
            </Box>
          </Box>
          <Box paddingX={4}>
            <Pagination<typeof paginationProps.rowsPerPage>
              id="Browse"
              rowsLabel={`${resources.documents}:`}
              currentPage={paginationProps.currentPage}
              nextPage={paginationProps.nextPage}
              previousPage={paginationProps.previousPage}
              pageLength={paginationProps.documentsData?.length || 0}
              rowsPerPage={paginationProps.rowsPerPage}
              setRowsPerPage={paginationProps.setRowsPerPage}
              isNextButtonDisabled={paginationProps.isNextButtonDisabled}
              isPreviousButtonDisabled={paginationProps.isPreviousButtonDisabled}
              pageLengthOptions={paginationProps.pageLengthOptions}
              totalRowCount={paginationProps.totalRowCount}
            />
          </Box>
        </Box>
      </Box>

      {selectedDocumentTypes.length > 0 && (
        <Box display={"flex"} padding={2} justifyContent={"between"} alignItems={"center"}>
          <Box display="flex" flex="grow" direction="row" alignItems="center">
            <Box display="flex" marginEnd={isSmallOrMediumBreakpoint ? 1 : 3}>
              <Text>{resources.filters}: </Text>
            </Box>
            <Box display="flex" wrap={true}>
              {selectedDocumentTypes.map((dt, index) => (
                <Box
                  display="flex"
                  alignItems="center"
                  height={"auto"}
                  width={"fit-content"}
                  key={index}
                  paddingX={isSmallOrMediumBreakpoint ? 2 : 4}
                  paddingY={isSmallOrMediumBreakpoint ? 1 : 2}
                  margin={1}
                  color={colors.white}
                  borderRadius={4}
                  onClickCapture={() => documentTypeHandleClick(index)}
                  dangerouslySetInlineStyle={{
                    __style: {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Text color={colors.neutral900}>{dt.label}</Text>

                  <Box smMarginStart={isSmallOrMediumBreakpoint ? 1 : 3}>
                    <XIconSvg />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
