import { FunctionComponent, useEffect, useRef, useState } from "react";
import { TextControlFrame, initTextControlFrame } from "./TextControlFrame";

import { Box } from "@prodoctivity/design-system";
import { createUuid } from "@prodoctivity/shared";
import { useParams } from "react-router-dom";
import { useServices } from "../../hooks/useServices";
import { useSettings } from "../../hooks/useSettings";

const AuthorWebPage: FunctionComponent = () => {
  let token = "";
  const { textControlUrl } = useSettings();
  const { user } = useServices();
  if (user) {
    token = user.token;
  }
  const { templateVersionId } = useParams();
  const instanceId = useRef<string>(createUuid());
  const theEditor = useRef<TextControlFrame | null>();
  const textControlRef = useRef<HTMLDivElement>(null);
  const [iframeInitialized, setIframeInitialized] = useState(false);

  // const downloadRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const frameName = instanceId.current + "_txframe";
    if (textControlRef.current) {
      textControlRef.current.id = instanceId.current;
      initTextControlFrame(textControlRef.current, {
        textControlLocation: textControlUrl || "",
        frameName,
        setIframeInitialized,
      }).then((editor) => {
        theEditor.current = editor;
      });
    }

    return () => {
      console.log("cleaning up component with name: ", frameName);
      const iFrameElement: HTMLElement | null = document.getElementsByName(frameName)[0];
      if (iFrameElement) iFrameElement.remove();
      setIframeInitialized(false);
    };
  }, [textControlUrl, token]);

  // Set user token inside TextControl Frame and load template (if id is present in route)
  useEffect(() => {
    if (iframeInitialized) {
      if (theEditor.current) {
        theEditor.current.updateToken(token).then((_) => {
          if (templateVersionId) {
            if (theEditor.current) {
              theEditor.current.load(templateVersionId);
            }
          } else {
            if (theEditor.current) {
              theEditor.current.create();
            }
          }
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [iframeInitialized, token, templateVersionId]);

  return (
    <>
      <Box display="flex" direction="column">
        <Box margin={6} />
        {/* <Box margin={4}>
          <button
            onClick={() => {
              if (theEditor.current) {
                theEditor.current.save().then((response) => {
                  console.log("got a response: ", response);
                  if (downloadRef.current) {
                    downloadRef.current.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${response.data}`;
                    downloadRef.current.target = "_blank";
                    downloadRef.current.style.display = "block";
                  }
                });
              }
            }}
          >
            Save
          </button>
          <a
            ref={downloadRef}
            className="pull-right anticon"
            download="MyDocument.docx"
            style={{ display: "none" }}
          >
            <svg
              viewBox="64 64 896 896"
              className=""
              data-icon="download"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
            </svg>
          </a>
          <br />
        </Box> */}
      </Box>
      <div ref={textControlRef} />
    </>
  );
};

export default AuthorWebPage;
