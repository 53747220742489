import { ColorConfig, calculateColor } from "../../utils";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const ViewerZoomInIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5722 8.66111C11.55 9.67222 9.81667 11.3333 8.73333 13.1389C7.57778 15.0889 7 17.1833 7 19.6389C7 21.7333 7.65 24.55 8.44444 26.2833C9.45556 28.3778 10.9 29.9667 12.9222 31.1944C15.0889 32.4222 17.1111 33 19.6389 33C21.7333 33 24.55 32.35 26.2833 31.5556C28.3778 30.5444 29.9667 29.1 31.1944 27.0056C32.4222 24.9111 33 22.8889 33 20.3611C33 18.2667 32.35 15.45 31.5556 13.6444C30.5444 11.6222 29.1 10.0333 27.0056 8.80556C24.9111 7.57778 22.8889 7 20.3611 7C17.9778 7 15.6667 7.57778 13.5722 8.66111ZM17.689 9.74459C16.7501 9.88903 15.1612 10.6835 14.0779 11.4057C12.9223 12.1279 11.5501 13.8613 10.9001 15.1613C10.2501 16.389 9.74456 18.8446 9.81678 20.5779C9.889 22.3113 10.539 24.5501 11.3334 25.7779C12.1279 26.9335 13.8612 28.4501 15.1612 29.1001C16.389 29.7501 18.8446 30.2557 20.5779 30.1835C22.3112 30.1113 24.5501 29.4613 25.7779 28.6668C27.0057 27.8724 28.4501 26.139 29.1001 24.9113C29.7501 23.6113 30.2557 21.1557 30.1834 19.4946C30.1112 17.689 29.4612 15.4501 28.6668 14.2224C27.8723 13.0668 26.5001 11.6946 25.6334 11.189C24.6946 10.7557 22.9612 10.1779 21.6612 9.96125C20.3612 9.67236 18.6279 9.60014 17.689 9.74459Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <path
        d="M16.0278 18.5556C18.4111 18.5556 18.5556 18.4111 18.5556 16.0278C18.5556 14.0056 18.8444 13.5 20 13.5C21.1556 13.5 21.4444 14.0056 21.4444 16.0278C21.4444 18.4111 21.5889 18.5556 23.9722 18.5556C25.9944 18.5556 26.5 18.8444 26.5 20C26.5 21.1556 25.9944 21.4444 23.9722 21.4444C21.5889 21.4444 21.4444 21.5889 21.4444 23.9722C21.4444 25.9944 21.1556 26.5 20 26.5C18.8444 26.5 18.5556 25.9944 18.5556 23.9722C18.5556 21.5889 18.4111 21.4444 16.0278 21.4444C14.0056 21.4444 13.5 21.1556 13.5 20C13.5 18.8444 14.0056 18.5556 16.0278 18.5556Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
    </svg>
  );
};
