import { Box, DatePicker, useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode, useCallback } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { GenerationSearch } from "../../interfaces/generations/GenerationSearch";
import { Topic } from "../Layout/Topic";

type Props = {
  search: GenerationSearch;
  onChangeSearch: (search: GenerationSearch) => void;
};

export const GenerationMenuSearch: FunctionComponent<Props> = ({ search, onChangeSearch }) => {
  const { resources } = useAppTranslation();

  const handleChangeDate = useCallback(
    (d: any) => {
      onChangeSearch({
        ...search,
        pageNumber: 0,
        fromDate: d.value == null ? undefined : new Date(d.value),
      });
    },
    [onChangeSearch, search]
  );
  const handleChangeDateTo = useCallback(
    (d: any) => {
      onChangeSearch({
        ...search,
        pageNumber: 0,
        toDate: d.value == null ? undefined : new Date(d.value),
      });
    },
    [onChangeSearch, search]
  );

  console.log(search);

  return (
    <LeftSide>
      <Box paddingX={4} display="flex" direction="column">
        <Topic>{resources.activities.dateRange}</Topic>

        <Box display="flex" gap={2} direction="column">
          <DatePicker
            resources={resources}
            // rangeStartDate={search.fromDate}
            rangeEndDate={search.toDate}
            id="fromDate"
            label={resources.from}
            placeholder={resources.from}
            onChange={handleChangeDate}
            rangeSelector="start"
            value={search.fromDate}
            dataType="Date"
          />
          <DatePicker
            resources={resources}
            rangeStartDate={search.fromDate}
            // rangeEndDate={search.toDate}
            id="toDate"
            label={resources.to}
            placeholder={resources.to}
            onChange={handleChangeDateTo}
            rangeSelector="end"
            value={search.toDate}
            dataType="Date"
          />
        </Box>
      </Box>
    </LeftSide>
  );
};

export function LeftSide({ children }: { children?: ReactNode }) {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box
      height="100%"
      display="flex"
      direction="column"
      color={colors.white}
      dangerouslySetInlineStyle={
        breakpoint !== "small"
          ? {
              __style: {
                borderRight: "1px solid #C7C7C8",
              },
            }
          : undefined
      }
    >
      <Box direction="column" flex="grow" display="flex">
        {children}
      </Box>
    </Box>
  );
}
