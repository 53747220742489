import { FC, useMemo } from "react";
import { EmptyView } from "./wigets/EmptyView";
import { LoadingChart } from "./wigets/LoadingChart";
import type { ForPieData } from "@prodoctivity/types";
import { Box } from "@prodoctivity/design-system";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { PieChart } from "./wigets/Dougnut/PieChart";
import { StatsContainer } from "./StatsContainer";

export const CollectionStatusPie: FC<{
  dataSet: ForPieData | undefined;
  isLoading: boolean;
}> = ({ dataSet, isLoading }) => {
  const curentViewState = useMemo(() => {
    if (isLoading) return "IsLoading";
    if (!dataSet) return "IsEmpty";
    return "valid";
  }, [isLoading, dataSet]);

  const { resources } = useAppTranslation();

  const dataSetChart: Array<{ id: string; label: string; value: number; color: string }> =
    useMemo(() => {
      return [
        {
          id: resources.valid,
          label: resources.valid,
          color: "rgb(4, 118, 231)",
          value: dataSet
            ? Number.parseInt(((dataSet.distribution.valid / dataSet.total) * 100).toString())
            : 0,
        },
        {
          id: resources.expired,
          label: resources.expired,
          color: "rgb(127, 74, 223)",
          value: dataSet
            ? Number.parseInt(((dataSet.distribution.expired / dataSet.total) * 100).toString())
            : 0,
        },
        {
          id: resources.toExpire,
          label: resources.toExpire,
          color: "rgb(255, 219, 111)",
          value: dataSet
            ? Number.parseInt(((dataSet.distribution.expiring / dataSet.total) * 100).toString())
            : 0,
        },
        {
          id: resources.issues,
          label: resources.issues,
          color: "rgb(213, 38, 38)",
          value: dataSet
            ? Number.parseInt(
                ((dataSet.distribution.issueOrInvalid / dataSet.total) * 100).toString()
              )
            : 0,
        },
      ];
    }, [dataSet, resources.expired, resources.issues, resources.toExpire, resources.valid]);

  const renderContentByState = useMemo(() => {
    if (curentViewState === "IsEmpty") {
      return <EmptyView mesage={resources.empty} />;
    }

    if (curentViewState === "IsLoading") {
      return <LoadingChart message="loading" />;
    }

    const totalReducer = dataSetChart.map((x) => x.value).reduce((a, b) => a + b);

    if (totalReducer === 0 || Number.isNaN(totalReducer)) {
      return <EmptyView mesage={resources.empty} />;
    }

    return (
      <Box display="flex" direction="column" flex="grow" minHeight={250} gap={6}>
        <Box display="flex" direction="row" flex="grow" maxHeight={250}>
          <PieChart
            useCustomColor={true}
            formatOutputValueInChart={true}
            format={(e) => `${e.value}%`}
            data={dataSetChart}
            useLeyend={true}
            leyendLocation="leyend-left"
            arcLabelsRadiusOffset={0.5}
          />
        </Box>
      </Box>
    );
  }, [curentViewState, dataSetChart, resources.empty]);

  return (
    // //title={}
    <StatsContainer title={resources.stateOfDocumentCollection}>
      {renderContentByState}
    </StatsContainer>
  );
};
