import { FormConnector, FormDefinition } from "../_lib/types";
import { IFormValues, makeCancelable } from "./_types";

interface OnFireConnector {
  (formConnector: { name?: string }, formValues: IFormValues): Promise<IFormValues>;
}

const getConnectorsToFire = (
  _formConnectors: FormConnector[],
  _connectors: any[],
  _formDefinition: FormDefinition
): any[] => {
  return [];
};

let fireConnectorsPromise: any;

export const fireConnectorsList: any = async (
  formConnectors: FormConnector[],
  connectors: any[],
  onFireConnector: OnFireConnector,
  formDefinition: FormDefinition,
  formData: IFormValues,
  onFormDefinitionChange: { (formDefinition: FormDefinition): void }
) => {
  if (!onFireConnector) {
    return;
  }

  const firedConnectors = getConnectorsToFire(formConnectors, connectors, formDefinition);

  if (!firedConnectors || firedConnectors.length === 0) {
    return;
  }

  // Fire the connectors and capture their promises
  const promises = firedConnectors.map((fc) => onFireConnector(fc, formData));

  // Cancel the requests any previously fired connectors
  if (fireConnectorsPromise) {
    fireConnectorsPromise.cancel();
  }

  fireConnectorsPromise = makeCancelable(
    Promise.all(
      promises.map((p) =>
        p.then(
          (data) => ({ ok: true, data }),
          (error) => {
            //onConnectorFail(firedConnectors[i], error)
            return { ok: false, error };
          }
        )
      )
    )
  );

  // Await the responses
  let responses: [{ ok: any; data: any }];
  try {
    responses = await fireConnectorsPromise.promise;
  } catch (e: any) {
    if (e.isCanceled) {
      return;
    }
    throw e;
  }

  // Apply data response to each list of data element
  Array.from(responses).forEach((response /*, index */) => {
    if (!response.ok || response.data == null || !Array.isArray(response.data)) {
      return;
    }

    // const formConnector: FormConnector = formConnectors.filter(
    //   (formConnector) => formConnector.name === firedConnectors[index].name
    // )[0];

    onFormDefinitionChange(formDefinition);
  });
};
