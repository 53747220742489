import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const IconsEssentialsTableSortDesc: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7272_104065)">
        <path
          d="M15.0308 20H24.9692C25.1729 20.0012 25.3718 20.0607 25.5409 20.1709C25.7101 20.2812 25.842 20.4374 25.9203 20.6201C25.9986 20.8028 26.0198 21.0038 25.9812 21.1981C25.9426 21.3924 25.846 21.5714 25.7034 21.7126L20.7342 26.7042C20.6379 26.7981 20.5235 26.8726 20.3975 26.9233C20.2715 26.9741 20.1364 27.0002 20 27C19.864 27.0003 19.7294 26.9743 19.6039 26.9235C19.4784 26.8727 19.3646 26.7981 19.269 26.7042L14.2966 21.7189C14.154 21.5776 14.0574 21.3986 14.0188 21.2043C13.9802 21.01 14.0014 20.809 14.0797 20.6263C14.158 20.4437 14.2899 20.2875 14.4591 20.1772C14.6282 20.0669 14.8271 20.0074 15.0308 20.0062V20Z"
          fill={calculateColor(color || colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_7272_104065">
          <rect width="12.0002" height="14.0002" fill="white" transform="matrix(1 0 0 -1 14 27)" />
        </clipPath>
      </defs>
    </svg>
  );
};
