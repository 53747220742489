import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const PlusSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
  onClick,
}) => {
  return (
    <svg
      //stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M20 10C20.3315 10 20.6495 10.1317 20.8839 10.3661C21.1183 10.6005 21.25 10.9185 21.25 11.25V18.75H28.75C29.0815 18.75 29.3995 18.8817 29.6339 19.1161C29.8683 19.3505 30 19.6685 30 20C30 20.3315 29.8683 20.6495 29.6339 20.8839C29.3995 21.1183 29.0815 21.25 28.75 21.25H21.25V28.75C21.25 29.0815 21.1183 29.3995 20.8839 29.6339C20.6495 29.8683 20.3315 30 20 30C19.6685 30 19.3505 29.8683 19.1161 29.6339C18.8817 29.3995 18.75 29.0815 18.75 28.75V21.25H11.25C10.9185 21.25 10.6005 21.1183 10.3661 20.8839C10.1317 20.6495 10 20.3315 10 20C10 19.6685 10.1317 19.3505 10.3661 19.1161C10.6005 18.8817 10.9185 18.75 11.25 18.75H18.75V11.25C18.75 10.9185 18.8817 10.6005 19.1161 10.3661C19.3505 10.1317 19.6685 10 20 10Z"
        fill={color ? calculateColor(color) : "#767676"}
      />
    </svg>
  );
};
