import { FunctionComponent } from "react";
type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};
export const DocumentInstructionsSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 50,
  height = 50,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      style={svgStyle}
      viewBox={`0 0 50 50`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect width="50" height="50" rx="25" fill="#CCE7FF" />
      <rect x="17.7139" y="11.6973" width="20.5714" height="25.4545" rx="2" fill="#CCE7FF" />
      <g clipPath="url(#clip0_11378_9627)">
        <path
          d="M35.6999 20.6519C35.6999 25.8838 35.6999 31.1012 35.6999 36.3331C35.6999 37.594 35.292 37.9998 34.0391 37.9998C28.2552 37.9998 22.4567 37.9998 16.6728 37.9998C15.4636 37.9998 15.0557 37.594 15.0557 36.3766C15.0557 28.9418 15.0557 21.507 15.0557 14.0577C15.0557 13.6954 15.0557 13.3331 15.2451 12.9998C15.7258 13.1882 16.1046 12.7969 16.5708 12.7969C20.1257 12.8404 23.6951 12.8259 27.2645 12.7969C27.8035 12.7969 27.9784 12.9563 27.9638 13.4925C27.9347 15.1592 27.9492 16.8404 27.9638 18.5215C27.9638 19.5505 28.4009 19.9708 29.4498 19.9708C31.5041 19.9708 33.5437 19.9708 35.598 19.9708C35.8311 20.1592 35.7145 20.4201 35.7145 20.6519H35.6999Z"
          fill="url(#paint0_linear_11378_9627)"
        />
        <path
          d="M35.5688 19.9708C33.5146 19.9708 31.4749 19.9708 29.4207 19.9708C28.3863 19.9708 27.9492 19.5506 27.9492 18.5216C27.9492 16.8404 27.9201 15.1737 27.9492 13.4926C27.9492 12.9419 27.7889 12.7824 27.2499 12.7969C23.6951 12.8259 20.1257 12.8259 16.5562 12.7969C16.0755 12.7969 15.7112 13.1882 15.2305 12.9998C15.4344 12.5506 15.8132 12.3477 16.294 12.3477C20.1257 12.3477 23.9573 12.3621 27.7889 12.3766C27.8181 12.4781 27.8618 12.5216 27.9346 12.4056C29.1584 13.4056 30.2074 14.594 31.3146 15.6955C32.7424 17.1158 34.1556 18.5506 35.5688 19.9853V19.9708Z"
          fill="#2650A3"
        />
        <g filter="url(#filter0_b_11378_9627)">
          <path
            d="M22.3837 16.1594C22.3837 12.7577 19.6115 10 16.1918 10C12.7722 10 10 12.7577 10 16.1594C10 19.5612 12.7722 22.3188 16.1918 22.3188C19.6115 22.3188 22.3837 19.5612 22.3837 16.1594Z"
            fill="url(#paint1_linear_11378_9627)"
          />
        </g>
        <path d="M31.1979 23.7969H19.6738V25.565H31.1979V23.7969Z" fill="#FEFEFE" />
        <path d="M31.1979 27.8691H19.6738V29.6373H31.1979V27.8691Z" fill="#FEFEFE" />
        <path d="M31.0963 31.9424H19.5723V33.7105H31.0963V31.9424Z" fill="#FEFEFE" />
        <path
          d="M13.9478 15.4932L15.2007 16.6381L18.3913 13.4642C18.7555 13.1453 19.28 13.1308 19.6151 13.3772C20.023 13.6816 20.125 14.3482 19.7462 14.812L16.8324 17.6961L15.5504 18.9859C15.3464 19.1598 15.0696 19.1598 14.8656 18.9859L12.5929 16.8265C12.2723 16.4062 12.3452 15.812 12.724 15.5077C13.0591 15.2323 13.569 15.2178 13.9478 15.5077V15.4932Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_b_11378_9627)">
        <rect
          x="26.2861"
          y="26.1211"
          width="13.7143"
          height="7.63636"
          rx="1"
          fill="url(#paint2_linear_11378_9627)"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_11378_9627"
          x="5"
          y="5"
          width="22.3838"
          height="22.3184"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11378_9627" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11378_9627"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_11378_9627"
          x="25.2861"
          y="25.1211"
          width="15.7139"
          height="9.63672"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11378_9627" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11378_9627"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_11378_9627"
          x1="23.7141"
          y1="15.0904"
          x2="19.9344"
          y2="94.4275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0074E8" />
          <stop offset="1" stop-color="#2650A3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11378_9627"
          x1="16"
          y1="12.1212"
          x2="19.3632"
          y2="26.5607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2650A3" />
          <stop offset="1" stop-color="#2650A3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11378_9627"
          x1="27.048"
          y1="23.0665"
          x2="75.1161"
          y2="55.4199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2650A3" />
          <stop offset="1" stop-color="#2650A3" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_11378_9627">
          <rect width="25.7143" height="28" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  );
};
