import {
  ParametersObject$Schema,
  applyCalculatedFields,
  buildEventEmitter,
  contextToFormValue,
  deepCopy,
  evaluateContextValue,
  extractNameAndIndex,
  filterOutContextFromDefinition,
  filterOutWizardDefinition,
  fixContextDefinition,
  fixWizardDefinition,
  getContextField,
  getContextRecord,
  getJsonValue,
  isParametersObject,
  setJsonValue,
  validateContextPayload,
} from "@prodoctivity/shared";
import type {
  ContextField,
  ContextPayloadValidationError,
  ContextPayloadValidationResult,
  DataLinkExecutionContext,
  DataLinkMapping,
  InputControlValueType,
  ParametersObject,
  RecordContextStack,
  TemplateContextDefinition,
  TemplateContextRecordHolder,
  TemplateWizardDefinition,
  TemplateWizardField,
} from "@prodoctivity/shared/src/index-types";
import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import type momentType from "moment";

export type FormControllerEvents = {
  onchange: { data: ParametersObject; progress: number };
};

export type FormControllerContext = {
  context: ParametersObject;
  currentSection: string;
  currentSectionErrors: ContextPayloadValidationError[];
  validationResult: ContextPayloadValidationResult;
  contextDefinition: TemplateContextDefinition;
  wizardDefinition: TemplateWizardDefinition;
  fullWizardDefinition: TemplateWizardDefinition;
  setContext: (h: (prevState: ParametersObject) => ParametersObject) => void;
  setCurrentSection(key: string): void;
  getContextValueAsString: (
    k: string,
    recordContextStack: RecordContextStack
  ) => string | undefined;
  updateContextRecordData: (
    recordFullPath: string,
    dataTypeValue: InputControlValueType | undefined,
    elementName: string
  ) => void;
  removeContextRecordInstance: (recordInstanceFullPath: string) => void;
  updateContextRecordInstance: (
    recordInstanceFullPath: string,
    recordValues: ParametersObject
  ) => void;
  addRecordInstanceRow: (recordInstanceFullPath: string, recordName: string) => void;
  moveRecordInstanceUp: (recordInstanceFullPath: string) => void;
  moveRecordInstanceDown: (recordInstanceFullPath: string) => void;

  subscribe(
    eventType: keyof FormControllerEvents,
    handler: (arg: { data: ParametersObject; progress: number }) => void
  ): {
    unsubscribe(): void;
  };
};

const valuesIsNull = (value: any) => {
  const nullValues = ["", undefined, [], [""], [undefined]];
  return nullValues.findIndex((v) => JSON.stringify(v) === JSON.stringify(value)) >= 0;
};

export const emptyFormController: FormControllerContext = {
  currentSection: "",
  context: {},
  currentSectionErrors: [],
  validationResult: {
    success: true,
    errors: [],
  },
  contextDefinition: {
    records: [],
    fields: [],
  },
  wizardDefinition: {
    defaultPageName: "",
    defaultSectionName: "",
    dependencies: [],
    inferredDependencies: [],
    pages: [
      {
        description: "",
        key: "page_1",
        label: "",
        properties: {},
        sections: [
          {
            description: "",
            fields: [],
            key: "section_1",
            label: "",
            properties: {},
          },
        ],
      },
    ],
  },
  fullWizardDefinition: {
    defaultPageName: "",
    defaultSectionName: "",
    dependencies: [],
    inferredDependencies: [],
    pages: [
      {
        description: "",
        key: "page_1",
        label: "",
        properties: {},
        sections: [
          {
            description: "",
            fields: [],
            key: "section_1",
            label: "",
            properties: {},
          },
        ],
      },
    ],
  },
  setContext() {
    //
  },
  setCurrentSection() {
    //
  },
  getContextValueAsString() {
    return undefined;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateContextRecordData() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeContextRecordInstance() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateContextRecordInstance() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addRecordInstanceRow() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  moveRecordInstanceUp() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  moveRecordInstanceDown() {},
  subscribe() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return { unsubscribe() {} };
  },
};

export const FormController = createContext<FormControllerContext>(emptyFormController);

type FormControllerProviderProps = PropsWithChildren & {
  contextDefinition: TemplateContextDefinition;
  wizardDefinition: TemplateWizardDefinition;
  dataLinkMappings: Array<DataLinkMapping>;
  moment: typeof momentType;
  executeDataLink:
    | ((
        dataLinkId: string,
        dataLinkConfigVersionId: string,
        inputParameters: Array<{
          key: string;

          value: boolean | number | string;
        }>
      ) => Promise<{
        contexts: DataLinkExecutionContext[];
      }>)
    | undefined;
  initialContext?: ParametersObject;
};

function recursivelyEnumerateWizardFields(fields: TemplateWizardField[]): string[] {
  const result: string[] = [];
  fields.forEach((wizardField) => {
    if (wizardField.isRecord) {
      recursivelyEnumerateWizardFields(wizardField.fields || []).forEach((childField) => {
        result.push(childField);
      });
    } else {
      result.push(wizardField.key);
    }
  });

  return result;
}

function populateRecordsInContext(
  holder: TemplateContextRecordHolder,
  context: ParametersObject
): ParametersObject {
  (holder.records || []).forEach((record) => {
    if (context[record.name] === undefined) {
      context[record.name] = [];
    }
  });

  return context;
}

export const FormControllerProvider: FunctionComponent<FormControllerProviderProps> = ({
  contextDefinition: rawContextDefinition,
  wizardDefinition: rawWizardDefinition,
  initialContext,
  dataLinkMappings,
  executeDataLink,
  moment,
  children,
}) => {
  // HACK: Sanitize movement. Please remove when done
  const { contextDefinition, wizardDefinition } = useMemo(() => {
    const contextDefinition = fixContextDefinition(rawContextDefinition, false);
    return {
      contextDefinition,
      wizardDefinition: fixWizardDefinition(rawWizardDefinition, contextDefinition, false),
    };
  }, [rawWizardDefinition, rawContextDefinition]);

  const eventEmitter = useMemo(() => {
    return buildEventEmitter<FormControllerEvents, keyof FormControllerEvents>();
  }, []);

  const normalizeInitialContext: () => ParametersObject = useCallback(() => {
    const initial = populateRecordsInContext(
      contextDefinition,
      initialContext && Object.keys(initialContext).length
        ? initialContext
        : createEmptyObjectFromHolder(moment, contextDefinition)
    );

    return initial;
  }, [contextDefinition, initialContext, moment]);

  const [payload, rawSetPayload] = useState<ParametersObject>(normalizeInitialContext);

  const { newWizardDefinition, newContextDefinition, validationResult } = useMemo(() => {
    const { newContextDefinition, fieldsToHide, ...validationResult } = validateContextPayload(
      contextDefinition,
      wizardDefinition,
      payload
    );
    const newWizardDefinition = filterOutWizardDefinition(
      wizardDefinition,
      contextDefinition,
      fieldsToHide
    );

    return { newWizardDefinition, newContextDefinition, validationResult, fieldsToHide };
  }, [contextDefinition, payload, wizardDefinition]);

  const setPayload: (h: (prevState: ParametersObject) => ParametersObject) => void = useCallback(
    (handler) => {
      rawSetPayload((prev) => {
        const data = handler(prev);

        const { newContextDefinition, fieldsToHide } = validateContextPayload(
          contextDefinition,
          wizardDefinition,
          data
        );

        const newPayload = filterOutContextFromDefinition(
          {
            ...newContextDefinition,
            fields: newContextDefinition.fields.filter((f) => !fieldsToHide.includes(f.fullPath)),
          },
          data
        );

        const progress = computeFormProgress(newPayload, contextDefinition);

        const withCalculatedFields = applyCalculatedFields(contextDefinition, newPayload);

        //diff here
        eventEmitter.emit("onchange", {
          data: withCalculatedFields,
          progress,
        });
        return withCalculatedFields;
      });
    },
    [eventEmitter, contextDefinition, wizardDefinition]
  );

  const allSections = useMemo(() => {
    return wizardDefinition.pages.flatMap((p) => p.sections);
  }, [wizardDefinition.pages]);
  const [currentSectionKey, setCurrentSectionKey] = useState(
    allSections.length ? allSections[0].key : ""
  );

  const removeContextRecordInstance = useCallback(
    (recordInstanceFullPath: string) => {
      setPayload((prev) => {
        const rootPayload = deepCopy(prev);
        let current: ParametersObject = rootPayload;
        if (!recordInstanceFullPath) {
          return prev;
        }

        const splitPath = recordInstanceFullPath.split("/");
        for (let cnt = 0; cnt < splitPath.length; cnt += 1) {
          const rawPart = splitPath[cnt];
          const extracted = extractNameAndIndex(rawPart);
          if (!extracted) {
            return prev;
          }

          const { variable: part, index: rawIndex } = extracted;

          const idx = rawIndex ?? 0;

          if (!current[part]) {
            return prev;
          }

          const childArray = current[part];
          if (!childArray || !Array.isArray(childArray)) {
            throw new Error("Cannot happen in payload 04");
          }

          if (!childArray[idx]) {
            return prev;
          }

          if (cnt === splitPath.length - 1) {
            childArray.splice(idx, 1);

            return rootPayload;
          }

          const childInstance = childArray[idx];
          if (typeof childInstance !== "object") {
            throw new Error("Cannot happen in payload 05");
          }
          current = childInstance;
        }

        return prev;
      });
    },
    [setPayload]
  );

  const updateContextRecordInstance = useCallback(
    (recordInstanceFullPath: string, recordValues: ParametersObject) => {
      setPayload((prev) => {
        const rootPayload = deepCopy(prev);
        let current: ParametersObject = rootPayload;
        const splitPath = recordInstanceFullPath.split("/");
        for (let cnt = 0; cnt < splitPath.length; cnt += 1) {
          const rawPart = splitPath[cnt];
          const extracted = extractNameAndIndex(rawPart);
          if (!extracted) {
            return prev;
          }

          const { variable: part, index } = extracted;

          const idx = index ?? 0;

          if (!current[part]) {
            return prev;
          }

          const childArray = current[part];
          if (!childArray || !Array.isArray(childArray)) {
            throw new Error("Cannot happen in payload 06");
          }

          if (!childArray[idx]) {
            return prev;
          }

          if (cnt === splitPath.length - 1) {
            childArray[idx] = recordValues;

            return rootPayload;
          }

          const childInstance = childArray[idx];
          if (typeof childInstance !== "object") {
            throw new Error("Cannot happen in payload 07");
          }
          current = childInstance;
        }

        return prev;
      });
    },
    [setPayload]
  );

  const fireDataLink = useCallback(
    async (payload: ParametersObject, dataElementName: string, _groupName?: string) => {
      if (!executeDataLink) {
        return;
      }

      const formValues = payload;

      const dataElements = allSections
        .flatMap((section) => section.fields)
        .map((wfld) => getContextField(contextDefinition, wfld.key))
        .reduce((acc: ContextField[], next) => {
          if (next) {
            acc.push(next);
          }

          return acc;
        }, []);

      const updatedFormFieldDataElement = dataElements.find((d) => d.name === dataElementName);
      if (!updatedFormFieldDataElement) {
        console.log(`Attempt to map name '${dataElementName}' to a DataElement failed.`);
        return;
      }
      const dataElementJSONPath = `$.${dataElementName}`;

      // we need to also include the fired form values of groups
      const firedValues = formValues;

      // Find dataLinks that should be fired
      const fireDataLinks = dataLinkMappings.filter(
        (dl) =>
          dl.mapping.input.every((i) => {
            const jsonPath = getJsonValue(firedValues, i.templateVersionContextPath);
            return !valuesIsNull(jsonPath);
          }) && dl.mapping.input.some((i) => i.templateVersionContextPath === dataElementJSONPath)
      );

      if (fireDataLinks.length <= 0) {
        return;
      }

      let outputValues: ParametersObject = {};
      for (const dataLink of fireDataLinks) {
        const inputParameters = dataLink.mapping.input.map((input) => {
          const value = getJsonValue(firedValues, input.templateVersionContextPath);
          return {
            key: input.inputParameterKey,
            value: Array.isArray(value) && value.length > 0 ? value[0] : value,
          };
        });

        //DEBUG - remove this. Only debugging.
        console.log(
          `Running datalink '${dataLink.datalinkId}' -> Version:'${dataLink.datalinkVersionId}'`
        );

        let contextResult = undefined;
        try {
          contextResult = await executeDataLink(
            dataLink.datalinkId,
            dataLink.datalinkVersionId,
            inputParameters
          );
        } catch (e) {
          console.error(e);
        }

        if (!contextResult) {
          return;
        }

        const { dataLinkFormValue /*, dataLinkGroupValue */ } = contextToFormValue(
          contextResult.contexts[0]
        );

        for (const outputTuple of dataLink.mapping.output) {
          if (dataLinkFormValue[outputTuple.outputContextKey] !== undefined) {
            const parsed = ParametersObject$Schema.safeParse(
              setJsonValue(
                formValues,
                outputTuple.templateVersionContextPath,
                dataLinkFormValue[outputTuple.outputContextKey]
              )
            );
            if (parsed.success) {
              outputValues = parsed.data;
            }
          }
        }
      }

      setPayload((prev) => ({ ...prev, ...formValues, ...outputValues }));
    },
    [allSections, contextDefinition, dataLinkMappings, executeDataLink, setPayload]
  );

  const updateContextRecordData = useCallback(
    (
      recordInstanceFullPath: string,
      dataTypeValue: InputControlValueType | undefined,
      elementName: string
    ) => {
      setPayload((prev) => {
        const rootPayload = deepCopy(prev);
        let current: ParametersObject = rootPayload;
        let currentPath = "";
        if (recordInstanceFullPath) {
          const path = recordInstanceFullPath.split("/");
          for (let cnt = 0; cnt < path.length - 1; cnt += 1) {
            const part = path[cnt];
            const extracted = extractNameAndIndex(part);

            if (!extracted) {
              throw new Error("Cannot happen in payload 11");
            }

            const { variable, index: rawIndex } = extracted;

            const index = rawIndex ?? 0;

            if (!currentPath) {
              currentPath = variable;
            } else {
              currentPath += `/${variable}`;
            }

            const contextRecord = getContextRecord(contextDefinition, currentPath);

            if (!current[variable]) {
              current[variable] = [];
            }

            const childArray = current[variable];
            if (!childArray || !Array.isArray(childArray)) {
              throw new Error("Cannot happen in payload 01");
            }

            if (!childArray[index]) {
              while (childArray.length <= index) {
                childArray.push(createEmptyObjectFromHolder(moment, contextRecord));
              }
            }

            const childInstance = childArray[index];
            if (typeof childInstance !== "object") {
              throw new Error("Cannot happen in payload 02");
            }
            current = childInstance;
          }
        }

        if (Array.isArray(current) || typeof current !== "object") {
          throw new Error("Cannot happen in payload 03");
        }

        if (dataTypeValue === undefined || dataTypeValue.value === undefined) {
          delete current[elementName];
        } else {
          current[elementName] = dataTypeValue.value;
        }
        fireDataLink(rootPayload, elementName);
        return rootPayload;
      });
    },
    [contextDefinition, fireDataLink, moment, setPayload]
  );

  const addRecordInstanceRow = useCallback(
    (recordInstanceFullPath: string, recordName: string) => {
      setPayload((prev) => {
        const rootPayload = deepCopy(prev);
        let current: ParametersObject = rootPayload;

        let currentPath = "";

        if (recordInstanceFullPath) {
          const splitPath = recordInstanceFullPath.split("/");
          for (let cnt = 0; cnt < splitPath.length; cnt += 1) {
            const rawPart = splitPath[cnt];
            const extracted = extractNameAndIndex(rawPart);
            if (!extracted) {
              throw new Error("Cannot happen in payload 13");
            }

            const { variable: part, index: rawIndex } = extracted;
            const idx = rawIndex ?? 0;

            if (!current[part]) {
              throw new Error("Cannot happen in payload 08");
            }

            const childArray = current[part];
            if (!childArray || !Array.isArray(childArray)) {
              throw new Error("Cannot happen in payload 09");
            }

            if (!childArray[idx]) {
              throw new Error("Cannot happen in payload 09");
            }

            const childInstance = childArray[idx];
            if (typeof childInstance !== "object") {
              throw new Error("Cannot happen in payload 10");
            }
            current = childInstance;
            if (!currentPath) {
              currentPath = part;
            } else {
              currentPath += `/${part}`;
            }
          }
        }

        if (!current) {
          throw new Error("Cannot happen in payload 14");
        }

        const list = current[recordName];

        const contextRecord = getContextRecord(
          contextDefinition,
          currentPath ? `${currentPath}/${recordName}` : recordName
        );

        if (list === undefined || list === null) {
          current[recordName] = [];
          return prev;
        }

        if (!Array.isArray(list)) {
          throw new Error("Cannot happen in payload 12");
        }

        list.push(createEmptyObjectFromHolder(moment, contextRecord));

        return rootPayload;
      });
    },
    [contextDefinition, moment, setPayload]
  );

  const moveRecordInstanceUp: FormControllerContext["moveRecordInstanceUp"] = useCallback(
    (recordInstanceFullPath) => {
      if (!recordInstanceFullPath) {
        return;
      }
      const root: ParametersObject = deepCopy(payload);
      let current: ParametersObject = root;
      const parts = recordInstanceFullPath.split("/");
      for (let cnt = 0; cnt < parts.length; cnt += 1) {
        const part = parts[cnt];
        const extracted = extractNameAndIndex(part);
        if (!extracted) {
          return;
        }

        const { variable, index: rawIndex } = extracted;
        const index = rawIndex ?? 0;
        const list = current[variable];
        if (!Array.isArray(list) || index <= 0 || index >= list.length) {
          return;
        }

        if (cnt === parts.length - 1) {
          const t = list[index - 1];
          list[index - 1] = list[index];
          list[index] = t;

          setPayload(() => root);
        } else {
          const holder = list[index];
          if (isParametersObject(holder)) {
            current = holder;
          } else {
            return;
          }
        }
      }
    },
    [payload, setPayload]
  );

  const moveRecordInstanceDown: FormControllerContext["moveRecordInstanceDown"] = useCallback(
    (recordInstanceFullPath) => {
      if (!recordInstanceFullPath) {
        return;
      }
      const root: ParametersObject = deepCopy(payload);
      let current: ParametersObject = root;
      const parts = recordInstanceFullPath.split("/");
      for (let cnt = 0; cnt < parts.length; cnt += 1) {
        const part = parts[cnt];
        const extracted = extractNameAndIndex(part);
        if (!extracted) {
          return;
        }

        const { variable, index: rawIndex } = extracted;
        const index = rawIndex ?? 0;
        const list = current[variable];
        if (
          !Array.isArray(list) ||
          index < 0 ||
          (cnt === parts.length - 1 && index >= list.length - 1)
        ) {
          return;
        }

        if (cnt === parts.length - 1) {
          const t = list[index + 1];
          list[index + 1] = list[index];
          list[index] = t;

          setPayload(() => root);
        } else {
          const holder = list[index];
          if (isParametersObject(holder)) {
            current = holder;
          } else {
            return;
          }
        }
      }
    },
    [payload, setPayload]
  );

  const getContextValueAsString = useCallback(
    (k: string, recordContextStack: RecordContextStack) => {
      const value = evaluateContextValue(payload, k, recordContextStack);
      if (typeof value.type !== "string") {
        return undefined;
      }

      return value.valueAsString;
    },
    [payload]
  );

  const currentSection = useMemo(() => {
    return allSections.find((section) => section.key === currentSectionKey);
  }, [allSections, currentSectionKey]);

  const subscribe: FormControllerContext["subscribe"] = useCallback(
    (eventType, handler) => {
      eventEmitter.on(eventType, handler);

      return {
        unsubscribe() {
          eventEmitter.off(eventType, handler);
        },
      };
    },
    [eventEmitter]
  );

  const value: FormControllerContext = useMemo(() => {
    const sectionFields = new Set<string>(
      recursivelyEnumerateWizardFields(currentSection?.fields || [])
    );

    const currentSectionErrors = validationResult.success
      ? []
      : validationResult.errors.filter((err) => sectionFields.has(err.fullPath));

    // console.log("dependencies");
    // console.log(wizardDefinition.dependencies);
    // console.log("Fields to hide");
    // console.log(fieldsToHide);
    // console.log("new wizard definition");
    // console.log(wizardDefinition);
    // console.log(newWizardDefinition);
    console.log("payload");
    console.log(payload);
    // console.log("contextDefinition");
    // console.log(contextDefinition);
    // console.log(newContextDefinition);
    // console.log("currentSectionKey");
    // console.log(currentSectionKey);
    // console.log("section");
    // console.log(currentSection);

    // console.log("sectionFields");
    // console.log(sectionFields);
    if (!validationResult.success) {
      console.log("errors");
      console.log(validationResult);
    }

    const result: FormControllerContext = {
      context: payload,
      contextDefinition: newContextDefinition,
      wizardDefinition: newWizardDefinition,
      fullWizardDefinition: wizardDefinition,
      currentSection: currentSectionKey,
      currentSectionErrors,
      validationResult,
      setContext: setPayload,
      setCurrentSection: setCurrentSectionKey,
      removeContextRecordInstance,
      getContextValueAsString,
      updateContextRecordData,
      updateContextRecordInstance,
      addRecordInstanceRow,
      subscribe,
      moveRecordInstanceDown,
      moveRecordInstanceUp,
    };

    return result;
  }, [
    addRecordInstanceRow,
    currentSectionKey,
    getContextValueAsString,
    moveRecordInstanceDown,
    moveRecordInstanceUp,
    payload,
    removeContextRecordInstance,
    setPayload,
    subscribe,
    updateContextRecordData,
    updateContextRecordInstance,
    wizardDefinition,
    currentSection,
    newContextDefinition,
    newWizardDefinition,
    validationResult,
  ]);

  return <FormController.Provider value={value}>{children}</FormController.Provider>;
};

export function useFormController() {
  const ctx = useContext(FormController);
  return ctx;
}

function createEmptyObjectFromHolder(
  moment: typeof momentType,
  holder: TemplateContextRecordHolder | undefined
): ParametersObject {
  if (!holder) {
    return {};
  }

  const result: ParametersObject = {};

  (holder.fields || []).forEach((fld) => {
    if (fld.properties.defaultValue !== undefined) {
      result[fld.name] = fld.properties.defaultValue;
    } else if (fld.properties.dataType === "Date" && fld.properties.isSystemDate) {
      result[fld.name] = moment().startOf("day").toISOString();
    } else if (fld.properties.dataType === "DateTime" && fld.properties.isSystemDate) {
      result[fld.name] = moment().toISOString();
    }
  });

  (holder.records || []).forEach((rec) => {
    result[rec.name] = [];
  });

  return result;
}

export function useEscapeKey(handler: () => void) {
  const keyHandler: (this: Window, ev: KeyboardEvent) => any = useCallback(
    (ev) => {
      if (ev.key === "Escape") {
        handler();
      }
    },
    [handler]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyHandler);

    return () => {
      window.removeEventListener("keydown", keyHandler);
    };
  }, [keyHandler]);
}

function computeFormProgress(
  payload: ParametersObject,
  contextDefinition: TemplateContextRecordHolder
): number {
  const { filled, total } = countFields(payload, contextDefinition);

  return (100 * filled) / total;
}

function countFields(
  payload: ParametersObject,
  holder: TemplateContextRecordHolder
): { total: number; filled: number } {
  let total = 0;
  let filled = 0;

  (holder.fields || []).forEach((fld) => {
    if (fld.properties.minOccurs === 0) {
      return;
    }
    total += 1;
    const v = payload[fld.name];
    if (v !== undefined && v !== null) {
      if (Array.isArray(v)) {
        if (v.filter((item) => item !== null && item !== "").length > 0) {
          filled += 1;
        }
      } else {
        if (typeof v === "string") {
          if (!!v) {
            filled += 1;
          }
        } else {
          filled += 1;
        }
      }
    }
  });

  (holder.records || []).forEach((rec) => {
    const v = payload[rec.name];
    if (Array.isArray(v)) {
      v.forEach((item) => {
        if (typeof item !== "object") {
          return;
        }

        const { filled: itemFilled, total: itemTotal } = countFields(item, rec);
        filled += itemFilled;
        total += itemTotal;
      });
    }
  });

  return {
    total,
    filled,
  };
}
