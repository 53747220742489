import { Box } from "@prodoctivity/design-system";
import "./style.css";

export const AnimatedWaves = () => {
  return (
    <Box display={"flex"}>
      <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use href="#gentle-wave" x="48" y="0" fill="rgba(229, 238, 250,0.7" />
          <use href="#gentle-wave" x="48" y="3" fill="rgba(60, 116, 198,0.5)" />
          <use href="#gentle-wave" x="48" y="5" fill="rgba(44, 94, 175,0.3)" />
          <use href="#gentle-wave" x="48" y="7" fill="#2650a3" />
        </g>
      </svg>
    </Box>
  );
};
