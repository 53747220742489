"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentCollectionIconDefaultToDocument$Schema = exports.DocumentCollectionIcon$Schema = exports.PredefinedIconType$Schema = exports.getIconList = void 0;
const zod_1 = require("zod");
function getIconList() {
    const icons = [
        {
            area: "financial-industry",
            icon: "bank",
            description: "An icon representing a bank.",
        },
        {
            area: "financial-industry",
            icon: "bank-note",
            description: "An icon representing currency or money.",
        },
        {
            area: "financial-industry",
            icon: "calculator",
            description: "An icon representing financial calculations.",
        },
        {
            area: "financial-industry",
            icon: "bar-chart",
            description: "An icon representing financial growth or stock charts.",
        },
        {
            area: "financial-industry",
            icon: "safe",
            description: "An icon depicting a safe for securing valuables.",
        },
        {
            area: "financial-industry",
            icon: "credit-card",
            description: "An icon representing credit or debit card transactions.",
        },
        {
            area: "financial-industry",
            icon: "piggy-bank",
            description: "An icon representing savings or investments.",
        },
        {
            area: "financial-industry",
            icon: "coins-stack",
            description: "An icon representing accumulated wealth or coinage.",
        },
        {
            area: "financial-industry",
            icon: "financial-document",
            description: "An icon representing financial statements or reports.",
        },
        {
            area: "financial-industry",
            icon: "stock-market",
            description: "An icon representing stock trading or market analysis.",
        },
        {
            area: "financial-industry",
            icon: "wallet",
            description: "An icon representing personal finance or cash holding.",
        },
        {
            area: "financial-industry",
            icon: "loan",
            description: "An icon representing borrowing or lending activities.",
        },
        {
            area: "law-firms",
            icon: "gavel",
            description: "An icon representing a judge's gavel.",
        },
        {
            area: "law-firms",
            icon: "legal-scale",
            description: "An icon representing the balance scales of justice.",
        },
        {
            area: "law-firms",
            icon: "contract",
            description: "An icon representing legal documents or contracts.",
        },
        {
            area: "law-firms",
            icon: "law-book",
            description: "An icon representing law books or statutes.",
        },
        {
            area: "law-firms",
            icon: "handcuffs",
            description: "An icon representing legal enforcement or arrest.",
        },
        {
            area: "law-firms",
            icon: "law-badge",
            description: "An icon representing legal authority or certification.",
        },
        {
            area: "law-firms",
            icon: "legal-briefcase",
            description: "An icon representing attorney's tools or profession.",
        },
        {
            area: "law-firms",
            icon: "witness-stand",
            description: "An icon representing court testimony or witness.",
        },
        {
            area: "law-firms",
            icon: "prison-cell",
            description: "An icon representing legal detention or imprisonment.",
        },
        {
            area: "law-firms",
            icon: "document-signature",
            description: "An icon representing agreement or legal consent.",
        },
        {
            area: "manufacturing",
            icon: "assembly-line",
            description: "An icon representing a production or assembly line.",
        },
        {
            area: "manufacturing",
            icon: "gear",
            description: "An icon representing machinery or mechanics.",
        },
        {
            area: "manufacturing",
            icon: "factory",
            description: "An icon representing a manufacturing plant or industrial setting.",
        },
        {
            area: "manufacturing",
            icon: "robot-arm",
            description: "An icon representing automation or robotic machinery.",
        },
        {
            area: "manufacturing",
            icon: "safety-helmet",
            description: "An icon representing safety in the workplace.",
        },
        {
            area: "manufacturing",
            icon: "toolbox",
            description: "An icon representing tools or maintenance.",
        },
        {
            area: "manufacturing",
            icon: "welding-mask",
            description: "An icon representing welding or specific manufacturing processes.",
        },
        {
            area: "manufacturing",
            icon: "product-box",
            description: "An icon representing packaged goods or products.",
        },
        {
            area: "manufacturing",
            icon: "quality-check",
            description: "An icon representing quality control or assurance.",
        },
        {
            area: "airlines",
            icon: "airplane",
            description: "An icon representing an airplane or flight.",
        },
        {
            area: "airlines",
            icon: "airport",
            description: "An icon representing an airport or terminal.",
        },
        {
            area: "airlines",
            icon: "passport",
            description: "An icon representing international travel or documentation.",
        },
        {
            area: "airlines",
            icon: "air-ticket",
            description: "An icon representing a boarding pass or air ticket.",
        },
        {
            area: "airlines",
            icon: "flight-attendant",
            description: "An icon representing cabin crew or airline staff.",
        },
        {
            area: "airlines",
            icon: "control-tower",
            description: "An icon representing air traffic control or oversight.",
        },
        {
            area: "airlines",
            icon: "air-cargo",
            description: "An icon representing freight or cargo transportation by air.",
        },
        {
            area: "airlines",
            icon: "departure-board",
            description: "An icon representing flight schedules or information displays.",
        },
        {
            area: "airlines",
            icon: "seat-belt",
            description: "An icon representing safety measures or in-flight procedures.",
        },
        {
            area: "airlines",
            icon: "pilot-badge",
            description: "An icon representing flight pilots or aviation professionals.",
        },
        {
            area: "medical",
            icon: "stethoscope",
            description: "An icon representing a doctor's examination tool.",
        },
        {
            area: "medical",
            icon: "syringe",
            description: "An icon representing injections or vaccinations.",
        },
        {
            area: "medical",
            icon: "hospital",
            description: "An icon representing a hospital or medical facility.",
        },
        {
            area: "medical",
            icon: "ambulance",
            description: "An icon representing emergency medical transportation.",
        },
        {
            area: "medical",
            icon: "pill",
            description: "An icon representing medication or pharmaceuticals.",
        },
        {
            area: "medical",
            icon: "medical-record",
            description: "An icon representing patient records or medical history.",
        },
        {
            area: "medical",
            icon: "heartbeat",
            description: "An icon representing health monitoring or cardiograms.",
        },
        {
            area: "medical",
            icon: "microscope",
            description: "An icon representing research or laboratory work.",
        },
        {
            area: "medical",
            icon: "surgical-mask",
            description: "An icon representing medical safety or surgical procedures.",
        },
        {
            area: "medical",
            icon: "dna",
            description: "An icon representing genetics or molecular biology.",
        },
        {
            area: "health-insurance",
            icon: "insurance-card",
            description: "An icon representing a health insurance card or proof of coverage.",
        },
        {
            area: "health-insurance",
            icon: "shield-check",
            description: "An icon representing insurance protection or assurance.",
        },
        {
            area: "health-insurance",
            icon: "policy",
            description: "An icon representing an insurance policy or document.",
        },
        {
            area: "health-insurance",
            icon: "hospital-sign",
            description: "An icon representing a medical facility covered by insurance.",
        },
        {
            area: "health-insurance",
            icon: "claim-form",
            description: "An icon representing an insurance claim or request for benefits.",
        },
        {
            area: "health-insurance",
            icon: "premium",
            description: "An icon representing periodic insurance payments or premiums.",
        },
        {
            area: "health-insurance",
            icon: "deductible",
            description: "An icon representing insurance deductibles or out-of-pocket costs.",
        },
        {
            area: "health-insurance",
            icon: "emergency-cover",
            description: "An icon representing emergency medical coverage or services.",
        },
        {
            area: "health-insurance",
            icon: "healthcare-network",
            description: "An icon representing networked hospitals or healthcare providers.",
        },
        {
            area: "health-insurance",
            icon: "phone-assistance",
            description: "An icon representing helpline or customer support for policyholders.",
        },
        {
            area: "retail",
            icon: "shopping-bag",
            description: "An icon representing a shopping bag or purchase.",
        },
        {
            area: "retail",
            icon: "barcode",
            description: "An icon representing product identification or scanning.",
        },
        {
            area: "retail",
            icon: "cash-register",
            description: "An icon representing a checkout point or payment processing.",
        },
        {
            area: "retail",
            icon: "price-tag",
            description: "An icon representing product pricing or discounts.",
        },
        {
            area: "retail",
            icon: "shopping-cart",
            description: "An icon representing a shopping cart or basket.",
        },
        {
            area: "retail",
            icon: "store-front",
            description: "An icon representing a physical retail store or outlet.",
        },
        {
            area: "retail",
            icon: "online-shop",
            description: "An icon representing online shopping or e-commerce.",
        },
        {
            area: "retail",
            icon: "discount",
            description: "An icon representing offers, sales, or discounts.",
        },
        {
            area: "retail",
            icon: "customer-service",
            description: "An icon representing customer support or assistance.",
        },
        {
            area: "retail",
            icon: "gift-box",
            description: "An icon representing gifts or special packages.",
        },
        {
            area: "real-estate",
            icon: "house",
            description: "An icon representing a residential property or home.",
        },
        {
            area: "real-estate",
            icon: "skyscraper",
            description: "An icon representing commercial buildings or offices.",
        },
        {
            area: "real-estate",
            icon: "for-sale-sign",
            description: "An icon indicating a property is for sale.",
        },
        {
            area: "real-estate",
            icon: "keys",
            description: "An icon representing property keys or ownership.",
        },
        {
            area: "real-estate",
            icon: "mortgage",
            description: "An icon representing property financing or loans.",
        },
        {
            area: "real-estate",
            icon: "blueprint",
            description: "An icon representing architectural plans or designs.",
        },
        {
            area: "real-estate",
            icon: "land",
            description: "An icon representing vacant land or plots.",
        },
        {
            area: "real-estate",
            icon: "apartment",
            description: "An icon representing multi-unit buildings or apartments.",
        },
        {
            area: "real-estate",
            icon: "lease-agreement",
            description: "An icon representing rental contracts or lease agreements.",
        },
        {
            area: "real-estate",
            icon: "property-agent",
            description: "An icon representing a real estate agent or broker.",
        },
        {
            area: "education",
            icon: "book",
            description: "An icon representing books or academic literature.",
        },
        {
            area: "education",
            icon: "mortarboard",
            description: "An icon representing graduation or academic achievement.",
        },
        {
            area: "education",
            icon: "school",
            description: "An icon representing schools or educational institutions.",
        },
        {
            area: "education",
            icon: "pencil",
            description: "An icon representing writing, note-taking, or academic tasks.",
        },
        {
            area: "education",
            icon: "world",
            description: "An icon representing the world.",
        },
        {
            area: "education",
            icon: "classroom",
            description: "An icon representing a teaching or learning environment.",
        },
        {
            area: "education",
            icon: "online-course",
            description: "An icon representing digital learning or online classes.",
        },
        {
            area: "education",
            icon: "certificate",
            description: "An icon representing qualifications, certifications, or accolades.",
        },
        {
            area: "education",
            icon: "student-avatar",
            description: "An icon representing a student or learner.",
        },
        {
            area: "education",
            icon: "lecture",
            description: "An icon representing lectures, seminars, or talks.",
        },
        {
            area: "criminal-investigation",
            icon: "magnifying-glass",
            description: "An icon representing the act of searching or investigating.",
        },
        {
            area: "criminal-investigation",
            icon: "crime-scene-tape",
            description: "An icon symbolizing a crime scene or restricted area.",
        },
        {
            area: "criminal-investigation",
            icon: "police-badge",
            description: "An icon representing police authority or identification.",
        },
        {
            area: "criminal-investigation",
            icon: "fingerprint",
            description: "An icon representing identity verification or forensic evidence.",
        },
        {
            area: "criminal-investigation",
            icon: "jail-cell",
            description: "An icon representing imprisonment or detention facilities.",
        },
        {
            area: "criminal-investigation",
            icon: "police-car",
            description: "An icon symbolizing law enforcement vehicles or patrols.",
        },
        {
            area: "criminal-investigation",
            icon: "evidence-bag",
            description: "An icon representing collected evidence or crucial items.",
        },
        {
            area: "criminal-investigation",
            icon: "interrogation",
            description: "An icon symbolizing questioning, interviews, or investigation procedures.",
        },
        {
            area: "general",
            icon: "default",
            description: "A default icon representing a document type.",
        },
        {
            area: "general",
            icon: "document",
            description: "A default document icon representing generic files or content.",
        },
        {
            area: "general",
            icon: "folder",
            description: "An icon representing a folder or collection of documents.",
        },
        {
            area: "general",
            icon: "attachment",
            description: "An icon representing attached files or supplementary documents.",
        },
        {
            area: "general",
            icon: "search",
            description: "An icon representing the search or find function for documents.",
        },
        {
            area: "general",
            icon: "lock",
            description: "An icon representing protected or encrypted documents.",
        },
        {
            area: "general",
            icon: "share",
            description: "An icon representing document sharing or collaboration.",
        },
        {
            area: "general",
            icon: "cloud-upload",
            description: "An icon representing document upload or cloud storage.",
        },
        {
            area: "general",
            icon: "cloud-download",
            description: "An icon representing document download or retrieval.",
        },
        {
            area: "general",
            icon: "print",
            description: "An icon representing the print function for documents.",
        },
        {
            area: "general",
            icon: "delete",
            description: "An icon representing the removal or deletion of documents.",
        },
    ];
    return icons;
}
exports.getIconList = getIconList;
exports.PredefinedIconType$Schema = zod_1.z.enum([
    "bank",
    "bank-note",
    "calculator",
    "bar-chart",
    "safe",
    "credit-card",
    "piggy-bank",
    "coins-stack",
    "financial-document",
    "stock-market",
    "wallet",
    "loan",
    "gavel",
    "legal-scale",
    "contract",
    "law-book",
    "handcuffs",
    "law-badge",
    "legal-briefcase",
    "witness-stand",
    "prison-cell",
    "document-signature",
    "assembly-line",
    "gear",
    "factory",
    "robot-arm",
    "safety-helmet",
    "toolbox",
    "welding-mask",
    "product-box",
    "quality-check",
    "airplane",
    "airport",
    "passport",
    "air-ticket",
    "flight-attendant",
    "control-tower",
    "air-cargo",
    "departure-board",
    "seat-belt",
    "pilot-badge",
    "stethoscope",
    "syringe",
    "hospital",
    "ambulance",
    "pill",
    "medical-record",
    "heartbeat",
    "microscope",
    "surgical-mask",
    "dna",
    "insurance-card",
    "shield-check",
    "policy",
    "hospital-sign",
    "claim-form",
    "premium",
    "deductible",
    "emergency-cover",
    "healthcare-network",
    "phone-assistance",
    "shopping-bag",
    "barcode",
    "cash-register",
    "price-tag",
    "shopping-cart",
    "store-front",
    "online-shop",
    "discount",
    "customer-service",
    "gift-box",
    "house",
    "skyscraper",
    "for-sale-sign",
    "keys",
    "mortgage",
    "blueprint",
    "land",
    "apartment",
    "lease-agreement",
    "property-agent",
    "book",
    "mortarboard",
    "school",
    "pencil",
    "world",
    "classroom",
    "online-course",
    "certificate",
    "student-avatar",
    "lecture",
    "magnifying-glass",
    "crime-scene-tape",
    "police-badge",
    "fingerprint",
    "jail-cell",
    "police-car",
    "evidence-bag",
    "interrogation",
    "default",
    "document",
    "folder",
    "attachment",
    "search",
    "lock",
    "share",
    "cloud-upload",
    "cloud-download",
    "print",
    "delete",
]);
exports.DocumentCollectionIcon$Schema = exports.PredefinedIconType$Schema;
exports.DocumentCollectionIconDefaultToDocument$Schema = exports.DocumentCollectionIcon$Schema.catch("document");
