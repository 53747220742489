"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccessConstraint = exports.getLanguage = exports.getIdentificationType = exports.OrganizationUserLanguage$Schema = void 0;
const zod_1 = require("zod");
exports.OrganizationUserLanguage$Schema = zod_1.z
    .enum(["en", "es", "pt", "nl"])
    .catch("en");
function getIdentificationType() {
    return ["IdentityDocumentEsDo", "Passport", "TaxpayerIdentificationNumberEsDo", "Other"];
}
exports.getIdentificationType = getIdentificationType;
function getLanguage() {
    return ["en", "es", "pt", "nl"];
}
exports.getLanguage = getLanguage;
function getAccessConstraint() {
    return [
        { type: "unrestricted" },
        { type: "dateRange", fromDate: new Date().getTime(), toDate: new Date().getTime() },
        { type: "limitDate", toDate: new Date().getTime() },
    ];
}
exports.getAccessConstraint = getAccessConstraint;
