import { calculateColor, type ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const RecentActivityWidgetIconSvg: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 40,
  height = 40,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M29 19.11V13C29 12.4696 28.7893 11.9609 28.4142 11.5858C28.0391 11.2107 27.5304 11 27 11H22.82C22.4 9.84 21.3 9 20 9C18.7 9 17.6 9.84 17.18 11H13C11.9 11 11 11.9 11 13V27C11 27.5304 11.2107 28.0391 11.5858 28.4142C11.9609 28.7893 12.4696 29 13 29H19.11C20.37 30.24 22.09 31 24 31C27.87 31 31 27.87 31 24C31 22.09 30.24 20.37 29 19.11ZM20 11C20.55 11 21 11.45 21 12C21 12.55 20.55 13 20 13C19.45 13 19 12.55 19 12C19 11.45 19.45 11 20 11ZM14 15H26V17H14V15ZM17.08 25H14V23H17.08C17.03 23.33 17 23.66 17 24C17 24.34 17.03 24.67 17.08 25ZM14 21V19H19.11C18.5 19.57 18.04 20.25 17.68 21H14ZM24 29C21.24 29 19 26.76 19 24C19 21.24 21.24 19 24 19C26.76 19 29 21.24 29 24C29 26.76 26.76 29 24 29ZM24.5 24.25L27.36 25.94L26.61 27.16L23 25V20H24.5V24.25Z"
        fill={color ? calculateColor(color) : "#FBA528"}
      />
    </svg>
  );
};
