import { Box, Spinner } from "@prodoctivity/design-system";

import { useMemo } from "react";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { TemplateEditWizard } from "../../components/TemplateEditWizard/TemplateEditWizard";
import { organizationLinkTemplates } from "../../link-templates";
import { useTemplateEdit } from "./hooks";

function TemplateEditPage() {
  const {
    data: template,
    isLoading,
    state,
    selectedTabIndex,
    resources,
    eventManager,
    setSelectedTabIndex,
    onFinish,
  } = useTemplateEdit();

  const breadCrumbEntries: BreadCrumbEntry[] | undefined = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      {
        type: "url",
        name: resources.templates,
        url: organizationLinkTemplates.templateList(false),
      },
      { type: "text", name: template?.info?.informationDefinition.name || resources.loading },
    ];
  }, [resources, template]);

  if (isLoading) {
    return <Box></Box>;
  }

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      {isLoading || !template || !template.info ? (
        <Spinner show={true} accessibilityLabel={resources.loading} />
      ) : (
        <Box display="flex" direction="column" flex="grow">
          <TemplateEditWizard
            selectedTabIndex={selectedTabIndex}
            eventManager={eventManager}
            status={state.status}
            template={template}
            setSelectedTabIndex={setSelectedTabIndex}
            onFinish={onFinish}
            isLoading={state.loading}
          />
        </Box>
      )}
    </Page>
  );
}

export default TemplateEditPage;
