import { Box, Icon, Link, Text, useColors } from "@prodoctivity/design-system";

import { FC } from "react";
import { useDataElement } from "../../hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationLink } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";

type Props = {
  dataElementName: string;
  dataElementId: string;
};

const DataElementLink: FC<Props> = (props: Props) => {
  const organizationLink = useOrganizationLink();
  const { isLoading, data, isError } = useDataElement(props.dataElementName);
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  if (isError) {
    return <Text color={colors.error}>Err</Text>;
  } else if (isLoading) {
    return (
      <Box height={25} width={25}>
        <Icon icon="loader" color={colors.subtle} accessibilityLabel={resources.loading} />
      </Box>
    );
  } else if (!data || !data.dataElement) {
    return (
      <Box marginTop={1}>
        <Icon icon="circle-info" color={colors.error} accessibilityLabel={resources.error} />
      </Box>
    );
  } else {
    return (
      <Box marginTop={1}>
        <Link
          href={organizationLink(
            organizationLinkTemplates.dataElementDetailsByName(props.dataElementName)
          )}
        >
          <Icon icon="circle-info" accessibilityLabel={resources.details} />
        </Link>
        {/* <TapArea
          onTap={() => {
            organizationNavigate(
              organizationLinkTemplates.dataElementDetailsByName(props.dataElementName)
            );
          }}
        >
          <Icon icon="circle-info" accessibilityLabel={resources.details} />
        </TapArea> */}
      </Box>
    );
  }
};

export { DataElementLink };
