import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const ViewerLastPageIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8557 15.6883L27.5428 19.6168C27.7812 19.8166 27.7812 20.1834 27.5428 20.3832L22.8557 24.3117C22.5303 24.5845 22.0345 24.3531 22.0345 23.9285V22C22.0345 21.7239 21.8106 21.5 21.5345 21.5H11.5C11.2239 21.5 11 21.2761 11 21V19C11 18.7239 11.2239 18.5 11.5 18.5H21.5345C21.8106 18.5 22.0345 18.2761 22.0345 18V16.0715C22.0345 15.6469 22.5303 15.4155 22.8557 15.6883Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <rect
        x="29"
        y="13"
        width="2"
        height="14"
        rx="0.5"
        fill={color ? calculateColor(color) : "#91979E"}
      />
    </svg>
  );
};
