import { useColors, Box, frontIndex, ContextStateUpdater } from "@prodoctivity/design-system";
import { noop } from "@prodoctivity/prodoctivity-form-v5";
import { FormDataCapture } from "@prodoctivity/prodoctivity-form-v5/src/components/Wizard";
import moment from "moment";
import { FC, useCallback } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";

/**
 * Props for use WidgeReportForm
 */
export type WidgeReportFormProps = {
  /**
   * callback function for save change on current form
   * @param formContext form context
   * @returns
   */
  saveConfiguration: (formContext?: ParametersObject) => void;
  /**
   * callback for reset changes
   * @returns
   */
  resetConfiguration?: () => void;
};

/**
 * form for configure widget
 * @param param0
 * @returns JSX.Element
 */
export const WidgeReportForm: FC<WidgeReportFormProps> = ({
  resetConfiguration,
  saveConfiguration,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const onFormChange = useCallback(
    (formData: ParametersObject) => {
      if (JSON.stringify(formData) === "{}" && resetConfiguration) {
        resetConfiguration();
        return;
      }
      saveConfiguration(formData);
    },
    [resetConfiguration, saveConfiguration]
  );

  return (
    <Box
      display="flex"
      direction="column"
      minWidth={400}
      padding={4}
      color={colors.white}
      borderStyle="raisedTopShadow"
      borderRadius={12}
      zIndex={frontIndex}
      gap={4}
    >
      <Box display="flex" direction="column" gap={4}>
        <ContextStateUpdater onUpdate={(e) => onFormChange(e.data)} />
        <FormDataCapture
          formConfiguration={{ formConnectors: [], formLayout: { layout: [], groupLayouts: {} } }}
          formDefinition={{ allowedListValues: [], contextFields: [], groups: [] }}
          connectors={[]}
          dataLinks={[]}
          connectorDataLinks={[]}
          dataLinkMappings={[]}
          executeDataLink={() => {
            return {} as any;
          }}
          onUpdateValues={noop}
          onFireConnector={() =>
            new Promise((resolve) =>
              resolve({
                formValues: null,
                formErrors: null,
                groupValues: null,
              })
            )
          }
          readOnly={false}
          i18n={() => ""}
          onFormErrorsUpdate={noop}
          onDependencyLayoutUpdate={noop}
          onNoDataFound={noop}
          moment={moment}
          resources={resources}
        />
      </Box>
      <Box
        display="flex"
        direction="row"
        justifyContent="between"
        maxHeight={40}
        width={"100%"}
        gap={4}
      ></Box>
    </Box>
  );
};
