"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpressionControl = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var design_system_1 = require("@prodoctivity/design-system");
var shared_1 = require("@prodoctivity/shared");
var ExpressionUtils_1 = require("./ExpressionUtils");
var FilterBuilder_1 = require("./FilterBuilder/FilterBuilder");
var hooks_1 = require("./hooks");
var ExpressionControlComponent = function (props) {
    var _a = (0, hooks_1.useExpressionControl)(props), i18n = _a.i18n, toggleReadMode = _a.toggleReadMode, 
    // toggleHelpMode,
    localOnExpressionChanged = _a.localOnExpressionChanged, moment = _a.moment, state = _a.state, colors = _a.colors, fields = _a.fields;
    if (state.readMode) {
        return ((0, jsx_runtime_1.jsxs)("div", { className: "expression-control", children: [(0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "row", children: (0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", direction: "column", children: [(0, jsx_runtime_1.jsx)("span", { children: i18n("summary") }), (0, jsx_runtime_1.jsx)(design_system_1.Switch, { id: "".concat(state.id, "-read-mode"), switched: state.readMode, onChange: toggleReadMode })] }) }), (0, jsx_runtime_1.jsx)("div", { className: "expression-control-representation", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { children: (0, jsx_runtime_1.jsx)(ExpressionUtils_1.RichExpressionComponent, { t: i18n, expression: props.expression, level: 0, contextDefinition: props.contextDefinition }) }) })] }));
    }
    return (
    // <div className="expression-control">
    (0, jsx_runtime_1.jsxs)(design_system_1.Box, { color: colors.white, children: [props.subtitle || props.help ? ((0, jsx_runtime_1.jsxs)("div", { className: "alert alert-block col-sm-12", children: [props.subtitle ? ((0, jsx_runtime_1.jsxs)("h4", { children: [(0, jsx_runtime_1.jsx)("i", { className: "fa fa-bell-alt" }), props.subtitle] })) : null, props.help ? props.help : null] })) : null, props.hideName ? null : ((0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "row" })), (0, jsx_runtime_1.jsx)(design_system_1.Box, { children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "column", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "row", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { width: "100%", children: (0, jsx_runtime_1.jsx)(FilterBuilder_1.FilterBuilder, { contextDefinition: props.contextDefinition, translateFn: i18n, helpMode: state.helpMode, level: 0, expression: props.expression ? props.expression : new shared_1.Expression().toDataInput(), expressionIndex: 0, fieldList: fields, onExpressionChanged: localOnExpressionChanged, moment: moment, resources: props.resources, colors: colors, parentExpressionOperator: undefined }) }) }) }) })] }));
};
exports.ExpressionControl = ExpressionControlComponent;
