import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const WitnessStandIconSvg: FunctionComponent<Props> = ({
  color,
  width = 22,
  height = 25,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5718_96442)">
        <path d="M17.5996 21.7764V24.9997H0.893555V21.7764H17.5996Z" fill={color || "#5A5A5A"} />
        <path
          d="M9.25292 14.4408C9.9727 13.5383 10.6577 12.6873 11.331 11.8364C11.4819 11.643 11.6329 11.5527 11.8651 11.5527C13.3859 11.5527 14.512 12.8034 14.5236 14.4924C14.5236 15.2144 14.5236 15.9493 14.5236 16.6714C14.5236 16.7745 14.5236 16.8648 14.512 16.9937H3.9822C3.9822 16.8905 3.95898 16.8003 3.95898 16.71C3.95898 15.9751 3.95898 15.2273 3.95898 14.4924C3.97059 12.7131 5.23603 11.4238 6.82653 11.5656C6.93102 11.5656 7.05872 11.6817 7.13999 11.7848C7.80173 12.5971 8.45186 13.4223 9.1136 14.2474C9.16004 14.299 9.19487 14.3635 9.25292 14.4408Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M9.2526 20.7706C7.82463 20.7706 6.38505 20.7706 4.95708 20.7706C3.69165 20.7706 2.42621 20.7835 1.16078 20.7835C0.649959 20.7835 0.243627 20.4741 0.0810936 19.9841C-0.0814393 19.5071 0.0346556 18.9656 0.382941 18.6046C0.63835 18.3467 0.940197 18.2822 1.27687 18.2822C5.06157 18.2822 8.84627 18.2822 12.6426 18.2822C14.2099 18.2822 15.7771 18.2822 17.356 18.2822C17.8901 18.2822 18.3196 18.6432 18.4473 19.1848C18.5866 19.7392 18.4009 20.3194 17.9481 20.5901C17.7275 20.719 17.4605 20.7706 17.2051 20.7706C14.5581 20.7706 11.8996 20.7706 9.2526 20.7706C9.2526 20.7706 9.2526 20.7577 9.2526 20.7448V20.7706Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M9.22973 3.8291C10.9479 3.8291 12.3295 5.3505 12.3295 7.2587C12.3295 9.15401 10.9479 10.6883 9.24134 10.6883C7.53474 10.6883 6.1416 9.1669 6.1416 7.28449C6.1416 5.37629 7.51152 3.8291 9.22973 3.8291Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M2.00864 17.0194C1.86933 17.0194 1.76484 17.0194 1.67196 17.0194C1.57909 17.0194 1.48621 17.0194 1.38173 17.0065C1.38173 16.8905 1.37012 16.7873 1.37012 16.6971C1.37012 14.9694 1.37012 13.2288 1.37012 11.5011C1.37012 11.2948 1.41656 11.153 1.55587 11.0241C1.9622 10.6373 2.35692 10.2376 2.77487 9.83789C2.91418 10.0184 3.03028 10.186 3.1812 10.3665C2.87935 10.676 2.57751 10.9854 2.25244 11.2819C2.0783 11.4367 2.00864 11.6043 2.00864 11.8621C2.00864 13.448 2.00864 15.0468 2.00864 16.6326C2.00864 16.7487 2.00864 16.8776 2.00864 17.0194Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M4.46984 9.34783C4.46984 9.83777 4.13317 10.2117 3.70362 10.2117C3.26246 10.2117 2.92578 9.82488 2.92578 9.36072C2.92578 8.89656 3.26246 8.52266 3.6804 8.50977C4.10995 8.50977 4.45823 8.87078 4.46984 9.34783Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M21.4662 6.45952H16.9268C16.8107 6.45952 16.6947 6.49819 16.6018 6.57555L14.8487 8.03249L15.2202 6.44662H13.7458C13.444 6.44662 13.2002 6.17586 13.2002 5.84064V0.605982C13.2002 0.270758 13.444 0 13.7458 0H21.4778C21.7796 0 22.0234 0.270758 22.0234 0.605982V5.86643C22.0234 6.20165 21.7796 6.47241 21.4778 6.47241L21.4662 6.45952Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M20.2819 1.37988H14.9183C14.7644 1.37988 14.6396 1.51842 14.6396 1.68932V1.79247C14.6396 1.96336 14.7644 2.1019 14.9183 2.1019H20.2819C20.4357 2.1019 20.5605 1.96336 20.5605 1.79247V1.68932C20.5605 1.51842 20.4357 1.37988 20.2819 1.37988Z"
          fill="white"
        />
        <path
          d="M18.7379 3.28809H15.5569C15.4031 3.28809 15.2783 3.42663 15.2783 3.59752V3.70067C15.2783 3.87157 15.4031 4.01011 15.5569 4.01011H18.7379C18.8918 4.01011 19.0166 3.87157 19.0166 3.70067V3.59752C19.0166 3.42663 18.8918 3.28809 18.7379 3.28809Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5718_96442">
          <rect width="22" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
