import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const DeletedDocumentSvgIcon: FunctionComponent<Props> = ({
  width = 35,
  height = 41,
  color,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6948_103272)">
        <path
          d="M28.0677 21.6469L27.3538 28.9743C27.2975 29.6828 26.6775 30.2421 25.9636 30.2421C25.9072 30.2421 25.8697 30.2235 25.8321 30.2235C25.043 30.1489 24.4794 29.4777 24.5546 28.6946L25.2497 21.3672C25.3248 20.6028 26.0387 20.0434 26.7902 20.118C27.5605 20.1926 28.1429 20.8638 28.0677 21.6469Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M22.0938 21.4785L22.0186 28.8432C21.9999 29.6263 21.3799 30.2416 20.6096 30.2416H20.5908C19.8018 30.223 19.1818 29.589 19.2006 28.8246L19.2758 21.4412C19.2758 20.6768 19.9145 20.0615 20.6848 20.0615H20.7036C21.4738 20.0615 22.0938 20.6954 22.0938 21.4785Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M21.5301 4.77308C21.2859 4.88495 21.0041 4.94088 20.7223 4.94088C19.689 4.94088 18.8624 4.08322 18.8812 3.03911V1.82719C18.9187 0.801728 19.7642 0 20.8162 0C21.8495 0 22.6761 0.857662 22.6573 1.90177V3.11369C22.6198 3.85948 22.1689 4.49341 21.5301 4.77308Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M32.8021 11.597C32.3137 11.8207 31.7688 11.9326 31.2052 11.9139L9.78817 11.4292C7.75918 11.3919 6.16229 9.71384 6.19987 7.7002L6.25623 5.53739C6.31259 3.52375 7.98462 1.93894 10.0136 1.97623L31.4307 2.461C32.4076 2.47964 33.3282 2.88983 34.0045 3.57969C34.6808 4.28819 35.0378 5.20179 35.019 6.17132L34.9626 8.33412C34.925 9.76977 34.0421 11.0003 32.8021 11.5783V11.597ZM31.2616 9.1172C31.7313 9.1172 32.1258 8.76295 32.1258 8.29683L32.1822 6.13403C32.1822 5.91029 32.107 5.68655 31.9379 5.53739C31.7688 5.38824 31.5622 5.27637 31.3367 5.27637L9.91968 4.7916C9.45 4.7916 9.05548 5.1645 9.05548 5.61197L8.99912 7.77478C8.99912 8.2409 9.35607 8.63244 9.82574 8.65108L31.2428 9.13585L31.2616 9.1172Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M34.1918 15.0461C33.9288 14.7664 33.5531 14.5986 33.1586 14.5986H8.24713C7.85261 14.5986 7.47687 14.7664 7.21385 15.0461C6.95083 15.3444 6.81933 15.736 6.8569 16.1275L7.06356 18.4954H9.88159L9.78766 17.3954H31.618L29.8333 38.203H11.5724L11.4785 37.103H8.64166L8.8671 39.7132C8.94225 40.4404 9.54343 40.9997 10.2761 40.9997H31.1296C31.8623 40.9997 32.4822 40.4404 32.5386 39.7132L34.5676 16.1275C34.5864 15.736 34.4549 15.3444 34.1918 15.0461Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M13.7332 20.2852H1.91626C0.864198 20.2852 0 21.1988 0 22.3174V33.262C0 34.3807 0.864198 35.2943 1.91626 35.2943H13.7332C14.7853 35.2943 15.6495 34.3807 15.6495 33.262V22.3174C15.6495 21.1988 14.7853 20.2852 13.7332 20.2852ZM12.4745 30.9314C12.9442 31.4161 12.9442 32.2179 12.4745 32.7026C12.2491 32.945 11.9297 33.0569 11.6291 33.0569C11.3285 33.0569 11.0279 32.945 10.7837 32.684L9.56253 31.3788L7.83414 29.5516L7.06388 30.3534L4.84702 32.6467C4.62158 32.8891 4.32099 33.0196 4.0204 33.0196C3.70102 33.0196 3.40043 32.8891 3.1562 32.628C2.68653 32.1433 2.68653 31.3415 3.1562 30.8568L6.14332 27.7617L3.19377 24.6294C2.7241 24.1446 2.7241 23.3429 3.19377 22.8581C3.66345 22.3734 4.41492 22.3734 4.88459 22.8581L6.57542 24.6667L7.83414 25.9905L8.98014 24.7786L10.8213 22.8954C11.0091 22.6903 11.2721 22.5598 11.5352 22.5412C11.8921 22.5039 12.2303 22.6158 12.5121 22.9141C12.9818 23.3988 12.963 24.2006 12.5121 24.6853L11.7606 25.4498L9.52496 27.7804L12.4745 30.9127V30.9314Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6948_103272">
          <rect width="35" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
