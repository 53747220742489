import { Box, Button, useColors } from "@prodoctivity/design-system";
import { FormDataCapture } from "@prodoctivity/prodoctivity-form-v5/src/components/Wizard";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import type { HttpGetBundleConfigResponse } from "@prodoctivity/types";
import { noop } from "../../../utils";
import { useBundleForm } from "./hooks";

type BundleFormProps = {
  bundleConfig: HttpGetBundleConfigResponse["payload"]["bundleConfig"];
  isLoading: boolean;
  onCancel: () => void;
  onApply: (context: ParametersObject) => void;
};

export const BundleForm = ({ bundleConfig, isLoading, onCancel, onApply }: BundleFormProps) => {
  const { resources, moment, i18n, validationResult, onApplyClick, execDataLink } =
    useBundleForm(onApply);

  const { colors } = useColors();

  if (!bundleConfig.inputContextDefinition || !bundleConfig.inputWizardDefinition) {
    return <Box></Box>;
  }

  return (
    <Box width={"100%"}>
      <FormDataCapture
        formConfiguration={{ formConnectors: [], formLayout: { layout: [], groupLayouts: {} } }}
        formDefinition={{ allowedListValues: [], contextFields: [], groups: [] }}
        connectors={[]}
        dataLinks={[]}
        connectorDataLinks={[]}
        dataLinkMappings={[]}
        executeDataLink={execDataLink}
        onUpdateValues={noop}
        onFireConnector={() =>
          new Promise((resolve) =>
            resolve({
              formValues: null,
              formErrors: null,
              groupValues: null,
            })
          )
        }
        readOnly={isLoading}
        i18n={i18n}
        onFormErrorsUpdate={noop}
        onDependencyLayoutUpdate={noop}
        onNoDataFound={noop}
        moment={moment}
        resources={resources}
      />
      <Box
        justifyContent="end"
        color={colors.white}
        direction="row"
        display="flex"
        marginStart={4}
        marginEnd={4}
      >
        <Box width={140} marginEnd={4}>
          <Button
            color="transparent"
            onClick={onCancel}
            disabled={isLoading}
            text={resources.cancel}
          />
        </Box>
        <Box width={140}>
          <Button
            onClick={onApplyClick}
            color={"blue"}
            disabled={isLoading || !validationResult.success}
            text={resources.apply}
          />
        </Box>
      </Box>
    </Box>
  );
};
