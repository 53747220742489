import { Link as GestaltLink, LinkProps } from "gestalt";
import { NavLink as RRLink, NavLinkProps as RRLinkProps } from "react-router-dom";

export function Link(props: LinkProps) {
  return <GestaltLink {...props} />;
}

export function NavLink(props: RRLinkProps) {
  return <RRLink {...props} />;
}
