import {
  Box,
  BoxWithAnimations,
  BoxWithRef,
  Grid,
  SegmentedControl,
  TapArea,
} from "@prodoctivity/design-system";
import { range } from "@prodoctivity/shared";
import { useContext, useEffect, useMemo } from "react";
import { TemplateCardSkeleton } from "../../../loading";
import { DashboardItemContext } from "../../../pages/Dashboard/DashboardConfigColumnElement";
import { EmptyStateWidgetContent } from "../../../svg/EmptyStateWidgetContent";
import { GridLargeIcon } from "../../../svg/GridLargeIcon";
import { GridMediumIcon } from "../../../svg/GridMediumIcon";
import { GridSmallIcon } from "../../../svg/GridSmallIcon";
import { Pagination } from "../../Layout/Pagination";
import { NoResultsFoundBlock } from "../../NoResultsFoundBlock";
import { RecentDocumentCard } from "./RecentDocumentCard";
import { useRecentDocumentsWidget } from "./hooks";

const documentsDisplaySettingKeyName = "documents_display_setting";

export function documentsDisplaySettingInitialState() {
  if (window.localStorage) {
    const raw = window.localStorage.getItem(documentsDisplaySettingKeyName);
    if (raw !== undefined && raw !== null) {
      const val = Number.parseInt(raw, 10);
      if (!Number.isNaN(val) && val >= 0 && val < 3) {
        return val;
      }
    }
  }

  return 0;
}

export function RecentDocumentsGrid() {
  const {
    colors,
    resources,
    breakpoint,
    isLoading,
    handleMoveTo,
    currentPage,
    rowsPerPage,
    pageLengthOptions,
    setPageLength,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    previousPage,
    nextPage,
    itemIndex,
    setItemIndex,
    displayDocumentsAsCards,
    gridWidth,
    gridSizes,
    gridTemplateColumns,
    gridTemplateRows,
    templateAnimationSet,
    smallestBreakPoint,
    recentDocument,
    refetch,
    remove,
  } = useRecentDocumentsWidget();

  const dashboardItemContext = useContext(DashboardItemContext);

  useEffect(() => {
    const eventEmitter = dashboardItemContext.eventEmitter;
    if (eventEmitter) {
      const handler = () => {
        remove();
        refetch();
      };
      eventEmitter.on("refresh", handler);
      return () => {
        eventEmitter.off("refresh", handler);
      };
    }
  }, [dashboardItemContext, refetch, remove]);

  const displayItemsOptions = useMemo(() => {
    let sizeItems = [];
    if (breakpoint === "medium") {
      sizeItems = [
        <Box paddingY={2} display="flex" alignItems="center">
          <GridSmallIcon color={itemIndex === 0 ? colors.primary : undefined} />
        </Box>,
        <Box paddingY={2} display="flex" alignItems="center">
          <GridMediumIcon color={itemIndex === 1 ? colors.primary : undefined} />
        </Box>,
      ];
    } else {
      sizeItems = [
        <Box paddingY={2} display="flex" alignItems="center">
          <GridSmallIcon
            color={displayDocumentsAsCards && itemIndex === 0 ? colors.primary : undefined}
          />
        </Box>,
        <Box paddingY={2} display="flex" alignItems="center">
          <GridMediumIcon
            color={displayDocumentsAsCards && itemIndex === 1 ? colors.primary : undefined}
          />
        </Box>,
        <Box paddingY={2} display="flex" alignItems="center">
          <GridLargeIcon
            color={displayDocumentsAsCards && itemIndex === 2 ? colors.primary : undefined}
          />
        </Box>,
      ];
    }
    return sizeItems;
  }, [breakpoint, colors.primary, displayDocumentsAsCards, itemIndex]);

  const loading = isLoading;

  if (loading) {
    return (
      <>
        <Box width={"100%"} display="flex" flex="grow" direction="column" paddingTop={4}>
          <Grid
            gridTemplateColumns={gridTemplateColumns}
            gridTemplateRows={gridTemplateRows}
            gap={4}
            justifyContent="center"
          >
            {range(8).map((_, i) => (
              <BoxWithAnimations animationSet={templateAnimationSet} key={i}>
                <Box
                  key={`${i}`}
                  display="flex"
                  borderStyle="sm"
                  color={colors.neutral200}
                  maxHeight={415}
                  width={"100%"}
                  maxWidth={400}
                  borderRadius={12}
                  overflow="hidden"
                >
                  <TemplateCardSkeleton key={i} />
                </Box>
              </BoxWithAnimations>
            ))}
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box width={"100%"} display="flex" flex="grow" direction="column" paddingTop={4}>
        <Box
          display="flex"
          direction="row"
          justifyContent="between"
          paddingX={4}
          width={"100%"}
          gap={4}
        >
          <Box display="flex" alignItems="center">
            <TapArea>
              {!smallestBreakPoint && (
                <Box display="flex" gap={4} alignItems="center">
                  <Box width={200}>
                    <SegmentedControl
                      items={displayItemsOptions}
                      selectedItemIndex={itemIndex}
                      onChange={({ activeIndex }) => setItemIndex(activeIndex)}
                      size="sm"
                    ></SegmentedControl>
                  </Box>
                </Box>
              )}
            </TapArea>
          </Box>
          <Box display="flex" direction="row">
            <Box display="flex" flex="grow">
              <Pagination<typeof rowsPerPage>
                id="recent_documents_list_pagination_top"
                rowsLabel={`${resources.documents}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={recentDocument.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
              />
            </Box>
          </Box>
        </Box>

        <BoxWithRef ref={gridWidth} color={undefined} width={"100%"} padding={4}>
          <Grid
            gridTemplateColumns={recentDocument.length > 0 ? gridSizes[itemIndex] : [1]}
            gap={4}
            justifyContent={"start"}
            width={"100%"}
          >
            {recentDocument && recentDocument.length <= 0 ? (
              <NoResultsFoundBlock
                key={`${recentDocument.length}_not-found`}
                messageTitle={resources.dashboard.noContentFound}
                messageDescription={""}
                illustration={<EmptyStateWidgetContent width={"100%"} height={"100%"} />}
              />
            ) : (
              <>
                {recentDocument.map((recentDocument, index) => {
                  return (
                    <RecentDocumentCard
                      ecmDocument={recentDocument}
                      onChange={handleMoveTo}
                      idx={index}
                      itemIndex={itemIndex}
                      key={`${recentDocument.documentId}_${recentDocument.documentVersionId}`}
                    />
                  );
                })}
              </>
            )}
          </Grid>
        </BoxWithRef>
      </Box>
    </>
  );
}
