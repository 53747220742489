import { FC, useMemo } from "react";
import { ResponsivePie } from "@nivo/pie";
import { leyendLocation, leyendPieConfig } from "./leyendLocation";

export const PieChart: FC<{
  data: Array<{ id: string; label: string; value: number; color: string }>;
  useLeyend?: boolean;
  leyendLocation?: leyendLocation;
  formatOutputValueInChart: boolean;
  useCustomColor?: boolean;
  arcLabelsRadiusOffset: number;
  /**
   * format value over charts
   * @example (e) => e.value.toString() result output  = 5
   * @example (e) => "RD$ "+e.value.toString()
   */
  format?: (e: any) => string;
}> = ({
  data,
  useLeyend,
  leyendLocation,
  formatOutputValueInChart,
  format,
  useCustomColor,
  arcLabelsRadiusOffset,
}) => {
  const labelTextColorConfig: any = {
    from: "color",
    modifiers: [["darker", 2.5]],
  };

  const configLeyend = useMemo(() => {
    if (!useLeyend) {
      return leyendPieConfig["none"];
    }

    if (!leyendLocation) {
      return leyendPieConfig["none"];
    }

    return leyendPieConfig[leyendLocation];
  }, [leyendLocation, useLeyend]);

  const displayValueInChartInFormat = useMemo(() => {
    if (!formatOutputValueInChart) {
      return undefined;
    }
    return format;
  }, [formatOutputValueInChart, format]);

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 0, right: 20, bottom: 0, left: 20 }}
      valueFormat=" >-"
      innerRadius={0.5}
      transitionMode="centerRadius"
      padAngle={1}
      cornerRadius={0}
      activeInnerRadiusOffset={12}
      activeOuterRadiusOffset={8}
      colors={useCustomColor ? ({ data }) => data.color : { scheme: "category10" }}
      borderWidth={1}
      enableArcLinkLabels={false}
      arcLinkLabelsTextOffset={21}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsOffset={-22}
      arcLinkLabelsDiagonalLength={36}
      arcLinkLabelsThickness={7}
      arcLabel={displayValueInChartInFormat}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={labelTextColorConfig}
      motionConfig="gentle"
      legends={configLeyend}
      arcLabelsRadiusOffset={arcLabelsRadiusOffset}
    />
  );
};
