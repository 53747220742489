import {
  Box,
  Button,
  Divider,
  Heading,
  Link,
  PasswordSetterField,
  Spinner,
  Text,
  TextField,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { checkPasswordStrength } from "@prodoctivity/shared";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LoginLayout } from "../../components/Layout/LoginLayout";
import { LoginLayoutSmall } from "../../components/Layout/LoginLayoutFooterSmall";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { PasswordStrength } from "../../utils";
import { PasswordRecoveryMedium } from "./PasswordRecoveryMedium";
import { InvalidTokenSvg } from "../../svg/InvalidTokenSvg";

const PasswordRecoveryPage: FC = () => {
  const { colors } = useColors();
  const { search } = useLocation();
  const { email, token } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      email: params.get("email"),
      token: params.get("token"),
    };
  }, [search]);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength | undefined>(undefined);
  const [submit, setSubmit] = useState(false);
  const passwordNotMatch = password && password !== confirmedPassword;
  const { activateForgotPasswordOTP, verifyPasswordToken } = useServices();
  const popoverRef = useRef<HTMLInputElement>(null);
  const { breakpoint } = useDesignBreakpoint();
  const anchorRef = popoverRef.current;
  const { resources } = useAppTranslation();
  const verifyingToken = useCallback(async () => {
    if (token && email) {
      try {
        const res = await verifyPasswordToken(token, email);
        return res;
      } catch (error) {
        throw error;
      }
    }
  }, [email, token, verifyPasswordToken]);
  const { isLoading: tokenIsLoading, isError: tokenIsError } = useQuery(
    [`/verify-password-token/`, token],
    verifyingToken,
    {
      staleTime: 60 * 1000,
    }
  );
  const [errorMessage, setErrorMessage] = useState("");
  const doChangePassword = useCallback(async () => {
    if (token && email) {
      await activateForgotPasswordOTP(email, token, password);
      return true;
    }
    return false;
  }, [activateForgotPasswordOTP, email, password, token]);
  const { data, mutate, isLoading } = useMutation(doChangePassword, {
    onError(error: any) {
      const message = error?.response?.data?.message;
      if (message === "expired") {
        setErrorMessage(resources.forgotPasswordPage.passwordResetAlreadyExpired);
        setTimeout(() => setErrorMessage(""), 5000);
      }
    },
  });
  const passwordChanged = !!data;
  useEffect(() => {
    let latestCheck = undefined;
    const comparison: boolean = password !== "";
    if (comparison) latestCheck = checkPasswordStrength(password);
    setPasswordStrength(latestCheck);
    confirmedPassword !== "" ? setSubmit(true) : setSubmit(false);
  }, [password, confirmedPassword]);

  const changePasswordClick = useCallback(() => {
    if (password && !passwordNotMatch) {
      mutate();
    }
  }, [mutate, passwordNotMatch, password]);

  return (
    <>
      {(breakpoint === "large" || breakpoint === "hd") && (
        <LoginLayout>
          {tokenIsLoading ? (
            <Spinner accessibilityLabel={"Loading"} show={true} />
          ) : tokenIsError ? (
            <Box direction="column" gap={2} alignItems="center" display="flex">
              <Text inline={true} overflow="breakWord" align="start" color={colors.neutral900}>
                {resources.thisLinkHasExpired}
              </Text>
              <InvalidTokenSvg width={251} height={228} />
            </Box>
          ) : (
            <Box
              maxWidth={520}
              marginBottom="auto"
              alignSelf="center"
              right
              padding={12}
              rounding="pill"
            >
              <Box paddingY={2}>
                <Heading size="400" color={colors.black600}>
                  {resources.changePassword}
                </Heading>
              </Box>
              <Box paddingY={4}>
                <Text inline={true} overflow="breakWord" align="start" color={colors.neutral900}>
                  {resources.enterNewPassword}
                  <Text inline={true} weight="bold">
                    "{email}".
                  </Text>
                </Text>
              </Box>
              <Divider />
              <Box paddingY={5}>
                <Box marginBottom={4} direction="column">
                  <Box display="flex" gap={3} direction="column">
                    <Box display="flex" direction="row" justifyContent="between" marginBottom={2}>
                      <Text weight="bold">{resources.password}</Text>
                      <Link
                        href=""
                        onClick={(event) => {
                          setShowPassword(!showPassword);
                          event.event.preventDefault();
                        }}
                      >
                        <Text color={colors.primary}>
                          {showPassword ? resources.hide : resources.show}
                        </Text>
                      </Link>
                    </Box>
                    <PasswordSetterField
                      disabled={passwordChanged}
                      id="newPasswordField"
                      value={password}
                      onChange={setPassword}
                      resources={resources}
                      labelDisplay="hidden"
                    />
                  </Box>
                </Box>
                <Box marginBottom={4} direction="column">
                  <Box display="flex" gap={3} direction="column">
                    <Box display="flex" direction="row" justifyContent="between" marginBottom={2}>
                      <Text weight="bold">{resources.adminChangePassword.confirmPassword}</Text>
                      <Link
                        href=""
                        onClick={(event) => {
                          setShowConfirmPassword(!showConfirmPassword);
                          event.event.preventDefault();
                        }}
                      >
                        <Text color={colors.primary}>{showConfirmPassword ? "Hide" : "Show"}</Text>
                      </Link>
                    </Box>
                    <TextField
                      disabled={!passwordStrength?.success || passwordChanged}
                      value={confirmedPassword}
                      type={showConfirmPassword ? "text" : "password"}
                      label={resources.adminChangePassword.confirmPassword}
                      labelDisplay="hidden"
                      onChange={(e) => setConfirmedPassword(e.value)}
                      id="confirmedPasswordField"
                      placeholder={resources.adminChangePassword.confirmPassword}
                      errorMessage={
                        passwordNotMatch ? resources.adminChangePassword.passwordNotMatch : null
                      }
                    ></TextField>
                  </Box>
                </Box>
              </Box>
              {passwordChanged ? (
                <Button
                  size="lg"
                  text={resources.forgotPasswordPage.backToLogin}
                  accessibilityLabel={resources.forgotPasswordPage.backToLogin}
                  fullWidth={true}
                  type="button"
                  color="gray"
                  onClick={() => navigate(organizationLinkTemplates.login())}
                />
              ) : (
                <>
                  <Button
                    type="button"
                    disabled={!submit || isLoading}
                    size="lg"
                    text={resources.adminChangePassword.setNewPassword}
                    accessibilityLabel={resources.adminChangePassword.setNewPassword}
                    fullWidth={true}
                    color={"blue"}
                    onClick={changePasswordClick}
                  />
                  {errorMessage && <Text color={colors.error}>{errorMessage}</Text>}
                </>
              )}
            </Box>
          )}
        </LoginLayout>
      )}
      {(breakpoint === "medium" || breakpoint === "small") && (
        <LoginLayoutSmall>
          {tokenIsError ? (
            <Box direction="column" gap={2} alignItems="center" display="flex">
              <Text inline={true} overflow="breakWord" align="start" color={colors.neutral900}>
                {resources.thisLinkHasExpired}
              </Text>
              <InvalidTokenSvg />
            </Box>
          ) : (
            <PasswordRecoveryMedium
              email={email}
              errorMessage={errorMessage}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              passwordChanged={passwordChanged}
              password={password}
              setPassword={setPassword}
              popoverRef={popoverRef}
              setOpen={setOpen}
              anchorRef={anchorRef}
              passwordStrength={passwordStrength}
              setShowConfirmPassword={setShowConfirmPassword}
              open={open}
              showConfirmPassword={showConfirmPassword}
              confirmedPassword={confirmedPassword}
              setConfirmedPassword={setConfirmedPassword}
              passwordNotMatch={passwordNotMatch}
              submit={submit}
              isLoading={isLoading}
              changePasswordClick={changePasswordClick}
            />
          )}
        </LoginLayoutSmall>
      )}
    </>
  );
};

export default PasswordRecoveryPage;
