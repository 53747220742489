import { Box, Button, Text, TextArea, TextField, useColors } from "@prodoctivity/design-system";
import { FC, useCallback, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { NotificationMessage } from "../../components/NotificationMessage";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { useUserProfile } from "../../hooks/useUserProfile";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";

type props = {
  showDeleteAccount: boolean;
  setShowDeleteAccount: (n: boolean) => void;
  email: string;
};
const DeleteAccountForm: FC<props> = ({ email, setShowDeleteAccount }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { deleteMyAccountPetition, isDeletionAccountProcessInitiated } = useServices();
  const { data } = useUserProfile();
  const [toastMessage, setToastMessage] = useState("");
  const [reason, setReason] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  const executeDeleteAccountPetition = useCallback(async () => {
    if (reason) {
      await deleteMyAccountPetition(reason, inputEmail);
      return true;
    }
    return false;
  }, [deleteMyAccountPetition, inputEmail, reason]);

  const mutation = useMutation(executeDeleteAccountPetition, {
    onSuccess() {
      setToastMessage(resources.deleted);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4500);
      navigate(organizationLinkTemplates.logout());
    },

    onError(error: {
      name: string;
      message: string;
      response: {
        data: {
          errors: Array<{ errorCode: string; message: string }>;
        };
      };
    }) {
      const errorType = error?.response.data.errors[0].message;
      switch (errorType) {
        case "organization-not-found":
          setToastMessage(resources.leaveOrganizationPage.checkOrganizationId);
          break;
        default:
          setToastMessage(resources.anErrorHasOccurred);
      }
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4500);
    },
  });
  const handleSubmit = useCallback(async () => {
    mutation.mutate();
  }, [mutation]);

  const checkDeletionProcess = useCallback(async () => {
    return await isDeletionAccountProcessInitiated();
  }, [isDeletionAccountProcessInitiated]);

  const { data: isInitiatedData } = useOrganizationQuery(
    "check-deletion-process",
    checkDeletionProcess
  );

  const disable =
    !reason || mutation.isLoading || isInitiatedData?.isStarted || inputEmail !== data?.email;

  return (
    <Box padding={4} color={colors.white}>
      <Text align="center" weight="bold" size="400" color={colors.error}>
        {resources.deleteAccount}
      </Text>
      <Text align="center" weight="bold" size={"200"} color={colors.black600}>
        {resources.toDeleteAccount.replace("{email}", email)}
      </Text>
      <Box padding={2} justifyContent="center">
        <TextField
          value={inputEmail}
          maxLength={{
            characterCount: 50,
            errorAccessibilityLabel: resources.maximumCharactersAllowed,
          }}
          type={"text"}
          onChange={(e) => setInputEmail(e.value)}
          id="emailField"
          placeholder={resources.email}
        />
      </Box>

      <Text align="center" weight="bold" size={"200"} color={colors.black600}>
        {resources.whyDoYouWantToDeleteYourAccount}
      </Text>
      <Box padding={2} justifyContent="center">
        <TextArea
          value={reason}
          maxLength={{
            characterCount: 1000,
            errorAccessibilityLabel: resources.maximumCharactersAllowed,
          }}
          onChange={(e) => setReason(e.value)}
          id="delete_organization_reason"
        />
      </Box>

      <Box display="flex" direction="row" flex="grow" gap={4} justifyContent="end">
        <Button
          color={"transparent"}
          type="button"
          text={resources.cancel}
          size="lg"
          accessibilityLabel={resources.cancel}
          onClick={() => setShowDeleteAccount(false)}
        />

        <Button
          type="button"
          disabled={disable}
          size="lg"
          text={resources.deleteAccount}
          accessibilityLabel={resources.deleteOrganization}
          color={"red"}
          onClick={handleSubmit}
        />
      </Box>
      {isInitiatedData?.isStarted && (
        <Text align="center" color={colors.error}>
          {resources.alreadyExists}
        </Text>
      )}
      <Box>
        {showToast ? (
          <NotificationMessage
            type={"error"}
            message={toastMessage}
            position="bottom-left"
            handleDismiss={() => setShowToast(false)}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export { DeleteAccountForm };
