import { Box, Text, TextField, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FunctionComponent, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { TemplateList } from "../../components/TemplateList";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type Props = {
  usedAsWidget?: boolean;
};

function TemplateListPage({ usedAsWidget = false }: Props) {
  const { search: searchParams } = useLocation();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const hasWidth = breakpoint === "large" || breakpoint === "hd";

  const params = useMemo(() => new URLSearchParams(searchParams), [searchParams]);
  const generate = params.get("action") === "generate";
  const filter = params.get("filter") || undefined;
  const [search, setSearch] = useState<string>(filter || "");
  const breadCrumbEntries: BreadCrumbEntry[] | undefined = useMemo(() => {
    if (usedAsWidget) {
      return undefined;
    }

    return [
      { type: "url", name: resources.home, url: "/" },
      { type: "text", name: resources.templates },
    ];
  }, [resources, usedAsWidget]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box>
        <Box paddingX={usedAsWidget ? 4 : 12}>
          {!usedAsWidget && (
            <Box display="flex" direction="row">
              <Box margin={2} />
              <Text size="400" weight="bold">
                {resources.templates}
              </Text>
            </Box>
          )}
          <Box marginBottom={-12} padding={4} display="flex" justifyContent="between" gap={6}>
            <Box width="100%" maxWidth={hasWidth ? 500 : undefined}>
              <TextField
                placeholder={resources.searchForTemplate}
                id="search_templates"
                value={search}
                onChange={({ value }) => setSearch(value)}
              />
            </Box>
            {hasWidth && <Box width={500}></Box>}
            {/* <Box display="flex" alignItems="center" gap="1.8rem">
              <Text>{resources.sortBy}</Text>
              <Text>{resources.category}</Text>
              <Text>{resources.date}</Text>
              <Text>{resources.user}</Text>
            </Box> */}
          </Box>
          {!usedAsWidget && <Box height={16} />}
          <TemplateList
            filterText={search}
            action={generate ? "generate" : undefined}
            hideTitle={true}
          />
        </Box>
      </Box>
    </Page>
  );
}

export const TemplateListDashboardComponent: FunctionComponent = () => {
  return <TemplateListPage usedAsWidget={true} />;
};

export default TemplateListPage;
