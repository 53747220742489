import {
  DataElement$Schema,
  contextFieldToDataElementSync,
  createUuid,
} from "@prodoctivity/shared";
import type {
  ContextField,
  ContextRecord,
  DataElement,
  TemplateContextDefinition,
  TemplatePage,
  TemplateSection,
  TemplateWizardDefinition,
  TemplateWizardField,
  TemplateWizardFieldIsRecord,
  TemplateWizardFieldNotRecord,
} from "@prodoctivity/shared/src/index-types";

import { getResources } from "../../../resources";
import { Item } from "./Steps/DocumentKeyStep";

export const sortByName = (a: Item, b: Item) => {
  return a.label.localeCompare(b.label);
};

export const extractFieldsName = (
  dataElements: DataElement[],
  prevFieldsName: Array<string>,
  records: Array<ContextRecord>
): Array<string> => {
  let fieldsName: Array<string> = prevFieldsName;
  records.forEach((r) => {
    const names = r.fields.filter((f) => !fieldsName.includes(f.name)).map((f) => f.name);
    fieldsName = [...fieldsName, ...names];

    const nestedNames = extractFieldsName(dataElements, fieldsName, r.records);
    fieldsName = [...fieldsName, ...nestedNames];
  });
  return fieldsName;
};

function getSectionName(wizardDefinitionPages: TemplatePage[], fullPath: string): string {
  for (const page of wizardDefinitionPages) {
    for (const section of page.sections) {
      if (visitTemplateWizardField(section.fields, (fld) => fld.key === fullPath)) {
        return section.label;
      }
    }
  }

  return "General";
}

function visitTemplateWizardField(
  fields: TemplateWizardField[],
  pred: (field: TemplateWizardField) => boolean
): boolean {
  for (const field of fields) {
    if (pred(field)) {
      return true;
    }
    if (field.isRecord) {
      if (visitTemplateWizardField(field.fields || [], pred)) {
        return true;
      }
    }
  }

  return false;
}

export function isDataElement(obj: DataElement | ContextRecord | undefined): obj is DataElement {
  const v: DataElement = obj as unknown as DataElement;
  return v && !!v.dataType;
}

export const deepItemFinder = (
  sourceItemList: Item[],
  itemToFind: Item,
  isSubItem?: boolean
): { updatedList: Item[]; parentItem?: Item; isSubItem?: boolean } => {
  for (const i of sourceItemList) {
    if (i.items && i.items.length > 0) {
      const foundInSubItems = deepItemFinder(i.items, itemToFind, true);
      if (foundInSubItems.isSubItem) {
        // Remove the item from i.items
        i.items = i.items.filter((subItem) => subItem.label !== itemToFind.label);
        return {
          updatedList: sourceItemList.filter((itemInListWithNesting) => {
            if (itemInListWithNesting.label === i.label) {
              return i;
            } else {
              return itemInListWithNesting;
            }
          }),
          parentItem: i,
        };
      }
    } else {
      if (i.label === itemToFind.label && isSubItem) {
        // Remove the item from sourceItemList and return its parent
        return {
          updatedList: sourceItemList.filter((item) => item.label !== itemToFind.label),
          isSubItem,
        };
      } else if (i.label === itemToFind.label && !isSubItem) {
        // Remove the item from sourceItemList
        return {
          updatedList: sourceItemList.filter((item) => item.label !== itemToFind.label),
        };
      }
    }
  }

  return { updatedList: sourceItemList };
};

export const contextToRightItems = (
  contextDefinition: TemplateContextDefinition,
  wizardDefinition: TemplateWizardDefinition | undefined
): Item[] => {
  const items: Array<Item> = [];

  const fieldToItem = (currentItems: Array<Item>, field: ContextField, recordName?: string) => {
    const dataElementInfo = contextFieldToDataElementSync(
      "en-US",
      getSectionName(wizardDefinition?.pages || [], field.fullPath),
      field
    );
    currentItems.push({
      id: field.name,
      label: field.properties.label,
      dataElementInfo: dataElementInfo,
      selected: false,
      fullPath: recordName ? `${recordName}/${field.name}` : field.name,
    });

    return;
  };

  const recordToItem = (record: ContextRecord): Item => {
    const item: Item = {
      id: record.name,
      label: record.properties.label,
      dataElementInfo: record,
      selected: false,
      items: [],
      fullPath: record.fullPath,
    };
    record.fields.forEach((f) => fieldToItem(item.items || [], f, record.fullPath));
    (record.records || []).forEach((r) => {
      const item = recordToItem(r);
      (item.items || []).push(item);
    });

    return item;
  };

  contextDefinition.fields.forEach(async (f) => fieldToItem(items, f));

  contextDefinition.records.forEach((r) => {
    const item = recordToItem(r);
    if ((item.items || []).length > 0) {
      items.push(item);
    }
  });

  return items;
};

export const rightItemsToContext = (
  items: Item[],
  dataElements: DataElement[]
): TemplateContextDefinition => {
  const itemToField = (item: Item): ContextField | undefined => {
    if (item.dataElementInfo) {
      if (isDataElement(item.dataElementInfo)) {
        const dataElementInfoValue = item.dataElementInfo;
        const dataElement = dataElements.find((d) => d.name === item.id);
        const r = (() => {
          const parsedDataElementResponse = DataElement$Schema.safeParse(dataElementInfoValue);
          if (!parsedDataElementResponse.success) {
            return undefined;
          } else {
            return parsedDataElementResponse.data;
          }
        })();
        if (!r) {
          return undefined;
        }
        const {
          name: _name,
          // dictionaryDataType: _dictionaryDataType,
          unique,
          cultureLanguageName: _cultureLanguageName,
          sectionName: _sectionName,
          isReferenceField: _isReferenceField,
          readOnly: _readOnly,
          dictionaryListName: _dictionaryListName,
          alternativeQuestions: _alternativeQuestions,
          input: _input,
          output: _output,
          ...rest
        } = r;

        if (r) {
          return {
            name: r.name,

            humanName: r.humanName,
            fullPath: item.fullPath,
            properties: {
              isUnique: unique,
              ...rest,
            },
          };
        } else if (!dataElement) {
          return undefined;
        } else {
          const {
            name: _DEname,
            // dictionaryDataType: _DEdictionaryDataType,
            unique,
            cultureLanguageName: _DEcultureLanguageName,
            sectionName: _DEsectionName,
            isReferenceField: _DEisReferenceField,
            readOnly: _DEreadOnly,
            dictionaryListName: _DEdictionaryListName,
            alternativeQuestions: _DEalternativeQuestions,
            input: _DEinput,
            output: _DEoutput,
            ...rest
          } = dataElement;
          return {
            name: dataElement.name,
            humanName: dataElement.humanName,
            fullPath: item.fullPath,
            properties: {
              isUnique: unique,
              ...rest,
            },
          };
        }
      }
    }
    return undefined;
  };

  const itemToRecord = (item: Item): ContextRecord | undefined => {
    if (item.items === undefined || item.items.length <= 0) {
      return undefined;
    }

    const fields: ContextField[] = [];
    const records: ContextRecord[] = [];
    item.items.forEach((i) => {
      if (typeof i.items === "undefined") {
        const field = itemToField(i);
        if (field) {
          fields.push(field);
        }
      } else if (i.items.length > 0) {
        const record = itemToRecord(i);
        if (record) {
          records.push(record);
        }
      }
    });

    return {
      name: item.id,
      humanName: item.id,
      fullPath: item.fullPath,
      fields: fields,
      records: records,
      properties: {
        label: item.label,
        humanName: item.id,
        inputType: "Table",
      },
    };
  };

  const fields: ContextField[] = [];
  const records: ContextRecord[] = [];
  items.forEach((i) => {
    if (typeof i.items === "undefined") {
      const field = itemToField(i);
      if (field) {
        fields.push(field);
      }
    } else if (i.items.length > 0) {
      const record = itemToRecord(i);
      if (record) {
        records.push(record);
      }
    }
  });

  return {
    fields: fields,
    records: records,
  };
};

export const updateWizardDefinition = (
  resources: ReturnType<typeof getResources>,
  dataElements: Array<DataElement>,
  contextDefinition: TemplateContextDefinition,
  wizardDefinition?: TemplateWizardDefinition
) => {
  let newWizardDefinition: TemplateWizardDefinition | undefined = wizardDefinition;

  if (!newWizardDefinition) {
    newWizardDefinition = {
      defaultPageName: resources.formDesigner.generalPage,
      defaultSectionName: resources.formDesigner.defaultSection,
      pages: [],
      dependencies: [],
      inferredDependencies: [],
    };
  }

  let currentSections: Array<TemplateSection> = [];
  let currentFields: Array<TemplateWizardField> = [];

  newWizardDefinition?.pages.forEach((p) => {
    currentSections = [...currentSections, ...p.sections];
    p.sections.forEach((s) => {
      //Remove fields and records that not exist in the contextDefinition
      const fields = s.fields.filter((field) => {
        if (field.isRecord !== true) {
          return contextDefinition.fields.findIndex((f) => f.name === field.key) >= 0;
        } else {
          return false;
        }
      });
      currentFields = [...currentFields, ...fields];
      s.fields = fields;
    });
  });

  const defaultSectionIndex = () => {
    let defaultSectionIndex = currentSections.findIndex((s) => s.key === "general_Section");
    if (defaultSectionIndex < 0) {
      const defaultSection: TemplateSection = {
        key: "general_Section",
        label: resources.formDesigner.defaultSection,
        description: resources.formDesigner.defaultSection,
        fields: [],
        properties: {},
      };
      currentSections.push(defaultSection);
      defaultSectionIndex = currentSections.length - 1;
    }
    return defaultSectionIndex;
  };

  const findSectionIndex = (sectionName: string) => {
    if (!sectionName) {
      return defaultSectionIndex();
    }
    let index = currentSections.findIndex((s) => s.label === sectionName);

    if (index < 0) {
      const section: TemplateSection = {
        key: createUuid(),
        label: sectionName,
        description: sectionName,
        fields: [],
        properties: {},
      };
      currentSections.push(section);
      index = currentSections.length - 1;
    }

    return index;
  };

  const recordToWizardField = (record: ContextRecord): TemplateWizardFieldIsRecord => {
    const fields: TemplateWizardFieldNotRecord[] = record.fields.map((f) => {
      return {
        key: f.name,
        label: f.properties.label,
        isRecord: false,
        controlSize: f.properties.controlSize || "Large",
      };
    });
    const subRecords = record.records.map(recordToWizardField);

    return {
      key: record.name,
      label: record.properties.label,
      isRecord: true,
      fields: [...fields, ...subRecords],
    };
  };

  //Add missing fields from contextDefinition
  contextDefinition.fields
    .filter((f) => currentFields.findIndex((cF) => cF.isRecord !== true && cF.key === f.name) < 0)
    .forEach((f) => {
      const dataElement = dataElements.find((d) => d.name === f.name);
      if (dataElement) {
        const sectionIndex = findSectionIndex(dataElement.sectionName);
        currentSections[sectionIndex].fields.push({
          key: f.name,
          label: f.properties.label,
          isRecord: false,
          properties: {
            controlSize: "Large",
          },
        });
      } else {
        const sectionIndex = findSectionIndex(
          getSectionName(newWizardDefinition ? newWizardDefinition.pages : [], f.fullPath)
        );
        currentSections[sectionIndex].fields.push({
          key: f.name,
          label: f.properties.label,
          isRecord: false,
        });
      }
    });

  //Add missing records to wizardDefinition
  contextDefinition.records
    .filter((r) => currentFields.findIndex((cF) => cF.isRecord === true && cF.key === r.name) < 0)
    .forEach((r) => {
      const sectionIndex = defaultSectionIndex();
      const record = recordToWizardField(r);
      currentSections[sectionIndex].fields.push(record);
    });

  return {
    ...newWizardDefinition,
    pages: [
      {
        key: "generalPage",
        label: resources.formDesigner.generalPage,
        description: resources.formDesigner.generalPage,
        sections: currentSections,
        properties: {},
      },
    ],
  };
};
