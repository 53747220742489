import {
  BoxWithRef,
  DatePickerProps,
  useComponentBreakpoint,
  type DataElementInputProps,
} from "@prodoctivity/design-system";
import type { HttpExecuteDataLinkRequest, HttpExecuteDataLinkResponse } from "@prodoctivity/types";
import { FunctionComponent, useRef } from "react";
import { FormConfiguration, FormConnector, FormDefinition, FormValues } from "../../_lib/types";

import type { TemplateVersionContextMapping } from "@prodoctivity/shared/src/index-types";
import type momentType from "moment";
import { ProDoctivityForm } from "../../ProDoctivityForm";
import { noop } from "../../_lib/utils";
import { IFormValues } from "../_types";

export interface IFormDataCaptureProps {
  paginate?: boolean;
  formDefinition: FormDefinition;
  formConfiguration: FormConfiguration;
  connectors: any[];
  connectorDataLinks: any[];
  dataLinks: any[];
  initialValues?: IFormValues;
  summaryMode?: boolean;
  readOnly?: boolean;
  dataLinkMappings: TemplateVersionContextMapping["datalinks"];
  executeDataLink: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  onUpdateValues: (values: IFormValues) => void;
  onFireConnector: (formConnector: FormConnector, formValues: FormValues) => Promise<FormValues>;
  i18n: (key: string) => string;
  navigate?: string;
  onNavigate?: (to: string) => void;
  initialPagesHeight?: number;
  onFormErrorsUpdate: (formHasErrors: boolean) => void;
  onDependencyLayoutUpdate: (layout: any) => void;
  onNoDataFound?: (message: string) => void;
  onSummary?: () => void;
  handlerNextStep?: (callback: (isNext: boolean) => boolean) => void;
  cancel?: () => void;
  cancelLabel?: string;
  finish?: () => void;
  finishButtonLabel?: string;
  back?: () => void;
  backButtonLabel?: string;
  moment: typeof momentType;
  purpose?: DataElementInputProps["purpose"];
  isLoading?: boolean;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
}

export const FormDataCapture: FunctionComponent<IFormDataCaptureProps> = (
  props: IFormDataCaptureProps
) => {
  const {
    moment,
    paginate = true,
    handlerNextStep,
    cancel,
    cancelLabel,
    finish,
    finishButtonLabel,
    back,
    backButtonLabel,
    isLoading,
    resources,
    purpose,
  } = props;
  const { formValues, groupValues } = props.initialValues || {
    formValues: undefined,
    groupValues: undefined,
  };
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { componentBreakpoint } = useComponentBreakpoint(containerRef, "large");

  return (
    <BoxWithRef ref={containerRef} display="flex" flex="grow" direction="column">
      <ProDoctivityForm
        initialFormValues={formValues}
        initialGroupValues={groupValues}
        initialPagesHeight={props.initialPagesHeight || 0}
        formDefinition={props.formDefinition}
        formLayout={props.formConfiguration.formLayout}
        connectors={props.connectors}
        connectorDataLinks={props.connectorDataLinks}
        onNoDataFound={props.onNoDataFound}
        dataLinks={props.dataLinks}
        formConnectors={props.formConfiguration.formConnectors}
        onUpdateValues={props.onUpdateValues}
        onFireConnector={props.onFireConnector}
        dataLinkMappings={props.dataLinkMappings}
        executeDataLink={props.executeDataLink}
        i18n={props.i18n}
        summaryMode={props.summaryMode}
        readOnly={props.readOnly}
        navigate={props.navigate || ""}
        onNavigate={props.onNavigate || noop}
        onFormErrorsUpdate={props.onFormErrorsUpdate}
        onDependencyLayoutUpdate={props.onDependencyLayoutUpdate}
        showPins={false}
        paginate={paginate}
        onConnectorFail={console.log}
        sequenceBehaviour={"Lock"}
        componentBreakpoint={componentBreakpoint}
        moment={moment}
        handlerNextStep={handlerNextStep}
        cancelLabel={cancelLabel}
        cancel={cancel}
        finish={finish}
        finishButtonLabel={finishButtonLabel}
        back={back}
        backButtonLabel={backButtonLabel}
        isLoading={isLoading}
        resources={resources}
        purpose={purpose}
      />
    </BoxWithRef>
  );
};
