import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

const getRotateAngle = (direction: "right" | "left" | "down" | "up") => {
  switch (direction) {
    case "left":
      return "270deg";
    case "down":
      return "180deg";
    case "right":
      return "90deg";
    case "up":
    default:
      return "0deg";
  }
};

type Props = {
  color?: ColorConfig;
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
  direction?: "right" | "left" | "down" | "up";
};

export const ArrowSvg: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 15,
  height = 14,
  direction = "up",
  onClick,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={15}
      height={14}
      viewBox={`0 0 15 14`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{
        transform: `rotate(${getRotateAngle(direction)})`,
        cursor: onClick ? "pointer" : undefined,
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <path
        d="M7 13L7 1M7 1L1 6.25M7 1L13 6.25"
        stroke={color ? calculateColor(color) : "#111111"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
