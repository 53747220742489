import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const LandingPageS7Icon3: FunctionComponent<Props> = ({
  customClassName,
  width = 46,
  height = 46,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 46 46`}
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <ellipse
        cx="20"
        cy="19.5"
        rx="20"
        ry="19.5"
        transform="translate(3.258 3.738)"
        fill="rgba(233,112,37,0.3)"
        opacity="0.5"
      />
      <path
        d="M23.159,0A23.159,23.159,0,1,1,0,23.159,23.159,23.159,0,0,1,23.159,0Z"
        fill="rgba(30,57,78,0.15)"
        opacity="0.3"
      />
      <circle
        cx="17"
        cy="17"
        r="17"
        transform="translate(6.258 6.738)"
        fill="rgba(233,112,37,0.67)"
      />
      <path
        d="M13.26,3A5.755,5.755,0,0,0,7.5,8.76c0,4.32,5.76,10.7,5.76,10.7s5.76-6.377,5.76-10.7A5.755,5.755,0,0,0,13.26,3Zm0,7.817A2.057,2.057,0,1,1,15.317,8.76,2.058,2.058,0,0,1,13.26,10.817Z"
        transform="translate(9.9 12.439)"
        fill="#fff"
      />
    </svg>
  );
};
