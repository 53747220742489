import {
  Box,
  RadioGroup,
  SelectList,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import {
  DataElementFinalizer,
  DataElementListSeparator,
  DataElementOrderType,
  DataElementPenultimateSeparator,
} from "@prodoctivity/shared";
import { FunctionComponent, useCallback } from "react";

import type { DataElement } from "@prodoctivity/shared/src/index-types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../../../hooks/useOrganizationQuery";
import { useServices } from "../../../../hooks/useServices";

type OutputTabProps = {
  element: DataElement;
  onChange: (dataElement: DataElement) => void;
};

export const OutputTab: FunctionComponent<OutputTabProps> = ({ element, onChange }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { getOutputFormats } = useServices();
  const getOutputFormatList = useCallback(() => {
    const outputFormatList = getOutputFormats();
    return outputFormatList;
  }, [getOutputFormats]);

  const { data: ofData } = useOrganizationQuery(`/output-formats/`, getOutputFormatList);
  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box display="flex" direction="column" color={colors.white} paddingY={4} paddingX={4}>
      {/* Form Start */}
      <Box display="flex" direction={breakpoint === "small" ? "column" : "row"}>
        <Box direction="column" width={"40%"} marginEnd={4}>
          {/* Default Format Start */}
          <Box display="flex" direction="column">
            <Box
              color={colors.neutral200}
              paddingX={4}
              paddingY={3}
              display="flex"
              direction="row"
              justifyContent="between"
            >
              <Box display="flex" alignItems="center" direction="row">
                <Box>
                  <Text weight="bold">{resources.dataDictionary.defaultFormat}</Text>
                </Box>
              </Box>
            </Box>
            <Box margin={4}>
              <Box>
                <RadioGroup
                  legend="Select default output format"
                  legendDisplay="hidden"
                  id="defaultOutputFormat"
                >
                  {(ofData && ofData.outputFormats ? ofData.outputFormats : [])
                    .filter((of) => {
                      return (
                        (of.dataType === element.dataType ||
                          (of.dataType === "Date" && element.dataType === "Date")) &&
                        of.language === element.cultureLanguageName
                      );
                    })
                    .map((of) => {
                      return (
                        <RadioGroup.RadioButton
                          key={`${of.language}-${of.format}`}
                          checked={of.format === element.output?.outputFormat}
                          id={of.format}
                          label={of.description !== "" ? of.description : of.format}
                          name={of.format}
                          onChange={() => {
                            onChange({
                              ...element,
                              output: {
                                ...element.output,
                                outputFormat: of.format,
                              },
                            });
                          }}
                          value={element.output?.outputFormat ?? ""}
                        />
                      );
                    })}
                </RadioGroup>
              </Box>
            </Box>
          </Box>
          {/* Default Format End */}
        </Box>
        {/* Second Column Start */}
        <Box direction="column" width={"60%"}>
          {/* Default Value Start */}
          <Box display="flex" direction="column">
            <Box
              color={colors.neutral200}
              paddingX={4}
              paddingY={3}
              display="flex"
              direction="row"
              justifyContent="between"
            >
              <Box>
                <Text weight="bold">{resources.dataDictionary.separators}</Text>
              </Box>
            </Box>
            <Box margin={4}>
              {element && element.maxOccurs && element.maxOccurs > 1 ? (
                <Box display="flex" justifyContent="between">
                  <Box width={"25%"} paddingX={2}>
                    <SelectList
                      label={resources.dataDictionary.separator}
                      onChange={({ value }) => {
                        const numericVal = Number.parseInt(value, 10);
                        onChange({
                          ...element,
                          output: {
                            ...element.output,
                            outputFormat: element.output?.outputFormat ?? "",
                            dataElementListSeparator: isNaN(numericVal)
                              ? DataElementListSeparator.None
                              : numericVal,
                          },
                        });
                      }}
                      id={"dataElementListSeparator"}
                      options={Object.keys(DataElementListSeparator)
                        .filter(
                          (key) =>
                            typeof DataElementListSeparator[
                              key as keyof typeof DataElementListSeparator
                            ] === "number"
                        )
                        .map((key) => {
                          return {
                            label:
                              resources.dataTypeValues[
                                key.toLowerCase() as keyof typeof resources.dataTypeValues
                              ],
                            value:
                              DataElementListSeparator[
                                key as keyof typeof DataElementListSeparator
                              ].toString(),
                          };
                        })}
                      value={`${
                        element.output?.dataElementListSeparator || DataElementListSeparator.None
                      }`}
                    />
                  </Box>
                  <Box width={"25%"} paddingX={2}>
                    <SelectList
                      label={resources.dataDictionary.penultimate}
                      onChange={({ value }) => {
                        const numericVal = Number.parseInt(value, 10);
                        onChange({
                          ...element,
                          output: {
                            ...element.output,
                            outputFormat: element.output?.outputFormat ?? "",
                            dataElementPenultimateSeparator: isNaN(numericVal)
                              ? DataElementPenultimateSeparator.None
                              : numericVal,
                          },
                        });
                      }}
                      id={"dataElementPenultimateSeparator"}
                      options={Object.keys(DataElementPenultimateSeparator)
                        .filter(
                          (key) =>
                            typeof DataElementPenultimateSeparator[
                              key as keyof typeof DataElementPenultimateSeparator
                            ] === "number"
                        )
                        .map((key) => {
                          return {
                            label:
                              resources.dataTypeValues[
                                key.toLowerCase() as keyof typeof resources.dataTypeValues
                              ],
                            value:
                              DataElementPenultimateSeparator[
                                key as keyof typeof DataElementPenultimateSeparator
                              ].toString(),
                          };
                        })}
                      value={`${
                        element.output?.dataElementPenultimateSeparator ||
                        DataElementPenultimateSeparator.None
                      }`}
                    />
                  </Box>
                  <Box width={"25%"} paddingX={2}>
                    <SelectList
                      label={resources.dataDictionary.finalizer}
                      onChange={({ value }) => {
                        const numericVal = Number.parseInt(value, 10);
                        onChange({
                          ...element,
                          output: {
                            ...element.output,
                            outputFormat: element.output?.outputFormat ?? "",
                            dataElementFinalizer: isNaN(numericVal)
                              ? DataElementFinalizer.None
                              : numericVal,
                          },
                        });
                      }}
                      id={"dataElementFinalizer"}
                      options={Object.keys(DataElementFinalizer)
                        .filter(
                          (key) =>
                            typeof DataElementFinalizer[
                              key as keyof typeof DataElementFinalizer
                            ] === "number"
                        )
                        .map((key) => {
                          return {
                            label:
                              resources.dataTypeValues[
                                key.toLowerCase() as keyof typeof resources.dataTypeValues
                              ],
                            value:
                              DataElementFinalizer[
                                key as keyof typeof DataElementFinalizer
                              ].toString(),
                          };
                        })}
                      value={`${element.output?.dataElementFinalizer || DataElementFinalizer.None}`}
                    />
                  </Box>
                  <Box width={"25%"} paddingX={2}>
                    <SelectList
                      label={resources.dataDictionary.orderType}
                      onChange={({ value }) => {
                        const numericVal = Number.parseInt(value, 10);
                        onChange({
                          ...element,
                          output: {
                            ...element.output,
                            outputFormat: element.output?.outputFormat ?? "",
                            dataElementOrderType: isNaN(numericVal)
                              ? DataElementOrderType.None
                              : numericVal,
                          },
                        });
                      }}
                      id={"dataElementOrderType"}
                      options={Object.keys(DataElementOrderType)
                        .filter(
                          (key) =>
                            typeof DataElementOrderType[
                              key as keyof typeof DataElementOrderType
                            ] === "number"
                        )
                        .map((key) => {
                          return {
                            label:
                              resources.dataTypeValues[
                                key.toLowerCase() as keyof typeof resources.dataTypeValues
                              ],
                            value:
                              DataElementOrderType[
                                key as keyof typeof DataElementOrderType
                              ].toString(),
                          };
                        })}
                      value={`${element.output?.dataElementOrderType || DataElementOrderType.None}`}
                    />
                  </Box>
                </Box>
              ) : (
                <Text>{resources.dataDictionary.noSeparators}</Text>
              )}
            </Box>
          </Box>
          {/* Default Value End */}
        </Box>
        {/* Second Column End */}
      </Box>

      {/* Form End */}
    </Box>
  );
};
