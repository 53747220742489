import {
  Box,
  BoxWithAnimations,
  BoxWithRef,
  Divider,
  Spinner,
  Table,
  Text,
  VerticalEllipseMenuSvg,
  useColors,
} from "@prodoctivity/design-system";
import { useCallback, useRef } from "react";

import type { IndexEntityResume } from "@prodoctivity/types";
import NoThumbnail2 from "../../assets/NoThumbnail2.jpeg";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { EllipseMenuSvg } from "../../svg/EllipseMenuSvg";
import { MenuSvg } from "../../svg/MenuSvg";
import { NoResultsFoundIllustration } from "../../svg/NoResultsFoundIllustration";
import { DocumentBookmarkTags } from "../DocumentBookmarkTags/DocumentBookmarkTags";
import { DocumentViewerSearchFilterState } from "../DocumentViewer/hooks";
import { OrganizationNavLink } from "../OrganizationLink";

import {
  CascadeOptionsPopover,
  CascadeOptionsPopoverItem,
} from "../CascadeOptionsPopover/CascadeOptionsPopover";
import { PublicProfile } from "../Profile/Public/PublicProfile";
import { ParameterData } from "./SearchResultsList";

const templateAnimationSet = {
  initial: {
    display: "flex",
    opacity: 0,
    scale: 0.5,
    translate: "0 100px",
    width: "100%",
  },
  whileInView: { opacity: 1, scale: 1, translate: "0 0px" },
  transition: { duration: 0.7, animationFillMode: "both" },
  viewport: { once: true },
};

export function SearchResultEntities({
  resultFiltered,
  searchFilter,
  displayDocumentsAsCards,
  breakpoint,
  itemIndex,
}: ParameterData) {
  const { colors } = useColors();
  const organizationNavigate = useOrganizationNavigate();
  const getDocumentViewerState = useCallback(
    (index: number): DocumentViewerSearchFilterState => {
      return {
        type: "document-viewer-state",
        searchFilter,
        index,
      };
    },
    [searchFilter]
  );

  const isMobile = breakpoint === "small";
  const tabletBreakpoint = breakpoint === "medium";

  const { resources, moment } = useAppTranslation();
  const { baseUrl, token } = useServices();
  const anchorRefItems = useRef<Array<HTMLDivElement | null>>([]);
  const cardWidth = useRef<HTMLDivElement | null>(null);
  const cardWidthValue = cardWidth && cardWidth.current ? cardWidth.current.offsetWidth : 230;
  const itemNameEllipsis = cardWidthValue > 400 ? 100 : 70;
  const documentTypeNameEllipsis = cardWidthValue < 380 ? 35 : 50;

  const handleMoveTo = useCallback(
    (documentId: string) => {
      return organizationNavigate(`/documents/${documentId}`);
    },
    [organizationNavigate]
  );

  const createCascadeOptionsPopoverList = useCallback(
    (): CascadeOptionsPopoverItem[] => [
      {
        childIcon: <MenuSvg width={40} />,
        childLabel: resources.view,
        childHoverLabel: resources.view,
        treeItem: false,
        treeContent: undefined,
        key: "view",
      },
    ],
    [resources]
  );

  const handleCascadeOptionsPopoverClick = useCallback(
    (key: string, item: IndexEntityResume) => {
      if (key === "view") {
        handleMoveTo(item.$documentId);
      }
    },
    [handleMoveTo]
  );

  if (!resultFiltered) {
    return <Spinner show={true} accessibilityLabel={resources.loading} />;
  }

  if (resultFiltered.length <= 0) {
    return (
      <Box paddingY={10} display="flex" justifyContent="center">
        <Box
          width={"100%"}
          maxWidth={513}
          display="flex"
          justifyContent="center"
          direction="column"
          gap={6}
        >
          <NoResultsFoundIllustration width={"100%"} height={"auto"} />
          <Box display="flex" direction="column" gap={3}>
            <Text size="400" weight="bold" align="center">
              {resources.noMatchingResults.noMatchingResultsTitle}
            </Text>
            <Text size="200" weight="normal" align="center" color={colors.neutral800}>
              {resources.noMatchingResults.noMatchingResultsDescription}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {resultFiltered?.map((item, idx) => {
        const createdAt = item.$createdAt;
        const documentId = item.$documentId;
        const documentDescription = item.$documentTypeName;
        const dateFormat = moment(createdAt).format("MM/DD/YYYY HH:mm");
        const obj = {
          link: "/",
          email: "",
        };

        obj.link = organizationLinkTemplates.documentId(item.$documentId, "data");

        if (displayDocumentsAsCards || isMobile) {
          return (
            <BoxWithAnimations animationSet={templateAnimationSet} key={idx}>
              <BoxWithRef
                ref={cardWidth}
                display="flex"
                direction="column"
                alignItems="center"
                color={colors.white}
                position="relative"
                borderRadius={12}
                borderColor={colors.neutral600}
                overflow="hidden"
                maxWidth={itemIndex === 0 ? 500 : undefined}
                width={"100%"}
                key={idx}
              >
                <OrganizationNavLink to={obj.link} state={getDocumentViewerState(idx)}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="start"
                    position="relative"
                    width={"100%"}
                    minWidth={230}
                    height={itemIndex === 0 ? 230 : itemIndex === 1 ? 320 : 520}
                  >
                    <Box
                      position="absolute"
                      paddingX={3}
                      width={itemIndex === 0 ? "120%" : itemIndex === 1 ? "210%" : "280%"}
                      height={itemIndex === 0 ? 230 : itemIndex === 1 ? 320 : 520}
                      overflow="hidden"
                    >
                      <Box width={"100%"} height={"100%"} overflow="hidden">
                        <img
                          id={`thumbnail-image-for-${documentId}`}
                          key={documentId}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                          src={`${baseUrl}/app${organizationLinkTemplates.documentThumbnail(
                            documentId,
                            token || ""
                          )}`}
                          onError={() => {
                            const imageElement = document.getElementById(
                              `thumbnail-image-for-${documentId}`
                            );
                            if (imageElement && imageElement instanceof HTMLImageElement) {
                              imageElement.src = NoThumbnail2;
                            }
                          }}
                          alt={documentDescription}
                        />
                      </Box>
                    </Box>
                  </Box>
                </OrganizationNavLink>

                <Divider size={1} direction="horizontal" color={colors.neutral600}></Divider>
                <Box
                  width={"100%"}
                  display="flex"
                  position="relative"
                  alignItems="center"
                  color={colors.neutral200}
                  height={"100%"}
                  maxHeight={170}
                >
                  <Box
                    width={"100%"}
                    display="flex"
                    direction="column"
                    paddingY={3}
                    paddingLeft={3}
                    paddingRight={1}
                    position="relative"
                    height={"100%"}
                  >
                    <OrganizationNavLink to={obj.link} state={getDocumentViewerState(idx)}>
                      <Box display="flex" direction="column" justifyContent="between">
                        <Box
                          display="flex"
                          direction="column"
                          marginBottom={7}
                          maxHeight={76}
                          overflow="hidden"
                        >
                          <Text weight="bold" size="300" ellipsisLength={documentTypeNameEllipsis}>
                            {item.$documentTypeName}
                          </Text>
                          <Box height={50} overflow="hidden">
                            <Text ellipsisLength={itemNameEllipsis} size="300">
                              {item.$name}
                            </Text>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          direction="column"
                          width={"100%"}
                          justifyContent="start"
                          alignItems="start"
                        >
                          {itemIndex === 0 ? (
                            <>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Box width={50}>
                                  <Text ellipsisLength={25} size="100" color={colors.neutral800}>
                                    {resources.browseDocument.author}
                                  </Text>
                                </Box>
                                <PublicProfile
                                  username={item.$updatedBy}
                                  size="100"
                                  sizeImage={32}
                                  color={colors.neutral700}
                                />
                              </Box>
                              <Box display="flex" gap={2} marginTop={1}>
                                <Box width={50}>
                                  <Text ellipsisLength={25} size="100" color={colors.neutral800}>
                                    {resources.dataTypeValues.date}
                                  </Text>
                                </Box>

                                <Text ellipsisLength={25} size="100" color={colors.neutral700}>
                                  {dateFormat}
                                </Text>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box
                                display="flex"
                                alignItems="end"
                                width={"100%"}
                                justifyContent="between"
                                gap={2}
                              >
                                <Box display="flex" alignItems="end">
                                  <Box width={50}>
                                    <Text ellipsisLength={25} size="100" color={colors.neutral800}>
                                      {resources.browseDocument.author}
                                    </Text>
                                  </Box>
                                  <PublicProfile
                                    username={item.$updatedBy}
                                    size="100"
                                    sizeImage={32}
                                    color={colors.neutral700}
                                  />
                                </Box>
                                <Text ellipsisLength={25} size="100" color={colors.neutral700}>
                                  {dateFormat}
                                </Text>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                    </OrganizationNavLink>
                  </Box>
                  <BoxWithRef
                    ref={(ref) => (anchorRefItems.current[idx] = ref)}
                    display="flex"
                    direction="column"
                  >
                    <Box display="flex" justifyContent="center">
                      <DocumentBookmarkTags
                        documentId={documentId}
                        viewMode={{
                          type: "countListPopoverMenu",
                        }}
                      />
                    </Box>
                    <CascadeOptionsPopover
                      icon={<VerticalEllipseMenuSvg />}
                      label={undefined}
                      hoverLabel={undefined}
                      items={createCascadeOptionsPopoverList()}
                      onClick={(key) => handleCascadeOptionsPopoverClick(key, item)}
                      searchFilter={false}
                      childPopoverActionOnClose={undefined}
                    />
                  </BoxWithRef>
                </Box>

                <Box marginEnd={5}></Box>
              </BoxWithRef>
            </BoxWithAnimations>
          );
        }

        return (
          <Table.Row key={idx} hoverStyle="gray">
            {/* <Table.Cell>
              <OrganizationNavLink to={obj.link} state={getDocumentViewerState(idx)}>
                <Box display="flex" gap={2}>
                  <Box justifyContent="center" alignItems="center" marginStart={4} marginEnd={2}>
                    {iconDocument(item.$entityType)}
                  </Box>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Text size="300" color={colors.black600} ellipsisLength={30}>
                      {" "}
                      {item.$entityType === "action-log"
                        ? resources.activities.activities
                        : resources.document}
                    </Text>
                  </Box>
                </Box>
              </OrganizationNavLink>
            </Table.Cell> */}
            <Table.Cell>
              <OrganizationNavLink to={obj.link} state={getDocumentViewerState(idx)}>
                <Text ellipsisLength={30}>{item.$documentTypeName}</Text>
              </OrganizationNavLink>
            </Table.Cell>
            <Table.Cell>
              <Box display="flex" alignItems="center" gap={2}>
                {
                  <OrganizationNavLink to={obj.link} state={getDocumentViewerState(idx)}>
                    <Text wrap={true}>{item.$name}</Text>
                  </OrganizationNavLink>
                }
              </Box>
            </Table.Cell>

            {!tabletBreakpoint && (
              <Table.Cell>
                <PublicProfile username={item.$updatedBy} size="300" sizeImage={32} />
              </Table.Cell>
            )}
            <Table.Cell>
              <OrganizationNavLink to={obj.link} state={getDocumentViewerState(idx)}>
                <Text ellipsisLength={30}>{dateFormat}</Text>
              </OrganizationNavLink>
            </Table.Cell>
            <Table.Cell>
              <BoxWithRef ref={(ref) => (anchorRefItems.current[idx] = ref)}>
                <CascadeOptionsPopover
                  icon={<EllipseMenuSvg />}
                  label={undefined}
                  hoverLabel={undefined}
                  items={createCascadeOptionsPopoverList()}
                  onClick={(key) => handleCascadeOptionsPopoverClick(key, item)}
                  searchFilter={false}
                  childPopoverActionOnClose={undefined}
                />
              </BoxWithRef>
            </Table.Cell>
            <Table.Cell>
              <BoxWithRef ref={(ref) => (anchorRefItems.current[idx] = ref)}>
                <DocumentBookmarkTags
                  documentId={documentId}
                  viewMode={{
                    type: "countListPopoverMenu",
                  }}
                />
              </BoxWithRef>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
}
