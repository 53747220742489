import type { HttpListUsersRequest, HttpSearchRequest, IndexEntity } from "@prodoctivity/types";
import QueryString, { parse } from "qs";
import { useCallback, useMemo, useState } from "react";
import { ZodTypeDef, z } from "zod";
import {
  DocumentSearchCriteriaField,
  SearchFilter,
  SearchSortDirection,
  SearchSortField,
  advancedFieldsForFilter,
  organizationLinkTemplates,
} from "../../link-templates";

import { useDesignBreakpoint } from "@prodoctivity/design-system";
import { stableStringify } from "@prodoctivity/shared";
import { useLocation } from "react-router-dom";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { searchFieldOperatorToLogicalOperatorType } from "../DocumentViewer/hooks";

export type checkListState = {
  label: string;
  id: IndexEntity["$entityType"]; //"all" | "task" | "reminders" | "action-log" | "collection" | "annotation" | "document";
  checked: boolean;
};

export type CheckListMsg = {
  id: checkListState["id"];
  type: "add-check" | "remove-check" | "remove-check-all";
  checked: boolean;
};

const documentListPageLengthOptions: Array<HttpSearchRequest["queryParameters"]["rowsPerPage"]> = [
  "15",
  "50",
  "100",
];

const DocumentSearchCriteriaField$Schema: z.Schema<
  DocumentSearchCriteriaField,
  ZodTypeDef,
  unknown
> = z.object({
  fld: z.string().min(1).catch("field"),
  op: z
    .enum(["eq", "contains", "starts-with", "ends-with", "lt", "lte", "gt", "gte", "neq"])
    .optional()
    .default("eq")
    .catch("eq"),
  val: z.string().or(z.number()).or(z.boolean()),
  dataType: z
    .enum(["Alphanumeric", "Numeric", "Currency", "Logical", "Date", "DateTime", "Time"])
    .optional()
    .default("Alphanumeric")
    .catch("Alphanumeric"),
});

export function useResultOfFilteredFields(params: URLSearchParams) {
  const rowsPerPageUSer: HttpListUsersRequest["queryParameters"]["rowsPerPage"] = "10";
  const [open, setOpen] = useState(false);
  const { search: getExecuteSearchQuery } = useServices();
  const [createdBy, setCreatedBy] = useState<string>();
  const [newState, setNewState] = useState<string>("default");
  const { breakpoint } = useDesignBreakpoint();

  const isValidSortDirection = (
    value: string | QueryString.ParsedQs | string[] | QueryString.ParsedQs[] | undefined
  ): value is SearchSortDirection => {
    if (typeof value === "string") {
      return ["asc", "desc"].includes(value);
    }
    return false;
  };

  //Most Recent
  const onSortClick = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  //parameters
  const { search } = useLocation();
  const queryParameters: SearchFilter = useMemo(() => {
    const queryParams = parse(search.substring(1));
    const sortDirection = isValidSortDirection(queryParams.sortDirection)
      ? queryParams.sortDirection
      : undefined;
    const sortField = formatSortField(queryParams.sortField);
    const amountOfFields = params.get("searchCriteria");
    const searchCriteria = amountOfFields !== null ? amountOfFields : "0";
    const amountOfDocTypes = params.get("docTypesSelected");
    const docTypesSelected = amountOfDocTypes !== null ? amountOfDocTypes : "0";

    // const entityTypeArray = Array.isArray(queryParams.entityType)
    //   ? queryParams.entityType
    //   : queryParams.entityType
    //   ? [queryParams.entityType]
    //   : [];
    const rawFields: Array<QueryString.ParsedQs | string> = Array.isArray(queryParams.fields)
      ? queryParams.fields
      : [];
    const result: SearchFilter = {
      q: typeof queryParams.q === "string" ? queryParams.q : "",
      documentTypeIdList:
        typeof queryParams.documentTypeIdList === "string"
          ? [queryParams.documentTypeIdList]
          : Array.isArray(queryParams.documentTypeIdList)
          ? queryParams.documentTypeIdList
              .map((dtid) => (typeof dtid === "string" ? dtid : ""))
              .filter(Boolean)
          : [],
      filterType: "document",
      fields: rawFields.reduce((acc: DocumentSearchCriteriaField[], next) => {
        if (typeof next === "string") {
          return acc;
        }

        const parseResult = DocumentSearchCriteriaField$Schema.safeParse(next);
        if (parseResult.success) {
          acc.push(parseResult.data);
        }

        return acc;
      }, []),
      fromDate:
        typeof queryParams.fromDate === "string"
          ? new Date(queryParams.fromDate).toISOString()
          : undefined,
      toDate:
        typeof queryParams.toDate === "string"
          ? new Date(queryParams.toDate).toISOString()
          : undefined,
      pageNumber:
        typeof queryParams.pageNumber === "string" ? parseInt(queryParams.pageNumber, 10) : 0,
      rowsPerPage:
        queryParams.rowsPerPage === "15" ||
        queryParams.rowsPerPage === "50" ||
        queryParams.rowsPerPage === "100"
          ? queryParams.rowsPerPage
          : "15",
      sortDirection: sortDirection,
      sortField: sortField,
      searchCriteria: searchCriteria,
      docTypesSelected: docTypesSelected,
      includeApproximateResults: queryParams.approximate === "true",
    };

    return result;
  }, [params, search]);

  const sortedColumn: string | undefined = useMemo(() => {
    if (queryParameters.sortField) {
      switch (queryParameters.sortField) {
        case "documentTypeId":
          return "Type";
        case "documentTypeName":
          return "Name";
        case "name":
          return "Reference";
        case "updatedAt":
          return "Date";
        case "createdBy":
          return "Created by";
        default:
          return undefined;
      }
    }
    return undefined;
  }, [queryParameters.sortField]);

  const listDocumentPage = useCallback(() => {
    return getExecuteSearchQuery(
      queryParameters.fields.map((fld) => {
        return {
          fld: fld.fld,
          op: searchFieldOperatorToLogicalOperatorType(fld.op),
          val: fld.val,
          dataType: fld.dataType,
        };
      }),
      queryParameters.pageNumber,
      queryParameters.rowsPerPage,
      queryParameters.q,
      queryParameters.documentTypeIdList,
      queryParameters.sortDirection,
      queryParameters.sortField,
      queryParameters.includeApproximateResults
    );
  }, [queryParameters, getExecuteSearchQuery]);

  const objFieldCondition = {
    value: "",
    equalityOperator: "",
  };
  if (queryParameters.fields && Array.isArray(queryParameters.fields)) {
    queryParameters.fields.forEach((i) => {
      objFieldCondition.equalityOperator = searchFieldOperatorToLogicalOperatorType(i.op);
      objFieldCondition.value = i.val.toString();
    });
  }

  const organizationNavigate = useOrganizationNavigate();
  const { data, isLoading, isRefetching, refetch } = useOrganizationQuery(
    `search/page_${stableStringify(queryParameters)}`,
    listDocumentPage,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (data && data.results && data.results.length === 1 && data.pageNumber === 0) {
          const item = data.results[0];

          organizationNavigate(organizationLinkTemplates.documentId(item.$documentId, "data"), {});
        }
      },
    }
  );

  const { listUsers, intersectionContextFields } = useServices();
  const listUsersPage = useCallback(() => {
    return listUsers(queryParameters.pageNumber, rowsPerPageUSer);
  }, [queryParameters.pageNumber, listUsers, rowsPerPageUSer]);

  const { data: organizationUsersData } = useOrganizationQuery(
    `settings/users/page_${queryParameters.pageNumber}_${queryParameters.rowsPerPage}`,
    listUsersPage,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  //filters clean
  const dataToBeFiltered = useMemo(
    () => (data?.results || []).map((items) => items),
    [data?.results]
  );

  const resultFiltered = useMemo(() => {
    const results = dataToBeFiltered;
    // .sort((a, b) => {
    //   const dateA: number = moment(a.$createdAt).valueOf();
    //   const dateB: number = moment(b.$createdAt).valueOf();
    //   return dateA - dateB;
    // });

    switch (newState) {
      case "asc":
        setOpen(false);
        return results; //.sort((a, b) => a.$createdAt - b.$createdAt);

      case "desc":
        setOpen(false);
        return results; //.sort((a, b) => b.$createdAt - a.$createdAt);
      default:
        return results;
    }
  }, [dataToBeFiltered, newState]);

  const cleanInitialDate = () => {
    buildSearchUrl({ newFromDate: null });
  };

  const cleanFinalDate = () => {
    buildSearchUrl({ newToDate: null });
  };

  const buildSearchUrl = useCallback(
    ({
      typeofSearch,
    }: {
      typeofSearch?: { id: checkListState["id"]; checked: boolean };
      newFromDate?: Date | null;
      newToDate?: Date | null;
      createBy?: string | null;
    }) => {
      const queryParams: Array<string> = [];

      if (!queryParameters.q) {
        queryParams.push(`q=${encodeURIComponent(queryParameters.q)}`);
      }
      if (!queryParameters.fromDate) {
        return;
      }

      if (queryParameters.fields && Array.isArray(queryParameters.fields)) {
        const fieldQueryParams = queryParameters.fields
          .filter((f) => f.val !== "" && f.val !== undefined)
          .map((item, index) => {
            if (item.val === undefined) return null;
            else {
              const value =
                item.val !== undefined ? encodeURIComponent(item.val.toString()) : "undefined";
              return `fields[${index}][fieldName]=${encodeURIComponent(
                item.fld
              )}&fields[${index}][value]=${value}&fields[${index}][equalityOperator]=${encodeURIComponent(
                item.op || "eq"
              )}`;
            }
          })
          .join("&");
        queryParams.push(`fields=${encodeURIComponent(fieldQueryParams)}`);
      }

      // const entityTypes = queryParameters.entityType;
      // if (typeofSearch) {
      //   if (typeofSearch.checked && !entityTypes.includes(typeofSearch.id)) {
      //     entityTypes.push(typeofSearch.id);
      //   } else if (!typeofSearch.checked && entityTypes.includes(typeofSearch.id)) {
      //     entityTypes.splice(entityTypes.indexOf(typeofSearch.id), 1);
      //   }
      // }
      // entityTypes.forEach((id) => queryParams.push(`entityType=${encodeURIComponent(id)}`));

      /*if (newFromDate) {
        queryParams.push(`fromDate=${encodeURIComponent(newFromDate.toDateString())}`);
        setStartDate(newFromDate);
      } else if (queryParameters.fromDate && newFromDate !== null) {
        queryParams.push(`fromDate=${encodeURIComponent(queryParameters.fromDate)}`);
      }

      if (newToDate) {
        queryParams.push(`toDate=${encodeURIComponent(newToDate.toDateString())}`);
        setEndDate(newToDate);
      } else if (queryParameters.toDate) {
        queryParams.push(`toDate=${encodeURIComponent(queryParameters.toDate)}`);
      }

      if (createBy) {
        queryParams.push(`createBy=${encodeURIComponent(createBy)}`);
      }*/
      if (!typeofSearch) {
        queryParams.splice(0, 20);
      }
      const queryParamsUrl = queryParams.join("&");
      return organizationNavigate(`/search?${queryParamsUrl}`);
    },
    [organizationNavigate, queryParameters]
  );
  //pagination clean
  const isNextButtonDisabled = useMemo(
    () => !data || data.requestedPageLength !== data.pageLength,
    [data]
  );
  const isPreviousButtonDisabled = useMemo(() => !data || data.pageNumber < 1, [data]);

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled || queryParameters.pageNumber === 0) {
      return;
    }

    organizationNavigate(
      organizationLinkTemplates.search({
        ...queryParameters,
        pageNumber: queryParameters.pageNumber - 1,
      })
    );
  }, [organizationNavigate, queryParameters, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    organizationNavigate(
      organizationLinkTemplates.search({
        ...queryParameters,
        pageNumber: queryParameters.pageNumber + 1,
      })
    );
  }, [isNextButtonDisabled, organizationNavigate, queryParameters]);

  const setPageLength = useCallback(
    (rpp: HttpSearchRequest["queryParameters"]["rowsPerPage"]) => {
      organizationNavigate(
        organizationLinkTemplates.search({ ...queryParameters, pageNumber: 0, rowsPerPage: rpp })
      );
    },
    [organizationNavigate, queryParameters]
  );

  const changeSortWithURL = useCallback(
    (headerColumn: string, sortType: SearchSortDirection | undefined) => {
      const field = mapHeaderToField(headerColumn);

      const sortNavigateAsc = () => {
        organizationNavigate(
          organizationLinkTemplates.search({
            ...queryParameters,
            sortField: field,
            sortDirection: "asc",
          })
        );
      };

      const sortNavigateDesc = () => {
        organizationNavigate(
          organizationLinkTemplates.search({
            ...queryParameters,
            sortField: field,
            sortDirection: "desc",
          })
        );
      };

      const sortNavigateUndefined = () => {
        organizationNavigate(
          organizationLinkTemplates.search({
            ...queryParameters,
            sortField: undefined,
            sortDirection: undefined,
          })
        );
      };

      if (!sortType) {
        if (!sortedColumn || sortedColumn !== headerColumn) {
          sortNavigateAsc();
        } else if (queryParameters.sortDirection === "asc" && sortedColumn === headerColumn) {
          sortNavigateDesc();
        } else if (queryParameters.sortDirection === "desc" && sortedColumn === headerColumn) {
          sortNavigateUndefined();
        }
      } else {
        switch (sortType) {
          case "asc": {
            sortNavigateAsc();
            break;
          }
          case "desc": {
            sortNavigateDesc();
            break;
          }
          default: {
            sortNavigateUndefined();
            break;
          }
        }
      }
    },
    [sortedColumn, queryParameters, organizationNavigate]
  );

  function mapHeaderToField(tableColumnHeader: string | undefined): SearchSortField | undefined {
    const headerFieldMap: { [key: string]: SearchSortField } = {
      Type: "documentTypeId",
      Name: "documentTypeName",
      Date: "updatedAt",
      Reference: "name",
      "Created by": "createdBy",
    };

    return tableColumnHeader ? headerFieldMap[tableColumnHeader] : undefined;
  }

  function formatSortField(
    urlSortField: string | QueryString.ParsedQs | string[] | QueryString.ParsedQs[] | undefined
  ): SearchSortField | undefined {
    if (typeof urlSortField === "string") {
      switch (urlSortField) {
        case "documentTypeId":
        case "documentTypeName":
        case "name":
        case "updatedAt":
        case "createdBy":
          return urlSortField;
        default:
          return undefined;
      }
    }
    return undefined;
  }

  const urlParamsExtracted = advancedFieldsForFilter(params);
  const documentTypesID = urlParamsExtracted.documentTypesSelected.map((items) => items);

  const handleFetchFieldsInfo = useCallback(async () => {
    if (documentTypesID.length > 0) {
      const resp = await intersectionContextFields(documentTypesID);

      const fields = resp.contextDefinition.fields.map((f) => ({
        id: f.name,
        humanName: f.humanName,
      }));
      return fields;
    } else {
      return [];
    }
  }, [documentTypesID, intersectionContextFields]);

  const { data: intersectionContext, isLoading: docTypeListLoading } = useOrganizationQuery(
    `/document-types/fetch-fields-info-for-labels/${documentTypesID.join("-")}`,
    handleFetchFieldsInfo,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const humanFilterName = (fieldName: string) => {
    if (!docTypeListLoading && intersectionContext) {
      const foundMatch = intersectionContext.filter((items) => items.id === fieldName);
      const humanName = foundMatch.length > 0 ? foundMatch[0].humanName : fieldName;
      return humanName;
    }
    return fieldName;
  };

  return {
    changeSort: changeSortWithURL,
    sortedColumn,
    sortDirection: queryParameters.sortDirection,
    setPageLength,
    rowsPerPage: queryParameters.rowsPerPage,
    previousPage,
    nextPage,
    pageLengthOptions: documentListPageLengthOptions,
    currentPage: queryParameters.pageNumber,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    document: data ? data.results : undefined,
    setCreatedBy,
    resultFiltered,
    setNewState,
    onSortClick,
    setOpen,
    open,
    newState,
    organizationUsersData,
    createdBy,
    cleanInitialDate,
    cleanFinalDate,
    buildSearchUrl,
    refetch,
    queryParameters,
    isLoading,
    isRefetching,
    breakpoint,
    humanFilterName,
    urlParamsExtracted,
  };
}
