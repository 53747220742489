import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DeleteDocument: FunctionComponent<Props> = ({
  customClassName,
  width = 21,
  height = 24,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      className={customClassName}
      style={svgStyle}
      width={width}
      height={height}
      viewBox={`0 0 21 24`}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8625 12.4476L16.4548 16.6324C16.4226 17.037 16.0685 17.3565 15.6608 17.3565C15.6286 17.3565 15.6072 17.3458 15.5857 17.3458C15.1351 17.3032 14.8132 16.9199 14.8561 16.4727L15.2531 12.2879C15.296 11.8514 15.7037 11.5319 16.1329 11.5745C16.5728 11.6171 16.9054 12.0004 16.8625 12.4476Z"
        fill="#7F7F7F"
      />
      <path
        d="M13.4508 12.3515L13.4078 16.5575C13.3971 17.0048 13.043 17.3562 12.6031 17.3562H12.5924C12.1418 17.3455 11.7877 16.9835 11.7984 16.5469L11.8414 12.3302C11.8414 11.8936 12.2062 11.5422 12.6461 11.5422H12.6568C13.0967 11.5422 13.4508 11.9043 13.4508 12.3515Z"
        fill="#7F7F7F"
      />
      <path
        d="M13.1283 2.8109C12.9888 2.87479 12.8279 2.90674 12.6669 2.90674C12.0768 2.90674 11.6047 2.41692 11.6155 1.82062V1.12848C11.6369 0.542834 12.1197 0.0849609 12.7206 0.0849609C13.3107 0.0849609 13.7828 0.574778 13.772 1.17108V1.86321C13.7506 2.28914 13.4931 2.65118 13.1283 2.8109Z"
        fill="#7F7F7F"
      />
      <path
        d="M19.5669 6.70807C19.288 6.83585 18.9768 6.89974 18.6549 6.88909L6.42348 6.61224C5.26471 6.59094 4.35272 5.63261 4.37418 4.4826L4.40637 3.24741C4.43855 2.0974 5.39347 1.1923 6.55223 1.2136L18.7837 1.49045C19.3416 1.5011 19.8673 1.73536 20.2536 2.12935C20.6399 2.53398 20.8437 3.05574 20.833 3.60945L20.8008 4.84464C20.7793 5.66455 20.2751 6.36733 19.5669 6.69743V6.70807ZM18.6871 5.29186C18.9554 5.29186 19.1807 5.08955 19.1807 4.82334L19.2129 3.58815C19.2129 3.46037 19.1699 3.33259 19.0734 3.24741C18.9768 3.16222 18.8588 3.09833 18.73 3.09833L6.49859 2.82148C6.23036 2.82148 6.00504 3.03444 6.00504 3.29L5.97285 4.52519C5.97285 4.7914 6.17671 5.01501 6.44494 5.02566L18.6764 5.30251L18.6871 5.29186Z"
        fill="#7F7F7F"
      />
      <path
        d="M20.3598 8.67791C20.2096 8.51818 19.995 8.42235 19.7697 8.42235H5.54259C5.31727 8.42235 5.10268 8.51818 4.95247 8.67791C4.80226 8.84828 4.72716 9.07189 4.74862 9.2955L4.86664 10.6478H6.47604L6.42239 10.0196H18.8899L17.8706 21.903H7.44168L7.38803 21.2747H5.7679L5.89665 22.7655C5.93957 23.1808 6.28291 23.5002 6.70135 23.5002H18.6109C19.0294 23.5002 19.3834 23.1808 19.4156 22.7655L20.5744 9.2955C20.5851 9.07189 20.51 8.84828 20.3598 8.67791Z"
        fill="#7F7F7F"
      />
      <path
        d="M8.67518 11.67H1.92642C1.32558 11.67 0.832031 12.1917 0.832031 12.8306V19.0811C0.832031 19.72 1.32558 20.2418 1.92642 20.2418H8.67518C9.27602 20.2418 9.76957 19.72 9.76957 19.0811V12.8306C9.76957 12.1917 9.27602 11.67 8.67518 11.67ZM7.95631 17.7501C8.22455 18.0269 8.22455 18.4848 7.95631 18.7617C7.82756 18.9001 7.64516 18.964 7.47349 18.964C7.30182 18.964 7.13015 18.9001 6.99067 18.751L6.29326 18.0056L5.30617 16.9621L4.86626 17.42L3.6002 18.7297C3.47145 18.8681 3.29978 18.9427 3.12811 18.9427C2.94571 18.9427 2.77404 18.8681 2.63456 18.7191C2.36633 18.4422 2.36633 17.9843 2.63456 17.7075L4.34053 15.9399L2.65602 14.151C2.38779 13.8741 2.38779 13.4163 2.65602 13.1394C2.92425 12.8626 3.35343 12.8626 3.62166 13.1394L4.5873 14.1723L5.30617 14.9283L5.96066 14.2362L7.01213 13.1607C7.11942 13.0436 7.26963 12.969 7.41985 12.9584C7.6237 12.9371 7.81683 13.001 7.97777 13.1714C8.246 13.4482 8.23527 13.9061 7.97777 14.1829L7.5486 14.6195L6.27181 15.9505L7.95631 17.7394V17.7501Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
