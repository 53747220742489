import { NavigateOptions, useNavigate } from "react-router-dom";

import { useCallback } from "react";
import { useServices } from "./useServices";

export function useOrganizationLink() {
  const { user } = useServices();

  const organizationLink = useCallback(
    (to: string) => {
      if (!user) {
        return "/login";
      }

      return `/${user.organizationId}${to}`;
    },
    [user]
  );

  return organizationLink;
}

export function useOrganizationNavigate() {
  const { user } = useServices();

  const navigate = useNavigate();
  const organizationNavigate = useCallback(
    (to: string, options?: NavigateOptions) => {
      if (!user) {
        return navigate("/login");
      }

      return navigate(`/${user.organizationId}${to}`, options);
    },
    [navigate, user]
  );

  return organizationNavigate;
}
