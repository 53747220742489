import {
  Box,
  Button,
  ChevronSvgIcon,
  ComboBox,
  Divider,
  Skeleton,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { RoleManagementState, SpecialPermission, useSpecialPermissionsTab } from "../hooks";

import { shouldNever } from "@prodoctivity/shared";
import type { GlobalPermissionType } from "@prodoctivity/shared/src/index-types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";

type SpecialPermissionsProps = {
  formState: RoleManagementState;
  isLoading: boolean;
  setFormState: Dispatch<SetStateAction<RoleManagementState>>;
};

export const SpecialPermissionsTab: FunctionComponent<SpecialPermissionsProps> = ({
  formState,
  isLoading,
  setFormState,
}) => {
  const { resources } = useAppTranslation();
  const {
    filteredPermissions,
    selectedPermissions,
    onSelectPermission,
    onRemovePermission,

    filter,
    setFilter,

    expandedRow,
    setExpandedRow,
  } = useSpecialPermissionsTab(resources, formState, setFormState);
  const { colors } = useColors();

  return (
    <Skeleton height={400} show={isLoading}>
      <Box display="flex" direction="column" flex="grow" padding={2} gap={4}>
        <Box display="flex" direction="column" flex="shrink" gap={1}>
          <Box minWidth={170} display="flex">
            <Text>{resources.rolesManagement.listOfPermissions}</Text>
          </Box>
          <Box>
            <ComboBox
              id={"availableUsers"}
              label=""
              disabled={isLoading}
              inputValue={filter || ""}
              placeholder={resources.rolesManagement.selectAPermissionToInclude}
              onClear={() => setFilter(undefined)}
              onChange={(e) => setFilter(e.value)}
              onSelect={(e) => onSelectPermission(e.item.value as GlobalPermissionType)}
              options={filteredPermissions}
            />
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="shrink" gap={1}>
          <Box minWidth={170} display="flex">
            <Text>{resources.rolesManagement.assignedPermissions}</Text>
          </Box>
          <Box width={"100%"}>
            <Box
              borderRadius={6}
              borderColor={colors.neutral600}
              width={"100%"}
              height={"fit-content"}
              minHeight={50}
              alignContent="start"
            >
              {selectedPermissions.map((p) => (
                <SpecialPermissionRow
                  key={p.permissionKey}
                  resources={resources}
                  permission={p}
                  isExpanded={expandedRow === p.permissionKey}
                  onExpandRow={setExpandedRow}
                  onRemove={() => onRemovePermission(p.permissionKey)}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="grow" />
      </Box>
    </Skeleton>
  );
};

type SpecialPermissionRowProps = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  permission: SpecialPermission;
  isExpanded: boolean;
  onExpandRow: (permissionKey?: GlobalPermissionType) => void;
  onRemove: () => void;
};

const SpecialPermissionRow = ({
  resources,
  permission,
  isExpanded,
  onExpandRow,
  onRemove,
}: SpecialPermissionRowProps) => {
  const { colors } = useColors();

  switch (permission.permissionKey) {
    case "organization-admin":
    case "delete-document-collection":
    case "document-collection-task":
    case "delete-document":
    case "publish-template":
      return (
        <Box flex="grow">
          <Box
            flex="grow"
            display="flex"
            justifyContent="between"
            alignItems="center"
            paddingY={1}
            paddingX={3}
            color={isExpanded ? colors.neutral500 : colors.neutral300}
          >
            <TapArea onTap={() => onExpandRow(isExpanded ? undefined : permission.permissionKey)}>
              <Box display="flex" gap={2} alignItems="center">
                <ChevronSvgIcon direction={isExpanded ? "down" : "right"} />
                <Text weight={isExpanded ? "bold" : "normal"} color={colors.black900}>
                  {permission.label}
                </Text>
              </Box>
            </TapArea>
            <Box alignSelf="end">
              <Button color={"transparent"} onClick={onRemove} text={resources.remove} />
            </Box>
          </Box>
          <Divider />
          {isExpanded && (
            <Box>
              <Box color={colors.white} padding={4}>
                <Text color={colors.subtle}>{permission.description}</Text>
              </Box>
              <Divider />
            </Box>
          )}
        </Box>
      );
    default:
      shouldNever(permission.permissionKey);
      return undefined;
  }
};
