import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
};

export const InvalidTokenSvg: FunctionComponent<Props> = ({ width = 321, height = 292 }) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      width="321"
      height="292"
      viewBox="0 0 321 292"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6558_127926)">
        <path
          d="M172.806 56.827C188.393 56.827 201.03 44.2029 201.03 28.6303C201.03 13.0577 188.393 0.433594 172.806 0.433594C157.218 0.433594 144.582 13.0577 144.582 28.6303C144.582 44.2029 157.218 56.827 172.806 56.827Z"
          fill="url(#paint0_linear_6558_127926)"
        />
        <path
          d="M55.9344 239.155C55.1993 238.907 54.4554 238.668 53.7204 238.411C34.2817 231.802 15.1175 220.548 5.74795 202.287C-1.36334 188.441 -1.91241 171.41 4.27787 157.139C10.1493 143.611 20.1476 137.055 32.0146 129.535C43.1022 122.501 52.8082 113.105 64.0287 105.904C79.128 96.2246 95.9365 89.3148 109.911 78.3263C138.339 55.9866 171.026 33.8415 206.68 25.5515C237.666 18.3497 276.482 23.375 293.317 53.7924C303.661 72.487 304.387 95.2868 299.746 116.14C291.129 154.883 268.972 191.423 245.814 223.123C234.461 238.659 221.203 253.806 203.491 261.344C189.526 267.289 173.771 268.006 158.734 265.891C146.362 264.157 134.796 260.176 122.832 256.867C100.71 250.753 77.7996 246.356 55.9344 239.155Z"
          fill="#D4DFF3"
        />
        <path
          d="M233.628 29.8335L236.701 37.8669L237.454 38.9375L243.077 43.3346L236.975 48.1034L233.637 56.827L230.298 48.1034L224.196 43.3346L230.298 38.5659L233.637 29.8423L233.628 29.8335Z"
          fill="white"
        />
        <path
          d="M29.4374 145.504L32.5104 153.538C32.7672 153.901 33.0063 154.246 33.2632 154.608L38.8867 159.006L32.7849 163.774L29.4463 172.498L26.1076 163.774L20.0059 159.006L26.1076 154.237L29.4463 145.513L29.4374 145.504Z"
          fill="#2951A0"
        />
        <path
          d="M284.771 137.117C283.035 136.109 281.255 135.383 279.369 133.817C280.662 132.349 283.788 127.615 283.788 127.615C283.788 127.615 287.286 128.712 289.571 129.854C288.305 132.384 286.365 134.764 284.771 137.117Z"
          fill="#E97025"
        />
        <path
          d="M60.8842 81.2016C61.0879 82.1748 60.9639 83.1923 60.3795 84.0239C59.423 85.3687 57.5367 85.9615 55.9869 85.3953C54.4371 84.829 53.3656 83.1657 53.4984 81.5289L53.6047 80.1841C54.4106 78.7509 55.9869 77.7157 57.6341 77.928C59.2813 78.1404 60.5477 79.6002 60.8842 81.2016Z"
          fill="#E97025"
        />
        <path
          d="M255.573 159.767C255.573 210.232 214.482 251.284 163.959 251.284C113.436 251.284 72.3624 210.241 72.3535 159.776H88.4713L163.968 158.997V68.2402C214.491 68.2491 255.573 109.292 255.573 159.758V159.767Z"
          fill="#EEEDED"
        />
        <path
          d="M163.967 47.1392H163.958C101.799 47.1392 51.2227 97.6579 51.2227 159.767C51.2315 221.884 101.808 272.394 163.958 272.394C226.109 272.394 276.712 221.876 276.712 159.767C276.712 97.6579 226.145 47.148 163.976 47.1392H163.967ZM163.958 251.284C113.444 251.284 72.3617 210.241 72.3528 159.776C72.3528 109.301 113.444 68.2491 163.958 68.2491H163.967C214.49 68.2579 255.573 109.301 255.573 159.767C255.573 210.232 214.481 251.284 163.958 251.284Z"
          fill="#2951A0"
          stroke="white"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M163.968 68.249C163.968 68.249 164.446 158.165 163.968 159.006L72.3535 159.784C72.3535 109.31 113.445 68.2579 163.959 68.2579H163.968V68.249Z"
          fill="#D4DFF3"
        />
        <path
          d="M90.9326 204.278L104.323 196.554"
          stroke="#2951A0"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M122.504 235.81L130.235 222.442"
          stroke="#2951A0"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path d="M165.632 247.356V231.917" stroke="#2951A0" strokeWidth="4" strokeMiterlimit="10" />
        <path
          d="M208.752 235.819L201.029 222.442"
          stroke="#2951A0"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M240.323 204.278L226.941 196.554"
          stroke="#2951A0"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path d="M251.88 161.191H236.427" stroke="#2951A0" strokeWidth="4" strokeMiterlimit="10" />
        <path
          d="M240.323 118.113L226.941 125.828"
          stroke="#2951A0"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M208.76 86.5723L201.029 99.9496"
          stroke="#2951A0"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M165.358 74.6104H162.896C161.282 74.6104 159.974 75.9175 159.974 77.53V85.2449C159.974 86.8574 161.282 88.1646 162.896 88.1646H165.358C166.972 88.1646 168.28 86.8574 168.28 85.2449V77.53C168.28 75.9175 166.972 74.6104 165.358 74.6104Z"
          fill="#2951A0"
        />
        <path
          d="M163.968 159.006V105.727"
          stroke="#2951A0"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          d="M163.028 159.776L139.463 110.672"
          stroke="#2951A0"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.7"
          d="M163.028 159.775L118.271 129.854"
          stroke="#2951A0"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.55 159.767L107.998 159.775"
          stroke="#2951A0"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M174.834 159.784C174.834 165.659 170.069 170.419 164.18 170.419C159.548 170.419 155.599 167.455 154.137 163.323C154.129 163.288 154.111 163.253 154.102 163.217C153.73 162.147 153.535 160.997 153.535 159.802C153.535 157.82 154.075 155.98 155.005 154.405C155.377 153.768 155.811 153.175 156.307 152.627C158.255 150.503 161.045 149.159 164.162 149.159H164.18C167.297 149.159 170.096 150.503 172.044 152.627C173.771 154.52 174.816 157.033 174.816 159.793L174.834 159.784Z"
          fill="#2951A0"
          stroke="white"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M89.9404 161.014V158.554C89.9404 156.942 88.632 155.635 87.018 155.635H79.2956C77.6816 155.635 76.3732 156.942 76.3732 158.554V161.014C76.3732 162.626 77.6816 163.934 79.2956 163.934H87.018C88.632 163.934 89.9404 162.626 89.9404 161.014Z"
          fill="#2951A0"
        />
        <path
          d="M315.129 286.984C314.881 287.152 314.641 287.32 314.385 287.461C314.048 287.656 313.72 287.842 313.366 288.01C313.154 288.107 312.95 288.205 312.72 288.302C312.551 288.373 312.383 288.435 312.215 288.496C312.038 288.576 311.861 288.629 311.684 288.691C311.391 288.788 311.099 288.877 310.807 288.948C310.771 288.957 310.727 288.965 310.692 288.974C310.399 289.045 310.098 289.107 309.788 289.16C309.496 289.213 309.204 289.249 308.912 289.275C308.814 289.293 308.717 289.302 308.619 289.302C308.327 289.328 308.035 289.337 307.743 289.355C309.328 288.169 310.63 286.674 311.551 284.958C312.047 284.046 312.454 283.082 312.72 282.073C313.658 278.773 313.348 275.075 311.4 271.695L279.785 216.992L277.42 212.904L248.169 162.288C245.548 157.776 240.996 155.52 236.435 155.52C236.329 155.52 236.231 155.52 236.125 155.529C236.222 155.449 236.329 155.378 236.435 155.316C238.737 153.688 241.465 152.875 244.202 152.875C248.762 152.875 253.323 155.139 255.927 159.652L285.178 210.259L287.542 214.346L319.158 269.05C322.913 275.544 320.593 283.197 315.12 286.975L315.129 286.984Z"
          fill="#2951A0"
        />
        <path
          d="M315.129 286.984C314.881 287.152 314.641 287.32 314.385 287.461C314.048 287.656 313.72 287.842 313.366 288.01C313.154 288.107 312.95 288.205 312.72 288.302C312.551 288.373 312.383 288.435 312.215 288.496C312.038 288.576 311.861 288.629 311.684 288.691C311.391 288.788 311.099 288.877 310.807 288.948C310.771 288.957 310.727 288.965 310.692 288.974C310.399 289.045 310.098 289.107 309.788 289.16C309.496 289.213 309.204 289.249 308.912 289.275C308.814 289.293 308.717 289.302 308.619 289.302C308.327 289.328 308.035 289.337 307.743 289.355C309.328 288.169 310.63 286.674 311.551 284.958C312.047 284.046 312.454 283.082 312.72 282.073C313.658 278.773 313.348 275.075 311.4 271.695L279.785 216.992L277.42 212.904L248.169 162.288C245.548 157.776 240.996 155.52 236.435 155.52C236.329 155.52 236.231 155.52 236.125 155.529C236.222 155.449 236.329 155.378 236.435 155.316C238.737 153.688 241.465 152.875 244.202 152.875C248.762 152.875 253.323 155.139 255.927 159.652L285.178 210.259L287.542 214.346L319.158 269.05C322.913 275.544 320.593 283.197 315.12 286.975L315.129 286.984Z"
          fill="#2951A0"
        />
        <path
          d="M236.444 155.529V292H173.222C169.573 292 166.412 290.62 164.056 288.435C162.524 287.028 161.328 285.285 160.585 283.339C159.176 279.747 159.283 275.517 161.497 271.704L193.112 217.001L224.728 162.288C227.278 157.882 231.697 155.626 236.143 155.529C236.249 155.52 236.347 155.52 236.453 155.52L236.444 155.529Z"
          fill="url(#paint1_linear_6558_127926)"
        />
        <path
          d="M312.72 282.082C312.446 283.091 312.047 284.055 311.551 284.966C310.621 286.674 309.32 288.178 307.743 289.364C305.538 291.009 302.775 292.009 299.675 292.009H236.453V155.538C241.014 155.538 245.575 157.794 248.187 162.306L277.438 212.922L279.803 217.009L311.418 271.713C313.367 275.093 313.677 278.791 312.738 282.091L312.72 282.082Z"
          fill="url(#paint2_linear_6558_127926)"
        />
        <path
          d="M315.128 286.984C314.88 287.152 314.641 287.32 314.384 287.462C314.048 287.656 313.72 287.842 313.366 288.01C313.154 288.107 312.95 288.205 312.72 288.302C312.551 288.373 312.383 288.435 312.215 288.497C312.038 288.576 311.861 288.629 311.683 288.691C311.391 288.789 311.099 288.877 310.807 288.948C310.771 288.957 310.727 288.966 310.692 288.974C310.399 289.045 310.098 289.107 309.788 289.16C309.496 289.213 309.204 289.249 308.912 289.275C308.814 289.293 308.717 289.302 308.619 289.302C308.327 289.328 308.035 289.337 307.743 289.355C305.537 291 302.774 292 299.675 292H173.221C169.573 292 166.411 290.62 164.055 288.435C162.523 287.028 161.328 285.285 160.584 283.339C173.939 257.097 200.453 238.65 231.449 236.686C233.096 236.58 234.761 236.518 236.444 236.518C236.621 236.509 236.807 236.509 236.993 236.509C238.852 236.509 240.694 236.571 242.537 236.686C273.027 238.633 299.17 256.513 312.728 282.082C313.579 283.684 314.384 285.32 315.128 286.984Z"
          fill="url(#paint3_linear_6558_127926)"
        />
        <path
          d="M313.366 288.01C313.154 288.107 312.95 288.204 312.72 288.302C312.551 288.373 312.383 288.434 312.215 288.496C312.038 288.576 311.861 288.629 311.684 288.691C311.391 288.788 311.099 288.877 310.807 288.948C310.771 288.956 310.727 288.965 310.692 288.974C310.399 289.045 310.098 289.107 309.788 289.16C309.496 289.213 309.204 289.248 308.912 289.275C308.814 289.293 308.717 289.302 308.619 289.302C308.327 289.328 308.035 289.337 307.743 289.355C305.538 291 302.775 292 299.675 292H173.222C169.573 292 166.411 290.62 164.056 288.434C177.605 264.246 202.597 247.303 231.715 244.914C232.246 244.861 232.769 244.826 233.3 244.799C234.345 244.728 235.39 244.684 236.444 244.649C237.064 244.64 237.675 244.631 238.295 244.631C238.472 244.622 238.658 244.622 238.844 244.622C239.986 244.622 241.129 244.64 242.271 244.693C242.971 244.719 243.679 244.755 244.379 244.799C272.735 246.604 297.346 262.202 311.551 284.966C312.18 285.966 312.782 286.975 313.366 288.01Z"
          fill="url(#paint4_linear_6558_127926)"
        />
        <path
          d="M228.252 271.483C228.252 266.634 231.954 263.14 236.993 263.14C242.324 263.14 245.734 266.643 245.734 271.483C245.734 276.322 242.324 279.826 236.993 279.826C231.661 279.826 228.252 276.19 228.252 271.483ZM231.794 247.206L230.059 192.971C229.961 190.042 232.317 187.609 235.257 187.609H238.737C241.678 187.609 244.024 190.034 243.936 192.971L242.191 247.206C242.103 250.01 239.8 252.231 236.993 252.231C234.185 252.231 231.883 250.001 231.794 247.206Z"
          fill="white"
        />
        <path
          d="M242.315 80.0339C243.148 79.6889 243.963 79.3615 244.777 79.0342"
          stroke="#E97025"
          strokeMiterlimit="10"
        />
        <path
          d="M249.71 77.1407C268.21 70.3105 280.936 68.8242 284.567 74.1415C293.414 87.1118 244.804 135.622 175.985 182.487C107.166 229.352 44.209 256.832 35.3531 243.861C31.7664 238.606 37.6202 227.503 50.4435 213.037"
          stroke="#E97025"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeDasharray="6 6"
        />
        <path
          d="M52.2148 211.073C52.7993 210.427 53.4015 209.781 54.0126 209.118"
          stroke="#E97025"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6558_127926"
          x1="115.995"
          y1="-42.264"
          x2="380.557"
          y2="288.541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#29508F" />
          <stop offset="0.196246" stopColor="#E97535" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6558_127926"
          x1="92.0398"
          y1="206.649"
          x2="1064.84"
          y2="355.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04" stopColor="#244F92" />
          <stop offset="0.07" stopColor="#29508F" />
          <stop offset="0.12" stopColor="#3A5387" />
          <stop offset="0.17" stopColor="#55587A" />
          <stop offset="0.23" stopColor="#7B5F68" />
          <stop offset="0.29" stopColor="#AC6951" />
          <stop offset="0.36" stopColor="#E97535" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6558_127926"
          x1="31.7754"
          y1="224.061"
          x2="909.289"
          y2="222.953"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04" stopColor="#244F92" />
          <stop offset="0.06" stopColor="#29508F" />
          <stop offset="0.09" stopColor="#395387" />
          <stop offset="0.13" stopColor="#53587B" />
          <stop offset="0.16" stopColor="#775F6A" />
          <stop offset="0.2" stopColor="#A66854" />
          <stop offset="0.24" stopColor="#DE7339" />
          <stop offset="0.25" stopColor="#E97535" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6558_127926"
          x1="160.584"
          y1="264.255"
          x2="315.128"
          y2="264.255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6558_127926"
          x1="195.096"
          y1="219.133"
          x2="230.506"
          y2="275.274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_6558_127926">
          <rect width="321" height="292" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
