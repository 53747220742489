import {
  Box,
  Button,
  Divider,
  Icon,
  SearchField,
  Spinner,
  TapArea,
  Text,
  TextField,
  TextFieldProps,
  useColors,
  useEscapeKey,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useRef } from "react";
import { ProfileDocumentListOption } from "../../../../components/DocumentBookmarkTags/hook";
import { Topic } from "../../../../components/Layout/Topic";

import { CascadeOptionsPopoverItem } from "../../../../components/CascadeOptionsPopover/CascadeOptionsPopover";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../../link-templates";
import { DeleteSvg } from "../../../../svg/DeleteSvg";
import { EditIconsSvg } from "../../../../svg/EditIconsSvg";
import { HardDeleteDocumentSvgIcon } from "../../../../svg/HardDeleteDocumentSvgIcon";
import { ListSvg } from "../../../../svg/ListSvg";
import { PlusSvgIcon } from "../../../../svg/PlusSvgIcon";
import { useAutoFocus } from "../../../../utils";
import { WarningDeleteLabelLayer } from "../WarningDeleteLabelLayer";
import { LabelListCheckBox } from "./LabelListCheckBox/LabelListCheckBox";
import { useProfileDocumentLabelsList } from "./hook";

type DocumentListsProps = {
  profileDocumentLabelsList: ProfileDocumentListOption[];
  selectedLabels: ProfileDocumentListOption[];
  handleSelectedLabels: (item: ProfileDocumentListOption) => void;
  onSelectSingle(item: ProfileDocumentListOption): void;
  isLoadingProfileDocumentLabels: boolean;
  handleUpdateProfileLabels: (
    oldDocumentLabel: string | undefined,
    newDocumentLabel: string
  ) => void;
  handleDeleteLabel: ((label: string) => void) | undefined;
  isPopoverWindows: boolean;
  profileDocumentLists:
    | {
        documentLists: Array<{
          isFavorites: boolean | undefined;
          name: string;
          icon: string | undefined;
          color: string | undefined;
        }>;
      }
    | undefined;
  isOnlyShowingNotSelected: boolean;
  isWidget: boolean;
};

export const ProfileDocumentLabelsList: FunctionComponent<DocumentListsProps> = ({
  profileDocumentLabelsList,
  selectedLabels: selectedLists,
  handleSelectedLabels,
  handleUpdateProfileLabels,
  handleDeleteLabel,
  onSelectSingle,
  isPopoverWindows,
  isLoadingProfileDocumentLabels,
  profileDocumentLists,
  isWidget,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const textFieldRef = useRef<HTMLInputElement>(null);

  const {
    labelIndex,
    handleSetLabelIndex: setLabelIndex,
    editMode,
    handleSetEditMode: setEditMode,
    searchTerm,
    setSearchTerm,
    currentLabel,
    deleteLabelWarning,
    setDeleteLabelWarningState,
    filteredProfileDocumentLabelsList,
    addMode,
    setAddMode,
    newLabel,
    handleSetNewLabel: setNewLabel,
    addList,
    handleDeleteMyProfileLabel,
    handleBlur,
    handleCascadeOptionsPopoverClick,
  } = useProfileDocumentLabelsList({
    profileDocumentLabelsList,
    handleUpdateProfileLabels,
    handleDeleteLabel,
    selectedLabels: selectedLists,
  });

  useAutoFocus(textFieldRef, addMode);

  const CascadeOptionsPopoverList: CascadeOptionsPopoverItem[] = useMemo(() => {
    return [
      {
        childIcon: (
          <Box>
            <Icon
              icon="edit-note"
              accessibilityLabel={resources.edit}
              color={colors.neutral900}
              size={"sm"}
            />
          </Box>
        ),
        childLabel: resources.edit,
        childHoverLabel: resources.edit,
        treeItem: false,
        treeContent: undefined,
        key: "edit",
      },
      {
        childIcon: (
          <Icon
            icon="trash"
            accessibilityLabel={resources.delete_}
            color={colors.neutral900}
            size={"sm"}
          />
        ),
        childLabel: resources.delete_,
        childHoverLabel: resources.delete_,
        treeItem: false,
        treeContent: undefined,
        key: "delete_",
      },
      {
        childIcon: <HardDeleteDocumentSvgIcon width={25} height={25} color={colors.neutral900} />,
        childLabel: resources.clearAll,
        childHoverLabel: resources.clearAll,
        treeItem: false,
        treeContent: undefined,
        key: "deleteAll_",
      },
    ];
  }, [colors.neutral900, resources.delete_, resources.edit, resources.clearAll]);

  const favoritesEntry: ProfileDocumentListOption = useMemo(() => {
    return {
      isFavorites: true,
      label: resources.favorites,
      value: "favorites",
    };
  }, [resources.favorites]);

  const addListKeydown: TextFieldProps["onKeyDown"] = useCallback(
    (args: { value: string; event: { code: string } }) => {
      const {
        value,
        event: { code },
      } = args;
      if (code === "Enter" && value) {
        addList();
      }
    },
    [addList]
  );

  useEscapeKey(() => setAddMode(false));

  if (isLoadingProfileDocumentLabels) {
    return <Spinner show={true} />;
  }

  return (
    <Box display="flex" direction="column" height={"100%"} flex="grow">
      <Box display="flex" direction="row">
        <Box
          display="flex"
          direction="row"
          alignItems="center"
          flex="grow"
          justifyContent="between"
        >
          <Box display="flex" direction="row" gap={2} alignItems="center" paddingX={4}>
            {!isWidget && (
              <>
                <ListSvg width={30} height={25} color={colors.neutral900} />
                <Topic>{resources.myLists}</Topic>
              </>
            )}
          </Box>
          <Box padding={2}>
            <Box display="flex" color={colors.neutral200} borderRadius={6} marginEnd={2}>
              <Button
                size="md"
                iconEnd="add-circle"
                color="white"
                type="button"
                accessibilityLabel={resources.addNewList}
                text={resources.addNewList}
                onClick={() => {
                  setEditMode({ editMode: false, index: 0 });
                  setAddMode(true);
                  setNewLabel("");
                  textFieldRef.current?.focus();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {addMode && (
        <Box display="flex" justifyContent="center" onBlurCapture={handleBlur}>
          <Box flex="grow" display="flex" direction="row" alignItems="center" padding={2} gap={2}>
            <Box flex="grow">
              <TextField
                placeholder={resources.addNewList}
                ref={textFieldRef}
                id={"update-label-textfield"}
                value={newLabel}
                onChange={(event) => setNewLabel(event.value)}
                onKeyDown={addListKeydown}
                errorMessage={
                  profileDocumentLabelsList.some((label) => label.label.trim() === newLabel.trim())
                    ? resources.listAlreadyExists
                    : undefined
                }
              />
            </Box>
            <Box display="flex" direction="row" alignItems="baseline">
              <TapArea role="button" onTap={addList}>
                <Box
                  borderRadius={6}
                  color={
                    profileDocumentLabelsList.some(
                      (label) => label.label.trim() === newLabel.trim() || newLabel.trim() === ""
                    )
                      ? colors.neutral400
                      : colors.neutral100
                  }
                  height={40}
                  width={40}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon icon="check" accessibilityLabel={resources.add} />
                </Box>
              </TapArea>
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" direction="row" gap={2} paddingX={2} paddingY={4}>
        <SearchField
          id={"search-label-textfield"}
          onChange={(event) => {
            setSearchTerm(event.value);
          }}
          accessibilityLabel={resources.search}
          value={searchTerm}
        />
      </Box>
      <Divider />
      <Box>
        <WarningDeleteLabelLayer
          deleteLabelWarning={deleteLabelWarning}
          setDeleteLabelWarningState={setDeleteLabelWarningState}
          handleDeleteMyProfileLabel={handleDeleteMyProfileLabel}
          selectedLabel={currentLabel}
        />
      </Box>
      <Box overflow="auto" marginStart={2}>
        <Box display="flex" direction="column" paddingY={2}>
          <LabelListCheckBox
            icon="favorites"
            editMode={editMode}
            entry={favoritesEntry}
            handleCascadeOptionsPopoverClick={handleCascadeOptionsPopoverClick}
            handleSelectedLists={handleSelectedLabels}
            handleUpdateProfileLists={handleUpdateProfileLabels}
            index={0}
            isPopoverWindows={isPopoverWindows}
            labelIndex={labelIndex}
            newLabel={newLabel}
            CascadeOptionsPopoverList={CascadeOptionsPopoverList}
            profileDocumentLists={profileDocumentLists}
            profileDocumentListOptions={profileDocumentLabelsList}
            selectedLists={selectedLists}
            setEditMode={setEditMode}
            setLabelIndex={setLabelIndex}
            setNewLabel={setNewLabel}
            onItemClick={onSelectSingle}
          />
          {filteredProfileDocumentLabelsList.map((entry, index) => {
            return (
              <LabelListCheckBox
                key={entry.value}
                icon="list"
                editMode={editMode}
                entry={entry}
                handleCascadeOptionsPopoverClick={handleCascadeOptionsPopoverClick}
                handleSelectedLists={handleSelectedLabels}
                handleUpdateProfileLists={handleUpdateProfileLabels}
                index={index}
                isPopoverWindows={isPopoverWindows}
                labelIndex={labelIndex}
                newLabel={newLabel}
                CascadeOptionsPopoverList={CascadeOptionsPopoverList}
                profileDocumentLists={profileDocumentLists}
                profileDocumentListOptions={profileDocumentLabelsList}
                selectedLists={selectedLists}
                setEditMode={setEditMode}
                setLabelIndex={setLabelIndex}
                setNewLabel={setNewLabel}
                onItemClick={onSelectSingle}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

type AddDocumentToListProps = DocumentListsProps & {
  documentId: string;
};

export const AddDocumentToList: FunctionComponent<AddDocumentToListProps> = ({
  profileDocumentLabelsList,
  selectedLabels: selectedLists,
  handleSelectedLabels,
  handleUpdateProfileLabels,
  handleDeleteLabel,
  onSelectSingle,
  isPopoverWindows,
  isLoadingProfileDocumentLabels,
  profileDocumentLists,
  documentId,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const organizationNavigate = useOrganizationNavigate();

  const {
    labelIndex,
    handleSetLabelIndex: setLabelIndex,
    editMode,
    handleSetEditMode: setEditMode,
    currentLabel,
    deleteLabelWarning,
    setDeleteLabelWarningState,
    addMode,
    setAddMode,
    newLabel,
    handleSetNewLabel: setNewLabel,
    handleDeleteMyProfileLabel,
    handleCascadeOptionsPopoverClick,
    handleBlur,
    notSelectedProfileDocumentLabelsList,
    addList,
  } = useProfileDocumentLabelsList({
    profileDocumentLabelsList,
    handleUpdateProfileLabels,
    handleDeleteLabel,
    selectedLabels: selectedLists,
  });

  useAutoFocus(textFieldRef, addMode);

  const CascadeOptionsPopoverList: CascadeOptionsPopoverItem[] = useMemo(() => {
    return [
      {
        childIcon: <EditIconsSvg width={36} height={22} color={colors.neutral900} />,
        childLabel: resources.edit,
        childHoverLabel: resources.edit,
        treeItem: false,
        treeContent: undefined,
        key: "edit",
      },
      {
        childIcon: <DeleteSvg width={36} height={36} color={colors.neutral900} />,
        childLabel: resources.delete_,
        childHoverLabel: resources.delete_,
        treeItem: false,
        treeContent: undefined,
        key: "delete_",
      },
    ];
  }, [colors.neutral900, resources.delete_, resources.edit]);

  const onHandleSelectedLabels = useCallback(
    (item: ProfileDocumentListOption) => {
      handleSelectedLabels(item);
    },
    [handleSelectedLabels]
  );

  const addListKeydown: TextFieldProps["onKeyDown"] = useCallback(
    (args: { value: string; event: { code: string } }) => {
      const {
        value,
        event: { code },
      } = args;
      if (code === "Enter" && value) {
        addList();
      }
    },
    [addList]
  );

  if (isLoadingProfileDocumentLabels) {
    return <Spinner show={true} />;
  }

  return (
    <Box>
      <Box paddingY={2} width={"100%"}>
        <Box
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="between"
          width={"100%"}
          paddingX={4}
        >
          <Box display="flex" direction="column" width={"100%"} gap={2}>
            <TapArea
              onTap={() => {
                setEditMode({ editMode: false, index: 0 });
                setAddMode(true);
                setNewLabel("");
                textFieldRef.current?.focus();
              }}
            >
              <Box
                display="flex"
                direction="row"
                alignItems="center"
                gap={2}
                hoverColor={colors.primaryHover0}
                borderRadius={6}
                borderColor={colors.white}
                height={50}
              >
                <PlusSvgIcon width={30} color={colors.neutral900} />
                <Text size="200">{resources.addNewList}</Text>
              </Box>
            </TapArea>
            {addMode && (
              <Box display="flex" alignItems="center" onBlurCapture={handleBlur}>
                <Box flex="grow" display="flex" direction="row" justifyContent="start" gap={2}>
                  <Box flex="grow">
                    <TextField
                      placeholder={resources.addNewList}
                      id={"update-label-textfield"}
                      ref={textFieldRef}
                      value={newLabel}
                      onChange={(event) => setNewLabel(event.value)}
                      onKeyDown={addListKeydown}
                      errorMessage={
                        profileDocumentLabelsList.some(
                          (label) => label.label.trim() === newLabel.trim()
                        )
                          ? resources.listAlreadyExists
                          : undefined
                      }
                    />
                  </Box>
                  <Box display="flex" direction="row" alignItems="baseline">
                    <TapArea onTap={addList}>
                      <Box
                        borderRadius={6}
                        color={
                          profileDocumentLabelsList.some(
                            (label) =>
                              label.label.trim() === newLabel.trim() || newLabel.trim() === ""
                          )
                            ? colors.neutral400
                            : colors.neutral100
                        }
                        height={40}
                        width={40}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Icon icon="check" accessibilityLabel={resources.add} />
                      </Box>
                    </TapArea>
                  </Box>
                </Box>
              </Box>
            )}
            <TapArea
              onTap={() => {
                organizationNavigate(organizationLinkTemplates.viewMyLists());
              }}
            >
              <Box
                display="flex"
                direction="row"
                alignItems="center"
                gap={2}
                hoverColor={colors.primaryHover0}
                borderRadius={6}
                borderColor={colors.white}
                height={50}
              >
                <ListSvg width={30} height={25} color={colors.neutral900} />
                <Text size="200">{resources.viewMyLists}</Text>
              </Box>
            </TapArea>
          </Box>
        </Box>
        <Box display="flex" right={true} marginStart={5} flex="shrink">
          <Box display="flex" gap={5} direction="row">
            <Box margin={1} />
          </Box>
        </Box>
      </Box>

      <Box>
        {notSelectedProfileDocumentLabelsList.length > 0 && (
          <Box>
            <Divider />
            <Box>
              <WarningDeleteLabelLayer
                deleteLabelWarning={deleteLabelWarning}
                setDeleteLabelWarningState={setDeleteLabelWarningState}
                handleDeleteMyProfileLabel={handleDeleteMyProfileLabel}
                selectedLabel={currentLabel}
              />
            </Box>
            <Box paddingX={2} overflow="auto">
              <Box display="flex" direction="column" margin={2}>
                {notSelectedProfileDocumentLabelsList.map((entry, index) => {
                  return (
                    <LabelListCheckBox
                      key={entry.value}
                      icon="list"
                      editMode={editMode}
                      entry={entry}
                      handleCascadeOptionsPopoverClick={handleCascadeOptionsPopoverClick}
                      handleSelectedLists={onHandleSelectedLabels}
                      handleUpdateProfileLists={handleUpdateProfileLabels}
                      index={index}
                      isPopoverWindows={isPopoverWindows}
                      labelIndex={labelIndex}
                      newLabel={newLabel}
                      CascadeOptionsPopoverList={CascadeOptionsPopoverList}
                      profileDocumentLists={profileDocumentLists}
                      profileDocumentListOptions={profileDocumentLabelsList}
                      selectedLists={selectedLists}
                      setEditMode={setEditMode}
                      setLabelIndex={setLabelIndex}
                      setNewLabel={setNewLabel}
                      onItemClick={onSelectSingle}
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}

        <Box paddingX={4} marginBottom={4}>
          <TapArea
            onTap={() => {
              organizationNavigate(organizationLinkTemplates.documentId(documentId, "myLists"));
            }}
          >
            <Text weight="bold" size="200">
              {resources.seeAllListsForThisDocument}
            </Text>
          </TapArea>
        </Box>
      </Box>
    </Box>
  );
};
