import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
};

export const TermsAndConditionsPolicySvg: FunctionComponent<Props> = ({
  width = 4818,
  height = 12176,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      width="483"
      height="355"
      viewBox="0 0 483 355"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M165.018 213.843C155.884 214.259 146.771 214.814 137.681 215.532C114.384 217.36 91.1379 220.974 68.3831 227.616C55.0044 231.525 41.6257 236.567 30.0211 245.78C24.1711 250.422 18.563 256.591 16.635 264.938C13.9006 276.794 20.4177 284.008 28.1297 288.43C35.1966 292.485 43.2165 292.747 50.9871 292.934C96.5114 294.06 142.036 295.186 187.567 296.312C193.505 296.459 199.751 296.712 204.839 300.555C209.926 304.399 213.174 313.138 210.139 319.535C208.284 323.451 204.523 325.736 203.262 330.012C201.936 334.508 204.01 339.689 207.111 342.496C210.212 345.303 214.105 346.249 217.873 347.073C285.111 361.761 355.303 355.837 420.613 330.966C436.001 325.108 452.077 317.332 464.246 303.729C473.6 293.269 483.189 272.772 477.075 256.918C473.586 247.868 465.5 243.046 457.648 241.055C411.208 229.281 362.465 224.369 315.284 219.334C265.332 213.998 215.131 211.582 165.018 213.851V213.843Z"
        fill="url(#paint0_linear_10350_122411)"
      />
      <path
        d="M15.5948 272.529C1.17817 242.358 0.131601 204.247 12.8689 173.105C25.6061 141.945 51.8189 118.528 81.2606 111.988C95.4339 108.833 110.556 109.261 123.448 101.803C144.639 89.5419 153.149 60.5558 167.923 39.2329C183.508 16.7427 207.538 2.2363 232.518 0.240332C257.497 -1.75563 283.053 8.76773 301.209 28.567C318.644 47.5643 330.075 74.9553 351.899 86.4944C369.877 96.0109 391.019 92.4912 410.133 98.4524C439.737 107.702 460.936 139.093 470.088 172.704C479.239 206.315 479.596 242.803 478.639 278L15.5948 272.565V272.538V272.529Z"
        fill="url(#paint1_linear_10350_122411)"
      />
      <g filter="url(#filter0_d_10350_122411)">
        <path
          d="M479 243C477.685 228.286 475.413 213.751 471.591 199.724C462.437 166.026 441.233 134.552 411.622 125.297C392.511 119.311 371.372 122.84 353.389 113.307C331.544 101.72 320.118 74.2665 302.687 55.2285C284.51 35.3864 258.948 24.8266 233.962 26.8278C208.977 28.8289 184.94 43.3822 169.351 65.9401C154.574 87.3009 146.07 116.381 124.866 128.656C111.971 136.133 96.853 135.722 82.6681 138.885C53.2272 145.442 27.008 168.929 14.2595 200.162C9.13085 212.732 6.22571 226.446 5.55218 240.302C2.05465 217.896 4.45667 194.221 12.8718 173.575C25.6122 142.333 51.8396 118.855 81.2804 112.298C95.4572 109.135 110.583 109.546 123.478 102.086C144.674 89.7935 153.187 60.7317 167.964 39.353C183.561 16.804 207.589 2.25969 232.575 0.240641C257.561 -1.76054 283.122 8.80819 301.284 28.6413C318.723 47.6794 330.156 75.1509 351.986 86.7203C369.968 96.2616 391.132 92.7327 410.234 98.7095C439.846 107.983 461.05 139.457 470.203 173.155C476.29 195.561 478.489 219.263 479 243Z"
          fill="url(#paint2_linear_10350_122411)"
          shapeRendering="crispEdges"
        />
      </g>
      <path d="M282.199 135.891H232.758V143.525H282.199V135.891Z" fill="#98BDEA" />
      <path d="M282.195 155.746H265.961V163.381H282.195V155.746Z" fill="#98BDEA" />
      <path
        d="M339.02 30.5391H152.311C150.277 30.5391 148.629 32.2447 148.629 34.3486V280.955C148.629 283.059 150.277 284.765 152.311 284.765H339.02C341.054 284.765 342.702 283.059 342.702 280.955V34.3486C342.702 32.2447 341.054 30.5391 339.02 30.5391Z"
        fill="url(#paint3_linear_10350_122411)"
      />
      <path
        d="M323.711 42.75H165.596C163.563 42.75 161.914 44.4556 161.914 46.5596V267.209C161.914 269.313 163.563 271.019 165.596 271.019H323.711C325.744 271.019 327.393 269.313 327.393 267.209V46.5596C327.393 44.4556 325.744 42.75 323.711 42.75Z"
        fill="url(#paint4_linear_10350_122411)"
      />
      <path d="M257.84 73.293H178.883V80.9274H257.84V73.293Z" fill="#98BDEA" />
      <path d="M289.571 93.1445H178.883V100.779H289.571V93.1445Z" fill="#98BDEA" />
      <path d="M313.184 111.465H178.883V119.099H313.184V111.465Z" fill="#98BDEA" />
      <path d="M313.184 129.789H178.883V137.423H313.184V129.789Z" fill="#98BDEA" />
      <path d="M245.296 148.875H178.883V156.509H245.296V148.875Z" fill="#98BDEA" />
      <g filter="url(#filter1_bd_10350_122411)">
        <path
          d="M352.976 78.25H194.862C192.828 78.25 191.18 79.9556 191.18 82.0596V302.709C191.18 304.813 192.828 306.519 194.862 306.519H352.976C355.01 306.519 356.658 304.813 356.658 302.709V82.0596C356.658 79.9556 355.01 78.25 352.976 78.25Z"
          fill="url(#paint5_linear_10350_122411)"
          shapeRendering="crispEdges"
        />
      </g>
      <path d="M285.723 117.57H206.766V125.205H285.723V117.57Z" fill="#98BDEA" />
      <path d="M341.07 117.57H291.629V125.205H341.07V117.57Z" fill="#98BDEA" />
      <path d="M341.07 137.422H324.836V145.056H341.07V137.422Z" fill="#98BDEA" />
      <path d="M317.454 137.422H206.766V145.056H317.454V137.422Z" fill="#98BDEA" />
      <path d="M341.067 155.746H206.766V163.381H341.067V155.746Z" fill="#98BDEA" />
      <path d="M341.067 174.066H206.766V181.701H341.067V174.066Z" fill="#98BDEA" />
      <path d="M273.179 193.152H206.766V200.787H273.179V193.152Z" fill="#98BDEA" />
      <path
        d="M273.108 262.904C285.868 262.904 296.213 252.202 296.213 239.001C296.213 225.8 285.868 215.098 273.108 215.098C260.348 215.098 250.004 225.8 250.004 239.001C250.004 252.202 260.348 262.904 273.108 262.904Z"
        stroke="url(#paint6_linear_10350_122411)"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <path
        d="M261.168 241.628L266.326 248.461L284.781 233.23"
        stroke="url(#paint7_linear_10350_122411)"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M344.098 201.945L341.191 203.113C339.855 203.648 338.925 204.915 338.792 206.388C338.763 206.686 338.933 206.969 339.206 207.068C340.408 207.534 341.722 207.579 342.947 207.198L346.297 206.167L344.098 201.938V201.945Z"
        fill="#F9D6B9"
      />
      <path
        d="M341.334 210.888L346.986 195.146C347.266 194.359 346.875 193.474 346.115 193.184C345.355 192.894 344.499 193.298 344.219 194.085C342.337 199.329 340.455 204.582 338.566 209.827L338.699 212.461C338.729 213.01 339.385 213.239 339.732 212.827L341.334 210.896V210.888Z"
        fill="url(#paint8_linear_10350_122411)"
      />
      <path
        d="M384.256 297.626L391.761 297.32L390.462 305.237L382.898 303.741C383.976 302.466 384.249 297.633 384.249 297.633L384.256 297.626Z"
        fill="url(#paint9_linear_10350_122411)"
      />
      <path
        d="M411.055 299.12L417.666 295.234L419.71 302.96L412.317 305.258C412.767 303.594 411.055 299.128 411.055 299.128V299.12Z"
        fill="url(#paint10_linear_10350_122411)"
      />
      <path
        d="M401.93 226.554C403.096 226.325 404.254 226.08 405.42 225.844C406.601 225.882 407.796 226.035 408.785 226.714L410.549 236.311L410.231 242.792L411.065 268.68C412.666 272.238 414.26 276.032 415.795 280.063C417.765 285.224 419.441 292.95 420.865 297.645C420.791 298.241 420.776 298.867 420.466 299.126C420.407 299.02 420.356 298.928 420.297 298.829C415.78 290.889 412.445 279.391 410.453 270.299C409.833 270.268 409.22 270.238 408.6 270.2C409.021 269.902 409.449 269.612 409.87 269.314C409.39 268.963 408.91 268.612 408.423 268.268L409.722 268.291C409.434 267.932 409.147 267.581 408.851 267.23C409.272 267.192 409.693 267.161 410.113 267.123C409.087 262.39 409.11 257.473 409.124 252.61C409.139 247.747 409.124 242.823 408.04 238.112C407.139 234.203 405.435 230.485 402.749 227.676C402.276 227.187 402.033 226.821 401.952 226.561H401.93V226.554Z"
        fill="#603826"
      />
      <path
        d="M419.974 300.375C416.365 303.512 411.569 303.123 411.569 303.123L411.06 304.658C410.698 305.894 409.79 306.894 408.721 307.33C407.658 307.765 406.374 308.566 405.643 310.055C405.348 310.651 405.769 311.361 406.381 311.361H411.2C411.532 311.361 411.849 311.322 412.167 311.238L414.513 310.796C415.185 310.62 415.812 310.315 416.653 309.841L417.192 309.154L418.181 308.742L418.535 309.154L422.512 307.039V305.467C421.974 302.627 419.989 300.359 419.989 300.359V300.375H419.974Z"
        fill="#7F4C2F"
      />
      <path
        d="M391.573 302.639C387.219 304.334 383.22 302.555 383.22 302.555L381.855 303.532C381.154 304.57 380.091 305.654 378.962 306.067C377.612 306.547 376.217 306.616 375.081 307.761C374.63 308.227 374.557 310.052 375.14 310.265C375.14 310.265 384.659 310.571 385.603 310.426L386.319 309.968L387.374 309.93L387.588 310.449L391.846 310.273L392.444 308.372C392.783 305.479 391.58 302.646 391.58 302.646L391.573 302.639Z"
        fill="#7F4C2F"
      />
      <path
        d="M389.124 226.977L398.341 229.068L399.935 236.886C398.157 246.933 395.441 260.11 393.663 270.157C393.692 273.134 393.884 275.493 394.046 278.471C394.135 280.127 394.12 281.799 393.987 283.456C393.581 288.922 392.969 295.602 392.128 300.526C388.984 303.145 383.767 300.923 383.767 300.923L382.395 227.297L389.132 226.977H389.124Z"
        fill="url(#paint11_linear_10350_122411)"
      />
      <path
        d="M408.53 225.228L408.508 225.121L409.902 224.938C409.445 225.037 408.987 225.121 408.53 225.22V225.228Z"
        fill="#683E29"
      />
      <path
        d="M405.441 225.845C406.474 225.631 407.508 225.417 408.533 225.211L408.806 226.715C407.818 226.035 406.615 225.883 405.441 225.845Z"
        fill="#683E29"
      />
      <path
        d="M385.151 229.99C390.76 228.845 396.36 227.7 401.961 226.547C402.042 226.806 402.293 227.181 402.758 227.661C405.444 230.471 407.149 234.189 408.049 238.098C409.134 242.816 409.149 247.74 409.134 252.595C409.119 257.459 409.097 262.375 410.123 267.109C409.702 267.147 409.282 267.177 408.861 267.215C409.156 267.574 409.436 267.925 409.732 268.277L408.433 268.254C408.92 268.597 409.4 268.948 409.879 269.3C409.459 269.597 409.038 269.887 408.61 270.185C409.23 270.223 409.842 270.254 410.462 270.284C412.447 279.369 415.79 290.874 420.306 298.814C420.365 298.913 420.417 299.005 420.476 299.112C420.468 299.127 420.454 299.15 420.439 299.15C418.638 300.425 414.144 303.227 411.082 301.914L398.375 269.887L390.28 241.8C389.372 241.373 388.664 240.609 388.28 239.602C387.897 238.594 387.417 237.571 386.93 236.212L385.137 229.99H385.151Z"
        fill="#683E29"
      />
      <path
        d="M351.097 201.021C350.455 198.601 351.363 196.601 351.577 196.173L351.614 196.158L364.763 190.982C366.475 190.165 369.043 189.768 370.711 188.272L385.617 170.949C385.469 171.255 385.329 171.575 385.204 171.881C385.182 171.926 385.16 171.972 385.137 172.018C383.263 176.209 381.559 180.958 380.902 185.584C380.599 187.73 380.496 190.707 380.511 193.852L378.002 195.616L377.101 196.242C373.655 198.677 369.84 200.395 365.841 201.319C361.635 202.296 357.428 203.273 353.222 204.258C353.193 204.258 353.163 204.281 353.141 204.281C352.713 203.915 351.592 202.853 351.097 201.006V201.021Z"
        fill="url(#paint12_linear_10350_122411)"
      />
      <path
        d="M380.496 193.858C380.481 190.72 380.577 187.743 380.887 185.598C381.543 180.971 383.248 176.223 385.122 172.031C382.96 177.276 382.813 182.025 380.968 188.056L381.064 193.461L380.488 193.866L380.496 193.858Z"
        fill="url(#paint13_linear_10350_122411)"
      />
      <path
        d="M382.546 201.376C381.926 184.428 385.394 174.915 387.888 170.342C389.069 169.953 390.486 169.548 392.102 169.273C391.806 170.64 391.009 171.831 389.866 172.526L389.519 172.739C389.991 173.564 392.485 172.976 392.485 172.976C393.88 172.556 400.669 170.151 402.086 169.648C400.211 171.854 397.946 174.656 395.784 177.679C390.862 184.565 388.331 193.123 388.515 201.842L389.15 231.631L383.881 231.585L382.56 201.353L382.546 201.376Z"
        fill="white"
      />
      <path
        d="M391.811 169.32C390.313 169.588 389.014 169.962 387.892 170.328C386.948 170.634 386.151 170.947 385.523 171.183C385.538 171.145 385.553 171.107 385.575 171.069C385.59 171.031 385.605 170.985 385.627 170.947L387.258 169.985C387.59 169.809 387.966 169.641 388.379 169.481C389.464 169.068 390.807 168.74 392.224 168.481C392.814 168.374 393.412 168.274 394.01 168.191C396.511 167.832 399.175 167.946 401.772 168.496C402.134 168.572 402.488 168.649 402.85 168.755C405.314 169.389 407.676 170.435 409.786 171.84L410.222 172.275C412.996 175.046 414.413 179.085 414.051 183.177C413.764 186.49 413.712 186.368 413.402 189.292L410.45 208.744V217.219L413.026 232.007C413.1 232.472 413.055 232.953 412.878 233.388L410.428 239.458C408.864 241.794 404.237 240.023 401.352 239C400.85 238.824 400.37 238.595 399.913 238.313L389.139 231.648L388.505 201.858C388.313 193.132 390.859 184.582 395.773 177.695C397.935 174.672 400.201 171.863 402.075 169.664C400.658 170.16 393.869 172.573 392.475 172.993C392.475 172.993 389.973 173.58 389.508 172.756L389.862 172.542C391.006 171.855 391.803 170.672 392.098 169.29C391.995 169.305 391.899 169.32 391.803 169.343H391.818L391.811 169.32Z"
        fill="url(#paint14_linear_10350_122411)"
      />
      <path
        d="M380.511 193.854C380.497 190.716 380.592 187.739 380.902 185.593C381.559 180.967 383.264 176.218 385.138 172.027C385.16 171.981 385.182 171.935 385.204 171.89C385.3 171.653 385.411 171.424 385.514 171.195C386.149 170.966 386.939 170.653 387.883 170.34C388.99 169.973 390.303 169.599 391.801 169.332C390.414 169.615 389.883 170.042 388.813 171.653C386.319 176.226 381.928 184.418 382.548 201.366L383.869 231.598L382.157 231.575L381.102 209.848C381.043 207.924 380.533 200.503 380.504 193.861L380.511 193.854Z"
        fill="url(#paint15_linear_10350_122411)"
      />
      <path
        d="M389.48 172.718L389.835 172.505C390.978 171.818 391.775 170.634 392.071 169.252C392.122 169.062 392.144 168.863 392.166 168.665C392.166 168.604 392.189 168.535 392.189 168.474C392.262 167.817 392.358 167.046 392.484 166.176C392.543 165.84 392.587 165.496 392.654 165.13C393.192 161.939 399.56 161.068 401.656 160.87C401.693 160.855 401.73 160.855 401.759 160.855H401.782C402.128 160.832 402.32 160.816 402.32 160.816C402.32 160.816 402.224 161.198 402.099 161.817C401.752 163.427 401.206 166.649 401.737 168.489C401.855 168.878 402.01 169.191 402.232 169.436C402.269 169.474 402.291 169.504 402.328 169.535C402.328 169.535 402.239 169.573 402.069 169.634C400.653 170.13 393.864 172.543 392.469 172.963C392.469 172.963 389.975 173.551 389.503 172.726H389.488L389.48 172.718Z"
        fill="#F9D6B9"
      />
      <path
        d="M389.48 172.718L389.835 172.505C390.978 171.818 391.775 170.634 392.071 169.252C392.122 169.062 392.144 168.863 392.166 168.665C392.166 168.604 392.189 168.535 392.189 168.474C392.262 167.817 392.358 167.046 392.484 166.176C392.543 165.84 392.587 165.496 392.654 165.13C393.192 161.939 399.56 161.068 401.656 160.87C401.693 160.855 401.73 160.855 401.759 160.855H401.782C402.128 160.832 402.32 160.816 402.32 160.816C402.32 160.816 402.224 161.198 402.099 161.817C401.752 163.427 401.206 166.649 401.737 168.489C401.855 168.878 402.01 169.191 402.232 169.436C402.269 169.474 402.291 169.504 402.328 169.535C402.328 169.535 402.239 169.573 402.069 169.634C400.653 170.13 393.864 172.543 392.469 172.963C392.469 172.963 389.975 173.551 389.503 172.726H389.488L389.48 172.718Z"
        fill="url(#paint16_linear_10350_122411)"
      />
      <path
        d="M389.52 161.706C389.579 162.233 389.653 163.096 389.727 163.913C389.83 165.157 390.782 166.127 391.933 166.157L393.298 166.195C394.243 166.226 395.291 165.844 396.191 165.524C397.814 164.936 399.571 163.501 400.877 161.989C403.651 158.798 405.548 154.66 404.714 150.354C403.843 145.873 400.626 144.147 397.128 144.063C395.815 144.033 392.752 144.857 391.638 145.773C390.509 146.705 390.015 147.766 389.778 149.232C389.498 150.95 389.284 152.408 389.232 154.225C389.218 154.538 388.981 154.889 388.981 155.21C388.959 156.958 389.314 159.027 389.446 160.699C389.476 161.035 389.505 161.363 389.535 161.699L389.52 161.706Z"
        fill="#F9D6B9"
      />
      <path
        d="M389.521 161.706C389.58 162.233 389.654 163.096 389.728 163.913C389.831 165.157 390.791 166.035 391.934 166.157C394.355 166.409 395.934 165.806 397.395 164.829C398.878 163.844 400.059 162.92 401.114 161.699C403.889 158.508 405.549 154.66 404.715 150.354C403.844 145.873 400.627 144.147 397.129 144.063C395.816 144.033 392.753 144.857 391.632 145.773C390.503 146.705 390.008 147.766 389.772 149.232C389.514 150.827 389.285 152.499 389.226 154.225C389.211 154.538 388.975 154.889 388.975 155.21C388.938 157.072 389.3 158.958 389.44 160.699C389.47 161.035 389.499 161.363 389.529 161.699L389.521 161.706Z"
        fill="url(#paint17_linear_10350_122411)"
      />
      <path
        d="M388.972 155.437L390.566 155.223V154.75C390.566 154.002 390.153 153.338 389.511 153.07H389.275C389.275 153.261 389.253 153.376 389.253 153.544V153.818C389.253 153.88 389.253 153.941 389.238 153.994C389.238 154.009 389.223 154.07 389.223 154.078V154.193C389.223 154.299 389.201 154.376 389.164 154.483C389.149 154.521 389.135 154.559 389.12 154.59V154.612C389.105 154.666 389.083 154.719 389.061 154.765C389.061 154.78 389.046 154.796 389.046 154.811C389.046 154.819 389.024 154.88 389.024 154.887C389.002 154.964 388.965 155.078 388.965 155.155V155.437H388.972Z"
        fill="url(#paint18_linear_10350_122411)"
      />
      <path
        d="M389.275 147.757C389.275 147.757 388.81 148.78 390.47 148.894L399.687 148.657C400.403 148.642 401 149.268 401 150.047C401.015 153.124 401.141 160.208 402.011 161.995L403.502 159.559C403.502 159.559 403.524 159.529 403.539 159.514C404.306 158.498 404.771 157.185 405.088 155.948C405.347 154.956 405.48 153.925 405.531 152.887C405.598 151.581 405.686 149.917 405.184 148.68C404.956 148.1 404.579 147.787 404.203 147.344C403.782 146.848 403.561 146.245 403.162 145.733C402.808 145.275 402.365 144.901 401.893 144.604C400.927 144.008 399.812 143.733 398.728 143.527C394.972 142.817 391.304 144.054 389.282 147.757H389.275Z"
        fill="#7F4C2F"
      />
      <path
        d="M400.667 157.332C400.594 158.233 400.542 159.386 400.778 159.5C401.169 159.699 401.442 159.592 402.18 158.912C402.918 158.233 403.951 156.401 403.258 154.744C402.866 153.782 402.092 154.439 401.509 155.156C401.014 155.759 400.719 156.515 400.653 157.317L400.667 157.332Z"
        fill="#F6BF95"
      />
      <path
        d="M396.345 155.703C396.05 155.764 392.781 156.085 392.781 155.833C392.781 155.26 392.803 154.695 392.803 154.123C392.803 153.963 392.818 153.787 392.914 153.665C393.025 153.527 393.209 153.512 393.372 153.505C394.11 153.466 394.877 153.436 395.563 153.726C396.124 153.97 397.533 155.474 396.353 155.703H396.345Z"
        fill="url(#paint19_linear_10350_122411)"
      />
      <path
        d="M401.332 158.608C401.42 158.768 401.605 158.608 401.664 158.463C401.723 158.326 401.716 158.173 401.738 158.02C401.863 157.081 402.985 156.921 403.007 155.936C403.007 155.806 403.022 155.356 402.933 155.264C402.83 155.157 402.446 155.333 402.328 155.394C402.136 155.486 401.966 155.631 401.841 155.806C401.583 156.157 401.479 156.638 401.568 157.081C401.59 157.203 401.634 157.333 401.634 157.463C401.634 157.669 401.531 157.86 401.442 158.043C401.376 158.173 401.251 158.463 401.339 158.608H401.332Z"
        fill="url(#paint20_linear_10350_122411)"
      />
      <path
        d="M390.367 158.642C390.721 158.588 392.02 158.993 392.293 159.306C392.743 159.832 390.987 159.588 390.713 159.581C390.551 159.581 390.381 159.565 390.234 159.497C390.086 159.428 389.953 159.283 389.946 159.107C389.931 158.871 390.145 158.68 390.359 158.649L390.367 158.642Z"
        fill="url(#paint21_linear_10350_122411)"
      />
      <path
        d="M390.392 161.194C390.244 161.148 390.074 161.095 389.956 161.003C389.927 160.98 389.75 160.767 389.809 160.751C390.222 160.667 390.709 160.667 391.093 160.881C391.329 160.698 391.617 160.729 391.89 160.774C392.421 160.858 392.952 160.942 393.484 161.026C393.432 161.026 393.314 161.095 393.27 161.11C393.196 161.133 393.13 161.156 393.056 161.171C392.879 161.202 392.701 161.232 392.532 161.255C392.185 161.309 391.831 161.347 391.477 161.347C391.122 161.347 390.753 161.316 390.407 161.194H390.392Z"
        fill="url(#paint22_linear_10350_122411)"
      />
      <path
        d="M395.627 144.234C398.276 147.448 399.007 147.991 399.612 148.372C397.501 149.51 395.797 149.838 394.609 149.93C392.624 150.082 391.783 149.571 390.203 150.098C389.975 150.174 389.362 150.327 388.632 150.182C388.115 150.075 387.606 149.724 387.429 149.181C387.178 148.395 387.694 147.586 388.182 146.937C387.754 146.632 387.643 145.944 387.857 145.441C388.071 144.929 388.536 144.593 389.03 144.433C389.525 144.265 390.041 144.265 390.55 144.265C392.24 144.265 393.93 144.242 395.627 144.234Z"
        fill="#7F4C2F"
      />
      <path
        d="M400.493 158.92C400.021 157.782 399.585 156.637 399.172 155.469C398.811 154.454 398.478 153.423 398.161 152.392C397.94 151.644 397.445 150.606 397.519 149.797C397.667 148.201 400.176 147.605 401.216 148.392C401.814 148.85 402.116 149.659 402.153 150.453C402.198 151.247 402.02 152.033 401.851 152.805C401.393 154.843 400.943 156.889 400.486 158.927L400.493 158.92Z"
        fill="#7F4C2F"
      />
      <path
        d="M402.832 168.746C387.897 183.496 388.177 198.039 388.436 201.2C388.495 201.826 388.524 202.452 388.546 203.078L388.701 207.781C388.701 207.781 390.686 198.933 391.159 196.581C391.631 194.23 393.084 189.611 393.084 189.611L391.653 188.336H393.498L396.944 183.328C399.866 179.297 408.323 170.945 408.323 170.945C407.289 169.761 402.832 168.761 402.832 168.761V168.746Z"
        fill="url(#paint23_linear_10350_122411)"
      />
      <path
        d="M405.567 222.531L401.309 226.074L395.922 223.218V212.484C399.088 213.836 405.567 212.484 405.567 212.484V222.531Z"
        fill="url(#paint24_linear_10350_122411)"
      />
      <path
        d="M405.57 225.57L402.832 227.204C401.253 228.143 399.335 228.189 397.726 227.311L395.785 226.257V215.523C399.002 216.875 405.577 215.523 405.577 215.523V225.57H405.57Z"
        fill="url(#paint25_linear_10350_122411)"
      />
      <path
        d="M392.216 168.484L389.515 169.103C388.128 169.507 386.962 170.607 386.276 171.981C384.866 174.79 382.217 183.578 382.217 183.578L382.63 185.12L381.678 185.868C381.678 185.868 381.199 188.395 381.162 189.281C380.926 193.999 382.748 205.786 382.748 205.786L382.475 196.029C382.224 186.25 388.895 172.752 389.677 171.164C390.157 170.202 392.208 168.484 392.208 168.484H392.216Z"
        fill="url(#paint26_linear_10350_122411)"
      />
      <path
        d="M404.983 207.184C404.673 206.436 404.717 205.589 404.762 204.764C405.131 198.061 405.551 191.175 408.127 185.06C409.403 182.037 411.174 179.311 413.115 176.746C413.897 178.739 414.229 180.96 414.037 183.182C413.75 186.495 413.698 186.373 413.388 189.297L410.436 208.75V217.224L413.004 232.012C413.086 232.47 413.034 232.958 412.864 233.386L412.805 233.516C411.92 232.019 411.174 230.408 410.562 228.759C408.902 224.286 408.127 219.499 407.352 214.75C407.094 213.139 406.843 211.528 406.747 209.895C406.304 209.788 405.861 209.666 405.419 209.559C405.861 209.147 406.311 208.727 406.754 208.322C406.031 208.551 405.264 207.956 404.961 207.2H404.976L404.983 207.184Z"
        fill="url(#paint27_linear_10350_122411)"
      />
      <path
        d="M351.577 196.175L351.614 196.16C352.293 196.641 352.972 198.443 353.26 200.649C353.437 202.054 353.407 203.352 353.23 204.276C353.201 204.276 353.171 204.298 353.149 204.298C352.721 203.932 351.599 202.871 351.105 201.023C350.463 198.603 351.371 196.603 351.585 196.175H351.577Z"
        fill="url(#paint28_linear_10350_122411)"
      />
      <path
        d="M409.646 262.046C407.262 262.046 405.462 259.68 405.897 257.13L409.159 238.082H433.591V260.069C433.591 261.161 432.78 262.046 431.776 262.046H409.653H409.646Z"
        fill="#3F261C"
      />
      <path
        d="M409.16 238.082H433.593L435.12 258.939C435.12 260.657 433.844 262.054 432.264 262.054H413.455C411.086 262.054 409.16 259.962 409.16 257.374V238.09V238.082Z"
        fill="url(#paint29_linear_10350_122411)"
      />
      <path
        d="M409.171 238.07H433.604L434.799 244.323C434.947 244.926 434.777 245.369 434.231 245.575L423.701 248.751C423.413 248.835 423.11 248.85 422.823 248.781L411.643 246.201C410.935 246.041 410.403 245.453 410.271 244.712L409.156 238.078H409.171V238.07Z"
        fill="#683E29"
      />
      <path
        d="M416.691 241.18V237.179C416.691 235.966 417.599 234.988 418.706 234.988H426.262C427.376 234.988 428.277 235.973 428.277 237.179V241.18"
        stroke="#683E29"
        strokeMiterlimit="10"
      />
      <path d="M418.492 235.678H426.469V233.762H418.492V235.678Z" fill="#E87026" />
      <path d="M419.527 246.477H426.198V245.5H419.527V246.477Z" fill="#E87026" />
      <path d="M421.426 251.18H424.761V245.988H421.426V251.18Z" fill="#E87026" />
      <path
        d="M424.247 251.425C424.247 252.119 423.73 252.684 423.088 252.684C422.446 252.684 421.93 252.119 421.93 251.425V246.691H424.247V251.425Z"
        fill="#E9824F"
      />
      <path
        d="M419.972 231.988L421.64 230.644C422.141 230.171 422.237 229.354 421.861 228.782C421.499 228.232 420.813 228.049 420.245 228.354C419.928 228.576 419.544 228.889 419.16 229.324C418.74 229.797 418.452 230.27 418.26 230.644C418.245 231.37 418.193 232.141 418.142 232.958C418.112 233.446 418.068 233.935 418.031 234.378C417.994 234.691 418.23 234.966 418.518 234.966C419.079 234.966 419.559 234.53 419.655 233.935L419.979 231.996L419.972 231.988Z"
        fill="#F9D6B9"
      />
      <path
        d="M421.65 230.652C422.152 230.179 422.248 229.362 421.871 228.789C421.51 228.24 420.823 228.057 420.255 228.362C419.938 228.583 419.554 228.896 419.17 229.332C418.75 229.805 418.462 230.278 418.27 230.652C418.255 231.378 418.204 232.149 418.152 232.966C418.115 233.462 418.078 233.966 418.034 234.416C418.004 234.714 418.218 234.966 418.491 234.966C419.067 234.966 419.561 234.515 419.665 233.897L419.982 231.996L421.65 230.652Z"
        fill="url(#paint30_linear_10350_122411)"
      />
      <path
        d="M425.516 226.199L425.449 224.39L420.483 223.672V227.947L419.834 228.94C419.376 229.642 419.133 230.482 419.133 231.344V238.009L420.159 238.338L425.508 236.635C426.298 236.063 426.77 235.101 426.77 234.062V231.299C426.77 229.963 426.357 228.665 425.604 227.604L425.508 227.466V226.206L425.516 226.199Z"
        fill="#F9D6B9"
      />
      <path
        d="M425.516 226.199L425.449 224.39L420.483 223.672V227.947L419.834 228.94C419.376 229.642 419.133 230.482 419.133 231.344V238.009L420.159 238.338L425.508 236.635C426.298 236.063 426.77 235.101 426.77 234.062V231.299C426.77 229.963 426.357 228.665 425.604 227.604L425.508 227.466V226.206L425.516 226.199Z"
        fill="url(#paint31_linear_10350_122411)"
      />
      <path
        d="M407.4 180.427C406.227 185.633 406.183 189.474 406.441 190.237L413.923 200.887C415.746 203.597 417.067 204.933 417.561 208.247C418.144 212.178 418.757 221.614 419.421 226.478L427.376 226.187C427.376 225.287 427.42 214.797 427.324 213.896C426.734 207.85 426.21 204.865 425.664 203.292C424.992 201.368 419.177 186.183 417.141 182.824C415.259 179.755 413.761 176.457 411.85 174.021C411.422 173.479 410.691 173.373 410.131 173.754C408.979 174.533 407.961 177.938 407.4 180.427Z"
        fill="url(#paint32_linear_10350_122411)"
      />
      <path
        d="M425.653 203.3C425.063 201.606 420.502 189.665 418.016 184.527C418.599 187.146 422.694 206.324 422.694 206.324C422.694 206.324 423.616 207.11 424.731 207.881C424.317 208.286 423.904 208.675 423.491 209.072C424.103 208.942 424.709 209.377 425.018 209.95C425.336 210.523 425.417 211.202 425.506 211.866C426.088 216.653 426.664 221.424 427.24 226.204H427.372C427.372 225.295 427.417 214.813 427.321 213.912C426.73 207.866 426.207 204.881 425.66 203.308H425.653V203.3Z"
        fill="url(#paint33_linear_10350_122411)"
      />
      <path
        d="M392.218 209.677C392.218 209.067 391.738 208.57 391.148 208.57C390.558 208.57 390.078 209.067 390.078 209.677C390.078 210.288 390.558 210.784 391.148 210.784C391.738 210.784 392.218 210.288 392.218 209.677Z"
        fill="url(#paint34_linear_10350_122411)"
      />
      <path
        d="M391.865 209.682C391.865 209.269 391.548 208.934 391.149 208.934C390.751 208.934 390.434 209.269 390.434 209.682C390.434 210.094 390.751 210.43 391.149 210.43C391.548 210.43 391.865 210.094 391.865 209.682Z"
        fill="#E97025"
      />
      <path
        d="M392.273 216.107C392.273 215.496 391.794 215 391.211 215C390.628 215 390.141 215.496 390.141 216.107C390.141 216.718 390.62 217.214 391.211 217.214C391.801 217.214 392.273 216.718 392.273 216.107Z"
        fill="url(#paint35_linear_10350_122411)"
      />
      <path
        d="M391.92 216.104C391.92 215.691 391.603 215.355 391.204 215.355C390.806 215.355 390.488 215.691 390.488 216.104C390.488 216.516 390.806 216.852 391.204 216.852C391.603 216.852 391.92 216.516 391.92 216.104Z"
        fill="#E97025"
      />
      <path
        d="M353.312 202.477C353.489 200.683 352.899 198.805 352.899 198.805L350.441 199.553L342.656 202.484L345.63 206.172L347.512 205.897C348.729 205.729 349.873 205.179 350.825 204.332L351.187 204.011L353.319 202.492L353.312 202.477Z"
        fill="#F9D6B9"
      />
      <path
        d="M353.312 202.477C353.489 200.683 352.899 198.805 352.899 198.805L350.441 199.553L342.656 202.484L345.63 206.172L347.512 205.897C348.729 205.729 349.873 205.179 350.825 204.332L351.187 204.011L353.319 202.492L353.312 202.477Z"
        fill="url(#paint36_linear_10350_122411)"
      />
      <path
        d="M352.898 198.582C353.208 199.59 353.311 201.071 353.311 201.071L350.057 202.056L349.607 202.185C348.19 202.582 346.7 202.529 345.32 202.033L344.287 201.666L342.914 203.644C342.678 203.995 342.221 204.079 341.881 203.842C341.519 203.583 341.416 203.056 341.66 202.666L342.708 200.964C343.32 199.964 344.279 199.284 345.364 199.086L347.047 198.773C347.725 198.651 348.419 198.712 349.068 198.964L350.197 199.407L352.906 198.582H352.898Z"
        fill="#F9D6B9"
      />
      <path
        d="M352.898 198.582C353.208 199.59 353.311 201.071 353.311 201.071L350.057 202.056L349.607 202.185C348.19 202.582 346.7 202.529 345.32 202.033L344.287 201.666L342.914 203.644C342.678 203.995 342.221 204.079 341.881 203.842C341.519 203.583 341.416 203.056 341.66 202.666L342.708 200.964C343.32 199.964 344.279 199.284 345.364 199.086L347.047 198.773C347.725 198.651 348.419 198.712 349.068 198.964L350.197 199.407L352.906 198.582H352.898Z"
        fill="url(#paint37_linear_10350_122411)"
      />
      <g filter="url(#filter2_d_10350_122411)">
        <path
          d="M380.037 118.333H355.988V100.773H380.037L374.377 109.156L380.037 118.333Z"
          fill="#E97025"
        />
      </g>
      <path
        d="M352.736 73.2896H328.688V55.7305H352.736L347.076 64.1131L352.736 73.2896Z"
        fill="#2951A0"
      />
      <path
        d="M218 196.861C217.985 215.489 217.963 254.966 196.246 289.672C179.289 316.774 155.343 330.974 145.3 336.929C144.149 337.609 143.197 338.158 142.54 338.532C142.415 338.456 142.304 338.38 142.179 338.303V338.204C141.529 337.838 140.57 337.288 139.419 336.608C129.376 330.654 105.437 316.446 88.4727 289.344C66.7556 254.645 66.7335 215.168 66.7188 196.54C66.7188 195.257 66.7335 194.196 66.7409 193.501C130.623 191.516 142.179 173.301 142.179 173.301C142.179 173.301 142.267 173.469 142.452 173.782C142.511 173.683 142.54 173.629 142.54 173.629C142.54 173.629 154.096 191.845 217.978 193.822C217.993 194.524 218 195.578 218 196.861Z"
        fill="url(#paint38_linear_10350_122411)"
      />
      <g filter="url(#filter3_d_10350_122411)">
        <path
          d="M217.998 196.861C217.983 215.489 217.961 254.966 196.244 289.672C179.287 316.774 155.341 330.974 145.298 336.929C144.147 337.609 143.195 338.158 142.538 338.532C142.413 338.456 142.302 338.38 142.177 338.303V338.204C141.527 337.838 140.568 337.288 139.417 336.608C139.247 336.509 139.07 336.402 138.885 336.295C149.475 329.997 172.38 315.858 188.762 289.672C210.479 254.966 210.501 215.489 210.515 196.861C210.515 195.578 210.515 194.524 210.493 193.822C163.886 192.379 145.128 182.294 138.398 176.95C141.232 174.782 142.169 173.301 142.169 173.301C142.169 173.301 142.258 173.469 142.442 173.782C142.501 173.683 142.531 173.629 142.531 173.629C142.531 173.629 154.087 191.845 217.968 193.822C217.983 194.524 217.991 195.578 217.991 196.861H217.998Z"
          fill="url(#paint39_linear_10350_122411)"
        />
      </g>
      <path
        d="M142.116 303.849C166.52 303.849 186.303 281.632 186.303 254.225C186.303 226.819 166.52 204.602 142.116 204.602C117.713 204.602 97.9297 226.819 97.9297 254.225C97.9297 281.632 117.713 303.849 142.116 303.849Z"
        fill="url(#paint40_linear_10350_122411)"
      />
      <path
        d="M135.046 303.849C159.45 303.849 179.233 281.632 179.233 254.225C179.233 226.819 159.45 204.602 135.046 204.602C110.642 204.602 90.8594 226.819 90.8594 254.225C90.8594 281.632 110.642 303.849 135.046 303.849Z"
        fill="#2951A0"
      />
      <path
        d="M134.542 295.342C154.903 295.342 171.409 276.806 171.409 253.94C171.409 231.075 154.903 212.539 134.542 212.539C114.181 212.539 97.6758 231.075 97.6758 253.94C97.6758 276.806 114.181 295.342 134.542 295.342Z"
        fill="white"
      />
      <g filter="url(#filter4_d_10350_122411)">
        <path
          d="M134.543 254.102C140.717 254.102 145.722 248.479 145.722 241.543C145.722 234.607 140.717 228.984 134.543 228.984C128.369 228.984 123.363 234.607 123.363 241.543C123.363 248.479 128.369 254.102 134.543 254.102Z"
          fill="#2951A0"
        />
        <path
          d="M116.187 259.916L116.903 259.16C119.346 256.58 122.821 255.725 126.039 256.778C128.083 257.45 130.525 257.977 133.292 258.076C137.189 258.214 140.554 257.443 143.188 256.488C146.427 255.313 149.984 256.29 152.449 258.924C152.766 259.26 153.076 259.595 153.393 259.924C158.817 265.688 156.308 275.956 149.01 277.735C144.656 278.804 139.668 279.514 134.163 279.46C129.204 279.407 124.681 278.75 120.681 277.781C113.317 276.002 110.712 265.718 116.195 259.916H116.187Z"
          fill="#2951A0"
        />
      </g>
      <g filter="url(#filter5_bd_10350_122411)">
        <path
          d="M160.409 0L196.11 14.0702C196.11 14.0702 160.114 39.3096 160.409 64.1978L130.523 50.9063C130.523 50.9063 133.431 14.0626 160.409 0Z"
          fill="url(#paint41_linear_10350_122411)"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M172.981 11.1647C174.184 10.4776 185.658 15.9973 185.658 15.9973C185.658 15.9973 175.387 23.4332 173.579 27.2427L163.609 21.9979C163.609 21.9979 168.03 13.9512 172.981 11.157V11.1723V11.1647Z"
        fill="url(#paint42_linear_10350_122411)"
      />
      <path
        d="M158.801 5.59375L169.781 10.3729"
        stroke="url(#paint43_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.352 8.55859L166.487 14.1852"
        stroke="url(#paint44_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.793 12.3633L163.312 17.9975"
        stroke="url(#paint45_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.078 19.168L170.297 30.36"
        stroke="url(#paint46_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.121 22.4844L168.159 34.5238"
        stroke="url(#paint47_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.852 26.3789L165.557 38.113"
        stroke="url(#paint48_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.734 30.957L163.307 42.1262"
        stroke="url(#paint49_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.785 35.0586L160.919 46.2125"
        stroke="url(#paint50_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.059 40.6484L159.192 51.8023"
        stroke="url(#paint51_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M209.118 247.301L202.277 263.86C202.277 263.86 220.054 282.305 228.525 282.167C228.525 282.167 230.687 270.303 233.3 268.761C233.3 268.761 210.21 252.385 209.118 247.301Z"
        fill="url(#paint52_linear_10350_122411)"
      />
      <path
        d="M204.898 263.077L212.558 269.948L214.705 264.581C213.776 264.459 211.82 262.405 211.097 261.802C209.156 260.191 208.049 258.825 207.393 257.848C206.566 259.588 205.732 261.337 204.898 263.077Z"
        fill="url(#paint53_linear_10350_122411)"
      />
      <path
        d="M208.082 257.115L210.303 252.496"
        stroke="url(#paint54_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M210.062 258.732L212.284 254.121"
        stroke="url(#paint55_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M214.23 262.123L216.459 257.512"
        stroke="url(#paint56_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M214.383 270.528L218.973 261.016"
        stroke="url(#paint57_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M216.859 272.294L221.442 262.781"
        stroke="url(#paint58_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M219.246 274.059L223.829 264.547"
        stroke="url(#paint59_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M222.121 275.852L226.704 266.34"
        stroke="url(#paint60_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M211.91 260.424L214.139 255.812"
        stroke="url(#paint61_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M253.149 279.914L242.242 292.266C242.242 292.266 259.104 309.55 268.128 309.55L277.699 294.724C277.699 294.724 269.073 296.885 253.149 279.898V279.914Z"
        fill="url(#paint62_linear_10350_122411)"
      />
      <path
        d="M245.98 292.11L249.692 287.445L256.577 293.034L253.153 297.507L245.98 292.102V292.11Z"
        fill="url(#paint63_linear_10350_122411)"
      />
      <path
        d="M250.855 286.542L253.637 283.152"
        stroke="url(#paint64_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.738 288.241L255.528 284.844"
        stroke="url(#paint65_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M254.684 289.94L257.458 286.543"
        stroke="url(#paint66_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M256.664 291.636L259.439 288.246"
        stroke="url(#paint67_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M255.527 298.595L261.564 290.785"
        stroke="url(#paint68_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.051 300.771L264.087 292.961"
        stroke="url(#paint69_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M260.781 302.49L266.817 294.688"
        stroke="url(#paint70_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M263.457 304.185L269.493 296.375"
        stroke="url(#paint71_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M293.038 301.699L286.094 312.7C286.094 312.7 297.591 321.892 309.811 319.403L314.843 309.074C314.843 309.074 303.693 311.12 293.038 301.699Z"
        fill="url(#paint72_linear_10350_122411)"
      />
      <path
        d="M290.949 312.801L293.686 308.633L300.918 312.801L299.487 317.275L290.941 312.801H290.949Z"
        fill="url(#paint73_linear_10350_122411)"
      />
      <path
        d="M294.73 307.389L296.42 304.816"
        stroke="url(#paint74_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M297.035 308.682L298.71 306.109"
        stroke="url(#paint75_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M299.5 309.967L301.19 307.395"
        stroke="url(#paint76_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M301.57 311.252L303.245 308.68"
        stroke="url(#paint77_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M301.992 317.318L305.623 310.684"
        stroke="url(#paint78_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M304.074 318.478L307.697 311.852"
        stroke="url(#paint79_linear_10350_122411)"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M103.078 191.719L104.694 198.177"
        stroke="url(#paint80_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter6_d_10350_122411)">
        <path
          d="M136.114 111.535L123.784 118.444C123.784 118.444 103.838 96.3962 136.808 76.875C136.808 76.875 147.072 81.616 149.965 81.7534C152.23 81.8679 126.957 94.9991 136.114 111.535Z"
          fill="url(#paint81_linear_10350_122411)"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M129.237 113.242L123.887 116.196C123.887 116.196 118.138 106.6 120.131 102.515L125.606 101.324C125.606 101.324 125.075 107.6 129.251 113.249L129.237 113.242Z"
        fill="url(#paint82_linear_10350_122411)"
      />
      <path
        d="M134.021 110.547C133.799 110.707 130.383 111.959 130.383 111.959"
        stroke="url(#paint83_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.646 104.062C131.424 104.223 128.008 105.475 128.008 105.475"
        stroke="url(#paint84_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.372 101.383C131.151 101.543 127.734 102.795 127.734 102.795"
        stroke="url(#paint85_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.927 97.8164C132.706 97.9767 120.25 100.572 120.25 100.572"
        stroke="url(#paint86_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.838 94.7344C133.616 94.8947 121.16 97.498 121.16 97.498"
        stroke="url(#paint87_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.686 91.9062C136.464 92.0666 124.613 93.2957 124.613 93.2957"
        stroke="url(#paint88_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.534 89.0664C139.312 89.2254 128.066 89.0816 128.066 89.0816"
        stroke="url(#paint89_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.386 86.2456C142.165 86.406 131.531 84.8867 131.531 84.8867"
        stroke="url(#paint90_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.238 83.4091C145.017 83.5694 134.988 80.6836 134.988 80.6836"
        stroke="url(#paint91_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.615 108.27C132.393 108.43 128.977 109.682 128.977 109.682"
        stroke="url(#paint92_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.107 106.188C131.885 106.348 128.469 107.6 128.469 107.6"
        stroke="url(#paint93_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.003 171.864L163.861 154.556C163.861 154.556 135.053 164.756 126.441 139.227C126.441 139.227 130.404 171.512 156.01 171.864H156.003Z"
        fill="url(#paint94_linear_10350_122411)"
      />
      <path
        d="M140.514 154.77C140.514 154.77 136.81 142.593 139.968 135.379C139.968 135.379 133.725 128.523 126.383 129.088L126.434 139.226C126.434 139.226 129.637 151.037 140.521 154.762H140.514V154.77Z"
        fill="url(#paint95_linear_10350_122411)"
      />
      <path
        d="M135.167 155.438L135.152 160.201"
        stroke="url(#paint96_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.871 153.219L132.907 156.517"
        stroke="url(#paint97_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.312 148.078L136.176 148.467"
        stroke="url(#paint98_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.141 144.715L136.044 145.768"
        stroke="url(#paint99_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.383 142.102L136.279 143.155"
        stroke="url(#paint100_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.371 139.008L136.547 140.535"
        stroke="url(#paint101_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.555 136.074L136.716 137.601"
        stroke="url(#paint102_linear_10350_122411)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.647 166.028L158.414 158.737C158.414 158.737 147.139 161.829 142.217 158.348L141.316 163.203C141.316 163.203 147.832 169.624 155.639 166.036L155.647 166.028Z"
        fill="url(#paint103_linear_10350_122411)"
      />
      <path
        d="M173.508 30.3601C174.608 30.3601 175.5 29.3524 175.5 28.1156C175.5 26.8788 174.608 25.8711 173.508 25.8711C172.409 25.8711 171.516 26.8788 171.516 28.1156C171.516 29.3524 172.409 30.3601 173.508 30.3601Z"
        fill="url(#paint104_radial_10350_122411)"
      />
      <path
        d="M384.116 51.8126C388.142 46.8632 394.772 43.8659 401.402 44.0046C408.041 44.1434 414.514 47.4183 418.292 52.5434C420.759 55.8924 422.545 60.2774 426.678 61.4986C430.98 62.766 436.248 59.9444 439.928 62.3959C442.681 64.2276 442.977 68.1131 445.513 70.1854C447.377 71.7026 450.061 71.9339 452.518 71.8043C454.984 71.6748 457.48 71.2493 459.897 71.7488C462.314 72.2484 464.663 74.0061 464.742 76.3189V81H384.116C382.745 79.5291 378.71 74.8202 378.266 67.6968C377.723 58.973 382.972 53.0338 384.116 51.8034V51.8126Z"
        fill="url(#paint105_linear_10350_122411)"
      />
      <g filter="url(#filter7_i_10350_122411)">
        <path
          d="M106.169 287.637C102.361 282.424 96.0882 279.273 89.8032 279.427C83.5182 279.576 77.3876 283.024 73.8114 288.411C71.4768 291.927 69.7838 296.552 65.869 297.84C61.7938 299.177 56.8098 296.205 53.3227 298.781C50.7149 300.706 50.4416 304.799 48.0298 306.984C46.2654 308.582 43.7288 308.823 41.3942 308.681C39.0596 308.538 36.6953 308.099 34.4082 308.625C32.1211 309.151 29.8935 311.002 29.8281 313.435V318.357H106.163C107.458 316.81 111.278 311.857 111.7 304.365C112.217 295.184 107.244 288.931 106.163 287.637H106.169Z"
          fill="url(#paint106_linear_10350_122411)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_10350_122411"
          x="0"
          y="0"
          width="483"
          height="251"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10350_122411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10350_122411"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_bd_10350_122411"
          x="168.38"
          y="55.45"
          width="211.08"
          height="273.87"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_10350_122411"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="14" dy="6" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_10350_122411"
            result="effect2_dropShadow_10350_122411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10350_122411"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_10350_122411"
          x="351.988"
          y="100.773"
          width="32.0508"
          height="25.5586"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.49 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10350_122411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10350_122411"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_10350_122411"
          x="137.398"
          y="170.301"
          width="87.5977"
          height="173.23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10350_122411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10350_122411"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_10350_122411"
          x="109.262"
          y="228.984"
          width="51.0547"
          height="58.4805"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10350_122411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10350_122411"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_bd_10350_122411"
          x="104.223"
          y="-26.3"
          width="118.186"
          height="116.799"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="13.15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_10350_122411"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_10350_122411"
            result="effect2_dropShadow_10350_122411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10350_122411"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_10350_122411"
          x="113.805"
          y="76.875"
          width="40.3047"
          height="49.5703"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.37 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10350_122411"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10350_122411"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_i_10350_122411"
          x="29.8281"
          y="279.422"
          width="81.9102"
          height="42.9336"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_10350_122411" />
        </filter>
        <linearGradient
          id="paint0_linear_10350_122411"
          x1="247.782"
          y1="168.646"
          x2="246.847"
          y2="439.538"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.15" stopColor="#2951A0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10350_122411"
          x1="245.782"
          y1="288.158"
          x2="214.644"
          y2="-61.5734"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05" stopColor="#B7CAEA" />
          <stop offset="0.27" stopColor="#D1DDF1" />
          <stop offset="0.55" stopColor="#EAEFF9" />
          <stop offset="0.8" stopColor="#F9FBFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10350_122411"
          x1="240.884"
          y1="233.432"
          x2="294.503"
          y2="-159.711"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05" stopColor="#B7CAEA" stopOpacity="0.02" />
          <stop offset="0.27" stopColor="#D1DDF1" />
          <stop offset="0.55" stopColor="#EAEFF9" />
          <stop offset="0.8" stopColor="#F9FBFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10350_122411"
          x1="295.903"
          y1="412.16"
          x2="16.0678"
          y2="-912.313"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#5C371F" />
          <stop offset="0.24" stopColor="#9C572F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_10350_122411"
          x1="289.419"
          y1="383.672"
          x2="40.0573"
          y2="-796.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7CAEA" />
          <stop offset="0.27" stopColor="#D1DDF1" />
          <stop offset="0.55" stopColor="#EAEFF9" />
          <stop offset="0.8" stopColor="#F9FBFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_10350_122411"
          x1="333.111"
          y1="130.927"
          x2="190.181"
          y2="787.437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_10350_122411"
          x1="248.159"
          y1="239.001"
          x2="298.057"
          y2="239.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="0.99" stopColor="#C7D6EE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_10350_122411"
          x1="259.323"
          y1="240.842"
          x2="286.626"
          y2="240.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="0.99" stopColor="#C7D6EE" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_10350_122411"
          x1="345.961"
          y1="193.047"
          x2="338.421"
          y2="212.676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_10350_122411"
          x1="391.672"
          y1="302.626"
          x2="383.66"
          y2="299.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_10350_122411"
          x1="419.71"
          y1="300.243"
          x2="411.047"
          y2="300.243"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_10350_122411"
          x1="397.455"
          y1="237.367"
          x2="332.381"
          y2="395.636"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#7F4C2F" />
          <stop offset="0.68" stopColor="#EA7126" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_10350_122411"
          x1="359.591"
          y1="197.326"
          x2="379.018"
          y2="185.911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_10350_122411"
          x1="371.914"
          y1="200.019"
          x2="386.461"
          y2="179.031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_10350_122411"
          x1="419.748"
          y1="181.703"
          x2="379.645"
          y2="222.254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_10350_122411"
          x1="377.833"
          y1="182.265"
          x2="391.023"
          y2="206.77"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_10350_122411"
          x1="396.889"
          y1="153.548"
          x2="394.67"
          y2="190.528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.44" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_10350_122411"
          x1="401.505"
          y1="144.728"
          x2="390.077"
          y2="163.512"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#EA7126" stopOpacity="0.44" />
          <stop offset="0.43" stopColor="#F09A64" stopOpacity="0.43" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_10350_122411"
          x1="392.44"
          y1="150.314"
          x2="387.264"
          y2="157.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.29" stopColor="#EA7126" stopOpacity="0.7" />
          <stop offset="0.46" stopColor="#F1A372" stopOpacity="0.59" />
          <stop offset="0.66" stopColor="#F9D0B7" stopOpacity="0.49" />
          <stop offset="0.85" stopColor="#FDECE1" stopOpacity="0.43" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_10350_122411"
          x1="398.382"
          y1="149.382"
          x2="390.287"
          y2="160.437"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.29" stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.48" stopColor="#F2A779" stopOpacity="0.52" />
          <stop offset="0.67" stopColor="#F9D2BA" stopOpacity="0.46" />
          <stop offset="0.85" stopColor="#FDECE2" stopOpacity="0.42" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_10350_122411"
          x1="400.343"
          y1="159.41"
          x2="403.455"
          y2="154.704"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.29" stopColor="#EA7126" stopOpacity="0.44" />
          <stop offset="0.3" stopColor="#EA752D" stopOpacity="0.44" />
          <stop offset="0.5" stopColor="#F3AD81" stopOpacity="0.43" />
          <stop offset="0.69" stopColor="#F9D5BE" stopOpacity="0.42" />
          <stop offset="0.86" stopColor="#FDEDE3" stopOpacity="0.41" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_10350_122411"
          x1="397.325"
          y1="161.81"
          x2="384.313"
          y2="156.881"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.29" stopColor="#EA7126" stopOpacity="0.7" />
          <stop offset="0.46" stopColor="#F1A372" stopOpacity="0.59" />
          <stop offset="0.66" stopColor="#F9D0B7" stopOpacity="0.49" />
          <stop offset="0.85" stopColor="#FDECE1" stopOpacity="0.43" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_10350_122411"
          x1="399.52"
          y1="156.72"
          x2="381.946"
          y2="165.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.29" stopColor="#EA7126" stopOpacity="0.7" />
          <stop offset="0.46" stopColor="#F1A372" stopOpacity="0.59" />
          <stop offset="0.66" stopColor="#F9D0B7" stopOpacity="0.49" />
          <stop offset="0.85" stopColor="#FDECE1" stopOpacity="0.43" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_10350_122411"
          x1="390.162"
          y1="181.144"
          x2="396.385"
          y2="186.865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_10350_122411"
          x1="400.999"
          y1="206.583"
          x2="400.778"
          y2="215.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_10350_122411"
          x1="413.776"
          y1="211.378"
          x2="389.1"
          y2="227.509"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_10350_122411"
          x1="378.549"
          y1="178.371"
          x2="386.509"
          y2="185.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_10350_122411"
          x1="441.488"
          y1="180.953"
          x2="386.683"
          y2="224.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_10350_122411"
          x1="339.785"
          y1="205.474"
          x2="356.788"
          y2="198.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_10350_122411"
          x1="439.467"
          y1="230.829"
          x2="410.572"
          y2="259.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04" stopColor="#5A392A" />
          <stop offset="0.98" stopColor="#231812" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_10350_122411"
          x1="422.1"
          y1="231.591"
          x2="417.857"
          y2="231.591"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.35" stopColor="#EA7126" stopOpacity="0.44" />
          <stop offset="0.58" stopColor="#F09A64" stopOpacity="0.43" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_10350_122411"
          x1="424.372"
          y1="218.106"
          x2="422.099"
          y2="239.948"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.35" stopColor="#EA7126" stopOpacity="0.44" />
          <stop offset="0.58" stopColor="#F09A64" stopOpacity="0.43" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_10350_122411"
          x1="445.477"
          y1="163.44"
          x2="402.854"
          y2="217.753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_10350_122411"
          x1="431.342"
          y1="209.82"
          x2="410.702"
          y2="199.964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_10350_122411"
          x1="392.299"
          y1="205.524"
          x2="389.463"
          y2="215.123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D35917" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_10350_122411"
          x1="392.354"
          y1="211.954"
          x2="389.518"
          y2="221.545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D35917" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_10350_122411"
          x1="365.738"
          y1="194.415"
          x2="341.435"
          y2="205.334"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.35" stopColor="#EA7126" stopOpacity="0.44" />
          <stop offset="0.58" stopColor="#F09A64" stopOpacity="0.43" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_10350_122411"
          x1="368.284"
          y1="188.97"
          x2="334.952"
          y2="207.028"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.35" stopColor="#EA7126" stopOpacity="0.44" />
          <stop offset="0.58" stopColor="#F09A64" stopOpacity="0.43" />
          <stop offset="1" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_10350_122411"
          x1="137.242"
          y1="203.342"
          x2="203.342"
          y2="363.077"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05" stopColor="#E97025" />
          <stop offset="0.84" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_10350_122411"
          x1="109.656"
          y1="337.578"
          x2="219.174"
          y2="152.893"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05" stopColor="#E97025" />
          <stop offset="0.84" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_10350_122411"
          x1="216.971"
          y1="387.69"
          x2="81.2641"
          y2="161.626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#244F92" />
          <stop offset="1" stopColor="#172439" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_10350_122411"
          x1="199.652"
          y1="-19.1624"
          x2="104.815"
          y2="89.8634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_10350_122411"
          x1="179.091"
          y1="13.9818"
          x2="152.162"
          y2="35.8456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_10350_122411"
          x1="166.645"
          y1="5.94493"
          x2="161.806"
          y2="9.85973"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_10350_122411"
          x1="163.114"
          y1="9.45945"
          x2="158.586"
          y2="13.1326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_10350_122411"
          x1="159.866"
          y1="13.1725"
          x2="155.125"
          y2="17.0294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_10350_122411"
          x1="163.294"
          y1="21.1911"
          x2="154.844"
          y2="28.0538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_10350_122411"
          x1="160.802"
          y1="24.8892"
          x2="152.261"
          y2="31.8253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_10350_122411"
          x1="158.348"
          y1="28.6463"
          x2="149.844"
          y2="35.5531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_10350_122411"
          x1="156.268"
          y1="32.8504"
          x2="147.56"
          y2="39.9183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_10350_122411"
          x1="153.746"
          y1="36.8221"
          x2="144.705"
          y2="44.1613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_10350_122411"
          x1="152.02"
          y1="42.4044"
          x2="142.985"
          y2="49.7438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_10350_122411"
          x1="227.352"
          y1="254.225"
          x2="204.035"
          y2="281.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_10350_122411"
          x1="201.888"
          y1="257.008"
          x2="227.488"
          y2="277.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_10350_122411"
          x1="208.481"
          y1="254.206"
          x2="209.936"
          y2="255.364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_10350_122411"
          x1="210.468"
          y1="255.824"
          x2="211.916"
          y2="256.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_10350_122411"
          x1="214.636"
          y1="259.222"
          x2="216.084"
          y2="260.372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_10350_122411"
          x1="215.519"
          y1="264.779"
          x2="217.907"
          y2="266.685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_10350_122411"
          x1="217.981"
          y1="266.545"
          x2="220.376"
          y2="268.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_10350_122411"
          x1="220.375"
          y1="268.311"
          x2="222.771"
          y2="270.216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_10350_122411"
          x1="223.25"
          y1="270.111"
          x2="225.645"
          y2="272.009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_10350_122411"
          x1="212.316"
          y1="257.523"
          x2="213.764"
          y2="258.673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_10350_122411"
          x1="272.74"
          y1="279.303"
          x2="243.661"
          y2="312.737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_10350_122411"
          x1="247.161"
          y1="286.491"
          x2="264.06"
          y2="309.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_10350_122411"
          x1="251.719"
          y1="284.076"
          x2="252.815"
          y2="285.583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_10350_122411"
          x1="253.609"
          y1="285.775"
          x2="254.697"
          y2="287.275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_10350_122411"
          x1="255.547"
          y1="287.482"
          x2="256.643"
          y2="288.981"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_10350_122411"
          x1="257.527"
          y1="289.162"
          x2="258.616"
          y2="290.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_10350_122411"
          x1="257.557"
          y1="293.228"
          x2="259.626"
          y2="296.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_10350_122411"
          x1="260.08"
          y1="295.396"
          x2="262.149"
          y2="298.254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_10350_122411"
          x1="262.811"
          y1="297.131"
          x2="264.879"
          y2="299.988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_10350_122411"
          x1="265.486"
          y1="298.826"
          x2="267.555"
          y2="301.668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint72_linear_10350_122411"
          x1="312.961"
          y1="296.355"
          x2="284.028"
          y2="329.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint73_linear_10350_122411"
          x1="290.949"
          y1="312.954"
          x2="300.925"
          y2="312.954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint74_linear_10350_122411"
          x1="294.384"
          y1="306.107"
          x2="296.76"
          y2="306.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint75_linear_10350_122411"
          x1="296.688"
          y1="307.4"
          x2="299.064"
          y2="307.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint76_linear_10350_122411"
          x1="299.153"
          y1="308.685"
          x2="301.529"
          y2="308.685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint77_linear_10350_122411"
          x1="301.223"
          y1="309.97"
          x2="303.592"
          y2="309.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint78_linear_10350_122411"
          x1="301.645"
          y1="314.005"
          x2="305.962"
          y2="314.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint79_linear_10350_122411"
          x1="303.735"
          y1="315.165"
          x2="308.052"
          y2="315.165"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint80_linear_10350_122411"
          x1="102.482"
          y1="197.145"
          x2="105.385"
          y2="192.843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint81_linear_10350_122411"
          x1="138.578"
          y1="131.097"
          x2="128.081"
          y2="50.0704"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint82_linear_10350_122411"
          x1="128.276"
          y1="105.278"
          x2="120.198"
          y2="110.753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint83_linear_10350_122411"
          x1="134.028"
          y1="109.933"
          x2="130.304"
          y2="112.457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint84_linear_10350_122411"
          x1="131.646"
          y1="103.448"
          x2="127.922"
          y2="105.972"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint85_linear_10350_122411"
          x1="131.374"
          y1="100.773"
          x2="127.65"
          y2="103.297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint86_linear_10350_122411"
          x1="131.745"
          y1="95.4573"
          x2="121.197"
          y2="102.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint87_linear_10350_122411"
          x1="132.657"
          y1="92.3748"
          x2="122.103"
          y2="99.5284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint88_linear_10350_122411"
          x1="135.288"
          y1="89.2396"
          x2="125.802"
          y2="95.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint89_linear_10350_122411"
          x1="137.93"
          y1="86.1086"
          x2="129.533"
          y2="91.8481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint90_linear_10350_122411"
          x1="140.568"
          y1="82.9502"
          x2="133.188"
          y2="87.9523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint91_linear_10350_122411"
          x1="143.214"
          y1="79.8055"
          x2="136.878"
          y2="84.1001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint92_linear_10350_122411"
          x1="132.615"
          y1="107.665"
          x2="128.898"
          y2="110.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint93_linear_10350_122411"
          x1="132.104"
          y1="105.583"
          x2="128.38"
          y2="108.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA7126" stopOpacity="0.6" />
          <stop offset="0.88" stopColor="#FFF6F0" stopOpacity="0.41" />
        </linearGradient>
        <linearGradient
          id="paint94_linear_10350_122411"
          x1="127.08"
          y1="167.447"
          x2="169.73"
          y2="133.919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint95_linear_10350_122411"
          x1="124.785"
          y1="149.219"
          x2="148.485"
          y2="130.601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint96_linear_10350_122411"
          x1="133.781"
          y1="159.007"
          x2="136.665"
          y2="156.805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint97_linear_10350_122411"
          x1="131.854"
          y1="155.762"
          x2="134.003"
          y2="154.121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint98_linear_10350_122411"
          x1="133.697"
          y1="149.166"
          x2="135.846"
          y2="147.467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint99_linear_10350_122411"
          x1="131.279"
          y1="146.771"
          x2="134.988"
          y2="143.854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint100_linear_10350_122411"
          x1="131.521"
          y1="144.149"
          x2="135.228"
          y2="141.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint101_linear_10350_122411"
          x1="130.084"
          y1="141.773"
          x2="134.962"
          y2="137.939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint102_linear_10350_122411"
          x1="130.256"
          y1="138.844"
          x2="135.128"
          y2="135.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint103_linear_10350_122411"
          x1="157.513"
          y1="163.631"
          x2="141.777"
          y2="160.724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint104_radial_10350_122411"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(168.329 24.4091) scale(17.8806 20.1892)"
        >
          <stop offset="0.16" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#E97535" />
        </radialGradient>
        <linearGradient
          id="paint105_linear_10350_122411"
          x1="316.203"
          y1="-41.746"
          x2="543.403"
          y2="156.526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4D5EF" />
          <stop offset="1" stopColor="#2951A0" />
        </linearGradient>
        <linearGradient
          id="paint106_linear_10350_122411"
          x1="65.7858"
          y1="382.108"
          x2="76.7567"
          y2="233.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.41" stopColor="#C4D5EF" />
          <stop offset="1" stopColor="#CCDBF2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
