import { BrowseDocumentsFilter, organizationLinkTemplates } from "../../link-templates";
import { useCallback, useState } from "react";
import { ComboBoxItemType } from "@prodoctivity/design-system";
import type { HttpGetDocumentsRequest } from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { useAllDocumentTypes } from "../hooks";

const browseDocumentsPageLengthOptions: Array<
  HttpGetDocumentsRequest["queryParameters"]["rowsPerPage"]
> = ["15", "30", "100", "500"];

export function useBrowseDocumentsList(filter: BrowseDocumentsFilter) {
  const organizationNavigate = useOrganizationNavigate();
  const { getDocuments } = useServices();
  const { moment } = useAppTranslation();

  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<ComboBoxItemType[]>([]);

  const {
    allDocumentTypes: documentTypeData,
    isLoading,
    isRefetching,
  } = useAllDocumentTypes({
    onSuccess(data) {
      const filteredDocumentTypes = data.documentTypes.filter((dt) =>
        filter.documentTypeIdList?.includes(dt.documentTypeId)
      );
      setSelectedDocumentTypes(
        filteredDocumentTypes.map((dt) => ({
          label: dt.name,
          value: dt.documentTypeId,
        }))
      );
    },
  });

  const documentTypeIsLoading = isLoading || isRefetching;

  const [documentTypeComboBoxInputValue, setDocumentTypeComboBoxInputValue] = useState("");

  const getFilteredDocuments = useCallback(async () => {
    const dateEnd = filter.dateTo ? moment(filter.dateTo).endOf("day").valueOf() : undefined;

    try {
      return await getDocuments(
        filter.documentTypeIdList && filter.documentTypeIdList.length
          ? filter.documentTypeIdList
          : undefined,
        filter.updatedBy,
        filter.dateFrom,
        dateEnd,
        filter.pageNumber,
        filter.rowsPerPage,
        filter.sortField,
        filter.sortDirection
      );
    } catch (error) {
      console.log(error);
    }
  }, [
    filter.dateFrom,
    filter.dateTo,
    filter.documentTypeIdList,
    filter.pageNumber,
    filter.rowsPerPage,
    filter.sortDirection,
    filter.sortField,
    filter.updatedBy,
    getDocuments,
    moment,
  ]);

  const handleFilterChange = useCallback(
    (filterType: keyof typeof filter, value: { value?: string | number }) => {
      organizationNavigate(
        organizationLinkTemplates.browseDocuments({ ...filter, [filterType]: value.value })
      );
    },
    [organizationNavigate, filter]
  );
  const onSelectDocumentType = useCallback(
    ({
      item,
    }: {
      event: React.SyntheticEvent<HTMLInputElement, Event>;
      item: ComboBoxItemType;
    }) => {
      const isAlreadySelected = selectedDocumentTypes.some(
        (selectedItem) => selectedItem.value === item.value
      );

      if (!isAlreadySelected) {
        const newSelectedDocumentTypes = [...selectedDocumentTypes, item];
        setSelectedDocumentTypes(newSelectedDocumentTypes);
        setDocumentTypeComboBoxInputValue("");
        const documentTypeIds = newSelectedDocumentTypes.map((selectedItem) => selectedItem.value);

        organizationNavigate(
          organizationLinkTemplates.browseDocuments({
            ...filter,
            documentTypeIdList: documentTypeIds,
          })
        );
      }
    },
    [organizationNavigate, selectedDocumentTypes, filter]
  );

  const documentTypeHandleClick = useCallback(
    (index: number) => {
      const updatedSelectedDocumentTypes = selectedDocumentTypes.filter((_, i) => i !== index);
      setSelectedDocumentTypes(updatedSelectedDocumentTypes);

      const documentTypeIds = updatedSelectedDocumentTypes.map(
        (selectedItem) => selectedItem.value
      );

      organizationNavigate(
        organizationLinkTemplates.browseDocuments({
          ...filter,
          documentTypeIdList: documentTypeIds,
        })
      );

      setDocumentTypeComboBoxInputValue("");
    },
    [organizationNavigate, selectedDocumentTypes, filter]
  );

  const setRowsPerPage = useCallback(
    (rpp: HttpGetDocumentsRequest["queryParameters"]["rowsPerPage"]) => {
      organizationNavigate(
        organizationLinkTemplates.browseDocuments({ ...filter, rowsPerPage: rpp })
      );
    },
    [organizationNavigate, filter]
  );

  const clearAll = useCallback(() => {
    setSelectedDocumentTypes([]);
    setDocumentTypeComboBoxInputValue("");
    organizationNavigate(
      organizationLinkTemplates.browseDocuments({
        pageNumber: 0,
        rowsPerPage: "15",
        dateFrom: undefined,
        dateTo: undefined,
        documentTypeIdList: undefined,
        sortField: undefined,
        sortDirection: undefined,
        updatedBy: undefined,
      })
    );
  }, [organizationNavigate]);

  const changeSort = useCallback(
    (
      field: "documentType" | "name" | "updatedBy" | "updatedAt" | undefined,
      direction: "ASC" | "DESC" | undefined
    ) => {
      organizationNavigate(
        organizationLinkTemplates.browseDocuments({
          ...filter,
          sortField: field,
          sortDirection: field === undefined ? undefined : direction,
        })
      );
    },
    [filter, organizationNavigate]
  );

  const handleMoveTo = useCallback(
    (documentId: string) => {
      organizationNavigate(`/documents/${documentId}`);
    },
    [organizationNavigate]
  );

  const stringifiedFilterValues = JSON.stringify(filter);

  const {
    data: documentsData,
    isLoading: documentsIsLoading,
    refetch: refetchDocuments,
    remove,
  } = useOrganizationQuery(`documents/${stringifiedFilterValues}`, getFilteredDocuments, {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const refetch = useCallback(() => {
    remove();
    refetchDocuments();
  }, [refetchDocuments, remove]);

  //pagination

  const isNextButtonDisabled =
    !documentsData || documentsData.requestedPageLength !== documentsData.pageLength;
  const isPreviousButtonDisabled = !documentsData || documentsData.pageNumber < 1;
  const totalRowCount = documentsData ? documentsData.totalRowCount : undefined;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    organizationNavigate(
      organizationLinkTemplates.browseDocuments({ ...filter, pageNumber: filter.pageNumber - 1 })
    );
  }, [organizationNavigate, filter, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    organizationNavigate(
      organizationLinkTemplates.browseDocuments({ ...filter, pageNumber: filter.pageNumber + 1 })
    );
  }, [organizationNavigate, filter, isNextButtonDisabled]);

  //end of pagination

  return {
    documentTypes: documentTypeData?.documentTypes || [],
    documentTypeIsLoading,
    documentsData: documentsData?.documents || [],
    documentsIsLoading,
    onSelectDocumentType,
    setSelectedDocumentTypes,
    selectedDocumentTypes,
    currentPage: filter.pageNumber,
    previousPage,
    nextPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    rowsPerPage: filter.rowsPerPage,
    pageLengthOptions: browseDocumentsPageLengthOptions,
    totalRowCount,
    documentTypeHandleClick,
    documentTypeComboBoxInputValue,
    setDocumentTypeComboBoxInputValue,
    clearAll,
    handleMoveTo,
    handleFilterChange,
    setRowsPerPage,
    changeSort,
    refetch,
  };
}
