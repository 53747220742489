import { Box, TapArea, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { RefreshIconSvg } from "../svg/RefreshIconSvg";

type Props = {
  marginTop?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  marginStart?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  accessibilityLabel: string;
  refetch(): void;
  size?: number;
};

export const ResultsPageRefreshButton: FunctionComponent<Props> = ({
  marginStart,
  accessibilityLabel,
  refetch,
  size,
}) => {
  const { colors } = useColors();
  const actualMarginStart = marginStart || 0;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginStart={actualMarginStart}
      flex="shrink"
      marginTop={2}
    >
      <TapArea accessibilityLabel={accessibilityLabel} onTap={refetch}>
        <RefreshIconSvg
          height={size ? size : 20}
          width={size ? size : 20}
          color={colors.neutral900}
        />
      </TapArea>
    </Box>
  );
};
