import { Box, Checkbox, Text, useColors } from "@prodoctivity/design-system";

import { FC, useEffect } from "react";

export const CheckboxLine: FC<{
  onClick: (data: { value: string; checkState: boolean }) => void;
  value: boolean;
  label: string;
  checkAll: () => void;
  type: "default" | "value";
  defaults: Array<string>;
}> = ({ onClick, value, label, checkAll, type, defaults }) => {
  const { colors } = useColors();

  useEffect(() => {
    if (defaults.includes(label)) {
      onClick({ value: label, checkState: true });
    }
  }, [label, defaults, onClick]);

  return (
    <Box>
      <Box display="flex" flex="grow" gap={2} paddingTop={1} paddingBottom={1}>
        <Box>
          <Checkbox
            id={label}
            mode={"default"}
            checked={value}
            onChange={() =>
              type === "default" ? checkAll() : onClick({ value: label, checkState: !value })
            }
            label={""}
          />
        </Box>
        <Box>
          <Text color={colors.black600} ellipsisLength={25}>
            {label}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
