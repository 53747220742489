import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const GridSmallIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 21,
  height = 21,
  color: rawColor,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7319 0.658388C20.2586 0.658388 20.6866 1.08634 20.6866 1.61305V19.0406C20.6866 19.5673 20.2586 19.9953 19.7319 19.9953H1.61305C1.08634 19.9953 0.658388 19.5673 0.658388 19.0406V1.61305C0.658388 1.08634 1.08634 0.658388 1.61305 0.658388H19.7253M19.7253 0H1.61305C0.724227 0 0 0.724227 0 1.61305V19.0406C0 19.9294 0.724227 20.6536 1.61305 20.6536H19.7253C20.6141 20.6536 21.3384 19.9294 21.3384 19.0406V1.61305C21.3384 0.724227 20.6141 0 19.7253 0Z"
        fill={calculateColor(color)}
      />
      <path
        d="M6.84092 2.36328H3.10128C2.85765 2.36328 2.66016 2.56078 2.66016 2.8044V6.54405C2.66016 6.78767 2.85765 6.98517 3.10128 6.98517H6.84092C7.08455 6.98517 7.28204 6.78767 7.28204 6.54405V2.8044C7.28204 2.56078 7.08455 2.36328 6.84092 2.36328Z"
        fill={calculateColor(color)}
      />
      <path
        d="M12.5401 2.36328H8.8005C8.55687 2.36328 8.35938 2.56078 8.35938 2.8044V6.54405C8.35938 6.78767 8.55687 6.98517 8.8005 6.98517H12.5401C12.7838 6.98517 12.9813 6.78767 12.9813 6.54405V2.8044C12.9813 2.56078 12.7838 2.36328 12.5401 2.36328Z"
        fill={calculateColor(color)}
      />
      <path
        d="M18.2433 2.36328H14.5036C14.26 2.36328 14.0625 2.56078 14.0625 2.8044V6.54405C14.0625 6.78767 14.26 6.98517 14.5036 6.98517H18.2433C18.4869 6.98517 18.6844 6.78767 18.6844 6.54405V2.8044C18.6844 2.56078 18.4869 2.36328 18.2433 2.36328Z"
        fill={calculateColor(color)}
      />
      <path
        d="M6.84092 7.97852H3.10128C2.85765 7.97852 2.66016 8.17601 2.66016 8.41964V12.1593C2.66016 12.4029 2.85765 12.6004 3.10128 12.6004H6.84092C7.08455 12.6004 7.28204 12.4029 7.28204 12.1593V8.41964C7.28204 8.17601 7.08455 7.97852 6.84092 7.97852Z"
        fill={calculateColor(color)}
      />
      <path
        d="M12.5401 7.97852H8.8005C8.55687 7.97852 8.35938 8.17601 8.35938 8.41964V12.1593C8.35938 12.4029 8.55687 12.6004 8.8005 12.6004H12.5401C12.7838 12.6004 12.9813 12.4029 12.9813 12.1593V8.41964C12.9813 8.17601 12.7838 7.97852 12.5401 7.97852Z"
        fill={calculateColor(color)}
      />
      <path
        d="M18.2433 7.97852H14.5036C14.26 7.97852 14.0625 8.17601 14.0625 8.41964V12.1593C14.0625 12.4029 14.26 12.6004 14.5036 12.6004H18.2433C18.4869 12.6004 18.6844 12.4029 18.6844 12.1593V8.41964C18.6844 8.17601 18.4869 7.97852 18.2433 7.97852Z"
        fill={calculateColor(color)}
      />
      <path
        d="M6.84092 13.4629H3.10128C2.85765 13.4629 2.66016 13.6604 2.66016 13.904V17.6437C2.66016 17.8873 2.85765 18.0848 3.10128 18.0848H6.84092C7.08455 18.0848 7.28204 17.8873 7.28204 17.6437V13.904C7.28204 13.6604 7.08455 13.4629 6.84092 13.4629Z"
        fill={calculateColor(color)}
      />
      <path
        d="M12.5401 13.4629H8.8005C8.55687 13.4629 8.35938 13.6604 8.35938 13.904V17.6437C8.35938 17.8873 8.55687 18.0848 8.8005 18.0848H12.5401C12.7838 18.0848 12.9813 17.8873 12.9813 17.6437V13.904C12.9813 13.6604 12.7838 13.4629 12.5401 13.4629Z"
        fill={calculateColor(color)}
      />
      <path
        d="M18.2433 13.4629H14.5036C14.26 13.4629 14.0625 13.6604 14.0625 13.904V17.6437C14.0625 17.8873 14.26 18.0848 14.5036 18.0848H18.2433C18.4869 18.0848 18.6844 17.8873 18.6844 17.6437V13.904C18.6844 13.6604 18.4869 13.4629 18.2433 13.4629Z"
        fill={calculateColor(color)}
      />
    </svg>
  );
};
