// import type { GetRecentSearchListResponse } from "@prodoctivity/types/model/recent-searches";
import { useCallback, useMemo, useState } from "react";
import { useServices } from "../../hooks/useServices";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import type { PaginationQueryRequestFilter } from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { DocumentSearchFilter, organizationLinkTemplates } from "../../link-templates";
import { FieldCondition } from "../../components/OpenSearch/hooks";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useDesignBreakpoint } from "@prodoctivity/design-system";

export type RecentSearchesDataType = {
  _id: string;
  category: "document" | "collection" | "template";
  inputSearch: string;
  searchType: "search" | "suggestion";
  documentId: string | undefined;
  createdAt: number;
};

export type RecentSearchesFitler = {
  rowsPerPage: "15" | "30" | "50";
  pageNumber: number;
  sort: "asc" | "desc";
};

export type SearchSortField = { [key: string]: string | undefined };

export function useRecentSearchLogic() {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const { breakpoint } = useDesignBreakpoint();

  const { getRecentSearchList: getRecentSearches, deleteRecentSearches } = useServices();

  const breakPointDuo = breakpoint === "small" || breakpoint === "medium";

  const [showMobileLeftPanel, setShowMobileLeftPanel] = useState<boolean>(false);
  const [isToggleSelect, setIsToggleSelect] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [recentSearchFilter, setRecentSearchFilter] = useState<PaginationQueryRequestFilter>({
    rowsPerPage: "15",
    pageNumber: 0,
    filter: undefined,
    order: "desc",
  });
  const [recentSearchDeleteList, setRecentSearchDeleteList] = useState<string[]>([]);
  const [filterByFields, setFilters] = useState<FieldCondition[]>([]);

  const documentListPageLengthOptions: Array<PaginationQueryRequestFilter["rowsPerPage"]> = [
    "15",
    "30",
    "50",
  ];

  const leftSidebarToggle = () => {
    setShowMobileLeftPanel(!showMobileLeftPanel);
  };

  const selectToggle = useCallback(() => {
    setIsToggleSelect(() => !isToggleSelect);
  }, [setIsToggleSelect, isToggleSelect]);

  const getRecentSearchList = useCallback(() => {
    return getRecentSearches(recentSearchFilter);
  }, [getRecentSearches, recentSearchFilter]);

  const { isLoading, data, refetch } = useOrganizationQuery(
    `recent-searches/${JSON.stringify(recentSearchFilter)}`,
    getRecentSearchList,
    {
      refetchOnWindowFocus: false,
    }
  );

  const isNextButtonDisabled = useMemo(
    () => !data || data.requestedPageLength !== data.pageLength,
    [data]
  );
  const isPreviousButtonDisabled = useMemo(() => !data || data.pageNumber < 1, [data]);

  const handleCheckboxChange = useCallback(
    (id: string, isChecked: boolean) => {
      const updatedList = [...recentSearchDeleteList];
      const index = updatedList.indexOf(id);

      if (isChecked && index === -1) {
        updatedList.push(id);
      } else if (!isChecked && index !== -1) {
        updatedList.splice(index, 1);
      }

      setRecentSearchDeleteList(updatedList);
    },
    [recentSearchDeleteList, setRecentSearchDeleteList]
  );

  const sortRecenSearchList = useCallback(
    (sortValue: PaginationQueryRequestFilter["order"]) => {
      setRecentSearchFilter({
        rowsPerPage: "15",
        pageNumber: 0,
        filter: undefined,
        order: sortValue,
      });
    },
    [setRecentSearchFilter]
  );

  const sortByLabel = useCallback(() => {
    switch (recentSearchFilter.order) {
      case "asc": {
        return resources.oldest;
      }
      case "desc": {
        return resources.mostRecent;
      }
      default: {
        return resources.withoutSorting;
      }
    }
  }, [recentSearchFilter.order, resources.mostRecent, resources.oldest, resources.withoutSorting]);

  const onSortClick = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setRecentSearchFilter((prev) => ({
      ...prev,
      pageNumber: prev.pageNumber + 1,
    }));
  }, [isNextButtonDisabled, setRecentSearchFilter]);

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled || recentSearchFilter.pageNumber === 0) {
      return;
    }

    setRecentSearchFilter((prev) => ({
      ...prev,
      pageNumber: prev.pageNumber - 1,
    }));
  }, [isPreviousButtonDisabled, recentSearchFilter.pageNumber]);

  const setPageLength = useCallback(
    (value: RecentSearchesFitler["rowsPerPage"]) => {
      setRecentSearchFilter((prev) => ({
        rowsPerPage: value,
        pageNumber: 0,
        filter: undefined,
        order: prev.order === "asc" ? "desc" : "asc",
      }));
    },
    [setRecentSearchFilter]
  );

  const handleRecentSearchDelele = useCallback(
    async (recentSearhId: string) => {
      await deleteRecentSearches([recentSearhId]);
      refetch();
    },
    [deleteRecentSearches, refetch]
  );

  const handleMultipleRecentSearchDelete = async () => {
    if (recentSearchDeleteList.length !== 0) {
      await deleteRecentSearches(recentSearchDeleteList);
    }
    refetch();
    setRecentSearchDeleteList([]);
  };

  const buildSearchUrl = useCallback(
    (
      searchQuery: string,
      category: RecentSearchesDataType["category"],
      searchType: RecentSearchesDataType["searchType"],
      documentId?: string,
      filter?: DocumentSearchFilter,
      specificFilter?: string,
      index?: number
    ) => {
      if (searchType === "suggestion") {
        if (documentId && category === "document") {
          return organizationNavigate(organizationLinkTemplates.documentId(documentId, "data"));
        } else if (documentId && category === "collection") {
          console.log(organizationLinkTemplates.documentCollection(documentId, "data"));
          return organizationNavigate(
            organizationLinkTemplates.documentCollection(documentId, "data")
          );
        } else {
          if (documentId) {
            return organizationNavigate(
              organizationLinkTemplates.combinedTemplateDetails(documentId)
            );
          }
        }
      }
      if (category === "collection") {
        return organizationNavigate(
          organizationLinkTemplates.documentCollectionList({
            pageNumber: 0,
            rowsPerPage: "15",
            filter: searchQuery,
            assignedTo: "",
          })
        );
      }

      if (category === "template") {
        return organizationNavigate(organizationLinkTemplates.templateList(false, searchQuery));
      }
      const queryParams: Array<string> = [];

      if (searchQuery) {
        queryParams.push(`q=${encodeURIComponent(searchQuery)}`);
      } else if (filter?.query && specificFilter !== "query") {
        queryParams.push(`q=${encodeURIComponent(filter.query)}`);
      }
      const queryParamsUrl = queryParams.join("&");
      const ObjFieldQueryParams = {
        fieldQueryParams: "",
      };
      if (searchQuery && searchQuery === "document" && !filter) {
        ObjFieldQueryParams.fieldQueryParams = filterByFields
          .filter((f) => f.value !== "" && f.value !== undefined)
          .map((item, index) => {
            if (item.value === undefined) return null;

            let value = item.value.toString();
            const dataType = item.field.properties.dataType;
            const equalityOperator = item.equalityOperator;

            if (
              dataType === "Alphanumeric" &&
              (equalityOperator === "starts-with" || equalityOperator === "ends-with")
            ) {
              value = value.replace(/\*/g, "");
            }

            return `${index === 0 ? "&" : ""}fields[${index}][fld]=${encodeURIComponent(
              item.field.name
            )}&fields[${index}][val]=${value}&fields[${index}][op]=${encodeURIComponent(
              equalityOperator
            )}&fields[${index}][dataType]=${dataType}`;
          })
          .join("&");
      } else if (filter) {
        if (filter.fields.length > 0) {
          ObjFieldQueryParams.fieldQueryParams = filter.fields
            .filter((_f, indexSecondary) => indexSecondary !== index)
            .map((item, index) => {
              if (item.val === undefined) return null;

              let value = item.val.toString();
              const dataType = item.dataType;
              const equalityOperator = item.op;

              if (
                dataType === "Alphanumeric" &&
                (equalityOperator === "starts-with" || equalityOperator === "ends-with")
              ) {
                value = value.replace(/\*/g, "");
              }

              return `${index === 0 ? "&" : ""}fields[${index}][fld]=${encodeURIComponent(
                item.fld
              )}&fields[${index}][val]=${value}&fields[${index}][op]=${encodeURIComponent(
                equalityOperator
              )}&fields[${index}][dataType]=${dataType}`;
            })
            .join("&");
        } else {
          ObjFieldQueryParams.fieldQueryParams = "";
        }
      }
      const totalFieldsToFilterBy = () => {
        if (filterByFields.length > 0) {
          const totalFilters = filterByFields.filter(
            (f) => f.value !== "" && f.value !== undefined
          ).length;
          return totalFilters;
        } else if (filter) {
          const totalFilters = filter.fields.filter(
            (_f, indexSecondary) => indexSecondary !== index
          ).length;
          return totalFilters;
        } else {
          return 0;
        }
      };

      const queryPageNumber = filter ? filter.pageNumber : "0";
      const queryRowsPerPage = filter ? filter.rowsPerPage : "15";
      const paginationQuery = `&pageNumber=${queryPageNumber}&rowsPerPage=${queryRowsPerPage}`;

      const builtQuery = `/search?${
        queryParamsUrl +
        "&" +
        "docTypesSelected=" +
        0 +
        ObjFieldQueryParams.fieldQueryParams +
        "&" +
        "searchCriteria=" +
        totalFieldsToFilterBy() +
        paginationQuery
      }`;

      organizationNavigate(builtQuery);
      return builtQuery;
    },
    [filterByFields, organizationNavigate]
  );

  const searchTypeText = useMemo(() => {
    return {
      document: { type: resources.document },
      collection: { type: resources.collection },
      template: { type: resources.template },
    };
  }, [resources.document, resources.collection, resources.template]);

  return {
    recentSearchList: data?.recentReachList,
    isLoading,
    refetch,
    showMobileLeftPanel,
    leftSidebarToggle,
    sortRecenSearchList,
    isToggleSelect,
    selectToggle,
    handleCheckboxChange,
    isCheckboxChecked,
    setIsCheckboxChecked,
    recentSearchDeleteList,
    handleRecentSearchDelele,
    recentSearchFilter,
    setPageLength,
    nextPage,
    previousPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    pageLengthOptions: documentListPageLengthOptions,
    onSortClick,
    setOpen,
    open,
    sortByLabel,
    sortDirection: recentSearchFilter.order,
    handleMultipleRecentSearchDelete,
    setRecentSearchDeleteList,
    buildSearchUrl,
    setFilters,
    searchTypeText,
    breakPointDuo,
    breakpoint,
  };
}
