import { useEffect, useMemo } from "react";

export type PubSubEventManagerCallback<T> = (args: T) => void;

export type PubSubEventManager<T> = {
  subscribe(callback: PubSubEventManagerCallback<T>): () => void;
  publish(command: T): void;
};

export function usePubSub<T>(): { eventManager: PubSubEventManager<T> } {
  const eventManager = useMemo(() => {
    const eventBag: T[] = [];
    const handlerList: PubSubEventManagerCallback<T>[] = [];

    return {
      subscribe(callback: PubSubEventManagerCallback<T>) {
        if (!handlerList.length) {
          eventBag.forEach((command) => callback(command));
          eventBag.length = 0;
        }
        handlerList.push(callback);
        return () => {
          const foundIndex = handlerList.findIndex((handler) => handler === callback);
          if (foundIndex >= 0) {
            handlerList.splice(foundIndex, 1);
          }
        };
      },
      publish(command: T) {
        if (!handlerList.length) {
          eventBag.push(command);
        } else {
          handlerList.forEach((handler) => handler(command));
        }
      },
    };
  }, []);

  return { eventManager };
}

export function useSubscribe<T>(
  eventManager: PubSubEventManager<T>,
  callback: PubSubEventManagerCallback<T>
) {
  const { subscribe } = eventManager;
  useEffect(() => {
    const unsubscribe = subscribe(callback);

    return unsubscribe;
  }, [subscribe, callback]);
}
