import { useColors } from "@prodoctivity/design-system";
import { useCallback, useRef, useState } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { CascadeOptionsPopoverItem } from "./CascadeOptionsPopover";

export const useCascadeOptionsPopover = (items: CascadeOptionsPopoverItem[]) => {
  const [open, setOpen] = useState(false);
  const { colors } = useColors();

  const { resources } = useAppTranslation();

  const handlePopoverState = () => {
    setOpen(true);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value);
  }, []);

  const filteredOptions = items.filter((option) =>
    option.childLabel?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const anchorRef = useRef(null);
  const newAnchorRefs = useRef<Array<HTMLDivElement | null>>([]);

  return {
    anchorRef,
    newAnchorRefs,
    open,
    setOpen,
    colors,
    resources,
    handlePopoverState,
    searchTerm,
    setSearchTerm,
    handleSearch,
    filteredOptions,
  };
};
