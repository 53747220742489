import {
  Box,
  BoxWithAnimations,
  Divider,
  Grid,
  Skeleton,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { useContext, useEffect, useMemo } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";
import { DashboardItemContext } from "../../../pages/Dashboard/DashboardConfigColumnElement";
import { RenderDocumentCollectionIcon } from "../../../pages/DocumentCollectionInstance/RenderDocumentCollectionIcon";
import { DocumentCheckSvgIcon } from "../../../svg/DocumentCollection/DocumentStatus/DocumentCheckSvgIcon";
import { DocumentExpiredSvgIcon } from "../../../svg/DocumentCollection/DocumentStatus/DocumentExpiredSvgIcon";
import { DocumentMissingSvgIcon } from "../../../svg/DocumentCollection/DocumentStatus/DocumentMissingSvgIcon";
import { DocumentToExpireSvgIcon } from "../../../svg/DocumentCollection/DocumentStatus/DocumentToExpireSvgIcon";
import { EmptyStateWidgetContent } from "../../../svg/EmptyStateWidgetContent";
import { Pagination } from "../../Layout/Pagination";
import { NoResultsFoundBlock } from "../../NoResultsFoundBlock";
import { templateAnimationSet } from "./RecentDocumentCard";
import {
  useRecentDocumentCollectionInstance,
  useRecentDocumentCollectionInstanceView,
} from "./hooks";
import { FancyDateTime } from "@prodoctivity/design-system";

export function RecentDocumentCollectionInstanceView() {
  const {
    RecentDocumentCollection: recentDocumentCollection,
    currentPage,
    rowsPerPage,
    setPageLength,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    previousPage,
    nextPage,
    totalRowCount,
    pageLengthOptions,
    isLoading,
    refetch,
  } = useRecentDocumentCollectionInstanceView();
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  const dashboardItemContext = useContext(DashboardItemContext);

  useEffect(() => {
    const eventEmitter = dashboardItemContext.eventEmitter;
    if (eventEmitter) {
      const handler = () => {
        refetch();
      };
      eventEmitter.on("refresh", handler);
      return () => {
        eventEmitter.off("refresh", handler);
      };
    }
  }, [dashboardItemContext, refetch]);

  return (
    <Box>
      <Box padding={4}>
        {isLoading || recentDocumentCollection.length > 0 ? (
          <Grid
            gridTemplateColumns={["repeat(auto-fill, minmax(265px, 1fr)"]}
            gridTemplateRows={["repeat(auto-fill, 280px)"]}
            gap={4}
          >
            {isLoading ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              (recentDocumentCollection || []).map((item, index) => {
                return (
                  <BoxWithAnimations animationSet={templateAnimationSet} width={"100%"} key={index}>
                    <TapArea>
                      <Box
                        color={colors.white}
                        gap={2}
                        borderStyle="lg"
                        borderRadius={6}
                        borderColor={colors.neutral600}
                      >
                        <DocumentViewIcon instanceId={item.instanceId} />
                        <Box
                          display="flex"
                          gap={2}
                          marginTop={2}
                          width={"100%"}
                          paddingX={3}
                          paddingY={3}
                        >
                          <Box width={"100%"} display="flex" gap={2} justifyContent="end">
                            <Text size="100" color={colors.neutral700}>
                              <FancyDateTime
                                moment={moment}
                                resources={resources}
                                size="100"
                                value={item.instanceUpdatedAt}
                              />
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </TapArea>
                  </BoxWithAnimations>
                );
              })
            )}
          </Grid>
        ) : (
          <NoResultsFoundBlock
            key={`${recentDocumentCollection.length}_not-found`}
            messageTitle={resources.dashboard.noContentFound}
            messageDescription={""}
            illustration={<EmptyStateWidgetContent width={"100%"} height={"100%"} />}
          />
        )}
      </Box>

      <Box display="flex" direction="row" flex="grow" justifyContent="end">
        <Box display="flex" paddingX={5} paddingY={2}>
          <Pagination<typeof rowsPerPage>
            id="recent_document_collection_instance_views_list_pagination_top"
            rowsLabel={resources.collections}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={recentDocumentCollection?.length || 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={pageLengthOptions}
            totalRowCount={totalRowCount}
          />
        </Box>
      </Box>
    </Box>
  );
}

export const DocumentViewIcon = ({
  width = 25,
  height = 25,
  instanceId,
}: {
  width?: number;
  height?: number;
  instanceId: string;
}) => {
  const { resources } = useAppTranslation();
  const { collectionInstance } = useRecentDocumentCollectionInstance(instanceId);
  const organizationNavigate = useOrganizationNavigate();

  const documentStatusCounts = useMemo(() => {
    if (collectionInstance) {
      return [
        {
          key: resources.documents,
          count: collectionInstance.counts?.documentCount,
          Icon: DocumentCheckSvgIcon,
        },
        {
          key: resources.toExpire,
          count: collectionInstance.counts?.toExpireCount,
          Icon: DocumentToExpireSvgIcon,
        },
        {
          key: resources.missing,
          count: collectionInstance.counts?.issueCount,
          Icon: DocumentMissingSvgIcon,
        },
        {
          key: resources.expired,
          count: collectionInstance.counts?.expiredCount,
          Icon: DocumentExpiredSvgIcon,
        },
      ];
    }
  }, [
    collectionInstance,
    resources.documents,
    resources.expired,
    resources.missing,
    resources.toExpire,
  ]);

  return (
    <Box
      display="flex"
      justifyContent="between"
      alignItems="center"
      direction="column"
      gap={3}
      onClickCapture={() =>
        organizationNavigate(organizationLinkTemplates.documentCollection(instanceId))
      }
    >
      <Box width={"100%"}>
        {collectionInstance && collectionInstance.icon && (
          <Box display="flex" justifyContent="between" alignItems="center" padding={3}>
            <Box display="flex" flex="grow">
              <Text weight="bold" overflow="ellipsis" size="200" title={collectionInstance.name}>
                {collectionInstance.name}
              </Text>
            </Box>
            <RenderDocumentCollectionIcon
              width={25}
              height={25}
              iconKey={collectionInstance.icon}
            />
          </Box>
        )}
        <Divider />
      </Box>
      <Box padding={3} width={"100%"}>
        {documentStatusCounts?.map(({ key, count, Icon }, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="between"
            width={"100%"}
            paddingY={1}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Icon width={width} height={height} />
              <Text>{key}</Text>
            </Box>

            <Text>{count}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
