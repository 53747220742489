import {
  Box,
  Button,
  ChevronSvgIcon,
  IconButton,
  MarkdownViewer,
  Skeleton,
  TapArea,
  Text,
  Tooltip,
  popupZIndex,
  useColors,
} from "@prodoctivity/design-system";

import { FunctionComponent, useMemo } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { Page } from "../../components/Layout/Page";
import { OrganizationNavigate } from "../../components/OrganizationNavigate";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";
import { TemplateRow } from "./TemplateRow";
import { useCombinedTemplateDetails } from "./hooks";

const CombinedTemplateDetailsPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const {
    baseUrl,
    combinedTemplateId,
    combinedTemplate,
    isLoading,
    templates,
    sample,
    sampleLoading,
    showSample,
    setShowSample,
    handleButtonClickEdit,
    handleButtonClickGenerate,
  } = useCombinedTemplateDetails();

  const [_, copyToClipboard] = useCopyToClipboard();

  const apiMarkdownText = useMemo(() => {
    return (
      "```" +
      `JSON
${JSON.stringify(sample ? sample.requestSample : "", null, 2)}
` +
      "```"
    );
  }, [sample]);

  if (!combinedTemplateId) {
    return <OrganizationNavigate to={organizationLinkTemplates.home()} />;
  }

  return (
    <Page>
      {/* NAV */}
      <Box
        zIndex={popupZIndex}
        color={colors.neutral400}
        paddingX={6}
        paddingY={5}
        marginTop={12}
        width={"100%"}
        top={true}
        position="fixed"
      >
        <Box margin="auto" maxWidth={1200}>
          <Box wrap={true} display="flex" justifyContent="between">
            <Box display="flex" gap={2} direction="column">
              <Text weight="bold">{combinedTemplate?.name}</Text>
              <Text>{combinedTemplate?.documentGroupName}</Text>
            </Box>
            <Box display="flex" gap={5}>
              <Box>
                <Button
                  onClick={handleButtonClickEdit}
                  color={"gray"}
                  text={resources.combinedTemplates.editCombined}
                />
              </Box>
              <Box>
                <Button
                  onClick={handleButtonClickGenerate}
                  color={"blue"}
                  text={resources.generate}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box direction="column" paddingX={6} display="flex" alignItems="center">
        <Box height={100}></Box>
        <Box width={"100%"} justifyContent="start" maxWidth={1200}>
          <Box display="flex" gap={2} direction="column">
            <Text weight="bold">{resources.description}</Text>
            <Skeleton show={isLoading} height={76}>
              <Text>{combinedTemplate?.description}</Text>
            </Skeleton>
          </Box>
          <Box marginTop={4}>
            <Text weight="bold">{resources.templates}</Text>
            <Box marginTop={2}>
              {combinedTemplate?.templates.map((templateId) => (
                <TemplateRow key={templateId} templateId={templateId} templates={templates} />
              ))}
            </Box>
          </Box>
          <Box>
            <Skeleton show={sampleLoading}>
              <Box flex="grow" borderRadius={6}>
                <Box
                  flex="grow"
                  display="flex"
                  justifyContent="between"
                  alignItems="center"
                  paddingY={2}
                  paddingX={5}
                  color={showSample ? colors.primary700 : colors.neutral300}
                >
                  <TapArea onTap={() => setShowSample((prev) => !prev)}>
                    <Box display="flex" gap={3} alignItems="center">
                      <ChevronSvgIcon
                        color={showSample ? colors.white : undefined}
                        direction={showSample ? "up" : "down"}
                      />
                      <Text weight={"bold"} color={showSample ? colors.white : colors.black600}>
                        {resources.generateAsDeveloper}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
                {showSample && sample && (
                  <Box
                    color={colors.white}
                    paddingY={2}
                    paddingX={3}
                    alignContent="center"
                    alignItems="center"
                    borderRadius={4}
                    overflow="scroll"
                  >
                    <Text weight="bold">
                      {resources.method}: <Text inline>POST</Text>
                    </Text>
                    <Text weight="bold">
                      Endpoint: <Text inline>{baseUrl}/documents/generate</Text>
                    </Text>
                    <Text weight="bold">{resources.body}: </Text>
                    <Tooltip text={resources.copy} idealDirection="down" inline={true}>
                      <IconButton
                        accessibilityLabel={resources.copy}
                        icon="copy"
                        onClick={() => copyToClipboard(apiMarkdownText)}
                      />
                    </Tooltip>

                    <Box
                      color={colors.white}
                      padding={4}
                      alignContent="center"
                      alignItems="center"
                      borderRadius={4}
                    >
                      <MarkdownViewer markdownText={apiMarkdownText} />
                      {/* <pre>{JSON.stringify(sample.requestSample, null, 2)}</pre> */}
                    </Box>
                  </Box>
                )}
              </Box>
            </Skeleton>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default CombinedTemplateDetailsPage;
