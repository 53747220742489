import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const CombinedTemplateSvgIcon: FunctionComponent<Props> = ({
  color,
  width = 37,
  height = 36,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8144 9.71973H14.7031V10.9712H16.6293L18.7357 10.9573H20.7451V25.0152H22.0338V10.9434C22.0338 10.262 21.4934 9.71973 20.8144 9.71973Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M14.6476 26.2806C14.1071 26.2806 13.6637 25.9607 13.4974 25.488C13.4558 25.3906 13.4281 25.2933 13.4281 25.1821V9.71973H1.48277C0.665169 9.71973 0 10.3872 0 11.2076V34.7765C0 35.4578 0.540449 36.0001 1.21948 36.0001H20.8142C21.3547 36.0001 21.7843 35.6664 21.9506 35.1937C22.006 35.1102 22.0337 34.999 22.0337 34.9017V26.2806H14.6476ZM11.003 29.437H2.8824V27.7962H11.003V29.437ZM11.003 25.78H2.8824V24.1253H11.003V25.78ZM11.003 22.123H2.8824V20.4683H11.003V22.123Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M29.3086 1.00098C31.1378 2.82253 32.3296 4.03226 34.0479 5.75648H29.3086V1.00098Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M34.242 6.57706H28.4079L28.4633 1.20973C28.4633 0.528389 27.9367 0 27.2577 0H14.6472C13.9682 0 13.4277 0.528389 13.4277 1.20973V9.71958H14.7026V1.20973L27.2577 1.27926L27.2023 6.64658C27.2023 7.32793 27.7289 7.87022 28.4079 7.87022H34.242L34.1727 25.0707L22.0334 25.0151V26.2804H34.242C34.921 26.2804 35.4614 25.752 35.4614 25.0707V7.78679C35.4614 7.10545 34.921 6.57706 34.242 6.57706Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M20.7445 23.2217V25.0154L18.9707 25.0015L20.7445 23.2217Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M20.7451 18.1465V21.1221L16.8649 25.0016L14.7031 24.9877V24.2091L20.7451 18.1465Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M20.7451 13.543V16.032L14.7031 22.0945V19.6055L20.7451 13.543Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M20.7451 10.9434V11.43L14.7031 17.4926V14.9897L18.7357 10.9573L20.7451 10.9434Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M16.6293 10.957L14.7031 12.8898V10.9709L16.6293 10.957Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M32.5934 10.749H24.459V12.4037H32.5934V10.749Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M32.5798 14.4055V16.0602H24.4453V15.1842C24.4453 15.1842 24.4453 15.1286 24.4453 15.0869V14.3916H32.5798V14.4055Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M32.5934 18.0762H24.459V19.717H32.5934V18.0762Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
    </svg>
  );
};
