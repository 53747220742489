import {
  Box,
  Button,
  Tag,
  Text,
  TextArea,
  TextField,
  useColors,
} from "@prodoctivity/design-system";

import { validateEmail } from "@prodoctivity/shared";
import type { EcmDocument } from "@prodoctivity/types";
import { Page } from "../../components/Layout/Page";
import { NotificationMessage } from "../../components/NotificationMessage";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useSendVialEmail } from "./hooks";

type Props = {
  document: EcmDocument;
};
export function SendViaEmailPage({ document }: Props) {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const {
    handleSubmit,
    setShowToast,
    subject,
    message,
    value,
    setValue,
    tags,
    onKeyDownTagManagement,
    handleTagRemoval,
    isButtonEnabled,
    setEmailValid,
    showToast,
    toastMessage,
    sendingEmail,
    setMessageValue,
  } = useSendVialEmail(document);

  const renderedTags = tags.map((tag) => (
    <Tag
      key={tag.value}
      accessibilityRemoveIconLabel={`Remove ${tag.label} tag`}
      onRemove={() => handleTagRemoval(tag.value)}
      text={tag.label}
    />
  ));

  return (
    <Page>
      <Box>
        <Box position="relative" color={colors.white}>
          <Box padding={4}>
            <Box display="flex" direction="column" gap={3}>
              <Text weight="bold"> {resources.sendViaEmailPage.recipients} </Text>
              <TextField
                autoComplete="off"
                id="recipients-tags"
                tags={renderedTags}
                value={value}
                helperText={resources.sendViaEmailPage.enterRecipientText}
                onChange={(e) => {
                  const isValidEmail: boolean = validateEmail(e.value);
                  setEmailValid(isValidEmail);
                  setValue(e.value);
                }}
                onKeyDown={(e) => {
                  const { keyCode, currentTarget } = e.event;
                  onKeyDownTagManagement(e.value, keyCode, currentTarget.selectionEnd);
                }}
              />
              <Box display="flex" direction="column" gap={3}>
                <Text weight="bold"> {resources.sendViaEmailPage.subject} </Text>
                <Text weight="normal"> {subject} </Text>
              </Box>
              <Box display="flex" direction="column" gap={3}>
                <Text weight="bold"> {resources.sendViaEmailPage.message} </Text>
                <Box width={"350px"}>
                  <TextArea
                    id="message-tags"
                    value={message}
                    onChange={(e) => {
                      setMessageValue(e.value);
                    }}
                  />
                </Box>
              </Box>
              <Box display="flex" direction="column" gap={2}>
                {sendingEmail ? (
                  <Box>
                    <Text>{resources.sending}</Text>
                  </Box>
                ) : (
                  <Button
                    color="blue"
                    text={resources.sendViaEmailPage.sendViaEmail}
                    disabled={!isButtonEnabled}
                    size="sm"
                    onClick={handleSubmit}
                    accessibilityLabel={resources.sendViaEmailPage.sendViaEmail}
                  />
                )}
              </Box>
              <Box>
                {showToast.show && (
                  <NotificationMessage
                    type={showToast.isError ? "error" : "success"}
                    message={toastMessage}
                    position="bottom-left"
                    handleDismiss={() => setShowToast({ show: false, isError: false })}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}
