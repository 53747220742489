import {
  Box,
  Checkbox,
  ComboBox,
  Icon,
  Label,
  SelectList,
  Spinner,
  Text,
  TextArea,
  TextField,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type {
  DataElement,
  DictionaryList,
  SequenceResponse,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useMemo, useState } from "react";

import {
  DictionaryDataType$Schema,
  dictionaryDataTypes,
  isValueListContextField,
  sanitizeFieldName,
  toDataType,
} from "@prodoctivity/shared";

import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../../../hooks/useOrganizationQuery";
import { useServices } from "../../../../hooks/useServices";
import { ContextFieldEditorProps } from "../../DocumentType/FormDesigner/hooks";
import { DataListItems } from "./DataListItems";
import { DictionaryListControl } from "./DictionaryListControl";

type GeneralTabProps = {
  element: DataElement;
  isUpdating: boolean;
  dictionaryLists: DictionaryList<string>[];
  classChildCount: number | undefined;
  sequenceList: {
    sequences: SequenceResponse[];
  };
  onChange: (dataElement: DataElement) => void;
  showAddDataElementModule: boolean | undefined;
  elementIsClassDataElement: boolean;
  setElementIsClassDataElement: (value: boolean) => void;
  // onListChange: (property: keyof DataList, value: string) => void;
  setShowPopoverComboBox: (n: boolean) => void;
  showPopoverComboBox: boolean;
  isPanelHidden: boolean;
  contextFieldState: ContextFieldEditorProps | undefined;
  updateValueLists: (
    index: number,
    k: {
      value: string;
      label: string;
      parentValue?: string | undefined;
    }
  ) => void;
};

type showableDataElement = {
  id: string;
} & DataElement;
export const GeneralTab: FunctionComponent<GeneralTabProps> = ({
  element,
  onChange,
  isUpdating,
  classChildCount,
  dictionaryLists,
  showAddDataElementModule,
  showPopoverComboBox,
  setShowPopoverComboBox,
  isPanelHidden,
  contextFieldState,
  updateValueLists,
  elementIsClassDataElement: isClassDataElement,
  setElementIsClassDataElement: setIsClassDataElement,
  sequenceList,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { peekSequenceValue } = useServices();
  const handleDataElementComboBoxClear = useCallback(() => {
    const { classDataElementId: _, ...rest } = element;
    const x: Record<string, unknown> = rest;
    delete x.classDataElementId;
    onChange({
      ...rest,
      classDataElementId: undefined,
    });
    setIsClassDataElement(true);
  }, [element, onChange, setIsClassDataElement]);
  const { getPaginatedOrganizationDataElements } = useServices();
  const showWarningMessage: boolean =
    isUpdating && !isClassDataElement && typeof classChildCount === "number" && classChildCount > 0;

  const getDataElements = useCallback(async () => {
    let pageNumber = 0;
    const requestedPageLength = "100";
    let pageLength = 100;

    const result: showableDataElement[] = [];
    do {
      const currentPage = await getPaginatedOrganizationDataElements(
        pageNumber,
        requestedPageLength,
        undefined,
        true
      );
      (currentPage.dataElements || []).forEach((de) => {
        result.push(de);
      });
      pageLength = currentPage.pageLength;
      pageNumber += 1;
    } while (pageLength >= parseInt(requestedPageLength));
    result.sort((a, b) => a.name.localeCompare(b.name));
    const elementsMap = result.reduce((acc: Record<string, showableDataElement>, next) => {
      if (!acc[next.name]) {
        acc[next.name] = next;
      }
      return acc;
    }, {});
    return Object.values(elementsMap);
  }, [getPaginatedOrganizationDataElements]);

  const { data: dataElements, isLoading } = useOrganizationQuery(`/dataElements`, getDataElements, {
    staleTime: 10 * 60 * 1000,
    refetchOnMount: "always",
    refetchOnWindowFocus: true,
  });
  const [dictionaryListName, setDictionaryListName] = useState("");
  const [showValueLists, setShowValueLists] = useState<boolean>(true);

  const selectedDictionaryListName = useMemo(() => {
    if (!isValueListContextField(element)) {
      return undefined;
    }

    if (element.dictionaryListId) {
      const foundList = dictionaryLists.find((list) => list._id === element.dictionaryListId);
      if (foundList) {
        return foundList.listSchema.name;
      }
    }

    if (element.dictionaryListName) {
      const foundList = dictionaryLists.find(
        (list) => list.listSchema.name === element.dictionaryListName
      );

      if (foundList) {
        return foundList.listSchema.name;
      }
    }
    return undefined;
  }, [dictionaryLists, element]);

  const { breakpoint } = useDesignBreakpoint();
  const isSequence =
    element.dataType === "Alphanumeric" &&
    (element.inputType === "Default" || element.inputType === "TextBox");

  const peekedSequence = useCallback(
    async (sequenceName: string | undefined) => {
      if (isSequence && sequenceName && !element.defaultValue && !element.sampleValue) {
        let sequenceValueResponse;
        try {
          sequenceValueResponse = await peekSequenceValue(sequenceName);
        } catch (error) {
          onChange({
            ...element,
            defaultValue: [],
            sampleValue: [],
            isUnique: true,
          });
          return;
        }
        const sequenceValue = sequenceValueResponse.sequenceValue;
        onChange({
          ...element,
          defaultValue: [sequenceValue],
          sampleValue: [sequenceValue],
        });
        return sequenceValue;
      }

      return {
        sequenceValue: undefined,
      };
    },
    [peekSequenceValue, onChange, element, isSequence]
  );

  const { isLoading: peekSequenceIsLoading } = useOrganizationQuery(
    `/sequences/${isSequence ? element.sequenceId : undefined}`,
    () => peekedSequence(isSequence && element.sequenceId ? element.sequenceId : undefined),
    {}
  );

  const selectedSequence = useMemo(() => {
    if (isSequence) {
      const foundSequence = sequenceList.sequences.find(
        (sequence) => sequence.name === element.sequenceId
      );
      if (foundSequence) {
        return foundSequence.name;
      }
    }

    return undefined;
  }, [sequenceList, element, isSequence]);
  const [SequenceInputValue, setSequenceInputValue] = useState<string | undefined>(
    selectedSequence
  );

  return (
    <Box display="flex" direction="column" paddingY={4} paddingX={4} width={"100%"}>
      {/* Form Start */}
      <Box
        display="flex"
        direction={breakpoint === "small" ? "column" : "row"}
        paddingX={4}
        paddingY={4}
        gap={8}
      >
        <Box display="flex" direction="column" flexValue={1} gap={2}>
          <Box display="flex" direction="column" flex="shrink">
            <Box minWidth={120}>
              <Label htmlFor="elementName">
                <Text color={colors.subtle}>{resources.name}</Text>
              </Label>
            </Box>
            <Box minWidth={260}>
              <TextField
                onChange={({ value }) => {
                  onChange({
                    ...element,
                    name: sanitizeFieldName(value, true),
                    humanName: value,
                    label: element.label === element.humanName ? value : element.label,
                  });
                }}
                id="elementName"
                value={element.humanName}
                maxLength={{
                  characterCount: 100,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
                disabled={contextFieldState?.isFieldDisabledAndHidden}
              />
            </Box>
          </Box>
          <Box display="flex" direction="column" flex="shrink">
            <Box minWidth={120}>
              <Label htmlFor="elementLabel">
                <Text color={colors.subtle}>{resources.label}</Text>
              </Label>
            </Box>
            <Box minWidth={260}>
              <TextField
                onChange={({ value }) => {
                  onChange({ ...element, label: value });
                }}
                id="elementLabel"
                value={element.label}
                maxLength={{
                  characterCount: 100,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
              />
            </Box>
          </Box>
          {/* {element &&
          element.alternativeQuestions &&
          element.alternativeQuestions.length > 0 &&
          !showAddDataElementModule
            ? element.alternativeQuestions.map((question, index) => {
                return (
                  <Box
                    alignItems="center"
                    display="flex"
                    marginBottom={4}
                    key={`${element.name}-question-${index}`}
                  >
                    <Box minWidth={120}>
                      <Label htmlFor={`${element.name}-question-${index}-text`}>
                        <Text color={colors.subtle}>
                          {resources.question} {index + 1}
                        </Text>
                      </Label>
                    </Box>
                    <Box minWidth={260} display="flex" alignItems="center">
                      <Box flex="grow">
                        <TextField
                          onChange={({ value }) => {
                            onChange({
                              ...element,
                              alternativeQuestions: [
                                ...element.alternativeQuestions.slice(0, index),
                                value,
                                ...element.alternativeQuestions.slice(index + 1),
                              ],
                            });
                          }}
                          id={`${element.name}-question-${index}-text`}
                          value={question}
                          maxLength={{
                            characterCount: 150,
                            errorAccessibilityLabel: resources.dataDictionary.limitReached,
                          }}
                        />
                      </Box>
                      <Box marginStart={2}>
                        <Icon
                          color={colors.subtle}
                          icon="trash"
                          accessibilityLabel="Remove Question"
                          onClick={() =>
                            onChange({
                              ...element,
                              alternativeQuestions: [
                                ...element.alternativeQuestions.slice(0, index),
                                ...element.alternativeQuestions.slice(index + 1),
                              ],
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })
            : null}
          {!showAddDataElementModule && (
            <Box alignItems="center" display="flex" marginBottom={4}>
              <Box minWidth={120}>
                <Label htmlFor="elementAltQuestions">
                  <Text color={colors.subtle}>{resources.dataDictionary.altQuestionFull}</Text>
                </Label>
              </Box>
              <Box flex="grow" />
              <Box minWidth={260}>
                <Box width={180} height={48}>
                  <Button
                    onClick={() => {
                      const newAlternativeQuestions = [...element.alternativeQuestions];
                      newAlternativeQuestions.push("");
                      onChange({ ...element, alternativeQuestions: newAlternativeQuestions });
                    }}
                    text={resources.add}
                    color="gray"
                    iconEnd="add-circle"
                  />
                </Box>
              </Box>
            </Box>
          )} */}
          <Box display="flex" direction="column" flex="shrink">
            <Box minWidth={120}>
              <Label htmlFor="elementDescription">
                <Text color={colors.subtle}>{resources.description}</Text>
              </Label>
            </Box>
            <Box minWidth={260}>
              <TextArea
                onChange={({ value }) => {
                  onChange({ ...element, description: value });
                }}
                id="elementDescription"
                value={element.description}
                maxLength={{
                  characterCount: 9999,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
              />
            </Box>
          </Box>
          <Box display="flex" direction="column" flex="shrink">
            <Box minWidth={120}>
              <Label htmlFor="elementDataType">
                <Text color={colors.subtle}>{resources.dataType}</Text>
              </Label>
            </Box>
            <Box minWidth={260}>
              {peekSequenceIsLoading ? (
                <Spinner show={true} />
              ) : (
                <SelectList
                  id="elementDataType"
                  disabled={isUpdating}
                  onChange={({ value }) => {
                    const parseResponse = DictionaryDataType$Schema.safeParse(value);
                    if (!parseResponse.success) {
                      return;
                    }
                    const dt = toDataType(parseResponse.data);
                    let list: string | undefined;
                    let listId: string | undefined;
                    let sequenceId: string | undefined;
                    if (value === "List") {
                      if (dictionaryLists && dictionaryLists.length > 0) {
                        list = dictionaryLists[0].listSchema.name;
                        listId = dictionaryLists[0]._id;
                      }
                    }
                    if (value === "Sequence") {
                      if (sequenceList.sequences && sequenceList.sequences.length > 0) {
                        sequenceId = sequenceList.sequences[0].name;
                      }
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const vl: any =
                      value !== "List"
                        ? {
                            valueList: undefined,
                          }
                        : {};
                    onChange({
                      ...element,
                      dataType: dt,
                      dictionaryDataType: parseResponse.data,
                      dictionaryListName: list,
                      dictionaryListId: listId,
                      sequenceId: sequenceId,
                      sampleValue: !isSequence ? element.sampleValue : undefined,
                      defaultValue: !isSequence ? element.defaultValue : undefined,

                      ...vl,
                    });
                  }}
                  options={dictionaryDataTypes
                    .filter((dt) => dt !== "None")
                    .map((dt) => {
                      const dataTypeValuesKeys =
                        dt.toLowerCase() as keyof typeof resources.dataTypeValues;
                      const key = dt.toLowerCase() as keyof typeof resources;
                      return {
                        key: dt,
                        label: String(
                          resources.dataTypeValues[dataTypeValuesKeys] || resources[key]
                        ),
                        value: dt,
                      };
                    })}
                  value={element.dictionaryDataType}
                />
              )}
            </Box>
          </Box>
          {element.dictionaryDataType === "Alphanumeric" ? (
            <Box display="flex" direction="column" flex="shrink">
              <Box minWidth={120}>
                <Label htmlFor="sequences-select-list">
                  <Text color={colors.subtle}>{resources.sequence}</Text>
                </Label>
              </Box>
              <Box minWidth={260}>
                {peekSequenceIsLoading ? (
                  <Spinner show={true} />
                ) : (
                  <ComboBox
                    id="sequences-select-list"
                    onChange={({ value }) => {
                      setSequenceInputValue(value);
                    }}
                    options={(sequenceList.sequences ? sequenceList.sequences : [])
                      .filter((dl) => dl.name.includes(SequenceInputValue || ""))
                      .map((dl) => {
                        return {
                          key: dl.name,
                          label: dl.name,
                          value: dl.name,
                        };
                      })}
                    inputValue={SequenceInputValue}
                    onSelect={(event) => {
                      const sequence =
                        event.item.value.trim() !== "" ? event.item.value : undefined;
                      setSequenceInputValue(sequence);
                      if (isSequence) {
                        onChange({
                          ...element,
                          dataType: "Alphanumeric",
                          inputType: element.inputType,
                          sequenceId: sequence,
                          sampleValue: undefined,
                          defaultValue: undefined,
                          minOccurs: 1,
                          maxOccurs: 1,
                        });
                      }
                    }}
                    onClear={() => {
                      setSequenceInputValue(undefined);
                      if (isSequence) {
                        onChange({
                          ...element,
                          dataType: "Alphanumeric",
                          inputType: element.inputType,
                          sequenceId: undefined,
                          sampleValue: undefined,
                          defaultValue: undefined,
                          minOccurs: 0,
                          maxOccurs: 0,
                        });
                      }
                    }}
                    onBlur={() => {
                      setSequenceInputValue(selectedSequence);
                    }}
                    label=""
                  />
                )}
              </Box>
            </Box>
          ) : null}
          <DictionaryListControl
            isUpdating={isUpdating}
            dictionaryLists={dictionaryLists}
            onChange={onChange}
            element={element}
            selectedDictionaryListName={selectedDictionaryListName}
            setDictionaryListName={setDictionaryListName}
            setShowPopoverComboBox={setShowPopoverComboBox}
            setLists={setShowValueLists}
            isSynchronizationNeeded={isPanelHidden}
          />
        </Box>
        <Box direction="column" width={"50%"}>
          <Box display="flex" direction="column" flex="shrink">
            <Box minWidth={120}>
              <Label htmlFor="elementInstructions">
                <Text color={colors.subtle}>{resources.instructions}</Text>
              </Label>
            </Box>
            <Box minWidth={260}>
              <TextArea
                onChange={({ value }) => {
                  onChange({ ...element, instructions: value });
                }}
                id="elementInstructions"
                value={element.instructions}
                maxLength={{
                  characterCount: 300,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
              />
            </Box>
          </Box>
          <Box display="flex" direction="column" flex="shrink">
            <Box minWidth={120}>
              <Label htmlFor="elementTopic">
                <Text color={colors.subtle}>{resources.topic}</Text>
              </Label>
            </Box>
            <Box minWidth={260}>
              <TextField
                onChange={({ value }) => {
                  onChange({ ...element, sectionName: value });
                }}
                id="elementTopic"
                value={element.sectionName || "General"}
                maxLength={{
                  characterCount: 100,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
              />
            </Box>
          </Box>
          <Box display="flex" direction="column" flex="shrink">
            <Box minWidth={120}>
              <Label htmlFor="listType">
                <Text color={colors.subtle}>{resources.language}</Text>
              </Label>
            </Box>
            <Box minWidth={260}>
              <SelectList
                id="language"
                size="md"
                onChange={({ value }) => {
                  onChange({ ...element, cultureLanguageName: value });
                }}
                options={[
                  { label: "English", value: "en" },
                  { label: "Español", value: "es" },
                  { label: "Português", value: "pt" },
                  { label: "Nederlands", value: "nl" },
                ]}
                value={element.cultureLanguageName}
              />
            </Box>
          </Box>

          <Box display="flex" direction="column" flexValue={1} gap={2}>
            <Box padding={1} />
            {!showAddDataElementModule && (
              <Box display="flex" direction="column" flex="shrink">
                <Box minWidth={383}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={element.isReferenceField}
                      id="isReference"
                      label={resources.dataDictionary.referenceField}
                      onChange={({ checked }) => {
                        onChange({ ...element, isReferenceField: checked });
                      }}
                    />
                    <Box marginStart={3}>
                      <Tooltip text={resources.dataDictionary.referenceFieldTooltip}>
                        <Icon
                          icon="circle-info"
                          accessibilityLabel={resources.dataDictionary.referenceFieldTooltip}
                          color={colors.subtle}
                          size="xs"
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Box display="flex" direction="column" flex="shrink">
              <Box display="flex">
                <Box display="flex" direction="column" width={"50%"} minHeight={120} gap={2}>
                  <Box>
                    <Checkbox
                      checked={element.minOccurs !== undefined && element.minOccurs > 0}
                      id="isRequired"
                      label={resources.required}
                      onChange={({ checked }) => {
                        if (checked) {
                          if (element.dataType === "Logical") {
                            onChange({
                              ...element,
                              minOccurs: 1,
                              maxOccurs: 1,
                            });
                          } else {
                            onChange({
                              ...element,
                              minOccurs:
                                !element.minOccurs || element.minOccurs <= 0
                                  ? 1
                                  : element.minOccurs,
                              maxOccurs: !element.maxOccurs ? 1 : element.maxOccurs,
                            });
                          }
                        } else {
                          if (element.dataType === "Logical") {
                            onChange({
                              ...element,
                              minOccurs: 0,
                              maxOccurs: 0,
                            });
                          } else {
                            onChange({
                              ...element,
                              minOccurs: 0,
                            });
                          }
                        }
                      }}
                    />
                  </Box>

                  {!showAddDataElementModule && (
                    <>
                      <Box>
                        <Checkbox
                          checked={element.unique}
                          id="isUnique"
                          label={resources.unique}
                          onChange={({ checked }) => {
                            onChange({ ...element, unique: checked });
                          }}
                        />
                      </Box>
                      <Box>
                        {!element.sequenceId && (
                          <Checkbox
                            checked={element.readOnly}
                            id="isReadOnly"
                            label={resources.readOnly}
                            onChange={({ checked }) => {
                              onChange({ ...element, readOnly: checked });
                            }}
                          />
                        )}
                      </Box>
                    </>
                  )}
                </Box>
                {!showAddDataElementModule && (
                  <Box display="flex" direction="column" width={"50%"} minHeight={120}>
                    <Box marginBottom={2}>
                      <Checkbox
                        checked={element.invisible}
                        id="isInvisible"
                        label={resources.dataDictionary.notVisibleInDocument}
                        onChange={({ checked }) => {
                          onChange({ ...element, invisible: checked });
                        }}
                      />
                    </Box>
                    {element.dataType === "Alphanumeric" &&
                    element.inputType === "TextBox" &&
                    !element.sequenceId &&
                    !element.dictionaryListName ? (
                      <Box marginBottom={2}>
                        <Checkbox
                          checked={
                            element.dataType === "Alphanumeric" &&
                            element.inputType === "TextBox" &&
                            element.autoCompleteValues
                          }
                          id="isAutocomplete"
                          label={resources.autocomplete}
                          onChange={({ checked }) => {
                            onChange({ ...element, autoCompleteValues: checked });
                          }}
                        />
                      </Box>
                    ) : null}
                    {!showAddDataElementModule && (
                      <Box alignItems="center" display="flex" marginBottom={6}>
                        <Box minWidth={383}>
                          <Box display="flex" alignItems="center">
                            <Checkbox
                              checked={element.immutable}
                              id="isImmutable"
                              label={resources.immutable}
                              onChange={({ checked }) => {
                                onChange({ ...element, immutable: checked });
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            {isLoading ? (
              <Spinner show={true} />
            ) : (
              <>
                <Checkbox
                  checked={isClassDataElement}
                  id=""
                  label={resources.partOfClassDataElement}
                  onChange={({ checked }) => {
                    setIsClassDataElement(checked);
                    if (checked === false) {
                      const { classDataElementId: _, ...rest } = element;
                      const x: Record<string, unknown> = rest;
                      delete x.classDataElementId;
                      onChange({
                        ...rest,
                        classDataElementId: undefined,
                      });
                    }
                  }}
                />
                {isClassDataElement && dataElements && (
                  <Box direction="column" display={"flex"} gap={4}>
                    <ComboBox
                      id="class_data_element_id"
                      onClear={handleDataElementComboBoxClear}
                      label={resources.classDataElement}
                      accessibilityClearButtonLabel={resources.clear}
                      noResultText={resources.dataDictionary.noItems}
                      selectedOption={dataElements
                        .map((dataElement) => ({
                          label: dataElement.name,
                          value: dataElement.id,
                        }))
                        .find((option) => option.value === element.classDataElementId)}
                      inputValue={
                        dataElements
                          .map((dataElement) => ({
                            label: dataElement.name,
                            value: dataElement.id,
                          }))
                          .find((option) => option.value === element.classDataElementId)?.label
                      }
                      options={dataElements
                        .map((element) => ({
                          label: element.name,
                          value: element.id,
                        }))
                        .filter((de) => de.label !== element.name)}
                      onSelect={(event) => {
                        const val = event.item.value;
                        if (val) {
                          onChange({
                            ...element,
                            classDataElementId: val,
                            classDataElementTag: element.classDataElementId
                              ? element.classDataElementTag
                              : "",
                          });
                        }
                      }}
                      placeholder={resources.dataDictionary.dataElementName}
                      errorMessage={
                        isClassDataElement === true && !element.classDataElementId
                          ? resources.required
                          : undefined
                      }
                    />
                    <TextField
                      id={"data_element_tag"}
                      onChange={({ value }) => {
                        if (element.classDataElementId) {
                          onChange({ ...element, classDataElementTag: value });
                        }
                      }}
                      value={element.classDataElementId ? element.classDataElementTag : ""}
                      label={resources.dataElementTag}
                      hasError={
                        element.classDataElementId && !element.classDataElementTag ? true : false
                      }
                      errorMessage={
                        isClassDataElement === true &&
                        element.classDataElementId &&
                        !element.classDataElementTag
                          ? resources.required
                          : undefined
                      }
                    />
                  </Box>
                )}
                {showWarningMessage && classChildCount && (
                  <Text size="200" color={colors.error}>
                    {resources.ifYouUpdateDateElement.replace(
                      "{classChildCount}",
                      classChildCount?.toString()
                    )}
                  </Text>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {/* Form End */}
      {element.dictionaryDataType === "List" && (
        <DataListItems
          onChange={onChange}
          element={element}
          dictionaryLists={dictionaryLists}
          dictionaryListName={dictionaryListName}
          showPopoverComboBox={showPopoverComboBox}
          showValueLists={showValueLists}
          setShowValueLists={setShowValueLists}
          updateValueLists={updateValueLists}
          isHiddenPopoverCombobox={isPanelHidden}
        />
      )}
    </Box>
  );
};
