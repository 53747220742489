import { Box, Button, Skeleton, Text, Tooltip } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { findList, hasEmptyLists } from "./utils";

import { ALLOWED_CHARS_REGEXP } from "@prodoctivity/shared";
import type { DictionaryList } from "@prodoctivity/shared/src/index-types";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";

import { Page } from "../../../components/Layout/Page";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";
import { DataListConfigurationTabs } from "./DataListConfigurationTabs";
import { ExportListButton } from "./ExportListButton";
import { ImportListButton } from "./ImportListButton";
import { ItemsTab } from "./ItemsTab";
import { PropertiesTab } from "./PropertiesTab";

type DataListConfigurationProps = {
  isUpdating: boolean;
};
const DataListConfigurationPage: FunctionComponent<DataListConfigurationProps> = ({
  isUpdating,
}) => {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const { dictionaryListName } = useParams();
  const { getDictionaryList, createDictionaryList, updateDictionaryList } = useServices();
  const [currentDataList, setCurrentDataList] = useState<DictionaryList<string>>();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();

  const getDictionaryListDetails = useCallback(async () => {
    if (dictionaryListName) {
      const dictionaryList = await getDictionaryList(dictionaryListName);
      return dictionaryList;
    } else {
      return {
        dictionaryList: undefined,
      };
    }
  }, [dictionaryListName, getDictionaryList]);

  const { data, isLoading, isError } = useOrganizationQuery(
    `/dictionaryLists/${dictionaryListName}`,
    getDictionaryListDetails,
    { refetchOnWindowFocus: false, refetchOnMount: "always" }
  );

  const {
    mutate: saveCurrentList,
    isLoading: isMutating,
    reset,
  } = useMutation(isUpdating ? updateDictionaryList : createDictionaryList, {
    onSuccess() {
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false);
      }, 4500);
      organizationNavigate(`/settings/data-dictionary/data-lists`);
    },
    onError(error: { response: { data: { errors: Array<{ message: string }> } } }) {
      setErrorMessage(error.response.data.errors[0].message);
      setShowErrorToast(true);
      setTimeout(() => {
        setShowErrorToast(false);
      }, 4500);
    },
    onSettled() {
      reset();
    },
  });

  const handleTabClick = useCallback(
    (index: number) => {
      setCurrentTabIndex(index);
    },
    [setCurrentTabIndex]
  );

  const handleSaveClicked = useCallback(() => {
    if (currentDataList) {
      saveCurrentList({
        listName: dictionaryListName ?? currentDataList.listSchema.name,
        dictionaryList: currentDataList,
      });
    }
  }, [currentDataList, dictionaryListName, saveCurrentList]);

  const handlePropertyChange = useCallback(
    (list: DictionaryList<string>) => {
      setCurrentDataList(list);
    },
    [setCurrentDataList]
  );

  useEffect(() => {
    if (data && data.dictionaryList) {
      setCurrentDataList(data.dictionaryList);
    }
  }, [data]);

  useEffect(() => {
    if (!isUpdating) {
      // New list is being created...
      setCurrentDataList({
        _id: undefined,
        listSchema: {
          children: [],
          description: "",
          listType: "None",
          name: "",
        },
        items: [],
      });
    }
  }, [isUpdating, setCurrentDataList]);

  const { isSaveButtonDisabled, validationMessage } = useMemo(() => {
    if (!currentDataList?.items.length) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.dataDictionary.listMustContainItems,
      };
    }
    if (!currentDataList.listSchema.name) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.dataDictionary.listNameCannotBeEmpty,
      };
    }
    if (findList(currentDataList.listSchema, "")) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.dataDictionary.childListNameCannotBeEmpty,
      };
    }
    if (hasEmptyLists(currentDataList.items)) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.dataDictionary.childListMustContainItems,
      };
    }
    if (!currentDataList.listSchema.name.match(ALLOWED_CHARS_REGEXP)) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.specialCharsNotAllowed,
      };
    }
    if (isMutating) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.loading,
      };
    }
    return { isSaveButtonDisabled: false, validationMessage: "" };
  }, [
    currentDataList,
    isMutating,
    resources.dataDictionary.childListMustContainItems,
    resources.dataDictionary.childListNameCannotBeEmpty,
    resources.dataDictionary.listMustContainItems,
    resources.dataDictionary.listNameCannotBeEmpty,
    resources.loading,
    resources.specialCharsNotAllowed,
  ]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.dataDictionary.dataLists,
        url: organizationLinkTemplates.manageDictionaryLists(),
      },
      currentDataList
        ? { type: "text", name: currentDataList.listSchema.name, ellipsisLength: 32 }
        : { type: "text", name: resources.loading, ellipsisLength: 32 },
    ];
  }, [
    currentDataList,
    resources.dataDictionary.dataLists,
    resources.home,
    resources.loading,
    resources.settings,
  ]);

  if (isLoading || !currentDataList) {
    return (
      <Box display="flex" padding={4}>
        <Box>
          <Skeleton show={true} />
        </Box>
      </Box>
    );
  }

  if (isError) {
    return <Box>{resources.errorOccurred}</Box>;
  }

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <Box display="flex" direction="row" flex="grow" gap={2} paddingX={12}>
          <Box display="flex" flex="shrink">
            <Text
              title={currentDataList.listSchema.name}
              weight="bold"
              size="400"
              ellipsisLength={100}
            >
              {resources.dataDictionary.listOf} {currentDataList.listSchema.name}{" "}
              {!isUpdating ? `(${resources.new})` : ""}
            </Text>
          </Box>
          <Box display="flex" direction="row" flex="grow" />

          <Box display="flex" direction="row" flex="shrink">
            <ImportListButton updateList={setCurrentDataList} />
          </Box>
          <Box display="flex" direction="row" flex="shrink">
            <ExportListButton list={currentDataList} />
          </Box>
        </Box>
        <Box marginTop={2} marginStart={6} marginEnd={6} padding={5}>
          <DataListConfigurationTabs
            selectedTabIndex={currentTabIndex}
            tabs={[
              {
                title: resources.properties,
                content: <PropertiesTab list={currentDataList} onChange={handlePropertyChange} />,
              },
              {
                title: resources.items,
                content: <ItemsTab list={currentDataList} onChange={handlePropertyChange} />,
              },
            ]}
            onTabClick={handleTabClick}
          />
          <Box display="flex" justifyContent="end" paddingY={4} gap={4}>
            <Box>
              <Button
                color={"transparent"}
                onClick={() => {
                  organizationNavigate("/settings/data-dictionary/data-lists/");
                }}
                text={resources.cancel}
              />
            </Box>
            <Box>
              <Tooltip text={validationMessage} doNotShowIf={() => !validationMessage}>
                <Button
                  color={"blue"}
                  onClick={handleSaveClicked}
                  disabled={isSaveButtonDisabled}
                  text={isUpdating ? resources.save : resources.create}
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        {showSuccessToast ? (
          <Box display="flex" justifyContent="center" width="100%">
            <NotificationMessage
              type={"success"}
              message={resources.dataDictionary.savedSuccessfully}
              position="bottom-left"
              handleDismiss={() => setShowErrorToast(false)}
            />
          </Box>
        ) : null}
        {showErrorToast ? (
          <Box display="flex" justifyContent="center" width="100%">
            <NotificationMessage
              type={"error"}
              message={errorMessage || resources.invalidNameType}
              position="bottom-left"
              handleDismiss={() => setShowErrorToast(false)}
            />
          </Box>
        ) : null}
      </Box>
    </Page>
  );
};

export default DataListConfigurationPage;
