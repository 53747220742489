import {
  Box,
  DatePicker,
  Divider,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useRef } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type Props = {
  filterLabel: string;
  handleChangeDate: (d: { value: Date | null | undefined }) => void;
  handleChangeDateTo: (d: { value: Date | null | undefined }) => void;
  toDate: Date | undefined;
  fromDate: Date | undefined;
};

export const DateRangeFilter: FunctionComponent<Props> = ({
  handleChangeDate,
  handleChangeDateTo,
  toDate,
  fromDate,
  filterLabel,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const endDateInput = useRef(null);
  const startDateInput = useRef(null);

  return (
    <Box display="flex" direction="column">
      <Divider direction="horizontal"></Divider>
      <Box display="flex" direction="row" flex="shrink" paddingX={4} paddingY={2} maxHeight={60}>
        <Box display="flex" flex="grow" paddingY={3}>
          <Text weight="normal" size="200" color={colors.neutral900}>
            {filterLabel}
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        paddingX={4}
        gap={2}
        direction={breakpoint === "small" ? "column" : "row"}
        marginBottom={4}
      >
        <DatePicker
          resources={resources}
          rangeEndDate={toDate}
          id="fromDate"
          label={resources.from}
          placeholder={resources.from}
          nextRef={endDateInput}
          onChange={handleChangeDate}
          rangeSelector="start"
          value={fromDate}
          ref={startDateInput}
          dataType="Date"
        />
        <DatePicker
          resources={resources}
          rangeStartDate={fromDate}
          id="toDate"
          label={resources.to}
          placeholder={resources.to}
          nextRef={startDateInput}
          onChange={handleChangeDateTo}
          rangeSelector="end"
          value={toDate}
          ref={endDateInput}
          dataType="Date"
        />
      </Box>
      <Divider direction="horizontal" />
    </Box>
  );
};
