import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const LandingPageS7Icon1: FunctionComponent<Props> = ({
  customClassName,
  width = 46,
  height = 46,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 46 46`}
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g transform="translate(0.324 -0.153)">
        <g transform="translate(0 0)">
          <ellipse
            cx="20"
            cy="20.5"
            rx="20"
            ry="20.5"
            transform="translate(2.676 2.153)"
            fill="rgba(233,112,37,0.3)"
            opacity="0.5"
          />
          <circle
            cx="23"
            cy="23"
            r="23"
            transform="translate(-0.324 0.153)"
            fill="rgba(30,57,78,0.15)"
            opacity="0.3"
          />
          <circle
            cx="17"
            cy="17"
            r="17"
            transform="translate(5.676 6.153)"
            fill="rgba(233,112,37,0.67)"
          />
          <path
            d="M14.116,6H4.235A1.233,1.233,0,0,0,3.006,7.235L3,14.646a1.239,1.239,0,0,0,1.235,1.235h9.881a1.239,1.239,0,0,0,1.235-1.235V7.235A1.239,1.239,0,0,0,14.116,6Zm0,2.47L9.176,11.558,4.235,8.47V7.235l4.941,3.088,4.941-3.088Z"
            transform="translate(13.4 12.023)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
