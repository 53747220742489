import { Box, Column, Text, UnsignedUpTo12, useColors } from "@prodoctivity/design-system";
import React, { DragEventHandler, ReactNode, useCallback } from "react";

import { useAppTranslation } from "../hooks/useAppTranslation";

export type DraggableBoxProps = {
  onDrop?: (item: Omit<Item, "content">, boxId: string) => void;
  onDragStart?: (item: Item) => void;
  itemsToDrag: Array<Item>;
  onItemMove: (key: string, oldIndex: number, index: number) => void;
  onItemMoveToSection: (fieldKey: string, sectionKey: string) => void;
  onMoveSection: (sourceKey: string, targetKey: string) => void;
  type: "root" | "section" | "field";
  itemKey: string;
};

type Item = {
  key: string;
  type: "root" | "field" | "section";
  content: ReactNode;
  column?: number;
};

export const DraggableBox: React.FC<DraggableBoxProps> = ({
  onDrop: _onDrop,
  onDragStart,
  itemsToDrag,
  onItemMove,
  onItemMoveToSection,
  onMoveSection,
  type,
  itemKey,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const handleDragStart = useCallback(
    (event: Parameters<DragEventHandler<HTMLDivElement>>[0], item: Item) => {
      if (onDragStart) onDragStart(item);

      const { content: _content, ...transfer } = item;

      if (!event.dataTransfer.items.length) {
        event.dataTransfer.setData("text/plain", JSON.stringify(transfer));
      }
    },
    [onDragStart]
  );

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  // const handleDrop = useCallback(
  //   (event: React.DragEvent<HTMLDivElement>) => {
  //     event.preventDefault();
  //     setDraggedItem(null);
  //     const itemData = event.dataTransfer.getData("text/plain");
  //     if (itemData !== "") {
  //       const item: Item = JSON.parse(itemData);
  //       if (onDrop) onDrop(item, item.key);
  //     }
  //   },
  //   [onDrop]
  // );

  const handleItemDrop = useCallback(
    (event: Parameters<DragEventHandler<HTMLDivElement>>[0], item: Item, index: number) => {
      const itemData = event.dataTransfer.getData("text/plain");
      if (itemData !== "") {
        // if (draggedItem) {
        const sourceItem: Item = JSON.parse(itemData);
        const oldIndex = itemsToDrag.findIndex((i) => i.key === sourceItem.key);
        if (sourceItem.type === item.type) {
          if (sourceItem.type === "section") {
            onMoveSection(sourceItem.key, item.key);
          } else if (oldIndex !== index) {
            onItemMove(item.key, oldIndex, index);
          }
        } else if (
          sourceItem.type === "field" &&
          item.type === "section" &&
          type === "section" &&
          item.key === itemKey
        ) {
          onItemMoveToSection(sourceItem.key, item.key);
        }
      }
      // }
    },
    [itemsToDrag, onItemMove, type, itemKey, onItemMoveToSection, onMoveSection]
  );

  return (
    <Box
      display="flex"
      wrap
      direction="row"
      onDragOver={handleDragOver}
      onDrop={(ev) => {
        const itemData = ev.dataTransfer.getData("text/plain");
        if (itemData !== "") {
          handleItemDrop(
            ev,
            {
              type,
              key: itemKey,
              content: <Text>{type}</Text>,
            },
            0
          );
          ev.preventDefault();
          ev.dataTransfer.clearData();
        }
      }}
    >
      {itemsToDrag.length === 0 ? (
        <Box
          padding={2}
          width={"91%"}
          display="flex"
          minHeight={65}
          marginStart={10}
          marginEnd={6}
          marginTop={4}
          marginBottom={4}
          borderRadius={6}
          borderStyle="lg"
          color={colors.neutral200}
        >
          <Box justifyContent="center" display="flex" alignItems="center" width={"100%"}>
            <Text align="center" color={colors.subtle}>
              {resources.formDesigner.emptySectionText}
            </Text>
          </Box>
        </Box>
      ) : (
        itemsToDrag.map((item) => (
          <Column key={item.key} span={(item.column as UnsignedUpTo12) ?? 12}>
            <Box
              key={item.key}
              display="flex"
              alignItems="center"
              justifyContent="between"
              marginTop={2}
              onDragStart={(ev) => handleDragStart(ev, item)}
              onDragOver={handleDragOver}
              onDrop={(ev) => {
                const itemData = ev.dataTransfer.getData("text/plain");
                if (itemData !== "") {
                  handleItemDrop(
                    ev,
                    {
                      type: item.type,
                      key: item.key,
                      content: <Text>{item.type}</Text>,
                    },
                    0
                  );
                  ev.preventDefault();
                  ev.dataTransfer.clearData();
                }
              }}
              draggable
            >
              {item.content}
            </Box>
          </Column>
        ))
      )}
    </Box>
  );
};
