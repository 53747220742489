import React, { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
};
export const IdCardSVG: FunctionComponent<Props> = ({ width = 249, height = 162 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 249 162"
      fill="none"
    >
      <path
        d="M249 6.05875V155.928C249 159.283 246.356 161.987 243.07 161.987H5.93044C2.64448 161.987 0 159.283 0 155.928V6.05875C0 2.70433 2.64448 0 5.93044 0H243.07C246.356 0 249 2.70433 249 6.05875Z"
        fill="#EDEDED"
      />
      <path
        d="M249 5.88973V35.0263H0V5.88973C0 2.62632 2.64448 0 5.93044 0H243.07C246.356 0 249 2.62632 249 5.88973Z"
        fill="#3F4B75"
      />
      <path d="M249 35.0273H0V42.1782H249V35.0273Z" fill="#273166" />
      <path d="M85.1349 49.4336H18.8789V119.603H85.1349V49.4336Z" fill="#D4DFF3" />
      <path
        d="M45.5308 119.6H20.2773C20.7879 115.647 22.4113 104.037 24.3226 100.345C26.0245 97.1071 29.4414 95.989 33.4736 94.9618L41.2761 93.5707L44.8239 92.9336L44.8501 94.0517L44.9286 97.0941V97.4191L44.9548 98.3683L44.981 99.0313L45.0988 103.686L45.2166 108.77L45.5046 119.6H45.5308Z"
        fill="#7782AF"
      />
      <path
        d="M83.3537 119.6H58.1133L58.3882 108.692L58.5322 103.803L58.5846 101.996L58.6631 98.7197L58.6893 97.4326V97.0945L58.7024 96.9125L58.8202 92.3359L59.1868 92.4269L62.4073 93.194L69.7778 94.9753C73.666 95.9114 77.6196 97.1205 79.2953 100.358C81.2197 104.05 82.83 115.661 83.3406 119.613L83.3537 119.6Z"
        fill="#7782AF"
      />
      <path d="M63.4274 97.0977H39.7188V119.603H63.4274V97.0977Z" fill="#E9E2CC" />
      <path
        d="M66.2672 114.271C66.7254 116.195 67.7203 118.171 69.1473 119.601H58.7003C59.7214 115.155 60.4938 110.422 60.7033 106.405C60.7818 104.91 60.7949 103.505 60.7033 102.257C60.6378 101.451 60.4415 99.696 60.1142 98.4999C59.9964 98.0838 59.8654 97.7328 59.7345 97.4987C59.5774 97.2257 59.3942 97.1347 59.1978 97.3297L59.1454 97.3817C59.0014 97.5377 58.8574 97.5507 58.6872 97.4337C58.6348 97.4077 58.5956 97.3557 58.5432 97.3167C58.4778 97.2517 58.4254 97.1997 58.3599 97.1087C57.3912 95.7565 56.37 91.531 57.6006 91.0239C58.0326 90.8549 58.5825 90.8809 59.1847 91.1019C60.1927 91.44 61.3448 92.2201 62.4052 93.1952C63.518 94.2353 64.5129 95.4965 65.089 96.6536C67.7072 101.971 69.4877 106.587 71.5561 112.113C70.0113 112.776 67.9298 114.193 66.2541 114.271H66.2672Z"
        fill="#3F4B75"
      />
      <path
        d="M45.7029 97.0967C45.6374 97.1877 45.5589 97.2787 45.4934 97.3437C45.297 97.5257 45.1138 97.5777 44.9567 97.4217C44.9436 97.4217 44.9305 97.4087 44.9043 97.3957C44.8781 97.3697 44.8388 97.3307 44.8257 97.3177C44.6425 97.1617 44.4723 97.2527 44.3021 97.5127C44.1843 97.7077 44.0664 98.0068 43.9748 98.3578C43.6213 99.554 43.3857 101.4 43.3333 102.258C43.2678 103.194 43.2548 104.222 43.3071 105.327C43.4119 109.552 44.2497 114.753 45.3625 119.602H34.9155C36.3294 118.172 37.3243 116.196 37.7825 114.272C36.1068 114.194 34.0122 112.777 32.4805 112.114C34.5489 106.588 36.3425 101.972 38.9477 96.6546C39.4451 95.6275 40.3092 94.5223 41.291 93.5732C42.4169 92.4681 43.713 91.545 44.8388 91.1159C45.5458 90.8429 46.1873 90.7649 46.6585 90.9599C47.9022 91.467 46.7502 95.7445 45.716 97.0837L45.7029 97.0967Z"
        fill="#3F4B75"
      />
      <path
        d="M59.1987 97.8353C59.7878 108.393 46.0548 110.304 44.8242 97.8353V85.5228H59.1987C59.1987 85.2887 59.1987 97.8353 59.1987 97.8353Z"
        fill="#EDAF96"
      />
      <path
        d="M62.7465 63.8244C62.1967 61.5361 58.7929 56.1664 48.4244 57.3366C43.7901 57.8566 40.6219 62.6672 40.2946 65.3846C38.8415 77.281 39.8233 83.6908 46.4345 89.7626C47.9924 91.1928 49.8252 92.4149 51.9199 92.74C55.7819 93.351 59.4998 88.9435 61.3195 85.5111C63.1524 82.0786 63.5058 79.7903 63.6498 75.9159C63.82 71.2483 63.7546 67.9459 62.7596 63.8244H62.7465Z"
        fill="#F4C5B5"
      />
      <path
        d="M66.2174 65.8796C65.5628 61.3291 63.01 56.4405 58.7029 54.7503C56.5951 53.9312 53.5579 53.1641 51.2931 53.1641C45.6506 53.1641 41.9981 57.1686 41.9981 57.1686C39.7202 56.9735 36.827 61.8751 36.4211 64.3974C35.7928 68.2329 36.5913 72.6925 37.403 75.5918C38.0445 77.8411 39.0787 77.165 40.1129 78.5692C40.833 77.126 40.9639 74.8377 41.0948 71.4703C41.1472 70.1052 46.0303 65.6976 45.0877 64.4885C47.4965 67.5438 50.4029 67.2578 53.5972 67.6999C56.7915 68.1549 59.8549 69.065 62.5256 70.8722C62.6172 74.5127 62.8267 75.9689 63.4943 78.5692C66.4006 77.256 66.885 70.4172 66.2305 65.8536L66.2174 65.8796Z"
        fill="#A53D17"
      />
      <path
        d="M40.6079 76.7088C39.901 75.5907 37.4136 74.0435 36.2092 75.2527C35.7903 75.6687 35.751 76.3318 35.8034 76.9169C35.9605 78.6071 36.7983 80.2193 38.0813 81.3244C38.3955 81.5974 38.7489 81.8445 39.1417 81.9615C39.5475 82.0785 40.0057 82.0395 40.333 81.7925C40.5163 81.6495 40.6472 81.4674 40.7519 81.2594C41.4327 80.0373 41.3542 77.918 40.5948 76.7088H40.6079Z"
        fill="#F4C5B5"
      />
      <path
        d="M63.1013 76.7088C63.8082 75.5907 66.2956 74.0435 67.5 75.2527C67.9189 75.6687 67.9582 76.3318 67.9059 76.9169C67.7488 78.6071 66.9109 80.2193 65.6279 81.3244C65.3137 81.5974 64.9603 81.8445 64.5675 81.9615C64.1617 82.0785 63.7035 82.0395 63.3762 81.7925C63.1929 81.6495 63.062 81.4674 62.9573 81.2594C62.2765 80.0373 62.3551 77.918 63.1144 76.7088H63.1013Z"
        fill="#F4C5B5"
      />
      <path
        d="M49.7852 100.946C49.7852 100.946 48.1488 100.088 47.5858 99.8537C46.8396 99.5416 46.0934 99.2296 45.4388 98.7615C44.7843 98.2935 44.2344 97.6434 44.0381 96.8633C43.6584 98.9825 44.4308 103.117 45.7661 104.82C47.1015 106.51 50.1256 106.914 52.2857 107.057C52.05 107.057 50.3351 102.87 49.7721 100.959L49.7852 100.946Z"
        fill="#DDD7C4"
      />
      <path
        d="M54.2507 100.946C54.2507 100.946 55.8871 100.088 56.45 99.8537C57.1962 99.5416 57.9425 99.2296 58.597 98.7615C59.2516 98.2935 59.8015 97.6434 59.9978 96.8633C60.3775 98.9825 59.8669 102.103 58.5316 103.793C57.1962 105.483 54.8398 106.914 52.6797 107.044C52.9153 107.044 53.7008 102.857 54.2638 100.946H54.2507Z"
        fill="#DDD7C4"
      />
      <path d="M54.0273 107.706H50.0475L48.9609 101.934H55.0485L54.0273 107.706Z" fill="#222A54" />
      <path
        d="M45.7432 97.8361C45.0886 96.8219 44.8923 94.9497 44.8268 93.5195C42.706 94.4036 42.2609 99.6303 42.3263 101.568C42.418 104.48 44.5781 108.081 46.4371 110.357C47.4451 107.353 49.0815 104.454 50.9536 101.88C51.0583 101.737 51.006 101.555 50.8489 101.49C49.6837 100.969 46.6596 99.2662 45.7563 97.8491L45.7432 97.8361Z"
        fill="#F7F2DF"
      />
      <path
        d="M58.3109 97.8361C58.9393 96.8089 59.175 94.8977 59.2273 93.5195C61.3482 94.4036 61.7933 99.6303 61.7278 101.568C61.6362 104.48 60.6543 107.392 58.7953 109.655C57.7873 106.651 55.1952 104.402 53.2576 101.88C53.1529 101.75 53.2052 101.542 53.3623 101.477C54.5144 100.943 57.4731 99.2142 58.2978 97.8361H58.3109Z"
        fill="#F7F2DF"
      />
      <path d="M55.1139 119.604H48.9609L50.0344 107.707H54.0142L55.1139 119.604Z" fill="#273166" />
      <path d="M89.7031 27.9653V15.0547H91.7585V27.9653H89.7031Z" fill="white" />
      <path
        d="M94.6758 27.9653V15.0547H99.284C100.331 15.0547 101.248 15.2237 102.033 15.5618C102.819 15.8998 103.473 16.3678 104.01 16.9399C104.547 17.512 104.94 18.2011 105.214 18.9812C105.489 19.7613 105.62 20.5934 105.62 21.4775C105.62 22.4656 105.476 23.3497 105.175 24.1428C104.874 24.9359 104.455 25.612 103.905 26.1841C103.355 26.7561 102.688 27.1852 101.915 27.4972C101.13 27.8093 100.266 27.9653 99.2971 27.9653H94.6889H94.6758ZM103.539 21.4905C103.539 20.8144 103.447 20.1903 103.251 19.6183C103.054 19.0462 102.779 18.5651 102.413 18.1491C102.046 17.733 101.601 17.421 101.078 17.2C100.554 16.9659 99.9516 16.8489 99.284 16.8489H96.718V26.1581H99.284C99.9647 26.1581 100.58 26.041 101.104 25.807C101.64 25.573 102.086 25.2479 102.439 24.8189C102.793 24.4028 103.067 23.9088 103.251 23.3367C103.434 22.7646 103.526 22.1536 103.526 21.4775L103.539 21.4905Z"
        fill="white"
      />
      <path
        d="M111.486 21.415C111.486 20.6349 111.63 19.8678 111.905 19.1007C112.18 18.3336 112.599 17.6445 113.135 17.0334C113.672 16.4224 114.34 15.9283 115.125 15.5513C115.911 15.1742 116.788 14.9922 117.783 14.9922C118.961 14.9922 119.982 15.2522 120.82 15.7723C121.658 16.2923 122.299 16.9684 122.718 17.8135L121.108 18.8927C120.925 18.5026 120.702 18.1776 120.453 17.9045C120.192 17.6445 119.917 17.4235 119.616 17.2675C119.314 17.1114 118.987 16.9944 118.66 16.9294C118.333 16.8644 118.018 16.8254 117.704 16.8254C117.023 16.8254 116.421 16.9554 115.911 17.2285C115.4 17.5015 114.968 17.8655 114.615 18.3076C114.261 18.7627 113.999 19.2567 113.829 19.8158C113.646 20.3749 113.567 20.9339 113.567 21.493C113.567 22.1171 113.672 22.7281 113.881 23.2872C114.091 23.8593 114.379 24.3663 114.746 24.7954C115.112 25.2375 115.557 25.5755 116.068 25.8355C116.578 26.0956 117.141 26.2256 117.757 26.2256C118.071 26.2256 118.398 26.1866 118.738 26.1086C119.079 26.0306 119.393 25.9005 119.707 25.7315C120.021 25.5625 120.309 25.3285 120.571 25.0684C120.833 24.8084 121.056 24.4834 121.226 24.1063L122.928 25.0684C122.718 25.5495 122.43 25.9785 122.051 26.3556C121.671 26.7196 121.239 27.0317 120.768 27.2917C120.296 27.5517 119.786 27.7468 119.262 27.8768C118.725 28.0068 118.215 28.0718 117.691 28.0718C116.775 28.0718 115.937 27.8768 115.178 27.4867C114.418 27.0967 113.764 26.5896 113.214 25.9655C112.664 25.3415 112.232 24.6264 111.931 23.8333C111.63 23.0402 111.473 22.2341 111.473 21.402L111.486 21.415Z"
        fill="white"
      />
      <path
        d="M128.478 15.0547H130.272L135.404 27.9653H133.244L131.869 24.4548H126.855L125.494 27.9653H123.32L128.478 15.0547ZM131.463 22.9467L129.382 17.395L127.222 22.9467H131.476H131.463Z"
        fill="white"
      />
      <path
        d="M137.016 27.9653V15.0547H142.671C143.26 15.0547 143.797 15.1717 144.294 15.4187C144.792 15.6658 145.211 15.9778 145.564 16.3678C145.918 16.7579 146.193 17.213 146.402 17.707C146.599 18.2141 146.703 18.7211 146.703 19.2412C146.703 19.6833 146.638 20.0993 146.52 20.4894C146.402 20.8924 146.232 21.2565 146.009 21.5945C145.787 21.9325 145.525 22.2316 145.198 22.4786C144.884 22.7256 144.53 22.9077 144.15 23.0377L147.214 27.9523H144.884L142.069 23.4667H139.071V27.9523H137.016V27.9653ZM139.071 21.6725H142.658C142.946 21.6725 143.221 21.6075 143.457 21.4775C143.692 21.3475 143.902 21.1784 144.072 20.9444C144.242 20.7234 144.373 20.4634 144.478 20.1773C144.569 19.8913 144.622 19.5792 144.622 19.2542C144.622 18.9292 144.569 18.6171 144.452 18.3311C144.334 18.0451 144.177 17.785 143.993 17.564C143.81 17.343 143.588 17.1739 143.339 17.0439C143.09 16.9139 142.828 16.8489 142.566 16.8489H139.084V21.6725H139.071Z"
        fill="white"
      />
      <path
        d="M149.125 27.9653V15.0547H153.733C154.781 15.0547 155.697 15.2237 156.482 15.5618C157.268 15.8998 157.922 16.3678 158.459 16.9399C158.996 17.512 159.389 18.2011 159.664 18.9812C159.939 19.7613 160.069 20.5934 160.069 21.4775C160.069 22.4656 159.925 23.3497 159.624 24.1428C159.323 24.9359 158.904 25.612 158.354 26.1841C157.805 26.7561 157.137 27.1852 156.365 27.4972C155.579 27.8093 154.715 27.9653 153.746 27.9653H149.138H149.125ZM157.988 21.4905C157.988 20.8144 157.896 20.1903 157.7 19.6183C157.504 19.0462 157.229 18.5651 156.862 18.1491C156.495 17.733 156.05 17.421 155.527 17.2C155.003 16.9659 154.401 16.8489 153.733 16.8489H151.167V26.1581H153.733C154.414 26.1581 155.029 26.041 155.553 25.807C156.09 25.573 156.535 25.2479 156.888 24.8189C157.242 24.4028 157.517 23.9088 157.7 23.3367C157.883 22.7646 157.975 22.1536 157.975 21.4775L157.988 21.4905Z"
        fill="white"
      />
      <path
        d="M108.121 55.6484H109.482V62.6823C109.482 65.4777 108.095 66.3358 106.275 66.3358C105.777 66.3358 105.162 66.2317 104.809 66.0887L105.018 64.9836C105.306 65.0876 105.712 65.1916 106.17 65.1916C107.401 65.1916 108.121 64.6455 108.121 62.5913V55.6614V55.6484Z"
        fill="#1D1D1B"
      />
      <path
        d="M120.81 60.7942C120.81 64.4087 118.597 66.3329 115.901 66.3329C113.204 66.3329 111.148 64.1746 111.148 61.0022C111.148 57.6608 113.243 55.4766 116.058 55.4766C118.872 55.4766 120.81 57.6738 120.81 60.7942ZM112.602 60.9632C112.602 63.2125 113.832 65.2148 115.979 65.2148C118.126 65.2148 119.37 63.2385 119.37 60.8462C119.37 58.753 118.27 56.5687 115.992 56.5687C113.714 56.5687 112.602 58.649 112.602 60.9502V60.9632Z"
        fill="#1D1D1B"
      />
      <path
        d="M123.951 55.6484V60.043H129.07V55.6484H130.458V66.1537H129.07V61.2261H123.951V66.1537H122.59V55.6484H123.951Z"
        fill="#1D1D1B"
      />
      <path
        d="M132.827 66.1537V55.6484H134.319L137.71 60.9661C138.496 62.2012 139.111 63.3064 139.608 64.3855H139.635C139.504 62.9683 139.477 61.6812 139.477 60.056V55.6614H140.76V66.1667H139.373L136.008 60.8361C135.275 59.6659 134.568 58.4698 134.031 57.3256H133.979C134.058 58.6648 134.084 59.926 134.084 61.6812V66.1667H132.801L132.827 66.1537Z"
        fill="#1D1D1B"
      />
      <path
        d="M105.191 70.625H107.221V71.0281H105.676V72.2762H107.103V72.6793H105.676V74.3695H105.191V70.625Z"
        fill="#1D1D1B"
      />
      <path
        d="M108.291 70.937C108.291 71.1061 108.173 71.2361 107.977 71.2361C107.807 71.2361 107.676 71.1061 107.676 70.937C107.676 70.768 107.807 70.625 107.99 70.625C108.173 70.625 108.291 70.755 108.291 70.937ZM107.741 74.3825V71.6911H108.239V74.3825H107.741Z"
        fill="#1D1D1B"
      />
      <path
        d="M109.054 72.5221C109.054 72.2101 109.054 71.937 109.027 71.677H109.459V72.2101H109.499C109.616 71.846 109.918 71.625 110.245 71.625C110.297 71.625 110.336 71.625 110.389 71.638V72.0931C110.389 72.0931 110.284 72.0801 110.219 72.0801C109.878 72.0801 109.63 72.3401 109.564 72.7041C109.564 72.7691 109.538 72.8472 109.538 72.9252V74.3553H109.054V72.5091V72.5221Z"
        fill="#1D1D1B"
      />
      <path
        d="M110.821 73.8743C110.965 73.9653 111.227 74.0693 111.476 74.0693C111.829 74.0693 111.999 73.8873 111.999 73.6663C111.999 73.4452 111.855 73.3022 111.502 73.1722C111.017 73.0032 110.782 72.7301 110.782 72.4181C110.782 71.989 111.135 71.625 111.711 71.625C111.986 71.625 112.222 71.703 112.379 71.794L112.261 72.1451C112.156 72.0801 111.96 71.989 111.711 71.989C111.423 71.989 111.253 72.1581 111.253 72.3531C111.253 72.5741 111.41 72.6781 111.764 72.8081C112.235 72.9902 112.47 73.2242 112.47 73.6142C112.47 74.0823 112.104 74.4203 111.462 74.4203C111.161 74.4203 110.886 74.3423 110.703 74.2383L110.821 73.8613V73.8743Z"
        fill="#1D1D1B"
      />
      <path
        d="M113.7 70.9088V71.6759H114.407V72.053H113.7V73.5091C113.7 73.8472 113.792 74.0292 114.067 74.0292C114.198 74.0292 114.289 74.0162 114.355 73.9902L114.381 74.3542C114.289 74.3932 114.132 74.4193 113.949 74.4193C113.713 74.4193 113.53 74.3412 113.412 74.2112C113.268 74.0682 113.216 73.8342 113.216 73.5091V72.04H112.797V71.6629H113.216V71.0128L113.7 70.8828V70.9088Z"
        fill="#1D1D1B"
      />
      <path
        d="M116.16 74.3695V70.625H116.697L117.901 72.5232C118.176 72.9653 118.399 73.3553 118.582 73.7454C118.543 73.2383 118.53 72.7833 118.53 72.1982V70.625H118.988V74.3695H118.49L117.299 72.4712C117.037 72.0552 116.789 71.6261 116.592 71.2231H116.579C116.605 71.7041 116.618 72.1462 116.618 72.7703V74.3695H116.16Z"
        fill="#1D1D1B"
      />
      <path
        d="M121.319 74.3683L121.28 74.0303H121.267C121.11 74.2383 120.822 74.4333 120.442 74.4333C119.892 74.4333 119.617 74.0563 119.617 73.6663C119.617 73.0162 120.193 72.6651 121.241 72.6651V72.6131C121.241 72.3921 121.175 71.989 120.625 71.989C120.376 71.989 120.115 72.0671 119.918 72.1841L119.8 71.859C120.023 71.716 120.35 71.625 120.691 71.625C121.515 71.625 121.725 72.1841 121.725 72.7301V73.7313C121.725 73.9653 121.725 74.1993 121.764 74.3813H121.319V74.3683ZM121.241 72.9902C120.704 72.9902 120.088 73.0682 120.088 73.6012C120.088 73.9133 120.298 74.0693 120.547 74.0693C120.9 74.0693 121.123 73.8483 121.201 73.6142C121.214 73.5622 121.227 73.5102 121.227 73.4582V72.9902H121.241Z"
        fill="#1D1D1B"
      />
      <path
        d="M122.522 72.4181C122.522 72.1451 122.522 71.911 122.496 71.69H122.928L122.954 72.1191H122.967C123.124 71.859 123.373 71.625 123.818 71.625C124.185 71.625 124.473 71.846 124.591 72.1711C124.669 72.015 124.787 71.898 124.892 71.82C125.049 71.703 125.232 71.625 125.494 71.625C125.848 71.625 126.384 71.859 126.384 72.7951V74.3813H125.9V72.8602C125.9 72.3401 125.704 72.0281 125.311 72.0281C125.036 72.0281 124.813 72.2361 124.735 72.4701C124.709 72.5351 124.695 72.6261 124.695 72.7171V74.3813H124.211V72.7691C124.211 72.3401 124.015 72.0281 123.648 72.0281C123.347 72.0281 123.111 72.2751 123.033 72.5221C123.007 72.6001 122.994 72.6781 122.994 72.7561V74.3813H122.509V72.4181H122.522Z"
        fill="#1D1D1B"
      />
      <path
        d="M127.457 73.1202C127.457 73.7833 127.889 74.0563 128.387 74.0563C128.74 74.0563 128.949 73.9913 129.133 73.9133L129.211 74.2643C129.041 74.3423 128.74 74.4333 128.308 74.4333C127.47 74.4333 126.973 73.8873 126.973 73.0812C126.973 72.2751 127.457 71.625 128.243 71.625C129.12 71.625 129.355 72.3921 129.355 72.8862C129.355 72.9902 129.355 73.0682 129.342 73.1072H127.444L127.457 73.1202ZM128.897 72.7691C128.897 72.4571 128.766 71.976 128.216 71.976C127.719 71.976 127.496 72.4311 127.457 72.7691H128.897Z"
        fill="#1D1D1B"
      />
      <path d="M105.191 97.4453H105.676V100.787H107.286V101.19H105.191V97.4453Z" fill="#1D1D1B" />
      <path
        d="M109.315 101.193L109.276 100.855H109.263C109.106 101.063 108.818 101.258 108.438 101.258C107.888 101.258 107.613 100.881 107.613 100.49C107.613 99.8404 108.189 99.4893 109.237 99.4893V99.4373C109.237 99.2163 109.171 98.8133 108.621 98.8133C108.373 98.8133 108.111 98.8913 107.914 99.0083L107.797 98.6832C108.019 98.5402 108.346 98.4492 108.687 98.4492C109.512 98.4492 109.721 99.0083 109.721 99.5544V100.555C109.721 100.79 109.721 101.024 109.76 101.206H109.315V101.193ZM109.237 99.8144C108.7 99.8144 108.085 99.8924 108.085 100.425C108.085 100.737 108.294 100.894 108.543 100.894C108.896 100.894 109.119 100.672 109.197 100.438C109.21 100.386 109.224 100.334 109.224 100.282V99.8144H109.237Z"
        fill="#1D1D1B"
      />
      <path
        d="M110.438 100.683C110.582 100.774 110.844 100.878 111.093 100.878C111.446 100.878 111.616 100.696 111.616 100.475C111.616 100.254 111.472 100.111 111.119 99.9808C110.635 99.8118 110.399 99.5387 110.399 99.2267C110.399 98.7976 110.752 98.4336 111.328 98.4336C111.603 98.4336 111.839 98.5116 111.996 98.6026L111.878 98.9537C111.773 98.8887 111.577 98.7976 111.328 98.7976C111.04 98.7976 110.87 98.9667 110.87 99.1617C110.87 99.3827 111.027 99.4867 111.381 99.6167C111.852 99.7988 112.088 100.033 112.088 100.423C112.088 100.891 111.721 101.229 111.08 101.229C110.779 101.229 110.504 101.151 110.32 101.047L110.438 100.67V100.683Z"
        fill="#1D1D1B"
      />
      <path
        d="M113.321 97.7213V98.4884H114.028V98.8655H113.321V100.322C113.321 100.66 113.413 100.842 113.688 100.842C113.819 100.842 113.91 100.829 113.976 100.803L114.002 101.167C113.91 101.206 113.753 101.232 113.57 101.232C113.347 101.232 113.151 101.154 113.033 101.024C112.889 100.881 112.837 100.647 112.837 100.322V98.8525H112.418V98.4754H112.837V97.8253L113.321 97.6953V97.7213Z"
        fill="#1D1D1B"
      />
      <path
        d="M115.781 101.19V97.4453H116.318L117.522 99.3436C117.797 99.7856 118.02 100.176 118.203 100.566C118.164 100.059 118.151 99.6036 118.151 99.0185V97.4453H118.609V101.19H118.112L116.92 99.2915C116.658 98.8755 116.41 98.4464 116.213 98.0434H116.2C116.226 98.5245 116.239 98.9665 116.239 99.5906V101.19H115.781Z"
        fill="#1D1D1B"
      />
      <path
        d="M120.94 101.193L120.901 100.855H120.888C120.731 101.063 120.443 101.258 120.063 101.258C119.513 101.258 119.238 100.881 119.238 100.49C119.238 99.8404 119.814 99.4893 120.862 99.4893V99.4373C120.862 99.2163 120.796 98.8133 120.246 98.8133C119.998 98.8133 119.736 98.8913 119.539 99.0083L119.435 98.6832C119.657 98.5402 119.984 98.4492 120.325 98.4492C121.15 98.4492 121.359 99.0083 121.359 99.5544V100.555C121.359 100.79 121.359 101.024 121.398 101.206H120.953L120.94 101.193ZM120.862 99.8144C120.325 99.8144 119.71 99.8924 119.71 100.425C119.71 100.737 119.919 100.894 120.168 100.894C120.521 100.894 120.744 100.672 120.822 100.438C120.835 100.386 120.849 100.334 120.849 100.282V99.8144H120.862Z"
        fill="#1D1D1B"
      />
      <path
        d="M122.143 99.2267C122.143 98.9537 122.143 98.7196 122.117 98.4986H122.549L122.575 98.9277H122.588C122.746 98.6676 122.994 98.4336 123.439 98.4336C123.806 98.4336 124.094 98.6546 124.199 98.9797C124.277 98.8236 124.395 98.7066 124.5 98.6286C124.657 98.5116 124.84 98.4336 125.102 98.4336C125.456 98.4336 125.992 98.6676 125.992 99.6037V101.19H125.508V99.6688C125.508 99.1487 125.312 98.8366 124.919 98.8366C124.644 98.8366 124.421 99.0447 124.343 99.2787C124.317 99.3437 124.303 99.4347 124.303 99.5257V101.19H123.819V99.5777C123.819 99.1487 123.623 98.8366 123.256 98.8366C122.955 98.8366 122.719 99.0837 122.641 99.3307C122.615 99.4087 122.602 99.4867 122.602 99.5647V101.19H122.117V99.2267H122.143Z"
        fill="#1D1D1B"
      />
      <path
        d="M127.078 99.9288C127.078 100.592 127.51 100.865 128.008 100.865C128.361 100.865 128.571 100.8 128.754 100.722L128.832 101.073C128.662 101.151 128.361 101.242 127.929 101.242C127.091 101.242 126.594 100.696 126.594 99.8898C126.594 99.0837 127.078 98.4336 127.864 98.4336C128.741 98.4336 128.976 99.2007 128.976 99.6948C128.976 99.7988 128.976 99.8768 128.963 99.9158H127.065L127.078 99.9288ZM128.518 99.5777C128.518 99.2657 128.387 98.7846 127.837 98.7846C127.34 98.7846 127.117 99.2397 127.078 99.5777H128.518Z"
        fill="#1D1D1B"
      />
      <path
        d="M105.949 83.7171C106.787 83.5871 107.769 83.4961 108.856 83.4961C110.819 83.4961 112.22 83.9512 113.136 84.8093C114.079 85.6674 114.629 86.8765 114.629 88.5797C114.629 90.2829 114.092 91.7001 113.11 92.6622C112.115 93.6503 110.492 94.1704 108.437 94.1704C107.468 94.1704 106.643 94.1184 105.949 94.0404V83.7041V83.7171ZM107.311 93.0133C107.651 93.0783 108.162 93.0913 108.698 93.0913C111.618 93.0913 113.202 91.4661 113.202 88.6317C113.215 86.1484 111.801 84.5752 108.921 84.5752C108.214 84.5752 107.677 84.6402 107.324 84.7182V93.0133H107.311Z"
        fill="#1D1D1B"
      />
      <path
        d="M125.454 88.7239C125.454 92.3383 123.242 94.2626 120.545 94.2626C117.848 94.2626 115.793 92.1043 115.793 88.9319C115.793 85.5905 117.888 83.4062 120.702 83.4062C123.517 83.4062 125.454 85.6035 125.454 88.7239ZM117.246 88.8929C117.246 91.1422 118.477 93.1444 120.624 93.1444C122.771 93.1444 124.014 91.1682 124.014 88.7759C124.014 86.6827 122.915 84.4984 120.637 84.4984C118.359 84.4984 117.246 86.5786 117.246 88.8799V88.8929Z"
        fill="#1D1D1B"
      />
      <path
        d="M132.72 89.1636H128.609V92.9471H133.191V94.0912H127.234V83.5859H132.955V84.7301H128.609V88.0455H132.72V89.1636Z"
        fill="#1D1D1B"
      />
      <path
        d="M107.337 113.802H108.384V119.198C108.384 121.343 107.324 121.993 105.923 121.993C105.543 121.993 105.072 121.915 104.797 121.798L104.954 120.953C105.177 121.031 105.478 121.109 105.831 121.109C106.774 121.109 107.324 120.693 107.324 119.107V113.789L107.337 113.802Z"
        fill="#1D1D1B"
      />
      <path
        d="M113.319 121.862L113.241 121.134H113.202C112.874 121.589 112.246 121.992 111.421 121.992C110.243 121.992 109.641 121.173 109.641 120.328C109.641 118.924 110.897 118.157 113.149 118.17V118.053C113.149 117.572 113.018 116.714 111.827 116.714C111.29 116.714 110.714 116.883 110.308 117.143L110.073 116.454C110.557 116.142 111.251 115.934 111.984 115.934C113.764 115.934 114.196 117.143 114.196 118.3V120.471C114.196 120.978 114.223 121.459 114.288 121.862H113.319ZM113.162 118.911C112.01 118.885 110.688 119.093 110.688 120.211C110.688 120.887 111.146 121.212 111.683 121.212C112.442 121.212 112.927 120.731 113.097 120.237C113.136 120.133 113.162 120.016 113.162 119.899V118.898V118.911Z"
        fill="#1D1D1B"
      />
      <path
        d="M115.9 117.637C115.9 117.039 115.9 116.545 115.848 116.064H116.79L116.856 117.026H116.882C117.17 116.48 117.851 115.934 118.806 115.934C119.618 115.934 120.862 116.415 120.862 118.404V121.862H119.801V118.521C119.801 117.585 119.448 116.805 118.453 116.805C117.759 116.805 117.209 117.299 117.026 117.884C116.974 118.014 116.947 118.196 116.947 118.378V121.862H115.887V117.637H115.9Z"
        fill="#1D1D1B"
      />
      <path
        d="M124.787 121.863V121.213L125.611 120.407C127.614 118.522 128.518 117.508 128.531 116.338C128.531 115.545 128.151 114.816 126.973 114.816C126.266 114.816 125.677 115.18 125.31 115.48L124.97 114.738C125.507 114.283 126.279 113.945 127.182 113.945C128.871 113.945 129.578 115.089 129.578 116.208C129.578 117.638 128.531 118.808 126.881 120.381L126.253 120.953V120.979H129.774V121.85H124.773L124.787 121.863Z"
        fill="#1D1D1B"
      />
      <path
        d="M136.163 117.887C136.163 120.527 135.181 121.996 133.44 121.996C131.908 121.996 130.874 120.579 130.848 118.004C130.848 115.391 131.987 113.961 133.571 113.961C135.155 113.961 136.163 115.417 136.163 117.9V117.887ZM131.908 118.004C131.908 120.033 132.536 121.177 133.492 121.177C134.579 121.177 135.089 119.916 135.089 117.939C135.089 115.963 134.592 114.767 133.505 114.767C132.576 114.767 131.908 115.885 131.908 118.004Z"
        fill="#1D1D1B"
      />
      <path
        d="M142.342 117.887C142.342 120.527 141.361 121.996 139.619 121.996C138.088 121.996 137.054 120.579 137.027 118.004C137.027 115.391 138.166 113.961 139.75 113.961C141.334 113.961 142.342 115.417 142.342 117.9V117.887ZM138.088 118.004C138.088 120.033 138.716 121.177 139.672 121.177C140.758 121.177 141.269 119.916 141.269 117.939C141.269 115.963 140.772 114.767 139.685 114.767C138.755 114.767 138.088 115.885 138.088 118.004Z"
        fill="#1D1D1B"
      />
      <path
        d="M143.223 119.89C143.223 118.915 143.812 118.212 144.781 117.809V117.77C143.903 117.367 143.524 116.691 143.524 116.028C143.524 114.793 144.571 113.961 145.946 113.961C147.464 113.961 148.224 114.91 148.224 115.872C148.224 116.535 147.896 117.237 146.941 117.692V117.731C147.922 118.108 148.525 118.798 148.525 119.747C148.525 121.099 147.36 122.009 145.867 122.009C144.231 122.009 143.236 121.034 143.236 119.903L143.223 119.89ZM147.438 119.838C147.438 118.889 146.77 118.433 145.71 118.134C144.794 118.394 144.296 118.993 144.296 119.734C144.257 120.527 144.859 121.216 145.867 121.216C146.875 121.216 147.438 120.631 147.438 119.825V119.838ZM144.519 115.95C144.519 116.73 145.108 117.146 146.011 117.38C146.692 117.159 147.202 116.678 147.202 115.963C147.202 115.339 146.823 114.689 145.88 114.689C145.003 114.689 144.519 115.261 144.519 115.937V115.95Z"
        fill="#1D1D1B"
      />
      <path
        d="M23.3548 126.18H21.4565V127.935H23.5773V128.455H20.8281V123.605H23.4726V124.126H21.4696V125.66H23.3679V126.18H23.3548Z"
        fill="#1D1D1B"
      />
      <path
        d="M27.0724 128.456L26.444 127.377C26.1953 126.961 26.0251 126.701 25.8811 126.415H25.868C25.724 126.701 25.593 126.961 25.3443 127.377L24.7552 128.443H24.0352L25.5276 125.986L24.0875 123.594H24.8206L25.4621 124.738C25.6454 125.05 25.7763 125.297 25.9072 125.557H25.9334C26.0774 125.271 26.1952 125.05 26.3785 124.738L27.0462 123.594H27.7662L26.2738 125.96L27.7924 128.456H27.0593H27.0724Z"
        fill="#1D1D1B"
      />
      <path
        d="M28.5508 123.657C28.8519 123.605 29.2577 123.566 29.7683 123.566C30.3967 123.566 30.8549 123.709 31.1429 123.969C31.4047 124.203 31.5749 124.555 31.5749 124.984C31.5749 125.413 31.444 125.764 31.1953 126.024C30.868 126.375 30.3181 126.557 29.7028 126.557C29.5196 126.557 29.3363 126.557 29.1923 126.518V128.468H28.5639V123.67L28.5508 123.657ZM29.1792 125.998C29.3232 126.037 29.4934 126.05 29.7028 126.05C30.4621 126.05 30.9334 125.686 30.9334 125.01C30.9334 124.334 30.4752 124.06 29.7814 124.06C29.5065 124.06 29.297 124.086 29.1923 124.112V125.998H29.1792Z"
        fill="#1D1D1B"
      />
      <path d="M33.0425 123.594V128.443H32.4141V123.594H33.0425Z" fill="#1D1D1B" />
      <path
        d="M34.1445 123.659C34.4587 123.594 34.9169 123.555 35.362 123.555C36.0297 123.555 36.4748 123.672 36.7759 123.945C37.0247 124.166 37.1556 124.491 37.1556 124.868C37.1556 125.505 36.7497 125.934 36.2392 126.103V126.129C36.6188 126.259 36.8414 126.61 36.9592 127.104C37.1163 127.78 37.2341 128.248 37.3389 128.43H36.6843C36.6057 128.287 36.501 127.871 36.357 127.273C36.213 126.597 35.9512 126.35 35.3751 126.324H34.786V128.43H34.1576V123.646L34.1445 123.659ZM34.7729 125.869H35.4144C36.0952 125.869 36.5141 125.505 36.5141 124.946C36.5141 124.322 36.0559 124.049 35.3882 124.036C35.0871 124.036 34.8646 124.062 34.7598 124.088V125.856L34.7729 125.869Z"
        fill="#1D1D1B"
      />
      <path
        d="M38.88 126.922L38.3694 128.443H37.7148L39.3775 123.594H40.1368L41.7994 128.443H41.1186L40.595 126.922H38.8669H38.88ZM40.4771 126.428L39.9928 125.037C39.888 124.725 39.8095 124.426 39.744 124.153H39.7309C39.6524 124.439 39.5738 124.738 39.4822 125.037L38.9978 126.441H40.4641L40.4771 126.428Z"
        fill="#1D1D1B"
      />
      <path
        d="M42.9026 124.139H41.4102V123.605H45.0234V124.139H43.531V128.455H42.8895V124.139H42.9026Z"
        fill="#1D1D1B"
      />
      <path d="M46.2143 123.594V128.443H45.5859V123.594H46.2143Z" fill="#1D1D1B" />
      <path
        d="M51.4876 125.972C51.4876 127.649 50.4665 128.533 49.2228 128.533C47.9791 128.533 47.0234 127.545 47.0234 126.076C47.0234 124.528 47.9922 123.527 49.2883 123.527C50.5843 123.527 51.4876 124.541 51.4876 125.985V125.972ZM47.6911 126.05C47.6911 127.09 48.254 128.013 49.249 128.013C50.2439 128.013 50.82 127.103 50.82 125.998C50.82 125.036 50.3094 124.021 49.2621 124.021C48.2148 124.021 47.6911 124.984 47.6911 126.05Z"
        fill="#1D1D1B"
      />
      <path
        d="M52.3256 128.455V123.605H53.0194L54.5904 126.063C54.957 126.635 55.2319 127.142 55.4675 127.636H55.4806C55.4283 126.986 55.4021 126.388 55.4021 125.634V123.605H55.9912V128.455H55.3497L53.7918 125.998C53.4515 125.452 53.1242 124.906 52.8754 124.373H52.8493C52.8885 124.997 52.9016 125.569 52.9016 126.375V128.455H52.3125H52.3256Z"
        fill="#1D1D1B"
      />
      <path
        d="M58.6381 123.659C59.0177 123.607 59.4759 123.555 59.9734 123.555C60.8767 123.555 61.5313 123.763 61.9502 124.153C62.3823 124.543 62.6441 125.115 62.6441 125.895C62.6441 126.675 62.3954 127.338 61.9371 127.78C61.4789 128.235 60.7196 128.482 59.7771 128.482C59.3319 128.482 58.9523 128.456 58.625 128.43V123.659H58.6381ZM59.2665 127.949C59.4236 127.975 59.6592 127.988 59.908 127.988C61.2564 127.988 61.9895 127.234 61.9895 125.934C61.9895 124.79 61.348 124.062 60.0127 124.062C59.6854 124.062 59.4367 124.088 59.2796 124.127V127.962L59.2665 127.949Z"
        fill="#1D1D1B"
      />
      <path
        d="M64.1612 126.922L63.6507 128.443H62.9961L64.6587 123.594H65.418L67.0806 128.443H66.413L65.8893 126.922H64.1612ZM65.7584 126.428L65.274 125.037C65.1693 124.725 65.0907 124.426 65.0253 124.153H65.0122C64.9336 124.439 64.8551 124.738 64.7634 125.037L64.2791 126.441H65.7453L65.7584 126.428Z"
        fill="#1D1D1B"
      />
      <path
        d="M68.1799 124.139H66.6875V123.605H70.3008V124.139H68.8083V128.455H68.1668V124.139H68.1799Z"
        fill="#1D1D1B"
      />
      <path
        d="M73.3899 126.18H71.4917V127.935H73.6125V128.455H70.8633V123.605H73.5078V124.126H71.5048V125.66H73.403V126.18H73.3899Z"
        fill="#1D1D1B"
      />
      <path
        d="M185.754 98.0599H186.749V103.209C186.749 105.25 185.741 105.874 184.406 105.874C184.039 105.874 183.594 105.796 183.332 105.692L183.476 104.886C183.686 104.964 183.987 105.029 184.314 105.029C185.204 105.029 185.741 104.626 185.741 103.117V98.0469L185.754 98.0599Z"
        fill="#1D1D1B"
      />
      <path
        d="M191.463 105.742L191.384 105.04H191.345C191.031 105.469 190.442 105.859 189.643 105.859C188.517 105.859 187.941 105.066 187.941 104.272C187.941 102.933 189.133 102.205 191.28 102.218V102.101C191.28 101.646 191.149 100.827 190.01 100.827C189.499 100.827 188.949 100.983 188.557 101.243L188.321 100.58C188.779 100.281 189.447 100.086 190.154 100.086C191.856 100.086 192.262 101.243 192.262 102.348V104.415C192.262 104.897 192.288 105.365 192.353 105.742H191.437H191.463ZM191.319 102.92C190.219 102.894 188.963 103.089 188.963 104.168C188.963 104.819 189.395 105.131 189.918 105.131C190.638 105.131 191.096 104.676 191.267 104.207C191.306 104.103 191.319 103.986 191.319 103.882V102.92Z"
        fill="#1D1D1B"
      />
      <path
        d="M193.912 101.723C193.912 101.151 193.912 100.683 193.859 100.228H194.75L194.802 101.138H194.828C195.103 100.618 195.745 100.098 196.661 100.098C197.433 100.098 198.625 100.553 198.625 102.451V105.753H197.617V102.568C197.617 101.684 197.289 100.943 196.334 100.943C195.666 100.943 195.155 101.411 194.972 101.97C194.92 102.1 194.907 102.269 194.907 102.438V105.753H193.899V101.723H193.912Z"
        fill="#1D1D1B"
      />
      <path
        d="M202.409 105.743V105.132L203.194 104.365C205.106 102.557 205.957 101.608 205.97 100.49C205.97 99.736 205.603 99.0469 204.491 99.0469C203.81 99.0469 203.247 99.385 202.906 99.671L202.579 98.9689C203.103 98.5399 203.836 98.2148 204.687 98.2148C206.297 98.2148 206.978 99.307 206.978 100.373C206.978 101.738 205.983 102.843 204.399 104.352L203.797 104.898V104.924H207.148V105.756H202.383L202.409 105.743Z"
        fill="#1D1D1B"
      />
      <path
        d="M213.246 101.957C213.246 104.479 212.304 105.87 210.654 105.87C209.201 105.87 208.206 104.518 208.18 102.061C208.18 99.5774 209.253 98.1992 210.772 98.1992C212.29 98.1992 213.246 99.5904 213.246 101.957ZM209.188 102.074C209.188 103.998 209.79 105.103 210.706 105.103C211.741 105.103 212.238 103.907 212.238 102.009C212.238 100.11 211.767 98.9923 210.719 98.9923C209.829 98.9923 209.188 100.058 209.188 102.087V102.074Z"
        fill="#1D1D1B"
      />
      <path
        d="M216.374 99.2681H216.348L215.052 99.9702L214.855 99.2031L216.492 98.332H217.356V105.743H216.374V99.2681Z"
        fill="#1D1D1B"
      />
      <path
        d="M224.924 98.332V98.9951L221.677 105.743H220.63L223.863 99.1901V99.1641H220.211V98.332H224.924Z"
        fill="#1D1D1B"
      />
      <path
        d="M185.703 115.661H186.698V120.81C186.698 122.851 185.69 123.475 184.355 123.475C183.988 123.475 183.543 123.397 183.281 123.293L183.425 122.487C183.635 122.565 183.936 122.63 184.263 122.63C185.153 122.63 185.69 122.227 185.69 120.719V115.648L185.703 115.661Z"
        fill="#1D1D1B"
      />
      <path
        d="M191.412 123.347L191.334 122.645H191.294C190.98 123.074 190.391 123.464 189.593 123.464C188.467 123.464 187.891 122.671 187.891 121.878C187.891 120.539 189.082 119.811 191.229 119.824V119.707C191.229 119.252 191.098 118.432 189.959 118.432C189.449 118.432 188.899 118.589 188.506 118.849L188.27 118.185C188.728 117.886 189.396 117.691 190.103 117.691C191.805 117.691 192.211 118.849 192.211 119.954V122.021C192.211 122.502 192.237 122.97 192.302 123.347H191.386H191.412ZM191.268 120.526C190.169 120.5 188.912 120.695 188.912 121.774C188.912 122.424 189.344 122.736 189.867 122.736C190.587 122.736 191.046 122.281 191.216 121.813C191.255 121.709 191.268 121.592 191.268 121.488V120.526Z"
        fill="#1D1D1B"
      />
      <path
        d="M193.873 119.317C193.873 118.745 193.873 118.276 193.82 117.821H194.711L194.763 118.732H194.789C195.064 118.211 195.705 117.691 196.622 117.691C197.394 117.691 198.586 118.146 198.586 120.045V123.347H197.578V120.162C197.578 119.278 197.25 118.537 196.295 118.537C195.627 118.537 195.116 119.005 194.933 119.564C194.881 119.694 194.868 119.863 194.868 120.032V123.347H193.86V119.317H193.873Z"
        fill="#1D1D1B"
      />
      <path
        d="M202.354 123.344V122.733L203.14 121.966C205.051 120.159 205.902 119.21 205.915 118.092C205.915 117.338 205.549 116.649 204.436 116.649C203.755 116.649 203.192 116.987 202.852 117.273L202.524 116.571C203.048 116.141 203.781 115.816 204.632 115.816C206.242 115.816 206.923 116.909 206.923 117.975C206.923 119.34 205.928 120.445 204.344 121.953L203.742 122.499V122.525H207.093V123.357H202.328L202.354 123.344Z"
        fill="#1D1D1B"
      />
      <path
        d="M213.207 119.562C213.207 122.084 212.264 123.476 210.615 123.476C209.162 123.476 208.167 122.123 208.141 119.666C208.141 117.183 209.214 115.805 210.733 115.805C212.251 115.805 213.207 117.196 213.207 119.562ZM209.149 119.679C209.149 121.603 209.751 122.709 210.667 122.709C211.701 122.709 212.199 121.512 212.199 119.614C212.199 117.716 211.728 116.598 210.68 116.598C209.79 116.598 209.149 117.664 209.149 119.692V119.679Z"
        fill="#1D1D1B"
      />
      <path
        d="M214.136 123.344V122.733L214.921 121.966C216.832 120.159 217.683 119.21 217.696 118.092C217.696 117.338 217.33 116.649 216.217 116.649C215.536 116.649 214.973 116.987 214.633 117.273L214.306 116.571C214.829 116.141 215.563 115.816 216.413 115.816C218.024 115.816 218.704 116.909 218.704 117.975C218.704 119.34 217.71 120.445 216.125 121.953L215.523 122.499V122.525H218.875V123.357H214.109L214.136 123.344Z"
        fill="#1D1D1B"
      />
      <path
        d="M222.214 116.87H222.188L220.892 117.572L220.695 116.805L222.332 115.934H223.196V123.345H222.214V116.87Z"
        fill="#1D1D1B"
      />
      <path d="M85.1195 131.875H19.793V152.379H85.1195V131.875Z" fill="white" />
      <path d="M23.7099 134.723H22.4531V149.544H23.7099V134.723Z" fill="#706F6F" />
      <path d="M26.0654 134.723H24.8086V149.544H26.0654V134.723Z" fill="#706F6F" />
      <path d="M28.4091 134.723H27.1523V149.544H28.4091V134.723Z" fill="#706F6F" />
      <path d="M30.7646 134.723H29.5078V149.544H30.7646V134.723Z" fill="#706F6F" />
      <path d="M33.1083 134.723H31.8516V149.544H33.1083V134.723Z" fill="#706F6F" />
      <path d="M35.4638 134.723H34.207V149.544H35.4638V134.723Z" fill="#706F6F" />
      <path d="M37.8076 134.723H36.5508V149.544H37.8076V134.723Z" fill="#706F6F" />
      <path d="M40.163 134.723H38.9062V149.544H40.163V134.723Z" fill="#706F6F" />
      <path d="M42.5224 134.723H41.2656V149.544H42.5224V134.723Z" fill="#706F6F" />
      <path d="M44.8662 134.723H43.6094V149.544H44.8662V134.723Z" fill="#706F6F" />
      <path d="M47.2216 134.723H45.9648V149.544H47.2216V134.723Z" fill="#706F6F" />
      <path d="M49.5654 134.723H48.3086V149.544H49.5654V134.723Z" fill="#706F6F" />
      <path d="M51.9208 134.723H50.6641V149.544H51.9208V134.723Z" fill="#706F6F" />
      <path d="M54.2646 134.723H53.0078V149.544H54.2646V134.723Z" fill="#706F6F" />
      <path d="M56.6201 134.723H55.3633V149.544H56.6201V134.723Z" fill="#706F6F" />
      <path d="M58.9638 134.723H57.707V149.544H58.9638V134.723Z" fill="#706F6F" />
      <path d="M61.3193 134.723H60.0625V149.544H61.3193V134.723Z" fill="#706F6F" />
      <path d="M63.663 134.723H62.4062V149.544H63.663V134.723Z" fill="#706F6F" />
      <path d="M66.0185 134.723H64.7617V149.544H66.0185V134.723Z" fill="#706F6F" />
      <path d="M68.3623 134.723H67.1055V149.544H68.3623V134.723Z" fill="#706F6F" />
      <path d="M70.7216 134.723H69.4648V149.544H70.7216V134.723Z" fill="#706F6F" />
      <path d="M73.0654 134.723H71.8086V149.544H73.0654V134.723Z" fill="#706F6F" />
      <path d="M75.4208 134.723H74.1641V149.544H75.4208V134.723Z" fill="#706F6F" />
      <path d="M77.7763 134.723H76.5195V149.544H77.7763V134.723Z" fill="#706F6F" />
      <path d="M80.1201 134.723H78.8633V149.544H80.1201V134.723Z" fill="#706F6F" />
      <path d="M82.4755 134.723H81.2188V149.544H82.4755V134.723Z" fill="#706F6F" />
      <path d="M191.122 134.723H106.773V149.544H191.122V134.723Z" fill="white" />
      <path
        d="M192.432 150.844H105.465V133.422H192.432V150.844ZM108.083 148.244H189.813V136.022H108.083V148.244Z"
        fill="#E7E12C"
      />
      <path
        d="M194.58 66.8399V66.1898L195.404 65.3837C197.407 63.4984 198.311 62.4843 198.324 61.3142C198.324 60.5211 197.944 59.793 196.766 59.793C196.059 59.793 195.47 60.157 195.103 60.4561L194.763 59.715C195.3 59.2599 196.072 58.9219 196.975 58.9219C198.664 58.9219 199.371 60.066 199.371 61.1842C199.371 62.6143 198.324 63.7845 196.674 65.3577L196.046 65.9297V65.9557H199.567V66.8269H194.566L194.58 66.8399Z"
        fill="#1D1D1B"
      />
      <path
        d="M205.956 62.864C205.956 65.5033 204.974 66.9725 203.233 66.9725C201.701 66.9725 200.667 65.5553 200.641 62.981C200.641 60.3677 201.78 58.9375 203.364 58.9375C204.948 58.9375 205.956 60.3937 205.956 62.877V62.864ZM201.701 62.981C201.701 65.0092 202.329 66.1534 203.285 66.1534C204.372 66.1534 204.882 64.8922 204.882 62.916C204.882 60.9397 204.385 59.7436 203.298 59.7436C202.369 59.7436 201.701 60.8617 201.701 62.981Z"
        fill="#1D1D1B"
      />
      <path
        d="M212.12 62.864C212.12 65.5033 211.138 66.9725 209.397 66.9725C207.865 66.9725 206.831 65.5553 206.805 62.981C206.805 60.3677 207.944 58.9375 209.528 58.9375C211.112 58.9375 212.12 60.3937 212.12 62.877V62.864ZM207.865 62.981C207.865 65.0092 208.493 66.1534 209.449 66.1534C210.536 66.1534 211.046 64.8922 211.046 62.916C211.046 60.9397 210.549 59.7436 209.462 59.7436C208.533 59.7436 207.865 60.8617 207.865 62.981Z"
        fill="#1D1D1B"
      />
      <path
        d="M217.567 59.8073C217.344 59.8073 217.069 59.8073 216.768 59.8593C215.105 60.1324 214.228 61.3415 214.045 62.6287H214.084C214.464 62.1346 215.105 61.7316 215.969 61.7316C217.357 61.7316 218.326 62.7197 218.326 64.2409C218.326 65.658 217.344 66.9842 215.721 66.9842C214.097 66.9842 212.945 65.6971 212.945 63.6688C212.945 62.1346 213.495 60.9255 214.268 60.1584C214.922 59.5213 215.786 59.1312 216.768 59.0142C217.082 58.9622 217.344 58.9492 217.54 58.9492V59.8073H217.567ZM217.266 64.2929C217.266 63.1747 216.624 62.5117 215.655 62.5117C215.014 62.5117 214.425 62.9017 214.137 63.4738C214.058 63.5908 214.019 63.7468 214.019 63.9418C214.045 65.216 214.634 66.1651 215.747 66.1651C216.663 66.1651 217.279 65.411 217.279 64.2929H217.266Z"
        fill="#1D1D1B"
      />
      <path
        d="M219.871 51.5758C219.661 51.3417 219.452 51.1207 219.229 50.8997C215.956 47.6233 211.4 45.582 206.386 45.582C196.45 45.582 188.359 53.539 188.359 63.3162C188.359 68.2698 190.428 72.7554 193.766 75.9798C193.976 76.2008 194.211 76.4088 194.447 76.6039C197.628 79.3862 201.817 81.0634 206.386 81.0634C216.323 81.0634 224.413 73.1064 224.413 63.3162C224.413 58.8177 222.698 54.6962 219.884 51.5628L219.871 51.5758ZM206.373 80.1533C202.053 80.1533 198.113 78.5671 195.101 75.9538C194.866 75.7588 194.643 75.5507 194.421 75.3297C191.253 72.2873 189.289 68.0228 189.289 63.3292C189.289 54.0591 196.96 46.5181 206.373 46.5181C211.139 46.5181 215.459 48.4554 218.561 51.5498C218.771 51.7708 218.993 52.0048 219.203 52.2389C221.86 55.2032 223.458 59.0907 223.458 63.3292C223.458 72.5994 215.786 80.1533 206.373 80.1533Z"
        fill="#C51E1F"
      />
      <path
        d="M219.438 51.1487L194.682 75.5268L194.001 76.1768C193.765 75.9688 193.543 75.7738 193.32 75.5528L193.988 74.9027L218.129 51.1227L218.783 50.4727C219.006 50.6937 219.228 50.9147 219.425 51.1487H219.438Z"
        fill="#C51E1F"
      />
      <path d="M178.79 97.4336H177.48V124.776H178.79V97.4336Z" fill="#1D3A6B" />
      <path d="M230.581 110.461H178.137V111.761H230.581V110.461Z" fill="#1D3A6B" />
      <path
        d="M121.805 138.326H122.8V143.474C122.8 145.515 121.792 146.139 120.456 146.139C120.09 146.139 119.645 146.061 119.383 145.957L119.527 145.151C119.736 145.229 120.037 145.294 120.365 145.294C121.255 145.294 121.792 144.891 121.792 143.383V138.312L121.805 138.326Z"
        fill="#1D1D1B"
      />
      <path
        d="M127.51 146.023L127.431 145.321H127.392C127.078 145.75 126.489 146.14 125.69 146.14C124.564 146.14 123.988 145.347 123.988 144.554C123.988 143.215 125.18 142.486 127.327 142.499V142.382C127.327 141.927 127.196 141.108 126.057 141.108C125.546 141.108 124.996 141.264 124.604 141.524L124.368 140.861C124.826 140.562 125.494 140.367 126.201 140.367C127.903 140.367 128.308 141.524 128.308 142.629V144.697C128.308 145.178 128.335 145.646 128.4 146.023H127.484H127.51ZM127.366 143.202C126.266 143.176 125.009 143.371 125.009 144.45C125.009 145.1 125.441 145.412 125.965 145.412C126.685 145.412 127.143 144.957 127.314 144.489C127.353 144.385 127.366 144.268 127.366 144.164V143.202Z"
        fill="#1D1D1B"
      />
      <path
        d="M129.97 141.992C129.97 141.42 129.97 140.952 129.918 140.497H130.808L130.861 141.407H130.887C131.162 140.887 131.803 140.367 132.72 140.367C133.492 140.367 134.683 140.822 134.683 142.72V146.023H133.675V142.837C133.675 141.953 133.348 141.212 132.392 141.212C131.725 141.212 131.214 141.68 131.031 142.239C130.978 142.369 130.965 142.538 130.965 142.707V146.023H129.957V141.992H129.97Z"
        fill="#1D1D1B"
      />
      <path
        d="M138.456 146.02V145.409L139.241 144.642C141.153 142.835 142.004 141.886 142.017 140.767C142.017 140.013 141.65 139.324 140.537 139.324C139.857 139.324 139.294 139.662 138.953 139.948L138.626 139.246C139.15 138.817 139.883 138.492 140.734 138.492C142.344 138.492 143.025 139.584 143.025 140.65C143.025 142.016 142.03 143.121 140.446 144.629L139.844 145.175V145.201H143.195V146.033H138.43L138.456 146.02Z"
        fill="#1D1D1B"
      />
      <path
        d="M144.934 145.305C145.157 145.331 145.406 145.305 145.746 145.266C146.335 145.188 146.885 144.941 147.304 144.551C147.801 144.109 148.155 143.459 148.286 142.575H148.247C147.828 143.082 147.239 143.368 146.492 143.368C145.144 143.368 144.293 142.367 144.293 141.092C144.293 139.688 145.314 138.453 146.846 138.453C148.378 138.453 149.32 139.688 149.32 141.574C149.32 143.199 148.77 144.343 148.037 145.058C147.461 145.617 146.676 145.955 145.864 146.046C145.497 146.098 145.17 146.111 144.934 146.098V145.279V145.305ZM145.301 141.04C145.301 141.964 145.864 142.614 146.741 142.614C147.422 142.614 147.945 142.289 148.207 141.834C148.26 141.743 148.299 141.626 148.299 141.469C148.299 140.208 147.828 139.233 146.767 139.233C145.903 139.233 145.301 139.987 145.301 141.04Z"
        fill="#1D1D1B"
      />
      <path
        d="M152.344 147.425C152.592 146.749 152.907 145.527 153.038 144.695L154.163 144.578C153.902 145.553 153.391 146.814 153.077 147.347L152.357 147.412L152.344 147.425Z"
        fill="#1D1D1B"
      />
      <path
        d="M155.057 146.02V145.409L155.843 144.642C157.754 142.835 158.605 141.886 158.618 140.767C158.618 140.013 158.252 139.324 157.139 139.324C156.458 139.324 155.895 139.662 155.555 139.948L155.228 139.246C155.751 138.817 156.484 138.492 157.335 138.492C158.946 138.492 159.626 139.584 159.626 140.65C159.626 142.016 158.631 143.121 157.047 144.629L156.445 145.175V145.201H159.797V146.033H155.031L155.057 146.02Z"
        fill="#1D1D1B"
      />
      <path
        d="M165.895 142.226C165.895 144.749 164.952 146.14 163.302 146.14C161.849 146.14 160.854 144.788 160.828 142.33C160.828 139.847 161.902 138.469 163.42 138.469C164.939 138.469 165.895 139.86 165.895 142.226ZM161.836 142.343C161.836 144.267 162.438 145.373 163.355 145.373C164.389 145.373 164.886 144.176 164.886 142.278C164.886 140.38 164.415 139.262 163.368 139.262C162.478 139.262 161.836 140.328 161.836 142.356V142.343Z"
        fill="#1D1D1B"
      />
      <path
        d="M166.823 146.02V145.409L167.609 144.642C169.52 142.835 170.371 141.886 170.384 140.767C170.384 140.013 170.017 139.324 168.905 139.324C168.224 139.324 167.661 139.662 167.321 139.948L166.993 139.246C167.517 138.817 168.25 138.492 169.101 138.492C170.711 138.492 171.392 139.584 171.392 140.65C171.392 142.016 170.397 143.121 168.813 144.629L168.211 145.175V145.201H171.562V146.033H166.797L166.823 146.02Z"
        fill="#1D1D1B"
      />
      <path
        d="M172.625 144.137C172.625 143.201 173.188 142.538 174.104 142.148V142.109C173.266 141.719 172.913 141.082 172.913 140.445C172.913 139.275 173.908 138.469 175.217 138.469C176.67 138.469 177.39 139.366 177.39 140.289C177.39 140.913 177.076 141.589 176.16 142.018V142.057C177.089 142.421 177.665 143.071 177.665 143.968C177.665 145.256 176.552 146.127 175.125 146.127C173.568 146.127 172.625 145.204 172.625 144.124V144.137ZM176.644 144.085C176.644 143.188 176.016 142.746 175.008 142.46C174.131 142.707 173.659 143.279 173.659 143.994C173.62 144.749 174.196 145.412 175.152 145.412C176.107 145.412 176.644 144.853 176.644 144.085ZM173.869 140.38C173.869 141.121 174.432 141.524 175.296 141.745C175.937 141.524 176.435 141.069 176.435 140.393C176.435 139.795 176.081 139.184 175.165 139.184C174.327 139.184 173.869 139.73 173.869 140.367V140.38Z"
        fill="#1D1D1B"
      />
    </svg>
  );
};
