import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const BlueCheckCircleSvgIcon: FunctionComponent<Props> = ({
  color,
  width = 20,
  height = 17,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      style={{
        ...svgStyle,
      }}
      onClick={onClick}
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.102 0.331578C17.2073 1.18643 16.3201 2.04846 15.4067 2.92845C15.3471 2.86739 15.3061 2.82788 15.2688 2.78837C12.7972 0.238191 9.02835 -0.638209 5.72547 0.572229C1.39369 2.15981 -0.906406 6.53821 0.334973 10.8484C1.58381 15.1837 6.3592 17.8668 10.8774 16.7389C13.8746 15.9882 15.9324 14.1888 17.0433 11.4015C17.5092 10.2306 17.6733 9.00578 17.5353 7.75224C17.4533 7.00874 16.6705 6.5885 15.9771 6.91535C15.5335 7.12368 15.3396 7.48645 15.3732 7.96057C15.4403 8.93754 15.3322 9.88936 14.9557 10.8017C13.6956 13.8691 10.3629 15.489 7.04513 14.6521C3.10105 13.6572 1.02836 9.30031 2.82146 5.76957C4.52137 2.42201 8.6593 1.16488 12.0367 2.97514C12.7264 3.3451 13.3266 3.82999 13.8187 4.40109C11.9361 6.21495 10.0684 8.01803 8.19331 9.82471C8.17095 9.80675 8.14112 9.78161 8.1113 9.75288C7.43283 9.09917 6.75436 8.44187 6.07216 7.79175C5.7292 7.46131 5.25948 7.3751 4.84942 7.54751C4.12994 7.85281 3.95473 8.71125 4.52509 9.27157C5.44588 10.1803 6.3853 11.0711 7.31726 11.969C7.91745 12.5437 8.50645 12.5473 9.09918 11.9726C11.2092 9.94324 13.3154 7.91028 15.4254 5.88091C16.827 4.53039 18.225 3.18347 19.6267 1.83295C20.0256 1.44863 20.1038 0.938592 19.8354 0.51476C19.4552 -0.0886629 18.6351 -0.178458 18.0983 0.33517L18.102 0.331578Z"
        fill={color ? calculateColor(color) : "#2951A0"}
      />
    </svg>
  );
};
