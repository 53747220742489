import { Box, Image, Spinner, Text, useColors } from "@prodoctivity/design-system";
import { FC, useCallback, useLayoutEffect, useMemo, useState } from "react";

import { SignUpFieldsGroup } from "../../utils";
import email_sent from "../../assets/email_sent.svg";
import { reverseGetLocaleName } from "@prodoctivity/shared";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useMutation } from "@tanstack/react-query";
import { useServices } from "../../hooks/useServices";
import { useTranslation } from "react-i18next";

type Props = {
  currentInput: SignUpFieldsGroup;
};

const SuccessSignUp: FC<Props> = ({ currentInput: input }) => {
  const { colors } = useColors();
  const { i18n } = useTranslation();
  const { createOrganization } = useServices();
  const [activated, setActivated] = useState(false);
  const doCreateOrganization = useCallback(async () => {
    await createOrganization(
      input.account.firstName,
      input.account.lastName,
      input.account.password,
      input.account.email,
      input.account.phone,
      input.company.organizationId,
      input.company.organizationName,
      reverseGetLocaleName(i18n.language),
      [],
      input.account.isEnrolledForMarketing
    );
  }, [createOrganization, input, i18n]);

  const { error, isLoading, isIdle, mutate, isError } = useMutation<
    void,
    //TODO: TICKET => We need to implement a type that manages the error.

    {
      name: string;
      message: string;
      response: {
        data: {
          errors: Array<{ errorCode: string; message: string }>;
        };
      };
    }
  >({
    mutationFn: async () => {
      return doCreateOrganization();
    },
  });

  const errorType = useMemo(() => {
    if (
      !error ||
      !error.response ||
      !error.response.data ||
      !error.response.data.errors ||
      !error.response.data.errors.length
    ) {
      return undefined;
    }
    return error.response.data.errors[0].errorCode;
  }, [error]);

  useLayoutEffect(() => {
    const handle = setTimeout(() => {
      if (!activated && !isLoading) {
        mutate();
        setActivated(true);
      }
    }, 500);
    return () => {
      clearTimeout(handle);
    };
  }, [mutate, activated, isLoading]);

  const { resources } = useAppTranslation();

  const errors: Record<string, string> = resources.signUpPage;

  return (
    <Box maxWidth={400} display="flex" justifyContent="center">
      {isLoading || isIdle ? (
        <Spinner accessibilityLabel={resources.loading} show={true} />
      ) : isError ? (
        <>
          {errorType && errorType === "exists" ? (
            <Text align="center" size="200" color={colors.black900} weight="bold">
              {errors[errorType] || resources.alreadyExists}
            </Text>
          ) : (
            <Text align="center" size="200" color={colors.black900} weight="bold">
              {resources.unknownError}
            </Text>
          )}
        </>
      ) : (
        <Box>
          <Text align="center" size="600" color={colors.lightWash} weight="bold">
            {resources.thankYou}
          </Text>
          <Box
            marginTop={12}
            marginBottom={4}
            width={100}
            height={127}
            display="block"
            marginEnd="auto"
            marginStart="auto"
          >
            <Image
              src={email_sent}
              alt=""
              color="transparent"
              naturalHeight={698}
              naturalWidth={627}
            />
          </Box>
          <Box marginStart={5}>
            <Text inline align="center" weight="bold">
              {resources.signUpPage.youWillRecieveAn}
              <Text inline weight="bold" color={colors.black900}>
                {resources.signUpPage.emailConfirmation}
              </Text>{" "}
              {resources.signUpPage.soon}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export { SuccessSignUp };
