import {
  Box,
  ComboBox,
  Divider,
  Icon,
  SearchField,
  Table,
  TapArea,
  Text,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type { TemplateSettingsFilter } from "@prodoctivity/shared/src/index-types";
import type { HttpGetTemplateListRequest, ListableTemplateWithCombined } from "@prodoctivity/types";
import { FunctionComponent, useCallback, useMemo } from "react";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useDocumentType } from "../DocumentType/hooks";
const PAGINATION_OPTIONS: HttpGetTemplateListRequest["queryParameters"]["rowsPerPage"][] = [
  "15",
  "30",
  "100",
];
const ListTemplatesPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { groupList } = useDocumentType();
  const {
    paginatedData,
    setFilter,
    filter,
    currentPage,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    refetch,
  } = usePaginatedDataEndpoint<
    { templates: ListableTemplateWithCombined[] },
    "15" | "30" | "100",
    TemplateSettingsFilter
  >(
    "15",
    {
      documentGroupId: undefined,
      templateName: undefined,
    },
    (services, currentPage, rowsPerPage, filter) => {
      return services.fetchTemplateList(
        "templates",
        false,
        currentPage,
        rowsPerPage,
        filter.templateName,
        filter.documentGroupId
      );
    },
    "settings_template_list"
  );

  const changeFilter = useCallback(
    (filter: string) => {
      setFilter((prev) => {
        return { ...prev, TemplateName: filter };
      });
    },
    [setFilter]
  );

  const organizationNavigate = useOrganizationNavigate();
  const { resources } = useAppTranslation();
  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      { type: "url", name: resources.settings, url: "/settings" },
      { type: "url", name: resources.fluency, url: "/settings/fluency/templates" },
      { type: "text", name: resources.template },
    ];
  }, [resources.home, resources.template, resources.settings, resources.fluency]);

  const { breakpoint } = useDesignBreakpoint();

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" gap={2}>
        <SearchComponentWrapper
          componentHeaderText={resources.templates}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="template-list-search"
          searchFieldLabel={resources.search}
          setFilter={changeFilter}
          omitSearch={true}
        />

        <Box margin={5} display="flex" flex="shrink" direction="column">
          <Pagination<typeof rowsPerPage>
            id="template_list_pagination_top"
            rowsLabel={`${resources.templates}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.templates.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            totalRowCount={totalRowCount}
            extraComponent={
              <>
                <SearchField
                  marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                  marginEnd={2}
                  accessibilityLabel={resources.search}
                  id="data_elements_filter"
                  onChange={({ value }) => {
                    setFilter((prev) => {
                      return { ...prev, templateName: value };
                    });
                  }}
                />
                {groupList && (
                  <Box>
                    <ComboBox
                      id={"document_ group_elements_filter"}
                      onSelect={({ item }) => {
                        setFilter((prev) => {
                          return { ...prev, documentGroupId: item.value };
                        });
                      }}
                      onClear={() => {
                        setFilter((prev) => {
                          return { ...prev, documentGroupId: undefined };
                        });
                      }}
                      placeholder={resources.documentGroup}
                      options={groupList.map((group) => ({
                        label: group.name,
                        value: group.id,
                      }))}
                      label=""
                      selectedOption={groupList
                        .map((group) => ({
                          label: group.name,
                          value: group.id,
                        }))
                        .find((option) => option.value === filter.documentGroupId)}
                    />
                  </Box>
                )}
              </>
            }
          />
          <Divider direction="horizontal" />
        </Box>
        <Box
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Box>
            <Table accessibilityLabel="data list">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Box paddingX={4}>
                      <Text size="200" weight="bold">
                        {resources.name.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.description.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.documentGroup.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.actions.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {paginatedData ? (
                  paginatedData.templates.map((template: ListableTemplateWithCombined) => {
                    if (template.type === "combined-template") {
                      return <></>;
                    }
                    return (
                      <Table.Row key={template.templateVersionId}>
                        <Table.Cell>
                          <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                            <Box>
                              <Text weight="bold">{`${template.name}`}</Text>
                            </Box>
                            <Box width={1} borderStyle={"sm"} borderRadius={4} />
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box>
                            <Text>{`${template.description}`}</Text>
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box>
                            <Text>{`${template.documentGroupName}`}</Text>
                          </Box>
                        </Table.Cell>

                        <Table.Cell>
                          <Box display="flex">
                            <Box display="flex">
                              <TapArea
                                onTap={() =>
                                  organizationNavigate(
                                    `/templates/versions/${template.templateVersionId}/details`
                                  )
                                }
                              >
                                <Tooltip text={resources.edit}>
                                  <Icon
                                    accessibilityLabel="Edit"
                                    icon="pencil"
                                    size={"sm"}
                                    color={colors.subtle}
                                  />
                                </Tooltip>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral200}
                      >
                        <Text size="300">{resources.pageNotFound.templateNotFound}</Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Box>
        </Box>
        <Box margin={5} display="flex" direction="row">
          <Box display="flex" flex="grow" />
          <Box display="flex" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="template_list_pagination_bottom"
              rowsLabel={`${resources.templates}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.templates.length : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={PAGINATION_OPTIONS}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default ListTemplatesPage;
