import { FunctionComponent, PropsWithChildren, useCallback, useMemo } from "react";

import { createUuid } from "@prodoctivity/shared";
import { Box } from "./Box";
import { useColors } from "./ColorSchemeProvider";
import { Icon } from "./Icon";
import { TextField } from "./TextField";

export type SearchParams = {
  searchTerms: string;
};

type Props = {
  accessibilityLabel: string;
  placeholder: string;
  searchParams: SearchParams;
  setSearchParams(args: SearchParams): void;
  direction?: "row" | "column";
  onSearch?(args: SearchParams): void;
};

export const SearchModule: FunctionComponent<PropsWithChildren<Props>> = ({
  placeholder,
  accessibilityLabel,
  searchParams,
  setSearchParams,
  onSearch,
  direction,
  children,
}) => {
  const { colors } = useColors();
  const componentId = useMemo(() => {
    return createUuid();
  }, []);
  const onInputChange = useCallback(
    (args: { event: unknown; value: string }) => {
      setSearchParams({
        searchTerms: args.value,
      });
    },
    [setSearchParams]
  );
  const onSearchClick = useCallback(() => {
    if (onSearch) {
      onSearch(searchParams);
    }
  }, [onSearch, searchParams]);

  return (
    <Box width="100%" display="flex" flex="grow" fit={true} direction={direction}>
      <Box width="100%" display="flex">
        <Box margin="auto" flex="grow">
          <TextField
            onChange={onInputChange}
            mode="unstyled"
            id={`${componentId}_search`}
            placeholder={placeholder}
            value={searchParams.searchTerms}
          />
        </Box>
        <Box margin="auto">
          <Icon
            size={5}
            color={colors.black600}
            accessibilityLabel={accessibilityLabel}
            icon="search"
            onClick={onSearchClick}
          />
        </Box>

        {/* <Button
            size="md"
            accessibilityLabel={accessibilityLabel}
            text={buttonText}
            onClick={() => {
              if (onSearch) {
                onSearch(searchParams);
              }
            }}
          /> */}
      </Box>
      <Box>
        <Box display="flex" direction="column">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
