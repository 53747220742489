import type { ContextPayloadValidationError } from "@prodoctivity/shared/src/index-types";
import { Form } from "antd";

export const translateError = (textTranslated: string, parameters: { [key: string]: string }) => {
  let newTranslation = textTranslated;

  Object.keys(parameters).forEach((key) => {
    newTranslation = newTranslation.replace(["${", key, "}"].join(""), parameters[key]?.toString());
  });

  return newTranslation;
};

export function ValidationDecorator({
  errors,
  children,
  i18n = (key: string) => key,
}: {
  errors: ContextPayloadValidationError[];
  children: any;
  i18n?: (key: string, parameters?: any) => string;
}) {
  return (
    <Form.Item
      validateStatus={errors.length > 0 ? "error" : "success"}
      help={errors.map((e, i) =>
        e == null ? (
          <></>
        ) : (
          <div key={`${e.errorCode}_${i}`}>
            {i18n ? translateError(i18n(e.errorCode), {}) : e.message}
          </div>
        )
      )}
    >
      {children}
    </Form.Item>
  );
}
