"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToXmlExpression = exports.convertToTreeForm = exports.getSummaryFunctionName = exports.convertTreeFormLogicalExpressionToExpressionObject = exports.isSourceFunction = void 0;
const _1 = require(".");
const utils_1 = require("../utils");
const Expression_1 = require("./Expression");
function isSourceFunction(fn) {
    return typeof fn === "function";
}
exports.isSourceFunction = isSourceFunction;
const dateToString = (d) => `${d.getFullYear()}-${`00${d.getMonth() + 1}`.slice(-2)}-${`00${d.getDate()}`.slice(-2)}`;
function convertToTreeFormInner(exprList, expr) {
    if (exprList && exprList.length > 0 && (0, _1.isLogicalCompositeOperator)(expr.operatorType)) {
        const exprListCopy = exprList.slice(0);
        const exprNormal = exprListCopy.shift();
        if (exprNormal && (expr.operatorType === "and" || expr.operatorType === "or")) {
            expr.leftExpression = convertToTreeForm(exprNormal);
            if (exprListCopy.length > 1) {
                expr.rightExpression = getNewExpression(expr.operatorType, expr.isNegative);
                convertToTreeFormInner(exprListCopy, expr.rightExpression);
            }
            else if (exprListCopy.length) {
                expr.rightExpression = convertToTreeForm(exprListCopy[0]);
            }
        }
        else {
            throw new Error("Invalid expression");
        }
    }
}
function convertToTreeForm(expression) {
    function valToString(val) {
        function solveQuotes(strVal) {
            function isQuotedString() {
                const lastCharIdx = strVal.length - 1;
                return strVal.indexOf("'") === 0 && strVal.indexOf("'", lastCharIdx) === lastCharIdx;
            }
            return isQuotedString() === true ? strVal.substring(1, strVal.length - 1) : strVal;
        }
        return val instanceof Date ? dateToString(val) : solveQuotes((val || "").toString());
    }
    function getTargetVal() {
        const target = expression.target;
        return isSourceFunction(target) ? valToString(target()) : target;
    }
    const expr = buildLogicalExpression(expression.isNegative, expression.operator, getSourceVal(expression), expression.sourceSummary, getTargetVal(), expression.where ? convertToTreeForm(expression.where) : undefined);
    convertToTreeFormInner(expression.expressionList, expr);
    return expr;
}
exports.convertToTreeForm = convertToTreeForm;
function buildLogicalExpression(isNegative, operatorType, source, summaryFunction, targetFixedValue, where) {
    switch (operatorType) {
        case "and":
        case "or": {
            const expr = {
                operatorType,
                isNegative,
                leftExpression: {
                    isNegative: false,
                    operatorType: "equals",
                    source: "",
                    targetFixedValue: "",
                },
                rightExpression: {
                    isNegative: false,
                    operatorType: "equals",
                    source: "",
                    targetFixedValue: "",
                },
            };
            return expr;
        }
        default: {
            const expr = {
                isNegative,
                operatorType,
                source,
                summaryFunction,
                targetFixedValue,
                where: where ? where : undefined,
                target: undefined,
            };
            return expr;
        }
    }
}
function convertFromTreeFormSimple(expression, tryAutoFix) {
    switch (expression.operatorType) {
        case "and":
        case "or": {
            const expr = new Expression_1.Expression();
            expr.expressionList = [];
            expr.isNegative = expression.isNegative;
            expr.operator = expression.operatorType;
            if (expression.where) {
                expr.where = convertTreeFormLogicalExpressionToExpressionObject(expression.where);
            }
            return expr;
        }
        default: {
            const expr = new Expression_1.Expression();
            expr.expressionList = [];
            expr.isNegative = expression.isNegative;
            expr.operator = expression.operatorType;
            expr.sourceFieldSetter(getSourceValFromLogicalExpression(expression));
            expr.sourceSummary = expression.summaryFunction;
            expr.target = expression.targetFixedValue ? expression.targetFixedValue : expression.target;
            if (tryAutoFix && typeof expr.target === "undefined") {
                console.log(`Attempting to fix Malformed expression of type 4`);
                const fallback = new Expression_1.Expression();
                fallback.isNegative = expression.isNegative;
                fallback.operator = expression.operatorType;
                fallback.sourceFieldSetter(getSourceValFromLogicalExpression(expression));
                fallback.sourceSummary = expression.summaryFunction;
                fallback.target = expression.targetFixedValue ? expression.targetFixedValue : undefined;
                if (expression.where) {
                    fallback.where = convertTreeFormLogicalExpressionToExpressionObject(expression.where);
                }
                return fallback;
            }
            if (expression.where) {
                expr.where = convertTreeFormLogicalExpressionToExpressionObject(expression.where);
            }
            return expr;
        }
    }
}
function convertTreeFormLogicalExpressionToExpressionObject(expression, tryAutoFix) {
    if (isSimple(expression)) {
        return convertFromTreeFormSimple(expression, tryAutoFix);
    }
    else {
        const op = expression.operatorType;
        if (!(op === "and" || op === "or")) {
            throw new Error("");
        }
        const newExpression = new Expression_1.Expression();
        const expressionList = [];
        newExpression.operator = getOperator(toLower(op));
        newExpression.isNegative = expression.isNegative;
        if (expression.where) {
            newExpression.where = convertTreeFormLogicalExpressionToExpressionObject(expression.where, tryAutoFix);
        }
        let childExpression = expression;
        while (childExpression && childExpression.operatorType === op) {
            if (childExpression.leftExpression) {
                expressionList.push(convertTreeFormLogicalExpressionToExpressionObject(childExpression.leftExpression, tryAutoFix));
            }
            else {
                if (tryAutoFix) {
                    console.error("Malformed expression type 1 detected. Attempting to fix.");
                }
                else {
                    throw new Error("Malformed expression");
                }
            }
            childExpression = childExpression.rightExpression;
        }
        if (!childExpression) {
            if (tryAutoFix) {
                console.error("Malformed expression type 2 detected. Attempting to fix.");
                if (expressionList.length === 1) {
                    return expressionList[0];
                }
                newExpression.expressionList = expressionList;
                return newExpression;
            }
            else {
                throw new Error("Malformed expression");
            }
        }
        else {
            expressionList.push(convertTreeFormLogicalExpressionToExpressionObject(childExpression, tryAutoFix));
            newExpression.expressionList = expressionList;
            return newExpression;
        }
    }
}
exports.convertTreeFormLogicalExpressionToExpressionObject = convertTreeFormLogicalExpressionToExpressionObject;
function isSimple(expression) {
    return !(expression.operatorType === "and" || expression.operatorType === "or");
}
function enumerateXmlExpressions(expressionListOriginal, operator) {
    let r = "";
    const expressionList = expressionListOriginal.slice(0);
    if (expressionList.length) {
        const expression = expressionList.shift();
        if (!expression) {
            throw new Error("Unexpected error in expression");
        }
        r += `<LeftExpression>${convertToXmlExpression(expression)}</LeftExpression>`;
        if (expressionList.length > 1) {
            r += "<RightExpression>";
            r += "<Expression>";
            r += `<IsNegative>False</IsNegative>`;
            r += `<Operand>${getXmlOperator(operator)}</Operand>`;
            r += enumerateXmlExpressions(expressionList, operator);
            r += "</Expression>";
            r += "</RightExpression>";
        }
        else if (expressionList.length) {
            r += `<RightExpression>${convertToXmlExpression(expressionList[0])}</RightExpression>`;
        }
    }
    return r;
}
function convertToXmlExpression(expression) {
    let r = "<Expression>";
    r += `<IsNegative>${(0, utils_1.toPascalCase)(expression.isNegative.toString())}</IsNegative>`;
    r += `<Operand>${getXmlOperator(expression.operator)}</Operand>`;
    const source = expression.source;
    let sourceVal = "";
    if (isSourceFunction(source)) {
        sourceVal = source();
    }
    else if (source !== null) {
        sourceVal = source;
    }
    if (!!sourceVal) {
        r += `<Source>${sourceVal}</Source>`;
        if (expression.sourceSummary) {
            const xmlSummary = getXmlSummary(expression.sourceSummary);
            if (xmlSummary) {
                r += `<Function>${xmlSummary}</Function>`;
            }
        }
    }
    if (expression.expressionList && expression.expressionList.length > 0) {
        r += enumerateXmlExpressions(expression.expressionList, expression.operator);
    }
    else {
        const target = expression.target;
        if (isSourceFunction(target)) {
            r += `<TargetFixedValue dataType="${typeof target()}">${valueToString(target())}</TargetFixedValue>`;
        }
        else if (target !== null && target !== undefined) {
            r += `<TargetFixedValue dataType="${typeof target}">${valueToString(target)}</TargetFixedValue>`;
        }
        else {
            r += `<TargetFixedValue dataType="undefined"></TargetFixedValue>`;
        }
    }
    r += "</Expression>";
    return r;
}
exports.convertToXmlExpression = convertToXmlExpression;
function valueToString(target) {
    if (target instanceof Date) {
        return dateToString(target);
    }
    else {
        return (target || "").toString();
    }
}
function getXmlOperator(operator) {
    const operatorMap = {
        and: "And",
        contains: "Contains",
        "ends-with": "EndsWith",
        equals: "Equals",
        "greater-than": "GreaterThan",
        "greater-or-equals": "GreaterOrEquals",
        "lesser-than": "LesserThan",
        "lesser-or-equals": "LesserOrEquals",
        "not-equals": "NotEquals",
        or: "Or",
        "starts-with": "StartsWith",
    };
    return _transformPropertyFromMap(operatorMap, operator, "Equals");
}
function getNewExpression(opr, isNegative) {
    if (opr === "and" || opr === "or") {
        return {
            operatorType: opr,
            isNegative,
            leftExpression: {
                isNegative: false,
                operatorType: "equals",
                source: "",
                targetFixedValue: "",
            },
            rightExpression: {
                isNegative: false,
                operatorType: "equals",
                source: "",
                targetFixedValue: "",
            },
        };
    }
    const expr = {
        isNegative,
        operatorType: opr,
        source: "",
        summaryFunction: undefined,
        targetFixedValue: "",
        where: undefined,
        target: undefined,
    };
    return expr;
}
function getSourceVal(expression) {
    const source = expression.source;
    let sourceVal = "";
    if (isSourceFunction(source)) {
        sourceVal = source();
    }
    else if (source !== null && source !== undefined) {
        sourceVal = source;
    }
    return sourceVal;
}
function isExpressionWithSource(expression) {
    return [
        "greater-than",
        "lesser-than",
        "equals",
        "not-equals",
        "greater-or-equals",
        "lesser-or-equals",
        "contains",
        "starts-with",
        "ends-with",
        "custom",
    ].includes(expression.operatorType);
}
function getSourceValFromLogicalExpression(expression) {
    let sourceVal = "";
    if (isExpressionWithSource(expression)) {
        const source = expression.source;
        if (isSourceFunction(source)) {
            sourceVal = source();
        }
        else if (source !== null) {
            sourceVal = source;
        }
    }
    return sourceVal;
}
function _transformPropertyFromMap(map, prop, defVal) {
    if (!map[prop]) {
        console.log("NOT MAP PROP");
        console.log(prop);
        console.log(map[prop]);
    }
    return map[prop] || defVal;
}
function getXmlSummary(value) {
    const xmlSummaryMap = {
        averageOf: "AVG",
        countOf: "COUNT",
        every: "ALL",
        maximumOf: "MAX",
        minimumOf: "MIN",
        some: "ANY",
        sumOf: "SUM",
        valueOf: "",
    };
    return _transformPropertyFromMap(xmlSummaryMap, value, "");
}
function getOperator(value) {
    const operatorMap = {
        and: "and",
        contains: "contains",
        equals: "equals",
        greaterorequals: "greater-or-equals",
        greaterthan: "greater-than",
        lesserorequals: "lesser-or-equals",
        lesserthan: "lesser-than",
        notequals: "not-equals",
        or: "or",
        startswith: "starts-with",
        endswith: "ends-with",
    };
    return _transformPropertyFromMap(operatorMap, value, "equals");
}
function toLower(val) {
    if (val) {
        return val.toLowerCase();
    }
    else {
        return "";
    }
}
function _getOperatorFromXmlForm(operator) {
    return getOperator(toLower(operator));
}
function getSummaryFunctionName(value) {
    const summaryMap = {
        ALL: "every",
        ANY: "some",
        AVG: "averageOf",
        COUNT: "countOf",
        MAX: "maximumOf",
        MIN: "minimumOf",
        SUM: "sumOf",
    };
    return _transformPropertyFromMap(summaryMap, value, "valueOf");
}
exports.getSummaryFunctionName = getSummaryFunctionName;
