import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const IconsEssentialsTableSortAsc: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
  onClick,
}) => {
  const { colors } = useColors();

  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7272_104053)">
        <path
          d="M15.0308 20H24.9692C25.1729 19.9988 25.3718 19.9393 25.5409 19.8291C25.7101 19.7188 25.842 19.5626 25.9203 19.3799C25.9986 19.1972 26.0198 18.9962 25.9812 18.8019C25.9426 18.6076 25.846 18.4286 25.7034 18.2874L20.7342 13.2958C20.6379 13.2019 20.5235 13.1274 20.3975 13.0767C20.2715 13.0259 20.1364 12.9998 20 13C19.864 12.9997 19.7294 13.0257 19.6039 13.0765C19.4784 13.1273 19.3646 13.2019 19.269 13.2958L14.2966 18.2811C14.154 18.4224 14.0574 18.6014 14.0188 18.7957C13.9802 18.99 14.0014 19.191 14.0797 19.3737C14.158 19.5563 14.2899 19.7125 14.4591 19.8228C14.6282 19.9331 14.8271 19.9926 15.0308 19.9938V20Z"
          fill={calculateColor(color || colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_7272_104053">
          <rect width="12.0002" height="14.0002" fill="white" transform="translate(14 13)" />
        </clipPath>
      </defs>
    </svg>
  );
};
