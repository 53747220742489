"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalculatedField$Schema = exports.CalculatedExpressionType$Schema = exports.CalculatedExpressionSummary$Schema = exports.CalculatedExpressionOperator$Schema = exports.LogicalExpression$Schema = exports.LogicalSummaryFunctionTypes$Schema = exports.LogicalOperatorType$Schema = exports.LogicalNonCompositeOperator$Schema = exports.LogicalCompositeOperator$Schema = exports.isLogicalCompositeOperator = void 0;
const zod_1 = require("zod");
const fluency_shared_1 = require("../fluency-shared");
function isLogicalCompositeOperator(op) {
    return op === "and" || op === "or";
}
exports.isLogicalCompositeOperator = isLogicalCompositeOperator;
__exportStar(require("./expressionConversionFunctions"), exports);
exports.LogicalCompositeOperator$Schema = zod_1.z.enum([
    "and",
    "or",
]);
exports.LogicalNonCompositeOperator$Schema = zod_1.z.enum([
    "greater-than",
    "lesser-than",
    "equals",
    "not-equals",
    "greater-or-equals",
    "lesser-or-equals",
    "contains",
    "starts-with",
    "ends-with",
    "custom",
]);
exports.LogicalOperatorType$Schema = exports.LogicalCompositeOperator$Schema.or(exports.LogicalNonCompositeOperator$Schema);
const LogicalExpressionCompositePart$Schema = zod_1.z.strictObject({
    operatorType: exports.LogicalCompositeOperator$Schema,
    leftExpression: zod_1.z.lazy(() => exports.LogicalExpression$Schema),
    rightExpression: zod_1.z.lazy(() => exports.LogicalExpression$Schema),
});
const nonCompositeCommonPart = {
    source: zod_1.z.string().min(1, "source cannot be empty"),
    operatorType: exports.LogicalNonCompositeOperator$Schema,
};
const LogicalExpressionNonCompositePart$Schema = zod_1.z
    .strictObject(Object.assign(Object.assign({}, nonCompositeCommonPart), { target: zod_1.z.string(), targetFixedValue: zod_1.z.undefined().optional() }))
    .or(zod_1.z.strictObject(Object.assign(Object.assign({}, nonCompositeCommonPart), { target: zod_1.z.undefined().optional(), targetFixedValue: zod_1.z.string().or(zod_1.z.number()).or(zod_1.z.boolean()) })));
exports.LogicalSummaryFunctionTypes$Schema = zod_1.z.enum([
    "countOf",
    "sumOf",
    "averageOf",
    "minimumOf",
    "maximumOf",
    "some",
    "every",
    "valueOf",
]);
const LogicalExpressionHeader$Schema = zod_1.z.strictObject({
    isNegative: zod_1.z.boolean(),
    summaryFunction: exports.LogicalSummaryFunctionTypes$Schema.optional(),
    where: zod_1.z.lazy(() => exports.LogicalExpression$Schema.optional()),
});
exports.LogicalExpression$Schema = zod_1.z
    .object({})
    .passthrough()
    .superRefine((expression, ctx) => {
    const _a = expression, { isNegative, summaryFunction, where } = _a, rest = __rest(_a, ["isNegative", "summaryFunction", "where"]);
    const parsedHeader = LogicalExpressionHeader$Schema.safeParse({
        isNegative,
        summaryFunction,
        where,
    });
    if (!parsedHeader.success) {
        parsedHeader.error.issues.forEach((issue) => ctx.addIssue(issue));
        return zod_1.z.NEVER;
    }
    if (rest.operatorType === "and" || rest.operatorType === "or") {
        const parsedComposite = LogicalExpressionCompositePart$Schema.safeParse(rest);
        if (!parsedComposite.success) {
            parsedComposite.error.issues.forEach((issue) => ctx.addIssue(issue));
            return zod_1.z.NEVER;
        }
    }
    else {
        const parsedSimple = LogicalExpressionNonCompositePart$Schema.safeParse(rest);
        if (!parsedSimple.success) {
            parsedSimple.error.issues.forEach((issue) => ctx.addIssue(issue));
            return zod_1.z.NEVER;
        }
    }
})
    .transform((arg) => {
    return arg;
});
exports.CalculatedExpressionOperator$Schema = zod_1.z.enum([
    "None",
    "Add",
    "Subtract",
    "Multiply",
    "Divide",
    "Modulus",
]);
exports.CalculatedExpressionSummary$Schema = zod_1.z.enum([
    "None",
    "Sum",
    "Average",
    "Count",
]);
exports.CalculatedExpressionType$Schema = zod_1.z.enum([
    "FixedValue",
    "ContextValue",
    "Composite",
]);
const CalculatedFieldTypePart$Schema = zod_1.z.discriminatedUnion("type", [
    zod_1.z.strictObject({
        type: zod_1.z.literal("FixedValue"),
        fixedValueType: fluency_shared_1.DataType$Schema,
        fixedValue: zod_1.z.string().optional(),
    }),
    zod_1.z.strictObject({
        type: zod_1.z.literal("ContextValue"),
        contextFilterPath: zod_1.z.string().optional(),
        contextFullPath: zod_1.z.string().min(1),
        contextWhere: exports.LogicalExpression$Schema.optional(),
    }),
    zod_1.z.strictObject({
        type: zod_1.z.literal("Composite"),
        expressions: zod_1.z.array(zod_1.z.lazy(() => exports.CalculatedField$Schema)).min(1),
        operator: exports.CalculatedExpressionOperator$Schema,
        summary: exports.CalculatedExpressionSummary$Schema,
    }),
]);
exports.CalculatedField$Schema = zod_1.z
    .strictObject({
    dataType: fluency_shared_1.DataType$Schema,
})
    .and(CalculatedFieldTypePart$Schema);
