import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
};

export const CaretRightSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 25,
  height = 25,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1702 7.45555L16.6702 11.9235C16.8727 12.1399 16.9899 12.4224 17.0002 12.7185C16.9957 13.0174 16.8778 13.3035 16.6702 13.5185L12.1702 17.9825C12.0128 18.1399 11.8124 18.2471 11.5942 18.2905C11.3759 18.3339 11.1497 18.3117 10.9442 18.2265C10.7379 18.1518 10.5601 18.0144 10.4357 17.8337C10.3113 17.653 10.2464 17.4379 10.2502 17.2185V8.25055C10.25 8.02799 10.3158 7.81038 10.4394 7.62526C10.5629 7.44014 10.7386 7.29581 10.9442 7.21055C11.1496 7.12536 11.3758 7.10317 11.5939 7.14679C11.812 7.19041 12.0122 7.29788 12.1692 7.45555H12.1702Z"
        fill="#767676"
      />
    </svg>
  );
};
