import { FunctionComponent, ReactNode } from "react";
import { Box } from "../Box";

type Props = {
  children?: ReactNode;
};

export const FormHtml: FunctionComponent<Props> = ({ children }) => {
  return (
    <Box>
      <form>{children}</form>
    </Box>
  );
};
