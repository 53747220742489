import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DocumentSignatureIconSvg: FunctionComponent<Props> = ({
  color,
  width = 22,
  height = 20,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.35 4.45H13.44C13.56 4.45 13.66 4.35 13.66 4.23C13.66 3.83 13.49 3.45 13.18 3.2L9.76 0.34C9.5 0.12 9.17 0.01 8.83 0.01C8.65 0.01 8.5 0.16 8.5 0.34V3.6C8.5 4.07 8.88 4.45 9.35 4.45Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M13.08 13.83C13.1 14.46 12.59 15.02 11.92 15.02L12.53 17.97H13.66V13.63H13.06C13.07 13.7 13.08 13.76 13.08 13.83ZM13.19 11.64C13.08 11.75 13.01 11.89 13.01 12.06V12.34C13.01 12.34 13.01 12.38 13.02 12.4C13.01 12.41 13.02 12.42 13.02 12.43C13 12.49 13 12.56 13.01 12.63H13.66V11.49H13.58C13.43 11.49 13.29 11.55 13.19 11.64ZM9.35 5.61C8.23 5.61 7.33 4.71 7.32 3.59V0H1.44C0.65 0 0 0.65 0 1.44V16.54C0 17.33 0.65 17.97 1.44 17.97H11.51L10.94 15.22C10.88 14.93 10.96 14.62 11.15 14.39C11.34 14.15 11.62 14.02 11.92 14.02C11.97 14.02 12.01 14 12.04 13.96C12.08 13.92 12.09 13.88 12.08 13.83C12.03 13.57 12.07 13.31 12.21 13.09C12.08 12.87 12.01 12.61 12.01 12.34V12.06C12.01 11.23 12.65 10.56 13.46 10.5C13.5 10.49 13.54 10.49 13.58 10.49H13.66V5.61H9.35ZM4.45 14.22H2.39C2.05 14.22 1.77 13.94 1.77 13.6C1.77 13.26 2.05 12.99 2.39 12.99H4.45C4.8 12.99 5.06 13.27 5.06 13.6C5.06 13.93 4.8 14.22 4.45 14.22ZM7.74 11.95H2.39C2.05 11.95 1.77 11.68 1.77 11.33C1.77 11 2.05 10.72 2.39 10.72H7.74C8.08 10.72 8.36 11 8.36 11.33C8.36 11.68 8.08 11.95 7.74 11.95ZM7.74 9.52H2.39C2.05 9.52 1.77 9.24 1.77 8.9C1.77 8.56 2.05 8.29 2.39 8.29H7.74C8.08 8.29 8.36 8.57 8.36 8.9C8.36 9.23 8.08 9.52 7.74 9.52Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M5.06 13.6C5.06 13.94 4.79 14.22 4.45 14.22H2.39C2.05 14.22 1.77 13.94 1.77 13.6C1.77 13.26 2.05 12.99 2.39 12.99H4.45C4.79 12.99 5.06 13.27 5.06 13.6Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M8.36 11.33C8.36 11.67 8.08 11.95 7.74 11.95H2.39C2.05 11.95 1.77 11.67 1.77 11.33C1.77 10.99 2.05 10.72 2.39 10.72H7.74C8.08 10.72 8.36 11 8.36 11.33Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M1.77 8.9C1.77 8.57 2.05 8.29 2.39 8.29H7.74C8.08 8.29 8.36 8.57 8.36 8.9C8.36 9.23 8.08 9.52 7.74 9.52H2.39C2.05 9.52 1.77 9.24 1.77 8.9Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M8.36 8.9C8.36 9.24 8.08 9.52 7.74 9.52H2.39C2.05 9.52 1.77 9.24 1.77 8.9C1.77 8.56 2.05 8.29 2.39 8.29H7.74C8.08 8.29 8.36 8.57 8.36 8.9Z"
        fill="white"
      />
      <path
        d="M8.36 11.33C8.36 11.67 8.08 11.95 7.74 11.95H2.39C2.05 11.95 1.77 11.67 1.77 11.33C1.77 10.99 2.05 10.72 2.39 10.72H7.74C8.08 10.72 8.36 11 8.36 11.33Z"
        fill="white"
      />
      <path
        d="M5.06 13.6C5.06 13.94 4.79 14.22 4.45 14.22H2.39C2.05 14.22 1.77 13.94 1.77 13.6C1.77 13.26 2.05 12.99 2.39 12.99H4.45C4.79 12.99 5.06 13.27 5.06 13.6Z"
        fill="white"
      />
      <path
        d="M15.2 11.49L14.88 12.63H13.51C13.5 12.57 13.5 12.5 13.51 12.44L13.74 11.49H15.2Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M14.49 10.49L14.64 9.87C14.62 10.08 14.61 10.28 14.61 10.49H14.49Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M15.71 9.71C15.4 9.85 15.17 10.06 15.02 10.33C15.01 10.33 15.01 10.34 15.01 10.35L14.97 10.49H14.67C14.78 10.18 14.95 9.93 15.19 9.74C15.33 9.61 15.5 9.51 15.69 9.44C15.72 9.53 15.73 9.62 15.71 9.71Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M13.46 10.5C13.46 10.5 13.54 10.49 13.58 10.49H13.66V9.67L13.46 10.5ZM16.16 9.31C16.09 9.33 16.02 9.35 15.95 9.36C15.86 9.38 15.77 9.41 15.69 9.44C15.5 9.51 15.33 9.61 15.19 9.74L15.02 10.33C15.17 10.06 15.4 9.85 15.71 9.71C15.81 9.66 15.93 9.62 16.06 9.59C16.11 9.58 16.16 9.57 16.21 9.55C16.21 9.47 16.2 9.38 16.16 9.31ZM14.68 11.49L14.36 12.63H15.39L15.72 11.49H14.68ZM14.25 11.49H13.58C13.43 11.49 13.29 11.55 13.19 11.64L13.03 12.32C13.03 12.32 13.02 12.37 13.02 12.4C13.01 12.41 13.02 12.42 13.02 12.43C13 12.49 13 12.56 13.01 12.63H14.02L14 12.56L14.25 11.49Z"
        fill="white"
      />
      <path
        d="M16.16 9.31C16.09 9.33 16.02 9.35 15.95 9.36C15.86 9.38 15.77 9.41 15.69 9.44C15.5 9.51 15.33 9.61 15.19 9.74C14.95 9.93 14.78 10.18 14.67 10.49H14.94C14.96 10.44 14.98 10.39 15.01 10.35C15.01 10.35 15.01 10.33 15.02 10.33C15.17 10.06 15.4 9.85 15.71 9.71C15.81 9.66 15.93 9.62 16.06 9.59C16.11 9.58 16.16 9.57 16.21 9.55C16.21 9.47 16.2 9.38 16.16 9.31ZM14.61 10.49C14.61 10.28 14.62 10.08 14.64 9.87L14.49 10.49H14.61Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M21.65 0.6C21.65 0.68 21.63 0.76 21.59 0.84C20.8 2.63 19.24 6.11 19.24 6.11C19.18 6.25 19.05 6.35 18.91 6.39L18.93 6.51C18.95 6.67 18.9 6.83 18.78 6.95C18.39 7.32 17.31 8.44 16.97 9.13C16.88 9.3 16.76 9.54 16.51 9.69C16.4 9.75 16.3 9.79 16.2 9.82C16.16 9.82 16.11 9.84 16.07 9.85C15.9 9.89 15.75 9.96 15.62 10.05C15.45 10.15 15.31 10.3 15.22 10.49H14.97L15.01 10.35C15.01 10.35 15.01 10.33 15.02 10.33C15.17 10.06 15.4 9.85 15.71 9.71C15.81 9.66 15.93 9.62 16.06 9.59C16.11 9.58 16.16 9.57 16.21 9.55C16.26 9.54 16.32 9.52 16.38 9.48C16.57 9.36 16.66 9.17 16.75 9C17.18 8.15 18.59 6.78 18.61 6.77C18.67 6.71 18.69 6.63 18.68 6.55L18.61 6.09C18.61 6.09 18.72 6.15 18.78 6.15H18.79C18.89 6.15 18.97 6.09 19.01 6.01C19.01 6.01 20.59 2.48 21.38 0.7C21.42 0.6 21.4 0.49 21.32 0.41C21.28 0.37 21.21 0.34 21.15 0.34C21.11 0.34 21.08 0.34 21.04 0.37C19.04 1.38 17.3 3.16 15.87 5.67C15.45 6.41 15.12 7.11 14.88 7.78C14.81 7.96 14.75 8.14 14.71 8.32H14.7C14.47 9.08 14.35 9.8 14.36 10.49H13.98L14.15 9.75C14.21 9.13 14.36 8.49 14.6 7.82C14.85 7.1 15.2 6.34 15.65 5.55C17.1 3 18.87 1.18 20.92 0.15C20.98 0.12 21.05 0.1 21.12 0.1H21.16C21.43 0.1 21.65 0.32 21.65 0.6Z"
        fill="white"
      />
      <path
        d="M21.38 0.7C20.59 2.48 19.01 6.01 19.01 6.01C18.97 6.09 18.89 6.15 18.79 6.15H18.78C18.72 6.15 18.65 6.13 18.61 6.09L18.68 6.55C18.69 6.63 18.68 6.71 18.61 6.77C18.59 6.78 17.18 8.15 16.75 9C16.66 9.17 16.57 9.36 16.38 9.48C16.32 9.52 16.26 9.54 16.21 9.55C16.21 9.47 16.2 9.38 16.16 9.31C16.09 9.33 16.02 9.35 15.95 9.36C15.86 9.38 15.77 9.41 15.69 9.44C15.5 9.51 15.33 9.61 15.19 9.74C14.95 9.93 14.78 10.18 14.67 10.49H14.61C14.61 10.28 14.62 10.08 14.64 9.87L14.49 10.49H14.36C14.36 9.8 14.47 9.08 14.7 8.32H14.71C14.75 8.14 14.81 7.96 14.88 7.78C15.12 7.11 15.45 6.41 15.87 5.67C17.3 3.16 19.04 1.38 21.04 0.37C21.08 0.35 21.11 0.34 21.15 0.34C21.21 0.34 21.28 0.37 21.32 0.41C21.4 0.49 21.42 0.6 21.38 0.7Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M16.72 12.06V12.34C16.72 12.45 16.69 12.55 16.64 12.63H13.01C13 12.56 13 12.49 13.02 12.43C13.02 12.42 13.01 12.41 13.02 12.4C13.01 12.38 13.01 12.36 13.01 12.34V12.06C13.01 11.89 13.08 11.75 13.19 11.64C13.29 11.55 13.43 11.49 13.58 11.49H16.15C16.47 11.49 16.72 11.74 16.72 12.06Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M13.02 12.43C13 12.49 13 12.56 13.01 12.63H13.09C13.05 12.57 13.03 12.5 13.02 12.43Z"
        fill="white"
      />
      <path
        d="M18.59 14.39C18.4 14.15 18.11 14.02 17.81 14.02C17.76 14.02 17.72 14 17.69 13.96C17.66 13.92 17.65 13.88 17.66 13.83C17.71 13.57 17.66 13.31 17.52 13.09C17.65 12.87 17.72 12.61 17.72 12.34V12.06C17.72 11.19 17.02 10.49 16.15 10.49H13.58C13.58 10.49 13.5 10.49 13.46 10.5C12.65 10.56 12.01 11.23 12.01 12.06V12.34C12.01 12.61 12.08 12.87 12.21 13.09C12.07 13.31 12.03 13.57 12.08 13.83C12.09 13.88 12.08 13.92 12.04 13.96C12.01 14 11.97 14.02 11.92 14.02C11.62 14.02 11.34 14.15 11.15 14.39C10.96 14.62 10.88 14.93 10.94 15.22L11.51 17.97L11.55 18.18C11.66 18.69 12.11 19.05 12.63 19.05H17.11C17.63 19.05 18.08 18.69 18.18 18.18L18.79 15.22C18.85 14.93 18.78 14.62 18.59 14.39ZM13.01 12.34V12.06C13.01 11.89 13.08 11.75 13.19 11.64C13.29 11.55 13.43 11.49 13.58 11.49H16.15C16.47 11.49 16.72 11.74 16.72 12.06V12.34C16.72 12.45 16.69 12.55 16.64 12.63H13.01C13 12.56 13 12.49 13.02 12.43C13.02 12.42 13.01 12.41 13.02 12.4C13.01 12.38 13.01 12.36 13.01 12.34ZM17.2 17.98C17.2 17.98 17.15 18.05 17.11 18.05H12.63C12.63 18.05 12.54 18.02 12.53 17.98L11.92 15.02C12.59 15.02 13.1 14.46 13.08 13.83C13.08 13.76 13.07 13.7 13.06 13.63H16.68C16.66 13.7 16.66 13.76 16.66 13.83C16.63 14.46 17.15 15.02 17.81 15.02L17.2 17.98Z"
        fill="white"
      />
      <path
        d="M17.81 15.02L17.2 17.98C17.2 17.98 17.15 18.05 17.11 18.05H12.63C12.63 18.05 12.54 18.02 12.53 17.98L11.92 15.02C12.59 15.02 13.1 14.46 13.08 13.83C13.08 13.76 13.07 13.7 13.06 13.63H16.68C16.66 13.7 16.66 13.76 16.66 13.83C16.63 14.46 17.15 15.02 17.81 15.02Z"
        fill={color || "#5A5A5A"}
      />
    </svg>
  );
};
