import { Box, Heading, Link, Text, useColors } from "@prodoctivity/design-system";
import type {
  DocumentDeletedLogEntry,
  DocumentPermanentlyDeletedLogEntry,
  DocumentRestoredLogEntry,
  DocumentSavedLogEntry,
} from "@prodoctivity/types";
import { FunctionComponent, useCallback, useMemo, useState } from "react";

import { shouldNever } from "@prodoctivity/shared";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { OrganizationNavLink } from "../../OrganizationLink";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  id: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  entry:
    | DocumentDeletedLogEntry
    | DocumentSavedLogEntry
    | DocumentRestoredLogEntry
    | DocumentPermanentlyDeletedLogEntry;
  entryCount: number;
};

export const DocumentActionEntry: FunctionComponent<Props> = ({
  id,
  entry,
  entryCount,
  fullScreen,
  fullWidth,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [showDetails, setShowDetails] = useState(!!fullScreen);

  const viewDetails = useCallback(({ event }: any) => {
    event.preventDefault();
    setShowDetails(true);
  }, []);
  const hideDetails = useCallback(({ event }: any) => {
    event.preventDefault();
    setShowDetails(false);
  }, []);

  const actionNameTranslated = useMemo(() => {
    switch (entry.entryType) {
      case "document-saved":
        return resources.saved;
      case "document-deleted":
        return resources.deleted;
      case "document-restored":
        return resources.restored;
      case "document-permanently-deleted":
        return resources.permanentlyDeleted;
      default:
        shouldNever(entry as never);
        return "";
    }
  }, [entry, resources]);

  const actionTranslated = useMemo(() => {
    switch (entry.entryType) {
      case "document-saved":
        return resources.activities.entryTypes.documentSaved;
      case "document-deleted":
        return resources.activities.entryTypes.documentDeleted;
      case "document-restored":
        return resources.activities.entryTypes.documentRestored;
      case "document-permanently-deleted":
        return resources.activities.entryTypes.documentPermanentlyDeleted;
      default:
        shouldNever(entry as never);
        return "";
    }
  }, [entry, resources]);

  return (
    <Box display="flex" direction={fullWidth ? "row" : "column"} flex="grow">
      {fullWidth ? (
        <Box marginEnd={6}>
          <PublicProfile username={entry.username} onlyImage={true} sizeImage={48} />
        </Box>
      ) : null}

      <Box display="flex" direction="column">
        <Text inline={true} size="200">
          <Box display="flex" direction="column">
            <PublicProfile username={entry.username} onlyImage={false} size="300" />
            <Text inline={true} size="200">
              {actionNameTranslated} {entryCount}{" "}
              {entryCount === 1
                ? resources.document.toLocaleLowerCase()
                : resources.documents.toLocaleLowerCase()}
              .&nbsp;
            </Text>
            {fullWidth && !showDetails ? (
              <Link onClick={viewDetails} href="">
                <Text inline={true} size="200" color={colors.primary}>
                  {resources.activities.viewDetail}
                </Text>
              </Link>
            ) : null}
          </Box>
        </Text>
        {!showDetails ? (
          fullWidth || fullScreen ? null : (
            <Link onClick={viewDetails} href="">
              <Text inline={true} size="200" color={colors.primary}>
                {resources.activities.viewDetail}
              </Text>
            </Link>
          )
        ) : (
          <Box display="flex" direction="column">
            {fullScreen ? null : (
              <Link onClick={hideDetails} href="">
                <Text inline={true} size="100" color={colors.subtle}>
                  {resources.activities.hideDetail}
                </Text>
              </Link>
            )}
            <Box margin={1}></Box>
            <Box display="flex" direction="column" gap={4}>
              {fullScreen ? (
                <Box display="flex" gap={2} direction="column">
                  <Box borderStyle="sm" width={"100%"} height={1} />
                  <Heading accessibilityLevel={3} size="300" color={colors.black600}>
                    <Box display="flex">{actionTranslated}:&nbsp;</Box>
                  </Heading>
                  <Box display="flex" direction="row" gap={2}>
                    <Text inline={true} size="200">
                      {resources.activities.documentCount}:
                    </Text>
                    <Text inline={true} size="200" weight="bold">
                      {entryCount}
                    </Text>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" gap={2} direction="row">
                  <Box borderStyle="sm" width={"100%"} height={1} />
                  <Heading accessibilityLevel={3} size="300" color={colors.black600}>
                    <Box display="flex">{resources.activities.entryTypes.documentSaved}:&nbsp;</Box>
                  </Heading>
                  <Box display="flex" direction="row" gap={2}>
                    <Text inline={true} size="200">
                      {resources.activities.documentCount}:
                    </Text>
                    <Text inline={true} size="200" weight="bold">
                      {entryCount}
                    </Text>
                  </Box>
                </Box>
              )}

              {fullScreen ? null : (
                <Box>
                  <OrganizationNavLink to={`/activities/${id}`}>
                    <Text size="200" color={colors.primary}>
                      {resources.activities.viewEntry}
                    </Text>
                  </OrganizationNavLink>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
