import { Component, createRef } from "react";

import { Button } from "antd";
import type { ContextFieldProperties } from "@prodoctivity/shared/src/index-types";
import Signaturepad from "signature_pad";

type Props = {
  id: string;
  properties: ContextFieldProperties;
  resources: {
    clear: string;
  };
  disabled: boolean;
  value: any;
  onChange: (event: any) => void;
  onRemove: () => void;
};

export class ElectronicSignatureGrabber extends Component<Props> {
  canvasRef: any;
  constructor(props: Props) {
    super(props);

    this.canvasRef = createRef();
  }

  componentDidMount() {
    if (!this.props.disabled && this.canvasRef && this.canvasRef.current) {
      const signaturePad = new Signaturepad(this.canvasRef.current, {
        backgroundColor: "rgba(255, 255, 255, 0)",
        penColor: "rgb(0, 0, 0)",
        //onEnd: () => this.props.onChange(signaturePad),
      });

      signaturePad.addEventListener("endStroke", () => {
        this.props.onChange(signaturePad);
      });

      //Set onclick to clear button by ref
      const clearNode = document.getElementById(this.props.id + "Clear");
      if (clearNode)
        clearNode.onclick = () => {
          if (this.props) this.props.onRemove();
          signaturePad.clear();
        };

      const resizeCanvas = () => {
        if (this.canvasRef.current) {
          const ratio = Math.max(window.devicePixelRatio || 1, 1);
          this.canvasRef.current.width = this.canvasRef.current.parentNode.offsetWidth * ratio;
          this.canvasRef.current.height = this.canvasRef.current.parentNode.offsetHeight * ratio;
          this.canvasRef.current.getContext("2d").scale(ratio, ratio);
          signaturePad.clear(); // otherwise isEmpty() might return incorrect value
        }
      };

      window.addEventListener("resize", resizeCanvas);
      resizeCanvas();

      if (this.props.value) {
        const img = new Image();
        img.src = "data:image/png;base64," + this.props.value;

        img.onload = () => {
          this.canvasRef.current.width = img.width;
          this.canvasRef.current.height = img.height;
          this.canvasRef.current.getContext("2d").drawImage(img, 0, 0);
        };
      }
    }
  }

  render() {
    const { id, resources } = this.props;

    return (
      <div style={{ width: "100%", height: "250px" }}>
        <canvas
          className={id}
          id={id}
          ref={this.canvasRef}
          style={{
            width: "100%",
            height: "100%",
            border: "1px dashed #dddddd",
            cursor: "default",
          }}
        />
        <Button
          id={id + "Clear"}
          style={{
            marginLeft: "-77px",
            position: "absolute",
            zIndex: "100",
            width: "77px",
            borderRadius: "0 0 0 5px",
          }}
        >
          {resources.clear}
        </Button>
      </div>
    );
  }
}
