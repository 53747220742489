import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const LandingPageCardIcon1: FunctionComponent<Props> = () => {
  return (
    <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.10044 5.99932C0.25668 1.74177 0.412915 0.843393 0.998816 0.413733C1.46754 0.0621926 2.95182 -0.0549875 6.07662 0.0231326C8.45928 0.0621926 10.842 0.179373 11.3107 0.296553C11.9747 0.452793 12.209 0.765273 12.209 1.46835C12.209 2.05425 11.8575 2.67921 11.3107 3.03075C10.7248 3.46041 9.43579 3.61665 3.61584 3.61665V7.13206C3.61584 9.08506 3.57678 10.7646 3.53772 10.9599C3.53772 11.1162 3.22524 11.4287 2.8737 11.7021C2.52216 11.9755 1.97532 12.1708 1.66284 12.1708C1.35036 12.1708 0.80352 11.9364 0.49104 11.624C0.0223199 11.1943 -0.0558001 10.2178 0.10044 5.99932Z"
        fill="white"
      />
      <path
        d="M37.7934 2.36673C37.5199 1.58553 37.5199 1.19493 37.9496 0.726213C38.3793 0.218433 39.3558 0.101253 43.418 0.101253C47.2068 0.140313 48.5349 0.257493 49.1208 0.726213C49.8238 1.27305 49.902 1.70271 49.7848 6.27274C49.7067 10.0225 49.5504 11.3896 49.1208 11.8193C48.6521 12.288 48.3005 12.327 47.4412 12.0146C46.3875 11.6243 46.3866 11.585 46.1918 3.63834L46.1913 3.61665H42.6759C40.7229 3.61665 38.9261 3.57759 38.6917 3.53853C38.4183 3.46041 38.0277 2.95263 37.7934 2.36673Z"
        fill="white"
      />
      <path
        d="M9.90451 13.8894C9.70921 12.327 9.74826 12.1708 11.1544 11.0381C11.9747 10.4131 12.9902 9.86626 13.3808 9.86626C13.8105 9.86626 14.6308 10.1006 15.2167 10.4131C15.8416 10.6865 16.5447 11.3896 16.7791 11.9755C17.0525 12.5223 17.2869 13.2645 17.2869 13.577C17.2869 13.8894 17.0525 14.6316 16.74 15.2565C16.4666 15.8424 15.7635 16.5455 15.1776 16.8189C14.6308 17.0533 13.7715 17.2877 13.2637 17.2877C12.795 17.2877 11.8966 16.8971 11.2716 16.3893C10.4513 15.7643 10.0998 15.0612 9.90451 13.8894Z"
        fill="white"
      />
      <path
        d="M20.8804 12.288C21.3491 11.5849 22.2865 10.7256 22.9115 10.4131C23.6146 10.1006 24.7083 9.94438 25.4895 10.0616C26.2316 10.1787 27.2472 10.5303 27.7159 10.8428C28.2237 11.1552 28.8877 12.0536 29.2002 12.7958C29.5126 13.5379 29.7861 14.6316 29.7861 15.2565C29.7861 15.8815 29.3173 16.8971 28.5361 17.7954C27.4815 19.0063 26.8956 19.3578 25.4895 19.5141C24.1614 19.6703 23.4193 19.5531 22.4428 19.0063C21.7397 18.5766 20.9194 17.6783 20.607 17.0142C20.2945 16.3112 20.0211 15.2565 20.0211 14.6706C20.0211 14.0457 20.4117 12.9911 20.8804 12.288Z"
        fill="white"
      />
      <path
        d="M32.6765 12.7176C32.8327 11.8193 33.3015 11.1552 34.1608 10.6475C35.0592 10.1006 35.8013 9.90532 36.6216 10.0616C37.2465 10.1787 38.184 10.6865 38.6917 11.1552C39.2776 11.7021 39.6292 12.4833 39.6292 13.3817C39.6683 14.1629 39.3558 15.2175 38.9261 15.8034C38.5355 16.3893 37.6371 16.9752 37.0122 17.1314C36.3091 17.2877 35.3716 17.1705 34.6686 16.8189C34.0436 16.5455 33.2624 15.7643 32.9499 15.1394C32.6374 14.4363 32.5203 13.4598 32.6765 12.7176Z"
        fill="white"
      />
      <path
        d="M8.14681 21.9749C8.45929 21.4671 9.1233 20.8031 9.59202 20.5296C10.1389 20.2172 11.9747 20.0219 18.4587 20.0219L16.5057 21.7796C15.2948 22.8732 14.4355 24.006 14.2402 24.8262C13.9277 26.0371 13.8496 26.0762 11.5841 26.1933C10.0217 26.2324 9.00613 26.0762 8.38117 25.6856C7.83433 25.334 7.52184 24.7091 7.52184 24.045C7.52184 23.4201 7.79527 22.5217 8.14681 21.9749Z"
        fill="white"
      />
      <path
        d="M35.6451 25.0215C35.4107 24.3185 34.317 22.9123 33.1843 21.8967L31.1532 20.0219H35.3716C39.4339 20.0219 39.5901 20.0609 40.9182 21.1937C41.9337 22.053 42.2853 22.6779 42.2853 23.5373C42.2853 24.2794 41.9728 24.9825 41.3869 25.4512C40.7619 25.998 39.9026 26.2324 38.2621 26.2324C36.0747 26.2715 36.0357 26.2324 35.6451 25.0215Z"
        fill="white"
      />
      <path
        d="M17.4822 24.7091C17.9899 23.9279 19.0446 23.1076 19.7086 22.8732C20.4117 22.6389 22.9506 22.4826 25.3723 22.5217C29.122 22.5608 29.9032 22.6779 30.8407 23.381C31.4266 23.8497 32.2859 24.7091 32.7546 25.334C33.3015 26.1152 33.4968 26.8574 33.3796 27.6776C33.3015 28.3026 32.989 29.0447 32.7546 29.3181C32.4421 29.7087 30.4891 29.8259 24.8254 29.8259C19.357 29.8259 17.1306 29.6697 16.7791 29.3181C16.4666 29.0447 16.2713 28.2635 16.3494 27.4823C16.4275 26.7011 16.9353 25.4512 17.4822 24.7091Z"
        fill="white"
      />
      <path
        d="M0.0223152 33.146C-0.0558049 28.8885 0.0613823 27.8729 0.530103 27.7167C0.842583 27.5995 1.38942 27.5214 1.78002 27.4823C2.13156 27.4823 2.71746 27.8729 3.02994 28.3416C3.4596 28.9666 3.61584 30.2556 3.61584 36.4661L7.59997 36.5443C10.881 36.6224 11.6231 36.7786 11.9747 37.3255C12.2872 37.8332 12.2872 38.1848 11.9747 38.6926C11.6231 39.2785 10.8029 39.3956 6.42817 39.4738C2.28781 39.5519 1.15506 39.4738 0.68634 39.005C0.21762 38.5363 0.100435 37.2083 0.0223152 33.146Z"
        fill="white"
      />
      <path
        d="M46.1131 32.4039C46.0741 29.3181 46.1522 28.3026 46.5819 27.912C46.8943 27.6386 47.5974 27.4823 48.1443 27.5604C48.6911 27.5995 49.3161 28.0682 49.5895 28.6541C49.902 29.2791 50.0582 31.1149 49.9801 34.0444C49.902 38.1067 49.8238 38.4973 49.0426 39.1222C48.3005 39.6691 47.4412 39.7472 43.2227 39.5909C39.0823 39.3956 38.184 39.2785 37.8324 38.6926C37.5199 38.1848 37.5199 37.8332 37.8324 37.3255C38.184 36.7786 38.9652 36.6224 46.1913 36.4661L46.1131 32.4039Z"
        fill="white"
      />
    </svg>
  );
};
