import {
  Box,
  BoxWithAnimations,
  Grid,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";

import { range } from "@prodoctivity/shared";
import type { ListableTemplateWithCombined } from "@prodoctivity/types";
import { useCallback } from "react";
import { useAppTranslation } from "../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../link-templates";
import { TemplateCardSkeleton } from "../loading";
import { Pagination } from "./Layout/Pagination";
import { Topic } from "./Layout/Topic";
import { SmallRefreshButton } from "./SmallRefreshButton";
import { TemplateCard } from "./TemplateCard";
import { useTemplateList } from "./hooks";
import { NoResultsFoundBlock } from "./NoResultsFoundBlock";
import { EmptyStateWidgetContent } from "../svg/EmptyStateWidgetContent";

const templateAnimationSet = {
  initial: {
    display: "flex",
    opacity: 0,
    scale: 0.5,
    translate: "0 100px",
    width: "100%",
  },
  whileInView: { opacity: 1, scale: 1, translate: "0 0px" },
  transition: { duration: 0.7, animationFillMode: "both" },
  viewport: { once: true },
};

type Props = {
  filterText?: string;
  action?: "generate";
  hideTitle?: boolean;
  hideRefreshButton?: boolean;
};

export function TemplateList({ filterText, action, hideTitle, hideRefreshButton }: Props) {
  const { colors } = useColors();
  const {
    isLoading,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    rowsPerPage,
    pageLengthOptions,
    totalRowCount,
    previousPage,
    refetch,
    setPageLength,
    templates,
  } = useTemplateList(action, filterText);

  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const goToTemplateVersion = useCallback(
    (type: ListableTemplateWithCombined["type"], id: string) => {
      if (type === "template") {
        const navigateTo =
          action === "generate"
            ? organizationLinkTemplates.templateVersionGeneratePage(id, undefined)
            : organizationLinkTemplates.templateVersionDetailPage(id);
        organizationNavigate(navigateTo);
      } else {
        const navigateTo =
          action === "generate"
            ? organizationLinkTemplates.generateCombinedTemplate(id, undefined)
            : organizationLinkTemplates.combinedTemplateDetails(id);
        organizationNavigate(navigateTo);
      }
    },
    [action, organizationNavigate]
  );
  const { breakpoint } = useDesignBreakpoint();
  const gridTemplateColumns =
    breakpoint === "small"
      ? ["repeat(auto-fill, 320px)"]
      : ["repeat(auto-fill, minmax(265px, 1fr)"];
  const gridTemplateRows = ["repeat(auto-fill, 420px)"];

  if (isLoading) {
    return (
      <>
        {!hideTitle && (
          <>
            <Box paddingX={1}>
              <Topic>{resources.templates}</Topic>
            </Box>
            <Box margin={1} />
          </>
        )}

        <Box
          width={"100%"}
          display="flex"
          flex="grow"
          direction="column"
          marginTop={12}
          marginBottom={4}
        >
          <Grid
            width="100%"
            gridTemplateColumns={gridTemplateColumns}
            gridTemplateRows={gridTemplateRows}
            gap={4}
            justifyContent="center"
          >
            {range(8).map((_, i) => (
              <BoxWithAnimations animationSet={templateAnimationSet} key={i}>
                <Box
                  key={`${i}`}
                  display="flex"
                  borderStyle="sm"
                  color={colors.neutral200}
                  maxHeight={415}
                  width={"100%"}
                  maxWidth={400}
                  borderRadius={12}
                  overflow="hidden"
                >
                  <TemplateCardSkeleton key={i} />
                </Box>
              </BoxWithAnimations>
            ))}
          </Grid>
        </Box>
      </>
    );
  }

  const hasWidth = breakpoint === "large" || breakpoint === "hd";

  return (
    <>
      {hasWidth ? (
        <Box
          paddingX={4}
          paddingBottom={4}
          alignItems="center"
          display="flex"
          direction="row"
          gap={2}
        >
          <Box display="flex">
            {hideTitle ? <Topic></Topic> : <Topic>{resources.templates}</Topic>}
          </Box>
          {/* <Box marginStart={3} /> */}

          <Box display="flex" flex="grow" />
          {hideRefreshButton && (
            <SmallRefreshButton
              marginTop={0}
              accessibilityLabel={resources.refresh}
              refetch={refetch}
            />
          )}
          <Box display="flex" flex="shrink" marginTop={-3}>
            <Pagination<typeof rowsPerPage>
              id="template_list_pagination_top"
              rowsLabel={`${resources.templates}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={templates?.length || 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={pageLengthOptions}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      ) : (
        <Box paddingY={4} paddingX={1} display="flex" marginTop={6} justifyContent="between">
          <SmallRefreshButton accessibilityLabel={resources.refresh} refetch={refetch} />
          <Box display="flex">
            <Pagination<typeof rowsPerPage>
              id="template_list_pagination_top"
              rowsLabel={`${resources.templates}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={templates?.length || 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={pageLengthOptions}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      )}

      <Box width={"100%"} display="flex" flex="grow" direction="column">
        {templates?.length === 0 ? (
          <NoResultsFoundBlock
            key={`${templates?.length}_not-found`}
            messageTitle={resources.dashboard.noContentFound}
            messageDescription={""}
            illustration={<EmptyStateWidgetContent width={"100%"} height={"100%"} />}
          />
        ) : (
          <Grid
            width={hasWidth ? "100%" : undefined}
            gridTemplateColumns={gridTemplateColumns}
            gridTemplateRows={gridTemplateRows}
            gap={4}
            justifyContent="center"
          >
            {(templates || []).map((template, i) => (
              <BoxWithAnimations animationSet={templateAnimationSet} key={`${i}${template.name}`}>
                <Box
                  key={`${i}${template.name}`}
                  display="flex"
                  borderStyle="sm"
                  color={colors.neutral200}
                  maxHeight={415}
                  width={"100%"}
                  maxWidth={400}
                  borderRadius={12}
                  overflow="hidden"
                >
                  <TemplateCard template={template} onClick={goToTemplateVersion} />
                </Box>
              </BoxWithAnimations>
            ))}
          </Grid>
        )}

        <Box display="flex" direction="row">
          <Box display="flex" flex="grow" />
          <Box
            marginTop={5}
            padding={2}
            display="flex"
            direction="column"
            flex="shrink"
            alignItems={breakpoint === "small" || breakpoint === "medium" ? "center" : undefined}
          >
            <Pagination<typeof rowsPerPage>
              id="template_list_pagination_bottom"
              rowsLabel={`${resources.templates}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={templates?.length || 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={pageLengthOptions}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
