import { getContextField, shouldNever } from "@prodoctivity/shared";
import type {
  StringTemplatePart,
  TemplateContextRecordHolder,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent } from "react";
import { Box } from "../Box";
import { useColors } from "../ColorSchemeProvider";
import { Text } from "../Text";

type Props = {
  parts: StringTemplatePart[];
  contextDefinition: TemplateContextRecordHolder | undefined;
};

export const StringTemplatePartListDisplay: FunctionComponent<Props> = ({
  parts,
  contextDefinition,
}) => {
  const { colors } = useColors();

  return (
    <Box display="flex" direction="row" flex="shrink" gap={1} wrap={true}>
      {parts.map((n, index) => {
        if (n.type === "text") {
          return (
            <Text key={`text_${n.value}_${index}`} inline={true}>
              {n.value}
            </Text>
          );
        } else if (n.type === "variable") {
          const field = contextDefinition ? getContextField(contextDefinition, n.name) : undefined;

          if (!field) {
            return (
              <Text
                key={`var_${n.name}_${index}`}
                weight="bold"
                color={colors.primary}
                inline={true}
              >
                [{n.name}]
              </Text>
            );
          }

          return (
            <Text key={`var_${n.name}_${index}`} weight="bold" color={colors.primary} inline={true}>
              [{field.humanName}]
            </Text>
          );
        } else {
          shouldNever(n);
          return null;
        }
      })}
    </Box>
  );
};
