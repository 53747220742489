import { CompositeZIndex, FixedZIndex, Layer as GestaltLayer, LayerProps } from "gestalt";

import { FunctionComponent } from "react";

const Layer: FunctionComponent<LayerProps> = (props) => {
  return <GestaltLayer {...props} />;
};

export const HEADER_ZINDEX = new FixedZIndex(2200);
export const modalZIndex = new CompositeZIndex([HEADER_ZINDEX]);
export const dropDownZIndex = new CompositeZIndex([modalZIndex]);
export const backIndex = new FixedZIndex(-1);
export const frontIndex = new FixedZIndex(10);

export { Layer };
