import {
  Box,
  Checkbox,
  DatePicker,
  Divider,
  OverlayPanel,
  TapArea,
  dropDownZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useState } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { ActivitySearch } from "../../interfaces/activities/ActivitySearch";
import { ExpandIconSvg } from "../../svg/Tasks/ExpandIconSvg";
import { Topic } from "../Layout/Topic";
import { LeftSide } from "./ActivityMenuSearch";

interface Props {
  search: ActivitySearch;
  handleChange: (d: any) => void;
  endDateInput?: React.MutableRefObject<null>;
  startDateInput?: React.MutableRefObject<null>;
  handleChangeDate: (d: any) => void;
  handleChangeDateTo: (d: any) => void;
}
export const MenuSearch: FunctionComponent<Props> = ({
  handleChange,
  handleChangeDate,
  handleChangeDateTo,
  search,
}) => {
  const resources = useAppTranslation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Box paddingX={4} display="flex" direction="column">
      <Box margin={3}></Box>
      <Box>
        <TapArea onTap={() => setShowMobileMenu(true)}>
          <ExpandIconSvg />
        </TapArea>
      </Box>
      {showMobileMenu && (
        <Box zIndex={dropDownZIndex}>
          <OverlayPanel
            accessibilityLabel={resources.resources.actions}
            heading=""
            onDismiss={() => {
              setShowMobileMenu(false);
            }}
            size="lg"
          >
            <ActivityMenu
              handleChangeDateTo={handleChangeDateTo}
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              search={search}
            />
          </OverlayPanel>
        </Box>
      )}
    </Box>
  );
};
const ActivityMenu = ({
  handleChangeDateTo,
  search,
  handleChange,
  endDateInput,
  startDateInput,
  handleChangeDate,
}: Props) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const ActivityBreakPoint = breakpoint === "medium" || breakpoint === "small";
  return (
    <LeftSide>
      <Box paddingX={4} display="flex" direction="column">
        {ActivityBreakPoint && <Topic>{resources.activities.typeOfActivity}</Topic>}
        {search.listEntryTypes.map((entry) => {
          return (
            <Box key={entry.code} margin={2}>
              <Checkbox
                label={entry.name}
                id={entry.code}
                onChange={handleChange}
                checked={search.entryTypes?.some((element) => element.code === entry.code)}
              ></Checkbox>
            </Box>
          );
        })}
        <Divider direction="horizontal" color={colors.neutral500} />
        <Topic>{resources.activities.dateRange}</Topic>
        <Box display="flex" gap={2} direction="column">
          <DatePicker
            resources={resources}
            // rangeStartDate={search && search.fromDate}
            rangeEndDate={search && search.toDate}
            id="fromDate"
            label={resources.from}
            placeholder={resources.from}
            nextRef={endDateInput}
            onChange={handleChangeDate}
            rangeSelector="start"
            value={search && search.fromDate}
            ref={startDateInput}
            dataType="Date"
          />
          <DatePicker
            resources={resources}
            rangeStartDate={search && search.fromDate}
            // rangeEndDate={search && search.toDate}
            id="toDate"
            label={resources.to}
            placeholder={resources.to}
            nextRef={startDateInput}
            onChange={handleChangeDateTo}
            rangeSelector="end"
            value={search && search.toDate}
            ref={endDateInput}
            dataType="Date"
          />
        </Box>
        <Box marginTop={12} />
      </Box>
    </LeftSide>
  );
};
