import {
  Box,
  Divider,
  OverlayPanel,
  Svg,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useState } from "react";

import { ButtonLink } from "@prodoctivity/design-system/components/Button/Button";
import { useNavigate } from "react-router-dom";
import logoUrl from "../../assets/logo.png";
import { LanguageSwitcher } from "../../components/Layout/LanguageSwitcher";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useSettings } from "../../hooks/useSettings";
import { HamburgerMenuIcon } from "../../svg/HamburgerMenuIcon";

export const VisitorsHeader: FunctionComponent = () => {
  const { breakpoint } = useDesignBreakpoint();
  const breakPointHD = breakpoint === "hd";
  const breakPointSmallDuo = breakpoint === "small" || breakpoint === "medium";
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const navigate = useNavigate();
  const navigateTo = useCallback(
    (route: string) => {
      navigate(route);
    },
    [navigate]
  );
  const { disableSignUp } = useSettings();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Box width={"100%"} display="flex" direction="row">
      <Box width={"100vw"} height={64} marginBottom={5} display="flex" direction="column"></Box>
      <Box
        direction="column"
        height={64}
        width={"100%"}
        color={colors.white}
        dangerouslySetInlineStyle={{
          __style: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.12)",
            zIndex: 1000,
            top: 0,
          },
        }}
        position="fixed"
        alignContent="center"
        justifyContent="center"
        display="flex"
      >
        <Box
          display="flex"
          direction="row"
          width={"100%"}
          paddingX={breakpoint === "small" ? 4 : 8}
        >
          <Box display="flex" direction="row" flex="grow">
            <Box paddingX={4} display="flex" alignSelf="center" flex="shrink">
              <TapArea onTap={() => navigateTo("/")}>
                <Box alignItems="center" display="flex">
                  <Svg src={logoUrl} alt="logo" width={170} />
                </Box>
              </TapArea>
            </Box>
            <Box display="flex" flex="grow" />
            {!breakPointSmallDuo && breakPointHD && (
              <Box display="flex" alignItems="center" gap={12} flex="shrink">
                <Box display="flex" direction="row">
                  <ButtonLink
                    href="/product"
                    text={resources.product}
                    accessibilityLabel={resources.product}
                    color="transparent"
                  />
                </Box>
                {!disableSignUp && (
                  <Box display="flex" direction="row">
                    <ButtonLink
                      href="/pricing"
                      text={resources.pricing}
                      accessibilityLabel={resources.pricing}
                      color="transparent"
                    />
                  </Box>
                )}
                {/* <Box display="flex" direction="row">
                  <ButtonLink
                    href="/"
                    text={resources.support}
                    accessibilityLabel={resources.support}
                    color="transparent"
                  />
                </Box> */}
              </Box>
            )}
          </Box>
          <Box display="flex" flex="grow" />

          <Box display="flex">
            {breakPointSmallDuo ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <TapArea>
                  <HamburgerMenuIcon onClick={() => setShowMobileMenu(true)} />
                </TapArea>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" height={"100%"} gap={2}>
                <LanguageSwitcher />
                {/* <Box marginEnd={-3} height={40}>
                  <LandingPageLanguageIcon />
                </Box>
                <TapArea onTap={() => navigateTo("/")}>
                  <Box alignItems="center" display="flex" width={100} justifyContent="center">
                    <Text size="300">{resources.languages.english}</Text>
                  </Box>
                </TapArea>
                <Box width={1} height={30} color={colors.neutral600}></Box> */}

                {disableSignUp ? (
                  <Box>
                    <ButtonLink
                      accessibilityLabel={resources.signIn}
                      text={resources.signIn}
                      href="/login"
                    />
                  </Box>
                ) : (
                  <ButtonLink
                    accessibilityLabel={resources.signIn}
                    href="/login"
                    text={resources.signIn}
                    color={"transparent"}
                  />
                )}

                {!disableSignUp && (
                  <Box color={colors.primary} rounding={4}>
                    <ButtonLink
                      accessibilityLabel={resources.signUp}
                      text={resources.signUp}
                      href="/sign-up"
                      color="blue"
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {showMobileMenu && (
            <Box position="fixed">
              <OverlayPanel
                accessibilityLabel={resources.actions}
                heading=""
                onDismiss={() => setShowMobileMenu(false)}
                size="lg"
              >
                <Box
                  color={colors.white}
                  position="fixed"
                  top={true}
                  left={true}
                  width={"100vw"}
                  height={"100vh"}
                >
                  <Box display="flex" alignSelf="center" direction="column" padding={2}>
                    <Box margin={4} />
                    <TapArea onTap={() => navigateTo("/")}>
                      <Box alignItems="center" display="flex">
                        <Svg src={logoUrl} alt="logo" width={180} />
                      </Box>
                    </TapArea>

                    <Box display="flex" direction="column" minWidth={"100%"} gap={4}>
                      <Box paddingY={6} />
                      <Box
                        color={disableSignUp ? colors.primary : colors.neutral400}
                        rounding={4}
                        hoverColor={colors.neutral500}
                      >
                        <ButtonLink
                          accessibilityLabel={resources.signIn}
                          text={resources.signIn}
                          href="/login"
                          color={disableSignUp ? "blue" : undefined}
                          fullWidth={true}
                          size="lg"
                        />
                      </Box>
                      {!disableSignUp && (
                        <Box color={colors.primary} rounding={4} flex="grow">
                          <ButtonLink
                            accessibilityLabel={resources.signUp}
                            text={resources.signUp}
                            href="/sign-up"
                            color={"blue"}
                            fullWidth={true}
                            size="lg"
                          />
                        </Box>
                      )}
                    </Box>

                    <Box display="flex" direction="column" justifyContent="start" gap={5}>
                      <Box paddingY={2} />
                      <TapArea
                        onTap={() => {
                          navigateTo("/product");
                          setShowMobileMenu(false);
                        }}
                      >
                        <Box width={"100%"} hoverColor={colors.primaryHover0} padding={2}>
                          <Text weight="bold" size="300">
                            {resources.product}
                          </Text>
                        </Box>
                      </TapArea>
                      {!disableSignUp && (
                        <TapArea
                          onTap={() => {
                            navigateTo("/pricing");
                            setShowMobileMenu(false);
                          }}
                        >
                          <Box width={"100%"} hoverColor={colors.primaryHover0} padding={2}>
                            <Text weight="bold" size="300">
                              {resources.pricing}
                            </Text>
                          </Box>
                        </TapArea>
                      )}
                      <TapArea
                        onTap={() => {
                          navigateTo("/");
                          setShowMobileMenu(false);
                        }}
                      >
                        <Box width={"100%"} hoverColor={colors.primaryHover0} padding={2}>
                          <Text weight="bold" size="300">
                            {resources.support}
                          </Text>
                        </Box>
                      </TapArea>
                    </Box>
                    <Divider />
                    <Box paddingY={2}></Box>
                    <LanguageSwitcher />
                  </Box>
                </Box>
              </OverlayPanel>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
