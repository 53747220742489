import {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

import type { GlobalPermissionType } from "@prodoctivity/shared/src/index-types";
import { Spinner } from "@prodoctivity/design-system";
import { useMutation } from "@tanstack/react-query";
import { useServices } from "../../hooks/useServices";
import { useCacheManager } from "../../utils";

type Props = PropsWithChildren<{
  permission?: GlobalPermissionType;
  loggedOutRoute?: string;
  children: ReactElement;
}>;

export const RouteNeedsPermission: FunctionComponent<Props> = ({
  permission,
  children,
  loggedOutRoute,
}) => {
  const { user, switchOrganization } = useServices();
  const location = useLocation();
  const [isError, setIsError] = useState(false);
  const { organizationId } = useParams();
  const { clearQueryCache } = useCacheManager();
  const doSwitchOrganization = useCallback(async () => {
    if (organizationId) {
      clearQueryCache();
      await switchOrganization(organizationId);
    }
  }, [organizationId, clearQueryCache, switchOrganization]);
  const { mutate: mutateSwitchOrganization } = useMutation(doSwitchOrganization, {
    onError() {
      setIsError(true);
    },
  });

  useEffect(() => {
    if (user && user.organizationId !== organizationId) {
      mutateSwitchOrganization();
    }
  }, [user, organizationId, mutateSwitchOrganization]);

  if (isError) {
    return <Navigate to="/" replace={true} />;
  }

  if (user) {
    if (!organizationId || user.organizationId === organizationId) {
      if (permission && user.permissions.indexOf(permission) < 0) {
        return (
          <Navigate
            to={loggedOutRoute ?? `/login?returnTo=${encodeURIComponent(location.pathname)}`}
            state={{ from: location }}
            replace={true}
          />
        );
      }

      return children;
    } else {
      return <Spinner show={true} accessibilityLabel="..." />;
    }
  }

  if (location.pathname === "/") {
    return <Navigate to={"/info"} replace={true} />;
  }

  return (
    <Navigate
      to={loggedOutRoute ?? `/login?returnTo=${encodeURIComponent(location.pathname)}`}
      state={{ from: location }}
      replace={true}
    />
  );
};

type ComponentNeedsPermissionProps = PropsWithChildren<{
  permission: GlobalPermissionType;
}>;

export const ComponentNeedsPermission: FunctionComponent<ComponentNeedsPermissionProps> = ({
  permission,
  children,
}) => {
  const { user } = useServices();
  const { organizationId } = useParams();

  if (user) {
    if (!organizationId || user.organizationId === organizationId) {
      if (permission && user.permissions.indexOf(permission) < 0) {
        return null;
      }

      return children;
    } else {
      return null;
    }
  }

  return null;
};
