import {
  Box,
  ColorConfig,
  Icon,
  IconButton,
  Image,
  ImagePicker,
  Spinner,
  Text,
  popupZIndex,
  useColors,
} from "@prodoctivity/design-system";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../../../hooks/useOrganizationQuery";
import { useServices } from "../../../../hooks/useServices";
import { useUserProfile } from "../../../../hooks/useUserProfile";
import { GraphicMarkStateForm } from "./hooks";
import { CertificateData } from "../../../../components/Documents/ViewerSignatureWidget";

type Props = {
  index: number;
  graphicMark: Omit<GraphicMarkStateForm, "errors">;
  building: boolean;
  setFormState?: Dispatch<SetStateAction<GraphicMarkStateForm>>;
  handlePicChange: (image: string) => void;
  clearPicture: () => void;
};

type Certificate = {
  name: string;
  distinguishedName: string;
  date: number;
  logo: string | undefined;
  email: string;
  url: string | undefined;
  waterMark: string | undefined;
};

export const GraphicMarkConfigWidget = ({
  index,
  handlePicChange,
  clearPicture,
  graphicMark,
}: Props) => {
  const { colors } = useColors();
  const { data } = useUserProfile();
  const { resources, moment } = useAppTranslation();
  let isEven = true;

  const certificate: Certificate = {
    name: "Certificate 10",
    distinguishedName: "Certificate of Merit 2",
    date: Date.now(),
    logo: "https://cloudx.prodoctivity.com/info",
    email: data?.email || "example10@example.com",
    url: "[URL]",
    waterMark: "https://cloudx.prodoctivity.com/info",
  };

  const renderRow = (label: string, value: string) => {
    const rowColor = isEven ? colors.neutral250 : colors.white;
    isEven = !isEven;
    return (
      <Box
        key={label}
        padding={3}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="between"
        direction="row"
        color={rowColor}
      >
        <Text>{label}</Text>
        <Text>{value}</Text>
      </Box>
    );
  };

  const rows = [
    graphicMark.distinguishedName &&
      data && {
        label: resources.distinguishedName,
        value: `CN=${data.firstName} ${data.lastName} O=${data.organizationName}`,
      },
    graphicMark.date && {
      label: resources.date,
      value: moment(certificate.date).format(resources._dateTimeFormat),
    },
    graphicMark.email && {
      label: resources.email,
      value: certificate.email,
    },
    graphicMark.url && {
      label: "URL",
      value: certificate.url,
    },
  ].filter((row): row is { label: string; value: string } => row !== false);

  return (
    <Box
      borderRadius={6}
      padding={6}
      marginBottom={4}
      display="flex"
      direction="column"
      key={index}
      width={"100%"}
    >
      <Box height={"auto"} width={"100%"} direction="row" display="flex">
        <Box display="flex" height={"100%"} width={"100%"} paddingY={1} direction="row" gap={5}>
          <Box id="info" direction="column" display="flex" width={"100%"} gap={2}>
            {rows.map((row) => renderRow(row.label, row.value))}
          </Box>
          {graphicMark.logo && (
            <Box
              width={"250px"}
              height={"150px"}
              borderColor={colors.neutral600}
              id="PICKER"
              gap={2}
              position="relative"
            >
              {graphicMark?.image && (
                <Box zIndex={popupZIndex} position="absolute" margin={1} right>
                  <IconButton
                    accessibilityLabel={resources.remove}
                    size={"sm"}
                    color={colors.primary}
                    icon="x"
                    onClick={clearPicture}
                  />
                </Box>
              )}
              <ImagePicker onSelected={handlePicChange}>
                {graphicMark?.image ? (
                  <Image
                    naturalHeight={100}
                    naturalWidth={100}
                    src={graphicMark?.image}
                    alt={resources.clickUploadImage}
                  />
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Icon icon="cloud-upload" accessibilityLabel={resources.uploadFiles} />
                  </Box>
                )}
              </ImagePicker>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

type RenderWidgetProps = {
  certificateData: CertificateData;
  graphicMarkId: string;
  componentId: string;
  onElementRendered: () => void;
};

export const GraphicMarkRenderWidget = ({
  graphicMarkId,
  componentId,
  onElementRendered,
  certificateData,
}: RenderWidgetProps) => {
  const { getOrganizationGraphicMark } = useServices();
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  const { data, status } = useOrganizationQuery(`/graphicMark/${graphicMarkId}`, () => {
    return getOrganizationGraphicMark(graphicMarkId);
  });

  useEffect(() => {
    //UseEffect is the only reliable way to make sure the component is done rendering.
    //The prop method onElementRendered depends on the DOM being drawn already.
    setTimeout(() => {
      requestAnimationFrame(onElementRendered);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphicMarkId]);

  const renderRow = useCallback((value: string, _label: string, rowColor: ColorConfig) => {
    return (
      <Box
        padding={3}
        flex="grow"
        display="flex"
        alignItems="center"
        justifyContent="between"
        direction="row"
        color={rowColor}
      >
        <Text>{value}</Text>
      </Box>
    );
  }, []);

  if (status === "success") {
    return (
      <Box id={componentId} padding={2} width={"700px"}>
        <Box borderRadius={6} padding={6} marginTop={2} marginBottom={2}>
          <Box display="flex" direction="row">
            <Box flex="grow">
              {data.graphicMark.distinguishedName &&
                renderRow(certificateData.DN, resources.distinguishedName, colors.neutral250)}
              {data.graphicMark.date &&
                renderRow(
                  moment(Date.now()).format(resources._dateTimeFormat),
                  resources.date,
                  colors.white
                )}
              {data.graphicMark.email &&
                renderRow(
                  certificateData.mail || certificateData.contact,
                  resources.email,
                  colors.neutral250
                )}
              {data.graphicMark.url && renderRow(certificateData.location, "URL", colors.white)}
            </Box>

            {data.graphicMark.logo && data.graphicMark.image && (
              <Box width={"250px"} borderColor={colors.neutral600} padding={2}>
                <Image
                  naturalHeight={1}
                  naturalWidth={1}
                  src={data.graphicMark.image}
                  alt={resources.graphicMark}
                />
              </Box>
            )}
          </Box>
          {data.graphicMark.customMessage && (
            <Box>
              <Text align="center">{data.graphicMark.customMessage}</Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  } else {
    return <Spinner show={true} />;
  }
};
