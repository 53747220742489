import {
  Box,
  Button,
  Divider,
  Icon,
  Layer,
  Modal,
  modalZIndex,
  SearchField,
  Skeleton,
  Table,
  Text,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type {
  HttpGetOrganizationGraphicMarksRequest,
  ListableGraphicMark,
} from "@prodoctivity/types";
import { FunctionComponent, useMemo, useState } from "react";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";
import { useGraphicMarkList } from "./GraphicMarkBuilder/hooks";
import { noop } from "../../../utils";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { EditSvgIcon } from "../../../svg/EditSvgIcon";
const PAGINATION_OPTIONS: HttpGetOrganizationGraphicMarksRequest["queryParameters"]["rowsPerPage"][] =
  ["15", "30", "50"];

const GraphicMarkPage: FunctionComponent = () => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const organizationNavigate = useOrganizationNavigate();
  const [deleteInfo, setDeleteInfo] = useState<
    { GraphicMarkId: string; GraphicMarkName: string } | undefined
  >(undefined);

  const {
    mutateDeleteGraphicMark,
    setToastMessage,
    paginatedData,
    setFilter,
    isLoading,
    currentPage,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    refetch,
    toastMessage,
  } = useGraphicMarkList();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.graphicMarks,
        url: organizationLinkTemplates.graphicMarkList(),
      },
    ];
  }, [resources.graphicMarks, resources.home, resources.settings]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        {deleteInfo && (
          <Layer zIndex={modalZIndex}>
            <Modal
              accessibilityModalLabel={resources.deleteGraphicMark}
              heading={
                <Box height={50}>
                  <Box margin={4}>
                    <Text weight="bold" size="400" color={colors.secondary}>
                      {resources.deleteGraphicMark}
                    </Text>
                  </Box>
                  <Divider />
                </Box>
              }
              size="sm"
              onDismiss={() => setDeleteInfo(undefined)}
              footer={
                <Box display="flex">
                  <Box>
                    <Button
                      color={"transparent"}
                      onClick={() => setDeleteInfo(undefined)}
                      text={resources.cancel}
                    />
                  </Box>
                  <Box marginStart="auto">
                    <Button
                      color={"red"}
                      onClick={() => {
                        mutateDeleteGraphicMark(deleteInfo.GraphicMarkId);
                        setDeleteInfo(undefined);
                      }}
                      text={resources.yesDelete}
                    />
                  </Box>
                </Box>
              }
            >
              <Box margin={4} display="flex" justifyContent="center">
                <Text>
                  {resources.youAreAboutToDeleteThisGraphicMark.replace(
                    "{graphicMarkName}",
                    deleteInfo.GraphicMarkName
                  )}
                </Text>
              </Box>
              <Divider />
            </Modal>
          </Layer>
        )}
        <SearchComponentWrapper
          componentHeaderText={resources.graphicMarks}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="mg-list-search"
          searchFieldLabel={resources.search}
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          buttonAction={() => organizationNavigate(organizationLinkTemplates.newGraphicMark())}
          setFilter={noop}
          omitSearch={true}
        />

        <Box margin={5} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="mg_list_pagination_top"
            rowsLabel={resources.graphicMarks + `:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.graphicMarkList.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            totalRowCount={totalRowCount}
            extraComponent={
              <SearchField
                marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                marginEnd={2}
                accessibilityLabel={resources.search}
                id="graphic_marks_filter"
                onChange={({ value }) => {
                  setFilter(value);
                }}
              />
            }
          />
          <Divider direction="horizontal" />
        </Box>
        {toastMessage && (
          <NotificationMessage
            type={toastMessage.type}
            delay={5000}
            message={toastMessage.message}
            position="bottom-left"
            handleDismiss={() => setToastMessage(undefined)}
          />
        )}
        <Box
          marginStart={6}
          marginEnd={6}
          padding={5}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Box>
            <Table accessibilityLabel={resources.graphicMarks}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Box paddingX={4}>
                      <Text size="200" weight="bold">
                        {resources.name.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {"Custom Message".toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.actions.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.default.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isLoading ? (
                  <Table.Row>
                    <Table.Cell>
                      <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                        <Skeleton width={"100%"} height={"100%"} />
                        <Box width={1} borderStyle={"sm"} borderRadius={4} />
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton width={"100%"} height={"100%"} />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton width={"100%"} height={"100%"} />
                    </Table.Cell>

                    <Table.Cell>
                      <Box display="flex" justifyContent="center">
                        <Skeleton width={"100%"} height={"100%"} />
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                ) : paginatedData ? (
                  paginatedData.graphicMarkList.map((graphicMark: ListableGraphicMark) => {
                    return (
                      <Table.Row key={graphicMark.id}>
                        <Table.Cell>
                          <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                            <Box>
                              <Text weight="bold">{`${graphicMark.name}`}</Text>
                            </Box>
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box>
                            <Text>{`${graphicMark.customMessage}`}</Text>
                          </Box>
                        </Table.Cell>

                        <Table.Cell>
                          <Box display="flex" gap={3} alignItems="center">
                            <Tooltip text={resources.edit}>
                              <EditSvgIcon
                                color={colors.primary}
                                onClick={() =>
                                  organizationNavigate(
                                    organizationLinkTemplates.editGraphicMark(graphicMark.id)
                                  )
                                }
                              />
                            </Tooltip>
                            <Tooltip text={resources.remove}>
                              <Icon
                                color={colors.primary}
                                icon="trash"
                                accessibilityLabel="Remove"
                                onClick={() =>
                                  setDeleteInfo({
                                    GraphicMarkId: graphicMark.id,
                                    GraphicMarkName: graphicMark.name,
                                  })
                                }
                              />
                            </Tooltip>
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box display="flex" gap={3} alignItems="center">
                            {graphicMark.isDefault ? (
                              <Icon accessibilityLabel={resources.default} icon="check"></Icon>
                            ) : null}
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral200}
                      >
                        <Text size="300">{resources.noResultsFound}</Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Box>
        </Box>
        <Box margin={5} display="flex" direction="row">
          <Box display="flex" flex="grow" />
          <Box display="flex" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="Marca_Grafica_list_pagination_bottom"
              rowsLabel={resources.graphicMarks}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.graphicMarkList.length : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={PAGINATION_OPTIONS}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default GraphicMarkPage;
