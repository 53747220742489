import { Box, Mask, Text, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";

export const StatusTableBadge: FunctionComponent<{ status: string }> = ({ status }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  switch (status) {
    case "in-progress":
      return (
        <Mask rounding={3} width="fit-content">
          <Box color={colors.neutral200} paddingX={4} paddingY={2}>
            <Text weight="bold" color={colors.other200}>
              {resources.inProgress}
            </Text>
          </Box>
        </Mask>
      );
    case "completed":
      return (
        <Mask rounding={3} width="fit-content">
          <Box color={colors.neutral200} paddingX={4} paddingY={2}>
            <Text weight="bold" color={colors.other}>
              {resources.completed}
            </Text>
          </Box>
        </Mask>
      );
    case "error":
      return (
        <Mask rounding={3} width="fit-content">
          <Box color={colors.neutral200} paddingX={4} paddingY={2}>
            <Text weight="bold" color={colors.other100}>
              {resources.error}
            </Text>
          </Box>
        </Mask>
      );
    case "pending":
      return (
        <Mask rounding={3} width="fit-content">
          <Box color={colors.neutral200} paddingX={4} paddingY={2}>
            <Text weight="bold" color={colors.neutral800}>
              {resources.pending}
            </Text>
          </Box>
        </Mask>
      );
    default:
      return <Text>${status}</Text>;
  }
};
