import {
  SelectList as GestaltSelectList,
  SelectListProps as GestaltSelectListProps,
} from "gestalt";

export type SelectListProps = Omit<GestaltSelectListProps, "children"> & {
  options: Array<{ label: string; value: string; disabled?: boolean }>;
};
export const SelectList = ({ options, ...props }: SelectListProps) => {
  return (
    <GestaltSelectList {...props} placeholder={props.placeholder ?? " "}>
      {options.map((option) => {
        return (
          <GestaltSelectList.Option
            key={`${option.label}-${option.value}`}
            label={option.label}
            value={option.value}
            disabled={option.disabled !== undefined ? option.disabled : undefined}
          />
        );
      })}
    </GestaltSelectList>
  );
};
