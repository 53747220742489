import { Box, Spinner, Text } from "@prodoctivity/design-system";
import { FC } from "react";

export const LoadingChart: FC<{ message: string }> = ({ message }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={"400px"}>
      <Box alignItems="center">
        <Spinner show={true} />
        <Text size="400">{message} </Text>
      </Box>
    </Box>
  );
};
