import { useCallback, useEffect, useState } from "react";

import { Box } from "@prodoctivity/design-system";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { OrganizationNavigate } from "../../components/OrganizationNavigate";
import { useServices } from "../../hooks/useServices";

function LoginTokenPage() {
  const { token } = useParams();
  const { user, renewToken } = useServices();
  const [isMutated, setIsMutated] = useState(false);

  const doRenewToken = useCallback(async () => {
    if (token) {
      const response = await renewToken(token);
      return response;
    }
  }, [token, renewToken]);

  const mutation = useMutation(doRenewToken, {
    onError: () => {
      setIsMutated(false);
    },
    onSuccess: (data) => {
      if (data?.token) {
        setIsMutated(true);
      }
    },
  });

  useEffect(() => {
    mutation.mutate();
  }, [mutation]);

  if (isMutated) {
    if (!user?.token) {
      return <OrganizationNavigate to={`/login`} />;
    } else {
      return <OrganizationNavigate to={"/"} />;
    }
  }

  return <Box></Box>;
}

export default LoginTokenPage;
