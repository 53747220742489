import { FunctionComponent, MouseEventHandler, Ref, forwardRef, useMemo, useState } from "react";
import { ColorConfig, calculateColor } from "../utils";

import { Box } from "./Box";
import { useColors } from "./ColorSchemeProvider";
import { TapArea } from "./TapArea";

const iconSourceMap = {
  number: "prodoctivity-icon prodoc-",
  calendar: "prodoctivity-icon prodoc-",
  decimal: "prodoctivity-icon prodoc-",
  list: "prodoctivity-icon prodoc-",
  text: "prodoctivity-icon prodoc-",
  "yes-no": "prodoctivity-icon prodoc-",
  image: "prodoctivity-icon prodoc-",
  signature: "prodoctivity-icon prodoc-",
  "toggle-panel": "prodoctivity-icon prodoc-",
  "lock-download": "prodoctivity-icon prodoc-",
  "new-version": "prodoctivity-icon prodoc-",
  "expand-vertical": "prodoctivity-icon prodoc-",
  expand: "prodoctivity-icon prodoc-",
  "expand-wide": "prodoctivity-icon prodoc-",
  "doc-filter": "prodoctivity-icon prodoc-",
  "deleted-doc": "prodoctivity-icon prodoc-",
  "doc-view": "prodoctivity-icon prodoc-",
  "generated-doc": "prodoctivity-icon prodoc-",
  "imported-doc": "prodoctivity-icon prodoc-",
  "doc-scan": "prodoctivity-icon prodoc-",
  "save-publish": "prodoctivity-icon prodoc-",
  "save-test": "prodoctivity-icon prodoc-",
  "rotate-left": "prodoctivity-icon prodoc-",
  "rotate-right": "prodoctivity-icon prodoc-",
  scan: "prodoctivity-icon prodoc-",
  "add-page": "prodoctivity-icon prodoc-",
  "page-up": "prodoctivity-icon prodoc-",
  "page-down": "prodoctivity-icon prodoc-",
  "delete-page": "prodoctivity-icon prodoc-",
  fax: "prodoctivity-icon prodoc-",
  expansion: "prodoctivity-icon prodoc-",
  "zoom-in": "prodoctivity-icon prodoc-",
  "zoom-out": "prodoctivity-icon prodoc-",
  shrink: "prodoctivity-icon prodoc-",
  "view-keys": "prodoctivity-icon prodoc-",
  "edit-keys": "prodoctivity-icon prodoc-",
  key: "prodoctivity-icon prodoc-",
  drawer: "prodoctivity-icon prodoc-",
  "add-alarm": "prodoctivity-icon prodoc-",
  alarm: "prodoctivity-icon prodoc-",
  "add-note": "prodoctivity-icon prodoc-",
  "edit-note": "prodoctivity-icon prodoc-",
  print: "prodoctivity-icon prodoc-",
  "continue-task": "prodoctivity-icon prodoc-",
  folder: "prodoctivity-icon prodoc-",
  document: "prodoctivity-icon prodoc-",
  "arrow-minimize": "prodoctivity-icon prodoc-",
  "arrow-maximize": "prodoctivity-icon prodoc-",
  "add-cloud": "prodoctivity-icon prodoc-",
  logo: "prodoctivity-icon prodoc-",
  "download-cloud": "prodoctivity-icon prodoc-",
  integrations: "prodoctivity-icon prodoc-",
  dashboard: "prodoctivity-icon prodoc-",
  entities: "prodoctivity-icon prodoc-",
  configure: "prodoctivity-icon prodoc-",
  lock: "prodoctivity-icon prodoc-",
  "cloud-upload": "prodoctivity-icon prodoc-",
  record: "prodoctivity-icon prodoc-",
  "spinner-in": "prodoctivity-icon prodoc-",
  spinner: "prodoctivity-icon prodoc-",
  spinner2: "prodoctivity-icon prodoc-",
  plus: "fa fa-regular fa-",
  "eye-slash": "fa fa-regular fa-",
  filter: "fa fa-regular fa-",
  table: "fa fa-regular fa-",
  sliders: "fa fa-regular fa-",
  timeline: "fa fa-regular fa-",
  "user-large": "fa fa-regular fa-",
  user: "fa-solid fa-",
  "screwdriver-wrench": "fa fa-regular fa-",
  "power-off": "fa fa-regular fa-",
  bell: "fa fa-solid fa-",
  "circle-question": "fa fa-regular fa-",
  minus: "fa fa-regular fa-",
  "arrow-up": "fa fa-regular fa-",
  "arrow-down": "fa fa-regular fa-",
  "arrow-left": "fa fa-regular fa-",
  "arrow-right": "fa fa-regular fa-",
  "angle-down": "fa fa-regular fa-",
  "chevron-up": "fa fa-regular fa-",
  "chevron-right": "fa fa-regular fa-",
  "chevron-left": "fa fa-regular fa-",
  "chevron-down": "fa fa-regular fa-",
  "chevron-double-right": "fa fa-solid fa-",
  "chevron-double-left": "fa fa-solid fa-",
  close: "fa fa-regular fa-",
  search: "fa fa-regular fa-",
  "location-arrow": "fa fa-solid fa-",
  pencil: "fa fa-solid fa-",
  trash: "fa fa-solid fa-",
  "people-group": "fa-solid fa-",
  wallet: "fa-solid fa-",
  "lock-keyhole": "fa-solid fa-",
  "circle-info": "fa fa-solid fa-",
  "filter-list": "fa fa-solid fa-",
  crown: "fa fa-solid fa-",
  unlock: "fa fa-solid fa-",
  "ballot-check": "fa fa-solid fa-",
  "file-plus": "fa fa-solid fa-",
  "clock-rotate-left": "fa fa-regular fa-",
  gear: "fa fa-solid fa-",
  "file-circle-check": "fa fa-regular fa-",
  "triangle-exclamation": "fa fa-regular fa-",
  loader: "fa fa-regular fa-",
  "arrows-rotate": "fa fa-regular fa-",
  check: "fa fa-regular fa-",
  clone: "fa fa-regular fa-",
  copy: "fa fa-regular fa-",
  "down-to-bracket": "fa fa-regular fa-",
  "up-from-bracket": "fa fa-regular fa-",
  x: "fa fa-regular fa-",
  "circle-check": "fa fa-solid fa-",
  hamburgerMenu: "fa-solid fa fa-bars fa-",
  bookmark: "fa-solid fa fa-",
};

export type IconType = keyof typeof iconSourceMap;
export const iconList: IconType[] = Object.keys(iconSourceMap).sort() as IconType[];

type Props = {
  ref?: Ref<HTMLButtonElement | null>;
  icon: IconType;
  accessibilityLabel: string;
  padding?: number;

  color?: ColorConfig;
  hoverColor?: ColorConfig;
  textShadowOnHover?: boolean;
  disabled?: boolean;
  inline?: boolean | undefined;
  size?: "xs" | "sm" | "md" | 1 | 2 | 3 | 4 | 5 | 6;
  onClick?(): void;
};

export function Icon(props: Props) {
  const { colors } = useColors();
  const { icon, size, hoverColor, accessibilityLabel, color, padding, disabled, onClick } = props;

  const [hColor, setHColor] = useState<ColorConfig | undefined>();

  const onMouseEnter: MouseEventHandler<HTMLDivElement> | undefined = useMemo(() => {
    if (hoverColor) {
      return () => {
        setHColor(hoverColor);
      };
    }
    return undefined;
  }, [hoverColor]);

  const onMouseLeave: MouseEventHandler<HTMLDivElement> | undefined = useMemo(() => {
    if (hoverColor) {
      return () => {
        setHColor(undefined);
      };
    }
    return undefined;
  }, [hoverColor]);

  const computedColor = useMemo(() => {
    if (disabled) {
      return calculateColor(colors.neutral500);
    } else {
      return calculateColor(hColor || color || colors.primary);
    }
  }, [color, colors.neutral500, colors.primary, disabled, hColor]);

  const containerStyle = useMemo(() => {
    return {
      color: computedColor,
      fontSize: typeof size === "number" ? size * 4 : undefined,
      cursor: onClick ? "pointer" : undefined,
      textShadow:
        hColor && color
          ? `${calculateColor(color)} 0px 0px 5px, ${calculateColor(color)} 1px 1px 2px`
          : undefined,
      padding: padding !== undefined ? padding * 4 : undefined,
    };
  }, [computedColor, hColor, onClick, padding, size, color]);

  return (
    <div
      aria-label={accessibilityLabel}
      className={`${iconSourceMap[icon]}${icon} icon-size-${size || "sm"}`}
      style={containerStyle}
      onClick={onClick}
      onMouseOver={hoverColor ? onMouseEnter : undefined}
      onMouseLeave={hoverColor ? onMouseLeave : undefined}
    />
  );
}

export function IconButton(props: Props) {
  return (
    <Box dangerouslySetInlineStyle={{ __style: { cursor: "pointer" } }}>
      <Icon {...props} />
    </Box>
  );
}

export const IconButtonWithBox: FunctionComponent<Props & { disabled: boolean }> = (props) => {
  const { colors } = useColors();
  return props.disabled ? (
    <Box
      color={colors.neutral600}
      paddingX={2}
      paddingY={1}
      borderRadius={6}
      justifyContent="center"
    >
      <IconButton {...props} />
    </Box>
  ) : (
    <TapArea onTap={props.onClick}>
      <Box
        color={colors.neutral900}
        hoverColor={colors.primary}
        paddingX={2}
        paddingY={1}
        borderRadius={6}
        justifyContent="center"
      >
        <IconButton {...props} onClick={undefined} />
      </Box>
    </TapArea>
  );
};

export const IconWithRef = forwardRef<HTMLButtonElement | null, Props>((props, ref) => {
  return <Icon {...props} ref={ref} />;
});
