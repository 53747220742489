import { Box, Text, useColors } from "@prodoctivity/design-system";

import { FC } from "react";

type Props = {
  label: string;
  step: number;
  active: boolean;
  isComplete: boolean;
  stepChange?: () => void;
};

const SignUpWizardStep: FC<Props> = ({ label, step, active, isComplete, stepChange }) => {
  const { colors } = useColors();
  return (
    <Box width={70} paddingX={1} display="flex" direction="column" alignItems="center">
      <Box
        rounding="circle"
        height={50}
        width={50}
        paddingY={3}
        color={isComplete ? colors.primary300 : active ? colors.primary : colors.neutral700}
        marginBottom={2}
        borderStyle="sm"
        onClickCapture={stepChange}
        display="flex"
        justifyContent="center"
        alignItems="center"
        dangerouslySetInlineStyle={
          stepChange
            ? {
                __style: {
                  cursor: "pointer",
                },
              }
            : undefined
        }
      >
        <Text size="300" color={colors.white}>
          {step}
        </Text>
      </Box>
      <Text align="justify" color={active ? colors.primary : colors.subtle} size="200">
        {label}
      </Text>
    </Box>
  );
};

export { SignUpWizardStep };
