import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const StarSVG: FunctionComponent<Props> = ({
  customClassName,
  width = 18,
  height = 18,
  onClick,
  color: rawColor,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 18 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M9.58336 0.37899L11.8611 5.21277C11.9559 5.41343 12.138 5.5525 12.3504 5.58628L17.4426 6.36111C17.9755 6.44257 18.1898 7.128 17.8029 7.52336L14.118 11.2863C13.9643 11.4432 13.8942 11.6677 13.9302 11.8883L14.8007 17.2008C14.8918 17.7571 14.3342 18.1823 13.8581 17.9181L9.30267 15.4088C9.11302 15.3035 8.88733 15.3035 8.69767 15.4088L4.1422 17.9181C3.66428 18.1803 3.10669 17.7571 3.19962 17.2008L4.07013 11.8883C4.10617 11.6677 4.036 11.4412 3.88238 11.2863L0.197412 7.52336C-0.18948 7.12998 0.024828 6.44257 0.557754 6.36111L5.64995 5.58628C5.86236 5.55449 6.04442 5.41541 6.13925 5.21277L8.41699 0.37899C8.65595 -0.127632 9.34439 -0.127632 9.58336 0.37899Z"
        fill={calculateColor(color)}
      />
    </svg>
  );
};
