import {
  Box,
  Divider,
  Icon,
  SearchField,
  Skeleton,
  Table,
  TapArea,
  Text,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type {
  HttpGetPaginatedOrganizationDictionaryListsRequest,
  HttpGetPaginatedOrganizationDictionaryListsResponse,
} from "@prodoctivity/types";

import { range } from "@prodoctivity/shared";
import { FunctionComponent, useMemo } from "react";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";

const PAGINATION_OPTIONS: HttpGetPaginatedOrganizationDictionaryListsRequest["queryParameters"]["rowsPerPage"][] =
  ["15", "30", "100"];

const ManageDictionaryListsPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.dataDictionary.dictionary,
        url: organizationLinkTemplates.manageDictionaryLists(),
      },
      {
        type: "text",
        name: resources.dataDictionary.dataLists,
      },
    ];
  }, [resources]);

  const {
    currentPage,
    paginatedData,
    isLoading,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    refetch,
    nextPage,
    setFilter,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    HttpGetPaginatedOrganizationDictionaryListsResponse["payload"],
    HttpGetPaginatedOrganizationDictionaryListsRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.getPaginatedOrganizationDictionaryLists(currentPage, rowsPerPage, filter);
    },
    "settings_page_dictionary_lists"
  );

  const { breakpoint } = useDesignBreakpoint();

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <SearchComponentWrapper
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          buttonAction={() => organizationNavigate("/settings/data-dictionary/data-lists/new")}
          componentHeaderText={resources.dataDictionary.dataLists}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="dictionary_lists_filter"
          searchFieldLabel={resources.dataDictionary.searchListPlaceholder}
          setFilter={setFilter}
          omitSearch={true}
        />

        <Box margin={5} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="dictionary_list_pagination_top"
            rowsLabel={`${resources.dataDictionary.dictionaryList}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.dictionaryLists.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            totalRowCount={totalRowCount}
            extraComponent={
              <SearchField
                marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                marginEnd={2}
                accessibilityLabel={resources.dataDictionary.searchListPlaceholder}
                id="data_elements_filter"
                onChange={({ value }) => {
                  setFilter(value);
                }}
              />
            }
          />
          <Divider direction="horizontal" />
        </Box>
        <Box
          marginTop={8}
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Box>
            <Table accessibilityLabel="data list">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Box paddingX={4}>
                      <Text size="200" weight="bold">
                        {resources.name.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.dataDictionary.listType.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.description.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.actions.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isLoading ? (
                  range(7).map((_, idx) => <RowSkeleton key={idx} />)
                ) : paginatedData && paginatedData.dictionaryLists.length > 0 ? (
                  paginatedData.dictionaryLists.map((dataList, index) => {
                    return (
                      <Table.Row hoverStyle="gray" key={`${dataList.listSchema.name}_${index}`}>
                        <Table.Cell>
                          <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                            <Box>
                              <Text
                                weight="bold"
                                title={dataList.listSchema.name}
                                ellipsisLength={65}
                              >
                                {dataList.listSchema.name}
                              </Text>
                            </Box>
                            <Box width={1} borderStyle={"sm"} borderRadius={4} />
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box>
                            <Text>
                              {
                                resources.dataDictionary.listTypeLabels[
                                  dataList.listSchema.listType
                                ]
                              }
                            </Text>
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box>
                            <Text>{`${dataList.listSchema.description}`}</Text>
                          </Box>
                        </Table.Cell>

                        <Table.Cell>
                          <Box display="flex">
                            <Box display="flex">
                              <TapArea
                                onTap={() =>
                                  organizationNavigate(
                                    `/settings/data-dictionary/data-lists/${dataList.listSchema.name}`
                                  )
                                }
                              >
                                <Tooltip text={resources.edit}>
                                  <Icon
                                    accessibilityLabel="Edit"
                                    icon="pencil"
                                    size={"sm"}
                                    color={colors.primary}
                                  />
                                </Tooltip>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral200}
                      >
                        <Text size="300">{resources.dataDictionary.noLists}</Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Box>
        </Box>
        <Box marginTop={5} padding={2} display="flex" flex="grow" direction="row">
          <Box display="flex" flex="grow" />
          <Box display="flex" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="dictionary_list_pagination_bottom"
              rowsLabel={`${resources.dataDictionary.dataLists}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.pageLength : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={PAGINATION_OPTIONS}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export const RowSkeleton: FunctionComponent = () => {
  return (
    <Table.Row>
      <Table.Cell>
        <Box marginStart={4} display={"flex"} justifyContent={"between"}>
          <Skeleton height={50} width={"100%"} />
          <Box width={1} borderStyle={"sm"} borderRadius={4} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box>
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>

      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Box display="flex" gap={6}>
            <Skeleton height={50} width={"100%"} />
          </Box>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};

export default ManageDictionaryListsPage;
