import "highlight.js/styles/default.css";

import { FunctionComponent, useCallback, useMemo } from "react";

import hljs from "highlight.js";
import markdownit from "markdown-it";

const md = markdownit("default", {
  breaks: true,
  typographer: true,
  xhtmlOut: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (__) {}
    }

    return ""; // use external default escaping
  },
});

function getMarkdownValue(val: string) {
  return md.render(val);
}

export type Props = {
  markdownText: string;
  size?: "sm" | "md" | "xs";
};

export const MarkdownViewer: FunctionComponent<Props> = ({ markdownText, size = "md" }) => {
  const divRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      Array.from(node.querySelectorAll("a"))
        .filter((link) => link.hostname !== window.location.hostname)
        .forEach((link) => {
          if (!link.target) {
            link.target = "_blank";
          }
        });
    }
  }, []);

  const rawMarkup = useMemo(() => {
    const view = getMarkdownValue(markdownText);
    return view;
  }, [markdownText]);

  return (
    <div
      className={`prodoctivity-markdown markdown-size-${size}`}
      ref={divRef}
      dangerouslySetInnerHTML={{ __html: rawMarkup }}
    />
  );
};
