import {
  AccordionExpandable,
  Box,
  BroomSvgIcon,
  Button,
  Divider,
  Icon,
  Layer,
  Modal,
  NumberField,
  RadioGroup,
  SelectList,
  Skeleton,
  TapArea,
  Text,
  TextField,
  Tooltip,
  modalZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FilesSequence, SequenceBody, SequenceType } from "@prodoctivity/shared";
import { ChangeEvent, FunctionComponent, MutableRefObject, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../../components/Layout/Page";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { HardDeleteDocumentSvgIcon } from "../../../../svg/HardDeleteDocumentSvgIcon";
import { FilesValidationMessages, SequenceMsg, useSequencesConfiguration } from "./hooks";

type Props = {
  isUpdating: boolean;
  onCancel: () => void;
};

export const SequencesConfiguration: FunctionComponent<Props> = ({ isUpdating, onCancel }) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const { sequenceName } = useParams();

  const {
    sequenceState,
    alterSequenceValue,
    changeSequenceType,
    stepTypeOptions,
    selectFromTable,
    saveSequenceMutation,
    updateSequenceMutation,
    deleteFile,
    rollBackSequenceMutation,
    isLoadingSequence,
    breadCrumbEntries,
    fileInputRef,
    acceptedFormatsString,
    isSaveButtonDisable,
    validationMessages,
    isFileUpdatable,
    filesErrorMessages,
  } = useSequencesConfiguration(sequenceName, onCancel);

  return isLoadingSequence ? (
    <Box display="flex" padding={4}>
      <Box>
        <Skeleton show={true} />
      </Box>
    </Box>
  ) : (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box padding={6} display="flex" flex="grow" direction="column">
        <Box display="flex" direction="row" flex="shrink" paddingY={4}>
          {isUpdating ? (
            <Text size="400" weight="bold">{` ${resources.sequence} - ${sequenceName}`}</Text>
          ) : (
            <Text size="400" weight="bold">{` ${resources.sequence} - (${resources.new})`}</Text>
          )}
        </Box>

        <Box
          borderRadius={4}
          borderColor={colors.neutral400}
          display="flex"
          direction="column"
          flex="grow"
        >
          <TopicHeader title={resources.properties} />
          <Box padding={6} display="flex" direction="column" flex="grow" gap={4}>
            <Box paddingY={2}>
              <DesignatorProperties
                sequenceState={sequenceState}
                alterSequenceValue={alterSequenceValue}
                validationMessages={validationMessages}
              />
            </Box>
            <Box borderStyle="sm" borderRadius={4}>
              <SourceType
                sequenceState={sequenceState}
                changeSequenceType={changeSequenceType}
                alterSequenceValue={alterSequenceValue}
                stepTypeOptions={stepTypeOptions}
                selectFromTable={selectFromTable}
                fileInputRef={fileInputRef}
                acceptedFormatsString={acceptedFormatsString}
                validationMessages={validationMessages}
                isUpdating={isUpdating}
              />
            </Box>

            {sequenceState.type === "value-list" &&
              sequenceState.files &&
              sequenceState.files.length > 0 && (
                <Box borderStyle="sm" borderRadius={4}>
                  <SequenceOverview
                    sequenceState={sequenceState}
                    isUpdating={isUpdating}
                    deleteFile={deleteFile}
                    rollBackSequenceMutation={rollBackSequenceMutation}
                    alterSequenceValue={alterSequenceValue}
                    isFileUpdatable={isFileUpdatable}
                    validationMessages={validationMessages}
                    filesErrorMessages={filesErrorMessages}
                  />
                </Box>
              )}
          </Box>
        </Box>
        <Box display="flex" direction="row" flex="shrink" gap={2} justifyContent="end" paddingY={4}>
          <Button color={"transparent"} onClick={() => onCancel()} text={resources.cancel} />
          <Button
            disabled={isSaveButtonDisable}
            color={"blue"}
            onClick={() => {
              if (!isUpdating) {
                saveSequenceMutation();
              } else {
                updateSequenceMutation();
              }
            }}
            text={!isUpdating ? resources.saveChanges : resources.update}
          />
        </Box>
      </Box>
    </Page>
  );
};

type DesignatorPropertiesProps = {
  sequenceState: SequenceBody;
  alterSequenceValue: (action: SequenceMsg) => void;
  validationMessages: FilesValidationMessages;
};

const DesignatorProperties: FunctionComponent<DesignatorPropertiesProps> = ({
  sequenceState,
  alterSequenceValue,
  validationMessages,
}) => {
  const { resources } = useAppTranslation();
  return (
    <Box>
      <Box display="flex" direction="row" flex="grow" gap={4}>
        <Box display="flex" flex="grow" direction="column" gap={4}>
          <Box>
            <Text wrap>{`${resources.giveValueDesignatorDescriptiveName}:`}</Text>
            <Box width={"100%"}>
              <TextField
                maxLength={{
                  characterCount: 70,
                  errorAccessibilityLabel: resources.sequenceNameLimit,
                }}
                id={"designator-name-text-field"}
                onChange={(event) => alterSequenceValue({ type: "alterName", value: event.value })}
                placeholder={resources.name}
                value={sequenceState.sequenceName}
                errorMessage={validationMessages.sequenceName}
              />
            </Box>
          </Box>
          <Box>
            <Text>{`${resources.giveValueDescription}:`}</Text>
            <Box width={"100%"}>
              <TextField
                id={"designator-description-text-field"}
                onChange={(event) =>
                  alterSequenceValue({ type: "alterDescription", value: event.value })
                }
                placeholder={resources.description}
                value={sequenceState.sequenceDescription}
                errorMessage={validationMessages.sequenceDescription}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type SourceTypeProps = {
  sequenceState: SequenceBody;
  changeSequenceType: (sequenceType: SequenceType) => void;
  alterSequenceValue: (action: SequenceMsg) => void;
  stepTypeOptions: {
    value: string;
    label: string;
  }[];
  selectFromTable: (event: ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
  acceptedFormatsString: string;
  validationMessages: FilesValidationMessages;
  isUpdating: boolean;
};

const SourceType: FunctionComponent<SourceTypeProps> = ({
  sequenceState,
  changeSequenceType,
  stepTypeOptions,
  alterSequenceValue,
  selectFromTable,
  fileInputRef,
  acceptedFormatsString,
  validationMessages,
  isUpdating,
}) => {
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const isSmall = breakpoint === "small";
  const isMedium = breakpoint === "medium";
  const isSmallOrMedium = isSmall || isMedium;

  return (
    <Box>
      <TopicHeader title={resources.sourceType} />
      <Box padding={6}>
        <Box gap={2}>
          <Box>
            <RadioGroup id={"source-type-radio-group"} legend="">
              <Box
                display="flex"
                direction={isSmallOrMedium ? "column" : "row"}
                justifyContent="center"
              >
                <RadioGroup.RadioButton
                  checked={sequenceState.type === "value-list"}
                  id="value-list-radio"
                  label={resources.documentUniqueValueSelection}
                  onChange={() => changeSequenceType("value-list")}
                  value="value-list"
                  disabled={sequenceState.isUsed || isUpdating}
                />
                {sequenceState.type === "value-list" && (
                  <Box
                    flex="grow"
                    display="flex"
                    justifyContent="center"
                    minWidth={150}
                    paddingX={2}
                  >
                    <input
                      style={{ display: "none" }}
                      type="file"
                      ref={fileInputRef}
                      id="file-input"
                      accept={acceptedFormatsString}
                      onChange={selectFromTable}
                    />
                    <Box width={"100%"} padding={2}>
                      <Button
                        fullWidth
                        size="lg"
                        color={"transparent"}
                        text={resources.uploadFile}
                        onClick={() => fileInputRef.current?.click()}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <RadioGroup.RadioButton
                checked={sequenceState.type === "autogenerated"}
                id="autogenerated-radio"
                label={resources.generateUniqueDocumentNumber}
                onChange={() => changeSequenceType("autogenerated")}
                value="autogenerated"
                disabled={sequenceState.isUsed || isUpdating}
              />
            </RadioGroup>
          </Box>
        </Box>
        {sequenceState.type === "autogenerated" && (
          <Box paddingY={4}>
            <GeneratedNumber
              sequenceState={sequenceState}
              alterSequenceValue={alterSequenceValue}
              stepTypeOptions={stepTypeOptions}
              validationMessages={validationMessages}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

type GeneratedNumberProps = {
  alterSequenceValue: (action: SequenceMsg) => void;
  stepTypeOptions: {
    value: string;
    label: string;
  }[];
  sequenceState: SequenceBody;
  validationMessages: FilesValidationMessages;
};

const GeneratedNumber: FunctionComponent<GeneratedNumberProps> = ({
  alterSequenceValue,
  sequenceState,
  stepTypeOptions,
  validationMessages,
}) => {
  const { resources } = useAppTranslation();
  const isAutoGenerated = sequenceState.type === "autogenerated";
  return (
    <Box display="flex" direction="row" flex="grow" gap={2}>
      <Box display="flex" flex="grow" direction="column" gap={2}>
        <Box>
          <Text>{`${resources.initialValue}`}</Text>
          <NumberField
            id={"initial-value-number-field"}
            min={0}
            onChange={(event) =>
              alterSequenceValue({ type: "changeInitialValue", value: event.value })
            }
            disabled={sequenceState.isUsed}
            value={isAutoGenerated ? sequenceState.initialValue : undefined}
            errorMessage={validationMessages.initialValue}
          />
        </Box>
        <Text>{`${resources.stepValue}`}</Text>
        <Box display="flex" direction="row" flex="grow" gap={2}>
          <Box width={"25%"} minWidth={120}>
            <SelectList
              id={"step-type-select-list"}
              disabled={sequenceState.isUsed}
              value={isAutoGenerated ? sequenceState.stepType : undefined}
              label={""}
              options={stepTypeOptions}
              onChange={(event) => {
                if (event.value === "increment" || event.value === "decrement") {
                  alterSequenceValue({ type: "changeStepType", value: event.value });
                }
              }}
              errorMessage={validationMessages.stepType}
            />
          </Box>

          <Box width={"100%"}>
            <NumberField
              id={"step-value-text-field"}
              min={1}
              disabled={sequenceState.isUsed}
              onChange={(event) =>
                alterSequenceValue({ type: "changeStepValue", value: event.value })
              }
              value={isAutoGenerated ? sequenceState.stepValue : undefined}
              errorMessage={validationMessages.stepValue}
            />
          </Box>
        </Box>
        <Box display="flex" direction="row" gap={4} flex="grow">
          <Box width={"100%"}>
            <Text>{`${resources.assignPrefix}`}</Text>
            <TextField
              id={"prefix-value-text-field"}
              disabled={sequenceState.isUsed}
              onChange={(event) => alterSequenceValue({ type: "changePrefix", value: event.value })}
              value={isAutoGenerated ? sequenceState.prefix : undefined}
              placeholder={`(${resources.optional})`}
            />
          </Box>

          <Box width={"100%"}>
            <Text>{`${resources.assignSuffix}`}</Text>
            <TextField
              id={"suffix-text-field"}
              disabled={sequenceState.isUsed}
              onChange={(event) => alterSequenceValue({ type: "changeSuffix", value: event.value })}
              value={isAutoGenerated ? sequenceState.suffix : undefined}
              placeholder={`(${resources.optional})`}
            />
          </Box>
        </Box>
        <Box display="flex" direction="row" flex="grow" gap={3}>
          <Box width={"100%"}>
            <Text>{`${resources.paddingValue}`}</Text>
            <TextField
              id={"padding-value-text-field"}
              disabled={sequenceState.isUsed}
              onChange={(event) =>
                alterSequenceValue({ type: "changePaddingValue", value: event.value })
              }
              value={isAutoGenerated ? sequenceState.paddingValue : undefined}
              errorMessage={validationMessages.paddingValue}
            />
          </Box>

          <Box width={"100%"}>
            <Text>{`${resources.paddingLength}`}</Text>
            <NumberField
              min={0}
              id={"padding-length-number-field"}
              disabled={sequenceState.isUsed}
              onChange={(event) =>
                alterSequenceValue({ type: "changePaddingLength", value: event.value })
              }
              value={isAutoGenerated ? sequenceState.paddingLength : undefined}
              errorMessage={validationMessages.paddingLength}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type SequenceOverviewProps = {
  sequenceState: SequenceBody;
  isUpdating: boolean;
  deleteFile: (fileName: string) => void;
  rollBackSequenceMutation: () => void;
  alterSequenceValue: (action: SequenceMsg) => void;
  isFileUpdatable: (file: FilesSequence) => boolean;
  validationMessages: FilesValidationMessages;
  filesErrorMessages: boolean;
};

const SequenceOverview: FunctionComponent<SequenceOverviewProps> = ({
  sequenceState,
  isUpdating,
  deleteFile,
  rollBackSequenceMutation,
  alterSequenceValue,
  isFileUpdatable,
  validationMessages,
  filesErrorMessages,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const isValueList = sequenceState.type === "value-list";
  const showModal = isValueList && sequenceState.behaviorModal;

  return (
    isValueList && (
      <Box flex="grow">
        <TopicHeader title={resources.fields} />
        <Box padding={6}>
          <Box padding={4}>
            {filesErrorMessages && (
              <Text color={colors.error}>{validationMessages.filesErrorMessages}</Text>
            )}
          </Box>
          {sequenceState.files?.map((file, key) => (
            <Box key={key} padding={2} display="flex" direction="row" gap={4}>
              <Box display="flex" flex="grow" direction="row">
                <Box width={"100%"}>
                  <AccordionExpandable
                    accessibilityExpandLabel={resources.expand}
                    accessibilityCollapseLabel={resources.collapse}
                    id={`sequence_list_${key}`}
                    items={[
                      {
                        title: file.fileName,
                        summary: [
                          `${file.valueList.length} ${resources.items} ${
                            !isFileUpdatable(file)
                              ? resources.pendingToSave.toLocaleLowerCase()
                              : resources.saved.toLocaleLowerCase()
                          }`,
                        ],
                        type: "info",
                        icon: "folder",
                        children: (
                          <Box>
                            {file.valueList.map((value, index) => (
                              <Box key={index}>
                                <Text size="300">{value}</Text>
                              </Box>
                            ))}
                          </Box>
                        ),
                      },
                    ]}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                direction="row"
                gap={2}
              >
                {isUpdating && isFileUpdatable(file) ? (
                  <>
                    <TapArea
                      onTap={() => {
                        alterSequenceValue({
                          type: "showDeleteFileWarningModal",
                          value: true,
                          message: resources.deleteAllIfNoItemUsed,
                        });
                        alterSequenceValue({
                          type: "changeBehavior",
                          value: {
                            behaviorType: "halt-on-used",
                            fileNameToClean: file.fileName,
                            sequenceNameToClean: sequenceState.sequenceName,
                          },
                        });
                      }}
                    >
                      <Tooltip text={resources.deleteAllIfNoItemUsed}>
                        <HardDeleteDocumentSvgIcon width={30} height={30} color={colors.error} />
                      </Tooltip>
                    </TapArea>
                    <TapArea
                      onTap={() => {
                        alterSequenceValue({
                          type: "showDeleteFileWarningModal",
                          value: true,
                          message: resources.deleteOnlyUnusedItems,
                        });
                        alterSequenceValue({
                          type: "changeBehavior",
                          value: {
                            behaviorType: "skip-used",
                            fileNameToClean: file.fileName,
                            sequenceNameToClean: sequenceState.sequenceName,
                          },
                        });
                      }}
                    >
                      <Tooltip text={resources.deleteOnlyUnusedItems}>
                        <BroomSvgIcon width={30} height={30} />
                      </Tooltip>
                    </TapArea>
                  </>
                ) : (
                  <Box>
                    <TapArea onTap={() => deleteFile(file.fileName)}>
                      <Tooltip text={resources.delete_}>
                        <Icon
                          icon="trash"
                          accessibilityLabel={resources.deleted}
                          color={colors.error}
                          size={"md"}
                        />
                      </Tooltip>
                    </TapArea>
                  </Box>
                )}
              </Box>
              <DeleteFileModal
                rollBackSequenceMutation={rollBackSequenceMutation}
                showModal={showModal}
                alterSequenceValue={alterSequenceValue}
                message={sequenceState.behaviorModalMessage}
              />
            </Box>
          ))}
        </Box>
      </Box>
    )
  );
};

const TopicHeader = ({ title }: { title: string }) => {
  const { colors } = useColors();
  return (
    <Box color={colors.neutral200} borderColor={colors.neutral400} padding={2}>
      <Box paddingX={4}>
        <Text weight="bold" size="300">
          {title}
        </Text>
      </Box>
    </Box>
  );
};

type DeleteFileModalProps = {
  rollBackSequenceMutation: () => void;
  showModal: boolean;
  alterSequenceValue: (action: SequenceMsg) => void;
  message: string | undefined;
};

const DeleteFileModal = ({
  rollBackSequenceMutation,
  showModal,
  alterSequenceValue,
  message,
}: DeleteFileModalProps) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const closeModal = useCallback(() => {
    alterSequenceValue({ type: "showDeleteFileWarningModal", value: false, message: undefined });
  }, [alterSequenceValue]);

  return (
    showModal &&
    message && (
      <Box>
        {
          <Layer zIndex={modalZIndex}>
            <Modal
              accessibilityModalLabel={`${resources.delete_} ${resources.fields}`}
              heading={
                <Box paddingY={2}>
                  <Box>
                    <Text weight="bold" size="400" color={colors.secondary}>
                      {resources.saveChangesBeforeProceeding}
                    </Text>
                  </Box>
                  <Divider />
                </Box>
              }
              size="sm"
              onDismiss={() => closeModal()}
              footer={
                <Box display="flex">
                  <Box>
                    <Button
                      color={"transparent"}
                      onClick={() => closeModal()}
                      text={resources.cancel}
                    />
                  </Box>
                  <Box marginStart="auto">
                    <Button
                      color={"red"}
                      onClick={() => {
                        rollBackSequenceMutation();
                        closeModal();
                      }}
                      text={resources.yesDelete}
                    />
                  </Box>
                </Box>
              }
            >
              <Box margin={6} display="flex" justifyContent="center" marginBottom={12}>
                <Text weight="bold" size="400">
                  {message}
                </Text>
              </Box>
              <Divider />
            </Modal>
          </Layer>
        }
      </Box>
    )
  );
};
