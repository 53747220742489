import type {
  StringTemplateParameterValue,
  StringTemplatePart,
} from "@prodoctivity/shared/src/index-types";
import type { DistributionConfiguration, DistributionParameter } from "@prodoctivity/types";
import { FunctionComponent, useCallback, useState } from "react";

import { StringTemplateBuilderEventManager } from "@prodoctivity/design-system";
import { shouldNever } from "@prodoctivity/shared";
import { DistributionInputParameterConfig } from "./DistributionInputParameterConfig";

type Props = {
  config: DistributionConfiguration;
  eventManager: StringTemplateBuilderEventManager;
  variableList: StringTemplateParameterValue[];
  onChange(parameters: Record<string, DistributionParameter>): void;
  onPartClicked(args: { lineIndex: number; partIndex: number; part: StringTemplatePart }): void;
};

export const DistributionConfig: FunctionComponent<Props> = ({
  config,
  eventManager,
  variableList,
  onChange,
  onPartClicked,
}) => {
  function initialState(): Record<string, DistributionParameter> {
    return config.inputParameters.reduce((result: Record<string, DistributionParameter>, next) => {
      result[next.key] = next;

      return result;
    }, {});
  }
  const [updatedConfig, setUpdatedConfig] =
    useState<Record<string, DistributionParameter>>(initialState);

  const onParameterUpdated = useCallback(
    (args: { parameterKey: string; parts: StringTemplatePart[] }) => {
      const updatedParameter = {
        key: args.parameterKey,
        template: {
          values: getParameterValuesFromParts(variableList, args.parts),
        },
      };

      setUpdatedConfig((cfg) => {
        return {
          ...cfg,
          [args.parameterKey]: updatedParameter,
        };
      });

      onChange({ ...updatedConfig, [args.parameterKey]: updatedParameter });
    },
    [onChange, variableList, updatedConfig]
  );

  const handlePartClicked = useCallback(
    (args: { lineIndex: number; partIndex: number; part: StringTemplatePart }) => {
      onPartClicked({
        lineIndex: args.lineIndex,
        partIndex: args.partIndex,
        part: args.part,
      });
    },
    [onPartClicked]
  );

  return (
    <>
      {config.inputParameters.map((param) => {
        return (
          <DistributionInputParameterConfig
            key={config.distributionId + param.key}
            param={param}
            eventManager={eventManager}
            onParameterUpdated={onParameterUpdated}
            onPartClicked={handlePartClicked}
          />
        );
      })}
    </>
  );
};

function getParameterValuesFromParts(
  variableList: StringTemplateParameterValue[],
  parts: StringTemplatePart[]
): StringTemplateParameterValue[] {
  const paramValues: StringTemplateParameterValue[] = parts.map((p) => {
    switch (p.type) {
      case "text":
        return { value: p.value, source: "fixed" };

      case "variable": {
        const currentVariable = variableList.find((v) => v.value === p.name);
        if (!currentVariable) {
          return {
            source: "context",
            value: p.name,
          };
        }
        switch (currentVariable.source) {
          case "distribution-result": {
            return {
              source: "distribution-result",
              value: currentVariable.value,
              mimeType: currentVariable.mimeType,
            };
          }
          case "generation-result": {
            return { source: "generation-result", value: "document" };
          }
          default: {
            return {
              source: currentVariable.source,
              value: currentVariable.value,
            };
          }
        }
      }

      default:
        shouldNever(p);
        throw new Error();
    }
  });
  return paramValues;
}

export function getVariableList(params: DistributionParameter[]): StringTemplateParameterValue[] {
  const variableList: StringTemplateParameterValue[] = [];
  for (const param of params) {
    for (const v of param.template.values) {
      if (v.source === "fixed") {
        continue;
      } else {
        const varName = v.source === "generation-result" ? "document" : v.value;
        if (!variableList.find((current) => current.value === varName)) {
          if (v.source === "distribution-result") {
            variableList.push({
              source: "distribution-result",
              value: v.value,
              mimeType: v.mimeType,
            });
          } else if (v.source === "generation-result") {
            variableList.push({
              source: "generation-result",
              value: "document",
            });
          } else {
            variableList.push({
              source: v.source,
              value: varName,
            });
          }
        }
      }
    }
  }
  return variableList;
}
