import { Box, Button, SearchField, Text, useDesignBreakpoint } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { SmallRefreshButton } from "./SmallRefreshButton";

type Props = {
  componentHeaderText: string;
  refetchFunction: () => void;
  searchFieldId: string;
  searchFieldLabel: string;
  refreshButtonLabel: string;
  setFilter: (filter: string) => void;
  addButtonLabel?: string;
  addButtonText?: string;
  disableAddButton?: boolean;
  buttonAction?: () => void;
  omitSearch?: boolean;
};

export const SearchComponentWrapper: FunctionComponent<Props> = (props: Props) => {
  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box
      display="flex"
      alignContent="center"
      direction={breakpoint === "large" || breakpoint === "hd" ? "row" : "column"}
      paddingX={breakpoint === "small" ? 2 : 6}
      width={"100%"}
    >
      <Box direction="row" display="flex" alignItems="center">
        <Text weight="bold" size="400">
          {props.componentHeaderText}
        </Text>
        <SmallRefreshButton
          refetch={props.refetchFunction}
          accessibilityLabel={props.refreshButtonLabel}
          marginTop={1}
          marginStart={2}
        />
      </Box>
      <Box
        direction="row"
        display="flex"
        justifyContent={breakpoint === "large" || breakpoint === "hd" ? "end" : "between"}
        flex="grow"
        alignItems="center"
      >
        {!props.omitSearch && (
          <SearchField
            marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
            marginEnd={props.buttonAction !== undefined ? 2 : undefined}
            accessibilityLabel={props.searchFieldLabel}
            id={props.searchFieldId}
            onChange={({ value }) => {
              props.setFilter(value);
            }}
          />
        )}

        {props.buttonAction && (
          <Box>
            <Button
              size="sm"
              iconEnd="add-circle"
              color="blue"
              type="button"
              accessibilityLabel={props.addButtonLabel ?? "..."}
              disabled={props.disableAddButton}
              text={props.addButtonText ?? "..."}
              onClick={props.buttonAction}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
