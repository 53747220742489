import { Box, Divider, Tabs, TabsProps, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode, useMemo } from "react";

type DataListTab = {
  title: string;
  content: ReactNode;
};

type DataListTabsProps = {
  tabs: DataListTab[];
  selectedTabIndex: number;
  onTabClick: (index: number) => void;
};

export const DataListConfigurationTabs: FunctionComponent<DataListTabsProps> = ({
  tabs,
  selectedTabIndex,
  onTabClick,
}) => {
  const { colors } = useColors();
  const tabSettings: TabsProps["tabs"] = useMemo(() => {
    return tabs.map((tab) => {
      const t: TabsProps["tabs"][number] = {
        href: "",
        text: tab.title,
        id: tab.title,
      };

      return t;
    });
  }, [tabs]);

  return (
    <Box display="flex" direction="column">
      <Box display="flex" direction="row">
        <Tabs
          activeTabIndex={selectedTabIndex}
          onChange={(args) => {
            args.event.preventDefault();
            if (onTabClick) {
              onTabClick(args.activeTabIndex);
            }
          }}
          tabs={tabSettings}
        />
      </Box>
      <Divider direction="horizontal" />
      <Box color={colors.white} paddingY={4} paddingX={4}>
        {tabs[selectedTabIndex].content}
      </Box>
    </Box>
  );
};
