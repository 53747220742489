import * as React from "react";

import { Button, Modal } from "antd";

import { Icon } from "@prodoctivity/design-system";
import { TaskAction } from "../_types";

export interface ITaskOptionsProps {
  processTaskId: number;
  i18n: (key: string) => string;
  onExit: (result: { processTaskId: number; action: TaskAction }) => Promise<void>;
}

interface IState {
  action: TaskAction | undefined;
  showMenu: boolean;
  showModal: boolean;
}

export class TaskOptions extends React.Component<ITaskOptionsProps, IState> {
  public state: IState = {
    action: undefined,
    showMenu: false,
    showModal: false,
  };

  public render() {
    const { i18n } = this.props;
    const { showModal } = this.state;
    // const menu = <TaskActionsMenu i18n={i18n} />;
    return (
      <div>
        <Button.Group>
          <Button onClick={this.onSave} id="btn-save">
            <Icon accessibilityLabel={i18n("save")} icon="check" />
            {i18n("save")}
          </Button>
          {/* <Dropdown overlay={menu} visible={this.state.showMenu}>
          <Button onClick={this.toggleMenu} id="btn-actions">
            <Icon className="rotate-90" type="ellipsis" />
            {i18n('Actions')}
          </Button>
        </Dropdown> */}
          <Button onClick={this.toggleModal} id="btn-exit">
            <Icon accessibilityLabel={i18n("close")} icon="close" />
            {i18n("ExitEditMode")}
          </Button>
        </Button.Group>
        <Modal
          visible={showModal}
          title={i18n("ExitEditMode")}
          onCancel={this.toggleModal}
          footer={[
            <Button
              id="btn-save-and-exit"
              type="primary"
              key="btn-save-and-exit"
              onClick={this.saveAndExit}
            >
              {i18n("SaveAndGoHome")}
            </Button>,
            <Button id="btn-exit-no-save" key="btn-exit-no-save" onClick={this.closeWithoutSave}>
              {i18n("ExitWithoutSave")}
            </Button>,
            <Button id="btn-cancel-exit" key="back" onClick={this.toggleModal}>
              {i18n("cancel")}
            </Button>,
          ]}
        >
          <p>{i18n("MsgSaveProcess")}</p>
        </Modal>
      </div>
    );
  }

  private toggleMenu = () => {
    this.setState((previousState) => {
      return { showMenu: !previousState.showMenu };
    });
  };

  private toggleModal = () => {
    this.setState((previousState) => {
      return { showModal: !previousState.showModal };
    });
  };

  private onSave = () => {
    this.props.onExit({
      processTaskId: this.props.processTaskId,
      action: TaskAction.SaveAndContinue,
    });
  };

  public saveAndExit = () => {
    this.props.onExit({
      processTaskId: this.props.processTaskId,
      action: TaskAction.SaveAndClose,
    });
    this.setState({
      showModal: false,
    });
  };

  public closeWithoutSave = () => {
    this.props.onExit({
      processTaskId: this.props.processTaskId,
      action: TaskAction.Abandon,
    });
    this.setState({
      showModal: false,
    });
  };
}
