import { Box, Spinner, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, useMemo } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import {
  AddDocumentToList,
  ProfileDocumentLabelsList,
} from "../../pages/DocumentLabels/Components/ProfileDocumentLabelsList/ProfileDocumentsLabelsList";
import { StarSVG } from "../../svg/StarSVG";
import { CountListPopoverMenu } from "./Components/CountListPopoverMenu";
import { ProfileDocumentListOption, useDocumentBookmarkTags } from "./hook";

type IsOnlyFavorite = {
  starWidth: number | undefined;
  starHeight: number | undefined;
  shouldDisplayAddToFavoritesLabel: boolean;
};
export type ViewMode =
  | { type: "onlyFavorite"; value: IsOnlyFavorite }
  | { type: "addToList"; refetchParentInfo: (() => void) | undefined }
  | { type: "countListPopoverMenu" }
  | undefined;

type DocumentBookmarkTagsProps = {
  documentId: string;
  viewMode: ViewMode;
};

export const DocumentBookmarkTags: FunctionComponent<DocumentBookmarkTagsProps> = ({
  documentId,
  viewMode,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const favoritesEntry: ProfileDocumentListOption = useMemo(() => {
    return {
      isFavorites: true,
      label: resources.favorites,
      value: "favorites",
    };
  }, [resources.favorites]);

  const {
    isLoadingProfileLabels,
    isLoadingDocumentLabels,
    profileDocumentLabels: profileDocumentLabelsList,
    selectedLabels,
    handleSelectedLabels,
    handleUpdateProfileLabels,
    profileListsData,
  } = useDocumentBookmarkTags({ documentId, viewMode });

  if (isLoadingDocumentLabels || isLoadingProfileLabels) {
    return <Spinner show={true} />;
  }

  switch (viewMode?.type) {
    case "onlyFavorite":
      const value = viewMode.value;
      return (
        <Box
          display="flex"
          direction="row"
          gap={2}
          flex="grow"
          onClickCapture={() => handleSelectedLabels(favoritesEntry)}
        >
          <Box>
            <StarSVG
              width={value.starWidth}
              height={value.starHeight}
              color={
                selectedLabels.some((list) => list.isFavorites)
                  ? colors.secondary
                  : colors.neutral900
              }
            />
          </Box>
          {value.shouldDisplayAddToFavoritesLabel && (
            <Box display="flex" flex="grow" alignItems="center">
              <Text size="200">{resources.addToFavorites}</Text>
            </Box>
          )}
        </Box>
      );
    case "addToList":
      return (
        <AddDocumentToList
          profileDocumentLabelsList={profileDocumentLabelsList}
          selectedLabels={selectedLabels}
          handleSelectedLabels={handleSelectedLabels}
          onSelectSingle={handleSelectedLabels}
          isLoadingProfileDocumentLabels={isLoadingProfileLabels}
          handleUpdateProfileLabels={handleUpdateProfileLabels}
          isPopoverWindows={true}
          handleDeleteLabel={undefined}
          profileDocumentLists={profileListsData}
          isOnlyShowingNotSelected={true}
          documentId={documentId}
          isWidget={false}
        />
      );
    case "countListPopoverMenu":
      return <CountListPopoverMenu documentId={documentId} />;
    default:
      return (
        <ProfileDocumentLabelsList
          profileDocumentLabelsList={profileDocumentLabelsList}
          selectedLabels={selectedLabels}
          handleSelectedLabels={handleSelectedLabels}
          onSelectSingle={handleSelectedLabels}
          isLoadingProfileDocumentLabels={isLoadingProfileLabels}
          handleUpdateProfileLabels={handleUpdateProfileLabels}
          isPopoverWindows={true}
          handleDeleteLabel={undefined}
          profileDocumentLists={profileListsData}
          isOnlyShowingNotSelected={false}
          isWidget={false}
        />
      );
  }
};
