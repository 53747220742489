import { FunctionComponent } from "react";
type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};
export const DocumentPendingSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 50,
  height = 50,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      style={svgStyle}
      viewBox={`0 0 50 50`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect width="50" height="50" rx="25" fill="#FFEFF0" />
      <rect x="18.1084" y="12.6973" width="19.4595" height="25.4545" rx="2" fill="#FFC8CB" />
      <g clipPath="url(#clip0_11378_9603)">
        <path
          d="M34.3106 21.6519C34.3106 26.8838 34.3106 32.1012 34.3106 37.3331C34.3106 38.594 33.9247 38.9998 32.7395 38.9998C27.2683 38.9998 21.7832 38.9998 16.312 38.9998C15.1681 38.9998 14.7822 38.594 14.7822 37.3766C14.7822 29.9418 14.7822 22.507 14.7822 15.0577C14.7822 14.6954 14.7822 14.3331 14.9614 13.9998C15.4162 14.1882 15.7745 13.7969 16.2155 13.7969C19.5782 13.8404 22.9546 13.8259 26.3311 13.7969C26.841 13.7969 27.0064 13.9563 26.9926 14.4925C26.9651 16.1592 26.9788 17.8404 26.9926 19.5215C26.9926 20.5505 27.4061 20.9708 28.3983 20.9708C30.3415 20.9708 32.2709 20.9708 34.2141 20.9708C34.4346 21.1592 34.3244 21.4201 34.3244 21.6519H34.3106Z"
          fill="url(#paint0_linear_11378_9603)"
        />
        <path
          d="M34.1862 20.9708C32.243 20.9708 30.3136 20.9708 28.3704 20.9708C27.3919 20.9708 26.9785 20.5506 26.9785 19.5216C26.9785 17.8404 26.9509 16.1737 26.9785 14.4926C26.9785 13.9419 26.8269 13.7824 26.317 13.7969C22.9543 13.8259 19.5778 13.8259 16.2014 13.7969C15.7466 13.7969 15.4021 14.1882 14.9473 13.9998C15.1402 13.5506 15.4985 13.3477 15.9533 13.3477C19.5778 13.3477 23.2024 13.3621 26.8269 13.3766C26.8545 13.4781 26.8958 13.5216 26.9647 13.4056C28.1224 14.4056 29.1146 15.594 30.162 16.6955C31.5126 18.1158 32.8494 19.5506 34.1862 20.9853V20.9708Z"
          fill="#800101"
        />
        <g filter="url(#filter0_b_11378_9603)">
          <path
            d="M21.7143 17.1594C21.7143 13.7577 19.0919 11 15.8571 11C12.6223 11 10 13.7577 10 17.1594C10 20.5612 12.6223 23.3188 15.8571 23.3188C19.0919 23.3188 21.7143 20.5612 21.7143 17.1594Z"
            fill="url(#paint1_linear_11378_9603)"
          />
          <path
            d="M21.7143 17.1594C21.7143 13.7577 19.0919 11 15.8571 11C12.6223 11 10 13.7577 10 17.1594C10 20.5612 12.6223 23.3188 15.8571 23.3188C19.0919 23.3188 21.7143 20.5612 21.7143 17.1594Z"
            fill="#B73636"
            fill-opacity="0.2"
          />
        </g>
        <path d="M30.0525 24.7969H19.1514V26.565H30.0525V24.7969Z" fill="white" />
        <path d="M18.9191 16.0908H12.4326V18.6363H18.9191V16.0908Z" fill="#FEFEFE" />
        <path d="M30.0525 28.8691H19.1514V30.6373H30.0525V28.8691Z" fill="white" />
        <path d="M29.9558 32.9424H19.0547V34.7105H29.9558V32.9424Z" fill="white" />
      </g>
      <g filter="url(#filter1_b_11378_9603)">
        <rect
          x="27.0273"
          y="27.1211"
          width="12.973"
          height="7.63636"
          rx="1"
          fill="url(#paint2_linear_11378_9603)"
        />
      </g>
      <g filter="url(#filter2_bf_11378_9603)">
        <path
          d="M15.8571 11.5C18.7924 11.5 21.2143 14.0098 21.2143 17.1594C21.2143 20.309 18.7924 22.8188 15.8571 22.8188C12.9219 22.8188 10.5 20.309 10.5 17.1594C10.5 14.0098 12.9219 11.5 15.8571 11.5Z"
          stroke="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_11378_9603"
          x="5"
          y="6"
          width="21.7139"
          height="22.3184"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11378_9603" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11378_9603"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_11378_9603"
          x="26.0273"
          y="26.1211"
          width="14.9727"
          height="9.63672"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11378_9603" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11378_9603"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_bf_11378_9603"
          x="5"
          y="6"
          width="21.7139"
          height="22.3184"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11378_9603" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11378_9603"
            result="shape"
          />
          <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_11378_9603" />
        </filter>
        <linearGradient
          id="paint0_linear_11378_9603"
          x1="24.5671"
          y1="13.7969"
          x2="23.2651"
          y2="69.5423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CC0000" />
          <stop offset="1" stop-color="#CC0000" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11378_9603"
          x1="10"
          y1="11"
          x2="18.5897"
          y2="22.0812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7F0101" />
          <stop offset="1" stop-color="#CC0000" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11378_9603"
          x1="20.9463"
          y1="20.7575"
          x2="74.8962"
          y2="53.6639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7F0101" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_11378_9603">
          <rect width="24.3243" height="28" fill="white" transform="translate(10 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};
