import { Box, Link, Text, useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import { organizationLinkTemplates } from "../../link-templates";
import { useAppTranslation } from "../../hooks/useAppTranslation";

export function FooterLink() {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const isSmallDisplay = breakpoint === "small" || breakpoint === "medium";

  return (
    <>
      <Box paddingX={isSmallDisplay ? 2 : 0}>
        <Text color={colors.neutral900} size="200">
          <Link href="terms">{resources.footer.terms}</Link>
        </Text>
      </Box>
      <Box paddingX={isSmallDisplay ? 2 : 0}>
        <Text color={colors.neutral900} size="200">
          <Link href="privacy-policy">{resources.footer.privacy}</Link>
        </Text>
      </Box>
      <Box paddingX={isSmallDisplay ? 2 : 0}>
        <Text color={colors.neutral900} size="200">
          {resources.security}
        </Text>
      </Box>
      <Box paddingX={isSmallDisplay ? 2 : 0}>
        <Text color={colors.neutral900} size="200">
          {resources.footer.contact}
        </Text>
      </Box>
      <Box paddingX={isSmallDisplay ? 2 : 0}>
        <Link href={organizationLinkTemplates.documentation()}>
          <Text color={colors.neutral900} size="200">
            {resources.footer.api}
          </Text>
        </Link>
      </Box>
    </>
  );
}
