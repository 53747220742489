import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DocSvgIcon: FunctionComponent<Props> = ({
  color,
  width = 25,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 0.850294V21.1497C3 21.6233 3.37192 22 3.83949 22H21.1605C21.6281 22 22 21.6233 22 21.1497V11.8503C22 11.3767 21.6281 11 21.1605 11H11.9793C11.5117 11 11.1398 10.6233 11.1398 10.1497V0.850294C11.1398 0.376712 10.7679 0 10.3003 0H3.83949C3.37192 0 3 0.376712 3 0.850294ZM13.8602 2.05577V7.40509C13.8602 7.87867 14.2321 8.25538 14.6997 8.25538H19.981C20.7248 8.25538 21.0968 7.34051 20.5761 6.80235L15.2841 1.45303C14.7528 0.914873 13.8496 1.30235 13.8496 2.05577H13.8602ZM6.55984 5.51076H7.60123C8.06879 5.51076 8.44072 5.88748 8.44072 6.36106V7.41585C8.44072 7.88943 8.06879 8.26614 7.60123 8.26614H6.55984C6.09228 8.26614 5.72036 7.88943 5.72036 7.41585V6.36106C5.72036 5.88748 6.09228 5.51076 6.55984 5.51076ZM6.55984 11.0108H7.60123C8.06879 11.0108 8.44072 11.3875 8.44072 11.8611V12.9159C8.44072 13.3894 8.06879 13.7661 7.60123 13.7661H6.55984C6.09228 13.7661 5.72036 13.3894 5.72036 12.9159V11.8611C5.72036 11.3875 6.09228 11.0108 6.55984 11.0108ZM6.55984 16.5108H15.7411C16.2086 16.5108 16.5805 16.8875 16.5805 17.3611V18.4159C16.5805 18.8894 16.2086 19.2661 15.7411 19.2661H6.54922C6.08166 19.2661 5.70973 18.8894 5.70973 18.4159V17.3611C5.70973 16.8875 6.08166 16.5108 6.54922 16.5108H6.55984Z"
        fill={color || "#5A5A5A"}
      />
    </svg>
  );
};
