import {
  Box,
  Button,
  Center,
  Divider,
  Spinner,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LoginLayout } from "../../components/Layout/LoginLayout";
import { LoginLayoutSmall } from "../../components/Layout/LoginLayoutFooterSmall";
import { useAppDocumentTitle } from "../../hooks/useAppDocumentTitle";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { InvalidTokenSvg } from "../../svg/InvalidTokenSvg";
import { WelcomeToOrgIconSvg } from "../../svg/WelcomeToOrgIconSvg";

const VerifyAccountPage: FC = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  useAppDocumentTitle(resources.verifyAccount);
  const { userActivateOrganization, renewToken, saveToken, verifyToken } = useServices();
  const [needsVerify, setNeedsVerify] = useState(true);
  const navigate = useNavigate();
  const { otp } = useParams();
  const { breakpoint } = useDesignBreakpoint();
  const continueAction = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const goToSignUp = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  const goToLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const verifyingToken = useCallback(async () => {
    if (otp) {
      try {
        const res = await verifyToken(otp, undefined);
        return res;
      } catch (error) {
        throw error;
      }
    }
  }, [otp, verifyToken]);

  const { isLoading: tokenIsLoading, isError: tokenIsError } = useQuery(
    [`/verify-token/`, otp],
    verifyingToken,
    {
      staleTime: 60 * 1000,
    }
  );
  const activate = useCallback(async () => {
    if (!otp) {
      return;
    }
    const response = await userActivateOrganization(otp);
    const jwtToken = response.jwtToken;
    await renewToken(jwtToken);
    saveToken(jwtToken);
  }, [otp, renewToken, userActivateOrganization, saveToken]);

  const {
    isError,
    isLoading,
    mutate: doActivation,
    reset,
    status,
  } = useMutation({
    mutationFn: activate,
  });

  const activateClick = useCallback(() => {
    doActivation(undefined, {
      onSuccess: () => {
        setNeedsVerify(false);
        reset();
        goToLogin();
      },
    });
  }, [doActivation, goToLogin, reset]);

  const activationJSX = (
    <Center direction="column">
      {needsVerify && status === "idle" ? (
        tokenIsLoading ? (
          <Spinner accessibilityLabel={resources.loading} show={true} />
        ) : tokenIsError ? (
          <Box color={colors.neutral300}>
            <Box display="flex" justifyContent="center" marginBottom={12}>
              <Text color={colors.primary400} weight="bold" size="500">
                {resources.anErrorHasOccurred}
              </Text>
            </Box>
            <Box display="flex" justifyContent="center" marginBottom={12}>
              {breakpoint === "small" || breakpoint === "medium" ? (
                <InvalidTokenSvg width={251} height={228} />
              ) : (
                <InvalidTokenSvg />
              )}
            </Box>
            <Box paddingX={10}>
              <Divider />
            </Box>
            <Box
              padding={12}
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
              marginTop={5}
            >
              <Text align="center" color={colors.neutral900}>
                {resources.invitationExpiredMessage}
              </Text>
              <Box display="flex" alignContent="center">
                <Button
                  accessibilityLabel={resources.signUp}
                  text={resources.signUp}
                  onClick={goToSignUp}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            maxWidth={734}
            maxHeight={805}
            width="100%"
            height="auto"
            borderRadius={12}
            marginBottom="auto"
            alignSelf="center"
            right
            padding={12}
            rounding="pill"
            display="flex"
            direction="column"
            borderStyle="shadow"
            justifyContent="between"
          >
            <Box
              display="flex"
              direction="column"
              justifyContent="between"
              alignItems="center"
              marginBottom={2}
            >
              <Text color={colors.black900} weight="bold" size="400" title="">
                {resources.accountActivation}
              </Text>

              <Box
                width={"100%"}
                height={"2px"}
                color={colors.primaryHover0}
                alignSelf="center"
                display="flex"
                marginTop={2}
                marginBottom={3}
              ></Box>

              <Text color={colors.primary} weight="bold" size="600" title="">
                {resources.welcome}
              </Text>

              <Box display="flex" justifyContent="center" alignItems="center" width={"100%"}>
                <Box right={true} top={true}>
                  <Box margin={2} />
                  {breakpoint === "small" ? (
                    <WelcomeToOrgIconSvg width={251} height={228} />
                  ) : (
                    <WelcomeToOrgIconSvg />
                  )}
                </Box>
              </Box>
            </Box>
            <Button
              size={breakpoint === "small" || breakpoint === "medium" ? "md" : "lg"}
              text={resources.activateYourAccount}
              accessibilityLabel={resources.activateYourAccount}
              fullWidth={true}
              color={"blue"}
              onClick={() => {
                activateClick();
              }}
            />
          </Box>
        )
      ) : isLoading || !otp ? (
        <Spinner accessibilityLabel={resources.loading} show={true} />
      ) : isError ? (
        <Box color={colors.neutral300}>
          <Box display="flex" justifyContent="center" marginBottom={12}>
            <Text color={colors.primary400} weight="bold" size="500">
              {resources.anErrorHasOccurred}
            </Text>
          </Box>
          <Box display="flex" justifyContent="center" marginBottom={12}>
            {breakpoint === "small" || breakpoint === "medium" ? (
              <InvalidTokenSvg width={251} height={228} />
            ) : (
              <InvalidTokenSvg />
            )}
          </Box>
          <Box paddingX={10}>
            <Divider />
          </Box>
          <Box
            padding={12}
            display="flex"
            direction="column"
            justifyContent="center"
            alignItems="center"
            marginTop={5}
          >
            <Text align="center" color={colors.neutral900}>
              {resources.invitationExpiredMessage}
            </Text>
            <Box display="flex" alignContent="center">
              <Button
                accessibilityLabel={resources.signUp}
                text={resources.signUp}
                onClick={goToSignUp}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Text>{resources.youHaveActivatedYourAccount}</Text>
          <Button
            accessibilityLabel={resources.continue}
            text={resources.continue}
            onClick={continueAction}
          />
        </Box>
      )}
    </Center>
  );

  return (
    <Box>
      {(breakpoint === "large" || breakpoint === "hd") && (
        <LoginLayout>{activationJSX}</LoginLayout>
      )}
      {(breakpoint === "medium" || breakpoint === "small") && (
        <LoginLayoutSmall>{activationJSX}</LoginLayoutSmall>
      )}
    </Box>
  );
};
export default VerifyAccountPage;
