// @flow

import keyMirror from "keymirror";

export const ControlType: {
  Default: "Default";
} = Object.freeze(
  keyMirror({
    Default: null,
  })
);

export const ControlLayout: {
  Vertical: "Vertical";
  Horizontal: "Horizontal";
} = Object.freeze(
  keyMirror({
    Vertical: null,
    Horizontal: null,
  })
);

export const AlphanumericControlType: {
  TextBox: "TextBox";
  TextArea: "TextArea";
} = Object.freeze(
  keyMirror({
    TextBox: null,
    TextArea: null,
  })
);

export const IntegerControlType: {
  Unmasked: "Unmasked";
  Masked: "Masked";
} = Object.freeze(
  keyMirror({
    Unmasked: null,
    Masked: null,
  })
);

export const BooleanControlType: {
  Checkbox: "Checkbox";
  Radio: "Radio";
  Dropdown: "Dropdown";
  Switch: "Switch";
} = Object.freeze(
  keyMirror({
    Checkbox: null,
    Radio: null,
    Dropdown: null,
    Switch: null,
  })
);

export const DateControlType: {
  DatePicker: "DatePicker";
} = Object.freeze(
  keyMirror({
    DatePicker: null,
  })
);

export const TimeControlType: {
  TimePicker: "TimePicker";
} = Object.freeze(
  keyMirror({
    TimePicker: null,
  })
);

export const DateTimeControlType: {
  DateTimePicker: "DateTimePicker";
} = Object.freeze(
  keyMirror({
    DateTimePicker: null,
  })
);

export const ImageControlType: {
  ElectronicSignatureGrabber: "ElectronicSignatureGrabber";
  ButtonImageFile: "ButtonImageFile";
} = Object.freeze(
  keyMirror({
    ElectronicSignatureGrabber: null,
    ButtonImageFile: null,
  })
);

export const DictionaryListControlType: {
  Checkbox: "Checkbox";
  Radio: "Radio";
  Dropdown: "Dropdown";
  MultiSelect: "MultiSelect";
} = Object.freeze(
  keyMirror({
    Checkbox: null,
    Radio: null,
    Dropdown: null,
    MultiSelect: null,
  })
);

export const DataLinkActions: {
  AllowModify: "AllowModify";
  NotModify: "NotModify";
} = Object.freeze(
  keyMirror({
    AllowModify: null,
    NotModify: null,
  })
);
