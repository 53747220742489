import {
  Box,
  BoxWithRef,
  Divider,
  Icon,
  Popover,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { MimeTypesArray, mimeTypeToExtension } from "@prodoctivity/shared";
import { ChangeEvent, useCallback, useRef, useState } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";
import { PrinterSvgIcon } from "../../svg/PrinterSvgIcon";
import { ScannerSvgIcon } from "../../svg/scanner/ScannerSvgIcon";
import { WebAuthorSvgIcon } from "../../svg/WebAuthorSvg";
import { WordAuthorSvgIcon } from "../../svg/WordAuthorSvgIcon";
import { OrganizationNavLink } from "../OrganizationLink";
import { useDetectOs } from "../../hooks";
import { ImportSvgDownArrowIcon } from "../../svg/ImportSvgDownArrowIcon";

type CreateItemProps = {
  forceOpen: boolean;
};

const acceptedFormats = MimeTypesArray.map(mimeTypeToExtension).join(",");

export function CreateItem({ forceOpen }: CreateItemProps) {
  const { colors } = useColors();
  const { os } = useDetectOs();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const organizationNavigate = useOrganizationNavigate();

  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const { resources } = useAppTranslation();

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      organizationNavigate(organizationLinkTemplates.createDocument(), {
        state: {
          file: selectedFile,
          mimeType: selectedFile?.type,
        },
      });
    }
  };

  return (
    <BoxWithRef
      ref={anchorRef}
      onClickCapture={onClick}
      aria-label="Create"
      aria-roledescription="button"
      dangerouslySetInlineStyle={{
        __style: {
          cursor: "pointer",
        },
      }}
    >
      <Icon
        padding={1}
        color={colors.primary}
        hoverColor={colors.white}
        textShadowOnHover={true}
        accessibilityLabel={resources.create}
        icon="file-plus"
        size={6}
      />
      <input
        name="importInput"
        type="file"
        accept={acceptedFormats}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      {(open || forceOpen) && (
        <Popover
          anchor={anchorRef.current}
          onDismiss={() => setOpen(false)}
          idealDirection="down"
          positionRelativeToAnchor={true}
          size="xl"
          shouldFocus={true}
          color="white"
          role="menu"
        >
          <Box paddingX={6} paddingY={4} width={346} color={colors.white}>
            <Box display="flex" direction="column" gap={4}>
              <Box display="flex" direction="row">
                <Text weight="bold">{resources.newDocument}</Text>
              </Box>

              <OrganizationNavLink to="/templates?action=generate">
                <Box padding={2} rounding={2} datatype="menu-item-entry" color={colors.neutral50}>
                  <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                    <Box marginEnd={5} width={30}>
                      <PrinterSvgIcon
                        customClassName="svg-primary-on-hover"
                        height={24}
                        width={24}
                      />
                    </Box>
                    <Box display="flex" direction="column">
                      <Text
                        customClassName="text-bold-on-hover text-subtle-on-hover"
                        color={colors.subtle}
                      >
                        {resources.generateDocument}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </OrganizationNavLink>

              {os === "Windows" && (
                <Box
                  padding={2}
                  rounding={2}
                  datatype="menu-item-entry"
                  color={colors.neutral50}
                  onClickCapture={() =>
                    organizationNavigate(organizationLinkTemplates.scanNewDocument())
                  }
                >
                  <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                    <Box marginEnd={5} width={30}>
                      <ScannerSvgIcon
                        customClassName="svg-primary-on-hover"
                        height={24}
                        width={24}
                      />
                    </Box>
                    <Box display="flex" direction="column">
                      <TapArea rounding="pill">
                        <Text
                          customClassName="text-bold-on-hover text-subtle-on-hover"
                          color={colors.subtle}
                        >
                          {resources.scanADocument}
                        </Text>
                      </TapArea>
                    </Box>
                  </Box>
                </Box>
              )}

              <Box
                padding={2}
                rounding={2}
                datatype="menu-item-entry"
                color={colors.neutral50}
                onClickCapture={() =>
                  organizationNavigate(organizationLinkTemplates.createDocument())
                }
              >
                <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                  <Box marginEnd={5} width={30}>
                    <ImportSvgDownArrowIcon
                      customClassName="svg-primary-on-hover"
                      height={24}
                      width={24}
                    />
                  </Box>
                  <Box display="flex" direction="column">
                    <TapArea rounding="pill">
                      <Text
                        customClassName="text-bold-on-hover text-subtle-on-hover"
                        color={colors.subtle}
                      >
                        {resources.importADocument}
                      </Text>
                    </TapArea>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" direction="row">
                <Text weight="bold">{resources.newTemplate}</Text>
              </Box>
              <Box padding={2} rounding={2} datatype="menu-item-entry" color={colors.neutral50}>
                <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                  <Box display="flex" direction="column">
                    <TapArea rounding="pill">
                      <OrganizationNavLink to="/templates/author/new">
                        <Box
                          display="flex"
                          direction="row"
                          alignItems="center"
                          justifyContent="start"
                        >
                          <Box marginEnd={5} width={30}>
                            <WebAuthorSvgIcon
                              customClassName="svg-primary-on-hover"
                              height={24}
                              width={24}
                            />
                          </Box>
                          <Box display="flex" direction="column">
                            <Text
                              customClassName="text-bold-on-hover text-subtle-on-hover"
                              color={colors.subtle}
                            >
                              {resources.webAuthor}
                            </Text>
                          </Box>
                        </Box>
                      </OrganizationNavLink>
                    </TapArea>
                  </Box>
                </Box>
              </Box>
              <Box padding={2} rounding={2} datatype="menu-item-entry" color={colors.neutral50}>
                <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                  <Box display="flex" direction="column">
                    <OrganizationNavLink to="/download-advanced-author">
                      <Box
                        display="flex"
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Box marginEnd={5} padding={1} width={32}>
                          <WordAuthorSvgIcon
                            customClassName="svg-primary-on-hover"
                            height={24}
                            width={24}
                          />
                        </Box>
                        <Box display="flex" direction="column">
                          <Text
                            customClassName="text-bold-on-hover text-subtle-on-hover"
                            color={colors.subtle}
                          >
                            {resources.advancedAuthor}
                          </Text>
                        </Box>
                      </Box>
                    </OrganizationNavLink>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Popover>
      )}
    </BoxWithRef>
  );
}
