import {
  Box,
  BoxWithRef,
  Icon,
  Table,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type { DocumentSummary } from "@prodoctivity/types";
import { FunctionComponent, MutableRefObject, useCallback, useState } from "react";
import { DocumentTypeInfo } from "../../../components/Display/DocumentTypeInfo";
import { DocumentBookmarkTags } from "../../../components/DocumentBookmarkTags/DocumentBookmarkTags";

import { FancyDateTime } from "@prodoctivity/design-system";
import {
  CascadeOptionsPopover,
  CascadeOptionsPopoverItem,
} from "../../../components/CascadeOptionsPopover/CascadeOptionsPopover";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { BrowseDocumentLabelsFilter } from "../../../link-templates";
import { DocIconsSvg } from "../../../svg/DocumentIconsSvg";
import { EllipseMenuSvg } from "../../../svg/EllipseMenuSvg";
import { IconsEssentialsTableSort } from "../../../svg/IconsEssentialsTableSort";
import { IconsEssentialsTableSortAsc } from "../../../svg/IconsEssentialsTableSortAsc";
import { IconsEssentialsTableSortDesc } from "../../../svg/IconsEssentialsTableSortDesc";
import { ListSvg } from "../../../svg/ListSvg";
import { MenuSvg } from "../../../svg/MenuSvg";

export type DocumentLabelsTableProps = {
  filter: BrowseDocumentLabelsFilter;
  isSmallOrMediumBreakpoint: boolean;
  changeSort: (
    field: "name" | "updatedAt" | "documentType" | undefined,
    direction: "ASC" | "DESC" | undefined
  ) => void;
  handleMoveTo: (documentId: string) => void;
  anchorRefItems: MutableRefObject<(HTMLDivElement | null)[]>;
  documentsData: DocumentSummary[];
  refetch: () => void;
};

export const DocumentLabelsTable: FunctionComponent<DocumentLabelsTableProps> = ({
  anchorRefItems,
  changeSort,
  filter,
  handleMoveTo,
  isSmallOrMediumBreakpoint,
  documentsData,
  refetch,
}) => {
  const { resources, moment } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const [documentId, setDocumentId] = useState<string>("");

  const CascadeOptionsPopoverList: CascadeOptionsPopoverItem[] = [
    {
      childIcon: <MenuSvg width={40} />,
      childLabel: resources.viewDocument,
      childHoverLabel: resources.viewDocument,
      treeItem: false,
      treeContent: undefined,
      key: resources.viewDocument,
    },
    {
      childIcon: (
        <Icon
          icon={"copy"}
          accessibilityLabel={resources.browseDocument.copyDocumentId}
          color={colors.neutral900}
          size={"sm"}
        />
      ),
      childLabel: resources.browseDocument.copyDocumentId,
      childHoverLabel: resources.browseDocument.copyDocumentId,
      treeItem: false,
      treeContent: undefined,
      key: resources.browseDocument.copyDocumentId,
    },
    {
      childIcon: <ListSvg width={20} height={40} />,
      childLabel: resources.lists,
      childHoverLabel: resources.myLists,
      treeItem: true,
      treeContent: (
        <Box>
          <DocumentBookmarkTags documentId={documentId} viewMode={undefined} />
        </Box>
      ),
      key: resources.myLists,
    },
  ];

  const handleCascadeOptionsPopoverClick = useCallback(
    (key: string) => {
      if (documentId) {
        switch (key) {
          case resources.viewDocument:
            handleMoveTo(documentId);
            break;
          case resources.browseDocument.copyDocumentId:
            navigator.clipboard.writeText(documentId);
            break;
          default:
            break;
        }
      }
    },
    [documentId, handleMoveTo, resources.browseDocument.copyDocumentId, resources.viewDocument]
  );
  return (
    <Table accessibilityLabel={resources.myLists}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={2}>
            <Box display="flex" direction="row" alignItems="center" marginBottom={2} gap={1}>
              <Box paddingX={isSmallOrMediumBreakpoint ? 2 : 4}>
                <Icon
                  icon={"document"}
                  accessibilityLabel={resources.document}
                  color={colors.black600}
                  size={"sm"}
                />
              </Box>

              <Text weight="bold" size="300">
                {resources.documentTypes.documentType.toUpperCase()}
              </Text>

              <TapArea
                onTap={() => {
                  switch (filter.sortField === "documentType" && filter.sortDirection) {
                    case "ASC":
                      changeSort("documentType", "DESC");
                      break;
                    case "DESC":
                      changeSort("documentType", "ASC");
                      break;
                    default:
                      changeSort("documentType", undefined);
                      break;
                  }
                }}
              >
                {filter.sortField === "documentType" ? (
                  <Box>
                    {filter.sortDirection === "DESC" ? (
                      <IconsEssentialsTableSortDesc />
                    ) : (
                      <IconsEssentialsTableSortAsc />
                    )}
                  </Box>
                ) : (
                  <Box>
                    <IconsEssentialsTableSort />
                  </Box>
                )}
              </TapArea>
            </Box>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Box display="flex" direction="row" marginBottom={2} alignItems="center">
              <Text weight="bold" size="300">
                {resources.name.toUpperCase()}
              </Text>

              <TapArea
                onTap={() => {
                  switch (filter.sortField === "name" && filter.sortDirection) {
                    case "ASC":
                      changeSort("name", "DESC");
                      break;
                    case "DESC":
                      changeSort("name", "ASC");
                      break;
                    default:
                      changeSort("name", undefined);
                      break;
                  }
                }}
              >
                {filter.sortField === "name" ? (
                  <Box>
                    {filter.sortDirection === "DESC" ? (
                      <IconsEssentialsTableSortDesc />
                    ) : (
                      <IconsEssentialsTableSortAsc />
                    )}
                  </Box>
                ) : (
                  <Box>
                    <IconsEssentialsTableSort />
                  </Box>
                )}
              </TapArea>
            </Box>
          </Table.HeaderCell>
          {breakpoint === "hd" && (
            <Table.HeaderCell>
              <Box display="flex" direction="row" marginBottom={2} alignItems="center">
                <Text weight="bold" size="300">
                  {resources.dataTypeValues.date.toUpperCase()}
                </Text>

                <TapArea
                  onTap={() => {
                    switch (filter.sortField === "updatedAt" && filter.sortDirection) {
                      case "ASC":
                        changeSort("updatedAt", "DESC");
                        break;
                      case "DESC":
                        changeSort("updatedAt", "ASC");
                        break;
                      default:
                        changeSort("updatedAt", undefined);
                        break;
                    }
                  }}
                >
                  {filter.sortField === "updatedAt" ? (
                    <Box>
                      {filter.sortDirection === "DESC" ? (
                        <IconsEssentialsTableSortDesc />
                      ) : (
                        <IconsEssentialsTableSortAsc />
                      )}
                    </Box>
                  ) : (
                    <Box>
                      <IconsEssentialsTableSort />
                    </Box>
                  )}
                </TapArea>
              </Box>
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(documentsData || []).map((document, idx) => {
          const expandedChildren = document.documentLists || [];

          return (
            <Table.RowExpandable
              key={idx}
              accessibilityCollapseLabel="Collapse"
              accessibilityExpandLabel="Expand"
              hoverStyle="gray"
              expandedContents={
                expandedChildren ? (
                  <Box display="flex" gap={2} direction="row">
                    {expandedChildren.map((documentListRow, index) => (
                      <Box key={index} padding={1} borderRadius={4} color={colors.white}>
                        <Text size="300">
                          {documentListRow.isFavorites ? resources.favorites : documentListRow.name}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box padding={2}>
                    <Text size="300">{resources.noResultsFound}</Text>
                  </Box>
                )
              }
              id={document.documentId}
            >
              <Table.Cell>
                <TapArea>
                  <Box
                    display="flex"
                    direction="row"
                    alignItems="center"
                    onClickCapture={() => handleMoveTo(document.documentId)}
                  >
                    <Box paddingX={2}>{<DocIconsSvg />}</Box>
                    <DocumentTypeInfo
                      size="300"
                      documentTypeId={document.documentTypeId}
                      skipLink={true}
                    />
                  </Box>
                </TapArea>
              </Table.Cell>
              <Table.Cell>
                <TapArea onTap={() => handleMoveTo(document.documentId)}>
                  <Text size="300" title={document.name} ellipsisLength={65}>
                    {document.name}
                  </Text>
                </TapArea>
              </Table.Cell>

              {breakpoint === "hd" && (
                <Table.Cell>
                  <TapArea onTap={() => handleMoveTo(document.documentId)}>
                    <FancyDateTime
                      moment={moment}
                      resources={resources}
                      value={document.createdAt}
                    />
                  </TapArea>
                </Table.Cell>
              )}

              <Table.Cell>
                <BoxWithRef ref={(ref) => (anchorRefItems.current[idx] = ref)}>
                  <TapArea
                    onTap={() => {
                      setDocumentId(document.documentId);
                    }}
                  >
                    <CascadeOptionsPopover
                      icon={<EllipseMenuSvg />}
                      hoverLabel={undefined}
                      items={CascadeOptionsPopoverList}
                      label={undefined}
                      onClick={handleCascadeOptionsPopoverClick}
                      searchFilter={false}
                      childPopoverActionOnClose={() => refetch()}
                    />
                  </TapArea>
                </BoxWithRef>
              </Table.Cell>
              <Table.Cell>
                <BoxWithRef ref={(ref) => (anchorRefItems.current[idx] = ref)}>
                  <TapArea
                    onTap={() => {
                      setDocumentId(document.documentId);
                    }}
                  >
                    <DocumentBookmarkTags
                      documentId={document.documentId}
                      viewMode={{
                        type: "countListPopoverMenu",
                      }}
                    />
                  </TapArea>
                </BoxWithRef>
              </Table.Cell>
            </Table.RowExpandable>
          );
        })}
      </Table.Body>
    </Table>
  );
};
