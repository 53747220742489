import {
  Badge,
  Box,
  BoxWithRef,
  ChevronSvgIcon,
  Divider,
  Layer,
  Popover,
  SearchSvgIcon,
  SelectList,
  TapArea,
  Text,
  Tooltip,
  VerticalEllipseMenuSvg,
  WhatsThis,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import {
  DocumentCollectionFolderSortKind$Schema,
  SortDirection$Schema,
  documentIsAboutToExpire,
  documentIsExpired,
} from "@prodoctivity/shared";
import type {
  DocumentCollectionDocumentView,
  DocumentCollectionFolderSortType,
  DocumentCollectionFolderView,
  DocumentsDisplayProps,
  EvaluateValueMapFilter,
  ParametersObject,
} from "@prodoctivity/shared/src/index-types";
import {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
} from "react";
import {
  DocumentCheckSvgIcon,
  DocumentExpiredSvgIcon,
  DocumentMissingSvgIcon,
  DocumentToExpireSvgIcon,
} from "../../../svg/DocumentCollection/DocumentStatus";

import type { DocumentTypeInfo } from "@prodoctivity/types";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { AddDocumentButtonSvg } from "../../../svg/DocumentCollection/AddDocumentButtonSvg";
import { FolderSnapshotSvgIcon } from "../../../svg/DocumentCollection/FolderSnapshotSvgIcon";
import { XIconSvg } from "../../../svg/XIconSvg";
import type { CollectionInstanceEvents } from "../DocumentCollectionInstance";
import { RenderDocumentCollectionIcon } from "../RenderDocumentCollectionIcon";
import { useFolderTreeInstance } from "../hooks";
import { useDocumentTreeInstance } from "./hooks";

type FolderProps = {
  depth: number;
  folder: DocumentCollectionFolderView;
  folderPath: Array<string>;
  resources: ReturnType<typeof useAppTranslation>["resources"];
  selectedFolderPath: Array<string>;
  selectedDocument: DocumentCollectionDocumentView | undefined;
  onFolderSelect: (newFolderPath: Array<string>) => void;
  onSelectDocument: (document: DocumentCollectionDocumentView) => void;
  pathList: Array<string>;
  filterValue: string;
  sortSelectedOptions: { [pathKey: string]: DocumentCollectionFolderSortType };
  setSortSelectedOptions: Dispatch<
    SetStateAction<{ [pathKey: string]: DocumentCollectionFolderSortType }>
  >;
  documentsDisplay: DocumentsDisplayProps;
  documentCollectionResponseContext: ParametersObject;
  documentSelected: string;
  documentSelectedState: (value: string) => void;
  subscribe: (
    eventType: keyof CollectionInstanceEvents,
    handler: () => void
  ) => {
    unsubscribe(): void;
  };
  isHoverDisabledForSelectedId: boolean;
  context: ParametersObject | undefined;
};

export const FolderTreeInstance: FunctionComponent<FolderProps> = ({
  depth,
  folder: originalFolder,
  folderPath,
  resources,
  selectedFolderPath,
  selectedDocument,
  filterValue,
  pathList,
  onFolderSelect,
  onSelectDocument,
  sortSelectedOptions,
  setSortSelectedOptions,
  documentsDisplay,
  documentCollectionResponseContext,
  documentSelected,
  documentSelectedState,
  subscribe,
  isHoverDisabledForSelectedId,
  context,
}) => {
  const {
    folderView: folder,
    showSnapshot,
    setShowSnapshot,
    paddingLeft,
    showChildren,
    documentsCount,
    currentPath,
    isFolderSelected,
    openSortFilter,
    setOpenSortFilter,
    closeFolder,
    folderSortOptions,
    anchorRef,
    selectCollectionElement,
    changeFolderState,
    changeSortFilterPopoverState,
    sortToFoldersAndDocuments,
    changeIssueDocumentPopoverState,
    issuePopoverState,
  } = useFolderTreeInstance(
    depth,
    folderPath,
    originalFolder,
    selectedFolderPath,
    pathList,
    resources,
    onFolderSelect
  );

  const { colors } = useColors();
  const shouldShowDocument = useCallback(
    (doc: DocumentCollectionDocumentView) => {
      const matchesFilterValue =
        !filterValue || doc.name.toLowerCase().includes(filterValue.toLowerCase());

      if (
        documentsDisplay.expiredDocuments &&
        documentsDisplay.expiringDocuments &&
        documentsDisplay.missingDocuments
      ) {
        return matchesFilterValue;
      }

      if (
        documentsDisplay.expiredDocuments ||
        documentsDisplay.expiringDocuments ||
        documentsDisplay.missingDocuments
      ) {
        if (doc.type === "document" && typeof doc.expirationDate === "number") {
          if (documentsDisplay.expiredDocuments && documentIsExpired(doc.expirationDate)) {
            return matchesFilterValue;
          }
          if (
            documentsDisplay.expiringDocuments &&
            documentIsAboutToExpire(doc.expirationDate, doc.documentTypeExpirationWarningDays)
          ) {
            return matchesFilterValue;
          }

          return false;
        }

        if (doc.type === "missing-document") {
          return documentsDisplay.missingDocuments && matchesFilterValue;
        }
      }

      return false;
    },
    [filterValue, documentsDisplay]
  );
  const shouldShowFolder = useCallback(
    (f: DocumentCollectionFolderView): boolean => {
      return f.documents.some(shouldShowDocument) || f.folders.some(shouldShowFolder);
    },
    [shouldShowDocument]
  );

  const sortedAndFilteredFolders = useMemo(() => {
    return folder.folders
      .filter(shouldShowFolder)
      .sort((a, b) =>
        sortToFoldersAndDocuments(
          sortSelectedOptions[currentPath.toString()] || folder.currentSort,
          a,
          b
        )
      );
  }, [
    folder.folders,
    shouldShowFolder,
    sortToFoldersAndDocuments,
    sortSelectedOptions,
    currentPath,
    folder.currentSort,
  ]);

  const sortedAndFilteredDocuments = useMemo(() => {
    return folder.documents
      .filter(shouldShowDocument)
      .sort((a, b) =>
        sortToFoldersAndDocuments(
          sortSelectedOptions[currentPath.toString()] || folder.currentSort,
          a,
          b
        )
      );
  }, [
    folder.documents,
    shouldShowDocument,
    sortToFoldersAndDocuments,
    sortSelectedOptions,
    currentPath,
    folder.currentSort,
  ]);

  const onCollapseAll = useCallback(() => {
    if (depth > 0) {
      closeFolder();
    }
  }, [closeFolder, depth]);

  useLayoutEffect(() => {
    const subscription = subscribe("collapseAll", onCollapseAll);
    return () => {
      subscription.unsubscribe();
    };
  }, [onCollapseAll, subscribe]);

  const sortDirection = sortSelectedOptions[currentPath.toString()]
    ? sortSelectedOptions[currentPath.toString()]?.direction
    : "desc";
  const sortType = sortSelectedOptions[currentPath.toString()]
    ? sortSelectedOptions[currentPath.toString()]?.type
    : "document-date";

  return (
    <Fragment key={`${folder.name}_${depth}`}>
      {depth > 0 && (
        <>
          <BoxWithRef
            ref={anchorRef}
            display="flex"
            width={"100%"}
            justifyContent="between"
            alignItems="center"
            borderStyle="sm"
            paddingY={1}
            dangerouslySetInlineStyle={{
              __style: {
                paddingLeft: `${paddingLeft}px`,
                cursor: "pointer",
              },
            }}
            color={isFolderSelected ? colors.primary100 : colors.white}
          >
            <TapArea onTap={selectCollectionElement}>
              <Box display="flex" gap={3} alignItems="center">
                <RenderDocumentCollectionIcon iconKey={folder.icon} color={folder.color} />
                <TapArea
                  onTap={() => {
                    setOpenSortFilter(false);
                    changeFolderState();
                  }}
                >
                  <Text
                    weight={showSnapshot ? "bold" : "normal"}
                    decoration={showSnapshot ? "line-through" : undefined}
                  >
                    {folder.name}
                  </Text>
                </TapArea>
                <Box display="flex" flex="grow" justifyContent="end">
                  <Box display="flex" direction="row">
                    {originalFolder.snapshot && !showSnapshot && (
                      <Box display="flex" paddingTop={1} alignItems="center">
                        <FolderSnapshotSvgIcon
                          onClick={() => {
                            setShowSnapshot(!showSnapshot);
                            setOpenSortFilter(false);
                          }}
                          color={colors.subtle}
                        />
                      </Box>
                    )}
                    <Box
                      display="flex"
                      alignItems="center"
                      onClickCapture={changeSortFilterPopoverState}
                    >
                      <VerticalEllipseMenuSvg />
                    </Box>
                    <Box color={colors.neutral400} width={"2px"} />

                    <Box
                      onClickCapture={() => {
                        setOpenSortFilter(false);
                        changeFolderState();
                      }}
                    >
                      <ChevronSvgIcon
                        color={colors.subtle}
                        direction={showChildren ? "up" : "down"}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              {isFolderSelected && folder.showHeader && (
                <FolderSummaryBadge resources={resources} documentsCount={documentsCount} />
              )}
            </TapArea>
          </BoxWithRef>
          {isFolderSelected && openSortFilter && (
            <Box>
              <Layer zIndex={modalZIndex}>
                <Popover
                  anchor={anchorRef.current}
                  onDismiss={() => setOpenSortFilter(false)}
                  idealDirection="right"
                  positionRelativeToAnchor={false}
                  size={"flexible"}
                  color="white"
                  role="menu"
                >
                  <Box padding={4} display="flex" direction="column" color={colors.white}>
                    <Box display="flex" width={"100%"} justifyContent="end">
                      <XIconSvg onClick={() => setOpenSortFilter(false)} />
                    </Box>
                    <Box>
                      <Text>{resources.sort}</Text>
                      <SelectList
                        id={"folder-sort-direction"}
                        label={""}
                        options={[
                          { value: "desc", label: resources.dataTypeValues.descending },
                          { value: "asc", label: resources.dataTypeValues.ascending },
                        ]}
                        onChange={(value) => {
                          const validationResult = SortDirection$Schema.safeParse(value.value);

                          if (validationResult.success) {
                            setSortSelectedOptions((prev) => {
                              const newValue = { ...prev };
                              const pathKey = currentPath.toString();

                              if (!newValue[pathKey]) {
                                newValue[pathKey] = { direction: "desc", type: "document-date" };
                              }

                              newValue[pathKey].direction = validationResult.data;
                              return newValue;
                            });
                          }
                        }}
                        value={sortDirection}
                      />
                    </Box>
                    <Box width={"12vw"} paddingY={2}>
                      <Text>{resources.sortBy}</Text>
                      <SelectList
                        id={"folder-sort-by"}
                        label={""}
                        options={folderSortOptions}
                        onChange={(value) => {
                          const validationResult =
                            DocumentCollectionFolderSortKind$Schema.safeParse(value.value);

                          if (validationResult.success) {
                            setSortSelectedOptions((prev) => {
                              const newValue = { ...prev };
                              const pathKey = currentPath.toString();

                              if (!newValue[pathKey]) {
                                newValue[pathKey] = { direction: "desc", type: "document-date" };
                              }

                              newValue[pathKey].type = validationResult.data;
                              return newValue;
                            });
                          }
                        }}
                        value={sortType}
                      />
                    </Box>
                    {originalFolder.snapshot && (
                      <TapArea
                        onTap={() => {
                          setShowSnapshot(!showSnapshot);
                          setOpenSortFilter(false);
                        }}
                      >
                        <Box paddingY={2} display="flex" gap={2} alignItems="center">
                          <FolderSnapshotSvgIcon color={colors.subtle} />
                          <Text>
                            {showSnapshot
                              ? resources.documentCollection.hideSnapshot
                              : resources.documentCollection.viewSnapshot}
                          </Text>
                        </Box>
                      </TapArea>
                    )}
                  </Box>
                </Popover>
              </Layer>
            </Box>
          )}
        </>
      )}
      {showChildren && (
        <Fragment>
          {sortedAndFilteredFolders.map((f, i) => (
            <FolderTreeInstance
              key={`folder_${f.id}_${i}`}
              depth={depth + 1}
              folder={f}
              folderPath={currentPath}
              resources={resources}
              selectedFolderPath={selectedFolderPath}
              selectedDocument={selectedDocument}
              onFolderSelect={onFolderSelect}
              onSelectDocument={onSelectDocument}
              filterValue={filterValue}
              pathList={pathList}
              sortSelectedOptions={sortSelectedOptions}
              setSortSelectedOptions={setSortSelectedOptions}
              documentsDisplay={documentsDisplay}
              documentCollectionResponseContext={documentCollectionResponseContext}
              documentSelected={documentSelected}
              documentSelectedState={documentSelectedState}
              subscribe={subscribe}
              isHoverDisabledForSelectedId={isHoverDisabledForSelectedId}
              context={context}
            />
          ))}
          {sortedAndFilteredDocuments.map((d, i) => (
            <DocumentTreeInstance
              key={`document_${d.name}_${i}`}
              depth={depth + 1}
              document={d}
              isSelected={
                isHoverDisabledForSelectedId
                  ? false
                  : !!selectedDocument && d.name === selectedDocument.name
              }
              onSelectDocument={onSelectDocument}
              changeIssueDocumentPopoverState={changeIssueDocumentPopoverState}
              issuePopoverState={issuePopoverState}
              documentCollectionResponseContext={documentCollectionResponseContext}
              folder={folder}
              currentPath={currentPath}
              documentSelected={documentSelected}
              documentSelectedState={documentSelectedState}
              context={context}
            />
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

type DocumentTreeInstanceProps = {
  depth: number;
  document: DocumentCollectionDocumentView;
  isSelected: boolean;
  onSelectDocument: FolderProps["onSelectDocument"];
  changeIssueDocumentPopoverState: () => void;
  issuePopoverState: boolean;
  documentCollectionResponseContext: ParametersObject;
  folder: DocumentCollectionFolderView;
  currentPath: string[];
  documentSelected: string;
  documentSelectedState: (value: string) => void;
  context: ParametersObject | undefined;
};
export const DocumentTreeInstance: FunctionComponent<DocumentTreeInstanceProps> = ({
  depth,
  document,
  isSelected,
  onSelectDocument,
  changeIssueDocumentPopoverState,
  issuePopoverState,
  currentPath,
  documentCollectionResponseContext,
  folder,
  documentSelected,
  documentSelectedState,
  context,
}) => {
  const { colors } = useColors();
  const documentName = document.name || "";
  const {
    documentTypeInfo,
    generateDocument,
    indexDocument,
    documentAnchorRef,
    isCurrentPathEqualToDocumentSelected,
    changeDocumentSelection,
  } = useDocumentTreeInstance(
    document,
    documentCollectionResponseContext,
    currentPath,
    documentName,
    documentSelected,
    documentSelectedState
  );

  const paddingLeft = depth * 11;
  const { resources } = useAppTranslation();

  const renderAddDocumentPopover = () => {
    return (
      <Box>
        {issuePopoverState &&
          documentSelected &&
          isCurrentPathEqualToDocumentSelected &&
          documentTypeInfo && (
            <Layer zIndex={modalZIndex}>
              <Popover
                anchor={documentAnchorRef.current}
                onDismiss={() => changeIssueDocumentPopoverState()}
                idealDirection="right"
                positionRelativeToAnchor={false}
                size={"flexible"}
                color="white"
                role="menu"
              >
                <Box color={colors.white}>
                  <TapArea onTap={() => addDocument(documentTypeInfo, folder.valueMap, context)}>
                    <Box
                      padding={4}
                      display="flex"
                      direction="column"
                      onBlur={() => changeIssueDocumentPopoverState()}
                    >
                      <Box display="flex" direction="row" gap={2}>
                        <Box display="flex">
                          <AddDocumentButtonSvg />
                        </Box>
                        <Text>{resources.add}</Text>
                      </Box>
                    </Box>
                  </TapArea>
                </Box>
              </Popover>
            </Layer>
          )}
      </Box>
    );
  };
  const addDocument = useCallback(
    (
      documentTypeInfo: DocumentTypeInfo,
      valueMap: Record<string, EvaluateValueMapFilter>,
      context: ParametersObject | undefined
    ) => {
      documentTypeInfo.templateId ? generateDocument(valueMap) : indexDocument(context);
    },
    [generateDocument, indexDocument]
  );

  if (document.type === "missing-document") {
    return (
      <Fragment>
        <TapArea onTap={() => changeDocumentSelection()}>
          <BoxWithRef
            ref={documentAnchorRef}
            minHeight={50}
            alignItems="center"
            display="flex"
            direction="row"
            paddingY={1}
            width={"100%"}
            dangerouslySetInlineStyle={{
              __style: {
                paddingLeft: `${paddingLeft}px`,
                cursor: "pointer",
              },
            }}
          >
            <Box margin={1} flex="shrink" />
            <DocumentViewIcon document={document} />
            <Box display="flex" marginStart={2}>
              <Text color={colors.error} size="200" title={document.name}>
                {(document.name || "").substring(0, 100)}
              </Text>
            </Box>
            <Box margin={1} />
            <Box
              display="flex"
              flex="grow"
              justifyContent="end"
              onClickCapture={changeIssueDocumentPopoverState}
            >
              <VerticalEllipseMenuSvg />
              <Box width={42} />
            </Box>
          </BoxWithRef>
        </TapArea>
        <Divider />
        {renderAddDocumentPopover()}
      </Fragment>
    );
  }

  return (
    <TapArea onTap={() => onSelectDocument(document)}>
      <Box
        minHeight={50}
        alignItems="center"
        display="flex"
        direction="row"
        paddingY={1}
        dangerouslySetInlineStyle={{
          __style: {
            paddingLeft: `${paddingLeft}px`,
            cursor: "pointer",
          },
        }}
        color={isSelected ? colors.neutral400 : undefined}
      >
        <Box margin={1} flex="shrink" />
        <DocumentViewIcon document={document} />
        <Box marginStart={2}>
          <Text size="200" title={document.name}>
            {documentName.length > 48
              ? (documentName || "").substring(0, 47) + "..."
              : documentName}
          </Text>
        </Box>
      </Box>
      <Divider />
    </TapArea>
  );
};

export const DocumentViewIcon = ({
  width,
  height,
  document,
}: {
  width?: number;
  height?: number;
  document: DocumentCollectionDocumentView;
}) => {
  return document.type === "missing-document" ? (
    <DocumentMissingSvgIcon width={width} height={height} />
  ) : document.expirationDate && documentIsExpired(document.expirationDate) ? (
    <DocumentExpiredSvgIcon width={width} height={height} />
  ) : document.expirationDate &&
    documentIsAboutToExpire(document.expirationDate, document.documentTypeExpirationWarningDays) ? (
    <DocumentToExpireSvgIcon width={width} height={height} />
  ) : (
    <RenderDocumentCollectionIcon width={width} height={height} iconKey="document" />
  );
};

export const FolderSummary = ({
  resources,
  documentsCount,
  gapSize,
  setDocumentsDisplay,
  documentsDisplay,
  setFilterValue,
  filterTextFieldEnabled,
  setFilterTextFieldEnabled,
  clearAllFilters,
}: {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  documentsCount: {
    documentCount: number;
    issueCount: number;
    expiredCount: number;
    toExpireCount: number;
  };
  gapSize: 2 | 4 | 6 | 8;
  documentsDisplay: DocumentsDisplayProps;
  setDocumentsDisplay: Dispatch<SetStateAction<DocumentsDisplayProps>>;
  filterTextFieldEnabled: boolean;
  setFilterTextFieldEnabled: Dispatch<SetStateAction<boolean>>;
  setFilterValue: Dispatch<SetStateAction<string>>;
  clearAllFilters: () => void;
}) => {
  const { colors } = useColors();
  const allDocumentsSelected = useMemo(() => {
    return (
      documentsDisplay.missingDocuments &&
      documentsDisplay.expiringDocuments &&
      documentsDisplay.expiredDocuments
    );
  }, [documentsDisplay]);

  const backgroundColorIfDocumentSelected = (value: boolean) => {
    return value ? colors.primaryHover0 : colors.neutral200;
  };

  const borderIfDocumentSelected = (value: boolean) => {
    return value ? colors.primary : colors.neutral400;
  };

  const areAllDocumentsDeselected = (newState: DocumentsDisplayProps) => {
    const areAllDocumentsDeselected =
      !newState.missingDocuments && !newState.expiredDocuments && !newState.expiringDocuments;

    if (areAllDocumentsDeselected) {
      return {
        expiredDocuments: true,
        expiringDocuments: true,
        missingDocuments: true,
      };
    }
    return newState;
  };

  return (
    <Box
      display="flex"
      // direction={breakpoint === "small" ? "column" : "row"}
      flex="grow"
      gap={gapSize}
      justifyContent="between"
    >
      <Box display="flex" gap={2} direction="row">
        <Box>
          <Tooltip text={resources.documentCollection.collectionFilter.showAllDocuments}>
            <TapArea
              onTap={() => {
                clearAllFilters();
                setDocumentsDisplay(() => ({
                  missingDocuments: true,
                  expiredDocuments: true,
                  expiringDocuments: true,
                }));
              }}
            >
              <Box
                color={
                  allDocumentsSelected
                    ? backgroundColorIfDocumentSelected(true)
                    : backgroundColorIfDocumentSelected(allDocumentsSelected)
                }
                borderColor={
                  allDocumentsSelected
                    ? borderIfDocumentSelected(true)
                    : borderIfDocumentSelected(allDocumentsSelected)
                }
                borderRadius={6}
                display="flex"
                direction="column"
                padding={2}
                gap={1}
              >
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                  <DocumentCheckSvgIcon width={25} height={25} />
                  <Text size="100">{documentsCount.documentCount}</Text>
                </Box>
                <Box>
                  <Text align="center" size="100">
                    {resources.documents}
                  </Text>
                </Box>
              </Box>
            </TapArea>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip text={resources.documentCollection.collectionFilter.showIssuesDocuments}>
            <TapArea
              onTap={() => {
                setDocumentsDisplay((prevState) => {
                  if (allDocumentsSelected) {
                    setFilterTextFieldEnabled(true);
                    return {
                      expiredDocuments: !prevState.expiredDocuments,
                      expiringDocuments: !prevState.expiringDocuments,
                      missingDocuments: true,
                    };
                  } else {
                    const newState = {
                      ...prevState,
                      missingDocuments: !prevState.missingDocuments,
                    };
                    return areAllDocumentsDeselected(newState);
                  }
                });
              }}
            >
              <Box
                color={
                  allDocumentsSelected
                    ? backgroundColorIfDocumentSelected(false)
                    : backgroundColorIfDocumentSelected(documentsDisplay.missingDocuments)
                }
                borderColor={
                  allDocumentsSelected
                    ? borderIfDocumentSelected(false)
                    : borderIfDocumentSelected(documentsDisplay.missingDocuments)
                }
                borderRadius={6}
                display="flex"
                direction="column"
                padding={2}
                gap={1}
              >
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                  <DocumentMissingSvgIcon width={25} height={25} />
                  <Text size="100">{documentsCount.issueCount}</Text>
                </Box>

                <Box>
                  <Text size="100" align="center">
                    {resources.missing}
                  </Text>
                </Box>
              </Box>
            </TapArea>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip text={resources.documentCollection.collectionFilter.showExpiringSoonDocuments}>
            <TapArea
              onTap={() => {
                setDocumentsDisplay((prevState) => {
                  if (allDocumentsSelected) {
                    setFilterTextFieldEnabled(true);
                    return {
                      ...prevState,
                      expiredDocuments: !prevState.expiredDocuments,
                      expiringDocuments: true,
                      missingDocuments: !prevState.expiringDocuments,
                    };
                  } else {
                    const newState = {
                      ...prevState,
                      expiringDocuments: !prevState.expiringDocuments,
                    };
                    return areAllDocumentsDeselected(newState);
                  }
                });
              }}
            >
              <Box
                color={
                  allDocumentsSelected
                    ? backgroundColorIfDocumentSelected(false)
                    : backgroundColorIfDocumentSelected(documentsDisplay.expiringDocuments)
                }
                borderColor={
                  allDocumentsSelected
                    ? borderIfDocumentSelected(false)
                    : borderIfDocumentSelected(documentsDisplay.expiringDocuments)
                }
                borderRadius={6}
                display="flex"
                direction="column"
                padding={2}
                gap={1}
              >
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                  <DocumentToExpireSvgIcon width={25} height={25} />
                  <Text size="100">{documentsCount.toExpireCount}</Text>
                </Box>

                <Box>
                  <Text align="center" size="100">
                    {resources.toExpire}
                  </Text>
                </Box>
              </Box>
            </TapArea>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip text={resources.documentCollection.collectionFilter.showExpiredDocuments}>
            <TapArea
              onTap={() => {
                setDocumentsDisplay((prevState) => {
                  if (allDocumentsSelected) {
                    setFilterTextFieldEnabled(true);
                    return {
                      ...prevState,
                      expiredDocuments: true,
                      expiringDocuments: !prevState.expiringDocuments,
                      missingDocuments: !prevState.expiringDocuments,
                    };
                  } else {
                    const newState = {
                      ...prevState,
                      expiredDocuments: !prevState.expiredDocuments,
                    };
                    return areAllDocumentsDeselected(newState);
                  }
                });
              }}
            >
              <Box
                color={
                  allDocumentsSelected
                    ? backgroundColorIfDocumentSelected(false)
                    : backgroundColorIfDocumentSelected(documentsDisplay.expiredDocuments)
                }
                borderColor={
                  allDocumentsSelected
                    ? borderIfDocumentSelected(false)
                    : borderIfDocumentSelected(documentsDisplay.expiredDocuments)
                }
                borderRadius={6}
                display="flex"
                direction="column"
                padding={2}
                gap={1}
              >
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                  <DocumentExpiredSvgIcon width={25} height={25} />
                  <Text size="100">{documentsCount.expiredCount}</Text>
                </Box>

                <Box>
                  <Text size="100" align="center">
                    {resources.expired}
                  </Text>
                </Box>
              </Box>
            </TapArea>
          </Tooltip>
        </Box>
        <Box display="flex" flex="grow" />
      </Box>
      <Box display="flex" direction={"column"} justifyContent="around" alignItems="center">
        <Box display="flex" alignItems="center">
          <TapArea
            onTap={() => {
              setFilterTextFieldEnabled(!filterTextFieldEnabled);
              if (filterTextFieldEnabled) {
                setFilterValue("");
              }
            }}
          >
            <SearchSvgIcon width={40} />
          </TapArea>
        </Box>

        <WhatsThis
          size="sm"
          colorBundle={colors}
          title={resources.documentCollection.collectionFilter.filterWhatsThis}
          clickContentTitle={" "}
          clickContent={
            <Box>
              <Text align="justify">
                {resources.documentCollection.collectionFilter.filterWhatsThisDetailed}
              </Text>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export const FolderSummaryBadge = ({
  resources,
  documentsCount,
}: {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  documentsCount: {
    documentCount: number;
    issuesCount: number;
    expiredCount: number;
    toExpireCount: number;
  };
}) => {
  const { colors } = useColors();
  return (
    <Box display="flex" gap={4}>
      <Box display="flex" direction="column" alignItems="center">
        <Badge text={`${documentsCount.documentCount}`} color={colors.primary400} />
        <Text size="100">{resources.documents}</Text>
      </Box>
      <Box display="flex" direction="column" alignItems="center">
        <Badge text={`${documentsCount.issuesCount}`} color={colors.error} />
        <Text size="100">{resources.missing}</Text>
      </Box>
      <Box display="flex" direction="column" alignItems="center">
        <Badge text={`${documentsCount.toExpireCount}`} color={colors.other400} />
        <Text size="100">{resources.toExpire}</Text>
      </Box>
      <Box display="flex" direction="column" alignItems="center">
        <Badge text={`${documentsCount.expiredCount}`} color={colors.other500} />
        <Text size="100">{resources.expired}</Text>
      </Box>
    </Box>
  );
};

export const SmallFolderSummaryBadge = ({
  resources,
  documentsCount: { documentCount = 0, issueCount = 0, expiredCount = 0, toExpireCount = 0 },
}: {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  documentsCount: {
    documentCount: number;
    issueCount: number;
    expiredCount: number;
    toExpireCount: number;
  };
}) => {
  const { colors } = useColors();
  return (
    <Box display="flex" gap={4}>
      <Box alignItems="center">
        <Tooltip
          children={<Badge text={`${documentCount}`} color={colors.primary400} />}
          text={resources.document}
        />
      </Box>
      <Box alignItems="center">
        <Tooltip
          children={<Badge text={`${issueCount}`} color={colors.error} />}
          text={resources.missing}
        />
      </Box>
      <Box alignItems="center">
        <Tooltip
          children={<Badge text={`${toExpireCount}`} color={colors.other400} />}
          text={resources.toExpire}
        />
      </Box>
      <Box alignItems="center">
        <Tooltip
          children={<Badge text={`${expiredCount}`} color={colors.other500} />}
          text={resources.expired}
        />
      </Box>
    </Box>
  );
};
