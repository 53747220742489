import { Dispatch, FunctionComponent, useCallback } from "react";

import { Msg } from "./StringTemplateBuilder";
import type { StringTemplatePart } from "@prodoctivity/shared/src/index-types";
import { StringTemplateTextPart } from "./StringTemplateTextPart";
import { StringTemplateVariablePart } from "./StringTemplateVariablePart";

type PartProps = {
  part: StringTemplatePart;
  lineIndex: number;
  partIndex: number;
  dispatch: Dispatch<Msg>;
  hoverPart(lineIndex: number, partIndex: number): void;
  onPartClicked(
    lineIndex: number,
    partIndex: number,
    newPart: StringTemplatePart | undefined
  ): void;
  getFieldLabel(fldName: string): string;
  t(key: string): string;
};
export const StringTemplatePartComponent: FunctionComponent<PartProps> = ({
  part,
  lineIndex,
  partIndex,
  dispatch,
  hoverPart,
  onPartClicked,
  getFieldLabel,
  t,
}) => {
  const remove = useCallback(() => {
    dispatch({
      type: "remove-part",
      lineIndex,
      partIndex,
    });
  }, [dispatch, lineIndex, partIndex]);

  const onTextChange = useCallback(
    (newValue: string) => {
      dispatch({
        type: "edit-text-part",
        lineIndex,
        partIndex,
        newValue,
      });
    },
    [dispatch, lineIndex, partIndex]
  );

  if (part.type === "text") {
    return (
      <StringTemplateTextPart
        part={part}
        lineIndex={lineIndex}
        partIndex={partIndex}
        remove={remove}
        hoverPart={hoverPart}
        onTextChange={onTextChange}
        t={t}
      />
    );
  } else if (part.type === "variable") {
    return (
      <StringTemplateVariablePart
        part={part}
        lineIndex={lineIndex}
        partIndex={partIndex}
        getFieldLabel={getFieldLabel}
        remove={remove}
        hoverPart={hoverPart}
        onClick={onPartClicked}
      />
    );
  } else {
    throw new Error("");
  }
};
