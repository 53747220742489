import { Box, ChevronSvgIcon, TapArea, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { SearchFilter } from "../../link-templates";
import { useDocumentViewerSearchResultsNavigator } from "./hooks";

type DocumentViewerSearchResultsNavigatorProps = {
  searchFilter: SearchFilter;
  index: number;
};

export const DocumentViewerSearchResultsNavigator: FunctionComponent<
  DocumentViewerSearchResultsNavigatorProps
> = ({ searchFilter, index }) => {
  const { colors } = useColors();
  const {
    isPreviousDocumentDisabled,
    isNextDocumentDisabled,
    onPreviousDocumentClick,
    onNextDocumentClick,
  } = useDocumentViewerSearchResultsNavigator(searchFilter, index);

  return (
    <Box display="flex" direction="row" gap={2}>
      <TapArea disabled={isPreviousDocumentDisabled} onTap={onPreviousDocumentClick}>
        <Box paddingY={2}>
          <Box borderStyle="sm" borderRadius={6} hoverColor={colors.primary200}>
            <ChevronSvgIcon
              direction="left"
              height={25}
              width={25}
              color={isPreviousDocumentDisabled ? colors.neutral400 : colors.primary}
            />
          </Box>
        </Box>
      </TapArea>

      <TapArea disabled={isNextDocumentDisabled} onTap={onNextDocumentClick}>
        <Box paddingY={2}>
          <Box borderStyle="sm" borderRadius={6} hoverColor={colors.primary200}>
            <ChevronSvgIcon
              direction="right"
              height={25}
              width={25}
              color={isNextDocumentDisabled ? colors.neutral400 : colors.primary}
            />
          </Box>
        </Box>
      </TapArea>

      <Box margin={2} />
    </Box>
  );
};
