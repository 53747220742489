import { Button, Form, Input, Tooltip } from "antd";

import { Icon, Text } from "@prodoctivity/design-system";
import { Component } from "react";
import { getLayoutTopicsLowercase } from "../AddTopic/utils";
import { noop } from "../_lib/utils";

const moveCaretToEnd = ({ target }: { target: { value: string } }) => {
  const value = target.value;
  target.value = "";
  target.value = value;
};

type Props = {
  onSubmitEditTopic: (params: { prevTopicName: string; nextTopicName: string }) => void;
  layoutTopics: Set<string>;
  editing: boolean;
  children: string;
  i18n: (key: string) => string;
};

export class EditableText extends Component<Props> {
  /*static propTypes = {
    onSubmitEditTopic: func.isRequired,
    /** Should be a `Set<string>` of topic names. * /
    layoutTopics: instanceOf(Set).isRequired,
    editing: bool.isRequired,
    children: node.isRequired,
    i18n: func.isRequired,
  };*/

  /*static getDerivedStateFromProps(
    { layoutTopics: nextlayoutTopics },
    { layoutTopics: prevLayoutTopics }
  ) {
    return updateLayoutTopicsLowercase(prevLayoutTopics, nextlayoutTopics)
  }*/

  state = {
    value: this.props.children,
    topicAlreadyExists: false,
    layoutTopicsLowercase: getLayoutTopicsLowercase(Array.from(this.props.layoutTopics)),
    layoutTopics: null,
  };

  onChange = ({ target: { value } }: { target: { value: string } }) => {
    const { children: prevTopicName } = this.props;
    const { layoutTopicsLowercase } = this.state;
    const nextTopicName = value.trim();

    this.setState({
      value: nextTopicName,
      topicAlreadyExists:
        prevTopicName !== nextTopicName && layoutTopicsLowercase.has(nextTopicName.toLowerCase()),
    });
  };

  onSubmit = () => {
    this.props.onSubmitEditTopic({
      prevTopicName: this.props.children,
      nextTopicName: this.state.value,
    });
  };

  handleKeyDown = ({ key }: { key: string }) => {
    if (key === "Escape") {
      this.props.onSubmitEditTopic({
        prevTopicName: this.props.children,
        nextTopicName: this.props.children,
      });
    }
  };

  render() {
    const { editing, children, i18n } = this.props;
    const { topicAlreadyExists } = this.state;

    return editing ? (
      <Form.Item validateStatus={topicAlreadyExists ? "error" : ""}>
        <Tooltip
          visible={topicAlreadyExists}
          title={topicAlreadyExists ? i18n("TopicDuplicated") : ""}
          placement="bottomRight"
        >
          <Input
            autoFocus
            className={
              "ant-col-xs-2 ant-col-sm-4 ant-col-md-6 ant-col-lg-8 ant-col-xl-10 " +
              "ant-input-search ant-input-search-enter-button"
            }
            defaultValue={children}
            onFocus={moveCaretToEnd}
            onPressEnter={topicAlreadyExists ? noop : this.onSubmit}
            onKeyDown={this.handleKeyDown}
            onChange={this.onChange}
            suffix={
              <Button
                className="ant-input-button"
                key="enterButton"
                type={"primary"}
                danger={topicAlreadyExists ? true : false}
                onClick={topicAlreadyExists ? noop : this.onSubmit}
                disabled={topicAlreadyExists}
              >
                {topicAlreadyExists ? (
                  <Icon icon="close" accessibilityLabel={i18n("close")} />
                ) : (
                  <Icon icon="check" accessibilityLabel={i18n("save")} />
                )}
              </Button>
            }
          />
        </Tooltip>
      </Form.Item>
    ) : (
      <Text weight="bold" size="400">
        {children}
      </Text>
    );
  }
}
