import { generateInputMaskString, range, validateInputMaskEntry } from "@prodoctivity/shared";
import { AbstractEventHandler, TextField as GestaltTextField, TextFieldProps } from "gestalt";
import { FunctionComponent, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";

import InputMask from "react-input-mask";
import { TextField } from "./TextField";

const RawInputMask: any = InputMask;
const RawTextField: any = TextField;

// eslint-disable-next-line @typescript-eslint/ban-types
type InputMaskControlState = {};

type Props = TextFieldProps & {
  mode?: "default" | "unstyled" | "summary";
  maxLength?: { characterCount: number; errorAccessibilityLabel: string };
  inputMask: string;
  inputMaskStoreFixedChars: boolean;
};

export const InputMaskField: FunctionComponent<Props> = ({
  // mode = "default",
  inputMask: rawInputMask,
  inputMaskStoreFixedChars,
  onChange: rawOnChange,
  onBlur: rawOnBlur,
  value: rawValue,
  errorMessage,
  disabled,
  ...props
}) => {
  const inputMask = useMemo(() => {
    return rawInputMask
      .replace(/\\([^sd])/g, function (_, g1) {
        return g1;
      })
      .replace(/\\d(\{([0-9]+)\})/g, function (_, _g1, g2) {
        return range(Number.parseInt(g2))
          .map((_) => "#")
          .join("");
      })
      .replace(/\\s(\{([0-9]+)\})/g, function (_, _g1, g2) {
        return range(Number.parseInt(g2))
          .map((_) => "s")
          .join("");
      });
  }, [rawInputMask]);

  const mask = useMemo(() => {
    return inputMask.split("#").join("9").split("\\d").join("9").split("\\s").join("*");
  }, [inputMask]);

  const onChange = useCallback(
    (args: any) => {
      if (disabled) {
        return;
      }
      const t = generateInputMaskString(inputMask, inputMaskStoreFixedChars, args.value);
      rawOnChange({ event: args.event, value: t });
    },
    [inputMask, inputMaskStoreFixedChars, rawOnChange, disabled]
  );

  const onBlur = useCallback(
    (args: any) => {
      if (disabled) {
        return;
      }
      const t = generateInputMaskString(inputMask, inputMaskStoreFixedChars, args.value);

      if (rawOnBlur) {
        rawOnBlur({ ...args.event, value: t });
      }
    },
    [inputMask, inputMaskStoreFixedChars, rawOnBlur, disabled]
  );

  return (
    <RawInputMask mask={mask} value={rawValue} onChange={onChange} onBlur={onBlur}>
      {(inputProps: { value: string }) => {
        return (
          <RawTextField
            value={inputProps.value}
            id={props.id}
            type="text"
            errorMessage={errorMessage}
          />
        );
      }}
    </RawInputMask>
  );
};

export const InputMaskField2: FunctionComponent<Props> = ({
  mode = "default",
  inputMask,
  inputMaskStoreFixedChars,
  onChange: rawOnChange,
  value: rawValue,
  ...props
}) => {
  const controlRef = useRef<HTMLInputElement | null>(null);

  const controlState = useRef<InputMaskControlState | undefined>();

  const [tempValue, setTempValue] = useState(
    generateInputMaskString(inputMask, inputMaskStoreFixedChars, rawValue || "")
  );

  const [inputIndex, setInputIndex] = useState(0);

  const { displayValue, hasError } = useMemo(() => {
    const isError = !validateInputMaskEntry(inputMask, inputMaskStoreFixedChars, tempValue);
    return {
      hasError: isError,
      displayValue: generateInputMaskString(inputMask, true, tempValue),
    };
  }, [inputMask, inputMaskStoreFixedChars, tempValue]);

  useLayoutEffect(() => {
    if (controlRef.current) {
      controlRef.current.selectionStart = inputIndex;
      controlRef.current.selectionEnd = inputIndex;
    }
  }, [inputIndex]);

  const onChange: Props["onChange"] = useCallback(
    (args) => {
      const { value } = args;

      const newTempValue = generateInputMaskString(
        inputMask,
        inputMaskStoreFixedChars,
        value || ""
      );
      setTempValue(newTempValue);

      if (controlRef.current && controlRef.current.selectionStart !== null) {
        setInputIndex(
          getNearestEditablePosition(
            inputMask,
            controlRef.current.selectionStart,
            value.length > newTempValue.length
          )
        );
      }

      if (validateInputMaskEntry(inputMask, inputMaskStoreFixedChars, value || "")) {
        const val = generateInputMaskString(inputMask, inputMaskStoreFixedChars, value || "");
        rawOnChange({ ...args, value: val });
      }
    },
    [inputMask, inputMaskStoreFixedChars, rawOnChange]
  );

  const onKeyDown: AbstractEventHandler<
    React.KeyboardEvent<HTMLInputElement>,
    { value: string }
  > = useCallback(({ event /*, value */ }) => {
    if (controlRef.current) {
      switch (event.key) {
        case "ArrowLeft": {
          break;
        }
        case "ArrowRight": {
          break;
        }
        default: {
          break;
        }
      }
    }
  }, []);

  const updateRef = useCallback((v: HTMLInputElement) => {
    if (v) {
      controlRef.current = v;
      controlState.current = {};
    }
  }, []);

  if (mode === "unstyled") {
    return (
      <div className={`prodoctivity-unstyled-field`}>
        <GestaltTextField
          ref={updateRef}
          {...props}
          onKeyDown={onKeyDown}
          value={displayValue}
          onChange={onChange}
          hasError={hasError}
        />
      </div>
    );
  }

  return (
    <GestaltTextField
      ref={updateRef}
      {...props}
      onKeyDown={onKeyDown}
      value={displayValue}
      onChange={onChange}
      hasError={hasError}
    />
  );
};

function getNearestEditablePosition(
  inputMask: string,
  selectionStart: number,
  forward: boolean
): number {
  let idx = selectionStart;

  while (forward ? idx < inputMask.length - 1 : idx > 1) {
    if (
      inputMask[idx] === "#" ||
      (inputMask[idx] === "\\" && (inputMask[idx + 1] === "d" || inputMask[idx + 1] === "s"))
    ) {
      return idx;
    } else {
      if (forward) {
        idx += 1;
      } else {
        idx -= 1;
      }
    }
  }

  return idx;
}
