import { Box } from "./Box";
import { ColorConfig } from "../utils";
import { FunctionComponent } from "react";
import { Divider as GestaltDivider } from "gestalt";
import { useColors } from "./ColorSchemeProvider";

type Props = {
  position?: "absolute" | undefined;
  size?: number;
  color?: ColorConfig;
} & (
  | {
      direction?: "vertical";
      height?: number;
    }
  | {
      direction?: "horizontal";
      width?: number;
    }
);

export const Divider: FunctionComponent<Props> = ({ size, position, color, ...props }) => {
  const { colors } = useColors();
  return props.direction === "vertical" ? (
    <Box
      position={position === "absolute" ? "absolute" : undefined}
      top={position === "absolute" ? true : undefined}
      minWidth={size ? size : 1}
      height={props.height || "100%"}
      color={color ? color : colors.neutral600}
    ></Box>
  ) : props.direction === "horizontal" ? (
    <Box
      position={position === "absolute" ? "absolute" : undefined}
      left={position === "absolute" ? true : undefined}
      minHeight={size ? size : 1}
      width={props.width || "100%"}
      color={color ? color : colors.neutral600}
    ></Box>
  ) : (
    <GestaltDivider />
  );
};
