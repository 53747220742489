import { Table as GestaltTable, TableProps } from "gestalt";

export function Table(props: TableProps) {
  return <GestaltTable {...props} />;
}

Table.Body = GestaltTable.Body;

Table.Cell = GestaltTable.Cell;

Table.Footer = GestaltTable.Footer;

Table.Header = GestaltTable.Header;

Table.HeaderCell = GestaltTable.HeaderCell;

Table.Row = GestaltTable.Row;

Table.SortableHeaderCell = GestaltTable.SortableHeaderCell;

Table.RowExpandable = GestaltTable.RowExpandable;
