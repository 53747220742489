import {
  Box,
  ComboBox,
  SearchField,
  Table,
  Text,
  TriStateSwitch,
  useColors,
} from "@prodoctivity/design-system";
import type {
  CollectionPermissionType,
  DocumentPermissionType,
  TemplatePermissionType,
} from "@prodoctivity/shared/src/index-types";

import type { DocumentGroup, OrganizationQueryBehavior } from "@prodoctivity/types";
import { useCallback } from "react";
import { Pagination } from "../../../../components/Layout/Pagination";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";

export type PermissionsTableProps<
  PaginationLength = string,
  Permissions = CollectionPermissionType | DocumentPermissionType | TemplatePermissionType
> = {
  queryBehavior: OrganizationQueryBehavior | undefined;
  /**
   * Array of documentTypes, templates or roles that will assign the permission
   */
  targets: Array<{ id: string; name: string }>;
  targetLabel: string;
  action: "allow" | "deny";
  selectedPermissions: Array<{
    targetId: string;
    action: "allow" | "deny";
    permissionType: Permissions;
  }>;
  permissionsList: Permissions[];
  onPermissionsChange: (
    targetId: string,
    permissionKey: Permissions,
    action: "allow" | "deny" | undefined
  ) => void;
  paginationData: {
    rowsPerPage: PaginationLength;
    currentPage: number;
    pageLength: number;
    isNextButtonDisabled: boolean;
    isPreviousButtonDisabled: boolean;
    pageLengthOptions: Array<PaginationLength>;
    totalRowCount?: number;
    nextPage: () => void;
    previousPage: () => void;
    setPageLength: (pageLength: PaginationLength) => void;
  };
  groupList: DocumentGroup[] | undefined;
  onFilterChanged: ({ value }: { value: string }) => void;
  filterValue: string | undefined;
  groupListFilter: string | undefined;
  onSetGroupListFilter: ((value: string | undefined) => void) | undefined;
};

type PermissionsTableType = <PaginationLength, Permissions>(
  props: PermissionsTableProps<PaginationLength, Permissions>
) => React.ReactElement;

export const PermissionsTable: PermissionsTableType = ({
  queryBehavior,
  groupList,
  groupListFilter,
  onSetGroupListFilter,
  targets,
  targetLabel,
  selectedPermissions,
  paginationData,
  permissionsList,
  onPermissionsChange,
  onFilterChanged,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const buildTableRows = useCallback(() => {
    const targetRows = targets.map((target, i) => {
      const permissionsCells = permissionsList.map((permissionKey, i) => {
        const isCheckedAllow =
          selectedPermissions.findIndex(
            (p) =>
              p.targetId === target.id && p.permissionType === permissionKey && p.action === "allow"
          ) >= 0;

        const isCheckedUndefined =
          selectedPermissions.findIndex(
            (p) => p.targetId === target.id && p.permissionType === permissionKey
          ) < 0;

        return (
          <Table.Cell key={`${target.id}_${i}`}>
            <Box gap={6} display="flex" direction="row" flex="shrink">
              <TriStateSwitch
                value={isCheckedUndefined ? undefined : isCheckedAllow ? true : false}
                onChange={(value) => {
                  onPermissionsChange(
                    target.id,
                    permissionKey,
                    value === undefined ? undefined : value === true ? "allow" : "deny"
                  );
                }}
                onLabel={resources.rolesManagement.allow}
                offLabel={resources.rolesManagement.deny}
                undefinedLabel={resources.inherit}
                undefinedColor={
                  queryBehavior !== undefined
                    ? queryBehavior === "allowed-until-denied"
                      ? colors.primary
                      : colors.error
                    : undefined
                }
              />
            </Box>
          </Table.Cell>
        );
      });

      return (
        <Table.Row key={i}>
          <Table.Cell colSpan={2}>
            <Box display="flex" direction="row" flex="grow">
              <Text title={target.name} overflow="ellipsis">
                {target.name}
              </Text>
            </Box>
          </Table.Cell>
          {permissionsCells}
        </Table.Row>
      );
    });
    return targetRows;
  }, [
    onPermissionsChange,
    permissionsList,
    resources.rolesManagement.allow,
    resources.rolesManagement.deny,
    selectedPermissions,
    targets,
    resources.inherit,
    colors.error,
    colors.primary,
    queryBehavior,
  ]);

  return (
    <Box display="flex" direction="column" flex="grow">
      <Box display="flex" flex="grow" gap={2} paddingY={2}>
        <Box display="flex" flex="grow">
          <SearchField
            accessibilityLabel={resources.search}
            id={targetLabel}
            onChange={onFilterChanged}
          />
        </Box>
        {groupList && onSetGroupListFilter && (
          <Box display="flex" flex="shrink">
            <ComboBox
              id={"document_group_elements_filter"}
              onSelect={({ item }) => {
                onSetGroupListFilter(item.value);
              }}
              onClear={() => {
                onSetGroupListFilter(undefined);
              }}
              placeholder={resources.documentGroup}
              options={groupList.map((group) => ({
                label: group.name,
                value: group.id,
              }))}
              label=""
              selectedOption={groupList
                .map((group) => ({
                  label: group.name,
                  value: group.id,
                }))
                .find((option) => option.value === groupListFilter)}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" flex="grow">
        <Box display="flex" direction="column" flex="grow" />
        <Box display="flex" direction="column" flex="shrink">
          <Pagination<typeof paginationData.rowsPerPage>
            id="permission_table_pagination_top"
            rowsLabel={`${targetLabel}:`}
            currentPage={paginationData.currentPage}
            nextPage={paginationData.nextPage}
            previousPage={paginationData.previousPage}
            pageLength={paginationData.pageLength}
            rowsPerPage={paginationData.rowsPerPage}
            setRowsPerPage={paginationData.setPageLength}
            isNextButtonDisabled={paginationData.isNextButtonDisabled}
            isPreviousButtonDisabled={paginationData.isPreviousButtonDisabled}
            pageLengthOptions={paginationData.pageLengthOptions}
            totalRowCount={paginationData.totalRowCount}
          />
        </Box>
      </Box>
      {queryBehavior !== undefined ? (
        <Box display="flex" direction="row" flex="grow" borderRadius={4} padding={2} gap={1}>
          {resources.organizationDefaultQueryBehavior
            .split("{{behavior}}")
            .reduce((acc: JSX.Element[], next) => {
              if (acc.length) {
                acc.push(
                  <Text
                    weight="bold"
                    key={queryBehavior}
                    color={queryBehavior === "allowed-until-denied" ? colors.primary : colors.error}
                  >
                    {queryBehavior === "allowed-until-denied"
                      ? resources.rolesManagement.allow
                      : resources.rolesManagement.deny}
                  </Text>
                );
              }
              acc.push(<Text key={next}>{next}</Text>);

              return acc;
            }, [])}
        </Box>
      ) : null}
      <Table accessibilityLabel={targetLabel}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2}>
              <Box display="flex" direction="row" flex="grow">
                <Text weight="bold" size="200">
                  {targetLabel.toUpperCase()}
                </Text>
              </Box>
            </Table.HeaderCell>
            {Permissions &&
              permissionsList.map((permissionKey) => {
                return (
                  <Table.HeaderCell key={`${permissionKey}`} rowSpan={1}>
                    <Box display="flex" direction="row" flex="shrink">
                      <Text weight="bold" size="200">
                        {`${permissionKey}`.toLocaleLowerCase().toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                );
              })}
          </Table.Row>
        </Table.Header>
        <Table.Body>{buildTableRows()}</Table.Body>
      </Table>
      <Box display="flex" flex="grow">
        <Box display="flex" direction="column" flex="grow" />
        <Box display="flex" direction="column" flex="shrink">
          <Pagination<typeof paginationData.rowsPerPage>
            id="permission_table_pagination_bottom"
            rowsLabel={`${targetLabel}:`}
            currentPage={paginationData.currentPage}
            nextPage={paginationData.nextPage}
            previousPage={paginationData.previousPage}
            pageLength={paginationData.pageLength}
            rowsPerPage={paginationData.rowsPerPage}
            setRowsPerPage={paginationData.setPageLength}
            isNextButtonDisabled={paginationData.isNextButtonDisabled}
            isPreviousButtonDisabled={paginationData.isPreviousButtonDisabled}
            pageLengthOptions={paginationData.pageLengthOptions}
            totalRowCount={paginationData.totalRowCount}
          />
        </Box>
      </Box>
      <Box padding={4} />
    </Box>
  );
};
