import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ExpandIconSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 24,
  height = 15,
  onClick,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8717 0.661317C15.8717 0.296082 15.5757 0 15.2104 0H0.66144C0.296206 0 0.000123978 0.296082 0.000123978 0.661317V1.98395C0.000123978 2.34919 0.296206 2.64527 0.66144 2.64527H15.2104C15.5757 2.64527 15.8717 2.34919 15.8717 1.98395V0.661317ZM23.8675 7.03927C24.0442 7.14102 24.0442 7.39599 23.8675 7.49774L18.5958 10.5334C18.4194 10.635 18.1992 10.5077 18.1992 10.3042V8.559H0.661318C0.296082 8.559 0 8.26292 0 7.89768V6.80011C0 6.43488 0.296082 6.1388 0.661318 6.1388H18.1992V4.23282C18.1992 4.02932 18.4194 3.90204 18.5958 4.00359L23.8675 7.03927ZM15.2104 11.8921C15.5757 11.8921 15.8717 12.1882 15.8717 12.5534V13.876C15.8717 14.2413 15.5757 14.5374 15.2104 14.5374H0.66144C0.296206 14.5374 0.000123978 14.2413 0.000123978 13.876V12.5534C0.000123978 12.1882 0.296206 11.8921 0.66144 11.8921H15.2104Z"
        fill="#767676"
      />
    </svg>
  );
};
