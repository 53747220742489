type Props = {
  alt: string;
  src: string;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
};
export function Svg({ alt, maxHeight, ...restProps }: Props) {
  return <img alt={alt} style={{ maxHeight }} {...restProps} />;
}
