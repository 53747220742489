import { Box, Text, useColors } from "@prodoctivity/design-system";

import type { TemplateUpdatedLogEntry } from "@prodoctivity/types";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../../link-templates";
import { OrganizationNavLink } from "../../OrganizationLink";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  id: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  entry: TemplateUpdatedLogEntry;
};

export const TemplateUpdatedEntry: FunctionComponent<Props> = ({ entry, fullWidth }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const entryText = entry.name.length >= 20 ? `${entry.name.substring(0, 19)}...` : entry.name;
  if (fullWidth) {
    return (
      <Box display="flex" direction={fullWidth ? "row" : "column"} flex="grow">
        {fullWidth ? (
          <Box marginEnd={6}>
            <PublicProfile username={entry.username} onlyImage={true} sizeImage={48} />
          </Box>
        ) : null}

        <Box display="flex" direction="column">
          <Text inline={true} size="200">
            <Box display="flex" direction="column">
              <PublicProfile username={entry.username} onlyImage={false} size="300" />{" "}
              <OrganizationNavLink
                to={organizationLinkTemplates.templateVersionDetailPage(entry.templateVersionId)}
              >
                <Text
                  inline={true}
                  size="200"
                  title={`${resources.activities.templateUpdated} ${entryText}`}
                  ellipsisLength={45}
                >
                  {resources.activities.templateUpdated}: {entry.name}
                </Text>
              </OrganizationNavLink>
            </Box>
          </Text>
        </Box>
      </Box>
    );
  }
  return (
    <Text size="200">
      {" "}
      {resources.template}{" "}
      <OrganizationNavLink
        to={organizationLinkTemplates.templateVersionDetailPage(entry.templateVersionId)}
      >
        <Text size="200" color={colors.primary} inline title={entry.name} ellipsisLength={40}>
          {entryText}
        </Text>
      </OrganizationNavLink>{" "}
      {resources.activities.updatedBy} <PublicProfile username={entry.username} />
    </Text>
  );
};
