import {
  Box,
  Button,
  ChevronSvgIcon,
  Divider,
  FancyDateTime,
  Flex,
  Layer,
  Modal,
  TapArea,
  Text,
  Tooltip,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import type {
  ApprovalType,
  DocumentRequiredApproval,
  DocumentTypeInfo,
  EcmDocument,
} from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { CircleCheckSvgIcon } from "../../svg/CircleCheckSvgIcon";
import { WarningExclamationIconSvg } from "../../svg/WarningExclamationIconSvg";
import { NotificationMessage } from "../NotificationMessage";
import { PublicProfile } from "../Profile/Public/PublicProfile";
import { useViewerDocumentApprovals } from "./hooks";

type Props = {
  ecmDocument: EcmDocument;
  documentTypeInfo: DocumentTypeInfo;
};

export function ViewerDocumentApprovals({ ecmDocument, documentTypeInfo }: Props) {
  const {
    colors,
    resources,
    moment,
    breakpoint,
    approvalTypes,
    documentApprovals,
    showPending,
    setShowPending,
    showApprovals,
    setShowApprovals,
    showApproveModal,
    setShowApproveModal,
    onApprove,
    allowApprove,
    isLoading,
    isApproving,
    isSuccess,
    mutateApprove,
    toastMessage,
    setToastMessage,
  } = useViewerDocumentApprovals(ecmDocument, documentTypeInfo);

  return (
    <Box paddingY={5} paddingX={2} position="relative">
      <Box rounding={2} maxHeight={600}>
        <Box marginBottom={3}>
          <TapArea onTap={() => setShowPending((prev) => !prev)}>
            <Box display="flex" alignItems="center" justifyContent="between">
              <Text color={colors.primary} weight="bold">
                {resources.required} (
                {(documentApprovals.requiredApprovals || []).filter((a) => !!a.username).length}/
                {(documentApprovals.requiredApprovals || []).length})
              </Text>
              <ChevronSvgIcon direction="right" color={colors.primary} />
            </Box>
          </TapArea>
          <Divider />
          {showPending && (
            <Box
              display="flex"
              direction="column"
              gap={3}
              marginBottom={3}
              paddingX={2}
              paddingY={5}
            >
              {(documentApprovals.requiredApprovals || []).length === 0 && (
                <Text color={colors.subtle} align="center" size="400">
                  {resources.noResultsFound}
                </Text>
              )}
              {(documentApprovals.requiredApprovals || [])?.map((requiredApproval, idx) => {
                const approvalType = approvalTypes.find(
                  (a) => a.id === requiredApproval.approvalTypeId
                );
                if (!approvalType) {
                  <Box key={`${requiredApproval.approvalTypeId}_${idx}`}></Box>;
                }
                return (
                  <PendingApprovalItem
                    key={`${requiredApproval.approvalTypeId}_${idx}`}
                    requiredApproval={requiredApproval}
                    approvalType={approvalType}
                  />
                );
              })}
            </Box>
          )}
        </Box>

        <Box marginBottom={3}>
          <TapArea onTap={() => setShowApprovals((prev) => !prev)}>
            <Box display="flex" alignItems="center" justifyContent="between">
              <Text color={colors.primary} weight="bold">
                {resources.approvalType.approvals} ({(documentApprovals.approvals || []).length})
              </Text>
              <ChevronSvgIcon direction="right" color={colors.primary} />
            </Box>
          </TapArea>
          <Divider />
          {showApprovals && (
            <Box
              display="flex"
              direction="column"
              gap={3}
              marginBottom={3}
              paddingX={2}
              paddingY={5}
            >
              {(documentApprovals.approvals || []).length === 0 && (
                <Text color={colors.subtle} align="center" size="400">
                  {resources.noResultsFound}
                </Text>
              )}

              {(documentApprovals.approvals || [])?.map((approval, idx) => (
                <Box
                  color={colors.white}
                  key={`${approval.username}_${approval.approvedAt}_${idx}`}
                  borderColor={colors.neutral200}
                  borderRadius={6}
                  borderStyle="shadow"
                  minWidth={"360px"}
                  width={"auto"}
                  height={"100px"}
                  paddingY={3}
                  paddingX={5}
                  justifyContent="center"
                  display="flex"
                  direction="column"
                  gap={1}
                >
                  <Box display="flex" gap={3} alignItems="center">
                    <PublicProfile onlyImage={true} sizeImage={48} username={approval.username} />
                    <Box display="flex" flex="grow" direction="column" gap={2}>
                      <PublicProfile
                        weight="bold"
                        size="300"
                        username={approval.username}
                        color={colors.black900}
                      />

                      <Box display="flex" gap={1} alignItems="center">
                        <CircleCheckSvgIcon
                          color={approval.voided ? colors.subtle : colors.success}
                          width={19}
                          height={19}
                        />
                        <Text color={approval.voided ? colors.subtle : colors.success}>
                          {resources.approved}
                        </Text>
                        {approval.voided && (
                          <Text color={colors.subtle} size="200">
                            ({resources.voided})
                          </Text>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" height={"100%"} justifyContent="end">
                      <FancyDateTime
                        moment={moment}
                        resources={resources}
                        size="200"
                        color={colors.subtle}
                        value={approval.approvedAt}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {showApprovals && <Divider />}

        <Tooltip text={allowApprove.reason || ""} doNotShowIf={() => !allowApprove.reason}>
          <Box marginTop={8} width={"100%"}>
            <Button
              onClick={onApprove}
              disabled={!allowApprove.allow || isLoading || isApproving || isSuccess}
              fullWidth={true}
              text={resources.approve}
            ></Button>
          </Box>
        </Tooltip>
      </Box>
      {showApproveModal && (
        <Layer zIndex={modalZIndex}>
          <Modal
            accessibilityModalLabel={resources.save}
            heading={
              <Box>
                <Box marginStart={2} marginBottom={3}>
                  <Text weight="bold" size="400" color={colors.secondary}>
                    {resources.approve}
                  </Text>
                </Box>
                <Divider />
              </Box>
            }
            size="lg"
            padding="none"
            onDismiss={() => setShowApproveModal(false)}
            footer={
              <Box
                display="flex"
                direction={breakpoint === "small" ? "column" : "row"}
                alignItems="center"
                gap={3}
                flex="grow"
                justifyContent="between"
              >
                <Box>
                  <Button
                    color={"transparent"}
                    onClick={() => setShowApproveModal(false)}
                    text={resources.cancel}
                  />
                </Box>
                <Box>
                  <Button color={"gray"} onClick={() => mutateApprove()} text={resources.approve} />
                </Box>
              </Box>
            }
          >
            <Box paddingX={6}>
              <Box marginBottom={6}>
                <Text>{resources.areYouSureApproveDocument}</Text>
              </Box>
              <Divider />
            </Box>
          </Modal>
        </Layer>
      )}
      {toastMessage && (
        <NotificationMessage
          type={toastMessage.type}
          message={toastMessage.message}
          position="bottom-left"
          delay={3000}
          handleDismiss={() => setToastMessage(undefined)}
        />
      )}
    </Box>
  );
}

type ApprovalItemProps = {
  requiredApproval: DocumentRequiredApproval<string>;
  approvalType:
    | ({
        id: string;
      } & ApprovalType<string>)
    | undefined;
};
const PendingApprovalItem = ({ requiredApproval, approvalType }: ApprovalItemProps) => {
  const { colors } = useColors();

  const { resources, moment } = useAppTranslation();

  return (
    <Box
      color={colors.white}
      borderColor={colors.neutral200}
      borderRadius={6}
      borderStyle="shadow"
      justifyContent="center"
      display="flex"
      minWidth={"360px"}
      width={"auto"}
      height={"100px"}
      direction="column"
      paddingY={3}
      paddingX={5}
    >
      <Box display="flex" gap={3} alignItems="center" width={"auto"}>
        {requiredApproval.username ? (
          <PublicProfile sizeImage={48} onlyImage={true} username={requiredApproval.username} />
        ) : (
          <WarningExclamationIconSvg />
        )}
        <Box direction="column" display="flex" flex="grow" width={"auto"}>
          <Text weight="bold" color={colors.primary}>
            {approvalType?.label}
          </Text>
          {requiredApproval.username ? (
            <>
              <PublicProfile
                size="300"
                weight="bold"
                username={requiredApproval.username}
                color={colors.black900}
              />
              <Flex gap={1} alignItems="center">
                <CircleCheckSvgIcon width={19} height={19} />
                <Text color={colors.success}>{resources.approved}</Text>
              </Flex>
            </>
          ) : (
            <Text color={colors.secondary}>{resources.pending}</Text>
          )}
        </Box>
        {requiredApproval.approvedAt && (
          <Box display="flex" height={"100%"} justifyContent="end">
            <FancyDateTime
              moment={moment}
              resources={resources}
              size="200"
              color={colors.subtle}
              value={requiredApproval.approvedAt}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
