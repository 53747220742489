import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ListSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 22,
  height = 29,
  onClick,
  color,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 22 29`}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_9370_117785)">
        <path
          d="M17.84 15.5202C17.87 14.9902 17.63 14.6302 17.14 14.5202C16.94 14.4702 16.72 14.4502 16.51 14.4502C15.61 14.4502 14.71 14.4502 13.81 14.4502C12.81 14.4502 11.82 14.4502 10.82 14.4502C10.42 14.4502 10.12 14.6802 9.97002 15.0302C9.83002 15.3602 9.85002 15.7002 10.1 16.0002C10.3 16.2402 10.56 16.3302 10.86 16.3402C12.46 16.3602 14.06 16.3802 15.66 16.3902C16.12 16.3902 16.58 16.3602 17.03 16.3202C17.48 16.2802 17.8 15.9402 17.83 15.5202H17.84Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M17.7199 20.53C17.7499 20 17.5099 19.64 17.0199 19.53C16.8199 19.48 16.5999 19.46 16.3899 19.46C15.4899 19.46 14.5899 19.46 13.6899 19.46C12.6899 19.46 11.6999 19.46 10.6999 19.46C10.2999 19.46 9.99991 19.69 9.84991 20.04C9.70991 20.37 9.72991 20.71 9.97991 21.01C10.1799 21.25 10.4399 21.34 10.7399 21.35C12.3399 21.37 13.9399 21.39 15.5399 21.4C15.9999 21.4 16.4599 21.37 16.9099 21.33C17.3599 21.29 17.6799 20.95 17.7099 20.53H17.7199Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M3.93996 20.9696C4.29996 21.3896 4.66996 21.7996 5.07996 22.1696C5.46996 22.5196 5.94996 22.5296 6.32996 22.1696C7.07996 21.4596 7.79996 20.7296 8.50996 19.9796C8.79996 19.6696 8.73996 19.1296 8.44996 18.8196C8.12996 18.4896 7.55996 18.3896 7.22996 18.6496C6.85996 18.9496 6.50996 19.2796 6.16996 19.6096C5.75996 19.9996 5.75996 20.0096 5.28996 19.6896C4.95996 19.4696 4.61996 19.3696 4.24996 19.6096C3.94996 19.7996 3.75996 20.0496 3.70996 20.4396C3.77996 20.6196 3.81996 20.8296 3.93996 20.9696Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M7.15023 13.69C6.83023 13.96 6.52023 14.25 6.22023 14.54C5.76023 14.98 5.75023 14.99 5.25023 14.63C4.94023 14.41 4.59023 14.41 4.30023 14.56C3.99023 14.72 3.75023 15.01 3.74023 15.43C3.80023 15.58 3.82023 15.75 3.92023 15.86C4.34023 16.31 4.75023 16.77 5.22023 17.17C5.56023 17.46 5.99023 17.45 6.32023 17.14C7.07023 16.43 7.80023 15.7 8.50023 14.95C8.84023 14.58 8.77023 14.05 8.39023 13.72C7.93023 13.33 7.56023 13.33 7.16023 13.67L7.15023 13.69Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M19.17 3.54004H12.25V5.54004H19.17C19.39 5.54004 19.56 5.71004 19.56 5.92004V25.87C19.56 26.08 19.39 26.26 19.17 26.26H2.39C2.17 26.26 2 26.08 2 25.87V5.92004C2 5.71004 2.17 5.54004 2.39 5.54004H3.72V3.54004H2.39C1.07 3.54004 0 4.61004 0 5.92004V25.87C0 27.19 1.07 28.26 2.39 28.26H19.17C20.49 28.26 21.56 27.19 21.56 25.87V5.92004C21.56 4.61004 20.49 3.54004 19.17 3.54004Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M5.39014 9.0702V10.0602C5.39014 10.0602 5.39014 10.1102 5.40014 10.1402C5.39014 10.0002 5.39014 9.6102 5.39014 9.0702ZM12.0301 10.1802C12.0301 10.1802 12.0301 10.0902 12.0301 10.0502V8.9502C12.0301 9.6802 12.0301 10.1702 12.0301 10.1802Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M5.39014 9.0702V10.0602C5.39014 10.0602 5.39014 10.1102 5.40014 10.1402C5.39014 10.0002 5.39014 9.6102 5.39014 9.0702ZM12.0301 10.1802C12.0301 10.1802 12.0301 10.0902 12.0301 10.0502V8.9502C12.0301 9.6802 12.0301 10.1702 12.0301 10.1802Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M5.92014 2C5.92014 2 10.9801 2 11.5101 2.01C11.6701 2.01 11.8301 2.03 11.9301 2.16C12.0101 2.25 12.0401 2.34 12.0401 2.44V8.95C12.0401 9.68 12.0401 10.17 12.0401 10.18C12.0401 10.19 12.0301 10.19 12.0201 10.2C12.0101 10.19 11.9001 10.1 11.7401 9.96C11.7101 9.92 11.6701 9.9 11.6401 9.87C11.3701 9.63 10.9901 9.29 10.6101 8.97C10.5801 8.93 10.5501 8.9 10.5201 8.88C10.4801 8.84 10.4301 8.8 10.3901 8.77C9.96014 8.38 9.56014 8.03 9.43014 7.91C9.24014 7.74 8.99014 7.65 8.73014 7.65C8.47014 7.65 8.23014 7.74 8.03014 7.91C7.25014 8.59 5.55014 10.1 5.47014 10.17C5.46014 10.18 5.44014 10.18 5.41014 10.2V10.14C5.40014 10 5.40014 9.61 5.40014 9.07V2.46C5.40014 2.17 5.60014 2 5.93014 2M5.92014 0C4.48014 0 3.39014 1.06 3.39014 2.46V9.07C3.39014 9.69 3.39014 9.99 3.40014 10.2C3.40014 10.94 3.81014 11.62 4.46014 11.97C4.76014 12.13 5.08014 12.21 5.40014 12.21C5.70014 12.21 6.01014 12.14 6.29014 12C6.48014 11.91 6.65014 11.8 6.80014 11.66L7.05014 11.44C7.42014 11.11 8.12014 10.49 8.71014 9.97C8.81014 10.06 8.92014 10.16 9.03014 10.26C9.07014 10.29 9.10014 10.32 9.14014 10.35C9.19014 10.4 9.25014 10.46 9.30014 10.5C9.63014 10.78 9.96014 11.07 10.2101 11.3L10.2701 11.35C10.2701 11.35 10.3101 11.39 10.3401 11.41C10.3601 11.43 10.3801 11.45 10.4001 11.47C10.5101 11.57 10.6101 11.65 10.6601 11.69C10.6301 11.66 10.6001 11.63 10.5801 11.62C10.9701 12.01 11.4801 12.21 11.9901 12.21C12.4301 12.21 12.8701 12.07 13.2301 11.78C13.7101 11.4 14.0101 10.81 14.0101 10.19C14.0101 10.19 14.0101 9.7 14.0101 8.99V2.44C14.0101 1.86 13.8101 1.33 13.4401 0.88C13.1001 0.47 12.5001 0.01 11.4801 0.01C10.9801 0.01 6.41014 0 5.89014 0L5.92014 0Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_9370_117785">
          <rect width="21.56" height="28.26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
