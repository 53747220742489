import { Box, Button, Grid, Skeleton, TabsProps, Text, Tooltip } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo } from "react";

import { noop } from "@prodoctivity/prodoctivity-form-v5/src/components/_lib/utils";
import { useParams } from "react-router-dom";
import { BreadCrumbEntry } from "../../../../components/BreadCrumb";
import { Page } from "../../../../components/Layout/Page";
import { NotificationMessage } from "../../../../components/NotificationMessage";
import { PublicProfile } from "../../../../components/Profile/Public/PublicProfile";
import { organizationLinkTemplates } from "../../../../link-templates";
import { useEditOrganizationUser } from "../hooks";
import { EditOrganizationUserTabs } from "./EditOrganizationUserTabs";
import { RolesTab } from "./RolesTab";
import { UserTab } from "./UserTab";

type EditOrganizationUserProps = {
  isUpdating?: boolean;
};
const EditOrganizationUserPage: FunctionComponent<EditOrganizationUserProps> = () => {
  const { username } = useParams();
  const {
    handlePropertyChange,
    handleSaveClicked,
    organizationNavigate,
    specificScheduleSwitch,
    setSpecificScheduleSwitch,
    currentOrganizationUser,
    showSuccessToast,
    showErrorToast,
    currentTab,
    toastMessage,
    resources,
    validationMessage,
    isSaveButtonDisabled,
    availableOrganizationRoles,
    isLoading,
    isError,
  } = useEditOrganizationUser(username);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    const result: BreadCrumbEntry[] = [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      { type: "url", name: resources.users, url: organizationLinkTemplates.manageUsers() },
    ];
    if (username) {
      result.push({
        type: "content",
        content: (
          <Box display="flex" direction="row">
            <PublicProfile username={username} />
          </Box>
        ),
      });
    }
    return result;
  }, [username, resources.users, resources.home, resources.settings]);

  const tabs = useMemo(() => {
    if (!currentOrganizationUser) {
      return [];
    }
    return [
      {
        title: resources.userManagementAndRoles.userData,
        key: "user",
        content: (
          <UserTab
            user={currentOrganizationUser}
            onChange={handlePropertyChange}
            specificScheduleSwitch={specificScheduleSwitch}
            setSpecificScheduleSwitch={setSpecificScheduleSwitch}
          />
        ),
      },
      {
        title: resources.rolesManagement.roles,
        key: "roles",
        content: (
          <RolesTab
            user={currentOrganizationUser}
            onChange={handlePropertyChange}
            rolesAvailable={availableOrganizationRoles}
          />
        ),
      },
    ];
  }, [
    availableOrganizationRoles,
    currentOrganizationUser,
    handlePropertyChange,
    resources.rolesManagement.roles,
    resources.userManagementAndRoles.userData,
    setSpecificScheduleSwitch,
    specificScheduleSwitch,
  ]);

  const onTabChange: TabsProps["onChange"] = useCallback(
    (args) => {
      args.event.preventDefault();
      if (args.dangerouslyDisableOnNavigation) {
        args.dangerouslyDisableOnNavigation();
      }
      const tab = tabs[args.activeTabIndex];
      if (tab && username) {
        organizationNavigate(organizationLinkTemplates.editUser(username, tab.key));
      }
    },
    [organizationNavigate, tabs, username]
  );

  if (isLoading || !currentOrganizationUser) {
    return (
      <Box display="flex" padding={4}>
        <Box>
          <Skeleton show={true} />
        </Box>
      </Box>
    );
  }

  if (isError) {
    return <Box>{resources.errorOccurred}</Box>;
  }

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <Grid gridTemplateColumns={["1fr"]}>
          <Box paddingX={5} marginBottom={4} marginTop={4}>
            <Text weight="bold" size="400">
              {resources.users}
            </Text>
          </Box>
        </Grid>
        <Box paddingX={5}>
          <EditOrganizationUserTabs selectedTab={currentTab} tabs={tabs} onChange={onTabChange} />
          <Box display="flex" justifyContent="end" paddingY={4} gap={4}>
            <Button
              color={"transparent"}
              onClick={() => {
                organizationNavigate("/settings/users");
              }}
              text={resources.cancel}
            />

            <Tooltip text={validationMessage} doNotShowIf={() => !validationMessage}>
              <Button
                color={"blue"}
                onClick={handleSaveClicked}
                disabled={isSaveButtonDisabled}
                text={resources.saveChanges}
              />
            </Tooltip>
          </Box>
        </Box>
        {showSuccessToast && (
          <NotificationMessage
            type="success"
            message={toastMessage}
            position="bottom-left"
            handleDismiss={noop}
          />
        )}
        {showErrorToast && (
          <NotificationMessage
            type="error"
            message={toastMessage}
            position="bottom-left"
            handleDismiss={noop}
          />
        )}
      </Box>
    </Page>
  );
};

export default EditOrganizationUserPage;
