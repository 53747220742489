import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const IconsEssentialsTableSort: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1163_110684)">
        <path
          d="M16.3961 19.0872H23.9432C24.0979 19.0862 24.2489 19.0397 24.3774 18.9535C24.5058 18.8673 24.6061 18.7452 24.6655 18.6023C24.725 18.4595 24.7411 18.3023 24.7118 18.1504C24.6825 17.9985 24.6091 17.8586 24.5008 17.7482L20.7272 13.8455C20.6541 13.7721 20.5672 13.7139 20.4715 13.6742C20.3758 13.6345 20.2732 13.6141 20.1697 13.6143C20.0664 13.614 19.9642 13.6344 19.8689 13.6741C19.7736 13.7138 19.6871 13.7721 19.6146 13.8455L15.8386 17.7433C15.7302 17.8537 15.6568 17.9937 15.6275 18.1456C15.5982 18.2975 15.6143 18.4546 15.6738 18.5975C15.7333 18.7403 15.8335 18.8624 15.9619 18.9487C16.0904 19.0349 16.2414 19.0814 16.3961 19.0823V19.0872ZM23.9432 20.9131H16.3961C16.2414 20.914 16.0904 20.9605 15.9619 21.0468C15.8335 21.133 15.7333 21.2551 15.6738 21.3979C15.6143 21.5408 15.5982 21.6979 15.6275 21.8498C15.6568 22.0017 15.7302 22.1417 15.8386 22.2521L19.6243 26.1608C19.6944 26.2354 19.7794 26.2944 19.8737 26.3341C19.9681 26.3737 20.0698 26.3931 20.1721 26.3909C20.2757 26.391 20.3782 26.3706 20.4739 26.3309C20.5696 26.2912 20.6565 26.233 20.7296 26.1596L24.5032 22.257C24.6131 22.1468 24.6879 22.0066 24.7182 21.854C24.7485 21.7014 24.7329 21.5432 24.6734 21.3995C24.6139 21.2557 24.5131 21.1328 24.3838 21.0462C24.2545 20.9597 24.1025 20.9133 23.9469 20.9131H23.9432Z"
          fill={calculateColor(color || colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1163_110684">
          <rect width="10.3457" height="14" fill="white" transform="translate(15 13)" />
        </clipPath>
      </defs>
    </svg>
  );
};
