import type {
  AlphanumericListContextField,
  ContextField,
  ContextRecord,
  DataType,
  DataTypeAndValue,
  InputControlValueType,
  ParametersObject,
  TemplateContextDefinition,
} from "@prodoctivity/shared/src/index-types";
import type {
  DataElementValue,
  DateMinMaxType,
  DictionaryList,
  DictionaryListElement,
  FormValues,
  GroupValues,
  ValidationErrors,
} from "./types";

import { shouldNever } from "@prodoctivity/shared";
import type momentType from "moment";
import { IFormValues } from "../Wizard/_types";
import { DATETIME_FORMAT } from "./date-formats";

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

export const delay = (d: number) => new Promise((r) => setTimeout(r, d));

export const regexToMaskInput = (regex: string) => {
  if (!regex) {
    return undefined;
  }
  return new RegExp(regex).source
    .replace(/^\^|\$$/g, "")
    .replace(/\\d/g, "#")
    .replace(/\(([^)]*)\)|\[([^^])\]|\\([\\/.(){}[\]])/g, "$1$2$3")
    .replace(/([\w#.-])\{(\d+)\}/gi, function (_, c, n) {
      return Array(+n + 1).join(c);
    });
};

export function getInitialValue(
  moment: typeof momentType,
  dataType: DataType,
  initialValue: DataElementValue | null,
  isMultiInstance: boolean
): DataElementValue | null {
  // Intentional double equals (checks both null and undefined)
  if (initialValue == null) {
    return initialValue;
  }

  switch (dataType) {
    case "DateTime":
    case "Date":
    case "Time":
      // flowlint-next-line unclear-type:off
      return getDateValueAsMomentInstance(moment, initialValue, isMultiInstance);
    case "Logical":
      return parseToBoolean(initialValue);
    default:
      if (isMultiInstance && !Array.isArray(initialValue)) {
        if (!initialValue) {
          return [];
        }
        // flowlint-next-line unclear-type:off
        return [initialValue as any];
      }
      return initialValue;
  }
}

export function getDateValueAsMomentInstance(
  moment: typeof momentType,
  value: momentType.Moment | string | string[] | Date | any,
  isMultiInstance: boolean
): momentType.Moment | momentType.Moment[] | null {
  if (typeof value === "string") {
    if (!value) {
      return isMultiInstance ? [] : null;
    }

    const processed = moment.utc(value, DATETIME_FORMAT).local();
    return isMultiInstance ? [processed] : processed;
  } else if (Array.isArray(value)) {
    if (value[0] != null && typeof value[0] === "string") {
      return value.map((v) => moment.utc(v, DATETIME_FORMAT).local());
    } else if (value[0] != null && moment.isMoment(value[0])) {
      // flowlint-next-line unclear-type:off
      //return value
      return value.map((v) => moment.utc(v, DATETIME_FORMAT).local());
    } else {
      return [];
    }
  } else if (value instanceof Date) {
    const processed = moment.utc(value).local();
    return isMultiInstance ? [processed] : processed;
  } else if (moment.isMoment(value)) {
    return isMultiInstance ? [value] : value;
  } else if (typeof value === "number") {
    return isMultiInstance ? [moment(value)] : moment(value);
  }

  throw new Error(`Date value '${value}' should be of type 'string', 'Date' or 'Moment'`);
}

export function flattenDeep<T>(arr: Array<any>): Array<T> {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(Array.isArray(toFlatten) ? flattenDeep(toFlatten) : toFlatten);
  }, []);
}

export function makeCancelable<T>(promise: Promise<T>): {
  promise: Promise<T>;
  cancel: () => void;
} {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

export function exhaustiveCheck(value: string, message?: string) {
  throw new Error(message || `Unhandled value: ${value}`);
}

export function range(start: number, end: number): number[] {
  return [...Array(end - start + 1)].map((_, i) => start + i);
}

export function getDisabledDate(
  moment: typeof momentType,
  dateMinMaxType: DateMinMaxType,
  minValue: string,
  maxValue: string
): (current: momentType.Moment | null) => boolean {
  return (current: momentType.Moment | null): boolean => {
    if (dateMinMaxType === "Custom" || dateMinMaxType == null) {
      return !Boolean(
        current &&
          minValue &&
          maxValue &&
          // $FlowIgnore Moment instances should be comparable
          (current < moment(minValue) || current > moment(maxValue))
      );
    } else if (dateMinMaxType === "MinIsCurrentDate") {
      return !(
        current != null &&
        current &&
        // $FlowIgnore Moment instances should be comparable
        current < moment().subtract(1, "days").endOf("day")
      );
    } else if (dateMinMaxType === "MaxIsCurrentDate") {
      // $FlowIgnore Moment instances should be comparable
      return !(current != null && current && current > moment().endOf("day"));
    } else {
      exhaustiveCheck(dateMinMaxType);
      return false;
    }
  };
}

export function getDisabledHours(
  moment: typeof momentType,
  dateMinMaxType: DateMinMaxType,
  minValue: string | undefined,
  maxValue: string | undefined
): () => number[] {
  return () => {
    if (dateMinMaxType === "Custom" || dateMinMaxType == null) {
      const lowerRange =
        minValue == null
          ? []
          : range(0, minValue != null ? moment(minValue).utc().local().hour() : 0);
      const upperRange =
        maxValue == null
          ? []
          : range(maxValue != null ? moment(maxValue).utc().local().hour() + 1 : 24, 24);

      return [...lowerRange, ...upperRange];
    } else if (dateMinMaxType === "MinIsCurrentDate") {
      return range(0, moment().hour() - 1);
    } else if (dateMinMaxType === "MaxIsCurrentDate") {
      return range(moment().hour() + 1, 24);
    } else {
      exhaustiveCheck(dateMinMaxType);
      return [];
    }
  };
}

export function getDisabledMinutes(
  moment: typeof momentType,
  dateMinMaxType: DateMinMaxType
): (selectedHour: number) => number[] {
  return (_selectedHour: number) => {
    if (dateMinMaxType === "Custom" || dateMinMaxType == null) {
      return [];
    } else if (dateMinMaxType === "MinIsCurrentDate") {
      return range(0, moment().minutes() - 1);
    } else if (dateMinMaxType === "MaxIsCurrentDate") {
      return range(moment().minutes() + 1, 60);
    } else {
      exhaustiveCheck(dateMinMaxType);
      return [];
    }
  };
}

export function getErrors({
  name,
  label,
  required,
  minInstances,
  value,
  disabled,
  element,
  checkInputMask,
}: {
  name: string;
  label: string;
  required: boolean;
  minInstances?: number;
  value: any;
  disabled: boolean;
  element?: HTMLElement | any | null;
  checkInputMask?: any;
}): ValidationErrors {
  if (disabled === true) return [];
  const errors: ValidationErrors = [];

  if (
    required &&
    (value == null ||
      value === "" ||
      value.length === 0 ||
      (value._isAMomentObject && !value.isValid()))
  ) {
    errors.push({
      key: "requiredField",
      dataElementName: name,
      text: `The field '${label}' is required.`,
      parameters: { field: label },
    });
  }

  if (
    minInstances != null &&
    required &&
    (Array.isArray(value) ? value.filter((val) => val) : value ? [value] : []).length < minInstances
  ) {
    errors.push({
      key: "minimumInstancesNotMet",
      dataElementName: name,
      text: `Minimum required instances not met: ${minInstances}`,
      parameters: { minInstances: minInstances },
    });
  }

  if (checkInputMask && element && !disabled && element.inputmask && !element.inputmask.isValid()) {
    errors.push({
      key: "invalidValidationField",
      dataElementName: name,
      text: `The field '${label}' is invalid.`,
      parameters: { field: label },
    });
  }

  return errors;
}

export function parseToBoolean(anyValue?: any): boolean {
  if (!anyValue || anyValue === 0) return false;

  if (typeof anyValue === "boolean") return anyValue;

  if (anyValue === 1 || (typeof anyValue === "string" && anyValue.toLowerCase() === "true")) {
    return true;
  }

  if (Array.isArray(anyValue) && anyValue.length > 0) {
    return parseToBoolean(anyValue[0]);
  }

  return false;
}

export function filterListElementsByParentValue(
  formValues: FormValues,
  dictionaryList: DictionaryList
): Array<DictionaryListElement> {
  let elements: Array<DictionaryListElement> = [];

  if (!dictionaryList) {
    return [];
  }

  if (!dictionaryList.parentListSource) {
    return dictionaryList.elements;
  }

  if (formValues[dictionaryList.parentListSource]) {
    elements = dictionaryList.elements.filter(
      (el) =>
        dictionaryList.parentListSource &&
        el.parentValue === formValues[dictionaryList.parentListSource]
    );
  }

  return elements;
}

export function concatArrayToStr(values: Array<any> | string) {
  let valueStr = "";

  if (Array.isArray(values)) {
    values.forEach((value) => {
      valueStr += (valueStr === "" || !value ? "" : ", ") + value;
    });
  } else {
    valueStr = values;
  }
  return valueStr;
}

export function without(arr: Array<any>, values: Array<any>) {
  return arr.filter((v) => !values.includes(v));
}

function getFormValues(fields: ContextField[], data: ParametersObject): FormValues {
  const result: FormValues = {};

  fields.forEach((field) => {
    const value = data[field.name];
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        const arr: Array<string | number> = [];
        result[field.name] = arr;
        value.forEach((v) => {
          if (typeof v !== "object") {
            arr.push(v);
          }
        });
      } else {
        result[field.name] = value;
      }
    }
  });

  return result;
}

function getGroupValues(records: ContextRecord[], data: ParametersObject): GroupValues {
  const result: GroupValues = {};

  records.forEach((record) => {
    const arr = data[record.name];
    if (Array.isArray(arr)) {
      const list: unknown[] = arr;
      const rows: ParametersObject[] = list.reduce((acc: ParametersObject[], next: unknown) => {
        if (typeof next === "object" && next !== null) {
          acc.push(next as ParametersObject);
        }
        return acc;
      }, []);
      rows.forEach((row) => {
        const formValues = getFormValues(record.fields, row);
        const list = result[record.name];
        if (!list || !Array.isArray(list)) {
          const arr: ParametersObject[] = [];
          result[record.name] = arr;
          arr.push(formValues);
        } else {
          list.push(formValues);
        }
      });
    }
  });

  return result;
}

export function convertParametersObjectToIFormValues(
  contextDefinition: TemplateContextDefinition,
  data: ParametersObject
): IFormValues {
  const formValues = getFormValues(contextDefinition.fields, data);
  const groupValues = getGroupValues(contextDefinition.records, data);
  const result: IFormValues = {
    pinnedElements: [],
    formValues,
    groupValues,
  };

  return result;
}

export function getInputDictionaryListFromDataElement(
  name: string,
  dataElement: AlphanumericListContextField
): DictionaryList {
  const result: DictionaryList = {
    description: dataElement.description,
    name: name,
    parentListSource: dataElement.parentListSource,
    elements: dataElement.valueList.map((item) => {
      return {
        code: item.value,
        name: item.label || item.value,
        parentValue: item.parentValue,
      };
    }),
  };

  return result;
}

export function valueTypeToFormValue(
  inputControl: InputControlValueType,
  moment: typeof momentType
): DataTypeAndValue {
  switch (inputControl.dataType) {
    case "Alphanumeric":
      return { value: inputControl.value || "", type: "Alphanumeric" };
    case "Numeric":
    case "Currency":
      return { value: inputControl.value || 0, type: inputControl.dataType };
    case "Logical":
      return { value: !!inputControl.value, type: "Logical" };
    case "Date":
    case "DateTime":
    case "Time":
      let val: number | number[] = 0;
      if (Array.isArray(inputControl.value)) {
        val = inputControl.value.reduce((arr: number[], dateVal: string | number) => {
          if (typeof dateVal === "string") {
            arr.push(moment(dateVal).toDate().getTime());
          } else {
            arr.push(dateVal);
          }
          return arr;
        }, []);
      } else if (typeof inputControl.value === "string") {
        val = moment(inputControl.value).toDate().getTime();
      } else {
        val = inputControl.value || 0;
      }
      return { value: val, type: inputControl.dataType };
    case "Image":
      const value = inputControl.value || "";
      return {
        value: Array.isArray(value) ? value.map((v) => Number.parseInt(v)) : value,
        type: "Image",
      };
    default:
      shouldNever(inputControl);
      return { type: "Alphanumeric", value: "" };
  }
}
