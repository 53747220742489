import { Box, Callout, Text, useColors } from "@prodoctivity/design-system";

import type { EcmDocument } from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";
import { useGetDocumentsByGenerationToken } from "../../pages/StandAloneViewer/hooks";

import { OrganizationLink } from "../OrganizationLink";

import { useOrganizationLink } from "../../hooks/useOrganizationNavigate";

type Props = {
  document: EcmDocument;
};

export function ViewerDocumentGenerationToken({ document }: Props) {
  const { colors } = useColors();
  const { data: documentsByGenerationTokenList } = useGetDocumentsByGenerationToken(
    document?.generationToken || ""
  );
  const documentsByGenerationToken = documentsByGenerationTokenList
    ? documentsByGenerationTokenList.documents
    : [];
  const { resources } = useAppTranslation();
  const token = document.generationToken || "";

  const organizationLink = useOrganizationLink();

  return (
    <Box padding={5} position="relative">
      <Box
        width={"100%"}
        display="flex"
        justifyContent="end"
        position="absolute"
        top={true}
        right={true}
        marginBottom={12}
      ></Box>
      <Box>
        <Callout
          type="info"
          iconAccessibilityLabel={resources.documentViewerWrapper.viewGeneration}
          message={resources.generationInformation}
          primaryAction={{
            accessibilityLabel: resources.documentViewerWrapper.viewGeneration,
            role: "link",
            label: resources.documentViewerWrapper.viewGeneration,

            href: organizationLink(organizationLinkTemplates.documentGenerationStatus(token)),
          }}
        />
      </Box>

      {documentsByGenerationToken.length > 1 && (
        <Box
          rounding={2}
          color={colors.neutral400}
          borderStyle="sm"
          overflow="scrollY"
          maxHeight={600}
        >
          {documentsByGenerationToken.map((generation, index) => (
            <Box
              marginBottom={index === documentsByGenerationToken.length - 1 ? 0 : 4}
              key={generation.generationToken}
              padding={5}
              color={
                document?.documentId === generation.documentId ? colors.neutral300 : colors.white
              }
              hoverColor={colors.neutral600}
            >
              <Box display="flex" direction="column" flex="grow">
                <Box>
                  <OrganizationLink
                    to={organizationLinkTemplates.documentId(generation.documentId, "generations")}
                  >
                    <Text size="300" weight="bold">
                      {generation.documentTypeName}
                    </Text>
                    <Text size="200">{generation.name}</Text>
                  </OrganizationLink>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
