import { CSSProperties, FunctionComponent, PropsWithChildren } from "react";
import { BREAKPOINTS, useDesignBreakpoint } from "../breakpoint";

import { UnsignedUpTo12 } from "gestalt";

export type { UnsignedUpTo12 };

export function getGridColumnConfig(
  columnConfig: Record<keyof typeof BREAKPOINTS, Array<string | number>>
) {
  return columnConfig;
}

function calculateColumns(
  columnConfig: Record<keyof typeof BREAKPOINTS, Array<string | number>>,
  breakpoint: keyof typeof BREAKPOINTS
) {
  return columnConfig[breakpoint] || [1];
}

type Props = {
  gridTemplateColumns:
    | Array<string | number>
    | Record<keyof typeof BREAKPOINTS, Array<string | number>>;
  gridTemplateRows?:
    | Array<string | number>
    | Record<keyof typeof BREAKPOINTS, Array<string | number>>;
  gap?: number;
  height?: string;
  width?: string | number;
  minHeight?: string;
  padding?: string;
  justifyContent?: CSSProperties["justifyContent"];
};

const Grid: FunctionComponent<PropsWithChildren<Props>> = ({
  gridTemplateColumns,
  gridTemplateRows,
  height,
  width,
  minHeight,
  children,
  padding,
  gap,
  justifyContent,
}) => {
  const { breakpoint } = useDesignBreakpoint();

  const cols = Array.isArray(gridTemplateColumns)
    ? gridTemplateColumns
    : calculateColumns(gridTemplateColumns, breakpoint);

  const rows = gridTemplateRows
    ? Array.isArray(gridTemplateRows)
      ? gridTemplateRows
      : calculateColumns(gridTemplateRows, breakpoint)
    : undefined;
  return (
    <div
      style={{
        padding,
        height,
        width,
        justifyContent,
        minHeight,
        display: "grid",
        gridTemplateColumns: gridTemplateColumns
          ? cols.map((tc) => (typeof tc === "number" ? `${tc}fr` : tc)).join(" ")
          : "none",
        gridTemplateRows: rows
          ? rows.map((tr) => (typeof tr === "number" ? `${tr}fr` : tr)).join(" ")
          : undefined,
        gap: (gap || 0) * 4,
      }}
    >
      {children}
    </div>
  );
};

export { Grid };
