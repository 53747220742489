import {
  Box,
  Divider,
  SearchSvgIcon,
  Table,
  TapArea,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode } from "react";

import { FancyDateTime } from "@prodoctivity/design-system";
import type { HttpGetDocumentCollectionInstanceListResponse } from "@prodoctivity/types";
import { BreadCrumb } from "../../components/BreadCrumb";
import { OrganizationAvatarUserLookup } from "../../components/Display/OrganizationAvatarUserLookup";
import { usePaginatedDocumentCollectionInstanceList } from "../../components/DocumentCollection/hooks";
import { Pagination } from "../../components/Layout/Pagination";
import { NotificationMessage } from "../../components/NotificationMessage";
import { PublicProfile } from "../../components/Profile/Public/PublicProfile";
import { SmallRefreshButton } from "../../components/SmallRefreshButton";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";
import { RowSkeleton } from "../Settings/DataDictionary/DataLists";
import { RenderDocumentCollectionIcon } from "./RenderDocumentCollectionIcon";
import { SmallFolderSummaryBadge } from "./components/StructureInstance";
import { useDocumentCollectionCard } from "./hooks";

const DocumentCollectionInstanceListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const {
    isLoading,
    documentCollections,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    pageLengthOptions,
    previousPage,
    rowsPerPage,
    paramsFilter,
    handleFilterChange,
    setPageLength,
    totalRowCount,
    refetch,
  } = usePaginatedDocumentCollectionInstanceList();
  return (
    <Box>
      <Box color={colors.white}>
        <BreadCrumb
          entries={[
            { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
            { type: "text", name: resources.documentCollection.documentCollections },
          ]}
        />
      </Box>

      <Box padding={6}>
        <Box display="flex" gap={2} alignItems="center">
          <Text size="400" weight="bold" color={colors.black600}>
            {resources.documentCollection.documentCollections}
          </Text>
          <Box>
            <SmallRefreshButton
              accessibilityLabel={resources.refresh}
              refetch={refetch}
              marginTop={2}
            />
          </Box>
        </Box>

        <Box flex="grow" display="flex" direction="column">
          <Box display="flex" direction="row">
            <Box display="flex" gap={3} flex={"grow"}>
              <Box
                flex="grow"
                borderStyle="lg"
                borderRadius={12}
                paddingX={2}
                color={colors.white}
                display={"flex"}
                alignItems={"center"}
                direction={"row"}
              >
                <Box width={"100%"}>
                  <TextField
                    id={"document-collection-search"}
                    onChange={({ value }) => {
                      handleFilterChange("filter", { value });
                    }}
                    size="lg"
                    mode="unstyled"
                    placeholder={resources.search}
                    value={paramsFilter.filter}
                  />
                </Box>
                <Box>
                  <SearchSvgIcon />
                </Box>
              </Box>
              <Box display="flex" flex="shrink" paddingX={1}>
                <OrganizationAvatarUserLookup
                  id="browse_document_collection_assigned_lookup"
                  label={""}
                  accessibilityClearButtonLabel={resources.clear}
                  noResultText={resources.noUsers}
                  placeholder={resources.assignedTo}
                  onSelect={(element) => {
                    return handleFilterChange("assignedTo", {
                      value: element.value,
                    });
                  }}
                  onClear={() => {
                    handleFilterChange("assignedTo", {
                      value: undefined,
                    });
                  }}
                  selectedUsername={paramsFilter.assignedTo}
                />
              </Box>
            </Box>
            <Box>
              <Pagination<typeof rowsPerPage>
                id="document_collection_list_pagination_top"
                rowsLabel={`${resources.documentTypes.documentCollection}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={documentCollections?.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
                totalRowCount={totalRowCount}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box margin={5} color={colors.white}>
        <Table accessibilityLabel={resources.documentTypes.documentTypes}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Box></Box>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Box>
                  <Text size="200">{resources.name.toUpperCase()}</Text>
                </Box>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Box>
                  <Text size="200">{resources.documentTypes.documentCollection.toUpperCase()}</Text>
                </Box>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Box paddingX={4}>
                  <Text size="200" align="center">
                    {resources.updateDate.toUpperCase()}
                  </Text>
                </Box>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Box paddingX={4}>
                  <Text size="200" align="center">
                    {resources.assignedTo.toUpperCase()}
                  </Text>
                </Box>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <RowSkeleton />}
            {!isLoading &&
              (documentCollections || []).map((dc, i) => (
                <DocumentCollectionRow key={i} documentCollectionInstance={dc} refetch={refetch} />
              ))}
          </Table.Body>
        </Table>
      </Box>

      <Box color={colors.white} marginTop={5} padding={2} display="flex" direction="column">
        <Divider />
        <Box display="flex" direction="row" flex="grow">
          <Box display="flex" direction="row" flex="grow" />
          <Box display="flex" direction="row" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="document_collection_list_pagination_bottom"
              rowsLabel={`${resources.documentTypes.documentCollection}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={documentCollections?.length || 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={pageLengthOptions}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type RowProps = {
  documentCollectionInstance: HttpGetDocumentCollectionInstanceListResponse["payload"]["documentCollections"][0];
  refetch: () => void;
};
const DocumentCollectionRow: FunctionComponent<RowProps> = ({
  documentCollectionInstance,

  refetch,
}) => {
  const {
    documentCollectionConfig,
    mutateUpdateAssigned,

    toastMessage,
    setToastMessage,
  } = useDocumentCollectionCard(documentCollectionInstance, refetch);
  const { resources, moment } = useAppTranslation();

  const isAllowActions = documentCollectionInstance && documentCollectionInstance.allowActions;

  return (
    <>
      {toastMessage && (
        <NotificationMessage
          type={toastMessage.type}
          message={toastMessage.message}
          position="bottom-left"
          handleDismiss={() => setToastMessage(undefined)}
        />
      )}
      {isAllowActions && documentCollectionInstance.allowActions.includes("view") && (
        <Table.Row hoverStyle="gray">
          <Table.Cell>
            <CustomTapArea instanceId={documentCollectionInstance.id}>
              <Box display="flex" justifyContent="center">
                <RenderDocumentCollectionIcon
                  iconKey={documentCollectionConfig?.iconKey || "default"}
                  width={30}
                  height={30}
                />
              </Box>
            </CustomTapArea>
          </Table.Cell>
          <Table.Cell>
            <CustomTapArea instanceId={documentCollectionInstance.id}>
              <Text size="200" weight="bold">
                {documentCollectionInstance.name}
              </Text>

              <Box display="flex">
                <SmallFolderSummaryBadge
                  resources={resources}
                  documentsCount={
                    documentCollectionInstance.counts || {
                      documentCount: 0,
                      expiredCount: 0,
                      issueCount: 0,
                      toExpireCount: 0,
                    }
                  }
                />
              </Box>
            </CustomTapArea>
          </Table.Cell>
          <Table.Cell>
            <CustomTapArea instanceId={documentCollectionInstance.id}>
              <Text size="200">{documentCollectionConfig?.name}</Text>
            </CustomTapArea>
          </Table.Cell>
          <Table.Cell>
            <CustomTapArea instanceId={documentCollectionInstance.id}>
              <Box display="flex" justifyContent="center">
                <FancyDateTime
                  moment={moment}
                  resources={resources}
                  size="200"
                  value={documentCollectionInstance.updatedAt}
                  showTime={false}
                />
              </Box>
            </CustomTapArea>
          </Table.Cell>

          <Table.Cell>
            {isAllowActions && documentCollectionInstance.allowActions.includes("assign") ? (
              <OrganizationAvatarUserLookup
                id="browse_document_collection_assigned_lookup"
                label={""}
                accessibilityClearButtonLabel={resources.clear}
                noResultText={resources.noUsers}
                placeholder={resources.user}
                onSelect={(element) => {
                  return mutateUpdateAssigned(element.value);
                }}
                onClear={() => {
                  mutateUpdateAssigned(undefined);
                }}
                selectedUsername={documentCollectionInstance.assignedTo}
              />
            ) : (
              <Text align="center" size="200">
                <CustomTapArea instanceId={documentCollectionInstance.id}>
                  {documentCollectionInstance.assignedTo && (
                    <PublicProfile username={documentCollectionInstance.assignedTo} />
                  )}
                </CustomTapArea>
              </Text>
            )}
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

const CustomTapArea = ({ instanceId, children }: { instanceId: string; children: ReactNode }) => {
  const organizationNavigate = useOrganizationNavigate();
  return (
    <TapArea
      onTap={() => organizationNavigate(organizationLinkTemplates.documentCollection(instanceId))}
    >
      {children}
    </TapArea>
  );
};

export default DocumentCollectionInstanceListPage;
