import { Box, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  messageTitle: string;
  messageDescription: string;
  illustration: ReactNode;
};

export const NoResultsFoundBlock: FunctionComponent<Props> = ({
  messageTitle,
  messageDescription,
  illustration,
}) => {
  const { colors } = useColors();

  return (
    <Box paddingY={10} display="flex" justifyContent="center">
      <Box
        width={"100%"}
        maxWidth={513}
        display="flex"
        justifyContent="center"
        direction="column"
        gap={6}
      >
        <Box
          width={"100%"}
          height={"auto"}
          maxWidth={320}
          maxHeight={322}
          justifyContent="center"
          alignItems="center"
          margin={"auto"}
          paddingTop={6}
        >
          {illustration}
        </Box>
        <Box display="flex" direction="column" gap={3}>
          <Text size="400" weight="bold" align="center">
            {messageTitle}
          </Text>
          <Text size="200" weight="normal" align="center" color={colors.neutral800}>
            {messageDescription}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
