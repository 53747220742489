import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const RecentDocumentCollectionInstanceViewIcon: FunctionComponent<Props> = ({
  width = 26,
  height = 21,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      width={width}
      height={height}
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_87_922)">
        <path
          d="M20.3183 8.00595C18.9045 7.94099 17.5053 8.31576 16.3133 9.07868C15.1212 9.8416 14.1948 10.9552 13.6617 12.2662C12.2834 15.5172 13.1684 18.3434 15.8324 20.6916C15.6904 20.7005 15.5864 20.7132 15.4824 20.7132H3.16444C2.79639 20.7452 2.42581 20.6963 2.07861 20.5701C1.73142 20.4439 1.41599 20.2433 1.15441 19.9824C0.892821 19.7216 0.691388 19.4067 0.564203 19.0599C0.437017 18.713 0.38715 18.3426 0.418089 17.9745C0.418089 15.1529 0.418089 12.3309 0.418089 9.50846C0.39555 9.15113 0.448667 8.79307 0.573969 8.45768C0.699271 8.12228 0.893937 7.81711 1.14524 7.56209C1.39655 7.30707 1.69885 7.10795 2.03237 6.97775C2.36589 6.84755 2.72313 6.78919 3.08075 6.80649C8.0612 6.79888 13.0416 6.79888 18.0221 6.80649C18.4755 6.78817 18.9257 6.88919 19.3278 7.09946C19.7298 7.30973 20.0697 7.62188 20.3133 8.00468L20.3183 8.00595Z"
          fill="#F6C720"
        />
        <path
          d="M25.7499 15.0671C25.7438 16.1897 25.405 17.2852 24.7763 18.2152C24.1476 19.1452 23.2572 19.8678 22.2177 20.2917C21.1783 20.7156 20.0365 20.8217 18.9367 20.5966C17.8369 20.3715 16.8286 19.8253 16.0393 19.0272C15.25 18.229 14.7151 17.2146 14.5023 16.1124C14.2895 15.0102 14.4084 13.8696 14.8439 12.835C15.2793 11.8003 16.0119 10.9181 16.9488 10.2998C17.8858 9.68147 18.985 9.35492 20.1076 9.36142C20.8533 9.36357 21.5912 9.51309 22.2789 9.80137C22.9666 10.0896 23.5906 10.511 24.1149 11.0412C24.6393 11.5715 25.0536 12.2001 25.3342 12.891C25.6148 13.5819 25.7561 14.3215 25.7499 15.0671ZM20.0847 16.9348C19.6194 16.9348 19.1554 16.9221 18.69 16.9348C18.3501 16.9367 18.0179 17.0356 17.7322 17.2197C17.4465 17.4039 17.2193 17.6657 17.0772 17.9745C17.0497 18.0146 17.0307 18.0599 17.0213 18.1076C17.0119 18.1553 17.0124 18.2044 17.0228 18.2519C17.0331 18.2994 17.053 18.3443 17.0814 18.3838C17.1097 18.4234 17.1458 18.4567 17.1875 18.4817C18.0093 19.1429 19.0337 19.5007 20.0885 19.495C21.1432 19.4892 22.1637 19.1202 22.9782 18.45C23.0302 18.3872 23.0664 18.3129 23.0836 18.2332C23.1008 18.1535 23.0986 18.0709 23.0771 17.9923C22.9448 17.6864 22.7278 17.4248 22.4517 17.2384C22.1756 17.0519 21.8519 16.9483 21.5188 16.9399C21.0408 16.9158 20.5615 16.9399 20.0835 16.9399L20.0847 16.9348ZM22.0031 13.7992C22.008 13.287 21.8092 12.7939 21.4505 12.4283C21.0918 12.0627 20.6026 11.8546 20.0904 11.8497C19.5783 11.8449 19.0852 12.0436 18.7196 12.4023C18.354 12.761 18.1459 13.2503 18.141 13.7624C18.1472 14.2753 18.3533 14.7654 18.7153 15.1287C19.0774 15.4919 19.5669 15.6995 20.0797 15.7074C20.5857 15.7016 21.0696 15.499 21.4289 15.1426C21.7881 14.7862 21.9945 14.3039 22.0044 13.7979L22.0031 13.7992Z"
          fill="#F6C720"
        />
        <path
          d="M10.5679 3.63567H17.3386C17.5503 3.63223 17.762 3.64112 17.9726 3.66229C18.1778 3.68364 18.3692 3.77582 18.5139 3.92299C18.6585 4.07016 18.7473 4.26312 18.7651 4.4687C18.7844 4.67478 18.7318 4.8812 18.6163 5.05292C18.5007 5.22464 18.3293 5.35108 18.1311 5.41077C17.9707 5.46168 17.8036 5.4886 17.6353 5.49065C12.9093 5.49488 8.18373 5.49488 3.45856 5.49065C2.59256 5.49065 2.1285 4.8998 2.41251 4.18849C2.47 4.02729 2.57728 3.88856 2.71883 3.79237C2.86038 3.69617 3.02886 3.64751 3.1999 3.65342C3.94671 3.63694 4.6948 3.6344 5.44288 3.63313C7.15205 3.63228 8.86038 3.63313 10.5679 3.63567Z"
          fill="#F6C720"
        />
        <path
          d="M10.5225 2.30471C8.88688 2.30471 7.25167 2.30471 5.61688 2.30471C5.40505 2.31037 5.19311 2.29977 4.98291 2.27301C4.78487 2.24811 4.60263 2.15206 4.47014 2.00278C4.33764 1.85349 4.26393 1.66113 4.26272 1.46154C4.2296 1.25719 4.27351 1.04782 4.38596 0.873999C4.4984 0.700181 4.67136 0.574293 4.87133 0.520729C5.04659 0.470934 5.22801 0.446175 5.4102 0.447189C8.83363 0.447189 12.257 0.435777 15.6805 0.4586C16.0147 0.466259 16.3386 0.575986 16.6086 0.773048C16.7391 0.895549 16.8262 1.05714 16.8568 1.23346C16.8875 1.40978 16.86 1.59129 16.7785 1.75063C16.725 1.90635 16.6235 2.04114 16.4887 2.13567C16.3538 2.23019 16.1925 2.2796 16.0279 2.27682C15.2823 2.29964 14.5343 2.30091 13.7874 2.30218C12.7021 2.30218 11.6167 2.30218 10.5314 2.30218L10.5225 2.30471Z"
          fill="#F6C720"
        />
      </g>
      <defs>
        <clipPath id="clip0_87_922">
          <rect
            width="25.3333"
            height="20.2705"
            fill="white"
            transform="translate(0.416992 0.444336)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
