import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ImportADocumentSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 28,
  height = 27,
  onClick,
  color,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 28 27`}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clip-path="url(#clip0_13681_139160)">
        <path
          d="M27.8784 2.31555C27.5129 0.986048 26.2059 0.0110792 24.8325 0.0110792C24.4227 0.0110792 24.0129 0 23.592 0C18.043 0 12.4939 0.0110792 6.94486 0C5.85941 0 5.00656 0.454247 4.34201 1.28519C3.96542 1.75051 3.68853 2.27123 3.67745 2.86951C3.67745 3.14649 3.66637 3.36808 3.66637 3.54534H3.62207V15.2561L5.78188 15.3114C5.78188 11.755 5.78188 8.46451 5.79296 7.48954H5.78188V3.92204C5.75973 2.83627 6.20277 2.19368 6.69011 2.19368C7.65372 2.19368 8.51764 2.16044 9.49232 2.14936C11.3199 2.12721 13.1585 2.11613 14.9971 2.13828C18.3199 2.16044 21.6316 2.20476 24.9543 2.24908C25.5303 2.24908 25.8293 2.57037 25.8293 3.13541C25.8293 3.29052 25.8293 3.41239 25.8404 3.54534H25.7961C25.8072 6.5478 25.8737 22.6126 25.8072 23.3328C25.774 23.6984 25.3863 23.9089 25.0319 23.9311C24.0683 23.9754 13.679 24.0086 11.9069 23.9975L11.929 25.0501V25.0833L11.9512 26.1801C16.3926 26.1801 20.236 26.1691 24.6775 26.1801C25.7629 26.1801 26.6047 25.7259 27.2803 24.895C27.6569 24.4407 27.9338 23.9089 27.9449 23.3106C27.9781 21.3275 27.9892 22.3024 28.0002 20.6405V2.90275C27.967 2.70332 27.9338 2.5039 27.8784 2.31555Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M22.2404 6.55859H17.5996V9.05141H22.2404V6.55859Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M22.2404 11.8438H17.5996V14.3366H22.2404V11.8438Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M22.2847 17.1396H11.9287V19.6214H22.2847V17.1396Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M14.9637 6.55859H8.89403C8.66158 6.55859 8.47314 6.74709 8.47314 6.9796V13.9373C8.47314 14.1699 8.66158 14.3583 8.89403 14.3583H14.9637C15.1961 14.3583 15.3845 14.1699 15.3845 13.9373V6.9796C15.3845 6.74709 15.1961 6.55859 14.9637 6.55859Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M10.2229 25.061L9.1375 23.9642L8.25142 23.0668L7.37642 23.942L4.47452 26.8448C4.37484 26.9445 4.24193 26.9999 4.10902 26.9999C3.96503 26.9999 3.83212 26.9445 3.73243 26.8448L0.154903 23.2662C-0.0555404 23.0557 -0.0555404 22.7233 0.154903 22.5128L3.96503 18.7016L3.62167 18.3471L2.05997 16.7295L3.62167 16.7738L5.78149 16.8292L6.41281 16.8514L7.29889 16.8735L9.59161 16.94C9.86851 16.94 10.1011 17.1727 10.1011 17.4607L10.1454 19.7209V20.5961L10.1786 22.0143V22.8784L10.2008 23.9753L10.2229 25.061Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_13681_139160">
          <rect width="28" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
