import type {
  DocumentHistoryEntry,
  DocumentTypeInfo,
  DocumentTypeResume,
  HttpGetDocumentCollectionConfigListRequest,
  HttpGetDocumentHistoryRequest,
  HttpGetTemplateListRequest,
  PaginatedQueryResponsePart,
} from "@prodoctivity/types";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useDesignBreakpoint } from "@prodoctivity/design-system";
import { MimeTypes, parseDataURI } from "@prodoctivity/shared";
import { useDebounceCallback, useDebounceValue } from "usehooks-ts";
import { useAppTranslation } from "../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../hooks/useOrganizationQuery";
import { useServices } from "../hooks/useServices";
import { DashboardItemContext } from "../pages/Dashboard/DashboardConfigColumnElement";

const templateListPageLengthOptions: Array<
  HttpGetTemplateListRequest["queryParameters"]["rowsPerPage"]
> = ["15", "30", "100"];

export function useTemplateList(action: "generate" | undefined, filterText: string | undefined) {
  const { breakpoint } = useDesignBreakpoint();
  const resources = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();

  const goToTemplateVersion = useCallback(
    (templateVersionId: string) => {
      if (action === "generate") {
        organizationNavigate(`/templates/versions/${templateVersionId}/generate`);
      } else {
        organizationNavigate(`/templates/versions/${templateVersionId}/details`);
      }
    },
    [action, organizationNavigate]
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =
    useState<HttpGetTemplateListRequest["queryParameters"]["rowsPerPage"]>("15");

  const setPageLength = useCallback((pageLength: typeof rowsPerPage) => {
    setRowsPerPage(pageLength);
    setCurrentPage(0);
  }, []);

  const { fetchTemplateList, user: thisUser } = useServices();

  const listTemplatesPage = useCallback(() => {
    return fetchTemplateList("all", false, currentPage, rowsPerPage, filterText);
  }, [currentPage, fetchTemplateList, filterText, rowsPerPage]);

  const { isLoading, data, refetch, remove } = useOrganizationQuery(
    `template-list/page_${currentPage}_${rowsPerPage},${filterText}`,
    listTemplatesPage,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const dashboardItemContext = useContext(DashboardItemContext);

  useEffect(() => {
    const eventEmitter = dashboardItemContext.eventEmitter;
    if (eventEmitter) {
      const handler = () => {
        remove();
        refetch();
      };
      eventEmitter.on("refresh", handler);
      return () => {
        eventEmitter.off("refresh", handler);
      };
    }
  }, [dashboardItemContext, refetch, remove]);

  const totalRowCount = data ? data.totalRowCount : undefined;
  const isNextButtonDisabled =
    !data ||
    data.requestedPageLength !== data.pageLength ||
    data.pageLength + currentPage * data.requestedPageLength >=
      (data.totalRowCount ?? Number.MAX_SAFE_INTEGER);
  const isPreviousButtonDisabled = !data || data.pageNumber < 1;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled]);

  return {
    isLoading,
    templates: data ? data.templates : undefined,
    currentPage,
    nextPage,
    previousPage,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    rowsPerPage,
    setPageLength,
    refetch: () => {
      remove();
      refetch();
    },
    thisUsername: thisUser?.username,
    pageLengthOptions: templateListPageLengthOptions,
    resources,
    goToTemplateVersion,
    totalRowCount,
    breakpoint,
  };
}

export function usePaginatedDataEndpoint<ResponseData, RowsPerPageType, FilterType>(
  defaultRowsPerPage: RowsPerPageType,
  defaultFilterValue: FilterType,
  fetchFunction: (
    services: ReturnType<typeof useServices>,
    currentPage: number,
    rowsPerPage: RowsPerPageType,
    filter: FilterType
  ) => Promise<PaginatedQueryResponsePart & ResponseData>,
  organizationQueryKey: string,
  options?: {
    debounceDelay?: number;
    refetchInterval?: number;
  }
) {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageType>(defaultRowsPerPage);
  const [filter, setFilter] = useState<FilterType>(defaultFilterValue);

  const setDebouncedFilter = useDebounceCallback(setFilter, options?.debounceDelay || 600, {
    leading: true,
  });
  const services = useServices();

  useEffect(() => {
    setCurrentPage(0);
  }, [filter]);

  const setPageLength = useCallback((pageLength: RowsPerPageType) => {
    setCurrentPage(0);
    setRowsPerPage(pageLength);
  }, []);

  const getPaginatedData = useCallback(() => {
    return fetchFunction(services, currentPage, rowsPerPage, filter);
  }, [services, fetchFunction, currentPage, rowsPerPage, filter]);

  const debouncedResult = useMemo(() => {
    return typeof filter === "object" ? JSON.stringify(filter) : filter;
  }, [filter]);

  const { data, isLoading, remove, refetch } = useOrganizationQuery(
    `${organizationQueryKey}/page_${currentPage}_${rowsPerPage}_${debouncedResult}`,
    getPaginatedData,
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: options?.refetchInterval,
    }
  );

  const isNextButtonDisabled =
    !data ||
    data.requestedPageLength !== data.pageLength ||
    data.pageLength + currentPage * data.requestedPageLength >=
      (data.totalRowCount ?? Number.MAX_SAFE_INTEGER);

  const isPreviousButtonDisabled = !data || data.pageNumber < 1;
  const totalRowCount = data ? data.totalRowCount : undefined;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled]);

  return {
    isLoading,
    paginatedData: data || undefined,
    currentPage,
    nextPage,
    previousPage,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    rowsPerPage,
    filter,
    setFilter: setDebouncedFilter,
    setPageLength,
    refetch: () => {
      remove();
      refetch();
    },
    totalRowCount,
  };
}
export function usePaginatedDocumentCollectionList() {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =
    useState<HttpGetDocumentCollectionConfigListRequest["queryParameters"]["rowsPerPage"]>("15");
  const [debouncedFilter, setFilter] = useDebounceValue("", 600);

  const setPageLength = useCallback(
    (pageLength: HttpGetDocumentCollectionConfigListRequest["queryParameters"]["rowsPerPage"]) => {
      setCurrentPage(0);
      setRowsPerPage(pageLength);
    },
    []
  );

  const { getDocumentCollectionConfigList, user: thisUser } = useServices();
  const getData = useCallback(() => {
    return getDocumentCollectionConfigList(currentPage, rowsPerPage, debouncedFilter);
  }, [currentPage, getDocumentCollectionConfigList, rowsPerPage, debouncedFilter]);

  const { data, isLoading, remove, refetch } = useOrganizationQuery(
    `document-collection-config/page_${currentPage}_${rowsPerPage}_${debouncedFilter}`,
    getData,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchInterval: 30 * 1000,
      cacheTime: 0,
    }
  );

  const isNextButtonDisabled =
    !data ||
    data.requestedPageLength !== data.pageLength ||
    data.pageLength + currentPage * data.requestedPageLength >=
      (data.totalRowCount ?? Number.MAX_SAFE_INTEGER);

  const isPreviousButtonDisabled = !data || data.pageNumber < 1;
  const totalRowCount = data ? data.totalRowCount : undefined;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled]);

  return {
    isLoading,
    documentCollections: data?.documentCollectionConfigList || [],
    currentPage,
    nextPage,
    previousPage,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    rowsPerPage,
    filter: debouncedFilter,
    setFilter,
    setPageLength,
    refetch: () => {
      remove();
      refetch();
    },
    thisUsername: thisUser?.username,
    pageLengthOptions: ["15", "30", "50"] as Array<typeof rowsPerPage>,
    totalRowCount,
  };
}

export function usePaginatedDocumentHistory(
  documentId: string,
  documentTypeInfo: DocumentTypeInfo | undefined
) {
  const [currentPage, setCurrentPage] = useState(0);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] =
    useState<HttpGetDocumentHistoryRequest["queryParameters"]["rowsPerPage"]>("10");

  const setPageLength = useCallback(
    (pageLength: HttpGetDocumentHistoryRequest["queryParameters"]["rowsPerPage"]) => {
      setCurrentPage(0);
      setRowsPerPage(pageLength);
    },
    []
  );

  const { getDocumentHistory, user: thisUser } = useServices();
  const getData = useCallback(() => {
    return getDocumentHistory(documentId, rowsPerPage, currentPage);
  }, [getDocumentHistory, documentId, rowsPerPage, currentPage]);

  const { data, isLoading, remove, refetch } = useOrganizationQuery(
    `/document-history-list/documentId:${documentId}/page_${currentPage}_${rowsPerPage}`,
    getData,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchInterval: 30 * 1000,
    }
  );

  const isNextButtonDisabled =
    !data ||
    data.requestedPageLength !== data.pageLength ||
    data.pageLength + currentPage * data.requestedPageLength >=
      (data.totalRowCount ?? Number.MAX_SAFE_INTEGER);

  const isPreviousButtonDisabled = !data || data.pageNumber < 1;
  const totalRowCount = data ? data.totalRowCount : undefined;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled]);

  const getInitialSectionState = useMemo(() => {
    if (!documentTypeInfo?.wizardDefinition?.pages) {
      return [];
    }

    return documentTypeInfo.wizardDefinition.pages.flatMap((page) =>
      page.sections.flatMap((section) => {
        return { checked: false, label: section.label };
      })
    );
  }, [documentTypeInfo?.wizardDefinition?.pages]);

  const [sectionListState, setSectionListState] = useState<
    {
      checked: boolean;
      label: string;
    }[]
  >(getInitialSectionState);

  const handleCheckboxChange = useCallback((label: string | undefined, checked: boolean) => {
    setSectionListState((prevSections) =>
      prevSections.map((section) => (section.label === label ? { ...section, checked } : section))
    );
  }, []);

  const setAllFieldsToFalse = useCallback(() => {
    setSectionListState((prevSections) =>
      prevSections.map((section) => ({ ...section, checked: false }))
    );
  }, []);

  const getCheckedLabelsFromHistory = useMemo(() => {
    const pageSections = documentTypeInfo?.wizardDefinition.pages.flatMap((page) => page.sections);

    const sectionFields = pageSections?.flatMap((section) =>
      sectionListState.flatMap((f) => {
        if (f.checked && section.label === f.label) {
          return section.fields;
        }
        return [];
      })
    );

    let checkedLabels = sectionFields?.flatMap((i) =>
      (data?.documentHistory || []).flatMap((k) => {
        const filteredChanges = k.changes.filter(
          (l) => i.label === l.fieldName || i.key === l.fieldName
        );

        return filteredChanges.length > 0 ? { ...k, changes: filteredChanges } : [];
      })
    );

    const groupedByCreatedAt: { [key: number]: DocumentHistoryEntry } = {};
    checkedLabels &&
      checkedLabels.forEach((item) => {
        const createdAt = item.createdAt;
        if (!groupedByCreatedAt[createdAt]) {
          groupedByCreatedAt[createdAt] = item;
        } else {
          if (!groupedByCreatedAt[createdAt].changes) {
            groupedByCreatedAt[createdAt].changes = [];
          }
          groupedByCreatedAt[createdAt].changes.push(...item.changes);
        }
      });

    checkedLabels = Object.values(groupedByCreatedAt);

    return checkedLabels.length > 0 ? checkedLabels : data?.documentHistory;
  }, [data, documentTypeInfo, sectionListState]);

  return {
    isLoading,
    historyList: data?.documentHistory || [],
    currentPage,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    refetch: () => {
      remove();
      refetch();
    },
    totalRowCount,
    thisUsername: thisUser?.username,
    pageLengthOptions: ["10", "30", "50"] as Array<typeof rowsPerPage>,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    getInitialSectionState,
    handleCheckboxChange,
    sectionListState,
    setAllFieldsToFalse,
    getCheckedLabelsFromHistory,
    isHovered,
    setIsHovered,
  };
}

export function useDocumentCollection(instanceId: string, configId: string) {
  const { getDocumentCollection, getDocumentCollectionConfig } = useServices();

  const fetchDocumentCollectionInstance = useCallback(async () => {
    if (!instanceId) return undefined;
    return getDocumentCollection(instanceId);
  }, [instanceId, getDocumentCollection]);

  const { data: instanceIdData, isLoading: instanceIdIsLoading } = useOrganizationQuery(
    `document-collection/instanceId/${instanceId}`,
    fetchDocumentCollectionInstance
  );

  const fetchDocumentCollectionConfigId = useCallback(async () => {
    if (!configId) return undefined;
    return getDocumentCollectionConfig(configId);
  }, [configId, getDocumentCollectionConfig]);

  const { data: configIdData, isLoading: configIdIsLoading } = useOrganizationQuery(
    `document-collection/configId/${configId}`,
    fetchDocumentCollectionConfigId
  );

  return {
    documentCollection: instanceIdData?.collection,
    instanceIdIsLoading,
    configIdData: configIdData?.config,
    configIdIsLoading,
  };
}

export const useDocumentWordToPdf = (key: string, documentVersionId?: string) => {
  const [wordDataUri, setWordDataUri] = useState<string | undefined>();
  const { getWordAsPdf } = useServices();

  const fetchWordAsPdf = useCallback(async () => {
    if (!wordDataUri) {
      return { pdfDataUri: undefined };
    }
    return await getWordAsPdf(wordDataUri, documentVersionId);
  }, [wordDataUri, getWordAsPdf, documentVersionId]);

  const queryKey = useMemo(() => {
    return `word-as-pdf/${key}/${wordDataUri ? "convert-to-pdf" : undefined}`;
  }, [key, wordDataUri]);

  const { data: pdfResponse } = useOrganizationQuery(queryKey, fetchWordAsPdf);

  const setDataUri = useCallback((wordDataUri?: string) => {
    if (!wordDataUri) {
      setWordDataUri(undefined);
      return;
    }
    const dataUri = parseDataURI(wordDataUri);
    if (!dataUri || dataUri.contentType !== MimeTypes.MsWordDocument) {
      setWordDataUri(undefined);
      return;
    }
    setWordDataUri(wordDataUri);
  }, []);

  const pdfDataUri = useMemo(() => {
    return wordDataUri ? pdfResponse?.pdfDataUri : undefined;
  }, [wordDataUri, pdfResponse]);

  return {
    pdfDataUri,
    setWordDataUri: setDataUri,
  };
};

export function useAllDocumentTypes(opts?: {
  onSuccess?(data: { documentTypes: DocumentTypeResume[] } & PaginatedQueryResponsePart): void;
}) {
  const { getAllDocumentTypes } = useServices();

  const {
    data: documentTypeData,
    isLoading,
    isRefetching,
    remove,
    refetch,
  } = useOrganizationQuery(`document-types/all`, getAllDocumentTypes, {
    staleTime: 2 * 60 * 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    ...(opts ? opts : {}),
  });

  return {
    allDocumentTypes: documentTypeData,
    isLoading,
    isRefetching,
    remove,
    refetch,
  };
}
