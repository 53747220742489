import Dynamsoft from "dwt";
import { WebTwain } from "dwt/dist/types/WebTwain";
import { DeviceConfiguration } from "dwt/dist/types/WebTwain.Acquire";
import * as React from "react";

/**
 * Represensts the possibles status of the current scanner instance
 */
export type ScannerServiceStatus = "Loading" | "Error" | "Ready";

/**
 *  state for use DWT container component
 */
export interface IDwtState {
  /**
   * List a current scanner device connected to machine
   *
   * @type {Array<string>}
   * @memberof IDwtState
   */
  scanners: Array<string>;
  /**
   * DEfault device to operate
   *
   * @type {(string | undefined)}
   * @memberof IDwtState
   */
  currentScanner: string | undefined;
  /**
   * current device configuration
   *
   * @type {DeviceConfiguration}
   * @memberof IDwtState
   */
  deviceConfig: DeviceConfiguration;
  /**
   * Current pages index on current scan session
   *
   * @type {number}
   * @memberof IDwtState
   */
  imageIndex: number;
}

/**
 * Props for render a DWT (scanner view)
 *
 */
export type ScannerProps = {
  /**
   * Callback function for init scanner process
   *
   * @type {void}
   * @memberof ScannerProps
   *
   */
  beginScan?: () => void;
  /**
   * callback function to execute when scanner finish the process
   *
   */
  endScan?: () => void;
  setCurrentScanner?: (scanner: any) => void;
  /**
   *
   *  Callback function for retrieve all connected scanner device
   *
   * @type {void}
   * @memberof ScannerProps
   */
  getAvailableScanners: (scanners: Array<string>) => void;
  /**
   *
   *  callback function that emit current scanner page
   *
   * @type {void}
   * @memberof ScannerProps
   */
  emitNewPage: (images: string | boolean) => void;
  /**
   * set if the default Dynamsoft view is visible
   *
   * @type {boolean}
   */
  hideDisplay: boolean;
  /**
   *
   * set default device to scan
   *
   * @type {string| undefined}
   */
  defaultScanner: string | undefined;
  /**
   * function for translate labels
   *
   * @type {Function}
   */
  translate: (key: string) => string;
  /**
   *  set the license for use dynamsoft web twain plugin
   *
   * @type {string}
   */
  license: string;
  /**
   * send current status to higth level component
   * @param status the current status
   * @returns
   */
  getServiceState: (status: ScannerServiceStatus) => void;

  /**
   * callback function the trigger when user cancel cApture process
   */
  onCancelScanProcess: () => void;
};

/**
 * Provide functionalities for use scanner device into Web Applications
 *
 */
export class DWT extends React.Component<ScannerProps, IDwtState> {
  constructor(props: ScannerProps) {
    super(props);

    this.state = {
      scanners: new Array<string>(),
      currentScanner: props.translate("LookingDevice"),
      imageIndex: 0,
      deviceConfig: {
        IfShowUI: true,
        Resolution: 300,
      },
    };
  }

  DWObject: WebTwain | undefined = undefined;
  containerId = "dwtcontrolContainer";
  width = this.props.hideDisplay ? "0px" : "100%";
  height = this.props.hideDisplay ? "0px" : "600";

  componentDidUpdate(prevProps: ScannerProps) {
    if (prevProps.defaultScanner !== this.props.defaultScanner) {
      this.setState({ currentScanner: this.props.defaultScanner });
    }
  }

  componentDidMount(): void {
    this.loadDWT();
  }

  loadDWT = () => {
    Dynamsoft.DWT.ProductKey = this.props.license;
    Dynamsoft.DWT.ResourcesPath = "/dwt-resources";
    const innerLoad = () => {
      this.innerLoadDWT().then(
        (_dwt) => {
          this.props.getServiceState("Ready");
          Dynamsoft.DWT.Unload();
          this.checkErrorLoadType("ok");
        },
        (err) => {
          Dynamsoft.DWT.Unload();
          this.checkErrorLoadType(err);
        }
      );
    };
    innerLoad();
  };

  checkErrorLoadType = (err: unknown) => {
    console.error(err);
    if (typeof err === "string") {
      Dynamsoft.DWT.RegisterEvent("OnWebTwainReady", () => {
        this.Dynamsoft_OnReady();
      });
      Dynamsoft.DWT.Containers = [
        {
          WebTwainId: "dwtObject",
          ContainerId: this.containerId,
          Width: "300px",
          Height: "400px",
        },
      ];
      Dynamsoft.DWT.Load();
      this.props.getServiceState("Ready");
    } else {
      this.props.getServiceState("Error");
    }
  };

  innerLoadDWT() {
    return new Promise((res, rej) => {
      this.modulizeInstallJS();
      const dwtInitialConfig = {
        WebTwainId: "dwtObject",
      };
      Dynamsoft.DWT.CreateDWTObjectEx(
        dwtInitialConfig,
        (_DWObject) => {
          res(_DWObject);
        },
        (errorString) => {
          rej(errorString);
        }
      );
    });
  }

  modulizeInstallJS() {
    const _DWT_Reconnect = (Dynamsoft as any).DWT_Reconnect;
    (Dynamsoft as any).DWT_Reconnect = (...args: any) =>
      _DWT_Reconnect.call({ Dynamsoft: Dynamsoft }, ...args);
    const __show_install_dialog = (Dynamsoft as any)._show_install_dialog;
    (Dynamsoft as any)._show_install_dialog = (...args: any) =>
      __show_install_dialog.call({ Dynamsoft: Dynamsoft }, ...args);
    const _OnWebTwainOldPluginNotAllowedCallback = (Dynamsoft as any)
      .OnWebTwainOldPluginNotAllowedCallback;
    (Dynamsoft as any).OnWebTwainOldPluginNotAllowedCallback = (...args: any) =>
      _OnWebTwainOldPluginNotAllowedCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    const _OnWebTwainNeedUpgradeCallback = (Dynamsoft as any).OnWebTwainNeedUpgradeCallback;
    (Dynamsoft as any).OnWebTwainNeedUpgradeCallback = (...args: any) =>
      _OnWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    const _OnWebTwainPostExecuteCallback = (Dynamsoft as any).OnWebTwainPostExecuteCallback;
    (Dynamsoft as any).OnWebTwainPostExecuteCallback = (...args: any) =>
      _OnWebTwainPostExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    const _OnRemoteWebTwainNotFoundCallback = (Dynamsoft as any).OnRemoteWebTwainNotFoundCallback;
    (Dynamsoft as any).OnRemoteWebTwainNotFoundCallback = (...args: any) =>
      _OnRemoteWebTwainNotFoundCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    const _OnRemoteWebTwainNeedUpgradeCallback = (Dynamsoft as any)
      .OnRemoteWebTwainNeedUpgradeCallback;
    (Dynamsoft as any).OnRemoteWebTwainNeedUpgradeCallback = (...args: any) =>
      _OnRemoteWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    const _OnWebTWAINDllDownloadFailure = (Dynamsoft as any).OnWebTWAINDllDownloadFailure;
    (Dynamsoft as any).OnWebTWAINDllDownloadFailure = (...args: any) =>
      _OnWebTWAINDllDownloadFailure.call({ Dynamsoft: Dynamsoft }, ...args);
  }

  Dynamsoft_OnReady() {
    if (this.DWObject) {
      return;
    }
    this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
    if (this.DWObject) {
      const castedDWO = this.DWObject as WebTwain;
      const vCount = castedDWO.SourceCount;
      const sourceNames = new Array<string>();
      for (let i = 0; i < vCount; i++) sourceNames.push(castedDWO.GetSourceNameItems(i));
      this.setState({ scanners: sourceNames });
      this.props.getAvailableScanners(sourceNames);
      this.registerTransferAsyncEvent();
    }
  }

  private registerTransferAsyncEvent() {
    const castDWO = this.getInstance(); //this.DWObject as WebTwain;
    if (!castDWO) return;

    castDWO.RegisterEvent("OnPostTransferAsync", (outputImage) => {
      this.transferPage(this, outputImage.imageInfo.PixelType);
    });

    castDWO.RegisterEvent("OnSourceUIClose", () => {
      this.props.onCancelScanProcess();
    });
  }

  private getInstance = () => Dynamsoft.DWT.GetWebTwain(this.containerId);

  transferPage = (instance: this, pixelType: number) => {
    const self = instance;
    const lastImageScannedIndex = self.DWObject?.CurrentImageIndexInBuffer as number;
    self.DWObject?.CopyToClipboard(lastImageScannedIndex);
    let pages = "";
    pages = self.getScannedFilesBase64ById(self, lastImageScannedIndex, pixelType);
    self.props.emitNewPage(pages);
  };

  private getScannedFilesBase64ById = (self: this, index: number, pixelType: number): string => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let imagedata: string | boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let pages: any = "";
    const pixelTypeFullColor = 2;
    const grayScaleColor = 1;

    const validColorSchema = [pixelTypeFullColor, grayScaleColor];
    const jpgFormat = 1;
    let imageFormat: number;

    if (self) {
      (self.DWObject as WebTwain).SelectedImagesCount = 1;
      if (this.DWObject && this.DWObject !== null) {
        this.DWObject.SelectImages([index]);
        if (!validColorSchema.includes(pixelType)) {
          this.DWObject.ConvertToGrayScale(
            index,
            () => {
              return console.log("Tiff detected convert to jpeg");
            },
            () => console.log("Error when try to change color schema")
          );
          imageFormat = jpgFormat;
        } else {
          imageFormat = jpgFormat;
        }

        this.DWObject.GetSelectedImagesSize(imageFormat);
        imagedata = this.DWObject.SaveSelectedImagesToBase64Binary();
        pages = imagedata;
      }
      return pages;
    }
    return "";
  };

  /**
   * initialize scanner for acquire images
   *
   * @returns
   */
  acquireImage() {
    if (!this.DWObject) {
      return;
    }
    this.DWObject.CloseSource();
    for (let sourceIndex = 0; sourceIndex < this.DWObject.SourceCount; sourceIndex++) {
      if (this.DWObject.GetSourceNameItems(sourceIndex) === this.state.currentScanner) {
        this.DWObject.SelectSourceByIndex(sourceIndex);
        break;
      }
    }

    this.DWObject.OpenSource();
    this.DWObject.IfDisableSourceAfterAcquire = true;
    this.DWObject.AcquireImage(this.state.deviceConfig);
  }

  /**
   * stop current scanner job
   *
   */
  abortScan() {
    if (!this.DWObject) {
      return;
    }
    this.DWObject.CloseSource();
    this.DWObject.CancelAllPendingTransfers();
  }

  render() {
    return (
      <>
        <div
          style={{
            display: this.props.hideDisplay ? "none" : "inline-block",
            width: "30%",
            margin: "0 auto",
          }}
        >
          <div id={this.containerId}></div>
        </div>
      </>
    );
  }
}
