import type {
  AppUserAccountContext,
  FromServerWsMessage,
  HttpLoginResponse,
  RegisterWebDeviceMessage,
} from "@prodoctivity/types";
import { FunctionComponent, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { Websocket, WebsocketBuilder } from "websocket-ts/lib";
import { baseUrl, isProduction } from "../config";

import { changeLanguage } from "@prodoctivity/design-system";
import { OrganizationUserLanguage$Schema } from "@prodoctivity/shared";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ServicesContext } from "../context";
import { useAppTranslation } from "../hooks/useAppTranslation";
import { useSettings } from "../hooks/useSettings";
import { servicesBuilder } from "../services";
import { noop } from "../utils";

export function isSuccessLogin(
  response: Record<string, unknown>
): response is HttpLoginResponse["payload"] {
  return !response.errors && !response.errorCode;
}

function getAuthorizationToken() {
  const token = (localStorage && localStorage.getItem("token")) ?? undefined;
  if (token) {
    try {
      const accountData = jwtDecode<AppUserAccountContext>(token);

      //Checking for expiration
      if (new Date(accountData.exp * 1000).getTime() < new Date().getTime()) {
        return undefined;
      }
      return token;
    } catch {
      return undefined;
    }
  }
  return token;
}

function getLocation() {
  return window.location.pathname;
}

function goToUrl(url: string) {
  window.location.pathname = url;
}

const messageEventHandlers: Record<
  FromServerWsMessage["type"],
  Array<(msg: FromServerWsMessage) => void>
> = {
  "send-notifications-to-user": [],
};

export type ProviderProps = PropsWithChildren;

export const ServicesContextProvider: FunctionComponent<ProviderProps> = ({ children }) => {
  const { wsGatewayApiURL } = useSettings();
  const wsRef = useRef<Websocket>();

  const [token, setToken] = useState(getAuthorizationToken);

  const { i18n } = useTranslation();
  const { moment } = useAppTranslation();

  useEffect(() => {
    if (!isProduction) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const win: any = window;
      win.i18n = i18n;
      win.changeLanguage = changeLanguage(i18n, moment);
    }
  }, [i18n, moment]);

  useEffect(() => {
    if (token && wsGatewayApiURL) {
      const ws = new WebsocketBuilder(`${wsGatewayApiURL}`)
        .onOpen((/* i, ev */) => {
          console.log("opened");
          const registerMsg: RegisterWebDeviceMessage = {
            status: "online",
            type: "register-web-device",
            token,
            deviceId: token,
          };
          ws.send(JSON.stringify(registerMsg));
        })
        .onClose((/* i, ev */) => {
          console.log("closed");
        })
        .onError((i, ev) => {
          console.log("error");
          console.log(ev);
        })
        .onMessage((_i, ev) => {
          const data = ev.data;
          if (typeof data === "string") {
            const msg: FromServerWsMessage = JSON.parse(data);
            const handlerList = messageEventHandlers[msg.type];
            if (handlerList && Array.isArray(handlerList)) {
              handlerList.forEach((handler) => {
                handler(msg);
              });
            }
          }
        })
        .onRetry((/* i, ev */) => {
          console.log("retry");
        })
        .build();
      wsRef.current = ws;
    }
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = undefined;
      }
    };
  }, [token, wsGatewayApiURL]);

  const services = useMemo(() => {
    const svc = servicesBuilder(
      baseUrl,
      token,
      setToken,
      messageEventHandlers,
      getLocation,
      goToUrl,
      changeLanguage(i18n, moment)
    );

    if (!isProduction) {
      (window as any).renewToken = async () => {
        if (!token) {
          return;
        }
        const response = await svc.renewToken(token);

        console.log(response);
      };
    }

    return svc;
  }, [token, i18n, moment]);

  useEffect(() => {
    const handle = setTimeout(async () => {
      if (token) {
        const response = await services.renewToken(token);
        if (!response || !isSuccessLogin(response)) {
          services.logout();
        }
      }
    }, 15 * 60 * 1000);

    return () => {
      clearTimeout(handle);
    };
  }, [services.renewToken, services, token]);

  useEffect(() => {
    if (token) {
      console.log("Getting user profile");
      services.getMyUserProfile().then((value) => {
        const tempLanguage = services.getTempLanguage();
        changeLanguage(i18n, moment)(tempLanguage ? tempLanguage : value.language);
        if (tempLanguage) {
          services.updateUserProfile({
            language: OrganizationUserLanguage$Schema.parse(tempLanguage),
          });
          services.setTempLanguage(undefined);
        }
      }, noop);
    }
  }, [services.getMyUserProfile, i18n, moment, token, services]);

  return <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>;
};
