import { Box, useColors } from "@prodoctivity/design-system";
import type { DictionaryList, DictionaryListItem } from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useState } from "react";

import { DataListItems } from "./DataListItems";
import { FocusFieldContext } from "./FocusFieldContext";
import { refreshListItems } from "./utils";

type ItemsTabProps = {
  list: DictionaryList<string>;
  onChange: (list: DictionaryList<string>) => void;
};

export const ItemsTab: FunctionComponent<ItemsTabProps> = ({ list, onChange }) => {
  const { colors } = useColors();
  const [focusFieldPath, setFocusFieldPath] = useState("");
  const updateListItem = useCallback(
    (list: DictionaryListItem, path: string, value: DictionaryListItem) => {
      const item = path.split("->").reduce((acc: DictionaryListItem | undefined, current) => {
        const [listName, index] = current.split("::");
        const element = acc && acc.children ? acc.children[listName] : undefined;
        return element ? element[parseInt(index)] : undefined;
      }, list);

      if (item) {
        item.key = value.key;
        item.label = value.label;
        item.children = value.children;
      }
    },
    []
  );
  const addListItem = useCallback(
    (list: DictionaryListItem, path: string, currentIndex?: number) => {
      const segments = path.split("->");
      const targetList = segments.pop();
      const item = segments.reduce((acc: DictionaryListItem | undefined, current) => {
        const [listName, index] = current.split("::");
        const element = acc && acc.children ? acc.children[listName] : undefined;
        return element ? element[parseInt(index)] : undefined;
      }, list);

      if (item && targetList && item.children) {
        const itemChildren = item.children[targetList];
        // If the currentIndex is present, add the new item after the current index, instead of at the end
        if (currentIndex !== undefined && currentIndex >= 0 && itemChildren[currentIndex]) {
          itemChildren.splice(currentIndex + 1, 0, { key: "", label: "" });
        } else {
          itemChildren.push({ key: "", label: "" });
        }
      }
    },
    []
  );
  const removeListItem = useCallback((list: DictionaryListItem, path: string) => {
    const segments = path.split("->");
    const targetElement = segments.pop();
    const container = segments.reduce((acc: DictionaryListItem | undefined, current) => {
      const [listName, index] = current.split("::");
      const element = acc && acc.children ? acc.children[listName] : undefined;
      return element ? element[parseInt(index)] : undefined;
    }, list);

    if (container && container.children && targetElement) {
      const [listName, index] = targetElement.split("::");
      const newList = container.children[listName]
        .slice(0, parseInt(index))
        .concat(container.children[listName].slice(parseInt(index) + 1));
      container.children[listName] = newList;
    }
  }, []);

  const handleChange = useCallback(
    (path: string, value: DictionaryListItem) => {
      const updatedList = { ...list };
      updateListItem(
        {
          key: "root",
          label: "root",
          children: {
            [list.listSchema.name]: list.items,
          },
        },
        path,
        value
      );
      onChange(updatedList);
    },
    [list, onChange, updateListItem]
  );
  const handleAdd = useCallback(
    (path: string) => {
      const updatedList = { ...list };
      addListItem(
        {
          key: "root",
          label: "root",
          children: {
            [updatedList.listSchema.name]: updatedList.items,
          },
        },
        path
      );
      updatedList.items = refreshListItems(updatedList.items, updatedList.listSchema);
      onChange(updatedList);
    },
    [addListItem, list, onChange]
  );
  const handleEnterKey = useCallback(
    (path: string, currentIndex: number) => {
      const updatedList = { ...list };
      addListItem(
        {
          key: "root",
          label: "root",
          children: {
            [updatedList.listSchema.name]: updatedList.items,
          },
        },
        path,
        currentIndex
      );
      updatedList.items = refreshListItems(updatedList.items, updatedList.listSchema);
      onChange(updatedList);
    },
    [addListItem, list, onChange]
  );
  const handleRemove = useCallback(
    (path: string) => {
      const updatedList = { ...list };
      const segments = path.split("->");
      const targetElement = segments.pop();
      if (segments.length === 0) {
        if (targetElement && updatedList.items) {
          const [_, index] = targetElement.split("::");
          const newList = updatedList.items
            .slice(0, parseInt(index))
            .concat(updatedList.items.slice(parseInt(index) + 1));
          updatedList.items = newList;
        }
      } else {
        removeListItem(
          {
            key: "root",
            label: "root",
            children: {
              [updatedList.listSchema.name]: updatedList.items,
            },
          },
          path
        );
      }
      onChange(updatedList);
    },
    [list, onChange, removeListItem]
  );
  return (
    <Box display="flex" direction="column" color={colors.white}>
      <Box>
        <FocusFieldContext.Provider value={{ focusFieldPath, setFocusFieldPath }}>
          <DataListItems
            root={list}
            onChange={handleChange}
            onAdd={handleAdd}
            onRemove={handleRemove}
            onEnterKey={handleEnterKey}
            currentPath={list.listSchema.name}
          />
        </FocusFieldContext.Provider>
      </Box>
    </Box>
  );
};
