// @flow

import type { FormConnector, WebServiceConnector } from "../_lib/types";

import type { ConnectorToAssign } from "./AssignConnectors";

export function mergeConnectorsAndFormConnectors(
  connectors: Array<WebServiceConnector>,
  formConnectors: Array<FormConnector> = []
): Array<ConnectorToAssign> {
  const _formConnectors = formConnectors.slice(0);
  const connectorsToAssign = connectors.map((connector) => {
    const formConnector = pluck(_formConnectors, (fc) => fc.name === connector.name);

    if (!formConnector) {
      return {
        key: connector.name,
        name: connector.name,
        input: connector.inputMapping
          ? connector.inputMapping.map((im) => ({
              key: im.dataElement.name,
              name: im.dataElement.name,
            }))
          : [],
        output: [],
        isDataLink: false,
        shouldDisableOnData: true,
        shouldDisableOnNoData: false,
        noDataMessage: "",
      };
    }

    return {
      selected: true,
      key: formConnector.name,
      name: formConnector.name,
      input: formConnector.input
        ? formConnector.input.filter((i) => {
            if (connector.inputMapping) {
              return connector.inputMapping.some((im) => im.dataElement.name === i.name);
            }
            return false;
          })
        : [],
      output: formConnector.output.filter((o) => {
        if (connector.outputMapping) {
          return connector.outputMapping.some((om) => om.dataElement.name === o.name);
        }
        return false;
      }),
      isDataLink: false,
      shouldDisableOnData:
        formConnector.shouldDisableOnData == null ? true : formConnector.shouldDisableOnData,
      shouldDisableOnNoData:
        formConnector.shouldDisableOnNoData == null ? false : formConnector.shouldDisableOnNoData,
      noDataMessage: formConnector.noDataMessage || "",
    };
  });

  return connectorsToAssign;
}

function pluck<T>(
  array: T[],
  predicate: (value: T, index: number, obj: T[]) => boolean
): T | undefined {
  const index = array.findIndex(predicate);
  if (index > -1) {
    return array.splice(index, 1)[0];
  }
  return undefined;
}

export function stringReplaceAll(
  value: string,
  search: string,
  callback: string | ((part: string, i: number) => string)
) {
  const target = value;
  return target
    .split(search)
    .map(function (part, i, arr) {
      return i === arr.length - 1
        ? part
        : typeof callback === "string"
        ? callback
        : callback(part, i);
    })
    .join("");
}
