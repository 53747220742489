import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DeleteSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
  onClick,
}) => {
  const { colors } = useColors();

  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_342_17269)">
        <path
          d="M11.75 9.5C11.3522 9.5 10.9706 9.65804 10.6893 9.93934C10.408 10.2206 10.25 10.6022 10.25 11V12.5C10.25 12.8978 10.408 13.2794 10.6893 13.5607C10.9706 13.842 11.3522 14 11.75 14H12.5V27.5C12.5 28.2956 12.8161 29.0587 13.3787 29.6213C13.9413 30.1839 14.7044 30.5 15.5 30.5H24.5C25.2956 30.5 26.0587 30.1839 26.6213 29.6213C27.1839 29.0587 27.5 28.2956 27.5 27.5V14H28.25C28.6478 14 29.0294 13.842 29.3107 13.5607C29.592 13.2794 29.75 12.8978 29.75 12.5V11C29.75 10.6022 29.592 10.2206 29.3107 9.93934C29.0294 9.65804 28.6478 9.5 28.25 9.5H23C23 9.10218 22.842 8.72064 22.5607 8.43934C22.2794 8.15804 21.8978 8 21.5 8H18.5C18.1022 8 17.7206 8.15804 17.4393 8.43934C17.158 8.72064 17 9.10218 17 9.5H11.75ZM16.25 15.5C16.4489 15.5 16.6397 15.579 16.7803 15.7197C16.921 15.8603 17 16.0511 17 16.25V26.75C17 26.9489 16.921 27.1397 16.7803 27.2803C16.6397 27.421 16.4489 27.5 16.25 27.5C16.0511 27.5 15.8603 27.421 15.7197 27.2803C15.579 27.1397 15.5 26.9489 15.5 26.75V16.25C15.5 16.0511 15.579 15.8603 15.7197 15.7197C15.8603 15.579 16.0511 15.5 16.25 15.5V15.5ZM20 15.5C20.1989 15.5 20.3897 15.579 20.5303 15.7197C20.671 15.8603 20.75 16.0511 20.75 16.25V26.75C20.75 26.9489 20.671 27.1397 20.5303 27.2803C20.3897 27.421 20.1989 27.5 20 27.5C19.8011 27.5 19.6103 27.421 19.4697 27.2803C19.329 27.1397 19.25 26.9489 19.25 26.75V16.25C19.25 16.0511 19.329 15.8603 19.4697 15.7197C19.6103 15.579 19.8011 15.5 20 15.5V15.5ZM24.5 16.25V26.75C24.5 26.9489 24.421 27.1397 24.2803 27.2803C24.1397 27.421 23.9489 27.5 23.75 27.5C23.5511 27.5 23.3603 27.421 23.2197 27.2803C23.079 27.1397 23 26.9489 23 26.75V16.25C23 16.0511 23.079 15.8603 23.2197 15.7197C23.3603 15.579 23.5511 15.5 23.75 15.5C23.9489 15.5 24.1397 15.579 24.2803 15.7197C24.421 15.8603 24.5 16.0511 24.5 16.25V16.25Z"
          fill={calculateColor(color || colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_342_17269">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};
