export const baseUrl =
  process.env.NODE_ENV === "development" ? "http://localhost:8080/api" : "/api";

export const textControlUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : process.env.REACT_APP_TEXT_CONTROL_URL;

export const isProduction = process.env.NODE_ENV === "production";

export const fileSizeCap = process.env.FILE_SIZE_CAP;
