import { CSSProperties, FunctionComponent, PropsWithChildren } from "react";
import { ColorConfig, calculateColor } from "../utils";

import { shouldNever } from "@prodoctivity/shared";
import { useColors } from "./ColorSchemeProvider";

export type TextSize = "100" | "200" | "300" | "400" | "500" | "600";

function textAlign(align: TextProps["align"]) {
  if (!align) {
    return undefined;
  }

  switch (align) {
    case "center":
      return "center";
    case "start":
      return "left";
    case "end":
      return "right";
    case "justify":
      return "justify";
    default:
      shouldNever(align);
      return undefined;
  }
}

function textOverflow(overflow: TextProps["overflow"]) {
  if (!overflow) {
    return undefined;
  }

  switch (overflow) {
    case "breakWord":
      return "break-word";
    case "ellipsis":
      return "ellipsis";
    default:
      shouldNever(overflow);
      return undefined;
  }
}

function computeLineClamp(lineClamp: TextProps["lineClamp"]): Partial<CSSProperties> {
  if (!lineClamp) {
    return {};
  }

  // .lineClamp {
  //   -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  //   display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  //   max-width: 100%;
  //   overflow: hidden;
  //   word-break: break-word;
  // }
  return {
    maxWidth: "100%",
    overflow: "hidden",
    wordBreak: "break-word",
    lineClamp: lineClamp,
    WebkitLineClamp: lineClamp,
  };
}

export type TextProps = PropsWithChildren<{
  color?: ColorConfig;

  customClassName?: string;

  align?: "center" | "start" | "end" | "justify";

  decoration?: "line-through" | "underline";

  overflow?: "breakWord" | "ellipsis";

  textTransform?: CSSProperties["textTransform"];

  wrap?: boolean;

  inline?: boolean;

  lineClamp?: number;

  size?: TextSize;
  /**
   * This populates the `title` attribute of the element, which is visible on hover in most browsers. This is useful when truncating the text with `lineClamp` when `children` is a `React.Node`. See the [Title variant](https://gestalt.pinterest.systems/web/text#Title) for more details.
   */
  title?: string;

  weight?: "bold" | "normal";

  ellipsisLength?: number;
}>;

export const Text: FunctionComponent<TextProps> = ({
  children,
  color: rawColor,
  customClassName,
  align,
  overflow,
  lineClamp,
  inline = false,
  size = 300,
  title: rawTitle,
  textTransform,
  weight = "normal",
  ellipsisLength,
  decoration,
  wrap,
}) => {
  const { colors } = useColors();
  const color = rawColor ? rawColor : colors.black600;
  const computedColor = calculateColor(color);

  const Tag = inline ? "span" : "div";

  const normalStyle: CSSProperties = {
    color: computedColor,
    fontSize: `var(--font-size-${size})`,
    fontWeight: weight,
    textDecoration: decoration,
    textAlign: textAlign(align),
    textTransform: textTransform,
    overflow: textOverflow(overflow),
    ...(overflow === "ellipsis"
      ? {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }
      : {}),
    ...(wrap
      ? {
          textWrap: "wrap",
        }
      : {}),
  };

  const style = lineClamp ? { ...normalStyle, ...computeLineClamp(lineClamp) } : normalStyle;

  const title =
    rawTitle ||
    (typeof children === "string" && ellipsisLength && children.length > ellipsisLength
      ? children
      : undefined);

  return (
    <Tag className={customClassName || undefined} style={style} title={title || undefined}>
      {typeof children === "string" && ellipsisLength && children.length > ellipsisLength
        ? truncateString(children, ellipsisLength)
        : children}
    </Tag>
  );
};

export function truncateString(str: string, n: number) {
  if (str) {
    return str.length > n ? str.slice(0, n - 3) + "..." : str;
  }
}
