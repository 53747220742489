import { Box, Button, TapArea, Text, useColors } from "@prodoctivity/design-system";
import { FC, useCallback, useMemo, useState } from "react";

import { IconButton } from "@prodoctivity/design-system/components/Icon";
import type { UserNotification } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { NotificationItems } from "../../components/Notification/Notifications";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";

function initNotificationsMap() {
  return new Map<string, { notification: UserNotification; pageNumber: number }>();
}

const NotificationsPage: FC = () => {
  const { colors } = useColors();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { getNotifications, markNotificationAsRead, removeNotifications } = useServices();
  const [notificationsMap, setNotificationsMap] =
    useState<Map<string, { notification: UserNotification; pageNumber: number }>>(
      initNotificationsMap
    );

  const notifications = useMemo(() => {
    return Array.from(notificationsMap.values());
  }, [notificationsMap]);

  const headerGetNotifications = useCallback(
    async (pageNumbers: number[]) => {
      const result = await Promise.all(
        pageNumbers.map((pageNumber) => {
          return getNotifications(pageNumber);
        })
      );
      return result;
    },
    [getNotifications]
  );

  const { mutate: fetchNotifications, isLoading } = useMutation(headerGetNotifications, {
    onSuccess(data) {
      const thisMap = new Map<string, { notification: UserNotification; pageNumber: number }>(
        notificationsMap
      );
      const newMap = data.reduce((pageData, nextPage) => {
        nextPage.notifications.reduce((acc, next) => {
          if (!acc.has(next.id)) {
            acc.set(next.id, { notification: next, pageNumber: nextPage.pageNumber });
          }

          return acc;
        }, pageData);
        return pageData;
      }, thisMap);
      setNotificationsMap(newMap);
    },
  });

  const refetchAll = useCallback(() => {
    const pages = new Set<number>();
    notificationsMap.forEach((entry) => {
      pages.add(entry.pageNumber);
    });
    setTimeout(() => {
      fetchNotifications(Array.from(pages.values()));
    }, 2000);
  }, [fetchNotifications, notificationsMap]);

  useEffect(() => {
    fetchNotifications([pageNumber]);
  }, [fetchNotifications, pageNumber]);

  const [notificationsSelected, setNotificationsSelected] = useState<UserNotification[]>([]);

  const onMarkAsRead = useCallback(
    (notificationsIdList: Array<string>) => {
      markNotificationAsRead(notificationsIdList).then((_res) => {
        refetchAll();
      });
    },
    [markNotificationAsRead, refetchAll]
  );

  const onMarkAllAsRead = useCallback(() => {
    if (notifications && notifications.length > 0) {
      const notificationsIdList = notifications.map((n) => n.notification.id);
      onMarkAsRead(notificationsIdList);
    }
  }, [notifications, onMarkAsRead]);

  const onMarkSelectedAsRead = useCallback(() => {
    if (notificationsSelected && notificationsSelected.length > 0) {
      const notificationsIdList = notificationsSelected.map((n) => n.id);
      onMarkAsRead(notificationsIdList);
      setNotificationsSelected([]);
    }
  }, [notificationsSelected, onMarkAsRead]);

  const onRemoveNotifications = useCallback(
    (notificationsIdList: Array<string>) => {
      removeNotifications(notificationsIdList).then((_res) => {
        refetchAll();
      });
    },
    [refetchAll, removeNotifications]
  );

  const onRemoveSelected = useCallback(() => {
    if (notificationsSelected && notificationsSelected.length > 0) {
      const notificationsIdList = notificationsSelected.map((n) => n.id);
      onRemoveNotifications(notificationsIdList);
      setNotificationsSelected([]);
    }
  }, [notificationsSelected, onRemoveNotifications]);

  const onLoadMore = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);

  const onClickItemSelect = (notification: UserNotification) => {
    const actualSelected: UserNotification[] = JSON.parse(JSON.stringify(notificationsSelected));

    const selectedIndex = actualSelected.findIndex((n) => n.id === notification.id);
    if (selectedIndex >= 0) {
      actualSelected.splice(selectedIndex, 1);
      setNotificationsSelected(actualSelected);
      return;
    }
    actualSelected.push(notification);
    setNotificationsSelected(actualSelected);
  };
  const { resources } = useAppTranslation();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "text", name: resources.notifications.notifications },
    ];
  }, [resources.home, resources.notifications.notifications]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box width={"100%"} color={colors.neutral100}>
        <Box paddingX={12}>
          <Box paddingX={12}>
            <Box paddingX={12}>
              <Box paddingX={8} paddingY={8}>
                <Box marginStart={3} marginEnd={3} justifyContent="between" display="flex">
                  <Text size="400" weight="bold">
                    {resources.notifications.notifications}
                  </Text>
                  <IconButton
                    icon="arrows-rotate"
                    accessibilityLabel={resources.refresh}
                    onClick={refetchAll}
                    disabled={isLoading}
                  />
                  {notificationsSelected.length === 0 ? (
                    <TapArea fullWidth={false} role="button" onTap={onMarkAllAsRead}>
                      <Text size="200" color={colors.primary} align="end">
                        {resources.notifications.markAllAsRead}
                      </Text>
                    </TapArea>
                  ) : (
                    <Box display="flex">
                      <TapArea fullWidth={false} role="button" onTap={onMarkSelectedAsRead}>
                        <Text size="200" color={colors.primary} align="end">
                          {resources.notifications.markAsRead}
                        </Text>
                      </TapArea>
                      &nbsp;&nbsp;|&nbsp;&nbsp;
                      <TapArea fullWidth={false} role="button" onTap={onRemoveSelected}>
                        <Text size="200" color={colors.primary} align="end">
                          {resources.delete_}
                        </Text>
                      </TapArea>
                    </Box>
                  )}
                </Box>

                <Box rounding={1} width="100%" borderStyle="lg" marginTop={3} display="inlineBlock">
                  <NotificationItems
                    isLoading={isLoading}
                    notifications={notifications || []}
                    notificationsSelected={notificationsSelected}
                    refetchAll={refetchAll}
                    onClickItemSelect={onClickItemSelect}
                    onMarkAsRead={onMarkAsRead}
                    removeNotifications={removeNotifications}
                    isFullPage={true}
                    setNotificationsMap={setNotificationsMap}
                  />
                </Box>
                <Box marginTop={5} width="100%" display="flex" justifyContent="center">
                  <Box rounding={2} borderStyle="lg">
                    <Button
                      color="transparent"
                      size="md"
                      accessibilityLabel={resources.loadMore}
                      text={resources.loadMore}
                      disabled={isLoading}
                      onClick={onLoadMore}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default NotificationsPage;
