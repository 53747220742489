"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataElement$Schema = exports.DataElementOutput$Schema = exports.DataElementInput$Schema = exports.ConditionalStyle$Schema = exports.ControlLayout$Schema = exports.DictionaryDataType$Schema = void 0;
const zod_1 = require("zod");
const dictionary_1 = require("../../dictionary");
const fluency_shared_1 = require("../../fluency-shared");
const index_types_1 = require("../../index-types");
const logical_1 = require("../../logical");
exports.DictionaryDataType$Schema = zod_1.z.enum([
    "None",
    "Alphanumeric",
    "IntegerNumber",
    "DecimalNumber",
    "DateTime",
    "Date",
    "Time",
    "Boolean",
    "Image",
    "ElectronicSignature",
    "List",
]);
exports.ControlLayout$Schema = zod_1.z.enum(["Horizontal", "Vertical"]);
const GraphicsUnit$Schema = zod_1.z.nativeEnum(index_types_1.GraphicsUnit);
const FontStyle$Schema = zod_1.z.nativeEnum(index_types_1.FontStyle);
const HighlightColorValue$Schema = zod_1.z.nativeEnum(index_types_1.HighlightColorValue);
const Style$Schema = zod_1.z.strictObject({
    name: zod_1.z.string(),
    fontName: zod_1.z.string(),
    fontStyle: FontStyle$Schema,
    graphicsUnit: GraphicsUnit$Schema,
    size: zod_1.z.number().optional(),
    fontColorAsArgb: zod_1.z.number(),
    backgroundColor: HighlightColorValue$Schema,
});
exports.ConditionalStyle$Schema = zod_1.z.strictObject({
    name: zod_1.z.string(),
    expression: logical_1.LogicalExpression$Schema,
    style: Style$Schema,
});
exports.DataElementInput$Schema = zod_1.z.strictObject({
    controlSize: zod_1.z.enum(["None", "Small", "Normal", "Large"]).optional(),
    controlLayout: exports.ControlLayout$Schema.optional(),
});
const DataElementOrderType$Schema = zod_1.z.nativeEnum(dictionary_1.DataElementOrderType);
const DataElementListSeparator$Schema = zod_1.z.nativeEnum(dictionary_1.DataElementListSeparator);
const DataElementPenultimateSeparator$Schema = zod_1.z.nativeEnum(dictionary_1.DataElementPenultimateSeparator);
const DataElementFinalizer$Schema = zod_1.z.nativeEnum(dictionary_1.DataElementFinalizer);
exports.DataElementOutput$Schema = zod_1.z.strictObject({
    outputFormat: zod_1.z.string(),
    conditionalStyles: exports.ConditionalStyle$Schema.array().optional(),
    dataElementOrderType: DataElementOrderType$Schema.optional(),
    dataElementListSeparator: DataElementListSeparator$Schema.optional(),
    dataElementPenultimateSeparator: DataElementPenultimateSeparator$Schema.optional(),
    dataElementFinalizer: DataElementFinalizer$Schema.optional(),
});
exports.DataElement$Schema = zod_1.z
    .object({})
    .passthrough()
    .superRefine((dataElement, ctx) => {
    const parsedHeader = DataElementHeader$Schema.safeParse(dataElement);
    if (!parsedHeader.success) {
        parsedHeader.error.issues.forEach((issue) => ctx.addIssue(issue));
        return zod_1.z.NEVER;
    }
    const parsedAll = fluency_shared_1.ContextFieldProperties$Schema.safeParse(dataElement);
    if (!parsedAll.success) {
        parsedAll.error.issues.forEach((issue) => ctx.addIssue(issue));
        return zod_1.z.NEVER;
    }
})
    .transform((arg) => {
    return arg;
});
const DataElementHeader$Schema = zod_1.z
    .object({
    name: zod_1.z.string().min(1),
    dictionaryDataType: exports.DictionaryDataType$Schema,
    unique: zod_1.z.boolean(),
    cultureLanguageName: zod_1.z.string(),
    sectionName: zod_1.z.string(),
    isReferenceField: zod_1.z.boolean(),
    readOnly: zod_1.z.boolean(),
    dictionaryListName: zod_1.z.string().min(1).optional(),
    alternativeQuestions: zod_1.z.string().array(),
    input: exports.DataElementInput$Schema.optional(),
    output: exports.DataElementOutput$Schema.optional().catch(undefined),
})
    .strip();
