import { Box, Divider, OverlayPanel, TapArea, Text, Tooltip } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { CommentsLayout } from "@prodoctivity/design-system/components/CommentsLayout/CommentsLayout";
import { DetailListLabels, useTaskDetailsPanel } from "./hooks";
import { PublicAvatarImage } from "../Avatar/Avatar";
import { PublicProfile } from "../Profile/Public/PublicProfile";
import { Pagination } from "../Layout/Pagination";
import { ClipboardSvgIcon } from "../../svg/ClipboardSvgIcon";
import { NotificationMessage } from "../NotificationMessage";
import type { WorkflowTaskActions } from "@prodoctivity/shared/src/index-types";

type Props = {
  taskSelected: {
    taskId: string;
    processId: string;
    taskStageName: string | undefined;
  };
  onCloseSheet: () => void;
};

function formatTaskPropertyValue(
  resources: {
    completed: string;
    inProgress: string;
    normal: string;
    high: string;
  },
  key: string,
  value: string | number | boolean | WorkflowTaskActions[] | undefined
) {
  if (typeof value === "string") {
    if (key === "username") {
    }
    if (key === "state") {
      return value === "done" ? resources.completed : resources.inProgress;
    }
    return value;
  }

  if (typeof value === "number") {
    if (key === "workflowTaskPriority") {
      return value === 1 ? resources.normal : resources.high;
    }
    const date = new Date(value);
    const formattedDate = date
      .toLocaleString("en-US", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "");
    return formattedDate;
  }

  if (Array.isArray(value)) {
    return value
      .map((action) => {
        if (action.type === "workflow-keys") {
          return action.key;
        }
        return undefined;
      })
      .filter((key) => key !== undefined)
      .join(", ");
  }
}

export const TaskDetailsPanel: FunctionComponent<Props> = ({ taskSelected, onCloseSheet }) => {
  const {
    user,
    userProfile,
    colors,
    taskDetails,
    detailListLabels,
    isLoading,
    updateCommentList,
    addComment,
    resources,
    moment,
    comments,
    isLoadingComments,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    pageLengthOptions,
    commentsTotal,
    refetchComments,
    toastMessage,
    copyToClipboard,
    hasClipboard,
    handleCopyToClipBoard,
    setToastMessage,
  } = useTaskDetailsPanel(taskSelected);

  return (
    <OverlayPanel
      heading={resources.workflow.processDetails}
      onDismiss={onCloseSheet}
      size="md"
      accessibilityLabel={resources.workflow.processDetails}
    >
      <Box>
        {!isLoading && taskDetails && (
          <Box>
            <Box
              display="flex"
              paddingX={6}
              paddingY={3}
              direction="column"
              marginBottom={6}
              gap={2}
            >
              <Text weight="bold" color={colors.black600}>
                {` ${taskDetails.workflowTask.collectionName}`}
              </Text>
              <Text color={colors.neutral900}>{taskDetails.workflowTask.instruction}</Text>
            </Box>
            <Box
              display="flex"
              direction="column"
              paddingRight={10}
              paddingLeft={6}
              paddingBottom={8}
            >
              {Object.entries(taskDetails.workflowTask).map(([key, value]) => {
                if (!detailListLabels.hasOwnProperty(key)) {
                  return null;
                }
                const label = detailListLabels[key as keyof DetailListLabels];
                const valueIsAnId =
                  key === "id" || key === "workflowProcessId" || key === "processId";
                return (
                  <Box
                    key={`${key}-${value}`}
                    display="flex"
                    direction="column"
                    justifyContent="center"
                    maxHeight={50}
                    paddingY={4}
                    marginBottom={4}
                    gap={1}
                  >
                    <Text weight="bold" color={colors.black600}>
                      {label}
                    </Text>
                    <Box display="flex" gap={2}>
                      <Text color={colors.neutral900}>
                        {key === "username"
                          ? userProfile?.firstName + " " + userProfile?.lastName
                          : formatTaskPropertyValue(resources, key, value)}
                      </Text>
                      {valueIsAnId && typeof value === "string" && hasClipboard && (
                        <Box marginStart={3}>
                          <TapArea onTap={() => copyToClipboard(value)}>
                            <Tooltip text={resources.copyIdToClipboard}>
                              <ClipboardSvgIcon
                                color={colors.primary}
                                width={21}
                                height={21}
                                onClick={() => handleCopyToClipBoard()}
                              />
                              {toastMessage && (
                                <NotificationMessage
                                  type={toastMessage?.type}
                                  message={toastMessage.message}
                                  position="bottom-left"
                                  handleDismiss={() => setToastMessage(undefined)}
                                  delay={3000}
                                />
                              )}
                            </Tooltip>
                          </TapArea>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box
              display="flex"
              direction="column"
              paddingLeft={6}
              paddingRight={10}
              paddingBottom={8}
            >
              <Divider direction="horizontal"></Divider>
            </Box>
            <Box display="flex" direction="column" paddingLeft={4} paddingY={4} paddingRight={8}>
              <CommentsLayout
                taskSelected={taskSelected}
                data={comments?.taskComments}
                commentsTotal={commentsTotal}
                isLoadingComments={isLoadingComments}
                onAddComment={addComment}
                onCommentUpdate={updateCommentList}
                myPublicAvatarImageComponent={
                  <PublicAvatarImage username={user?.email || ""} size={32}></PublicAvatarImage>
                }
                PublicProfileComponent={PublicProfile}
                OtherUsersPublicAvatarImage={PublicAvatarImage}
                resources={resources}
                moment={moment}
                refetchComments={refetchComments}
              ></CommentsLayout>
              <Box display="flex" direction="row" justifyContent="end" width={"100%"}>
                <Box>
                  <Pagination<typeof rowsPerPage>
                    id="task-details-pagination"
                    rowsLabel={`${resources.comments}`}
                    currentPage={currentPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageLength={comments?.taskComments.length || 0}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setPageLength}
                    isNextButtonDisabled={isNextButtonDisabled}
                    isPreviousButtonDisabled={isPreviousButtonDisabled}
                    pageLengthOptions={pageLengthOptions}
                    totalRowCount={commentsTotal}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </OverlayPanel>
  );
};
