import { FunctionComponent } from "react";
import { ColorConfig } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const DashboardContentViewIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  // color: rawColor,
  onClick,
}) => {
  // const { colors } = useColors();
  // const color = rawColor || colors.neutral600;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M29.2221 29.5625H16.7021V15.5475H29.5621V29.2225C29.5621 29.411 29.4106 29.5625 29.2221 29.5625ZM29.5621 9.9025V12.8675H16.7021V9.5625H29.2221C29.4106 9.5625 29.5621 9.71402 29.5621 9.9025Z"
        fill="#2650A3"
        stroke="#2650A3"
      />
      <path
        d="M9.5625 29.2225V9.9025C9.5625 9.71402 9.71402 9.5625 9.9025 9.5625H14.0225V29.5625H9.9025C9.71402 29.5625 9.5625 29.411 9.5625 29.2225Z"
        fill="#7993C6"
        stroke="#7993C6"
      />
    </svg>
  );
};
