import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const AddFolderIconSvg: FunctionComponent<Props> = ({
  color,
  width = 42,
  height = 27,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="42"
      height="27"
      viewBox="0 0 42 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6352_101628)">
        <path
          d="M31.8046 21.7943C31.9863 21.7943 32.1862 21.7943 32.3679 21.7583L30.8413 26.1892C30.6778 26.6936 30.496 26.9998 29.8599 26.9998C20.9547 26.9637 12.0495 26.9998 3.14423 26.9998C2.99884 26.9998 2.85345 26.9457 2.61719 26.9097C3.48954 24.388 4.32554 21.9024 5.17971 19.3987C5.79763 17.6155 6.41554 15.8323 7.03346 14.0491C7.85128 11.6715 9.59598 10.4287 12.0858 10.4287H20.0097C20.046 11.8336 20.3368 13.2746 20.8638 14.5354C21.3 15.5982 21.8997 16.5888 22.6085 17.4714C24.8803 20.2272 28.2243 21.8303 31.7864 21.8303L31.8046 21.7943Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M22.5357 2.71985H22.7174C22.3358 3.15214 22.0087 3.63846 21.7179 4.12479H2.61705V5.4937C2.61705 10.2128 2.70792 14.914 2.56253 19.6331C2.508 21.3443 1.83557 23.0374 1.39939 24.8386C0.290783 24.3703 0 23.7218 0 22.9293C0 16.0307 0 9.11411 0 2.21551C0.0363479 0.990696 0.672436 0.360276 1.92644 0.360276C4.65253 0.324252 7.3968 0.30624 10.1229 0.360276C10.5954 0.360276 11.2133 0.828588 11.4859 1.26088C12.2311 2.43166 13.1761 2.7919 14.5573 2.73786C17.2107 2.68382 19.8641 2.68382 22.5357 2.70184V2.71985Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M4.9248 6.03418H20.7907C20.6089 6.44846 20.4817 6.86273 20.3727 7.31303C20.3727 7.34906 20.3727 7.36707 20.3727 7.38508H6.19698C6.19698 9.52851 6.46959 11.762 4.9248 13.5812V6.03418Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M31.804 0C29.1688 0 26.7153 1.02668 24.8615 2.71981C24.8252 2.75584 24.7888 2.77385 24.7525 2.80987L24.4981 3.06204C24.1709 3.38626 23.8983 3.7465 23.6257 4.12475C23.1532 4.71915 22.7897 5.33155 22.4989 6.03402C22.299 6.48432 22.1173 6.91661 22.0082 7.38492C21.9719 7.47498 21.9537 7.60107 21.9355 7.69113C21.7174 8.44763 21.6084 9.24016 21.6084 10.1227V10.4109C21.6629 11.5997 21.8992 12.8245 22.3535 13.9233C22.717 14.8239 23.2441 15.6704 23.8801 16.445C25.8429 18.8586 28.7144 20.2275 31.804 20.2275C32.1856 20.2275 32.5491 20.2095 32.9308 20.1554C38.0376 19.6151 41.9996 15.3282 41.9996 10.1227C41.9996 4.55704 37.4379 0 31.804 0ZM37.7105 11.1134H33.058V15.9226H30.55V11.1134H25.9156V8.51968H30.55V3.71047H33.058V8.51968H37.7105V11.1134Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6352_101628">
          <rect width="42" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
