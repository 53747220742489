import { isError } from "@prodoctivity/shared";
import type { HttpGetDocumentTypeListRequest } from "@prodoctivity/types";
import { useCallback } from "react";
import { useOrganizationQuery } from "./useOrganizationQuery";
import { useServices } from "./useServices";

export function useDataElement(name: string) {
  const { getDataElement } = useServices();

  const fetchDataElement = useCallback(async () => {
    try {
      const r = await getDataElement(name);
      return r;
    } catch (err: unknown) {
      if (isError(err)) {
        console.log(err);
      }
    }
  }, [getDataElement, name]);

  const dataElement = useOrganizationQuery(`get-data-element/${name}`, fetchDataElement, {
    retry: 3,
    staleTime: 1000 * 60,
  });
  return dataElement;
}

export function useTemplateInfo(templateVersionId?: string) {
  const { fetchTemplateInfo } = useServices();
  const templateInfo = useCallback(async () => {
    if (templateVersionId) {
      return fetchTemplateInfo(templateVersionId);
    }
    return { info: undefined };
  }, [fetchTemplateInfo, templateVersionId]);
  const info = useOrganizationQuery(`template-version-info/${templateVersionId}`, templateInfo, {
    retry: false,
    staleTime: 2 * 60 * 1000,
    cacheTime: 2 * 60 * 1000,
  });
  return info;
}

export function useCombinedTemplateInfo(id: string) {
  const { getCombinedTemplate } = useServices();
  const fetchCombinedTemplate = useCallback(async () => {
    if (id) {
      return getCombinedTemplate(id);
    }
    return {
      id: undefined,
      combinedTemplate: undefined,
    };
  }, [getCombinedTemplate, id]);
  const info = useOrganizationQuery(`/combined-templates/${id}`, fetchCombinedTemplate);
  return info;
}

export function useDistributionConfig(distributionConfigId: string) {
  const { getDistribution } = useServices();
  return useOrganizationQuery(
    `get-distribution-details/${distributionConfigId}`,
    () => {
      return getDistribution(distributionConfigId);
    },
    { staleTime: 1000 * 120 }
  );
}

export function useDatalinkVersionConfig(datalinkId: string, _datalinkVersionConfig: string) {
  const { getDataLinkConfig } = useServices();
  return useOrganizationQuery(`get-datalink-config-details/${datalinkId}`, () => {
    return getDataLinkConfig(datalinkId, _datalinkVersionConfig);
  });
}

export function useTemplateVersionList(templateVersionId: string) {
  const { getTemplateVersionList } = useServices();
  return useOrganizationQuery(`template-version-list/${templateVersionId}`, () => {
    if (templateVersionId) {
      return getTemplateVersionList(templateVersionId);
    } else {
      return Promise.resolve({
        templateVersions: [],
      });
    }
  });
}

export function useUserPublicProfile(username: string, evictUserListCache?: boolean) {
  const { getUserPublicProfile } = useServices();
  return useOrganizationQuery(
    `user-public-profile/${username}`,
    () =>
      getUserPublicProfile(username).then(
        ({ user }) => user,
        () => null
      ),
    {
      enabled: !!username,
      staleTime: evictUserListCache ? 0 : 10 * 60 * 1000,
      cacheTime: evictUserListCache ? 0 : undefined,
      retry: false,
    }
  );
}

export function useDocumentGroupList(isContentLibrary: boolean | undefined) {
  const { getAllDocumentGroups } = useServices();
  return useOrganizationQuery(`document-groups/${isContentLibrary}`, () =>
    getAllDocumentGroups(isContentLibrary).then(({ documentGroups }) => documentGroups)
  );
}

export function useDocumentTypeList(
  pageNumber: HttpGetDocumentTypeListRequest["queryParameters"]["pageNumber"],
  rowsPerPage: HttpGetDocumentTypeListRequest["queryParameters"]["rowsPerPage"]
) {
  const { getDocumentTypeList } = useServices();
  return useOrganizationQuery(`document-types`, () =>
    getDocumentTypeList(pageNumber, rowsPerPage, undefined, undefined).then(
      ({ documentTypes }) => documentTypes
    )
  );
}

export function useGetAllOrganizationRoles() {
  const { getAllOrganizationRoles } = useServices();
  return useOrganizationQuery(`organization-roles/all`, () =>
    getAllOrganizationRoles().then(({ roles }) => roles)
  );
}

export function useDocumentGroupById(documentGroupId: string) {
  const { getDocumentGroup } = useServices();
  const documentGroupInfo = useCallback(async () => {
    if (documentGroupId) {
      return getDocumentGroup(documentGroupId);
    }
    return {
      documentGroup: undefined,
    };
  }, [getDocumentGroup, documentGroupId]);

  return useOrganizationQuery(`document-group/${documentGroupId}`, documentGroupInfo, {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: "always",
  });
}

export const useApprovalType = (approvalTypeId: string) => {
  const { getApprovalType } = useServices();
  const fetchApprovalType = useCallback(() => {
    return getApprovalType(approvalTypeId);
  }, [approvalTypeId, getApprovalType]);

  const { data, isLoading, refetch } = useOrganizationQuery(
    `approval-type/${approvalTypeId}`,
    fetchApprovalType
  );
  return {
    approvalType: data?.approvalType,
    isLoading,
    refetch,
  };
};

export const useAllApprovalTypes = () => {
  const { getApprovalTypes } = useServices();

  const {
    data: approvalTypesResponse,
    isLoading,
    refetch,
  } = useOrganizationQuery(
    `settings_approve_type_list/page_0_500_`,
    () => getApprovalTypes(0, "500", undefined),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: "always",
    }
  );
  return {
    approvalTypesResponse,
    isLoading,
    refetch,
  };
};
export const useDetectOs = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os: "Mac OS" | "iOS" | "Windows" | "Android" | "Linux" | null = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }
  return {
    os,
  };
};
