import type { DocumentTypeSubscriptionType } from "@prodoctivity/types";
import type { TemplateSubscriptionType } from "@prodoctivity/shared/src/index-types";

export type DocumentTypesInitialState = {
  documentTypeId: string;
  documentTypeName: string;
  subscriptionType: DocumentTypeSubscriptionType;
  action: "subscribe" | "unsubscribe";
  sendToWeb: boolean;
  sendToMobile: boolean;
}[];

export type TemplatesInitialState = {
  templateId: string;
  templateName: string;
  subscriptionType: TemplateSubscriptionType;
  action: "subscribe" | "unsubscribe";
  sendToWeb: boolean;
  sendToMobile: boolean;
}[];

/* ---------------------------- #TEMPLATES region ---------------------------- */

export const templateExtractUniqueElements = (subscriptions: TemplatesInitialState) => {
  return subscriptions.reduce((uniqueElements: TemplatesInitialState, subscription) => {
    if (!uniqueElements.some((elem) => elem.templateId === subscription.templateId)) {
      uniqueElements.push(subscription);
    }
    return uniqueElements;
  }, []);
};

/* ---------------------------- #DOCUMENT TYPES region ---------------------------- */

export const documentTypeExtractUniqueElements = (subscriptions: DocumentTypesInitialState) => {
  return subscriptions.reduce((uniqueElements: DocumentTypesInitialState, subscription) => {
    if (!uniqueElements.some((elem) => elem.documentTypeId === subscription.documentTypeId)) {
      uniqueElements.push(subscription);
    }
    return uniqueElements;
  }, []);
};
