import { useColors } from "@prodoctivity/design-system";
import type {
  DocumentCollectionConfigValidation,
  DocumentCollectionInvalidExpressionConfig,
} from "@prodoctivity/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import { FolderValidationListProps } from "./FolderValidation";

const emptyInvalidExpressionConfig: DocumentCollectionInvalidExpressionConfig = {
  addEntry: false,
  icon: "folder",
  name: "",
  documentTypeId: "",
};

export type FolderValidationRowProps = Omit<
  FolderValidationListProps,
  "validationListLength" | "deleteFolderValidation"
> & {
  validation: DocumentCollectionConfigValidation;
};

export const useFolderValidationRow = ({
  currIndex,
  validation,
  onSingleValidationFolderChange,
  tI18n,
  mergedContextDefinition,
  expressionDataInput,
  getConditionText,
  setConfigItem,
  onFilterChange,
  filter,
  isLoading,
  folderDocumentTypesOptions,
  iconOptions,
  setIsFolderChangesButtonDisabled,
}: FolderValidationRowProps) => {
  // ------------------ IMPORTS ------------------

  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  // ------------------ VARIABLES ------------------

  const onExpressionChange = onSingleValidationFolderChange(currIndex);
  const invalidExpressionConfig: DocumentCollectionInvalidExpressionConfig | undefined =
    useMemo(() => {
      if (validation.invalidExpressionConfig) {
        return validation.invalidExpressionConfig;
      }
      return undefined;
    }, [validation.invalidExpressionConfig]);

  // ------------------ STATES ------------------

  const [openInvalidExpressionConfig, setOpenInvalidExpressionConfig] = useState<boolean>(
    invalidExpressionConfig && invalidExpressionConfig.name.trim() !== "" ? true : false
  );
  const [validationNameState, setValidationNameState] = useState<string>(validation.name);
  const [invalidExpressionName, setInvalidExpressionName] = useState<string>(
    invalidExpressionConfig ? invalidExpressionConfig.name : ""
  );
  const [validationDescriptionState, setValidationDescriptionState] = useState<string>(
    validation.description
  );
  const [validationDebouncedName] = useDebounceValue(validationNameState, 500);
  const [validationDebouncedDescription] = useDebounceValue(validationDescriptionState, 500);
  const [invalidExpressionDebouncedName] = useDebounceValue(invalidExpressionName, 500);

  // ------------------ METHODS ------------------

  const updateValidation = useCallback(
    (
      field: "name" | "description" | "invalidExpressionConfig",
      value: string | boolean | DocumentCollectionInvalidExpressionConfig | undefined,
      subField?: "name" | "icon" | "addEntry" | "documentTypeId"
    ) => {
      setConfigItem((prev) => {
        if (!prev) return prev;
        const updatedItem = { ...prev.updatedItem };
        if (updatedItem.type === "folder") {
          if (field !== "invalidExpressionConfig" && typeof value === "string") {
            updatedItem.item.validations[currIndex][field] = value;
          } else if (subField && invalidExpressionConfig) {
            updatedItem.item.validations[currIndex].invalidExpressionConfig = {
              ...invalidExpressionConfig,
              [subField]: value,
            };
          } else if (typeof value !== "boolean" && typeof value !== "string") {
            updatedItem.item.validations[currIndex].invalidExpressionConfig = value;
          }
        }
        return {
          ...prev,
          updatedItem: updatedItem,
        };
      });
    },
    [currIndex, invalidExpressionConfig, setConfigItem]
  );

  useEffect(() => {
    if (validation.name !== validationDebouncedName) {
      updateValidation("name", validationDebouncedName);
    }

    if (validation.description !== validationDebouncedDescription) {
      updateValidation("description", validationDebouncedDescription);
    }

    if (
      invalidExpressionConfig &&
      invalidExpressionConfig.name !== invalidExpressionDebouncedName
    ) {
      updateValidation("invalidExpressionConfig", invalidExpressionDebouncedName, "name");
    }
  }, [
    currIndex,
    invalidExpressionConfig,
    invalidExpressionDebouncedName,
    invalidExpressionName,
    setConfigItem,
    updateValidation,
    validation.description,
    validation.name,
    validationDebouncedDescription,
    validationDebouncedName,
  ]);

  const onValidationChange = useCallback(
    (field: "name" | "description" | "invalidExpressionName", { value }: { value: string }) => {
      if (field === "name") {
        setValidationNameState(value);
        setIsFolderChangesButtonDisabled(value === "" ? true : false);
      } else if (field === "description") {
        setValidationDescriptionState(value);
      } else if (field === "invalidExpressionName") {
        setInvalidExpressionName(value);
        setIsFolderChangesButtonDisabled(invalidExpressionConfig && value === "" ? true : false);
      }
    },
    [invalidExpressionConfig, setIsFolderChangesButtonDisabled]
  );

  return {
    currIndex,
    resources,
    validationNameState,
    onValidationChange,
    validationDescriptionState,
    openInvalidExpressionConfig,
    setOpenInvalidExpressionConfig,
    updateValidation,
    setInvalidExpressionName,
    invalidExpressionConfig,
    isLoading,
    folderDocumentTypesOptions,
    getConditionText,
    expressionDataInput,
    mergedContextDefinition,
    validation,
    onExpressionChange,
    moment,
    tI18n,
    onFilterChange,
    filter,
    iconOptions,
    colors,
    emptyInvalidExpressionConfig,
    invalidExpressionName,
    setConfigItem,
    setIsFolderChangesButtonDisabled,
  };
};
