import { Box, BoxWithRef, InboxAccordion, Skeleton, Text } from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useProcessAccordion } from "./hooks";
import { Pagination } from "../Layout/Pagination";
import { range } from "@prodoctivity/shared";
import { TemplateEntry } from "./TaskTypeRow/TemplateEntry";
import { DocumentApprovalEntry } from "./TaskTypeRow/DocumentApprovalEntry";
import { CombinedTemplateEntry } from "./TaskTypeRow/CombinedTemplateEntry";
import { DocumentCollectionIssueEntry } from "./TaskTypeRow/DocumentCollectionIssueEntry";
import { WorkflowTasksEntry } from "./TaskTypeRow/WorkflowTasksEntry";
import type { WorkflowType } from "@prodoctivity/shared/src/index-types";

type Props = {
  category: WorkflowType & { workflowTypeId: string };
  taskType:
    | "document-collection-task"
    | "document-approval-task"
    | "workflow-task"
    | "template-context-state"
    | undefined;
  cIndex: number;
  fromDate: number | undefined;
  toDate: number | undefined;
  processFilter: string;
  listType: string | undefined;
  setProcessDetailsPanel: Dispatch<
    SetStateAction<
      | {
          taskId: string;
          processId: string;
          taskStageName: string | undefined;
        }
      | undefined
    >
  >;
  isWidget: boolean;
  isOpenAccordion: boolean;
  onAnyAccordionOpen: (value: boolean) => void;
};

export const ProcessAccordion: FunctionComponent<Props> = ({
  category,
  cIndex,
  fromDate,
  toDate,
  processFilter,
  listType,
  setProcessDetailsPanel,
  taskType,
  isWidget,
  isOpenAccordion,
  onAnyAccordionOpen,
}) => {
  const {
    resources,
    colors,
    workflowTaskList,
    isLoading,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount,
    pageLengthOptions,
    claimTask,
    containerRef,
    accordionWidth,
    mainContentEllipsisOptions,
  } = useProcessAccordion(
    category.workflowTypeId,
    fromDate,
    toDate,
    processFilter,
    listType || "unclaimed",
    taskType
  );

  return totalRowCount && totalRowCount > 0 ? (
    <BoxWithRef ref={containerRef} minWidth={320}>
      <InboxAccordion
        key={`${cIndex}-${category.workflowTypeId}_${category.workflowTypeName}`}
        title={category.workflowTypeName.toUpperCase()}
        itemsTotalCount={totalRowCount}
        resources={resources}
        isOpenAccordion={isOpenAccordion}
        onAnyAccordionOpen={onAnyAccordionOpen}
      >
        <Box paddingTop={4} paddingBottom={3} paddingX={4} width={"100%"}>
          {isLoading ? (
            <>
              {range(5).map((_, idx) => (
                <Box key={`${idx}`}>
                  <Box
                    display="flex"
                    flex="grow"
                    alignItems="center"
                    maxHeight={60}
                    paddingY={3}
                    height={"100%"}
                    color={idx % 2 === 0 ? colors.primaryHover0 : undefined}
                    borderRadius={6}
                    borderColor={colors.white}
                  >
                    <Box
                      display="flex"
                      justifyContent="start"
                      alignItems="center"
                      gap={2}
                      column={5}
                      paddingLeft={2}
                      paddingRight={3}
                    >
                      <Box display="flex" justifyContent="end">
                        <Skeleton width={24} height={28} />
                      </Box>
                      <Skeleton width={350} height={28} />
                    </Box>
                    <Box display="flex" justifyContent="start" column={3} paddingRight={3}></Box>
                    <Box display="flex" justifyContent="start" column={1} paddingRight={3}>
                      <Skeleton
                        width={accordionWidth && accordionWidth < 700 ? 40 : 100}
                        height={28}
                      />
                    </Box>
                    <Box display="flex" justifyContent="start" column={1}>
                      <Skeleton
                        width={accordionWidth && accordionWidth < 700 ? 40 : 100}
                        height={28}
                      />
                    </Box>
                    {accordionWidth && accordionWidth < 700 ? null : (
                      <Box column={1} paddingX={2}>
                        <Skeleton width={80} height={40} />
                      </Box>
                    )}
                    <Box column={1} display="flex" justifyContent="end">
                      <Skeleton width={32} height={28} rounded />
                    </Box>
                  </Box>
                </Box>
              ))}
            </>
          ) : workflowTaskList && workflowTaskList.taskList.length > 0 ? (
            workflowTaskList.taskList.map((item, itemIdx) => {
              return (
                <Box key={`${itemIdx}-${item.id}`}>
                  {item.taskType === "workflow-task" && (
                    <WorkflowTasksEntry
                      key={`${itemIdx}-${item.id}`}
                      item={item}
                      itemIdx={itemIdx}
                      setProcessDetailsPanel={setProcessDetailsPanel}
                      claimTask={claimTask}
                      isWidget={isWidget}
                      accordionWidth={accordionWidth}
                      mainContentEllipsisOptions={mainContentEllipsisOptions}
                    ></WorkflowTasksEntry>
                  )}

                  {item.taskType === "template-context-state" &&
                    item.contextType === "template" && (
                      <TemplateEntry
                        key={`${itemIdx}-${item.id}`}
                        id={item.id}
                        item={item}
                        context={item.context}
                        itemIdx={itemIdx}
                        isWidget={isWidget}
                        accordionWidth={accordionWidth}
                        mainContentEllipsisOptions={mainContentEllipsisOptions}
                      ></TemplateEntry>
                    )}

                  {item.taskType === "template-context-state" &&
                    item.contextType === "combined-template" && (
                      <CombinedTemplateEntry
                        key={`${itemIdx}-${item.id}`}
                        id={item.id}
                        item={item}
                        context={item.context}
                        itemIdx={itemIdx}
                        isWidget={isWidget}
                        accordionWidth={accordionWidth}
                        mainContentEllipsisOptions={mainContentEllipsisOptions}
                      ></CombinedTemplateEntry>
                    )}

                  {item.taskType === "document-approval-task" && (
                    <DocumentApprovalEntry
                      key={`${itemIdx}-${item.id}`}
                      id={item.id}
                      item={item}
                      itemIdx={itemIdx}
                      isWidget={isWidget}
                      accordionWidth={accordionWidth}
                      mainContentEllipsisOptions={mainContentEllipsisOptions}
                    ></DocumentApprovalEntry>
                  )}

                  {item.taskType === "document-collection-task" && (
                    <DocumentCollectionIssueEntry
                      key={`${itemIdx}-${item.id}`}
                      item={item}
                      itemIdx={itemIdx}
                      isWidget={isWidget}
                      accordionWidth={accordionWidth}
                      mainContentEllipsisOptions={mainContentEllipsisOptions}
                    ></DocumentCollectionIssueEntry>
                  )}
                </Box>
              );
            })
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Text size="200" color={colors.neutral900}>
                {resources.homePage.taskIsEmpty}
              </Text>
            </Box>
          )}
          <Box display="flex" direction="row" justifyContent="end" flex="grow" paddingX={6}>
            <Box>
              <Pagination<typeof rowsPerPage>
                id="process-accordion-pagination"
                rowsLabel={`${resources.tasks}`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={workflowTaskList?.taskList.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
                totalRowCount={totalRowCount}
              />
            </Box>
          </Box>
        </Box>
      </InboxAccordion>
    </BoxWithRef>
  ) : null;
};
