import {
  Box,
  ColorConfig,
  Divider,
  IconType,
  useColors,
  Text,
  TapArea,
  Image,
  Icon,
  ChevronSvgIcon,
} from "@prodoctivity/design-system";
import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { StandardIconContainer } from "./StandarIconContainer";

export type LeftSidebarProps = {
  options: {
    name: string;
    icon: IconType | undefined;
    svgIcon:
      | string
      | FunctionComponent
      | React.FunctionComponent<{ color?: ColorConfig }>
      | undefined;
    color: ColorConfig | undefined;
    onTap: () => void;
  }[];
  openState: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  };
  customElement: ReactNode | undefined;
  title: string | undefined;
  setSelectedMenuItemCallback: (item: string) => void;
  resources: {
    open: string;
    close: string;
  };
};
export const LeftSidebar: FunctionComponent<LeftSidebarProps> = ({
  options,
  openState,
  customElement,
  title,
  setSelectedMenuItemCallback,
  resources,
}) => {
  const { colors } = useColors();
  return (
    <Box
      display="flex"
      flex="grow"
      direction="column"
      width={openState.isOpen ? "40%" : "auto"}
      color={colors.white}
    >
      <Box display="flex" height={"100%"}>
        <CollapsibleSidebar
          title={title}
          options={options}
          mode="desktop"
          isOpen={openState.isOpen}
          setIsOpen={openState.setIsOpen}
          customElement={customElement}
          setSelectedMenuItemCallback={setSelectedMenuItemCallback}
          resources={resources}
        />
        <Divider direction="vertical" />
      </Box>
    </Box>
  );
};
export const CollapsibleSidebar = ({
  options,
  title,
  mode,
  isOpen,
  setIsOpen,
  customElement,
  setSelectedMenuItemCallback,
}: {
  options: LeftSidebarProps["options"];
  title: string | undefined;
  mode: "desktop" | "mobile";
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customElement: ReactNode | undefined;
  setSelectedMenuItemCallback?: (item: string) => void;
  resources: {
    open: string;
    close: string;
  };
}) => {
  const { colors } = useColors();
  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);
  const [selected, setSelected] = useState<string | null>(options[0].name);
  if (!isOpen) {
    return (
      <Box display="flex" flex="grow" direction="row" width={"auto"} minWidth={40}>
        <Box
          display="flex"
          flex="shrink"
          paddingY={2}
          maxHeight={60}
          alignItems="center"
          direction="column"
        >
          <StandardIconContainer handleTap={toggleOpen}>
            <ChevronSvgIcon direction="left" color={colors.neutral900} />
          </StandardIconContainer>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" direction="column" width={"100%"} flex="grow" minWidth={400}>
      <Box
        display="flex"
        direction="row"
        flex="shrink"
        paddingX={4}
        paddingY={2}
        maxHeight={60}
        justifyContent="end"
      >
        {title && (
          <Box display="flex" flex="grow" paddingY={3}>
            <Text weight="normal" size="200" color={colors.neutral900}>
              {title.toUpperCase()}
            </Text>
          </Box>
        )}
        {mode === "desktop" && (
          <Box display="flex" flex="shrink" alignItems="center" paddingX={1}>
            <TapArea onTap={toggleOpen}>
              <ChevronSvgIcon direction="right" color={colors.neutral900} />
            </TapArea>
          </Box>
        )}
      </Box>
      <Divider direction="horizontal" />
      {options.map((opt, idx) => {
        const isSelected = selected === opt.name;
        return (
          <Box
            key={`${opt.name}_${idx}`}
            onClickCapture={() => {
              setSelected(opt.name);
              setSelectedMenuItemCallback && setSelectedMenuItemCallback(opt.name);
            }}
            color={isSelected ? colors.primaryHover0 : undefined}
            hoverColor={colors.primaryHover0}
          >
            {isSelected ? <Box height={1} color={colors.neutral500}></Box> : null}
            <TabLink
              key={opt.name}
              current={isSelected}
              icon={opt.icon}
              svgIcon={opt.svgIcon}
              color={opt.color}
              onTap={opt.onTap}
            >
              <Text
                weight={isSelected ? "bold" : "normal"}
                color={isSelected ? colors.primary : colors.subtle}
              >
                {opt.name}
              </Text>
            </TabLink>
            {isSelected ? <Box height={1} color={colors.neutral500}></Box> : null}
          </Box>
        );
      })}
      {customElement && <>{customElement}</>}
    </Box>
  );
};
function TabLink({
  icon,
  current,
  children,
  svgIcon,
  onTap,
}: {
  children?: ReactNode;
  current?: boolean;
  icon?: IconType;
  svgIcon?: string | React.FunctionComponent<{ color?: ColorConfig }>;
  color?: ColorConfig;
  onTap?: () => void;
}) {
  const { colors } = useColors();
  const SvgIcon = svgIcon;
  const iconColor = current ? colors.primary : colors.neutral700;
  return (
    <Box maxHeight={56} display="flex" paddingY={4}>
      <Box display="flex" alignItems="center" flex="grow">
        {current && <Box height={60} width={5} color={colors.primary} />}
        <TapArea onTap={onTap}>
          <Box
            paddingLeft={5}
            display="flex"
            alignItems="center"
            minHeight={56}
            width={"100%"}
            marginStart={current ? 0 : 1}
          >
            <Box display="flex" alignItems="center" width={"100%"} gap={4}>
              {SvgIcon && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={40}
                  height={40}
                  marginStart={-1}
                >
                  {typeof SvgIcon === "function" ? (
                    <SvgIcon color={iconColor} />
                  ) : (
                    <Image
                      alt="tabLinkIcon"
                      src={SvgIcon}
                      naturalHeight={24}
                      naturalWidth={27}
                      color="transparent"
                    />
                  )}
                </Box>
              )}
              {icon && (
                <Icon
                  size="xs"
                  accessibilityLabel="profile"
                  color={current ? colors.primary : colors.subtle}
                  icon={icon}
                />
              )}
              <Text
                weight={current ? "bold" : "normal"}
                color={current ? colors.primary : colors.subtle}
              >
                {children}
              </Text>
            </Box>
          </Box>
        </TapArea>
      </Box>
    </Box>
  );
}
