import {
  ActivityCollectionSearch,
  ActivityHistorySearch,
  ActivitySearch,
  ActivityType,
} from "../../interfaces/activities/ActivitySearch";
import { useCallback, useState } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";

const pageLengthOptions: Array<ActivitySearch["rowsPerPage"]> = ["15", "50", "100"];

export function useActivitiesPage() {
  const { resources, moment } = useAppTranslation();

  const { getActionLogs, user: thisUser } = useServices();

  const [search, setSearch] = useState<ActivitySearch>({
    rowsPerPage: "15",
    pageNumber: 0,
    entryTypes: [],
    sort: "desc",
    listEntryTypes: [
      { code: "document-generated", name: resources.activities.entryTypes.documentGenerated },
      { code: "document-saved", name: resources.activities.entryTypes.documentSaved },
      { code: "document-deleted", name: resources.activities.entryTypes.documentDeleted },
      { code: "document-restored", name: resources.activities.entryTypes.documentRestored },
      {
        code: "document-permanently-deleted",
        name: resources.activities.entryTypes.documentPermanentlyDeleted,
      },
      { code: "user-created", name: resources.activities.entryTypes.userCreated },
      { code: "template-updated", name: resources.activities.entryTypes.templateUpdated },
      { code: "organization-created", name: resources.activities.entryTypes.organizationCreated },
      { code: "user-password-updated", name: resources.activities.entryTypes.userPasswordUpdated },
      {
        code: "admin-change-user-password",
        name: resources.activities.entryTypes.adminChangeUserPassword,
      },
      { code: "document-view", name: resources.viewDocument },
      {
        code: "document-collection-assigned",
        name: resources.activities.entryTypes.documentCollectionAssigned,
      },
    ],
  });
  const currentPage = search.pageNumber;
  const rowsPerPage = search.rowsPerPage;

  const setCurrentPage = (pageNum: number) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        pageNumber: pageNum,
      };
    });
  };

  const setRowsPerPage = (rpp: ActivitySearch["rowsPerPage"]) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        rowsPerPage: rpp,
      };
    });
  };

  const setPageLength = useCallback((pageLength: typeof rowsPerPage) => {
    setRowsPerPage(pageLength);
    setCurrentPage(0);
  }, []);

  const onChangeSearch = useCallback((newState: ActivitySearch) => {
    setSearch(newState);
  }, []);

  const onSortChange = useCallback(
    (newState: "asc" | "desc") => {
      const newEntry = newState;
      setSearch({ ...search, sort: newEntry, pageNumber: 0 });
    },
    [search]
  );

  const onRemoveType = useCallback(
    (newState: ActivityType) => {
      const index = search.entryTypes.findIndex((element) => element.code === newState.code);
      if (index >= 0) {
        const newEntry = [...search.entryTypes];

        newEntry.splice(index, 1);

        setSearch({ ...search, entryTypes: newEntry });
      }
    },
    [search]
  );

  const onRemoveDates = useCallback(() => {
    setSearch({ ...search, fromDate: undefined, toDate: undefined });
  }, [search]);

  const onClear = useCallback(() => {
    setSearch({ ...search, fromDate: undefined, toDate: undefined, entryTypes: [] });
  }, [search]);

  const getActions = useCallback(() => {
    return getActionLogs(search);
  }, [getActionLogs, search]);

  const { isLoading, data, refetch } = useOrganizationQuery(
    `activities/${JSON.stringify(search)}`,
    getActions,
    {
      refetchInterval: 60 * 1000,

      staleTime: 0,
    }
  );

  const entries = data ? data.entries : [];

  const isNextButtonDisabled = !data || data.requestedPageLength !== data.pageLength;
  const isPreviousButtonDisabled = !data || data.pageNumber < 1;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled]);

  return {
    isLoading,
    entries,
    resources,
    search,
    currentPage,
    nextPage,
    previousPage,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    rowsPerPage,
    setPageLength,
    refetch,
    thisUsername: thisUser?.username,
    pageLengthOptions,
    onChangeSearch,
    onSortChange,
    onRemoveType,
    onRemoveDates,
    onClear,
    moment,
  };
}

export function useCollectionActivitiesPage(instanceId: string | undefined) {
  const { resources, moment } = useAppTranslation();

  const { getCollectionActionLogs, user: thisUser } = useServices();

  const [search, setSearch] = useState<ActivityCollectionSearch>({
    rowsPerPage: "15",
    pageNumber: 0,
    sort: "desc",
    instanceId: instanceId ?? "",
  });
  const currentPage = search.pageNumber;
  const rowsPerPage = search.rowsPerPage;

  const setCurrentPage = (pageNum: number) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        pageNumber: pageNum,
      };
    });
  };

  const setRowsPerPage = (rpp: ActivityCollectionSearch["rowsPerPage"]) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        rowsPerPage: rpp,
      };
    });
  };

  const setPageLength = useCallback((pageLength: typeof rowsPerPage) => {
    setRowsPerPage(pageLength);
    setCurrentPage(0);
  }, []);

  const onChangeSearch = useCallback((newState: ActivityCollectionSearch) => {
    setSearch(newState);
  }, []);

  const onSortChange = useCallback(
    (newState: "asc" | "desc") => {
      const newEntry = newState;
      setSearch({ ...search, sort: newEntry, pageNumber: 0 });
    },
    [search]
  );

  const getCollectionsActions = useCallback(() => {
    return getCollectionActionLogs(search, instanceId ?? "");
  }, [getCollectionActionLogs, instanceId, search]);

  const { isLoading, data, refetch } = useOrganizationQuery(
    `activities/${JSON.stringify(search)}/${instanceId}`,
    getCollectionsActions,
    {
      refetchInterval: 60 * 1000,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const isNextButtonDisabled = !data || data.requestedPageLength !== data.pageLength;
  const isPreviousButtonDisabled = !data || data.pageNumber < 1;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled]);

  return {
    collectionLogs: data,
    isLoading,
    resources,
    search,
    currentPage,
    nextPage,
    previousPage,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    rowsPerPage,
    setPageLength,
    refetch,
    thisUsername: thisUser?.username,
    pageLengthOptions,
    onChangeSearch,
    onSortChange,
    getCollectionsActions,
    moment,
  };
}

export function useHistoryActivitiesPage(documentId: string | undefined) {
  const { resources, moment } = useAppTranslation();

  const { getHistoryActionLogs, user: thisUser } = useServices();

  const [search, setSearch] = useState<ActivityHistorySearch>({
    rowsPerPage: "15",
    pageNumber: 0,
    sort: "desc",
    documentId: documentId ?? "",
  });
  const currentPage = search.pageNumber;
  const rowsPerPage = search.rowsPerPage;

  const setCurrentPage = (pageNum: number) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        pageNumber: pageNum,
      };
    });
  };

  const setRowsPerPage = (rpp: ActivityHistorySearch["rowsPerPage"]) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        rowsPerPage: rpp,
      };
    });
  };

  const setPageLength = useCallback((pageLength: typeof rowsPerPage) => {
    setRowsPerPage(pageLength);
    setCurrentPage(0);
  }, []);

  const onChangeSearch = useCallback((newState: ActivityHistorySearch) => {
    setSearch(newState);
  }, []);

  const onSortChange = useCallback(
    (newState: "asc" | "desc") => {
      const newEntry = newState;
      setSearch({ ...search, sort: newEntry, pageNumber: 0 });
    },
    [search]
  );

  const getHistoryActions = useCallback(() => {
    return getHistoryActionLogs(search, documentId ?? "");
  }, [documentId, getHistoryActionLogs, search]);

  const { isLoading, data, refetch } = useOrganizationQuery(
    `activities/${JSON.stringify(search)}/${documentId}`,
    getHistoryActions,
    {
      refetchInterval: 60 * 1000,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const isNextButtonDisabled = !data || data.requestedPageLength !== data.pageLength;
  const isPreviousButtonDisabled = !data || data.pageNumber < 1;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled]);

  return {
    HistoryLogs: data,
    isLoading,
    resources,
    search,
    currentPage,
    nextPage,
    previousPage,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    rowsPerPage,
    setPageLength,
    refetch,
    thisUsername: thisUser?.username,
    pageLengthOptions,
    onChangeSearch,
    onSortChange,
    getHistoryActions,
    moment,
  };
}
