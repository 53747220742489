import { FunctionComponent, useCallback, useState } from "react";

import { isIconSelected } from "../../utils";
import { Box } from "../Box";
import { useColors } from "../ColorSchemeProvider";
import { TapArea } from "../TapArea";
import { DynamicIcon } from "./DynamicIcon";

type Props = {
  buttonName: string;
  selected: boolean | undefined;
  handleFunction: (toolSelected?: boolean) => void;
  statefulCondition: (() => boolean) | boolean | undefined;
};
export const DynamicIconButton: FunctionComponent<Props> = ({
  handleFunction,
  selected,
  buttonName,
  statefulCondition,
}) => {
  const { colors } = useColors();
  const [btnClicked, setBtnClicked] = useState<boolean>(() => {
    return selected ? selected : false;
  });

  const flashClickStyles = useCallback(
    (actualButtonState: boolean) => {
      // Toggle for specified buttons
      if (["cloud", "fullScreen", "handTool", "rectangleTool"].includes(buttonName)) {
        setBtnClicked(!actualButtonState);
      } else {
        // For other buttons, briefly activate and then deactivate
        setBtnClicked(true);
        setTimeout(() => {
          setBtnClicked(false);
        }, 200);
      }
    },
    [buttonName]
  );

  return (
    <Box
      width={40}
      height={32}
      color={
        selected !== undefined
          ? selected
            ? colors.primaryHover0
            : undefined
          : isIconSelected(
              colors,
              statefulCondition !== undefined
                ? typeof statefulCondition === "function"
                  ? statefulCondition()
                  : statefulCondition
                : btnClicked,
              true
            )
      }
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={4}
      borderColor={
        selected !== undefined
          ? selected
            ? colors.primary
            : colors.neutral50
          : isIconSelected(
              colors,
              statefulCondition !== undefined
                ? typeof statefulCondition === "function"
                  ? statefulCondition()
                  : statefulCondition
                : btnClicked,
              false
            ) !== undefined
          ? isIconSelected(
              colors,
              statefulCondition !== undefined
                ? typeof statefulCondition === "function"
                  ? statefulCondition()
                  : statefulCondition
                : btnClicked,
              false
            )
          : colors.neutral50
      }
    >
      <TapArea
        onTap={() => {
          // Synchronize condition that defines button state with actual internal state.
          // This fixes you having to double click this button to update its state when something
          // external to this button changes the state this button represents.
          let actualButtonState = btnClicked;
          if (statefulCondition !== undefined) {
            actualButtonState =
              typeof statefulCondition === "function" ? statefulCondition() : statefulCondition;
          }

          if (buttonName === "handTool" || buttonName === "rectangleTool") {
            handleFunction(!actualButtonState);
          } else {
            handleFunction();
          }
          flashClickStyles(actualButtonState);
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <DynamicIcon
            btnClicked={
              selected !== undefined
                ? !!selected
                : statefulCondition !== undefined
                ? typeof statefulCondition === "function"
                  ? statefulCondition()
                  : statefulCondition
                : btnClicked
            }
            iconName={buttonName}
          ></DynamicIcon>
        </Box>
      </TapArea>
    </Box>
  );
};
