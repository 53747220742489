import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ScannerSvgIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 44,
  height = 51,
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M0.502299 39.2811C0.4903 33.4857 0.526297 28.6981 0.586291 28.6621C0.646285 28.6141 10.8333 28.5781 45.7621 28.5781V30.6539C45.7621 31.7818 45.786 32.7537 45.822 32.8137C45.858 32.8617 46.602 32.5138 47.4659 32.0458C48.3418 31.5778 49.3977 31.0379 49.8057 30.8459C50.5256 30.5099 50.5856 30.5099 50.9816 30.6779C51.2095 30.7739 51.4255 30.9539 51.4615 31.0739C51.4975 31.1819 51.4855 31.4099 51.4255 31.5778C51.3415 31.8178 50.7296 32.1898 45.822 34.7695L45.786 36.7974C45.7741 37.9252 45.798 38.8852 45.846 38.9332C45.906 38.9931 46.9259 38.5252 48.1258 37.8893C49.4817 37.1813 50.4536 36.7374 50.6816 36.7374C50.8736 36.7374 51.1255 36.8214 51.2215 36.9173C51.3175 37.0133 51.4255 37.2053 51.4615 37.3373C51.4975 37.4693 51.4495 37.6973 51.3655 37.8533C51.2695 38.0092 50.1416 38.6812 45.7621 40.997V45.3765C45.7621 49.3722 45.7381 49.8041 45.5341 50.2361C45.4141 50.5001 45.2101 50.812 45.0901 50.92C44.9701 51.028 44.6582 51.208 44.4062 51.304C44.0462 51.46 43.3743 51.496 40.6025 51.496C37.5428 51.496 37.2069 51.472 36.7869 51.268C36.5469 51.148 36.2589 50.944 36.151 50.824C36.043 50.6921 35.863 50.4161 35.755 50.2121C35.647 49.9961 35.563 49.7081 35.563 49.5762C35.563 49.3362 35.479 49.3362 10.7853 49.3362L10.6413 49.8401C10.5574 50.1281 10.3414 50.5241 10.1614 50.74C9.98141 50.956 9.62144 51.208 9.38146 51.316C9.0095 51.46 8.32556 51.496 5.56582 51.496C2.50611 51.496 2.17014 51.472 1.75018 51.268C1.5102 51.148 1.18623 50.908 1.04225 50.752C0.898262 50.6081 0.730278 50.3321 0.658284 50.1521C0.562293 49.9241 0.514298 46.4085 0.502299 39.2811Z"
          fill="#9E9E9E"
        />
        <path
          d="M38.2019 4.50762C40.4816 3.39173 43.2294 2.03586 44.3213 1.49591C45.4132 0.955957 46.4091 0.511999 46.5411 0.5C46.6731 0.5 46.925 0.595991 47.117 0.739977C47.357 0.919961 47.441 1.08794 47.441 1.36392C47.441 1.5799 47.381 1.85587 47.297 1.96386C47.213 2.08385 44.5733 3.42773 41.4416 4.97558C38.3098 6.51144 32.3344 9.43916 28.1828 11.479C24.0312 13.5188 20.5275 15.2466 20.4075 15.3186C20.2275 15.4146 20.1915 15.6186 20.1436 17.4784H18.8237C17.7078 17.4784 17.4558 17.4424 17.1558 17.2384C16.9639 17.1064 16.7359 16.8545 16.6519 16.6625C16.5559 16.4825 16.4839 16.1585 16.4839 15.9425C16.4839 15.7386 16.4119 15.3186 16.3159 15.0186C16.2199 14.7187 16.1719 14.4427 16.2079 14.3827C16.2559 14.3347 16.7239 14.2987 18.2237 14.2987L22.0034 12.4509C24.0792 11.443 27.6428 9.69114 29.9226 8.57524C32.2024 7.45935 35.9221 5.62352 38.2019 4.50762Z"
          fill="#C6C6C6"
        />
        <path
          d="M1.20933 14.3947C1.38931 14.2147 1.67728 14.0227 1.84527 13.9507C2.02525 13.8787 4.25704 13.8307 7.52073 13.8307C11.7083 13.8187 12.9682 13.8547 13.2802 13.9747C13.4842 14.0707 13.8441 14.3227 14.0601 14.5507C14.2761 14.7787 14.5041 15.1506 14.5641 15.3786C14.6241 15.6066 14.6841 16.0145 14.6841 16.2785C14.6841 16.5425 14.8161 17.0224 14.972 17.3584C15.116 17.6824 15.488 18.1743 15.776 18.4263C16.0639 18.6903 16.4959 18.9903 16.7239 19.0863C17.0958 19.2542 18.7397 19.2902 30.7026 19.3382C42.9894 19.3982 44.2973 19.4222 44.6213 19.5902C44.8132 19.7102 45.0772 19.8902 45.1852 20.0102C45.2932 20.1422 45.4732 20.4421 45.5692 20.6821C45.7252 21.0541 45.7611 21.678 45.7611 26.7775H0.525391V21.0781C0.525391 16.4105 0.561387 15.3186 0.693375 15.0426C0.789366 14.8627 1.01734 14.5747 1.20933 14.3947Z"
          fill="#C6C6C6"
        />
        <path
          d="M2.5656 21.8223C2.5776 19.3745 2.58959 19.1945 2.81757 18.9665C3.04555 18.7386 3.21354 18.7266 7.73711 18.7266C12.2487 18.7266 12.4167 18.7386 12.6566 18.9665C12.8726 19.1945 12.8966 19.3745 12.8846 21.8223C12.8846 24.1261 12.8606 24.45 12.6806 24.642C12.4887 24.846 12.1287 24.858 7.73711 24.846C3.41752 24.846 2.97356 24.822 2.78158 24.642C2.58959 24.45 2.5656 24.1741 2.5656 21.8223Z"
          fill="#E1E1E2"
        />
        <path
          d="M30.559 22.8422C30.679 22.6622 30.8949 22.4462 31.0389 22.3622C31.2429 22.2422 32.0708 22.2182 37.5063 22.2662L37.8063 22.6022C37.9623 22.7822 38.0943 23.0342 38.0943 23.1661C38.0943 23.2981 37.9623 23.5621 37.5063 24.0661L34.4106 24.1021C32.0948 24.1261 31.2309 24.0901 31.0149 23.9821C30.8589 23.8981 30.643 23.6821 30.535 23.5021C30.367 23.2021 30.367 23.1422 30.559 22.8422Z"
          fill="#E1E1E2"
        />
        <path
          d="M39.8221 22.8782C39.9301 22.6742 40.1461 22.4462 40.2901 22.3622C40.446 22.2782 40.986 22.2062 41.6339 22.2062C42.5338 22.2062 42.7618 22.2422 43.0378 22.4462C43.2778 22.6262 43.3738 22.7942 43.3738 23.0822C43.3738 23.2981 43.2778 23.6101 43.1458 23.7661C42.9298 24.0541 42.8218 24.0781 41.7299 24.1021C40.938 24.1261 40.458 24.0901 40.2541 23.9821C40.0981 23.8981 39.8821 23.6941 39.7861 23.5261C39.6301 23.2621 39.6421 23.1901 39.8221 22.8782Z"
          fill="#E1E1E2"
        />
        <path
          d="M2.5656 31.4214C2.5536 31.2054 2.63759 30.9054 2.74558 30.7614C2.92556 30.5095 3.04555 30.4855 4.05346 30.4975C4.92937 30.4975 5.19335 30.5335 5.36133 30.7014C5.49332 30.8334 5.57731 31.0854 5.57731 31.3614C5.57731 31.6733 5.49332 31.8893 5.30134 32.0573C5.08536 32.2493 4.86938 32.2973 4.07745 32.2973C3.27353 32.2973 3.06955 32.2493 2.84157 32.0573C2.67359 31.9013 2.5656 31.6614 2.5656 31.4214Z"
          fill="#E1E1E2"
        />
        <path
          d="M6.06927 34.3011C6.18926 34.1211 6.52522 33.8691 6.8012 33.7372C7.29315 33.4972 7.54513 33.4852 16.8563 33.4852L16.9162 33.8451C16.9402 34.0491 17.1322 34.3731 17.3362 34.5651C17.5282 34.7691 17.8642 34.961 18.0801 34.997C18.2961 35.033 20.6959 35.045 23.4076 35.021L23.4938 35.0204C28.1735 34.985 28.3651 34.9836 28.6871 34.7451C28.8791 34.6131 29.1431 34.2651 29.5151 33.4852H34.2186C37.9503 33.4852 39.0062 33.5212 39.3181 33.6532C39.5341 33.7492 39.8581 33.9771 40.0261 34.1691C40.2061 34.3491 40.41 34.6611 40.482 34.8411C40.554 35.033 40.614 36.1129 40.614 39.6046H23.3356C12.0567 39.6046 5.97328 39.5686 5.81729 39.4846C5.58931 39.3766 5.57731 39.2446 5.57731 37.4808C5.57731 36.4369 5.63731 35.369 5.7093 35.105C5.78129 34.8411 5.94928 34.4811 6.06927 34.3011Z"
          fill="#E1E1E2"
        />
        <path
          d="M5.63731 47.4639L5.60131 44.4401L5.56531 41.4044H16.8563L17.0362 41.7644C17.1322 41.9684 17.3602 42.2444 17.5402 42.3763C17.8642 42.6043 18.0321 42.6163 23.0957 42.6163C28.0872 42.6043 28.3272 42.5923 28.6991 42.3643C28.9151 42.2324 29.1551 41.9684 29.3951 41.4044H40.614V47.4039L40.0981 47.4759C39.8221 47.5119 31.9508 47.5239 5.63731 47.4639Z"
          fill="#E1E1E2"
        />
      </g>
    </svg>
  );
};
