import {
  Box,
  Button,
  Divider,
  Spinner,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoginLayout } from "../../components/Layout/LoginLayout";
import { LoginLayoutSmall } from "../../components/Layout/LoginLayoutFooterSmall";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { InvalidTokenSvg } from "../../svg/InvalidTokenSvg";
import { WelcomeToOrgIconSvg } from "../../svg/WelcomeToOrgIconSvg";
import { InvitationPassword } from "./InvitationPassword";

const JoinOrganizationPage: FC = () => {
  const { colors } = useColors();
  const { search } = useLocation();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const [shouldSetPassword, setShouldSetPassword] = useState(false);
  const [createPwdToken, setCreatePwdToken] = useState<string | undefined>("");
  const { email, token } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      email: params.get("email"),
      token: params.get("token"),
    };
  }, [search]);
  const navigate = useNavigate();
  const { activateJoinOrganization, verifyToken } = useServices();
  const [errorMessage, setErrorMessage] = useState("");

  const joinOrganization = useCallback(async () => {
    if (token && email) {
      const joinOrganizationResult = await activateJoinOrganization(email, token);
      if (!joinOrganizationResult.userExists) {
        setCreatePwdToken(joinOrganizationResult?.passwordToken);
        setShouldSetPassword(true);
        return false;
      } else {
        navigate(organizationLinkTemplates.login());
      }
    }
    return false;
  }, [activateJoinOrganization, email, navigate, token]);

  const { mutate: joinOrganizationMutate } = useMutation(joinOrganization, {
    onError() {
      setErrorMessage("Request already expired. Please send the request again.");
      setTimeout(() => setErrorMessage(""), 5000);
    },
  });

  const verifyingToken = useCallback(async () => {
    if (token && email) {
      const res = await verifyToken(token, email);
      return res;
    }
  }, [email, token, verifyToken]);

  const { isLoading, isError } = useQuery([`/verify-token/`, token, email], verifyingToken, {
    staleTime: 60 * 1000,
    retry: false,
  });

  const isLargeOrHD = breakpoint === "large" || breakpoint === "hd";
  const LoginLayoutComponent = isLargeOrHD ? LoginLayout : LoginLayoutSmall;

  return (
    <LoginLayoutComponent>
      {shouldSetPassword ? (
        <InvitationPassword email={email} token={createPwdToken} />
      ) : (
        <>
          {isLoading ? (
            <Spinner accessibilityLabel={"Loading"} show={true} />
          ) : isError ? (
            <Box color={colors.neutral300}>
              <Box color={colors.neutral300}>
                <Box display="flex" justifyContent="center" marginBottom={12}>
                  <Text color={colors.primary400} weight="bold" size="500">
                    {resources.invitationExpired}
                  </Text>
                </Box>
                <Box display="flex" justifyContent="center" marginBottom={12}>
                  {breakpoint === "small" || breakpoint === "medium" ? (
                    <InvalidTokenSvg width={251} height={228} />
                  ) : (
                    <InvalidTokenSvg />
                  )}
                </Box>
                <Box paddingX={10}>
                  <Divider />
                </Box>
                <Box padding={12} display="flex" justifyContent="center" marginTop={5}>
                  <Text align="center" color={colors.neutral900}>
                    {resources.invitationExpiredMessage}
                  </Text>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              width={breakpoint === "small" || breakpoint === "medium" ? "100%" : 734}
              height={breakpoint === "small" || breakpoint === "medium" ? "auto" : 805}
              borderRadius={12}
              marginBottom="auto"
              alignSelf="center"
              right
              padding={12}
              rounding="pill"
              display="flex"
              direction="column"
              borderStyle="shadow"
              justifyContent="between"
            >
              <Box
                display="flex"
                direction="column"
                justifyContent="between"
                alignItems="center"
                marginBottom={2}
              >
                <Text color={colors.black900} weight="bold" size="400" title="">
                  {resources.accountActivation}
                </Text>

                <Box
                  width={"100%"}
                  height={"2px"}
                  color={colors.primaryHover0}
                  alignSelf="center"
                  display="flex"
                  marginTop={2}
                  marginBottom={3}
                ></Box>

                <Text color={colors.primary} weight="bold" size="600" title="">
                  {resources.welcome}
                </Text>

                <Box display="flex" justifyContent="center" alignItems="center" width={"100%"}>
                  <Box right={true} top={true}>
                    <Box margin={2} />
                    {breakpoint === "small" ? (
                      <WelcomeToOrgIconSvg width={251} height={228} />
                    ) : (
                      <WelcomeToOrgIconSvg />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box padding={2}>
                <Box
                  display="flex"
                  direction="row"
                  width={"100%"}
                  justifyContent="center"
                  gap={1}
                  wrap={true}
                >
                  <Box width="auto">
                    <Text weight="bold" size="200" title="">
                      {resources.your}
                    </Text>
                  </Box>
                  <Box width="auto">
                    <Text color={colors.primary} weight="bold" size="200" title="">
                      {resources.prodoctivityAccount}
                    </Text>
                  </Box>
                  <Box width="auto">
                    <Text weight="bold" size="200" title="">
                      {resources.canNowJoinTheOrganization}
                    </Text>
                  </Box>
                </Box>
              </Box>

              <Button
                size={breakpoint === "small" || breakpoint === "medium" ? "md" : "lg"}
                text={resources.joinOrganizationAndLogin}
                accessibilityLabel={resources.joinOrganizationAndLogin}
                fullWidth={true}
                color={"blue"}
                onClick={() => {
                  joinOrganizationMutate();
                }}
              />
              {errorMessage && <Text color={colors.error}>{errorMessage}</Text>}
            </Box>
          )}
        </>
      )}
    </LoginLayoutComponent>
  );
};

export default JoinOrganizationPage;
