"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenderOperator = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var design_system_1 = require("@prodoctivity/design-system");
var RenderOperator = function (props) {
    var objExpression = props.objExpression, i18n = props.translateFn, id = props.id, expression = props.expression, currentField = props.currentField, inputSelectOperatorChanged = props.inputSelectOperatorChanged, options = props.options, resourcesAdapters = props.resourcesAdapters;
    return currentField && expression.sourceSummary && expression.sourceField ? ((0, jsx_runtime_1.jsx)(design_system_1.Box, { minWidth: 150, children: (0, jsx_runtime_1.jsx)(design_system_1.SelectList, { id: "".concat(id, "-operator"), value: expression.operator, onChange: inputSelectOperatorChanged, options: Object.keys(objExpression.operatorList)
                .reduce(function (arr, k) {
                var op = objExpression.operatorList[k];
                if (!!op && //This removes the undefined possibility
                    !op.reductor &&
                    options.some(function (so) { return so.key === expression.sourceSummary; }) &&
                    (op.dataTypeList ? op.dataTypeList.indexOf(currentField.dataType) >= 0 : true)) {
                    arr.push(op);
                }
                return arr;
            }, [])
                .map(function (op) {
                return { label: i18n(resourcesAdapters[op.name]), value: op.name };
            }) }) })) : null;
};
exports.RenderOperator = RenderOperator;
