import { Box, TapArea, Tooltip, useColors, useDesignBreakpoint } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { RefreshIconSvg } from "../svg/RefreshIconSvg";

type Props = {
  marginTop?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  marginStart?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  accessibilityLabel: string;
  refetch(): void;
  size?: number;
};

export const SmallRefreshButton: FunctionComponent<Props> = ({
  marginTop,
  marginStart,
  accessibilityLabel,
  refetch,
  size,
}) => {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const actualMarginTop =
    marginTop === undefined
      ? breakpoint === "small" || breakpoint === "medium"
        ? 2
        : 6
      : marginTop;
  const actualMarginStart = marginStart || 0;
  return (
    <Box
      display="flex"
      marginTop={size ? 2 : actualMarginTop}
      marginStart={actualMarginStart}
      flex="shrink"
      padding={1}
      alignItems="center"
    >
      <Tooltip text={accessibilityLabel}>
        <TapArea accessibilityLabel={accessibilityLabel} onTap={refetch}>
          <RefreshIconSvg
            height={size ? size : 34}
            width={size ? size : 34}
            color={colors.primary400}
          />
        </TapArea>
      </Tooltip>
    </Box>
  );
};
