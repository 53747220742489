"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formValueToValueType = exports.isCalculatedField = exports.isClassDataElement = exports.calculateDocumentCounts = exports.documentIsAboutToExpire = exports.documentIsExpired = exports.getVariablePartDateTimeFilter = exports.getFieldSampleData = exports.isTextLineContextField = exports.isValueListContextField = exports.recursivelyMergeHolders = exports.mergeGenerationContexts = exports.isContextDefinitionMergeError = exports.toTemplateContextRecordHolder = exports.getCollectionsPermissionsList = exports.getDocumentTypePermissionList = exports.toUpdateTemplateDefinitionRequest = exports.SortDirection$Schema = exports.convertToContextValue = exports.DocumentCollectionFolderSortKind$Schema = exports.mapEvaluateValToContextVal = exports.TemplateWizardField$Schema = exports.getContextRecord = exports.getContextField = exports.ContextField$Schema = exports.ContextFieldProperties$Schema = exports.ContextFieldPropertiesAllParts$Schema = exports.CurrencyContextFieldPropertiesPart$Schema = exports.DateWithCatch$Schema = exports.Date$Schema = exports.StringOrNumberOrArrayWithCatch$Schema = exports.StringOrNumberOrArray$Schema = exports.IntOrIntArray$Schema = exports.NumberOrNumberArrayWithCatch$Schema = exports.NumberOrNumberArray$Schema = exports.StringOrStringArrayWithCatch$Schema = exports.StringOrStringArray$Schema = exports.ContextFieldPropertiesHeaderPart$Schema = exports.DataElementSampleValueEntry$Schema = exports.buildFilterRegex = exports.escapeRegExp = exports.DateMinMaxType$Schema = exports.DocumentTypeNameValidationRegex = exports.ALLOWED_CHARS_REGEXP = exports.DataType$Schema = exports.DataTypes = void 0;
const utils_1 = require("./utils");
const validations_1 = require("./validations");
const zod_1 = require("zod");
const logical_1 = require("./logical");
const alphanumeric_context_field_1 = require("./schemas/fluency-shared/alphanumeric-context-field");
exports.DataTypes = [
    "Alphanumeric",
    "Numeric",
    "Date",
    "Logical",
    "Currency",
    "Time",
    "DateTime",
    "Image",
];
exports.DataType$Schema = zod_1.z.enum(exports.DataTypes).describe("Fluency Data Types");
exports.ALLOWED_CHARS_REGEXP = /^[a-zA-Z0-9_\- ]+$/;
exports.DocumentTypeNameValidationRegex = /^[a-zA-ZÀàÁáÂâÃãÄäåĂăÈèÉéÊêËëÌìÍíÎîÏïÒòÓóÔôÕõÖöÙùÚúÛûÜüÑñ&()\-_@´][0-9a-zA-ZÀàÁáÂâÃãÄäåĂăÈèÉéÊêËëÌìÍíÎîÏïÒòÓóÔôÕõÖöÙùÚúÛûÜüÑñ& ()\-_@´]*$/;
exports.DateMinMaxType$Schema = zod_1.z.enum([
    "Custom",
    "MinIsCurrentDate",
    "MaxIsCurrentDate",
]);
const escapeRegExp = (val) => {
    return val.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
exports.escapeRegExp = escapeRegExp;
const buildFilterRegex = (filter) => {
    const escapedFilter = (0, exports.escapeRegExp)(filter).toLowerCase();
    const regexPattern = escapedFilter.split(" ").join(".*");
    return new RegExp(regexPattern, "i");
};
exports.buildFilterRegex = buildFilterRegex;
exports.DataElementSampleValueEntry$Schema = zod_1.z
    .strictObject({
    dataElementId: zod_1.z.string().min(1).refine(utils_1.isConvertibleToObjectID, "Invalid Data Element ID"),
})
    .and(zod_1.z
    .strictObject({
    dataType: zod_1.z.literal("Alphanumeric"),
    sampleValues: zod_1.z.string().array(),
})
    .or(zod_1.z.strictObject({
    dataType: zod_1.z.literal("Numeric"),
    sampleValues: zod_1.z.number().int().array(),
}))
    .or(zod_1.z.strictObject({
    dataType: zod_1.z.literal("Currency"),
    sampleValues: zod_1.z.number().array(),
}))
    .or(zod_1.z.strictObject({
    dataType: zod_1.z.literal("Logical"),
    sampleValues: zod_1.z.boolean().array(),
}))
    .or(zod_1.z.strictObject({
    dataType: zod_1.z.literal("Date"),
    sampleValues: zod_1.z.array(zod_1.z
        .string()
        .refine((arg) => {
        return !Number.isNaN(Date.parse(arg));
    })
        .or(zod_1.z.number())),
}))
    .or(zod_1.z.strictObject({
    dataType: zod_1.z.literal("DateTime"),
    sampleValues: zod_1.z.array(zod_1.z
        .string()
        .refine((arg) => {
        return !Number.isNaN(Date.parse(arg));
    })
        .or(zod_1.z.number())),
}))
    .or(zod_1.z.strictObject({
    dataType: zod_1.z.literal("Time"),
    sampleValues: zod_1.z.array(zod_1.z
        .string()
        .refine((arg) => {
        return !Number.isNaN(Date.parse(arg));
    })
        .or(zod_1.z.number())),
}))
    .or(zod_1.z.strictObject({
    dataType: zod_1.z.literal("Image"),
    sampleValues: zod_1.z.array(zod_1.z.string().refine((arg) => {
        return (0, utils_1.parseDataURI)(arg) !== undefined;
    }, "Invalid image Data URI")),
})));
exports.ContextFieldPropertiesHeaderPart$Schema = zod_1.z
    .object({
    description: zod_1.z.string().describe("Get or Set a brief description of the field"),
    instructions: zod_1.z
        .string()
        .describe("Get or Set the instructions that will be shown in the form while indexing the document"),
    label: zod_1.z
        .string()
        .describe("Get or Set the question that will be shown in the form while indexing the document"),
    humanName: zod_1.z.string(),
    controlSize: zod_1.z.enum(["None", "Small", "Normal", "Large"]).optional(),
    dictionaryDataElementId: zod_1.z
        .string()
        .refine(utils_1.isConvertibleToObjectID, "Invalid dictionaryDataElementId")
        .optional()
        .describe("Data Element ID in the organization's Data Dictionary"),
    saveToECM: zod_1.z
        .boolean()
        .optional()
        .describe("Determines if this field will be created in the ECM"),
    invisible: zod_1.z
        .boolean()
        .optional()
        .describe("Determines if a field created with the Data Element will not be shown in the generated document by default"),
    immutable: zod_1.z.boolean().optional().describe("Determines whether this field is immutable"),
    isUnique: zod_1.z
        .boolean()
        .optional()
        .describe("Determines if a field is considered for the document unique key"),
    classDataElementId: zod_1.z
        .string()
        .refine(utils_1.isConvertibleToObjectID, "Invalid classDataElementId")
        .optional(),
    classDataElementTag: zod_1.z.string().min(1).optional(),
    sequenceId: zod_1.z.string().optional(),
})
    .passthrough()
    .transform((arg) => {
    if (arg.classDataElementId !== undefined) {
        if (!arg.classDataElementTag) {
            const result = {
                description: arg.description,
                humanName: arg.humanName,
                instructions: arg.instructions,
                label: arg.label,
                controlSize: arg.controlSize,
                dictionaryDataElementId: arg.dictionaryDataElementId,
                immutable: arg.immutable,
                invisible: arg.invisible,
                isUnique: arg.isUnique,
                saveToECM: arg.saveToECM,
                classDataElementId: undefined,
                sequenceId: arg.sequenceId,
            };
            return result;
        }
        const result = {
            description: arg.description,
            humanName: arg.humanName,
            instructions: arg.instructions,
            label: arg.label,
            controlSize: arg.controlSize,
            dictionaryDataElementId: arg.dictionaryDataElementId,
            immutable: arg.immutable,
            invisible: arg.invisible,
            isUnique: arg.isUnique,
            saveToECM: arg.saveToECM,
            classDataElementId: arg.classDataElementId,
            classDataElementTag: arg.classDataElementTag,
            sequenceId: arg.sequenceId,
        };
        return result;
    }
    else {
        const result = {
            description: arg.description,
            humanName: arg.humanName,
            instructions: arg.instructions,
            label: arg.label,
            controlSize: arg.controlSize,
            dictionaryDataElementId: arg.dictionaryDataElementId,
            immutable: arg.immutable,
            invisible: arg.invisible,
            isUnique: arg.isUnique,
            saveToECM: arg.saveToECM,
            classDataElementId: undefined,
            sequenceId: arg.sequenceId,
        };
        return result;
    }
});
const CalculatedFieldTruePart$Schema = zod_1.z.object({
    isCalculated: zod_1.z.literal(true),
    isCalculatedAdvancedMode: zod_1.z.boolean(),
    calculatedField: logical_1.CalculatedField$Schema,
});
const CalculatedFieldPropertiesPart$Schema = zod_1.z
    .object({
    isCalculated: zod_1.z.boolean().optional(),
})
    .transform((val, ctx) => {
    if (val.isCalculated) {
        const r = CalculatedFieldTruePart$Schema.safeParse(val);
        if (!r.success) {
            r.error.issues.forEach((issue) => {
                ctx.addIssue(Object.assign({}, issue));
            });
            return zod_1.z.NEVER;
        }
        return r.data;
    }
    else {
        return {
            isCalculated: val.isCalculated,
        };
    }
});
exports.StringOrStringArray$Schema = zod_1.z.preprocess((arg) => {
    if (Array.isArray(arg)) {
        if (arg.length === 1 && arg[0] === "") {
            return undefined;
        }
        return arg;
    }
    else if (arg !== undefined) {
        return [arg];
    }
    return undefined;
}, zod_1.z.string().array());
exports.StringOrStringArrayWithCatch$Schema = exports.StringOrStringArray$Schema.optional().catch(undefined);
exports.NumberOrNumberArray$Schema = zod_1.z.preprocess((arg) => {
    if (Array.isArray(arg)) {
        return arg;
    }
    else if (arg !== undefined) {
        return [arg];
    }
    return undefined;
}, zod_1.z.number().array());
exports.NumberOrNumberArrayWithCatch$Schema = exports.NumberOrNumberArray$Schema.optional().catch(undefined);
exports.IntOrIntArray$Schema = zod_1.z.preprocess((arg) => {
    if (Array.isArray(arg)) {
        return arg;
    }
    else if (arg !== undefined) {
        return [arg];
    }
    return undefined;
}, zod_1.z.number().int().array());
exports.StringOrNumberOrArray$Schema = zod_1.z.preprocess((arg) => {
    if (Array.isArray(arg)) {
        return arg;
    }
    else if (arg !== undefined) {
        return [arg];
    }
    return undefined;
}, zod_1.z.string().or(zod_1.z.number()).array());
exports.StringOrNumberOrArrayWithCatch$Schema = exports.StringOrNumberOrArray$Schema.optional().catch(undefined);
exports.Date$Schema = zod_1.z.preprocess((arg) => {
    if (Array.isArray(arg)) {
        return arg;
    }
    else if (arg !== undefined) {
        if (arg instanceof Date) {
            return arg.getTime();
        }
        if (typeof arg === "string") {
            const d = Date.parse(arg);
            return d;
        }
        return arg;
    }
    return undefined;
}, zod_1.z.number());
exports.DateWithCatch$Schema = exports.Date$Schema.optional().catch(undefined);
const NumericContextFieldPropertiesPart$Schema = zod_1.z
    .object({
    dataType: zod_1.z.literal("Numeric"),
    minOccurs: zod_1.z.number().optional(),
    maxOccurs: zod_1.z.number().optional(),
    inputType: zod_1.z.enum(["TextBox", "Default"]),
    minValue: zod_1.z.number().int().optional(),
    maxValue: zod_1.z.number().int().optional(),
    defaultValue: exports.IntOrIntArray$Schema.optional(),
    sampleValue: exports.IntOrIntArray$Schema.optional(),
    autoCompleteValues: zod_1.z.literal(false).optional(),
    lineCount: zod_1.z.literal(0).optional(),
    maxLength: zod_1.z.literal(0).optional(),
    isSystemDate: zod_1.z.literal(false).optional(),
})
    .refine((arg) => {
    if (arg.minOccurs !== undefined && arg.maxOccurs !== undefined) {
        return arg.minOccurs <= arg.maxOccurs;
    }
    return true;
}, "minOccurs cannot be higher than maxOccurs")
    .refine((arg) => {
    if (arg.minValue !== undefined && arg.maxValue !== undefined) {
        return arg.minValue <= arg.maxValue;
    }
    return true;
}, "minValue cannot be higher than maxValue")
    .and(CalculatedFieldPropertiesPart$Schema);
exports.CurrencyContextFieldPropertiesPart$Schema = zod_1.z
    .object({
    dataType: zod_1.z.literal("Currency"),
    minOccurs: zod_1.z.number().optional(),
    maxOccurs: zod_1.z.number().optional(),
    inputType: zod_1.z.enum(["TextBox", "Default"]),
    minValue: zod_1.z.number().optional(),
    maxValue: zod_1.z.number().optional(),
    defaultValue: exports.NumberOrNumberArray$Schema.optional(),
    sampleValue: exports.NumberOrNumberArray$Schema.optional(),
    autoCompleteValues: zod_1.z.literal(false).optional(),
    lineCount: zod_1.z.literal(0).optional(),
    maxLength: zod_1.z.literal(0).optional(),
    isSystemDate: zod_1.z.literal(false).optional(),
})
    .refine((arg) => {
    if (arg.minOccurs !== undefined && arg.maxOccurs !== undefined) {
        return arg.minOccurs <= arg.maxOccurs;
    }
    return true;
}, "minOccurs cannot be higher than maxOccurs")
    .refine((arg) => {
    if (arg.minValue !== undefined && arg.maxValue !== undefined) {
        return arg.minValue <= arg.maxValue;
    }
    return true;
}, "minValue cannot be higher than maxValue")
    .and(CalculatedFieldPropertiesPart$Schema);
const LogicalContextFieldPropertiesPart$Schema = zod_1.z
    .object({
    dataType: zod_1.z.literal("Logical"),
    inputType: zod_1.z.enum(["Checkbox", "Switch", "Default"]),
    minOccurs: zod_1.z.literal(0).or(zod_1.z.literal(1)).optional(),
    maxOccurs: zod_1.z.literal(0).or(zod_1.z.literal(1)).optional(),
    defaultValue: zod_1.z.boolean().optional(),
    sampleValue: zod_1.z.boolean().optional(),
})
    .refine((arg) => {
    if (arg.minOccurs !== undefined && arg.maxOccurs !== undefined) {
        return arg.minOccurs <= arg.maxOccurs;
    }
    return true;
}, "minOccurs cannot be higher than maxOccurs");
const DateOrDateTimeContextFieldPropertiesPart$Schema = zod_1.z
    .object({
    dataType: zod_1.z.literal("Date").or(zod_1.z.literal("DateTime")),
    minOccurs: zod_1.z.number().optional(),
    maxOccurs: zod_1.z.number().optional(),
    isSystemDate: zod_1.z.boolean().optional(),
    inputType: zod_1.z.enum(["DateTimePicker", "Default"]),
    minValue: zod_1.z.number().optional(),
    maxValue: zod_1.z.number().optional(),
    defaultValue: zod_1.z.string().or(zod_1.z.number()).optional(),
    sampleValue: zod_1.z.string().or(zod_1.z.number()).optional(),
    dateMinMaxType: exports.DateMinMaxType$Schema.optional(),
})
    .refine((arg) => {
    if (arg.minOccurs !== undefined && arg.maxOccurs !== undefined) {
        return arg.minOccurs <= arg.maxOccurs;
    }
    return true;
}, "minOccurs cannot be higher than maxOccurs")
    .refine((arg) => {
    if (arg.minValue !== undefined && arg.maxValue !== undefined) {
        return arg.minValue <= arg.maxValue;
    }
    return true;
}, "minValue cannot be higher than maxValue");
const TimeContextFieldPropertiesPart$Schema = zod_1.z
    .object({
    dataType: zod_1.z.literal("Time"),
    minOccurs: zod_1.z.number().optional(),
    maxOccurs: zod_1.z.number().optional(),
    isSystemDate: zod_1.z.boolean(),
    inputType: zod_1.z.enum(["TimePicker", "Default"]),
    minValue: zod_1.z.number().optional(),
    maxValue: zod_1.z.number().optional(),
    defaultValue: zod_1.z.string().or(zod_1.z.number()).optional(),
    sampleValue: zod_1.z.string().or(zod_1.z.number()).optional(),
})
    .refine((arg) => {
    if (arg.minOccurs !== undefined && arg.maxOccurs !== undefined) {
        return arg.minOccurs <= arg.maxOccurs;
    }
    return true;
}, "minOccurs cannot be higher than maxOccurs")
    .refine((arg) => {
    if (arg.minValue !== undefined && arg.maxValue !== undefined) {
        return arg.minValue <= arg.maxValue;
    }
    return true;
}, "minValue cannot be higher than maxValue");
const ImageContextFieldPropertiesPart$Schema = zod_1.z
    .object({
    dataType: zod_1.z.literal("Image"),
    minOccurs: zod_1.z.number().optional(),
    maxOccurs: zod_1.z.number().optional(),
    inputType: zod_1.z.enum(["ImageUpload", "Signature", "Default"]),
    defaultValue: zod_1.z
        .string()
        .refine((arg) => (0, utils_1.parseDataURI)(arg) !== undefined)
        .optional(),
    sampleValue: zod_1.z
        .string()
        .refine((arg) => (0, utils_1.parseDataURI)(arg) !== undefined)
        .optional(),
})
    .refine((arg) => {
    if (arg.minOccurs !== undefined && arg.maxOccurs !== undefined) {
        return arg.minOccurs <= arg.maxOccurs;
    }
    return true;
}, "minOccurs cannot be higher than maxOccurs");
exports.ContextFieldPropertiesAllParts$Schema = zod_1.z
    .object({
    dataType: exports.DataType$Schema,
})
    .passthrough()
    .transform((val, ctx) => {
    switch (val.dataType) {
        case "Alphanumeric": {
            const r = alphanumeric_context_field_1.AlphanumericContextFieldPropertiesPart$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "Currency": {
            const r = exports.CurrencyContextFieldPropertiesPart$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "Date": {
            const r = DateOrDateTimeContextFieldPropertiesPart$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "DateTime": {
            const r = DateOrDateTimeContextFieldPropertiesPart$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "Image": {
            const r = ImageContextFieldPropertiesPart$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "Logical": {
            const r = LogicalContextFieldPropertiesPart$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "Numeric": {
            const r = NumericContextFieldPropertiesPart$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "Time": {
            const r = TimeContextFieldPropertiesPart$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
    }
});
exports.ContextFieldProperties$Schema = zod_1.z
    .object({})
    .passthrough()
    .superRefine((contextFieldProperties, ctx) => {
    const parsedHeader = exports.ContextFieldPropertiesHeaderPart$Schema.safeParse(contextFieldProperties);
    if (!parsedHeader.success) {
        parsedHeader.error.issues.forEach((issue) => ctx.addIssue(issue));
        return zod_1.z.NEVER;
    }
    const parsedAll = exports.ContextFieldPropertiesAllParts$Schema.safeParse(contextFieldProperties);
    if (!parsedAll.success) {
        parsedAll.error.issues.forEach((issue) => ctx.addIssue(issue));
        return zod_1.z.NEVER;
    }
})
    .transform((arg) => {
    return arg;
});
exports.ContextField$Schema = zod_1.z.strictObject({
    name: zod_1.z.string(),
    humanName: zod_1.z.string(),
    fullPath: zod_1.z.string(),
    properties: exports.ContextFieldProperties$Schema,
});
function getContextField(holder, fullPath) {
    const spl = fullPath.split("/");
    if (spl.length > 1) {
        const foundRecord = (holder.records || []).find((record) => record.name === spl[0]);
        if (!foundRecord) {
            return undefined;
        }
        return getContextField(foundRecord, spl.slice(1).join("/"));
    }
    else {
        return (holder.fields || []).find((field) => field.name === spl[0]);
    }
}
exports.getContextField = getContextField;
function getContextRecord(holder, fullPath) {
    const spl = fullPath.split("/");
    if (spl.length > 1) {
        const foundRecord = (holder.records || []).find((record) => record.name === spl[0]);
        if (!foundRecord) {
            return undefined;
        }
        return getContextRecord(foundRecord, spl.slice(1).join("/"));
    }
    else {
        return (holder.records || []).find((record) => record.name === spl[0]);
    }
}
exports.getContextRecord = getContextRecord;
const TemplateWizardFieldIsRecord$Schema = zod_1.z.lazy(() => zod_1.z.object({
    key: zod_1.z.string().nonempty(),
    isRecord: zod_1.z.literal(true),
    label: zod_1.z.string().nonempty(),
    fields: zod_1.z.array(exports.TemplateWizardField$Schema),
    isDuplicated: zod_1.z.boolean().optional(),
}));
const TemplateWizardFieldNotRecord$Schema = zod_1.z.object({
    key: zod_1.z.string().nonempty(),
    isRecord: zod_1.z.literal(false),
    label: zod_1.z.string().nonempty(),
    isDuplicated: zod_1.z.boolean().optional(),
});
exports.TemplateWizardField$Schema = zod_1.z.lazy(() => zod_1.z
    .object({
    isRecord: zod_1.z.boolean(),
})
    .passthrough()
    .transform((val, ctx) => {
    if (val.isRecord) {
        const r = TemplateWizardFieldIsRecord$Schema.safeParse(val);
        if (!r.success) {
            r.error.issues.forEach((issue) => {
                ctx.addIssue(Object.assign({}, issue));
            });
            return zod_1.z.NEVER;
        }
        return r.data;
    }
    else {
        const r = TemplateWizardFieldNotRecord$Schema.safeParse(val);
        if (!r.success) {
            r.error.issues.forEach((issue) => {
                ctx.addIssue(Object.assign({}, issue));
            });
            return zod_1.z.NEVER;
        }
        return r.data;
    }
}));
const mapEvaluateValToContextVal = (type, value) => {
    if (value === undefined) {
        return null;
    }
    switch (type) {
        case "string":
        case "number":
        case "boolean":
        case "Image":
            if (typeof value !== "object" && typeof value !== "function") {
                return value;
            }
            else {
                throw new Error(`Function 'mapEvaluateValToContextVal': was given a value with type '${typeof value}', but specified type was '${type}'.`);
            }
        case "Date":
        case "object":
            if (value instanceof Date || Array.isArray(value) || value === null) {
                return null;
            }
            else {
                if (typeof value !== "object" && typeof value !== "function") {
                    return value;
                }
                else {
                    throw new Error(`Function 'mapEvaluateValToContextVal': was given a value with type '${typeof value}', but specified type was '${type}'.`);
                }
            }
        case "function":
        case "array":
        case "undefined":
        default:
            return null;
    }
};
exports.mapEvaluateValToContextVal = mapEvaluateValToContextVal;
exports.DocumentCollectionFolderSortKind$Schema = zod_1.z.enum(["document-date", "folder-name", "creation-date", "update-date"]);
const convertToContextValue = (value) => {
    if (typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        value === null) {
        return value;
    }
    return undefined;
};
exports.convertToContextValue = convertToContextValue;
exports.SortDirection$Schema = zod_1.z.enum(["asc", "desc"]);
function toUpdateTemplateDefinitionRequest(contextDefinition, wizardDefinition, nameConfig, identifierConfig, identifierCollisionForcesNewVersion) {
    const updatedContextFields = (0, utils_1.linearizeContextFields)(contextDefinition).map((fld) => ({
        fullPath: fld.fullPath,
        label: fld.properties.label,
        saveToECM: !!fld.properties.saveToECM,
    }));
    const result = {
        wizardDefinition,
        updatedContextFields,
        nameConfig,
        identifierConfig,
        identifierCollisionForcesNewVersion,
    };
    return result;
}
exports.toUpdateTemplateDefinitionRequest = toUpdateTemplateDefinitionRequest;
function getDocumentTypePermissionList() {
    const permissionList = ["view", "edit", "delete"];
    return permissionList;
}
exports.getDocumentTypePermissionList = getDocumentTypePermissionList;
function getCollectionsPermissionsList() {
    const permissionList = ["view", "assign"];
    return permissionList;
}
exports.getCollectionsPermissionsList = getCollectionsPermissionsList;
function toTemplateContextRecordHolder(holder) {
    return {
        records: holder.records || [],
        fields: holder.fields,
    };
}
exports.toTemplateContextRecordHolder = toTemplateContextRecordHolder;
function isContextDefinitionMergeError(tgt) {
    return typeof tgt === "object" && tgt !== null && tgt.hasOwnProperty("isError");
}
exports.isContextDefinitionMergeError = isContextDefinitionMergeError;
function mergeGenerationContexts(intoContext, fromContext) {
    const mergedContextDefinition = Object.assign({}, intoContext.contextDefinition);
    const targetContextDefinition = fromContext.contextDefinition;
    const response = recursivelyMergeHolders(mergedContextDefinition, targetContextDefinition);
    if (isContextDefinitionMergeError(response)) {
        return response;
    }
    mergedContextDefinition.records = response.records || [];
    mergedContextDefinition.fields = response.fields;
    return {
        key: intoContext.key,
        documents: [...intoContext.documents, ...fromContext.documents],
        contextDefinition: mergedContextDefinition,
    };
}
exports.mergeGenerationContexts = mergeGenerationContexts;
function recursivelyMergeHolders(into, from) {
    const records = [];
    const fields = [];
    const errors = [];
    (into.records || []).forEach((record) => {
        records.push((0, utils_1.deepCopy)(record));
    });
    into.fields.forEach((field) => {
        fields.push((0, utils_1.deepCopy)(field));
    });
    for (const record of from.records || []) {
        const foundIndex = records.findIndex((rRecord) => rRecord.fullPath === record.fullPath);
        if (typeof foundIndex === "undefined" || foundIndex < 0) {
            records.push((0, utils_1.deepCopy)(record));
        }
        else {
            const foundRecord = records[foundIndex];
            const mergedRecord = Object.assign({}, foundRecord);
            const mergedHolder = recursivelyMergeHolders(foundRecord, record);
            if (isContextDefinitionMergeError(mergedHolder)) {
                return mergedHolder;
            }
            mergedRecord.records = mergedHolder.records || [];
            mergedRecord.fields = mergedHolder.fields;
            records.splice(foundIndex, 1, mergedRecord);
        }
    }
    for (const field of from.fields) {
        const foundIndex = fields.findIndex((fField) => fField.fullPath === field.fullPath);
        if (typeof foundIndex === "undefined" || foundIndex < 0) {
            fields.push((0, utils_1.deepCopy)(field));
        }
        else {
            const foundField = fields[foundIndex];
            if (foundField.properties.dataType !== field.properties.dataType) {
                return {
                    isError: true,
                    errors: [
                        `Field with key "${field.fullPath}" has different data type with the one being merged`,
                    ],
                };
            }
        }
    }
    if (errors.length) {
        return {
            isError: true,
            errors,
        };
    }
    return {
        records,
        fields,
    };
}
exports.recursivelyMergeHolders = recursivelyMergeHolders;
function isValueListContextField(contextField) {
    if (contextField.dataType !== "Alphanumeric") {
        return false;
    }
    switch (contextField.inputType) {
        case "TextArea":
        case "Default":
        case "TextBox":
            return false;
        case "CheckBoxList":
        case "Checkbox":
        case "DropDownList":
        case "Dropdown":
        case "Radio":
        case "RadioButtonList": {
            return !!contextField.valueList;
        }
        default:
            (0, utils_1.shouldNever)(contextField);
            return false;
    }
}
exports.isValueListContextField = isValueListContextField;
function isTextLineContextField(contextField) {
    if (contextField.dataType !== "Alphanumeric") {
        return false;
    }
    switch (contextField.inputType) {
        case "Default":
        case "TextBox":
            return true;
        case "TextArea":
        case "CheckBoxList":
        case "Checkbox":
        case "DropDownList":
        case "Dropdown":
        case "Radio":
        case "RadioButtonList": {
            return false;
        }
        default:
            (0, utils_1.shouldNever)(contextField);
            return false;
    }
}
exports.isTextLineContextField = isTextLineContextField;
function getFieldSampleData(field, dataElementSampleValueMap, moment) {
    const properties = field.properties;
    const dataType = properties.dataType;
    if (properties.dictionaryDataElementId) {
        const sampleVal = dataElementSampleValueMap[properties.dictionaryDataElementId];
        if (sampleVal !== undefined && Array.isArray(sampleVal.sampleValues)) {
            const value = sampleVal.sampleValues[0];
            const errors = (0, validations_1.contextValidateFieldValue)(field, value, 0, []);
            if (errors && !errors.length) {
                return value;
            }
        }
    }
    switch (properties.dataType) {
        case "Alphanumeric": {
            const minOccurs = properties.minOccurs || 1;
            if (minOccurs > 1) {
                const instances = [];
                for (let cnt = 0; cnt < minOccurs; cnt += 1) {
                    getStringSampleData(properties).forEach((i) => instances.push(i));
                }
                return instances;
            }
            else {
                return getStringSampleData(properties);
            }
        }
        case "Numeric": {
            const minOccurs = properties.minOccurs || 1;
            if (minOccurs > 1) {
                const instances = [];
                for (let cnt = 0; cnt < minOccurs; cnt += 1) {
                    const arr = getNumericSampleData(properties);
                    if (arr.length) {
                        instances.push(arr[0]);
                    }
                }
                return instances;
            }
            else {
                return getNumericSampleData(properties);
            }
        }
        case "Currency": {
            const minOccurs = properties.minOccurs || 1;
            if (minOccurs > 1) {
                const instances = [];
                for (let cnt = 0; cnt < minOccurs; cnt += 1) {
                    const arr = getCurrencySampleData(properties);
                    if (arr.length) {
                        instances.push(arr[0]);
                    }
                }
                return instances;
            }
            else {
                return getCurrencySampleData(properties);
            }
        }
        case "Date": {
            const minOccurs = properties.minOccurs || 1;
            if (minOccurs > 1) {
                const instances = [];
                for (let cnt = 0; cnt < minOccurs; cnt += 1) {
                    instances.push(getDateSampleData(properties, moment));
                }
                return instances;
            }
            else {
                return getDateSampleData(properties, moment);
            }
        }
        case "DateTime": {
            const minOccurs = properties.minOccurs || 1;
            if (minOccurs > 1) {
                const instances = [];
                for (let cnt = 0; cnt < minOccurs; cnt += 1) {
                    instances.push(getDateTimeSampleData(properties));
                }
                return instances;
            }
            else {
                return getDateTimeSampleData(properties);
            }
        }
        case "Time": {
            const minOccurs = properties.minOccurs || 1;
            if (minOccurs > 1) {
                const instances = [];
                for (let cnt = 0; cnt < minOccurs; cnt += 1) {
                    instances.push(getTimeSampleData(properties));
                }
                return instances;
            }
            else {
                return getTimeSampleData(properties);
            }
        }
        case "Logical": {
            return getBooleanSampleData(properties);
        }
        case "Image": {
            const minOccurs = properties.minOccurs || 1;
            if (minOccurs > 1) {
                const instances = [];
                for (let cnt = 0; cnt < minOccurs; cnt += 1) {
                    instances.push(getImageSampleData(properties));
                }
                return instances;
            }
            else {
                return getImageSampleData(properties);
            }
        }
        default:
            (0, utils_1.shouldNever)(properties);
            throw new Error(`Invalid dataType ${dataType}`);
    }
}
exports.getFieldSampleData = getFieldSampleData;
function getStringSampleData(properties) {
    if (properties.dataType !== "Alphanumeric") {
        throw new Error();
    }
    let value = properties.sampleValue;
    if (typeof value === "undefined") {
        value = properties.defaultValue;
        if (typeof value === "undefined") {
            value = [sampleString()];
        }
    }
    if (!Array.isArray(value)) {
        value = [value];
    }
    if (properties.inputType === "TextBox" || properties.inputType === "Default") {
        if (properties.inputMask) {
            value = [
                (0, validations_1.generateInputMaskString)(properties.inputMask, !!properties.inputMaskStoreFixedChars),
            ];
        }
    }
    if (properties.inputType === "TextBox" || properties.inputType === "TextArea") {
        const maxLength = properties.maxLength;
        if (maxLength && value && value.some((v) => v.length > maxLength)) {
            value = value.map((v) => v.substring(0, maxLength));
        }
    }
    else if (properties.inputType === "Checkbox" ||
        properties.inputType === "CheckBoxList" ||
        properties.inputType === "Dropdown" ||
        properties.inputType === "DropDownList" ||
        properties.inputType === "Radio" ||
        properties.inputType === "RadioButtonList") {
        if (properties.valueList && properties.valueList.length) {
            value = [getRandomValueListValue(properties.valueList)];
        }
    }
    return value || [];
}
function getNumericSampleData(properties) {
    if (properties.dataType !== "Numeric") {
        throw new Error();
    }
    let value = properties.sampleValue;
    if (typeof value === "undefined") {
        value = properties.defaultValue;
        if (typeof value === "undefined") {
            value = sampleNumeric();
        }
    }
    if (Array.isArray(value)) {
        return value;
    }
    return [value];
}
function getCurrencySampleData(properties) {
    if (properties.dataType !== "Currency") {
        throw new Error();
    }
    let value = properties.sampleValue;
    if (typeof value === "undefined") {
        value = properties.defaultValue;
        if (typeof value === "undefined") {
            value = sampleCurrency();
        }
    }
    if (Array.isArray(value)) {
        return value;
    }
    return [value];
}
function getDateSampleData(properties, moment) {
    if (properties.dataType !== "Date") {
        throw new Error();
    }
    let value = properties.sampleValue;
    if (typeof value === "undefined") {
        value = properties.defaultValue;
        if (typeof value === "undefined") {
            value = new Date().getTime();
        }
    }
    const parsed = new Date(value);
    let dateValue = Number.isNaN(parsed.getTime()) ? undefined : parsed;
    if (typeof dateValue === "undefined") {
        dateValue = new Date();
    }
    return moment(dateValue).startOf("day").toDate().toISOString();
}
function getDateTimeSampleData(properties) {
    if (properties.dataType !== "DateTime") {
        throw new Error();
    }
    let value = properties.sampleValue;
    if (typeof value === "undefined") {
        value = properties.defaultValue;
        if (typeof value === "undefined") {
            value = new Date().getTime();
        }
    }
    const parsed = new Date(value);
    let dateValue = Number.isNaN(parsed.getTime()) ? undefined : parsed;
    if (typeof dateValue === "undefined") {
        dateValue = new Date();
    }
    return dateValue.toISOString();
}
function getTimeSampleData(properties) {
    if (properties.dataType !== "Time") {
        throw new Error();
    }
    let value = properties.sampleValue;
    if (typeof value === "undefined") {
        value = properties.defaultValue;
        if (typeof value === "undefined") {
            value = new Date().getTime();
        }
    }
    const parsed = new Date(value);
    let dateValue = Number.isNaN(parsed.getTime()) ? undefined : parsed;
    if (typeof dateValue === "undefined") {
        dateValue = new Date();
    }
    return dateValue.toISOString();
}
function getBooleanSampleData(properties) {
    if (properties.dataType !== "Logical") {
        throw new Error();
    }
    let value = properties.sampleValue;
    if (typeof value === "undefined") {
        value = properties.defaultValue;
        if (typeof value === "undefined") {
            value = false;
        }
    }
    return value;
}
function getImageSampleData(properties) {
    if (properties.dataType !== "Image") {
        throw new Error();
    }
    let value = properties.sampleValue;
    if (typeof value === "undefined") {
        value = properties.defaultValue;
        if (typeof value === "undefined") {
            value = (0, utils_1.imageSampleDataURI)();
        }
    }
    return value;
}
function sampleString() {
    return "ABCDEFGHIJKLM";
}
function sampleNumeric() {
    return [1234];
}
function sampleCurrency() {
    return [1234.56];
}
function getRandomValueListValue(items) {
    return items[Math.floor(Math.random() * items.length)].value;
}
function getVariablePartDateTimeFilter(properties, filter) {
    if (properties.dataType === "Date" || properties.dataType === "DateTime") {
        return {
            dataType: properties.dataType,
            filter,
        };
    }
    return {
        dataType: properties.dataType,
        filter: undefined,
    };
}
exports.getVariablePartDateTimeFilter = getVariablePartDateTimeFilter;
function documentIsExpired(expirationDate) {
    const currentDate = Date.now();
    return currentDate >= expirationDate;
}
exports.documentIsExpired = documentIsExpired;
function documentIsAboutToExpire(expirationDate, documentTypeExpirationWarningDays) {
    const daysToExpiration = documentTypeExpirationWarningDays;
    const currentDate = Date.now();
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + daysToExpiration);
    return expirationDate > currentDate && expirationDate <= futureDate.getTime();
}
exports.documentIsAboutToExpire = documentIsAboutToExpire;
const calculateDocumentCounts = (folder, countStack) => {
    const documentInstanceSet = new Set();
    const expiredInstanceSet = new Set();
    const aboutToExpireInstanceSet = new Set();
    const newCountStack = [
        ...countStack,
        {
            issueCount: 0,
            documentInstanceSet,
            expiredInstanceSet,
            aboutToExpireInstanceSet,
        },
    ];
    const documents = folder.documents;
    folder.validations.forEach((validation) => {
        if (validation.status === "invalid") {
            newCountStack.forEach((countInstance) => {
                countInstance.issueCount++;
            });
        }
    });
    for (const document of documents) {
        if (document.type === "document") {
            if (document.expirationDate && documentIsExpired(document.expirationDate)) {
                newCountStack.forEach((countInstance) => {
                    const { expiredInstanceSet: childExpiredInstanceSet } = countInstance;
                    childExpiredInstanceSet.add(document.documentId);
                });
            }
            else if (document.expirationDate &&
                documentIsAboutToExpire(document.expirationDate, document.documentTypeExpirationWarningDays)) {
                newCountStack.forEach((countInstance) => {
                    const { aboutToExpireInstanceSet: childAboutToExpireInstanceSet } = countInstance;
                    childAboutToExpireInstanceSet.add(document.documentId);
                });
            }
            newCountStack.forEach((countInstance) => {
                const { documentInstanceSet: childDocumentInstanceSet } = countInstance;
                childDocumentInstanceSet.add(document.documentId);
            });
        }
    }
    if (folder.folders) {
        for (const childFolder of folder.folders) {
            (0, exports.calculateDocumentCounts)(childFolder, newCountStack);
        }
    }
    const topStackElement = newCountStack[newCountStack.length - 1];
    folder.counts = {
        issueCount: topStackElement.issueCount,
        documentCount: topStackElement.documentInstanceSet.size,
        expiredCount: topStackElement.expiredInstanceSet.size,
        toExpireCount: topStackElement.aboutToExpireInstanceSet.size,
    };
};
exports.calculateDocumentCounts = calculateDocumentCounts;
function isClassDataElement(properties) {
    if (properties.classDataElementId !== undefined) {
        return true;
    }
    return false;
}
exports.isClassDataElement = isClassDataElement;
function isCalculatedField(properties) {
    if ((properties.dataType === "Currency" || properties.dataType === "Numeric") &&
        properties.isCalculated) {
        return true;
    }
    return false;
}
exports.isCalculatedField = isCalculatedField;
function formValueToValueType(moment, contextFieldProperties, currentValue) {
    switch (contextFieldProperties.dataType) {
        case "Alphanumeric":
            return {
                dataType: contextFieldProperties.dataType,
                value: currentValue === null
                    ? ""
                    : currentValue === undefined
                        ? undefined
                        : Array.isArray(currentValue)
                            ? currentValue.map((v) => (v === null || v === undefined ? "" : `${v}`))
                            : `${currentValue}`,
            };
        case "Numeric":
            return { dataType: "Numeric", value: Number(currentValue) };
        case "Currency":
            return { dataType: "Currency", value: Number(currentValue) };
        case "Logical":
            const val = currentValue === null
                ? false
                : currentValue === undefined
                    ? false
                    : Array.isArray(currentValue)
                        ? !!currentValue[0]
                        : typeof currentValue === "boolean"
                            ? currentValue
                            : false;
            return { dataType: "Logical", value: val };
        case "Date":
        case "DateTime":
        case "Time":
            if (typeof currentValue === "string" || typeof currentValue === "number") {
                return { dataType: contextFieldProperties.dataType, value: currentValue };
            }
            else if (Array.isArray(currentValue) &&
                (typeof currentValue[0] === "string" || typeof currentValue[0] === "number")) {
                const vals = currentValue.reduce((acc, next) => {
                    if (typeof next !== "boolean") {
                        if (next instanceof Date) {
                            acc.push(next.getTime());
                        }
                        else if (moment.isMoment(next)) {
                            acc.push(next.toDate().getTime());
                        }
                        else {
                            acc.push(next);
                        }
                    }
                    return acc;
                }, []);
                return { dataType: contextFieldProperties.dataType, value: vals };
            }
            else if (currentValue instanceof Date || moment.isMoment(currentValue)) {
                return { dataType: contextFieldProperties.dataType, value: currentValue.toString() };
            }
            else {
                return { dataType: contextFieldProperties.dataType, value: undefined };
            }
            break;
        case "Image":
            if (typeof currentValue === "string" || Array.isArray(currentValue)) {
                return {
                    dataType: "Image",
                    value: Array.isArray(currentValue) ? currentValue.map(String) : String(currentValue),
                };
            }
            else {
                return { dataType: "Alphanumeric", value: undefined };
            }
            break;
        default:
            (0, utils_1.shouldNever)(contextFieldProperties);
            return { dataType: "Alphanumeric", value: undefined };
    }
}
exports.formValueToValueType = formValueToValueType;
