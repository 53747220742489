import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CircleCloseSvgIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  onClick,
}) => {
  return (
    <svg
      //stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 16 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842854 5.87827 0 8 0C10.1217 0 12.1566 0.842854 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8ZM5.46909 6.50327L6.94109 7.97236L5.46909 9.46909C5.40008 9.53637 5.34524 9.61679 5.30779 9.70559C5.27035 9.7944 5.25106 9.8898 5.25106 9.98618C5.25106 10.0826 5.27035 10.178 5.30779 10.2668C5.34524 10.3556 5.40008 10.436 5.46909 10.5033C5.53279 10.5782 5.61203 10.6385 5.70132 10.6798C5.79061 10.7211 5.88781 10.7425 5.98618 10.7425C6.08456 10.7425 6.18176 10.7211 6.27105 10.6798C6.36033 10.6385 6.43957 10.5782 6.50327 10.5033L7.97236 9.05964L9.46909 10.5033C9.53279 10.5782 9.61203 10.6385 9.70132 10.6798C9.79061 10.7211 9.88781 10.7425 9.98618 10.7425C10.0846 10.7425 10.1818 10.7211 10.271 10.6798C10.3603 10.6385 10.4396 10.5782 10.5033 10.5033C10.5782 10.4396 10.6385 10.3603 10.6798 10.271C10.7211 10.1818 10.7425 10.0846 10.7425 9.98618C10.7425 9.88781 10.7211 9.79061 10.6798 9.70132C10.6385 9.61203 10.5782 9.53279 10.5033 9.46909L9.05964 7.97164L10.5033 6.50255C10.5782 6.43885 10.6385 6.35961 10.6798 6.27032C10.7211 6.18103 10.7425 6.08383 10.7425 5.98545C10.7425 5.88708 10.7211 5.78988 10.6798 5.70059C10.6385 5.6113 10.5782 5.53206 10.5033 5.46836C10.436 5.39936 10.3556 5.34451 10.2668 5.30707C10.178 5.26962 10.0826 5.25033 9.98618 5.25033C9.8898 5.25033 9.7944 5.26962 9.70559 5.30707C9.61679 5.34451 9.53637 5.39936 9.46909 5.46836L7.97164 6.94036L6.50255 5.46836C6.43526 5.39936 6.35485 5.34451 6.26604 5.30707C6.17724 5.26962 6.08183 5.25033 5.98545 5.25033C5.88908 5.25033 5.79367 5.26962 5.70486 5.30707C5.61606 5.34451 5.53564 5.39936 5.46836 5.46836C5.39929 5.5357 5.34441 5.6162 5.30696 5.7051C5.26951 5.794 5.25025 5.88951 5.25032 5.98597C5.25039 6.08244 5.26978 6.17791 5.30736 6.26676C5.34493 6.35561 5.39992 6.43603 5.46909 6.50327Z"
        fill="#CC0000"
      />
    </svg>
  );
};
