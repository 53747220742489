import {
  AccordionExpandable,
  Box,
  Button,
  Divider,
  FormControllerProvider,
  MarkdownViewer,
  Skeleton,
  Spinner,
  Text,
  useColors,
  BannerSlim,
} from "@prodoctivity/design-system";

import { FancyDateTime } from "@prodoctivity/design-system";
import { FunctionComponent, useEffect, useMemo } from "react";
import { Page } from "../../../components/Layout/Page";
import { BlueCheckCircleSvgIcon } from "../../../svg/BlueCheckCircleSvgIcon";
import { BundleForm } from "./BundleForm";
import { BundleIcon } from "./BundleIcon";
import { useBundleView } from "./hooks";

export const BundleConfigViewPage: FunctionComponent = () => {
  const {
    breadCrumbEntries,
    resources,
    moment,
    bundleConfig,
    isLoading,
    allChanges,
    state,
    validateApplyBundle,
    cancelDisplayForm,
    isMutating,
    refetch,
  } = useBundleView();

  const { colors } = useColors();

  const errorBanner = useMemo(() => {
    if (
      !bundleConfig ||
      bundleConfig.ApplicationStatus !== "Error" ||
      !bundleConfig.BundleApplicationErrorMessages ||
      bundleConfig.BundleApplicationErrorMessages.length === 0
    ) {
      return <></>;
    }

    return (
      <Box marginBottom={4} borderColor={colors.error} color={colors.other600} borderRadius={6}>
        {bundleConfig.BundleApplicationErrorMessages.map((msg, i) => {
          return <BannerSlim key={`error-message-slim-banner-${i}`} type="error" message={msg} />;
        })}
      </Box>
    );
  }, [bundleConfig, colors.error, colors.other600]);

  useEffect(() => {
    const timer = setInterval(() => {
      refetch();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [refetch]);

  const buttonLayout = useMemo(() => {
    if (!bundleConfig) {
      return <Button disabled={true} color={"blue"} text={resources.apply} />;
    } else {
      switch (bundleConfig.ApplicationStatus) {
        case "Not-Applied":
        case "Error":
          return (
            <Button
              disabled={isMutating}
              color={"blue"}
              onClick={() => validateApplyBundle(bundleConfig.inputData || {})}
              text={
                !bundleConfig.lastVersionApplied ? resources.apply : resources.bundle.updateBundle
              }
            />
          );
          break;
        case "Applied":
          return (
            <Button
              disabled={true}
              color={"blue"}
              text={resources.applied}
              iconEnd={bundleConfig.lastVersionApplied !== undefined ? "check-circle" : undefined}
            />
          );
          break;
        case "In-process":
          return <Spinner show={true} />;
          break;
      }
    }
  }, [
    bundleConfig,
    isMutating,
    resources.applied,
    resources.apply,
    resources.bundle.updateBundle,
    validateApplyBundle,
  ]);

  if (!bundleConfig || isLoading) {
    return (
      <Page breadCrumbEntries={breadCrumbEntries}>
        <Skeleton show={true} height={500}></Skeleton>
      </Page>
    );
  }

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box color={colors.white} padding={3}>
        <Box padding={3}>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" height={"100%"} marginEnd={4}>
              <BundleIcon bundleIdentifier={bundleConfig.identifier} />
            </Box>
            <Box flex="grow">
              <Text size="500" weight="bold">
                {bundleConfig.name}
              </Text>
              <Box display="flex" gap={2}>
                <Text size="200">
                  {resources.bundle.latestVersion} (v{bundleConfig.versionNumber})
                </Text>
                {"-"}

                <FancyDateTime
                  moment={moment}
                  resources={resources}
                  value={bundleConfig.versionDate}
                  color={colors.primary400}
                  size="200"
                />
              </Box>
            </Box>
            <Box
              right
              direction="column"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {!state.displayForm && (
                <Box width={140} marginBottom={3}>
                  {buttonLayout}
                </Box>
              )}

              {bundleConfig.lastVersionApplied &&
                bundleConfig.lastVersionApplied < bundleConfig.versionNumber &&
                !state.displayForm && (
                  <>
                    <Text size="200" color={colors.primary}>
                      {resources.updateAvailable}
                    </Text>
                  </>
                )}
            </Box>
          </Box>
        </Box>

        {errorBanner}

        <Divider />

        {state.displayForm &&
          bundleConfig.inputContextDefinition &&
          bundleConfig.inputWizardDefinition && (
            <FormControllerProvider
              contextDefinition={bundleConfig.inputContextDefinition}
              wizardDefinition={bundleConfig.inputWizardDefinition}
              dataLinkMappings={[]}
              executeDataLink={undefined}
              initialContext={bundleConfig.inputData}
              moment={moment}
            >
              <BundleForm
                bundleConfig={bundleConfig}
                isLoading={isMutating}
                onCancel={cancelDisplayForm}
                onApply={validateApplyBundle}
              />
            </FormControllerProvider>
          )}

        {!state.displayForm && (
          <Box padding={3}>
            <MarkdownViewer
              key={bundleConfig.identifier}
              markdownText={bundleConfig.markdownDescription}
            />
          </Box>
        )}

        {!state.displayForm && (
          <Box>
            <Box padding={3} color={colors.primaryHover0}>
              <Text weight="bold" color={colors.primary} size="400">
                {resources.bundle.listOfChangesToImplement}
              </Text>
              {Object.keys(allChanges).map((key: string) => {
                const changes = allChanges[key];
                if (!changes || changes.changeList.length === 0) {
                  return <Box key={key}></Box>;
                }
                return (
                  <Box key={key}>
                    <AccordionExpandable
                      accessibilityCollapseLabel={resources.collapse}
                      accessibilityExpandLabel={resources.expand}
                      id={`changes_${key}`}
                      items={[
                        {
                          title: changes.i18n,
                          summary: [`${changes.changeList.length} ${resources.items}`],
                          children: (
                            <Box paddingLeft={8} paddingTop={2}>
                              {changes.changeList
                                .sort((a, b) => a.field.localeCompare(b.field))
                                .map((d) => (
                                  <Box key={d.field} marginBottom={3}>
                                    <Box display="flex" direction="column">
                                      <Box display="flex" gap={2} alignItems="center">
                                        <BlueCheckCircleSvgIcon width={20} height={17} />
                                        <Text weight="bold">{d.field}</Text>
                                      </Box>
                                      <Box display="flex" direction="row">
                                        <Box margin={4} />
                                        <Text>{d.description}</Text>
                                      </Box>
                                    </Box>
                                  </Box>
                                ))}
                            </Box>
                          ),
                        },
                      ]}
                    ></AccordionExpandable>
                  </Box>
                );
              })}
            </Box>

            <Box marginTop={3} padding={3} color={colors.primaryHover0}>
              <Text weight="bold" color={colors.primary} size="400">
                {resources.bundle.changeLog}
              </Text>
              {bundleConfig.changeLog.map((change) => {
                return (
                  <Box
                    borderRadius={4}
                    paddingY={2}
                    color={colors.white}
                    marginTop={2}
                    paddingLeft={3}
                    key={`changeInfo_${change.date}`}
                  >
                    <Box display="flex" gap={1}>
                      <Text weight="bold">
                        {resources.version} {change.version} (
                      </Text>
                      <FancyDateTime
                        moment={moment}
                        resources={resources}
                        weight={"bold"}
                        value={change.date}
                      />
                      <Text weight="bold">)</Text>
                    </Box>
                    <Box paddingLeft={8} paddingTop={2}>
                      {change.descriptions.map((description, i) => (
                        <Box key={`changeItem_${change.date}_${i}`} marginBottom={3}>
                          <Box display="flex" alignItems="center" gap={2}>
                            <Box width={20} height={17}>
                              <BlueCheckCircleSvgIcon width={20} height={17} />
                            </Box>
                            <Box display="flex">
                              <Text>{description}</Text>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Page>
  );
};

export default BundleConfigViewPage;
