import {
  Box,
  ChevronSvgIcon,
  Divider,
  DocumentViewer,
  DocumentViewerEvents,
  TapArea,
  Text,
  Tooltip,
  useColors,
  usePubSub,
} from "@prodoctivity/design-system";
import { FC, useState } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { CircleCheckSvgIcon } from "../../svg/CircleCheckSvgIcon";
import { TemplateIconSvg } from "../../svg/TemplateIconSvg";
import { useCombinedDocumentViewer } from "./hooks";

type Props = {
  combinedTemplateId: string;
  documents?: Record<string, Blob>;
  templateVersionList: Array<{ name: string; templateId: string; templateVersionId: string }>;
  documentKeys: {
    templateKey: string;
    documentKey: string;
    distributions: never[];
    plugins: never[];
  }[];
};

export const CombinedDocumentViewer: FC<Props> = ({
  combinedTemplateId,
  documents,
  templateVersionList,
  documentKeys,
}) => {
  const { colors } = useColors();
  const { moment } = useAppTranslation();
  const { resources, currentTemplate, setCurrent, showSidePanel, setShowPanel, document } =
    useCombinedDocumentViewer({
      combinedTemplateId,
      documents,
      documentKeys,
      templateVersionList,
    });
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { eventManager } = usePubSub<DocumentViewerEvents>();

  return (
    <Box flex="grow" display="flex" height="100%">
      <Box flex="grow" borderStyle="sm">
        <DocumentViewer
          key={currentTemplate}
          height="75vh"
          src={document}
          strategy="normal"
          resources={resources}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          eventManager={eventManager}
          moment={moment}
        />
      </Box>
      {showSidePanel && (
        <Box width={360}>
          <TapArea onTap={() => setShowPanel((prev) => !prev)}>
            <Box paddingX={3} height={36} display="flex" alignItems="center">
              <ChevronSvgIcon direction="right" />
              <Text size="300" weight="bold">
                {resources.generateDocumentPage.documentsList}
              </Text>
            </Box>
          </TapArea>
          <Divider />
          <Box paddingX={3}>
            {templateVersionList.map((t) => {
              const documentKeyInfo = documentKeys.find(
                (d) => d.templateKey === t.templateVersionId
              );
              return (
                <TapArea key={t.templateVersionId} onTap={() => setCurrent(t.templateVersionId)}>
                  <Box
                    display="flex"
                    justifyContent="between"
                    alignItems="center"
                    paddingX={3}
                    marginTop={2}
                    color={
                      t.templateVersionId === currentTemplate
                        ? colors.primaryHover0
                        : colors.neutral300
                    }
                    borderRadius={6}
                    borderStyle="lg"
                  >
                    <Box paddingY={2}>
                      <Box marginBottom={1}>
                        <Text color={colors.subtle} ellipsisLength={36}>
                          {t.name}
                        </Text>
                      </Box>
                      <Text
                        color={t.templateVersionId === currentTemplate ? colors.primary : undefined}
                        weight={t.templateVersionId === currentTemplate ? "bold" : "normal"}
                        ellipsisLength={36}
                        size="200"
                      >
                        {documentKeyInfo?.documentKey}
                      </Text>
                    </Box>
                    {t.templateVersionId === currentTemplate && (
                      <CircleCheckSvgIcon color={colors.primary} />
                    )}
                  </Box>
                </TapArea>
              );
            })}
          </Box>
        </Box>
      )}
      <Box paddingY={2} paddingX={2} color={colors.neutral300}>
        <TapArea onTap={() => setShowPanel((prev) => !prev)}>
          <Tooltip text={resources.generateDocumentPage.documentsList}>
            <TemplateIconSvg color={showSidePanel ? colors.primary : undefined} />
          </Tooltip>
        </TapArea>
      </Box>
    </Box>
  );
};
