import { isCalculatedField } from "@prodoctivity/shared";
import type {
  ContextField,
  ContextRecord,
  TemplateContextDefinition,
} from "@prodoctivity/shared/src/index-types";
import { FormDefinition, Group } from "./types";

export const recordToGroup = (record: ContextRecord): Group => {
  return {
    fullPath: record.fullPath,
    groupName: record.name,
    question: record.humanName,
    groups: (record.records || []).map((r) => recordToGroup(r)),
    fields: record.fields.filter((field) => !isCalculatedField(field.properties)),
  };
};

export const contextToFormDefinition = (context?: TemplateContextDefinition): FormDefinition => {
  if (context === undefined) {
    return {
      contextFields: [],
      groups: [],
      allowedListValues: [],
    };
  }
  const dataElements: Array<ContextField> = context.fields.filter(
    (field: any) => field.properties.isCalculated !== true
  );
  const groups: Array<Group> = context.records.map((record) => recordToGroup(record));

  return {
    contextFields: dataElements,
    groups: groups,
    allowedListValues: [],
  };
};
