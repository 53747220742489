import type { EcmDocument, EmailRecipient } from "@prodoctivity/types";
import { useCallback, useMemo, useState } from "react";

import { validateEmail } from "@prodoctivity/shared";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useTemplateVersionList } from "../../hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { useCacheManager } from "../../utils";

export function useGetDocumentVersion(
  documentId: string | undefined,
  documentVersionId: string | undefined
) {
  const { getDocument } = useServices();

  const getDocumentCallback = useCallback(async () => {
    if (documentId && documentVersionId) {
      return getDocument(documentId, documentVersionId);
    }

    return {
      document: undefined,
    };
  }, [documentId, documentVersionId, getDocument]);

  const queryKey = `documents/${documentId}/versions/${documentVersionId}`;

  const {
    data: documentResponse,
    isLoading,
    isError: isDocumentError,
    remove,
  } = useOrganizationQuery(queryKey, getDocumentCallback, {
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    keepPreviousData: false,
  });

  return {
    isLoading,
    isDocumentError,
    documentResponse,
    remove,
  };
}

export function useGetDocumentLatestVersion(id: string | undefined) {
  const { documentId } = useParams();

  const selectedDocumentId = id || documentId;

  const { getDocumentSummaryInfo } = useServices();

  const getLatestVersion = useCallback(async () => {
    if (!selectedDocumentId) {
      return {
        document: undefined,
      };
    }

    return getDocumentSummaryInfo(selectedDocumentId);
  }, [getDocumentSummaryInfo, selectedDocumentId]);

  const queryKey = `document-latest-version/${selectedDocumentId}`;

  const {
    data: documentResponse,
    isLoading,
    refetch: rawRefetch,
    remove,
  } = useOrganizationQuery(queryKey, getLatestVersion, {
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: true,
  });

  const refetch = useCallback(() => {
    remove();
    rawRefetch();
  }, [remove, rawRefetch]);

  return {
    isLoading,
    documentResponse,
    refetch,
  };
}

export function useGetDocumentVersions(documentId: string) {
  const { getDocumentVersions } = useServices();
  return useOrganizationQuery(
    `document-version-list/${documentId}`,
    () => {
      if (documentId) {
        return getDocumentVersions(documentId);
      }
    },
    {
      enabled: !!documentId,
    }
  );
}

export function useGetDocumentVersionGenerationContext(
  documentId: string | undefined,
  documentVersionId: string | undefined,
  generationToken: string | undefined
) {
  const { getDocumentVersionGenerationContext } = useServices();
  const doFetch = useCallback(async () => {
    if (documentId && documentVersionId) {
      return getDocumentVersionGenerationContext(documentId, documentVersionId);
    } else {
      return {
        documentVersionGenerationContext: undefined,
      };
    }
  }, [documentId, documentVersionId, getDocumentVersionGenerationContext]);
  return useOrganizationQuery(
    `/document-version-generation-context/${documentId}/versions/${documentVersionId}`,
    doFetch,
    {
      enabled: !!generationToken,
    }
  );
}

export function useGetDocumentsByGenerationToken(generationToken: string) {
  const { getDocumentsByGenerationToken } = useServices();
  return useOrganizationQuery(
    `document-generationToken-list/${generationToken}`,
    () => {
      if (generationToken) {
        return getDocumentsByGenerationToken({ generationToken });
      }
    },
    {
      enabled: !!generationToken,
      cacheTime: 3 * 60 * 1000,
      staleTime: 3 * 60 * 1000,
    }
  );
}

export function useGetGeneratedDocuments() {
  const { generationToken, documentId } = useParams();
  const { getDocumentsByGenerationToken, getDocumentByGenerationToken } = useServices();

  const [currentDocumentId, setCurrentDocumentId] = useState<string | undefined>(undefined);

  const useGetGeneratedDocuments = useCallback(async () => {
    if (!generationToken) return undefined;
    return getDocumentsByGenerationToken({ generationToken }).then((generation) => {
      if (!documentId && generation.documents.length > 0) {
        setCurrentDocumentId(() => generation.documents[0].documentId);
      }

      return generation;
    });
  }, [documentId, getDocumentsByGenerationToken, generationToken]);

  const { data: generationInfo, isLoading } = useOrganizationQuery(
    `/documents-by-generation-token/:${generationToken}/`,
    useGetGeneratedDocuments,

    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const useGetGeneratedDocumentId = useCallback(async () => {
    if (currentDocumentId && generationToken) {
      const document = await getDocumentByGenerationToken({
        generationToken,
        documentId: currentDocumentId,
      });

      return document;
    }
    return undefined;
  }, [currentDocumentId, getDocumentByGenerationToken, generationToken]);

  const { data: documentInfo, isLoading: isLoadingDocument } = useOrganizationQuery(
    `/documents-by-generation-token/${generationToken}/document/${currentDocumentId}`,
    useGetGeneratedDocumentId,
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  return {
    generationInfo,
    documentInfo,
    isSingleDocumentView: !!documentId,
    isLoading,
    isLoadingDocument,
    setCurrentDocumentId,
  };
}

export function useGenerationToken(): string {
  const { generationToken } = useParams();
  return generationToken || "";
}

export const useViewerSideBar = (toggleDocumentsList: () => void) => {
  const [showDocumentInfo, setShowDocumentInfo] = useState(false);

  const toggleDocumentInfo = useCallback(() => {
    setShowDocumentInfo((prevShowDocumentInfo) => !prevShowDocumentInfo);
  }, []);

  const toggleDocumentsListWithReset = useCallback(() => {
    setShowDocumentInfo(false);
    toggleDocumentsList();
  }, [toggleDocumentsList]);

  return {
    showDocumentInfo,
    toggleDocumentInfo,
    toggleDocumentsListWithReset,
  };
};

export function useDocumentViewerPage() {
  const { resources } = useAppTranslation();
  const { documentId, documentVersionId } = useParams();
  const { getDocumentTypeInfo } = useServices();
  const organizationNavigate = useOrganizationNavigate();

  const { clearQueryCache } = useCacheManager();

  const { documentResponse, isLoading, isDocumentError } = useGetDocumentVersion(
    documentId,
    documentVersionId
  );
  const documentTypeId = documentResponse?.document?.documentTypeId;

  const getDocumentTypeInfoDetails = useCallback(async () => {
    if (documentTypeId) {
      const documentTypeResponse = await getDocumentTypeInfo(documentTypeId);
      return documentTypeResponse;
    }
    return {
      documentType: undefined,
    };
  }, [documentTypeId, getDocumentTypeInfo]);

  const { data: documentTypeInfo, isLoading: isLoadingDocumentTypeInfo } = useOrganizationQuery(
    `document-type-details/${documentTypeId}`,
    getDocumentTypeInfoDetails,
    { staleTime: 60 * 1000, refetchOnWindowFocus: false, enabled: documentTypeId !== undefined }
  );

  return {
    resources,
    organizationNavigate,
    documentTypeInfo,
    isLoadingDocumentTypeInfo,
    documentResponse,
    isLoading,
    isDocumentError,
    clearQueryCache,
  };
}

export function useTemplateVersions(templateVersionId: string) {
  const { data: versionList, isLoading: versionListLoading } = useTemplateVersionList(
    templateVersionId || ""
  );

  const versions = versionList ? versionList.templateVersions : [];

  return {
    versions,
    versionListLoading,
  };
}

export function useSendVialEmail(document: EcmDocument) {
  const { documentId, documentVersionId } = document;
  const { shareDocument } = useServices();
  const organizationNavigate = useOrganizationNavigate();
  const { resources } = useAppTranslation();
  const subject = `${resources.prodoctivityDocSharedWithYou} ${document.name}`;
  const [message, setMessageValue] = useState<string>(
    `${
      resources.sendViaEmailPage.theFollowingMail
    } ${resources.sendViaEmailPage.theMailHasDocAttach.replace("{documentName}", document.name)}`
  );
  const [textBoxValue, setTextBoxValue] = useState("");
  const [tags, setTags] = useState<{ value: string; label: string }[]>([]);
  const [toastMessage, setToastMessage] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [showToast, setShowToast] = useState<{ show: boolean; isError: boolean }>({
    show: false,
    isError: false,
  });
  const [recipients, setRecipients] = useState<EmailRecipient[]>([]);
  const [isEmailValid, setEmailValid] = useState(false);

  const isButtonEnabled = useMemo(() => {
    return (textBoxValue.length > 0 || tags.length > 0) && (isEmailValid || tags.length > 0);
  }, [textBoxValue, tags, isEmailValid]);

  const handleTagRemoval = useCallback(
    (removedTagValue: string) => {
      const updatedTags = tags.filter((tag) => tag.value !== removedTagValue);
      const updatedMails = recipients.filter((mail) => {
        return mail.name !== removedTagValue && mail.email !== removedTagValue;
      });

      setRecipients(updatedMails);
      setTags(updatedTags);
    },
    [tags, recipients]
  );

  const sendEmail = useCallback(async () => {
    try {
      setSendingEmail(true);
      await shareDocument(
        documentId,
        documentVersionId,
        { name: "", email: "" },
        recipients,
        subject,
        message
      );
      setSendingEmail(false);
      return { success: true, error: null };
    } catch (e) {
      console.error(e);
      setSendingEmail(false);
      return { success: false, error: e };
    }
  }, [documentId, documentVersionId, subject, shareDocument, recipients, message]);

  const mutation = useMutation(sendEmail, {
    onSuccess(result) {
      if (result.success) {
        setToastMessage(resources.sendViaEmailPage.documentSent);
        setShowToast({ show: true, isError: false });
        setTimeout(() => {
          setShowToast({ show: false, isError: false });
        }, 4500);
        setRecipients([]);
        setTags([]);
        setTextBoxValue("");
        setMessageValue("");
      } else {
        setToastMessage(resources.validations.couldntSendEmail);
        setShowToast({ show: true, isError: true });
        setTimeout(() => {
          setShowToast({ show: false, isError: true });
        }, 4500);
      }
    },
    onError() {
      setToastMessage(resources.validations.couldntSendEmail);
      setShowToast({ show: true, isError: true });
      setTimeout(() => {
        setShowToast({ show: false, isError: true });
      }, 4500);
    },
  });

  const handleSubmit = useCallback(async () => {
    if (validateEmail(textBoxValue)) {
      setTags((tags) => [...tags, { value: textBoxValue.trim(), label: textBoxValue.trim() }]);
      setRecipients((mail) => [...mail, { name: textBoxValue.trim(), email: textBoxValue.trim() }]);
      setTextBoxValue("");
    }
    mutation.mutate();
  }, [mutation, textBoxValue]);

  const onKeyDownTagManagement = useCallback(
    (val: string, keyCode: number, selectionEnd: number | null) => {
      const isValidEmail: boolean = validateEmail(textBoxValue);
      const showToastMessage = (message: string, isError: boolean) => {
        setToastMessage(message);
        setShowToast({ isError: isError, show: true });
        setTimeout(() => {
          setShowToast({ isError: false, show: false });
        }, 4500);
      };

      if (keyCode === 8 && selectionEnd === 0) {
        setTags((tags) => [...tags.slice(0, -1)]);
        setRecipients((mails) => mails.slice(0, -1));
      } else if (keyCode === 32 && val.trim() !== "") {
        if (!isValidEmail) {
          showToastMessage(resources.invalidEmail, true);
          return;
        }
        if (val.trim().length > 32) {
          showToastMessage(resources.exceedMailChar, true);
          return;
        }
        if (recipients.some((mails) => mails.email === textBoxValue)) {
          showToastMessage(resources.alreadyInRecipients, true);
          return;
        }
        setTags((tags) => [...tags, { value: textBoxValue.trim(), label: textBoxValue.trim() }]);
        setRecipients((mail) => [
          ...mail,
          { name: textBoxValue.trim(), email: textBoxValue.trim() },
        ]);
        setTextBoxValue("");
      } else if (keyCode === 13) {
        if (isValidEmail) {
          setTags((tags) => [...tags, { value: textBoxValue.trim(), label: textBoxValue.trim() }]);
          setRecipients((mail) => [
            ...mail,
            { name: textBoxValue.trim(), email: textBoxValue.trim() },
          ]);
          setTextBoxValue("");
          setMessageValue("");
        }
        handleSubmit();
      }
    },
    [textBoxValue, recipients, resources, handleSubmit]
  );

  return {
    sendingEmail,
    handleSubmit,
    setShowToast,
    documentId,
    organizationNavigate,
    subject,
    message,
    value: textBoxValue,
    setValue: setTextBoxValue,
    tags,
    onKeyDownTagManagement,
    handleTagRemoval,
    isButtonEnabled,
    setEmailValid,
    showToast,
    toastMessage,
    setMessageValue,
  };
}
