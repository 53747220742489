import { Box, Heading, useColors, useDesignBreakpoint } from "@prodoctivity/design-system";

export const Topic = ({ children }: { children?: string }) => {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const hasWidth = breakpoint === "large" || breakpoint === "hd";
  return (
    <Box paddingY={2} display="flex" flex="grow">
      <Heading size={hasWidth ? "200" : "400"} color={colors.black600}>
        {children}
      </Heading>
    </Box>
  );
};
