import {
  Box,
  Flex,
  Grid,
  Image,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";

import { Page } from "../../components/Layout/Page";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { VisitorsHeader } from "../LandingPage/VisitorsHeader";

function ProductPage() {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();

  const breakpointFluid =
    breakpoint === "small" || breakpoint === "medium" || breakpoint === "large";

  return (
    <Page>
      <Box display="flex" direction="column" flex="grow">
        <VisitorsHeader />
        <Box display="flex" direction="column" gap={2} paddingX={2}>
          <Box height={35}>
            <Image
              alt="ProDoctivity"
              color="transparent"
              naturalHeight={60}
              naturalWidth={100}
              src="../img/logo_prodoctivity.png"
              role="img"
              fit="contain"
            />
          </Box>

          <Text weight="bold" size="400" align={"center"}>
            {resources.productPage.proDoctivitySubtitle}
          </Text>
          <Text align={"center"} color={colors.black600}>
            {resources.productPage.productSubtitle}
          </Text>
        </Box>
      </Box>

      <Box width={"100%"} position="relative" display="flex">
        {!breakpointFluid && (
          <Box position="absolute" width={"100%"}>
            <Image
              alt="ProDoctivity"
              color="transparent"
              naturalHeight={1080}
              naturalWidth={1920}
              src="../img/fifth_section.png"
              fit="cover"
            ></Image>
          </Box>
        )}
        <Box
          display="flex"
          paddingX={12}
          wrap={breakpointFluid ? true : false}
          justifyContent="center"
          alignItems="start"
          position="relative"
        >
          <Box>
            <Box>
              <Text weight="bold" color={colors.black900} align="justify" size="400">
                {resources.productPage.whatIsItTitle}
              </Text>
              <Box marginBottom={5}>
                <Text color={colors.black900} align="justify">
                  {resources.productPage.innovativeConceptApplication}
                </Text>
              </Box>
              <Box marginBottom={5}>
                <Text weight="bold" color={colors.black900} align="justify" size="400">
                  {resources.productPage.benefitsTitle}
                </Text>
                <Text color={colors.black900} align="justify">
                  {resources.productPage.benefits}
                </Text>
              </Box>
              <Box marginBottom={5} display="flex" direction="column">
                <Grid gridTemplateColumns={[1, 1]}>
                  <Flex direction="column" gap={2}>
                    <Box display="flex" alignItems="center">
                      <Text color={colors.primary}>&#10004;</Text>
                      {resources.productPage.centralizeDocumentationManagement}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Text color={colors.primary}>&#10004;</Text>
                      {resources.productPage.saveTimeEfficiency}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Text color={colors.primary}>&#10004;</Text>
                      {resources.productPage.efficientDocumentCreation}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Text color={colors.primary}>&#10004;</Text>
                      {resources.productPage.documentVersionControl}
                    </Box>
                  </Flex>
                  <Flex direction="column" gap={2}>
                    <Box display="flex" alignItems="center">
                      <Text color={colors.primary}>&#10004;</Text>
                      {resources.productPage.paperPrintSaving}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Text color={colors.primary}>&#10004;</Text>
                      {resources.productPage.faxFunctionality}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Text color={colors.primary}>&#10004;</Text>
                      {resources.productPage.emailEaseAccess}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Text color={colors.primary}>&#10004;</Text>
                      {resources.productPage.internalAuditControl}
                    </Box>
                  </Flex>
                </Grid>
              </Box>
              <Box marginBottom={10}>
                <Text weight="bold" color={colors.black900} align="justify" size="400">
                  {resources.productPage.processTitle}
                </Text>
                <Text color={colors.black900} align="justify">
                  {resources.productPage.process}
                </Text>
              </Box>
            </Box>
          </Box>

          {!breakpointFluid && <Box width={100} />}

          <Box>
            <Box
              width={breakpointFluid ? 350 : 800}
              height={breakpointFluid ? 200 : 400}
              paddingRight={breakpointFluid ? 8 : undefined}
            >
              <Image
                alt="ProDoctivity"
                color="transparent"
                naturalHeight={400}
                naturalWidth={800}
                src="../img/productLayer.png"
                role="presentation"
                fit="contain"
              />
            </Box>
            <Box margin={10}>
              <Text weight="bold" color={colors.black900} align="justify" size="400">
                {resources.productPage.digitizeTitle}
              </Text>
              <Text color={colors.black900} align="justify">
                {resources.productPage.digitize}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default ProductPage;
