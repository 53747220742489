import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import { MimeTypes, mimeTypeToExtension } from "@prodoctivity/shared";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useDebounceValue } from "usehooks-ts";
import { PubSubEventManagerCallback, useSubscribe } from "../../core";
import { blobToBase64, fileToBase64 } from "../../utils";
import { useDesignBreakpoint } from "../breakpoint";
import { HEADER_ZINDEX } from "../layout";
import {
  DocumentViewerDigitalSignatureItem,
  DocumentViewerEvents,
  DocumentViewerProps,
} from "./DocumentViewer";

const btnOptionsInHamburger = [
  "adjustHeight",
  "adjustWidth",
  "collapseSidePanel",
  "fullScreen",
  "handTool",
  "rectangleTool",
  "selectTool",
  "zoomIn",
  "zoomOut",
  "search",
];

type DocumentViewerToolBarIcons =
  | "none"
  | "cloud"
  | "fullScreen"
  | "adjustHeight"
  | "adjustWidth"
  | "zoomOut"
  | "zoomIn"
  | "handTool"
  | "rectangleTool"
  | "selectTool"
  | "collapseSidePanel"
  | "nextPage"
  | "previousPage"
  | "lastPage"
  | "firstPage";

const toolBarButtonName = {
  none: "none",
  cloud: "cloud",
  fullScreen: "fullScreen",
  adjustHeight: "adjustHeight",
  adjustWidth: "adjustWidth",
  zoomOut: "zoomOut",
  zoomIn: "zoomIn",
  handTool: "handTool",
  rectangleTool: "rectangleTool",
  selectTool: "selectTool",
  collapseSidePanel: "collapseSidePanel",
  nextPage: "nextPage",
  previousPage: "previousPage",
  lastPage: "lastPage",
  firstPage: "firstPage",
};

export function useDocumentViewer(
  {
    src: originalSrc,
    readOnly,
    mimeType: originalMimeType = MimeTypes.MsWordDocument,
    height,
    onFilesChange,
    isPrintScreenVisible,
    handleDocumentPrintAndDownload,
    wordToPdfHookRef,
    eventManager,
    moment,
    ...rest
  }: DocumentViewerProps,
  viewerHTMLElementRef: RefObject<HTMLDivElement>
) {
  const { breakpoint } = useDesignBreakpoint();
  const [instanceLoadState, setInstanceLoadState] = useState<
    "not-loaded" | "loading-1" | "loading-2" | "loaded"
  >("not-loaded");
  const [selectedThumbnail, setSelectedThumbnail] = useState<number>(0);
  const [btnSelected, setBtnSelected] = useState<DocumentViewerToolBarIcons>();
  const [imagesPanelOpen, setImagesPanelOpen] = useState<boolean>(
    breakpoint === "small" ? false : true
  );
  const [documentFullyLoaded, setDocumentFullyLoaded] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(1);
  const isMeasuringGraphicMark = useRef<boolean>(false);
  const graphicMarkMeasurementAnnotationIdReference = useRef<string | undefined>(undefined);
  const [debouncedCurrentPage] = useDebounceValue(inputValue, 100);

  const src = useMemo(() => {
    if (originalMimeType === MimeTypes.MsWordDocument && wordToPdfHookRef?.pdfDataUri) {
      return [wordToPdfHookRef.pdfDataUri];
    }
    return originalSrc;
  }, [originalSrc, originalMimeType, wordToPdfHookRef?.pdfDataUri]);

  const mimeType = useMemo(() => {
    if (originalMimeType === MimeTypes.MsWordDocument && wordToPdfHookRef?.setWordDataUri) {
      return MimeTypes.PdfDocument;
    }
    return originalMimeType;
  }, [originalMimeType, wordToPdfHookRef?.setWordDataUri]);

  const srcAreImages = useMemo(() => {
    return originalMimeType?.includes("image");
  }, [originalMimeType]);

  const instanceRef = useRef<WebViewerInstance | undefined>(undefined);
  const webviewerIsLoading = useRef<boolean>(false);

  const getPdfDocumentSignatures = useCallback(async () => {
    const signatures: DocumentViewerDigitalSignatureItem[] = [];
    const instance = instanceRef.current;

    if (!instance) {
      console.error(
        "Attempting to scan document for digital signatures failed: Webviewer instance has not loaded."
      );
      return [];
    }

    if (originalMimeType !== "application/pdf") {
      // console.info("Original document is not a PDF file, skipping signature scan.");
      return [];
    }

    const documentViewer = instance.docViewer;

    try {
      const currentDocument = documentViewer.getDocument();
      if (instance && currentDocument && currentDocument.getType() === "pdf") {
        await currentDocument.documentCompletePromise();
        const doc = await currentDocument.getPDFDoc();

        const digitalSignatureFieldIterator = await doc.getDigitalSignatureFieldIteratorBegin();
        for (
          ;
          await digitalSignatureFieldIterator.hasNext();
          await digitalSignatureFieldIterator.next()
        ) {
          const digitalSignatureField = await digitalSignatureFieldIterator.current();
          const currentSignature: DocumentViewerDigitalSignatureItem = {
            signer: "",
            isValid: false,
            hasVisibleAppearance: false,
            signingTime: undefined,
          };

          if (!(await digitalSignatureField.hasCryptographicSignature())) {
            console.error(
              `Either digital signature field lacks a digital signature dictionary, or digital signature dictionary lacks a cryptographic Contents entry. Digital signature field is not presently considered signed.`
            );
            continue;
          }

          const subfilter = await digitalSignatureField.getSubFilter();
          if (subfilter !== instance.PDFNet.DigitalSignatureField.SubFilterType.e_ETSI_RFC3161) {
            const signer = await digitalSignatureField.getSignatureName();
            currentSignature.signer = signer;

            const signing_time = await digitalSignatureField.getSigningTime();
            const d = new Date(
              signing_time.year,
              signing_time.month - 1,
              signing_time.day,
              signing_time.hour,
              signing_time.minute,
              signing_time.second
            );

            currentSignature.signingTime = moment(d)
              .add(-1 * d.getTimezoneOffset(), "minutes")
              .toDate();
            if (await signing_time.isValid()) {
              currentSignature.isValid = true;
            }
          }

          const hasVisibleAppearance = await digitalSignatureField.hasVisibleAppearance();
          currentSignature.hasVisibleAppearance = hasVisibleAppearance;
          signatures.push(currentSignature);
        }
      }
    } catch (err: unknown) {
      console.error(err);
    }

    return signatures;
  }, [originalMimeType, moment]);

  const documentViewer = instanceRef.current?.docViewer;
  const coreTools = instanceRef.current?.Tools;
  const panTool = coreTools && documentViewer?.getTool(coreTools.ToolNames.PAN);
  const textSelectTool = coreTools && documentViewer?.getTool(coreTools.ToolNames.TEXT_SELECT);
  const rectangleSelectTool = coreTools && documentViewer?.getTool(coreTools.ToolNames.RECTANGLE);

  const [selectedTool, setSelectedTool] = useState<string | undefined>(undefined);
  const handleRectangleSelectToggle = useCallback(
    (toolSelected?: boolean | undefined) => {
      if (documentViewer && rectangleSelectTool && textSelectTool) {
        setSelectedTool(toolSelected ? rectangleSelectTool.name : textSelectTool.name);
        documentViewer.setToolMode(toolSelected ? rectangleSelectTool : textSelectTool);

        //turn off graphic mark measuring mode whenever rectangle mode is toggled off.
        if (toolSelected !== undefined && toolSelected === false) {
          isMeasuringGraphicMark.current = false;
        }
      }
    },
    [documentViewer, rectangleSelectTool, textSelectTool]
  );

  const eventManagerCallback: PubSubEventManagerCallback<DocumentViewerEvents> = useCallback(
    (ev) => {
      switch (ev.type) {
        case "clear-measurement-annotation":
          if (instanceRef.current && graphicMarkMeasurementAnnotationIdReference.current) {
            const { annotManager } = instanceRef.current;
            const a = annotManager.getAnnotationById(
              graphicMarkMeasurementAnnotationIdReference.current
            );
            annotManager.deleteAnnotation(a, {
              force: true,
            });
          }
          break;
        case "toggle-measurement-mode":
          isMeasuringGraphicMark.current =
            ev.value.toolSelected === undefined ? false : ev.value.toolSelected;
          handleRectangleSelectToggle(ev.value.toolSelected);
          break;
        case "request-loaded-document-signatures":
          if (documentFullyLoaded) {
            getPdfDocumentSignatures()
              .then((signatures) =>
                eventManager.publish({
                  type: "document-signatures-loaded",
                  value: { signatures: signatures },
                })
              )
              .catch((error) => {
                console.error(error);
              });
          } else {
          }
          break;
        default:
          break;
      }
    },
    [documentFullyLoaded, eventManager, getPdfDocumentSignatures, handleRectangleSelectToggle]
  );

  useSubscribe(eventManager, eventManagerCallback);

  useEffect(() => {
    switch (instanceLoadState) {
      case "not-loaded":
        if (webviewerIsLoading.current === false) {
          webviewerIsLoading.current = true;

          const divElement = viewerHTMLElementRef.current;
          if (divElement === null || !divElement) {
            console.error("Viewer ref is null.");
            throw new Error("Viewer ref is null.");
          }

          console.info("Loading webviewer component...");
          const officeMimeTypes: Array<string> = [
            MimeTypes.MsExcelDocument,
            MimeTypes.MsWordDocument,
            MimeTypes.MsPresentationDocument,
          ];

          (async () => {
            const instance = await WebViewer(
              {
                path: "/webviewer/public",
                initialDoc: undefined,
                isReadOnly: true,
                enableRedaction: false,
                accessibleMode: false,
                disabledElements: undefined,
                loadAsPDF: true,
                fullAPI: true,
                licenseKey: officeMimeTypes.includes(mimeType)
                  ? undefined
                  : "NOVOSIT SRL(novosit.com):OEM:ProDoctivity Web::B+:AMS(20211231):88B54DD204F7580AB360B13AC9A2737860617FBBF7184ABAE56C052B58E5A35D6629BEF5C7",
              },
              divElement
            );

            await instance.PDFNet.initialize();

            instance.disableElements(["header"]);
            instanceRef.current = instance;

            const { FitMode, setFitMode } = instance;
            setFitMode(FitMode.FitWidth);

            const { annotManager } = instance;

            if (eventManager) {
              annotManager.on("annotationChanged", (annotations, action, info) => {
                if (
                  isMeasuringGraphicMark.current &&
                  action === "add" &&
                  !info.imported &&
                  !info.isUndoRedo
                ) {
                  //Clear previous signature measurement annotation.
                  if (graphicMarkMeasurementAnnotationIdReference.current) {
                    annotManager.getAnnotationsList().forEach((e) => {
                      if (e.Id === graphicMarkMeasurementAnnotationIdReference.current) {
                        annotManager.deleteAnnotation(e, { force: true });
                        annotManager.updateAnnotation(e);
                      }
                    });
                  }

                  const measurementAnnotation = annotations[0];
                  measurementAnnotation.NoResize = true;
                  measurementAnnotation.NoMove = true;
                  measurementAnnotation.Printable = false;
                  annotManager.updateAnnotation(measurementAnnotation);

                  const e = measurementAnnotation;
                  eventManager.publish({
                    type: "rectangular-annotation-created",
                    value: {
                      x: e.X,
                      y: e.Y,
                      height: e.Height,
                      width: e.Width,
                      pageIndex: e.getPageNumber() - 1,
                    },
                  });

                  //Keep track of current measurement annotation.
                  graphicMarkMeasurementAnnotationIdReference.current = e.Id;
                }
              });
            }
            setInstanceLoadState("loaded");
          })();
        } else {
          console.info("Skipping loading state: Webviewer component is already loading.");
        }
        break;
      case "loaded":
        if (instanceRef.current) {
          if (viewerHTMLElementRef.current) {
            const iframes = viewerHTMLElementRef.current.getElementsByTagName("iframe");
            Array.prototype.forEach.call(iframes, (iframe) => {
              const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

              const scriptSource = `
                ::-webkit-scrollbar {
                    width: 4px;
                }

                ::-webkit-scrollbar-track {
                    border-radius: 8px;
                    background-color: #dfe0e1;
                    border: 1px solid #b3b3b3;
                }

                ::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    background-color: #2650a3;
                }
              `;
              const script = iframeDocument.createElement("style");
              const source = iframeDocument.createTextNode(scriptSource);
              script.appendChild(source);
              iframeDocument.body.appendChild(script);
            });
          }

          const documentViewer = instanceRef.current.docViewer;

          const onDocumentLoaded = async () => {
            setDocumentFullyLoaded(true);
          };

          documentViewer.on("documentLoaded", onDocumentLoaded);
          documentViewer.on("pageNumberUpdated", (pageNumber: number) => {
            const page = isNaN(pageNumber) || pageNumber < 1 ? 1 : pageNumber;
            setInputValue(page);
          });

          if (isPrintScreenVisible && documentFullyLoaded && instanceRef.current) {
            handleDocumentPrintAndDownload &&
              handleDocumentPrintAndDownload(documentFullyLoaded, instanceRef);
          }

          if (srcAreImages) {
            setInputValue(selectedThumbnail + 1);
          } else {
            documentViewer.setCurrentPage(1);
            setInputValue(documentViewer.getCurrentPage());
          }

          const {
            loadDocument,
            setFitMode,
            FitMode,
            disableElements,
            enableElements,
            closeElements,
          } = instanceRef.current;

          const extension = mimeType
            ? mimeTypeToExtension(mimeType as string).replace(".", "")
            : undefined;

          const setWordDataUri = wordToPdfHookRef?.setWordDataUri;
          const pdfDataUri = wordToPdfHookRef?.pdfDataUri;

          const doLoad = (d: string) => {
            if (originalMimeType === MimeTypes.MsWordDocument && setWordDataUri && !pdfDataUri) {
              setWordDataUri(d);
              return;
            }
            return loadDocument(d, {
              extension: extension,
            });
          };

          const alwaysDisabledElements = [
            "thumbnailsSizeSlider",
            "outlinesPanelButton",
            "layersPanelButton",
            "documentControl",
            "thumbnailsControlRotateCounterClockwise",
            "thumbnailsControlRotateClockwise",
            "pageManipulationOverlay",
            "contextMenuPopup",
            "toolsHeader",
            "settingsKeyboardButton",
            "settingsAdvancedButton",
          ];

          const imageDisabledElements = [
            "leftPanel",
            "thumbDelete",
            "pageManipulationOverlayButton",
            "thumbRotateClockwise",
            "thumbnailsSizeSlider",
            "viewControlsButton",
          ];

          const alwaysClosedElements = ["leftPanel", "thumbnailControl"];
          const documentDisabledElements = ["thumbnailControl"];

          disableElements(alwaysDisabledElements);

          if (srcAreImages) {
            disableElements(imageDisabledElements);
            enableElements(documentDisabledElements);
          } else {
            disableElements(documentDisabledElements);
            enableElements(imageDisabledElements);
          }
          closeElements(alwaysClosedElements);

          if (!srcAreImages) {
            src.forEach((doc) => {
              if (typeof doc === "string") {
                return doLoad(doc);
              } else if (doc instanceof File) {
                fileToBase64(doc).then((base64) => {
                  doLoad(base64);
                });
              } else if (doc instanceof Blob) {
                blobToBase64(doc).then((base64) => {
                  doLoad(base64);
                });
              }
            });
            setFitMode(FitMode.FitWidth);
            return;
          }

          loadDocument(src[selectedThumbnail], { extension: extension });
          setFitMode(FitMode.FitWidth);
        } else {
          throw new Error("Invalid State: Webviewer component should be loaded at this point.");
        }
        break;
      default:
        throw new Error(`Invalid State: '${instanceLoadState}' is not a valid loading state.`);
    }
  }, [
    instanceLoadState,
    originalMimeType,
    documentFullyLoaded,
    handleDocumentPrintAndDownload,
    isPrintScreenVisible,
    srcAreImages,
    selectedThumbnail,
    originalSrc,
    viewerHTMLElementRef,
    mimeType,
    wordToPdfHookRef?.setWordDataUri,
    wordToPdfHookRef?.pdfDataUri,
    src,
    eventManager,
    getPdfDocumentSignatures,
    handleRectangleSelectToggle,
  ]);

  const viewerScreenSizeProps = useMemo(() => {
    if (rest.strategy === "ecm-document" && rest.isFullScreen) {
      return {
        height: "100vh",
        width: "100vw",
        position: "absolute",
        top: true,
        left: true,
        zIndex: HEADER_ZINDEX,
      } as const;
    }
    return { width: "100%" } as const;
  }, [rest.strategy, rest.isFullScreen]);

  const moveElement = useCallback(
    (index: number, direction: "up" | "bottom") => {
      if (!onFilesChange || src.length <= 1) return;
      const newArray = [...src];

      if (direction === "up" && index > 0) {
        const prevIndex = index - 1;
        [newArray[index], newArray[prevIndex]] = [newArray[prevIndex], newArray[index]];

        onFilesChange(newArray);
      } else if (direction === "bottom" && index < src.length - 1) {
        const nextIndex = index + 1;
        [newArray[index], newArray[nextIndex]] = [newArray[nextIndex], newArray[index]];
        onFilesChange(newArray);
      }
    },
    [onFilesChange, src]
  );

  const onDelete = useCallback(
    (index: number) => {
      if (!onFilesChange) return;

      const newArray = [...src.slice(0, index), ...src.slice(index + 1)];
      onFilesChange(newArray);
    },
    [onFilesChange, src]
  );

  const onRotate = useCallback(
    async (pageIndex: number) => {
      const newArray = [...src];

      newArray[pageIndex] = await rotateImage(newArray[pageIndex]);
      if (onFilesChange) onFilesChange(newArray);
      setSelectedThumbnail(pageIndex);
    },
    [onFilesChange, src]
  );

  const rotateImage = async (source: string | Blob): Promise<Blob> => {
    const img = new Image();

    const loadImage = new Promise<void>((resolve, reject) => {
      img.onload = () => {
        resolve();
      };
      img.onerror = (error) => {
        reject(error);
      };
    });

    const url = typeof source === "string" ? source : URL.createObjectURL(source);

    img.src = url;
    await loadImage;

    const canvas = document.createElement("canvas");
    canvas.width = img.height;
    canvas.height = img.width;

    // Rotate the image 90 deg to right
    const ctx = canvas.getContext("2d");
    ctx?.rotate(Math.PI / 2);
    ctx?.translate(0, -canvas.width);
    ctx?.drawImage(img, 0, 0);

    const blob = await new Promise<Blob>((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) resolve(blob);
      });
    });

    if (typeof source !== "string") {
      URL.revokeObjectURL(url);
    }

    return blob;
  };

  const handleFullScreenToggle = useCallback(() => {
    if (btnSelected === "fullScreen") {
      setBtnSelected("none");
      rest.setIsFullScreen(false);
    } else {
      setBtnSelected("fullScreen");
      rest.setIsFullScreen(true);
    }
  }, [btnSelected, rest]);

  const exitFullScreen = useCallback(() => {
    setBtnSelected("none");
    rest.setIsFullScreen(false);
  }, [rest]);

  const handleAdjustHeightToggle = () => {
    if (instanceRef.current) {
      instanceRef.current.setFitMode(instanceRef.current.FitMode.FitPage);
    }
  };
  const handleAdjustWidthToggle = () => {
    if (instanceRef.current) {
      instanceRef.current.setFitMode(instanceRef.current.FitMode.FitWidth);
    }
  };

  const handleZoomInBtn = () => {
    if (documentViewer) {
      documentViewer.zoomTo(documentViewer.getZoom() + 0.25);
    }
  };

  const handleZoomOutBtn = () => {
    if (documentViewer) {
      documentViewer.zoomTo(documentViewer.getZoom() - 0.25);
    }
  };

  const handleHandTool = (toolSelected?: boolean) => {
    if (documentViewer && panTool && textSelectTool) {
      if (toolSelected) {
        documentViewer.setToolMode(panTool);
      } else {
        documentViewer.setToolMode(textSelectTool);
      }
    }
  };

  const handleHandToolInHamburger = () => {
    if (documentViewer && panTool) {
      documentViewer.setToolMode(panTool);
    }
  };

  const handleRectangleToolInHamburger = () => {
    if (documentViewer && rectangleSelectTool) {
      documentViewer.setToolMode(rectangleSelectTool);
    }
  };

  const handleSelectTool = () => {
    if (documentViewer && textSelectTool) {
      documentViewer.setToolMode(textSelectTool);
    }
  };

  const handlePreviousPageBtn = () => {
    if (documentFullyLoaded && documentViewer) {
      const totalOfPages = documentViewer.getPageCount();
      const currentPage = documentViewer.getCurrentPage();
      if (totalOfPages > 0 && currentPage > 0) {
        documentViewer.setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleNextPageBtn = () => {
    if (documentFullyLoaded && documentViewer) {
      const totalOfPages = documentViewer.getPageCount();
      const currentPage = documentViewer.getCurrentPage();
      if (totalOfPages > 0 && currentPage < totalOfPages) {
        documentViewer.setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleSpecifiedPageBtn = (e: string) => {
    if (documentFullyLoaded) {
      if (srcAreImages) {
        const totalImages = src.length;
        const newValue = e;
        const numericValue = parseInt(newValue, 10);

        if (
          newValue === "" ||
          (newValue === numericValue.toString() && numericValue >= 1 && numericValue <= totalImages)
        ) {
          setInputValue(isNaN(numericValue) ? 1 : numericValue);
        }
      } else if (documentViewer) {
        const totalOfPages = documentViewer.getPageCount();
        const newValue = e;
        const numericValue = parseInt(newValue, 10);
        if (
          newValue === "" ||
          (newValue === numericValue.toString() &&
            numericValue >= 1 &&
            numericValue <= totalOfPages)
        ) {
          setInputValue(isNaN(numericValue) ? 1 : numericValue);
        }
      }
    }
  };

  const handlerEnterKeyPress = useCallback(
    ({ event: { code } }: { event: { code: string } }) => {
      if (code === "Enter" || code === "NumpadEnter") {
        if (!srcAreImages && documentFullyLoaded && documentViewer) {
          const totalOfPages = documentViewer.getPageCount();
          if (totalOfPages > 0) {
            documentViewer.setCurrentPage(Number(inputValue));
          }
        } else if (srcAreImages) {
          setSelectedThumbnail(Number(inputValue) - 1);
        }
      }
    },
    [documentFullyLoaded, documentViewer, inputValue, srcAreImages]
  );

  const handlePreviousImageBtn = () => {
    if (selectedThumbnail > 0) {
      setSelectedThumbnail(selectedThumbnail - 1);
    }
  };

  const handleNextImageBtn = () => {
    const totalImages = src.length - 1;
    if (selectedThumbnail < totalImages) {
      setSelectedThumbnail(selectedThumbnail + 1);
    }
  };

  const handleCollapseImagePanelBtn = useCallback(() => {
    exitFullScreen();
    setImagesPanelOpen(!imagesPanelOpen);
  }, [exitFullScreen, imagesPanelOpen]);

  const handleFirstPageBtn = useCallback(() => {
    if (documentFullyLoaded && documentViewer) {
      const totalOfPages = documentViewer.getPageCount();
      if (totalOfPages > 0) {
        documentViewer.setCurrentPage(1);
      }
    }
  }, [documentFullyLoaded, documentViewer]);

  const handleFirstImageBtn = () => {
    const totalImages = src.length;
    if (totalImages > 0 && selectedThumbnail !== 0) {
      setSelectedThumbnail(0);
    }
  };

  const handleLastImageBtn = () => {
    const totalImages = src.length - 1;
    if (totalImages >= 0 && selectedThumbnail !== totalImages) {
      setSelectedThumbnail(totalImages);
    }
  };

  const handleLastPageBtn = () => {
    if (documentFullyLoaded && documentViewer) {
      const totalOfPages = documentViewer.getPageCount();
      if (totalOfPages > 0) {
        documentViewer.setCurrentPage(totalOfPages);
      }
    }
  };
  const [isSearchPanelVisible, setIsSearchPanelVisible] = useState(false);
  const handleTapSearchPanel = useCallback(() => {
    if (instanceRef.current) {
      const searchPanelOpen = instanceRef.current.isElementOpen("searchPanel");
      if (searchPanelOpen) {
        instanceRef.current.closeElements(["searchPanel"]);
        setIsSearchPanelVisible(false);
      } else {
        instanceRef.current.openElements(["searchPanel"]);
        setIsSearchPanelVisible(true);
      }
    }
  }, [instanceRef]);

  const handleTapPrinter = useCallback(() => {
    if (instanceRef.current) {
      instanceRef.current.print();
    }
  }, [instanceRef]);

  const handleFunctionsBundle = [
    handleAdjustHeightToggle,
    handleAdjustWidthToggle,
    handleCollapseImagePanelBtn,
    handleFullScreenToggle,
    handleHandToolInHamburger,
    handleRectangleToolInHamburger,
    handleSelectTool,
    handleZoomInBtn,
    handleZoomOutBtn,
    handleTapSearchPanel,
  ];

  const menuOptionLabels = [
    "documentViewerToolbarItems.adjustHeight",
    "documentViewerToolbarItems.adjustWidth",
    "documentViewerToolbarItems.collapseImagePanel",
    "documentViewerToolbarItems.fullScreen",
    "documentViewerToolbarItems.handTool",
    "documentViewerToolbarItems.rectangleTool",
    "documentViewerToolbarItems.selectTool",
    "documentViewerToolbarItems.zoomIn",
    "documentViewerToolbarItems.zoomOut",
    "searchPanel",
  ];

  return {
    breakpoint,
    toolBarButtonName,
    viewerScreenSizeProps,
    moveElement,
    onDelete,
    onRotate,
    handleFullScreenToggle,
    handleAdjustHeightToggle,
    handleAdjustWidthToggle,
    handleZoomInBtn,
    handleZoomOutBtn,
    handleHandTool,
    handleRectangleSelectToggle,
    handlePreviousPageBtn,
    handleNextPageBtn,
    handleSpecifiedPageBtn,
    handlerEnterKeyPress,
    handlePreviousImageBtn,
    handleNextImageBtn,
    handleCollapseImagePanelBtn,
    handleFirstPageBtn,
    handleFirstImageBtn,
    handleLastImageBtn,
    handleLastPageBtn,
    handleTapSearchPanel,
    handleTapPrinter,
    isSearchPanelVisible,
    setIsSearchPanelVisible,
    readOnly,
    height,
    rest,
    src,
    srcAreImages,
    instanceLoadState,
    inputValue,
    imagesPanelOpen,
    selectedThumbnail,
    setSelectedThumbnail,
    viewer: viewerHTMLElementRef,
    instanceRef,
    handleFunctionsBundle,
    btnOptionsInHamburger,
    debouncedCurrentPage,
    menuOptionLabels,
    exitFullScreen,
    selectedTool,
  };
}
