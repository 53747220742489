import {
  Box,
  BoxWithRef,
  Divider,
  Heading,
  Layer,
  Popover,
  Skeleton,
  TapArea,
  Text,
  popupZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";

import { ActivityMenuSearch } from "../../components/Activities/ActivityMenuSearch";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { Pagination } from "../../components/Layout/Pagination";
import { Activity } from "../../components/RecentActivities/Activity";
import { SmallRefreshButton } from "../../components/SmallRefreshButton";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";
import { XIconSvg } from "../../svg/XIconSvg";
import { useActivitiesPage } from "./hooks";

const ActivityListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const {
    isLoading,
    entries,
    search,
    rowsPerPage,
    pageLengthOptions,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    refetch,
    setPageLength,
    resources,
    onChangeSearch,
    onSortChange,
    onRemoveType,
    onRemoveDates,
    onClear,
    moment,
  } = useActivitiesPage();

  const { breakpoint } = useDesignBreakpoint();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "text", name: resources.activities.activities },
    ];
  }, [resources.activities.activities, resources.home]);

  return (
    <Page
      breadCrumbEntries={breadCrumbEntries}
      breadCrumbExtraComponent={
        <BreadCrumbExtraComponent sortDirection={search.sort} onSortChange={onSortChange} />
      }
      left={<ActivityMenuSearch key={"search"} onChangeSearch={onChangeSearch} search={search} />}
    >
      <Box display="flex" direction="column" flex="grow" paddingX={2}>
        <Box
          display="flex"
          direction="row"
          flex="shrink"
          wrap={true}
          gap={2}
          alignItems="center"
          padding={1}
        >
          {!search.fromDate && !search.toDate && !search.entryTypes.length ? null : (
            <Box display="flex" flex="shrink">
              <Text>{resources.filters}: </Text>
            </Box>
          )}

          {search.entryTypes.map((entry) => {
            return (
              <Box
                key={entry.code}
                display="flex"
                direction="row"
                padding={2}
                borderRadius={4}
                height={30}
                alignItems="center"
              >
                <Box display="flex" direction="row" flex="shrink" gap={2}>
                  <Text inline={true} color={colors.subtle} size="200">
                    {entry.name}
                  </Text>
                  <Box display="flex" flex="shrink">
                    <TapArea onTap={() => onRemoveType(entry)}>
                      <XIconSvg color={colors.black600} />
                    </TapArea>
                  </Box>
                </Box>
              </Box>
            );
          })}
          {!search.fromDate && !search.toDate ? null : (
            <Box
              display="flex"
              direction="row"
              padding={2}
              borderRadius={4}
              height={30}
              alignItems="center"
            >
              <Box display="flex" direction="row" gap={2}>
                <Box display="flex" flex="shrink">
                  <Text color={colors.subtle} size="200">
                    {!search.fromDate
                      ? ""
                      : moment(search.fromDate).format(resources._dateFormatShort)}{" "}
                    -{" "}
                    {!search.toDate ? "" : moment(search.toDate).format(resources._dateFormatShort)}
                  </Text>
                </Box>
                <Box display="flex" flex="shrink">
                  <TapArea onTap={() => onRemoveDates()}>
                    <XIconSvg color={colors.black600} />
                  </TapArea>
                </Box>
              </Box>
            </Box>
          )}
          {!search.fromDate && !search.toDate && search.entryTypes.length === 0 ? null : (
            <Box display="flex" flex="shrink" padding={2}>
              <TapArea onTap={onClear}>
                <Text color={colors.primary300}>{resources.clear}</Text>
              </TapArea>
            </Box>
          )}
        </Box>

        <Box display="flex" direction="column" flex="shrink">
          <Box display="flex" flex="grow">
            <Box display="flex" flex="grow" />
            <Box display="flex" flex="shrink">
              <Pagination<typeof rowsPerPage>
                id="activity_list_pagination_top"
                rowsLabel={`${resources.activities.activities}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={entries?.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="shrink">
          <Box display="flex" flex="grow" justifyContent="end">
            <SmallRefreshButton
              size={30}
              marginTop={0}
              refetch={refetch}
              accessibilityLabel={resources.refresh}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          direction="column"
          borderRadius={breakpoint === "small" ? undefined : 12}
        >
          <Skeleton show={isLoading} />
          <Box display="flex" direction="column" gap={4}>
            {!isLoading && (!entries || !entries.length) ? (
              <Box display="flex" direction="column" flex="shrink" padding={4}>
                <Heading color={colors.black600}>{resources.activities.noActivitiesFound}</Heading>
              </Box>
            ) : (
              (entries || []).map((entry) => {
                return (
                  <Activity key={entry.id} entry={entry} fullScreen={false} fullWidth={true} />
                );
              })
            )}
          </Box>
          <Divider />
          <Box display="flex" flex="grow">
            <Box display="flex" flex="grow" />
            <Box display="flex" flex="shrink">
              <Pagination<typeof rowsPerPage>
                id="activity_list_pagination_bottom"
                rowsLabel={`${resources.activities.activities}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={entries?.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

type BreadCrumbExtraComponentProps = {
  sortDirection: "desc" | "asc" | undefined;
  onSortChange: (newState: "asc" | "desc") => void;
};

const BreadCrumbExtraComponent: FunctionComponent<BreadCrumbExtraComponentProps> = ({
  sortDirection,
  onSortChange,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const onSortClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Box direction="row">
      <BoxWithRef
        ref={anchorRef}
        onClickCapture={onSortClick}
        aria-label="My Organization"
        aria-roledescription="button"
        dangerouslySetInlineStyle={{
          __style: {
            cursor: "pointer",
          },
        }}
      >
        <Text>
          {resources.sortBy} {sortDirection === "desc" ? resources.mostRecent : resources.oldest}
        </Text>

        {open && (
          <Layer zIndex={popupZIndex}>
            <Popover
              anchor={anchorRef.current}
              onDismiss={() => setOpen(false)}
              idealDirection="down"
              positionRelativeToAnchor={true}
              size="xl"
              shouldFocus={true}
              color="white"
              role="menu"
            >
              <Box color={colors.white}>
                <Box margin={6}>
                  <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                    <Box width={266}>
                      <Box
                        onClickCapture={() => onSortChange("desc")}
                        dangerouslySetInlineStyle={{
                          __style: {
                            cursor: "pointer",
                          },
                        }}
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Box>
                          <Text
                            customClassName="text-bold-on-hover text-subtle-on-hover"
                            color={colors.subtle}
                          >
                            {resources.mostRecent}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        marginTop={5}
                        width="100%"
                        height={1}
                        color={colors.neutral500}
                        marginBottom={3}
                      />
                      <Box
                        onClickCapture={() => onSortChange("asc")}
                        dangerouslySetInlineStyle={{
                          __style: {
                            cursor: "pointer",
                          },
                        }}
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Box display="flex" direction="column">
                          <Text
                            customClassName="text-bold-on-hover text-subtle-on-hover"
                            color={colors.subtle}
                          >
                            {resources.oldest}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Popover>
          </Layer>
        )}
      </BoxWithRef>
    </Box>
  );
};

export default ActivityListPage;
