import {
  Box,
  Button,
  FormHtml,
  Grid,
  PasswordSetterField,
  Text,
  TextField,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { useChangePassword } from "../hooks";
import { useParams } from "react-router-dom";

export const ChangePasswordForm: FunctionComponent = () => {
  const { username } = useParams();

  const {
    newPassword,
    confirmedPassword,
    resources,
    hasInvalidPassword,
    handlePasswordChange,
    organizationNavigate,
    setConfirmedPassword,
    setNewPassword,
  } = useChangePassword(username);

  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box padding={4}>
      <FormHtml>
        <Grid gridTemplateColumns={["1fr"]}>
          <Box>
            <Box display="flex" alignItems="center" direction="column" justifyContent="center">
              <Box marginBottom={4} width={"100%"}>
                <Box display="flex" direction="column">
                  <Box display="flex" direction="row" justifyContent="between" marginBottom={4}>
                    <Text weight="bold">{resources.newPassword}</Text>
                  </Box>
                  <PasswordSetterField
                    id="newPassword"
                    value={newPassword}
                    onChange={setNewPassword}
                    resources={resources}
                    labelDisplay="hidden"
                  />
                </Box>
              </Box>
            </Box>
            <Box marginBottom={3} width={"100%"}>
              <Box marginBottom={4}>
                <Text weight="bold">{resources.adminChangePassword.confirmPassword}</Text>
              </Box>
              <Box>
                <TextField
                  id="confirmedPassword"
                  value={confirmedPassword}
                  onChange={(e) => setConfirmedPassword(e.value)}
                  label={resources.repeatPassword}
                  placeholder={resources.repeatPassword}
                  labelDisplay="hidden"
                  type="password"
                  errorMessage={
                    newPassword !== confirmedPassword
                      ? resources.adminChangePassword.passwordNotMatch
                      : undefined
                  }
                />
              </Box>
            </Box>

            <Box display="flex" justifyContent="between" alignItems="center" marginTop={10}>
              <Box
                display="flex"
                gap={breakpoint === "small" ? 4 : undefined}
                direction={breakpoint === "small" ? "column" : undefined}
              >
                <Box
                  marginEnd={breakpoint === "small" ? undefined : 12}
                  width={250}
                  rounding="pill"
                >
                  <Button
                    fullWidth
                    onClick={() => organizationNavigate("/settings/users/")}
                    color="gray"
                    accessibilityLabel={resources.cancel}
                    text={resources.cancel}
                  />
                </Box>
                <Box
                  marginStart={breakpoint === "small" ? undefined : 12}
                  width={250}
                  rounding="pill"
                >
                  <Button
                    onClick={handlePasswordChange}
                    fullWidth
                    disabled={
                      !newPassword || newPassword !== confirmedPassword || hasInvalidPassword
                    }
                    color="blue"
                    accessibilityLabel={resources.accept}
                    text={resources.accept}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </FormHtml>
    </Box>
  );
};
