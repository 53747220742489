import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const HorizontalEllipsisSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
}) => {
  return (
    <svg
      stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="20" r="3" transform="rotate(-90 11 20)" fill="#333333" />
      <circle cx="20" cy="20" r="3" transform="rotate(-90 20 20)" fill="#333333" />
      <circle cx="29" cy="20" r="3" transform="rotate(-90 29 20)" fill="#333333" />
    </svg>
  );
};
