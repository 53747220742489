import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number | string;
  height?: number | string;
  color?: ColorConfig | undefined;
};

export const NewGadgetIllustration: FunctionComponent<Props> = ({ width = 129, height = 118 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 129 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8531_111631)">
        <path
          d="M65.8714 6.72015L62.1945 7.66443L16.8954 19.2982L13.2185 20.2425C11.4339 20.7029 9.90546 21.8496 8.96866 23.431C8.03187 25.0123 7.76318 26.8991 8.22154 28.6775L24.1651 90.2487C24.6274 92.0261 25.7789 93.5482 27.3667 94.4812C28.9546 95.4141 30.8492 95.6817 32.6349 95.2252L32.6444 95.2228L85.2782 81.7054L85.2877 81.7029C87.0724 81.2425 88.6008 80.0958 89.5376 78.5144C90.4744 76.9331 90.7431 75.0463 90.2847 73.2679L74.3412 11.6966C73.8789 9.91929 72.7274 8.39717 71.1396 7.46422C69.5517 6.53127 67.6571 6.26367 65.8714 6.72015Z"
          fill="#DFE0E1"
        />
      </g>
      <path
        d="M66.371 8.65063L62.016 9.76907L18.073 21.0545L13.7181 22.1729C12.4472 22.5009 11.3588 23.3174 10.6916 24.4436C10.0245 25.5697 9.83317 26.9133 10.1596 28.1797L26.1031 89.751C26.4324 91.0167 27.2523 92.1006 28.3831 92.765C29.5138 93.4294 30.863 93.6199 32.1347 93.2948L32.1442 93.2924L84.778 79.775L84.7876 79.7725C86.0585 79.4446 87.1469 78.628 87.814 77.5019C88.4811 76.3758 88.6724 75.0322 88.346 73.7657L72.4025 12.1945C72.0732 10.9288 71.2533 9.8449 70.1225 9.18052C68.9918 8.51614 67.6426 8.32558 66.371 8.65063Z"
        fill="white"
      />
      <path
        d="M63.0821 28.589L36.4559 35.4271C36.1585 35.5035 35.8429 35.4591 35.5784 35.3037C35.3138 35.1483 35.1222 34.8946 35.0455 34.5984C34.9688 34.3023 35.0134 33.9879 35.1694 33.7245C35.3255 33.461 35.5802 33.2701 35.8776 33.1938L62.5038 26.3556C62.8011 26.2795 63.1165 26.3241 63.3808 26.4796C63.6451 26.635 63.8366 26.8886 63.9132 27.1846C63.9899 27.4806 63.9454 27.7948 63.7896 28.0581C63.6337 28.3215 63.3793 28.5124 63.0821 28.589Z"
        fill="#DFE0E1"
      />
      <path
        d="M68.684 31.1698L37.4325 39.1958C37.1351 39.2722 36.8194 39.2278 36.5549 39.0724C36.2904 38.917 36.0987 38.6633 36.022 38.3671C35.9453 38.071 35.9899 37.7566 36.146 37.4932C36.302 37.2297 36.5568 37.0388 36.8542 36.9625L68.1057 28.9364C68.4031 28.8601 68.7188 28.9045 68.9833 29.0599C69.2478 29.2153 69.4395 29.469 69.5162 29.7652C69.5929 30.0613 69.5483 30.3757 69.3922 30.6391C69.2362 30.9025 68.9814 31.0934 68.684 31.1698Z"
        fill="#DFE0E1"
      />
      <path
        d="M67.4561 45.4789L40.83 52.317C40.6827 52.3549 40.5294 52.3634 40.3788 52.3422C40.2283 52.3211 40.0834 52.2706 39.9524 52.1936C39.8214 52.1166 39.7069 52.0148 39.6155 51.8937C39.5241 51.7727 39.4575 51.635 39.4195 51.4883C39.3815 51.3417 39.3729 51.189 39.3942 51.0391C39.4155 50.8891 39.4662 50.7448 39.5434 50.6144C39.6207 50.4839 39.723 50.3699 39.8445 50.2789C39.9661 50.1878 40.1044 50.1215 40.2516 50.0837L66.8778 43.2455C67.0251 43.2077 67.1783 43.1991 67.3289 43.2203C67.4795 43.2415 67.6244 43.292 67.7554 43.369C67.8864 43.4459 68.0008 43.5478 68.0923 43.6688C68.1837 43.7898 68.2503 43.9276 68.2883 44.0742C68.3262 44.2209 68.3348 44.3735 68.3136 44.5235C68.2923 44.6735 68.2416 44.8178 68.1643 44.9482C68.087 45.0786 67.9847 45.1926 67.8632 45.2837C67.7417 45.3748 67.6034 45.4411 67.4561 45.4789Z"
        fill="#DFE0E1"
      />
      <path
        d="M73.0571 48.06L41.8055 56.086C41.6583 56.1238 41.505 56.1324 41.3544 56.1112C41.2039 56.09 41.0589 56.0395 40.928 55.9625C40.797 55.8856 40.6825 55.7837 40.5911 55.6627C40.4997 55.5417 40.4331 55.4039 40.3951 55.2573C40.3571 55.1106 40.3485 54.958 40.3698 54.808C40.391 54.658 40.4418 54.5137 40.519 54.3833C40.5963 54.2529 40.6986 54.1389 40.8201 54.0478C40.9416 53.9568 41.08 53.8904 41.2272 53.8526L72.4788 45.8266C72.7761 45.7502 73.0918 45.7946 73.3563 45.95C73.6208 46.1054 73.8125 46.3591 73.8892 46.6553C73.9659 46.9515 73.9213 47.2658 73.7653 47.5293C73.6092 47.7927 73.3545 47.9836 73.0571 48.06Z"
        fill="#DFE0E1"
      />
      <path
        d="M71.8294 62.369L45.2032 69.2072C44.9059 69.2833 44.5904 69.2388 44.3261 69.0833C44.0617 68.9279 43.8702 68.6743 43.7935 68.3783C43.7169 68.0822 43.7614 67.768 43.9173 67.5046C44.0732 67.2413 44.3277 67.0503 44.6249 66.9738L71.2511 60.1357C71.5484 60.0593 71.8641 60.1037 72.1286 60.2591C72.3931 60.4145 72.5848 60.6682 72.6615 60.9644C72.7382 61.2605 72.6936 61.5749 72.5376 61.8383C72.3815 62.1018 72.1268 62.2927 71.8294 62.369Z"
        fill="#DFE0E1"
      />
      <path
        d="M77.4311 64.9501L46.1796 72.9761C46.0323 73.0141 45.8789 73.0228 45.7282 73.0017C45.5776 72.9806 45.4325 72.9301 45.3015 72.8532C45.1704 72.7763 45.0558 72.6743 44.9643 72.5533C44.8728 72.4322 44.8061 72.2944 44.7681 72.1477C44.7301 72.0009 44.7215 71.8482 44.7429 71.6981C44.7642 71.5481 44.815 71.4037 44.8923 71.2733C44.9697 71.1428 45.0722 71.0288 45.1938 70.9377C45.3154 70.8467 45.4539 70.7804 45.6013 70.7427L76.8528 62.7167C77.1502 62.6403 77.4659 62.6847 77.7304 62.8402C77.9949 62.9956 78.1866 63.2493 78.2633 63.5454C78.34 63.8416 78.2954 64.156 78.1393 64.4194C77.9833 64.6828 77.7285 64.8737 77.4311 64.9501Z"
        fill="#DFE0E1"
      />
      <path
        d="M31.9113 42.4385L22.4886 44.8584C22.346 44.8949 22.1947 44.8735 22.0679 44.799C21.9411 44.7245 21.8492 44.603 21.8123 44.461L19.6493 36.1079C19.6127 35.9659 19.6341 35.8153 19.7089 35.689C19.7838 35.5627 19.9058 35.4712 20.0483 35.4344L29.4711 33.0145C29.6136 32.978 29.7649 32.9994 29.8917 33.0739C30.0185 33.1484 30.1105 33.2699 30.1474 33.4118L32.3104 41.7649C32.347 41.9069 32.3255 42.0576 32.2507 42.1839C32.1759 42.3101 32.0539 42.4017 31.9113 42.4385Z"
        fill="#CACBCE"
      />
      <path
        d="M36.2844 59.3286L26.8616 61.7485C26.7191 61.785 26.5678 61.7636 26.441 61.6891C26.3142 61.6146 26.2222 61.4931 26.1853 61.3512L24.0223 52.9981C23.9857 52.8561 24.0072 52.7054 24.082 52.5791C24.1568 52.4529 24.2788 52.3613 24.4213 52.3246L33.8441 49.9046C33.9867 49.8681 34.138 49.8895 34.2648 49.964C34.3916 50.0385 34.4835 50.16 34.5204 50.302L36.6834 58.6551C36.72 58.7971 36.6985 58.9477 36.6237 59.074C36.5489 59.2003 36.4269 59.2918 36.2844 59.3286Z"
        fill="#CACBCE"
      />
      <path
        d="M40.6584 76.2187L31.2357 78.6387C31.0931 78.6751 30.9418 78.6538 30.815 78.5793C30.6882 78.5048 30.5963 78.3832 30.5593 78.2413L28.3964 69.8882C28.3598 69.7462 28.3812 69.5956 28.456 69.4693C28.5308 69.343 28.6529 69.2515 28.7954 69.2147L38.2181 66.7947C38.3607 66.7583 38.512 66.7796 38.6388 66.8541C38.7656 66.9286 38.8575 67.0502 38.8944 67.1921L41.0574 75.5452C41.094 75.6872 41.0726 75.8379 40.9978 75.9641C40.923 76.0904 40.8009 76.182 40.6584 76.2187Z"
        fill="#CACBCE"
      />
      <g filter="url(#filter1_d_8531_111631)">
        <path
          d="M102.594 22.3276H48.2184C46.3748 22.3297 44.6073 23.06 43.3036 24.3583C42 25.6565 41.2667 27.4168 41.2646 29.2528V92.8384C41.2667 94.6744 42 96.4347 43.3036 97.7329C44.6073 99.0312 46.3748 99.7615 48.2184 99.7636H102.594C104.437 99.7615 106.205 99.0312 107.509 97.7329C108.812 96.4347 109.545 94.6744 109.548 92.8384V29.2528C109.545 27.4168 108.812 25.6565 107.509 24.3583C106.205 23.06 104.437 22.3297 102.594 22.3276Z"
          fill="#DFE0E1"
        />
      </g>
      <path
        d="M102.594 24.3213H48.2185C46.9056 24.3228 45.647 24.8428 44.7186 25.7673C43.7903 26.6919 43.2681 27.9454 43.2666 29.2529V92.8384C43.2681 94.1459 43.7903 95.3994 44.7186 96.324C45.647 97.2485 46.9056 97.7685 48.2185 97.77H102.594C103.907 97.7685 105.166 97.2485 106.094 96.3239C107.022 95.3994 107.544 94.1459 107.546 92.8384V29.2529C107.544 27.9454 107.022 26.6919 106.094 25.7673C105.166 24.8428 103.907 24.3228 102.594 24.3213Z"
        fill="white"
      />
      <g filter="url(#filter2_d_8531_111631)">
        <path
          d="M108.306 107.5C115.317 107.5 121 101.84 121 94.8584C121 87.8766 115.317 82.2168 108.306 82.2168C101.296 82.2168 95.6123 87.8766 95.6123 94.8584C95.6123 101.84 101.296 107.5 108.306 107.5Z"
          fill="#2650A3"
        />
      </g>
      <path
        d="M114.377 93.2096H109.961V88.8125C109.961 88.3752 109.787 87.9558 109.477 87.6465C109.166 87.3373 108.745 87.1636 108.306 87.1636C107.867 87.1636 107.445 87.3373 107.135 87.6465C106.824 87.9558 106.65 88.3752 106.65 88.8125V93.2096H102.235C101.796 93.2096 101.375 93.3833 101.064 93.6925C100.754 94.0017 100.579 94.4211 100.579 94.8585C100.579 95.2958 100.754 95.7152 101.064 96.0244C101.375 96.3336 101.796 96.5074 102.235 96.5074H106.65V100.904C106.65 101.342 106.824 101.761 107.135 102.07C107.445 102.38 107.867 102.553 108.306 102.553C108.745 102.553 109.166 102.38 109.477 102.07C109.787 101.761 109.961 101.342 109.961 100.904V96.5074H114.377C114.816 96.5074 115.237 96.3336 115.547 96.0244C115.858 95.7152 116.032 95.2958 116.032 94.8585C116.032 94.4211 115.858 94.0017 115.547 93.6925C115.237 93.3833 114.816 93.2096 114.377 93.2096Z"
        fill="white"
      />
      <path
        d="M94.4105 59.2615H66.9132C66.761 59.2617 66.6103 59.232 66.4697 59.1741C66.329 59.1162 66.2012 59.0313 66.0935 58.9242C65.9859 58.8171 65.9005 58.6899 65.8422 58.5499C65.7839 58.4099 65.7539 58.2599 65.7539 58.1083C65.7539 57.9567 65.7839 57.8067 65.8422 57.6667C65.9005 57.5267 65.9859 57.3995 66.0935 57.2924C66.2012 57.1853 66.329 57.1004 66.4697 57.0425C66.6103 56.9846 66.761 56.9549 66.9132 56.9551H94.4105C94.7174 56.9554 95.0116 57.0771 95.2284 57.2933C95.4453 57.5095 95.5672 57.8027 95.5672 58.1083C95.5672 58.4139 95.4453 58.7071 95.2284 58.9233C95.0116 59.1395 94.7174 59.2612 94.4105 59.2615Z"
        fill="#CACBCE"
      />
      <path
        d="M99.1871 63.1536H66.9132C66.761 63.1538 66.6103 63.1241 66.4697 63.0662C66.329 63.0083 66.2012 62.9234 66.0935 62.8163C65.9859 62.7092 65.9005 62.582 65.8422 62.442C65.7839 62.302 65.7539 62.1519 65.7539 62.0004C65.7539 61.8488 65.7839 61.6988 65.8422 61.5588C65.9005 61.4188 65.9859 61.2916 66.0935 61.1845C66.2012 61.0774 66.329 60.9925 66.4697 60.9346C66.6103 60.8767 66.761 60.847 66.9132 60.8472H99.1871C99.4943 60.8472 99.7888 60.9687 100.006 61.1849C100.223 61.4012 100.345 61.6945 100.345 62.0004C100.345 62.3062 100.223 62.5996 100.006 62.8158C99.7888 63.0321 99.4943 63.1536 99.1871 63.1536Z"
        fill="#CACBCE"
      />
      <path
        d="M94.4105 76.7039H66.9132C66.761 76.7041 66.6103 76.6744 66.4697 76.6165C66.329 76.5586 66.2012 76.4737 66.0935 76.3666C65.9859 76.2595 65.9005 76.1323 65.8422 75.9923C65.7839 75.8523 65.7539 75.7022 65.7539 75.5507C65.7539 75.3991 65.7839 75.2491 65.8422 75.1091C65.9005 74.9691 65.9859 74.8419 66.0935 74.7348C66.2012 74.6277 66.329 74.5428 66.4697 74.4849C66.6103 74.427 66.761 74.3973 66.9132 74.3975H94.4105C94.7176 74.3975 95.0121 74.519 95.2293 74.7352C95.4464 74.9515 95.5684 75.2448 95.5684 75.5507C95.5684 75.8565 95.4464 76.1499 95.2293 76.3661C95.0121 76.5824 94.7176 76.7039 94.4105 76.7039Z"
        fill="#CACBCE"
      />
      <path
        d="M99.1871 80.596H66.9132C66.761 80.5962 66.6103 80.5665 66.4697 80.5086C66.329 80.4507 66.2012 80.3658 66.0935 80.2587C65.9859 80.1516 65.9005 80.0244 65.8422 79.8844C65.7839 79.7444 65.7539 79.5943 65.7539 79.4428C65.7539 79.2912 65.7839 79.1412 65.8422 79.0012C65.9005 78.8612 65.9859 78.734 66.0935 78.6269C66.2012 78.5198 66.329 78.4348 66.4697 78.377C66.6103 78.3191 66.761 78.2894 66.9132 78.2896H99.1871C99.3393 78.2894 99.49 78.3191 99.6307 78.377C99.7713 78.4348 99.8991 78.5198 100.007 78.6269C100.114 78.734 100.2 78.8612 100.258 79.0012C100.316 79.1412 100.346 79.2912 100.346 79.4428C100.346 79.5943 100.316 79.7444 100.258 79.8844C100.2 80.0244 100.114 80.1516 100.007 80.2587C99.8991 80.3658 99.7713 80.4507 99.6307 80.5086C99.49 80.5665 99.3393 80.5962 99.1871 80.596Z"
        fill="#CACBCE"
      />
      <path
        d="M60.7531 64.9203H51.0221C50.8748 64.9201 50.7337 64.8618 50.6296 64.7582C50.5255 64.6545 50.467 64.5139 50.4668 64.3673V55.741C50.467 55.5944 50.5255 55.4538 50.6296 55.3501C50.7337 55.2465 50.8748 55.1881 51.0221 55.188H60.7531C60.9003 55.1881 61.0414 55.2465 61.1455 55.3501C61.2496 55.4538 61.3082 55.5944 61.3083 55.741V64.3673C61.3082 64.5139 61.2496 64.6545 61.1455 64.7582C61.0414 64.8618 60.9003 64.9201 60.7531 64.9203Z"
        fill="#CACBCE"
      />
      <path
        d="M60.7531 82.3632H51.0221C50.8748 82.363 50.7337 82.3047 50.6296 82.201C50.5255 82.0974 50.467 81.9568 50.4668 81.8102V73.1838C50.467 73.0372 50.5255 72.8967 50.6296 72.793C50.7337 72.6893 50.8748 72.631 51.0221 72.6309H60.7531C60.9003 72.631 61.0414 72.6893 61.1455 72.793C61.2496 72.8967 61.3082 73.0372 61.3083 73.1838V81.8102C61.3082 81.9568 61.2496 82.0974 61.1455 82.201C61.0414 82.3047 60.9003 82.363 60.7531 82.3632Z"
        fill="#CACBCE"
      />
      <path
        d="M94.4404 39.8445H74.1677C73.8606 39.8445 73.5661 39.723 73.3489 39.5068C73.1318 39.2905 73.0098 38.9972 73.0098 38.6913C73.0098 38.3855 73.1318 38.0921 73.3489 37.8759C73.5661 37.6596 73.8606 37.5381 74.1677 37.5381H94.4404C94.7475 37.5381 95.042 37.6596 95.2592 37.8759C95.4763 38.0921 95.5984 38.3855 95.5984 38.6913C95.5984 38.9972 95.4763 39.2905 95.2592 39.5068C95.042 39.723 94.7475 39.8445 94.4404 39.8445Z"
        fill="#CCCCCC"
      />
      <path
        d="M99.2171 43.7366H74.1677C74.0157 43.7366 73.8651 43.7068 73.7246 43.6488C73.5841 43.5909 73.4565 43.5059 73.3489 43.3989C73.2414 43.2918 73.1561 43.1646 73.0979 43.0247C73.0397 42.8848 73.0098 42.7348 73.0098 42.5834C73.0098 42.432 73.0397 42.282 73.0979 42.1421C73.1561 42.0022 73.2414 41.875 73.3489 41.7679C73.4565 41.6609 73.5841 41.5759 73.7246 41.518C73.8651 41.46 74.0157 41.4302 74.1677 41.4302H99.2171C99.5242 41.4302 99.8187 41.5517 100.036 41.7679C100.253 41.9842 100.375 42.2775 100.375 42.5834C100.375 42.8893 100.253 43.1826 100.036 43.3989C99.8187 43.6151 99.5242 43.7366 99.2171 43.7366Z"
        fill="#CCCCCC"
      />
      <path
        d="M69.6814 48.4535H50.9918C50.8446 48.4533 50.7034 48.395 50.5993 48.2913C50.4952 48.1877 50.4367 48.0471 50.4365 47.9005V33.3743C50.4367 33.2276 50.4952 33.0871 50.5993 32.9834C50.7034 32.8798 50.8446 32.8215 50.9918 32.8213H69.6814C69.8286 32.8215 69.9697 32.8798 70.0738 32.9834C70.1779 33.0871 70.2365 33.2276 70.2366 33.3743V47.9005C70.2365 48.0471 70.1779 48.1877 70.0738 48.2913C69.9697 48.395 69.8286 48.4533 69.6814 48.4535Z"
        fill="#2650A3"
      />
      <defs>
        <filter
          id="filter0_d_8531_111631"
          x="0.00292969"
          y="0.502441"
          width="98.5"
          height="104.94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8531_111631" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8531_111631"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8531_111631"
          x="33.2646"
          y="16.3276"
          width="84.2832"
          height="93.436"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8531_111631" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8531_111631"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8531_111631"
          x="87.6123"
          y="76.2168"
          width="41.3877"
          height="41.2832"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8531_111631" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8531_111631"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
