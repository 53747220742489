import { useColors, Box, FormControllerProvider } from "@prodoctivity/design-system";
import moment from "moment";
import { useMemo, useCallback } from "react";
import { CollectionReportWithFilter } from "../../../pages/Dashboard/components/collectionReportView";
import { WidgeReportForm } from "./WidgetReportForm";
import { useCollectionReportWidget } from "./hook";
import type { ReportHeaderData } from "@prodoctivity/types";
import { useAppTranslation } from "../../../hooks/useAppTranslation";

export const ReportWidget = (parameters: any) => {
  const {
    resetConfiguration,
    state,
    saveConfiguration,
    isLoading,
    filtersConfig,
    handleCheckAll,
    handlerBoxFilterValues,
    payload,
    updateFilterValue,
    formConfig,
    changeSort,
    setEditMode,
  } = useCollectionReportWidget(parameters);

  const { colors } = useColors();
  const { resources } = useAppTranslation();

  useMemo(() => {
    setEditMode(parameters["allowParamEdition"] as boolean);
  }, [parameters, setEditMode]);

  const editModeContent = useCallback(() => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        direction="column"
        height={400}
        padding={4}
        position="relative"
      >
        <Box
          display="flex"
          flex="grow"
          position="absolute"
          color={colors.neutral600}
          width={"100%"}
          height={"100%"}
          opacity={0.2}
        ></Box>

        <FormControllerProvider
          contextDefinition={formConfig.inputContextDefinition as any}
          wizardDefinition={formConfig.inputWizardDefinition as any}
          dataLinkMappings={[]}
          executeDataLink={undefined}
          moment={moment}
        >
          <WidgeReportForm
            saveConfiguration={saveConfiguration}
            resetConfiguration={resetConfiguration}
          />
        </FormControllerProvider>
      </Box>
    );
  }, [
    colors.neutral600,
    formConfig.inputContextDefinition,
    formConfig.inputWizardDefinition,
    resetConfiguration,
    saveConfiguration,
  ]);

  const invalidConfigurationContent = useCallback(() => {
    return (
      <Box display="flex" direction="column" alignItems="center">
        <Box margin={4} padding={4}>
          {resources.noDataLoaded}
        </Box>
        <Box></Box>
      </Box>
    );
  }, [resources.noDataLoaded]);

  const renderContentDependEditionMode = useMemo(() => {
    const allowEdition = state.widgetParameters.editionMode;
    const hasCollectionId = state.widgetParameters.collectionId !== undefined;

    if (!allowEdition && !hasCollectionId) {
      return invalidConfigurationContent();
    }

    if (allowEdition) {
      return editModeContent();
    }

    if (!allowEdition && isLoading) {
      return (
        <Box display="flex" direction="column" alignItems="center">
          <Box margin={4} padding={4}>
            {resources.loading}
          </Box>
          <Box></Box>
        </Box>
      );
    }

    if (state.widgetData.categoryFilters.length === 0) {
      return invalidConfigurationContent();
    }

    return (
      <CollectionReportWithFilter
        dataSetForPieLoading={isLoading}
        filtersConfig={filtersConfig as ReportHeaderData}
        handleCheckAll={handleCheckAll}
        state={state.widgetData}
        handlerBoxFilterValues={handlerBoxFilterValues}
        payload={payload}
        assignedFilters={state.assignedFilters}
        setAssignedFilter={updateFilterValue}
        changeSort={changeSort}
        defaults={state.widgetData.defaults}
      />
    );
  }, [
    state.widgetParameters.collectionId,
    editModeContent,
    filtersConfig,
    handleCheckAll,
    handlerBoxFilterValues,
    isLoading,
    payload,
    updateFilterValue,
    changeSort,
    state.assignedFilters,
    state.widgetData,
    state.widgetParameters.editionMode,
    resources.loading,
    invalidConfigurationContent,
  ]);

  return renderContentDependEditionMode;
};
