import {
  Box,
  ComboBox,
  Divider,
  Icon,
  SearchField,
  Table,
  TapArea,
  Text,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type { HttpGetDocumentTypeListRequest, ListableDocumentType } from "@prodoctivity/types";
import { FunctionComponent, useCallback, useMemo } from "react";

import type { DocumentTypeSettingsFilter } from "@prodoctivity/shared/src/index-types";
import { useCopyToClipboard } from "usehooks-ts";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { Page } from "../../../components/Layout/Page";
import { Pagination } from "../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../link-templates";
import { EditFillSvgIcon } from "../../../svg/EditFillSvgIcon";
import { useDocumentType } from "./hooks";

const PAGINATION_OPTIONS: HttpGetDocumentTypeListRequest["queryParameters"]["rowsPerPage"][] = [
  "15",
  "30",
  "100",
];

const DocumentTypeListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const { groupList } = useDocumentType();

  const {
    paginatedData,
    currentPage,
    filter,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    refetch,
    setFilter,
    setPageLength,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    { documentTypes: ListableDocumentType[] },
    HttpGetDocumentTypeListRequest["queryParameters"]["rowsPerPage"],
    DocumentTypeSettingsFilter
  >(
    "15",
    { documentTypeName: undefined, documentGroupId: undefined },
    (services, currentPage, rowsPerPage, filter) => {
      return services.getDocumentTypeList(
        currentPage,
        rowsPerPage,
        filter.documentTypeName,
        filter.documentGroupId
      );
    },
    `document_type_list_settings_page`
  );

  const [_value, copyToClipboard] = useCopyToClipboard();

  const { breakpoint } = useDesignBreakpoint();

  const translateMimeType = useCallback(
    (mimeType: string) => {
      switch (mimeType) {
        case "image/png":
        // case "image/gif":
        case "image/jpeg":
        case "image/tiff":
        case "image/bmp":
          // return `${resources.image} ${mimeType.split("/")[1].toUpperCase()}`;
          return resources.images;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "Word";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "Excel";
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return "Power Point";
        case "application/pdf":
          return "PDF";
        case "application/octet-stream":
          return "binariFile";
        case "application/zip":
          return "Zip";
        case "text/csv":
          return "CSV";
        case "application/xml":
          return "XML";
        default:
          return "Other";
      }
    },
    [resources.images]
  );

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: `${resources.documentaryStructure}`,
        url: organizationLinkTemplates.manageDocumentTypes(),
      },
      { type: "text", name: resources.documentTypes.documentTypes },
    ];
  }, [
    resources.documentTypes.documentTypes,
    resources.documentaryStructure,
    resources.home,
    resources.settings,
  ]);

  const changeFilter = useCallback(
    (filter: string) => {
      setFilter((prev) => {
        return { ...prev, documentTypeName: filter };
      });
    },
    [setFilter]
  );

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <SearchComponentWrapper
          refetchFunction={refetch}
          componentHeaderText={resources.documentTypes.documentTypes}
          refreshButtonLabel={resources.search}
          searchFieldId="document-type-search"
          searchFieldLabel={resources.documentTypes.documentType}
          setFilter={changeFilter}
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          buttonAction={() => organizationNavigate("/settings/document-types/new")}
          omitSearch={true}
        />
        <Box margin={5} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="document_type_list_pagination_top"
            rowsLabel={`${resources.documentTypes.documentTypes}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.documentTypes.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            totalRowCount={totalRowCount}
            extraComponent={
              <Box display="flex" direction="row" flex="grow" gap={2}>
                <Box flex="grow">
                  <SearchField
                    marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                    marginEnd={2}
                    accessibilityLabel={resources.documentTypes.documentType}
                    id="document_ name_elements_filter"
                    onChange={({ value }) => {
                      setFilter((prev) => {
                        return { ...prev, documentTypeName: value };
                      });
                    }}
                  />
                </Box>
                <Box margin={1} />
                {groupList && (
                  <Box display="flex" flex="shrink">
                    <ComboBox
                      id={"document_ group_elements_filter"}
                      size="sm"
                      onSelect={({ item }) => {
                        setFilter((prev) => {
                          return { ...prev, documentGroupId: item.value };
                        });
                      }}
                      onClear={() => {
                        setFilter((prev) => {
                          return { ...prev, documentGroupId: undefined };
                        });
                      }}
                      placeholder={resources.documentGroup}
                      options={groupList.map((group) => ({
                        label: group.name,
                        value: group.id,
                      }))}
                      label=""
                      selectedOption={groupList
                        .map((group) => ({
                          label: group.name,
                          value: group.id,
                        }))
                        .find((option) => option.value === filter.documentGroupId)}
                    />
                  </Box>
                )}
              </Box>
            }
          />
          <Divider direction="horizontal" />
        </Box>
        <Box
          marginTop={8}
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Table accessibilityLabel={resources.documentTypes.documentTypes}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" weight="bold">
                      {resources.documentGroup.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box paddingX={4}>
                    <Text size="200" weight="bold">
                      {resources.name.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" weight="bold">
                      {resources.dataDictionary.defaultFormat.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" weight="bold">
                      {resources.template.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box>
                    <Text size="200" weight="bold">
                      {resources.actions.toUpperCase()}
                    </Text>
                  </Box>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(paginatedData?.documentTypes || []).map((dt, i) => (
                <Table.Row hoverStyle="gray" key={i}>
                  <Table.Cell>
                    <Text ellipsisLength={32} align="start" size="200">
                      {dt.documentGroupName}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                      <Box>
                        <Text ellipsisLength={50} size="200" weight="bold">
                          {dt.name}
                        </Text>
                      </Box>
                      <Box width={1} borderStyle={"sm"} borderRadius={4} />
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <Text size="200">
                      {Array.from(new Set(dt.acceptedMimeTypes.map(translateMimeType))).join(
                        ", "
                      ) || "N/A"}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Box display="flex" paddingLeft={4}>
                      {dt.templateVersionId ? (
                        <Icon
                          icon="check"
                          color={colors.success}
                          accessibilityLabel={resources.success}
                        />
                      ) : (
                        <Icon
                          icon="close"
                          color={colors.error}
                          accessibilityLabel={resources.success}
                        />
                      )}
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <Box display="flex">
                      <Box display="flex" gap={2}>
                        <Tooltip
                          text={resources.documentTypes.cannotDirectlyEditTemplates}
                          doNotShowIf={() => !dt.templateVersionId}
                        >
                          <TapArea
                            disabled={!!dt.templateVersionId}
                            onTap={() => {
                              organizationNavigate(
                                organizationLinkTemplates.manageDocumentTypeEdit(dt.documentTypeId)
                              );
                            }}
                          >
                            <EditFillSvgIcon
                              fillColor={
                                !!dt.templateVersionId ? colors.neutral700 : colors.primary
                              }
                            />
                          </TapArea>
                        </Tooltip>
                        <Tooltip text={resources.copyIdToClipboard}>
                          <TapArea onTap={() => copyToClipboard(dt.documentTypeId)}>
                            <Icon
                              icon="circle-info"
                              accessibilityLabel={resources.copyIdToClipboard}
                            />
                          </TapArea>
                        </Tooltip>
                        <Tooltip text={resources.duplicate}>
                          <TapArea
                            onTap={() =>
                              organizationNavigate(
                                `/settings/document-types/${dt.documentTypeId}?clone=true`
                              )
                            }
                          >
                            <Icon
                              accessibilityLabel={resources.duplicate}
                              icon="clone"
                              size={"sm"}
                            />
                          </TapArea>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Box>
        <Box marginTop={5} padding={2} display="flex" direction="column">
          <Box display="flex" flex="grow">
            <Box display="flex" flex="grow" />
            <Box display="flex" flex="shrink">
              <Pagination<typeof rowsPerPage>
                id="document_type_list_pagination_bottom"
                rowsLabel={`${resources.documentTypes.documentTypes}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={paginatedData ? paginatedData?.documentTypes.length : 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setPageLength}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={PAGINATION_OPTIONS}
                totalRowCount={totalRowCount}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default DocumentTypeListPage;
