import { ColorConfig, calculateColor } from "../utils";

import { FunctionComponent } from "react";
import { useColors } from "../components";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const PreviewDocSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_7774_20007)">
        <path
          d="M8 13C8 12.2044 8.31607 11.4413 8.87868 10.8787C9.44129 10.3161 10.2044 10 11 10H29C29.7956 10 30.5587 10.3161 31.1213 10.8787C31.6839 11.4413 32 12.2044 32 13V28C32 28.7956 31.6839 29.5587 31.1213 30.1213C30.5587 30.6839 29.7956 31 29 31H11C10.2044 31 9.44129 30.6839 8.87868 30.1213C8.31607 29.5587 8 28.7956 8 28V13ZM11 28H18.5V16H11V28ZM29 16H21.5V28H29V16Z"
          fill={calculateColor(color || colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_7774_20007">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};
