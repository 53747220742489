import {
  Box,
  Button,
  Text,
  Tooltip,
  useColors,
  useFormController,
} from "@prodoctivity/design-system";
import { FunctionComponent, useMemo } from "react";

export const NavigationButtons: FunctionComponent<{
  hasBackButton: boolean;
  hasNextButton: boolean;
  finishButtonLabel: string | undefined;
  backButtonLabel?: string;
  i18n: (key: string) => string;
  prev: () => void;
  next: () => void;
  cancel?: () => void;
  cancelLabel?: string;
  isFinishButtonDisabled?: boolean;
  finish?: () => void;
  back?: () => void;
  summaryMode: boolean;
  formHasErrors: boolean;
  isLoading?: boolean;
}> = ({
  hasBackButton,
  hasNextButton,
  finishButtonLabel,
  backButtonLabel,
  finish,
  cancel,
  cancelLabel,
  isFinishButtonDisabled,
  i18n,
  prev,
  next,
  back,
  summaryMode,
  formHasErrors,
  isLoading,
}) => {
  const formController = useFormController();
  const { colors } = useColors();

  const isFinishDisabled = useMemo(() => {
    return isLoading || !(!formHasErrors && formController.validationResult.success);
  }, [formController.validationResult.success, formHasErrors, isLoading]);

  return (
    <div
      className="steps-action"
      style={{
        marginLeft: "10px",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      {/* <Button.Group> */}
      <Box display="flex" gap={2} direction="row">
        {cancel && (
          <Button
            accessibilityLabel={i18n("cancel")}
            onClick={cancel}
            text={cancelLabel || i18n("cancel")}
            color={"gray"}
            disabled={summaryMode && formHasErrors ? true : false}
            size="lg"
          />
        )}
        <Box display="flex" flex="grow" />
        {hasBackButton ? (
          <Button
            accessibilityLabel={backButtonLabel || i18n("back")}
            onClick={prev}
            text={backButtonLabel || i18n("back")}
            color={"gray"}
            disabled={summaryMode && formHasErrors ? true : false}
            size="lg"
          />
        ) : back ? (
          <Button
            accessibilityLabel={backButtonLabel || i18n("back")}
            onClick={back}
            text={backButtonLabel || i18n("back")}
            color={"gray"}
            disabled={summaryMode && formHasErrors ? true : false}
            size="lg"
          />
        ) : null}
        {hasNextButton &&
          (formController.currentSectionErrors.length ? (
            <Tooltip text={i18n("invalid")}>
              <Box display="flex" direction="row" alignItems="center">
                <Button
                  accessibilityLabel={i18n("next")}
                  text={i18n("next")}
                  color={"blue"}
                  onClick={next}
                  disabled={isLoading || formHasErrors}
                  size="lg"
                />
                {formHasErrors && <Text color={colors.error}>*</Text>}
              </Box>
            </Tooltip>
          ) : (
            <Button
              accessibilityLabel={i18n("next")}
              text={i18n("next")}
              color={"blue"}
              onClick={next}
              disabled={isLoading || formHasErrors}
              size="lg"
            />
          ))}
        {finish &&
          (!isFinishDisabled ? (
            <Button
              accessibilityLabel={finishButtonLabel || i18n("finish")}
              text={finishButtonLabel || i18n("finish")}
              color={"blue"}
              onClick={finish}
              disabled={isFinishDisabled || isFinishButtonDisabled}
              size="lg"
            />
          ) : (
            <Tooltip
              text={formController.validationResult.errors.map((err) => err.message).join(", ")}
            >
              <Button
                accessibilityLabel={finishButtonLabel || i18n("finish")}
                text={finishButtonLabel || i18n("finish")}
                color={"blue"}
                onClick={finish}
                disabled={isFinishDisabled}
                size="lg"
              />
            </Tooltip>
          ))}
      </Box>
      {/* </Button.Group> */}
    </div>
  );
};
