import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CalculatorIconSvg: FunctionComponent<Props> = ({
  color,
  width = 22,
  height = 25,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5701_96517)">
        <path
          d="M19.8458 16.5198C19.6169 16.3822 19.5496 16.2445 19.5496 15.9692C19.5496 11.3711 19.5496 6.77313 19.5496 2.16134C19.5496 0.894824 18.6744 0 17.4357 0H2.16769C0.848225 0 0 0.881057 0 2.24394V12.5C0 15.9141 0 19.3144 0 22.7285C0 24.1189 0.834761 25 2.19461 25C7.28397 25 12.3599 25 17.4357 25C17.7185 25 18.0012 24.9862 18.2705 24.9312C20.1151 24.6145 21.5557 23.1553 21.9192 21.2417C22.2693 19.4246 21.4211 17.4972 19.8458 16.5198ZM14.6622 24.174H2.07344C1.34639 24.174 0.821297 23.6234 0.821297 22.8662V10.6691C0.821297 7.90198 0.821297 5.14868 0.821297 2.39537C0.821297 2.16134 0.821297 1.91355 0.888617 1.70705C1.03672 1.18392 1.52142 0.853524 2.05998 0.839758H10.5961C12.8715 0.839758 15.1603 0.839758 17.4357 0.839758C18.2166 0.839758 18.7283 1.36289 18.7283 2.16134V15.7764C18.7283 15.8453 18.7283 15.9141 18.7283 16.0105C17.4492 15.6663 16.2509 15.8453 15.1334 16.5474C14.3121 17.0705 13.7332 17.8001 13.3696 18.7087C12.6561 20.4983 13.1004 22.7423 14.9045 24.1465C14.8103 24.1465 14.743 24.1602 14.6756 24.1602L14.6622 24.174ZM17.5165 24.174C15.4431 24.1602 13.8274 22.467 13.8409 20.3469C13.8678 18.337 15.5373 16.6575 17.5165 16.685C19.5765 16.6988 21.1787 18.3921 21.1652 20.5121C21.1652 22.5083 19.4688 24.2015 17.5165 24.174Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M17.3394 1.66602H2.1674C1.75007 1.66602 1.62891 1.78991 1.62891 2.23044V6.08507C1.62891 6.53936 1.75007 6.67703 2.20779 6.67703H17.3664C17.7972 6.67703 17.9183 6.53936 17.9183 6.09883V2.27174C17.9183 1.78991 17.7972 1.66602 17.326 1.66602H17.3394ZM17.0837 5.80974H2.46357V2.51954H17.0971V5.8235L17.0837 5.80974Z"
          fill={color || "#5A5A5A"}
        />
        <path d="M17.0974 2.51953H2.46387V5.8235H17.0974V2.51953Z" fill={color || "#5A5A5A"} />
        <path
          d="M5.31801 7.50293C4.49672 7.50293 3.67542 7.50293 2.84066 7.50293C2.154 7.50293 1.64237 8.03982 1.62891 8.74192C1.62891 9.01725 1.62891 9.29258 1.62891 9.58167C1.64237 10.2975 2.154 10.8207 2.85412 10.8344C3.25804 10.8344 3.67542 10.8344 4.07933 10.8344C4.48325 10.8344 4.9141 10.8344 5.33148 10.8344C5.96428 10.8344 6.50284 10.2975 6.5163 9.63674C6.5163 9.32011 6.5163 9.01725 6.5163 8.70062C6.50284 8.03982 5.96428 7.50293 5.31801 7.50293ZM5.70847 9.55414C5.70847 9.84324 5.5469 9.99467 5.26416 9.99467C4.8737 9.99467 4.46979 9.99467 4.06587 9.99467C3.66195 9.99467 3.29843 9.99467 2.92144 9.99467C2.61177 9.99467 2.46367 9.84324 2.4502 9.54037C2.4502 9.29258 2.4502 9.03101 2.4502 8.78322C2.4502 8.49412 2.61177 8.34269 2.90798 8.34269C3.68888 8.34269 4.48325 8.34269 5.26416 8.34269C5.53343 8.34269 5.695 8.50789 5.695 8.78322C5.695 9.04478 5.695 9.30634 5.695 9.56791L5.70847 9.55414Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M11.0126 11.6738C10.6086 11.6738 10.1913 11.6738 9.78734 11.6738C9.38343 11.6738 8.95258 11.6738 8.5352 11.6738C7.86201 11.6738 7.35038 12.2245 7.33691 12.899C7.33691 13.1881 7.33691 13.4772 7.33691 13.7526C7.35038 14.4547 7.87547 14.9916 8.54866 14.9916C9.36996 14.9916 10.1913 14.9916 11.026 14.9916C11.6992 14.9916 12.2108 14.4409 12.2243 13.7663C12.2243 13.4772 12.2243 13.1881 12.2243 12.9128C12.2243 12.2107 11.6858 11.6738 11.0126 11.6738ZM11.403 13.7113C11.403 14.0279 11.2414 14.1656 10.9452 14.1793C10.5548 14.1793 10.1643 14.1793 9.77388 14.1793C9.38343 14.1793 9.00644 14.1793 8.62945 14.1793C8.29285 14.1793 8.15821 14.0279 8.15821 13.6837C8.15821 13.4497 8.15821 13.2019 8.15821 12.9541C8.15821 12.6788 8.31978 12.5136 8.58906 12.5136C9.38343 12.5136 10.1778 12.5136 10.9856 12.5136C11.2549 12.5136 11.4165 12.6788 11.4165 12.9541C11.4165 13.2019 11.4165 13.4635 11.4165 13.7113H11.403Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M17.9186 12.8991C17.9186 12.2108 17.3936 11.6739 16.7204 11.6602C15.8856 11.6602 15.0508 11.6602 14.2295 11.6602C13.5698 11.6602 13.0582 12.1971 13.0312 12.8716C13.0312 13.1469 13.0312 13.4223 13.0312 13.7114C13.0312 14.4685 13.5563 14.9917 14.3103 14.9917C14.7008 14.9917 15.0912 14.9917 15.4817 14.9917C15.8856 14.9917 16.303 14.9917 16.6934 14.9917C17.3801 14.9917 17.9052 14.4548 17.9186 13.7527C17.9186 13.4636 17.9186 13.1745 17.9186 12.8991ZM16.653 14.1657C16.2626 14.1657 15.8856 14.1657 15.5086 14.1657C15.1316 14.1657 14.7277 14.1657 14.3372 14.1657C14.0006 14.1657 13.8525 14.0005 13.8525 13.6701C13.8525 13.4223 13.8525 13.1882 13.8525 12.9404C13.8525 12.6651 14.0141 12.4999 14.2834 12.4999C15.0778 12.4999 15.8856 12.4999 16.68 12.4999C16.9492 12.4999 17.1108 12.6651 17.1108 12.9404C17.1108 13.1882 17.1108 13.436 17.1108 13.6976C17.1108 14.0005 16.9492 14.1657 16.653 14.1657Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M10.9856 15.8311C10.1643 15.8311 9.3565 15.8311 8.54866 15.8311C7.87547 15.8311 7.35038 16.3818 7.33691 17.0701V17.8686C7.33691 18.612 7.86201 19.1489 8.58906 19.1489C8.97951 19.1489 9.38343 19.1489 9.78734 19.1489C10.1913 19.1489 10.5952 19.1489 10.9991 19.1489C11.6992 19.1489 12.2108 18.612 12.2243 17.8961C12.2243 17.6208 12.2243 17.3592 12.2243 17.0839C12.2243 16.368 11.6992 15.8174 10.9856 15.8174V15.8311ZM11.403 17.8824C11.403 18.1715 11.2414 18.3229 10.9452 18.3229C10.5548 18.3229 10.1509 18.3229 9.74695 18.3229C9.36996 18.3229 8.99297 18.3229 8.60252 18.3229C8.30631 18.3229 8.14475 18.1715 8.14475 17.8686C8.14475 17.6208 8.14475 17.3592 8.14475 17.1114C8.14475 16.8223 8.30631 16.6709 8.58906 16.6709C9.36996 16.6709 10.1643 16.6709 10.9587 16.6709C11.228 16.6709 11.3895 16.8361 11.3895 17.1114C11.3895 17.373 11.3895 17.6346 11.3895 17.8961L11.403 17.8824Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M10.972 20.003H8.54846C7.87526 20.003 7.33671 20.5537 7.32324 21.242C7.32324 21.5173 7.32324 21.7927 7.32324 22.0818C7.32324 22.7976 7.84833 23.3345 8.57538 23.3345C8.96584 23.3345 9.35629 23.3345 9.74674 23.3345C10.1776 23.3345 10.595 23.3345 11.0258 23.3345C11.699 23.3345 12.2106 22.7839 12.2241 22.0955C12.2241 21.8202 12.2241 21.5311 12.2241 21.2558C12.2241 20.5262 11.699 20.003 10.972 19.9893V20.003ZM11.4028 22.068C11.4028 22.3433 11.2412 22.4948 10.972 22.5085C10.5815 22.5085 10.1776 22.5085 9.77367 22.5085C9.36975 22.5085 9.00623 22.5085 8.62924 22.5085C8.30611 22.5085 8.158 22.3433 8.14454 22.0129C8.14454 21.7651 8.14454 21.5311 8.14454 21.2833C8.14454 21.008 8.30611 20.8428 8.57538 20.8428C9.36975 20.8428 10.1776 20.8428 10.972 20.8428C11.2412 20.8428 11.3893 21.008 11.4028 21.2833C11.4028 21.5449 11.4028 21.8064 11.4028 22.068Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M11.0529 7.50293C10.2047 7.50293 9.3565 7.50293 8.50827 7.50293C7.84854 7.50293 7.35038 8.05359 7.33691 8.72815C7.33691 9.00348 7.33691 9.29258 7.33691 9.56791C7.33691 10.2975 7.86201 10.8207 8.58906 10.8344C8.97951 10.8344 9.36996 10.8344 9.76041 10.8344C10.1913 10.8344 10.6086 10.8344 11.0395 10.8344C11.6992 10.8207 12.2108 10.2975 12.2378 9.62297C12.2378 9.32011 12.2378 9.01725 12.2378 8.71438C12.2108 8.05359 11.7127 7.5167 11.0664 7.50293H11.0529ZM11.403 9.52661C11.403 9.84324 11.2414 9.99467 10.9452 9.99467C10.5548 9.99467 10.1509 9.99467 9.74695 9.99467C9.36996 9.99467 8.97951 9.99467 8.60252 9.99467C8.30631 9.99467 8.14475 9.82947 8.14475 9.52661C8.14475 9.27881 8.14475 9.04478 8.14475 8.79698C8.14475 8.49412 8.30631 8.32892 8.57559 8.32892C9.36996 8.32892 10.1643 8.32892 10.9722 8.32892C11.2414 8.32892 11.403 8.49412 11.403 8.76945C11.403 9.01725 11.403 9.27881 11.403 9.52661Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M16.68 7.50302C15.8721 7.50302 15.0778 7.50302 14.2565 7.50302C13.5698 7.50302 13.0447 8.05369 13.0312 8.75578C13.0312 9.03111 13.0312 9.29267 13.0312 9.55424C13.0312 10.2701 13.5698 10.8208 14.2699 10.8208C14.6738 10.8208 15.0643 10.8208 15.4682 10.8208C15.8721 10.8208 16.2895 10.8208 16.6934 10.8208C17.3801 10.8208 17.9052 10.2976 17.9186 9.58177C17.9186 9.30644 17.9186 9.03111 17.9186 8.75578C17.9186 8.02615 17.3936 7.48926 16.68 7.48926V7.50302ZM17.1108 9.5267C17.1108 9.84333 16.9492 10.0085 16.6396 10.0085C16.2491 10.0085 15.8587 10.0085 15.4682 10.0085C15.0778 10.0085 14.7008 10.0085 14.3238 10.0085C14.0007 10.0085 13.8525 9.84333 13.8525 9.5267C13.8525 9.27891 13.8525 9.04487 13.8525 8.79708C13.8525 8.50798 14.0141 8.34278 14.2969 8.34278C15.0778 8.34278 15.8587 8.34278 16.6396 8.34278C16.9492 8.34278 17.0973 8.50798 17.1108 8.82461C17.1108 9.05864 17.1108 9.29267 17.1108 9.5267Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M5.29129 11.6739C4.88738 11.6739 4.49692 11.6739 4.09301 11.6739C3.68909 11.6739 3.28517 11.6739 2.88126 11.6739C2.18113 11.6739 1.65604 12.2108 1.64258 12.9267C1.64258 13.202 1.64258 13.4773 1.64258 13.7664C1.64258 14.4685 2.16767 15.0054 2.85433 15.0054C3.67563 15.0054 4.49692 15.0054 5.31822 15.0054C5.99142 15.0054 6.51651 14.4685 6.52997 13.7802C6.52997 13.5049 6.52997 13.2158 6.52997 12.9404C6.52997 12.2108 6.00488 11.6739 5.29129 11.6602V11.6739ZM5.29129 14.1657C4.49692 14.1657 3.68909 14.1657 2.89472 14.1657C2.62544 14.1657 2.46388 14.0005 2.46388 13.7251C2.46388 13.4773 2.46388 13.2295 2.46388 12.968C2.46388 12.6513 2.62544 12.4999 2.92165 12.4999C3.32556 12.4999 3.71602 12.4999 4.11993 12.4999H5.26437C5.56057 12.4999 5.70867 12.6513 5.72214 12.968C5.72214 13.2158 5.72214 13.4773 5.72214 13.7251C5.72214 14.0005 5.56057 14.1657 5.29129 14.1657Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M5.29088 15.8311C4.46958 15.8311 3.64828 15.8311 2.81352 15.8311C2.15379 15.8311 1.6287 16.3956 1.61523 17.0701C1.61523 17.3317 1.61523 17.607 1.61523 17.8686C1.61523 18.612 2.14033 19.1489 2.86738 19.1626H4.06566C4.48304 19.1626 4.90042 19.1626 5.3178 19.1626C5.991 19.1489 6.50263 18.612 6.51609 17.9237C6.51609 17.6483 6.51609 17.3592 6.51609 17.0839C6.50263 16.368 6.00446 15.8311 5.29088 15.8174V15.8311ZM5.69479 17.8548C5.69479 18.1715 5.53323 18.3229 5.23702 18.3229C4.84657 18.3229 4.44265 18.3229 4.03874 18.3229C3.66175 18.3229 3.29822 18.3229 2.92123 18.3229C2.5981 18.3229 2.45 18.1715 2.43653 17.8411C2.43653 17.607 2.43653 17.373 2.43653 17.139C2.43653 16.8361 2.5981 16.6709 2.8943 16.6709C3.68867 16.6709 4.46958 16.6709 5.26395 16.6709C5.53323 16.6709 5.69479 16.8361 5.69479 17.1114C5.69479 17.3592 5.69479 17.6208 5.69479 17.8686V17.8548Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M5.27762 20.0029C4.8737 20.0029 4.48325 20.0029 4.07933 20.0029H2.90798C2.16746 20.0029 1.64237 20.5398 1.62891 21.297C1.62891 21.5585 1.62891 21.8339 1.62891 22.0954C1.62891 22.77 2.16746 23.3207 2.82719 23.3344C3.64849 23.3344 4.46979 23.3344 5.30455 23.3344C6.00467 23.3344 6.5163 22.7975 6.52976 22.0679C6.52976 21.8063 6.52976 21.5448 6.52976 21.297C6.52976 20.5398 6.01814 20.0167 5.27762 20.0029ZM5.70847 22.0404C5.70847 22.357 5.56036 22.5084 5.25069 22.5084C4.86024 22.5084 4.46979 22.5084 4.07933 22.5084C3.68888 22.5084 3.32536 22.5084 2.9349 22.5084C2.59831 22.5084 2.46367 22.357 2.4502 22.0128C2.4502 21.7788 2.4502 21.531 2.4502 21.2832C2.4502 21.0079 2.61177 20.8427 2.88105 20.8427C3.67542 20.8427 4.48325 20.8427 5.27762 20.8427C5.56036 20.8427 5.70847 21.0079 5.70847 21.3107C5.70847 21.5448 5.70847 21.7926 5.70847 22.0404Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M17.9453 22.5081C17.878 23.1689 17.7972 23.3341 17.5145 23.3203C17.2317 23.3203 17.151 23.1551 17.0971 22.5081C16.4778 22.4393 16.0604 22.1226 15.9257 21.4756C15.8988 21.3379 15.8854 21.159 15.9661 21.0488C16.0469 20.9387 16.2219 20.8423 16.3566 20.8286C16.5451 20.8148 16.6797 20.9662 16.7201 21.1727C16.8144 21.5995 16.8817 21.6546 17.2991 21.6546C17.5145 21.6546 17.7299 21.6546 17.9319 21.6546C18.1742 21.6546 18.3358 21.4756 18.3492 21.2553C18.3492 21.0213 18.1742 20.8423 17.9184 20.8286C17.6761 20.8286 17.4472 20.8286 17.2048 20.8286C16.4912 20.8286 15.9527 20.3467 15.8988 19.6722C15.845 18.9701 16.3027 18.4332 17.0432 18.3231C17.0567 18.3231 17.0702 18.3231 17.1106 18.3093C17.151 17.9927 17.0029 17.4833 17.5549 17.5108C18.0396 17.5246 17.9184 17.9789 17.9453 18.3093C18.3089 18.3368 18.6185 18.4745 18.8609 18.7498C19.0494 18.9839 19.1571 19.2454 19.1571 19.562C19.1571 19.7961 18.9955 19.9613 18.7936 19.9888C18.5916 20.0026 18.4031 19.8649 18.3492 19.6309C18.255 19.2041 18.1877 19.1491 17.7703 19.1491C17.5818 19.1491 17.3798 19.1491 17.1914 19.1491C16.9086 19.1491 16.7336 19.3143 16.7201 19.562C16.7201 19.8098 16.8951 19.975 17.1914 19.9888C17.4068 19.9888 17.6357 19.9888 17.8511 19.9888C18.5647 19.9888 19.1032 20.4706 19.1571 21.1452C19.2109 21.8198 18.7532 22.3704 18.0396 22.4943C18.0126 22.4943 17.9857 22.4943 17.9588 22.4943L17.9453 22.5081Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M4.0524 10.0085C3.67541 10.0085 3.28496 10.0085 2.90797 10.0085C2.61176 10.0085 2.4502 9.85709 2.4502 9.54046C2.4502 9.29266 2.4502 9.0311 2.4502 8.7833C2.4502 8.49421 2.61176 8.34277 2.8945 8.34277C3.68887 8.34277 4.46978 8.34277 5.26415 8.34277C5.53343 8.34277 5.69499 8.49421 5.69499 8.76954C5.69499 9.0311 5.69499 9.29266 5.69499 9.55423C5.69499 9.84332 5.53343 9.99476 5.23722 10.0085C4.8333 10.0085 4.44285 10.0085 4.03893 10.0085H4.0524Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M9.77366 14.1657C9.39667 14.1657 9.00622 14.1657 8.62923 14.1657C8.29263 14.1657 8.158 14.0143 8.14453 13.6702C8.14453 13.4224 8.14453 13.1883 8.14453 12.9405C8.14453 12.6652 8.3061 12.5 8.57538 12.5C9.36974 12.5 10.1641 12.5 10.9719 12.5C11.2412 12.5 11.3893 12.6652 11.4028 12.9405C11.4028 13.1883 11.4028 13.4499 11.4028 13.6977C11.4028 14.0143 11.2547 14.152 10.945 14.1657C10.5546 14.1657 10.1641 14.1657 9.77366 14.1657Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M15.5086 14.1657C15.1181 14.1657 14.7277 14.1657 14.3372 14.1657C14.0006 14.1657 13.8525 14.0143 13.8525 13.6702C13.8525 13.4224 13.8525 13.1883 13.8525 12.9405C13.8525 12.6652 14.0141 12.5 14.2834 12.5C15.0778 12.5 15.8721 12.5 16.68 12.5C16.9492 12.5 17.0973 12.6652 17.1108 12.9405C17.1108 13.1883 17.1108 13.4499 17.1108 13.6977C17.1108 14.0006 16.9492 14.152 16.653 14.1657C16.276 14.1657 15.8856 14.1657 15.5086 14.1657Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M9.76041 18.3366C9.38342 18.3366 8.99296 18.3366 8.61598 18.3366C8.31977 18.3366 8.1582 18.1852 8.1582 17.8824C8.1582 17.6346 8.1582 17.373 8.1582 17.1252C8.1582 16.8361 8.31977 16.6847 8.60251 16.6709C9.39688 16.6709 10.1778 16.6709 10.9722 16.6709C11.2414 16.6709 11.403 16.8361 11.403 17.1114C11.403 17.373 11.403 17.6346 11.403 17.8961C11.403 18.1852 11.2414 18.3366 10.9587 18.3366C10.5548 18.3366 10.1643 18.3366 9.76041 18.3366Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M9.77366 22.4949C9.39667 22.4949 9.00622 22.4949 8.62923 22.4949C8.3061 22.4949 8.158 22.3297 8.14453 21.9993C8.14453 21.7515 8.14453 21.5174 8.14453 21.2696C8.14453 20.9943 8.3061 20.8429 8.57538 20.8291C9.36974 20.8291 10.1641 20.8291 10.9719 20.8291C11.2412 20.8291 11.3893 20.9943 11.4028 21.2696C11.4028 21.5312 11.4028 21.7928 11.4028 22.0543C11.4028 22.3297 11.2412 22.4811 10.9719 22.4949C10.568 22.4949 10.1776 22.4949 9.77366 22.4949Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M9.74673 9.99485C9.36974 9.99485 8.97929 9.99485 8.6023 9.99485C8.3061 9.99485 8.14453 9.82965 8.14453 9.52679C8.14453 9.27899 8.14453 9.04496 8.14453 8.79716C8.14453 8.4943 8.29263 8.3291 8.57538 8.3291C9.36974 8.3291 10.1641 8.3291 10.9719 8.3291C11.2412 8.3291 11.3893 8.4943 11.4028 8.76963C11.4028 9.01743 11.4028 9.27899 11.4028 9.52679C11.4028 9.82965 11.2547 9.99485 10.945 9.99485C10.5411 9.99485 10.1507 9.99485 9.74673 9.99485Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M15.4682 9.99485C15.0912 9.99485 14.7008 9.99485 14.3238 9.99485C14.0006 9.99485 13.866 9.84342 13.8525 9.51302C13.8525 9.26522 13.8525 9.03119 13.8525 8.7834C13.8525 8.4943 14.0141 8.3291 14.2968 8.3291C15.0778 8.3291 15.8587 8.3291 16.6396 8.3291C16.9627 8.3291 17.0973 8.48053 17.1108 8.81093C17.1108 9.04496 17.1108 9.27899 17.1108 9.51302C17.1108 9.84342 16.9492 9.99485 16.6396 9.99485C16.2491 9.99485 15.8587 9.99485 15.4682 9.99485Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M4.09258 12.5C4.46957 12.5 4.86002 12.5 5.23701 12.5C5.54668 12.5 5.69479 12.6514 5.69479 12.9681C5.69479 13.2159 5.69479 13.4774 5.69479 13.7252C5.69479 14.0006 5.53322 14.1657 5.26394 14.1657C4.46957 14.1657 3.6752 14.1657 2.86737 14.1657C2.59809 14.1657 2.43652 14.0006 2.43652 13.7252C2.43652 13.4774 2.43652 13.2159 2.43652 12.9681C2.43652 12.6514 2.58463 12.5 2.8943 12.5C3.29821 12.5 3.68867 12.5 4.09258 12.5Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M4.0524 18.3366C3.67541 18.3366 3.31188 18.3366 2.9349 18.3366C2.5983 18.3366 2.4502 18.1852 2.4502 17.8411C2.4502 17.607 2.4502 17.373 2.4502 17.139C2.4502 16.8361 2.5983 16.6709 2.90797 16.6709C3.70234 16.6709 4.48324 16.6709 5.27761 16.6709C5.54689 16.6709 5.70846 16.8361 5.70846 17.1114C5.70846 17.3592 5.70846 17.6208 5.70846 17.8686C5.70846 18.1714 5.56035 18.3366 5.25068 18.3366C4.84677 18.3366 4.45632 18.3366 4.0524 18.3366Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M4.07933 22.4949C3.70234 22.4949 3.31188 22.4949 2.9349 22.4949C2.5983 22.4949 2.4502 22.3434 2.4502 21.9993C2.4502 21.7515 2.4502 21.5174 2.4502 21.2696C2.4502 20.9943 2.61176 20.8291 2.88104 20.8291C3.67541 20.8291 4.46978 20.8291 5.27761 20.8291C5.56035 20.8291 5.70846 20.9943 5.70846 21.2972C5.70846 21.545 5.70846 21.779 5.70846 22.0268C5.70846 22.3297 5.56035 22.4949 5.25068 22.4949C4.86023 22.4949 4.46978 22.4949 4.07933 22.4949Z"
          fill={color || "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_5701_96517">
          <rect width="21.9975" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
