import * as React from "react";

import { Card } from "antd";

export interface ITaskActionsMenuProps {
  i18n: (key: string) => string;
}

export const TaskActionsMenu: React.FC<ITaskActionsMenuProps> = (props: ITaskActionsMenuProps) => {
  return (
    <Card title={props.i18n("Actions")}>
      <div />
    </Card>
  );
};
