import {
  Box,
  HEADER_ZINDEX,
  Icon,
  Image,
  OverlayPanel,
  TapArea,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import whiteLogoUrl from "../assets/logo_white.svg";
import { useAppTranslation } from "../hooks/useAppTranslation";
import { LogoSvg } from "../svg/LogoSvg";
import { MenuLink } from "./MenuLink";

export function BarMenu() {
  const { colors } = useColors();
  const navigate = useNavigate();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const paddingBreakpoint = breakpoint === "small" ? 2 : undefined;

  const [scroll, setScroll] = useState("not-scroll");

  const handleScroll = useCallback(() => {
    setScroll(window.scrollY > 0 ? "scroll" : "not-scroll");
  }, [setScroll]);

  window.addEventListener("scroll", handleScroll);

  return (
    <>
      {breakpoint === "hd" && (
        <Box display="flex" justifyContent="between">
          <Box>
            <TapArea onTap={() => navigate("/")}>
              <Box width={400} height={90}>
                <Image
                  src={whiteLogoUrl}
                  alt={resources.prodoctivityLogo}
                  fit="cover"
                  color="transparent"
                  naturalHeight={90}
                  naturalWidth={400}
                  role="img"
                />
              </Box>
            </TapArea>
          </Box>
          <Box fit={true} display="flex" direction="row" flex="grow"></Box>
          <MenuLink />
        </Box>
      )}
      {breakpoint === "large" && (
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" alignItems="center" width="95%">
            <Box
              onClickCapture={() => navigate("/")}
              width={"17.8%"}
              display="flex"
              alignItems="center"
              justifyContent="start"
              padding={2}
            >
              <LogoSvg width={60} height={88} />
            </Box>
            <Box width={"auto"}>
              <MenuLink />
            </Box>
          </Box>
        </Box>
      )}
      {(breakpoint === "small" || breakpoint === "medium") && (
        <Box
          display="flex"
          justifyContent={"between"}
          alignItems="center"
          width="100%"
          padding={scroll === "scroll" ? 3 : paddingBreakpoint}
          color={scroll === "scroll" ? colors.white : undefined}
          overflow={"auto"}
          position="fixed"
          zIndex={HEADER_ZINDEX}
          dangerouslySetInlineStyle={
            scroll === "scroll"
              ? {
                  __style: {
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
                  },
                }
              : undefined
          }
        >
          <Box padding={3}>
            <Box paddingX={paddingBreakpoint} display={"flex"}>
              <Box onClickCapture={() => navigate("/")}>
                {scroll !== "scroll" && <LogoSvg width={50} height={28} />}
              </Box>
            </Box>
          </Box>

          <Box display="flex">
            <Box display="flex" alignItems="center" paddingX={2}>
              <TapArea onTap={() => setShowMobileMenu(true)}>
                <Icon
                  icon={"hamburgerMenu"}
                  accessibilityLabel={resources.options}
                  size={"md"}
                  color={scroll === "scroll" ? colors.black600 : colors.white}
                ></Icon>
              </TapArea>
            </Box>
            <Box fit={true} display="flex" direction="row" flex="grow"></Box>

            {showMobileMenu && (
              <Box>
                <OverlayPanel
                  accessibilityLabel={resources.actions}
                  heading=""
                  onDismiss={() => setShowMobileMenu(false)}
                  size="lg"
                >
                  <MenuLink />
                </OverlayPanel>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
