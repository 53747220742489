import { useCallback } from "react";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";

export function useDocumentTypeVersionInfo(
  documentTypeId: string | undefined,
  documentTypeVersionId: string | undefined,
  enabled: boolean
) {
  const { getDocumentTypeVersionInfo } = useServices();
  const fetchDocumentType = useCallback(async () => {
    if (!documentTypeId || !documentTypeVersionId) {
      return {
        documentType: undefined,
      };
    }
    return await getDocumentTypeVersionInfo(documentTypeId, documentTypeVersionId);
  }, [documentTypeId, documentTypeVersionId, getDocumentTypeVersionInfo]);

  return useOrganizationQuery(
    [`document-type-version`, documentTypeId || "und", documentTypeVersionId || "und"],
    fetchDocumentType,
    {
      staleTime: 60 * 1000,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled,
    }
  );
}

export function useDocumentTypeInfoWithLatestVersion(
  documentTypeId: string | undefined,
  enabled: boolean
) {
  const { getDocumentTypeInfo } = useServices();
  const fetchDocumentType = useCallback(async () => {
    if (!documentTypeId) {
      return {
        documentType: undefined,
      };
    }
    return await getDocumentTypeInfo(documentTypeId);
  }, [documentTypeId, getDocumentTypeInfo]);

  return useOrganizationQuery(`document-type/${documentTypeId}`, fetchDocumentType, {
    staleTime: 60 * 1000,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled,
  });
}
