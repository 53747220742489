import {
  ContextStateUpdater,
  DatePickerProps,
  DesignBreakpointType,
  FormControllerProvider,
} from "@prodoctivity/design-system";
import type {
  ParametersObject,
  TemplateContextDefinition,
  TemplateVersionContextMapping,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import type { HttpExecuteDataLinkRequest, HttpExecuteDataLinkResponse } from "@prodoctivity/types";
import { useCallback, useMemo, useState } from "react";
import {
  DataElementValue,
  FormConfiguration,
  FormConnector,
  FormValues,
} from "../../components/_lib/types";

import { ProDoctivityForm } from "../../components";
import { contextToFormDefinition } from "../../components/_lib/context-to-FormDefinition";
//import { updateFormLayoutWithFormDefinition } from '../../components/ProDoctivityFormDesigner/utils'
import type momentType from "moment";
import { noop } from "../../components/_lib/utils";
import { wizardDefinitionToLayout } from "../../components/_lib/wizardDefinition-to-layout";

type Props = {
  contextDefinition: TemplateContextDefinition;
  wizardDefinition: TemplateWizardDefinition;
  initialFormValues?: any;
  summaryMode?: boolean;
  paginate: boolean;
  defaultColumns?: string;
  readOnly?: boolean;
  componentBreakpoint: DesignBreakpointType;
  moment: typeof momentType;
  onFormValuesChange?: (formData: ParametersObject) => void;
  executeDataLink?: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  dataLinkMappings?: TemplateVersionContextMapping["datalinks"];
  setFormHasErrors?(n: boolean): void;
  cancel?: () => void;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
};

type State = {
  // formDefinition: FormDefinition;
  selectedSectionKey: string | undefined;
  navigate: any;
};

export const ProDoctivityFormWrapper = ({
  contextDefinition,
  wizardDefinition,
  initialFormValues,
  paginate,
  summaryMode,
  defaultColumns,
  readOnly,
  componentBreakpoint,
  moment,
  onFormValuesChange,
  dataLinkMappings,
  executeDataLink,
  cancel,
  resources,
}: Props) => {
  const initialState: State = {
    // formDefinition: {
    //   dataElements: [],
    //   groups: [],
    //   allowedListValues: [],
    // },
    selectedSectionKey: wizardDefinition.pages[0]?.key,
    navigate: null,
  };
  const [state, setState] = useState<State>(initialState);

  // const { formLayout } = useMemo(() => {
  //   const formDefinition = contextToFormDefinition(contextDefinition, wizardDefinition);
  //   const formLayout = wizardDefinitionToLayout(
  //     formDefinition,
  //     wizardDefinition,
  //     state.selectedSectionKey
  //   );

  //   return { formLayout };
  // }, [contextDefinition, state.selectedSectionKey, wizardDefinition]);

  //const formDefinition = contextToFormDefinition(contextDefinition);
  //const formLayout = updateFormLayoutWithFormDefinition({ layout: [], groupLayouts: {} }, formDefinition).formLayout
  //const formLayout = wizardDefinitionToLayout(formDefinition, wizardDefinition);

  const onNavigate = useCallback((element: any) => {
    setState((prev) => ({ ...prev, navigate: element }));
  }, []);

  const onFormValuesUpdated = (args: { data: ParametersObject }) => {
    if (onFormValuesChange) {
      onFormValuesChange(args.data);
    }
  };

  //TODO: @eburgos Remove this
  const { formDefinition, formConfiguration } = useMemo(() => {
    const formDefinition = contextToFormDefinition(contextDefinition);
    const formLayout = wizardDefinitionToLayout(wizardDefinition, contextDefinition, undefined);

    const formConfiguration: FormConfiguration = {
      formLayout,
      formConnectors: [],
    };

    return { formDefinition, formLayout, formConfiguration };
  }, [contextDefinition, wizardDefinition]);

  // This condition is set to give the 'formLayout' property enough time to get its value before printing the summary
  if (formConfiguration.formLayout.layout.length === 0 && initialFormValues) {
    return <></>;
  }

  const dataLink = dataLinkMappings !== undefined ? dataLinkMappings : [];

  return (
    <FormControllerProvider
      contextDefinition={contextDefinition}
      wizardDefinition={wizardDefinition}
      dataLinkMappings={dataLinkMappings || []}
      executeDataLink={executeDataLink}
      moment={moment}
      initialContext={initialFormValues}
    >
      <ContextStateUpdater onUpdate={onFormValuesUpdated} />
      <ProDoctivityForm
        formDefinition={formDefinition}
        formLayout={formConfiguration.formLayout}
        connectors={[]}
        formConnectors={[]}
        dataLinks={[]}
        connectorDataLinks={[]}
        dataLinkMappings={dataLink}
        executeDataLink={
          executeDataLink || (() => new Promise<any>((resolve) => resolve({ context: {} })))
        }
        navigate={state.navigate || ""}
        onNavigate={onNavigate}
        readOnly={!!readOnly}
        summaryMode={summaryMode || false}
        initialFormValues={initialFormValues || {}}
        initialGroupValues={{}}
        onChangeFormValue={(_params: { name: string; value: DataElementValue }) => {
          //
        }}
        onFireConnector={(formConnector: FormConnector, formValues: FormValues) => {
          console.log(formConnector, formValues);
          return Promise.resolve({});
        }}
        onConnectorFail={noop}
        onNoDataFound={noop}
        onFormErrorsUpdate={noop}
        onDependencyLayoutUpdate={noop}
        showPins={true}
        initialPinnedElements={[]}
        sequenceBehaviour={"Lock"}
        i18n={(key: string) => key}
        initialPagesHeight={0}
        defaultColumns={defaultColumns}
        componentBreakpoint={componentBreakpoint}
        moment={moment}
        paginate={paginate}
        cancel={cancel}
        resources={resources}
        onUpdateValues={noop}
      />
    </FormControllerProvider>
  );
};
