import "@prodoctivity/design-system/styles";
import "moment/locale/es";
import "moment/locale/nl";
import "moment/locale/pt";

import { Box, ColorSchemeProvider, ColorSchemeProviderProps } from "@prodoctivity/design-system";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FunctionComponent, Suspense, useCallback, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ServicesContextProvider, isSuccessLogin } from "./context/ServicesContextProvider";

import type { HttpLoginResponse } from "@prodoctivity/types";
import { RouteNeedsPermission } from "./components/Layout/RouteNeedsPermission";
import { UserLoginWithSso } from "./components/Login/UserLoginWithSso";
import { OrganizationNavigate } from "./components/OrganizationNavigate";
import { SearchPage } from "./components/ResultsPage/SearchPage";
import { useDetectOs } from "./hooks";
import { useOrganizationNavigate } from "./hooks/useOrganizationNavigate";
import { useServices } from "./hooks/useServices";
import { useSettings } from "./hooks/useSettings";
import { DocumentRecoveryPage } from "./pages/DocumentRecovery/DocumentRecoveryPage";
import { SettingsProvider } from "./providers/SettingsProvider";

//All pages
import SnackbarProvider from "react-simple-snackbar";
import ApiDocumentationPage from "./pages/ApiDocumentation/ApiDocumentation";
import AuthorWebPage from "./pages/Author/AuthorWeb";
import BrowseDocumentsPage from "./pages/BrowseDocumentsPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import DashboardPageWrapper, { PageWrapper } from "./pages/Dashboard/DashboardPageWrapper";
import IndexDocumentPage from "./pages/Dashboard/IndexDocument";
import DistributionManagementPage from "./pages/Distributions/DistributionManagement";
import DistributionManagementWrapper from "./pages/Distributions/DistributionManagementWrapper";
import DocumentCollectionInstancePage from "./pages/DocumentCollectionInstance/DocumentCollectionInstance";
import DocumentCollectionInstanceListPage from "./pages/DocumentCollectionInstance/DocumentCollectionInstanceList";
import DocumentLabelsPage from "./pages/DocumentLabels/DocumentLabelsPage";
import DownloadFluencyAdvancedAuthorPage from "./pages/DownloadFluencyAdvancedAuthorPage";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPassword";
import PasswordRecoveryPage from "./pages/ForgotPassword/PasswordRecovery";
import GenerationStatusPage from "./pages/GenerationStatus/GenerationStatus";
import GenerationListPage from "./pages/Generations/GenerationList";
import JoinOrganizationPage from "./pages/JoinOrganization/JoinOrganization";
import LandingPage from "./pages/LandingPage/LandingPage";
import LoginPage from "./pages/Login/Login";
import LoginTokenPage from "./pages/Login/LoginToken";
import LogoutPage from "./pages/Logout";
import NotificationsPage from "./pages/Notification/Notifications";
import PricingPage from "./pages/Pricing/Pricing";
import SettingsPage from "./pages/Settings";
import BundleConfigListPage from "./pages/Settings/Bundles/BundleConfigList";
import BundleConfigViewPage from "./pages/Settings/Bundles/BundleConfigView";
import CombinedTemplateListPage from "./pages/Settings/CombinedTemplate/CombinedTemplateList";
import ManageCombinedTemplatePage from "./pages/Settings/CombinedTemplate/ManageCombinedTemplate";
import DataElementConfigurationPage from "./pages/Settings/DataDictionary/DataElements/DataElementConfigurationPage";
import DataElementListPage from "./pages/Settings/DataDictionary/DataElements/DataElementsList";
import DataListConfigurationPage from "./pages/Settings/DataDictionary/DataListConfiguration";
import ManageDictionaryListsPage from "./pages/Settings/DataDictionary/DataLists";
import DeferredDistributionListPage from "./pages/Settings/DeferredMessages/Distributions/DeferredDistributionList";
import ManageDocumentCollectionsPage from "./pages/Settings/DocumentCollection/DocumentCollection";
import DocumentCollectionListPage from "./pages/Settings/DocumentCollection/DocumentCollectionList";
import DocumentGroupListPage from "./pages/Settings/DocumentGroup/DocumentGroupList";
import EditDocumentGroupPage from "./pages/Settings/DocumentGroup/NewDocumentGroup";
import ManageDocumentTypesPage from "./pages/Settings/DocumentType/DocumentType";
import DocumentTypeListPage from "./pages/Settings/DocumentType/DocumentTypeList";
import ListFragmentsPage from "./pages/Settings/Fragments/ListFragments";
import CertificateBuilder from "./pages/Settings/GraphicMarks/GraphicMarkBuilder/GraphicMarkBuilder";
import EditOrganizationRolePage from "./pages/Settings/Roles/EditOrganizationRole";
import RoleManagementListPage from "./pages/Settings/Roles/RoleManagementList";
import ListTemplatesPage from "./pages/Settings/Templates/ListTemplates";
import ChangePasswordPage from "./pages/Settings/Users/Actions/ChangePassword";
import EditOrganizationUserPage from "./pages/Settings/Users/Actions/EditOrganizationUser";
import AddUserToOrganizationPage from "./pages/Settings/Users/AddUserToOrganization";
import UserManagementListPage from "./pages/Settings/Users/UserManagementList";
import SignUpPage from "./pages/SignUp/SignUp";
import VerifyAccountPage from "./pages/SignUp/VerifyAccountPage";
import DocumentViewerLookupVersionPageFilter from "./pages/StandAloneViewer/DocumentViewerLookupVersionPageFilter";
import DocumentViewerPageFilter from "./pages/StandAloneViewer/DocumentViewerPageFilter";
import PaymentPage from "./pages/account/PaymentPage";
import ProfilePage from "./pages/account/ProfilePage";
import UpgradePlanPage from "./pages/account/UpgradePlanPage";
import UserSubscriptionsPage from "./pages/account/UserSubscriptionsPage";
import ActivityDetailPage from "./pages/activities/ActivityDetail";
import ActivityListPage from "./pages/activities/ActivityList";
import CombinedGenerationWizardPage from "./pages/combinedtemplates/CombineGenerationWizard";
import CombinedTemplateDetailsPage from "./pages/combinedtemplates/CombinedTemplateDetails";
import ProductPage from "./pages/product/Product";
import GenerationWizardPage from "./pages/templates/GenerationWizard";
import RedirectEditTemplatePage from "./pages/templates/RedirectEditTemplate";
import TemplateDetailPage from "./pages/templates/TemplateDetail";
import TemplateEditPage from "./pages/templates/TemplateEdit";
import TemplateListPage from "./pages/templates/TemplateList";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
import { TermsOfServicePrivacy } from "./pages/TermsOfServicePrivacy/TermsOfServicePrivacy";
import GraphicMarkPage from "./pages/Settings/GraphicMarks/GraphicMarks";
import RecentSearchesPage from "./pages/RecentSearches/RecentSearchesPage";
import ApprovalTypeListPage from "./pages/Settings/ApprovalType/ApprovalTypeListPage";
import EditApprovalTypePage from "./pages/Settings/ApprovalType/EditApprovalTypePage";

import { useCacheManager } from "./utils";
import SequencesListPage from "./pages/Settings/DataDictionary/Sequences/SequencesList";
import SequencesConfigurationPage from "./pages/Settings/DataDictionary/Sequences/SequencesConfigurationPage";
import { usePlanSetting } from "./hooks/usePlanSetting";
import { InboxPage } from "./components/Tasks/InboxPage";
import { TaskInstructions } from "./components/InstructionTask/TaskInstructions";
import OpenTaskPage from "./pages/tasks/OpenTask";

const queryClient = new QueryClient();

function App() {
  const [colorScheme, setColorScheme] = useState<ColorSchemeProviderProps["colorScheme"]>("light");

  useEffect(() => {
    (window as any).setColorScheme = (scheme: ColorSchemeProviderProps["colorScheme"]) => {
      setColorScheme(scheme);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SettingsProvider>
        <ColorSchemeProvider colorScheme={colorScheme}>
          <ServicesContextProvider>
            <SnackbarProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </SnackbarProvider>
          </ServicesContextProvider>
        </ColorSchemeProvider>
      </SettingsProvider>
    </QueryClientProvider>
  );
}

const AppRoutes: FunctionComponent = () => {
  const services = useServices();
  const { disableSignUp } = useSettings();
  const upgradePlanSetting = usePlanSetting();
  const { os } = useDetectOs();

  useCacheManager();

  const performAuthenticate = useCallback(
    async (
      user: string,
      password: string,
      returnTo: string | undefined,
      withGoogle?: boolean
    ): Promise<
      | { success: true; payload: HttpLoginResponse["payload"] }
      | { success: false; errorMessage: string }
    > => {
      const response = await services.authenticate(user, password, withGoogle).catch((r) => {
        return r.data ? r.data : undefined;
      });

      if (response && isSuccessLogin(response)) {
        services.saveToken(response.token);
        return {
          success: true,
          payload: response,
        };
      } else {
        return {
          success: false,
          errorMessage: response ? JSON.stringify(response) : "Forbidden",
        };
      }
    },
    [services]
  );

  return (
    <Suspense fallback={<div>...</div>}>
      <Routes>
        <Route
          path="sign-up"
          element={
            <PageWrapper>
              <SignUpPage />
            </PageWrapper>
          }
        />
        <Route
          path="verify-account/:otp"
          element={
            <PageWrapper>
              <VerifyAccountPage />
            </PageWrapper>
          }
        />
        <Route
          path="password-reset"
          element={
            <PageWrapper>
              <PasswordRecoveryPage />
            </PageWrapper>
          }
        />
        <Route path="join-organization" element={<JoinOrganizationPage />} />
        <Route
          path="logout"
          element={
            <PageWrapper>
              <LogoutPage logout={services.logout} />
            </PageWrapper>
          }
        ></Route>
        <Route
          path="login"
          element={
            <PageWrapper>
              <LoginPage authenticate={performAuthenticate} />
            </PageWrapper>
          }
        />
        <Route
          path="login-token/:token"
          element={
            <PageWrapper>
              <LoginTokenPage />
            </PageWrapper>
          }
        />
        <Route
          path="login-sso"
          element={
            <PageWrapper>
              <UserLoginWithSso />
            </PageWrapper>
          }
        />
        <Route
          path="forgot-password"
          element={
            <PageWrapper>
              <ForgotPasswordPage />
            </PageWrapper>
          }
        />
        <Route path="info" element={<LandingPage />} />
        {!disableSignUp && <Route path="pricing" element={<PricingPage />} />}
        <Route path="product" element={<ProductPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<TermsOfServicePrivacy />} />
        <Route path="docs">
          <Route index={true} element={<Navigate to="api" />} />
          <Route path="api" element={<ApiDocumentationPage />} />
        </Route>
        <Route
          path="edit-template/:token/:templateVersionId"
          element={<RedirectEditTemplatePage />}
        />
        <Route
          path="/"
          element={
            <RouteNeedsPermission>
              <OrgRedirection />
            </RouteNeedsPermission>
          }
        ></Route>
        <Route
          path=":organizationId"
          element={
            <RouteNeedsPermission>
              <DashboardPageWrapper />
            </RouteNeedsPermission>
          }
        >
          <Route
            index={true}
            element={
              <RouteNeedsPermission>
                <DashboardPage />
              </RouteNeedsPermission>
            }
          />

          <Route path="templates">
            <Route index={true} element={<TemplateListPage />} />
            <Route path="versions">
              <Route path=":templateVersionId">
                <Route path="details" element={<TemplateDetailPage />} />
                <Route path="generate" element={<GenerationWizardPage />} />
                <Route path="edit" element={<TemplateEditPage />} />
              </Route>
            </Route>
            <Route path="author">
              <Route path="new" element={<AuthorWebPage />} />
              <Route path=":templateVersionId" element={<AuthorWebPage />} />
            </Route>
          </Route>
          <Route path="combined-templates">
            <Route path=":combinedTemplateId">
              <Route path="details" element={<CombinedTemplateDetailsPage />} />
              <Route path="generate" element={<CombinedGenerationWizardPage />} />
            </Route>
          </Route>
          <Route path="generations">
            <Route index={true} element={<GenerationListPage />} />
            <Route path=":generationToken">
              <Route index={true} element={<GenerationStatusPage />} />
            </Route>
          </Route>
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="tasks">
            <Route path="built-in/:taskId" element={<OpenTaskPage />} />
            <Route path="list/:filter" index={true} element={<InboxPage />} />
            <Route path=":taskId" element={<TaskInstructions />} />
          </Route>
          <Route path="activities">
            <Route index={true} element={<ActivityListPage />} />
            <Route path=":activityId" element={<ActivityDetailPage />} />
          </Route>
          <Route path="search" element={<SearchPage />} />
          <Route path="account" element={<SettingsPage />}>
            <Route index={true} element={<OrganizationNavigate to={"/account/profile"} />} />
            <Route path="profile" element={<ProfilePage />} />
            {!disableSignUp && (
              <Route
                path="upgrade-plan"
                element={
                  upgradePlanSetting.upgradePlanBehavior === "blocked" ? (
                    <OrganizationNavigate to={"/"} />
                  ) : (
                    <RouteNeedsPermission permission="organization-admin">
                      <UpgradePlanPage />
                    </RouteNeedsPermission>
                  )
                }
              />
            )}
            <Route path="subscriptions" element={<UserSubscriptionsPage />} />
            <Route path="payment" element={<PaymentPage />} />
          </Route>
          <Route
            path="settings"
            element={
              <RouteNeedsPermission permission="organization-admin">
                <SettingsPage />
              </RouteNeedsPermission>
            }
          >
            <Route index={true} element={<OrganizationNavigate to={"/account/profile"} />} />

            <Route path="data-dictionary">
              <Route
                index={true}
                element={<OrganizationNavigate to={"/settings/data-dictionary/data-elements"} />}
              />
              <Route path="data-elements">
                <Route index={true} element={<DataElementListPage />} />
                <Route path="new" element={<DataElementConfigurationPage isUpdating={false} />} />
                <Route
                  path=":dataElementName"
                  element={<DataElementConfigurationPage isUpdating={true} />}
                />
              </Route>

              <Route path="sequences">
                <Route index={true} element={<SequencesListPage />} />
                <Route path="new" element={<SequencesConfigurationPage isUpdating={false} />} />
                <Route
                  path=":sequenceName"
                  element={<SequencesConfigurationPage isUpdating={true} />}
                />
              </Route>

              <Route path="data-lists">
                <Route index={true} element={<ManageDictionaryListsPage />} />
                <Route path="new" element={<DataListConfigurationPage isUpdating={false} />} />
                <Route
                  path=":dictionaryListName"
                  element={<DataListConfigurationPage isUpdating={true} />}
                />
              </Route>
            </Route>

            <Route path="fluency">
              <Route path="fragments" element={<ListFragmentsPage />} />
              <Route path="templates" element={<ListTemplatesPage />} />
              <Route path="combined-templates">
                <Route index={true} element={<CombinedTemplateListPage />} />
                <Route path="new" element={<ManageCombinedTemplatePage />} />
                <Route path=":combinedTemplateId" element={<ManageCombinedTemplatePage />} />
              </Route>
            </Route>

            <Route path="bundles">
              <Route index={true} element={<BundleConfigListPage />} />
              <Route path=":identifier" element={<BundleConfigViewPage />} />
            </Route>
            <Route path="document-groups">
              <Route index={true} element={<DocumentGroupListPage />}></Route>
              <Route path="new" element={<EditDocumentGroupPage isUpdating={false} />} />
              <Route
                path=":documentGroupId"
                element={<EditDocumentGroupPage isUpdating={true} />}
              />
            </Route>

            <Route path="approval-types">
              <Route index={true} element={<ApprovalTypeListPage />} />
              <Route path="new" element={<EditApprovalTypePage />} />
              <Route path=":id" element={<EditApprovalTypePage />} />
            </Route>

            <Route path="document-types">
              <Route index={true} element={<DocumentTypeListPage />} />
              <Route path="new" element={<ManageDocumentTypesPage />} />
              <Route path="new/:documentTypeId" element={<ManageDocumentTypesPage />} />
              <Route path=":documentTypeId" element={<ManageDocumentTypesPage />} />
            </Route>

            <Route path="document-collection-config">
              <Route index={true} element={<DocumentCollectionListPage />} />
              <Route path="new" element={<ManageDocumentCollectionsPage />} />
              <Route path=":id" element={<ManageDocumentCollectionsPage />} />
            </Route>

            <Route path="users">
              <Route index={true} element={<UserManagementListPage />}></Route>
              <Route path="new" element={<AddUserToOrganizationPage />} />
              <Route
                path=":username/profile"
                element={<EditOrganizationUserPage isUpdating={true} />}
              />
              <Route path=":username/change-password" element={<ChangePasswordPage />} />
            </Route>

            <Route path="roles">
              <Route index={true} element={<RoleManagementListPage />}></Route>
              <Route path="new" element={<EditOrganizationRolePage isUpdating={false} />} />
              <Route path=":roleId" element={<EditOrganizationRolePage isUpdating={true} />} />
            </Route>

            <Route path="graphic-mark">
              <Route index={true} element={<GraphicMarkPage />}></Route>
              <Route path="new" element={<CertificateBuilder isUpdating={false} />} />
              <Route path=":graphicMarkId" element={<CertificateBuilder isUpdating={true} />} />
            </Route>

            <Route path="deferred-distributions" element={<DeferredDistributionListPage />} />

            <Route path="distributions">
              <Route index={true} element={<DistributionManagementPage isUpdating={false} />} />
              <Route path="built-in">
                <Route path=":implementationId">
                  <Route
                    path="new"
                    element={<DistributionManagementWrapper isUpdating={false} />}
                  />
                </Route>
              </Route>

              <Route path=":distributionId">
                <Route index={true} element={<DistributionManagementWrapper isUpdating={true} />} />
                <Route path="new" element={<DistributionManagementWrapper isUpdating={false} />} />
              </Route>
            </Route>
          </Route>

          <Route path="documents-labels">
            <Route index={true} element={<DocumentLabelsPage />} />
          </Route>
          <Route path="documents">
            <Route index={true} element={<BrowseDocumentsPage />} />
            <Route
              path="new"
              element={
                <IndexDocumentPage
                  mode="import"
                  handleShowInstructionTask={undefined}
                  isInstructionsTask={false}
                />
              }
            />
            {os === "Windows" && (
              <Route
                path="scan"
                element={
                  <IndexDocumentPage
                    mode="scan"
                    handleShowInstructionTask={undefined}
                    isInstructionsTask={false}
                  />
                }
              />
            )}
            <Route path=":documentId">
              <Route index={true} element={<DocumentViewerLookupVersionPageFilter />} />
              <Route path="versions">
                <Route path=":documentVersionId" element={<DocumentViewerPageFilter />} />
              </Route>
            </Route>
          </Route>
          <Route path="document-recovery" element={<DocumentRecoveryPage />} />
          <Route path="recent-searches" element={<RecentSearchesPage />} />
          <Route path="document-collections">
            <Route index={true} element={<DocumentCollectionInstanceListPage />} />
            <Route path=":documentCollectionId" element={<DocumentCollectionInstancePage />} />
          </Route>
          <Route path="download-advanced-author" element={<DownloadFluencyAdvancedAuthorPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

function OrgRedirection() {
  const { user } = useServices();
  const navigate = useNavigate();
  const organizationNavigate = useOrganizationNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      organizationNavigate("/");
    }
  }, [navigate, organizationNavigate, user]);
  return <Box></Box>;
}

// eslint-disable-next-line import/no-default-export
export default App;
