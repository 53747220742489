import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const ScannerSvgIcon: FunctionComponent<Props> = ({
  color: rawColor,
  customClassName,
  width = 24,
  height = 24,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      viewBox="0 0 24 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_514_2138)">
        <path
          d="M12.0015 10.6036C12.0015 7.59068 12.0015 4.57775 12.0015 1.56229C12.0015 0.479756 12.3649 0.108818 13.3919 0.111341C14.6334 0.113864 15.022 0.444429 15.022 1.55472C15.027 7.58059 15.022 13.609 15.027 19.6348C15.027 20.2001 14.9564 20.8259 14.376 20.9621C13.7855 21.1009 13.084 21.0782 12.5213 20.8764C12.2437 20.7779 12.0267 20.1193 12.0217 19.7105C11.9813 16.6749 11.999 13.6367 11.999 10.6011L12.0015 10.6036Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M23.9972 10.5949C23.9972 13.6079 23.9972 16.6233 23.9972 19.6363C23.9972 20.7289 23.5607 21.1654 22.5008 21.1629C21.4234 21.1604 20.9717 20.7238 20.9717 19.6489C20.9691 13.6205 20.9666 7.59462 20.9717 1.56624C20.9717 0.455943 21.3603 0.122855 22.5942 0.115285C23.6263 0.110238 23.9947 0.481177 23.9972 1.55614C23.9997 4.56908 23.9972 7.58453 23.9972 10.5975V10.5949Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M10.5046 10.6007C10.5046 13.6136 10.5046 16.6266 10.5046 19.642C10.5046 20.7321 10.0681 21.1687 9.00322 21.1636C7.93834 21.1586 7.48161 20.7145 7.48161 19.6395C7.47656 13.6136 7.48161 7.58526 7.47656 1.55939C7.47656 0.991629 7.56993 0.363304 8.15536 0.254798C8.77359 0.138722 9.47761 0.21947 10.0782 0.416295C10.3129 0.491997 10.4819 1.11528 10.487 1.49126C10.5198 4.5269 10.5046 7.56507 10.5046 10.6007Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M0.000144946 10.5854C0.000144946 7.61787 -0.00237845 4.65288 0.000144946 1.68537C0.00519173 0.300023 1.1281 -0.409051 2.36709 0.247032C2.65728 0.400959 2.85915 0.824889 2.9828 1.16807C3.08626 1.45826 3.0156 1.80901 3.0156 2.13453C3.0156 7.95095 3.0156 13.7674 3.0156 19.5838C3.0156 20.7471 2.55634 21.1937 1.42586 21.1609C0.431645 21.1332 0.00771513 20.7092 0.00519173 19.6898C-0.00237845 16.6541 0.00519173 13.6185 0.00519173 10.5829L0.000144946 10.5854Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M6.02361 10.6687C6.02361 13.7498 6.02361 16.8308 6.02361 19.9119C6.02361 20.1188 6.0867 20.3913 5.98324 20.5226C5.78641 20.7673 5.48108 21.0853 5.22874 21.0777C4.98397 21.0701 4.72659 20.7118 4.52724 20.4645C4.44649 20.3636 4.50201 20.1516 4.50201 19.9876C4.50201 13.7321 4.50201 7.47662 4.50201 1.22112C4.50201 1.12776 4.50201 1.03439 4.50201 0.943549C4.52472 0.464104 4.7064 0.158773 5.25398 0.15625C5.79651 0.15625 6.00847 0.459057 6.01604 0.935979C6.03118 2.00085 6.02361 3.06825 6.02361 4.13312C6.02361 6.31081 6.02361 8.4885 6.02361 10.6662V10.6687Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M17.9943 10.5952C17.9943 7.53688 17.9943 4.47853 17.9943 1.42018C17.9943 1.21074 17.9236 0.953349 18.022 0.806992C18.1886 0.554653 18.4662 0.191284 18.6832 0.201378C18.9633 0.213995 19.261 0.516802 19.4856 0.754001C19.5815 0.854937 19.5184 1.1098 19.5184 1.29401C19.5184 7.50408 19.5184 13.7142 19.5184 19.9242C19.5184 20.1311 19.579 20.4062 19.473 20.5324C19.2711 20.7746 18.9633 21.0875 18.7109 21.0774C18.4687 21.0673 18.1987 20.714 18.0246 20.4541C17.9261 20.3078 17.9968 20.0479 17.9968 19.841C17.9968 16.7599 17.9968 13.6763 17.9968 10.5952H17.9943Z"
          fill={color ? calculateColor(color) : undefined}
        />
      </g>
      <defs>
        <clipPath id="clip0_514_2138">
          <rect width="24" height="21.1637" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
