import {
  Box,
  frontIndex,
  useColors,
  Text,
  Grid,
  DatePicker,
  ComboBox,
  ColorConfig,
  getRGBAFromCSSVariable,
  HEADER_ZINDEX,
} from "@prodoctivity/design-system";
import React, { FC, useMemo } from "react";
// import { ProgressDocumentCollection } from "../../../pages/Dashboard/components/progressDocumentCollectionView";
import { DoughnutCollectionGroupedDataView } from "../../../pages/Dashboard/components/GroupedDataPie";
import { MyResponsiveBar } from "../../../pages/Dashboard/components/wigets/Bar";
import { MyResponsiveLine } from "../../../pages/Dashboard/components/wigets/Lines";
import data from "./demo-data-set.json";
import { StatsContainer } from "../../../pages/Dashboard/components/StatsContainer";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import { createUuid } from "@prodoctivity/shared";
// import moment from "moment";

type Grouped<T> = {
  [key: string]: T[];
};

function groupBy<T>(arr: T[], key: keyof T): Grouped<T> {
  return arr.reduce((accumulator, currentValue) => {
    const groupKey = currentValue[key] as unknown as string;

    if (!accumulator[groupKey]) {
      accumulator[groupKey] = [];
    }

    accumulator[groupKey].push(currentValue);
    return accumulator;
  }, {} as Grouped<T>);
}

type dummyNode = {
  fechafin: string;
  fechainicio: string;
  proceso: string;
  usuario: string;
};

export type dummyDemoType = {
  Abortados: Array<dummyNode>;
  Activos: Array<dummyNode>;
  Completados: Array<dummyNode>;
  "En progreso": Array<dummyNode>;
  Pendiente: Array<dummyNode>;
};

export const TaskReportWidget: React.FC = () => {
  // const dummyDataSet: {
  //   level: number;
  //   identity: string;
  //   data: {
  //     issues: number;
  //     expired: number;
  //     expiring: number;
  //     valid: number;
  //   };
  // }[] = [];

  const inProgress = data["En progreso"][0];
  const unassigned = data["Pendiente"][0];
  const actives = data["Activos"][0];
  const canceled = data["Abortados"][0];
  const completed = data["Completados"][0];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDataFormArray = (array: any[], field: string, value: string): Array<any> => {
    if (array.length === 0) return [];
    const foundData = array.filter((x) => x[field] === value);
    return foundData;
  };

  const processTypes: {
    level: number;
    identity: string;
    data: {
      issues: number;
      expired: number;
      expiring: number;
      valid: number;
    };
  }[] = [
    "prestamo hipotecario",
    "Prestamo de vehiculo",
    "tarjeta de crédito",
    "cuenta de ahorro",
    "cuenta corriente",
    "certificado financiero",
    "Seguro de vida",
    "seguro de vehiculo",
  ].map((x, i) => ({
    level: i,
    identity: x,
    data: {
      expired: [
        ...getDataFormArray(inProgress, "proceso", x),
        ...getDataFormArray(unassigned, "proceso", x),
        ...getDataFormArray(actives, "proceso", x),
        ...getDataFormArray(canceled, "proceso", x),
        ...getDataFormArray(completed, "proceso", x),
      ].length,
      issues: 0,
      expiring: 0,
      valid: 0,
    },
  }));

  const asigmentsByUser = ["daniel", "jorge", "Waddy", "manuel", "maria"].map((row) => ({
    user: row,
    [row]: getDataFormArray(inProgress, "usuario", row).length,
  }));

  const mergedData: dummyNode[] = [
    ...data.Abortados,
    ...data.Activos,
    ...data.Completados,
    ...data["En progreso"],
    ...data.Pendiente,
  ]
    .flatMap((x) => x)
    .map((x) => ({
      fechafin: x.fechafin !== "" ? new Date(x.fechafin).toISOString().split("T")[0] : "",
      fechainicio: x.fechainicio !== "" ? new Date(x.fechainicio).toISOString().split("T")[0] : "",
      proceso: x.proceso,
      usuario: x.usuario,
    }));

  const { colors } = useColors();
  return (
    <Box
      id="widget-container"
      display="flex"
      alignItems="center"
      justifyContent="center"
      direction="column"
      padding={4}
      position="relative"
    >
      <Box
        id="widget-container-additional"
        display="flex"
        flex="grow"
        position="absolute"
        color={colors.neutral600}
        width={"100%"}
        height={"100%"}
        opacity={0.2}
      ></Box>
      <Box
        id="main-container"
        display="flex"
        direction="column"
        width={"100%"}
        padding={4}
        gap={4}
        color={colors.neutral300}
      >
        <Box id="filter-panel" marginEnd={-12}>
          <Box id="filter-panel-child" paddingRight={12}>
            <Box id="filter-panel-box">
              <Box
                id="filter-elements-container"
                display="flex"
                direction="column"
                gap={4}
                marginBottom={4}
              >
                <Box borderRadius={6} id="element-box">
                  <FilterTitleBox />
                  <FilterElementsBox>
                    <DateFilterLine title="fecha inicio" />
                    <DateFilterLine title="fecha final" />
                    <SeletedLine
                      title="Usuario"
                      items={["daniel", "jorge", "Waddy", "manuel", "maria"]}
                    />
                    <SeletedLine
                      title="Proceso"
                      items={[
                        "prestamo hipotecario",
                        "Prestamo de vehiculo",
                        "tarjeta de crédito",
                        "cuenta de ahorro",
                        "cuenta corriente",
                        "certificado financiero",
                        "Seguro de vida",
                        "seguro de vehiculo",
                      ]}
                    />
                  </FilterElementsBox>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box id="panel-content" color={colors.neutral300} width={"100%"} zIndex={frontIndex}>
          <Box
            id="panel-main-container"
            direction="column"
            display="flex"
            gap={4}
            color={colors.neutral300}
          >
            <TaskStatusResume dataSet={data} />
            <Grid
              gridTemplateColumns={["repeat(auto-fill, minmax(500px, 1fr))"]}
              gap={6}
              width={"100%"}
            >
              <DoughnutCollectionGroupedDataView
                dataSet={processTypes}
                isLoading={false}
                valueToRepresent="expired"
                title={"Total de tareas"}
                filterHasSelection={true}
              />
              <StatsContainer title="Tareas iniciados por usuario" size={{ width: "100%" }}>
                <Box display="flex" direction="column" flex="grow" minHeight={250} gap={6}>
                  <Box display="flex" direction="row" flex="grow" maxHeight={250}>
                    <MyResponsiveBar data={asigmentsByUser} />
                  </Box>
                </Box>
              </StatsContainer>

              <StatsContainer title="Tareas por fecha de inicios" size={{ width: "100% " }}>
                <Box display="flex" direction="column" flex="grow" minHeight={250} gap={6}>
                  <Box display="flex" direction="row" flex="grow" maxHeight={250}>
                    <MyResponsiveLine data={groupBy(mergedData, "fechainicio")} />
                  </Box>
                </Box>
              </StatsContainer>
              <StatsContainer title="Tareas por fecha de finalizacion" size={{ width: "100%" }}>
                <Box display="flex" direction="column" flex="grow" minHeight={250} gap={6}>
                  <Box display="flex" direction="row" flex="grow" maxHeight={250}>
                    <MyResponsiveLine
                      data={groupBy(
                        mergedData.filter((x) => x.fechafin !== ""),
                        "fechafin"
                      )}
                    />
                  </Box>
                </Box>
              </StatsContainer>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const FilterTitleBox: FC = () => {
  const { colors } = useColors();
  return (
    <Box id="filter-title" display="flex" direction="column">
      <Box
        id="box-title"
        color={colors.white}
        display="flex"
        paddingY={3}
        paddingX={4}
        alignItems="center"
        alignContent="center"
        borderRadius={6}
      >
        <Text color={colors.primary100} weight="bold">
          Filtros
        </Text>
      </Box>
    </Box>
  );
};

export const FilterElementsBox: FC<{ children?: JSX.Element[] }> = ({ children }) => {
  const { colors } = useColors();
  return (
    <Box
      id="elements-box"
      color={colors.white}
      paddingY={3}
      paddingX={4}
      overflow="scrollY"
      display="flex"
      direction="row"
      marginEnd={-12}
      paddingRight={12}
      gap={5}
      zIndex={HEADER_ZINDEX}
      // maxHeight={"300px"}
      width={"100%"}
    >
      <>{children}</>
    </Box>
  );
};

export const DateFilterLine: FC<{ title: string }> = ({ title }) => {
  const { resources } = useAppTranslation();
  return (
    <Box display="flex" direction="row" alignItems="center" gap={2}>
      <Box display="flex" flex="grow" gap={2} paddingTop={1} paddingBottom={1}>
        <Text>{title}</Text>
      </Box>
      <Box>
        <DatePicker
          value={new Date()}
          onChange={() => console.log()}
          placeholder="Select date"
          dataType="Date"
          resources={resources}
        />
      </Box>
    </Box>
  );
};

export const SeletedLine: FC<{ title: string; items: string[] }> = ({ title, items }) => {
  const listOfProcess = items.map((x) => ({ label: x, value: x }));
  listOfProcess.unshift({ label: "default", value: "default" });
  return (
    <Box display="flex" direction="row" alignItems="center" gap={2}>
      <Box display="flex" flex="grow" gap={2} paddingTop={1} paddingBottom={1}>
        <Text>{title}</Text>
      </Box>
      <Box>
        <ComboBox id="" label="" options={listOfProcess} zIndex={HEADER_ZINDEX} />
      </Box>
    </Box>
  );
};

export const TaskStatusResume: FC<{ dataSet: any }> = ({ dataSet }) => {
  const inProgress = dataSet["En progreso"][0];
  const unassigned = dataSet["Pendiente"][0];
  const actives = dataSet["Activos"][0];
  const canceled = dataSet["Abortados"][0];
  const completed = dataSet["Completados"][0];
  const total =
    inProgress.length + unassigned.length + actives.length + canceled.length + completed.length;
  return (
    <Box id="box-for-progress-bar" display="flex" direction="row" gap={6} width={"100%"}>
      <LocalProgressDocumentCollection
        title={"Total de tareas"}
        value={total}
        total={total}
        color={"rgb(4, 118, 231)"}
        showRadios={false}
      />
      <LocalProgressDocumentCollection
        title={"No asignado"}
        value={unassigned.length}
        total={total}
        color={"rgb(213, 38, 38)"}
        showRadios={true}
      />
      <LocalProgressDocumentCollection
        title={"En progreso"}
        value={inProgress.length}
        total={total}
        color={"rgb(127, 74, 223)"}
        showRadios={true}
      />
      <LocalProgressDocumentCollection
        title={"Pausado"}
        value={actives.length}
        total={total}
        color={"rgb(255, 219, 111)"}
        showRadios={true}
      />
      <LocalProgressDocumentCollection
        title={"Cancelados"}
        value={canceled.length}
        total={total}
        color={"rgb(255, 219, 111)"}
        showRadios={true}
      />
      <LocalProgressDocumentCollection
        title={"Completados"}
        value={completed.length}
        total={total}
        color={"rgb(255, 219, 111)"}
        showRadios={true}
      />
    </Box>
  );
};

export type Props = {
  title: string;
  value: number | undefined;
  color?: ColorConfig | string;
  total: number | undefined;
  showRadios: boolean;
};

export const LocalProgressDocumentCollection: React.FC<Props> = ({
  title,
  value,
  color,
  total,
  showRadios,
}) => {
  const { colors } = useColors();

  const valueInPercent = useMemo(() => {
    if (!value) {
      return 0;
    }
    if (!total) {
      return 0;
    }
    return (value / total) * 100;
  }, [value, total]);

  const valuePercentString = value === total ? "100" : valueInPercent.toPrecision(2);
  const convertToNumericString = useMemo(() => {
    if (!value) {
      return "0 %";
    }
    return value.toLocaleString("en-us", { minimumFractionDigits: 0 });
  }, [value]);

  const renderTitle = () => {
    return (
      <Box id="myProgressBox" display="flex" direction="column" gap={2} width={"100%"}>
        <Box id="myProgressBox-2" display="flex" direction="row">
          <Text weight="bold" color={colors.neutral600} size="200">
            {title}
          </Text>
        </Box>
        <Box display="flex" direction="row" alignContent="center" alignItems="center" gap={10}>
          <Box>
            <Text color={colors.black900} weight="bold" size="600">
              {convertToNumericString}
            </Text>
          </Box>
          <Box>
            {showRadios && (
              <Box width={"60px"}>
                <CircularProgressbar
                  value={valueInPercent}
                  text={`${valuePercentString} %`}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0.75,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "25px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: !color
                      ? `rgba(92, 152, 255, ${60 / 100})`
                      : typeof color === "string"
                      ? color
                      : getRGBAFromCSSVariable(color), //`rgba(92, 152, 255, ${60 / 100})`,
                    textColor: "#000",
                    trailColor: "#d6d6d6",
                    backgroundColor: "#3e98c7",
                  })}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" flex="grow">
      <StatsContainer title={renderTitle()}></StatsContainer>
    </Box>
  );
};
