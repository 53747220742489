import { Button } from "antd";

import { Box } from "@prodoctivity/design-system";
import { EditableText } from "../EditableText";
import { noop } from "../_lib/utils";

const ButtonsContainer = ({ children }: { children: any }) => (
  <div style={{ padding: "0 5px", position: "absolute", right: "0px", zIndex: "500" }}>
    {children}
  </div>
);

/*const ButtonsContainer = styled('div')`
  padding: 0 5px;
  position: absolute;
  right: 0px;
  z-index: 500;
`*/

type Props = {
  isBeingEdited?: boolean;
  isDesignMode?: boolean;
  paddingX: 0 | 4;
  topicId: string;
  topicName: string;
  enableTopicEditing: () => void;
  removeTopic: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
  onSubmitEditTopic: () => void;
  layoutTopics: Set<string>;
  i18n: (key: string) => string;
};

export function Topic({
  isBeingEdited = false,
  isDesignMode,
  topicId,
  topicName,
  paddingX,
  enableTopicEditing = noop,
  removeTopic = noop,
  onSubmitEditTopic = noop,
  layoutTopics = new Set(),
  i18n,
}: Props) {
  return (
    // <Container
    //   style={{
    //     display: isBeingEdited ? "block" : "auto",
    //   }}
    // >
    <Box>
      <ButtonsContainer>
        {isDesignMode && !isBeingEdited && (
          <div className="float-right">
            <Button size="small" data-topic-id={topicId} onClick={enableTopicEditing}>
              {i18n("edit")}
            </Button>
            <Button
              size="small"
              data-topic-id={topicId}
              data-topic-name={topicName}
              onClick={(e: any) => removeTopic(e)}
            >
              {i18n("remove")}
            </Button>
          </div>
        )}
      </ButtonsContainer>
      <Box display="flex" direction="row" paddingX={paddingX}>
        <EditableText
          onSubmitEditTopic={onSubmitEditTopic}
          layoutTopics={layoutTopics}
          editing={isBeingEdited}
          i18n={i18n}
        >
          {topicName}
        </EditableText>
      </Box>
    </Box>
    //</Container>
  );
}
