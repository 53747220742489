import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const SocialMediaIconTwitter: FunctionComponent<Props> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g transform="translate(0.125)">
        <circle cx="15" cy="15" r="15" transform="translate(-0.125)" fill="#fff" />
        <path
          d="M17.831,6.083a6.81,6.81,0,0,1-1.934.532A3.38,3.38,0,0,0,17.378,4.75a6.693,6.693,0,0,1-2.14.818,3.367,3.367,0,0,0-5.827,2.3A3.3,3.3,0,0,0,9.5,8.64,9.545,9.545,0,0,1,2.554,5.116,3.375,3.375,0,0,0,3.6,9.618,3.29,3.29,0,0,1,2.071,9.2v.042a3.371,3.371,0,0,0,2.7,3.305,3.391,3.391,0,0,1-.887.118,3.178,3.178,0,0,1-.633-.063A3.374,3.374,0,0,0,6.4,14.94a6.753,6.753,0,0,1-4.185,1.444,6.827,6.827,0,0,1-.8-.049,9.415,9.415,0,0,0,5.156,1.524,9.526,9.526,0,0,0,9.592-9.6c0-.146,0-.292-.01-.435A6.857,6.857,0,0,0,17.831,6.083Z"
          transform="translate(5.545 4.132)"
          fill="#1e394e"
        />
      </g>
    </svg>
  );
};
