import {
  Box,
  Label,
  SelectList,
  TextArea,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import type {
  DictionaryList,
  DictionaryListItem,
  DictionaryListSchema,
  ListType,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback } from "react";

import { getListTypes } from "@prodoctivity/shared";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { DependentLists } from "./DependentLists";
import { findList } from "./utils";

type PropertiesTabProps = {
  list: DictionaryList<string>;
  onChange: (list: DictionaryList<string>) => void;
};

export const PropertiesTab: FunctionComponent<PropertiesTabProps> = ({ list, onChange }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const refreshListItems = useCallback(
    (items: DictionaryListItem[], schema: DictionaryListSchema): DictionaryListItem[] => {
      if (!schema.children) {
        return [...items];
      } else {
        return items.map((item) => {
          const updatedItem: DictionaryListItem = {
            key: item.key,
            label: item.label,
            children: {},
          };
          for (const c of schema.children) {
            if (updatedItem.children) {
              if (item.children && item.children[c.name]) {
                updatedItem.children[c.name] = refreshListItems(item.children[c.name], c);
              } else {
                updatedItem.children[c.name] = [];
              }
            }
          }
          return updatedItem;
        });
      }
    },
    []
  );
  const updateListSchema = useCallback(
    (
      schema: DictionaryListSchema,
      updatedValue: DictionaryListSchema & { newName?: string },
      parentListName: string
    ): DictionaryListSchema => {
      if (schema.name === parentListName) {
        return {
          ...schema,
          children: schema.children.map((s) => {
            if (s.name === updatedValue.name) {
              const newName = updatedValue.newName ?? updatedValue.name;
              delete updatedValue.newName;
              return { ...updatedValue, name: newName };
            } else {
              return s;
            }
          }),
        };
      }
      return {
        ...schema,
        children: schema.children.map((s) => updateListSchema(s, updatedValue, parentListName)),
      };
    },
    []
  );
  const addListSchema = useCallback(
    (
      schema: DictionaryListSchema,
      newValue: DictionaryListSchema,
      parentListName: string
    ): DictionaryListSchema => {
      if (schema.name === parentListName) {
        return {
          ...schema,
          children: [...schema.children, newValue],
        };
      }
      return {
        ...schema,
        children: schema.children.map((s) => addListSchema(s, newValue, parentListName)),
      };
    },
    []
  );

  const removeListSchema = useCallback(
    (schema: DictionaryListSchema, listName: string): DictionaryListSchema => {
      if (schema.children.find((s) => s.name === listName)) {
        return {
          ...schema,
          children: schema.children.filter((s) => s.name !== listName),
        };
      }
      return {
        ...schema,
        children: schema.children.map((s) => removeListSchema(s, listName)),
      };
    },
    []
  );
  const handleChange = useCallback(
    (updatedValue: DictionaryListSchema & { newName?: string }, parentListName: string) => {
      const updatedList = { ...list };
      if (updatedValue.newName && findList(updatedList.listSchema, updatedValue.newName)) {
        console.log("Unable to change name: List Exists");
      } else {
        updatedList.listSchema = updateListSchema(
          updatedList.listSchema,
          updatedValue,
          parentListName
        );
        updatedList.items = refreshListItems(updatedList.items, updatedList.listSchema);
        onChange(updatedList);
      }
    },
    [list, onChange, refreshListItems, updateListSchema]
  );
  const handleAdd = useCallback(
    (newValue: DictionaryListSchema, parentListName: string) => {
      const updatedList = { ...list };
      if (!findList(updatedList.listSchema, newValue.name)) {
        updatedList.listSchema = addListSchema(updatedList.listSchema, newValue, parentListName);
      } else {
        console.log("Unable to add: List Exists");
      }
      updatedList.items = refreshListItems(updatedList.items, updatedList.listSchema);
      onChange(updatedList);
    },
    [addListSchema, list, onChange, refreshListItems]
  );
  const handleRemove = useCallback(
    (listName: string) => {
      const updatedList = { ...list };
      updatedList.listSchema = removeListSchema(updatedList.listSchema, listName);
      updatedList.items = refreshListItems(updatedList.items, updatedList.listSchema);
      onChange(updatedList);
    },
    [list, onChange, refreshListItems, removeListSchema]
  );
  return (
    <Box display="flex" direction="column" color={colors.white}>
      {/* Form Start */}
      <Box display="flex" direction="row" paddingX={6} paddingY={4}>
        <Box direction="column" width={"50%"}>
          <Box alignItems="center" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={116}>
              <Label htmlFor="listName">{resources.name}</Label>
            </Box>
            <Box width={293}>
              <TextField
                onChange={({ value }) => {
                  const currentSchema = list.listSchema;
                  currentSchema.name = value;
                  onChange({ ...list, listSchema: currentSchema });
                }}
                id="listName"
                placeholder={resources.listName}
                value={list.listSchema.name}
                maxLength={{
                  characterCount: 100,
                  errorAccessibilityLabel: "100 max",
                }}
              />
            </Box>
          </Box>
          <Box alignItems="center" display="flex">
            <Box width={116}>
              <Label htmlFor="listDescription">{resources.description}</Label>
            </Box>
            <Box width={293}>
              <TextArea
                onChange={({ value }) => {
                  const currentSchema = list.listSchema;
                  currentSchema.description = value;
                  onChange({ ...list, listSchema: currentSchema });
                }}
                value={list.listSchema.description}
                id="listDescription"
                maxLength={{
                  characterCount: 400,
                  // TODO: i18n
                  errorAccessibilityLabel: "400 max",
                }}
              ></TextArea>
            </Box>
          </Box>
        </Box>
        <Box direction="column" width={"50%"}>
          <Box alignItems="center" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={116}>
              <Label htmlFor="listType">{resources.dataDictionary.listType}</Label>
            </Box>
            <Box width={293}>
              <SelectList
                id="listType"
                onChange={({ value }) => {
                  const currentSchema = list.listSchema;
                  currentSchema.listType = value as ListType;
                  onChange({ ...list, listSchema: currentSchema });
                }}
                options={getListTypes().map((t) => {
                  return { label: resources.dataDictionary.listTypeLabels[t], value: t };
                })}
                placeholder={resources.dataDictionary.listTypePlaceholder}
                value={list.listSchema.listType}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Form End */}
      <Box>
        <DependentLists
          currentSchema={list.listSchema}
          onChange={handleChange}
          onAdd={handleAdd}
          onRemove={handleRemove}
          parentListName={list.listSchema.name}
        />
      </Box>
    </Box>
  );
};
