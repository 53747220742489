import { FunctionComponent } from "react";
import { calculateColor, type ColorConfig } from "../utils";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ResendInvitationIconSvg: FunctionComponent<Props> = ({
  color,
  width = 33,
  height = 31,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      cursor="pointer"
      style={svgStyle}
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 40 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6313_99864)">
        <path
          d="M38.6094 2.71875C31.9447 2.71875 25.2681 2.71875 18.6034 2.71875C18.4955 2.71875 18.3996 2.73089 18.1839 2.74302C18.4955 2.96149 18.7233 3.11928 18.951 3.26492C19.7182 3.78682 20.4973 4.29659 21.2285 4.86704C21.4802 5.06123 21.684 5.15833 21.9957 5.15833C26.287 5.15833 30.5782 5.15833 34.8815 5.15833H36.9313C36.9313 5.15833 36.9432 5.20688 36.9552 5.24329C32.9876 8.14408 29.0319 11.057 25.0643 13.9699C23.0985 12.5377 21.1566 11.1177 19.1908 9.69764C18.4236 10.2317 17.6684 10.7414 16.8893 11.2755C17.0331 11.3969 17.117 11.4697 17.201 11.5304C19.5264 13.2174 21.8518 14.8924 24.1773 16.5794C24.8126 17.0406 25.2801 17.0285 25.9274 16.5794C26.0352 16.5066 26.1311 16.4338 26.227 16.361C29.5953 13.9214 32.9756 11.4939 36.3439 9.05437C36.7275 8.77521 37.123 8.49606 37.5666 8.19263V20.524H12.5021V14.2612C11.7589 14.8195 11.0158 15.1108 10.0928 14.8438C10.0928 15.0623 10.0928 15.2201 10.0928 15.39C10.0928 17.2955 10.0928 19.1889 10.0928 21.0945C10.0928 22.7451 10.3685 23.0243 11.9867 23.0243C16.254 23.0243 20.5213 23.0243 24.7886 23.0243C29.3915 23.0243 33.9825 23.0243 38.5854 23.0243C39.5324 23.0243 39.9999 22.5509 39.9999 21.6164C39.9999 15.8027 39.9999 9.98893 39.9999 4.17521C39.9999 3.22851 39.5324 2.75516 38.5974 2.75516L38.6094 2.71875Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M10.3566 9.72213C10.3446 10.6081 10.3326 11.482 10.3566 12.368C10.3566 12.5986 10.4405 12.9506 10.5844 13.0113C10.7642 13.0841 11.0878 12.987 11.2796 12.8657C14.1085 10.9601 16.9254 9.04245 19.7423 7.12477C20.3656 6.69997 20.3536 6.43295 19.7423 6.00815C17.9443 4.78229 16.1462 3.55643 14.3482 2.33057C13.2934 1.61448 12.2385 0.886247 11.1717 0.194426C11.0159 0.0851915 10.7282 0.0366427 10.5724 0.109466C10.4405 0.170152 10.3686 0.449308 10.3566 0.643503C10.3206 0.971207 10.3566 1.31105 10.3566 1.65089C10.3566 2.41553 10.3566 3.18018 10.3566 3.93268C10.2727 3.95696 10.2367 3.98123 10.1888 3.98123C8.51064 3.94482 6.8205 3.92055 5.1783 3.47147C3.3683 2.96171 1.83398 2.02714 0.851064 0.327936C0.719209 0.109466 0.52742 -0.0968665 0.29967 0.0487799C0.143842 0.145878 0 0.412896 0 0.607091C0.0479473 3.47147 1.42643 5.55907 3.75187 7.07622C5.66976 8.32635 7.80342 8.96962 10.021 9.33374C10.2847 9.38229 10.3686 9.46725 10.3566 9.73427V9.72213Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6313_99864">
          <rect width="40" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
