import { FunctionComponent, useMemo } from "react";
import { Page } from "../../../components/Layout/Page";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { useApprovalTypes } from "./hooks";
import { organizationLinkTemplates } from "../../../link-templates";
import {
  Box,
  Button,
  Divider,
  Flex,
  Layer,
  Modal,
  SearchField,
  Table,
  TapArea,
  Text,
  TrashSvgIcon,
  modalZIndex,
} from "@prodoctivity/design-system";
import { SearchComponentWrapper } from "../../../components/SearchComponentWrapper";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import type { HttpGetApprovalTypesRequest } from "@prodoctivity/types";
import { Pagination } from "../../../components/Layout/Pagination";
import { EditSvgIcon } from "../../../svg/EditSvgIcon";
import { NotificationMessage } from "../../../components/NotificationMessage";

const PAGINATION_OPTIONS: HttpGetApprovalTypesRequest["queryParameters"]["rowsPerPage"][] = [
  "15",
  "50",
  "100",
  "500",
];

const ApprovalTypeListPage: FunctionComponent = () => {
  const organizationNavigate = useOrganizationNavigate();
  const {
    colors,
    resources,
    breakpoint,
    deleteInfo,
    setDeleteInfo,
    mutateDelete,
    isMutating,
    toastMessage,
    setToastMessage,
    paginatedData,
    refetch,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setFilter,
    setPageLength,
    totalRowCount,
  } = useApprovalTypes();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.approvalType.manageApprovalTypes,
        url: organizationLinkTemplates.manageApprovalTypes(),
      },
      { type: "text", name: resources.approvalType.approvalTypes },
    ];
  }, [
    resources.home,
    resources.approvalType.manageApprovalTypes,
    resources.approvalType.approvalTypes,
    resources.settings,
  ]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        {deleteInfo && (
          <Layer zIndex={modalZIndex}>
            <Modal
              accessibilityModalLabel="Edit Pin"
              heading={
                <Box height={50}>
                  <Box margin={4}>
                    <Text weight="bold" size="400" color={colors.secondary}>
                      {resources.approvalType.deleteApprovalType}
                    </Text>
                  </Box>
                  <Divider />
                </Box>
              }
              size="sm"
              onDismiss={() => setDeleteInfo(undefined)}
              footer={
                <Box display="flex">
                  <Box>
                    <Button
                      color={"transparent"}
                      onClick={() => setDeleteInfo(undefined)}
                      text={resources.cancel}
                    />
                  </Box>
                  <Box marginStart="auto">
                    <Button
                      color={"blue"}
                      onClick={() => {
                        mutateDelete({ id: deleteInfo.id });
                        setDeleteInfo(undefined);
                      }}
                      text={resources.yesDelete}
                    />
                  </Box>
                </Box>
              }
            >
              <Box margin={4} display="flex" justifyContent="center">
                <Text>
                  {resources.youAreAboutToDeleteThisApprovalType.replace(
                    "{approvalType}",
                    deleteInfo.label
                  )}
                </Text>
              </Box>
              <Divider />
            </Modal>
          </Layer>
        )}
        {toastMessage && (
          <NotificationMessage
            type={toastMessage.type}
            message={toastMessage.message}
            position="bottom-left"
            delay={5000}
            handleDismiss={() => setToastMessage(undefined)}
          />
        )}
        <SearchComponentWrapper
          componentHeaderText={resources.approvalType.approvalTypes}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="approval-type-search"
          searchFieldLabel={resources.search}
          setFilter={setFilter}
          addButtonLabel={resources.addNew}
          addButtonText={resources.addNew}
          disableAddButton={isMutating}
          buttonAction={() => organizationNavigate(organizationLinkTemplates.newApprovalTypes())}
          omitSearch={true}
        />

        <Box margin={5} borderRadius={4} display="flex" direction="column">
          <Pagination<typeof rowsPerPage>
            id="approve_type_list_pagination_top"
            rowsLabel={`${resources.approvalType.approvalTypes}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.approvalTypes.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            totalRowCount={totalRowCount}
            extraComponent={
              <Box display="flex" direction="row" gap={2}>
                <Box>
                  <SearchField
                    marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                    marginEnd={2}
                    accessibilityLabel={resources.approvalType.approvalTypes}
                    id="approve_type_label_filter"
                    onChange={({ value }) => {
                      setFilter(value);
                    }}
                  />
                </Box>
              </Box>
            }
          />
        </Box>

        <Box
          marginTop={8}
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Table accessibilityLabel="Roles Table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Box paddingX={4}>
                    <Text size="200">{resources.label.toUpperCase()}</Text>
                  </Box>
                </Table.HeaderCell>
                {breakpoint !== "small" && breakpoint !== "medium" && (
                  <>
                    <Table.HeaderCell>
                      <Text size="200">{resources.rolesManagement.roles.toUpperCase()}</Text>
                    </Table.HeaderCell>
                  </>
                )}
                <Table.HeaderCell>
                  <Text size="200">{resources.actions.toUpperCase()}</Text>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(paginatedData?.approvalTypes || []).map((tg) => (
                <Table.Row hoverStyle="gray" key={`${tg.id}`}>
                  <Table.Cell>
                    <Box marginStart={4}>
                      <Text
                        size="200"
                        weight="bold"
                        title={tg.label}
                        ellipsisLength={breakpoint === "medium" || breakpoint === "small" ? 15 : 32}
                      >
                        {tg.label}
                      </Text>
                    </Box>
                  </Table.Cell>
                  {breakpoint !== "small" && breakpoint !== "medium" && (
                    <Table.Cell>
                      <Text size="200">{tg.roles.length}</Text>
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    <Flex gap={3}>
                      <Box>
                        <TapArea
                          disabled={isMutating}
                          onTap={() =>
                            organizationNavigate(organizationLinkTemplates.editApprovalTypes(tg.id))
                          }
                        >
                          <EditSvgIcon color={colors.primary} />
                        </TapArea>
                      </Box>
                      <Box>
                        <TapArea
                          disabled={isMutating}
                          onTap={() => setDeleteInfo({ id: tg.id, label: tg.label })}
                        >
                          <TrashSvgIcon color={colors.primary} />
                        </TapArea>
                      </Box>
                    </Flex>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Box>

        <Box
          color={colors.white}
          marginTop={5}
          padding={2}
          borderRadius={4}
          borderStyle="lg"
          display="flex"
          direction="column"
        >
          <Pagination<typeof rowsPerPage>
            id="approval-type_list_pagination_bottom"
            rowsLabel={`${resources.approvalType.approvalTypes}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.approvalTypes.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
          />
        </Box>
      </Box>
    </Page>
  );
};

export default ApprovalTypeListPage;
