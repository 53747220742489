import { Box, Divider, Skeleton } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { range } from "@prodoctivity/shared";

export const RecentActivitiesSkeleton: FunctionComponent = () => {
  return (
    <Box width={"100%"}>
      {range(7).map((n) => (
        <Box width={"100%"} key={n} paddingX={5}>
          <Skeleton width="50%" height={35} />
          <Box padding={3} />
          <Skeleton width="100%" height={70} />
          <Box paddingY={3} />
          <Divider />
          <Box paddingY={3} />
        </Box>
      ))}
    </Box>
  );
};
