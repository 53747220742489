import { useCallback, useMemo, useState } from "react";
import { BrowseDocumentLabelsFilter, organizationLinkTemplates } from "../../link-templates";

import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import { stableStringify } from "@prodoctivity/shared";
import type { HttpGetDocumentsWithListsRequest } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { ProfileDocumentListOption } from "../../components/DocumentBookmarkTags/hook";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { useAllDocumentTypes } from "../../components/hooks";

const browseDocumentsPageLengthOptions: Array<
  HttpGetDocumentsWithListsRequest["queryParameters"]["rowsPerPage"]
> = ["15", "30", "100"];

type Error = {
  show: boolean;
  message: string;
};

export function useDocumentLabelsList(filter: BrowseDocumentLabelsFilter | undefined) {
  if (!filter) {
    filter = {
      documentTypeIdList: [],
      documentLists: [],
      includeFavorites: false,
      dateStart: undefined,
      dateEnd: undefined,
      sortField: undefined,
      sortDirection: "DESC",
      pageNumber: 0,
      rowsPerPage: "15",
    };
  }
  const organizationNavigate = useOrganizationNavigate();
  const {
    getDocumentsWithLists,
    getMyUserProfileDocumentLabels,
    updateMyUserProfileDocumentLabels,
    deleteMyUserProfileDocumentLabel,
  } = useServices();
  const { resources, moment } = useAppTranslation();

  const [isError, setIsError] = useState<Error>({ show: false, message: "" });

  const handleFilterChange = useCallback(
    (filterType: keyof typeof filter, value: { value?: string | number }) => {
      organizationNavigate(
        organizationLinkTemplates.browseDocumentsWithLabels({
          ...filter,
          [filterType]: value.value,
        })
      );
    },
    [organizationNavigate, filter]
  );

  //#region documentTypes

  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<ComboBoxItemType[]>([]);

  const onSelectDocumentType = useCallback(
    ({
      item,
    }: {
      event: React.SyntheticEvent<HTMLInputElement, Event>;
      item: ComboBoxItemType;
    }) => {
      const isAlreadySelected = selectedDocumentTypes.some(
        (selectedItem) => selectedItem.value === item.value
      );

      if (!isAlreadySelected) {
        const newSelectedDocumentTypes = [...selectedDocumentTypes, item];
        setSelectedDocumentTypes(newSelectedDocumentTypes);
        setDocumentTypeComboBoxInputValue("");
        const documentTypeIds = newSelectedDocumentTypes.map((selectedItem) => selectedItem.value);

        organizationNavigate(
          organizationLinkTemplates.browseDocumentsWithLabels({
            ...filter,
            documentTypeIdList: documentTypeIds,
          })
        );
      }
    },
    [organizationNavigate, selectedDocumentTypes, filter]
  );

  const {
    allDocumentTypes: documentTypeData,
    isLoading,
    isRefetching,
  } = useAllDocumentTypes({
    onSuccess(data) {
      const filteredDocumentTypes = data.documentTypes.filter((dt) =>
        filter.documentTypeIdList?.includes(dt.documentTypeId)
      );
      setSelectedDocumentTypes(
        filteredDocumentTypes.map((dt) => ({
          label: dt.name,
          value: dt.documentTypeId,
        }))
      );
    },
  });

  const documentTypeIsLoading = isLoading || isRefetching;

  const [documentTypeComboBoxInputValue, setDocumentTypeComboBoxInputValue] = useState("");
  const handleSetDocumentTypeComboBoxInputValue = useCallback(
    (value: string) => {
      setDocumentTypeComboBoxInputValue(value);
    },
    [setDocumentTypeComboBoxInputValue]
  );

  const documentTypeHandleClick = useCallback(
    (index: number) => {
      const updatedSelectedDocumentTypes = selectedDocumentTypes.filter((_, i) => i !== index);
      setSelectedDocumentTypes(updatedSelectedDocumentTypes);

      const documentTypeIds = updatedSelectedDocumentTypes.map(
        (selectedItem) => selectedItem.value
      );

      organizationNavigate(
        organizationLinkTemplates.browseDocumentsWithLabels({
          ...filter,
          documentTypeIdList: documentTypeIds,
        })
      );

      handleSetDocumentTypeComboBoxInputValue("");
    },
    [organizationNavigate, selectedDocumentTypes, filter, handleSetDocumentTypeComboBoxInputValue]
  );

  //#endregion

  //#region documentLabels

  const [selectedLists, setSelectedLists] = useState<ProfileDocumentListOption[]>([]);

  const getFilteredDocuments = useCallback(async () => {
    const dateEnd = filter.dateEnd ? moment(filter.dateEnd).endOf("day").valueOf() : undefined;
    const dateStart = filter.dateStart
      ? moment(filter.dateStart).startOf("day").valueOf()
      : undefined;

    try {
      setSelectedLists([
        ...(filter.includeFavorites
          ? [{ label: resources.favorites, value: "favorites", isFavorites: true }]
          : []),
        ...(filter.documentLists?.map((documentList) => ({
          label: documentList,
          value: documentList,
          isFavorites: false,
        })) || []),
      ]);
      return await getDocumentsWithLists(
        filter.documentTypeIdList && filter.documentTypeIdList.length
          ? filter.documentTypeIdList
          : undefined,
        filter.documentLists && filter.documentLists.length ? filter.documentLists : undefined,
        filter.includeFavorites,
        dateStart,
        dateEnd,
        filter.pageNumber,
        filter.rowsPerPage,
        filter.sortField,
        filter.sortDirection
      );
    } catch (error) {
      console.log(error);
    }
  }, [
    filter.dateStart,
    filter.dateEnd,
    filter.documentTypeIdList,
    filter.pageNumber,
    filter.rowsPerPage,
    filter.sortDirection,
    filter.sortField,
    filter.documentLists,
    getDocumentsWithLists,
    moment,
    filter.includeFavorites,
    resources.favorites,
  ]);

  const updateProfileDocumentLabels = useCallback(
    async ({
      oldDocumentLabel,
      newDocumentLabel,
    }: {
      oldDocumentLabel: string | undefined;
      newDocumentLabel: string;
    }) => {
      return await updateMyUserProfileDocumentLabels(oldDocumentLabel, newDocumentLabel);
    },
    [updateMyUserProfileDocumentLabels]
  );

  const { mutate: mutateUpdateProfileLabels } = useMutation(updateProfileDocumentLabels, {
    onError(error: { response: { data: { errors: Array<{ message: string }> } } }) {
      setIsError({ show: true, message: error.response.data.errors[0].message });
    },
    onSuccess() {
      refetch();
      setIsError({ show: false, message: "" });
    },
  });

  const handleUpdateProfileLabels = useCallback(
    (oldDocumentLabel: string | undefined, newDocumentLabel: string) => {
      mutateUpdateProfileLabels({
        oldDocumentLabel: oldDocumentLabel,
        newDocumentLabel: newDocumentLabel,
      });
    },
    [mutateUpdateProfileLabels]
  );

  const deleteMyLabels = useCallback(
    async (oldDocumentLabel: string) => {
      return await deleteMyUserProfileDocumentLabel(oldDocumentLabel);
    },
    [deleteMyUserProfileDocumentLabel]
  );

  const { mutate: mutateDeleteMyDocumentLists } = useMutation(deleteMyLabels, {
    onError(error: { response: { data: { errors: Array<{ message: string }> } } }) {
      setIsError({ show: true, message: error.response.data.errors[0].message });
    },
    onSuccess() {
      refetch();
      setIsError({ show: false, message: "" });
    },
  });

  const handleDeleteLabel = useCallback(
    (documentList: string) => {
      mutateDeleteMyDocumentLists(documentList);
    },
    [mutateDeleteMyDocumentLists]
  );

  const fetchProfileDocumentLabels = useCallback(async () => {
    const profileDocumentLabelsResponse = await getMyUserProfileDocumentLabels();
    return profileDocumentLabelsResponse;
  }, [getMyUserProfileDocumentLabels]);

  const {
    data: profileDocumentLists,
    isLoading: isLoadingProfileDocumentLabels,
    refetch: refetchProfileDocumentLabels,
  } = useOrganizationQuery(`my-profile-document-labels`, fetchProfileDocumentLabels, {
    staleTime: 0,
    cacheTime: 60 * 1000,
    refetchOnMount: true,
  });

  const profileDocumentListsArray: ProfileDocumentListOption[] = useMemo(
    () =>
      profileDocumentLists?.documentLists.map((label) => ({
        label: label.isFavorites ? resources.favorites : label.name,
        value: label.name,
        isFavorites: label.isFavorites,
      })) || [],
    [profileDocumentLists, resources]
  );

  const handleSelectedLabels = useCallback(
    (item: ProfileDocumentListOption) => {
      setSelectedLists((prevState) => {
        const alreadySelected = prevState.some((checkbox) => checkbox.value === item.value);
        const documentLists = alreadySelected
          ? prevState.filter((checkbox) => checkbox.value !== item.value)
          : [...prevState, item];

        organizationNavigate(
          organizationLinkTemplates.browseDocumentsWithLabels({
            ...filter,
            documentLists: documentLists
              .filter((list) => !list.isFavorites)
              .map((list) => {
                return list.value;
              }),
            includeFavorites: documentLists.some((list) => list.isFavorites),
          })
        );

        return documentLists;
      });
    },
    [organizationNavigate, setSelectedLists, filter]
  );

  const handleSelectSingle = useCallback(
    (item: ProfileDocumentListOption) => {
      setSelectedLists(() => {
        const documentLists = [item];

        organizationNavigate(
          organizationLinkTemplates.browseDocumentsWithLabels({
            ...filter,
            documentLists: documentLists
              .filter((list) => !list.isFavorites)
              .map((list) => {
                return list.value;
              }),
            includeFavorites: documentLists.some((list) => list.isFavorites),
          })
        );

        return documentLists;
      });
    },
    [organizationNavigate, setSelectedLists, filter]
  );

  //#endregion

  const setRowsPerPage = useCallback(
    (rpp: HttpGetDocumentsWithListsRequest["queryParameters"]["rowsPerPage"]) => {
      organizationNavigate(
        organizationLinkTemplates.browseDocumentsWithLabels({ ...filter, rowsPerPage: rpp })
      );
    },
    [organizationNavigate, filter]
  );

  const clearAll = useCallback(() => {
    setSelectedDocumentTypes([]);
    handleSetDocumentTypeComboBoxInputValue("");
    organizationNavigate(
      organizationLinkTemplates.browseDocumentsWithLabels({
        pageNumber: 0,
        rowsPerPage: "15",
        dateStart: undefined,
        dateEnd: undefined,
        documentTypeIdList: undefined,
        sortField: undefined,
        sortDirection: undefined,
        documentLists: undefined,
        includeFavorites: undefined,
      })
    );
  }, [organizationNavigate, handleSetDocumentTypeComboBoxInputValue]);

  const changeSort = useCallback(
    (
      field: "documentType" | "name" | "updatedAt" | undefined,
      direction: "ASC" | "DESC" | undefined
    ) => {
      organizationNavigate(
        organizationLinkTemplates.browseDocumentsWithLabels({
          ...filter,
          sortField: field,
          sortDirection: field === undefined ? undefined : direction,
        })
      );
    },
    [filter, organizationNavigate]
  );

  const handleMoveTo = useCallback(
    (documentId: string) => {
      organizationNavigate(`/documents/${documentId}`);
    },
    [organizationNavigate]
  );

  const stringifiedFilterValues = stableStringify(filter);

  const {
    data: documentsData,
    isLoading: documentsIsLoading,
    refetch: refetchDocuments,
    remove,
  } = useOrganizationQuery(
    `documents-with-labels/${stringifiedFilterValues}`,
    getFilteredDocuments,
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
    }
  );

  const refetch = useCallback(() => {
    remove();
    refetchDocuments();
    refetchProfileDocumentLabels();
  }, [refetchDocuments, remove, refetchProfileDocumentLabels]);

  //#region pagination

  const isNextButtonDisabled =
    !documentsData || documentsData.requestedPageLength !== documentsData.pageLength;
  const isPreviousButtonDisabled = !documentsData || documentsData.pageNumber < 1;
  const totalRowCount = documentsData ? documentsData.totalRowCount : undefined;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    organizationNavigate(
      organizationLinkTemplates.browseDocumentsWithLabels({
        ...filter,
        pageNumber: filter.pageNumber - 1,
      })
    );
  }, [organizationNavigate, filter, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    organizationNavigate(
      organizationLinkTemplates.browseDocumentsWithLabels({
        ...filter,
        pageNumber: filter.pageNumber + 1,
      })
    );
  }, [organizationNavigate, filter, isNextButtonDisabled]);

  //#endregion

  const [displayDocumentsAsCards, setDisplayDocumentsAsCards] = useState(
    localStorage.getItem("cards") === "true"
  );

  const showOnCards = useCallback(() => {
    const newCardsState = !displayDocumentsAsCards;
    localStorage.setItem("cards", String(newCardsState));
    setDisplayDocumentsAsCards(newCardsState);
  }, [displayDocumentsAsCards]);

  const documentTypeList = useMemo(() => {
    const documentTypes = documentTypeData?.documentTypes || [];
    const filteredDocumentTypes = documentTypes.filter(
      (type) =>
        !selectedDocumentTypes.some((item) => item.label === type.name) &&
        type.name.toLowerCase().includes(documentTypeComboBoxInputValue.toLowerCase())
    );
    return filteredDocumentTypes.map((type) => ({
      label: type.name,
      value: type.documentTypeId,
    }));
  }, [documentTypeComboBoxInputValue, selectedDocumentTypes, documentTypeData?.documentTypes]);

  const handleComboBoxClear = useCallback(() => {
    handleSetDocumentTypeComboBoxInputValue("");
  }, [handleSetDocumentTypeComboBoxInputValue]);

  return {
    selectedLabels: selectedLists,
    profileDocumentLabelsList: profileDocumentListsArray,
    isLoadingProfileDocumentLabels,
    documentTypes: documentTypeData?.documentTypes || [],
    documentTypeIsLoading,
    documentsData: documentsData?.documents || [],
    documentsIsLoading,
    onSelectDocumentType,
    setSelectedDocumentTypes,
    selectedDocumentTypes,
    currentPage: filter.pageNumber,
    previousPage,
    nextPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    rowsPerPage: filter.rowsPerPage,
    pageLengthOptions: browseDocumentsPageLengthOptions,
    totalRowCount,
    documentTypeHandleClick,
    documentTypeComboBoxInputValue,
    handleSetDocumentTypeComboBoxInputValue,
    clearAll,
    handleMoveTo,
    handleSelectedLabels,
    handleSelectSingle,
    handleFilterChange,
    setRowsPerPage,
    changeSort,
    refetch,
    handleUpdateProfileLabels,
    handleDeleteLabel,
    isError,
    displayDocumentsAsCards,
    showOnCards,
    profileDocumentLabels: profileDocumentLists,
    documentTypeList,
    handleComboBoxClear,
  };
}
