import { FunctionComponent } from "react";
import { calculateColor, ColorConfig } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const GenerateSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 32,
  height = 27,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13002_135308)">
        <path
          d="M32.0001 1.14639V25.8707C32.0001 26.4867 31.4843 27 30.848 27H10.2312C9.59495 27 9.0791 26.4867 9.0791 25.8707V22.6882H10.042L11.6756 23.7833L12.3806 24.2452L13.0855 23.8004L14.9598 22.654V23.5951H27.1167V21.6787H16.5418L16.6965 21.576L16.5761 20.7376L16.301 18.7871L16.6277 18.2053L16.7481 17.9829H27.1167V16.0837H19.4649V15.5532L19.3961 13.8593L19.3445 12.3878H27.1167V10.4715H17.1608L16.6965 10.2833L16.6621 10.2319L15.9743 9.13688L16.1291 7.1692V6.79278H27.1167V4.87643H14.9598V5.68061L13.9453 5.13308L12.4493 4.3289L11.6927 3.93536L11.0393 4.46578L9.47459 5.68061H9.0791V1.14639C9.0791 0.513308 9.59495 0 10.2312 0H30.848C31.4843 0 32.0001 0.513308 32.0001 1.14639Z"
          fill={color ? calculateColor(color) : "#575756"}
        />
        <path
          d="M18.158 15.5878C18.1064 13.9794 18.1064 13.8083 18.0548 12.1999L15.8195 11.3102C15.3896 10.6258 15.0801 10.1296 14.6674 9.44517L14.8393 7.06684C13.4121 6.31399 13.2574 6.22844 11.8302 5.47559L9.93874 6.94707C9.62923 6.94707 9.35411 6.96418 9.07899 6.98129C8.64911 6.98129 8.23643 6.9984 7.73778 7.01551L5.76034 5.6638C4.38474 6.51931 4.24718 6.60486 2.87157 7.46038L3.19828 9.82159C2.81999 10.5402 2.54487 11.0364 2.16658 11.7551L0 12.7988C0.0515852 14.4071 0.0687802 14.5782 0.120365 16.1866L2.33853 17.0763C2.7684 17.7608 3.07792 18.257 3.50779 18.9414L3.31865 21.3197C4.76303 22.0725 4.90059 22.1581 6.32778 22.9109L8.21924 21.4395C8.52875 21.4395 8.82106 21.4224 9.07899 21.4052C9.50887 21.4052 9.92155 21.3881 10.4202 21.371L12.4148 22.7227C13.7904 21.8672 13.928 21.7817 15.3036 20.9262L14.9597 18.5649C15.338 17.8463 15.6131 17.3501 16.0086 16.6315L18.158 15.5878ZM11.6411 15.9813C11.022 16.8368 10.0591 17.2988 9.07899 17.2988C8.45997 17.2988 7.84095 17.1106 7.2907 16.7341C5.88071 15.7589 5.53681 13.8083 6.53412 12.4052C7.13595 11.5497 8.09887 11.0878 9.07899 11.0878C9.69801 11.0878 10.3342 11.276 10.8845 11.6524C12.2773 12.6277 12.6212 14.5782 11.6411 15.9813Z"
          fill={color ? calculateColor(color) : "#575756"}
        />
      </g>
      <defs>
        <clipPath id="clip0_13002_135308">
          <rect width="32" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
