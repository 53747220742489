import {
  Box,
  Button,
  RadioGroup,
  Text,
  TextArea,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import { FC, useCallback, useState } from "react";

import type { DeleteOrganizationAccountType } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { NotificationMessage } from "../../components/NotificationMessage";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";

type props = {
  showDeleteOrg: boolean;
  setShowDeleteOrganization: (n: boolean) => void;
  organizationId: string;
};
const DeleteOrganizationForm: FC<props> = ({ setShowDeleteOrganization, organizationId }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { deleteOrganizationPetition, isDeletionProcessInitiated } = useServices();
  const [toastMessage, setToastMessage] = useState("");
  const [reason, setReason] = useState("");
  const [inputOrganizationId, setInputOrganizationId] = useState("");
  const [deleteType, setDeleteType] = useState<DeleteOrganizationAccountType>(
    "until-subscription-expires"
  );
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  const executeDeleteOrganizationPetition = useCallback(async () => {
    if (reason) {
      await deleteOrganizationPetition(reason, deleteType, inputOrganizationId);
      return true;
    }
    return false;
  }, [reason, deleteOrganizationPetition, deleteType, inputOrganizationId]);

  const checkDeletionProcess = useCallback(async () => {
    return await isDeletionProcessInitiated();
  }, [isDeletionProcessInitiated]);

  const { data: isInitiatedData } = useOrganizationQuery(
    "check-deletion-process",
    checkDeletionProcess
  );
  const mutation = useMutation(executeDeleteOrganizationPetition, {
    onSuccess() {
      setToastMessage(resources.deleted);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4500);
      navigate(organizationLinkTemplates.logout());
    },

    onError(error: {
      name: string;
      message: string;
      response: {
        data: {
          errors: Array<{ errorCode: string; message: string }>;
        };
      };
    }) {
      const errorType = error?.response.data.errors[0].message;
      switch (errorType) {
        case "organization-not-found":
          setToastMessage(resources.leaveOrganizationPage.checkOrganizationId);
          break;
        default:
          setToastMessage(resources.anErrorHasOccurred);
      }
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4500);
    },
  });
  const handleSubmit = useCallback(async () => {
    mutation.mutate();
  }, [mutation]);

  const isButtonDisable =
    !reason ||
    mutation.isLoading ||
    isInitiatedData?.isStarted ||
    inputOrganizationId !== organizationId;
  return (
    <Box padding={4} color={colors.white}>
      <Text align="center" weight="bold" size="400" color={colors.error}>
        {resources.deleteOrganization}
      </Text>
      <Text align="center" weight="bold" size={"200"} color={colors.black600}>
        {resources.toDeleteOrganization.replace("{orgId}", organizationId)}
      </Text>
      <Box padding={2} justifyContent="center">
        <TextField
          value={inputOrganizationId}
          maxLength={{
            characterCount: 50,
            errorAccessibilityLabel: resources.maximumCharactersAllowed,
          }}
          type={"text"}
          onChange={(e) => setInputOrganizationId(e.value)}
          id="organizartionNameField"
          placeholder={resources.myOrganization}
        />
      </Box>

      <Text align="center" weight="bold" size={"200"} color={colors.black600}>
        {resources.whyDoYouWantToDeleteYourOrganization}
      </Text>
      <Box padding={2} justifyContent="center">
        <TextArea
          value={reason}
          maxLength={{
            characterCount: 1000,
            errorAccessibilityLabel: resources.maximumCharactersAllowed,
          }}
          onChange={(e) => setReason(e.value)}
          id="delete_organization_reason"
        />
        <RadioGroup
          id={"erase-type"}
          legend={resources.formOfDisposal}
          direction="row"
          legendDisplay="hidden"
        >
          <RadioGroup.RadioButton
            id="until-subscription-expires"
            label={resources.untilSubscriptionExpires}
            name={resources.untilSubscriptionExpires}
            onChange={() => {
              setDeleteType("until-subscription-expires");
            }}
            value={deleteType}
            checked={deleteType === "until-subscription-expires"}
          />
          <RadioGroup.RadioButton
            id="immediately"
            label={resources.immediately}
            name={resources.immediately}
            onChange={() => {
              setDeleteType("immediately");
            }}
            value={deleteType}
            checked={deleteType === "immediately"}
          />
        </RadioGroup>
      </Box>

      <Box display="flex" direction="row" flex="grow" gap={4} justifyContent="end">
        <Button
          color={"transparent"}
          type="button"
          text={resources.cancel}
          size="lg"
          accessibilityLabel={resources.cancel}
          onClick={() => setShowDeleteOrganization(false)}
        />

        <Button
          type="button"
          disabled={isButtonDisable}
          size="lg"
          text={resources.deleteOrganization}
          accessibilityLabel={resources.deleteOrganization}
          color={"red"}
          onClick={handleSubmit}
        />
      </Box>
      {isInitiatedData?.isStarted && (
        <Text align="center" color={colors.error}>
          {resources.alreadyExists}
        </Text>
      )}
      <Box>
        {showToast ? (
          <NotificationMessage
            type={"error"}
            message={toastMessage}
            position="bottom-left"
            handleDismiss={() => setShowToast(false)}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export { DeleteOrganizationForm };
