import "./css/ModalStyles.css";

import {
  DatePickerProps,
  DesignBreakpointType,
  FormControllerProvider,
  Icon,
  ProDoctivityColorBundle,
} from "@prodoctivity/design-system";
import type {
  TemplateContextDefinition,
  TemplateWizardDefinition,
} from "@prodoctivity/shared/src/index-types";
import { Button, Col, Dropdown, Menu, Modal, Row, message } from "antd";
import { buildDataLinksConnectors, parseConnectors } from "../AssignDataLinks/utils";
import type {
  ConnectorDataLink,
  DataElementValue,
  DataLink,
  FormConfiguration,
  FormConnector,
  FormDefinition,
  FormErrors,
  FormLayout,
  FormValues,
  GroupLayouts,
  GroupValues,
  ProDoctivityFormLayout,
  WebServiceConnector,
} from "../_lib/types";
import {
  buildLayout,
  chosenQuestionUpdater,
  createTopicLayoutItem,
  updateFormLayoutWithFormDefinition,
} from "./utils";

import type momentType from "moment";
import React from "react";
import { AddTopic } from "../AddTopic";
import { AssignConnectorsDragDropContext } from "../AssignConnectors";
import { AssignDataLinksDragDropContext } from "../AssignDataLinks";
import { getNewLayoutFromDependencies } from "../Dependencies/DependenciesLayoutUtils";
import { ErrorBoundary } from "../ErrorBoundary";
import { FormPage } from "../ProDoctivityForm/FormPage";
import { noop } from "../_lib/utils";

//import classNames from 'classnames'

type Props = {
  formDefinition: FormDefinition;
  connectors: WebServiceConnector[];
  formConnectors: FormConnector[];
  contextDefinition: TemplateContextDefinition;
  wizardDefinition: TemplateWizardDefinition;
  dataLinks: DataLink[];
  connectorDataLinks: ConnectorDataLink[];
  formLayout: FormLayout;
  selectedSectionKey: string | undefined;
  setSelectedSectionKey(k: string): void;
  documentHandleList: number;
  showPins: boolean;
  initialPinnedElements: string[];
  componentBreakpoint: DesignBreakpointType;
  fetchTopics: () => Promise<Array<string>>;
  onChangeFormConfiguration: (formConfiguration: FormConfiguration) => void;
  onChangeFormValue?: (params: { name: string; value: DataElementValue }) => void;
  onUpdateFormValues: (
    formValues: FormValues,
    formErrors: FormErrors,
    pinnedElements: string[]
  ) => void;
  onUpdateGroupValues?: (groupValues: GroupValues) => void;
  onFireConnector: (formConnector: FormConnector, formValues: FormValues) => Promise<FormValues>;
  onNoDataFound?: (message: string) => void;
  i18n: (key: string) => string;
  onDependenciesUpdate?: (dependencies: any[]) => void;
  moment: typeof momentType;
  onCancel: () => void;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
  colors: ProDoctivityColorBundle;
};

type State = {
  layout: ProDoctivityFormLayout;
  fullLayout: ProDoctivityFormLayout;
  dependenciesLayouts: FormLayout;
  prevLayout: ProDoctivityFormLayout;
  groupLayouts: GroupLayouts;
  fullGroupLayouts: GroupLayouts;
  layoutTopics: Set<string>;
  values?: FormValues;
  formValues?: FormValues;
  groupValues?: GroupValues;
  isTestingDesigner: boolean;
  editingTopic: boolean | string;
  connectorsModalVisible: boolean;
  dependenciesModalVisible: boolean;
  formConnectors: FormConnector[];
  dependenciesGroupLayouts: GroupLayouts;
  useDependenciesLayout: boolean;
  forceInitialLayout: boolean;
  connectorDataLinks: ConnectorDataLink[];
  dataLinkModalVisible: boolean;
};

function validateOutputConnectors(
  dataLinksMapped: FormConnector[],
  formConnectors: FormConnector[]
) {
  if (
    dataLinksMapped &&
    dataLinksMapped.length > 0 &&
    formConnectors &&
    formConnectors.length > 0
  ) {
    return dataLinksMapped.some((dataLink) => {
      if (dataLink.output && dataLink.output.length > 0) {
        return dataLink.output.some((output) => {
          return formConnectors.some((connector) => {
            return connector.output.some((co) => {
              return co.name === output.name;
            });
          });
        });
      }
      return false;
    });
  }
}

function validateInputConnectors(
  dataLinksMapped: FormConnector[],
  formConnectors: FormConnector[]
) {
  if (
    dataLinksMapped &&
    dataLinksMapped.length > 0 &&
    formConnectors &&
    formConnectors.length > 0
  ) {
    return dataLinksMapped.some((dataLink) => {
      if (dataLink.input && dataLink.input.length > 0) {
        return dataLink.input.some((input) => {
          return formConnectors.some((connector) => {
            return connector.input.some((ci) => {
              return ci.name === input.name;
            });
          });
        });
      }
      return false;
    });
  }
}

export class ProDoctivityFormDesigner extends React.Component<Props, State> {
  static displayName = "ProDoctivityFormDesigner";

  static defaultProps = {
    formLayout: {},
    onChangeFormConfiguration: noop,
    onChangeFormValue: noop,
    onUpdateFormValues: noop,
    onUpdateGroupValues: noop,
    initialPinnedElements: [],
    dataLinks: [],
    connectorDataLinks: [],
  };

  constructor(props: Props) {
    super(props);

    let _formLayout = props.formLayout || {};
    const builtLayout = buildLayout(props.formDefinition);
    const { layout } = builtLayout;
    let { layoutTopics } = builtLayout;

    if (_formLayout.layout) {
      const { formLayout, layoutTopicNames } = updateFormLayoutWithFormDefinition(
        _formLayout,
        props.formDefinition
      );
      _formLayout = formLayout;
      layoutTopics = layoutTopicNames;
    }

    this.state = {
      layout: _formLayout.layout || layout,
      prevLayout: _formLayout.layout || layout,
      layoutTopics: layoutTopics,
      isTestingDesigner: false,
      editingTopic: false,
      groupLayouts: _formLayout.groupLayouts || {},
      connectorsModalVisible: false,
      dataLinkModalVisible: false,
      formConnectors: [...this.props.formConnectors],
      connectorDataLinks: buildDataLinksConnectors(
        this.props.dataLinks,
        this.props.connectorDataLinks,
        this.props.documentHandleList,
        this.props.i18n("NoDataFound")
      ),
      dependenciesModalVisible: false,
      dependenciesLayouts: _formLayout,
      dependenciesGroupLayouts: _formLayout.groupLayouts || {},
      useDependenciesLayout: false,
      fullLayout: _formLayout.layout || layout,
      fullGroupLayouts: _formLayout.groupLayouts || {},
      forceInitialLayout: false,
    };
  }

  onUpdateFormValues = (values: FormValues, errors: FormErrors, pinned: string[]) => {
    if (this.props.onUpdateFormValues) {
      this.props.onUpdateFormValues(values, errors, pinned);
    }
  };

  onUpdateGroupValues = (groupValues: GroupValues) => {
    this.setState({ groupValues });
  };

  notifyConfigurationChange = () => {
    if (!this.state.isTestingDesigner) {
      const layout = this.state.layout.map((l) => {
        return { ...l, static: false };
      });

      const formConfiguration = {
        formLayout: {
          layout: layout,
          groupLayouts: this.state.groupLayouts,
        },
        formConnectors: this.state.formConnectors,
        dataLinksConnectors: this.state.connectorDataLinks,
      };

      this.props.onChangeFormConfiguration(formConfiguration);
    }
  };

  savePreviousLayout(layout: ProDoctivityFormLayout) {
    this.setState({ prevLayout: layout });
  }

  toggleDesigner = () => {
    this.setState(({ isTestingDesigner }) => ({
      isTestingDesigner: !isTestingDesigner,
    }));

    if (this.state.isTestingDesigner) {
      this.setState(({ prevLayout }) => ({
        layout: prevLayout,
      }));
    }
  };

  addTopic = (topicName: string) => {
    this.setState(
      ({ layout, layoutTopics }) => ({
        layout: [
          createTopicLayoutItem({
            parentFullPath: undefined,
            name: topicName,
            label: topicName,
            y: 0,
          }),
          ...layout,
        ],
        layoutTopics: layoutTopics.add(topicName), //  new Set([...layoutTopics, topicName]),
      }),
      this.notifyConfigurationChange
    );
  };

  onLayoutChange = (newLayout: ProDoctivityFormLayout) => {
    // The new layout that ReactGridLayout sends to the
    // `onLayoutChange` event has stripped any additional
    // properties that we've added to the layout item.
    // So we need to reassign them again on each change.
    this.setState(
      ({ layout }) => ({
        layout: newLayout.map((l, i) => {
          return {
            ...layout[i],
            // flowlint-next-line inexact-spread:off
            ...l,
          };
        }),
      }),
      !this.state.isTestingDesigner ? this.notifyConfigurationChange : noop
    );

    if (!this.state.isTestingDesigner) {
      this.savePreviousLayout(this.state.layout);
    }
  };

  onChooseAlternativeQuestion = ({
    name,
    alternativeQuestion,
  }: {
    name: string;
    alternativeQuestion: string;
  }) => {
    this.setState(
      chosenQuestionUpdater({ name, alternativeQuestion }),
      this.notifyConfigurationChange
    );
  };

  enableTopicEditing = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const { topicId } = event.currentTarget.dataset;

    // Set the current Topic's layout item as static (not resizable nor draggable)
    // because otherwise the item will capture click events and remove focus from the input
    this.setState((prevState) => {
      const newLayout = prevState.layout.map((l) => {
        return { ...l, static: true };
      });

      return {
        ...prevState,
        editingTopic: topicId || false,
        layout: newLayout,
      };
    }, this.notifyConfigurationChange);
  };

  onSubmitEditTopic = ({
    prevTopicName,
    nextTopicName,
  }: {
    prevTopicName: string;
    nextTopicName: string;
  }) => {
    this.setState((prevState) => {
      const newLayout = prevState.layout.map((l) => {
        return l.type === "topic" && l.name === prevTopicName
          ? {
              ...l,
              static: false,
              i: "topic__" + nextTopicName,
              name: nextTopicName,
            }
          : { ...l, static: false };
      });

      if (prevTopicName === nextTopicName) {
        return {
          ...prevState,
          editingTopic: false,
          layout: newLayout,
        };
      }

      const newLayoutTopics = new Set(prevState.layoutTopics);
      newLayoutTopics.delete(prevTopicName);
      newLayoutTopics.add(nextTopicName);

      return {
        ...prevState,
        editingTopic: false,
        layout: newLayout,
        layoutTopics: newLayoutTopics,
      };
    }, this.notifyConfigurationChange);
  };

  removeTopic = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const { topicId, topicName } = event.currentTarget.dataset;

    this.setState(({ layout, layoutTopics }) => {
      const newLayout = layout.filter((l) => !(l.type === "topic" && l.i === topicId)); /* reject(
        layout,
        (l) => l.type === 'topic' && l.i === topicId
      )*/

      const newLayoutTopics: Set<string> = new Set(layoutTopics);
      newLayoutTopics.delete(topicName || "");

      return { layout: newLayout, layoutTopics: newLayoutTopics };
    }, this.notifyConfigurationChange);
  };

  onGroupLayoutChange = (groupName: string, layout: ProDoctivityFormLayout) => {
    this.setState(
      ({ groupLayouts: prevGroupLayouts }) => ({
        groupLayouts: {
          ...prevGroupLayouts,
          [groupName]: layout,
        },
      }),
      this.notifyConfigurationChange
    );
  };

  updateLayout = (
    callback: (param: { layout: ProDoctivityFormLayout }) => {
      layout: ProDoctivityFormLayout;
    }
  ) => {
    this.setState(callback, this.notifyConfigurationChange);
  };

  openAssignedConnectors = () =>
    this.setState((prevState) => ({ ...prevState, connectorsModalVisible: true }));

  closeAssignedConnectors = () => {
    if (this.state.formConnectors) {
      const result = this.state.formConnectors.filter((connector) => {
        if (connector.output.length <= 0) {
          return connector;
        }
        return false;
      });
      if (result.length) {
        message.error(this.props.i18n("FormConnectorOutputTooFew"));
        return;
      }
      const connectorDataLink = parseConnectors(
        this.props.dataLinks,
        this.state.connectorDataLinks
      );
      const inputInvalid = validateInputConnectors(connectorDataLink, this.state.formConnectors);
      const outputInvalid = validateOutputConnectors(connectorDataLink, this.state.formConnectors);
      if (inputInvalid) {
        message.error(this.props.i18n("FormConnectorInputConflict"));
      }
      if (outputInvalid) {
        message.error(this.props.i18n("FormConnectorOutputConflict"));
      }
      if (inputInvalid || outputInvalid) {
        return;
      }
    }
    this.setState(
      () => ({
        connectorsModalVisible: false,
      }),
      this.notifyConfigurationChange
    );
  };
  openDataLinksModal = () =>
    this.setState(() => ({
      dataLinkModalVisible: true,
    }));
  closeDataLinksModal = () =>
    this.setState(
      ({ dataLinkModalVisible: _dataLinkModalVisible }) => ({
        dataLinkModalVisible: false,
      }),
      this.notifyConfigurationChange
    );
  onChangeFormConnectors = (formConnectors: FormConnector[]) => {
    this.setState({ formConnectors });
  };

  onChangeDataLinksConnectors = (connectorDataLinks: ConnectorDataLink[]) => {
    const connectorDataLink = parseConnectors(this.props.dataLinks, connectorDataLinks);

    const inputInvalid = validateInputConnectors(connectorDataLink, this.state.formConnectors);
    const outputInvalid = validateOutputConnectors(connectorDataLink, this.state.formConnectors);
    if (inputInvalid) {
      message.error(this.props.i18n("FormConnectorInputConflict"));
    }
    if (outputInvalid) {
      message.error(this.props.i18n("FormConnectorOutputConflict"));
    }
    if (inputInvalid || outputInvalid) {
      connectorDataLinks = connectorDataLinks.map((dataLink) => {
        dataLink.keywordHandleList = [];
        dataLink.fields = [];
        return dataLink;
      });
    }
    this.setState({ connectorDataLinks }, this.notifyConfigurationChange);
  };

  openDependenciesDesigner = () =>
    this.setState(({ dependenciesModalVisible: _dependenciesModalVisible }) => ({
      dependenciesModalVisible: true,
    }));

  // Closes modal, and rollbacks changes to dependencies
  onCancelDependencyChanges = () => {
    this.setState({
      dependenciesModalVisible: false,
    });
  };

  // save dependencies,
  // set rollback point (saved dependencies)
  // and closes modal
  onDependencySave = () => {
    const dependenciesLayouts = getNewLayoutFromDependencies(
      this.state.fullLayout,
      [],
      this.state.formValues,
      this.state.groupLayouts,
      this.state.groupValues
    );

    this.setState(
      {
        dependenciesModalVisible: false,
        dependenciesLayouts: dependenciesLayouts,
      },
      () => {
        if (this.props.onDependenciesUpdate) {
          this.props.onDependenciesUpdate([]);
        }
        this.notifyConfigurationChange();
      }
    );
  };

  onDependencyUpdate = noop;

  addNewDependency = noop;

  genDependenciesModalHeader = () => {
    return (
      <div>
        <div>
          <Row /*type="flex"*/ align="middle">
            <Col span={18} className="ant-modal-title">
              {this.props.i18n("Dependencies")}
            </Col>
            <Col style={{ position: "absolute", right: "20px" }}>
              <Button type="default" onClick={this.addNewDependency}>
                {this.props.i18n("AddDependency")}
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Row /*type="flex"*/ align="middle">
            <Col span={18}>
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: "13px",
                  color: "#696e6b",
                }}
              >
                {this.props.i18n("DependenciesDescription")}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  menu = (
    <Menu>
      <Menu.Item>
        <a onClick={this.openAssignedConnectors}>Web services</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={this.openDataLinksModal}>ODBC/OLEDB/SOAP</a>
      </Menu.Item>
    </Menu>
  );

  menuItems = [
    {
      key: "1",
      label: <a onClick={this.openAssignedConnectors}>Web services</a>,
    },
    {
      key: "2",
      label: <a onClick={this.openDataLinksModal}>ODBC/OLEDB/SOAP</a>,
    },
  ];

  render() {
    const {
      connectors,
      dataLinks,
      formDefinition,
      contextDefinition,
      onChangeFormValue,
      onFireConnector,
      onNoDataFound,
      showPins,
      initialPinnedElements,
      i18n,
      fetchTopics,
      wizardDefinition,
      moment,
      onCancel,
      resources,
    } = this.props;
    const {
      layoutTopics,
      fullLayout,
      fullGroupLayouts,
      isTestingDesigner,
      editingTopic,
      useDependenciesLayout,
      dependenciesLayouts,
      connectorDataLinks,
      formConnectors,
    } = this.state;

    const layout =
      useDependenciesLayout && isTestingDesigner ? dependenciesLayouts.layout : this.state.layout;

    const groupLayouts =
      useDependenciesLayout && isTestingDesigner
        ? dependenciesLayouts.groupLayouts
        : this.state.groupLayouts;

    const addTopicBtn = (
      <AddTopic
        key="AddTopic"
        addTopic={this.addTopic}
        layoutTopics={Array.from(layoutTopics)}
        fetchTopics={fetchTopics}
        i18n={i18n}
      />
    );

    const dependenciesBtn = (
      <Button
        key="DependencyButton"
        onClick={this.openDependenciesDesigner}
        style={{ marginLeft: 12 }}
      >
        {i18n("Dependencies")}
      </Button>
    );

    const connectorsBtn = (
      <Dropdown
        key="ConnectorButton"
        menu={{
          items: this.menuItems,
        }} /*overlay={this.menu}*/
        placement="bottom"
      >
        <Button style={{ marginLeft: "auto" }}>
          {i18n("dataLinks")}
          <Icon icon="arrow-down" accessibilityLabel={i18n("dataLinks")} />
        </Button>
      </Dropdown>
    );

    const designButtons = !isTestingDesigner ? [addTopicBtn, dependenciesBtn, connectorsBtn] : null;

    return (
      <ErrorBoundary>
        <FormControllerProvider
          contextDefinition={contextDefinition}
          wizardDefinition={wizardDefinition}
          dataLinkMappings={[]}
          executeDataLink={undefined}
          moment={moment}
        >
          <div className="ProDoctivityFormDesigner" data-is-testing={isTestingDesigner}>
            <div style={{ display: "flex" }}>
              <Button onClick={this.toggleDesigner} style={{ marginRight: 12 }}>
                {isTestingDesigner ? i18n("Design") : i18n("Test")}
              </Button>
              {designButtons}
            </div>

            <FormPage
              formDefinition={formDefinition}
              connectors={connectors}
              formConnectors={formConnectors}
              //dependencies={savedDependencies}
              dataLinkMappings={[]}
              executeDataLink={() => new Promise<any>((resolve) => resolve({ context: {} }))}
              dataLinks={dataLinks}
              connectorDataLinks={connectorDataLinks}
              layout={layout}
              fullLayout={fullLayout}
              fullGroupLayouts={fullGroupLayouts}
              groupLayouts={groupLayouts}
              isDesignMode={!isTestingDesigner}
              inDesignMode
              readOnly={false}
              layoutTopics={layoutTopics}
              editingTopic={editingTopic}
              onLayoutChange={this.onLayoutChange}
              onChooseAlternativeQuestion={this.onChooseAlternativeQuestion}
              enableTopicEditing={this.enableTopicEditing}
              onSubmitEditTopic={this.onSubmitEditTopic}
              removeTopic={this.removeTopic}
              onChangeFormValue={onChangeFormValue}
              onUpdateFormValues={this.onUpdateFormValues}
              onUpdateGroupValues={this.onUpdateGroupValues}
              onGroupLayoutChange={this.onGroupLayoutChange}
              updateLayout={this.updateLayout}
              onFireConnector={onFireConnector}
              onNoDataFound={onNoDataFound}
              showPins={showPins}
              initialPinnedElements={initialPinnedElements}
              i18n={i18n}
              paginate={true}
              initialFormValues={{}}
              initialGroupValues={{}}
              summaryMode={false}
              initialPagesHeight={0}
              sequenceBehaviour={"AllowEdit"}
              componentBreakpoint={this.props.componentBreakpoint}
              moment={moment}
              cancel={onCancel}
              resources={resources}
            />
            {/* Init Data Link Modal */}
            <Modal
              closable={false}
              title={i18n("AssignedDataLinks")}
              open={this.state.dataLinkModalVisible}
              onOk={this.closeDataLinksModal}
              style={{ minWidth: 560 }}
              width={"80%"}
              footer={[
                <Button type="primary" onClick={this.closeDataLinksModal}>
                  {i18n("Ok")}
                </Button>,
              ]}
            >
              {/* $FlowIgnore */}
              <AssignDataLinksDragDropContext
                dataLinks={dataLinks}
                documentHandleList={this.props.documentHandleList}
                connectorDataLinks={connectorDataLinks}
                onChange={this.onChangeDataLinksConnectors}
                i18n={i18n}
              />
            </Modal>
            {/* End Data Link Modal */}
            {/* Init Connector Modal */}
            <Modal
              closable={false}
              title={i18n("AssignedConnectors")}
              open={this.state.connectorsModalVisible}
              onOk={this.closeAssignedConnectors}
              style={{ minWidth: 560 }}
              width={"80%"}
              footer={[
                <Button type="primary" onClick={this.closeAssignedConnectors}>
                  {i18n("Ok")}
                </Button>,
              ]}
            >
              {/* $FlowIgnore */}
              <AssignConnectorsDragDropContext
                connectors={connectors}
                formConnectors={formConnectors}
                onChange={this.onChangeFormConnectors}
                i18n={i18n}
              />
            </Modal>
            {/* End Connector Modal */}
            {/* Dependencies Modal */}
            <Modal
              closable={false}
              title={this.genDependenciesModalHeader()}
              open={this.state.dependenciesModalVisible}
              onOk={this.onCancelDependencyChanges}
              style={{ minWidth: 560 }}
              width={"80%"}
              /*className={classNames({
              'ant-modal-body': true,
              'ant-collapse-content>.ant-collapse-content-box': true,
            })}*/
              footer={[
                <Button type="default" onClick={this.onCancelDependencyChanges}>
                  {i18n("cancel")}
                </Button>,
                <Button key="submit" type="primary" onClick={this.onDependencySave}>
                  {i18n("SaveDependencies")}
                </Button>,
              ]}
            >
              {/* $FlowIgnore */}
              {/* <Dependencies
                formDefinition={formDefinition}
                formDependencies={wizardDefinition.dependencies}
                onDependencyUpdate={this.onDependencyUpdate}
                i18n={i18n}
                addNewDependency={this.addNewDependency}
                moment={moment}
                colors={this.props.colors}
                contextDefinition={contextDefinition}
                wizardDefinition={wizardDefinition}
                resources={{remove:"remove"}}
              /> */}
            </Modal>
            {/* End Dependencies Modal */}
          </div>
        </FormControllerProvider>
      </ErrorBoundary>
    );
  }
}
