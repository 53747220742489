import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { Layer, Popover } from "../layout";

import { checkPasswordStrength } from "@prodoctivity/shared";
import { Box } from "../Box";
import { useColors } from "../ColorSchemeProvider";
import { Divider } from "../Divider";
import { PasswordMetric } from "../PasswordMetric/PasswordMetric";
import { Text } from "../Text";
import { TextField, TextFieldProps } from "../TextField";

type Props = {
  id: string;
  value: string;
  onChange(v: string): void;
  labelDisplay: TextFieldProps["labelDisplay"];
  showPassword?: boolean;
  disabled?: boolean;
  resources: {
    newPassword: string;
    passwordChange: {
      eightCharacters: string;
      lowercaseUppercase: string;
      numberOrSymbol: string;
      tryCreatingPassword: string;
      typeComplexPassword: string;
    };
  };
};

export const PasswordSetterField: FunctionComponent<Props> = ({
  id,
  value,
  onChange,
  labelDisplay,
  disabled,
  showPassword,
  resources,
}) => {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const popoverRef = useRef<HTMLInputElement>(null);

  const passwordStrength = useMemo(() => {
    const result = checkPasswordStrength(value);
    return result;
  }, [value]);

  const setPassword: TextFieldProps["onChange"] = useCallback(
    (e) => {
      onChange(e.value);
    },
    [onChange]
  );
  return (
    <>
      <Box>
        <TextField
          ref={popoverRef}
          id={id}
          disabled={disabled}
          type={showPassword ? "text" : "password"}
          placeholder={resources.newPassword}
          onChange={setPassword}
          value={value}
          onFocus={() => setOpen(true)}
          label={resources.newPassword}
          labelDisplay={labelDisplay}
          errorMessage={
            !value || passwordStrength?.success ? "" : resources.passwordChange.typeComplexPassword
          }
        />
      </Box>

      {value !== "" && <PasswordMetric score={passwordStrength?.score ?? 0} />}

      {open && !passwordStrength?.success && (
        <Layer>
          <Popover
            anchor={popoverRef.current}
            idealDirection="right"
            onDismiss={() => setOpen(false)}
            positionRelativeToAnchor={false}
            size="md"
            color="white"
            role="dialog"
          >
            <Box color={colors.white}>
              <Box padding={3}>
                <Box>
                  <Text inline={true} align="start" size="300">
                    {resources.passwordChange.typeComplexPassword}
                  </Text>
                </Box>
                <Divider direction="horizontal" />
                <Box margin={2} />
                <Text align="start" size="200">
                  {resources.passwordChange.tryCreatingPassword}
                </Text>
                <Text color={colors.neutral900}>
                  <ul>
                    <li>
                      <Text size="200">{resources.passwordChange.eightCharacters}</Text>
                    </li>
                    <li>
                      <Text size="200">{resources.passwordChange.lowercaseUppercase}</Text>
                    </li>
                    <li>
                      <Text size="200">{resources.passwordChange.numberOrSymbol}</Text>
                    </li>
                  </ul>
                </Text>
              </Box>
            </Box>
          </Popover>
        </Layer>
      )}
    </>
  );
};
