import {
  Box,
  BoxWithRef,
  ComboBox,
  Layer,
  Popover,
  StringTemplatePartListDisplay,
  TapArea,
  Text,
  TrashSvgIcon,
  useColors,
} from "@prodoctivity/design-system";
import type { DocumentCollectionConfigFolder, DocumentTypeResume } from "@prodoctivity/types";
import { Dispatch, FunctionComponent, MutableRefObject, SetStateAction, useRef } from "react";
import {
  AddDocumentIconSvg,
  AddFolderIconSvg,
  LineIndentationIconSvg,
  TLineIndentationIconSvg,
} from "../../../../../svg/DocumentCollection";
import { TreeItem, useDocumentTreeItem, useFolderTreeItem } from "../../hooks";

import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import { IndentationArrowSvgIcon } from "../../../../../svg/IndentationArrowSvgIcon";
import { noop } from "../../../../../utils";
import { RenderDocumentCollectionIcon } from "../../../../DocumentCollectionInstance/RenderDocumentCollectionIcon";
import { nameConfigContextToString } from "../utils";

type Props = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  currIndex: number;
  parentLength: number;
  folder: DocumentCollectionConfigFolder<string>;
  folderPath: Array<string>;
  documentTypes: DocumentTypeResume[];
  dropPosition?: {
    isBefore: boolean;
  } & TreeItem;
  selectedItem?: TreeItem;
  rootTreeItem: TreeItem;
  docTypesAvailableToAdd: ComboBoxItemType[];
  addDocumentTypeToFolder: (documentTypeId: string) => void;
  onConfigTreeItem: (item: TreeItem) => void;
  onNewFolder: (path: string[]) => void;
  removeSelectedItem: () => void;
  handleDragStart: (item: TreeItem) => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>, item: TreeItem) => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  setShowMainSaveButton: Dispatch<SetStateAction<boolean>>;
};

export const FolderTreeItem = ({
  resources,
  currIndex,
  parentLength,
  folder,
  folderPath,
  documentTypes,
  dropPosition,
  selectedItem,
  rootTreeItem,
  docTypesAvailableToAdd,
  addDocumentTypeToFolder,
  onConfigTreeItem,
  onNewFolder,
  removeSelectedItem,
  handleDragStart,
  handleDragOver,
  handleDrop,
  setShowMainSaveButton,
}: Props) => {
  const { colors } = useColors();
  const { moment } = useAppTranslation();
  const anchorRefItems = useRef<HTMLDivElement | null>(null);
  const {
    showPopover,
    setShowPopover,
    open,
    setOpen,
    currFolderPath,
    boxColor,
    isSelected,
    strNameConfig,
    paddingLeft,
    dragItem,
    dragTo,
  } = useFolderTreeItem({
    folder,
    folderPath,
    dropPosition,
    selectedItem,
  });
  return (
    <Box flex="grow">
      <Box flex="grow" display="flex">
        <Box
          flex="grow"
          display="flex"
          justifyContent="between"
          alignItems="center"
          paddingX={3}
          color={boxColor}
          id={`${folderPath.join("/")}/${strNameConfig}`}
          draggable
          onDragStart={() => handleDragStart(dragItem)}
          onDragOver={(e) => handleDragOver(e, dragTo)}
          onDrop={handleDrop}
          dangerouslySetInlineStyle={{
            __style: {
              paddingLeft: `${paddingLeft}px`,
            },
          }}
        >
          <TapArea
            onTap={() => {
              setOpen((prev) => (open && !isSelected ? true : !prev));
              onConfigTreeItem(dragItem);
              setShowMainSaveButton(false);
            }}
          >
            <Box display="flex" alignItems="center">
              {currIndex >= 0 && currIndex < parentLength - 1 && (
                <Box display="flex" flex="shrink" paddingLeft={1}>
                  {" "}
                  <TLineIndentationIconSvg />
                </Box>
              )}
              {currIndex === parentLength - 1 && (
                <Box display="flex" flex="shrink" paddingLeft={1}>
                  <LineIndentationIconSvg />
                </Box>
              )}
              <Box>
                <IndentationArrowSvgIcon direction={open ? "bottomLeft" : "left"} />
              </Box>
              <RenderDocumentCollectionIcon
                color={folder.color || undefined}
                iconKey={folder.iconKey || "folder"}
                height={20}
                width={20}
              />
              <Box display="flex" flex="grow" paddingLeft={1}>
                <StringTemplatePartListDisplay
                  parts={folder.nameConfig}
                  contextDefinition={undefined}
                />
              </Box>
            </Box>
          </TapArea>
        </Box>
        {isSelected && (
          <Box display="flex" alignItems="center" paddingX={4} color={boxColor}>
            <Box display="flex" gap={2} alignItems="center">
              <BoxWithRef ref={anchorRefItems as MutableRefObject<HTMLDivElement | null>}>
                <AddDocumentIconSvg
                  height={26}
                  width={26}
                  color={colors.subtle}
                  onClick={() => setShowPopover(true)}
                />
              </BoxWithRef>
              {showPopover && isSelected && (
                <Layer>
                  <Popover
                    anchor={anchorRefItems.current}
                    onDismiss={noop}
                    idealDirection="down"
                    positionRelativeToAnchor={false}
                    shouldFocus={true}
                    size={"flexible"}
                    color="white"
                    role="menu"
                  >
                    <Box
                      width={400}
                      onBlur={() => setShowPopover(false)}
                      padding={2}
                      color={colors.white}
                    >
                      <ComboBox
                        id="add-document-type"
                        placeholder={resources.copyDocument}
                        label=""
                        noResultText={resources.noResultsFound}
                        onSelect={(e) => {
                          addDocumentTypeToFolder(e.item.value);
                        }}
                        size="lg"
                        options={docTypesAvailableToAdd}
                      />
                    </Box>
                  </Popover>
                </Layer>
              )}
              <Box marginStart={1} display="flex" alignItems="center">
                <AddFolderIconSvg
                  height={28}
                  width={28}
                  onClick={() => onNewFolder(currFolderPath)}
                  color={colors.subtle}
                />
              </Box>
              <TrashSvgIcon
                height={25}
                width={25}
                onClick={() => removeSelectedItem()}
                color={colors.subtle}
              />
            </Box>
          </Box>
        )}
      </Box>
      {open &&
        folder.folders.map((f, i) => {
          return (
            <FolderTreeItem
              key={`${currFolderPath.toString()},${nameConfigContextToString(
                moment,
                f.nameConfig
              )}`}
              currIndex={i}
              parentLength={folder.folders.length + folder.documentTypes.length}
              folder={f}
              folderPath={currFolderPath}
              documentTypes={documentTypes}
              dropPosition={dropPosition}
              selectedItem={selectedItem}
              rootTreeItem={rootTreeItem}
              resources={resources}
              docTypesAvailableToAdd={docTypesAvailableToAdd}
              addDocumentTypeToFolder={addDocumentTypeToFolder}
              onConfigTreeItem={onConfigTreeItem}
              onNewFolder={onNewFolder}
              removeSelectedItem={removeSelectedItem}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              setShowMainSaveButton={setShowMainSaveButton}
            />
          );
        })}
      {open &&
        folder.documentTypes.map((d, i) => {
          return (
            <DocumentTreeItem
              key={`${currFolderPath.toString()},${d.documentTypeId}`}
              currIndex={i}
              parentLength={folder.documentTypes.length}
              documentType={d}
              folderPath={currFolderPath}
              documentTypes={documentTypes}
              dropPosition={dropPosition}
              selectedItem={selectedItem}
              rootTreeItem={rootTreeItem}
              parentDragItem={dragTo}
              removeSelectedItem={removeSelectedItem}
              onConfigTreeItem={onConfigTreeItem}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              setShowMainSaveButton={setShowMainSaveButton}
            />
          );
        })}
    </Box>
  );
};
//);

type DocProps = {
  currIndex: number;
  parentLength: number;
  documentType: DocumentCollectionConfigFolder<string>["documentTypes"][0];
  folderPath: Array<string>;
  documentTypes: DocumentTypeResume[];
  dropPosition?: {
    isBefore: boolean;
  } & TreeItem;
  selectedItem?: TreeItem;
  rootTreeItem: TreeItem;
  parentDragItem?: TreeItem;
  removeSelectedItem: () => void;
  onConfigTreeItem: (item: TreeItem) => void;
  handleDragStart: (item: TreeItem) => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>, item: TreeItem) => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  setShowMainSaveButton: Dispatch<SetStateAction<boolean>>;
};

export const DocumentTreeItem: FunctionComponent<DocProps> = ({
  currIndex,
  parentLength,
  folderPath,
  documentType,
  documentTypes,
  dropPosition,
  selectedItem,
  rootTreeItem,
  parentDragItem,
  removeSelectedItem,
  onConfigTreeItem,
  handleDragStart,
  handleDragOver,
  handleDrop,
  setShowMainSaveButton,
}) => {
  const { documentTypeInfo, existMultipleInstances, paddingLeft, dragItem, boxColor, isSelected } =
    useDocumentTreeItem({
      documentType,
      documentTypes,
      folderPath,
      dropPosition,
      selectedItem,
      rootTreeItem,
    });
  const { colors } = useColors();

  return (
    <Box display="flex" flex="grow">
      <TapArea
        onTap={() => {
          onConfigTreeItem(dragItem);
          setShowMainSaveButton(false);
        }}
      >
        <Box
          flex="grow"
          id={`${folderPath.join("/")}/${documentTypeInfo?.documentTypeId}`}
          draggable={true}
          onDragStart={() => handleDragStart(dragItem)}
          onDragOver={(e) => {
            if (parentDragItem) handleDragOver(e, parentDragItem);
          }}
          onDrop={handleDrop}
          paddingX={3}
          color={boxColor}
          dangerouslySetInlineStyle={{
            __style: {
              paddingLeft: `${paddingLeft}px`,
            },
          }}
        >
          <Box display="flex" gap={2} alignItems="center">
            {currIndex >= 0 && currIndex < parentLength - 1 && (
              <Box display="flex" flex="shrink" paddingLeft={1}>
                <TLineIndentationIconSvg />
              </Box>
            )}
            {currIndex === parentLength - 1 && (
              <Box display="flex" flex="shrink" paddingLeft={1}>
                <LineIndentationIconSvg />
              </Box>
            )}

            <Box paddingLeft={4} display="flex" gap={2} alignItems="center">
              <RenderDocumentCollectionIcon
                color={documentType.color || undefined}
                iconKey={documentType.iconKey || "document"}
                height={20}
                width={20}
              />
              <Text weight={"normal"} color={colors.black600}>
                {documentTypeInfo?.name}
              </Text>
            </Box>
          </Box>
        </Box>
      </TapArea>
      {isSelected && existMultipleInstances && (
        <Box display="flex" alignItems="center" paddingX={3} color={boxColor}>
          <TrashSvgIcon
            height={25}
            width={25}
            onClick={() => removeSelectedItem()}
            color={colors.subtle}
          />
        </Box>
      )}
    </Box>
  );
};
