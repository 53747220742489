import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../utils";
import { useColors } from "../components";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const VerticalEllipseMenuSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral600;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <circle cx="20" cy="11" r="3" fill={color ? calculateColor(color) : "#CACBCD"} />
      <circle cx="20" cy="20" r="3" fill={color ? calculateColor(color) : "#CACBCD"} />
      <circle cx="20" cy="29" r="3" fill={color ? calculateColor(color) : "#CACBCD"} />
    </svg>
  );
};
