import { replaceAll } from "@prodoctivity/shared";

export function getResources(t: (key: any) => string) {
  const root = {
    _dateFormatShort: t("_dateFormatShort"),
    _dateTimeFormat: t("_dateTimeFormat"),
    _dateTimeFormatShort: t("_dateTimeFormatShort"),
    _locale: t("_locale"),
    _timeFormat: t("_timeFormat"),
    aboutToExpire: t("aboutToExpire"),
    accept: t("accept"),
    account: t("account"),
    accountActivation: t("accountActivation"),
    accountPage: {
      basicInfo: t("accountPage.basicInfo"),
      contactInfo: t("accountPage.contactInfo"),
      editProfile: t("accountPage.editProfile"),
      peopleAndSharing: t("accountPage.peopleAndSharing"),
      personalizePicture: t("accountPage.personalizePicture"),
      successApplyingChange: t("accountPage.successApplyingChange"),
    },
    actions: t("actions"),
    activate: t("activate"),
    activateYourAccount: t("activateYourAccount"),
    active: t("active"),
    activities: {
      activities: t("activities.activities"),
      activity: t("activities.activity"),
      closeDetails: t("activities.closeDetails"),
      dateRange: t("activities.dateRange"),
      documentCollectionNamed: t("activities.documentCollectionNamed"),
      documentCount: t("activities.documentCount"),
      entryTypes: {
        adminChangeUserPassword: t("activities.entryTypes.adminChangeUserPassword"),
        documentCollectionAssigned: t("activities.entryTypes.documentCollectionAssigned"),
        documentDeleted: t("activities.entryTypes.documentDeleted"),
        documentGenerated: t("activities.entryTypes.documentGenerated"),
        documentPermanentlyDeleted: t("activities.entryTypes.documentPermanentlyDeleted"),
        documentRestored: t("activities.entryTypes.documentRestored"),
        documentSaved: t("activities.entryTypes.documentSaved"),
        documentShare: t("activities.entryTypes.documentShare"),
        organizationCreated: t("activities.entryTypes.organizationCreated"),
        templateUpdated: t("activities.entryTypes.templateUpdated"),
        userCreated: t("activities.entryTypes.userCreated"),
        userPasswordUpdated: t("activities.entryTypes.userPasswordUpdated"),
        viewADocument: t("activities.entryTypes.viewADocument"),
      },
      filterTypes: {
        approval: t("activities.filterTypes.approval"),
        configuration: t("activities.filterTypes.configuration"),
        schedule: t("activities.filterTypes.schedule"),
        update: t("activities.filterTypes.update"),
      },
      generation: t("activities.generation"),
      hideDetail: t("activities.hideDetail"),
      noActivitiesFound: t("activities.noActivitiesFound"),
      notFound: t("activities.notFound"),
      organizationCreatedBy: t("activities.organizationCreatedBy"),
      templateUpdated: t("activities.templateUpdated"),
      theUser: t("activities.theUser"),
      typeOfActivity: t("activities.typeOfActivity"),
      typeOfSearch: t("activities.typeOfSearch"),
      updatedBy: t("activities.updatedBy"),
      userWasCreated: t("activities.userWasCreated"),
      viewAllEntries: t("activities.viewAllEntries"),
      viewDetail: t("activities.viewDetail"),
      viewEntry: t("activities.viewEntry"),
      wasCreated: t("activities.wasCreated"),
    },
    add: t("add"),
    addBookmark: t("addBookmark"),
    addComment: t("addComment"),
    addDocumentToFavorites: t("addDocumentToFavorites"),
    addEntry: t("addEntry"),
    addNew: t("addNew"),
    addNewElement: t("addNewElement"),
    addNewLine: t("addNewLine"),
    addNewList: t("addNewList"),
    addNewUser: t("addNewUser"),
    address: t("address"),
    addToFavorites: t("addToFavorites"),
    addToList: t("addToList"),
    adminChangePassword: {
      adminChangePass: t("adminChangePassword.adminChangePass"),
      confirmPassword: t("adminChangePassword.confirmPassword"),
      invalidPassword: t("adminChangePassword.invalidPassword"),
      passwordNotMatch: t("adminChangePassword.passwordNotMatch"),
      setNewPassword: t("adminChangePassword.setNewPassword"),
    },
    administration: t("administration"),
    adminMode: t("adminMode"),
    advancedAuthor: t("advancedAuthor"),
    advancedMode: t("advancedMode"),
    all: t("all"),
    allowedFormats: t("allowedFormats"),
    allRightsReserved: t("allRightsReserved"),
    allTasks: t("allTasks"),
    alreadyApproved: t("alreadyApproved"),
    alreadyExists: t("alreadyExists"),
    alreadyInRecipients: t("alreadyInRecipients"),
    anErrorHasOccurred: t("anErrorHasOccurred"),
    annotations: t("annotations"),
    apiPage: {
      apiUser: t("apiPage.apiUser"),
      createAccount: t("apiPage.createAccount"),
      createUploadTemplate: t("apiPage.createUploadTemplate"),
      generateDocuments: t("apiPage.generateDocuments"),
      generateUpTo: t("apiPage.generateUpTo"),
      includes: t("apiPage.includes"),
      moreInformations: t("apiPage.moreInformations"),
      process: t("apiPage.process"),
      startNow: t("apiPage.startNow"),
      tryDays: t("apiPage.tryDays"),
      tryOurApi: t("apiPage.tryOurApi"),
      uploadesTemplate: t("apiPage.uploadesTemplate"),
      wantToTest: t("apiPage.wantToTest"),
    },
    applied: t("applied"),
    apply: t("apply"),
    approvalTasks: t("approvalTasks"),
    approvalType: {
      addApprovalType: t("approvalType.addApprovalType"),
      anyUserCanApprove: t("approvalType.anyUserCanApprove"),
      approvals: t("approvalType.approvals"),
      approvalTypes: t("approvalType.approvalTypes"),
      configureDocumentTypeApprove: t("approvalType.configureDocumentTypeApprove"),
      deleteApprovalType: t("approvalType.deleteApprovalType"),
      dismissPreviousApprovals: t("approvalType.dismissPreviousApprovals"),
      dismissPreviousDescription: t("approvalType.dismissPreviousDescription"),
      executeTaskInOrder: t("approvalType.executeTaskInOrder"),
      executeTaskOrderDescription: t("approvalType.executeTaskOrderDescription"),
      manageApprovalTypes: t("approvalType.manageApprovalTypes"),
      newApprovalType: t("approvalType.newApprovalType"),
      onlyUserWithPermission: t("approvalType.onlyUserWithPermission"),
    },
    approve: t("approve"),
    approved: t("approved"),
    areYouSureApproveDocument: t("areYouSureApproveDocument"),
    assign: t("assign"),
    assignedRole: t("assignedRole"),
    assignedTo: t("assignedTo"),
    assignee: t("assignee"),
    assignPrefix: t("assignPrefix"),
    assignSuffix: t("assignSuffix"),
    assignTo: t("assignTo"),
    autocomplete: t("autocomplete"),
    autoExtract: t("autoExtract"),
    autoExtractDesc: t("autoExtractDesc"),
    availableOptions: t("availableOptions"),
    avatar: t("avatar"),
    backToInstructionList: t("backToInstructionList"),
    billing: t("billing"),
    body: t("body"),
    browseDocument: {
      author: t("browseDocument.author"),
      browseDocuments: t("browseDocument.browseDocuments"),
      copyDocumentId: t("browseDocument.copyDocumentId"),
      sendDocumentViaEmail: t("browseDocument.sendDocumentViaEmail"),
      trash: t("browseDocument.trash"),
    },
    bundle: {
      bundle: t("bundle.bundle"),
      bundleApplied: t("bundle.bundleApplied"),
      bundleBeingApplied: t("bundle.bundleBeingApplied"),
      bundles: t("bundle.bundles"),
      changeLog: t("bundle.changeLog"),
      install: t("bundle.install"),
      latestVersion: t("bundle.latestVersion"),
      listOfChangesToImplement: t("bundle.listOfChangesToImplement"),
      updateBundle: t("bundle.updateBundle"),
    },
    businessFunction: t("businessFunction"),
    byNameOrByTypeOfDocument: t("byNameOrByTypeOfDocument"),
    cancel: t("cancel"),
    cannotDeactivateYourself: t("cannotDeactivateYourself"),
    canNowJoinTheOrganization: t("canNowJoinTheOrganization"),
    category: t("category"),
    categoryConfig: t("categoryConfig"),
    certificate: t("certificate"),
    changePassword: t("changePassword"),
    changes: t("changes"),
    checklist: t("checklist"),
    choose: t("choose"),
    chooseATemplate: t("chooseATemplate"),
    chooseFile: t("chooseFile"),
    chooseOne: t("chooseOne"),
    chooseOneItem: t("chooseOneItem"),
    chooseTwoItems: t("chooseTwoItems"),
    classDataElement: t("classDataElement"),
    clear: t("clear"),
    clearAll: t("clearAll"),
    clearFilter: t("clearFilter"),
    clickToAddNewPart: t("clickToAddNewPart"),
    clickToEdit: t("clickToEdit"),
    clickUploadImage: t("clickUploadImage"),
    clone: t("clone"),
    close: t("close"),
    collapse: t("collapse"),
    collection: t("collection"),
    collectionName: t("collectionName"),
    collections: t("collections"),
    collectionSummaries: t("collectionSummaries"),
    color: t("color"),
    combinedTemplates: {
      combinedTemplates: t("combinedTemplates.combinedTemplates"),
      combinedTemplatesManagement: t("combinedTemplates.combinedTemplatesManagement"),
      combinedTemplateStep2SubTitle: t("combinedTemplates.combinedTemplateStep2SubTitle"),
      editCombined: t("combinedTemplates.editCombined"),
      selectedTemplates: t("combinedTemplates.selectedTemplates"),
    },
    comment: t("comment"),
    comments: t("comments"),
    company: t("company"),
    completed: t("completed"),
    conditionBlock: t("conditionBlock"),
    conditionBlockName: t("conditionBlockName"),
    conditions: t("conditions"),
    configurationWorkflow: t("configurationWorkflow"),
    configuratorPage: {
      buildtemplatesMSWord: t("configuratorPage.buildtemplatesMSWord"),
      downloadAdvancedAuthor: t("configuratorPage.downloadAdvancedAuthor"),
      featuresofOurSoftware: t("configuratorPage.featuresofOurSoftware"),
      managingDocumentsEasy: t("configuratorPage.managingDocumentsEasy"),
    },
    configureInvalidExpression: t("configureInvalidExpression"),
    configureTemplateProperties: t("configureTemplateProperties"),
    confirmation: t("confirmation"),
    contains: t("contains"),
    contentLibraryDescription: t("contentLibraryDescription"),
    context: t("context"),
    contextFieldAlreadyExists: t("contextFieldAlreadyExists"),
    contextValidationErrors: {
      "cannot-send-calculated-values": t("contextValidationErrors.cannot-send-calculated-values"),
      "error-evaluating-dependency": t("contextValidationErrors.error-evaluating-dependency"),
      "field-is-required": t("contextValidationErrors.field-is-required"),
      "field-max-instances-not-met": t("contextValidationErrors.field-max-instances-not-met"),
      "field-max-value-not-met": t("contextValidationErrors.field-max-value-not-met"),
      "field-min-instances-not-met": t("contextValidationErrors.field-min-instances-not-met"),
      "field-min-value-not-met": t("contextValidationErrors.field-min-value-not-met"),
      "invalid-input-mask-value": t("contextValidationErrors.invalid-input-mask-value"),
      "logical-cannot-be-list": t("contextValidationErrors.logical-cannot-be-list"),
      "record-is-not-a-list": t("contextValidationErrors.record-is-not-a-list"),
      "record-max-instances-not-met": t("contextValidationErrors.record-max-instances-not-met"),
      "record-min-instances-not-met": t("contextValidationErrors.record-min-instances-not-met"),
      "string-value-exceeds-max-length": t(
        "contextValidationErrors.string-value-exceeds-max-length"
      ),
      "string-value-exceeds-max-line-count": t(
        "contextValidationErrors.string-value-exceeds-max-line-count"
      ),
      "value-list-not-present": t("contextValidationErrors.value-list-not-present"),
      "value-must-be-boolean-type": t("contextValidationErrors.value-must-be-boolean-type"),
      "value-must-be-date-type": t("contextValidationErrors.value-must-be-date-type"),
      "value-must-be-integer": t("contextValidationErrors.value-must-be-integer"),
      "value-must-be-number-type": t("contextValidationErrors.value-must-be-number-type"),
      "value-must-be-string-type": t("contextValidationErrors.value-must-be-string-type"),
      "value-must-be-time-type": t("contextValidationErrors.value-must-be-time-type"),
      "value-must-be-valid-data-uri": t("contextValidationErrors.value-must-be-valid-data-uri"),
      "value-must-be-valid-date-time": t("contextValidationErrors.value-must-be-valid-date-time"),
      "value-must-be-valid-time-time": t("contextValidationErrors.value-must-be-valid-time-time"),
      "value-out-of-value-list": t("contextValidationErrors.value-out-of-value-list"),
    },
    continue: t("continue"),
    controlSize: t("controlSize"),
    convertValues: t("convertValues"),
    copy: t("copy"),
    copyDocument: t("copyDocument"),
    copyFromDocument: t("copyFromDocument"),
    copyIdToClipboard: t("copyIdToClipboard"),
    copyOf: t("copyOf"),
    create: t("create"),
    createdAt: t("createdAt"),
    createdBy: t("createdBy"),
    createNewVariable: t("createNewVariable"),
    createPkcs12File: t("createPkcs12File"),
    createRule: t("createRule"),
    creationDate: t("creationDate"),
    currency: t("currency"),
    currencyFormat: t("currencyFormat"),
    custom: t("custom"),
    customMessage: t("customMessage"),
    dashboard: {
      addGadget: t("dashboard.addGadget"),
      clickHereTo: t("dashboard.clickHereTo"),
      defaultDashboardName: t("dashboard.defaultDashboardName"),
      editDashboard: t("dashboard.editDashboard"),
      insertRowAfter: t("dashboard.insertRowAfter"),
      insertRowBefore: t("dashboard.insertRowBefore"),
      listOfWidgets: t("dashboard.listOfWidgets"),
      moveToMainContent: t("dashboard.moveToMainContent"),
      moveToOtherColumn: t("dashboard.moveToOtherColumn"),
      moveToSidebar: t("dashboard.moveToSidebar"),
      noContentFound: t("dashboard.noContentFound"),
      openWidgetList: t("dashboard.openWidgetList"),
      quickActions: t("dashboard.quickActions"),
      quickActionsDesc: t("dashboard.quickActionsDesc"),
      widgetList: {
        assignmentListByDocumentCollectionDescription: t(
          "dashboard.widgetList.assignmentListByDocumentCollectionDescription"
        ),
        documentCollectionReport: t("dashboard.widgetList.documentCollectionReport"),
        documentCollectionReportDescription: t(
          "dashboard.widgetList.documentCollectionReportDescription"
        ),
        myTasksDescription: t("dashboard.widgetList.myTasksDescription"),
        recentActivityDescription: t("dashboard.widgetList.recentActivityDescription"),
        recentDocumentCollectionsDescription: t(
          "dashboard.widgetList.recentDocumentCollectionsDescription"
        ),
        recentDocumentsDescription: t("dashboard.widgetList.recentDocumentsDescription"),
        reportDocumentCollectionDescription: t(
          "dashboard.widgetList.reportDocumentCollectionDescription"
        ),
        reportOfDocumentCollection: t("dashboard.widgetList.reportOfDocumentCollection"),
        templatesDescription: t("dashboard.widgetList.templatesDescription"),
      },
      youAreEditing: t("dashboard.youAreEditing"),
    },
    dataDictionary: {
      addNewItem: t("dataDictionary.addNewItem"),
      allowsMultipleInstances: t("dataDictionary.allowsMultipleInstances"),
      altQuestion: t("dataDictionary.altQuestion"),
      altQuestionFull: t("dataDictionary.altQuestionFull"),
      assignedDataElements: t("dataDictionary.assignedDataElements"),
      availableDataElements: t("dataDictionary.availableDataElements"),
      characterLength: t("dataDictionary.characterLength"),
      childListMustContainItems: t("dataDictionary.childListMustContainItems"),
      childListNameCannotBeEmpty: t("dataDictionary.childListNameCannotBeEmpty"),
      chooseInputType: t("dataDictionary.chooseInputType"),
      copyDataElementId: t("dataDictionary.copyDataElementId"),
      dataElementExists: t("dataDictionary.dataElementExists"),
      dataElementFundamentalProperties: t("dataDictionary.dataElementFundamentalProperties"),
      dataElementName: t("dataDictionary.dataElementName"),
      dataElements: t("dataDictionary.dataElements"),
      dataLists: t("dataDictionary.dataLists"),
      defaultControl: t("dataDictionary.defaultControl"),
      defaultFormat: t("dataDictionary.defaultFormat"),
      defaultValue: t("dataDictionary.defaultValue"),
      dependentListName: t("dataDictionary.dependentListName"),
      dependentListsFor: t("dataDictionary.dependentListsFor"),
      dictionary: t("dataDictionary.dictionary"),
      dictionaryList: t("dataDictionary.dictionaryList"),
      exportList: t("dataDictionary.exportList"),
      finalizer: t("dataDictionary.finalizer"),
      identities: t("dataDictionary.identities"),
      importList: t("dataDictionary.importList"),
      inputMask: t("dataDictionary.inputMask"),
      invalidFile: t("dataDictionary.invalidFile"),
      keepAllChars: t("dataDictionary.keepAllChars"),
      limitReached: t("dataDictionary.limitReached"),
      listMustContainItems: t("dataDictionary.listMustContainItems"),
      listNameCannotBeEmpty: t("dataDictionary.listNameCannotBeEmpty"),
      listOf: t("dataDictionary.listOf"),
      listType: t("dataDictionary.listType"),
      listTypeLabels: {
        CurrencyType: t("dataDictionary.listTypeLabels.CurrencyType"),
        Gender: t("dataDictionary.listTypeLabels.Gender"),
        None: t("dataDictionary.listTypeLabels.None"),
      },
      listTypePlaceholder: t("dataDictionary.listTypePlaceholder"),
      manageHowDataIsShown: t("dataDictionary.manageHowDataIsShown"),
      maskValue: t("dataDictionary.maskValue"),
      maxLength: t("dataDictionary.maxLength"),
      maxLines: t("dataDictionary.maxLines"),
      noCommonFieldsFound: t("dataDictionary.noCommonFieldsFound"),
      noDefaultValue: t("dataDictionary.noDefaultValue"),
      noDependentLists: t("dataDictionary.noDependentLists"),
      noElements: t("dataDictionary.noElements"),
      noItems: t("dataDictionary.noItems"),
      noLists: t("dataDictionary.noLists"),
      noListSeparator: t("dataDictionary.noListSeparator"),
      noName: t("dataDictionary.noName"),
      noSampleValue: t("dataDictionary.noSampleValue"),
      noSeparators: t("dataDictionary.noSeparators"),
      notVisibleInDocument: t("dataDictionary.notVisibleInDocument"),
      numberOfOccurrences: t("dataDictionary.numberOfOccurrences"),
      orderType: t("dataDictionary.orderType"),
      penultimate: t("dataDictionary.penultimate"),
      referenceField: t("dataDictionary.referenceField"),
      referenceFieldTooltip: t("dataDictionary.referenceFieldTooltip"),
      sampleValue: t("dataDictionary.sampleValue"),
      savedSuccessfully: t("dataDictionary.savedSuccessfully"),
      searchElementsPlaceholder: t("dataDictionary.searchElementsPlaceholder"),
      searchListPlaceholder: t("dataDictionary.searchListPlaceholder"),
      searchUsersPlaceholder: t("dataDictionary.searchUsersPlaceholder"),
      separator: t("dataDictionary.separator"),
      separators: t("dataDictionary.separators"),
      unableToSave: t("dataDictionary.unableToSave"),
      unableToSaveElement: t("dataDictionary.unableToSaveElement"),
      unableToSaveElementErrorExceedChar: t("dataDictionary.unableToSaveElementErrorExceedChar"),
      unableToSaveElementNameError: t("dataDictionary.unableToSaveElementNameError"),
      unableToSaveElementTagError: t("dataDictionary.unableToSaveElementTagError"),
    },
    dataElementTag: t("dataElementTag"),
    dataLinkRequestTemplateParameters: {
      applied_tags: t("dataLinkRequestTemplateParameters.applied_tags"),
      attachment: t("dataLinkRequestTemplateParameters.attachment"),
      baseURL: t("dataLinkRequestTemplateParameters.baseURL"),
      headers: t("dataLinkRequestTemplateParameters.headers"),
      path: t("dataLinkRequestTemplateParameters.path"),
      payload: t("dataLinkRequestTemplateParameters.payload"),
      queryString: t("dataLinkRequestTemplateParameters.queryString"),
      responseType: t("dataLinkRequestTemplateParameters.responseType"),
      thread_id: t("dataLinkRequestTemplateParameters.thread_id"),
      thread_name: t("dataLinkRequestTemplateParameters.thread_name"),
      username: t("dataLinkRequestTemplateParameters.username"),
      verb: t("dataLinkRequestTemplateParameters.verb"),
      webhookUrl: t("dataLinkRequestTemplateParameters.webhookUrl"),
    },
    dataLinks: t("dataLinks"),
    dataType: t("dataType"),
    dataTypeValues: {
      alphanumeric: t("dataTypeValues.alphanumeric"),
      and: t("dataTypeValues.and"),
      ascending: t("dataTypeValues.ascending"),
      boolean: t("dataTypeValues.boolean"),
      comma: t("dataTypeValues.comma"),
      date: t("dataTypeValues.date"),
      datetime: t("dataTypeValues.datetime"),
      decimalnumber: t("dataTypeValues.decimalnumber"),
      descending: t("dataTypeValues.descending"),
      electronicsignature: t("dataTypeValues.electronicsignature"),
      image: t("dataTypeValues.image"),
      integernumber: t("dataTypeValues.integernumber"),
      list: t("dataTypeValues.list"),
      none: t("dataTypeValues.none"),
      or: t("dataTypeValues.or"),
      paragraph: t("dataTypeValues.paragraph"),
      period: t("dataTypeValues.period"),
      semicolon: t("dataTypeValues.semicolon"),
      time: t("dataTypeValues.time"),
      whitespace: t("dataTypeValues.whitespace"),
    },
    date: t("date"),
    dateFormat: t("dateFormat"),
    deactivateUser: t("deactivateUser"),
    decisionDetailsPrompt: t("decisionDetailsPrompt"),
    default: t("default"),
    deferred: {
      deferredDistributionList: t("deferred.deferredDistributionList"),
      identifier: t("deferred.identifier"),
      noDeferredDistributions: t("deferred.noDeferredDistributions"),
    },
    delete_: t("delete_"),
    deleteAccount: t("deleteAccount"),
    deleteAllIfNoItemUsed: t("deleteAllIfNoItemUsed"),
    deleted: t("deleted"),
    deleteDocumentGroup: t("deleteDocumentGroup"),
    deleteGraphicMark: t("deleteGraphicMark"),
    deleteLabel: t("deleteLabel"),
    deleteOnlyUnusedItems: t("deleteOnlyUnusedItems"),
    deleteOrganization: t("deleteOrganization"),
    deleteRole: t("deleteRole"),
    description: t("description"),
    deselect: t("deselect"),
    details: t("details"),
    differentThan: t("differentThan"),
    disable: t("disable"),
    distinguishedName: t("distinguishedName"),
    distribution: t("distribution"),
    distributionManagementPage: {
      AmazonSESDistributionLabel: t("distributionManagementPage.AmazonSESDistributionLabel"),
      basedOn: t("distributionManagementPage.basedOn"),
      basicHttpRequestDistributionLabel: t(
        "distributionManagementPage.basicHttpRequestDistributionLabel"
      ),
      builtIn: t("distributionManagementPage.builtIn"),
      cloneDistribution: t("distributionManagementPage.cloneDistribution"),
      cloneDistributionDescription: t("distributionManagementPage.cloneDistributionDescription"),
      cloneDistributionSuccess: t("distributionManagementPage.cloneDistributionSuccess"),
      cloneInstead: t("distributionManagementPage.cloneInstead"),
      convertToPDFDistributionLabel: t("distributionManagementPage.convertToPDFDistributionLabel"),
      currentlyDisabledDistribution: t("distributionManagementPage.currentlyDisabledDistribution"),
      deferredHttpRequestDistributionLabel: t(
        "distributionManagementPage.deferredHttpRequestDistributionLabel"
      ),
      deleteDistribution: t("distributionManagementPage.deleteDistribution"),
      deleteDistributionDescription: t("distributionManagementPage.deleteDistributionDescription"),
      deleteDistributionSuccess: t("distributionManagementPage.deleteDistributionSuccess"),
      digitalSignatureDistributionLabel: t(
        "distributionManagementPage.digitalSignatureDistributionLabel"
      ),
      disableDistribution: t("distributionManagementPage.disableDistribution"),
      disableDistributionDescription: t(
        "distributionManagementPage.disableDistributionDescription"
      ),
      disableDistributionSuccess: t("distributionManagementPage.disableDistributionSuccess"),
      discordWebHookDistributionLabel: t(
        "distributionManagementPage.discordWebHookDistributionLabel"
      ),
      editDistributionLabel: t("distributionManagementPage.editDistributionLabel"),
      enableDistribution: t("distributionManagementPage.enableDistribution"),
      myOwn: t("distributionManagementPage.myOwn"),
      noDistributionSelected: t("distributionManagementPage.noDistributionSelected"),
      permanentlyDelete: t("distributionManagementPage.permanentlyDelete"),
      permanentlyDeleteAfter: t("distributionManagementPage.permanentlyDeleteAfter"),
      permanentlyDeleteBefore: t("distributionManagementPage.permanentlyDeleteBefore"),
      remoteHttpRequestDistributionLabel: t(
        "distributionManagementPage.remoteHttpRequestDistributionLabel"
      ),
      step1Description: t("distributionManagementPage.step1Description"),
      step2Description: t("distributionManagementPage.step2Description"),
      step3Description: t("distributionManagementPage.step3Description"),
    },
    distributions: t("distributions"),
    document: t("document"),
    documentaryStructure: t("documentaryStructure"),
    documentCollection: {
      closeConfig: {
        closingCondition: t("documentCollection.closeConfig.closingCondition"),
        documentTypeExists: t("documentCollection.closeConfig.documentTypeExists"),
        fieldValue: t("documentCollection.closeConfig.fieldValue"),
        logicalExpression: t("documentCollection.closeConfig.logicalExpression"),
        noMissingRequiredDocuments: t("documentCollection.closeConfig.noMissingRequiredDocuments"),
      },
      collectionFilter: {
        clearAllFilters: t("documentCollection.collectionFilter.clearAllFilters"),
        documentsToShow: t("documentCollection.collectionFilter.documentsToShow"),
        filterDocuments: t("documentCollection.collectionFilter.filterDocuments"),
        filterWhatsThis: t("documentCollection.collectionFilter.filterWhatsThis"),
        filterWhatsThisDetailed: t("documentCollection.collectionFilter.filterWhatsThisDetailed"),
        showAllDocuments: t("documentCollection.collectionFilter.showAllDocuments"),
        showExpiredDocuments: t("documentCollection.collectionFilter.showExpiredDocuments"),
        showExpiringSoonDocuments: t(
          "documentCollection.collectionFilter.showExpiringSoonDocuments"
        ),
        showIssuesDocuments: t("documentCollection.collectionFilter.showIssuesDocuments"),
      },
      collectionValues: t("documentCollection.collectionValues"),
      conformation: t("documentCollection.conformation"),
      conformationStepDesc: t("documentCollection.conformationStepDesc"),
      definingKey: t("documentCollection.definingKey"),
      displaySettings: t("documentCollection.displaySettings"),
      displaySettingsStepDesc: t("documentCollection.displaySettingsStepDesc"),
      documentCollectionPanel: t("documentCollection.documentCollectionPanel"),
      documentCollections: t("documentCollection.documentCollections"),
      documentCollectionVariablesRequired: t(
        "documentCollection.documentCollectionVariablesRequired"
      ),
      documentConfigurationFor: t("documentCollection.documentConfigurationFor"),
      documentsRelations: t("documentCollection.documentsRelations"),
      documentsRelationsStepDesc: t("documentCollection.documentsRelationsStepDesc"),
      dragAndDropYourFilesHereOr: t("documentCollection.dragAndDropYourFilesHereOr"),
      dragToMainLevel: t("documentCollection.dragToMainLevel"),
      filterByDocumentCollection: t("documentCollection.filterByDocumentCollection"),
      financialIndustry: {
        bank: t("documentCollection.financialIndustry.bank"),
        barChart: t("documentCollection.financialIndustry.barChart"),
        calculator: t("documentCollection.financialIndustry.calculator"),
        coinsStack: t("documentCollection.financialIndustry.coinsStack"),
        creditCard: t("documentCollection.financialIndustry.creditCard"),
        financialDocument: t("documentCollection.financialIndustry.financialDocument"),
        financialIndustry: t("documentCollection.financialIndustry.financialIndustry"),
        loan: t("documentCollection.financialIndustry.loan"),
        piggyBank: t("documentCollection.financialIndustry.piggyBank"),
        safe: t("documentCollection.financialIndustry.safe"),
        stockMarket: t("documentCollection.financialIndustry.stockMarket"),
        wallet: t("documentCollection.financialIndustry.wallet"),
      },
      folderConfigurationFor: t("documentCollection.folderConfigurationFor"),
      folderName: t("documentCollection.folderName"),
      folderOrder: t("documentCollection.folderOrder"),
      goBackToDocumentCollection: t("documentCollection.goBackToDocumentCollection"),
      hideSnapshot: t("documentCollection.hideSnapshot"),
      identityFormula: t("documentCollection.identityFormula"),
      identityFormulaVariablesRequired: t("documentCollection.identityFormulaVariablesRequired"),
      lawFirms: {
        contract: t("documentCollection.lawFirms.contract"),
        documentSignature: t("documentCollection.lawFirms.documentSignature"),
        gavel: t("documentCollection.lawFirms.gavel"),
        handcuffs: t("documentCollection.lawFirms.handcuffs"),
        lawBadge: t("documentCollection.lawFirms.lawBadge"),
        lawBook: t("documentCollection.lawFirms.lawBook"),
        lawFirms: t("documentCollection.lawFirms.lawFirms"),
        legalBriefcase: t("documentCollection.lawFirms.legalBriefcase"),
        legalScale: t("documentCollection.lawFirms.legalScale"),
        prisonCell: t("documentCollection.lawFirms.prisonCell"),
        witnessStand: t("documentCollection.lawFirms.witnessStand"),
      },
      masterDocumentType: t("documentCollection.masterDocumentType"),
      noDocumentTypesAvailable: t("documentCollection.noDocumentTypesAvailable"),
      noMoreFieldsAvailable: t("documentCollection.noMoreFieldsAvailable"),
      relationRequired: t("documentCollection.relationRequired"),
      relationshipWithEmptyDoctypeOrTarget: t(
        "documentCollection.relationshipWithEmptyDoctypeOrTarget"
      ),
      relationshipWithEmptySource: t("documentCollection.relationshipWithEmptySource"),
      resolveTheIssues: t("documentCollection.resolveTheIssues"),
      sourceField: t("documentCollection.sourceField"),
      structure: t("documentCollection.structure"),
      structureStepDesc: t("documentCollection.structureStepDesc"),
      targetField: t("documentCollection.targetField"),
      validations: t("documentCollection.validations"),
      validationsStepDesc: t("documentCollection.validationsStepDesc"),
      viewSnapshot: t("documentCollection.viewSnapshot"),
      youAreAboutToChangeTheCollection: t("documentCollection.youAreAboutToChangeTheCollection"),
      youAreAboutToDeleteTheCollection: t("documentCollection.youAreAboutToDeleteTheCollection"),
      YouHaveRepeatedVariables: t("documentCollection.YouHaveRepeatedVariables"),
    },
    documentDate: t("documentDate"),
    documentGroup: t("documentGroup"),
    documentGroupNameValidations: {
      documentGroupAlreadyExists: t("documentGroupNameValidations.documentGroupAlreadyExists"),
      invalidDocumentGroupName: t("documentGroupNameValidations.invalidDocumentGroupName"),
    },
    documentGroups: t("documentGroups"),
    documentLabels: t("documentLabels"),
    documentLabelsNames: t("documentLabelsNames"),
    documentName: t("documentName"),
    documentRecovery: t("documentRecovery"),
    documents: t("documents"),
    documentsAboutToExpire: t("documentsAboutToExpire"),
    documentsLowercase: t("documentsLowercase"),
    documentsNotFound: t("documentsNotFound"),
    documentTypeFormatDoesntExist: t("documentTypeFormatDoesntExist"),
    documentTypes: {
      cannotDirectlyEditTemplates: t("documentTypes.cannotDirectlyEditTemplates"),
      changesInIdentifierFound: t("documentTypes.changesInIdentifierFound"),
      copy: t("documentTypes.copy"),
      createRecord: t("documentTypes.createRecord"),
      createRecordActionMeaning: t("documentTypes.createRecordActionMeaning"),
      documentCollection: t("documentTypes.documentCollection"),
      documentKeys: t("documentTypes.documentKeys"),
      documentKeysStepDesc: t("documentTypes.documentKeysStepDesc"),
      documentType: t("documentTypes.documentType"),
      documentTypeName: t("documentTypes.documentTypeName"),
      documentTypes: t("documentTypes.documentTypes"),
      documentTypeSaved: t("documentTypes.documentTypeSaved"),
      documentTypeStepDesc: t("documentTypes.documentTypeStepDesc"),
      documentTypeVersion: t("documentTypes.documentTypeVersion"),
      formConfigurationStepDesc: t("documentTypes.formConfigurationStepDesc"),
      missingSecurityKeys: t("documentTypes.missingSecurityKeys"),
      noDataElements: t("documentTypes.noDataElements"),
      notEnoughElementsSelected: t("documentTypes.notEnoughElementsSelected"),
      permissionsStepDesc: t("documentTypes.permissionsStepDesc"),
      pleaseSelectADocumentGroup: t("documentTypes.pleaseSelectADocumentGroup"),
      recordCreation: t("documentTypes.recordCreation"),
      recordName: t("documentTypes.recordName"),
      required: t("documentTypes.required"),
      selectElementsRecord: t("documentTypes.selectElementsRecord"),
      shouldHaveAValueBetween: t("documentTypes.shouldHaveAValueBetween"),
      typesOfNotes: t("documentTypes.typesOfNotes"),
    },
    documentUniqueValueSelection: t("documentUniqueValueSelection"),
    documentViewerSignatureWidget: {
      appendToEnd: t("documentViewerSignatureWidget.appendToEnd"),
      clickHere: t("documentViewerSignatureWidget.clickHere"),
      createRectangleAnnotation: t("documentViewerSignatureWidget.createRectangleAnnotation"),
      documentMustBeConverted: t("documentViewerSignatureWidget.documentMustBeConverted"),
      errorMessageInvalidKeyfile: t("documentViewerSignatureWidget.errorMessageInvalidKeyfile"),
      errorMessageInvalidPassphrase: t(
        "documentViewerSignatureWidget.errorMessageInvalidPassphrase"
      ),
      horizontalPosition: t("documentViewerSignatureWidget.horizontalPosition"),
      location: t("documentViewerSignatureWidget.location"),
      notice: t("documentViewerSignatureWidget.notice"),
      noValidSignatureMarks: t("documentViewerSignatureWidget.noValidSignatureMarks"),
      pageIndex: t("documentViewerSignatureWidget.pageIndex"),
      pleaseEnsureCertificateFileAndPrivateKey: t(
        "documentViewerSignatureWidget.pleaseEnsureCertificateFileAndPrivateKey"
      ),
      pleaseSelectYourKeyFile: t("documentViewerSignatureWidget.pleaseSelectYourKeyFile"),
      pleaseSelectYourKeyFileAlternate: t(
        "documentViewerSignatureWidget.pleaseSelectYourKeyFileAlternate"
      ),
      pleaseSelectYourKeyFileExplanation: t(
        "documentViewerSignatureWidget.pleaseSelectYourKeyFileExplanation"
      ),
      pleaseSelectYourKeyFileLocalStorageLoaded: t(
        "documentViewerSignatureWidget.pleaseSelectYourKeyFileLocalStorageLoaded"
      ),
      pleaseSelectYourKeyFileWarning: t(
        "documentViewerSignatureWidget.pleaseSelectYourKeyFileWarning"
      ),
      pleaseSelectYourSignatureImageFile: t(
        "documentViewerSignatureWidget.pleaseSelectYourSignatureImageFile"
      ),
      pleaseTypeKeyFilePassPhrase: t("documentViewerSignatureWidget.pleaseTypeKeyFilePassPhrase"),
      signatureHeight: t("documentViewerSignatureWidget.signatureHeight"),
      signatureReason: t("documentViewerSignatureWidget.signatureReason"),
      signatureWidth: t("documentViewerSignatureWidget.signatureWidth"),
      signerContact: t("documentViewerSignatureWidget.signerContact"),
      signerLocation: t("documentViewerSignatureWidget.signerLocation"),
      signerName: t("documentViewerSignatureWidget.signerName"),
      verticalPosition: t("documentViewerSignatureWidget.verticalPosition"),
      whatIsAKeyFile: t("documentViewerSignatureWidget.whatIsAKeyFile"),
    },
    documentViewerToolbarItems: {
      adjustHeight: t("documentViewerToolbarItems.adjustHeight"),
      adjustWidth: t("documentViewerToolbarItems.adjustWidth"),
      collapseImagePanel: t("documentViewerToolbarItems.collapseImagePanel"),
      fullScreen: t("documentViewerToolbarItems.fullScreen"),
      handTool: t("documentViewerToolbarItems.handTool"),
      selectTool: t("documentViewerToolbarItems.selectTool"),
      zoomIn: t("documentViewerToolbarItems.zoomIn"),
      zoomOut: t("documentViewerToolbarItems.zoomOut"),
    },
    documentViewerWrapper: {
      documentsInThisVersionHistory: t("documentViewerWrapper.documentsInThisVersionHistory"),
      documentsOfThisCollection: t("documentViewerWrapper.documentsOfThisCollection"),
      documentsOfThisGeneration: t("documentViewerWrapper.documentsOfThisGeneration"),
      viewGeneration: t("documentViewerWrapper.viewGeneration"),
    },
    dontHaveAccount: t("dontHaveAccount"),
    download: t("download"),
    downloadP12KeyFile: t("downloadP12KeyFile"),
    dragDropFile: t("dragDropFile"),
    duplicate: t("duplicate"),
    duplicated: t("duplicated"),
    edit: t("edit"),
    editTemplate: t("editTemplate"),
    editTemplatePage: {
      comment: t("editTemplatePage.comment"),
      documentPreview: t("editTemplatePage.documentPreview"),
      formDesigner: t("editTemplatePage.formDesigner"),
      formTesting: t("editTemplatePage.formTesting"),
      publishingAtDate: t("editTemplatePage.publishingAtDate"),
      publishingDateRange: t("editTemplatePage.publishingDateRange"),
      publishingNow: t("editTemplatePage.publishingNow"),
      publishingType: t("editTemplatePage.publishingType"),
      youMustFill: t("editTemplatePage.youMustFill"),
    },
    email: t("email"),
    empty: t("empty"),
    enable: t("enable"),
    endsWith: t("endsWith"),
    enterEmail: t("enterEmail"),
    enterInitialValue: t("enterInitialValue"),
    enterLastName: t("enterLastName"),
    enterName: t("enterName"),
    enterNewPassword: t("enterNewPassword"),
    enterPassword: t("enterPassword"),
    equals: t("equals"),
    error: t("error"),
    errorMessageEmpty: t("errorMessageEmpty"),
    errorMessageLabel: t("errorMessageLabel"),
    errorMessageSso: t("errorMessageSso"),
    errorOccurred: t("errorOccurred"),
    estimatedAndRealEndDate: t("estimatedAndRealEndDate"),
    exceededTimeOut: t("exceededTimeOut"),
    exceedMailChar: t("exceedMailChar"),
    exit: t("exit"),
    exitOrganization: t("exitOrganization"),
    expand: t("expand"),
    expirationDateConfig: {
      attachmentToField: t("expirationDateConfig.attachmentToField"),
      daysAfterCreation: t("expirationDateConfig.daysAfterCreation"),
      expirationDateConfiguration: t("expirationDateConfig.expirationDateConfiguration"),
      noExpirationDate: t("expirationDateConfig.noExpirationDate"),
    },
    expirationDateWhatsThis: {
      daysAfterCreationDescription: t("expirationDateWhatsThis.daysAfterCreationDescription"),
      expirationWhatsThis: t("expirationDateWhatsThis.expirationWhatsThis"),
      indexedDocumentDescription: t("expirationDateWhatsThis.indexedDocumentDescription"),
    },
    expirationDaysOfNotice: t("expirationDaysOfNotice"),
    expirationDaysOfNoticeDesc: t("expirationDaysOfNoticeDesc"),
    expirationTimeHours: t("expirationTimeHours"),
    expirationTimeLabel: t("expirationTimeLabel"),
    expired: t("expired"),
    expiredDocuments: t("expiredDocuments"),
    expires: t("expires"),
    favorites: t("favorites"),
    field: t("field"),
    fields: t("fields"),
    fileElementsCannotBeRepeatedBetweenFiles: t("fileElementsCannotBeRepeatedBetweenFiles"),
    filesLengthMatchNoEmpty: t("filesLengthMatchNoEmpty"),
    FileType: t("FileType"),
    fileTypeNotSupported: t("fileTypeNotSupported"),
    fileWithThisNameAlreadyExists: t("fileWithThisNameAlreadyExists"),
    filterBuilder: {
      addAnExpressionHere: t("filterBuilder.addAnExpressionHere"),
      addDependency: t("filterBuilder.addDependency"),
      alwaysFalse: t("filterBuilder.alwaysFalse"),
      alwaysTrue: t("filterBuilder.alwaysTrue"),
      averageOf: t("filterBuilder.averageOf"),
      clickHereToTurnToNegativeAnd: t("filterBuilder.clickHereToTurnToNegativeAnd"),
      clickHereToTurnToNegativeOr: t("filterBuilder.clickHereToTurnToNegativeOr"),
      clickHereToTurnToPositiveAnd: t("filterBuilder.clickHereToTurnToPositiveAnd"),
      clickHereToTurnToPositiveOr: t("filterBuilder.clickHereToTurnToPositiveOr"),
      clickToFilterFunnel: t("filterBuilder.clickToFilterFunnel"),
      condition: t("filterBuilder.condition"),
      countOf: t("filterBuilder.countOf"),
      createDependenciesDescription: t("filterBuilder.createDependenciesDescription"),
      custom: t("filterBuilder.custom"),
      dependencyName: t("filterBuilder.dependencyName"),
      dependencyNotCreated: t("filterBuilder.dependencyNotCreated"),
      dependencySelection: t("filterBuilder.dependencySelection"),
      every: t("filterBuilder.every"),
      exclude: t("filterBuilder.exclude"),
      falseF: t("filterBuilder.falseF"),
      helpMode: t("filterBuilder.helpMode"),
      ifThisExpressionIs: t("filterBuilder.ifThisExpressionIs"),
      include: t("filterBuilder.include"),
      invalidExpression: t("filterBuilder.invalidExpression"),
      is: t("filterBuilder.is"),
      isNot: t("filterBuilder.isNot"),
      maximumOf: t("filterBuilder.maximumOf"),
      minimumOf: t("filterBuilder.minimumOf"),
      negativeF: t("filterBuilder.negativeF"),
      not: t("filterBuilder.not"),
      positiveF: t("filterBuilder.positiveF"),
      some: t("filterBuilder.some"),
      sumOf: t("filterBuilder.sumOf"),
      then: t("filterBuilder.then"),
      theSelectedTextInTheDocument: t("filterBuilder.theSelectedTextInTheDocument"),
      trueF: t("filterBuilder.trueF"),
      turnYourExpression: t("filterBuilder.turnYourExpression"),
      valueOfLabel: t("filterBuilder.valueOfLabel"),
      whereCaps: t("filterBuilder.whereCaps"),
    },
    filters: t("filters"),
    finish: t("finish"),
    firstName: t("firstName"),
    fluency: t("fluency"),
    folder: t("folder"),
    footer: {
      api: t("footer.api"),
      contact: t("footer.contact"),
      privacy: t("footer.privacy"),
      terms: t("footer.terms"),
    },
    forgotPassword: t("forgotPassword"),
    forgotPasswordPage: {
      backToLogin: t("forgotPasswordPage.backToLogin"),
      didntRecieveEmail: t("forgotPasswordPage.didntRecieveEmail"),
      enterAssociatedEmail: t("forgotPasswordPage.enterAssociatedEmail"),
      forgotYourPassword: t("forgotPasswordPage.forgotYourPassword"),
      moveToSignIn: t("forgotPasswordPage.moveToSignIn"),
      passwordResetAlreadyExpired: t("forgotPasswordPage.passwordResetAlreadyExpired"),
      resetPassword: t("forgotPasswordPage.resetPassword"),
      weSentEmailToResetPassword: t("forgotPasswordPage.weSentEmailToResetPassword"),
      youWillRecievedEmailCheckInbox: t("forgotPasswordPage.youWillRecievedEmailCheckInbox"),
    },
    formConfiguration: t("formConfiguration"),
    formDesigner: {
      connectorSetting: {
        description: t("formDesigner.connectorSetting.description"),
        whatAreCondition: t("formDesigner.connectorSetting.whatAreCondition"),
      },
      createNewVersionIfSave: t("formDesigner.createNewVersionIfSave"),
      createNewVersionOrDocument: t("formDesigner.createNewVersionOrDocument"),
      defaultSection: t("formDesigner.defaultSection"),
      emptySectionText: t("formDesigner.emptySectionText"),
      fieldRelevance: t("formDesigner.fieldRelevance"),
      fieldRelevanceSetting: {
        description: t("formDesigner.fieldRelevanceSetting.description"),
        whatAreCondition: t("formDesigner.fieldRelevanceSetting.whatAreCondition"),
      },
      formPreview: t("formDesigner.formPreview"),
      generalPage: t("formDesigner.generalPage"),
      newVersion: t("formDesigner.newVersion"),
      sectionsList: t("formDesigner.sectionsList"),
      useDocumentData: t("formDesigner.useDocumentData"),
    },
    formOfDisposal: t("formOfDisposal"),
    formRequirement: t("formRequirement"),
    formRequirementInfo: t("formRequirementInfo"),
    formSteps: t("formSteps"),
    fragmentNotFound: t("fragmentNotFound"),
    fragments: t("fragments"),
    from: t("from"),
    general: t("general"),
    generalData: t("generalData"),
    generalProperties: t("generalProperties"),
    generalSettings: t("generalSettings"),
    generate: t("generate"),
    generateAsDeveloper: t("generateAsDeveloper"),
    generatedByProDoctivity: t("generatedByProDoctivity"),
    generateDocument: t("generateDocument"),
    generateDocumentPage: {
      documentsList: t("generateDocumentPage.documentsList"),
    },
    generatePkcs12File: t("generatePkcs12File"),
    generateUniqueDocumentNumber: t("generateUniqueDocumentNumber"),
    generation: t("generation"),
    generationDocuments: t("generationDocuments"),
    generationInformation: t("generationInformation"),
    generations: t("generations"),
    generationStatus: {
      documentKey: t("generationStatus.documentKey"),
      documentsAvailable: t("generationStatus.documentsAvailable"),
      documentsExpired: t("generationStatus.documentsExpired"),
      errors: t("generationStatus.errors"),
      expirationDate: t("generationStatus.expirationDate"),
      generationList: t("generationStatus.generationList"),
      templateName: t("generationStatus.templateName"),
      totalDocuments: t("generationStatus.totalDocuments"),
    },
    generationTasks: t("generationTasks"),
    getStarted: t("getStarted"),
    giveValueDescription: t("giveValueDescription"),
    giveValueDesignatorDescriptiveName: t("giveValueDesignatorDescriptiveName"),
    global: t("global"),
    goBack: t("goBack"),
    goBackForm: t("goBackForm"),
    goBackHome: t("goBackHome"),
    goToLastVersion: t("goToLastVersion"),
    graphicMark: t("graphicMark"),
    graphicMarks: t("graphicMarks"),
    graphicMarkTemplate: t("graphicMarkTemplate"),
    graphicMarkTemplateDesc: t("graphicMarkTemplateDesc"),
    greaterThan: t("greaterThan"),
    greaterThanOrEquals: t("greaterThanOrEquals"),
    hasSeen: t("hasSeen"),
    hasTheWord: t("hasTheWord"),
    haveAccount: t("haveAccount"),
    help: t("help"),
    hide: t("hide"),
    high: t("high"),
    history: t("history"),
    home: t("home"),
    homePage: {
      butOthersMightHaveTasksToDo: t("homePage.butOthersMightHaveTasksToDo"),
      myTasks: t("homePage.myTasks"),
      noActiveTask: t("homePage.noActiveTask"),
      taskIsEmpty: t("homePage.taskIsEmpty"),
      viewTaskHistory: t("homePage.viewTaskHistory"),
    },
    hours: t("hours"),
    iAcceptTheConditions: t("iAcceptTheConditions"),
    icon: t("icon"),
    identifierCollisionForcesNewVersion: t("identifierCollisionForcesNewVersion"),
    identifierConfig: t("identifierConfig"),
    ifYouUpdateDateElement: t("ifYouUpdateDateElement"),
    image: t("image"),
    images: t("images"),
    imageType: t("imageType"),
    immediately: t("immediately"),
    immutable: t("immutable"),
    importADocument: t("importADocument"),
    importingModule: t("importingModule"),
    importOtherDocument: t("importOtherDocument"),
    includeDocumentIf: t("includeDocumentIf"),
    indexDocument: {
      captureAndReview: t("indexDocument.captureAndReview"),
      captureAndReviewDesc: t("indexDocument.captureAndReviewDesc"),
      clickHereAndUploadYourFile: t("indexDocument.clickHereAndUploadYourFile"),
      indexing: t("indexDocument.indexing"),
      indexingDesc: t("indexDocument.indexingDesc"),
    },
    info: t("info"),
    inherit: t("inherit"),
    initialValue: t("initialValue"),
    inProgress: t("inProgress"),
    input: t("input"),
    inputData: t("inputData"),
    instance: t("instance"),
    instructions: t("instructions"),
    instructionsOfTask: t("instructionsOfTask"),
    integrations: t("integrations"),
    invalid: t("invalid"),
    invalidDocument: t("invalidDocument"),
    invalidEmail: t("invalidEmail"),
    invalidLogin: t("invalidLogin"),
    invalidName: t("invalidName"),
    invalidNameType: t("invalidNameType"),
    invalidPhone: t("invalidPhone"),
    invalidUserOrPassword: t("invalidUserOrPassword"),
    invitationDoesntExist: t("invitationDoesntExist"),
    invitationExpired: t("invitationExpired"),
    invitationExpiredMessage: t("invitationExpiredMessage"),
    invitationForwarded: t("invitationForwarded"),
    isBlocking: t("isBlocking"),
    isContentLibrary: t("isContentLibrary"),
    issues: t("issues"),
    isTrackable: t("isTrackable"),
    items: t("items"),
    itemsFor: t("itemsFor"),
    itIsNotAllowedToActivateUsers: t("itIsNotAllowedToActivateUsers"),
    itSeemsDontHavePermissions: t("itSeemsDontHavePermissions"),
    itSeemsLike: t("itSeemsLike"),
    iWantEmails: t("iWantEmails"),
    joinOrganizationAndLogin: t("joinOrganizationAndLogin"),
    label: t("label"),
    labelAlreadyExists: t("labelAlreadyExists"),
    landingPage: {
      org: t("landingPage.org"),
      orgId: t("landingPage.orgId"),
      orgName: t("landingPage.orgName"),
    },
    landingPageNew: {
      achieveStrategicObjectives: t("landingPageNew.achieveStrategicObjectives"),
      andMuchMore: t("landingPageNew.andMuchMore"),
      buttonLabels: {
        getAccessNow: t("landingPageNew.buttonLabels.getAccessNow"),
        startProcess: t("landingPageNew.buttonLabels.startProcess"),
        tryItNow: t("landingPageNew.buttonLabels.tryItNow"),
      },
      cards: {
        bulletPoints: {
          authenticityProof: t("landingPageNew.cards.bulletPoints.authenticityProof"),
          autoDocGeneration: t("landingPageNew.cards.bulletPoints.autoDocGeneration"),
          autoDocSeparation: t("landingPageNew.cards.bulletPoints.autoDocSeparation"),
          barCodeIdentification: t("landingPageNew.cards.bulletPoints.barCodeIdentification"),
          distributionToDigitalMedia: t(
            "landingPageNew.cards.bulletPoints.distributionToDigitalMedia"
          ),
          emailNotificationAccountStatements: t(
            "landingPageNew.cards.bulletPoints.emailNotificationAccountStatements"
          ),
          integrationOnBase: t("landingPageNew.cards.bulletPoints.integrationOnBase"),
          massiveBatchDigitization: t("landingPageNew.cards.bulletPoints.massiveBatchDigitization"),
          scanOnlineAndIndex: t("landingPageNew.cards.bulletPoints.scanOnlineAndIndex"),
          storageDatabases: t("landingPageNew.cards.bulletPoints.storageDatabases"),
          toolIntegrationWithAPIs: t("landingPageNew.cards.bulletPoints.toolIntegrationWithAPIs"),
        },
        configurableCaptureForm: t("landingPageNew.cards.configurableCaptureForm"),
        easyImplementTool: t("landingPageNew.cards.easyImplementTool"),
      },
      clientsWhoTrustInOurProduct: t("landingPageNew.clientsWhoTrustInOurProduct"),
      completeAssignedTasks: t("landingPageNew.completeAssignedTasks"),
      consultationAndVisualization: t("landingPageNew.consultationAndVisualization"),
      contactUsForMoreInformation: t("landingPageNew.contactUsForMoreInformation"),
      countWithIntegrations: t("landingPageNew.countWithIntegrations"),
      createDocumentsByImportingOrDigitizing: t(
        "landingPageNew.createDocumentsByImportingOrDigitizing"
      ),
      generateDocumentsFromTemplate: t("landingPageNew.generateDocumentsFromTemplate"),
      howDoWeSolveYourNeeds: t("landingPageNew.howDoWeSolveYourNeeds"),
      inputLabels: {
        documentsToGenerate: t("landingPageNew.inputLabels.documentsToGenerate"),
        messageLabel: t("landingPageNew.inputLabels.messageLabel"),
      },
      manageFiles: t("landingPageNew.manageFiles"),
      multipurposeData: t("landingPageNew.multipurposeData"),
      offerCompatibility: t("landingPageNew.offerCompatibility"),
      optimizeTheWayYouGenerateAndManageYourDocuments: t(
        "landingPageNew.optimizeTheWayYouGenerateAndManageYourDocuments"
      ),
      performOpenAndStructuredSearches: t("landingPageNew.performOpenAndStructuredSearches"),
      placeholders: {
        messagePlaceholder: t("landingPageNew.placeholders.messagePlaceholder"),
      },
      startGeneratingDocumentsInAMinute: t("landingPageNew.startGeneratingDocumentsInAMinute"),
      streamlineDocumentCreationAndManagementWith: t(
        "landingPageNew.streamlineDocumentCreationAndManagementWith"
      ),
      visitorsFooter: {
        corpBoilerplate: t("landingPageNew.visitorsFooter.corpBoilerplate"),
        linksTitle: t("landingPageNew.visitorsFooter.linksTitle"),
        termsAndConditions: t("landingPageNew.visitorsFooter.termsAndConditions"),
      },
      webBased: t("landingPageNew.webBased"),
      whatToolsWillYouGetAccessTo: t("landingPageNew.whatToolsWillYouGetAccessTo"),
      whenCanYouStartGeneratingDocuments: t("landingPageNew.whenCanYouStartGeneratingDocuments"),
      whoAreOurClients: t("landingPageNew.whoAreOurClients"),
      whyAreYouStillWaiting: t("landingPageNew.whyAreYouStillWaiting"),
      wontNeedToInstallSoftware: t("landingPageNew.wontNeedToInstallSoftware"),
    },
    language: t("language"),
    languages: {
      dutch: t("languages.dutch"),
      english: t("languages.english"),
      portuguese: t("languages.portuguese"),
      spanish: t("languages.spanish"),
    },
    large: t("large"),
    lastModifiedBy: t("lastModifiedBy"),
    lastName: t("lastName"),
    lastUpdated: t("lastUpdated"),
    leaveOrganization: t("leaveOrganization"),
    leaveOrganizationPage: {
      aboutLeaveOrganization: t("leaveOrganizationPage.aboutLeaveOrganization"),
      checkOrganizationId: t("leaveOrganizationPage.checkOrganizationId"),
      couldntLeaveOrganization: t("leaveOrganizationPage.couldntLeaveOrganization"),
      dangerZone: t("leaveOrganizationPage.dangerZone"),
      successfullyLeftOrganization: t("leaveOrganizationPage.successfullyLeftOrganization"),
      toLeaveOrganization: t("leaveOrganizationPage.toLeaveOrganization"),
    },
    lessThan: t("lessThan"),
    lessThanOrEquals: t("lessThanOrEquals"),
    licenseManagement: t("licenseManagement"),
    listAlreadyExists: t("listAlreadyExists"),
    listIsEmpty: t("listIsEmpty"),
    listName: t("listName"),
    lists: t("lists"),
    loading: t("loading"),
    loadMore: t("loadMore"),
    logInWithSso: t("logInWithSso"),
    manageUsers: t("manageUsers"),
    maxFileSize: t("maxFileSize"),
    maximum: t("maximum"),
    maximumCharactersAllowed: t("maximumCharactersAllowed"),
    method: t("method"),
    middleName: t("middleName"),
    milliseconds: t("milliseconds"),
    minimum: t("minimum"),
    minutes: t("minutes"),
    missing: t("missing"),
    missingDocuments: t("missingDocuments"),
    modifyDocument: t("modifyDocument"),
    more: t("more"),
    mostRecent: t("mostRecent"),
    moveDown: t("moveDown"),
    moveTo: t("moveTo"),
    moveUp: t("moveUp"),
    myLists: t("myLists"),
    myOrganization: t("myOrganization"),
    myProfile: t("myProfile"),
    name: t("name"),
    nameCannotStartWithNumber: t("nameCannotStartWithNumber"),
    new: t("new"),
    newCondition: t("newCondition"),
    newDocument: t("newDocument"),
    newDocumentGroup: t("newDocumentGroup"),
    newDocumentType: t("newDocumentType"),
    newFolder: t("newFolder"),
    newPassword: t("newPassword"),
    newRelation: t("newRelation"),
    newTask: t("newTask"),
    newTemplate: t("newTemplate"),
    newVariable: t("newVariable"),
    newVariableDescription: t("newVariableDescription"),
    noComments: t("noComments"),
    noDataLoaded: t("noDataLoaded"),
    noInternetConnection: t("noInternetConnection"),
    noJustSave: t("noJustSave"),
    noMatchingResults: {
      noMatchingResultsDescription: t("noMatchingResults.noMatchingResultsDescription"),
      noMatchingResultsTitle: t("noMatchingResults.noMatchingResultsTitle"),
    },
    noProcessesFoundToSelect: t("noProcessesFoundToSelect"),
    noProcessSelected: t("noProcessSelected"),
    noResultsFound: t("noResultsFound"),
    normal: t("normal"),
    noSelectedDocumentType: t("noSelectedDocumentType"),
    noTagsAdded: t("noTagsAdded"),
    notEquals: t("notEquals"),
    notExistsDocumentOn: t("notExistsDocumentOn"),
    notifications: {
      markAllAsRead: t("notifications.markAllAsRead"),
      markAsRead: t("notifications.markAsRead"),
      noNotificationsToShow: t("notifications.noNotificationsToShow"),
      notifications: t("notifications.notifications"),
      seeAllNotifications: t("notifications.seeAllNotifications"),
      weWillLetYouKnow: t("notifications.weWillLetYouKnow"),
    },
    notLatestVersion: t("notLatestVersion"),
    notLoggedIn: t("notLoggedIn"),
    notPriviledged: t("notPriviledged"),
    noUsers: t("noUsers"),
    noValue: t("noValue"),
    numberOfTimes: t("numberOfTimes"),
    of: t("of"),
    oldest: t("oldest"),
    oldPassword: t("oldPassword"),
    open: t("open"),
    operator: t("operator"),
    optional: t("optional"),
    options: t("options"),
    or: t("or"),
    organizationDefaultQueryBehavior: t("organizationDefaultQueryBehavior"),
    organizationEventCreated: t("organizationEventCreated"),
    origin: t("origin"),
    originalFormat: t("originalFormat"),
    output: t("output"),
    paddingLength: t("paddingLength"),
    paddingValue: t("paddingValue"),
    page: t("page"),
    pageNotFound: {
      combinedTemplateNotFound: t("pageNotFound.combinedTemplateNotFound"),
      documentCollectionNotFound: t("pageNotFound.documentCollectionNotFound"),
      documentGenerationNotFound: t("pageNotFound.documentGenerationNotFound"),
      documentNotFound: t("pageNotFound.documentNotFound"),
      notFound: t("pageNotFound.notFound"),
      taskNotFound: t("pageNotFound.taskNotFound"),
      templateNotFound: t("pageNotFound.templateNotFound"),
    },
    parent: t("parent"),
    partOfClassDataElement: t("partOfClassDataElement"),
    passphrase: t("passphrase"),
    passphraseLimit: t("passphraseLimit"),
    password: t("password"),
    passwordChange: {
      eightCharacters: t("passwordChange.eightCharacters"),
      lowercaseUppercase: t("passwordChange.lowercaseUppercase"),
      numberOrSymbol: t("passwordChange.numberOrSymbol"),
      tryCreatingPassword: t("passwordChange.tryCreatingPassword"),
      typeComplexPassword: t("passwordChange.typeComplexPassword"),
    },
    passwordError: t("passwordError"),
    passwordSuccess: t("passwordSuccess"),
    paste: t("paste"),
    paymentPage: {
      amountDisplayed: t("paymentPage.amountDisplayed"),
      basicPlan: t("paymentPage.basicPlan"),
      byUpgrading: t("paymentPage.byUpgrading"),
      cancelPurchase: t("paymentPage.cancelPurchase"),
      cardDetails: t("paymentPage.cardDetails"),
      cardNumber: t("paymentPage.cardNumber"),
      cardVerificationCode: t("paymentPage.cardVerificationCode"),
      contactUs: t("paymentPage.contactUs"),
      countryOrRegion: t("paymentPage.countryOrRegion"),
      creditOrDebit: t("paymentPage.creditOrDebit"),
      enterprisePlan: t("paymentPage.enterprisePlan"),
      fifty: t("paymentPage.fifty"),
      fiveTenPerYear: t("paymentPage.fiveTenPerYear"),
      fullName: t("paymentPage.fullName"),
      hundredPerMonth: t("paymentPage.hundredPerMonth"),
      ifYoureTaxExcempt: t("paymentPage.ifYoureTaxExcempt"),
      mmOrYy: t("paymentPage.mmOrYy"),
      paidMonthly: t("paymentPage.paidMonthly"),
      paidYearly: t("paymentPage.paidYearly"),
      paymentDetails: t("paymentPage.paymentDetails"),
      paymentMethod: t("paymentPage.paymentMethod"),
      postalCode: t("paymentPage.postalCode"),
      proPlan: t("paymentPage.proPlan"),
      subscription: t("paymentPage.subscription"),
      tenTwentyPerYear: t("paymentPage.tenTwentyPerYear"),
      thirtySixtyPerYear: t("paymentPage.thirtySixtyPerYear"),
      threeHundredPerMonth: t("paymentPage.threeHundredPerMonth"),
      totalCost: t("paymentPage.totalCost"),
      upgrade: t("paymentPage.upgrade"),
    },
    pencil: t("pencil"),
    pending: t("pending"),
    pendingToSave: t("pendingToSave"),
    permanentlyDeleted: t("permanentlyDeleted"),
    permissions: t("permissions"),
    phase: t("phase"),
    phone: t("phone"),
    photo: t("photo"),
    pinned: t("pinned"),
    pleaseWaitSeconds: t("pleaseWaitSeconds"),
    position: t("position"),
    post: t("post"),
    preview: t("preview"),
    previousStep: t("previousStep"),
    pricing: t("pricing"),
    pricingPage: {
      advancedPlan: t("pricingPage.advancedPlan"),
      annually: t("pricingPage.annually"),
      basicPlan: t("pricingPage.basicPlan"),
      bestPlanForYourTeam: t("pricingPage.bestPlanForYourTeam"),
      briefDescriptionOfUsage: t("pricingPage.briefDescriptionOfUsage"),
      businessPlan: t("pricingPage.businessPlan"),
      choosePlanThatFitsYourNeeds: t("pricingPage.choosePlanThatFitsYourNeeds"),
      chooseTheBestFull: t("pricingPage.chooseTheBestFull"),
      conctactUsDescription: t("pricingPage.conctactUsDescription"),
      constactUsSuccessMessage: t("pricingPage.constactUsSuccessMessage"),
      contactUsIfYouNeedToSwitchYourPlan: t("pricingPage.contactUsIfYouNeedToSwitchYourPlan"),
      downgradePlan: t("pricingPage.downgradePlan"),
      enterprisePlan: t("pricingPage.enterprisePlan"),
      monthly: t("pricingPage.monthly"),
      payAnnually: t("pricingPage.payAnnually"),
      payMonthly: t("pricingPage.payMonthly"),
      premiumPlan: t("pricingPage.premiumPlan"),
      pricingCalculator: {
        apiCompose: t("pricingPage.pricingCalculator.apiCompose"),
        apiOperate: t("pricingPage.pricingCalculator.apiOperate"),
        choose: t("pricingPage.pricingCalculator.choose"),
        collectionDescription: t("pricingPage.pricingCalculator.collectionDescription"),
        collectionUse: t("pricingPage.pricingCalculator.collectionUse"),
        composeDescription: t("pricingPage.pricingCalculator.composeDescription"),
        estimate: t("pricingPage.pricingCalculator.estimate"),
        gigabytes: t("pricingPage.pricingCalculator.gigabytes"),
        howManyCompose: t("pricingPage.pricingCalculator.howManyCompose"),
        howManyOperation: t("pricingPage.pricingCalculator.howManyOperation"),
        integrationDescription: t("pricingPage.pricingCalculator.integrationDescription"),
        integrationUse: t("pricingPage.pricingCalculator.integrationUse"),
        monthly: t("pricingPage.pricingCalculator.monthly"),
        operationDescription: t("pricingPage.pricingCalculator.operationDescription"),
        organizationDetails: t("pricingPage.pricingCalculator.organizationDetails"),
        organizationUser: t("pricingPage.pricingCalculator.organizationUser"),
        storage: t("pricingPage.pricingCalculator.storage"),
        twelveMonth: t("pricingPage.pricingCalculator.twelveMonth"),
        userCount: t("pricingPage.pricingCalculator.userCount"),
      },
      pricingCards: {
        business: {
          feature1: t("pricingPage.pricingCards.business.feature1"),
          feature10: t("pricingPage.pricingCards.business.feature10"),
          feature11: t("pricingPage.pricingCards.business.feature11"),
          feature2: t("pricingPage.pricingCards.business.feature2"),
          feature3: t("pricingPage.pricingCards.business.feature3"),
          feature4: t("pricingPage.pricingCards.business.feature4"),
          feature5: t("pricingPage.pricingCards.business.feature5"),
          feature6: t("pricingPage.pricingCards.business.feature6"),
          feature7: t("pricingPage.pricingCards.business.feature7"),
          feature8: t("pricingPage.pricingCards.business.feature8"),
          feature9: t("pricingPage.pricingCards.business.feature9"),
        },
        enterprise: {
          feature1: t("pricingPage.pricingCards.enterprise.feature1"),
          feature2: t("pricingPage.pricingCards.enterprise.feature2"),
          feature3: t("pricingPage.pricingCards.enterprise.feature3"),
          feature4: t("pricingPage.pricingCards.enterprise.feature4"),
          feature5: t("pricingPage.pricingCards.enterprise.feature5"),
          feature6(compositionsPerUser: number) {
            return replaceAll(
              t("pricingPage.pricingCards.enterprise.feature6"),
              "{{compositionsPerUser}}",
              `${compositionsPerUser}`
            );
          },
          feature7(operationsPerUser: number) {
            return replaceAll(
              t("pricingPage.pricingCards.enterprise.feature7"),
              "{{operationsPerUser}}",
              `${operationsPerUser}`
            );
          },
          feature8(integrationsPerUser: number) {
            return replaceAll(
              t("pricingPage.pricingCards.enterprise.feature8"),
              "{{integrationsPerUser}}",
              `${integrationsPerUser}`
            );
          },
          feature9: t("pricingPage.pricingCards.enterprise.feature9"),
        },
        professional: {
          feature1: t("pricingPage.pricingCards.professional.feature1"),
          feature2: t("pricingPage.pricingCards.professional.feature2"),
          feature3: t("pricingPage.pricingCards.professional.feature3"),
          feature4: t("pricingPage.pricingCards.professional.feature4"),
          feature5: t("pricingPage.pricingCards.professional.feature5"),

          feature6(collectionPerUser: number) {
            return replaceAll(
              t("pricingPage.pricingCards.professional.feature6"),
              "{{collectionPerUser}}",
              `${collectionPerUser}`
            );
          },
        },
      },

      pricingDescription: t("pricingPage.pricingDescription"),
      pricingTable: {
        feature1: t("pricingPage.pricingTable.feature1"),
        feature10: t("pricingPage.pricingTable.feature10"),
        feature11: t("pricingPage.pricingTable.feature11"),
        feature12: t("pricingPage.pricingTable.feature12"),
        feature2: t("pricingPage.pricingTable.feature2"),
        feature3: t("pricingPage.pricingTable.feature3"),
        feature4: t("pricingPage.pricingTable.feature4"),
        feature5: t("pricingPage.pricingTable.feature5"),
        feature6: t("pricingPage.pricingTable.feature6"),
        feature7: t("pricingPage.pricingTable.feature7"),
        feature8: t("pricingPage.pricingTable.feature8"),
        feature9: t("pricingPage.pricingTable.feature9"),
      },
      professionalPlan: t("pricingPage.professionalPlan"),
      selectPlan: t("pricingPage.selectPlan"),
      submit: t("pricingPage.submit"),
      team: t("pricingPage.team"),
      theBestPlan: t("pricingPage.theBestPlan"),
      twoMonthsOff: t("pricingPage.twoMonthsOff"),
      twoMonthsOffDescription: t("pricingPage.twoMonthsOffDescription"),
      workEmail: t("pricingPage.workEmail"),
    },
    print: t("print"),
    privacyPolicyPage: {
      access: t("privacyPolicyPage.access"),
      accessPersonalInformationRequest: t("privacyPolicyPage.accessPersonalInformationRequest"),
      additionalDisclosures: t("privacyPolicyPage.additionalDisclosures"),
      advertisingPartnersSharing: t("privacyPolicyPage.advertisingPartnersSharing"),
      applicantInformation: t("privacyPolicyPage.applicantInformation"),
      approximateGeolocationData: t("privacyPolicyPage.approximateGeolocationData"),
      attentionPrivacy: t("privacyPolicyPage.attentionPrivacy"),
      authoritiesAndOthers: t("privacyPolicyPage.authoritiesAndOthers"),
      authorizedAgentInstructions: t("privacyPolicyPage.authorizedAgentInstructions"),
      authorizedAgents: t("privacyPolicyPage.authorizedAgents"),
      automatedDataCollectionTechnologies: t(
        "privacyPolicyPage.automatedDataCollectionTechnologies"
      ),
      automaticDataCollection: t("privacyPolicyPage.automaticDataCollection"),
      automaticLoggingInformation: t("privacyPolicyPage.automaticLoggingInformation"),
      businessTransactionParticipantsDescription: t(
        "privacyPolicyPage.businessTransactionParticipantsDescription"
      ),
      businessTransferees: t("privacyPolicyPage.businessTransferees"),
      californiaPrivacyNotice: t("privacyPolicyPage.californiaPrivacyNotice"),
      californiaRightsDescription: t("privacyPolicyPage.californiaRightsDescription"),
      categoriesOfRecipients: t("privacyPolicyPage.categoriesOfRecipients"),
      categoryOfPersonalInformation: t("privacyPolicyPage.categoryOfPersonalInformation"),
      chatMonitoring: t("privacyPolicyPage.chatMonitoring"),
      children: t("privacyPolicyPage.children"),
      childrenUnder13Policy: t("privacyPolicyPage.childrenUnder13Policy"),
      collectedInformation: t("privacyPolicyPage.collectedInformation"),
      combinedPersonalInformation: t("privacyPolicyPage.combinedPersonalInformation"),
      commercialInformation: t("privacyPolicyPage.commercialInformation"),
      communicationDescription: t("privacyPolicyPage.communicationDescription"),
      communications: t("privacyPolicyPage.communications"),
      complianceAndProtection: t("privacyPolicyPage.complianceAndProtection"),
      connectionsAndInvitations: t("privacyPolicyPage.connectionsAndInvitations"),
      consent: t("privacyPolicyPage.consent"),
      consentDisclosureDescription: t("privacyPolicyPage.consentDisclosureDescription"),
      contactEmail: t("privacyPolicyPage.contactEmail"),
      contactsInformationUsage: t("privacyPolicyPage.contactsInformationUsage"),
      correction: t("privacyPolicyPage.correction"),
      correctPersonalInformationRequest: t("privacyPolicyPage.correctPersonalInformationRequest"),
      dataCollectionTechnologiesDescription: t(
        "privacyPolicyPage.dataCollectionTechnologiesDescription"
      ),
      dataRetention: t("privacyPolicyPage.dataRetention"),
      declineToProvideInfo: t("privacyPolicyPage.declineToProvideInfo"),
      deIdentifiedInformationUsage: t("privacyPolicyPage.deIdentifiedInformationUsage"),
      deletePersonalInformationRequest: t("privacyPolicyPage.deletePersonalInformationRequest"),
      deletion: t("privacyPolicyPage.deletion"),
      derivedInformation: t("privacyPolicyPage.derivedInformation"),
      deviceAndOnlineActivityInformation: t("privacyPolicyPage.deviceAndOnlineActivityInformation"),
      disclosedParties: t("privacyPolicyPage.disclosedParties"),
      disclosureOfPersonalInformation: t("privacyPolicyPage.disclosureOfPersonalInformation"),
      doNotTrackDisclaimer: t("privacyPolicyPage.doNotTrackDisclaimer"),
      exerciseRightsContactInstructions: t("privacyPolicyPage.exerciseRightsContactInstructions"),
      googleAnalyticsInfo: t("privacyPolicyPage.googleAnalyticsInfo"),
      headquartersLocationDescription: t("privacyPolicyPage.headquartersLocationDescription"),
      howToContactUs: t("privacyPolicyPage.howToContactUs"),
      howWeDiscloseYourPersonalInformation: t(
        "privacyPolicyPage.howWeDiscloseYourPersonalInformation"
      ),
      howWeUseYourPersonalInformation: t("privacyPolicyPage.howWeUseYourPersonalInformation"),
      identificationInformation: t("privacyPolicyPage.identificationInformation"),
      identifiers: t("privacyPolicyPage.identifiers"),
      includingTo: t("privacyPolicyPage.includingTo"),
      inferences: t("privacyPolicyPage.inferences"),
      inferredPreferences: t("privacyPolicyPage.inferredPreferences"),
      informationCategories: t("privacyPolicyPage.informationCategories"),
      informationYouProvide: t("privacyPolicyPage.informationYouProvide"),
      internetActivityInformation: t("privacyPolicyPage.internetActivityInformation"),
      jobApplicationInformation: t("privacyPolicyPage.jobApplicationInformation"),
      lawEnforcementDescription: t("privacyPolicyPage.lawEnforcementDescription"),
      legalComplianceAndProtection: t("privacyPolicyPage.legalComplianceAndProtection"),
      locationInformation: t("privacyPolicyPage.locationInformation"),
      marketingInformation: t("privacyPolicyPage.marketingInformation"),
      marketingPreferences: t("privacyPolicyPage.marketingPreferences"),
      noticeToCaliforniaUsers: t("privacyPolicyPage.noticeToCaliforniaUsers"),
      onlineTrackingOptOut: t("privacyPolicyPage.onlineTrackingOptOut"),
      optOutInstructions: t("privacyPolicyPage.optOutInstructions"),
      optOutInstructionsKey: t("privacyPolicyPage.optOutInstructionsKey"),
      optOutMethodsDescription: t("privacyPolicyPage.optOutMethodsDescription"),
      optOutNote: t("privacyPolicyPage.optOutNote"),
      optOutOfMarketingCommunications: t("privacyPolicyPage.optOutOfMarketingCommunications"),
      optOutOfSaleOfYourPersonalInformation: t(
        "privacyPolicyPage.optOutOfSaleOfYourPersonalInformation"
      ),
      optOutWaysDescription: t("privacyPolicyPage.optOutWaysDescription"),
      orByWritingTo: t("privacyPolicyPage.orByWritingTo"),
      orderProcessingInformation: t("privacyPolicyPage.orderProcessingInformation"),
      otherSitesAndServices: t("privacyPolicyPage.otherSitesAndServices"),
      otherSources: t("privacyPolicyPage.otherSources"),
      paymentAndTransactionalInformation: t("privacyPolicyPage.paymentAndTransactionalInformation"),
      personalInformationDetails: t("privacyPolicyPage.personalInformationDetails"),
      personalInformationUsage: t("privacyPolicyPage.personalInformationUsage"),
      personalInformationWeCollect: t("privacyPolicyPage.personalInformationWeCollect"),
      plaidApiInformation: t("privacyPolicyPage.plaidApiInformation"),
      plaidPrivacyPolicy: t("privacyPolicyPage.plaidPrivacyPolicy"),
      privacyPolicy: t("privacyPolicyPage.privacyPolicy"),
      privacyPolicyDescription: t("privacyPolicyPage.privacyPolicyDescription"),
      privacyRights: t("privacyPolicyPage.privacyRights"),
      processingOfDataInTheUS: t("privacyPolicyPage.processingOfDataInTheUS"),
      professionalAdvisors: t("privacyPolicyPage.professionalAdvisors"),
      professionalAdvisorsDescription: t("privacyPolicyPage.professionalAdvisorsDescription"),
      relatedCompanies: t("privacyPolicyPage.relatedCompanies"),
      researchAndDevelopment: t("privacyPolicyPage.researchAndDevelopment"),
      retentionPolicy: t("privacyPolicyPage.retentionPolicy"),
      securitySafeguardsDescription: t("privacyPolicyPage.securitySafeguardsDescription"),
      serviceDelivery: t("privacyPolicyPage.serviceDelivery"),
      serviceProvidersDescription: t("privacyPolicyPage.serviceProvidersDescription"),
      thirdPartyAccountConnection: t("privacyPolicyPage.thirdPartyAccountConnection"),
      thirdPartyAccountInformation: t("privacyPolicyPage.thirdPartyAccountInformation"),
      thirdPartyLinksDisclaimer: t("privacyPolicyPage.thirdPartyLinksDisclaimer"),
      thirdPartySources: t("privacyPolicyPage.thirdPartySources"),
      updateInformationInstructions: t("privacyPolicyPage.updateInformationInstructions"),
      updateOrCorrectYourInformation: t("privacyPolicyPage.updateOrCorrectYourInformation"),
      useOfPersonalInformation: t("privacyPolicyPage.useOfPersonalInformation"),
      vendors: t("privacyPolicyPage.vendors"),
      weMayUseYourPersonalInformationTo: t("privacyPolicyPage.weMayUseYourPersonalInformationTo"),
      yourChoices: t("privacyPolicyPage.yourChoices"),
    },
    privateKey: t("privateKey"),
    prodoctivityAccount: t("prodoctivityAccount"),
    prodoctivityDocSharedWithYou: t("prodoctivityDocSharedWithYou"),
    prodoctivityLogo: t("prodoctivityLogo"),
    product: t("product"),
    productPage: {
      benefits: t("productPage.benefits"),
      benefitsTitle: t("productPage.benefitsTitle"),
      centralizeDocumentationManagement: t("productPage.centralizeDocumentationManagement"),
      digitize: t("productPage.digitize"),
      digitizeTitle: t("productPage.digitizeTitle"),
      documentVersionControl: t("productPage.documentVersionControl"),
      efficientDocumentCreation: t("productPage.efficientDocumentCreation"),
      emailEaseAccess: t("productPage.emailEaseAccess"),
      faxFunctionality: t("productPage.faxFunctionality"),
      innovativeConceptApplication: t("productPage.innovativeConceptApplication"),
      internalAuditControl: t("productPage.internalAuditControl"),
      paperPrintSaving: t("productPage.paperPrintSaving"),
      process: t("productPage.process"),
      processTitle: t("productPage.processTitle"),
      proDoctivitySubtitle: t("productPage.proDoctivitySubtitle"),
      productSubtitle: t("productPage.productSubtitle"),
      productTitle: t("productPage.productTitle"),
      saveTimeEfficiency: t("productPage.saveTimeEfficiency"),
      whatIsItTitle: t("productPage.whatIsItTitle"),
    },
    properties: t("properties"),
    protectedUser: t("protectedUser"),
    publish: t("publish"),
    published: t("published"),
    question: t("question"),
    reactivate: t("reactivate"),
    readOnly: t("readOnly"),
    reassign: t("reassign"),
    recentActivity: t("recentActivity"),
    recentDocuments: t("recentDocuments"),
    recentDocumentsCollection: t("recentDocumentsCollection"),
    recentSearches: t("recentSearches"),
    records: t("records"),
    reference: t("reference"),
    refresh: t("refresh"),
    reject: t("reject"),
    reminders: t("reminders"),
    remove: t("remove"),
    rename: t("rename"),
    repeatPassword: t("repeatPassword"),
    replace: t("replace"),
    replaceFile: t("replaceFile"),
    reply: t("reply"),
    reports: t("reports"),
    representationOfDocumentOnCollectionInState: t("representationOfDocumentOnCollectionInState"),
    required: t("required"),
    resendInvitation: t("resendInvitation"),
    reset: t("reset"),
    restore: t("restore"),
    restored: t("restored"),
    restoreToThisVersion: t("restoreToThisVersion"),
    restoreToThisVersionDesc: t("restoreToThisVersionDesc"),
    restrictedPage: t("restrictedPage"),
    returnToLogin: t("returnToLogin"),
    revision: t("revision"),
    role: t("role"),
    roleAssignDesc: t("roleAssignDesc"),
    rolesManagement: {
      allow: t("rolesManagement.allow"),
      assignDocumentCollection: t("rolesManagement.assignDocumentCollection"),
      assignDocumentCollectionDesc: t("rolesManagement.assignDocumentCollectionDesc"),
      assignedPermissions: t("rolesManagement.assignedPermissions"),
      availableUsers: t("rolesManagement.availableUsers"),
      defaultPermission: t("rolesManagement.defaultPermission"),
      deleteDocument: t("rolesManagement.deleteDocument"),
      deleteDocumentCollection: t("rolesManagement.deleteDocumentCollection"),
      deleteDocumentCollectionDesc: t("rolesManagement.deleteDocumentCollectionDesc"),
      deleteDocumentDesc: t("rolesManagement.deleteDocumentDesc"),
      deny: t("rolesManagement.deny"),
      documentCollectionTask: t("rolesManagement.documentCollectionTask"),
      documentCollectionTaskDesc: t("rolesManagement.documentCollectionTaskDesc"),
      documentPermissions: t("rolesManagement.documentPermissions"),
      listOfPermissions: t("rolesManagement.listOfPermissions"),
      missingSecurityKeysForRole: t("rolesManagement.missingSecurityKeysForRole"),
      organizationAdmin: t("rolesManagement.organizationAdmin"),
      organizationAdminDesc: t("rolesManagement.organizationAdminDesc"),
      publishTemplate: t("rolesManagement.publishTemplate"),
      publishTemplateDesc: t("rolesManagement.publishTemplateDesc"),
      roleMembers: t("rolesManagement.roleMembers"),
      roles: t("rolesManagement.roles"),
      roleWithNameAlreadyExists: t("rolesManagement.roleWithNameAlreadyExists"),
      securityKeys: t("rolesManagement.securityKeys"),
      selectAPermissionToInclude: t("rolesManagement.selectAPermissionToInclude"),
      selectAUserToInclude: t("rolesManagement.selectAUserToInclude"),
      specialPermissions: t("rolesManagement.specialPermissions"),
    },
    rows: t("rows"),
    rule: t("rule"),
    runningTime: t("runningTime"),
    save: t("save"),
    saveChanges: t("saveChanges"),
    saveChangesBeforeProceeding: t("saveChangesBeforeProceeding"),
    saved: t("saved"),
    saveInECM: t("saveInECM"),
    saveToCollection: t("saveToCollection"),
    saveVariable: t("saveVariable"),
    scanADocument: t("scanADocument"),
    scanDocument: {
      instructionMessage: t("scanDocument.instructionMessage"),
      noDeviceDetected: t("scanDocument.noDeviceDetected"),
      scannerServiceNotFound: t("scanDocument.scannerServiceNotFound"),
      scannerServiceNotFoundSecondLine: t("scanDocument.scannerServiceNotFoundSecondLine"),
      searchingForDevice: t("scanDocument.searchingForDevice"),
      selectDevice: t("scanDocument.selectDevice"),
      startScan: t("scanDocument.startScan"),
    },
    scanModule: t("scanModule"),
    search: t("search"),
    searchBySequenceName: t("searchBySequenceName"),
    searchFor: t("searchFor"),
    searchForDocuments: t("searchForDocuments"),
    searchForDocumentType: t("searchForDocumentType"),
    searchForTemplate: t("searchForTemplate"),
    searchItems: t("searchItems"),
    searchPanel: t("searchPanel"),
    searchResult: t("searchResult"),
    searchTemplateByName: t("searchTemplateByName"),
    seconds: t("seconds"),
    security: t("security"),
    seeAllListsForThisDocument: t("seeAllListsForThisDocument"),
    seeLess: t("seeLess"),
    seeMore: t("seeMore"),
    seeProperties: t("seeProperties"),
    select: t("select"),
    selectACollection: t("selectACollection"),
    selectedOptions: t("selectedOptions"),
    selectedTasks: t("selectedTasks"),
    selectFromDocument: t("selectFromDocument"),
    selectOne: t("selectOne"),
    selectSource: t("selectSource"),
    sendEmail: t("sendEmail"),
    sending: t("sending"),
    sendViaEmailPage: {
      documentId: t("sendViaEmailPage.documentId"),
      documentSent: t("sendViaEmailPage.documentSent"),
      documentViewer: t("sendViaEmailPage.documentViewer"),
      enterRecipientText: t("sendViaEmailPage.enterRecipientText"),
      message: t("sendViaEmailPage.message"),
      recipients: t("sendViaEmailPage.recipients"),
      sendViaEmail: t("sendViaEmailPage.sendViaEmail"),
      shareDocument: t("sendViaEmailPage.shareDocument"),
      subject: t("sendViaEmailPage.subject"),
      theFollowingMail: t("sendViaEmailPage.theFollowingMail"),
      theMailHasDocAttach: t("sendViaEmailPage.theMailHasDocAttach"),
    },
    sequence: t("sequence"),
    sequenceNameLimit: t("sequenceNameLimit"),
    sequences: t("sequences"),
    setPasswordActivateAccount: t("setPasswordActivateAccount"),
    settings: t("settings"),
    setUpYourSignature: t("setUpYourSignature"),
    share: t("share"),
    shareByEmail: t("shareByEmail"),
    shared: t("shared"),
    show: t("show"),
    showForm: t("showForm"),
    showSummary: t("showSummary"),
    signatures: t("signatures"),
    signaturesNoSignaturesPresentInDocument: t("signaturesNoSignaturesPresentInDocument"),
    signaturesSignedBy: t("signaturesSignedBy"),
    signIn: t("signIn"),
    signing: t("signing"),
    signOut: t("signOut"),
    signThisDocument: t("signThisDocument"),
    signUp: t("signUp"),
    signUpPage: {
      "domain-not-allowed": t("signUpPage.domain-not-allowed"),
      emailConfirmation: t("signUpPage.emailConfirmation"),
      forbidden: t("signUpPage.forbidden"),
      "invalid-phone": t("signUpPage.invalid-phone"),
      "organization-already-exists": t("signUpPage.organization-already-exists"),
      "send-otp-failed": t("signUpPage.send-otp-failed"),
      soon: t("signUpPage.soon"),
      "weak-password": t("signUpPage.weak-password"),
      youWillRecieveAn: t("signUpPage.youWillRecieveAn"),
    },
    small: t("small"),
    sort: t("sort"),
    sortBy: t("sortBy"),
    sortType: t("sortType"),
    source: t("source"),
    sourceType: t("sourceType"),
    specialCharsNotAllowed: t("specialCharsNotAllowed"),
    split: t("split"),
    start: t("start"),
    startsWith: t("startsWith"),
    stateOfDocumentCollection: t("stateOfDocumentCollection"),
    status: t("status"),
    step: t("step"),
    stepValue: t("stepValue"),
    storageSize: t("storageSize"),
    subscribe: t("subscribe"),
    subscriptions: {
      changesSaved: t("subscriptions.changesSaved"),
      dbModelTypeError: t("subscriptions.dbModelTypeError"),
      docTypeAndEmailPreferenceErrorsOnSave: t(
        "subscriptions.docTypeAndEmailPreferenceErrorsOnSave"
      ),
      docTypeAndTemplateTableErrorsOnSave: t("subscriptions.docTypeAndTemplateTableErrorsOnSave"),
      documentTypeSaveError: t("subscriptions.documentTypeSaveError"),
      documentTypesTableTitle: t("subscriptions.documentTypesTableTitle"),
      emailPreferenceError: t("subscriptions.emailPreferenceError"),
      emailPreferencesTableTitle: t("subscriptions.emailPreferencesTableTitle"),
      errorOnAllSaveMethods: t("subscriptions.errorOnAllSaveMethods"),
      itemToUpdateNotFound: t("subscriptions.itemToUpdateNotFound"),
      noDocumentTypeSubscriptions: t("subscriptions.noDocumentTypeSubscriptions"),
      noTemplateSubscriptions: t("subscriptions.noTemplateSubscriptions"),
      subscriptionsLabel: t("subscriptions.subscriptionsLabel"),
      subscriptionsTable: t("subscriptions.subscriptionsTable"),
      tableHeaderTitles: {
        titleSendToMobile: t("subscriptions.tableHeaderTitles.titleSendToMobile"),
        titleSendToWeb: t("subscriptions.tableHeaderTitles.titleSendToWeb"),
        titleSubscriptionType: t("subscriptions.tableHeaderTitles.titleSubscriptionType"),
      },
      templatesTableTitle: t("subscriptions.templatesTableTitle"),
    },
    success: t("success"),
    successfullyDeleted: t("successfullyDeleted"),
    summary: t("summary"),
    support: t("support"),
    suspend: t("suspend"),
    switchAccount: t("switchAccount"),
    switchLanguage: t("switchLanguage"),
    syncLossWarningMessage: t("syncLossWarningMessage"),
    SyncValueLists: t("SyncValueLists"),
    task: t("task"),
    taskApprovalDetailsPrompt: t("taskApprovalDetailsPrompt"),
    taskCompletionStatusMessage: t("taskCompletionStatusMessage"),
    taskDescriptionLabel: t("taskDescriptionLabel"),
    taskDetails: t("taskDetails"),
    taskHistory: t("taskHistory"),
    taskInstructionNotFound: t("taskInstructionNotFound"),
    tasks: t("tasks"),
    template: t("template"),
    templateDetailsPage: {
      apiSampleForDevs: t("templateDetailsPage.apiSampleForDevs"),
      generation: t("templateDetailsPage.generation"),
      myTemplate: t("templateDetailsPage.myTemplate"),
      myTemplates: t("templateDetailsPage.myTemplates"),
      notifyBrowser: t("templateDetailsPage.notifyBrowser"),
      notifyPhone: t("templateDetailsPage.notifyPhone"),
      thisIsNotThePublishedVersion: t("templateDetailsPage.thisIsNotThePublishedVersion"),
      thisIsThePublishedVersion: t("templateDetailsPage.thisIsThePublishedVersion"),
      thisTemplateDoesNotHaveADescription: t(
        "templateDetailsPage.thisTemplateDoesNotHaveADescription"
      ),
      typeNotification: t("templateDetailsPage.typeNotification"),
      update: t("templateDetailsPage.update"),
      updateSubscription: t("templateDetailsPage.updateSubscription"),
    },
    templateListSearchPlaceholder: t("templateListSearchPlaceholder"),
    templatePreview: t("templatePreview"),
    templateProperties: t("templateProperties"),
    templates: t("templates"),
    termsOfServicePrivacyPage: {
      accessAndUseOfTheService: t("termsOfServicePrivacyPage.accessAndUseOfTheService"),
      additionalServiceTerms: t("termsOfServicePrivacyPage.additionalServiceTerms"),
      agreementToArbitrate: t("termsOfServicePrivacyPage.agreementToArbitrate"),
      appleTerms: t("termsOfServicePrivacyPage.appleTerms"),
      appleThirdPartyBeneficiary: t("termsOfServicePrivacyPage.appleThirdPartyBeneficiary"),
      arbitrationNotice: t("termsOfServicePrivacyPage.arbitrationNotice"),
      arbitrationProcedures: t("termsOfServicePrivacyPage.arbitrationProcedures"),
      authorizationToChargePaymentMethodsOnFile: t(
        "termsOfServicePrivacyPage.authorizationToChargePaymentMethodsOnFile"
      ),
      chargebacks: t("termsOfServicePrivacyPage.chargebacks"),
      commercialUse: t("termsOfServicePrivacyPage.commercialUse"),
      conditionsOfUse: t("termsOfServicePrivacyPage.conditionsOfUse"),
      confidentiality: t("termsOfServicePrivacyPage.confidentiality"),
      contentDisclaimer: t("termsOfServicePrivacyPage.contentDisclaimer"),
      copyrightComplaints: t("termsOfServicePrivacyPage.copyrightComplaints"),
      costsOfArbitration: t("termsOfServicePrivacyPage.costsOfArbitration"),
      disclaimerOfWarranties: t("termsOfServicePrivacyPage.disclaimerOfWarranties"),
      disputeResolutionByBindingArbitration: t(
        "termsOfServicePrivacyPage.disputeResolutionByBindingArbitration"
      ),
      dmcaNotice: t("termsOfServicePrivacyPage.dmcaNotice"),
      dmcaNotificationInfo: t("termsOfServicePrivacyPage.dmcaNotificationInfo"),
      dmcaNotificationIntro: t("termsOfServicePrivacyPage.dmcaNotificationIntro"),
      dmcaSubjectLine: t("termsOfServicePrivacyPage.dmcaSubjectLine"),
      dueDiligence: t("termsOfServicePrivacyPage.dueDiligence"),
      fees: t("termsOfServicePrivacyPage.fees"),
      futureChanges: t("termsOfServicePrivacyPage.futureChanges"),
      generalPracticesRegardingUseAndStorage: t(
        "termsOfServicePrivacyPage.generalPracticesRegardingUseAndStorage"
      ),
      here: t("termsOfServicePrivacyPage.here"),
      importantNotice: t("termsOfServicePrivacyPage.importantNotice"),
      indemnityAndRelease: t("termsOfServicePrivacyPage.indemnityAndRelease"),
      intellectualPropertyRights: t("termsOfServicePrivacyPage.intellectualPropertyRights"),
      interactionsWithOtherUsers: t("termsOfServicePrivacyPage.interactionsWithOtherUsers"),
      liabilityDisclaimer: t("termsOfServicePrivacyPage.liabilityDisclaimer"),
      limitationOfLiability: t("termsOfServicePrivacyPage.limitationOfLiability"),
      memberAccountPasswordAndSecurity: t(
        "termsOfServicePrivacyPage.memberAccountPasswordAndSecurity"
      ),
      mobileServices: t("termsOfServicePrivacyPage.mobileServices"),
      modificationsToService: t("termsOfServicePrivacyPage.modificationsToService"),
      pleaseContactUsAt: t("termsOfServicePrivacyPage.pleaseContactUsAt"),
      powerOfAttorney: t("termsOfServicePrivacyPage.powerOfAttorney"),
      preArbitrationDisputeResolution: t(
        "termsOfServicePrivacyPage.preArbitrationDisputeResolution"
      ),
      preservationDisclosureTerms: t("termsOfServicePrivacyPage.preservationDisclosureTerms"),
      privacyPolicy: t("termsOfServicePrivacyPage.privacyPolicy"),
      proDoctivityPlatform: t("termsOfServicePrivacyPage.proDoctivityPlatform"),
      proDoctivityTrademarks: t("termsOfServicePrivacyPage.proDoctivityTrademarks"),
      professionalAdviceDisclaimer: t("termsOfServicePrivacyPage.professionalAdviceDisclaimer"),
      prohibitedActivities: t("termsOfServicePrivacyPage.prohibitedActivities"),
      prohibitionOfClassActions: t("termsOfServicePrivacyPage.prohibitionOfClassActions"),
      publicity: t("termsOfServicePrivacyPage.publicity"),
      questionsConcernsSuggestions: t("termsOfServicePrivacyPage.questionsConcernsSuggestions"),
      releaseIndemnification: t("termsOfServicePrivacyPage.releaseIndemnification"),
      repeatInfringerPolicy: t("termsOfServicePrivacyPage.repeatInfringerPolicy"),
      reportViolationsOrQuestions: t("termsOfServicePrivacyPage.reportViolationsOrQuestions"),
      reserve: t("termsOfServicePrivacyPage.reserve"),
      reviewThePreviousVersion: t("termsOfServicePrivacyPage.reviewThePreviousVersion"),
      serviceContentSoftwareAndTrademarks: t(
        "termsOfServicePrivacyPage.serviceContentSoftwareAndTrademarks"
      ),
      serviceDisclaimer: t("termsOfServicePrivacyPage.serviceDisclaimer"),
      servicesDescription: t("termsOfServicePrivacyPage.servicesDescription"),
      setoff: t("termsOfServicePrivacyPage.setoff"),
      severability: t("termsOfServicePrivacyPage.severability"),
      socialNetworkingDisclaimer: t("termsOfServicePrivacyPage.socialNetworkingDisclaimer"),
      socialNetworkingServices: t("termsOfServicePrivacyPage.socialNetworkingServices"),
      softwareDistribution: t("termsOfServicePrivacyPage.softwareDistribution"),
      specialNoticeForInternationalUseExportControls: t(
        "termsOfServicePrivacyPage.specialNoticeForInternationalUseExportControls"
      ),
      submissionTerms: t("termsOfServicePrivacyPage.submissionTerms"),
      termination: t("termsOfServicePrivacyPage.termination"),
      terminationOfAccount: t("termsOfServicePrivacyPage.terminationOfAccount"),
      termsAndPrivacy: t("termsOfServicePrivacyPage.termsAndPrivacy"),
      termsIncorporation: t("termsOfServicePrivacyPage.termsIncorporation"),
      termsOfService: t("termsOfServicePrivacyPage.termsOfService"),
      termsOfServiceDescription: t("termsOfServicePrivacyPage.termsOfServiceDescription"),
      termsOfServicePrivacy: t("termsOfServicePrivacyPage.termsOfServicePrivacy"),
      termsPrivacyDataProtection: t("termsOfServicePrivacyPage.termsPrivacyDataProtection"),
      thirdPartyDistributionChannels: t("termsOfServicePrivacyPage.thirdPartyDistributionChannels"),
      thirdPartyLinks: t("termsOfServicePrivacyPage.thirdPartyLinks"),
      thirdPartyMaterial: t("termsOfServicePrivacyPage.thirdPartyMaterial"),
      thirdPartyPrivacy: t("termsOfServicePrivacyPage.thirdPartyPrivacy"),
      thirdPartyServices: t("termsOfServicePrivacyPage.thirdPartyServices"),
      thirdPartyWebsites: t("termsOfServicePrivacyPage.thirdPartyWebsites"),
      updatedMay: t("termsOfServicePrivacyPage.updatedMay"),
      userConduct: t("termsOfServicePrivacyPage.userConduct"),
      userContentTransmittedThroughTheService: t(
        "termsOfServicePrivacyPage.userContentTransmittedThroughTheService"
      ),
      userDisputes: t("termsOfServicePrivacyPage.userDisputes"),
      welcomeToProDoctivity: t("termsOfServicePrivacyPage.welcomeToProDoctivity"),
      yourPrivacy: t("termsOfServicePrivacyPage.yourPrivacy"),
      yourRegistrationObligations: t("termsOfServicePrivacyPage.yourRegistrationObligations"),
    },
    text: t("text"),
    thankYou: t("thankYou"),
    thisFieldCannotBeEmpty: t("thisFieldCannotBeEmpty"),
    thisIsAnError: t("thisIsAnError"),
    thisLinkHasExpired: t("thisLinkHasExpired"),
    to: t("to"),
    toDeleteAccount: t("toDeleteAccount"),
    toDeleteOrganization: t("toDeleteOrganization"),
    toExpire: t("toExpire"),
    topic: t("topic"),
    totalCollectionProgressTitle: t("totalCollectionProgressTitle"),
    totalExpiredCollectionProgressTitle: t("totalExpiredCollectionProgressTitle"),
    totalExpiringCollectionProgressTitle: t("totalExpiringCollectionProgressTitle"),
    totalMissingCollectionProgressTitle: t("totalMissingCollectionProgressTitle"),
    totalSize: t("totalSize"),
    type: t("type"),
    typeToChooseVariable: t("typeToChooseVariable"),
    unableToSignIn: t("unableToSignIn"),
    unassigned: t("unassigned"),
    undoChanges: t("undoChanges"),
    unique: t("unique"),
    unknownEmail: t("unknownEmail"),
    unknownError: t("unknownError"),
    unlimited: t("unlimited"),
    unsubscribe: t("unsubscribe"),
    untilSubscriptionExpires: t("untilSubscriptionExpires"),
    update: t("update"),
    updateAvailable: t("updateAvailable"),
    updatedAt: t("updatedAt"),
    updateDate: t("updateDate"),
    updateDocument: t("updateDocument"),
    updateDocumentBinaries: t("updateDocumentBinaries"),
    updateDocuments: t("updateDocuments"),
    upgradePage: {
      basic: t("upgradePage.basic"),
      contactSales: t("upgradePage.contactSales"),
      currentField: t("upgradePage.currentField"),
      currentPlan: t("upgradePage.currentPlan"),
      enterprise: t("upgradePage.enterprise"),
      exploreFeatures: t("upgradePage.exploreFeatures"),
      month: t("upgradePage.month"),
      monthly: t("upgradePage.monthly"),
      plansPricing: t("upgradePage.plansPricing"),
      professional: t("upgradePage.professional"),
      save15: t("upgradePage.save15"),
      usingBasic: t("upgradePage.usingBasic"),
      year: t("upgradePage.year"),
      yearly: t("upgradePage.yearly"),
    },
    upgradePlan: t("upgradePlan"),
    uploadFile: t("uploadFile"),
    uploadFiles: t("uploadFiles"),
    user: t("user"),
    userHasntAcceptedInvitation: t("userHasntAcceptedInvitation"),
    userManagementAndRoles: {
      accessConstraint: {
        accessConstraintLabel: t("userManagementAndRoles.accessConstraint.accessConstraintLabel"),
        dateRange: t("userManagementAndRoles.accessConstraint.dateRange"),
        limitDate: t("userManagementAndRoles.accessConstraint.limitDate"),
        unrestricted: t("userManagementAndRoles.accessConstraint.unrestricted"),
      },
      identificationType: {
        IdentityDocumentEsDo: t("userManagementAndRoles.identificationType.IdentityDocumentEsDo"),
        Other: t("userManagementAndRoles.identificationType.Other"),
        Passport: t("userManagementAndRoles.identificationType.Passport"),
        TaxpayerIdentificationNumberEsDo: t(
          "userManagementAndRoles.identificationType.TaxpayerIdentificationNumberEsDo"
        ),
      },
      identificationTypeLabel: t("userManagementAndRoles.identificationTypeLabel"),
      onlySeeOwnedDocs: t("userManagementAndRoles.onlySeeOwnedDocs"),
      placeHolderUserName: t("userManagementAndRoles.placeHolderUserName"),
      positionUser: t("userManagementAndRoles.positionUser"),
      requiredFieldErrors: {
        assignedRoleInvalid: t("userManagementAndRoles.requiredFieldErrors.assignedRoleInvalid"),
        noFirstName: t("userManagementAndRoles.requiredFieldErrors.noFirstName"),
        noIdentification: t("userManagementAndRoles.requiredFieldErrors.noIdentification"),
        noIdentificationType: t("userManagementAndRoles.requiredFieldErrors.noIdentificationType"),
        noLanguage: t("userManagementAndRoles.requiredFieldErrors.noLanguage"),
        noLastName: t("userManagementAndRoles.requiredFieldErrors.noLastName"),
        noPhone: t("userManagementAndRoles.requiredFieldErrors.noPhone"),
        noPosition: t("userManagementAndRoles.requiredFieldErrors.noPosition"),
      },
      saveMessages: {
        failedToSave: t("userManagementAndRoles.saveMessages.failedToSave"),
        successfullySaved: t("userManagementAndRoles.saveMessages.successfullySaved"),
      },
      specificScheduleTime: t("userManagementAndRoles.specificScheduleTime"),
      userData: t("userManagementAndRoles.userData"),
      userRoles: {
        availableRoles: t("userManagementAndRoles.userRoles.availableRoles"),
        selectedRoles: t("userManagementAndRoles.userRoles.selectedRoles"),
      },
    },
    users: t("users"),
    valid: t("valid"),
    validations: {
      couldntSendEmail: t("validations.couldntSendEmail"),
      invalidPhoneNumber: t("validations.invalidPhoneNumber"),
      invitationNotSentUserAlreadyExists: t("validations.invitationNotSentUserAlreadyExists"),
    },
    value: t("value"),
    variableDetails: t("variableDetails"),
    variableList: t("variableList"),
    variableName: t("variableName"),
    variables: t("variables"),
    verifyAccount: t("verifyAccount"),
    version: t("version"),
    versionHistory: t("versionHistory"),
    versions: t("versions"),
    view: t("view"),
    viewActivities: t("viewActivities"),
    viewAllUserLists: t("viewAllUserLists"),
    viewDocument: t("viewDocument"),
    viewMyLists: t("viewMyLists"),
    viewOfDocumentCollectionAssignedByUser: t("viewOfDocumentCollectionAssignedByUser"),
    voided: t("voided"),
    warning: t("warning"),
    waterMark: t("waterMark"),
    webAuthor: t("webAuthor"),
    website: t("website"),
    welcome: t("welcome"),
    whatsThis: t("whatsThis"),
    whyDoYouWantToDeleteYourAccount: t("whyDoYouWantToDeleteYourAccount"),
    whyDoYouWantToDeleteYourOrganization: t("whyDoYouWantToDeleteYourOrganization"),
    withoutSorting: t("withoutSorting"),
    workflow: {
      claim: t("workflow.claim"),
      dueDate: t("workflow.dueDate"),
      errorModifyingTask: t("workflow.errorModifyingTask"),
      finishBy: t("workflow.finishBy"),
      finished: t("workflow.finished"),
      inbox: t("workflow.inbox"),
      noTaskComments: t("workflow.noTaskComments"),
      priority: t("workflow.priority"),
      processDetails: t("workflow.processDetails"),
      processId: t("workflow.processId"),
      processName: t("workflow.processName"),
      send: t("workflow.send"),
      startedAt: t("workflow.startedAt"),
      taskCreationDate: t("workflow.taskCreationDate"),
      taskId: t("workflow.taskId"),
      taskInformation: t("workflow.taskInformation"),
      taskNumber: t("workflow.taskNumber"),
      unclaimed: t("workflow.unclaimed"),
      workflow: t("workflow.workflow"),
      workflowProcessId: t("workflow.workflowProcessId"),
      workflowTaskId: t("workflow.workflowTaskId"),
      workflowType: t("workflow.workflowType"),
    },
    workflowProcess: t("workflowProcess"),
    workflowProcessAlreadySelected: t("workflowProcessAlreadySelected"),
    yes: t("yes"),
    yesDelete: t("yesDelete"),
    yesModify: t("yesModify"),
    yesRestore: t("yesRestore"),
    youAreAboutToDelete: t("youAreAboutToDelete"),
    youAreAboutToDeleteAllDocumentsFromThisList: t("youAreAboutToDeleteAllDocumentsFromThisList"),
    youAreAboutToDeleteConfirmation: t("youAreAboutToDeleteConfirmation"),
    youAreAboutToDeleteThisApprovalType: t("youAreAboutToDeleteThisApprovalType"),
    youAreAboutToDeleteThisDocument: t("youAreAboutToDeleteThisDocument"),
    youAreAboutToDeleteThisGraphicMark: t("youAreAboutToDeleteThisGraphicMark"),
    youAreAboutToDeleteThisList: t("youAreAboutToDeleteThisList"),
    youAreAboutToDeleteThisRole: t("youAreAboutToDeleteThisRole"),
    youAreAboutToRestoreThisDocument: t("youAreAboutToRestoreThisDocument"),
    youHaveActivatedYourAccount: t("youHaveActivatedYourAccount"),
    youHaveBeenSentAnEmailSetPwd: t("youHaveBeenSentAnEmailSetPwd"),
    your: t("your"),
  } as const;

  return root;
}
