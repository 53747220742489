import type {
  DataType,
  StringTemplatePart,
  VariablePart,
} from "@prodoctivity/shared/src/index-types";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { areArraysEqual, shouldNever } from "@prodoctivity/shared";

import { Box, BoxWithRef } from "../Box";
import { StringTemplateBuilderEventManager } from "./StringTemplateBuilderEventManager";
import { StringTemplateBuilderLine } from "./StringTemplateBuilderLine";
import { useColors } from "../ColorSchemeProvider";
import { Text } from "../Text";

export type StringTemplateBuilderState = {
  parts: StringTemplatePart[];
  hoveredLineIndex: number | undefined;
  hoveredPartIndex: number | undefined;
  preventClose: boolean;
  // waitingForUpdates: boolean;
};

export type Msg =
  | {
      type: "remove-part";
      lineIndex: number;
      partIndex: number;
    }
  | { type: "part-hovered"; lineIndex: number; partIndex: number }
  | { type: "reset-hover" }
  | { type: "add-text-part"; lineIndex: number; partIndex: number }
  | {
      type: "add-variable-part";
      lineIndex: number;
      partIndex: number;
      name: string;
      dataType: DataType;
    }
  | { type: "add-new-line-part"; lineIndex: number; partIndex: number }
  | { type: "edit-variable-part"; lineIndex: number; partIndex: number; part: VariablePart }
  | { type: "edit-text-part"; lineIndex: number; partIndex: number; newValue: string }
  | { type: "prevent-close"; value: boolean }
  | { type: "wait-for-updates"; value: boolean }
  | { type: "update-parts"; parts: StringTemplatePart[] }
  | { type: "reset-status" };

export type StringTemplateBuilderProps = {
  initialState?: { parts: StringTemplatePart[] };
  inline?: boolean;
  eventManager?: StringTemplateBuilderEventManager;
  onChange(state: { parts: StringTemplatePart[] }): void;
  onPartClicked(args: { lineIndex: number; partIndex: number; part: StringTemplatePart }): void;
  onVariableAdded(args: {
    lineIndex: number;
    partIndex: number;
    name: string;
    dataType: DataType;
  }): void;
  getNewVariableData(): { name: string; dataType: DataType };
  getFieldLabel(fldName: string): string;
  warningLabel: string | undefined;
};

function reducer(prevState: StringTemplateBuilderState, msg: Msg): StringTemplateBuilderState {
  switch (msg.type) {
    case "remove-part": {
      const lines = removeLinesPart(getLines(prevState.parts), msg.lineIndex, msg.partIndex);
      const newParts = getStateParts(lines);
      return {
        parts: newParts,
        hoveredLineIndex: prevState.hoveredLineIndex,
        hoveredPartIndex: prevState.hoveredPartIndex,
        preventClose: prevState.preventClose,
        // waitingForUpdates: false,
      };
    }
    case "part-hovered": {
      return {
        parts: prevState.parts,
        hoveredLineIndex: msg.lineIndex,
        hoveredPartIndex: msg.partIndex,
        preventClose: prevState.preventClose,
        // waitingForUpdates: prevState.waitingForUpdates,
      };
    }
    case "reset-hover": {
      return {
        parts: prevState.parts,
        hoveredLineIndex: undefined,
        hoveredPartIndex: undefined,
        preventClose: prevState.preventClose,
        // waitingForUpdates: prevState.waitingForUpdates,
      };
    }
    case "add-text-part": {
      const lines = addLineTextPart(getLines(prevState.parts), msg.lineIndex, msg.partIndex);
      const newParts = getStateParts(lines);
      return {
        parts: newParts,
        hoveredLineIndex: prevState.hoveredLineIndex,
        hoveredPartIndex: prevState.hoveredPartIndex,
        preventClose: prevState.preventClose,
        // waitingForUpdates: false,
      };
    }
    case "add-variable-part": {
      const lines = addLineVariablePart(
        getLines(prevState.parts),
        msg.lineIndex,
        msg.partIndex,
        msg.name,
        msg.dataType
      );
      const newParts = getStateParts(lines);
      return {
        parts: newParts,
        hoveredLineIndex: prevState.hoveredLineIndex,
        hoveredPartIndex: prevState.hoveredPartIndex,
        preventClose: prevState.preventClose,
        // waitingForUpdates: false,
      };
    }
    case "add-new-line-part": {
      const lines = addNewLineTextPart(getLines(prevState.parts), msg.lineIndex, msg.partIndex);
      const newParts = getStateParts(lines);
      return {
        parts: newParts,
        hoveredLineIndex: prevState.hoveredLineIndex,
        hoveredPartIndex: prevState.hoveredPartIndex,
        preventClose: prevState.preventClose,
        // waitingForUpdates: false,
      };
    }
    case "edit-variable-part": {
      const lines = getLines(prevState.parts);
      const part = lines[msg.lineIndex].parts[msg.partIndex];
      if (part && part.type === "variable") {
        Object.keys(msg.part).forEach((key) => {
          const tgtObject: Record<string, unknown> = part;
          const srcObject: Record<string, unknown> = msg.part;
          tgtObject[key] = srcObject[key];
        });
      }
      const newParts = getStateParts(lines);
      return {
        parts: newParts,
        hoveredLineIndex: prevState.hoveredLineIndex,
        hoveredPartIndex: prevState.hoveredPartIndex,
        preventClose: prevState.preventClose,
        // waitingForUpdates: false,
      };
    }
    case "edit-text-part": {
      const lines = getLines(prevState.parts);
      const part = lines[msg.lineIndex].parts[msg.partIndex];
      if (part && part.type === "text") {
        part.value = msg.newValue;
      }
      const newParts = getStateParts(lines);
      return {
        parts: newParts,
        hoveredLineIndex: prevState.hoveredLineIndex,
        hoveredPartIndex: prevState.hoveredPartIndex,
        preventClose: prevState.preventClose,
        // waitingForUpdates: false,
      };
    }
    case "prevent-close": {
      return {
        parts: prevState.parts,
        hoveredLineIndex: !msg.value ? undefined : prevState.hoveredLineIndex,
        hoveredPartIndex: !msg.value ? undefined : prevState.hoveredPartIndex,
        preventClose: msg.value,
        // waitingForUpdates: prevState.waitingForUpdates,
      };
    }
    case "update-parts": {
      return {
        ...prevState,
        parts: msg.parts,
        // waitingForUpdates: false,
      };
    }
    case "wait-for-updates": {
      return {
        ...prevState,
        // waitingForUpdates: true,
      };
    }
    case "reset-status": {
      return {
        ...prevState,
        // waitingForUpdates: false,
      };
    }
    default:
      shouldNever(msg);
      return prevState;
  }
}

type RenderedLine = {
  parts: StringTemplatePart[];
};

export const StringTemplateBuilder: FunctionComponent<StringTemplateBuilderProps> = ({
  initialState,
  inline,
  eventManager,
  onChange,
  onPartClicked,
  onVariableAdded,
  getNewVariableData,
  getFieldLabel,
  warningLabel,
}) => {
  const { colors } = useColors();

  const rootElementRef = useRef<HTMLDivElement | null>(null);
  const [state, dispatch] = useReducer(reducer, {
    parts: [],
    ...(initialState || {}),
    hoveredLineIndex: undefined,
    hoveredPartIndex: undefined,
    preventClose: false,
    // waitingForUpdates: false,
  });
  const lines = useMemo(() => {
    const result = getLines(state.parts);
    return result;
  }, [state.parts]);
  useEffect(() => {
    const newStateParts = getStateParts(lines);
    if (!areArraysEqual((initialState || { parts: [] }).parts, newStateParts)) {
      onChange({
        parts: newStateParts.filter((v) => {
          return v.type === "variable" || (v.type === "text" && v.value);
        }),
      });
    }
  }, [initialState, lines, onChange]);

  const hoverPart = useCallback(
    (lineIndex: number, partIndex: number) => {
      if (!state.preventClose) {
        dispatch({
          type: "part-hovered",
          lineIndex,
          partIndex,
        });
      }
    },
    [state.preventClose]
  );

  const handlePartClicked = useCallback(
    (lineIndex: number, partIndex: number, newPart: StringTemplatePart | undefined) => {
      dispatch({
        type: "wait-for-updates",
        value: true,
      });
      const lines = getLines(state.parts);
      const part = lines[lineIndex].parts[partIndex];
      const parts = newPart ? newPart : part;
      onPartClicked({ lineIndex, partIndex, part: parts });
    },
    [onPartClicked, state.parts]
  );

  const handleVariableAdded = useCallback(
    (lineIndex: number, partIndex: number, name: string, dataType: DataType) => {
      onVariableAdded({
        lineIndex,
        partIndex,
        name,
        dataType,
      });
      const part: StringTemplatePart = {
        type: "variable",
        name,
        dataType,
      };

      handlePartClicked(lineIndex, partIndex, part);
    },

    [onVariableAdded, handlePartClicked]
  );

  const clearHover: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (
        !state.preventClose &&
        rootElementRef.current &&
        rootElementRef.current === event.target
      ) {
        dispatch({
          type: "reset-hover",
        });
      }
    },
    [state.preventClose]
  );
  useLayoutEffect(() => {
    if (rootElementRef.current) {
      rootElementRef.current.classList.add("string-template-builder");
    }
  }, []);

  useEffect(() => {
    if (eventManager) {
      return eventManager.subscribe((command) => {
        switch (command.type) {
          case "variable-name-change":
            const old = command.oldVariableName;
            const newParts = state.parts.map((part) => {
              if (part.type === "variable" && part.name === old) {
                const newPart: StringTemplatePart = {
                  ...part,
                  name: command.newVariableName,
                };
                return newPart;
              } else {
                return part;
              }
            });
            dispatch({
              type: "update-parts",
              parts: newParts,
            });
            break;
          case "edit-variable-part":
            // if (state.waitingForUpdates) {
            dispatch({
              type: "edit-variable-part",
              lineIndex: command.lineIndex,
              partIndex: command.partIndex,
              part: command.part,
            });
            // }
            break;
          case "reset-status":
            dispatch({
              type: "reset-status",
            });

            break;
          case "add-variable-to-list":
          case "edit-variable-list":
            break;
          default:
            shouldNever(command);
        }
      });
    }
  }, [eventManager, state.parts /*, state.waitingForUpdates*/]);

  const duplicateWarning = useMemo(() => {
    const variableNames: Record<string, boolean> = {};
    const duplicates: string[] = [];
    state.parts.forEach((part) => {
      if (part.type === "variable") {
        if (variableNames[part.name]) {
          duplicates.push(part.name);
        } else {
          variableNames[part.name] = true;
        }
      }
    });
    return duplicates;
  }, [state.parts]);

  return (
    <>
      <BoxWithRef
        ref={rootElementRef}
        color={colors.white}
        borderStyle="sm"
        borderRadius={4}
        padding={1}
        onMouseOutCapture={clearHover}
        onMouseOver={() => {
          if (lines.length === 1 && lines[0].parts.length === 1) {
            hoverPart(0, 0);
          }
        }}
        display="flex"
        direction="column"
      >
        {lines.map((line, lineIndex) => {
          const parts = line.parts;
          return (
            <StringTemplateBuilderLine
              key={lineIndex}
              inline={inline}
              parts={parts}
              lineIndex={lineIndex}
              hoveredLineIndex={state.hoveredLineIndex}
              hoveredPartIndex={state.hoveredPartIndex}
              hoverPart={hoverPart}
              onPartClicked={handlePartClicked}
              onVariableAdded={handleVariableAdded}
              dispatch={dispatch}
              getNewVariableData={getNewVariableData}
              getFieldLabel={getFieldLabel}
            />
          );
        })}
      </BoxWithRef>
      <Box>
        {duplicateWarning.length > 0 && (
          <Text size="100" color={colors.secondary}>
            {warningLabel}
          </Text>
        )}
      </Box>
    </>
  );
};

function getLines(parts: StringTemplatePart[]): RenderedLine[] {
  const result: RenderedLine[] = [];
  let currentLine: RenderedLine = {
    parts: [],
  };

  parts.forEach((part) => {
    switch (part.type) {
      case "variable": {
        currentLine.parts.push(part);
        break;
      }
      case "text": {
        if (part.value === "\n") {
          result.push(currentLine);
          currentLine = {
            parts: [],
          };
        } else {
          const splitValues = part.value.split("\n");

          if (splitValues.length === 1) {
            currentLine.parts.push(part);
          } else {
            splitValues.forEach((child, childIndex) => {
              currentLine.parts.push({
                type: "text",
                value: child,
              });

              if (childIndex < splitValues.length - 1) {
                result.push(currentLine);
                currentLine = {
                  parts: [],
                };
              }
            });
          }
        }
        break;
      }
    }
  });
  result.push(currentLine);

  return result;
}

function getStateParts(lines: RenderedLine[]): StringTemplatePart[] {
  const result: StringTemplatePart[] = [];
  let lastPart: StringTemplatePart | undefined = undefined;

  lines.forEach((line, lineIdx) => {
    if (lineIdx > 0) {
      if (lastPart && lastPart.type === "text") {
        lastPart.value += `
`;
      } else {
        if (lastPart) {
          result.push(lastPart);
        }
        const newLinePart: StringTemplatePart = {
          type: "text",
          value: `
`,
        };
        lastPart = newLinePart;
      }
    }
    line.parts.forEach((part) => {
      if (part.type === "text") {
        if (part.value === "\n") {
          if (lastPart) {
            result.push(lastPart);
          }
          lastPart = part;
        } else {
          const partLines = part.value.split("\n");
          if (partLines.length === 1) {
            const onlyItem = partLines[0];
            if (lastPart && lastPart.type === "text") {
              lastPart.value += onlyItem;
            } else {
              if (lastPart) {
                result.push(lastPart);
              }
              lastPart = {
                type: "text",
                value: onlyItem,
              };
            }
          } else {
            partLines.forEach((partLine, partLineIndex) => {
              if (partLineIndex > 0) {
                if (lastPart) {
                  if (lastPart.type === "text") {
                    lastPart.value += `
`;
                  } else {
                    result.push(lastPart);
                    lastPart = {
                      type: "text",
                      value: `
`,
                    };
                  }
                }
              }
              if (lastPart && lastPart.type === "text") {
                lastPart.value += partLine;
                if (partLineIndex < partLines.length - 1) {
                  lastPart = undefined;
                }
              } else {
                if (lastPart) {
                  result.push(lastPart);
                }
                lastPart = part;
              }
            });
          }
        }
      } else {
        if (lastPart) {
          result.push(lastPart);
        }
        lastPart = part;
      }
    });
  });
  if (lastPart) {
    result.push(lastPart);
  }

  return result;
}
function removeLinesPart(lines: RenderedLine[], lineIndex: number, partIndex: number) {
  const foundLine = lines[lineIndex];
  if (foundLine) {
    const parts = [...foundLine.parts.slice(0, partIndex), ...foundLine.parts.slice(partIndex + 1)];
    return [...lines.slice(0, lineIndex), { parts }, ...lines.slice(lineIndex + 1)];
  }
  return lines;
}
function addLineTextPart(lines: RenderedLine[], lineIndex: number, partIndex: number) {
  const foundLine = lines[lineIndex];
  if (foundLine) {
    const textPart: StringTemplatePart = { type: "text", value: "<text>" };
    const parts = [
      ...foundLine.parts.slice(0, partIndex),
      textPart,
      ...foundLine.parts.slice(partIndex),
    ];
    const result = [...lines.slice(0, lineIndex), { parts }, ...lines.slice(lineIndex + 1)];
    return result;
  }
  return lines;
}
function addLineVariablePart(
  lines: RenderedLine[],
  lineIndex: number,
  partIndex: number,
  newVariableName: string,
  newVariableDataType: DataType
) {
  const foundLine = lines[lineIndex];
  if (foundLine) {
    const textPart: StringTemplatePart = {
      type: "variable",
      dataType: newVariableDataType,
      name: newVariableName,
    };
    const parts = [
      ...foundLine.parts.slice(0, partIndex),
      textPart,
      ...foundLine.parts.slice(partIndex),
    ];
    return [...lines.slice(0, lineIndex), { parts }, ...lines.slice(lineIndex + 1)];
  }
  return lines;
}
function addNewLineTextPart(lines: RenderedLine[], lineIndex: number, partIndex: number) {
  const foundLine = lines[lineIndex];
  if (foundLine) {
    const textPart: StringTemplatePart = {
      type: "text",
      value: `
`,
    };
    const parts = [
      ...foundLine.parts.slice(0, partIndex),
      textPart,
      ...foundLine.parts.slice(partIndex),
    ];
    return [...lines.slice(0, lineIndex), { parts }, ...lines.slice(lineIndex + 1)];
  }
  return lines;
}
