import { Box, Button, ComboBox, Text, useColors } from "@prodoctivity/design-system";
import { DocumentCollectionState, useRelationshipRow } from "../../hooks";

import { ComboBoxProps } from "@prodoctivity/design-system/components/ComboBox";
import type { DataType } from "@prodoctivity/shared/src/index-types";
import type { DocumentCollectionConfigRelationship } from "@prodoctivity/types";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import { DocumentTypeRelation } from "./DocumentTypeRelation";
import { SmallAccordion } from "./SmallAccordion";

type RelationShipRowProps = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  formState: DocumentCollectionState;
  relation: DocumentCollectionConfigRelationship<string>;
  index: number;
  masterSourceFields: Array<{ label: string; value: string; dataType: DataType }>;
  documentTypeListAvailable: ComboBoxProps["options"];
  documentTypesOptions: ComboBoxProps["options"];
  setFormState: React.Dispatch<React.SetStateAction<DocumentCollectionState>>;
  onRemoveRelationship: (indexRemoved?: number | undefined) => void;
};

export const RelationshipRow = ({
  formState,
  resources,
  masterSourceFields,
  relation,
  index,
  documentTypeListAvailable,
  documentTypesOptions,
  setFormState,
  onRemoveRelationship,
}: RelationShipRowProps) => {
  const {
    filteredFields,
    currentSourceField,
    onChangeDocumentType,
    onChangeTargetField,
    onRemoveDocTypeRelation,
    onChangeSourceField,
    open,
    setOpen,
    onCancel,
    onNew,
  } = useRelationshipRow({
    relationIndex: index || 0,
    isNew: relation.documentTypes.length === 0,
    formState,
    documentTypeListAvailable,
    masterSourceFields,
    relation,
    setFormState,
  });

  const { colors } = useColors();

  return (
    <Box display="flex" direction="column" flex="shrink" width={"100%"} borderRadius={4}>
      <Box
        display="flex"
        direction="column"
        flex="shrink"
        width={"100%"}
        borderColor={colors.subtle}
      >
        <SmallAccordion
          resources={resources}
          label={currentSourceField?.label}
          isActive={open}
          currIndex={index}
          setActive={
            !open
              ? setOpen
              : (active: boolean) => {
                  setOpen(active);
                  onCancel();
                }
          }
          remove={onRemoveRelationship}
        >
          <Box paddingY={4} paddingLeft={4} color={colors.neutral300}>
            <Box>
              <Box marginBottom={3}>
                <Text color={colors.neutral900}>{resources.documentCollection.sourceField}</Text>
              </Box>
              <Box display="flex" direction="row" gap={2}>
                <Box display="flex" direction="column" flex="shrink" flexValue={3}>
                  <ComboBox
                    id={`source-field-${index}`}
                    options={filteredFields}
                    label={""}
                    selectedOption={currentSourceField}
                    placeholder={currentSourceField?.label}
                    inputValue={currentSourceField?.label}
                    onClear={() => onChangeSourceField("")}
                    onSelect={(e) => onChangeSourceField(e.item.value || "")}
                    onChange={(e) => {
                      const field = filteredFields.find((f) => f.label === e.value);
                      if (field) {
                        onChangeSourceField(field.value);
                      }
                    }}
                  />
                </Box>
                <Box display="flex" direction="column" flexValue={7}>
                  {relation.documentTypes.map((d, dIndex) => {
                    return (
                      <DocumentTypeRelation
                        key={dIndex}
                        resources={resources}
                        docTypeIndex={dIndex}
                        formState={formState}
                        relation={relation}
                        docTypeRelation={d}
                        documentTypesOptions={documentTypesOptions}
                        onChangeDocumentType={onChangeDocumentType}
                        onChangeTargetField={onChangeTargetField}
                        onRemove={onRemoveDocTypeRelation}
                      />
                    );
                  })}

                  <Box display="flex" direction="row" flex="grow" paddingTop={4}>
                    {documentTypeListAvailable.length === 0 ? (
                      <Box>
                        <Text size="200" color={colors.subtle}>
                          {resources.documentCollection.noDocumentTypesAvailable}
                        </Text>
                      </Box>
                    ) : (
                      <>
                        <Box display="flex" direction="row" flex="grow" />
                        <Box display="flex" direction="row" flex="shrink">
                          <Button
                            color="gray"
                            onClick={onNew}
                            text={resources.newDocumentType}
                            iconEnd="board"
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </SmallAccordion>
      </Box>
    </Box>
  );
};
