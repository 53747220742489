import { AbstractEventHandler, RadioButton as GestaltRadioButton, RadioButtonProps } from "gestalt";
import { FunctionComponent, useCallback, useEffect, useState } from "react";

import { Box } from "./Box";
import { Grid } from "./layout";
import { checkBoxGroupGridTemplateColumns } from "./Checkbox";

export function RadioButton(props: RadioButtonProps) {
  return <GestaltRadioButton {...props} />;
}

type RadioButtonGroupProps = {
  id: string;
  name: string;
  options: Array<{ label: string; value: string }>;
  disabled?: boolean;
  value: string;
  size?: RadioButtonProps["size"];
  gap?: number;
  onChange?(value: string): void;
};

export const RadioButtonGroup: FunctionComponent<RadioButtonGroupProps> = ({
  id,
  name,
  options,
  disabled,
  value,
  size,
  gap = 2,
  onChange,
}) => {
  const [radioButtonValue, setRadioButtonValue] = useState<string>("");
  useEffect(() => {
    setRadioButtonValue(value);
  }, [value]);
  const radioButtonGroupChanged = useCallback<
    AbstractEventHandler<React.SyntheticEvent<HTMLInputElement>, { checked: boolean }>
  >(
    (args) => {
      const value = args.event.currentTarget.value;
      setRadioButtonValue(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );
  return (
    <Box display="flex" direction="column">
      <Grid gap={gap} gridTemplateColumns={checkBoxGroupGridTemplateColumns}>
        {options.map((option) => {
          return (
            <RadioButton
              id={`${id}_${option.value.replace(/ /g, "")}`}
              key={option.value}
              disabled={disabled}
              checked={radioButtonValue === option.value}
              value={option.value}
              onChange={radioButtonGroupChanged}
              label={option.label}
              name={name}
              size={size || "sm"}
            ></RadioButton>
          );
        })}
      </Grid>
      <Box margin={2} />
    </Box>
  );
};
