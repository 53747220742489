import {
  Box,
  ComboBox,
  Text,
  TextField,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction } from "react";

import { ComboBoxProps } from "@prodoctivity/design-system/components/ComboBox";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { DocumentCollectionState } from "../hooks";

type Props = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  isLoading: boolean;
  formState: DocumentCollectionState;
  documentTypesOptions: ComboBoxProps["options"];
  setFormState: Dispatch<SetStateAction<DocumentCollectionState>>;
};

export const ConformationStep: FunctionComponent<Props> = ({
  resources,
  isLoading,
  formState,
  documentTypesOptions,
  setFormState,
}) => {
  const { colors } = useColors();

  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box
      paddingY={8}
      paddingX={6}
      marginTop={3}
      color={colors.white}
      borderStyle="lg"
      borderRadius={4}
    >
      <Box
        display="flex"
        justifyContent="center"
        width={breakpoint === "small" ? "80%" : undefined}
        paddingX={breakpoint === "small" ? undefined : 6}
        gap={5}
      >
        <Box
          display="flex"
          width={breakpoint === "small" ? undefined : "80%"}
          flex="grow"
          direction="column"
          paddingX={breakpoint === "small" ? undefined : 6}
        >
          <Box minHeight={65}>
            <Box marginBottom={3} width="100%">
              <Text color={colors.neutral900}>{resources.name}</Text>
            </Box>
            <Box width="100%">
              <TextField
                placeholder={resources.name}
                id="name"
                value={formState.name}
                disabled={isLoading}
                onChange={(e) => setFormState((prev) => ({ ...prev, name: e.value }))}
                errorMessage={formState.errors?.["name"]}
              />
            </Box>
          </Box>
          <Box minHeight={65}>
            <Box marginBottom={3} width="100%">
              <Text color={colors.neutral900}>
                {resources.documentCollection.masterDocumentType}
              </Text>
            </Box>
            <Box width={"100%"}>
              <ComboBox
                id="masterDocumentTypeId"
                label=""
                disabled={isLoading}
                size="lg"
                options={documentTypesOptions}
                onSelect={(e) =>
                  setFormState((prev) => ({ ...prev, masterDocumentTypeId: e.item.value }))
                }
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    masterDocumentTypeId: documentTypesOptions.find((dt) => dt.label === e.value)
                      ?.value,
                  }))
                }
                onClear={() =>
                  setFormState((prev) => ({ ...prev, masterDocumentTypeId: undefined }))
                }
                placeholder={
                  documentTypesOptions.find((dt) => dt.value === formState.masterDocumentTypeId)
                    ?.label
                }
                inputValue={
                  documentTypesOptions.find((dt) => dt.value === formState.masterDocumentTypeId)
                    ?.label
                }
                selectedOption={documentTypesOptions.find(
                  (dt) => dt.value === formState.masterDocumentTypeId
                )}
                errorMessage={formState.errors?.["masterDocumentTypeId"]}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
