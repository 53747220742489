import { ColorConfig, calculateColor } from "../../utils";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const ViewerCollapseSidePanelIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84421 8.63149C8.10591 9.23556 7.90455 11.5847 8.03879 19.9074C8.10591 27.4246 8.37438 30.512 8.97845 31.1161C9.58251 31.7202 12.8713 31.9215 20.9255 31.7873L28.0485 31.7009C30.2385 31.6744 32 29.8915 32 27.7012V12.0921C32 9.91032 30.242 8.13005 28.0605 8.09674C12.396 7.85759 9.46643 8.0715 8.84421 8.63149ZM10.8578 29.3041H14.8849V10.511H10.8578V29.3041ZM17.5696 29.3041H29.6509V10.511H17.5696V29.3041Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
    </svg>
  );
};
