import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const BackToTaskListSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 32,
  height = 26,
  onClick,
  color,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 32 26`}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clip-path="url(#clip0_13673_7963)">
        <path
          d="M31.8724 2.51225C31.4901 1.06981 30.123 0 28.6863 0C28.2577 0 27.829 0 27.3887 0C21.5842 0 15.7797 0 9.97525 0C8.83984 0 7.94774 0.492834 7.25259 1.39436C6.85867 1.89921 6.56903 2.46417 6.55744 3.11327C6.55744 3.41378 6.54585 3.65418 6.54585 3.84651H6.49951V9.37587H8.75874C8.75874 7.06796 8.75874 4.49561 8.75874 4.2552C8.74716 3.07721 9.21059 2.38003 9.72037 2.38003C10.7283 2.38003 11.6204 2.34397 12.64 2.33195C14.5516 2.30791 16.4749 2.29589 18.3981 2.31993C21.8739 2.34397 25.338 2.39205 28.8138 2.44013C29.4162 2.44013 29.7291 2.78872 29.7291 3.40176C29.7291 3.57004 29.7291 3.70227 29.7406 3.84651H29.6943C29.7059 7.10402 29.7291 19.3407 29.7522 21.5044C29.7522 21.5044 29.7406 21.5164 29.7522 21.5405C29.7406 21.5765 29.7406 21.6486 29.7406 21.7448C29.7291 22.5261 29.6827 22.1054 29.6132 22.8867C29.5784 23.2834 29.2656 23.5358 28.8949 23.5599C27.8869 23.608 26.8674 23.6681 25.8594 23.6681C23.9477 23.6921 22.0245 23.7041 20.1012 23.6681C16.6255 23.644 13.1498 23.5959 9.68561 23.5479C9.08315 23.5479 8.77033 23.1993 8.77033 22.5862C8.75874 21.6246 8.75874 19.8336 8.77033 17.73H6.49951V22.8386C6.52268 23.055 6.56903 23.2714 6.62696 23.4877C7.00929 24.9182 8.36483 25.976 9.80147 25.976C10.2417 25.976 10.6704 25.988 11.1107 25.988C16.9152 25.988 22.7196 25.976 28.5241 25.988C29.6595 25.988 30.5401 25.4951 31.2468 24.5936C31.6407 24.1008 31.9304 23.5238 31.9419 22.8747C31.9767 20.7231 31.9883 21.7809 31.9999 19.9778V3.14933C31.9651 2.93296 31.9304 2.7166 31.8724 2.51225Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M14.4937 12.9098C14.8297 13.1262 15.1773 13.1502 15.5017 12.8858C15.745 12.6935 15.9999 12.5011 16.2084 12.2848C16.9036 11.5515 17.6103 10.8183 18.2707 10.049C18.6299 9.62828 18.5372 8.96716 18.1664 8.57049C17.8073 8.18584 17.1585 8.11372 16.7414 8.46231C16.3243 8.8109 15.9304 9.20757 15.5365 9.59222C15.0383 10.0851 15.0499 10.0851 14.4706 9.68839C14.0766 9.42394 13.6711 9.30373 13.2425 9.60424C12.9065 9.84465 12.6748 10.1331 12.6284 10.6019C12.7095 10.8183 12.7559 11.0467 12.8717 11.239C13.3004 11.9121 13.8333 12.4771 14.5053 12.8978L14.4937 12.9098Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M16.6719 14.6889C16.2895 15.0255 15.9304 15.374 15.5712 15.7347C15.0267 16.2756 15.0151 16.2876 14.4126 15.8428C14.0419 15.5784 13.6364 15.5784 13.2888 15.7587C12.9181 15.963 12.6284 16.3116 12.6284 16.8285C12.6979 17.0088 12.7211 17.2252 12.837 17.3574C13.3352 17.9103 13.8218 18.4753 14.3779 18.9681C14.7834 19.3287 15.2932 19.3167 15.6871 18.9321C16.5676 18.0666 17.4365 17.1651 18.2707 16.2395C18.6762 15.7948 18.5951 15.1336 18.1317 14.7249C17.5871 14.2441 17.1585 14.2441 16.6719 14.6648V14.6889Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M25.9522 9.72461H20.5532V12.4172H25.9522V9.72461Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M25.9522 15.458H20.5532V18.1506H25.9522V15.458Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M5.00507 19.2079L2.2013 16.1306L1.62201 15.5056L0.13903 13.8828C-0.0463432 13.6785 -0.0463432 13.366 0.13903 13.1736L1.61043 11.587L2.18972 10.9739L3.11658 9.97623L3.68429 9.37522L5.10934 7.84863L5.08617 10.5773H10.1028C10.3809 10.5773 10.601 10.8056 10.601 11.0941V16.0225C10.601 16.311 10.3809 16.5393 10.1028 16.5393H5.03983L4.99348 19.2079H5.00507Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_13673_7963">
          <rect width="32" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
