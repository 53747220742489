import "./Group.css";

import {
  Box,
  Button,
  DataElementInput,
  DatePickerProps,
  DesignBreakpointType,
  FormController,
  FormControllerContext,
  Heading,
  IconButtonWithBox,
  Layer,
  Modal,
  ProDoctivityColorBundle,
  Text,
  emptyFormController,
  modalZIndex,
  useColors,
  useDesignBreakpoint,
  useFormController,
} from "@prodoctivity/design-system";
import {
  convertTreeFormLogicalExpressionToExpressionObject,
  deepCopy,
  enumerateDependencyItemFields,
  evaluateRecordInstanceList,
  formValueToValueType,
  isTextLineContextField,
  replaceActiveContent,
} from "@prodoctivity/shared";
import type {
  ContextField,
  ContextRecord,
  DataElement,
  DataElementSingleValue,
  ParametersObject,
  RecordContextStack,
  TemplateWizardField,
  TemplateWizardFieldIsRecord,
} from "@prodoctivity/shared/src/index-types";
import { Table, message } from "antd";
import { Component, FunctionComponent, useCallback, useMemo, useState } from "react";
import { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "../_lib/date-formats";
import type { FormErrors, FormValues, GroupLayouts, ProDoctivityFormLayout } from "../_lib/types";
import {
  concatArrayToStr,
  getDateValueAsMomentInstance,
  noop,
  parseToBoolean,
} from "../_lib/utils";

// import { DataElementInput } from "../DataElementInput";
// import { DragHandle } from "../icons";

import { buildGroupLayout } from "../ProDoctivityFormDesigner/utils";
import { contextToExcerpt } from "../ProDoctivityFormGridLayout/FormSummary/FormSummary";
import { ResponsiveReactGridLayout } from "../ProDoctivityFormGridLayout/ResponsiveReactGridLayout";
// import { getNewLayoutFromDependencies } from "../Dependencies/DependenciesLayoutUtils";
import type momentType from "moment";
import { translateError } from "../ValidationDecorator";

// const ResponsiveReactGridLayout = WidthProvider(ReactGridLayout);

type DataSource = Array<unknown>; /*Array<>*/

type Props = {
  templateWizardRecord: TemplateWizardFieldIsRecord;
  contextRecord: ContextRecord;
  getDataElement: (
    fullPath: string
  ) => { contextField: ContextField; dataElement: DataElement } | undefined;

  instanceFullPath: string;
  layout?: ProDoctivityFormLayout;
  groupLayouts: GroupLayouts;
  fullGroupLayout: ProDoctivityFormLayout;
  isDesignMode?: boolean;
  readOnly: boolean;
  summaryMode?: boolean;
  formHasErrors?: boolean;
  indexes: number[];
  formValues: FormValues;
  generalFormValues: FormValues;
  onGroupLayoutChange: (groupName: string, layout: ProDoctivityFormLayout) => void;
  initialValue: DataSource;
  groupTableMode?: boolean;
  onTableGroupModeChange: (groupName: string, value: any) => void;
  onFormErrorOccurred: (name: string, errors: any) => void;
  i18n: (key: string, params?: any) => string;
  fireConnectors: (dataElementName: string) => void;
  dataFromFiredConnectors?: {
    id: string;
    // values: Array<{
    //   [dataElementName: string]: DataElementValue;
    // }>;
    values: ParametersObject[];
  };
  onCurrentValues: FormControllerContext["updateContextRecordData"];
  moment: typeof momentType;
  componentBreakpoint: DesignBreakpointType;
  colors: ProDoctivityColorBundle;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
  };
  recordContextStack: RecordContextStack;
};

type State = {
  layout: ProDoctivityFormLayout;
  formErrors: FormErrors;
  dependencyGroupLayout?: ProDoctivityFormLayout;
  useDependenciesLayout: boolean;
  groupTableMode?: boolean;
};

// let firstItemGroupId: any = null;
// let addedRow = false;
export class GroupComponent extends Component<Props, State> {
  context: React.ContextType<typeof FormController> = emptyFormController;

  constructor(props: Props) {
    super(props);

    this.state = {
      layout: props.layout || buildGroupLayout(props.instanceFullPath, props.contextRecord.fields),
      dependencyGroupLayout: props.layout,
      useDependenciesLayout: false,
      formErrors: {},
      groupTableMode: props.groupTableMode != null ? this.props.groupTableMode : true,
    };
  }
  static defaultProps = {
    initialValue: [],
    onGroupLayoutChange: noop,
  };

  generateLayout = (currentRecordInstanceContext: FormValues) => {
    const {
      generalFormValues,
      contextRecord,
      templateWizardRecord,
      recordContextStack,
      groupLayouts,
    } = this.props;
    const { name: groupName, fullPath: groupFullPath } = contextRecord;

    const groupValues = deepCopy(this.props.formValues);
    groupValues[groupName] = [currentRecordInstanceContext];

    const contextRecordLayout = groupLayouts[contextRecord.fullPath];

    const fieldsToHide = new Set<string>();
    const fieldsToDisable = new Set<string>();

    this.context.fullWizardDefinition.dependencies.forEach((dep) => {
      const expression = convertTreeFormLogicalExpressionToExpressionObject(dep.expression, true);

      if (
        expression.evaluate(generalFormValues, [
          ...recordContextStack,
          {
            name: groupName,
            value: currentRecordInstanceContext,
          },
        ])
      ) {
        dep.items.forEach((item) => {
          if (item.action === "hide") {
            enumerateDependencyItemFields(
              this.context.contextDefinition,
              this.context.wizardDefinition,
              item
            ).forEach((field) => {
              if (field.startsWith(`${groupFullPath}/`)) {
                fieldsToHide.add(field);
              }
            });
          } else if (item.action === "disable") {
            enumerateDependencyItemFields(
              this.context.contextDefinition,
              this.context.wizardDefinition,
              item
            ).forEach((field) => {
              if (field.startsWith(`${groupFullPath}/`)) {
                fieldsToDisable.add(field);
              }
            });
          }
        });
      }
    });

    const result: ProDoctivityFormLayout = templateWizardRecord.fields.reduce(
      (acc: ProDoctivityFormLayout, next) => {
        if (fieldsToHide.has(next.key)) {
          return acc;
        }

        const found = contextRecordLayout.find(
          (item) =>
            (item.type === "dataElement" || item.type === "group") && item.fullPath === next.key
        );
        if (found) {
          acc.push(found);
        }

        return acc;
      },
      []
    );

    return result;
  };

  errorMessage = () => {
    const { i18n } = this.props;
    const errors = Object.values(this.state.formErrors).filter(
      (error) => error.length > 0 && error[0] != null
    );
    errors
      .filter((e, i) => i <= 2)
      .forEach((error) => {
        if (error[0] != null)
          message.error(
            translateError(
              i18n(error[0].key),
              error[0].parameters || { field: error[0].dataElementName }
            )
          );
      });

    if (errors.length > 3) {
      message.error(i18n("thereAreOtherErrors"));
    }
  };

  applyDependencyToFormValue = (
    anyLayout: ProDoctivityFormLayout,
    formValues: ParametersObject
  ): ParametersObject => {
    if (anyLayout) {
      const itemNames = anyLayout.reduce((acc: string[], next) => {
        acc.push(next.name);

        return acc;
      }, []);
      const newFormValue: FormValues = {};

      itemNames.forEach((item) => {
        if (typeof formValues[item] !== "undefined") {
          newFormValue[item] = formValues[item];
        }
      });

      return newFormValue;
    }
    return formValues;
  };

  removeFormErrorsDependency = (dependencyLayout: ProDoctivityFormLayout) => {
    const formErrorsT: FormErrors = {};

    if (dependencyLayout) {
      const { formErrors } = this.state;

      const itemNames = dependencyLayout.reduce((acc: string[], next) => {
        acc.push(next.name);

        return acc;
      }, []);
      itemNames.forEach((item) => {
        if (formErrors[item]) {
          formErrorsT[item] = formErrors[item];
        }
      });

      //this.setState({ formErrors: formErrorsT })
    }
    return formErrorsT;
  };

  notifyLayoutChange() {
    const { name: groupName } = this.props.contextRecord;
    if (this.props.isDesignMode) this.props.onGroupLayoutChange(groupName, this.state.layout);
  }

  tableColumns = this.getTableColumns();

  getTableColumns() {
    const { moment } = this.props;
    const getDateRenderer = (format: string) => (date: moment.Moment | string | Date | void) => {
      if (date == null) {
        return null;
      }
      const momentInstance = getDateValueAsMomentInstance(moment, date, false);

      let stringValue = "";

      if (Array.isArray(momentInstance)) {
        stringValue = concatArrayToStr(
          momentInstance.map((date) => {
            return date.format(format);
          })
        );
      } else if (momentInstance) {
        stringValue = momentInstance.format(format);
      }

      return stringValue;
    };

    const dataColumns: any = this.props.contextRecord.fields.map((contextField) => {
      let renderObj = null;

      const { name } = contextField;

      switch (contextField.properties.dataType) {
        case "Logical":
          renderObj = {
            render: (value?: boolean) => (parseToBoolean(value) ? "✔" : "✘"),
          };
          break;
        case "Date":
          renderObj = {
            render: getDateRenderer(DATE_FORMAT),
          };
          break;
        case "Time":
          renderObj = {
            render: getDateRenderer(TIME_FORMAT),
          };
          break;
        case "DateTime":
          renderObj = {
            render: getDateRenderer(DATETIME_FORMAT),
          };
          break;
        default:
          renderObj = {
            render: (value: string | string[]) => concatArrayToStr(value),
          };
          break;
      }

      return {
        title: contextField.properties.label,
        dataIndex: name,
        key: name,
        ...renderObj,
      };
    });

    if (!this.props.readOnly) {
      dataColumns.push({
        title: this.props.i18n("action"),
        dataIndex: "action",
        render: (_text: any, _record: any) => {
          return (
            <Layer zIndex={modalZIndex}>
              <Modal
                accessibilityModalLabel={this.props.i18n("areYouSureDeleteThisElement")}
                onDismiss={noop}
              >
                <Heading color={this.props.colors.black600}>
                  {this.props.i18n("areYouSureDeleteThisElement")}
                </Heading>

                <Button
                  type="button"
                  accessibilityLabel={this.props.i18n("delete_")}
                  text={this.props.i18n("delete_")}
                  iconEnd="remove"
                />
                {/*<button data-record-key={record.groupRowKey} className="btn btn-default">
                {this.props.i18n("delete_")}
          </button>*/}
              </Modal>
            </Layer>
          );
        },
      });
    }

    return dataColumns;
  }

  addRow = () => {
    this.context.addRecordInstanceRow(this.props.instanceFullPath, this.props.contextRecord.name);
  };

  onLayoutChange = (newLayout: ProDoctivityFormLayout) => {
    if (this.props.isDesignMode) {
      this.setState(
        ({ layout }) => ({
          layout: newLayout.map((l, i) => {
            return {
              ...layout[i],
              // flowlint-next-line inexact-spread:off
              ...l,
            };
          }),
        }),
        this.notifyLayoutChange
      );
    }
  };

  findWizardFieldInRecord = (dataElementName: string) => {
    const field = this.props.templateWizardRecord.fields.find((key) => {
      if (key.isRecord === false) {
        const parts = key.key.split("/");
        const lastPart = parts[parts.length - 1];
        return lastPart === dataElementName;
      }
      return false;
    });

    return field;
  };

  groupLooseDataMode = () => {
    const {
      isDesignMode,
      readOnly,
      contextRecord,
      i18n,
      // onSampleDataUsed,
      moment,
      //
      // templateWizardRecord: record,
      getDataElement,
      resources,
      indexes,
      instanceFullPath,
      groupLayouts,
      formValues,
      templateWizardRecord,
      componentBreakpoint,
    } = this.props;

    const dataSource = evaluateRecordInstanceList(instanceFullPath, contextRecord.name, formValues);

    if (!dataSource || dataSource.length === 0) {
      return;
    }

    const rowsGrid = [];

    for (let index = 0; index < dataSource.length; index += 1) {
      if (dataSource[index] != null) {
        const customLayout = this.generateLayout(dataSource[index]);

        const customFormValues = this.applyDependencyToFormValue(customLayout, dataSource[index]);
        dataSource[index] = customFormValues;

        const parts = contextRecord.fullPath.split("/");
        const recordInstanceFullPath = indexes.reduce((acc: string, next, idx) => {
          if (!acc) {
            return `${parts[idx]}[${next}]`;
          }
          return `${acc}/${parts[idx]}[${next}]`;
        }, "");

        rowsGrid.push(
          <RecordLooseRow
            formValues={formValues}
            groupLayouts={groupLayouts}
            key={`${contextRecord.name}_${index}`}
            contextRecord={contextRecord}
            templateWizardRecord={templateWizardRecord}
            recordInstanceFullPath={recordInstanceFullPath}
            recordContextStack={this.props.recordContextStack}
            recordInstance={dataSource[index]}
            i18n={i18n}
            isDesignMode={!!isDesignMode}
            customLayout={customLayout}
            findWizardFieldInRecord={this.findWizardFieldInRecord}
            onLayoutChange={this.onLayoutChange}
            moment={moment}
            getDataElement={getDataElement}
            readOnly={readOnly}
            resources={resources}
            index={index}
            instanceCount={dataSource.length}
            indexes={indexes}
            onCurrentValues={this.props.onCurrentValues}
            componentBreakpoint={componentBreakpoint}
          />
        );
      }
    }
    return rowsGrid;
  };

  render = () => {
    const {
      isDesignMode,
      readOnly,
      contextRecord,
      colors,
      i18n,
      // fireConnectors,
      // onSampleDataUsed,
      // contextRecord,
      //
      // templateWizardRecord: record,
      // moment,
      // getDataElement,
      // resources,
      // getContextValue,
    } = this.props;

    // const { name: groupName } = contextRecord;
    // const label = contextRecord.properties.label || groupName;

    const { groupTableMode /*, useDependenciesLayout, dependencyGroupLayout */ } = this.state;

    return (
      <Box display="flex" direction="column" gap={2} flex="grow" width={"100%"}>
        <Heading size="400" color={colors.black900}>
          {contextRecord.properties.label}:
        </Heading>
        {!groupTableMode && !isDesignMode && this.groupLooseDataMode()}

        {groupTableMode ? (
          <Table
            columns={this.tableColumns}
            dataSource={
              evaluateRecordInstanceList(
                this.props.instanceFullPath,
                contextRecord.name,
                this.props.formValues
              ) || []
            }
            className="tableStyle"
            locale={{
              emptyText: (
                <div style={{ display: "grid" }}>
                  <span style={{ color: "#8c8c8c" }}>{i18n("noData")}</span>
                </div>
              ),
            }}
            /*className={styled.style`
overflow-x: scroll;

.ant-spin-container {
min-width: max-content;
}
`}*/
          />
        ) : (
          !readOnly && (
            <Box display="flex" direction="row">
              <Box flex="grow" />
              <Box flex="shrink">
                <Button
                  accessibilityLabel={i18n("add")}
                  type="button"
                  text={i18n("add")}
                  iconEnd="add-circle"
                  color="gray"
                  // className="buttonGroups"
                  onClick={this.addRow}
                  disabled={isDesignMode} // || hasValidationErrors}
                />
              </Box>
            </Box>
          )
        )}
      </Box>
    );
  };
}

const RecordLooseRow: FunctionComponent<{
  formValues: ParametersObject;
  customLayout: ProDoctivityFormLayout;
  contextRecord: ContextRecord;
  templateWizardRecord: TemplateWizardFieldIsRecord;
  recordInstanceFullPath: string;
  recordContextStack: RecordContextStack;
  recordInstance: ParametersObject;
  groupLayouts: GroupLayouts;
  isDesignMode: boolean;
  i18n: (key: string, params?: any) => string;
  findWizardFieldInRecord: (dataElementName: string) => TemplateWizardField | undefined;
  onLayoutChange: (newLayout: ProDoctivityFormLayout) => void;
  moment: typeof momentType;
  getDataElement: (fullPath: string) =>
    | {
        contextField: ContextField;
        dataElement: DataElement;
      }
    | undefined;
  readOnly: boolean;
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
  };
  index: number;
  instanceCount: number;
  indexes: number[];
  componentBreakpoint: DesignBreakpointType;
  onCurrentValues: FormControllerContext["updateContextRecordData"];
}> = ({
  formValues,
  customLayout,
  contextRecord,
  templateWizardRecord,
  recordContextStack,
  recordInstanceFullPath,
  recordInstance,
  groupLayouts,
  isDesignMode,
  i18n,
  findWizardFieldInRecord,
  onLayoutChange,
  moment,
  getDataElement,
  readOnly,
  resources,
  index,
  instanceCount,
  indexes,
  componentBreakpoint,
  onCurrentValues,
}) => {
  const { colors } = useColors();
  const {
    context,
    getContextValueAsString,
    updateContextRecordData,
    removeContextRecordInstance,
    moveRecordInstanceUp,
    moveRecordInstanceDown,
  } = useFormController();
  const { breakpoint } = useDesignBreakpoint();

  const newRecordContextStack: RecordContextStack = useMemo(() => {
    return [...recordContextStack, { name: contextRecord.name, value: recordInstance }];
  }, [contextRecord.name, recordContextStack, recordInstance]);

  const thisInstanceFullPath = useMemo(() => {
    const pathSuffix = `${contextRecord.name}[${index}]`;
    const result = recordInstanceFullPath ? `${recordInstanceFullPath}/${pathSuffix}` : pathSuffix;

    return result;
  }, [contextRecord.name, index, recordInstanceFullPath]);

  const localGetDynamicValue = useCallback(
    (text: string) => {
      return replaceActiveContent(context, newRecordContextStack, text);
    },
    [context, newRecordContextStack]
  );

  const localGetContextValueAsString = useCallback(
    (k: string) => {
      return getContextValueAsString(k, newRecordContextStack);
    },
    [getContextValueAsString, newRecordContextStack]
  );

  const [confirmOpen, setConfirmOpen] = useState(false);
  const openConfirmDialog = useCallback(() => {
    setConfirmOpen(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setConfirmOpen(false);
  }, []);

  const performDeleteRecordInstance = useCallback(() => {
    closeConfirmDialog();

    removeContextRecordInstance(thisInstanceFullPath);
  }, [closeConfirmDialog, removeContextRecordInstance, thisInstanceFullPath]);

  const moveUp = useCallback(() => {
    moveRecordInstanceUp(thisInstanceFullPath);
  }, [moveRecordInstanceUp, thisInstanceFullPath]);

  const moveDown = useCallback(() => {
    moveRecordInstanceDown(thisInstanceFullPath);
  }, [moveRecordInstanceDown, thisInstanceFullPath]);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  const rowSummary = useMemo(() => {
    return contextToExcerpt(
      moment,
      i18n,
      contextRecord,
      templateWizardRecord,
      breakpoint === "small"
        ? 40
        : breakpoint === "medium"
        ? 55
        : breakpoint === "large"
        ? 70
        : 120,
      context,
      newRecordContextStack
    );
  }, [
    context,
    contextRecord,
    i18n,
    moment,
    newRecordContextStack,
    templateWizardRecord,
    breakpoint,
  ]);

  const newIndexes = useMemo(() => {
    return [...indexes, index];
  }, [indexes, index]);

  return (
    <div className="groupLooseDataItem">
      <Box
        display="flex"
        direction="column"
        padding={2}
        gap={2}
        borderRadius={6}
        color={colors.neutral400}
        position="relative"
      >
        <Box display="flex" direction="row" gap={2}>
          <Box flex="shrink">
            <IconButtonWithBox
              icon={collapsed ? "chevron-right" : "chevron-down"}
              accessibilityLabel={collapsed ? i18n("expand") : i18n("collapse")}
              color={colors.white}
              onClick={toggleCollapsed}
              disabled={false}
            />
          </Box>
          <Box flex="shrink" alignContent="center" direction="column" alignItems="center">
            <Text size="100" weight="bold">
              #{index + 1}
            </Text>
          </Box>
          {componentBreakpoint !== "small" && componentBreakpoint !== "medium" && (
            <Box
              display="flex"
              flex="shrink"
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              {rowSummary.map((line, idx) => (
                <Text key={idx} size="100">
                  {line}
                </Text>
              ))}
            </Box>
          )}

          <Box flex="grow" />
          <Box flex="shrink">
            <IconButtonWithBox
              icon="arrow-down"
              accessibilityLabel={i18n("moveDown")}
              color={colors.white}
              onClick={moveDown}
              disabled={index >= instanceCount - 1}
            />
          </Box>
          <Box flex="shrink">
            <IconButtonWithBox
              icon="arrow-up"
              accessibilityLabel={i18n("moveUp")}
              color={colors.white}
              onClick={moveUp}
              disabled={index === 0}
            />
          </Box>
          <Box flex="shrink">
            {confirmOpen && (
              <Layer zIndex={modalZIndex}>
                <Modal
                  accessibilityModalLabel={i18n("areYouSureDeleteThisElement")}
                  onDismiss={closeConfirmDialog}
                >
                  <Box display="flex" direction="column" flex="shrink" gap={8}>
                    <Heading size="400" color={colors.black600}>
                      {i18n("areYouSureDeleteThisElement")}
                    </Heading>

                    <Box display="flex" direction="row" flex="shrink" gap={4}>
                      <Box display="flex" flex="grow" />
                      <Button
                        type="button"
                        color="transparent"
                        accessibilityLabel={i18n("cancel")}
                        text={i18n("cancel")}
                        iconEnd="cancel"
                        onClick={closeConfirmDialog}
                      />
                      <Button
                        type="button"
                        color="red"
                        accessibilityLabel={i18n("delete_")}
                        text={i18n("delete_")}
                        iconEnd="trash-can"
                        onClick={performDeleteRecordInstance}
                      />
                    </Box>
                  </Box>
                </Modal>
              </Layer>
            )}
            <IconButtonWithBox
              icon="trash"
              accessibilityLabel={i18n("delete_")}
              color={colors.white}
              onClick={openConfirmDialog}
              disabled={!!readOnly}
            />
          </Box>
        </Box>
        {(breakpoint === "small" || breakpoint === "medium") && (
          <Box
            display="flex"
            flex="shrink"
            direction="column"
            justifyContent="center"
            alignContent="center"
          >
            {rowSummary.map((line, idx) => (
              <Text size="200" key={idx}>
                {line}
              </Text>
            ))}
          </Box>
        )}
      </Box>
      {!collapsed && (
        <Box paddingY={2} paddingLeft={2} borderRadius={6}>
          <ResponsiveReactGridLayout
            layout={customLayout}
            onLayoutChange={onLayoutChange}
            className="layout non-draggable"
            isDraggable={isDesignMode}
            isResizable={isDesignMode}
            //isRearrangeable={isDesignMode}
            cols={12}
            rowHeight={40}
            margin={[10, 10]}
            draggableHandle=".draggable"
          >
            {customLayout.map((layoutItem) => {
              if (layoutItem.type === "group") {
                const recordName = layoutItem.fullPath.substring(
                  layoutItem.fullPath.lastIndexOf("/") + 1
                );
                const recordContents = recordInstance[recordName];
                const contextWizardRecord = templateWizardRecord.fields.find(
                  (wf) => wf.key === layoutItem.fullPath
                );
                const childContextRecord = (contextRecord.records || []).find(
                  (rec) => rec.name === recordName
                );

                return (
                  <Box
                    display="flex"
                    key={layoutItem.name}
                    id={layoutItem.name}
                    width="calc(100% - 1px)"
                  >
                    {childContextRecord &&
                      contextWizardRecord &&
                      contextWizardRecord.isRecord &&
                      Array.isArray(recordContents) && (
                        <GroupComponent
                          templateWizardRecord={contextWizardRecord}
                          contextRecord={childContextRecord}
                          getDataElement={getDataElement}
                          instanceFullPath={thisInstanceFullPath}
                          isDesignMode={isDesignMode}
                          readOnly={readOnly}
                          onGroupLayoutChange={noop}
                          groupLayouts={groupLayouts}
                          fullGroupLayout={groupLayouts[layoutItem.fullPath]}
                          layout={groupLayouts[layoutItem.fullPath]}
                          initialValue={recordContents}
                          onTableGroupModeChange={noop}
                          groupTableMode={false}
                          onFormErrorOccurred={noop} //SPC
                          i18n={i18n}
                          fireConnectors={noop}
                          formValues={formValues}
                          onCurrentValues={updateContextRecordData}
                          dataFromFiredConnectors={undefined}
                          generalFormValues={formValues}
                          moment={moment}
                          componentBreakpoint={componentBreakpoint}
                          colors={colors}
                          resources={resources}
                          recordContextStack={newRecordContextStack}
                          indexes={newIndexes}
                        />
                      )}
                  </Box>
                );
              }
              const fullPath = `${contextRecord.fullPath}/${layoutItem.name}`;
              const element = getDataElement(fullPath);

              if (layoutItem.type !== "dataElement" || !element || !element.contextField) {
                return <></>;
                //throw new Error(`Data Element '${layoutItem.name}' not found`);
              }

              const { contextField, dataElement } = element;

              const field = findWizardFieldInRecord(dataElement.name);

              if (!field) {
                return <></>;
              }

              const formVal = recordInstance[dataElement.name];

              const arr = Array.isArray(formVal)
                ? formVal.reduce((acc: Array<string | number>, next) => {
                    if (typeof next !== "object") {
                      acc.push(next);
                    }
                    return acc;
                  }, [])
                : formVal === null
                ? []
                : [formVal];
              const valueArr = arr.reduce(
                (acc: DataElementSingleValue[], next: ParametersObject[string]) => {
                  if (next !== null) {
                    if (Array.isArray(next)) {
                      next.forEach((v) => {
                        if (typeof v !== "object") {
                          acc.push(v);
                        }
                      });
                    } else {
                      acc.push(next);
                    }
                  }

                  return acc;
                },
                []
              );

              const pathSuffix = `${contextRecord.name}[${index}]/${contextField.name}`;

              return (
                <div key={layoutItem.i} className={isDesignMode ? "draggable" : " non-draggable"}>
                  <DataElementInput
                    componentBreakpoint={componentBreakpoint}
                    instanceFullPath={`${thisInstanceFullPath}/${contextField.name}`}
                    disabled={
                      isDesignMode ||
                      readOnly ||
                      (isTextLineContextField(dataElement) && !!dataElement.sequenceId)
                    }
                    contextField={contextField}
                    typeValue={formValueToValueType(moment, dataElement, valueArr)}
                    // onChooseAlternativeQuestion={this.onChooseAlternativeQuestion}
                    // chosenQuestion={layoutItem.chosenQuestion}
                    onChange={(params) => {
                      onCurrentValues(
                        recordInstanceFullPath
                          ? `${recordInstanceFullPath}/${pathSuffix}`
                          : pathSuffix,
                        params,
                        contextField.name
                      );
                    }}
                    onBlur={noop}
                    // showPins={false}
                    // i18n={i18n}
                    // onSampleDataUsed={onSampleDataUsed}
                    // filterListByDocumentType={this.props.filterListByDocumentType}
                    // allowedListValues={this.props.allowedListValues}
                    getContextValueAsString={localGetContextValueAsString}
                    getDynamicValue={localGetDynamicValue}
                    moment={moment}
                    resources={resources}
                  />
                </div>
              );
            })}
          </ResponsiveReactGridLayout>
        </Box>
      )}
    </div>
  );
};

GroupComponent.contextType = FormController;
