import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { BrowseDocumentLabelsFilter } from "../../link-templates";
import { DocumentLabelsList } from "./DocumentLabelsList";

const BrowseDocumentLabelsFilter$Schema = z
  .object({
    dateStart: z.coerce.date({}).optional().catch(undefined),
    dateEnd: z.coerce.date({}).optional().catch(undefined),
    documentTypeIdList: z
      .preprocess((arg) => {
        if (typeof arg === "string") {
          return arg.split(",");
        } else if (Array.isArray(arg)) {
          return arg;
        }
        return undefined;
      }, z.string().min(1).array())
      .optional()
      .catch(undefined),
    documentLists: z
      .preprocess((arg) => {
        if (typeof arg === "string") {
          return arg.split(",");
        } else if (Array.isArray(arg)) {
          return arg;
        }
        return undefined;
      }, z.string().min(1).array())
      .optional()
      .catch(undefined),
    includeFavorites: z
      .preprocess((arg) => {
        if (typeof arg === "string") {
          if (arg.toLowerCase() === "true") {
            return true;
          } else if (arg.toLowerCase() === "false") {
            return false;
          }
        }
        return arg;
      }, z.boolean())
      .optional()
      .catch(undefined),
    pageNumber: z.coerce.number().nonnegative().catch(0),
    rowsPerPage: z.enum(["15", "30", "100"]).catch("15"),
    sortField: z
      .enum(["documentType", "name", "updatedAt"])
      .optional()
      .default("updatedAt")
      .catch("updatedAt"),
    sortDirection: z.enum(["ASC", "DESC"]).optional().default("DESC").catch("DESC"),
  })
  .strip();

function urlParamsToFilter(urlParams: Record<string, string | null>): BrowseDocumentLabelsFilter {
  const filter = BrowseDocumentLabelsFilter$Schema.safeParse(urlParams);

  if (!filter.success) {
    return {
      dateStart: undefined,
      dateEnd: undefined,
      documentTypeIdList: undefined,
      pageNumber: 0,
      rowsPerPage: "15",
      sortDirection: "ASC",
      sortField: undefined,
      documentLists: undefined,
      includeFavorites: undefined,
    };
  }

  const result: BrowseDocumentLabelsFilter = {
    dateStart: filter.data.dateStart ? filter.data.dateStart.getTime() : undefined,
    dateEnd: filter.data.dateEnd ? filter.data.dateEnd.getTime() : undefined,
    documentTypeIdList: filter.data.documentTypeIdList,
    pageNumber: filter.data.pageNumber,
    rowsPerPage: filter.data.rowsPerPage,
    sortDirection: filter.data.sortDirection || "ASC",
    sortField: filter.data.sortField,
    documentLists: filter.data.documentLists,
    includeFavorites: filter.data.includeFavorites,
  };

  return result;
}

const DocumentLabelsPage: FunctionComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const includeFavorites = params.get("includeFavorites");
  const documentLists = params.get("documentLists");

  const filter = urlParamsToFilter({
    dateFrom: params.get("dateFrom"),
    dateEnd: params.get("dateEnd"),
    documentTypeIdList: params.get("documentTypeIdList"),
    pageNumber: params.get("pageNumber"),
    rowsPerPage: params.get("rowsPerPage"),
    sortDirection: params.get("sortDirection"),
    sortField: params.get("sortField"),
    documentLists,
    includeFavorites: includeFavorites === null && !documentLists ? "true" : includeFavorites,
  });

  return <DocumentLabelsList hideTitle={false} filter={filter} />;
};

export default DocumentLabelsPage;
