import { FC, useCallback, useMemo } from "react";
import { StatsContainer } from "../../StatsContainer";
import type { RootAssignedGroupedDataSet } from "@prodoctivity/types";
import { Box, SearchField, Table, Text } from "@prodoctivity/design-system";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import { IconsEssentialsTableSort } from "../../../../../svg/IconsEssentialsTableSort";
import { IconsEssentialsTableSortAsc } from "../../../../../svg/IconsEssentialsTableSortAsc";
import { IconsEssentialsTableSortDesc } from "../../../../../svg/IconsEssentialsTableSortDesc";
import { PublicProfile } from "../../../../../components/Profile/Public/PublicProfile";

type ReportTableDataViewProps = {
  dataSet: RootAssignedGroupedDataSet[] | undefined;
  filter: string;
  sortBy: "default" | "asc" | "desc";
  sortedCol: string;
  changeSort: (sortBy: "default" | "asc" | "desc", sortedCol: string) => void;
  onFilterChange: (value: string) => void;
};

export const ReportTableDataView: FC<ReportTableDataViewProps> = ({
  dataSet,
  filter,
  sortBy,
  sortedCol,
  onFilterChange,
  changeSort,
}) => {
  const { resources } = useAppTranslation();

  const normalizeDataSet = useMemo(() => {
    const myDataSet: any[] = [];
    if (filter === "") {
      dataSet?.forEach((row) => {
        myDataSet.push({
          valid: row.data.valid,
          assignedTo: row.assignedTo,
          issues: row.data.issues,
          expired: row.data.expired,
          expiring: row.data.expiring,
        });
      });
    } else {
      dataSet?.forEach((row) => {
        if (row.assignedTo.includes(filter)) {
          myDataSet.push({
            valid: row.data.valid,
            assignedTo: row.assignedTo,
            issues: row.data.issues,
            expired: row.data.expired,
            expiring: row.data.expiring,
          });
        }
      });
    }

    if (sortedCol !== "") {
      if (sortBy === "asc") {
        return myDataSet.sort((row, next) => {
          if (row[sortedCol] > next[sortedCol]) return 1;
          if (row[sortedCol] < row[sortedCol]) return -1;
          return 0;
        });
      }

      if (sortBy === "desc") {
        return myDataSet.sort((row, next) => {
          if (row[sortedCol] < next[sortedCol]) return 1;
          if (row[sortedCol] > next[sortedCol]) return -1;
          return 0;
        });
      }
    }

    return myDataSet;
  }, [dataSet, filter, sortBy, sortedCol]);

  const getSortedIcon = useCallback(
    (colSortedName: string) => {
      if (colSortedName === sortedCol) {
        if (sortBy === "asc") {
          return <IconsEssentialsTableSortAsc />;
        } else return <IconsEssentialsTableSortDesc />;
      }

      return <IconsEssentialsTableSort />;
    },
    [sortBy, sortedCol]
  );

  const labelTranslated = useCallback(
    (valueToRepresent: string): any => {
      const labels: Record<string, string> = {
        expired: resources.expired,
        expiring: resources.toExpire,
        valid: resources.valid,
        issues: resources.missing,
        assignedTo: resources.assignedTo,
      };
      return labels[valueToRepresent];
    },
    [
      resources.expired,
      resources.toExpire,
      resources.valid,
      resources.missing,
      resources.assignedTo,
    ]
  );

  const nameHeader: string[] = [];
  if (dataSet) {
    if (dataSet.length > 0) {
      nameHeader.push(Object.keys(dataSet[0])[0]);
      nameHeader.push(...Object.keys(dataSet[0].data));
    }
  }

  return (
    <StatsContainer title={""}>
      <Box display="flex" direction="column" width={"100%"}>
        <Box marginBottom={2}>
          <SearchField
            id="0"
            value={filter}
            onChange={(x) => onFilterChange(x.value)}
            accessibilityLabel=""
          />
        </Box>
        <Box>
          {nameHeader.length === 0 ? (
            <Box display="flex" direction="column">
              <Box alignContent="center" alignItems="center" height={200}>
                <Box>
                  <Text align="center" weight="bold" size="300">
                    {resources.empty}
                  </Text>
                </Box>
              </Box>
            </Box>
          ) : (
            <Table
              accessibilityLabel={resources.viewOfDocumentCollectionAssignedByUser}
              stickyColumns={0}
              borderStyle="sm"
            >
              <Table.Header>
                <Table.Row>
                  {nameHeader.map((x, index) => (
                    <Table.Cell key={index}>
                      <Box
                        display="flex"
                        direction="row"
                        gap={2}
                        alignContent="center"
                        alignItems="center"
                      >
                        <Box>{labelTranslated(x)}</Box>
                        <Box
                          onClickCapture={() => changeSort(sortBy === "asc" ? "desc" : "asc", x)}
                        >
                          {sortBy === "default" ? <IconsEssentialsTableSort /> : getSortedIcon(x)}
                        </Box>
                      </Box>
                    </Table.Cell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {normalizeDataSet.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan={nameHeader.length}>
                      <Text align="center">{resources.empty}</Text>
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  normalizeDataSet.map((row, index) => (
                    <Table.Row key={index}>
                      {nameHeader.map((key, index) => {
                        if (key === "assignedTo") {
                          return (
                            <Table.Cell key={index}>
                              <PublicProfile username={row[key]} />
                            </Table.Cell>
                          );
                        }
                        return <Table.Cell key={index}>{row[key]}</Table.Cell>;
                      })}
                    </Table.Row>
                  ))
                )}
              </Table.Body>
            </Table>
          )}
        </Box>
      </Box>
    </StatsContainer>
  );
};
