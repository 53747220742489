import { FunctionComponent } from "react";
import { isIconSelected } from "../../utils";
import { useColors } from "../ColorSchemeProvider";
import { AddItemIcon } from "./AddItemIcon";
import { ArrowSvg } from "./ArrowSvg";
import { CollapseSidebarIcon } from "./CollapseSidebarIcon";
import { DashboardContentViewIcon } from "./DashboardContentViewIcon";
import { DashboardSidebarViewIcon } from "./DashboardSidebarViewIcon";
import { DoubleColumnLayoutIcon } from "./DoubleColumnLayoutIcon";
import { HamburgerIcon } from "./HamburgerMenuIcon";
import { SingleColumnLayoutIcon } from "./SingleColumnLayoutIcon";
import { ViewerAdjustHeightIcon } from "./ViewerAdjustHeightIcon";
import { ViewerAdjustWidthIcon } from "./ViewerAdjustWidthIcon";
import { ViewerCloudIcon } from "./ViewerCloudIcon";
import { ViewerCollapseSidePanelIcon } from "./ViewerCollapseSidePanelIcon";
import { ViewerFirstPageIcon } from "./ViewerFirstPageIcon";
import { ViewerFullScreenIcon } from "./ViewerFullScreenIcon";
import { ViewerHandIcon } from "./ViewerHandIcon";
import { ViewerLastPageIcon } from "./ViewerLastPageIcon";
import { ViewerNextPageIcon } from "./ViewerNextPageIcon";
import { ViewerPreviousPageIcon } from "./ViewerPreviousPageIcon";
import { ViewerSelectToolIcon } from "./ViewerSelectToolIcon";
import { ViewerZoomInIcon } from "./ViewerZoomInIcon";
import { ViewerZoomOutIcon } from "./ViewerZoomOutIcon";
import { DashboardDeleteRowSvg, VerticalEllipseMenuSvg } from "../../svg";

const toolBarButtonName = {
  none: "none",
  cloud: "cloud",
  fullScreen: "fullScreen",
  adjustHeight: "adjustHeight",
  adjustWidth: "adjustWidth",
  zoomOut: "zoomOut",
  zoomIn: "zoomIn",
  handTool: "handTool",
  rectangleTool: "rectangleTool",
  selectTool: "selectTool",
  collapseSidePanel: "collapseSidePanel",
  nextPage: "nextPage",
  previousPage: "previousPage",
  lastPage: "lastPage",
  firstPage: "firstPage",
  hamburger: "hamburger",
  Print: "Print",
  singleColumn: "singleColumn",
  doubleColumn: "doubleColumn",
  addItem: "addItem",
  moveUp: "moveUp",
  moveDown: "moveDown",
  verticalEllipsis: "verticalEllipsis",
  collapseSidebar: "collapseSidebar",
  dashboardContentView: "dashboardContentView",
  dashboardSidebarView: "dashboardSidebarView",
  delete: "delete",
};

type Props = {
  iconName: string;
  btnClicked: boolean;
};
export const DynamicIcon: FunctionComponent<Props> = ({ iconName, btnClicked }) => {
  const { colors } = useColors();
  if (!iconName || iconName === "none") {
    return null;
  }

  let conditionalIcon;
  switch (iconName) {
    case toolBarButtonName.cloud:
      conditionalIcon = (
        <ViewerCloudIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerCloudIcon>
      );
      break;
    case toolBarButtonName.fullScreen:
      conditionalIcon = (
        <ViewerFullScreenIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerFullScreenIcon>
      );
      break;
    case toolBarButtonName.adjustHeight:
      conditionalIcon = (
        <ViewerAdjustHeightIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerAdjustHeightIcon>
      );
      break;
    case toolBarButtonName.adjustWidth:
      conditionalIcon = (
        <ViewerAdjustWidthIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerAdjustWidthIcon>
      );
      break;
    case toolBarButtonName.zoomOut:
      conditionalIcon = (
        <ViewerZoomOutIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerZoomOutIcon>
      );
      break;
    case toolBarButtonName.zoomIn:
      conditionalIcon = (
        <ViewerZoomInIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerZoomInIcon>
      );
      break;
    case toolBarButtonName.handTool:
      conditionalIcon = (
        <ViewerHandIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerHandIcon>
      );
      break;
    case toolBarButtonName.selectTool:
      conditionalIcon = (
        <ViewerSelectToolIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerSelectToolIcon>
      );
      break;
    case toolBarButtonName.collapseSidePanel:
      conditionalIcon = (
        <ViewerCollapseSidePanelIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerCollapseSidePanelIcon>
      );
      break;
    case toolBarButtonName.nextPage:
      conditionalIcon = (
        <ViewerNextPageIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerNextPageIcon>
      );
      break;
    case toolBarButtonName.lastPage:
      conditionalIcon = (
        <ViewerLastPageIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerLastPageIcon>
      );
      break;
    case toolBarButtonName.previousPage:
      conditionalIcon = (
        <ViewerPreviousPageIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerPreviousPageIcon>
      );
      break;
    case toolBarButtonName.firstPage:
      conditionalIcon = (
        <ViewerFirstPageIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></ViewerFirstPageIcon>
      );
      break;
    case toolBarButtonName.hamburger:
      conditionalIcon = (
        <HamburgerIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></HamburgerIcon>
      );
      break;
    case toolBarButtonName.singleColumn:
      conditionalIcon = (
        <SingleColumnLayoutIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={40}
          height={40}
        ></SingleColumnLayoutIcon>
      );
      break;
    case toolBarButtonName.doubleColumn:
      conditionalIcon = (
        <DoubleColumnLayoutIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={40}
          height={40}
        ></DoubleColumnLayoutIcon>
      );
      break;
    case toolBarButtonName.addItem:
      conditionalIcon = (
        <AddItemIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={40}
          height={40}
        ></AddItemIcon>
      );
      break;
    case toolBarButtonName.moveUp:
      conditionalIcon = (
        <ArrowSvg direction="up" color={isIconSelected(colors, btnClicked, false)}></ArrowSvg>
      );
      break;
    case toolBarButtonName.moveDown:
      conditionalIcon = (
        <ArrowSvg direction="down" color={isIconSelected(colors, btnClicked, false)}></ArrowSvg>
      );
      break;
    case toolBarButtonName.verticalEllipsis:
      conditionalIcon = (
        <VerticalEllipseMenuSvg
          color={isIconSelected(colors, btnClicked, false)}
          width={32}
          height={32}
        ></VerticalEllipseMenuSvg>
      );
      break;
    case toolBarButtonName.collapseSidebar:
      conditionalIcon = (
        <CollapseSidebarIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={40}
          height={40}
        ></CollapseSidebarIcon>
      );
      break;
    case toolBarButtonName.dashboardContentView:
      conditionalIcon = (
        <DashboardContentViewIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={40}
          height={40}
        ></DashboardContentViewIcon>
      );
      break;
    case toolBarButtonName.dashboardSidebarView:
      conditionalIcon = (
        <DashboardSidebarViewIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={40}
          height={40}
        ></DashboardSidebarViewIcon>
      );
      break;
    case toolBarButtonName.delete:
      conditionalIcon = (
        <DashboardDeleteRowSvg
          color={isIconSelected(colors, btnClicked, false)}
          width={40}
          height={40}
        ></DashboardDeleteRowSvg>
      );
      break;
    case toolBarButtonName.rectangleTool:
      conditionalIcon = (
        <ViewerSelectToolIcon
          color={isIconSelected(colors, btnClicked, false)}
          width={40}
          height={40}
        ></ViewerSelectToolIcon>
      );
      break;
  }

  return <>{conditionalIcon}</>;
};
