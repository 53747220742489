import {
  Box,
  Button,
  Divider,
  Layer,
  Modal,
  Text,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";

import { FunctionComponent, useState } from "react";

import { Page } from "../../../components/Layout/Page";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { StepTabs } from "../../../components/StepTabs";
import { organizationLinkTemplates } from "../../../link-templates";
import { emptyArray } from "../../../utils";
import { FormDesigner } from "./FormDesigner/index";
import { DocumentKeyStep } from "./Steps/DocumentKeyStep";
import { DocumentTypeStep } from "./Steps/DocumentTypeStep";
import { PermissionsStep } from "./Steps/PermissionsStep";
import { SecurityKeywordsTab } from "./Steps/SecurityKeywordsTab";
import { useDocumentType } from "./hooks";
import { ApprovalStep } from "./Steps/ApprovalStep";

/**
 *
 *
 * @return {*}
 */
const ManageDocumentTypesPage: FunctionComponent = () => {
  const { colors } = useColors();
  const {
    i18n,
    isAdmin,
    resources,
    eventManager,
    breakpoint,
    breadCrumbEntries,
    organizationNavigate,

    permissionsList,
    dataElements,
    groupList,
    organizationRoles,
    organizationData,

    isNew,
    STEPS,
    lastStep,
    isLoading,
    formState,
    acceptedFormats,
    setFormState,
    selectedTabIndex,

    onPrev,
    onNext,
    setShowUpdateDocsModal,
    mutateSaveDocumentType,
    updateNameConfig,
    setIdentifierCollisionForcesNewVersion,
    setIdentifierConfig,
    onPermissionsChange,
    onChange,
    onContextDefinitionChange,
    onWizardDefinitionChange,

    addNewSecurityField,
    removeSecurityKeyword,

    allApprovalTypes,
    approvalTypeOptions,
    addApprovalType,
    moveApproveItem,
    onChangeApprovePermission,
    onChangeApproveExecOrder,
    dismissOnUpdateDocument,
    onRemoveApprovalType,
  } = useDocumentType();

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  return (
    <Page breadCrumbEntries={breadCrumbEntries} fixedHeight={true}>
      <Box display="flex" direction="column" flex="grow">
        <Box display="flex" direction="column" flex="grow">
          <StepTabs
            selectedTabIndex={selectedTabIndex}
            tabs={[
              {
                title: resources.general,
                description: resources.documentTypes.documentTypeStepDesc,
                content: (
                  <DocumentTypeStep
                    isNew={isNew}
                    isLoading={isLoading}
                    documentGroups={groupList}
                    roles={organizationRoles}
                    formState={formState}
                    acceptedFormats={acceptedFormats}
                    onChange={onChange}
                    setFormState={setFormState}
                    showAutoExtract={
                      organizationData?.settings.aIProviders &&
                      organizationData?.settings.aIProviders.length
                        ? true
                        : false
                    }
                  />
                ),
              },
              {
                title: resources.fields,
                description: `${resources.documentTypes.documentKeysStepDesc} ${formState.name}`,
                content: (
                  <DocumentKeyStep
                    dataElements={dataElements || emptyArray}
                    contextDefinition={formState.contextDefinition}
                    onContextDefinitionChange={onContextDefinitionChange}
                    wizardDefinition={formState.wizardDefinition}
                    error={formState.errors ? formState.errors["documentKeyStep"] : undefined}
                    setFormState={setFormState}
                    formState={formState}
                    disableContinueButton={setIsSaveButtonDisabled}
                  />
                ),
              },
              {
                title: resources.formConfiguration,
                content: (
                  <Box display="flex" direction="column" flex="grow">
                    <FormDesigner
                      dataElements={dataElements || emptyArray}
                      contextDefinition={formState.contextDefinition}
                      wizardDefinition={formState.wizardDefinition}
                      onWizardDefinitionChange={onWizardDefinitionChange}
                      nameConfig={formState.nameConfig}
                      updateNameConfig={updateNameConfig}
                      identifierCollisionForcesNewVersion={
                        formState.identifierCollisionForcesNewVersion
                      }
                      setIdentifierCollisionForcesNewVersion={
                        setIdentifierCollisionForcesNewVersion
                      }
                      identifierConfig={formState.identifierConfig}
                      setIdentifierConfig={setIdentifierConfig}
                      i18n={i18n}
                      onContextDefinitionChange={onContextDefinitionChange}
                      formDesignerEventManager={eventManager}
                      onCancel={() =>
                        organizationNavigate(organizationLinkTemplates.manageDocumentTypes())
                      }
                      setIsContinueButtonDisabled={setIsSaveButtonDisabled}
                      isContinueButtonDisabled={isSaveButtonDisabled}
                      mode="edit-document-type"
                    />
                  </Box>
                ),
              },
              {
                title: resources.permissions,
                content: (
                  <PermissionsStep
                    roles={organizationRoles || []}
                    permissionsList={permissionsList || []}
                    permissions={formState.permissions}
                    onPermissionsChange={onPermissionsChange}
                  />
                ),
              },
              ...(organizationData?.featureFlags?.allowSecurityFields === true
                ? [
                    {
                      title: resources.rolesManagement.securityKeys,
                      content: (
                        <SecurityKeywordsTab
                          resources={resources}
                          roles={organizationRoles || []}
                          isLoading={isLoading}
                          formState={formState}
                          setFormState={setFormState}
                          addNewSecurityField={addNewSecurityField}
                          removeSecurityKeyword={removeSecurityKeyword}
                        />
                      ),
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      title: resources.approvalType.approvals,
                      content: (
                        <ApprovalStep
                          formState={formState}
                          isLoading={isLoading}
                          resources={resources}
                          allApprovalTypes={allApprovalTypes}
                          approvalTypeOptions={approvalTypeOptions}
                          addApprovalType={addApprovalType}
                          moveApproveItem={moveApproveItem}
                          onChangeApprovePermission={onChangeApprovePermission}
                          onChangeApproveExecOrder={onChangeApproveExecOrder}
                          dismissOnUpdateDocument={dismissOnUpdateDocument}
                          onRemoveApprovalType={onRemoveApprovalType}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </Box>
        {formState.toastMessage && (
          <NotificationMessage
            type={formState.toastMessage.type}
            message={formState.toastMessage.message}
            delay={5000}
            position="bottom-left"
            handleDismiss={() => setFormState((prev) => ({ ...prev, toastMessage: undefined }))}
          />
        )}

        <Box
          justifyContent="between"
          color={colors.neutral300}
          direction="row"
          display="flex"
          padding={4}
          borderStyle="lg"
          borderRadius={4}
          // position="fixed"
          // width={"100%"}
        >
          <Box display="flex" flex="shrink">
            {selectedTabIndex > STEPS.DocumentType && (
              <Button
                color={"transparent"}
                disabled={isLoading || isSaveButtonDisabled}
                onClick={onPrev}
                text={resources.previousStep}
              />
            )}
          </Box>
          <Box flex="grow" />
          <Box display="flex" flex="shrink" justifyContent="end">
            <Button
              onClick={onNext}
              color={"blue"}
              disabled={isLoading || isSaveButtonDisabled}
              text={`${selectedTabIndex === lastStep ? resources.save : resources.continue}`}
            />
          </Box>
        </Box>
        {formState.showUpdateDocsModal && (
          <Layer zIndex={modalZIndex}>
            <Modal
              accessibilityModalLabel={resources.save}
              heading={
                <Box>
                  <Box marginStart={2} marginBottom={3}>
                    <Text weight="bold" size="400" color={colors.secondary}>
                      {resources.identifierConfig}
                    </Text>
                  </Box>
                  <Divider />
                </Box>
              }
              size="lg"
              padding="none"
              onDismiss={() => setShowUpdateDocsModal(false)}
              footer={
                <Box
                  display="flex"
                  direction={breakpoint === "small" ? "column" : "row"}
                  alignItems="center"
                  gap={3}
                  flex="grow"
                  justifyContent="between"
                >
                  <Box>
                    <Button
                      color={"transparent"}
                      onClick={() => mutateSaveDocumentType(false)}
                      text={resources.noJustSave}
                    />
                  </Box>
                  <Box>
                    <Button
                      color={"gray"}
                      onClick={() => mutateSaveDocumentType(true)}
                      text={resources.updateDocuments}
                    />
                  </Box>
                </Box>
              }
            >
              <Box paddingX={6}>
                <Box marginBottom={6}>
                  <Text>{resources.documentTypes.changesInIdentifierFound}</Text>
                </Box>
                <Divider />
              </Box>
            </Modal>
          </Layer>
        )}
      </Box>
    </Page>
  );
};

export default ManageDocumentTypesPage;
