import { FunctionComponent } from "react";
import { calculateColor, type ColorConfig } from "../utils";
import { useColors } from "../components";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const PrintSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_78_913)">
        <path
          d="M28.9987 17.8262H11.0007C10.8171 17.8262 10.6353 17.8624 10.4658 17.9327C10.2962 18.003 10.1422 18.1061 10.0125 18.2361C9.8828 18.366 9.78001 18.5202 9.71 18.6899C9.64 18.8596 9.60414 19.0415 9.60449 19.225V25.5186H11.5196V21.5841H27.7924V25.5186H30.3895V19.225C30.3902 18.8552 30.2442 18.5002 29.9834 18.2379C29.7227 17.9757 29.3685 17.8276 28.9987 17.8262ZM26.5915 21.092C26.4093 21.0899 26.2319 21.0339 26.0815 20.9311C25.9311 20.8283 25.8145 20.6833 25.7463 20.5144C25.6782 20.3454 25.6616 20.1601 25.6986 19.9817C25.7357 19.8034 25.8247 19.64 25.9544 19.5121C26.0841 19.3842 26.2488 19.2976 26.4277 19.2631C26.6066 19.2287 26.7916 19.248 26.9596 19.3185C27.1275 19.3891 27.2708 19.5078 27.3714 19.6596C27.472 19.8115 27.5254 19.9897 27.5249 20.1719C27.5249 20.2937 27.5008 20.4144 27.4539 20.5269C27.407 20.6393 27.3384 20.7414 27.2518 20.8272C27.1653 20.913 27.0626 20.9808 26.9497 21.0267C26.8368 21.0726 26.716 21.0957 26.5941 21.0946L26.5915 21.092Z"
          fill={calculateColor(color)}
        />
        <path
          d="M11.5195 21.584V30.5629H27.7923V21.584H11.5195ZM25.3851 28.1557H13.9268V23.9912H25.3851V28.1557Z"
          fill={calculateColor(color)}
        />
        <path
          d="M28.1405 11.923V16.2213H26.9369L25.7333 16.2025V13.0116L23.9332 11.4068H14.3203H14.2695V16.0019H11.8623V11.3159C11.886 10.6844 12.1571 10.0877 12.6169 9.65431C13.0768 9.22097 13.6886 8.98581 14.3203 8.9996H24.8506L28.1405 11.923Z"
          fill={calculateColor(color)}
        />
        <path d="M26.937 16.2082H25.7334V16.1895L26.937 16.2082Z" fill={calculateColor(color)} />
      </g>
      <defs>
        <clipPath id="clip0_78_913">
          <rect width="24" height="21.5634" fill="white" transform="translate(8 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};
