import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: "green" | "yellow" | "red";
  width?: number;
  height?: number;
};

export const RoundIndicator: FunctionComponent<Props> = ({
  customClassName,
  color = "green",
  width = 20,
  height = 20,
}) => {
  switch (color) {
    case "green":
      return <GreenIndicator width={width} height={height} customClassName={customClassName} />;
    case "yellow":
      return <YellowIndicator width={width} height={height} customClassName={customClassName} />;
    case "red":
      return <RedIndicator width={width} height={height} customClassName={customClassName} />;
  }
};

const GreenIndicator: FunctionComponent<Omit<Props, "color">> = ({
  customClassName,
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2602_48465)">
        <path
          d="M12.9954 10.2836C12.9954 8.6199 11.6467 7.27123 9.98303 7.27123C8.31937 7.27123 6.9707 8.6199 6.9707 10.2836C6.9707 11.9472 8.31937 13.2959 9.98303 13.2959C11.6467 13.2959 12.9954 11.9472 12.9954 10.2836Z"
          fill="#138804"
        />
        <path
          d="M2.37654 9.99925C2.37654 11.5072 2.82369 12.9812 3.66145 14.235C4.49921 15.4888 5.68995 16.466 7.08309 17.0431C8.47624 17.6202 10.0092 17.7711 11.4882 17.477C12.9671 17.1828 14.3256 16.4566 15.3919 15.3904C16.4581 14.3241 17.1843 12.9656 17.4785 11.4867C17.7726 10.0077 17.6217 8.47473 17.0446 7.08159C16.4675 5.68845 15.4903 4.49771 14.2365 3.65995C12.9827 2.82219 11.5087 2.37504 10.0008 2.37504C7.9793 2.37703 6.04121 3.18093 4.61182 4.61032C3.18244 6.0397 2.37854 7.97779 2.37654 9.99925ZM2 9.99925C2 8.41685 2.46924 6.86998 3.34837 5.55427C4.2275 4.23855 5.47705 3.21307 6.939 2.60752C8.40094 2.00196 10.0096 1.84352 11.5616 2.15223C13.1136 2.46094 14.5392 3.22294 15.6581 4.34186C16.7771 5.46079 17.5391 6.88638 17.8478 8.43838C18.1565 9.99037 17.998 11.5991 17.3925 13.061C16.7869 14.5229 15.7614 15.7725 14.4457 16.6516C13.13 17.5308 11.5832 18 10.0008 18C7.87882 18 5.8438 17.1571 4.34337 15.6566C2.84293 14.1562 2 12.1212 2 9.99925H2Z"
          fill="#6EE060"
        />
      </g>
      <defs>
        <clipPath id="clip0_2602_48465">
          <rect width="16" height="16" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
const YellowIndicator: FunctionComponent<Omit<Props, "color">> = ({
  customClassName,
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2602_48517)">
        <path
          d="M12.9954 10.2836C12.9954 8.6199 11.6467 7.27123 9.98303 7.27123C8.31937 7.27123 6.9707 8.6199 6.9707 10.2836C6.9707 11.9472 8.31937 13.2959 9.98303 13.2959C11.6467 13.2959 12.9954 11.9472 12.9954 10.2836Z"
          fill="#CDA300"
        />
        <path
          d="M2.37654 9.99925C2.37654 11.5072 2.82369 12.9812 3.66145 14.235C4.49921 15.4888 5.68995 16.466 7.08309 17.0431C8.47624 17.6202 10.0092 17.7711 11.4882 17.477C12.9671 17.1828 14.3256 16.4566 15.3919 15.3904C16.4581 14.3241 17.1843 12.9656 17.4785 11.4867C17.7726 10.0077 17.6217 8.47473 17.0446 7.08159C16.4675 5.68845 15.4903 4.49771 14.2365 3.65995C12.9827 2.82219 11.5087 2.37504 10.0008 2.37504C7.9793 2.37703 6.04121 3.18093 4.61182 4.61032C3.18244 6.0397 2.37854 7.97779 2.37654 9.99925ZM2 9.99925C2 8.41685 2.46924 6.86998 3.34837 5.55427C4.2275 4.23855 5.47705 3.21307 6.939 2.60752C8.40094 2.00196 10.0096 1.84352 11.5616 2.15223C13.1136 2.46094 14.5392 3.22294 15.6581 4.34186C16.7771 5.46079 17.5391 6.88638 17.8478 8.43838C18.1565 9.99037 17.998 11.5991 17.3925 13.061C16.7869 14.5229 15.7614 15.7725 14.4457 16.6516C13.13 17.5308 11.5832 18 10.0008 18C7.87882 18 5.8438 17.1571 4.34337 15.6566C2.84293 14.1562 2 12.1212 2 9.99925H2Z"
          fill="#FAC700"
        />
      </g>
      <defs>
        <clipPath id="clip0_2602_48517">
          <rect width="16" height="16" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
const RedIndicator: FunctionComponent<Omit<Props, "color">> = ({
  customClassName,
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2602_48567)">
        <path
          d="M12.9954 10.2836C12.9954 8.6199 11.6467 7.27123 9.98303 7.27123C8.31937 7.27123 6.9707 8.6199 6.9707 10.2836C6.9707 11.9472 8.31937 13.2959 9.98303 13.2959C11.6467 13.2959 12.9954 11.9472 12.9954 10.2836Z"
          fill="#FE6564"
        />
        <path
          d="M2.37654 9.99925C2.37654 11.5072 2.82369 12.9812 3.66145 14.235C4.49921 15.4888 5.68995 16.466 7.08309 17.0431C8.47624 17.6202 10.0092 17.7711 11.4882 17.477C12.9671 17.1828 14.3256 16.4566 15.3919 15.3904C16.4581 14.3241 17.1843 12.9656 17.4785 11.4867C17.7726 10.0077 17.6217 8.47473 17.0446 7.08159C16.4675 5.68845 15.4903 4.49771 14.2365 3.65995C12.9827 2.82219 11.5087 2.37504 10.0008 2.37504C7.9793 2.37703 6.04121 3.18093 4.61182 4.61032C3.18244 6.0397 2.37854 7.97779 2.37654 9.99925ZM2 9.99925C2 8.41685 2.46924 6.86998 3.34837 5.55427C4.2275 4.23855 5.47705 3.21307 6.939 2.60752C8.40094 2.00196 10.0096 1.84352 11.5616 2.15223C13.1136 2.46094 14.5392 3.22294 15.6581 4.34186C16.7771 5.46079 17.5391 6.88638 17.8478 8.43838C18.1565 9.99037 17.998 11.5991 17.3925 13.061C16.7869 14.5229 15.7614 15.7725 14.4457 16.6516C13.13 17.5308 11.5832 18 10.0008 18C7.87882 18 5.8438 17.1571 4.34337 15.6566C2.84293 14.1562 2 12.1212 2 9.99925H2Z"
          fill="#F72D2C"
        />
      </g>
      <defs>
        <clipPath id="clip0_2602_48567">
          <rect width="16" height="16" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
