import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
};

export const CaretExpandedSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 25,
  height = 25,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9814 9.34539L16.0041 15.6867C15.9943 15.9829 15.8775 16.2656 15.6753 16.4822C15.4608 16.6904 15.1751 16.8093 14.8762 16.8146L8.53773 16.7891C8.31522 16.7891 8.09771 16.7232 7.91269 16.5996C7.72767 16.476 7.58345 16.3003 7.49828 16.0947C7.4053 15.896 7.37671 15.6732 7.41652 15.4574C7.45632 15.2416 7.56253 15.0437 7.72032 14.8912L14.0616 8.5499C14.2189 8.39241 14.4193 8.2851 14.6376 8.24155C14.8558 8.198 15.0821 8.22017 15.2878 8.30524C15.4933 8.39029 15.6689 8.53452 15.7923 8.7196C15.9157 8.90468 15.9813 9.12225 15.9807 9.34469L15.9814 9.34539Z"
        fill="#767676"
      />
    </svg>
  );
};
