import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const DocumentPropertiesSVG: FunctionComponent<Props> = ({
  width = 31,
  height = 28,
  color,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6052_119987)">
        <path
          d="M13.9937 0C6.26266 0 0 6.26266 0 14.0063C0 21.7499 6.26266 28 13.9937 28C21.7248 28 28 21.7373 28 14.0063C28 6.27521 21.7248 0 13.9937 0ZM15.9767 20.294C15.3994 21.0345 14.8346 21.7875 14.0063 22.277C13.203 22.7539 12.3496 23.03 11.4209 22.6786C10.4671 22.3147 10.1156 21.524 10.4169 20.5199C11.2075 17.8844 12.0108 15.2362 12.8014 12.6006C12.8893 12.2994 13.1654 11.8978 12.7638 11.6719C12.5504 11.5589 12.3747 11.6342 12.2116 11.7597V10.9314C12.7136 10.5424 13.2532 10.216 13.8682 9.99014C14.5961 9.72658 15.2613 9.82698 15.8135 10.3667C16.3407 10.8938 16.4034 11.5589 16.19 12.2618C15.3868 14.9099 14.5961 17.5455 13.8055 20.1811C13.7176 20.4697 13.4792 20.8463 13.8682 20.9969C14.069 21.0722 14.4706 20.8839 14.6589 20.6831C15.1735 20.156 15.6378 19.5661 16.1147 19.0013C16.1775 18.926 16.2403 18.8507 16.3783 18.6876C16.416 19.3528 16.3407 19.8422 15.9767 20.294ZM15.9265 8.69744C14.8974 8.69744 14.1443 7.96952 14.1318 6.95294C14.1192 5.93635 14.8723 5.18333 15.8888 5.17077C16.918 5.15822 17.671 5.8987 17.6835 6.90273C17.6835 7.93187 16.9431 8.68489 15.9265 8.69744Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6052_119987">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
