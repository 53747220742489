import {
  Box,
  Button,
  Divider,
  Heading,
  Link,
  PasswordSetterField,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";

import { PasswordStrength } from "../../utils";
import { organizationLinkTemplates } from "../../link-templates";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useNavigate } from "react-router-dom";

interface Props {
  email: string | null;
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  passwordChanged: boolean;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  popoverRef: React.RefObject<HTMLInputElement>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: HTMLInputElement | null;
  passwordStrength: PasswordStrength | undefined;
  setShowConfirmPassword: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  showConfirmPassword: boolean;
  confirmedPassword: string;
  setConfirmedPassword: React.Dispatch<React.SetStateAction<string>>;
  passwordNotMatch: boolean | "";
  submit: boolean;
  isLoading: boolean;
  changePasswordClick: () => void;
  errorMessage: string;
}
export function PasswordRecoveryMedium({
  errorMessage,
  changePasswordClick,
  isLoading,
  submit,
  passwordNotMatch,
  setConfirmedPassword,
  confirmedPassword,
  showConfirmPassword,
  setShowConfirmPassword,
  passwordStrength,
  setPassword,
  password,
  passwordChanged,
  setShowPassword,
  email,
  showPassword,
}: Props) {
  const { colors } = useColors();
  const navigate = useNavigate();
  const { resources } = useAppTranslation();
  return (
    <Box maxWidth={520} marginBottom="auto" alignSelf="center" right padding={12} rounding="pill">
      <Box paddingY={2}>
        <Heading size="400" color={colors.black600}>
          {resources.changePassword}
        </Heading>
      </Box>
      <Box paddingY={4}>
        <Text inline={true} overflow="breakWord" align="start" color={colors.neutral900}>
          {resources.enterNewPassword}
          <Text inline={true} weight="bold">
            "{email}".
          </Text>
        </Text>
      </Box>
      <Divider />
      <Box paddingY={5}>
        <Box marginBottom={4} direction="column">
          <Box display="flex" gap={3} direction="column">
            <Box display="flex" direction="row" justifyContent="between" marginBottom={2}>
              <Text weight="bold">{resources.password}</Text>
              <Link
                href=""
                onClick={(event) => {
                  setShowPassword(!showPassword);
                  event.event.preventDefault();
                }}
              >
                <Text color={colors.primary}>{showPassword ? resources.hide : resources.show}</Text>
              </Link>
            </Box>
            <PasswordSetterField
              id="newPasswordField"
              value={password}
              onChange={setPassword}
              resources={resources}
              labelDisplay="hidden"
              showPassword={showPassword}
            />
          </Box>
        </Box>
        <Box marginBottom={4} direction="column">
          <Box display="flex" gap={3} direction="column">
            <Box display="flex" direction="row" justifyContent="between" marginBottom={2}>
              <Text weight="bold">{resources.adminChangePassword.confirmPassword}</Text>
              <Link
                href=""
                onClick={(event) => {
                  setShowConfirmPassword(!showConfirmPassword);
                  event.event.preventDefault();
                }}
              >
                <Text color={colors.primary}>{showConfirmPassword ? "Hide" : "Show"}</Text>
              </Link>
            </Box>
            <TextField
              disabled={!passwordStrength?.success || passwordChanged}
              value={confirmedPassword}
              type={showConfirmPassword ? "text" : "password"}
              label={resources.adminChangePassword.confirmPassword}
              labelDisplay="hidden"
              onChange={(e) => setConfirmedPassword(e.value)}
              id="confirmedPasswordField"
              placeholder={resources.adminChangePassword.confirmPassword}
              errorMessage={
                passwordNotMatch ? resources.adminChangePassword.passwordNotMatch : null
              }
            ></TextField>
          </Box>
        </Box>
      </Box>
      {passwordChanged ? (
        <Button
          size="lg"
          text={resources.forgotPasswordPage.backToLogin}
          accessibilityLabel={resources.forgotPasswordPage.backToLogin}
          fullWidth={true}
          type="button"
          color="gray"
          onClick={() => navigate(organizationLinkTemplates.login())}
        />
      ) : (
        <>
          <Button
            type="button"
            disabled={!submit || isLoading}
            size="lg"
            text={resources.adminChangePassword.setNewPassword}
            accessibilityLabel={resources.adminChangePassword.setNewPassword}
            fullWidth={true}
            color={"blue"}
            onClick={changePasswordClick}
          />
          {errorMessage && <Text color={colors.error}>{errorMessage}</Text>}
        </>
      )}
    </Box>
  );
}
