import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const ViewerPreviousPageIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1443 15.6883L12.4572 19.6168C12.2188 19.8166 12.2188 20.1834 12.4572 20.3832L17.1443 24.3117C17.4697 24.5845 17.9655 24.3531 17.9655 23.9285V22C17.9655 21.7239 18.1894 21.5 18.4655 21.5H28.5C28.7761 21.5 29 21.2761 29 21V19C29 18.7239 28.7761 18.5 28.5 18.5H18.4655C18.1894 18.5 17.9655 18.2761 17.9655 18V16.0715C17.9655 15.6469 17.4697 15.4155 17.1443 15.6883Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
    </svg>
  );
};
