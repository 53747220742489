import type {
  DocumentCollectionDocumentView,
  DocumentCollectionFolderView,
} from "@prodoctivity/shared/src/index-types";

export const findFolderByPath = (
  folder: DocumentCollectionFolderView,
  folderPath: Array<string>
): DocumentCollectionFolderView | undefined => {
  const currentPathSegment = folderPath[0];

  if (!currentPathSegment) {
    return folder;
  }

  const foundFolder = folder.folders.find((item) => item.name === currentPathSegment);

  if (!foundFolder) {
    return undefined;
  }

  return findFolderByPath(foundFolder, folderPath.slice(1));
};

export const findDocumentInFolders = (
  folders: Array<DocumentCollectionFolderView>,
  id: string,
  findBy: "documentId" | "documentTypeId"
): { document: DocumentCollectionDocumentView | undefined; path: Array<string> } => {
  for (const folder of folders) {
    if (folder.documents) {
      const foundDocument = folder.documents.find(
        (doc) => doc.type === "document" && doc[findBy] === id
      );
      if (foundDocument) {
        return { document: foundDocument, path: [folder.name] };
      }
    }

    if (folder.folders) {
      const foundInSubFolder = findDocumentInFolders(folder.folders, id, findBy);
      if (foundInSubFolder.document) {
        return {
          document: foundInSubFolder.document,
          path: [folder.name, ...foundInSubFolder.path],
        };
      }
    }
  }

  return { document: undefined, path: [] };
};

export const folderSort = (
  currentSort: DocumentCollectionFolderView["currentSort"],
  a: DocumentCollectionFolderView,
  b: DocumentCollectionFolderView
) => {
  if (currentSort.type === "folder-name") {
    return a.name.localeCompare(b.name);
  } else if (currentSort.type === "document-date" || currentSort.type === "creation-date") {
    return a.createdAt - b.createdAt;
  } else if (currentSort.type === "update-date") {
    return a.updatedAt - b.updatedAt;
  }
  return 0; // Default: no sorting
};

export const documentSort = (
  currentSort: DocumentCollectionFolderView["currentSort"],
  a: DocumentCollectionDocumentView,
  b: DocumentCollectionDocumentView
) => {
  if (currentSort.type === "document-date") {
    if (a.type === "missing-document") {
      if (b.type === "missing-document") {
        if (currentSort.direction === "asc") {
          return (a.name || "").localeCompare(b.name || "");
        } else {
          return (b.name || "").localeCompare(a.name || "");
        }
      } else {
        if (currentSort.direction === "asc") {
          return 1;
        } else {
          return -1;
        }
      }
    } else {
      if (b.type === "missing-document") {
        if (currentSort.direction === "asc") {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (currentSort.direction === "asc") {
          return a.documentDate - b.documentDate;
        } else {
          return b.documentDate - a.documentDate;
        }
      }
    }
  }
  if (currentSort.type === "folder-name") {
    return a.name.localeCompare(b.name);
  } else if (currentSort.type === "creation-date" || currentSort.type === "update-date") {
    if (a.type === "missing-document") {
      return -1;
    } else if (b.type === "missing-document") {
      return 1;
    }
    return currentSort.type === "creation-date"
      ? a.createdAt - b.createdAt
      : a.updatedAt - b.updatedAt;
  }
  return 0; // Default: no sorting
};
