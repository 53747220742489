import { useCallback, useState } from "react";
import { RecentSearchesDataType } from "./hooks";
import { DocumentSearchFilter, organizationLinkTemplates } from "../../link-templates";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { FieldCondition } from "../../components/OpenSearch/hooks";

export function useBuildSearchurl() {
  const organizationNavigate = useOrganizationNavigate();

  const [filterByFields, setFilters] = useState<FieldCondition[]>([]);

  const buildRecentSearchUrl = useCallback(
    (
      searchQuery: string,
      category: RecentSearchesDataType["category"],
      searchType: RecentSearchesDataType["searchType"],
      documentId?: string,
      filter?: DocumentSearchFilter,
      specificFilter?: string,
      index?: number
    ) => {
      if (searchType === "suggestion") {
        if (documentId && category === "document") {
          return organizationNavigate(organizationLinkTemplates.documentId(documentId, "data"));
        } else if (documentId && category === "collection") {
          console.log(organizationLinkTemplates.documentCollection(documentId, "data"));
          return organizationNavigate(
            organizationLinkTemplates.documentCollection(documentId, "data")
          );
        } else {
          if (documentId) {
            return organizationNavigate(
              organizationLinkTemplates.combinedTemplateDetails(documentId)
            );
          }
        }
      }
      if (category === "collection") {
        return organizationNavigate(
          organizationLinkTemplates.documentCollectionList({
            pageNumber: 0,
            rowsPerPage: "15",
            filter: searchQuery,
            assignedTo: "",
          })
        );
      }

      if (category === "template") {
        return organizationNavigate(organizationLinkTemplates.templateList(false, searchQuery));
      }
      const queryParams: Array<string> = [];

      if (searchQuery) {
        queryParams.push(`q=${encodeURIComponent(searchQuery)}`);
      } else if (filter?.query && specificFilter !== "query") {
        queryParams.push(`q=${encodeURIComponent(filter.query)}`);
      }
      const queryParamsUrl = queryParams.join("&");
      const ObjFieldQueryParams = {
        fieldQueryParams: "",
      };
      if (searchQuery && searchQuery === "document" && !filter) {
        ObjFieldQueryParams.fieldQueryParams = filterByFields
          .filter((f) => f.value !== "" && f.value !== undefined)
          .map((item, index) => {
            if (item.value === undefined) return null;

            let value = item.value.toString();
            const dataType = item.field.properties.dataType;
            const equalityOperator = item.equalityOperator;

            if (
              dataType === "Alphanumeric" &&
              (equalityOperator === "starts-with" || equalityOperator === "ends-with")
            ) {
              value = value.replace(/\*/g, "");
            }

            return `${index === 0 ? "&" : ""}fields[${index}][fld]=${encodeURIComponent(
              item.field.name
            )}&fields[${index}][val]=${value}&fields[${index}][op]=${encodeURIComponent(
              equalityOperator
            )}&fields[${index}][dataType]=${dataType}`;
          })
          .join("&");
      } else if (filter) {
        if (filter.fields.length > 0) {
          ObjFieldQueryParams.fieldQueryParams = filter.fields
            .filter((_f, indexSecondary) => indexSecondary !== index)
            .map((item, index) => {
              if (item.val === undefined) return null;

              let value = item.val.toString();
              const dataType = item.dataType;
              const equalityOperator = item.op;

              if (
                dataType === "Alphanumeric" &&
                (equalityOperator === "starts-with" || equalityOperator === "ends-with")
              ) {
                value = value.replace(/\*/g, "");
              }

              return `${index === 0 ? "&" : ""}fields[${index}][fld]=${encodeURIComponent(
                item.fld
              )}&fields[${index}][val]=${value}&fields[${index}][op]=${encodeURIComponent(
                equalityOperator
              )}&fields[${index}][dataType]=${dataType}`;
            })
            .join("&");
        } else {
          ObjFieldQueryParams.fieldQueryParams = "";
        }
      }
      const totalFieldsToFilterBy = () => {
        if (filterByFields.length > 0) {
          const totalFilters = filterByFields.filter(
            (f) => f.value !== "" && f.value !== undefined
          ).length;
          return totalFilters;
        } else if (filter) {
          const totalFilters = filter.fields.filter(
            (_f, indexSecondary) => indexSecondary !== index
          ).length;
          return totalFilters;
        } else {
          return 0;
        }
      };

      const queryPageNumber = filter ? filter.pageNumber : "0";
      const queryRowsPerPage = filter ? filter.rowsPerPage : "15";
      const paginationQuery = `&pageNumber=${queryPageNumber}&rowsPerPage=${queryRowsPerPage}`;

      const builtQuery = `/search?${
        queryParamsUrl +
        "&" +
        "docTypesSelected=" +
        0 +
        ObjFieldQueryParams.fieldQueryParams +
        "&" +
        "searchCriteria=" +
        totalFieldsToFilterBy() +
        paginationQuery
      }`;

      organizationNavigate(builtQuery);
      return builtQuery;
    },
    [filterByFields, organizationNavigate]
  );

  return { buildRecentSearchUrl, setFilters };
}
