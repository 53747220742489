import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DateIconsSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 20,
  height = 20,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      className={customClassName}
      style={svgStyle}
      width={width}
      height={height}
      viewBox={`0 0 36 28`}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.67112 14.4607C1.36978 14.4607 1.36888 14.4602 1.36888 14.1689C1.36888 11.5384 1.36888 8.90837 1.36888 6.27788C1.36888 6.20073 1.37745 6.12359 1.38241 6.04821H14.9422V8.41978H16.1846V8.16839C16.1846 6.71282 16.1782 5.25725 16.1868 3.80167C16.1922 2.86661 15.4226 2.21176 14.3152 2.4494C14.3152 2.65025 14.3228 2.85641 14.3138 3.06169C14.2908 3.57245 13.9435 4.00473 13.4423 4.15903C12.9578 4.30844 12.435 4.13774 12.1142 3.7365C11.8792 3.44299 11.8571 3.10293 11.8575 2.75089C11.858 2.39443 11.9365 2.40551 11.5115 2.40551C9.91824 2.40551 8.32448 2.40551 6.73118 2.40551C5.98099 2.40551 5.2308 2.40551 4.43685 2.40551C4.43685 2.64803 4.44407 2.86129 4.4355 3.07411C4.40437 3.83581 3.60411 4.3878 2.86565 4.16036C2.28237 3.98079 1.98599 3.54762 1.98329 2.87237C1.98208 2.56113 1.82314 2.4058 1.50647 2.4064C0.687711 2.40817 0.118866 2.96593 0.118866 3.76886C0.118415 7.279 0.129241 10.7887 0.109393 14.2979C0.105784 14.951 0.65072 15.6892 1.51865 15.6848C3.84139 15.6733 6.16459 15.6808 8.48733 15.6808H8.74717V14.4602C8.63845 14.4602 8.54913 14.4602 8.45936 14.4602C6.19662 14.4602 3.93387 14.4602 1.67112 14.4602V14.4607Z"
        fill="#7F7F7F"
      />
      <path
        d="M15.0534 9.63571C12.2349 9.58207 10.0488 11.8149 9.99332 14.3554C9.93332 17.1064 12.2655 19.3246 14.9198 19.2865C17.5945 19.3392 19.8351 17.169 19.8901 14.5744C19.9479 11.8694 17.7014 9.68626 15.0534 9.63571ZM14.9221 18.0694C12.8939 18.0668 11.2515 16.4529 11.2519 14.4626C11.2519 12.4546 12.8993 10.8598 14.9722 10.8612C16.9927 10.8625 18.6324 12.483 18.6315 14.4782C18.6306 16.4702 16.976 18.0721 14.9221 18.0694Z"
        fill="#7F7F7F"
      />
      <path
        d="M5.05621 7.22883C4.26452 7.22883 3.49989 7.23105 2.73572 7.22706C2.58279 7.22617 2.59362 7.3175 2.59407 7.41726C2.59543 8.02911 2.59452 8.6414 2.59497 9.25325C2.59497 9.37561 2.59497 9.49798 2.59497 9.62922H5.05666V7.22883H5.05621Z"
        fill="#7F7F7F"
      />
      <path
        d="M12.3363 7.23512C11.5712 7.22758 10.8057 7.2298 10.0117 7.2298V9.62886H12.473C12.473 8.86094 12.4752 8.10944 12.4671 7.35793C12.4666 7.31492 12.3823 7.23556 12.3363 7.23512Z"
        fill="#7F7F7F"
      />
      <path
        d="M6.29393 9.63229H8.73712V7.22924C7.96212 7.22924 7.21238 7.23235 6.46219 7.22658C6.31243 7.22525 6.29167 7.29353 6.29303 7.4159C6.29709 7.90272 6.29438 8.38953 6.29438 8.87635C6.29438 9.12464 6.29393 9.37248 6.29393 9.63229Z"
        fill="#7F7F7F"
      />
      <path
        d="M2.59945 13.1261C2.5999 13.1696 2.68381 13.2489 2.72982 13.2494C3.49535 13.2569 4.26133 13.2547 5.04896 13.2547V10.8716H2.59404C2.59404 11.6364 2.59178 12.3812 2.5999 13.1261H2.59945Z"
        fill="#7F7F7F"
      />
      <path d="M6.31641 13.2435H8.75508V10.8706H6.31641V13.2435Z" fill="#7F7F7F" />
      <path
        d="M3.21819 3.61346C3.58088 3.61124 3.82719 3.37626 3.8308 3.01846C3.83486 2.61233 3.8317 2.20577 3.8317 1.7992C3.83034 1.7992 3.82899 1.7992 3.82764 1.7992C3.82764 1.41524 3.82854 1.03084 3.82764 0.646888C3.82583 0.252734 3.59126 0.00223204 3.22316 1.52063e-05C2.84603 -0.00220163 2.59973 0.238103 2.59747 0.630483C2.59251 1.42854 2.59251 2.2266 2.59747 3.02422C2.59973 3.37803 2.85776 3.61523 3.21774 3.61346H3.21819Z"
        fill="#7F7F7F"
      />
      <path
        d="M13.1063 3.61358C13.4595 3.60737 13.699 3.37504 13.7017 3.02789C13.7049 2.62176 13.7026 2.2152 13.7026 1.80907C13.704 1.80907 13.7053 1.80907 13.7067 1.80907C13.7067 1.41004 13.7085 1.01145 13.7062 0.612424C13.704 0.255957 13.4577 0.00589783 13.1094 0.000577425C12.7386 -0.00474298 12.4779 0.228468 12.4743 0.599123C12.4666 1.41181 12.4675 2.22451 12.4734 3.0372C12.4761 3.38524 12.7445 3.62023 13.1063 3.61402V3.61358Z"
        fill="#7F7F7F"
      />
      <path
        d="M16.7427 14.461C16.158 14.4601 15.5734 14.461 14.9495 14.461C14.9495 13.8585 14.9504 13.2843 14.9495 12.7102C14.9486 12.3094 14.7037 12.0522 14.3266 12.0518C13.9571 12.0518 13.7054 12.3173 13.7045 12.7164C13.7027 13.4838 13.7027 14.2513 13.7045 15.0188C13.7054 15.4218 13.9679 15.6794 14.3816 15.6807C15.1701 15.6829 15.9582 15.6825 16.7467 15.6807C17.1609 15.6798 17.4247 15.4276 17.4193 15.0432C17.4144 14.6911 17.1491 14.4619 16.7427 14.461Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
