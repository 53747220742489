import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const ClosePanelXIcon: FunctionComponent<Props> = ({
  customClassName,
  width,
  height,
  color,
}) => {
  return (
    <svg
      width={width}
      className={customClassName || undefined}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26 14L14 26ZM14 14L26 26Z" fill={color ? calculateColor(color) : "#CACBCE"} />
      <path
        d="M26 14L14 26M14 14L26 26"
        stroke="#707070"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
