import {
  Box,
  ChevronSvgIcon,
  ComboBox,
  Divider,
  RenderedControl,
  SelectList,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";

import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import type { CloseConfig } from "@prodoctivity/types";
import { useCloseConfig } from "../../hooks";
import type { ContextField } from "@prodoctivity/shared/src/index-types";
import { TreeItemSettingsProps } from "./TreeItemSettings";

type CloseConfigProps = {
  closeConfig?: CloseConfig;
  resources: ReturnType<typeof useAppTranslation>["resources"];
  folderDocumentTypeOptions: {
    label: string;
    value: string;
  }[];
  folderMergedContextFields: ContextField[];
  setConfigItem: TreeItemSettingsProps["setConfigItem"];
};

export const CloseConfigComponent = ({
  closeConfig,
  resources,
  folderDocumentTypeOptions,
  folderMergedContextFields,
  setConfigItem,
}: CloseConfigProps) => {
  const { colors } = useColors();

  const {
    moment,
    dataElementInfo,
    fieldsOptions,
    isExpanded,
    setIsExpanded,
    closeConfigOptions,
    selectedDocumentType,
    onSelectType,
    onSelectDocumentType,
    onChangeFieldName,
    onChangeFieldValue,
    // onExpressionChange,
  } = useCloseConfig(
    folderDocumentTypeOptions,
    folderMergedContextFields,
    setConfigItem,
    closeConfig
  );
  return (
    <Box borderRadius={12} borderStyle="lg" marginBottom={1} width="100%">
      <TapArea onTap={() => setIsExpanded((prev) => !prev)}>
        <Box
          height={48}
          alignItems="center"
          color={colors.neutral200}
          paddingX={3}
          display="flex"
          justifyContent="between"
        >
          <Text weight="bold" color={colors.subtle}>
            {resources.documentCollection.closeConfig.closingCondition}
          </Text>
          <ChevronSvgIcon direction={isExpanded ? "up" : "down"} color={colors.subtle} />
        </Box>
      </TapArea>

      {isExpanded && (
        <>
          <Divider />
          <Box padding={4} display="flex" direction="column" gap={4}>
            <Box display="flex" gap={2} alignItems="center" flex="grow">
              <Text>{resources.documentCollection.closeConfig.closingCondition}: </Text>
              <Box display="flex" flex="grow">
                <Box width={"100%"}>
                  <SelectList
                    id="type"
                    label=""
                    onChange={(e) => {
                      onSelectType(e.value as CloseConfig["type"]);
                    }}
                    size="lg"
                    options={closeConfigOptions}
                    value={closeConfig?.type || "none"}
                  />
                </Box>
              </Box>
            </Box>
            {closeConfig?.type === "document-type-exists" && (
              <Box display="flex" gap={2} alignItems="center" flex="grow">
                <Text>{resources.documentTypes.documentType}: </Text>
                <Box display="flex" flex="grow">
                  <Box width={"100%"}>
                    <ComboBox
                      id="documentTypeId"
                      label=""
                      size="lg"
                      options={folderDocumentTypeOptions}
                      onSelect={(e) => onSelectDocumentType(e.item.value)}
                      onChange={(e) => {
                        const dt = folderDocumentTypeOptions.find((dt) => dt.label === e.value);
                        if (dt) {
                          onSelectDocumentType(dt.value);
                        }
                      }}
                      placeholder={selectedDocumentType?.label}
                      inputValue={selectedDocumentType?.label}
                      selectedOption={selectedDocumentType}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {closeConfig?.type === "field-value" && (
              <Box display="flex">
                <Box display="flex" alignItems="center" gap={3}>
                  <Box minWidth={105}>
                    <Text color={colors.subtle}>{resources.field}:</Text>
                  </Box>
                  <Box flex="grow">
                    <ComboBox
                      id={"fieldName"}
                      label=""
                      inputValue={
                        fieldsOptions.find((f) => f.value === closeConfig.fieldName)?.label
                      }
                      placeholder={resources.field}
                      onSelect={(e) => onChangeFieldName(e.item.value)}
                      options={fieldsOptions}
                    />
                  </Box>
                  <Box>
                    <Text color={colors.subtle}>{`${resources.equals}`.toLocaleLowerCase()}</Text>
                  </Box>
                  <Box flex="grow">
                    {dataElementInfo && (
                      <RenderedControl
                        instanceFullPath={""}
                        contextField={dataElementInfo.contextField}
                        typeValue={dataElementInfo.typeValue}
                        onCommitChange={onChangeFieldValue}
                        disabled={false}
                        error={undefined}
                        isFocused={false}
                        setIsFocused={(v) => console.log(v)}
                        onBlur={console.log}
                        getContextValueAsString={(fullPath: string) => fullPath}
                        moment={moment}
                        resources={resources}
                        componentBreakpoint={"small"}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {/* {closeConfig?.type === "logical-expression" && (
              <Box display="flex">
                <ExpressionControl
                  name="expression"
                  expression={expressionDataInput}
                  hideName={true}
                  fields={[]}
                  contextualIf={{ node: <></>, value: () => true }}
                  onExpressionChanged={() => onExpressionChangeTemp("", closeConfig.expression)}
                  moment={moment}
                  translate={i18n}
                  resources={resources}
                />
              </Box>
            )} */}
          </Box>
        </>
      )}
    </Box>
  );
};
