import { useCallback, useMemo, useState } from "react";

import { useDesignBreakpoint, usePubSub } from "@prodoctivity/design-system";
import type {
  TemplateSubscriptionType,
  UpdateTemplateDefinitionCommand,
} from "@prodoctivity/shared/src/index-types";
import type { HttpSyncDocumentGenerationRequest } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useCopyToClipboard } from "usehooks-ts";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { TemplateEditWizardEvent } from "../../components/TemplateEditWizard/hooks";
import { useTemplateInfo, useTemplateVersionList } from "../../hooks";
import { useAppDocumentTitle } from "../../hooks/useAppDocumentTitle";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";

type State = {
  step: "form" | "summary" | "document-viewer";
  status: "idle" | "generating";
  loading: boolean;
  isSaving: boolean;
};

export function useTemplateEdit() {
  const { templateVersionId } = useParams();
  const { resources } = useAppTranslation();
  const { eventManager } = usePubSub<TemplateEditWizardEvent>();

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const { updateTemplateDefinition } = useServices();

  const { data: template, isLoading } = useTemplateInfo(templateVersionId);
  const [state, setState] = useState<State>({
    step: "form",
    status: "idle",
    isSaving: false,
    loading: false,
  });

  const onTabClick = useCallback((index: number) => {
    setSelectedTabIndex(index);
  }, []);

  const organizationNavigate = useOrganizationNavigate();

  const onFinish = useCallback(
    async (templateVersionId: string, documentTypeSent: UpdateTemplateDefinitionCommand) => {
      return updateTemplateDefinition(templateVersionId, documentTypeSent)
        .then((data) => {
          setState((prevState) => ({
            ...prevState,
            loading: true,
            toastMessage: {
              type: "success",
              message: resources.accountPage.successApplyingChange,
            },
          }));
          setTimeout(() => {
            organizationNavigate(`/templates/versions/${data.templateVersionId}/details`);
            setState((prevState) => ({
              ...prevState,
              loading: false,
            }));
          }, 3000);
        })
        .catch((ex) => {
          console.log(ex);
          setState((prevState) => ({
            ...prevState,
            toastMessage: {
              type: "error",
              message:
                resources.error +
                (ex.response.data.errors.length > 0 ? ex.response.data.errors[0].message : ex),
            },
            loading: false,
            isSaving: false,
          }));
        });
    },
    [
      organizationNavigate,
      resources.accountPage.successApplyingChange,
      resources.error,
      updateTemplateDefinition,
    ]
  );
  return {
    isLoading,
    //isSuccess,
    state,
    selectedTabIndex,
    data: template,
    resources,
    eventManager,
    setSelectedTabIndex,
    onTabClick,
    setState,
    onFinish,
  };
}

export function useTemplateDetail() {
  const { templateVersionId } = useParams();
  const { getTemplateContextSampleRequest } = useServices();
  const { data: templateInfo, isLoading, remove, refetch } = useTemplateInfo(templateVersionId);
  const editable = useMemo(() => templateInfo?.info?.myPermissions.edit ?? false, [templateInfo]);
  const { resources, moment } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const { updateTemplateSubscription } = useServices();
  const organizationNavigate = useOrganizationNavigate();

  const template = useMemo(() => (templateInfo ? templateInfo.info : undefined), [templateInfo]);
  const documentTitle = useMemo(
    () =>
      `${resources.template} - ${
        isLoading ? resources.loading : template?.informationDefinition?.name
      }`,
    [resources, isLoading, template]
  );

  useAppDocumentTitle(documentTitle);

  const { data: versionList, isLoading: versionListLoading } = useTemplateVersionList(
    templateVersionId || ""
  );

  const { data: sample, isLoading: sampleLoading } = useOrganizationQuery(
    `template-version-details/${templateVersionId}/sample-request`,
    () => {
      if (!templateVersionId) {
        return;
      }
      return getTemplateContextSampleRequest([templateVersionId]);
    }
  );

  const versions = useMemo(() => (versionList ? versionList.templateVersions : []), [versionList]);

  const publishedMessage = useMemo(() => {
    if (template && versions) {
      return versions.some((version) => version.templateVersionId === template.templateVersionId)
        ? versions.find((version) => version.templateVersionId === template.templateVersionId)
            ?.isPublished
          ? resources.templateDetailsPage.thisIsThePublishedVersion
          : resources.templateDetailsPage.thisIsNotThePublishedVersion
        : "";
    }
    return "";
  }, [template, versions, resources]);

  const verDate = useMemo(() => {
    if (template && versions) {
      return (
        versions.find((version) => version.templateVersionId === template.templateVersionId)
          ?.updateDate || 0
      );
    }
    return 0;
  }, [template, versions]);

  const templateVersion = useMemo(() => {
    if (template && versions) {
      return (
        versions.length -
        [...versions]
          .reverse()
          .findIndex((version) => version.templateVersionId === template.templateVersionId)
      );
    }
    return -1;
  }, [template, versions]);

  const loading = isLoading || !template;

  const templateGenerationSubscription:
    | { type: TemplateSubscriptionType; mobile: boolean; web: boolean }
    | undefined = useMemo(() => {
    const sub = template?.mySubscriptions.generation;
    if (!sub) {
      return undefined;
    }

    return {
      type: "generation",
      mobile: sub.sendToMobile,
      web: sub.sendToWeb,
    };
  }, [template?.mySubscriptions.generation]);

  const templateUpdateSubscription:
    | { type: TemplateSubscriptionType; mobile: boolean; web: boolean }
    | undefined = useMemo(() => {
    const sub = template?.mySubscriptions.update;
    if (!sub) {
      return undefined;
    }

    return {
      type: "update",
      mobile: sub.sendToMobile,
      web: sub.sendToWeb,
    };
  }, [template?.mySubscriptions.update]);

  const mutateUpdateTemplateSubscription = useCallback(
    async (args: {
      subscriptionType: TemplateSubscriptionType;
      item: "mobile" | "web";
      value: boolean;
    }) => {
      if (!template) {
        return;
      }
      const { subscriptionType, item, value } = args;
      const foundSubscription = template.mySubscriptions[subscriptionType];
      if (foundSubscription) {
        await updateTemplateSubscription({
          updatedSubscriptionList: [
            {
              action: "subscribe",
              sendToMobile: item === "mobile" ? value : foundSubscription.sendToMobile,
              sendToWeb: item === "web" ? value : foundSubscription.sendToWeb,
              subscriptionType,
              templateId: template.templateId,
            },
          ],
        });
      } else {
        await updateTemplateSubscription({
          updatedSubscriptionList: [
            {
              action: "subscribe",
              sendToMobile: item === "mobile" && value,
              sendToWeb: item === "web" && value,
              subscriptionType,
              templateId: template.templateId,
            },
          ],
        });
      }
    },
    [template, updateTemplateSubscription]
  );

  const { mutate: mutateSubscribe, isLoading: isSubscriptionLoading } = useMutation(
    mutateUpdateTemplateSubscription,
    {
      onSuccess() {
        remove();
        refetch();
      },
    }
  );

  const handleButtonClickEdit = useCallback(() => {
    if (templateVersionId) {
      organizationNavigate(organizationLinkTemplates.templateVersionEditPage(templateVersionId));
    }
  }, [templateVersionId, organizationNavigate]);

  const handleButtonClickGenerate = useCallback(() => {
    if (templateVersionId) {
      organizationNavigate(
        organizationLinkTemplates.templateVersionGeneratePage(templateVersionId, undefined)
      );
    }
  }, [templateVersionId, organizationNavigate]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    const templateName = template?.informationDefinition?.name;
    const breadCrumbText = templateName
      ? `${templateName} - v${templateVersion}`
      : resources.loading;
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "text", name: breadCrumbText },
    ];
  }, [resources.home, resources.loading, template?.informationDefinition?.name, templateVersion]);

  const { token } = useServices();

  const [_, copyToClipboard] = useCopyToClipboard();

  const apiMarkdownText = useMemo(() => {
    if (!sample) {
      return "";
    }

    const documentGenerationEndpointRoute: HttpSyncDocumentGenerationRequest["route"] =
      "/documents/generate-sync";

    return `
\`\`\`
POST ${window.location.protocol}//${window.location.host}/api${documentGenerationEndpointRoute}

\`\`\`

\`\`\`JSON
${JSON.stringify(sample.requestSample, null, 2)}
\`\`\``;
  }, [sample]);

  return {
    breadCrumbEntries,
    handleButtonClickGenerate,
    handleButtonClickEdit,
    isSubscriptionLoading,
    mutateSubscribe,
    templateUpdateSubscription,
    templateGenerationSubscription,
    loading,
    verDate,
    publishedMessage,
    breakpoint,
    editable,
    templateVersionId,
    template,
    versions,
    templateVersion,
    token,
    copyToClipboard,
    apiMarkdownText,
    sampleLoading,
    resources,
    moment,
    versionList,
    versionListLoading,
    sample,
  };
}
