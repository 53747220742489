import { Box, Button, ChevronSvgIcon, TapArea, Text, useColors } from "@prodoctivity/design-system";

import { useCallback } from "react";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";

export type SmallAccordionProps = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  label?: string;
  isActive: boolean;
  currIndex?: number;
  setActive: (active: boolean) => void;
  remove: (currIndex: number | undefined) => void;
  children: React.ReactNode;
};

export const SmallAccordion = ({
  resources,
  label,
  isActive,
  currIndex,
  setActive,
  remove,
  children,
}: SmallAccordionProps) => {
  const { colors } = useColors();
  const onTap = useCallback(() => {
    if (currIndex !== undefined) {
      setActive(!isActive);
    }
  }, [currIndex, isActive, setActive]);
  const onRemoveClicked = useCallback(() => {
    remove(currIndex);
  }, [remove, currIndex]);

  return (
    <Box display="flex" direction="column" flex="shrink" width={"100%"}>
      <Box
        flex="grow"
        display="flex"
        alignItems="center"
        paddingY={0}
        paddingX={4}
        color={isActive ? colors.primary200 : colors.neutral300}
      >
        <Box display="flex" direction="row" flex="grow">
          <TapArea onTap={onTap}>
            <Box display="flex" gap={2} alignItems="center">
              <ChevronSvgIcon
                color={isActive ? colors.black900 : undefined}
                direction={isActive ? "up" : "down"}
              />
              <Text
                weight={isActive ? "bold" : undefined}
                color={isActive ? colors.black900 : colors.black600}
              >
                {label}
              </Text>
            </Box>
          </TapArea>
        </Box>
        <Box display="flex" direction="row" flex="shrink">
          <Button
            size="sm"
            color={"transparent"}
            onClick={onRemoveClicked}
            text={resources.remove}
            iconEnd="trash-can"
          />
        </Box>
      </Box>
      {isActive && children}
    </Box>
  );
};
