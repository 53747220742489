// @flow
import { Box, Text } from "@prodoctivity/design-system";
import { FunctionComponent, useMemo } from "react";
import { CustomAccordion } from "./CustomAccordion";
import { DependenciesRow } from "./RowComponent/DependenciesRow";
import { DependencyProps, useDependencies } from "./hooks";

const DependenciesComponent: FunctionComponent<DependencyProps> = (props) => {
  const {
    onSingleDependencyUpdate,
    state,
    i18n,
    colors,
    removeSelectedDependency,
    moment,
    resources,
    mixedList,
    recordList,
  } = useDependencies(props);

  const dependenciesRows = useMemo(() => {
    return props.formDependencies.map((dependency, index) => {
      let dependencyName = `${i18n("conditionBlock")} ${index + 1}`;
      if (dependency.name.trim() !== "") {
        dependencyName = dependency.name;
      }

      return (
        <Box flex="grow" borderColor={colors.subtle} borderRadius={6} key={index}>
          <CustomAccordion
            removeLabel={"remove"}
            label={dependencyName}
            currIndex={index}
            i18n={i18n}
            deleteAccordionHandler={removeSelectedDependency}
            hideLabelOnOpen={undefined}
          >
            <Box padding={4} color={colors.neutral300}>
              <DependenciesRow
                index={index}
                dependency={dependency}
                fields={state.fields}
                onDependencyUpdate={onSingleDependencyUpdate(index)}
                i18n={i18n}
                moment={moment}
                resources={resources}
                formFieldList={mixedList}
                contextDefinition={props.contextDefinition}
                recordList={recordList}
              />
            </Box>
          </CustomAccordion>
        </Box>
      );
    });
  }, [
    colors.neutral300,
    colors.subtle,
    i18n,
    mixedList,
    moment,
    onSingleDependencyUpdate,
    props.contextDefinition,
    props.formDependencies,
    recordList,
    removeSelectedDependency,
    resources,
    state.fields,
  ]);

  const empty = (
    <Box display="flex" alignItems="start" gap={4} direction="column">
      <Box>
        <Text weight="bold">{i18n("filterBuilder.dependencyNotCreated")}</Text>
      </Box>
      <Box>
        <Text>{`${i18n("filterBuilder.createDependenciesDescription")}  "${i18n("addNew")}"`}</Text>
      </Box>
    </Box>
  );

  const dependenciesCollapse = <Box>{dependenciesRows}</Box>;
  const dependenciesOrEmpty = props.formDependencies.length > 0 ? dependenciesCollapse : empty;

  return <div>{dependenciesOrEmpty}</div>;
};

export const Dependencies = DependenciesComponent;
