import { useCallback, useEffect, useState } from "react";
import { BrowseDeletedDocumentsFilter, organizationLinkTemplates } from "../../link-templates";
import { ComboBoxItemType } from "@prodoctivity/design-system/components/ComboBox";
import type { HttpGetDocumentsRequest, RestoreDocumentRequest } from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { useAllDocumentTypes } from "../../components/hooks";

const browseDocumentsPageLengthOptions: Array<
  HttpGetDocumentsRequest["queryParameters"]["rowsPerPage"]
> = ["15", "30", "100", "500"];

export function useBrowseDeletedDocumentsList(filter: BrowseDeletedDocumentsFilter) {
  const organizationNavigate = useOrganizationNavigate();
  const { getDeletedDocumentList, hardDeleteDocument, restoreDeletedDocument } = useServices();
  const { moment } = useAppTranslation();

  const [hardDeletedList, setHardDeletedList] = useState<
    RestoreDocumentRequest["deletedDocumentList"]
  >([]);

  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<ComboBoxItemType[]>([]);

  const [isToggleSelect, setIsToggleSelect] = useState<boolean>(false);

  const handleToggleSelect = () => setIsToggleSelect(() => !isToggleSelect);

  const { allDocumentTypes: documentTypeData, isLoading, isRefetching } = useAllDocumentTypes();

  useEffect(() => {
    if (documentTypeData) {
      const filteredDocumentTypes = documentTypeData.documentTypes.filter((dt) =>
        filter.documentTypeIdList?.includes(dt.documentTypeId)
      );
      setSelectedDocumentTypes(
        filteredDocumentTypes.map((dt) => ({
          label: dt.name,
          value: dt.documentTypeId,
        }))
      );
    }
  }, [documentTypeData, filter.documentTypeIdList]);

  const documentTypeIsLoading = isLoading || isRefetching;

  const [documentTypeComboBoxInputValue, setDocumentTypeComboBoxInputValue] = useState("");

  const getFilteredDocuments = useCallback(async () => {
    const dateEnd = filter.dateEnd ? moment(filter.dateEnd).endOf("day").valueOf() : undefined;

    try {
      return await getDeletedDocumentList(
        filter.pageNumber,
        filter.rowsPerPage,
        filter.sortField,
        filter.sortDirection,
        filter.documentTypeIdList && filter.documentTypeIdList.length
          ? filter.documentTypeIdList
          : undefined,
        filter.updatedBy,
        filter.dateStart,
        dateEnd
      );
    } catch (error) {
      console.log(error);
    }
  }, [
    filter.dateStart,
    filter.dateEnd,
    filter.documentTypeIdList,
    filter.pageNumber,
    filter.rowsPerPage,
    filter.sortDirection,
    filter.sortField,
    filter.updatedBy,
    getDeletedDocumentList,
    moment,
  ]);

  const handleFilterChange = useCallback(
    (filterType: keyof typeof filter, value: { value?: string | number }) => {
      organizationNavigate(
        organizationLinkTemplates.browseDeletedDocuments({ ...filter, [filterType]: value.value })
      );
    },
    [organizationNavigate, filter]
  );
  const onSelectDocumentType = useCallback(
    ({
      item,
    }: {
      event: React.SyntheticEvent<HTMLInputElement, Event>;
      item: ComboBoxItemType;
    }) => {
      const isAlreadySelected = selectedDocumentTypes.some(
        (selectedItem) => selectedItem.value === item.value
      );

      if (!isAlreadySelected) {
        const newSelectedDocumentTypes = [...selectedDocumentTypes, item];
        setSelectedDocumentTypes(newSelectedDocumentTypes);
        setDocumentTypeComboBoxInputValue("");
        const documentTypeIds = newSelectedDocumentTypes.map((selectedItem) => selectedItem.value);

        organizationNavigate(
          organizationLinkTemplates.browseDeletedDocuments({
            ...filter,
            documentTypeIdList: documentTypeIds,
          })
        );
      }
    },
    [organizationNavigate, selectedDocumentTypes, filter]
  );

  const documentTypeHandleClick = useCallback(
    (index: number) => {
      const updatedSelectedDocumentTypes = selectedDocumentTypes.filter((_, i) => i !== index);
      setSelectedDocumentTypes(updatedSelectedDocumentTypes);

      const documentTypeIds = updatedSelectedDocumentTypes.map(
        (selectedItem) => selectedItem.value
      );

      organizationNavigate(
        organizationLinkTemplates.browseDeletedDocuments({
          ...filter,
          documentTypeIdList: documentTypeIds,
        })
      );

      setDocumentTypeComboBoxInputValue("");
    },
    [organizationNavigate, selectedDocumentTypes, filter]
  );

  const setRowsPerPage = useCallback(
    (rpp: HttpGetDocumentsRequest["queryParameters"]["rowsPerPage"]) => {
      organizationNavigate(
        organizationLinkTemplates.browseDeletedDocuments({ ...filter, rowsPerPage: rpp })
      );
    },
    [organizationNavigate, filter]
  );

  const clearAll = useCallback(() => {
    setSelectedDocumentTypes([]);
    setDocumentTypeComboBoxInputValue("");
    organizationNavigate(
      organizationLinkTemplates.browseDeletedDocuments({
        pageNumber: 0,
        rowsPerPage: "15",
        dateStart: undefined,
        dateEnd: undefined,
        documentTypeIdList: undefined,
        sortField: undefined,
        sortDirection: undefined,
        updatedBy: undefined,
      })
    );
  }, [organizationNavigate]);

  const changeSort = useCallback(
    (
      field: "documentType" | "name" | "deletedBy" | "deletedAt" | undefined,
      direction: "ASC" | "DESC" | undefined
    ) => {
      organizationNavigate(
        organizationLinkTemplates.browseDeletedDocuments({
          ...filter,
          sortField: field,
          sortDirection: field === undefined ? undefined : direction,
        })
      );
    },
    [filter, organizationNavigate]
  );

  const stringifiedFilterValues = JSON.stringify(filter);

  const {
    data: documentsData,
    isLoading: documentsIsLoading,
    refetch: refetchDocuments,
    remove,
  } = useOrganizationQuery(`document-recovery/${stringifiedFilterValues}`, getFilteredDocuments, {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const refetch = useCallback(() => {
    remove();
    refetchDocuments();
  }, [refetchDocuments, remove]);

  const handleRestoreDocument = useCallback(
    async (documentId: string) => {
      const documentInfo: RestoreDocumentRequest["deletedDocumentList"] = [];

      const filteredDoc = documentsData?.deletedDocumentList.filter(
        (doc) => doc.documentId === documentId
      );

      if (filteredDoc) {
        documentInfo.push({
          documentId: filteredDoc[0].documentId,
          documentVersionList: [
            filteredDoc.find((doc) => doc.documentId === documentId)?.documentVersions[0]
              ?.documentVersionId || "",
          ],
        });
      }

      if (documentInfo.length === 0) return;
      console.log("anda");

      await restoreDeletedDocument(documentInfo);
      refetch();
    },
    [documentsData?.deletedDocumentList, refetch, restoreDeletedDocument]
  );

  const handleDeleteDocument = useCallback(
    async (documentId: string) => {
      const documentInfo: RestoreDocumentRequest["deletedDocumentList"] = [];

      const filteredDoc = documentsData?.deletedDocumentList.filter(
        (doc) => doc.documentId === documentId
      );

      if (filteredDoc) {
        documentInfo.push({
          documentId: filteredDoc[0].documentId,
          documentVersionList: [
            filteredDoc.find((doc) => doc.documentId === documentId)?.documentVersions[0]
              ?.documentVersionId || "",
          ],
        });
      }

      if (documentInfo.length === 0) return;

      await hardDeleteDocument(documentInfo);

      refetch();
    },
    [documentsData?.deletedDocumentList, hardDeleteDocument, refetch]
  );

  const handleChecboxChange = useCallback(
    (docId: string, isChecked: boolean) => {
      const updatedList = [...hardDeletedList];

      const existingIndex = updatedList.findIndex((doc) => doc.documentId === docId);

      if (isChecked) {
        if (existingIndex === -1) {
          updatedList.push({
            documentId: docId,
            documentVersionList: [
              documentsData?.deletedDocumentList.find((doc) => doc.documentId === docId)
                ?.documentVersions[0]?.documentVersionId || "",
            ],
          });
        } else {
          const documentVersionId =
            documentsData?.deletedDocumentList.find((doc) => doc.documentId === docId)
              ?.documentVersions[0]?.documentVersionId || "";
          updatedList[existingIndex].documentVersionList.push(documentVersionId);
        }
      } else {
        if (existingIndex !== -1) {
          const document = updatedList[existingIndex];
          if (document.documentVersionList.length === 1) {
            updatedList.splice(existingIndex, 1);
          } else {
            const versionIndex = document.documentVersionList.indexOf(docId);
            if (versionIndex !== -1) {
              updatedList[existingIndex].documentVersionList.splice(versionIndex, 1);
            }
          }
        }
      }

      setHardDeletedList(updatedList);
    },
    [documentsData?.deletedDocumentList, hardDeletedList]
  );

  const isNextButtonDisabled =
    !documentsData || documentsData.requestedPageLength !== documentsData.pageLength;
  const isPreviousButtonDisabled = !documentsData || documentsData.pageNumber < 1;
  const totalRowCount = documentsData ? documentsData.totalRowCount : undefined;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    organizationNavigate(
      organizationLinkTemplates.browseDeletedDocuments({
        ...filter,
        pageNumber: filter.pageNumber - 1,
      })
    );
  }, [organizationNavigate, filter, isPreviousButtonDisabled]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    organizationNavigate(
      organizationLinkTemplates.browseDeletedDocuments({
        ...filter,
        pageNumber: filter.pageNumber + 1,
      })
    );
  }, [organizationNavigate, filter, isNextButtonDisabled]);

  //end of pagination

  return {
    documentTypes: documentTypeData?.documentTypes || [],
    documentTypeIsLoading,
    documentsData: documentsData?.deletedDocumentList || [],
    documentsIsLoading,
    onSelectDocumentType,
    setSelectedDocumentTypes,
    selectedDocumentTypes,
    currentPage: filter.pageNumber,
    previousPage,
    nextPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    rowsPerPage: filter.rowsPerPage,
    pageLengthOptions: browseDocumentsPageLengthOptions,
    totalRowCount,
    documentTypeHandleClick,
    documentTypeComboBoxInputValue,
    setDocumentTypeComboBoxInputValue,
    clearAll,
    handleFilterChange,
    setRowsPerPage,
    changeSort,
    refetch,
    hardDeleteDocument,
    restoreDeletedDocument,
    handleToggleSelect,
    isToggleSelect,
    handleChecboxChange,
    hardDeletedList,
    setHardDeletedList,
    handleDeleteDocument,
    handleRestoreDocument,
  };
}
