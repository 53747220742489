import {
  Box,
  Button,
  Divider,
  IconButton,
  Table,
  Text,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import type { DictionaryList, DictionaryListItem } from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useContext, useEffect, useRef } from "react";

import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { FocusFieldContext } from "./FocusFieldContext";
import { findList } from "./utils";

type DataListItemsProps = {
  root: DictionaryList<string>;
  onChange: (path: string, value: DictionaryListItem) => void;
  onAdd: (path: string) => void;
  onEnterKey: (path: string, currentIndex: number) => void;
  onRemove: (path: string) => void;
  currentPath: string;
};
type DataListItemRowProps = {
  root: DictionaryList<string>;
  currentPath: string;
  index: number;
  item: DictionaryListItem;
  onChange: (path: string, value: DictionaryListItem) => void;
  onAdd: (path: string) => void;
  onEnterKey: (path: string, currentIndex: number) => void;
  onRemove: (path: string) => void;
};

const DataListItemRow: FunctionComponent<DataListItemRowProps> = ({
  root,
  currentPath,
  index,
  item,
  onChange,
  onAdd,
  onEnterKey,
  onRemove,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { focusFieldPath, setFocusFieldPath } = useContext(FocusFieldContext);
  const path = `${currentPath}::${index}`;
  const onKeyDown = useCallback(
    (args: { event: React.KeyboardEvent<HTMLInputElement> }) => {
      if (args.event.key === "Enter") {
        onEnterKey(currentPath, index);
      }
    },
    [currentPath, index, onEnterKey]
  );

  useEffect(() => {
    if (focusFieldPath === path) {
      if (inputRef.current) {
        inputRef.current.focus();
        setFocusFieldPath("");
      }
    }
  }, [focusFieldPath, path, setFocusFieldPath]);

  return (
    <Table.RowExpandable
      key={`${currentPath}-${index}`}
      id={`${currentPath}-${index}`}
      expandedContents={Object.keys(item.children ?? {}).map((listName) => {
        const subList = findList(root.listSchema, listName);
        const subListItems = item && item.children ? item.children[listName] : [];
        const newPath = `${path}->${listName}`;
        return subList ? (
          <DataListItems
            key={`${currentPath}-${index}-${listName}`}
            root={{ _id: undefined, listSchema: subList, items: subListItems }}
            onChange={onChange}
            onAdd={onAdd}
            onEnterKey={onEnterKey}
            onRemove={onRemove}
            currentPath={newPath}
          />
        ) : null;
      })}
      accessibilityCollapseLabel={resources.collapse}
      accessibilityExpandLabel={resources.expand}
    >
      <Table.Cell>
        <TextField
          id={`${root.listSchema.name}-item-value-${index}`}
          ref={inputRef}
          onKeyDown={onKeyDown}
          onChange={({ value }) => {
            if (item.label === item.key) {
              onChange(path, { ...item, key: value, label: value });
            } else {
              onChange(path, { ...item, key: value });
            }
          }}
          value={item.key}
          maxLength={{
            characterCount: 100,
            errorAccessibilityLabel: "100 max",
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <TextField
          id={`${root.listSchema.name}-item-label-${index}`}
          onChange={({ value }) => {
            onChange(path, { ...item, label: value });
          }}
          value={item.label}
          maxLength={{
            characterCount: 300,
            errorAccessibilityLabel: "300 max",
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" direction="column" justifyContent="start">
          <IconButton
            key={path}
            color={colors.neutral900}
            hoverColor={colors.primary}
            icon="trash"
            accessibilityLabel={resources.delete_}
            onClick={() => {
              onRemove(path);
            }}
          />
        </Box>
      </Table.Cell>
    </Table.RowExpandable>
  );
};

export const DataListItems: FunctionComponent<DataListItemsProps> = ({
  root,
  onChange,
  onAdd,
  onEnterKey,
  onRemove,
  currentPath,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { setFocusFieldPath } = useContext(FocusFieldContext);
  const handleEnterKey = useCallback(
    (currentPath: string, currentIndex: number) => {
      onEnterKey(currentPath, currentIndex);
      setFocusFieldPath(`${currentPath}::${currentIndex + 1}`);
    },
    [onEnterKey, setFocusFieldPath]
  );
  const handleAddButton = useCallback(
    (currentPath: string) => {
      onAdd(currentPath);
      setFocusFieldPath(`${currentPath}::${root.items.length - 1}`);
    },
    [onAdd, root.items.length, setFocusFieldPath]
  );
  return (
    <>
      <Box marginStart={4} marginEnd={4} display="flex" direction="column">
        <>
          <Box
            borderRadius={4}
            borderStyle="sm"
            height={48}
            display="flex"
            alignItems="center"
            paddingX={6}
            color={colors.neutral200}
          >
            <Text weight="bold">{`${resources.itemsFor} ${root.listSchema.name}`}</Text>
          </Box>
          {root.items && root.items.length > 0 ? (
            <Box>
              <Table accessibilityLabel={resources.dataDictionary.dataLists}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell> </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box paddingX={4}>
                        <Text size="200">{resources.value.toUpperCase()}</Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Box paddingX={4}>
                        <Text size="200">{resources.label.toUpperCase()}</Text>
                      </Box>
                    </Table.HeaderCell>
                    <Table.HeaderCell> </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {root.items.map((item, index) => {
                    return (
                      <DataListItemRow
                        root={root}
                        currentPath={currentPath}
                        index={index}
                        item={item}
                        key={`${currentPath}-${index}`}
                        onChange={onChange}
                        onAdd={onAdd}
                        onEnterKey={handleEnterKey}
                        onRemove={onRemove}
                      />
                    );
                  })}
                </Table.Body>
              </Table>
              <Divider direction="horizontal" />
            </Box>
          ) : (
            <Box
              marginTop={3}
              borderRadius={4}
              borderStyle="sm"
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={colors.neutral200}
            >
              <Text size="300">{`${resources.dataDictionary.noItems} ${root.listSchema.name}`}</Text>
            </Box>
          )}
        </>
      </Box>
      <Box margin={4} width={150} height={48}>
        <Button
          key={currentPath}
          onClick={() => {
            handleAddButton(currentPath);
          }}
          text={resources.add}
          color="gray"
          iconEnd="add-circle"
        />
      </Box>
    </>
  );
};
