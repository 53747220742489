import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DocumentCheckSvgIcon: FunctionComponent<Props> = ({
  width = 34,
  height = 32,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="7.71387" y="1.69727" width="20.5714" height="25.4545" rx="2" fill="#CCE7FF" />
      <g clipPath="url(#clip0_5790_96525)">
        <path
          d="M25.6999 10.6519C25.6999 15.8838 25.6999 21.1012 25.6999 26.3331C25.6999 27.594 25.292 27.9998 24.0391 27.9998C18.2552 27.9998 12.4567 27.9998 6.67282 27.9998C5.4636 27.9998 5.05566 27.594 5.05566 26.3766C5.05566 18.9418 5.05566 11.507 5.05566 4.05774C5.05566 3.69543 5.05566 3.33311 5.24506 2.99977C5.72584 3.18818 6.10463 2.79688 6.57084 2.79688C10.1257 2.84035 13.6951 2.82586 17.2645 2.79688C17.8035 2.79688 17.9784 2.9563 17.9638 3.49253C17.9347 5.15919 17.9492 6.84035 17.9638 8.52151C17.9638 9.5505 18.4009 9.97079 19.4498 9.97079C21.5041 9.97079 23.5437 9.97079 25.598 9.97079C25.8311 10.1592 25.7145 10.4201 25.7145 10.6519H25.6999Z"
          fill="url(#paint0_linear_5790_96525)"
        />
        <path
          d="M25.5688 9.97085C23.5146 9.97085 21.4749 9.97085 19.4207 9.97085C18.3863 9.97085 17.9492 9.55056 17.9492 8.52157C17.9492 6.84041 17.9201 5.17374 17.9492 3.49258C17.9492 2.94186 17.7889 2.78244 17.2499 2.79693C13.6951 2.82592 10.1257 2.82592 6.55625 2.79693C6.07547 2.79693 5.71125 3.18824 5.23047 2.99983C5.43443 2.55055 5.81323 2.34766 6.29401 2.34766C10.1257 2.34766 13.9573 2.36215 17.7889 2.37664C17.8181 2.47809 17.8618 2.52157 17.9346 2.40563C19.1584 3.40563 20.2074 4.59403 21.3146 5.69548C22.7424 7.11577 24.1556 8.55055 25.5688 9.98534V9.97085Z"
          fill="#2650A3"
        />
        <g filter="url(#filter0_b_5790_96525)">
          <path
            d="M12.3837 6.15942C12.3837 2.75767 9.61148 0 6.19183 0C2.77217 0 0 2.75767 0 6.15942C0 9.56117 2.77217 12.3188 6.19183 12.3188C9.61148 12.3188 12.3837 9.56117 12.3837 6.15942Z"
            fill="url(#paint1_linear_5790_96525)"
          />
        </g>
        <path d="M21.1979 13.7969H9.67383V15.565H21.1979V13.7969Z" fill="#FEFEFE" />
        <path d="M21.1979 17.8691H9.67383V19.6373H21.1979V17.8691Z" fill="#FEFEFE" />
        <path d="M21.0963 21.9424H9.57227V23.7105H21.0963V21.9424Z" fill="#FEFEFE" />
        <path
          d="M3.94777 5.49316L5.2007 6.63809L8.39131 3.46418C8.75554 3.14534 9.28002 3.13084 9.61511 3.37722C10.023 3.68157 10.125 4.34824 9.74623 4.812L6.83243 7.69606L5.55036 8.98592C5.34639 9.15983 5.06958 9.15983 4.86561 8.98592L2.59285 6.8265C2.27233 6.40621 2.34518 5.812 2.72397 5.50766C3.05906 5.23229 3.56897 5.2178 3.94777 5.50766V5.49316Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_b_5790_96525)">
        <rect
          x="16.2861"
          y="16.1211"
          width="13.7143"
          height="7.63636"
          rx="1"
          fill="url(#paint2_linear_5790_96525)"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_5790_96525"
          x="-5"
          y="-5"
          width="22.3838"
          height="22.3184"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5790_96525" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5790_96525"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_5790_96525"
          x="15.2861"
          y="15.1211"
          width="15.7139"
          height="9.63672"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5790_96525" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5790_96525"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5790_96525"
          x1="13.7141"
          y1="5.09044"
          x2="9.93436"
          y2="84.4275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0074E8" />
          <stop offset="1" stopColor="#2650A3" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5790_96525"
          x1="6"
          y1="2.12121"
          x2="9.3632"
          y2="16.5607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2650A3" />
          <stop offset="1" stopColor="#2650A3" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5790_96525"
          x1="17.048"
          y1="13.0665"
          x2="65.1161"
          y2="45.4199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2650A3" />
          <stop offset="1" stopColor="#2650A3" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_5790_96525">
          <rect width="25.7143" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
