import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CircleXIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 34,
  height = 35,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13393_134989)">
        <path
          d="M16.6668 5.5C14.2934 5.5 11.9733 6.20379 9.99991 7.52236C8.02652 8.84094 6.48845 10.7151 5.5802 12.9078C4.67195 15.1005 4.43431 17.5133 4.89733 19.8411C5.36035 22.1689 6.50324 24.307 8.18147 25.9853C9.8597 27.6635 11.9979 28.8064 14.3257 29.2694C16.6534 29.7324 19.0662 29.4948 21.259 28.5865C23.4517 27.6783 25.3258 26.1402 26.6444 24.1668C27.963 22.1934 28.6667 19.8734 28.6667 17.5C28.6634 14.3184 27.398 11.2681 25.1483 9.01843C22.8986 6.76872 19.8483 5.50336 16.6668 5.5ZM21.0121 20.5392C21.0979 20.625 21.1659 20.7268 21.2123 20.8389C21.2588 20.9509 21.2826 21.071 21.2826 21.1923C21.2826 21.3136 21.2588 21.4337 21.2123 21.5457C21.1659 21.6578 21.0979 21.7596 21.0121 21.8454C20.9264 21.9311 20.8246 21.9992 20.7125 22.0456C20.6004 22.092 20.4803 22.1159 20.3591 22.1159C20.2378 22.1159 20.1177 22.092 20.0056 22.0456C19.8936 21.9992 19.7917 21.9311 19.706 21.8454L16.6668 18.805L13.6275 21.8454C13.5418 21.9311 13.4399 21.9992 13.3279 22.0456C13.2158 22.092 13.0957 22.1159 12.9744 22.1159C12.8532 22.1159 12.7331 22.092 12.621 22.0456C12.5089 21.9992 12.4071 21.9311 12.3214 21.8454C12.2356 21.7596 12.1676 21.6578 12.1212 21.5457C12.0747 21.4337 12.0509 21.3136 12.0509 21.1923C12.0509 21.071 12.0747 20.9509 12.1212 20.8389C12.1676 20.7268 12.2356 20.625 12.3214 20.5392L15.3618 17.5L12.3214 14.4608C12.1482 14.2876 12.0509 14.0526 12.0509 13.8077C12.0509 13.5627 12.1482 13.3278 12.3214 13.1546C12.4946 12.9814 12.7295 12.8841 12.9744 12.8841C13.2194 12.8841 13.4543 12.9814 13.6275 13.1546L16.6668 16.195L19.706 13.1546C19.7917 13.0689 19.8936 13.0008 20.0056 12.9544C20.1177 12.908 20.2378 12.8841 20.3591 12.8841C20.4803 12.8841 20.6004 12.908 20.7125 12.9544C20.8246 13.0008 20.9264 13.0689 21.0121 13.1546C21.0979 13.2404 21.1659 13.3422 21.2123 13.4542C21.2588 13.5663 21.2826 13.6864 21.2826 13.8077C21.2826 13.929 21.2588 14.0491 21.2123 14.1611C21.1659 14.2732 21.0979 14.375 21.0121 14.4608L17.9718 17.5L21.0121 20.5392Z"
          fill={color ? calculateColor(color) : "#CC0000"}
        />
      </g>
      <defs>
        <clipPath id="clip0_13393_134989">
          <rect width="34" height="34" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
