import { Dispatch, FunctionComponent, useCallback, useRef, useState } from "react";
import { Box, BoxWithRef } from "../Box";
import { Layer, Popover } from "../layout";

import type { DataType } from "@prodoctivity/shared/src/index-types";
import { useTranslation } from "react-i18next";
import { popupZIndex } from "../../utils";
import { useColors } from "../ColorSchemeProvider";
import { Icon } from "../Icon";
import { TapArea } from "../TapArea";
import { Text } from "../Text";
import { Tooltip } from "../Tooltip";
import { Msg } from "./StringTemplateBuilder";

type AddNewPartComponentProps = {
  lineIndex: number;
  partIndex: number;
  inline?: boolean;
  show: boolean;
  dispatch: Dispatch<Msg>;
  onVariableAdded(lineIndex: number, partIndex: number, name: string, dataType: DataType): void;
  getNewVariableData(): { name: string; dataType: DataType };
};

export const AddNewPartComponent: FunctionComponent<AddNewPartComponentProps> = ({
  lineIndex,
  partIndex,
  inline,
  show,
  dispatch,
  onVariableAdded,
  getNewVariableData,
}) => {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const addPart = useCallback(() => {
    setOpen(true);
    dispatch({
      type: "prevent-close",
      value: true,
    });
  }, [dispatch]);
  const close = useCallback(() => {
    setOpen(false);
    dispatch({
      type: "prevent-close",
      value: false,
    });
  }, [dispatch]);
  const addTextPart = useCallback(() => {
    dispatch({
      type: "add-text-part",
      lineIndex: lineIndex,
      partIndex: partIndex,
    });
    close();
  }, [close, dispatch, lineIndex, partIndex]);
  const addVariablePart = useCallback(() => {
    const { name, dataType } = getNewVariableData();
    dispatch({
      type: "add-variable-part",
      lineIndex,
      partIndex,
      name,
      dataType,
    });
    close();

    onVariableAdded(lineIndex, partIndex, name, dataType);
  }, [close, dispatch, getNewVariableData, lineIndex, onVariableAdded, partIndex]);
  const addNewLine = useCallback(() => {
    dispatch({
      type: "add-new-line-part",
      lineIndex,
      partIndex,
    });
    close();
  }, [close, dispatch, lineIndex, partIndex]);
  if (!show) {
    return null;
  }
  const resources = {
    addTextPart: t("addTextPart"),
    addVariablePart: t("addVariablePart"),
    addNewLine: t("addNewLine"),
  };
  return (
    <BoxWithRef display="inlineBlock" onClickCapture={addPart} padding={1} ref={anchorRef}>
      <Tooltip text={t("clickToAddNewPart")}>
        <Icon
          accessibilityLabel={t("clickToAddNewPart")}
          icon="plus"
          size={"xs"}
          color={colors.primary}
          onClick={addPart}
        ></Icon>
      </Tooltip>
      {open && (
        <Layer zIndex={popupZIndex}>
          <Popover
            anchor={anchorRef.current}
            onDismiss={close}
            idealDirection="down"
            positionRelativeToAnchor={true}
            size="xl"
            shouldFocus={true}
            color="white"
            role="menu"
          >
            <Box color={colors.white}>
              <Box paddingX={6} paddingY={4} width={346}>
                <Box display="flex" direction="column" gap={4}>
                  <Box display="flex" direction="row">
                    <TapArea onTap={addTextPart}>
                      <Text weight="bold">{resources.addTextPart}</Text>
                    </TapArea>
                  </Box>
                  <Box display="flex" direction="row">
                    <TapArea onTap={addVariablePart}>
                      <Text weight="bold">{resources.addVariablePart}</Text>
                    </TapArea>
                  </Box>
                  {inline !== true && (
                    <Box display="flex" direction="row">
                      <TapArea onTap={addNewLine}>
                        <Text weight="bold">{resources.addNewLine}</Text>
                      </TapArea>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Popover>
        </Layer>
      )}
    </BoxWithRef>
  );
};
