import { Box, ComboBox, Text, TextField, useColors } from "@prodoctivity/design-system";

import { ALLOWED_CHARS_REGEXP } from "@prodoctivity/shared";
import { CombinedTemplateState } from "../hooks";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";

type Props = {
  isLoading: boolean;
  resources: ReturnType<typeof useAppTranslation>["resources"];
  documentGroups: Array<{ value: string; label: string }>;
  currentDocumentGroup?: {
    label: string;
    value: string;
  };
  onChangeDocumentGroup: (isValue: boolean, value?: string) => void;
  formState: CombinedTemplateState;
  setFormState: (value: React.SetStateAction<CombinedTemplateState>) => void;
};

export const GeneralProperties: FunctionComponent<Props> = ({
  isLoading,
  resources,
  documentGroups,
  currentDocumentGroup,
  onChangeDocumentGroup,
  formState,
  setFormState,
}) => {
  const { colors } = useColors();
  return (
    <Box>
      <Box marginEnd={12} marginStart={8} paddingY={8} color={colors.white}>
        <Box minHeight={65}>
          <Box marginBottom={2} width="100%">
            <Text color={colors.subtle}>{resources.name}</Text>
          </Box>
          <Box width="100%">
            <TextField
              placeholder={resources.name}
              id="name"
              value={formState.name}
              disabled={isLoading}
              onChange={(e) =>
                setFormState((prev) => {
                  const newState = { ...prev };
                  if (e.value === "" || ALLOWED_CHARS_REGEXP.test(e.value)) {
                    newState.name = e.value;
                  } else {
                    newState.toastMessage = {
                      type: "error",
                      message: resources.specialCharsNotAllowed,
                    };
                  }
                  return newState;
                })
              }
              errorMessage={formState.errors?.["name"]}
            />
          </Box>
        </Box>
        <Box marginTop={4}>
          <Box marginBottom={2} width="100%">
            <Text color={colors.subtle}>{resources.description}</Text>
          </Box>
          <Box width="100%">
            <TextField
              placeholder={resources.description}
              id="description"
              value={formState.description}
              disabled={isLoading}
              onChange={(e) =>
                setFormState((prev) => {
                  const newState = { ...prev };
                  if (e.value === "" || ALLOWED_CHARS_REGEXP.test(e.value)) {
                    newState.description = e.value;
                  } else {
                    newState.toastMessage = {
                      type: "error",
                      message: resources.specialCharsNotAllowed,
                    };
                  }
                  return newState;
                })
              }
              errorMessage={formState.errors?.["description"]}
            />
          </Box>
        </Box>
        <Box marginTop={4} minHeight={65}>
          <Box marginBottom={2} width="100%">
            <Text color={colors.subtle}>{resources.documentGroup}</Text>
          </Box>
          <Box width="100%">
            <ComboBox
              id="documentGroupId1"
              label=""
              disabled={isLoading}
              size="lg"
              options={documentGroups}
              onSelect={(e) => onChangeDocumentGroup(true, e.item.value)}
              onChange={(e) => onChangeDocumentGroup(false, e.value)}
              onClear={() => onChangeDocumentGroup(true, undefined)}
              placeholder={currentDocumentGroup?.label}
              inputValue={currentDocumentGroup?.label}
              selectedOption={currentDocumentGroup}
              errorMessage={formState.errors?.["documentGroupId"]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
