import type {
  CollectionFilter,
  CollectionResume,
  ForPieData,
  ReportHeaderData,
} from "@prodoctivity/types";
import { DocumentCollectionReportParameters } from "./documentCollectionReportParameters";
import { useCallback, useMemo, useState } from "react";
import { useServices } from "../../../hooks/useServices";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";

const defaultData: ReportHeaderData = {
  filters: [],
  initialData: {
    expired: 0,
    expiring: 0,
    issue: 0,
    total: 0,
    valid: 0,
  },
  assignments: [],
};

const initialDataChart = {
  pieDataSet: {
    total: 0,
    distribution: {
      valid: 0,
      missing: 0,
      expiring: 0,
      expired: 0,
      issueOrInvalid: 0,
    },
  },
  groupedDataSet: [],
};

export type Filter = { category: string; level: number; values: Array<string> };

export type Report = {
  categoryFilters: Array<Filter>;
  initialData: CollectionResume | undefined;
  forPieData:
    | {
        pieDataSet: ForPieData;
        groupedDataSet: Array<{
          level: number;
          identity: string;
          data: {
            issues: number;
            expired: number;
            expiring: number;
            valid: number;
          };
        }>;
      }
    | undefined;
  sortBy: "default" | "asc" | "desc";
  sortedCol: string;
  defaults: string[];
};

export type Props = {
  widgetParameters: DocumentCollectionReportParameters;
  widgetData: Report;
  assignedFilters: string;
};

export const useCollectionReportWidget = (parameters: any) => {
  const [state, setState] = useState<Props>({
    widgetParameters: {
      collectionId: parameters.params["collectionId"],
      editionMode: false,
      collectionName: parameters.params["additionalWidgetTitle"],
      elementParametersOnChange: parameters["setNewParams"],
      availableCollections: [],
    },
    widgetData: {
      categoryFilters: [],
      forPieData: undefined,
      initialData: undefined,
      sortBy: "default",
      sortedCol: "",
      defaults: [],
    },
    assignedFilters: "",
  });

  const changeSort = useCallback((sortBy: "default" | "asc" | "desc", sortedCol: string) => {
    setState((prev) => {
      return {
        ...prev,
        widgetData: {
          ...prev.widgetData,
          sortBy: sortBy,
          sortedCol: sortedCol,
        },
      };
    });
  }, []);

  useMemo(() => {
    setState((prev) => {
      return {
        ...prev,
        widgetParameters: {
          ...prev.widgetParameters,
          collectionId: parameters.params.collectionId,
        },
      };
    });
  }, [parameters.params.collectionId]);

  const {
    getDocumentCollectionFilters,
    getDocumentCollectionStatsByFilters,
    getDocumentCollectionKeyNameList: getDocumentCollectionConfigForReport,
  } = useServices();

  const { resources } = useAppTranslation();

  const getCollectionConfig = useCallback(async () => {
    return await getDocumentCollectionConfigForReport();
  }, [getDocumentCollectionConfigForReport]);

  const getConfiguration = useCallback(async () => {
    if (state.widgetParameters.editionMode) return defaultData;
    if (!state.widgetParameters.collectionId) return defaultData;
    if (!parameters) return defaultData;
    if (state.widgetParameters.collectionId === "") return defaultData;
    return await getDocumentCollectionFilters(state.widgetParameters.collectionId);
  }, [
    state.widgetParameters.collectionId,
    getDocumentCollectionFilters,
    state.widgetParameters.editionMode,
    ,
    parameters,
  ]);

  useOrganizationQuery(
    `/collection-list/${state.widgetParameters.editionMode}`,
    getCollectionConfig,
    {
      onSuccess(data) {
        setState((prev) => {
          const optionsList: Array<{ id: string; name: string }> = [];
          optionsList.push(
            ...data.list.map((x) => ({ id: x.collectionId, name: x.collectionName }))
          );
          return {
            ...prev,
            widgetParameters: {
              ...prev.widgetParameters,
              availableCollections: optionsList,
            },
          };
        });
      },
    }
  );

  useOrganizationQuery(
    `/collection-list-valid-for-widget/${state.widgetParameters.editionMode}`,
    getCollectionConfig
  );

  const setEditMode = useCallback((state: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        widgetParameters: {
          ...prev.widgetParameters,
          editionMode: state,
        },
      };
    });
  }, []);

  const resetConfiguration = useCallback(() => {
    setState((prev) => {
      return {
        ...prev,
        widgetParameters: {
          ...prev.widgetParameters,
          collectionId: "-1",
          collectionName: resources.chooseOne,
        },
      };
    });
  }, [resources.chooseOne]);

  const formConfig = useMemo(() => {
    return {
      inputContextDefinition: {
        fields: [
          {
            name: "CollectionName",
            fullPath: "CollectionName",
            humanName: "Collection Name",
            properties: {
              dataType: "Alphanumeric",
              defaultValue: [state.widgetParameters.collectionId],
              description: resources.selectACollection, //"Select the name of the document collection",
              humanName: "Collection Name",
              inputType: "Dropdown",
              instructions: resources.selectACollection, //"Select the name of the document collection",
              label: resources.collectionName, //"Document Collection Name",
              maxLength: 100,
              sampleValue: ["Customers"],
              minOccurs: 1,
              maxOccurs: 1,
              saveToECM: true,
              valueList: state.widgetParameters.availableCollections.map((x) => ({
                value: x.id,
                label: x.name,
              })),
            },
          },
        ],
        records: [],
      },
      inputWizardDefinition: {
        defaultPageName: "",
        defaultSectionName: "",
        dependencies: [],
        inferredDependencies: [],
        pages: [
          {
            key: "bundleInformation",
            label: "Bundle information",
            description: "Bundle information",
            properties: {},
            sections: [
              {
                key: "bundleSection",
                label: resources.formRequirementInfo, //"Required information",
                description: resources.formRequirement, //"This widget needs the following required information",
                properties: {},
                fields: [
                  {
                    key: "CollectionName",
                    isRecord: false,
                    label: "Collection Name",
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  }, [
    resources.collectionName,
    resources.formRequirementInfo,
    resources.formRequirement,
    state.widgetParameters.collectionId,
    state.widgetParameters.availableCollections,
    resources.selectACollection,
  ]);

  const saveConfiguration = useCallback(
    (contextValue?: any) => {
      const newObjectWithProps = { ...parameters.params };
      newObjectWithProps.additionalWidgetTitle = state.widgetParameters.availableCollections.find(
        (x) => x.id === contextValue["CollectionName"]
      )?.name;
      newObjectWithProps.collectionId = contextValue["CollectionName"];
      state.widgetParameters.elementParametersOnChange(
        newObjectWithProps,
        parameters["setOfIndexes"],
        undefined
      );
      if (state.widgetData.categoryFilters.length > 0) {
        setState((prev) => {
          return {
            ...prev,
            widgetData: {
              ...prev.widgetData,
              categoryFilters: [],
            },
          };
        });
      }
    },
    [parameters, state]
  );

  const updateFiltersByChanges = useCallback(async () => {
    if (!state.widgetParameters.collectionId) return [];
    if (state.widgetData.categoryFilters.flatMap((n) => n.values.flatMap((x) => x)).length === 0)
      return [];
  }, [state.widgetParameters.collectionId, state.widgetData.categoryFilters]);

  const {
    data: filtersConfig,
    isError,
    isLoading,
  } = useOrganizationQuery(
    `/collection-config/filters/${JSON.stringify(state.widgetParameters)}`,
    getConfiguration,
    {
      onSuccess(data) {
        if (state.widgetParameters.editionMode) {
          return;
        }

        const updateParams = parameters.params;
        if (data.initialData) {
          const regex = /\(\d+\)/;
          const appendContent = ` (${data.initialData.total})`;
          if (updateParams.additionalWidgetTitle) {
            updateParams.additionalWidgetTitle += regex.test(updateParams.additionalWidgetTitle)
              ? ""
              : appendContent;
            state.widgetParameters.elementParametersOnChange(
              updateParams,
              parameters["setOfIndexes"],
              undefined
            );
          }
        }

        const defaultSelections = (filters: CollectionFilter[]) => {
          if (filters.length === 0) {
            return [];
          }
          const firstLevel = filters.filter((x) => x.level === 1)[0];

          if (!firstLevel) {
            return [];
          }

          const options = firstLevel.filters.map((x) => x.label);
          // console.log('Default selection items :',JSON.stringify(options))
          return options;
        };
        console.log("other defaults :", defaultSelections(data.filters));

        setState((prev) => {
          console.log("setter");
          return {
            ...prev,
            widgetData: {
              ...prev.widgetData,
              categoryFilters: data.filters.map((x) => ({
                category: x.title,
                level: x.level,
                values: [],
              })),
              initialData: data.initialData,
              defaults: defaultSelections(data.filters),
            },
          };
        });
      },
    }
  );

  const handlerBoxFilterValues = useCallback(
    (category: string, values: { value: string; checkState: boolean }) => {
      if (!filtersConfig) {
        return;
      }
      const updateProvideCategoryList = (category: string) => {
        const categoryList = state.widgetData.categoryFilters.filter(
          (x) => x.category === category
        );
        if (categoryList.length > 0) {
          const currentCategory = categoryList[0];
          if (currentCategory.values.includes(values.value)) {
            currentCategory.values = currentCategory.values.filter(
              (x) => x !== values.value && x !== "Seleccionar Todo"
            );
            if (state.widgetData.defaults.length > 0 && currentCategory.level === 1) {
              currentCategory.values = [...state.widgetData.defaults];
            }
            return currentCategory;
          }

          if (
            currentCategory.values.length + 1 ===
            filtersConfig.filters?.filter((x) => x.title)[0].filters.length - 1
          ) {
            currentCategory.values = [...currentCategory.values, "Seleccionar Todo", values.value];
            return currentCategory;
          }

          currentCategory.values = [...currentCategory.values, values.value];

          return currentCategory;
        }
      };

      if (state.widgetData.categoryFilters === undefined) {
        console.log("Undefined");
      }

      const indexToUpdate = state.widgetData.categoryFilters.findIndex(
        (x) => x.category === category
      );
      const updateCategory = updateProvideCategoryList(category);
      if (!updateCategory) {
        return;
      }

      setState((prev) => {
        const categoryUpdated = [
          ...prev.widgetData.categoryFilters.toSpliced(indexToUpdate, 1, updateCategory),
        ];
        return {
          ...prev,
          widgetData: {
            ...prev.widgetData,
            categoryFilters: categoryUpdated,
            defaults: prev.widgetData.defaults.includes(values.value)
              ? prev.widgetData.defaults.filter((x) => x !== values.value)
              : prev.widgetData.defaults,
          },
        };
      });
    },
    [filtersConfig, state.widgetData.categoryFilters, state.widgetData.defaults]
  );

  const handleCheckAll = useCallback(
    (category: string) => {
      if (!filtersConfig) {
        return;
      }

      const indexToUpdate = state.widgetData.categoryFilters.findIndex(
        (x) => x.category === category
      );
      const updateCategory = state.widgetData.categoryFilters[indexToUpdate];

      if (
        updateCategory.values.length ===
        filtersConfig.filters.filter((x) => x.title === category)[0].filters.length
      ) {
        updateCategory.values = [];
      } else {
        updateCategory.values = filtersConfig.filters
          .filter((x) => x.title === category)[0]
          .filters.map((x) => x.label);
      }
      setState((prev) => {
        const categoryUpdated = [
          ...prev.widgetData.categoryFilters.toSpliced(indexToUpdate, 1, updateCategory),
        ];
        return {
          ...prev,
          widgetData: {
            ...prev.widgetData,
            categoryFilters: categoryUpdated,
          },
        };
      });
    },
    [filtersConfig, state.widgetData.categoryFilters]
  );

  const getDataSetsByFilters = useCallback(async () => {
    if (!state.widgetParameters.collectionId) return initialDataChart;
    if (state.widgetData.categoryFilters.filter((x) => x.values.length > 0).length === 0)
      return initialDataChart;

    const orderByLevel = () => {
      const levels = state.widgetData.categoryFilters.map((lv) => lv.level);
      const filters: Array<{ category: Array<string>; level: number; values: Array<string> }> = [];
      levels.forEach((lv) => {
        const currentLevel = state.widgetData.categoryFilters.filter((f) => f.level === lv);
        const filter = {
          level: lv,
          category: currentLevel.flatMap((x) => x.category),
          values: currentLevel.flatMap((x) => x.values),
        };
        if (!filters.find((x) => x.level === filter.level)) {
          if (filter.values.length > 0) {
            filters.push(filter);
          }
        }
      });
      return filters;
    };

    const userFilters = orderByLevel();

    if (userFilters.length === 0) {
      return initialDataChart;
    }

    return await getDocumentCollectionStatsByFilters(
      state.widgetParameters.collectionId,
      userFilters
    );
  }, [
    state.widgetParameters.collectionId,
    getDocumentCollectionStatsByFilters,
    state.widgetData.categoryFilters,
  ]);

  const { data: statsResponse, isLoading: dataSetForPieLoading } = useOrganizationQuery(
    `/collection-report-stats/${state.widgetParameters.collectionId}/${JSON.stringify(
      state.widgetData.categoryFilters
    )}`,
    getDataSetsByFilters,
    {
      onSuccess(dataSet) {
        if (dataSet) {
          setState((prev) => {
            return {
              ...prev,
              widgetData: {
                ...prev.widgetData,
                forPieData: dataSet,
              },
            };
          });
        }
      },
    }
  );

  const updateFilterValue = useCallback((value: string) => {
    setState((prev) => {
      return {
        ...prev,
        assignedFilters: value,
      };
    });
  }, []);

  return {
    state,
    setEditMode,
    resetConfiguration,
    saveConfiguration,
    updateFiltersByChanges,
    handlerBoxFilterValues,
    handleCheckAll,
    isLoading,
    filtersConfig,
    payload: statsResponse,
    updateFilterValue,
    formConfig,
    isError,
    dataSetForPieLoading,
    changeSort,
  };
};
