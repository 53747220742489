import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../utils";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const BroomSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 20,
  height = 20,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_10392_119259)">
        <path
          d="M18.6639 16.4198C18.7518 14.5252 19.1747 13.0944 19.4238 12.8398H7.53227C7.23708 13.2607 6.46245 14.4697 6.41011 16.2289V16.2885C6.39546 16.9556 6.55457 17.5838 6.84139 18.1257C6.98794 18.4028 7.40666 18.3392 7.46109 18.0313C7.52599 17.6515 7.68301 17.2738 7.90702 16.8797C8.11847 16.5061 8.58534 16.0216 8.6314 15.9765C8.61047 16.036 8.26921 16.9638 8.19175 17.4503C8.1101 17.9635 8.1436 18.5013 8.38645 18.992C8.50997 19.2383 8.69212 19.4702 8.92032 19.6653C9.17783 19.8849 9.5128 20.0019 9.85405 20.0019H10.7459C10.6894 19.9219 10.6391 19.8274 10.5952 19.7207C10.1995 18.7826 10.3084 17.9409 10.6852 16.9946C10.859 16.5594 11.2735 15.9744 11.2735 15.9744C11.2735 15.9744 11.0097 17.0459 10.9699 17.5899C10.926 18.1483 10.9846 18.723 11.2295 19.2321L11.2337 19.2403C11.4598 19.7063 11.9393 20.0019 12.4648 20.0019H14.2171C13.6602 19.2157 13.3587 18.2222 13.4048 17.1629C13.4111 16.3644 13.5681 15.5721 13.8716 14.8433C13.6393 15.5967 13.5618 16.387 13.6267 17.1547C13.7 17.9081 13.8758 18.6738 14.2925 19.3142C14.5562 19.7433 15.0294 20.004 15.5381 20.004H16.9157C16.296 19.1993 15.9548 18.1503 16.005 17.0295C16.0113 16.2289 16.1683 15.4366 16.4719 14.7079C16.2395 15.4612 16.162 16.2515 16.2269 17.0213C16.3002 17.7747 16.4782 18.5383 16.8948 19.1808L16.9011 19.1911C17.213 19.696 17.7699 20.006 18.3728 20.006H20.0017C19.7965 19.7946 18.5634 18.6266 18.6659 16.426L18.6639 16.4198Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M18.375 18.2412C18.3834 18.1817 18.3917 18.1201 18.4001 18.0605C18.5111 18.0749 18.5195 18.1591 18.5216 18.2432C18.4734 18.2432 18.4232 18.2432 18.375 18.2412Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M13.2021 19.0313C13.2231 18.9964 13.2503 18.9287 13.265 18.9307C13.3299 18.941 13.3131 18.9923 13.2964 19.0354C13.265 19.0354 13.2336 19.0333 13.2021 19.0313Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M13.1729 19.1626C13.1833 19.1195 13.1917 19.0764 13.2022 19.0312C13.2336 19.0312 13.265 19.0354 13.2964 19.0354C13.3047 19.0846 13.3131 19.1318 13.3215 19.1811C13.2733 19.1749 13.2231 19.1688 13.1749 19.1626H13.1729Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M9.32608 8.43299C9.87878 7.87259 10.622 7.47846 11.4406 7.24034C11.8363 7.12538 11.9368 6.93037 11.9347 6.55061C11.9117 4.35006 11.9033 4.24126 11.9075 2.04071C11.9096 0.689995 12.9103 -0.182426 14.1706 0.0351658C14.9578 0.170648 15.5587 0.733103 15.6801 1.50904C15.7429 1.90728 15.7387 2.31783 15.7408 2.72428C15.7513 4.67234 15.7701 4.52454 15.7492 6.47261C15.745 6.93243 15.8706 7.13975 16.3396 7.29987C18.272 7.9588 19.4883 9.24588 19.7835 11.2904C19.8505 11.7564 19.7249 11.9001 19.2245 11.896C15.634 11.8611 12.0415 11.8467 8.44886 11.8529C7.97362 11.8529 7.86266 11.7277 7.92337 11.274C8.06783 10.1943 8.47189 9.25204 9.32608 8.43709V8.43299Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M4.2754 13.3145C4.75692 13.3165 5.13796 13.6922 5.13796 14.1664C5.13796 14.6344 4.74646 15.0182 4.26912 15.0182C3.80434 15.0182 3.39819 14.6159 3.40238 14.1602C3.40656 13.6942 3.80225 13.3124 4.2754 13.3145Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M0.873056 15.4981C1.35458 15.5001 1.73561 15.8758 1.73561 16.3499C1.73561 16.818 1.34411 17.2018 0.866775 17.2018C0.402 17.2018 -0.00415507 16.7995 3.20915e-05 16.3438C0.00421926 15.8778 0.399906 15.496 0.873056 15.4981Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M4.08261 16.3477C5.05194 16.3538 5.8161 17.1092 5.8161 18.0597C5.8161 19.0019 5.02891 19.7737 4.07005 19.7737C3.13631 19.7737 2.31981 18.9649 2.32819 18.0474C2.33656 17.1113 3.13003 16.3436 4.08261 16.3477Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_10392_119259">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
