import {
  Box,
  BoxWithRef,
  ColorConfig,
  Layer,
  Popover,
  TapArea,
  Text,
  useColors,
  type TextSize,
} from "@prodoctivity/design-system";
import { useCallback, useRef, useState } from "react";

import { useUserPublicProfile } from "../../../hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { AvatarImage } from "../../Avatar/Avatar";
import { OrganizationNavLink } from "../../OrganizationLink";

type Props = {
  username: string;
  size?: TextSize;
  weight?: "normal" | "bold";
  sizeImage?: 32 | 48 | 62;
  onlyImage?: boolean;
  evictUserListCache?: boolean;
  color?: ColorConfig;
};

const PublicProfile: React.FC<Props> = ({
  username,
  size = "200",
  weight = "normal",
  onlyImage = false,
  sizeImage = 32,
  evictUserListCache,
  color: rawColor,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.primary;
  const { data: userProfile, isLoading } = useUserPublicProfile(username, evictUserListCache);
  const { resources } = useAppTranslation();
  const [open, setOpen] = useState<{ [itemId: string]: boolean }>({});

  const close = useCallback((itemId?: string) => {
    if (itemId) setOpen({ [itemId]: false });
  }, []);

  const handleElementClick = (itemId: string) => {
    setOpen((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const email = userProfile?.email;

  const userTapped = useCallback(() => {
    if (email) {
      setOpen({ email: true });
    }
  }, [email]);
  const anchorRef = useRef<HTMLDivElement>(null);
  if (onlyImage) {
    if (isLoading || !userProfile) {
      return <Box></Box>;
    }
    return (
      <AvatarImage
        imageDataURI={userProfile.avatarImageDataURI || ""}
        email={userProfile.email}
        size={sizeImage}
        name={userProfile.firstName}
      />
    );
  }
  return (
    <Box>
      {userProfile && (
        <BoxWithRef ref={anchorRef} display="inlineBlock">
          <TapArea
            onMouseEnter={() => handleElementClick(userProfile.email)}
            onTap={userTapped}
            onMouseLeave={() => close(userProfile.email)}
          >
            <Text weight={weight} inline={true} color={color} size={size}>
              {!userProfile || isLoading
                ? resources.loading
                : `${userProfile.firstName} ${userProfile.lastName}`}
            </Text>
          </TapArea>
        </BoxWithRef>
      )}
      {open && userProfile && !isLoading && anchorRef.current && open[userProfile.email] && (
        <Layer>
          <Popover
            onDismiss={() => close(userProfile.email)}
            anchor={anchorRef.current}
            idealDirection="down"
            size="flexible"
            positionRelativeToAnchor={false}
            color="white"
            role="menu"
          >
            <Box display="flex" direction="column" color={colors.white}>
              <Box padding={5} display="flex" justifyContent="center">
                <Box padding={1} display="flex" direction="column" justifyContent="center">
                  <AvatarImage
                    imageDataURI={userProfile.avatarImageDataURI || ""}
                    email={userProfile.email}
                    name={userProfile.firstName}
                    size={62}
                  />
                </Box>
                <Box margin={2} />
                <Box display="flex" gap={4}>
                  <Box display="flex" direction="column">
                    <Box direction="column">
                      <Text weight="bold" inline size="400">
                        {userProfile.firstName + " " + userProfile.lastName}{" "}
                      </Text>
                    </Box>
                    <Box direction="column">
                      <Text weight="normal" inline>
                        {userProfile.email}
                      </Text>
                    </Box>
                    {userProfile.positionRole && (
                      <Box direction="column">
                        <Text weight="normal" inline>
                          {userProfile.positionRole}
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box borderRadius={4} borderStyle="lg" width="100%"></Box>
              <Box
                display="flex"
                justifyContent="around"
                alignItems="center"
                color={colors.neutral300}
                dangerouslySetInlineStyle={{
                  __style: {
                    cursor: "pointer",
                  },
                }}
              >
                <OrganizationNavLink to={"/activities"}>
                  <Box
                    padding={2}
                    width="17vw"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Text weight="bold" inline>
                      {resources.activities.activity}
                    </Text>
                  </Box>
                </OrganizationNavLink>
              </Box>
            </Box>
          </Popover>
        </Layer>
      )}
    </Box>
  );
};

export { PublicProfile };
