import { FunctionComponent, PropsWithChildren, useMemo } from "react";

import { isValueListContextField } from "@prodoctivity/shared";
import type {
  ContextRecord,
  ContextField,
  ContextFieldProperties,
  DataElement,
} from "@prodoctivity/shared/src/index-types";
import type { DataElementInputProps } from "../dictionary";
import { Box } from "./Box";
import { useColors } from "./ColorSchemeProvider";
import { MarkdownViewer } from "./Markdown/MarkdownViewer";
import { Text } from "./Text";
import { WhatsThis } from "./WhatsThis";
import { Icon } from "./Icon";
import { TapArea } from "./TapArea";
import { useTranslation } from "react-i18next";

export interface Item {
  id: string;
  label: string;
  dataElementInfo?: DataElement | ContextRecord;
  selected: boolean;
  items?: Array<Item>;
  fullPath: string;
}

type Props = {
  properties: ContextFieldProperties;
  id: string;
  focused: boolean;
  purpose?: DataElementInputProps["purpose"];
  getDynamicValue?(text: string): string;
  contextField: ContextField;
  onEditClick?: (
    dataElements: {
      dataElement: DataElement;
      itemToReplace: Item;
    },
    fullPath: string,
    properties: ContextFieldProperties
  ) => void;
};

function renderDefinition(definition: string) {
  return (
    <span>
      {(definition || "").split("\n").map((d, i) => (
        <div key={i}>{d}</div>
      ))}
    </span>
  );
}

export const FormItem: FunctionComponent<PropsWithChildren<Props>> = ({
  properties,
  id,
  focused,
  purpose,
  getDynamicValue,
  children,
  contextField,
  onEditClick,
}) => {
  const { colors } = useColors();
  const { t } = useTranslation();
  const { activeDescriptionText, activeLabelText } = useMemo(() => {
    if (!getDynamicValue) {
      return { activeDescriptionText: properties.description, activeLabelText: properties.label };
    }

    return {
      activeDescriptionText: getDynamicValue(properties.description),
      activeLabelText: getDynamicValue(properties.label),
    };
  }, [getDynamicValue, properties.description, properties.label]);

  const required = !!properties.minOccurs && properties.minOccurs > 0;
  const { name, humanName, fullPath } = contextField;

  return (
    <Box
      key={id}
      about={focused ? "focused" : undefined}
      minHeight={properties.dataType !== "Logical" ? "50px" : "80px"}
      minWidth="210px"
      position="relative"
    >
      <Box display="flex" direction="column">
        <Box display="flex">
          <Box display="flex" flex="grow" gap={1}>
            {purpose === "edit-template" && (
              <Box display="flex" direction="column">
                <Box display="flex" flex="grow" />
                <Box width={25} height={34}></Box>
                <Box margin={1} />
              </Box>
            )}
            <Text weight="bold" size="200">
              {activeLabelText}
            </Text>
            {required ? <Text color={colors.error}> *</Text> : null}
            {properties.instructions && (focused || forceDisplayHelp(properties)) && (
              <WhatsThis
                title={properties.instructions}
                color={colors.neutral900}
                colorBundle={colors}
                iconType="info"
                size="xs"
              >
                {renderDefinition(properties.instructions)}
              </WhatsThis>
            )}
          </Box>
          {onEditClick && (
            <Box
              onClickCapture={() => {
                const dataElements: any = {
                  ...properties,
                  name: name,
                  humanName: humanName,
                };

                onEditClick(
                  { dataElement: dataElements, itemToReplace: dataElements },
                  fullPath,
                  properties
                );
              }}
            >
              <TapArea>
                <Icon icon={"pencil"} accessibilityLabel={t("pencil")} />
              </TapArea>
            </Box>
          )}
        </Box>
        {!!properties.description && (
          <Box display="flex" direction="row">
            {purpose === "edit-template" && (
              <Box display="flex" direction="column">
                <Box display="flex" flex="grow" />
                <Box width={25} height={34}></Box>
                <Box margin={1} />
              </Box>
            )}
            <Box display="flex" direction="column">
              <Box display="flex" direction="row">
                <MarkdownViewer markdownText={activeDescriptionText} size="sm" />
              </Box>
            </Box>
          </Box>
        )}
        <Box direction="row" flex="grow">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

function forceDisplayHelp(properties: ContextFieldProperties): boolean {
  switch (properties.dataType) {
    case "Alphanumeric":
      return isValueListContextField(properties);
    case "Image":
      return true;
    case "Logical":
      return true;
    default:
      return false;
  }
}
