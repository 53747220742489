import { UnsignedUpTo12, useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import { ListSvg } from "@prodoctivity/design-system/svg/ListSvg";
import type {
  ParametersObject,
  TemplateContextDefinition,
} from "@prodoctivity/shared/src/index-types";
import type {
  DashboardConfig,
  DashboardConfigColumn,
  DashboardConfigRow,
  DashboardElementConfig,
  DashboardElementType,
} from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { FunctionComponent, ReactNode, useCallback, useMemo, useState } from "react";
import NoThumbnailImg from "../../assets/NoThumbnailImg.png";
import DocumentCollectionReportThumbnail from "../../assets/WidgetComponentThumbnails/DocumentCollectionReportThumbnail.png";
import DocumentCoolectionAssignnedToUseThumbnail from "../../assets/WidgetComponentThumbnails/DocumentCoolectionAssignnedToUseThumbnail.png";
import ProcessTaskThumbnail from "../../assets/WidgetComponentThumbnails/ProcessTaskThumbnail.png";
import DocumentCollectionStatsThumbnail from "../../assets/WidgetComponentThumbnails/DocumentCollectionStatsThumbnail.png";
import MyListViewThumbnail from "../../assets/WidgetComponentThumbnails/MyListThumbnail.png";
import MyTasksThumbnail from "../../assets/WidgetComponentThumbnails/MyTasksThumbnail.png";
import RecentActivityThumbanil from "../../assets/WidgetComponentThumbnails/RecentActivityThumbnail.png";
import RecentDocumentsCollectionInstanceViewThumbnail from "../../assets/WidgetComponentThumbnails/RecentDocumentsCollectionInstanceViewThumbnail.png";
import RecentDocumentsThumbnail from "../../assets/WidgetComponentThumbnails/RecentDocumentsThumbnail.png";
import { RecentActivityHome } from "../../components/RecentActivities/RecentActivityHome";
import { DocumentCollectionReport } from "../../components/Reports/document-collection-report/DocumentCollectionReport";
import { HomeTasks } from "../../components/Tasks/HomeTasks";
import { ReportWidget } from "../../components/Widgets/DocumentCollectionReport/ReportCollectionWidget";
import { QuickActions } from "../../components/Widgets/QuickActions/QuickActionsGrid";
import { RecentDocumentCollectionInstanceView } from "../../components/Widgets/RecentDocuments/RecentDocumentCollectionInstanceView";
import { RecentDocumentsGrid } from "../../components/Widgets/RecentDocuments/RecentDocumentsGrid";
import { TaskReportWidget } from "../../components/Widgets/Task-reports";
import { includeIf } from "../../context/SettingsPageProvider";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { DocumentCollectionReportWidgetIconSvg } from "../../svg/DocumentCollectionReportWidgetIconSvg";
import { MyTasksWidgetIconSvg } from "../../svg/MyTasksWidgetIconSvg";
import { QuickActionsWidgetIconSvg } from "../../svg/QuickActionsWidgetIconSvg";
import { RecentActivityWidgetIconSvg } from "../../svg/RecentActivityWidgetIconSvg";
import { RecentDocumentCollectionInstanceViewIcon } from "../../svg/RecentDocumentCollectionInstanceViewIcon";
import { RecentDocumentsWidgetIconSvg } from "../../svg/RecentDocumentsWidgetIconSvg";
import { TemplateIconSvg } from "../../svg/TemplateIconSvg";
import { ReportIconSvg } from "../../svg/reports/ReportIconSvg";
import { DocumentListWidget } from "../DocumentLabels/Components/ProfileDocumentLabelsList/DocumentListWidget";
import { TemplateListDashboardComponent } from "../templates/TemplateList";
import { ColumnItemPlaceholder } from "./ColumnItemPlaceholder";
import { AssignmentList } from "./Assignment-list";
import { AddWidgetIcon } from "../../svg/AddWidgetIcon";

export type DashboardParametersTypeSet = {
  allowParamEdition: boolean;
  params: ParametersObject;
  setNewParams: (
    params: ParametersObject,
    setOfIndexes:
      | {
          rowIndex: number;
          colIndex: number;
          itemIndex: number;
        }
      | undefined,
    sidebarItemIndex: number | undefined
  ) => void;
  setOfIndexes?: {
    rowIndex: number;
    colIndex: number;
    itemIndex: number;
  };
  sidebarItemIndex?: number;
};

export type DashboardElementImplementation<T> = {
  elementType: DashboardElementType;
  contextDefinition: TemplateContextDefinition;
  Component: FunctionComponent<T>;
  icon: ReactNode;
  thumbnail: string;
  allowsRefresh: boolean | undefined;
};

type HandleDeleteColumnItemParams = {
  columnCount: number;
  rowIndex: number;
  colIndex: number;
  itemIndex: number;
  externalState?: DashboardConfig;
};

export const useUserDashboardConfig = () => {
  /* ---------------------------- #FETCHING DASHBOARD CONFIG region ---------------------------- */

  const { getMyUserProfile, updateUserProfile, fetchOrganizationAdminInfo } = useServices();
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const [sidebarToShow, setSidebarToShow] = useState<number | null>(null);
  const [editionMode, setEditionMode] = useState<boolean>(false);
  const allowEditionMode = useMemo(() => {
    if (breakpoint !== "hd") {
      return false;
    }
    return editionMode;
  }, [breakpoint, editionMode]);
  const [dashboardViewMode, setDashboardViewMode] = useState<"main" | "sidebar">("main");
  const [colorIconOnHover, setColorIconOnHover] = useState<boolean>(false);
  const [newRowId, setNewRowId] = useState<number | null>(null);
  const [newColumnId, setNewColumnId] = useState<number | null>(null);
  const [openListOfWidgets, setOpenListOfWidgets] = useState<{
    isListOfWidgetsOpen: boolean;
    rowIndex: number | null;
    colIndex: number | null;
    addToSidebar: boolean;
  }>({
    isListOfWidgetsOpen: false,
    rowIndex: null,
    colIndex: null,
    addToSidebar: false,
  });

  const [currentDashboardConfig, setCurrentDashboardConfig] = useState<DashboardConfig | undefined>(
    undefined
  );

  const fetchOrganizationData = useCallback(async () => {
    return await fetchOrganizationAdminInfo();
  }, [fetchOrganizationAdminInfo]);

  const { data: OrganizationInfo } = useOrganizationQuery(
    `organization-admin-plan-data/${currentDashboardConfig}`,
    fetchOrganizationData
  );

  const isEnterprise = useMemo(() => {
    return OrganizationInfo?.settings.plan === "enterprise";
  }, [OrganizationInfo]);

  const defaultDashboardConfig: DashboardConfig = useMemo(() => {
    const initialDashboard: DashboardConfig = {
      id: "1",
      label: resources.dashboard.defaultDashboardName,
      rows: [
        {
          columnCount: 2,
          columns: [
            {
              elements: [
                {
                  elementType: "document-collection-report",
                  parameters: {
                    icon: "",
                    isUsedInSidebar: false,
                  },
                },
              ],
            },
            {
              elements: [
                {
                  elementType: "recent-documents",
                  parameters: {
                    elementType: "tasks",
                    icon: "",
                    isUsedInSidebar: false,
                  },
                },
              ],
            },
          ],
        },
      ],
      sidePanelConfig: {
        elements: [
          {
            elementType: "tasks",
            parameters: {
              elementType: "tasks",
              icon: "",
              isUsedInSidebar: false,
            },
          },
          {
            elementType: "recent-activities",
            parameters: {
              elementType: "papito",
              icon: "",
              isUsedInSidebar: false,
            },
          },
        ],
      },
    };
    return initialDashboard;
  }, [resources.dashboard.defaultDashboardName]);

  const listOfWidgets: DashboardElementImplementation<DashboardParametersTypeSet>[] =
    useMemo(() => {
      const componentList: DashboardElementImplementation<DashboardParametersTypeSet>[] = [
        {
          elementType: "recent-documents",
          contextDefinition: {
            records: [],
            fields: [
              {
                name: resources.recentDocuments,
                fullPath: "toBeDetermined",
                humanName: resources.recentDocuments,
                properties: {
                  dataType: "Alphanumeric",
                  description: resources.dashboard.widgetList.recentDocumentsDescription,
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: RecentDocumentsGrid,
          icon: <RecentDocumentsWidgetIconSvg />,
          thumbnail: RecentDocumentsThumbnail,
          allowsRefresh: true,
        },
        {
          elementType: "quick-actions",
          contextDefinition: {
            records: [],
            fields: [
              {
                name: resources.dashboard.quickActions,
                fullPath: "toBeDetermined",
                humanName: resources.dashboard.quickActions,
                properties: {
                  dataType: "Alphanumeric",
                  description: resources.dashboard.quickActionsDesc,
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: QuickActions,
          icon: <QuickActionsWidgetIconSvg />,
          thumbnail: RecentDocumentsThumbnail,
          allowsRefresh: false,
        },
        {
          elementType: "tasks",
          contextDefinition: {
            records: [],
            fields: [
              {
                name: resources.homePage.myTasks,
                fullPath: "toBeDetermined",
                humanName: resources.homePage.myTasks,
                properties: {
                  dataType: "Alphanumeric",
                  description: resources.dashboard.widgetList.myTasksDescription,
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: HomeTasks,
          icon: <MyTasksWidgetIconSvg />,
          thumbnail: MyTasksThumbnail,
          allowsRefresh: true,
        },
        {
          elementType: "recent-activities",
          contextDefinition: {
            records: [],
            fields: [
              {
                name: resources.recentActivity,
                fullPath: "toBeDetermined",
                humanName: resources.recentActivity,
                properties: {
                  dataType: "Alphanumeric",
                  description: resources.dashboard.widgetList.recentActivityDescription,
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: RecentActivityHome,
          icon: <RecentActivityWidgetIconSvg />,
          thumbnail: RecentActivityThumbanil,
          allowsRefresh: true,
        },
        {
          elementType: "templates",
          contextDefinition: {
            records: [],
            fields: [
              {
                name: resources.templates,
                fullPath: "toBeDetermined",
                humanName: resources.templates,
                properties: {
                  dataType: "Alphanumeric",
                  description: resources.dashboard.widgetList.templatesDescription,
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: TemplateListDashboardComponent,
          icon: <TemplateIconSvg color={colors.error} />,
          thumbnail: DocumentCollectionReportThumbnail,
          allowsRefresh: true,
        },
        {
          elementType: "document-collection-instance-view",
          contextDefinition: {
            records: [],
            fields: [
              {
                name: resources.recentDocumentsCollection,
                fullPath: "toBeDetermined",
                humanName: resources.recentDocumentsCollection,
                properties: {
                  dataType: "Alphanumeric",
                  description: resources.dashboard.widgetList.recentDocumentCollectionsDescription,
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: RecentDocumentCollectionInstanceView,
          icon: <RecentDocumentCollectionInstanceViewIcon />,
          thumbnail: RecentDocumentsCollectionInstanceViewThumbnail,
          allowsRefresh: true,
        },
        {
          elementType: "my-lists",
          contextDefinition: {
            records: [],
            fields: [
              {
                name: resources.myLists,
                fullPath: "toBeDetermined",
                humanName: resources.myLists,
                properties: {
                  dataType: "Alphanumeric",
                  description: resources.viewAllUserLists,
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: DocumentListWidget,
          icon: <ListSvg width={25} height={25} />,
          thumbnail: MyListViewThumbnail,
          allowsRefresh: true,
        },
        {
          elementType: "document-collection-report",
          contextDefinition: {
            records: [],
            fields: [
              {
                name: resources.dashboard.widgetList.documentCollectionReport,
                fullPath: "toBeDetermined",
                humanName: resources.dashboard.widgetList.documentCollectionReport,
                properties: {
                  dataType: "Alphanumeric",
                  description: resources.dashboard.widgetList.documentCollectionReportDescription,
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: DocumentCollectionReport,
          icon: <DocumentCollectionReportWidgetIconSvg />,
          thumbnail: DocumentCollectionReportThumbnail,
          allowsRefresh: true,
        },
        {
          elementType: "task-status-report",
          allowsRefresh: false,
          contextDefinition: {
            records: [],
            fields: [
              {
                name: "Estado de las tareas",
                fullPath: "toBeDetermined",
                humanName: "Estado de las tareas",
                properties: {
                  dataType: "Alphanumeric",
                  description: "Estado de las tareas",
                  humanName: "",
                  inputType: "TextBox",
                  instructions: "",
                  label: "",
                  classDataElementTag: undefined,
                  sequenceId: undefined,
                },
              },
            ],
          },
          Component: TaskReportWidget,
          icon: <ReportIconSvg color={colors.primary700} />,
          thumbnail: ProcessTaskThumbnail,
        },
        ...includeIf<DashboardElementImplementation<DashboardParametersTypeSet>>(
          () => isEnterprise,
          {
            elementType: "collectionReport",
            allowsRefresh: true,
            contextDefinition: {
              records: [],
              fields: [
                {
                  name: resources.collectionSummaries,
                  fullPath: "toBeDetermined",
                  humanName: resources.collectionSummaries,
                  properties: {
                    dataType: "Alphanumeric",
                    description: resources.dashboard.widgetList.reportDocumentCollectionDescription,
                    humanName: "",
                    inputType: "TextBox",
                    instructions: "",
                    label: "",
                    classDataElementTag: undefined,
                    sequenceId: undefined,
                  },
                },
              ],
            },
            Component: ReportWidget,
            icon: <ReportIconSvg color={colors.primary700} />,
            thumbnail: DocumentCollectionStatsThumbnail,
          }
        ),
        ...includeIf<DashboardElementImplementation<DashboardParametersTypeSet>>(
          () => isEnterprise,
          {
            elementType: "assignment-list-by-document-collection",
            allowsRefresh: true,
            contextDefinition: {
              records: [],
              fields: [
                {
                  name: resources.viewOfDocumentCollectionAssignedByUser, //resources.dashboard.widgetList.reportOfDocumentCollection,
                  fullPath: "toBeDetermined",
                  humanName: resources.viewOfDocumentCollectionAssignedByUser, //resources.dashboard.widgetList.reportOfDocumentCollection,
                  properties: {
                    dataType: "Alphanumeric",
                    description:
                      resources.dashboard.widgetList.assignmentListByDocumentCollectionDescription,
                    humanName: "",
                    inputType: "TextBox",
                    instructions: "",
                    label: "",
                    classDataElementTag: undefined,
                    sequenceId: undefined,
                  },
                },
              ],
            },
            Component: AssignmentList,
            icon: <ReportIconSvg color={colors.primary700} />,
            thumbnail: DocumentCoolectionAssignnedToUseThumbnail,
          }
        ),
      ];
      return componentList;
    }, [
      colors.error,
      resources.dashboard.quickActions,
      resources.dashboard.quickActionsDesc,
      resources.dashboard.widgetList.documentCollectionReport,
      resources.dashboard.widgetList.documentCollectionReportDescription,
      resources.dashboard.widgetList.myTasksDescription,
      resources.dashboard.widgetList.recentActivityDescription,
      resources.dashboard.widgetList.recentDocumentsDescription,
      resources.dashboard.widgetList.templatesDescription,
      resources.dashboard.widgetList.recentDocumentCollectionsDescription,
      resources.homePage.myTasks,
      resources.recentActivity,
      resources.recentDocuments,
      resources.recentDocumentsCollection,
      resources.myLists,
      resources.viewAllUserLists,
      resources.templates,
      colors.primary700,
      resources.dashboard.widgetList.reportDocumentCollectionDescription,
      resources.collectionSummaries,
      resources.dashboard.widgetList.assignmentListByDocumentCollectionDescription,
      resources.viewOfDocumentCollectionAssignedByUser,
      isEnterprise,
    ]);

  const handleElementParametersChange = useCallback(
    (
      params: ParametersObject,
      setOfIndexes:
        | {
            rowIndex: number;
            colIndex: number;
            itemIndex: number;
          }
        | undefined,
      sidebarItemIndex: number | undefined
    ) => {
      // console.log('Update Parameters for', params, setOfIndexes, sidebarItemIndex)
      if (!currentDashboardConfig) {
        return;
      }

      const newState = { ...currentDashboardConfig };
      if (setOfIndexes) {
        newState.rows[setOfIndexes.rowIndex].columns[setOfIndexes.colIndex].elements[
          setOfIndexes.itemIndex
        ].parameters = params;
      }
      if (sidebarItemIndex) {
        newState.sidePanelConfig.elements[sidebarItemIndex].parameters = params;
      }
      setCurrentDashboardConfig(newState);
    },
    [currentDashboardConfig]
  );

  const getMyUserProfileDetails = useCallback(async () => {
    const myUserProfileResponse = await getMyUserProfile();
    if (myUserProfileResponse && myUserProfileResponse.dashboardConfiguration) {
      setCurrentDashboardConfig(myUserProfileResponse.dashboardConfiguration);
    } else {
      setCurrentDashboardConfig(defaultDashboardConfig);
    }
    return myUserProfileResponse;
  }, [getMyUserProfile, defaultDashboardConfig]);

  const {
    // data: myProfileData,
    isLoading: getMyProfileIsLoading,
    isError: getMyProfileIsError,
    refetch: refetchMyProfile,
  } = useOrganizationQuery(`dashboard-configuration-applied/`, getMyUserProfileDetails, {
    refetchOnMount: "always",
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const { mutate: saveCurrentDashboardConfig } = useMutation(updateUserProfile);

  /* ---------------------------- #BUILDING DYNAMIC DASHBOARD region ---------------------------- */

  const dashboardRowsArray: DashboardConfigRow[] = useMemo(() => {
    return currentDashboardConfig ? currentDashboardConfig.rows : [];
  }, [currentDashboardConfig]);
  const sideBarsArray: DashboardElementConfig[] = useMemo(() => {
    return currentDashboardConfig ? currentDashboardConfig.sidePanelConfig.elements : [];
  }, [currentDashboardConfig]);
  const isThereAnySidebar = sideBarsArray.length > 0;
  const convertDashboardElementForImplementation: (
    elements: DashboardElementConfig[]
  ) => DashboardElementImplementation<DashboardParametersTypeSet>[] = useCallback(
    (elements: DashboardElementConfig[]) => {
      const arr: DashboardElementImplementation<DashboardParametersTypeSet>[] = elements.map(
        (el) => {
          const desiredElementFound = listOfWidgets.find(
            (widget) => widget.elementType === el.elementType
          );
          if (desiredElementFound) {
            return desiredElementFound;
          }
          return {
            elementType: "placeholder",
            contextDefinition: {
              records: [],
              fields: [
                {
                  name: "newElement",
                  fullPath: "newElement",
                  humanName: "",
                  type: "Single",
                  properties: {
                    dataType: "Alphanumeric",
                    description: "",
                    humanName: "",
                    inputType: "TextBox",
                    instructions: "",
                    label: "",
                    sequenceId: undefined,
                  },
                },
              ],
            },
            Component: ColumnItemPlaceholder,
            icon: <AddWidgetIcon color={colors.neutral700} />,
            thumbnail: NoThumbnailImg,
            allowsRefresh: undefined,
          };
        }
      );
      return arr;
    },
    [colors.neutral700, listOfWidgets]
  );

  const convertDashboardColumnsForImplementation: (
    columns: DashboardConfigColumn[]
  ) => DashboardElementImplementation<DashboardParametersTypeSet>[][] = useCallback(
    (columns: DashboardConfigColumn[]) => {
      const arrayOfColumns: DashboardElementImplementation<DashboardParametersTypeSet>[][] =
        columns.map((column) => {
          const arr: DashboardElementImplementation<DashboardParametersTypeSet>[] =
            convertDashboardElementForImplementation(column.elements);
          return arr;
        });
      return arrayOfColumns;
    },
    [convertDashboardElementForImplementation]
  );

  const setOfSidebarsPerConfig = convertDashboardElementForImplementation(sideBarsArray);

  /* ---------------------------- #HANDLING DASHBOARD CONFIGURATION region ---------------------------- */

  const handleClearPlaceholdersBeforeSave = useCallback((config: DashboardConfig) => {
    // Remove placeholders from the rows before saving
    const newState: DashboardConfig = { ...config };
    if (
      (config.rows.length === 1 &&
        config.rows[0].columns.length === 1 &&
        config.rows[0].columns[0].elements.length === 1 &&
        config.rows[0].columns[0].elements[0].elementType === "placeholder") ||
      (config.rows.length === 1 &&
        config.rows[0].columns.length === 2 &&
        config.rows[0].columns[0].elements.length === 1 &&
        config.rows[0].columns[1].elements.length === 1 &&
        config.rows[0].columns[0].elements[0].elementType === "placeholder" &&
        config.rows[0].columns[1].elements[0].elementType === "placeholder")
    ) {
      // If the dashboard is empty (only 1 row 1 placeholder), return the original state
      return newState;
    } else {
      newState.rows = newState.rows
        // Removes rows with columns where there is only placeholder items on them
        .filter((row) => {
          if (
            (row.columns.length === 1 &&
              row.columns[0].elements.length === 1 &&
              row.columns[0].elements[0].elementType === "placeholder") ||
            (row.columns.length === 2 &&
              row.columns[0].elements.length === 1 &&
              row.columns[1].elements.length === 1 &&
              row.columns[0].elements[0].elementType === "placeholder" &&
              row.columns[1].elements[0].elementType === "placeholder")
          ) {
            return false;
          }
          return true;
        })
        .map((row) => {
          return {
            ...row,
            columns: row.columns
              // Removes columns with only placeholder items
              .filter((column) => {
                if (
                  column.elements.length === 1 &&
                  column.elements[0].elementType === "placeholder"
                ) {
                  return false;
                }
                return true;
              })
              // Removes placeholder items from columns
              .map((column) => {
                return {
                  ...column,
                  elements: column.elements.filter((el) => el.elementType !== "placeholder"),
                };
              }),
          };
        });

      // Adjust column count if needed
      newState.rows.map((row) => {
        if (row.columnCount === 2 && row.columns.length === 1) {
          row.columnCount = 1;
        }
        return row;
      });
    }

    return newState;
  }, []);

  const saveDashboardConfig: () => void = useCallback(() => {
    if (!currentDashboardConfig) {
      return;
    }

    const clearedDashboardConfig = handleClearPlaceholdersBeforeSave(currentDashboardConfig);
    if (clearedDashboardConfig.rows.length === 0) {
      clearedDashboardConfig.rows.push({
        columnCount: 1,
        columns: [
          {
            elements: [{ elementType: "placeholder", parameters: { icon: "" } }],
          },
        ],
      });
    }

    setCurrentDashboardConfig(clearedDashboardConfig);
    saveCurrentDashboardConfig({ dashboardConfiguration: clearedDashboardConfig });

    setOpenListOfWidgets((prev) => ({
      ...prev,
      isListOfWidgetsOpen: false,
    }));
    setEditionMode(false);
  }, [currentDashboardConfig, handleClearPlaceholdersBeforeSave, saveCurrentDashboardConfig]);

  const handleColumnAmount = useCallback(
    (columnType: "singleColumn" | "doubleColumn", index: number) => {
      if (!currentDashboardConfig) {
        return;
      }

      const newState = { ...currentDashboardConfig };
      newState.rows[index].columnCount = columnType === "singleColumn" ? 1 : 2;

      if (columnType === "doubleColumn" && newState.rows[index].columns.length <= 1) {
        newState.rows[index].columns.push({
          elements: [{ elementType: "placeholder", parameters: { icon: "plus" } }],
        });
      } else if (
        columnType === "singleColumn" &&
        newState.rows[index].columns[1]?.elements[0]?.elementType === "placeholder"
      ) {
        newState.rows[index].columns.splice(1, 1);
      }

      setCurrentDashboardConfig(newState);
    },
    [currentDashboardConfig]
  );

  const handleDeleteRow = useCallback(
    (index: number) => {
      if (!currentDashboardConfig) {
        return;
      }

      const previousState = { ...currentDashboardConfig };
      if (previousState.rows.length === 1) {
        previousState.rows[0].columns = [
          {
            elements: [{ elementType: "placeholder", parameters: { icon: "" } }],
          },
        ];
        previousState.rows[0].columnCount = 1;
      } else {
        const clearedRows = previousState.rows.filter(
          (_row, matchingIndex) => matchingIndex !== index
        );
        previousState.rows = clearedRows;
      }
      setCurrentDashboardConfig(previousState);
    },
    [currentDashboardConfig]
  );

  const handleDeleteColumnItem = useCallback(
    ({
      columnCount,
      rowIndex,
      colIndex,
      itemIndex,
      externalState,
    }: HandleDeleteColumnItemParams) => {
      // Clone the current state to avoid direct mutations
      let newState: DashboardConfig = JSON.parse(JSON.stringify(currentDashboardConfig));

      // Used for cases where the state was modified outside this function before this handler is called
      if (externalState) {
        newState = externalState;
      }

      const savedDeletedElement = newState.rows[rowIndex].columns[colIndex].elements[itemIndex];

      // Function to remove an item based on its index
      const removeElementAtIndex = (elements: DashboardElementConfig[], index: number) =>
        elements.filter((_element, filterIndex) => filterIndex !== index);

      // Simplify deletion logic by consolidating conditions
      const { rows } = newState;
      const columnElements = rows[rowIndex].columns[colIndex].elements;

      if (columnElements.length > 1) {
        // Remove the item if there are multiple elements
        newState.rows[rowIndex].columns[colIndex].elements = removeElementAtIndex(
          columnElements,
          itemIndex
        );
      } else if (columnCount === 1 && rows[rowIndex].columns.length === 2) {
        // Remove the column if it's the last item in a two-column row
        rows[rowIndex].columns.splice(colIndex, 1);
      } else if (columnCount === 2 && columnElements.length === 1) {
        // Adjust column count and remove column if only one item and two columns expected
        newState.rows[rowIndex].columns.splice(colIndex, 1);
        newState.rows[rowIndex].columnCount = 1;
      } else if (rows.length >= 1 && rows[rowIndex].columns.length === 1) {
        // Handle single-column row scenarios
        if (rows.length > 1) {
          // Remove the entire row if multiple rows exist
          newState.rows.splice(rowIndex, 1);
        } else if (rows[0].columns[0].elements[0].elementType !== "placeholder") {
          // Insert a placeholder if it's the last item in the dashboard
          newState.rows[0].columns[0].elements = [
            {
              elementType: "placeholder",
              parameters: { icon: "" },
            },
          ];
        }
      }

      // Update the state with the new configuration
      setCurrentDashboardConfig(newState);

      // Return the deleted element to allow for undo functionality
      return {
        deletedElement: savedDeletedElement,
        filteredState: newState,
      };
    },
    [currentDashboardConfig]
  );

  const handleMoveItemToSidebar = useCallback(
    ({ columnCount, rowIndex, colIndex, itemIndex }: HandleDeleteColumnItemParams) => {
      const modifiedState = handleDeleteColumnItem({
        columnCount,
        rowIndex,
        colIndex,
        itemIndex,
      });
      const adaptElementForSidebar: DashboardElementConfig = {
        ...modifiedState.deletedElement,
        parameters: { ...modifiedState.deletedElement.parameters, isUsedInSidebar: true },
      };
      modifiedState.filteredState.sidePanelConfig.elements.push(adaptElementForSidebar);

      // Remove placeholders from the sidebar if there are any
      modifiedState.filteredState.sidePanelConfig.elements =
        modifiedState.filteredState.sidePanelConfig.elements.filter(
          (el) => el.elementType !== "placeholder"
        );
      setCurrentDashboardConfig(modifiedState.filteredState);
    },
    [handleDeleteColumnItem]
  );

  const handleAddRowItem = useCallback(
    (insertRowOn: "top" | "bottom", index: number) => {
      if (!currentDashboardConfig) {
        return;
      }

      const newState = { ...currentDashboardConfig };
      const newRowElement: DashboardConfigRow = {
        columnCount: 1,
        columns: [
          {
            elements: [{ elementType: "placeholder", parameters: { icon: "" } }],
          },
        ],
      };

      const insertIndex = insertRowOn === "top" ? index : index + 1;
      newState.rows.splice(insertIndex, 0, newRowElement);
      setCurrentDashboardConfig(newState);
      const indexOfNewRow = newState.rows.findIndex((row) => row === newRowElement);
      setNewRowId(indexOfNewRow);
    },
    [currentDashboardConfig]
  );

  const handleMoveColumnItemVertically = useCallback(
    (move: "up" | "down", rowIndex: number, colIndex: number, itemIndex: number) => {
      if (!currentDashboardConfig) {
        return;
      }

      const newState = {
        ...currentDashboardConfig,
        rows: currentDashboardConfig.rows.map((row) => ({
          ...row,
          columns: row.columns.map((column) => ({
            ...column,
            elements: [...column.elements],
          })),
        })),
      };

      const newIndex = move === "up" ? itemIndex - 1 : itemIndex + 1;

      // Swap elements
      const temp = newState.rows[rowIndex].columns[colIndex].elements[itemIndex];
      newState.rows[rowIndex].columns[colIndex].elements[itemIndex] =
        newState.rows[rowIndex].columns[colIndex].elements[newIndex];
      newState.rows[rowIndex].columns[colIndex].elements[newIndex] = temp;

      setCurrentDashboardConfig(newState);
    },
    [currentDashboardConfig]
  );

  const handleAddColumnItem = useCallback(
    (elementType: DashboardElementType, rowIndex: number | null, colIndex: number | null) => {
      if (!currentDashboardConfig || rowIndex === null || colIndex === null) {
        return;
      }
      const newState = {
        ...currentDashboardConfig,
        rows: currentDashboardConfig.rows.map((row) => ({
          ...row,
          columns: row.columns.map((column) => ({
            ...column,
            elements: [...column.elements],
          })),
        })),
      };
      const newElement: DashboardElementConfig = {
        elementType,
        parameters: {
          icon: "",
          isUsedInSidebar: false,
        },
      };
      const listOfElementsToModify = newState.rows[rowIndex].columns[colIndex].elements;
      // This is used to avoid an undesired scroll to the bottom of the page caused by adding an item from a placeholder call
      const wasEmpty =
        listOfElementsToModify.length === 1 &&
        listOfElementsToModify[0].elementType === "placeholder";
      listOfElementsToModify.push(newElement);

      // cleaning any placeholder components from lists with 1 element or more
      const indexOfPlaceholder = listOfElementsToModify.findIndex(
        (element) => element.elementType === "placeholder"
      );
      if (indexOfPlaceholder !== -1) {
        listOfElementsToModify.splice(indexOfPlaceholder, 1);
      }

      setCurrentDashboardConfig(newState);
      const indexOfNewColumnItem = newState.rows[rowIndex].columns[colIndex].elements.findIndex(
        (el) => el === newElement
      );
      if (wasEmpty) {
        setNewColumnId(null);
      } else {
        setNewColumnId(indexOfNewColumnItem);
      }
    },
    [currentDashboardConfig]
  );

  const handleMoveItemToOppositeColumn = useCallback(
    ({ columnCount, rowIndex, colIndex, itemIndex }: HandleDeleteColumnItemParams) => {
      if (!currentDashboardConfig) {
        return;
      }
      const oldState: DashboardConfig = { ...currentDashboardConfig };
      const elementToMove = oldState.rows[rowIndex].columns[colIndex].elements[itemIndex];
      const newColumnIndex = colIndex === 0 ? 1 : 0;

      if (oldState.rows[rowIndex].columns.length === 1) {
        oldState.rows[rowIndex].columns.push({
          elements: [elementToMove],
        });
        oldState.rows[rowIndex].columnCount = 2;
      } else if (oldState.rows[rowIndex].columns.length === 2) {
        oldState.rows[rowIndex].columns[newColumnIndex].elements.unshift(elementToMove);
      }
      const updatedState = handleDeleteColumnItem({
        columnCount,
        rowIndex,
        colIndex,
        itemIndex,
        externalState: oldState,
      });
      const cleanedFromPlaceholdersState = handleClearPlaceholdersBeforeSave(
        updatedState.filteredState
      );
      setCurrentDashboardConfig(cleanedFromPlaceholdersState);
    },
    [currentDashboardConfig, handleClearPlaceholdersBeforeSave, handleDeleteColumnItem]
  );

  const handleDeleteSidebarItem = useCallback(
    ({ sidebarItemIndex }: { sidebarItemIndex: number }) => {
      if (!currentDashboardConfig) {
        return;
      }
      if (
        currentDashboardConfig.sidePanelConfig.elements.length === 1 &&
        currentDashboardConfig.sidePanelConfig.elements[0].elementType === "placeholder"
      )
        return;
      const newState = { ...currentDashboardConfig };
      if (
        newState.sidePanelConfig.elements.length === 1 &&
        newState.sidePanelConfig.elements[0].elementType !== "placeholder"
      ) {
        // Insert a placeholder if it's the last item in the sidebar
        newState.sidePanelConfig.elements.push({
          elementType: "placeholder",
          parameters: { icon: "" },
        });
      }
      newState.sidePanelConfig.elements.splice(sidebarItemIndex, 1);
      setSidebarToShow(null);
      setCurrentDashboardConfig(newState);
    },
    [currentDashboardConfig]
  );

  const handleMoveSidebarItemUp = useCallback(
    ({ sidebarItemIndex }: { sidebarItemIndex: number }) => {
      if (!currentDashboardConfig) {
        return;
      }
      if (sidebarItemIndex === 0) return;

      const newState = {
        ...currentDashboardConfig,
        sidePanelConfig: {
          elements: [...currentDashboardConfig.sidePanelConfig.elements],
        },
      };

      const newIndex = sidebarItemIndex - 1;

      // Swap elements
      const temp = newState.sidePanelConfig.elements[sidebarItemIndex];
      newState.sidePanelConfig.elements[sidebarItemIndex] =
        newState.sidePanelConfig.elements[newIndex];
      newState.sidePanelConfig.elements[newIndex] = temp;
      setSidebarToShow(null);
      setCurrentDashboardConfig(newState);
    },
    [currentDashboardConfig]
  );

  const handleMoveSidebarItemDown = useCallback(
    ({ sidebarItemIndex }: { sidebarItemIndex: number }) => {
      if (!currentDashboardConfig) {
        return;
      }
      if (sidebarItemIndex === currentDashboardConfig.sidePanelConfig.elements.length - 1) return;

      const newState = {
        ...currentDashboardConfig,
        sidePanelConfig: {
          elements: [...currentDashboardConfig.sidePanelConfig.elements],
        },
      };

      const newIndex = sidebarItemIndex + 1;

      // Swap elements
      const temp = newState.sidePanelConfig.elements[sidebarItemIndex];
      newState.sidePanelConfig.elements[sidebarItemIndex] =
        newState.sidePanelConfig.elements[newIndex];
      newState.sidePanelConfig.elements[newIndex] = temp;
      setSidebarToShow(null);
      setCurrentDashboardConfig(newState);
    },
    [currentDashboardConfig]
  );

  const handleMoveSidebarItemToMainContent = useCallback(
    ({ sidebarItemIndex }: { sidebarItemIndex: number }) => {
      if (!currentDashboardConfig) {
        return;
      }
      const newState = { ...currentDashboardConfig };
      const newElement = newState.sidePanelConfig.elements[sidebarItemIndex];
      handleDeleteSidebarItem({ sidebarItemIndex });
      newState.rows[0].columns[0].elements.unshift(newElement);
      setCurrentDashboardConfig(newState);
    },
    [currentDashboardConfig, handleDeleteSidebarItem]
  );
  const handleAddSidebarItem = useCallback(
    (elementType: DashboardElementType) => {
      if (!currentDashboardConfig) {
        return;
      }
      const newState = { ...currentDashboardConfig };
      const newElement: DashboardElementConfig = {
        elementType,
        parameters: {
          icon: "",
          isUsedInSidebar: true,
        },
      };
      newState.sidePanelConfig.elements.push(newElement);
      // Remove placeholders from the sidebar before saving
      newState.sidePanelConfig.elements = newState.sidePanelConfig.elements.filter(
        (el) => el.elementType !== "placeholder"
      );
      setSidebarToShow(null);
      setCurrentDashboardConfig(newState);
    },
    [currentDashboardConfig]
  );

  const handleOpenListOfWidgets = useCallback(
    ({
      rowIndex,
      colIndex,
      addToSidebar,
    }: {
      rowIndex: number | null;
      colIndex: number | null;
      addToSidebar: boolean;
    }) => {
      setEditionMode(true);
      setOpenListOfWidgets({
        isListOfWidgetsOpen: true,
        rowIndex: rowIndex,
        colIndex: colIndex,
        addToSidebar: addToSidebar,
      });
    },
    []
  );

  const handleCancelEditionMode = useCallback(() => {
    refetchMyProfile();
    setTimeout(() => {
      setEditionMode(false);
    }, 1000);
  }, [refetchMyProfile]);

  const mainContentEllipsisOptions = useMemo(() => {
    return [
      // "Rename",
      "dashboard.moveToSidebar",
      "dashboard.moveToOtherColumn",
      "delete_",
    ];
  }, []);

  const mainContentFunctionsBundle: ((parametersObject?: any) => void)[] = useMemo(() => {
    return [
      // () => {
      //   console.log();
      // },
      handleMoveItemToSidebar,
      handleMoveItemToOppositeColumn,
      handleDeleteColumnItem,
    ];
  }, [handleDeleteColumnItem, handleMoveItemToOppositeColumn, handleMoveItemToSidebar]);

  const sidebarContentEllipsisOptions = useMemo(() => {
    return [
      // "Rename",
      "dashboard.addGadget",
      "dashboard.moveToMainContent",
      "moveUp",
      "moveDown",
      "delete_",
    ];
  }, []);

  const sidebarContentFunctionsBundle: ((parametersObject?: any) => void)[] = useMemo(() => {
    return [
      // () => {
      //   console.log();
      // },
      handleOpenListOfWidgets,
      handleMoveSidebarItemToMainContent,
      handleMoveSidebarItemUp,
      handleMoveSidebarItemDown,
      handleDeleteSidebarItem,
    ];
  }, [
    handleDeleteSidebarItem,
    handleMoveSidebarItemDown,
    handleMoveSidebarItemToMainContent,
    handleMoveSidebarItemUp,
    handleOpenListOfWidgets,
  ]);

  const sidebarElementsAsMainContent: DashboardConfigRow[] = useMemo(() => {
    return [
      {
        columnCount: 1,
        columns: [
          {
            elements: sideBarsArray,
          },
        ],
      },
    ];
  }, [sideBarsArray]);

  const rowsToDisplay = useMemo(() => {
    if (!allowEditionMode && dashboardViewMode === "sidebar") {
      return sidebarElementsAsMainContent;
    }
    return dashboardRowsArray;
  }, [allowEditionMode, dashboardViewMode, dashboardRowsArray, sidebarElementsAsMainContent]);

  const dashboardPaddingRight: UnsignedUpTo12 = useMemo(() => {
    if (breakpoint === "small" || breakpoint === "medium") {
      return 4;
    }
    if (isThereAnySidebar) {
      return 0;
    }
    return 10;
  }, [breakpoint, isThereAnySidebar]);

  const isMobile = breakpoint === "small" || breakpoint === "medium";

  return {
    sidebarToShow,
    setSidebarToShow,
    getMyProfileIsLoading,
    getMyProfileIsError,
    dashboardRowsArray,
    isThereAnySidebar,
    convertDashboardElementForImplementation,
    convertDashboardColumnsForImplementation,
    sideBarsArray,
    setOfSidebarsPerConfig,
    setCurrentDashboardConfig,
    saveDashboardConfig,
    currentDashboardConfig,
    defaultDashboardConfig,
    handleColumnAmount,
    handleDeleteRow,
    handleAddRowItem,
    handleMoveColumnItemVertically,
    listOfWidgets,
    openListOfWidgets,
    setOpenListOfWidgets,
    handleAddColumnItem,
    handleAddSidebarItem,
    resources,
    colors,
    mainContentEllipsisOptions,
    mainContentFunctionsBundle,
    sidebarContentEllipsisOptions,
    sidebarContentFunctionsBundle,
    handleCancelEditionMode,
    editionMode,
    setEditionMode,
    colorIconOnHover,
    setColorIconOnHover,
    newRowId,
    setNewRowId,
    newColumnId,
    setNewColumnId,
    allowEditionMode,
    dashboardViewMode,
    setDashboardViewMode,
    rowsToDisplay,
    handleOpenListOfWidgets,
    dashboardPaddingRight,
    isMobile,
    handleElementParametersChange,
  };
};
