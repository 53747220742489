import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const FolderSnapshotSvgIcon: FunctionComponent<Props> = ({
  color,
  width = 34,
  height = 32,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };

  const fillColor = color ? calculateColor(color) : "#5a5a5a";

  return (
    <svg style={svgStyle} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 32">
      <path
        style={{ fill: fillColor, strokeWidth: "0px" }}
        d="m22.57,8.4c-1.27,0-2.54,0-3.8,0h-2.86c-.83-.07-1.45-.39-1.95-1.18-.22-.35-.72-.72-1.1-.73-2.2-.06-4.42-.04-6.62-.01-1.01,0-1.54.51-1.55,1.51-.01,5.62-.01,11.26,0,16.9,0,.58.17,1.41.86,1.52v.02h23.13c.98,0,1.78-.8,1.78-1.78v-14.47c0-.98-.8-1.78-1.78-1.78h-6.1Z"
      />
      <path
        style={{ fill: "#fff", strokeWidth: "0px" }}
        d="m25.13,11.4h-5.72v.69h5.72c.51,0,.92.41.92.92v3.07h.69v-3.07c0-.89-.72-1.6-1.6-1.6Zm.92,8.37v3.07c0,.51-.41.92-.92.92h-5.72v.69h5.72c.89,0,1.6-.72,1.6-1.6v-3.07h-.69ZM9.99,11.4c-.88,0-1.6.72-1.6,1.6v3.07h.69v-3.07c0-.51.42-.92.92-.92h5.73v-.69h-5.73Zm0,12.36c-.5,0-.92-.41-.92-.92v-3.07h-.69v3.07c0,.89.72,1.6,1.6,1.6h5.73v-.69h-5.73Z"
      />
      <rect
        style={{ fill: "#fff", strokeWidth: "0px" }}
        x="11.61"
        y="14.07"
        width="11.9"
        height="7.71"
        rx=".72"
        ry=".72"
      />
    </svg>
  );
};
