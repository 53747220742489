import { checkPasswordStrength, validatePhoneNumber } from "@prodoctivity/shared";
import type {
  HttpListUsersRequest,
  HttpListUsersResponse,
  OrganizationRoles,
  OrganizationUser,
  UpdateOrganizationUser,
} from "@prodoctivity/types";
import { useCallback, useMemo, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";
import { usePaginatedDataEndpoint } from "../../../components/hooks";

const pageLengthOptions: Array<HttpListUsersRequest["queryParameters"]["rowsPerPage"]> = [
  "10",
  "20",
  "50",
];

export function useUserManagementList() {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const breadcrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      { type: "text", name: `${resources.manageUsers}` },
    ];
  }, [resources.home, resources.manageUsers, resources.settings]);
  const { user: thisUser } = useServices();

  const {
    paginatedData,
    setFilter,
    isLoading,
    currentPage,
    totalRowCount,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    refetch,
  } = usePaginatedDataEndpoint<
    { users: HttpListUsersResponse["payload"]["users"] },
    HttpListUsersRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "10",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.listUsers(currentPage, rowsPerPage, filter);
    },
    "users_list"
  );

  return {
    breadCrumbEntries: breadcrumbEntries,
    isLoading: isLoading,
    users: paginatedData?.users,
    currentPage,
    setFilter,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    pageLength: rowsPerPage,
    thisUsername: thisUser?.username,
    pageLengthOptions,
    resources,
    nextPage,
    organizationNavigate,
    previousPage,
    refetch,
    setPageLength,
    totalRowCount,
  };
}

export function useUserManagementListRow(user: OrganizationUser, refetchUserList: () => void) {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();

  const [toastMessage, setToastMessage] = useState<{
    type: "success" | "error";
    message: string;
  }>();

  const {
    setUserActivationState,
    resendAccountActivationInvitation,
    user: currentUser,
  } = useServices();

  const toggleUserStatus = useCallback(
    async (state: boolean) => {
      await setUserActivationState(user.id, state);
      refetchUserList();
    },
    [setUserActivationState, refetchUserList, user.id]
  );
  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: toggleUserStatus,
  });

  const resendInvitation = useCallback(async () => {
    await resendAccountActivationInvitation(user.firstName, user.lastName, user.email);
  }, [resendAccountActivationInvitation, user.firstName, user.lastName, user.email]);

  const { mutate: resendAccountActivation } = useMutation(resendInvitation, {
    onSuccess() {
      setToastMessage({ type: "success", message: resources.invitationForwarded });
      refetchUserList();
    },
    onError() {
      setToastMessage({ type: "error", message: resources.errorOccurred });
    },
  });

  const changePasswordToUser = useCallback(
    () => organizationNavigate(`/settings/users/${encodeURIComponent(user.id)}/change-password`),
    [organizationNavigate, user.id]
  );

  const editProfileOfUser = useCallback(
    () => organizationNavigate(`/settings/users/${encodeURIComponent(user.id)}/profile`),
    [organizationNavigate, user.id]
  );

  return {
    isMutating,
    currentUser,
    resources,
    changePasswordToUser,
    mutate,
    editProfileOfUser,
    toastMessage,
    resendAccountActivation,
  };
}

type FormAddUserToOrganizationState = {
  name: string;
  lastName: string;
  email: string;
};

type FormAddUserToOrganizationHook = {
  state: FormAddUserToOrganizationState;
  handleChange: (field: keyof FormAddUserToOrganizationState, value: string) => void;
  resetForm: () => void;
};

export const useFormAddUserToOrganization = (): FormAddUserToOrganizationHook => {
  const [state, setState] = useState<FormAddUserToOrganizationState>({
    name: "",
    lastName: "",
    email: "",
  });
  const handleChange = useCallback(
    (field: keyof FormAddUserToOrganizationState, value: string) => {
      const maxLength = 30;
      if (value.length <= maxLength) {
        setState(state);
      }

      setState((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    },
    [state]
  );

  const resetForm = useCallback(() => {
    setState({
      name: "",
      lastName: "",
      email: "",
    });
  }, []);
  return {
    state,
    handleChange,
    resetForm,
  };
};

export function useChangePassword(username: string | undefined) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const organizationNavigate = useOrganizationNavigate();
  const { adminChangePassword } = useServices();
  const { resources } = useAppTranslation();

  const hasInvalidPassword = useMemo(() => {
    return !checkPasswordStrength(newPassword).success;
  }, [newPassword]);

  const handlePasswordChange = useCallback(async () => {
    if (username === undefined) {
      organizationNavigate("/settings/users/");
      return;
    }

    if (newPassword !== confirmedPassword) {
      setPasswordMatchError(true);
      return;
    }

    try {
      await adminChangePassword(username, newPassword);
      setPasswordMatchError(false);
      organizationNavigate("/settings/users");
    } catch (error) {
      setPasswordMatchError(true);
    }
  }, [adminChangePassword, confirmedPassword, newPassword, organizationNavigate, username]);

  return {
    newPassword,
    setNewPassword,
    confirmedPassword,
    setConfirmedPassword,
    hasInvalidPassword,
    passwordMatchError,
    handlePasswordChange,
    resources,
    organizationNavigate,
  };
}

export function useChangePasswordPage() {
  const { resources } = useAppTranslation();

  const breadcrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.users,
        url: organizationLinkTemplates.manageUsers(),
      },
      {
        type: "text",
        name: resources.changePassword,
      },
    ];
  }, [resources.changePassword, resources.users, resources.home, resources.settings]);

  return {
    breadcrumbEntries,
    resources,
  };
}

const UserProfileTabType$Schema = z.enum(["user", "roles"]).catch("user").default("user");

export function useEditOrganizationUser(username: string | undefined) {
  const { resources } = useAppTranslation();
  const { search } = useLocation();
  const organizationNavigate = useOrganizationNavigate();
  const { getOrganizationUser, updateOrganizationUser, getAllOrganizationRoles } = useServices();
  const [currentOrganizationUser, setCurrentOrganizationUser] = useState<UpdateOrganizationUser>();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [specificScheduleSwitch, setSpecificScheduleSwitch] = useState(false);

  const currentTab = useMemo(() => {
    const params = new URLSearchParams(search);
    const current = params.get("tab");
    return UserProfileTabType$Schema.parse(current);
  }, [search]);

  const getOrganizationUserDetails = useCallback(async () => {
    if (username) {
      return await getOrganizationUser(username);
    }
    return { user: undefined };
  }, [username, getOrganizationUser]);

  const getOrganizationRolesDetails = useCallback(async () => {
    if (username) {
      const rolesResponse = await getAllOrganizationRoles();
      return rolesResponse;
    }
  }, [username, getAllOrganizationRoles]);

  const { isLoading, isError } = useOrganizationQuery(
    `/${username}/profile`,
    getOrganizationUserDetails,
    {
      refetchOnMount: true,
      onSuccess: (orgUserResponse) => {
        if (orgUserResponse.user) {
          const updateOrganizationUserData: UpdateOrganizationUser = {
            username: orgUserResponse.user.email,
            firstName: orgUserResponse.user.firstName,
            lastName: orgUserResponse.user.lastName,
            isActive: orgUserResponse.user.isActive,
            isApiUser: orgUserResponse.user.isApiUser,
            identification: orgUserResponse.user.identification ?? undefined,
            identificationType: orgUserResponse.user.identificationType ?? undefined,
            accessConstraint: orgUserResponse.user.accessConstraint ?? {
              type: "unrestricted",
            },
            specificScheduleTime: orgUserResponse.user.specificScheduleTime,
            positionRole:
              orgUserResponse.user.positionRole === ""
                ? undefined
                : orgUserResponse.user.positionRole,
            onlySeeOwnedDocs: orgUserResponse.user.onlySeeOwnedDocs ?? false,
            phone: orgUserResponse.user.phone === "" ? undefined : orgUserResponse.user.phone,
            language: orgUserResponse.user.language,
            assignedRoleIdList: orgUserResponse.user.assignedRoleIdList,
            dashboardConfiguration: orgUserResponse.user.dashboardConfiguration || undefined,
            documentLists: orgUserResponse.user.documentLists || [],
            avatarImageDataURI: orgUserResponse.user.avatarImageDataURI || undefined,
            isEnrolledForMarketing: orgUserResponse.user.isEnrolledForMarketing,
            newPassword: undefined,
          };

          if (orgUserResponse.user.specificScheduleTime) {
            setSpecificScheduleSwitch(true);
          }
          setCurrentOrganizationUser(updateOrganizationUserData);
        }
      },
    }
  );

  const { data: organizationRoles } = useOrganizationQuery(
    `/${username}/profile/availableRoles`,
    getOrganizationRolesDetails,
    { refetchOnWindowFocus: false, refetchOnMount: "always", staleTime: 60 * 1000 }
  );

  const availableOrganizationRoles: OrganizationRoles = organizationRoles ?? {
    roles: [
      {
        id: "",
        name: "string;",
      },
    ],
  };

  const {
    mutate: saveCurrentOrganizationUser,
    reset,
    isLoading: isMutating,
  } = useMutation(updateOrganizationUser, {
    onSuccess() {
      setToastMessage(resources.userManagementAndRoles.saveMessages.successfullySaved);
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false);
      }, 4500);
      setTimeout(() => {
        organizationNavigate(`/settings/users`);
      }, 1500);
    },
    onError() {
      setToastMessage(resources.userManagementAndRoles.saveMessages.failedToSave);
      setShowErrorToast(true);
      setTimeout(() => {
        setShowErrorToast(false);
      }, 4500);
    },
    onSettled() {
      reset();
    },
  });

  const handleSaveClicked = useCallback(() => {
    if (currentOrganizationUser) {
      if (!currentOrganizationUser.firstName) {
        setToastMessage(resources.userManagementAndRoles.requiredFieldErrors.noFirstName);
        setShowErrorToast(true);
        setTimeout(() => {
          setShowErrorToast(false);
        }, 4500);
      } else if (!currentOrganizationUser.lastName) {
        setToastMessage(resources.userManagementAndRoles.requiredFieldErrors.noLastName);
        setShowErrorToast(true);
        setTimeout(() => {
          setShowErrorToast(false);
        }, 4500);
      } else if (!currentOrganizationUser.language) {
        setToastMessage(resources.userManagementAndRoles.requiredFieldErrors.noLanguage);
        setShowErrorToast(true);
        setTimeout(() => {
          setShowErrorToast(false);
        }, 4500);
      } else if (
        currentOrganizationUser.assignedRoleIdList.find((string) => {
          return string == null || string.trim() === "";
        })
      ) {
        setToastMessage(resources.userManagementAndRoles.requiredFieldErrors.assignedRoleInvalid);
        setShowErrorToast(true);
        setTimeout(() => {
          setShowErrorToast(false);
        }, 4500);
      } else {
        setToastMessage(resources.userManagementAndRoles.requiredFieldErrors.noPosition);
        saveCurrentOrganizationUser(currentOrganizationUser);
      }
    }
  }, [
    currentOrganizationUser,
    resources.userManagementAndRoles.requiredFieldErrors.noFirstName,
    resources.userManagementAndRoles.requiredFieldErrors.noLastName,
    resources.userManagementAndRoles.requiredFieldErrors.noPosition,
    resources.userManagementAndRoles.requiredFieldErrors.noLanguage,
    resources.userManagementAndRoles.requiredFieldErrors.assignedRoleInvalid,
    saveCurrentOrganizationUser,
  ]);

  const handlePropertyChange = useCallback(
    (user: UpdateOrganizationUser) => {
      setCurrentOrganizationUser(user);
    },
    [setCurrentOrganizationUser]
  );

  const { isSaveButtonDisabled, validationMessage } = useMemo(() => {
    if (!currentOrganizationUser?.firstName) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.userManagementAndRoles.requiredFieldErrors.noFirstName,
      };
    }

    if (!currentOrganizationUser?.lastName) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.userManagementAndRoles.requiredFieldErrors.noLastName,
      };
    }

    if (!currentOrganizationUser?.language) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.userManagementAndRoles.requiredFieldErrors.noLanguage,
      };
    }

    if (isMutating) {
      return {
        isSaveButtonDisabled: true,
        validationMessage: resources.loading,
      };
    }

    return { isSaveButtonDisabled: false, validationMessage: "" };
  }, [
    currentOrganizationUser?.firstName,
    currentOrganizationUser?.lastName,
    currentOrganizationUser?.language,
    isMutating,
    resources.userManagementAndRoles.requiredFieldErrors.noFirstName,
    resources.userManagementAndRoles.requiredFieldErrors.noLastName,
    resources.userManagementAndRoles.requiredFieldErrors.noLanguage,
    resources.loading,
  ]);

  return {
    handlePropertyChange,
    handleSaveClicked,
    organizationNavigate,
    specificScheduleSwitch,
    setSpecificScheduleSwitch,
    currentOrganizationUser,
    showSuccessToast,
    showErrorToast,
    toastMessage,
    resources,
    validationMessage,
    isSaveButtonDisabled,
    availableOrganizationRoles,
    isLoading,
    isError,
    currentTab,
  };
}

export function useOrganizationUserEditUserTab(
  user: UpdateOrganizationUser,
  onChange: (user: UpdateOrganizationUser) => void
) {
  const { resources } = useAppTranslation();
  const [phoneValidationErrorMsg, setPhoneValidationErrorMsg] = useState("");

  const { user: currentUser } = useServices();

  const phoneValidation = useCallback(
    (phone: string) => {
      if (!validatePhoneNumber(phone)) {
        setPhoneValidationErrorMsg(resources.invalidPhone);
      } else {
        setPhoneValidationErrorMsg("");
      }
    },
    [resources.invalidPhone]
  );

  const handleChangeDateFrom = useCallback(
    (date: { value: Date | null }) => {
      const accessConstraint =
        user.accessConstraint || ({} as UpdateOrganizationUser["accessConstraint"]);

      if (accessConstraint?.type === "dateRange") {
        accessConstraint.fromDate =
          date.value !== null ? date.value.getTime() : new Date().getTime();
      }

      onChange({
        ...user,
        accessConstraint,
      });
    },
    [onChange, user]
  );

  const handleChangeDateTo = useCallback(
    (date: { value: Date | null }) => {
      const accessConstraint = user.accessConstraint;

      if (accessConstraint?.type === "dateRange" || accessConstraint?.type === "limitDate") {
        accessConstraint.toDate = date.value !== null ? date.value.getTime() : new Date().getTime();
      }

      onChange({
        ...user,
        accessConstraint,
      });
    },
    [onChange, user]
  );

  const handleScheduleDateFrom = useCallback(
    (date: { value: Date | null }) => {
      const specificScheduleTime =
        user.specificScheduleTime || ({} as UpdateOrganizationUser["specificScheduleTime"]);

      if (specificScheduleTime) {
        specificScheduleTime.fromTime =
          date.value !== null ? date.value.getTime() : new Date().getTime();

        if (specificScheduleTime.toTime === undefined) {
          specificScheduleTime.toTime =
            date.value !== null ? date.value.getTime() : new Date().getTime();
        }
      }

      onChange({
        ...user,
        specificScheduleTime,
      });
    },
    [onChange, user]
  );

  const handleScheduleDateTo = useCallback(
    (date: { value: Date | null }) => {
      const specificScheduleTime =
        user.specificScheduleTime || ({} as UpdateOrganizationUser["specificScheduleTime"]);
      if (specificScheduleTime) {
        specificScheduleTime.toTime =
          date.value !== null ? date.value.getTime() : new Date().getTime();
        if (specificScheduleTime.fromTime === undefined) {
          specificScheduleTime.fromTime =
            date.value !== null ? date.value.getTime() : new Date().getTime();
        }
      }

      onChange({
        ...user,
        specificScheduleTime,
      });
    },
    [onChange, user]
  );

  const accessConstraintFromDate = (): Date | undefined => {
    if (user.accessConstraint && user.accessConstraint.type === "dateRange") {
      return new Date(user.accessConstraint.fromDate);
    } else {
      return undefined;
    }
  };

  const accessConstraintToDate = (): Date | undefined => {
    if (
      user.accessConstraint &&
      (user.accessConstraint.type === "dateRange" || user.accessConstraint.type === "limitDate")
    ) {
      return new Date(user.accessConstraint.toDate);
    } else {
      return undefined;
    }
  };

  const scheduleDateFrom = (): Date | undefined => {
    if (user.specificScheduleTime) {
      return new Date(user.specificScheduleTime.fromTime);
    } else {
      return undefined;
    }
  };

  const scheduleDateTo = (): Date | undefined => {
    if (user.specificScheduleTime) {
      return new Date(user.specificScheduleTime.toTime);
    } else {
      return undefined;
    }
  };

  return {
    resources,
    handleChangeDateFrom,
    handleChangeDateTo,
    handleScheduleDateFrom,
    handleScheduleDateTo,
    accessConstraintFromDate,
    accessConstraintToDate,
    scheduleDateFrom,
    scheduleDateTo,
    phoneValidation,
    phoneValidationErrorMsg,
    currentUser,
  };
}

export function useOrganizationUserEditRoleTab() {
  const { resources } = useAppTranslation();

  const updateRoleValue = (
    value: string,
    user: UpdateOrganizationUser,
    onChange: (user: UpdateOrganizationUser) => void
  ) => {
    if (!value || value === " ") {
      return;
    }
    const assignedRoles = user.assignedRoleIdList || [];
    assignedRoles.push(value);
    onChange({
      ...user,
      assignedRoleIdList: assignedRoles,
    });
  };

  const filteredRoles = (
    rolesAvailable: OrganizationRoles,
    user: UpdateOrganizationUser
  ): {
    label: string;
    value: string;
  }[] => {
    return rolesAvailable.roles
      .filter((role) => !user.assignedRoleIdList.includes(role.id))
      .map((type) => {
        return {
          label: type.name,
          value: type.id,
        };
      });
  };

  return {
    resources,
    filteredRoles,
    updateRoleValue,
  };
}
