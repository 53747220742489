import { areObjectsEqual, getFieldSampleData } from "@prodoctivity/shared";
import type {
  DataElementSampleValueEntry,
  StringTemplatePart,
  TemplateContextRecordHolder,
} from "@prodoctivity/shared/src/index-types";

import type { DocumentCollectionConfigFolder } from "@prodoctivity/types";
import type momentType from "moment";
import { TreeItem } from "../hooks";

export const nameConfigContextToString = (
  moment: typeof momentType,
  nameConfig: StringTemplatePart[],
  contextDefinition?: TemplateContextRecordHolder
) => {
  return nameConfig
    .map((n) => {
      if (n.type === "text") {
        return n.value;
      } else if (n.type === "variable" && contextDefinition) {
        const emptyRecord: Record<string, DataElementSampleValueEntry> = {};
        const field = contextDefinition.fields?.find((e) => e.name === n.name);

        if (field) {
          const result = getFieldSampleData(field, emptyRecord, moment);
          return result || field.humanName;
        }
      }
      return n.name;
    })
    .join(" ");
};

export const updateElementByPath = (
  moment: typeof momentType,
  structure: DocumentCollectionConfigFolder<string>,
  originalItem: TreeItem,
  updatedItem: TreeItem
) => {
  function findAndUpdate(
    currentNode: Array<DocumentCollectionConfigFolder<string>>,
    currentPath: Array<string>
  ) {
    if (currentPath.length === 0) {
      return;
    }
    const currentFolderName = currentPath[0];
    const restOfPath = currentPath.slice(1);

    const foundFolderIndex = currentNode.findIndex(
      (item) => nameConfigContextToString(moment, item.nameConfig) === currentFolderName
    );

    if (foundFolderIndex !== -1) {
      if (restOfPath.length === 0) {
        if (originalItem.type === "folder" && updatedItem.type === "folder") {
          const sourceIndex = currentNode[foundFolderIndex].folders.findIndex(
            (f) =>
              nameConfigContextToString(moment, f.nameConfig) ===
              nameConfigContextToString(moment, originalItem.item.nameConfig)
          );
          if (sourceIndex >= 0) {
            currentNode[foundFolderIndex].folders[sourceIndex] = updatedItem.item;
            currentNode[foundFolderIndex].folders[sourceIndex].defaultFolderSort = {
              type: updatedItem.item.defaultFolderSort?.type || "document-date",
              direction: updatedItem.item.defaultFolderSort?.direction || "desc",
            };
            return;
          }
        } else if (originalItem.type === "documentType" && updatedItem.type === "documentType") {
          const sourceIndex = currentNode[foundFolderIndex].documentTypes.findIndex(
            (d) => d.documentTypeId === originalItem.item.documentTypeId
          );
          if (sourceIndex >= 0) {
            currentNode[foundFolderIndex].documentTypes[sourceIndex] = updatedItem.item;
            return;
          }
        }
        return;
      } else {
        findAndUpdate(currentNode[foundFolderIndex].folders, restOfPath);
        return;
      }
    }
    return false;
  }

  findAndUpdate(
    [structure],
    [nameConfigContextToString(moment, structure.nameConfig), ...originalItem.path]
  );
};

export const moveElementByPath = (
  moment: typeof momentType,
  structure: DocumentCollectionConfigFolder<string>,
  sourceItem: TreeItem,
  destinationItem: { isBefore: boolean } & TreeItem
) => {
  function findAndMove(
    currentNode: Array<DocumentCollectionConfigFolder<string>>,
    currentPath: Array<string>
  ): boolean {
    if (currentPath.length === 0) {
      return false;
    }
    const currentFolderName = currentPath[0];
    const restOfPath = currentPath.slice(1);

    const foundFolderIndex = currentNode.findIndex(
      (item) => nameConfigContextToString(moment, item.nameConfig) === currentFolderName
    );

    if (foundFolderIndex !== -1) {
      if (restOfPath.length === 0) {
        if (sourceItem.type === "folder") {
          const sourceIndex = currentNode[foundFolderIndex].folders.findIndex(
            (f) =>
              nameConfigContextToString(moment, f.nameConfig) ===
              nameConfigContextToString(moment, sourceItem.item.nameConfig)
          );
          if (sourceIndex >= 0) {
            currentNode[foundFolderIndex].folders.splice(sourceIndex, 1);
            return true;
          }
        } else {
          const sourceIndex = currentNode[foundFolderIndex].documentTypes.findIndex(
            (d) => d.documentTypeId === sourceItem.item.documentTypeId
          );
          if (sourceIndex >= 0) {
            currentNode[foundFolderIndex].documentTypes.splice(sourceIndex, 1);
            return true;
          }
        }
        return false;
      } else {
        return findAndMove(currentNode[foundFolderIndex].folders, restOfPath);
      }
    }
    return false;
  }

  // Find and move the element from the source location
  const sourceRemoved = findAndMove(
    [structure],
    [nameConfigContextToString(moment, structure.nameConfig), ...sourceItem.path]
  );

  if (!sourceRemoved) {
    return;
  }

  // Recursive function to add the element at the destination location
  function addToDestination(
    currentNode: Array<DocumentCollectionConfigFolder<string>>,
    currentPath: Array<string>
  ) {
    if (currentPath.length === 0) {
      return;
    }

    const currentFolderName = currentPath[0];
    const restOfPath = currentPath.slice(1);

    // Browse the destination folder
    const foundFolderIndex = currentNode.findIndex(
      (item) => nameConfigContextToString(moment, item.nameConfig) === currentFolderName
    );

    if (foundFolderIndex !== -1) {
      if (restOfPath.length === 0) {
        if (sourceItem.type === "folder") {
          const destinationIndex =
            destinationItem.type === "folder"
              ? currentNode[foundFolderIndex].folders.findIndex(
                  (f) =>
                    nameConfigContextToString(moment, f.nameConfig) ===
                    nameConfigContextToString(moment, destinationItem.item.nameConfig)
                )
              : -1;
          const { type: _type, ...source } = sourceItem;

          if (destinationIndex >= 0) {
            currentNode[foundFolderIndex].folders.splice(destinationIndex, 0, source.item);
            return true;
          } else {
            currentNode[foundFolderIndex].folders.push(source.item);
          }
        } else {
          const destinationIndex =
            destinationItem.type === "documentType"
              ? currentNode[foundFolderIndex].documentTypes.findIndex(
                  (d) => d.documentTypeId === destinationItem.item.documentTypeId
                )
              : -1;
          const { type: _type, ...source } = sourceItem;

          if (destinationIndex >= 0) {
            currentNode[foundFolderIndex].documentTypes.splice(destinationIndex, 0, source.item);
            return true;
          } else {
            currentNode[foundFolderIndex].documentTypes.push(source.item);
          }
        }
      } else {
        addToDestination(currentNode[foundFolderIndex].folders, restOfPath);
      }
    }
  }

  // Move the item to the destination location
  addToDestination(
    [structure],
    [nameConfigContextToString(moment, structure.nameConfig), ...destinationItem.path]
  );
};

export const getDocumentTypesFromFolders = (mainFolder: DocumentCollectionConfigFolder<string>) => {
  const documentTypeIdsUsed: Array<string> = [];

  function processFolder(folder: DocumentCollectionConfigFolder<string>) {
    folder.documentTypes.forEach((dt) => {
      documentTypeIdsUsed.push(dt.documentTypeId);
    });

    folder.folders.forEach(processFolder);
  }

  processFolder(mainFolder);

  return documentTypeIdsUsed;
};

export const updateFoldersDocumentTypes = (
  documentTypes: Array<DocumentCollectionConfigFolder<string>["documentTypes"][0]>,
  folders: Array<DocumentCollectionConfigFolder<string>>,
  documentTypeIdList: Array<string>
) => {
  const documentTypeIdsUsed: Array<string> = [];

  documentTypes = documentTypes.filter((dt) => {
    const existInList = documentTypeIdList.includes(dt.documentTypeId);
    if (existInList) {
      documentTypeIdsUsed.push(dt.documentTypeId);
    }
    return existInList;
  });

  function processFolder(folder: DocumentCollectionConfigFolder<string>) {
    folder.documentTypes = folder.documentTypes.filter((dt) => {
      const existInList = documentTypeIdList.includes(dt.documentTypeId);
      if (existInList) {
        documentTypeIdsUsed.push(dt.documentTypeId);
      }
      return existInList;
    });

    folder.folders.forEach(processFolder);
  }

  folders.forEach(processFolder);

  //Filter the document types that aren't used to include them
  documentTypeIdList
    .filter((id) => !documentTypeIdsUsed.includes(id))
    .forEach((id) => {
      documentTypes.push({
        iconKey: "document",
        documentTypeId: id,
        nameConfig: undefined,
        pinned: false,
        color: undefined,
        required: false,
        ignoredFieldsInMatch: undefined,
        includeExpression: undefined,
        requiredCondition: undefined,
      });
    });

  return { documentTypes, folders };
};

const renameNewFolder = (
  moment: typeof momentType,
  item: DocumentCollectionConfigFolder<string>,
  currentFolders: DocumentCollectionConfigFolder<string>[]
) => {
  let newName = nameConfigContextToString(moment, item.nameConfig);
  let count = 1;

  const folderNameExist = (name: string): boolean => {
    return currentFolders.some(
      (element) => nameConfigContextToString(moment, element.nameConfig) === name
    );
  };

  while (folderNameExist(newName)) {
    count++;
    newName = `${nameConfigContextToString(moment, item.nameConfig)} ${count}`;
  }

  item.nameConfig = [{ type: "text", value: newName }];
};

export const addItemAtPath = (
  moment: typeof momentType,
  newItem: TreeItem,
  structure: Array<DocumentCollectionConfigFolder<string>>,
  path: Array<string>
) => {
  function findAndCreate(
    currentNode: Array<DocumentCollectionConfigFolder<string>>,
    currentPath: Array<string>
  ) {
    if (currentPath.length === 0) {
      if (newItem.type === "folder") {
        renameNewFolder(moment, newItem.item, currentNode);
        currentNode.push(newItem.item);
      }
      return;
    }
    const currentFolderName = currentPath[0];
    const restOfPath = currentPath.slice(1);

    const foundFolderIndex = currentNode.findIndex(
      (item) => nameConfigContextToString(moment, item.nameConfig) === currentFolderName
    );

    if (foundFolderIndex !== -1) {
      if (restOfPath.length === 0) {
        if (newItem.type === "documentType") {
          currentNode[foundFolderIndex].documentTypes.push(newItem.item);
        } else if (newItem.type === "folder") {
          renameNewFolder(moment, newItem.item, currentNode[foundFolderIndex].folders);
          currentNode[foundFolderIndex].folders.push(newItem.item);
        }
        return;
      } else {
        findAndCreate(currentNode[foundFolderIndex].folders, restOfPath);
        return;
      }
    }
    return false;
  }

  findAndCreate(structure, path);
};

export const folderContainsDocumentTypes = (
  folder: DocumentCollectionConfigFolder<string>
): boolean => {
  if (folder.documentTypes.length > 0) {
    return true;
  }
  for (const subfolder of folder.folders) {
    if (folderContainsDocumentTypes(subfolder)) {
      return true;
    }
  }
  return false;
};

export function existMultipleInstancesOfDocumentType(
  documentTypeId: string,
  rootFolder: DocumentCollectionConfigFolder<string>
): boolean {
  let count = 0;
  function searchInFolders(folders: Array<DocumentCollectionConfigFolder<string>>): boolean {
    for (const folder of folders) {
      const instancesInFolder = folder.documentTypes.filter(
        (docType) => docType.documentTypeId === documentTypeId
      ).length;

      count += instancesInFolder;

      if (searchInFolders(folder.folders)) {
        return true;
      }
    }

    return false;
  }

  searchInFolders([rootFolder]);
  return count > 1;
}

export const removeItemAtPath = (
  moment: typeof momentType,
  treeItem: TreeItem,
  folders: Array<DocumentCollectionConfigFolder<string>>
): Array<DocumentCollectionConfigFolder<string>> => {
  const findAndRemove = (
    currentNode: Array<DocumentCollectionConfigFolder<string>>,
    currentPath: Array<string>
  ): Array<DocumentCollectionConfigFolder<string>> => {
    if (currentPath.length === 0) {
      if (treeItem.type === "folder") {
        currentNode = currentNode.filter(
          (f) =>
            !areObjectsEqual(treeItem.item.nameConfig, f.nameConfig) ||
            !(
              areObjectsEqual(treeItem.item.nameConfig, f.nameConfig) &&
              folderContainsDocumentTypes(f) === false
            )
        );
      }
      return currentNode;
    }
    const currentFolderName = currentPath[0];
    const restOfPath = currentPath.slice(1);

    const foundFolderIndex = currentNode.findIndex(
      (item) => nameConfigContextToString(moment, item.nameConfig) === currentFolderName
    );

    if (foundFolderIndex !== -1) {
      if (restOfPath.length === 0) {
        if (treeItem.type === "folder") {
          currentNode[foundFolderIndex].folders = currentNode[foundFolderIndex].folders.filter(
            (f) =>
              !areObjectsEqual(treeItem.item.nameConfig, f.nameConfig) ||
              !(
                areObjectsEqual(treeItem.item.nameConfig, f.nameConfig) &&
                folderContainsDocumentTypes(f) === false
              )
          );
        } else if (treeItem.type === "documentType") {
          currentNode[foundFolderIndex].documentTypes = currentNode[
            foundFolderIndex
          ].documentTypes.filter((dt) => dt.documentTypeId !== treeItem.item.documentTypeId);
        }
      } else {
        currentNode[foundFolderIndex].folders = findAndRemove(
          currentNode[foundFolderIndex].folders,
          restOfPath
        );
      }
    }
    return currentNode;
  };

  return findAndRemove(folders, treeItem.path);
};
