import { Box, Checkbox, Table, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useState } from "react";

import { StepTabs } from "../../../../components/StepTabs";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";

type Props = {
  roles: Array<{ id: string; name: string }>;
  permissionsList: Array<{ id: string; name: string }>;
  permissions: Array<{ roleId: string; permissions: Array<string> }>;
  onPermissionsChange: (roleId: string, permissionName: string, active: boolean) => void;
};

type State = {
  selectedTabIndex: number;
};

export const PermissionsStep: FunctionComponent<Props> = ({
  permissions,
  roles,
  permissionsList,
  onPermissionsChange,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const [state, setState] = useState<State>({
    selectedTabIndex: 0,
  });

  const buildTableRows = useCallback(() => {
    return roles.map((role, i) => {
      const permissionsCells = permissionsList.map((pL) => {
        const isChecked =
          permissions.findIndex((p) => p.roleId === role.id && p.permissions.includes(pL.name)) >=
          0;

        return (
          <Table.Cell key={`permissionCheckbox_${role.id}_${pL.name}`}>
            <Checkbox
              id={`permissionCheckbox_${role.id}_${pL.name}`}
              checked={isChecked}
              onChange={() => onPermissionsChange(role.id, pL.name, !isChecked)}
            />
          </Table.Cell>
        );
      });

      return (
        <Table.Row key={i}>
          <Table.Cell>
            <Text>{role.name}</Text>
          </Table.Cell>
          {permissionsCells}
        </Table.Row>
      );
    });
  }, [onPermissionsChange, permissions, permissionsList, roles]);

  return (
    <Box color={colors.white} display="flex" direction="column" flex="grow" width="100%">
      <StepTabs
        selectedTabIndex={state.selectedTabIndex}
        onTabClick={(index: number) => setState({ ...state, selectedTabIndex: index })}
        tabs={[
          {
            title: resources.documents,
            content: (
              <Box
                padding={3}
                color={colors.white}
                display="flex"
                direction="column"
                flex="grow"
                width="100%"
              >
                <Table accessibilityLabel="Basic Table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Text weight="bold" size="300">
                          {resources.role.toUpperCase()}
                        </Text>
                      </Table.HeaderCell>
                      {permissionsList.map((p) => {
                        const rs: Record<string, unknown> = resources;

                        const resourceName =
                          rs[p.name === "delete" ? "delete_" : p.name.toLowerCase()];

                        return (
                          <Table.HeaderCell key={p.id}>
                            <Text weight="bold" size="300">
                              {`${resourceName}`.toUpperCase()}
                            </Text>
                          </Table.HeaderCell>
                        );
                      })}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{buildTableRows()}</Table.Body>
                </Table>
              </Box>
            ),
          },
        ]}
      />
    </Box>
  );
};
