import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const MenuGridIcons: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      className={customClassName}
      style={svgStyle}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8115_106288)">
        <path
          d="M14.8094 10H10V14.7084H14.8094V10Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
        <path
          d="M22.3563 10H17.5469V14.7084H22.3563V10Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
        <path
          d="M29.8925 10H25.083V14.7084H29.8925V10Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
        <path
          d="M14.8632 17.6514H10.0537V22.3597H14.8632V17.6514Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
        <path
          d="M22.41 17.6514H17.6006V22.3597H22.41V17.6514Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
        <path
          d="M29.9462 17.6514H25.1367V22.3597H29.9462V17.6514Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
        <path
          d="M14.9169 25.292H10.1074V30.0003H14.9169V25.292Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
        <path
          d="M22.453 25.292H17.6436V30.0003H22.453V25.292Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
        <path
          d="M29.9999 25.292H25.1904V30.0003H29.9999V25.292Z"
          fill={color ? calculateColor(color) : "#91979E"}
        />
      </g>
      <defs>
        <clipPath id="clip0_8115_106288">
          <rect width={width} height={height} fill="white" transform="translate(0 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};
