import { FunctionComponent, useCallback, useState } from "react";

import { Box } from "../Box";
import { useColors } from "../ColorSchemeProvider";
import { IconButton } from "../Icon";
import { StandardIconContainer } from "../StandardIconContainer";
import { Text } from "../Text";

export type Props = {
  title: string;
  itemsTotalCount: number | undefined;
  children: React.ReactNode;
  resources: {
    open: string;
  };
  isOpenAccordion: boolean;
  onAnyAccordionOpen: (value: boolean) => void;
};

export const InboxAccordion: FunctionComponent<Props> = ({
  title,
  itemsTotalCount,
  children,
  resources,
  isOpenAccordion,
  onAnyAccordionOpen,
}) => {
  const { colors } = useColors();
  //TODO: Delete this state
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
    onAnyAccordionOpen(!isOpenAccordion);
  }, [isOpenAccordion, onAnyAccordionOpen]);

  return (
    <Box
      key={`inbox-accordion-${title}-${itemsTotalCount || 0}`}
      display="flex"
      flex="grow"
      direction="column"
      width={"100%"}
      color={colors.white}
      borderRadius={12}
      borderColor={colors.primary}
      alignItems="start"
      paddingTop={2}
      minHeight={60}
    >
      <Box
        display="flex"
        flex="grow"
        alignItems="center"
        justifyContent="between"
        maxHeight={40}
        paddingY={2}
        width={"100%"}
        paddingX={4}
        onClickCapture={toggleOpen}
      >
        <Box display="flex" gap={6} alignItems="center">
          <StandardIconContainer>
            <IconButton
              accessibilityLabel={resources.open}
              icon={isOpen ? "chevron-up" : "chevron-down"}
              size={"sm"}
              color={colors.primary}
            />
          </StandardIconContainer>
          <Text color={colors.primary} weight="bold">
            {title}
          </Text>
        </Box>
        <StandardIconContainer>
          <Box
            display="flex"
            rounding={"circle"}
            alignItems="center"
            justifyContent="center"
            color={
              itemsTotalCount === undefined || itemsTotalCount === 0
                ? colors.neutral400
                : colors.primary100
            }
            borderColor={
              itemsTotalCount === undefined || itemsTotalCount === 0
                ? colors.neutral700
                : colors.primary
            }
            borderRadius={48}
            minHeight={36}
            minWidth={36}
          >
            <Text
              size="200"
              weight="bold"
              color={
                itemsTotalCount === undefined || itemsTotalCount === 0
                  ? colors.neutral900
                  : colors.primary
              }
            >
              {itemsTotalCount &&
                (itemsTotalCount > 99 ? "+" + 99 : itemsTotalCount <= 0 ? 0 : itemsTotalCount)}
            </Text>
          </Box>
        </StandardIconContainer>
      </Box>
      {isOpen && children}
    </Box>
  );
};
