// @flow

export function getLayoutTopicsLowercase(layoutTopics: Array<string>): Set<string> {
  const lowercases = layoutTopics.map((t) => (t || "").toLowerCase());
  return new Set(lowercases);
}

export function updateLayoutTopicsLowercase(
  prevLayoutTopics: Array<string>,
  nextlayoutTopics: Array<string>
): {
  layoutTopicsLowercase: Set<string>;
  layoutTopics: Array<string>;
} | null {
  if (prevLayoutTopics !== nextlayoutTopics) {
    const layoutTopicsLowercase: Set<string> = getLayoutTopicsLowercase(nextlayoutTopics);
    return { layoutTopicsLowercase, layoutTopics: nextlayoutTopics };
  }

  return null;
}
