import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ClearAllSvgIcon: FunctionComponent<Props> = ({ color }) => {
  const { colors } = useColors();
  return (
    <svg width="20" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.65 0.4L19.25 2.85C17.3 1.1 14.75 0 12 0C7.25 0 3.1 3.05 1.65 7.55C1.4 8.4 1.85 9.3 2.7 9.55C3.55 9.8 4.45 9.35 4.7 8.5C5.7 5.35 8.65 3.2 12 3.2C13.85 3.2 15.65 3.95 17 5.1L14.75 7.3C13.95 8.15 14.5 9.6 15.7 9.6H23.05C23.55 9.6 24 9.2 24 8.65V1.4C24 0.15 22.5 -0.45 21.65 0.4ZM21.25 12.9C20.4 12.65 19.5 13.1 19.25 13.95C18.25 17.1 15.3 19.2 12 19.2C10.1 19.2 8.35 18.5 6.95 17.35L9.2 15.1C10 14.3 9.45 12.85 8.25 12.8H0.9C0.4 12.85 0 13.25 0 13.8V21.05C0 22.3 1.45 22.9 2.3 22.05L4.7 19.6C6.65 21.35 9.2 22.4 11.95 22.4C16.65 22.4 20.8 19.4 22.25 14.9C22.55 14.05 22.1 13.15 21.25 12.9Z"
        fill={calculateColor(color || colors.neutral900)}
      />
    </svg>
  );
};
