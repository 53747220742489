import { Box, Table } from "@prodoctivity/design-system";
import { ResultsTableHeader } from "./ResultsTableHeader";
import { FunctionComponent, ReactNode } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";

export type Props = {
  cardsLayout: boolean;
  children: ReactNode;
  breakpoint: "small" | "medium" | "large" | "hd";
  noResultsLayout: boolean;
};

export const ConditionalTableWrapper: FunctionComponent<Props> = ({
  cardsLayout,
  children,
  breakpoint,
  noResultsLayout,
}: Props) => {
  const { resources } = useAppTranslation();
  if (!cardsLayout && !noResultsLayout) {
    return (
      <Table accessibilityLabel={resources.searchResult}>
        <ResultsTableHeader breakpoint={breakpoint} />
        <Table.Body>{children}</Table.Body>
      </Table>
    );
  } else if (noResultsLayout) {
    return <Box>{children}</Box>;
  }
  return <>{children}</>;
};
