import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ReportIconSvg: FunctionComponent<Props> = ({
  color,
  width = 25,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width={25}
      height={22}
      viewBox={`0 0 25 22`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.62 21.5626V13.5763C22.62 13.3232 22.4242 13.1263 22.1725 13.1263H18.6387C18.3869 13.1263 18.1911 13.3232 18.1911 13.5763V21.5626H15.5058V8.99254C15.5058 8.73946 15.31 8.54261 15.0583 8.54261H11.5245C11.2727 8.54261 11.0769 8.73946 11.0769 8.99254V21.5626H7.93473V4.82126C7.93473 4.57755 7.73893 4.37133 7.48718 4.37133H3.95338C3.70163 4.37133 3.50583 4.56817 3.50583 4.82126V21.5626H0.932401V0.5H0V22.5H24V21.5626H22.62Z"
        fill={color ? calculateColor(color) : "#00185E"}
      />
    </svg>
  );
};
