import { CSSProperties, useCallback, useMemo, useState } from "react";

import { useDesignBreakpoint } from "@prodoctivity/design-system";
import { TextSize } from "@prodoctivity/design-system/components/Text";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";

/**************************************************************************************************************************************************  ************************************************************* HOOK ********************************************************************************/

export function useLandingPageLogic() {
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const breakPointSmall = breakpoint === "small";
  const breakPointSmallDuo = breakpoint === "small" || breakpoint === "medium";
  const breakPointMedium = breakpoint === "medium";
  const breakPointLarge = breakpoint === "large";
  const titleSize: TextSize | undefined = breakPointMedium
    ? "500"
    : breakPointSmall
    ? "400"
    : "600";
  const flexDirection: CSSProperties["flexDirection"] | undefined = breakPointSmall
    ? "column"
    : "row";

  const boxProps = useMemo(() => {
    switch (breakPointSmallDuo) {
      case true:
        return {
          wrap: true,
          justifyContent: "center",
        } as const;
      default: {
        return {
          wrap: false,
        } as const;
      }
    }
  }, [breakPointSmallDuo]);

  const navigate = useNavigate();
  const signUp = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);
  const signIn = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  return {
    resources,
    breakpoint,
    breakPointSmall,
    breakPointSmallDuo,
    breakPointLarge,
    titleSize,
    flexDirection,
    boxProps,
    signUp,
    signIn,
  };
}

export function useSendContactHelpEmail() {
  const { resources } = useAppTranslation();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [sendingEmail, setSendingEmail] = useState(false);
  const [showToast, setShowToast] = useState<{ show: boolean; isError: boolean }>({
    show: false,
    isError: false,
  });
  const { contactHelp } = useServices();
  const [toastMessage, setToastMessage] = useState("");

  const sendEmail = useCallback(async () => {
    try {
      const recipient = {
        name: name + " " + lastName,
        email: email,
      };
      setSendingEmail(true);
      await contactHelp(recipient, phoneNumber, message);
      setSendingEmail(false);
      return { success: true, error: null };
    } catch (e) {
      console.error(e);
      setSendingEmail(false);
      return { success: false, error: e };
    }
  }, [name, lastName, email, message, phoneNumber, contactHelp]);

  const mutation = useMutation(sendEmail, {
    onSuccess(result) {
      if (result.success) {
        setToastMessage(resources.success);
        setShowToast({ show: true, isError: false });
        setTimeout(() => {
          setShowToast({ show: false, isError: false });
        }, 4500);
      } else {
        setToastMessage(resources.validations.couldntSendEmail);
        setShowToast({ show: true, isError: true });
        setTimeout(() => {
          setShowToast({ show: false, isError: true });
        }, 4500);
      }
    },
    onError() {
      setToastMessage(resources.validations.couldntSendEmail);
      setShowToast({ show: true, isError: true });
      setTimeout(() => {
        setShowToast({ show: false, isError: true });
      }, 4500);
    },
  });

  const handleSubmit = useCallback(async () => {
    mutation.mutate();
  }, [mutation]);

  const isButtonEnabled = useMemo(() => {
    return (
      name.length > 0 &&
      lastName.length > 0 &&
      phoneNumber.length > 0 &&
      email.length > 0 &&
      message.length > 0
    );
  }, [name, lastName, phoneNumber, email, message]);

  return {
    name,
    setName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    message,
    setMessage,
    sendingEmail,
    handleSubmit,
    showToast,
    setShowToast,
    toastMessage,
    isButtonEnabled,
  };
}
