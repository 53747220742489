import {
  Box,
  Checkbox,
  ComboBox,
  DatePicker,
  DeleteDocumentModal,
  Icon,
  Spinner,
  Table,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { BreadCrumb } from "../../components/BreadCrumb";
import { OrganizationUserLookup } from "../../components/Display/OrganizationUserLookup";
import { Pagination } from "../../components/Layout/Pagination";
import { SmallRefreshButton } from "../../components/SmallRefreshButton";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { BrowseDeletedDocumentsFilter, organizationLinkTemplates } from "../../link-templates";
import { ArrowSvg } from "../../svg/ArrowSvg";
import { HardDeleteDocumentSvgIcon } from "../../svg/HardDeleteDocumentSvgIcon";
import { RestoreDeletedDocumentSvgIcon } from "../../svg/RestoreDeletedDocumentSvgIcon";
import { XIconSvg } from "../../svg/XIconSvg";
import { RowSkeleton } from "../Settings/DataDictionary/DataLists";
import { DeletedDocumentCardList } from "./DeletedDocumentCardList";
import { useBrowseDeletedDocumentsList } from "./hooks";

type Props = {
  filter: BrowseDeletedDocumentsFilter;
};

export const DocumentRecoveryList: FunctionComponent<Props> = ({ filter }: Props) => {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const { resources } = useAppTranslation();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const breakpointHd = breakpoint === "hd";
  const breakpointDuo = breakpoint === "small" || breakpoint === "medium";

  const {
    documentTypes,
    documentTypeIsLoading,
    documentsData,
    onSelectDocumentType,
    selectedDocumentTypes,
    currentPage,
    previousPage,
    nextPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    rowsPerPage,
    totalRowCount,
    pageLengthOptions,
    documentTypeHandleClick,
    documentTypeComboBoxInputValue,
    setDocumentTypeComboBoxInputValue,
    clearAll,
    handleFilterChange,
    setRowsPerPage,
    changeSort,
    refetch,
    hardDeleteDocument,
    restoreDeletedDocument,
    handleToggleSelect,
    isToggleSelect,
    handleChecboxChange,
    hardDeletedList,
    setHardDeletedList,
    handleDeleteDocument,
    handleRestoreDocument,
  } = useBrowseDeletedDocumentsList(filter);

  const hardDeleteConfirmation = useCallback(async () => {
    const cannotDelete = !hardDeletedList.length;

    if (cannotDelete) return setShowDeleteModal(false);

    await hardDeleteDocument(hardDeletedList);

    setHardDeletedList([]);
    setShowDeleteModal(false);
    setIsSelectAll(false);
    refetch();
  }, [hardDeleteDocument, hardDeletedList, refetch, setHardDeletedList]);

  const restoreDocumentsList = useCallback(async () => {
    const canRestore = !hardDeletedList.length;

    if (canRestore) return;

    await restoreDeletedDocument(hardDeletedList);

    setHardDeletedList([]);
    setIsSelectAll(false);
    refetch();
  }, [hardDeletedList, refetch, restoreDeletedDocument, setHardDeletedList]);

  const deleteAll = useCallback(() => {
    const result = documentsData.reduce((acc: any[], doc) => {
      const existingDoc = acc.find((item) => item.documentId === doc.documentId);
      if (existingDoc) {
        existingDoc.documentVersionList.push(
          ...doc.documentVersions.map((version) => version.documentVersionId)
        );
      } else {
        acc.push({
          documentId: doc.documentId,
          documentVersionList: doc.documentVersions.map((version) => version.documentVersionId),
        });
      }
      return acc;
    }, []);

    setHardDeletedList(result);
  }, [documentsData, setHardDeletedList]);

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setIsSelectAll(checked);
        deleteAll();
      } else {
        setIsSelectAll(checked);
        setHardDeletedList([]);
      }
    },
    [deleteAll, setHardDeletedList]
  );

  const documentTypeList = useMemo(() => {
    const filteredDocumentTypes = documentTypes.filter(
      (type) =>
        !selectedDocumentTypes.some((item) => item.label === type.name) &&
        type.name.toLowerCase().includes(documentTypeComboBoxInputValue.toLowerCase())
    );
    return filteredDocumentTypes.map((type) => ({
      label: type.name,
      value: type.documentTypeId,
    }));
  }, [documentTypeComboBoxInputValue, documentTypes, selectedDocumentTypes]);

  const handleComboBoxClear = useCallback(() => {
    setDocumentTypeComboBoxInputValue("");
  }, [setDocumentTypeComboBoxInputValue]);

  if (documentTypeIsLoading) {
    return (
      <>
        <Box width={"100%"} display="flex" flex="grow" direction="column">
          <Spinner show={true} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box color={colors.white}>
        <BreadCrumb
          entries={[
            { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
            {
              type: "url",
              name: resources.documentRecovery,
              url: organizationLinkTemplates.browseDeletedDocuments(filter),
            },
          ]}
        />
      </Box>
      <Box>
        <Box marginStart={breakpointDuo ? 2 : 12} marginEnd={breakpointDuo ? 2 : 12} paddingY={5}>
          <Box display="flex" alignItems="center" justifyContent="between" width={"99.5%"}>
            <Box display="flex" gap={5} direction="row">
              <Text size="400" weight="bold" color={colors.black600}>
                {resources.documentRecovery}
              </Text>
              <Box paddingTop={1}>
                <SmallRefreshButton
                  accessibilityLabel={resources.refresh}
                  refetch={refetch}
                  marginTop={0}
                />
              </Box>
            </Box>
          </Box>
          <Box width={"100%"}>
            <Box paddingY={5} display="flex" direction={breakpointHd ? "row" : "column"}>
              <Box display="flex" width={breakpointHd ? "70%" : "100%"}>
                <Box width={breakpointHd ? "100%" : "70%"} display="flex" flex="grow">
                  <Box width={"100%"}>
                    <ComboBox
                      id="documentType"
                      onClear={handleComboBoxClear}
                      label=""
                      inputValue={documentTypeComboBoxInputValue}
                      onChange={(event) => setDocumentTypeComboBoxInputValue(event.value as string)}
                      accessibilityClearButtonLabel={resources.clear}
                      noResultText={resources.dataDictionary.noItems}
                      onSelect={onSelectDocumentType}
                      size="lg"
                      options={documentTypeList}
                      placeholder={resources.documentTypes.documentTypeName}
                    />
                  </Box>
                </Box>

                <Box width={breakpointHd ? "50%" : "30%"} paddingX={1}>
                  <OrganizationUserLookup
                    id="browse_documents_author_lookup"
                    label={""}
                    accessibilityClearButtonLabel={resources.clear}
                    noResultText={resources.noUsers}
                    placeholder={resources.browseDocument.author}
                    size="lg"
                    onSelect={(element) => {
                      return handleFilterChange("updatedBy", {
                        value: element.item.value,
                      });
                    }}
                    onClear={() => {
                      handleFilterChange("updatedBy", {
                        value: undefined,
                      });
                    }}
                    selectedUsername={filter.updatedBy}
                  />
                </Box>
              </Box>
              <Box padding={breakpointHd ? undefined : 2} />
              <Box display="flex" width={breakpointHd ? "30%" : "100%"} gap={1}>
                <Box width={"50%"} paddingX={breakpointHd ? 1 : undefined}>
                  <DatePicker
                    id="startDate"
                    resources={resources}
                    placeholder={resources.from}
                    value={filter.dateStart ? new Date(filter.dateStart) : undefined}
                    onChange={(value) => {
                      const dateValue = value.value;
                      const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;
                      handleFilterChange("dateStart", { value: timestamp });
                    }}
                    dataType="Date"
                  />
                </Box>
                <Box width={"50%"} paddingX={breakpointHd ? 1 : undefined}>
                  <DatePicker
                    resources={resources}
                    id="dateEnd"
                    value={filter.dateEnd ? new Date(filter.dateEnd) : undefined}
                    placeholder={resources.to}
                    onChange={(value) => {
                      const dateValue = value.value;
                      const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;

                      handleFilterChange("dateEnd", { value: timestamp });
                    }}
                    dataType="Date"
                  />
                </Box>
              </Box>
            </Box>
            <Box borderStyle="sm" width={"100%"}>
              <Pagination<typeof rowsPerPage>
                id="Browse"
                rowsLabel={`${resources.documents}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={documentsData?.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
                totalRowCount={totalRowCount}
              />
            </Box>
            <Box display={"flex"} padding={3} justifyContent={"between"} alignItems={"center"}>
              <Box
                display="flex"
                flex="grow"
                direction="row"
                alignItems="center"
                justifyContent="between"
              >
                {/* toggle, select all and filter */}
                <Box>
                  <Box display="flex">
                    {isToggleSelect && (
                      <Box display="flex" marginStart={breakpointDuo ? 2 : 4} marginEnd={4}>
                        <Checkbox
                          id={"id"}
                          name={"id"}
                          checked={isSelectAll}
                          onChange={(e) => {
                            handleSelectAll(e.checked);
                          }}
                        />
                        <Box marginStart={2} marginTop={1}>
                          <Text>{"Select All"}</Text>
                        </Box>
                      </Box>
                    )}
                    <Box marginStart={3} marginTop={1}>
                      <Text>{resources.filters}:</Text>
                    </Box>
                  </Box>
                  <Box display="flex" wrap={true}>
                    {selectedDocumentTypes.map((dt, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        height={"auto"}
                        width={"fit-content"}
                        key={index}
                        paddingX={breakpointDuo ? 2 : 4}
                        paddingY={breakpointDuo ? 1 : 2}
                        margin={1}
                        color={colors.white}
                        borderRadius={4}
                        onClickCapture={() => documentTypeHandleClick(index)}
                        dangerouslySetInlineStyle={{
                          __style: {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Text color={colors.neutral900}>{dt.label}</Text>

                        <Box smMarginStart={breakpointDuo ? 1 : 3}>
                          <XIconSvg />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box display="flex" flex="shrink" minWidth="140px" justifyContent="end">
                  <TapArea onTap={handleToggleSelect} accessibilityLabel={resources.clearAll}>
                    <Text color={colors.primary}>
                      {isToggleSelect ? resources.deselect : resources.select}
                    </Text>
                  </TapArea>

                  {/* <Box height={25} borderStyle="sm" color={colors.black900} /> */}

                  <TapArea onTap={clearAll} accessibilityLabel={resources.clearAll}>
                    <Text color={colors.primary} align="end">
                      {resources.clearAll}
                    </Text>
                  </TapArea>
                </Box>
              </Box>
            </Box>

            <Box>
              <Box
                borderStyle="lg"
                marginBottom={12}
                marginTop={2}
                paddingY={3}
                paddingX={breakpointDuo ? 2 : 4}
                borderRadius={4}
              >
                <Table accessibilityLabel="Basic Table">
                  {!breakpointDuo && (
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan={2}>
                          <Box display="flex" direction="row" alignItems="center" marginBottom={2}>
                            <Box paddingX={breakpointDuo ? 2 : 4}>
                              <Icon
                                icon={"document"}
                                accessibilityLabel={resources.document}
                                color={colors.black600}
                                size={"sm"}
                              />
                            </Box>

                            <Text weight="bold" size="300">
                              {resources.documentTypes.documentType.toUpperCase()}
                            </Text>
                            <Box display="flex" gap={5} direction="row">
                              <Box margin={1} />
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "documentType" &&
                                    filter.sortDirection === "DESC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("documentType", "DESC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "documentType" &&
                                    filter.sortDirection === "DESC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg direction="down" />
                                </Box>
                              </TapArea>
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "documentType" &&
                                    filter.sortDirection === "ASC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("documentType", "ASC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "documentType" &&
                                    filter.sortDirection === "ASC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg />
                                </Box>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>
                          <Box display="flex" direction="row" marginBottom={2}>
                            <Text weight="bold" size="300">
                              {resources.name.toUpperCase()}
                            </Text>
                            <Box display="flex" gap={5} direction="row">
                              <Box margin={1} />
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "name" &&
                                    filter.sortDirection === "DESC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("name", "DESC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "name" && filter.sortDirection === "DESC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg direction="down" />
                                </Box>
                              </TapArea>
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "name" &&
                                    filter.sortDirection === "ASC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("name", "ASC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "name" && filter.sortDirection === "ASC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg />
                                </Box>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>
                          <Box display="flex" direction="row" marginBottom={2}>
                            <Text weight="bold" size="300">
                              {resources.browseDocument.author.toUpperCase()}
                            </Text>
                            <Box display="flex" gap={5} direction="row">
                              <Box margin={1} />
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "deletedBy" &&
                                    filter.sortDirection === "DESC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("deletedBy", "DESC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "deletedBy" &&
                                    filter.sortDirection === "DESC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg direction="down" />
                                </Box>
                              </TapArea>
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "deletedBy" &&
                                    filter.sortDirection === "ASC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("deletedBy", "ASC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "deletedBy" &&
                                    filter.sortDirection === "ASC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg />
                                </Box>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.HeaderCell>

                        <Table.HeaderCell>
                          <Box display="flex" direction="row" marginBottom={2}>
                            <Text weight="bold" size="300">
                              {resources.dataTypeValues.date.toUpperCase()}
                            </Text>
                            <Box display="flex" gap={5} direction="row">
                              <Box margin={1} />
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "deletedAt" &&
                                    filter.sortDirection === "DESC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("deletedAt", "DESC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "deletedAt" &&
                                    filter.sortDirection === "DESC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg direction="down" />
                                </Box>
                              </TapArea>
                              <TapArea
                                onTap={() => {
                                  if (
                                    filter.sortField === "deletedAt" &&
                                    filter.sortDirection === "ASC"
                                  ) {
                                    changeSort(undefined, undefined);
                                  } else {
                                    changeSort("deletedAt", "ASC");
                                  }
                                }}
                              >
                                <Box
                                  color={
                                    filter.sortField === "deletedAt" &&
                                    filter.sortDirection === "ASC"
                                      ? colors.neutral500
                                      : colors.neutral200
                                  }
                                  padding={1}
                                  borderRadius={4}
                                >
                                  <ArrowSvg />
                                </Box>
                              </TapArea>
                            </Box>
                          </Box>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                  )}
                  <Table.Body>
                    {documentTypeIsLoading && <RowSkeleton />}
                    {!documentTypeIsLoading &&
                      (documentsData || []).map((document, idx) => {
                        return (
                          <DeletedDocumentCardList
                            document={document}
                            index={idx}
                            selectToggle={isToggleSelect}
                            selectAll={isSelectAll}
                            onSelectAll={handleSelectAll}
                            onCheckboxChange={(id, isChecked) => handleChecboxChange(id, isChecked)}
                            onDeleteDocument={handleDeleteDocument}
                            onRestoreDocument={handleRestoreDocument}
                            last={documentsData.length}
                          />
                        );
                      })}
                  </Table.Body>
                </Table>
              </Box>
              <Pagination<typeof rowsPerPage>
                id="Browse"
                rowsLabel={`${resources.documents}:`}
                currentPage={currentPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageLength={documentsData?.length || 0}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                isNextButtonDisabled={isNextButtonDisabled}
                isPreviousButtonDisabled={isPreviousButtonDisabled}
                pageLengthOptions={pageLengthOptions}
                totalRowCount={totalRowCount}
              />

              <Box
                display="flex"
                justifyContent={breakpointDuo ? "center" : "end"}
                marginTop={2}
                paddingX={3}
              >
                <Box marginTop={3} marginEnd={2}>
                  <TapArea onTap={() => restoreDocumentsList()}>
                    <Box
                      minWidth={130}
                      borderStyle="sm"
                      height={42}
                      borderRadius={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color={colors.primary}
                      hoverColor={colors.primary400}
                    >
                      <Box paddingRight={2} paddingTop={1}>
                        <RestoreDeletedDocumentSvgIcon
                          width={24}
                          height={35}
                          color={colors.neutral200}
                        />
                      </Box>
                      <Text weight="bold" color={colors.neutral200}>
                        {resources.restore}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>

                <Box marginTop={3} marginStart={2}>
                  <TapArea onTap={() => setShowDeleteModal(true)}>
                    <Box
                      minWidth={130}
                      borderStyle="sm"
                      height={42}
                      borderRadius={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color={colors.neutral200}
                      hoverColor={colors.secondary}
                    >
                      <Box paddingRight={2} paddingTop={1}>
                        <HardDeleteDocumentSvgIcon width={18} height={25} color={colors.black600} />
                      </Box>
                      <Text weight="bold" color={colors.black600}>
                        {resources.delete_}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Box>
              <DeleteDocumentModal
                permanentDelete={true}
                show={showDeleteModal}
                onDismiss={() => setShowDeleteModal(false)}
                deleteDocument={() => {
                  hardDeleteConfirmation();
                }}
                resources={resources}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
