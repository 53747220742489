import { FunctionComponent, PropsWithChildren } from "react";

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = PropsWithChildren<{}>;

const FixedContainer: FunctionComponent<Props> = ({ children }) => {
  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        flex: "1",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
};

export { FixedContainer };
