import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const PrinterSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 46,
  height = 46,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      viewBox="0 0 46 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.7492 33.9703C39.7492 36.6403 39.7492 39.0903 39.7492 41.5403C39.7492 44.2603 38.7292 45.3003 36.0592 45.3103C27.1292 45.3103 18.2092 45.3103 9.27919 45.3103C6.57919 45.3103 5.64919 44.3603 5.63919 41.6703C5.63919 39.1803 5.63919 36.6903 5.63919 33.9803C4.69919 33.9803 3.84919 34.0003 2.98919 33.9803C1.11919 33.9203 0.0591878 32.9103 0.0191878 31.0403C-0.0208122 28.4003 -0.00081224 25.7803 0.0191878 23.1503C0.0491878 19.2203 2.29919 16.9603 6.23919 16.9503C13.3692 16.9303 20.4992 16.9503 27.6392 16.9503C31.4492 16.9503 35.2492 16.9403 39.0592 16.9503C43.0692 16.9703 45.2992 19.2203 45.3192 23.2303C45.3292 25.6803 45.3192 28.1303 45.3192 30.5803C45.3192 32.9803 44.4292 33.8803 42.0092 33.9703C41.3192 34.0003 40.6292 33.9703 39.7592 33.9703H39.7492ZM11.4792 31.2603V39.4803H33.8692V31.2603H11.4792ZM38.2392 22.0103C37.0692 22.0103 36.1692 22.9003 36.1692 24.0703C36.1692 25.2403 37.0692 26.1303 38.2392 26.1303C39.4192 26.1303 40.3192 25.2403 40.3192 24.0703C40.3192 22.9103 39.4192 22.0203 38.2392 22.0103Z"
        fill="#767676"
      />
      <path
        d="M39.7473 14.1298H33.9773C33.9773 12.4498 33.9773 10.7998 33.9773 9.15984C33.9773 7.54984 32.1673 5.72984 30.5673 5.72984C24.6173 5.72984 18.6573 5.72984 12.7073 5.72984C12.3173 5.72984 11.9373 5.76984 11.4073 5.78984V14.1198H5.74733C5.71733 13.9098 5.65733 13.7098 5.65733 13.5098C5.65733 10.0498 5.63733 6.58984 5.65733 3.13984C5.66733 1.11984 6.75733 0.0198417 8.73733 0.0198417C16.7073 -0.00015833 24.6673 -0.0201583 32.6373 0.0598417C33.4973 0.0598417 34.4673 0.599842 35.1673 1.15984C36.3573 2.11984 37.3473 3.30984 38.4473 4.36984C39.3573 5.23984 39.7573 6.28984 39.7473 7.53984C39.7273 9.71984 39.7473 11.8898 39.7473 14.1298V14.1298Z"
        fill="#767676"
      />
    </svg>
  );
};
