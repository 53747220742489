import {
  AccordionExpandable,
  Box,
  Button,
  Divider,
  FormHtml,
  IconButton,
  ImagePicker,
  PasswordSetterField,
  SelectList,
  Skeleton,
  SummaryField,
  Text,
  TextField,
  changeLanguage,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { checkPasswordStrength, cleanPhone, validatePhoneNumber } from "@prodoctivity/shared";
import { ReactNode, useCallback, useEffect, useState } from "react";

import type { MyUserProfile } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "react-simple-snackbar";
import { AvatarImage } from "../../components/Avatar/Avatar";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { NotificationMessage } from "../../components/NotificationMessage";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useLanguages } from "../../hooks/useLanguages";
import { useServices } from "../../hooks/useServices";
import { useUserProfile } from "../../hooks/useUserProfile";
import { organizationLinkTemplates } from "../../link-templates";
import { LeaveOrganization } from "./LeaveOrganization";
import { DeleteOrganizationForm } from "./DeleteOrganizationForm";
import { DeleteAccountForm } from "./DeleteAccountForm";

export function Field({
  name,
  children,
  divider = true,
}: {
  children?: ReactNode;
  divider?: boolean;
  name: string;
}) {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const breakPoint = breakpoint === "medium" || breakpoint === "small";
  const disableBreakPoint = breakPoint ? "none" : "flex";
  return (
    <Box>
      <Box minHeight={65} display="flex">
        <Box display={disableBreakPoint} alignItems="center" maxWidth={250} width="100%">
          <Text color={colors.subtle}>{name}</Text>
        </Box>
        {children}
      </Box>
      {divider && <Box height={1} color={colors.neutral500} />}
    </Box>
  );
}
function ProfilePage() {
  const { colors } = useColors();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showLeaveOrganization, setShowLeaveOrganization] = useState(false);
  const [showDeleteOrganization, setShowDeleteOrganizatiton] = useState(false);
  const [showDeleteAccount, setShowDeleteAccounť] = useState(false);
  const { changePassword, user } = useServices();
  const { updateUserProfile } = useServices();
  const { resources, moment } = useAppTranslation();
  const { languages } = useLanguages();
  const { i18n } = useTranslation();
  const [success] = useSnackbar({ style: { backgroundColor: "#008753" } });
  const [fail] = useSnackbar({ style: { backgroundColor: "#CC0000" } });
  const [profileData, setProfileData] = useState<MyUserProfile | undefined>();

  const changeLang = useMemo(() => {
    return changeLanguage(i18n, moment);
  }, [i18n, moment]);

  const { data, isLoading, refetch } = useUserProfile();
  useEffect(() => {
    if (!isLoading && data) {
      setProfileData(data);
    }
  }, [data, isLoading]);

  const { mutate } = useMutation(updateUserProfile, {
    onSuccess: () => {
      success(resources.accountPage.successApplyingChange);
      refetch();
    },
    onError: () => {
      refetch();
      fail(resources.unknownError);
    },
  });

  const { mutate: updatePhoneNumber } = useMutation(updateUserProfile, {
    onSuccess: () => {
      success(resources.accountPage.successApplyingChange);
      refetch();
    },
    onError: () => {
      refetch();
      fail(resources.validations.invalidPhoneNumber);
    },
  });

  const handlePicChange = useCallback(
    (image: string) => {
      setProfileData((curr) => {
        if (!curr) {
          return curr;
        }
        return { ...curr, avatarImageDataURI: image };
      });
      mutate({ avatarImageDataURI: image });
    },
    [setProfileData, mutate]
  );

  const clearPicture = useCallback(() => {
    mutate({ avatarImageDataURI: "" });
  }, [mutate]);

  useEffect(() => {
    if (data && data.language && data.language !== i18n.language) {
      changeLang(data.language);
    }
  }, [changeLang, data, i18n]);
  const loading = isLoading || !data || !profileData;
  const [newPassword, setNewPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const hasInvalidPassword = useMemo(() => {
    return !checkPasswordStrength(newPassword).success;
  }, [newPassword]);
  const handlePasswordChange = useCallback(async () => {
    try {
      await changePassword(oldPassword.trim(), newPassword.trim());
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false);
      }, 4500);
    } catch (error) {
      setShowErrorToast(true);
      setTimeout(() => {
        setShowErrorToast(false);
      }, 4500);
    }
  }, [changePassword, oldPassword, newPassword]);
  const { breakpoint } = useDesignBreakpoint();
  const isSmallDisplay = breakpoint === "small" || breakpoint === "medium";

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    const result: BreadCrumbEntry[] = [
      ...(!isSmallDisplay
        ? [{ type: "url", name: resources.home, url: organizationLinkTemplates.home() } as const]
        : []),
      { type: "url", name: resources.account, url: organizationLinkTemplates.account() },

      { type: "text", name: resources.accountPage.editProfile },
    ];

    return result;
  }, [isSmallDisplay, resources.account, resources.accountPage.editProfile, resources.home]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" alignItems={isSmallDisplay ? "center" : undefined}>
        <Box color={isSmallDisplay ? colors.white : undefined} paddingX={isSmallDisplay ? 6 : 10}>
          {isSmallDisplay ? null : (
            <Box paddingY={4}>
              <Text weight="bold" size="400">
                {resources.accountPage.basicInfo}
              </Text>
            </Box>
          )}

          <Box
            color={colors.white}
            paddingX={isSmallDisplay ? undefined : 6}
            rounding={isSmallDisplay ? undefined : 1}
            borderRadius={isSmallDisplay ? undefined : 12}
            marginBottom={10}
            width={isSmallDisplay ? "85vw" : undefined}
          >
            {isSmallDisplay ? (
              <Field name={resources.photo}>
                <Box
                  paddingY={3}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="between"
                >
                  <Box paddingY={2}>
                    <Text weight="bold" size="400">
                      {resources.accountPage.basicInfo}
                    </Text>
                  </Box>
                </Box>
              </Field>
            ) : (
              <Field name={resources.photo}>
                <Box
                  paddingY={3}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="between"
                >
                  <Skeleton show={loading} width="40%" height={25}>
                    <Text>{resources.accountPage.personalizePicture}</Text>
                  </Skeleton>
                  <Skeleton show={loading} rounded={true} width={48} height={48}>
                    <Box flex="shrink" display="flex" direction="row" alignItems="center" gap={2}>
                      <ImagePicker onSelected={handlePicChange}>
                        <AvatarImage
                          size={48}
                          name={profileData?.firstName ?? ""}
                          email={profileData?.email ?? ""}
                          imageDataURI={profileData?.avatarImageDataURI}
                        />
                      </ImagePicker>
                      {profileData?.avatarImageDataURI && (
                        <Box display="flex" borderRadius={4} padding={1}>
                          <IconButton
                            accessibilityLabel={resources.remove}
                            size={"sm"}
                            color={colors.neutral600}
                            icon="x"
                            onClick={clearPicture}
                          />
                        </Box>
                      )}
                    </Box>
                  </Skeleton>
                </Box>
              </Field>
            )}
            <Field name={resources.name}>
              <Box display="flex" alignItems="center" width="100%">
                <Skeleton show={loading}>
                  {!loading && (
                    <SummaryField
                      value={profileData.firstName}
                      onChange={({ value }) => {
                        setProfileData({ ...data, firstName: value as string });
                      }}
                      onDone={() => mutate({ firstName: profileData.firstName })}
                      renderField={(value, onChange) => (
                        <TextField value={value} id="name" onChange={onChange} />
                      )}
                    />
                  )}
                </Skeleton>
              </Box>
            </Field>
            <Field name={resources.lastName}>
              <Box display="flex" alignItems="center" width="100%">
                {loading ? (
                  <Skeleton width="20%" height={25} />
                ) : (
                  <SummaryField
                    value={profileData.lastName}
                    onChange={({ value }) => {
                      setProfileData({ ...data, lastName: value as string });
                    }}
                    onDone={() => mutate({ lastName: profileData.lastName })}
                    renderField={(value, onChange) => (
                      <TextField value={value} id="lastName" onChange={onChange} />
                    )}
                  />
                )}
              </Box>
            </Field>
            <Field name={resources.position}>
              <Box display="flex" alignItems="center" width="100%">
                {loading ? (
                  <Skeleton width="20%" height={25} />
                ) : (
                  <Text>{profileData.positionRole}</Text>
                )}
              </Box>
            </Field>
            <Field name={resources.language}>
              <Box display="flex" alignItems="center" width="100%">
                {loading ? (
                  <Skeleton width="20%" height={25} />
                ) : (
                  <SummaryField
                    value={profileData.language}
                    options={languages}
                    onChange={({ value }) => {
                      setProfileData({ ...data, language: value as any });
                    }}
                    onDone={() => mutate({ language: profileData.language })}
                    renderField={(value, onChange) => (
                      <SelectList
                        id="language"
                        onChange={onChange}
                        value={value}
                        options={languages}
                      />
                    )}
                  />
                )}
              </Box>
            </Field>

            <Field name={resources.password} divider={false}>
              <Box paddingY={4} width={isSmallDisplay ? "100%" : "50%"}>
                <FormHtml>
                  <TextField
                    type="password"
                    placeholder={resources.oldPassword}
                    id="old-password"
                    onChange={(e) => setOldPassword(e.value)}
                    value={oldPassword}
                  />
                  <Box height={10} />
                  <PasswordSetterField
                    id="new-password"
                    value={newPassword}
                    onChange={setNewPassword}
                    resources={resources}
                    labelDisplay="hidden"
                  />
                </FormHtml>
                <Box height={10} />

                <Box
                  width={"100%"}
                  display="flex"
                  justifyContent={breakpoint === "medium" ? "center" : "start"}
                  alignItems="center"
                >
                  <Button
                    accessibilityLabel={resources.changePassword}
                    text={resources.changePassword}
                    onClick={handlePasswordChange}
                    disabled={loading || !newPassword || !oldPassword || hasInvalidPassword}
                    fullWidth={breakpoint === "small" ? true : false}
                  />
                </Box>
              </Box>
            </Field>
          </Box>

          {showSuccessToast ? (
            <NotificationMessage
              type={"success"}
              message={resources.passwordSuccess}
              position="bottom-left"
              handleDismiss={() => setShowSuccessToast(false)}
            />
          ) : null}
          {showErrorToast ? (
            <NotificationMessage
              type={"error"}
              message={resources.passwordError}
              position="bottom-left"
              handleDismiss={() => setShowErrorToast(false)}
            />
          ) : null}

          {isSmallDisplay ? null : (
            <Box paddingY={4}>
              <Text weight="bold" size="400">
                {resources.accountPage.contactInfo}
              </Text>
            </Box>
          )}

          <Box
            color={colors.white}
            paddingX={isSmallDisplay ? undefined : 6}
            rounding={isSmallDisplay ? undefined : 1}
            borderRadius={isSmallDisplay ? undefined : 12}
            marginBottom={10}
          >
            {isSmallDisplay ? (
              <>
                <Divider />
                <Box paddingY={isSmallDisplay ? 2 : 4}>
                  <Text weight="bold" size="400">
                    {resources.accountPage.contactInfo}
                  </Text>
                </Box>{" "}
              </>
            ) : null}
            <Field name={resources.phone}>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                padding={isSmallDisplay ? undefined : 4}
              >
                {loading ? (
                  <Skeleton width="20%" height={25} />
                ) : (
                  <SummaryField
                    value={profileData.phone}
                    onChange={({ value }) => {
                      const numericValue = value?.replace(/\D/g, "");

                      setProfileData({ ...data, phone: cleanPhone(numericValue || "") });
                    }}
                    onDone={() => {
                      updatePhoneNumber({ phone: profileData.phone });
                    }}
                    renderField={(value, onChange) => (
                      <TextField
                        value={value}
                        type="tel"
                        id="phone"
                        placeholder="(555) 555-5555"
                        onChange={onChange}
                        errorMessage={
                          value
                            ? validatePhoneNumber(cleanPhone(value), true)
                              ? undefined
                              : resources.validations.invalidPhoneNumber
                            : undefined
                        }
                      />
                    )}
                  />
                )}
              </Box>
            </Field>
            <Field name={resources.email} divider={false}>
              <Box display="flex" alignItems="center" width="100%">
                {loading ? <Skeleton width="20%" height={25} /> : <Text>{profileData.email}</Text>}
              </Box>
            </Field>
          </Box>

          <Box marginBottom={10}>
            <AccordionExpandable
              id="danger_zone"
              accessibilityCollapseLabel={resources.collapse}
              accessibilityExpandLabel={resources.expand}
              items={[
                {
                  title: resources.leaveOrganizationPage.dangerZone,
                  icon: "alert",
                  type: "error",
                  children: (
                    <>
                      {showLeaveOrganization && user ? (
                        <LeaveOrganization
                          showLeaveOrg={showLeaveOrganization}
                          setShowLeaveOrganization={setShowLeaveOrganization}
                          organizationId={user.organizationId}
                        />
                      ) : showDeleteOrganization && user ? (
                        <DeleteOrganizationForm
                          showDeleteOrg={showDeleteOrganization}
                          setShowDeleteOrganization={setShowDeleteOrganizatiton}
                          organizationId={user.organizationId}
                        />
                      ) : showDeleteAccount && user ? (
                        <DeleteAccountForm
                          showDeleteAccount={showDeleteAccount}
                          setShowDeleteAccount={setShowDeleteAccounť}
                          email={user.username}
                        />
                      ) : (
                        <Box
                          width={"100%"}
                          display="flex"
                          justifyContent={breakpoint === "medium" ? "center" : "start"}
                          alignItems="center"
                          gap={6}
                        >
                          <Button
                            disabled={showLeaveOrganization}
                            size={breakpoint === "small" || breakpoint === "medium" ? "md" : "lg"}
                            text={resources.exitOrganization}
                            accessibilityLabel={resources.joinOrganizationAndLogin}
                            fullWidth={breakpoint === "small" ? true : false}
                            color={"red"}
                            onClick={() => setShowLeaveOrganization(true)}
                          />

                          <Button
                            disabled={showDeleteOrganization}
                            size={breakpoint === "small" || breakpoint === "medium" ? "md" : "lg"}
                            text={resources.deleteOrganization}
                            fullWidth={breakpoint === "small" ? true : false}
                            color={"red"}
                            onClick={() => setShowDeleteOrganizatiton(true)}
                          />

                          <Button
                            disabled={showDeleteAccount}
                            size={breakpoint === "small" || breakpoint === "medium" ? "md" : "lg"}
                            text={resources.deleteAccount}
                            fullWidth={breakpoint === "small" ? true : false}
                            color={"red"}
                            onClick={() => setShowDeleteAccounť(true)}
                          />
                        </Box>
                      )}
                      {isSmallDisplay ? (
                        <>
                          <Divider />
                          <Box paddingY={isSmallDisplay ? 2 : 4}>
                            <Text weight="bold" size="400">
                              {resources.leaveOrganization}
                            </Text>
                          </Box>
                        </>
                      ) : null}
                    </>
                  ),
                },
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default ProfilePage;
