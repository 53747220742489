import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const FragmentMenuIcon: FunctionComponent<Props> = ({ color }) => {
  const { colors } = useColors();
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9222_118052)">
        <path
          d="M18.123 0H0V22.9795C0.0491803 23.2623 0.295082 23.4836 0.590164 23.4836H10.7336L10.7828 23.4344L13.6844 20.7049L12.5533 17.1885L15.2459 14.668L14.1148 11.1516L16.8074 8.60656L15.6885 5.09016L18.123 2.79098V0ZM7.43853 18.3689H3.95902C3.62705 18.3689 3.34426 18.0984 3.34426 17.7541C3.34426 17.4098 3.62705 17.1393 3.95902 17.1393H7.43853C7.78279 17.1393 8.05328 17.4098 8.05328 17.7541C8.05328 18.0984 7.78279 18.3689 7.43853 18.3689ZM8.70492 14.0287H5.82787V14.582H3.34426V12.2459H5.82787V12.7992H8.70492C9.04918 12.7992 9.31967 13.082 9.31967 13.4139C9.31967 13.7459 9.04918 14.0287 8.70492 14.0287ZM11.582 9.67623H7.53689V10.4631H3.34426V7.67213H7.53689V8.44672H11.582C11.9262 8.44672 12.1967 8.71721 12.1967 9.06147C12.1967 9.40574 11.9262 9.67623 11.582 9.67623ZM12.1967 6.11066H8.0041V5.32377H3.95902C3.62705 5.32377 3.34426 5.05328 3.34426 4.70902C3.34426 4.38934 3.62705 4.09426 3.95902 4.09426H8.0041V3.31967H12.1967V6.11066Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path d="M10.7826 23.4346V23.4838H10.7334L10.7826 23.4346Z" fill="white" />
        <path
          d="M12.1972 3.31934V6.11032H8.00456V5.32343H3.95948C3.62751 5.32343 3.34473 5.05294 3.34473 4.70868C3.34473 4.38901 3.62751 4.09393 3.95948 4.09393H8.00456V3.31934H12.1972Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M12.1972 9.06122C12.1972 9.40548 11.9267 9.67597 11.5824 9.67597H7.53735V10.4629H3.34473V7.67188H7.53735V8.44647H11.5824C11.9267 8.44647 12.1972 8.71696 12.1972 9.06122Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M9.32014 13.4141C9.32014 13.7461 9.04964 14.0289 8.70538 14.0289H5.82833V14.5822H3.34473V12.2461H5.82833V12.7994H8.70538C9.04964 12.7994 9.32014 13.0822 9.32014 13.4141Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path d="M10.7338 23.4834L10.7092 23.508L10.6846 23.4834H10.7338Z" fill="#5A5A5A" />
        <path
          d="M21.0001 9.68897V21.1849C21.0001 21.5291 20.7173 21.7996 20.3853 21.7996H16.4263C16.1558 21.7996 15.9222 21.6275 15.8361 21.3816L14.7787 18.1111C14.705 17.8898 14.7664 17.6316 14.9386 17.4718L17.3484 15.2095L16.3402 12.0619C16.2664 11.8283 16.3402 11.5824 16.5124 11.4226L18.8361 9.23405C18.9468 9.1234 19.0943 9.07422 19.2542 9.07422H20.3853C20.7173 9.07422 21.0001 9.34471 21.0001 9.68897Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_9222_118052">
          <rect width="21" height="23.5082" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
