import {
  Box,
  Button,
  DataElementInput,
  Grid,
  SelectList,
  Spinner,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
  type DesignBreakpointType,
} from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction, useMemo } from "react";
import { FieldCondition, FilterOption, SupportedDataTypes } from "./hooks";

import type { ContextField } from "@prodoctivity/shared/src/index-types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { XIconSvg } from "../../svg/XIconSvg";

type Props = {
  intersectionContext: ContextField[];
  filterByFields: FieldCondition[];
  documentTypesSelected: FilterOption[];
  isLoading: boolean;
  selectedDocumentName: FilterOption[];
  isBoxOpenDocument: boolean;
  setBoxOpenDocument: (isBoxOpenDocument: boolean) => void;
  removeQueryRow: (id: string) => void;
  onChange: (
    id: string,
    name: "value" | "equalityOperator",
    value: string | number | Date | undefined | null
  ) => void;
  resetSelectedValues: () => void;
  buildSearchUrl: () => void;
  handlerEnterKeyPress: ({ event: { code } }: { event: { code: string } }) => void;
  closePopup: () => void;
  toggleShowMore: () => void;
  showMore: boolean;
  setSearchClicked?: Dispatch<SetStateAction<boolean>>;
  componentBreakpoint: DesignBreakpointType;
};

export const FieldsRequiredByTheDocument = ({
  intersectionContext,
  filterByFields,
  documentTypesSelected,
  isLoading,
  removeQueryRow,
  onChange,
  resetSelectedValues,
  isBoxOpenDocument,
  buildSearchUrl,
  closePopup,
  toggleShowMore,
  showMore,
  setSearchClicked,
  componentBreakpoint,
}: Props) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const isMobile = breakpoint === "small";

  return documentTypesSelected.length > 0 ? (
    <Box>
      <Box>
        <Box borderStyle="lg" borderRadius={6}></Box>
        <Box
          color={colors.neutral200}
          width={"100%"}
          maxHeight={isMobile ? 180 : 220}
          overflow="scrollY"
        >
          <Grid gridTemplateColumns={[1]} gap={1}>
            {isLoading ? (
              <Spinner accessibilityLabel={"Loading"} show={isLoading} />
            ) : intersectionContext.length > 0 && isBoxOpenDocument && !isBoxOpenDocument ? null : (
              filterByFields.slice(0, showMore ? filterByFields.length : 6).map((fields) => {
                return (
                  <Box
                    display="flex"
                    direction={isMobile ? "column" : undefined}
                    paddingY={3}
                    width="100%"
                    key={fields.id}
                  >
                    <Box
                      width="50%"
                      marginStart={4}
                      marginBottom={isMobile ? 3 : undefined}
                      marginTop={2}
                    >
                      <Text>{fields.field.humanName}</Text>
                    </Box>
                    <Box
                      display="flex"
                      width="100%"
                      height="auto"
                      overflow="hidden"
                      gap={1}
                      alignItems="start"
                      justifyContent="between"
                    >
                      <Box
                        marginStart={isMobile ? 4 : undefined}
                        marginEnd={isMobile ? undefined : 4}
                        width={"100%"}
                      >
                        <GetFieldControl
                          isMobile={isMobile}
                          fieldCondition={fields}
                          onChange={onChange}
                          componentBreakpoint={componentBreakpoint}
                        />
                      </Box>

                      <Box
                        width="auto"
                        height="100%"
                        display="none"
                        justifyContent="between"
                        alignItems="center"
                      >
                        <TapArea onTap={() => removeQueryRow(fields.id)}>
                          <XIconSvg />
                        </TapArea>
                      </Box>
                    </Box>
                  </Box>
                );
              })
            )}
          </Grid>
        </Box>
        <Box display="flex" color={colors.neutral200} alignItems="center" justifyContent="center">
          {filterByFields.length < 6 ? null : (
            <Box
              onClickCapture={toggleShowMore}
              dangerouslySetInlineStyle={{
                __style: {
                  cursor: "pointer",
                },
              }}
              paddingY={3}
            >
              <Text color={colors.primary} inline={true}>
                {showMore ? resources.seeLess : resources.seeMore}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Box borderStyle="lg" borderRadius={6}></Box>
        <Box
          width="100%"
          minHeight={50}
          display="flex"
          direction="row"
          justifyContent="end"
          alignItems="center"
          paddingY={3}
          paddingX={4}
        >
          <Box minWidth={100} marginEnd={4}>
            <Button
              text={resources.default}
              color="transparent"
              accessibilityLabel={resources.default}
              onClick={() => {
                resetSelectedValues();
              }}
              fullWidth={true}
            />
          </Box>
          <Box minWidth={100}>
            <Button
              text={resources.search}
              accessibilityLabel={resources.search}
              onClick={() => {
                buildSearchUrl();
                closePopup();
                if (setSearchClicked) {
                  setSearchClicked(false);
                }
              }}
              fullWidth={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

type GetFieldControlProps = {
  fieldCondition: FieldCondition;
  onChange: (
    id: string,
    name: "value" | "equalityOperator",
    value: string | number | Date | undefined | null,
    dataType?:
      | "Alphanumeric"
      | "Numeric"
      | "Currency"
      | "Logical"
      | "Date"
      | "DateTime"
      | "Time"
      | "Image"
  ) => void;
  componentBreakpoint: DesignBreakpointType;
  isMobile?: boolean;
};

const GetFieldControl: FunctionComponent<GetFieldControlProps> = ({
  fieldCondition,
  onChange,
  componentBreakpoint,
  isMobile,
}) => {
  const contextField = fieldCondition.field;
  const dataType = contextField.properties.dataType;
  const { resources, moment } = useAppTranslation();
  const { optionsDataTypes } = useMemo(() => {
    const optionsDataTypes: SupportedDataTypes[] = [
      { label: " = " /* resources.equals */, value: "eq", dataTypeArray: [] },
      { label: resources.notEquals, value: "neq", dataTypeArray: [] },
      { label: resources.contains, value: "contains", dataTypeArray: ["Alphanumeric"] },
      { label: resources.endsWith, value: "ends-with", dataTypeArray: ["Alphanumeric"] },
      { label: resources.startsWith, value: "starts-with", dataTypeArray: ["Alphanumeric"] },
      {
        label: " > ", //  resources.greaterThan,
        value: "gt",
        dataTypeArray: ["Alphanumeric", "Numeric", "Currency", "DateTime", "Date", "Time"],
      },
      {
        label: " < ", // resources.lessThan,
        value: "lt",
        dataTypeArray: ["Alphanumeric", "Numeric", "Currency", "DateTime", "Date", "Time"],
      },
      {
        label: " >= ", // resources.greaterThanOrEquals,
        value: "gte",
        dataTypeArray: ["Numeric", "Currency", "DateTime", "Date", "Time"],
      },
      {
        label: " <= ", // resources.lessThanOrEquals,
        value: "lte",
        dataTypeArray: ["Numeric", "Currency", "DateTime", "Date", "Time"],
      },
    ];
    return {
      optionsDataTypes: optionsDataTypes.filter(
        (op) => !op.dataTypeArray.length || op.dataTypeArray.includes(dataType)
      ),
    };
  }, [resources, dataType]);

  // const [fieldValue, setFieldValue] = useState<string | number | Date | undefined | null>(
  //   field.value
  // );
  const fieldValue = useMemo(() => {
    return {
      dataType: contextField.properties.dataType,
      value: fieldCondition.value as any,
    };
  }, [contextField.properties.dataType, fieldCondition.value]);

  const equalityOperators = useMemo(() => {
    return fieldCondition.equalityOperator;
  }, [fieldCondition.equalityOperator]);

  const equalityOperatorsLabel = optionsDataTypes.find((opt) => opt.value === equalityOperators);

  return (
    <Box display="flex" gap={2} justifyContent={isMobile ? "start" : "end"}>
      <Box width="40%">
        <Box padding={1}>
          <SelectList
            id={resources.conditions}
            label=""
            options={optionsDataTypes}
            onChange={(event) => {
              onChange(fieldCondition.id, "equalityOperator", event.value);
            }}
            placeholder={equalityOperatorsLabel?.label}
            value={equalityOperators}
          />
        </Box>
      </Box>

      <Box display="flex" width={"60%"}>
        <Box width={"100%"}>
          <DataElementInput
            componentBreakpoint={componentBreakpoint}
            contextField={contextField}
            typeValue={fieldValue}
            // typeValue={isThereAnUrlValue ? {dataType: isThereAnUrlValue.dataType, value: isThereAnUrlValue.val} as InputControlValueType : fieldValue}
            moment={moment}
            onChange={(e) => {
              const val: any = e.value;
              onChange(
                fieldCondition.id,
                "value",
                typeof val === "boolean" ? `${val}` : val,
                fieldValue.dataType
              );
            }}
            hideWrapper={true}
            getContextValueAsString={() => undefined}
            instanceFullPath={`${contextField.fullPath}[0]`}
            resources={resources}
            purpose={"search"}
          />
        </Box>
      </Box>
      {/* {(() => {
        switch (field.field.properties.dataType) {
          case "Alphanumeric":
            return (
              <TextField
                id={field.id}
                onChange={(e) => setFieldValue(e.value)}
                onBlur={() => {
                  onChange(field.id, "value", fieldValue);
                }}
                value={typeof fieldValue === "string" ? fieldValue : ""}
              />
            );
          case "Currency":
            return (
              <NumberField
                id="value"
                onBlur={(e) => {
                  const value: string | number | Date = e.value !== undefined ? e.value : "";
                  onChange(field.id, "value", value);
                }}
                onChange={(e) => setFieldValue(e.value)}
                value={fieldValue as number}
              />
            );
          case "DateTime":
            return (
              <DatePicker
                id="value"
                onChange={(e) => {
                  const value: string | number | Date | null =
                    e.value !== undefined ? e.value : "";
                  setFieldValue(value);
                  onChange(field.id, "value", value);
                }}
              />
            );
          case "Date":
            return (
              <DatePicker
                id="value"
                onChange={(e) => {
                  const value: string | number | Date | null =
                    e.value !== undefined ? e.value : "";
                  setFieldValue(value);
                  onChange(field.id, "value", value);
                }}
                value={fieldValue as Date}
              />
            );
          case "Time":
            return (
              <DatePicker
                id="value"
                onChange={(e) => {
                  const value: string | number | Date =
                    e.event.timeStamp !== undefined ? e.event.timeStamp : "";
                  setFieldValue(value);
                  onChange(field.id, "value", value);
                }}
              />
            );
          default:
            return (
              <TextField
                id="value"
                onChange={(e) => setFieldValue(e.value)}
                onBlur={() => {
                  onChange(field.id, "value", fieldValue);
                }}
                value={fieldValue as string}
              />
            );
        }
      })()} */}
    </Box>
  );
};
