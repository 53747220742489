import { Box, Spinner } from "@prodoctivity/design-system";
import { FC, useMemo } from "react";
import { StatsContainer } from "./StatsContainer";
import { PieChart } from "./wigets/Dougnut/PieChart";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { EmptyView } from "./wigets/EmptyView";

export const DoughnutCollectionGroupedDataView: FC<{
  dataSet:
    | Array<{
        level: number;
        identity: string;
        data: {
          issues: number;
          expired: number;
          expiring: number;
          valid: number;
        };
      }>
    | undefined;
  isLoading: boolean;
  filterHasSelection: boolean;
  title?: string;
  valueToRepresent: "expired" | "expiring" | "valid" | "issues";
}> = ({ dataSet, valueToRepresent, isLoading, title }) => {
  const { resources } = useAppTranslation();

  const labelTranslated = useMemo(() => {
    const labels: Record<"expired" | "expiring" | "valid" | "issues", string> = {
      expired: resources.expiredDocuments,
      expiring: resources.documentsAboutToExpire,
      valid: resources.valid,
      issues: resources.missingDocuments,
    };
    return labels[valueToRepresent];
  }, [
    resources.expiredDocuments,
    resources.documentsAboutToExpire,
    resources.valid,
    resources.missingDocuments,
    valueToRepresent,
  ]);

  const loadingView = useMemo(
    () => (
      <Box display="flex" justifyContent="center" alignItems="center" height={"400px"}>
        <Box alignItems="center">
          <Spinner show={true} />
        </Box>
      </Box>
    ),
    []
  );

  const renderContentByState = useMemo(() => {
    if (isLoading) {
      return loadingView;
    }

    if (!dataSet) {
      return <EmptyView mesage={resources.empty} />;
    }

    if (dataSet.length === 0) {
      return <EmptyView mesage={resources.empty} />;
    }

    const getRamdonHslColor = () => {
      return `hsl(${Math.floor(Math.random() * 1024)}, 70%, 50%)`;
    };

    const chartDataSet = dataSet
      .map((x) => {
        if (x.data[valueToRepresent] !== 0) {
          return {
            id: x.identity,
            label: x.identity,
            value: x.data[valueToRepresent],
            color: getRamdonHslColor(),
          };
        }
      })
      .filter((x) => x !== undefined) as Array<{
      id: string;
      label: string;
      value: number;
      color: string;
    }>;

    if (chartDataSet.map((x) => x.value).reduce((a, b) => a + b, 0) === 0) {
      return <EmptyView mesage={""} />;
    }

    return (
      <Box display="flex" direction="column" flex="grow" minHeight={250} gap={6}>
        <Box display="flex" direction="row" flex="grow" maxHeight={250}>
          <PieChart
            formatOutputValueInChart={false}
            data={chartDataSet}
            useLeyend={true}
            arcLabelsRadiusOffset={0.5}
            leyendLocation="leyend-left"
          />
        </Box>
      </Box>
    );
  }, [dataSet, isLoading, valueToRepresent, loadingView, resources.empty]);

  const chartTitle = useMemo(() => {
    const getSumary = (
      data: {
        issues: number;
        expired: number;
        expiring: number;
        valid: number;
      },
      valueToRepresent: "expired" | "expiring" | "valid" | "issues"
    ) => {
      if (!dataSet) {
        return 0;
      }

      if (!data) {
        return 0;
      }

      if (data[valueToRepresent] === undefined) {
        return 0;
      }
      return data[valueToRepresent];
    };

    if (!dataSet) {
      return "";
    }

    if (dataSet.length === 0) {
      return "";
    }

    return title
      ? title
      : labelTranslated +
          ": " +
          (dataSet
            ? dataSet
                ?.map((x) => getSumary(x.data, valueToRepresent))
                .reduce((a, b) => a + b)
                .toLocaleString("en-us", { minimumFractionDigits: 0 })
            : "");
  }, [title, dataSet, valueToRepresent, labelTranslated]);

  return <StatsContainer title={chartTitle}>{renderContentByState}</StatsContainer>;
};
