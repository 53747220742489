import {
  Box,
  BoxWithRef,
  Checkbox,
  Text,
  Layer,
  Popover,
  useColors,
  TapArea,
  DeleteDocumentModal,
  Tooltip,
  Table,
  Divider,
} from "@prodoctivity/design-system";
import { SyntheticEvent, useCallback, useRef, useState } from "react";

import type { DeletedDocumentSummary } from "@prodoctivity/types";
import { DeletedDocumentSvgIcon } from "../../svg/DeletedDocumentSvgIcon";
import { RestoreDeletedDocumentSvgIcon } from "../../svg/RestoreDeletedDocumentSvgIcon";
import { EllipseMenuSvg } from "../../svg/EllipseMenuSvg";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { DocIconsSvg } from "../../svg/DocumentIconsSvg";

type Props = {
  document: DeletedDocumentSummary;
  selectToggle: boolean;
  selectAll: boolean;
  onCheckboxChange: (id: string, isChecked: boolean) => void;
  onDeleteDocument: (id: string) => void;
  onRestoreDocument: (id: string) => void;
  onSelectAll: (checked: boolean) => void;
  index: number;
  lastPosition: number;
};

export function DeletedDocumentSmallList({
  index,
  document,
  selectToggle,
  selectAll,
  onSelectAll,
  onCheckboxChange,
  onDeleteDocument: handleDeleteDocument,
  onRestoreDocument: handleRestoreDocument,
  lastPosition,
}: Props) {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const anchorRefItems = useRef<Array<HTMLDivElement | null>>([]);

  const [isOpenMenu, setIsOpenMenu] = useState<boolean[]>([false]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showIndividualDeleteModal, setShowIndividualDeleteModal] = useState<boolean>(false);

  const handleChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement, Event>) => {
      const { checked, id } = event.currentTarget;

      if (selectAll) onSelectAll(false);

      onCheckboxChange(id, checked);

      setIsCheckboxChecked(checked);
    },
    [onCheckboxChange, onSelectAll, selectAll]
  );

  const handleOpen = useCallback((id: number) => {
    setIsOpenMenu((prevOpenStates: boolean[]) => {
      const updatedStates = [...prevOpenStates];
      updatedStates[id] = !prevOpenStates[id];
      return updatedStates;
    });
  }, []);

  return (
    <Box display="flex" direction="column">
      <Table.Cell>
        <Box display="flex" justifyContent="between">
          <Box display="flex">
            {selectToggle && (
              <Checkbox
                id={document.documentId}
                name={document.documentTypeName}
                checked={selectAll ? true : isCheckboxChecked}
                onChange={(e) => handleChange(e.event)}
              />
            )}
            <Box paddingX={2}>{<DocIconsSvg />}</Box>
            <Box>
              {document.documentTypeName ? (
                <Text size="300" weight="bold">
                  {document.documentTypeName}
                </Text>
              ) : (
                <Text size="300">...</Text>
              )}
              <Box height={2}></Box>
              <Text size="300" title={document.name} ellipsisLength={35}>
                {document.name}
              </Text>
            </Box>
          </Box>
          <Box>
            <BoxWithRef ref={(ref) => (anchorRefItems.current[index] = ref)}>
              <TapArea
                onTap={() => {
                  handleOpen(index);
                }}
                accessibilityLabel={resources.open}
              >
                <EllipseMenuSvg />
                {isOpenMenu[index] === true && (
                  <Layer>
                    <Popover
                      anchor={anchorRefItems.current[index]}
                      onDismiss={() => setIsOpenMenu([false])}
                      idealDirection="down"
                      positionRelativeToAnchor={false}
                      size={"flexible"}
                      shouldFocus={true}
                    >
                      <Box display="flex" width={170} direction="column">
                        {" "}
                        <TapArea>
                          <Box
                            display="flex"
                            onClickCapture={() => handleRestoreDocument(document.documentId)}
                            width={"100%"}
                            height={"auto"}
                            padding={2}
                            hoverColor={colors.primaryHover0}
                          >
                            <Tooltip text={resources.restore}>
                              <Box
                                display="flex"
                                direction="row"
                                paddingY={2}
                                paddingLeft={2}
                                alignItems="center"
                              >
                                <RestoreDeletedDocumentSvgIcon width={25} color={colors.black600} />
                                <Box display="flex" alignItems="center" paddingX={2}>
                                  <Text size="300">{resources.restore}</Text>
                                </Box>
                              </Box>
                            </Tooltip>
                          </Box>
                        </TapArea>
                        <TapArea>
                          <Box
                            display="flex"
                            onClickCapture={() => setShowIndividualDeleteModal(true)}
                            width={"100%"}
                            height={"auto"}
                            padding={2}
                            hoverColor={colors.primaryHover0}
                          >
                            <Tooltip text={resources.delete_}>
                              <Box
                                display="flex"
                                direction="row"
                                paddingY={2}
                                paddingLeft={3}
                                alignItems="center"
                              >
                                <DeletedDocumentSvgIcon
                                  width={20}
                                  height={26}
                                  color={colors.black600}
                                />
                                <Box display="flex" alignItems="center" paddingX={2}>
                                  <Text size="300">{resources.delete_}</Text>
                                </Box>
                              </Box>
                            </Tooltip>
                          </Box>
                        </TapArea>
                      </Box>
                    </Popover>
                  </Layer>
                )}
              </TapArea>
            </BoxWithRef>
            <DeleteDocumentModal
              permanentDelete={true}
              show={showIndividualDeleteModal}
              onDismiss={() => setShowIndividualDeleteModal(false)}
              deleteDocument={() => {
                handleDeleteDocument(document.documentId);
                setShowIndividualDeleteModal(false);
              }}
              resources={resources}
            />
          </Box>
        </Box>
      </Table.Cell>
      {index !== lastPosition && <Divider />}
    </Box>
  );
}
