import { TextArea as GestaltTextArea, TextAreaProps as RawTextAreaProps } from "gestalt";
import { Ref, forwardRef } from "react";

export type TextAreaProps = RawTextAreaProps & {
  mode?: "default" | "unstyled" | "summary";
  maxLength?: { characterCount: number; errorAccessibilityLabel: string };
  fullWith?: boolean;
};

export const TextArea = forwardRef(
  (
    { mode = "default", fullWith = false, ...props }: TextAreaProps,
    ref?: Ref<HTMLTextAreaElement>
  ) => {
    if (mode === "unstyled") {
      return (
        <div
          className={"prodoctivity-unstyled-textArea"}
          style={{ width: fullWith ? "100%" : undefined, height: "100%" }}
        >
          <GestaltTextArea ref={ref} {...props} />
        </div>
      );
    }

    return <GestaltTextArea ref={ref} {...props} />;
  }
);
