import { Box, Button, OverlayPanel, Text, useColors } from "@prodoctivity/design-system";
import { StateDataLinkSetting, useFieldRelevance } from "../hooks";

import { FunctionComponent } from "react";
import { noop } from "../../../../../utils";

export const DataLinkSettingSheet: FunctionComponent<StateDataLinkSetting> = ({
  contextDefinition,
  wizardDefinition,

  updateTemplateWizardDefinition,
  onCloseSheet,
}) => {
  const { colors } = useColors();
  const { resources } = useFieldRelevance({
    contextDefinition: contextDefinition,
    formDefinition: undefined,
    wizardDefinition: wizardDefinition,
    updateTemplateWizardDefinition: updateTemplateWizardDefinition,
    onCloseSheet: onCloseSheet,
  });

  return (
    <OverlayPanel
      heading={resources.dataLinks}
      onDismiss={onCloseSheet}
      size="lg"
      accessibilityLabel={""}
    >
      <Box>
        <Box wrap={true} display="flex" justifyContent="between">
          <Box justifyContent="between" display="flex" direction="row">
            <Box>
              <Text size="400" weight="bold" color={colors.primary}>
                {resources.formDesigner.connectorSetting.whatAreCondition}
              </Text>
              <Text>{resources.formDesigner.connectorSetting.description}</Text>
            </Box>
            <Box marginStart={10}>
              <Box display="flex" alignContent="center">
                <Button color={"gray"} text={resources.addNew} onClick={noop} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box marginTop={5}></Box>
      </Box>
    </OverlayPanel>
  );
};
