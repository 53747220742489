import {
  DatePickerProps,
  DesignBreakpointType,
  FormController,
  emptyFormController,
  type DataElementInputProps,
} from "@prodoctivity/design-system";
import type { TemplateVersionContextMapping } from "@prodoctivity/shared/src/index-types";
import type { HttpExecuteDataLinkRequest, HttpExecuteDataLinkResponse } from "@prodoctivity/types";
import {
  areLayoutsEqual,
  getNewLayoutFromDependencies,
} from "../../Dependencies/DependenciesLayoutUtils";
import {
  ConnectorDataLink,
  DataLink,
  FormConnector,
  FormDefinition,
  FormErrors,
  FormValues,
  Group,
  GroupLayouts,
  GroupValues,
  ProDoctivityFormLayout,
  ProDoctivityFormLayoutItem,
  SequenceBehavior,
  ValidationErrors,
  WebServiceConnector,
} from "../../_lib/types";
import { flattenDeep, makeCancelable, noop } from "../../_lib/utils";

import { createUuid } from "@prodoctivity/shared";
import type momentType from "moment";
import { Component } from "react";
import { parseConnectors } from "../../AssignDataLinks/utils";
import { buildGroupLayout } from "../../ProDoctivityFormDesigner/utils";
import { fireConnectorsList } from "../FireFormConnectorList";
import { FormPageComponent } from "./Page";

type PageTyping = { limit: number; content: ProDoctivityFormLayoutItem[] };

type Props = {
  layout: ProDoctivityFormLayout;
  fullLayout: ProDoctivityFormLayout;
  groupLayouts: GroupLayouts;
  fullGroupLayouts: GroupLayouts;
  formDefinition: FormDefinition;
  updateLayout: any;
  initialFormValues: FormValues;
  initialGroupValues: GroupValues;
  isDesignMode: boolean;
  readOnly: boolean;
  summaryMode: boolean;
  connectors: WebServiceConnector[];
  formConnectors: FormConnector[];
  dataLinks: DataLink[];
  connectorDataLinks: ConnectorDataLink[];
  onChangeFormValue?: (params: { name: string; value: any }) => void;
  onUpdateFormValues: (
    formValues: FormValues,
    formErrors: FormErrors,
    pinnedElements: string[]
  ) => void;
  onUpdateGroupValues: (groupValues: GroupValues) => void;
  setPinAllCallback?: (pinAllCallback: any) => void;
  onFireConnector: (formConnector: FormConnector, formValues: FormValues) => Promise<FormValues>;
  onConnectorFail?: (formConnector: FormConnector, error: any) => void;
  onNoDataFound?: (message: string) => void;
  showPins: boolean;
  initialPinnedElements: string[];
  initialPagesHeight: number;
  sequenceBehaviour: SequenceBehavior;
  i18n: (key: string) => string;
  onFormErrorsUpdate?: (formHasErrors: boolean) => void;
  navigate?: string;
  onNavigate?: (to: string) => void;
  onDependencyLayoutUpdate?: (layout: ProDoctivityFormLayout) => void;
  layoutTopics?: Set<string>;
  inDesignMode?: boolean;
  editingTopic?: boolean | string;
  onLayoutChange?: any;
  onChooseAlternativeQuestion?: any;
  enableTopicEditing?: any;
  onSubmitEditTopic?: any;
  removeTopic?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  onGroupLayoutChange?: (groupName: string, layout: ProDoctivityFormLayout) => void;
  paginate: boolean;
  dataLinkMappings: TemplateVersionContextMapping["datalinks"];
  executeDataLink: (
    dataLinkId: string,
    dataLinkConfigVersionId: string,
    inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
  ) => Promise<HttpExecuteDataLinkResponse["payload"]>;
  onnPageDefinitionChange?: (pages: ProDoctivityFormLayoutItem[]) => void;
  onSummary?: () => void;
  defaultColumns?: string;
  componentBreakpoint: DesignBreakpointType;
  moment: typeof momentType;
  handlerNextStep?: (callback: (isNext: boolean) => boolean) => void;
  cancel?: () => void;
  cancelLabel?: string;
  finish?: () => void;
  finishButtonLabel?: string;
  back?: () => void;
  backButtonLabel?: string;
  isLoading?: boolean;
  purpose?: DataElementInputProps["purpose"];
  resources: DatePickerProps["resources"] & {
    clear: string;
    clickUploadImage: string;
    collapse: string;
    contextValidationErrors: Record<string, string>;
    dataTypeValues: {
      none: string;
    };
    dragDropFile: string;
    expand: string;
  };
};

type State = {
  formDefinition?: FormDefinition;
  pinnedElements: string[];
  formErrors: FormErrors;
  groupDataFromConnectors: any;
  disabledElements: Array<string>;
  disabledElementsByDependencies: Array<string>;
  useDependenciesLayout: boolean;
  dependenciesLayouts: {
    layout: ProDoctivityFormLayout;
    groupLayouts: GroupLayouts;
  };
  totalPages: number;
  pages: Array<PageTyping> | null;
  pageHasErrors: boolean;
  rePaginate: boolean;
};

export class FormPage extends Component<Props, State> {
  fireConnectorsPromise: any = null;

  dependenciesHiddenFields: Array<string> = [];
  state: State = {
    pinnedElements: this.props.initialPinnedElements,
    formErrors: {},
    groupDataFromConnectors: null,
    disabledElements: [],
    disabledElementsByDependencies: [],
    useDependenciesLayout: false,
    dependenciesLayouts: {
      layout: this.props.layout,
      groupLayouts: this.props.groupLayouts,
    },
    totalPages: 0,
    pages: null,
    pageHasErrors: false,
    rePaginate: false,
  };

  constructor(props: Props) {
    super(props);

    const updatedLayout = this.updateInitialGroupLayout(
      this.props.layout,
      this.props.groupLayouts,
      this.props.formDefinition.groups
    );
    let layout = this.props.layout;

    if (updatedLayout.layoutUpdated) {
      layout = updatedLayout.newLayout;
      this.props.updateLayout(() => {
        return { layout: layout };
      });
    }

    this.state = {
      pinnedElements: this.props.initialPinnedElements,
      formErrors: {},
      groupDataFromConnectors: null,
      disabledElements: [],
      disabledElementsByDependencies: [],
      useDependenciesLayout: false,
      dependenciesLayouts: {
        layout: layout,
        groupLayouts: this.props.groupLayouts,
      },
      totalPages: 0,
      pages: null,
      pageHasErrors: false,
      rePaginate: false,
    };
  }

  validateNextStep(isNext: boolean) {
    const formController = this.context;
    const { wizardDefinition } = formController;

    const sections = wizardDefinition.pages.flatMap((p) => p.sections);
    const idx = sections.findIndex((s) => s.key === formController.currentSection);

    if (this && isNext) {
      if (idx - 1 === sections.length) {
        return true;
      }

      this.next();
    }

    if (this && !isNext) {
      if (idx <= 0) {
        return true;
      }
      this.prev();
    }
    return false;
  }

  context: React.ContextType<typeof FormController> = emptyFormController;

  async componentDidMount() {
    const { summaryMode } = this.props;

    if (this.props.handlerNextStep) {
      this.props.handlerNextStep((isNext) => this.validateNextStep(isNext));
    }

    if (summaryMode) return;

    this.paginateForm();
    if (this.props.setPinAllCallback) {
      this.props.setPinAllCallback(this.pinAllDataElements);
    }
  }

  updateInitialGroupLayout = (
    layout: ProDoctivityFormLayout,
    groupLayouts: GroupLayouts = {},
    groupsDefinition: Array<Group>
  ): { layoutUpdated: boolean; newLayout: ProDoctivityFormLayout } => {
    let triggerUpdateLayout = false;

    layout.forEach((element) => {
      if (element.type === "group") {
        let groupLayout = groupLayouts[element.name];

        if (!groupLayout || groupLayout.length <= 0) {
          groupLayout = [];

          groupsDefinition.forEach((group) => {
            if (group.groupName === element.name) {
              groupLayout = buildGroupLayout(group.fullPath, group.fields);
            }
          });
        }

        if (groupLayout.length > 0) {
          let highestY = 0,
            highestH = 0,
            totalGroupHeight = 0;

          groupLayout.forEach(({ y, h }) => {
            if (highestY <= y) {
              highestY = y;
              highestH = h;
            }
          });
          const extraHeight = 2;

          totalGroupHeight = highestH + highestY + extraHeight;

          if (element.groupTableMode) {
            const tableHeight = 10;
            totalGroupHeight += tableHeight;
          }

          if (element.h < totalGroupHeight) {
            element.h = totalGroupHeight;
            triggerUpdateLayout = true;
          }
        }
      }
    });

    return { layoutUpdated: triggerUpdateLayout, newLayout: layout };

    /*let dependenciesLayouts = this.state.dependenciesLayouts,
      callback = () => {
        this.props.updateLayout(() => {
          return { layout: layout }
        })
      }

    dependenciesLayouts.layout.forEach(element => {
      if (element.name === groupName && element.type === 'group') {
        if (element.h < totalGroupHeight) {
          element.h = totalGroupHeight
          updateDependencyLayout = true
        }
      }
    })

    if (updateDependencyLayout) {
      this.setState({ dependenciesLayouts: dependenciesLayouts }, callback)
    } else if (triggerUpdateLayout) {
      callback()
    }*/
  };

  paginateForm = () => {
    const { /*navigate, */ inDesignMode, onnPageDefinitionChange } = this.props;
    this.setState({ rePaginate: false });
    if (inDesignMode) return;
    this.paginateIfPossible().then((res: any) => {
      if (!res || res.totalPages <= 0) return;
      // const currentPage = this.findItemOnPages(res.pages, navigate);
      this.setState({
        totalPages: res.totalPages,
        pages: res.pages,
      });
      if (onnPageDefinitionChange) {
        onnPageDefinitionChange(res.pages);
      }
    });
  };

  onChangeFormValue = ({
    name,
    value,
    errors,
    isInitialValue = false,
  }: {
    name: string;
    value: any;
    errors: any;
    isInitialValue: boolean;
  }) => {
    if (this.props.onChangeFormValue) this.props.onChangeFormValue({ name, value });
    const formController = this.context;

    formController.setContext((prev) => ({ ...prev, [name]: value }));

    this.setState(
      (prevState /*{ formValues: prevFormValues, formErrors: prevFormErrors }*/) => ({
        ...prevState,
        formErrors: {
          ...prevState.formErrors,
          [name]: errors,
        },
      }),
      async () => this.checkForErrorsOnValueChange(name, isInitialValue)
    );
  };

  async checkForErrorsOnValueChange(
    name: string,
    isInitialValue: boolean,
    isGroupValueChange?: boolean
  ) {
    const formController = this.context;

    const formValues = formController.context;

    const newFormValues = formValues;

    this.dependenciesHiddenFields = [];

    const formErrors = this.state.formErrors;

    this.props.onUpdateFormValues(newFormValues, formErrors, this.state.pinnedElements);

    const { pages } = this.state;

    const formHasErrors: boolean = await this.checkErrors(formErrors);

    const pageHasErrors = await this.checkErrorsByPage(formErrors, pages, 1);
    if (this.props.onFormErrorsUpdate) {
      this.props.onFormErrorsUpdate(formHasErrors);
    }

    if (pageHasErrors !== this.state.pageHasErrors) this.setState({ pageHasErrors });

    if (isInitialValue !== true) {
      this.updateDependenciesLayoutIfApplicable(formValues, name, isGroupValueChange || false);
    }
  }

  onLayoutUpdated = () => {
    const { rePaginate } = this.state;

    if (!rePaginate) return;

    this.paginateForm();
  };

  onFormErrorOccurred = (name: string, errors: ValidationErrors) => {
    const formController = this.context;

    const formValues = formController.context;

    this.setState(
      (prevState) => ({
        ...prevState,
        formErrors: {
          ...prevState.formErrors,
          [name]: errors,
        },
      }),
      () => {
        this.props.onUpdateFormValues(formValues, this.state.formErrors, this.state.pinnedElements);
      }
    );
  };

  findItemOnPages = (pages: Array<PageTyping>, navigate: any) => {
    if (!navigate || !pages) return 1;

    const { fullGroupLayouts } = this.props;

    const page = pages.findIndex((page) => {
      const itemFound = page.content.find((i: any) => {
        if (i.name === navigate) return true;
        else if (i.type === "group") {
          return fullGroupLayouts[i.name].find((item) => item.name === navigate);
        }

        return false;
      });
      return itemFound ? true : false;
    });

    return page >= 0 ? page + 1 : 1;
  };

  filterObject = (obj: any, callback: any): any => {
    return Object.fromEntries(Object.entries(obj).filter(([key, val]) => callback(val, key)));
  };

  //this method takes care of paginating form if
  // form content height is bigger than device screen height
  paginateIfPossible = () => {
    const { useDependenciesLayout, dependenciesLayouts } = this.state;
    // const { initialPagesHeight, formDefinition } = this.props;
    const layout = useDependenciesLayout ? dependenciesLayouts.layout : this.props.layout;
    return new Promise((resolve) => {
      // We need to add 76 to formPage height to include
      // the pages tab row and next back buttons row height
      // const initialPageHeight = initialPagesHeight ? initialPagesHeight + 263 : 263;

      const PAGE_LIMIT = 500; //document.body.clientHeight - initialPageHeight;

      //this line gives us the form container
      const gridContainer: any = document.getElementsByClassName("react-grid-layout")[0];

      if (!gridContainer) return resolve(null);

      // let containerChildrenSumHeight = 0;

      // gridContainer.childNodes.forEach((node: any) => {
      //   containerChildrenSumHeight += node.scrollHeight;
      // });

      // const containerHeight =
      //   formDefinition.dependencies && formDefinition.dependencies.length > 0
      //     ? containerChildrenSumHeight
      //     : gridContainer.clientHeight;

      // If form is not rendered or
      // formContent height is shorter than device screen height
      // we return null and stop pagination immediately
      //if (containerHeight <= PAGE_LIMIT) return resolve(null);

      // this line gives us each dataElement and topic inside form container
      const gridItems = document.getElementsByClassName("react-grid-item");
      // it is used to group each topic with its dataelements
      let topics: {
        [key: string]: {
          height: number;
          elements: Array<ProDoctivityFormLayoutItem>;
        };
      } = {};
      const provisionalElements = [];
      const pages: PageTyping[] = [
        {
          limit: PAGE_LIMIT,
          content: [],
        },
      ];
      let totalPages = 1;
      let currentTopic = "";

      for (let index = 0; index < layout.length; index++) {
        const item = layout[index];

        //We check here if there are dataElements without topics
        // And If it is then we save them temporarly so we can then add them
        // under the first topic that shows
        if (item.type !== "topic" && Object.keys(topics).length <= 0) {
          if (item.type === "group") {
            const groupContainer = document.getElementById(item.i);
            item.clientHeight = groupContainer?.scrollHeight;
          } else {
            item.clientHeight = gridItems[index].scrollHeight;
          }
          provisionalElements.push(item);
          continue;
        }

        if (item.type === "topic") {
          topics[item.name] = {
            height: 0, // gridItems[index].scrollHeight,
            elements: [item],
          };
          currentTopic = item.name;
          if (Object.keys(topics).length === 1) {
            topics[item.name].elements.push(...provisionalElements);
          }
          continue;
        }

        if (item.type === "group") {
          const groupContainer = document.getElementById(item.i); //item.i represents the id
          topics[currentTopic].height += groupContainer?.scrollHeight || 0;
          topics[currentTopic].elements.push(item);
          continue;
        }
        //item.clientHeight = gridItems[index].scrollHeight;
        topics[currentTopic].elements.push(item);
      }

      topics = this.filterObject(
        topics,
        (v: any) => v.elements.findIndex((i: ProDoctivityFormLayoutItem) => i.type !== "topic") >= 0
      );

      this.calculateTopicsHeight(topics);
      for (let index = 0; index < layout.length; index++) {
        const item = layout[index];
        if (item.type !== "topic" || !topics[item.name]) continue;

        const currentPage = pages[totalPages - 1];
        const topicHeight = topics[item.name].height;
        const topicElements = topics[item.name].elements;

        // if curr page limit equals defined LIMIT then page is empty
        // so topic with elements should be added
        if (currentPage.limit === PAGE_LIMIT || currentPage.limit - topicHeight >= 0) {
          pages[totalPages - 1].content.push(...topicElements);
          pages[totalPages - 1].limit -= topicHeight;
        } else {
          // creates new page if current page is full
          // and topic to be added does not fit in
          totalPages++;
          pages[totalPages - 1] = {
            limit: PAGE_LIMIT - topicHeight,
            content: topicElements,
          };
        }
      }

      resolve({
        totalPages,
        pages,
        usedLayout: JSON.stringify(layout),
      });
    });
  };

  calculateTopicsHeight = (topics: any) => {
    Object.keys(topics).forEach((key) => {
      let tempElementAsHeight = null;
      const { elements } = topics[key];

      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];

        if (element.type === "topic" || element.type === "group") continue;

        if (!tempElementAsHeight) {
          tempElementAsHeight = {
            y: element.y,
            h: element.y + element.h,
            height: element.clientHeight,
            counted: false,
          };

          if (index === elements.length - 1) {
            topics[key].height += tempElementAsHeight.height;
            tempElementAsHeight.counted = true;
          }
          continue;
        }

        if (tempElementAsHeight.y !== element.y && !tempElementAsHeight.counted) {
          topics[key].height += tempElementAsHeight.height;
          tempElementAsHeight.counted = true;
        }

        if (element.y + element.h > tempElementAsHeight.h)
          tempElementAsHeight = {
            y: element.y,
            h: element.y + element.h,
            height: element.clientHeight,
            counted: false,
          };

        if (index === elements.length - 1) {
          if (!tempElementAsHeight.counted) {
            topics[key].height += tempElementAsHeight.height;
            tempElementAsHeight.counted = true;
          }
        }
      }
    });
  };

  next = () => {
    const formController = this.context;
    const { wizardDefinition } = formController;

    const sections = wizardDefinition.pages.flatMap((p) => p.sections);
    const idx = sections.findIndex((s) => s.key === formController.currentSection);
    if (idx < 0) {
      return;
    }
    const nextSection = sections[idx + 1];
    if (!nextSection) {
      return;
    }

    formController.setCurrentSection(nextSection.key);
  };

  prev = () => {
    const formController = this.context;
    const { wizardDefinition } = formController;

    const sections = wizardDefinition.pages.flatMap((p) => p.sections);
    const idx = sections.findIndex((s) => s.key === formController.currentSection);
    if (idx < 0) {
      return;
    }
    const previousSection = sections[idx - 1];
    if (!previousSection) {
      return;
    }

    formController.setCurrentSection(previousSection.key);
  };

  checkErrors = (errors: FormErrors): Promise<boolean> =>
    new Promise((resolve) => {
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          if (errors[key] && errors[key].length > 0) return resolve(true);
        }
      }
      resolve(false);
    });

  checkErrorsByPage = (
    errors: FormErrors,
    pages: PageTyping[] | null,
    currentPage: number
  ): Promise<boolean> =>
    new Promise((resolve) => {
      if (!pages) return resolve(false);

      const { paginate } = this.props;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          // First we make sure that form is paginated then
          //we only want the errors of dataElements for the currentPage
          //so the user can keep navigating through pages
          if (
            paginate &&
            pages[currentPage - 1].content.find((dataElement) => dataElement.name === key)
          ) {
            if (errors[key] && errors[key].length > 0) return resolve(true);
          }
        }
      }
      resolve(false);
    });

  filterFormDefinitionByGroups = () => {
    // const { pages, currentSectionKey } = this.state;
    const { groups, contextFields, allowedListValues } = this.props.formDefinition;

    // if (!pages || !pages[currentPage - 1]) {
    return {
      contextFields: contextFields,
      groups: groups,
      allowedListValues,
    };
    // }

    // const { content } = pages[currentPage - 1];
    // const filteredGroups = groups.filter((group) => {
    //   const groupFound = content.find(
    //     (item) => item.type === "group" && item.name === group.groupName
    //   );
    //   return groupFound ? true : false;
    // });

    // return {
    //   dataElements: dataElements,
    //   groups: filteredGroups,
    //   allowedListValues,
    // };
  };

  componentWillUnmount() {
    if (this.fireConnectorsPromise) {
      this.fireConnectorsPromise.cancel();
    }
  }

  //TODO: @eburgos Remove this
  fireConnectors = (dataElementName: string, groupName?: string) => {
    if (!this.props.onFireConnector) {
      return;
    }
    const formController = this.context;

    const formValues = formController.context;

    // this.fireDataLink(dataElementName, groupName);
    let formConnectors: FormConnector[] = [];
    if (this.props.formConnectors.length > 0) {
      formConnectors = [...this.props.formConnectors];
    }
    if (this.props.dataLinks && this.props.connectorDataLinks) {
      formConnectors = [
        ...formConnectors,
        ...parseConnectors(this.props.dataLinks, this.props.connectorDataLinks),
      ];
    }

    // Find connectors that should be fired
    const firedConnectors = formConnectors.filter((fc) =>
      fc.input.some((d) => d.name === dataElementName)
    );

    fireConnectorsList(
      formConnectors,
      this.props.connectors,
      this.props.onFireConnector,
      this.props.formDefinition,
      formValues,
      this.onFormDefinitionChange,
      dataElementName
    );

    if (!firedConnectors || firedConnectors.length === 0) {
      return;
    }

    // we need to also include the fired form values of groups
    const firedValues: FormValues = formValues;
    // Fire the connectors and capture their promises
    const promises = firedConnectors.map((fc) => this.props.onFireConnector(fc, firedValues));

    // Cancel the requests any previously fired connectors
    if (this.fireConnectorsPromise) {
      this.fireConnectorsPromise.cancel();
    }
    this.fireConnectorsPromise = makeCancelable(
      Promise.all(
        promises.map((p, i) =>
          p.then(
            (data) => ({ ok: true, data }),
            (error) => {
              if (this.props.onConnectorFail) this.props.onConnectorFail(firedConnectors[i], error);
              return { ok: false, error };
            }
          )
        )
      )
    );

    Promise.all([this.fireConnectorsPromise.promise])
      .then((data) => {
        const responses = data[0];
        // Capture the data
        const result: any = {};
        Array.from(responses)
          .reverse()
          .forEach((response: any, index) => {
            firedConnectors[firedConnectors.length - index - 1].output.forEach((o) => {
              // Request failed or returned no data
              if (!response.ok || response.data == null) {
                return;
              }
              const keyValue = firedConnectors[firedConnectors.length - index - 1].isDataLink
                ? o.key
                : o.name;
              const responseHasValue =
                keyValue && response.data[keyValue] != null && response.data[keyValue] !== "";
              if (keyValue && (responseHasValue || result[keyValue] == null)) {
                result[o.name] =
                  typeof response.data[keyValue] !== "undefined" ? response.data[keyValue] : null;
              }
            });
          });

        // Apply the captured data to the form
        const resultEntries = Object.entries(result);
        if (groupName) {
          const targetKey = `group__${groupName}`;
          if (!this.dependenciesHiddenFields.includes(targetKey)) {
            this.setState({
              groupDataFromConnectors: {
                groupName,
                data: {
                  id: createUuid(),
                  values: result,
                },
              },
            });
          }
        } else {
          // Apply the captured data to the form
          resultEntries.forEach(([name, value]) => {
            const targetKey = `dataElement__${name}`;
            if (!this.dependenciesHiddenFields.includes(targetKey)) {
              this.onChangeFormValue({ name, value, errors: [], isInitialValue: false });
            }
          });
        }

        // Apply the rules of the first connector that was successful, if any
        const connectorWithRulesToApplyIndex = responses.findIndex((r: any) => r.ok);
        if (connectorWithRulesToApplyIndex !== -1) {
          const connectorWithRulesToApply = firedConnectors[connectorWithRulesToApplyIndex];

          const allOutputDataElements = new Set(
            flattenDeep<string>(firedConnectors.map((fc) => fc.output.map((o) => o.name)))
          );

          const noDataFound = resultEntries.filter(([_, value]) => value != null).length === 0;
          if (noDataFound) {
            if (this.props.onNoDataFound)
              this.props.onNoDataFound(connectorWithRulesToApply.noDataMessage);

            if (connectorWithRulesToApply.shouldDisableOnNoData) {
              this.setState({
                disabledElements: Array.from(allOutputDataElements),
              });
            } else {
              this.setState({
                disabledElements: [],
              });
            }
          } else {
            if (connectorWithRulesToApply.shouldDisableOnData) {
              this.setState({
                disabledElements: Array.from(allOutputDataElements),
              });
            } else {
              this.setState({
                disabledElements: [],
              });
            }
          }
        }
      })
      .catch((err) => {
        if (err.isCanceled) {
          return;
        }
        throw err;
      });
  };

  pinAllDataElements = () => {
    const formController = this.context;

    const formValues = formController.context;

    this.setState(
      ({ pinnedElements: prevPinnedElements }) => {
        let pinnedElements: string[] = [];
        if (!prevPinnedElements || prevPinnedElements.length === 0) {
          pinnedElements = this.props.formDefinition.contextFields
            // .filter((d) => !this.valuesIsNull(formValues[d.name]))
            .map((dt) => dt.name);
        }
        return { pinnedElements };
      },
      () => {
        this.props.onUpdateFormValues(formValues, this.state.formErrors, this.state.pinnedElements);
      }
    );
  };

  onPin = (dataElementName: string) => {
    const formController = this.context;

    const formValues = formController.context;

    this.setState(
      ({ pinnedElements: prevPinnedElements }) => {
        const pinnedElements = prevPinnedElements.includes(dataElementName)
          ? prevPinnedElements.filter((p) => p !== dataElementName)
          : prevPinnedElements.concat(dataElementName);

        return { pinnedElements };
      },
      () => {
        this.props.onUpdateFormValues(formValues, this.state.formErrors, this.state.pinnedElements);
      }
    );
  };

  onFormDefinitionChange = (formDefinition: FormDefinition) => {
    const formDefinitions = this.props.formDefinition;
    formDefinitions.contextFields = formDefinition.contextFields;

    this.setState({ formDefinition: formDefinitions });
  };

  // dataElement refers to dataelement that triggers a dependency change
  updateDependenciesLayoutIfApplicable = (
    formValues: FormValues,
    firedDataElementName: string,
    isGroupValueChange: boolean
  ) => {
    const { onNavigate, onDependencyLayoutUpdate, inDesignMode, summaryMode } = this.props;

    if (!isGroupValueChange) {
      if (summaryMode && onNavigate) return onNavigate(firedDataElementName);
    }
    const dependenciesLayouts = this.state.dependenciesLayouts;

    // we need to remove the clientheight property to compare layouts
    dependenciesLayouts.layout.forEach((l) => delete l.clientHeight);

    const newDependenciesLayouts = getNewLayoutFromDependencies(
      this.props.fullLayout,
      [],
      formValues,
      this.props.fullGroupLayouts,
      this.context.context
    );

    areLayoutsEqual(dependenciesLayouts, newDependenciesLayouts).then((areEqual: boolean): void => {
      if (areEqual) return;

      this.setState({
        useDependenciesLayout: true,
        dependenciesLayouts: newDependenciesLayouts,
        totalPages: 0,
        pages: null,
        pageHasErrors: false,
        rePaginate: true,
      });

      if (onDependencyLayoutUpdate) onDependencyLayoutUpdate(newDependenciesLayouts.layout);
    });

    if (!inDesignMode && !summaryMode && onNavigate) onNavigate(firedDataElementName);
  };

  render() {
    const {
      pinnedElements,
      disabledElements,
      //FIX: REMOVE
      pages: _pages,
      totalPages,
      pageHasErrors,
      useDependenciesLayout,
      dependenciesLayouts,
      groupDataFromConnectors,
    } = this.state;
    const {
      isDesignMode,
      readOnly,
      formDefinition,
      connectors,
      formConnectors,
      summaryMode,
      updateLayout,
      onFireConnector,
      onConnectorFail,
      onNoDataFound,
      showPins,
      sequenceBehaviour: sequenceBehavior,
      i18n,
      navigate,
      onNavigate,
      onLayoutChange,
      layoutTopics,
      editingTopic,
      onChooseAlternativeQuestion,
      enableTopicEditing,
      onSubmitEditTopic,
      removeTopic,
      onGroupLayoutChange,
      //TODO: @eburgos Remove this
      fullGroupLayouts: _fullGroupLayouts,
      defaultColumns,
      componentBreakpoint,
      moment,
      paginate,
      isLoading,
      purpose,
      resources,
    } = this.props;

    const formController = this.context;
    const { contextDefinition, wizardDefinition } = formController;

    const formValues = formController.context;
    const groupValues = formController.context;

    const layout =
      useDependenciesLayout && !isDesignMode ? dependenciesLayouts.layout : this.props.layout;
    // const groupLayouts =
    //   useDependenciesLayout && !isDesignMode
    //     ? dependenciesLayouts.groupLayouts
    //     : this.props.groupLayouts;

    return (
      <FormPageComponent
        showSinglePage={!paginate}
        pagination={
          paginate
            ? {
                formHasErrors: pageHasErrors,
                total: totalPages,
                sectionKey: formController.currentSection,
                filterFormDefinitionByGroups: this.filterFormDefinitionByGroups,
              }
            : undefined
        }
        next={this.next}
        prev={this.prev}
        isDesignMode={isDesignMode}
        readOnly={readOnly}
        contextDefinition={contextDefinition}
        summaryMode={summaryMode}
        formDefinition={paginate ? this.filterFormDefinitionByGroups() : formDefinition}
        connectors={connectors}
        formConnectors={formConnectors}
        layout={isDesignMode ? this.props.layout : layout}
        formValues={formValues}
        updateLayout={updateLayout}
        onFireConnector={onFireConnector}
        onConnectorFail={onConnectorFail || noop}
        onNoDataFound={onNoDataFound}
        showPins={showPins}
        sequenceBehavior={sequenceBehavior}
        i18n={i18n}
        navigate={navigate || ""}
        onNavigate={onNavigate || noop}
        onLayoutChange={onLayoutChange}
        groupValues={groupValues || {}}
        pinnedElements={pinnedElements}
        onPin={this.onPin}
        fireConnectors={this.fireConnectors}
        disabledElements={disabledElements}
        layoutTopics={layoutTopics || new Set()}
        editingTopic={editingTopic}
        onChooseAlternativeQuestion={onChooseAlternativeQuestion}
        enableTopicEditing={enableTopicEditing}
        onSubmitEditTopic={onSubmitEditTopic}
        removeTopic={removeTopic}
        onGroupLayoutChange={onGroupLayoutChange}
        onLayoutUpdated={this.onLayoutUpdated}
        groupDataFromConnectors={groupDataFromConnectors}
        onFormErrorOccurred={this.onFormErrorOccurred}
        defaultColumns={defaultColumns}
        componentBreakpoint={componentBreakpoint}
        wizardDefinition={wizardDefinition}
        moment={moment}
        cancel={this.props.cancel}
        cancelLabel={this.props.cancelLabel}
        finish={this.props.finish}
        finishButtonLabel={this.props.finishButtonLabel}
        back={this.props.back}
        backButtonLabel={this.props.backButtonLabel}
        isLoading={isLoading}
        purpose={purpose}
        resources={resources}
      />
    );
  }
}

FormPage.contextType = FormController;
