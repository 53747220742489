import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const WordAuthorSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color || colors.neutral900) : undefined}
      width={width}
      height={height}
      viewBox="0 0 19 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_514_2207)">
        <path
          d="M18.3809 5.50129V5.66546H13.3009C13.0996 5.66546 12.9066 5.58551 12.7643 5.44318C12.6219 5.30086 12.542 5.10783 12.542 4.90656V0H12.8765L18.3809 5.50129Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M11.8854 7.21425H18.3903V22.3025C18.3903 22.7527 18.2115 23.1845 17.8931 23.5028C17.5748 23.8212 17.143 24 16.6928 24H1.69747C1.24727 24 0.815515 23.8212 0.497178 23.5028C0.17884 23.1845 0 22.7527 0 22.3025L0 1.69747C0 1.24727 0.17884 0.815515 0.497178 0.497178C0.815515 0.17884 1.24727 0 1.69747 0H10.9902V6.32215C10.9902 6.43956 11.0134 6.55582 11.0584 6.66426C11.1034 6.77269 11.1694 6.87118 11.2526 6.95406C11.3357 7.03694 11.4345 7.10258 11.543 7.14723C11.6516 7.19188 11.768 7.21466 11.8854 7.21425Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M18.3807 6.42453V6.4555C18.3807 6.65677 18.3008 6.84981 18.1585 6.99213C18.0161 7.13445 17.8231 7.21441 17.6218 7.21441H11.7364C11.5352 7.21441 11.3421 7.13445 11.1998 6.99213C11.0575 6.84981 10.9775 6.65677 10.9775 6.4555V0.759063C10.9775 0.658086 10.9976 0.558119 11.0368 0.465021C11.0759 0.371922 11.1331 0.287563 11.2053 0.216885C11.2774 0.146208 11.3629 0.0906344 11.4568 0.0534196C11.5506 0.0162047 11.651 -0.0019027 11.7519 0.000158067H11.7705C11.9718 0.000158067 12.1648 0.0801139 12.3071 0.222436C12.4495 0.364759 12.5294 0.557789 12.5294 0.759063V4.90671C12.5294 5.00637 12.5491 5.10506 12.5872 5.19713C12.6253 5.28921 12.6812 5.37287 12.7517 5.44334C12.8222 5.51381 12.9058 5.56971 12.9979 5.60785C13.09 5.64599 13.1887 5.66562 13.2883 5.66562H17.6249C17.8257 5.66644 18.0179 5.74676 18.1596 5.88899C18.3012 6.03122 18.3807 6.22379 18.3807 6.42453Z"
          fill="white"
        />
        <path
          d="M5.90113 20.4247L3.98993 12.879C3.95828 12.755 3.95542 12.6254 3.98157 12.5C4.00772 12.3747 4.06218 12.257 4.1408 12.156C4.21942 12.055 4.3201 11.9732 4.43515 11.9171C4.5502 11.861 4.67657 11.8319 4.80459 11.8321H4.90061C5.093 11.8319 5.27965 11.8975 5.42954 12.0181C5.57943 12.1387 5.68355 12.307 5.72457 12.4949L6.48657 16.0231C6.72818 17.1444 6.9543 18.3215 7.10609 19.2384H7.13706C7.29194 18.2688 7.56143 17.1723 7.83092 16.0076L8.70753 12.4702C8.75252 12.2881 8.85719 12.1263 9.00484 12.0107C9.1525 11.895 9.33463 11.8321 9.52219 11.8321H10.0178C10.2096 11.8304 10.3962 11.8948 10.5462 12.0143C10.6962 12.1339 10.8006 12.3014 10.8418 12.4887L11.644 16.0757C11.8856 17.1723 12.0808 18.1976 12.2264 19.2043H12.2543C12.4246 18.1914 12.6507 17.1382 12.9047 16.0138L13.7442 12.4856C13.7862 12.3007 13.8896 12.1354 14.0375 12.0167C14.1854 11.898 14.3692 11.833 14.5588 11.8321C14.6886 11.8316 14.8168 11.8612 14.9332 11.9186C15.0496 11.976 15.1512 12.0596 15.2298 12.1629C15.3085 12.2661 15.3621 12.3862 15.3865 12.5137C15.4109 12.6412 15.4054 12.7726 15.3704 12.8976L13.2765 20.4433C13.2276 20.6212 13.1218 20.7781 12.9752 20.89C12.8286 21.0019 12.6493 21.0626 12.4649 21.0628H11.9042C11.714 21.0626 11.5296 20.9979 11.381 20.8791C11.2324 20.7604 11.1286 20.5947 11.0865 20.4092L10.2439 16.7262C10.0116 15.7605 9.83577 14.7821 9.71734 13.7959H9.68946C9.5191 14.781 9.33634 15.6731 9.05136 16.7262L8.12209 20.4433C8.07407 20.6222 7.96803 20.7801 7.82058 20.8922C7.67314 21.0044 7.49265 21.0644 7.30743 21.0628H6.71579C6.52823 21.0628 6.3461 20.9999 6.19844 20.8842C6.05079 20.7686 5.94612 20.6068 5.90113 20.4247Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_514_2207">
          <rect width="18.381" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
