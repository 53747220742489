import { useCallback, useEffect, useMemo, useState } from "react";
import { useServices } from "../../hooks/useServices";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import type {
  DocumentCollectionDocumentView,
  DocumentCollectionFolderSortType,
  DocumentCollectionFolderView,
  EvaluateValueMapFilter,
  ParametersObject,
  WorkflowTaskActions,
  WorkflowTaskDocumentTypes,
} from "@prodoctivity/shared/src/index-types";
import {
  buildEventEmitter,
  calculateDocumentCounts,
  deepCopy,
  mapEvaluateValToContextVal,
} from "@prodoctivity/shared";
import { useDesignBreakpoint } from "@prodoctivity/design-system";
import { findDocumentInFolders } from "../../pages/DocumentCollectionInstance/utils";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { CollectionInstanceEvents, EnhancedDocumentView } from "./TaskInstructions";
import { useDocumentTypeInfoWithLatestVersion } from "../Display/hooks";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { usePaginatedDataEndpoint } from "../hooks";
import type {
  HttpGetTaskCommentsRequest,
  HttpGetTaskCommentsResponse,
  WorkflowTask,
} from "@prodoctivity/types";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";
import { useCacheManager } from "../../utils";

type TaskVisibilitySettings = {
  isViewerShow: boolean;
  isInstructionTaskShow: boolean;
  isImport: boolean;
  isGenerate: boolean;
  isScan: boolean;
  isSign: boolean;
};

export function useTaskInstructions() {
  const [folderPath, setFolderPath] = useState<Array<string>>([]);
  const { resources } = useAppTranslation();
  const [paths, setPaths] = useState<Array<string>>([]);
  const { taskId } = useParams();
  const { user } = useServices();

  const {
    getDocumentCollection,
    getDocumentCollectionConfig,
    getDocument,
    getWorkflowTaskDetails,
    updateWorkflowTaskState,
  } = useServices();
  const [currDocument, rawSetCurrDocument] = useState<DocumentCollectionDocumentView | undefined>(
    undefined
  );
  const { clearQueryCache } = useCacheManager();
  const organizationNavigate = useOrganizationNavigate();
  const [collectionTabIndex, setCollectionTabIndex] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const { breakpoint } = useDesignBreakpoint();
  const isMobileOrTablet = breakpoint === "small" || breakpoint === "medium";
  const [documentSelected, setDocumentSelected] = useState("");
  const [filterTextFieldEnabled, setFilterTextFieldEnabled] = useState(false);
  const [popoverState, setPopoverState] = useState({
    actionName: "",
    isVisible: false,
  });

  const [visibleInstructionsTask, setVisibleInstructions] = useState<TaskVisibilitySettings>({
    isViewerShow: false,
    isInstructionTaskShow: true,
    isImport: false,
    isGenerate: false,
    isScan: false,
    isSign: false,
  });
  const getInitialState = useMemo(() => {
    const savedState = localStorage.getItem("isPanelHidden");
    return savedState !== null ? JSON.parse(savedState) : true;
  }, []);
  const [isPanelHidden, setPanelHidden] = useState(getInitialState);
  const [documentsDisplay, setDocumentsDisplay] = useState({
    missingDocuments: true,
    expiredDocuments: true,
    expiringDocuments: true,
  });
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState<string>("");
  const [isSignatureInstruction, setSignatureInstruction] = useState<boolean>(false);
  const [sortSelectedOptions, SetSortSelectedOptions] = useState<{
    [pathKey: string]: DocumentCollectionFolderSortType;
  }>({
    defaultSort: {
      direction: "desc",
      type: "document-date",
    },
  });
  const [selectedTypeIds, setSelectedTypeId] = useState<{
    documentTypeId: string;
    valueMap: Record<string, EvaluateValueMapFilter> | undefined;
  }>({
    documentTypeId: "",
    valueMap: undefined,
  });

  const fetchWorkflowTask = useCallback(async () => {
    if (!taskId) return { workflowTask: undefined };

    const details = await getWorkflowTaskDetails(taskId);

    if (
      !user ||
      (details.workflowTask.username && details.workflowTask.username !== user.username)
    ) {
      return { workflowTask: undefined };
    }

    return details;
  }, [getWorkflowTaskDetails, taskId, user]);

  const { data: workflowTaskResponse, isLoading: loadingTaskDetails } = useOrganizationQuery(
    `workflow-task/${taskId || undefined}`,
    fetchWorkflowTask
  );

  const documentCollectionId = useMemo(() => {
    if (!workflowTaskResponse?.workflowTask?.collectionId) return undefined;

    return workflowTaskResponse.workflowTask.collectionId;
  }, [workflowTaskResponse]);

  const updateVisibleInstructions = useCallback((props: Partial<TaskVisibilitySettings>) => {
    setVisibleInstructions({
      isViewerShow: false,
      isInstructionTaskShow: false,
      isImport: false,
      isGenerate: false,
      isScan: false,
      isSign: false,
      ...props,
    });
  }, []);

  const fetchDocumentCollection = useCallback(async () => {
    if (!documentCollectionId) return { collection: undefined, keys: [] };
    try {
      return await getDocumentCollection(documentCollectionId);
    } catch (err: any) {
      if (err.response.status === 404) {
        return { collection: undefined, keys: [] };
      }
      throw err;
    }
  }, [documentCollectionId, getDocumentCollection]);

  const {
    data: documentCollectionResponse,
    isLoading,
    refetch,
  } = useOrganizationQuery(`document-collection/${documentCollectionId}`, fetchDocumentCollection);

  const documentSelectedState = useCallback((value: string) => {
    setDocumentSelected(value);
  }, []);

  const findFolderByWorkflowProcessId = useCallback(
    (
      folder: DocumentCollectionFolderView,
      workflowProcessId: string
    ): DocumentCollectionFolderView | undefined => {
      if (folder.workflowProcessId === workflowProcessId) {
        return folder;
      }
      if (folder.folders) {
        for (const child of folder.folders) {
          const found = findFolderByWorkflowProcessId(child, workflowProcessId);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    },
    []
  );

  const folderMatch = useMemo(() => {
    if (!documentCollectionResponse?.collection) return undefined;

    const processId = workflowTaskResponse?.workflowTask?.workflowProcessId
      ? workflowTaskResponse.workflowTask?.workflowProcessId
      : "";

    return findFolderByWorkflowProcessId(
      documentCollectionResponse.collection.rootFolder,
      processId
    );
  }, [documentCollectionResponse, findFolderByWorkflowProcessId, workflowTaskResponse]);

  const collectedDocumentsFromFolders = useMemo(() => {
    const exploreFoldersAndCollectDocuments = (folder: DocumentCollectionFolderView) => {
      const documents = folder.documents?.map((doc) => ({
        ...doc,
        valueMap: folder.valueMap,
        folderName: folder.name,
      }));

      const folderData = { documents };

      if (folder.folders && folder.folders.length > 0) {
        folder.folders.forEach((subFolder: DocumentCollectionFolderView) => {
          const subFolderData = exploreFoldersAndCollectDocuments(subFolder);
          folderData.documents = folderData.documents.concat(subFolderData.documents);
        });
      }
      return folderData;
    };

    const initialFolderData = {
      documents:
        folderMatch?.documents?.map((doc) => ({
          ...doc,
          valueMap: folderMatch?.valueMap,
          folderName: folderMatch?.name,
        })) || [],
    };

    if (folderMatch && folderMatch.folders) {
      folderMatch.folders.forEach((folder) => {
        const nestedFolderData = exploreFoldersAndCollectDocuments(folder);
        initialFolderData.documents = initialFolderData.documents.concat(
          nestedFolderData.documents
        );
      });
    }

    return initialFolderData;
  }, [folderMatch]);

  const thisFolder = useMemo(() => {
    if (!documentCollectionResponse?.collection) {
      return undefined;
    }

    return {
      ...(folderMatch || documentCollectionResponse.collection.rootFolder),
      name: folderMatch ? folderMatch.name : documentCollectionResponse.collection.name,
    };
  }, [documentCollectionResponse, folderMatch]);

  const showPanel = useCallback(() => {
    setPanelHidden(false);
  }, []);

  const hidePanel = useCallback(() => {
    setPanelHidden(true);
  }, []);

  const setCurrDocument = useCallback(
    (docView: DocumentCollectionDocumentView | undefined) => {
      rawSetCurrDocument(docView);
      if (isSignatureInstruction) {
        updateVisibleInstructions({
          isInstructionTaskShow: false,
        });
        setSignatureInstruction(false);
      }

      if (breakpoint === "small" || breakpoint === "medium") {
        showPanel();
      }
    },
    [isSignatureInstruction, breakpoint, updateVisibleInstructions, showPanel]
  );
  const documentCollectionResponseContext = useMemo(() => {
    const contextValue: ParametersObject = {};

    if (documentCollectionResponse?.keys) {
      documentCollectionResponse.keys.forEach((keyItem) => {
        const contextVal = mapEvaluateValToContextVal(keyItem.type, keyItem.value);
        contextValue[keyItem.fieldName] = contextVal;
      });
    }
    return contextValue;
  }, [documentCollectionResponse]);

  const clearAllFilters = () => {
    setFilterValue("");
    setDocumentsDisplay({
      missingDocuments: false,
      expiredDocuments: false,
      expiringDocuments: false,
    });
    SetSortSelectedOptions({
      defaultSort: {
        direction: "desc",
        type: "document-date",
      },
    });
  };

  const fetchDocumentCollectionConfig = useCallback(async () => {
    if (!documentCollectionResponse || !documentCollectionResponse.collection) {
      return {
        config: undefined,
      };
    }

    return getDocumentCollectionConfig(
      documentCollectionResponse.collection.documentCollectionConfigId
    );
  }, [documentCollectionResponse, getDocumentCollectionConfig]);
  const { data: configResponse } = useOrganizationQuery(
    `document-collection-config/${documentCollectionResponse?.collection?.documentCollectionConfigId}`,
    fetchDocumentCollectionConfig,
    {
      enabled: !isLoading,
    }
  );

  useEffect(() => {
    if (
      configResponse &&
      configResponse.config &&
      folderMatch &&
      documentCollectionResponse?.collection
    ) {
      const resolvedDocumentTypeId =
        selectedDocumentTypeId.length > 0
          ? selectedDocumentTypeId
          : configResponse.config.masterDocumentTypeId;
      const documentFound = findDocumentInFolders(
        [
          {
            ...folderMatch,
            name: documentCollectionResponse.collection.name,
          },
        ],
        resolvedDocumentTypeId,
        "documentTypeId"
      );
      setFolderPath(documentFound.path);
      setCurrDocument(documentFound.document);
      localStorage.setItem("isPanelHidden", JSON.stringify(isPanelHidden));
    }
  }, [
    documentCollectionResponse,
    folderMatch,
    configResponse,
    setCurrDocument,
    selectedDocumentTypeId,
    isSignatureInstruction,
    isPanelHidden,
  ]);

  const fetchDocument = useCallback(async () => {
    if (!currDocument || currDocument.type === "missing-document") return { document: undefined };
    return getDocument(currDocument.documentId, currDocument.documentVersionId);
  }, [currDocument, getDocument]);

  const documentQueryKey = useMemo(() => {
    if (!currDocument || currDocument.type === "missing-document") return "missing-document";
    return `documents/${currDocument.documentId}/${currDocument.documentVersionId}`;
  }, [currDocument]);
  const { data: documentResponse, isLoading: isLoadingDocument } = useOrganizationQuery(
    `${documentQueryKey}`,
    fetchDocument,
    {
      enabled: currDocument && currDocument.type === "document",
    }
  );

  const globalDocumentsCount = useMemo(() => {
    if (!folderMatch) {
      return { documentCount: 0, issueCount: 0, toExpireCount: 0, expiredCount: 0 };
    }

    const rootFolder = deepCopy(folderMatch);

    calculateDocumentCounts(rootFolder, []);

    return {
      issueCount: rootFolder.counts.issueCount,
      expiredCount: rootFolder.counts.expiredCount,
      toExpireCount: rootFolder.counts.toExpireCount,
      documentCount: rootFolder.counts.documentCount,
    };
  }, [folderMatch]);

  const tabs = useMemo(() => {
    return [{ href: "#", text: resources.view }];
  }, [resources.view]);

  const eventEmitter = useMemo(() => {
    return buildEventEmitter<CollectionInstanceEvents, keyof CollectionInstanceEvents>();
  }, []);

  const subscribe = useCallback(
    (eventType: keyof CollectionInstanceEvents, handler: () => void) => {
      eventEmitter.on(eventType, handler);

      return {
        unsubscribe() {
          eventEmitter.off(eventType, handler);
        },
      };
    },
    [eventEmitter]
  );
  const checkFolder = useCallback(
    (
      folder: DocumentCollectionFolderView,
      fullPath: string[],
      filter: string,
      paths: Set<string>
    ): boolean => {
      if (!filter) {
        return true;
      }
      const result =
        folder.folders.some((subFolder) =>
          checkFolder(subFolder, [...fullPath, folder.name], filter, paths)
        ) || folder.documents.some((doc) => doc.name.toLowerCase().includes(filter.toLowerCase()));
      if (result) {
        paths.add([...fullPath, folder.name].toString());
      }
      return result;
    },
    []
  );

  const { data } = useDocumentTypeInfoWithLatestVersion(
    selectedTypeIds.documentTypeId,
    !!selectedTypeIds.documentTypeId
  );

  const handleDocumentAction = useCallback(
    (Import: boolean, isGenerated: boolean) => {
      updateVisibleInstructions({
        isImport: Import && !isGenerated ? true : false,
        isGenerate: isGenerated,
        isScan: !Import && !isGenerated ? true : false,
      });
    },
    [updateVisibleInstructions]
  );

  const DocumentViewAction = useCallback(
    (isSign: boolean) => {
      setSelectedTypeId({ documentTypeId: "", valueMap: undefined });
      updateVisibleInstructions({
        isViewerShow: !isSign ? true : false,
        isSign: isSign ? true : false,
      });
    },
    [updateVisibleInstructions]
  );

  const createValueMapContextAndToggleVisibility = useMemo(() => {
    const context: ParametersObject = {};

    const getValueMapContext = () => {
      for (const key in selectedTypeIds.valueMap) {
        if (selectedTypeIds.valueMap.hasOwnProperty(key)) {
          const contextVal = mapEvaluateValToContextVal(
            selectedTypeIds.valueMap[key].type,
            selectedTypeIds.valueMap[key].value
          );

          if (contextVal !== undefined) {
            context[key] = contextVal;
          }
        }
      }

      return context;
    };

    const valueMapContext = getValueMapContext();

    return valueMapContext;
  }, [selectedTypeIds.valueMap]);

  const saveWorkflowTask = useCallback(
    (action: { state: "open" | "done"; action: string; comment: string }) => {
      const id = taskId ? taskId : "";
      return updateWorkflowTaskState(id, action.state, action.action, action.comment);
    },
    [updateWorkflowTaskState, taskId]
  );

  const { mutate } = useMutation(saveWorkflowTask, {
    onSuccess: () => {
      setPopoverState({
        actionName: "",
        isVisible: false,
      });
      updateVisibleInstructions({
        isInstructionTaskShow: true,
      });
      setTimeout(() => {
        organizationNavigate(organizationLinkTemplates.tasks("unclaimed"));
      }, 500);
    },
    onError(err) {
      setPopoverState({
        actionName: "",
        isVisible: false,
      });
      updateVisibleInstructions({
        isInstructionTaskShow: true,
      });
      setTimeout(() => {
        organizationNavigate(organizationLinkTemplates.tasks("unclaimed"));
      }, 500);
      throw err;
    },
  });

  const visiblePanelIndices = useMemo(() => {
    return isPanelHidden ? [1, 30] : [2, 5];
  }, [isPanelHidden]);

  const handleShowInstructionTask = useCallback(() => {
    setSelectedDocumentTypeId("");
    setSelectedTypeId({ documentTypeId: "", valueMap: undefined });
    updateVisibleInstructions({
      isInstructionTaskShow: true,
    });
    clearQueryCache();
    refetch();
  }, [clearQueryCache, refetch, updateVisibleInstructions]);

  const resetAndShowSignatureInstructions = useCallback(() => {
    clearQueryCache();
    setSignatureInstruction(true);
    updateVisibleInstructions({
      isSign: false,
    });

    setTimeout(() => {
      updateVisibleInstructions({
        isSign: true,
      });
    }, 2000);
  }, [clearQueryCache, updateVisibleInstructions]);

  const validateDate = useCallback((date: number | undefined): string | undefined => {
    if (!date) return undefined;
    const now = new Date();
    const pastDate = new Date(date);

    return now > pastDate ? "expired-document" : "expiring-document";
  }, []);

  return {
    thisFolder,
    folderPath,
    setFolderPath,
    currDocument,
    filterValue,
    setFilterValue,
    setCurrDocument,
    isPanelHidden,
    setPanelHidden,
    sortSelectedOptions,
    SetSortSelectedOptions,
    setDocumentsDisplay,
    documentsDisplay,
    documentCollectionResponseContext,
    setDocumentSelected,
    documentSelected,
    documentSelectedState,
    collectionTabIndex,
    setCollectionTabIndex,
    filterTextFieldEnabled,
    setFilterTextFieldEnabled,
    clearAllFilters,
    fetchDocument,
    documentQueryKey,
    ecmDocument: documentResponse?.document,
    isLoadingDocument,
    globalDocumentsCount,
    hidePanel,
    showPanel,
    folderView: collectedDocumentsFromFolders.documents,
    documentCollection: documentCollectionResponse?.collection,
    collectionConfig: configResponse?.config,
    refetch,
    paths,
    setPaths,
    tabs,
    eventEmitter,
    subscribe,
    checkFolder,
    visibleInstructionsTask,
    setSelectedDocumentTypeId,
    selectedDocumentTypeId,
    handleDocumentAction,
    setSelectedTypeId,
    DocumentViewAction,
    documentTypeInfo: data?.documentType,
    createValueMapContextAndToggleVisibility,
    selectedTypeIds,
    workflowTaskResponse,
    saveTaskInstructions: mutate,
    popoverState,
    setPopoverState,
    taskId,
    loadingTaskDetails,
    isLoading,
    visiblePanelIndices,
    handleShowInstructionTask,
    resetAndShowSignatureInstructions,
    updateVisibleInstructions,
    isSignatureInstruction,
    validateDate,
    isMobileOrTablet,
  };
}

export function useTaskCommentsManager({
  taskId,
  folderView,
  workflowTaskResponse,
  validateDate,
}: {
  taskId: string;
  folderView: EnhancedDocumentView[];
  workflowTaskResponse:
    | {
        workflowTask: WorkflowTask<number> & {
          id: string;
        };
      }
    | {
        workflowTask: undefined;
      }
    | undefined;
  validateDate: (date: number | undefined) => string | undefined;
}) {
  const { insertTaskComment, updateTaskComment, user } = useServices();
  const { resources } = useAppTranslation();

  const getInitialFilter = useMemo(() => {
    const savedFilter = localStorage.getItem("filter");
    return savedFilter !== null ? savedFilter : resources.documents;
  }, [resources.documents]);

  const [filter, setFilter] = useState<string>(getInitialFilter);

  const memoizedProcessId = useMemo(() => {
    if (!workflowTaskResponse?.workflowTask?.processId) return undefined;

    return workflowTaskResponse?.workflowTask?.processId;
  }, [workflowTaskResponse?.workflowTask?.processId]);

  const {
    paginatedData: comments,
    isLoading: isLoadingComments,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount: commentsTotal,
    refetch: refetchComments,
  } = usePaginatedDataEndpoint<
    HttpGetTaskCommentsResponse["payload"],
    HttpGetTaskCommentsRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage) => {
      return services.getTaskComments(
        undefined,
        memoizedProcessId,
        currentPage,
        rowsPerPage,
        undefined
      );
    },
    `workflow/task-comments/${taskId}`
  );

  const { mutate: insertComment } = useMutation(insertTaskComment, {
    onSuccess: () => {
      refetchComments();
    },
    onError: () => {
      refetchComments();
    },
  });

  const addComment = useCallback(
    (arg: {
      taskId: string | undefined;
      processId: string | undefined;
      content: string;
      parentCommentId: string | undefined;
      commentSubtitle: string | undefined;
    }) => {
      return insertComment(arg);
    },
    [insertComment]
  );

  const { mutate: updateComment } = useMutation(updateTaskComment, {
    onSuccess: () => {
      refetchComments();
    },
    onError: () => {
      refetchComments();
    },
  });

  const updateCommentList = useCallback(
    (arg: { content: string; taskCommentId: string }) => {
      return updateComment(arg);
    },
    [updateComment]
  );

  const getActionButtonLabel = useCallback(
    (actionName: string) => {
      return actionName.toLowerCase() === "approve" ? resources.approve : resources.reject;
    },
    [resources]
  );

  const getDocumentsInFolderView = (
    actions: (WorkflowTaskDocumentTypes | WorkflowTaskActions)[],
    folderView: EnhancedDocumentView[]
  ) => {
    const documentTypeIds = actions
      .filter(
        (action): action is WorkflowTaskDocumentTypes => action.type === "workflow-document-types"
      )
      .map((action) => action.documentTypeId);

    if (documentTypeIds.length === 0) {
      return folderView;
    }

    return folderView.filter((folder) => documentTypeIds.includes(folder.documentTypeId));
  };

  const filteredDocuments = useMemo(() => {
    const actions = workflowTaskResponse?.workflowTask?.actions;
    if (!actions || !folderView) {
      return [];
    }
    localStorage.setItem("filter", filter);
    return getDocumentsInFolderView(actions, folderView);
  }, [workflowTaskResponse?.workflowTask?.actions, folderView, filter]);

  const disableApprove = useMemo(() => {
    const actions =
      workflowTaskResponse?.workflowTask?.actions?.filter(
        (action): action is WorkflowTaskDocumentTypes => action.type === "workflow-document-types"
      ) ?? [];
    if (!actions || actions.length === 0) return false;

    for (const action of actions) {
      if (action.isRequired) {
        const document = filteredDocuments.find(
          (doc) => doc.documentTypeId === action.documentTypeId
        );
        if (document && document.type === "missing-document") {
          return true;
        }
      }

      if (action.requireNotBeExpired) {
        const hasExpiredDocuments = filteredDocuments.some(
          (doc) =>
            doc.type === "document" && validateDate(doc.expirationDate) === "expired-document"
        );

        return hasExpiredDocuments;
      }
    }
    return false;
  }, [filteredDocuments, validateDate, workflowTaskResponse?.workflowTask?.actions]);

  const getFilteredDocumentsByStatus = useMemo(() => {
    if (filter === resources.document) {
      return filteredDocuments;
    }
    if (filter === resources.toExpire) {
      return filteredDocuments.filter(
        (doc) => doc.type === "document" && validateDate(doc.expirationDate) === "expiring-document"
      );
    }
    if (filter === resources.missing) {
      return filteredDocuments.filter((doc) => doc.type === "missing-document");
    }
    if (filter === resources.expired) {
      return filteredDocuments.filter(
        (doc) => doc.type === "document" && validateDate(doc.expirationDate) === "expired-document"
      );
    } else {
      return filteredDocuments;
    }
  }, [
    filter,
    filteredDocuments,
    resources.document,
    resources.expired,
    resources.missing,
    resources.toExpire,
    validateDate,
  ]);

  return {
    addComment,
    comments,
    isLoadingComments,
    updateCommentList,
    user,
    memoizedProcessId,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    getActionButtonLabel,
    commentsTotal,
    refetchComments,
    pageLengthOptions: ["15", "50", "100"] as Array<typeof rowsPerPage>,
    disableApprove,
    filteredDocuments,
    getFilteredDocumentsByStatus,
    filter,
    setFilter,
  };
}
