import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
  color?: ColorConfig;
};

export const EditIconsSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 31,
  height = 29,
  onClick,
  color,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      className={customClassName || undefined}
      style={svgStyle}
      width="31"
      height="29"
      viewBox={`0 0 31 29`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_6533_102927)">
        <path
          d="M6.31087 0.40918V5.92242H0.889648C2.85337 3.92537 4.21288 2.52572 6.31087 0.40918Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M14.1489 16.8811C14.4007 16.113 14.837 15.4303 15.4245 14.9011L23.5311 7.44203C23.6654 7.32254 23.7493 7.15185 23.7493 6.9641V0.665686C23.7493 0.290171 23.464 0 23.0947 0H8.64375C8.2745 0 7.98917 0.290171 7.98917 0.665686V6.9641C7.98917 7.33961 7.70384 7.62978 7.3346 7.62978H0.654575C0.285328 7.62978 0 7.91995 0 8.29547V28.3343C0 28.7098 0.285328 29 0.654575 29H23.0947C23.464 29 23.7493 28.7098 23.7493 28.3343V20.2095C23.7493 19.6463 23.078 19.339 22.6751 19.7145L21.0471 21.2166C20.4597 21.7628 19.738 22.1383 18.9659 22.309L15.542 23.0942C15.3909 23.1283 15.2231 23.1454 15.0552 23.1454C14.9377 23.1454 14.8202 23.1283 14.7028 23.1112H14.686C14.686 23.1112 14.5853 23.1283 14.5181 23.1283H3.60855C3.1386 23.1283 2.76936 22.7528 2.76936 22.2749C2.76936 21.7969 3.1386 21.4214 3.60855 21.4214H12.9572C12.8733 21.0459 12.8901 20.6363 13.0244 20.2607L14.1489 16.8811ZM9.96968 17.4614H3.60855C3.1386 17.4614 2.76936 17.0859 2.76936 16.608C2.76936 16.1301 3.1386 15.7546 3.60855 15.7546H9.96968C10.4228 15.7546 10.8089 16.1471 10.8089 16.608C10.8089 17.0689 10.4228 17.4614 9.96968 17.4614ZM9.96968 12.392H3.60855C3.1386 12.392 2.76936 11.9994 2.76936 11.5386C2.76936 11.0777 3.1386 10.6851 3.60855 10.6851H9.96968C10.4228 10.6851 10.8089 11.0606 10.8089 11.5386C10.8089 12.0165 10.4228 12.392 9.96968 12.392Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M30.9831 8.84184C30.916 9.60994 30.5467 10.2927 29.8922 10.7877L23.7492 16.4204L19.7882 20.0561C19.5029 20.3292 19.1504 20.5169 18.7644 20.6023L15.1726 21.4216C14.9209 21.4899 14.7698 21.3362 14.7195 21.285C14.6691 21.2168 14.5348 21.0461 14.6188 20.8071L15.7936 17.2568C15.9279 16.8813 16.1461 16.5399 16.4314 16.2668L23.7492 9.55873L26.5857 6.94719C27.0892 6.40099 27.7774 6.09375 28.5159 6.09375C29.2544 6.09375 29.909 6.38392 30.3621 6.89599C30.8321 7.40805 31.0503 8.12494 30.9831 8.84184Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6533_102927">
          <rect width="31" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
