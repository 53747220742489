import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
};

export const DocumentNoResultsSVG: FunctionComponent<Props> = ({ width = 635, height = 488 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 635 488"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6070_122409)">
        <path
          d="M627.486 441.362H7.51411C3.36418 441.362 0 444.726 0 448.875V448.889C0 453.038 3.36418 456.402 7.51411 456.402H627.486C631.636 456.402 635 453.038 635 448.889V448.875C635 444.726 631.636 441.362 627.486 441.362Z"
          fill="#CACBCE"
        />
        <path
          d="M193.517 225.176C234.28 225.176 267.325 191.149 267.325 149.176C267.325 107.202 234.28 73.1758 193.517 73.1758C152.754 73.1758 119.709 107.202 119.709 149.176C119.709 191.149 152.754 225.176 193.517 225.176Z"
          stroke="#B3B3B3"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M604.112 441.377C604.112 280.674 473.805 150.395 313.069 150.395C152.333 150.395 22.0261 280.674 22.0261 441.377H604.112Z"
          fill="#E9E9E9"
        />
        <path
          d="M431.961 130.581H196.199C183.179 130.581 172.624 141.134 172.624 154.151V449.75C172.624 462.767 183.179 473.319 196.199 473.319H431.961C444.981 473.319 455.536 462.767 455.536 449.75V154.151C455.536 141.134 444.981 130.581 431.961 130.581Z"
          fill="#F6F6F6"
        />
        <path
          d="M431.961 130.581H196.199C183.179 130.581 172.624 141.134 172.624 154.151V449.75C172.624 462.767 183.179 473.319 196.199 473.319H431.961C444.981 473.319 455.536 462.767 455.536 449.75V154.151C455.536 141.134 444.981 130.581 431.961 130.581Z"
          stroke="#CACBCE"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M376.279 210.322H228.449C224.299 210.322 220.935 213.686 220.935 217.835V217.849C220.935 221.998 224.299 225.362 228.449 225.362H376.279C380.429 225.362 383.793 221.998 383.793 217.849V217.835C383.793 213.686 380.429 210.322 376.279 210.322Z"
          fill="#CACBCE"
        />
        <path
          d="M328.327 249.348H228.449C224.299 249.348 220.935 252.711 220.935 256.86V256.875C220.935 261.024 224.299 264.387 228.449 264.387H328.327C332.477 264.387 335.841 261.024 335.841 256.875V256.86C335.841 252.711 332.477 249.348 328.327 249.348Z"
          fill="#CACBCE"
        />
        <path
          d="M327.108 288.373H227.23C223.08 288.373 219.716 291.737 219.716 295.886V295.9C219.716 300.049 223.08 303.412 227.23 303.412H327.108C331.258 303.412 334.622 300.049 334.622 295.9V295.886C334.622 291.737 331.258 288.373 327.108 288.373Z"
          fill="#CACBCE"
        />
        <path
          d="M328.327 327.384H228.449C224.299 327.384 220.935 330.747 220.935 334.896V334.911C220.935 339.06 224.299 342.423 228.449 342.423H328.327C332.477 342.423 335.841 339.06 335.841 334.911V334.896C335.841 330.747 332.477 327.384 328.327 327.384Z"
          fill="#CACBCE"
        />
        <path
          d="M328.327 366.408H228.449C224.299 366.408 220.935 369.772 220.935 373.921V373.935C220.935 378.084 224.299 381.448 228.449 381.448H328.327C332.477 381.448 335.841 378.084 335.841 373.935V373.921C335.841 369.772 332.477 366.408 328.327 366.408Z"
          fill="#CACBCE"
        />
        <path d="M333.403 392.387H220.935V410.681H333.403V392.387Z" fill="white" />
        <path
          d="M193.517 209.104C225.663 209.104 251.723 182.273 251.723 149.175C251.723 116.078 225.663 89.2471 193.517 89.2471C161.371 89.2471 135.312 116.078 135.312 149.175C135.312 182.273 161.371 209.104 193.517 209.104Z"
          fill="#B3B3B3"
        />
        <path
          d="M193.517 192.028C216.5 192.028 235.131 172.842 235.131 149.175C235.131 125.508 216.5 106.322 193.517 106.322C170.534 106.322 151.903 125.508 151.903 149.175C151.903 172.842 170.534 192.028 193.517 192.028Z"
          fill="#858585"
        />
        <path
          d="M204.215 149.176L211.858 141.305C214.812 138.266 214.812 133.334 211.858 130.294C208.904 127.255 204.114 127.255 201.16 130.294L193.517 138.165L185.874 130.294C182.92 127.255 178.13 127.255 175.176 130.294C172.222 133.334 172.222 138.266 175.176 141.305L182.82 149.176L175.176 157.047C172.222 160.086 172.222 165.018 175.176 168.058C178.13 171.097 182.92 171.097 185.874 168.058L193.517 160.187L201.16 168.058C204.114 171.097 208.904 171.097 211.858 168.058C214.812 165.018 214.812 160.086 211.858 157.047L204.215 149.176Z"
          fill="white"
        />
        <path
          d="M101.756 375.283C105.212 376.703 105.829 381.334 102.86 383.599L97.0239 388.086L91.1876 392.574C88.2335 394.853 83.9172 393.076 83.4297 389.377L82.4546 382.079L81.4794 374.782C80.9919 371.083 84.6772 368.244 88.1332 369.663L94.9446 372.473L101.756 375.283Z"
          fill="#B3B3B3"
        />
        <path
          d="M92.9227 217.936C93.5537 219.455 92.3061 221.075 90.6857 220.86L87.4735 220.445L84.2614 220.029C82.641 219.814 81.8523 217.921 82.8418 216.617L84.8063 214.05L86.7709 211.484C87.7603 210.179 89.7966 210.452 90.4276 211.957L91.6751 214.939L92.9227 217.921V217.936Z"
          fill="#B3B3B3"
        />
        <path
          d="M496.835 157.621C495.702 160.388 491.988 160.875 490.167 158.509L486.567 153.836L482.968 149.162C481.147 146.796 482.567 143.341 485.535 142.94L491.386 142.165L497.236 141.391C500.205 140.99 502.485 143.957 501.338 146.724L499.086 152.187L496.835 157.649V157.621Z"
          fill="#B3B3B3"
        />
        <path
          d="M439.088 79.857C439.088 95.5702 426.369 108.287 410.666 108.287H287.472C279.628 108.287 272.501 105.104 267.353 99.9717C262.22 94.8247 259.036 87.6992 259.036 79.857C259.036 76.9035 259.495 74.0505 260.327 71.3838C261.718 75.7996 264.17 79.7709 267.353 82.9681C272.501 88.115 279.628 91.2835 287.472 91.2835H410.666C423.415 91.2835 434.198 82.8964 437.797 71.3408C438.629 74.0218 439.088 76.8749 439.088 79.8426V79.857Z"
          fill="#CACBCE"
        />
        <path
          d="M437.797 71.3545C434.198 82.91 423.414 91.2971 410.666 91.2971H287.472C279.628 91.2971 272.501 88.1287 267.353 82.9817C264.17 79.7703 261.718 75.8133 260.327 71.3975C261.99 66.0498 265.188 61.376 269.418 57.9065C268.386 55.0534 267.826 51.971 267.826 48.7452C267.826 46.2075 268.171 43.7416 268.83 41.4047C268.945 41.0032 269.045 40.6161 269.189 40.2434C272.759 29.462 282.912 21.6771 294.9 21.6771C299.173 21.6771 303.232 22.6663 306.831 24.4441C312.882 10.0928 327.093 0.0283203 343.642 0.0283203C361.896 0.0283203 377.269 12.2577 382.044 28.9746C384.267 28.3867 386.59 28.057 389.013 28.057C402.894 28.057 414.309 38.5086 415.886 51.9423C422.482 53.1753 428.29 56.6735 432.449 61.6197C434.829 64.4584 436.665 67.7559 437.797 71.3688V71.3545Z"
          fill="#E9E9E9"
        />
        <path
          d="M458.519 414.48C458.356 414.27 458.179 414.155 457.988 414.136C462.692 420.401 467.18 426.078 472.199 432.086C467.467 426.107 462.978 420.43 458.519 414.48ZM474.479 434.638C473.733 433.691 472.988 432.745 472.199 432.086C481.635 444.344 491.4 456.344 501.122 468.63C492.146 457.29 483.169 445.949 474.479 434.638Z"
          fill="#5A5A5A"
        />
        <path
          d="M545.161 458.896C544.73 457.706 544.3 456.502 543.54 455.556C539.582 450.237 536.055 446.108 533.818 443.269C519.822 425.907 506.113 408.588 492.146 390.939C491.372 390.28 490.611 389.334 489.866 388.387C489.149 387.169 488.962 386.28 490.224 385.29C490.54 385.047 490.884 384.502 491.228 383.972C504.679 369.233 512.738 351.584 516.165 331.972C518.015 321.032 518.13 310.179 516.524 299.412C513.67 282.208 507.447 266.896 497.824 253.764C496.333 251.871 494.827 249.979 493.307 248.373C491.056 245.534 488.79 242.982 486.166 240.961C482.854 237.434 479.484 234.466 475.798 231.742C467.108 225.305 458.261 220.258 448.366 216.817C441.225 214.265 433.424 212.502 425.523 211.584C419.313 210.868 412.76 210.667 406.379 211.355C399.481 211.699 392.469 212.875 385.686 214.667C375.907 217.534 366.887 221.348 358.856 226.409C349.349 231.885 341.061 239.212 333.733 248.086C330.105 252.23 327.007 256.731 324.226 260.989C317.486 272.215 312.969 283.986 310.918 296.617C309.398 304.731 308.982 313.262 309.656 322.208C310.158 327.699 310.947 333.219 312.323 338.538C315.622 354.366 322.576 368.33 332.901 380.402C334.364 382.581 336.386 384.817 338.164 386.739C339.698 388.344 341.247 389.678 343.068 391.312C343.814 392.258 344.875 392.946 345.907 393.936C364.865 410.437 387.379 418.509 412.516 418.853C416.775 419.068 420.834 418.681 424.92 418.007C434.456 417.118 443.892 414.782 452.883 411.255C454.389 410.566 455.235 410.667 456.239 411.929L457.988 414.136C458.179 414.155 458.356 414.27 458.519 414.48C462.979 420.43 467.467 426.108 472.199 432.086C472.973 432.746 473.733 433.692 474.479 434.638C483.169 445.95 492.146 457.29 501.123 468.631C504.607 473.047 507.819 477.42 510.788 481.477C510.788 481.477 511.075 481.506 511.032 481.792C511.806 482.452 512.595 483.126 513.656 483.814C522.905 490.323 535.366 488.918 542.221 481.706C547.498 475.742 548.832 466.753 545.161 458.882V458.896Z"
          fill="#5A5A5A"
        />
        <path
          d="M412.316 398.537C458.852 398.537 496.577 360.82 496.577 314.294C496.577 267.767 458.852 230.05 412.316 230.05C365.779 230.05 328.054 267.767 328.054 314.294C328.054 360.82 365.779 398.537 412.316 398.537Z"
          fill="#E9E9E9"
        />
        <path
          d="M478.437 276C467.61 267.254 454.245 261.39 439.403 259.656C397.502 254.767 359.573 284.788 354.683 326.695C352.704 343.699 356.461 360.057 364.449 373.835C344.674 357.921 333.288 332.53 336.443 305.462C341.333 263.57 379.262 233.562 421.177 238.437C446.057 241.333 466.75 255.885 478.451 276H478.437Z"
          fill="#B7B7B7"
        />
        <path
          d="M488.217 323.126C483.327 365.033 445.383 395.04 403.482 390.165C388.64 388.431 375.29 382.567 364.463 373.836H364.449C356.462 360.043 352.704 343.685 354.683 326.681C359.573 284.775 397.502 254.768 439.404 259.642C454.26 261.377 467.61 267.241 478.437 275.986C486.424 289.735 490.21 306.094 488.217 323.126Z"
          fill="#CACBCE"
        />
        <path
          d="M405.805 340.688L405.504 336.76C404.601 328.588 407.325 319.67 414.882 310.595C421.694 302.581 425.465 296.674 425.465 289.878C425.465 282.165 420.618 277.018 411.097 276.875C405.647 276.875 399.596 278.695 395.825 281.563L392.197 272.029C397.187 268.401 405.805 265.979 413.821 265.979C431.215 265.979 439.088 276.717 439.088 288.215C439.088 298.495 433.338 305.907 426.082 314.523C419.428 322.394 417.005 329.047 417.463 336.76L417.621 340.688H405.819H405.805ZM402.478 361.864C402.478 356.272 406.264 352.33 411.555 352.33C416.847 352.33 420.475 356.258 420.475 361.864C420.475 367.154 416.99 371.24 411.398 371.24C406.106 371.24 402.478 367.154 402.478 361.864Z"
          fill="#858585"
        />
        <path
          d="M399.18 341.792L398.879 337.863C397.976 329.691 400.7 320.774 408.257 311.699C415.069 303.684 418.84 297.777 418.84 290.982C418.84 283.268 413.993 278.121 404.472 277.978C399.022 277.978 392.971 279.799 389.2 282.666L385.572 273.132C390.562 269.505 399.18 267.082 407.196 267.082C424.59 267.082 432.463 277.82 432.463 289.319C432.463 299.598 426.713 307.01 419.457 315.627C412.803 323.498 410.38 330.15 410.838 337.863L410.996 341.792H399.194H399.18ZM395.853 362.967C395.853 357.376 399.639 353.433 404.93 353.433C410.222 353.433 413.85 357.362 413.85 362.967C413.85 368.258 410.365 372.344 404.773 372.344C399.481 372.344 395.853 368.258 395.853 362.967Z"
          fill="#5A5A5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_6070_122409">
          <rect width="635" height="488" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
