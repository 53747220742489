import { Box, Skeleton } from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { RoleManagementState, useUserTab } from "../hooks";

import type { HttpListAllOrganizationUsersResponse } from "@prodoctivity/types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { ComboBoxWithSelectedTag } from "../../ApprovalType/ComboBoxWithSelectedTag";

type UsersTabProps = {
  formState: RoleManagementState;
  isLoading: boolean;
  users: HttpListAllOrganizationUsersResponse["payload"]["users"];
  setFormState: Dispatch<SetStateAction<RoleManagementState>>;
};

export const UsersTab: FunctionComponent<UsersTabProps> = ({
  formState,
  isLoading,
  users,
  setFormState,
}) => {
  const { usersOptions, selectedUsers, onSelectUser, onRemoveUser } = useUserTab(
    formState,
    users,
    setFormState
  );
  const { resources } = useAppTranslation();

  return (
    <Skeleton height={400} show={isLoading}>
      <Box paddingTop={4}>
        <ComboBoxWithSelectedTag
          options={usersOptions}
          isLoading={isLoading}
          onRemove={onRemoveUser}
          onSelect={onSelectUser}
          selectedOptions={selectedUsers}
          availableLabel={resources.rolesManagement.availableUsers}
          placeholder={resources.rolesManagement.selectAUserToInclude}
          selectedLabel={resources.rolesManagement.roleMembers}
        />
      </Box>
    </Skeleton>
  );
};
