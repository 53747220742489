import { calculateColor, type ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DocumentCollectionReportWidgetIconSvg: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 40,
  height = 40,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10.3999 15.7998C10.3999 15.0042 10.716 14.2411 11.2786 13.6785C11.8412 13.1159 12.6043 12.7998 13.3999 12.7998H26.5999C27.3956 12.7998 28.1586 13.1159 28.7212 13.6785C29.2838 14.2411 29.5999 15.0042 29.5999 15.7998V24.1998C29.5999 24.9955 29.2838 25.7585 28.7212 26.3211C28.1586 26.8837 27.3956 27.1998 26.5999 27.1998H13.3999C12.6043 27.1998 11.8412 26.8837 11.2786 26.3211C10.716 25.7585 10.3999 24.9955 10.3999 24.1998V15.7998ZM19.3999 15.1998H13.3999C13.2408 15.1998 13.0882 15.263 12.9756 15.3755C12.8631 15.4881 12.7999 15.6407 12.7999 15.7998C12.7999 15.9589 12.8631 16.1115 12.9756 16.2241C13.0882 16.3366 13.2408 16.3998 13.3999 16.3998H19.3999C19.559 16.3998 19.7116 16.3366 19.8242 16.2241C19.9367 16.1115 19.9999 15.9589 19.9999 15.7998C19.9999 15.6407 19.9367 15.4881 19.8242 15.3755C19.7116 15.263 19.559 15.1998 19.3999 15.1998ZM22.9999 17.5998H13.3999C13.2408 17.5998 13.0882 17.663 12.9756 17.7755C12.8631 17.8881 12.7999 18.0407 12.7999 18.1998C12.7999 18.3589 12.8631 18.5115 12.9756 18.6241C13.0882 18.7366 13.2408 18.7998 13.3999 18.7998H22.9999C23.159 18.7998 23.3116 18.7366 23.4242 18.6241C23.5367 18.5115 23.5999 18.3589 23.5999 18.1998C23.5999 18.0407 23.5367 17.8881 23.4242 17.7755C23.3116 17.663 23.159 17.5998 22.9999 17.5998ZM13.9999 21.1998C13.6816 21.1998 13.3764 21.3262 13.1514 21.5513C12.9263 21.7763 12.7999 22.0815 12.7999 22.3998V23.5998C12.7999 23.9181 12.9263 24.2233 13.1514 24.4483C13.3764 24.6734 13.6816 24.7998 13.9999 24.7998H18.7999C19.1182 24.7998 19.4234 24.6734 19.6484 24.4483C19.8735 24.2233 19.9999 23.9181 19.9999 23.5998V22.3998C19.9999 22.0815 19.8735 21.7763 19.6484 21.5513C19.4234 21.3262 19.1182 21.1998 18.7999 21.1998H13.9999Z"
        fill={color ? calculateColor(color) : "#03CAB2"}
      />
    </svg>
  );
};
