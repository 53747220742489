import {
  Box,
  DesignBreakpointType,
  Image,
  Link,
  Text,
  useColors,
} from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import VisitorsFooterBckg from "../../assets/VisitorsFooterBckg.png";
import { OrganizationLink } from "../../components/OrganizationLink";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useSettings } from "../../hooks/useSettings";
import { organizationLinkTemplates } from "../../link-templates";
import { LogoWhiteandOrange } from "../../svg/LogoWhiteandOrange";
import { SocialMediaIconFacebook } from "../../svg/SocialMediaIconFacebook";
import { SocialMediaIconInstagram } from "../../svg/SocialMediaIconInstagram";
import { SocialMediaIconTwitter } from "../../svg/SocialMediaIconTwitter";

type Props = {
  breakpoints: DesignBreakpointType;
  breakPointSmallDuo: boolean;
};

export const VisitorsFooter: FunctionComponent<Props> = ({ breakPointSmallDuo, breakpoints }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const currentYear = new Date().getFullYear();
  const { contactInfo } = useSettings();

  return (
    <>
      {/* Footer */}
      <Box
        width={"100%"}
        minHeight={340}
        height={"fit-content"}
        overflow="hidden"
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="start"
        color={colors.black600}
        bottom
      >
        <Box position="absolute" width={"100%"} height={"100%"}>
          <Image
            color="transparent"
            alt=""
            naturalWidth={389}
            naturalHeight={80}
            src={VisitorsFooterBckg}
            fit="cover"
          ></Image>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          width={"100%"}
          minHeight={340}
          height={"100%"}
          paddingY={breakpoints === "small" ? 4 : 12}
          paddingX={breakpoints === "small" ? 2 : 8}
        >
          <Box
            display="flex"
            direction="column"
            justifyContent="between"
            height={"100%"}
            minHeight={340}
            width={"100%"}
            maxWidth={1296}
            paddingX={breakPointSmallDuo ? 4 : 12}
            position="relative"
          >
            <Box
              display="flex"
              direction={breakPointSmallDuo ? "column" : "row"}
              justifyContent="between"
              alignItems="start"
              width={"100%"}
            >
              <Box
                display="flex"
                width={breakPointSmallDuo ? "100%" : "50%"}
                direction="row"
                justifyContent="between"
                gap={5}
                minWidth={300}
              >
                <Box
                  minWidth={breakPointSmallDuo ? 150 : undefined}
                  width={"100%"}
                  position="relative"
                  marginBottom={breakPointSmallDuo ? 10 : undefined}
                >
                  <Box marginBottom={8} display="inlineBlock" width={254} height={54}>
                    <LogoWhiteandOrange />
                  </Box>
                  <Box marginBottom={6} maxWidth={330}>
                    <Text size="100" weight="normal" color={colors.white}>
                      {resources.landingPageNew.visitorsFooter.corpBoilerplate}
                    </Text>
                  </Box>
                  <Box display="flex" gap={4}>
                    <Box display="flex" alignItems="center" width={30} height={30}>
                      <Link href={contactInfo.FBPage}>
                        <SocialMediaIconFacebook />
                      </Link>
                    </Box>
                    <Box display="flex" alignItems="center" width={30} height={30}>
                      <Link href={contactInfo.XPage}>
                        <SocialMediaIconTwitter />
                      </Link>
                    </Box>
                    <Box display="flex" alignItems="center" width={30} height={30}>
                      <Link href={contactInfo.IGPage}>
                        <SocialMediaIconInstagram />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                width={breakPointSmallDuo ? "100%" : "fit-content"}
                direction={"row"}
                gap={breakPointSmallDuo ? 4 : 12}
                justifyContent="between"
                minWidth={300}
              >
                {breakpoints !== "small" && (
                  <Box
                    minWidth={breakPointSmallDuo ? 150 : undefined}
                    width={breakPointSmallDuo ? "50%" : "fit-content"}
                    position="relative"
                  >
                    <Box marginBottom={5} display="inlineBlock" width={"100%"}>
                      <Text weight="bold" size="200" color={colors.white}>
                        {resources.landingPageNew.visitorsFooter.linksTitle}
                      </Text>
                    </Box>
                    <Box display="flex" direction="column" marginBottom={6}>
                      <OrganizationLink to={organizationLinkTemplates.signup()}>
                        <Box marginBottom={4} display="block" justifyContent="start" width={"100%"}>
                          <Text size="100" align="start" color={colors.white}>
                            {resources.signUp}
                          </Text>
                        </Box>
                      </OrganizationLink>
                      <Box marginBottom={4} display="block" justifyContent="start" width={"100%"}>
                        <Text size="100" align="start" color={colors.white}>
                          Legal
                        </Text>
                      </Box>
                      <Box marginBottom={4} display="block" justifyContent="start" width={"100%"}>
                        <Text size="100" align="start" color={colors.white}>
                          FAQ
                        </Text>
                      </Box>
                      <Box display="block" justifyContent="start" width={"100%"}>
                        <Text size="100" align="start" color={colors.white}>
                          {resources.paymentPage.contactUs}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box
                  minWidth={240}
                  maxWidth={240}
                  width={breakPointSmallDuo ? "50%" : "fit-content"}
                  position="relative"
                >
                  <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                    <Text weight="bold" size="200" color={colors.white}>
                      {resources.paymentPage.contactUs}
                    </Text>
                  </Box>
                  <Box display="flex" direction="column" marginBottom={6}>
                    <Box marginBottom={3}>
                      <Text inline={true} size="100" weight="bold" color={colors.white}>
                        {resources.address}:{" "}
                      </Text>
                      <Text inline={true} size="100" weight="normal" color={colors.white}>
                        Av Pedro Antonio Bobea esquina Anacaona, Santo Domingo, República Dominicana
                      </Text>
                    </Box>
                    <Box marginBottom={3}>
                      <Text inline={true} size="100" weight="bold" color={colors.white}>
                        {resources.website}:{" "}
                      </Text>
                      <Text inline={true} size="100" weight="normal" color={colors.white}>
                        prodoctivity.com
                      </Text>
                    </Box>
                    <Box marginBottom={3}>
                      <Text inline={true} size="100" weight="bold" color={colors.white}>
                        {resources.email}:{" "}
                      </Text>
                      <Text inline={true} size="100" weight="normal" color={colors.white}>
                        info@prodoctivity.com
                      </Text>
                    </Box>
                    <Box marginBottom={3}>
                      <Text inline={true} size="100" weight="bold" color={colors.white}>
                        {resources.phone}:{" "}
                      </Text>
                      <Text inline={true} size="100" weight="normal" color={colors.white}>
                        {"(809) 440-7964"}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              direction="row"
              justifyContent="between"
              alignItems="end"
              padding={4}
              marginBottom={breakPointSmallDuo ? undefined : -6}
              width={"100%"}
            >
              <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                <Text weight="normal" size="100" color={colors.white}>
                  ProDoctivity © {currentYear} - {resources.allRightsReserved}
                </Text>
              </Box>
              <Box marginBottom={4} display="block" justifyContent="end" width={"100%"}>
                <Link href="/terms">
                  <Text size="100" align="end" color={colors.white}>
                    {resources.landingPageNew.visitorsFooter.termsAndConditions}
                  </Text>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
