import * as React from "react";

import { GenerationWizard, Props as GenerationWizardProps } from "./GenerationWizard";

import { ErrorBoundary } from "../ErrorBoundary";

export const GenerationWizardBoundary: React.FC<GenerationWizardProps> = (
  props: GenerationWizardProps
) => {
  return (
    <ErrorBoundary>
      <GenerationWizard {...props} />
    </ErrorBoundary>
  );
};
