import { Box, ComboBox, Divider, Label, Text, useColors } from "@prodoctivity/design-system";
import { CombinedTemplateState, useTemplateTab } from "../hooks";

import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { ArrowSvg } from "../../../../svg/ArrowSvg";
import { XIconSvg } from "../../../../svg/XIconSvg";

type Props = {
  isLoading: boolean;
  resources: ReturnType<typeof useAppTranslation>["resources"];
  templates: Array<{ value: string; label: string }>;
  formState: CombinedTemplateState;
  setFormState: (value: React.SetStateAction<CombinedTemplateState>) => void;
};

export const TemplatesSelect: FunctionComponent<Props> = ({
  // isLoading,
  resources,
  templates,
  formState,
  setFormState,
}) => {
  const { colors } = useColors();
  const { templatesFiltered, onSelectTemplate, onMoveTemplate, onRemoveTemplate } = useTemplateTab({
    templates,
    formState,
    setFormState,
  });

  return (
    <Box>
      <Box paddingY={4} paddingX={6} color={colors.white}>
        <Box marginEnd={8} marginBottom={6}>
          <Box display="flex" paddingY={3}>
            <Label htmlFor={"templates-for-combined-template"}>{resources.templates}</Label>
          </Box>
          <Box width={"100%"}>
            <ComboBox
              id={"templates-for-combined-template"}
              size="lg"
              label=""
              placeholder={resources.chooseATemplate}
              onSelect={({ item }) => onSelectTemplate(item.value)}
              options={templatesFiltered}
              inputValue={""}
              errorMessage={formState.errors?.["templates-for-combined-template"]}
            />
          </Box>
        </Box>
        <Box
          height={"fit-content"}
          minHeight={90}
          alignItems="start"
          borderStyle="lg"
          borderRadius={6}
        >
          <Box color={colors.neutral200} paddingY={3} paddingX={5}>
            <Text color={colors.subtle} weight="bold">
              {resources.combinedTemplates.selectedTemplates}
            </Text>
          </Box>
          <Box display="flex" direction="column" width={"100%"}>
            {formState.templates.map((id) => (
              <Box key={id}>
                <Box
                  paddingY={2}
                  paddingX={2}
                  display="flex"
                  justifyContent="between"
                  alignItems="center"
                >
                  <Box marginStart={3} flex="grow">
                    {templates.find((role) => id === role.value)?.label}
                  </Box>
                  <Box display="flex" gap={3}>
                    <ArrowSvg
                      direction="up"
                      color={colors.subtle}
                      onClick={() => onMoveTemplate(id, "up")}
                    />
                    <ArrowSvg
                      direction="down"
                      color={colors.subtle}
                      onClick={() => onMoveTemplate(id, "down")}
                    />
                    <XIconSvg width={12} height={12} onClick={() => onRemoveTemplate(id)} />
                  </Box>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
