import { Box, ComboBox, Text, useColors } from "@prodoctivity/design-system";
import type {
  DocumentCollectionConfigRelationship,
  DocumentCollectionConfigRelationshipDocumentType,
} from "@prodoctivity/types";
import { DocumentCollectionState, useDocumentTypeRelation } from "../../hooks";

import { ComboBoxProps } from "@prodoctivity/design-system/components/ComboBox";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import { SmallAccordion } from "./SmallAccordion";

// import { FilterBuilder } from "@prodoctivity/fluency-components";
// import { useTranslation } from "react-i18next";
// import { parseLogicalExpressionToCondition } from "@prodoctivity/prodoctivity-form-v5/src/components/_lib/extract-dependencies";

export const DocumentTypeRelation = ({
  resources,
  docTypeIndex,
  formState,
  relation,
  docTypeRelation,
  documentTypesOptions,
  onChangeDocumentType,
  onChangeTargetField,
  onRemove,
}: {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  docTypeIndex: number;
  formState: DocumentCollectionState;
  relation: DocumentCollectionConfigRelationship<string>;
  docTypeRelation: DocumentCollectionConfigRelationshipDocumentType<string>;
  documentTypesOptions: ComboBoxProps["options"];
  onChangeDocumentType: (documentTypeId: string, index: number) => void;
  onChangeTargetField: (index: number, targetField?: string) => void;
  onRemove: (indexRemoved?: number | undefined) => void;
}) => {
  const {
    // documentType,
    docTypesFilteredByInput,
    currentDocType,
    currentTargetField,
    targetFieldsFilteredByInput,
    targetFields,
    open,
    setOpen,
    // openExpression,
    // setOpenExpression,
    // onRemoveExpression,
    currentDocTypeLabel,
    currentTargetFieldLabel,
    setCurrentDocTypeLabel,
    setCurrentTargetFieldLabel,
  } = useDocumentTypeRelation({
    docTypeIndex,
    isNew: docTypeRelation.targetField === undefined,
    formState,
    relation,
    documentTypesOptions,
    docTypeRelation,
    onChangeTargetField,
  });

  const { colors } = useColors();

  // const { t } = useTranslation();
  return (
    <Box width="100%">
      <SmallAccordion
        resources={resources}
        label={currentDocType?.label}
        isActive={open}
        currIndex={docTypeIndex}
        setActive={
          !open
            ? setOpen
            : (active: boolean) => {
                setOpen(active);
              }
        }
        remove={onRemove}
      >
        <Box padding={2} color={colors.neutral200}>
          <Box display="flex" direction="column">
            <Box flex="grow">
              <Box marginBottom={3}>
                <Text color={colors.neutral900}>{resources.documentTypes.documentType}</Text>
              </Box>
              <ComboBox
                id={`document-type-${docTypeIndex}`}
                options={[...(currentDocType ? [currentDocType] : []), ...docTypesFilteredByInput]}
                label={""}
                placeholder={currentDocType?.label}
                selectedOption={currentDocType}
                inputValue={currentDocTypeLabel || ""}
                onClear={() => {
                  onChangeDocumentType("", docTypeIndex);
                  setCurrentDocTypeLabel("");
                }}
                onSelect={(e) => {
                  onChangeDocumentType(e.item.value, docTypeIndex);
                  setCurrentDocTypeLabel(e.item.label);
                }}
                onChange={(e) => {
                  const docType = documentTypesOptions.find((f) => f.label === e.value);
                  if (docType) {
                    onChangeDocumentType(docType.value, docTypeIndex);
                  }
                  setCurrentDocTypeLabel(e.value);
                }}
                onBlur={(e) => {
                  const docType = documentTypesOptions.find((f) => f.value === e.value);
                  if (!docType) {
                    if (currentDocType) {
                      onChangeDocumentType(currentDocType.value, docTypeIndex);
                      setCurrentDocTypeLabel(currentDocType.label);
                    } else {
                      onChangeDocumentType("", docTypeIndex);
                      setCurrentDocTypeLabel("");
                    }
                  }
                }}
              />
            </Box>

            <Box flex="grow">
              <Box marginBottom={3}>
                <Text color={colors.neutral900}>{resources.documentCollection.targetField}</Text>
              </Box>
              <ComboBox
                id={`target-field-${docTypeIndex}`}
                options={targetFieldsFilteredByInput}
                label={""}
                placeholder={currentTargetField?.label}
                selectedOption={currentTargetField}
                inputValue={currentTargetFieldLabel || ""}
                onClear={() => {
                  onChangeTargetField(docTypeIndex, "");
                  setCurrentTargetFieldLabel("");
                }}
                onSelect={(e) => {
                  onChangeTargetField(docTypeIndex, e.item.value);
                  setCurrentTargetFieldLabel(e.item.label);
                }}
                onChange={(e) => {
                  const field = targetFields.find((f) => f.label === e.value);
                  if (field) {
                    onChangeTargetField(docTypeIndex, field.value);
                  }
                  setCurrentTargetFieldLabel(e.value);
                }}
                onBlur={(e) => {
                  const field = targetFields.find((f) => f.label === e.value);
                  if (!field) {
                    if (currentTargetField) {
                      onChangeTargetField(docTypeIndex, currentTargetField.value);
                      setCurrentTargetFieldLabel(currentTargetField.label);
                    } else {
                      onChangeTargetField(docTypeIndex, undefined);
                      setCurrentTargetFieldLabel("");
                    }
                  }
                }}
              />
            </Box>
          </Box>

          {/* <Box marginTop={2}>
          <SmallAccordion
            resources={resources}
            label={resources.includeDocumentIf}
            currIndex={0}
            isActive={openExpression}
            setActive={setOpenExpression}
            remove={onRemoveExpression}
          >
            <FilterBuilder
              translateFn={t}
              fieldList={(documentType?.contextDefinition.fields || []).map((f) => {
                return {
                  type: "field",
                  displayName: f.properties.label,
                  fields: [],
                  isChild: false,
                  records: [],
                  name: f.name,
                  fullPath: f.fullPath,
                  inputSize: "Normal",
                  isReference: false,
                  isReadOnly: false,
                  ...f.properties,
                } as any;
              })}
              expression={
                currDocTypeRelation.includeExpression
                  ? parseLogicalExpressionToCondition(currDocTypeRelation.includeExpression)
                  : {
                    isNegative: false,
                    expressionList: [],
                    // sourceField?: string;
                    // sourceValue?: any;
                    // targetField?: string;
                    // targetValue?: any;
                    where: undefined
                  }
              }
              expressionIndex={0}
              helpMode={false}
              level={0}
              lists={{}}
              onExpressionChanged={() => {
                console.log("");
              }}
            />
          </SmallAccordion>
        </Box> */}
        </Box>
      </SmallAccordion>
    </Box>
  );
};
