import {
  Box,
  Table,
  Text,
  TapArea,
  SelectList,
  TextField,
  Flex,
  PopoverCombobox,
  ChevronSvgIcon,
  useColors,
} from "@prodoctivity/design-system";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import type {
  DataElement,
  DictionaryList,
  DictionaryListItem,
  DictionaryListSchema,
} from "@prodoctivity/shared/src/index-types";
import { DictionaryListPath } from "./DictionaryListControl";
import { FolderSvgIcons } from "../../../../svg/FolderSvgIcons";
import { DocSvg } from "../../../../svg/DocSvg";
import { noop } from "../../../../utils";
import { useDataListItems } from "./hook";
import { TLineIndentationIconSvg } from "../../../../svg/DocumentCollection";
import { IndentationArrowSvgIcon } from "../../../../svg/IndentationArrowSvgIcon";
import { ListSvg } from "@prodoctivity/design-system/svg/ListSvg";

type Props = {
  element: DataElement;
  onChange: (dataElement: DataElement) => void;
  dictionaryLists: DictionaryList<string>[];
  dictionaryListName: string;
  showPopoverComboBox: boolean;
  showValueLists: boolean;
  setShowValueLists: (n: boolean) => void;
  updateValueLists: (
    index: number,
    k: {
      value: string;
      label: string;
      parentValue?: string | undefined;
    }
  ) => void;
  isHiddenPopoverCombobox: boolean;
};
export const DataListItems = ({
  element,
  dictionaryLists,
  dictionaryListName,
  onChange,
  showPopoverComboBox,
  showValueLists,
  setShowValueLists,
  updateValueLists,
  isHiddenPopoverCombobox,
}: Props) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const [isOpen, setIsOpen] = useState<{ [itemId: string]: boolean }>({});
  const [activeHoverItemId, setActiveHoverItemId] = useState<string>("");
  const {
    inputRef,
    dictionaryPathName,
    setDictionaryPathName,
    search,
    setSearch,
    showListItems,
    setShowListItems,
    itemsDictionary,
    listValue,
    setListValue,
    listLabel,
    setListLabel,
    getNullValues,
    dictionaryList,
    elementOptionInputType,
    updateElementWithValueList,
    generateUniqueValues,
  } = useDataListItems(element, dictionaryLists, dictionaryListName, onChange, setShowValueLists);

  const optionsPopovers: {
    strLabel: string;
    label: JSX.Element;
    value: string;
  }[] = useMemo(() => {
    if (itemsDictionary) {
      return [
        {
          strLabel: "",
          label: <TreeDictionaryList items={itemsDictionary} search={search} />,
          value: "",
        },
      ];
    } else {
      return [
        {
          strLabel: "",
          label: <></>,
          value: "",
        },
      ];
    }
  }, [itemsDictionary, search]);

  return (
    <>
      {elementOptionInputType && showPopoverComboBox ? (
        <Box display="flex" alignItems="center" justifyContent="between" paddingX={4}>
          <Box maxWidth={500}>
            {!isHiddenPopoverCombobox ? (
              <PopoverCombobox
                getI18n={(k) => {
                  const m: Record<string, string> = resources as any;
                  return m[k] || k;
                }}
                options={optionsPopovers}
                onSelect={updateElementWithValueList}
                placeholder={undefined}
                direction="down"
                onClear={noop}
                displayOption="strLabel"
                filter={search}
                onFilterChange={setSearch}
                displayedText={resources.searchItems}
              />
            ) : (
              <Box minWidth={410}>
                <Box
                  display="flex"
                  padding={2}
                  rounding={2}
                  color={colors.neutral200}
                  alignItems="center"
                  borderStyle="lg"
                  borderColor={colors.neutral500}
                >
                  <Text color={colors.subtle}>{resources.listName}</Text>
                  <ChevronSvgIcon
                    width={30}
                    height={30}
                    color={colors.subtle}
                    direction={"right"}
                  />
                  <Text color={colors.subtle}>{dictionaryList?.listSchema.name}</Text>
                </Box>
                <Box maxHeight={200} padding={1} overflow="auto">
                  {dictionaryList && (
                    <TreeListSchema
                      dictionaryList={dictionaryList}
                      getNullValues={getNullValues}
                      data={dictionaryList.listSchema}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      activeHoverItemId={activeHoverItemId}
                      setActiveHoverItemId={setActiveHoverItemId}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}
      {element.dataType === "Alphanumeric" &&
        (element.inputType === "Checkbox" ||
          element.inputType === "Radio" ||
          element.inputType === "Dropdown" ||
          element.inputType === "CheckBoxList" ||
          element.inputType === "DropDownList" ||
          element.inputType === "RadioButtonList") &&
        showValueLists &&
        element.listHasLabels && (
          <Box marginTop={2}>
            <TapArea onTap={() => setShowListItems(!showListItems)}>
              <Box
                display="flex"
                alignItems="center"
                width={"100%"}
                padding={4}
                height={50}
                borderStyle="sm"
                borderRadius={4}
                color={colors.neutral200}
                justifyContent="between"
              >
                <Box>
                  <Text size="300">{resources.items}</Text>
                </Box>
                <ChevronSvgIcon color={colors.primary} direction={showListItems ? "up" : "down"} />
              </Box>
            </TapArea>
            {showListItems && (
              <Box margin={2} display="flex" direction="column" overflow="auto">
                <Box
                  borderRadius={4}
                  borderStyle="sm"
                  height={48}
                  display="flex"
                  alignItems="center"
                  paddingX={6}
                  color={colors.neutral200}
                  justifyContent="between"
                  paddingY={4}
                >
                  <Box>
                    <Text weight="bold">{resources.items}</Text>
                  </Box>
                  <Box>
                    <TapArea
                      onTap={() => {
                        if (element.valueList) {
                          onChange({
                            ...element,
                            valueList: [],
                          });
                        }
                      }}
                    >
                      <Text>{resources.clearAll}</Text>
                    </TapArea>
                  </Box>
                </Box>
                {element.valueList && element.valueList.length > 0 ? (
                  <Box height={element.valueList.length > 5 ? 600 : "auto"}>
                    {(element.valueList || []).map((k, index) => (
                      <Box key={index} borderStyle="sm" margin={1}>
                        <Table accessibilityLabel="data list">
                          <Table.Header>
                            <Table.Row>
                              {k.parentValue && (
                                <Table.HeaderCell>
                                  <Box paddingX={4}>
                                    <Text size="200">{resources.parent.toUpperCase()}</Text>
                                  </Box>
                                </Table.HeaderCell>
                              )}
                              <Table.HeaderCell>
                                <Box paddingX={4}>
                                  <Text size="200">{resources.value.toUpperCase()}</Text>
                                </Box>
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <Box paddingX={4}>
                                  <Text size="200">{resources.label.toUpperCase()}</Text>
                                </Box>
                              </Table.HeaderCell>
                              <Table.HeaderCell> </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body key={index}>
                            {k.parentValue && (
                              <Table.Cell>
                                <Box minWidth={200} height={65}>
                                  <SelectList
                                    id={`dictionaryList_${index}`}
                                    disabled={k.parentValue === undefined ? true : false}
                                    onChange={(e) => {
                                      setDictionaryPathName((prevState) => ({
                                        ...prevState,
                                        [index]: e.value,
                                      }));

                                      updateValueLists(index, {
                                        value: k.value,
                                        label: k.label,
                                        parentValue: e.value,
                                      });
                                    }}
                                    value={
                                      k.parentValue ? k.parentValue : dictionaryPathName[index]
                                    }
                                    options={generateUniqueValues(k)}
                                  />
                                </Box>
                              </Table.Cell>
                            )}
                            <Table.Cell>
                              <TextField
                                id={`item-value-${index}`}
                                ref={inputRef}
                                onChange={(e) => {
                                  setListValue((prevListValue) => ({
                                    ...prevListValue,
                                    [index]: e.value,
                                  }));
                                  updateValueLists(index, {
                                    value: e.value,
                                    label: k.label,
                                    parentValue: k.parentValue,
                                  });
                                }}
                                value={k.value ? k.value : listValue[index]}
                                maxLength={{
                                  characterCount: 100,
                                  errorAccessibilityLabel: resources.dataDictionary.maxLength,
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <TextField
                                id={`-item-value-${index}`}
                                ref={inputRef}
                                onChange={(e) => {
                                  setListLabel((prevListLabe) => ({
                                    ...prevListLabe,
                                    [index]: e.value,
                                  }));

                                  updateValueLists(index, {
                                    value: k.value,
                                    label: e.value,
                                    parentValue: k.parentValue,
                                  });
                                }}
                                value={k.label ? k.label : listLabel[index]}
                                maxLength={{
                                  characterCount: 100,
                                  errorAccessibilityLabel: resources.dataDictionary.maxLength,
                                }}
                              />
                            </Table.Cell>
                          </Table.Body>
                        </Table>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box
                    marginTop={3}
                    borderRadius={4}
                    borderStyle="sm"
                    height={48}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={colors.neutral200}
                  >
                    <Text size="300">{`${resources.dataDictionary.noItems}`}</Text>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
    </>
  );
};

const TreeDictionaryList = ({ items, search }: { items: DictionaryListPath[]; search: string }) => (
  <Box padding={2}>
    {items.map((node, idx) => (
      <TreeNodeDictionaryList key={idx} items={node} search={search} />
    ))}
  </Box>
);

const TreeNodeDictionaryList = ({
  items,
  search,
}: {
  items: DictionaryListPath;
  search: string;
}) => {
  const hasSearchValue = useCallback(
    (node: DictionaryListPath) => {
      if (node.label.toLowerCase().includes(search.toLowerCase())) return true;
      else if (node.children) {
        return Object.values(node.children).flat().some(hasSearchValue);
      } else {
        return false;
      }
    },
    [search]
  );

  const show = useMemo(() => hasSearchValue(items), [hasSearchValue, items]);
  const [isOpen, setIsOpen] = useState<boolean>(show);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      <Box
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="between"
        onClickCapture={handleClick}
        padding={1}
      >
        <TapArea>
          {items.children ? (
            <Box display="flex" alignItems="center" justifyContent="between" width={"100%"}>
              <Flex alignItems="center" gap={1}>
                <IndentationArrowSvgIcon
                  direction={items.children.length > 0 && isOpen ? "bottomLeft" : "left"}
                />
                <FolderSvgIcons /> <Text>{items.label}</Text>
              </Flex>
            </Box>
          ) : (
            <Flex alignItems="center" gap={1}>
              <DocSvg /> <Text>{items.label}</Text>
            </Flex>
          )}
        </TapArea>
      </Box>
      {show && isOpen && (
        <Box paddingX={2}>
          {items.children && (
            <TreeDictionaryList
              key={items.key}
              items={Object.values(items.children).flat()}
              search={search}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

const TreeListSchema = ({
  data,
  getNullValues,
  dictionaryList,
  setIsOpen,
  isOpen,
  activeHoverItemId,
  setActiveHoverItemId,
}: {
  data: DictionaryListSchema;
  dictionaryList: DictionaryList<string>;
  getNullValues: (items: DictionaryListItem[] | undefined, searchKey: string) => void;
  isOpen: {
    [itemId: string]: boolean;
  };
  setIsOpen: Dispatch<
    SetStateAction<{
      [itemId: string]: boolean;
    }>
  >;
  activeHoverItemId: string;
  setActiveHoverItemId: (n: string) => void;
}) => {
  return (
    <TreeNodeListSchema
      dictionaryList={dictionaryList}
      getNullValues={getNullValues}
      node={data}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      activeHoverItemId={activeHoverItemId}
      setActiveHoverItemId={setActiveHoverItemId}
    />
  );
};

const TreeNodeListSchema = ({
  node,
  getNullValues,
  dictionaryList,
  setIsOpen,
  isOpen,
  activeHoverItemId,
  setActiveHoverItemId,
}: {
  node: DictionaryListSchema;
  dictionaryList: DictionaryList<string>;
  getNullValues: (items: DictionaryListItem[] | undefined, searchKey: string) => void;
  isOpen: {
    [itemId: string]: boolean;
  };
  setIsOpen: Dispatch<
    SetStateAction<{
      [itemId: string]: boolean;
    }>
  >;
  activeHoverItemId: string;
  setActiveHoverItemId: (n: string) => void;
}) => {
  const { colors } = useColors();
  const handleClick = (value: string) => {
    setIsOpen((prevState) => {
      return {
        ...prevState,
        [value]: !prevState[value],
      };
    });
    getNullValues(dictionaryList.items, value);
    setActiveHoverItemId(value);
  };

  return (
    <Box width={"100%"}>
      <Box
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="between"
        hoverColor={colors.neutral300}
        color={node.name === activeHoverItemId ? colors.neutral300 : undefined}
      >
        <Box
          onClickCapture={() => handleClick(node.name)}
          display="flex"
          direction="row"
          flex="grow"
          alignItems="center"
        >
          <TapArea>
            {node.children && (
              <Box display="flex" alignItems="center" justifyContent="between" flex="grow">
                <Flex alignItems="center" gap={1}>
                  {node.children.length > 0 && (
                    <IndentationArrowSvgIcon
                      direction={
                        node.children.length > 0 && isOpen[node.name] ? "bottomLeft" : "left"
                      }
                    />
                  )}
                  {node.children.length > 0 ? (
                    <TLineIndentationIconSvg />
                  ) : (
                    <Box paddingLeft={7}>
                      <TLineIndentationIconSvg />
                    </Box>
                  )}
                  <ListSvg color={colors.primary} />
                  <Text>{node.name}</Text>
                </Flex>
              </Box>
            )}
          </TapArea>
        </Box>
      </Box>
      {isOpen[node.name] && (
        <Box>
          {node &&
            node.children.map((child, idx) => (
              <TreeNodeListSchema
                getNullValues={getNullValues}
                key={idx}
                node={child}
                dictionaryList={dictionaryList}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                activeHoverItemId={activeHoverItemId}
                setActiveHoverItemId={setActiveHoverItemId}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};
