import { Modal, Tooltip, modalZIndex, type ProDoctivityColorBundle } from "./";

import { Box } from "./Box";
import { ColorConfig } from "../utils";
import { Component } from "react";
import { Icon } from "./Icon";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  title: string;
  state?: State;
  clickContent?: JSX.Element;
  clickContentTitle?: JSX.Element | string;
  forceVisible?: boolean;
  colorBundle: ProDoctivityColorBundle;
  color?: ColorConfig;
  iconType?: "question" | "info" | "check";
  size: "xs" | "sm" | "md" | undefined;
}>;
interface State {
  modalVisible: boolean;
}
export class WhatsThis extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = props.state || {
      modalVisible: false,
    };

    this.clicked = this.clicked.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  public clicked() {
    if (this.props.clickContent) {
      this.setState({ ...this.state, modalVisible: true });
    }
  }
  public closeModal() {
    this.setState({ ...this.state, modalVisible: false });
  }

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const infoWeakColor: any = "infoWeak";
    const { iconType } = this.props;
    return (
      <Box marginStart={2}>
        <Tooltip text={this.props.title}>
          <Box color={infoWeakColor} height={"100%"} minWidth={25} direction="row">
            <Icon
              accessibilityLabel={this.props.title}
              size={this.props.size ? this.props.size : "xs"}
              icon={
                iconType === "check"
                  ? "circle-check"
                  : iconType === "info"
                  ? "circle-info"
                  : "circle-question"
              }
              color={this.props.color ? this.props.color : undefined}
              onClick={this.clicked}
            />
          </Box>
        </Tooltip>
        {this.state.modalVisible ? (
          <Box zIndex={modalZIndex} position="relative">
            <Modal
              accessibilityModalLabel={this.props.title}
              onDismiss={this.closeModal}
              footer={this.props.clickContentTitle}
              closeOnOutsideClick={true}
              role="dialog"
            >
              <Box color={this.props.colorBundle.white} padding={2} rounding={6}>
                <Box margin={4}>{this.props.clickContent ? this.props.clickContent : []}</Box>
              </Box>
            </Modal>
          </Box>
        ) : null}
      </Box>
    );
  }
}
