import { useCallback } from "react";
import { ZodTypeDef, z } from "zod";

import type { HttpGetDocumentCollectionInstanceListRequest } from "@prodoctivity/types";
import { useLocation } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";

export type DocumentCollectionInstanceListFilter = {
  pageNumber: number;
  rowsPerPage: HttpGetDocumentCollectionInstanceListRequest["queryParameters"]["rowsPerPage"];
  filter: string;
  assignedTo: string;
};

const DocumentCollectionInstanceListFilter$Schema: z.Schema<
  DocumentCollectionInstanceListFilter,
  ZodTypeDef,
  unknown
> = z
  .object({
    pageNumber: z.coerce.number().nonnegative().optional().default(0).catch(0),
    rowsPerPage: z.enum(["15", "30", "50"]).optional().default("15").catch("15"),
    filter: z.string().default("").optional().default("").catch(""),
    assignedTo: z.string().default("").optional().default("").catch(""),
  })
  .strip();

function urlParamsToFilter(
  urlParams: Record<string, string | null>
): DocumentCollectionInstanceListFilter {
  const filter = DocumentCollectionInstanceListFilter$Schema.safeParse(urlParams);

  if (!filter.success) {
    return {
      assignedTo: "",
      filter: "",
      rowsPerPage: "15",
      pageNumber: 0,
    };
  }

  return filter.data;
}

export function usePaginatedDocumentCollectionInstanceList() {
  const organizationNavigate = useOrganizationNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const paramsFilter = urlParamsToFilter({
    pageNumber: params.get("pageNumber"),
    rowsPerPage: params.get("rowsPerPage"),
    filter: params.get("filter"),
    assignedTo: params.get("assignedTo"),
  });

  const { pageNumber: currentPage, rowsPerPage } = paramsFilter;

  const [debouncedFilter] = useDebounceValue(paramsFilter.filter, 600);

  const handleFilterChange = useCallback(
    (filterType: keyof typeof paramsFilter, value: { value?: string | number }) => {
      organizationNavigate(
        organizationLinkTemplates.documentCollectionList({
          ...paramsFilter,
          [filterType]: value.value,
          pageNumber: 0,
        })
      );
    },
    [organizationNavigate, paramsFilter]
  );

  const setRowsPerPage = useCallback(
    (val: HttpGetDocumentCollectionInstanceListRequest["queryParameters"]["rowsPerPage"]) => {
      organizationNavigate(
        organizationLinkTemplates.documentCollectionList({
          ...paramsFilter,
          pageNumber: 0,
          rowsPerPage: val,
        })
      );
    },
    [organizationNavigate, paramsFilter]
  );

  const setCurrentPage = useCallback(
    (val: number) => {
      organizationNavigate(
        organizationLinkTemplates.documentCollectionList({
          ...paramsFilter,
          pageNumber: val,
        })
      );
    },
    [organizationNavigate, paramsFilter]
  );

  const setPageLength = useCallback(
    (
      pageLength: HttpGetDocumentCollectionInstanceListRequest["queryParameters"]["rowsPerPage"]
    ) => {
      setCurrentPage(0);
      setRowsPerPage(pageLength);
    },
    [setCurrentPage, setRowsPerPage]
  );

  const { getDocumentCollectionInstanceList, user: thisUser } = useServices();
  const fetchDocumentCollectionInstanceList = useCallback(() => {
    return getDocumentCollectionInstanceList(
      currentPage,
      rowsPerPage,
      debouncedFilter,
      paramsFilter.assignedTo
    );
  }, [currentPage, getDocumentCollectionInstanceList, rowsPerPage, debouncedFilter, paramsFilter]);

  const { data, isLoading, remove, refetch } = useOrganizationQuery(
    `document-collection-instance/page_${currentPage}_${rowsPerPage}_${debouncedFilter}_${paramsFilter.assignedTo}`,
    fetchDocumentCollectionInstanceList,
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const isNextButtonDisabled =
    !data ||
    data.requestedPageLength !== data.pageLength ||
    data?.pageLength + currentPage * data.requestedPageLength >=
      (data.totalRowCount ?? Number.MAX_SAFE_INTEGER);

  const isPreviousButtonDisabled = !data || data.pageNumber < 1;
  const totalRowCount = data ? data.totalRowCount : undefined;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled, setCurrentPage]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled, setCurrentPage]);

  return {
    isLoading,
    documentCollections: data?.documentCollections || [],
    currentPage,
    nextPage,
    previousPage,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    rowsPerPage,
    paramsFilter,
    handleFilterChange,
    setPageLength,
    refetch: () => {
      remove();
      refetch();
    },
    thisUsername: thisUser?.username,
    pageLengthOptions: ["15", "30", "50"] as Array<
      HttpGetDocumentCollectionInstanceListRequest["queryParameters"]["rowsPerPage"]
    >,
    totalRowCount,
  };
}
