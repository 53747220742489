import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const SimpleBillTemplateSvgIcon: FunctionComponent<Props> = ({
  width = 101,
  height = 101,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8207_106245)">
        <rect
          width="93"
          height="93"
          transform="translate(4)"
          fill="url(#paint0_linear_8207_106245)"
        />
        <g filter="url(#filter1_d_8207_106245)">
          <rect width="81" height="81" transform="translate(10 6)" fill="#D4E0F7" />
          <path d="M46.4212 28.1035H41.1211V32.4841H46.4212V28.1035Z" fill="#9D9D9C" />
          <path d="M63.5306 28.1035H49.3086V32.4841H63.5306V28.1035Z" fill="#9D9D9C" />
          <path d="M63.5289 38.9785H41.1211V43.3591H63.5289V38.9785Z" fill="#9D9D9C" />
          <path d="M56.4032 49.8262H41.1211V54.2068H56.4032V49.8262Z" fill="#9D9D9C" />
          <path d="M50.9278 21.7598H36.1758V26.1404H50.9278V21.7598Z" fill="#878787" />
          <path d="M41.4759 32.6348H36.1758V37.0154H41.4759V32.6348Z" fill="#878787" />
          <path d="M58.5541 32.6348H44.332V37.0154H58.5541V32.6348Z" fill="#878787" />
          <path d="M58.5836 43.4805H36.1758V47.8611H58.5836V43.4805Z" fill="#878787" />
          <path d="M51.4579 54.3555H36.1758V58.7361H51.4579V54.3555Z" fill="#878787" />
          <g filter="url(#filter2_d_8207_106245)">
            <path
              d="M25.9375 20.8519L25.9375 72.1481C25.9375 72.6186 26.3005 73 26.7482 73H64.1268C64.5745 73 64.9375 72.6186 64.9375 72.1481V20.8519C64.9375 20.3814 64.5745 20 64.1268 20H26.7482C26.3005 20 25.9375 20.3814 25.9375 20.8519Z"
              fill="white"
            />
          </g>
          <g filter="url(#filter3_d_8207_106245)">
            <path d="M45.5099 26.8047H30.7578V31.1853H45.5099V26.8047Z" fill="#2951A0" />
          </g>
          <g filter="url(#filter4_d_8207_106245)">
            <path d="M36.058 37.6484H30.7578V42.029H36.058V37.6484Z" fill="#2951A0" />
          </g>
          <g filter="url(#filter5_d_8207_106245)">
            <path d="M53.1674 37.6484H38.9453V42.029H53.1674V37.6484Z" fill="#E97025" />
          </g>
          <g filter="url(#filter6_d_8207_106245)">
            <path d="M53.1656 48.5254H30.7578V52.906H53.1656V48.5254Z" fill="#2951A0" />
          </g>
          <g filter="url(#filter7_d_8207_106245)">
            <path d="M46.0399 59.373H30.7578V63.7536H46.0399V59.373Z" fill="#E97025" />
          </g>
          <path
            d="M70.0115 33.0888C70.1587 34.9014 69.0398 36.4724 67.4203 36.8954C67.1553 37.0464 66.8609 37.0464 66.5959 37.0464C66.5959 37.4694 66.7431 37.7413 66.5959 38.1642C66.5959 38.8591 66.1836 39.282 65.5064 39.282C64.8292 39.282 64.4169 38.8591 64.4169 38.1642V37.0464H62.0907C61.4135 37.0464 61.0013 36.4724 61.0013 35.7776C61.0013 35.0827 61.5607 34.6597 62.238 34.6597H66.6253C67.3026 34.6597 67.7148 34.2368 67.862 33.6628C67.862 32.9679 67.3026 32.3939 66.6253 32.3939H64.1519C62.503 32.2429 61.1485 30.8532 61.0013 29.1613C60.854 27.4695 61.973 25.9288 63.5925 25.5058C63.8575 25.3548 64.1519 25.3548 64.4169 25.3548V24.2369C64.4169 23.5421 64.9764 23.1191 65.5064 23.1191C66.1836 23.1191 66.5959 23.5421 66.5959 24.2369C66.7431 24.6599 66.5959 24.9318 66.5959 25.3548H68.922C69.5993 25.3548 70.0115 25.9288 70.0115 26.4726C70.0115 27.1674 69.5993 27.7414 68.7748 27.7414H64.2403C63.6808 27.8925 63.2686 28.3154 63.2686 28.8592C63.2686 29.403 63.6808 29.977 64.2403 29.977H66.5664C68.3626 30.1281 69.8643 31.397 69.9821 33.0586L70.0115 33.0888Z"
            fill="#9D9D9C"
            stroke="white"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <g filter="url(#filter8_d_8207_106245)">
            <path
              d="M70.6234 21.9372C69.621 21.1151 68.4038 20.5421 67.0673 20.3179C66.399 20.1934 65.7308 20.0938 65.0625 20.0938C59.5016 20.0938 55.0625 24.8518 55.0625 30.656C55.0625 32.1756 55.3966 33.5457 55.9456 34.8411C57.4969 38.4283 61.053 41.0938 65.0625 41.0938C67.1627 41.0938 69.072 40.3962 70.6234 39.35C73.2964 37.5066 75.0625 34.2432 75.0625 30.656C75.0625 27.0688 73.2964 23.8055 70.6234 21.9621V21.9372ZM70.6234 38.1792C69.072 39.4496 67.1866 40.1471 65.0625 40.1471C61.1723 40.1471 57.7355 37.3571 56.5183 33.6454C56.1842 32.7236 55.9694 31.6774 55.9694 30.6311C55.9694 25.3002 60.0744 21.0155 65.0864 21.0155C65.5398 21.0155 65.874 21.0155 66.2081 21.14C67.8787 21.3642 69.43 21.9621 70.6472 23.0083C72.7475 24.7521 74.2033 27.5421 74.2033 30.656C74.2033 33.7699 72.7475 36.4603 70.6472 38.2041L70.6234 38.1792Z"
              fill="#EA7126"
            />
            <path
              d="M75.3125 30.656C75.3125 27.0369 73.5548 23.7356 70.8734 21.8319V21.8189L70.7819 21.7439C69.7474 20.8955 68.4909 20.3037 67.1109 20.0718C66.439 19.9466 65.7534 19.8438 65.0625 19.8438C59.3498 19.8438 54.8125 24.7278 54.8125 30.656C54.8125 32.2141 55.1555 33.6174 55.7154 34.9386L55.7161 34.9403C57.3018 38.6071 60.9408 41.3438 65.0625 41.3438C67.219 41.3438 69.1763 40.6275 70.7632 39.5573L70.7653 39.5558C73.5075 37.6647 75.3125 34.3236 75.3125 30.656ZM70.6647 37.8609L70.6439 37.8392L70.465 37.9857C68.9581 39.2198 67.1286 39.8971 65.0625 39.8971C61.2935 39.8971 57.9437 37.1896 56.7559 33.5675L56.756 33.5674L56.7534 33.5601C56.4277 32.6618 56.2194 31.6444 56.2194 30.6311C56.2194 25.4263 60.2241 21.2655 65.0864 21.2655C65.5496 21.2655 65.8364 21.2683 66.1208 21.3743L66.147 21.3841L66.1748 21.3878C67.8088 21.6071 69.3116 22.1899 70.4843 23.1979L70.4842 23.198L70.4875 23.2007C72.5331 24.899 73.9533 27.6197 73.9533 30.656C73.9533 33.6014 72.6166 36.1586 70.6647 37.8609Z"
              stroke="#FB9231"
              strokeWidth="0.5"
            />
          </g>
          <path
            d="M70.0115 33.0888C70.1587 34.9014 69.0398 36.4724 67.4203 36.8954C67.1553 37.0464 66.8609 37.0464 66.5959 37.0464C66.5959 37.4694 66.7431 37.7413 66.5959 38.1642C66.5959 38.8591 66.1836 39.282 65.5064 39.282C64.8292 39.282 64.4169 38.8591 64.4169 38.1642V37.0464H62.0907C61.4135 37.0464 61.0013 36.4724 61.0013 35.7776C61.0013 35.0827 61.5607 34.6597 62.238 34.6597H66.6253C67.3026 34.6597 67.7148 34.2368 67.862 33.6628C67.862 32.9679 67.3026 32.3939 66.6253 32.3939H64.1519C62.503 32.2429 61.1485 30.8532 61.0013 29.1613C60.854 27.4695 61.973 25.9288 63.5925 25.5058C63.8575 25.3548 64.1519 25.3548 64.4169 25.3548V24.2369C64.4169 23.5421 64.9764 23.1191 65.5064 23.1191C66.1836 23.1191 66.5959 23.5421 66.5959 24.2369C66.7431 24.6599 66.5959 24.9318 66.5959 25.3548H68.922C69.5993 25.3548 70.0115 25.9288 70.0115 26.4726C70.0115 27.1674 69.5993 27.7414 68.7748 27.7414H64.2403C63.6808 27.8925 63.2686 28.3154 63.2686 28.8592C63.2686 29.403 63.6808 29.977 64.2403 29.977H66.5664C68.3626 30.1281 69.8643 31.397 69.9821 33.0586L70.0115 33.0888Z"
            fill="#9D9D9C"
          />
          <path
            d="M70.0115 33.0888C70.1587 34.9014 69.0398 36.4724 67.4203 36.8954C67.1553 37.0464 66.8609 37.0464 66.5959 37.0464C66.5959 37.4694 66.7431 37.7413 66.5959 38.1642C66.5959 38.8591 66.1836 39.282 65.5064 39.282C64.8292 39.282 64.4169 38.8591 64.4169 38.1642V37.0464H62.0907C61.4135 37.0464 61.0013 36.4724 61.0013 35.7776C61.0013 35.0827 61.5607 34.6597 62.238 34.6597H66.6253C67.3026 34.6597 67.7148 34.2368 67.862 33.6628C67.862 32.9679 67.3026 32.3939 66.6253 32.3939H64.1519C62.503 32.2429 61.1485 30.8532 61.0013 29.1613C60.854 27.4695 61.973 25.9288 63.5925 25.5058C63.8575 25.3548 64.1519 25.3548 64.4169 25.3548V24.2369C64.4169 23.5421 64.9764 23.1191 65.5064 23.1191C66.1836 23.1191 66.5959 23.5421 66.5959 24.2369C66.7431 24.6599 66.5959 24.9318 66.5959 25.3548H68.922C69.5993 25.3548 70.0115 25.9288 70.0115 26.4726C70.0115 27.1674 69.5993 27.7414 68.7748 27.7414H64.2403C63.6808 27.8925 63.2686 28.3154 63.2686 28.8592C63.2686 29.403 63.6808 29.977 64.2403 29.977H66.5664C68.3626 30.1281 69.8643 31.397 69.9821 33.0586L70.0115 33.0888Z"
            fill="#DADADA"
          />
          <g filter="url(#filter9_i_8207_106245)">
            <path
              d="M70.571 23.0729C69.3679 22.0339 67.8345 21.4648 66.1831 21.2174C65.8528 21.0937 65.5225 21.0938 65.0743 21.0938C60.1438 21.0938 56.0625 25.349 56.0625 30.6432C56.0625 31.6823 56.2748 32.7214 56.6051 33.6367C57.8082 37.3229 61.2289 40.0938 65.0507 40.0938C67.1267 40.0938 69.014 39.401 70.5474 38.1393C72.6234 36.4076 74.0625 33.6367 74.0625 30.6432C74.0625 27.6497 72.6234 24.7799 70.5474 23.0482L70.571 23.0729ZM64.0835 29.6289H65.9472C67.3862 29.7526 68.5894 30.7917 68.6837 32.1523C68.8017 33.6367 67.9052 34.9232 66.6077 35.2695C66.3954 35.3932 66.1595 35.3932 65.9472 35.3932C65.9472 35.7396 66.0651 35.9622 65.9472 36.3086C65.9472 36.8776 65.6169 37.224 65.0743 37.224C64.5317 37.224 64.2014 36.8776 64.2014 36.3086V35.3932H62.3377C61.7951 35.3932 61.4649 34.9232 61.4649 34.3542C61.4649 33.7852 61.9131 33.4388 62.4557 33.4388H65.9708C66.5134 33.4388 66.8436 33.0924 66.9616 32.6224C66.9616 32.0534 66.5134 31.5833 65.9708 31.5833H63.9891C62.668 31.4596 61.5828 30.3216 61.4649 28.9362C61.3469 27.5508 62.2434 26.2891 63.5409 25.9427C63.7532 25.819 63.9891 25.819 64.2014 25.819V24.9036C64.2014 24.3346 64.6497 23.9883 65.0743 23.9883C65.6169 23.9883 65.9472 24.3346 65.9472 24.9036C66.0651 25.25 65.9472 25.4727 65.9472 25.819H67.8109C68.3535 25.819 68.6837 26.2891 68.6837 26.7344C68.6837 27.3034 68.3535 27.7734 67.6929 27.7734H64.0599C63.6116 27.8971 63.2814 28.2435 63.2814 28.6888C63.2814 29.1341 63.6116 29.6042 64.0599 29.6042L64.0835 29.6289Z"
              fill="url(#paint1_linear_8207_106245)"
            />
          </g>
          <path
            d="M69.051 32.1124C69.1813 33.5704 68.1907 34.8339 66.757 35.1741C66.5223 35.2956 66.2617 35.2956 66.027 35.2956C66.027 35.6358 66.1574 35.8545 66.027 36.1947C66.027 36.7536 65.6621 37.0938 65.0625 37.0938C64.4629 37.0938 64.098 36.7536 64.098 36.1947V35.2956H62.0385C61.439 35.2956 61.074 34.8339 61.074 34.2751C61.074 33.7162 61.5693 33.376 62.1689 33.376H66.0531C66.6527 33.376 67.0176 33.0358 67.148 32.5741C67.148 32.0152 66.6527 31.5536 66.0531 31.5536H63.8633C62.4035 31.4321 61.2043 30.3143 61.074 28.9536C60.9437 27.5928 61.9343 26.3536 63.368 26.0134C63.6027 25.8919 63.8633 25.8919 64.098 25.8919V24.9928C64.098 24.4339 64.5933 24.0938 65.0625 24.0938C65.6621 24.0938 66.027 24.4339 66.027 24.9928C66.1574 25.333 66.027 25.5517 66.027 25.8919H68.0865C68.686 25.8919 69.051 26.3536 69.051 26.7909C69.051 27.3498 68.686 27.8115 67.9561 27.8115H63.9416C63.4462 27.933 63.0813 28.2732 63.0813 28.7106C63.0813 29.148 63.4462 29.6096 63.9416 29.6096H66.001C67.5912 29.7311 68.9207 30.7517 69.0249 32.0881L69.051 32.1124Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_8207_106245"
          x="0"
          y="0"
          width="101"
          height="101"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.12 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8207_106245"
          x="8"
          y="6"
          width="85"
          height="85"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8207_106245"
          x="25.9375"
          y="20"
          width="42"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_8207_106245"
          x="30.7578"
          y="26.8047"
          width="18.7539"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_8207_106245"
          x="30.7578"
          y="37.6484"
          width="9.30078"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_8207_106245"
          x="38.9453"
          y="37.6484"
          width="18.2227"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_8207_106245"
          x="30.7578"
          y="48.5254"
          width="26.4062"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_8207_106245"
          x="30.7578"
          y="59.373"
          width="19.2812"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_8207_106245"
          x="53.5625"
          y="17.5938"
          width="25"
          height="26"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8207_106245" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8207_106245"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_i_8207_106245"
          x="56.0625"
          y="21.0938"
          width="18"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.745833 0 0 0 0 0.402423 0 0 0 0 0.189566 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8207_106245" />
        </filter>
        <linearGradient
          id="paint0_linear_8207_106245"
          x1="21.9754"
          y1="-94.9194"
          x2="23.3125"
          y2="98.0588"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00455753" stopColor="#B7CAEA" />
          <stop offset="0.533268" stopColor="#FAFBFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8207_106245"
          x1="59.7367"
          y1="5.87386"
          x2="85.3556"
          y2="53.7507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0BC36" />
          <stop offset="1" stopColor="#D35917" />
        </linearGradient>
      </defs>
    </svg>
  );
};
