import { Switch as GestaltSwitch, SwitchProps } from "gestalt";
import { FunctionComponent } from "react";
import { ColorConfig } from "../utils";
import { Box } from "./Box";
import { useColors } from "./ColorSchemeProvider";
import { TapArea } from "./TapArea";
import { Text } from "./Text";

export type { SwitchProps };

export function Switch(props: SwitchProps) {
  return <GestaltSwitch {...props} />;
}

type SwitchState = boolean | undefined;

interface TriStateSwitchProps {
  value: SwitchState | undefined;
  onChange(value: SwitchState): void;
  onLabel?: string;
  offLabel?: string;
  offColor?: ColorConfig;
  undefinedLabel?: string;
  undefinedColor?: ColorConfig;
  width?: number;
}

export const TriStateSwitch: FunctionComponent<TriStateSwitchProps> = ({
  value,
  onChange,
  onLabel,
  offLabel,
  offColor,
  undefinedLabel,
  undefinedColor,
  width = 100,
}) => {
  const { colors } = useColors();
  const offStateColor = offColor === undefined ? colors.error : offColor;
  const nextState = () => {
    switch (value) {
      case true:
        onChange(false);
        break;
      case false:
        onChange(undefined);
        break;
      case undefined:
        onChange(true);
        break;
    }
  };

  return (
    <TapArea role="switch" onTap={nextState}>
      <Box
        position="relative"
        borderStyle="lg"
        borderColor={colors.neutral700}
        borderRadius={48}
        height={32}
        width={width}
        maxWidth={width}
      >
        <Box
          display="block"
          position="absolute"
          right={value === true ? true : undefined}
          left={value === false ? true : undefined}
          borderRadius={48}
          width={value === undefined ? "100%" : "auto"}
          height={30}
          color={
            value === undefined
              ? undefinedColor || colors.neutral900
              : value === false
              ? offStateColor
              : colors.primary
          }
          paddingX={3}
          paddingTop={1}
        >
          <Text weight="bold" size="200" color={colors.white} align="center">
            {value === undefined ? undefinedLabel : value === true ? onLabel : offLabel}
          </Text>
        </Box>
      </Box>
    </TapArea>
  );
};
