import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const LandingPageCardIcon3: FunctionComponent<Props> = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.58138 2.51864C8.25239 1.84764 9.43651 1.13717 10.2259 0.939812C11.0153 0.702988 12.6336 0.54511 13.8572 0.624051C15.0808 0.663522 16.2649 0.900346 16.5412 1.21611C16.7781 1.49241 17.0938 2.12394 17.2122 2.59759C17.3701 3.22912 17.1728 3.7817 16.5018 4.49218C15.7124 5.28159 15.1203 5.47894 13.423 5.47894C12.0021 5.47894 11.1338 5.6763 10.6206 6.14995C10.2259 6.54466 9.94963 7.37354 9.94963 8.04454C9.94963 8.67607 10.2654 9.54443 10.6601 9.89967C11.2916 10.5707 12.3968 10.6101 24.7511 10.6101C35.4477 10.5707 38.3291 10.6891 38.9606 11.1233C39.3948 11.3995 39.829 12.1495 39.9474 12.7416C40.1053 13.4915 39.9079 14.0441 39.3553 14.5967C38.6054 15.3072 37.9344 15.3466 24.8696 15.3466C17.2912 15.3466 10.6601 15.2282 10.068 15.0703C9.4365 14.9519 8.3708 14.2809 7.66033 13.6099C6.91039 12.9389 6.04203 11.7548 5.64732 11.0048C5.25261 10.2154 4.97632 8.83395 5.01579 7.84719C5.05526 6.86042 5.37103 5.51841 5.68679 4.88688C6.04203 4.21588 6.91038 3.15017 7.58138 2.51864Z"
        fill="white"
      />
      <path
        d="M2.05548 20.5568C2.92384 19.7279 4.22637 18.8595 4.93684 18.6227C5.8052 18.3069 11.0943 18.149 21.9882 18.1096C34.0662 18.1096 38.0133 18.1885 38.7633 18.5832C39.4737 18.9779 39.7895 19.4516 39.8684 20.4778C39.9474 21.3856 39.75 22.0567 39.2764 22.5303C38.6448 23.1618 37.2634 23.2408 22.3829 23.2408C7.66033 23.2408 6.16044 23.3197 5.48944 23.9118C5.09473 24.3065 4.81843 25.0564 4.81843 25.609C4.81843 26.1221 5.05525 26.9115 5.37102 27.3852C5.84467 27.9773 6.47621 28.1746 8.44974 28.2535C10.147 28.3325 11.1732 28.6088 11.6469 29.043C12.1995 29.5561 12.3179 30.1087 12.16 31.1349C12.0416 32.0427 11.6469 32.6348 11.0548 32.9506C10.5417 33.1874 9.08127 33.3453 7.77874 33.2663C5.96308 33.2269 4.97632 32.9111 3.5159 32.0427C2.13443 31.2138 1.38449 30.4244 0.752954 29.1614C0.318776 28.1746 -0.0364698 26.8326 0.00300094 26.2011C0.0424717 25.5301 0.160889 24.346 0.279301 23.5171C0.437184 22.4514 0.950303 21.6225 2.05548 20.5568Z"
        fill="white"
      />
      <path
        d="M21.1198 29.9508C21.5935 28.9245 22.6592 27.701 23.567 27.0694C25.1459 26.0037 25.1853 26.0037 31.9743 26.0037C38.2501 26.0037 38.8027 26.0432 39.4737 26.7537C39.9079 27.2668 40.0658 27.8983 39.9474 28.6088C39.829 29.1614 39.3948 29.9113 38.9606 30.1876C38.3685 30.5823 36.6318 30.7402 32.369 30.7402C26.9615 30.7402 26.4879 30.7797 25.8563 31.5296C25.4616 31.9638 25.1064 32.7137 25.0274 33.1874C24.988 33.7005 25.1853 34.411 25.5011 34.8846C25.9353 35.4767 26.7247 35.7135 28.7772 35.9109C30.7112 36.1082 31.619 36.3845 31.9743 36.8976C32.2506 37.2923 32.4479 38.0423 32.4479 38.5159C32.4479 38.9896 32.0927 39.7001 31.698 40.0948C31.0665 40.6868 30.4349 40.7658 27.8299 40.6079C25.1064 40.4105 24.5143 40.2132 23.1723 39.2264C22.3434 38.5554 21.3172 37.2923 20.9225 36.4635C20.5672 35.5951 20.212 34.1347 20.212 33.3058C20.212 32.398 20.6067 30.9376 21.1198 29.9508Z"
        fill="white"
      />
    </svg>
  );
};
