import { FunctionComponent } from "react";
import { Page } from "../../../components/Layout/Page";
import { useEditApprovalType } from "./hooks";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Text,
  TextField,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { ComboBoxWithSelectedTag } from "./ComboBoxWithSelectedTag";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { organizationLinkTemplates } from "../../../link-templates";

const MaxLabelLength = 100;

const EditApprovalTypePage: FunctionComponent = () => {
  const { breakpoint } = useDesignBreakpoint();
  const {
    id,
    colors,
    resources,
    breadCrumbEntries,
    organizationNavigate,

    isLoading,
    isSaving,
    formState,
    setFormState,
    handleSubmit,
    rolesOptions,
    selectedRoles,
    onSelectRole,
    onRemoveRole,
  } = useEditApprovalType();

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <Box paddingY={4}>
          <Grid gridTemplateColumns={[11, 1]}>
            <Box marginStart={12}>
              <Text weight="bold" size="400">
                {id ? formState.label : resources.approvalType.newApprovalType}
              </Text>
            </Box>
            <Box>
              <Box marginEnd={12} width={90} marginStart="auto"></Box>
            </Box>
          </Grid>
        </Box>

        <Box
          color={colors.white}
          paddingY={8}
          paddingX={6}
          marginTop={2}
          marginStart={12}
          marginEnd={12}
          borderStyle="lg"
          borderRadius={4}
        >
          <Skeleton height={400} show={isLoading}>
            <Box
              alignItems="center"
              display={breakpoint === "hd" || breakpoint === "large" ? "flex" : undefined}
              paddingX={breakpoint === "hd" || breakpoint === "large" ? 6 : 0}
              paddingY={breakpoint === "hd" || breakpoint === "large" ? 4 : 0}
            >
              <Box minWidth={170} marginBottom={3}>
                <Text color={colors.subtle}>{resources.label}</Text>
              </Box>
              <Box width="100%">
                <TextField
                  placeholder={resources.label}
                  id="label"
                  value={formState.label}
                  disabled={isLoading || isSaving}
                  onChange={(e) => {
                    setFormState((prev) => ({
                      ...prev,
                      label: e.value,
                    }));
                  }}
                  maxLength={{
                    characterCount: MaxLabelLength,
                    errorAccessibilityLabel: resources.maximumCharactersAllowed,
                  }}
                />
              </Box>
            </Box>
            <ComboBoxWithSelectedTag
              isLoading={isLoading || isSaving}
              onRemove={onRemoveRole}
              onSelect={onSelectRole}
              options={rolesOptions}
              selectedOptions={selectedRoles}
              availableLabel={resources.userManagementAndRoles.userRoles.availableRoles}
              selectedLabel={resources.userManagementAndRoles.userRoles.selectedRoles}
            />
            {formState.toastMessage && (
              <NotificationMessage
                type={formState.toastMessage.type}
                message={formState.toastMessage.message}
                position="bottom-left"
                delay={3000}
                handleDismiss={() => setFormState({ ...formState, toastMessage: undefined })}
              />
            )}
            <Box marginTop={5} width={"100%"} display="flex">
              <Box>
                <Button
                  color={"transparent"}
                  onClick={() =>
                    organizationNavigate(organizationLinkTemplates.manageApprovalTypes())
                  }
                  disabled={isLoading || isSaving}
                  text={resources.cancel}
                />
              </Box>
              <Box marginStart="auto" justifyContent="end">
                <Button
                  color={"blue"}
                  disabled={isLoading || isSaving || !formState.label || !formState.roles.length}
                  onClick={handleSubmit}
                  text={resources.saveChanges}
                />
              </Box>
            </Box>
          </Skeleton>
        </Box>
      </Box>
    </Page>
  );
};

export default EditApprovalTypePage;
