import { Box, Button, Text, TextField, useColors } from "@prodoctivity/design-system";
import { FC, useCallback, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { NotificationMessage } from "../../components/NotificationMessage";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";

type props = {
  showLeaveOrg: boolean;
  setShowLeaveOrganization: (n: boolean) => void;
  organizationId: string;
};
const LeaveOrganization: FC<props> = ({ organizationId, setShowLeaveOrganization }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { leaveOrganization } = useServices();
  const [inputOrganizationId, setInputOrganizationId] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  const LeaveOrganization = useCallback(async () => {
    if (inputOrganizationId) {
      await leaveOrganization(inputOrganizationId);
      return true;
    }
    return false;
  }, [inputOrganizationId, leaveOrganization]);

  const { data: _dataN, isLoading } = useOrganizationQuery("leave-organization", LeaveOrganization);

  const mutation = useMutation(LeaveOrganization, {
    onSuccess() {
      setToastMessage(resources.leaveOrganizationPage.successfullyLeftOrganization);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4500);
      navigate(organizationLinkTemplates.login());
    },

    onError(error: {
      name: string;
      message: string;
      response: {
        data: {
          errors: Array<{ errorCode: string; message: string }>;
        };
      };
    }) {
      const errorType = error?.response.data.errors[0].message;
      switch (errorType) {
        case "organization-not-found":
          setToastMessage(resources.leaveOrganizationPage.checkOrganizationId);
          break;
        default:
          setToastMessage(resources.leaveOrganizationPage.couldntLeaveOrganization);
      }
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4500);
    },
  });
  const handleSubmit = useCallback(async () => {
    mutation.mutate();
  }, [mutation]);

  return (
    <Box padding={4} color={colors.white}>
      <Text align="center" weight="bold" size="400" color={colors.error}>
        {resources.leaveOrganizationPage.aboutLeaveOrganization}
      </Text>
      <Text align="center" weight="bold" size={"200"} color={colors.black600}>
        {resources.leaveOrganizationPage.toLeaveOrganization.replace("{orgId}", organizationId)}
      </Text>
      <Box padding={2} justifyContent="center">
        <TextField
          value={inputOrganizationId}
          maxLength={{
            characterCount: 50,
            errorAccessibilityLabel: resources.maximumCharactersAllowed,
          }}
          type={"text"}
          onChange={(e) => setInputOrganizationId(e.value)}
          id="organizartionNameField"
          placeholder={resources.myOrganization}
        />
      </Box>

      <Box display="flex" direction="row" flex="grow" gap={4} justifyContent="end">
        <Button
          color={"transparent"}
          type="button"
          text={resources.cancel}
          size="lg"
          accessibilityLabel={resources.cancel}
          onClick={() => setShowLeaveOrganization(false)}
        />

        <Button
          type="button"
          disabled={isLoading || inputOrganizationId !== organizationId}
          size="lg"
          text={resources.leaveOrganization}
          accessibilityLabel={resources.leaveOrganization}
          color={"red"}
          onClick={handleSubmit}
        />
      </Box>
      <Box>
        {showToast ? (
          <NotificationMessage
            type={"error"}
            message={toastMessage}
            position="bottom-left"
            handleDismiss={() => setShowToast(false)}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export { LeaveOrganization };
