import { calculateColor, ColorConfig } from "../../../utils";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const SendMessageIcon: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 30,
  height = 31,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={customClassName}
      onClick={onClick}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2597 15.4998H8.33305L6.68555 8.94567C6.67501 8.90758 6.66858 8.86847 6.66639 8.829C6.64805 8.22817 7.30972 7.8115 7.88305 8.0865L23.3331 15.4998L7.88305 22.9132C7.31639 23.1857 6.66305 22.7807 6.66639 22.1907C6.66808 22.138 6.67734 22.0858 6.69389 22.0357L7.91639 17.9998"
        stroke={color ? calculateColor(color) : "#00185E"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
