import { useRef } from "react";

export * from "./InputFormUtils";
export * from "./input";
export * from "./pub-sub";

export function useWhatChanged(data: Record<string, unknown>) {
  const dataRef = useRef(data);

  for (const key in data) {
    if (data[key] !== dataRef.current[key]) {
      console.log(`prop "${key}" changed`);
    }
  }

  dataRef.current = data;
}
