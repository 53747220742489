import {
  Box,
  Button,
  Divider,
  Spinner,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, type DragEvent } from "react";

import { createUuid } from "@prodoctivity/shared";
import type { MimeType, ParametersObject } from "@prodoctivity/shared/src/index-types";
import type { BreadCrumbEntry } from "../../../components/BreadCrumb";

import type { DocumentTypeInfo, DocumentTypeResume } from "@prodoctivity/types";
import { Page } from "../../../components/Layout/Page";
import { StateProps } from "../../../components/LookupDocument/LookupDocument";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { useDocumentWordToPdf } from "../../../components/hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useSettings } from "../../../hooks/useSettings";
import { organizationLinkTemplates } from "../../../link-templates";
import { Services } from "../../../services";
import { CircleInfoSvgIcon } from "../../../svg/CircleInfoSvgIcon";
import { ImportADocumentSvg } from "../../../svg/ImportADocumentSvg";
import { ImportCloudSvgIcon } from "../../../svg/ImportCloudSvgIcon";
import { Indexing } from "./Indexing";
import { ScannerServiceStatus } from "./components/Scan";
import { ScannerPanel } from "./components/ScannerPanel";
import { ScannerImage } from "./components/utils/decode-image";
import { useImportDocument } from "./hooks";

type Props = {
  mode: "scan" | "import" | "all";
  handleShowInstructionTask: (() => void) | undefined;
  documentTypeInfo?: DocumentTypeInfo | undefined;
  context?: ParametersObject;
  isInstructionsTask: boolean;
  disableDocumentTypesFromTaskInstructions?: boolean;
};

const IndexDocumentPage: FunctionComponent<Props> = ({
  mode,
  handleShowInstructionTask,
  documentTypeInfo,
  context,
  isInstructionsTask,
  disableDocumentTypesFromTaskInstructions,
}) => {
  const { colors } = useColors();
  const {
    STEPS,
    setTabIndex,
    resources,
    breakpoint,

    isLoading,
    isRefetching,
    selectedTabIndex,
    documentTypes,

    identifierConfig,
    mutateSaveDocument,
    onPrev,
    onNext,
    onClearAll,
    execDataLink,
    handleFileUpload,
    clickFileInput,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    onFormValuesChange,
    setDocumentWithSameIdentifier,
    onDocumentTypeSelect,
    disableComponents,
    disableSave,
    state,
    setState: _setState,
    fileInputRef,
    acceptedFormats,
    documentLoadingState,
    setDocumentLoadingState,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
    extractedContext,
    receivePage,
    onScanServiceReady,
    onDeviceChange,
    onDevicesReady,
    onCancelCaptureImages,
    updatePageList,
  } = useImportDocument({
    mode,
    handleShowInstructionTask,
    documentTypeInfo,
    context,
    isInstructionsTask,
  });

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "text",
        name: mode === "import" ? resources.importADocument : resources.scanADocument,
      },
    ];
  }, [resources.home, resources.importADocument, resources.scanADocument, mode]);

  const wordToPdfKey = useMemo(() => {
    return `index-document/${state.files.length ? createUuid() : undefined}`;
  }, [state.files]);
  const wordToPdfHookRef = useDocumentWordToPdf(wordToPdfKey);

  const onClear = useCallback(() => {
    onClearAll();
    wordToPdfHookRef.setWordDataUri();
  }, [onClearAll, wordToPdfHookRef]);

  const isMobile = useMemo(() => {
    return breakpoint === "small";
  }, [breakpoint]);
  const mobileTitle = useMemo(() => {
    return selectedTabIndex === STEPS.Index
      ? resources.sendViaEmailPage.documentViewer
      : resources.indexDocument.indexing;
  }, [
    STEPS.Index,
    resources.indexDocument.indexing,
    resources.sendViaEmailPage.documentViewer,
    selectedTabIndex,
  ]);
  const mobileBackFn = useCallback(() => {
    onClear();
    setTabIndex(-1);
  }, [onClear, setTabIndex]);

  const screenHasContent = useMemo(() => {
    return (
      (mode === "import" && state.files.length > 0) ||
      (mode === "scan" && state.filenames.length > 0)
    );
  }, [mode, state.filenames.length, state.files.length]);

  const renderTextButton = useMemo(() => {
    return selectedTabIndex > STEPS.CaptureAndReview
      ? screenHasContent
        ? resources.save
        : resources.continue
      : resources.continue;
  }, [STEPS.CaptureAndReview, resources, screenHasContent, selectedTabIndex]);

  const instructionTask = useMemo(() => {
    if (handleShowInstructionTask) {
      return undefined;
    } else {
      return breadCrumbEntries;
    }
  }, [breadCrumbEntries, handleShowInstructionTask]);
  if (isLoading || isRefetching) {
    return (
      <Page breadCrumbEntries={isMobile ? undefined : instructionTask}>
        <Spinner show={true} accessibilityLabel={resources.loading}></Spinner>
      </Page>
    );
  }

  const isVideoFormat = state.files.map((f) => f.file.type).includes("video/mp4");
  const isAudioFormat = state.files.map((f) => f.file.type).includes("audio/mpeg");
  const audioFormat = isAudioFormat ? "audio/mpeg" : undefined;

  const displayCaptureContainer =
    (mode === "import" && state.files.length <= 0) ||
    (mode === "scan" && state.filenames.length <= 0);

  return (
    <Page
      breadCrumbEntries={isMobile ? undefined : instructionTask}
      mobileHeading={
        isMobile
          ? {
              title: mobileTitle,
              backArrowFn: selectedTabIndex === STEPS.Index ? mobileBackFn : undefined,
              rightButtonIcon: undefined,
            }
          : undefined
      }
    >
      <Box display="flex" direction="column" minWidth={360} flex="grow">
        <input
          name="importInput"
          type="file"
          accept={acceptedFormats}
          multiple={true}
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        {!isMobile && (
          <ImportHeader
            acceptedFormats={acceptedFormats}
            clickFileInput={clickFileInput}
            files={state.files}
            handleDragLeave={handleDragLeave}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            isDragging={state.isDragging}
            mode={mode}
            onClear={onClear}
            screenHasContent={screenHasContent}
          />
        )}
        <Box display="flex" direction="column" flex="grow">
          {displayCaptureContainer ? (
            <CaptureContainerByCurrentMode
              acceptedFormats={acceptedFormats}
              clickFileInput={clickFileInput}
              devices={state.device}
              handleDragLeave={handleDragLeave}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              isDragging={state.isDragging}
              mode={mode}
              onDeviceChange={onDeviceChange}
              onDevicesReady={onDevicesReady}
              onScanServiceReady={onScanServiceReady}
              receivePage={receivePage}
              scanner={state.scanner}
              serviceState={state.serviceState}
              onCancelCaptureImages={onCancelCaptureImages}
            />
          ) : (
            <IndexContainer
              audioFormat={audioFormat}
              context={state.context}
              disableComponents={disableComponents}
              documentLoadingState={documentLoadingState}
              documentTypeSelected={state.documentTypeSelected}
              documentTypes={documentTypes}
              execDataLink={execDataLink}
              extractedContext={extractedContext}
              files={state.files}
              isDocumentSearchOpen={isDocumentSearchOpen}
              isVideoFormat={isVideoFormat}
              loadingForm={state.loadingForm}
              mimeTypeSelected={state.mimeTypeSelected || "application/pdf"}
              onDocumentTypeSelect={onDocumentTypeSelect}
              onFormValuesChange={onFormValuesChange}
              setDocumentLoadingState={setDocumentLoadingState}
              setDocumentSearchOpen={setDocumentSearchOpen}
              setDocumentWithSameIdentifier={setDocumentWithSameIdentifier}
              wordToPdfHookRef={wordToPdfHookRef}
              disableDocumentTypesFromTaskInstructions={disableDocumentTypesFromTaskInstructions}
              updatePageList={updatePageList}
            />
          )}
        </Box>
        {state.toastMessage && (
          <NotificationMessage
            type={state.toastMessage.type}
            message={state.toastMessage.message}
            position="bottom-left"
            handleDismiss={() => _setState((prev) => ({ ...prev, toastMessage: undefined }))}
          />
        )}
        <Box color={colors.neutral300} borderStyle="lg" borderRadius={4} display="flex">
          {state.documentTypeSelected && identifierConfig && state.documentWithSameIdentifier && (
            <Box width={"100%"} paddingX={2}>
              <Text align="center" color={colors.secondary}>
                {(state.documentTypeSelected.identifierCollisionForcesNewVersion
                  ? resources.formDesigner.createNewVersionIfSave
                  : resources.formDesigner.createNewVersionOrDocument
                ).replace("{{identifierConfig}}", identifierConfig)}
              </Text>
              <Divider />
            </Box>
          )}
          <Box
            display="flex"
            direction="row"
            flex="grow"
            alignItems="center"
            justifyContent="end"
            gap={4}
            padding={2}
          >
            {mode === "all" && selectedTabIndex > STEPS.CaptureAndReview && (
              <Button
                color={"transparent"}
                disabled={disableComponents}
                onClick={onPrev}
                text={resources.previousStep}
              />
            )}

            {(selectedTabIndex > STEPS.CaptureAndReview || !state.documentWithSameIdentifier) && (
              <Box color={colors.primary} borderRadius={6} opacity={disableSave ? 0.6 : undefined}>
                <TapArea
                  onTap={() => {
                    if (disableSave) return;
                    onNext();
                  }}
                >
                  <Box
                    width={"100%"}
                    paddingY={2}
                    paddingX={4}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minWidth={200}
                  >
                    <Text color={colors.white}>{renderTextButton}</Text>
                  </Box>
                </TapArea>
              </Box>
            )}
            {selectedTabIndex === STEPS.Index && state.documentWithSameIdentifier && (
              <Box
                display="flex"
                width={breakpoint === "medium" ? 450 : 650}
                justifyContent={
                  !state.documentTypeSelected?.identifierCollisionForcesNewVersion
                    ? "between"
                    : "end"
                }
                alignItems="center"
                gap={2}
                direction={breakpoint === "small" ? "column" : "row"}
              >
                {!state.documentTypeSelected?.identifierCollisionForcesNewVersion && (
                  <Box display="flex">
                    <Button
                      onClick={() => mutateSaveDocument(false)}
                      color={"gray"}
                      disabled={disableComponents}
                      text={resources.newDocument}
                    />
                  </Box>
                )}
                <Box display="flex">
                  <Button
                    onClick={() => mutateSaveDocument(true)}
                    color={"blue"}
                    disabled={disableComponents}
                    text={resources.updateDocument}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

const IndexContainer: FunctionComponent<{
  isVideoFormat: boolean;
  audioFormat: "audio/mpeg" | undefined;
  documentTypes: DocumentTypeResume[];
  documentTypeSelected: DocumentTypeInfo | undefined;
  mimeTypeSelected: MimeType;
  context: ParametersObject;
  extractedContext: ParametersObject | undefined;
  disableComponents: boolean;
  files: Array<{
    file: File;
    type: MimeType | undefined;
  }>;
  isDocumentSearchOpen: boolean;
  setDocumentSearchOpen(n: boolean): void;
  documentLoadingState: StateProps;
  setDocumentLoadingState(n: StateProps): void;
  loadingForm: boolean;
  execDataLink: Services["executeDataLink"];
  onFormValuesChange(values: ParametersObject): void;
  onDocumentTypeSelect(docType: DocumentTypeResume): void;
  setDocumentWithSameIdentifier(documentVersionId: string | undefined): void;
  disableDocumentTypesFromTaskInstructions?: boolean;
  wordToPdfHookRef:
    | undefined
    | {
        pdfDataUri: string | undefined;
        setWordDataUri: (wordDataUri?: string | undefined) => void;
      };
  updatePageList: (newFiles: Array<File>) => void;
}> = ({
  documentTypes,
  documentTypeSelected,
  mimeTypeSelected,
  context,
  extractedContext,
  files,
  disableComponents,
  isDocumentSearchOpen,
  setDocumentSearchOpen,
  documentLoadingState,
  setDocumentLoadingState,
  loadingForm,
  execDataLink,
  onFormValuesChange,
  onDocumentTypeSelect,
  setDocumentWithSameIdentifier,
  wordToPdfHookRef,
  disableDocumentTypesFromTaskInstructions,
  updatePageList,
}) => {
  const _handleImagesChanges = useCallback(
    (images: Array<string | Blob>) => {
      const blobToFile = (blob: Blob, fileName: string): File =>
        new File([blob], fileName, { type: blob.type });

      const stringToFile = (base64: string, fileName: string, mimeType: string): File =>
        blobToFile(new Blob([base64], { type: mimeType }), fileName);

      const imagesList: Array<File> = images.map((x) => {
        const newName = createUuid();
        if (typeof x === "string") {
          return stringToFile(x, newName, mimeTypeSelected);
        }
        return blobToFile(x, newName);
      });
      updatePageList(imagesList);
    },
    [mimeTypeSelected, updatePageList]
  );

  return (
    <Indexing
      documentTypes={documentTypes}
      documentTypeSelected={documentTypeSelected}
      context={context}
      files={files}
      mimeType={mimeTypeSelected}
      disabled={disableComponents}
      loadingForm={loadingForm}
      executeDataLink={execDataLink}
      onDocumentTypeSelect={onDocumentTypeSelect}
      onFormValuesChange={onFormValuesChange}
      setDocumentWithSameIdentifier={setDocumentWithSameIdentifier}
      wordToPdfHookRef={wordToPdfHookRef}
      documentLoadingState={documentLoadingState}
      setDocumentLoadingState={setDocumentLoadingState}
      isDocumentSearchOpen={isDocumentSearchOpen}
      setDocumentSearchOpen={setDocumentSearchOpen}
      extractedContext={extractedContext}
      disableDocumentTypesFromTaskInstructions={disableDocumentTypesFromTaskInstructions}
      onFilesChange={(images) => {
        const stringToFile = (base64: string, fileName: string, mimeType: string): File => {
          const blob = new Blob([base64], { type: mimeType });
          return new File([blob], fileName, { type: mimeType });
        };

        const blobToFile = (blob: Blob, fileName: string): File =>
          new File([blob], fileName, { type: blob.type });

        const imagesList: Array<File> = images.map((pageImage) => {
          const newName = createUuid();
          return typeof pageImage === "string"
            ? stringToFile(pageImage, newName, "image/jpeg")
            : blobToFile(pageImage, newName);
        });
        updatePageList(imagesList);
      }}
    />
  );
};

const CaptureContainerByCurrentMode: FunctionComponent<{
  mode: Props["mode"];
  acceptedFormats: string;
  clickFileInput(): void;
  handleDragLeave(): void;
  handleDragOver: (event: undefined | React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: undefined | React.DragEvent<HTMLDivElement>) => void;
  isDragging: boolean;
  onDevicesReady(devices: string[]): void;
  onDeviceChange(device: string): void;
  devices: string[];
  scanner: string;
  serviceState: ScannerServiceStatus;
  receivePage(scannedImage: ScannerImage): void;
  onScanServiceReady(st: ScannerServiceStatus): void;
  onCancelCaptureImages: () => void;
}> = ({
  mode,
  acceptedFormats,
  clickFileInput,
  handleDrop,
  handleDragOver,
  handleDragLeave,
  isDragging,
  onDevicesReady,
  onDeviceChange,
  devices,
  scanner,
  serviceState,
  receivePage,
  onScanServiceReady,
  onCancelCaptureImages,
}) => {
  if (mode === "import") {
    return (
      <DragAndDropArea
        acceptedFormats={acceptedFormats}
        clickFileInput={clickFileInput}
        handleDragLeave={handleDragLeave}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        isDragging={isDragging}
      />
    );
  }
  return (
    <ScannerPanel
      onReceivePage={receivePage}
      serviceState={serviceState}
      setScanServiceState={onScanServiceReady}
      setDevice={onDeviceChange}
      currentDevice={scanner}
      devicesReady={onDevicesReady}
      devices={devices}
      cancelCaptureImages={onCancelCaptureImages}
    />
  );
};

const ImportHeader: FunctionComponent<{
  mode: Props["mode"];
  files: Array<{
    file: File;
    type: MimeType | undefined;
  }>;
  acceptedFormats: string;
  isDragging: boolean;
  screenHasContent: boolean;
  handleDragOver: (event: undefined | React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: undefined | React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave(): void;
  clickFileInput(): void;
  onClear(): void;
}> = ({
  mode,
  files,
  acceptedFormats,
  isDragging,
  screenHasContent,
  handleDragOver,
  handleDrop,
  handleDragLeave,
  clickFileInput,
  onClear,
}) => {
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const { colors } = useColors();

  const isMediumOrSmallBreakpoint = breakpoint === "medium" || breakpoint === "small";

  return (
    <Box
      display="flex"
      direction="column"
      flex="shrink"
      color={colors.neutral250}
      dangerouslySetInlineStyle={{
        __style: {
          borderBottom: "1px solid #E5E5E5",
        },
      }}
    >
      <Box
        onDragEnter={handleDragOver}
        onDragOver={handleDragOver}
        padding={3}
        alignItems="center"
        justifyContent="between"
        display="flex"
        direction={isMediumOrSmallBreakpoint ? "column" : "row"}
      >
        {mode === "import" ? (
          <Box
            height={30}
            alignItems="center"
            display="flex"
            onClickCapture={clickFileInput}
            flex="grow"
          >
            <CircleInfoSvgIcon type="exclamation-inverted" color={colors.black600} />
            <Box>
              {breakpoint === "small" || breakpoint === "medium" ? (
                <Text color={colors.black600} size="200">
                  {resources.indexDocument.clickHereAndUploadYourFile}
                </Text>
              ) : (
                <Text color={colors.black600} size="200">
                  {resources.indexDocument.captureAndReviewDesc}
                </Text>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            height={30}
            alignItems="center"
            color={colors.white}
            display="flex"
            flex="grow"
          ></Box>
        )}
        {screenHasContent && (
          <Box marginTop={isMediumOrSmallBreakpoint ? 2 : undefined}>
            <TapArea onTap={onClear}>
              <Box display="flex" direction="row" gap={2}>
                <ImportADocumentSvg width={25} height={25} color={colors.primary} />
                <Text color={colors.primary}>{resources.importOtherDocument}</Text>
              </Box>
            </TapArea>
          </Box>
        )}
      </Box>
      {isDragging && files.length > 0 && (
        <DragAndDropArea
          acceptedFormats={acceptedFormats}
          clickFileInput={clickFileInput}
          handleDragLeave={handleDragLeave}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          isDragging={isDragging}
        />
      )}
    </Box>
  );
};

const DragAndDropArea: FunctionComponent<{
  isDragging: boolean;
  acceptedFormats: string;
  clickFileInput: () => void;
  handleDragOver: (event?: DragEvent<HTMLDivElement> | undefined) => void;
  handleDragLeave: () => void;
  handleDrop: (event?: DragEvent<HTMLDivElement> | undefined) => void;
}> = ({ isDragging, clickFileInput, handleDragOver, handleDragLeave, handleDrop }) => {
  const { sizeCap } = useSettings();
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();

  return (
    <Box marginBottom={3} paddingX={3} marginTop={5} gap={2} display="flex" direction="column">
      <Text size="400">{resources.uploadFiles}</Text>
      <Box
        paddingY={12}
        marginTop={2}
        minHeight={260}
        display="flex"
        direction="column"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        height="auto"
        onClickCapture={clickFileInput}
        dangerouslySetInlineStyle={{
          __style: {
            cursor: "pointer",
            borderRadius: "4px",
          },
        }}
        position="relative"
        onDragEnter={handleDragOver}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        color={isDragging ? colors.primaryHover0 : undefined}
        gap={8}
      >
        <Box
          dangerouslySetInlineStyle={{
            __style: {
              position: "absolute",
              border: `8px dashed ${isDragging ? "#2650A3" : "#3366CC"}`,
              top: `${isDragging ? "-6px" : "-7px"}`,
              bottom: `${isDragging ? "-6px" : "-7px"}`,
              left: `${isDragging ? "-6px" : "-7px"}`,
              right: `${isDragging ? "-6px" : "-7px"}`,
            },
          }}
          height={"auto"}
        ></Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ImportCloudSvgIcon color={colors.primary} />
        </Box>
        <Box display="flex" direction="column" justifyContent="center">
          <Box marginTop={2} display="flex" justifyContent="center" alignItems="center" gap={1}>
            {breakpoint === "small" || breakpoint === "medium" ? (
              <Text>{resources.dashboard.clickHereTo}</Text>
            ) : (
              <Text>{resources.documentCollection.dragAndDropYourFilesHereOr}</Text>
            )}

            <Box>
              <Text color={colors.primary}> {resources.chooseFile}</Text>
            </Box>
          </Box>
          <Box marginTop={2} display="flex" justifyContent="center" alignItems="center">
            <Text color={colors.subtle} size="200">
              {resources.maxFileSize.replace("{fileSizeCap}", sizeCap)}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IndexDocumentPage;
