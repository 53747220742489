import { DataType, ListType } from "../model/fluency";

import type { ExpressionDataInput } from "@prodoctivity/shared/src/index-types";
import type momentType from "moment";

export function isCurrencyTypeField(field: any, templateController: any) {
  if (field.listType === ListType.CurrencyType) {
    return true;
  }
  if (!field.listName) {
    return false;
  }
  const list = templateController.lists[field.listName];
  return list && (list.items || []).filter((i: any) => i.key === "USD").length;
}
export function isNumericField(f: any) {
  return f.dataType === DataType.Currency || f.dataType === DataType.Numeric;
}

export function compareStrings(a: string, b: string) {
  if (a === b) {
    return 0;
  } else if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  } else {
    return 1;
  }
}
export function valueOrDefault(value: any, defaultValue: any) {
  if (typeof value === "undefined") {
    return defaultValue;
  }
  return value;
}
export function valueOrDefaultNotNull(value: any, defaultValue: any) {
  if (typeof value === "undefined" || value === null) {
    return defaultValue;
  }
  return value;
}
/**
 * Use this when you want to assert a value being defined
 * @param value
 * @param msg
 */
export function valueOrThrow(value: any, msg?: any) {
  if (typeof value === "undefined") {
    throw new Error(msg || "Invalid value");
  }
  return value;
}
export function valueOrThrowNotNull(value: any, msg: any) {
  if (value === null || typeof value === "undefined") {
    throw new Error(msg || "Invalid value");
  }
  return value;
}
/**
 * Converts a blob into it's base64 string
 * @param file input Blob
 */
export function getBase64FromBlob(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject(reader.result);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}
export function emptyPromisedFunction() {
  return Promise.resolve();
}
export function isWrappedWith(src: any, left: any, right: any) {
  return src.startsWith(left) && src.endsWith(right);
}
export function tokenizeFormat(f: any) {
  const r = [];
  let format = f;
  while (format) {
    const firstLeft = format.indexOf("[");
    if (firstLeft > 0) {
      r.push(`[${format.substr(0, firstLeft)}]`);
      format = format.substr(firstLeft);
    } else if (isWrappedWith(format, "[", "]")) {
      r.push(format);
      format = "";
    } else if (firstLeft === 0) {
      let groupLevel = 0;
      for (let cnt = 0; cnt < format.length; cnt += 1) {
        if (format[cnt] === "[") {
          groupLevel += 1;
        } else if (format[cnt] === "]") {
          groupLevel -= 1;
          if (groupLevel === 0) {
            r.push(format.substr(0, cnt + 1));
            format = format.substr(cnt + 1);
            break;
          }
        }
      }
      if (groupLevel > 0) {
        r.push(`[${format}]`);
        format = "";
      }
    } else {
      r.push(`[${format}]`);
      format = "";
    }
  }
  return r.join("");
}
export function trimWithSuffix(maxLength: number, suffix: string, target: any) {
  if (target.length > maxLength) {
    return `${target.substr(0, maxLength)}${suffix}`;
  }
  return target;
}
export const listItemProps = {
  column: "",
  lg: 24,
  md: 24,
  sm: 24,
  xl: 24,
  xs: 24,
  xxl: 24,
};

function dateValidator(moment: typeof momentType, targetValue: any) {
  const regexExpression =
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?$/g;
  if (typeof targetValue === "string") {
    return targetValue.match(regexExpression) && Date.parse(targetValue) ? true : false;
  } else {
    return (targetValue instanceof Date && !isNaN(targetValue.valueOf())) ||
      moment.isMoment(targetValue)
      ? true
      : false;
  }
}

function dateTimeParser(moment: typeof momentType, targetValue: any, jsonList: JSON[]): JSON[] {
  const date = moment(targetValue).format("YYYY-MM-DDTHH:mm:ss");
  jsonList.push(JSON.parse(JSON.stringify(date)));
  return jsonList;
}

function recursiveDependencyExplorer(
  moment: typeof momentType,
  dependencyConditions: ExpressionDataInput | ExpressionDataInput[],
  jsonList: JSON[]
) {
  if (
    !Array.isArray(dependencyConditions) &&
    dependencyConditions.expressionList &&
    dependencyConditions.expressionList.length >= 1
  ) {
    recursiveDependencyExplorer(moment, dependencyConditions.expressionList, jsonList);
  } else if (Array.isArray(dependencyConditions) && dependencyConditions.length >= 1) {
    dependencyConditions.forEach((condition: any) => {
      recursiveDependencyExplorer(moment, condition, jsonList);
    });
  } else if (
    !Array.isArray(dependencyConditions) &&
    dateValidator(moment, dependencyConditions.targetValue)
  ) {
    dateTimeParser(moment, dependencyConditions.targetValue, jsonList);
  }
}

function recursiveDependencyAssigner(
  moment: typeof momentType,
  dependencyConditions: ExpressionDataInput,
  jsonList: JSON[]
) {
  if (dependencyConditions.expressionList && dependencyConditions.expressionList.length >= 1) {
    recursiveDependencyExplorer(moment, dependencyConditions.expressionList, jsonList);
  } else if (jsonList.length >= 1 && dateValidator(moment, dependencyConditions.targetValue)) {
    dependencyConditions.targetValue = jsonList[0];
    jsonList.shift();
  }
}

export function dependencyExplorer(moment: typeof momentType, dependencyConditions: any) {
  const jsonList: any = [];
  recursiveDependencyExplorer(moment, dependencyConditions, jsonList);

  if (dependencyConditions.expressionList.length >= 1) {
    recursiveDependencyAssigner(moment, dependencyConditions.expressionList, jsonList);
  } else if (jsonList.length >= 1 && dateValidator(moment, dependencyConditions.targetValue)) {
    dependencyConditions.targetValue = jsonList[0];
    jsonList.shift();
  }

  const jsonParsedConditions: JSON = JSON.parse(JSON.stringify(dependencyConditions));
  return jsonParsedConditions;
}
