import { MutableRefObject, useLayoutEffect, useMemo, useState } from "react";

import { useWindowSize } from "usehooks-ts";

export const BREAKPOINTS = {
  small: 576,
  medium: 768,
  large: 1200,
  hd: 3500,
} as const;

export type DesignBreakpointType = keyof typeof BREAKPOINTS;

export const useDesignBreakpoint = () => {
  const windowSize = useWindowSize();

  const breakpoint: DesignBreakpointType = useMemo(() => {
    if (windowSize.width < BREAKPOINTS.small) {
      return "small";
    } else if (windowSize.width < BREAKPOINTS.medium) {
      return "medium";
    } else if (windowSize.width < BREAKPOINTS.large) {
      return "large";
    } else {
      return "hd";
    }
  }, [windowSize.width]);

  return {
    breakpoint,
  };
};

export const useComponentBreakpoint = (
  ref: MutableRefObject<HTMLDivElement | null>,
  defaultValue: DesignBreakpointType = "large"
) => {
  const [componentBreakpoint, setComponentBreakpoint] =
    useState<DesignBreakpointType>(defaultValue);

  useLayoutEffect(() => {
    const listener = () => {
      setComponentBreakpoint(getElementSize(ref.current, defaultValue));
    };
    let listenerSet = false;
    let element: HTMLDivElement | undefined;
    const handle = setInterval(() => {
      if (ref.current) {
        ref.current.addEventListener("resize", listener);
        element = ref.current;
        listenerSet = true;
        clearInterval(handle);
        setComponentBreakpoint(getElementSize(ref.current, defaultValue));
      }
    }, 50);

    return () => {
      if (listenerSet && element) {
        element.removeEventListener("resize", listener);
      }
    };
  }, [defaultValue, ref]);

  return {
    componentBreakpoint,
    width: ref.current ? ref.current.clientWidth : undefined,
    height: ref.current ? ref.current.clientHeight : undefined,
  };
};

function getElementSize(
  element: HTMLDivElement | null,
  defaultValue: DesignBreakpointType
): DesignBreakpointType {
  if (!element) {
    return defaultValue;
  }
  if (element.clientWidth < BREAKPOINTS.small) {
    return "small";
  } else if (element.clientWidth < BREAKPOINTS.medium) {
    return "medium";
  } else if (element.clientWidth < BREAKPOINTS.large) {
    return "large";
  } else {
    return "hd";
  }
}
