import {
  Box,
  ChevronSvgIcon,
  ColorConfig,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";

import { useCallback, useMemo, useState } from "react";
import { DependencyButton } from "./DependencyButton";

export type CustomAccordionProps = {
  removeLabel: string;
  label?: JSX.Element | string;
  currIndex?: number;
  children: React.ReactNode;
  i18n: (key: string) => string;
  deleteAccordionHandler?: (index: number) => void;
  openedColor?: ColorConfig;
  collapsedColor?: ColorConfig;
  canDeleteRow?: boolean;
  hideLabelOnOpen?: boolean;
  disableToggle?: boolean;
};

export const CustomAccordion = ({
  removeLabel,
  label,
  currIndex,
  i18n,
  deleteAccordionHandler,
  children,
  openedColor,
  collapsedColor,
  canDeleteRow = true,
  hideLabelOnOpen = false,
  disableToggle = false,
}: CustomAccordionProps) => {
  const { colors } = useColors();
  const [open, setOpen] = useState<boolean>(false);
  const onTap = useCallback(() => {
    if (disableToggle) return;
    if (currIndex !== undefined) {
      setOpen((prev) => !prev);
    }
  }, [currIndex, disableToggle]);
  const rowColor = useMemo(() => {
    if (open) {
      if (openedColor) {
        return openedColor;
      } else {
        return colors.primary700;
      }
    } else {
      if (collapsedColor) {
        return collapsedColor;
      } else {
        return colors.neutral300;
      }
    }
  }, [collapsedColor, colors.neutral300, colors.primary700, openedColor, open]);

  return (
    <Box flex="grow" borderRadius={6} overflow="hidden" borderColor={colors.neutral50}>
      <Box
        flex="grow"
        display="flex"
        justifyContent="between"
        alignItems="center"
        paddingY={2}
        paddingLeft={5}
        paddingRight={2}
        color={rowColor}
        borderRadius={6}
        borderColor={colors.neutral50}
      >
        <TapArea onTap={onTap}>
          <Box display="flex" gap={3} alignItems="center">
            <Box minWidth={32} minHeight={32}>
              <ChevronSvgIcon
                color={open && !collapsedColor ? colors.white : undefined}
                direction={open ? "up" : "down"}
                height={32}
                width={32}
              />
            </Box>
            {(!hideLabelOnOpen || !open) &&
              (typeof label === "string" ? (
                <Text
                  weight={"normal"}
                  color={open && !collapsedColor ? colors.white : colors.black600}
                >
                  {label}
                </Text>
              ) : (
                label
              ))}
          </Box>
        </TapArea>
        {canDeleteRow && (
          <Box height={"100%"} display="flex" alignItems="center">
            <DependencyButton
              bgColor={colors.white}
              color={colors.white}
              border={colors.subtle}
              width={100}
              onClick={() => {
                if (currIndex !== undefined && currIndex >= 0 && deleteAccordionHandler) {
                  deleteAccordionHandler(currIndex);
                }
              }}
            >
              <Box display="flex" alignItems="center">
                <Text size="200" weight="bold" color={colors.subtle}>
                  {i18n(removeLabel)}
                </Text>
              </Box>
            </DependencyButton>
          </Box>
        )}
      </Box>
      {open && (
        <Box borderRadius={6} borderColor={colors.neutral50}>
          {children}
        </Box>
      )}
    </Box>
  );
};
