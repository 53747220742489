import * as React from "react";

import { getLayoutTopicsLowercase, updateLayoutTopicsLowercase } from "./utils";

import AutoComplete from "antd/lib/auto-complete";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import { Spinner } from "../Loader";
import { without } from "../_lib/utils";

//import { func, instanceOf } from 'prop-types'

//import { func, instanceOf } from 'prop-types'

type Props = {
  addTopic: (key: string) => void;
  i18n: (key: string) => string;
  form?: any;
  /** Should be a `Set<string>` of topic names */
  layoutTopics: Array<string>;
  fetchTopics: () => Promise<any>;
};

type State = {
  visible: boolean;
  topics: any;
  layoutTopicsLowercase: Set<string>;
};

export class AddTopic extends React.Component<Props, State> {
  /*static propTypes = {
    addTopic: func.isRequired,
    i18n: func.isRequired,
    /** Should be a `Set<string>` of topic names * /
    layoutTopics: instanceOf(Set).isRequired,
    fetchTopics: func.isRequired,
  }*/

  static getDerivedStateFromProps(
    { layoutTopics: nextlayoutTopics }: any,
    { layoutTopics: prevLayoutTopics }: any
  ) {
    return updateLayoutTopicsLowercase(prevLayoutTopics, nextlayoutTopics);
  }

  container?: HTMLElement = undefined;

  state = {
    visible: false,
    topics: null,
    layoutTopicsLowercase: getLayoutTopicsLowercase(this.props.layoutTopics),
  };

  formRef: any; // = React.useRef<FormInstance>(null);

  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    const topics = await this.props.fetchTopics();
    this.setState({ topics });
  }

  shouldDisableSubmit() {
    if (this.formRef.current) {
      const { getFieldError, getFieldValue } = this.formRef.current;

      const shouldDisableSubmit = !getFieldValue("topicName") || getFieldError("topicName");

      return !!shouldDisableSubmit;
    }
    return true;
  }

  showModal = () => this.setState({ visible: true });

  handleOk = (e: any) => {
    e.preventDefault();

    if (this.shouldDisableSubmit()) {
      return;
    }

    const value = this.formRef.current?.getFieldValue("topicName");
    this.formRef.current?.resetFields();
    this.props.addTopic(value);

    this.setState({ visible: false });
  };

  handleCancel = () => this.setState({ visible: false });

  saveContainer = (container: any) => {
    this.container = container;
  };

  getContainer = () => {
    return this.container;
  };

  render() {
    const { layoutTopics, i18n } = this.props;
    //const { getFieldDecorator } = this.props.form;
    const { visible, topics, layoutTopicsLowercase } = this.state;

    return (
      <div>
        <Button onClick={this.showModal}>{i18n("AddTopic")}</Button>

        {/* mount node for Modal */}
        <div ref={this.saveContainer} />
        <Modal
          title={i18n("CreateTopic")}
          open={visible}
          onCancel={this.handleCancel}
          getContainer={this.container} //={this.getContainer}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              {i18n("cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleOk}
              /*disabled*/ loading={this.shouldDisableSubmit()}
            >
              {i18n("CreateTopic")}
            </Button>,
          ]}
        >
          {!topics ? (
            <Spinner style={undefined} />
          ) : (
            <Form /*ref={this.formRef}*/ layout="horizontal" onFinish={this.handleOk}>
              <Form.Item
                label={i18n("TopicName")}
                name="topicName"
                noStyle
                rules={[
                  {
                    whitespace: true,
                    pattern: /^[a-zA-Z].*/,
                    message: i18n("InvalidTopicName"),
                  },
                  {
                    required: true,
                    message: i18n("TopicNameMissing"),
                  },
                  {
                    message: i18n("TopicDuplicated"),
                    validator: function (rule: any, value: any, callback: any) {
                      if (layoutTopicsLowercase.has(value.trim().toLowerCase())) {
                        callback(true);
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <AutoComplete
                  dataSource={without(topics, layoutTopics)}
                  placeholder={i18n("TopicName")}
                />
              </Form.Item>
            </Form>
          )}
        </Modal>
      </div>
    );
  }
}
