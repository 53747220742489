"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortFields = exports.mapRecordToTemplateFieldRecordUI = exports.mapFieldToTemplateFieldRecordUI = exports.resourcesAdapters = exports.dictionaryDataTypeToInputType = exports.dataTypeIcon = exports.isNumericField = void 0;
var shared_1 = require("@prodoctivity/shared");
function isNumericField(f) {
    return f.dataType === "Currency" || f.dataType === "Numeric";
}
exports.isNumericField = isNumericField;
function dataTypeIcon(dataType) {
    switch (dataType) {
        case "Currency":
        case "DecimalNumber":
            return "number";
        case "Alphanumeric":
            return "text";
        case "Date":
        case "DateTime":
            return "calendar";
        case "Logical":
        case "Boolean":
            return "yes-no";
        case "Numeric":
        case "IntegerNumber":
            return "number";
        case "Time":
            return "alarm";
        case "Image":
        case "ElectronicSignature":
            return "image";
        case "Field":
            return "eye-slash";
        case "Replacement":
            return "new-version";
        case "Condition":
            return "filter";
        case "record":
            return "table";
        case "List":
        case "None":
        case "Basic":
        case "CalculatedField":
        case "Chart":
        case "RepetitiveRecord":
        case "ContentFragment":
        case "Barcode":
            return "list";
        default:
            (0, shared_1.shouldNever)(dataType);
            throw new Error("Unsupported icon type");
    }
}
exports.dataTypeIcon = dataTypeIcon;
exports.dictionaryDataTypeToInputType = {
    Alphanumeric: ["TextBox", "TextArea"],
    Boolean: ["Checkbox", "Switch", "Radio", "Dropdown"],
    Date: ["DateTimePicker"],
    DateTime: ["DateTimePicker"],
    DecimalNumber: ["TextBox"],
    ElectronicSignature: ["Signature"],
    Image: ["ImageUpload"],
    IntegerNumber: ["TextBox"],
    List: ["Dropdown", "Checkbox", "Radio"],
    Time: ["TimePicker"],
};
exports.resourcesAdapters = {
    "greater-than": "greaterThan",
    "lesser-than": "lessThan",
    valueOfLabel: "filterBuilder.valueOfLabel",
    equals: "equals",
    "not-equals": "differentThan",
    "greater-or-equals": "greaterThanOrEquals",
    "lesser-or-equals": "lessThanOrEquals",
    contains: "contains",
    "starts-with": "startsWith",
    "ends-with": "endsWith",
    andLabel: "dataTypeValues.and",
    and: "dataTypeValues.and",
    or: "or",
    orLabel: "or",
    countOf: "filterBuilder.countOf",
    sumOf: "filterBuilder.sumOf",
    averageOf: "filterBuilder.averageOf",
    minimumOf: "filterBuilder.minimumOf",
    maximumOf: "filterBuilder.maximumOf",
    some: "filterBuilder.some",
    every: "filterBuilder.every",
    custom: "custom",
    yes: "yes",
    not: "not",
};
function mapFieldToTemplateFieldRecordUI(field, isChild) {
    var defaultCalculatedValue = {
        dataType: field.properties.dataType,
        fixedValueType: "Alphanumeric",
        type: "FixedValue",
    };
    var maxLengthGetter = function () {
        switch (field.properties.dataType) {
            case "Alphanumeric":
                switch (field.properties.inputType) {
                    case "TextArea":
                    case "TextBox":
                    case "Default":
                        return field.properties.maxLength;
                    default:
                        return undefined;
                }
            case "Numeric":
            case "Currency":
                return field.properties.maxLength;
            default:
                return undefined;
        }
    };
    var inputMaskGetter = function () {
        switch (field.properties.dataType) {
            case "Alphanumeric":
                switch (field.properties.inputType) {
                    case "TextBox":
                    case "Default":
                        return field.properties.inputMask;
                    default:
                        return "###-#######-#";
                }
            default:
                return "###-#######-#";
        }
    };
    var inputMaskStoreFixedCharsGetter = function () {
        switch (field.properties.dataType) {
            case "Alphanumeric":
                switch (field.properties.inputType) {
                    case "TextBox":
                    case "Default":
                        return field.properties.inputMaskStoreFixedChars;
                    default:
                        return false;
                }
            default:
                return false;
        }
    };
    var calculatedFieldGetter = function () {
        switch (field.properties.dataType) {
            case "Numeric":
            case "Currency":
                return field.properties.isCalculated
                    ? field.properties.calculatedField
                    : defaultCalculatedValue;
            default:
                return defaultCalculatedValue;
        }
    };
    var minValueGetter = function () {
        switch (field.properties.dataType) {
            case "Date":
            case "DateTime":
            case "Time":
            case "Numeric":
            case "Currency":
                return field.properties.minValue;
            default:
                return undefined;
        }
    };
    var maxValueGetter = function () {
        switch (field.properties.dataType) {
            case "Date":
            case "DateTime":
            case "Time":
            case "Numeric":
            case "Currency":
                return field.properties.maxValue;
            default:
                return undefined;
        }
    };
    var dateMinMaxTypeGetter = function () {
        switch (field.properties.dataType) {
            case "Date":
            case "DateTime":
                return field.properties.dateMinMaxType;
            default:
                return undefined;
        }
    };
    var sequenceIdGetter = function () {
        switch (field.properties.dataType) {
            case "Alphanumeric":
                switch (field.properties.inputType) {
                    case "TextBox":
                    case "Default":
                        return field.properties.sequenceId ? true : false;
                    default:
                        return false;
                }
            default:
                return false;
        }
    };
    var maxLinesGetter = function () {
        switch (field.properties.dataType) {
            case "Alphanumeric":
                switch (field.properties.inputType) {
                    case "TextArea":
                        return field.properties.lineCount;
                    default:
                        return undefined;
                }
            case "Numeric":
            case "Currency":
                return field.properties.lineCount;
            default:
                return undefined;
        }
    };
    var valueListGetter = function () {
        switch (field.properties.dataType) {
            case "Alphanumeric":
                switch (field.properties.inputType) {
                    case "Checkbox":
                    case "CheckBoxList":
                    case "Dropdown":
                    case "DropDownList":
                    case "Radio":
                    case "RadioButtonList":
                        return field.properties.valueList;
                    default:
                        return undefined;
                }
            default:
                return undefined;
        }
    };
    var inputTypeGetter = function () {
        switch (field.properties.inputType) {
            case "CheckBoxList":
            case "DropDownList":
            case "RadioButtonList":
            case "Default":
            case "ImageUpload": {
                var inputType_1 = field.properties.inputType;
                return inputType_1;
            }
            default:
                var inputType = "Default";
                return inputType;
        }
    };
    return {
        type: "field",
        fields: undefined,
        isChild: isChild,
        records: undefined,
        name: field.name,
        humanName: field.properties.humanName,
        fullPath: field.fullPath,
        description: field.properties.description,
        dataType: field.properties.dataType,
        listType: "None",
        inputSize: field.properties.controlSize ? field.properties.controlSize : "None",
        inputMask: inputMaskGetter(),
        inputMaskStoreFixedChars: inputMaskStoreFixedCharsGetter(),
        instructions: field.properties.instructions,
        label: field.properties.label,
        inputType: inputTypeGetter(),
        isSequence: sequenceIdGetter(),
        maxLength: maxLengthGetter(),
        maxLines: maxLinesGetter(),
        minimumOccurrences: field.properties.minOccurs,
        maximumOccurrences: field.properties.maxOccurs,
        minimumValue: minValueGetter(),
        maximumValue: maxValueGetter(),
        // listName: mapDataTypeToLabel(resources, field.properties.dataType, undefined),
        listName: (0, shared_1.isValueListContextField)(field.properties) ? field.name : undefined,
        calculatedField: calculatedFieldGetter(),
        sampleValue: typeof field.properties.sampleValue === "string" ? field.properties.sampleValue : "",
        isUnique: field.properties.isUnique,
        defaultValue: typeof field.properties.defaultValue === "string" ? field.properties.defaultValue : "",
        isReference: false,
        isReadOnly: false,
        isAutoCompleteValues: false,
        dateMinMaxType: dateMinMaxTypeGetter(),
        valueList: valueListGetter(),
    };
}
exports.mapFieldToTemplateFieldRecordUI = mapFieldToTemplateFieldRecordUI;
function mapRecordToTemplateFieldRecordUI(record, isChild) {
    var records = [];
    if (record.records) {
        records = record.records.map(function (r) { return mapRecordToTemplateFieldRecordUI(r, true); });
    }
    return {
        type: "record",
        fields: record.fields.map(function (f) { return mapFieldToTemplateFieldRecordUI(f, true); }),
        isChild: isChild,
        records: records,
        name: record.name,
        humanName: record.properties.humanName,
        fullPath: record.fullPath,
        dataType: "Alphanumeric",
        defaultValue: "",
        description: "",
        inputSize: "None",
        instructions: "",
        isReadOnly: false,
        isReference: false,
        label: record.name,
        sampleValue: "",
    };
}
exports.mapRecordToTemplateFieldRecordUI = mapRecordToTemplateFieldRecordUI;
function sortFields(flds) {
    flds
        .filter(function (fld) { return fld.type === "record"; })
        .forEach(function (rec) {
        if (rec.fields) {
            sortFields(rec.fields);
        }
        if (rec.records) {
            sortFields(rec.records);
        }
    });
    flds.sort(function (a, b) {
        return (a.humanName || "").localeCompare(b.humanName || "");
    });
    return flds;
}
exports.sortFields = sortFields;
