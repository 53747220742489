import { FunctionComponent } from "react";
import { calculateColor, type ColorConfig } from "../utils";
import { useColors } from "../components";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const DownloadSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M26 16.9998C25.991 16.9998 25.983 17.0018 25.975 17.0028C25.8534 15.3225 25.0838 13.7552 23.8286 12.6315C22.5734 11.5079 20.9307 10.9159 19.2473 10.9804C17.5639 11.0448 15.9713 11.7608 14.8057 12.9772C13.6401 14.1935 12.9927 15.8151 13 17.4998C13.002 18.0117 13.0644 18.5215 13.186 19.0188C13.123 19.0158 13.064 18.9998 13 18.9998C11.9391 18.9998 10.9217 19.4212 10.1716 20.1714C9.42143 20.9215 9 21.9389 9 22.9998C9 24.2018 9.541 25.2668 10.38 25.9998H28.973C30.196 25.0888 31 23.6428 31 21.9998C31 21.3432 30.8707 20.693 30.6194 20.0864C30.3681 19.4797 29.9998 18.9285 29.5355 18.4642C29.0712 18 28.52 17.6317 27.9134 17.3804C27.3068 17.1291 26.6566 16.9998 26 16.9998ZM20 23.9998L16 18.9998H19V15.9998H21V18.9998H24L20 23.9998Z"
        fill={calculateColor(color || colors.neutral900)}
      />
    </svg>
  );
};
