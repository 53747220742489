import {
  AccordionExpandable,
  AccordionExpandableProps,
  AccordionModule,
  Box,
  Button,
  Checkbox,
  DataGrid,
  DataGridProps,
  DocumentViewer,
  DocumentViewerEvents,
  Grid,
  Icon,
  IconButton,
  Image,
  MarkdownViewer,
  ProDoctivityColorBundle,
  Skeleton,
  Spinner,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
  usePubSub,
} from "@prodoctivity/design-system";
import { MimeTypes, formatFileSize, isContextRecord, range } from "@prodoctivity/shared";
import type {
  ContextField,
  ContextFieldProperties,
  ContextRecord,
  TemplateSubscriptionType,
  TemplateVersionId,
} from "@prodoctivity/shared/src/index-types";
import type { TemplateInfo, TemplateVersion } from "@prodoctivity/types";
import { UseMutateFunction } from "@tanstack/react-query";
import { Fragment, FunctionComponent, ReactNode, useMemo, useState } from "react";
import { PublicAvatarImage } from "../../components/Avatar/Avatar";
import { DataElementLink } from "../../components/DataElement/DataElementLink";
import { DatalinkTable, DatalinkTableSkeleton } from "../../components/Datalinks/DatalinkTable";
import {
  DistributionTable,
  DistributionTableSkeleton,
} from "../../components/Distributions/DistributionTable";
import { Page } from "../../components/Layout/Page";
import { OrganizationLink } from "../../components/OrganizationLink";
import { OrganizationNavigate } from "../../components/OrganizationNavigate";
import { PublicProfile } from "../../components/Profile/Public/PublicProfile";
import { baseUrl } from "../../config";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { Services } from "../../services";
import { CaretExpandedSvg } from "../../svg/CaretExpanded";
import { CaretRightSvg } from "../../svg/CaretRight";
import { useTemplateDetail } from "./hooks";
import { FancyDateTime } from "@prodoctivity/design-system";

type DetailProps = {
  label: string;
  children?: ReactNode;
};

type InputDataColumn = DataGridProps<ContextField | ContextRecord>["columns"];

function makeColumns(
  colors: ProDoctivityColorBundle,
  resources: ReturnType<typeof useAppTranslation>["resources"]
): InputDataColumn {
  const columns: InputDataColumn = [
    {
      id: "name",
      accessorFn: (row) => {
        return {
          name: row.name,
          humanName: row.humanName,
          isRecord: "records" in row,
          dataElementId: "records" in row ? undefined : row.properties.dictionaryDataElementId,
        };
      },
      header: resources.name,
      cell: ({ row, getValue }) => {
        const { name, humanName, isRecord, dataElementId } = getValue<{
          name: string;
          humanName: string;
          isRecord: boolean;
          dataElementId: string | undefined;
        }>();

        return (
          <Box display="flex" direction="row" position="relative">
            <Box position="absolute" display="block" marginStart={-6}>
              {row.getCanExpand() && (
                <TapArea onTap={row.getToggleExpandedHandler()}>
                  {row.getIsExpanded() ? <CaretExpandedSvg /> : <CaretRightSvg />}
                </TapArea>
              )}
            </Box>
            {row.depth ? range(row.depth).map((_, idx) => <Box key={idx} marginStart={6} />) : null}

            <Box display="flex" gap={2} direction="row">
              {!dataElementId &&
                (isRecord ? (
                  <Icon
                    icon="drawer"
                    accessibilityLabel={resources.records}
                    color={colors.primary400}
                    onClick={row.getToggleExpandedHandler()}
                  />
                ) : (
                  <Icon
                    icon="circle-info"
                    accessibilityLabel={resources.noValue}
                    color={colors.neutral500}
                  />
                ))}
              {humanName && !isRecord && dataElementId && (
                <DataElementLink dataElementName={humanName} dataElementId={dataElementId} />
              )}
              <Text>{name}</Text>
            </Box>
          </Box>
        );
      },
    },
    {
      id: "label",
      accessorFn: (row) => row.properties.label,
      header: resources.label,
    },
    {
      id: "description",
      accessorFn: (row) => {
        if (Object.hasOwn(row.properties, "description")) {
          return (row.properties as ContextFieldProperties).description;
        }
        return "";
      },
      header: resources.description,
    },
  ];
  return columns;
}

function handleExpandedRow(row: ContextField | ContextRecord) {
  if ("records" in row) {
    return [...row.fields, ...row.records];
  }
}

function Detail({ label, children }: DetailProps) {
  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box marginBottom={3} display="flex" direction={breakpoint === "small" ? "column" : "row"}>
      <Text weight="bold">{label}: </Text>
      <Box width={5} />
      <Text>{children}</Text>
    </Box>
  );
}

const TableSkeleton = () => {
  const { resources } = useAppTranslation();

  return (
    <Grid gap={4} gridTemplateColumns={[1, 1, 1]}>
      <Box paddingX={5}>
        <Text weight="bold">{resources.name}</Text>
      </Box>
      <Box paddingX={5}>
        <Text weight="bold">{resources.dataType}</Text>
      </Box>
      <Box paddingX={5}>
        <Text weight="bold">{resources.description}</Text>
      </Box>
      {range(33).map((_, n) => (
        <Box key={n} paddingX={5}>
          <Skeleton width="100%" height={35} />
        </Box>
      ))}
    </Grid>
  );
};

const TableVersionSkeleton = () => {
  return (
    <Grid gap={5} gridTemplateColumns={[1, 24, 1] as any}>
      {range(8).map((n) => (
        <Fragment key={n}>
          <Box display="flex">
            <Skeleton width={50} height={50} rounded={true} />
          </Box>
          <Box>
            <Skeleton width={100} height={20} />
            <Box height={6} />
            <Skeleton width="50%" height={20} />
            <Box marginTop={5} width="100%" height="1px" />
          </Box>
          <Box display="flex" alignItems="center">
            <Skeleton width="100%" height={20} />
          </Box>
        </Fragment>
      ))}
    </Grid>
  );
};

function TemplateDetailPage() {
  const {
    breadCrumbEntries,
    handleButtonClickGenerate,
    handleButtonClickEdit,
    isSubscriptionLoading,
    mutateSubscribe,
    templateUpdateSubscription,
    templateGenerationSubscription,
    loading,
    verDate,
    publishedMessage,
    breakpoint,
    editable,
    templateVersionId,
    template,
    versions,
    templateVersion,
    copyToClipboard,
    apiMarkdownText,
    resources,
    moment,
    versionList,
    versionListLoading,
    sample,
    sampleLoading,
  } = useTemplateDetail();

  const { colors } = useColors();
  if (!templateVersionId) {
    return <OrganizationNavigate to={organizationLinkTemplates.home()} />;
  }

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box paddingX={6}>
        <Box margin="auto" maxWidth={"100%"} paddingY={2}>
          <Box display="flex" direction="row" gap={4} wrap={breakpoint === "small"}>
            <PropertiesBox
              template={template}
              handleButtonClickGenerate={handleButtonClickGenerate}
              versions={versions}
              loading={loading}
              publishedMessage={publishedMessage}
              templateVersion={templateVersion}
              verDate={verDate}
              templateGenerationSubscription={templateGenerationSubscription}
              templateUpdateSubscription={templateUpdateSubscription}
              isSubscriptionLoading={isSubscriptionLoading}
              mutateSubscribe={mutateSubscribe}
              templateVersionId={templateVersionId}
              editable={editable}
              handleButtonClickEdit={handleButtonClickEdit}
            />
          </Box>
          <Box margin={4} />
          <Box borderStyle="shadow">
            <AccordionExpandable
              accessibilityCollapseLabel={resources.collapse}
              accessibilityExpandLabel={resources.expand}
              id="template_version_info"
              expandedIndex={0}
              items={[
                {
                  title: resources.preview,
                  type: "info",
                  icon: "eye",

                  children: (
                    <Box width={breakpoint === "small" ? 300 : undefined} height={1000}>
                      <ViewerModule templateVersionId={templateVersionId} canEdit={editable} />
                    </Box>
                  ),
                },
                {
                  title: resources.properties,
                  type: "info",
                  icon: "information",

                  children: (
                    <Box display="flex" direction="column" wrap={true}>
                      <Detail label={resources.published}>{publishedMessage}</Detail>
                      <Detail label="Version">{templateVersion}</Detail>
                      <Detail label={resources.language}>
                        {template?.informationDefinition?.language?.toString()}
                      </Detail>

                      {!!template?.storage && (
                        <Detail label={resources.storageSize}>
                          {formatFileSize(template.storage)}
                        </Detail>
                      )}

                      <Detail label={resources.date}>
                        <FancyDateTime
                          moment={moment}
                          resources={resources}
                          value={verDate}
                          showTime={true}
                        />
                      </Detail>
                      <Detail label={resources.lastModifiedBy}>
                        <PublicProfile username={versions[0]?.author} />{" "}
                      </Detail>
                      <Detail label={resources.updatedAt}>
                        {versions[0]?.updateDate && (
                          <FancyDateTime
                            moment={moment}
                            resources={resources}
                            value={versions[0].updateDate}
                            showTime={true}
                          />
                        )}
                      </Detail>
                    </Box>
                  ),
                },
                {
                  title: resources.versionHistory,
                  type: "info",
                  icon: "history",
                  summary:
                    breakpoint === "small"
                      ? undefined
                      : [`${(versionList?.templateVersions || []).length} ${resources.versions}`],
                  children: (
                    <Box rounding={2} borderStyle="sm" overflow="auto">
                      {(versionListLoading || !versionList) && <TableVersionSkeleton />}
                      <Box display="flex" direction="column">
                        {versions.map((version, idx) => (
                          <OrganizationLink
                            key={version.templateVersionId}
                            to={organizationLinkTemplates.templateVersionDetailPage(
                              version.templateVersionId
                            )}
                          >
                            <Box
                              paddingX={7}
                              paddingY={5}
                              color={
                                templateVersionId === version.templateVersionId
                                  ? colors.primary100
                                  : undefined
                              }
                              hoverColor={colors.primary200}
                              wrap={true}
                            >
                              <Box
                                display="flex"
                                direction="row"
                                flex="grow"
                                minWidth={250}
                                wrap={true}
                                alignItems="center"
                                gap={2}
                              >
                                <Box display="flex" flex="shrink">
                                  <PublicAvatarImage username={version.author} size={48} />
                                </Box>
                                <Box display="flex" flex="grow" gap={2}>
                                  <Text inline={true}>
                                    {resources.revision} #{versions.length - idx} -{" "}
                                  </Text>
                                  <Text inline={true} weight="bold">
                                    {version.name}
                                  </Text>
                                </Box>
                                <Box display="flex" flex="shrink">
                                  <FancyDateTime
                                    moment={moment}
                                    resources={resources}
                                    value={version.updateDate}
                                    showTime={true}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </OrganizationLink>
                        ))}
                      </Box>
                    </Box>
                  ),
                },
                {
                  title: resources.notifications.notifications,
                  type: "info",
                  icon: "link",
                  children: (
                    <Box display="flex" wrap={true} direction="column" gap={2}>
                      <Box rounding={2} borderStyle="sm" color={colors.white} padding={2}>
                        <Box display="flex" width={"100%"} justifyContent="end" alignItems="center">
                          <Grid gridTemplateColumns={[2, 2]}>
                            <Text weight="bold" title={resources.templateDetailsPage.notifyBrowser}>
                              {resources.templateDetailsPage.notifyPhone}
                            </Text>
                            <Box width={"60px"}>
                              <Text
                                weight="bold"
                                overflow="ellipsis"
                                title={resources.templateDetailsPage.notifyBrowser}
                              >
                                {resources.templateDetailsPage.notifyBrowser}
                              </Text>
                            </Box>
                          </Grid>
                        </Box>
                        <TemplateSubscriptions
                          subscriptionType={"generation"}
                          isLoading={!templateGenerationSubscription || isSubscriptionLoading}
                          isMobile={
                            templateGenerationSubscription
                              ? templateGenerationSubscription.mobile
                              : false
                          }
                          isWeb={
                            templateGenerationSubscription
                              ? templateGenerationSubscription.web
                              : false
                          }
                          update={mutateSubscribe}
                        />

                        <TemplateSubscriptions
                          subscriptionType={"update"}
                          isLoading={!templateUpdateSubscription || isSubscriptionLoading}
                          isMobile={
                            templateUpdateSubscription ? templateUpdateSubscription.mobile : false
                          }
                          isWeb={
                            templateUpdateSubscription ? templateUpdateSubscription.web : false
                          }
                          update={mutateSubscribe}
                        />
                      </Box>
                    </Box>
                  ),
                },
                {
                  title: resources.inputData,
                  type: "info",
                  icon: "info-circle",
                  summary:
                    breakpoint === "small"
                      ? undefined
                      : [
                          `${(template?.contextDefinition.fields || []).length} ${
                            resources.fields
                          }`,
                          `${(template?.contextDefinition.records || []).length} ${
                            resources.records
                          }`,
                        ],
                  children: template ? (
                    <ContextDefinitionView template={template} loading={loading} />
                  ) : (
                    <Spinner show={true} />
                  ),
                },

                {
                  title: resources.dataLinks,
                  type: "info",
                  icon: "link",
                  summary:
                    breakpoint === "small"
                      ? undefined
                      : [`${(template?.dataLinkMappings || []).length} ${resources.dataLinks}`],
                  children: (
                    <Box color={colors.white}>
                      {!template && <DatalinkTableSkeleton />}
                      {template && template.dataLinkMappings && (
                        <DatalinkTable
                          key="dlink-table"
                          templateVersionId={template.templateVersionId}
                          mappedDatalinks={template.dataLinkMappings}
                        />
                      )}
                    </Box>
                  ),
                },
                {
                  title: resources.distributions,
                  type: "info",
                  icon: "share",
                  summary:
                    breakpoint === "small"
                      ? undefined
                      : [
                          `${(template?.distributionMappings || []).length} ${
                            resources.distributions
                          }`,
                        ],
                  children: (
                    <Box color={colors.white}>
                      {!template && <DistributionTableSkeleton />}
                      {template && template.distributionMappings && (
                        <DistributionTable
                          key="dist-table"
                          templateVersionId={template.templateVersionId}
                          mappedDistributions={template.distributionMappings}
                        />
                      )}
                    </Box>
                  ),
                },
                {
                  title: resources.templateDetailsPage.apiSampleForDevs,
                  type: "info",
                  icon: "code",

                  children: (
                    <Box>
                      {(sampleLoading || !sample) && <Skeleton width="100%" height="100%" />}
                      {sample && (
                        <Box
                          color={colors.white}
                          padding={4}
                          alignContent="center"
                          alignItems="center"
                          borderRadius={4}
                          overflow="scroll"
                          maxWidth={breakpoint !== "small" ? undefined : 290}
                        >
                          <IconButton
                            accessibilityLabel={resources.copy}
                            icon="copy"
                            onClick={() => copyToClipboard(apiMarkdownText)}
                          />
                          <MarkdownViewer markdownText={apiMarkdownText} />
                          {/* <pre>{JSON.stringify(sample.requestSample, null, 2)}</pre> */}
                        </Box>
                      )}
                      <Box height={40} />
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
          <Box margin={4} />
          {editable && (
            <Box>
              <Box display="flex" gap={2} justifyContent="end">
                <Box
                  display="flex"
                  flex="shrink"
                  width={breakpoint === "small" || breakpoint === "medium" ? "100%" : undefined}
                >
                  <Button
                    onClick={handleButtonClickEdit}
                    color={"gray"}
                    text={resources.editTemplate}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  );
}

const PropertiesBox: FunctionComponent<{
  template: TemplateInfo | undefined;
  handleButtonClickGenerate: () => void;
  handleButtonClickEdit: () => void;
  templateVersionId: string;
  editable: boolean;
  versions: TemplateVersion[];
  loading: boolean;
  publishedMessage: string;
  templateVersion: number;
  verDate: number;
  templateGenerationSubscription:
    | {
        type: TemplateSubscriptionType;
        mobile: boolean;
        web: boolean;
      }
    | undefined;
  templateUpdateSubscription:
    | {
        type: TemplateSubscriptionType;
        mobile: boolean;
        web: boolean;
      }
    | undefined;
  isSubscriptionLoading: boolean;
  mutateSubscribe: UseMutateFunction<
    void,
    unknown,
    {
      subscriptionType: TemplateSubscriptionType;
      item: "mobile" | "web";
      value: boolean;
    },
    unknown
  >;
}> = ({ template, handleButtonClickGenerate, versions, loading }) => {
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const { colors } = useColors();

  return (
    <Box display="flex" flex="grow" direction="column" gap={4}>
      <AccordionModule
        id="template_details_properties"
        size="sm"
        icon="info-circle"
        title={template?.informationDefinition?.name}
      >
        {versions[0] && (
          <Box display="flex" flex="grow" alignItems="center" gap={4}>
            <Box
              display="flex"
              direction="column"
              flex="grow"
              paddingX={2}
              paddingY={2}
              rounding={2}
              borderStyle="sm"
              color={colors.white}
            >
              <Box display="flex" flex="grow">
                <Text weight="bold">{resources.description}</Text>
              </Box>
              <Box minHeight={40}>
                <Skeleton show={loading} height={76}>
                  {template?.informationDefinition.description ? (
                    <Text>{template.informationDefinition.description}</Text>
                  ) : (
                    <Text>{resources.templateDetailsPage.thisTemplateDoesNotHaveADescription}</Text>
                  )}
                </Skeleton>
              </Box>
            </Box>
            <Box display="flex" flex="shrink">
              <ButtonGenerate
                isButtonOnRight={breakpoint !== "small" && breakpoint !== "medium" ? true : false}
                handleButtonClickGenerate={handleButtonClickGenerate}
              />
            </Box>
          </Box>
        )}
      </AccordionModule>
    </Box>
  );
};

const ButtonGenerate: FunctionComponent<{
  handleButtonClickGenerate: () => void;
  isButtonOnRight: boolean;
}> = ({ handleButtonClickGenerate, isButtonOnRight }) => {
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  return (
    <Box
      width={"100%"}
      marginTop={isButtonOnRight ? -9 : undefined}
      display="flex"
      justifyContent={isButtonOnRight ? "end" : "center"}
      direction={breakpoint !== "small" && breakpoint !== "medium" ? undefined : "column"}
    >
      <Box
        marginTop={breakpoint === "small" && isButtonOnRight ? 10 : undefined}
        paddingY={1}
        width={breakpoint !== "small" && breakpoint !== "medium" ? undefined : "100%"}
      >
        <Button onClick={handleButtonClickGenerate} color={"blue"} text={resources.generate} />
      </Box>
    </Box>
  );
};

const ViewerModule: FunctionComponent<{
  templateVersionId: TemplateVersionId;
  canEdit: boolean;
}> = ({ templateVersionId, canEdit }) => {
  const { resources, moment } = useAppTranslation();
  const { user } = useServices();
  const { breakpoint } = useDesignBreakpoint();
  const imageDataUrl = useMemo(() => {
    if (!user || !user.token) {
      return undefined;
    }
    return `${baseUrl}/app/template-versions/${templateVersionId}/image?token=${encodeURIComponent(
      user.token
    )}`;
  }, [templateVersionId, user]);

  const templateVersionUrl = useMemo(() => {
    if (!user || !user.token) {
      return undefined;
    }

    return `${baseUrl}/app/template-versions/${templateVersionId}?returnAsPdf=true&token=${encodeURIComponent(
      user.token
    )}`;
  }, [user, templateVersionId]);

  const versionUrlSrc = useMemo(() => {
    if (!templateVersionUrl) {
      return [];
    }
    return [templateVersionUrl];
  }, [templateVersionUrl]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const { eventManager } = usePubSub<DocumentViewerEvents>();

  if (!templateVersionUrl || !imageDataUrl) {
    return <Spinner show={true} accessibilityLabel={resources.loading} />;
  }

  return (
    <Box width={"100%"} height={"100%"}>
      {canEdit ? (
        <Box flex="grow" height={"100%"} paddingY={3}>
          <DocumentViewer
            key={templateVersionUrl}
            height={"100%"}
            src={versionUrlSrc}
            mimeType={MimeTypes.PdfDocument}
            strategy="normal"
            resources={resources}
            templateDetailsExist={breakpoint !== "hd" ? true : false}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            moment={moment}
            eventManager={eventManager}
          />
        </Box>
      ) : (
        <Image
          alt="templateImage"
          naturalHeight={250}
          naturalWidth={180}
          fetchPriority="low"
          loading="eager"
          src={imageDataUrl}
        />
      )}
    </Box>
  );
};

const TemplateSubscriptions: FunctionComponent<{
  subscriptionType: TemplateSubscriptionType;
  isLoading: boolean;
  isMobile: boolean;
  isWeb: boolean;
  update: (args: {
    subscriptionType: TemplateSubscriptionType;
    item: "mobile" | "web";
    value: boolean;
  }) => void;
}> = ({ subscriptionType, isLoading, isMobile, isWeb, update }) => {
  const { resources } = useAppTranslation();

  return (
    <Box paddingY={4} width="100%" display="flex" justifyContent="between" gap={4}>
      <Text weight="bold" overflow="ellipsis">
        {resources.templateDetailsPage[subscriptionType]}
      </Text>

      <Box display="flex" gap={4}>
        <Grid gap={5} gridTemplateColumns={[4, 2]}>
          <Box paddingX={1}>
            <Checkbox
              checked={isMobile}
              disabled={isLoading}
              onChange={(event) =>
                update({ subscriptionType, item: "mobile", value: event.checked })
              }
              id={`${subscriptionType}_mobile`}
            />
          </Box>
          <Box paddingX={1}>
            <Checkbox
              checked={isWeb}
              disabled={isLoading}
              onChange={(event) => update({ subscriptionType, item: "web", value: event.checked })}
              id={`${subscriptionType}_browser`}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

const ContextDefinitionView: FunctionComponent<{
  template: Awaited<ReturnType<Services["fetchTemplateInfo"]>>["info"];
  loading: boolean;
}> = ({ template, loading }) => {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const organizationNavigate = useOrganizationNavigate();
  const { resources } = useAppTranslation();

  const meta = useMemo(() => {
    return { navigationFunction: organizationNavigate };
  }, [organizationNavigate]);

  const data = useMemo(() => {
    return [...template.contextDefinition.fields, ...template.contextDefinition.records];
  }, [template.contextDefinition.fields, template.contextDefinition.records]);

  const columns = useMemo(() => {
    return makeColumns(colors, resources);
  }, [colors, resources]);

  if (breakpoint !== "small") {
    return (
      <Box paddingY={5} rounding={2} borderStyle="sm" overflow="auto" color={colors.white}>
        {loading && <TableSkeleton />}
        {template && (
          <DataGrid
            meta={meta}
            tableAttributes={{ width: "100%" }}
            columns={columns}
            data={data}
            getSubRows={handleExpandedRow}
          />
        )}
      </Box>
    );
  }

  return (
    <Box rounding={2} color={colors.white} maxWidth={300}>
      {loading && <Spinner show={true} accessibilityLabel={resources.loading} />}
      {
        <AccordionExpandable
          accessibilityCollapseLabel={resources.collapse}
          accessibilityExpandLabel={resources.expand}
          id="template_data_schema"
          items={data.map((item) => {
            const result: AccordionExpandableProps["items"][number] = {
              title: item.humanName,

              children: (
                <Box>
                  <MarkdownViewer
                    markdownText={
                      isContextRecord(item) ? item.properties.label : item.properties.description
                    }
                  />
                </Box>
              ),
            };

            return result;
          })}
        />
      }
    </Box>
  );
};

export default TemplateDetailPage;
