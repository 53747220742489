import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const WebAuthorSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 21,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color || colors.neutral900) : undefined}
      width={width}
      height={height}
      viewBox="0 0 24 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_514_2188)">
        <path
          d="M21.4957 0H2.5043C1.12121 0 0 1.12121 0 2.5043V18.0398C0 19.4229 1.12121 20.5441 2.5043 20.5441H21.4957C22.8788 20.5441 24 19.4229 24 18.0398V2.5043C24 1.12121 22.8788 0 21.4957 0Z"
          fill={color ? calculateColor(color || colors.neutral900) : undefined}
        />
        <path
          d="M3.7331 5.1244C4.47183 5.1244 5.07069 4.52554 5.07069 3.78681C5.07069 3.04808 4.47183 2.44922 3.7331 2.44922C2.99437 2.44922 2.39551 3.04808 2.39551 3.78681C2.39551 4.52554 2.99437 5.1244 3.7331 5.1244Z"
          fill="white"
        />
        <path
          d="M8.13153 5.1244C8.87026 5.1244 9.46912 4.52554 9.46912 3.78681C9.46912 3.04808 8.87026 2.44922 8.13153 2.44922C7.3928 2.44922 6.79395 3.04808 6.79395 3.78681C6.79395 4.52554 7.3928 5.1244 8.13153 5.1244Z"
          fill="white"
        />
        <path
          d="M12.5329 5.1244C13.2716 5.1244 13.8705 4.52554 13.8705 3.78681C13.8705 3.04808 13.2716 2.44922 12.5329 2.44922C11.7942 2.44922 11.1953 3.04808 11.1953 3.78681C11.1953 4.52554 11.7942 5.1244 12.5329 5.1244Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_514_2188">
          <rect width="24" height="20.5441" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
