import { useCallback, useMemo, useState } from "react";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useServices } from "../../../hooks/useServices";
import type { ListableTemplateWithCombined } from "@prodoctivity/types";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useLocation } from "react-router-dom";
import { organizationLinkTemplates } from "../../../link-templates";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";

export const useGenerateRelatedDocument = () => {
  const organizationNavigate = useOrganizationNavigate();

  const { search: searchParams } = useLocation();

  const params = useMemo(() => new URLSearchParams(searchParams), [searchParams]);
  const filter = params.get("filter") || undefined;
  const [filterText, setFilterText] = useState<string>(filter || "");

  const goToTemplateVersion = useCallback(
    (
      type: ListableTemplateWithCombined["type"],
      id: string,
      context: ParametersObject | undefined
    ) => {
      const navigateTo = organizationLinkTemplates.templateVersionGeneratePage(id, undefined);
      organizationNavigate(navigateTo, {
        state: {
          generationContext: context,
        },
      });
    },
    [organizationNavigate]
  );

  const { fetchTemplateList } = useServices();

  const listTemplatesPage = useCallback(() => {
    return fetchTemplateList("all", false, 0, "15", filterText);
  }, [fetchTemplateList, filterText]);

  const {
    isLoading,
    data: templateList,
    refetch,
    remove,
  } = useOrganizationQuery(`template-list/page_${0}_${"15"},${filterText}`, listTemplatesPage, {
    staleTime: 0,
    refetchOnWindowFocus: false,
  });
  return {
    templateList,
    isLoading,
    refetch,
    remove,
    filterText,
    setFilterText,
    goToTemplateVersion,
  };
};
