import { calculateColor, type ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const MyTasksWidgetIconSvg: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 40,
  height = 40,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M12 14.25C12 13.5859 12.5469 13 13.25 13H28.25C28.9141 13 29.5 13.5859 29.5 14.25C29.5 14.9531 28.9141 15.5 28.25 15.5H13.25C12.5469 15.5 12 14.9531 12 14.25ZM12 20.5C12 19.8359 12.5469 19.25 13.25 19.25H28.25C28.9141 19.25 29.5 19.8359 29.5 20.5C29.5 21.2031 28.9141 21.75 28.25 21.75H13.25C12.5469 21.75 12 21.2031 12 20.5ZM28.25 28H13.25C12.5469 28 12 27.4531 12 26.75C12 26.0859 12.5469 25.5 13.25 25.5H28.25C28.9141 25.5 29.5 26.0859 29.5 26.75C29.5 27.4531 28.9141 28 28.25 28Z"
        fill={color ? calculateColor(color) : "#7E2BFA"}
        fillOpacity="0.9"
      />

      <g clipPath="url(#clip0_8891_117511)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1495 12.2268C15.4473 12.5291 15.4473 13.0193 15.1495 13.3216L13.1156 15.3861C12.8177 15.6885 12.3348 15.6885 12.037 15.3861L11.2234 14.5603C10.9255 14.258 10.9255 13.7678 11.2234 13.4655C11.5213 13.1631 12.0042 13.1631 12.302 13.4655L12.5763 13.7438L14.0709 12.2268C14.3687 11.9244 14.8516 11.9244 15.1495 12.2268ZM16.2881 14.2194C16.2881 13.7918 16.6296 13.4452 17.0508 13.4452H28.2373C28.6585 13.4452 29 13.7918 29 14.2194C29 14.6469 28.6585 14.9935 28.2373 14.9935H17.0508C16.6296 14.9935 16.2881 14.6469 16.2881 14.2194ZM15.1495 18.4203C15.4473 18.7226 15.4473 19.2128 15.1495 19.5152L13.1156 21.5797C12.8177 21.882 12.3348 21.882 12.037 21.5797L11.2234 20.7539C10.9255 20.4515 10.9255 19.9614 11.2234 19.659C11.5213 19.3567 12.0042 19.3567 12.302 19.659L12.5763 19.9374L14.0709 18.4203C14.3687 18.118 14.8516 18.118 15.1495 18.4203ZM16.2881 20.4129C16.2881 19.9853 16.6296 19.6387 17.0508 19.6387H28.2373C28.6585 19.6387 29 19.9853 29 20.4129C29 20.8405 28.6585 21.1871 28.2373 21.1871H17.0508C16.6296 21.1871 16.2881 20.8405 16.2881 20.4129ZM15.1495 24.6139C15.4473 24.9162 15.4473 25.4064 15.1495 25.7087L13.1156 27.7732C12.9726 27.9184 12.7786 28 12.5763 28C12.374 28 12.18 27.9184 12.037 27.7732L11.2234 26.9474C10.9255 26.6451 10.9255 26.1549 11.2234 25.8526C11.5213 25.5502 12.0042 25.5502 12.302 25.8526L12.5763 26.1309L14.0709 24.6139C14.3687 24.3115 14.8516 24.3115 15.1495 24.6139ZM16.2881 26.6065C16.2881 26.1789 16.6296 25.8323 17.0508 25.8323H28.2373C28.6585 25.8323 29 26.1789 29 26.6065C29 27.034 28.6585 27.3806 28.2373 27.3806H17.0508C16.6296 27.3806 16.2881 27.034 16.2881 26.6065Z"
          fill={color ? calculateColor(color) : "#7E2BFA"}
        />
      </g>
      <defs>
        <clipPath id="clip0_8891_117511">
          <rect width="40" height="40" fill="white" transform="matrix(-1 0 0 1 40 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
