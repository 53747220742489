import { Box, Text, useColors } from "@prodoctivity/design-system";
import { formatNumber } from "@prodoctivity/shared";
import { FunctionComponent } from "react";
import { DataCard } from "./DocumentCollectionReport";
import { ItemLine } from "./ItemLine";

export type CardProps = {
  reportData: DataCard;
};

export const ReportCard: FunctionComponent<CardProps> = ({ reportData }) => {
  const { colors } = useColors();

  return (
    <Box key={`report-card-${reportData.title}`}>
      <Box
        display="flex"
        direction="column"
        alignItems="center"
        color={colors.white}
        position="relative"
        overflow="hidden"
        gap={2}
        padding={4}
        borderRadius={6}
        borderColor={colors.neutral600}
      >
        <Box display="flex" direction="column" width={"100%"}>
          <Box display="flex" direction="column" gap={4}>
            <Box height={25} display="flex" alignItems="center" direction="row">
              <Box display="flex" direction="row" flex="grow">
                <Box flex="grow">
                  <Text
                    align="start"
                    color={colors.black600}
                    weight="bold"
                    size="200"
                    title={reportData.title}
                    overflow="ellipsis"
                  >
                    {reportData.title}
                  </Text>
                </Box>
                <Box alignItems="center" justifyContent="center" flex="shrink">
                  <Text align="end" color={colors.black600} weight="bold" size="300">
                    {formatNumber(reportData.resume)}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              overflow="hidden"
              width={"100%"}
              height={"8px"}
              color={colors.primaryHover0}
              borderRadius={32}
            >
              {reportData.colors?.map((r, index) => (
                <Box key={index} height={"100%"} color={r.color} width={r.value + "%"}></Box>
              ))}
            </Box>
            <Box gap={4}>
              <ItemLine {...reportData.content.documentCounter} />
              <ItemLine {...reportData.content.issueDocumentCounter} />
              <ItemLine {...reportData.content.expiredDocumentCounter} />
              <ItemLine {...reportData.content.pendingtoExpireDocumentCounter} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
