import { Box, Tabs, TabsProps, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FunctionComponent, ReactNode, useMemo } from "react";

type EditUserTab = {
  title: string;
  key: string;
  content: ReactNode;
};

type EditUserTabsProps = {
  tabs: EditUserTab[];
  selectedTab: string;
  onChange: TabsProps["onChange"];
};

export const EditOrganizationUserTabs: FunctionComponent<EditUserTabsProps> = ({
  tabs,
  selectedTab,
  onChange,
}) => {
  const { breakpoint } = useDesignBreakpoint();

  const tabItems: TabsProps["tabs"] = useMemo(() => {
    return tabs.map((tab) => {
      const tabItem: TabsProps["tabs"][number] = {
        href: ``,
        text: tab.title,
      };
      return tabItem;
    });
  }, [tabs]);

  const tabIndex = tabs.findIndex((t) => t.key === selectedTab);
  const tab = tabs[tabIndex];

  return (
    <Box
      flex="grow"
      display="flex"
      direction="column"
      borderStyle="sm"
      borderRadius={4}
      paddingX={4}
    >
      <Tabs activeTabIndex={tabIndex} tabs={tabItems} onChange={onChange} />

      {(breakpoint === "hd" || breakpoint === "large") && (
        <Box display="flex" direction="column" flex="grow">
          {tab.content}
        </Box>
      )}
    </Box>
  );
};
