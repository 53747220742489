import {
  Box,
  BoxWithRef,
  Grid,
  Icon,
  Image,
  Layer,
  Popover,
  Spinner,
  TapArea,
  Text,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import type {
  HttpMarkNotificationsAsReadResponse,
  HttpRemoveNotificationsResponse,
  UserNotification,
} from "@prodoctivity/types";
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import ellipsis from "../../assets/ellipsis.svg";
import generate_circle from "../../assets/generate_cicle.svg";
import review_icon from "../../assets/review-icon.svg";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { useGetDocumentsByGenerationToken } from "../../pages/StandAloneViewer/hooks";
import { NotificationsEmptySvg } from "../../svg/NotificationsEmptySvg";
import { noop } from "../../utils";
import { OrganizationLink } from "../OrganizationLink";

const notificationIcon = (type: string): string => {
  switch (type) {
    case "review-document-reminder":
      return review_icon;
    case "system-notification":
      return generate_circle;
    default:
      return generate_circle;
  }
};

type NotificationsProps = {
  forceOpen: boolean;
  markNotificationAsRead(
    notificationIdList: Array<string>
  ): Promise<HttpMarkNotificationsAsReadResponse["payload"]>;
  removeNotifications(
    notificationIdList: Array<string>
  ): Promise<HttpRemoveNotificationsResponse["payload"]>;
};

export const NotificationsIcon: FunctionComponent<NotificationsProps> = ({
  forceOpen,
  markNotificationAsRead,
  removeNotifications,
}: NotificationsProps) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { getNotifications } = useServices();
  const fetchNotifications = useCallback(() => {
    return getNotifications(0);
  }, [getNotifications]);

  const {
    data: notifications,
    remove,
    refetch,
    isLoading,
    isRefetching,
  } = useOrganizationQuery(["notifications-header"], fetchNotifications, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: 30 * 1000,
  });

  const refetchAll = useCallback(() => {
    remove();
    refetch();
  }, [remove, refetch]);

  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const closePanel = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        anchorRef.current &&
        !(event.target instanceof Node && anchorRef.current.contains(event.target))
      ) {
        closePanel();
      }
    },
    [closePanel]
  );

  useEffect(() => {
    const handler = handleClickOutside;
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [handleClickOutside]);

  const onMarkAsRead = useCallback(
    (notificationsIdList: Array<string>) => {
      markNotificationAsRead(notificationsIdList).then((_res) => {
        refetchAll();
      });
      closePanel();
    },
    [markNotificationAsRead, refetchAll, closePanel]
  );

  const onMarkAllAsRead = useCallback(() => {
    const nots = notifications?.notifications || [];
    if (nots.length > 0) {
      const notificationsIdList = nots.map((n) => n.id);
      onMarkAsRead(notificationsIdList);
    }
  }, [notifications, onMarkAsRead]);

  const unreadCount = useMemo(() => {
    if (!notifications) {
      return 0;
    }
    return notifications.notifications.filter((notification) => !notification.isRead).length;
  }, [notifications]);

  return (
    <BoxWithRef
      ref={anchorRef}
      aria-label={resources.notifications.notifications}
      aria-roledescription="button"
      dangerouslySetInlineStyle={{
        __style: {
          cursor: "pointer",
        },
      }}
    >
      <Box position="relative">
        <Icon
          padding={1}
          color={colors.primary}
          hoverColor={colors.white}
          textShadowOnHover={true}
          accessibilityLabel={resources.notifications.notifications}
          icon="bell"
          size={6}
          onClick={onClick}
        />

        {unreadCount > 0 && (
          <Box
            position="absolute"
            bottom={true}
            color={colors.error}
            borderRadius={12}
            width={16}
            height={16}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClickCapture={onClick}
            marginTop={2}
            padding={1}
          >
            <Text color={colors.white} size="100">
              {unreadCount > 9 ? "9+" : unreadCount}
            </Text>
          </Box>
        )}
      </Box>

      {(open || forceOpen) && (
        <Popover
          anchor={anchorRef.current}
          onDismiss={closePanel}
          idealDirection="down"
          positionRelativeToAnchor={true}
          size="xl"
          shouldFocus={true}
          color="white"
          showDismissButton={true}
          role="menu"
        >
          <Box color={colors.white}>
            <Box minWidth={320} marginTop={4} marginBottom={1}>
              <Box display="flex" direction="column">
                <Box marginBottom={4} marginStart={5} marginEnd={5}>
                  <Box column={6} display="inlineBlock">
                    <Text size="200" weight="bold">
                      {resources.notifications.notifications}
                    </Text>
                  </Box>
                  <Box column={6} display="inlineBlock" onClickCapture={onMarkAllAsRead}>
                    <TapArea fullWidth={false} role="button">
                      <Text size="200" color={colors.primary} align="end">
                        {resources.notifications.markAllAsRead}
                      </Text>
                    </TapArea>
                  </Box>
                </Box>
                <Box height={1} width={"100%"} color={colors.neutral100}></Box>
                <Box paddingY={4} justifyContent="center">
                  <OrganizationLink
                    to={organizationLinkTemplates.notificationsPage()}
                    onClick={closePanel}
                  >
                    <Text size="200" color={colors.primary} align="center">
                      {resources.notifications.seeAllNotifications}
                    </Text>
                  </OrganizationLink>
                </Box>
                <Box>
                  <NotificationItems
                    isLoading={isLoading || isRefetching}
                    notifications={(notifications?.notifications || []).map((notification) => {
                      return {
                        notification: notification,
                        pageNumber: 0,
                      };
                    })}
                    isFullPage={false}
                    removeNotifications={removeNotifications}
                    refetchAll={refetchAll}
                    onMarkAsRead={onMarkAsRead}
                    setNotificationsMap={noop}
                    notificationsSelected={[]}
                    onClickItemSelect={console.log}
                    closeNotifications={closePanel}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Popover>
      )}
    </BoxWithRef>
  );
};

type NotificationItemsProps = {
  isLoading: boolean;
  notifications: Array<{
    notification: UserNotification;
    pageNumber: number;
  }>;
  notificationsSelected: UserNotification[];
  isFullPage: boolean;
  removeNotifications: NotificationsProps["removeNotifications"];
  refetchAll(): void;
  onMarkAsRead: (notificationsIdList: Array<string>) => void;
  onClickItemSelect: (notification: UserNotification) => void;
  closeNotifications?: () => void;
  setNotificationsMap: React.Dispatch<
    React.SetStateAction<Map<string, { notification: UserNotification; pageNumber: number }>>
  >;
};

export const NotificationItems: FunctionComponent<NotificationItemsProps> = ({
  isLoading,
  notifications,
  isFullPage,
  removeNotifications,
  refetchAll,
  onMarkAsRead,
  setNotificationsMap,
  closeNotifications,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [activeItem, setActiveItem] = useState<string>();
  const [activeSubItem, setActiveSubItem] = useState<undefined | "MarkAsRead" | "Delete">();
  const [openItemId, setOpenItemId] = useState<string | undefined>();

  const doRemoveNotifications = useCallback(
    (notificationsIdList: Array<string>) => {
      removeNotifications(notificationsIdList).then(() => {
        refetchAll();
        setNotificationsMap((prevMap) => {
          const newMap = new Map(prevMap);
          notificationsIdList.forEach((id) => {
            newMap.delete(id);
          });
          return newMap;
        });
      });
    },
    [refetchAll, removeNotifications, setNotificationsMap]
  );

  const onClickItem = useCallback((id?: string) => {
    setOpenItemId((prevOpenItemId) => (prevOpenItemId === id ? undefined : id));
  }, []);
  const onLeaveItem = useCallback(() => {
    setActiveItem(undefined);
    setOpenItemId(undefined);
    setActiveSubItem(undefined);
  }, []);

  if (isLoading) {
    return (
      <Box padding={5} display="flex" justifyContent="center">
        <Spinner accessibilityLabel={"Loading"} show={true} />
      </Box>
    );
  }

  if (!notifications || (notifications.length === 0 && !isLoading)) {
    return (
      <Box
        padding={5}
        borderStyle="sm"
        display="flex"
        alignItems="center"
        direction="column"
        maxWidth={isFullPage ? undefined : 350}
      >
        <NotificationsEmptySvg width={350} height={252} />
        <Text size="200" color={colors.neutral900} align="center">
          <Text size="200" weight="bold" color={colors.neutral900} align="center">
            {resources.notifications.noNotificationsToShow}
          </Text>
          {resources.notifications.weWillLetYouKnow}
        </Text>
      </Box>
    );
  }

  return (
    <>
      {(notifications || []).map(({ notification }) => (
        <NotificationSingleItem
          key={notification.id}
          isFullPage={isFullPage}
          notification={notification}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          onLeaveItem={onLeaveItem}
          onClickItem={onClickItem}
          setActiveSubItem={setActiveSubItem}
          openItemId={openItemId}
          activeSubItem={activeSubItem}
          onMarkAsRead={onMarkAsRead}
          doRemoveNotifications={doRemoveNotifications}
          closeNotifications={closeNotifications}
        />
      ))}
    </>
  );
};

const isNode = (value: unknown): value is Node => {
  return value instanceof Node;
};

export type NotificationSingleItemProps = {
  notification: UserNotification;
  isFullPage: boolean;
  activeItem: string | undefined;
  activeSubItem: undefined | "MarkAsRead" | "Delete";
  openItemId: string | undefined;
  setActiveItem(value: string): void;
  setActiveSubItem(value: undefined | "MarkAsRead" | "Delete"): void;
  onLeaveItem(): void;
  onClickItem(id?: string): void;
  onMarkAsRead: (notificationsIdList: Array<string>) => void;
  doRemoveNotifications: (notificationsIdList: Array<string>) => void;
  closeNotifications: (() => void) | undefined;
};

export const NotificationSingleItem: FunctionComponent<NotificationSingleItemProps> = ({
  notification,
  isFullPage,
  activeItem,
  activeSubItem,
  openItemId,
  setActiveItem,
  setActiveSubItem,
  onLeaveItem,
  onClickItem,
  onMarkAsRead,
  doRemoveNotifications,
}) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();
  const anchorRefItem = useRef<HTMLDivElement | null>(null);
  const dateValue = useMemo(() => {
    const date = moment(notification.createdAt);
    if (date.diff(moment(), "days") <= -7) {
      return isFullPage ? date.format("MMM Do, YYYY - hh:MM:SS a") : date.format("MMM Do, YYYY");
    }
    return date.fromNow();
  }, [isFullPage, moment, notification.createdAt]);

  const isNotificationRead = notification.isRead === true;

  const [selectedNotification, setSelectedNotification] = useState<UserNotification | undefined>();
  const organizationNavigate = useOrganizationNavigate();

  const handleNotificationClick = useCallback(
    (notification: UserNotification, event: React.MouseEvent<HTMLDivElement>) => {
      const isInsidePopover =
        anchorRefItem.current &&
        isNode(event.target) &&
        anchorRefItem.current.contains(event.target);

      if (!isInsidePopover) {
        if (notification.notificationType === "system-notification" && notification.metadata) {
          const metadata = notification.metadata;
          if (metadata.type === "generation") {
            setSelectedNotification(notification);
          } else if (metadata.type === "document-approval") {
            onMarkAsRead([notification.id]);
            organizationNavigate(
              organizationLinkTemplates.documentId(metadata.documentId, "approvals")
            );
          }
        }
      }
    },
    [anchorRefItem, organizationNavigate, onMarkAsRead]
  );

  if (
    selectedNotification &&
    selectedNotification.notificationType === "system-notification" &&
    selectedNotification.metadata?.type === "generation"
  ) {
    const generationToken = selectedNotification.metadata.generationToken;
    return (
      <DocumentRedirect
        generationToken={generationToken}
        notificationId={notification.id}
        onMarkAsRead={onMarkAsRead}
      />
    );
  }

  return (
    <TapArea
      key={notification.id}
      onMouseEnter={() => setActiveItem(notification.id)}
      onMouseLeave={onLeaveItem}
      role="button"
    >
      <Box display="flex" position="relative">
        {notification.isRead === false && (
          <Box position="absolute" width={5} height={"100%"} color={colors.primary}></Box>
        )}
        <Box
          color={activeItem === notification.id ? colors.primary100 : colors.white}
          minHeight={100}
          width={"95%"}
          padding={3}
          column={12}
          onClickCapture={(event) => handleNotificationClick(notification, event)}
        >
          <Grid gridTemplateColumns={[1, 10, 1]}>
            <Box display="flex" direction="column">
              <Box width={56} height={56} marginTop={2} marginStart={1} marginEnd={4}>
                <Image
                  alt="printer"
                  src={notificationIcon(notification.notificationType)}
                  naturalHeight={56}
                  naturalWidth={56}
                  color="transparent"
                />
              </Box>
            </Box>
            <Box display="flex" direction="column" gap={1} justifyContent={"start"}>
              <Box marginTop={2}>
                <Box display="flex" direction="column" gap={1}>
                  {notification.notificationType === "system-notification" && (
                    <>
                      <Text
                        size="300"
                        weight="bold"
                        lineClamp={1}
                        color={isNotificationRead ? colors.neutral600 : undefined}
                      >
                        {notification.title}
                      </Text>
                      <Text
                        size="200"
                        lineClamp={1}
                        color={isNotificationRead ? colors.neutral600 : undefined}
                      >
                        {notification.bodyHtml}
                      </Text>
                    </>
                  )}
                  {notification.notificationType === "review-document-reminder" && (
                    <Text
                      size="300"
                      weight="bold"
                      color={isNotificationRead ? colors.neutral600 : undefined}
                    >
                      Go to the Document {notification.documentType}
                    </Text>
                  )}
                  <Text
                    size="200"
                    color={isNotificationRead ? colors.neutral500 : colors.neutral900}
                  >
                    {dateValue}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={activeItem === notification.id ? colors.primary100 : colors.white}
        >
          {isFullPage ? (
            <Box display="flex" direction="column" flex="shrink">
              <BoxWithRef
                ref={anchorRefItem}
                marginTop={8}
                marginStart={8}
                width={6}
                height={24}
                onClickCapture={() => onClickItem(notification.id)}
              >
                <Image
                  alt={resources.options}
                  color="transparent"
                  naturalHeight={22}
                  naturalWidth={6}
                  src={ellipsis}
                  role="img"
                  fit={"contain"}
                />
                {openItemId === notification.id && (
                  <Layer zIndex={modalZIndex}>
                    <Popover
                      anchor={anchorRefItem.current}
                      onDismiss={() => onClickItem(undefined)}
                      idealDirection="left"
                      positionRelativeToAnchor={false}
                      size={"flexible"}
                      color="white"
                      role="menu"
                    >
                      <Box
                        width={167}
                        height={isNotificationRead ? 50 : 90}
                        padding={2}
                        color={colors.white}
                      >
                        {!isNotificationRead && (
                          <TapArea
                            onMouseEnter={() => setActiveSubItem("MarkAsRead")}
                            onMouseLeave={() => setActiveSubItem(undefined)}
                          >
                            <Box
                              height={35}
                              onClickCapture={() => onMarkAsRead([notification.id])}
                              rounding={1}
                              padding={2}
                              color={activeSubItem === "MarkAsRead" ? colors.neutral100 : undefined}
                              borderStyle={activeSubItem === "MarkAsRead" ? "sm" : "none"}
                              marginBottom={1}
                            >
                              <Text size="200">{resources.notifications.markAsRead}</Text>
                            </Box>
                          </TapArea>
                        )}
                        <TapArea
                          onMouseEnter={() => setActiveSubItem("Delete")}
                          onMouseLeave={() => setActiveSubItem(undefined)}
                        >
                          <Box
                            height={35}
                            onClickCapture={() => doRemoveNotifications([notification.id])}
                            rounding={1}
                            padding={2}
                            color={activeSubItem === "Delete" ? colors.neutral100 : undefined}
                            borderStyle={activeSubItem === "Delete" ? "sm" : "none"}
                            marginBottom={1}
                          >
                            <Text size="200">{resources.delete_}</Text>
                          </Box>
                        </TapArea>
                      </Box>
                    </Popover>
                  </Layer>
                )}
              </BoxWithRef>
            </Box>
          ) : (
            <Box display="flex" direction="column" alignItems="center" justifyContent="center">
              {!isNotificationRead && (
                <Box padding={2}>
                  <Icon
                    accessibilityLabel={resources.notifications.markAsRead}
                    icon="circle-check"
                    color={colors.neutral900}
                    onClick={() => onMarkAsRead([notification.id])}
                  />
                </Box>
              )}
              <Box padding={2}>
                <Icon
                  accessibilityLabel={resources.delete_}
                  icon="trash"
                  color={colors.neutral900}
                  onClick={() => doRemoveNotifications([notification.id])}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </TapArea>
  );
};

const DocumentRedirect: React.FC<{
  generationToken: string;
  notificationId: string;
  onMarkAsRead: (notificationsIdList: Array<string>) => void;
}> = ({ generationToken, notificationId, onMarkAsRead }) => {
  const organizationNavigate = useOrganizationNavigate();
  const { data: documents } = useGetDocumentsByGenerationToken(generationToken || "");

  const onMarkAsReadMemo = useCallback(() => {
    return onMarkAsRead([notificationId]);
  }, [notificationId, onMarkAsRead]);

  const fetchData = useCallback(() => {
    if (!documents) return;
    if (documents.documents?.length === 1) {
      organizationNavigate(`/documents/${documents.documents[0].documentId}`);
    } else {
      organizationNavigate(organizationLinkTemplates.documentGenerationStatus(generationToken));
    }

    if (notificationId) {
      onMarkAsReadMemo();
    }
  }, [documents, generationToken, organizationNavigate, onMarkAsReadMemo, notificationId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return null;
};
