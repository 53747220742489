import {
  Box,
  Checkbox,
  ComboBox,
  Label,
  PopoverCombobox,
  Switch,
  Text,
  TextField,
} from "@prodoctivity/design-system";
import { ExpressionControl } from "@prodoctivity/fluency-components";
import { CustomAccordion } from "@prodoctivity/prodoctivity-form-v5/src/components/Dependencies/CustomAccordion";
import type { DocumentCollectionIcon } from "@prodoctivity/shared/src/index-types";
import { FolderValidationRowProps, useFolderValidationRow } from "./hooks";

export const FolderValidationRow = (props: FolderValidationRowProps) => {
  const {
    currIndex,
    resources,
    validationNameState,
    onValidationChange,
    validationDescriptionState,
    openInvalidExpressionConfig,
    setOpenInvalidExpressionConfig,
    updateValidation,
    setInvalidExpressionName,
    invalidExpressionConfig,
    isLoading,
    folderDocumentTypesOptions,
    getConditionText,
    expressionDataInput,
    mergedContextDefinition,
    validation,
    onExpressionChange,
    moment,
    tI18n,
    onFilterChange,
    filter,
    iconOptions,
    colors,
    emptyInvalidExpressionConfig,
    invalidExpressionName,
    setConfigItem,
    setIsFolderChangesButtonDisabled,
  } = useFolderValidationRow(props);

  return (
    <Box>
      <Box display="flex" gap={4} flex="grow">
        <Box display="flex" direction="column" gap={2} marginBottom={4}>
          <Box width={116}>
            <Label htmlFor={`folder-validation-${currIndex}-name`}>{resources.name}</Label>
          </Box>
          <Box minWidth={260}>
            <TextField
              id={`folder-validation-${currIndex}-name`}
              value={validationNameState}
              onChange={(value) => {
                onValidationChange("name", value);
              }}
              errorMessage={validationNameState === "" ? resources.dataDictionary.noName : false}
            />
          </Box>
        </Box>
        <Box display="flex" direction="column" gap={2} flex="grow">
          <Box width={116}>
            <Label htmlFor={`folder-validation-${currIndex}-description`}>
              {resources.description}
            </Label>
          </Box>
          <Box flex="grow">
            <TextField
              onChange={(value) => {
                onValidationChange("description", value);
              }}
              id={`folder-validation-${currIndex}-description`}
              placeholder={resources.description}
              value={validationDescriptionState}
              maxLength={{
                characterCount: 100,
                errorAccessibilityLabel: "100 max",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box flex="grow" display="flex" direction="row" gap={4} alignItems="center" marginBottom={6}>
        <Box>
          <Label htmlFor={`chk-validation-config-invalid-expression-${currIndex}`}>
            {resources.configureInvalidExpression}
          </Label>
        </Box>
        <Switch
          id={`chk-validation-config-invalid-expression-${currIndex}`}
          switched={openInvalidExpressionConfig}
          onChange={() => {
            setOpenInvalidExpressionConfig(!openInvalidExpressionConfig);
            if (openInvalidExpressionConfig) {
              updateValidation("invalidExpressionConfig", undefined);
              setInvalidExpressionName("");
              setIsFolderChangesButtonDisabled(validationNameState === "" ? true : false);
            } else if (!openInvalidExpressionConfig && invalidExpressionConfig === undefined) {
              updateValidation("invalidExpressionConfig", emptyInvalidExpressionConfig);
              setInvalidExpressionName("");
              setIsFolderChangesButtonDisabled(invalidExpressionName === "" ? true : false);
            }
          }}
        />
      </Box>
      {openInvalidExpressionConfig && invalidExpressionConfig && (
        <Box display="flex" direction="column" gap={4} marginBottom={4}>
          <Box display="flex" gap={4} flex="grow">
            <Box minWidth={260} display="flex" direction="column" gap={4}>
              <Box display="flex" alignItems="center">
                <Label
                  htmlFor={`chk-validation-config-invalid-expression-add-entry-check-${currIndex}`}
                >
                  {resources.addEntry}
                </Label>
              </Box>
              <Checkbox
                id={`chk-validation-config-invalid-expression-add-entry-check-${currIndex}`}
                checked={invalidExpressionConfig.addEntry}
                onChange={() => {
                  updateValidation(
                    "invalidExpressionConfig",
                    !invalidExpressionConfig.addEntry,
                    "addEntry"
                  );
                }}
              />
            </Box>
            <Box display="flex" flex="grow" direction="column" gap={2}>
              <Box width={116}>
                <Label htmlFor={`folder-validation-${currIndex}-error-msg`}>
                  {resources.errorMessageLabel}
                </Label>
              </Box>
              <Box minWidth={260}>
                <TextField
                  id={`folder-validation-${currIndex}-error-msg`}
                  value={invalidExpressionName}
                  onChange={(value) => {
                    onValidationChange("invalidExpressionName", value);
                  }}
                  errorMessage={invalidExpressionName === "" ? resources.errorMessageEmpty : false}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" gap={4} flex="grow">
            <Box display="flex" direction="column" gap={2} marginBottom={4}>
              <Box width={116}>
                <Label htmlFor={`folder-validation-${currIndex}-error-icon`}>
                  {resources.icon}
                </Label>
              </Box>
              <Box minWidth={260}>
                <PopoverCombobox
                  key={`folder-validation-${currIndex}-error-icon`}
                  filter={filter}
                  onFilterChange={onFilterChange}
                  getI18n={(k) => {
                    const m: Record<string, string> = resources as any;
                    return m[k] || k;
                  }}
                  selectedValue={invalidExpressionConfig.icon}
                  onSelect={(item) =>
                    setConfigItem((prev) => {
                      if (!prev) return prev;
                      const updatedItem = { ...prev.updatedItem };
                      if (updatedItem.type === "folder") {
                        invalidExpressionConfig.icon = item.value as DocumentCollectionIcon;
                        updatedItem.item.validations[currIndex].invalidExpressionConfig =
                          invalidExpressionConfig;
                      }
                      return {
                        ...prev,
                        updatedItem: updatedItem,
                      };
                    })
                  }
                  options={iconOptions}
                  direction={undefined}
                  placeholder={undefined}
                  onClear={undefined}
                  displayOption="label"
                  displayedText={undefined}
                />
              </Box>
            </Box>

            <Box display="flex" direction="column" gap={2} flex="grow">
              <Box width={160}>
                <Label htmlFor={`folder-validation-${currIndex}-document-type-id`}>
                  {resources.documentTypes.documentType}
                </Label>
              </Box>
              <Box flex="grow">
                <ComboBox
                  id={`folder-validation-${currIndex}-document-type-id`}
                  label=""
                  disabled={isLoading}
                  size="lg"
                  options={folderDocumentTypesOptions}
                  onSelect={(e) =>
                    updateValidation("invalidExpressionConfig", e.item.value, "documentTypeId")
                  }
                  onChange={(e) => {
                    const documentTypeFound = folderDocumentTypesOptions.find(
                      (dt) => dt.value === e.value
                    );
                    updateValidation(
                      "invalidExpressionConfig",
                      documentTypeFound ? documentTypeFound.value : "",
                      "documentTypeId"
                    );
                  }}
                  onClear={() => updateValidation("invalidExpressionConfig", "", "documentTypeId")}
                  placeholder={
                    folderDocumentTypesOptions.find(
                      (dt) => dt.value === invalidExpressionConfig.documentTypeId
                    )?.label
                  }
                  inputValue={
                    folderDocumentTypesOptions.find(
                      (dt) => dt.value === invalidExpressionConfig.documentTypeId
                    )?.label
                  }
                  selectedOption={folderDocumentTypesOptions.find(
                    (dt) => dt.value === invalidExpressionConfig.documentTypeId
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box borderRadius={6} borderColor={colors.neutral900}>
        <CustomAccordion
          removeLabel={"remove"}
          label={
            <Box display="flex" direction="row" gap={2} paddingY={2}>
              <Text>{`${resources.rule}: ${getConditionText(
                expressionDataInput(validation.expression)
              )}`}</Text>
            </Box>
          }
          currIndex={currIndex}
          i18n={tI18n}
          canDeleteRow={false}
          hideLabelOnOpen={true}
          collapsedColor={colors.primaryHover0}
          openedColor={colors.neutral400}
        >
          <ExpressionControl
            name="include_condition_expression"
            contextDefinition={mergedContextDefinition}
            expression={expressionDataInput(validation.expression)}
            hideName={true}
            contextualIf={{ node: <></>, value: () => true }}
            onExpressionChanged={onExpressionChange}
            moment={moment}
            translate={tI18n}
            resources={resources}
          />
          <Box
            display="flex"
            width={"100%"}
            minHeight={56}
            alignItems="center"
            padding={4}
            color={colors.primary}
            borderColor={colors.neutral50}
            borderRadius={6}
          >
            <Text color={colors.white}>
              {getConditionText(expressionDataInput(validation.expression))}
            </Text>
          </Box>
        </CustomAccordion>
      </Box>
    </Box>
  );
};
