import { Box, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

import type { DocumentCollectionAssignedLogEntry } from "@prodoctivity/types";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../../link-templates";
import { DocumentCollectionInstanceName } from "../../DocumentCollectionInstanceName";
import { OrganizationNavLink } from "../../OrganizationLink";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  id: string;
  fullScreen: boolean | undefined;
  fullWidth: boolean | undefined;
  entry: DocumentCollectionAssignedLogEntry;
};

export const DocumentCollectionEntry: FunctionComponent<Props> = ({
  id,
  entry,
  fullScreen,
  fullWidth,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  return (
    <Box display="flex" direction={fullWidth ? "row" : "column"} flex="grow">
      {fullWidth ? (
        <Box marginEnd={6}>
          <PublicProfile username={entry.username} onlyImage={true} sizeImage={48} />
        </Box>
      ) : null}
      <Box display="flex" direction="column">
        <Text inline={true} size="200">
          <Box display="flex" direction="column">
            <PublicProfile username={entry.username} onlyImage={false} size="300" />
            <Box display="flex" direction="column">
              <Text inline={true} size="200">
                {resources.activities.documentCollectionNamed}
              </Text>
              <OrganizationNavLink
                to={organizationLinkTemplates.documentCollection(entry.instanceId)}
              >
                <Text weight="bold" size="200" ellipsisLength={20}>
                  {entry.configName}
                </Text>
              </OrganizationNavLink>
              {entry.assignedTo ? (
                <Box>
                  <Text inline={true} size="200">
                    {resources.assignTo}:
                  </Text>
                  <PublicProfile username={entry.assignedTo} onlyImage={false} size="200" />
                </Box>
              ) : (
                <Box>
                  <Text inline={true} size="200">
                    {resources.unassigned}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Text>
        {!fullScreen ? (
          <Box>
            <OrganizationNavLink to={`/activities/${id}`}>
              <Text size="200" color={colors.primary}>
                {resources.activities.viewEntry}
              </Text>
            </OrganizationNavLink>
          </Box>
        ) : (
          <Box display="flex" direction="column">
            {!fullScreen && (
              <Box>
                <OrganizationNavLink to={`/activities/${id}`}>
                  <Text size="200" color={colors.primary}>
                    {resources.activities.viewEntry}
                  </Text>
                </OrganizationNavLink>
              </Box>
            )}
            <Box margin={1}></Box>
            <Box display="flex" direction="column" gap={4}>
              {fullScreen ? (
                <Box display="flex" gap={2} direction="column">
                  <Box borderStyle="sm" width={"100%"} height={1} />
                  <Box display="flex" direction="row" gap={2}>
                    <Text inline={true} size="200">
                      {resources.documentTypes.documentCollection}:
                    </Text>
                    <OrganizationNavLink
                      to={organizationLinkTemplates.documentCollection(entry.instanceId)}
                    >
                      <Text weight="bold" size="200" ellipsisLength={20}>
                        <DocumentCollectionInstanceName
                          configId={entry.configId}
                          instanceId={entry.instanceId}
                        />
                      </Text>
                    </OrganizationNavLink>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <OrganizationNavLink
                    to={organizationLinkTemplates.documentCollection(entry.instanceId)}
                  >
                    <Text inline={true} size="200" ellipsisLength={30}>
                      {entry.configName}
                    </Text>
                  </OrganizationNavLink>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
