import { FunctionComponent, useCallback } from "react";

import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../../link-templates";
import { DataElementConfiguration } from "./DataElementConfiguration";

type Props = {
  isUpdating: boolean;
};

const DataElementConfigurationPage: FunctionComponent<Props> = ({ isUpdating }) => {
  const organizationNavigate = useOrganizationNavigate();

  const onCancel = useCallback(() => {
    organizationNavigate(organizationLinkTemplates.dataElements());
  }, [organizationNavigate]);

  return (
    <DataElementConfiguration
      isUpdating={isUpdating}
      onCancel={onCancel}
      isPanelHidden={true}
      contextFieldState={undefined}
    />
  );
};

export default DataElementConfigurationPage;
