import {
  Box,
  Divider,
  Image,
  Text,
  useColors,
  BannerSlim,
  Svg,
  Grid,
  useDesignBreakpoint,
  TapArea,
  SelectList,
  Button,
  TextField,
  TextArea,
} from "@prodoctivity/design-system";
import { useUpgradePlanPage } from "./hooks";
import LogoWhiteUrl from "../../assets/prodoctivity-noiso-white.png";
import checkCircle from "../../assets/checkCircle.png";

import { Page } from "../../components/Layout/Page";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useCallback, useMemo, useState } from "react";

function UpgradePlanPage() {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();

  const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
  const [selectedPlanToUpgrade, setSelectedPlanToUpgrade] = useState<{
    plan: string;
    method: string;
    email: string;
    message: string;
  }>({
    plan: "",
    method: "",
    email: "",
    message: "",
  });

  const canSubmit = useMemo(() => {
    return Boolean(
      selectedPlanToUpgrade.plan && selectedPlanToUpgrade.email && selectedPlanToUpgrade.message
    );
  }, [selectedPlanToUpgrade.email, selectedPlanToUpgrade.message, selectedPlanToUpgrade.plan]);

  const onSubmit = useCallback(() => {
    setSelectedPlanToUpgrade({
      plan: "",
      method: "",
      email: "",
      message: "",
    });
    setSuccessSubmit(true);
  }, [setSelectedPlanToUpgrade, setSuccessSubmit]);

  const {
    breadCrumbEntries,
    breakpointFluid,
    showYearly,
    setShowYearly,
    subscriptionData,
    toPayment,
    upgradePlanSetting,
    layout,
    planSelection,
    breakPointSmallDuo,
  } = useUpgradePlanPage();

  const activePlanIndex = useMemo(() => {
    return subscriptionData.findIndex((planData) => planData.plan === upgradePlanSetting.plan);
  }, [subscriptionData, upgradePlanSetting.plan]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" alignItems="center">
        <Box
          paddingX={breakpointFluid ? 5 : 12}
          paddingY={breakPointSmallDuo ? 6 : breakpoint === "large" ? 6 : 6}
        >
          {/* Header */}
          <Box
            display="flex"
            direction="column"
            alignItems={breakpointFluid ? "center" : undefined}
          >
            <Box
              display="flex"
              direction="column"
              alignItems={breakpointFluid ? "center" : "start"}
            >
              <Box display="flex">
                <Text color={colors.black900} size="400" weight="bold">
                  {resources.upgradePage.plansPricing}
                </Text>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent={breakpointFluid ? "center" : "evenly"}
                direction={breakpointFluid ? "column" : "row"}
                width={"100%"}
              >
                <Box display="flex" width={"100%"} marginBottom={breakpointFluid ? 6 : undefined}>
                  <Text
                    color={colors.black600}
                    title={resources.upgradePage.exploreFeatures}
                    ellipsisLength={breakpointFluid ? 150 : 150}
                    align={breakpointFluid ? "center" : undefined}
                  >
                    {resources.upgradePage.exploreFeatures}
                  </Text>
                </Box>

                {upgradePlanSetting?.upgradePlanBehavior !== "managed" && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="between"
                    width={breakpointFluid ? "50%" : "35%"}
                    borderRadius={48}
                    borderStyle="shadow"
                    height={"auto"}
                  >
                    <Box
                      display="flex"
                      color={showYearly ? colors.neutral50 : colors.secondary}
                      rounding={3}
                      fit={true}
                      width={breakpointFluid ? "100%" : "60%"}
                      justifyContent="center"
                      alignItems="center"
                      height={42}
                      borderRadius={!showYearly ? 48 : undefined}
                    >
                      <TapArea onTap={() => setShowYearly(false)}>
                        <Box>
                          <Text
                            size="200"
                            weight="bold"
                            color={showYearly ? colors.black600 : colors.white}
                            title={resources.upgradePage.monthly}
                            align="center"
                          >
                            {resources.upgradePage.monthly}
                          </Text>
                        </Box>
                      </TapArea>
                    </Box>

                    <Box
                      display="flex"
                      color={!showYearly ? colors.neutral50 : colors.secondary}
                      rounding={3}
                      fit={true}
                      width={breakpointFluid ? "100%" : "60%"}
                      borderRadius={showYearly ? 48 : undefined}
                      height={42}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <TapArea onTap={() => setShowYearly(true)}>
                        <Box>
                          <Text
                            size="200"
                            weight="bold"
                            color={!showYearly ? colors.black600 : colors.white}
                            title={
                              resources.upgradePage.yearly + " " + resources.upgradePage.save15
                            }
                            ellipsisLength={breakpointFluid ? 10 : 13}
                            align="center"
                          >
                            {resources.upgradePage.yearly + " " + resources.upgradePage.save15}
                          </Text>
                        </Box>
                      </TapArea>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Pricing cards */}
          <Box
            display="flex"
            justifyContent="center"
            direction="row"
            paddingX={breakPointSmallDuo || breakpoint === "large" ? 4 : undefined}
            marginTop={8}
          >
            <Grid gridTemplateColumns={layout} gap={3}>
              {subscriptionData.map((card, index) => {
                const current =
                  card.plan === upgradePlanSetting.plan
                    ? { ...card, activePlan: upgradePlanSetting.plan }
                    : card;
                return (
                  <>
                    <Box
                      key={index}
                      display="flex"
                      direction="column"
                      width={"100%"}
                      minWidth={200}
                      borderRadius={6}
                      borderStyle="shadow"
                      justifyContent="between"
                      borderColor={current.activePlan ? colors.primary : undefined}
                      position="relative"
                    >
                      <Box flex="grow">
                        {current.activePlan && (
                          <Box
                            display="flex"
                            justifyContent="end"
                            color={colors.primary}
                            padding={2}
                            width={"fit-content"}
                            position="absolute"
                            right
                            dangerouslySetInlineStyle={{
                              __style: {
                                borderTopRightRadius: "4px",
                                borderBottomLeftRadius: "4px",
                              },
                            }}
                          >
                            <Text size="100" color={colors.neutral200} weight="bold" align="center">
                              {resources.upgradePage.currentPlan.toUpperCase()}
                            </Text>
                          </Box>
                        )}
                        <Box>
                          <Box
                            display="flex"
                            justifyContent="center"
                            position="relative"
                            marginTop={10}
                          >
                            <Box position="absolute" width={192} height={34}>
                              <Image
                                alt="ProDoctivity"
                                color="transparent"
                                naturalHeight={34}
                                naturalWidth={192}
                                src={LogoWhiteUrl}
                                role="presentation"
                                fit="contain"
                              />
                            </Box>
                          </Box>

                          <Box display="flex" direction="column" position="relative">
                            <Box padding={6} width={"100%"} marginTop={6}>
                              <Text
                                align="center"
                                color={colors.primary}
                                weight="bold"
                                size="400"
                                title={current.title}
                              >
                                {current.title}
                              </Text>
                              <Box
                                display="flex"
                                alignItems="center"
                                direction="column"
                                marginTop={2}
                              >
                                {showYearly ? (
                                  upgradePlanSetting?.upgradePlanBehavior !== "managed" && (
                                    <Text
                                      decoration="line-through"
                                      weight="bold"
                                      size="300"
                                      color={colors.neutral600}
                                    >
                                      USD ${current.rawAnnualPrice}
                                    </Text>
                                  )
                                ) : upgradePlanSetting?.upgradePlanBehavior === "managed" ? (
                                  <></>
                                ) : (
                                  <Box maxWidth={355}>
                                    <BannerSlim
                                      type="recommendation"
                                      message={resources.pricingPage.twoMonthsOffDescription}
                                      primaryAction={{
                                        accessibilityLabel: resources.pricingPage.payAnnually,
                                        label: resources.accept,
                                        onClick: () => {
                                          setShowYearly(true);
                                        },
                                        role: "button",
                                      }}
                                    />
                                  </Box>
                                )}
                              </Box>
                              {upgradePlanSetting?.upgradePlanBehavior !== "managed" && (
                                <>
                                  <Text
                                    align="center"
                                    color={colors.primary}
                                    size="400"
                                    weight="bold"
                                  >
                                    USD ${showYearly ? current.priceYear : current.priceMonth}
                                  </Text>
                                  <Text align="center" color={colors.primary} size="400">
                                    {showYearly
                                      ? resources.pricingPage.annually
                                      : resources.pricingPage.monthly}
                                  </Text>
                                </>
                              )}
                              <Divider />
                              {current.features.map((item, index) => {
                                return (
                                  <Box display="flex" direction="column" key={index}>
                                    <Box display="flex" alignItems="center" margin={3}>
                                      <Box width={24} height={24} marginEnd={2}>
                                        <Svg src={checkCircle} alt="ProDoctivity Logo" />
                                      </Box>
                                      <Text size="200" weight="normal">
                                        {item}
                                      </Text>
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {upgradePlanSetting?.upgradePlanBehavior !== "managed" &&
                        current?.activePlan !== upgradePlanSetting.plan && (
                          <Box marginStart={6} marginBottom={8}>
                            <TapArea onTap={() => toPayment(card)}>
                              <Box
                                maxWidth={160}
                                height={42}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                color={activePlanIndex < index ? colors.primary : colors.white}
                                borderStyle="sm"
                                borderRadius={6}
                                hoverColor={
                                  activePlanIndex < index ? colors.primary400 : colors.neutral300
                                }
                              >
                                <Text
                                  weight="bold"
                                  color={activePlanIndex < index ? colors.white : colors.black600}
                                >
                                  {activePlanIndex < index
                                    ? resources.upgradePlan
                                    : resources.pricingPage.downgradePlan}
                                </Text>
                              </Box>
                            </TapArea>
                          </Box>
                        )}
                    </Box>
                  </>
                );
              })}
            </Grid>
          </Box>

          {/* contact form */}
          {upgradePlanSetting.upgradePlanBehavior === "managed" && (
            <Box width={breakpointFluid ? "90%" : "65%"} margin="auto">
              <Box
                display="flex"
                marginTop={12}
                direction="column"
                alignItems={breakpointFluid ? "center" : undefined}
                borderRadius={12}
                color={colors.neutral300}
                paddingX={breakpointFluid ? 2 : 12}
                paddingY={10}
              >
                {!successSubmit ? (
                  <>
                    {/* form title */}
                    <Box marginBottom={2}>
                      <Text color={colors.black900} size="400" weight="bold" align="center">
                        {resources.pricingPage.contactUsIfYouNeedToSwitchYourPlan}
                      </Text>
                    </Box>
                    {/* form description */}
                    <Box display="flex" width={"100%"}>
                      <Text
                        color={colors.black600}
                        title={resources.upgradePage.exploreFeatures}
                        ellipsisLength={breakpointFluid ? 150 : 150}
                        align="center"
                      >
                        {resources.pricingPage.conctactUsDescription}
                      </Text>
                    </Box>

                    {/* select list and email */}
                    <Box
                      alignItems="end"
                      display="flex"
                      justifyContent="start"
                      paddingY={4}
                      paddingX={2}
                      gap={4}
                      marginBottom={3}
                      direction={breakpointFluid ? "column" : "row"}
                      width={"100%"}
                    >
                      <Box width={"100%"}>
                        <SelectList
                          id="planSelection"
                          label={resources.pricingPage.selectPlan}
                          size="lg"
                          onChange={({ value }) => {
                            setSelectedPlanToUpgrade((prev) => ({
                              ...prev,
                              plan: value && value.split(" /")[0],
                              method: value && value.split("/ ")[1],
                            }));
                          }}
                          options={planSelection.filter(
                            (item) =>
                              item.value.split(" /")[0].toLowerCase() !== upgradePlanSetting.plan
                          )}
                          value={
                            selectedPlanToUpgrade.plan
                              ? `${selectedPlanToUpgrade.plan} / ${selectedPlanToUpgrade.method}`
                              : ""
                          }
                        />
                      </Box>
                      <Box flex="grow" width={"100%"}>
                        <TextField
                          id={resources.pricingPage.workEmail}
                          label={resources.pricingPage.workEmail}
                          onChange={(e) => {
                            setSelectedPlanToUpgrade((prev) => ({
                              ...prev,
                              email: e.value,
                            }));
                          }}
                          type="email"
                          value={selectedPlanToUpgrade.email}
                        />
                      </Box>
                    </Box>

                    <Box marginBottom={6} width={"100%"} paddingX={2}>
                      <TextArea
                        id={resources.pricingPage.briefDescriptionOfUsage}
                        label={resources.pricingPage.briefDescriptionOfUsage}
                        onChange={(e) => {
                          setSelectedPlanToUpgrade((prev) => ({
                            ...prev,
                            message: e.value,
                          }));
                        }}
                        value={selectedPlanToUpgrade.message}
                      />
                    </Box>
                    {/* submit button */}
                    <Box marginBottom={1} width={"100%"} paddingX={2}>
                      <Button
                        fullWidth
                        disabled={!canSubmit}
                        onClick={onSubmit}
                        text={resources.pricingPage.submit}
                      />
                    </Box>
                  </>
                ) : (
                  <Box>
                    <Box marginBottom={2}>
                      <Text color={colors.black900} size="400" weight="bold" align="center">
                        {`${resources.thankYou}!`}
                      </Text>
                    </Box>
                    <Box display="flex" width={"100%"} justifyContent="center">
                      <Text
                        color={colors.black600}
                        title={resources.upgradePage.exploreFeatures}
                        ellipsisLength={breakpointFluid ? 150 : 150}
                        align="center"
                      >
                        {resources.pricingPage.constactUsSuccessMessage}
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  );
}

export default UpgradePlanPage;
