import { AlignItemsType, Video } from "gestalt";
import { useMemo, useState } from "react";
import { blobToBase64, fileToBase64 } from "../../utils";
import { Box } from "../Box";
import { useDesignBreakpoint } from "../breakpoint";

type Props = {
  src: Array<File | Blob | string>;
  captions?: string;
  strategy: "normal";
  customIcon?: JSX.Element;
  format: "audio/mpeg" | undefined;
};

export function AudioVideoPlayer({ src, captions, strategy, customIcon, format }: Props) {
  const { breakpoint } = useDesignBreakpoint();

  const alignAudioVideoPlayer: {
    alignItems: AlignItemsType | undefined;
    height: string | number | undefined;
    aspectRatio: number;
  } = useMemo(() => {
    return {
      alignItems: format === "audio/mpeg" ? "center" : undefined,
      height: format === "audio/mpeg" ? "100vh" : undefined,
      aspectRatio: format === "audio/mpeg" ? 1024 / 60 : 1024 / 576,
    };
  }, [format]);

  const [filesToBase64, setFilesToBase64] = useState("");
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);

  if (src) {
    (src || []).map(async (doc) => {
      if (doc instanceof File) {
        const convertFilesToBase64 = await fileToBase64(doc).then((base64) => {
          return base64;
        });
        setFilesToBase64(convertFilesToBase64);
      } else if (doc instanceof Blob) {
        const convertBlobToBase64 = await blobToBase64(doc).then((base64) => {
          return base64;
        });
        setFilesToBase64(convertBlobToBase64);
      }
    });
  }
  return (
    <Box display="flex" direction="column" flex="grow" width={"100%"}>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"start"}
          paddingX={4}
          paddingTop={2}
          paddingBottom={1}
          minHeight={52}
        >
          {breakpoint !== "small" && (
            <>
              <Box display="flex" flex="grow" justifyContent="end" marginTop={2} gap={2}></Box>
            </>
          )}

          {strategy === "normal" ? customIcon : null}
        </Box>
      </Box>

      <Box display="flex" flex="grow" padding={2}>
        <Video
          objectFit="cover"
          backgroundColor="black"
          aspectRatio={alignAudioVideoPlayer.aspectRatio}
          captions={captions}
          controls
          playing={playing}
          volume={volume}
          onPlay={() => setPlaying(playing)}
          onPlayError={({ error }) => error && setPlaying(false)}
          onControlsPlay={() => setPlaying(!playing)}
          onControlsPause={() => setPlaying(!playing)}
          onEnded={() => setPlaying(false)}
          onVolumeChange={(e) => setVolume(e.volume)}
          loop
          src={filesToBase64}
        />
      </Box>
    </Box>
  );
}
