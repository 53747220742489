import { BoxWithRef, Grid, useColors } from "@prodoctivity/design-system";
import type {
  IndexEntityResume,
  OrganizationUser,
  PaginatedQueryResponsePart,
} from "@prodoctivity/types";
import { MutableRefObject, useRef } from "react";

import { ConditionalTableWrapper } from "./ConditionalTableWrapper";
import { SearchFilter } from "../../link-templates";
import { SearchResultEntities } from "./SearchResultEntities";

export type ParameterData = {
  resultFiltered: (IndexEntityResume & { $score: number })[] | undefined;
  organizationUsersData:
    | ({
        users: OrganizationUser[];
      } & PaginatedQueryResponsePart)
    | undefined;
  searchFilter: SearchFilter;
  displayDocumentsAsCards: boolean;
  breakpoint: "small" | "medium" | "large" | "hd";
  itemIndex?: number;
  gridWidth?: MutableRefObject<HTMLDivElement | null>;
};

export function SearchResultsList({
  resultFiltered,
  organizationUsersData,
  searchFilter,
  displayDocumentsAsCards,
  breakpoint,
  itemIndex,
}: ParameterData) {
  const { colors } = useColors();
  const cardsLayout = breakpoint === "small" || displayDocumentsAsCards;
  let noResultsLayout = false;
  if (resultFiltered && resultFiltered.length <= 0) {
    noResultsLayout = resultFiltered && resultFiltered.length <= 0;
  }
  const gridWidth = useRef<HTMLDivElement | null>(null);
  const displayGridViewCondition = cardsLayout && !noResultsLayout;
  const gridSizes = [
    ["repeat(auto-fill, minmax(230px, 1fr)"],
    ["repeat(auto-fill, minmax(400px, 1fr)"],
    ["repeat(auto-fill, minmax(600px, 1fr)"],
  ];

  return (
    <BoxWithRef
      ref={gridWidth}
      marginTop={4}
      color={cardsLayout ? undefined : colors.white}
      borderStyle={cardsLayout ? undefined : "sm"}
      borderRadius={cardsLayout ? undefined : 4}
    >
      <Grid
        gridTemplateColumns={displayGridViewCondition ? gridSizes[itemIndex ? itemIndex : 0] : [1]}
        gap={cardsLayout ? 4 : 0}
        justifyContent={cardsLayout ? "start" : undefined}
      >
        <ConditionalTableWrapper
          cardsLayout={cardsLayout}
          breakpoint={breakpoint}
          noResultsLayout={noResultsLayout}
        >
          <SearchResultEntities
            resultFiltered={resultFiltered}
            organizationUsersData={organizationUsersData}
            searchFilter={searchFilter}
            displayDocumentsAsCards={displayDocumentsAsCards}
            breakpoint={breakpoint}
            gridWidth={gridWidth}
            itemIndex={itemIndex}
          />
        </ConditionalTableWrapper>
      </Grid>
    </BoxWithRef>
  );
}
