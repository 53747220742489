import { Box, Text, useColors } from "@prodoctivity/design-system";
import React, { FC } from "react";

export const StatsContainer: FC<{
  children?: React.ReactNode;
  title: string | React.ReactNode;
  size?: {
    width: number | string;
    height?: number | undefined;
  };
}> = ({ title, size, children }) => {
  const { colors } = useColors();
  return (
    <Box
      id="stat-container"
      direction="column"
      display="flex"
      color={colors.lightWash}
      width={size ? size.width : "100%"}
      borderRadius={6}
      padding={1}
      borderStyle="raisedTopShadow"
    >
      <Box display="flex" direction="column">
        <Box
          display="flex"
          paddingY={3}
          alignItems="center"
          alignContent="center"
          paddingX={4}
          color={colors.white}
        >
          {typeof title === "string" ? (
            <Text weight="bold" color={colors.primary600}>
              {title}
            </Text>
          ) : (
            title
          )}
        </Box>
      </Box>
      <Box height={"100%"} width={"100%"}>
        {children && (
          <Box direction="row" display="flex" justifyContent="center" padding={2}>
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
};
