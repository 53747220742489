import { Box, Checkbox, Text, TextField, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FC, useCallback, useMemo, useState } from "react";

import { createUuid } from "@prodoctivity/shared";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { SignUpFieldsGroup } from "../../utils";

type Props = {
  updateInput(input: SignUpFieldsGroup): void;
  currentInput: SignUpFieldsGroup;
};

function getValidOrganizationId(value: string) {
  let tempValue = value.replace(/[^a-z0-9_\-]/gi, "").toLowerCase();
  while (tempValue.length && !/[a-z]/.test(tempValue[0])) {
    tempValue = tempValue.substring(1);
  }
  return tempValue;
}

const CompanySignUp: FC<Props> = ({ updateInput, currentInput }) => {
  const { organizationName, organizationId, termsAndPolicyCheck } = currentInput.company;
  const { breakpoint } = useDesignBreakpoint();
  const { resources } = useAppTranslation();
  const { organizationExists } = useServices();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const doesOrganizationExists = useCallback(
    async (orgId: string) => {
      if (orgId && orgId.length > 3) {
        const res = await organizationExists(orgId);
        if (res.exists === true) {
          setErrorMessage(resources.alreadyExists);
        } else if (res.exists === false) {
          setErrorMessage(undefined);
        }
      }
    },
    [organizationExists, resources.alreadyExists]
  );

  const updateOrganizationId = useCallback(
    async (e: { event: React.SyntheticEvent<HTMLInputElement>; value: string }) => {
      const { value } = e;
      updateInput({
        ...currentInput,
        company: { ...currentInput.company, organizationId: getValidOrganizationId(value) },
      });
      doesOrganizationExists(getValidOrganizationId(value));
    },
    [currentInput, doesOrganizationExists, updateInput]
  );

  const updateOrganizationName = useCallback(
    (e: { event: React.SyntheticEvent<HTMLInputElement>; value: string }) => {
      const newOrganizationId = getValidOrganizationId(e.value);
      doesOrganizationExists(newOrganizationId);
      updateInput({
        ...currentInput,
        company: {
          ...currentInput.company,
          organizationId: newOrganizationId,
          organizationName: e.value,
        },
      });
    },
    [currentInput, doesOrganizationExists, updateInput]
  );

  const componentId = useMemo(() => {
    return createUuid();
  }, []);
  return (
    <>
      {breakpoint !== "small" && breakpoint !== "medium" && (
        <Box maxWidth={400}>
          <Box marginBottom={4}>
            <Box marginBottom={2}>
              <Text weight="bold">{resources.landingPage.orgName}</Text>
            </Box>
            <TextField
              id={`${componentId}_organizationNameField`}
              value={organizationName}
              onChange={updateOrganizationName}
              placeholder={resources.landingPage.orgName}
              maxLength={{
                characterCount: 50,
                errorAccessibilityLabel: resources.maximumCharactersAllowed,
              }}
            />
          </Box>
          <Box marginBottom={4}>
            <Box marginBottom={2}>
              <Text weight="bold">{resources.landingPage.orgId}</Text>
            </Box>
            <TextField
              id={`${componentId}_organizationIdField`}
              value={organizationId}
              maxLength={{
                characterCount: 50,
                errorAccessibilityLabel: resources.maximumCharactersAllowed,
              }}
              onChange={updateOrganizationId}
              placeholder={resources.landingPage.orgId}
              errorMessage={errorMessage}
            />
          </Box>
          <Box marginBottom={4}>
            <Checkbox
              checked={termsAndPolicyCheck}
              onChange={({ checked }) =>
                updateInput({
                  ...currentInput,
                  company: { ...currentInput.company, termsAndPolicyCheck: checked },
                })
              }
              id="privacycheck"
              label={resources.iAcceptTheConditions}
            />
          </Box>
        </Box>
      )}
      {(breakpoint === "small" || breakpoint === "medium") && (
        <Box>
          <Box display="flex" justifyContent="between" direction="column">
            <Box paddingY={2}>
              <Box paddingY={2}>
                <Text weight="bold">{resources.landingPage.orgName}</Text>
              </Box>
              <TextField
                id={`${componentId}_organizationNameField`}
                value={organizationName}
                onChange={updateOrganizationName}
                placeholder={resources.landingPage.orgName}
              />
            </Box>
            <Box paddingY={2}>
              <Box paddingY={2}>
                <Text weight="bold">{resources.landingPage.orgId}</Text>
              </Box>
              <TextField
                id={`${componentId}_organizationIdField`}
                value={organizationId}
                onChange={updateOrganizationId}
                placeholder={resources.landingPage.orgId}
                errorMessage={errorMessage}
              />
            </Box>
            <Box paddingY={2}>
              <Checkbox
                checked={termsAndPolicyCheck}
                onChange={({ checked }) =>
                  updateInput({
                    ...currentInput,
                    company: { ...currentInput.company, termsAndPolicyCheck: checked },
                  })
                }
                id="privacycheck"
                label={resources.iAcceptTheConditions}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export { CompanySignUp };
