"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyCalculatedFields = void 0;
const evaluator_1 = require("./evaluator");
const fluency_shared_1 = require("./fluency-shared");
const utils_1 = require("./utils");
function applyCalculatedFields(holder, payload) {
    const evaluationResult = applyCalculatedFieldsToContext(holder, holder, payload, []);
    return evaluationResult.result;
}
exports.applyCalculatedFields = applyCalculatedFields;
const operatorImplementations = {
    Add(calculatedField, context, recordContextStack, evaluatedSet, visitor) {
        if (calculatedField.type !== "Composite") {
            throw new Error("Calculated field must be Composite at this point");
        }
        switch (calculatedField.dataType) {
            case "Numeric":
            case "Currency":
                const calDataType = calculatedField.dataType;
                return calculatedField.expressions
                    .map((exp) => evaluateCalculatedField(exp, context, recordContextStack, evaluatedSet, visitor))
                    .reduce((acc, next) => {
                    if (acc.type !== "Numeric" && acc.type !== "Currency") {
                        return {
                            type: calDataType,
                            value: [0],
                        };
                    }
                    switch (next.type) {
                        case "Numeric":
                        case "Currency": {
                            const [v] = Array.isArray(acc.value) ? acc.value : [acc.value];
                            const [nextVal] = Array.isArray(next.value) ? next.value : [next.value];
                            return {
                                type: acc.type,
                                value: [v + nextVal],
                            };
                        }
                        default:
                            return acc;
                    }
                }, { type: calDataType, value: 0 });
            default:
                throw new Error(`Operator Add not implemented for Data type "${calculatedField.dataType}"`);
        }
    },
    Divide(calculatedField, context, recordContextStack, evaluatedSet, visitor) {
        if (calculatedField.type !== "Composite") {
            throw new Error("Calculated field must be Composite at this point");
        }
        switch (calculatedField.dataType) {
            case "Numeric":
            case "Currency":
                const calDataType = calculatedField.dataType;
                if (!calculatedField.expressions.length) {
                    return {
                        type: calculatedField.dataType,
                        value: [],
                    };
                }
                const vals = calculatedField.expressions.map((exp) => evaluateCalculatedField(exp, context, recordContextStack, evaluatedSet, visitor));
                return vals.slice(1).reduce((acc, next) => {
                    if (acc.type !== "Numeric" && acc.type !== "Currency") {
                        return {
                            type: calDataType,
                            value: [],
                        };
                    }
                    if (Array.isArray(acc.value) && !acc.value.length) {
                        return {
                            type: calDataType,
                            value: [0],
                        };
                    }
                    switch (next.type) {
                        case "Numeric":
                        case "Currency": {
                            const [v] = Array.isArray(acc.value) ? acc.value : [acc.value];
                            const [nextVal] = Array.isArray(next.value) ? next.value : [next.value];
                            return {
                                type: acc.type,
                                value: [(Number.isNaN(v) ? 0 : v) / nextVal],
                            };
                        }
                        default:
                            return acc;
                    }
                }, vals[0]);
            default:
                throw new Error(`Operator Add not implemented for Data type "${calculatedField.dataType}"`);
        }
    },
    Modulus(calculatedField, context, recordContextStack, evaluatedSet, visitor) {
        if (calculatedField.type !== "Composite") {
            throw new Error("Calculated field must be Composite at this point");
        }
        switch (calculatedField.dataType) {
            case "Numeric":
            case "Currency":
                const calDataType = calculatedField.dataType;
                if (!calculatedField.expressions.length) {
                    return {
                        type: calculatedField.dataType,
                        value: [],
                    };
                }
                const vals = calculatedField.expressions.map((exp) => evaluateCalculatedField(exp, context, recordContextStack, evaluatedSet, visitor));
                return vals.slice(1).reduce((acc, next) => {
                    if (acc.type !== "Numeric" && acc.type !== "Currency") {
                        return {
                            type: calDataType,
                            value: [],
                        };
                    }
                    if (Array.isArray(acc.value) && !acc.value.length) {
                        return {
                            type: calDataType,
                            value: [],
                        };
                    }
                    switch (next.type) {
                        case "Numeric":
                        case "Currency": {
                            const [v] = Array.isArray(acc.value) ? acc.value : [acc.value];
                            const [nextVal] = Array.isArray(next.value) ? next.value : [next.value];
                            return {
                                type: acc.type,
                                value: [v * nextVal],
                            };
                        }
                        default:
                            return acc;
                    }
                }, vals[0]);
            default:
                throw new Error(`Operator Add not implemented for Data type "${calculatedField.dataType}"`);
        }
    },
    Multiply(calculatedField, context, recordContextStack, evaluatedSet, visitor) {
        if (calculatedField.type !== "Composite") {
            throw new Error("Calculated field must be Composite at this point");
        }
        switch (calculatedField.dataType) {
            case "Numeric":
            case "Currency":
                const calDataType = calculatedField.dataType;
                if (!calculatedField.expressions.length) {
                    return {
                        type: calculatedField.dataType,
                        value: [],
                    };
                }
                const vals = calculatedField.expressions.map((exp) => evaluateCalculatedField(exp, context, recordContextStack, evaluatedSet, visitor));
                return vals.slice(1).reduce((acc, next) => {
                    if (acc.type !== "Numeric" && acc.type !== "Currency") {
                        return {
                            type: calDataType,
                            value: [],
                        };
                    }
                    if (Array.isArray(acc.value) && !acc.value.length) {
                        return {
                            type: calDataType,
                            value: [],
                        };
                    }
                    switch (next.type) {
                        case "Numeric":
                        case "Currency": {
                            const [v] = Array.isArray(acc.value) ? acc.value : [acc.value];
                            const [nextVal] = Array.isArray(next.value) ? next.value : [next.value];
                            return {
                                type: acc.type,
                                value: [v * nextVal],
                            };
                        }
                        default:
                            return acc;
                    }
                }, vals[0]);
            default:
                throw new Error(`Operator Add not implemented for Data type "${calculatedField.dataType}"`);
        }
    },
    None(calculatedField, context, recordContextStack, evaluatedSet, visitor) {
        if (calculatedField.type !== "Composite") {
            throw new Error("Calculated field must be Composite at this point");
        }
        const first = calculatedField.expressions[0];
        if (!first) {
            switch (calculatedField.dataType) {
                case "Currency":
                case "Numeric": {
                    return {
                        type: calculatedField.dataType,
                        value: [],
                    };
                }
                case "Alphanumeric": {
                    return {
                        type: calculatedField.dataType,
                        value: [],
                    };
                }
                case "Date":
                case "DateTime":
                case "Time": {
                    return {
                        type: calculatedField.dataType,
                        value: [],
                    };
                }
                case "Image": {
                    return {
                        type: calculatedField.dataType,
                        value: "",
                    };
                }
                case "Logical": {
                    return {
                        type: calculatedField.dataType,
                        value: false,
                    };
                }
            }
        }
        return evaluateCalculatedField(first, context, recordContextStack, evaluatedSet, visitor);
    },
    Subtract(calculatedField, context, recordContextStack, evaluatedSet, visitor) {
        if (calculatedField.type !== "Composite") {
            throw new Error("Calculated field must be Composite at this point");
        }
        switch (calculatedField.dataType) {
            case "Numeric":
            case "Currency":
                const calDataType = calculatedField.dataType;
                if (!calculatedField.expressions.length) {
                    return {
                        type: calculatedField.dataType,
                        value: [],
                    };
                }
                const vals = calculatedField.expressions.map((exp) => evaluateCalculatedField(exp, context, recordContextStack, evaluatedSet, visitor));
                return vals.slice(1).reduce((acc, next) => {
                    if (acc.type !== "Numeric" && acc.type !== "Currency") {
                        return {
                            type: calDataType,
                            value: [],
                        };
                    }
                    if (Array.isArray(acc.value) && !acc.value.length) {
                        return {
                            type: calDataType,
                            value: [],
                        };
                    }
                    switch (next.type) {
                        case "Numeric":
                        case "Currency": {
                            const [v] = Array.isArray(acc.value) ? acc.value : [acc.value];
                            const [nextVal] = Array.isArray(next.value) ? next.value : [next.value];
                            return {
                                type: acc.type,
                                value: [v - nextVal],
                            };
                        }
                        default:
                            return acc;
                    }
                }, vals[0]);
            default:
                throw new Error(`Operator Add not implemented for Data type "${calculatedField.dataType}"`);
        }
    },
};
function applyCalculatedFieldsToContext(holder, rootHolder, payload, recordContextStack) {
    const newValues = (0, utils_1.deepCopy)(payload);
    let modified = false;
    const calculatedFields = (holder.fields || []).reduce((acc, next) => {
        const properties = next.properties;
        if ((0, fluency_shared_1.isCalculatedField)(properties)) {
            acc.push({
                fullPath: next.fullPath,
                properties,
            });
        }
        return acc;
    }, []);
    const evaluatedSet = new Set();
    function applySingleCalculatedField(fld) {
        const calculatedField = fld.properties.calculatedField;
        const fieldName = fld.fullPath.substring(fld.fullPath.lastIndexOf("/") + 1);
        const value = evaluateCalculatedField(calculatedField, newValues, recordContextStack, evaluatedSet, (visitingFullPath) => {
            const cf = (0, fluency_shared_1.getContextField)(rootHolder, visitingFullPath);
            if (cf && (0, fluency_shared_1.isCalculatedField)(cf.properties) && !evaluatedSet.has(cf.fullPath)) {
                const aPathIndex = visitingFullPath.lastIndexOf("/");
                const bPathIndex = fld.fullPath.lastIndexOf("/");
                if (aPathIndex === bPathIndex &&
                    visitingFullPath.substring(0, aPathIndex) === fld.fullPath.substring(0, bPathIndex)) {
                    applySingleCalculatedField({
                        fullPath: cf.fullPath,
                        properties: cf.properties,
                    });
                }
            }
        });
        switch (calculatedField.dataType) {
            case "Currency":
            case "Numeric": {
                const newVal = value.type === "Currency" || value.type === "Numeric"
                    ? calculatedField.type === "Composite"
                        ? Array.isArray(value.value)
                            ? value.value[0]
                            : value.value
                        : Array.isArray(value.value)
                            ? value.value
                            : [value.value]
                    : null;
                if ((0, utils_1.stableStringify)(newValues[fieldName]) !== (0, utils_1.stableStringify)(newVal)) {
                    newValues[fieldName] = newVal;
                    modified = true;
                }
                break;
            }
            default: {
                throw new Error(`Calculated fields for type "${calculatedField.dataType}" are not implemented`);
            }
        }
    }
    holder.records.forEach((record) => {
        const recordName = record.fullPath.substring(record.fullPath.lastIndexOf("/") + 1);
        const lst = newValues[recordName];
        if (Array.isArray(lst)) {
            lst.forEach((child, idx) => {
                if (typeof child !== "object") {
                    return;
                }
                const childApply = applyCalculatedFieldsToContext(record, rootHolder, child, [
                    ...recordContextStack,
                    {
                        name: recordName,
                        value: child,
                    },
                ]);
                if (childApply.modified) {
                    lst[idx] = childApply.result;
                    modified = true;
                }
            });
        }
    });
    calculatedFields.forEach((fld) => {
        applySingleCalculatedField(fld);
    });
    if (!modified) {
        return {
            modified: false,
            result: payload,
        };
    }
    return {
        modified: true,
        result: (0, utils_1.removeUndefinedKeys)(newValues, true),
    };
}
function evaluateCalculatedField(calculatedField, context, recordContextStack, evaluatedSet, visitor) {
    for (const dependentField of getDependentContextFields(calculatedField)) {
        if (!evaluatedSet.has(dependentField)) {
            evaluatedSet.add(dependentField);
            visitor(dependentField);
        }
    }
    const expressionType = calculatedField.type;
    switch (calculatedField.type) {
        case "FixedValue": {
            return getFixedValue(calculatedField);
        }
        case "ContextValue": {
            const _a = (0, evaluator_1.evaluateContextValue)(context, calculatedField.contextFullPath, recordContextStack), { valueAsString: _valueAsString } = _a, primitiveTypeVal = __rest(_a, ["valueAsString"]);
            const dType = calculatedField.dataType;
            switch (calculatedField.dataType) {
                case "Currency":
                case "Numeric": {
                    const values = [];
                    const typeVal = {
                        type: calculatedField.dataType,
                        value: values,
                    };
                    switch (primitiveTypeVal.type) {
                        case "number": {
                            values.push(primitiveTypeVal.value);
                            break;
                        }
                        case "array": {
                            primitiveTypeVal.value.forEach((v) => {
                                if (typeof v === "number") {
                                    values.push(v);
                                }
                            });
                            break;
                        }
                        case "undefined": {
                            break;
                        }
                        default:
                            throw new Error(`Unsupported evaluation of primitive type "${primitiveTypeVal.type}" on dataType "${calculatedField.dataType}"`);
                    }
                    return typeVal;
                }
                case "Alphanumeric": {
                    const values = [];
                    const typeVal = {
                        type: calculatedField.dataType,
                        value: values,
                    };
                    switch (primitiveTypeVal.type) {
                        case "number": {
                            values.push(`${primitiveTypeVal.value}`);
                            break;
                        }
                        case "array": {
                            primitiveTypeVal.value.forEach((v) => {
                                if (typeof v === "number") {
                                    values.push(`${v}`);
                                }
                                else if (typeof v === "string") {
                                    values.push(v);
                                }
                            });
                            break;
                        }
                        case "undefined": {
                            break;
                        }
                        default:
                            throw new Error(`Unsupported evaluation of primitive type "${primitiveTypeVal.type}" on dataType "${calculatedField.dataType}"`);
                    }
                    return typeVal;
                }
                case "Date":
                case "DateTime":
                case "Image":
                case "Time": {
                    throw new Error(`Unsupported evaluation of primitive type "${primitiveTypeVal.type}" on dataType "${calculatedField.dataType}"`);
                }
                case "Logical": {
                    switch (primitiveTypeVal.type) {
                        case "boolean": {
                            return {
                                type: calculatedField.dataType,
                                value: primitiveTypeVal.value,
                            };
                        }
                        case "undefined": {
                            return {
                                type: calculatedField.dataType,
                                value: false,
                            };
                        }
                        default:
                            throw new Error(`Unsupported evaluation of primitive type "${primitiveTypeVal.type}" on dataType "${calculatedField.dataType}"`);
                    }
                }
                default:
                    (0, utils_1.shouldNever)(calculatedField.dataType);
                    throw new Error(`Unsupported calculated field data type: ${dType}`);
            }
            break;
        }
        case "Composite": {
            const operatorFunc = operatorImplementations[calculatedField.operator];
            return getSummary(calculatedField.summary, calculatedField.dataType, operatorFunc(calculatedField, context, recordContextStack, evaluatedSet, visitor));
        }
        default:
            (0, utils_1.shouldNever)(calculatedField);
            throw new Error(`Expression type "${expressionType} " is not implemented`);
    }
}
function getRawDependentContextFields(calculatedField) {
    const result = [];
    switch (calculatedField.type) {
        case "ContextValue": {
            result.push(calculatedField.contextFullPath);
            break;
        }
        case "Composite": {
            (calculatedField.expressions || []).forEach((expression) => {
                getRawDependentContextFields(expression).forEach((f) => {
                    result.push(f);
                });
            });
            break;
        }
        case "FixedValue": {
            break;
        }
        default:
            (0, utils_1.shouldNever)(calculatedField);
    }
    return result;
}
function getDependentContextFields(calculatedField) {
    return (0, utils_1.getDistinct)(getRawDependentContextFields(calculatedField));
}
function getFixedValue(calculatedField) {
    switch (calculatedField.fixedValueType) {
        case "Currency": {
            const numeric = Number.parseFloat(calculatedField.fixedValue || "");
            return Number.isNaN(numeric)
                ? { type: "Currency", value: [] }
                : { type: "Currency", value: [numeric] };
        }
        case "Numeric": {
            const numeric = Number.parseInt(calculatedField.fixedValue || "", 10);
            return Number.isNaN(numeric)
                ? { type: "Numeric", value: [] }
                : { type: "Numeric", value: [numeric] };
        }
        default:
            throw new Error(`Fixed value for "${calculatedField.fixedValueType}" not implemented`);
    }
}
function getSummary(summary, dataType, typeVal) {
    switch (summary) {
        case "None": {
            switch (typeVal.type) {
                case "Currency":
                case "Numeric":
                case "Date":
                case "Time":
                case "DateTime":
                    return {
                        type: typeVal.type,
                        value: Array.isArray(typeVal.value) ? typeVal.value[0] : typeVal.value,
                    };
                case "Alphanumeric":
                    return {
                        type: typeVal.type,
                        value: Array.isArray(typeVal.value) ? typeVal.value[0] : typeVal.value,
                    };
                case "Image": {
                    return {
                        type: typeVal.type,
                        value: Array.isArray(typeVal.value) ? "" : typeVal.value,
                    };
                }
                case "Logical": {
                    return typeVal;
                }
                default:
                    throw new Error(`Error getting summary "${summary}"`);
            }
        }
        case "Sum": {
            if (dataType !== "Currency" && dataType !== "Numeric") {
                throw new Error(`Error getting summary "${summary}" with dataType "${dataType}"`);
            }
            switch (typeVal.type) {
                case "Currency":
                case "Numeric":
                    const values = Array.isArray(typeVal.value) ? typeVal.value : [typeVal.value];
                    return {
                        type: typeVal.type,
                        value: values.reduce((acc, next) => acc + next, 0),
                    };
                case "Date":
                case "Time":
                case "DateTime":
                case "Alphanumeric":
                case "Image":
                case "Logical": {
                    return {
                        type: dataType,
                        value: 0,
                    };
                }
                default:
                    throw new Error(`Error getting summary "${summary}"`);
            }
        }
        case "Average": {
            if (dataType !== "Currency" && dataType !== "Numeric") {
                throw new Error(`Error getting summary "${summary}" with dataType "${dataType}"`);
            }
            switch (typeVal.type) {
                case "Currency":
                case "Numeric":
                case "Date":
                case "Time":
                case "DateTime":
                    const values = Array.isArray(typeVal.value) ? typeVal.value : [typeVal.value];
                    if (!values.length) {
                        return {
                            type: dataType,
                            value: 0,
                        };
                    }
                    return {
                        type: typeVal.type,
                        value: values.reduce((acc, next) => acc + next / values.length, 0),
                    };
                case "Alphanumeric":
                case "Image":
                case "Logical": {
                    return {
                        type: dataType,
                        value: 0,
                    };
                }
                default:
                    throw new Error(`Error getting summary "${summary}"`);
            }
        }
        case "Count": {
            if (dataType !== "Currency" && dataType !== "Numeric") {
                throw new Error(`Error getting summary "${summary}" with dataType "${dataType}"`);
            }
            switch (typeVal.type) {
                case "Currency":
                case "Numeric":
                case "Date":
                case "Time":
                case "DateTime":
                    const values = Array.isArray(typeVal.value) ? typeVal.value : [typeVal.value];
                    return {
                        type: typeVal.type,
                        value: values.length,
                    };
                case "Alphanumeric": {
                    const values = Array.isArray(typeVal.value) ? typeVal.value : [typeVal.value];
                    return {
                        type: dataType,
                        value: values.length,
                    };
                }
                case "Image":
                case "Logical": {
                    return {
                        type: dataType,
                        value: 1,
                    };
                }
                default:
                    throw new Error(`Error getting summary "${summary}"`);
            }
        }
    }
}
