"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceActiveContent = exports.evaluateRecordInstanceList = exports.evaluateContextValue = exports.evaluateContextValueWithRecords = exports.extractNameAndIndex = void 0;
const core_1 = require("./core");
const utils_1 = require("./utils");
function extractNameAndIndex(part) {
    const match = part.match(/^([^[]+)(\[(\d+)\])?$/);
    if (match) {
        const variable = match[1];
        const index = match[3] ? parseInt(match[3], 10) : undefined;
        return { variable, index };
    }
    else {
        return null;
    }
}
exports.extractNameAndIndex = extractNameAndIndex;
function evaluateContextValueWithRecords(contextData, fullPath, recordContextStack) {
    const originalParts = fullPath.split("/");
    const parts = [];
    let data = contextData;
    let partLevel = 0;
    for (let cnt = 0; cnt < originalParts.length; cnt += 1) {
        const variableInfo = extractNameAndIndex(originalParts[cnt]);
        if (!variableInfo) {
            return {
                valueAsString: "",
                type: "undefined",
                value: undefined,
            };
        }
        if (recordContextStack.length > cnt && variableInfo.variable === recordContextStack[cnt].name) {
            partLevel += 1;
        }
        else {
            if (partLevel) {
                parts.push((0, utils_1.range)(recordContextStack.length - (partLevel - 1))
                    .map((_) => "#")
                    .join(""));
                partLevel = 0;
            }
            parts.push(originalParts[cnt]);
        }
    }
    for (let cnt = 0; cnt < parts.length; cnt += 1) {
        const part = parts[cnt];
        const variableInfo = extractNameAndIndex(part);
        if (!variableInfo) {
            return {
                valueAsString: "",
                type: "undefined",
                value: undefined,
            };
        }
        if (part.startsWith("#") && part.split("").every((ch) => ch === "#")) {
            const stackItem = recordContextStack[recordContextStack.length - part.length];
            if (!stackItem) {
                return {
                    valueAsString: "",
                    type: "undefined",
                    value: undefined,
                };
            }
            data = stackItem.value;
            continue;
        }
        if (data === undefined) {
            return {
                valueAsString: "",
                type: "undefined",
                value: undefined,
            };
        }
        const prop = data[variableInfo.variable];
        if (prop === null) {
            return {
                valueAsString: "",
                type: "undefined",
                value: undefined,
            };
        }
        if (typeof prop === "object") {
            if (Array.isArray(prop)) {
                if (variableInfo.index === undefined) {
                    if (cnt < parts.length - 1) {
                        const values = prop
                            .map((item) => {
                            if (typeof item === "number" || typeof item === "string") {
                                return {
                                    valueAsString: "",
                                    type: "undefined",
                                    value: undefined,
                                };
                            }
                            const value = evaluateContextValueWithRecords(contextData, fullPath, [
                                ...recordContextStack,
                                {
                                    name: variableInfo.variable,
                                    value: item,
                                },
                            ]);
                            return value.value;
                        })
                            .flat();
                        return {
                            valueAsString: `${values.map((val) => `${val}`).join(", ")}`,
                            type: "array",
                            value: values,
                        };
                    }
                    else {
                        const flattenedProp = prop.flat();
                        return {
                            valueAsString: `${flattenedProp}`,
                            type: "array",
                            value: flattenedProp,
                        };
                    }
                }
                const val = prop[variableInfo.index];
                if (val === undefined || val === null) {
                    return {
                        valueAsString: "",
                        type: "undefined",
                        value: undefined,
                    };
                }
                if (typeof val === "object") {
                    if (cnt + 1 === parts.length) {
                        if (val instanceof Date) {
                            return {
                                valueAsString: val.toISOString(),
                                type: "Date",
                                value: val,
                            };
                        }
                        else {
                            return {
                                valueAsString: `${val}`,
                                type: "object",
                                value: val,
                            };
                        }
                    }
                    else {
                        const p = core_1.ParametersObject$Schema.safeParse(val);
                        if (!p.success) {
                            data = undefined;
                        }
                        else {
                            data = p.data;
                        }
                    }
                }
                else {
                    if (cnt + 1 === parts.length) {
                        return toPrimitiveTypeAndValue(val);
                    }
                    else {
                        return {
                            valueAsString: "",
                            type: "undefined",
                            value: undefined,
                        };
                    }
                }
            }
            else {
                return {
                    valueAsString: `${prop}`,
                    type: "object",
                    value: prop,
                };
            }
        }
        else {
            if (cnt + 1 === parts.length) {
                if (prop === undefined) {
                    return {
                        valueAsString: "",
                        type: "undefined",
                        value: undefined,
                    };
                }
                return toPrimitiveTypeAndValue(prop);
            }
            else {
                return {
                    valueAsString: "",
                    type: "undefined",
                    value: undefined,
                };
            }
        }
    }
    if (typeof data === "object" && !Array.isArray(data)) {
        return {
            type: "record",
            value: data,
        };
    }
    return {
        valueAsString: "",
        type: "undefined",
        value: undefined,
    };
}
exports.evaluateContextValueWithRecords = evaluateContextValueWithRecords;
function evaluateContextValue(contextData, fullPath, recordContextStack) {
    const result = evaluateContextValueWithRecords(contextData, fullPath, recordContextStack);
    if (result.type === "record") {
        return {
            type: "undefined",
            value: undefined,
            valueAsString: "",
        };
    }
    return result;
}
exports.evaluateContextValue = evaluateContextValue;
function evaluateRecordInstanceList(parentInstanceFullPath, recordName, contextData) {
    if (typeof contextData !== "object" || Array.isArray(contextData)) {
        return undefined;
    }
    if (!parentInstanceFullPath) {
        const list = contextData[recordName];
        if (!Array.isArray(list)) {
            return undefined;
        }
        return list.reduce((acc, next) => {
            if (typeof next === "object") {
                acc.push(next);
            }
            return acc;
        }, []);
    }
    const [firstPart, ...rest] = parentInstanceFullPath.split("/");
    const extracted = extractNameAndIndex(firstPart);
    if (!extracted) {
        return undefined;
    }
    const { variable, index } = extracted;
    const list = contextData[variable];
    if (!Array.isArray(list)) {
        return undefined;
    }
    const item = list[index !== null && index !== void 0 ? index : 0];
    if (typeof item !== "object" || Array.isArray(item)) {
        return undefined;
    }
    return evaluateRecordInstanceList(rest.join("/"), recordName, item);
}
exports.evaluateRecordInstanceList = evaluateRecordInstanceList;
function toPrimitiveTypeAndValue(val) {
    switch (typeof val) {
        case "boolean": {
            return {
                valueAsString: `${val}`,
                type: "boolean",
                value: val,
            };
        }
        case "function": {
            return {
                valueAsString: `${val}`,
                type: "function",
                value: val,
            };
        }
        case "number": {
            return {
                valueAsString: `${val}`,
                type: "number",
                value: val,
            };
        }
        case "string": {
            return {
                valueAsString: val,
                type: "string",
                value: val,
            };
        }
        default: {
            return {
                valueAsString: "",
                type: "undefined",
                value: undefined,
            };
        }
    }
}
const activeContentTagRegex = /{{([^}|]+)(\|([^}]+))?}}/g;
function replaceActiveContent(data, recordContextStack, content, renderValue) {
    if (!content) {
        return content;
    }
    return content.replace(activeContentTagRegex, (substr, instanceFullPath, _, _args) => {
        const result = evaluateContextValue(data, instanceFullPath, recordContextStack);
        if (!renderValue) {
            return result.valueAsString;
        }
        return renderValue(result);
    });
}
exports.replaceActiveContent = replaceActiveContent;
