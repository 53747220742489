import "./ReadOnlyItem.css";

import { Box, Heading, Icon, Text, useColors } from "@prodoctivity/design-system";

import type {
  ContextFieldProperties,
  InputControlValueType,
} from "@prodoctivity/shared/src/index-types";
import type momentType from "moment";
import { useMemo } from "react";
import { formatValue } from "../Item";
import { Thumbnail } from "./Thumbnail";

type Props = {
  name: string;
  typeValue: InputControlValueType;
  editable: boolean;
  moment: typeof momentType;
  i18n: (v: string) => string;
  width: string | number | undefined;
  error: string | undefined;
  properties: ContextFieldProperties;
  onClick: () => void;
  onHover: () => void;
  onHoverOut: () => void;
};

export function ReadOnlyItem({
  name,
  typeValue,
  editable,
  moment,
  i18n,
  properties,
  width,
  error,
  onClick,
  onHover,
  onHoverOut,
}: Props) {
  const { colors } = useColors();

  const valueStr = useMemo(() => {
    const value = typeValue.value;

    if (Array.isArray(value)) {
      return value
        .map((itemValue) => {
          return formatValue(moment, i18n, properties, itemValue !== undefined ? itemValue : "");
        })
        .sort()
        .join(", ");
    } else {
      return formatValue(moment, i18n, properties, value !== undefined ? value : "");
    }
  }, [typeValue, i18n, moment, properties]);

  return (
    <Box width={width}>
      <Box flex="grow" display="flex" direction="column">
        <div
          className={`ReadOnlyItem ${editable ? "ReadOnlyItemHover" : ""}`}
          onMouseOver={onHover}
          onMouseOut={onHoverOut}
          onClick={onClick}
        >
          <Box display="flex" direction="column" gap={4}>
            <Box>
              <Heading size="400" color={colors.black900}>
                {name}:
              </Heading>
            </Box>
            <div>
              {typeValue.dataType === "Image" && valueStr ? (
                <Thumbnail image={valueStr} fieldName={name} />
              ) : (
                <Text title={valueStr} overflow="breakWord">
                  {valueStr}
                </Text>
              )}
            </div>
          </Box>
          {/* <div style={{ display: "flex" }}>
        <strong>{name}:&nbsp; </strong>
        {dataType === "image" && valueStr ? <Thumbnail image={valueStr} /> : valueStr}
      </div> */}

          {editable && (
            <Icon
              icon="edit-note"
              color={colors.neutral700}
              hoverColor={colors.primary}
              accessibilityLabel={i18n("edit")}
            />
          )}
        </div>
        {error && (
          <Text color={colors.error} wrap={true}>
            {error}
          </Text>
        )}
      </Box>
    </Box>
  );
}
