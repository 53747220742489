import { FC } from "react";
type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};
export const MissingScannerSvg: FC<Props> = ({
  customClassName,
  width = 417,
  height = 238,
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 692 395"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10342_7917)">
        <path
          d="M692 327.244C692 146.532 545.275 0 364.324 0C183.374 0 36.6487 146.532 36.6487 327.244H692Z"
          fill="#EDEDED"
        />
        <path d="M521.953 214.071H247.671V274.136H521.953V214.071Z" fill="#9E9E9E" />
        <path d="M357.877 163.859L370.166 214.072H247.671L259.96 163.859H357.877Z" fill="#B2B2B2" />
        <path
          d="M348.055 170.989L354.762 203.009L259.051 202.75L266.277 170.989H348.055Z"
          fill="#606060"
        />
        <path
          d="M517.324 147.336L364.323 210.472L367.281 217.62L520.281 154.485L517.324 147.336Z"
          fill="#B2B2B2"
        />
        <path
          d="M521.953 285.501V327.244H247.671V285.501H345.978V301.619H419.406V285.501H521.953Z"
          fill="#B2B2B2"
        />
        <path
          d="M521.953 336.794V378.537H247.671V336.794H348.099V352.912H421.526V336.794H521.953Z"
          fill="#B2B2B2"
        />
        <path d="M421.526 336.404H348.099V336.793H421.526V336.404Z" fill="white" />
        <path d="M345.978 285.501V285.155H419.406V285.501" fill="white" />
        <path d="M295.354 378.536H247.671V395H295.354V378.536Z" fill="#B2B2B2" />
        <path d="M521.953 378.536H474.271V395H521.953V378.536Z" fill="#B2B2B2" />
        <path
          d="M392.969 129.16H383.925L358.137 146.358C356.839 147.223 355.195 145.84 355.801 144.414L362.464 129.16H359.695C335.68 129.16 316.253 109.715 316.253 85.7754C316.253 61.7928 335.724 42.3906 359.695 42.3906H392.925C416.939 42.3906 436.367 61.836 436.367 85.7754C436.367 109.758 416.896 129.16 392.925 129.16H392.969Z"
          fill="#B2B2B2"
        />
        <path
          d="M385.44 98.134L385.31 96.492C384.877 93.035 386.175 89.2756 389.81 85.473C393.098 82.1024 394.915 79.5961 394.915 76.7442C394.915 73.5033 392.579 71.3427 387.992 71.2562C385.353 71.2562 382.454 72.0341 380.637 73.244L378.906 69.2253C381.329 67.7129 385.44 66.6758 389.334 66.6758C397.728 66.6758 401.492 71.213 401.492 76.0528C401.492 80.374 398.723 83.4852 395.218 87.115C392.016 90.4423 390.848 93.2079 391.065 96.492L391.151 98.134H385.483H385.44ZM383.839 107.036C383.839 104.702 385.656 103.017 388.209 103.017C390.762 103.017 392.492 104.659 392.492 107.036C392.492 109.283 390.805 110.968 388.122 110.968C385.569 110.968 383.839 109.24 383.839 107.036Z"
          fill="white"
        />
        <path
          d="M366.358 75.5773L366.488 77.2194C366.92 80.6763 365.622 84.4358 361.988 88.2384C358.699 91.609 356.882 94.1152 356.882 96.9672C356.882 100.208 359.219 102.369 363.805 102.455C366.444 102.455 369.344 101.677 371.161 100.467L372.892 104.486C370.469 105.999 366.358 107.036 362.464 107.036C354.07 107.036 350.305 102.498 350.305 97.6586C350.305 93.3374 353.074 90.2262 356.579 86.5964C359.781 83.2691 360.949 80.5035 360.733 77.2194L360.646 75.5773H366.315H366.358ZM367.959 66.6325C367.959 68.9659 366.142 70.6512 363.589 70.6512C361.036 70.6512 359.305 69.0091 359.305 66.6325C359.305 64.3855 360.993 62.7002 363.675 62.7002C366.228 62.7002 367.959 64.4287 367.959 66.6325Z"
          fill="white"
        />
        <path
          d="M183.244 86.6827C182.725 86.6827 182.205 86.7692 181.729 86.8556C178.83 77.4354 170.133 70.5215 159.749 70.5215C147.85 70.5215 138.158 79.5528 136.903 91.1336C136.816 91.1336 136.73 91.1336 136.643 91.1336C131.494 91.1336 127.167 94.4609 125.61 99.0846C125.004 98.9117 124.398 98.7821 123.749 98.7821C120.288 98.7821 117.389 101.159 116.566 104.4H196.571C196.917 103.19 197.133 101.98 197.133 100.683C197.133 93.0349 190.902 86.8124 183.244 86.8124V86.6827Z"
          fill="#C6C6C6"
        />
        <path
          d="M628.178 158.112C628.178 157.896 628.308 157.68 628.308 157.421C628.308 154.699 626.101 152.538 623.419 152.538C623.202 152.538 622.986 152.624 622.77 152.668C621.948 149.254 618.876 146.661 615.154 146.661C612.472 146.661 610.092 148.001 608.707 150.075C608.058 149.859 607.366 149.686 606.587 149.686C603.645 149.686 601.135 151.717 600.486 154.482C600.4 154.482 600.356 154.482 600.27 154.482C598.366 154.482 596.808 156.038 596.808 157.939C596.808 158.026 596.808 158.069 596.808 158.155H628.135L628.178 158.112Z"
          fill="#C6C6C6"
        />
        <path
          d="M166.412 264.284C162.302 264.284 158.97 267.611 158.97 271.716V283.254C158.624 283.384 158.061 283.556 157.326 283.556H150.836V258.796C150.836 248.425 142.441 240.042 132.057 240.042C121.672 240.042 113.278 248.425 113.278 258.796V282.39H110.422C108.043 282.39 106.095 280.445 106.095 278.068V268.778C106.095 264.673 102.764 261.345 98.6532 261.345C94.5426 261.345 91.2109 264.673 91.2109 268.778V278.068C91.2109 288.655 99.8215 297.255 110.422 297.255H113.278V313.718C113.278 314.064 113.321 346.386 113.365 346.732H150.792C150.792 346.386 150.879 314.064 150.879 313.718V298.378H157.369C166.629 298.378 173.898 292.242 173.898 284.421V271.716C173.898 267.611 170.566 264.284 166.456 264.284H166.412Z"
          fill="#C6C6C6"
        />
        <path
          d="M605.116 312.897H604.986V301.792C604.986 297.168 601.222 293.365 596.549 293.365C591.876 293.365 588.111 297.125 588.111 301.792V312.378H586.813C585.731 312.378 584.866 311.514 584.866 310.434V306.286C584.866 304.427 583.395 302.958 581.534 302.958C579.674 302.958 578.203 304.427 578.203 306.286V310.434C578.203 315.187 582.054 319.033 586.813 319.033H588.111V326.422C588.111 326.422 588.111 341.071 588.111 341.201H604.9C604.9 341.201 604.9 326.552 604.9 326.422V319.552H605.029V312.897H605.116Z"
          fill="#C6C6C6"
        />
        <path d="M535.064 214.071H230.277V244.104H535.064V214.071Z" fill="#B2B2B2" />
        <path
          d="M254.075 234.986C257.612 234.986 260.479 232.123 260.479 228.591C260.479 225.059 257.612 222.195 254.075 222.195C250.538 222.195 247.671 225.059 247.671 228.591C247.671 232.123 250.538 234.986 254.075 234.986Z"
          fill="#EDEDED"
        />
        <path
          d="M276.964 234.986C280.501 234.986 283.368 232.123 283.368 228.591C283.368 225.059 280.501 222.195 276.964 222.195C273.428 222.195 270.561 225.059 270.561 228.591C270.561 232.123 273.428 234.986 276.964 234.986Z"
          fill="#EDEDED"
        />
        <path
          d="M498.112 123.37C511.279 123.37 521.953 112.71 521.953 99.5598C521.953 86.41 511.279 75.75 498.112 75.75C484.945 75.75 474.271 86.41 474.271 99.5598C474.271 112.71 484.945 123.37 498.112 123.37Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10342_7917">
          <rect width="692" height="395" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
