import { FunctionComponent } from "react";
import { ProfileDocumentLabelsList } from "./ProfileDocumentsLabelsList";
import { useDocumentLabelsList } from "../../hook";

export const DocumentListWidget: FunctionComponent = () => {
  const {
    profileDocumentLabelsList,
    selectedLabels,
    handleSelectedLabels,
    isLoadingProfileDocumentLabels,
    handleUpdateProfileLabels,
    handleDeleteLabel,
    profileDocumentLabels,
    handleSelectSingle,
  } = useDocumentLabelsList(undefined);
  return (
    <ProfileDocumentLabelsList
      profileDocumentLabelsList={profileDocumentLabelsList}
      selectedLabels={selectedLabels}
      handleSelectedLabels={handleSelectedLabels}
      isLoadingProfileDocumentLabels={isLoadingProfileDocumentLabels}
      handleUpdateProfileLabels={handleUpdateProfileLabels}
      handleDeleteLabel={handleDeleteLabel}
      isPopoverWindows={false}
      profileDocumentLists={profileDocumentLabels}
      onSelectSingle={handleSelectSingle}
      isOnlyShowingNotSelected={false}
      isWidget={true}
    />
  );
};
