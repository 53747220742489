import { DatePicker, DatePickerProps } from "./DatePicker";

export type DateFieldProps = DatePickerProps & {
  onClearInput?(): void;
};

export function DateField(props: DateFieldProps) {
  const { onClearInput: _onClearInput, ...rest } = props;
  return <DatePicker {...rest} />;
}
