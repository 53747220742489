import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DocumentIconsSvg: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 39,
  height = 38,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 39 38`}
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_3328_36200)">
        <path
          d="M19.5 0.5C29.7173 0.5 38 8.78273 38 19C38 29.2173 29.7173 37.5 19.5 37.5C9.28273 37.5 1 29.2173 1 19C1 8.78273 9.28273 0.5 19.5 0.5Z"
          fill="white"
          stroke="#DFE0E1"
        />
        <g clipPath="url(#clip1_3328_36200)">
          <path
            d="M28.3183 16.006C26.9045 15.941 25.5053 16.3158 24.3133 17.0787C23.1212 17.8416 22.1948 18.9552 21.6617 20.2662C20.2834 23.5172 21.1684 26.3434 23.8324 28.6916C23.6904 28.7005 23.5864 28.7132 23.4824 28.7132H11.1644C10.7964 28.7452 10.4258 28.6963 10.0786 28.5701C9.73142 28.4439 9.41599 28.2433 9.15441 27.9824C8.89282 27.7216 8.69139 27.4067 8.5642 27.0599C8.43702 26.713 8.38715 26.3426 8.41809 25.9745C8.41809 23.1529 8.41809 20.3309 8.41809 17.5085C8.39555 17.1511 8.44867 16.7931 8.57397 16.4577C8.69927 16.1223 8.89394 15.8171 9.14524 15.5621C9.39655 15.3071 9.69885 15.108 10.0324 14.9777C10.3659 14.8475 10.7231 14.7892 11.0808 14.8065C16.0612 14.7989 21.0416 14.7989 26.0221 14.8065C26.4755 14.7882 26.9257 14.8892 27.3278 15.0995C27.7298 15.3097 28.0697 15.6219 28.3133 16.0047L28.3183 16.006Z"
            fill={color ? calculateColor(color) : "#F6C720"}
          />
          <path
            d="M33.7499 23.0671C33.7438 24.1897 33.405 25.2852 32.7763 26.2152C32.1476 27.1452 31.2572 27.8678 30.2177 28.2917C29.1783 28.7156 28.0365 28.8217 26.9367 28.5966C25.8369 28.3715 24.8286 27.8253 24.0393 27.0272C23.25 26.229 22.7151 25.2146 22.5023 24.1124C22.2895 23.0102 22.4084 21.8696 22.8439 20.835C23.2793 19.8003 24.0119 18.9181 24.9488 18.2998C25.8858 17.6815 26.985 17.3549 28.1076 17.3614C28.8533 17.3636 29.5912 17.5131 30.2789 17.8014C30.9666 18.0896 31.5906 18.511 32.1149 19.0412C32.6393 19.5715 33.0536 20.2001 33.3342 20.891C33.6148 21.5819 33.7561 22.3215 33.7499 23.0671V23.0671ZM28.0847 24.9348C27.6194 24.9348 27.1554 24.9221 26.69 24.9348C26.3501 24.9367 26.0179 25.0356 25.7322 25.2197C25.4465 25.4039 25.2193 25.6657 25.0772 25.9745C25.0497 26.0146 25.0307 26.0599 25.0213 26.1076C25.0119 26.1553 25.0124 26.2044 25.0228 26.2519C25.0331 26.2994 25.053 26.3443 25.0814 26.3838C25.1097 26.4234 25.1458 26.4567 25.1875 26.4817C26.0093 27.1429 27.0337 27.5007 28.0885 27.495C29.1432 27.4892 30.1637 27.1202 30.9782 26.45C31.0302 26.3872 31.0664 26.3129 31.0836 26.2332C31.1008 26.1535 31.0986 26.0709 31.0771 25.9923C30.9448 25.6864 30.7278 25.4248 30.4517 25.2384C30.1756 25.0519 29.8519 24.9483 29.5188 24.9399C29.0408 24.9158 28.5615 24.9399 28.0835 24.9399L28.0847 24.9348ZM30.0031 21.7992C30.008 21.287 29.8092 20.7939 29.4505 20.4283C29.0918 20.0627 28.6026 19.8546 28.0904 19.8497C27.5783 19.8449 27.0852 20.0436 26.7196 20.4023C26.354 20.761 26.1459 21.2503 26.141 21.7624C26.1472 22.2753 26.3533 22.7654 26.7153 23.1287C27.0774 23.4919 27.5669 23.6995 28.0797 23.7074C28.5857 23.7016 29.0696 23.499 29.4289 23.1426C29.7881 22.7862 29.9945 22.3039 30.0044 21.7979L30.0031 21.7992Z"
            fill={color ? calculateColor(color) : "#F6C720"}
          />
          <path
            d="M18.5679 11.6357H25.3386C25.5503 11.6322 25.762 11.6411 25.9726 11.6623C26.1778 11.6836 26.3692 11.7758 26.5139 11.923C26.6585 12.0702 26.7473 12.2631 26.7651 12.4687C26.7844 12.6748 26.7318 12.8812 26.6163 13.0529C26.5007 13.2246 26.3293 13.3511 26.1311 13.4108C25.9707 13.4617 25.8036 13.4886 25.6353 13.4907C20.9093 13.4949 16.1837 13.4949 11.4586 13.4907C10.5926 13.4907 10.1285 12.8998 10.4125 12.1885C10.47 12.0273 10.5773 11.8886 10.7188 11.7924C10.8604 11.6962 11.0289 11.6475 11.1999 11.6534C11.9467 11.6369 12.6948 11.6344 13.4429 11.6331C15.1521 11.6323 16.8604 11.6331 18.5679 11.6357Z"
            fill={color ? calculateColor(color) : "#F6C720"}
          />
          <path
            d="M18.5225 10.3047C16.8869 10.3047 15.2517 10.3047 13.6169 10.3047C13.4051 10.3104 13.1931 10.2998 12.9829 10.273C12.7849 10.2481 12.6026 10.1521 12.4701 10.0028C12.3376 9.85349 12.2639 9.66113 12.2627 9.46154C12.2296 9.25719 12.2735 9.04782 12.386 8.874C12.4984 8.70018 12.6714 8.57429 12.8713 8.52073C13.0466 8.47093 13.228 8.44618 13.4102 8.44719C16.8336 8.44719 20.257 8.43578 23.6805 8.4586C24.0147 8.46626 24.3386 8.57599 24.6086 8.77305C24.7391 8.89555 24.8262 9.05714 24.8568 9.23346C24.8875 9.40978 24.86 9.59129 24.7785 9.75063C24.725 9.90635 24.6235 10.0411 24.4887 10.1357C24.3538 10.2302 24.1925 10.2796 24.0279 10.2768C23.2823 10.2996 22.5343 10.3009 21.7874 10.3022C20.7021 10.3022 19.6167 10.3022 18.5314 10.3022L18.5225 10.3047Z"
            fill={color ? calculateColor(color) : "#F6C720"}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3328_36200">
          <rect width="38" height="38" fill="white" transform="translate(0.5)" />
        </clipPath>
        <clipPath id="clip1_3328_36200">
          <rect
            width="25.3333"
            height="20.2705"
            fill="white"
            transform="translate(8.41699 8.44434)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
