// @flow

import { Box, ComboBoxTags, Text, TextField } from "@prodoctivity/design-system";
import { ExpressionControl, resourcesAdapters } from "@prodoctivity/fluency-components";
import { FunctionComponent } from "react";
import { ErrorBoundary } from "../../ErrorBoundary";
import { CustomAccordion } from "../CustomAccordion";
import { DependencyRowProps, useDependenciesRow } from "./hooks";

const DependenciesRowComponent: FunctionComponent<DependencyRowProps> = (props) => {
  const {
    resources,
    expression,
    expressionDataInput,
    optionsForComboBoxTags,
    selectedDataElement,
    transferDataElement,
    getConditionsContextualIf,
    onExpressionChange,
    onTextInputChange,
    state,
    i18n,
    colors,
  } = useDependenciesRow(props);

  return (
    <ErrorBoundary>
      <Box>
        <Box>
          <Box display="flex" direction="column" gap={2}>
            <Text>{i18n("conditionBlockName")}</Text>
            <Box width={"100%"}>
              <TextField
                id={expression.toString(i18n, resourcesAdapters, new Map<string, string>())}
                value={state.dependencyName ?? ""}
                onChange={({ value }) => {
                  onTextInputChange(value);
                }}
              ></TextField>
            </Box>
          </Box>
        </Box>
        <Box marginTop={6} marginBottom={6} borderRadius={6} borderColor={colors.neutral900}>
          <CustomAccordion
            removeLabel={"remove"}
            currIndex={props.index}
            i18n={i18n}
            collapsedColor={colors.primaryHover0}
            openedColor={colors.white}
            canDeleteRow={false}
            label={
              <Box>
                <Text>
                  <strong>{i18n("filterBuilder.condition")} </strong>
                </Text>
              </Box>
            }
          >
            <ErrorBoundary>
              <ExpressionControl
                expression={expressionDataInput}
                contextDefinition={props.contextDefinition}
                contextualIf={getConditionsContextualIf()}
                onExpressionChanged={onExpressionChange}
                moment={props.moment}
                name="expression"
                resources={resources}
                translate={i18n}
              />
              <Box
                display="flex"
                width={"100%"}
                minHeight={56}
                alignItems="center"
                padding={4}
                color={colors.primary}
                borderColor={colors.neutral50}
                borderRadius={6}
              >
                <Text color={colors.white}>{state.conditionText}</Text>
              </Box>
            </ErrorBoundary>
          </CustomAccordion>
        </Box>
        <Box>
          <Box>
            <ErrorBoundary>
              <Box display="flex" direction="column" gap={2} marginBottom={4}>
                <Text>{i18n("filterBuilder.dependencySelection")}</Text>
                <Box>
                  <ComboBoxTags
                    id={`dependencies_form_elements`}
                    placeholder={"form elements to select"}
                    label=""
                    noResultText="There's no more"
                    accessibilityClearButtonLabel={i18n("clear")}
                    options={optionsForComboBoxTags}
                    selected={selectedDataElement}
                    onSelect={transferDataElement}
                    size="lg"
                    errorMessage={selectedDataElement.length <= 0 ? i18n("selectOne") : ""}
                  />
                </Box>
              </Box>
            </ErrorBoundary>
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export const DependenciesRow = DependenciesRowComponent;
