// @flow

import type {
  FormDefinition,
  FormLayout,
  Group,
  ProDoctivityFormLayout,
  ProDoctivityFormLayoutItem,
} from "../_lib/types";

import { deepCopy } from "@prodoctivity/shared";
import type { ContextField, ControlSize } from "@prodoctivity/shared/src/index-types";

type OrganizedByTopics = {
  [topicName: string]: FormDefinition;
};

/**
 * Places each element of the `formDefinition` in an object
 * where the key is the name of the topic where it belongs.
 *
 * For groups, the topic where it belongs is the topic name
 * that the majority of its data elements have.
 *
 * See the tests of this function for an example input and output.
 *
 * @param {FormDefinition} formDefinition
 * @returns {OrganizedByTopics}
 */
export function organizeByTopics(formDefinition: FormDefinition): OrganizedByTopics {
  const topicsWithDataElements = formDefinition.contextFields.reduce(
    (prev: OrganizedByTopics, curr) => {
      // I set "General" here because we will most likely not use this
      if (!prev["General"]) {
        prev["General"] = { contextFields: [], groups: [], allowedListValues: [] };
      }

      prev["General"].contextFields.push(curr);

      return prev;
    },
    {}
  );

  const organizedByTopics = formDefinition.groups.reduce((prev: OrganizedByTopics, curr: Group) => {
    const mostFrequentTopic = getMostFrequentTopicName(curr.fields);

    if (!prev[mostFrequentTopic]) {
      prev[mostFrequentTopic] = { contextFields: [], groups: [], allowedListValues: [] };
    }

    prev[mostFrequentTopic].groups.push(curr);

    return prev;
  }, topicsWithDataElements);

  return organizedByTopics;
}

function getMostFrequentTopicName(_dataElements: Array<ContextField>): string {
  // const compare = (a: DataElement, b: DataElement) => a.sectionName === b.sectionName;

  // const mostFrequent = dataElements
  //   .sort(
  //     (x, y) =>
  //       dataElements.filter((e) => compare(e, x)).length -
  //       dataElements.filter((e) => compare(e, y)).length
  //   )
  //   .pop();

  return "General";
}

type CreateLayoutItemArg = {
  parentFullPath: string | undefined;
  name: string;
  label: string;
  y: number;
};

export function createTopicLayoutItem({
  name,
  y,
}: CreateLayoutItemArg): ProDoctivityFormLayoutItem {
  return {
    i: "topic__" + name,
    x: 0,
    y,
    w: 12,
    h: 1,
    isDraggable: true,
    isResizable: false,
    name,
    type: "topic",
  };
}
function controlSizeWidth(controlSize: ControlSize) {
  switch (controlSize) {
    case "Small":
      return 4;
    case "Normal":
      return 8;
    case "Large":
      return 12;
    case "None":
    default:
      return 8;
  }
}

export function createDataElementLayoutItem({
  parentInstanceFullPath,
  contextField,
  y,
  controlSize,
}: {
  parentInstanceFullPath: string | undefined;
  contextField: ContextField;
  controlSize: ControlSize;
  y: number;
}): ProDoctivityFormLayoutItem {
  const widthItem = controlSizeWidth(controlSize);

  let heightItem = 3;

  if (contextField && contextField.properties.dataType === "Image") {
    if (contextField.properties.inputType === "Signature") {
      heightItem = 7;
    }
  }

  return {
    i: "dataElement__" + contextField.name,
    x: 0,
    y,
    w: widthItem,
    h: heightItem,
    minW: contextField.properties.dataType === "DateTime" ? 3 : 2,
    minH: 2,
    name: contextField.name,
    isDraggable: true,
    type: "dataElement",
    dataElement: contextField,
    fullPath: `${parentInstanceFullPath ? parentInstanceFullPath + "/" : ""}${contextField.name}`,
  };
}

export function createGroupLayoutItem({
  parentFullPath,
  name,
  label,
  y,
}: CreateLayoutItemArg): ProDoctivityFormLayoutItem {
  return {
    i: "group__" + name,
    x: 0,
    y: y++,
    w: 12,
    h: 6,
    minW: 12,
    minH: 6,
    name,
    label,
    type: "group",
    isDraggable: false,
    groupTableMode: true,
    fullPath: `${parentFullPath ? parentFullPath + "/" : ""}${name}`,
  };
}

export function buildLayout(formDefinition: FormDefinition): {
  layout: ProDoctivityFormLayout;
  layoutTopics: Set<string>;
} {
  const topicsToItems: OrganizedByTopics = organizeByTopics(formDefinition);

  const layout: ProDoctivityFormLayout = [];

  let y = 0;
  Object.entries(topicsToItems).forEach(
    ([topicName, _formDefinition]: [string, FormDefinition]) => {
      // See https://github.com/facebook/flow/issues/2174#issuecomment-338187182
      // flowlint-next-line unclear-type:off
      const formDefinition: FormDefinition = _formDefinition;

      if (formDefinition.contextFields.length > 0) {
        // Topics
        const topicLayoutItem = createTopicLayoutItem({
          parentFullPath: undefined,
          name: topicName,
          label: topicName,
          y: y++,
        });
        layout.push(topicLayoutItem);

        let x = 0;
        // Data Elements
        formDefinition.contextFields.forEach((field) => {
          const dataElementLayoutItem = createDataElementLayoutItem({
            parentInstanceFullPath: undefined,
            contextField: field,
            y: y++,
            controlSize: field.properties.controlSize ?? "Normal",
          });
          dataElementLayoutItem.x = x;
          x = x === 0 ? 4 : 0;
          layout.push(dataElementLayoutItem);
        });
      }
      // Groups
      formDefinition.groups.forEach((group) => {
        const groupLayoutItem = createGroupLayoutItem({
          parentFullPath: undefined,
          name: group.groupName,
          label: group.question,
          y: y++,
        });
        layout.push(groupLayoutItem);
      });
    }
  );

  const layoutTopics: Set<string> = new Set(Object.keys(topicsToItems));

  return { layout, layoutTopics };
}

export function buildGroupLayout(
  parentFullPath: string,
  fields: Array<ContextField>
): ProDoctivityFormLayout {
  return fields.map((fld, i) => {
    return {
      i: "dataElement__" + fld.name,
      x: (4 * i) % 12,
      y: i,
      w: fld.properties.dataType === "DateTime" ? 5 : 4,
      h: 3,
      minW: fld.properties.dataType === "DateTime" ? 3 : 2,
      minH: 3,
      name: fld.name,
      type: "dataElement",
      dataElement: fld,
      fullPath: `${parentFullPath ? parentFullPath + "/" : ""}${fld.name}`,
    };
  });
}

export function chosenQuestionUpdater({
  name,
  alternativeQuestion,
}: {
  name: string;
  alternativeQuestion: string;
}): ({ layout }: { layout: ProDoctivityFormLayout }) => { layout: ProDoctivityFormLayout } {
  return ({ layout: prevLayout }: { layout: ProDoctivityFormLayout }) => {
    const nextLayout: ProDoctivityFormLayout = prevLayout.map((l) => {
      if (l.type === "dataElement" && l.name === name) {
        return { ...l, chosenQuestion: alternativeQuestion };
      }
      return l;
    });

    return {
      layout: nextLayout,
    };
  };
}

export function updateFormLayoutWithFormDefinition(
  _formLayout: FormLayout,
  _formDefinition: FormDefinition
): { formLayout: FormLayout; layoutTopicNames: Set<string> } {
  if (!_formLayout) return _formLayout;

  if (
    _formLayout.layout &&
    Array.isArray(_formLayout.layout)
    //&& _formLayout.layout.length > 0
  ) {
    const tempLayout: ProDoctivityFormLayout = JSON.parse(JSON.stringify(_formLayout.layout));
    // eliminate groups removed from Layout
    tempLayout.forEach((element) => {
      if (element.type === "group") {
        const elementsToRemove = _formDefinition.groups.filter(
          (group) => group.groupName === element.name
        );
        if (elementsToRemove.length === 0) {
          _formLayout.layout = _formLayout.layout.filter((item) => item.i !== element.i);
        }
      }
    });

    _formLayout.layout = updateLayoutWithDataElements(
      _formLayout.layout,
      _formDefinition.contextFields,
      undefined
    );
  }

  if (Array.isArray(_formDefinition.groups) && _formDefinition.groups.length > 0) {
    if (!_formLayout.groupLayouts) _formLayout.groupLayouts = {};

    _formDefinition.groups.forEach((group) => {
      const newGroupLayout = updateLayoutWithDataElements(
        _formLayout.groupLayouts[group.groupName],
        group.fields,
        group.fullPath
      );

      const lastItemLayout = _formLayout.layout[_formLayout.layout.length - 1];
      const itemLayout = createGroupLayoutItem({
        parentFullPath: undefined,
        name: group.groupName,
        label: group.question,
        y: lastItemLayout ? lastItemLayout.y : 0,
      });
      if (!_formLayout.layout.find((item) => item.i === itemLayout.i)) {
        //Add in layout if group is new
        _formLayout.layout.push(itemLayout);
      }

      _formLayout.groupLayouts[group.groupName] = newGroupLayout;
    });
  }

  const layoutTopicNames: Set<string> = new Set(
    _formLayout.layout
      .filter((elementsType) => elementsType && elementsType.type === "topic")
      .reduce((acc: string[], next) => {
        if (next.type === "topic") {
          acc.push(next.name);
        }

        return acc;
      }, [])
  );

  return { formLayout: _formLayout, layoutTopicNames };
}

export function updateLayoutWithDataElements(
  layout: ProDoctivityFormLayout,
  fields: Array<ContextField>,
  parentFullPath: string | undefined
) {
  // validating the existing topic on layout
  if (!layout) {
    layout = [];
  }

  let topicAdded =
    layout.filter(
      (elementsType) =>
        elementsType && elementsType.type === "topic" && elementsType.name === "loose"
    ).length > 0;

  const tempLayout: ProDoctivityFormLayout = deepCopy(layout);

  // eliminate DataElement removed from Layout
  tempLayout.forEach((element) => {
    if (element.type === "dataElement") {
      const elementsToRemove = fields.filter((dataElement) => dataElement.name === element.name);
      if (elementsToRemove.length === 0) {
        layout = layout.filter((item) => item.i !== element.i);
      }
    }
  });

  const lengthLayout = layout.length;
  const highElement = 3;
  let verticalLayout = layout.length === 0 ? 0 : layout[lengthLayout - 1].y;

  // to add new DataElement.
  fields.forEach((field) => {
    const newElements = layout.filter(
      (elementType) => elementType.type === "dataElement" && elementType.name === field.name
    );

    if (newElements.length === 0) {
      if (!topicAdded && !parentFullPath) {
        const highTopic = 1;
        verticalLayout = verticalLayout + highTopic;

        // mew dataElement Topic to the Layout
        const topicLayout = createTopicLayoutItem({
          parentFullPath: undefined,
          name: "loose",
          label: "",
          y: verticalLayout,
        });

        layout.push(topicLayout);
        topicAdded = true;
      }

      verticalLayout = verticalLayout + highElement;

      const layoutItem = createDataElementLayoutItem({
        parentInstanceFullPath: parentFullPath,
        contextField: field,
        y: verticalLayout,
        controlSize: field.properties.controlSize || "Normal",
      });
      layout.push(layoutItem);
    }
  });
  return layout;
}
