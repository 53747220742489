import { Box, Skeleton, useColors } from "@prodoctivity/design-system";

export const TemplateCardSkeleton = () => {
  const { colors } = useColors();
  return (
    <Box color={colors.white} padding={4} rounding={1} width="100%" maxWidth={400}>
      <Skeleton width="100%" height={230} />
      <Box margin={3} />
      <Skeleton width="100%" height={25} />
    </Box>
  );
};
