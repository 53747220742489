import {
  Box,
  DatePicker,
  Label,
  SelectList,
  Switch,
  TextField,
  useColors,
} from "@prodoctivity/design-system";
import { getAccessConstraint, getIdentificationType, getLanguage } from "@prodoctivity/shared";
import type { UpdatableUserProfile, UpdateOrganizationUser } from "@prodoctivity/types";

import type { OrganizationUserLanguage } from "@prodoctivity/shared/src/index-types";
import { FunctionComponent } from "react";
import { noop } from "../../../../utils";
import { useOrganizationUserEditUserTab } from "../hooks";

type UserTabProps = {
  user: UpdateOrganizationUser;
  onChange: (user: UpdateOrganizationUser) => void;
  specificScheduleSwitch: boolean;
  setSpecificScheduleSwitch: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserTab: FunctionComponent<UserTabProps> = ({
  user,
  onChange,
  specificScheduleSwitch,
  setSpecificScheduleSwitch,
}) => {
  const { colors } = useColors();
  const {
    resources,
    handleChangeDateFrom,
    handleChangeDateTo,
    handleScheduleDateFrom,
    handleScheduleDateTo,
    accessConstraintFromDate,
    accessConstraintToDate,
    scheduleDateFrom,
    scheduleDateTo,
    phoneValidation,
    currentUser,
  } = useOrganizationUserEditUserTab(user, onChange);

  const disabled = currentUser?.username === user.username;
  return (
    <Box display="flex" direction="column" color={colors.white}>
      {/* Form Start */}
      <Box display="flex" direction="row" paddingX={6} paddingY={4}>
        <Box direction="column" width={"50%"}>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="email">{resources.email}</Label>
            </Box>
            <Box width={293}>
              <TextField
                onChange={noop}
                id="email"
                value={user.username}
                maxLength={{
                  characterCount: 50,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
                disabled
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="isActive">{resources.active}</Label>
            </Box>

            <Box width={293} paddingY={3}>
              <Switch
                id="isActive"
                onChange={({ value }) => onChange({ ...user, isActive: value })}
                switched={user.isActive}
                disabled={disabled}
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="isApiUser">{resources.apiPage.apiUser}</Label>
            </Box>
            <Box width={293} paddingY={3}>
              <Switch
                id="isApiUser"
                onChange={({ value }) => onChange({ ...user, isApiUser: value })}
                switched={user.isApiUser}
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="firstName">{resources.firstName}</Label>
            </Box>
            <Box width={293}>
              <TextField
                onChange={({ value }) => onChange({ ...user, firstName: value })}
                id="firstName"
                placeholder={resources.firstName}
                value={user.firstName}
                maxLength={{
                  characterCount: 50,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
                errorMessage={
                  !user.firstName || user.firstName.trim() === ""
                    ? resources.userManagementAndRoles.requiredFieldErrors.noFirstName
                    : null
                }
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="lastName">{resources.lastName}</Label>
            </Box>
            <Box width={293}>
              <TextField
                onChange={({ value }) => onChange({ ...user, lastName: value })}
                id="lastName"
                placeholder={resources.lastName}
                value={user.lastName}
                maxLength={{
                  characterCount: 50,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
                errorMessage={
                  !user.lastName || user.lastName.trim() === ""
                    ? resources.userManagementAndRoles.requiredFieldErrors.noLastName
                    : null
                }
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="identificationType">
                {resources.userManagementAndRoles.identificationTypeLabel}
              </Label>
            </Box>
            <Box width={293}>
              <SelectList
                id="identificationType"
                onChange={({ value }) => {
                  onChange({
                    ...user,
                    identificationType: value as UpdatableUserProfile["identificationType"],
                  });
                }}
                options={getIdentificationType().map((type) => {
                  return {
                    label: resources.userManagementAndRoles.identificationType[type],
                    value: type,
                  };
                })}
                value={user.identificationType}
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="id">{"ID"}</Label>
            </Box>
            <Box width={293}>
              <TextField
                onChange={({ value }) => {
                  onChange({ ...user, identification: value });
                }}
                id="id"
                placeholder={"Enter your id here"}
                value={user.identification}
                maxLength={{
                  characterCount: 50,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="positionRole">{resources.userManagementAndRoles.positionUser}</Label>
            </Box>
            <Box width={293}>
              <TextField
                onChange={({ value }) => {
                  onChange({ ...user, positionRole: value });
                }}
                id="positionRole"
                placeholder={resources.userManagementAndRoles.positionUser}
                value={user.positionRole}
                maxLength={{
                  characterCount: 50,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="phone">{resources.phone}</Label>
            </Box>
            <Box width={293}>
              <TextField
                onChange={(e) => {
                  phoneValidation(e.value);
                  onChange({ ...user, phone: e.value });
                }}
                id="phone"
                placeholder={resources.phone}
                value={user.phone}
                label="Email"
                labelDisplay="hidden"
                maxLength={{
                  characterCount: 50,
                  errorAccessibilityLabel: resources.dataDictionary.limitReached,
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box direction="column" width={"50%"}>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="accessConstraint">
                {resources.userManagementAndRoles.accessConstraint.accessConstraintLabel}
              </Label>
            </Box>
            <Box width={293}>
              <SelectList
                id="accessConstraint"
                onChange={({ value }) => {
                  let accessConstraint = user.accessConstraint;

                  if (value === "dateRange") {
                    accessConstraint = {
                      type: "dateRange",
                      fromDate: new Date().getTime(),
                      toDate: new Date().getTime(),
                    };
                  } else if (value === "limitDate") {
                    accessConstraint = { type: "limitDate", toDate: new Date().getTime() };
                  } else {
                    accessConstraint = { type: "unrestricted" };
                  }
                  onChange({ ...user, accessConstraint });
                }}
                options={getAccessConstraint().map((type) => {
                  let label;
                  if (type.type === "dateRange") {
                    label = resources.userManagementAndRoles.accessConstraint.dateRange;
                  } else if (type.type === "limitDate") {
                    label = resources.userManagementAndRoles.accessConstraint.limitDate;
                  } else {
                    label = resources.userManagementAndRoles.accessConstraint.unrestricted;
                  }

                  return {
                    label,
                    value: type.type,
                  };
                })}
                value={user.accessConstraint?.type}
                disabled={disabled}
              />
            </Box>
          </Box>
          {user.accessConstraint?.type === "dateRange" ? (
            <Box marginBottom={7} display="flex">
              <Box marginEnd={3}>
                <DatePicker
                  resources={resources}
                  rangeStartDate={accessConstraintFromDate()}
                  rangeEndDate={accessConstraintToDate()}
                  id="fromDate"
                  label={resources.from}
                  placeholder={resources.from}
                  onChange={handleChangeDateFrom}
                  rangeSelector="start"
                  value={accessConstraintFromDate()}
                  dataType="Date"
                />
              </Box>
              <DatePicker
                resources={resources}
                rangeStartDate={accessConstraintFromDate()}
                rangeEndDate={accessConstraintToDate()}
                id="toDate"
                label={resources.to}
                placeholder={resources.to}
                onChange={handleChangeDateTo}
                rangeSelector="end"
                value={accessConstraintToDate()}
                dataType="Date"
              />
            </Box>
          ) : user.accessConstraint?.type === "limitDate" ? (
            <Box marginBottom={7} display="flex">
              <DatePicker
                resources={resources}
                rangeStartDate={accessConstraintFromDate()}
                rangeEndDate={accessConstraintToDate()}
                id="toDate"
                label={resources.to}
                placeholder={resources.to}
                onChange={handleChangeDateTo}
                rangeSelector="end"
                value={accessConstraintToDate()}
                dataType="Date"
              />
            </Box>
          ) : null}

          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={5}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="specificScheduleTime">
                {resources.userManagementAndRoles.specificScheduleTime}
              </Label>
            </Box>
            <Box width={293} paddingY={3}>
              <Switch
                id="specificScheduleTime"
                onChange={({ value }) => {
                  setSpecificScheduleSwitch(value);
                }}
                switched={user.specificScheduleTime ? true : specificScheduleSwitch}
                disabled={disabled}
              />
            </Box>
          </Box>
          {specificScheduleSwitch ? (
            <Box marginBottom={7} display="flex">
              <Box marginEnd={3}>
                <DatePicker
                  resources={resources}
                  rangeStartDate={scheduleDateFrom()}
                  rangeEndDate={scheduleDateTo()}
                  id="fromDate"
                  label={resources.from}
                  placeholder={resources.from}
                  onChange={handleScheduleDateFrom}
                  rangeSelector="start"
                  value={scheduleDateFrom()}
                  dataType="Date"
                />
              </Box>
              <DatePicker
                resources={resources}
                rangeStartDate={scheduleDateFrom()}
                rangeEndDate={scheduleDateTo()}
                id="toDate"
                label={resources.to}
                placeholder={resources.to}
                onChange={handleScheduleDateTo}
                rangeSelector="end"
                value={scheduleDateTo()}
                dataType="Date"
              />
            </Box>
          ) : (
            (user.specificScheduleTime = undefined)
          )}
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="onlySeeOwnedDocs">
                {resources.userManagementAndRoles.onlySeeOwnedDocs}
              </Label>
            </Box>
            <Box width={293} paddingY={3}>
              <Switch
                id="onlySeeOwnedDocs"
                onChange={({ value }) => {
                  onChange({ ...user, onlySeeOwnedDocs: value });
                }}
                switched={user.onlySeeOwnedDocs}
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor="language">{resources.language}</Label>
            </Box>
            <Box width={293}>
              <SelectList
                id="language"
                onChange={({ value }) => {
                  onChange({ ...user, language: value as OrganizationUserLanguage });
                }}
                options={getLanguage().map((type) => {
                  return {
                    label:
                      type === "pt"
                        ? resources.languages.portuguese
                        : type === "es"
                        ? resources.languages.spanish
                        : type === "nl"
                        ? resources.languages.dutch
                        : resources.languages.english,
                    value: type,
                  };
                })}
                value={user.language}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Form End */}
    </Box>
  );
};
