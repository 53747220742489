import {
  Condition,
  Dependency,
  FormLayout,
  FormValues,
  GroupLayouts,
  GroupValues,
  ProDoctivityFormLayout,
  ProDoctivityFormLayoutItem,
} from "../_lib/types";

import { Expression } from "./logical";

export function areLayoutsEqual(firstLayout: any, secondLayout: any): Promise<boolean> {
  return new Promise((resolve) =>
    resolve(JSON.stringify(firstLayout) === JSON.stringify(secondLayout))
  );
}

// Returns an array of form elements with a "canRender"
// property after being evaluated by a dependency condition.
// Also, this needs formValues, so, make sure to call this
// only when the form is not in design mode.
function getRenderableElements(
  elements: ProDoctivityFormLayout,
  dependencies: Array<Dependency>,
  formValues: any
) {
  // STEP 1: re-map dependencies
  const elementExpressions: Array<{ id: string; expressions: Array<Condition> }> = [];

  if (dependencies) {
    dependencies.forEach((dependency) => {
      dependency.fields.forEach((field) => {
        const elementExpression = elementExpressions.find((x) => x.id === field);

        if (!elementExpression) {
          elementExpressions.push({
            id: field,
            expressions: [dependency.conditions],
          });
        } else {
          elementExpression.expressions.push(dependency.conditions);
        }
      });
    });

    // STEP 2: evaluate if element can render
    return elements.map((element) => {
      // A single element can be used by multiple dependencies
      // so we account for that
      const targetExpressions = elementExpressions.filter((x) => {
        return x.id === element.i;
      });

      if (targetExpressions.length > 0) {
        const canRender: Array<boolean> = [];

        targetExpressions.forEach((expressionSet) => {
          const renderable = expressionSet.expressions.some((e) => {
            try {
              const expression = new Expression(e);
              return expression.evaluate(formValues);
            } catch (e) {
              //console.warn("DEPENDENCIES: Expression Evaluation Error");
            }
            return false;
          });

          canRender.push(renderable);
        });

        // Every target expression must be true in order for the
        // element to render.
        const allCanRender = canRender.every((x) => x);

        return { element: element, renderable: !allCanRender };
      }

      return { element: element, renderable: true };
    });
  }

  return [];
}

// "Vaxinates" the layout with properties that
// the render function needs but ResponsiveReactGridLayout
// actively kills
function addMissingLayoutProperties(layout: ProDoctivityFormLayout): ProDoctivityFormLayout {
  return layout.reduce((acc: ProDoctivityFormLayoutItem[], next) => {
    acc.push(addMissingLayoutItemProperties(next));

    return acc;
  }, []);
}

function addMissingLayoutItemProperties(
  layoutItem: ProDoctivityFormLayoutItem
): ProDoctivityFormLayoutItem {
  // Ok, layoutItem is a layout element and i its key
  // so, we are splitting that into two after
  // the __ (2 underscores) separator.
  const idMatch = layoutItem.i.split(/__(.+)/);
  const type: any = idMatch[0];
  const name = idMatch[1];

  return {
    ...layoutItem,
    name: name,
    type: type,
  };
}

// This one has groups
// additional params
//  groupLayout: In a perfect world this is an array of all groups layous
//  groupValues: formValues of all the groups
export function getNewLayoutFromDependencies(
  layout: ProDoctivityFormLayout,
  dependencies: Array<Dependency>,
  formValues: FormValues | undefined,
  groupLayouts: GroupLayouts,
  groupValues?: GroupValues
): FormLayout {
  if (dependencies.length > 0) {
    const allFormValues = { ...formValues, ...groupValues };

    const newLayout: ProDoctivityFormLayout = getDependencyLayout(
      layout,
      dependencies,
      allFormValues
    );
    const newGroupLayouts: any = {};

    if (groupLayouts) {
      Object.keys(groupLayouts).forEach((key) => {
        newGroupLayouts[key] = getDependencyLayout(groupLayouts[key], dependencies, allFormValues);
      });
    }

    return {
      layout: newLayout,
      groupLayouts: newGroupLayouts,
    };
  }

  return {
    layout,
    groupLayouts,
  };
}

function getDependencyLayout(
  layout: ProDoctivityFormLayout,
  dependencies: Array<Dependency>,
  formValues: any
): ProDoctivityFormLayout {
  // Evaluate layout elements
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const elementsByRenderability = getRenderableElements(layout, dependencies, formValues);

  // Get fake layout, filter out non-renderable elements
  const newLayout = elementsByRenderability.filter((e) => e.renderable).map((e) => e.element);

  const noEmptyTopicsLayout: any = newLayout
    .map((item, i) => {
      if (newLayout[i + 1] === undefined) {
        return newLayout[i].type === "topic" ? null : item;
      }

      const elementType = newLayout[i].type;
      const nextType = newLayout[i + 1].type;

      if (elementType === "topic" && nextType === "topic") {
        return null;
      }
      return item;
    })
    .filter((x) => x !== null);

  if (elementsByRenderability.length > 0) {
    return addMissingLayoutProperties(noEmptyTopicsLayout);
  }

  return layout;
}

// this function does the opposite of "getNewLayoutFromDependencies"
export function getUnrenderableElementsFromDependencies(
  layout: ProDoctivityFormLayout,
  dependencies: Array<Dependency>,
  formValues: FormValues,
  groupValues: GroupValues
) {
  if (dependencies.length > 0) {
    const allFormValues = { ...formValues, ...groupValues };
    // Evaluate layout elements
    // eslint-disable-next-line testing-library/render-result-naming-convention
    const elementsByRenderability = getRenderableElements(layout, dependencies, allFormValues);

    // Get fake layout, filter out non-renderable elements
    const newLayout = elementsByRenderability.filter((e) => !e.renderable).map((e) => e.element);

    if (elementsByRenderability.length > 0) {
      return addMissingLayoutProperties(newLayout);
    }
  }

  return layout;
}
