import type { ConnectorDataLink, DataLink, FormConnector } from "../_lib/types";

import { DataLinkActions } from "../_lib/enums";

const ProdoctivityDataConnectorType = Object.freeze({
  ODBC: "ODBC",
  OLEDB: "OLEDB",
  SOAP: "SOAP",
});

export function getMappingFromType(type: string, xmlParams: any) {
  switch (type) {
    case ProdoctivityDataConnectorType.ODBC:
      return xmlParams.OdbcConnector.Mapping;
    case ProdoctivityDataConnectorType.OLEDB:
      return xmlParams.OleDbConnector.Mapping;
    case ProdoctivityDataConnectorType.SOAP:
      const mapping: any = {
        PrimaryKey: {
          Field: {},
        },
        Fields: {
          Field: [],
        },
      };
      const primaryEntitys = xmlParams.SoapDocumentParameters.RequestStructure.Mapping.Entry.length
        ? xmlParams.SoapDocumentParameters.RequestStructure.Mapping.Entry
        : [xmlParams.SoapDocumentParameters.RequestStructure.Mapping.Entry];
      primaryEntitys.forEach(function (entity: any) {
        mapping.PrimaryKey.Field = {
          ColumnSource: entity.XPath,
          KeyTarget: entity.SourceKey,
        };
      });
      const fieldsEntitys = xmlParams.SoapDocumentParameters.ResponseStructure.Mapping.Entry.length
        ? xmlParams.SoapDocumentParameters.ResponseStructure.Mapping.Entry
        : [xmlParams.SoapDocumentParameters.ResponseStructure.Mapping.Entry];
      fieldsEntitys.forEach(function (entity: any) {
        mapping.Fields.Field.push({
          ColumnSource: entity.XPath,
          KeyTarget: entity.SourceKey,
        });
      });
      return mapping;
    default:
      throw new Error("Unimplemented data link type: " + type);
  }
}

export function buildDataLinksConnectors(
  dataLinks: DataLink[],
  selectedDataLinks: ConnectorDataLink[],
  defaultDocumentHandle: number,
  defaultMessage: string
) {
  const dataLinksSelected = dataLinks.map(function (dataLink) {
    let index = 0,
      selectedDataLink: ConnectorDataLink = {
        keywordHandleList: [],
        connectorId: 0,
        description: "",
        documentTypeHandle: 0,
        existingData: "",
        fields: [],
        noDataMessage: "",
        notExistingData: "",
        order: 0,
        type: "",
        xmlParams: "",
      };
    if (
      selectedDataLinks.some(function (s, i) {
        index = i;
        return s.connectorId === dataLink.id;
      })
    ) {
      selectedDataLink = selectedDataLinks[index];
    }
    const mapping = getMappingFromType(dataLink.type, dataLink.xmlParams);
    return {
      id: dataLink.id,
      dataLinkId: dataLink.id,
      connectorId: selectedDataLink.connectorId || dataLink.id,
      selected: dataLink.selected,
      description: dataLink.description,
      key: mapping.PrimaryKey.Field.ColumnSource,
      mappingLength: mapping.Fields.Field.length + 1,
      documentTypeHandle: selectedDataLink.documentTypeHandle || defaultDocumentHandle,
      documentDocumentHandle: selectedDataLink.documentTypeHandle || defaultDocumentHandle,
      mapping: mapping,
      existingData:
        typeof selectedDataLink.existingData !== "undefined"
          ? selectedDataLink.existingData
          : DataLinkActions.AllowModify,
      notExistingData:
        typeof selectedDataLink.notExistingData !== "undefined"
          ? selectedDataLink.notExistingData
          : DataLinkActions.AllowModify,
      type: dataLink.type,
      order: selectedDataLink.order || dataLinks.length,
      keywordHandleList: selectedDataLink.keywordHandleList || [],
      fields: selectedDataLink.keywordHandleList || [],
      xmlParams: selectedDataLink.xmlParams || "",
      noDataMessage: selectedDataLink.noDataMessage || defaultMessage,
    };
  });
  return dataLinksSelected;
}
// Parse Data Link Connector to Webservices Connector Structure
export function parseConnectors(
  dataLinks: DataLink[],
  selectedConnectors: ConnectorDataLink[]
): FormConnector[] {
  // {
  //   name: 'JCE',
  //   input: [{ name: 'Cedula' }],
  //   output: [{ name: 'Nombre' }],
  //   shouldDisableOnData: false,
  //   shouldDisableOnNoData: false,
  //   noDataMessage: 'No data found',
  // }
  if (dataLinks.length <= 0 || selectedConnectors.length <= 0) {
    return [];
  }
  const connectors = dataLinks.reduce((result: any[], dataLink) => {
    const index = selectedConnectors.findIndex(
      (c) => c.connectorId === dataLink.id && c.keywordHandleList.length > 0
    );

    if (index >= 0) {
      const mapping = getMappingFromType(dataLink.type, dataLink.xmlParams);
      let output = [];
      if (Array.isArray(mapping.Fields.Field)) {
        output = mapping.Fields.Field.filter((field: any) =>
          dataLink.keywords.find(
            // eslint-disable-next-line
            (keyword) => keyword.keywordId == field.KeyTarget
          )
        ).map((field: any) => {
          const keyword = dataLink.keywords.find(
            // eslint-disable-next-line
            (keyword) => keyword.keywordId == field.KeyTarget
          );
          return {
            key: field.KeyTarget,
            name: keyword.name,
          };
        });
      } else {
        output.push({
          key: mapping.Fields.Field.KeyTarget,
          name: dataLink.keywords.find(
            // eslint-disable-next-line
            (keyword) => keyword.keywordId == mapping.Fields.Field.KeyTarget
          ).name,
        });
      }

      const inputName = dataLink.keywords.find(
        // eslint-disable-next-line
        (keyword) => keyword.keywordId == mapping.PrimaryKey.Field.KeyTarget
      ).name;

      result.push({
        name: dataLink.description,
        inputKey: mapping.PrimaryKey.Field.KeyTarget,
        input: [
          {
            name: inputName,
          },
        ],
        output: output,
        isDataLink: true,
        connectorId: dataLink.id,
        documentHandle: dataLink.documentDocumentHandle ? dataLink.documentDocumentHandle : 0,
        shouldDisableOnData: DataLinkActions.AllowModify !== selectedConnectors[index].existingData,
        shouldDisableOnNoData:
          DataLinkActions.AllowModify !== selectedConnectors[index].notExistingData,
        noDataMessage: selectedConnectors[index].noDataMessage,
      });
    }
    return result;
  }, []);
  return connectors;
}
