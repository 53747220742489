/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, frontIndex, useColors } from "@prodoctivity/design-system";
import { FC, useCallback, useMemo } from "react";
import { FilterReportContentBox } from "./FilterReportContentBox";
import { DoughnutCollectionGroupedDataView } from "./GroupedDataPie";
import { CollectionStatusPie } from "./CollectionStatusPie";
import { ProgressDocumentCollection } from "./progressDocumentCollectionView";
import type { ReportHeaderData } from "@prodoctivity/types";
import { Report } from "../../../components/Widgets/DocumentCollectionReport/hook";
import { useAppTranslation } from "../../../hooks/useAppTranslation";

export const CollectionReportWithFilter: FC<{
  dataSetForPieLoading: boolean;
  state: Report;
  handlerBoxFilterValues: (
    category: string,
    values: { value: string; checkState: boolean }
  ) => void;
  handleCheckAll: (category: string) => void;
  filtersConfig: ReportHeaderData;
  payload: any;
  assignedFilters: string;
  setAssignedFilter: (value: string) => void;
  changeSort: (sortBy: "default" | "asc" | "desc", sortedCol: string) => void;
  defaults: Array<string>;
}> = ({
  dataSetForPieLoading,
  state,
  handlerBoxFilterValues,
  handleCheckAll,
  filtersConfig,
  payload,
  defaults,
}) => {
  const { colors } = useColors();

  const getSelectedFilter = useCallback(
    (category: string) => {
      if (state.categoryFilters.length === 0) return [];
      if (state.categoryFilters.filter((r) => r.category === category)[0] === undefined) return [];
      const checkedValues = state.categoryFilters.filter((r) => r.category === category)[0].values;
      return checkedValues;
    },
    [state.categoryFilters]
  );

  const buildFilters = useMemo(() => {
    if (filtersConfig) {
      return (filtersConfig as ReportHeaderData).filters
        .filter((x) => x.title !== "assignedTo")
        .map((x, index) => (
          <Box display="flex" direction="column" gap={4} key={index} marginBottom={4}>
            <FilterReportContentBox
              key={x.title}
              title={x.title}
              filters={x.filters}
              onFilterChange={handlerBoxFilterValues}
              selectedFilter={getSelectedFilter(x.title)}
              onCheckAll={handleCheckAll}
              defaults={defaults}
            />
          </Box>
        ));
    }
  }, [filtersConfig, getSelectedFilter, handleCheckAll, handlerBoxFilterValues, defaults]);

  const selectionCounter = useMemo(() => {
    const selectionCount = state.categoryFilters
      .map((n) => {
        return n.values.length;
      })
      .reduce((a, b) => a + b);
    return selectionCount > 0;
  }, [state.categoryFilters]);

  const { resources } = useAppTranslation();

  return (
    <Box
      display="flex"
      direction="row"
      width={"100%"}
      flex="grow"
      padding={4}
      gap={4}
      color={colors.neutral300}
    >
      <Box overflow="scrollY" marginEnd={-12} minWidth={350}>
        <Box paddingRight={12}>
          <Box>{buildFilters}</Box>
        </Box>
      </Box>
      <Box
        color={colors.neutral300}
        width={"100%"}
        zIndex={frontIndex}
        paddingRight={4}
        marginEnd={-8}
        overflow="scrollY"
      >
        <Box
          id="main-container"
          direction="column"
          display="flex"
          gap={4}
          color={colors.neutral300}
        >
          <Grid
            gridTemplateColumns={["repeat(auto-fill, minmax(500px, 1fr))"]}
            gap={6}
            width={"100%"}
          >
            <Box
              id="box-for-progress-bar-1"
              display="flex"
              direction="row"
              width={"100%"}
              gap={4}
              wrap={true}
            >
              <ProgressDocumentCollection
                title={resources.totalCollectionProgressTitle}
                value={state.initialData?.valid}
                total={state.initialData?.total}
                color={"rgb(4, 118, 231)"}
              />
              <ProgressDocumentCollection
                title={resources.totalMissingCollectionProgressTitle}
                value={state.initialData?.issue}
                total={state.initialData?.total}
                color={"rgb(213, 38, 38)"}
              />
            </Box>
            <Box
              id="box-for-progress-bar-2"
              display="flex"
              direction="row"
              width={"100%"}
              gap={5}
              wrap={true}
            >
              <ProgressDocumentCollection
                title={resources.totalExpiredCollectionProgressTitle}
                value={state.initialData?.expired}
                total={state.initialData?.total}
                color={"rgb(4, 118, 231)"}
              />
              <ProgressDocumentCollection
                title={resources.totalExpiringCollectionProgressTitle}
                value={state.initialData?.expiring}
                total={state.initialData?.total}
                color={"rgb(213, 38, 38)"}
              />
            </Box>
            <CollectionStatusPie dataSet={payload?.pieDataSet} isLoading={dataSetForPieLoading} />
            <DoughnutCollectionGroupedDataView
              dataSet={payload?.groupedDataSet}
              isLoading={dataSetForPieLoading}
              valueToRepresent="expired"
              filterHasSelection={selectionCounter}
            />
            <DoughnutCollectionGroupedDataView
              dataSet={payload?.groupedDataSet}
              isLoading={dataSetForPieLoading}
              valueToRepresent="expiring"
              filterHasSelection={selectionCounter}
            />
            <DoughnutCollectionGroupedDataView
              dataSet={payload?.groupedDataSet}
              isLoading={dataSetForPieLoading}
              valueToRepresent="issues"
              filterHasSelection={selectionCounter}
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
