import {
  Box,
  Divider,
  Icon,
  SearchField,
  Skeleton,
  Table,
  Text,
  Tooltip,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { range } from "@prodoctivity/shared";
import type { SequenceResponse } from "@prodoctivity/shared/src/index-types";
import type {
  HttpGetPaginatedOrganizationSequencesRequest,
  HttpGetPaginatedOrganizationSequencesResponse,
  PaginatedQueryResponsePart,
} from "@prodoctivity/types";
import { FunctionComponent, useCallback, useMemo } from "react";
import { BreadCrumbEntry } from "../../../../components/BreadCrumb";
import { Page } from "../../../../components/Layout/Page";
import { Pagination } from "../../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../../components/hooks";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../../link-templates";

const SequencesList: FunctionComponent = () => {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const { breakpoint } = useDesignBreakpoint();
  const { colors } = useColors();

  const addNew = useCallback(() => {
    organizationNavigate("/settings/data-dictionary/sequences/new");
  }, [organizationNavigate]);

  const PAGINATION_OPTIONS: HttpGetPaginatedOrganizationSequencesRequest["queryParameters"]["rowsPerPage"][] =
    ["15", "30", "100"];

  const {
    currentPage,
    isLoading,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    paginatedData,
    previousPage,
    refetch,
    rowsPerPage,
    setFilter,
    setPageLength,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    HttpGetPaginatedOrganizationSequencesResponse["payload"],
    HttpGetPaginatedOrganizationSequencesRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage, filter) => {
      return services.getPaginatedSequences(currentPage, rowsPerPage, filter);
    },
    "data_elements_sequences_page"
  );

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.dataDictionary.dictionary,
        url: organizationLinkTemplates.dataDictionary(),
      },
      { type: "text", name: resources.sequences },
    ];
  }, [
    resources.dataDictionary.dictionary,
    resources.home,
    resources.sequences,
    resources.settings,
  ]);
  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" flex="grow" padding={4}>
        <SearchComponentWrapper
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          buttonAction={addNew}
          componentHeaderText={resources.sequences}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="sequences_filter"
          searchFieldLabel={resources.searchBySequenceName}
          setFilter={setFilter}
          omitSearch={true}
        />

        <Box display="flex" direction="column" flex="shrink" padding={2}>
          <Pagination<typeof rowsPerPage>
            id="data_elements_sequences_pagination_top"
            rowsLabel={`${resources.sequences}:`}
            currentPage={currentPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageLength={paginatedData ? paginatedData.sequences.length : 0}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setPageLength}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            pageLengthOptions={PAGINATION_OPTIONS}
            totalRowCount={totalRowCount}
            extraComponent={
              <SearchField
                marginStart={breakpoint === "large" || breakpoint === "hd" ? 2 : undefined}
                marginEnd={2}
                accessibilityLabel={resources.searchBySequenceName}
                id="data_elements_filter"
                onChange={({ value }) => {
                  setFilter(value);
                }}
              />
            }
          />
        </Box>
        <Divider />
        <Box paddingTop={6} display="flex" direction="column" flex="grow">
          <Box margin={4} color={colors.white} borderStyle={"raisedTopShadow"} borderRadius={4}>
            <SequencesListTable isLoading={isLoading} paginatedData={paginatedData} />
          </Box>
          <Box color={colors.white} display="flex" direction="row" flex="shrink" alignSelf="end">
            <Pagination<typeof rowsPerPage>
              id="data_elements_list_pagination_bottom"
              rowsLabel={`${resources.sequences}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.sequences.length : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={PAGINATION_OPTIONS}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

const SequencesListTable: FunctionComponent<{
  isLoading: boolean;
  paginatedData:
    | (PaginatedQueryResponsePart & {
        sequences: SequenceResponse[];
      })
    | undefined;
}> = ({ isLoading, paginatedData }) => {
  const { resources } = useAppTranslation();
  return (
    <Table accessibilityLabel={resources.sequences}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Text>{resources.name.toUpperCase()}</Text>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Text>{resources.description.toUpperCase()}</Text>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Text>{resources.type.toUpperCase()}</Text>
          </Table.HeaderCell>
          {
            //TODO: Uncomment when isBlocking got implemented
            /* <Table.HeaderCell>
            <Text>{resources.isBlocking.toUpperCase()}</Text>
          </Table.HeaderCell> */
          }
          <Table.HeaderCell key={"d"}>
            <Text>{resources.actions.toUpperCase()}</Text>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isLoading
          ? range(7).map((_, idx) => <SequencesListSkeletonRowTable key={idx} />)
          : paginatedData &&
            paginatedData.sequences.length > 0 &&
            paginatedData.sequences.map((sequence, idx) => (
              <SequencesListRowTable
                key={`${sequence.name}_${idx}`}
                index={idx}
                name={sequence.name}
                desc={sequence.description}
                type={sequence.type}
                isBlocking={sequence.isBlocking}
              />
            ))}
      </Table.Body>
    </Table>
  );
};

const SequencesListRowTable: FunctionComponent<{
  index: number;
  name: string;
  desc: string;
  type: string;
  isBlocking: boolean;
}> = ({ index, name, desc, type }) => {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const { colors } = useColors();
  return (
    <Table.Row key={`${name}_${index}`}>
      <Table.Cell>
        <Text>{`${name}`} </Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{`${desc}`} </Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{`${type.toUpperCase()}`} </Text>
      </Table.Cell>
      {
        //TODO: Uncomment when isBlocking got implemented
        /* <Table.Cell>
        {isBlocking ? (
          <Icon icon="check" accessibilityLabel="Blocking" color={colors.success} />
        ) : (
          <Icon icon="x" accessibilityLabel="NotBlocking" color={colors.error} />
        )}
      </Table.Cell> */
      }
      <Table.Cell>
        <Box flex="shrink" display="flex">
          <Tooltip text={resources.edit}>
            <Icon
              accessibilityLabel={resources.edit}
              onClick={() => organizationNavigate(`/settings/data-dictionary/sequences/${name}`)}
              icon="pencil"
              size={"sm"}
              color={colors.primary}
            />
          </Tooltip>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};

type SequencesListSkeletonRowTableProps = {
  key: number;
};

const SequencesListSkeletonRowTable: FunctionComponent<SequencesListSkeletonRowTableProps> = (
  key
) => {
  return (
    <Table.Row key={`${key}`}>
      <Table.Cell>
        <Box marginStart={4} display={"flex"} justifyContent={"between"}>
          <Skeleton height={50} width={"100%"} />
          <Box width={1} borderStyle={"sm"} borderRadius={4} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box>
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};
export default SequencesList;
