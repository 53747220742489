import { Upload } from "antd";
import { Icon, type DesignBreakpointType } from "../../components";

const Dragger = Upload.Dragger;

type Props = {
  accept: string;
  image: any;
  disabled: boolean;
  onChange: (event: any) => Promise<any>;
  onRemove: () => void;
  componentBreakpoint: DesignBreakpointType;
  customRequest: any;
  resources: {
    dragDropFile: string;
  };
};

export function DropImage({
  accept,
  image,
  disabled,
  customRequest,
  onChange,
  onRemove,
  componentBreakpoint,
  resources,
}: Props) {
  return (
    <div className={`prodoctivity-drop-image ${componentBreakpoint}`}>
      <Dragger
        accept={accept}
        name="image"
        disabled={disabled}
        listType="picture"
        fileList={image ? [image] : []}
        onChange={onChange}
        onRemove={onRemove}
        customRequest={customRequest}
      >
        <p className="ant-upload-drag-icon">
          <Icon size={"md"} icon="cloud-upload" accessibilityLabel={"drop"} />
        </p>
        <p className="ant-upload-text upload-text">{resources.dragDropFile}</p>
      </Dragger>
    </div>
  );
}
