import { Box, Button, TabsProps, Text, useDesignBreakpoint } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo } from "react";

import { Page } from "../../../components/Layout/Page";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { organizationLinkTemplates } from "../../../link-templates";
import { EditOrganizationUserTabs } from "../Users/Actions/EditOrganizationUserTabs";
import { DocumentAndTemplatePermissionsTab } from "./TabsContents/DocumentAndTemplatePermissionsTab";
import { PropertiesTab } from "./TabsContents/PropertiesTab";
import { SecurityKeywordsTab } from "./TabsContents/SecurityKeywordsTab";
import { SpecialPermissionsTab } from "./TabsContents/SpecialPermissionsTab";
import { UsersTab } from "./TabsContents/UsersTab";
import { useRoleManagement } from "./hooks";

type EditOrganizationUserProps = {
  isUpdating?: boolean;
};
const EditOrganizationRolePage: FunctionComponent<EditOrganizationUserProps> = ({ isUpdating }) => {
  const {
    groupList,
    groupListFilter,
    onSetGroupListFilter,
    roleId,
    organizationInfoResponse,
    documentTypes,
    collectionsPaginated,
    breadCrumbEntries,
    resources,
    organizationNavigate,
    currentTab,
    formState,
    setFormState,
    isLoading,
    isSaveButtonDisabled,
    organizationUsers,
    documentTypesPaginated,
    templatesPaginated,
    handleSaveChanges,
    isMutating,
    isSuccess,
    onDocumentTypesFilterChanged,
    onTemplatesFilterChanged,
    onCollectionFilterChanged,
    docTypesFilter: documentTypeFilteredValue,
    templatesFilter: templateFilteredValue,
    collectionsFilter: collectionFilteredValue,
  } = useRoleManagement(!!isUpdating);
  const { breakpoint } = useDesignBreakpoint();
  const tabs = useMemo(() => {
    return [
      {
        title: resources.properties,
        key: "properties",
        content: (
          <PropertiesTab formState={formState} isLoading={isLoading} setFormState={setFormState} />
        ),
      },
      {
        title: resources.users,
        key: "users",
        content: (
          <UsersTab
            formState={formState}
            users={organizationUsers?.users || []}
            isLoading={isLoading}
            setFormState={setFormState}
          />
        ),
      },
      {
        title: resources.rolesManagement.specialPermissions,
        key: "special",
        content: (
          <SpecialPermissionsTab
            formState={formState}
            isLoading={isLoading}
            setFormState={setFormState}
          />
        ),
      },
      {
        title: resources.permissions,
        key: "permissions",
        content: (
          <Box>
            {
              <DocumentAndTemplatePermissionsTab
                queryBehavior={organizationInfoResponse?.settings.queryBehavior}
                action={
                  organizationInfoResponse?.settings.queryBehavior === "denied-until-allowed"
                    ? "allow"
                    : "deny"
                }
                formState={formState}
                documentTypesPaginated={documentTypesPaginated}
                templatesPaginated={templatesPaginated}
                isLoading={isLoading}
                setFormState={setFormState}
                onDocumentTypesFilterChanged={onDocumentTypesFilterChanged}
                onTemplatesFilterChanged={onTemplatesFilterChanged}
                collectionsPaginated={collectionsPaginated}
                onCollectionFilterChanged={onCollectionFilterChanged}
                documentTypeFilteredValue={documentTypeFilteredValue}
                templateFilteredValue={templateFilteredValue}
                collectionFilteredValue={collectionFilteredValue}
                groupList={groupList}
                groupListFilter={groupListFilter}
                onSetGroupListFilter={onSetGroupListFilter}
              />
            }
          </Box>
        ),
      },
      {
        title: resources.rolesManagement.securityKeys,
        key: "security-fields",
        content: (
          <SecurityKeywordsTab
            roleId={roleId}
            resources={resources}
            isLoading={isLoading}
            documentTypes={documentTypes}
            documentTypesSecurityFields={
              organizationInfoResponse?.securityFieldConfig?.documentTypes || []
            }
          />
        ),
      },
    ];
  }, [
    collectionFilteredValue,
    collectionsPaginated,
    documentTypeFilteredValue,
    documentTypes,
    documentTypesPaginated,
    formState,
    groupList,
    groupListFilter,
    isLoading,
    onCollectionFilterChanged,
    onDocumentTypesFilterChanged,
    onSetGroupListFilter,
    onTemplatesFilterChanged,
    organizationInfoResponse?.securityFieldConfig?.documentTypes,
    organizationInfoResponse?.settings.queryBehavior,
    organizationUsers?.users,
    resources,
    roleId,
    setFormState,
    templateFilteredValue,
    templatesPaginated,
  ]);

  const onTabChange: TabsProps["onChange"] = useCallback(
    (args) => {
      args.event.preventDefault();
      if (args.dangerouslyDisableOnNavigation) {
        args.dangerouslyDisableOnNavigation();
      }
      const tab = tabs[args.activeTabIndex];
      if (tab) {
        organizationNavigate(organizationLinkTemplates.editRole(roleId || "new", tab.key));
      }
    },
    [organizationNavigate, roleId, tabs]
  );

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" flex="grow">
        <Box display="flex" direction="column" flex="grow" paddingX={breakpoint === "hd" ? 12 : 4}>
          <Box marginBottom={4} paddingTop={4}>
            <Text
              weight="bold"
              size="400"
              title={formState.name ?? ""}
              ellipsisLength={breakpoint === "small" || breakpoint === "medium" ? 32 : 50}
            >
              {`${resources.rolesManagement.roles}${formState.name ? `: ${formState.name}` : ""}`}
            </Text>
          </Box>

          <Box display="flex" direction="column" flex="grow">
            <EditOrganizationUserTabs selectedTab={currentTab} tabs={tabs} onChange={onTabChange} />
            <Box display="flex" justifyContent="end" paddingY={4} gap={4}>
              <Box>
                <Button
                  color={"transparent"}
                  onClick={() => {
                    organizationNavigate(organizationLinkTemplates.manageRoles());
                  }}
                  disabled={isLoading || isMutating || isSuccess}
                  text={resources.cancel}
                />
              </Box>
              <Box>
                <Button
                  color={"blue"}
                  onClick={handleSaveChanges}
                  disabled={isSaveButtonDisabled || isLoading || isMutating || isSuccess}
                  text={resources.saveChanges}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {formState.toastMessage && (
          <NotificationMessage
            type={formState.toastMessage.type}
            message={formState.toastMessage.message}
            position="bottom-left"
            handleDismiss={() => setFormState((prev) => ({ ...prev, toastMessage: undefined }))}
          />
        )}
      </Box>
    </Page>
  );
};

export default EditOrganizationRolePage;
