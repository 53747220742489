import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const SingleColumnLayoutIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 11C30 11.5523 29.5523 12 29 12H11C10.4477 12 10 11.5523 10 11V11C10 10.4477 10.4477 10 11 10H29C29.5523 10 30 10.4477 30 11V11ZM30 29C30 29.5523 29.5523 30 29 30H11C10.4477 30 10 29.5523 10 29V29C10 28.4477 10.4477 28 11 28H29C29.5523 28 30 28.4477 30 29V29ZM26 24C26 24.5523 25.5523 25 25 25H15C14.4477 25 14 24.5523 14 24V16C14 15.4477 14.4477 15 15 15L25 15C25.5523 15 26 15.4477 26 16V24Z"
        fill={color ? calculateColor(color) : "#CACBCD"}
      />
    </svg>
  );
};
