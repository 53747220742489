import {
  Box,
  Button,
  Grid,
  Icon,
  SelectList,
  Switch,
  Text,
  TextArea,
  TextField,
  Tooltip,
  WhatsThis,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import { DocumentTypeNameValidationRegex } from "@prodoctivity/shared";
import type { DocumentGroup } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";

import { Page } from "../../../components/Layout/Page";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { useDocumentGroupById, useGetAllOrganizationRoles } from "../../../hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";

const nameFieldMaxLength = 100;
const descriptionFieldMaxLength = 4000;

const gridTemplateColumns = [11, 1];

type DocumentGroupConfigurationProps = {
  isUpdating: boolean;
};
const EditDocumentGroupPage: FunctionComponent<DocumentGroupConfigurationProps> = ({
  isUpdating,
}) => {
  const { documentGroupId } = useParams();
  const { isLoading, data } = useDocumentGroupById(documentGroupId || "");
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: `${resources.documentaryStructure}`,
        url: organizationLinkTemplates.manageDocumentTypes(),
      },
      { type: "url", name: resources.documentGroups, url: "/document-groups" },
    ];
  }, [
    resources.documentGroups,
    resources.documentaryStructure,
    resources.home,
    resources.settings,
  ]);

  if (isLoading) {
    return (
      <Page breadCrumbEntries={breadCrumbEntries}>
        <Box display="flex" direction="column">
          <Grid gridTemplateColumns={gridTemplateColumns}>
            <Box marginStart={12}>
              <Text weight="bold" size="400">
                {resources.loading}
              </Text>
            </Box>
            <Box>
              <Box marginEnd={12} width={90} marginStart="auto"></Box>
            </Box>
          </Grid>
          <Box
            color={colors.white}
            paddingY={8}
            paddingX={6}
            marginTop={2}
            marginStart={12}
            marginEnd={12}
            borderStyle="lg"
            borderRadius={4}
          ></Box>
        </Box>
      </Page>
    );
  }

  if (!data && isUpdating) {
    return (
      <Page breadCrumbEntries={breadCrumbEntries}>
        <Box display="flex" direction="column">
          <Grid gridTemplateColumns={gridTemplateColumns}>
            <Box marginStart={12}>
              <Text weight="bold" size="400">
                {resources.noResultsFound}
              </Text>
            </Box>
            <Box>
              <Box marginEnd={12} width={90} marginStart="auto"></Box>
            </Box>
          </Grid>
          <Box
            color={colors.white}
            paddingY={8}
            paddingX={6}
            marginTop={2}
            marginStart={12}
            marginEnd={12}
            borderStyle="lg"
            borderRadius={4}
          ></Box>
        </Box>
      </Page>
    );
  }

  return <EditDocumentGroupComponent documentGroup={data?.documentGroup} isUpdating={isUpdating} />;
};

type DocumentGroupState = {
  name?: string;
  description?: string;
  roleToNotify: string | undefined;
  isContentLibrary: boolean;
  configurationWorkflowId?: string;
  generationWorkflowId?: string;
  infoOpen: boolean;
  error?: string;
  loading: boolean;
  toastMessage?: {
    type: "error" | "success" | "warn";
    message: string;
  };
  showToast: boolean;
};

const EditDocumentGroupComponent: FunctionComponent<{
  documentGroup: DocumentGroup | undefined;
  isUpdating: boolean;
}> = ({ documentGroup, isUpdating }) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const organizationNavigate = useOrganizationNavigate();
  const { saveDocumentGroup, updateDocumentGroup } = useServices();
  const { data: roles } = useGetAllOrganizationRoles();
  const withoutRole = {
    id: "none",
    name: resources.dataTypeValues.none,
  };
  const rolesOptions = roles?.concat(withoutRole).reverse();
  const [formState, setFormState] = useState<DocumentGroupState>({
    name: documentGroup?.name || "",
    description: documentGroup?.description || "",
    isContentLibrary: documentGroup?.isContentLibrary || false,
    roleToNotify: documentGroup?.roleToNotify || undefined,
    infoOpen: false,
    loading: false,
    showToast: false,
    configurationWorkflowId: documentGroup?.configurationWorkflowId,
    generationWorkflowId: documentGroup?.generationWorkflowId,
  });

  const { mutate } = useMutation(updateDocumentGroup, {
    onSuccess: () => {
      organizationNavigate(organizationLinkTemplates.manageDocumentGroups());
      onChange("loading", true);
      setFormState({
        ...formState,
        toastMessage: { type: "success", message: resources.documentGroup },
      });
    },

    onError: (error: {
      name: string;
      message: string;
      response: {
        data: {
          errors: Array<{ errorCode: string; message: string }>;
        };
      };
    }) => {
      const errorType = error?.response.data.errors[0].message;
      onChange("loading", false);
      setFormState({
        ...formState,
        loading: false,
        toastMessage: {
          type: "error",
          message: errorType,
        },
      });
    },
  });

  const onChange = useCallback(
    (
      field: "name" | "description" | "roleToNotify" | "isContentLibrary" | "error" | "loading",
      value: string | boolean
    ) => {
      let val = value;
      if (field === "name" && typeof value === "string" && value.length > nameFieldMaxLength) {
        val = value.substring(0, nameFieldMaxLength - 1);
      } else if (
        field === "description" &&
        typeof value === "string" &&
        value.length > descriptionFieldMaxLength
      ) {
        val = value.substring(0, descriptionFieldMaxLength - 1);
      }
      setFormState((prev) => {
        return { ...prev, [field]: val };
      });
    },
    [setFormState]
  );

  const handleSubmit = useCallback(async () => {
    if (!formState.name || !formState.description) {
      return;
    }
    onChange("error", "");
    const payload = {
      name: formState.name.trim(),
      description: formState.description.trim(),
      roleToNotify: formState.roleToNotify === "none" ? undefined : formState.roleToNotify,
      isContentLibrary: formState.isContentLibrary,
      configurationWorkflowId: formState.configurationWorkflowId,
      generationWorkflowId: formState.generationWorkflowId,
    };
    onChange("loading", true);
    if (isUpdating && documentGroup?.id) {
      mutate({
        documentGroupId: documentGroup?.id,
        name: payload.name,
        isContentLibrary: payload.isContentLibrary,
        description: payload.description,
        roleToNotify: payload.roleToNotify,
      });
    } else {
      await saveDocumentGroup(payload)
        .then(() => {
          organizationNavigate(organizationLinkTemplates.manageDocumentGroups());
          onChange("loading", false);
        })
        .catch(
          (error: {
            name: string;
            message: string;
            response: {
              data: {
                errors: Array<{ errorCode: string; message: string }>;
              };
            };
          }) => {
            const errorType = error?.response.data.errors[0].message;
            setFormState({
              ...formState,
              toastMessage: {
                type: "error",
                message:
                  errorType === "exists"
                    ? resources.documentGroupNameValidations.documentGroupAlreadyExists
                    : errorType === "invalid-name"
                    ? resources.documentGroupNameValidations.invalidDocumentGroupName
                    : resources.anErrorHasOccurred,
              },
            });
            setTimeout(() => {
              setFormState({
                ...formState,
              });
            }, 5000);
            onChange("loading", false);
          }
        );
    }
  }, [
    resources.documentGroupNameValidations.documentGroupAlreadyExists,
    resources.documentGroupNameValidations.invalidDocumentGroupName,
    resources.anErrorHasOccurred,
    formState,
    saveDocumentGroup,
    onChange,
    organizationNavigate,
    isUpdating,
    mutate,
    setFormState,
    documentGroup?.id,
  ]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: `${resources.documentaryStructure}`,
        url: organizationLinkTemplates.manageDocumentTypes(),
      },
      { type: "url", name: resources.documentGroups, url: "/document-groups" },
    ];
  }, [
    resources.documentGroups,
    resources.documentaryStructure,
    resources.home,
    resources.settings,
  ]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <Grid gridTemplateColumns={[11, 1]}>
          <Box marginStart={12}>
            <Text weight="bold" size="400">
              {isUpdating ? formState.name : resources.newDocumentGroup}
            </Text>
          </Box>
          <Box>
            <Box marginEnd={12} width={90} marginStart="auto"></Box>
          </Box>
        </Grid>
        <Box
          color={colors.white}
          paddingY={8}
          paddingX={6}
          marginTop={2}
          marginStart={12}
          marginEnd={12}
          borderStyle="lg"
          borderRadius={4}
        >
          <Box minHeight={65}>
            <Box marginBottom={3} width="100%">
              <Text weight="bold" color={colors.black600}>
                {resources.name}
              </Text>
            </Box>
            <Box width="100%">
              <TextField
                placeholder={resources.name}
                id="name"
                value={formState.name}
                disabled={formState.loading}
                onChange={(e) => {
                  if (e.value === "" || DocumentTypeNameValidationRegex.test(e.value)) {
                    onChange("name", e.value);
                  } else {
                    setFormState &&
                      setFormState((prev) => {
                        return {
                          ...prev,
                          toastMessage: {
                            type: "error",
                            message: resources.specialCharsNotAllowed,
                          },
                        };
                      });
                  }
                }}
                errorMessage={formState.error}
              />
            </Box>
            <Box>
              <Text color={colors.neutral100}></Text>
              {formState.name?.length || 0}/{nameFieldMaxLength}
            </Box>
          </Box>
          <Box marginTop={8} minHeight={65}>
            <Box marginBottom={3} width="100%">
              <Text weight="bold" color={colors.black600}>
                {resources.description}
              </Text>
            </Box>
            <Box width="100%">
              <TextArea
                id={"description"}
                name={resources.description}
                //onBlur={handleBlur}
                placeholder={resources.description}
                disabled={formState.loading}
                value={formState.description}
                onChange={(e) => {
                  onChange("description", e.value);
                }}
                errorMessage={formState.description === "" ? resources.required : ""}
                rows={6}
              />
            </Box>
            <Box>
              <Text color={colors.neutral100}></Text>
              {formState.description?.length || 0}/{descriptionFieldMaxLength}
            </Box>
          </Box>
          <Box marginTop={8} minHeight={65}>
            <Box marginBottom={3} display="flex" width="100%">
              <Box marginEnd={3}>
                <Box display="flex" gap={3} alignItems="center">
                  <Text weight="bold" color={colors.black600}>
                    {resources.assignedRole}
                  </Text>
                  <Box zIndex={modalZIndex}>
                    <WhatsThis
                      color={colors.neutral900}
                      title={resources.roleAssignDesc}
                      clickContent={
                        <Box>
                          <Box marginBottom={5}>
                            <Text size="300" weight="bold">
                              {resources.roleAssignDesc}
                            </Text>
                          </Box>
                        </Box>
                      }
                      size="sm"
                      colorBundle={colors}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box alignItems="center" width="100%">
              <Box width="100%">
                <SelectList
                  id="roleId"
                  label=""
                  disabled={formState.loading}
                  onChange={(e) => {
                    onChange("roleToNotify", e.value);
                  }}
                  size="lg"
                  options={(rolesOptions || []).map((d) => {
                    return { label: d.name, value: d.id };
                  })}
                  value={formState.roleToNotify}
                />
              </Box>
            </Box>
          </Box>
          <Box marginTop={8} minHeight={65}>
            <Box marginBottom={3} display="flex" width="100%">
              <Box marginEnd={3}>
                <Text weight="bold" color={colors.black600}>
                  {resources.isContentLibrary}
                </Text>
              </Box>
              <Tooltip text={resources.contentLibraryDescription}>
                <Icon
                  accessibilityLabel="is content library"
                  icon="circle-info"
                  color={colors.black600}
                />
              </Tooltip>
            </Box>
            <Box alignItems="center" width="100%">
              <Switch
                disabled={formState.loading}
                switched={formState.isContentLibrary}
                onChange={() => onChange("isContentLibrary", !formState.isContentLibrary)}
                id="isContentLibrary"
              />
            </Box>
          </Box>
          {formState.toastMessage && (
            <NotificationMessage
              type={formState.toastMessage.type}
              message={formState.toastMessage.message}
              position="bottom-left"
              handleDismiss={() => setFormState({ ...formState, toastMessage: undefined })}
            />
          )}
          <Box marginTop={5} width={"100%"} display="flex">
            <Box>
              <Button
                color={"transparent"}
                onClick={() => organizationNavigate("/settings/document-groups")}
                disabled={formState.loading}
                text={resources.cancel}
              />
            </Box>
            <Box marginStart="auto" justifyContent="end">
              <Button
                color={"blue"}
                disabled={formState.loading || !formState.name || !formState.description}
                onClick={handleSubmit}
                text={resources.saveChanges}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default EditDocumentGroupPage;
