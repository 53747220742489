import { PubSubEventManager, useSubscribe } from "@prodoctivity/design-system";
import type {
  ParametersObject,
  StringTemplatePart,
  TemplateWizardDefinition,
  UpdateTemplateDefinitionCommand,
} from "@prodoctivity/shared/src/index-types";
import type { DateTime, HttpExecuteDataLinkRequest } from "@prodoctivity/types";
import { useCallback, useState } from "react";

import { blobToBase64 } from "@prodoctivity/design-system";
import { IFormValues } from "@prodoctivity/prodoctivity-form-v5/src/components/Wizard/_types";
import { confirmStringTemplatePartType } from "@prodoctivity/shared";
import { useTranslation } from "react-i18next";
import { isProduction } from "../../config";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { Services } from "../../services";

export type TemplateEditWizardEvent = {
  direction: "next" | "prev";
};

export const TemplateConfigurationWizardSteps = {
  FormDesign: 0,
  FormTesting: 1,
  Publish: 2,
};

type State = {
  wizardDefinition: TemplateWizardDefinition;
  fromDate: DateTime | undefined;
  toDate: DateTime | undefined;
  publishType: string;
  templateBlob?: Blob;
};

export function useTemplateEditWizard(
  template: Awaited<ReturnType<Services["fetchTemplateInfo"]>>,
  selectedTabIndex: number,
  eventManager: PubSubEventManager<TemplateEditWizardEvent>,
  setTabIndex: (v: number) => void,
  onFinish: (
    templateVersionId: string,
    documentTypeSent: UpdateTemplateDefinitionCommand
  ) => Promise<void>
) {
  const { t } = useTranslation();

  const { getTemplateContextSampleRequest, executeDataLink, generateDocumentsSync, getWordAsPdf } =
    useServices();
  const templateVersionId = template.info.templateVersionId;

  const [nameConfig, setNameConfig] = useState<StringTemplatePart[]>(
    confirmStringTemplatePartType(template.info.contextDefinition, template.info.nameConfig)
  );

  const [identifierConfig, setIdentifierConfig] = useState<StringTemplatePart[] | undefined>(
    template.info.identifierConfig
  );
  const [identifierCollisionForcesNewVersion, setIdentifierCollisionForcesNewVersion] =
    useState<boolean>(template.info.identifierCollisionForcesNewVersion || false);

  const [state, setState] = useState<State>({
    publishType: "",
    wizardDefinition: template.info.wizardDefinition,
    fromDate: undefined,
    toDate: undefined,
  });

  const getTemplateContextSample = useCallback(async () => {
    if (!templateVersionId) {
      return {};
    }
    const response = await getTemplateContextSampleRequest([templateVersionId]);
    return response.requestSample.contexts[0].data;
  }, [getTemplateContextSampleRequest, templateVersionId]);

  const setFromDate = useCallback((val: DateTime | undefined) => {
    setState((prev) => ({ ...prev, fromDate: val }));
  }, []);

  const setToDate = useCallback((val: DateTime | undefined) => {
    setState((prev) => ({ ...prev, toDate: val }));
  }, []);

  const setPublishType = useCallback((val: string) => {
    setState((prev) => ({ ...prev, publishType: val }));
  }, []);

  const [publishComment, setPublishComment] = useState<string>("");

  const onCommentChange = useCallback(({ value }: { value: string }) => {
    setPublishComment(value);
  }, []);

  const onTypeChange = useCallback(
    ({ value }: { value: string }) => {
      setPublishType(value);
      setFromDate(undefined);
      setToDate(undefined);
    },
    [setFromDate, setPublishType, setToDate]
  );

  const handleChangeDateTo = useCallback(
    (value: any) => {
      const dateValue = value.value; // Assuming the date is in the 'value' property
      const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;
      setToDate(timestamp);
    },
    [setToDate]
  );
  const handleChangeDate = useCallback(
    (value: any) => {
      const dateValue = value.value; // Assuming the date is in the 'value' property
      const timestamp = dateValue ? new Date(dateValue).getTime() : undefined;
      setFromDate(timestamp);
    },
    [setFromDate]
  );

  const onFormValuesChange = useCallback((values: IFormValues) => {
    setState((curr) => ({ ...curr, formValues: values }));
  }, []);

  const onWizardDefinitionChange = useCallback((wizardDefinition: TemplateWizardDefinition) => {
    setState((prevState) => ({ ...prevState, wizardDefinition: wizardDefinition }));
  }, []);

  const i18n = useCallback(
    (key: string) => {
      const value = t(key);
      if (!isProduction && typeof value === "undefined") {
        console.error(`Key "${key}" not defined in i18n resource files`);
        return key;
      }

      return value;
    },
    [t]
  );

  const generateDocument = useCallback(
    (
      formValues?: ParametersObject,
      isPreview = false
    ): Promise<
      | {
          success: boolean;
          blob?: Blob;
          documentId: string | undefined;
          documentVersionId: string | undefined;
        }
      | { success: false; errors: any[] }
    > => {
      if (!templateVersionId || !formValues) {
        return new Promise<{
          success: boolean;
          blob?: Blob;
          documentId: string | undefined;
          documentVersionId: string | undefined;
        }>((resolve) =>
          resolve({
            success: false,
            blob: new Blob(),
            documentId: undefined,
            documentVersionId: undefined,
          })
        );
      }
      setState((curr) => ({ ...curr, templateBlob: undefined, status: "generating", errors: [] }));
      return generateDocumentsSync({
        isPreview: isPreview,
        distributions: [],
        templates: [
          {
            templateVersionId: templateVersionId,
            key: templateVersionId,
          },
        ],
        contexts: [
          {
            key: templateVersionId,
            data: formValues,
            documents: [
              {
                templateKey: templateVersionId,
                documentKey: templateVersionId,
                distributions: [],
                plugins: [],
              },
            ],
          },
        ],
      })
        .then(({ docs, documentId, documentVersionId }) => {
          const documentKey = Object.keys(docs)[0];

          setState((curr) => ({
            ...curr,
            status: "idle",
            step: "document-viewer",
            templateBlob: docs[documentKey],
          }));
          return {
            success: true,
            blob: docs[documentKey],
            documentId,
            documentVersionId,
          };
        })
        .catch((ex) => {
          console.log("ex", ex);
          const message = ex.response ? ex.response.data?.errors[0].message : ex.message;
          setState((curr) => ({
            ...curr,
            status: "idle",
            step: "summary",
            toastMessage: {
              type: "error",
              message: message,
            },
          }));
          return {
            success: false,
            errors: [message],
          };
        });
    },
    [generateDocumentsSync, templateVersionId]
  );

  const onNext = useCallback(async () => {
    if (selectedTabIndex === TemplateConfigurationWizardSteps.Publish) {
      const documentTypeSent: UpdateTemplateDefinitionCommand = {
        wizardDefinition: state.wizardDefinition,
        updatedContextFields: [],
        nameConfig,
        identifierConfig: identifierConfig,
        identifierCollisionForcesNewVersion: identifierCollisionForcesNewVersion,
      };
      onFinish(templateVersionId, documentTypeSent);

      return;
    }

    setTabIndex(selectedTabIndex + 1);
  }, [
    identifierCollisionForcesNewVersion,
    nameConfig,
    onFinish,
    selectedTabIndex,
    setTabIndex,
    state.wizardDefinition,
    templateVersionId,
    identifierConfig,
  ]);

  const onPrev = useCallback(() => {
    setTabIndex(selectedTabIndex - 1);
  }, [selectedTabIndex, setTabIndex]);

  const subscribeHandler = useCallback(
    async (ev: TemplateEditWizardEvent) => {
      if (ev.direction === "next") {
        await onNext();
      } else if (ev.direction === "prev") {
        onPrev();
      }
    },
    [onNext, onPrev]
  );

  useSubscribe(eventManager, subscribeHandler);

  const execDataLink = useCallback(
    (
      dataLinkId: string,
      dataLinkConfigVersionId: string,
      inputParameters: HttpExecuteDataLinkRequest["payload"]["inputParameters"]
    ) => {
      return executeDataLink(dataLinkId, dataLinkConfigVersionId, inputParameters);
    },
    [executeDataLink]
  );

  const fetchWordAsPdf = useCallback(async () => {
    if (!state.templateBlob) {
      return { pdfDataUri: undefined };
    }
    const base64 = await blobToBase64(state.templateBlob);
    return await getWordAsPdf(base64, undefined);
  }, [state.templateBlob, getWordAsPdf]);

  const { data: pdfResponse } = useOrganizationQuery(
    `edit-template-get-word-as-pdf/${state.templateBlob ? templateVersionId : undefined}`,
    fetchWordAsPdf
  );

  return {
    state,
    pdfResponse,
    publishComment,
    eventManager,
    nameConfig,
    setNameConfig,
    identifierConfig,
    setIdentifierConfig,
    identifierCollisionForcesNewVersion,
    setIdentifierCollisionForcesNewVersion,
    generateDocument,
    onWizardDefinitionChange,
    onFormValuesChange,
    onTypeChange,
    onCommentChange,
    handleChangeDate,
    handleChangeDateTo,
    execDataLink,
    i18n,
    getTemplateContextSample,
  };
}
