import { useCallback, useMemo, useState } from "react";
import { ProfileDocumentListOption } from "../../../../components/DocumentBookmarkTags/hook";

export type EditLabels = {
  editMode: boolean;
  index: number;
};

export type WarningDeleteLabelLayerMode = "deleteLabel" | "deleteAllDocuments";

type ProfileDocumentLabelsListProps = {
  profileDocumentLabelsList: ProfileDocumentListOption[];
  handleUpdateProfileLabels: (
    oldDocumentLabel: string | undefined,
    newDocumentLabel: string
  ) => void;
  handleDeleteLabel: ((label: string) => void) | undefined;
  selectedLabels: ProfileDocumentListOption[];
};

export function useProfileDocumentLabelsList({
  profileDocumentLabelsList,
  handleUpdateProfileLabels,
  handleDeleteLabel,
  selectedLabels,
}: ProfileDocumentLabelsListProps) {
  const [labelIndex, setLabelIndex] = useState<number>(0);
  const handleSetLabelIndex = useCallback(
    (index: number) => {
      setLabelIndex(index);
    },
    [setLabelIndex]
  );
  const [editMode, setEditMode] = useState<EditLabels>({
    editMode: false,
    index: 0,
  });

  const handleSetEditMode = useCallback(
    (editMode: EditLabels) => {
      setEditMode(editMode);
    },
    [setEditMode]
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const filteredProfileDocumentLabelsList = useMemo(
    () =>
      profileDocumentLabelsList.filter(
        (label) =>
          label.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
          selectedLabels.some((selectedLabel) => selectedLabel.value === label.value)
      ),
    [profileDocumentLabelsList, searchTerm, selectedLabels]
  );

  const currentLabel = useMemo(
    () => profileDocumentLabelsList[labelIndex]?.label || "",
    [profileDocumentLabelsList, labelIndex]
  );

  const [deleteLabelWarning, setDeleteLabelWarning] = useState<{
    show: boolean;
    mode: WarningDeleteLabelLayerMode;
  }>({
    show: false,
    mode: "deleteLabel",
  });

  const setDeleteLabelWarningState = useCallback(
    (deleteLabelWarning: { show: boolean; mode: WarningDeleteLabelLayerMode }) => {
      setDeleteLabelWarning(deleteLabelWarning);
    },
    [setDeleteLabelWarning]
  );

  const [addMode, setAddMode] = useState<boolean>(false);
  const [newLabel, setNewLabel] = useState<string>("");
  const handleSetNewLabel = useCallback(
    (label: string) => {
      setNewLabel(label);
    },
    [setNewLabel]
  );

  const notSelectedProfileDocumentLabelsList = useMemo(() => {
    return profileDocumentLabelsList
      .filter(
        (label) => !selectedLabels.some((selectedLabel) => selectedLabel.value === label.value)
      )
      .slice(0, 4);
  }, [profileDocumentLabelsList, selectedLabels]);

  const handleCascadeOptionsPopoverClick = (key: string) => {
    switch (key) {
      case "edit":
        setAddMode(false);
        setNewLabel("");
        setEditMode({ editMode: true, index: labelIndex });

        break;
      case "delete_":
        setEditMode({ editMode: false, index: labelIndex });
        setAddMode(false);
        setDeleteLabelWarningState({
          show: true,
          mode: "deleteLabel",
        });
        break;
      case "deleteAll_":
        setEditMode({ editMode: false, index: labelIndex });
        setAddMode(false);
        setDeleteLabelWarningState({
          show: true,
          mode: "deleteAllDocuments",
        });
        break;
      default:
        break;
    }
  };

  const handleSaveChanges = useCallback(() => {
    if (editMode.editMode) {
      handleUpdateProfileLabels(profileDocumentLabelsList[editMode.index].label, newLabel);
    } else {
      handleUpdateProfileLabels(undefined, newLabel);
    }
    setEditMode({ editMode: false, index: 0 });
  }, [editMode, handleUpdateProfileLabels, profileDocumentLabelsList, newLabel, setEditMode]);

  const handleDeleteMyProfileLabel = (label: string) => {
    if (handleDeleteLabel) {
      handleDeleteLabel(label);
    }
  };

  const handleBlur = useCallback(
    ({
      currentTarget,
      relatedTarget,
    }: {
      currentTarget: EventTarget | null;
      relatedTarget: EventTarget | null;
    }) => {
      if (
        currentTarget instanceof Node &&
        relatedTarget instanceof Node &&
        currentTarget.contains(relatedTarget)
      ) {
        return;
      }
      setAddMode(false);
    },
    [setAddMode]
  );

  const addList = useCallback(() => {
    if (
      !profileDocumentLabelsList.some(
        (label) => label.label.trim() === newLabel.trim() || newLabel.trim() === ""
      )
    ) {
      handleSaveChanges();
      setAddMode(false);
    }
  }, [handleSaveChanges, setAddMode, newLabel, profileDocumentLabelsList]);
  return {
    addList,
    labelIndex,
    handleSetLabelIndex,
    editMode,
    handleSetEditMode,
    searchTerm,
    setSearchTerm,
    filteredProfileDocumentLabelsList,
    currentLabel,
    deleteLabelWarning,
    setDeleteLabelWarningState,
    addMode,
    setAddMode,
    newLabel,
    handleSetNewLabel,
    handleCascadeOptionsPopoverClick,
    handleSaveChanges,
    handleDeleteMyProfileLabel,
    notSelectedProfileDocumentLabelsList,
    handleBlur,
  };
}
