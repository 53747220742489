import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import DocumentViewerLookupVersionPage from "./DocumentViewerLookupVersionPage";
import { DocumentViewerPanelKeysSchema } from "./DocumentViewerPageFilter";

type Props = {
  defaultPanel: DocumentViewerPanelKeys | undefined;
};

const documentViewer$Schema = z
  .object({
    defaultPanel: DocumentViewerPanelKeysSchema.optional(),
  })
  .strip();
function urlParamsToFilter(urlParams: Record<string, string | null>): Props {
  const filter = documentViewer$Schema.safeParse(urlParams);

  if (!filter.success) {
    return {
      defaultPanel: "data",
    };
  }

  const result: Props = {
    defaultPanel: filter.data.defaultPanel,
  };

  return result;
}

const DocumentViewerLookupVersionPageFilter: FunctionComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const filter = urlParamsToFilter({
    defaultPanel: params.get("defaultPanel"),
  });

  return <DocumentViewerLookupVersionPage defaultPanel={filter.defaultPanel} />;
};

export default DocumentViewerLookupVersionPageFilter;
