import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const RestoreDeletedDocumentSvgIcon: FunctionComponent<Props> = ({
  width = 35,
  height = 41,
  color,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6948_103292)">
        <path
          d="M21.7759 37.1879C21.047 37.1879 20.4301 36.6334 20.3741 35.9126L19.8133 28.7782C19.7572 28.0204 20.3367 27.355 21.103 27.2811C21.8881 27.2256 22.5423 27.7986 22.6171 28.5564L23.1778 35.6908C23.2339 36.4486 22.6544 37.1139 21.8881 37.1879C21.8507 37.1879 21.8133 37.1879 21.7759 37.1879Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M27.0281 37.3354C26.2618 37.3354 25.6262 36.707 25.6449 35.9307L25.7197 28.6115C25.7197 27.8537 26.3178 27.1698 27.1403 27.2438C27.9066 27.2438 28.5421 27.8722 28.5234 28.6485L28.4487 35.9677C28.4487 36.7255 27.8132 37.3354 27.0468 37.3354H27.0281Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M32.224 37.3358C32.224 37.3358 32.3175 37.3358 32.3548 37.3358C33.0651 37.3358 33.6819 36.7998 33.7567 36.0789L34.4483 28.8152C34.5231 28.0574 33.9623 27.3735 33.1773 27.2996C32.4109 27.2256 31.7193 27.7801 31.6446 28.5564L30.953 35.8202C30.8782 36.578 31.439 37.2618 32.224 37.3358Z"
          fill="#5A5A5A"
        />
        <path
          d="M43.9998 9.9438L38.2054 7.0235C38.3923 6.85716 38.5605 6.65385 38.6913 6.41357C39.2334 5.36005 38.8035 4.06624 37.7381 3.53024L33.925 1.60802C32.8596 1.07202 31.5512 1.49713 31.0091 2.55065C30.8783 2.79093 30.8222 3.04969 30.8035 3.28996L25.0091 0.388154C23.2147 -0.517506 21.0091 0.203325 20.0932 1.9592L19.1026 3.86293C18.1867 5.63729 18.9156 7.81827 20.71 8.72393L39.7007 18.2796C40.224 18.5383 40.7848 18.6677 41.3455 18.6677C41.7194 18.6677 42.1119 18.6123 42.4857 18.4829C43.4203 18.1872 44.168 17.5403 44.6166 16.69L45.6072 14.7863C46.5231 13.012 45.8128 10.831 43.9998 9.92531V9.9438ZM43.1026 13.548L42.1119 15.4517C42.0184 15.655 41.8315 15.8029 41.6259 15.8768C41.4016 15.9507 41.1773 15.9322 40.9717 15.8213L21.9811 6.26571C21.5698 6.06239 21.4016 5.54487 21.6072 5.13825L22.5979 3.23452C22.8035 2.82789 23.3082 2.66155 23.7381 2.86486L42.7287 12.4205C43.1399 12.6238 43.3082 13.1413 43.1026 13.548Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M39.5137 21.8467H14.7474C14.3548 21.8467 13.981 22.013 13.7193 22.2903C13.4577 22.5675 13.3268 22.9557 13.3642 23.3438L15.3829 46.7431C15.439 47.4639 16.0558 48.0184 16.7848 48.0184H37.5324C38.2614 48.0184 38.8595 47.4639 38.9343 46.7431L40.953 23.3438C40.9904 22.9557 40.8595 22.5675 40.5978 22.2903C40.3362 22.013 39.9623 21.8467 39.5698 21.8467H39.5137ZM36.2053 45.246H18.0371L16.2614 24.6191H37.981L36.2053 45.246Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M25.7194 18.2797C25.7194 18.2797 25.5886 18.1134 25.3643 17.8546C20.1306 11.7923 11.5699 9.94397 4.18671 13.0676L6.24278 7.9663C6.52315 7.24547 6.18671 6.45071 5.45773 6.17347C4.74745 5.89622 3.92502 6.22892 3.64465 6.94975L0.093248 15.6921C-0.0375931 16.0248 -0.0375931 16.413 0.093248 16.7457C0.242781 17.0784 0.523155 17.3556 0.859603 17.485L9.73811 20.9782C10.4484 21.2555 11.2708 20.9228 11.5512 20.202C11.8316 19.4996 11.4951 18.6864 10.7661 18.4091L4.50446 15.9509C4.50446 15.9509 4.50446 15.9509 4.48577 15.9509C10.8596 12.8273 18.5792 14.2874 23.2147 19.666C23.383 19.8508 23.4577 19.9617 23.4764 19.9802C23.7568 20.3313 24.168 20.5162 24.5792 20.5162C24.8783 20.5162 25.1774 20.4238 25.439 20.2389C26.0559 19.7769 26.168 18.9082 25.7007 18.2982L25.7194 18.2797Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6948_103292">
          <rect width="46" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
