import {
  AnimatePresence,
  AnimationControls,
  MotionProps,
  Target,
  TargetAndTransition,
  Transition,
  VariantLabels,
  motion,
} from "framer-motion";
import { Box as GestaltBox, BoxProps as GestaltBoxProps, UnsignedUpTo12 } from "gestalt";
import {
  CSSProperties,
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
  Ref,
  forwardRef,
  useMemo,
  useState,
} from "react";
import { ColorConfig, calculateColor } from "../utils";

import { useColors } from "./ColorSchemeProvider";

export type AnimationSet = {
  animate?: boolean | VariantLabels | AnimationControls | TargetAndTransition | undefined;
  initial?: boolean | VariantLabels | Target | undefined;
  transition?: Transition | undefined;
  exit?: VariantLabels | TargetAndTransition | undefined;
  whileInView?: VariantLabels | TargetAndTransition | undefined;
  viewport?: MotionProps["viewport"] | undefined;
  whileHover?: VariantLabels | TargetAndTransition | undefined;
};

type BoxProps = Omit<GestaltBoxProps, "color" | "position" /*| "dangerouslySetInlineStyle" */> & {
  color?: ColorConfig;
  hoverColor?: ColorConfig;
  position?: GestaltBoxProps["position"] | "sticky";
  borderRadius?: 4 | 6 | 12 | 32 | 48;
  borderColor?: ColorConfig;
  gap?: number;
  paddingTop?: UnsignedUpTo12 | undefined;
  paddingRight?: UnsignedUpTo12 | undefined;
  paddingBottom?: UnsignedUpTo12 | undefined;
  paddingLeft?: UnsignedUpTo12 | undefined;
  flexValue?: number | undefined;
  flexBasis?: string | number | undefined;
};

type BoxWithAnimationsProps = BoxProps & {
  children?: ReactNode;
  animationSet: AnimationSet | undefined;
  animatePresence?: boolean;
  condition?: boolean;
};

export const Box: FunctionComponent<BoxProps> = (props) => {
  const {
    color,
    hoverColor,
    dangerouslySetInlineStyle,
    borderColor,
    borderRadius,
    flexValue: _flexValue,
    ...rest
  } = props;
  const {
    style,
    onMouseEnter,
    onMouseLeave,
  }: {
    style: { [key: string]: string | number | undefined };
    onMouseEnter: MouseEventHandler<HTMLDivElement> | undefined;
    onMouseLeave: MouseEventHandler<HTMLDivElement> | undefined;
  } = useBoxProps(hoverColor, color, borderRadius, dangerouslySetInlineStyle, props, borderColor);
  const {
    paddingTop: _paddingTop,
    paddingBottom: _paddingBottom,
    paddingLeft: _paddingLeft,
    paddingRight: _paddingRight,
    ...safeRest
  } = rest;

  return (
    <GestaltBox
      dangerouslySetInlineStyle={{
        __style: style,
      }}
      {...safeRest}
      position={rest.position === "sticky" ? undefined : rest.position}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export const BoxWithRef = forwardRef((props: BoxProps, ref?: Ref<HTMLDivElement>) => {
  const { color, hoverColor, dangerouslySetInlineStyle, borderColor, borderRadius, ...rest } =
    props;
  const {
    style,
    onMouseEnter,
    onMouseLeave,
  }: {
    style: { [key: string]: string | number | undefined };
    onMouseEnter: MouseEventHandler<HTMLDivElement> | undefined;
    onMouseLeave: MouseEventHandler<HTMLDivElement> | undefined;
  } = useBoxProps(hoverColor, color, borderRadius, dangerouslySetInlineStyle, props, borderColor);
  return (
    <GestaltBox
      ref={ref}
      dangerouslySetInlineStyle={{
        __style: style,
      }}
      {...rest}
      position={rest.position === "sticky" ? undefined : rest.position}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
});

export const BoxWithAnimations = forwardRef(
  (props: BoxWithAnimationsProps, ref?: Ref<HTMLDivElement>) => {
    const {
      color,
      hoverColor,
      dangerouslySetInlineStyle,
      borderColor,
      borderRadius,
      children,
      animationSet,
      width,
      height,
      display,
      animatePresence,
      condition,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
    } = props;
    const {
      style,
      onMouseEnter,
      onMouseLeave,
    }: {
      style: { [key: string]: string | number | undefined };
      onMouseEnter: MouseEventHandler<HTMLDivElement> | undefined;
      onMouseLeave: MouseEventHandler<HTMLDivElement> | undefined;
    } = useBoxProps(hoverColor, color, borderRadius, dangerouslySetInlineStyle, props, borderColor);
    return (
      <>
        {animationSet && (
          <>
            {animatePresence ? (
              <AnimatePresence mode="wait">
                {condition && (
                  <motion.div
                    ref={ref}
                    initial={animationSet.initial}
                    animate={animationSet.animate}
                    style={{
                      ...style,
                      width,
                      height,
                      display,
                      paddingTop,
                      paddingRight,
                      paddingBottom,
                      paddingLeft,
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    transition={animationSet.transition}
                    whileInView={animationSet.whileInView}
                    whileHover={animationSet.whileHover}
                    viewport={animationSet.viewport}
                    exit={animationSet.exit}
                  >
                    {children}
                  </motion.div>
                )}
              </AnimatePresence>
            ) : (
              <motion.div
                ref={ref}
                initial={animationSet.initial}
                animate={animationSet.animate}
                whileInView={animationSet.whileInView}
                whileHover={animationSet.whileHover}
                viewport={animationSet.viewport}
                style={{
                  ...style,
                  width,
                  height,
                  display,
                  paddingTop,
                  paddingRight,
                  paddingBottom,
                  paddingLeft,
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                transition={animationSet.transition}
              >
                {children}
              </motion.div>
            )}
          </>
        )}
      </>
    );
  }
);

function useBoxProps(
  hoverColor: ColorConfig | undefined,
  color: ColorConfig | undefined,
  borderRadius: number | undefined,
  dangerouslySetInlineStyle:
    | { __style: { [key: string]: string | number | undefined } }
    | undefined,
  props: BoxProps,
  borderColor?: ColorConfig
) {
  const { paddingBottom, paddingLeft, paddingRight, paddingTop } = props;
  const { colors } = useColors();
  const [hColor, setHColor] = useState<ColorConfig | undefined>();

  const onMouseEnter: MouseEventHandler<HTMLDivElement> | undefined = useMemo(() => {
    if (hoverColor) {
      return () => {
        setHColor(hoverColor);
      };
    }
    return undefined;
  }, [hoverColor]);

  const onMouseLeave: MouseEventHandler<HTMLDivElement> | undefined = useMemo(() => {
    if (hoverColor) {
      return () => {
        setHColor(undefined);
      };
    }
    return undefined;
  }, [hoverColor]);

  const style: Record<string, string | number | undefined> = useMemo(() => {
    const preStyle: CSSProperties = {
      backgroundColor: hColor ? calculateColor(hColor) : color ? calculateColor(color) : undefined,
    };
    if (props.display === "flex") {
      if (props.gap !== undefined) {
        preStyle.gap = props.gap * 4;
      }
      if (props.flexValue !== undefined) {
        preStyle.flex = props.flexValue;
      }
      if (props.flexBasis) {
        preStyle.flexBasis = props.flexBasis;
      }
    }
    if (paddingTop) {
      preStyle.paddingTop = paddingTop * 4;
    }
    if (paddingRight) {
      preStyle.paddingRight = paddingRight * 4;
    }
    if (paddingBottom) {
      preStyle.paddingBottom = paddingBottom * 4;
    }
    if (paddingLeft) {
      preStyle.paddingLeft = paddingLeft * 4;
    }
    if (borderRadius) {
      const borderColorValue = borderColor
        ? calculateColor(borderColor)
        : calculateColor(colors.neutral500);
      const isBorderColorNeutral50 = borderColor === colors.neutral50;

      if (isBorderColorNeutral50) {
        preStyle.borderRadius = borderRadius;
        preStyle.border = `1px solid transparent`;
      } else {
        preStyle.borderRadius = borderRadius;
        preStyle.border = `1px solid ${borderColorValue}`;
      }
    }
    if (dangerouslySetInlineStyle) {
      return { ...dangerouslySetInlineStyle.__style, ...preStyle };
    }

    if (props.position === "sticky") {
      preStyle.position = props.position;
    }

    return preStyle as Record<string, string | number | undefined>;
  }, [
    hColor,
    color,
    props.display,
    props.gap,
    props.position,
    props.flexValue,
    props.flexBasis,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    borderRadius,
    dangerouslySetInlineStyle,
    borderColor,
    colors.neutral500,
    colors.neutral50,
  ]);
  return { style, onMouseEnter, onMouseLeave };
}
