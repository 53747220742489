import { Box, SelectList, changeLanguage } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useState } from "react";

import { OrganizationUserLanguage$Schema } from "@prodoctivity/shared";
import type { OrganizationUserLanguage } from "@prodoctivity/shared/src/index-types";
import { useTranslation } from "react-i18next";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";

export const LanguageSwitcher: FunctionComponent = () => {
  const { resources, moment } = useAppTranslation();
  const { i18n } = useTranslation();
  const { setTempLanguage } = useServices();
  const [language, setLanguage] = useState<OrganizationUserLanguage>(() => {
    return OrganizationUserLanguage$Schema.parse(i18n.language);
  });
  const options = useMemo(() => {
    return [
      {
        value: "en",
        label: resources.languages.english,
      },
      {
        value: "es",
        label: resources.languages.spanish,
      },
      {
        value: "nl",
        label: resources.languages.dutch,
      },
      {
        value: "pt",
        label: resources.languages.portuguese,
      },
    ];
  }, [resources]);
  const onChange = useCallback(
    ({ value }: { value: string }) => {
      const lang = OrganizationUserLanguage$Schema.parse(value);
      setLanguage((prev) => {
        if (prev !== lang) {
          setTempLanguage(lang);
        }

        return lang;
      });
      changeLanguage(i18n, moment)(lang);

      return lang;
    },
    [i18n, moment, setTempLanguage]
  );

  return (
    <Box>
      <Box display="flex" gap={5} direction="row">
        <Box margin={1}>
          <SelectList
            options={options}
            value={language}
            id="current_language"
            onChange={onChange}
          />
        </Box>
      </Box>
    </Box>
  );
};
