import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const ViewerFullScreenIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 17.3333C8.13333 17.3333 8 16.9333 8 12.6667V8H12.6667C16.9333 8 17.3333 8.13333 17.3333 9.33333C17.3333 10.5333 16.9333 10.6667 10.6667 10.6667V14C10.6667 16.9333 10.5333 17.3333 9.33333 17.3333Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <path
        d="M26 10.6667C23.1333 10.6667 22.6667 10.5333 22.6667 9.33333C22.6667 8.13333 23.1333 8 32 8V12.6667C32 16.9333 31.8667 17.3333 30.6667 17.3333C29.5333 17.3333 29.3333 16.9333 29.3333 10.6667H26Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <path
        d="M8 32V27.3333C8 23.1333 8.13333 22.6667 9.33333 22.6667C10.5333 22.6667 10.6667 23.1333 10.6667 29.3333H14C16.9333 29.3333 17.3333 29.5333 17.3333 30.6667C17.3333 31.8667 16.9333 32 8 32Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <path
        d="M29.3333 29.3333V26C29.3333 23.1333 29.5333 22.6667 30.6667 22.6667C31.8667 22.6667 32 23.1333 32 32H27.3333C23.1333 32 22.6667 31.8667 22.6667 30.6667C22.6667 29.5333 23.1333 29.3333 29.3333 29.3333Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
    </svg>
  );
};
