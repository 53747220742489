import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const TLineIndentationIconSvg: FunctionComponent<Props> = ({
  width = 40,
  height = 40,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="19" width="2" height="40" fill="#CACBCE" />
      <rect
        x="20"
        y="21"
        width="2"
        height="19.5"
        rx="1"
        transform="rotate(-90 20 21)"
        fill="#CACBCE"
      />
    </svg>
  );
};
