import {
  Box,
  Button,
  ComboBox,
  RenderedControl,
  Skeleton,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction, useCallback } from "react";
import {
  SmallAccordion,
  SmallAccordionProps,
} from "../../DocumentCollection/Steps/Components/SmallAccordion";
import { DocumentTypeState, useSecurityFieldForm } from "../hooks";

import { valueTypeToFormValue } from "@prodoctivity/prodoctivity-form-v5/src/components/_lib/utils";
import type { SecurityField } from "@prodoctivity/types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { MissingKeysIconSvg } from "../../../../svg/MissingKeysIconSvg";
import { SelectedItemTag } from "../../Users/Actions/RolesTab";

type Props = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  isLoading: boolean;
  formState: DocumentTypeState;
  roles: Array<{ id: string; name: string }>;
  setFormState: Dispatch<SetStateAction<DocumentTypeState>>;
  addNewSecurityField: () => void;
  removeSecurityKeyword: SmallAccordionProps["remove"];
};

export const SecurityKeywordsTab: FunctionComponent<Props> = ({
  roles,
  resources,
  isLoading,
  formState,
  setFormState,
  addNewSecurityField,
  removeSecurityKeyword,
}) => {
  const { colors } = useColors();
  return (
    <Skeleton height={400} show={isLoading}>
      <Box
        padding={3}
        color={colors.white}
        display="flex"
        direction="column"
        flex="grow"
        width="100%"
      >
        <Box display="flex" justifyContent="end">
          <Button
            onClick={addNewSecurityField}
            color="blue"
            text={resources.add}
            iconEnd="add-circle"
          />
        </Box>
        {formState.securityFields.length === 0 && (
          <Box display="flex" direction="column" justifyContent="center" alignItems="center">
            <MissingKeysIconSvg height={300} width={200} />
            <Text weight="bold" color={colors.subtle}>
              {resources.documentTypes.missingSecurityKeys}
            </Text>
          </Box>
        )}
        {formState.securityFields.map((securityField, index) => (
          <Box marginTop={3} key={`securityField_${index}`} flex="grow" borderRadius={6}>
            <SecurityFieldForm
              roles={roles}
              resources={resources}
              index={index}
              isLoading={isLoading}
              securityField={securityField}
              formState={formState}
              onRemove={removeSecurityKeyword}
              setFormState={setFormState}
            />
          </Box>
        ))}
      </Box>
    </Skeleton>
  );
};

type SecurityFieldFormProps = {
  roles: Array<{ id: string; name: string }>;
  resources: ReturnType<typeof useAppTranslation>["resources"];
  index: number;
  isLoading: boolean;
  securityField: SecurityField;
  onRemove: SmallAccordionProps["remove"];
  formState: DocumentTypeState;
  setFormState: Dispatch<SetStateAction<DocumentTypeState>>;
};

const SecurityFieldForm: FunctionComponent<SecurityFieldFormProps> = ({
  roles,
  resources,
  index,
  isLoading,
  securityField,
  onRemove,
  formState,
  setFormState,
}) => {
  const {
    open,
    setOpen,

    fields,
    moment,
    accordionTitle,
    dataElementInfo,

    filterRoles,
    setFilterRoles,
    filteredRoles,
    selectedRoles,
    onSelectRole,
    onRemoveRole,
  } = useSecurityFieldForm({ currentIndex: index, roles, formState, setFormState });

  const { colors } = useColors();

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <SmallAccordion
      resources={resources}
      label={accordionTitle}
      isActive={open}
      currIndex={index}
      setActive={setOpen}
      remove={onRemove}
    >
      <Box padding={4} color={colors.neutral200}>
        <Box display="flex" alignItems="center" gap={3}>
          <Box minWidth={105}>
            <Text color={colors.subtle}>{resources.field}:</Text>
          </Box>
          <Box flex="grow">
            <ComboBox
              id={"fieldName"}
              label=""
              disabled={isLoading}
              inputValue={fields.find((f) => f.value === securityField.fieldName)?.label}
              placeholder={resources.field}
              onSelect={(e) =>
                setFormState((prev) => {
                  const securityFields = prev.securityFields;
                  securityFields[index].fieldName = e.item.value;
                  return { ...prev, securityFields };
                })
              }
              options={fields}
            />
          </Box>
          <Box>
            <Text>{`${resources.equals}`.toLocaleLowerCase()}</Text>
          </Box>
          <Box flex="grow">
            {dataElementInfo && (
              <RenderedControl
                instanceFullPath={""}
                contextField={dataElementInfo.contextField}
                typeValue={dataElementInfo.typeValue}
                onCommitChange={(typeValue) =>
                  setFormState((prev) => {
                    const securityFields = prev.securityFields;
                    securityFields[index] = {
                      ...securityFields[index],
                      ...valueTypeToFormValue(typeValue, moment),
                    };
                    return { ...prev, securityFields };
                  })
                }
                disabled={false}
                error={undefined}
                isFocused={false}
                setIsFocused={(v) => console.log(v)}
                onBlur={console.log}
                getContextValueAsString={(fullPath: string) => fullPath}
                moment={moment}
                resources={resources}
                componentBreakpoint={"small"}
              />
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={3} marginTop={5}>
          <Box minWidth={105}>
            <Text color={colors.subtle}>
              {resources.userManagementAndRoles.userRoles.availableRoles}:
            </Text>
          </Box>
          <Box flex="grow">
            <ComboBox
              id={"availableRoles"}
              label=""
              disabled={isLoading}
              inputValue={filterRoles || ""}
              placeholder={resources.userManagementAndRoles.userRoles.availableRoles}
              onClear={() => setFilterRoles(undefined)}
              onChange={(e) => setFilterRoles(e.value)}
              onSelect={(e) => onSelectRole(e.item.value)}
              options={filteredRoles}
            />
          </Box>
        </Box>
        <Box display="flex" gap={3} marginTop={5}>
          <Box minWidth={105}>
            <Text color={colors.subtle}>
              {resources.userManagementAndRoles.userRoles.selectedRoles}:
            </Text>
          </Box>
          <Box
            width={"100%"}
            height={"fit-content"}
            minHeight={120}
            display="flex"
            paddingX={2}
            borderRadius={4}
            borderStyle="lg"
            alignContent="start"
            wrap={true}
          >
            {selectedRoles.map((role) => (
              <SelectedItemTag
                key={`${index}_${role.value}`}
                label={role.label}
                value={role.value}
                onRemove={() => onRemoveRole(role.value)}
              />
            ))}
          </Box>
        </Box>
        <Box display="flex" direction="row">
          <Box display="flex" flex="grow" />
          <Box display="flex" flex="shrink" padding={2}>
            <Button onClick={close} text={resources.close} />
          </Box>
        </Box>
      </Box>
    </SmallAccordion>
  );
};
