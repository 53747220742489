import { ColorConfig, calculateColor } from "../utils";
import React, { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";

function isNotNullish(val: unknown): boolean {
  return val !== null && val !== undefined;
}

const defaultHeadingLevels = {
  "100": 6,
  "200": 5,
  "300": 4,
  "400": 3,
  "500": 2,
  "600": 1,
};

type AccessibilityLevel = 1 | 2 | 3 | 4 | 5 | 6 | "none";
type Size = "100" | "200" | "300" | "400" | "500" | "600";

type Props = PropsWithChildren<{
  /**
   * Allows you to override the default heading level for the given `size`.
   */
  accessibilityLevel?: AccessibilityLevel;

  /**
   * The color of the text. See [Text colors example](https://gestalt.pinterest.systems/foundations/design_tokens#Text-color) for more details.
   */
  color: ColorConfig;

  /**
   * A unique identifier for the element.
   */
  id?: string;
  /**
   * Visually truncate the text to the specified number of lines. This also adds the `title` attribute if `children` is a string, which displays the full text on hover in most browsers. See [Truncation example](https://gestalt.pinterest.systems/web/heading#Overflow-and-truncation) for more details.
   */
  lineClamp?: number;

  /**
   * The font size of the text. See [Sizes example](https://gestalt.pinterest.systems/web/heading#Size) for more details.
   * The sizes are based on our [font-size design tokens](https://gestalt.pinterest.systems/foundations/design_tokens#Font-size).
   */
  size?: Size;
}>;

export function Heading({
  accessibilityLevel,
  children,
  color,
  lineClamp,
  id,
  size = "600",
}: Props): JSX.Element {
  const c = calculateColor(color);

  // const cs = cx(
  //   styles.Heading,
  //   typography[`fontSize${size}`],
  //   color && semanticColors.includes(color) && colors[`${color}Text`],
  //   align === "center" && typography.alignCenter,
  //   align === "justify" && typography.alignJustify,
  //   align === "start" && typography.alignStart,
  //   align === "end" && typography.alignEnd,
  //   align === "forceLeft" && typography.alignForceLeft,
  //   align === "forceRight" && typography.alignForceRight,
  //   overflow === "breakWord" && typography.breakWord,
  //   isNotNullish(lineClamp) && typography.lineClamp
  // );

  const headingLevel = accessibilityLevel || defaultHeadingLevels[size];
  const style: CSSProperties = {
    marginBottom: 0,
    marginTop: 0,
    color: c,
  };
  let newProps: HTMLAttributes<HTMLDivElement> = {
    style,
  };
  if (id) {
    newProps = { ...newProps, id };
  }
  if (isNotNullish(lineClamp) && typeof children === "string") {
    style.WebkitLineClamp = lineClamp;
    newProps = {
      ...newProps,
      title: children,
    };
  }

  const Tag = headingLevel === "none" ? "div" : `h${headingLevel}`;

  return <Tag {...newProps}>{children}</Tag>;
}
