import { Box, DynamicIconButton, ProDoctivityColorBundle, Text } from "@prodoctivity/design-system";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import type { DashboardConfigRow } from "@prodoctivity/types";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { DashboardConfigColumnElement } from "./DashboardConfigColumnElement";
import { DashboardElementImplementation, DashboardParametersTypeSet } from "./hooks";

type Props = {
  row: DashboardConfigRow;
  column: DashboardElementImplementation<DashboardParametersTypeSet>[];
  index: number;
  colIndex: number;
  newColumnId: number | null;
  setNewColumnId: Dispatch<SetStateAction<number | null>>;
  openListOfWidgets: {
    isListOfWidgetsOpen: boolean;
    rowIndex: number | null;
    colIndex: number | null;
    addToSidebar: boolean;
  };
  allowEditionMode: boolean;
  handleMoveColumnItemVertically: (
    move: "up" | "down",
    rowIndex: number,
    colIndex: number,
    itemIndex: number
  ) => void;
  mainContentFunctionsBundle: ((parametersObject?: any) => void)[];
  mainContentEllipsisOptions: string[];
  handleOpenListOfWidgets: ({
    rowIndex,
    colIndex,
    addToSidebar,
  }: {
    rowIndex: number | null;
    colIndex: number | null;
    addToSidebar: boolean;
  }) => void;
  resources: {
    addNew: string;
  };
  colors: ProDoctivityColorBundle;
  elementParametersOnChange: (
    params: ParametersObject,
    setOfIndexes:
      | {
          rowIndex: number;
          colIndex: number;
          itemIndex: number;
        }
      | undefined,
    sidebarItemIndex: number | undefined
  ) => void;
};

export const DashboardConfigColumns: FunctionComponent<Props> = ({
  row,
  column,
  index,
  colIndex,
  newColumnId,
  setNewColumnId,
  openListOfWidgets,
  allowEditionMode,
  handleMoveColumnItemVertically,
  mainContentFunctionsBundle,
  mainContentEllipsisOptions,
  handleOpenListOfWidgets,
  elementParametersOnChange,
  resources,
  colors,
}) => {
  return (
    <Box
      width={"100%"}
      height={"fit-content"}
      display="flex"
      direction="column"
      key={`Row#${index}_Column#${colIndex}`}
      paddingBottom={4}
    >
      {!allowEditionMode ? null : (
        <Box
          width={"100%"}
          minHeight={60}
          display="flex"
          justifyContent="end"
          paddingX={2}
          paddingY={2}
        >
          <Box
            width={"fit-content"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Text size="200" color={colors.neutral700}>
              {resources.addNew}
            </Text>
            <DynamicIconButton
              buttonName={"addItem"}
              handleFunction={() => {
                handleOpenListOfWidgets({
                  rowIndex: index,
                  colIndex: colIndex,
                  addToSidebar: false,
                });
              }}
              statefulCondition={
                openListOfWidgets.isListOfWidgetsOpen &&
                colIndex === openListOfWidgets.colIndex &&
                index === openListOfWidgets.rowIndex
              }
              selected={undefined}
            ></DynamicIconButton>
          </Box>
        </Box>
      )}

      {column.map((rowItem, subIndex) => {
        return (
          <DashboardConfigColumnElement
            allowEditionMode={allowEditionMode}
            colIndex={colIndex}
            column={column}
            handleMoveColumnItemVertically={handleMoveColumnItemVertically}
            handleOpenListOfWidgets={handleOpenListOfWidgets}
            index={index}
            mainContentEllipsisOptions={mainContentEllipsisOptions}
            mainContentFunctionsBundle={mainContentFunctionsBundle}
            newColumnId={newColumnId}
            row={row}
            rowItem={rowItem}
            setNewColumnId={setNewColumnId}
            subIndex={subIndex}
            key={`Row#${index}_Column#${colIndex}_Item#${subIndex}`}
            elementParametersOnChange={elementParametersOnChange}
          />
        );
      })}
    </Box>
  );
};
