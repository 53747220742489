import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const ViewerSelectToolIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3337 26.667H26.667V21.3337H29.3337V26.667H34.667V29.3337H29.3337V34.667H26.667V29.3337H21.3337V26.667ZM18.667 26.667V29.3337H16.0003V26.667H18.667ZM13.3337 26.667V29.3337H10.667V26.667H13.3337ZM29.3337 16.0003V18.667H26.667V16.0003H29.3337ZM29.3337 10.667V13.3337H26.667V10.667H29.3337ZM24.0003 10.667V13.3337H21.3337V10.667H24.0003ZM18.667 10.667V13.3337H16.0003V10.667H18.667ZM13.3337 10.667V13.3337H10.667V10.667H13.3337ZM13.3337 21.3337V24.0003H10.667V21.3337H13.3337ZM13.3337 16.0003V18.667H10.667V16.0003H13.3337Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
    </svg>
  );
};
