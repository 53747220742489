import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const BankCustomerSvgIcon: FunctionComponent<Props> = ({
  width = 101,
  height = 101,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_7972_113993)">
        <rect
          width="93"
          height="93"
          transform="translate(4)"
          fill="url(#paint0_linear_7972_113993)"
        />
        <g filter="url(#filter1_d_7972_113993)">
          <rect width="81" height="81" transform="translate(10 6)" fill="#D4E0F7" />
          <g clipPath="url(#clip0_7972_113993)">
            <path
              d="M75.1388 14.5H35.4277C34.952 14.5 34.5664 14.8857 34.5664 15.3615V67.2385C34.5664 67.7143 34.952 68.1 35.4277 68.1H75.1388C75.6145 68.1 76.0001 67.7143 76.0001 67.2385V15.3615C76.0001 14.8857 75.6145 14.5 75.1388 14.5Z"
              fill="url(#paint1_linear_7972_113993)"
            />
            <path
              d="M70.7403 19.3926H31.0292C30.5536 19.3926 30.168 19.7783 30.168 20.2541V72.131C30.168 72.6068 30.5536 72.9926 31.0292 72.9926H70.7403C71.216 72.9926 71.6016 72.6068 71.6016 72.131V20.2541C71.6016 19.7783 71.216 19.3926 70.7403 19.3926Z"
              fill="url(#paint2_linear_7972_113993)"
            />
            <g filter="url(#filter2_di_7972_113993)">
              <path
                d="M25 25.8198L25 75.1803C25 75.633 25.3909 76 25.8731 76H66.1269C66.6091 76 67 75.633 67 75.1803V25.8198C67 25.367 66.6091 25 66.1269 25H25.8731C25.3909 25 25 25.367 25 25.8198Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter3_d_7972_113993)">
              <path d="M45.5787 31.5469H30.168V36.0084H45.5787V31.5469Z" fill="#2951A0" />
            </g>
            <g filter="url(#filter4_d_7972_113993)">
              <path d="M35.7048 42.623H30.168V47.0846H35.7048V42.623Z" fill="#2951A0" />
            </g>
            <g filter="url(#filter5_d_7972_113993)">
              <path d="M53.5758 42.623H38.7188V47.0846H53.5758V42.623Z" fill="#E97025" />
            </g>
            <g filter="url(#filter6_d_7972_113993)">
              <path d="M53.5763 53.6699H30.168V58.1315H53.5763V53.6699Z" fill="#2650A3" />
            </g>
            <g filter="url(#filter7_d_7972_113993)">
              <path d="M46.1324 64.7461H30.168V69.2076H46.1324V64.7461Z" fill="#E97025" />
            </g>
            <g filter="url(#filter8_i_7972_113993)">
              <path
                d="M63 41.0078C55.8289 41.0078 50 35.1772 50 28.0039C50 20.8307 55.8289 15 63 15C70.1711 15 76 20.8307 76 28.0039C76 35.1772 70.1711 41.0078 63 41.0078ZM54.8702 36.2512C59.472 40.8928 67.0266 40.5859 71.1681 36.2512C69.941 33.1825 66.8348 30.7658 63 30.8042C59.1268 30.8042 56.059 33.2592 54.8702 36.2512ZM64.1121 20.2169C60.6224 19.4114 57.5929 22.5569 58.5516 26.0476C58.9351 27.3902 59.9322 28.5026 61.1976 29.0396C64.6106 30.3822 68.0619 27.6587 67.6018 24.0912C67.3717 22.2116 65.9145 20.6389 64.0737 20.2169H64.1121Z"
                fill="#2951A0"
              />
            </g>
            <path
              d="M54.8711 36.2928C56.0982 33.3007 59.1277 30.8457 63.0009 30.8457C66.8357 30.8457 69.9419 33.2624 71.169 36.2928C66.9891 40.6274 59.4729 40.9343 54.8711 36.2928Z"
              fill="white"
            />
            <path
              d="M63.0346 20.1035C65.604 20.1035 67.6748 22.2133 67.6748 24.745C67.6748 27.3535 65.5273 29.3866 63.0346 29.3866C60.4653 29.3866 58.3945 27.2768 58.3945 24.745C58.3945 22.1749 60.4653 20.1035 63.0346 20.1035Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7972_113993"
          x="0"
          y="0"
          width="101"
          height="101"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.12 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7972_113993" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7972_113993"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_7972_113993"
          x="8"
          y="6"
          width="85"
          height="85"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7972_113993" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7972_113993"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_di_7972_113993"
          x="25"
          y="23"
          width="44"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="-1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.144618 0 0 0 0 0.262674 0 0 0 0 0.495833 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7972_113993" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7972_113993"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.85" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_7972_113993" />
        </filter>
        <filter
          id="filter3_d_7972_113993"
          x="30.168"
          y="31.5469"
          width="19.4102"
          height="8.46094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7972_113993" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7972_113993"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_7972_113993"
          x="30.168"
          y="42.623"
          width="9.53516"
          height="8.46094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7972_113993" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7972_113993"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_7972_113993"
          x="38.7188"
          y="42.623"
          width="18.8555"
          height="8.46094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7972_113993" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7972_113993"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_7972_113993"
          x="30.168"
          y="53.6699"
          width="27.4102"
          height="8.46094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7972_113993" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7972_113993"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_7972_113993"
          x="30.168"
          y="64.7461"
          width="19.9648"
          height="8.46094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7972_113993" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7972_113993"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_i_7972_113993"
          x="50"
          y="15"
          width="26"
          height="27.0078"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_7972_113993" />
        </filter>
        <linearGradient
          id="paint0_linear_7972_113993"
          x1="21.9754"
          y1="-94.9194"
          x2="23.3125"
          y2="98.0588"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00455753" stopColor="#B7CAEA" />
          <stop offset="0.533268" stopColor="#FAFBFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7972_113993"
          x1="95"
          y1="86"
          x2="-23"
          y2="-39.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F1F1" />
          <stop offset="0.952515" stopColor="#2951A0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7972_113993"
          x1="80.5"
          y1="44"
          x2="84"
          y2="99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2951A0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_7972_113993">
          <rect width="51" height="64" fill="white" transform="translate(25 14.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
