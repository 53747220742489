import {
  AccordionExpandable,
  Box,
  Button,
  Divider,
  Heading,
  IconButton,
  Layer,
  Spinner,
  Text,
  TextField,
  VariablePartSheet,
  VariablePartSheetCurrentPart,
  popupZIndex,
  useColors,
  useDesignBreakpoint,
  usePubSub,
} from "@prodoctivity/design-system";
import type {
  StringTemplateParameterValue,
  StringTemplatePart,
} from "@prodoctivity/shared/src/index-types";
import type { DistributionConfiguration, DistributionParameter } from "@prodoctivity/types";
import { FunctionComponent, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DistributionConfig, getVariableList } from "./DistributionConfig";

import { EditVariableCommand } from "@prodoctivity/design-system";
import { shouldNever } from "@prodoctivity/shared";
import { useMutation } from "@tanstack/react-query";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { NotificationMessage, ToastMessageType } from "../../components/NotificationMessage";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { DeleteDistributionModal } from "./DeleteDistributionModal";
import { DistributionListSection } from "./DistributionListSection";
import { DistributionOptionsLarge } from "./DistributionOptionsLarge";
import { HelpSection } from "./HelpSection";

export type DistributionManagementMessage =
  | {
      type: "distribution-change";
      value: Record<string, DistributionParameter>;
    }
  | {
      type: "reload-distribution";
      config: DistributionConfiguration;
    }
  | {
      type: "update-distribution-property";
      property: Partial<DistributionConfiguration>;
    };

function reducer(
  prevState: DistributionConfiguration,
  msg: DistributionManagementMessage
): DistributionConfiguration {
  switch (msg.type) {
    case "distribution-change": {
      return {
        ...prevState,
        inputParameters: Object.keys(msg.value).reduce((arr: DistributionParameter[], next) => {
          const parameter = msg.value[next];
          arr.push(parameter);

          return arr;
        }, []),
      };
    }
    case "update-distribution-property": {
      return {
        ...prevState,
        ...msg.property,
      };
    }
    case "reload-distribution": {
      return {
        distributionId: msg.config.distributionId,
        name: msg.config.name,
        label: msg.config.label,
        inputParameters: [...msg.config.inputParameters],
        implementationId: msg.config.implementationId,
        isActive: msg.config.isActive,
        organizationId: msg.config.organizationId,
      };
    }

    default:
      shouldNever(msg);
      return prevState;
  }
}

const Topic = ({ children }: { children?: string }) => {
  const { colors } = useColors();
  return (
    <Box marginBottom={5} marginTop={5} display="flex" flex="grow">
      <Heading color={colors.primary} size="300">
        {children}
      </Heading>
    </Box>
  );
};

function getVariableSource(val: string): StringTemplateParameterValue["source"] {
  const tryVal: StringTemplateParameterValue["source"] =
    val as StringTemplateParameterValue["source"];
  switch (tryVal) {
    case "context":
    case "distribution-result":
    case "env":
    case "fixed":
    case "generation-result":
    case "global-parameter":
    case "instance-parameter":
      return tryVal;
    default:
      shouldNever(tryVal);
      return "fixed";
  }
}

type Props = {
  isUpdating: boolean;
};

const DistributionManagementPage: FunctionComponent<Props> = ({ isUpdating }) => {
  const {
    getDistributionImplementations,
    getDistribution,
    saveDistribution,
    getDistributions,
    disableDistribution,
    deleteDistribution,
    user,
  } = useServices();
  const { colors } = useColors();
  const { implementationId, distributionId } = useParams();
  const location = useLocation();
  const previousConfiguration = location.state as DistributionConfiguration;
  const { resources, moment } = useAppTranslation();
  const [showHelpSection, setShowHelpSection] = useState(false);
  const { breakpoint } = useDesignBreakpoint();
  const distributionManagementPageBreakpoint =
    breakpoint === "large" || breakpoint === "medium" || breakpoint === "small";
  const distributionShowHelpSection = breakpoint === "medium" || breakpoint === "small";
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [toastMessage, setToastMessage] = useState<ToastMessageType | undefined>();

  const getBaseImplementations = useCallback(() => {
    return getDistributionImplementations();
  }, [getDistributionImplementations]);

  const getUserDefinedDistributions = useCallback(() => {
    return getDistributions(0);
  }, [getDistributions]);

  const getDistributionDetails = useCallback(async () => {
    if (distributionId) {
      const distribution = getDistribution(distributionId);
      return distribution;
    }

    return {
      distribution: undefined,
    };
  }, [distributionId, getDistribution]);

  const {
    data: selectedDistributionPayload,
    refetch: refetchDistributionDetails,
    isLoading: isDistributionLoading,
  } = useOrganizationQuery(`/distribution-configuration/${distributionId}`, getDistributionDetails);

  const { data: baseImplementations } = useOrganizationQuery(
    `/base-implementations`,
    getBaseImplementations,
    {
      staleTime: 0,
    }
  );

  const { data: userDefinedDistributions, refetch: refetchDistributions } = useOrganizationQuery(
    `/user-defined-distributions`,
    getUserDefinedDistributions,
    {
      staleTime: 0,
    }
  );

  const organizationNavigate = useOrganizationNavigate();

  const saveDistributionOptions = useMemo(
    () => ({
      onSuccess() {
        refetchDistributions();
        refetchDistributionDetails();
        setToastMessage({ type: "success", message: resources.success });
      },
      onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
        if (error.response.data.errors.length > 0) {
          setToastMessage({ type: "error", message: error.response.data.errors[0].message });
        }
      },
    }),
    [refetchDistributionDetails, refetchDistributions, resources]
  );

  const disableDistributionOptions = useMemo(
    () => ({
      onSuccess() {
        refetchDistributions();
        refetchDistributionDetails();
        setToastMessage({
          type: "success",
          message: resources.distributionManagementPage.disableDistributionSuccess,
        });
      },
      onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
        if (error.response.data.errors.length > 0) {
          setToastMessage({ type: "error", message: error.response.data.errors[0].message });
        }
      },
    }),
    [refetchDistributionDetails, refetchDistributions, resources]
  );

  const handleDeleteButtonClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmed = () => {
    handleDeleteClicked();
    setShowDeleteModal(false);
  };

  const handleDeleteModalDismiss = () => {
    setShowDeleteModal(false);
  };

  const deleteDistributionOptions = useMemo(
    () => ({
      onSuccess() {
        refetchDistributions();
        refetchDistributionDetails();
        setToastMessage({
          type: "success",
          message: resources.distributionManagementPage.deleteDistributionSuccess,
        });
        setTimeout(() => {
          organizationNavigate(organizationLinkTemplates.manageDistributions());
        }, 1000);
      },
      onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
        if (error.response.data.errors.length > 0) {
          setToastMessage({ type: "error", message: error.response.data.errors[0].message });
        }
      },
    }),
    [organizationNavigate, refetchDistributionDetails, refetchDistributions, resources]
  );

  const { mutate: saveCurrentDistribution } = useMutation(
    saveDistribution,
    saveDistributionOptions
  );

  const { mutate: disableCurrentDistribution } = useMutation(
    disableDistribution,
    disableDistributionOptions
  );

  const { mutate: deleteCurrentDistribution } = useMutation(
    deleteDistribution,
    deleteDistributionOptions
  );

  const getImplementationTitle = useCallback(
    (implementationId: string) => {
      if (baseImplementations) {
        const implementation = baseImplementations.implementations.find(
          (imp) => imp.implementationId === implementationId
        );
        if (implementation) {
          const key =
            implementation.nameI18nKey as keyof typeof resources["distributionManagementPage"];
          return resources.distributionManagementPage[key];
        }
      }
    },
    [baseImplementations, resources.distributionManagementPage]
  );

  useEffect(() => {
    refetchDistributions();
  }, [refetchDistributions]);

  const selectedDistribution: DistributionConfiguration = useMemo(() => {
    const emptyDistribution = {
      distributionId: "",
      label: "",
      name: "",
      implementationId: "amazonSES" as const,
      inputParameters: [],
      isActive: false,
      organizationId: "",
    };
    if (previousConfiguration) {
      return {
        ...previousConfiguration,
        distributionId: "",
        label: resources.copyOf + " " + previousConfiguration.label,
      };
    } else {
      if (implementationId && baseImplementations && !distributionId) {
        const baseImplementation = baseImplementations.implementations.find(
          (impl) => impl.implementationId === implementationId
        );
        if (!baseImplementation) {
          return emptyDistribution;
        } else {
          return {
            distributionId: "",
            name: baseImplementation.implementationId,
            label:
              resources.copyOf + " " + getImplementationTitle(baseImplementation.implementationId),
            implementationId: baseImplementation.implementationId,
            inputParameters: baseImplementation.inputParameters.map((param) => {
              return {
                key: param.key,
                template: {
                  values: [{ source: "fixed" as const, value: param.descriptionI18nKey }],
                },
              };
            }),
            isActive: true,
            organizationId: user ? user.organizationId : "",
          };
        }
      } else if (!selectedDistributionPayload) {
        return emptyDistribution;
      } else {
        if (!selectedDistributionPayload.distribution) {
          return emptyDistribution;
        }
        if (isUpdating) {
          return selectedDistributionPayload.distribution;
        } else {
          return {
            ...selectedDistributionPayload.distribution,
            distributionId: "",
            label: resources.copyOf + " " + selectedDistributionPayload.distribution.label,
          };
        }
      }
    }
  }, [
    baseImplementations,
    distributionId,
    getImplementationTitle,
    implementationId,
    isUpdating,
    previousConfiguration,
    resources.copyOf,
    selectedDistributionPayload,
    user,
  ]);

  const { eventManager } = usePubSub<EditVariableCommand>();

  const [showVariablePartSheet, setShowVariablePartSheet] = useState(false);
  const [editingDistributionLabel, setEditingDistributionLabel] = useState(false);
  const [clickedPart, setClickedPart] = useState<VariablePartSheetCurrentPart>();
  const setClickedPartValue = useCallback(
    (value: VariablePartSheetCurrentPart) => {
      setClickedPart(value);
    },
    [setClickedPart]
  );
  const [currentVariable, rawSetCurrentVariable] = useState<
    StringTemplateParameterValue | undefined
  >();

  const rawSetCurrentVariableValue = useCallback(
    (value: StringTemplateParameterValue | undefined) => {
      rawSetCurrentVariable(value);
    },
    []
  );
  const [distributionState, dispatch] = useReducer(reducer, { ...selectedDistribution });
  const variableListInitialStateGetter = useCallback(() => {
    if (selectedDistribution) return getVariableList(selectedDistribution.inputParameters);
    else return [];
  }, [selectedDistribution]);
  const [variableList, setVariableList] = useState<StringTemplateParameterValue[]>(
    variableListInitialStateGetter
  );

  const getFieldLabel = useCallback((fldName: string) => {
    return fldName;
  }, []);

  const getFieldDataType = useCallback(
    (_source: StringTemplateParameterValue["source"], _name: string) => {
      return "Alphanumeric";
    },
    []
  );

  const onChange = useCallback((parameters: Record<string, DistributionParameter>) => {
    dispatch({
      type: "distribution-change",
      value: parameters,
    });
  }, []);

  const handleDistributionLabelChanged = useCallback((newValue: string) => {
    dispatch({
      type: "update-distribution-property",
      property: {
        label: newValue,
      },
    });
  }, []);

  const handlePartClicked = useCallback(
    (args: {
      lineIndex: number;
      partIndex: number;
      parameterKey: string;
      part: StringTemplatePart;
    }) => {
      setClickedPartValue({
        lineIndex: args.lineIndex,
        parameterKey: args.parameterKey,
        partIndex: args.partIndex,
        part: args.part,
      });
      setShowVariablePartSheet(true);
    },
    [setClickedPartValue]
  );
  const handleSaveClicked = useCallback(() => {
    const payload: DistributionConfiguration = {
      ...distributionState,
      inputParameters: distributionState.inputParameters.filter((p) => !!p.template.values.length),
    };
    saveCurrentDistribution(payload);
    refetchDistributions();
  }, [distributionState, saveCurrentDistribution, refetchDistributions]);

  const handleDisableClicked = useCallback(() => {
    disableCurrentDistribution(selectedDistribution.distributionId);
    refetchDistributions();
  }, [disableCurrentDistribution, selectedDistribution.distributionId, refetchDistributions]);

  const handleDeleteClicked = useCallback(() => {
    deleteCurrentDistribution(selectedDistribution.distributionId);
  }, [deleteCurrentDistribution, selectedDistribution.distributionId]);

  useEffect(() => {
    dispatch({
      type: "reload-distribution",
      config: selectedDistribution,
    });
    refetchDistributions();
    const variables = getVariableList(selectedDistribution.inputParameters);
    setVariableList(variables);
  }, [refetchDistributions, selectedDistribution]);

  useEffect(() => {
    if (eventManager) {
      return eventManager.subscribe((command) => {
        switch (command.type) {
          case "add-variable-to-list":
            {
              const source = getVariableSource(command.source);
              switch (source) {
                case "generation-result": {
                  const newVar: StringTemplateParameterValue = {
                    source,
                    value: "document",
                  };
                  setVariableList((variableList) => [...variableList, newVar]);
                  break;
                }
                case "distribution-result": {
                  const newVar: StringTemplateParameterValue = {
                    source,
                    value: command.variableName,
                    // TODO: Check. I have a missing mimeType here and I made it up
                    mimeType:
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  };
                  setVariableList((variableList) => [...variableList, newVar]);
                  break;
                }
                case "context":
                case "env":
                case "fixed":
                case "global-parameter":
                case "instance-parameter": {
                  const newVar: StringTemplateParameterValue = {
                    source,
                    value: command.variableName,
                  };
                  setVariableList((variableList) => [...variableList, newVar]);
                  break;
                }
                default:
                  shouldNever(source);
                  throw new Error("should not happen");
              }
            }
            break;
          case "edit-variable-list":
            setVariableList(
              variableList.map((variable) => {
                if (variable.value === command.value) {
                  return {
                    value: command.value,
                    source: command.source,
                  } as StringTemplateParameterValue;
                } else {
                  return variable;
                }
              })
            );
            break;
          case "variable-name-change":
          case "edit-variable-part":
          case "reset-status":
            break;
          default:
            shouldNever(command);
        }
      });
    }
  }, [eventManager, variableList]);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "url",
        name: resources.distributions,
        url: organizationLinkTemplates.manageDistributions(),
      },
    ];
  }, [resources.distributions, resources.home]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="row" flex="grow" gap={2}>
        <Box display="flex" direction="column" flex="grow" flexValue={3}>
          {distributionManagementPageBreakpoint ? (
            <DistributionOptionsLarge
              baseImplementations={baseImplementations}
              userDefinedDistributions={userDefinedDistributions}
            />
          ) : (
            <DistributionListSection
              baseImplementations={baseImplementations ? baseImplementations.implementations : []}
              userDefinedDistributions={
                userDefinedDistributions ? userDefinedDistributions.distributions : []
              }
            />
          )}
        </Box>
        <Box paddingY={2}>
          <Divider direction="vertical" />
        </Box>
        <Box display="flex" direction="column" flex="grow" flexValue={6}>
          <Box display="flex" direction="row" flex="grow">
            {isDistributionLoading ? (
              <Spinner accessibilityLabel={resources.loading} show={isDistributionLoading} />
            ) : (
              <>
                <Box display="flex" direction="column" flex="grow" width={"100%"}>
                  {/* Content Start */}
                  {!implementationId && !distributionId ? (
                    <Box marginStart={6}>
                      <Text size="200">
                        {resources.distributionManagementPage.noDistributionSelected}
                      </Text>
                    </Box>
                  ) : (
                    <Box direction="column" display="flex" paddingTop={2}>
                      {/* Title Start */}
                      <>
                        {editingDistributionLabel ? (
                          <Box width={250}>
                            <TextField
                              id={distributionState.label}
                              value={distributionState.label}
                              onChange={({ value }) => {
                                handleDistributionLabelChanged(value);
                              }}
                              onBlur={() => {
                                setEditingDistributionLabel(false);
                              }}
                              onKeyDown={({ event }) => {
                                if (event.key === "Enter") {
                                  setEditingDistributionLabel(false);
                                }
                              }}
                            />
                          </Box>
                        ) : (
                          <Box marginBottom={1}>
                            <Box display="flex" gap={2} alignItems={"center"}>
                              <Text size="400" weight={"bold"} color={colors.primary}>
                                {distributionState.label}
                              </Text>

                              <IconButton
                                accessibilityLabel={
                                  resources.distributionManagementPage.editDistributionLabel
                                }
                                onClick={() => {
                                  setEditingDistributionLabel(true);
                                }}
                                size={"xs"}
                                icon="pencil"
                              />

                              <Box display="flex" flex="grow" />
                              {distributionShowHelpSection ? null : showHelpSection ? null : (
                                <Box display="flex" flex="shrink" padding={2}>
                                  <IconButton
                                    accessibilityLabel="Open Help Section"
                                    icon="circle-question"
                                    size={"sm"}
                                    color={colors.subtle}
                                    onClick={() => setShowHelpSection(true)}
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        )}

                        <Box paddingX={1}>
                          <Text size="100" color={colors.subtle}>
                            {resources.distributionManagementPage.basedOn}{" "}
                            {getImplementationTitle(selectedDistribution.implementationId)}
                          </Text>
                        </Box>
                      </>

                      {/* Title End */}
                      {/* Distribution Content Start */}
                      <Box color={colors.neutral100} minWidth={400} width={"100%"}>
                        <Box padding={6} opacity={selectedDistribution.isActive ? 1 : 0.5}>
                          {!selectedDistribution.isActive && (
                            <Text align="end" color={colors.error}>
                              {resources.distributionManagementPage.currentlyDisabledDistribution}
                            </Text>
                          )}
                          <DistributionConfig
                            onChange={onChange}
                            config={selectedDistribution}
                            eventManager={eventManager}
                            variableList={variableList}
                            onPartClicked={handlePartClicked}
                          />
                        </Box>
                      </Box>
                      {/* Distribution Content End */}
                      {/* Create Button Start */}
                      <Box display="flex" alignSelf="end" marginEnd={2} paddingY={6}>
                        <Button
                          accessibilityLabel={`${
                            isUpdating ? resources.saveChanges : resources.create
                          }`}
                          text={isUpdating ? resources.saveChanges : resources.create}
                          color="blue"
                          size="md"
                          onClick={handleSaveClicked}
                        />
                      </Box>
                      {/* Create Button End */}
                      {/* Clone Section Start */}
                      {isUpdating ? (
                        <Box
                          marginStart={4}
                          marginEnd={4}
                          direction={
                            breakpoint === "small" || breakpoint === "medium" ? "column" : "row"
                          }
                        >
                          <Box paddingY={4} paddingX={6} display="flex" direction="column">
                            <Box marginBottom={4}>
                              <Text weight="bold">
                                {resources.distributionManagementPage.cloneDistribution}
                              </Text>
                            </Box>
                            <Box>
                              <Text>
                                {resources.distributionManagementPage.cloneDistributionDescription}
                              </Text>
                            </Box>
                          </Box>
                          {/* Clone Button Start */}
                          <Box display="flex" alignSelf="center" paddingY={6} justifyContent="end">
                            <Button
                              accessibilityLabel={resources.distributionManagementPage.cloneInstead}
                              text={resources.distributionManagementPage.cloneInstead}
                              color="gray"
                              size="md"
                              onClick={() => {
                                organizationNavigate(
                                  `/settings/distributions/${distributionState.distributionId}/new`,
                                  { state: distributionState }
                                );
                              }}
                            />
                          </Box>
                          {/* Clone Button End */}
                        </Box>
                      ) : null}

                      {/* Clone Section End */}
                    </Box>
                  )}
                  {/* Warning Start */}
                  {isUpdating ? (
                    <Box
                      marginStart={12}
                      marginEnd={12}
                      paddingX={12}
                      direction="column"
                      display="flex"
                    >
                      {/* Warning Title and toggle button */}
                      <Box marginBottom={10}>
                        <AccordionExpandable
                          id="danger_zone"
                          accessibilityCollapseLabel={resources.collapse}
                          accessibilityExpandLabel={resources.expand}
                          items={[
                            {
                              title: resources.leaveOrganizationPage.dangerZone,
                              icon: "alert",
                              type: "error",
                              children: (
                                <>
                                  {/* Warning Content */}
                                  <Box color={colors.neutral300} width={"100%"} borderStyle="lg">
                                    <Box
                                      paddingY={4}
                                      paddingX={6}
                                      display="flex"
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="between"
                                    >
                                      {/* Disable / Enable Section Start */}
                                      <Box maxWidth={"65%"}>
                                        <Box marginBottom={4}>
                                          <Text weight="bold">
                                            {selectedDistribution.isActive
                                              ? resources.distributionManagementPage
                                                  .disableDistribution
                                              : resources.distributionManagementPage
                                                  .enableDistribution}
                                          </Text>
                                        </Box>
                                        <Box>
                                          <Text>
                                            {
                                              resources.distributionManagementPage
                                                .disableDistributionDescription
                                            }
                                          </Text>
                                        </Box>
                                      </Box>
                                      {/* Disable / Enable Button Start */}
                                      <Box
                                        display="flex"
                                        alignSelf="end"
                                        marginEnd={2}
                                        paddingY={6}
                                      >
                                        <Button
                                          accessibilityLabel={`${
                                            selectedDistribution.isActive
                                              ? resources.disable
                                              : resources.enable
                                          }`}
                                          text={
                                            selectedDistribution.isActive
                                              ? resources.disable
                                              : resources.enable
                                          }
                                          color={
                                            selectedDistribution.isActive
                                              ? "semiTransparentWhite"
                                              : "blue"
                                          }
                                          size="md"
                                          onClick={() => {
                                            handleDisableClicked();
                                          }}
                                        />
                                      </Box>
                                      {/* Disable / Enable Button End */}
                                    </Box>
                                    {/* Disable / Enable Section End */}
                                    <Box
                                      height={1}
                                      marginTop={2}
                                      marginBottom={2}
                                      color={colors.neutral500}
                                    />
                                    {/* Delete Section Start */}
                                    <Box
                                      paddingY={4}
                                      paddingX={6}
                                      display="flex"
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="between"
                                    >
                                      <Box maxWidth={"65%"}>
                                        <Box marginBottom={4}>
                                          <Text weight="bold">
                                            {
                                              resources.distributionManagementPage
                                                .deleteDistribution
                                            }
                                          </Text>
                                        </Box>
                                        <Box>
                                          <Text>
                                            {
                                              resources.distributionManagementPage
                                                .deleteDistributionDescription
                                            }
                                          </Text>
                                        </Box>
                                      </Box>
                                      {/* Delete Button Start */}
                                      <Box
                                        display="flex"
                                        alignSelf="end"
                                        marginEnd={2}
                                        paddingY={6}
                                      >
                                        <>
                                          <Button
                                            accessibilityLabel={resources.delete_}
                                            text={resources.delete_}
                                            color="red"
                                            size="md"
                                            onClick={() => {
                                              handleDeleteButtonClick();
                                            }}
                                          />
                                          <DeleteDistributionModal
                                            show={showDeleteModal}
                                            onDismiss={handleDeleteModalDismiss}
                                            deleteDistribution={handleDeleteConfirmed}
                                            permanentDelete={true}
                                          />
                                        </>
                                      </Box>
                                      {/* Delete Button End */}
                                    </Box>
                                    {/* Delete Section End */}
                                  </Box>
                                  {/* Warning End */}
                                </>
                              ),
                            },
                          ]}
                        />
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </>
            )}
          </Box>
          {showVariablePartSheet && (
            <Layer zIndex={popupZIndex}>
              <VariablePartSheet
                onDismiss={() => {
                  setShowVariablePartSheet(false);
                }}
                getFieldLabel={getFieldLabel}
                getFieldDataType={getFieldDataType}
                variableList={variableList}
                currentPart={clickedPart}
                currentVariable={currentVariable}
                rawSetCurrentVariableValue={rawSetCurrentVariableValue}
                eventManager={eventManager}
                resources={resources}
                moment={moment}
              />
            </Layer>
          )}
          {toastMessage && (
            <Box>
              <NotificationMessage
                type={toastMessage.type}
                message={toastMessage.message}
                position="bottom-left"
                handleDismiss={() => setToastMessage(undefined)}
                delay={3000}
              />
            </Box>
          )}
        </Box>
        {showHelpSection ? (
          <Box display="flex" direction="column" flexValue={3}>
            <Box display="flex" direction="row" paddingLeft={4}>
              <Box display="flex" flex="grow">
                <Topic>{resources.help}</Topic>
              </Box>
              <Box display="flex" paddingY={5} paddingX={5} flex="shrink">
                <IconButton
                  onClick={() => setShowHelpSection(false)}
                  accessibilityLabel={resources.close}
                  icon="close"
                  size={"sm"}
                  color={colors.subtle}
                />
              </Box>
            </Box>
            <HelpSection />
          </Box>
        ) : null}
      </Box>
    </Page>
  );
};

export default DistributionManagementPage;
