import { AccordionExpandable, Box, Skeleton, Text, useColors } from "@prodoctivity/design-system";

import type { DistributionParameterTemplate } from "@prodoctivity/types";
import { FC } from "react";
import { useDistributionConfig } from "../../hooks";

type Props = {
  distributionId: string;
};

const stringifyParameterTemplate = (template: DistributionParameterTemplate): string => {
  const templateString = template.values.reduce<string>((previous, current) => {
    let result = "";
    if (current.source === "fixed") {
      result = current.value;
    } else {
      result = `[${current.value}:${current.source}]`;
    }
    return `${previous}${result}`;
  }, "");
  if (templateString === "") {
    return "[NONE]";
  }
  return templateString;
};

const DistributionCard: FC<Props> = (DistributionCardProps: Props) => {
  const { colors } = useColors();
  const { isLoading, data } = useDistributionConfig(DistributionCardProps.distributionId);
  if (isLoading || !data) {
    return (
      <Box padding={2} margin={1}>
        <Skeleton width={400} height={160} key={DistributionCardProps.distributionId} />
      </Box>
    );
  } else {
    return (
      <Box padding={2} margin={1}>
        <AccordionExpandable
          id={data.distribution.distributionId}
          items={[
            {
              title: `${data.distribution.name.toLocaleLowerCase()}`,
              children: (
                <Box>
                  <Text size="200" color={colors.primary200}>
                    {data.distribution.distributionId}
                  </Text>
                  <Text size="300" color={colors.primary200}>
                    {data.distribution.implementationId}
                  </Text>

                  {data.distribution.inputParameters.map((ip) => {
                    return (
                      <Box key={ip.key}>
                        <Text size="200" color={colors.black600}>
                          {ip.key}
                        </Text>
                        <Text size="100" color={colors.secondary}>
                          {stringifyParameterTemplate(ip.template)}
                        </Text>
                      </Box>
                    );
                  })}
                </Box>
              ),
            },
          ]}
          accessibilityCollapseLabel="Hide Details"
          accessibilityExpandLabel="Show Details"
        />
      </Box>
    );
  }
};

export { DistributionCard };
