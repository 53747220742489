import { Box, Button, Heading, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, useMemo } from "react";

import { BreadCrumbEntry } from "../../../components/BreadCrumb";

import { Page } from "../../../components/Layout/Page";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { StepTabs } from "../../../components/StepTabs";
import { organizationLinkTemplates } from "../../../link-templates";
import { GeneralProperties } from "./Steps/GeneralProperties";
import { TemplatesSelect } from "./Steps/TemplatesSelect";
import { useCombinedTemplate } from "./hooks";

export const ManageCombinedTemplatePage: FunctionComponent = () => {
  const { colors } = useColors();
  const {
    selectedTabIndex,
    resources,
    formState,
    setFormState,
    nextStep,
    prevStep,
    STEPS,
    templateList,
    isLoading,
    documentGroups,
    currentDocumentGroup,
    onChangeDocumentGroup,
  } = useCombinedTemplate();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      { type: "url", name: resources.settings, url: "/settings" },
      {
        type: "url",
        name: resources.combinedTemplates.combinedTemplates,
        url: organizationLinkTemplates.manageCombinedTemplates(),
      },
      {
        type: "text",
        name: formState.name || "",
      },
    ];
  }, [
    resources.home,
    resources.settings,
    resources.combinedTemplates.combinedTemplates,
    formState.name,
  ]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <Box padding={4}>
          <Heading color={colors.black600} size="500">
            {resources.combinedTemplates.combinedTemplatesManagement}
          </Heading>
        </Box>
        <Box height={"100%"}>
          <StepTabs
            selectedTabIndex={selectedTabIndex}
            color={colors.white}
            tabs={[
              {
                title: resources.generalProperties,
                description: resources.generalProperties,
                content: (
                  <GeneralProperties
                    resources={resources}
                    isLoading={isLoading}
                    documentGroups={documentGroups}
                    currentDocumentGroup={currentDocumentGroup}
                    onChangeDocumentGroup={onChangeDocumentGroup}
                    formState={formState}
                    setFormState={setFormState}
                  />
                ),
              },
              {
                title: resources.activities.filterTypes.configuration,
                description: resources.combinedTemplates.combinedTemplateStep2SubTitle,
                content: (
                  <TemplatesSelect
                    resources={resources}
                    isLoading={isLoading}
                    templates={templateList}
                    formState={formState}
                    setFormState={setFormState}
                  />
                ),
              },
            ]}
          />
        </Box>
        {formState.toastMessage && (
          <NotificationMessage
            type={formState.toastMessage.type}
            message={formState.toastMessage.message}
            position="bottom-left"
            handleDismiss={() => setFormState({ ...formState, toastMessage: undefined })}
          />
        )}
        <Box
          justifyContent="between"
          color={colors.neutral300}
          direction="row"
          display="flex"
          padding={2}
          borderStyle="lg"
          borderRadius={4}
          bottom={true}
        >
          <Box display="flex" flex="shrink">
            {selectedTabIndex > STEPS.Creation && (
              <Button
                color={"transparent"}
                disabled={isLoading}
                onClick={prevStep}
                text={resources.previousStep}
              />
            )}
          </Box>
          <Box flex="grow" />
          <Box display="flex" flex="shrink">
            <Button
              onClick={nextStep}
              color={"blue"}
              disabled={isLoading}
              text={`${
                selectedTabIndex === STEPS.Configuration ? resources.save : resources.continue
              }`}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default ManageCombinedTemplatePage;
