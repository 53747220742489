"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlphanumericContextFieldPropertiesPart$Schema = void 0;
const zod_1 = require("zod");
const dictionary_1 = require("../../dictionary");
const utils_1 = require("../../utils");
const TextBoxOrDefaultInputType$Schema = zod_1.z.object({
    inputType: zod_1.z.enum(["TextBox", "Default"]),
    maxLength: zod_1.z.number().int().positive().optional().catch(undefined),
    autoCompleteValues: zod_1.z.boolean().optional().catch(undefined),
    inputMask: zod_1.z.string().optional(),
    inputMaskStoreFixedChars: zod_1.z.boolean().optional(),
    sequenceId: zod_1.z.string().optional(),
});
const TextAreaInputType$Schema = zod_1.z.object({
    inputType: zod_1.z.literal("TextArea"),
    maxLength: zod_1.z.number().int().positive().optional().catch(undefined),
    lineCount: zod_1.z.number().int().positive().optional().catch(undefined),
});
const ContextFieldValueList$Schema = zod_1.z
    .strictObject({
    label: zod_1.z.string(),
    value: zod_1.z.string(),
    parentValue: zod_1.z.string().optional(),
})
    .array()
    .min(1)
    .superRefine((list, ctx) => {
    const s = new Set();
    list.forEach((item) => {
        if (s.has(item.value)) {
            ctx.addIssue({
                code: "custom",
                message: `Duplicate value "${item.value}" in value list`,
            });
        }
        s.add(item.value);
    });
});
const ListsInputType$Schema = zod_1.z.object({
    inputType: zod_1.z.enum([
        "Checkbox",
        "Radio",
        "Dropdown",
        "CheckBoxList",
        "DropDownList",
        "RadioButtonList",
    ]),
    listHasLabels: zod_1.z.boolean().optional(),
    valueList: ContextFieldValueList$Schema,
    dictionaryListId: zod_1.z
        .string()
        .refine(utils_1.isConvertibleToObjectID, "Invalid DictionaryListId")
        .optional(),
    dictionaryListPath: zod_1.z.array(zod_1.z.string()),
    parentListSource: zod_1.z.string().optional(),
});
const AlphanumericContextFieldPropertiesInputTypePart$Schema = zod_1.z
    .object({
    inputType: dictionary_1.InputType$Schema.or(zod_1.z.literal("Default")),
})
    .passthrough()
    .transform((val, ctx) => {
    switch (val.inputType) {
        case "TextBox":
        case "Default": {
            const r = TextBoxOrDefaultInputType$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "TextArea": {
            const r = TextAreaInputType$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        case "Checkbox":
        case "Radio":
        case "Dropdown":
        case "CheckBoxList":
        case "DropDownList":
        case "RadioButtonList": {
            const r = ListsInputType$Schema.safeParse(val);
            if (!r.success) {
                r.error.issues.forEach((issue) => {
                    ctx.addIssue(Object.assign({}, issue));
                });
                return zod_1.z.NEVER;
            }
            return r.data;
        }
        default:
            return zod_1.z.NEVER;
    }
})
    .superRefine((arg, ctx) => {
    switch (arg.inputType) {
        case "Checkbox":
        case "Radio":
        case "Dropdown":
        case "CheckBoxList":
        case "DropDownList":
        case "RadioButtonList": {
            if (arg.dictionaryListId !== undefined) {
                if (typeof arg.parentListSource !== "undefined") {
                    ctx.addIssue({
                        code: zod_1.z.ZodIssueCode.custom,
                        message: "Can have either dictionaryListId or parentListSource",
                    });
                }
            }
            else if (arg.parentListSource !== undefined) {
                if (typeof arg.dictionaryListId !== "undefined") {
                    ctx.addIssue({
                        code: zod_1.z.ZodIssueCode.custom,
                        message: "Can have either dictionaryListId or parentListSource",
                    });
                }
            }
            break;
        }
        default: {
            break;
        }
    }
});
exports.AlphanumericContextFieldPropertiesPart$Schema = zod_1.z
    .object({
    dataType: zod_1.z.literal("Alphanumeric"),
    minOccurs: zod_1.z.number().optional(),
    maxOccurs: zod_1.z.number().optional(),
    defaultValue: zod_1.z
        .preprocess((arg) => {
        if (typeof arg === "string") {
            return [arg];
        }
        if (Array.isArray(arg)) {
            if (arg.every((item) => typeof item === "string")) {
                return arg;
            }
        }
        return zod_1.z.NEVER;
    }, zod_1.z.array(zod_1.z.string()))
        .optional(),
    sampleValue: zod_1.z
        .preprocess((arg) => {
        if (typeof arg === "string") {
            return [arg];
        }
        if (Array.isArray(arg)) {
            if (arg.every((item) => typeof item === "string")) {
                return arg;
            }
        }
        return zod_1.z.NEVER;
    }, zod_1.z.array(zod_1.z.string()))
        .optional(),
})
    .and(AlphanumericContextFieldPropertiesInputTypePart$Schema)
    .refine((arg) => {
    if (arg.minOccurs !== undefined && arg.maxOccurs !== undefined) {
        return arg.minOccurs <= arg.maxOccurs;
    }
    return true;
});
