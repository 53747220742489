import {
  Box,
  Image,
  Label,
  TapArea,
  Text,
  TextArea,
  TextField,
  useColors,
} from "@prodoctivity/design-system";

import { ReactNode } from "react";
import { Link } from "react-router-dom";
import LandingPageS1Bckg from "../../assets/LandingPageS1Bckg.png";
import LandingPageS1MobileBckg from "../../assets/LandingPageS1MobileBckg.png";
import LandingPageS1RImg from "../../assets/LandingPageS1RImg.png";
import LandingPageS2BckgItem1 from "../../assets/LandingPageS2BckgItem1.png";
import LandingPageS2BckgItem2 from "../../assets/LandingPageS2BckgItem2.png";
import LandingPageS2BckgItem3 from "../../assets/LandingPageS2BckgItem3.png";
import LandingPageS2LImg from "../../assets/LandingPageS2LImg.png";
import LandingPageS3BckgItem1 from "../../assets/LandingPageS3BckgItem1.png";
import LandingPageS3BckgItem2 from "../../assets/LandingPageS3BckgItem2.png";
import LandingPageS5BckgItem1 from "../../assets/LandingPageS5BckgItem1.png";
import LandingPageS5BckgItem2 from "../../assets/LandingPageS5BckgItem2.png";
import LandingPageS5LImg from "../../assets/LandingPageS5LImg.png";
import LandingPageS6BImg from "../../assets/LandingPageS6BImg.png";
import LandingPageS6BckgItem1 from "../../assets/LandingPageS6BckgItem1.png";
import LandingPageS6BckgItem2 from "../../assets/LandingPageS6BckgItem2.png";
import LandingPageS7BckgItem1 from "../../assets/LandingPageS7BckgItem1.png";
import LandingPageS7BckgItem2 from "../../assets/LandingPageS7BckgItem2.png";
import LandingPageSoftBlueBckg from "../../assets/LandingPageSoftBlueBckg.png";
import { NotificationMessage } from "../../components/NotificationMessage";
import { CardBlueCornerImg } from "../../svg/CardBlueCornerImg";
import { LandingPageBlueCheckIcon } from "../../svg/LandingPageBlueCheckIcon";
import { LandingPageCardIcon1 } from "../../svg/LandingPageCardIcon1";
import { LandingPageCardIcon2 } from "../../svg/LandingPageCardIcon2";
import { LandingPageCardIcon3 } from "../../svg/LandingPageCardIcon3";
import { LandingPageS7Icon1 } from "../../svg/LandingPageS7Icon1";
import { LandingPageS7Icon2 } from "../../svg/LandingPageS7Icon2";
import { LandingPageS7Icon3 } from "../../svg/LandingPageS7Icon3";
import { VisitorsFooter } from "./VisitorsFooter";
import { VisitorsHeader } from "./VisitorsHeader";
import { useLandingPageLogic, useSendContactHelpEmail } from "./hooks";

export const BulletPointWithText = ({ children }: { children?: ReactNode }) => (
  <Box display="flex" direction="column">
    <Box display="flex" alignItems="center" marginBottom={4}>
      <Box width={24} height={24} marginEnd={2}>
        <LandingPageBlueCheckIcon />
      </Box>
      <Text size="200" weight="normal">
        {children}
      </Text>
    </Box>
  </Box>
);

export const ProductCard = ({
  product,
  description,
  children,
  icon,
  breakpoint,
}: {
  product: string;
  description: string;
  children?: ReactNode;
  icon: ReactNode;
  breakpoint?: boolean;
}) => {
  const { colors } = useColors();

  return (
    <Box
      width={"100%"}
      height={"100%"}
      maxWidth={380}
      minWidth={breakpoint ? 290 : undefined}
      padding={7}
      color={colors.white}
      rounding={3}
      dangerouslySetInlineStyle={{ __style: { boxShadow: "0px 0px 15px #00000029" } }}
      position="relative"
    >
      <Box>
        <Box position="absolute" width={100} height={90} top={true} right={true}>
          <Box position="relative" display="flex" justifyContent="end">
            <Box position="absolute" paddingX={4} paddingY={3}>
              {icon}
            </Box>
            <CardBlueCornerImg />
          </Box>
        </Box>
        <Box marginBottom={10}>
          <Text weight="bold" size="400" inline={true}>
            ProDoctivity{" "}
          </Text>
          <Text weight="normal" size="400" inline={true}>
            {product}
          </Text>
        </Box>
      </Box>
      <Box marginBottom={5}>
        <Text size="200" color={colors.black600}>
          {description}
        </Text>
      </Box>
      <Box height={0} color={colors.neutral600} marginBottom={5} />

      {children}
    </Box>
  );
};

function LandingPage() {
  const {
    resources,
    breakpoint,
    breakPointSmall,
    breakPointSmallDuo,
    breakPointLarge,
    titleSize,
    flexDirection,
    boxProps,
    signIn,
  } = useLandingPageLogic();

  const {
    setEmail,
    setName,
    setLastName,
    setPhoneNumber,
    setMessage,
    handleSubmit,
    showToast,
    setShowToast,
    toastMessage,
    isButtonEnabled,
  } = useSendContactHelpEmail();

  const { colors } = useColors();

  return (
    <Box display="flex" direction="column" flex="grow">
      {/* {Header} */}
      <VisitorsHeader />
      {/* Section #1 (S1) */}
      <Box
        display="flex"
        direction="column"
        flex="grow"
        minHeight={742}
        height={"fit-content"}
        position="relative"
        justifyContent="center"
        alignItems="center"
        dangerouslySetInlineStyle={{ __style: { background: "#F7FDFE" } }}
      >
        {breakPointSmallDuo ? (
          <Box
            position="absolute"
            height={742}
            bottom={true}
            display="flex"
            direction="column"
            flex="grow"
          >
            <Image
              color="transparent"
              alt="landingPage_S1_background"
              naturalHeight={80}
              naturalWidth={389}
              src={LandingPageS1MobileBckg}
              fit="cover"
            ></Image>
          </Box>
        ) : (
          <Box
            position="absolute"
            height={742}
            width={"100%"}
            bottom={true}
            display="flex"
            direction="column"
            flex="grow"
          >
            <Image
              color="transparent"
              alt="landingPage_S1_background"
              naturalHeight={80}
              naturalWidth={389}
              src={LandingPageS1Bckg}
              fit="cover"
            ></Image>
          </Box>
        )}
        <Box
          maxWidth={1520}
          display="flex"
          justifyContent="center"
          width={"100%"}
          height={"fit-content"}
          paddingX={8}
        >
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            height={720}
            width={"100%"}
            maxWidth={1296}
            paddingX={breakPointSmall ? 2 : 12}
            position="relative"
            {...boxProps}
          >
            {breakPointLarge && !breakPointSmallDuo ? (
              <Box position="absolute" right={true} width={372} height={400}>
                <Image
                  color="transparent"
                  alt="landingPage_S1_illustration"
                  naturalWidth={372}
                  naturalHeight={400}
                  src={LandingPageS1RImg}
                  fit="contain"
                ></Image>
              </Box>
            ) : breakPointSmallDuo ? null : (
              <Box position="absolute" right={true} width={420} height={450}>
                <Image
                  color="transparent"
                  alt="landingPage_S1_illustration"
                  naturalHeight={514}
                  naturalWidth={480}
                  src={LandingPageS1RImg}
                  fit="contain"
                ></Image>
              </Box>
            )}
            <Box width={breakPointSmallDuo ? "100%" : "50%"} position="relative">
              <Box>
                <Box maxWidth={450} marginTop={breakPointSmall ? 0 : -2} marginBottom={8}>
                  <Text weight={"normal"} size={titleSize} color={colors.black600}>
                    {resources.landingPageNew.streamlineDocumentCreationAndManagementWith}{" "}
                    <Box display="inlineBlock">
                      <Box display="flex">
                        <Text weight="bold" size={titleSize} color={colors.primary}>
                          Pro
                        </Text>
                        <Text weight="bold" size={titleSize} color={colors.secondary}>
                          Doc
                        </Text>
                        <Text weight="bold" size={titleSize} color={colors.primary}>
                          tivity
                        </Text>
                      </Box>
                    </Box>
                  </Text>
                </Box>
              </Box>
              <Box display="flex" height={"fit-content"} marginBottom={10}>
                <Box width={1} minHeight={"100%"} color={colors.secondary}></Box>
                <Box paddingX={4} width={468}>
                  <Text size="200" weight="normal">
                    {resources.landingPageNew.achieveStrategicObjectives}
                  </Text>
                </Box>
              </Box>
              {breakPointSmallDuo && (
                <Box left={true} width={240} height={272} margin={"auto"}>
                  <Image
                    color="transparent"
                    alt="landingPage_S1_illustration"
                    naturalWidth={240}
                    naturalHeight={272}
                    src={LandingPageS1RImg}
                    fit="contain"
                  ></Image>
                </Box>
              )}
              <Box
                width={"100%"}
                display="flex"
                justifyContent={breakPointSmallDuo ? "center" : "start"}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  width={340}
                  height={40}
                  padding={3}
                  color={colors.primary}
                  rounding={2}
                  marginTop={breakPointSmallDuo ? 6 : undefined}
                >
                  <TapArea>
                    <Box display="flex" justifyContent="center" onClickCapture={signIn}>
                      <Text size="200" weight="bold" color={colors.white}>
                        {resources.landingPageNew.buttonLabels.tryItNow}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Section #2 (S2) */}
      <Box
        display="flex"
        direction="column"
        flex="grow"
        minHeight={720}
        paddingY={10}
        height={"fit-content"}
        position="relative"
        justifyContent="center"
        alignItems="center"
        dangerouslySetInlineStyle={{ __style: { background: "#FFFFFF" } }}
      >
        {breakPointSmallDuo ? (
          <Box
            position="absolute"
            height={73}
            top={true}
            display="flex"
            direction="column"
            flex="grow"
          >
            <Image
              color="transparent"
              alt=""
              naturalHeight={73}
              naturalWidth={1000}
              src={LandingPageS2BckgItem1}
              fit="cover"
            ></Image>
          </Box>
        ) : (
          <Box
            position="absolute"
            height={132}
            top={true}
            display="flex"
            direction="column"
            flex="grow"
          >
            <Image
              color="transparent"
              alt=""
              naturalHeight={80}
              naturalWidth={389}
              src={LandingPageS2BckgItem1}
              fit="cover"
            ></Image>
          </Box>
        )}
        <Box
          position="absolute"
          maxWidth={1520}
          height={"100%"}
          display="flex"
          direction="column"
          flex="grow"
        >
          <Box
            position="absolute"
            height={breakPointSmallDuo ? 167 : 265}
            width={breakPointSmallDuo ? 198 : 314}
            left={true}
            bottom={true}
            marginStart={-12}
          >
            <Image
              color="transparent"
              alt=""
              naturalHeight={breakPointSmallDuo ? 167 : 265}
              naturalWidth={breakPointSmallDuo ? 198 : 314}
              src={LandingPageS2BckgItem2}
              fit="cover"
            ></Image>
          </Box>
          <Box
            position="absolute"
            height={breakPointSmallDuo ? 126 : 154}
            width={breakPointSmallDuo ? 261 : 320}
            right={true}
            bottom={true}
            marginEnd={-12}
          >
            <Image
              color="transparent"
              alt=""
              naturalHeight={breakPointSmallDuo ? 126 : 154}
              naturalWidth={breakPointSmallDuo ? 261 : 320}
              src={LandingPageS2BckgItem3}
              fit="cover"
            ></Image>
          </Box>
        </Box>
        <Box
          maxWidth={1520}
          display="flex"
          justifyContent="center"
          width={"100%"}
          height={"fit-content"}
          paddingX={breakPointSmall ? 0 : 8}
        >
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            minHeight={720}
            marginTop={breakPointSmallDuo ? 10 : undefined}
            height={"fit-content"}
            width={"100%"}
            maxWidth={1296}
            paddingX={12}
            position="relative"
            {...boxProps}
          >
            {breakPointSmallDuo ? null : (
              <Box position="absolute" left={true} width={420} height={450}>
                <Image
                  color="transparent"
                  alt="landingPage_S2_illustration"
                  naturalHeight={514}
                  naturalWidth={480}
                  src={LandingPageS2LImg}
                  fit="contain"
                ></Image>
              </Box>
            )}
            <Box
              maxWidth={breakPointSmallDuo ? undefined : 554}
              width={breakPointSmallDuo ? "100%" : "50%"}
              position="relative"
            >
              <Box>
                <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                  <Text weight="bold" align="center" size="400" color={colors.black600}>
                    {resources.landingPageNew.howDoWeSolveYourNeeds}
                  </Text>
                </Box>
              </Box>
              <Box display="flex" direction="column" marginBottom={6}>
                <Box marginStart={breakPointSmallDuo ? undefined : 7} marginBottom={3}>
                  <Text size="200" weight="normal">
                    {resources.landingPageNew.wontNeedToInstallSoftware}
                  </Text>
                </Box>
                <Box display="flex" {...boxProps}>
                  <Box width={breakPointSmallDuo ? "100%" : 278}>
                    <BulletPointWithText>
                      {resources.landingPageNew.generateDocumentsFromTemplate}
                    </BulletPointWithText>
                    <BulletPointWithText>
                      {resources.landingPageNew.performOpenAndStructuredSearches}
                    </BulletPointWithText>
                    <BulletPointWithText>
                      {resources.landingPageNew.completeAssignedTasks}
                    </BulletPointWithText>
                    <BulletPointWithText>
                      {resources.landingPageNew.manageFiles}
                    </BulletPointWithText>
                  </Box>

                  <Box width={breakPointSmallDuo ? "100%" : 278}>
                    <BulletPointWithText>
                      {resources.landingPageNew.createDocumentsByImportingOrDigitizing}
                    </BulletPointWithText>
                    <BulletPointWithText>
                      {resources.landingPageNew.andMuchMore}
                    </BulletPointWithText>
                  </Box>
                </Box>
              </Box>

              {breakPointSmallDuo && (
                <Box left={true} width={200} height={280} margin={"auto"}>
                  <Image
                    color="transparent"
                    alt="landingPage_S2_illustration"
                    naturalHeight={200}
                    naturalWidth={280}
                    src={LandingPageS2LImg}
                    fit="contain"
                  ></Image>
                </Box>
              )}

              <Box
                width={"100%"}
                display="flex"
                justifyContent={breakPointSmallDuo ? "center" : "end"}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  width={280}
                  height={40}
                  padding={3}
                  color={colors.primary}
                  rounding={2}
                  marginTop={breakPointSmallDuo ? 6 : undefined}
                >
                  <TapArea>
                    <Box display="flex" justifyContent="center" onClickCapture={signIn}>
                      <Text size="200" weight="bold" color={colors.white}>
                        {resources.landingPageNew.buttonLabels.startProcess}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Section #3 (S3) */}
      <Box
        display="flex"
        direction="column"
        flex="grow"
        minHeight={720}
        paddingY={10}
        height={"fit-content"}
        position="relative"
        justifyContent="center"
        alignItems="center"
        dangerouslySetInlineStyle={{ __style: { background: "rgba(202,241,255,0.25)" } }}
      >
        <Box position="absolute" width={"100%"} height={"100%"}>
          <Image
            color="transparent"
            alt=""
            naturalWidth={389}
            naturalHeight={80}
            src={LandingPageSoftBlueBckg}
            fit="cover"
          ></Image>
        </Box>
        <Box
          position="absolute"
          maxWidth={1520}
          height={"100%"}
          display="flex"
          direction="column"
          flex="grow"
        >
          <Box
            position="absolute"
            width={breakPointSmallDuo ? 267 : 705}
            height={breakPointSmallDuo ? 269 : 604}
            left={true}
            bottom={true}
            marginStart={-12}
          >
            <Image
              color="transparent"
              alt=""
              naturalWidth={breakPointSmallDuo ? 267 : 705}
              naturalHeight={breakPointSmallDuo ? 269 : 604}
              src={LandingPageS3BckgItem1}
              fit="cover"
            ></Image>
          </Box>
          <Box
            position="absolute"
            right={true}
            top={true}
            width={breakPointSmallDuo ? 262 : 320}
            height={breakPointSmallDuo ? 129 : 158}
            marginEnd={-12}
          >
            <Image
              color="transparent"
              alt=""
              naturalWidth={breakPointSmallDuo ? 262 : 320}
              naturalHeight={breakPointSmallDuo ? 129 : 158}
              src={LandingPageS3BckgItem2}
              fit="cover"
            ></Image>
          </Box>
        </Box>
        <Box
          maxWidth={1520}
          display="flex"
          justifyContent="center"
          width={"100%"}
          minHeight={720}
          height={"100%"}
          paddingX={8}
        >
          <Box
            display="flex"
            direction="column"
            justifyContent="center"
            alignItems="center"
            height={"100%"}
            minHeight={"fit-content"}
            paddingY={10}
            width={"100%"}
            maxWidth={1296}
            paddingX={12}
            position="relative"
          >
            <Box>
              <Box marginBottom={10} display="inlineBlock" width={"100%"}>
                <Text weight="bold" align="center" size="400" color={colors.black600}>
                  {resources.landingPageNew.whatToolsWillYouGetAccessTo}
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent={breakPointSmallDuo || breakPointLarge ? "center" : "between"}
              width={"100%"}
              minHeight={"fit-content"}
              height={"100%"}
              marginBottom={10}
              wrap={breakPointSmallDuo || breakPointLarge ? true : false}
            >
              <Box marginBottom={6}>
                <ProductCard
                  breakpoint={breakPointSmallDuo}
                  product="Capture"
                  description={resources.pricingPage.pricingDescription}
                  icon={<LandingPageCardIcon1 />}
                >
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.integrationOnBase}
                  </BulletPointWithText>
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.barCodeIdentification}
                  </BulletPointWithText>
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.massiveBatchDigitization}
                  </BulletPointWithText>
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.autoDocSeparation}
                  </BulletPointWithText>
                </ProductCard>
              </Box>
              <Box
                marginBottom={6}
                marginStart={breakPointSmallDuo ? undefined : 4}
                marginEnd={breakPointSmallDuo ? undefined : 4}
              >
                <ProductCard
                  breakpoint={breakPointSmallDuo}
                  product="ECM"
                  description={resources.landingPageNew.cards.easyImplementTool}
                  icon={<LandingPageCardIcon2 />}
                >
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.storageDatabases}
                  </BulletPointWithText>
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.scanOnlineAndIndex}
                  </BulletPointWithText>
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.authenticityProof}
                  </BulletPointWithText>
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.toolIntegrationWithAPIs}
                  </BulletPointWithText>
                </ProductCard>
              </Box>
              <Box marginBottom={6}>
                <ProductCard
                  breakpoint={breakPointSmallDuo}
                  product="Fluency"
                  description={resources.landingPageNew.cards.configurableCaptureForm}
                  icon={<LandingPageCardIcon3 />}
                >
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.autoDocGeneration}
                  </BulletPointWithText>
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.emailNotificationAccountStatements}
                  </BulletPointWithText>
                  <BulletPointWithText>
                    {resources.landingPageNew.cards.bulletPoints.distributionToDigitalMedia}
                  </BulletPointWithText>
                </ProductCard>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              width={280}
              height={40}
              padding={3}
              color={colors.primary}
              rounding={2}
            >
              <TapArea>
                <Box display="flex" justifyContent="center" onClickCapture={signIn}>
                  <Text size="200" weight="bold" color={colors.white}>
                    {resources.landingPageNew.buttonLabels.getAccessNow}
                  </Text>
                </Box>
              </TapArea>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Section #5 (S5) */}
      <Box
        display="flex"
        direction="column"
        flex="grow"
        minHeight={720}
        paddingY={10}
        height={"fit-content"}
        position="relative"
        justifyContent="center"
        alignItems="center"
        dangerouslySetInlineStyle={{ __style: { background: "#FFFFFF" } }}
      >
        <Box
          position="absolute"
          maxWidth={1520}
          height={"100%"}
          display="flex"
          direction="column"
          flex="grow"
        >
          {breakPointSmallDuo ? (
            <Box position="relative" display="flex" alignItems="center" height={1160}>
              <Box position="absolute">
                <Box></Box>
                <Box
                  position="absolute"
                  width={breakPointSmallDuo ? 150 : 225}
                  height={breakPointSmallDuo ? 200 : 300}
                  left={true}
                  top={true}
                  marginStart={12}
                >
                  <Image
                    color="transparent"
                    alt=""
                    naturalWidth={breakPointSmallDuo ? 150 : 225}
                    naturalHeight={breakPointSmallDuo ? 200 : 300}
                    src={LandingPageS5BckgItem1}
                    fit="cover"
                  ></Image>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              position="absolute"
              width={breakPointSmallDuo ? 150 : 225}
              height={breakPointSmallDuo ? 200 : 300}
              left={true}
              top={true}
              marginTop={12}
              marginStart={12}
            >
              <Image
                color="transparent"
                alt=""
                naturalWidth={breakPointSmallDuo ? 150 : 225}
                naturalHeight={breakPointSmallDuo ? 200 : 300}
                src={LandingPageS5BckgItem1}
                fit="cover"
              ></Image>
            </Box>
          )}
          <Box
            position="absolute"
            width={breakPointSmallDuo ? 332 : 484}
            height={breakPointSmallDuo ? 320 : 433}
            right={true}
            bottom={true}
            marginEnd={-12}
          >
            <Image
              color="transparent"
              alt=""
              naturalWidth={breakPointSmallDuo ? 332 : 484}
              naturalHeight={breakPointSmallDuo ? 320 : 433}
              src={LandingPageS5BckgItem2}
              fit="cover"
            ></Image>
          </Box>
        </Box>
        <Box
          maxWidth={1520}
          display="flex"
          justifyContent="center"
          width={"100%"}
          height={"fit-content"}
          paddingX={breakPointSmall ? 0 : 8}
        >
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            minHeight={720}
            marginTop={breakPointSmallDuo ? 10 : undefined}
            height={"fit-content"}
            width={"100%"}
            maxWidth={1296}
            paddingX={12}
            position="relative"
            {...boxProps}
          >
            {breakPointSmallDuo ? null : (
              <Box
                position="absolute"
                left={true}
                width={breakPointLarge ? 300 : 386}
                height={breakPointLarge ? 424 : 544}
                rounding={2}
                color={colors.white}
                marginStart={12}
                dangerouslySetInlineStyle={{ __style: { boxShadow: "0px 0px 15px #00000029" } }}
              >
                <Image
                  color="transparent"
                  alt=""
                  naturalWidth={breakPointLarge ? 300 : 386}
                  naturalHeight={breakPointLarge ? 424 : 544}
                  src={LandingPageS5LImg}
                  fit="contain"
                ></Image>
              </Box>
            )}
            <Box
              maxWidth={breakPointSmallDuo ? undefined : 520}
              width={breakPointSmallDuo ? "100%" : "50%"}
              position="relative"
            >
              <Box>
                <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                  <Text weight="bold" align="start" size="400" color={colors.black600}>
                    {resources.landingPageNew.whenCanYouStartGeneratingDocuments}
                  </Text>
                </Box>
              </Box>
              <Box display="flex" direction="column" marginBottom={6}>
                <Box marginBottom={9}>
                  <Text size="200" weight="normal">
                    {resources.landingPageNew.startGeneratingDocumentsInAMinute}
                  </Text>
                </Box>
                <Box display="flex" direction="column" gap={3}>
                  <Box>
                    <Text weight="bold" size="200">
                      {resources.landingPage.orgName}
                    </Text>
                  </Box>
                  <Box>
                    <TextField
                      id="organizationName"
                      onChange={() => console.log()}
                      placeholder={"Novosit"}
                      label={resources.landingPage.orgName}
                      labelDisplay="hidden"
                    />
                  </Box>
                  <Box>
                    <Text weight="bold" size="200">
                      {resources.landingPage.orgId}
                    </Text>
                  </Box>
                  <Box>
                    <TextField
                      id="organizationId"
                      onChange={() => console.log()}
                      placeholder={"#200-777-0000-PD"}
                      label={resources.landingPage.orgId}
                      labelDisplay="hidden"
                    />
                  </Box>
                  <Box>
                    <Text weight="bold" size="200">
                      {resources.landingPageNew.inputLabels.documentsToGenerate}
                    </Text>
                  </Box>
                  <Box>
                    <TextField
                      id="amountToGenerate"
                      onChange={() => console.log()}
                      placeholder={"+ 15.000.000"}
                      label={resources.landingPageNew.inputLabels.documentsToGenerate}
                      labelDisplay="hidden"
                    />
                  </Box>
                </Box>
              </Box>

              <Box width={"100%"} display="flex" justifyContent={"center"}>
                <Box
                  display="flex"
                  alignItems="center"
                  width={"100%"}
                  height={40}
                  padding={3}
                  color={colors.primary}
                  rounding={2}
                  marginTop={breakPointSmallDuo ? 6 : undefined}
                  marginBottom={breakPointSmallDuo ? 12 : undefined}
                >
                  <TapArea>
                    <Box display="flex" justifyContent="center" onClickCapture={signIn}>
                      <Text size="200" weight="bold" color={colors.white}>
                        {resources.generate}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Box>

              {breakPointSmallDuo && (
                <Box
                  width={breakPointSmall ? 280 : 350}
                  height={breakPointSmall ? 395 : 495}
                  rounding={2}
                  margin={"auto"}
                  color={colors.white}
                  dangerouslySetInlineStyle={{ __style: { boxShadow: "0px 0px 15px #00000029" } }}
                >
                  <Image
                    color="transparent"
                    alt=""
                    naturalWidth={breakPointSmall ? 280 : 350}
                    naturalHeight={breakPointSmall ? 395 : 495}
                    src={LandingPageS5LImg}
                    fit="contain"
                  ></Image>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Section #6 (S6) */}
      <Box
        display="flex"
        direction="column"
        flex="grow"
        minHeight={720}
        paddingY={10}
        height={"fit-content"}
        position="relative"
        justifyContent="center"
        alignItems="center"
        dangerouslySetInlineStyle={{ __style: { background: "rgba(202,241,255,0.25)" } }}
      >
        <Box position="absolute" width={"100%"} height={"100%"}>
          <Image
            color="transparent"
            alt=""
            naturalWidth={389}
            naturalHeight={80}
            src={LandingPageSoftBlueBckg}
            fit="cover"
          ></Image>
        </Box>
        <Box
          position="absolute"
          maxWidth={1520}
          height={"100%"}
          display="flex"
          direction="column"
          flex="grow"
        >
          <Box
            position="absolute"
            width={breakPointSmallDuo ? 127 : 340}
            height={breakPointSmallDuo ? 132 : 321}
            left={true}
            top={true}
            marginStart={breakPointSmallDuo ? -6 : -12}
          >
            <Image
              color="transparent"
              alt=""
              naturalWidth={breakPointSmallDuo ? 127 : 340}
              naturalHeight={breakPointSmallDuo ? 132 : 321}
              src={LandingPageS6BckgItem1}
              fit="cover"
            ></Image>
          </Box>
          <Box
            position="absolute"
            right={true}
            top={true}
            width={breakPointSmallDuo ? 127 : 340}
            height={breakPointSmallDuo ? 132 : 321}
            marginEnd={breakPointSmallDuo ? -6 : -12}
          >
            <Image
              color="transparent"
              alt=""
              naturalWidth={breakPointSmallDuo ? 127 : 340}
              naturalHeight={breakPointSmallDuo ? 132 : 321}
              src={LandingPageS6BckgItem2}
              fit="cover"
            ></Image>
          </Box>
        </Box>
        <Box
          maxWidth={1520}
          display="flex"
          justifyContent="center"
          width={"100%"}
          minHeight={720}
          height={"100%"}
          paddingX={8}
        >
          <Box
            display="flex"
            direction="column"
            justifyContent="between"
            height={"100%"}
            minHeight={720}
            width={"100%"}
            maxWidth={1296}
            paddingX={breakPointSmallDuo ? 4 : 12}
            marginTop={10}
            position="relative"
          >
            <Box
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
              width={"100%"}
            >
              <Box marginBottom={10} display="inlineBlock" width={"100%"}>
                <Text weight="bold" align="center" size="400" color={colors.black600}>
                  {resources.landingPageNew.whyAreYouStillWaiting}
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent={"center"}
                width={"100%"}
                minHeight={"fit-content"}
                height={"100%"}
                marginBottom={8}
                wrap={breakPointSmallDuo || breakPointLarge ? true : false}
              >
                <Box
                  display="flex"
                  width={breakPointSmallDuo ? "100%" : "50%"}
                  gap={5}
                  minWidth={300}
                >
                  <Box width={"50%"}>
                    <Box
                      minWidth={breakPointSmallDuo ? 150 : undefined}
                      width={"100%"}
                      position="relative"
                    >
                      <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                        <Text weight="bold" size="300" color={colors.black600}>
                          {resources.landingPageNew.webBased}
                        </Text>
                      </Box>
                      <Box display="flex" direction="column" marginBottom={6}>
                        <Box marginBottom={3}>
                          <Text size="200" weight="normal">
                            {resources.landingPageNew.consultationAndVisualization}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box width={"50%"}>
                    <Box
                      minWidth={breakPointSmallDuo ? 150 : undefined}
                      width={"100%"}
                      position="relative"
                    >
                      <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                        <Text weight="bold" size="300" color={colors.black600}>
                          {resources.landingPageNew.countWithIntegrations}
                        </Text>
                      </Box>
                      <Box display="flex" direction="column" marginBottom={6}>
                        <Box marginBottom={3}>
                          <Text size="200" weight="normal">
                            OnBase - DocuSign - MS Office - XML Webservices API - BPM
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  width={breakPointSmallDuo ? "100%" : "50%"}
                  gap={5}
                  minWidth={300}
                >
                  <Box width={"50%"}>
                    <Box
                      minWidth={breakPointSmallDuo ? 150 : undefined}
                      width={"100%"}
                      position="relative"
                    >
                      <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                        <Text weight="bold" size="300" color={colors.black600}>
                          {resources.landingPageNew.offerCompatibility}
                        </Text>
                      </Box>
                      <Box display="flex" direction="column" marginBottom={6}>
                        <Box marginBottom={3}>
                          <Text size="200" weight="normal">
                            IOS - Android - Windows
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box width={"50%"}>
                    <Box
                      minWidth={breakPointSmallDuo ? 150 : undefined}
                      width={"100%"}
                      position="relative"
                    >
                      <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                        <Text weight="bold" size="300" color={colors.black600}>
                          {resources.landingPageNew.multipurposeData}
                        </Text>
                      </Box>
                      <Box display="flex" direction="column" marginBottom={6}>
                        <Box marginBottom={3}>
                          <Text size="200" weight="normal">
                            CRM - ERP - LOB - RRHH
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                width={280}
                height={40}
                padding={3}
                color={colors.primary}
                rounding={2}
              >
                <TapArea>
                  <Box display="flex" justifyContent="center" onClickCapture={signIn}>
                    <Text size="200" weight="bold" color={colors.white}>
                      {resources.landingPageNew.buttonLabels.getAccessNow}
                    </Text>
                  </Box>
                </TapArea>
              </Box>
            </Box>
            <Box display="flex" marginBottom={-12} position="relative">
              <Box width={488} height={295} rounding={2} margin={"auto"}>
                <Image
                  color="transparent"
                  alt=""
                  naturalWidth={488}
                  naturalHeight={295}
                  src={LandingPageS6BImg}
                  fit="contain"
                ></Image>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Section #7 (S7) */}
      <Box
        display="flex"
        direction="column"
        flex="grow"
        minHeight={720}
        paddingY={10}
        height={"fit-content"}
        position="relative"
        justifyContent="center"
        alignItems="center"
        dangerouslySetInlineStyle={{ __style: { background: "#FFFFFF" } }}
      >
        <Box
          position="absolute"
          marginEnd={breakPointSmallDuo ? undefined : -12}
          maxWidth={1720}
          height={"100%"}
          display="flex"
          direction="column"
          flex="grow"
          paddingY={12}
          marginStart={-12}
        >
          {breakPointSmallDuo ? (
            <Box position="relative" display="flex" alignItems="center" height={1160}>
              <Box position="absolute">
                <Box
                  position="absolute"
                  width={breakPointSmallDuo ? 137 : 275}
                  height={breakPointSmallDuo ? 200 : 400}
                  left={true}
                  top={true}
                >
                  <Image
                    color="transparent"
                    alt=""
                    naturalWidth={breakPointSmallDuo ? 137 : 275}
                    naturalHeight={breakPointSmallDuo ? 200 : 400}
                    src={LandingPageS7BckgItem1}
                    fit="cover"
                  ></Image>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              position="absolute"
              width={breakPointSmallDuo ? 137 : 275}
              height={breakPointSmallDuo ? 200 : 400}
              left={true}
              marginStart={-12}
              marginTop={12}
            >
              <Image
                color="transparent"
                alt=""
                naturalWidth={breakPointSmallDuo ? 150 : 275}
                naturalHeight={breakPointSmallDuo ? 200 : 400}
                src={LandingPageS7BckgItem1}
                fit="cover"
              ></Image>
            </Box>
          )}
        </Box>
        <Box
          maxWidth={1520}
          display="flex"
          justifyContent="center"
          width={"100%"}
          height={"fit-content"}
          paddingX={breakPointSmall ? 0 : 8}
        >
          <Box
            position="absolute"
            maxWidth={1520}
            height={"100%"}
            display="flex"
            direction="column"
            flex="grow"
          >
            <Box
              position="absolute"
              width={breakPointSmallDuo ? 120 : 300}
              height={breakPointSmallDuo ? 174 : 285}
              right={true}
              top={true}
              paddingX={breakPointSmallDuo ? undefined : 12}
            >
              <Image
                color="transparent"
                alt=""
                naturalWidth={breakPointSmallDuo ? 120 : 208}
                naturalHeight={breakPointSmallDuo ? 174 : 285}
                src={LandingPageS7BckgItem2}
                fit="cover"
              ></Image>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="between"
            alignItems="center"
            minHeight={720}
            marginTop={breakPointSmallDuo ? 10 : undefined}
            height={"fit-content"}
            width={"100%"}
            maxWidth={1296}
            paddingX={12}
            position="relative"
            {...boxProps}
          >
            <Box
              maxWidth={breakPointSmallDuo ? undefined : 520}
              width={breakPointSmallDuo ? "100%" : "50%"}
              position="relative"
            >
              <Box>
                <Box marginBottom={4} display="inlineBlock" width={"100%"}>
                  <Text weight="bold" align="start" size="400" color={colors.black600}>
                    {resources.landingPageNew.contactUsForMoreInformation}
                  </Text>
                </Box>
              </Box>
              <Box display="flex" direction="column" marginBottom={6}>
                <Box marginBottom={9}>
                  <Text size="200" weight="normal">
                    {resources.landingPageNew.optimizeTheWayYouGenerateAndManageYourDocuments}{" "}
                    <Link style={{ textDecoration: "none" }} to={"/"}>
                      <Text inline={true} size="200" color={colors.primary}>
                        {resources.paymentPage.contactUs}
                      </Text>
                    </Link>
                  </Text>
                </Box>
                <Box display="flex" direction="column" gap={3}>
                  <Box display="flex" gap={5} direction={flexDirection}>
                    <Box width={"100%"}>
                      <Box marginBottom={3}>
                        <Text weight="bold" size="200">
                          {resources.firstName}
                        </Text>
                      </Box>
                      <Box>
                        <TextField
                          id="firstName"
                          onChange={(e) => setName(e.value)}
                          placeholder={""}
                          label={resources.firstName}
                          labelDisplay="hidden"
                        />
                      </Box>
                    </Box>
                    <Box width={"100%"}>
                      <Box marginBottom={3}>
                        <Text weight="bold" size="200">
                          {resources.lastName}
                        </Text>
                      </Box>
                      <Box>
                        <TextField
                          id="lastName"
                          onChange={(e) => setLastName(e.value)}
                          placeholder={""}
                          label={resources.lastName}
                          labelDisplay="hidden"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" gap={5} direction={flexDirection}>
                    <Box width={"100%"}>
                      <Box marginBottom={3}>
                        <Text weight="bold" size="200">
                          {resources.email}
                        </Text>
                      </Box>
                      <Box>
                        <TextField
                          id="emailInput"
                          onChange={(e) => setEmail(e.value)}
                          placeholder={""}
                          label={resources.email}
                          labelDisplay="hidden"
                        />
                      </Box>
                    </Box>
                    <Box width={"100%"}>
                      <Box marginBottom={3}>
                        <Text weight="bold" size="200">
                          {resources.phone}
                        </Text>
                      </Box>
                      <Box>
                        <TextField
                          id="phoneInput"
                          onChange={(e) => setPhoneNumber(e.value)}
                          placeholder={"(555) 555-5555"}
                          label={resources.phone}
                          labelDisplay="hidden"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  direction="column"
                  marginEnd={8}
                  marginBottom={4}
                  width={"100%"}
                  marginTop={3}
                >
                  <Box marginBottom={3}>
                    <Label htmlFor="messageInput">
                      <Text color={colors.black600} weight="bold" size="200">
                        {resources.landingPageNew.inputLabels.messageLabel}
                      </Text>
                    </Label>
                  </Box>
                  <Box height={"fit-content"}>
                    <TextArea
                      onChange={(e) => setMessage(e.value)}
                      placeholder={resources.landingPageNew.placeholders.messagePlaceholder}
                      id="messageInput"
                      maxLength={{
                        characterCount: 10000,
                        errorAccessibilityLabel: resources.dataDictionary.limitReached,
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box width={"100%"} display="flex" justifyContent={"center"}>
                <Box
                  display="flex"
                  alignItems="center"
                  width={"100%"}
                  height={40}
                  padding={3}
                  color={isButtonEnabled ? colors.primary : colors.neutral600}
                  rounding={2}
                  marginBottom={breakPointSmallDuo ? 12 : undefined}
                >
                  {isButtonEnabled ? (
                    <TapArea>
                      <Box display="flex" justifyContent="center" onClickCapture={handleSubmit}>
                        <Text size="200" weight="bold" color={colors.white}>
                          {resources.paymentPage.contactUs}
                        </Text>
                      </Box>
                    </TapArea>
                  ) : (
                    <TapArea>
                      <Box display="flex" justifyContent="center">
                        <Text size="200" weight="bold" color={colors.white}>
                          {resources.paymentPage.contactUs}
                        </Text>
                      </Box>
                    </TapArea>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              width={breakPointSmallDuo ? "100%" : "50%"}
              position="relative"
              paddingX={breakPointSmallDuo ? undefined : 12}
            >
              <Box display="flex" direction="column" gap={6}>
                <Box display="flex" maxWidth={330} gap={4} alignItems="center">
                  <Box display="flex" alignItems="center" width={46} height={46}>
                    <LandingPageS7Icon1 />
                  </Box>
                  <Box>
                    <Box marginBottom={2} display="inlineBlock" width={"100%"}>
                      <Text weight="bold" align="start" size="200" color={colors.black600}>
                        {resources.email}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="200" weight="normal" ellipsisLength={28}>
                        info@prodoctivity.com
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" maxWidth={330} gap={4} alignItems="center">
                  <Box display="flex" alignItems="center" width={46} height={46} marginTop={4}>
                    <LandingPageS7Icon2 />
                  </Box>
                  <Box>
                    <Box marginBottom={2} display="inlineBlock" width={"100%"}>
                      <Text weight="bold" align="start" size="200" color={colors.black600}>
                        {resources.phone}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="200" weight="normal">
                        {"(809) 440-7964"}
                      </Text>
                      {/* <Text size="200" weight="normal">
                        {"(809) 440-7964"}
                      </Text> */}
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" maxWidth={330} gap={4} alignItems="center">
                  <Box display="flex" alignItems="center" width={46} height={46}>
                    <LandingPageS7Icon3 />
                  </Box>
                  <Box minWidth={breakPointSmall ? undefined : 270} width={"100%"}>
                    <Box marginBottom={2} display="inlineBlock" width={"100%"}>
                      <Text weight="bold" align="start" size="200" color={colors.black600}>
                        {resources.address}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="200" weight="normal">
                        {
                          "Av Pedro Antonio Bobea esquina Anacaona, Santo Domingo, República Dominicana"
                        }
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* {Footer} */}
      <VisitorsFooter breakPointSmallDuo={breakPointSmallDuo} breakpoints={breakpoint} />
      {/* {Email Confirmation Toast} */}
      {showToast.show && (
        <Box>
          {showToast ? (
            <NotificationMessage
              type={showToast.isError ? "error" : "success"}
              message={toastMessage}
              position="bottom-left"
              handleDismiss={() => setShowToast({ show: false, isError: false })}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
}

export default LandingPage;
