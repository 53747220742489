import {
  Box,
  Button,
  Layer,
  Modal,
  modalZIndex,
  TapArea,
  Text,
  TextField,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { Dispatch, FunctionComponent, ReactNode, SetStateAction, useState } from "react";
import { useGenerateRelatedDocument } from "./hook";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";

export type TemplateLookupProps = {
  children: ReactNode;
  isTemplateLookupOpen: boolean;
  setIsTemplateLookupOpen: Dispatch<SetStateAction<boolean>>;
};

export function TemplateLookup({
  isTemplateLookupOpen,
  setIsTemplateLookupOpen,
  children,
}: TemplateLookupProps) {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  return (
    <>
      {isTemplateLookupOpen && (
        <Layer zIndex={modalZIndex}>
          <Modal
            accessibilityModalLabel={resources.generate}
            heading={
              <Box top right width={"100%"} padding={6} position="absolute">
                <Text size="400" color={colors.secondary}>
                  {`${resources.select} ${resources.template}`}
                </Text>
              </Box>
            }
            size="md"
            onDismiss={() => ({})}
            footer={
              <Box width={"100%"} display="flex" gap={2} justifyContent="end" alignItems="end">
                <Button
                  color="gray"
                  text={resources.cancel}
                  onClick={() => setIsTemplateLookupOpen(false)}
                />
              </Box>
            }
          >
            {children}
          </Modal>
        </Layer>
      )}
    </>
  );
}

export const GenerateRelatedDocument: FunctionComponent<{
  context: ParametersObject | undefined;
}> = ({ context }) => {
  const { templateList, filterText, setFilterText, goToTemplateVersion } =
    useGenerateRelatedDocument();
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const isMobile = breakpoint === "small";
  const breakPointDUo = breakpoint === "small" || "medium";
  const [showList, setShowList] = useState<boolean>(false);

  const isListMaxHeight = templateList && templateList?.templates.length > 3;

  return (
    <Box padding={4} display="flex" justifyContent="start" direction="column" height={250}>
      <Box width="100%">
        <TextField
          placeholder={resources.searchForTemplate}
          id="search_templates"
          value={filterText}
          onChange={({ value }) => setFilterText(value)}
          onFocus={() => setShowList(true)}
          onBlur={() => setShowList(false)}
          autoComplete={"off"}
          size={"lg"}
        />
      </Box>
      {!showList && (
        <Box marginTop={8} padding={8}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Text>{resources.searchTemplateByName}</Text>
          </Box>
        </Box>
      )}
      {showList && (
        <Box
          borderRadius={6}
          width={"100%"}
          maxWidth={680}
          marginTop={breakPointDUo ? 2 : 1}
          color={colors.white}
          maxHeight={isListMaxHeight ? 174 : undefined}
          overflow={isListMaxHeight ? "scrollY" : undefined}
          onMouseDown={(e) => e.preventDefault()}
        >
          {templateList?.templates.length === 0 && (
            <Box padding={2}>
              <Box display="flex" alignItems="center" justifyContent="center">
                {resources.noResultsFound}
              </Box>
            </Box>
          )}
          {templateList?.templates.map((template) => {
            const id = template.type === "template" ? template.templateVersionId : template.id;
            return (
              <>
                <Box key={id}>
                  <TapArea onTap={() => goToTemplateVersion(template.type, id, context)}>
                    <Box display="flex" padding={4} hoverColor={colors.primaryHover0}>
                      {isMobile ? (
                        <Text ellipsisLength={38}>{template.name}</Text>
                      ) : (
                        <Text
                          title={template.name}
                          ellipsisLength={65}
                        >{`${template.documentGroupName} - ${template.name}`}</Text>
                      )}
                    </Box>
                  </TapArea>
                </Box>
              </>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
