import { useCallback, useMemo } from "react";
import { useOrganizationQuery } from "../../../../../hooks/useOrganizationQuery";
import { useServices } from "../../../../../hooks/useServices";

type ProfileDocumentLabelsListProps = {
  label: string;
  isFavorites: boolean | undefined;
  enabled: boolean;
};

export function useProfileDocumentLabelsListCount({
  label,
  isFavorites,
  enabled,
}: ProfileDocumentLabelsListProps) {
  const { getDocumentLabelsDocumentsCount } = useServices();
  const fetchDocumentsLabelsCount = useCallback(async () => {
    return await getDocumentLabelsDocumentsCount(label, isFavorites);
  }, [getDocumentLabelsDocumentsCount, label, isFavorites]);

  const {
    data: documentsLabelsCountData,
    isLoading: isLoadingDocumentsLabelsCountData,
    refetch: refetchDocumentsLabelsCount,
  } = useOrganizationQuery(`documents-labels-counts/${label}`, fetchDocumentsLabelsCount, {
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    enabled,
  });

  const documentsLabelsCount = useMemo(() => {
    return documentsLabelsCountData?.documentCount || 0;
  }, [documentsLabelsCountData]);

  return {
    isLoadingDocumentsLabelsCountData,
    documentsLabelsCount,
    refetchDocumentsLabelsCount,
  };
}
