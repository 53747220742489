import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CoinsStackIconSvg: FunctionComponent<Props> = ({
  color,
  width = 25,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5701_96557)">
        <path
          d="M0.0228399 14.8041C2.91142 16.2033 5.93756 16.8142 9.17002 16.5698C9.13563 16.381 9.10124 16.1922 9.06686 16.0146C9.04393 15.8369 9.02101 15.6481 8.99808 15.4815C8.7459 15.4815 8.49373 15.4815 8.24155 15.4815C5.85732 15.5481 3.55333 15.1261 1.34104 14.2488C0.928388 14.0822 0.527196 13.8934 0.114541 13.7046C0.0686904 13.6824 0.0113773 13.6047 0.0113773 13.5491C0.0113773 12.6274 0.0113773 11.7056 0.0113773 10.7728C0.0113773 10.7505 0.0113773 10.7283 0.0113773 10.695C0.0572278 10.7172 0.0916157 10.7283 0.126004 10.7394C1.89125 11.5946 3.7482 12.1498 5.69684 12.3831C6.88896 12.5274 8.09253 12.5496 9.29611 12.4386C9.4222 12.4386 9.46805 12.372 9.50244 12.272C9.62853 11.9833 9.76608 11.7056 9.8807 11.4502C9.17002 11.4835 8.44788 11.539 7.72573 11.539C5.05494 11.539 2.53316 10.9282 0.137466 9.78437C0.0572278 9.75105 -8.53727e-05 9.71774 -8.53727e-05 9.60668C-8.53727e-05 8.68492 -8.53727e-05 7.76317 -8.53727e-05 6.85252C-8.53727e-05 6.8303 -8.53729e-05 6.80809 0.0113773 6.76367C2.46438 7.95196 5.04347 8.58497 7.77158 8.58497C10.4997 8.58497 13.0788 7.95196 15.5318 6.76367V7.8298C15.5318 7.8298 15.5776 7.85201 15.5891 7.85201C16.2081 7.85201 16.8385 7.85201 17.4575 7.8298C20.8619 7.71875 23.9568 9.97316 24.7707 13.1715C25.7794 17.1029 23.2461 21.012 19.1425 21.8449C18.4891 21.9782 17.8243 22.0226 17.148 21.9671C17.0334 21.9671 16.9188 21.9671 16.8156 21.9671C14.3855 22.1004 12.3566 21.2786 10.7404 19.5128C10.6258 19.3906 10.5226 19.3573 10.3621 19.3795C7.16406 19.8571 4.06915 19.4684 1.08886 18.2468C0.424032 17.9691 0.0457652 17.4361 0.0113773 16.7253C-0.0230106 16.1145 0.0113773 15.4926 0.0113773 14.8818C0.0113773 14.8596 0.0113773 14.8263 0.0228399 14.7818V14.8041ZM9.9724 14.9151C9.9724 18.3023 12.7922 21.0342 16.2883 21.0342C19.773 21.0342 22.5928 18.3023 22.6042 14.9262C22.6042 11.539 19.7959 8.80708 16.2883 8.80708C12.8151 8.80708 9.9724 11.5501 9.9724 14.9151Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M-0.000223566 4.24215C-0.000223566 3.84235 0.0112391 3.43145 -0.000223566 3.03165C-0.0231488 2.5319 0.183179 2.14321 0.53852 1.82115C1.03141 1.36582 1.61601 1.08819 2.24645 0.85497C3.27809 0.477383 4.35558 0.255273 5.44453 0.133113C6.76273 -0.0223643 8.09239 -0.0445753 9.4106 0.0664797C10.9695 0.199746 12.494 0.455172 13.9154 1.1104C14.3395 1.3103 14.7293 1.54351 15.0617 1.87668C15.3826 2.19874 15.5546 2.56522 15.5431 3.02054C15.5316 3.89788 15.5431 4.78632 15.5431 5.66365C15.5431 5.77471 15.5087 5.83024 15.4056 5.88576C13.6632 6.71868 11.8292 7.27395 9.89203 7.50717C6.47616 7.91807 3.22078 7.35169 0.137328 5.88576C0.0341643 5.83024 -0.0116862 5.77471 -0.000223566 5.66365C-0.000223566 5.18612 -0.000223566 4.71969 -0.000223566 4.24215ZM7.67974 4.58642C9.07818 4.58642 10.3735 4.47536 11.6458 4.16441C12.345 3.99783 13.0213 3.78682 13.6518 3.44255C13.9039 3.29818 14.03 3.09828 14.0071 2.80954C13.9842 2.56522 13.8466 2.38753 13.6288 2.26537C13.0443 1.95442 12.4253 1.74341 11.7834 1.58793C9.28451 1.01045 6.77419 0.988237 4.25241 1.46577C3.4271 1.62125 2.62472 1.86557 1.87965 2.25426C1.63893 2.38753 1.50138 2.57632 1.48992 2.84286C1.48992 3.12049 1.62747 3.3315 1.87965 3.44255C2.25792 3.60914 2.63618 3.79793 3.03737 3.92009C4.5619 4.40873 6.14375 4.5531 7.66828 4.56421L7.67974 4.58642Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M16.7703 18.813V20.079H15.326V18.8907C14.5809 18.8018 13.8817 18.6353 13.2627 18.191C13.4461 17.7357 13.618 17.2915 13.8014 16.8251C13.9275 16.9028 14.0192 16.9583 14.1339 17.025C14.7414 17.3803 15.4062 17.5469 16.1169 17.4581C16.3118 17.4359 16.5066 17.3692 16.6671 17.2804C17.1371 17.0139 17.2058 16.4364 16.8046 16.0699C16.6212 15.9033 16.392 15.77 16.1627 15.6701C15.8189 15.5146 15.4635 15.3925 15.1082 15.237C14.707 15.0704 14.3402 14.8483 14.0307 14.5484C13.171 13.7044 13.2742 12.3273 14.237 11.5611C14.5809 11.2945 14.9706 11.1279 15.4062 11.0169V9.78418H16.839V10.9392C17.4809 11.0058 18.0655 11.1724 18.6043 11.5166C18.4209 11.9609 18.2375 12.4051 18.0541 12.8493C17.8363 12.7382 17.63 12.6383 17.4236 12.5494C16.9193 12.3496 16.3805 12.2718 15.8418 12.3718C15.3833 12.4606 15.1426 12.7827 15.2113 13.1714C15.2457 13.3602 15.3604 13.4934 15.5208 13.5823C15.8533 13.7599 16.1971 13.9043 16.541 14.0709C16.9651 14.2819 17.4236 14.4485 17.8019 14.7039C18.9023 15.4369 19.143 16.814 18.375 17.8357C18.0197 18.3132 17.5268 18.6019 16.9537 18.7685C16.8964 18.7907 16.839 18.8018 16.7588 18.8241L16.7703 18.813Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M20.6787 14.926C20.6787 15.2703 20.3921 15.5368 20.0253 15.5368C19.67 15.5368 19.3834 15.2591 19.3949 14.9149C19.3949 14.5706 19.6929 14.293 20.0597 14.293C20.415 14.293 20.6901 14.5817 20.6787 14.926Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M11.7031 14.9031C11.7031 14.5588 11.9897 14.2812 12.345 14.2923C12.7004 14.2923 12.9869 14.57 12.9869 14.9142C12.9869 15.2585 12.6889 15.5472 12.3336 15.5361C11.9782 15.5361 11.7031 15.2585 11.7031 14.9031Z"
          fill={color || "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_5701_96557">
          <rect width="25" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
