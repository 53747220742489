import {
  BannerSlim,
  Box,
  Button,
  ColorConfig,
  Divider,
  Svg,
  useColors,
} from "@prodoctivity/design-system";

import { Text } from "@prodoctivity/design-system";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import checkCircle from "../../assets/checkCircle.png";
import LogoWhiteUrl from "../../assets/prodoctivity-noiso-white.png";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type BulletPointProps = {
  children: ReactNode;
  index: number | string;
};

export const BulletPoint = ({ children, index }: BulletPointProps) => (
  <Box display="flex" direction="column" key={index}>
    <Box display="flex" alignItems="center" margin={3}>
      <Box width={24} height={24} marginEnd={2}>
        <Svg src={checkCircle} alt="ProDoctivity Logo" />
      </Box>
      <Text size="200" weight="normal">
        {children}
      </Text>
    </Box>
  </Box>
);

type PricingProps = {
  index: number;
  title: string;
  isAnnually: boolean;
  monthlyPrice: number;
  annualPrice: number;
  rawAnnualPrice: number;
  features: string[];
  url?: string;
  borderColor?: ColorConfig;
  togglePaymentPeriod(): void;
};

export const PricingCard = ({
  index,
  title,
  isAnnually,
  monthlyPrice,
  annualPrice,
  rawAnnualPrice,
  features,
  url,
  borderColor,
  togglePaymentPeriod,
}: PricingProps) => {
  const { colors } = useColors();
  const navigate = useNavigate();
  const { resources } = useAppTranslation();

  return (
    <Box
      color={colors.white}
      borderRadius={6}
      borderColor={borderColor ?? colors.neutral600}
      padding={6}
      marginTop={4}
      marginBottom={4}
      width={"100%"}
      minWidth={200}
      display="flex"
      direction="column"
      key={index}
    >
      <Box display="flex" direction="column" flex="grow">
        <Box display="flex" justifyContent="center">
          <Svg src={LogoWhiteUrl} alt="ProDoctivity Logo" width={"192px"} />
        </Box>
        <Text align="center" weight="bold" color={colors.primary} size="400">
          {title}
        </Text>

        <Box
          display="flex"
          direction="column"
          justifyContent="center"
          alignContent="center"
          marginTop={4}
          marginBottom={4}
        >
          <Box display="flex" justifyContent="center">
            <Box width={"100%"} display="flex" justifyContent="center" position="relative">
              <Box display="flex" alignItems="center" direction="column">
                {isAnnually ? (
                  <Text
                    decoration="line-through"
                    weight="bold"
                    size="300"
                    color={colors.neutral600}
                  >
                    USD ${rawAnnualPrice}
                  </Text>
                ) : (
                  <Box maxWidth={355}>
                    <BannerSlim
                      type="recommendation"
                      message={resources.pricingPage.twoMonthsOffDescription}
                      primaryAction={{
                        accessibilityLabel: resources.pricingPage.payAnnually,
                        label: resources.accept,
                        onClick: () => {
                          togglePaymentPeriod();
                        },
                        role: "button",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" direction="column" gap={1}>
            <Text weight="bold" size="400" color={colors.primary}>
              USD ${isAnnually ? annualPrice : monthlyPrice}
            </Text>
            <Text size="400" color={colors.primary}>
              {isAnnually ? resources.pricingPage.annually : resources.pricingPage.monthly}
            </Text>
          </Box>
        </Box>
        <Divider direction="horizontal" />
        {features.map((feature, index) => (
          <BulletPoint key={index} index={index}>
            {feature}
          </BulletPoint>
        ))}
      </Box>

      <Box>
        <Button
          color={"blue"}
          onClick={() => {
            if (url) {
              navigate(url);
            } else {
              navigate("/#");
            }
          }}
          text={resources.getStarted}
        />
      </Box>
    </Box>
  );
};
