import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const XIconSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 10,
  height = 10,
  color,
  onClick,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={10}
      height={10}
      viewBox={`0 0 10 10`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        cursor: onClick ? "pointer" : undefined,
      }}
    >
      <path
        d="M9 1L1 9M1 1L9 9"
        stroke={color ? calculateColor(color) : "#767676"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
