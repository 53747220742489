import { FormErrors, FormValues, GroupValues } from "../_lib/types";

export interface IDependency {
  name: string;
  conditions: {
    expressionList: IExpression[];
    isNegative: boolean;
    operator: string;
    sourceField: string;
    sourceSummary: string;
    targetValue?: any;
  };
  fields: string[];
}

export interface IDocumentViewer {
  documentViewer: any;
  processTaskId: number;
  show: (node: HTMLElement) => void;
  set: (key: string, value: any) => void;
  on: (event: string, handle: (data: any) => void) => void;
  clear: () => void;
}

export interface IDocumentViewerStrategy {
  enable(docuemntViewer: IDocumentViewer): Promise<void>;
}

export interface IExpression {
  expressionList?: IExpression[];
  isNegative: boolean;
  operator: string;
  sourceField: string;
  sourceSummary: string;
  targetValue?: any;
}

export interface IFormError {
  dataElementName: string;
  key: string;
  text: string;
  parameters?: any;
}

export interface IFormValues {
  formValues: FormValues;
  groupValues: GroupValues;
  formErrors?: FormErrors;
  pinnedElements: string[];
}

export interface IPublishData {
  result: WizardDecisionResult;
  publishOption: PublishOption;
  reviewers: string[];
  start: Date | undefined;
  end: Date | undefined;
  comments: string;
}

export interface IUser {
  id: number;
  userName: string;
  name: string;
  lastName: string;
  email: string;
}

export enum PublishOption {
  None = "None",
  PublishNow = "PublishNow",
  SpecifiedTime = "SpecifiedTime",
  Range = "Range",
}

export enum PublishMode {
  Avance = "Avance",
  Publish = "Publish",
}

export enum WizardStep {
  //FormDesigner = 'FormDesigner',
  Form = "form",
  Summary = "summary",
  Preview = "preview",
  Publish = "Publish",
  DocumentGenerated = "documentGenerated",
}

export enum WizardDecisionResult {
  None = "None",
  Advance = "Advance",
  Rework = "Rework",
}

export enum DocumentViewMode {
  Preview = "Preview",
  Generated = "Generated",
}

export enum TaskAction {
  Abandon = "Abandon",
  Comments = "Comments",
  SaveAndClose = "SaveAndClose",
  SaveAndContinue = "SaveAndContinue",
}

export function makeCancelable<T>(promise: Promise<T>): {
  promise: Promise<T>;
  cancel: () => void;
} {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}
