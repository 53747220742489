import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const AddWidgetIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25865 8.34821C9.40326 8.2625 9.66034 8.15 9.82102 8.09643C10.0888 8.01607 10.4477 8.00536 13.5434 8C16.8105 8 16.9873 8.00536 17.3193 8.10179C17.5122 8.16071 17.8121 8.30536 17.9888 8.41786C18.1656 8.53571 18.3745 8.69643 18.4495 8.78214C18.5298 8.86786 18.669 9.06071 18.7601 9.20536C18.8565 9.35536 18.9797 9.62857 19.0386 9.82143C19.135 10.1536 19.1404 10.3304 19.1404 13.5714C19.1404 16.8071 19.135 16.9893 19.0386 17.3214C18.9797 17.5143 18.8351 17.8143 18.7226 17.9911C18.6048 18.1679 18.4441 18.3768 18.3584 18.4518C18.2727 18.5321 18.0852 18.6714 17.9353 18.7625C17.7907 18.8589 17.5122 18.9821 17.3193 19.0411C16.9873 19.1375 16.8105 19.1429 13.5702 19.1429C10.3298 19.1429 10.1531 19.1375 9.82102 19.0411C9.62821 18.9821 9.35505 18.8589 9.20509 18.7625C9.06048 18.6714 8.86766 18.5321 8.78197 18.4518C8.69627 18.3768 8.53559 18.1679 8.41776 17.9911C8.30529 17.8143 8.16068 17.5143 8.10176 17.3214C8.00536 16.9893 8 16.8071 8 13.5714C8 10.3357 8.00536 10.1536 8.10176 9.82143C8.16068 9.62857 8.29993 9.32857 8.41776 9.15179C8.53024 8.975 8.70699 8.75536 8.80875 8.66964C8.91051 8.57857 9.10868 8.43393 9.25865 8.34821Z"
        fill={color ? calculateColor(color) : "#767676"}
      />
      <path
        d="M25.7055 8.42857C25.7858 8.32679 25.9251 8.1875 26.0108 8.12321C26.1233 8.0375 26.2518 8 26.4178 8C26.5839 8 26.7231 8.0375 26.8517 8.11786C26.9534 8.1875 27.0927 8.32679 27.157 8.42857C27.2855 8.61607 27.2855 8.62143 27.2801 12.2857H29.3261C31.3721 12.2804 31.3774 12.2804 31.5649 12.4036C31.6667 12.4732 31.8059 12.6125 31.8702 12.7143C31.9559 12.8429 31.9987 12.9821 31.9987 13.1429C31.9987 13.3036 31.9577 13.4464 31.8756 13.5714C31.8059 13.6732 31.6667 13.8125 31.3774 14.0054L27.2801 14L27.2855 18.5268L27.157 18.7143C27.0927 18.8161 26.9534 18.9554 26.8517 19.025C26.7267 19.1071 26.5839 19.1482 26.4232 19.1482C26.2625 19.1482 26.1197 19.1071 25.9947 19.025C25.893 18.9554 25.7537 18.8161 25.5609 18.5268L25.5662 14H23.5149C21.4743 14.0054 21.4689 14.0054 21.2815 13.8821C21.1797 13.8125 21.0405 13.6732 20.9762 13.5714C20.8905 13.4429 20.8477 13.3036 20.8477 13.1429C20.8477 12.9821 20.8887 12.8393 20.9708 12.7143C21.0405 12.6125 21.1797 12.4732 21.4689 12.2804L25.5662 12.2857L25.5609 8.61607L25.7055 8.42857Z"
        fill={color ? calculateColor(color) : "#767676"}
      />
      <path
        d="M9.25865 21.2056C9.40326 21.1199 9.66034 21.0074 9.82102 20.9539C10.0888 20.8735 10.4477 20.8628 13.5434 20.8574C16.8105 20.8574 16.9873 20.8628 17.3193 20.9592C17.5122 21.0181 17.8121 21.1628 17.9888 21.2753C18.1656 21.3931 18.3745 21.5539 18.4495 21.6396C18.5298 21.7253 18.669 21.9128 18.7601 22.0628C18.8565 22.2074 18.9797 22.486 19.0386 22.6789C19.135 23.011 19.1404 23.1878 19.1404 26.4289C19.1404 29.6646 19.135 29.8467 19.0386 30.1789C18.9797 30.3717 18.8351 30.6717 18.7226 30.8485C18.6048 31.0253 18.4441 31.2342 18.3584 31.3092C18.2727 31.3896 18.0852 31.5289 17.9353 31.6199C17.7907 31.7164 17.5122 31.8396 17.3193 31.8985C16.9873 31.9949 16.8105 32.0003 13.5702 32.0003C10.3298 32.0003 10.1531 31.9949 9.82102 31.8985C9.62821 31.8396 9.35505 31.7164 9.20509 31.6199C9.06048 31.5289 8.86766 31.3896 8.78197 31.3092C8.69627 31.2342 8.53559 31.0253 8.41776 30.8485C8.30529 30.6717 8.16068 30.3717 8.10176 30.1789C8.00536 29.8467 8 29.6646 8 26.4289C8 23.1931 8.00536 23.011 8.10176 22.6789C8.16068 22.486 8.29993 22.186 8.41776 22.0092C8.53024 21.8324 8.70699 21.6128 8.80875 21.5271C8.91051 21.436 9.10868 21.2914 9.25865 21.2056Z"
        fill={color ? calculateColor(color) : "#767676"}
      />
      <path
        d="M22.1141 21.2056C22.2587 21.1199 22.5158 21.0074 22.6765 20.9539C22.9443 20.8735 23.3031 20.8628 26.3989 20.8574C29.666 20.8574 29.8427 20.8628 30.1748 20.9592C30.3676 21.0181 30.6676 21.1628 30.8443 21.2753C31.0211 21.3931 31.2299 21.5539 31.3049 21.6396C31.3853 21.7253 31.5245 21.9128 31.6156 22.0628C31.712 22.2074 31.8352 22.486 31.8941 22.6789C31.9905 23.011 31.9958 23.1878 31.9958 26.4289C31.9958 29.6646 31.9905 29.8467 31.8941 30.1789C31.8352 30.3717 31.6906 30.6717 31.5781 30.8485C31.4602 31.0253 31.2996 31.2342 31.2139 31.3092C31.1282 31.3896 30.9407 31.5289 30.7908 31.6199C30.6461 31.7164 30.3676 31.8396 30.1748 31.8985C29.8427 31.9949 29.666 32.0003 26.4257 32.0003C23.1853 32.0003 23.0086 31.9949 22.6765 31.8985C22.4837 31.8396 22.2105 31.7164 22.0606 31.6199C21.9159 31.5289 21.7231 31.3896 21.6374 31.3092C21.5517 31.2342 21.3911 31.0253 21.2732 30.8485C21.1608 30.6717 21.0161 30.3717 20.9572 30.1789C20.8608 29.8467 20.8555 29.6646 20.8555 26.4289C20.8555 23.1931 20.8608 23.011 20.9572 22.6789C21.0161 22.486 21.1554 22.186 21.2732 22.0092C21.3857 21.8324 21.5625 21.6128 21.6642 21.5271C21.766 21.436 21.9641 21.2914 22.1141 21.2056Z"
        fill={color ? calculateColor(color) : "#767676"}
      />
    </svg>
  );
};
