import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const BundleSvgIcon: FunctionComponent<Props> = ({
  color,
  width = 29,
  height = 27,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      style={{
        ...svgStyle,
      }}
      onClick={onClick}
      width="29"
      height="27"
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8085_131266)">
        <path
          d="M8.10086 10.4297V12.0431L5.87598 11.2484L8.10086 10.4297Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M23.7708 11.2484L21.5459 12.0431V10.4297L23.7708 11.2484Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M14.5361 14.957V15.0293L14.584 14.9811H14.5361V14.957Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M20.325 4H9.32025C8.98532 4 8.69824 4.28897 8.69824 4.62611V12.2598L14.8226 14.4271L20.947 12.2598V4.62611C20.947 4.28897 20.66 4 20.325 4ZM18.2676 10.5501H11.0427V9.27377H18.2676V10.5501ZM18.2676 7.80482H11.0427V6.5526H18.2676V7.80482Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path d="M14.5361 14.957V15.0293L14.584 14.9811H14.5361V14.957Z" fill="white" />
        <path
          d="M24.3443 16.0167V19.7492L15.1338 23.0002V15.1738L17.8611 18.0395L18.0524 18.2321C18.0524 18.2321 18.196 18.3285 18.2678 18.3285C18.3156 18.3285 18.3395 18.3285 18.3874 18.3044L19.0812 18.0395L20.9472 17.317L21.2582 17.1966L21.5453 17.1003L24.3443 16.0167Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M14.5362 14.9573V15.0295L14.584 14.9814H14.5362V14.9573ZM5.01465 11.5859V11.6582L5.0625 11.61H5.01465V11.5859Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path d="M14.5361 14.957V15.0293L14.584 14.9811H14.5361V14.957Z" fill="white" />
        <path
          d="M14.5362 15.4141V22.9996L5.30176 19.7487V15.9198L8.1008 16.9793L8.38788 17.0757L8.69889 17.1961L10.9716 18.0389L11.2826 18.1593C11.2826 18.1593 11.3544 18.1834 11.3783 18.1834C11.4501 18.1834 11.5219 18.1593 11.5936 18.1111L11.6654 18.0389L14.5362 15.4141Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M27.0004 14.355L24.9429 15.1497L24.6319 15.2701L24.3449 15.3664L21.5458 16.4501L21.2587 16.5464L20.9477 16.6668L18.3401 17.6782L15.6846 14.8848L20.9477 13.0787L21.2587 12.9824L21.5458 12.8861L24.3449 11.9228L24.5602 11.8506L24.9429 12.2359L27.0004 14.355Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M14.5361 14.957V15.0293L14.584 14.9811L14.5361 14.957Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M8.45933 12.8134L8.38756 12.7893V12.7653L8.10048 12.6689L5.42105 11.7298L5.30144 11.6816L5.0622 11.6094L5.01435 11.6575L4.70335 11.8983L2 14.0416L4.70335 15.053L5.01435 15.1734L5.30144 15.2697L8.10048 16.3293L8.38756 16.4256L8.69856 16.546L11.3301 17.5333L14.2488 14.8603L8.69856 12.8857L8.45933 12.8134Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_8085_131266">
          <rect width="25" height="19" fill="white" transform="translate(2 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};
