import { Box, Tooltip } from "@prodoctivity/design-system";
import { FunctionComponent, useMemo } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useGetDocumentLatestVersion } from "../../../pages/StandAloneViewer/hooks";
import { ActiveDocumentListSVG } from "../../../svg/ActiveDocumentListSVG";
import { InactiveDocumentListSVG } from "../../../svg/InactiveDocumentListSVG";
import {
  CascadeOptionsPopover,
  CascadeOptionsPopoverItem,
} from "../../CascadeOptionsPopover/CascadeOptionsPopover";
import { DocumentBookmarkTags } from "../DocumentBookmarkTags";

type countListPopoverMenuProps = {
  documentId: string;
};

export const CountListPopoverMenu: FunctionComponent<countListPopoverMenuProps> = ({
  documentId,
}) => {
  const { documentResponse, refetch: refetchDocumentResponse } =
    useGetDocumentLatestVersion(documentId);
  const { resources } = useAppTranslation();

  const myListOptions: CascadeOptionsPopoverItem[] = useMemo(() => {
    return [
      {
        childIcon: (
          <DocumentBookmarkTags
            documentId={documentId}
            viewMode={{
              type: "onlyFavorite",
              value: { starWidth: 26, starHeight: 26, shouldDisplayAddToFavoritesLabel: true },
            }}
          />
        ),
        childLabel: "",
        childHoverLabel: "",
        treeItem: false,
        treeContent: undefined,
        key: "favorites",
      },

      {
        childIcon: <InactiveDocumentListSVG width={20} height={40} />,
        childLabel: resources.addToList,
        childHoverLabel: resources.addToList,
        treeItem: true,
        treeContent: (
          <Box>
            <DocumentBookmarkTags
              documentId={documentId}
              viewMode={{
                type: "addToList",
                refetchParentInfo: refetchDocumentResponse,
              }}
            />
          </Box>
        ),
        key: "labels",
      },
    ];
  }, [resources, documentId, refetchDocumentResponse]);

  const documentListCount = useMemo(() => {
    return documentResponse?.document?.documentLists?.length || 0;
  }, [documentResponse?.document?.documentLists?.length]);

  return (
    <Box>
      <CascadeOptionsPopover
        icon={
          <Tooltip text={`${documentListCount} ${resources.lists}`}>
            {documentListCount > 0 ? <ActiveDocumentListSVG /> : <InactiveDocumentListSVG />}
          </Tooltip>
        }
        label={undefined}
        hoverLabel={undefined}
        items={myListOptions}
        searchFilter={false}
        onClick={refetchDocumentResponse}
        childPopoverActionOnClose={undefined}
      />
    </Box>
  );
};
