import { Box, Skeleton, useColors } from "@prodoctivity/design-system";

import type { TemplateVersionContextMapping } from "@prodoctivity/shared/src/index-types";
import type { TemplateVersionId } from "@prodoctivity/types";
import { FC } from "react";
import { DatalinkCard } from "./DatalinkCard";

type Props = {
  templateVersionId: TemplateVersionId;
  mappedDatalinks: TemplateVersionContextMapping["datalinks"];
};

const DatalinkTableSkeleton = () => {
  return (
    <Box>
      <Skeleton width="100%" height={100} />
    </Box>
  );
};

const DatalinkTable: FC<Props> = (DatalinkTableProps: Props) => {
  const { colors } = useColors();
  return (
    <Box
      color={colors.white}
      minHeight={80}
      alignContent="center"
      alignItems="center"
      borderRadius={4}
    >
      <Box
        display="flex"
        height="100%"
        width="100%"
        justifyContent="start"
        alignItems="start"
        alignSelf="center"
        overflow="scrollY"
      >
        {DatalinkTableProps.mappedDatalinks.length === 0 && (
          <Box padding={4} margin={4}>
            No datalinks configured for this template.
          </Box>
        )}
        {DatalinkTableProps.mappedDatalinks.map((datalinkMapping) => {
          return (
            <DatalinkCard
              key={datalinkMapping.datalinkVersionId}
              datalinkId={datalinkMapping.datalinkId}
              datalinkVersionId={datalinkMapping.datalinkVersionId}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export { DatalinkTable, DatalinkTableSkeleton };
