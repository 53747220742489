import { Box, Button, Text, useColors } from "@prodoctivity/design-system";
import { FC, useCallback, useMemo } from "react";
import { DWT, ScannerServiceStatus } from "./Scan";
import { ScannerImage, decodeBase64Image } from "./utils/decode-image";

import { ComboBox } from "@prodoctivity/design-system/components/ComboBox";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useSettings } from "../../../../hooks/useSettings";
import { ScannerSvgIcon } from "../../../../svg/scanner/ScannerSvg";
import { itemOptions } from "./utils/item-to-options";
import { MissingScannerSvg } from "../../../../svg/scanner/MissingScanner.svg";

type Props = {
  /**
   *  Emit recieved image from scanner
   * @param scannedImage image in blob format
   * @returns void
   */
  onReceivePage: (scannedImage: ScannerImage) => void;
  /**
   * change state of panel in function service detection
   * @param state new state for service
   * @returns void
   */
  setScanServiceState: (state: ScannerServiceStatus) => void;
  /**
   * callback function for change scanner device
   * @param device
   * @returns
   */
  setDevice: (device: string) => void;
  /**
   * set a list of device compoatibles with scanner plugin
   * @param device list of name's of scanner
   * @returns void
   */
  devicesReady: (device: Array<string>) => void;
  /**
   * list of device availables
   * @type Array<{string}>
   */
  devices: string[];
  /**
   * current scanner device
   * @type string
   */
  currentDevice: string;
  /**
   * current state of scanner plugin
   * @type ScannerServiceStatus  => "Loading" | "Error" | "Ready"'
   */
  serviceState: ScannerServiceStatus;

  cancelCaptureImages: () => void;
};

/**
 * panel for active scanner
 *
 * @param props
 * @returns
 */
export const ScannerPanel: FC<Props> = (props) => {
  const { colors } = useColors();
  const { dynamsoftLicense } = useSettings();
  const { resources } = useAppTranslation();

  let dwt: DWT | undefined = undefined;
  const defaultDevice = resources.scanDocument.selectDevice;
  const { onReceivePage, cancelCaptureImages } = props;

  const dynamsoftWords: Record<string, string> = useMemo(
    () => ({
      LookingDevice: resources.scanDocument.searchingForDevice,
    }),
    [resources.scanDocument.searchingForDevice]
  );

  const translations = useCallback(
    (key: string): string => {
      return dynamsoftWords[key];
    },
    [dynamsoftWords]
  );

  const renderContentByState = useCallback(
    (state: ScannerServiceStatus) => {
      const content: Record<ScannerServiceStatus, () => JSX.Element> = {
        Error: () => {
          return (
            <Box
              marginTop={2}
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <MissingScannerSvg />
              <Box padding={4} />
              <Text>{resources.scanDocument.scannerServiceNotFound}</Text>
              <Text>
                {resources.scanDocument.scannerServiceNotFoundSecondLine}
                <a
                  href="/dwt-resources/dist/DynamsoftServiceSetup.msi"
                  download="DynamsoftServiceSetup.msi"
                >
                  DynamsoftServiceSetup.msi
                </a>
              </Text>
            </Box>
          );
        },
        Loading: () => (
          <Box marginTop={2} display="flex" justifyContent="center" alignItems="center">
            {resources.loading}...
          </Box>
        ),
        Ready: () => (
          <>
            <Box marginTop={2} display="flex" justifyContent="center" alignItems="center">
              <Text color={colors.subtle} size="200">
                {resources.scanDocument.instructionMessage}
                {": "}
              </Text>
            </Box>
            <Box marginTop={2} display="flex" justifyContent="center" alignItems="center">
              <Text>
                <ComboBox
                  errorMessage={
                    props.devices.length > 0 ? "" : resources.scanDocument.noDeviceDetected
                  }
                  id="scanner-selector"
                  label=""
                  options={itemOptions(props.devices, defaultDevice)}
                  onSelect={(e) => {
                    props.setDevice(e.item.value);
                  }}
                  onClear={() => props.setDevice(defaultDevice)}
                  inputValue={props.currentDevice}
                ></ComboBox>
              </Text>
            </Box>
            <Box display="flex" marginTop={4}>
              <Box borderRadius={6} borderStyle="sm" margin="auto">
                <Button
                  color={"white"}
                  onClick={() => {
                    if (
                      props.currentDevice === "" ||
                      props.currentDevice === defaultDevice ||
                      !dwt
                    ) {
                      return;
                    }
                    dwt.acquireImage();
                  }}
                  text={resources.scanDocument.startScan}
                />
              </Box>
            </Box>
          </>
        ),
      };
      return content[state]();
    },
    [colors.subtle, defaultDevice, dwt, resources, props]
  );

  return (
    <Box marginTop={2} width={"100%"}>
      <Box marginTop={12} display="flex" justifyContent="center" alignItems="center">
        {props.serviceState !== "Error" && <ScannerSvgIcon />}
      </Box>
      <DWT
        getServiceState={props.setScanServiceState}
        ref={(self) => {
          if (self) {
            dwt = self;
          }
        }}
        defaultScanner={props.currentDevice}
        hideDisplay
        getAvailableScanners={props.devicesReady}
        emitNewPage={(page) => {
          if (typeof page === "boolean") {
            return;
          }
          onReceivePage(decodeBase64Image(page));
        }}
        translate={translations}
        license={dynamsoftLicense}
        onCancelScanProcess={cancelCaptureImages}
      ></DWT>
      {renderContentByState(props.serviceState)}
    </Box>
  );
};
