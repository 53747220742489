import { Box, TapArea, Text, useColors } from "@prodoctivity/design-system";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useCallback } from "react";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";

type Props = {
  templateVersionId: string;
};

const TemplateLink: React.FC<Props> = ({ templateVersionId }) => {
  const { colors } = useColors();
  const { fetchTemplateInfo } = useServices();
  const { resources } = useAppTranslation();
  const handleFetchTemplateInfo = useCallback(async () => {
    if (!templateVersionId) {
      return undefined;
    }
    const status = await fetchTemplateInfo(templateVersionId);

    return status;
  }, [fetchTemplateInfo, templateVersionId]);
  const { data: templateInfo, isLoading } = useOrganizationQuery(
    `/template-version-info/${templateVersionId}`,
    handleFetchTemplateInfo,
    {
      staleTime: 60 * 1000,
    }
  );

  const organizationNavigate = useOrganizationNavigate();
  const visitTemplateVersionDetails = useCallback(() => {
    organizationNavigate(`/templates/versions/${templateVersionId}/details`);
  }, [organizationNavigate, templateVersionId]);

  return (
    <Box display="inlineBlock">
      {isLoading ? (
        <Text inline={true} color={colors.primary} size={"200"}>
          {resources.loading}
        </Text>
      ) : !templateInfo ? (
        <Text inline={true} color={colors.primary} size={"200"}>
          {resources.pageNotFound.templateNotFound}
        </Text>
      ) : (
        <TapArea onTap={visitTemplateVersionDetails}>
          <Text inline={true} color={colors.primary} size={"200"}>
            {`${templateInfo.info.informationDefinition.name}`}
          </Text>
        </TapArea>
      )}
    </Box>
  );
};

export { TemplateLink };
