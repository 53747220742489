import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const AddDocumentIconSvg: FunctionComponent<Props> = ({
  color,
  width = 34,
  height = 32,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6352_101629)">
        <path d="M25.731 18.9778V31.1319H0.87207V0.868164H23.8818C22.8351 0.989705 21.8059 1.26751 20.8639 1.73631C20.044 2.1183 19.2938 2.63919 18.6135 3.24689L18.3692 3.48998C18.0204 3.8546 17.7587 4.14977 17.5145 4.49703C17.1132 5.00056 16.7992 5.53881 16.4678 6.25069C16.398 6.44169 16.3282 6.63268 16.241 6.82367C16.1886 7.01466 16.1189 7.22302 16.0665 7.43138L15.9793 7.67446C15.9095 7.96963 15.8397 8.2648 15.8049 8.55997C15.7351 8.99405 15.7002 9.44548 15.7002 9.93165V10.2095C15.7176 10.6783 15.77 11.1297 15.8572 11.5811C15.9619 12.1888 16.1363 12.7792 16.3456 13.3174C16.3456 13.3348 16.3631 13.3348 16.3631 13.3522C16.6771 14.1509 17.1307 14.9148 17.7238 15.6441C17.9157 15.8872 18.1425 16.1129 18.3518 16.3386C19.067 17.0505 19.8869 17.6408 20.7941 18.0749C22.0327 18.7 23.4283 19.0299 24.8587 19.0299C25.1553 19.0299 25.4519 19.0125 25.731 18.9778Z" />
        <path
          d="M20.864 1.7363C21.8061 1.2675 22.8353 0.989691 23.882 0.86815H25.7311C26.0277 0.885513 26.3243 0.920239 26.6034 0.989691V0H0V32H26.6034V18.8562C26.3592 18.9083 26.1149 18.943 25.8707 18.9778C25.8184 18.9778 25.7835 18.9951 25.7311 18.9778C25.452 19.0125 25.1555 19.0298 24.8589 19.0298V30.2637H1.74448V1.7363H20.864Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M16.2415 6.82324C16.1892 7.01424 16.1194 7.22259 16.0671 7.43095L15.9799 7.67403C15.9101 7.9692 15.8403 8.26437 15.8054 8.55954H5.65252C5.16406 8.55954 4.78027 8.17756 4.78027 7.69139C4.78027 7.20523 5.16406 6.82324 5.65252 6.82324H16.2415Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M16.3462 13.3174H5.65252C5.16406 13.3174 4.78027 12.918 4.78027 12.4492C4.78027 11.9804 5.16406 11.5811 5.65252 11.5811H15.8577C15.9624 12.1888 16.1369 12.7791 16.3462 13.3174Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M20.7945 18.0752H6.01873C5.53027 18.0752 5.14648 17.6758 5.14648 17.207C5.14648 16.7382 5.53027 16.3389 6.01873 16.3389H18.3522C19.0675 17.0507 19.8874 17.6411 20.7945 18.0752Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M21.3179 22.8154H6.01873C5.53027 22.8154 5.14648 22.4334 5.14648 21.9473C5.14648 21.4611 5.53027 21.0791 6.01873 21.0791H21.3179C21.8063 21.0791 22.1901 21.4611 22.1901 21.9473C22.1901 22.4334 21.8063 22.8154 21.3179 22.8154Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M24.8587 0.816406C24.5273 0.816406 24.1958 0.833769 23.8818 0.868495H25.731C25.4519 0.833769 25.1553 0.816406 24.8587 0.816406Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M26.6032 0.989705C26.3241 0.920253 26.0275 0.885527 25.731 0.868164H23.8818C22.8351 0.989705 21.8059 1.26751 20.8639 1.73631C20.044 2.1183 19.2938 2.63919 18.6135 3.24689L18.3693 3.48998C18.0204 3.8546 17.7587 4.14977 17.5145 4.49703C17.1132 5.00056 16.7992 5.53881 16.4678 6.25069C16.398 6.44169 16.3282 6.63268 16.241 6.82367C16.1887 7.01466 16.1189 7.22302 16.0665 7.43138L15.9793 7.67446C15.9095 7.96963 15.8398 8.2648 15.8049 8.55997C15.7351 8.99405 15.7002 9.44548 15.7002 9.93165V10.2095C15.7176 10.6783 15.77 11.1297 15.8572 11.5811C15.9619 12.1888 16.1363 12.7792 16.3457 13.3174C16.3457 13.3348 16.3631 13.3348 16.3631 13.3522C16.6771 14.1509 17.1307 14.9148 17.7238 15.6441C17.9157 15.8872 18.1425 16.1129 18.3518 16.3386C19.0671 17.0505 19.887 17.6408 20.7941 18.0749C22.0327 18.7 23.4283 19.0299 24.8587 19.0299C25.1553 19.0299 25.4519 19.0125 25.731 18.9778C25.7833 18.9951 25.8182 18.9778 25.8705 18.9778C26.1148 18.943 26.359 18.9083 26.6032 18.8562C30.8772 18.0575 33.9998 14.3418 33.9998 9.93165C33.9998 5.52145 30.8249 1.80577 26.6032 0.989705ZM30.1445 11.095H25.9752V15.4184H23.7248V11.095H19.5555V8.75096H23.7248V4.42758H25.9752V8.75096H30.1445V11.095Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6352_101629">
          <rect width="34" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
