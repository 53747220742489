import {
  Box,
  BoxWithRef,
  ChevronSvgIcon,
  useColors,
  Text,
  popupZIndex,
  Popover,
  Layer,
  TapArea,
  Table,
  Checkbox,
  Icon,
} from "@prodoctivity/design-system";
import { FunctionComponent, SyntheticEvent, useCallback, useRef, useState } from "react";
import { Pagination } from "../../../components/Layout/Pagination";
import { useRecentSearchLogic } from "../hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { MobileResultsPageSortIcon } from "../../../svg/MobileResultsPageSortIcon";
import { DeletedDocumentSvgIcon } from "../../../svg/DeletedDocumentSvgIcon";
import { ArrowSvg } from "@prodoctivity/design-system/components/InteractiveIconController/ArrowSvg";
import { RowSkeleton } from "../../Settings/DataDictionary/DataLists";
import type { RecentSearchQuery } from "@prodoctivity/types/model/recent-searches";
import { DocIconsSvg } from "../../../svg/DocumentIconsSvg";
import { DocumentIconsSvg } from "../../../svg/DocumentTypeIconsSvg";
import { TemplateIconSvg } from "../../../svg/TemplateIconSvg";
import { RecentSearchCard, RecentSearchList } from "./RecentSearchList";

interface RecentSearchTypeIconProps {
  iconType: RecentSearchQuery["category"];
  size: number | undefined;
}

export const MainContent: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const {
    recentSearchFilter,
    recentSearchList,
    nextPage,
    previousPage,
    setPageLength,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    pageLengthOptions,
    onSortClick,
    sortByLabel,
    sortDirection,
    open,
    setOpen,
    sortRecenSearchList,
    recentSearchDeleteList,
    handleMultipleRecentSearchDelete,
    isToggleSelect,
    handleCheckboxChange,
    isLoading,
    setRecentSearchDeleteList,
    breakPointDuo,
    breakpoint,
  } = useRecentSearchLogic();

  const handleDeleteSelectList = useCallback(
    (event: SyntheticEvent<HTMLInputElement, Event>) => {
      const { checked, id } = event.currentTarget;

      setCheckedItems((prevState) => {
        const newState = { ...prevState, [id]: !prevState[id] };
        return newState;
      });

      handleCheckboxChange(id, checked);
    },
    [handleCheckboxChange, setCheckedItems]
  );

  const handleDeleteAll = useCallback(() => {
    const updatedList: string[] = [];

    recentSearchList?.map((item) => updatedList.push(item._id));

    setRecentSearchDeleteList(updatedList);
  }, [recentSearchList, setRecentSearchDeleteList]);

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setIsSelectAll(true);
        handleDeleteAll();
      } else {
        setIsSelectAll(false);
        setRecentSearchDeleteList([]);
      }
    },
    [setIsSelectAll, setRecentSearchDeleteList, handleDeleteAll]
  );

  return (
    <Box direction="row" flex="grow" color={colors.white}>
      <Box paddingX={6} display="flex" justifyContent="end">
        <Box display="flex" alignItems="center" paddingY={1}>
          <Box>
            <Pagination
              id="recent-searches"
              rowsLabel={` `}
              currentPage={recentSearchFilter.pageNumber}
              nextPage={nextPage}
              previousPage={previousPage}
              rowsPerPage={recentSearchFilter.rowsPerPage}
              setRowsPerPage={setPageLength}
              pageLength={recentSearchList?.length || 0}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={pageLengthOptions}
            />
          </Box>
          <Box marginStart={4} marginEnd={-2}>
            <Box display="flex" gap={6} alignItems="center">
              {resources.sortType}:
              <BoxWithRef
                ref={anchorRef}
                alignItems="center"
                display="flex"
                onClickCapture={onSortClick}
              >
                <TapArea>
                  {breakPointDuo ? (
                    <MobileResultsPageSortIcon />
                  ) : (
                    <Box display="flex" gap={2} alignItems="center">
                      <Text size="200" weight="bold">
                        {sortByLabel()}
                      </Text>

                      <ChevronSvgIcon direction="down" />
                    </Box>
                  )}
                </TapArea>
              </BoxWithRef>
            </Box>
            {open && (
              <Layer zIndex={popupZIndex}>
                <Popover
                  anchor={anchorRef.current}
                  onDismiss={() => setOpen(false)}
                  idealDirection="down"
                  positionRelativeToAnchor={true}
                  size="flexible"
                  shouldFocus={true}
                  color="white"
                  role="menu"
                >
                  <Box color={colors.white}>
                    <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                      <Box width={160}>
                        <Box
                          onClickCapture={() => sortRecenSearchList("desc")}
                          color={sortDirection === "desc" ? colors.primaryHover0 : undefined}
                          hoverColor={colors.primaryHover0}
                          direction="row"
                          alignItems="center"
                          justifyContent="start"
                          padding={2}
                        >
                          <TapArea>
                            <Box paddingX={2} marginTop={2} marginBottom={2}>
                              <Text
                                customClassName="text-bold-on-hover text-subtle-on-hover"
                                color={colors.subtle}
                              >
                                {resources.mostRecent}
                              </Text>
                            </Box>
                          </TapArea>
                        </Box>
                        <Box width="100%" height={1} color={colors.neutral500} />
                        <Box
                          onClickCapture={() => sortRecenSearchList("asc")}
                          color={sortDirection === "asc" ? colors.primaryHover0 : undefined}
                          hoverColor={colors.primaryHover0}
                          direction="row"
                          alignItems="center"
                          justifyContent="start"
                          padding={2}
                        >
                          <TapArea>
                            <Box display="flex" direction="column">
                              <Box paddingX={2} marginTop={2} marginBottom={2}>
                                <Text
                                  customClassName="text-bold-on-hover text-subtle-on-hover"
                                  color={colors.subtle}
                                >
                                  {resources.oldest}
                                </Text>
                              </Box>
                            </Box>
                          </TapArea>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Popover>
              </Layer>
            )}
          </Box>
        </Box>
      </Box>
      <Box borderStyle="sm" borderRadius={6} width="100%"></Box>
      <Box
        paddingX={breakPointDuo ? 4 : breakpoint === "large" ? 4 : 12}
        paddingTop={12}
        color={colors.white}
      >
        <Box
          display="flex"
          justifyContent="end"
          marginBottom={2}
          gap={8}
          alignItems="center"
          height={25}
        >
          {recentSearchDeleteList.length !== 0 && (
            <Box marginEnd={breakPointDuo ? 6 : undefined}>
              <TapArea onTap={handleMultipleRecentSearchDelete}>
                <Box display="flex" alignItems="start" gap={1}>
                  <Box marginEnd={1}>
                    <Text color={colors.primary}>{"Delete"}</Text>
                  </Box>
                  <DeletedDocumentSvgIcon color={colors.primary} width={17} height={20} />
                  <Text>{recentSearchDeleteList.length}</Text>
                </Box>
              </TapArea>
            </Box>
          )}
        </Box>
        <Box>
          {breakPointDuo ? (
            <RecentSearchCard
              recentSearchList={recentSearchList || []}
              isToggleSelect={isToggleSelect}
              isSelectAll={isSelectAll}
              onCheckboxChange={(e) => handleDeleteSelectList(e)}
              isChecked={checkedItems}
            />
          ) : (
            <Table accessibilityLabel="Basic Table">
              <Table.Header>
                <Table.Row key={"recent search list"}>
                  <Table.HeaderCell colSpan={2}>
                    <Box display="flex" direction="row" alignItems="center" marginBottom={2}>
                      <Checkbox
                        id={"select all"}
                        name={"select all"}
                        size="sm"
                        checked={isSelectAll}
                        onChange={(e) => handleSelectAll(e.checked)}
                      />
                      <Box paddingX={breakPointDuo ? 2 : 4}>
                        <Icon
                          icon={"document"}
                          accessibilityLabel={resources.document}
                          color={colors.black600}
                          size={"sm"}
                        />
                      </Box>

                      <Text weight="bold" size="300">
                        {resources.recentSearches.toLocaleUpperCase()}
                      </Text>
                      <Box display="flex" gap={5} direction="row">
                        <Box margin={1} />
                        <TapArea onTap={() => sortRecenSearchList("desc")}>
                          <Box
                            color={
                              recentSearchFilter.order === "desc"
                                ? colors.neutral500
                                : colors.neutral200
                            }
                            padding={1}
                            borderRadius={4}
                          >
                            <ArrowSvg direction="down" />
                          </Box>
                        </TapArea>
                        <TapArea onTap={() => sortRecenSearchList("asc")}>
                          <Box
                            color={
                              recentSearchFilter.order === "asc"
                                ? colors.neutral500
                                : colors.neutral200
                            }
                            padding={1}
                            borderRadius={4}
                          >
                            <ArrowSvg />
                          </Box>
                        </TapArea>
                      </Box>
                    </Box>
                  </Table.HeaderCell>

                  <Table.HeaderCell colSpan={2}>
                    <Box display="flex" direction="row" marginBottom={2}>
                      <Text weight="bold" size="300">
                        {`${resources.search.toLocaleUpperCase()} ${resources.type.toLocaleUpperCase()}`}
                      </Text>
                      <Box display="flex" gap={5} direction="row">
                        <Box margin={1} />
                        <TapArea onTap={() => sortRecenSearchList("desc")}>
                          <Box
                            color={
                              recentSearchFilter.order === "desc"
                                ? colors.neutral500
                                : colors.neutral200
                            }
                            padding={1}
                            borderRadius={4}
                          >
                            <ArrowSvg direction="down" />
                          </Box>
                        </TapArea>
                        <TapArea onTap={() => sortRecenSearchList("asc")}>
                          <Box
                            color={
                              recentSearchFilter.order === "asc"
                                ? colors.neutral500
                                : colors.neutral200
                            }
                            padding={1}
                            borderRadius={4}
                          >
                            <ArrowSvg />
                          </Box>
                        </TapArea>
                      </Box>
                    </Box>
                  </Table.HeaderCell>

                  <Table.HeaderCell>
                    <Box display="flex" direction="row" marginBottom={2}>
                      <Text weight="bold" size="300">
                        {resources.date.toUpperCase()}
                      </Text>
                      <Box display="flex" gap={5} direction="row">
                        <Box margin={1} />
                        <TapArea onTap={() => sortRecenSearchList("desc")}>
                          <Box
                            color={
                              recentSearchFilter.order === "desc"
                                ? colors.neutral500
                                : colors.neutral200
                            }
                            padding={1}
                            borderRadius={4}
                          >
                            <ArrowSvg direction="down" />
                          </Box>
                        </TapArea>
                        <TapArea onTap={() => sortRecenSearchList("asc")}>
                          <Box
                            color={
                              recentSearchFilter.order === "asc"
                                ? colors.neutral500
                                : colors.neutral200
                            }
                            padding={1}
                            borderRadius={4}
                          >
                            <ArrowSvg />
                          </Box>
                        </TapArea>
                      </Box>
                    </Box>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {isLoading && <RowSkeleton />}

                <RecentSearchList
                  recentSearchList={recentSearchList || []}
                  isToggleSelect={isToggleSelect}
                  isSelectAll={isSelectAll}
                  onCheckboxChange={(e) => handleDeleteSelectList(e)}
                  isChecked={checkedItems}
                />
              </Table.Body>
            </Table>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const RenderDocumentTypeIconSvg: FunctionComponent<RecentSearchTypeIconProps> = ({
  iconType,
  size,
}) => {
  switch (iconType) {
    case "document":
      return <DocIconsSvg width={size} height={size} />;
    case "collection":
      return <DocumentIconsSvg width={size} height={size} />;
    case "template":
      return <TemplateIconSvg width={size} height={size} />;
    default:
      return null;
  }
};
