import { ColorConfig, useColors, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const RemoteHttpSvg: FunctionComponent<Props> = ({ width, height, color }) => {
  const { colors } = useColors();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 24"
      fill={calculateColor(color || colors.primary)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9911_118667)">
        <path
          d="M4.39936 23.1582H1.0873C0.420486 23.1582 -0.0900777 22.5489 0.0133555 21.8745L1.26776 13.6621C1.28536 13.6936 1.30297 13.7228 1.32277 13.7543L1.69689 14.3568C2.37031 15.4404 3.2704 16.3171 4.31573 16.9399C4.59742 17.1085 4.79108 17.3962 4.8417 17.7245L5.4755 21.8745C5.57894 22.5489 5.06837 23.1582 4.40156 23.1582H4.39936Z"
          fill={calculateColor(color || colors.primary)}
        />
        <path
          d="M12.6673 7.83716L9.76897 10.2224L8.41113 8.03949L11.7562 6.36914C11.8002 6.6569 11.9037 6.93792 12.0643 7.1987C12.225 7.45498 12.4296 7.6708 12.6673 7.83716Z"
          fill={calculateColor(color || colors.primary)}
        />
        <path
          d="M14.5666 7.11584C14.3553 7.25297 14.1176 7.32042 13.8843 7.32042C13.7215 7.32042 13.5586 7.28894 13.409 7.22824C13.1691 7.12933 12.9556 6.95622 12.8082 6.71567C12.6586 6.47737 12.5969 6.2076 12.6101 5.94232C12.6321 5.5399 12.839 5.15323 13.1977 4.91942C13.4112 4.78229 13.6467 4.71484 13.8821 4.71484C14.3025 4.71484 14.7162 4.92842 14.9583 5.31959C15.3368 5.92658 15.1608 6.73141 14.5666 7.11584Z"
          fill={calculateColor(color || colors.primary)}
        />
        <path
          d="M11.9169 16.0295C10.7748 16.7714 9.50058 17.1244 8.23957 17.1244C5.96845 17.1244 3.74793 15.9756 2.43852 13.8736L2.0644 13.2711C0.0287442 10.0046 0.975047 5.66794 4.17487 3.59068C4.23429 3.55246 4.30031 3.53223 4.36633 3.53223C4.48297 3.53223 4.59961 3.59293 4.66783 3.70308L12.027 15.526C12.1326 15.6946 12.0842 15.9216 11.9169 16.0295Z"
          fill={calculateColor(color || colors.primary)}
        />
        <path
          d="M11.9169 16.0295C10.7747 16.7714 9.50051 17.1244 8.23951 17.1244C6.97851 17.1244 5.68229 16.7557 4.58414 16.0543C3.74567 15.517 3.00403 14.7841 2.43845 13.8736L2.06433 13.2711C2.03132 13.2171 1.99831 13.1654 1.9697 13.1115C0.059491 9.86518 1.0256 5.63422 4.17481 3.59068C4.23423 3.55246 4.30025 3.53223 4.36627 3.53223C4.48291 3.53223 4.59954 3.59293 4.66777 3.70308L12.0269 15.526C12.1326 15.6946 12.0841 15.9216 11.9169 16.0295Z"
          fill={calculateColor(color || colors.primary)}
        />
        <path
          d="M19.2873 7.39641C19.2367 7.39641 19.1883 7.38741 19.1376 7.36943C18.9088 7.28625 18.7899 7.02771 18.8736 6.79391C19.3731 5.37534 19.109 3.80391 18.1649 2.58992C17.2186 1.37369 15.7772 0.750961 14.3071 0.928562C14.0694 0.957788 13.8472 0.782434 13.8185 0.535141C13.7899 0.287848 13.9616 0.0652836 14.2037 0.036058C15.9774 -0.177513 17.716 0.566614 18.8538 2.03014C19.9893 3.48917 20.3062 5.38434 19.701 7.09965C19.6372 7.284 19.4678 7.39641 19.2873 7.39641Z"
          fill={calculateColor(color || colors.primary)}
        />
        <path
          d="M16.9041 7.40777C16.8556 7.40777 16.805 7.39878 16.7544 7.3808C16.5255 7.29762 16.4067 7.03908 16.4903 6.80528C16.7896 5.95774 16.629 5.01802 16.0656 4.29188C15.5 3.56349 14.6373 3.19255 13.7592 3.29821C13.5216 3.32743 13.2993 3.15208 13.2707 2.90479C13.2421 2.65749 13.4137 2.43493 13.6558 2.4057C14.8376 2.26407 15.9974 2.75866 16.7544 3.7321C17.5114 4.70328 17.7205 5.96673 17.3178 7.10877C17.254 7.29312 17.0845 7.40553 16.9041 7.40553V7.40777Z"
          fill={calculateColor(color || colors.primary)}
        />
      </g>
      <defs>
        <clipPath id="clip0_9911_118667">
          <rect width={width} height={height} fill={calculateColor(color || colors.primary)} />
        </clipPath>
      </defs>
    </svg>
  );
};
