import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ApproveTypeIconSvg: FunctionComponent<Props> = ({
  color,
  width = 29,
  height = 26,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3317 2.95783V1.6053C23.3317 0.718939 22.5976 0 21.6926 0H3.30115C2.39616 0 1.66211 0.718939 1.66211 1.6053V20.5242C2.27214 20.2157 2.94921 20.022 3.6598 19.9727C3.81733 19.9596 3.97152 19.9497 4.12905 19.9399L3.79722 15.6492C3.7503 15.0222 3.97152 14.4018 4.40725 13.9422C4.84299 13.4826 5.45638 13.2199 6.09658 13.2199H6.97475C7.61495 13.2199 8.23169 13.4826 8.66743 13.9422C8.74787 14.0242 8.81826 14.1162 8.88194 14.2081H14.0605C14.58 14.2081 14.999 14.6217 14.999 15.1273C14.999 15.3833 14.8951 15.6131 14.7242 15.7806C14.5566 15.948 14.322 16.0497 14.0605 16.0497H9.24394L9.02607 18.8828H18.9709C19.4905 18.8828 19.9095 19.2965 19.9095 19.8053C19.9095 20.0581 19.8055 20.2879 19.6346 20.4553C19.4637 20.6227 19.229 20.7245 18.9709 20.7245H12.5321C13.7689 21.4631 14.6705 22.6843 14.9119 24.0992L14.999 24.6015L14.6571 24.9856L14.3756 25.304L14.2616 25.4288V25.5634H21.6926C22.5976 25.5634 23.3317 24.8444 23.3317 23.9581V16.1088C19.8089 15.9053 17.0101 13.0328 17.0101 9.53333C17.0101 6.03384 19.8089 3.16136 23.3317 2.95783ZM14.7242 11.1025C14.5566 11.2699 14.322 11.3717 14.0605 11.3717H6.18707C5.66754 11.3717 5.24856 10.9614 5.24856 10.4525C5.24856 10.1965 5.35247 9.96667 5.52341 9.80253C5.69436 9.6351 5.92898 9.53333 6.18707 9.53333H14.0605C14.58 9.53333 14.999 9.94369 14.999 10.4525C14.999 10.7086 14.8951 10.9384 14.7242 11.1025ZM14.7242 6.42778C14.5566 6.5952 14.322 6.69697 14.0605 6.69697H6.18707C5.66754 6.69697 5.24856 6.28662 5.24856 5.77778C5.24856 5.52172 5.35247 5.29192 5.52341 5.12778C5.69436 4.96035 5.92898 4.85859 6.18707 4.85859H14.0605C14.58 4.85859 14.999 5.26894 14.999 5.77778C14.999 6.03384 14.8951 6.26364 14.7242 6.42778Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M13.833 24.2793C13.6218 24.5124 13.5414 24.6043 13.3302 24.8407H13.166V25.9995H0.904993V24.8407H0.492718C0.291609 24.5978 0.20111 24.4894 0 24.2465C0.18435 23.3634 0.66031 22.5952 1.32062 22.0306C1.42788 21.9387 1.54184 21.85 1.66251 21.768C2.25913 21.3576 2.97307 21.0983 3.74064 21.0425C4.24006 21.0064 4.75624 20.9801 5.30929 20.9637L4.89367 15.5667C4.85344 15.0382 5.17187 14.5687 5.64112 14.3816C5.7819 14.3225 5.93608 14.293 6.09697 14.293H6.97515C7.67903 14.293 8.23544 14.8806 8.18181 15.5667L8.14494 16.0493L7.92707 18.8824L7.78294 20.724L7.76618 20.9473C8.63095 20.9604 9.38846 20.9932 10.0991 21.0457C11.9526 21.1836 13.528 22.5132 13.833 24.2793Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
      <path
        d="M23.738 4.37891C23.6005 4.37891 23.4665 4.38547 23.3324 4.39532C20.6141 4.59886 18.4756 6.82133 18.4756 9.53295C18.4756 12.2446 20.6141 14.467 23.3324 14.6706C23.4665 14.6804 23.6005 14.687 23.738 14.687C26.644 14.687 29.0003 12.3792 29.0003 9.53295C29.0003 6.68673 26.644 4.37891 23.738 4.37891ZM26.4864 8.80416L23.6776 11.2597C23.5704 11.3516 23.4564 11.4206 23.3324 11.4665C23.2084 11.5158 23.0776 11.5388 22.9469 11.5388C22.6386 11.5388 22.3335 11.4042 22.1023 11.1448L20.9291 9.81199C20.6811 9.53295 20.6811 9.0832 20.9291 8.80416C21.1738 8.52512 21.5693 8.52512 21.8174 8.80416L22.9536 10.091L23.3324 9.75946L25.7189 7.67487C25.9937 7.43194 26.3892 7.49103 26.6004 7.8029C26.8149 8.11805 26.7613 8.56451 26.4864 8.80416Z"
        fill={color ? calculateColor(color) : "#5A5A5A"}
      />
    </svg>
  );
};
