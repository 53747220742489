import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const GavelIconSvg: FunctionComponent<Props> = ({
  color,
  width = 26,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.337 10.2703C14.2532 10.5124 14.1814 10.7118 14.0977 10.9396C13.8942 10.8399 13.7147 10.7545 13.4993 10.6548C13.1642 11.6373 12.8411 12.6056 12.494 13.6166C10.7228 12.7622 8.95165 11.9221 7.18047 11.0678C8.18573 8.07746 9.191 5.10141 10.1963 2.11112C11.9794 2.96549 13.7386 3.79138 15.5218 4.64575C15.2226 5.54283 14.9354 6.3972 14.6362 7.29429C14.8396 7.39397 15.0191 7.49364 15.2346 7.59332C15.1628 7.82115 15.091 8.03474 15.0191 8.27681C15.45 8.49041 15.8808 8.68976 16.2997 8.88911C18.9804 10.1707 21.673 11.4522 24.3538 12.7338C24.9043 12.9901 25.1436 13.5739 24.9402 14.172C24.7487 14.7415 24.2341 14.9694 23.6955 14.7131C20.7157 13.2891 17.7477 11.8794 14.7678 10.4555C14.6362 10.3985 14.5045 10.3415 14.349 10.2561L14.337 10.2703Z"
        fill={color || "#5A5A5A"}
      />
      <path d="M18.0469 19.2697V21.9894H0V19.2697H18.0469Z" fill={color || "#5A5A5A"} />
      <path
        d="M17.1853 16.9914V18.8995H0.849689C0.849689 18.3014 0.849689 17.7034 0.849689 17.1053C0.849689 17.0626 0.945428 16.9914 0.993298 16.9914C1.12494 16.9771 1.25658 16.9914 1.38822 16.9914C6.54619 16.9914 11.6922 16.9914 16.8502 16.9914H17.1853Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M16.7425 3.49235C16.7425 4.17584 16.192 4.61727 15.6295 4.36095C14.7798 3.96225 13.9421 3.5493 13.0924 3.1506C12.1589 2.70918 11.2255 2.26775 10.292 1.81209C9.7415 1.54154 9.59789 0.758366 10.0048 0.274224C10.2561 -0.0248052 10.5673 -0.0675236 10.9023 0.0891107C12.0153 0.615972 13.1163 1.14283 14.2293 1.66969C14.8875 1.98296 15.5577 2.31047 16.2159 2.62374C16.551 2.78037 16.7425 3.10788 16.7425 3.50659V3.49235Z"
        fill={color || "#5A5A5A"}
      />
      <path
        d="M12.9248 14.827C12.9248 15.4962 12.3863 15.9234 11.8478 15.6813C11.2374 15.4108 10.6391 15.1118 10.0287 14.8127C8.84395 14.2432 7.65917 13.6878 6.48636 13.1182C6.11537 12.9331 5.92389 12.5487 5.98372 12.093C6.03159 11.6658 6.30685 11.3383 6.67784 11.3098C6.80948 11.3098 6.96506 11.3241 7.0967 11.3953C8.87985 12.2354 10.663 13.0898 12.4342 13.9441C12.7693 14.1008 12.9368 14.414 12.9368 14.8412L12.9248 14.827Z"
        fill={color || "#5A5A5A"}
      />
    </svg>
  );
};
