import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const ClipboardSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 40,
  height = 40,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 17.22 17.99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fill={color ? calculateColor(color) : "#5a5a5a"}
        d="m4.26.74c-1.25,1.24-2.06,2.06-3.23,3.23h3.23V.74Z"
      />
      <path
        fill={color ? calculateColor(color) : "#5a5a5a"}
        d="m14.65,12.76c-.28,0-.5.22-.5.5v3.73H1V5.47h3.87c.5,0,.89-.39.89-.89V1h8.39v4.79c0,.28.22.5.5.5s.5-.22.5-.5V.89c0-.5-.39-.89-.89-.89H5.65c-.5,0-.89.39-.89.89v3.58H.89c-.5,0-.89.39-.89.89v11.74c0,.5.39.89.89.89h13.37c.5,0,.89-.39.89-.89v-3.84c0-.28-.22-.5-.5-.5Z"
      />
      <path
        fill={color ? calculateColor(color) : "#5a5a5a"}
        d="m11.82,6.36H3.22c-.33,0-.59.27-.59.6s.26.59.59.59h7.53l1.07-1.19Z"
      />
      <path
        fill={color ? calculateColor(color) : "#5a5a5a"}
        d="m9.42,9.02l.03-.03H3.22c-.33,0-.59.27-.59.6s.26.59.59.59h6.35l-.15-.16c-.26-.28-.26-.72,0-1Z"
      />
      <path
        fill={color ? calculateColor(color) : "#5a5a5a"}
        d="m2.63,12.22c0,.32.26.59.59.59h8.76l-1.08-1.18H3.22c-.33,0-.59.26-.59.59Z"
      />
      <path
        fill={color ? calculateColor(color) : "#5a5a5a"}
        d="m14.9,8.56c-.07-.01-.15-.01-.25-.03-.45-.03-1.13-.09-1.71-.15v-2.14s-.28.3-.28.3l-.91,1.01-1.3,1.44-.48.53.61.66,1.33,1.45.83.9.21.23v-2.13c.49-.05,1-.1,1-.1.17-.01.43-.02.7-.03h.2c.26,0,.52.01.68.07,1.07.32,1.36,1.26,1.45,2.36l.22-1.25c.14-1.63-.6-3.03-2.3-3.12Z"
      />
    </svg>
  );
};
