import { Box, Skeleton, Table, Text, useColors } from "@prodoctivity/design-system";
import type {
  HttpGetDeferredDistributionListRequest,
  HttpGetDeferredDistributionListResponse,
} from "@prodoctivity/types";
import { FunctionComponent, useMemo } from "react";

import { range } from "@prodoctivity/shared";
import { BreadCrumbEntry } from "../../../../components/BreadCrumb";
import { Page } from "../../../../components/Layout/Page";
import { Pagination } from "../../../../components/Layout/Pagination";
import { SearchComponentWrapper } from "../../../../components/SearchComponentWrapper";
import { usePaginatedDataEndpoint } from "../../../../components/hooks";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../../../link-templates";

const DeferredDistributionListPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      { type: "url", name: resources.settings, url: organizationLinkTemplates.settings() },
      {
        type: "url",
        name: resources.deferred.deferredDistributionList,
        url: organizationLinkTemplates.deferredDistributionList(),
      },
    ];
  }, [resources.deferred.deferredDistributionList, resources.home, resources.settings]);

  const PAGINATION_OPTIONS: HttpGetDeferredDistributionListRequest["queryParameters"]["rowsPerPage"][] =
    ["15", "30", "100"];

  const {
    currentPage,
    paginatedData,
    isLoading,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    refetch,
    nextPage,
    setFilter,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount,
  } = usePaginatedDataEndpoint<
    HttpGetDeferredDistributionListResponse["payload"],
    HttpGetDeferredDistributionListRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage) => {
      return services.getDeferredDistributionList(currentPage, rowsPerPage);
    },
    "settings_page_deferred_distribution_list"
  );

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <SearchComponentWrapper
          addButtonLabel={resources.add}
          addButtonText={resources.add}
          componentHeaderText={resources.deferred.deferredDistributionList}
          refetchFunction={refetch}
          refreshButtonLabel={resources.refresh}
          searchFieldId="distribution_filter"
          searchFieldLabel={resources.dataDictionary.searchElementsPlaceholder}
          setFilter={setFilter}
          omitSearch={true}
        />

        <Box margin={5} display="flex" direction="row">
          <Box display="flex" flex="grow" />
          <Box display="flex" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="distribution_pagination_top"
              rowsLabel={`${resources.distributions}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.deferredDistributionRequests.length : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={PAGINATION_OPTIONS}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
        <Box
          marginTop={8}
          marginStart={6}
          marginEnd={6}
          padding={5}
          color={colors.white}
          borderStyle="raisedTopShadow"
          borderRadius={4}
        >
          <Box>
            <Table accessibilityLabel={resources.deferred.deferredDistributionList}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Box paddingX={4}>
                      <Text size="200" weight="bold">
                        {resources.deferred.identifier.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.status.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.date.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Box>
                      <Text size="200" weight="bold">
                        {resources.type.toUpperCase()}
                      </Text>
                    </Box>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isLoading ? (
                  range(7).map((_, dt) => <RowSkeleton key={dt} />)
                ) : paginatedData && paginatedData.deferredDistributionRequests.length > 0 ? (
                  paginatedData.deferredDistributionRequests.map((distribution, index) => {
                    return (
                      <Table.Row
                        key={`${distribution.deferredRequestMessage.correlationId}_${index}`}
                      >
                        <Table.Cell>
                          <Box marginStart={4} display={"flex"}>
                            <Box>
                              <Text
                                size="300"
                                weight="bold"
                              >{`${distribution.deferredRequestMessage.correlationId}`}</Text>
                            </Box>
                            <Box width={1} borderStyle={"sm"} borderRadius={4} />
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box marginStart={4} display={"flex"}>
                            <Box>
                              <Text>{`${distribution.currentStatus}`}</Text>
                            </Box>
                            <Box width={1} borderStyle={"sm"} borderRadius={4} />
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box marginStart={4} display={"flex"}>
                            <Box>
                              <Text size="200">{`${new Date(
                                distribution.queueDate
                              ).toLocaleString()}`}</Text>
                            </Box>
                            <Box width={1} borderStyle={"sm"} borderRadius={4} />
                          </Box>
                        </Table.Cell>
                        <Table.Cell>
                          <Box marginStart={4} display={"flex"}>
                            <Box>
                              <Text size="200">{`${distribution.deferredRequestMessage.distributionConfiguration.name}`}</Text>
                              <Text> </Text>
                            </Box>
                            <Box width={1} borderStyle={"sm"} borderRadius={4} />
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral200}
                      >
                        <Text size="300">{resources.deferred.noDeferredDistributions}</Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Box>
        </Box>
        <Box marginTop={5} padding={2} display="flex" direction="row">
          <Box display="flex" flex="grow" />
          <Box display="flex" flex="shrink">
            <Pagination<typeof rowsPerPage>
              id="async_distribution_pagination_bottom"
              rowsLabel={`${resources.distributions}:`}
              currentPage={currentPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageLength={paginatedData ? paginatedData.pageLength : 0}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setPageLength}
              isNextButtonDisabled={isNextButtonDisabled}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              pageLengthOptions={PAGINATION_OPTIONS}
              totalRowCount={totalRowCount}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

const RowSkeleton: FunctionComponent = () => {
  return (
    <Table.Row>
      <Table.Cell>
        <Box marginStart={4} display={"flex"} justifyContent={"between"}>
          <Skeleton height={50} width={"100%"} />
          <Box width={1} borderStyle={"sm"} borderRadius={4} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box>
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="center">
          <Skeleton height={50} width={"100%"} />
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};

export default DeferredDistributionListPage;
