/** eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Checkbox,
  Skeleton,
  Table,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import {
  DocumentTypesInitialState,
  TemplatesInitialState,
  documentTypeExtractUniqueElements,
  templateExtractUniqueElements,
} from "../../components/Subscriptions/hooks";

import { Grid } from "@prodoctivity/design-system";
import { noop } from "@prodoctivity/prodoctivity-form-v5/src/components/_lib/utils";
import { FunctionComponent } from "react";

import { Page } from "../../components/Layout/Page";
import { Pagination } from "../../components/Layout/Pagination";
import { NotificationMessage } from "../../components/NotificationMessage";
import { DoctypeSubscriptionsTable } from "../../components/Subscriptions/DoctypeSubscriptionsTable";
import { TableHeaderCells } from "../../components/Subscriptions/TableHeaderCells";
import { TemplateSubscriptionsTable } from "../../components/Subscriptions/TemplateSubscriptionsTable";
import { useUserSubscriptions } from "./hooks";

const UserSubscriptionsPage: FunctionComponent = () => {
  const {
    breadCrumbEntries,
    isLoading,
    isError,
    documentTypeIsLoading,
    documentTypeIsError,
    resources,
    organizationNavigate,
    subscriptionTemplates,
    subscriptionDocumentType,
    //TODO: FIX
    currentTemplatesSubscriptions,
    //TODO: FIX
    currentDocumentTypeSubscriptions,
    showSuccessToast,
    showErrorToast,
    toastMessage,
    handleSaveClicked,
    handleCheckBoxOnChange,
    handleDocTypeCheckBoxOnChange,
    isMutating,
    documentTypeIsMutating,
    previousPage,
    nextPage,
    setPageLength,
    templatesTotalRowCount,
    documentTypePreviousPage,
    documentTypeNextPage,
    documentTypeSetPageLength,
    documentTypesTotalRowCount,
    templateSubscriptionsPageLengthOptions,
    documentTypeSubscriptionsPageLengthOptions,
    currentPage,
    rowsPerPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    docTypeCurrentPage,
    docTypeRowsPerPage,
    docTypeIsNextButtonDisabled,
    docTypeIsPreviousButtonDisabled,
    userEmailPreferences,
    getMyProfileIsLoading,
    getMyProfileIsError,
    emailPreferencesIsMutating,
    currentUserEmailPreferences,
    handleEmailPreferenceCheckBoxOnChange,
  } = useUserSubscriptions();

  const { colors } = useColors();

  if (
    isLoading ||
    documentTypeIsLoading ||
    getMyProfileIsLoading ||
    !userEmailPreferences ||
    !subscriptionTemplates ||
    !subscriptionDocumentType
  ) {
    return (
      <Box display="flex" padding={4}>
        <Box>
          <Skeleton show={true} />
        </Box>
      </Box>
    );
  }

  if (isError || documentTypeIsError || getMyProfileIsError) {
    return (
      <Page breadCrumbEntries={breadCrumbEntries}>
        <Box display="flex" direction="column">
          <Grid gridTemplateColumns={["1fr"]}>
            <Box marginStart={12} marginBottom={3}>
              <Text weight="bold" size="400">
                {resources.subscriptions.subscriptionsLabel}
              </Text>
            </Box>
            <Box
              height={"fit-content"}
              paddingY={3}
              paddingX={5}
              marginStart={10}
              marginEnd={10}
              marginBottom={12}
              color={colors.white}
              borderRadius={4}
            >
              <Table accessibilityLabel={resources.errorOccurred}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan={5}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral250}
                      >
                        <Text size="300">{resources.errorOccurred}</Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Box>
          </Grid>
        </Box>
      </Page>
    );
  }

  const templateSubscriptions: TemplatesInitialState = currentTemplatesSubscriptions;
  // subscriptionTemplates.subscriptionTemplates
  //   .flatMap((tpl) => {
  //     return tpl.mySubscriptions.map((sub) => {
  //       return {
  //         template: tpl,
  //         subscription: sub,
  //       };
  //     });
  //   })
  //   .map(({ template, subscription }) => {
  //     return {
  //       action: "unsubscribe",
  //       sendToMobile: subscription.sendToMobile,
  //       sendToWeb: subscription.sendToWeb,
  //       subscriptionType: subscription.subscriptionType,
  //       templateId: template.templateId,
  //       templateName: template.templateName,
  //     };
  //   });
  const documentTypeSubscriptions: DocumentTypesInitialState = currentDocumentTypeSubscriptions;
  // subscriptionDocumentType.subscriptionDocumentTypes
  //   .flatMap((docType) => {
  //     return docType.mySubscriptions.map((sub) => {
  //       return {
  //         documentType: docType,
  //         subscription: sub,
  //       };
  //     });
  //   })
  //   .map(({ documentType, subscription }) => {
  //     return {
  //       action: "unsubscribe",
  //       sendToMobile: subscription.sendToMobile,
  //       sendToWeb: subscription.sendToWeb,
  //       subscriptionType: subscription.subscriptionType,
  //       documentTypeId: documentType.documentTypeId,
  //       documentTypeName: documentType.documentTypeName,
  //     };
  // eslint-disable-next-line prettier/prettier
  //   });

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column">
        <Grid gridTemplateColumns={["1fr"]}>
          <Box marginStart={12} marginBottom={3}>
            <Text weight="bold" size="400">
              {resources.subscriptions.emailPreferencesTableTitle}
            </Text>
          </Box>
          <Box
            height={"fit-content"}
            display="flex"
            justifyContent="between"
            paddingY={3}
            marginStart={10}
            marginEnd={10}
            marginBottom={12}
            color={colors.white}
          >
            <Box width={"70%"} marginStart={6}>
              <Text size="200">{resources.iWantEmails}</Text>
            </Box>
            <Box marginEnd={5}>
              <Checkbox
                id={`emailSubscriptionCheckbox_${userEmailPreferences?.email}`}
                checked={currentUserEmailPreferences}
                onChange={handleEmailPreferenceCheckBoxOnChange}
                disabled={emailPreferencesIsMutating}
              />
            </Box>
          </Box>

          <Box marginStart={12} marginBottom={3}>
            <Text weight="bold" size="400">
              {resources.subscriptions.templatesTableTitle}
            </Text>
          </Box>
          <Box
            height={"fit-content"}
            paddingY={3}
            paddingX={5}
            marginStart={10}
            marginEnd={10}
            marginBottom={12}
            color={colors.white}
          >
            <Table accessibilityLabel={resources.subscriptions.subscriptionsTable}>
              <Table.Header>
                <Table.Row>
                  <TableHeaderCells tableTitle={resources.generationStatus.templateName} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {templateSubscriptions.length !== 0 ? (
                  <TemplateSubscriptionsTable
                    extractUniqueElements={templateExtractUniqueElements}
                    currentTemplatesSubscriptions={templateSubscriptions}
                    handleCheckBoxOnChange={handleCheckBoxOnChange}
                    isMutating={isMutating}
                  />
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral200}
                      >
                        <Text size="300">{resources.subscriptions.noTemplateSubscriptions}</Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Box display="flex" flex="grow">
              <Box display="flex" flex="grow" />
              <Box display="flex" flex="shrink">
                <Pagination<typeof rowsPerPage>
                  id={`subscription_page_template_list_pagination_top`}
                  rowsLabel={`${resources.subscriptions.templatesTableTitle}:`}
                  currentPage={currentPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  pageLength={subscriptionTemplates.subscriptionTemplates.length || 0}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setPageLength}
                  isNextButtonDisabled={isNextButtonDisabled}
                  isPreviousButtonDisabled={isPreviousButtonDisabled}
                  pageLengthOptions={templateSubscriptionsPageLengthOptions}
                  totalRowCount={templatesTotalRowCount}
                />
              </Box>
            </Box>
          </Box>

          <Box marginStart={12} marginBottom={3}>
            <Text weight="bold" size="400">
              {resources.subscriptions.documentTypesTableTitle}
            </Text>
          </Box>
          <Box
            height={"fit-content"}
            paddingY={3}
            paddingX={5}
            marginStart={10}
            marginEnd={10}
            marginBottom={12}
            color={colors.white}
          >
            <Table accessibilityLabel={resources.subscriptions.subscriptionsTable}>
              <Table.Header>
                <Table.Row>
                  <TableHeaderCells tableTitle={resources.documentTypes.documentTypeName} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {documentTypeSubscriptions.length !== 0 ? (
                  <DoctypeSubscriptionsTable
                    documentTypeExtractUniqueElements={documentTypeExtractUniqueElements}
                    currentDocumentTypeSubscriptions={documentTypeSubscriptions}
                    handleDocTypeCheckBoxOnChange={handleDocTypeCheckBoxOnChange}
                    documentTypeIsMutating={documentTypeIsMutating}
                  />
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={5}>
                      <Box
                        borderRadius={4}
                        borderStyle="sm"
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color={colors.neutral200}
                      >
                        <Text size="300">
                          {resources.subscriptions.noDocumentTypeSubscriptions}
                        </Text>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Box display="flex" flex="grow">
              <Box display="flex" flex="grow" />
              <Box display="flex" flex="shrink">
                <Pagination<typeof docTypeRowsPerPage>
                  id={`subscription_page_template_list_pagination_bottom`}
                  rowsLabel={`${resources.subscriptions.documentTypesTableTitle}:`}
                  currentPage={docTypeCurrentPage}
                  nextPage={documentTypeNextPage}
                  previousPage={documentTypePreviousPage}
                  pageLength={subscriptionDocumentType?.subscriptionDocumentTypes.length || 0}
                  rowsPerPage={docTypeRowsPerPage}
                  setRowsPerPage={documentTypeSetPageLength}
                  isNextButtonDisabled={docTypeIsNextButtonDisabled}
                  isPreviousButtonDisabled={docTypeIsPreviousButtonDisabled}
                  pageLengthOptions={documentTypeSubscriptionsPageLengthOptions}
                  totalRowCount={documentTypesTotalRowCount}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="end" paddingY={4} marginStart={10} marginEnd={10}>
            <Box width={150} marginEnd={4}>
              <Button
                color={"transparent"}
                onClick={() => {
                  organizationNavigate("/");
                }}
                disabled={isMutating || documentTypeIsMutating || emailPreferencesIsMutating}
                text={resources.cancel}
              />
            </Box>
            <Box width={150}>
              <Button
                color={"blue"}
                onClick={handleSaveClicked}
                disabled={isMutating || documentTypeIsMutating || emailPreferencesIsMutating}
                text={resources.saveChanges}
              />
            </Box>
          </Box>
        </Grid>
        {showSuccessToast && (
          <NotificationMessage
            type="success"
            message={toastMessage}
            position="bottom-left"
            handleDismiss={noop}
          />
        )}
        {showErrorToast && (
          <NotificationMessage
            type="error"
            message={toastMessage}
            position="bottom-left"
            handleDismiss={noop}
          />
        )}
      </Box>
    </Page>
  );
};

export default UserSubscriptionsPage;
