import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const DocumentInfoSVG: FunctionComponent<Props> = ({ width = 31, height = 28, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6052_119972)">
        <path
          d="M15.7637 5.72578V1.15234L20.349 5.72578H15.7637Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M21.9287 5.7257L16.203 0H2.0432C0.914687 0 0 0.914687 0 2.03132V24.9816C0 26.1102 0.914687 27.0248 2.0432 27.0248H19.9687C21.0972 27.0248 22.0119 26.1102 22.0119 24.9816V5.79698L21.9406 5.7257H21.9287ZM20.8715 24.9816C20.8715 25.4924 20.4676 25.8963 19.9568 25.8963H2.0432C1.54428 25.8963 1.12851 25.4806 1.12851 24.9816V2.03132C1.12851 1.5324 1.5324 1.12851 2.0432 1.12851H14.635V6.85421H20.8834V24.9816H20.8715Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M6.94934 12.4493L6.18909 14.7657H5.20312L7.7096 7.37695H8.86187L11.3802 14.7657H10.3586L9.57462 12.4493H6.96122H6.94934ZM9.37267 11.7009L8.64805 9.57458C8.48174 9.08754 8.37483 8.65989 8.26792 8.23224H8.24416C8.13725 8.67177 8.01846 9.12317 7.87591 9.57458L7.15129 11.7128H9.37267V11.7009Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M13.5899 8.54148H13.5661L12.3307 9.20671L12.1406 8.47021L13.6968 7.63867H14.5164V14.7542H13.5899V8.5296V8.54148Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M5.33386 17.7832H3.96777V17.0586H7.579V17.7832H6.20103V21.834H5.33386V17.7832Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M8.17317 20.3489C8.19693 20.9666 8.68397 21.2398 9.24228 21.2398C9.64617 21.2398 9.93127 21.1804 10.2045 21.0854L10.3352 21.6912C10.0382 21.81 9.62241 21.905 9.13537 21.905C8.01874 21.905 7.35352 21.216 7.35352 20.1588C7.35352 19.1966 7.93559 18.3057 9.04034 18.3057C10.1451 18.3057 10.5252 19.2322 10.5252 19.9806C10.5252 20.1469 10.5133 20.2657 10.5015 20.3489H8.19693H8.17317ZM9.69369 19.743C9.69369 19.4223 9.56302 18.8996 8.98095 18.8996C8.44639 18.8996 8.22069 19.3867 8.18505 19.743H9.70557H9.69369Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M11.7361 18.377L12.1519 19.0303C12.2707 19.2204 12.3657 19.3867 12.4607 19.5649H12.4845C12.5795 19.3748 12.6864 19.1966 12.7815 19.0184L13.1616 18.377H14.1119L12.9597 20.04L14.1357 21.8338H13.1497L12.7221 21.1448C12.6033 20.9547 12.5083 20.7765 12.4132 20.5865H12.4013C12.3063 20.7884 12.1994 20.9547 12.0925 21.1448L11.7005 21.8338H10.7383L11.9381 20.0757L10.7858 18.377H11.7599H11.7361Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M15.7515 17.3908V18.3767H16.583V19.0301H15.7515V20.5506C15.7515 20.9664 15.8703 21.1921 16.2029 21.1921C16.3573 21.1921 16.4643 21.1683 16.5474 21.1446V21.8098C16.4286 21.8573 16.2029 21.8929 15.9297 21.8929C15.609 21.8929 15.3476 21.786 15.1813 21.6078C14.9913 21.4059 14.9081 21.097 14.9081 20.6456V19.0182H14.4092V18.3649H14.9081V17.5809L15.7634 17.3789L15.7515 17.3908Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M17.7471 15.1461H17.6758V14.8135C17.6995 14.9204 17.7233 15.0273 17.7471 15.1461Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M19.5055 15.6449C19.5055 15.9181 19.2798 16.1438 19.0066 16.1438H17.6643V17.4861C17.6643 17.6881 17.5455 17.8544 17.3792 17.9375C17.3198 17.9613 17.2485 17.985 17.1654 17.985C17.1179 17.985 17.0822 17.985 17.0347 17.9613C16.9397 17.9494 16.8446 17.89 16.7852 17.8068C16.714 17.7237 16.6664 17.6049 16.6664 17.4861V16.1438H15.3241C15.0509 16.1438 14.8252 15.9181 14.8252 15.6449C14.8252 15.3716 15.0509 15.1459 15.3241 15.1459H16.6664V13.8036C16.6664 13.6848 16.7021 13.5779 16.7734 13.4948C16.8328 13.4235 16.9159 13.3522 17.0109 13.3284C17.0585 13.3047 17.106 13.3047 17.1654 13.3047C17.4386 13.3047 17.6643 13.5304 17.6643 13.8036V15.1459H19.0066C19.2798 15.1459 19.5055 15.3716 19.5055 15.6449Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6052_119972">
          <rect width="22" height="27.013" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
