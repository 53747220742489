import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const PrisonCellIconSvg: FunctionComponent<Props> = ({
  color,
  width = 22,
  height = 25,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5718_96443)">
        <path
          d="M6.00252 15.6599V22.4673H1.94063L1.44141 22.4541V2.48047H2.40582V17.1243C2.99582 15.9633 4.08504 15.2377 5.3558 15.2905C5.56002 15.3037 5.84368 15.4752 5.99117 15.6467C5.99117 15.6467 5.99117 15.6599 6.00252 15.6599Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M10.5287 16.3848V22.4666H6.94336V16.5695C8.06662 17.3082 9.46218 17.2555 10.5287 16.3848Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M4.6748 9.65664C4.6748 8.27142 5.18538 7.0445 6.00229 6.22656V13.0208C5.18538 12.2028 4.68615 10.9891 4.6748 9.65664Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M8.57719 5.09277C9.29199 5.09277 9.96141 5.30386 10.5287 5.68644V13.5492C9.96141 13.9318 9.29199 14.1429 8.58854 14.1429C8.00989 14.1429 7.44259 13.9846 6.94336 13.7207V5.51494C7.43124 5.23789 7.99854 5.09277 8.57719 5.09277Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M12.4809 9.63098C12.4809 10.7919 12.1065 11.8605 11.4824 12.6653V6.59668C12.1065 7.40143 12.4809 8.47003 12.4809 9.63098Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M6.00195 22.4805H6.93233C6.64868 22.4805 6.36503 22.4937 6.00195 22.5069V22.4805Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M20.5473 10.4224V2.52003H19.5716V10.4092H16.0429V2.50684H15.0445V10.4224H15.0331V18.1797C14.6133 16.768 13.6035 15.6467 12.2874 15.3432C11.9243 15.2509 11.6974 15.2641 11.4932 15.396V22.4673H15.226L16.0543 22.4541V18.3907H19.5942V22.4541H20.57V10.4224H20.5587H20.5473ZM18.176 14.4461L18.3462 16.1612H17.2229L17.3931 14.4461C17.1322 14.2878 16.962 13.9712 16.962 13.615C16.962 13.0741 17.325 12.652 17.7902 12.652C18.2554 12.652 18.6071 13.0741 18.6071 13.615C18.6071 13.9712 18.4256 14.2878 18.176 14.4461Z"
          fill={color || "#5A5A5A"}
        />
        <path d="M22 0H0V1.53034H22V0Z" fill={color || "#5A5A5A"} />
        <path d="M22 23.4561H0V24.9864H22V23.4561Z" fill={color || "#5A5A5A"} />
      </g>
      <defs>
        <clipPath id="clip0_5718_96443">
          <rect width="22" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
