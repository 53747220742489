import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const MenuSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`4 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_4229_80001)">
        <path d="M32 8H7V33H32V8Z" fill="white" />
        <path d="M20.2068 19.2152H19.9878L20.0968 19.0742L20.2068 19.2152Z" fill="white" />
        <path d="M18.7547 26.6182V28.8982L16.4897 26.6182H18.7547Z" fill="white" />
        <path
          d="M16.3799 11.5615V14.3415C18.4771 15.2039 20.29 16.638 21.6119 18.4805C21.7228 18.6398 21.7676 18.8359 21.7369 19.0275C21.7369 19.0275 21.7369 19.0275 21.7369 19.1215C21.766 19.3131 21.7213 19.5086 21.6119 19.6685C20.283 21.5005 18.4718 22.9278 16.3799 23.7915V25.0615H19.5039C19.711 25.0615 19.9097 25.1438 20.0561 25.2903C20.2026 25.4367 20.2849 25.6354 20.2849 25.8425V29.4505H30.4369V11.5615H16.3799ZM27.7039 23.7295H24.8459C24.6403 23.7247 24.4444 23.6409 24.299 23.4954C24.1535 23.35 24.0697 23.1541 24.0649 22.9485C24.0649 22.7414 24.1472 22.5427 24.2936 22.3963C24.4401 22.2498 24.6387 22.1675 24.8459 22.1675H27.6459C27.853 22.1675 28.0517 22.2498 28.1981 22.3963C28.3446 22.5427 28.4269 22.7414 28.4269 22.9485C28.4221 23.1541 28.3382 23.35 28.1928 23.4954C28.0474 23.6409 27.8515 23.7247 27.6459 23.7295H27.7039ZM27.7039 19.8555H24.0649C23.8593 19.8507 23.6634 19.7669 23.518 19.6214C23.3725 19.476 23.2887 19.2801 23.2839 19.0745C23.2839 18.8674 23.3662 18.6687 23.5126 18.5223C23.6591 18.3758 23.8577 18.2935 24.0649 18.2935H27.6419C27.849 18.2935 28.0477 18.3758 28.1941 18.5223C28.3406 18.6687 28.4229 18.8674 28.4229 19.0745C28.4181 19.2801 28.3342 19.476 28.1888 19.6214C28.0434 19.7669 27.8475 19.8507 27.6419 19.8555H27.7039ZM27.7039 15.7635H21.4559C21.2487 15.7635 21.0501 15.6812 20.9036 15.5348C20.7572 15.3883 20.6749 15.1897 20.6749 14.9825C20.6749 14.7754 20.7572 14.5767 20.9036 14.4303C21.0501 14.2838 21.2487 14.2015 21.4559 14.2015H27.7039C27.911 14.2015 28.1097 14.2838 28.2561 14.4303C28.4026 14.5767 28.4849 14.7754 28.4849 14.9825C28.4825 15.0897 28.4586 15.1953 28.4146 15.293C28.3706 15.3908 28.3074 15.4787 28.2288 15.5515C28.1501 15.6244 28.0577 15.6807 27.9568 15.7171C27.856 15.7535 27.7489 15.7693 27.6419 15.7635H27.7039Z"
          fill="white"
        />
        <path
          d="M20.2068 19.2152H19.9878L20.0968 19.0742L20.2068 19.2152Z"
          fill={calculateColor(color)}
        />
        <path
          d="M21.6128 18.4818C20.2905 16.6404 18.4776 15.2073 16.3808 14.3458C16.1276 14.2492 15.8662 14.1759 15.5998 14.1268C15.3442 14.0638 15.0819 14.0322 14.8188 14.0328C14.6942 14.0177 14.5683 14.0177 14.4438 14.0328C10.3828 13.9988 7.27476 18.2938 7.14976 18.4818C7.03246 18.6381 6.9808 18.834 7.00576 19.0278V19.1218C6.97664 19.3134 7.02131 19.5089 7.13076 19.6688C7.25576 19.8408 10.3638 24.1358 14.3628 24.1358C14.5168 24.1323 14.6694 24.106 14.8158 24.0578C15.0543 24.0844 15.2952 24.0844 15.5338 24.0578C15.8229 23.9973 16.1053 23.9082 16.3768 23.7918C18.4688 22.9277 20.28 21.5001 21.6088 19.6678C21.7182 19.5079 21.7629 19.3124 21.7338 19.1208V19.0278C21.7644 18.8361 21.7196 18.6401 21.6088 18.4808L21.6128 18.4818ZM20.0508 19.2158C19.0474 20.438 17.7962 21.4336 16.3798 22.1368C16.1256 22.2467 15.8647 22.3405 15.5988 22.4178C15.3414 22.4676 15.0799 22.4938 14.8178 22.4958C14.6932 22.5108 14.5673 22.5108 14.4428 22.4958C11.9278 22.4958 9.75676 20.1368 8.83576 19.1378L8.72676 18.9968L8.83576 18.8558C9.67976 17.8458 11.9598 15.4828 14.4438 15.4828C14.5683 15.4677 14.6942 15.4677 14.8188 15.4828C15.0824 15.5003 15.3438 15.5421 15.5998 15.6078C15.8685 15.6843 16.1299 15.7847 16.3808 15.9078C17.7893 16.6436 19.0206 17.6776 19.9888 18.9378L20.0978 19.0788L20.2068 19.2198L20.0508 19.2158Z"
          fill={calculateColor(color)}
        />
        <path
          d="M19.9886 18.9344C19.0088 17.7138 17.7784 16.7176 16.3806 16.0134C16.1297 15.8904 15.8683 15.79 15.5996 15.7134C15.3436 15.6478 15.0822 15.6059 14.8186 15.5884C14.694 15.5734 14.5681 15.5734 14.4436 15.5884C11.9286 15.5884 9.75756 17.9474 8.83656 18.9624L8.72656 19.1074L8.83556 19.2484C9.67856 20.2484 11.9596 22.6064 14.4426 22.6064C14.5716 22.5859 14.6976 22.5493 14.8176 22.4974C15.0565 22.5176 15.2967 22.5176 15.5356 22.4974C15.8245 22.3974 16.1062 22.2774 16.3786 22.1384C17.7723 21.4279 19.0014 20.4328 19.9866 19.2174L20.0956 19.0764L19.9886 18.9344ZM16.9736 19.2154C16.9509 19.7635 16.7413 20.2872 16.3796 20.6994C16.1662 20.9486 15.8992 21.1463 15.5986 21.2774C15.3593 21.416 15.0929 21.5013 14.8176 21.5274H14.4436C13.7911 21.5471 13.1553 21.32 12.6629 20.8914C12.1706 20.4629 11.858 19.8644 11.7876 19.2154C11.7721 19.1224 11.7721 19.0275 11.7876 18.9344C11.7792 18.5872 11.8399 18.2417 11.966 17.9181C12.0922 17.5945 12.2814 17.2991 12.5226 17.0491C12.7638 16.7992 13.0523 16.5996 13.3712 16.462C13.6901 16.3244 14.0332 16.2514 14.3806 16.2474H14.7556C15.0309 16.2736 15.2973 16.3589 15.5366 16.4974C15.8372 16.6286 16.1042 16.8262 16.3176 17.0754C16.7808 17.5815 17.0274 18.2488 17.0046 18.9344C17.0098 19.0292 16.9993 19.1241 16.9736 19.2154V19.2154Z"
          fill="white"
        />
        <path
          d="M17.0049 18.9341C17.0204 19.0271 17.0204 19.122 17.0049 19.2151C16.9737 19.767 16.7529 20.2911 16.3799 20.6991C16.1666 20.9483 15.8996 21.1459 15.5989 21.2771C15.3596 21.4156 15.0932 21.5009 14.8179 21.5271H14.4429C13.7907 21.5465 13.1551 21.3192 12.6629 20.8907C12.1708 20.4622 11.8584 19.8638 11.7879 19.2151C11.7725 19.122 11.7725 19.0271 11.7879 18.9341C11.7796 18.5868 11.8402 18.2413 11.9664 17.9177C12.0926 17.5941 12.2818 17.2987 12.523 17.0488C12.7642 16.7988 13.0526 16.5992 13.3716 16.4616C13.6905 16.324 14.0336 16.2511 14.3809 16.2471H14.7559C15.0312 16.2732 15.2976 16.3585 15.5369 16.4971C15.8376 16.6283 16.1046 16.8259 16.3179 17.0751C16.7811 17.5811 17.0278 18.2484 17.0049 18.9341V18.9341Z"
          fill={calculateColor(color)}
        />
        <path
          d="M28.4228 14.9822C28.418 15.1878 28.3341 15.3836 28.1887 15.5291C28.0433 15.6745 27.8474 15.7583 27.6418 15.7632H21.3938C21.1867 15.7632 20.988 15.6809 20.8415 15.5344C20.6951 15.388 20.6128 15.1893 20.6128 14.9822C20.6128 14.775 20.6951 14.5764 20.8415 14.4299C20.988 14.2835 21.1867 14.2012 21.3938 14.2012H27.6418C27.7444 14.2012 27.8459 14.2214 27.9407 14.2606C28.0354 14.2999 28.1215 14.3574 28.194 14.4299C28.2666 14.5024 28.3241 14.5885 28.3633 14.6833C28.4026 14.7781 28.4228 14.8796 28.4228 14.9822V14.9822Z"
          fill={calculateColor(color)}
        />
        <path
          d="M28.4227 19.0749C28.4179 19.2806 28.3341 19.4764 28.1886 19.6219C28.0432 19.7673 27.8473 19.8511 27.6417 19.8559H24.0637C23.8581 19.8511 23.6622 19.7673 23.5168 19.6219C23.3714 19.4764 23.2875 19.2806 23.2827 19.0749C23.2827 18.9724 23.3029 18.8708 23.3422 18.7761C23.3814 18.6813 23.4389 18.5952 23.5115 18.5227C23.584 18.4502 23.6701 18.3926 23.7648 18.3534C23.8596 18.3141 23.9612 18.2939 24.0637 18.2939H27.6407C27.7434 18.2938 27.845 18.3139 27.9399 18.3531C28.0348 18.3923 28.121 18.4498 28.1936 18.5223C28.2662 18.5949 28.3239 18.681 28.3632 18.7758C28.4025 18.8707 28.4227 18.9723 28.4227 19.0749Z"
          fill={calculateColor(color)}
        />
        <path
          d="M28.423 22.949C28.4181 23.1546 28.3343 23.3504 28.1889 23.4959C28.0434 23.6413 27.8476 23.7251 27.642 23.73H24.845C24.6393 23.7251 24.4435 23.6413 24.2981 23.4959C24.1526 23.3504 24.0688 23.1546 24.064 22.949C24.064 22.7418 24.1462 22.5432 24.2927 22.3967C24.4392 22.2503 24.6378 22.168 24.845 22.168H27.645C27.8516 22.1688 28.0495 22.2514 28.1953 22.3978C28.3411 22.5442 28.423 22.7424 28.423 22.949Z"
          fill={calculateColor(color)}
        />
        <path
          d="M31.1719 9.99952H15.5519C15.353 10.0115 15.1662 10.0991 15.0298 10.2442C14.8933 10.3894 14.8175 10.5813 14.8179 10.7805V14.0295C15.0811 14.029 15.3433 14.0605 15.5989 14.1235C15.8653 14.1726 16.1268 14.2459 16.3799 14.3425V11.5615H30.4369V29.4465H20.3179V25.8385C20.3179 25.6314 20.2356 25.4327 20.0891 25.2863C19.9427 25.1398 19.744 25.0575 19.5369 25.0575H16.3799V23.7915C16.1268 23.8881 15.8653 23.9614 15.5989 24.0105C15.3414 24.0577 15.0792 24.0735 14.8179 24.0575V26.7285C14.8167 26.9325 14.8953 27.1288 15.0369 27.2755L18.4259 30.7755C18.5714 30.9209 18.7672 31.0047 18.9729 31.0095H31.1729C31.3747 31.0227 31.5737 30.9572 31.7281 30.8266C31.8826 30.6961 31.9803 30.5107 32.0009 30.3095V10.7805C32.0011 10.6738 31.9794 10.5682 31.9372 10.4701C31.895 10.3721 31.8331 10.2838 31.7554 10.2106C31.6777 10.1374 31.5859 10.0809 31.4855 10.0446C31.3852 10.0083 31.2784 9.99296 31.1719 9.99952V9.99952ZM18.6759 28.8995L16.4889 26.6195H18.7539L18.6759 28.8995Z"
          fill={calculateColor(color)}
        />
      </g>
      <defs>
        <clipPath id="clip0_4229_80001">
          <rect width="25" height="25" fill="white" transform="translate(7 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};
