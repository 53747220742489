import {
  Box,
  BoxWithRef,
  Button,
  Divider,
  FancyDateTime,
  Icon,
  Layer,
  Modal,
  SearchSvgIcon,
  Skeleton,
  Spinner,
  Table,
  TapArea,
  Text,
  TextField,
  Tooltip,
  VerticalEllipseMenuSvg,
  modalZIndex,
  overlayPanelZIndex,
  popupZIndex,
  useColors,
} from "@prodoctivity/design-system";
import type {
  DocumentTypeSettingsFilter,
  ParametersObject,
} from "@prodoctivity/shared/src/index-types";
import type {
  EcmDocument,
  HttpGetDocumentTypeListRequest,
  ListableDocumentType,
} from "@prodoctivity/types";
import { useCallback, useMemo, useRef, useState } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { LookUpDocumentSvg } from "../../svg/LookUpDocumentSvg";
import { useAutoFocus } from "../../utils";
import { Pagination } from "../Layout/Pagination";
import { useLookupDocument } from "./hook";

export type StateProps = {
  context: ParametersObject | undefined;
  isLoading: boolean;
};

const PAGINATION_OPTIONS: HttpGetDocumentTypeListRequest["queryParameters"]["rowsPerPage"][] = [
  "15",
  "30",
  "100",
];

export type LookUpDocumentProps = {
  onChange?: (n: StateProps) => void;
  setDocumentSearchOpen: (n: boolean) => void;
  isDocumentSearchOpen: boolean;
};
type PropsDocumentSearchPopover = LookUpDocumentProps & {
  children: React.ReactNode;
  setValue: (n: string) => void;
  updateContext: () => void;
  selectedDocumentInfo: { documentId: string; documentVersionId: string };
  documentResponse:
    | {
        document: EcmDocument;
      }
    | {
        document: undefined;
      }
    | undefined;
  setSelectedDocumentInfo: (n: { documentId: string; documentVersionId: string }) => void;
  loading: boolean;
  setDocumentTypeIdSelected: (n: string) => void;
};

type DocumentInfoTableProps = {
  tableOptions: {
    value: string;
  }[];
  data: {
    name: string;
    documentId: string;
    documentVersionId: string;
    documentTypeName: string;
    date: number;
  }[];
  setSelectedDocumentInfo: (n: { documentId: string; documentVersionId: string }) => void;
  selectedDocumentInfo: { documentId: string; documentVersionId: string };
};

export function LookupDocument({
  isDocumentSearchOpen,
  setDocumentSearchOpen,
  onChange,
}: LookUpDocumentProps) {
  const {
    data,
    value,
    setValue,
    setSelectedDocumentInfo,
    documentTypeIdSelected,
    tableOptions,
    handleSearchSelection,
    resources,
    colors,
    selectedDocumentInfo,
    updateContext,
    isLoading,
    documentResponse,
    documentTypeData,
    loading,
    setDocumentTypeIdSelected,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount,
    setFilterValue,
    filterValue,
    handleChange,
    filterTextFieldValue,
    isLoadingDocumentTypeData,
  } = useLookupDocument({
    onChange,
    isDocumentSearchOpen,
    setDocumentSearchOpen,
  });

  const textBoxRef = useRef<HTMLInputElement | null>(null);
  useAutoFocus(textBoxRef, true);

  const [openDocumentTypeSelector, setDocumentTypeSelectorOpen] = useState(false);

  const dropdownOptions = useMemo(() => {
    const values: {
      value: string;
      label: string;
    }[] = [
      {
        value: resources.all,
        label: resources.all,
      },
    ];
    documentTypeData?.documentTypes.forEach((dt) => {
      const value = {
        value: dt.documentTypeVersionId ? dt.documentTypeVersionId : dt.documentTypeId,
        label: dt.name,
      };
      values.push(value);
    });

    return values;
  }, [documentTypeData, resources]);

  return (
    <DocumentSearchPopover
      children={
        <>
          {isDocumentSearchOpen && (
            <Box>
              <Box display="flex" direction="column" flex="grow" gap={2}>
                <Box
                  width={"100%"}
                  display="flex"
                  alignItems="center"
                  // justifyContent="between"
                >
                  <Box display="flex" flex="grow" direction="column" width={"100%"} gap={2}>
                    <DocumentTypeFilterDropdown
                      documentTypeIdSelected={documentTypeIdSelected}
                      onChange={handleSearchSelection}
                      items={dropdownOptions}
                      currentPage={currentPage}
                      isNextButtonDisabled={isNextButtonDisabled}
                      isPreviousButtonDisabled={isPreviousButtonDisabled}
                      nextPage={nextPage}
                      previousPage={previousPage}
                      rowsPerPage={rowsPerPage}
                      setPageLength={setPageLength}
                      totalRowCount={totalRowCount}
                      pageLength={documentTypeData ? documentTypeData.documentTypes.length : 0}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      documentTypeData={documentTypeData?.documentTypes || []}
                      handleChange={handleChange}
                      filterTextFieldValue={filterTextFieldValue}
                      isLoadingDocumentTypeData={isLoadingDocumentTypeData}
                      openDocumentTypeSelector={openDocumentTypeSelector}
                      setDocumentTypeSelectorOpen={setDocumentTypeSelectorOpen}
                    />
                    {!openDocumentTypeSelector && (
                      <Box display="flex" flex="grow" borderStyle="sm" borderRadius={12}>
                        <TextField
                          fullWith
                          id={"Look_up_document"}
                          value={value}
                          onChange={(e) => {
                            setValue(e.value);
                          }}
                          placeholder={resources.search}
                          mode={"unstyled"}
                          autoComplete={"off"}
                          ref={textBoxRef}
                        />
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          paddingX={4}
                        >
                          <Icon
                            color={colors.neutral900}
                            icon="search"
                            accessibilityLabel={resources.search}
                            size={6}
                            onClick={() => {
                              if (textBoxRef.current) {
                                textBoxRef.current.focus();
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>

                {data.length === 0 && !isLoading && !openDocumentTypeSelector && (
                  <Box
                    maxHeight={350}
                    height={350}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                  >
                    <LookUpDocumentSvg width={300} height={250} />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      direction="column"
                    >
                      <Text size="600" color={colors.neutral900}>
                        {resources.searchForDocuments}
                      </Text>
                      <Text size="300" color={colors.neutral700}>
                        {resources.byNameOrByTypeOfDocument}
                      </Text>
                    </Box>
                  </Box>
                )}
                {!data && isLoading && !openDocumentTypeSelector && (
                  <Skeleton show={isLoading}></Skeleton>
                )}
                {data.length > 0 && !isLoading && !openDocumentTypeSelector && (
                  <DocumentInfoTable
                    tableOptions={tableOptions}
                    data={data}
                    setSelectedDocumentInfo={setSelectedDocumentInfo}
                    selectedDocumentInfo={selectedDocumentInfo}
                  />
                )}
              </Box>
            </Box>
          )}
        </>
      }
      isDocumentSearchOpen={isDocumentSearchOpen}
      setDocumentSearchOpen={setDocumentSearchOpen}
      setValue={setValue}
      updateContext={updateContext}
      selectedDocumentInfo={selectedDocumentInfo}
      documentResponse={documentResponse}
      setSelectedDocumentInfo={setSelectedDocumentInfo}
      loading={loading}
      setDocumentTypeIdSelected={setDocumentTypeIdSelected}
    />
  );
}

const DocumentInfoTable = ({
  tableOptions,
  data,
  setSelectedDocumentInfo,
  selectedDocumentInfo,
}: DocumentInfoTableProps) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();

  return (
    <Table accessibilityLabel={resources.search}>
      <Table.Header>
        <Table.Row>
          {tableOptions.map((i, idx) => {
            return <Table.HeaderCell key={idx}>{i.value}</Table.HeaderCell>;
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data?.map((d, idx) => {
          const disableColors =
            selectedDocumentInfo.documentVersionId === d.documentVersionId
              ? colors.neutral900
              : undefined;
          return (
            <Table.Row key={idx} hoverStyle={"gray"}>
              <Table.Cell>
                <Box width={200}>
                  <TapArea
                    onTap={() => {
                      setSelectedDocumentInfo({
                        documentId: d.documentId,
                        documentVersionId: d.documentVersionId,
                      });
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text
                        size="100"
                        overflow="ellipsis"
                        title={d.documentTypeName}
                        color={disableColors}
                      >
                        {d.documentTypeName}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Table.Cell>

              <Table.Cell>
                <Box width={300}>
                  <TapArea
                    onTap={() => {
                      setSelectedDocumentInfo({
                        documentId: d.documentId,
                        documentVersionId: d.documentVersionId,
                      });
                    }}
                  >
                    <Box>
                      <Text size="100" overflow="ellipsis" title={d.name} color={disableColors}>
                        {d.name}
                      </Text>
                    </Box>
                  </TapArea>
                </Box>
              </Table.Cell>
              <Table.Cell>
                <Box width={100}>
                  <TapArea
                    onTap={() => {
                      setSelectedDocumentInfo({
                        documentId: d.documentId,
                        documentVersionId: d.documentVersionId,
                      });
                    }}
                  >
                    <FancyDateTime
                      moment={moment}
                      resources={resources}
                      size="100"
                      value={d.date}
                      color={disableColors}
                    />
                  </TapArea>
                </Box>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const DocumentSearchPopover = ({
  children,
  setValue,
  isDocumentSearchOpen,
  setDocumentSearchOpen,
  updateContext,
  documentResponse,
  setSelectedDocumentInfo,
  loading,
  setDocumentTypeIdSelected,
}: PropsDocumentSearchPopover) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const resetDocumentSelection = useCallback(() => {
    setValue("");
    setDocumentSearchOpen(false);
    setSelectedDocumentInfo({ documentId: "", documentVersionId: "" });
    setDocumentTypeIdSelected(resources.all);
  }, [
    setValue,
    setDocumentSearchOpen,
    setSelectedDocumentInfo,
    setDocumentTypeIdSelected,
    resources.all,
  ]);
  const isButtonDisabled = loading || documentResponse?.document === undefined;

  return (
    <>
      {isDocumentSearchOpen && (
        <Layer zIndex={modalZIndex}>
          <Modal
            accessibilityModalLabel={resources.delete_}
            heading={
              <Box top right width={"100%"} padding={6} position="absolute">
                <Text size="400" color={colors.secondary}>
                  {resources.selectFromDocument}
                </Text>
              </Box>
            }
            size="md"
            onDismiss={resetDocumentSelection}
            footer={
              <Box width={"100%"} display="flex" gap={2} justifyContent="end">
                <Button
                  color="semiTransparentWhite"
                  text={resources.cancel}
                  onClick={resetDocumentSelection}
                />
                <Button
                  text={resources.replace}
                  onClick={updateContext}
                  disabled={isButtonDisabled}
                />
              </Box>
            }
          >
            <Box height={700}>{children}</Box>
          </Modal>
        </Layer>
      )}
    </>
  );
};

export const LookupDocumentButton = ({ onchange }: { onchange: (n: boolean) => void }) => {
  const [show, setShow] = useState(false);
  const [colorHovers, setColors] = useState(false);
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  return (
    <Box position="relative">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        direction="column"
        position="absolute"
        zIndex={popupZIndex}
        right={true}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <TapArea
            onTap={() => {
              setShow(!show);
              setColors(!colorHovers);
            }}
          >
            <Text title={resources.open}>
              <VerticalEllipseMenuSvg color={colorHovers ? colors.primary : undefined} />
            </Text>
          </TapArea>
        </Box>

        {show && (
          <Box
            color={colors.white}
            display="flex"
            direction="column"
            alignItems="center"
            justifyContent="center"
            hoverColor={colors.neutral700}
            borderRadius={4}
          >
            <TapArea
              onTap={() => {
                setShow(false);
                onchange(true);
                setColors(!colorHovers);
              }}
            >
              <Tooltip text={resources.copyFromDocument}>
                <SearchSvgIcon color={colors.white} width={32} height={32} />
              </Tooltip>
            </TapArea>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const DocumentTypeFilterDropdown = ({
  documentTypeIdSelected,
  onChange,
  items,
  currentPage,
  isNextButtonDisabled,
  isPreviousButtonDisabled,
  nextPage,
  previousPage,
  rowsPerPage,
  pageLength,
  setPageLength,
  documentTypeData,
  handleChange,
  filterTextFieldValue,
  isLoadingDocumentTypeData,
  setDocumentTypeSelectorOpen,
  openDocumentTypeSelector,
}: {
  documentTypeIdSelected: string | undefined;
  onChange(value: string | undefined): void;
  items: Array<{
    value: string;
    label: string;
  }>;
  totalRowCount: number | undefined;
  setPageLength: (pageLength: "15" | "30" | "100") => void;
  previousPage: () => void;
  nextPage: () => void;
  isPreviousButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  currentPage: number;
  pageLength: number;
  rowsPerPage: "15" | "30" | "100";
  setFilterValue: (n: DocumentTypeSettingsFilter) => void;
  filterValue: DocumentTypeSettingsFilter;
  documentTypeData: ListableDocumentType[];
  handleChange: (newFilter: DocumentTypeSettingsFilter) => void;
  filterTextFieldValue: DocumentTypeSettingsFilter;
  isLoadingDocumentTypeData: boolean;
  openDocumentTypeSelector: boolean;
  setDocumentTypeSelectorOpen: (n: boolean) => void;
}) => {
  const { colors } = useColors();
  const anchorRef = useRef(null);
  const textBoxRef = useRef<HTMLInputElement | null>(null);
  useAutoFocus(textBoxRef, true);
  const { resources } = useAppTranslation();
  const selected: { value: string; label: string } | undefined = useMemo(() => {
    if (documentTypeIdSelected === undefined) {
      return undefined;
    }

    const found = (items || []).find((item) => item.value === documentTypeIdSelected);

    if (!found) {
      return undefined;
    }

    return {
      value: found.value,
      label: found.label,
    };
  }, [items, documentTypeIdSelected]);

  const selectedAll = selected && selected.label === resources.all;
  return (
    <Box display="flex" flex="grow" direction="column">
      <TapArea onTap={() => setDocumentTypeSelectorOpen(!openDocumentTypeSelector)}>
        <BoxWithRef
          display="flex"
          flex="grow"
          alignItems="center"
          direction="column"
          hoverColor={colors.neutral400}
          borderRadius={4}
          color={selectedAll ? colors.neutral200 : colors.neutral400}
          ref={anchorRef}
        >
          <Box
            display="flex"
            flex="shrink"
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Box paddingY={2} paddingX={6}>
              <Text weight="bold" overflow="ellipsis">
                {selected ? selected.label : resources.all}
              </Text>
            </Box>
          </Box>
        </BoxWithRef>
      </TapArea>

      {openDocumentTypeSelector && (
        <Box>
          <Box padding={4} display="flex" direction="column" flex="grow" paddingY={6} gap={2}>
            <Text weight="bold">{resources.documentTypes.documentTypes}</Text>
            <Box
              borderStyle="sm"
              borderRadius={12}
              display="flex"
              direction="column"
              flex="shrink"
              gap={2}
            >
              <Box display="flex" flex="grow" alignItems="center">
                <Box width={"100%"}>
                  <TextField
                    id={"search_look_up_document_type"}
                    value={filterTextFieldValue.documentTypeName}
                    onChange={(e) => {
                      const value: DocumentTypeSettingsFilter = {
                        documentGroupId: "",
                        documentTypeName: e.value,
                      };

                      handleChange(value);
                    }}
                    placeholder={resources.searchForDocumentType}
                    mode={"unstyled"}
                    autoComplete={"off"}
                    ref={textBoxRef}
                  />
                </Box>

                <Box paddingX={4}>
                  <TapArea
                    onTap={() => {
                      if (textBoxRef.current) {
                        textBoxRef.current.focus();
                      }
                    }}
                  >
                    <Icon icon="search" accessibilityLabel={resources.search} />
                  </TapArea>
                </Box>
              </Box>
            </Box>
            <Box display="flex" direction="column" flex="shrink" width={"100%"} alignItems="end">
              <Box display="flex" direction="row" flex="shrink" alignItems="center" gap={2}>
                <Pagination<typeof rowsPerPage>
                  id="document_type_list_pagination_top"
                  rowsLabel={`${resources.documentTypes.documentTypes}:`}
                  currentPage={currentPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  pageLength={pageLength}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setPageLength}
                  isNextButtonDisabled={isNextButtonDisabled}
                  isPreviousButtonDisabled={isPreviousButtonDisabled}
                  pageLengthOptions={PAGINATION_OPTIONS}
                />
              </Box>
            </Box>
            {!isLoadingDocumentTypeData ? (
              <Box borderStyle="lg" borderRadius={6}>
                {documentTypeData.map((item) => (
                  <Box key={item.name} marginTop={1}>
                    <TapArea>
                      <Tooltip text={item.name} zIndex={overlayPanelZIndex} idealDirection="right">
                        <Box
                          hoverColor={colors.neutral600}
                          padding={2}
                          alignItems="center"
                          onClickCapture={() => {
                            onChange(
                              item.documentTypeVersionId
                                ? item.documentTypeVersionId
                                : item.documentTypeId
                            );
                            setDocumentTypeSelectorOpen(false);
                          }}
                        >
                          <Text overflow="ellipsis">{item.name}</Text>
                        </Box>
                      </Tooltip>
                    </TapArea>
                    <Divider />
                  </Box>
                ))}
              </Box>
            ) : (
              <Spinner show={true} />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
