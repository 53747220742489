"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExpressionControl = void 0;
var design_system_1 = require("@prodoctivity/design-system");
var shared_1 = require("@prodoctivity/shared");
var react_1 = require("react");
var utils_1 = require("../utils");
var useExpressionControl = function (_a) {
    /* ---------------------------- #Common Hooks area---------------------------- */
    var moment = _a.moment, contextDefinition = _a.contextDefinition, resources = _a.resources, 
    // contextualIf,
    helpMode = _a.helpMode, name = _a.name, i18n = _a.translate, onExpressionChanged = _a.onExpressionChanged;
    var HELP_MODE = "expression-control-help-mode";
    var colors = (0, design_system_1.useColors)().colors;
    var fields = (0, react_1.useMemo)(function () {
        if (!contextDefinition)
            return [];
        var fieldUI = contextDefinition.fields.map(function (field) {
            var mappedField = (0, utils_1.mapFieldToTemplateFieldRecordUI)(field, false);
            return mappedField;
        });
        var recordUI = contextDefinition.records.map(function (record) {
            var mappedRecord = (0, utils_1.mapRecordToTemplateFieldRecordUI)(record, false);
            return mappedRecord;
        });
        var mergedTemplateFieldRecordUI = (0, utils_1.sortFields)(fieldUI.concat(recordUI));
        return mergedTemplateFieldRecordUI;
    }, [contextDefinition]);
    var setInitialStateFromProps = (0, react_1.useCallback)(function () {
        var initialState = {
            id: (0, shared_1.createUuid)(),
            helpMode: typeof helpMode !== "undefined"
                ? helpMode
                : typeof localStorage !== "undefined" && localStorage.getItem(HELP_MODE) === "true"
                    ? true
                    : false,
            name: name,
            readMode: false,
        };
        return initialState;
    }, [helpMode, name]);
    var _b = (0, react_1.useState)(setInitialStateFromProps), state = _b[0], setState = _b[1];
    var toggleReadMode = (0, react_1.useCallback)(function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { readMode: !prevState.readMode })); });
    }, []);
    var toggleHelpMode = (0, react_1.useCallback)(function () {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(HELP_MODE, (!state.helpMode).toString());
        }
        setState(__assign(__assign({}, state), { helpMode: !state.helpMode }));
    }, [state]);
    var localOnExpressionChanged = (0, react_1.useCallback)(function (expression) {
        // const isNegative = contextualIf.value();
        // if (isNegative) {
        //   expression.isNegative = !expression.isNegative;
        // }
        //fix
        onExpressionChanged("", expression);
    }, [onExpressionChanged]);
    // Probably need to implement this
    // const nameChanged = useCallback((e: any) => {
    //   const value = e;
    //   setState((prevState) => ({
    //     ...prevState,
    //     name: value,
    //   }));
    // }, []);
    return {
        HELP_MODE: HELP_MODE,
        colors: colors,
        i18n: i18n,
        state: state,
        toggleReadMode: toggleReadMode,
        toggleHelpMode: toggleHelpMode,
        localOnExpressionChanged: localOnExpressionChanged,
        moment: moment,
        resources: resources,
        onExpressionChanged: onExpressionChanged,
        fields: fields,
    };
};
exports.useExpressionControl = useExpressionControl;
