import { FunctionComponent } from "react";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { useCallback } from "react";
import { organizationLinkTemplates } from "../../../../link-templates";
import { SequencesConfiguration } from "./SequencesConfiguration";

type Props = {
  isUpdating: boolean;
};

const SequencesConfigurationPage: FunctionComponent<Props> = ({ isUpdating }) => {
  const organizationNavigate = useOrganizationNavigate();

  const onCancel = useCallback(() => {
    organizationNavigate(organizationLinkTemplates.dataSequences());
  }, [organizationNavigate]);

  return <SequencesConfiguration isUpdating={isUpdating} onCancel={onCancel} />;
};

export default SequencesConfigurationPage;
