import { Box, Text, ColorConfig } from "@prodoctivity/design-system";
import { formatNumber } from "@prodoctivity/shared";
import { FunctionComponent } from "react";

type LineCardData = { label: string; value: string | number; color: ColorConfig | undefined };

export const ItemLine: FunctionComponent<LineCardData> = ({ label, value, color }) => {
  return (
    <Box
      display="flex"
      direction="row"
      marginBottom={2}
      justifyContent="between"
      alignItems="center"
      width={"100%"}
    >
      <Box display="flex" direction="row" gap={2} alignItems="center">
        <Box
          color={color}
          dangerouslySetInlineStyle={{
            __style: {
              borderRadius: "50%",
              height: "9px",
              width: "9px",
            },
          }}
        ></Box>
        <Text>{label} </Text>
      </Box>
      <Box>{typeof value === "number" ? formatNumber(value) : value}</Box>
    </Box>
  );
};
