import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const ViewerAdjustHeightIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6667 10.8L26.3333 9.4L25 8L32 8.06667V11.4C32 14.7333 32 14.7333 30.5333 13.7333C29.0667 12.8 28.8667 12.8667 25.8667 15.8667C23.3333 18.4667 22.5333 18.8667 21.8667 18.2C21.2 17.5333 21.6667 16.7333 27.6667 10.8Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
      <path
        d="M10.7333 27.7333L13.8 24.6C16.3333 22.0667 17.0667 21.6 17.8 22.2667C18.6 22.9333 18.2 23.6 12.3333 29.4L15 32.1333L8 32.0667V25.0667L10.7333 27.7333Z"
        fill={color ? calculateColor(color) : "#91979E"}
      />
    </svg>
  );
};
