import { createContext } from "react";

type FocusFieldValue = {
  focusFieldPath: string;
  setFocusFieldPath: (path: string) => void;
};

export const FocusFieldContext = createContext<FocusFieldValue>({
  focusFieldPath: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFocusFieldPath: () => {},
});
