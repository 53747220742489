import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const MyTasksSvg: FunctionComponent<Props> = ({ color }) => {
  const { colors } = useColors();
  return (
    <svg width="35" height="35" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.58517 6.17619C9.83805 6.42907 9.86615 6.85054 9.61327 7.13151L7.59024 9.37932C7.47785 9.49171 7.30927 9.576 7.11259 9.576C6.944 9.6041 6.74732 9.5198 6.63493 9.37932L5.51102 8.25541C5.23005 8.00254 5.23005 7.58107 5.51102 7.32819C5.7639 7.04722 6.18537 7.04722 6.43824 7.32819L7.08449 7.94634L8.62985 6.20429C8.88273 5.95141 9.30419 5.92332 9.58517 6.17619ZM9.58517 10.6718C9.83805 10.8966 9.86615 11.3461 9.61327 11.6271L7.59024 13.8749C7.47785 13.9873 7.30927 14.0716 7.11259 14.0716C6.944 14.0997 6.74732 14.0154 6.63493 13.8749L5.51102 12.751C5.23005 12.4981 5.23005 12.0767 5.51102 11.7957C5.7639 11.5428 6.18537 11.5428 6.43824 11.7957L7.08449 12.4419L8.62985 10.6999C8.88273 10.447 9.30419 10.4189 9.58517 10.6718ZM11.6082 7.77775C11.6082 7.3001 12.0016 6.87863 12.5073 6.87863H18.8012C19.2788 6.87863 19.7003 7.3001 19.7003 7.77775C19.7003 8.28351 19.2788 8.67688 18.8012 8.67688H12.5073C12.0016 8.67688 11.6082 8.28351 11.6082 7.77775ZM11.6082 12.2734C11.6082 11.7957 12.0016 11.3742 12.5073 11.3742H18.8012C19.2788 11.3742 19.7003 11.7957 19.7003 12.2734C19.7003 12.7791 19.2788 13.1725 18.8012 13.1725H12.5073C12.0016 13.1725 11.6082 12.7791 11.6082 12.2734ZM9.80995 16.769C9.80995 16.2913 10.2033 15.8699 10.7091 15.8699H18.8012C19.2788 15.8699 19.7003 16.2913 19.7003 16.769C19.7003 17.2747 19.2788 17.6681 18.8012 17.6681H10.7091C10.2033 17.6681 9.80995 17.2747 9.80995 16.769ZM5.31434 16.769C5.31434 16.0384 5.90439 15.4203 6.66302 15.4203C7.39356 15.4203 8.01171 16.0384 8.01171 16.769C8.01171 17.5276 7.39356 18.1177 6.66302 18.1177C5.90439 18.1177 5.31434 17.5276 5.31434 16.769Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
    </svg>
  );
};
