import {
  Box,
  Spinner,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import type {
  DocumentCollectionInstance,
  IndexEntityResume,
  ListableTemplateWithCombined,
} from "@prodoctivity/types";
import { Dispatch, MutableRefObject, SetStateAction, useMemo } from "react";
import { FieldCondition, FilterOption, OpenSearchOption, useOpenSearch } from "./hooks";

import type { DocumentCollectionCount } from "@prodoctivity/shared/src/index-types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";
import { DocIconsSvg } from "../../svg/DocumentIconsSvg";
import { DocumentIconsSvg } from "../../svg/DocumentTypeIconsSvg";
import { TemplateIconSvg } from "../../svg/TemplateIconSvg";
import { XIconSvg } from "../../svg/XIconSvg";
import { DocFormOpenSearch } from "./DocFormOpenSearch";
import type { GetRecentSearchListResponse } from "@prodoctivity/types/model/recent-searches";
import { RecentSeachSuggestion } from "./RecentSearchSuggestion";
import { useBuildSearchurl } from "../../pages/RecentSearches/useBuildSearchUrl";

type Props = {
  documentTypesVersionIdList: FilterOption[];
  isDocumentSearchOpen: boolean;
  isSuggestionsOpen: boolean;
  closePopup: () => void;
  idSelected: OpenSearchOption["id"];
  searchResults: Array<IndexEntityResume & { $score: number }>;
  isLoadingSearch: boolean;
  buildSearchUrl: () => void;
  documentTypesSelected: FilterOption[];
  setDocumentTypesSelected: (value: FilterOption[]) => void;
  newSetFilters: (value: FieldCondition[]) => void;
  filterByFields: FieldCondition[];
  handlerEnterKeyPress: ({ event: { code } }: { event: { code: string } }) => void;
  isLoadingDocTypes: boolean;
  queryValue: string;
  isMobile?: boolean;
  setSearchClicked?: Dispatch<SetStateAction<boolean>>;
  searchBarNodeRef: MutableRefObject<HTMLDivElement | null>;
  documentCollectionList: ({
    id: string;
    counts: DocumentCollectionCount | undefined;
  } & DocumentCollectionInstance<string>)[];
  isLoadingDocumentCollections: boolean;
  templateList: ListableTemplateWithCombined[];
  isLoadingTemplates: boolean;
  isLoading: boolean;
  recentSearchList: GetRecentSearchListResponse[];
  isRecentSearchListEmpty: { document: boolean; collection: boolean; template: boolean };
};

export const ViewsToSelect = ({
  isDocumentSearchOpen,
  isSuggestionsOpen,
  documentTypesVersionIdList,
  closePopup,
  idSelected,
  searchResults,
  isLoadingSearch,
  buildSearchUrl,
  documentTypesSelected,
  setDocumentTypesSelected,
  newSetFilters,
  filterByFields,
  handlerEnterKeyPress,
  isLoadingDocTypes,
  queryValue,
  isMobile,
  setSearchClicked,
  searchBarNodeRef,
  documentCollectionList,
  isLoadingDocumentCollections,
  templateList,
  isLoadingTemplates,
  isLoading,
  recentSearchList,
  isRecentSearchListEmpty,
}: Props) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const { savingRecentSearches } = useOpenSearch();
  const { buildRecentSearchUrl } = useBuildSearchurl();
  const limitSuggestionsPanelHeight =
    idSelected === "document" ? searchResults.length > 5 : documentCollectionList.length > 5;

  const optionsIcons = useMemo(() => {
    const result: OpenSearchOption[] = [
      // {
      //   id: "all",
      //   title: resources.all,
      //   icon: (
      //     <Box>
      //       <AllSvgIcon />
      //     </Box>
      //   ),
      // },
      {
        id: "document",
        title: resources.documents,
        icon: (
          <Box>
            <DocIconsSvg />
          </Box>
        ),
      },
      // {
      //   id: "action-log",
      //   title: resources.activities.activities,
      //   icon: (
      //     <Box>
      //       <ActivitiesIconSvg />
      //     </Box>
      //   ),
      // },
      {
        id: "collection",
        title: resources.collections,
        icon: (
          <Box>
            <DocumentIconsSvg />
          </Box>
        ),
      },
      {
        id: "template",
        title: resources.templates,
        icon: (
          <Box>
            <TemplateIconSvg color={colors.primary} />
          </Box>
        ),
      },
      // {
      //   id: "task",
      //   title: resources.task,
      //   icon: (
      //     <Box>
      //       <TaskIconSvg color={colors.primaryHover0} />
      //     </Box>
      //   ),
      // },
      // {
      //   id: "annotation",
      //   title: resources.annotations,
      //   icon: (
      //     <Box>
      //       <AnnotationsIconSvg color={colors.primaryHover0} />
      //     </Box>
      //   ),
      // },
      // {
      //   id: "Settings",
      //   title: resources.settings,
      //   icon: (
      //     <Box>
      //       <SettingsSvg width={40} height={40} color={colors.primaryHover0} />
      //     </Box>
      //   ),
      // },
    ];
    return result;
  }, [resources, colors]);

  const { breakpoint } = useDesignBreakpoint();
  const isTablet = breakpoint === "large";

  return (
    <Box
      borderRadius={6}
      width={isTablet ? "fit-content" : !isMobile ? searchBarNodeRef.current?.offsetWidth : "100%"}
      maxWidth={600}
      marginTop={isMobile ? 2 : 1}
      position="fixed"
      color={colors.white}
    >
      {isDocumentSearchOpen && (
        <DocFormOpenSearch
          documentTypesVersionIdList={documentTypesVersionIdList}
          closePopup={closePopup}
          setSearchClicked={setSearchClicked}
          buildSearchUrl={buildSearchUrl}
          documentTypesSelected={documentTypesSelected}
          setDocumentTypesSelected={setDocumentTypesSelected}
          newSetFilters={newSetFilters}
          filterByFields={filterByFields}
          handlerEnterKeyPress={handlerEnterKeyPress}
          isLoadingDocTypes={isLoadingDocTypes}
          isSuggestionsOpen={isSuggestionsOpen}
        />
      )}
      {isSuggestionsOpen && recentSearchList.length !== 0 ? (
        <RecentSeachSuggestion
          isSuggestionsOpen={isSuggestionsOpen}
          closePopup={closePopup}
          setSearchClicked={setSearchClicked}
          idSelected={idSelected}
          recentSearchList={recentSearchList}
          isLoading={isLoading}
          buildRecentSearchUrl={buildRecentSearchUrl}
          isRecentSearchListEmpty={isRecentSearchListEmpty}
        />
      ) : idSelected === "document" ? (
        isLoadingSearch ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Spinner accessibilityLabel={"Loading"} show={true} />
          </Box>
        ) : (
          <Box
            direction="column"
            maxHeight={limitSuggestionsPanelHeight ? 340 : undefined}
            overflow={limitSuggestionsPanelHeight ? "scrollY" : undefined}
          >
            <>
              {searchResults.length === 0 ? (
                <Box padding={4}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    {resources.noResultsFound}
                  </Box>
                </Box>
              ) : (
                searchResults.map((item) => {
                  const obj = {
                    link: "/",
                  };

                  obj.link = organizationLinkTemplates.documentId(item.$documentId, "data");

                  return queryValue ? (
                    <Box
                      key={getItemId(item)}
                      padding={3}
                      width="100%"
                      height="100%"
                      hoverColor={colors.primaryHover0}
                      display="flex"
                      alignItems="center"
                    >
                      <TapArea
                        onTap={() => {
                          savingRecentSearches({
                            category: idSelected,
                            searchQuery: queryValue,
                            searchType: "suggestion",
                            documentId: item.$documentId,
                          });
                          organizationNavigate(obj.link);
                          closePopup();
                          if (setSearchClicked) {
                            setSearchClicked(false);
                          }
                        }}
                      >
                        <Box display="flex" gap={3} alignItems="center" justifyContent="start">
                          {optionsIcons.find((element) => element.id === "document")?.icon}
                          <Text color={colors.black900}>{item.$name}</Text>
                        </Box>
                      </TapArea>
                    </Box>
                  ) : null;
                })
              )}
            </>
          </Box>
        )
      ) : idSelected === "template" ? (
        isLoadingTemplates ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Spinner accessibilityLabel={"Loading"} show={true} />
          </Box>
        ) : (
          <Box
            direction="column"
            maxHeight={limitSuggestionsPanelHeight ? 340 : undefined}
            overflow={limitSuggestionsPanelHeight ? "scrollY" : undefined}
          >
            {templateList.length === 0 ? (
              <Box padding={4}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  {resources.noResultsFound}
                </Box>
              </Box>
            ) : (
              templateList
                .reduce((acc: ListableTemplateWithCombined[], next) => {
                  if (next.type === "template" || next.type === "combined-template") {
                    acc.push(next);
                  }

                  return acc;
                }, [])
                .map((item) => {
                  const obj = {
                    link: "/",
                  };

                  obj.link =
                    item.type === "template"
                      ? organizationLinkTemplates.templateVersionDetailPage(item.templateVersionId)
                      : organizationLinkTemplates.combinedTemplateDetails(item.id);

                  return queryValue ? (
                    <Box
                      key={item.type === "template" ? item.templateVersionId : item.id}
                      padding={3}
                      width="100%"
                      height="100%"
                      hoverColor={colors.primaryHover0}
                      display="flex"
                      alignItems="center"
                    >
                      <TapArea
                        onTap={() => {
                          savingRecentSearches({
                            category: idSelected,
                            searchQuery: queryValue,
                            searchType: "suggestion",
                            documentId: item.type === "template" ? item.templateVersionId : item.id,
                          });
                          organizationNavigate(obj.link);
                          closePopup();
                          if (setSearchClicked) {
                            setSearchClicked(false);
                          }
                        }}
                      >
                        <Box display="flex" gap={3} alignItems="center" justifyContent="start">
                          {optionsIcons.find((element) => element.id === "template")?.icon}
                          <Text color={colors.black900}>{item.name}</Text>
                        </Box>
                      </TapArea>
                    </Box>
                  ) : null;
                })
            )}
          </Box>
        )
      ) : isLoadingDocumentCollections ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Spinner accessibilityLabel={"Loading"} show={true} />
        </Box>
      ) : (
        <Box
          direction="column"
          maxHeight={limitSuggestionsPanelHeight ? 340 : undefined}
          overflow={limitSuggestionsPanelHeight ? "scrollY" : undefined}
        >
          <>
            {documentCollectionList.length === 0 ? (
              <Box padding={4}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  {resources.noResultsFound}
                </Box>
              </Box>
            ) : (
              documentCollectionList.map((item) => {
                const obj = {
                  link: "/",
                };

                obj.link = organizationLinkTemplates.documentCollection(item.id);

                return queryValue ? (
                  <Box
                    key={item.id}
                    padding={3}
                    width="100%"
                    hoverColor={colors.primaryHover0}
                    display="flex"
                    alignItems="center"
                  >
                    <TapArea
                      onTap={() => {
                        if (idSelected === "collection") {
                          savingRecentSearches({
                            category: idSelected,
                            searchQuery: queryValue,
                            searchType: "suggestion",
                            documentId: item.id,
                          });
                        }
                        organizationNavigate(obj.link);
                        closePopup();
                        if (setSearchClicked) {
                          setSearchClicked(false);
                        }
                      }}
                    >
                      <Box display="flex" gap={3} alignItems="center" justifyContent="start">
                        {optionsIcons.find((element) => element.id === "collection")?.icon}
                        <Text color={colors.black900}>{item.name}</Text>
                      </Box>
                    </TapArea>
                  </Box>
                ) : null;
              })
            )}
          </>
        </Box>
      )}

      {isMobile && (
        <Box
          position="absolute"
          display="flex"
          top
          right
          width={40}
          height={40}
          justifyContent="center"
          alignItems="center"
          onClickCapture={() => {
            closePopup();
            if (setSearchClicked) {
              setSearchClicked(false);
            }
          }}
        >
          <XIconSvg height={13} width={13} />
        </Box>
      )}
    </Box>
  );
};

function getItemId(item: IndexEntityResume & { $score: number }): string {
  return `doc_${item.$documentVersionId}`;
}
