import {
  Box,
  Button,
  Divider,
  Spinner,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import {
  checkPasswordStrength,
  createUuid,
  validateEmail,
  validatePhoneNumber,
} from "@prodoctivity/shared";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { GoogleProfileObj, PasswordStrength, SignUpFieldsGroup } from "../../utils";

import { useNavigate } from "react-router-dom";
import { GoogleButton } from "../../components/Button/GoogleButton";
import { LoginLayout } from "../../components/Layout/LoginLayout";
import { AccountSignUp } from "../../components/SignUp/AccountSignUp";
import { CompanySignUp } from "../../components/SignUp/CompanySignUp";
import { SuccessSignUp } from "../../components/SignUp/SuccessSignUp";
import { SignUpWizardStep } from "../../components/SignUp/WizardStep";
import { useAppDocumentTitle } from "../../hooks/useAppDocumentTitle";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { useSettings } from "../../hooks/useSettings";

type childProps = {
  updateInput(input: SignUpFieldsGroup): void;
  currentInput: SignUpFieldsGroup;
  emailValidation: boolean;
  phoneValidation: boolean;
};
export interface SignUpWizardSteps {
  key: number;
  label: string;
  completed: boolean;
  component: React.FC<childProps>;
}

const SignUpPage: FC = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const { organizationExists } = useServices();

  const doesOrganizationExists = useCallback(
    async (orgId: string) => {
      if (orgId) {
        const res = await organizationExists(orgId);
        return res.exists;
      } else return false;
    },
    [organizationExists]
  );
  const responsive = useMemo(() => {
    return {
      isMobile: breakpoint === "small" || breakpoint === "medium",
      isFullScreen: breakpoint === "large" || breakpoint === "hd",
    };
  }, [breakpoint]);

  useAppDocumentTitle(resources.signUp);
  const { googleClientId } = useSettings();

  const steps = useMemo(() => {
    return [
      { key: 1, label: resources.account, completed: false, component: AccountSignUp },
      { key: 2, label: resources.company, completed: false, component: CompanySignUp },
      { key: 3, label: resources.completed, completed: true, component: SuccessSignUp },
    ];
  }, [resources]);

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(steps[0]);
  const ActiveStepComponent = activeStep.component;
  const [googleProfile, setGoogleProfile] = useState<GoogleProfileObj | undefined | null>(null);
  const [input, setInput] = useState<SignUpFieldsGroup>({
    account: {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phone: "",
      isEnrolledForMarketing: true,
    },
    company: {
      organizationId: "",
      organizationName: "",
      termsAndPolicyCheck: false,
    },
  });

  const [validEmail, setEmailValidation] = useState<boolean>(false);
  const [validPhone, setPhoneValidation] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength | undefined>(undefined);
  useEffect(() => {
    if (activeStep.key === 1) {
      let emailCheck = false;
      let phoneCheck = false;
      let passwordCheck = undefined;

      const emailComparison: boolean = input.account.email !== "";
      if (emailComparison) emailCheck = validateEmail(input.account.email);
      setEmailValidation(emailCheck);

      const phoneComparison: boolean = input.account.phone !== "";
      if (phoneComparison) phoneCheck = validatePhoneNumber(input.account.phone);
      setPhoneValidation(phoneCheck);

      const comparison: boolean = input.account.password !== "";
      if (comparison) passwordCheck = checkPasswordStrength(input.account.password);
      setPasswordStrength(passwordCheck);

      const stringValues = Object.values(input.account);
      stringValues.length = stringValues.length - 2;
      const completed = stringValues.every((x) => x !== "");

      setActiveStep((prevState) => {
        return {
          ...prevState,
          completed: completed && emailCheck && passwordStrength?.success === true,
        };
      });
    }
  }, [activeStep.key, input.account, passwordStrength?.success]);
  useEffect(() => {
    if (activeStep.key === 2) {
      const { organizationName, organizationId, termsAndPolicyCheck } = input.company;
      doesOrganizationExists(organizationId).then((exists) => {
        const valid =
          !exists &&
          typeof organizationName === "string" &&
          typeof organizationId === "string" &&
          organizationName.length > 3 &&
          organizationId.length > 3 &&
          termsAndPolicyCheck;
        setActiveStep((prevState) => {
          return {
            ...prevState,
            completed: valid,
          };
        });
      });
    }
  }, [activeStep.key, doesOrganizationExists, input.company]);

  useEffect(() => {
    if (googleProfile) {
      const googlePassword = createUuid();
      setInput((prevState) => {
        return {
          ...prevState,
          account: {
            ...prevState.account,
            firstName: googleProfile?.given_name,
            lastName: googleProfile?.family_name,
            email: googleProfile?.email || "",
            password: googlePassword,
          },
        };
      });
      if (activeStep.key === 1) setActiveStep(steps[1]);
    }
  }, [activeStep.key, googleProfile, steps]);

  const handleSteps = useCallback(() => {
    if (steps[steps.length - 1].key === activeStep.key) navigate("/login");

    const index = steps.findIndex((x) => x.key === activeStep.key);
    setActiveStep(steps[index + 1]);
  }, [activeStep.key, navigate, steps]);

  const googleButtonComponent = useMemo(() => {
    if (googleClientId) {
      return (
        <GoogleButton
          type="signup_with"
          width={responsive.isMobile ? 250 : 400}
          setGoogleProfile={setGoogleProfile}
          googleClientId={googleClientId}
        />
      );
    } else {
      return <Spinner size="md" color="default" show={true} />;
    }
  }, [googleClientId, setGoogleProfile, responsive.isMobile]);

  return (
    <>
      <LoginLayout removeBorder={responsive.isFullScreen ? false : true}>
        <Box direction="column" paddingX={8} paddingY={6} rounding="pill">
          <Box marginBottom={responsive.isFullScreen ? 6 : 12}>
            <Text size="400" weight="bold" align="center">
              {activeStep.key !== 3 ? resources.signUp : resources.confirmation}
            </Text>
          </Box>

          <Box display="flex" justifyContent="center" direction="column" gap={4}>
            <Box marginBottom={8} direction="row" display="flex" justifyContent="center">
              {steps.map((step: SignUpWizardSteps, index: number) => {
                const isComplete = index < activeStep.key - 1;
                return (
                  <Box display="flex" justifyContent="center" key={step.key}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <SignUpWizardStep
                        step={step.key}
                        label={step.label}
                        active={step.key === activeStep.key ? true : false}
                        isComplete={isComplete}
                        stepChange={
                          isComplete && activeStep.key !== 3
                            ? () => {
                                setInput({
                                  ...input,
                                  account: {
                                    ...input.account,
                                    password: "",
                                  },
                                });
                                setGoogleProfile(undefined);
                                setActiveStep(step);
                              }
                            : undefined
                        }
                      />
                    </Box>

                    {step.key !== 3 && (
                      <Box
                        marginEnd={responsive.isMobile ? undefined : 4}
                        width={responsive.isMobile ? 30 : 55}
                        paddingY={8}
                        paddingX={responsive.isMobile ? undefined : 2}
                      >
                        <Box marginEnd={responsive.isMobile ? 2 : undefined}>
                          <Divider />
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
            <Box marginBottom={8}>
              {responsive.isFullScreen && <Box height={1} color={colors.neutral700} />}
            </Box>
            <Box marginBottom={4}>
              <ActiveStepComponent
                currentInput={input}
                updateInput={setInput}
                emailValidation={validEmail}
                phoneValidation={validPhone}
              />
            </Box>
            <Box marginBottom={4} marginTop={3}>
              <Button
                size="lg"
                fullWidth={true}
                text={activeStep.key !== 3 ? resources.continue : resources.getStarted}
                disabled={!activeStep.completed}
                accessibilityLabel={
                  activeStep.key !== 3 ? resources.continue : resources.getStarted
                }
                onClick={handleSteps}
              />
            </Box>

            {activeStep.key === 1 && (
              <Box display="flex" direction="column" alignItems="center">
                <Box>
                  <Text align="center" color={colors.neutral900}>
                    {resources.or}
                  </Text>
                </Box>

                <Box display="flex" alignItems="center">
                  {googleButtonComponent}
                </Box>
              </Box>
            )}

            {activeStep.key !== 3 && (
              <Box display="flex" direction="row" padding={0} justifyContent="center">
                <Box justifyContent="center" alignItems="center" direction="column" gap={4}>
                  <Box>
                    <Text>{resources.haveAccount}</Text>
                  </Box>
                  <Box>
                    <TapArea onTap={() => navigate("/login")}>
                      <Text color={colors.primary} align="center" weight="bold">
                        {resources.signIn}
                      </Text>
                    </TapArea>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </LoginLayout>
    </>
  );
};

export default SignUpPage;
