import { DocumentSearchFilter } from "../../link-templates";
import { FunctionComponent } from "react";
import { ResultOfFilteredFields } from "./ResultOfFilteredFields";
import { useLocation } from "react-router-dom";
import { useResultOfFilteredFields } from "./hooks";
import { z } from "zod";

const searchPageFilter$Schema = z
  .object({
    documentTypesSelected: z.undefined().or(z.string().array()),
    fields: z.array(
      z.object({
        fld: z.string().min(1),
        val: z.string().min(1),
        op: z.string().min(1),
        dataType: z
          .enum([
            "Alphanumeric",
            "Numeric",
            "Currency",
            "Logical",
            "Date",
            "DateTime",
            "Time",
            "Image",
          ])
          .optional()
          .default("Alphanumeric")
          .catch("Alphanumeric"),
      })
    ),
    query: z.undefined().or(z.string()),
    searchCriteria: z.undefined().or(z.string()),
    docTypesSelected: z.undefined().or(z.string()),
    pageNumber: z.undefined().or(z.string()),
    rowsPerPage: z.undefined().or(z.string()),
  })
  .strip();

function urlSearchParamsToFilter(urlParams: DocumentSearchFilter): DocumentSearchFilter {
  const filter = searchPageFilter$Schema.safeParse(urlParams);

  if (!filter.success) {
    return {
      documentTypesSelected: [],
      fields: [],
      query: "",
      searchCriteria: "0",
      docTypesSelected: "0",
      pageNumber: "0",
      rowsPerPage: "15",
    };
  }

  const result: DocumentSearchFilter = {
    documentTypesSelected: filter.data.documentTypesSelected
      ? filter.data.documentTypesSelected
      : [],
    fields: filter.data.fields || [],
    query: filter.data.query || "",
    searchCriteria: filter.data.searchCriteria || "0",
    docTypesSelected: filter.data.docTypesSelected || "0",
    pageNumber: filter.data.pageNumber || "0",
    rowsPerPage: filter.data.rowsPerPage || "15",
  };

  return result;
}

export const SearchPage: FunctionComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  // const paramsObject = Object.fromEntries(params.entries());
  // console.log(`"Inner objects" ""`, paramsObject);
  const { urlParamsExtracted } = useResultOfFilteredFields(params);
  const filter = urlSearchParamsToFilter(urlParamsExtracted);
  return <ResultOfFilteredFields params={params} filter={filter} />;
};
