import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DocumentToExpireSvgIcon: FunctionComponent<Props> = ({
  width = 34,
  height = 32,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.other400;

  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="12.1084" y="5.69727" width="19.4595" height="25.4545" rx="2" fill="#FFEEBF" />
      <g clipPath="url(#clip0_5790_96528)">
        <path
          d="M28.3106 14.6519C28.3106 19.8838 28.3106 25.1012 28.3106 30.3331C28.3106 31.594 27.9247 31.9998 26.7395 31.9998C21.2683 31.9998 15.7832 31.9998 10.312 31.9998C9.16811 31.9998 8.78223 31.594 8.78223 30.3766C8.78223 22.9418 8.78223 15.507 8.78223 8.05774C8.78223 7.69543 8.78223 7.33311 8.96139 6.99977C9.41617 7.18818 9.77449 6.79688 10.2155 6.79688C13.5782 6.84035 16.9546 6.82586 20.3311 6.79688C20.841 6.79688 21.0064 6.9563 20.9926 7.49253C20.9651 9.15919 20.9788 10.8404 20.9926 12.5215C20.9926 13.5505 21.4061 13.9708 22.3983 13.9708C24.3415 13.9708 26.2709 13.9708 28.2141 13.9708C28.4346 14.1592 28.3244 14.4201 28.3244 14.6519H28.3106Z"
          fill="url(#paint0_linear_5790_96528)"
        />
        <path
          d="M28.1862 13.9708C26.243 13.9708 24.3136 13.9708 22.3704 13.9708C21.3919 13.9708 20.9785 13.5506 20.9785 12.5216C20.9785 10.8404 20.9509 9.17374 20.9785 7.49258C20.9785 6.94186 20.8269 6.78244 20.317 6.79693C16.9543 6.82592 13.5778 6.82592 10.2014 6.79693C9.74659 6.79693 9.40205 7.18824 8.94727 6.99983C9.14021 6.55055 9.49853 6.34766 9.95331 6.34766C13.5778 6.34766 17.2024 6.36215 20.8269 6.37664C20.8545 6.47809 20.8958 6.52157 20.9647 6.40563C22.1224 7.40563 23.1146 8.59403 24.162 9.69548C25.5126 11.1158 26.8494 12.5506 28.1862 13.9853V13.9708Z"
          fill="#DEB744"
        />
        <g filter="url(#filter0_b_5790_96528)">
          <path
            d="M15.7143 10.1594C15.7143 6.75767 13.0919 4 9.85713 4C6.62233 4 4 6.75767 4 10.1594C4 13.5612 6.62233 16.3188 9.85713 16.3188C13.0919 16.3188 15.7143 13.5612 15.7143 10.1594Z"
            fill="url(#paint1_linear_5790_96528)"
          />
          <path
            d="M15.7143 10.1594C15.7143 6.75767 13.0919 4 9.85713 4C6.62233 4 4 6.75767 4 10.1594C4 13.5612 6.62233 16.3188 9.85713 16.3188C13.0919 16.3188 15.7143 13.5612 15.7143 10.1594Z"
            fill="#DEB744"
          />
        </g>
        <path
          d="M9.01529 9.77241V7.27729C9.01529 7.01096 9.24468 6.8007 9.53524 6.8007H10.0399C10.3305 6.8007 10.5598 7.01096 10.5598 7.27729V9.77241C10.5598 10.1649 10.2081 10.4873 9.77992 10.4873C9.35173 10.4873 9 10.1649 9 9.77241H9.01529Z"
          fill="white"
        />
        <path
          d="M9.58175 9.61791H11.6122C11.8289 9.61791 12 9.89242 12 10.2401V10.8441C12 11.1918 11.8289 11.4663 11.6122 11.4663H9.58175C9.26236 11.4663 9 11.0454 9 10.5329C9 10.0205 9.26236 9.59961 9.58175 9.59961V9.61791Z"
          fill="white"
        />
        <path d="M24.0525 17.7969H13.1514V19.565H24.0525V17.7969Z" fill="white" />
        <path d="M24.0525 21.8691H13.1514V23.6373H24.0525V21.8691Z" fill="white" />
        <path d="M23.9558 25.9424H13.0547V27.7105H23.9558V25.9424Z" fill="white" />
      </g>
      <g filter="url(#filter1_b_5790_96528)">
        <rect
          x="21.0273"
          y="20.1211"
          width="12.973"
          height="7.63636"
          rx="1"
          fill="url(#paint2_linear_5790_96528)"
        />
      </g>
      <g filter="url(#filter2_bf_5790_96528)">
        <path
          d="M9.85713 4.5C12.7924 4.5 15.2143 7.0098 15.2143 10.1594C15.2143 13.309 12.7924 15.8188 9.85713 15.8188C6.92188 15.8188 4.5 13.309 4.5 10.1594C4.5 7.0098 6.92188 4.5 9.85713 4.5Z"
          stroke="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_5790_96528"
          x="-1"
          y="-1"
          width="21.7139"
          height="22.3184"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5790_96528" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5790_96528"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_5790_96528"
          x="20.0273"
          y="19.1211"
          width="14.9727"
          height="9.63672"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5790_96528" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5790_96528"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_bf_5790_96528"
          x="-1"
          y="-1"
          width="21.7139"
          height="22.3184"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5790_96528" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5790_96528"
            result="shape"
          />
          <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_5790_96528" />
        </filter>
        <linearGradient
          id="paint0_linear_5790_96528"
          x1="18.5671"
          y1="6.79688"
          x2="17.709"
          y2="72.7258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={calculateColor(color)} />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5790_96528"
          x1="4"
          y1="4"
          x2="12.5897"
          y2="15.0812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={calculateColor(color)} />
          <stop offset="1" stopColor="#FFEEBF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5790_96528"
          x1="14.9463"
          y1="13.7575"
          x2="68.8962"
          y2="46.6639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={calculateColor(color)} />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_5790_96528">
          <rect width="24.3243" height="28" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};
