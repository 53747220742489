import {
  Box,
  Button,
  ColorConfig,
  Divider,
  Icon,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import type {
  DistributionConfiguration,
  DistributionImplementationDefinition,
} from "@prodoctivity/types";
import { FunctionComponent, useCallback } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { BasicHttpSvg } from "../../svg/BasicHttpSvg";
import { DeferredHttpSvg } from "../../svg/DeferredHttpSvg";
import { DiscordSvg } from "../../svg/DiscordSvg";
import { EmailsSvg } from "../../svg/EmailSvg";
import { RemoteHttpSvg } from "../../svg/RemoteHttpSvg";

type Props = {
  baseImplementations: DistributionImplementationDefinition[];
  userDefinedDistributions: DistributionConfiguration[];
};
export const DistributionListSection: FunctionComponent<Props> = ({
  baseImplementations,
  userDefinedDistributions,
}) => {
  const organizationNavigate = useOrganizationNavigate();
  const { resources } = useAppTranslation();
  const getImplementationTitle = useCallback(
    (implementationId: string) => {
      if (baseImplementations) {
        const implementation = baseImplementations.find(
          (imp) => imp.implementationId === implementationId
        );
        if (implementation) {
          const key =
            implementation.nameI18nKey as keyof typeof resources["distributionManagementPage"];
          return resources.distributionManagementPage[key];
        }
      }
    },
    [baseImplementations, resources.distributionManagementPage]
  );
  return (
    <Box display="flex" direction="column" flex="shrink" paddingX={4} gap={0}>
      <SectionTitle>{resources.distributionManagementPage.builtIn.toUpperCase()}</SectionTitle>
      {baseImplementations &&
        baseImplementations.map((dist) => {
          return (
            <SectionRow
              key={dist.implementationId}
              distributionType={dist.implementationId}
              hasCloneButton={true}
              label={getImplementationTitle(dist.implementationId) ?? dist.implementationId}
              isActive={true}
              onClick={() => {
                organizationNavigate(
                  `/settings/distributions/built-in/${dist.implementationId}/new`
                );
              }}
              onCloneClick={() => {
                organizationNavigate(
                  `/settings/distributions/built-in/${dist.implementationId}/new`
                );
              }}
            />
          );
        })}

      <Divider direction="horizontal" />

      <SectionTitle>{resources.distributionManagementPage.myOwn.toUpperCase()}</SectionTitle>
      {userDefinedDistributions &&
        userDefinedDistributions.map((dist) => {
          const distributionLabel =
            dist.label.length > 30 ? `${dist.label.slice(0, 15)}...` : dist.label;
          return (
            <SectionRow
              key={dist.distributionId}
              distributionType={dist.implementationId}
              hasCloneButton={false}
              label={distributionLabel}
              isActive={dist.isActive}
              onClick={() => {
                organizationNavigate(`/settings/distributions/${dist.distributionId}`);
              }}
              onCloneClick={() => {
                organizationNavigate(`/settings/distributions/${dist.distributionId}/new`);
              }}
            />
          );
        })}
    </Box>
  );
};

const getIconByType = (color: ColorConfig, baseImplementationId: string) => {
  switch (baseImplementationId) {
    case "amazonSES":
      return <EmailsSvg width={20} height={24} color={color} />;
    case "convertToPDF":
      return (
        <Icon
          accessibilityLabel={`${baseImplementationId} icon`}
          icon="arrows-rotate"
          size={"sm"}
        />
      );
    case "digitalSignature":
      return (
        <Icon accessibilityLabel={`${baseImplementationId} icon`} icon="edit-note" size={"sm"} />
      );
    case "discordWebHook":
      return <DiscordSvg width={20} height={24} color={color} />;
    case "basicHttpRequest":
      return <BasicHttpSvg width={20} height={24} color={color} />;
    case "remoteHttpRequest":
      return <RemoteHttpSvg width={20} height={24} color={color} />;
    case "remoteDeferredHttpRequest":
      return <DeferredHttpSvg width={20} height={24} color={color} />;
    default:
      return <Icon accessibilityLabel={`${baseImplementationId} icon`} icon="gear" size={"sm"} />;
  }
};

const SectionTitle = ({ children }: { children?: string }) => {
  const { colors } = useColors();

  return (
    <Box display="flex" flex="grow" paddingY={4}>
      <Text size="200" weight="normal" color={colors.subtle}>
        {children}
      </Text>
    </Box>
  );
};

const SectionRow = ({
  distributionType,
  label,
  onClick,
  isActive,
  hasCloneButton,
}: {
  distributionType: string;
  label: string;
  onClick: () => void;
  onCloneClick: () => void;
  isActive: boolean;
  hasCloneButton: boolean;
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  const iconColor = isActive ? colors.primary : colors.subtle;
  return (
    <Box
      display="flex"
      flex="grow"
      alignItems="center"
      gap={2}
      hoverColor={colors.primaryHover0}
      paddingY={1}
    >
      {/* Icon and Label Start */}
      <TapArea onTap={onClick}>
        <Box display="flex" flex="grow" gap={4} direction="row" alignItems="center">
          {getIconByType(iconColor, distributionType)}
          <Text size="200" color={iconColor}>
            {label}
          </Text>
        </Box>
      </TapArea>
      {/* Icon and Label End */}
      {/* Clone Button Start */}
      {hasCloneButton && (
        <Box display="flex" flex="shrink">
          <Button
            accessibilityLabel={`${resources.clone} ${label} ${resources.distribution}`}
            text={resources.clone}
            onClick={onClick}
            color="gray"
            size="sm"
            iconEnd="copy-to-clipboard"
          />
        </Box>
      )}
      {/* Clone Button End */}
    </Box>
  );
};
