import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import type { CombinedTemplate } from "@prodoctivity/types";
import { areObjectsEqual } from "@prodoctivity/shared";
import { organizationLinkTemplates } from "../../../link-templates";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useDocumentGroupList } from "../../../hooks";
import { useMutation } from "@tanstack/react-query";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useParams } from "react-router-dom";
import { useServices } from "../../../hooks/useServices";

export const useCombinedTemplateList = (refetchList: () => void) => {
  const { deleteCombinedTemplate } = useServices();

  const organizationNavigate = useOrganizationNavigate();
  const { resources } = useAppTranslation();
  const breadcrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      { type: "url", name: resources.settings, url: "/settings" },
      {
        type: "url",
        name: resources.fluency,
        url: organizationLinkTemplates.manageCombinedTemplates(),
      },
      {
        type: "text",
        name: resources.combinedTemplates.combinedTemplates,
      },
    ];
  }, [
    resources.home,
    resources.combinedTemplates.combinedTemplates,
    resources.settings,
    resources.fluency,
  ]);

  const { mutate: deleteMutation, isLoading: isMutating } = useMutation(deleteCombinedTemplate, {
    onSuccess: useCallback(() => {
      refetchList();
    }, [refetchList]),
  });

  return {
    resources,
    breadCrumbEntries: breadcrumbEntries,
    isMutating,
    organizationNavigate,
    deleteMutation,
  };
};

const STEPS = {
  Creation: 0,
  Configuration: 1,
};

export type CombinedTemplateState = {
  name?: string;
  description?: string;
  documentGroupId?: string;
  templates: Array<string>;

  errors?: { [key: string]: string };
  toastMessage?: {
    type: "error" | "success" | "warn";
    message: string;
  };
};

export const useCombinedTemplate = () => {
  const { resources } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const [formState, setFormState] = useState<CombinedTemplateState>({
    templates: [],
  });

  const { combinedTemplateId } = useParams();

  const {
    getCombinedTemplate,
    fetchTemplateListWithoutPagination,
    createOrUpdateCombinedTemplate,
  } = useServices();
  const { data: groupList } = useDocumentGroupList(false);

  const fetchTemplateList = useCallback(() => {
    if (formState.documentGroupId) {
      return fetchTemplateListWithoutPagination(formState.documentGroupId);
    }
    return {
      templates: [],
    };
  }, [fetchTemplateListWithoutPagination, formState.documentGroupId]);

  const { data: templateListResponse, isLoading } = useOrganizationQuery(
    `/templates/all?documentGroupId=${formState.documentGroupId}`,
    fetchTemplateList
  );

  const fetchCombinedTemplate = useCallback(async () => {
    if (combinedTemplateId) {
      return await getCombinedTemplate(combinedTemplateId);
    }
    return Promise.resolve({
      id: undefined,
      combinedTemplate: undefined,
    });
  }, [getCombinedTemplate, combinedTemplateId]);

  const { isLoading: isLoadingInfo } = useOrganizationQuery(
    `/combined-templates/${combinedTemplateId}`,
    fetchCombinedTemplate,
    {
      refetchOnMount: true,
      onSuccess: useCallback(
        (
          data:
            | {
                id: string;
                combinedTemplate: CombinedTemplate<string>;
              }
            | {
                id: undefined;
                combinedTemplate: undefined;
              }
        ) => {
          if (data.id) {
            setFormState((prev) => ({
              ...prev,
              name: data.combinedTemplate.name,
              description: data.combinedTemplate.description,
              documentGroupId: data.combinedTemplate.documentGroupId,
              templates: data.combinedTemplate.templates,
            }));
          }
        },
        []
      ),
    }
  );

  const [selectedTabIndex, setTabIndex] = useState<number>(STEPS.Creation);

  const documentGroups = useMemo(() => {
    return (groupList || []).map((group) => {
      return {
        label: group.name,
        value: group.id,
      };
    });
  }, [groupList]);

  const templateList = useMemo(() => {
    return (templateListResponse?.templates || []).map((template) => {
      return {
        label: template.name,
        value: template.templateId,
      };
    });
  }, [templateListResponse]);

  const currentDocumentGroup = useMemo(() => {
    return documentGroups.find((g) => g.value === formState.documentGroupId);
  }, [documentGroups, formState.documentGroupId]);

  const { mutate: createOrUpdateMutation, isLoading: isMutating } = useMutation(
    createOrUpdateCombinedTemplate,
    {
      onSuccess: () => {
        setFormState((prev) => ({
          ...prev,
          toastMessage: { type: "success", message: resources.dataDictionary.savedSuccessfully },
        }));
        setTimeout(() => {
          organizationNavigate(organizationLinkTemplates.manageCombinedTemplates());
        }, 2000);
      },
      onError: (error: { response: { data: { errors: Array<{ message: string }> } } }) => {
        if (error.response.data.errors.length > 0) {
          setFormState((prev) => ({
            ...prev,
            toastMessage: { type: "error", message: error.response.data.errors[0].message },
          }));
        }
      },
    }
  );

  const prevStep = useCallback(() => {
    setTabIndex((prev) => {
      if (prev === STEPS.Creation) {
        return prev;
      }
      return prev - 1;
    });
  }, []);

  const nextStep = useCallback(() => {
    const errors: CombinedTemplateState["errors"] = {};
    const emptyErrors: CombinedTemplateState["errors"] = {};
    if (!formState.name) {
      errors["name"] = resources.required;
    }
    if (!formState.description) {
      errors["description"] = resources.required;
    }
    if (!formState.documentGroupId) {
      errors["documentGroupId"] = resources.required;
    }

    if (selectedTabIndex === STEPS.Configuration) {
      if (formState.templates.length < 2) {
        errors["templates-for-combined-template"] = resources.chooseTwoItems;
      } else if (
        formState.documentGroupId &&
        currentDocumentGroup &&
        formState.name &&
        formState.description
      ) {
        const combinedTemplate: CombinedTemplate<string> = {
          documentGroupId: formState.documentGroupId,
          documentGroupName: currentDocumentGroup?.label,
          name: formState.name,
          description: formState.description,
          templates: formState.templates,
        };
        createOrUpdateMutation({ id: combinedTemplateId, combinedTemplate });
        return;
      }
    }

    setFormState((prev) => ({ ...prev, errors: errors }));
    if (!areObjectsEqual(errors, emptyErrors)) {
      return;
    }

    setTabIndex((prev) => {
      if (prev === STEPS.Configuration) {
        return prev;
      }
      return prev + 1;
    });
  }, [
    formState,
    resources,
    selectedTabIndex,
    currentDocumentGroup,
    combinedTemplateId,
    createOrUpdateMutation,
  ]);

  const onChangeDocumentGroup = useCallback(
    (isValue: boolean, value?: string) => {
      const documentGroup = documentGroups.find(
        (g) => (isValue && g.value === value) || (!isValue && g.label === value)
      );

      setFormState((prev) => ({ ...prev, documentGroupId: documentGroup?.value, templates: [] }));
    },
    [documentGroups]
  );

  return {
    selectedTabIndex,
    resources,
    formState,
    templateList,
    setFormState,
    nextStep,
    prevStep,
    STEPS,
    isLoading: isLoading || isLoadingInfo || isMutating,
    documentGroups,
    currentDocumentGroup,
    onChangeDocumentGroup,
  };
};

export const useTemplateTab = ({
  templates,
  formState,
  setFormState,
}: {
  templates: Array<{ value: string; label: string }>;
  formState: CombinedTemplateState;
  setFormState: Dispatch<SetStateAction<CombinedTemplateState>>;
}) => {
  const templatesFiltered = useMemo(() => {
    return templates.filter((t) => !formState.templates.includes(t.value));
  }, [templates, formState]);

  const onSelectTemplate = useCallback(
    (templateId: string) => {
      setFormState((prev) => {
        const templates = prev.templates;

        templates.push(templateId);
        return { ...prev, templates };
      });
    },
    [setFormState]
  );

  const onMoveTemplate = useCallback(
    (templateId: string, moveTo: "up" | "down") => {
      setFormState((prev) => {
        const templateIndex = prev.templates.findIndex((id) => id === templateId);

        if (templateIndex === -1) {
          return prev;
        }

        const templates = prev.templates;

        if (moveTo === "up" && templateIndex > 0) {
          const itemToMove = templates[templateIndex];
          templates.splice(templateIndex, 1);
          templates.splice(templateIndex - 1, 0, itemToMove);
        } else if (moveTo === "down" && templateIndex < templates.length - 1) {
          const itemToMove = templates[templateIndex];
          templates.splice(templateIndex, 1);
          templates.splice(templateIndex + 1, 0, itemToMove);
        }

        return { ...prev, templates };
      });
    },
    [setFormState]
  );

  const onRemoveTemplate = useCallback(
    (templateId: string) => {
      setFormState((prev) => {
        const templates = prev.templates.filter((id) => id !== templateId);
        return { ...prev, templates };
      });
    },
    [setFormState]
  );

  return {
    templatesFiltered,
    onSelectTemplate,
    onMoveTemplate,
    onRemoveTemplate,
  };
};
