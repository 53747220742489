import { Box, Text, useColors } from "@prodoctivity/design-system";

import type { EcmDocument } from "@prodoctivity/types";
import { OrganizationLink } from "../OrganizationLink";
import { SmallRefreshButton } from "../SmallRefreshButton";
import { organizationLinkTemplates } from "../../link-templates";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useGetDocumentVersions } from "../../pages/StandAloneViewer/hooks";
import { FancyDateTime } from "@prodoctivity/design-system";

type Props = {
  document: EcmDocument;
};

export function ViewerDocumentVersions({ document }: Props) {
  const { colors } = useColors();
  const { data: versionList, refetch } = useGetDocumentVersions(document?.documentId || "");
  const versions = versionList ? versionList.documentVersions : [];
  const { resources, moment } = useAppTranslation();
  return (
    <Box paddingX={5}>
      <Box width={"100%"} display="flex" justifyContent="end">
        <SmallRefreshButton refetch={() => refetch()} accessibilityLabel={resources.refresh} />
      </Box>

      <Box rounding={2} color={colors.white} borderStyle="sm" overflow="scrollY" maxHeight={600}>
        {versions.map((version) => (
          <OrganizationLink
            to={organizationLinkTemplates.documentIdAndVersion(
              version.documentId,
              version.documentVersionId,
              undefined
            )}
            key={version.documentVersionId}
          >
            <Box
              padding={5}
              color={
                document?.documentVersionId === version.documentVersionId
                  ? colors.white
                  : colors.neutral300
              }
              hoverColor={colors.neutral500}
            >
              <Box display="flex" direction="column" flex="grow">
                <Box display="flex" flex="grow">
                  <Box display="flex" flex="grow">
                    <Text inline={true} weight="bold">
                      {version.name}
                    </Text>
                  </Box>
                </Box>
                <Box display="flex" flex="shrink">
                  <FancyDateTime moment={moment} resources={resources} value={version.updatedAt} />
                </Box>
              </Box>
            </Box>
          </OrganizationLink>
        ))}
      </Box>
    </Box>
  );
}
