import { Box, SelectList, Skeleton, Text, useColors } from "@prodoctivity/design-system";
import { useQuickActionsWidget } from "./hooks";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { NotificationMessage } from "../../NotificationMessage";
import { useLanguages } from "../../../hooks/useLanguages";
import { OrganizationNavLink } from "../../OrganizationLink";

export function QuickActions() {
  const { setToastMessage, handleLanguageChange, profileData, data, toastMessage } =
    useQuickActionsWidget();
  const { resources } = useAppTranslation();
  const { languages } = useLanguages();
  const { colors } = useColors();
  const loading = !data || !profileData;
  return (
    <Box
      width={"100%"}
      display="flex"
      flex="grow"
      minWidth="350px"
      direction="column"
      paddingTop={2}
    >
      <Box
        display="flex"
        direction="row"
        justifyContent="between"
        paddingX={2}
        paddingY={2}
        gap={4}
      >
        <Text weight="bold">{resources.language}</Text>
        <Box display="flex" alignItems="center" width="100%">
          {loading ? (
            <Skeleton height={25} />
          ) : (
            <Box marginBottom={2}>
              <SelectList
                id="language"
                onChange={({ value }) => {
                  handleLanguageChange({ value });
                }}
                value={profileData.language}
                options={languages}
              />
            </Box>
          )}
        </Box>
        {toastMessage && (
          <NotificationMessage
            type={toastMessage?.type}
            message={toastMessage.message}
            position="bottom-left"
            handleDismiss={() => setToastMessage(undefined)}
            delay={3000}
          />
        )}
      </Box>
      <Box padding={2} rounding={2} datatype="menu-item-entry" color={colors.neutral50}>
        <OrganizationNavLink to="/templates?action=generate">
          <Text>{resources.generateDocument}</Text>
        </OrganizationNavLink>
      </Box>

      <Box padding={2} rounding={2} datatype="menu-item-entry" color={colors.neutral50}>
        <OrganizationNavLink to="/documents/scan">
          <Text>{resources.scanADocument}</Text>
        </OrganizationNavLink>
      </Box>

      <Box padding={2} rounding={2} datatype="menu-item-entry" color={colors.neutral50}>
        <OrganizationNavLink to="/documents/new">
          <Text>{resources.importADocument} </Text>
        </OrganizationNavLink>
      </Box>
    </Box>
  );
}
