import { FunctionComponent, useEffect } from "react";

import { Spinner } from "@prodoctivity/design-system";
import { useNavigate } from "react-router-dom";
import { LoginLayout } from "../components/Layout/LoginLayout";

type Props = {
  logout: () => void;
};

const LogoutPage: FunctionComponent<Props> = function Login({ logout }) {
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate("/info");
  }, [logout, navigate]);

  return (
    <LoginLayout>
      <Spinner accessibilityLabel="Loading ..." show={true} />
    </LoginLayout>
  );
};

export default LogoutPage;
