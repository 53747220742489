import { ChangeEvent, ReactNode, useEffect, useMemo, useRef } from "react";

import { Box } from "../Box";
import { TapArea } from "../TapArea";

type Props = {
  id?: string;
  onSelected: (base64Img: string) => void;
  children?: ReactNode;
};

export function ImagePicker({ id, children, onSelected }: Props) {
  const reader = useMemo(() => new FileReader(), []);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    if (!image) {
      return;
    }
    reader.readAsDataURL(image);
  };

  useEffect(() => {
    reader.onload = (e) => {
      const image = e.target?.result;

      if (typeof image === "string") {
        onSelected(image.replace("jpeg", "png"));
      }
    };
  }, [onSelected, reader]);

  return (
    <Box>
      <TapArea onTap={() => inputRef.current?.click()}>{children}</TapArea>
      <input
        id={id}
        onChange={handleChange}
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        accept="image/*"
      />
    </Box>
  );
}
