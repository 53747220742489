import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CardIconSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 28,
  height = 21,
  onClick,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 28 21`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1818_57423)">
        <path
          d="M0 18.4327C0 19.6231 1.04938 20.5961 2.33333 20.5961H25.6667C26.9506 20.5961 28 19.6231 28 18.4327V10.5H0V18.4327ZM9.33333 15.3649C9.33333 15.0673 9.59259 14.8269 9.91358 14.8269H16.5309C16.8519 14.8269 17.1111 15.0673 17.1111 15.3649V17.1735C17.1111 17.4711 16.8519 17.7115 16.5309 17.7115H9.91358C9.59259 17.7115 9.33333 17.4711 9.33333 17.1735V15.3649ZM3.11111 15.3649C3.11111 15.0673 3.37037 14.8269 3.69136 14.8269H7.19753C7.51852 14.8269 7.77778 15.0673 7.77778 15.3649V17.1735C7.77778 17.4711 7.51852 17.7115 7.19753 17.7115H3.69136C3.37037 17.7115 3.11111 17.4711 3.11111 17.1735V15.3649ZM28 2.56727V4.73073H0V2.56727C0 1.37679 1.04938 0.403809 2.33333 0.403809H25.6667C26.9506 0.403809 28 1.37679 28 2.56727Z"
          fill="#767676"
        />
      </g>
      <defs>
        <clipPath id="clip0_1818_57423">
          <rect width="28" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
