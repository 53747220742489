import {
  AnimationSet,
  Box,
  BoxWithAnimations,
  Center,
  Divider,
  HEADER_ZINDEX,
  Icon,
  Link,
  OverlayPanel,
  Svg,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { advancedFieldsForFilter, organizationLinkTemplates } from "../link-templates";
import { Avatar, PublicAvatarImage } from "./Avatar/Avatar";
import { OrganizationLink, OrganizationNavLink } from "./OrganizationLink";

import { useLocation } from "react-router-dom";
import logoUrl from "../assets/logo.png";
import { useAppTranslation } from "../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../hooks/useOrganizationNavigate";
import { useServices } from "../hooks/useServices";
import { useUserProfile } from "../hooks/useUserProfile";
import { CloudLogoSvgIcon } from "../svg/CloudLogoSvgIcon";
import { MyTasksSvg } from "../svg/MyTasksSvg";
import { RecentActivitySvgIcon } from "../svg/RecentActivitySvgIcon";
import { AvatarProfile } from "./Avatar/AvatarProfile";
import { CreateItem } from "./Header/CreateItem";
import { CreateItemMenu } from "./Header/CreateItemMenu";
import { ComponentNeedsPermission } from "./Layout/RouteNeedsPermission";
import { NotificationsIcon } from "./Notification/Notifications";
import { OpenSearch } from "./OpenSearch/OpenSearch";
import { OrganizationAvatar } from "./OrganizationAvatar/OrganizationAvatar";
import { OrganizationAvatarProfile } from "./OrganizationAvatar/OrganizationAvatarProfile";

export const Header: FunctionComponent = () => {
  // HEADER SMALL LOGIC
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const organizationNavigate = useOrganizationNavigate();
  const goToHome = useCallback(() => {
    setShowMobileMenu(false);
    organizationNavigate("/");
  }, [organizationNavigate]);
  const { colors } = useColors();
  const [searchClicked, setSearchClicked] = useState<boolean>(false);

  const cloudLogoAnimation = { initial: { width: "fit-content" }, exit: { x: "-50vw" } };
  const openSearchDissolveAnimation: AnimationSet = {
    initial: { width: "94%", opacity: 1, overflow: "hidden", position: "absolute" },
    exit: {
      opacity: 0,
      width: 1,
      transition: { when: "beforeChildren", duration: 0.4 },
      position: "static",
      marginLeft: 200,
    },
  };
  const openSearchFadeInAnimation = {
    animate: {
      width: "100%",
      opacity: 1,
      display: searchClicked ? "block" : undefined,
    },
    initial: {
      width: 1,
      opacity: 0,
      display: searchClicked ? "none" : undefined,
    },
    transition: { delay: 0.5 },
  };
  const avatarProfileAnimation = {
    initial: { width: "100%", display: "flex", justifyContent: "end" },
    exit: { x: "50vw", width: 1 },
  };

  // REGULAR HEADER LOGIC
  const {
    user,
    userOrganizations,
    markNotificationAsRead,
    removeNotifications,
    switchOrganization,
  } = useServices();
  const { breakpoint } = useDesignBreakpoint();
  const location = useLocation();
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const urlFieldsToCompare = useMemo(() => {
    return advancedFieldsForFilter(params);
  }, [params]);

  const { resources } = useAppTranslation();
  const { data: userProfile } = useUserProfile();

  const isTablet = breakpoint === "large";

  const isAdmin = useMemo(() => {
    return (user?.permissions || []).includes("organization-admin");
  }, [user]);

  return (
    <>
      {breakpoint !== "small" && (
        <Box
          width="100%"
          color={colors.white}
          paddingY={3}
          zIndex={HEADER_ZINDEX}
          position="fixed"
          dangerouslySetInlineStyle={{
            __style: {
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.10)",
            },
          }}
        >
          <Box
            paddingX={4}
            display="flex"
            direction="row"
            flex="grow"
            fit={true}
            justifyContent="between"
          >
            <Box
              paddingX={4}
              maxWidth={220}
              width={breakpoint === "medium" ? "33%" : "100%"}
              display="flex"
              alignSelf="center"
              flex="shrink"
            >
              <Link href={organizationLinkTemplates.home()}>
                <Box alignItems="center" display="flex">
                  <Svg src={logoUrl} alt="logo" width={170} />
                </Box>
              </Link>
            </Box>
            <OpenSearch urlFieldsToCompare={urlFieldsToCompare} />

            {breakpoint === "medium" || isTablet ? (
              <Box
                width={"33%"}
                display="flex"
                justifyContent="end"
                alignItems="center"
                padding={1}
              >
                <Box display="flex" justifyContent="end" alignItems="center">
                  <TapArea onTap={() => setShowMobileMenu(true)}>
                    <Icon
                      accessibilityLabel="Menu"
                      icon="hamburgerMenu"
                      size={"sm"}
                      color={colors.subtle}
                    />
                  </TapArea>
                </Box>
              </Box>
            ) : (
              <>
                {userProfile && user && (
                  <Box display="flex" flex="shrink">
                    <Box display="flex" direction="row" gap={5}>
                      <ComponentNeedsPermission permission="organization-admin">
                        <Center>
                          <OrganizationNavLink to="/settings">
                            <Icon
                              padding={1}
                              color={colors.primary}
                              hoverColor={colors.white}
                              textShadowOnHover={true}
                              accessibilityLabel={resources.notifications.notifications}
                              icon="gear"
                              size={6}
                            />
                          </OrganizationNavLink>
                        </Center>
                      </ComponentNeedsPermission>
                      <Center>
                        <OrganizationNavLink to="/tasks/list/unclaimed">
                          <Icon
                            padding={1}
                            color={colors.primary}
                            hoverColor={colors.white}
                            textShadowOnHover={true}
                            accessibilityLabel={resources.tasks}
                            icon="ballot-check"
                            size={6}
                          />
                        </OrganizationNavLink>
                      </Center>
                      <Center>
                        <CreateItem forceOpen={false} />
                      </Center>

                      <Center>
                        <NotificationsIcon
                          forceOpen={false}
                          markNotificationAsRead={markNotificationAsRead}
                          removeNotifications={removeNotifications}
                        />
                      </Center>

                      <Box color={colors.neutral700} borderStyle="shadow" width={1} />

                      <OrganizationAvatar
                        username={user.username}
                        organizationName={userProfile.organizationName}
                        organizationId={user.organizationId}
                        fetchOrganizations={userOrganizations}
                        switchOrganization={switchOrganization}
                      />

                      <Avatar
                        name={`${userProfile.firstName} ${userProfile?.lastName}`}
                        email={userProfile.email}
                        imageDataURI={userProfile.avatarImageDataURI}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      )}

      {breakpoint === "small" && (
        <Box>
          <>
            <Box
              width="100%"
              color={colors.white}
              dangerouslySetInlineStyle={{
                __style: {
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
                  zIndex: 1000,
                },
              }}
              paddingY={1}
              minHeight={52}
              display="flex"
              alignItems="center"
              position="fixed"
            >
              <Box
                paddingX={4}
                width="100%"
                display="flex"
                direction="row"
                flex="grow"
                justifyContent={"between"}
                alignItems="center"
              >
                <BoxWithAnimations
                  animationSet={cloudLogoAnimation}
                  condition={!searchClicked}
                  animatePresence={true}
                >
                  <Box maxWidth={220} height={36} alignItems="center" justifyContent="center">
                    <TapArea onTap={goToHome}>
                      <CloudLogoSvgIcon />
                    </TapArea>
                  </Box>
                </BoxWithAnimations>

                <BoxWithAnimations
                  animationSet={openSearchDissolveAnimation}
                  animatePresence={true}
                  condition={searchClicked}
                >
                  <BoxWithAnimations
                    animationSet={openSearchFadeInAnimation}
                    width="100%"
                    height={36}
                  >
                    <Box
                      onClickCapture={() => {
                        setSearchClicked(true);
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width={"100%"}
                    >
                      <OpenSearch
                        searchClicked={searchClicked}
                        setSearchClicked={setSearchClicked}
                        urlFieldsToCompare={urlFieldsToCompare}
                      />
                    </Box>
                  </BoxWithAnimations>
                </BoxWithAnimations>

                <BoxWithAnimations
                  animationSet={avatarProfileAnimation}
                  condition={!searchClicked}
                  animatePresence={true}
                >
                  <Box display="flex" justifyContent="between" alignItems="center" padding={1}>
                    <Box
                      onClickCapture={() => {
                        setSearchClicked(true);
                      }}
                      minWidth={36}
                      minHeight={36}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Icon
                        icon={"search"}
                        accessibilityLabel={resources.home}
                        size={"md"}
                        color={colors.black600}
                      />
                    </Box>
                    <Box paddingY={1} paddingX={4}>
                      <Box color={colors.neutral900} width={1} height={24} />
                    </Box>
                    <TapArea onTap={() => setShowMobileMenu(true)}>
                      <PublicAvatarImage
                        username={userProfile ? userProfile.email : ""}
                        size={32}
                      />
                    </TapArea>
                  </Box>
                </BoxWithAnimations>
              </Box>
            </Box>
          </>
          <Box margin={6} />
        </Box>
      )}

      {showMobileMenu && (breakpoint === "small" || breakpoint === "medium" || isTablet) && (
        <Box zIndex={HEADER_ZINDEX}>
          <OverlayPanel
            accessibilityLabel={resources.actions}
            onDismiss={() => setShowMobileMenu(false)}
            size={breakpoint === "medium" ? "sm" : "lg"}
          >
            <Box
              position="relative"
              color={colors.white}
              display="flex"
              direction="column"
              height={"auto"}
            >
              <Box width={"auto"}>
                <Box>
                  <TapArea onTap={goToHome}>
                    <Box alignItems="center" display="flex">
                      <Svg src={logoUrl} alt="logo" width={280} />
                    </Box>
                  </TapArea>
                </Box>
              </Box>
              <Box width={"auto"}>
                {userProfile && user && (
                  <Box display="flex" direction="column">
                    <Divider />
                    <Box minHeight={80} paddingX={4} paddingY={2}>
                      <AvatarProfile
                        name={`${userProfile.firstName} ${userProfile?.lastName}`}
                        email={userProfile.email}
                        imageDataURI={userProfile.avatarImageDataURI}
                        setShowMobileMenu={setShowMobileMenu}
                      />
                    </Box>
                    <Divider />
                    <Box paddingX={4} paddingY={4}>
                      <OrganizationAvatarProfile
                        username={user.username}
                        organizationName={userProfile.organizationName}
                        organizationId={user.organizationId}
                        fetchOrganizations={userOrganizations}
                        switchOrganization={switchOrganization}
                      />
                    </Box>
                    <Divider />
                    <Box minHeight={80} display="flex" alignItems="center" paddingX={3}>
                      <OrganizationLink to="/" onClick={() => setShowMobileMenu(false)}>
                        <Box display="flex" direction="row" padding={2}>
                          <Box display="flex" direction="row" gap={6}>
                            <Box display="flex">
                              <Icon
                                icon={"document"}
                                accessibilityLabel={resources.home}
                                size={"md"}
                                color={colors.neutral900}
                              />
                            </Box>
                            <Box display="flex" alignItems="center">
                              <Text size="400" align="center">
                                {resources.home}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </OrganizationLink>
                    </Box>
                    <Divider />
                    <Box minHeight={80} display="flex" alignItems="center" paddingX={4}>
                      <CreateItemMenu setShowMobileMenu={setShowMobileMenu} />
                    </Box>
                    <Divider />

                    {isAdmin && (
                      <Box minHeight={80} display="flex" alignItems="center" paddingX={4}>
                        <OrganizationLink to="/settings" onClick={() => setShowMobileMenu(false)}>
                          <Box
                            display="flex"
                            direction="row"
                            alignItems="center"
                            paddingX={2}
                            paddingY={4}
                          >
                            <Box display="flex" direction="row" alignItems="center" gap={6}>
                              <Box display="flex">
                                <Icon
                                  color={colors.neutral900}
                                  accessibilityLabel={resources.notifications.notifications}
                                  icon="gear"
                                  size={6}
                                />
                              </Box>
                              <Box display="flex">
                                <Text size="400" align="center">
                                  {resources.settings}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </OrganizationLink>
                      </Box>
                    )}

                    <Divider />
                    <Box minHeight={80} display="flex" alignItems="center" paddingX={4}>
                      <OrganizationLink
                        to={organizationLinkTemplates.tasks("unclaimed")}
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <Box display="flex" direction="row" paddingX={0} paddingY={2}>
                          <Box display="flex" direction="row" gap={6}>
                            <Box display="flex">
                              <MyTasksSvg color={colors.black600} />
                            </Box>
                            <Box display="flex" paddingY={1}>
                              <Text size="400" align="center">
                                {resources.homePage.myTasks}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </OrganizationLink>
                    </Box>
                    <Divider />
                    <Box minHeight={80} display="flex" alignItems="center" paddingX={4}>
                      <OrganizationLink
                        to={organizationLinkTemplates.activityList()}
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <Box display="flex" direction="row" padding={2} paddingY={4}>
                          <Box display="flex" direction="row" gap={6}>
                            <Box display="flex">
                              <RecentActivitySvgIcon />
                            </Box>
                            <Box display="flex">
                              <Text size="400" align="center">
                                {resources.recentActivity}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </OrganizationLink>
                    </Box>
                    <Divider />
                    <Box minHeight={80} display="flex" alignItems="center" paddingX={4}>
                      <OrganizationLink
                        to={organizationLinkTemplates.notificationsPage()}
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <Box display="flex" direction="row" padding={2}>
                          <Box display="flex" direction="row" gap={6}>
                            <Box display="flex">
                              <Icon
                                icon={"bell"}
                                accessibilityLabel={resources.notifications.noNotificationsToShow}
                                color={colors.neutral900}
                                size={"md"}
                              />
                            </Box>
                            <Box display="flex">
                              <Text size="400" align="center">
                                {resources.notifications.seeAllNotifications}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </OrganizationLink>
                    </Box>
                    <Divider />
                  </Box>
                )}
              </Box>
            </Box>
          </OverlayPanel>
        </Box>
      )}
    </>
  );
};
