import {
  Box,
  Button,
  ChevronSvgIcon,
  Divider,
  ResponsiveContainer,
  Skeleton,
  Tabs,
  TapArea,
  Text,
  TextField,
  Tooltip,
  useColors,
} from "@prodoctivity/design-system";
import type {
  DocumentCollectionDocumentView,
  DocumentCollectionFolderView,
} from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { FolderSummary, FolderTreeInstance } from "./components/StructureInstance";

import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";
import { dateFormat } from "@prodoctivity/prodoctivity-form-v5/src/components/_lib/date-formats";
import { buildEventEmitter } from "@prodoctivity/shared";
import type { EcmDocument } from "@prodoctivity/types";
import { type BreadCrumbEntry } from "../../components/BreadCrumb";
import { DocumentViewerWrapper } from "../../components/DocumentViewer/DocumentViewerWrapper";
import { Page } from "../../components/Layout/Page";
import { NotificationMessage } from "../../components/NotificationMessage";
import { PublicProfile } from "../../components/Profile/Public/PublicProfile";
import { SmallRefreshButton } from "../../components/SmallRefreshButton";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { CircleInfoSvgIcon } from "../../svg/CircleInfoSvgIcon";
import { FolderSnapshotSvgIcon } from "../../svg/DocumentCollection/FolderSnapshotSvgIcon";
import { emptyArray, useCacheManager } from "../../utils";
import { usePageHeight } from "../Dashboard/DashboardPageWrapper";
import { PageNotFound } from "../StandAloneViewer/PageNotFound";
import { RenderDocumentCollectionIcon } from "./RenderDocumentCollectionIcon";
import { CollectionsInstanceLogs } from "./components/CollectionsInstanceLogs";
import { useDocumentCollectionInstance } from "./hooks";
import { FancyDateTime } from "@prodoctivity/design-system";

export type CollectionInstanceEvents = {
  collapseAll: Record<string, unknown>;
};

const DocumentCollectionInstancePage: FunctionComponent = () => {
  const { resources, moment } = useAppTranslation();

  const {
    isMobile,
    showSnapshot,
    setShowSnapshot,
    folderView,
    collectionTabIndex,
    setCollectionTabIndex,
    user,
    documentCollection,
    documentCollectionResponseContext,
    refetch,
    globalDocumentsCount,
    collectionConfig,
    folderPath,
    setFolderPath,
    isLoadingDocument,
    ecmDocument,
    currDocument,
    setCurrDocument,
    toastMessage,
    setToastMessage,
    isPanelHidden,
    showPanel,
    hidePanel,
    filterValue,
    setFilterValue,
    setDocumentsDisplay,
    documentsDisplay,
    sortSelectedOptions,
    SetSortSelectedOptions,
    filterTextFieldEnabled,
    setFilterTextFieldEnabled,
    clearAllFilters,
    documentSelected,
    documentSelectedState,
    isComponentLoading,
  } = useDocumentCollectionInstance();
  const { colors } = useColors();

  const tabs = useMemo(() => {
    return [
      { href: "#", text: resources.view },
      { href: "#", text: resources.history },
      // { href: "#", text: resources.notifications.notifications },
    ];
  }, [resources.history, resources.view]);

  const [paths, setPaths] = useState<Array<string>>([]);
  const documentCollectionName = documentCollection ? documentCollection.name : resources.loading;

  const checkFolder = useCallback(
    (
      folder: DocumentCollectionFolderView,
      fullPath: string[],
      filter: string,
      paths: Set<string>
    ): boolean => {
      if (!filter) {
        return true;
      }
      const result =
        folder.folders.some((subFolder) =>
          checkFolder(subFolder, [...fullPath, folder.name], filter, paths)
        ) || folder.documents.some((doc) => doc.name.toLowerCase().includes(filter.toLowerCase()));
      if (result) {
        paths.add([...fullPath, folder.name].toString());
      }
      return result;
    },
    []
  );

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
      {
        type: "url",
        name: resources.documentCollection.documentCollections,
        url: organizationLinkTemplates.documentCollectionList({
          pageNumber: 0,
          rowsPerPage: "15",
          filter: "",
          assignedTo: "",
        }),
      },
      { type: "text", name: documentCollectionName },
    ];
  }, [resources, documentCollectionName]);

  const eventEmitter = useMemo(() => {
    return buildEventEmitter<CollectionInstanceEvents, keyof CollectionInstanceEvents>();
  }, []);

  const subscribe = useCallback(
    (eventType: keyof CollectionInstanceEvents, handler: () => void) => {
      eventEmitter.on(eventType, handler);

      return {
        unsubscribe() {
          eventEmitter.off(eventType, handler);
        },
      };
    },
    [eventEmitter]
  );

  const thisFolder = useMemo(() => {
    if (!documentCollection) {
      return undefined;
    }

    return {
      ...(folderView || documentCollection.rootFolder),
      name: folderView ? folderView.name : documentCollection.name,
    };
  }, [documentCollection, folderView]);

  const { clearQueryCache } = useCacheManager();
  const organizationNavigate = useOrganizationNavigate();

  usePageHeight(true);

  useEffect(() => {
    //refetching because this document collection does not exists
    if (!isComponentLoading && !thisFolder) {
      setTimeout(() => {
        clearQueryCache();
        organizationNavigate(
          organizationLinkTemplates.documentCollectionList({
            assignedTo: "",
            filter: "",
            pageNumber: 0,
            rowsPerPage: "15",
          })
        );
      }, 500);
    }
  }, [clearQueryCache, isComponentLoading, organizationNavigate, thisFolder]);

  if (isComponentLoading) {
    return <Skeleton width={"100%"} height={"100%"} />;
  } else if (!thisFolder || !documentCollection || !collectionConfig) {
    return <PageNotFound message={resources.pageNotFound.documentCollectionNotFound} />;
  }

  return (
    <Page
      breadCrumbEntries={isMobile ? undefined : breadCrumbEntries}
      mobileHeading={
        isMobile
          ? {
              title: thisFolder.name || documentCollectionName,
              backArrowFn: window.history.length > 1 ? () => window.history.back() : undefined,
              rightButtonIcon: undefined,
            }
          : undefined
      }
    >
      <Box display="flex" direction="column" flex="grow" height={"100%"}>
        <Box
          display="flex"
          flex="grow"
          direction={isMobile ? "column" : "row"}
          dangerouslySetInlineStyle={{
            __style: isMobile ? { flexDirection: "column-reverse" } : {},
          }}
        >
          <ResponsiveContainer
            resources={resources}
            isMobile={isMobile}
            displaySheetButtonText={resources.documentCollection.documentCollectionPanel}
            displayButtonType="button"
            size="full"
            showDismissButton={true}
            customFooter={undefined}
            disableSheetButton={false}
            externalOpenSetter={undefined}
            externalOpenState={false}
            headingText={undefined}
          >
            <Box
              display={isMobile && isPanelHidden ? "none" : "flex"}
              direction="column"
              borderStyle="shadow"
              height={"100%"}
              width={!isPanelHidden ? (isMobile ? "100%" : "26%") : 40}
              flex="shrink"
            >
              <Box display="flex" direction="row" paddingTop={3}>
                {!isPanelHidden && (
                  <Box display="flex" flex="shrink" alignItems="center" paddingLeft={4}>
                    <Text
                      // align="center"
                      size="300"
                      weight={"bold" /*showSnapshot ? "bold" : "normal" */}
                      decoration={showSnapshot ? "line-through" : undefined}
                    >
                      {collectionConfig.name}
                    </Text>{" "}
                  </Box>
                )}

                <Box display="flex" flex="grow"></Box>
                {!isPanelHidden &&
                documentCollection.assignedTo === user?.username &&
                (folderView || documentCollection.rootFolder).counts.issueCount > 0 ? (
                  <Tooltip text={resources.documentCollection.resolveTheIssues}>
                    <CircleInfoSvgIcon
                      width={40}
                      height={40}
                      type="exclamation"
                      color={colors.error}
                    />
                  </Tooltip>
                ) : (
                  <Box display="flex" flex="grow"></Box>
                )}

                <Box display="flex" flex="shrink">
                  {!isPanelHidden && (
                    <SmallRefreshButton
                      marginTop={0}
                      refetch={refetch}
                      accessibilityLabel={resources.refresh}
                    />
                  )}
                  {!isMobile && (
                    <TapArea onTap={isPanelHidden ? showPanel : hidePanel}>
                      <ChevronSvgIcon
                        color={colors.neutral700}
                        direction={isPanelHidden ? "right" : "left"}
                      />
                    </TapArea>
                  )}
                </Box>
              </Box>

              {!isPanelHidden ? (
                <Box
                  display="flex"
                  direction="column"
                  flex="grow"
                  width={isMobile ? "100%" : undefined}
                >
                  <Box display="flex" direction="column" paddingLeft={4}>
                    <Box display="flex" direction="row" gap={2} flex="grow">
                      <Box display="flex" flex="shrink" paddingTop={2}>
                        <TapArea
                          onTap={() => {
                            if (documentCollection.rootFolder.snapshot) {
                              setShowSnapshot(!showSnapshot);
                            }
                          }}
                        >
                          <Box display="flex">
                            {!showSnapshot ? (
                              <>
                                <RenderDocumentCollectionIcon
                                  iconKey={documentCollection.icon}
                                  width={25}
                                  height={25}
                                />
                                {documentCollection.rootFolder.snapshot && (
                                  <Box display="flex" alignItems="end">
                                    <FolderSnapshotSvgIcon width={25} height={25} />
                                  </Box>
                                )}
                              </>
                            ) : (
                              <>
                                {documentCollection.rootFolder.snapshot && (
                                  <FolderSnapshotSvgIcon width={25} height={25} />
                                )}
                                <Box display="flex" alignItems="end">
                                  <RenderDocumentCollectionIcon
                                    iconKey={documentCollection.icon}
                                    width={25}
                                    height={25}
                                  />
                                </Box>
                              </>
                            )}
                          </Box>
                        </TapArea>
                      </Box>
                      <Box display="flex" direction="column" flex="grow">
                        <Box>
                          <Text>{documentCollection.name}</Text>
                        </Box>

                        <Box display="flex" gap={2}>
                          {documentCollection.assignedTo && (
                            <>
                              <Box>
                                <Box display="flex" gap={1}>
                                  <Text color={colors.subtle} size="200">
                                    {resources.assignedTo}:
                                  </Text>
                                  <PublicProfile username={documentCollection.assignedTo} />
                                </Box>
                              </Box>
                              <Box display="flex" flex="grow" />
                            </>
                          )}
                          <Box>
                            <Tooltip
                              text={`${resources.lastUpdated}: ${moment(
                                documentCollection.updatedAt
                              ).format(dateFormat("DateTime", false))}`}
                            >
                              <FancyDateTime
                                moment={moment}
                                resources={resources}
                                color={colors.subtle}
                                size="200"
                                value={documentCollection.updatedAt}
                                showTime={false}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Tabs
                      activeTabIndex={collectionTabIndex}
                      onChange={({ activeTabIndex: index }) => setCollectionTabIndex(index)}
                      tabs={tabs}
                      wrap={true}
                    />
                  </Box>

                  <Divider direction="horizontal" />
                  <Box display="flex" direction="column" flex="grow">
                    {collectionTabIndex === 0 ? (
                      <Box width={"100%"} display="flex" direction="column" flex="grow">
                        <Box paddingY={2} display="flex" direction="column" paddingX={2}>
                          <FolderSummary
                            resources={resources}
                            documentsCount={globalDocumentsCount}
                            gapSize={2}
                            setDocumentsDisplay={setDocumentsDisplay}
                            documentsDisplay={documentsDisplay}
                            setFilterTextFieldEnabled={setFilterTextFieldEnabled}
                            filterTextFieldEnabled={filterTextFieldEnabled}
                            setFilterValue={setFilterValue}
                            clearAllFilters={clearAllFilters}
                          />

                          {filterTextFieldEnabled && (
                            <Box paddingY={4} width={"100%"} display="flex" gap={2}>
                              <Box display="block" width={"100%"}>
                                <TextField
                                  placeholder={
                                    resources.documentCollection.collectionFilter.filterDocuments
                                  }
                                  id={"document-collection-filter"}
                                  onChange={(e) => {
                                    const paths = new Set<string>([]);
                                    (folderView || documentCollection.rootFolder).folders.map((f) =>
                                      checkFolder(f, [documentCollection.name], e.value, paths)
                                    );
                                    setPaths(Array.from(paths));
                                    setFilterValue(e.value);
                                  }}
                                  value={filterValue}
                                />
                              </Box>
                              <Box padding={0} flex="shrink">
                                <Button
                                  iconEnd="star"
                                  text="Collapse"
                                  accessibilityLabel=""
                                  color={"gray"}
                                  onClick={() => {
                                    eventEmitter.emit("collapseAll", {});
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Divider />
                        <Box width={"100%"} overflow="auto" flex="grow">
                          <FolderTreeInstance
                            depth={0}
                            folder={thisFolder}
                            folderPath={emptyArray}
                            resources={resources}
                            selectedFolderPath={folderPath}
                            selectedDocument={currDocument}
                            onFolderSelect={setFolderPath}
                            onSelectDocument={setCurrDocument}
                            filterValue={filterValue}
                            pathList={paths}
                            sortSelectedOptions={sortSelectedOptions}
                            setSortSelectedOptions={SetSortSelectedOptions}
                            documentsDisplay={documentsDisplay}
                            documentCollectionResponseContext={documentCollectionResponseContext}
                            documentSelected={documentSelected}
                            documentSelectedState={documentSelectedState}
                            subscribe={subscribe}
                            isHoverDisabledForSelectedId={false}
                            context={ecmDocument?.data}
                          />
                        </Box>
                      </Box>
                    ) : collectionTabIndex === 1 ? (
                      <CollectionsInstanceLogs instanceId={documentCollection.id} />
                    ) : (
                      collectionTabIndex === 2 && (
                        <Box width={"100%"}>
                          {/* <CollectionUsersToNotify
                        users={usersToNotify ? usersToNotify.users : []}
                        isLoading={isLoadingUsersToNotify}
                      /> */}
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              ) : (
                <Box display="flex" direction="column" padding={1}>
                  <Box display="flex" flex="grow" height={"100%"} />
                </Box>
              )}
            </Box>
          </ResponsiveContainer>

          <Box display="flex" flex="grow" direction="column">
            <PreviewContainer
              currDocument={currDocument}
              ecmDocument={ecmDocument}
              isLoadingDocument={isLoadingDocument}
              onDocumentDelete={refetch}
              initialPanel={"none"}
              isSignatureRequired={false}
              handleShowInstructionTask={undefined}
            />
            {toastMessage && (
              <NotificationMessage
                type={toastMessage.type}
                message={toastMessage.message}
                position="bottom-left"
                handleDismiss={() => setToastMessage(undefined)}
              />
            )}
          </Box>
        </Box>
        <Box>
          <Divider direction="horizontal" />
        </Box>
      </Box>
    </Page>
  );
};

type PreviewContainerProps = {
  isLoadingDocument: boolean;
  ecmDocument: EcmDocument | undefined;
  currDocument: DocumentCollectionDocumentView | undefined;
  onDocumentDelete: () => void;
  initialPanel: DocumentViewerPanelKeys;
  goToLastVersion?: () => void;
  isSignatureRequired: boolean;
  handleShowInstructionTask: (() => void) | undefined;
};

export const PreviewContainer: FunctionComponent<PreviewContainerProps> = ({
  isLoadingDocument,
  ecmDocument,
  currDocument,
  onDocumentDelete,
  initialPanel,
  goToLastVersion,
  isSignatureRequired,
  handleShowInstructionTask,
}) => {
  const { getDocumentTypeInfo } = useServices();
  const [selectedPanel, setSelectedPanel] = useState<DocumentViewerPanelKeys>(initialPanel);

  const getDocumentTypeInfoDetails = useCallback(async () => {
    if (ecmDocument?.documentTypeId) {
      const documentTypeResponse = await getDocumentTypeInfo(ecmDocument.documentTypeId);
      return documentTypeResponse;
    }
    return {
      documentType: undefined,
    };
  }, [ecmDocument, getDocumentTypeInfo]);

  const { data: documentTypeInfo, isLoading: isLoadingDocumentTypeInfo } = useOrganizationQuery(
    `document-type-details/${ecmDocument?.documentTypeId}`,
    getDocumentTypeInfoDetails,
    { refetchOnWindowFocus: false, enabled: typeof ecmDocument?.documentTypeId !== undefined }
  );

  if (isLoadingDocument || isLoadingDocumentTypeInfo) {
    return <Skeleton width={"100%"} height={"100%"} />;
  }
  if (currDocument && ecmDocument) {
    return (
      <Box width={"100%"} height={"100%"} display="flex" flex="grow">
        <DocumentViewerWrapper
          key={ecmDocument.documentId}
          selectedPanel={selectedPanel}
          setSelectedPanel={setSelectedPanel}
          src={ecmDocument.binaries}
          contentType={ecmDocument.mimeType}
          ecmDocument={ecmDocument}
          documentTypeInfo={documentTypeInfo ? documentTypeInfo.documentType : undefined}
          readOnly={true}
          height="100%"
          onDocumentDelete={onDocumentDelete}
          goToLastVersion={goToLastVersion}
          isSignatureRequired={isSignatureRequired}
          handleShowInstructionTask={handleShowInstructionTask}
        />
      </Box>
    );
  }
  return <Box></Box>;
};

export default DocumentCollectionInstancePage;
