import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;

  width?: number;
  height?: number;
};

export const WorkflowTaskSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 50,
  height = 50,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13297_135519)">
        <path
          d="M40.9505 0.480469H3.43047C1.80123 0.480469 0.480469 1.80123 0.480469 3.43047V40.9505C0.480469 42.5797 1.80123 43.9005 3.43047 43.9005H40.9505C42.5797 43.9005 43.9005 42.5797 43.9005 40.9505V3.43047C43.9005 1.80123 42.5797 0.480469 40.9505 0.480469Z"
          fill="#FFD9ED"
        />
        <mask id="mask0_13297_135519" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="44">
          <path
            d="M40.9505 0.480469H3.43047C1.80123 0.480469 0.480469 1.80123 0.480469 3.43047V40.9505C0.480469 42.5797 1.80123 43.9005 3.43047 43.9005H40.9505C42.5797 43.9005 43.9005 42.5797 43.9005 40.9505V3.43047C43.9005 1.80123 42.5797 0.480469 40.9505 0.480469Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_13297_135519)">
          <path d="M43.0406 1.35059H1.35059V43.0406H43.0406V1.35059Z" fill="#FFD9ED" />
          <path d="M43.0406 1.35059H1.35059V43.0406H43.0406V1.35059Z" fill="#FFD9ED" />
        </g>
        <path
          d="M40.65 0.5H3.73C1.94 0.5 0.5 1.95 0.5 3.73V40.65C0.5 42.43 1.94 43.88 3.73 43.88H40.65C42.43 43.88 43.88 42.43 43.88 40.65V3.73C43.88 1.95 42.43 0.5 40.65 0.5Z"
          fill="#F1EEFF"
          stroke="#88559A"
          strokeMiterlimit="10"
        />
        <path
          d="M30.5098 9.38996H27.7698C27.5598 7.60996 26.0598 6.20996 24.2198 6.20996H18.7098C16.7298 6.20996 15.1198 7.81996 15.1198 9.79996C15.1198 11.78 16.7298 13.4 18.7098 13.4H21.0498V19.59L16.9698 21.46C16.8298 21.53 16.7198 21.62 16.6398 21.75H13.8798C10.2198 21.75 7.25977 24.73 7.25977 28.37C7.25977 32.01 10.2298 35 13.8798 35H15.1598C15.3698 36.78 16.8698 38.18 18.7098 38.18H24.2198C26.1998 38.18 27.8098 36.57 27.8098 34.59C27.8098 32.61 26.1998 30.99 24.2198 30.99H21.8698V24.74L25.9498 22.93C26.0998 22.86 26.2098 22.77 26.2998 22.64H30.5198C34.1698 22.64 37.1398 19.66 37.1398 16.02C37.1398 12.38 34.1698 9.38996 30.5198 9.38996H30.5098ZM18.7098 12.58C17.1798 12.58 15.9398 11.33 15.9398 9.79996C15.9398 8.26996 17.1798 7.02996 18.7098 7.02996H24.2198C25.6098 7.02996 26.7498 8.05996 26.9498 9.38996C26.9698 9.52996 26.9898 9.65996 26.9898 9.79996C26.9898 9.93996 26.9698 10.08 26.9498 10.21C26.7498 11.55 25.6098 12.58 24.2198 12.58H18.7098ZM8.06977 28.36C8.06977 25.16 10.6798 22.56 13.8698 22.56H16.5798C16.6598 22.72 16.7898 22.86 16.9598 22.93L20.9998 24.81C20.9998 24.81 21.0198 24.81 21.0398 24.83V30.99H18.6998C16.8598 30.99 15.3598 32.39 15.1498 34.18H13.8698C10.6698 34.18 8.06977 31.57 8.06977 28.37V28.36ZM24.2198 37.35H18.7098C17.3198 37.35 16.1798 36.32 15.9798 34.99C15.9598 34.85 15.9398 34.72 15.9398 34.58C15.9398 34.44 15.9598 34.3 15.9798 34.17C16.1798 32.83 17.3198 31.8 18.7098 31.8H24.2198C25.7498 31.8 26.9898 33.05 26.9898 34.58C26.9898 36.11 25.7498 37.35 24.2198 37.35ZM24.5698 22.64L21.8698 23.84L21.4598 24.02L21.3498 24.07L21.0498 23.93L18.0798 22.56L17.3098 22.2L18.3198 21.74L21.0498 20.49L21.4398 20.31H21.4598L21.8698 20.51L24.8098 21.82L25.6198 22.18L24.5798 22.64H24.5698ZM30.5098 21.82H26.3398C26.2598 21.66 26.1298 21.52 25.9498 21.44L21.8698 19.6V13.4H24.2198C26.0598 13.4 27.5598 12 27.7698 10.21H30.5098C33.7098 10.21 36.3098 12.82 36.3098 16.02C36.3098 19.22 33.6998 21.82 30.5098 21.82Z"
          fill="#88559A"
        />
        <path
          d="M26.9895 34.5798C26.9895 36.1098 25.7495 37.3498 24.2195 37.3498H18.7095C17.3195 37.3498 16.1795 36.3198 15.9795 34.9898C15.9595 34.8498 15.9395 34.7198 15.9395 34.5798C15.9395 34.4398 15.9595 34.2998 15.9795 34.1698C16.1795 32.8298 17.3195 31.7998 18.7095 31.7998H24.2195C25.7495 31.7998 26.9895 33.0498 26.9895 34.5798Z"
          fill="#F1EEFF"
        />
        <path
          d="M26.9895 9.80027C26.9895 9.94027 26.9695 10.0803 26.9495 10.2103C26.7495 11.5503 25.6095 12.5803 24.2195 12.5803H18.7095C17.1795 12.5803 15.9395 11.3303 15.9395 9.80027C15.9395 8.27027 17.1795 7.03027 18.7095 7.03027H24.2195C25.6095 7.03027 26.7495 8.06027 26.9495 9.39027C26.9695 9.53027 26.9895 9.66027 26.9895 9.80027Z"
          fill="#F1EEFF"
        />
        <path
          d="M25.6196 22.1796L24.5696 22.6396L21.8696 23.8396L21.4596 24.0196L21.3496 24.0696L21.0496 23.9296L18.0796 22.5596L17.3096 22.1996L18.3196 21.7396L21.0496 20.4896L21.4396 20.3096L21.4596 20.3196L21.8696 20.5096L24.8096 21.8196L25.6196 22.1796Z"
          fill="#88559A"
        />
      </g>
      <defs>
        <clipPath id="clip0_13297_135519">
          <rect width="44.38" height="44.38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
