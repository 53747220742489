import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const LandingPageBlueCheckIcon: FunctionComponent<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.811"
      height="22.49"
      viewBox="0 0 19.811 22.49"
    >
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#39bdfb" />
          <stop offset="1" stopColor="#2650a3" />
        </linearGradient>
        <clipPath id="b">
          <path
            d="M10.644,0A11.583,11.583,0,0,0,5.329,1.366,9.716,9.716,0,0,0,.357,7.046,5.178,5.178,0,0,0,1.133,12.2c1.212,1.677.464,3.959.377,4.71a4.292,4.292,0,0,0,1.621,4.01,8.2,8.2,0,0,0,8.568.664c2.318-1.39,2.388-3.738,3.134-4.6,1.012-1.174,4.945-1.328,4.978-4.973,0-.008,0-.015,0-.023v-.01C19.805,8.785,14.9,6.772,14.37,5.6a3.962,3.962,0,0,1,.12-2.461A2.275,2.275,0,0,0,13.837.81,5.2,5.2,0,0,0,10.679,0Z"
            transform="translate(0 -0.001)"
            fill="url(#a)"
          />
        </clipPath>
      </defs>
      <g transform="translate(0 0)">
        <g transform="translate(0 0)" clipPath="url(#b)">
          <rect width="20.118" height="22.991" transform="translate(-0.307 0)" fill="url(#a)" />
        </g>
      </g>
      <text
        transform="translate(8.958 16.213)"
        fill="#fafafa"
        fontSize="11"
        fontFamily="FontAwesome6Free-Solid"
      >
        <tspan x="-4.813" y="0">
          🗸
        </tspan>
      </text>
    </svg>
  );
};
