import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const DoubleColumnLayoutIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 11C31 11.5523 30.5523 12 30 12H12C11.4477 12 11 11.5523 11 11C11 10.4477 11.4477 10 12 10H30C30.5523 10 31 10.4477 31 11ZM31 29C31 29.5523 30.5523 30 30 30H12C11.4477 30 11 29.5523 11 29C11 28.4477 11.4477 28 12 28H30C30.5523 28 31 28.4477 31 29Z"
        fill={color ? calculateColor(color) : "#CACBCD"}
      />
      <path
        d="M22 16V24C22 24.5523 22.4477 25 23 25H26C26.5523 25 27 24.5523 27 24V16C27 15.4477 26.5523 15 26 15H23C22.4477 15 22 15.4477 22 16Z"
        fill={color ? calculateColor(color) : "#CACBCD"}
      />
      <path
        d="M15 16V24C15 24.5523 15.4477 25 16 25H19C19.5523 25 20 24.5523 20 24V16C20 15.4477 19.5523 15 19 15H16C15.4477 15 15 15.4477 15 16Z"
        fill={color ? calculateColor(color) : "#CACBCD"}
      />
    </svg>
  );
};
