import { Box, TapArea, Text, useColors } from "@prodoctivity/design-system";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { LogoSvg } from "../../svg/LogoSvg";
import { FooterLink } from "./FooterLink";
import { LanguageSwitcher } from "./LanguageSwitcher";

export function LoginLayoutFooterLarge() {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const navigate = useNavigate();

  const goHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Box display="flex" direction="column" flex="shrink" width={"100%"}>
      <Box display="flex" direction="column" alignItems="center" wrap={false}>
        <Box display="flex" width={"90%"} height={1} color={colors.neutral700}></Box>
        <Box width={"95%"} paddingY={2} display="flex" justifyContent="center">
          <Box display="flex" direction="column" gap={3}>
            <Box display="flex" justifyContent="between" width="100%" alignItems="center">
              <FooterLink />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Box display="flex" flex="shrink">
                <TapArea onTap={goHome}>
                  <LogoSvg width={50} height={28} />
                </TapArea>
              </Box>
              <Box display="flex" flex="shrink">
                <Text color={colors.neutral900} size="200">
                  © {new Date().getFullYear()} {resources.allRightsReserved}
                </Text>
              </Box>
              <LanguageSwitcher />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
