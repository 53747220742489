import { Text, type TextSize } from "@prodoctivity/design-system";
import { organizationLinkTemplates } from "../../link-templates";
import { OrganizationLink } from "../OrganizationLink";
import { useDocumentTypeInfoWithLatestVersion, useDocumentTypeVersionInfo } from "./hooks";

type DocumentTypeInfoProps = {
  documentTypeId: string;
  skipLink: boolean;
  size?: TextSize;
};

export const DocumentTypeInfo: React.FC<DocumentTypeInfoProps> = ({
  documentTypeId,
  skipLink,
  size = "200",
}) => {
  const { data, isLoading } = useDocumentTypeInfoWithLatestVersion(documentTypeId, true);

  const documentTypeInfo = data ? data.documentType : undefined;

  if (isLoading || !documentTypeInfo) {
    return <Text size={size}>...</Text>;
  }

  if (skipLink) {
    return (
      <Text size="300" ellipsisLength={40}>
        {documentTypeInfo.name}
      </Text>
    );
  }

  return (
    <OrganizationLink
      to={organizationLinkTemplates.browseDocuments({
        documentTypeIdList: [documentTypeInfo.documentTypeId],
        dateFrom: undefined,
        dateTo: undefined,
        pageNumber: 0,
        rowsPerPage: "30",
        sortDirection: undefined,
        sortField: undefined,
        updatedBy: undefined,
      })}
    >
      <Text size="300" ellipsisLength={40}>
        {documentTypeInfo.name}
      </Text>
    </OrganizationLink>
  );
};

type DocumentTypeVersionInfoProps = {
  documentTypeId: string;
  documentTypeVersionId: string;
  skipLink: boolean;
  size?: TextSize;
};

export const DocumentTypeVersionInfo: React.FC<DocumentTypeVersionInfoProps> = ({
  documentTypeId,
  documentTypeVersionId,
  skipLink,
  size = "200",
}) => {
  const { data, isLoading } = useDocumentTypeVersionInfo(
    documentTypeId,
    documentTypeVersionId,
    true
  );

  const documentTypeInfo = data ? data.documentType : undefined;

  if (isLoading || !documentTypeInfo) {
    return <Text size={size}>...</Text>;
  }

  if (skipLink) {
    return (
      <Text size="300" ellipsisLength={40}>
        {documentTypeInfo.name}
      </Text>
    );
  }

  return (
    <OrganizationLink
      to={organizationLinkTemplates.browseDocuments({
        documentTypeIdList: [documentTypeInfo.documentTypeId],
        dateFrom: undefined,
        dateTo: undefined,
        pageNumber: 0,
        rowsPerPage: "30",
        sortDirection: undefined,
        sortField: undefined,
        updatedBy: undefined,
      })}
    >
      <Text size="300" ellipsisLength={40}>
        {documentTypeInfo.name}
      </Text>
    </OrganizationLink>
  );
};
