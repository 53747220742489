import { Box, MarkdownViewer, Text, useColors } from "@prodoctivity/design-system";
import { Link } from "react-router-dom";
import { TermsAndConditionsPolicySvg } from "../../svg/TermsAndConditionsPolicySvg";
import { VisitorsFooter } from "../LandingPage/VisitorsFooter";
import { VisitorsHeader } from "../LandingPage/VisitorsHeader";
import { useLandingPageLogic } from "../LandingPage/hooks";

export function TermsOfServicePrivacy() {
  const { resources, breakPointSmallDuo, breakpoint } = useLandingPageLogic();
  const { colors } = useColors();
  return (
    <Box color={colors.neutral300}>
      <VisitorsHeader />
      <Box display="flex" justifyContent="center">
        <Box
          width={"75%"}
          display="flex"
          alignItems="center"
          gap={2}
          direction="column"
          marginBottom={8}
        >
          <Box marginTop={8}>
            <TermsAndConditionsPolicySvg width={600} height={300} />
          </Box>
          <Box display="flex" gap={5} marginTop={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.termsAndPrivacy}
            </Text>
          </Box>
          <Box>
            <Text size="300">{resources.termsOfServicePrivacyPage.termsPrivacyDataProtection}</Text>
          </Box>
          {/* <Box display="flex" gap={5} marginBottom={2}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.termsOfServicePrivacy}
            </Text>
          </Box> */}
          {/* <Text size="200">
            {resources.termsOfServicePrivacyPage.updatedMay} ({" "}
            {resources.termsOfServicePrivacyPage.reviewThePreviousVersion}{" "}
            <Link to="#">{resources.termsOfServicePrivacyPage.here}</Link> )
          </Text> */}
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.welcomeToProDoctivity}
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.termsOfServiceDescription}</Text>
          <Text size="400"> {resources.termsOfServicePrivacyPage.additionalServiceTerms}</Text>
          <Text size="400"> {resources.termsOfServicePrivacyPage.arbitrationNotice}</Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.accessAndUseOfTheService}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.servicesDescription}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.yourRegistrationObligations}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.memberAccountPasswordAndSecurity}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.modificationsToService}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={
                resources.termsOfServicePrivacyPage.generalPracticesRegardingUseAndStorage
              }
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.mobileServices}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.conditionsOfUse}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.proDoctivityPlatform}
              size="xs"
            />
          </Text>
          <Text size="400">
            {" "}
            {resources.termsOfServicePrivacyPage.professionalAdviceDisclaimer}
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.userConduct}
              size="xs"
            />
          </Text>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer
                markdownText={resources.termsOfServicePrivacyPage.prohibitedActivities}
                size="xs"
              />
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.contentDisclaimer}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer markdownText={resources.termsOfServicePrivacyPage.fees} size="xs" />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.chargebacks}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={
                resources.termsOfServicePrivacyPage.authorizationToChargePaymentMethodsOnFile
              }
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer markdownText={resources.termsOfServicePrivacyPage.reserve} size="xs" />
          </Text>
          <Text size="400">
            <MarkdownViewer markdownText={resources.termsOfServicePrivacyPage.setoff} size="xs" />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.powerOfAttorney}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.dueDiligence}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={
                resources.termsOfServicePrivacyPage.specialNoticeForInternationalUseExportControls
              }
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.commercialUse}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.thirdPartyDistributionChannels}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.softwareDistribution}
              size="xs"
            />
          </Text>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer
                markdownText={resources.termsOfServicePrivacyPage.appleTerms}
                size="xs"
              />
              <Text size="400">
                • <Link to="#">concierge@ProDoctivity.com </Link>
              </Text>
              <Text>Calle Pedro A. Bobea no. 1, Santo Domingo, Dominican Republic.</Text>
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.appleThirdPartyBeneficiary}</Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.intellectualPropertyRights}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.serviceContentSoftwareAndTrademarks}
              size="xs"
            />
          </Text>
          <Text size="400">{resources.termsOfServicePrivacyPage.proDoctivityTrademarks}</Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.thirdPartyMaterial}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={
                resources.termsOfServicePrivacyPage.userContentTransmittedThroughTheService
              }
              size="xs"
            />
          </Text>
          <Text size="400"> {resources.termsOfServicePrivacyPage.submissionTerms}</Text>
          <Text size="400"> {resources.termsOfServicePrivacyPage.preservationDisclosureTerms}</Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.publicity}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.copyrightComplaints}
              size="xs"
            />
          </Text>
          <Box>
            <Text size="400">
              {" "}
              {resources.termsOfServicePrivacyPage.dmcaNotice}{" "}
              <Link to="#"> copyright@ProDoctivity.com </Link>{" "}
              {resources.termsOfServicePrivacyPage.dmcaSubjectLine}
            </Text>
          </Box>
          <Text size="400">539 Bryant St, San Francisco, CA 94107.</Text>
          <Text size="400"> {resources.termsOfServicePrivacyPage.dmcaNotificationIntro}</Text>
          <Box paddingLeft={5}>
            <Text size="400">
              <MarkdownViewer
                markdownText={resources.termsOfServicePrivacyPage.dmcaNotificationInfo}
                size="xs"
              />
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.repeatInfringerPolicy}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.thirdPartyWebsites}
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.thirdPartyLinks}</Text>
          <Box display="flex" gap={5} marginTop={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.socialNetworkingServices}
            </Text>
          </Box>
          <Box>
            <Text inline size="400">
              {" "}
              {resources.termsOfServicePrivacyPage.thirdPartyServices}{" "}
              <Link to="/privacy-policy"> ProDoctivity</Link>{" "}
              {resources.termsOfServicePrivacyPage.thirdPartyPrivacy}
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.socialNetworkingDisclaimer}</Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.indemnityAndRelease}
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.releaseIndemnification}</Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.disclaimerOfWarranties}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.serviceDisclaimer}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.limitationOfLiability}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.liabilityDisclaimer}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.disputeResolutionByBindingArbitration}
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.importantNotice}</Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.agreementToArbitrate}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.prohibitionOfClassActions}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.preArbitrationDisputeResolution}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.arbitrationProcedures}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.costsOfArbitration}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.confidentiality}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.severability}
              size="xs"
            />
          </Text>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.futureChanges}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.termination}
            </Text>
          </Box>
          <Text size="400">
            <MarkdownViewer
              markdownText={resources.termsOfServicePrivacyPage.terminationOfAccount}
              size="xs"
            />
          </Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.userDisputes}
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.interactionsWithOtherUsers}</Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              13. {resources.general}
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.termsOfService}</Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.yourPrivacy}
            </Text>
          </Box>
          <Text size="400"> {resources.termsOfServicePrivacyPage.privacyPolicy}</Text>
          <Box display="flex" gap={5} marginBottom={5}>
            <Text size="500" weight="bold">
              {resources.termsOfServicePrivacyPage.questionsConcernsSuggestions}
            </Text>
          </Box>
          <Box>
            <Text size="400">
              {" "}
              {resources.termsOfServicePrivacyPage.pleaseContactUsAt}{" "}
              <Link to="#"> concierge@ProDoctivity.com </Link>{" "}
              {resources.termsOfServicePrivacyPage.reportViolationsOrQuestions}
            </Text>
          </Box>
        </Box>
      </Box>
      <VisitorsFooter breakPointSmallDuo={breakPointSmallDuo} breakpoints={breakpoint} />
    </Box>
  );
}
