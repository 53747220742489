import {
  Box,
  Icon,
  StringTemplateBuilder,
  StringTemplateBuilderEventManager,
  Text,
  Tooltip,
  useColors,
} from "@prodoctivity/design-system";
import type { DataType, StringTemplatePart } from "@prodoctivity/shared/src/index-types";
import { FunctionComponent, useCallback, useMemo } from "react";

import type { DistributionParameter } from "@prodoctivity/types";
import { shouldNever } from "@prodoctivity/shared";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type Props = {
  param: DistributionParameter;
  eventManager: StringTemplateBuilderEventManager;
  onParameterUpdated(paramInfo: { parameterKey: string; parts: StringTemplatePart[] }): void;
  onPartClicked(args: { lineIndex: number; partIndex: number; part: StringTemplatePart }): void;
};

export const DistributionInputParameterConfig: FunctionComponent<Props> = ({
  param,
  eventManager,
  onParameterUpdated,
  onPartClicked,
}) => {
  const { resources } = useAppTranslation();
  const { colors } = useColors();
  const onChange = useCallback(
    (args: { parts: StringTemplatePart[] }) => {
      onParameterUpdated({
        parameterKey: param.key,
        parts: args.parts,
      });
    },
    [onParameterUpdated, param.key]
  );
  const handlePartClicked = useCallback(
    (args: { lineIndex: number; partIndex: number; part: StringTemplatePart }) => {
      onPartClicked({
        lineIndex: args.lineIndex,
        partIndex: args.partIndex,
        part: args.part,
      });
    },
    [onPartClicked]
  );
  const handleVariableAdded = useCallback(
    (args: { lineIndex: number; partIndex: number; name: string; dataType: DataType }) => {
      console.log("Variable added ", args.name);
    },
    []
  );
  const initialState = useMemo(() => {
    return {
      parts: getStringTemplateBuilderParts(param),
    };
  }, [param]);

  const getNewVariableData: () => { name: string; dataType: DataType } = useCallback(() => {
    return {
      name: "name",
      dataType: "Alphanumeric",
    };
  }, []);

  const getFieldLabel = useCallback((fldName: string) => fldName, []);

  return (
    <Box>
      {/* Section Title Start */}
      <Box padding={3} display="flex" direction="row" alignItems="center">
        <Box>
          <Text size="200">{param.key.toUpperCase()}</Text>
        </Box>
        <Box marginStart={2}>
          <Tooltip text={param.key}>
            {/*TODO: Add a description to this tooltip for every parameter*/}
            <Icon
              accessibilityLabel={`${param.key} description`}
              icon="circle-info"
              size={"xs"}
              color={colors.subtle}
            />
          </Tooltip>
        </Box>
      </Box>
      {/* Section Title End */}

      {/* String Builder Start */}
      <Box>
        <Box paddingX={2} paddingY={1}>
          <StringTemplateBuilder
            eventManager={eventManager}
            onChange={onChange}
            initialState={initialState}
            onPartClicked={handlePartClicked}
            onVariableAdded={handleVariableAdded}
            getNewVariableData={getNewVariableData}
            getFieldLabel={getFieldLabel}
            warningLabel={resources.documentCollection.YouHaveRepeatedVariables}
          />
        </Box>
      </Box>
      {/* String Builder End */}
      <Box height={1} marginTop={3} marginBottom={3} color={colors.neutral500} />
    </Box>
  );
};

function getStringTemplateBuilderParts(param: DistributionParameter) {
  const parts: StringTemplatePart[] = [];

  for (const val of param.template.values) {
    switch (val.source) {
      case "fixed":
        parts.push({ type: "text", value: val.value });
        break;
      case "generation-result":
        parts.push({ type: "variable", dataType: "Alphanumeric", name: "Generation Result" });
        break;
      case "context":
      case "global-parameter":
      case "instance-parameter":
      case "env":
      case "distribution-result":
        parts.push({ type: "variable", dataType: "Alphanumeric", name: val.value });
        break;
      default:
        shouldNever(val);
        break;
    }
  }
  return parts;
}
