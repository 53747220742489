import { useCallback, useReducer } from "react";

import { shouldNever } from "@prodoctivity/shared";

type PaginationState<RPP> = {
  currentPage: number;
  rowsPerPage: RPP;
  requestedPageLength: number;
  pageLength: number;
  pageCount?: number;
};

type PaginationMsg<RPP> =
  | {
      type: "set-rows-per-page";
      value: RPP;
    }
  | {
      type: "set-current-page";
      value: number;
    }
  | {
      type: "set-page-data";
      requestedPageLength: number;
      pageLength: number;
      pageCount?: number;
    };

function paginationReducer<RPP>(
  prev: PaginationState<RPP>,
  msg: PaginationMsg<RPP>
): PaginationState<RPP> {
  switch (msg.type) {
    case "set-rows-per-page":
      if (msg.value !== prev.rowsPerPage) {
        return {
          ...prev,
          rowsPerPage: msg.value,
          currentPage: 0,
        };
      }
      return prev;
    case "set-current-page":
      if (msg.value < 0) {
        return prev;
      }
      return {
        ...prev,
        currentPage: msg.value,
      };
    case "set-page-data":
      return {
        ...prev,
        requestedPageLength: msg.requestedPageLength,
        pageLength: msg.pageLength,
        pageCount: msg.pageCount,
      };
    default:
      shouldNever(msg);
      return prev;
  }
}

export type PaginationInitialStateFn<RPP> = () => PaginationState<RPP>;

/**
 * Helper hook to enable pagination through the <Pagination> component
 *
 * @export
 * @template RPP dataType for "rowsPerPage" option. eg: "10" | "20" | "50"
 * @param {PaginationState<RPP>} initialState
 * @return {*}
 */
export function usePagination<RPP>(
  initialState: Pick<PaginationState<RPP>, "currentPage" | "rowsPerPage">
) {
  const [state, dispatch] = useReducer<typeof paginationReducer<RPP>>(paginationReducer, {
    ...initialState,
    requestedPageLength: 1,
    pageLength: 1,
    pageCount: 1,
  });

  const setPageData = useCallback(
    (requestedPageLength: number, pageLength: number, pageCount?: number) => {
      dispatch({
        type: "set-page-data",
        requestedPageLength,
        pageLength,
        pageCount,
      });
    },
    []
  );

  const isNextButtonDisabled = state.requestedPageLength !== state.pageLength;
  const isPreviousButtonDisabled = state.currentPage < 1;

  return {
    currentPage: state.currentPage,
    rowsPerPage: state.rowsPerPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    setPageData,
    paginationDispatch: dispatch,
  };
}
