import { Box, Divider, TapArea, Text, useColors } from "@prodoctivity/design-system";
import { useCallback, useMemo } from "react";

import type { ListableTemplateWithCombined } from "@prodoctivity/types";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { CombinedTemplateSvgIcon } from "../../svg/CombinedTemplateSvgIcon";

type Props = {
  template: ListableTemplateWithCombined;
  onClick: (type: ListableTemplateWithCombined["type"], id: string) => void;
};

export function TemplateCard({ onClick, template }: Props) {
  const { colors } = useColors();
  const { baseUrl, token, fetchTemplateListWithoutPagination } = useServices();

  const id = template.type === "template" ? template.templateVersionId : template.id;

  const fetchTemplateList = useCallback(() => {
    if (template.type === "combined-template") {
      return fetchTemplateListWithoutPagination(template.documentGroupId);
    }
    return {
      templates: [],
    };
  }, [fetchTemplateListWithoutPagination, template]);

  const { data: templateListResponse } = useOrganizationQuery(
    `/templates/all?documentGroupId=${
      template.type === "combined-template" ? template.documentGroupId : undefined
    }`,
    fetchTemplateList
  );

  const templateVersionIds = useMemo(() => {
    if (template.type === "template" || !templateListResponse) {
      return [];
    }

    return templateListResponse.templates
      .filter((t) => template.templates.includes(t.templateId))
      .map((t) => t.templateVersionId)
      .slice(0, 1);
  }, [template, templateListResponse]);

  return (
    <TapArea onTap={() => onClick(template.type, id)}>
      <Box display="flex" direction="column">
        <Box color={colors.white} minHeight="225px">
          {template.type === "template" ? (
            <img
              style={{ width: "100%", height: "230px", objectFit: "cover", objectPosition: "top" }}
              src={`${baseUrl}/app/template-versions/${id}/thumbnail?token=${encodeURIComponent(
                token || ""
              )}`}
              alt={template.name}
            />
          ) : (
            <>
              {templateVersionIds.map((templateVersionId, index) => (
                <img
                  key={index}
                  style={{
                    width: "100%",
                    height: "230px",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  src={`${baseUrl}/app/template-versions/${templateVersionId}/thumbnail?token=${encodeURIComponent(
                    token || ""
                  )}`}
                  alt={template.name}
                />
              ))}
            </>
          )}
        </Box>
        <Box padding={2} height={169} flex="grow">
          <Text size="200" color={colors.neutral900} overflow="ellipsis">
            {template.documentGroupName}
          </Text>
          <Divider />
          <Box margin={2} />
          <Box display="flex" justifyContent="between">
            <Box display="flex" direction="column" fit={true}>
              <Text
                weight="bold"
                color={colors.primary700}
                size="200"
                title={template.name}
                wrap={true}
              >
                {template.name}
              </Text>
              <Box margin={2} />
              <Box display="flex" wrap={true}>
                <Text
                  lineClamp={5}
                  size="200"
                  title={template.description}
                  overflow="ellipsis"
                  inline={true}
                  wrap={true}
                >
                  {template.description}
                </Text>
              </Box>
            </Box>
            {template.type === "combined-template" && (
              <Box marginTop={2} marginEnd={2}>
                <CombinedTemplateSvgIcon width={27} color={colors.subtle} />{" "}
              </Box>
            )}
          </Box>
        </Box>
        <Box margin={1} />
      </Box>
    </TapArea>
  );
}
