import { TextField as GestaltTextField, TextFieldProps as RawTextFieldProps } from "gestalt";
import { Ref, forwardRef } from "react";

export type TextFieldProps = RawTextFieldProps & {
  mode?: "default" | "unstyled" | "summary";
  maxLength?: { characterCount: number; errorAccessibilityLabel: string };
  fullWith?: boolean;
};
export const TextField = forwardRef(
  (
    { mode = "default", fullWith = false, ...props }: TextFieldProps,
    ref?: Ref<HTMLInputElement>
  ) => {
    if (mode === "unstyled") {
      return (
        <div
          className={"prodoctivity-unstyled-field"}
          style={{ width: fullWith ? "100%" : undefined }}
        >
          <GestaltTextField ref={ref} {...props} />
        </div>
      );
    }

    return <GestaltTextField ref={ref} {...props} />;
  }
);
