import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const SaveLogSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 25,
  height = 23,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
    cursor: onClick ? "pointer" : undefined,
  };
  return (
    <svg
      className={customClassName}
      style={svgStyle}
      width={width}
      height={height}
      viewBox={`0 0 25 23`}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8905_142676)">
        <path
          d="M12.5834 6.20265H17.4308C17.5777 6.20265 17.6907 6.09125 17.702 5.94642C17.702 5.47851 17.4986 5.04403 17.137 4.74324L13.0579 1.38992C12.7528 1.14483 12.3574 1 11.9619 1C11.7472 1 11.5664 1.17825 11.5664 1.38992V5.2C11.5664 5.74589 12.0184 6.20265 12.5834 6.20265Z"
          fill="#7F7F7F"
        />
        <path
          d="M17.6921 7.5618V12.6642C16.3136 13.0541 15.3192 14.3019 15.3192 15.7836C15.3192 16.3406 15.4661 16.8642 15.7147 17.3098C14.0876 17.8 12.5847 18.7024 12.5847 20.095V22H3.20621C2.26836 22 1.5 21.2536 1.5 20.3289V2.68223C1.5 1.75756 2.26836 1 3.20621 1H10.178V5.2C10.178 6.50345 11.2514 7.5618 12.5847 7.5618H17.6921Z"
          fill="#7F7F7F"
        />
        <path
          d="M10.6633 10.6816H4.32431C3.91867 10.6816 3.58984 11.0034 3.58984 11.4002C3.58984 11.7971 3.91867 12.1188 4.32431 12.1188H10.6633C11.0689 12.1188 11.3978 11.7971 11.3978 11.4002C11.3978 11.0034 11.0689 10.6816 10.6633 10.6816Z"
          fill="white"
        />
        <path
          d="M10.6633 13.5225H4.32431C3.91867 13.5225 3.58984 13.8442 3.58984 14.241C3.58984 14.6379 3.91867 14.9596 4.32431 14.9596H10.6633C11.0689 14.9596 11.3978 14.6379 11.3978 14.241C11.3978 13.8442 11.0689 13.5225 10.6633 13.5225Z"
          fill="white"
        />
        <path
          d="M6.76499 16.1738H4.32431C3.91867 16.1738 3.58984 16.4955 3.58984 16.8924C3.58984 17.2893 3.91867 17.611 4.32431 17.611H6.76499C7.17062 17.611 7.49945 17.2893 7.49945 16.8924C7.49945 16.4955 7.17062 16.1738 6.76499 16.1738Z"
          fill="white"
        />
        <path
          d="M7.35227 3.66309H4.7308C4.0381 3.66309 3.47656 4.21673 3.47656 4.89969V7.48431C3.47656 8.16726 4.0381 8.72091 4.7308 8.72091H7.35227C8.04497 8.72091 8.60651 8.16726 8.60651 7.48431V4.89969C8.60651 4.21673 8.04497 3.66309 7.35227 3.66309Z"
          fill="white"
        />
        <path
          d="M5.6126 7.71819C5.477 7.71819 5.35271 7.66249 5.25102 7.57337L4.44876 6.78238C4.25666 6.59299 4.25666 6.26992 4.44876 6.08053C4.64085 5.89114 4.96853 5.89114 5.16062 6.08053L5.6013 6.51501L6.95723 5.167C7.14932 4.96647 7.477 4.96647 7.66909 5.167C7.87248 5.35639 7.87248 5.67947 7.66909 5.86886L5.95158 7.57337C5.86118 7.66249 5.72559 7.71819 5.59 7.71819H5.6126Z"
          fill="#7F7F7F"
        />
        <path
          d="M23.5002 20.095V20.886H13.7148V20.095C13.7148 19.3041 15.0369 18.6245 16.4832 18.2568C16.8335 18.5465 17.2403 18.7693 17.6922 18.903C17.986 18.981 18.2911 19.0255 18.6075 19.0255C19.4098 19.0255 20.1555 18.7359 20.7318 18.2568C22.1781 18.6245 23.5002 19.3041 23.5002 20.095Z"
          fill="#7F7F7F"
        />
        <path
          d="M20.7656 15.7724C20.7656 16.2403 20.6187 16.6748 20.3475 17.0313C20.2232 17.2207 20.0537 17.3766 19.8616 17.5103C19.5114 17.7665 19.082 17.9114 18.6074 17.9114C18.2797 17.9114 17.9746 17.8445 17.6922 17.6997C17.5792 17.6551 17.4662 17.5883 17.3645 17.5103C17.1724 17.3766 17.0029 17.2207 16.8673 17.0313C16.6074 16.6859 16.4492 16.2514 16.4492 15.7724C16.4492 14.9257 16.9577 14.1793 17.6922 13.8451C17.9746 13.7225 18.2797 13.6445 18.6074 13.6445C19.8052 13.6445 20.7656 14.6026 20.7656 15.7724Z"
          fill="#7F7F7F"
        />
      </g>
      <defs>
        <clipPath id="clip0_8905_142676">
          <rect width="22" height="21" fill="white" transform="translate(1.5 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};
