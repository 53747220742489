import { FunctionComponent, ReactNode } from "react";
import { Box } from "../Box";
import { useColors } from "../ColorSchemeProvider";
import { Text } from "../Text";
import { FancyDateTime } from "../FancyDateTime";
import type momentType from "moment";
import type { TaskComment } from "@prodoctivity/shared/src/index-types";

export type PublicAvatarImageProps = {
  username: string;
  size: 32 | 48 | 62;
};

type Props = {
  onAddComment: (arg: {
    taskId: string;
    processId: string;
    content: string;
    parentCommentId: string | undefined;
    commentSubtitle: string | undefined;
  }) => void;
  onCommentUpdate: (arg: { content: string; taskCommentId: string }) => void;
  taskSelected: {
    taskId: string;
    processId: string;
  };
  myPublicAvatarImageComponent: ReactNode;
  OtherUsersPublicAvatarImage: FunctionComponent<PublicAvatarImageProps>;
  PublicProfileComponent: FunctionComponent<{
    username: string;
  }>;
  resources: {
    _timeFormat: string;
    _dateTimeFormatShort: string;
    _dateFormatShort: string;
    addComment: string;
    comments: string;
    noComments: string;
  };
  moment: typeof momentType;
} & {
  data: Array<TaskComment & { commentId: string }> | undefined;
};

export const NestedCommentRow: FunctionComponent<Props> = ({
  data,
  PublicProfileComponent,
  OtherUsersPublicAvatarImage,
  resources,
  moment,
}) => {
  const { colors } = useColors();
  const newData = data?.map((comment) => {
    return {
      ...comment,
      childrenComments: data?.filter(
        (childComment) => childComment.parentCommentId === comment.commentId
      ),
    };
  });

  return (
    <Box display="flex" direction="column" paddingLeft={6} paddingBottom={4} gap={3} width={"100%"}>
      {newData === undefined || newData.length === 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Text size="200" color={colors.neutral900}>
            {resources.noComments}
          </Text>
        </Box>
      ) : (
        newData &&
        newData.map((comment, idx) => {
          return (
            <Box key={`${idx}-${comment.createdAt}-${comment.content}`} width={"100%"}>
              <Box display="flex" flex="grow" gap={4} paddingLeft={2}>
                <Box
                  rounding={"circle"}
                  borderRadius={48}
                  borderColor={colors.primary}
                  height={"fit-content"}
                >
                  <OtherUsersPublicAvatarImage username={comment.username} size={32} />
                </Box>
                <Box display="flex" direction="column" width={"100%"}>
                  <Box display="flex" gap={3} marginBottom={2}>
                    <PublicProfileComponent username={comment.username} />
                    <Text size="200" color={colors.neutral900}>
                      •
                    </Text>
                    <FancyDateTime
                      moment={moment}
                      resources={resources}
                      value={comment.createdAt}
                      size="200"
                      color={colors.neutral900}
                    />
                  </Box>
                  <Box display="flex" flex="grow" marginBottom={4}>
                    <Text size="200" color={colors.neutral900} inline lineClamp={2}>
                      {comment.content}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })
      )}
    </Box>
  );
};
