import { Box, Spinner } from "@prodoctivity/design-system";

import { useLocation } from "react-router-dom";
import { Page } from "../../components/Layout/Page";
import { OrganizationNavigate } from "../../components/OrganizationNavigate";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";
import { PageNotFound } from "./PageNotFound";
import { useGetDocumentLatestVersion } from "./hooks";
import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";

type Props = {
  defaultPanel: DocumentViewerPanelKeys | undefined;
};

function DocumentViewerLookupVersionPage({ defaultPanel }: Props) {
  const { documentResponse, isLoading } = useGetDocumentLatestVersion(undefined);
  const { state } = useLocation();
  const { resources } = useAppTranslation();

  const ecmDocument = documentResponse?.document;

  if (isLoading) {
    return (
      <Page>
        <Box justifyContent="center">
          <Spinner accessibilityLabel={resources.loading} show={isLoading} />
        </Box>
      </Page>
    );
  }

  if (!ecmDocument) {
    return (
      <Page>
        <PageNotFound message={resources.pageNotFound.documentNotFound} />
      </Page>
    );
  }

  return (
    <OrganizationNavigate
      to={organizationLinkTemplates.documentIdAndVersion(
        ecmDocument.documentId,
        ecmDocument.documentVersionId,
        defaultPanel
      )}
      state={state}
      replace={true}
    />
  );
}

export default DocumentViewerLookupVersionPage;
