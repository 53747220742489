import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const StatusFailureSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 48.98"
    >
      <g transform="translate(0 0)">
        <rect width="50" height="48.98" rx="24.49" fill="#ffc8cb" />
        <path
          d="M5.265,10.719-.857.514.857-.514,6.98,9.69Z"
          transform="translate(11.735 19.898)"
          fill="#ee3636"
        />
        <path
          d="M6.472,18.516-.926.378.926-.378l7.4,18.138Z"
          transform="translate(30.102 19.268)"
          fill="#ee3636"
        />
        <path
          d="M.857,10.719-.857,9.69,5.265-.514,6.98.514Z"
          transform="translate(20.918 19.898)"
          fill="#ee3636"
        />
        <path
          d="M0,3.048,5.118,5.277,5.833,0"
          transform="translate(33.651 32.74) rotate(7)"
          fill="#ee3636"
        />
        <path
          d="M5.118,6.527a1.249,1.249,0,0,1-.5-.1L-.5,4.194a1.25,1.25,0,0,1-.647-1.645A1.25,1.25,0,0,1,.5,1.9L4.1,3.471,4.595-.168A1.25,1.25,0,0,1,6-1.239,1.25,1.25,0,0,1,7.072.168L6.357,5.445A1.25,1.25,0,0,1,5.118,6.527Z"
          transform="translate(33.651 32.74) rotate(7)"
          fill="#ee3636"
        />
        <circle
          cx="3.333"
          cy="3.333"
          r="3.333"
          transform="translate(7.143 13.742)"
          fill="#ee3636"
        />
        <path
          d="M3.333,1A2.333,2.333,0,1,0,5.667,3.333,2.336,2.336,0,0,0,3.333,1m0-1A3.333,3.333,0,1,1,0,3.333,3.333,3.333,0,0,1,3.333,0Z"
          transform="translate(7.143 13.742)"
          fill="#ee3636"
        />
        <circle
          cx="4.167"
          cy="4.167"
          r="4.167"
          transform="translate(15.816 27.381)"
          fill="#ee3636"
        />
        <path
          d="M4.167,1A3.167,3.167,0,1,0,7.333,4.167,3.17,3.17,0,0,0,4.167,1m0-1A4.167,4.167,0,1,1,0,4.167,4.167,4.167,0,0,1,4.167,0Z"
          transform="translate(15.816 27.381)"
          fill="#ee3636"
        />
        <circle cx="5" cy="5" r="5" transform="translate(24.49 11.429)" fill="#ee3636" />
        <path
          d="M5,1A4,4,0,1,0,9,5,4,4,0,0,0,5,1M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"
          transform="translate(24.49 11.429)"
          fill="#ee3636"
        />
      </g>
    </svg>
  );
};
