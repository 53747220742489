import { ColorConfig, calculateColor } from "../utils";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const SearchSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 32,
  height = 32,
  onClick,
}) => {
  return (
    <svg
      stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 32 32`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M20.1022 14.3596C20.1022 12.7784 19.5403 11.4259 18.4163 10.3019C17.2924 9.178 15.9398 8.61604 14.3586 8.61604C12.7775 8.61604 11.4249 9.178 10.301 10.3019C9.17702 11.4259 8.61506 12.7784 8.61506 14.3596C8.61506 15.9408 9.17702 17.2934 10.301 18.4173C11.4249 19.5412 12.7775 20.1032 14.3586 20.1032C15.9398 20.1032 17.2924 19.5412 18.4163 18.4173C19.5403 17.2934 20.1022 15.9408 20.1022 14.3596ZM26.6663 25.0263C26.6663 25.4707 26.5039 25.8554 26.1792 26.1801C25.8544 26.5049 25.4698 26.6673 25.0253 26.6673C24.5638 26.6673 24.1792 26.5049 23.8715 26.1801L19.474 21.7955C17.9441 22.8554 16.239 23.3853 14.3586 23.3853C13.1364 23.3853 11.9676 23.1481 10.8522 22.6737C9.73685 22.1994 8.77532 21.5583 7.96762 20.7507C7.15993 19.943 6.51891 18.9814 6.04455 17.866C5.57019 16.7507 5.33301 15.5818 5.33301 14.3596C5.33301 13.1374 5.57019 11.9686 6.04455 10.8532C6.51891 9.73783 7.15993 8.77629 7.96762 7.9686C8.77532 7.16091 9.73685 6.51988 10.8522 6.04552C11.9676 5.57116 13.1364 5.33398 14.3586 5.33398C15.5809 5.33398 16.7497 5.57116 17.8651 6.04552C18.9804 6.51988 19.942 7.16091 20.7497 7.9686C21.5574 8.77629 22.1984 9.73783 22.6728 10.8532C23.1471 11.9686 23.3843 13.1374 23.3843 14.3596C23.3843 16.24 22.8544 17.9451 21.7945 19.475L26.192 23.8724C26.5082 24.1887 26.6663 24.5733 26.6663 25.0263Z"
        fill="#767676"
      />
    </svg>
  );
};
