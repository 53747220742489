import { Box, ComboBoxTags, Spinner, Text, useDesignBreakpoint } from "@prodoctivity/design-system";
import { Dispatch, SetStateAction } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { FieldsRequiredByTheDocument } from "./FieldsRequiredByTheDocument";
import { FieldCondition, FilterOption, useDocFormOpenSearch } from "./hooks";

type Props = {
  documentTypesVersionIdList: FilterOption[];
  closePopup: () => void;
  buildSearchUrl: () => void;
  documentTypesSelected: FilterOption[];
  setDocumentTypesSelected: (value: FilterOption[]) => void;
  newSetFilters: (value: FieldCondition[]) => void;
  filterByFields: FieldCondition[];
  handlerEnterKeyPress: ({ event: { code } }: { event: { code: string } }) => void;
  isLoadingDocTypes: boolean;
  isSuggestionsOpen: boolean;
  setSearchClicked?: Dispatch<SetStateAction<boolean>>;
};

export function DocFormOpenSearch({
  documentTypesVersionIdList,
  buildSearchUrl,
  documentTypesSelected,
  setDocumentTypesSelected,
  newSetFilters,
  filterByFields,
  handlerEnterKeyPress,
  isLoadingDocTypes,
  isSuggestionsOpen,
  closePopup,
  setSearchClicked,
}: Props) {
  const {
    intersectionContext,
    isLoading,
    selectedDocumentName,
    isBoxOpenDocument,
    onFilterChange,
    setBoxOpenDocument,
    removeQueryRow,
    resetSelectedValues,
    isError,
    toggleShowMore,
    showMore,
  } = useDocFormOpenSearch({
    documentTypesSelected,
    filterByFields,
    newSetFilters,
  });
  const { resources } = useAppTranslation();

  const { breakpoint } = useDesignBreakpoint();
  const isTablet = breakpoint === "large";
  return (
    <Box onKeyDownCapture={() => handlerEnterKeyPress}>
      <Box display="flex" width="100%" direction="column">
        <Box borderStyle="lg" borderRadius={6}></Box>
        <Box display="flex" alignItems="center" direction="row" gap={2} width="100%">
          {documentTypesVersionIdList && !isLoadingDocTypes ? (
            <Box display="flex" alignItems="center" justifyContent="between" width={"100%"}>
              <Box maxWidth={184} width={"100%"} marginStart={4}>
                <Text>{resources.documentTypes.documentTypes}</Text>
              </Box>
              <Box width={isTablet ? 375 : "100%"} maxWidth={375} marginEnd={4}>
                <Box width="100%" paddingY={3}>
                  <ComboBoxTags
                    options={documentTypesVersionIdList}
                    selected={documentTypesSelected}
                    onSelect={(items) => {
                      {
                        setDocumentTypesSelected(items);
                        setBoxOpenDocument(true);
                      }
                    }}
                    label={""}
                    zIndex={{
                      index: () => 99999,
                    }}
                    id={"open_search_document_type_list"}
                    accessibilityClearButtonLabel={resources.clear}
                    noResultText={resources.noResultsFound}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" width="100%" padding={2}>
              <Spinner accessibilityLabel={"Loading"} show={isLoadingDocTypes} />
            </Box>
          )}
        </Box>
        {!isSuggestionsOpen && intersectionContext ? (
          <FieldsRequiredByTheDocument
            intersectionContext={intersectionContext.contextDefinition.fields || []}
            filterByFields={filterByFields}
            documentTypesSelected={documentTypesSelected}
            isLoading={isLoading}
            selectedDocumentName={selectedDocumentName}
            isBoxOpenDocument={isBoxOpenDocument}
            onChange={onFilterChange}
            setBoxOpenDocument={setBoxOpenDocument}
            removeQueryRow={removeQueryRow}
            resetSelectedValues={resetSelectedValues}
            buildSearchUrl={buildSearchUrl}
            handlerEnterKeyPress={handlerEnterKeyPress}
            closePopup={closePopup}
            setSearchClicked={setSearchClicked}
            toggleShowMore={toggleShowMore}
            showMore={showMore}
            componentBreakpoint={"small"}
          />
        ) : null}
        {isLoading ? (
          <Spinner accessibilityLabel={"Loading"} show={isLoading} />
        ) : isError && intersectionContext === undefined ? (
          <Box width="100%" display="flex" justifyContent="center">
            {resources.dataDictionary.noCommonFieldsFound}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
