import {
  Box,
  Icon,
  Spinner,
  Switch,
  Table,
  Text,
  Tooltip,
  useColors,
} from "@prodoctivity/design-system";

import { ResendInvitationIconSvg } from "@prodoctivity/design-system/svg/ResendInvitationIconSvg";
import type { OrganizationUser } from "@prodoctivity/types";
import { FunctionComponent } from "react";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { PublicProfile } from "../../../components/Profile/Public/PublicProfile";
import { noop } from "../../../utils";
import { useUserManagementListRow } from "./hooks";

type Props = {
  user: OrganizationUser;
  disabled?: boolean;
  refetchUserList(): void;
};

export const UserManagementListRow: FunctionComponent<Props> = ({
  user,
  disabled,
  refetchUserList,
}) => {
  const {
    isMutating,
    currentUser,
    resources,
    changePasswordToUser,
    editProfileOfUser,
    mutate,
    resendAccountActivation,
    toastMessage,
  } = useUserManagementListRow(user, refetchUserList);
  const { colors } = useColors();

  if (!currentUser) {
    return <Spinner show={true} accessibilityLabel={resources.loading} />;
  }

  return (
    <Table.Row>
      <Table.Cell>
        <PublicProfile username={user.id} size="200" sizeImage={32} evictUserListCache={true} />
      </Table.Cell>
      <Table.Cell>
        <Tooltip
          doNotShowIf={() => !!user.organizationInviteToken || currentUser.username === user.id}
          text={resources.userHasntAcceptedInvitation}
        >
          <Text size="200">{user.email}</Text>
        </Tooltip>
      </Table.Cell>
      <Table.Cell>
        <Text size="200">{user.positionRole}</Text>
      </Table.Cell>
      <Table.Cell>
        <Tooltip
          doNotShowIf={() => currentUser.username !== user.id || !user.hasDeactivationProtection}
          text={
            currentUser.username === user.id
              ? resources.cannotDeactivateYourself
              : user.hasDeactivationProtection === true
              ? resources.protectedUser
              : user.isActive
              ? resources.deactivateUser
              : resources.active
          }
        >
          <Switch
            id={`${user.id}_active`}
            disabled={disabled || isMutating}
            name={`${user.id}_active`}
            switched={user.isActive}
            onChange={(e) => mutate(e.value)}
          />
        </Tooltip>
      </Table.Cell>
      <Table.Cell>
        {(currentUser.username === user.id || user.organizationInviteToken) && (
          <Box display="flex" gap={4}>
            <Tooltip text={resources.changePassword}>
              <Icon
                color={colors.subtle}
                icon="lock-keyhole"
                accessibilityLabel={resources.changePassword}
                onClick={changePasswordToUser}
              />
            </Tooltip>
            {user.isActive && (
              <Tooltip text={resources.edit}>
                <Icon
                  color={colors.subtle}
                  icon="pencil"
                  accessibilityLabel={resources.edit}
                  onClick={editProfileOfUser}
                />
              </Tooltip>
            )}
          </Box>
        )}
        {currentUser.username !== user.id && !user.organizationInviteToken && (
          <Tooltip text={resources.resendInvitation}>
            <ResendInvitationIconSvg onClick={resendAccountActivation} />
          </Tooltip>
        )}
        {toastMessage && (
          <NotificationMessage
            type={toastMessage.type}
            message={toastMessage.message}
            position="bottom-left"
            handleDismiss={noop}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};
