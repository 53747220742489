import "./Thumbnail.css";

import { parseDataURI } from "@prodoctivity/shared";
import { useMemo } from "react";

export function Thumbnail({ image, fieldName }: { image: string; fieldName: string }) {
  const sanitizedImage = useMemo(() => {
    const r = parseDataURI(image);
    if (r !== undefined) {
      return image;
    }
    return `data:image/png;base64,${image}`;
  }, [image]);
  return (
    <p
      className="Thumbnail read-only-item-thumbnail"
      style={{ width: "auto", height: "auto", maxHeight: "192px", maxWidth: "192px" }}
    >
      <img
        src={sanitizedImage}
        alt={fieldName}
        // height="auto"
        width={200}
        style={{
          objectFit: "contain",
          objectPosition: "center",
          height: "100%",
          maxHeight: "192px",
          maxWidth: "192px",
        }}
      />
    </p>
  );
}
