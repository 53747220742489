import { FunctionComponent, useCallback, useMemo } from "react";
import { parseDataURI, shouldNever } from "@prodoctivity/shared";

import { ButtonImage } from "./ButtonImage";
import type { ContextFieldProperties } from "@prodoctivity/shared/src/index-types";
import type { DesignBreakpointType } from "../../components";
import { DropImage } from "./Dropimage";
import { ElectronicSignatureGrabber } from "./ElectronicSignatureGrabber";

function getBase64(img: Blob) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (reader && reader.result && typeof reader.result == "string") {
        resolve(reader.result);
      }
    });
    reader.readAsDataURL(img);
  });
}

type Props = {
  instanceFullPath: string;

  properties: ContextFieldProperties;
  value: any;
  disabled: boolean;
  onChange: any;
  onError: any;
  componentBreakpoint: DesignBreakpointType;
  resources: {
    dragDropFile: string;
    clear: string;
    clickUploadImage: string;
  };
};

export const ImageInput: FunctionComponent<Props> = ({
  instanceFullPath,
  properties,
  value,
  disabled,
  onChange,
  onError,
  componentBreakpoint,
  resources,
}) => {
  const accept = ".jpeg,.png,.jpg";

  const sanitizedImage = useMemo(() => {
    const r = parseDataURI(value);
    if (r !== undefined) {
      return value;
    }
    return `data:image/png;base64,${value}`;
  }, [value]);

  const image = {
    uid: "11441",
    name: "",
    status: "done",
    url: sanitizedImage,
  };
  const onFileChange = useCallback(
    async (event: any) => {
      if (properties.inputType === "Signature") {
        onChange(
          event.isEmpty && event.isEmpty() ? "" : event.toDataURL("image/png").split(",")[1]
        );
        return;
      }

      event.file.status = "done";
      const errors = validateImageFile(event.file, instanceFullPath);

      if (errors.length > 0) return onError(errors);

      const base64Image = await getBase64(event.file.originFileObj);
      onChange(base64Image);
    },
    [properties.inputType, instanceFullPath, onChange, onError]
  );

  switch (properties.dataType) {
    case "Alphanumeric":
    case "Currency":
    case "Date":
    case "DateTime":
    case "Time":
    case "Logical":
    case "Numeric": {
      return null;
    }
    case "Image": {
      switch (properties.inputType) {
        case "Default":
        case "ImageUpload": {
          return (
            <DropImage
              accept={accept}
              image={value ? image : value}
              disabled={disabled}
              customRequest={customRequest}
              onChange={onFileChange}
              onRemove={() => onChange(null)}
              componentBreakpoint={componentBreakpoint}
              resources={resources}
            />
          );
        }
        case "Signature": {
          return (
            <ElectronicSignatureGrabber
              id={instanceFullPath}
              properties={properties}
              value={value}
              resources={resources}
              disabled={disabled}
              onChange={onFileChange}
              onRemove={() => onChange()}
            />
          );
        }
        default:
          shouldNever(properties);
          return (
            <ButtonImage
              accept={accept}
              image={value ? image : value}
              disabled={disabled}
              customRequest={customRequest}
              onChange={onFileChange}
              resources={resources}
              onRemove={() => onChange(null)}
            />
          );
      }
    }
    default:
      shouldNever(properties);
      return null;
  }
};

function customRequest({ /*file,*/ onSuccess }: { file: any; onSuccess: any }) {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
}

function validateImageFile(file: any, elementName: string) {
  const errors = [];
  const allowedImageFormats = ["image/jpeg", "image/png", "image/gif", "image/jpg"];

  if (allowedImageFormats.indexOf(file.type) < 0) {
    errors.push({
      key: "UnSupportedImageFormat",
      dataElementName: elementName,
      text: `The file '${elementName}' only allow file JPG, PNG, GIF.`,
      parameters: {
        name: elementName,
      },
    });
  }

  if (!(file.size / 1024 / 1024 < 2)) {
    errors.push({
      key: "InvalidFileSize",
      dataElementName: elementName,
      text: `The file '${elementName}' must be smaller than 2MB!.`,
      parameters: {
        name: elementName,
      },
    });
  }

  return errors;
}
