import { useEffect, useRef } from "react";

import { GoogleProfileObj } from "../../utils";
import jwt_decode from "jwt-decode";
import { useScript } from "usehooks-ts";

type Props = {
  width?: number;
  setGoogleProfile: (profileObj: GoogleProfileObj, token: string) => void;
  type: "signup_with" | "signin_with";
  googleClientId: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const google: any;

export const GoogleButton: React.FC<Props> = ({
  width,
  setGoogleProfile,
  type,
  googleClientId,
}) => {
  const status = useScript("https://accounts.google.com/gsi/client", { removeOnUnmount: false });
  const googleButtonDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof google !== "undefined") {
      google.accounts.id.initialize({
        client_id: googleClientId,
        callback: (response: google.accounts.id.CredentialResponse) => {
          if (response.credential) {
            const userObject: GoogleProfileObj = jwt_decode(response.credential);
            setGoogleProfile(userObject, response.credential);
          }
        },
      });
      const { current } = googleButtonDiv;
      if (current) {
        google.accounts.id.renderButton(current, {
          theme: "outline",
          size: "large",
          type: "standard",
          text: type,
          shape: "rectangular",
          logo_alignment: "left",
          width: width || 300,
        });
      }
    }
  }, [width, type, setGoogleProfile, googleClientId, status]);

  return <div ref={googleButtonDiv} />;
};
