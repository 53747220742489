import React from "react";
import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const CooperativaSvgIcon: FunctionComponent<Props> = ({
  width = 101,
  height = 101,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8255_107562)">
        <rect
          width="93"
          height="93"
          transform="translate(4)"
          fill="url(#paint0_linear_8255_107562)"
        />
        <g filter="url(#filter1_d_8255_107562)">
          <rect width="81" height="81" transform="translate(10 6)" fill="#D4E0F7" />
          <path d="M51.4847 28.1045H46.1846V32.4851H51.4847V28.1045Z" fill="#9D9D9C" />
          <path d="M68.5934 28.1045H54.3713V32.4851H68.5934V28.1045Z" fill="#9D9D9C" />
          <path d="M68.5924 38.9785H46.1846V43.3591H68.5924V38.9785Z" fill="#9D9D9C" />
          <path d="M61.4667 49.8252H46.1846V54.2058H61.4667V49.8252Z" fill="#9D9D9C" />
          <path d="M55.9908 21.7588H41.2388V26.1394H55.9908V21.7588Z" fill="#878787" />
          <path d="M46.5389 32.6348H41.2388V37.0154H46.5389V32.6348Z" fill="#878787" />
          <path d="M63.6168 32.6348H49.3948V37.0154H63.6168V32.6348Z" fill="#878787" />
          <path d="M63.6466 43.4805H41.2388V47.8611H63.6466V43.4805Z" fill="#878787" />
          <path d="M56.5209 54.3564H41.2388V58.737H56.5209V54.3564Z" fill="#878787" />
          <g filter="url(#filter2_d_8255_107562)">
            <path
              d="M31 20.8519L31 72.1481C31 72.6186 31.363 73 31.8107 73H69.1893C69.637 73 70 72.6186 70 72.1481V20.8519C70 20.3814 69.637 20 69.1893 20H31.8107C31.363 20 31 20.3814 31 20.8519Z"
              fill="white"
            />
          </g>
          <g filter="url(#filter3_d_8255_107562)">
            <path d="M50.5729 26.8037H35.8208V31.1843H50.5729V26.8037Z" fill="#2951A0" />
          </g>
          <g filter="url(#filter4_d_8255_107562)">
            <path d="M41.1209 37.6494H35.8208V42.03H41.1209V37.6494Z" fill="#2951A0" />
          </g>
          <g filter="url(#filter5_d_8255_107562)">
            <path d="M58.2296 37.6494H44.0076V42.03H58.2296V37.6494Z" fill="#E97025" />
          </g>
          <g filter="url(#filter6_d_8255_107562)">
            <path d="M58.2286 48.5254H35.8208V52.906H58.2286V48.5254Z" fill="#2951A0" />
          </g>
          <g filter="url(#filter7_d_8255_107562)">
            <path d="M51.1029 59.3721H35.8208V63.7527H51.1029V59.3721Z" fill="#E97025" />
          </g>
          <g filter="url(#filter8_d_8255_107562)">
            <path
              d="M69.9999 42.2342C76.2343 42.2342 81.2883 37.192 81.2883 30.9721C81.2883 24.7522 76.2343 19.71 69.9999 19.71C63.7654 19.71 58.7114 24.7522 58.7114 30.9721C58.7114 37.192 63.7654 42.2342 69.9999 42.2342Z"
              fill="url(#paint1_linear_8255_107562)"
            />
          </g>
          <g filter="url(#filter9_i_8255_107562)">
            <path
              d="M70 20.4389C75.8269 20.4389 80.5577 25.1587 80.5577 30.972C80.5577 36.7854 75.8269 41.5051 70 41.5051C64.1731 41.5051 59.4423 36.7854 59.4423 30.972C59.4423 25.1587 64.1731 20.4389 70 20.4389ZM70 19C63.3846 19 58 24.3721 58 30.972C58 37.572 63.3846 42.944 70 42.944C76.6154 42.944 82 37.572 82 30.972C82 24.3721 76.6154 19 70 19Z"
              fill="url(#paint2_linear_8255_107562)"
            />
          </g>
          <g filter="url(#filter10_i_8255_107562)">
            <path
              d="M69.8346 33.8054L65.5076 24.6729L61.2 33.8054H64.6615V37.1821H66.373V33.8054H69.8346Z"
              fill="#FFE925"
            />
          </g>
          <g filter="url(#filter11_i_8255_107562)">
            <path
              d="M78.4691 33.8054L74.1422 24.6729L69.8345 33.8054H73.296V37.1821H74.9883V33.8054H78.4691Z"
              fill="#FFE925"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_8255_107562"
          x="0"
          y="0"
          width="101"
          height="101"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.12 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8255_107562"
          x="8"
          y="6"
          width="85"
          height="85"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8255_107562"
          x="31"
          y="20"
          width="42"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_8255_107562"
          x="35.8208"
          y="26.8037"
          width="18.752"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_8255_107562"
          x="35.8208"
          y="37.6494"
          width="9.30005"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_8255_107562"
          x="44.0076"
          y="37.6494"
          width="18.2222"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_8255_107562"
          x="35.8208"
          y="48.5254"
          width="26.4077"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_8255_107562"
          x="35.8208"
          y="59.3721"
          width="19.282"
          height="8.38086"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.317647 0 0 0 0 0.627451 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_8255_107562"
          x="57.7114"
          y="19.71"
          width="24.5769"
          height="24.5244"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8255_107562" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8255_107562"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_i_8255_107562"
          x="58"
          y="19"
          width="24"
          height="24.9443"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0923611 0 0 0 0 0.291667 0 0 0 0 0.160125 0 0 0 0.76 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8255_107562" />
        </filter>
        <filter
          id="filter10_i_8255_107562"
          x="61.2"
          y="24.6729"
          width="8.63452"
          height="13.5088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.52549 0 0 0 0 0.466667 0 0 0 0 0.160784 0 0 0 0.8 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8255_107562" />
        </filter>
        <filter
          id="filter11_i_8255_107562"
          x="69.8345"
          y="24.6729"
          width="8.63452"
          height="13.5088"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.525 0 0 0 0 0.465066 0 0 0 0 0.159688 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8255_107562" />
        </filter>
        <linearGradient
          id="paint0_linear_8255_107562"
          x1="21.9754"
          y1="-94.9194"
          x2="23.3125"
          y2="98.0588"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00455753" stopColor="#B7CAEA" />
          <stop offset="0.533268" stopColor="#FAFBFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8255_107562"
          x1="71.5"
          y1="21"
          x2="87"
          y2="51.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008753" />
          <stop offset="1" stopColor="#FFE925" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8255_107562"
          x1="65.5"
          y1="17"
          x2="71"
          y2="44.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008753" />
          <stop offset="1" stopColor="#FFE925" stopOpacity="0.88" />
        </linearGradient>
      </defs>
    </svg>
  );
};
