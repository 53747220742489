import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;

  width?: number;
  height?: number;
};

export const CombinedTemplateTaskSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 50,
  height = 50,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.7711 1H4.19063C2.42849 1 1 2.42849 1 4.19063V44.7711C1 46.5332 2.42849 47.9617 4.19063 47.9617H44.7711C46.5332 47.9617 47.9617 46.5332 47.9617 44.7711V4.19063C47.9617 2.42849 46.5332 1 44.7711 1Z"
        fill="#DAE6FD"
      />
      <mask id="mask0_13293_135470" maskUnits="userSpaceOnUse" x="1" y="1" width="47" height="47">
        <path
          d="M44.7711 1H4.19063C2.42849 1 1 2.42849 1 4.19063V44.7711C1 46.5332 2.42849 47.9617 4.19063 47.9617H44.7711C46.5332 47.9617 47.9617 46.5332 47.9617 44.7711V4.19063C47.9617 2.42849 46.5332 1 44.7711 1Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_13293_135470)">
        <path
          d="M43.2679 2.40625H5.69422C3.87832 2.40625 2.40625 3.87832 2.40625 5.69422V43.2679C2.40625 45.0838 3.87832 46.5559 5.69422 46.5559H43.2679C45.0838 46.5559 46.5559 45.0838 46.5559 43.2679V5.69422C46.5559 3.87832 45.0838 2.40625 43.2679 2.40625Z"
          fill="#DAE6FD"
        />
        <path d="M47.032 1.94141H1.94141V47.032H47.032V1.94141Z" fill="#DAE6FD" />
        <mask id="mask1_13293_135470" maskUnits="userSpaceOnUse" x="1" y="1" width="47" height="47">
          <path d="M47.032 1.94141H1.94141V47.032H47.032V1.94141Z" fill="white" />
        </mask>
        <g mask="url(#mask1_13293_135470)">
          <path
            d="M43.2679 2.40625H5.69422C3.87718 2.40625 2.40625 3.87718 2.40625 5.69422V43.2679C2.40625 45.0849 3.86637 46.5559 5.69422 46.5559H43.2679C45.0849 46.5559 46.5559 45.0849 46.5559 43.2679V5.69422C46.5559 3.87718 45.0849 2.40625 43.2679 2.40625Z"
            fill="#DAE6FD"
          />
        </g>
        <path d="M47.032 1.94141H1.94141V47.032H47.032V1.94141Z" fill="#DAE6FD" />
      </g>
      <path
        d="M34.3438 10.4102C35.8579 11.9244 36.853 12.9302 38.2807 14.3579H34.3438V10.4102Z"
        fill="#4D6BD3"
      />
      <path
        d="M27.2923 17.6562H22.2197V18.6946H25.5726L27.2382 18.6837V30.3647H28.309V18.6729C28.309 18.1105 27.8547 17.6562 27.2923 17.6562Z"
        fill="#4D6BD3"
      />
      <path
        d="M22.1656 31.4138C21.7114 31.4138 21.3436 31.1434 21.2138 30.754C21.1814 30.6783 21.1598 30.5918 21.1598 30.5053V17.6562H11.2418C10.5604 17.6562 10.0088 18.2079 10.0088 18.8892V38.4656C10.0088 39.028 10.463 39.4823 11.0255 39.4823H27.3031C27.7573 39.4823 28.1142 39.2011 28.244 38.8117C28.2873 38.7468 28.3089 38.6495 28.3089 38.5738V31.4138H22.1764H22.1656ZM19.1372 34.0312H12.3882V32.6684H19.1372V34.0312ZM19.1372 30.992H12.3882V29.6184H19.1372V30.992ZM19.1372 27.9528H12.3882V26.5792H19.1372V27.9528Z"
        fill="#4D6BD3"
      />
      <path
        d="M38.4428 15.0391H33.5974L33.6406 10.583C33.6406 10.0206 33.208 9.57715 32.6348 9.57715H22.1544C21.592 9.57715 21.1377 10.0206 21.1377 10.583V17.6565H22.1976V10.583L32.6239 10.6371L32.5807 15.0931C32.5807 15.6556 33.0133 16.1098 33.5865 16.1098H38.432L38.3779 30.3973L28.2977 30.3541V31.4032H38.4428C39.0052 31.4032 39.4595 30.9598 39.4595 30.3973V16.0449C39.4595 15.4825 39.0052 15.0391 38.4428 15.0391Z"
        fill="#4D6BD3"
      />
      <path d="M27.2278 28.8721V30.3646H25.7568L27.2278 28.8721Z" fill="#4D6BD3" />
      <path
        d="M27.2275 24.6543V27.1311L24.0044 30.3542H22.209V29.6944L27.2275 24.6543Z"
        fill="#4D6BD3"
      />
      <path d="M27.2275 20.8252V22.891L22.209 27.9311V25.8653L27.2275 20.8252Z" fill="#4D6BD3" />
      <path
        d="M27.2275 18.6729V19.073L22.209 24.1131V22.0365L25.5618 18.6837H27.2275V18.6729Z"
        fill="#4D6BD3"
      />
      <path d="M23.8097 18.6836L22.209 20.2843V18.6944H23.8097V18.6836Z" fill="#4D6BD3" />
      <path d="M37.0694 18.5107H30.3096V19.8843H37.0694V18.5107Z" fill="#4D6BD3" />
      <path d="M37.0586 21.5391V22.9127H30.2988V21.5283H37.0586V21.5391Z" fill="#4D6BD3" />
      <path d="M37.0694 24.5889H30.3096V25.9516H37.0694V24.5889Z" fill="#4D6BD3" />
      <path
        d="M44.425 1.04395H4.49347C2.55746 1.04395 1 2.61222 1 4.53741V44.4689C1 46.3941 2.55746 47.9624 4.49347 47.9624H44.425C46.3502 47.9624 47.9184 46.3941 47.9184 44.4689V4.53741C47.9184 2.61222 46.3502 1.04395 44.425 1.04395Z"
        stroke="#4D6BD3"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
