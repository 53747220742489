import {
  Box,
  ChevronSvgIcon,
  Divider,
  Skeleton,
  Table,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import type { DocumentTypeResume, DocumentTypeSecurityField } from "@prodoctivity/types";
import { FunctionComponent, useMemo, useState } from "react";

import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { MissingKeysIconSvg } from "../../../../svg/MissingKeysIconSvg";

type Props = {
  roleId?: string;
  resources: ReturnType<typeof useAppTranslation>["resources"];
  isLoading: boolean;
  documentTypes: DocumentTypeResume[];
  documentTypesSecurityFields: DocumentTypeSecurityField[];
};

export const SecurityKeywordsTab: FunctionComponent<Props> = ({
  roleId,
  resources,
  isLoading,
  documentTypes,
  documentTypesSecurityFields,
}) => {
  const { colors } = useColors();
  const documentTypesSecurityFiltered = useMemo(() => {
    if (!roleId) {
      return [];
    }
    return documentTypesSecurityFields.reduce((arr: DocumentTypeSecurityField[], dt) => {
      if (dt.fields.some((f) => f.roleIdList.includes(roleId))) {
        arr.push({
          documentTypeId: dt.documentTypeId,
          fields: dt.fields.filter((f) => f.roleIdList.includes(roleId)),
        });
      }

      return arr;
    }, []);
  }, [roleId, documentTypesSecurityFields]);

  return (
    <Skeleton height={400} show={isLoading}>
      <Box height={"fit-content"} alignItems="start">
        {documentTypesSecurityFiltered.length === 0 ? (
          <Box display="flex" direction="column" justifyContent="center" alignItems="center">
            <MissingKeysIconSvg height={300} width={200} />
            <Text weight="bold" color={colors.subtle}>
              {resources.rolesManagement.missingSecurityKeysForRole}
            </Text>
          </Box>
        ) : (
          documentTypesSecurityFiltered.map((securityField) => {
            const documentType = documentTypes.find(
              (dt) => dt.documentTypeId === securityField.documentTypeId
            );
            return (
              <DocumentTypeSecurityFieldRow
                key={`document-type-security-fields-${documentType?.documentTypeId}`}
                resources={resources}
                documentType={documentType}
                documentTypeSecurityFields={securityField}
              />
            );
          })
        )}
      </Box>
    </Skeleton>
  );
};

type DocumentTypeSecurityFieldProp = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  documentType?: DocumentTypeResume;
  documentTypeSecurityFields: DocumentTypeSecurityField;
};
const DocumentTypeSecurityFieldRow: FunctionComponent<DocumentTypeSecurityFieldProp> = ({
  resources,
  documentType,
  documentTypeSecurityFields,
}) => {
  const { colors } = useColors();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  if (!documentType) {
    return <></>;
  }

  return (
    <Box minHeight={50} alignItems="start" borderStyle="lg" borderRadius={6}>
      <Box color={colors.neutral200} paddingY={1} paddingX={5}>
        <TapArea onTap={() => setIsExpanded((prev) => !prev)}>
          <Box display="flex" gap={2} alignItems="center">
            <ChevronSvgIcon color={colors.subtle} direction={isExpanded ? "down" : "right"} />
            <Text weight={"bold"} color={colors.subtle}>
              {resources.documentTypes.documentType}: {documentType.name}
            </Text>
          </Box>
        </TapArea>
      </Box>
      {isExpanded && <Divider />}
      {isExpanded && (
        <Box color={colors.white}>
          <Table accessibilityLabel={documentType.name}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Box paddingX={4}>
                    <Text weight="bold" size="200">
                      {resources.field}
                    </Text>
                  </Box>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Box paddingX={4}>
                    <Text size="200" weight="bold" align="center">
                      {resources.value}
                    </Text>
                  </Box>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {documentTypeSecurityFields.fields.map((f) => (
                <Table.Row hoverStyle="gray" key={`${documentType.documentTypeId}${f.fieldName}`}>
                  <Table.Cell>
                    <Box marginStart={4} display={"flex"} justifyContent={"between"}>
                      <Box>
                        <Text size="200">{f.fieldName}</Text>
                      </Box>
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <Text align="center" size="200">
                      {f.value}
                    </Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Box>
      )}
    </Box>
  );
};
