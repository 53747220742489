import { Box, Spinner, TapArea, Text, useColors } from "@prodoctivity/design-system";
import { Dispatch, SetStateAction, useMemo } from "react";
import { OpenSearchOption } from "./hooks";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { DocumentSearchFilter } from "../../link-templates";
import { DocIconsSvg } from "../../svg/DocumentIconsSvg";
import { DocumentIconsSvg } from "../../svg/DocumentTypeIconsSvg";
import { TemplateIconSvg } from "../../svg/TemplateIconSvg";
import type { GetRecentSearchListResponse } from "@prodoctivity/types/model/recent-searches";
import { RecentSearchesDataType } from "../../pages/RecentSearches/hooks";

type Props = {
  isSuggestionsOpen: boolean;
  closePopup: () => void;
  idSelected: OpenSearchOption["id"];
  setSearchClicked: Dispatch<SetStateAction<boolean>> | undefined;
  isLoading: boolean;
  recentSearchList: GetRecentSearchListResponse[];
  buildRecentSearchUrl: (
    searchQuery: string,
    category: RecentSearchesDataType["category"],
    searchType: RecentSearchesDataType["searchType"],
    documentId: string | undefined,
    filter: DocumentSearchFilter | undefined,
    specificFilter: string | undefined,
    index: number | undefined
  ) => void;
  isRecentSearchListEmpty: { document: boolean; collection: boolean; template: boolean };
};

export const RecentSeachSuggestion = ({
  isSuggestionsOpen,
  closePopup,
  idSelected,
  setSearchClicked,
  isLoading,
  recentSearchList,
  buildRecentSearchUrl,
  isRecentSearchListEmpty,
}: Props) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const limitSuggestionsPanelHeight = recentSearchList.length > 4;

  const optionsIcons = useMemo(() => {
    const result: OpenSearchOption[] = [
      {
        id: "document",
        title: resources.documents,
        icon: (
          <Box>
            <DocIconsSvg />
          </Box>
        ),
      },

      {
        id: "collection",
        title: resources.collections,
        icon: (
          <Box>
            <DocumentIconsSvg />
          </Box>
        ),
      },
      {
        id: "template",
        title: resources.templates,
        icon: (
          <Box>
            <TemplateIconSvg color={colors.primary} />
          </Box>
        ),
      },
    ];
    return result;
  }, [resources, colors]);

  return (
    <>
      {isSuggestionsOpen &&
        (idSelected === "document" ? (
          isLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Spinner accessibilityLabel={"Loading"} show={true} />
            </Box>
          ) : (
            <Box
              direction="column"
              maxHeight={limitSuggestionsPanelHeight ? 255 : undefined}
              overflow={limitSuggestionsPanelHeight ? "scrollY" : undefined}
            >
              <>
                {isRecentSearchListEmpty.document ? (
                  <Box paddingY={4}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                      {resources.noResultsFound}
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box paddingX={4} paddingTop={4} paddingBottom={2}>
                      <Text size="100">{resources.mostRecent}</Text>
                    </Box>
                    {recentSearchList
                      .filter((item) => item.category === "document")
                      .map((item, index) => {
                        return (
                          <Box
                            key={index}
                            paddingX={4}
                            paddingY={1}
                            width="100%"
                            height="100%"
                            hoverColor={colors.primaryHover0}
                            display="flex"
                            alignItems="center"
                          >
                            <TapArea
                              onTap={() => {
                                buildRecentSearchUrl(
                                  item.searchQuery,
                                  item.category,
                                  item.searchType,
                                  item.documentId,
                                  undefined,
                                  undefined,
                                  undefined
                                );
                                closePopup();
                                if (setSearchClicked) {
                                  setSearchClicked(false);
                                }
                              }}
                            >
                              <Box
                                display="flex"
                                gap={3}
                                alignItems="center"
                                justifyContent="start"
                              >
                                {optionsIcons.find((element) => element.id === item.category)?.icon}
                                <Text color={colors.black900}>{item.searchQuery}</Text>
                              </Box>
                            </TapArea>
                          </Box>
                        );
                      })}
                  </Box>
                )}
              </>
            </Box>
          )
        ) : idSelected === "template" ? (
          isLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Spinner accessibilityLabel={"Loading"} show={true} />
            </Box>
          ) : (
            <Box
              direction="column"
              maxHeight={limitSuggestionsPanelHeight ? 255 : undefined}
              overflow={limitSuggestionsPanelHeight ? "scrollY" : undefined}
            >
              {isRecentSearchListEmpty.template ? (
                <Box padding={4}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    {resources.noResultsFound}
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box paddingX={4} paddingTop={4} paddingBottom={2}>
                    <Text size="100">{resources.mostRecent}</Text>
                  </Box>
                  {recentSearchList
                    .filter((item) => item.category === "template")
                    .map((item) => {
                      return (
                        <Box
                          key={item.category === "template" ? item.documentId : item.documentId}
                          padding={2}
                          width="100%"
                          height="100%"
                          hoverColor={colors.primaryHover0}
                          display="flex"
                          alignItems="center"
                        >
                          <TapArea
                            onTap={() => {
                              buildRecentSearchUrl(
                                item.searchQuery,
                                item.category,
                                item.searchType,
                                item.documentId,
                                undefined,
                                undefined,
                                undefined
                              );
                              closePopup();
                              if (setSearchClicked) {
                                setSearchClicked(false);
                              }
                            }}
                          >
                            <Box display="flex" gap={3} alignItems="center" justifyContent="start">
                              {optionsIcons.find((element) => element.id === item.category)?.icon}
                              <Text color={colors.black900}>{item.searchQuery}</Text>
                            </Box>
                          </TapArea>
                        </Box>
                      );
                    })}
                </Box>
              )}
            </Box>
          )
        ) : isLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Spinner accessibilityLabel={"Loading"} show={true} />
          </Box>
        ) : (
          <Box
            direction="column"
            maxHeight={limitSuggestionsPanelHeight ? 255 : undefined}
            overflow={limitSuggestionsPanelHeight ? "scrollY" : undefined}
          >
            <>
              {isRecentSearchListEmpty.collection ? (
                <Box padding={4}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    {resources.noResultsFound}
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box paddingX={4} paddingTop={4} paddingBottom={2}>
                    <Text size="100">{resources.mostRecent}</Text>
                  </Box>
                  {recentSearchList
                    .filter((item) => item.category === "collection")
                    .map((item) => {
                      return (
                        <Box
                          key={item._id}
                          padding={2}
                          width="100%"
                          height="100%"
                          hoverColor={colors.primaryHover0}
                          display="flex"
                          alignItems="center"
                        >
                          <TapArea
                            onTap={() => {
                              buildRecentSearchUrl(
                                item.searchQuery,
                                item.category,
                                item.searchType,
                                item.documentId,
                                undefined,
                                undefined,
                                undefined
                              );
                              closePopup();
                              if (setSearchClicked) {
                                setSearchClicked(false);
                              }
                            }}
                          >
                            <Box display="flex" gap={3} alignItems="center" justifyContent="start">
                              {optionsIcons.find((element) => element.id === item.category)?.icon}
                              <Text color={colors.black900}>{item.searchQuery}</Text>
                            </Box>
                          </TapArea>
                        </Box>
                      );
                    })}
                </Box>
              )}
            </>
          </Box>
        ))}
    </>
  );
};
