import {
  MutableRefObject,
  useMemo,
  useRef,
  type Dispatch,
  type FunctionComponent,
  type SetStateAction,
} from "react";
import { Box, BoxWithRef } from "../Box";
import { useDocumentViewer } from "./hooks";

import type { WebViewerInstance } from "@pdftron/webviewer";
import { mimeTypeToExtension } from "@prodoctivity/shared";
import type { MimeType } from "@prodoctivity/shared/src/index-types";
import type momentType from "moment";
import { PubSubEventManager } from "../../core";
import { SearchSvgIcon } from "../../svg";
import { DownloadSvg } from "../../svg/DownloadSvg";
import { ListSvg } from "../../svg/ListSvg";
import { PrintSvg } from "../../svg/PrintSvg";
import { ResendInvitationIconSvg } from "../../svg/ResendInvitationIconSvg";
import { Button } from "../Button/Button";
import { useColors } from "../ColorSchemeProvider";
import { Divider } from "../Divider";
import { DynamicIconButton } from "../InteractiveIconController/DynamicIconButton";
import { PopoverMenu } from "../InteractiveIconController/PopoverMenu";
import { Modal } from "../Modal";
import { TapArea } from "../TapArea";
import { Text } from "../Text";
import { TextField } from "../TextField";
import { Tooltip } from "../Tooltip";
import { Layer, modalZIndex } from "../layout";
import { SidePanel } from "./SidePanel";
import { SignDocumentSvg } from "../../svg/SignDocumentSvg";

export type DocumentViewerEvents =
  | {
      type: "save-with-payload";
      value: Record<string, unknown>;
    }
  | {
      type: "restore-document-version";
      value: Record<string, unknown>;
    }
  | {
      type: "toggle-measurement-mode";
      value: { toolSelected: boolean | undefined };
    }
  | {
      type: "clear-measurement-annotation";
    }
  | {
      type: "rectangular-annotation-created";
      value: { x: number; y: number; height: number; width: number; pageIndex: number };
    }
  | {
      type: "request-loaded-document-signatures";
    }
  | {
      type: "document-signatures-loaded";
      value: { signatures: DocumentViewerDigitalSignatureItem[] | undefined };
    };

export type InstanceWebViewer = WebViewerInstance;

export type DocumentViewerPanelKeys =
  | "none"
  | "data"
  | "update"
  | "generations"
  | "versions"
  | "change"
  | "info"
  | "approvals"
  | "collections"
  | "exportViaEmail"
  | "share"
  | "sign"
  | "history"
  | "myLists";

export type DocumentViewerProps = {
  src: Array<File | Blob | string>;
  moment: typeof momentType;
  readOnly?: boolean;
  mimeType?: MimeType;
  height?: string;
  templateDetailsExist?: boolean;
  onFilesChange?: (filesUpdated: Array<string | Blob>) => void;
  isPrintScreenVisible?: boolean;
  setIsPrintScreenVisible?: Dispatch<SetStateAction<boolean>>;
  handleDocumentPrintAndDownload?: (
    documentFullyLoaded: boolean,
    instanceRef: MutableRefObject<InstanceWebViewer | undefined>
  ) => void;
  isFullScreen: boolean;
  setIsFullScreen: (val: boolean) => void;
  resources: {
    cancel: string;
    delete_: string;
    download: string;
    print: string;
    myLists: string;
    searchPanel: string;
    sendViaEmailPage: {
      sendViaEmail: string;
    };
    yesDelete: string;
    youAreAboutToDeleteThisDocument: string;
    signThisDocument: string;
  };
  eventManager: PubSubEventManager<DocumentViewerEvents>;
  wordToPdfHookRef?: {
    pdfDataUri: string | undefined;
    setWordDataUri: (wordDataUri: string) => void;
  };
} & (
  | {
      strategy: "normal";
    }
  | {
      strategy: "ecm-document";
      mimeType: string;
      customIcon: JSX.Element;
      isPanelOpen: DocumentViewerPanelKeys;
      canDeleteDocument: boolean;
      name: string;
      binaries: string[];
      sendViaEmail(): void;
      myLists(): void;
      downloadZipFile(name: string, mimeType: string, binaries: string[]): void;
      deleteDocument(): void;
      signDocument(): void;
    }
);

export type DocumentViewerDigitalSignatureItem = {
  signer: string;
  isValid: boolean;
  signingTime: Date | undefined;
  hasVisibleAppearance: boolean;
};

export function DocumentViewer(props: DocumentViewerProps) {
  const { resources } = props;

  const viewerHTMLElementRef = useRef<HTMLDivElement>(null);
  const {
    breakpoint,
    toolBarButtonName,
    viewerScreenSizeProps,
    moveElement,
    onDelete,
    onRotate,
    handleFullScreenToggle,
    handleAdjustHeightToggle,
    handleAdjustWidthToggle,
    handleZoomInBtn,
    handleZoomOutBtn,
    handleHandTool,
    handleRectangleSelectToggle,
    handlePreviousPageBtn,
    handleNextPageBtn,
    handleSpecifiedPageBtn,
    handlerEnterKeyPress,
    handlePreviousImageBtn,
    handleNextImageBtn,
    handleCollapseImagePanelBtn,
    handleFirstPageBtn,
    handleFirstImageBtn,
    handleLastImageBtn,
    handleLastPageBtn,
    handleTapPrinter,
    handleTapSearchPanel,
    isSearchPanelVisible,
    srcAreImages,
    instanceLoadState,
    imagesPanelOpen,
    selectedThumbnail,
    setSelectedThumbnail,
    src,
    readOnly,
    height,
    rest,
    instanceRef,
    handleFunctionsBundle,
    btnOptionsInHamburger,
    debouncedCurrentPage,
    menuOptionLabels,
    exitFullScreen,
    selectedTool,
  } = useDocumentViewer(props, viewerHTMLElementRef);
  const { colors } = useColors();
  const showOnlyCloudIcon = props.templateDetailsExist || false; //rest.strategy === "ecm-document" && rest.isPanelOpen !== "none");

  const isImage = useMemo(() => {
    return props.strategy === "ecm-document" && props.mimeType.startsWith("image/");
  }, [props.strategy, props.mimeType]);

  const downloadHref = useMemo(() => {
    if (props.strategy !== "ecm-document") {
      return undefined;
    }
    return `data:${props.mimeType};base64,${props.binaries[0]}`;
  }, [props]);

  const documentViewerToolbar = useMemo(() => {
    return (
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={rest.strategy === "ecm-document" ? "between" : "start"}
          paddingX={4}
          paddingTop={2}
          paddingBottom={1}
          minHeight={52}
        >
          {showOnlyCloudIcon || breakpoint === "small" ? (
            <Box display="flex" alignItems="center" marginEnd={4}>
              <PopoverMenu
                handleFunctionsBundle={handleFunctionsBundle}
                menuLabels={menuOptionLabels}
                srcAreImages={() => srcAreImages}
                menuHasIcons={true}
                iconsToRender={btnOptionsInHamburger}
                collapsibleMenuIcon="hamburger"
                bundleNeedsParameters={false}
                popoverPanelWidth={undefined}
              />
            </Box>
          ) : null}
          {showOnlyCloudIcon ? null : (
            <Box
              display="flex"
              alignItems="center"
              gap={breakpoint === "small" ? 1 : 3}
              marginEnd={4}
            >
              {breakpoint === "small" ? null : (
                <>
                  {srcAreImages && (
                    <>
                      <DynamicIconButton
                        buttonName={toolBarButtonName.collapseSidePanel}
                        handleFunction={handleCollapseImagePanelBtn}
                        statefulCondition={undefined}
                        selected={undefined}
                      ></DynamicIconButton>
                      <Box width={1} height={16} color={colors.neutral600}></Box>
                    </>
                  )}
                  <DynamicIconButton
                    buttonName={toolBarButtonName.fullScreen}
                    handleFunction={handleFullScreenToggle}
                    statefulCondition={undefined}
                    selected={rest.isFullScreen}
                  ></DynamicIconButton>
                  {breakpoint !== "medium" && (
                    <DynamicIconButton
                      buttonName={toolBarButtonName.adjustHeight}
                      handleFunction={handleAdjustHeightToggle}
                      statefulCondition={undefined}
                      selected={undefined}
                    ></DynamicIconButton>
                  )}
                  <DynamicIconButton
                    buttonName={toolBarButtonName.adjustWidth}
                    handleFunction={handleAdjustWidthToggle}
                    statefulCondition={undefined}
                    selected={undefined}
                  ></DynamicIconButton>
                  <DynamicIconButton
                    buttonName={toolBarButtonName.zoomOut}
                    handleFunction={handleZoomOutBtn}
                    statefulCondition={undefined}
                    selected={undefined}
                  ></DynamicIconButton>
                  <DynamicIconButton
                    buttonName={toolBarButtonName.zoomIn}
                    handleFunction={handleZoomInBtn}
                    statefulCondition={undefined}
                    selected={undefined}
                  ></DynamicIconButton>
                  <Box width={1} height={16} color={colors.neutral600}></Box>
                  <DynamicIconButton
                    buttonName={toolBarButtonName.rectangleTool}
                    handleFunction={handleRectangleSelectToggle}
                    statefulCondition={selectedTool === "AnnotationCreateRectangle"}
                    selected={selectedTool === "AnnotationCreateRectangle"}
                  ></DynamicIconButton>
                  <DynamicIconButton
                    buttonName={toolBarButtonName.handTool}
                    handleFunction={handleHandTool}
                    statefulCondition={undefined}
                    selected={undefined}
                  ></DynamicIconButton>
                </>
              )}

              <DynamicIconButton
                buttonName={toolBarButtonName.firstPage}
                handleFunction={srcAreImages ? handleFirstImageBtn : handleFirstPageBtn}
                statefulCondition={undefined}
                selected={undefined}
              ></DynamicIconButton>

              {breakpoint !== "medium" && (
                <DynamicIconButton
                  buttonName={toolBarButtonName.previousPage}
                  handleFunction={srcAreImages ? handlePreviousImageBtn : handlePreviousPageBtn}
                  statefulCondition={undefined}
                  selected={undefined}
                ></DynamicIconButton>
              )}

              <Box
                width={60}
                height={40}
                overflow="hidden"
                borderRadius={4}
                borderColor={colors.neutral600}
              >
                <TextField
                  id="specificPagePanel"
                  onChange={(e) => {
                    handleSpecifiedPageBtn(e.value);
                  }}
                  size="md"
                  value={`${debouncedCurrentPage}`}
                  onKeyDown={handlerEnterKeyPress}
                  mode="unstyled"
                ></TextField>
              </Box>

              {breakpoint !== "medium" && (
                <DynamicIconButton
                  buttonName={toolBarButtonName.nextPage}
                  handleFunction={srcAreImages ? handleNextImageBtn : handleNextPageBtn}
                  statefulCondition={undefined}
                  selected={undefined}
                ></DynamicIconButton>
              )}

              <DynamicIconButton
                buttonName={toolBarButtonName.lastPage}
                handleFunction={srcAreImages ? handleLastImageBtn : handleLastPageBtn}
                statefulCondition={undefined}
                selected={undefined}
              ></DynamicIconButton>
            </Box>
          )}

          {!rest.isFullScreen && breakpoint !== "small" && props.strategy === "ecm-document" && (
            <>
              <Box display="flex" flex="shrink" marginTop={1} gap={2}></Box>
              <Box display="flex" flex="grow" justifyContent="end" marginTop={2} gap={2}>
                <Box color={isSearchPanelVisible ? colors.neutral400 : colors.white}>
                  <Tooltip text={resources.searchPanel}>
                    <TapArea
                      onTap={() => {
                        handleTapSearchPanel();
                      }}
                    >
                      <SearchSvgIcon width={26} height={26} />
                    </TapArea>
                  </Tooltip>
                </Box>
                {breakpoint !== "medium" && breakpoint !== "large" && (
                  <>
                    <Box>
                      <TapArea
                        onTap={() => {
                          handleTapPrinter();
                        }}
                      >
                        <Tooltip text={resources.print}>
                          <PrintSvg width={26} height={26} />
                        </Tooltip>
                      </TapArea>
                    </Box>
                    <Box>
                      <Tooltip text={resources.download}>
                        {isImage ? (
                          <TapArea
                            onTap={() => {
                              props.downloadZipFile(props.name, props.mimeType, props.binaries);
                            }}
                          >
                            <DownloadSvg width={26} height={26} />
                          </TapArea>
                        ) : (
                          //TODO: @eburgos change this
                          <a
                            download={`${props.name}${mimeTypeToExtension(
                              props.strategy === "ecm-document" ? props.mimeType : undefined
                            )}`}
                            href={downloadHref}
                          >
                            <DownloadSvg width={26} height={26} />
                          </a>
                        )}
                      </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <TapArea
                        onTap={() => {
                          exitFullScreen();
                          props.sendViaEmail();
                        }}
                      >
                        <Tooltip text={resources.sendViaEmailPage.sendViaEmail}>
                          <ResendInvitationIconSvg width={22} height={22} />
                        </Tooltip>
                      </TapArea>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <TapArea
                        onTap={() => {
                          exitFullScreen();
                          props.signDocument();
                        }}
                      >
                        <Tooltip text={resources.signThisDocument}>
                          <SignDocumentSvg width={22} height={22} />
                        </Tooltip>
                      </TapArea>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <TapArea
                        onTap={() => {
                          exitFullScreen();
                          props.myLists();
                        }}
                      >
                        <Tooltip text={resources.myLists}>
                          <ListSvg width={22} height={22} />
                        </Tooltip>
                      </TapArea>
                    </Box>
                  </>
                )}

                <Box>
                  <Divider direction="vertical" color={colors.neutral600} height={30} />
                </Box>
              </Box>
            </>
          )}
          <>{rest.strategy === "ecm-document" ? rest.customIcon : null}</>
        </Box>
        <Divider size={1} direction="horizontal"></Divider>
      </Box>
    );
  }, [
    breakpoint,
    btnOptionsInHamburger,
    colors.neutral600,
    debouncedCurrentPage,
    downloadHref,
    exitFullScreen,
    handleAdjustHeightToggle,
    handleAdjustWidthToggle,
    handleCollapseImagePanelBtn,
    handleFirstImageBtn,
    handleFirstPageBtn,
    handleFullScreenToggle,
    handleFunctionsBundle,
    handleHandTool,
    handleLastImageBtn,
    handleLastPageBtn,
    handleNextImageBtn,
    handleNextPageBtn,
    handlePreviousImageBtn,
    handlePreviousPageBtn,
    handleRectangleSelectToggle,
    handleSpecifiedPageBtn,
    handleZoomInBtn,
    handleZoomOutBtn,
    handlerEnterKeyPress,
    handleTapSearchPanel,
    handleTapPrinter,
    isSearchPanelVisible,
    isImage,
    menuOptionLabels,
    props,
    resources.download,
    resources.print,
    resources.myLists,
    resources.searchPanel,
    resources.sendViaEmailPage.sendViaEmail,
    resources.signThisDocument,
    rest,
    showOnlyCloudIcon,
    srcAreImages,
    toolBarButtonName.adjustHeight,
    toolBarButtonName.adjustWidth,
    toolBarButtonName.collapseSidePanel,
    toolBarButtonName.firstPage,
    toolBarButtonName.fullScreen,
    toolBarButtonName.handTool,
    toolBarButtonName.lastPage,
    toolBarButtonName.nextPage,
    toolBarButtonName.previousPage,
    toolBarButtonName.rectangleTool,
    toolBarButtonName.zoomIn,
    toolBarButtonName.zoomOut,
    colors.neutral400,
    colors.white,
    selectedTool,
  ]);

  return (
    <Box display="flex" height="100%">
      {srcAreImages && imagesPanelOpen && (
        <SidePanel
          readOnly={readOnly}
          height={height}
          selected={selectedThumbnail}
          onSelectThumbnail={(index: number) => setSelectedThumbnail(index)}
          images={src}
          onMove={moveElement}
          onRotate={onRotate}
          onDelete={onDelete}
        />
      )}

      <Box width={"100%"} height="100%" display="flex" direction="column">
        <Box color={colors.white} {...viewerScreenSizeProps} height="100%">
          {(instanceLoadState === "loaded" || instanceRef.current) && <>{documentViewerToolbar}</>}
          <BoxWithRef
            flex="grow"
            display="flex"
            ref={viewerHTMLElementRef}
            width="100%"
            height="calc(100% - 58px)"
          ></BoxWithRef>
        </Box>
      </Box>
    </Box>
  );
}

export const DeleteDocumentModal: FunctionComponent<{
  show: boolean;
  resources: {
    cancel: string;
    delete_: string;
    yesDelete: string;
    youAreAboutToDeleteThisDocument: string;
    youAreAboutToDeleteConfirmation: string;
  };
  onDismiss(): void;
  deleteDocument: () => void;
  permanentDelete?: boolean;
}> = ({ show, resources, onDismiss, deleteDocument, permanentDelete }) => {
  const { colors } = useColors();
  return (
    show && (
      <Layer zIndex={modalZIndex}>
        <Modal
          accessibilityModalLabel={resources.delete_}
          heading={
            <Box>
              <Box marginBottom={4}>
                <Text weight="bold" size="400" color={colors.secondary}>
                  {resources.delete_}
                </Text>
              </Box>
              <Divider />
            </Box>
          }
          size="md"
          onDismiss={onDismiss}
          footer={
            <>
              <Divider />
              <Box marginTop={4} display="flex">
                <Box borderRadius={6} borderStyle="sm">
                  <Button color={"gray"} onClick={onDismiss} text={resources.cancel} />
                </Box>
                <Box marginStart="auto" borderRadius={6} borderStyle="sm">
                  <Button color={"red"} onClick={deleteDocument} text={resources.yesDelete} />
                </Box>
              </Box>
            </>
          }
        >
          <Box display="flex" alignItems="center">
            <Text>
              {permanentDelete
                ? resources.youAreAboutToDeleteThisDocument
                : resources.youAreAboutToDeleteConfirmation}
            </Text>
          </Box>
        </Modal>
      </Layer>
    )
  );
};
