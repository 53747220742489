// @flow

import "./AssignDataLinks.css";

import * as React from "react";

import { Button, Divider, Empty, Radio, Table } from "antd";
import type { ConnectorDataLink, DataLink } from "../_lib/types";

import { Icon } from "@prodoctivity/design-system";
import update from "immutability-helper";
import { DraggableBodyRow } from "../AssignConnectors/DraggableBodyRow";
import { FormDragDropContext } from "../_lib/DragDropContext";
import { DataLinkActions } from "../_lib/enums";
import { noop } from "../_lib/utils";
import { getMappingFromType } from "./utils";

//import noop from 'noop2'

const checkboxAndRadioStyle = { display: "block", marginLeft: 0 };

const MyDivider = () => (
  <div>
    <Divider type="vertical" style={{ height: "90%", top: "5%" }} />
  </div>
);

type DataLinksProps = {
  dataLinks: DataLink[];
  i18n: (key: string) => string;
  connectorDataLinks: ConnectorDataLink[];
  documentHandleList: number;
  onChange: (ConnectorDataLink: ConnectorDataLink[]) => void;
};

type DataLinksState = {
  dataLinks: DataLink[];
  connectorDataLinks: ConnectorDataLink[];
};

class AssignDataLinks extends React.PureComponent<DataLinksProps, DataLinksState> {
  static displayName = "AssignDataLinks";

  static defaultProps = {
    onChange: noop,
    i18n: noop,
  };

  state = {
    dataLinks: this.props.dataLinks.map((link) => ({
      ...link,
      key: link.description,
      name2: link.description + " [" + link.type + "]",
    })),
    connectorDataLinks: this.props.connectorDataLinks,
  };

  notifyChange = () => {
    this.props.onChange(this.state.connectorDataLinks);
  };

  columns = [
    {
      title: this.props.i18n("SuggestDataConnectors"),
      dataIndex: "name2",
      key: "description",
      onHeaderCell(column: any) {
        return { id: column.key };
      },
      filterMultiple: false,
      filters: [
        { text: this.props.i18n("Selected"), value: "SELECTED" },
        { text: this.props.i18n("Unselected"), value: "UNSELECTED" },
        { text: this.props.i18n("All"), value: "ALL" },
      ],
      onFilter: (
        value: string, //'SELECTED' | 'UNSELECTED' | 'ALL',
        record: DataLink
      ) => {
        if (value === "ALL") {
          return true;
        }
        const { connectorDataLinks } = this.state;
        const currentRecordIsSelected = connectorDataLinks.some(
          ({ description, keywordHandleList }) =>
            description === record.description && keywordHandleList.length > 0
        );
        if (value === "SELECTED") {
          return currentRecordIsSelected;
        }
        if (value === "UNSELECTED") {
          return !currentRecordIsSelected;
        }

        return `Unrecognized filter value: ${value}`;
        //throw new Error(`Unrecognized filter value: ${value}`)
      },
    },
  ];

  getHeaderCell = (headerCellProps: any) => {
    if (headerCellProps.id === "name") {
      return (
        <th
          style={{
            display: "flex",
            alignItems: "center",
          }}
          {...headerCellProps}
        >
          {headerCellProps.children}
        </th>
      );
    }

    return <th {...headerCellProps} />;
  };

  components = {
    body: {
      row: DraggableBodyRow,
    },
    header: {
      cell: this.getHeaderCell,
    },
  };

  moveRow = (dragIndex: number, hoverIndex: number) => {
    this.setState((prevState) => {
      const { dataLinks, connectorDataLinks } = update(prevState, {
        dataLinks: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevState.dataLinks[dragIndex]],
          ],
        },
        connectorDataLinks: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevState.connectorDataLinks[dragIndex]],
          ],
        },
      });

      return { dataLinks, connectorDataLinks };
    }, this.notifyChange);
  };

  clearSelectionTitle = () => {
    return <Button onClick={() => this.onClearSelection()}>{this.props.i18n("clear")}</Button>;
  };

  onClearSelection = () => {
    this.setState((prevState) => {
      const connectorDataLinks = prevState.connectorDataLinks.map((dataLink) => {
        dataLink.keywordHandleList = [];
        dataLink.fields = [];
        return dataLink;
      });
      return { connectorDataLinks };
    }, this.notifyChange);
  };

  rowSelection = {
    columnTitle: this.clearSelectionTitle(),
    onChange: (selectedRowKeys: any, selectedRows: Array<DataLink>) => {
      this.setState((prevState) => {
        const connectorDataLinks = prevState.connectorDataLinks.map((c) => {
          const index = selectedRows.findIndex((row) => row.id === c.connectorId);
          if (index >= 0) {
            const dataLink = this.state.dataLinks[index];
            const mapping = getMappingFromType(dataLink.type, dataLink.xmlParams);

            c.keywordHandleList = [mapping.PrimaryKey.Field.KeyTarget];
            if (Array.isArray(mapping.Fields.Field)) {
              mapping.Fields.Field.forEach((field: any) => {
                c.keywordHandleList.push(field.KeyTarget);
              });
            } else {
              c.keywordHandleList.push(mapping.Fields.Field.KeyTarget);
            }
          } else {
            c.keywordHandleList = [];
          }
          c.fields = c.keywordHandleList;
          return c;
        });
        return { connectorDataLinks };
      }, this.notifyChange);
    },
  };

  expandedRowRender = (record: DataLink) => {
    const index = this.state.connectorDataLinks.findIndex(
      (c) => c.description === record.description
    );
    const assignedDataLink = this.state.connectorDataLinks[index];
    const { i18n } = this.props;
    const mapping = getMappingFromType(record.type, record.xmlParams);
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {
          <div>
            {mapping &&
              record.keywords
                .filter(
                  (keyword) =>
                    // eslint-disable-next-line
                    keyword.keywordId == mapping.PrimaryKey.Field.KeyTarget
                )
                .map((keyword) => (
                  <div key={keyword.name}>
                    <h5>{i18n("PrimaryKey")}</h5>
                    <Icon icon="key" accessibilityLabel={i18n("primaryKey")} /> {keyword.name}
                  </div>
                ))}
          </div>
        }
        <MyDivider />

        <div>
          {mapping && Array.isArray(mapping.Fields.Field)
            ? mapping.Fields.Field.map((f: any) =>
                record.keywords
                  // eslint-disable-next-line
                  .filter((keyword) => keyword.keywordId == f.KeyTarget)
                  .map((keyword) => (
                    <div key={keyword.name}>
                      <span>{keyword.name}</span>
                    </div>
                  ))
              )
            : record.keywords
                .filter(
                  // eslint-disable-next-line
                  (keyword) => keyword.keywordId == mapping.Fields.Field.KeyTarget
                )
                .map((keyword) => (
                  <div key={keyword.name}>
                    <span>{keyword.name}</span>
                  </div>
                ))}
        </div>
        <MyDivider />

        <div>
          <div style={{ marginBottom: "1rem", marginRight: "6rem" }}>
            <strong>{i18n("ExistingDataFromDataLink")}</strong>
            <div>
              <Radio.Group
                name="shouldBlockDataExisting"
                defaultValue={
                  !assignedDataLink ? DataLinkActions.NotModify : assignedDataLink.existingData
                }
                onChange={(e) => {
                  this.setState((prevState) => {
                    const connectorDataLinks = [...prevState.connectorDataLinks];
                    connectorDataLinks[index].existingData = e.target.value;
                    return { connectorDataLinks };
                  }, this.notifyChange);
                }}
              >
                <Radio style={checkboxAndRadioStyle} value={DataLinkActions.AllowModify}>
                  {i18n("UnblockText")}
                </Radio>
                <Radio style={checkboxAndRadioStyle} value={DataLinkActions.NotModify}>
                  {i18n("BlockText")}
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div>
            <strong>{i18n("NoExistingDataFromDataLink")}</strong>
            <div>
              <Radio.Group
                name="shouldBlockDataNoExisting"
                defaultValue={
                  !assignedDataLink ? DataLinkActions.NotModify : assignedDataLink.notExistingData
                }
                onChange={(e) => {
                  this.setState((prevState) => {
                    const connectorDataLinks = [...prevState.connectorDataLinks];
                    connectorDataLinks[index].notExistingData = e.target.value;
                    return { connectorDataLinks };
                  }, this.notifyChange);
                }}
              >
                <Radio style={checkboxAndRadioStyle} value={DataLinkActions.AllowModify}>
                  {i18n("UnblockText")}
                </Radio>
                <Radio style={checkboxAndRadioStyle} value={DataLinkActions.NotModify}>
                  {i18n("BlockText")}
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <div>{i18n("DataLinkCustomMessagesForNoDataTitle")}:</div>
          <textarea
            defaultValue={this.state.connectorDataLinks
              .filter((cdn) => cdn.connectorId === record.id)
              .map((i) => i.noDataMessage)}
            style={{ width: "100%", height: "100%" }}
            onChange={(e) => {
              const message = e.currentTarget.value;
              this.setState((prevState) => {
                const connectorDataLinks = [...prevState.connectorDataLinks];
                connectorDataLinks[index].noDataMessage = message;
                return { connectorDataLinks };
              }, this.notifyChange);
            }}
          />
        </div>
      </div>
    );
  };
  onRow = (record: any, index?: number) => {
    return { index, moveRow: this.moveRow } as React.HTMLAttributes<any>;
  };

  render() {
    const { dataLinks, connectorDataLinks } = this.state;
    const isNotEmpty = dataLinks.length > 0 && connectorDataLinks.length > 0;
    const selectedRowKeys = connectorDataLinks
      .filter(({ keywordHandleList }) => keywordHandleList.length > 0)
      .map(({ description }) => description);
    return isNotEmpty ? (
      <Table
        className={"TableStyle"}
        //className={anchorTagsStyles}
        bordered
        components={this.components}
        rowSelection={{ ...this.rowSelection, selectedRowKeys }}
        expandedRowRender={this.expandedRowRender}
        columns={this.columns as any}
        onRow={this.onRow as any}
        dataSource={dataLinks}
      />
    ) : (
      <Empty description={<span>{this.props.i18n("NoConnectorsFound")}</span>} />
    );
  }
}

export const AssignDataLinksDragDropContext = FormDragDropContext(AssignDataLinks);
