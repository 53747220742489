import { Moment } from "moment";
import { FunctionComponent } from "react";
import { DatePicker, DatePickerProps } from "./DatePicker";

export type Normal = {
  format: string;
  placeholder: string;
  value?: Moment;
};

export const TimePicker: FunctionComponent<DatePickerProps> = (props) => {
  return <DatePicker {...props} dataType="Time" />;
};
