import { Box, Text, useColors } from "@prodoctivity/design-system";

import { FunctionComponent, useMemo } from "react";
import { BreadCrumbEntry } from "../../../components/BreadCrumb";
import { Page } from "../../../components/Layout/Page";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { usePageHeight } from "../../Dashboard/DashboardPageWrapper";
import { FormAddUserToOrganization } from "./FormAddUserToOrganization";

const AddUserToOrganizationPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  usePageHeight(true);

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      { type: "url", name: resources.settings, url: "/settings" },
      { type: "url", name: resources.users, url: "/settings/users" },
      { type: "text", name: resources.addNewUser },
    ];
  }, [resources]);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      <Box display="flex" direction="column" flex="grow" padding={4}>
        <Box>
          <Text weight="bold" size="400">
            {resources.addNewUser}
          </Text>
        </Box>
        <Box
          color={colors.white}
          alignContent="center"
          display="flex"
          direction="column"
          flex="grow"
        >
          <FormAddUserToOrganization />
        </Box>
      </Box>
    </Page>
  );
};

export default AddUserToOrganizationPage;
