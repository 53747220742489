"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateContextRecordHolderWithDictionaryDataElements = void 0;
const updateContextRecordHolderWithDictionaryDataElements = (contextRecord, dataElements) => {
    for (const [index, element] of contextRecord.fields.entries()) {
        const dataElement = dataElements.find((d) => d.humanName === element.humanName);
        if (dataElement) {
            const { name: _name, dictionaryDataType: _dictionaryDataType, unique: _unique, cultureLanguageName: _cultureLanguageName, sectionName: _sectionName, isReferenceField: _isReferenceField, readOnly: _readOnly, dictionaryListName: _dictionaryListName, input: _input, output: _output } = dataElement, properties = __rest(dataElement, ["name", "dictionaryDataType", "unique", "cultureLanguageName", "sectionName", "isReferenceField", "readOnly", "dictionaryListName", "input", "output"]);
            const contextFieldProperties = properties;
            contextRecord.fields[index] = {
                name: element.name,
                humanName: element.humanName,
                fullPath: element.fullPath,
                properties: contextFieldProperties,
            };
        }
    }
    for (const [index, element] of contextRecord.records.entries()) {
        const updatedContext = (0, exports.updateContextRecordHolderWithDictionaryDataElements)(element, dataElements);
        contextRecord.records[index].fields = updatedContext.fields;
        contextRecord.records[index].records = updatedContext.records;
    }
    return contextRecord;
};
exports.updateContextRecordHolderWithDictionaryDataElements = updateContextRecordHolderWithDictionaryDataElements;
