import { FunctionComponent, ReactNode, useState } from "react";

import { Icon, IconButton, IconType } from "../Icon";
import { ColorConfig } from "../../utils";
import { Box } from "../Box";
import { Divider } from "../Divider";
import { useColors } from "../ColorSchemeProvider";
import { Text } from "../Text";
import { Image } from "../Image";
import { StandardIconContainer } from "../StandardIconContainer";
import { TapArea } from "../TapArea";

export type LeftSidebarProps = {
  options: {
    name: string;
    icon: IconType | undefined;
    svgIcon:
      | string
      | FunctionComponent
      | React.FunctionComponent<{ color?: ColorConfig }>
      | undefined;
    color: ColorConfig | undefined;
    onTap: (() => void) | undefined;
    totalCount: number | undefined;
  }[];
  openState: {
    isOpen: boolean;
    setIsOpen: () => void;
  };
  customElement: ReactNode | undefined;
  title: string | undefined;
  setSelectedMenuItemCallback: ((item: string) => void) | undefined;
  resources: {
    open: string;
    close: string;
  };
  index: number | undefined;
};

export const LeftSidebar: FunctionComponent<LeftSidebarProps> = ({
  options,
  openState,
  customElement,
  title,
  setSelectedMenuItemCallback,
  resources,
  index,
}) => {
  const { colors } = useColors();

  return (
    <Box
      display="flex"
      flex="grow"
      direction="column"
      width={openState.isOpen ? "100%" : "auto"}
      color={colors.white}
    >
      <Box display="flex" height={"100%"}>
        <CollapsibleSidebar
          title={title}
          options={options}
          mode="desktop"
          isOpen={openState.isOpen}
          setIsOpen={openState.setIsOpen}
          customElement={customElement}
          setSelectedMenuItemCallback={setSelectedMenuItemCallback}
          resources={resources}
          index={index}
        />
        {openState.isOpen && <Divider direction="vertical" />}
      </Box>
    </Box>
  );
};

export const CollapsibleSidebar = ({
  options,
  title,
  mode,
  isOpen,
  setIsOpen,
  customElement,
  setSelectedMenuItemCallback,
  resources,
  index,
}: {
  options: LeftSidebarProps["options"];
  title: string | undefined;
  mode: "desktop" | "mobile";
  isOpen: boolean;
  setIsOpen: () => void;
  customElement: ReactNode | undefined;
  setSelectedMenuItemCallback?: (item: string) => void;
  resources: {
    open: string;
    close: string;
  };
  index: number | undefined;
}) => {
  const { colors } = useColors();

  const [selected, setSelected] = useState<string | null>(options[index ? index : 0].name);

  if (!isOpen) {
    return (
      <Box display="flex" flex="grow" direction="row" minWidth={40}>
        <Box
          display="flex"
          flex="shrink"
          direction="column"
          paddingY={2}
          maxHeight={60}
          alignItems="center"
        >
          <StandardIconContainer handleTap={setIsOpen}>
            <IconButton
              accessibilityLabel={resources.open}
              icon="chevron-right"
              size={"sm"}
              color={colors.neutral900}
            />
          </StandardIconContainer>
        </Box>
        <Divider direction="vertical" />
      </Box>
    );
  }

  return (
    <Box display="flex" direction="column" width={"100%"} flex="grow" minWidth={300}>
      <Box display="flex" direction="row" flex="shrink" paddingX={4} paddingY={2} maxHeight={60}>
        {title && (
          <Box display="flex" flex="grow" paddingY={3}>
            <Text weight="normal" size="200" color={colors.neutral900}>
              {title.toUpperCase()}
            </Text>
          </Box>
        )}
        {mode === "desktop" && (
          <Box display="flex" flex="shrink" alignItems="center" paddingX={1}>
            <IconButton
              onClick={setIsOpen}
              accessibilityLabel={isOpen ? resources.close : resources.open}
              icon="chevron-left"
              size={"sm"}
              color={colors.neutral900}
            />
          </Box>
        )}
      </Box>
      <Divider direction="horizontal" />
      {options.map((opt, idx) => {
        const isSelected = selected === opt.name;

        return (
          <Box
            key={`${opt.name}_${idx}`}
            onClickCapture={() => {
              setSelected(opt.name);
              setSelectedMenuItemCallback && setSelectedMenuItemCallback(opt.name);
            }}
            color={isSelected ? colors.primaryHover0 : undefined}
            hoverColor={colors.primaryHover0}
          >
            {isSelected ? <Box height={1} color={colors.neutral500}></Box> : null}
            <TabLink
              key={opt.name}
              current={isSelected}
              icon={opt.icon}
              svgIcon={opt.svgIcon}
              totalCount={opt.totalCount}
              color={opt.color}
              onTap={opt.onTap}
            >
              <Text
                weight={isSelected ? "bold" : "normal"}
                color={isSelected ? colors.primary : colors.subtle}
              >
                {opt.name}
              </Text>
            </TabLink>
            {isSelected ? <Box height={1} color={colors.neutral500}></Box> : null}
          </Box>
        );
      })}
      {customElement && <>{customElement}</>}
    </Box>
  );
};

function TabLink({
  icon,
  current,
  children,
  svgIcon,
  totalCount,
  onTap,
}: {
  children: ReactNode | undefined;
  current: boolean | undefined;
  icon: IconType | undefined;
  svgIcon: string | React.FunctionComponent<{ color?: ColorConfig }> | undefined;
  totalCount: number | undefined;
  color: ColorConfig | undefined;
  onTap: (() => void) | undefined;
}) {
  const { colors } = useColors();
  const SvgIcon = svgIcon;
  const iconColor = current ? colors.primary : colors.neutral700;

  return (
    <Box maxHeight={56} display="flex" paddingY={4}>
      <Box display="flex" alignItems="center" flex="grow">
        {current && <Box height={60} width={5} color={colors.primary} />}
        <TapArea onTap={onTap}>
          <Box
            paddingLeft={5}
            paddingRight={8}
            display="flex"
            alignItems="center"
            minHeight={56}
            flex="grow"
            marginStart={current ? 0 : 1}
          >
            <Box display="flex" alignItems="center" flex="grow" justifyContent="between" gap={4}>
              <Box display="flex" gap={4} alignItems="center">
                {SvgIcon && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={40}
                    height={40}
                    marginStart={-1}
                  >
                    {typeof SvgIcon === "function" ? (
                      <SvgIcon color={iconColor} />
                    ) : (
                      <Image
                        alt="tabLinkIcon"
                        src={SvgIcon}
                        naturalHeight={24}
                        naturalWidth={27}
                        color="transparent"
                      />
                    )}
                  </Box>
                )}
                {icon && (
                  <Icon
                    size="xs"
                    accessibilityLabel="profile"
                    color={current ? colors.primary : colors.subtle}
                    icon={icon}
                  />
                )}
                <Text
                  weight={current ? "bold" : "normal"}
                  color={current ? colors.primary : colors.subtle}
                >
                  {children}
                </Text>
              </Box>
              {totalCount !== undefined && (
                <Box
                  width={40}
                  display="flex"
                  justifyContent="center"
                  alignItems={"center"}
                  height={30}
                  borderRadius={12}
                  borderColor={current ? colors.primary : colors.neutral600}
                  color={current ? colors.primary100 : colors.neutral400}
                >
                  <Text
                    size="200"
                    align="center"
                    weight={current ? "bold" : "normal"}
                    color={current ? colors.primary : colors.subtle}
                  >
                    {totalCount > 99 ? "+" : undefined}
                    {totalCount > 99 ? 99 : totalCount < 0 ? 0 : totalCount}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </TapArea>
      </Box>
    </Box>
  );
}
