import { FunctionComponent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LoginInput, UserLogin } from "../../components/Login/UserLogin";

import { useDesignBreakpoint } from "@prodoctivity/design-system";
import type { HttpLoginResponse } from "@prodoctivity/types";
import { LoginLayout } from "../../components/Layout/LoginLayout";
import { LoginLayoutSmall } from "../../components/Layout/LoginLayoutFooterSmall";
import { UserLoginMedium } from "../../components/Login/UserLoginMedium";
import { NotificationMessage } from "../../components/NotificationMessage";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { usePageHeight } from "../Dashboard/DashboardPageWrapper";

function initState(): LoginInput {
  return {
    email: localStorage ? localStorage.getItem("login.email") || "" : "",
    password: "",
  };
}

type Props = {
  authenticate: (
    user: string,
    password: string,
    returnTo: string | undefined,
    withGoogle?: boolean
  ) => Promise<
    | { success: true; payload: HttpLoginResponse["payload"] }
    | { success: false; errorMessage: string }
  >;
};

const LoginPage: FunctionComponent<Props> = function Login({ authenticate }) {
  const { breakpoint } = useDesignBreakpoint();
  const { resources } = useAppTranslation();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get("returnTo") || undefined;
  const [showFailureToast, setShowFailureToast] = useState(false);

  usePageHeight(true);

  const [loginForm, setLoginInput] = useState<LoginInput>(initState);
  return (
    <>
      {(breakpoint === "large" || breakpoint === "hd") && (
        <LoginLayout>
          <UserLogin
            email={loginForm.email}
            password={loginForm.password}
            returnTo={returnTo}
            setLoginInput={setLoginInput}
            authenticationHandler={authenticate}
          />
        </LoginLayout>
      )}
      {(breakpoint === "medium" || breakpoint === "small") && (
        <LoginLayoutSmall>
          <UserLoginMedium
            email={loginForm.email}
            password={loginForm.password}
            returnTo={returnTo}
            setLoginInput={setLoginInput}
            authenticationHandler={authenticate}
          />
        </LoginLayoutSmall>
      )}
      {showFailureToast && (
        <NotificationMessage
          message={resources.unableToSignIn}
          position="bottom-left"
          type="error"
          handleDismiss={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </>
  );
};

export default LoginPage;
