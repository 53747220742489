import { Box, Button, Fieldset, Text, TextField } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useState } from "react";

import { validateEmail } from "@prodoctivity/shared";
import { useMutation } from "@tanstack/react-query";
import { NotificationMessage } from "../../../components/NotificationMessage";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";
import { useFormAddUserToOrganization } from "./hooks";

interface ToastMessage {
  type: "error" | "success" | "warn";
  message: string;
}

interface ErrorResponse {
  response: {
    data: {
      errors: Array<{ message: string }>;
    };
  };
}

export const FormAddUserToOrganization: FunctionComponent = () => {
  const { state, handleChange, resetForm } = useFormAddUserToOrganization();
  const [toastMessage, setToastMessage] = useState<ToastMessage>();
  const [showToast, setShowToast] = useState(false);
  const organizationNavigate = useOrganizationNavigate();
  const { addUserToOrganization, user } = useServices();
  const { resources } = useAppTranslation();
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const onSuccess = useCallback(() => {
    organizationNavigate(organizationLinkTemplates.manageUsers());
  }, [organizationNavigate]);

  const onError = useCallback(
    (ex: ErrorResponse) => {
      const errorMessage =
        ex.response?.data.errors?.length > 0
          ? resources.validations.invitationNotSentUserAlreadyExists
          : ex.response.data.errors[0].message;

      setToastMessage({
        type: "error",
        message: errorMessage,
      });
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4500);
    },
    [resources.validations.invitationNotSentUserAlreadyExists]
  );

  const onSettled = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const emailValidation = useCallback(
    (value: string) => {
      if (!validateEmail(value)) {
        setEmailErrorMessage(resources.invalidEmail);
      } else {
        setEmailErrorMessage("");
      }
    },
    [resources.invalidEmail]
  );
  const mutation = useMutation(async () => {
    if (user) {
      try {
        await addUserToOrganization(
          state.name.trim(),
          state.lastName.trim(),
          state.email.trim(),
          user.organizationId
        );
        onSuccess();
      } catch (e: any) {
        onError(e);
      } finally {
        onSettled();
      }
    }
  });

  const handleSubmit = useCallback(async () => {
    mutation.mutate();
  }, [mutation]);

  return (
    <Fieldset legend={resources.addNewUser} legendDisplay="hidden">
      <Box padding={4} />
      <Box display="flex" direction="column" borderRadius={4} padding={4} gap={8}>
        <Box display="flex" direction="row" flex="grow" justifyContent="between" gap={8}>
          <Box width={"47%"}>
            <Text weight="bold">{resources.firstName}</Text>
            <TextField
              id="name"
              name="name"
              onChange={(e) => handleChange("name", e.value)}
              label={resources.name}
              labelDisplay="hidden"
              placeholder={resources.enterName}
              value={state.name}
            />
          </Box>
          <Box width={"47%"}>
            <Text weight="bold">{resources.lastName}</Text>
            <TextField
              id="surname"
              name="surname"
              onChange={(e) => handleChange("lastName", e.value)}
              label={resources.lastName}
              labelDisplay="hidden"
              placeholder={resources.enterLastName}
              value={state.lastName}
            />
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="grow">
          <Text weight="bold">{resources.email}</Text>
          <TextField
            id="email"
            name="email"
            onChange={(e) => {
              emailValidation(e.value);
              handleChange("email", e.value);
            }}
            placeholder={resources.enterEmail}
            errorMessage={emailErrorMessage}
            label={resources.email}
            labelDisplay="hidden"
            value={state.email}
          />
        </Box>
        <Box display="flex" direction="column" flex="grow" />
        <Box display="flex" direction="row" paddingX={4} justifyContent="end" gap={4}>
          <Button
            color={"transparent"}
            type="button"
            text={resources.cancel}
            accessibilityLabel={resources.cancel}
            onClick={() => organizationNavigate("/settings/users/")}
          />

          <Button
            color="blue"
            type="button"
            text={resources.sendEmail}
            disabled={!!emailErrorMessage || !state.name || !state.lastName || !state.email}
            accessibilityLabel={resources.sendEmail}
            onClick={handleSubmit}
          />
        </Box>
        {showToast ? (
          <NotificationMessage
            type={"info"}
            message={toastMessage?.message}
            position="bottom-left"
            handleDismiss={() => setShowToast(false)}
          />
        ) : null}
      </Box>
    </Fieldset>
  );
};
