import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const GridViewIconsSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 24,
  height = 25,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 24 25`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_6438_110769)">
        <path
          d="M9.33268 3.16699H3.99935C3.64573 3.16699 3.30659 3.30747 3.05654 3.55752C2.80649 3.80756 2.66602 4.1467 2.66602 4.50033V9.83366C2.66602 10.1873 2.80649 10.5264 3.05654 10.7765C3.30659 11.0265 3.64573 11.167 3.99935 11.167H9.33268C9.6863 11.167 10.0254 11.0265 10.2755 10.7765C10.5255 10.5264 10.666 10.1873 10.666 9.83366V4.50033C10.666 4.1467 10.5255 3.80756 10.2755 3.55752C10.0254 3.30747 9.6863 3.16699 9.33268 3.16699ZM3.99935 9.83366V4.50033H9.33268V9.83366H3.99935Z"
          fill={calculateColor(color)}
        />
        <path
          d="M20.0007 3.16699H14.6673C14.3137 3.16699 13.9746 3.30747 13.7245 3.55752C13.4745 3.80756 13.334 4.1467 13.334 4.50033V9.83366C13.334 10.1873 13.4745 10.5264 13.7245 10.7765C13.9746 11.0265 14.3137 11.167 14.6673 11.167H20.0007C20.3543 11.167 20.6934 11.0265 20.9435 10.7765C21.1935 10.5264 21.334 10.1873 21.334 9.83366V4.50033C21.334 4.1467 21.1935 3.80756 20.9435 3.55752C20.6934 3.30747 20.3543 3.16699 20.0007 3.16699ZM14.6673 9.83366V4.50033H20.0007V9.83366H14.6673Z"
          fill={calculateColor(color)}
        />
        <path
          d="M9.33268 13.833H3.99935C3.64573 13.833 3.30659 13.9735 3.05654 14.2235C2.80649 14.4736 2.66602 14.8127 2.66602 15.1663V20.4997C2.66602 20.8533 2.80649 21.1924 3.05654 21.4425C3.30659 21.6925 3.64573 21.833 3.99935 21.833H9.33268C9.6863 21.833 10.0254 21.6925 10.2755 21.4425C10.5255 21.1924 10.666 20.8533 10.666 20.4997V15.1663C10.666 14.8127 10.5255 14.4736 10.2755 14.2235C10.0254 13.9735 9.6863 13.833 9.33268 13.833ZM3.99935 20.4997V15.1663H9.33268V20.4997H3.99935Z"
          fill={calculateColor(color)}
        />
        <path
          d="M20.0007 13.833H14.6673C14.3137 13.833 13.9746 13.9735 13.7245 14.2235C13.4745 14.4736 13.334 14.8127 13.334 15.1663V20.4997C13.334 20.8533 13.4745 21.1924 13.7245 21.4425C13.9746 21.6925 14.3137 21.833 14.6673 21.833H20.0007C20.3543 21.833 20.6934 21.6925 20.9435 21.4425C21.1935 21.1924 21.334 20.8533 21.334 20.4997V15.1663C21.334 14.8127 21.1935 14.4736 20.9435 14.2235C20.6934 13.9735 20.3543 13.833 20.0007 13.833ZM14.6673 20.4997V15.1663H20.0007V20.4997H14.6673Z"
          fill={calculateColor(color)}
        />
      </g>
      <defs>
        <clipPath id="clip0_6438_110769">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
