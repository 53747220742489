import type { DataType, DictionaryDataType } from "@prodoctivity/shared/src/index-types";
import { ReactNode, useCallback, useState } from "react";
import { Box, Button, Icon, TapArea, Text, useColors } from ".";

import { useTranslation } from "react-i18next";

type Value = string | undefined;
type OnChangeType = (params: { value: Value }) => void;
type Props = {
  value?: Value;
  dataType?: DictionaryDataType | DataType;
  options?: { value: Value; label: string }[];
  renderField: (value: Value, onChange: OnChangeType) => ReactNode;
  onChange: OnChangeType;
  onDone?: () => void;
  onCancel?: () => void;
};
export function SummaryField({
  value,
  onCancel,
  options,
  onChange,
  dataType,
  onDone,
  renderField,
}: Props) {
  const { colors } = useColors();
  const { t } = useTranslation();
  const resources = {
    cancel: t("cancel"),
    save: t("save"),
  };

  const [mode, setMode] = useState<"idle" | "editing">("idle");
  const [cache, setCache] = useState(value);
  const handleChange = useCallback(
    ({ value }: { value: Value }) => {
      setMode("editing");
      onChange({ value });
    },
    [onChange]
  );
  const handleSave = () => {
    setCache(value);
    setMode("idle");
    if (onDone) {
      onDone();
    }
  };
  const handleCancel = () => {
    setMode("idle");
    onChange({ value: cache });
    if (onCancel) {
      onCancel();
    }
  };

  let summary = value;

  if (typeof value === "boolean") {
    summary = value ? "Yes" : "No";
  } else if (dataType === "Boolean" || dataType === "Logical") {
    summary = value === "true" ? "Yes" : "No";
  }

  if (options) {
    const v = options.find((o) => o.value === value);
    if (v) {
      summary = v.label;
    }
  }

  return (
    <Box display="flex" width="100%" alignItems="start">
      {mode === "idle" && (
        <TapArea onTap={() => setMode("editing")}>
          <Box minHeight="1rem" justifyContent="between" display="flex">
            <Text>{summary}</Text>
            <Icon accessibilityLabel="Edit" icon="pencil" size={"sm"} color={colors.subtle} />
          </Box>
        </TapArea>
      )}
      <Box width="100%" hidden={mode === "idle"}>
        {renderField(value, handleChange)}
      </Box>
      <Box width="1.5rem" />
      {mode === "editing" && (
        <Box display="flex" gap={4}>
          <Box padding={1}>
            <Button text={resources.cancel} onClick={handleCancel} color="gray" />
          </Box>
          <Box padding={1}>
            <Button text={resources.save} onClick={handleSave} color="blue" />
          </Box>
        </Box>
      )}
    </Box>
  );
}
