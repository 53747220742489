import { ColorConfig } from "../utils";
import { Box } from "./Box";
import { useColors } from "./ColorSchemeProvider";
import { Icon } from "./Icon";
import { TextField } from "./TextField";

type SearchFieldProps = {
  accessibilityLabel: string;
  borderColor?: ColorConfig;
  id: string;
  value?: string;
  onChange: ({ value }: { value: string }) => void;
  marginStart?: 2 | 4 | 6;
  marginEnd?: 2 | 4 | 6;
};
export const SearchField = ({
  accessibilityLabel,
  id,
  value,
  onChange,
  marginStart,
  marginEnd,
  borderColor,
}: SearchFieldProps) => {
  const { colors } = useColors();
  return (
    <Box
      borderRadius={12}
      borderColor={borderColor ? borderColor : undefined}
      paddingX={1}
      color={colors.white}
      display={"flex"}
      alignItems={"center"}
      direction={"row"}
      marginStart={marginStart}
      marginEnd={marginEnd}
      width={"100%"}
    >
      <Box flex="grow">
        <TextField
          id={id}
          onChange={onChange}
          mode="unstyled"
          placeholder={accessibilityLabel}
          value={value}
        />
      </Box>
      <Box marginEnd={2}>
        <Icon
          size={5}
          color={colors.neutral600}
          accessibilityLabel={accessibilityLabel}
          icon="search"
        />
      </Box>
    </Box>
  );
};
