import { FunctionComponent } from "react";
import { ColorConfig, calculateColor } from "../../utils";
import { useColors } from "../ColorSchemeProvider";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const CollapseSidebarIcon: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral600;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M17.4894 14.9883L11.9074 19.6668C11.669 19.8667 11.669 20.2334 11.9074 20.4333L17.4894 25.1118C17.8148 25.3845 18.3105 25.1532 18.3105 24.7286V22.275C18.3105 21.9989 18.5344 21.775 18.8105 21.775H30.5002C30.7763 21.775 31.0002 21.5512 31.0002 21.275V18.825C31.0002 18.5489 30.7763 18.325 30.5002 18.325H18.8105C18.5344 18.325 18.3105 18.1012 18.3105 17.825V15.3715C18.3105 14.9469 17.8148 14.7156 17.4894 14.9883Z"
        fill={color ? calculateColor(color) : "#B3B3B3"}
      />
      <rect
        width="2.3"
        height="16.1"
        rx="0.5"
        transform="matrix(-1 0 0 1 10.2993 12)"
        fill={color ? calculateColor(color) : "#B3B3B3"}
      />
    </svg>
  );
};
