import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
  onClick?: () => void;
};

export const PendingTasksSvgIcon: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 40,
  height = 40,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13364_135308)">
        <path
          d="M5 7V30.9964H22.8465C22.1748 29.892 21.7591 28.6299 21.7591 27.2573C21.7591 27.1784 21.7751 27.1311 21.7751 27.068H15.8262V25.7743H21.903C21.9989 25.3167 22.1429 24.8592 22.3348 24.4333H15.8262V23.1553H23.0384C24.0458 21.6881 25.565 20.6311 27.3401 20.1735V7H5ZM14.6269 27.068H7.87846V20.5206H14.6269V27.068ZM14.6269 17.2233H7.87846V10.6917H14.6269V17.2233ZM23.9339 21.7985H15.8262V20.5206H23.9339V21.7985ZM23.9339 17.2233H15.8262V15.9454H23.9339V17.2233ZM23.9339 14.6044H15.8262V13.3107H23.9339V14.6044ZM23.9339 11.9697H15.8262V10.6917H23.9339V11.9697Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M13.2354 13.1845L10.9326 15.4563C10.8527 15.5352 10.7407 15.5826 10.6288 15.5826C10.5168 15.5826 10.4049 15.5352 10.3089 15.4406L9.25351 14.3677C9.07761 14.1784 9.07761 13.8629 9.25351 13.6736C9.42942 13.4685 9.71726 13.4685 9.89317 13.6578L10.6288 14.4151L12.6117 12.4588C12.8036 12.2852 13.0915 12.301 13.2674 12.4903C13.4273 12.6954 13.4273 13.011 13.2354 13.1845Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M13.2354 23.029L10.9326 25.2851C10.8527 25.364 10.7407 25.4113 10.6288 25.4113C10.5168 25.4113 10.4049 25.364 10.3089 25.2851L9.25351 24.1965C9.07761 24.0072 9.07761 23.6916 9.25351 23.5023C9.42942 23.2972 9.71726 23.2972 9.89317 23.4865L10.6288 24.2438L12.6117 22.3033C12.8036 22.114 13.0915 22.1297 13.2674 22.3191C13.4273 22.5242 13.4273 22.8397 13.2354 23.029Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M29.1793 21.499C28.9554 21.499 28.7635 21.5464 28.5396 21.5621C28.3477 21.5937 28.1398 21.5937 27.948 21.641C27.7401 21.6883 27.5482 21.7515 27.3403 21.8303C25.5013 22.4298 24.078 23.9129 23.5823 25.7745C23.4703 26.1847 23.3904 26.6107 23.3744 27.0682C23.3744 27.1313 23.3584 27.1786 23.3584 27.2575C23.3584 28.6932 23.9181 29.9869 24.7976 30.9966C24.9895 31.2175 25.1974 31.4068 25.4053 31.5961C25.6772 31.817 25.949 32.0063 26.2369 32.1798C27.1164 32.6847 28.1079 33.0002 29.1793 33.0002C32.3936 33.0002 35.0002 30.4129 35.0002 27.2575C35.0002 24.1022 32.3936 21.499 29.1793 21.499ZM30.9384 28.283H29.0514C28.8595 28.283 28.6836 28.2199 28.5396 28.0937C28.3637 27.9517 28.2518 27.7466 28.2518 27.4942C28.2518 27.4626 28.2678 27.4153 28.2838 27.368C28.2678 27.3364 28.2518 27.2891 28.2518 27.2575V23.55C28.2518 23.2976 28.3637 23.0925 28.5396 22.9505C28.6836 22.84 28.8595 22.7612 29.0514 22.7612C29.4991 22.7612 29.8509 23.1082 29.8509 23.55V26.7053H30.9384C31.3701 26.7053 31.7379 27.0682 31.7379 27.4942C31.7379 27.9201 31.3701 28.283 30.9384 28.283Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
        <path
          d="M28.5393 27.8415V28.0939C28.3634 27.9519 28.2515 27.7468 28.2515 27.4944C28.2515 27.4628 28.2675 27.4155 28.2834 27.3682C28.3154 27.5575 28.3954 27.731 28.5393 27.8415Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13364_135308">
          <rect width="30" height="26" fill="white" transform="translate(5 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};
