import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const DocumentaryStructureMenuIcon: FunctionComponent<Props> = ({ color }) => {
  const { colors } = useColors();
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1338_45218" maskUnits="userSpaceOnUse" x="7" y="8" width="26" height="24">
        <path
          d="M8.33301 25.8333H12.9997V30.5H8.33301V25.8333ZM10.6663 9.5H29.333V16.5H10.6663V9.5Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9993 25.8333V16.5M10.666 25.8333V21.1667H29.3327V25.8333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.9993 25.8335H31.666V30.5002H26.9993V25.8335ZM17.666 25.8335H22.3327V30.5002H17.666V25.8335Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.166 13H15.3327"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_1338_45218)">
        <path d="M6 6H34V34H6V6Z" fill={calculateColor(color ? color : colors.neutral900)} />
      </g>
    </svg>
  );
};
