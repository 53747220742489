import {
  Box,
  Icon,
  TapArea,
  Text,
  TextField,
  Tooltip,
  useColors,
} from "@prodoctivity/design-system";
import { formatNumber } from "@prodoctivity/shared";
import { ProfileDocumentListOption } from "../../../../../components/DocumentBookmarkTags/hook";
import { FilterOption } from "../../../../../components/OpenSearch/hooks";

import { useCallback, useMemo, useRef } from "react";
import {
  CascadeOptionsPopover,
  CascadeOptionsPopoverItem,
} from "../../../../../components/CascadeOptionsPopover/CascadeOptionsPopover";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import { ActiveDocumentListSVG } from "../../../../../svg/ActiveDocumentListSVG";
import { EllipseMenuSvg } from "../../../../../svg/EllipseMenuSvg";
import { InactiveDocumentListSVG } from "../../../../../svg/InactiveDocumentListSVG";
import { StarSVG } from "../../../../../svg/StarSVG";
import { useAutoFocus } from "../../../../../utils";
import { EditLabels } from "../hook";
import { useProfileDocumentLabelsListCount } from "./hook";

export type LabelListCheckBoxProps = {
  entry: ProfileDocumentListOption;
  onItemClick(item: ProfileDocumentListOption): void;
  icon: "favorites" | "list";
  index: number;
  labelIndex: number;
  setLabelIndex: (index: number) => void;
  setNewLabel: (label: string) => void;
  editMode: EditLabels;
  profileDocumentListOptions: FilterOption[];
  newLabel: string;
  handleUpdateProfileLists: (
    oldDocumentLists: string | undefined,
    newDocumentLists: string
  ) => void;
  setEditMode: (editMode: EditLabels) => void;
  handleSelectedLists: (item: ProfileDocumentListOption) => void;
  selectedLists: ProfileDocumentListOption[];
  isPopoverWindows: boolean;
  CascadeOptionsPopoverList: CascadeOptionsPopoverItem[];
  handleCascadeOptionsPopoverClick: (key: string) => void;
  profileDocumentLists:
    | {
        documentLists: Array<{
          isFavorites: boolean | undefined;
          name: string;
          icon: string | undefined;
          color: string | undefined;
        }>;
      }
    | undefined;
};

export const LabelListCheckBox = ({
  entry,
  onItemClick,
  icon,
  index,
  labelIndex,
  setLabelIndex,
  setNewLabel,
  editMode,
  profileDocumentListOptions: profileDocumentLabelsList,
  newLabel,
  handleUpdateProfileLists: handleUpdateProfileLabels,
  setEditMode,
  handleSelectedLists,
  selectedLists: selectedLabels,
  isPopoverWindows,
  CascadeOptionsPopoverList,
  handleCascadeOptionsPopoverClick,
  profileDocumentLists: profileDocumentLabels,
}: LabelListCheckBoxProps) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const textFieldRef = useRef<HTMLInputElement>(null);

  const { documentsLabelsCount, isLoadingDocumentsLabelsCountData, refetchDocumentsLabelsCount } =
    useProfileDocumentLabelsListCount({
      label: entry.isFavorites ? "favorites" : entry.label,
      isFavorites: entry.isFavorites,
      enabled: !isPopoverWindows,
    });

  useAutoFocus(textFieldRef, editMode.editMode && editMode.index === index && !entry.isFavorites);

  const onSingleItemClick = useCallback(() => {
    onItemClick(entry);
  }, [onItemClick, entry]);

  const renameList = useCallback(
    (oldValue: string, newValue: string) => {
      handleUpdateProfileLabels(oldValue, newValue);
      setEditMode({ editMode: false, index: 0 });
    },
    [handleUpdateProfileLabels, setEditMode]
  );

  const handleTap = useCallback(() => {
    if (
      !profileDocumentLabelsList.some(
        (label) => label.label.trim() === newLabel.trim() || newLabel.trim() === ""
      )
    ) {
      renameList(profileDocumentLabelsList[labelIndex].label, newLabel);
    }
  }, [profileDocumentLabelsList, newLabel, renameList, labelIndex]);

  const validateProfileLists = useMemo(() => {
    return profileDocumentLabelsList.some((label) => label.label.trim() === newLabel.trim());
  }, [profileDocumentLabelsList, newLabel]);

  const handleBlur = useCallback(
    ({
      currentTarget,
      relatedTarget,
    }: {
      currentTarget: EventTarget | null;
      relatedTarget: EventTarget | null;
    }) => {
      if (
        currentTarget instanceof Node &&
        relatedTarget instanceof Node &&
        currentTarget.contains(relatedTarget)
      ) {
        return;
      }
      setEditMode({ editMode: false, index: 0 });
      setNewLabel("");
    },
    [setEditMode, setNewLabel]
  );

  const starColor = useMemo(() => {
    if (!isPopoverWindows) {
      return selectedLabels?.length === 0 ||
        selectedLabels?.every((label) => label.isFavorites) ||
        selectedLabels?.some((label) => label.isFavorites)
        ? colors.secondary
        : colors.neutral900;
    }
    return selectedLabels?.some((label) => label.isFavorites)
      ? colors.secondary
      : colors.neutral900;
  }, [selectedLabels, colors, isPopoverWindows]);

  const onTapNewList = useCallback(() => {
    handleSelectedLists(entry);
  }, [handleSelectedLists, entry]);

  return (
    <Box paddingY={1}>
      <Box
        key={entry.value}
        display="flex"
        direction="row"
        onMouseDownCapture={() => {
          setLabelIndex(index);
          refetchDocumentsLabelsCount();
        }}
        onTouchStart={() => {
          setLabelIndex(index);
          refetchDocumentsLabelsCount();
        }}
      >
        {editMode.editMode && editMode.index === index && !entry.isFavorites ? (
          <Box
            flex="grow"
            display="flex"
            direction="row"
            alignItems="center"
            padding={2}
            gap={2}
            onBlur={handleBlur}
          >
            <Box flex="grow">
              <TextField
                placeholder={entry.label}
                ref={textFieldRef}
                id={"update-label-textfield"}
                value={newLabel}
                onChange={(event) => setNewLabel(event.value)}
                onKeyDown={(code) => {
                  if (
                    code.event.key === "Enter" &&
                    newLabel.trim() !== "" &&
                    !validateProfileLists
                  ) {
                    handleTap();
                  }
                }}
                errorMessage={validateProfileLists ? resources.listAlreadyExists : undefined}
              />
            </Box>
            <Box display="flex" direction="row" alignItems="baseline">
              <TapArea onTap={handleTap}>
                <Box
                  borderRadius={6}
                  color={validateProfileLists ? colors.neutral400 : colors.neutral100}
                  height={40}
                  width={40}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon icon="check" accessibilityLabel={resources.add} />
                </Box>
              </TapArea>
            </Box>
          </Box>
        ) : (
          <Box display="flex" direction="row" alignItems="center" flex="grow">
            <Tooltip text={entry.label}>
              <Box display="flex" direction="column">
                <Box display="flex" direction="row" alignItems="center" gap={2}>
                  <Box>
                    <TapArea
                      fullWidth={true}
                      onTap={() => {
                        onTapNewList();
                      }}
                    >
                      {icon === "favorites" ? (
                        <StarSVG width={25} height={25} color={starColor} />
                      ) : selectedLabels?.some((element) => element.value === entry.value) ? (
                        <ActiveDocumentListSVG width={25} height={25} />
                      ) : (
                        <InactiveDocumentListSVG width={25} height={25} />
                      )}
                    </TapArea>
                  </Box>
                  <TapArea onTap={onSingleItemClick}>
                    <Box display="flex" direction="column" gap={1}>
                      <Text weight="bold" size="200" overflow="ellipsis" ellipsisLength={30}>
                        {entry.label}
                      </Text>
                      {!isLoadingDocumentsLabelsCountData && (
                        <Box justifyContent="end">
                          {profileDocumentLabels &&
                            documentsLabelsCount !== undefined &&
                            !isPopoverWindows && (
                              <Text size="100">{`${formatNumber(documentsLabelsCount)} ${
                                resources.documentsLowercase
                              }`}</Text>
                            )}
                        </Box>
                      )}
                    </Box>
                  </TapArea>
                </Box>
              </Box>
            </Tooltip>
            {!isPopoverWindows && !entry.isFavorites && (
              <Box display="flex" flex="grow" justifyContent="end">
                <CascadeOptionsPopover
                  icon={<EllipseMenuSvg />}
                  label={undefined}
                  hoverLabel={undefined}
                  items={CascadeOptionsPopoverList}
                  onClick={(event) => {
                    handleCascadeOptionsPopoverClick(event);
                    refetchDocumentsLabelsCount();
                  }}
                  childPopoverActionOnClose={undefined}
                  searchFilter={false}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
