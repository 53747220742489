import { useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import type {
  HttpGetDocumentCollectionInstanceViewRequest,
  HttpGetDocumentCollectionInstanceViewResponse,
} from "@prodoctivity/types";
import { useCallback, useMemo, useRef, useState } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../../hooks/useOrganizationQuery";
import { useServices } from "../../../hooks/useServices";
import { RecentDocumentSearch } from "../../../interfaces/activities/ActivitySearch";
import { organizationLinkTemplates } from "../../../link-templates";
import { usePaginatedDataEndpoint } from "../../hooks";
import { documentsDisplaySettingInitialState } from "./RecentDocumentsGrid";

const documentsDisplaySettingKeyName = "documents_display_setting";

export const useRecentDocumentsWidget = () => {
  /* ---------------------------- #IMPORTS and STATES region ---------------------------- */
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const organizationNavigate = useOrganizationNavigate();
  const [rawItemIndex, rawSetItemIndex] = useState(documentsDisplaySettingInitialState);

  /* ---------------------------- #CONST DECLARATIONS ---------------------------- */
  const smallestBreakPoint = breakpoint === "small";

  const itemIndex = useMemo(() => {
    if (breakpoint === "small") {
      return 0;
    } else if (breakpoint === "medium") {
      return 1;
    }
    return rawItemIndex;
  }, [breakpoint, rawItemIndex]);

  const setItemIndex = useCallback((arg: number) => {
    if (window.localStorage) {
      window.localStorage.setItem(documentsDisplaySettingKeyName, `${arg}`);
    }
    rawSetItemIndex(arg);
  }, []);

  /* ---------------------------- #Document Recent---------------------------- */

  const { getListOfRecentDocument } = useServices();

  const [search, setSearch] = useState<RecentDocumentSearch>({
    rowsPerPage: "15",
    pageNumber: 0,
    sort: "desc",
  });

  const currentPage = search.pageNumber;
  const rowsPerPage = search.rowsPerPage;
  const pageLengthOptions: Array<RecentDocumentSearch["rowsPerPage"]> = ["15", "50", "100"];

  const setCurrentPage = useCallback((pageNum: number) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        pageNumber: pageNum,
      };
    });
  }, []);

  const setRowsPerPage = useCallback((rpp: RecentDocumentSearch["rowsPerPage"]) => {
    setSearch((currentSearch) => {
      return {
        ...currentSearch,
        rowsPerPage: rpp,
      };
    });
  }, []);

  const setPageLength = useCallback(
    (pageLength: typeof rowsPerPage) => {
      setRowsPerPage(pageLength);
      setCurrentPage(0);
    },
    [setCurrentPage, setRowsPerPage]
  );

  const getDocumentRecent = useCallback(() => {
    return getListOfRecentDocument(search);
  }, [getListOfRecentDocument, search]);

  const {
    isLoading,
    data: recentDocumentResponse,
    refetch,
    remove,
  } = useOrganizationQuery(`document-recent/${JSON.stringify(search)}`, getDocumentRecent, {
    refetchInterval: 60 * 1000,

    staleTime: 0,
  });

  const isNextButtonDisabled =
    !recentDocumentResponse ||
    recentDocumentResponse.requestedPageLength !== recentDocumentResponse.pageLength;
  const isPreviousButtonDisabled = !recentDocumentResponse || recentDocumentResponse.pageNumber < 1;

  const previousPage = useCallback(() => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, isPreviousButtonDisabled, setCurrentPage]);

  const nextPage = useCallback(() => {
    if (isNextButtonDisabled) {
      return;
    }

    setCurrentPage(currentPage + 1);
  }, [currentPage, isNextButtonDisabled, setCurrentPage]);

  /* ---------------------------- #FETCHING AND FUNCTIONS---------------------------- */

  const handleMoveTo = useCallback(
    (documentId: string) => {
      organizationNavigate(organizationLinkTemplates.documentId(documentId, "data"));
    },
    [organizationNavigate]
  );

  /* ---------------------------- #GRID CONDITIONS ---------------------------- */

  const displayDocumentsAsCards = useMemo(() => {
    return itemIndex < 3;
  }, [itemIndex]);

  const gridWidth = useRef<HTMLDivElement | null>(null);
  const gridSizes = [
    ["repeat(auto-fill, minmax(230px, 1fr)"],
    ["repeat(auto-fill, minmax(400px, 1fr)"],
    ["repeat(auto-fill, minmax(600px, 1fr)"],
  ];
  const gridTemplateColumns = useMemo(() => {
    return breakpoint === "small"
      ? ["repeat(auto-fill, 320px)"]
      : ["repeat(auto-fill, minmax(265px, 1fr)"];
  }, [breakpoint]);

  const gridTemplateRows = useMemo(() => {
    return ["repeat(auto-fill, 420px)"];
  }, []);

  const templateAnimationSet = useMemo(() => {
    return {
      initial: {
        display: "flex",
        opacity: 0,
        scale: 0.5,
        translate: "0 100px",
        width: "100%",
      },
      whileInView: { opacity: 1, scale: 1, translate: "0 0px" },
      transition: { duration: 0.7, animationFillMode: "both" },
      viewport: { once: true },
    };
  }, []);

  return {
    colors,
    resources,
    breakpoint,
    isLoading,
    handleMoveTo,
    currentPage,
    rowsPerPage,
    pageLengthOptions,
    setPageLength,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    previousPage,
    nextPage,
    rawItemIndex,
    rawSetItemIndex,
    itemIndex,
    setItemIndex,
    displayDocumentsAsCards,
    gridWidth,
    gridSizes,
    gridTemplateColumns,
    gridTemplateRows,
    templateAnimationSet,
    smallestBreakPoint,
    recentDocument: recentDocumentResponse?.recentDocuments || [],
    refetch,
    remove,
  };
};

export const useRecentDocumentCollectionInstanceView = () => {
  const {
    currentPage,
    paginatedData: data,
    isLoading,
    totalRowCount,
    rowsPerPage,
    setPageLength,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    previousPage,
    nextPage,
    refetch,
  } = usePaginatedDataEndpoint<
    HttpGetDocumentCollectionInstanceViewResponse["payload"],
    HttpGetDocumentCollectionInstanceViewRequest["queryParameters"]["rowsPerPage"],
    string
  >(
    "15",
    "",
    (services, currentPage, rowsPerPage) => {
      return services.getDocumentCollectionInstanceView(currentPage, rowsPerPage);
    },
    "document-collection-view"
  );

  return {
    currentPage,
    rowsPerPage,
    setPageLength,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    previousPage,
    nextPage,
    totalRowCount,
    pageLengthOptions: ["15", "30", "50"] as Array<typeof rowsPerPage>,
    RecentDocumentCollection: data?.documentCollectionInstanceView || [],
    isLoading,
    refetch,
  };
};

export const useRecentDocumentCollectionInstance = (instanceId: string) => {
  const { getDocumentCollectionInstanceDetails } = useServices();
  const getRecentDocumentCollectionInstance = useCallback(() => {
    return getDocumentCollectionInstanceDetails(instanceId);
  }, [getDocumentCollectionInstanceDetails, instanceId]);

  const { isLoading, data } = useOrganizationQuery(
    `document-collection-instance/${instanceId}`,
    getRecentDocumentCollectionInstance,
    {
      enabled: !!instanceId,
      staleTime: 10 * 60 * 1000,
      cacheTime: undefined,
      retry: false,
    }
  );

  return {
    collectionInstance: data?.instance,
    isLoading,
  };
};

export const useRecentDocumentSummary = (documentId: string, documentVersionId?: string) => {
  const { getDocumentSummaryInfo } = useServices();
  const getRecentDocumentSummaryCollection = useCallback(() => {
    return getDocumentSummaryInfo(documentId, documentVersionId);
  }, [getDocumentSummaryInfo, documentId, documentVersionId]);

  const { isLoading, data } = useOrganizationQuery(
    ["document-summary", documentId, documentVersionId || ""],
    getRecentDocumentSummaryCollection,
    {
      enabled: !!documentId,
      staleTime: !documentVersionId ? 1 * 60 * 1000 : 10 * 60 * 1000,
    }
  );

  return {
    documentSummary: data?.document,
    isLoading,
  };
};
