import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { PopoverCombobox, PopoverComboboxOption, Spinner } from "@prodoctivity/design-system";

import { PublicProfileInfo } from "../Profile/Public/PublicProfileInfo";
import { buildFilterRegex } from "@prodoctivity/shared";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";

type Props = {
  id: string;
  label: string;
  selectedUsername: string | undefined;
  accessibilityClearButtonLabel: string;
  noResultText: string;
  placeholder?: string;
  onSelect: (item: PopoverComboboxOption) => void;
  onClear: () => void | undefined;
};

export const OrganizationAvatarUserLookup: FunctionComponent<Props> = ({
  selectedUsername,
  placeholder,
  onSelect,
  onClear,
}) => {
  const { listAllOrganizationUsers, user: currentUser } = useServices();
  const [filter, setFilter] = useState("");
  const onFilterChange = useCallback((newFilter: string) => {
    setFilter(newFilter);
  }, []);

  const { resources } = useAppTranslation();
  const { data, isLoading } = useOrganizationQuery(
    `/organization-users`,
    listAllOrganizationUsers,
    {
      staleTime: 60 * 1000,
    }
  );
  const regex = buildFilterRegex(filter);
  const list = (data?.users || []).filter((user) => {
    const fullName = `${user.firstName} ${user.lastName}`;
    return regex.test(fullName);
  });

  const options = useMemo(() => {
    const options: PopoverComboboxOption[] = [];

    const lowercaseUser = currentUser?.username.toLowerCase();

    const currentUserOption = list.find((item) => item.username.toLowerCase() === lowercaseUser);

    if (currentUserOption) {
      options.push({
        value: currentUserOption.username,
        label: (
          <PublicProfileInfo username={currentUserOption.username} size={"300"} sizeImage={48} />
        ),
        strLabel: `${currentUserOption.firstName} ${currentUserOption.lastName}`,
      });
    }

    for (const item of list || []) {
      if (item.username !== currentUser?.username) {
        const user: PopoverComboboxOption = {
          value: item.username,
          label: <PublicProfileInfo username={item.username} size={"300"} sizeImage={48} />,
          strLabel: `${item.firstName} ${item.lastName}`,
        };
        options.push(user);
      }
    }

    return options;
  }, [list, currentUser]);

  const clear = useCallback(() => {
    if (onClear) {
      onClear();
    }
  }, [onClear]);

  if (isLoading || !data) {
    return <Spinner show={true} accessibilityLabel={resources.loading} />;
  }

  return (
    <PopoverCombobox
      getI18n={(k) => {
        const m: Record<string, string> = resources as any;
        return m[k] || k;
      }}
      options={options || []}
      onSelect={onSelect}
      placeholder={placeholder}
      direction="down"
      onClear={clear}
      selectedValue={selectedUsername}
      displayOption="strLabel"
      filter={filter}
      onFilterChange={onFilterChange}
      displayedText={placeholder}
    />
  );
};
