import { Box, Table, Text } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";

type Props = {
  tableTitle: string;
};

export const TableHeaderCells: FunctionComponent<Props> = ({ tableTitle }) => {
  const { resources } = useAppTranslation();
  return (
    <Table.Cell colSpan={7}>
      <Box display="flex" justifyContent="between" height={48}>
        <Box width={"8%"}>
          <Text></Text>
        </Box>
        <Box width={"48%"}>
          <Text size="300" weight="bold">
            {tableTitle}
          </Text>
        </Box>
        <Box minWidth={"fit-content"} width={"19%"}>
          <Text size="300" weight="bold">
            {resources.subscriptions.tableHeaderTitles.titleSubscriptionType}
          </Text>
        </Box>
        <Box minWidth={"fit-content"} width={"14%"} paddingX={1}>
          <Text size="300" weight="bold">
            {resources.subscriptions.tableHeaderTitles.titleSendToWeb}
          </Text>
        </Box>
        <Box minWidth={"fit-content"} width={"13%"} paddingX={1}>
          <Text size="300" weight="bold">
            {resources.subscriptions.tableHeaderTitles.titleSendToMobile}
          </Text>
        </Box>
      </Box>
    </Table.Cell>
  );
};
