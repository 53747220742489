import { Tooltip as GestaltTooltip, TooltipProps } from "gestalt";

import { FunctionComponent } from "react";

type Props = TooltipProps & {
  doNotShowIf?: () => boolean;
};

export const Tooltip: FunctionComponent<Props> = (props) => {
  const { doNotShowIf, ...rest } = props;
  if (doNotShowIf !== undefined && doNotShowIf()) {
    return <>{props.children}</>;
  }
  return <GestaltTooltip {...rest} />;
};
