"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOutContextFromDefinition = exports.extractWizardDefinitionFromSubsetOfContextDefinition = exports.filterFieldsBySaveToECM = void 0;
const fluency_shared_1 = require("./fluency-shared");
const logical_1 = require("./logical");
function filterFieldsBySaveToECM(contextDefinition) {
    const fields = contextDefinition.fields.filter((f) => f.properties.saveToECM === true);
    function filterFieldsInRecord(record) {
        const fieldsInRecord = record.fields.filter((f) => f.properties.saveToECM === true);
        const records = (record.records || [])
            .map(filterFieldsInRecord)
            .filter((r) => r.fields.length > 0 || r.records.length > 0);
        return Object.assign(Object.assign({}, record), { fields: fieldsInRecord, records: records });
    }
    const records = (contextDefinition.records || [])
        .map(filterFieldsInRecord)
        .filter((r) => r.fields.length > 0 || r.records.length > 0);
    return Object.assign(Object.assign({}, contextDefinition), { fields,
        records });
}
exports.filterFieldsBySaveToECM = filterFieldsBySaveToECM;
function extractWizardDefinitionFromSubsetOfContextDefinition(wizardDefinition, newContextDefinition) {
    const result = {
        defaultPageName: wizardDefinition.defaultPageName,
        defaultSectionName: wizardDefinition.defaultSectionName,
        dependencies: [],
        inferredDependencies: [],
        pages: wizardDefinition.pages
            .map((wPage) => {
            return mapPageToExtractedWizardDefinitionPage(wPage, newContextDefinition);
        })
            .filter((page) => !!page.sections.length),
    };
    if (!result.pages.some((page) => page.label === result.defaultPageName)) {
        result.defaultPageName = result.pages[0].label;
    }
    const defaultPage = result.pages.find((page) => page.label === result.defaultPageName);
    if (!defaultPage) {
        throw new Error("No default page");
    }
    if (!defaultPage.sections.some((section) => section.label === result.defaultSectionName)) {
        result.defaultSectionName = defaultPage.sections[0].label;
    }
    result.dependencies = wizardDefinition.dependencies.filter((dep) => {
        const expression = (0, logical_1.convertTreeFormLogicalExpressionToExpressionObject)(dep.expression);
        const involvedSources = expression.involvedSourcesGetter();
        return involvedSources.every((src) => {
            return contextDefinitionFieldExists(newContextDefinition, src);
        });
    });
    return result;
}
exports.extractWizardDefinitionFromSubsetOfContextDefinition = extractWizardDefinitionFromSubsetOfContextDefinition;
function mapPageToExtractedWizardDefinitionPage(wPage, newContextDefinition) {
    const result = {
        description: wPage.description,
        key: wPage.key,
        label: wPage.label,
        properties: wPage.properties,
        sections: wPage.sections
            .map((wSection) => {
            return mapSectionToExtractedWizardDefinitionSection(wSection, newContextDefinition);
        })
            .filter((section) => !!section.fields.length),
    };
    return result;
}
function mapSectionToExtractedWizardDefinitionSection(wSection, newContextDefinition) {
    const result = {
        description: wSection.description,
        key: wSection.key,
        properties: wSection.properties,
        label: wSection.label,
        fields: wSection.fields
            .map((wField) => {
            return mapWizardFieldToExtractedWizardDefinitionField(wField, newContextDefinition);
        })
            .reduce((acc, next) => {
            if (next) {
                acc.push(next);
            }
            return acc;
        }, [])
            .filter((wfield) => !wfield.isRecord || wfield.isDuplicated || (wfield.isRecord && !!wfield.fields.length)),
    };
    return result;
}
function mapWizardFieldToExtractedWizardDefinitionField(wField, newContextDefinition) {
    if (wField.isRecord) {
        const result = {
            isRecord: true,
            key: wField.key,
            label: wField.label,
            fields: wField.fields
                .map((cField) => {
                return mapWizardFieldToExtractedWizardDefinitionField(cField, newContextDefinition);
            })
                .reduce((acc, next) => {
                if (next) {
                    acc.push(next);
                }
                return acc;
            }, []),
        };
        if (!result.fields.length) {
            return undefined;
        }
        return result;
    }
    else {
        if (!contextDefinitionFieldExists(newContextDefinition, wField.key)) {
            return undefined;
        }
        const result = {
            isRecord: false,
            key: wField.key,
            label: wField.label,
        };
        return result;
    }
}
function contextDefinitionFieldExists(holder, fullPath) {
    if (fullPath.indexOf("/") >= 0) {
        const first = fullPath.split("/")[0];
        const record = (holder.records || []).find((rec) => rec.name === first);
        if (!record) {
            return false;
        }
        return contextDefinitionFieldExists(record, fullPath.split("/").slice(1).join("/"));
    }
    else {
        return (holder.fields || []).some((fld) => fld.name === fullPath);
    }
}
function filterOutContextFromDefinition(contextDefinition, payload) {
    const result = {};
    (contextDefinition.fields || []).forEach((fld) => {
        if (!(0, fluency_shared_1.isCalculatedField)(fld.properties) && payload[fld.name] !== undefined) {
            result[fld.name] = payload[fld.name];
        }
    });
    (contextDefinition.records || []).forEach((rec) => {
        const arr = payload[rec.name];
        if (Array.isArray(arr)) {
            const newArray = [];
            result[rec.name] = newArray;
            arr.forEach((element) => {
                if (typeof element !== "object") {
                    return;
                }
                newArray.push(filterOutContextFromDefinition(rec, element));
            });
        }
    });
    return result;
}
exports.filterOutContextFromDefinition = filterOutContextFromDefinition;
