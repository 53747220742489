import { useColors, useDesignBreakpoint } from "@prodoctivity/design-system";
import type { DocumentTypeInfo, EcmDocument } from "@prodoctivity/types";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useAllApprovalTypes } from "../../hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { useCacheManager } from "../../utils";
import { ToastMessageType } from "../NotificationMessage";

export const useViewerDocumentApprovals = (
  ecmDocument: EcmDocument,
  documentTypeInfo: DocumentTypeInfo
) => {
  const { colors } = useColors();

  const { resources, moment } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const organizationNavigate = useOrganizationNavigate();

  const { user, getOrganizationUser, getDocumentApprovals, approveDocument } = useServices();
  const { clearQueryCache } = useCacheManager();

  const [toastMessage, setToastMessage] = useState<ToastMessageType | undefined>(undefined);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [showPending, setShowPending] = useState<boolean>(false);
  const [showApprovals, setShowApprovals] = useState<boolean>(true);

  const getOrganizationUserDetails = useCallback(async () => {
    if (user) {
      return await getOrganizationUser(user.username);
    }
    return { user: undefined };
  }, [user, getOrganizationUser]);

  const { data: userDetails, isLoading } = useOrganizationQuery(
    `/${user?.username}/profile`,
    getOrganizationUserDetails
  );

  const fetchDocumentApprovals = useCallback(() => {
    return getDocumentApprovals(ecmDocument.documentId);
  }, [ecmDocument, getDocumentApprovals]);

  const { data: documentApprovalsResponse, isLoading: isLoadingApprovals } = useOrganizationQuery(
    `/documents/${ecmDocument.documentId}/approvals`,
    fetchDocumentApprovals
  );

  const { approvalTypesResponse } = useAllApprovalTypes();

  const approvalTypes = useMemo(() => {
    return (approvalTypesResponse?.approvalTypes || []).filter((a) =>
      (documentTypeInfo.approvalConfig?.approvalTypes || []).some(
        (approvalType) => approvalType.id === a.id
      )
    );
  }, [approvalTypesResponse, documentTypeInfo]);

  const allowApprove: { allow: boolean; reason: string | undefined } = useMemo(() => {
    if (!user || !userDetails?.user) {
      return { allow: false, reason: resources.invalid };
    }
    const alreadyApprove = (ecmDocument.approvals || []).some(
      (approval) => approval.username === user.username && !approval.voided
    );
    if (alreadyApprove) {
      return { allow: false, reason: resources.alreadyApproved };
    }

    if (!documentTypeInfo.approvalConfig || !approvalTypes.length) {
      return { allow: true, reason: undefined };
    }

    if (documentTypeInfo.approvalConfig.approvalPermissionType === "any-user") {
      return { allow: true, reason: undefined };
    }

    const approvalRoles = approvalTypes.reduce((arr: Array<string>, curr) => {
      const roles = curr.roles.filter((r) => !arr.includes(r));
      arr.push(...roles);
      return arr;
    }, []);

    const allow = approvalRoles.some((role) => userDetails.user.assignedRoleIdList.includes(role));
    return {
      allow,
      reason: allow ? undefined : resources.itSeemsDontHavePermissions,
    };
  }, [user, ecmDocument, documentTypeInfo, userDetails, resources, approvalTypes]);

  const onApprove = useCallback(() => {
    if (allowApprove.allow) {
      setShowApproveModal(true);
    }
  }, [allowApprove, setShowApproveModal]);

  const {
    mutate: mutateApprove,
    isLoading: isApproving,
    isSuccess,
  } = useMutation(() => approveDocument(ecmDocument.documentId), {
    onSuccess: useCallback(() => {
      setShowApproveModal(false);
      setToastMessage({ type: "success", message: resources.approved });
      clearQueryCache();
      setTimeout(() => {
        organizationNavigate(
          organizationLinkTemplates.documentId(ecmDocument?.documentId, "approvals")
        );
      }, 3000);
    }, [organizationNavigate, clearQueryCache, ecmDocument, resources]),
    onError: (error: {
      response: { data: { errors: Array<{ message: string; errorCode: string }> } };
    }) => {
      if (error.response.data.errors.length > 0) {
        setShowApproveModal(false);
        setToastMessage({ type: "error", message: error.response.data.errors[0].message });
      }
    },
  });

  return {
    colors,
    resources,
    moment,
    breakpoint,
    approvalTypes,
    documentApprovals: documentApprovalsResponse || { approvals: [], requiredApprovals: [] },
    isLoadingApprovals,
    showPending,
    setShowPending,
    showApprovals,
    setShowApprovals,
    showApproveModal,
    setShowApproveModal,
    onApprove,
    allowApprove,
    isLoading,
    isApproving,
    isSuccess,
    mutateApprove,
    toastMessage,
    setToastMessage,
  };
};
