import { FunctionComponent } from "react";
type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};
export const TaskInstructionsIconsSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 23,
  height = 30,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      style={svgStyle}
      viewBox={`0 0 23 30`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M21.5457 3.07227H15.8436V4.79338H20.7209V27.6817H1.91589V4.79338H6.79321V3.07227H1.05616C0.828367 3.07227 0.609904 3.16276 0.44883 3.32383C0.287756 3.48491 0.197266 3.70337 0.197266 3.93116V28.5423C0.197266 28.7701 0.287756 28.9885 0.44883 29.1496C0.609904 29.3107 0.828367 29.4012 1.05616 29.4012H21.5457C21.659 29.4015 21.7711 29.3795 21.8758 29.3363C21.9804 29.2931 22.0755 29.2297 22.1556 29.1496C22.2356 29.0696 22.2991 28.9745 22.3422 28.8698C22.3854 28.7652 22.4075 28.653 22.4071 28.5398V3.92867C22.4068 3.81588 22.3843 3.70425 22.3408 3.60017C22.2973 3.49609 22.2338 3.40159 22.1538 3.32207C22.0738 3.24254 21.979 3.17955 21.8746 3.13669C21.7703 3.09383 21.6585 3.07194 21.5457 3.07227Z"
        fill="#707070"
      />
      <path
        d="M8.52823 10.6113L5.76363 12.8263C5.61196 12.9463 5.42485 13.0126 5.2315 13.015H5.1284C5.01691 13.0013 4.9093 12.9654 4.81191 12.9094C4.71452 12.8535 4.62932 12.7785 4.56135 12.6891L3.41061 11.264C3.33884 11.1768 3.28534 11.0761 3.25331 10.9678C3.22128 10.8596 3.21137 10.7459 3.22418 10.6338C3.23698 10.5216 3.27223 10.4131 3.32782 10.3148C3.38342 10.2166 3.45823 10.1305 3.5478 10.0617C3.72368 9.91941 3.94861 9.85225 4.17373 9.87482C4.39884 9.8974 4.60596 10.0079 4.75009 10.1823L5.36869 10.9547L7.44733 9.23774C7.53528 9.16676 7.63635 9.11381 7.74477 9.0819C7.85319 9.04999 7.96683 9.03974 8.0792 9.05174C8.19158 9.06375 8.30049 9.09777 8.39972 9.15187C8.49894 9.20597 8.58655 9.27908 8.65752 9.36703C8.72849 9.45498 8.78144 9.55605 8.81336 9.66447C8.84527 9.77288 8.85552 9.88652 8.84351 9.9989C8.8315 10.1113 8.79748 10.2202 8.74338 10.3194C8.68929 10.4186 8.61618 10.5062 8.52823 10.5772V10.6113Z"
        fill="#707070"
      />
      <path
        d="M17.3011 12.1577C17.3011 12.3855 17.2107 12.604 17.0496 12.7651C16.8885 12.9261 16.67 13.0166 16.4422 13.0166H9.69581C9.46802 13.0166 9.24955 12.9261 9.08848 12.7651C8.9274 12.604 8.83691 12.3855 8.83691 12.1577C8.84118 11.9313 8.93303 11.7153 9.09319 11.5551C9.25335 11.3949 9.46935 11.3031 9.69581 11.2988H16.4447C16.6708 11.3037 16.8861 11.3959 17.0458 11.556C17.2054 11.7161 17.2969 11.9317 17.3011 12.1577Z"
        fill="#707070"
      />
      <path
        d="M8.1502 20.2969L5.40639 22.5119C5.24936 22.6343 5.05592 22.7007 4.8568 22.7006C4.62739 22.673 4.41818 22.5559 4.27478 22.3747L3.11739 20.9495C2.98027 20.7673 2.91808 20.5395 2.94359 20.3128C2.9691 20.0862 3.08038 19.8779 3.25458 19.7306C3.43253 19.5902 3.65878 19.5258 3.88399 19.5515C4.1092 19.5772 4.31513 19.6909 4.45687 19.8678L5.07547 20.6402L7.15412 18.9233C7.33174 18.78 7.55903 18.713 7.78598 18.7373C8.01294 18.7615 8.22097 18.875 8.3643 19.0526C8.50764 19.2302 8.57454 19.4575 8.55029 19.6844C8.52605 19.9114 8.41263 20.1194 8.23501 20.2628L8.1502 20.2969Z"
        fill="#707070"
      />
      <path
        d="M16.6849 21.8429C16.6849 22.0706 16.5944 22.2891 16.4333 22.4502C16.2723 22.6113 16.0538 22.7017 15.826 22.7017H9.69567C9.58069 22.7053 9.46616 22.6858 9.35887 22.6442C9.25159 22.6027 9.15375 22.54 9.07114 22.46C8.98854 22.3799 8.92287 22.2841 8.87802 22.1781C8.83317 22.0722 8.81006 21.9583 8.81006 21.8433C8.81006 21.7282 8.83317 21.6144 8.87802 21.5084C8.92287 21.4025 8.98854 21.3066 9.07114 21.2266C9.15375 21.1465 9.25159 21.0838 9.35887 21.0423C9.46616 21.0008 9.58069 20.9812 9.69567 20.9848H15.8435C16.0681 20.9894 16.282 21.0817 16.4392 21.2421C16.5965 21.4026 16.6847 21.6182 16.6849 21.8429Z"
        fill="#707070"
      />
      <path
        d="M5.93506 1.45898V6.38786H16.6683V1.45898H5.93506ZM15.8444 5.53312H6.79395V2.31788H15.8444V5.53312Z"
        fill="#707070"
      />
      <path
        d="M16.6683 0.599609H5.93507C5.70727 0.599609 5.48881 0.690099 5.32774 0.851173C5.16666 1.01225 5.07617 1.23071 5.07617 1.4585V6.38738C5.07617 6.50017 5.09839 6.61186 5.14155 6.71606C5.18472 6.82027 5.24798 6.91495 5.32774 6.99471C5.40749 7.07447 5.50218 7.13773 5.60638 7.1809C5.71059 7.22406 5.82228 7.24627 5.93507 7.24627H16.6683C16.8954 7.24518 17.1128 7.1542 17.273 6.99324C17.4332 6.83229 17.5231 6.61445 17.5231 6.38738V1.4585C17.5231 1.23143 17.4332 1.01359 17.273 0.852639C17.1128 0.691684 16.8954 0.600708 16.6683 0.599609ZM15.8444 5.52848H6.79729V2.31657H15.8435L15.8444 5.52848Z"
        fill="#707070"
      />
    </svg>
  );
};
