import type { ActionLogEntry, BaseDocumentActionLogEntry, EcmDocument } from "@prodoctivity/types";
import { Box, ResendInvitationIconSvg, Skeleton, Text } from "@prodoctivity/design-system";
import { useHistoryActivitiesPage } from "../../pages/activities/hooks";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { PublicProfile } from "../Profile/Public/PublicProfile";
import { SaveLogSvg } from "../../svg/SaveLogSvg";
import { useMemo } from "react";
import { ViewLogSvg } from "../../svg/ViewLogSvg";
import { RestoreDocument } from "../../svg/RestoreDocument";
import { DeleteDocument } from "../../svg/DeleteDocument";
import { Pagination } from "../Layout/Pagination";
import { FancyDateTime, FancyDateTimeProps } from "@prodoctivity/design-system";

type Props = {
  document: EcmDocument;
};

type HistoryLogsProps = Props & {
  entries: ActionLogEntry;
  username: string;
};

type DocumentActionDetailsListProps = HistoryLogsProps & {
  iconComponent: JSX.Element;
  entryList: BaseDocumentActionLogEntry[] | undefined;
  username: string;
  newVersion: boolean;
};

type documentActionLogEntryProps = HistoryLogsProps &
  Props & {
    createdAt: number;
  } & Pick<FancyDateTimeProps, "moment" | "resources">;

export const ViewerDocumentRecordActionLog = ({ document }: Props) => {
  const {
    HistoryLogs,
    currentPage,
    nextPage,
    previousPage,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    rowsPerPage,
    setPageLength,
    pageLengthOptions,
    isLoading,
  } = useHistoryActivitiesPage(document.documentId);
  const { resources, moment } = useAppTranslation();

  return (
    <Box overflow="auto" display="flex" direction="column" gap={2} paddingX={2} paddingY={6}>
      <Box borderStyle="sm" padding={1} rounding={2}>
        <Pagination<typeof rowsPerPage>
          id="document_history_list_pagination_top"
          rowsLabel={`${resources.history}`}
          currentPage={currentPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageLength={HistoryLogs?.entries?.length || 0}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setPageLength}
          isNextButtonDisabled={isNextButtonDisabled}
          isPreviousButtonDisabled={isPreviousButtonDisabled}
          pageLengthOptions={pageLengthOptions}
        />
      </Box>
      {HistoryLogs?.entries &&
        HistoryLogs?.entries.length > 0 &&
        !isLoading &&
        HistoryLogs?.entries.map((entry, idx) => {
          switch (entry.entryType) {
            case "document-view":
            case "document-saved":
            case "document-restored":
            case "document-generated":
            case "document-deleted":
            case "document-shared":
              return (
                <Box key={idx} borderStyle="sm" rounding="pill">
                  <DocumentActionLogEntry
                    document={document}
                    entries={entry}
                    username={entry.username}
                    createdAt={entry.createdAt}
                    resources={resources}
                    moment={moment}
                  />
                </Box>
              );
            default:
              return null;
          }
        })}
      {isLoading ? (
        <Box padding={4} display="flex" justifyContent="center">
          <Skeleton show={isLoading} />
        </Box>
      ) : (
        <Box borderStyle="sm" rounding="pill">
          <DocumentActionLogEntry
            document={document}
            username={document.createdBy}
            createdAt={document.createdAt}
            entries={{
              id: "",
              entryCount: 0,
              entryType: "document-saved",
              organizationId: "",
              indexed: false,
              createdAt: Date.now(),
              username: "",
              entries: [],
            }}
            resources={resources}
            moment={moment}
          />
        </Box>
      )}

      <Box padding={2} />
      <Box borderStyle="sm" padding={1} rounding={2}>
        <Pagination<typeof rowsPerPage>
          id="document_history_list_pagination_top"
          rowsLabel={`${resources.history}`}
          currentPage={currentPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageLength={HistoryLogs?.entries?.length || 0}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setPageLength}
          isNextButtonDisabled={isNextButtonDisabled}
          isPreviousButtonDisabled={isPreviousButtonDisabled}
          pageLengthOptions={pageLengthOptions}
        />
      </Box>
    </Box>
  );
};

const DocumentActionLogEntry = ({
  document,
  entries,
  username,
  createdAt,
  resources,
  moment,
}: documentActionLogEntryProps) => {
  return (
    <Box
      borderStyle="shadow"
      display="flex"
      alignItems="center"
      justifyContent="between"
      direction="column"
      rounding="pill"
      overflow="hidden"
    >
      <Box
        padding={4}
        display="flex"
        justifyContent="between"
        alignItems="center"
        width={"100%"}
        position="relative"
      >
        <Box display="flex" justifyContent="start" position="absolute" marginTop={4} top>
          <PublicProfile
            username={username}
            onlyImage={true}
            sizeImage={32}
            evictUserListCache={true}
            size="200"
          />
        </Box>
        <Box marginStart={10} width={300}>
          {entries.id.length === 0 && (
            <Text>
              <PublicProfile
                username={username}
                onlyImage={false}
                evictUserListCache={true}
                size="200"
              />
            </Text>
          )}
          <DocumentActionLogViewer entries={entries} username={username} document={document} />
          <Box display="flex" gap={2} paddingX={1}>
            <FancyDateTime
              moment={moment}
              resources={resources}
              weight="bold"
              size="200"
              value={createdAt}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DocumentActionDetailsList = ({
  iconComponent,
  entries,
  document,
  username,
  newVersion,
}: DocumentActionDetailsListProps) => {
  const { resources } = useAppTranslation();

  const renderTitleBasedOnEntryType = useMemo(() => {
    switch (entries.entryType) {
      case "document-saved":
        return resources.saved;
      case "document-view":
        return resources.hasSeen;
      case "document-restored":
        return resources.restored;
      case "document-deleted":
        return resources.delete_;
      case "document-shared":
        return resources.shared;
      default:
        return "";
    }
  }, [entries.entryType, resources]);

  return (
    <Box
      display="flex"
      direction="column"
      justifyContent="start"
      width={"100%"}
      position="relative"
      paddingX={1}
    >
      <Box display="flex" alignItems="center" position="absolute" right top>
        {iconComponent}
      </Box>
      <Box display="flex" alignItems="start" direction="column" gap={1} wrap>
        <PublicProfile username={username} onlyImage={false} evictUserListCache={true} size="200" />
        <Box display="flex" gap={1} alignItems="center" wrap>
          <Text size="200" weight="bold">
            {renderTitleBasedOnEntryType}
          </Text>
          {newVersion && (
            <Text size="200" weight="bold">
              {resources.formDesigner.newVersion.toLowerCase()}
            </Text>
          )}
          <Text size="200">{document.name}</Text>
        </Box>
      </Box>
    </Box>
  );
};

const DocumentActionLogViewer = ({ entries, document }: HistoryLogsProps) => {
  let entryList = undefined;
  let iconComponent;
  let newVersion = false;

  switch (entries.entryType) {
    case "document-view":
      iconComponent = <ViewLogSvg width={30} height={15} />;
      entryList = entries.entries;
      break;
    case "document-restored":
      iconComponent = <RestoreDocument width={40} height={15} />;
      entryList = entries.entries;
      break;
    case "document-deleted":
      iconComponent = <DeleteDocument width={30} height={20} />;
      entryList = entries.entries;
      break;
    case "document-shared":
      iconComponent = <ResendInvitationIconSvg width={30} height={20} />;
      entryList = entries.entries;
      break;
    case "document-saved":
      iconComponent = <SaveLogSvg width={30} height={20} />;
      entryList = entries.entries;
      for (let i = 1; i < entryList.length; i++) {
        if (entryList[i].documentVersionId !== entryList[i - 1].documentVersionId) {
          newVersion = true;
          break;
        }
      }
      break;
    default:
      return null;
  }

  return (
    <DocumentActionDetailsList
      iconComponent={iconComponent}
      entryList={entryList}
      entries={entries}
      document={document}
      username={entries.username}
      newVersion={newVersion}
    />
  );
};
