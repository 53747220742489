import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const EmailsSvg: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      className={customClassName || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.6663 3.00033C23.6663 1.71699 22.6163 0.666992 21.333 0.666992H2.66634C1.38301 0.666992 0.333008 1.71699 0.333008 3.00033V17.0003C0.333008 18.2837 1.38301 19.3337 2.66634 19.3337H21.333C22.6163 19.3337 23.6663 18.2837 23.6663 17.0003V3.00033ZM21.333 3.00033L11.9997 8.83366L2.66634 3.00033H21.333ZM21.333 17.0003H2.66634V5.33366L11.9997 11.167L21.333 5.33366V17.0003Z"
          fill={calculateColor(color || colors.neutral900)}
        />
      </svg>

      <defs>
        <clipPath id="clip0_3153_102082">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
