import {
  Box,
  Button,
  Divider,
  Grid,
  Image,
  Spinner,
  TapArea,
  Text,
  TextField,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { useCallback, useState } from "react";

import { IconButton, ResendInvitationIconSvg } from "@prodoctivity/design-system";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../link-templates";

export function UserLoginWithSso() {
  const { colors } = useColors();
  const navigate = useNavigate();
  const { resources } = useAppTranslation();
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessageOrg, setErrorMessageOrg] = useState(false);
  const { breakpoint } = useDesignBreakpoint();
  const login = organizationLinkTemplates.login();

  const emailValidation = useCallback(() => {
    if (email.length > 0) {
      setShowError(true);

      setTimeout(() => {
        setShowError(false);
        setErrorMessageOrg(true);
      }, 3000);
    }
  }, [email.length]);

  const breakpointDuo = breakpoint === "small" || breakpoint === "medium";

  return (
    <Box
      color={breakpointDuo ? colors.white : undefined}
      height={breakpointDuo ? "100vh" : "80%"}
      display={breakpointDuo ? undefined : "flex"}
      alignItems={breakpointDuo ? undefined : "center"}
      justifyContent={breakpointDuo ? undefined : "center"}
      direction={breakpointDuo ? undefined : "column"}
    >
      <Box padding={4} paddingY={6}>
        <Grid width={"100%"} gridTemplateColumns={breakpointDuo ? [1, 1, 1] : [1]}>
          {breakpointDuo ? (
            <TapArea>
              <IconButton
                accessibilityLabel={resources.goBack}
                icon="arrow-left"
                onClick={() => navigate(login)}
              />
            </TapArea>
          ) : undefined}
          <TapArea onTap={() => navigate(login)}>
            <Box
              width={breakpointDuo ? 220 : 400}
              height={breakpointDuo ? 30 : 55}
              marginTop={breakpointDuo ? undefined : 12}
            >
              <Image
                src={logo}
                alt={resources.prodoctivityLogo}
                fit="cover"
                color="transparent"
                naturalHeight={50}
                naturalWidth={359}
                role="img"
              />
            </Box>
          </TapArea>
          <Box />
        </Grid>
      </Box>

      {!showError && !errorMessageOrg && (
        <Box
          display="flex"
          direction="column"
          padding={4}
          color={breakpointDuo ? undefined : colors.white}
          width={
            breakpointDuo
              ? undefined
              : breakpoint === "large"
              ? "55%"
              : breakpoint === "hd"
              ? "25%"
              : undefined
          }
          marginTop={breakpointDuo ? undefined : 12}
        >
          <Box marginTop={4}>
            <Text size={"400"} weight="bold">
              {resources.logInWithSso}
            </Text>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            borderStyle="lg"
            borderRadius={4}
            marginTop={4}
            paddingX={1}
          >
            <ResendInvitationIconSvg width={23} height={21} />
            <Box width={"100%"} height={"auto"}>
              <TextField
                id="emailField"
                type="email"
                autoComplete="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.value);
                }}
                mobileInputMode="text"
                mode={"unstyled"}
                placeholder={resources.enterEmail}
              />
            </Box>
          </Box>
          <Box margin={2} />
          <Box width="100%" display="flex" justifyContent="center">
            <Box width="100%">
              <Button
                color="blue"
                fullWidth
                accessibilityLabel={resources.signIn}
                text={resources.signIn}
                onClick={emailValidation}
              />
            </Box>
          </Box>
        </Box>
      )}
      {showError && !errorMessageOrg && (
        <Box marginTop={12}>
          <Spinner accessibilityLabel={"Loading"} show={true} />
        </Box>
      )}
      {!showError && errorMessageOrg && (
        <Box paddingY={12} paddingX={4}>
          <Box display="flex" alignItems="center" justifyContent="center" direction="column">
            <Text>{resources.errorMessageSso}</Text>

            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              direction="column"
              marginTop={8}
            >
              <Box width={"50%"}>
                <TapArea onTap={() => navigate(organizationLinkTemplates.login())}>
                  <Text align="center" weight="bold" color={colors.primary}>
                    {resources.forgotPasswordPage.backToLogin}
                  </Text>
                </TapArea>
                <Divider />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
