import { Box, BoxWithRef } from "../Box";
import React, {
  FunctionComponent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Icon } from "../Icon";
import { Text } from "../Text";
import type { TextPart } from "@prodoctivity/shared/src/index-types";
import { Tooltip } from "../Tooltip";
import { range } from "@prodoctivity/shared";
import { useColors } from "../ColorSchemeProvider";

type StringTemplateTextPartProps = {
  part: TextPart;
  lineIndex: number;
  partIndex: number;
  remove(): void;
  hoverPart(lineIndex: number, partIndex: number): void;
  onTextChange(newValue: string): void;
  t(key: string): string;
};

export const StringTemplateTextPart: FunctionComponent<StringTemplateTextPartProps> = ({
  part,
  lineIndex,
  partIndex,
  remove,
  hoverPart,
  onTextChange,
  t,
}) => {
  const { colors } = useColors();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [editMode, setEditMode] = useState(false);

  const [inputValue, setInputValue] = useState(part.value);
  const enterEditMode = useCallback(() => {
    setEditMode(true);
  }, []);

  const exitEditMode = useCallback(() => {
    setEditMode(false);
    onTextChange(inputValue);
  }, [inputValue, onTextChange]);

  const onHoveringMe = useCallback(() => {
    hoverPart(lineIndex, partIndex);
  }, [hoverPart, lineIndex, partIndex]);

  useLayoutEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);

  useLayoutEffect(() => {
    if (rootRef.current) {
      rootRef.current.classList.add("string-template-text-part");
    }
  }, []);

  const textItems = useMemo(() => {
    const value = part.value;
    let idx = 0;
    while (value[idx] === " ") {
      idx += 1;
    }
    let endIdx = value.length - 1;
    while (value[endIdx] === " ") {
      endIdx -= 1;
    }

    const result: string[] = [];
    if (idx > 0) {
      result.push(value.substring(0, idx));
    }
    result.push(value.substring(idx, endIdx + 1));
    if (endIdx < value.length - 1 && endIdx > idx) {
      result.push(value.substring(endIdx + 1));
    }

    return result;
  }, [part.value]);

  if (editMode) {
    return (
      <Box display="flex" paddingX={2} paddingY={1} color={colors.primary100} alignItems="center">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onBlur={exitEditMode}
          onChange={(e) => {
            setInputValue(e.currentTarget.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              exitEditMode();
            }
          }}
        />
        <Box margin={1} />

        <Icon
          size={3}
          accessibilityLabel={`Remove this text`}
          icon="close"
          color={colors.primary}
          onClick={remove}
        />
      </Box>
    );
  }
  const resources = {
    clickToEdit: t("clickToEdit"),
  };
  return (
    <Tooltip text={resources.clickToEdit}>
      <BoxWithRef
        ref={rootRef}
        display="inlineBlock"
        padding={1}
        onClickCapture={enterEditMode}
        onMouseOver={onHoveringMe}
        wrap={false}
      >
        {textItems.map((item, idx) => {
          if (isAllWhiteSpace(item)) {
            return (
              <Text key={idx} inline={true} size="200">
                {range(item.length)
                  .map((_) => " ")
                  .join("")}
              </Text>
            );
          }
          return (
            <Text key={idx} inline={true} size="200">
              {item}
            </Text>
          );
        })}
      </BoxWithRef>
    </Tooltip>
  );
};
function isAllWhiteSpace(item: string) {
  let result = true;
  for (let cnt = 0; cnt < item.length; cnt += 1) {
    result = result && item[cnt] === " ";
  }
  return result;
}
