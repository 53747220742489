import { ColorConfig } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const CardBlueCornerImg: FunctionComponent<Props> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="95" height="77.395" viewBox="0 0 95 77.395">
      <path
        d="M-1455,19595.092c-23.992-4.236-28.738,6.594-28.738,6.594s-15.819,26.842-35.066-8.24c-17.665-3.062-6.855-16.719-14.5-32.023s-6.591-27.312-16.083-27.312H-1455Z"
        transform="translate(1550 -19534.109)"
        fill="#2650a3"
      />
    </svg>
  );
};
