import {
  Box,
  Button,
  Center,
  Divider,
  Image,
  Link,
  TapArea,
  Text,
  TextField,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FC, FormEventHandler, FunctionComponent, useCallback, useMemo, useState } from "react";

import { NavLink } from "@prodoctivity/design-system";
import { validateEmail } from "@prodoctivity/shared";
import { useMutation } from "@tanstack/react-query";
import emailSentSvg from "../../assets/email_sent.svg";
import { LoginLayout } from "../../components/Layout/LoginLayout";
import { LoginLayoutSmall } from "../../components/Layout/LoginLayoutFooterSmall";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";
import { ForgotPasswordMedium } from "./ForgotPasswordMedium";

type EmailSentProps = {
  resetState(): void;
};

export const EmailSent: FunctionComponent<EmailSentProps> = ({ resetState }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [showLabel, setShowLabel] = useState(false);
  const [countdown, setCountdown] = useState(60);
  useMemo(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 1) {
          return prevCountdown - 1;
        } else {
          clearInterval(interval);
          setShowLabel(true);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <Center direction="column">
      <Box maxWidth={550} display="flex" direction="column">
        <Box dangerouslySetInlineStyle={{ __style: { maxWidth: "50ch", margin: "0 auto" } }}>
          <Text size="400" weight="bold" align="center">
            {resources.forgotPasswordPage.weSentEmailToResetPassword}{" "}
          </Text>
        </Box>
        <Box margin={4}></Box>
        <Divider />
        <Box display="flex" justifyContent="center" direction="row" marginBottom={10} marginTop={6}>
          <Box width={144} height={160}>
            <Image
              fit={"cover"}
              alt="email"
              role="presentation"
              src={emailSentSvg}
              naturalHeight={400}
              naturalWidth={400}
              color="transparent"
            />
          </Box>
        </Box>
        <Box marginBottom={5}>
          <Box dangerouslySetInlineStyle={{ __style: { maxWidth: "50ch", margin: "0 auto" } }}>
            <Text align="center" size="300">
              {resources.forgotPasswordPage.youWillRecievedEmailCheckInbox}
            </Text>
          </Box>
        </Box>
        <Box marginBottom={5} flex="shrink">
          <NavLink
            to={organizationLinkTemplates.login()}
            replace={true}
            style={{ textDecoration: "none" }}
          >
            <Button
              size="lg"
              text={resources.forgotPasswordPage.moveToSignIn}
              accessibilityLabel="Reset password"
              fullWidth={true}
              type="button"
            />
          </NavLink>
        </Box>
        <Box flex="shrink">
          {showLabel ? (
            <TapArea onTap={resetState}>
              <Text align="center" color={colors.primary}>
                {resources.forgotPasswordPage.didntRecieveEmail}
              </Text>
            </TapArea>
          ) : (
            <Text align="center" color={colors.primary}>
              {resources.pleaseWaitSeconds.replace("{countdown}", countdown.toString())}
            </Text>
          )}
        </Box>
      </Box>
    </Center>
  );
};

const ForgotPasswordPage: FC = () => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const { forgotPassword } = useServices();
  const { breakpoint } = useDesignBreakpoint();

  const doForgotPassword = useCallback(async () => {
    if (email) {
      await forgotPassword(email);
      setEmailSent(true);
    }
  }, [email, forgotPassword]);
  const { mutate, isError, error, isLoading, reset } = useMutation(doForgotPassword, {
    onError() {
      setTimeout(reset, 3000);
    },
  });

  const handleSubmit: FormEventHandler<unknown> = useCallback(
    (event) => {
      mutate();
      event.preventDefault();
    },
    [mutate]
  );

  const resetState = useCallback(() => {
    setEmail("");
    setEmailSent(false);
  }, []);

  const errorMessage = useMemo(() => {
    if (!isError) {
      return undefined;
    }
    if (!error) {
      return undefined;
    }

    const responseError: any = error;
    const errorCode = responseError?.response?.data?.errorCode;
    if (errorCode === "not-found" || responseError.response.status === 404) {
      return `${resources.unknownEmail}`;
    }
    return resources.anErrorHasOccurred;
  }, [error, isError, resources]);

  return (
    <>
      {(breakpoint === "large" || breakpoint === "hd") && (
        <LoginLayout>
          <Box paddingX={8} paddingY={6} rounding="pill">
            {emailSent ? (
              <EmailSent resetState={resetState} />
            ) : (
              <Center direction="column">
                <Box>
                  <Text size="500" weight="bold">
                    {resources.forgotPasswordPage.forgotYourPassword}
                  </Text>
                </Box>
                <Box margin={6}></Box>
                <Box maxWidth={500}>
                  <Text overflow="breakWord" align="start" size="300">
                    {resources.forgotPasswordPage.enterAssociatedEmail}
                  </Text>
                  <Box margin={4}></Box>
                  <Divider />
                  <Box margin={6}></Box>
                  <form onSubmit={handleSubmit} method="POST">
                    <Box display="flex" direction="column" gap={2}>
                      <Box>
                        <Text weight="bold">{resources.email}</Text>
                      </Box>
                      <TextField
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.value)}
                        id="emailField"
                        placeholder="yourname@gmail.com"
                        autoComplete="email"
                        errorMessage={errorMessage}
                        maxLength={{
                          characterCount: 300,
                          errorAccessibilityLabel: resources.dataDictionary.limitReached,
                        }}
                      />

                      <Box margin={4}></Box>

                      <Button
                        size="lg"
                        text={resources.forgotPasswordPage.resetPassword}
                        accessibilityLabel={resources.forgotPasswordPage.resetPassword}
                        fullWidth={true}
                        disabled={isLoading || isError || !validateEmail(email)}
                        type="submit"
                      />
                    </Box>
                  </form>
                  <Box margin={4}></Box>
                  <Box paddingX={10} alignContent="center" alignItems="center">
                    <Link href={organizationLinkTemplates.login()}>
                      <Text align="center" weight="bold" color={colors.primary}>
                        {resources.forgotPasswordPage.backToLogin}
                      </Text>
                    </Link>
                  </Box>
                </Box>
              </Center>
            )}
          </Box>
        </LoginLayout>
      )}

      {(breakpoint === "medium" || breakpoint === "small") && (
        <LoginLayoutSmall>
          <ForgotPasswordMedium
            isLoading={isLoading}
            isError={isError}
            errorMessage={errorMessage}
            setEmail={setEmail}
            email={email}
            emailSent={emailSent}
            resetState={resetState}
            handleSubmit={handleSubmit}
          />
        </LoginLayoutSmall>
      )}
    </>
  );
};

export default ForgotPasswordPage;
