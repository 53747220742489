import {
  Accordion,
  Box,
  BoxWithRef,
  Divider,
  Icon,
  TapArea,
  Text,
  useColors,
} from "@prodoctivity/design-system";
import { useCallback, useRef, useState } from "react";

import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../link-templates";
import { ImportSvgIcon } from "../../svg/ImportSvgIcon";
import { PrinterSvgIcon } from "../../svg/PrinterSvgIcon";
import { WebAuthorSvgIcon } from "../../svg/WebAuthorSvg";
import { WordAuthorSvgIcon } from "../../svg/WordAuthorSvgIcon";
import { OrganizationNavLink } from "../OrganizationLink";

type CreateItemProps = {
  setShowMobileMenu: (value: boolean) => void;
};

export function CreateItemMenu({ setShowMobileMenu }: CreateItemProps) {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const organizationNavigate = useOrganizationNavigate();

  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const { resources } = useAppTranslation();

  return (
    <BoxWithRef
      ref={anchorRef}
      onClickCapture={onClick}
      width={"100%"}
      aria-label="Create"
      aria-roledescription="button"
      dangerouslySetInlineStyle={{
        __style: {
          cursor: "pointer",
        },
      }}
      marginBottom={open ? 4 : undefined}
    >
      <Accordion
        TitleComponent={
          <Box paddingX={1} paddingY={4}>
            <Box display="flex" direction="row" gap={7}>
              <Box>
                <Icon
                  color={colors.neutral900}
                  accessibilityLabel={resources.create}
                  icon="plus"
                  size={"md"}
                />
              </Box>
              <Box display="flex">
                <Text size="400">{resources.create}</Text>
              </Box>
            </Box>
          </Box>
        }
      >
        <Box paddingX={4} onClickCapture={() => setShowMobileMenu(false)}>
          <Box display="flex" direction="column" gap={4}>
            <OrganizationNavLink to="/templates?action=generate">
              <Box minHeight={40} rounding={2} datatype="menu-item-entry" color={colors.neutral50}>
                <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                  <Box marginEnd={5} width={"auto"}>
                    <PrinterSvgIcon customClassName="svg-primary-on-hover" width={24} />
                  </Box>
                  <Box display="flex" direction="column">
                    <Text
                      customClassName="text-bold-on-hover text-subtle-on-hover"
                      color={colors.subtle}
                    >
                      {resources.generateDocument}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </OrganizationNavLink>

            <Box
              rounding={2}
              minHeight={40}
              datatype="menu-item-entry"
              color={colors.neutral50}
              /*onClickCapture={() => {
                      if (fileInputRef && fileInputRef.current) fileInputRef.current.click();
                    }}*/
              onClickCapture={() =>
                organizationNavigate(organizationLinkTemplates.createDocument())
              }
            >
              <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                <Box marginEnd={5} width={"auto"}>
                  <ImportSvgIcon customClassName="svg-primary-on-hover" width={24} />
                </Box>
                <Box display="flex" direction="column">
                  <TapArea rounding="pill">
                    <Text
                      customClassName="text-bold-on-hover text-subtle-on-hover"
                      color={colors.subtle}
                    >
                      {resources.importADocument}
                    </Text>
                  </TapArea>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box
              marginTop={2}
              minHeight={40}
              rounding={2}
              datatype="menu-item-entry"
              color={colors.neutral50}
            >
              <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                <Box display="flex" direction="column">
                  <TapArea rounding="pill">
                    <OrganizationNavLink to="/templates/author/new">
                      <Box
                        display="flex"
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Box marginEnd={5} width={"auto"}>
                          <WebAuthorSvgIcon customClassName="svg-primary-on-hover" width={24} />
                        </Box>
                        <Box display="flex" direction="column">
                          <Text
                            customClassName="text-bold-on-hover text-subtle-on-hover"
                            color={colors.subtle}
                          >
                            {resources.webAuthor}
                          </Text>
                        </Box>
                      </Box>
                    </OrganizationNavLink>
                  </TapArea>
                </Box>
              </Box>
            </Box>
            <Box minHeight={40} rounding={2} datatype="menu-item-entry" color={colors.neutral50}>
              <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                <Box display="flex" direction="column">
                  <OrganizationNavLink to="/download-advanced-author">
                    <Box display="flex" direction="row" alignItems="center" justifyContent="start">
                      <Box marginEnd={5} width={"auto"}>
                        <WordAuthorSvgIcon customClassName="svg-primary-on-hover" width={24} />
                      </Box>
                      <Box display="flex" direction="column">
                        <Text
                          customClassName="text-bold-on-hover text-subtle-on-hover"
                          color={colors.subtle}
                        >
                          {resources.advancedAuthor}
                        </Text>
                      </Box>
                    </Box>
                  </OrganizationNavLink>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Accordion>
    </BoxWithRef>
  );
}
