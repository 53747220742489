"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceSummary = exports.FilterBuilder = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
/**
 *
 * @author Eduardo Burgos and refactored By Christopher Velazco
 *
 */
var design_system_1 = require("@prodoctivity/design-system");
var react_1 = require("react");
var utils_1 = require("../../utils");
var RenderIsNegative_1 = require("./RenderIsNegative");
var RenderMultiple_1 = require("./RenderMultiple");
var RenderOperator_1 = require("./RenderOperator");
var RenderSourceSummary_1 = require("./RenderSourceSummary");
var hooks_1 = require("./hooks");
/**
 * The big control that integrates everything and brings us a filtering experience.
 */
var FilterBuilderComponent = function (props) {
    var _a = (0, hooks_1.useFilterBuilder)(props), selectOperatorProps = _a.selectOperatorProps, appliesForWhereFunnel = _a.appliesForWhereFunnel, contextFieldValue = _a.contextFieldValue, 
    // dataElementInputTargetValue,
    selectableFieldList = _a.selectableFieldList, renderFieldOption = _a.renderFieldOption, moment = _a.moment, i18n = _a.i18n, hasMultiple = _a.hasMultiple, options = _a.options, currentField = _a.currentField, makeChangeHandler = _a.makeChangeHandler, highlightExpression = _a.highlightExpression, valueChanged = _a.valueChanged, inputSwitchChanged = _a.inputSwitchChanged, inputMultipleNegativeChanged = _a.inputMultipleNegativeChanged, inputSelectSourceChanged = _a.inputSelectSourceChanged, inputSelectOperatorChanged = _a.inputSelectOperatorChanged, inputSelectNegativeChanged = _a.inputSelectNegativeChanged, inputSelectSourceSummaryChanged = _a.inputSelectSourceSummaryChanged, convertToMultiple = _a.convertToMultiple, addNewExpression = _a.addNewExpression, deleteThis = _a.deleteThis, funnelClick = _a.funnelClick, handleWhereChanged = _a.handleWhereChanged, state = _a.state, objExpression = _a.objExpression, expression = _a.expression, onExpressionChanged = _a.onExpressionChanged, emptyExpression = _a.emptyExpression, inputControlResourcesProp = _a.inputControlResourcesProp, colors = _a.colors, selectListOperatorOptions = _a.selectListOperatorOptions, inputSelectSourceClear = _a.inputSelectSourceClear, typeValue = _a.typeValue;
    return ((0, jsx_runtime_1.jsx)(design_system_1.Box, { width: "100%", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", alignItems: "center", overflow: "hidden", children: hasMultiple ? ((0, jsx_runtime_1.jsx)(RenderMultiple_1.RenderMultiple, { addNewExpression: addNewExpression, highlightExpression: highlightExpression, inputMultipleNegativeChanged: inputMultipleNegativeChanged, inputSelectOperatorChanged: inputSelectOperatorChanged, inputSwitchChanged: inputSwitchChanged, makeChangeHandler: makeChangeHandler, objExpression: objExpression, parentProps: __assign({}, props), selectOperatorProps: selectOperatorProps, translateFn: i18n, state: state, colors: colors })) : ((0, jsx_runtime_1.jsx)(design_system_1.Box, { width: "100%", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "row", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "column", width: "100%", children: (0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", direction: "row", children: [(0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "row", alignItems: "center", children: props.isChild ? ((0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", height: "100%", children: (0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", children: [(0, jsx_runtime_1.jsx)(design_system_1.Box, { children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { dangerouslySetInlineStyle: {
                                                                __style: { borderBottom: "1px solid #cacbce" },
                                                            }, minWidth: 64, minHeight: 72, height: "100%", color: colors.neutral250, display: "flex", alignItems: "center", justifyContent: "center", children: (0, jsx_runtime_1.jsx)(design_system_1.Icon, { accessibilityLabel: i18n("remove"), icon: "close", onClick: deleteThis, color: colors.neutral900 }) }) }), (0, jsx_runtime_1.jsx)(design_system_1.Divider, { size: 1, direction: "vertical" })] }) })) : null }), (0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", direction: "column", justifyContent: "between", height: "100%", width: "100%", children: [(0, jsx_runtime_1.jsxs)(design_system_1.Box, { display: "flex", alignItems: "center", paddingX: 2, height: "100%", wrap: true, gap: 2, padding: 4, dangerouslySetInlineStyle: {
                                                    __style: { borderBottom: "1px solid #cacbce" },
                                                }, children: [currentField && appliesForWhereFunnel(currentField) ? ((0, jsx_runtime_1.jsx)(design_system_1.Tooltip, { text: "".concat(i18n("filterBuilder.clickToFilterFunnel"), " - ").concat(currentField.humanName), children: (0, jsx_runtime_1.jsx)("div", { className: "where-funnel".concat(expression.where ? " has-where" : ""), children: (0, jsx_runtime_1.jsx)(design_system_1.Icon, { accessibilityLabel: "Filter", icon: "filter", onClick: function () {
                                                                    funnelClick();
                                                                    var updatedExpression = __assign(__assign({}, expression), { where: emptyExpression() });
                                                                    onExpressionChanged(updatedExpression, props.expressionIndex);
                                                                } }) }) })) : null, (0, jsx_runtime_1.jsx)(design_system_1.Box, { minWidth: 150, children: (0, jsx_runtime_1.jsx)(design_system_1.SelectList, { id: "".concat(state.id, "-source-summary"), value: expression.sourceSummary, "data-name": "sourceSummary", onChange: function (event) {
                                                                if (event.value === " ")
                                                                    return;
                                                                inputSelectSourceSummaryChanged(event.value);
                                                            }, options: options.map(function (option) { return ({
                                                                label: i18n(option.key === "valueOf"
                                                                    ? utils_1.resourcesAdapters["valueOfLabel"]
                                                                    : utils_1.resourcesAdapters[option.key]),
                                                                value: option.key,
                                                            }); }) }) }), (0, jsx_runtime_1.jsx)(RenderSourceSummary_1.SourceSelectableFields, { inputSelectSourceChanged: inputSelectSourceChanged, objExpression: objExpression, parentProps: __assign({}, props), renderFieldOption: renderFieldOption, selectableFieldList: selectableFieldList, state: state, expression: expression, inputSelectSourceClear: inputSelectSourceClear, translateFn: i18n, resources: props.resources }), (0, jsx_runtime_1.jsx)(RenderIsNegative_1.RenderIsNegative, { id: state.id, expression: expression, inputSelectNegativeChanged: inputSelectNegativeChanged, translateFn: i18n }), (0, jsx_runtime_1.jsx)(RenderOperator_1.RenderOperator, { id: state.id, currentField: currentField, inputSelectOperatorChanged: inputSelectOperatorChanged, objExpression: objExpression, options: options, expression: expression, translateFn: i18n, resourcesAdapters: utils_1.resourcesAdapters }), expression.sourceSummary &&
                                                        expression.sourceField &&
                                                        expression.operator ? ((0, jsx_runtime_1.jsx)(design_system_1.Box, { width: "fit-content", minWidth: 150, children: (0, jsx_runtime_1.jsx)(design_system_1.DataElementInput, { moment: moment, componentBreakpoint: "small", contextField: contextFieldValue.value, typeValue: typeValue, hideWrapper: true, getContextValueAsString: function () { return undefined; }, instanceFullPath: "".concat(contextFieldValue.value.fullPath), resources: inputControlResourcesProp, disabled: contextFieldValue.disableInput, onChange: valueChanged }) })) : null, (expression.sourceField || expression.source) && ((0, jsx_runtime_1.jsx)(design_system_1.Box, { minWidth: 150, children: (0, jsx_runtime_1.jsx)(design_system_1.SelectList, { id: "".concat(state.id, "-add-expr"), onChange: function (event) {
                                                                if (event.value !== "and" && event.value !== "or")
                                                                    return;
                                                                convertToMultiple(event.value);
                                                            }, options: selectListOperatorOptions, value: "", label: "", placeholder: i18n("addNew") }) }))] }), state.showFunnel && expression.where && ((0, jsx_runtime_1.jsx)(design_system_1.Box, { dangerouslySetInlineStyle: {
                                                    __style: { borderBottom: "1px solid #cacbce" },
                                                }, children: (0, jsx_runtime_1.jsxs)(design_system_1.Box, { borderRadius: 6, borderColor: colors.neutral600, width: "fit-content", 
                                                    // color={colors.neutral300}
                                                    margin: 4, overflow: "hidden", children: [(0, jsx_runtime_1.jsx)(design_system_1.Box, { paddingX: 6, display: "flex", alignItems: "center", justifyContent: "between", paddingY: 3, color: colors.neutral250, children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", gap: 4, children: (0, jsx_runtime_1.jsx)(design_system_1.Text, { weight: "bold", children: i18n("filterBuilder.whereCaps").toUpperCase() }) }) }), (0, jsx_runtime_1.jsx)(design_system_1.Divider, { size: 1, direction: "horizontal" }), (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "row", justifyContent: "around", alignContent: "center", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", alignItems: "center", children: (0, jsx_runtime_1.jsx)(design_system_1.Box, { display: "flex", direction: "column", children: (0, jsx_runtime_1.jsx)(FilterBuilder, __assign({}, props, { level: (props.level + 1) % 7, isChild: true, expression: expression.where, onExpressionChanged: handleWhereChanged, resources: props.resources, parentExpressionOperator: undefined, contextDefinition: props.contextDefinition }), "".concat(props.level, "-").concat(props.expressionIndex, "-WHERE")) }) }) })] }) }))] })] }) }) }) })) }) }) }));
};
var SourceSummary = function () {
    var _a = (0, react_1.useState)(false), open = _a[0], setOpen = _a[1];
    var _b = (0, react_1.useState)(undefined), selected = _b[0], setSelected = _b[1];
    var anchorRef = (0, react_1.useRef)(null);
    var onSelect = (0, react_1.useCallback)(function (args) {
        return setSelected(args.item.value === (selected || {}).value ? undefined : args.item);
    }, [selected]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(design_system_1.BoxWithRef, { ref: anchorRef, display: "flex", flex: "shrink", children: (0, jsx_runtime_1.jsx)(design_system_1.Button, { accessibilityLabel: "Source Summary", accessibilityControls: "truncation-dropdown-example", accessibilityExpanded: open, accessibilityHaspopup: true, iconEnd: "arrow-down", onClick: function () { return setOpen(function (prevVal) { return !prevVal; }); }, selected: open, size: "lg", text: "Menu" }) }), open && ((0, jsx_runtime_1.jsx)(design_system_1.Dropdown, { anchor: anchorRef.current || undefined, id: "truncation-dropdown-example", onDismiss: function () { return setOpen(false); }, children: (0, jsx_runtime_1.jsx)(design_system_1.DropdownItem
                // badge={{ text: "New" }}
                , { 
                    // badge={{ text: "New" }}
                    onSelect: onSelect, option: {
                        value: "Homefeed anpassen",
                        label: "Homefeed anpassen",
                        subtext: "Aktualisieren Sie Ihren Homefeed, um Ihre Vorlieben und Ideen besser widerzuspiegeln",
                    }, selected: selected }) }))] }));
};
exports.SourceSummary = SourceSummary;
var FilterBuilder = FilterBuilderComponent;
exports.FilterBuilder = FilterBuilder;
