import {
  Box,
  BoxWithRef,
  Divider,
  Spinner,
  TapArea,
  Text,
  type TextSize,
} from "@prodoctivity/design-system";

import { useRef } from "react";
import { useUserPublicProfile } from "../../../hooks";
import { AvatarImage } from "../../Avatar/Avatar";

type Props = {
  username: string;
  size: TextSize;
  sizeImage: 32 | 48 | 62;
  evictUserListCache?: boolean;
};

const PublicProfileInfo: React.FC<Props> = ({ username, size, sizeImage, evictUserListCache }) => {
  const { data: userProfile, isLoading } = useUserPublicProfile(username, evictUserListCache);

  const anchorRef = useRef<HTMLDivElement>(null);

  if (isLoading || !userProfile) {
    return (
      <Box>
        <Spinner show={true} />
      </Box>
    );
  } else {
    return (
      <Box width="100%">
        <BoxWithRef ref={anchorRef} display="inlineBlock" width="100%">
          <TapArea>
            {
              <Box display="flex" direction="column">
                <Box padding={2} display="flex">
                  <Box padding={1} display="flex" direction="column" justifyContent="center">
                    <AvatarImage
                      imageDataURI={userProfile.avatarImageDataURI || ""}
                      email={userProfile.email}
                      name={userProfile.firstName}
                      size={sizeImage}
                    />
                  </Box>
                  <Box margin={2} />
                  <Box display="flex" gap={4}>
                    <Box display="flex" direction="column">
                      <Box direction="column">
                        <Text weight="bold" inline size={size}>
                          {userProfile.firstName + " " + userProfile.lastName}{" "}
                        </Text>
                      </Box>
                      <Box direction="column">
                        <Text weight="normal" inline>
                          {userProfile.email}
                        </Text>
                      </Box>
                      {userProfile.positionRole && (
                        <Box direction="column">
                          <Text weight="normal" inline>
                            {userProfile.positionRole}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Divider direction="horizontal" />
              </Box>
            }
          </TapArea>
        </BoxWithRef>
      </Box>
    );
  }
};

export { PublicProfileInfo };
