import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
  onClick?: () => void;
};

export const MyTasksSvgIcon: FunctionComponent<Props> = ({
  customClassName,
  color,
  width = 40,
  height = 40,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0186 6V8.02247H26.9568V30.8539H28.5494V6H10.0186Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M8.42627 9.14648V13.4461H9.25979V14.9892H8.42627V20.6221H9.25979V22.1802H8.42627V28.5772H9.54259V30.1352H8.42627V32.8768H25.8408V9.14648H8.42627ZM15.7642 31.3637H11.031V26.6746H15.7642V31.3637ZM15.7642 24.1278H11.031V19.4536H15.7642V24.1278ZM15.7642 16.9068H11.031V12.2176H15.7642V16.9068ZM23.251 29.7907H17.0145V28.2476H23.251V29.7907ZM23.251 22.5697H17.0145V21.0117H23.251V22.5697ZM23.251 15.3337H17.0145V13.7907H23.251V15.3337Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M14.7816 14.0152L14.6626 14.1351L13.1741 15.6332C13.1741 15.6332 13.0402 15.7231 12.9658 15.7231C12.8765 15.7231 12.802 15.6931 12.7425 15.6332L11.9983 14.8542C11.8792 14.7193 11.8643 14.4946 11.9983 14.3598C12.1174 14.21 12.3109 14.21 12.4448 14.3448L12.9658 14.8841L14.35 13.4909C14.484 13.356 14.6923 13.371 14.8114 13.5208C14.9305 13.6557 14.9156 13.8804 14.7816 14.0152Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M14.8263 21.1607C14.8263 21.1607 14.8114 21.2207 14.7816 21.2356L13.1741 22.8536C13.1741 22.8536 13.0402 22.9585 12.9658 22.9585C12.8765 22.9585 12.802 22.9135 12.7425 22.8536L11.9983 22.0746C11.8792 21.9398 11.8643 21.73 11.9983 21.5802C12.1174 21.4454 12.3109 21.4454 12.4448 21.5652L12.9658 22.1195L14.35 20.7263C14.484 20.5914 14.6923 20.5914 14.8114 20.7413C14.9156 20.8611 14.9156 21.0409 14.8263 21.1607Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M14.7816 28.4718L14.1416 29.116L13.1741 30.0898C13.1741 30.0898 13.0402 30.1797 12.9658 30.1797C12.8765 30.1797 12.802 30.1497 12.7425 30.0748L11.9983 29.3108C11.8792 29.1759 11.8643 28.9512 11.9983 28.8164C12.1174 28.6666 12.3109 28.6666 12.4448 28.8014L12.9658 29.3407L14.35 27.9475C14.484 27.8126 14.6923 27.8276 14.8114 27.9624C14.9305 28.1123 14.9156 28.337 14.7816 28.4718Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M8.85779 21.0566H6.46143V21.7458H8.85779V21.0566Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M8.82777 13.8799H6.44629V14.569H8.82777V13.8799Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M9.06605 29.0107H6.68457V29.6999H9.06605V29.0107Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
      <path
        d="M32.8512 13.1163H32.0772C32.0623 12.3073 32.0623 11.4983 32.0474 10.7043C32.0028 10.2249 31.586 9.86534 31.1246 9.8953C30.6781 9.8953 30.306 10.2698 30.2613 10.7193L30.2911 13.1462H29.5171L29.6659 26.1949C29.6659 26.1949 29.6659 26.2399 29.6659 26.2698L31.1841 30.4346C31.2139 30.5245 31.2734 30.5844 31.3479 30.5994C31.4372 30.6144 31.5265 30.5545 31.5711 30.4646L32.9702 26.2249C32.9702 26.2249 32.9851 26.165 32.9851 26.135L32.8363 13.1163H32.8512ZM31.6604 28.2174H31.0799L30.5441 26.6593H32.1516L31.6604 28.2174Z"
        fill={calculateColor(color ? color : colors.neutral900)}
      />
    </svg>
  );
};
