import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const EllipseMenuSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color: rawColor,
  onClick,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <circle cx="11" cy="20" r="3" transform="rotate(-90 11 20)" fill={calculateColor(color)} />
      <circle cx="20" cy="20" r="3" transform="rotate(-90 20 20)" fill={calculateColor(color)} />
      <circle cx="29" cy="20" r="3" transform="rotate(-90 29 20)" fill={calculateColor(color)} />
    </svg>
  );
};
