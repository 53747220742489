import { Box, Text } from "@prodoctivity/design-system";

import type { UserPasswordUpdatedLogEntry } from "@prodoctivity/types";
import { FunctionComponent } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  id: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  entry: UserPasswordUpdatedLogEntry;
};

export const UserPasswordUpdatedEntry: FunctionComponent<Props> = ({ entry, fullWidth }) => {
  const { resources } = useAppTranslation();
  if (fullWidth) {
    return (
      <Box display="flex" direction={fullWidth ? "row" : "column"} flex="grow">
        {fullWidth ? (
          <Box marginEnd={6}>
            <PublicProfile username={entry.user} onlyImage={true} sizeImage={48} />
          </Box>
        ) : null}

        <Box display="flex" direction="column">
          <Text inline={true} size="200">
            <Box display="flex" direction="column">
              <PublicProfile username={entry.user} onlyImage={false} size="300" />{" "}
              <Text inline={true} size="200">
                {resources.activities.entryTypes.userPasswordUpdated}
              </Text>
            </Box>
          </Text>
        </Box>
      </Box>
    );
  }
  return (
    <Text inline={true} size="200">
      {resources.activities.entryTypes.userPasswordUpdated}{" "}
      <PublicProfile username={entry.user} onlyImage={false} />
    </Text>
  );
};
