import { Box, Text, useColors } from "@prodoctivity/design-system";

import { Grid } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";
import { BreadCrumb } from "../../../../components/BreadCrumb";
import { useChangePasswordPage } from "../hooks";
import { ChangePasswordForm } from "./ChangePasswordForm";

const ChangePasswordPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { resources, breadcrumbEntries } = useChangePasswordPage();

  return (
    <Box>
      <Box direction="row" marginBottom={6}>
        <BreadCrumb entries={breadcrumbEntries} />
      </Box>
      <Grid gridTemplateColumns={["1fr", "1fr", "1fr"]}>
        <Box></Box>
        <Box display="flex" direction="column">
          <Box paddingLeft={12} paddingBottom={3}>
            <Text weight="bold" size="400">
              {resources.changePassword}
            </Text>
          </Box>
          <Box
            color={colors.white}
            marginTop={2}
            marginStart={12}
            marginEnd={12}
            padding={5}
            borderRadius={4}
            borderStyle="lg"
            alignContent="center"
            display="flex"
          >
            <ChangePasswordForm />
          </Box>
        </Box>
        <Box></Box>
      </Grid>
    </Box>
  );
};

export default ChangePasswordPage;
