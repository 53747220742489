import { ColorConfig, calculateColor } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  width?: number;
  height?: number;
  color?: ColorConfig;
};

export const DocumentGenerationSVG: FunctionComponent<Props> = ({
  width = 31,
  height = 29,
  color,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6052_120013)">
        <path
          d="M16.2257 15.3972C16.2257 17.5932 14.3417 19.3899 11.9935 19.3899C9.64523 19.3899 7.74805 17.5932 7.74805 15.3972C7.74805 13.2012 9.65849 11.417 11.9935 11.417C14.3285 11.417 16.2257 13.1887 16.2257 15.3972Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M16.4245 6.53803V0H1.12769C0.504146 0 0 0.474132 0 1.04808V27.2252C0 27.7991 0.517413 28.2857 1.12769 28.2857H22.8723C23.4959 28.2857 24 27.8116 24 27.2252V6.53803H16.4245ZM19.3035 19.8512C18.4279 20.762 18.3881 20.7994 17.5257 21.6978L15.4561 20.9741C14.7794 21.2361 14.3549 21.3983 13.6783 21.6603L12.7496 23.5319C11.4494 23.4571 11.3964 23.4571 10.1095 23.3822L9.41957 21.4233C8.78275 21.0739 8.38474 20.8618 7.73466 20.5249L5.58541 21.0115C4.85572 20.0134 4.81592 19.9759 4.08624 18.9778L5.29353 17.2559C5.16086 16.5697 5.09453 16.1454 4.97512 15.4592L3.23715 14.1865C3.59536 13.0262 3.62189 12.9638 3.9801 11.8034L6.18242 11.6162C6.6733 11.0922 6.97844 10.7803 7.46932 10.2687L7.44279 8.19749C8.63682 7.73584 8.68988 7.71088 9.88391 7.26171L11.4096 8.75896C12.1526 8.7964 12.5904 8.82135 13.3333 8.85878L15.0448 7.54868C16.1725 8.14758 16.2255 8.18501 17.3532 8.75896L17.0614 10.8177C17.4726 11.3916 17.738 11.741 18.1625 12.3025L20.3118 12.7392C20.5373 13.937 20.5373 13.9994 20.7496 15.1847L18.8657 16.2577C18.6534 16.9315 18.5207 17.3308 18.3217 18.0045L19.3035 19.8512Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
        <path
          d="M23.3762 5.29032H17.751V0L23.3762 5.29032Z"
          fill={color ? calculateColor(color) : "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_6052_120013">
          <rect width="24" height="28.2857" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
