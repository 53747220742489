import { Box, Label, SelectList, Text, useColors } from "@prodoctivity/design-system";
import type { OrganizationRoles, UpdateOrganizationUser } from "@prodoctivity/types";

import { FunctionComponent } from "react";
import { XIconSvg } from "../../../../svg/XIconSvg";
import { useOrganizationUserEditRoleTab } from "../hooks";

type RolesTabProps = {
  user: UpdateOrganizationUser;
  onChange: (user: UpdateOrganizationUser) => void;
  rolesAvailable: OrganizationRoles;
};

export const RolesTab: FunctionComponent<RolesTabProps> = ({ user, onChange, rolesAvailable }) => {
  const { colors } = useColors();
  const { resources, filteredRoles, updateRoleValue } = useOrganizationUserEditRoleTab();

  return (
    <Box display="flex" direction="column" color={colors.white}>
      {/* Form Start */}
      <Box display="flex" direction="row" paddingX={6} paddingY={4}>
        <Box direction="column" width={"100%"}>
          <Box alignItems="start" display="flex" marginEnd={8} marginBottom={6}>
            <Box width={150} display="flex" paddingY={3}>
              <Label htmlFor={resources.userManagementAndRoles.userRoles.availableRoles}>
                {resources.userManagementAndRoles.userRoles.availableRoles}
              </Label>
            </Box>
            <Box width={293}>
              <SelectList
                id={resources.userManagementAndRoles.userRoles.availableRoles}
                onChange={({ value }) => {
                  updateRoleValue(value, user, onChange);
                }}
                options={filteredRoles(rolesAvailable, user)}
              />
            </Box>
          </Box>
          <Box alignItems="start" display="flex">
            <Box minWidth={150} width={150} display="flex" paddingY={3}>
              <Label htmlFor={resources.userManagementAndRoles.userRoles.selectedRoles}>
                {resources.userManagementAndRoles.userRoles.selectedRoles}
              </Label>
            </Box>
            <Box width={"100%"}>
              <Box
                width={"100%"}
                height={"fit-content"}
                minHeight={120}
                display="flex"
                paddingX={2}
                borderRadius={4}
                alignContent="start"
                gap={2}
                wrap={true}
              >
                {user.assignedRoleIdList &&
                  user.assignedRoleIdList.map((roleID) => (
                    <SelectedItemTag
                      key={roleID}
                      value={roleID}
                      label={rolesAvailable.roles.find((role) => roleID === role.id)?.name || ""}
                      onRemove={() => {
                        const assignedRoles = user.assignedRoleIdList;
                        const updatedRoles = assignedRoles.filter((id) => id !== roleID);

                        onChange({
                          ...user,
                          assignedRoleIdList: updatedRoles,
                        });
                      }}
                    />
                  ))}
              </Box>
              {/* remove role from assignedRoleIdList */}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Form End */}
    </Box>
  );
};

type SelectedItemTagProps = {
  label: string;
  value: string;
  onRemove: () => void;
};

export const SelectedItemTag: FunctionComponent<SelectedItemTagProps> = ({
  label,
  value,
  onRemove,
}) => {
  const { colors } = useColors();
  return (
    <Box
      key={value}
      width={"fit-content"}
      minWidth={100}
      minHeight={"fit-content"}
      borderRadius={4}
      display="flex"
      justifyContent="center"
      alignContent="center"
      borderColor={colors.primary}
      color={colors.primaryHover0}
      padding={1}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingX={2}
        color={colors.primaryHover0}
        borderRadius={4}
        borderColor={colors.neutral50}
      >
        <Text size="200" color={colors.primary}>
          {label}
        </Text>
      </Box>
      <Box
        paddingX={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={colors.primaryHover0}
        borderRadius={4}
        borderColor={colors.neutral50}
      >
        <XIconSvg color={colors.primary} onClick={onRemove} />
      </Box>
    </Box>
  );
};
