import type { DataType, StringTemplatePart } from "@prodoctivity/shared/src/index-types";
import React, { Dispatch, FunctionComponent, useLayoutEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { BoxWithRef } from "../Box";
import { AddNewPartComponent } from "./AddNewPartComponent";
import { Msg } from "./StringTemplateBuilder";
import { StringTemplatePartComponent } from "./StringTemplatePart";

type StringTemplateBuilderLineProps = {
  parts: StringTemplatePart[];
  lineIndex: number;
  hoveredPartIndex: number | undefined;
  hoveredLineIndex: number | undefined;
  inline?: boolean;
  dispatch: Dispatch<Msg>;
  hoverPart(lineIndex: number, partIndex: number): void;
  onPartClicked(
    lineIndex: number,
    partIndex: number,
    newPart: StringTemplatePart | undefined
  ): void;
  onVariableAdded(lineIndex: number, partIndex: number, name: string, dataType: DataType): void;
  getNewVariableData(): { name: string; dataType: DataType };
  getFieldLabel(fldName: string): string;
};
export const StringTemplateBuilderLine: FunctionComponent<StringTemplateBuilderLineProps> = ({
  parts,
  lineIndex,
  hoveredLineIndex,
  hoveredPartIndex,
  inline,
  dispatch,
  hoverPart,
  onPartClicked,
  onVariableAdded,
  getNewVariableData,
  getFieldLabel,
}) => {
  const lineRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  useLayoutEffect(() => {
    if (lineRef.current) {
      lineRef.current.classList.add("string-template-builder-line");
    }
  }, []);
  return (
    <BoxWithRef key={lineIndex} ref={lineRef} direction="row" overflow={"auto"} padding={1}>
      {parts.map((part, partIndex) => {
        return (
          <React.Fragment key={partIndex}>
            <AddNewPartComponent
              lineIndex={lineIndex}
              partIndex={partIndex}
              inline={inline}
              show={
                (hoveredLineIndex === lineIndex && hoveredPartIndex === partIndex) ||
                (typeof hoveredPartIndex === "number" &&
                  hoveredPartIndex + 1 === partIndex &&
                  typeof hoveredLineIndex === "number" &&
                  hoveredLineIndex === lineIndex)
              }
              dispatch={dispatch}
              onVariableAdded={onVariableAdded}
              getNewVariableData={getNewVariableData}
            />
            <StringTemplatePartComponent
              key={partIndex}
              part={part}
              lineIndex={lineIndex}
              partIndex={partIndex}
              dispatch={dispatch}
              hoverPart={hoverPart}
              onPartClicked={onPartClicked}
              getFieldLabel={getFieldLabel}
              t={t}
            />
          </React.Fragment>
        );
      })}
      {
        <AddNewPartComponent
          lineIndex={lineIndex}
          partIndex={parts.length}
          inline={inline}
          show={
            !parts.length ||
            (typeof hoveredPartIndex === "number" &&
              hoveredPartIndex === parts.length - 1 &&
              typeof hoveredLineIndex === "number" &&
              hoveredLineIndex === lineIndex)
          }
          dispatch={dispatch}
          onVariableAdded={onVariableAdded}
          getNewVariableData={getNewVariableData}
        />
      }
    </BoxWithRef>
  );
};
