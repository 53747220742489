import { FunctionComponent, useMemo } from "react";
import { calculateColor, ColorConfig } from "../../utils";
import { Text, TextSize } from "../Text";
import { Box } from "../Box";
import { frontIndex } from "../layout";

type Props = {
  barColor: ColorConfig;
  backgroundColor: ColorConfig;
  emptyBarColor: ColorConfig | undefined;
  maxValue: number;
  currentValue: number;
  middleLabel: string | undefined;
  bottomLabel: string | undefined;
  labelColor: ColorConfig;
  gaugeSize?: number;
  labelSize?: TextSize;
};

export const CustomBasicGauge: FunctionComponent<Props> = ({
  barColor,
  backgroundColor,
  emptyBarColor,
  maxValue,
  currentValue,
  middleLabel,
  bottomLabel,
  labelColor,
  gaugeSize = 100,
  labelSize = "200",
}) => {
  const bgColor = useMemo(
    () => (backgroundColor ? calculateColor(backgroundColor) : "#fff"),
    [backgroundColor]
  );
  const progressBarColor = useMemo(
    () => (barColor ? calculateColor(barColor) : "#CC0000"),
    [barColor]
  );
  const emptyBarBgColor = useMemo(
    () => (emptyBarColor ? calculateColor(emptyBarColor) : "#E9EDF0"),
    [emptyBarColor]
  );

  const percentage = (currentValue / maxValue) * 100;
  const rotation = -90 + (percentage * 180) / 100;
  // To make sure the bottom label is not cut off and to use the visual height of the gauge instead of its real height for proper alignment
  const bottomLabelHeight = bottomLabel ? Math.max(gaugeSize * 0.25, 23) : 0;
  const reducedHeight = gaugeSize * 0.5 + bottomLabelHeight;

  return (
    <Box height={reducedHeight} overflow="hidden">
      <Box position="relative" display="flex" justifyContent="center">
        {middleLabel && (
          <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="end"
            height={"50%"}
            width={"60%"}
            overflow="hidden"
            zIndex={frontIndex}
          >
            <Text size={labelSize} align="center" color={labelColor}>
              {middleLabel}
            </Text>
          </Box>
        )}
        <Box width={gaugeSize} height={gaugeSize} position="relative">
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              backgroundColor: emptyBarBgColor,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "50%",
                height: "100%",
                backgroundColor: progressBarColor,
                transformOrigin: "center right",
                transform: `rotate(${rotation}deg)`,
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                content: "''",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                height: "80%",
                backgroundColor: bgColor,
                borderRadius: "50%",
                zIndex: 4,
              }}
            ></div>
          </div>
        </Box>
        {bottomLabel && (
          <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="start"
            bottom
            height={"50%"}
            width={"100%"}
            overflow="hidden"
            zIndex={frontIndex}
            paddingTop={1}
            color={backgroundColor}
          >
            <Text size={labelSize} align="center" color={labelColor}>
              {bottomLabel}
            </Text>
          </Box>
        )}
        <div
          style={{
            position: "absolute",
            content: "''",
            top: "50%",
            width: "100%",
            height: "50%",
            backgroundColor: bgColor,
            zIndex: 8,
          }}
        ></div>
      </Box>
    </Box>
  );
};
