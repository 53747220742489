import { AccordionExpandableProps, AccordionProps, Accordion as GestaltAccordion } from "gestalt";

import { Box } from "./Box";
import { FunctionComponent } from "react";
import { useColors } from "./ColorSchemeProvider";

export type { AccordionExpandableProps };

export const PlainExpandable = (props: AccordionExpandableProps) => {
  return <GestaltAccordion.Expandable {...props} />;
};

export const AccordionExpandable = (props: AccordionExpandableProps) => {
  const { colors } = useColors();
  return (
    <Box color={colors.neutral300} rounding={2}>
      <GestaltAccordion.Expandable {...props} />
    </Box>
  );
};

export const AccordionModule: FunctionComponent<AccordionProps> = (props) => {
  const { colors } = useColors();
  return (
    <Box color={colors.neutral300} rounding={2}>
      <GestaltAccordion {...props} />
    </Box>
  );
};
