import { Box, IconButton, TapArea, Tooltip, useColors } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback } from "react";

import type { DictionaryList } from "@prodoctivity/shared/src/index-types";
import { useAppTranslation } from "../../../hooks/useAppTranslation";

type ExportListButtonProps = {
  list: DictionaryList<string>;
};
export const ExportListButton: FunctionComponent<ExportListButtonProps> = ({ list }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const handleClick = useCallback(() => {
    const url = URL.createObjectURL(
      new Blob([JSON.stringify(list, null, 2)], {
        type: "application/json",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.download = `${list.listSchema.name}.json`;
    link.click();
  }, [list]);

  return (
    <Tooltip text={resources.dataDictionary.exportList}>
      <TapArea onTap={handleClick}>
        <Box display="flex" gap={1} direction="row">
          <IconButton
            color={colors.subtle}
            accessibilityLabel={resources.download}
            icon="up-from-bracket"
            size={5}
            onClick={handleClick}
          />
          {/* <Text>{resources.dataDictionary.exportList}</Text> */}
        </Box>
      </TapArea>
    </Tooltip>
  );
};
