import {
  EditVariableCommand,
  SwitchProps,
  VariablePartSheetCurrentPart,
  usePubSub,
} from "@prodoctivity/design-system";
import {
  areArraysEqual,
  confirmStringTemplatePartType,
  deepCopy,
  evaluateContextValue,
  getDefaultIdentifierConfig,
  getDefaultNameConfig,
  stableStringify,
} from "@prodoctivity/shared";
import type {
  ControlSize,
  DataElement,
  StringTemplateParameterValue,
  StringTemplatePart,
  TemplateContextDefinition,
  DataType,
  TemplateContextRecordHolder,
  TemplatePage,
  TemplateSection,
  TemplateVersionContextMapping,
  TemplateWizardDefinition,
  TemplateWizardField,
  TemplateWizardFieldIsRecord,
  ContextFieldProperties,
  ContextRecord,
  ContextField,
} from "@prodoctivity/shared/src/index-types";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { contextToFormDefinition } from "@prodoctivity/prodoctivity-form-v5/src/components/_lib/context-to-FormDefinition";
import type { FormDefinition } from "@prodoctivity/prodoctivity-form-v5/src/components/_lib/types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { Item } from "../Steps/DocumentKeyStep";
import { updateWizardDefinition } from "../utils";

export type StateFieldRelevance = {
  contextDefinition: TemplateContextDefinition;
  wizardDefinition?: TemplateWizardDefinition;
  formDefinition?: FormDefinition;
  updateTemplateWizardDefinition: (templateWizardDefinition: TemplateWizardDefinition) => void;
  onCloseSheet: () => void;
};

export function useFieldRelevance(stateField: StateFieldRelevance) {
  const { resources, moment } = useAppTranslation();

  const parse = contextToFormDefinition(stateField.contextDefinition);
  const [state] = useState<StateFieldRelevance>({ ...stateField, formDefinition: parse });

  return { resources, moment, state };
}

export type StateDataLinkSetting = {
  contextDefinition: TemplateContextDefinition;
  wizardDefinition?: TemplateWizardDefinition;
  formDefinition?: FormDefinition;
  dataLinkMappings?: TemplateVersionContextMapping["datalinks"];
  updateTemplateWizardDefinition: (templateWizardDefinition: TemplateWizardDefinition) => void;
  onCloseSheet: () => void;
};

export function useDataLinkSetting(stateField: StateDataLinkSetting) {
  const resources = useAppTranslation();
  const parse = contextToFormDefinition(stateField.contextDefinition);
  //const [state, setState] = useState<StateFieldRelevance>({ ...stateField, formDefinition: parse });

  return { resources, parse };
}

function toColumnControlSize(s: ControlSize) {
  switch (s) {
    case "Small":
      return 4;
    case "Large":
      return 12;
    case "None":
    case "Normal":
      return 8;
    default:
      return 12;
  }
}

type FormDesignerHookArgs = {
  dataElements: Array<DataElement>;
  nameConfig: StringTemplatePart[];
  updateNameConfig(nameConfig: StringTemplatePart[]): void;
  identifierConfig: StringTemplatePart[] | undefined;
  setIdentifierConfig(identifierConfig: StringTemplatePart[]): void;
  contextDefinition: TemplateContextRecordHolder;
  wizardDefinition?: TemplateWizardDefinition;
  onWizardDefinitionChange: (wizardDefinition: TemplateWizardDefinition) => void;
  onContextDefinitionChange?: (contextDefinition: TemplateContextRecordHolder) => void;
  isContinueButtonDisabled: boolean;
  setIsContinueButtonDisabled: Dispatch<SetStateAction<boolean>>;
};

type State = {
  pages: Array<TemplatePage>;
  originalSectionLabel?: string;
  sectionKeyEdited: string | undefined;
  sectionLabelEdited?: string;
  sectionDeleteActive: string | undefined;
  showFieldRelevanceSheet?: boolean;
  showDataLinkSheet?: boolean;
  showDistributionSheet?: boolean;
};

export type ContextFieldEditorProps = {
  contextFieldItem?: {
    dataElement: DataElement;
    itemToReplace: Item;
  };
  isEditingContextField: boolean;
  contextFieldProperties?: ContextFieldProperties;
  isFieldDisabledAndHidden: boolean;
  fullPath: string;
};

export const useFormDesigner = ({
  nameConfig,
  dataElements,
  identifierConfig,
  setIdentifierConfig,
  contextDefinition,
  wizardDefinition,
  onWizardDefinitionChange,
  updateNameConfig,
  onContextDefinitionChange,
  isContinueButtonDisabled,
  setIsContinueButtonDisabled,
}: FormDesignerHookArgs) => {
  const { resources, moment } = useAppTranslation();
  const [advancedMode, setAdvancedMode] = useState(false);
  const anchorRefItems = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const [contextFieldState, setContextFieldState] = useState<ContextFieldEditorProps>({
    contextFieldItem: undefined,
    isEditingContextField: false,
    contextFieldProperties: undefined,
    isFieldDisabledAndHidden: true,
    fullPath: "",
  });

  const sourceFields = useMemo(() => {
    if (!contextDefinition) {
      return [];
    }
    return contextDefinition.fields.map((keyword) => {
      return {
        label: keyword.properties.label,
        value: keyword.name,
        dataType: keyword.properties.dataType,
      };
    });
  }, [contextDefinition]);

  const identifierConfigOptions = useMemo(() => {
    return sourceFields.filter((f) => f.dataType === "Alphanumeric" || f.dataType === "Numeric");
  }, [sourceFields]);

  const [
    {
      pages,
      originalSectionLabel,
      sectionKeyEdited,
      sectionLabelEdited,
      sectionDeleteActive,
      showFieldRelevanceSheet,
      showDataLinkSheet,
      showDistributionSheet,
    },
    rawSetState,
  ] = useState<State>({
    pages: [],
    originalSectionLabel: undefined,
    sectionKeyEdited: undefined,
    sectionLabelEdited: undefined,
    sectionDeleteActive: undefined,
    showDataLinkSheet: false,
    showDistributionSheet: false,
    showFieldRelevanceSheet: false,
  });

  const setState = useCallback((setter: (p: State) => State) => {
    const newSetter = (p: State) => {
      const next = setter(p);

      return next;
    };
    rawSetState(newSetter);
  }, []);

  const allSections = useMemo(() => {
    return pages.flatMap((p) => p.sections);
  }, [pages]);

  const getContextValueAsString = useCallback((k: string) => {
    const value = evaluateContextValue({}, k, []);
    if (typeof value.type !== "string") {
      return undefined;
    }
    return value.valueAsString;
  }, []);

  const onSectionsChange = useCallback(
    (sections: Array<TemplateSection>) => {
      if (wizardDefinition) {
        const wizardUpdated: TemplateWizardDefinition = {
          ...wizardDefinition,
          pages: [
            {
              key: "generalPage",
              label: resources.formDesigner.generalPage,
              description: resources.formDesigner.generalPage,
              sections: sections,
              properties: {},
            },
          ],
        };

        onWizardDefinitionChange(wizardUpdated);
      }
    },
    [onWizardDefinitionChange, resources.formDesigner.generalPage, wizardDefinition]
  );

  const setEditableSection = useCallback(
    (sectionKey: string | undefined, sectionLabel: string) => {
      setState((prev) => {
        const pages = prev.pages.map((page) => {
          return {
            ...page,
            sections: page.sections.map((section) => {
              if (section.key !== sectionKey) {
                return section;
              }

              return {
                ...section,
                label: sectionLabel,
              };
            }),
          };
        });

        onSectionsChange(pages.flatMap((page) => page.sections));
        return {
          ...prev,
          pages,
          sectionKeyEdited: undefined,
          sectionLabelEdited: originalSectionLabel === sectionLabel ? undefined : sectionLabel,
          originalSectionLabel: originalSectionLabel === sectionLabel ? undefined : sectionLabel,
        };
      });
    },
    [setState, originalSectionLabel, onSectionsChange]
  );

  const addNewSection = useCallback(
    (section: TemplateSection) => {
      setState((prevState) => {
        const prev = deepCopy(prevState);

        const page = prev.pages[prev.pages.length - 1];

        if (!page) {
          return prev;
        }

        const index = page.sections.findIndex((s) => s.label === section.label);

        if (index >= 0) {
          return prev;
        } else {
          const newSections = page.sections;
          newSections.push(section);
          onSectionsChange(newSections);
          return prev;
        }
      });
    },
    [onSectionsChange, setState]
  );

  useEffect(() => {
    if (dataElements.length) {
      const newWizardDefinition = updateWizardDefinition(
        resources,
        dataElements,
        contextDefinition,
        wizardDefinition
      );
      if (stableStringify(wizardDefinition?.pages) !== stableStringify(newWizardDefinition.pages)) {
        setState((prev) => ({ ...prev, pages: newWizardDefinition.pages }));
        onWizardDefinitionChange(newWizardDefinition);
      }
      if (nameConfig.length <= 0) {
        const defaultNameConfig = getDefaultNameConfig(newWizardDefinition, contextDefinition);
        const stringTemplatePartConfirmed = confirmStringTemplatePartType(
          contextDefinition,
          defaultNameConfig
        );
        updateNameConfig(stringTemplatePartConfirmed);
      }
    } else if (wizardDefinition) {
      setState((prev) => {
        if (!areArraysEqual(wizardDefinition.pages, prev.pages)) {
          return { ...prev, pages: wizardDefinition.pages };
        }

        return prev;
      });
    }
  }, [
    contextDefinition,
    dataElements,
    nameConfig.length,
    onWizardDefinitionChange,
    resources,
    setState,
    updateNameConfig,
    wizardDefinition,
  ]);

  useEffect(() => {
    if (!(identifierConfig || []).length) {
      const defaultIdentifierConfig = getDefaultIdentifierConfig(contextDefinition);
      // Both are empty, the one that arrives as prop, and the one generated from contextDefinition
      if (stableStringify(identifierConfig) !== stableStringify(defaultIdentifierConfig)) {
        setIdentifierConfig(defaultIdentifierConfig);
      }
    }
  }, [
    contextDefinition,
    identifierConfig,
    isContinueButtonDisabled,
    setIdentifierConfig,
    setIsContinueButtonDisabled,
  ]);

  useEffect(() => {
    let canContinue = true;
    if (
      !(identifierConfig === undefined || !identifierConfig.length) &&
      !(identifierConfig || []).filter((p) => p.type === "variable").length
    ) {
      canContinue = false;
    }

    if (!(nameConfig || []).filter((p) => p.type === "variable").length) {
      canContinue = false;
    }

    if (canContinue && isContinueButtonDisabled) {
      setIsContinueButtonDisabled(false);
    } else if (!canContinue && !isContinueButtonDisabled) {
      setIsContinueButtonDisabled(true);
    }
  }, [identifierConfig, isContinueButtonDisabled, setIsContinueButtonDisabled, nameConfig]);

  const moveAllToAnotherSection = useCallback(
    (oldSectionKey: string, newSectionKey: string): void => {
      const sections = pages.flatMap((p) => p.sections);
      const oldSectionIndex = sections.findIndex((section) => section.key === oldSectionKey);
      const newSectionIndex = sections.findIndex((section) => section.key === newSectionKey);

      if (oldSectionIndex === -1 || newSectionIndex === -1) {
        return;
      }

      const fields = sections[oldSectionIndex].fields;

      const newSection = deepCopy(sections[newSectionIndex]);

      newSection.fields.push(...fields);

      const copiedPages = deepCopy(pages);
      const oldSectionPageIndex = copiedPages.findIndex((p) =>
        p.sections.some((s) => s.key === oldSectionKey)
      );
      if (oldSectionPageIndex < 0) {
        return;
      }
      copiedPages[oldSectionPageIndex].sections = copiedPages[oldSectionPageIndex].sections.filter(
        (s) => s.key !== oldSectionKey
      );

      const newSectionPageIndex = copiedPages.findIndex((p) =>
        p.sections.some((s) => s.key === newSectionKey)
      );

      if (newSectionPageIndex < 0) {
        return;
      }
      const newSectionIdx = copiedPages[newSectionPageIndex].sections.findIndex(
        (s) => s.key === newSectionKey
      );
      if (newSectionIdx < 0) {
        return;
      }

      copiedPages[newSectionPageIndex].sections[newSectionIdx] = newSection;

      setState((prev) => ({
        ...prev,
        pages: copiedPages.filter((p) => p.sections.length > 0),
        sectionDeleteActive: undefined,
      }));
      const newSections = copiedPages
        .filter((p) => p.sections.length > 0)
        .flatMap((p) => p.sections);
      onSectionsChange(newSections);
    },
    [onSectionsChange, pages, setState]
  );

  const moveFieldBetweenSections = useCallback(
    (oldSectionKey: string, newSectionKey: string, itemKey: string): void => {
      const copiedPages = deepCopy(pages);
      const sections = copiedPages.flatMap((p) => p.sections);
      const oldSectionIndex = sections.findIndex((section) => section.key === oldSectionKey);
      const newSectionIndex = sections.findIndex((section) => section.key === newSectionKey);

      if (oldSectionIndex === -1 || newSectionIndex === -1) {
        return;
      }

      const oldItemIndex = sections[oldSectionIndex].fields.findIndex(
        (item) => item.key === itemKey
      );

      if (oldItemIndex === -1) {
        return;
      }
      const newSections = sections;

      newSections[newSectionIndex].fields.push(
        newSections[oldSectionIndex].fields.splice(oldItemIndex, 1)[0]
      );

      setState((prev) => ({ ...prev, pages: copiedPages }));
      onSectionsChange(newSections);
    },
    [onSectionsChange, setState, pages]
  );

  const moveItemInSectionByKey = useCallback(
    (key: string, oldIndex: number, newIndex: number) => {
      const copiedPages = deepCopy(pages);
      function moveItemInSection(section: TemplateSection | TemplateWizardFieldIsRecord) {
        section.fields.forEach((item, i) => {
          if (item.key === key && i === oldIndex) {
            // Find the item and element to move
            const [element] = section.fields.splice(oldIndex, 1);
            section.fields.splice(newIndex, 0, element);
          } else if (item.isRecord === true && item.fields) {
            moveItemInSection(item);
          }
        });
      }

      const sections = copiedPages.flatMap((p) => p.sections);

      const newSections = sections;
      newSections.forEach((section, i) => {
        if (section.key === key && i === oldIndex) {
          const [element] = newSections.splice(oldIndex, 1);
          newSections.splice(newIndex, 0, element);
        } else {
          moveItemInSection(section);
        }
      });

      setState((prev) => ({ ...prev, pages: copiedPages }));
      onSectionsChange(newSections);
    },
    [onSectionsChange, setState, pages]
  );

  const moveFieldToSection = useCallback(
    (sourceKey: string, sectionKey: string) => {
      const copiedPages = deepCopy(pages);
      const sections = copiedPages.flatMap((p) => p.sections);

      const sourceSection = sections.find((section) =>
        section.fields.some((fld) => fld.key === sourceKey)
      );

      if (!sourceSection) {
        return;
      }

      const targetSection = sections.find((section) => section.key === sectionKey);

      if (!targetSection) {
        return;
      }

      const sourceFieldIndex = sourceSection.fields.findIndex((fld) => fld.key === sourceKey);

      if (sourceFieldIndex < 0) {
        return;
      }

      const sourceField = sourceSection.fields[sourceFieldIndex];

      sourceSection.fields.splice(sourceFieldIndex, 1);

      targetSection.fields.push(sourceField);

      setState((prev) => ({ ...prev, pages: copiedPages }));
      onSectionsChange(sections);
    },
    [onSectionsChange, setState, pages]
  );

  const moveSection = useCallback(
    (sourceKey: string, targetKey: string) => {
      const copiedPages = deepCopy(pages);

      const sections = copiedPages.flatMap((p) => p.sections.map((s) => ({ page: p, section: s })));

      const sourceSection = sections.find((s) => s.section.key === sourceKey);

      if (!sourceSection) {
        return;
      }

      const targetSection = sections.find((s) => s.section.key === targetKey);

      if (!targetSection) {
        return;
      }

      const sectionIndexInPage = sourceSection.page.sections.findIndex((s) => s.key === sourceKey);

      sourceSection.page.sections.splice(sectionIndexInPage, 1);

      const targetSectionIndexInPage = targetSection.page.sections.findIndex(
        (s) => s.key === targetKey
      );

      targetSection.page.sections.splice(targetSectionIndexInPage + 1, 0, sourceSection.section);

      setState((prev) => ({ ...prev, pages: copiedPages }));
    },
    [pages, setState]
  );

  const updateControlSize = useCallback(
    (section: TemplateSection, field: TemplateWizardField, size: ControlSize): void => {
      function editItemInSection(section: TemplateSection | TemplateWizardFieldIsRecord) {
        section.fields.forEach((item) => {
          if (item.key === field.key) {
            // Find the item and element to move

            if (item.isRecord === false) {
              const properties = { controlSize: size };
              item.properties = { ...item.properties, ...properties };
            }
          } else if (item.isRecord === true && item.fields) {
            editItemInSection(item);
          }
        });
      }

      const copiedPages = deepCopy(pages);
      const sections = copiedPages.flatMap((p) => p.sections);

      const sectionEdit = sections.find((p) => p.key === section.key);
      if (sectionEdit) {
        editItemInSection(sectionEdit);
        const index = sections.findIndex((p) => p.key === section.key);
        const newSections = sections;
        newSections[index] = { ...sectionEdit };
        setState((prev) => ({ ...prev, pages: copiedPages }));
        onSectionsChange(newSections);
      }

      // if (sectionEdit) {
      //   let index = state.sections.findIndex((p) => p.key == section.key);
      //   let edit = sectionEdit?.fields.find((p) => p.key == field.key);
      //   if (edit) {
      //     let indexField = sectionEdit.fields.findIndex((p) => p.key == field.key);
      //     edit.controlSize = size;
      //     sectionEdit.fields[indexField] = edit

      //     const newSections = state.sections;
      //     newSections[index] = { ...sectionEdit }

      //     setState({ ...state, sections: newSections });
      //     onSectionsChange(newSections);
      //   } else {

      //   }
      // }
    },
    [onSectionsChange, pages, setState]
  );

  const onCloseSheet = useCallback(() => {
    setState((prev) => ({
      ...prev,
      showDataLinkSheet: false,
      showDistributionSheet: false,
      showFieldRelevanceSheet: false,
    }));
  }, [setState]);

  const onShowSheet = useCallback(
    (sheet: "field" | "connector" | "distribution") => {
      switch (sheet) {
        case "field":
          setState((prev) => ({ ...prev, showFieldRelevanceSheet: true }));
          break;
        case "connector":
          setState((prev) => ({ ...prev, showDataLinkSheet: true }));
          break;

        default:
          setState((prev) => ({ ...prev, showDistributionSheet: true }));
          break;
      }
    },
    [setState]
  );
  const sectionIsDuplicated = useCallback(
    (actualSectionLabel?: string): boolean => {
      if (actualSectionLabel && sectionLabelEdited && actualSectionLabel !== sectionLabelEdited) {
        const index = pages
          .flatMap((p) => p.sections)
          .findIndex((s) => s.label === sectionLabelEdited);

        return index >= 0;
      }
      return false;
    },
    [pages, sectionLabelEdited]
  );

  const handleOnKeyDown = useCallback(
    (args: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event: { keyCode: number; key: string };
    }) => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        event: { keyCode, key },
      } = args;
      if (
        keyCode === 13 /* Enter */ &&
        sectionLabelEdited &&
        sectionLabelEdited === originalSectionLabel &&
        !sectionIsDuplicated(originalSectionLabel)
      ) {
        setState((prev) => ({
          ...prev,
          sectionKeyEdited: undefined,
          sectionLabelEdited: undefined,
          originalSectionLabel: undefined,
        }));
        return;
      }
      if (
        keyCode === 13 /* Enter */ &&
        sectionLabelEdited &&
        sectionLabelEdited !== originalSectionLabel &&
        !sectionIsDuplicated(originalSectionLabel)
      ) {
        const copiedPages = deepCopy(pages);
        copiedPages
          .flatMap((p) => p.sections)
          .forEach((s) => {
            if (s.label === originalSectionLabel && sectionLabelEdited) {
              s.label = sectionLabelEdited;
              s.description = sectionLabelEdited;
            }
            return s;
          });

        setState((prev) => ({
          ...prev,
          pages: copiedPages,
          sectionKeyEdited: undefined,
          sectionLabelEdited: undefined,
          originalSectionLabel: undefined,
        }));
      } else if (key === "Escape" && originalSectionLabel) {
        setEditableSection(sectionKeyEdited, originalSectionLabel);
      }
    },
    [
      sectionIsDuplicated,
      originalSectionLabel,
      pages,
      sectionKeyEdited,
      sectionLabelEdited,
      setState,
      setEditableSection,
    ]
  );

  const setWizardDefinition = useCallback(
    (newWizardDefinition: TemplateWizardDefinition) => {
      onWizardDefinitionChange(newWizardDefinition);
    },
    [onWizardDefinitionChange]
  );

  const onDeleteSectionClick = useCallback(
    (key?: string) => {
      setState((prev) => ({
        ...prev,
        sectionDeleteActive: key === prev.sectionDeleteActive ? undefined : key,
      }));
    },
    [setState]
  );

  const { eventManager } = usePubSub<EditVariableCommand>();

  const [showVariablePartSheet, setShowVariablePartSheet] = useState(false);

  const [clickedPart, setClickedPart] = useState<VariablePartSheetCurrentPart>();

  const [currentVariable, rawSetCurrentVariable] = useState<
    StringTemplateParameterValue | undefined
  >();

  const rawSetCurrentVariableValue = useCallback(
    (value: StringTemplateParameterValue | undefined) => {
      rawSetCurrentVariable(value);
    },
    [rawSetCurrentVariable]
  );

  const initialState: { parts: StringTemplatePart[] } = useMemo(() => {
    return {
      parts: nameConfig,
    };
  }, [nameConfig]);

  const handlePartClicked = useCallback(
    (args: {
      lineIndex: number;
      partIndex: number;
      parameterKey: string;
      part: StringTemplatePart;
    }) => {
      setClickedPart({
        lineIndex: args.lineIndex,
        parameterKey: args.parameterKey,
        partIndex: args.partIndex,
        part: args.part,
      });
      setShowVariablePartSheet(true);
    },
    []
  );
  const handleVariableAdded = useCallback(
    (_args: { lineIndex: number; partIndex: number; name: string; dataType: DataType }) => {
      setShowVariablePartSheet(false);
    },
    []
  );

  const getFieldLabel = useCallback(
    (fldName: string) => {
      const found = sourceFields.find((fld) => fld.value === fldName);
      if (found) {
        return found.label;
      }
      return fldName;
    },
    [sourceFields]
  );

  const variableList: StringTemplateParameterValue[] = useMemo(() => {
    return sourceFields.map((field) => {
      return {
        source: "context",
        value: field.value,
      };
    });
  }, [sourceFields]);

  const getFieldDataType: (
    source: StringTemplateParameterValue["source"],
    name: string
  ) => DataType = useCallback(
    (_source, name) => {
      const found = sourceFields.find((fld) => fld.value === name);
      if (found) {
        return found.dataType;
      }
      return "Alphanumeric";
    },
    [sourceFields]
  );

  const getNewVariableData = useCallback(() => {
    const found = variableList[0];
    if (found) {
      return {
        name: found.value,
        dataType: getFieldDataType(found.source, found.value),
      };
    } else {
      throw new Error("Cannot happen");
    }
  }, [getFieldDataType, variableList]);

  const advancedModeChecked: SwitchProps["onChange"] = useCallback((args) => {
    setAdvancedMode(args.value);
  }, []);

  const identifierConfigsSelected = useMemo(() => {
    if (!identifierConfig) {
      return [];
    }
    return identifierConfig.reduce((arr: Array<{ value: string; label: string }>, item) => {
      if (item.type === "variable") {
        const field = sourceFields.find((f) => f.value === item.name);
        if (field) {
          arr.push({
            value: item.name,
            label: field.label,
          });
        }
      }
      return arr;
    }, []);
  }, [identifierConfig, sourceFields]);

  const onSelectIdentifierConfig = useCallback(
    (items: Array<{ value: string; label: string }>) => {
      const variablePartsConstructed: StringTemplatePart[] = [];

      items.forEach((item, index) => {
        const field = contextDefinition.fields.find((f) => f.name === item.value);
        if (field) {
          variablePartsConstructed.push({
            type: "variable",
            name: field.name,
            dataType: field.properties.dataType,
          });
          if (index < items.length - 1) {
            variablePartsConstructed.push({
              type: "text",
              value: " - ",
            });
          }
        }
      });

      setIdentifierConfig(variablePartsConstructed);
    },
    [contextDefinition, setIdentifierConfig]
  );

  const onEditClick = useCallback(
    (
      dataElements: {
        dataElement: DataElement;
        itemToReplace: Item;
      },
      fullPath: string,
      properties: ContextFieldProperties
    ) => {
      setContextFieldState({
        ...contextFieldState,
        contextFieldItem: dataElements,
        isEditingContextField: true,
        contextFieldProperties: properties,
        fullPath: fullPath,
      });
    },
    [contextFieldState, setContextFieldState]
  );

  const updateContextFieldIfExists = useCallback(
    (field: ContextField) => {
      if (field) {
        const fields: {
          records: ContextRecord[];
          fields: ContextField[];
        } = { ...contextDefinition };

        const findField = (fieldsObj: { records: ContextRecord[]; fields: ContextField[] }) => {
          const index = fieldsObj.fields.findIndex((i) => i.fullPath === field.fullPath);
          if (index !== -1) {
            fieldsObj.fields[index] = field;
            return true;
          }

          for (let i = 0; i < fieldsObj.records.length; i++) {
            if (
              findField({
                records: fieldsObj.records[i].records,
                fields: fieldsObj.records[i].fields,
              })
            ) {
              return true;
            }
          }

          return false;
        };

        if (findField(fields)) {
          onContextDefinitionChange && onContextDefinitionChange(fields);
          setContextFieldState({ ...contextFieldState, isEditingContextField: false });
        }
      }
    },
    [contextDefinition, onContextDefinitionChange, contextFieldState]
  );

  return {
    resources,
    moment,
    setState,
    advancedMode,
    anchorRefItems,

    originalSectionLabel,
    sectionKeyEdited,
    sectionLabelEdited,
    pages,

    sectionDeleteActive,
    showFieldRelevanceSheet,
    showDataLinkSheet,
    showDistributionSheet,

    allSections,

    identifierConfigOptions,
    identifierConfigsSelected,
    onSelectIdentifierConfig,

    addNewSection,
    setEditableSection,
    getContextValueAsString,
    toColumnControlSize,
    moveAllToAnotherSection,
    moveFieldBetweenSections,
    moveItemInSectionByKey,
    moveFieldToSection,
    moveSection,
    updateControlSize,
    handleOnKeyDown,
    setWizardDefinition,
    onDeleteSectionClick,
    sectionIsDuplicated,
    getFieldDataType,
    variableList,

    eventManager,
    clickedPart,
    currentVariable,
    rawSetCurrentVariableValue,
    initialState,
    showVariablePartSheet,
    setShowVariablePartSheet,
    onCloseSheet,
    onShowSheet,
    handlePartClicked,
    handleVariableAdded,
    getFieldLabel,
    getNewVariableData,
    advancedModeChecked,

    updateContextFieldIfExists,
    contextFieldState,
    setContextFieldState,
    onEditClick,
  };
};
