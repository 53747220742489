import {
  Box,
  DeleteDocumentModal,
  Divider,
  DownloadSvg,
  PrintSvg,
  ResendInvitationIconSvg,
  TapArea,
  Text,
  TrashSvgIcon,
  useColors,
} from "@prodoctivity/design-system";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";
import type { EcmDocument } from "@prodoctivity/types";
import { downloadZipFile } from "../../utils";
import { mimeTypeToExtension } from "@prodoctivity/shared";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { GenerateSvgIcon } from "../../svg/GenerateSvgIcon";
import { SignDocumentSvg } from "@prodoctivity/design-system/svg/SignDocumentSvg";

type Props = {
  setIsPrintScreenVisible: (d: boolean) => void;
  setSelectedPanel: (panel: DocumentViewerPanelKeys) => void;
  ecmDocument: EcmDocument;
  deleteDocument(): void;
  isRestoring: boolean;
  setIsTemplateLookupOpen: Dispatch<SetStateAction<boolean>>;
};

export function ViewerDocumentShared({
  setIsPrintScreenVisible,
  setSelectedPanel,
  ecmDocument,
  deleteDocument,
  setIsTemplateLookupOpen,
}: Props) {
  const [hoverState, setHoverState] = useState({
    print: false,
    delete_: false,
    download: false,
    email: false,
    generate: false,
    sign: false,
  });
  const { colors } = useColors();
  const primaryHover0 = colors.primaryHover0;
  const colorPrint = hoverState.print ? colors.primary : undefined;
  const colorDownload = hoverState.download ? colors.primary : undefined;
  const colorEmail = hoverState.email ? colors.primary : undefined;
  const colorDelete = hoverState.delete_ ? colors.error : undefined;
  const colorGenerate = hoverState.generate ? colors.primary : undefined;
  const colorSign = hoverState.sign ? colors.primary : undefined;
  const { resources } = useAppTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hideBtnAudioVideoPlayer =
    ecmDocument.mimeType === "audio/mpeg" || ecmDocument.mimeType === "video/mp4" ? false : true;

  const isImage = useMemo(() => {
    return ecmDocument.mimeType.startsWith("image/");
  }, [ecmDocument.mimeType]);

  const downloadHref = useMemo(() => {
    return `data:${ecmDocument.mimeType};base64,${ecmDocument.binaries[0]}`;
  }, [ecmDocument.binaries, ecmDocument.mimeType]);

  return (
    <Box padding={5} position="relative">
      <Box display="flex" direction="column" gap={2}>
        {hideBtnAudioVideoPlayer && (
          <>
            <TapArea onTap={() => setIsPrintScreenVisible(true)}>
              <Box
                hoverColor={primaryHover0}
                onMouseMove={() =>
                  setHoverState({ ...hoverState, print: true, download: false, email: false })
                }
                onMouseOut={() => setHoverState({ ...hoverState, print: false })}
                padding={2}
              >
                <Box display="flex" alignItems="center" gap={4}>
                  <PrintSvg color={colorPrint} height={28} width={28} />
                  <Text color={colorPrint}>{resources.print}</Text>
                </Box>
              </Box>
            </TapArea>
            <Divider />
            {isImage ? (
              <TapArea
                onTap={() => {
                  downloadZipFile(ecmDocument.name, ecmDocument.mimeType, ecmDocument.binaries);
                }}
              >
                <Box
                  hoverColor={primaryHover0}
                  onMouseMove={() =>
                    setHoverState({
                      ...hoverState,
                      download: true,
                      print: false,
                      email: false,
                      generate: false,
                      delete_: false,
                      sign: false,
                    })
                  }
                  onMouseOut={() => setHoverState({ ...hoverState, download: false })}
                  padding={2}
                >
                  <Box display="flex" alignItems="center" gap={4}>
                    <DownloadSvg color={colorDownload} height={28} width={28} />
                    <Text color={colorDownload}>{resources.download}</Text>
                  </Box>
                </Box>
              </TapArea>
            ) : (
              //TODO: @eburgos change this
              <a
                download={`${ecmDocument.name}${mimeTypeToExtension(ecmDocument.mimeType)}`}
                href={downloadHref}
              >
                <Box
                  hoverColor={primaryHover0}
                  onMouseMove={() =>
                    setHoverState({
                      ...hoverState,
                      download: true,
                      print: false,
                      email: false,
                      generate: false,
                      delete_: false,
                      sign: false,
                    })
                  }
                  onMouseOut={() => setHoverState({ ...hoverState, download: false })}
                  padding={2}
                >
                  <Box display="flex" alignItems="center" gap={4}>
                    <DownloadSvg height={28} width={28} color={colorDownload} />
                    <Text color={colorDownload}>{resources.download}</Text>
                  </Box>
                </Box>
              </a>
            )}

            <Divider />

            <TapArea
              onTap={() => {
                setSelectedPanel("exportViaEmail");
              }}
            >
              <Box
                hoverColor={primaryHover0}
                onMouseMove={() =>
                  setHoverState({
                    ...hoverState,
                    email: true,
                    print: false,
                    download: false,
                    generate: false,
                    delete_: false,
                    sign: false,
                  })
                }
                onMouseOut={() => setHoverState({ ...hoverState, email: false })}
                padding={2}
              >
                <Box display="flex" alignItems="center" gap={4}>
                  <ResendInvitationIconSvg height={28} width={28} color={colorEmail} />
                  <Text color={colorEmail}>{resources.sendViaEmailPage.sendViaEmail}</Text>
                </Box>
              </Box>
            </TapArea>
            <Divider />

            <TapArea onTap={() => setIsTemplateLookupOpen(true)}>
              <Box
                hoverColor={primaryHover0}
                onMouseMove={() =>
                  setHoverState({
                    ...hoverState,
                    email: false,
                    print: false,
                    download: false,
                    generate: true,
                    delete_: false,
                  })
                }
                onMouseOut={() => setHoverState({ ...hoverState, generate: false })}
                padding={2}
              >
                <Box display="flex" alignItems="center" gap={4}>
                  <GenerateSvgIcon width={28} height={40} color={colorGenerate} />
                  <Text color={colorGenerate}>{`${resources.generate}`}</Text>
                </Box>
              </Box>
            </TapArea>

            <Divider />

            <TapArea
              onTap={() => {
                setSelectedPanel("sign");
              }}
            >
              <Box
                hoverColor={primaryHover0}
                onMouseMove={() =>
                  setHoverState({
                    ...hoverState,
                    email: false,
                    print: false,
                    download: false,
                    delete_: false,
                    sign: true,
                  })
                }
                onMouseOut={() => setHoverState({ ...hoverState, sign: false })}
                padding={2}
              >
                <Box display="flex" alignItems="center" gap={4}>
                  <SignDocumentSvg height={28} width={28} color={colorSign} />
                  <Text color={colorSign}>{resources.signThisDocument}</Text>
                </Box>
              </Box>
            </TapArea>

            <Divider />
          </>
        )}

        <TapArea onTap={() => setShowDeleteModal(true)}>
          <Box
            hoverColor={primaryHover0}
            onMouseMove={() =>
              setHoverState({
                ...hoverState,
                email: false,
                print: false,
                download: false,
                delete_: true,
                generate: false,
              })
            }
            onMouseOut={() => setHoverState({ ...hoverState, delete_: false })}
            padding={2}
          >
            <Box display="flex" alignItems="center" gap={4}>
              <TrashSvgIcon width={28} height={28} color={colorDelete} />
              <Text color={colorDelete}>{resources.delete_}</Text>
            </Box>
          </Box>
        </TapArea>
        <DeleteDocumentModal
          show={showDeleteModal}
          resources={resources}
          onDismiss={() => setShowDeleteModal(false)}
          deleteDocument={() => deleteDocument()}
        />
      </Box>
    </Box>
  );
}
