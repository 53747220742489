import {
  Box,
  Flex,
  Label,
  SelectList,
  TapArea,
  Text,
  Tooltip,
  useColors,
} from "@prodoctivity/design-system";
import type { DataElement, DictionaryList } from "@prodoctivity/shared/src/index-types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../../hooks/useOrganizationNavigate";
import { organizationLinkTemplates } from "../../../../link-templates";
import { RecentActivitySvgIcon } from "../../../../svg/RecentActivitySvgIcon";
import { useDictionaryListControl } from "./hook";

type Props = {
  dictionaryLists: DictionaryList<string>[] | undefined;
  element: DataElement;
  isUpdating: boolean;
  onChange: (dataElement: DataElement) => void;
  selectedDictionaryListName: string | undefined;
  setDictionaryListName: (n: string) => void;
  setShowPopoverComboBox: (n: boolean) => void;
  setLists: (n: boolean) => void;
  isSynchronizationNeeded: boolean;
};

export type DictionaryListPath = {
  key: string;
  label: string;
  children?: DictionaryListPath[];
  found: boolean;
  path: string;
};

export function DictionaryListControl({
  dictionaryLists,
  element,
  isUpdating,
  onChange,
  setDictionaryListName,
  selectedDictionaryListName,
  setShowPopoverComboBox,
  setLists,
  isSynchronizationNeeded,
}: Props) {
  const { resources } = useAppTranslation();
  const { colors } = useColors();

  const { synchronizeValueLists, synchronizeLists, updateDictionaryList } =
    useDictionaryListControl(
      setLists,
      element,
      dictionaryLists,
      onChange,
      setShowPopoverComboBox,
      setDictionaryListName
    );

  const organizationNavigate = useOrganizationNavigate();

  return element && element.dictionaryDataType === "List" ? (
    <Box alignItems="center" display="flex">
      <Box minWidth={120}>
        <Label htmlFor="elementDataType">
          <Text color={colors.subtle}>{resources.dataDictionary.dictionaryList}</Text>
        </Label>
      </Box>
      <Flex
        alignItems={isSynchronizationNeeded ? "center" : "start"}
        gap={1}
        direction={isSynchronizationNeeded ? "row" : "column"}
      >
        <Box minWidth={120} width={260}>
          <SelectList
            id="dictionaryList"
            onChange={({ value }) => updateDictionaryList(value)}
            options={(dictionaryLists ? dictionaryLists : []).map((dl) => {
              return {
                key: dl.listSchema.name,
                label: dl.listSchema.name,
                value: dl.listSchema.name,
              };
            })}
            value={selectedDictionaryListName}
            disabled={isUpdating}
          />
        </Box>

        {synchronizeLists && !isUpdating && (
          <>
            {isSynchronizationNeeded ? (
              <Tooltip text={resources.syncLossWarningMessage}>
                <TapArea onTap={synchronizeValueLists}>
                  <RecentActivitySvgIcon width={18} height={18} />
                </TapArea>
              </Tooltip>
            ) : (
              <Box paddingX={1}>
                <Text color={colors.neutral900}>
                  <TapArea
                    onTap={() =>
                      organizationNavigate(
                        organizationLinkTemplates.editDataLists(element?.dictionaryListName)
                      )
                    }
                  >
                    {resources.SyncValueLists}
                  </TapArea>
                </Text>
              </Box>
            )}
          </>
        )}
      </Flex>
    </Box>
  ) : null;
}
