// @flow

import "./AssignConnectors.css";

import * as React from "react";

import { Checkbox, Divider, Empty, Input, Radio, Table } from "antd";
import type { FormConnector, WebServiceConnector } from "../_lib/types";

import { Icon } from "@prodoctivity/design-system";
import update from "immutability-helper";
import { FormDragDropContext } from "../_lib/DragDropContext";
import { noop } from "../_lib/utils";
import { DraggableBodyRow } from "./DraggableBodyRow";
import { mergeConnectorsAndFormConnectors } from "./utils";

//import noop from 'noop2'
//import { highlightChars } from 'highlight-matches-utils'
//import Table from 'antd/lib/table'

const checkboxAndRadioStyle = { display: "block", marginLeft: 0 };

const MyDivider = () => (
  <div>
    <Divider type="vertical" style={{ height: "90%", top: "5%" }} />
  </div>
);

export type ConnectorToAssign = FormConnector & { key: string; selected?: boolean };

type P = {
  connectors: WebServiceConnector[];
  i18n: (key: string) => string;
  formConnectors: FormConnector[];
  onChange: (formConnectors: FormConnector[]) => void;
  onChangeConnectorsToAssign: (connectorsToAssign: ConnectorToAssign[]) => void;
};

type S = {
  searchText: string;
  connectors: WebServiceConnector[];
  filteredConnectors?: WebServiceConnector[] | null;
  connectorsToAssign: ConnectorToAssign[];
};

class AssignConnectors extends React.PureComponent<P, S> {
  static displayName = "AssignConnectors";

  static defaultProps = {
    onChangeConnectorsToAssign: noop,
  };

  state: S = {
    searchText: "",
    connectors: this.props.connectors.map((cds) => ({
      ...cds,
      key: cds.name,
      name2: cds.name + " [" + this.props.i18n(cds.kind) + "]",
    })),
    filteredConnectors: undefined,
    connectorsToAssign: mergeConnectorsAndFormConnectors(
      this.props.connectors,
      this.props.formConnectors
    ),
  };

  notifyChange = () => {
    const formConnectors: FormConnector[] = this.state.connectorsToAssign
      .filter((ac) => ac.selected)
      .map((ac) => {
        const { key: _key, selected: _selected, ...formConnector } = ac;
        return formConnector;
      });
    this.props.onChange(formConnectors);
    this.props.onChangeConnectorsToAssign(this.state.connectorsToAssign);
  };

  constructor(props: P) {
    super(props);
    this.notifyChange();
  }

  highlightChars = (text: string, find: string, replaceCallBack: any) => {
    const target = text;
    return target.split(find).map(function (part, i, arr) {
      return i === arr.length - 1 ? part : replaceCallBack(part, i);
    });
  };

  columns = [
    {
      title: this.props.i18n("Connector"),
      dataIndex: "name2",
      key: "name",
      onHeaderCell: (column: any) => {
        return { id: column.key };
      },
      filterMultiple: false,
      filters: [
        { text: this.props.i18n("Selected"), value: "SELECTED" },
        { text: this.props.i18n("Unselected"), value: "UNSELECTED" },
        { text: this.props.i18n("All"), value: "ALL" },
      ],
      onFilter: (value: "SELECTED" | "UNSELECTED" | "ALL", record: WebServiceConnector) => {
        if (value === "ALL") {
          return true;
        }

        const currentRecordIsSelected = this.state.connectorsToAssign.some(
          ({ name, selected }) => name === record.name && selected
        );
        if (value === "SELECTED") {
          return currentRecordIsSelected;
        }
        if (value === "UNSELECTED") {
          return !currentRecordIsSelected;
        }

        throw new Error(`Unrecognized filter value: ${value}`);
      },
      render: (text: string) =>
        this.highlightChars(text, this.state.searchText, (s: string, i: number) => (
          <mark
            key={i}
            style={{
              padding: 0,
              fontWeight: 600,
              backgroundColor: "transparent",
            }}
          >
            {s}
          </mark>
        )),
    },
  ];

  getHeaderCell = (headerCellProps: any) => {
    if (headerCellProps.id === "name") {
      return (
        <th
          style={{
            display: "flex",
            alignItems: "center",
          }}
          {...headerCellProps}
        >
          {headerCellProps.children}
          <Input
            name="search"
            placeholder={`${this.props.i18n("Search")}...`}
            style={{
              flexGrow: 1,
              marginLeft: 40,
              width: "auto",
            }}
            value={this.state.searchText}
            onChange={this.handleSearchTextChange}
          />
        </th>
      );
    }

    return <th {...headerCellProps} />;
  };

  components = {
    body: {
      row: DraggableBodyRow,
    },
    header: {
      cell: this.getHeaderCell,
    },
  };

  moveRow = (dragIndex: number, hoverIndex: number) => {
    this.setState((prevState) => {
      const { connectors, connectorsToAssign } = update(prevState, {
        connectors: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevState.connectors[dragIndex]],
          ],
        },
        connectorsToAssign: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevState.connectorsToAssign[dragIndex]],
          ],
        },
      });

      return { connectors, connectorsToAssign };
    }, this.notifyChange);
  };

  handleSearchTextChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.setState((prevState) => {
      const filteredConnectors = value
        ? //? filter(prevState.connectors, value, {key: 'name',})
          prevState.connectors.filter((c) => c.name === value)
        : undefined;

      return { searchText: value, filteredConnectors };
    });
  };

  rowSelection = {
    onChange: (selectedRowKeys: Array<any>, selectedRows: Array<WebServiceConnector>) => {
      this.setState((prevState) => {
        const connectorsToAssign = prevState.connectorsToAssign.map((c) => {
          c.selected = selectedRows.some((row) => c.name === row.name);

          if (c.selected && c.output.length === 0) {
            selectedRows = selectedRows.map((row) => {
              if (c.name === row.name)
                row.outputMapping.forEach((output) => {
                  c.output.push({ name: output.dataElement.name });
                });
              return row;
            });
          }
          return c;
        });

        return { connectorsToAssign };
      }, this.notifyChange);
    },
  };

  expandedRowRender = (record: WebServiceConnector) => {
    const index = this.state.connectorsToAssign.findIndex((c) => c.name === record.name);
    const assignedConnector = this.state.connectorsToAssign[index];

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div>
          {record.inputMapping &&
            record.inputMapping.map((r) => (
              <div key={r.dataElement.name}>
                <Icon icon="key" accessibilityLabel={"key"} /> {r.dataElement.name}
              </div>
            ))}
        </div>

        <MyDivider />

        <Checkbox.Group
          defaultValue={assignedConnector.output.map((i) => i.name)}
          onChange={(names) => {
            this.setState((prevState) => {
              const connectorsToAssign = [...prevState.connectorsToAssign];
              connectorsToAssign[index].output = names.map((name) => ({
                name: name.toString(),
              }));
              return { connectorsToAssign };
            }, this.notifyChange);
          }}
        >
          {record.outputMapping
            .filter((r) => r.dataElement != null)
            .map((r) => (
              <Checkbox
                style={checkboxAndRadioStyle}
                key={r.dataElement.name}
                value={r.dataElement.name}
              >
                {r.dataElement.name}
              </Checkbox>
            ))}
        </Checkbox.Group>

        <MyDivider />

        <div
          style={record.kind === "ExternalList" ? { pointerEvents: "none", opacity: "0.7" } : {}}
        >
          <div style={{ marginBottom: "1rem", marginRight: "2rem" }}>
            <strong>{this.props.i18n("DataFound")}</strong>
            <div>
              <Radio.Group
                name="shouldDisableOnData"
                defaultValue={assignedConnector.shouldDisableOnData}
                onChange={(e) => {
                  this.setState((prevState) => {
                    const connectorsToAssign = [...prevState.connectorsToAssign];
                    connectorsToAssign[index].shouldDisableOnData = e.target.value;
                    return { connectorsToAssign };
                  }, this.notifyChange);
                }}
              >
                <Radio style={checkboxAndRadioStyle} value={true}>
                  {this.props.i18n("Disable")}
                </Radio>
                <Radio style={checkboxAndRadioStyle} value={false}>
                  {this.props.i18n("Enable")}
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div>
            <strong>{this.props.i18n("NoDataFound")}</strong>
            <div>
              <Radio.Group
                name="shouldDisableOnNoData"
                defaultValue={assignedConnector.shouldDisableOnNoData}
                onChange={(e) => {
                  this.setState((prevState) => {
                    const connectorsToAssign = [...prevState.connectorsToAssign];
                    connectorsToAssign[index].shouldDisableOnNoData = e.target.value;
                    return { connectorsToAssign };
                  }, this.notifyChange);
                }}
              >
                <Radio style={checkboxAndRadioStyle} value={true}>
                  Disable
                </Radio>
                <Radio style={checkboxAndRadioStyle} value={false}>
                  Enable
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <div>{`${this.props.i18n("NoDataFound")}:`}</div>
          <textarea
            defaultValue={assignedConnector.noDataMessage}
            style={{ width: "100%", height: "100%" }}
            onChange={(e) => {
              const message = e.currentTarget.value;
              this.setState((prevState) => {
                const connectorsToAssign = [...prevState.connectorsToAssign];
                connectorsToAssign[index].noDataMessage = message;
                return { connectorsToAssign };
              }, this.notifyChange);
            }}
          />
        </div>
      </div>
    );
  };

  onRow = (record: any, index?: number) => {
    return { index, moveRow: this.moveRow } as React.HTMLAttributes<any>;
  };

  render() {
    const { filteredConnectors, connectors, connectorsToAssign } = this.state;
    const isNotEmpty = connectors.length > 0;
    const selectedRowKeys = connectorsToAssign
      .filter(({ selected }) => selected)
      .map(({ name }) => name);

    const ExpandedRowRender = ({ record }: { record: any }) => this.expandedRowRender(record);

    return isNotEmpty ? (
      <Table
        bordered
        components={this.components}
        rowSelection={{
          selectedRowKeys,
          onChange: this.rowSelection.onChange,
        }}
        expandedRowRender={(record, index, indent, expaned) =>
          expaned ? <ExpandedRowRender record={record} /> : null
        }
        onRow={this.onRow as any}
        columns={this.columns as any}
        dataSource={filteredConnectors || connectors}
      />
    ) : (
      <Empty
        description={
          <span>
            <span>{this.props.i18n("NoConnectorsFound")}</span>
          </span>
        }
      />
    );
  }
}

export const AssignConnectorsDragDropContext = FormDragDropContext(AssignConnectors);
