import type { FromServerWsMessage } from "@prodoctivity/types";
import { useEffect } from "react";
import { useServices } from "./useServices";

export function useSubscribeWS(
  type: FromServerWsMessage["type"],
  handler: (msg: FromServerWsMessage) => void
) {
  const { messageEventHandlers } = useServices();
  const handlerList = messageEventHandlers[type];

  useEffect(() => {
    console.log("Subscribing ...");
    const myHandler = handler;
    handlerList.push(myHandler);

    return () => {
      console.log("Unsubscribing");
      const idx = handlerList.indexOf(myHandler);
      if (idx >= 0) {
        handlerList.splice(idx, 1);
      }
      console.log(handlerList.length);
    };
  }, [handler, handlerList]);
}
