import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const PaginationLeftIconSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
}) => {
  const { colors } = useColors();
  return (
    <svg
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 25L17 20L22 15"
        stroke={calculateColor(color ? color : colors.neutral900)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
