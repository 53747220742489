import { ReactNode, useCallback } from "react";

import { Link } from "@prodoctivity/design-system";
import { NavLink } from "react-router-dom";
import { useOrganizationLink } from "../hooks/useOrganizationNavigate";
import { useServices } from "../hooks/useServices";

type Props = {
  to: string;
  accessibilityLabel?: string;
  children?: ReactNode;
  onClick?(): void;
};
export function OrganizationLink({ to, accessibilityLabel, children, onClick }: Props) {
  const organizationLink = useOrganizationLink();

  const clickHandler = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Link
      accessibilityLabel={accessibilityLabel}
      tapStyle="compress"
      underline="none"
      onClick={clickHandler}
      href={organizationLink(to)}
    >
      {children}
    </Link>
  );
}

const refHandler = (node: HTMLAnchorElement | null) => {
  if (node) {
    node.style.textDecoration = "none";
  }
};

type OrganizationNavLinkProps = {
  to: string;
  children?: ReactNode;
  onClick?(): void;
  state?: unknown;
};

export function OrganizationNavLink({ to, children, onClick, state }: OrganizationNavLinkProps) {
  const { user } = useServices();
  const clickHandler = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <NavLink
      ref={refHandler}
      to={`/${user?.organizationId}${to}`}
      onClick={clickHandler}
      state={state}
    >
      {children}
    </NavLink>
  );
}
