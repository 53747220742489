import { Box, Skeleton, Switch, Text, TextField, useColors } from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction } from "react";

import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { RoleManagementState } from "../hooks";

type Props = {
  formState: RoleManagementState;
  isLoading: boolean;
  setFormState: Dispatch<SetStateAction<RoleManagementState>>;
};

export const MaxRoleNameLength = 70;

export const PropertiesTab: FunctionComponent<Props> = ({ formState, isLoading, setFormState }) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  return (
    <Skeleton height={400} show={isLoading}>
      <Box display="flex" direction="column" flex="grow" gap={4}>
        <Box display="flex" direction="column" flex="shrink" padding={4} gap={1}>
          <Box
            display="flex"
            direction="column"
            flex="shrink"
            gap={2}
            justifyContent="start"
            paddingTop={2}
          >
            <Text>{resources.name}</Text>
          </Box>
          <Box display="flex" direction="column" flex="grow">
            <Box width="100%" alignItems="center">
              <TextField
                placeholder={resources.role}
                id="name"
                value={formState.name}
                disabled={isLoading}
                maxLength={{
                  characterCount: MaxRoleNameLength,
                  errorAccessibilityLabel: resources.maximumCharactersAllowed,
                }}
                onChange={(e) => setFormState((prev) => ({ ...prev, name: e.value }))}
                errorMessage={formState.errors?.["name"]}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="shrink" width={"100%"}>
          <Box display="flex" direction="row" flex="grow" padding={5} gap={4}>
            <Box display="flex" direction="column" flex="grow" />
            <Box display="flex" direction="column" flex="shrink">
              <Text color={colors.neutral900}>
                {resources.userManagementAndRoles.onlySeeOwnedDocs}
              </Text>
            </Box>
            <Box display="flex" direction="column" flex="shrink" alignItems="center">
              <Switch
                disabled={isLoading}
                switched={formState.allowOnlyOwnedDocuments}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, allowOnlyOwnedDocuments: e.value }))
                }
                id="allowOnlyOwnedDocuments"
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" direction="column" flex="grow" />
      </Box>
    </Skeleton>
  );
};
