import { FunctionComponent } from "react";
import { useColors } from "../../ColorSchemeProvider";
import { calculateColor, ColorConfig } from "../../../utils";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig;
  onClick?: () => void;
};

export const DesignSystemRefreshIconSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 40,
  height = 40,
  color,
  onClick,
}) => {
  const { colors } = useColors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName || undefined}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      onClick={onClick}
    >
      <path
        d="M29.65 9.4L27.25 11.85C25.3 10.1 22.75 9 20 9C15.25 9 11.1 12.05 9.65 16.55C9.4 17.4 9.85 18.3 10.7 18.55C11.55 18.8 12.45 18.35 12.7 17.5C13.7 14.35 16.65 12.2 20 12.2C21.85 12.2 23.65 12.95 25 14.1L22.75 16.3C21.95 17.15 22.5 18.6 23.7 18.6H31.05C31.55 18.6 32 18.2 32 17.65V10.4C32 9.15 30.5 8.55 29.65 9.4ZM29.25 21.9C28.4 21.65 27.5 22.1 27.25 22.95C26.25 26.1 23.3 28.2 20 28.2C18.1 28.2 16.35 27.5 14.95 26.35L17.2 24.1C18 23.3 17.45 21.85 16.25 21.8H8.9C8.4 21.85 8 22.25 8 22.8V30.05C8 31.3 9.45 31.9 10.3 31.05L12.7 28.6C14.65 30.35 17.2 31.4 19.95 31.4C24.65 31.4 28.8 28.4 30.25 23.9C30.55 23.05 30.1 22.15 29.25 21.9Z"
        fill={calculateColor(color || colors.neutral900)}
      />
    </svg>
  );
};
