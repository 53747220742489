import React, { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
};
export const CalderndarTimerSvg: FunctionComponent<Props> = ({ width = 226, height = 170 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 226 170"
      fill="none"
    >
      <path
        d="M18.7654 18.8242C14.8613 18.8242 11.6953 21.9933 11.6953 25.9012V162.077C11.6953 165.984 14.8613 169.154 18.7654 169.154H173.248C177.152 169.154 180.318 165.984 180.318 162.077V25.9012C180.318 21.9933 177.152 18.8242 173.248 18.8242H18.7654Z"
        fill="#B5B5B5"
      />
      <path
        d="M168.623 40.8867V163.015C168.623 166.409 165.874 169.15 162.494 169.15H6.12883C2.73819 169.15 0 166.399 0 163.015V40.8867H168.634H168.623Z"
        fill="#D1D2D3"
      />
      <path
        d="M168.623 25.4515V40.8902H0V25.4515C0 21.7899 2.9628 18.8242 6.62085 18.8242H162.013C165.671 18.8242 168.634 21.7899 168.634 25.4515H168.623Z"
        fill="#273166"
      />
      <path d="M29.5651 45.9727H7.08203V68.4776H29.5651V45.9727Z" fill="#FBDDDE" />
      <path d="M55.6393 45.9727H33.1562V68.4776H55.6393V45.9727Z" fill="#FBDDDE" />
      <path d="M81.7214 45.9727H59.2383V68.4776H81.7214V45.9727Z" fill="#FBDDDE" />
      <path d="M107.796 45.9727H85.3125V68.4776H107.796V45.9727Z" fill="#FBDDDE" />
      <path d="M133.885 45.9727H111.402V68.4776H133.885V45.9727Z" fill="#FBDDDE" />
      <path d="M159.96 45.9727H137.477V68.4776H159.96V45.9727Z" fill="#FBDDDE" />
      <path d="M29.2878 73.5625H6.80469V96.0675H29.2878V73.5625Z" fill="#FBDDDE" />
      <path d="M55.3659 73.5625H32.8828V96.0675H55.3659V73.5625Z" fill="#FBDDDE" />
      <path d="M81.4401 73.5625H58.957V96.0675H81.4401V73.5625Z" fill="#FBDDDE" />
      <path d="M107.518 73.5625H85.0352V96.0675H107.518V73.5625Z" fill="#FBDDDE" />
      <path d="M133.604 73.5625H111.121V96.0675H133.604V73.5625Z" fill="#FBDDDE" />
      <path d="M159.686 73.5625H137.203V96.0675H159.686V73.5625Z" fill="white" />
      <path d="M29.0104 101.145H6.52734V123.65H29.0104V101.145Z" fill="white" />
      <path d="M55.0846 101.145H32.6016V123.65H55.0846V101.145Z" fill="white" />
      <path d="M81.1667 101.145H58.6836V123.65H81.1667V101.145Z" fill="white" />
      <path d="M107.241 101.145H84.7578V123.65H107.241V101.145Z" fill="white" />
      <path d="M133.315 101.145H110.832V123.65H133.315V101.145Z" fill="white" />
      <path d="M159.405 101.145H136.922V123.65H159.405V101.145Z" fill="white" />
      <path d="M30.0807 128.328H7.59766V150.833H30.0807V128.328Z" fill="white" />
      <path d="M56.1549 128.328H33.6719V150.833H56.1549V128.328Z" fill="white" />
      <path d="M82.2331 128.328H59.75V150.833H82.2331V128.328Z" fill="white" />
      <path d="M108.311 128.328H85.8281V150.833H108.311V128.328Z" fill="white" />
      <path d="M134.385 128.328H111.902V150.833H134.385V128.328Z" fill="white" />
      <path
        d="M35.0321 36.0592C38.0507 36.0592 40.4978 33.6097 40.4978 30.5882C40.4978 27.5666 38.0507 25.1172 35.0321 25.1172C32.0135 25.1172 29.5664 27.5666 29.5664 30.5882C29.5664 33.6097 32.0135 36.0592 35.0321 36.0592Z"
        fill="white"
      />
      <path
        d="M123.938 36.0592C126.957 36.0592 129.404 33.6097 129.404 30.5882C129.404 27.5666 126.957 25.1172 123.938 25.1172C120.92 25.1172 118.473 27.5666 118.473 30.5882C118.473 33.6097 120.92 36.0592 123.938 36.0592Z"
        fill="white"
      />
      <path
        d="M36.403 31.8321C30.5094 31.8321 25.0331 28.3954 22.466 23.085C21.4392 20.9758 20.9258 18.706 20.9258 16.3399C20.9258 7.79615 27.8675 0.847656 36.403 0.847656C44.9384 0.847656 51.8801 7.79615 51.8801 16.3399C51.8801 17.2928 51.7946 18.2456 51.6234 19.1771L47.7515 18.4598C47.8798 17.7639 47.944 17.0572 47.944 16.3399C47.944 9.96956 42.7671 4.78763 36.403 4.78763C30.0388 4.78763 24.8619 9.96956 24.8619 16.3399C24.8619 18.1065 25.247 19.7981 26.0064 21.3719C27.921 25.3333 32.0069 27.9029 36.403 27.9029V31.8428V31.8321Z"
        fill="#D1D2D3"
      />
      <path
        d="M124.911 31.8321C119.017 31.8321 113.541 28.3954 110.974 23.085C109.947 20.9758 109.434 18.706 109.434 16.3399C109.434 7.79615 116.375 0.847656 124.911 0.847656C133.446 0.847656 140.388 7.79615 140.388 16.3399C140.388 17.2928 140.302 18.2456 140.131 19.1771L136.259 18.4598C136.388 17.7639 136.452 17.0465 136.452 16.3399C136.452 9.96956 131.275 4.78763 124.911 4.78763C118.547 4.78763 113.37 9.96956 113.37 16.3399C113.37 18.1065 113.755 19.7981 114.514 21.3719C116.429 25.3333 120.515 27.9029 124.911 27.9029V31.8428V31.8321Z"
        fill="#D1D2D3"
      />
      <path
        d="M18.3235 64.187C21.9151 64.187 24.8267 61.2726 24.8267 57.6775C24.8267 54.0824 21.9151 51.168 18.3235 51.168C14.7319 51.168 11.8203 54.0824 11.8203 57.6775C11.8203 61.2726 14.7319 64.187 18.3235 64.187Z"
        fill="#C51E1F"
      />
      <path
        d="M130.152 79.3146L128.141 77.3008L115.132 90.3222L117.144 92.336L130.152 79.3146Z"
        fill="#C51E1F"
      />
      <path
        d="M128.137 92.3302L130.148 90.3164L117.14 77.295L115.128 79.3087L128.137 92.3302Z"
        fill="#C51E1F"
      />
      <path d="M27.2539 57.6758H29.3931" stroke="#C51E1F" strokeMiterlimit="10" />
      <path d="M33.7266 57.6758H144.409" stroke="#C51E1F" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M146.582 57.6758H148.721" stroke="#C51E1F" strokeMiterlimit="10" />
      <path d="M18.3242 84.8164H20.4634" stroke="#C51E1F" strokeMiterlimit="10" />
      <path d="M24.7656 84.8164H117.179" stroke="#C51E1F" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M119.328 84.8164H121.467" stroke="#C51E1F" strokeMiterlimit="10" />
      <path
        d="M182.146 125.622C200.459 125.622 215.304 110.763 215.304 92.4322C215.304 74.1019 200.459 59.2422 182.146 59.2422C163.834 59.2422 148.988 74.1019 148.988 92.4322C148.988 110.763 163.834 125.622 182.146 125.622Z"
        fill="#C51E1F"
      />
      <path
        d="M182.147 118.129C196.324 118.129 207.818 106.625 207.818 92.4338C207.818 78.2425 196.324 66.7383 182.147 66.7383C167.97 66.7383 156.477 78.2425 156.477 92.4338C156.477 106.625 167.97 118.129 182.147 118.129Z"
        fill="white"
      />
      <path
        d="M182.145 75.3047V93.5057H197.119"
        stroke="#C51E1F"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M226 12.6691V47.6395C226 49.1933 224.743 50.4516 223.191 50.4516H211.21C211.03 50.4516 210.855 50.5173 210.724 50.6431L205.777 55.2168L206.488 51.2996C206.564 50.8565 206.225 50.4516 205.777 50.4516H190.479C189.063 50.4516 187.894 49.4012 187.697 48.0389V18.3042H187.664V12.6691C187.664 11.1154 188.921 9.85156 190.479 9.85156H223.185C224.738 9.85156 226 11.1154 226 12.6691Z"
        fill="#C51E1F"
      />
      <path
        d="M222.621 12.6691V47.6395C222.621 49.1933 221.359 50.4516 219.812 50.4516H211.209C211.029 50.4516 210.854 50.5173 210.722 50.6431L205.776 55.2168L203.333 57.4709C203.245 57.5529 203.153 57.6022 203.054 57.6295C202.907 57.6842 202.759 57.6788 202.622 57.6295C202.289 57.531 202.032 57.2083 202.109 56.7979L203.103 51.2996C203.185 50.8565 202.846 50.4516 202.398 50.4516H187.1C185.684 50.4516 184.515 49.4012 184.318 48.0389V18.3042H184.285V12.6691C184.285 11.1154 185.542 9.85156 187.1 9.85156H219.806C221.359 9.85156 222.621 11.1154 222.621 12.6691Z"
        fill="#FCE0E4"
      />
      <path
        d="M214.587 50.4531C214.407 50.4531 214.232 50.5188 214.101 50.6446L206.711 57.4724C206.624 57.5544 206.531 57.6037 206.432 57.631H203.055C203.153 57.6037 203.246 57.5544 203.333 57.4724L205.777 55.2183L210.723 50.6446C210.854 50.5188 211.029 50.4531 211.209 50.4531H214.587Z"
        fill="#C51E1F"
      />
    </svg>
  );
};
