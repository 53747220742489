import {
  Box,
  Image,
  TapArea,
  Text,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { ReactNode, useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { BarMenu } from "../../components/menu";
import { useAppTranslation } from "../../hooks/useAppTranslation";

const Card = ({ title, children }: { title: string; children?: ReactNode }) => {
  const { colors } = useColors();
  return (
    <Box>
      <Box>
        <Text size="400" weight="bold" inline={true}>
          {title}
        </Text>
      </Box>
      <Box height="1.25rem" />
      <Box height={2} color={colors.black900} />
      <Box height="1.5rem" />
      {children}
    </Box>
  );
};

function ApiDocumentationPage() {
  const { colors } = useColors();
  const { resources } = useAppTranslation();
  const { breakpoint } = useDesignBreakpoint();
  const navigate = useNavigate();

  const signUp = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  const boxProps = useMemo(() => {
    switch (breakpoint) {
      case "small":
        return {
          height: "70vh",
          display: "flex",
          direction: "column",
        } as const;
      default: {
        return { height: "100vh", direction: "column", display: "flex" } as const;
      }
    }
  }, [breakpoint]);
  return (
    <Box>
      <Box display="flex" justifyContent="around" color={colors.primary200}>
        <BarMenu />
      </Box>
      <Box {...boxProps}>
        <Image
          alt="ProDoctivity"
          color="transparent"
          naturalHeight={1080}
          naturalWidth={1920}
          src="/img/third_section.png"
          role="presentation"
          fit="cover"
        >
          <Box
            display="flex"
            direction="column"
            alignItems="center"
            justifyContent="center"
            margin={12}
          >
            <Box display="flex" direction="column">
              <Text size="500" weight="bold">
                {resources.apiPage.wantToTest}
              </Text>
            </Box>
            <Text>{resources.apiPage.tryOurApi}</Text>
          </Box>

          <Box margin={8} />
          <Box display="flex" justifyContent="center" gap={12}>
            <Card title={resources.apiPage.process}>
              <Text>{resources.apiPage.createAccount}</Text>
              <Text>{resources.apiPage.createUploadTemplate}</Text>
              <Text>{resources.apiPage.generateDocuments}</Text>
            </Card>
            <Card title={resources.apiPage.includes}>
              <Text>{resources.apiPage.tryDays}</Text>
              <Text>{resources.apiPage.generateUpTo}</Text>
              <Text>{resources.apiPage.uploadesTemplate}</Text>
            </Card>
            <Card title={resources.apiPage.moreInformations}>
              <Text>{resources.phone} : (809) 482-6287</Text>
              <Text>{resources.email} : info@prodoctivity.com</Text>
            </Card>
          </Box>
          <Box height="2.188rem" />
          <Box display="flex" justifyContent="center">
            <Box display="flex" padding={3} color={colors.primary} rounding={2} fit={true}>
              <TapArea>
                <Box display="flex" justifyContent="center" onClickCapture={signUp}>
                  <Text size="300" weight="bold" color={colors.white}>
                    {resources.apiPage.startNow}
                  </Text>
                </Box>
              </TapArea>
            </Box>
          </Box>
        </Image>
      </Box>
    </Box>
  );
}

export default ApiDocumentationPage;
