import { ColorConfig, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";
import { calculateColor } from "@prodoctivity/design-system";

type Props = {
  customClassName?: string;
  width?: number;
  height?: number;
  color?: ColorConfig | undefined;
};

export const DataDictionaryMenuIcon: FunctionComponent<Props> = ({ color }) => {
  const { colors } = useColors();
  return (
    <svg width="38" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1338_45204)">
        <path
          d="M8 25.2857V7.28571C8 6.57321 8.57321 6 9.28571 6H26.8571C29.6964 6 32 8.30357 32 11.1429V28.2857C32 31.125 29.6964 33.4286 26.8571 33.4286H9.28571C8.57321 33.4286 8 32.8554 8 32.1429V31.2857C8 30.8839 8.1875 30.5196 8.47679 30.2839C8.70179 29.4589 8.70179 27.1071 8.47679 26.2821C8.1875 26.0518 8 25.6875 8 25.2857ZM25.1429 13.1786C25.1429 13.0018 24.9982 12.8571 24.8214 12.8571H13.4643C13.2875 12.8571 13.1429 13.0018 13.1429 13.1786V14.25C13.1429 14.4268 13.2875 14.5714 13.4643 14.5714H24.8214C24.9982 14.5714 25.1429 14.4268 25.1429 14.25V13.1786ZM25.1429 16.6071C25.1429 16.4304 24.9982 16.2857 24.8214 16.2857H13.4643C13.2875 16.2857 13.1429 16.4304 13.1429 16.6071V17.6786C13.1429 17.8554 13.2875 18 13.4643 18H24.8214C24.9982 18 25.1429 17.8554 25.1429 17.6786V16.6071ZM11.5679 30H26.8571C27.8054 30 28.5714 29.2339 28.5714 28.2857C28.5714 27.3429 27.8 26.5714 26.8571 26.5714H11.5679C11.6696 27.4875 11.6696 29.0839 11.5679 30Z"
          fill={calculateColor(color ? color : colors.neutral900)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1338_45204">
          <rect width="24" height="27.4286" fill="white" transform="matrix(-1 0 0 1 32 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};
