import {
  Box,
  Grid,
  Layer,
  OverlayPanel,
  Text,
  modalZIndex,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { range } from "@prodoctivity/shared";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { LeftSide } from "../../components/Activities/ActivityMenuSearch";
import { BreadCrumb } from "../../components/BreadCrumb";
import { DocumentCard } from "../../components/DocumentCard";
import { Pagination } from "../../components/Layout/Pagination";
import { Topic } from "../../components/Layout/Topic";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { BrowseDocumentLabelsFilter, organizationLinkTemplates } from "../../link-templates";
import { TemplateCardSkeleton } from "../../loading";
import { DocumentLabelsFilters } from "./Components/DocumentLabelsFilters";
import { DocumentLabelsTable } from "./Components/DocumentLabelsTable";
import { ProfileDocumentLabelsList } from "./Components/ProfileDocumentLabelsList/ProfileDocumentsLabelsList";
import { useDocumentLabelsList } from "./hook";

const cardWidth = "244px";

type Props = {
  hideTitle: boolean | undefined;
  filter: BrowseDocumentLabelsFilter;
};

export const DocumentLabelsList: FunctionComponent<Props> = ({ hideTitle, filter }: Props) => {
  const { colors } = useColors();

  const anchorRefItems = useRef<Array<HTMLDivElement | null>>([]);
  const [showComponent, setShowComponent] = useState(false);
  const alterShowComponentState = useCallback(
    (value: boolean) => setShowComponent(value),
    [setShowComponent]
  );
  const { breakpoint } = useDesignBreakpoint();
  const isBreakpointHd = breakpoint === "hd";
  const isSmallOrMediumBreakpoint = breakpoint === "small" || breakpoint === "medium";
  const { resources } = useAppTranslation();
  const templateCols = useMemo(() => {
    return [`repeat(auto-fill, ${cardWidth})`];
  }, []);

  const {
    selectedLabels,
    profileDocumentLabelsList,
    documentTypeIsLoading,
    documentsData,
    onSelectDocumentType,
    selectedDocumentTypes,
    currentPage,
    previousPage,
    nextPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    rowsPerPage,
    totalRowCount,
    pageLengthOptions,
    documentTypeHandleClick,
    documentTypeComboBoxInputValue,
    handleSetDocumentTypeComboBoxInputValue,
    clearAll,
    handleMoveTo,
    handleFilterChange,
    handleSelectedLabels,
    handleSelectSingle,
    isLoadingProfileDocumentLabels,
    setRowsPerPage,
    changeSort,
    refetch,
    handleUpdateProfileLabels,
    handleDeleteLabel,
    displayDocumentsAsCards,
    showOnCards,
    documentTypeList,
    handleComboBoxClear,
    profileDocumentLabels,
  } = useDocumentLabelsList(filter);

  const paginationProps = useMemo(() => {
    return {
      rowsPerPage,
      currentPage,
      nextPage,
      previousPage,
      documentsData,
      setRowsPerPage,
      isNextButtonDisabled,
      isPreviousButtonDisabled,
      pageLengthOptions,
      totalRowCount,
    };
  }, [
    rowsPerPage,
    currentPage,
    nextPage,
    previousPage,
    documentsData,
    setRowsPerPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    pageLengthOptions,
    totalRowCount,
  ]);

  if (documentTypeIsLoading) {
    return (
      <Box>
        {!hideTitle && (
          <>
            <Box paddingX={isSmallOrMediumBreakpoint ? undefined : 1}>
              <Topic>{resources.myLists}</Topic>
            </Box>
            <Box margin={isSmallOrMediumBreakpoint ? undefined : 1} />
          </>
        )}

        <Box width={"100%"} display="flex" flex="grow" direction="column">
          <Grid width="100%" gridTemplateColumns={templateCols} gap={2}>
            {range(8).map((_, i) => (
              <TemplateCardSkeleton key={i} />
            ))}
          </Grid>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flex="grow" direction="column" width={"100%"}>
      <Box color={colors.white}>
        <BreadCrumb
          entries={[
            { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
            { type: "text", name: resources.myLists },
          ]}
        />
      </Box>
      <Box display="flex" direction="row" flex="grow">
        <Box>
          {breakpoint === "hd" ? (
            <LeftSide>
              <ProfileDocumentLabelsList
                profileDocumentLabelsList={profileDocumentLabelsList}
                selectedLabels={selectedLabels}
                handleSelectedLabels={handleSelectedLabels}
                isLoadingProfileDocumentLabels={isLoadingProfileDocumentLabels}
                handleUpdateProfileLabels={handleUpdateProfileLabels}
                handleDeleteLabel={handleDeleteLabel}
                isPopoverWindows={false}
                profileDocumentLists={profileDocumentLabels}
                onSelectSingle={handleSelectSingle}
                isOnlyShowingNotSelected={false}
                isWidget={false}
              />
            </LeftSide>
          ) : (
            showComponent && (
              <Layer zIndex={modalZIndex}>
                <OverlayPanel
                  accessibilityDismissButtonLabel={resources.close}
                  accessibilityLabel={resources.myLists}
                  heading=""
                  onDismiss={() => setShowComponent(false)}
                  size="lg"
                >
                  <ProfileDocumentLabelsList
                    profileDocumentLabelsList={profileDocumentLabelsList}
                    selectedLabels={selectedLabels}
                    handleSelectedLabels={handleSelectedLabels}
                    isLoadingProfileDocumentLabels={isLoadingProfileDocumentLabels}
                    handleUpdateProfileLabels={handleUpdateProfileLabels}
                    handleDeleteLabel={handleDeleteLabel}
                    isPopoverWindows={false}
                    profileDocumentLists={profileDocumentLabels}
                    onSelectSingle={handleSelectSingle}
                    isOnlyShowingNotSelected={false}
                    isWidget={false}
                  />
                </OverlayPanel>
              </Layer>
            )
          )}
        </Box>
        <Box paddingX={isSmallOrMediumBreakpoint ? 2 : 12} paddingY={5} flex="grow">
          <Box width={"100%"}>
            <DocumentLabelsFilters
              clearAll={clearAll}
              documentTypeComboBoxInputValue={documentTypeComboBoxInputValue}
              documentTypeList={documentTypeList}
              handleComboBoxClear={handleComboBoxClear}
              handleFilterChange={handleFilterChange}
              handleSetDocumentTypeComboBoxInputValue={handleSetDocumentTypeComboBoxInputValue}
              onSelectDocumentType={onSelectDocumentType}
              selectedDocumentTypes={selectedDocumentTypes}
              documentTypeHandleClick={documentTypeHandleClick}
              filter={filter}
              isBreakpointHd={isBreakpointHd}
              isSmallOrMediumBreakpoint={isSmallOrMediumBreakpoint}
              refetch={refetch}
              showOnCards={showOnCards}
              alterShowComponentState={alterShowComponentState}
              displayDocumentsAsCards={displayDocumentsAsCards}
              paginationProps={paginationProps}
            />

            {documentsData.length === 0 && (
              <Box paddingTop={3}>
                <Box
                  display="flex"
                  flex="grow"
                  justifyContent="center"
                  color={colors.neutral200}
                  borderStyle="sm"
                  rounding={2}
                  padding={5}
                >
                  <Text>{resources.noResultsFound}</Text>
                </Box>
              </Box>
            )}
            {documentsData.length > 0 && (
              <Box color={displayDocumentsAsCards ? colors.white : undefined} paddingY={2}>
                <Box>
                  {displayDocumentsAsCards && breakpoint !== "small" ? (
                    <Box
                      borderStyle="lg"
                      marginBottom={12}
                      marginTop={2}
                      paddingY={3}
                      paddingX={isSmallOrMediumBreakpoint ? 2 : 4}
                      borderRadius={4}
                    >
                      <DocumentLabelsTable
                        anchorRefItems={anchorRefItems}
                        changeSort={changeSort}
                        documentsData={documentsData}
                        filter={filter}
                        handleMoveTo={handleMoveTo}
                        isSmallOrMediumBreakpoint={isSmallOrMediumBreakpoint}
                        refetch={refetch}
                      />
                    </Box>
                  ) : (
                    <Grid
                      gridTemplateColumns={["repeat(auto-fill, 400px)"]}
                      gap={2}
                      justifyContent="center"
                    >
                      {(documentsData || []).map((document, idx) => (
                        <Box color={colors.neutral200} key={idx}>
                          <DocumentCard document={document} onChange={handleMoveTo} />
                        </Box>
                      ))}
                    </Grid>
                  )}
                </Box>
                <Pagination<typeof rowsPerPage>
                  id="Browse"
                  rowsLabel={`${resources.documents}:`}
                  currentPage={currentPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  pageLength={documentsData?.length || 0}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  isNextButtonDisabled={isNextButtonDisabled}
                  isPreviousButtonDisabled={isPreviousButtonDisabled}
                  pageLengthOptions={pageLengthOptions}
                  totalRowCount={totalRowCount}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
