import {
  Box,
  Heading,
  Skeleton,
  useColors,
  useDesignBreakpoint,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback } from "react";

import { useParams } from "react-router-dom";

import { BreadCrumb } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { Activity } from "../../components/RecentActivities/Activity";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { organizationLinkTemplates } from "../../link-templates";

const ActivityDetailPage: FunctionComponent = () => {
  const { colors } = useColors();
  const { activityId } = useParams();

  const { resources } = useAppTranslation();
  const { getActionEntry } = useServices();
  const getAction = useCallback(() => {
    return getActionEntry(activityId || "");
  }, [activityId, getActionEntry]);
  const { data, isLoading } = useOrganizationQuery(`action-entry/${activityId}`, getAction, {
    staleTime: 60 * 1000,
  });
  const { breakpoint } = useDesignBreakpoint();
  const breakPoint = breakpoint === "small";
  return (
    <Page>
      <Box marginTop={breakPoint ? 9 : undefined}>
        <BreadCrumb
          entries={
            breakPoint
              ? [
                  {
                    type: "text",
                    name: activityId ? `${resources.activities.activity} # ${activityId}` : "",
                  },
                ]
              : [
                  { type: "url", name: resources.home, url: organizationLinkTemplates.home() },
                  {
                    type: "url",
                    name: resources.activities.activities,
                    url: organizationLinkTemplates.activityList(),
                  },
                  {
                    type: "text",
                    name: activityId ? `${resources.activities.activity} # ${activityId}` : "",
                  },
                ]
          }
        />
        <Box margin={12} />
        <Box paddingX={6} display="flex" direction="row" justifyContent="center">
          {isLoading ? (
            <Skeleton show={true} />
          ) : !data ? (
            <Heading color={colors.black600}>{resources.activities.notFound}</Heading>
          ) : (
            <Box marginStart={12} marginEnd={12}>
              <Box
                maxWidth={800}
                display="flex"
                direction="column"
                justifyContent="center"
                flex="grow"
                color={colors.white}
                marginTop={2}
                padding={breakPoint ? 2 : 5}
                borderRadius={4}
                borderStyle="lg"
              >
                <Activity entry={data.entry} fullScreen={true} fullWidth={true} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  );
};

export default ActivityDetailPage;
