export var RightSidePanelMode;
(function (RightSidePanelMode) {
  RightSidePanelMode["Condition"] = "Condition";
  RightSidePanelMode["Properties"] = "Properties";
  RightSidePanelMode["RepetitiveRecord"] = "RepetitiveRecord";
  RightSidePanelMode["Replacements"] = "Replacements";
  RightSidePanelMode["Multiple"] = "Multiple";
  RightSidePanelMode["Image"] = "Image";
  RightSidePanelMode["Chart"] = "Chart";
  RightSidePanelMode["Barcode"] = "Barcode";
})(RightSidePanelMode || (RightSidePanelMode = {}));
export var PluralCondition;
(function (PluralCondition) {
  PluralCondition["AmountOf"] = "AmountOf";
  PluralCondition["ValueOf"] = "ValueOf";
})(PluralCondition || (PluralCondition = {}));
export var ReplacementType;
(function (ReplacementType) {
  ReplacementType["GenderNumber"] = "GenderNumber";
  ReplacementType["GenderSingular"] = "GenderSingular";
  ReplacementType["Simple"] = "Simple";
})(ReplacementType || (ReplacementType = {}));
/// <summary>
/// Sort type for group instances.
/// </summary>
export var InstanceSortType;
(function (InstanceSortType) {
  /// <summary>
  /// Leave the collection as is.
  /// </summary>
  InstanceSortType["NoSort"] = "NoSort";
  /// <summary>
  /// Sort the collection ascending.
  /// </summary>
  InstanceSortType["Ascending"] = "Ascending";
  /// <summary>
  /// Sort the collection descending.
  /// </summary>
  InstanceSortType["Descending"] = "Descending";
})(InstanceSortType || (InstanceSortType = {}));
export var BarcodeAnchorPosition;
(function (BarcodeAnchorPosition) {
  BarcodeAnchorPosition["UpperLeft"] = "UpperLeft";
  BarcodeAnchorPosition["UpperCentral"] = "UpperCentral";
  BarcodeAnchorPosition["UpperRight"] = "UpperRight";
  BarcodeAnchorPosition["MiddleLeft"] = "MiddleLeft";
  BarcodeAnchorPosition["MiddleRight"] = "MiddleRight";
  BarcodeAnchorPosition["BottomLeft"] = "BottomLeft";
  BarcodeAnchorPosition["BottomCentral"] = "BottomCentral";
  BarcodeAnchorPosition["BottomRight"] = "BottomRight";
})(BarcodeAnchorPosition || (BarcodeAnchorPosition = {}));
export var BarcodeSize;
(function (BarcodeSize) {
  BarcodeSize["Normal"] = "Normal";
  BarcodeSize["Medium"] = "Medium";
  BarcodeSize["Large"] = "Large";
  BarcodeSize["VeryLarge"] = "VeryLarge";
  BarcodeSize["Personalized"] = "Personalized";
})(BarcodeSize || (BarcodeSize = {}));
// ONECODE DISABLED
// until it can be generated
// without exploding in the back-end
export var BarcodeType;
(function (BarcodeType) {
  BarcodeType["QR"] = "QR";
  BarcodeType["CODE128"] = "CODE128";
  BarcodeType["CODE39"] = "CODE39";
  BarcodeType["CODE39EXTENDED"] = "CODE39EXTENDED";
  //ONECODE = "ONECODE",
  BarcodeType["EAN13"] = "EAN13";
  BarcodeType["ISBN"] = "ISBN";
})(BarcodeType || (BarcodeType = {}));
export var BarcodeSourceType;
(function (BarcodeSourceType) {
  BarcodeSourceType["FixedValue"] = "FixedValue";
  BarcodeSourceType["FieldValue"] = "FieldValue";
  BarcodeSourceType["Personalized"] = "Personalized";
})(BarcodeSourceType || (BarcodeSourceType = {}));
export var WorkflowType;
(function (WorkflowType) {
  WorkflowType["DocumentGeneration"] = "DocumentGeneration";
  WorkflowType["TemplateCreation"] = "TemplateCreation";
})(WorkflowType || (WorkflowType = {}));
export var DataType;
(function (DataType) {
  DataType["Alphanumeric"] = "Alphanumeric";
  DataType["Numeric"] = "Numeric";
  DataType["Date"] = "Date";
  DataType["DateTime"] = "DateTime";
  DataType["Logical"] = "Logical";
  DataType["Currency"] = "Currency";
  DataType["Time"] = "Time";
  DataType["Image"] = "Image";
})(DataType || (DataType = {}));
export var TagType;
(function (TagType) {
  TagType["Basic"] = "Basic";
  TagType["CalculatedField"] = "CalculatedField";
  TagType["Condition"] = "Condition";
  TagType["Field"] = "Field";
  TagType["RepetitiveRecord"] = "RepetitiveRecord";
  TagType["Replacement"] = "Replacement";
  TagType["Image"] = "Image";
  TagType["Chart"] = "Chart";
  TagType["Barcode"] = "Barcode";
  TagType["ContentFragment"] = "ContentFragment";
})(TagType || (TagType = {}));
export var UsableFieldType;
(function (UsableFieldType) {
  UsableFieldType[(UsableFieldType["Field"] = 0)] = "Field";
  UsableFieldType[(UsableFieldType["Record"] = 1)] = "Record";
})(UsableFieldType || (UsableFieldType = {}));
export var CalculatedExpressionType;
(function (CalculatedExpressionType) {
  /**
   * Fixed value. eg: 25, "Hello", "02/01/2001"
   */
  CalculatedExpressionType["FixedValue"] = "FixedValue";
  /**
   * A value that comes from the context.
   */
  CalculatedExpressionType["ContextValue"] = "ContextValue";
  /**
   * An expression that represents a composite. Such as "The average of"
   */
  CalculatedExpressionType["Composite"] = "Composite";
})(CalculatedExpressionType || (CalculatedExpressionType = {}));
export var CalculatedExpressionOperator;
(function (CalculatedExpressionOperator) {
  /**
   * No operation. Comonly used with single value summaries
   */ CalculatedExpressionOperator["None"] = "None";
  /**
   * Addition. Adds a value with another value.
   */
  CalculatedExpressionOperator["Add"] = "Add";
  /**
   * Subtraction. Subtracts a value from another value.
   */
  CalculatedExpressionOperator["Subtract"] = "Subtract";
  /**
   * Multiplication. The product of two values.
   */
  CalculatedExpressionOperator["Multiply"] = "Multiply";
  /**
   * Division. Divide a value by another value.
   */
  CalculatedExpressionOperator["Divide"] = "Divide";
  /**
   * Modulus. The modulus result of an integer division operation. Resembles arithmetic congruence modulus operation.
   */
  CalculatedExpressionOperator["Modulus"] = "Modulus";
})(CalculatedExpressionOperator || (CalculatedExpressionOperator = {}));
export var CalculatedExpressionSummary;
(function (CalculatedExpressionSummary) {
  /**
   * None. Just yields the first value
   */
  CalculatedExpressionSummary["None"] = "None";
  /**
   * Sum. Sums all the values and yields that result
   */
  CalculatedExpressionSummary["Sum"] = "Sum";
  /**
   * Average. Computes the average of all values.
   */
  CalculatedExpressionSummary["Average"] = "Average";
  /**
   * Count. Counts the amount of values.
   */
  CalculatedExpressionSummary["Count"] = "Count";
})(CalculatedExpressionSummary || (CalculatedExpressionSummary = {}));
export var ListType;
(function (ListType) {
  ListType["None"] = "";
  ListType["Gender"] = "Gender";
  ListType["CurrencyType"] = "CurrencyType";
})(ListType || (ListType = {}));
export var FluencyInputType;
(function (FluencyInputType) {
  FluencyInputType["Default"] = "Default";
  FluencyInputType["CheckBoxList"] = "CheckBoxList";
  FluencyInputType["DropDownList"] = "DropDownList";
  FluencyInputType["RadioButtonList"] = "RadioButtonList";
  FluencyInputType["ImageUpload"] = "ImageUpload";
})(FluencyInputType || (FluencyInputType = {}));
/// <summary>
/// Represent different controls types for <see cref="DataElement"/> input control
/// </summary>
export var InputType;
(function (InputType) {
  /// <summary>
  /// For a text-box (single line) input
  /// </summary>
  InputType["TextBox"] = "TextBox";
  /// <summary>
  /// For a multi line text area input
  /// </summary>
  InputType["TextArea"] = "TextArea";
  /// <summary>
  /// Checkbox control to use on boolean data types elements
  /// </summary>
  InputType["Checkbox"] = "Checkbox";
  /// <summary>
  /// Radio controls to use on multiple option data types elements
  /// </summary>
  InputType["Radio"] = "Radio";
  /// <summary>
  /// Dropdown control to use on multiple option data types elements
  /// </summary>
  InputType["Dropdown"] = "Dropdown";
  /// <summary>
  /// Switch control to use on boolean data types elements
  /// </summary>
  InputType["Switch"] = "Switch";
  /// <summary>
  /// TimePicker control to use on time data types elements.
  /// </summary>
  InputType["TimePicker"] = "TimePicker";
  /// <summary>
  /// DateTimePicker control to use on date types elements.
  /// </summary>
  InputType["DateTimePicker"] = "DateTimePicker";
  InputType["Signature"] = "Signature";
  InputType["ImageUpload"] = "ImageUpload";
})(InputType || (InputType = {}));
