import { FunctionComponent, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { Box } from "../Box";
import { useColors } from "../ColorSchemeProvider";
import { Text } from "../Text";
import { FancyDateTime } from "../FancyDateTime";
import type momentType from "moment";
import type { TaskComment } from "@prodoctivity/shared/src/index-types";
import { NestedCommentRow } from "./NestedCommentRow";
import { TapArea } from "../TapArea";
import { SendMessageIcon } from "../InteractiveIconController/design-system-icons/SendMessageIcon";
import { TextArea } from "../TextArea";
import { Skeleton } from "../Skeleton";
import { range } from "@prodoctivity/shared";
import { Tooltip } from "../Tooltip";
import { DesignSystemRefreshIconSvg } from "../InteractiveIconController/design-system-icons/DesignSystemRefreshIconSvg";
import { useDesignBreakpoint } from "../breakpoint";

export type PublicAvatarImageProps = {
  username: string;
  size: 32 | 48 | 62;
};

type Props = {
  onAddComment: (arg: {
    taskId: string | undefined;
    processId: string | undefined;
    content: string;
    parentCommentId: string | undefined;
    commentSubtitle: string | undefined;
  }) => void;
  onCommentUpdate: (arg: { content: string; taskCommentId: string }) => void;
  taskSelected: {
    taskId: string;
    processId: string;
    taskStageName: string | undefined;
  };
  myPublicAvatarImageComponent: ReactNode;
  OtherUsersPublicAvatarImage: FunctionComponent<PublicAvatarImageProps>;
  PublicProfileComponent: FunctionComponent<{
    username: string;
  }>;
  resources: {
    _timeFormat: string;
    _dateTimeFormatShort: string;
    _dateFormatShort: string;
    addComment: string;
    comment: string;
    comments: string;
    noComments: string;
    refresh: string;
  };
  moment: typeof momentType;
  data: Array<TaskComment & { commentId: string }> | undefined;
  commentsTotal: number | undefined;
  isLoadingComments: boolean;
  refetchComments: () => void;
};

export const CommentsLayout: FunctionComponent<Props> = ({
  data,
  isLoadingComments,
  taskSelected,
  onAddComment,
  onCommentUpdate,
  myPublicAvatarImageComponent,
  PublicProfileComponent,
  OtherUsersPublicAvatarImage,
  resources,
  moment,
  commentsTotal,
  refetchComments,
}) => {
  const { colors } = useColors();
  const { breakpoint } = useDesignBreakpoint();
  const [comment, setComment] = useState<string>("");
  const [isTextFieldFocused, setIsTextFieldFocused] = useState<boolean>(false);
  const onFocus = useCallback(() => setIsTextFieldFocused(true), []);
  const onBlur = useCallback(() => setIsTextFieldFocused(false), []);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = useCallback(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, []);

  const commentTree = useMemo(() => {
    return data
      ?.filter((rootComments) => !rootComments.parentCommentId)
      .map((comment) => {
        return {
          ...comment,
          childrenComments: data?.filter(
            (childComment) => childComment.parentCommentId === comment.commentId
          ),
        };
      });
  }, [data]);

  return (
    <Box display="flex" direction="column" paddingX={2} paddingBottom={8} gap={3}>
      <Box
        display="flex"
        direction={isTextFieldFocused || comment !== "" ? "column" : "row"}
        borderRadius={6}
        borderColor={colors.neutral600}
        padding={2}
        minHeight={56}
        alignItems="start"
        justifyContent={isTextFieldFocused ? undefined : "between"}
        flex="grow"
      >
        <Box display="flex" width={"100%"}>
          <Box
            width={36}
            height={36}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop={1}
          >
            <Box
              rounding={"circle"}
              borderRadius={48}
              borderColor={colors.primary}
              height={"fit-content"}
            >
              {myPublicAvatarImageComponent}
            </Box>
          </Box>
          <Box
            display="flex"
            flex="grow"
            paddingRight={2}
            height={isTextFieldFocused || comment !== "" ? undefined : 40}
          >
            <Box width={"100%"}>
              <TextArea
                ref={textAreaRef}
                id="comments"
                placeholder={resources.addComment}
                mode="unstyled"
                value={comment}
                onKeyDown={(e) => {
                  if (e.event.key === "Enter") {
                    e.event.preventDefault();
                    if (comment.trim() === "") return;
                    onAddComment({
                      taskId: taskSelected.taskId,
                      content: comment,
                      processId: taskSelected.processId,
                      parentCommentId: undefined,
                      commentSubtitle: taskSelected.taskStageName,
                    });
                    setComment("");
                  }
                }}
                onChange={(e) => {
                  setComment(e.value);
                  adjustHeight();
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                rows={1}
              />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          marginStart={"auto"}
          justifyContent="end"
          minHeight={40}
          alignItems="center"
        >
          <TapArea
            onTap={() => {
              if (comment.trim() === "") return;
              onAddComment({
                taskId: taskSelected.taskId,
                content: comment,
                processId: taskSelected.processId,
                parentCommentId: undefined,
                commentSubtitle: taskSelected.taskStageName,
              });
              setComment("");
            }}
            onFocus={onFocus}
          >
            <Box
              minWidth={isTextFieldFocused ? 100 : undefined}
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="end"
            >
              {(isTextFieldFocused || comment !== "") && (
                <Text size="200" color={colors.primary700}>
                  {resources.comment}
                </Text>
              )}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={30}
                height={31}
              >
                <SendMessageIcon color={colors.primary} width={30} height={31}></SendMessageIcon>
              </Box>
            </Box>
          </TapArea>
        </Box>
      </Box>
      {!isLoadingComments ? (
        <Box>
          <Box display="flex" justifyContent="between" maxHeight={46} padding={3}>
            <Box>
              <Text size="200" color={colors.neutral900}>
                {commentsTotal && commentsTotal > 99 ? "+" : undefined}
                {commentsTotal &&
                  (commentsTotal > 99 ? 99 : commentsTotal < 0 ? 0 : commentsTotal)}{" "}
                {resources.comments}
              </Text>
            </Box>
            <Box>
              <Box display="flex" flex="shrink" alignItems="center">
                <Tooltip text={resources.refresh}>
                  <TapArea accessibilityLabel={resources.refresh} onTap={refetchComments}>
                    <DesignSystemRefreshIconSvg height={24} width={24} color={colors.primary400} />
                  </TapArea>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          {commentTree === undefined || commentTree.length === 0 ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Text size="200" color={colors.neutral900}>
                {resources.noComments}
              </Text>
            </Box>
          ) : (
            commentTree &&
            commentTree.map((comment, idx) => {
              return (
                <Box key={`${idx}-${comment.createdAt}-${comment.content}`} width={"100%"}>
                  <Box display="flex" flex="grow" gap={4} paddingX={2}>
                    <Box
                      rounding={"circle"}
                      borderRadius={48}
                      borderColor={colors.primary}
                      height={"fit-content"}
                    >
                      <OtherUsersPublicAvatarImage username={comment.username} size={32} />
                    </Box>
                    <Box display="flex" direction="column" width={"100%"}>
                      <Box
                        display="flex"
                        gap={3}
                        marginBottom={2}
                        direction={breakpoint === "small" ? "column" : "row"}
                      >
                        <Box>
                          <PublicProfileComponent username={comment.username} />
                          <FancyDateTime
                            moment={moment}
                            resources={resources}
                            value={comment.createdAt}
                            size="100"
                            color={colors.neutral800}
                          />
                        </Box>

                        <Box>
                          {comment.commentSubtitle && (
                            <Tooltip text={comment.commentSubtitle}>
                              <Box display="flex" direction="row" gap={2}>
                                <Text size="200" color={colors.neutral600}>
                                  •
                                </Text>
                                <Text size="100" color={colors.neutral900} overflow={"ellipsis"}>
                                  {comment.commentSubtitle}
                                </Text>
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                      <Box display="flex" flex="grow" marginBottom={4}>
                        <Text size="200" color={colors.neutral900} inline lineClamp={2}>
                          {comment.content}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  {comment.childrenComments.length > 0 && (
                    <Box display="flex" paddingLeft={11} paddingTop={6} paddingRight={2}>
                      <NestedCommentRow
                        data={comment.childrenComments}
                        onAddComment={onAddComment}
                        onCommentUpdate={onCommentUpdate}
                        myPublicAvatarImageComponent={myPublicAvatarImageComponent}
                        PublicProfileComponent={PublicProfileComponent}
                        OtherUsersPublicAvatarImage={OtherUsersPublicAvatarImage}
                        resources={resources}
                        moment={moment}
                        taskSelected={taskSelected}
                      ></NestedCommentRow>
                    </Box>
                  )}
                </Box>
              );
            })
          )}
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="between" maxHeight={46} padding={3}>
            <Box>
              <Skeleton width={100} height={16} />
            </Box>
          </Box>
          {range(5).map((_, idx) => (
            <Box key={idx}>
              <Box display="flex" width={"100%"} gap={4} paddingX={2}>
                <Box
                  rounding={"circle"}
                  borderRadius={32}
                  borderColor={colors.primary}
                  height={"fit-content"}
                >
                  <Skeleton width={32} height={32} rounded={true} />
                </Box>
                <Box display="flex" direction="column" width={"100%"}>
                  <Box display="flex" gap={3} marginBottom={2}>
                    <Skeleton width={150} height={16} />
                  </Box>
                  <Box display="flex" flex="grow" marginBottom={4}>
                    <Skeleton width={350} height={24} />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};
