import { FunctionComponent } from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const LegalBriefcaseIconSvg: FunctionComponent<Props> = ({
  color,
  width = 22,
  height = 22,
  onClick,
}) => {
  const svgStyle: React.CSSProperties = {
    width: `${width}px`,
    height: `${height}px`,
  };
  return (
    <svg
      style={svgStyle}
      onClick={onClick}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5718_96441)">
        <path
          d="M21.7503 11.6102C21.5746 11.4992 21.4082 11.5214 21.2417 11.6657C20.8533 12.0098 20.4187 12.1097 19.947 12.1097C17.6814 12.1097 15.425 12.1097 13.1593 12.1097C13.0576 12.1097 12.9466 12.1097 12.8171 12.1097C12.8171 12.6314 12.8171 13.1198 12.8171 13.6082C12.8171 14.1188 12.6599 14.3075 12.2346 14.3186C11.4115 14.3186 10.5885 14.3186 9.76545 14.3186C9.29382 14.3186 9.14586 14.141 9.14586 13.586C9.14586 13.1087 9.14586 12.6314 9.14586 12.1208C9.01639 12.1208 8.92392 12.1208 8.82219 12.1208C6.52879 12.1208 4.24464 12.1208 1.95124 12.1208C1.50736 12.1208 1.10046 12.0098 0.739807 11.6879C0.57335 11.5436 0.406894 11.4992 0.221942 11.6213C0.0554855 11.7323 0 11.9321 0 12.1763C0 14.7182 0 17.2601 0 19.802C0 21.0341 0.80454 21.9775 1.83102 21.9997C2.42287 21.9997 3.01471 21.9997 3.6158 21.9997C7.06515 21.9997 10.5145 21.9997 13.9639 21.9997C16.0446 21.9997 18.1253 21.9997 20.206 21.9997C21.1862 21.9997 21.9815 21.0341 21.9908 19.8575C22 17.2934 21.9908 14.7182 21.9908 12.1541C21.9908 11.921 21.926 11.7323 21.7503 11.6213V11.6102Z"
          fill={color || "#5A5A5A"}
        />
        <path
          d="M20.0395 3.2966C18.6246 3.341 17.2098 3.2966 15.7856 3.2966C15.7209 3.2966 15.6654 3.2966 15.5822 3.2855C15.5822 2.9525 15.5822 2.63061 15.5822 2.31981C15.6099 0.954521 14.6944 -0.0222702 13.6494 -7.0448e-05C11.9664 0.0332292 10.2833 -7.0448e-05 8.60025 -7.0448e-05C8.36906 -7.0448e-05 8.11938 -0.0111703 7.89744 0.0443291C7.02816 0.244127 6.42707 1.12102 6.40858 2.19771C6.40858 2.55291 6.40858 2.9192 6.40858 3.2966C6.30685 3.2966 6.22362 3.2966 6.14964 3.2966C4.73476 3.2966 3.31988 3.2966 1.89575 3.2966C0.786044 3.3077 0 4.25119 0 5.59428C0 6.62657 0 7.65886 0 8.69115C0 10.0675 0.786044 10.9999 1.93274 10.9999C4.23539 10.9999 6.53804 10.9999 8.84994 10.9999C8.95166 10.9999 9.04414 10.9999 9.16435 10.9999C9.16435 10.8223 9.16435 10.6669 9.16435 10.5115C9.1736 10.1119 9.34006 9.91214 9.67297 9.90104C10.5607 9.90104 11.4485 9.90104 12.3363 9.90104C12.6599 9.90104 12.8172 10.1008 12.8356 10.4893C12.8356 10.6447 12.8356 10.8112 12.8356 10.9888C12.9651 10.9888 13.0668 10.9888 13.1593 10.9888C15.462 10.9888 17.7646 10.9888 20.0765 10.9888C21.2232 10.9888 22 10.0453 22 8.66895C22 7.65886 21.9723 6.65987 22 5.64978C22.037 4.32889 21.1677 3.2522 20.0395 3.2855V3.2966ZM13.7327 3.2966H8.25809V2.23101H13.7327V3.2966Z"
          fill={color || "#5A5A5A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_5718_96441">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
