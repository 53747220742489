import {
  BannerSlim,
  Box,
  Button,
  Checkbox,
  ChevronSvgIcon,
  ComboBox,
  ComboBoxItemType,
  DeviceTypeProvider,
  Divider,
  Icon,
  Layer,
  NumberField,
  PopoverCombobox,
  PopoverComboboxOption,
  SelectList,
  Spinner,
  StringTemplateBuilder,
  TapArea,
  Text,
  TextField,
  VariablePartSheet,
  overlayPanelZIndex,
  useColors,
} from "@prodoctivity/design-system";
import type {
  DocumentCollectionFolderSortType,
  ExpressionDataInput,
} from "@prodoctivity/shared/src/index-types";
import type { DocumentTypeResume } from "@prodoctivity/types";
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo, useState } from "react";
import {
  DocumentCollectionState,
  TreeItem,
  defaultLogicalExpression,
  useIconOptions,
  useTreeItemSettings,
} from "../../hooks";

import { RadioButtonGroup } from "@prodoctivity/design-system";
import { ExpressionControl } from "@prodoctivity/fluency-components";
import { CustomAccordion } from "@prodoctivity/prodoctivity-form-v5/src/components/Dependencies/CustomAccordion";
import {
  ALLOWED_CHARS_REGEXP,
  DocumentCollectionIconDefaultToDocument$Schema,
} from "@prodoctivity/shared";
import { useAppTranslation } from "../../../../../hooks/useAppTranslation";
import { RenderDocumentCollectionIcon } from "../../../../DocumentCollectionInstance/RenderDocumentCollectionIcon";
import { nameConfigContextToString } from "../utils";
import { CloseConfigComponent } from "./CloseConfigComponent";
import { FolderValidation } from "./FolderValidation";

export type TreeItemSettingsProps = {
  rootInfo:
    | { isRoot: false }
    | {
        isRoot: true;
        trackingIssuesDueDateHours: number | undefined;
      };
  resources: ReturnType<typeof useAppTranslation>["resources"];
  treeItem: TreeItem;
  documentTypes: DocumentTypeResume[];
  formState: DocumentCollectionState;
  configItem: { isRootFolder?: boolean; originalItem: TreeItem; updatedItem: TreeItem } | undefined;
  setConfigItem: Dispatch<
    SetStateAction<
      | {
          originalItem: TreeItem;
          updatedItem: TreeItem;
        }
      | undefined
    >
  >;
  onCancelConfig: () => void;
  onSaveConfig: (trackingIssuesDueDateHours: number | undefined) => void;
  documentTypesOptions: ComboBoxItemType[];
  setShowMainSaveButton: Dispatch<SetStateAction<boolean>>;
};

export const TreeItemSettings: FunctionComponent<TreeItemSettingsProps> = ({
  rootInfo,
  treeItem,
  documentTypes,
  formState,
  configItem,
  setConfigItem,
  onCancelConfig,
  onSaveConfig,
  documentTypesOptions,
  setShowMainSaveButton,
}) => {
  const { colors } = useColors();
  const [isSortExpanded, setIsSortExpanded] = useState<boolean>(false);
  const [filter, setFilter] = useState("");
  const [invalidExpressionsIconFilter, setInvalidExpressionsIconFilter] = useState("");
  const onInvalidExpressionFilterChange = useCallback((newFilter: string) => {
    setInvalidExpressionsIconFilter(newFilter);
  }, []);
  const onFilterChange = useCallback((newFilter: string) => {
    setFilter(newFilter);
  }, []);
  const { resources, moment } = useAppTranslation();
  const { iconOptions: icons } = useIconOptions(resources);
  const iconOptions = useMemo(() => {
    return icons.reduce((arr: PopoverComboboxOption[], curr) => {
      curr.icons.forEach((opt) => {
        arr.push({
          groupLabel: curr.groupLabel,
          value: opt.value,
          label: (
            <Box display="flex" gap={3}>
              <RenderDocumentCollectionIcon iconKey={opt.value} />
              <Text>{opt.label}</Text>
            </Box>
          ),
          strLabel: opt.label,
        });
      });
      return arr;
    }, []);
  }, [icons]);

  const {
    isLoading,
    folderSortOptions,
    folderDocumentTypeOptions,
    folderMergedContextFields,
    initialState,
    initialCategoryNameConfigState,
    variableList,
    getFieldLabel,
    getFieldDataType,
    getNewVariableData,
    showVariablePartSheet,
    setShowVariablePartSheet,
    clickedPart,
    currentVariable,
    rawSetCurrentVariableValue,
    eventManager,
    eventManagerForCategoryNameConfig,
    handlePartClicked,
    handleVariableAdded,
    handleVariableAddedForCategoryNameConfig,
    onCategoryCheckBoxClick,
    categoryError,
    setCategoryError,
    saveChangesState,
    setSaveChangesState,
    isTrackableError,
    setIsTrackableError,
    changeTrackingIssuesDueDateHours,
    trackingIssuesDueDateHours,
    onRequiredConditionChange,
    onIncludeExpressionChange,
    tI18n,
    getConditionText,
    logicalExpressionToExpressionDataInput,
    deleteRequiredCondition,
    deleteIncludeExpression,
    deleteFolderValidation,
    onSingleValidationFolderChange,
    createNewFolderValidation,
    folderDocumentTypesOptions,
    folderMergedContextFieldsForFilterBuilder,
    isFolderChangesButtonDisabled,
    setIsFolderChangesButtonDisabled,
    isAnyExpressionInvalidOnCurrentItem,
    isLoadingWorkflowTypeList,
    workflowTypesListOptions,
    changeWorkflowTypeValue,
    onBlurWorkflowProcess,
    alterTrackingWorkflowTypeSelectedOption,
    handlePartClickedForCategoryNameConfig,
    showCategoryNameConfigSheet,
    setShowCategoryNameConfigSheet,
    clickedPartForCategoryNameConfig,
    rawSetCurrentVariableForCategoryNameConfigValue,
    currentVariableForCategoryNameConfig,
    childFolderHaveWorkflowTypeSelected,
    parentFolderHaveWorkflowTypeSelected,
  } = useTreeItemSettings(
    treeItem,
    documentTypes,
    rootInfo,
    configItem,
    setConfigItem,
    documentTypesOptions,
    formState
  );

  return (
    <Box height={"100%"}>
      <Box height={48} alignItems="center" color={colors.neutral200} paddingX={3} display="flex">
        <Text weight="bold" color={colors.subtle}>
          {treeItem.type === "folder"
            ? `${resources.documentCollection.folderConfigurationFor} “${
                rootInfo.isRoot
                  ? formState.name
                  : nameConfigContextToString(moment, treeItem.item.nameConfig)
              }”`
            : `${resources.documentCollection.documentConfigurationFor} “
          ${documentTypes.find((dt) => dt.documentTypeId === treeItem.item.documentTypeId)?.name}”`}
        </Text>
      </Box>
      <Divider />
      <Box padding={3}>
        <Box display="flex" direction="column" gap={3}>
          {!rootInfo.isRoot && !isLoading && (
            <Box minHeight={65}>
              <Box marginBottom={1}>
                <Text color={colors.neutral900}>
                  {resources.documentCollection.identityFormula}
                </Text>
              </Box>
              <Box>
                <StringTemplateBuilder
                  eventManager={eventManager}
                  warningLabel={resources.documentCollection.YouHaveRepeatedVariables}
                  inline={true}
                  onChange={(e) => {
                    setConfigItem((prev) => {
                      if (!prev) return prev;
                      const updatedItem = { ...prev.updatedItem };
                      updatedItem.item.nameConfig = e.parts;
                      if (
                        updatedItem.type === "documentType" &&
                        updatedItem.item.nameConfig.length <= 0
                      ) {
                        updatedItem.item.nameConfig = undefined;
                      }
                      return {
                        ...prev,
                        updatedItem: updatedItem,
                      };
                    });
                  }}
                  initialState={initialState}
                  onPartClicked={handlePartClicked}
                  onVariableAdded={handleVariableAdded}
                  getNewVariableData={getNewVariableData}
                  getFieldLabel={getFieldLabel}
                />

                {treeItem.type === "folder" && (
                  <>
                    <Box display="flex" direction="column" flex="shrink" paddingY={3} gap={6}>
                      <Box display="flex" direction="row" flex="grow" gap={4}>
                        <Box paddingY={1} display="flex" direction="row" alignItems="center">
                          <Box paddingRight={2}>
                            <Checkbox
                              id={"document-collection-category-check"}
                              onChange={({ checked }) => onCategoryCheckBoxClick(checked)}
                              checked={!!treeItem.item.category || treeItem.item.category === ""}
                            />
                          </Box>
                          <Text color={colors.neutral900}>{resources.category}</Text>
                        </Box>

                        {treeItem.item.category !== undefined && (
                          <Box width={"100%"}>
                            <TextField
                              id={"document-collection-category"}
                              errorMessage={categoryError ? resources.thisFieldCannotBeEmpty : ""}
                              onBlur={(e) => {
                                if (ALLOWED_CHARS_REGEXP.test(e.value)) {
                                  setCategoryError(false);
                                  setSaveChangesState(false);
                                } else {
                                  setCategoryError(true);
                                  setSaveChangesState(true);
                                }
                              }}
                              onChange={(e) => {
                                setCategoryError(false);
                                setSaveChangesState(false);
                                setConfigItem((prev) => {
                                  if (!prev || prev.updatedItem.type !== "folder") return prev;
                                  const updatedItem = { ...prev.updatedItem };

                                  updatedItem.item.category = e.value;
                                  return {
                                    ...prev,
                                    updatedItem: updatedItem,
                                  };
                                });
                              }}
                              value={treeItem.item.category}
                            />
                          </Box>
                        )}
                      </Box>
                      {treeItem.item && treeItem.item.category !== undefined && (
                        <Box display="flex" direction="column" flex="grow" gap={2}>
                          <Text color={colors.neutral900}>{resources.categoryConfig}</Text>
                          <Box>
                            <StringTemplateBuilder
                              eventManager={eventManagerForCategoryNameConfig}
                              warningLabel={resources.documentCollection.YouHaveRepeatedVariables}
                              inline={true}
                              onChange={(e) =>
                                setConfigItem((prev) => {
                                  if (!prev) return prev;
                                  const updatedItem = { ...prev.updatedItem };
                                  if (updatedItem.type === "folder" && updatedItem.item.category) {
                                    updatedItem.item.categoryNameConfig = e.parts;
                                  }
                                  if (
                                    updatedItem.type === "folder" &&
                                    updatedItem.item.category &&
                                    updatedItem.item.categoryNameConfig &&
                                    updatedItem.item.categoryNameConfig.length <= 0
                                  ) {
                                    updatedItem.item.categoryNameConfig = undefined;
                                  }
                                  return {
                                    ...prev,
                                    updatedItem: updatedItem,
                                  };
                                })
                              }
                              initialState={initialCategoryNameConfigState}
                              onPartClicked={handlePartClickedForCategoryNameConfig}
                              onVariableAdded={handleVariableAddedForCategoryNameConfig}
                              getNewVariableData={getNewVariableData}
                              getFieldLabel={getFieldLabel}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                )}

                <DeviceTypeProvider deviceType="mobile">
                  {showVariablePartSheet && (
                    <Layer zIndex={overlayPanelZIndex}>
                      <VariablePartSheet
                        onDismiss={() => {
                          setShowVariablePartSheet(false);
                        }}
                        getFieldLabel={getFieldLabel}
                        getFieldDataType={getFieldDataType}
                        variableList={variableList}
                        currentPart={clickedPart}
                        showVariableDetails={false}
                        allowAddNew={false}
                        eventManager={eventManager}
                        moment={moment}
                        resources={resources}
                        currentVariable={currentVariable}
                        rawSetCurrentVariableValue={rawSetCurrentVariableValue}
                      />
                    </Layer>
                  )}

                  {showCategoryNameConfigSheet && (
                    <Layer zIndex={overlayPanelZIndex}>
                      <VariablePartSheet
                        onDismiss={() => {
                          setShowCategoryNameConfigSheet(false);
                        }}
                        getFieldLabel={getFieldLabel}
                        getFieldDataType={getFieldDataType}
                        variableList={variableList}
                        currentPart={clickedPartForCategoryNameConfig}
                        showVariableDetails={false}
                        allowAddNew={false}
                        eventManager={eventManagerForCategoryNameConfig}
                        moment={moment}
                        resources={resources}
                        currentVariable={currentVariableForCategoryNameConfig}
                        rawSetCurrentVariableValue={rawSetCurrentVariableForCategoryNameConfigValue}
                      />
                    </Layer>
                  )}
                </DeviceTypeProvider>
              </Box>
            </Box>
          )}
          {treeItem.type === "folder" && (
            <Box display="flex" gap={4} direction="column">
              <Box display="flex" gap={4}>
                <Box display="flex" gap={2} direction="row">
                  <Checkbox
                    id="chk-item-isTrackable"
                    checked={treeItem.item.isTrackable}
                    onChange={(e) => {
                      setConfigItem((prev) => {
                        if (!prev || prev.updatedItem.type !== "folder") return prev;
                        const updatedItem = { ...prev.updatedItem };

                        if (!e.checked && rootInfo.isRoot) {
                          changeTrackingIssuesDueDateHours(undefined);
                        }
                        updatedItem.item.isTrackable = e.checked;
                        updatedItem.item.closeConfig = e.checked
                          ? updatedItem.item.closeConfig
                          : undefined;

                        return {
                          ...prev,
                          updatedItem: updatedItem,
                        };
                      });
                    }}
                  />
                  <Text color={colors.neutral900}>{resources.isTrackable}</Text>
                </Box>

                {rootInfo.isRoot && treeItem.item.isTrackable && (
                  <Box display="flex" direction="column">
                    <Box marginEnd={2}>
                      <Text color={colors.neutral900}>{resources.expirationTimeHours}</Text>
                    </Box>
                    <NumberField
                      placeholder={resources.expirationTimeLabel}
                      id={"document-collection-isTrackable-field"}
                      errorMessage={isTrackableError ? resources.specialCharsNotAllowed : 0}
                      min={0}
                      onBlur={(e) => {
                        if (e.value && e.value < 1) {
                          setIsTrackableError(true);
                          setSaveChangesState(true);
                        }
                      }}
                      onChange={(e) => {
                        setIsTrackableError(false);
                        setSaveChangesState(false);
                        setConfigItem((prev) => {
                          if (!prev || prev.updatedItem.type !== "folder") return prev;
                          const updatedItem = { ...prev.updatedItem };
                          const value = e.value === 0 ? undefined : e.value;

                          changeTrackingIssuesDueDateHours(value);

                          return {
                            ...prev,
                            updatedItem: updatedItem,
                          };
                        });
                      }}
                      value={trackingIssuesDueDateHours}
                    />
                  </Box>
                )}
              </Box>
              {treeItem.item.isTrackable && (
                <CloseConfigComponent
                  closeConfig={treeItem.item.closeConfig}
                  resources={resources}
                  folderDocumentTypeOptions={folderDocumentTypeOptions}
                  folderMergedContextFields={folderMergedContextFields}
                  setConfigItem={setConfigItem}
                />
              )}
            </Box>
          )}
          <Box display="flex" gap={4}>
            <Box flex="grow" minHeight={65} alignItems="center">
              <Box marginBottom={1}>
                <Text color={colors.neutral900}>{resources.icon}</Text>
              </Box>
              <PopoverCombobox
                filter={filter}
                onFilterChange={onFilterChange}
                getI18n={(k) => {
                  const m: Record<string, string> = resources as any;
                  return m[k] || k;
                }}
                selectedValue={treeItem.item.iconKey}
                onSelect={(item) =>
                  setConfigItem((prev) => {
                    if (!prev) return prev;
                    const updatedItem = { ...prev.updatedItem };
                    updatedItem.item.iconKey = DocumentCollectionIconDefaultToDocument$Schema.parse(
                      item.value
                    );
                    return {
                      ...prev,
                      updatedItem: updatedItem,
                    };
                  })
                }
                options={iconOptions}
                direction={undefined}
                placeholder={undefined}
                onClear={undefined}
                displayOption="label"
                displayedText={undefined}
              />
            </Box>
            <Box display="flex" direction="column" flex="shrink" minHeight={65}>
              <Box marginBottom={1}>
                <Text color={colors.neutral900}>{resources.color}</Text>
              </Box>
              <input
                type="color"
                width="100%"
                value={treeItem.item.color || "#2560a3"}
                onChange={(e) => {
                  setConfigItem((prev) => {
                    if (!prev) return prev;
                    const updatedItem = { ...prev.updatedItem };
                    updatedItem.item.color = e.target.value || undefined;
                    return {
                      ...prev,
                      updatedItem: updatedItem,
                    };
                  });
                }}
              />
            </Box>
          </Box>
          {isLoadingWorkflowTypeList && !rootInfo.isRoot ? (
            <Spinner show={true} />
          ) : (
            treeItem.type === "folder" &&
            treeItem.item.category &&
            treeItem.item.categoryNameConfig && (
              <Box gap={2} display="flex" direction="column" width={"100%"}>
                <Text color={colors.neutral900}>{resources.workflowProcess}</Text>
                {(childFolderHaveWorkflowTypeSelected || parentFolderHaveWorkflowTypeSelected) && (
                  <Box display="flex" direction="row" gap={2} alignItems="center">
                    <Icon
                      icon="circle-info"
                      accessibilityLabel={resources.info}
                      color={colors.secondary300}
                    />
                    <Text color={colors.secondary300}>
                      {resources.workflowProcessAlreadySelected}
                    </Text>
                  </Box>
                )}

                <ComboBox
                  id={"workflow-combobox"}
                  label={""}
                  options={workflowTypesListOptions}
                  placeholder={resources.noProcessSelected}
                  inputValue={treeItem.item.workflowTypeName}
                  onClear={() => {
                    alterTrackingWorkflowTypeSelectedOption(undefined);
                  }}
                  onBlur={() => {
                    onBlurWorkflowProcess();
                  }}
                  onChange={(e) => {
                    alterTrackingWorkflowTypeSelectedOption(e.value);
                  }}
                  onSelect={(e) => {
                    changeWorkflowTypeValue(e.item);
                  }}
                  disabled={
                    childFolderHaveWorkflowTypeSelected || parentFolderHaveWorkflowTypeSelected
                  }
                />
              </Box>
            )
          )}
          <Box display="flex" gap={4}>
            {!rootInfo.isRoot && (
              <>
                <Box flex="grow" display="flex" direction="row" gap={2} alignItems="center">
                  <Checkbox
                    id="chk-item-pinned"
                    checked={treeItem.item.pinned}
                    onChange={(e) => {
                      setConfigItem((prev) => {
                        if (!prev) return prev;
                        const updatedItem = { ...prev.updatedItem };
                        updatedItem.item.pinned = e.checked;
                        return {
                          ...prev,
                          updatedItem: updatedItem,
                        };
                      });
                    }}
                  />
                  <Box>
                    <Text color={colors.neutral900}>{resources.pinned}</Text>
                  </Box>
                </Box>
                {treeItem.type === "documentType" && (
                  <Box flex="grow" display="flex" direction="row" gap={2} alignItems="center">
                    <Checkbox
                      id="chk-item-required"
                      checked={treeItem.item.required}
                      onChange={(e) => {
                        setConfigItem((prev) => {
                          if (!prev || prev.updatedItem.type !== "documentType") return prev;
                          const updatedItem = { ...prev.updatedItem };
                          updatedItem.item.required = e.checked;
                          return {
                            ...prev,
                            updatedItem: updatedItem,
                          };
                        });
                        deleteRequiredCondition();
                      }}
                    />
                    <Box display="flex" direction="row" gap={2} alignItems="center">
                      <Text color={colors.neutral900}>{resources.required}</Text>
                    </Box>
                    {treeItem.item.required && !treeItem.item.requiredCondition && (
                      <Box display="flex" flex="grow" justifyContent="end">
                        <Button
                          onClick={() => {
                            const expression: ExpressionDataInput =
                              logicalExpressionToExpressionDataInput(defaultLogicalExpression);
                            onRequiredConditionChange("", expression);
                          }}
                          text={resources.createRule}
                          color="gray"
                        />
                      </Box>
                    )}
                  </Box>
                )}

                {treeItem.type === "folder" && !rootInfo.isRoot && (
                  <Box display="flex" flex="grow" gap={2}>
                    <Checkbox
                      id="chk-item-show-summary"
                      checked={treeItem.item.showSummary}
                      onChange={(e) => {
                        setConfigItem((prev) => {
                          if (!prev || prev.updatedItem.type !== "folder") return prev;
                          const updatedItem = { ...prev.updatedItem };
                          updatedItem.item.showSummary = e.checked;
                          return {
                            ...prev,
                            updatedItem: updatedItem,
                          };
                        });
                      }}
                    />
                    <Box marginBottom={1}>
                      <Text color={colors.neutral900}>{resources.showSummary}</Text>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>

          {treeItem.type === "documentType" &&
            treeItem.item.required &&
            treeItem.item.requiredCondition &&
            folderMergedContextFieldsForFilterBuilder &&
            !isLoading && (
              <Box marginBottom={2} marginTop={1} borderRadius={6} borderColor={colors.neutral900}>
                <CustomAccordion
                  removeLabel={"delete_"}
                  currIndex={0}
                  i18n={tI18n}
                  collapsedColor={colors.primaryHover0}
                  deleteAccordionHandler={deleteRequiredCondition}
                  openedColor={colors.white}
                  canDeleteRow={true}
                  label={
                    <Box display="flex" direction="row" gap={2} paddingY={2}>
                      <Text>{`${resources.rule}: ${getConditionText(
                        logicalExpressionToExpressionDataInput(treeItem.item.requiredCondition)
                      )}`}</Text>
                    </Box>
                  }
                  hideLabelOnOpen={true}
                >
                  <ExpressionControl
                    name="required_condition_expression"
                    contextDefinition={folderMergedContextFieldsForFilterBuilder}
                    expression={logicalExpressionToExpressionDataInput(
                      treeItem.item.requiredCondition
                    )}
                    hideName={true}
                    contextualIf={{ node: <></>, value: () => true }}
                    onExpressionChanged={onRequiredConditionChange}
                    moment={moment}
                    translate={tI18n}
                    resources={resources}
                  />
                  <Box
                    display="flex"
                    width={"100%"}
                    minHeight={56}
                    alignItems="center"
                    padding={4}
                    color={colors.primary}
                    borderColor={colors.neutral50}
                    borderRadius={6}
                  >
                    <Text color={colors.white}>
                      {getConditionText(
                        logicalExpressionToExpressionDataInput(treeItem.item.requiredCondition)
                      )}
                    </Text>
                  </Box>
                </CustomAccordion>
              </Box>
            )}
          <Divider direction="horizontal" />
          {treeItem.type === "documentType" && (
            <Box display="flex" gap={4}>
              <Box
                display="flex"
                flex="grow"
                direction={!treeItem.item.includeExpression ? "row" : "column"}
                gap={4}
                minHeight={65}
              >
                <Box>
                  <Text color={colors.neutral900}>{resources.includeDocumentIf}</Text>
                </Box>
                {!treeItem.item.includeExpression && (
                  <Box display="flex" flex="grow" justifyContent="end">
                    <Box>
                      <Button
                        onClick={() => {
                          const expression: ExpressionDataInput =
                            logicalExpressionToExpressionDataInput(defaultLogicalExpression);
                          onIncludeExpressionChange("", expression);
                        }}
                        text={resources.createRule}
                        color="gray"
                      />
                    </Box>
                  </Box>
                )}
                {treeItem.item.includeExpression &&
                  folderMergedContextFieldsForFilterBuilder &&
                  !isLoading && (
                    <Box borderRadius={6} borderColor={colors.neutral900}>
                      <CustomAccordion
                        removeLabel={"delete_"}
                        currIndex={0}
                        i18n={tI18n}
                        collapsedColor={colors.primaryHover0}
                        deleteAccordionHandler={deleteIncludeExpression}
                        openedColor={colors.white}
                        canDeleteRow={true}
                        label={
                          <Box display="flex" direction="row" gap={2} paddingY={2}>
                            <Text>{`${resources.rule}: ${getConditionText(
                              logicalExpressionToExpressionDataInput(
                                treeItem.item.includeExpression
                              )
                            )}`}</Text>
                          </Box>
                        }
                        hideLabelOnOpen={true}
                      >
                        <ExpressionControl
                          name="include_condition_expression"
                          contextDefinition={folderMergedContextFieldsForFilterBuilder}
                          expression={logicalExpressionToExpressionDataInput(
                            treeItem.item.includeExpression
                          )}
                          hideName={true}
                          contextualIf={{ node: <></>, value: () => true }}
                          onExpressionChanged={onIncludeExpressionChange}
                          moment={moment}
                          translate={tI18n}
                          resources={resources}
                        />
                        <Box
                          display="flex"
                          width={"100%"}
                          minHeight={56}
                          alignItems="center"
                          padding={4}
                          color={colors.primary}
                          borderColor={colors.neutral50}
                          borderRadius={6}
                        >
                          <Text color={colors.white}>
                            {getConditionText(
                              logicalExpressionToExpressionDataInput(
                                treeItem.item.includeExpression
                              )
                            )}
                          </Text>
                        </Box>
                      </CustomAccordion>
                    </Box>
                  )}
              </Box>
            </Box>
          )}

          {treeItem.type === "folder" && (
            <>
              <Box borderRadius={12} borderStyle="lg" marginBottom={1} width="100%">
                <TapArea onTap={() => setIsSortExpanded((prev) => !prev)}>
                  <Box
                    height={48}
                    alignItems="center"
                    color={colors.neutral200}
                    paddingX={3}
                    display="flex"
                    justifyContent="between"
                  >
                    <Text weight="bold" color={colors.subtle}>
                      {resources.documentCollection.folderOrder}
                    </Text>
                    <ChevronSvgIcon
                      direction={isSortExpanded ? "up" : "down"}
                      color={colors.subtle}
                    />
                  </Box>
                </TapArea>
                {isSortExpanded && (
                  <>
                    <Divider />
                    <Box padding={4}>
                      <Box marginBottom={3}>
                        <RadioButtonGroup
                          id={"folder-sort"}
                          name={"Folder Sort"}
                          gap={3}
                          options={folderSortOptions}
                          value={treeItem.item.defaultFolderSort?.type || "document-date"}
                          onChange={(value) => {
                            setConfigItem((prev) => {
                              if (!prev || prev?.updatedItem.type !== "folder") return prev;

                              const folderSort: DocumentCollectionFolderSortType = {
                                type: value as DocumentCollectionFolderSortType["type"],
                                direction:
                                  prev.updatedItem.item.defaultFolderSort?.direction || "desc",
                              };

                              return {
                                ...prev,
                                updatedItem: {
                                  ...prev.updatedItem,
                                  item: {
                                    ...prev.updatedItem.item,
                                    defaultFolderSort: folderSort,
                                  },
                                },
                              };
                            });
                          }}
                        />
                      </Box>
                      <Box display="flex" gap={3} alignItems="center">
                        <Text>{resources.sort}:</Text>
                        <SelectList
                          id={"folder-sort-direction"}
                          options={[
                            { value: "desc", label: resources.dataTypeValues.descending },
                            { value: "asc", label: resources.dataTypeValues.ascending },
                          ]}
                          value={treeItem.item.defaultFolderSort?.direction || "desc"}
                          onChange={({ value }) => {
                            setConfigItem((prev) => {
                              if (!prev || prev?.updatedItem.type !== "folder") return prev;

                              const folderSort: DocumentCollectionFolderSortType = {
                                type:
                                  prev.updatedItem.item.defaultFolderSort?.type || "document-date",
                                direction: (value ||
                                  "desc") as DocumentCollectionFolderSortType["direction"],
                              };

                              return {
                                ...prev,
                                updatedItem: {
                                  ...prev.updatedItem,
                                  item: {
                                    ...prev.updatedItem.item,
                                    defaultFolderSort: folderSort,
                                  },
                                },
                              };
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}

          {!rootInfo.isRoot && treeItem.type === "folder" && (
            <Box display="flex" gap={4}>
              <Box flex="grow" minHeight={65}>
                <Box marginBottom={4}>
                  <Text color={colors.neutral900}>{resources.documentCollection.validations}</Text>
                </Box>
                <Box flex="grow" marginBottom={4}>
                  <Box display="flex" justifyContent="between" alignItems="center" minWidth={180}>
                    <Button
                      onClick={createNewFolderValidation}
                      text={resources.addNew}
                      color="gray"
                      fullWidth
                    />
                  </Box>
                </Box>
                {treeItem.item.validations &&
                  treeItem.item.validations.length > 0 &&
                  folderMergedContextFieldsForFilterBuilder.fields.length > 0 &&
                  !isLoading && (
                    <Box>
                      {treeItem.item.validations.map((validation, index) => {
                        return (
                          <Box key={`folder-validation-${index}${validation}`}>
                            <FolderValidation
                              currIndex={index}
                              expressionDataInput={logicalExpressionToExpressionDataInput}
                              getConditionText={getConditionText}
                              mergedContextDefinition={folderMergedContextFieldsForFilterBuilder}
                              onSingleValidationFolderChange={onSingleValidationFolderChange}
                              tI18n={tI18n}
                              validation={validation}
                              setConfigItem={setConfigItem}
                              filter={invalidExpressionsIconFilter}
                              onFilterChange={onInvalidExpressionFilterChange}
                              iconOptions={iconOptions}
                              isLoading={isLoading}
                              folderDocumentTypesOptions={folderDocumentTypesOptions}
                              setIsFolderChangesButtonDisabled={setIsFolderChangesButtonDisabled}
                              deleteFolderValidation={deleteFolderValidation}
                              validationListLength={treeItem.item.validations.length}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  )}
              </Box>
            </Box>
          )}
          <Box>
            {isAnyExpressionInvalidOnCurrentItem.length > 0 && (
              <Box
                marginBottom={4}
                borderColor={colors.error}
                color={colors.other600}
                borderRadius={6}
              >
                {isAnyExpressionInvalidOnCurrentItem.map((msg, i) => {
                  return (
                    <BannerSlim key={`${treeItem.item}${msg}${i}`} type="error" message={msg} />
                  );
                })}
              </Box>
            )}
          </Box>

          <Divider direction="horizontal" />

          <Box flex="grow" display="flex" justifyContent="between" marginTop={3}>
            <Box>
              <Button
                onClick={() => {
                  onCancelConfig();
                  setShowMainSaveButton(true);
                }}
                color={"transparent"}
                text={resources.cancel}
              />
            </Box>
            <Box>
              <Button
                onClick={() => {
                  onSaveConfig(trackingIssuesDueDateHours);
                  setShowMainSaveButton(true);
                }}
                color={"gray"}
                disabled={
                  saveChangesState ||
                  isFolderChangesButtonDisabled ||
                  isAnyExpressionInvalidOnCurrentItem.length > 0
                }
                text={resources.saveChanges}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
