import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  width?: number | string;
  height?: number | string;
  color?: ColorConfig | undefined;
};

export const EmptyStateWidgetContent: FunctionComponent<Props> = ({
  width = 414,
  height = 417,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 414 417"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="207" cy="207" r="207" fill="#E7E7E8" />
      <path d="M292 66.5L307 61.5" stroke="#BCBCBB" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M139 216C120.5 199.5 97.5 177.5 107 138"
        stroke="#BCBCBB"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M111.5 125.5C119.667 109 147.881 81.4162 197.5 96.5003C256 122.5 160.5 157 151 134.5C149.5 114 233.167 84 279 70"
        stroke="#BCBCBB"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M327 60.5C328.167 60.5 331.3 60 334.5 58" stroke="#858585" strokeLinecap="round" />
      <path d="M334.5 61.5C337.5 60 339.1 57.7 339.5 56.5" stroke="#858585" strokeLinecap="round" />
      <ellipse
        cx="336.59"
        cy="48.7806"
        rx="14.4772"
        ry="8.26267"
        transform="rotate(-24.1329 336.59 48.7806)"
        fill="#9D9D9C"
      />
      <ellipse
        cx="349.308"
        cy="42.3673"
        rx="2.27085"
        ry="1.5"
        transform="rotate(52.2745 349.308 42.3673)"
        fill="#9D9D9C"
      />
      <ellipse
        cx="335.246"
        cy="33.6729"
        rx="8"
        ry="14"
        transform="rotate(-5.24522 335.246 33.6729)"
        fill="#9D9D9C"
      />
      <ellipse
        cx="325.346"
        cy="36.734"
        rx="8"
        ry="14"
        transform="rotate(-42.3779 325.346 36.734)"
        fill="#9D9D9C"
      />
      <path
        d="M54 271V346.695C107.6 404.848 178.667 418.356 207.5 416.809V302.358L54 271Z"
        fill="#CDCCCC"
      />
      <path
        d="M361 270.909V346.695C304.2 407.323 234.667 418.699 207 416.809V302.358L54 270.909L194.5 285.345L207 219.87L220 285.345L361 270.909Z"
        fill="#BCBCBB"
      />
      <path d="M54 205.436L41 271.426L195 287.407L207.5 220.386L54 205.436Z" fill="#CDCCCC" />
      <path
        d="M360.5 205.436L373.5 271.426L219.5 287.407L207 220.386L360.5 205.436Z"
        fill="#CDCCCC"
      />
      <path d="M208 191L54 205.951L208 221.417L360.5 205.951L208 191Z" fill="#BCBCBB" />
    </svg>
  );
};
