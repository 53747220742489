import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { Option } from "../../pages/Settings/DocumentType/FormDesigner";
import { ViewMode } from "./DocumentBookmarkTags";

export interface ProfileDocumentListOption {
  label: string;
  subtext?: string;
  value: string;
  isFavorites: boolean | undefined;
}

export type DocumentListRowOption = Option & {
  isFavorites: boolean | undefined;
  order: number | undefined;
};

export type DocumentBookmarkTagsProps = {
  documentId: string;
  viewMode: ViewMode;
};
export function useDocumentBookmarkTags({ documentId, viewMode }: DocumentBookmarkTagsProps) {
  const { resources } = useAppTranslation();
  const {
    addDocumentToDocumentList,
    removeDocumentFromDocumentList,
    updateMyUserProfileDocumentLabels,
    getMyUserProfileDocumentLabels,
    getDocumentLabels,
  } = useServices();
  const [selectedProfileDocumentLists, setSelectedProfileDocumentLists] = useState<
    ProfileDocumentListOption[]
  >([]);

  const {
    data: profileListsData,
    isLoading: isLoadingProfileLabels,
    refetch: refetchProfileLabels,
    remove: evictProfileLabels,
  } = useOrganizationQuery(`profileLabels`, getMyUserProfileDocumentLabels, {
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: true,
  });

  const refetchProfileLabelsCallback = useCallback(() => {
    refetchProfileLabels();
  }, [refetchProfileLabels]);

  const fetchDocumentLists = useCallback(async () => {
    const data = await getDocumentLabels(documentId);
    setSelectedProfileDocumentLists(
      data.documentLists.map((documentList) => ({
        label: documentList.isFavorites ? resources.favorites : documentList.name,
        value: documentList.name,
        isFavorites: documentList.isFavorites,
      }))
    );
    return data;
  }, [getDocumentLabels, documentId, resources]);

  const {
    isLoading: isLoadingDocumentLabels,
    refetch: refetchDocumentLabels,
    remove: evictDocumentLabels,
  } = useOrganizationQuery(`documentLabels/${documentId}`, fetchDocumentLists, {
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: true,
  });

  const addDocumentToList = useCallback(
    async (
      lists: Array<{
        isFavorites: boolean;
        name: string;
        order: number | undefined;
      }>
    ) => {
      return await Promise.all(
        (lists || []).map((label) => {
          return addDocumentToDocumentList(documentId, label.name, label.isFavorites, label.order);
        })
      );
    },
    [addDocumentToDocumentList, documentId]
  );
  const { mutate: mutateAddDocumentToList } = useMutation(addDocumentToList, {
    onSuccess: () => {
      evictProfileLabels();
      evictDocumentLabels();
      refetchDocumentLabels();
      refetchProfileLabelsCallback();
      if (viewMode?.type === "addToList" && viewMode.refetchParentInfo) {
        viewMode.refetchParentInfo();
      }
    },
  });
  const removeDocumentFromList = useCallback(
    async (
      lists: Array<{
        isFavorites: boolean;
        name: string;
      }>
    ) => {
      return await Promise.all(
        (lists || []).map((label) => {
          return removeDocumentFromDocumentList(documentId, label.name, label.isFavorites);
        })
      );
    },
    [removeDocumentFromDocumentList, documentId]
  );
  const { mutate: mutateRemoveDocumentFromList } = useMutation(removeDocumentFromList, {
    onSuccess: () => {
      evictProfileLabels();
      evictDocumentLabels();
      refetchDocumentLabels();
      refetchProfileLabelsCallback();
      if (viewMode?.type === "addToList" && viewMode.refetchParentInfo) {
        viewMode.refetchParentInfo();
      }
    },
  });
  const handleSaveDocumentList = useCallback(
    (
      lists: Array<{
        isFavorites: boolean;
        name: string;
        order: number | undefined;
      }>
    ) => {
      mutateAddDocumentToList(lists);
    },
    [mutateAddDocumentToList]
  );

  const handleRemoveDocumentFromList = useCallback(
    (
      lists: Array<{
        isFavorites: boolean;
        name: string;
      }>
    ) => {
      mutateRemoveDocumentFromList(lists);
    },
    [mutateRemoveDocumentFromList]
  );

  const updateProfileDocumentLabels = useCallback(
    async ({
      oldDocumentLabel,
      newDocumentLabel,
    }: {
      oldDocumentLabel: string | undefined;
      newDocumentLabel: string;
    }) => {
      return await updateMyUserProfileDocumentLabels(oldDocumentLabel, newDocumentLabel);
    },
    [updateMyUserProfileDocumentLabels]
  );

  const { mutate: mutateUpdateProfileLabels } = useMutation(updateProfileDocumentLabels, {
    onSuccess: () => {
      evictProfileLabels();
      evictDocumentLabels();
      refetchProfileLabelsCallback();
      refetchDocumentLabels();
      if (viewMode?.type === "addToList" && viewMode.refetchParentInfo) {
        viewMode.refetchParentInfo();
      }
    },
  });

  const handleUpdateProfileLabels = useCallback(
    (oldDocumentLabel: string | undefined, newDocumentLabel: string) => {
      mutateUpdateProfileLabels({
        oldDocumentLabel: oldDocumentLabel,
        newDocumentLabel: newDocumentLabel,
      });
    },
    [mutateUpdateProfileLabels]
  );

  const profileDocumentLists: ProfileDocumentListOption[] = useMemo(() => {
    return (profileListsData?.documentLists || []).map((profileDocumentList) => ({
      label: profileDocumentList.name,
      value: profileDocumentList.name,
      isFavorites: profileDocumentList.isFavorites,
    }));
  }, [profileListsData]);

  const handleSelectedLabels = useCallback(
    (selectedLabel: ProfileDocumentListOption) => {
      setSelectedProfileDocumentLists((prevLabels) => {
        const isAlreadySelected = prevLabels.some(
          (documentList) =>
            (selectedLabel.isFavorites && documentList.isFavorites) ||
            documentList.value === selectedLabel.value
        );

        let action: "add" | "remove" = "add";

        if (isAlreadySelected) {
          action = "remove";
        }

        if (action === "add") {
          handleSaveDocumentList([
            {
              isFavorites: !!selectedLabel.isFavorites,
              name: selectedLabel.value,
              order: undefined,
            },
          ]);
        } else {
          handleRemoveDocumentFromList([
            {
              isFavorites: !!selectedLabel.isFavorites,
              name: selectedLabel.value,
            },
          ]);
        }

        return [];
      });
    },
    [handleRemoveDocumentFromList, handleSaveDocumentList]
  );

  return {
    profileDocumentLabels: profileDocumentLists,
    selectedLabels: selectedProfileDocumentLists,
    handleSelectedLabels,
    isLoadingProfileLabels,
    handleUpdateProfileLabels,
    isLoadingDocumentLabels,
    profileListsData,
    refetchProfileLabelsCallback,
  };
}
