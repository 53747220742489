import { Button, Upload } from "antd";

import { Icon } from "../../components";

type Props = {
  accept: string;
  image: any;
  disabled: boolean;
  onChange: (event: any) => Promise<any>;
  onRemove: () => void;
  customRequest: any;
  resources: {
    clickUploadImage: string;
  };
};
export function ButtonImage({
  accept,
  image,
  disabled,
  onChange,
  onRemove,
  customRequest,
  resources,
}: Props) {
  return (
    <Upload
      accept={accept}
      disabled={disabled}
      listType="picture"
      fileList={image ? [image] : []}
      onRemove={onRemove}
      onChange={onChange}
      customRequest={customRequest}
    >
      <Button /*disabled*/ loading={disabled}>
        <Icon icon="arrow-up" accessibilityLabel={"upload"} /> {resources.clickUploadImage}
      </Button>
    </Upload>
  );
}
