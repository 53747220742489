import { useMemo } from "react";
import { useOrganizationQuery } from "./useOrganizationQuery";
import { useServices } from "./useServices";

export function usePlanSetting() {
  const { fetchOrganizationAdminInfo } = useServices();

  const { data: organizationInfoResponse } = useOrganizationQuery(
    `organization-admin-info`,
    fetchOrganizationAdminInfo
  );

  const upgradePlanSetting = useMemo(() => {
    return {
      upgradePlanBehavior: organizationInfoResponse?.settings.upgradePlanBehavior
        ? organizationInfoResponse?.settings.upgradePlanBehavior
        : "managed",
      plan: organizationInfoResponse?.settings.plan
        ? organizationInfoResponse?.settings.plan
        : "professional",
    };
  }, [organizationInfoResponse]);

  return upgradePlanSetting;
}
