import { ColorConfig } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const DocSvg: FunctionComponent<Props> = ({
  customClassName,
  width = 14,
  height = 18,
  onClick,
}) => {
  return (
    <svg
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 14 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.66634 0.666992H1.99967C1.55765 0.666992 1.13372 0.842587 0.821163 1.15515C0.508602 1.46771 0.333008 1.89163 0.333008 2.33366V15.667C0.333008 16.109 0.508602 16.5329 0.821163 16.8455C1.13372 17.1581 1.55765 17.3337 1.99967 17.3337H11.9997C12.4417 17.3337 12.8656 17.1581 13.1782 16.8455C13.4907 16.5329 13.6663 16.109 13.6663 15.667V5.66699L8.66634 0.666992ZM4.62884 13.5453C4.27384 13.8403 3.73467 13.9795 3.07551 13.9795C2.67967 13.9795 2.40051 13.9545 2.21051 13.9295V10.6203C2.55132 10.5712 2.89536 10.5481 3.23967 10.5512C3.87967 10.5512 4.29467 10.6662 4.61884 10.9112C4.96884 11.1712 5.18884 11.5862 5.18884 12.1795C5.18884 12.8253 4.95384 13.2703 4.62884 13.5453ZM7.12051 14.0003C6.12051 14.0003 5.53634 13.2453 5.53634 12.2853C5.53634 11.2762 6.18051 10.522 7.17551 10.522C8.20967 10.522 8.77467 11.2962 8.77467 12.2262C8.77384 13.3303 8.10384 14.0003 7.12051 14.0003ZM10.9997 13.3653C11.2288 13.3653 11.4838 13.3145 11.6347 13.2553L11.7497 13.8495C11.6097 13.9195 11.2947 13.9945 10.8855 13.9945C9.72134 13.9945 9.12134 13.2703 9.12134 12.3103C9.12134 11.1612 9.94051 10.522 10.9605 10.522C11.3555 10.522 11.6547 10.602 11.7897 10.672L11.6347 11.277C11.4318 11.1931 11.2142 11.1506 10.9947 11.152C10.3897 11.152 9.91967 11.517 9.91967 12.267C9.91967 12.9412 10.3197 13.3653 10.9997 13.3653ZM8.66634 6.50033H7.83301V2.33366L11.9997 6.50033H8.66634Z"
        fill="#00185E"
      />
    </svg>
  );
};
