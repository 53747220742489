import { Box, Button, Text, useColors } from "@prodoctivity/design-system";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { DocumentCollectionState, useRelationshipStep } from "../hooks";

import { ComboBoxProps } from "@prodoctivity/design-system/components/ComboBox";
import type { DataType } from "@prodoctivity/shared/src/index-types";
import { useAppTranslation } from "../../../../hooks/useAppTranslation";
import { RelationshipRow } from "./Components/RelationshipRow";

type Props = {
  resources: ReturnType<typeof useAppTranslation>["resources"];
  formState: DocumentCollectionState;
  isLoading: boolean;
  masterSourceFields: Array<{ label: string; value: string; dataType: DataType }>;
  documentTypesOptions: ComboBoxProps["options"];
  setFormState: Dispatch<SetStateAction<DocumentCollectionState>>;
};

export const RelationshipsStep: FunctionComponent<Props> = ({
  resources,
  formState,
  masterSourceFields,
  documentTypesOptions,
  setFormState,
}) => {
  const { documentTypeListAvailable, sourceFieldsAvailable, onRemoveRelationship, onNewRelation } =
    useRelationshipStep(formState, setFormState, documentTypesOptions, masterSourceFields);
  const { colors } = useColors();

  return (
    <Box padding={2} borderStyle="lg" borderRadius={6}>
      <Box display={"flex"} gap={3} direction="column">
        {formState.relationships.map((r, index) => {
          return (
            <RelationshipRow
              resources={resources}
              formState={formState}
              key={index}
              relation={r}
              index={index}
              masterSourceFields={masterSourceFields}
              documentTypeListAvailable={documentTypeListAvailable}
              documentTypesOptions={documentTypesOptions}
              setFormState={setFormState}
              onRemoveRelationship={onRemoveRelationship}
            />
          );
        })}
      </Box>

      <Box marginTop={4}>
        {formState.relationships.length === 0 && (
          <Box marginBottom={2}>
            <Text color={colors.subtle}>{resources.documentCollection.relationRequired}</Text>
          </Box>
        )}
        {sourceFieldsAvailable.length === 0 ? (
          <Text size="200" color={colors.subtle}>
            {resources.documentCollection.noMoreFieldsAvailable}
          </Text>
        ) : (
          /* fit content fixes this button's click area extending past the actual button dimensions. */
          <Box display="flex" direction="row" flex="grow" justifyContent="end">
            <Button
              color="gray"
              onClick={onNewRelation}
              text={resources.newRelation}
              iconEnd="add-circle"
            />
          </Box>
        )}
      </Box>
      {formState.relationships.some((r) => r.sourceField === "") && (
        <Text size="200" color={colors.error}>
          {resources.documentCollection.relationshipWithEmptySource}
        </Text>
      )}
      {formState.relationships.filter(
        (r) =>
          r.documentTypes.length === 0 ||
          r.documentTypes.some((d) => d.documentTypeId === "" || d.targetField === "")
      ).length > 0 && (
        <Text size="200" color={colors.error}>
          {resources.documentCollection.relationshipWithEmptyDoctypeOrTarget}
        </Text>
      )}
    </Box>
  );
};
