import { ColorConfig, calculateColor } from "../utils";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const SectionSvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      //stroke={color ? calculateColor(color) : undefined}
      className={customClassName}
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.375 19.2211V4.77891C22.0312 4.54687 22.5 3.92109 22.5 3.1875C22.5 2.25469 21.7453 1.5 20.8125 1.5C20.0789 1.5 19.4531 1.96875 19.2211 2.625H4.77891C4.54687 1.96875 3.92109 1.5 3.1875 1.5C2.25469 1.5 1.5 2.25469 1.5 3.1875C1.5 3.92109 1.96875 4.54687 2.625 4.77891V19.2211C1.96875 19.4531 1.5 20.0789 1.5 20.8125C1.5 21.7453 2.25469 22.5 3.1875 22.5C3.92109 22.5 4.54687 22.0312 4.77891 21.375H19.2211C19.4531 22.0312 20.0789 22.5 20.8125 22.5C21.7453 22.5 22.5 21.7453 22.5 20.8125C22.5 20.0789 22.0312 19.4531 21.375 19.2211ZM20.8125 2.625C21.1242 2.625 21.375 2.87578 21.375 3.1875C21.375 3.49922 21.1242 3.75 20.8125 3.75C20.5008 3.75 20.25 3.49922 20.25 3.1875C20.25 2.87578 20.5008 2.625 20.8125 2.625ZM3.1875 21.375C2.87578 21.375 2.625 21.1242 2.625 20.8125C2.625 20.5008 2.87578 20.25 3.1875 20.25C3.49922 20.25 3.75 20.5008 3.75 20.8125C3.75 21.1242 3.49922 21.375 3.1875 21.375ZM3.1875 3.75C2.87578 3.75 2.625 3.49922 2.625 3.1875C2.625 2.87578 2.87578 2.625 3.1875 2.625C3.49922 2.625 3.75 2.87578 3.75 3.1875C3.75 3.49922 3.49922 3.75 3.1875 3.75ZM19.6875 19.6875H4.3125V4.3125H19.6875V19.6875ZM20.8125 21.375C20.5008 21.375 20.25 21.1242 20.25 20.8125C20.25 20.5008 20.5008 20.25 20.8125 20.25C21.1242 20.25 21.375 20.5008 21.375 20.8125C21.375 21.1242 21.1242 21.375 20.8125 21.375Z"
        fill={color ? calculateColor(color) : "#2650A3"}
      />
      <path
        d="M6.75 11.1094H17.25C17.4563 11.1094 17.625 10.9406 17.625 10.7344V6.60938C17.625 6.40312 17.4563 6.23438 17.25 6.23438H6.75C6.54375 6.23438 6.375 6.40312 6.375 6.60938V10.7344C6.375 10.9406 6.54375 11.1094 6.75 11.1094ZM8.0625 7.92188H15.9375V9.42188H8.0625V7.92188ZM6.75 17.7656H17.25C17.4563 17.7656 17.625 17.5969 17.625 17.3906V13.2656C17.625 13.0594 17.4563 12.8906 17.25 12.8906H6.75C6.54375 12.8906 6.375 13.0594 6.375 13.2656V17.3906C6.375 17.5969 6.54375 17.7656 6.75 17.7656ZM8.0625 14.5781H15.9375V16.0781H8.0625V14.5781Z"
        fill={color ? calculateColor(color) : "#2650A3"}
      />
    </svg>
  );
};
