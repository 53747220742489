import { useColors } from "@prodoctivity/design-system";
import type {
  ContextField,
  DocumentTypeSettingsFilter,
  ParametersObject,
  TemplateContextRecordHolder,
} from "@prodoctivity/shared/src/index-types";
import type { HttpGetDocumentTypeListRequest, ListableDocumentType } from "@prodoctivity/types";
import { useCallback, useMemo, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { useGetDocumentVersion } from "../../pages/StandAloneViewer/hooks";
import { usePaginatedDataEndpoint } from "../hooks";
import { LookUpDocumentProps, StateProps } from "./LookupDocument";

export type PropsDocumentContext = {
  context: ParametersObject | undefined;
};

export function useLookupDocument({ onChange, setDocumentSearchOpen }: LookUpDocumentProps) {
  const { searchSuggestions } = useServices();
  const [value, setValue] = useState("");
  const [selectedDocumentInfo, setSelectedDocumentInfo] = useState({
    documentId: "",
    documentVersionId: "",
  });
  const { resources } = useAppTranslation();
  const [documentTypeIdSelected, setDocumentTypeIdSelected] = useState<string>(resources.all);
  const { colors } = useColors();

  const [filterTextFieldValue, setFilterTextFieldValue] = useState<DocumentTypeSettingsFilter>({
    documentTypeName: undefined,
    documentGroupId: undefined,
  });
  const {
    paginatedData: documentTypeData,
    isLoading: isLoadingDocumentTypeData,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount,
    filter,
    setFilter,
  } = usePaginatedDataEndpoint<
    { documentTypes: ListableDocumentType[] },
    HttpGetDocumentTypeListRequest["queryParameters"]["rowsPerPage"],
    DocumentTypeSettingsFilter
  >(
    "15",
    { documentTypeName: undefined, documentGroupId: undefined },
    (services, currentPage, rowsPerPage, filter) => {
      return services.getDocumentTypeList(
        currentPage,
        rowsPerPage,
        filter.documentTypeName,
        filter.documentGroupId
      );
    },
    `document_type_list_settings_page/${filterTextFieldValue}`
  );

  const debouncedSetFilter = useDebounceCallback((newFilter: DocumentTypeSettingsFilter) => {
    setFilter(newFilter);
  }, 500);

  const handleChange = useCallback(
    (newFilter: DocumentTypeSettingsFilter) => {
      if (
        newFilter.documentTypeName !== filterTextFieldValue.documentTypeName ||
        newFilter.documentGroupId !== filterTextFieldValue.documentGroupId
      ) {
        setFilterTextFieldValue(newFilter);
        debouncedSetFilter(newFilter);
      }
    },
    [debouncedSetFilter, filterTextFieldValue]
  );

  const getInitialDocuments = useCallback(async () => {
    if (!value) {
      return {
        pageLength: 0,
        pageNumber: 0,
        requestedPageLength: 0,
        results: [],
        totalRowCount: undefined,
      };
    }

    const status = await searchSuggestions(undefined, value, []);

    return status;
  }, [searchSuggestions, value]);

  const { data, isLoading } = useOrganizationQuery(
    `/search-document/q=${encodeURIComponent(value)}`,
    getInitialDocuments,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { documentResponse, isLoading: loading } = useGetDocumentVersion(
    selectedDocumentInfo?.documentId,
    selectedDocumentInfo?.documentVersionId
  );

  const filteredDocumentList = useMemo(() => {
    if (value.length === 0) {
      return [];
    } else {
      return (data?.results || [])
        .filter(
          (dl) =>
            documentTypeIdSelected === resources.all ||
            dl.$documentTypeVersionId === documentTypeIdSelected
        )
        .map((d) => ({
          name: d.$name,
          documentId: d.$documentId,
          documentVersionId: d.$documentVersionId,
          documentTypeName: d.$documentTypeName,
          date: d.$updatedAt,
        }));
    }
  }, [data?.results, documentTypeIdSelected, resources.all, value.length]);

  const updateContext = useCallback(() => {
    if (documentResponse?.document?.data && onChange) {
      onChange({
        context: documentResponse.document.data,
        isLoading: true,
      });

      setTimeout(() => {
        onChange({
          context: documentResponse.document.data,
          isLoading: false,
        });
      }, 1);
    }

    setValue("");
    setDocumentSearchOpen(false);
    setSelectedDocumentInfo({
      documentId: "",
      documentVersionId: "",
    });
    setDocumentTypeIdSelected(resources.all);
  }, [
    documentResponse,
    onChange,
    setDocumentSearchOpen,
    setSelectedDocumentInfo,
    resources,
    setValue,
    setDocumentTypeIdSelected,
  ]);

  const handleSearchSelection = useCallback(
    (value: string) => {
      setDocumentTypeIdSelected(value);
    },
    [setDocumentTypeIdSelected]
  );

  const tableOptions = [
    {
      value: resources.documentTypes.documentType,
    },
    {
      value: resources.name,
    },
    {
      value: resources.date,
    },
  ];

  return {
    data: filteredDocumentList.slice(0, 6),
    value,
    setValue,
    selectedDocumentInfo,
    setSelectedDocumentInfo,
    documentTypeIdSelected,
    tableOptions,
    handleSearchSelection,
    resources,
    colors,
    updateContext,
    isLoading,
    documentResponse,
    documentTypeData,
    isLoadingDocumentTypeData,
    loading,
    setDocumentTypeIdSelected,
    currentPage,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    nextPage,
    previousPage,
    rowsPerPage,
    setPageLength,
    totalRowCount,
    setFilterValue: setFilter,
    filterValue: filter,
    handleChange,
    filterTextFieldValue,
  };
}

export const useDocumentLookupState = (
  contextDefinition: TemplateContextRecordHolder | undefined,
  context: ContextField[] | ParametersObject | undefined
) => {
  const [documentLoadingState, setDocumentLoadingState] = useState<StateProps>({
    context: undefined,
    isLoading: false,
  });
  const [isDocumentSearchOpen, setDocumentSearchOpen] = useState(false);

  const extractRelevantContext = useMemo(() => {
    const relevantContext: ParametersObject = {};

    if (context && documentLoadingState.context) {
      const fields = Array.isArray(context)
        ? context.map((field) => field.name)
        : Object.keys(context);

      fields.forEach((field) => {
        const fieldContext = (contextDefinition?.fields || []).find((f) => f.name === field);

        if (fieldContext) {
          const isSequence =
            fieldContext.properties.dataType === "Alphanumeric" &&
            (fieldContext.properties.inputType === "Default" ||
              fieldContext.properties.inputType === "TextBox") &&
            !!fieldContext.properties.sequenceId;

          if (isSequence) {
            relevantContext[field] = fieldContext.properties.defaultValue || "";
          }
        }

        if (documentLoadingState.context && field in documentLoadingState.context) {
          relevantContext[field] = documentLoadingState.context[field];
        }
      });
    }
    return Object.keys(relevantContext).length > 0 ? relevantContext : undefined;
  }, [documentLoadingState.context, context, contextDefinition]);

  return {
    documentLoadingState,
    setDocumentLoadingState,
    setDocumentSearchOpen,
    isDocumentSearchOpen,
    extractedContext: extractRelevantContext,
  };
};
