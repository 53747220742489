import {
  Box,
  BoxWithRef,
  ColorConfig,
  Popover,
  ProDoctivityColorBundle,
  TapArea,
  Text,
  modalZIndex,
  useColors,
} from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";

import type { DocumentViewerPanelKeys } from "@prodoctivity/design-system";
import type { EcmDocument } from "@prodoctivity/types";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useGetDocumentsByGenerationToken } from "../../pages/StandAloneViewer/hooks";
import { MenuGridIcons } from "../../svg/MenuGridIcons";

const panelKeys: DocumentViewerPanelKeys[] = [
  "none",
  "data",
  "history",
  "collections",
  "generations",
  "update",
  "versions",
  "info",
  "approvals",
  "share",
  "sign",
  "change",
  "myLists",
];

type Props = {
  document: EcmDocument | undefined;
  panelInfoValue: DocumentViewerPanelKeys;
  isPanelVisible(val: DocumentViewerPanelKeys): boolean;
  setPanelInfoValue(val: DocumentViewerPanelKeys): void;
  setIsPanelHidden(val: boolean): void;
  isIconSelected: (
    color: ProDoctivityColorBundle,
    wasClicked: boolean,
    useForHover: boolean
  ) => ColorConfig | undefined;
};

export const DocumentViewerCloudIcon: FunctionComponent<Props> = ({
  document,
  panelInfoValue,
  isPanelVisible,
  setPanelInfoValue,
  setIsPanelHidden,
  isIconSelected,
}) => {
  const { colors } = useColors();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [btnClicked, setBtnClicked] = useState<boolean>(false);
  const { data: documentsByGenerationTokenList } = useGetDocumentsByGenerationToken(
    document?.generationToken || ""
  );
  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const { resources } = useAppTranslation();
  const visiblePanelKeys = useMemo(() => {
    return panelKeys.filter((key) => isPanelVisible(key));
  }, [isPanelVisible]);

  const signatureTypeAllowed =
    document?.mimeType === "application/pdf" ||
    document?.mimeType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  const cloudMenuLabels = useMemo(() => {
    return visiblePanelKeys
      .filter((key) => {
        if (key === "generations") {
          const generationListCount = documentsByGenerationTokenList?.documents.length || 1;
          return generationListCount >= 1;
        }
        if (key === "sign" && !signatureTypeAllowed) {
          return false;
        }
        return true;
      })
      .map((key) => {
        switch (key) {
          case "collections":
            return resources.collections;
          case "data":
            return resources.info;
          case "exportViaEmail":
            return resources.actions;
          case "generations":
            return resources.generationDocuments;
          case "history":
            return resources.history;
          case "info":
            return resources.properties;
          case "none":
            return "";
          case "share":
            return resources.actions;
          case "approvals":
            return resources.approvalType.approvals;
          case "update":
            return resources.updateDocument;
          case "versions":
            return resources.versions;
          case "sign":
            return resources.signatures;
          case "change":
            return resources.changes;
          case "myLists":
            return resources.myLists;
          default:
            return "";
        }
      });
  }, [visiblePanelKeys, resources, documentsByGenerationTokenList, signatureTypeAllowed]);

  if (!document) {
    return <></>;
  }

  return (
    <BoxWithRef
      ref={anchorRef}
      onClickCapture={onClick}
      width={40}
      height={32}
      color={isIconSelected(colors, open, true)}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={4}
      borderColor={
        isIconSelected(colors, open, false) !== undefined
          ? isIconSelected(colors, open, false)
          : colors.neutral50
      }
    >
      <TapArea
        onTap={() => {
          // handleFunction();
          setBtnClicked(!btnClicked);
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <MenuGridIcons width={32} height={32} />

          {open && (
            <Box zIndex={modalZIndex}>
              <Popover
                anchor={anchorRef.current}
                onDismiss={() => setOpen(false)}
                idealDirection="down"
                positionRelativeToAnchor={true}
                size="sm"
                shouldFocus={true}
                color="white"
                role="menu"
              >
                <Box paddingX={2} paddingY={2} width={346} color={colors.white}>
                  <Box display="flex" direction="column" gap={2}>
                    {visiblePanelKeys.map((key, index) => {
                      if (key === "none" || !cloudMenuLabels[index]) {
                        return null;
                      } else {
                        return (
                          <Box
                            key={`${document.documentVersionId}_${key}`}
                            padding={2}
                            rounding={2}
                            datatype="menu-item-entry"
                            color={colors.neutral50}
                            onClickCapture={() => {
                              if (panelInfoValue !== key) {
                                setIsPanelHidden(false);
                                setPanelInfoValue(key);
                              }
                            }}
                          >
                            <Box
                              display="flex"
                              direction="row"
                              alignItems="center"
                              justifyContent="start"
                            >
                              <Box display="flex" direction="column">
                                <TapArea rounding="pill">
                                  <Box paddingLeft={2}>
                                    <Text
                                      customClassName="text-medium-on-hover text-subtle-on-hover"
                                      color={colors.subtle}
                                      title={cloudMenuLabels[index]}
                                    >
                                      {cloudMenuLabels[index]}
                                    </Text>
                                  </Box>
                                </TapArea>
                              </Box>
                            </Box>
                          </Box>
                        );
                      }
                    })}
                  </Box>
                </Box>
              </Popover>
            </Box>
          )}
        </Box>
      </TapArea>
    </BoxWithRef>
  );
};
