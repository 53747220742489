// @flow

import type {
  ContextField,
  DataElement,
  DataType,
  ParametersObject,
  TemplateSection,
  TemplateWizardDefinition,
  TemplateWizardField,
  TemplateWizardFieldIsRecord,
} from "@prodoctivity/shared/src/index-types";
import type { FormDefinition, FormValues, GroupValues } from "../_lib/types";
import { concatArrayToStr, flattenDeep } from "../_lib/utils";

import { DataElementInputProps } from "@prodoctivity/design-system";
import { dateFormat } from "../_lib/date-formats";
import type momentType from "moment";
import { parseToBoolean, formValueToValueType } from "@prodoctivity/shared";

export function extractDataElements(formDefinition: FormDefinition): Array<DataElement> {
  const dataElements: Array<DataElement> = flattenDeep([
    ...formDefinition.contextFields,
    ...formDefinition.groups.map((group) => group.fields),
  ]);
  return dataElements;
}

//TODO: @eburgos Optimize performance here
export function toValueItems(
  wizardDefinition: TemplateWizardDefinition,
  getDataElement: (
    fullPath: string
  ) => { contextField: ContextField; dataElement: DataElement } | undefined,
  groupValues: GroupValues,
  formValues: FormValues,
  moment: typeof momentType,
  isElementDisabled: (fullPath: string, dataElement: DataElement) => boolean
): ValueItem[] {
  const result: ValueItem[] = [];
  wizardDefinition.pages.forEach((page) => {
    page.sections.forEach((section) => {
      result.push({
        type: "topic",
        name: section.label,
        disabled: false,
        description: section.description,
      });

      section.fields.forEach((sectionField) => {
        if (sectionField.isRecord) {
          result.push(
            recordSectionFieldToValueItems(
              getDataElement,
              groupValues,
              moment,
              section,
              sectionField
            )
          );
        } else {
          const dataElementLookup = getDataElement(sectionField.key);

          if (!dataElementLookup) {
            return;
          }

          const { contextField, dataElement } = dataElementLookup;

          dataElement.sectionName = section.label;

          const val = formValues[sectionField.key];
          result.push({
            type: "dataElement",
            name: sectionField.key,
            disabled: isElementDisabled(sectionField.key, dataElement),
            typeValue: formValueToValueType(
              moment,
              contextField.properties,
              val === null
                ? []
                : Array.isArray(val)
                ? val.reduce((acc: Array<string | number>, next) => {
                    if (typeof next !== "object") {
                      acc.push(next);
                    }
                    return acc;
                  }, [])
                : [val]
            ),
            contextField,
            wizardField: sectionField,
            instanceFullPath: sectionField.key,
          });
        }
      });
    });
  });

  return result;
}

export type RecordValueItem = {
  name: string;
  disabled: boolean;
  type: "group";
  label: string;
  columns: Array<{ name: string; dataType: DataType; dataElement: DataElement }>;
  values: ParametersObject[];
  groupTableMode?: boolean;
};

export type ValueItem =
  | {
      name: string;
      disabled: boolean;
      type: "dataElement";
      wizardField: TemplateWizardField;
      contextField: ContextField;
      instanceFullPath: string;
      typeValue: DataElementInputProps["typeValue"];
    }
  | RecordValueItem
  | {
      name: string;
      disabled: boolean;
      type: "topic";
      description: string;
    };

function recordSectionFieldToValueItems(
  getDataElement: (
    fullPath: string
  ) => { contextField: ContextField; dataElement: DataElement } | undefined,
  groupValues: GroupValues,
  moment: typeof momentType,
  section: TemplateSection,
  sectionField: TemplateWizardFieldIsRecord
): ValueItem {
  const recordNameSplit = sectionField.key.split("/");
  const recordName = recordNameSplit[recordNameSplit.length - 1];
  const recordValues = groupValues[recordName];

  const values =
    recordValues && Array.isArray(recordValues)
      ? recordValues.reduce((acc: ParametersObject[], group) => {
          if (typeof group !== "object") {
            return acc;
          }
          const valueGroup = Object.assign({}, group);
          Object.keys(valueGroup).forEach((key) => {
            const element = getDataElement(key);

            if (element !== undefined) {
              const { dataElement } = element;
              dataElement.sectionName = section.label;
              const formatDateValue = (date: any, dataType: DataType, is12Hour?: boolean) => {
                const format = dateFormat(dataType, is12Hour || false);

                return date.isValid() ? date.format(format) : "";
              };

              if (moment.isMoment(valueGroup[key])) {
                valueGroup[key] = formatDateValue(valueGroup[key], dataElement.dataType, false);
              } else if (dataElement && Array.isArray(valueGroup[key])) {
                const dataElementCopy = dataElement;
                const values = (valueGroup[key] as string[] | number[]).map((value) => {
                  if (moment(value).isValid()) {
                    value = formatDateValue(moment(value), dataElementCopy.dataType, false);
                  }
                  return value;
                });

                valueGroup[key] = concatArrayToStr(values);
              } else if (dataElement.dataType === "Logical") {
                valueGroup[key] = parseToBoolean(valueGroup[key]) ? "✔" : "✘";
              }
            }
          });
          acc.push(valueGroup);
          return acc;
        }, [])
      : [];

  return {
    type: "group",
    name: sectionField.key,
    label: sectionField.label,
    columns: sectionField.fields.reduce(
      (
        acc: Array<{
          name: string;
          dataType: DataType;
          dataElement: DataElement;
        }>,
        e
      ) => {
        const element = getDataElement(e.key);

        if (element !== undefined) {
          const { dataElement } = element;
          dataElement.sectionName = section.label;
          acc.push({
            name: e.key,
            dataType: dataElement.dataType,
            dataElement,
          });
        }

        return acc;
      },
      []
    ),
    values,
    disabled: false,
  };
}

export const getWizardFieldSections = (wizardDefinition: TemplateWizardDefinition) => {
  const sections: TemplateSection[] = [];
  wizardDefinition.pages.forEach((key) => {
    sections.push(...key.sections);
  });

  const fields: TemplateWizardField[] = [];

  sections.forEach((section) => {
    fields.push(...section.fields);
  });

  return { fields };
};
