import { Toast as GestaltToast, ToastProps as GestaltToastProps } from "gestalt";

export type ToastProps = Omit<GestaltToastProps, "dismissButton">;

export function Toast(props: ToastProps) {
  return (
    <GestaltToast
      dismissButton={{
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onDismiss() {},
      }}
      {...props}
    />
  );
}
