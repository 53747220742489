import { Box, Skeleton, useColors } from "@prodoctivity/design-system";

import type { TemplateVersionContextMapping } from "@prodoctivity/shared/src/index-types";
import type { TemplateVersionId } from "@prodoctivity/types";
import { FC } from "react";
import { DistributionCard } from "./DistributionCard";

type Props = {
  templateVersionId: TemplateVersionId;
  mappedDistributions: TemplateVersionContextMapping["distributions"];
};

const DistributionTableSkeleton = () => {
  return (
    <Box>
      <Skeleton width="100%" height={100} />
    </Box>
  );
};

const DistributionTable: FC<Props> = (DistributionTableProps: Props) => {
  const { colors } = useColors();
  return (
    <Box
      color={colors.white}
      minHeight={80}
      alignContent="center"
      alignItems="center"
      borderRadius={4}
    >
      <Box
        display="flex"
        height="100%"
        width="100%"
        justifyContent="start"
        alignItems="start"
        alignSelf="center"
        overflow="scrollY"
      >
        {DistributionTableProps.mappedDistributions.length === 0 && (
          <Box padding={4} margin={4}>
            No distributions configured for this template.
          </Box>
        )}
        {DistributionTableProps.mappedDistributions.map((distributionMapping) => {
          return (
            <DistributionCard
              key={distributionMapping.distributionConfigId}
              distributionId={distributionMapping.distributionConfigId}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export { DistributionTable, DistributionTableSkeleton };
