import type {
  DictionaryList,
  DictionaryListItem,
  DictionaryListSchema,
} from "@prodoctivity/shared/src/index-types";

export function findList(
  schema: DictionaryListSchema,
  listName: string
): DictionaryListSchema | undefined {
  for (const s of schema.children) {
    if (s.name === listName) return s;
    if (s.children) {
      const child = findList(s, listName);
      if (child) return child;
    }
  }
}

export function refreshListItems(
  items: DictionaryListItem[],
  schema: DictionaryListSchema
): DictionaryListItem[] {
  if (!schema.children) {
    return [...items];
  } else {
    return items.map((item) => {
      const updatedItem: DictionaryListItem = {
        key: item.key,
        label: item.label,
        children: {},
      };
      for (const c of schema.children) {
        if (updatedItem.children) {
          if (item.children && item.children[c.name]) {
            updatedItem.children[c.name] = refreshListItems(item.children[c.name], c);
          } else {
            updatedItem.children[c.name] = [];
          }
        }
      }
      return updatedItem;
    });
  }
}
export function hasEmptyLists(items: DictionaryListItem[]): boolean {
  let isEmpty = false;
  if (!items || items.length === 0) {
    isEmpty = true;
  }
  for (const item of items) {
    if (item.children) {
      for (const key in item.children) {
        isEmpty = isEmpty || hasEmptyLists(item.children[key]);
      }
    }
  }
  return isEmpty;
}

function isValidSchema(schema: DictionaryListSchema): boolean {
  let isValid = true;
  const validKeys = ["name", "children", "description", "listType"];
  for (const key in schema) {
    if (!validKeys.includes(key)) {
      isValid = false;
    }
  }
  for (const s of schema.children) {
    isValid = isValid && isValidSchema(s);
  }
  return isValid;
}

function isValidItemList(items: DictionaryListItem[]): boolean {
  let isValid = true;
  const validKeys = ["key", "label", "children"];
  for (const item of items) {
    for (const key in item) {
      if (!validKeys.includes(key)) {
        isValid = false;
      }
    }
    if (item.children) {
      for (const key in item.children) {
        isValid = isValid && isValidItemList(item.children[key]);
      }
    }
  }

  return isValid;
}

export function isValid(list: DictionaryList<string>): boolean {
  return isValidSchema(list.listSchema) && isValidItemList(list.items);
}
