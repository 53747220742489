import { ColorConfig, calculateColor, useColors } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const ImportSvgIcon: FunctionComponent<Props> = ({
  color: rawColor,
  customClassName,
  width = 24,
  height = 24,
}) => {
  const { colors } = useColors();
  const color = rawColor || colors.neutral900;
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_514_2160)">
        <path
          d="M18.4587 0H5.85566C5.55232 0 5.2614 0.120502 5.04691 0.334996C4.83242 0.54949 4.71191 0.840407 4.71191 1.14375V13.1547H13.5222L10.2468 9.87612C10.0816 9.69889 9.99172 9.46447 9.996 9.22225C10.0003 8.98004 10.0984 8.74894 10.2697 8.57764C10.441 8.40634 10.6721 8.30822 10.9143 8.30395C11.1565 8.29968 11.3909 8.38958 11.5682 8.55473L16.3177 13.3074C16.4896 13.4794 16.5878 13.7117 16.5913 13.9549C16.5948 14.1981 16.5033 14.4331 16.3364 14.6101L11.5837 19.5964C11.411 19.7683 11.1779 19.8659 10.9342 19.8684C10.6905 19.871 10.4554 19.7783 10.2791 19.6101C10.1027 19.4418 9.99904 19.2114 9.9901 18.9679C9.98115 18.7243 10.0677 18.4869 10.2312 18.3062L13.3633 15.0245H4.71191V22.8531C4.71191 23.1565 4.83242 23.4474 5.04691 23.6619C5.2614 23.8764 5.55232 23.9969 5.85566 23.9969H22.4104C22.561 23.9969 22.7101 23.9672 22.8493 23.9096C22.9884 23.8519 23.1149 23.7675 23.2214 23.661C23.3278 23.5545 23.4123 23.428 23.47 23.2889C23.5276 23.1498 23.5573 23.0006 23.5573 22.85V5.08609L18.4587 0Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M23.5574 5.92131V7.7912H16.9847C16.7368 7.7912 16.499 7.69269 16.3236 7.51736C16.1483 7.34202 16.0498 7.10421 16.0498 6.85625V0H17.9197V5.92131H23.5574Z"
          fill="white"
        />
        <path
          d="M6.5446 15.0242H0.934943C0.686981 15.0242 0.449175 14.9257 0.273839 14.7503C0.0985027 14.575 0 14.3372 0 14.0892C0 13.8413 0.0985027 13.6035 0.273839 13.4281C0.449175 13.2528 0.686981 13.1543 0.934943 13.1543H6.5446C6.29664 13.1543 6.05884 13.2528 5.8835 13.4281C5.70816 13.6035 5.60966 13.8413 5.60966 14.0892C5.60966 14.3372 5.70816 14.575 5.8835 14.7503C6.05884 14.9257 6.29664 15.0242 6.5446 15.0242Z"
          fill={color ? calculateColor(color) : undefined}
        />
        <path
          d="M16.3363 14.6109L11.5837 19.5973C11.4109 19.7692 11.1778 19.8668 10.9341 19.8693C10.6904 19.8719 10.4553 19.7792 10.279 19.6109C10.1026 19.4427 9.99896 19.2123 9.99001 18.9687C9.98107 18.7252 10.0676 18.4878 10.2311 18.3071L13.3632 15.0254H6.54432C6.29636 15.0254 6.05855 14.9269 5.88321 14.7516C5.70788 14.5763 5.60938 14.3384 5.60938 14.0905C5.60938 13.8425 5.70788 13.6047 5.88321 13.4294C6.05855 13.254 6.29636 13.1555 6.54432 13.1555H13.5221L10.2467 9.877C10.0815 9.69977 9.99164 9.46535 9.99591 9.22314C10.0002 8.98092 10.0983 8.74982 10.2696 8.57853C10.4409 8.40723 10.672 8.30911 10.9142 8.30483C11.1564 8.30056 11.3908 8.39047 11.5681 8.55562L16.3176 13.3082C16.4896 13.4803 16.5877 13.7126 16.5912 13.9558C16.5947 14.199 16.5033 14.434 16.3363 14.6109V14.6109Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_514_2160">
          <rect width="23.5575" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
