import {
  Box,
  BoxWithRef,
  Button,
  PopoverMenu,
  Skeleton,
  StandardIconContainer,
  TapArea,
  Text,
  useColors,
  useComponentBreakpoint,
} from "@prodoctivity/design-system";
import { FancyDateTime } from "@prodoctivity/design-system/components/FancyDateTime";
import type { WorkflowTask } from "@prodoctivity/types";
import { UseMutateFunction } from "@tanstack/react-query";
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo, useRef } from "react";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { useOrganizationNavigate } from "../../../hooks/useOrganizationNavigate";
import { useServices } from "../../../hooks/useServices";
import { organizationLinkTemplates } from "../../../link-templates";
import { RoundIndicator } from "../../../svg/Tasks/RoundIndicator";
import { WorkflowTaskSvg } from "../../../svg/Tasks/WorkflowTaskSvg";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  item: WorkflowTask<number> & {
    id: string;
    createdAt: number;
  };
  itemIdx: number;
  setProcessDetailsPanel: Dispatch<
    SetStateAction<
      | {
          taskId: string;
          processId: string;
          taskStageName: string | undefined;
        }
      | undefined
    >
  >;
  claimTask: UseMutateFunction<Record<string, never>, unknown, string, unknown>;
  isWidget: boolean;
  accordionWidth: number | undefined;

  mainContentEllipsisOptions: string[];
};

export const WorkflowTasksEntry: FunctionComponent<Props> = ({
  item,
  itemIdx,
  claimTask,
  setProcessDetailsPanel,
  isWidget,
  accordionWidth,
  mainContentEllipsisOptions,
}) => {
  const { colors } = useColors();
  const { resources, moment } = useAppTranslation();
  const organizationNavigate = useOrganizationNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { user } = useServices();
  const { width: rowWidth } = useComponentBreakpoint(containerRef, "large");

  const itemStateLabel = useMemo(() => {
    if (!item.username) {
      if (item.state === "open") {
        return resources.open;
      } else {
        return resources.completed;
      }
    } else {
      if (item.state === "done") {
        return resources.completed;
      } else {
        return resources.inProgress;
      }
    }
  }, [item.state, item.username, resources]);

  const clickCaptureAction = useCallback(() => {
    const isClaimed = item.username;
    const isMyTask = user ? item.username === user.username : false;

    if (!isClaimed) {
      claimTask(item.id);
      organizationNavigate(organizationLinkTemplates.taskDetail(item.id, false));
    } else {
      if (isMyTask) {
        organizationNavigate(organizationLinkTemplates.taskDetail(item.id, false));
      }
    }
  }, [claimTask, item.id, item.username, organizationNavigate, user]);

  const widgetActionButtonJSX = useMemo(() => {
    const isClaimed = item.username;
    const isMyTask = user ? item.username === user.username : false;

    if (item.state === "open") {
      if (isMyTask) {
        return (
          <Button
            onClick={() => {
              clickCaptureAction();
            }}
            text={resources.open}
            iconEnd="directional-arrow-right"
            color={"gray"}
            size="md"
          />
        );
      } else {
        if (isClaimed) {
          return <></>;
        } else {
          return (
            <Button
              onClick={() => {
                clickCaptureAction();
              }}
              text={resources.workflow.claim}
              iconEnd="directional-arrow-right"
              color={"gray"}
              size="md"
            />
          );
        }
      }
    } else {
      return <></>;
    }
  }, [
    clickCaptureAction,
    item.state,
    item.username,
    resources.open,
    resources.workflow.claim,
    user,
  ]);

  const actionButtonJSX = useMemo(() => {
    const isClaimed = item.username;
    const isMyTask = user ? item.username === user.username : false;

    if (item.state === "open") {
      if (isMyTask) {
        return (
          <Box minWidth={accordionWidth && accordionWidth < 1100 ? 90 : undefined}>
            <Button
              text={resources.open}
              fullWidth
              onClick={() => {
                organizationNavigate(organizationLinkTemplates.taskDetail(item.id, false));
              }}
            />
          </Box>
        );
      } else {
        if (isClaimed) {
          return <></>;
        } else {
          return (
            <TapArea
              fullWidth
              onTap={() => {
                claimTask(item.id);
                organizationNavigate(organizationLinkTemplates.taskDetail(item.id, false));
              }}
            >
              <Box
                width={"100%"}
                padding={3}
                borderColor={colors.primary}
                borderRadius={6}
                display="flex"
                minWidth={accordionWidth && accordionWidth < 1100 ? 90 : undefined}
                justifyContent="center"
                alignItems="center"
                maxHeight={40}
                hoverColor={colors.primaryHover0}
                color={colors.white}
              >
                <Text weight="bold" align="center" color={colors.primary}>
                  {resources.workflow.claim}
                </Text>
              </Box>
            </TapArea>
          );
        }
      }
    } else {
      return item.action ? (
        <Text weight="bold" align="center">
          {item.action.charAt(0).toUpperCase() + item.action.slice(1)}
        </Text>
      ) : (
        <></>
      );
    }
  }, [
    accordionWidth,
    claimTask,
    colors.primary,
    colors.primaryHover0,
    colors.white,
    item.action,
    item.id,
    item.state,
    item.username,
    organizationNavigate,
    resources.open,
    resources.workflow.claim,
    user,
  ]);

  const widgetRowJSX = useMemo(() => {
    return (
      <Box
        onClickCapture={clickCaptureAction}
        display="flex"
        alignItems={"center"}
        justifyContent="end"
        marginTop={1}
        width={"100%"}
        gap={2}
      >
        <Box display="flex" direction="column" paddingRight={2} minWidth={170}>
          {accordionWidth && accordionWidth > 700 && (
            <Box display="flex" gap={1}>
              <Text size="200">{resources.workflow.priority}: </Text>
              <Text
                size="200"
                color={item.workflowTaskPriority === 1 ? colors.black600 : colors.other100}
                weight={item.workflowTaskPriority === 1 ? "normal" : "bold"}
              >
                {item.workflowTaskPriority === 1 ? resources.normal : resources.high}
              </Text>
            </Box>
          )}

          <Box display="flex" alignItems="center" gap={2}>
            {item.expirationDate > Date.now() ? (
              <RoundIndicator color="green" />
            ) : (
              <RoundIndicator color="red" />
            )}
            <Text size="100" weight="normal" color={colors.subtle}>
              {item.expirationDate > Date.now() ? resources.expires : resources.expired}{" "}
              {moment(item.expirationDate).fromNow()}
            </Text>
          </Box>
        </Box>
        <Box display="flex" paddingRight={2}>
          {widgetActionButtonJSX}
        </Box>
      </Box>
    );
  }, [
    accordionWidth,
    clickCaptureAction,
    colors.black600,
    colors.other100,
    colors.subtle,
    item.expirationDate,
    item.workflowTaskPriority,
    moment,
    resources.expired,
    resources.expires,
    resources.high,
    resources.normal,
    resources.workflow.priority,
    widgetActionButtonJSX,
  ]);

  return (
    <BoxWithRef ref={containerRef} key={`${itemIdx}-${item.id}`}>
      <Box
        display="flex"
        flex="grow"
        alignItems="center"
        justifyContent="start"
        maxHeight={accordionWidth && accordionWidth < 700 ? undefined : 60}
        paddingY={3}
        color={itemIdx % 2 === 0 ? colors.primaryHover0 : undefined}
        borderRadius={6}
        borderColor={colors.white}
        direction={accordionWidth && accordionWidth < 700 ? "column" : "row"}
      >
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          gap={4}
          column={isWidget ? undefined : accordionWidth && accordionWidth > 950 ? 5 : 8}
          paddingLeft={2}
          paddingRight={isWidget ? 6 : 3}
          width={isWidget || (accordionWidth && accordionWidth < 700) ? "100%" : undefined}
        >
          <Box display="flex" justifyContent="end">
            <StandardIconContainer>
              <WorkflowTaskSvg />
            </StandardIconContainer>
          </Box>
          <Box width={rowWidth && rowWidth < 400 ? 200 : 300}>
            <Text overflow="ellipsis" size="200" color={colors.black600} title={item.folderName}>
              {`${item.collectionName} - ${item.folderName}`}
            </Text>
            <Text overflow="ellipsis" size="200" color={colors.neutral900} title={item.folderName}>
              {!rowWidth ? (
                <Skeleton height={10} width={"100%"} />
              ) : (
                <Box display="flex" gap={1}>
                  {!item.username ? (
                    resources.unassigned
                  ) : (
                    <PublicProfile color={colors.neutral900} username={item.username} />
                  )}
                  {"-"}
                  <Text
                    size="200"
                    color={colors.neutral900}
                    overflow="ellipsis"
                  >{`${item.workflowTaskName}`}</Text>
                </Box>
              )}
            </Text>
          </Box>
        </Box>
        {!isWidget && accordionWidth && accordionWidth > 950 && (
          <Box display="flex" justifyContent="start" column={3} paddingRight={3}>
            <Text
              overflow="ellipsis"
              size="200"
              color={colors.black600}
              title={item.workflowTaskPriority === 1 ? resources.normal : resources.high}
            >
              {item.workflowTaskPriority === 1 ? resources.normal : resources.high}
            </Text>
          </Box>
        )}
        {!isWidget && accordionWidth && accordionWidth > 700 && (
          <Box
            display="flex"
            justifyContent="start"
            column={accordionWidth && accordionWidth > 950 ? 1 : 2}
            paddingRight={3}
          >
            <Text
              overflow="ellipsis"
              size="200"
              color={colors.black600}
              title={new Date(item.expirationDate).toUTCString()}
            >
              <FancyDateTime
                moment={moment}
                resources={resources}
                color={colors.black600}
                size="200"
                value={item.expirationDate}
              />
            </Text>
          </Box>
        )}
        {!isWidget && accordionWidth && accordionWidth > 950 && (
          <Box display="flex" justifyContent="start" column={1}>
            <Text size="200" color={colors.black600}>
              {itemStateLabel}
            </Text>
          </Box>
        )}

        {!isWidget && accordionWidth && accordionWidth > 700 && (
          <Box column={accordionWidth && accordionWidth > 950 ? 1 : 2} paddingX={2}>
            {actionButtonJSX}
          </Box>
        )}
        {isWidget || (accordionWidth && accordionWidth < 700) ? (
          widgetRowJSX
        ) : (
          <Box column={1} display="flex" justifyContent="end" alignItems="center">
            <PopoverMenu
              handleFunctionsBundle={[
                () => {
                  setProcessDetailsPanel({
                    taskId: item.id,
                    processId: item.processId,
                    taskStageName: undefined,
                  });
                },
              ]}
              menuLabels={mainContentEllipsisOptions}
              popoverPanelWidth={180}
              collapsibleMenuIcon="verticalEllipsis"
              menuHasIcons={false}
              srcAreImages={undefined}
              bundleNeedsParameters={false}
            />
          </Box>
        )}
      </Box>
    </BoxWithRef>
  );
};
