import { Box, Text, useColors } from "@prodoctivity/design-system";
import { FunctionComponent } from "react";

import type { DocumentSharedLogEntry, DocumentViewLogEntry } from "@prodoctivity/types";
import { useAppTranslation } from "../../../hooks/useAppTranslation";
import { organizationLinkTemplates } from "../../../link-templates";
import { OrganizationNavLink } from "../../OrganizationLink";
import { PublicProfile } from "../../Profile/Public/PublicProfile";

type Props = {
  id: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  entry: DocumentViewLogEntry | DocumentSharedLogEntry;
};

export const DocumentViewEntry: FunctionComponent<Props> = ({
  id,
  entry,
  fullScreen,
  fullWidth,
}) => {
  const { colors } = useColors();
  const { resources } = useAppTranslation();

  return (
    <Box display="flex" direction={fullWidth ? "row" : "column"} flex="grow">
      {fullWidth ? (
        <Box marginEnd={6}>
          <PublicProfile username={entry.username} onlyImage={true} sizeImage={48} />
        </Box>
      ) : null}

      <Box display="flex" direction="column">
        <Text inline={true} size="200">
          <Box display="flex" direction="column">
            <PublicProfile username={entry.username} onlyImage={false} size="300" />
            {entry.entryType === "document-view" ? (
              <Text inline={true} size="200">
                {resources.activities.entryTypes.viewADocument}.
              </Text>
            ) : (
              <Text inline={true} size="200">
                {resources.activities.entryTypes.documentShare}.
              </Text>
            )}
          </Box>
        </Text>
        {!fullScreen ? (
          <Box>
            <OrganizationNavLink to={`/activities/${id}`}>
              <Text size="200" color={colors.primary}>
                {resources.activities.viewDetail}
              </Text>
            </OrganizationNavLink>
          </Box>
        ) : (
          <Box display="flex" direction="column">
            {!fullScreen && (
              <Box>
                <OrganizationNavLink to={`/activities/${id}`}>
                  <Text size="200" color={colors.primary}>
                    {resources.activities.viewDetail}
                  </Text>
                </OrganizationNavLink>
              </Box>
            )}
            <Box margin={1}></Box>
            <Box display="flex" direction="column" gap={4}>
              {fullScreen ? (
                <Box display="flex" gap={2} direction="column">
                  <Box borderStyle="sm" width={"100%"} height={1} />
                  <Box display="flex" direction="row" gap={2}>
                    {entry.entryType === "document-view" ? (
                      <Text inline={true} size="200">
                        {resources.viewDocument}
                      </Text>
                    ) : (
                      <Text inline={true} size="200">
                        {resources.activities.entryTypes.documentShare}.
                      </Text>
                    )}
                    <OrganizationNavLink
                      to={organizationLinkTemplates.documentIdAndVersion(
                        entry.entries[0].documentId,
                        entry.entries[0].documentVersionId,
                        undefined
                      )}
                    >
                      <Text size="200" ellipsisLength={20}>
                        {/* TODO: @eburgos add document lookup */}
                        {resources.document}
                      </Text>
                    </OrganizationNavLink>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <OrganizationNavLink
                    to={organizationLinkTemplates.documentIdAndVersion(
                      entry.entries[0].documentId,
                      entry.entries[0].documentVersionId,
                      undefined
                    )}
                  >
                    <Text inline={true} size="200" ellipsisLength={30}>
                      {/* TODO: @eburgos add document lookup */}
                      {resources.document}
                    </Text>
                  </OrganizationNavLink>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
