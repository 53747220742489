import { FormControllerProvider, Spinner } from "@prodoctivity/design-system";
import { FunctionComponent, useCallback, useMemo } from "react";
import { useCombineGenerationWizard, useGenerationWizardWithPayload } from "./hooks";

import { GenerationWizardBoundary } from "@prodoctivity/prodoctivity-form-v5";
import type { ParametersObject } from "@prodoctivity/shared/src/index-types";
import type { TemplateVersionId } from "@prodoctivity/types";
import { BreadCrumbEntry } from "../../components/BreadCrumb";
import { Page } from "../../components/Layout/Page";
import { NotificationMessage } from "../../components/NotificationMessage";
import { useAppTranslation } from "../../hooks/useAppTranslation";
import { useOrganizationQuery } from "../../hooks/useOrganizationQuery";
import { useServices } from "../../hooks/useServices";
import { noop } from "../../utils";
import { usePageHeight } from "../Dashboard/DashboardPageWrapper";
import { PageNotFound } from "../StandAloneViewer/PageNotFound";
import { GenerationWizardWithoutState } from "../templates/GenerationWizard";
import { CombinedDocumentViewer } from "./CombinedDocumentViewer";

function CombinedGenerationWizardPage() {
  const { combinedTemplateId, combinedTemplateResponse, isLoading, stateId } =
    useCombineGenerationWizard();
  const { resources } = useAppTranslation();

  if (isLoading) {
    return <Spinner show={true} />;
  }

  if (!combinedTemplateId || !combinedTemplateResponse?.id) {
    return <PageNotFound message={resources.pageNotFound.combinedTemplateNotFound} />;
  }

  if (stateId) {
    return (
      <GenerationWizardWithStateId combinedTemplateId={combinedTemplateId} stateId={stateId} />
    );
  } else {
    return (
      <GenerationWizardWithoutState
        contextInfo={{
          contextType: "combined-template",
          combinedTemplateId,
          templateVersionIdList: combinedTemplateResponse.combinedTemplate.templates,
        }}
      />
    );
  }
}

const GenerationWizardWithStateId: FunctionComponent<{
  combinedTemplateId: TemplateVersionId | undefined;
  stateId: string;
}> = ({ combinedTemplateId, stateId }) => {
  const { getTemplateContextState } = useServices();
  const { resources } = useAppTranslation();
  const getTemplateContext = useCallback(() => {
    return getTemplateContextState(stateId);
  }, [getTemplateContextState, stateId]);

  const { isLoading, data } = useOrganizationQuery(
    `combined-template-generation/${combinedTemplateId}/${stateId}`,
    getTemplateContext,
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

  if (isLoading) {
    return <Spinner show={true} />;
  }

  if (
    !data ||
    data.contextType !== "combined-template" ||
    data.combinedTemplateId !== combinedTemplateId
  ) {
    return <PageNotFound message={resources.pageNotFound.combinedTemplateNotFound} />;
  }

  return (
    <GenerationWizardWithPayload
      combinedTemplateId={combinedTemplateId}
      stateId={stateId}
      context={data.context}
    />
  );
};

const GenerationWizardWithPayload: FunctionComponent<{
  combinedTemplateId: TemplateVersionId;
  stateId: string;
  context: ParametersObject;
}> = ({ combinedTemplateId, stateId, context }) => {
  const {
    i18n,
    moment,
    documents,
    isMutating,
    combinedTemplateDefinition,
    state,
    organizationNavigate,
    execDataLink,
    onFinish,
    onFormValuesChange,
    onCancel,
    documentKeys,
    generateDocument,
    handleDismiss,
    getTemplateContextSample,
  } = useGenerationWizardWithPayload({ combinedTemplateId, stateId, context });
  const { resources } = useAppTranslation();

  const breadCrumbEntries: BreadCrumbEntry[] = useMemo(() => {
    return [
      { type: "url", name: resources.home, url: "/" },
      {
        type: "text",
        name: `${resources.generate} - ${combinedTemplateDefinition?.name ?? ""}`,
      },
    ];
  }, [resources, combinedTemplateDefinition?.name]);

  usePageHeight(true);

  return (
    <Page breadCrumbEntries={breadCrumbEntries}>
      {combinedTemplateDefinition && (
        <FormControllerProvider
          contextDefinition={combinedTemplateDefinition.mergedContextDefinition}
          wizardDefinition={combinedTemplateDefinition.wizardDefinitionMerged}
          //TODO: @eburgos Put dataLinkMappings here
          dataLinkMappings={[]}
          executeDataLink={undefined}
          moment={moment}
        >
          <GenerationWizardBoundary
            generating={isMutating}
            generateOnFinish={true}
            templateVersionId={combinedTemplateId}
            // dataLinkMappings={state.template?.dataLinkMappings || []}
            dataLinkMappings={[]}
            DocumentViewerComponentImplementation={() => (
              <CombinedDocumentViewer
                combinedTemplateId={combinedTemplateId}
                documents={documents}
                templateVersionList={combinedTemplateDefinition.templateVersionList}
                documentKeys={documentKeys}
              />
            )}
            onFormValuesChange={onFormValuesChange}
            executeDataLink={execDataLink}
            generateDocument={generateDocument}
            documentBinary={new Blob()}
            navigate={organizationNavigate}
            connectors={[]}
            connectorDataLinks={[]}
            dataLinks={[]}
            i18n={i18n}
            onFireConnector={() =>
              new Promise((resolve) =>
                resolve({
                  formValues: null,
                  formErrors: null,
                  groupValues: null,
                })
              )
            }
            moment={moment}
            onFinish={onFinish}
            onCancel={onCancel}
            cancelLabel={undefined}
            resources={resources}
            getTemplateContextSample={getTemplateContextSample}
            onSearch={noop}
          />
        </FormControllerProvider>
      )}
      {(state.errors || []).map((error, i) => (
        <NotificationMessage
          key={i}
          type="error"
          message={error.message}
          position="bottom-left"
          handleDismiss={() => handleDismiss(i)}
        />
      ))}
    </Page>
  );
};

export default CombinedGenerationWizardPage;
