import { ColorConfig, calculateColor } from "@prodoctivity/design-system";

import { FunctionComponent } from "react";

type Props = {
  customClassName?: string;
  color?: ColorConfig;
  width?: number;
  height?: number;
};

export const AvailabilitySvgIcon: FunctionComponent<Props> = ({
  color,
  customClassName,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      className={customClassName || undefined}
      stroke={color ? calculateColor(color) : undefined}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 48.98"
    >
      <g transform="translate(0)">
        <rect width="50" height="48.98" rx="24.49" fill="#ddd4ff" />
        <path
          d="M7.5-21A1.5,1.5,0,0,1,9-19.5,1.5,1.5,0,0,1,7.5-18v.515a7.5,7.5,0,0,1-2.2,5.3L2.123-9,5.3-5.817a7.5,7.5,0,0,1,2.2,5.3V0a1.5,1.5,0,0,1,0,3h-15A1.5,1.5,0,0,1-9,1.5,1.5,1.5,0,0,1-7.5,0V-.516a7.5,7.5,0,0,1,2.2-5.3L-2.123-9-5.3-12.183a7.5,7.5,0,0,1-2.2-5.3V-18A1.5,1.5,0,0,1-9-19.5,1.5,1.5,0,0,1-7.5-21ZM-3.792-15H3.75a4.477,4.477,0,0,0,.75-2.485V-18h-9v.515A4.8,4.8,0,0,0-3.792-15Zm0,12H3.75a4.456,4.456,0,0,0-.567-.7L0-6.877-3.183-3.7c-.216.216-.4.45-.609.7Z"
          transform="translate(23.04 33.49)"
          fill="#8666fb"
        />
        <path
          d="M-7-5.25a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1-7-5.25ZM3.166-6.459a.767.767,0,0,0,0-1.083.767.767,0,0,0-1.083,0L-.875-4.583-2.084-5.791a.767.767,0,0,0-1.083,0,.767.767,0,0,0,0,1.083l1.75,1.75a.767.767,0,0,0,1.083,0Z"
          transform="translate(34.04 35.49)"
          fill="#8666fb"
        />
      </g>
    </svg>
  );
};
